
import ProjectResources from '@/models/ProjectResources';

const state = () => ({
    list: [],
    entities: []
})
  
// getters
const getters = {
    listPointclouds (state) {
        return state.list
    },
    getEntities (state, payload) {
        return state.entities;
    },
    getPointcloudEntity: (state) => (file_id) =>  {
        return state.entities.find(i => i.file_id === file_id)
    }
}

// actions
const actions = {
    async loadPointclouds(context, payload ) {
        let query = await ProjectResources.pointclouds(payload.id);
        let data = query.data.data
        return new Promise((resolve) => {
            context.commit( 'setPointcloudsList', data);
            resolve(data)
        })
    },        
    setPointcloudEntity(context, payload) {
        context.commit( 'addEntity', payload )
    },

    addPointcloud(context, payload) {
        if ( payload.Pointclouds.length > 0 ) {
            payload.Pointclouds.forEach(m => {
                context.commit( 'addPointcloud', m );
            })
        }
    },

    setPointcloudList(context, data){
        context.commit( 'setPointcloudsList', data);
    },

    clearPointcloudList(context){
        context.commit( 'clear' );
    }
}

// mutations
const mutations = {
    setPointcloudsList(state, data) {
        
        if (data != undefined) {
            if ( data.length > 0 ) {
                data.forEach(item => {
                    item.entity = null;
                })
            }
            let list = []
            data.forEach((i) => {
                if (i.process !== null) {
                    if (i.process.status !== undefined) {
                        if (i.process.status != 'cancelled' && i.process.status != 'running') {
                            list.push(i)
                        }                    
                    }                    
                }
            })
            state.list = list
            
        }
    },

    addEntity(state, data) {
        // Check if model has entity in store
        let check = state.entities.find(i => i.file_id === data.file_id )
        if ( !check ) {
            state.entities.push(data)
        } else {
            // Update entity_id if there is an update
            state.entities.map(i => {
                if ( i.file_id === data.file_id ) {
                    i.entity_id = data.entity_id
                }
            });
        }
        
    },

    addPointcloud(state, data) {
        state.list.push( data );
    },

    clear(state){
        state.list = [];
        state.entities = [];
        //console.log("pc cleared",state);
    }
}
  
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}