import swal from 'sweetalert';

export function customSwal(options = null) {
    let { title, text, releaseUrl, release_note } =
        options instanceof Object ? options : { title: '', text: '', releaseUrl: false, release_note: '' };

    title = title || '';
    text = text || '';
    releaseUrl = releaseUrl || false;
    release_note = release_note || '';

    let content = `
        <div class="flex mt-8">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M28.7992 16.0002C28.7992 23.0694 23.0685 28.8002 15.9992 28.8002C8.92997 28.8002 3.19922 23.0694 3.19922 16.0002C3.19922 8.93095 8.92997 3.2002 15.9992 3.2002C23.0685 3.2002 28.7992 8.93095 28.7992 16.0002ZM17.5992 22.4002C17.5992 23.2838 16.8829 24.0002 15.9992 24.0002C15.1156 24.0002 14.3992 23.2838 14.3992 22.4002C14.3992 21.5165 15.1156 20.8002 15.9992 20.8002C16.8829 20.8002 17.5992 21.5165 17.5992 22.4002ZM15.9992 8.0002C15.1156 8.0002 14.3992 8.71654 14.3992 9.60019V16.0002C14.3992 16.8838 15.1156 17.6002 15.9992 17.6002C16.8829 17.6002 17.5992 16.8838 17.5992 16.0002V9.60019C17.5992 8.71654 16.8829 8.0002 15.9992 8.0002Z" fill="#FCD34D"/>
            </svg>
            <p class="text-left text-xl ml-2 mt-0.5 font-semibold font-openSans">
            ${title}
            </p>
            <p class="text-right text-xl ml-auto mt-0.5 font-semibold font-openSans" onclick="swal.close()">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 13L13 1M1 1L13 13" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </p>
        </div>
        <div>
            <p class="w-96 text-left ml-8 mt-4 text-base">
            ${text}
            </p>
        </div>
    `;

    if (releaseUrl) {
        content = `
            <div class="flex mt-8">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M28.7992 16.0002C28.7992 23.0694 23.0685 28.8002 15.9992 28.8002C8.92997 28.8002 3.19922 23.0694 3.19922 16.0002C3.19922 8.93095 8.92997 3.2002 15.9992 3.2002C23.0685 3.2002 28.7992 8.93095 28.7992 16.0002ZM17.5992 22.4002C17.5992 23.2838 16.8829 24.0002 15.9992 24.0002C15.1156 24.0002 14.3992 23.2838 14.3992 22.4002C14.3992 21.5165 15.1156 20.8002 15.9992 20.8002C16.8829 20.8002 17.5992 21.5165 17.5992 22.4002ZM15.9992 8.0002C15.1156 8.0002 14.3992 8.71654 14.3992 9.60019V16.0002C14.3992 16.8838 15.1156 17.6002 15.9992 17.6002C16.8829 17.6002 17.5992 16.8838 17.5992 16.0002V9.60019C17.5992 8.71654 16.8829 8.0002 15.9992 8.0002Z" fill="#FCD34D"/>
                </svg>
                <p class="text-left text-xl ml-2 mt-0.5 font-semibold font-openSans">
                ${title}
                </p>
                <p class="text-right text-xl ml-auto mt-0.5 font-semibold font-openSans" onclick="swal.close()">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 13L13 1M1 1L13 13" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </p>
            </div>
            <div>
                <p class="w-96 text-left ml-8 mt-4 text-base">
                ${text}
                </p>
            </div>
            <div>
                <p class="w-96 text-left ml-8 mt-4 text-base">
                ${release_note}: <br />
                <a href="${releaseUrl}" target="_blank">${releaseUrl}</a>
                </p>
            </div>
        `;
    }

    //Remove title & text from option object before passing to swal
    delete options.title;
    delete options.text;

    let contentHTML = document.createElement('div');
    contentHTML.innerHTML = content;

    let defaultOptions = {
        content: contentHTML,
        buttons: {
            cancel: {
                text: 'No',
                value: null,
                visible: false,
                className: '',
                closeModal: true
            },
            confirm: {
                text: 'Yes',
                value: true,
                visible: true,
                className: '',
                closeModal: true
            }
        },
        dangerMode: false,
        showCloseButton: true
    };

    let swalOptions = { ...defaultOptions, ...options };
    swalOptions.content = contentHTML;

    return swal(swalOptions);
}
