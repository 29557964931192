
import Model from '@/models/Project'

const state = () => ({
    results: [],
    searchState: false
})
  
// getters
const getters = {
    searchResults(state) {
        return state.results
    },
    count(state) {
        return state.results.length
    },
    getSearchState(state) {
        return state.searchState
    }
}

// actions
const actions = {

    async querySearch(context, payload) {
        const results = await Model.search(payload)
        context.commit( 'load', results.data)
        return results;
    },
    setSearchStatus(context, status) {
        context.commit( 'setSearchState', status)
    },
    clearSearch(context) {
        context.commit( 'clearSearch')
    }
}

// mutations
const mutations = {
    load(state, results) {
        state.results = results
    },
    setSearchState(state, status) {
        state.searchState = status
    },
    clearSearch(state) {
        state.results = []
    }
}
  
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}