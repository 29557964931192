import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelRadio as _vModelRadio, withDirectives as _withDirectives, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, vModelText as _vModelText, vModelSelect as _vModelSelect, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-16dafb1c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col mx-8 my-2 justify-start items-start" }
const _hoisted_2 = { class: "text-neutral-500 text-sm" }
const _hoisted_3 = { class: "mt-5" }
const _hoisted_4 = { class: "cursor-pointer text-sm mr-20 font-bold" }
const _hoisted_5 = { class: "flex ml-6" }
const _hoisted_6 = { class: "cursor-pointer text-sm mr-12 font-semibold" }
const _hoisted_7 = ["value", "disabled"]
const _hoisted_8 = { class: "flex flex-col mx-8 my-2 justify-start items-start" }
const _hoisted_9 = { class: "cursor-pointer text-sm mr-20 font-bold" }
const _hoisted_10 = { class: "flex text-neutral-500 text-xs mt-2 ml-6" }
const _hoisted_11 = { class: "flex ml-6 mt-2 text-sm font-semibold" }
const _hoisted_12 = { class: "flex items-center align-middle" }
const _hoisted_13 = ["value"]
const _hoisted_14 = { class: "flex items-center align-middle" }
const _hoisted_15 = ["disabled"]
const _hoisted_16 = { class: "flex flex-row px-8 max-w-screen-2xl max-h-96 mt-3" }
const _hoisted_17 = { class: "overflow-y-scroll" }
const _hoisted_18 = { class: "table-fixed border-collapse border border-neutral-300" }
const _hoisted_19 = ["onUpdate:modelValue"]
const _hoisted_20 = ["value", "disabled"]
const _hoisted_21 = { class: "flex justify-center py-5 px-8 space-x-4" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Button = _resolveComponent("Button")
  const _component_LoadingButton = _resolveComponent("LoadingButton")
  const _component_Modal = _resolveComponent("Modal")

  return (_openBlock(), _createBlock(_component_Modal, {
    title: _ctx.$t('project.txt_header'),
    smallModal: true,
    onCloseModal: $options.closeModal
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('project.txt_desc')), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("label", _hoisted_4, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.txtFormat) = $event)),
              name: "txtFormat",
              type: "radio",
              class: "form-radio rounded-full mr-1 bg-neutral-50",
              value: "preFormat"
            }, null, 512), [
              [_vModelRadio, $data.txtFormat]
            ]),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('project.txt_pre_format')), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.txtOptions, (option, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: `${index}`,
              class: "flex my-2 items-start mt-2"
            }, [
              _createElementVNode("label", _hoisted_6, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.txtOption) = $event)),
                  name: "txtRadio",
                  type: "radio",
                  class: _normalizeClass(["form-checkbox mr-1 border-primary-500", $data.txtFormat == 'preFormat' ? '' : 'bg-neutral-200']),
                  value: option.value,
                  disabled: $data.txtFormat == 'preFormat' ? false : true
                }, null, 10, _hoisted_7), [
                  [_vModelRadio, $data.txtOption]
                ]),
                _createTextVNode(" " + _toDisplayString(option.value), 1)
              ])
            ]))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", null, [
          _createElementVNode("label", _hoisted_9, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.txtFormat) = $event)),
              name: "txtFormat",
              type: "radio",
              class: "form-radio rounded-full mr-1 bg-neutral-50",
              value: "customFormat"
            }, null, 512), [
              [_vModelRadio, $data.txtFormat]
            ]),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('project.txt_custom_format')), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('project.txt_custom_format_desc')), 1),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createTextVNode(_toDisplayString(_ctx.$t('project.txt_format')) + ": ", 1),
            _createElementVNode("input", {
              class: _normalizeClass(["w-5/12 ml-2 rounded outline-none border border-shades-100 text-center px-2 py-2", $data.txtFormat == 'customFormat' ? '' : 'bg-neutral-100']),
              disabled: true,
              value: $data.selectedColumns.join('')
            }, null, 10, _hoisted_13)
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createTextVNode(_toDisplayString(_ctx.$t('project.txt_max_lines')) + ": ", 1),
            _withDirectives(_createElementVNode("input", {
              class: _normalizeClass(["w-5/12 ml-2 rounded outline-none border border-shades-100 text-center px-2 py-2", $data.txtFormat == 'customFormat' ? '' : 'bg-neutral-100']),
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($data.maxLines) = $event)),
              disabled: $data.txtFormat == 'customFormat' ? false : true
            }, null, 10, _hoisted_15), [
              [_vModelText, $data.maxLines]
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("table", _hoisted_18, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.selectedColumns.length, (n) => {
                  return (_openBlock(), _createElementBlock("th", { key: n }, [
                    _withDirectives(_createElementVNode("select", {
                      class: "border-2 border-neutral-200 text-sm py-2 rounded-md w-36",
                      "onUpdate:modelValue": $event => (($data.selectedColumns[n-1]) = $event),
                      onChange: _cache[4] || (_cache[4] = $event => ($options.setOptionState($event)))
                    }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.columns, (option, index) => {
                        return (_openBlock(), _createElementBlock("option", {
                          value: option.value,
                          key: index,
                          disabled: (option.value !== 's') ? option.disabled : false,
                          class: "text-center"
                        }, _toDisplayString(_ctx.$i18n.locale == 'en' ? option.name : option.namejp), 9, _hoisted_20))
                      }), 128))
                    ], 40, _hoisted_19), [
                      [_vModelSelect, $data.selectedColumns[n-1]]
                    ])
                  ]))
                }), 128))
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.lines, (line, index) => {
                return (_openBlock(), _createElementBlock("tr", {
                  class: "text-sm border border-neutral-200",
                  key: index
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(line, (item, item_idx) => {
                    return (_openBlock(), _createElementBlock("td", {
                      key: item_idx,
                      class: _normalizeClass([(item_idx%2 == 0) ? '' : 'bg-secondShades-100', "px-5"])
                    }, _toDisplayString(item), 3))
                  }), 128))
                ]))
              }), 128))
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_21, [
        (!$data.loading)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              baseClass: "px-4 rounded-md ",
              size: "small",
              buttonType: "primary-solid",
              text: "project.txt_confirm",
              onClick: $options.handleSelectedTxt
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true),
        ($data.loading)
          ? (_openBlock(), _createBlock(_component_LoadingButton, {
              key: 1,
              class: "bg-primary-300 text-white font-bold px-3 py-2 rounded-md w-24 text-center",
              text: ""
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["title", "onCloseModal"]))
}