import Folder from '@/models/Folder';
import ProjectModel from '@/models/Project';

const state = () => ({
    projects: [],
    selectedProject: [],
    projectFile: [],
    navs: [],
    metas: [],
    demos: [],
    uploading: false
})

// getters
const getters = {
    listProjects(state) {
        return state.projects;
    },
    countProjects(state) {
        return state.allProjects.length;
    },
    getSelectedProject(state) {
        return state.selectedProject[0];
    },
    getProjectFiles(state) {
        return state.projectFile
    },
    getProjectNavs(state) {
        return state.navs
    },
    getProjectMetas(state) {
        return state.metas
    },
    listDemos(state) {
        return state.demos
    },
    isUploading(state) {
        return state.uploading;
    },
    getProcessStatus: (state) => (id) => {
        return process.find(p => p.project_id === id);
    },
    getAllProjects(state) {
        return state.allProjects
    }
}

// actions
const actions = {
    async fetchProjects(context, payload = null) {

        // get projects that are not inside folders
        const projects = await ProjectModel.list();

        // get projects within or or outside folders 
        const allProjects = await Folder.listSorted(payload);
        //const demos = await ProjectModel.demos();

        context.commit('loadProjects', projects.data.data.projects)
        context.commit('loadAllProjects', allProjects.data)
        context.commit('setProjectNavs', projects.data.links)
        context.commit('setProjectMetas', projects.data.meta)
        //context.commit( 'setDemos', demos.data.data.projects)
    },

    async addProject(context, payload) {
        await ProjectModel.store(payload);
        context.commit('addProject', payload);
    },

    async setSelectedProject(context, payload) {
        const project = await ProjectModel.get(payload.id);
        
        context.commit('setSelectedProject', project.data);
        if (payload.store != null) {
            
            payload.store.commit("pointclouds/setPointcloudsList", project.data.files);
        }
    },

    setSelectedProjectData(context, data) {
        context.commit('setSelectedProjectData', data);
    },

    async setProjectFiles(context, id) {
        const project = await ProjectModel.get(id);
        context.commit('setProjectFiles', project.data.files);
    },

    async setNewSequence(context, update) {
        context.commit('setNewSequence', update);
    },

    async urlRequest(context, url) {
        const projects = await ProjectModel.request(url)
        context.commit('loadProjects', projects.data.data.projects)
        context.commit('setProjectNavs', projects.data.links)
        context.commit('setProjectMetas', projects.data.meta)
    },

    updateProjectProcess(context, data) {
        context.commit('updateProjectProcess', data);
    },

    setUploadStatus(context, data) {
        context.commit('updateUploadStatus', data);
    },

    async fetchDemo(context, url) {
        const demos = await ProjectModel.demos();
        context.commit('setDemos', demos.data.data.projects)
    },
}

// mutations
const mutations = {
    addProject(state, project) {
        state.projects.push(project);
    },
    loadProjects(state, projects) {
        state.projects = projects;
    },
    loadAllProjects(state, allProjects) {
        state.allProjects = allProjects;
    },
    setSelectedProject(state, project) {
        state.selectedProject = [project]
    },
    setSelectedProjectData(state, data) {
        state.selectedProject = [data];
    },
    setProjectFiles(state, files) {
        state.projectFile = files;
    },
    setNewSequence(state, update) {
        state.projects = update;
    },
    setProjectNavs(state, navs) {
        state.navs = navs
    },
    setProjectMetas(state, metas) {
        state.metas = metas
    },
    setDemos(state, demos) {
        state.demos = demos;
    },
    updateProjectProcess(state, data) {
        state.projects.forEach((p, index) => {
            if (p.project_id === data.project_id) {
                state.projects[index].processes = [data];
                if (data.progress === 100) {
                    state.projects[index].processes = [];
                }
            }
        })
    },
    updateUploadStatus(state, data) {
        state.uploading = data;
    }

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
