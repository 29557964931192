

import authMixin from '@/mixins/Auth';
import { mapGetters, mapActions } from 'vuex'
import swal from 'sweetalert';

const permissions = {

    mixins: [authMixin],

    computed: {
        ...mapGetters('permissions', [
            'getAllPermissions'
        ]),
        ...mapGetters('projects', [
            'listProjects',
            'countProjects'
        ]),
        ...mapGetters('subscribe', ['subscriptionStatus']),
        ...mapGetters('usage', ['myUsage']),
        ...mapGetters('permissions', ['getPlanPermissions'])

    },

    methods: {
        ...mapActions('permissions', [
            'setUserPermissions'
        ]),
        hasUserPermission(permission) {
            // Todo: Check permission on permission store
            if (permission != '') {
                let user_permissions = this.getAllPermissions
                let permission_array = permission.split('|')
                if (permission_array.length > 0) {
                    for (let p of permission_array) {
                        let features = user_permissions.features
                        let access = user_permissions.access

                        if (features.length > 0) {
                            let match = features.find(f => f === p)
                            if (match) {
                                return true
                            }
                        }

                        if (access.length > 0) {
                            let match = access.find(a => a === p)
                            if (match) {
                                return true
                            }
                        }
                    }
                }
            }

            return false
        },
        hasProjectPermission(permission, tool = null) {
            // Todo: Check permission on permission store
            if (this.validateRole(false, tool)) {
                if (permission != '') {
                    let user_permissions = this.getAllPermissions
                    let permission_array = permission.split('|')

                    if (permission_array.length > 0) {

                        for (let p of permission_array) {

                            let features = user_permissions.parentFeatures
                            let access = user_permissions.parentAccess
                            //search features
                            if (features) {
                                if (features.length > 0) {
                                    let match = features.find(f => f === p)
                                    if (match) {
                                        return true
                                    }
                                }
                            }
                            //search access
                            if (access) {
                                if (access.length > 0) {
                                    let match = access.find(a => a === p)
                                    if (match) {
                                        return true
                                    }
                                }
                            }
                        }
                    }

                }
            }
            return false
        },
        allowToCreateProject() {
            if (this.subscriptionStatus.guest_details != null && this.subscriptionStatus.onGuestPlan) {
                //if user is an organization member
                if (this.subsStatus.guest_details.is_member == 1) {
                    if (this.subscriptionStatus.guest_details.role == 'Viewer') {
                        return false
                    } else {//editor && admins
                        var parent = this.subscriptionStatus.parent
                        var usage = parent.usage
                        if (usage.project_created >= usage.project_limit ||
                            Number(usage.current_month_bandwidth) > Number(usage.bandwidth_limit)) {
                            return false
                        }
                        return true
                    }
                }
                return this.subscriptionStatus.status
            } else {
                if (this.subscriptionStatus.plan !== undefined) {
                    if (this.myUsage.project_created < this.subscriptionStatus.plan.projects_per_month) {
                        if (Number(this.myUsage.current_month_bandwidth) < Number(this.myUsage.bandwidth_limit)) {
                            return true
                        } else {
                            return false
                        }
                    } else {
                        return false
                    }
                }
            }
        },
        validateInvitations(role, exist = false) {
            if (!exist) {
                if (role == 'Viewer') {
                    if (this.getPlanPermissions.viewers.used >= this.getPlanPermissions.viewers.max) {
                        return false
                    } else {
                        return true
                    }
                } else if (role == 'Editor') {
                    if (this.getPlanPermissions.editors.used >= this.getPlanPermissions.editors.max) {
                        return false
                    } else {
                        return true
                    }
                }
            } else {
                return true
            }
        },
        validateRole(isOwn = false, tool = null) {
            if (!isOwn) {
                if (this.subscriptionStatus !== null) {
                    if (tool == 'segment-tool') {
                        var gdetails = this.subscriptionStatus.guest_details
                        if (gdetails != null) {
                            if (gdetails.role == 'Viewer') {
                                return false
                            }
                        }
                    }
                    return true
                }
            }
            return true
        },
        showLimitReachSwal() {
            swal({
                title: this.$t('permissions.project_limit.title'),
                text: this.$t('permissions.project_limit.text'),
                icon: 'warning',
                buttons: {
                    catch: {
                        text: this.$t('viewer.process.upgrade_plan'),
                        value: "upgrade",
                    },
                    cancel: "OK",
                },
            })
                .then((value) => {
                    switch (value) {
                        case "upgrade":
                            this.$toast.show('Redirecting to billing page...', {
                                type: 'info',
                                position: 'top-right',
                                duration: 3000
                            });
                            window.location.href = window.location.origin + '/billing';
                            break;
                        default:
                    }
                });
        },
        showLimitedAccessSwal() {
            swal({
                title: this.$t('permissions.no_access_for_viewer.title'),
                text: this.$t('permissions.no_access_for_viewer.text'),
                icon: 'warning',
                buttons: {
                    catch: {
                        text: this.$t('viewer.process.upgrade_plan'),
                        value: "upgrade",
                    },
                    cancel: "OK",
                },
            })
                .then((value) => {
                    switch (value) {
                        case "upgrade":
                            this.$toast.show('Redirecting to billing page...', {
                                type: 'info',
                                position: 'top-right',
                                duration: 3000
                            });
                            window.location.href = window.location.origin + '/billing';
                            break;
                        default:
                    }
                });
        },
    },

    created() {
        /*
        this.getPermissions().then((permissions) => {
            if ( permissions.access && permissions.feature ) {
                this.setUserPermissions(permissions)
            }
        })
        */
    },

    setup() {
    }

}

export default permissions
