import * as Cesium from '@/sdk/cesium';

export default {
  hoveredPoint: null,

  /**
   * Listens for mouse move events and snaps to the nearest gcp point.
   * @param {Object} position - The mouse position object.
   * @param {Object} viewer - The Cesium viewer object.
   */
  mouseMoveSnapToPointListener(position, viewer) {
    // Perform a drill pick to get objects at the specified mouse position
    let pickedObjects = [];
    try {
      pickedObjects = viewer.scene.drillPick(position);
    }catch (error) {
      console.log("Invalid coordinates value!");
    }
    // Find the first picked object that is a GCP point
    const result = pickedObjects.find((e) => {
      if (e.id) {
        if (Cesium.defined(e.id.point) && e.id.snappablePoint) {
          return true;
        }
      }
    });

    // Get the entity if it exists, otherwise, set it to null
    const entity = result ? result.id : null;
    if (Cesium.defined(entity)) {
      // Update the appearance of the hovered point
      const point = entity.point;
      point.pixelSize = 11;
      point.outlineWidth = 3;

      // Set the hovered point
      this.hoveredPoint = entity;
    } else {
      // Clear the hovered point
      this.clearHoveredPoint();
    }
  },

  /**
   * Retrieves the position of the hovered point.
   * @returns {Object|null} - The position of the hovered point or null if no point is hovered.
   */
  getHoveredPointPosition() {
    if (this.hoveredPoint) {
      return this.hoveredPoint.originalXYZ.clone();
    }
    return null;
  },

  /**
   * Clears the appearance and reference to the hovered point.
   */
  clearHoveredPoint() {
    if (this.hoveredPoint) {
      // Restore the original appearance of the hovered point
      this.hoveredPoint.point.pixelSize = 8;
      this.hoveredPoint.point.outlineWidth = 1;

      // Clear the reference to the hovered point
      this.hoveredPoint = null;
    }
  }
};
