
import Model from '@/models/Subscribe'

const state = () => ({
    status: null
})
  
// getters
const getters = {
    subscriptionStatus(state) {
        return state.status
    }
}

// actions
const actions = {

    async getSubscriptionStatus(context) {
        const query = await Model.status()
        let status = query.data
        return new Promise((resolve) => {
            context.commit( 'setStatus', status)
            resolve(status)
        })
    },
}

// mutations
const mutations = {
    setStatus(state, status) {
        state.status = status
    }
}
  
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}