import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill", "stroke"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(), _createElementBlock("svg", {
      width: $props.width,
      height: $props.height,
      viewBox: "0 0 18 20",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg"
    }, [
      _createElementVNode("path", {
        d: "M15.7001 17.1999V17.6999H16.2001C16.5867 17.6999 16.9001 18.0133 16.9001 18.3999C16.9001 18.7865 16.5867 19.0999 16.2001 19.0999H12.4001C12.124 19.0999 11.9001 18.876 11.9001 18.5999V15.9999C11.9001 15.061 11.139 14.2999 10.2001 14.2999H7.8001C6.86121 14.2999 6.1001 15.061 6.1001 15.9999V18.5999C6.1001 18.876 5.87624 19.0999 5.6001 19.0999H1.8001C1.4135 19.0999 1.1001 18.7865 1.1001 18.3999C1.1001 18.0133 1.4135 17.6999 1.8001 17.6999H2.3001V17.1999V2.7999C2.3001 1.75056 3.15076 0.899902 4.2001 0.899902H13.8001C14.8494 0.899902 15.7001 1.75056 15.7001 2.7999V17.1999ZM5.4001 3.4999H4.9001V3.9999V6.3999V6.8999H5.4001H7.8001H8.3001V6.3999V3.9999V3.4999H7.8001H5.4001ZM8.3001 8.7999V8.2999H7.8001H5.4001H4.9001V8.7999V11.1999V11.6999H5.4001H7.8001H8.3001V11.1999V8.7999ZM10.2001 3.4999H9.7001V3.9999V6.3999V6.8999H10.2001H12.6001H13.1001V6.3999V3.9999V3.4999H12.6001H10.2001ZM13.1001 8.7999V8.2999H12.6001H10.2001H9.7001V8.7999V11.1999V11.6999H10.2001H12.6001H13.1001V11.1999V8.7999Z",
        fill: $props.color,
        stroke: $props.color
      }, null, 8, _hoisted_2)
    ], 8, _hoisted_1))
  ]))
}