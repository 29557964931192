
import DownloadResources from '@/models/Downloads';

export default {
    name: 'downloads',
    data() {
        return {
            downloadDone: false
        };
    },
    computed: {},
    methods: {
        checkToken() {
            let token = this.$route.params.token;
            if (token !== '' && token != undefined && token !== null){
                
                DownloadResources.download(token).then((response) => {
         
                    window.open(response.data, '_blank');
                
                    //route back to home page
                    window.location.href = window.location.origin;
                });

            }
        },
        download(link) {
            var element = document.createElement('a');
            element.setAttribute('href', link);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        }
    },
    mounted() {
        //this.checkToken();
    },
    watch: {
        '$route.params.token': {
            handler: function (val) {
                if (val !== '' && val != undefined && val !== null) {
                    DownloadResources.download(val)
                        .then((response) => {
                            //window.open(response.data, '_blank');
                            this.download(response.data);
                            this.$router.push({ path: '/' });
                            //window.location.href = window.location.origin;
                            //route back to home page
                        })
                        .catch((error) => {
                            //Return to home page on error
                            window.location.href = window.location.origin;
                        });
                }
            },
            deep: true,
            immediate: true
        }
    }
};
