
import * as Cesium from '@/sdk/cesium';

export class TransformClassifierBox {

    constructor(viewer, tileset) {
        this.viewer = viewer;
        this.tileset = tileset;
        this.boxPrimitive = null;
        this.classificationPrimitive = null;
        this.transformEditor = null;
        this.boundingSphere = null;
    }

    activate(){
        // let centerPosition = Cesium.Cartesian3.midpoint(
        //     positionsDrawingLine[0],
        //     positionsDrawingLine[1],
        //     new Cesium.Cartesian3()
        // );
        // let modelMatrix = Cesium.Transforms.eastNorthUpToFixedFrame(centerPosition);


        let tileset = this.tileset;

        let modelMatrix = tileset.modelMatrix;

        this.boundingSphere = tileset.boundingSphere.center;

        const geometry = Cesium.BoxGeometry.fromDimensions({
            dimensions: new Cesium.Cartesian3(32.0, 20.0, 32.0),
            vertexFormat: Cesium.VertexFormat.POSITION_AND_NORMAL
        });

        this.boxPrimitive = this.viewer.scene.primitives.add(
            new Cesium.Primitive({
                geometryInstances: new Cesium.GeometryInstance({
                    geometry: geometry,
                    modelMatrix: modelMatrix,
                    attributes: {
                        color: Cesium.ColorGeometryInstanceAttribute.fromColor(
                            Cesium.Color.RED.withAlpha(0.4)
                        )
                    }
                }),
                appearance: new Cesium.PerInstanceColorAppearance()
            })
        );

        // Use the box geometry with the current modelMatrix
        // as a classification primitive
        this.classificationPrimitive = this.viewer.scene.primitives.add(
            new Cesium.ClassificationPrimitive({
                geometryInstances: new Cesium.GeometryInstance({
                    geometry: geometry,
                    modelMatrix: modelMatrix,
                    attributes: {
                        color: Cesium.ColorGeometryInstanceAttribute.fromColor(
                            new Cesium.Color(0.0, 0.0, 1.0, 0.7)
                        ),
                        show: new Cesium.ShowGeometryInstanceAttribute(true)
                    }
                }),
                classificationType: Cesium.ClassificationType.CESIUM_3D_TILE
            })
        );

        this.transformEditor = new Cesium.TransformEditor({
            container: this.viewer.container,
            scene: this.viewer.scene,
            transform: this.boxPrimitive.modelMatrix,
            boundingSphere: this.boundingSphere,
            pixelSize: 100,
            maximumSizeInMeters: Infinity,
        });

        this.transformEditor.viewModel.activate();


    }
}