

const config = {
    environment: 'production',
    segmentWriteKey: 'LOEThTPrsVFSEQ8vo9HT46yEVrwLgGXt',
    release: 'v1.17.15',

    cookie_name: 'scx_token',

    env: {
        development: {
            broadcastUrl: 'http://localhost:6001',
            apiUrl: 'http://localhost/api',
            tileSetUrl: 'http://localhost:8002/static',
            storageUrl: 'http://localhost/storage',
            terrainUrl: 'http://localhost:8002/',
            shareUrl: 'http://localhost:8080/share',
            s3Url: 'https://scanx-storage-v2-dev-ap-northeast-1.s3.ap-northeast-1.amazonaws.com'
        },
        production: {
            broadcastUrl: 'https://echo.prod.cloud.scanx.jp',
            apiUrl: 'https://api.prod.cloud.scanx.jp/api',
            tileSetUrl: 'https://asset.prod.cloud.scanx.jp/static',
            storageUrl: 'https://api.prod.cloud.scanx.jp/storage',
            terrainUrl: 'https://asset.prod.cloud.scanx.jp/',
            shareUrl: 'https://appv2.scanx.jp/share',
            s3Url: 'https://scanx-storage-v2-prod-ap-northeast-1.s3.ap-northeast-1.amazonaws.com'
        },
        nonprod: {
            broadcastUrl: 'https://echo.dev.cloud.scanx.jp',
            apiUrl: 'https://api.dev.cloud.scanx.jp/api',
            tileSetUrl: 'https://asset.dev.cloud.scanx.jp/static',
            storageUrl: 'https://api.dev.cloud.scanx.jp/storage',
            terrainUrl: 'https://asset.dev.cloud.scanx.jp/',
            shareUrl: 'https://appv2.dev.cloud.scanx.jp/share',
            s3Url: 'https://scanx-storage-v2-dev-ap-northeast-1.s3.ap-northeast-1.amazonaws.com'
        },
        localtodevserver: {
            broadcastUrl: 'http://api.dev.scanx.jp:6001',
            apiUrl: 'http://api.dev.scanx.jp/api',
            tileSetUrl: 'http://api.dev.scanx.jp:8002/static',
            storageUrl: 'http://api.dev.scanx.jp/storage',
            terrainUrl: 'http://api.dev.scanx.jp:8002/',
            shareUrl: 'https://scanx2.dev.cloud.scanx.jp/share',
            s3Url: 'https://scanx-storage-v2-dev-ap-northeast-1.s3.ap-northeast-1.amazonaws.com'
        },
        laravel10: {
            broadcastUrl: 'https://echo-laravel10.dev.cloud.scanx.jp',
            apiUrl: 'https://api-laravel10.dev.cloud.scanx.jp/api',
            tileSetUrl: 'https://asset-laravel10.dev.cloud.scanx.jp/static',
            storageUrl: 'https://api-laravel10.dev.cloud.scanx.jp/storage',
            terrainUrl: 'https://asset-laravel10.cloud.scanx.jp/',
            shareUrl: 'https://appv2-laravel10.dev.cloud.scanx.jp/share',
            s3Url: 'https://scanx-storage-v2-dev-ap-northeast-1.s3.ap-northeast-1.amazonaws.com',
            copilotUrl: 'https://copilot-ui.dev.cloud.scanx.jp/'
        }
    },

    enableProduction() {
        this.environment = 'production';
    },

    get() {
        if (this.environment === 'production') {
            return this.env.production;
        } else if (this.environment === 'development') {
            return this.env.development;
        } else if (this.environment === 'localtodevserver') {
            return this.env.localtodevserver;
        } else if (this.environment === 'nonprod') {
            return this.env.nonprod;
        } else if (this.environment === 'laravel10') {
            return this.env.laravel10;
        }
    }
};

export default config;
