
import { mapGetters, mapActions } from 'vuex'


import ConfigProvider from '@/config'
import api from '@/providers/api'

const url = {
    data() {
        return {
        }
    },
    computed: {
    },
    methods: {
        request_url(url) {
            return api.request(url);
        },
    }
}

export default url
