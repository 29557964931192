/**
 * broadcast.js  is the one listening for the events from the backend. when you
 * login you are subscribing to a public and private channel.
 * the purpose of the echo server to communicate between backend and frontend.
 */

import Echo from 'laravel-echo';
import socket_io from 'socket.io-client';
import config from '@/config';

export class BroadcastProvider {

    echo = null;
    user_id = null;


    constructor( token ) {
        this.authToken = token
        this.init();
        console.log('[broadcast.js]: Created broadcast provider object');
    }

    setUser( user_id ) {
        this.user_id = user_id
    }

    setAuthToken( token ) {
        this.authToken = token
    }

    init() {
        //config.enableProduction();
        
        console.info( '[broadcast.js]: BroadcastProvider.init()', config.get().broadcastUrl );
        let echo_config = {
            broadcaster: 'socket.io',
            host: config.get().broadcastUrl,
            namespace: '',
            client: socket_io,
        };

        if ( this.authToken !== '' ) {
            echo_config.auth = {
                headers: {
                    authorization: `Bearer ${this.authToken}`
                }
            }
        }

        this.echo = new Echo( echo_config );
    }

    leaveChannels(filter=''){
        let channels = this.echo.connector.channels;
        for(var channel in channels){
            if (filter == ''){
                this.echo.leave(channel);
                console.log("Leaving Channel",channel);
            }else if(channel.includes(filter)){
                this.echo.leave(channel);
                console.log("Leaving Channel",channel);
            }
        }

    }

    channel( _channel ) {
        console.log('Subscribing to public channel ' + _channel);
        return this.echo.channel( _channel );
    }

    pchannel( _channel ) {
        console.log('Subscribing to private channel ' + _channel);
        return this.echo.private( _channel );
    }

}
