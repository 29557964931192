const state = () => ({
    profile: null
})
  
// getters  
const getters = {
    profile(state) {
        if (state.profile) {
            return state.profile
        }
        return false
    }
}

// actions
const actions = {
    async setProfile(context, profile) {
        context.commit( 'setProfile', profile )
    }
}

// mutations
const mutations = {
    setProfile(state, profile) {
        state.profile = profile
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}