//This file is automatically rebuilt by the Cesium build process.
export default "/**\n\
 * @name czm_depthRangeStruct\n\
 * @glslStruct\n\
 */\n\
struct czm_depthRangeStruct\n\
{\n\
    float near;\n\
    float far;\n\
};\n\
";
