
// Uses axios and Promises
// Everything that we need to communicate with the api will be declared here
import api from '@/providers/api'

const invites = {

    get(id) {
        return api.get(`invites/get/${id}`)
    },

    list() {
        return api.get('invites/list')
    },

    store(payload) {
        return api.post('invites/create', payload);
    },

    update(payload) {
        return api.put(`invites/update/${payload.invites_id}`, payload);
    },

    delete(id) {
        return api.delete(`invites/destroy/${id}`);
    },

    accept(payload) {
        return api.post('invites/accept', payload);
    }
}

export default invites