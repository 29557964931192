/**
 * Manual Classification Cross Section
 */

import {  Transforms, Matrix4, Ellipsoid,Cartesian3,
    ScreenSpaceEventType, ScreenSpaceEventHandler, ArcType,
    Color, CallbackProperty, EllipsoidTerrainProvider, Plane,
    Entity, Cartesian2 , Cartographic, Math as CesiumMath,
    ClippingPlane, ClippingPlaneCollection, IntersectionTests,
    defined as CesiumDefined, Matrix3, BoxGeometry, VertexFormat,
    PrimitiveCollection, DebugModelMatrixPrimitive, ClassificationPrimitive,
    PerInstanceColorAppearance, ColorGeometryInstanceAttribute,
    ShowGeometryInstanceAttribute, ClassificationType, GeometryInstance
} from '@/sdk/cesium';

export class ManualClassificationCrossSection {
    constructor(viewer, options, $this, args) {
        // disable base map & 3d terrain in viewer
        this.viewer = viewer;
        this.options = options;
        this.refs = this.options.refs
        this.$this = $this;
        this.$this.clear();
        this.handler = new ScreenSpaceEventHandler(this.viewer.scene.canvas);
        // this.handler = args._handler;
        this.primitivesMapperForMCCS = args.primitivesMapperForMCCS;
        this.manualClassificationCrossSection = args.manualClassificationCrossSection;
        this.manualClassificationCrossSectionResult = args.manualClassificationCrossSectionResult;


        let settings = this.options.settings
        this.viewer.scene.globe.show = false;
        this.viewer.terrainProvider = new EllipsoidTerrainProvider();
        // update sidebar settings
        settings.enableMap = false;
        settings.enableTerrain3d = false;
        this.refs.cesiumRef.$emit('on-update-settings', settings);
        this.positions = [];
        this.positionsDrawingLine = [];
        this.positionsOrthogonalLine = [];
        this.profileWidthLine = [];

        this.drawingLine = false;
        this.profileWidth = Number(this.options.profileWidth);
        this.classification = this.options.selectedClass;
        this.lineVector = new Cartesian3();
        this.mode = 'init';
        this.mousePosition = null;
        this.planeCrossSection;
        this.invNEU;
        this.transform;
        this.clippingPlanes;
        this.ellipsoidNormal;
        this.cameraVector;
        this.planeEntity;

        // Note: needs to be arrow function to extend the binding
        this.viewer.entities.add({
            name: 'cross section line',
            id: 'lineEntity',
            polyline: {
                positions: new CallbackProperty(() => {
                    return this.positions;
                }, false),
                material: Color.ORANGERED,
                depthFailMaterial: Color.ORANGERED,
                width: 4,
                clampToGround: false,
                zIndex: 100,
                arcType: ArcType.GEODESIC
            }
        });
        this.viewer.entities.add({
            name: 'drawer on cross section',
            id: 'lineDrawing',
            polyline: {
                positions: new CallbackProperty(() =>  {
                    return this.positionsDrawingLine;
                }, false),
                material: Color.YELLOW,
                depthFailMaterial: Color.YELLOW,
                width: 2,
                clampToGround: false,
                arcType: ArcType.NONE
            }
        });
        this.profileWidthLineEnity = this.viewer.entities.add({
            name: 'profile width line',
            id: 'profileWidthLine',
            polyline: {
                positions: new CallbackProperty(() =>  {
                    return this.profileWidthLine;
                }, false),
                material: Color.ORANGERED,
                depthFailMaterial: Color.ORANGERED,
                width: 4,
                clampToGround: false,
                zIndex: 100,
                arcType: ArcType.NONE
            }
        });

        // this.viewer.screenSpaceEventHandler.removeInputAction(ScreenSpaceEventType.WHEEL);
        this.viewer.scene.screenSpaceCameraController.enableZoom = true;
        this.viewer.scene.screenSpaceCameraController.enableTilt = false;

        this._topView(this.viewer, this.options.tileset[0], this.options.previousPosition);

        this.handler.setInputAction((movement) => {
            let cartesian = this.$this.getCartesian3FromPX(movement.position);
            if (cartesian && cartesian.x) {
                // When drawing the section line
                let target = this.$this.getCartesian3FromPX(movement.position, true);

                if (this.mode === 'init' && target.isOn3dtiles) {// Only allow selection on point cloud
                    this._initLeftClick(cartesian);
                    // testPos.push(cartesian);
                    // if (testPos.length >= 2) {
                    //     this.viewer.entities.add({
                    //         name: 'test',
                    //         id: 'test line',
                    //         polyline: {
                    //             positions: testPos,
                    //             material: Color.ORANGERED,
                    //             depthFailMaterial: Color.ORANGERED,
                    //             width: 4,
                    //             clampToGround: false,
                    //             zIndex: 100,
                    //             arcType: ArcType.GEODESIC
                    //         }
                    //     });
                    // }
                } else if (this.mode === 'width') {
                    this._selectProfileWidth();
                }
                // When drawing during section view
                else if (this.mode === 'section') {
                    this._selectArea(movement, cartesian)
                }
            }
        }, ScreenSpaceEventType.LEFT_CLICK);

        this.viewer.screenSpaceEventHandler.removeInputAction(ScreenSpaceEventType.LEFT_DOUBLE_CLICK);

        // mouse move
        this.handler.setInputAction((movement) => {
            //when drawing the profile path
            if (this.mode === 'init') {
                this._initMouseMove(movement);
            }
            else if (this.mode === 'width') {
                this._moveWidth(movement);
            }
            else if (this.mode === 'section') {
                this._moveArea(movement);
            }
        }, ScreenSpaceEventType.MOUSE_MOVE);

        // right
        this.handler.setInputAction((movement) => {
                if(this.mode === 'init') {
                    this.viewer.scene.screenSpaceCameraController.enableInputs = true;
                }
                else {
                    this.viewer.scene.screenSpaceCameraController.enableInputs = false;
                }
            }, ScreenSpaceEventType.LEFT_UP);
    }

    _topView(viewer, tileset, previousPosition) {
        let cam_position = Cartographic.fromCartesian(
            previousPosition ? previousPosition : tileset.boundingSphere.center);
        let _pos = {
            x: CesiumMath.toDegrees(cam_position.longitude),
            y: CesiumMath.toDegrees(cam_position.latitude),
            z: tileset.boundingSphere.radius + cam_position.height
        };
        viewer.camera.setView({
            destination: Cartesian3.fromDegrees(_pos.x, _pos.y, _pos.z)
        });
        // Switch to 3D orthographic view

        // this.viewer.camera.switchToOrthographicFrustum(); // Add this line when the cesium JS is updated to 1.7.1
    }

    _initLeftClick(cartesian) {

        // add points which first fixed point and the moving point
        if (this.positions.length === 0) {
            this.positions.push(cartesian.clone());
            this.positions.push(cartesian.clone());
            this.positionsOrthogonalLine.push(cartesian.clone());
            this.positionsOrthogonalLine.push(cartesian.clone());

            this.profileWidthLine.push(cartesian.clone());
            this.profileWidthLine.push(cartesian.clone());
            this.profileWidthLine.push(cartesian.clone());
            this.profileWidthLine.push(cartesian.clone());
            this.profileWidthLine.push(cartesian.clone());
        }
        // add second fixed point
        else {
            this.positions.push(cartesian.clone());
            this.midPosition = Cartesian3.midpoint(this.positions[0], this.positions[1], new Cartesian3());
            this.transform = Transforms.eastNorthUpToFixedFrame(this.midPosition);
            this.invNEU = Matrix4.inverseTransformation(this.transform, new Matrix4());
            // lineVector is projected vector to the plane that is vertical of the ellipsoidNormal
            this.lineVector = Cartesian3.subtract(this.positions[1], this.positions[0], new Cartesian3());
            this.lineVector = this._getProjectedVector(this.midPosition, this.lineVector);
            this.ellipsoidNormal = Ellipsoid.WGS84.geodeticSurfaceNormal(this.midPosition, new Cartesian3());

            this.cameraVector = Cartesian3.cross(this.ellipsoidNormal, this.lineVector, new Cartesian3());
            Cartesian3.normalize(this.cameraVector, this.cameraVector);
            this.options.callback({'previousPosition': this.midPosition})

            this.mode = 'width';
        }
    }

    _initMouseMove(movement) {
        if (this.positions.length >= 1 && this.positions.length <= 2) {

            let cartesian = this.$this.getCartesian3FromPX(movement.endPosition);
            if (cartesian && cartesian.x) {
                this.positions.pop();
                this.positions.push(cartesian);

                const tempOrthogonalPoints = this._getOrthogonalPoints(this.positions[0], cartesian, this.profileWidth);
                this.positionsOrthogonalLine.pop();
                this.positionsOrthogonalLine.pop();
                this.positionsOrthogonalLine.push(tempOrthogonalPoints.right);
                this.positionsOrthogonalLine.push(tempOrthogonalPoints.left);

                this.mousePosition = movement.endPosition;
            }
        }
    }

    _getProjectedVector(position, vector) {
        const upNormal = Ellipsoid.WGS84.geodeticSurfaceNormal(position, new Cartesian3());
        Cartesian3.normalize(upNormal, upNormal);

        const horizontalPlane = new Plane(upNormal, 0.0);

        let projectedVector = Plane.projectPointOntoPlane(horizontalPlane, vector);

        Cartesian3.normalize(projectedVector, projectedVector);

        return projectedVector;
    }

    _selectProfileWidth() {
        // calculate the distance between first clicked point and the first corner of the profile line
        this.profileWidth = Cartesian3.distance(this.positions[0], this.profileWidthLine[0]);
        const sectionViewWidth = Cartesian3.distance(this.positions[0], this.positions[1]);

        // 40 meters default
        let cameraOffset = Cartesian3.multiplyByScalar(this.cameraVector, sectionViewWidth * 0.5, new Cartesian3());
        const cameraPosition = Cartesian3.subtract(this.midPosition, cameraOffset, new Cartesian3());

        let cameraView = {
            destination: cameraPosition,
            orientation: {
                direction: this.cameraVector,
                up: this.ellipsoidNormal
            }
        };
        this.viewer.camera.setView(cameraView);
        this.manualClassificationCrossSection.cameraView = cameraView;
        this.manualClassificationCrossSection.enable = true;
        // limit camera rotation based on the camera axis
        this.viewer.camera.constrainedAxis = cameraPosition;
        this.viewer.camera.switchToOrthographicFrustum();
        this._controlCamera(this.viewer, false);

        // Crop the pointcloud based on the cross-section width
        let verticalDistance = Cartesian3.distance(this.positions[0], this.positions[this.positions.length - 1]);
        this._cropPointCloud(this.viewer, this.cameraVector, this.profileWidth*2, this.positions, verticalDistance, this.lineVector);

        // move the this.cameraVector to the plane coordinate system
        // the entity's position is eastNorthUpToFixedFrame, so I need to adjust the cameraVector of planeCrossSection with that. That's why I need to inverse.
        let planeVector = Matrix4.multiplyByPointAsVector(this.invNEU, this.cameraVector, new Cartesian3());
        Cartesian3.normalize(planeVector, planeVector);
        this.planeCrossSection = new Plane(planeVector, 0.0);
        this.planeEntity = this.viewer.entities.add(new Entity(
            {
                id: 'planeEntityForCrossSection',
                plane: {
                    dimensions: new Cartesian2(50000, 50000),
                    material: Color.WHITE.withAlpha(0.01),
                    plane: this.planeCrossSection
                },
                position: this.midPosition,
                // show: false
            }));

        // Reset the camera
        this.positions = [];
        this.positionsOrthogonalLine = [];
        this.viewer.entities.remove(this.profileWidthLineEnity);
        this.refs.processRef.updateButton();
        this.mode = 'section';
    }

    _cropPointCloud(viewer, cameraVector, width, verticalPositions, verticalDistance, lineVector) {
        /** Create new clipping plane for the front & back of the tileset */
        const midpoint = Cartesian3.midpoint(
            this.positionsOrthogonalLine[0],
            this.positionsOrthogonalLine[1],
            new Cartesian3());
        let normalVector = Cartesian3.normalize(
            this.positionsOrthogonalLine[0],
            new Cartesian3());
        let transform = Transforms.eastNorthUpToFixedFrame(normalVector);
        let inverse = Matrix4.inverseTransformation(
            transform,
            new Matrix4());
        let newVector = Matrix4.multiplyByPointAsVector(
            inverse,
            cameraVector,
            new Cartesian3());
        Cartesian3.normalize(newVector, newVector);

        /** Create new clipping plane for the left & back of the tileset */
        const backClippingPlane = new ClippingPlane(newVector, width / 2);
        const negate = Cartesian3.negate(newVector, new Cartesian3());
        const frontClippingPlane = new ClippingPlane(negate, width / 2);

        let normalVerticalVector = Cartesian3.normalize(
            verticalPositions[0],
            new Cartesian3());
        let transformVertical = Transforms.eastNorthUpToFixedFrame(normalVerticalVector);
        let inverseVertical = Matrix4.inverseTransformation(
            transformVertical,
            new Matrix4());
        let newVerticalVector = Matrix4.multiplyByPointAsVector(
            inverseVertical,
            lineVector,
            new Cartesian3());
        Cartesian3.normalize(newVerticalVector, newVerticalVector);

        const leftSideClippingPlane = new ClippingPlane(newVerticalVector, verticalDistance / 2);
        const negateVertical = Cartesian3.negate(newVerticalVector, new Cartesian3());
        const rightSideClippingPlane = new ClippingPlane(negateVertical, verticalDistance / 2);

        /** Add the clipping planes to tileset */
        this.clippingPlanes = new ClippingPlaneCollection({
            planes: [
                frontClippingPlane,
                backClippingPlane,
                leftSideClippingPlane,
                rightSideClippingPlane
            ],
            edgeWidth: 0.0,
            edgeColor: Color.WHITE,
            unionClippingRegions: true,
        });
        this.options.tileset[0].clippingPlanes = this.clippingPlanes;

        /** Re-center position of clipping planes to the midpoint of the orthogonal line */
        let clipping = this.options.tileset[0].clippingPlanes;
        let tileset = this.options.tileset[0];
        let inverseReference = Matrix4.inverse(
            tileset.clippingPlanesOriginMatrix,
            new Matrix4());
        clipping.modelMatrix = Matrix4.multiply(
            inverseReference,
            Transforms.eastNorthUpToFixedFrame(midpoint),
            new Matrix4());
    }

    _controlCamera(viewer, onoff = false) {
        const cameraController = viewer.scene.screenSpaceCameraController;
        cameraController.enableLook = onoff;
        cameraController.enableRotate = onoff;
        // cameraController.enableZoom = onoff;
        cameraController.enableTilt = onoff;
        // cameraController.enableTranslate = onoff;
        // cameraController.enableInputs = onoff;
        if (!onoff) {
            cameraController.inertiaSpin = 0;
            cameraController.tiltEventTypes = undefined;
            viewer.screenSpaceEventHandler.removeInputAction(ScreenSpaceEventType.LEFT_UP);
            viewer.screenSpaceEventHandler.removeInputAction(ScreenSpaceEventType.LEFT_CLICK);
        }
        else {
            // Put back the default values
            cameraController.inertiaSpin = 0.9;
            cameraController.tiltEventTypes = [2, 4, { 'eventType': 0, 'modifier': 1 }, { 'eventType': 1, 'modifier': 1 }];
        }
    }

    _moveWidth(movement) {
        const plane = Plane.fromPointNormal(this.positions[0], this.ellipsoidNormal);
        const ray = this.viewer.camera.getPickRay(movement.endPosition);
        const hitPoint = IntersectionTests.rayPlane(ray, plane);

        this.profileWidthLine[0] = this._lineFormula(
            {
                first: this.positions[0],
                hitPoint: hitPoint
            },
            this.cameraVector, false);

        this.profileWidthLine[1] = this._lineFormula(
            {
                first: this.positions[1],
                hitPoint: hitPoint
            },
            this.cameraVector, false);

        this.profileWidthLine[2] = this._lineFormula(
            {
                first: this.positions[1],
                hitPoint: hitPoint
            },
            this.cameraVector, true);

        this.profileWidthLine[3] = this._lineFormula(
            {
                first: this.positions[0],
                hitPoint: hitPoint
            },
            this.cameraVector, true);

        this.profileWidthLine[4] = this.profileWidthLine[0];
    }

    /**
     * Get the point that pass through the line which is parallel to the ortho vector,
     * and the point is where you can get by projecting the moving point to the given ortho vector.
     * @param points
     * @param vectors
     * @param opposite
     * @returns {*}
     */
    _lineFormula(
        points = {
            first: null,// Static point where it was clicked.
            hitPoint: null
        },// Moving point where the mouse cursor is.
        vectors,
        opposite = false // If true, the point will be on the opposite side of the ortho vector.
    ) {

        const vectorOnPlane = Cartesian3.subtract(points.first, points.hitPoint, new Cartesian3());
        const dotProductVectorOnPlane = Cartesian3.dot(vectorOnPlane, vectors);
        const bottomNumber = Cartesian3.magnitude(vectors) * Cartesian3.magnitude(vectors);
        let constantK = (dotProductVectorOnPlane / bottomNumber);

        let isGreaterOrEqual90 = Cartesian3.dot(vectors, vectorOnPlane) <= 0;
        if (opposite) {
            isGreaterOrEqual90 = !isGreaterOrEqual90;
        }
        if (!isGreaterOrEqual90) {
            constantK = -constantK;
        }

        const multipliedOrthoVector = Cartesian3.multiplyByScalar(vectors, constantK, new Cartesian3());
        return Cartesian3.add(points.first, multipliedOrthoVector, new Cartesian3());
    }

    _moveArea(movement) {
        this.viewer.scene.pickTranslucentDepth = true;
        this.viewer.scene.render();
        let cartesian = this.viewer.scene.pickPosition(movement.endPosition) // getCoordinateOnPlane(this.viewer, movement.endPosition);
        this.viewer.scene.pickTranslucentDepth = false;
        if (this.positionsDrawingLine.length >= 1) {
            if (CesiumDefined(cartesian)) {
                const cornerPoints = this._getCornerCoordinateFromSquare(this.viewer,
                    this.positionsDrawingLine[0], cartesian);

                this.positionsDrawingLine.pop();
                this.positionsDrawingLine.pop();
                this.positionsDrawingLine.pop();
                this.positionsDrawingLine.pop();
                this.positionsDrawingLine.push(cornerPoints.upperCorner);
                this.positionsDrawingLine.push(cartesian);
                this.positionsDrawingLine.push(cornerPoints.lowerCorner);
                this.positionsDrawingLine.push(this.positionsDrawingLine[0]);
            }
        }
    }

    _getCornerCoordinateFromSquare(viewer, staticPoint, movingPoint) {
        let upperCorner, lowerCorner;
        let horizontalVector, verticalVector;
        // const upNormal = Ellipsoid.WGS84.geodeticSurfaceNormal(staticPoint, new Cartesian3());
        // const horizontalPlane = new Plane(upNormal, 0.0);
        // horizontalVector = Cartesian3.subtract(movingPoint, staticPoint, new Cartesian3());
        // horizontalVector = Plane.projectPointOntoPlane(horizontalPlane, horizontalVector);
        // upperCorner = Cartesian3.add(staticPoint, horizontalVector, new Cartesian3());
        // verticalVector = Cartesian3.subtract(movingPoint, upperCorner, new Cartesian3());
        // lowerCorner = Cartesian3.add(staticPoint, verticalVector, new Cartesian3());
        staticPoint = Cartographic.fromCartesian(staticPoint);
        movingPoint = Cartographic.fromCartesian(movingPoint);

        if (staticPoint.height >= movingPoint.height) {
            upperCorner = Cartesian3.fromRadians(
                movingPoint.longitude,
                movingPoint.latitude,
                staticPoint.height);
            lowerCorner = Cartesian3.fromRadians(
                staticPoint.longitude,
                staticPoint.latitude,
                movingPoint.height);
        } else {
            upperCorner = Cartesian3.fromRadians(
                staticPoint.longitude,
                staticPoint.latitude,
                movingPoint.height);
            lowerCorner = Cartesian3.fromRadians(
                movingPoint.longitude,
                movingPoint.latitude,
                staticPoint.height);
        }
        return {
            upperCorner: upperCorner,
            lowerCorner: lowerCorner
        };
    }

    _selectArea(movement, cartesian) {
        const pos = movement.position;
        const ray = this.viewer.camera.getPickRay(pos);
        const projectedPoint = this._getProjectedPoint(ray, this.midPosition, this.planeCrossSection);

        // First click
        if (this.positionsDrawingLine.length <= 1 && !this.drawingLine) {

            this.positionsDrawingLine.push(projectedPoint.clone());
            this.positionsDrawingLine.push(projectedPoint.clone());

            this.positionsDrawingLine.push(projectedPoint.clone());
            this.positionsDrawingLine.push(projectedPoint.clone());
            this.positionsDrawingLine.push(projectedPoint.clone());
            this.drawingLine = true;
        }
        // Second click
        else if (this.drawingLine) {
            const distances = this._getHorizontalAndVerticalDistanceByVectors(
                this.positionsDrawingLine[0],
                cartesian.clone(),
                this.planeCrossSection,
                this.ellipsoidNormal,
                this.lineVector);

            this.classification = this.manualClassificationCrossSection.Class;
            this._addCuboid(
                this.viewer,
                {
                    point1: this.positionsDrawingLine[0].clone(),
                    point2: cartesian.clone()
                },
                this.primitivesMapperForMCCS,
                {
                    width: distances.horizontal,
                    depth: this.profileWidth * 2,
                    height: distances.vertical
                },
                this.classification,
                this.manualClassificationCrossSectionResult
            );

            this.positionsDrawingLine = [];
            this.drawingLine = false;
        }
    }

    _getProjectedPoint(ray, planePosition, plane) {
        const transform = Transforms.eastNorthUpToFixedFrame(planePosition);
        const invNEU = Matrix4.inverseTransformation(
            transform,
            new Matrix4()
        );
        ray.origin = Matrix4.multiplyByPoint(invNEU, ray.origin, ray.origin);
        ray.direction = Matrix4.multiplyByPointAsVector(
            invNEU,
            ray.direction,
            ray.direction
        );
        let projectedPoint = IntersectionTests.rayPlane(ray, plane);
        // Transform the intersection point from the space
        // of the plane into the world space
        projectedPoint = Matrix4.multiplyByPoint(
            transform,
            projectedPoint,
            projectedPoint
        );
        return projectedPoint;
    }

    _getHorizontalAndVerticalDistanceByVectors(point1, point2, plane, upNormal, lineNormal) {
        let horizontalDistance, verticalDistance;
        const vector = Cartesian3.subtract(point2, point1, new Cartesian3());
        const dot1 = Cartesian3.dot(vector, lineNormal);
        const magnitudeLineNormal = Cartesian3.magnitude(lineNormal, new Cartesian3());

        horizontalDistance = Math.abs(dot1 / magnitudeLineNormal);

        // test2: Calculate distance of the vertical projected vector
        const dot2 = Cartesian3.dot(vector, upNormal);
        const magnitudeUpNormal = Cartesian3.magnitude(upNormal, new Cartesian3());
        verticalDistance = Math.abs(dot2 / magnitudeUpNormal);
        return {
            horizontal: horizontalDistance,
            vertical: verticalDistance
        }
    }

    _addCuboid(viewer,
               positions,
               primitivesMapper,
               boxSize,
               selectedClassification,
               manualClassificationCrossSectionResult) {

        // add the primitives on the 500 ~ 599
        primitivesMapper.counter = viewer.scene.primitives.length;

        //https://cesium.com/learn/ion-sdk/ref-doc/Matrix4.html?classFilter=matrix#.inverseTransformation
        // https://en.wikipedia.org/wiki/Row-_and_column-major_order

        let centerPosition = Cartesian3.midpoint(positions.point1.clone(), positions.point2.clone(),
            new Cartesian3());

        // lineVector is projected vector to the plane that is vertical of the ellipsoidNormal
        let secondVector = Cartesian3.cross(this.ellipsoidNormal, this.lineVector, new Cartesian3());

        const rot = Matrix3.fromArray([
            this.lineVector.x, this.lineVector.y, this.lineVector.z,
            secondVector.x, secondVector.y, secondVector.z,
            this.ellipsoidNormal.x, this.ellipsoidNormal.y, this.ellipsoidNormal.z
        ]);

        const modelMatrix = Matrix4.fromRotationTranslation(rot, centerPosition);
        const geometry = BoxGeometry.fromDimensions({
            dimensions: new Cartesian3(Number(boxSize.width), Number(boxSize.depth),
                Number(boxSize.height)),
            vertexFormat: VertexFormat.POSITION_AND_NORMAL
        });

        // const mtx2 = new DebugModelMatrixPrimitive({
        //         modelMatrix: modelMatrix
        //     });
        // viewer.scene.primitives.add(mtx2);

        const primitiveCollectionsLayer = new PrimitiveCollection();

        let selectedColor;
        let selectedClassificationNumber;
        switch (selectedClassification) {
            case 'never_classified': {
                selectedColor = Color.fromCssColorString('#FFFFFF');
                selectedClassificationNumber = 0;
                break;
            }
            case 'unclassified': {
                selectedColor = Color.fromCssColorString('#808080');
                selectedClassificationNumber = 1;
                break;
            }
            case 'ground': {
                selectedColor = Color.fromCssColorString('#964B00');
                selectedClassificationNumber = 2;
                break;
            }
            case 'low_vegetation': {
                selectedColor = Color.fromCssColorString('#adff2f');
                selectedClassificationNumber = 3;
                break;
            }
            case 'medium_vegetation': {
                selectedColor = Color.fromCssColorString('#008000');
                selectedClassificationNumber = 4;
                break;
            }
            case 'high_vegetation': {
                selectedColor = Color.fromCssColorString('#006400');
                selectedClassificationNumber = 5;
                break;
            }
            case 'building': {
                selectedColor = Color.fromCssColorString('#ffa500');
                selectedClassificationNumber = 6;
                break;
            }
            case 'noise': {
                selectedColor = Color.fromCssColorString('#ffc0cb');
                selectedClassificationNumber = 7;
                break;
            }
            case 'key_point': {
                selectedColor = Color.fromCssColorString('#ff0000');
                selectedClassificationNumber = 8;
                break;
            }
            case 'water': {
                selectedColor = Color.fromCssColorString('#0000ff');
                selectedClassificationNumber = 9;
                break;
            }
            case 'overlap': {
                selectedColor = Color.fromCssColorString('#ffff00');
                selectedClassificationNumber = 12;
                break;
            }
            case 'wire': {
                selectedColor = Color.fromCssColorString('#F800FF');
                selectedClassificationNumber = 13;
                break;
            }
            case 'car_pedestrian': {
                selectedColor = Color.fromCssColorString('#00BEFF');
                selectedClassificationNumber = 18;
                break;
            }
            default: {
                selectedColor = Color.fromCssColorString('#FFFFFF');
                selectedClassificationNumber = 0;
                break;
            }
        }

        primitiveCollectionsLayer.add(
            new ClassificationPrimitive({
                geometryInstances: new GeometryInstance({
                    geometry: geometry,
                    modelMatrix: modelMatrix,
                    appearance: new PerInstanceColorAppearance(),
                    attributes: {
                        color: ColorGeometryInstanceAttribute.fromColor(
                            selectedColor
                        ),
                        show: new ShowGeometryInstanceAttribute(true)
                    },
                    id: 'ClassificationPrimitive'
                }),
                appearance: new PerInstanceColorAppearance(),
                classificationType: ClassificationType.CESIUM_3D_TILE
            })
        );


        manualClassificationCrossSectionResult.push({
            "point1": positions.point1,
            "point2": positions.point2,
            "class": selectedClassificationNumber,
            "width": boxSize.depth,
        });

        primitivesMapper[primitivesMapper.counter] = primitiveCollectionsLayer;

        viewer.scene.primitives.add(primitiveCollectionsLayer, primitivesMapper.counter);
        primitivesMapper.counter++;
    }

    _getOrthogonalPoints(point1, point2, width) {
        let orthogonalVector;
        let baseVector = Cartesian3.subtract(point1, point2, new Cartesian3());
        const midPosition = Cartesian3.midpoint(point1, point2, new Cartesian3());
        baseVector = this._getProjectedVector(midPosition, baseVector);
        let upNormal = Ellipsoid.WGS84.geodeticSurfaceNormal(midPosition, new Cartesian3());

        orthogonalVector = Cartesian3.cross(upNormal, baseVector, new Cartesian3());
        Cartesian3.normalize(orthogonalVector, orthogonalVector);

        const offsetLeft = Cartesian3.multiplyByScalar(
            orthogonalVector,
            width / 2,
            new Cartesian3());

        const leftOrthogonalPoint = Cartesian3.add(midPosition, offsetLeft, new Cartesian3());

        const offsetRight = Cartesian3.multiplyByScalar(
            Cartesian3.negate(orthogonalVector, new Cartesian3()),
            width / 2,
            new Cartesian3());

        const rightOrthogonalPoint = Cartesian3.add(midPosition, offsetRight, new Cartesian3());

        return {
            left: leftOrthogonalPoint,
            right: rightOrthogonalPoint
        };
    }


}