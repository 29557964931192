import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill", "stroke"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("svg", {
    width: $props.width,
    height: $props.height,
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      d: "M13.9 12.0001C13.9 13.0494 13.0493 13.9001 12 13.9001C10.9506 13.9001 10.1 13.0494 10.1 12.0001C10.1 10.9508 10.9506 10.1001 12 10.1001C13.0493 10.1001 13.9 10.9508 13.9 12.0001ZM11.9999 19.9001C6.90667 19.9001 2.58558 16.5887 1.07454 12.0001C2.58556 7.41156 6.90667 4.1001 11.9999 4.1001C17.0932 4.1001 21.4142 7.41152 22.9253 12.0001C21.4143 16.5886 17.0932 19.9001 11.9999 19.9001ZM12 17.3001C14.9271 17.3001 17.3 14.9272 17.3 12.0001C17.3 9.07299 14.9271 6.7001 12 6.7001C9.07287 6.7001 6.69999 9.07299 6.69999 12.0001C6.69999 14.9272 9.07287 17.3001 12 17.3001Z",
      fill: $props.color,
      stroke: $props.color
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}