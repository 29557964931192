import * as Cesium from '@/sdk/cesium';


export function cesiumEventHandler(component) {

    component.grabPoint = {};
    let mousePosition = null;
    let checker = false;
    const camera = component.viewer.camera;
    const scene = component.viewer.scene;
    component.handler = component.viewer.screenSpaceEventHandler;
    const entities = component.viewer.entities;
    component.handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
    // component.handler.removeInputAction(Cesium.ScreenSpaceEventType.WHEEL);
    component._debugAxesPrimitive = null;
    // component.defaultLeftDown = component.handler.getInputAction(Cesium.ScreenSpaceEventType.LEFT_DOWN);

    const pointEnt = {
        color: Cesium.Color.fromCssColorString('#68EBD0'),
        pixelSize: 15,
        heightReference: Cesium.HeightReference.NONE,
    };

    // for mobile improvements: volume update/recalculation
    let picked = null;
    const canvas = component.viewer.scene.canvas;
    let $this = component;
    component.viewer.selectedEntityChanged.addEventListener(function (selectedEntity) {
        //annotation with image
        if (Cesium.defined(selectedEntity)) {
            if (Cesium.defined(selectedEntity.name)) {
                if (selectedEntity.name == 'annotation') {
                    const lbl = selectedEntity.label.text._value
                    if (lbl.indexOf(component.$t('viewer.file_manager.with_image')) >= 0) {
                        component.findAnnotationData(selectedEntity.id)
                    }
                }
            } else {
                //console.log('Unknown entity selected.');
            }
        } else {
            //console.log('Deselected.');
        }

        // checker for mobile view
        if (canvas.width <= 540) {
            if (picked) {
                picked.label.backgroundColor = Cesium.Color.BLACK.withAlpha(0.8);
                picked.label.scale = 1;
                picked.label.horizontalOrigin = Cesium.HorizontalOrigin.LEFT;
            }
            if (Cesium.defined(selectedEntity)) {
                if (Cesium.defined(selectedEntity.label) && selectedEntity.name === 'volume') {
                    selectedEntity.label.backgroundColor = Cesium.Color.BLACK.withAlpha(0.9);
                    selectedEntity.label.scale = 1.20;
                    selectedEntity.label.horizontalOrigin = Cesium.HorizontalOrigin.CENTER;
                    picked = selectedEntity;
                    $this.viewer.zoomTo(
                        selectedEntity,
                    );
                    let projMeasurementId = $this.entities.find(element => element.id.includes(selectedEntity.id)).project_measurement_id;
                    let toolList = $this.compRef.fileManagerRef.toolList;
                    let projMeasurementData = toolList.find(element => element.data.project_measurement_id == projMeasurementId);
                    if (!$this.compRef.measureInfoRef.showUpdate) {
                        $this.compRef.floatingBarRef.update({ updateMeasurement: true, projMeasurementData: projMeasurementData });
                    }
                }
            } else {
                // if no entity is selected, return the view to the current tileset & remove buttons
                if ($this.compRef.measureInfoRef && !$this.compRef.measureInfoRef.showUpdate) {
                    $this.viewer.zoomTo($this.tilesetData[$this.selectedTileset]);
                    $this.compRef.floatingBarRef.update({ updateMeasurement: false, projMeasurementData: null });
                    $this.compRef.floatingBarRef.removeNotifUpdate(false);
                }
            }
        }
    });

    component.handler.setInputAction((movement) => {
        if (this.drawingMode !== '') {
            component.setSelectedTool('');
        }
    }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);

    // capture cursor position during mouse move for zoom update in 3D
    component.handler.setInputAction((event) => {
        mousePosition = event.endPosition;
        if (!(this.tilesetData[this.selectedTileset] instanceof Cesium.Cesium3DTileset)) { return; }
        // console.log("mousePosition: ", mousePosition)
        if (checker) {
            // Rotate the model.
            if ('enable2D' in component.tilesetData[this.selectedTileset]) {
                if (!this.tilesetData[this.selectedTileset].enable2D && component.compRef.floatBarRef) {
                    const threeSphere = component.compRef.floatBarRef.$refs.sphere.mesh;
                    threeSphere.rotation.y = component.viewer.camera.heading + component.rotationY;
                    threeSphere.rotation.x = component.viewer.camera.direction.x
                } else {
                    component.resetThreeCamera(true)
                }
            }
        }
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
    /**
     * use this code if you want to enable the rotation with deltaX & deltaY
     */
    // component.viewer.scene.canvas.setAttribute('tabindex', 0);
    // let mouseX, mouseY, deltaX, deltaY = 0;
    // let mousemove = function(evt) {
    //     deltaX = evt.clientX - mouseX,
    //     deltaY = evt.clientY - mouseY;
    //     mouseX = evt.clientX;
    //     mouseY = evt.clientY;
    // };
    // document.addEventListener('mousemove', mousemove, false);
    component.handler.setInputAction((movement) => {
        if (!(this.tilesetData[this.selectedTileset] instanceof Cesium.Cesium3DTileset)) { return; }

        if (checker) {
            // Rotate the model.
            if (!this.tilesetData[this.selectedTileset].enable2D && component.compRef.floatBarRef) {
                const threeSphere = component.compRef.floatBarRef.$refs.sphere.mesh;
                threeSphere.rotation.y = component.viewer.camera.heading + component.rotationY;
                threeSphere.rotation.x = component.viewer.camera.direction.x
            } else {
                component.resetThreeCamera(true)
            }
        }
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE, Cesium.KeyboardEventModifier.CTRL);

    const addAxesHelper = (position, mode) => {
        switch (mode) {
            // Adding the transformed axes helper in the position of the middle down mouse event
            case 1: {
                let cartesian3FromPx = position;
                if (!("z" in position)) {
                    cartesian3FromPx = component.measure.getCartesian3FromPX(position);
                }
                if (cartesian3FromPx) {
                    const _modelMatrix = Cesium.Transforms.headingPitchRollToFixedFrame(
                        cartesian3FromPx,
                        new Cesium.HeadingPitchRoll(),
                        Cesium.Ellipsoid.WGS84,
                        Cesium.Transforms.eastNorthUpToFixedFrame
                    );
                    try {
                        setTimeout(() => {
                            component._debugAxesPrimitive = scene.primitives.add(
                                new Cesium.DebugModelMatrixPrimitive({
                                    modelMatrix: _modelMatrix,
                                    length: 150.0,
                                    width: 2.5
                                })
                            );
                        }, 10)
                    } catch (e) {
                        console.log("Debug Primitive Error: ", e)
                    }
                }
                break;
            }
            // Remove _debugAxesPrimitive that was added in MIDDLE_DOWN
            case 2: {
                try {
                    setTimeout(() => {
                        if (Cesium.defined(this._debugAxesPrimitive) && !this._debugAxesPrimitive.isDestroyed()) {
                            scene.primitives.remove(this._debugAxesPrimitive);
                            component._debugAxesPrimitive = null;
                        }
                    }, 10);
                } catch (e) {
                    console.log("Debug Remove Primitive Error: ", e)
                }
                break;
            }
            default:
                break;
        }
    }

    component.handler.setInputAction((movement) => {
        if (!(this.tilesetData[this.selectedTileset] instanceof Cesium.Cesium3DTileset)) { return; }

        // Axes helper ? Rotate the model.
        if (!this.tilesetData[this.selectedTileset].enable2D && component.compRef.floatBarRef) {
            const threeSphere = component.compRef.floatBarRef.$refs.sphere.mesh;
            threeSphere.rotation.y = component.viewer.camera.heading + component.rotationY;
            threeSphere.rotation.x = component.viewer.camera.direction.x
        } else {
            component.resetThreeCamera(true)
        }

        // Adding the blue ball to instruct the origin of rotation
        const picks = component.viewer.scene.drillPick(movement.position, 10, 1, 1);
        if (picks.length > 0) {
            component.grabPoint = new Cesium.Entity();
            component.grabPoint.position = component.measure.getCartesian3FromPX(movement.position);
            component.grabPoint.point = pointEnt;
            entities.add(this.grabPoint);
            addAxesHelper(movement.position, 1);
        } else {
            try {
                let center = null;
                if (typeof component.focusedFile == "number") {
                    center = component.tilesetData[this.focusedFile].boundingSphere.center;
                } else {
                    center = component.focusedFile.boundingSphere.center;
                }
                if (center) {
                    let transform = Cesium.Transforms.eastNorthUpToFixedFrame(center);
                    if (transform) {
                        camera.lookAtTransform(transform, camera.DEFAULT_OFFSET);

                        // update rotate event from LEFT_DRAG to MIDDLE_DRAG
                        component.viewer.scene.screenSpaceCameraController.rotateEventTypes =
                            Cesium.CameraEventType.MIDDLE_DRAG;
                    }
                    addAxesHelper(center, 1);
                }
            } catch (e) {
                console.log("Middle Down Error: ", e)
            }
        }
        checker = true;

    }, Cesium.ScreenSpaceEventType.MIDDLE_DOWN);

    component.handler.setInputAction((movement) => {
        entities.remove(this.grabPoint);
        component.grabPoint = null;

        if (!(this.tilesetData[this.selectedTileset] instanceof Cesium.Cesium3DTileset)) { return; }

        // Rotate the model.
        if (!this.tilesetData[this.selectedTileset].enable2D) {
            if (this.compRef.floatBarRef) {
                const threeSphere = component.compRef.floatBarRef.$refs.sphere.mesh;
                threeSphere.rotation.y = component.viewer.camera.heading + component.rotationY;
                threeSphere.rotation.x = component.viewer.camera.direction.x
            }
        } else {
            component.resetThreeCamera(true)
        }
        addAxesHelper(movement.position, 2);
        try {
            // reset camera transform
            camera.lookAtTransform(Cesium.Matrix4.IDENTITY);
            // revert changes on rotate event
            component.viewer.scene.screenSpaceCameraController.rotateEventTypes =
                Cesium.CameraEventType.LEFT_DRAG;
        } catch (e) {
            console.log("Middle Up Error: ", e)
        }

    }, Cesium.ScreenSpaceEventType.MIDDLE_UP);

    component.handler.setInputAction((movement) => {
        let picks = component.viewer.scene.drillPick(movement.position, 10, 1, 1);
        const entity = component.viewer.entities.getById("grabPoint");
        if (entity) {
            entities.remove(entity);
        }
        if (picks.length > 0) {
            component.grabPoint = new Cesium.Entity({
                id: 'grabPoint'
            });
            component.grabPoint.position = component.measure.getCartesian3FromPX(movement.position);
            component.grabPoint.point = pointEnt;
            entities.add(this.grabPoint);
            addAxesHelper(movement.position, 1);
        } else {
            try {
                let center = null;
                if (typeof component.focusedFile == "number") {
                    center = component.tilesetData[this.focusedFile].boundingSphere.center;
                } else {
                    center = component.focusedFile.boundingSphere.center;
                }
                if (center) {
                    let transform = Cesium.Transforms.eastNorthUpToFixedFrame(center);
                    if (transform) {
                        camera.lookAtTransform(transform, camera.DEFAULT_OFFSET);
                        // update rotate event from LEFT_DRAG to MIDDLE_DRAG
                        component.viewer.scene.screenSpaceCameraController.rotateEventTypes =
                            [{ eventType: Cesium.CameraEventType.LEFT_DRAG, modifier: Cesium.KeyboardEventModifier.CTRL }];

                    }
                    addAxesHelper(center, 1);
                }
            } catch (e) {
                console.log("Left Down + CTRL Error: ", e)
            }
        }

        // addAxesHelper(movement.position, 1);
        checker = true;

    }, Cesium.ScreenSpaceEventType.LEFT_DOWN, Cesium.KeyboardEventModifier.CTRL);

    component.handler.setInputAction((movement) => {
        // console.log("CTRL + LEFT_UP")
        entities.remove(this.grabPoint);
        addAxesHelper(movement.position, 2);
        component.grabPoint = null;
        checker = false;
        try {
            // reset camera transform
            camera.lookAtTransform(Cesium.Matrix4.IDENTITY);
            // revert changes on rotate event
            component.viewer.scene.screenSpaceCameraController.rotateEventTypes =
                Cesium.CameraEventType.LEFT_DRAG;
        } catch (e) {
            console.log("Left Up + CTRL Error: ", e)
        }

    }, Cesium.ScreenSpaceEventType.LEFT_UP, Cesium.KeyboardEventModifier.CTRL);

    component.handler.setInputAction((movement) => {
        entities.remove(this.grabPoint);
        addAxesHelper(movement.position, 2);
        component.grabPoint = null;
        checker = false;
        // reset camera transform
        camera.lookAtTransform(Cesium.Matrix4.IDENTITY);
        // revert changes on rotate event
        component.viewer.scene.screenSpaceCameraController.rotateEventTypes =
            Cesium.CameraEventType.LEFT_DRAG;
    }, Cesium.ScreenSpaceEventType.LEFT_UP);

    component.handler.setInputAction((movement) => {
        let cartesian = component.measure.getCartesian3FromPX(movement.position);
        // let ray = camera.getPickRay(movement.position);
        // let directionToZoom = camera.getPickRay(movement.position).direction;
        // let picks = component.viewer.scene.drillPick(movement.position);
        // let tilesetDistance = Cesium.Cartesian3.distance(this.tilesetData[this.selectedTileset].boundingSphere.center, camera.position);

        if (cartesian) {
            // let ellipsoid = component.viewer.scene.globe.ellipsoid;
            // let cameraHeight = ellipsoid.cartesianToCartographic(
            //     camera.position
            // ).height;
            let cameraHeight = Cesium.Cartesian3.distance(cartesian, camera.position);
            component.zoomLevel = cameraHeight / 3.0;
            let initialZoom = cameraHeight - component.zoomLevel;
            if (this.zoomRate == 0.0) {
                component.zoomRate = initialZoom
            } else if (this.zoomRate > component.zoomLevel) {
                component.zoomRate -= component.zoomLevel
            }
            const entities = component.viewer.entities;
            let point = entities.add({
                position: cartesian.clone(),
                point: pointEnt,
            })
            let duration = 1;
            // if (picks.length > 0) {
            component.viewer.flyTo(
                point,
                {
                    offset: new Cesium.HeadingPitchRange(
                        camera.heading,
                        camera.pitch,
                        component.zoomRate),
                    // maximumHeight: 500,
                    duration: duration
                }).then(function () {
                    point.show = false;
                    console.log("MapView: zooming to: flying finished");
                });
            // } else {
            //     camera.flyTo({
            //         destination: ray.origin,
            //         orientation: {
            //             direction: directionToZoom,
            //             up: camera.up
            //         },
            //         duration: duration,
            //         complete: function () {
            //             point.show = false;
            //             console.log("MapView: zooming to: flying finished");
            //         }
            //     })
            // }
        }
        // if (picks.length > 0) {
        //     camera.move(directionToZoom, component.zoomLevel)
        // } else {
        //     camera.move(directionToZoom, tilesetDistance/3)
        // }
    }, Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);

    component.handler.setInputAction((wheelZoomAmount) => {
        if (wheelZoomAmount < 0) {
            component.zoomRate = 0
        }
        const pixel = { width: 50, height: 50 };
        const currentTileset = component.tilesetData[this.selectedTileset];
        // added checker if user adjusted the attenuation; higher value
        let fastZoomSpeed = false;
        if ('pointCloudShading' in currentTileset) {
            if (currentTileset.pointCloudShading.maximumAttenuation > 3) {
                fastZoomSpeed = true;
            }
        }
        if (mousePosition === null || !(currentTileset instanceof Cesium.Cesium3DTileset)) { return }
        let picks = component.viewer.scene.drillPick(mousePosition, 5, pixel.width, pixel.height) || [];
        let pickPosition = component.measure.getCartesian3FromPX(mousePosition, false, null, pixel) || component.prevPos;
        if (pickPosition) component.prevPos = pickPosition.clone();
        let directionToZoom = camera.getPickRay(mousePosition).direction.clone();
        // console.log("pickPosition: ", pickPosition)
        try {
            if (picks.length != 0) {
                const calcMouseSpeed = (tileset, adjustment = 1) => {
                    component.viewer.scene.screenSpaceCameraController.enableZoom = false;
                    const tilesetDistance = Cesium.Cartesian3.distance(pickPosition, camera.position);
                    const zoomInSpeed = Math.abs(tilesetDistance / (50 / adjustment) * (wheelZoomAmount / 100));
                    return zoomInSpeed;
                };
                if (wheelZoomAmount > 0) {
                    component.currZoomSpeed = calcMouseSpeed(picks[0].primitive, 4);
                    // How much the zoom speed difference is allowed
                    if (Math.abs(this.currZoomSpeed - component.prevZoomSpeed) < 10) {
                        // adjust zoom speed when 2D mode is set (for additional slow effect)
                        if (this.viewer.scene.screenSpaceCameraController.enableTilt == false) {
                            component.currZoomSpeed = component.currZoomSpeed / 5;
                        }
                        if (fastZoomSpeed) {
                            component.currZoomSpeed = calcMouseSpeed(picks[0].primitive, 8);
                        }
                    }
                    camera.move(directionToZoom, component.currZoomSpeed);
                    component.prevZoomSpeed = component.currZoomSpeed;
                } else {
                    component.currZoomSpeed = calcMouseSpeed(picks[0].primitive, 5);
                    if (Math.abs(this.currZoomSpeed - component.prevZoomSpeed) < 20) { // How much the zoom speed difference is allowed
                        if (fastZoomSpeed) {
                            component.currZoomSpeed = calcMouseSpeed(picks[0].primitive, 10);
                        }
                        // camera.moveBackward(this.currZoomSpeed);
                    }
                    camera.move(directionToZoom, -Math.abs(this.currZoomSpeed));
                    component.prevZoomSpeed = component.currZoomSpeed;
                }
            } else {
                // component.viewer.scene.screenSpaceCameraController.enableZoom = false;
                // Used to check if camera can still see the pointcloud; 
                // const blankSpaceDistance = Cesium.Cartesian3.distance(currentTileset.boundingSphere.center, camera.position);
                // add: compare distance in this line
                // const zoomInSpeed = Math.abs(blankSpaceDistance / (50 / 5) * (wheelZoomAmount / 100));
                let zoomInSpeed = 1;
                // let normalSpeed = 100;
                const radius = currentTileset.boundingSphere.radius;
                if (radius <= 100 && radius > 50) {
                    zoomInSpeed = 0.2;
                    // normalSpeed = 10;
                } else if (radius <= 50) {
                    zoomInSpeed = 0.1;
                    // normalSpeed = 2;
                }
                if (wheelZoomAmount > 0) {
                    camera.move(directionToZoom, zoomInSpeed);
                } else {
                    component.viewer.scene.screenSpaceCameraController.enableZoom = true;
                    // camera.move(directionToZoom, -Math.abs(zoomInSpeed));
                }
            }
        } catch (e) {
            component.viewer.scene.screenSpaceCameraController.enableZoom = true;
            console.log(e);
        }
    }, Cesium.ScreenSpaceEventType.WHEEL);
}