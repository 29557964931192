import * as Cesium from '@/sdk/cesium';

export class CameraControl {

    constructor(viewer, option = {}) {
        this.alt = false
        this.meta = false
        this.ctrl = false
        this.shift = false
        this.option = option
        //this.initHandler(viewer);
        this.initCesiumCamera(viewer, option);
    }

    initHandler(viewer) {
        document.addEventListener('keydown', (e) => {
            this.keyAction(e, viewer);
            this.setKeyState(e)
        }, false);
        document.addEventListener('keyup', (e) => {
            this.setKeyState(e)
        }, false);
    }

    keyAction(event, viewer) {
        let horizontalDegrees = 1.0;
        let verticalDegrees = 1.0;
        let viewRect = viewer.camera.computeViewRectangle();
        if (Cesium.defined(viewRect)) {
            horizontalDegrees *= Cesium.Math.toDegrees(viewRect.east - viewRect.west) / 360.0;
            verticalDegrees *= Cesium.Math.toDegrees(viewRect.north - viewRect.south) / 180.0;
        }
        let camera = viewer.camera;

        if (event.keyCode === 39) {  // right arrow 
            viewer.camera.rotateRight(Cesium.Math.toRadians(horizontalDegrees));
        } else if (event.keyCode === 37) {  // left arrow
            viewer.camera.rotateLeft(Cesium.Math.toRadians(horizontalDegrees));
        } else if (event.keyCode === 38) {  // up arrow
            if (this.shift) {
                if (viewer instanceof Cesium.Viewer) {
                    let ellipsoid = viewer.scene.globe.ellipsoid;
                    let cameraHeight = ellipsoid.cartesianToCartographic(
                        camera.position
                    ).height;
                    let moveRate = cameraHeight / 20.0;
                    camera.moveForward(moveRate);
                }
            } else {
                camera.rotateDown(Cesium.Math.toRadians(verticalDegrees));
            }
        } else if (event.keyCode === 40) {  // down arrow
            if (this.shift) {
                if (viewer instanceof Cesium.Viewer) {
                    let camera = viewer.camera;
                    let ellipsoid = viewer.scene.globe.ellipsoid;
                    let cameraHeight = ellipsoid.cartesianToCartographic(
                        camera.position
                    ).height;
                    let moveRate = cameraHeight / 20.0;
                    camera.moveBackward(moveRate);
                }
            } else {
                camera.rotateUp(Cesium.Math.toRadians(verticalDegrees));
            }
        }
    }

    setKeyState(event) {
        //console.log(event)
        let val = null
        if (event.type == 'keydown') {
            val = true
        }
        if (event.type == 'keyup') {
            val = false
        }
        switch (event.key) {
            case 'Alt':
                this.alt = val
                break;
            case 'Control':
                this.ctrl = val
                break;
            case 'Meta':
                this.meta = val
                break;
            case 'Shift':
                this.shift = val
                break;

            default:
                break;
        }
    }

    initCesiumCamera(viewer, option) {
        const scene = viewer.scene;
        const canvas = viewer.canvas;
        canvas.setAttribute("tabindex", "0"); // needed to put focus on the canvas
        const ellipsoid = scene.globe.ellipsoid;
        const flags = {
            looking: false,
            moveForward: false,
            moveBackward: false,
            moveUp: false,
            moveDown: false,
            moveLeft: false,
            moveRight: false,
        };

        const cesium_component = option.component


        function getFlagForKeyCode(keyCode) {
            switch (keyCode) {
                case "W".charCodeAt(0):
                    return "moveForward";
                case "S".charCodeAt(0):
                    return "moveBackward";
                case "Q".charCodeAt(0):
                    return "moveUp";
                case "E".charCodeAt(0):
                    return "moveDown";
                case "D".charCodeAt(0):
                    return "moveRight";
                case "A".charCodeAt(0):
                    return "moveLeft";
                default:
                    return undefined;
            }
        }

        document.addEventListener(
            "keydown",
            function (e) {
                let className = e.target.className
                let tagName = e.target.tagName
                if (className.indexOf("input") > 0) { return }

                if (tagName == 'INPUT' || tagName == 'TEXTAREA') return

                const flagName = getFlagForKeyCode(e.keyCode);
                if (typeof flagName !== "undefined") {
                    flags[flagName] = true;
                }
            },
            true
        );

        document.addEventListener(
            "keyup",
            function (e) {
                let className = e.target.className
                let tagName = e.target.tagName
                if (className.indexOf("input") > 0) return

                if (tagName == 'INPUT' || tagName == 'TEXTAREA') return

                const flagName = getFlagForKeyCode(e.keyCode);
                if (typeof flagName !== "undefined") {
                    flags[flagName] = false;
                }
            },
            true
        );

        viewer.clock.onTick.addEventListener(function (clock) {
            const camera = viewer.camera;
            //height from ellipsoid
            const ellipsoid_cameraHeight = ellipsoid.cartesianToCartographic(
                camera.position
            ).height;
            // height from pointcloud
            var boundingSphere = cesium_component.tilesetData[0].boundingSphere;
            var center = boundingSphere.center;
            let cameraHeight = Cesium.Cartographic.fromCartesian(center).height;

            if (cameraHeight < 0) {
                cameraHeight = ellipsoid_cameraHeight
            } else {
                if (ellipsoid_cameraHeight > cameraHeight) {
                    cameraHeight = ellipsoid_cameraHeight - cameraHeight
                } else {
                    cameraHeight = ellipsoid_cameraHeight
                }
            }

            const moveRate = (cameraHeight / 25.0) + 1;
            // console.log(moveRate)

            if (flags.moveForward) {
                camera.moveForward(moveRate);
            }
            if (flags.moveBackward) {
                camera.moveBackward(moveRate);
            }
            if (flags.moveUp) {
                camera.moveUp(moveRate);
            }
            if (flags.moveDown) {
                camera.moveDown(moveRate);
            }
            if (flags.moveLeft) {
                camera.moveLeft(moveRate);
            }
            if (flags.moveRight) {
                camera.moveRight(moveRate);
            }
        });

    }

    removeListener() {
        window.removeEventListener('keydown', function (e) { }, false)
        window.removeEventListener('keyup', function (e) { }, false)
    }
}

