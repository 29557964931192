import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col w-full mt-6 p-6 rounded-md" }
const _hoisted_2 = { class: "flex w-full relative" }
const _hoisted_3 = { class: "flex flex-col w-full relative mt-6 col-span-6" }
const _hoisted_4 = { class: "flex w-full mt-2" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CommonSkeleton = _resolveComponent("CommonSkeleton")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CommonSkeleton, { class: "w-2/6" }),
      _createVNode(_component_CommonSkeleton, { class: "w-1/6 absolute right-0" })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_CommonSkeleton, { class: "w-full h-10" }),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_CommonSkeleton, { class: "w-1/6" }),
        _createVNode(_component_CommonSkeleton, { class: "w-3/6" }),
        _createVNode(_component_CommonSkeleton, { class: "w-1/6" })
      ])
    ])
  ]))
}