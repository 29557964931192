import api from '@/providers/api'

const account = {
    store(payload) {
        return api.post(`account/create`, {
            name: payload.name,
            email: payload.email,
            password: payload.password,
            confirm_password: payload.confirm_password,
            token: payload.token,
            locale: payload.locale,
            company: payload.company
        });
    },
    put(payload) {
        return api.post(`account/update`, payload);
    },
    account() {
        return api.get(`account/me`);
    },
    profile() {
        return api.get(`account/profile`);
    },
    setEmail(payload) {
        return api.post(`account/set-email`, payload);
    },
    setLocale(payload) {
        return api.post(`account/locale/update`, payload)
    },
    request_email_verification() {
        return api.post(`account/email/verification/request`)
    },
    verify_email(payload) {
        return api.post(`account/email/verify`, payload)
    },
    check_email(payload) {
        return api.post(`account/email/check`, payload)
    },
    validateToken(payload) {
        return api.post(`account/check/token`, payload)
    },
    save_settings(payload) {
        return api.post(`account/settings/update`,payload);
    }
}

export default account