
// Uses axios and Promises
// Everything that we need to communicate with the api will be declared here
import api from '@/providers/api'

const projectfiles = {

    setVisibility(id, fileid, payload) {
        return api.post(`project/resources/pointclouds/visibility/${id}/${fileid}`, payload);
    },

    getFilesAdminConsole(project_id, per_page = 1, pageNumber) {
        return api.get(`admin/files/${project_id}?per_page=${per_page}&page=${pageNumber}`);
    },
    getDownloadUrlAdminConsole(file_id) {
        return api.get(`admin/files/get_url/${file_id}`);
    },
    saveSettings(payload) {
        if (payload.file_array == undefined) {
            payload.file_array = null
        }
        if (payload.file_array == null && payload.file_id == 0) return false
        return api.post('project/resources/save/settings', payload)
    },
    getDownloadUrlProcessedAdminConsole(file_id) {
        return api.get(`admin/files/get_url_processed/${file_id}`);
    },
    getDownloadUrlProcessedAdminConsoleV2(file_id) {
        return api.get(`admin/files/get_url_processed_v2/${file_id}`);
    },
    getUploadUrl(payload) {
        return api.post("signed-url", payload);
    },
}

export default projectfiles