
import Model from '@/models/Usage'

const state = () => ({
    usage: []
})
  
// getters
const getters = {
    myUsage(state) {
        return state.usage
    },
    count(state) {
        return state.usage.length
    }
}

// actions
const actions = {

    async loadUsage(context) {
        const usage = await Model.get()
        //console.log(usage.data)
        return new Promise((resolve) => {
            context.commit( 'load', usage.data)
            resolve(usage.data)
        })
    },
}

// mutations
const mutations = {
    load(state, usage) {
        state.usage = usage
    }
}
  
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}