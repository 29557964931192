import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("iframe", {
    class: "clickup-embed clickup-dynamic-height",
    src: $props.locale == 'ja' ? $data.ja_url : $data.en_url,
    onwheel: "",
    width: "100%",
    height: "100%",
    style: {"background":"transparent"}
  }, null, 8, _hoisted_1))
}