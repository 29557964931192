/**
 * Tree analysing Package
 *
 * Integrates Tree analysing 
 */
import * as Cesium from '@/sdk/cesium';
import ProjectResource from '@/models/ProjectResources';
import swal from 'sweetalert';

import { convert_ECEF } from '@/components/ui/panel/properties/library';

export default {
    tileset: null,
    viewer: null,
    selectedEpsg: null,
    filter(params) {
        this.tileset = params.tileset
        this.viewer = params.viewer
        this.selectedEpsg = params.selectedEpsg
        const search = '${Intensity} === ' + params.id
        const data = params.data
        const ref = params.ref;
        if (data.meta.output_metrics) {
            // let output_metrics = JSON.parse(data.meta.output_metrics)
            let payload = {
                file_id: data.file_id,
                tree_id: params.id
            }
            let result = {};
            try {
                ref.compRef.propertyRef.setSearchingTree(true);
                ProjectResource.getTreeData(payload).then(async (response) => {
                    result = response.data;
                    await this.tileset;
                    this.tileset.style = new Cesium.Cesium3DTileStyle({
                        show: search
                    });
                    // auto-zoom for specific treeID
                    if (result){
                        let midHeight = result.TreeHeight / 2;
                        let x = result.X
                        let y = result.Y
                        let z = Number(result.Z) + midHeight
                        let position = new Cesium.Cartesian3(Number(x), Number(y), Number(z));
                        const cartesian = convert_ECEF(position, this.selectedEpsg);
                        const pointEnt = {
                            color: Cesium.Color.fromCssColorString('#68EBD0'),
                            pixelSize: 25,
                            heightReference: Cesium.HeightReference.NONE,
                        };
                        const camera = this.viewer.camera;

                        if (cartesian) {
                            let cameraHeight = Cesium.Cartesian3.distance(cartesian, camera.position);
                            let initialZoom = cameraHeight / 3.0;
                            if (!Object.keys(ref.$route.params).includes("zoom")) {
                                ref.$route.params['zoom'] = initialZoom;
                            } else {
                                initialZoom = ref.$route.params.zoom;
                            }
                            const entities = this.viewer.entities;
                            let point = entities.add({
                                position: cartesian.clone(),
                                point: pointEnt,
                            })
                            this.viewer.flyTo(
                                point,
                                {
                                    offset: new Cesium.HeadingPitchRange(
                                        camera.heading,
                                        camera.pitch,
                                        initialZoom)
                                }).then(function () {
                                    point.show = false;
                                    console.log("MapView: zooming to tree: flying finished");
                                });
                        }
                    }
                }).catch((response)=>{
                    console.log("error response", response);
                    // double checking of the en & jp translation
                    
                    this.$helper.warningMessage(response, "", "", true);
                    // swal({
                    //     title: this.$t('uploads.server_error'),
                    //     icon: 'warning',
                    //     dangerMode: true
                    // })
                }).finally(()=>{
                    ref.compRef.propertyRef.setSearchingTree(false);
                });
                // let result = output_metrics.find(({ treeID }) => treeID === params.id.toString());
            } catch (e) {
                // double checking of the en & jp translation
                this.$helper.warningMessage(e);
                // swal(e, {
                //     icon: 'warning'
                // });
            }
        }
    },
    showAll(params) {
        this.tileset = params.tileset
        this.tileset.style = new Cesium.Cesium3DTileStyle({
            show: true
        });
        this.viewer.flyTo(this.tileset);
    },
    getMaxTreeID(params) {
        if ("meta" in params.data) {
            var meta = params.data.meta
            if (meta != null && "full_info" in meta) {
                var meta_array = (meta.full_info).split("\r\n")
                var max = 0
                meta_array.forEach(e => {
                    if(e.indexOf("  intensity           ") >= 0) {
                        var e_a = e.split(" ")
                        //console.log(e_a[e_a.length-1])
                        max = Number(e_a[e_a.length-1])
                    }
                });
                return max
            } else return;
        } else return;
    },
}