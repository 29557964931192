import * as Cesium from '@/sdk/cesium';

import { CrossSection } from '@/third-party/CrossSection';
import { Volume3D } from '@/third-party/Volume3D';
import { ManualClassificationCrossSection } from '@/third-party/ManualClassificationCrossSection';
import { ManualClassification } from '@/third-party/ManualClassification';

import convert_xyz from '@/components/ui/panel/properties/library';
import MeasurementSnapping from './MeasurementSnapping';
import worker from '@/web-worker';

export class ScanxMeasure {
    /**
     * 绘制对象
     * @param viewer
     * @param options
     * @constructor
     */
    constructor(viewer, options = {}) {
        if (viewer && viewer instanceof Cesium.Viewer) {
            this._drawLayer = viewer.dataSources.getByName('measureLayer')[0];
            this._basePath = options.basePath || '';
            this._viewer = viewer;
            this.selectedEpsg = options.selectedEpsg;
            this.cesiumComponent = options.cesiumComponent;
            this._handler = null;
            this._refs = options.refs || {};
            // this._lang = window.language ? window.language : 'ja';
            this.languageEvent = window.addEventListener('changeLanguage',
                (e) => {
                    this._lang = e.detail.lang;
                }
            );

            // MCCS
            this.primitivesMapperForMCCS = {
                counter: this._viewer.scene.primitives.length,
            };
            this.manualClassificationCrossSection = {
                Class: 'Building',
                ProfileWidth: 5,
                enable: false,
                cameraView: null
            };
            this.manualClassificationCrossSectionResult = [];

            this.zoomLevel = 0.0;
            this.zoomRate = 0.0;
            this.volumeMeasurement = null;
            this.toastr = options.toastr
            this.toastrPrompt = null
            // added for line/distance measurement tool mobile
            this.tempPos = [];
            this.options = options;
            this.entityIds = [];
            this.lineObj = [];
            // added for area measurement tool mobile
            this.tempPolygonPositions = [];
            this.tempPolygon = new Cesium.PolygonHierarchy();
            this.tempPolygonEntity = new Cesium.Entity();
            // added for profile/cross-section measurement tool mobile
            this.tempPoints = [];
            this.pointEntities = [];
            // this.primitives = this._viewer.scene.primitives;
        }
    }
    clearMobileParams() {
        // added for line/distance measurement tool mobile
        this.tempPos = [];
        this.entityIds = [];
        this.lineObj = [];
        // added for area measurement tool mobile
        this.tempPolygonPositions = [];
        this.tempPolygon = new Cesium.PolygonHierarchy();
        this.tempPolygonEntity = new Cesium.Entity();
        // added for profile/cross-section measurement tool mobile
        this.tempPoints = [];
        this.pointEntities = [];
    }
    clear() {
        if (this._handler != null) {
            this._handler.destroy();
            this._handler = null
        }
        this.clearPrompt()
    }

    /***
     * Convert coordinate
     * @return {Object} Cartesian3
     * @param position
     * @param alt
     */
    transformWGS84ToCartesian(position, alt) {
        if (this._viewer) {
            return position ?
                Cesium.Cartesian3.fromDegrees(
                    position.lng || position.lon,
                    position.lat,
                    position.alt = alt || position.alt,
                    Cesium.Ellipsoid.WGS84
                ) :
                Cesium.Cartesian3.ZERO;
        }
    }

    /***
     * 坐标数组转换 笛卡尔转84
     *
     * @param {Array} WSG84Arr {lng,lat,alt} 地理坐标数组
     * @param {Number} alt 拔高
     * @return {Array} Cartesian3 三维位置坐标数组
     */
    transformWGS84ArrayToCartesianArray(WSG84Arr, alt) {
        if (this._viewer && WSG84Arr) {
            var $this = this;
            return WSG84Arr ?
                WSG84Arr.map(function (item) {
                    return $this.transformWGS84ToCartesian(item, alt);
                }) : [];
        }
    }

    /***
     * 坐标转换 笛卡尔转84
     *
     *
     * @return {Object} {lng,lat,alt} 地理坐标
     * @param cartesian
     */
    transformCartesianToWGS84(cartesian) {
        if (this._viewer && cartesian) {
            var ellipsoid = Cesium.Ellipsoid.WGS84;
            var cartographic = ellipsoid.cartesianToCartographic(cartesian);
            return {
                lng: Cesium.Math.toDegrees(cartographic.longitude),
                lat: Cesium.Math.toDegrees(cartographic.latitude),
                alt: cartographic.height
            };
        }
    }

    /***
     * 坐标数组转换 笛卡尔转86
     *Coordinate array conversion Cartesian to 86
     * @param {Array} cartesianArr 三维位置坐标数组Three-dimensional array of position coordinates
     *
     * @return {Array} {lng,lat,alt} 地理坐标数组Geographical coordinates array
     */
    transformCartesianArrayToWGS84Array(cartesianArr) {
        if (this._viewer) {
            var $this = this;
            return cartesianArr ?
                cartesianArr.map(function (item) {
                    return $this.transformCartesianToWGS84(item);
                }) : [];
        }
    }

    /**
     * 84坐标转弧度坐标
     * @param {Object} position wgs84
     * @return {Object} Cartographic 弧度坐标
     *
     */
    transformWGS84ToCartographic(position) {
        return position ?
            Cesium.Cartographic.fromDegrees(
                position.lng || position.lon,
                position.lat,
                position.alt
            ) :
            Cesium.Cartographic.ZERO;
    }
    /**
     * Location of Point cloud or Ellipsoid
     *
     * @param {Object} px Screen position
     *
     * @return {Object} Cartesian3
     */
    getCartesian3FromPX(px, details = false, tool = null, pixel = { width: 1, height: 1 }) {

        if (this._viewer && px) {
            var picks = this._viewer.scene.drillPick(px, 10, pixel.width, pixel.height);
            var cartesian = null;
            var isOn3dtiles = false,
                isOnTerrain = false,
                isOnPrimitive = false;
            // drillPick
            for (let i in picks) {
                let pick = picks[i];

                if (pick &&
                    pick.primitive instanceof Cesium.Cesium3DTileFeature ||
                    pick && pick.primitive instanceof Cesium.Cesium3DTileset ||
                    pick && pick.primitive instanceof Cesium.Model ||
                    pick && pick.primitive instanceof Cesium.Primitive ||
                    pick && pick.primitive instanceof Cesium.PrimitiveCollection
                ) { //模型上拾取
                    isOn3dtiles = true;
                    if (pick && pick.primitive instanceof Cesium.Primitive) {
                        isOnPrimitive = true
                    }
                }
                // 3dtilset
                if (isOn3dtiles) {
                    this._viewer.scene.pick(px); // pick
                    cartesian = this._viewer.scene.pickPosition(px);
                    // if cartesian is still undefined, enable picking of translucent geometry using the depth buffer
                    // undefined happens when only Cesium.Model is shown in the viewer
                    if (!cartesian) {
                        this._viewer.scene.pickTranslucentDepth = true;
                        cartesian = this._viewer.scene.pickPosition(px);
                    }
                    // if (cartesian) {
                    //     let cartographic = Cesium.Cartographic.fromCartesian(cartesian);
                    //     if (cartographic.height < 0) cartographic.height = 0;
                    //     let lon = Cesium.Math.toDegrees(cartographic.longitude),
                    //         lat = Cesium.Math.toDegrees(cartographic.latitude),
                    //         height = cartographic.height;
                    //     cartesian = this.transformWGS84ToCartesian({ lng: lon, lat: lat, alt: height });
                    // }
                }
            }

            // 地形
            let boolTerrain = this._viewer.terrainProvider instanceof Cesium.EllipsoidTerrainProvider;
            // Terrain
            if (!isOn3dtiles && !boolTerrain) {
                var ray = this._viewer.scene.camera.getPickRay(px);
                if (!ray) return null;
                cartesian = this._viewer.scene.globe.pick(ray, this._viewer.scene);
                isOnTerrain = true;
            }

            // check if tool is cross-section; selects only points inside the point cloud
            if (tool && (picks.length == 0 || !isOn3dtiles)) {
                return false;
            }

            // 地球
            if (!isOn3dtiles && !isOnTerrain && boolTerrain) {
                cartesian = this._viewer.scene.camera.pickEllipsoid(px, this._viewer.scene.globe.ellipsoid);
            }

            if (cartesian) {
                // let position = this.transformCartesianToWGS84(cartesian);
                // if (position.alt < 0) {
                //     cartesian = this.transformWGS84ToCartesian(position, 0.1);
                // }
                if (!details) {
                    return cartesian;
                } else {
                    let details = {
                        point: cartesian,
                        boolTerrain: boolTerrain,
                        isOn3dtiles: isOn3dtiles,
                        isOnTerrain: isOnTerrain,
                        isOnPrimitive: isOnPrimitive,
                    }
                    return details
                }
            }
            return false;
        }
    }


    /**
     * 获取84坐标的距离
     * @param {*} positions
     */
    getPositionDistance(positions) {
        let distance = 0;
        for (let i = 0; i < positions.length - 1; i++) {
            let point1cartographic = this.transformWGS84ToCartographic(positions[i]);
            let point2cartographic = this.transformWGS84ToCartographic(positions[i + 1]);
            let geodesic = new Cesium.EllipsoidGeodesic();
            geodesic.setEndPoints(point1cartographic, point2cartographic);
            let s = geodesic.surfaceDistance;
            s = Math.sqrt(
                Math.pow(s, 2) +
                Math.pow(point2cartographic.height - point1cartographic.height, 2)
            );
            distance = distance + s;
        }
        return distance.toFixed(3);
    }

    /**
     * 计算一组坐标组成多边形的面积Calculate the area of a polygon formed by a set of coordinates
     * @param {*} positions
     */
    getPositionsArea(positions) {
        let result = 0;
        if (positions) {
            let h = 0;
            let ellipsoid = Cesium.Ellipsoid.WGS84;
            positions.push(positions[0]);
            for (let i = 1; i < positions.length; i++) {
                let oel = ellipsoid.cartographicToCartesian(
                    this.transformWGS84ToCartographic(positions[i - 1])
                );
                let el = ellipsoid.cartographicToCartesian(
                    this.transformWGS84ToCartographic(positions[i])
                );
                h += oel.x * el.y - el.x * oel.y;
            }
            result = Math.abs(h).toFixed(2);
        }
        return result;
    }

    /**
     * Calculate SURFACE Area of the given Polygon, not projected horizontal area.
     * |(VectorA X VectorB) | / 2 = Area of the triangle
     * @param {*} positions
     */
    getPolygonArea(positions) {
        let area = 0;
        if (positions) {
            let hierarchy = new Cesium.PolygonHierarchy();
            hierarchy.positions = positions;
            let indices = Cesium.PolygonPipeline.triangulate(hierarchy.positions, hierarchy.holes);
            for (let i = 0; i < indices.length; i += 3) {
                let vector1 = hierarchy.positions[indices[i]];
                let vector2 = hierarchy.positions[indices[i + 1]];
                let vector3 = hierarchy.positions[indices[i + 2]];
                let vectorC = Cesium.Cartesian3.subtract(vector2, vector1, new Cesium.Cartesian3());
                let vectorD = Cesium.Cartesian3.subtract(vector3, vector1, new Cesium.Cartesian3());
                let areaVector = Cesium.Cartesian3.cross(vectorC, vectorD, new Cesium.Cartesian3());
                area += Cesium.Cartesian3.magnitude(areaVector) / 2.0;
            }
        }
        return area;
    }

    getDistanceCalculation(positions) {
        return ((this.getPositionDistance(this.transformCartesianArrayToWGS84Array(positions)) / 1000) * 1000).toFixed(3) + 'm';
    }

    getAreaCalculation(positions) {
        return this.getPolygonArea(positions).toLocaleString() + ' ㎡';

    }

    /***
     *
     * @param opposite
     * @param hypotenuse
     * @returns {number}
     */
    getTriangleDegrees(opposite, hypotenuse) {
        return Number.parseInt((Math.asin(opposite / hypotenuse) * 180) / Math.PI);
    }

    getTriangleHeight(positions) {
        let hypotenusePositions = positions.e;

        let hypCarthoPointA = Cesium.Cartographic.fromCartesian(hypotenusePositions[0]);
        let hypCarthoPointB = Cesium.Cartographic.fromCartesian(hypotenusePositions[1]);
        let opposite = null;

        if (hypCarthoPointA.height > hypCarthoPointB.height) {
            opposite = positions.e2;
        } else {
            opposite = positions.e3;
        }
        // Fixed error when opposite do not have a value (missing positions needed for cartesian)
        let oppositeCarthoPointA = 0;
        let oppositeCarthoPointB = 0;
        if (opposite.length == 2) {
            oppositeCarthoPointA = Cesium.Cartographic.fromCartesian(opposite[0]);
            oppositeCarthoPointB = Cesium.Cartographic.fromCartesian(opposite[1]);
        }
        return Math.abs(oppositeCarthoPointA.height - oppositeCarthoPointB.height).toFixed(3);
    }

    getTriangleAdjacentLength(positions) {
        let hypotenusePositions = positions.e;

        let hypCarthoPointA = Cesium.Cartographic.fromCartesian(hypotenusePositions[0]);
        let hypCarthoPointB = Cesium.Cartographic.fromCartesian(hypotenusePositions[1]);
        let adjacent = positions.e3;

        if (hypCarthoPointB.height > hypCarthoPointA.height) {
            adjacent = positions.e2;
        }


        return this.getPositionDistance(this.transformCartesianArrayToWGS84Array(adjacent));
    }

    getTriangleHypotenuseLength(positions) {
        return this.getPositionDistance(this.transformCartesianArrayToWGS84Array(positions.e));
    }

    getHeightCalculation(positions) {
        let hypotenusePositions = positions.e;

        let hypCarthoPointA = Cesium.Cartographic.fromCartesian(hypotenusePositions[0]);
        let hypCarthoPointB = Cesium.Cartographic.fromCartesian(hypotenusePositions[1]);
        let opposite = positions.e2;

        if (hypCarthoPointA.height > hypCarthoPointB.height) {
            opposite = positions.e3;
        }

        let oppositeCarthoPointA = Cesium.Cartographic.fromCartesian(opposite[0]);
        let oppositeCarthoPointB = Cesium.Cartographic.fromCartesian(opposite[1]);


        let adjacent = positions.e2;

        if (hypCarthoPointB.height > hypCarthoPointA.height) {
            adjacent = positions.e3;
        }


        this.getPositionDistance(this.transformCartesianArrayToWGS84Array(adjacent));

        return {
            e: this.getPositionDistance(this.transformCartesianArrayToWGS84Array(hypotenusePositions)) + ' m',
            e2: Math.abs(oppositeCarthoPointA.height - oppositeCarthoPointB.height).toFixed(3) + ' m',
            e3: this.getPositionDistance(this.transformCartesianArrayToWGS84Array(adjacent)) + ' m'
        };
    }
    // profile distance calculation
    getProfileCalculation(positions) {
        const viewer = this._viewer;
        let updatedPositions = [];
        positions.e.forEach((position) => {
            let p = new Cesium.Cartesian3(Number(position.x), Number(position.y), Number(position.z));
            updatedPositions.push(p);
        })
        let crossSection = new CrossSection(viewer, worker);
        return Number(crossSection.getTotalDistance(updatedPositions)).toFixed(3);
    }

    getAngleCalculation(positions) {
        //e
        var e = this.getTriangleHypotenuseLength(positions) + 'm';
        //e2
        var e2 = this.getTriangleHeight(positions) + 'm';
        //e3
        var e3 = this.getTriangleAdjacentLength(positions) + 'm';

        let oppositePoints = positions.e3;
        let hypotenusePoints = positions.e;

        let opposite = this.getPositionDistance(this.transformCartesianArrayToWGS84Array(oppositePoints));
        let hypotenuse = this.getPositionDistance(this.transformCartesianArrayToWGS84Array(hypotenusePoints));

        let degreesPointA = this.getTriangleDegrees(opposite, hypotenuse);
        let degressPointC = 90;
        let degreesPointB = 180 - (degreesPointA + degressPointC);

        return {
            e: e,
            e2: e2,
            e3: e3,
            angles: {
                a: degreesPointA + ' °',
                b: degreesPointB + ' °',
                c: degressPointC + ' °'
            }
        };
    }

    /**
     * Retrieves the mouse position.
     * @param {Object} position - The position object.
     * @returns {Object} - The mouse position object.
     */
    getMousePosition(position, tool = null) {
        // Get the hovered point position
        let hoveredPointPosition = MeasurementSnapping.getHoveredPointPosition();
        // If a hovered point position exists, return it; otherwise, convert the position to Cartesian3
        return hoveredPointPosition ? hoveredPointPosition : this.getCartesian3FromPX(position, false, tool);
    }

    /**
     * Handler function that detects if there is a hovered point.
     * If a point is being hovered over, it changes the cursor to 'add' mode.
     * @param {Object} position - Object containing the x, y, and z coordinates of the mouse position.
     */
    mouseHoverHandler(position) {

        //detect if there is a hovered point
        MeasurementSnapping.mouseMoveSnapToPointListener(position, this._viewer);

        //change cursor to 'add' if point is hovered
        if (this.cesiumComponent) {
            this.cesiumComponent.snapCursor = MeasurementSnapping.getHoveredPointPosition() ? true : false;
        }
    }

    /**
     * function that will if selected number of points are valid
     * @param {Object} points positions of entity
     * @param {Integer} limit minimum number of points
     * @param {Boolean} fix default false
     * @returns boolean
     */
    validateMeasurementNumberOfPoints(points, limit, fix) {
        this.clearPrompt()
        let p = []
        points.forEach(e => { p.push(e) });
        p.pop()
        if (p.length < limit) {
            this.invalidNumberOfPointsPrompt(limit, fix)
            return false
        }
        return true
    }

    /**
     * Prompt for invalid number of points
     * @param {Integer} limit 
     * @param {Boolean} fix default false
     */
    invalidNumberOfPointsPrompt(limit = 2, fix = false) {
        this.clearPrompt()
        let txt = (this._lang == 'en') ? "Please select " + limit + " or more points." : limit + "点以上選択してください。"
        if (fix) {
            txt = (this._lang == 'en') ? "Please select " + limit + " points." : limit + "点選択してください。"
        }
        this.toastrPrompt = this.toastr.show(txt, {
            type: 'warning',
            position: 'top',
            duration: false,
            dismissible: true
        });
        if (this.cesiumComponent) {
            this.cesiumComponent.crosshairCursor = true;
        }
    }

    /**
     * Function that clears all the hovered points and removes the 'add' cursor mode.
     */
    clearMeasurementSnapping() {

        //clear all the hovered points
        MeasurementSnapping.clearHoveredPoint();

        //remove the 'add' cursor mode
        if (this.cesiumComponent) {
            this.cesiumComponent.snapCursor = false;
        }
    }

    clearPrompt() {
        if (this.toastrPrompt !== null) {
            this.toastrPrompt.destroy()
        }
    }

    /**
     * 测距
     * @param {*} options
     */

    drawPoint(options = {}) {
        if (this._viewer && options) {
            if ("lang" in options) {
                this._lang = options.lang;
            }
            let $this = this,
                lineObj, ids = [];
            this.clear();
            this._handler = new Cesium.ScreenSpaceEventHandler(this._viewer.scene.canvas);
            // left
            this._handler.setInputAction((movement) => {
                let refs = options.refs;

                let cartesian = $this.getCartesian3FromPX(movement.position, false, 'point');
                if (cartesian && cartesian.x) {
                    refs.propertyRef.setLoadingState({
                        state: true,
                        tool: 'measure'
                    });
                    this.clear();
                    _addInfoPoint(cartesian);
                    options.callback(({
                        positions: cartesian,
                        id: ids
                    }), lineObj);
                }
            }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

            // Reproducing the created entity.
            if (options.position) {
                this.clear();

                //create cesium cartesian3 from position from database
                let pointPosition = new Cesium.Cartesian3(options.position.x, options.position.y, options.position.z);

                _addInfoPoint(pointPosition, options.label);
                options.callback(({
                    positions: pointPosition,
                    id: ids
                }));
            }

            function _addInfoPoint(position, label = 'point') {

                //Create label Entity
                let _labelEntity = new Cesium.Entity();
                if (position && $this.selectedEpsg) {
                    _labelEntity.position = position;
                    _labelEntity.label = {
                        text: new Cesium.CallbackProperty(function () {
                            let _pos = convert_xyz(position, $this.selectedEpsg, 'point');

                            //get max character length
                            let txtOffset = 3;
                            let maxLength = _pos.x.toString().length + txtOffset;
                            maxLength = (_pos.y.toString().length + txtOffset) > maxLength ? (_pos.y.toString().length + txtOffset) : maxLength;
                            maxLength = (_pos.z.toString().length + txtOffset) > maxLength ? (_pos.z.toString().length + txtOffset) : maxLength;
                            maxLength = label.length > maxLength ? label.length : maxLength;

                            return centerText(label, maxLength);
                        }, true),
                        font: '12px monospace',
                        show: true,
                        showBackground: true,
                        backgroundColor: Cesium.Color.BLACK.withAlpha(0.8),
                        backgroundPadding: new Cesium.Cartesian2(10, 5),
                        horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
                        verticalOrigin: Cesium.VerticalOrigin.TOP,
                        pixelOffset: new Cesium.Cartesian2(10, -20),
                        scale: 1,
                        disableDepthTestDistance: Number.POSITIVE_INFINITY
                    };
                }
                _labelEntity.nonMovable = true;
                const l = $this._drawLayer.entities.add(_labelEntity);
                ids.push(l.id);

                //create point
                let _positionEntity = new Cesium.Entity();
                _positionEntity.position = position;
                _positionEntity.point = {
                    pixelSize: 7,
                    color: Cesium.Color.CRIMSON.withAlpha(0.8),
                    outlineColor: Cesium.Color.WHITE,
                    outlineWidth: 1,
                    disableDepthTestDistance: Number.POSITIVE_INFINITY
                };

                //create details
                if (position && $this.selectedEpsg) {
                    const text = new Cesium.CallbackProperty(function () {
                        let _pos = convert_xyz(position, $this.selectedEpsg, 'point');
                        let caption = 'X: ' + _pos.x + '\n' + 'Y: ' + _pos.y + '\n' + 'Z: ' + _pos.z;
                        return caption;
                    }, true);

                    _positionEntity.label = {
                        text: text,
                        font: '12px monospace',
                        show: true,
                        showBackground: true,
                        backgroundColor: Cesium.Color.BLACK.withAlpha(0.6),
                        backgroundPadding: new Cesium.Cartesian2(10, 10),
                        outlineWidth: 2,
                        outlineColor: Cesium.Color.WHITE,
                        horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
                        verticalOrigin: Cesium.VerticalOrigin.TOP,
                        pixelOffset: new Cesium.Cartesian2(10, 0),
                        scale: 1,
                        disableDepthTestDistance: Number.POSITIVE_INFINITY
                    };

                    _positionEntity.snappablePoint = true;
                    _positionEntity.originalXYZ = position.clone();
                    const p = $this._drawLayer.entities.add(_positionEntity);
                    ids.push(p.id);
                }
                _positionEntity.secondLabel = _labelEntity;
            }

            function centerText(text, numberOfSpaces) {
                text = text.trim();
                var l = text.length;
                var w2 = Math.ceil(numberOfSpaces / 2);
                var l2 = Math.ceil(l / 2);
                var s = new Array(w2 - l2 + 1).join(" ");
                text = s + text + s;
                if (text.length < numberOfSpaces) {
                    text += new Array(numberOfSpaces - text.length + 1).join(" ");
                }
                return text;
            }


        }
    }

    drawAnnotation(options = {}) {
        if (this._viewer && options) {
            if ("lang" in options) {
                this._lang = options.lang;
            }
            var $this = this,
                lineObj, ids = [];
            this.clear();
            this._handler = new Cesium.ScreenSpaceEventHandler(this._viewer.scene.canvas);
            // left
            this._handler.setInputAction((movement) => {
                var refs = options.refs;
                refs.propertyRef.setLoadingState({
                    state: true,
                    tool: 'measure'
                });
                var cartesian = $this.getCartesian3FromPX(movement.position, false, 'annotation');
                if (cartesian && cartesian.x) {

                    this.clear();

                    _addInfoPoint(cartesian);
                    options.callback(({
                        positions: cartesian,
                        id: ids
                    }), lineObj);
                }
            }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

            function _addInfoPoint(position, label = 'annotation') {
                let _labelEntity = new Cesium.Entity();
                _labelEntity.name = 'annotation'
                _labelEntity.position = position;
                _labelEntity.point = {
                    pixelSize: 7,
                    color: Cesium.Color.CRIMSON.withAlpha(0.8),
                    outlineColor: Cesium.Color.WHITE,
                    outlineWidth: 1,
                    disableDepthTestDistance: Number.POSITIVE_INFINITY
                };
                _labelEntity.label = {
                    text: label,
                    font: '14px arial',
                    show: true,
                    showBackground: true,
                    backgroundColor: Cesium.Color.BLACK,
                    horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
                    verticalOrigin: Cesium.VerticalOrigin.TOP,
                    pixelOffset: new Cesium.Cartesian2(0, -50),
                    scale: 1,
                    disableDepthTestDistance: Number.POSITIVE_INFINITY
                };
                var e = $this._drawLayer.entities.add(_labelEntity);
                ids.push(e.id);
            }

            if (options.position) {
                var position = options.position;
                var label = options.label || 'annotation';
                this.clear();
                _addInfoPoint(position, label);

                options.callback(({
                    positions: position,
                    id: ids
                }), lineObj);
            }
        }
    }

    async finishSelection(tool) {
        // Move the content of the original finishSelection function here.
        // Remember to replace `this` with `this.` to access class properties and methods.
        let valid = this.validateMeasurementNumberOfPoints(this.tempPos, 2);
        let data =
        {
            positions: this.tempPos,
            id: this.entityIds,
        }
        switch (tool) {
            case 'line':
                if (!valid) return false;
                this.clear();
                this.tempPos.pop();
                // this._refs.cesiumRef.$emit('on-toggle-draggable', true);
                this.clearMeasurementSnapping();
                this.reCreateLine();
                break;
            case 'area':
                valid = this.validateMeasurementNumberOfPoints(this.tempPos, 3);
                if (!valid) return
                this.clear();
                this.tempPos.pop();
                this.tempPolygonPositions.pop();
                this.tempPolygon.positions.pop();
                // refs.cesiumRef.$emit('on-toggle-draggable', true);
                this.tempPolygonEntity.areaPositions = this.tempPos;
                this.tempPolygon.positions = this.tempPos;
                //clear hovered position
                this.clearMeasurementSnapping();
                break;
            case 'volume':
                let success = await this.volumeMeasurement.rightClickHandler();
                if (success) {
                    this._handler.destroy();
                    this._handler = null;
                    data =
                    {
                        points: this.tempPos,
                        id: this.entityIds,
                    }
                } else {
                    return false;
                }
                break;
            case 'profile':
                let _pos = this.tempPos.pop();
                valid = this.validateMeasurementNumberOfPoints(this.tempPos, 2);
                this.tempPos.push(_pos);
                if (!valid) return false;
                this._handler.destroy();
                this._handler = null;
                this.tempPos.pop();
                if (this.tempPos.length > 1) {
                    // refs.cesiumRef.$emit('on-toggle-draggable', true);
                    this.tempPos.pop();
                    data = {
                        e: this.tempPos,
                        e2: this.tempPoints,
                        id: this.entityIds
                    };
                    let result = this.sampleHeights(this._viewer, data, false, 0, this.pointEntities, [], this.options);
                    data.samplePromise = result;
                    //clear hovered position
                    this.clearMeasurementSnapping();
                    this._drawLayer.entities.remove(this.lineObj);
                } else {
                    this._drawLayer.entities.removeById(this.entityIds.pop());
                }
                break;
        }
        this.tempPos = [];
        this.lineObj = [];
        this.tempPolygonPositions = [];
        this.tempPolygon = new Cesium.PolygonHierarchy();
        this.tempPolygonEntity = new Cesium.Entity();
        this.tempPoints = [];
        this.pointEntities = [];
        // this.primitives = this._viewer.scene.primitives;
        return data;
    }
    reCreateLine() {
        this.lineObj.polyline = {
            width: 2,
            clampToGround: this.options.clampToGround || false,
            material: Cesium.Color.YELLOW.withAlpha(0.8),
            depthFailMaterial: Cesium.Color.YELLOW.withAlpha(0.8),
            positions: this.tempPos
        };
    }

    drawLineMeasureGraphics(options = {}) {
        if (this._viewer && options) {
            if ("lang" in options) {
                this._lang = options.lang;
            }
            const line_parent_id = 'line_measure_' + (Math.random() + 1).toString(36).substring(7)
            let positions = [],
                $this = this,
                lineObj, ids = [],
                pointsObj = [];
            let refs = options.refs;
            this.clear();
            this._handler = new Cesium.ScreenSpaceEventHandler(this._viewer.scene.canvas);
            // left
            this._handler.setInputAction((movement) => {
                this.clearPrompt()
                refs.cesiumRef.$emit('on-toggle-draggable', false);
                refs.propertyRef.setLoadingState({
                    state: true,
                    tool: 'measure'
                });


                let cartesian = this.getMousePosition(movement.position, 'line');

                if (cartesian && cartesian.x) {

                    if (positions.length > 0) {
                        positions.pop();
                        $this.tempPos.pop();
                    }

                    //Insert new Coordinate 
                    positions.push(cartesian.clone());
                    $this.tempPos.push(cartesian.clone());

                    _addInfoPoint(cartesian, positions.map((pos) => pos));
                    //Insert new coordinate for storing coordinates for move event
                    positions.push(cartesian.clone());
                    $this.tempPos.push(cartesian.clone());
                    if (!lineObj) create();
                }
            }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

            this._handler.setInputAction((movement) => {

                //Handles Hover Event for GCP and Point Measurement
                this.mouseHoverHandler(movement.endPosition, refs.cesiumRef);

                let cartesian = $this.getCartesian3FromPX(movement.endPosition);
                if (positions.length >= 2) {
                    if (cartesian && cartesian.x) {
                        //removes old position
                        positions.pop();
                        $this.tempPos.pop();
                        //inserts new position
                        positions.push(cartesian);
                        $this.tempPos.push(cartesian.clone());
                    }
                }
            }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
            // right
            this._handler.setInputAction((movement) => {
                //only teriminate line measurement when points is more than on the viewer
                let valid = this.validateMeasurementNumberOfPoints(positions, 2)
                if (!valid) return
                this.clear();
                positions.pop();
                $this.tempPos.pop();
                refs.cesiumRef.$emit('on-toggle-draggable', true);
                //clear stored hovered points
                this.clearMeasurementSnapping();
                //save to database and finish measruements
                if (typeof options.callback === 'function') {
                    reCreateLine()
                    options.callback(({
                        positions: positions,
                        id: ids
                    }), lineObj);
                }
            }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);

            function create() {
                let _lineEntity = new Cesium.Entity({
                    entity_id: line_parent_id
                })
                _lineEntity.polyline = {
                    width: 2,
                    material: options.material || Cesium.Color.YELLOW.withAlpha(0.8),
                    clampToGround: options.clampToGround || false,
                };
                _lineEntity.polyline.positions = new Cesium.CallbackProperty(function () {
                    return positions;
                }, false);

                lineObj = $this._drawLayer.entities.add(_lineEntity);
                $this.lineObj = lineObj;
                ids.push(lineObj.id);
                $this.entityIds.push(lineObj.id);
            }

            function reCreateLine() {
                lineObj.polyline = {
                    width: 2,
                    clampToGround: options.clampToGround || false,
                    material: Cesium.Color.YELLOW.withAlpha(0.8),
                    depthFailMaterial: Cesium.Color.YELLOW.withAlpha(0.8),
                    positions: positions
                };
                // $this.lineObj.polyline = lineObj.polyline;
            }

            if (options.p) {
                if (options.p.length > 0) {
                    this.clear();
                }
                var _pos = options.p;
                Object.keys(_pos).forEach(key => {
                    var _point = _pos[key];
                    positions.push(_point);
                    let positionCopy = positions.map((pos) => pos);
                    _addInfoPoint(_point, positionCopy);
                    if (_pos.length == (Number(key) + 1)) {
                        var _lineEntity = new Cesium.Entity({
                            entity_id: line_parent_id
                        });
                        _lineEntity.polyline = {
                            width: 2,
                            material: options.material || Cesium.Color.YELLOW.withAlpha(0.8),
                            depthFailMaterial: Cesium.Color.YELLOW.withAlpha(0.8),
                            clampToGround: false,
                            positions: positions
                        };
                        lineObj = $this._drawLayer.entities.add(_lineEntity);
                        $this.lineObj = lineObj;
                        ids.push(lineObj.id);
                        $this.entityIds.push(lineObj.id);
                    }
                    options.callback(({
                        positions: positions,
                        id: ids
                    }), lineObj);
                });
            }

            function _addInfoPoint(position, positions) {
                let _labelEntity = new Cesium.Entity({
                    parent_id: line_parent_id
                });
                _labelEntity.position = position;
                _labelEntity.point = {
                    pixelSize: 7,
                    color: Cesium.Color.CRIMSON.withAlpha(0.8),
                    outlineColor: Cesium.Color.WHITE,
                    outlineWidth: 1,
                    disableDepthTestDistance: Number.POSITIVE_INFINITY
                };
                _labelEntity.label = {
                    text: new Cesium.CallbackProperty(function () {
                        return (($this.getPositionDistance(
                            $this.transformCartesianArrayToWGS84Array(positions)) / 1000) * 1000)
                            .toFixed(3) + ' m';
                    }, false),
                    show: true,
                    showBackground: true,
                    backgroundColor: Cesium.Color.BLACK,
                    font: '14px arial',
                    horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
                    verticalOrigin: Cesium.VerticalOrigin.TOP,
                    pixelOffset: new Cesium.Cartesian2(-20, -50), //left top
                    borderRadius: '10px',
                    disableDepthTestDistance: Number.POSITIVE_INFINITY
                };
                const e = $this._drawLayer.entities.add(_labelEntity);
                pointsObj.push(e);
                ids.push(e.id);
                $this.entityIds.push(e.id);
            }

            function pickGlobeIntersection(viewer, p0, p1) {
                //all positions are in Cartesian3
                var direction = new Cesium.Cartesian3();
                Cesium.Cartesian3.subtract(p1, p0, direction);
                Cesium.Cartesian3.normalize(direction, direction);

                var ray = new Cesium.Ray(p0, direction);
                var hitPos = viewer.scene.globe.pick(ray, viewer.scene);

                if ((hitPos !== undefined) && (hitPos !== null)) {
                    return hitPos;
                } else {
                    return null;
                }
            }
        }
    }

    /**
     * area
     * @param {*} options
     */
    drawAreaMeasureGraphics(options = {}) {
        if (this._viewer && options) {
            if ("lang" in options) {
                this._lang = options.lang;
            }
            let positions = [],
                polygonPositions = [],
                polygon = new Cesium.PolygonHierarchy(),
                _polygonEntity = new Cesium.Entity(),
                $this = this,
                polyObj = null,
                ids = [];

            let refs = options.refs;

            this.clear();

            if (!options.p) {
                this._handler = new Cesium.ScreenSpaceEventHandler(this._viewer.scene.canvas);
                // left
                this._handler.setInputAction((movement) => {
                    this.clearPrompt()
                    refs.cesiumRef.$emit('on-toggle-draggable', false);
                    refs.propertyRef.setLoadingState({
                        state: true,
                        tool: 'measure'
                    });

                    let cartesian = this.getMousePosition(movement.position, 'area');

                    if (cartesian && cartesian.x) {
                        if (positions.length === 0) {
                            _addInfoPoint(cartesian.clone(), true, polygonPositions);
                        } else {
                            //remove coordinates inserted by the move event to be relace with the final position from left click
                            positions.pop();
                            this.tempPos.pop();
                            polygonPositions.pop();
                            polygon.positions.pop();
                            _addInfoPoint(cartesian.clone(), false);
                        }
                        //Add latest coordinates to the array
                        positions.push(cartesian.clone());
                        this.tempPos.push(cartesian.clone());
                        polygonPositions.push(cartesian.clone());
                        polygon.positions.push(cartesian.clone());

                        //use by move event
                        positions.push(cartesian.clone());
                        this.tempPos.push(cartesian.clone());
                        polygonPositions.push(cartesian.clone());
                        polygon.positions.push(cartesian.clone());
                    }
                }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
                // mouse
                this._handler.setInputAction((movement) => {
                    //Handles Hover Event for GCP and Point Measurement
                    this.mouseHoverHandler(movement.endPosition, refs.cesiumRef);

                    const cartesian = $this.getCartesian3FromPX(movement.endPosition, false, 'area');
                    if (positions.length >= 2) {
                        if (cartesian && cartesian.x) {
                            //Remove old coordinates
                            positions.pop();
                            this.tempPos.pop();
                            polygonPositions.pop();
                            positions.push(cartesian.clone());
                            this.tempPos.push(cartesian.clone());
                            polygonPositions.push(cartesian.clone());
                            //Insert new coordinates      
                            polygon.positions.pop();
                            polygon.positions.push(cartesian.clone());
                        }
                    }
                }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

                // right
                this._handler.setInputAction(() => {

                    let valid = this.validateMeasurementNumberOfPoints(positions, 3)
                    if (!valid) return

                    this.clear();

                    positions.pop();
                    polygonPositions.pop();
                    polygon.positions.pop();

                    refs.cesiumRef.$emit('on-toggle-draggable', true);

                    if (typeof options.callback === 'function') {

                        // this is causing the floating points while creating
                        _polygonEntity.areaPositions = positions;
                        polygon.positions = positions;

                        //clear hovered position
                        this.clearMeasurementSnapping();

                        //save to database and finish measurement
                        options.callback(({
                            positions: positions,
                            id: ids
                        }), polyObj);
                    }
                }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);

                _polygonEntity.polyline = {
                    width: 2,
                    material: Cesium.Color.YELLOW.withAlpha(0.8),
                    clampToGround: options.clampToGround || false
                };
                _polygonEntity.polyline.positions = new Cesium.CallbackProperty(function () {
                    const polylinePositions = positions.map((p) => p);
                    if (polylinePositions.length > 0)
                        polylinePositions.push(positions[0])
                    return polylinePositions;
                }, false);
                _polygonEntity.polygon = {
                    hierarchy: new Cesium.CallbackProperty(function () {
                        return polygon;
                    }, false),
                    material: Cesium.Color.YELLOW.withAlpha(0.4),
                    classificationType: Cesium.ClassificationType.BOTH
                };
                polyObj = $this._drawLayer.entities.add(_polygonEntity);
                ids.push(polyObj.id);
                $this.entityIds = ids;
            }
            // execute via createEntity in cesium component
            else {
                if (options.p.length > 0) {
                    this.clear();
                }
                let _pos = options.p.map(pos => new Cesium.Cartesian3(pos.x, pos.y, pos.z));
                /** need to initialize a new variable for polygon position cause it will change 
                    the initial value of the variable after the assignment at line 1017;
                    also cannot do the assignment same as below cause it will still change the 
                    values of the _pos  
                    let polygonPos = _pos */
                let _polygonPositions = _pos.map(pos => pos.clone());

                _polygonEntity.polyline = {
                    width: 2,
                    material: Cesium.Color.YELLOW.withAlpha(0.8),
                    clampToGround: true//options.clampToGround || false
                };
                const positionsLine = _polygonPositions.map((p) => p);
                positionsLine.push(_polygonPositions[0]);
                _polygonEntity.polyline.positions = new Cesium.CallbackProperty(function () {
                    return positionsLine;
                }, false);
                _polygonEntity.polygon = {
                    hierarchy: new Cesium.CallbackProperty(function () {
                        return polygon;
                    }, false),
                    material: Cesium.Color.YELLOW.withAlpha(0.4),
                    classificationType: Cesium.ClassificationType.CESIUM_3D_TILE
                };
                for (let index = 0; index < _pos.length; index++) {
                    if (index === 0) _addInfoPoint(_pos[0], true, _pos);
                    else _addInfoPoint(_pos[index], false);
                }
                _polygonEntity.areaPositions = _polygonPositions;
                polygon.positions = _polygonPositions;
                polyObj = $this._drawLayer.entities.add(_polygonEntity);
                ids.push(polyObj.id);
                $this.entityIds = ids;

                options.callback(({
                    positions: _pos,
                    id: ids
                }), polyObj);
            }

            function _addInfoPoint(position, showLabel = false, positions = undefined) {
                let _labelEntity = new Cesium.Entity();
                _labelEntity.position = position;
                _labelEntity.point = {
                    pixelSize: 7,
                    color: Cesium.Color.CRIMSON.withAlpha(0.8),
                    outlineColor: Cesium.Color.WHITE,
                    outlineWidth: 1,
                    disableDepthTestDistance: Number.POSITIVE_INFINITY
                    // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND//DEFAULT NONE
                };
                if (showLabel) {
                    _labelEntity.label = {
                        text: new Cesium.CallbackProperty(function () {
                            let area = $this.getPolygonArea(positions)
                                .toLocaleString() + ' ㎡';
                            return showLabel ? area : '';
                        }, false),
                        show: true,
                        showBackground: true,
                        backgroundColor: Cesium.Color.BLACK,
                        font: '14px arial',
                        // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
                        horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
                        verticalOrigin: Cesium.VerticalOrigin.TOP,
                        pixelOffset: new Cesium.Cartesian2(-20, -50), //left top
                        // scale: 1,
                        borderRadius: '10px',
                        disableDepthTestDistance: Number.POSITIVE_INFINITY
                    };
                }
                const e = $this._drawLayer.entities.add(_labelEntity);
                ids.push(e.id);
                $this.entityIds = ids;
            }

        }
    }

    /**
     * 画三角量测
     * @param {*} options
     */
    drawTrianglesMeasureGraphics(options = {}) {
        options.style = options.style || {
            width: 2,
            material: Cesium.Color.YELLOW
        };
        let data = {};
        if (this._viewer && options) {
            if ("lang" in options) {
                this._lang = options.lang;
            }

            var _trianglesEntity = new Cesium.Entity(),
                _tempLineEntity = new Cesium.Entity(),
                _tempLineEntity2 = new Cesium.Entity(),
                _positions = [],
                _tempPoints = [],
                _tempPoints2 = [],
                $this = this,
                ids = [],
                degrees = 0;
            $this.entityIds = [];
            this.clear();
            this._handler = new Cesium.ScreenSpaceEventHandler(this._viewer.scene.canvas);
            const canvas = $this._viewer.scene.canvas;

            // 高度
            function _getHeading(startPosition, endPosition) {
                if (!startPosition && !endPosition) return 0;
                if (Cesium.Cartesian3.equals(startPosition, endPosition)) return 0;
                let cartographic = Cesium.Cartographic.fromCartesian(startPosition);
                let cartographic2 = Cesium.Cartographic.fromCartesian(endPosition);
                return (cartographic2.height - cartographic.height).toFixed(2);
            }

            // 偏移点
            function _computesHorizontalLine(positions) {
                let cartographic = Cesium.Cartographic.fromCartesian(positions[0]);
                let cartographic2 = Cesium.Cartographic.fromCartesian(positions[1]);
                if (cartographic.height > cartographic2.height) {
                    return Cesium.Cartesian3.fromDegrees(
                        Cesium.Math.toDegrees(cartographic.longitude),
                        Cesium.Math.toDegrees(cartographic.latitude),
                        cartographic2.height
                    );
                }

                return Cesium.Cartesian3.fromDegrees(
                    Cesium.Math.toDegrees(cartographic2.longitude),
                    Cesium.Math.toDegrees(cartographic2.latitude),
                    cartographic.height
                );
            }


            // left
            this._handler.setInputAction((movement) => {
                this.clearPrompt()
                var refs = options.refs;
                refs.propertyRef.setLoadingState({
                    state: true,
                    tool: 'measure'
                });

                let position = this.getMousePosition(movement.position, 'angle');

                if (!position && !position.z && _positions.length == 0) return false;
                if (_positions.length == 0) {
                    _positions.push(position.clone());
                    _positions.push(position.clone());
                    _tempPoints.push(position.clone());
                    _tempPoints.push(position.clone());
                    refs.cesiumRef.$emit('on-toggle-draggable', false);
                } else {
                    if (canvas.width <= 540) {
                        _positions.pop();
                        _positions.push(position.clone());
                        let horizontalPosition = _computesHorizontalLine(_positions);
                        //高度
                        _tempPoints.pop();
                        _tempPoints.push(horizontalPosition.clone());
                        //水平线
                        _tempPoints2.pop(), _tempPoints2.pop();
                        _tempPoints2.push(position.clone());
                        _tempPoints2.push(horizontalPosition.clone());

                        var opposite = $this.getPositionDistance($this.transformCartesianArrayToWGS84Array(_tempPoints2));

                        var hypotenuse = $this.getPositionDistance($this.transformCartesianArrayToWGS84Array(_positions));

                        if (opposite && hypotenuse) {
                            degrees = $this.getTriangleDegrees(opposite, hypotenuse);
                        }
                        refs.floatingBarRef.$emit('on-complete-selection');
                    }
                    this.clear();
                    this.clearMeasurementSnapping();
                    refs.cesiumRef.$emit('on-toggle-draggable', true);
                    if (typeof options.callback === 'function') {
                        data = { e: _positions, e2: _tempPoints, e3: _tempPoints2, id: ids };
                        options.callback(data);
                    }
                }
            }, Cesium.ScreenSpaceEventType.LEFT_DOWN);

            // right
            this._handler.setInputAction((movement) => {
                var refs = options.refs;
                refs.propertyRef.setLoadingState({
                    state: true,
                    tool: 'measure'
                });
                if (_positions.length > 0) {
                    let valid = this.validateMeasurementNumberOfPoints(_positions, 2, true)
                    if (!valid) return

                    this.clear();
                    this.clearMeasurementSnapping();
                    refs.cesiumRef.$emit('on-toggle-draggable', true);
                    if (typeof options.callback === 'function') {
                        data = { e: _positions, e2: _tempPoints, e3: _tempPoints2, id: ids };
                        options.callback(data);
                    }
                }
            }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);

            // mouse
            this._handler.setInputAction((movement) => {
                this.mouseHoverHandler(movement.endPosition, options.refs.cesiumRef);

                let position = this.getMousePosition(movement.endPosition, 'angle');

                if (position && _positions.length > 0) {
                    //直线
                    _positions.pop();
                    _positions.push(position.clone());
                    let horizontalPosition = _computesHorizontalLine(_positions);
                    //高度
                    _tempPoints.pop();
                    _tempPoints.push(horizontalPosition.clone());
                    //水平线
                    _tempPoints2.pop(), _tempPoints2.pop();
                    _tempPoints2.push(position.clone());
                    _tempPoints2.push(horizontalPosition.clone());

                    var opposite = $this.getPositionDistance($this.transformCartesianArrayToWGS84Array(_tempPoints2));

                    var hypotenuse = $this.getPositionDistance($this.transformCartesianArrayToWGS84Array(_positions));

                    if (opposite && hypotenuse) {
                        degrees = $this.getTriangleDegrees(opposite, hypotenuse);
                    }

                }
            }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);


            if (options.p) {
                this.clear();
                var _pos = options.p;
                _positions = _pos.e;
                _tempPoints = _pos.e2;
                _tempPoints2 = _pos.e3;

                var opposite = null;
                var hypotenuse = null;
                options.callback({ e: _positions, e2: _tempPoints, e3: _tempPoints2, id: ids });
            }
            // create entity
            //直线
            _trianglesEntity.polyline = {
                positions: new Cesium.CallbackProperty(function () {
                    return _positions;
                }, false),
                ...options.style
            };

            //POINT 1
            _trianglesEntity.position = new Cesium.CallbackProperty(function () {
                return _positions[0];
            }, false);
            _trianglesEntity.point = {
                pixelSize: 7,
                color: Cesium.Color.CRIMSON.withAlpha(0.8),
                outlineColor: Cesium.Color.WHITE,
                outlineWidth: 1,
                disableDepthTestDistance: Number.POSITIVE_INFINITY
            };
            _trianglesEntity.label = {
                text: new Cesium.CallbackProperty(function () {
                    if (_positions.length >= 2 && _tempPoints.length >= 2) {
                        let cartographic = Cesium.Cartographic.fromCartesian(_positions[0]);
                        let cartographic2 = Cesium.Cartographic.fromCartesian(_positions[1]);
                        if (cartographic.height > cartographic2.height) {
                            opposite = $this.getPositionDistance($this.transformCartesianArrayToWGS84Array(_tempPoints2));
                            hypotenuse = $this.getPositionDistance($this.transformCartesianArrayToWGS84Array(_positions));
                            degrees = $this.getTriangleDegrees(opposite, hypotenuse);

                            cartographic = Cesium.Cartographic.fromCartesian(_tempPoints[0]);
                            cartographic2 = Cesium.Cartographic.fromCartesian(_tempPoints[1]);
                            let heightLabel = '';
                            if (cartographic.height > cartographic2.height) {
                                heightLabel = $this._lang == 'ja' ?
                                    'A 高さ:' + _getHeading(_tempPoints[1], _tempPoints[0]) + 'm' :
                                    'A Height:' + _getHeading(_tempPoints[1], _tempPoints[0]) + 'm';
                            }
                            return $this._lang == 'ja' ?
                                'A 角度:' + degrees + '° | ' +
                                heightLabel :
                                'A Degrees:' + degrees + '° | ' +
                                heightLabel;
                        } else {
                            let opposite = $this.getPositionDistance($this.transformCartesianArrayToWGS84Array(_tempPoints2));
                            let hypotenuse = $this.getPositionDistance($this.transformCartesianArrayToWGS84Array(_positions));
                            let degrees = $this.getTriangleDegrees(opposite, hypotenuse);
                            let distance = $this.getPositionDistance($this.transformCartesianArrayToWGS84Array(_tempPoints));
                            return $this._lang == 'ja' ?
                                'A 角度:' + degrees + '° | ' +
                                'A 距離:' + distance + 'm' :
                                'A Degrees:' + degrees + '° | ' +
                                'A Distance:' + distance + 'm';
                        }

                    }

                }, false),
                show: true,
                showBackground: true,
                font: '14px arial',
                backgroundColor: Cesium.Color.BLACK,
                horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
                verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                pixelOffset: new Cesium.Cartesian2(-0, -0), //left top
                disableDepthTestDistance: Number.POSITIVE_INFINITY
            };

            //高度 Calculated Point
            _tempLineEntity.allowMovement = false;
            _tempLineEntity.polyline = {
                positions: new Cesium.CallbackProperty(function () {
                    return _tempPoints;
                }, false),
                ...options.style
            };
            _tempLineEntity.position = new Cesium.CallbackProperty(function () {
                return _tempPoints2[1];
            }, false);
            _tempLineEntity.point = {
                pixelSize: 7,
                color: Cesium.Color.CRIMSON.withAlpha(0.8),
                outlineColor: Cesium.Color.WHITE,
                outlineWidth: 1,
                disableDepthTestDistance: Number.POSITIVE_INFINITY
            };
            _tempLineEntity.label = {
                text: new Cesium.CallbackProperty(function () {
                    if (_positions.length >= 2 && _tempPoints.length >= 2) {
                        let degrees = 90;
                        let cartographic = Cesium.Cartographic.fromCartesian(_tempPoints[0]);
                        let cartographic2 = Cesium.Cartographic.fromCartesian(_tempPoints[1]);
                        let heightLabel = '';
                        if (cartographic.height < cartographic2.height) {
                            cartographic = Cesium.Cartographic.fromCartesian(_positions[0]);
                            cartographic2 = Cesium.Cartographic.fromCartesian(_positions[1]);
                            if (cartographic.height > cartographic2.height) {
                                heightLabel = $this._lang == 'ja' ?
                                    ' | 高さ:' + _getHeading(_tempPoints[0], _tempPoints[1]) + 'm' :
                                    ' | Height:' + _getHeading(_tempPoints[0], _tempPoints[1]) + 'm';
                            }
                        }
                        return $this._lang == 'ja' ?
                            'C 角度:' + degrees + '°' +
                            heightLabel :
                            'C Degrees:' + degrees + '°' +
                            heightLabel;
                    }
                }, false),
                show: true,
                showBackground: true,
                backgroundColor: Cesium.Color.BLACK,
                font: '14px arial',
                horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
                verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                pixelOffset: new Cesium.Cartesian2(-0, -0), //left top
                disableDepthTestDistance: Number.POSITIVE_INFINITY
            };

            //水平 POINT 2
            _tempLineEntity2.polyline = {
                positions: new Cesium.CallbackProperty(function () {
                    return _tempPoints2;
                }, false),
                ...options.style
            };
            _tempLineEntity2.position = new Cesium.CallbackProperty(function () {
                return _positions[1];
            }, false);
            _tempLineEntity2.point = {
                pixelSize: 7,
                color: Cesium.Color.CRIMSON.withAlpha(0.8),
                outlineColor: Cesium.Color.WHITE,
                outlineWidth: 1,
                disableDepthTestDistance: Number.POSITIVE_INFINITY
            };
            _tempLineEntity2.label = {
                text: new Cesium.CallbackProperty(function () {
                    if (_tempPoints2.length >= 2 && _positions.length >= 2) {
                        let cartographic = Cesium.Cartographic.fromCartesian(_positions[0]);
                        let cartographic2 = Cesium.Cartographic.fromCartesian(_positions[1]);
                        if (cartographic.height > cartographic2.height) {
                            let opposite = $this.getPositionDistance($this.transformCartesianArrayToWGS84Array(_tempPoints2));
                            let hypotenuse = $this.getPositionDistance($this.transformCartesianArrayToWGS84Array(_positions));
                            let degrees1 = $this.getTriangleDegrees(opposite, hypotenuse);
                            let degrees = 180 - (90 + degrees1);
                            let distance = $this.getPositionDistance($this.transformCartesianArrayToWGS84Array(_tempPoints2));
                            return $this._lang == 'ja' ?
                                'B 角度:' + degrees + '° | ' +
                                'B 距離:' + distance + 'm' :
                                'B Degrees:' + degrees + '° | ' +
                                'B Distance:' + distance + 'm';
                        } else {

                            let opposite = $this.getPositionDistance($this.transformCartesianArrayToWGS84Array(_tempPoints2));
                            let hypotenuse = $this.getPositionDistance($this.transformCartesianArrayToWGS84Array(_positions));
                            let degrees1 = $this.getTriangleDegrees(opposite, hypotenuse);
                            let degrees = 180 - (90 + degrees1);

                            let heightLabel = '';
                            heightLabel = $this._lang == 'ja' ?
                                '高さ:' + Math.abs(_getHeading(_tempPoints2[0], _tempPoints2[1])) + 'm' :
                                'Height:' + Math.abs(_getHeading(_tempPoints2[0], _tempPoints2[1])) + 'm';
                            return $this._lang == 'ja' ?
                                'B 角度:' + degrees + '° | ' +
                                heightLabel :
                                'B Degrees:' + degrees + '° | ' +
                                heightLabel;
                        }

                    }

                }, false),
                show: true,
                showBackground: true,
                backgroundColor: Cesium.Color.BLACK,
                font: '14px arial',
                horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
                verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                pixelOffset: new Cesium.Cartesian2(-0, -0), //left top
                disableDepthTestDistance: Number.POSITIVE_INFINITY
            };
            let e2 = this._drawLayer.entities.add(_tempLineEntity2);
            let e = this._drawLayer.entities.add(_tempLineEntity);
            let e3 = this._drawLayer.entities.add(_trianglesEntity);
            ids.push(e.id);
            ids.push(e2.id);
            ids.push(e3.id);
            $this.entityIds = ids;
        }
    }

    drawLineHeightGraphics(options = {}) {
        options.style = options.style || {
            width: 2,
            material: Cesium.Color.YELLOW
        };
        if (this._viewer && options) {
            if ("lang" in options) {
                this._lang = options.lang;
            }

            var _trianglesEntity = new Cesium.Entity(),
                _tempLineEntity = new Cesium.Entity(),
                _tempLineEntity2 = new Cesium.Entity(),
                _positions = [],
                _tempPoints = [],
                _tempPoints2 = [],
                $this = this,
                ids = [];
            this.clear();
            this._handler = new Cesium.ScreenSpaceEventHandler(this._viewer.scene.canvas);
            const canvas = $this._viewer.scene.canvas;

            // 高度
            function _getHeading(startPosition, endPosition) {
                if (!startPosition && !endPosition) return 0;
                if (Cesium.Cartesian3.equals(startPosition, endPosition)) return 0;
                let cartographic = Cesium.Cartographic.fromCartesian(startPosition);
                let cartographic2 = Cesium.Cartographic.fromCartesian(endPosition);
                return Math.abs((cartographic2.height - cartographic.height)).toFixed(3);
            }

            // 偏移点
            function _computesHorizontalLine(positions) {
                let cartographic = Cesium.Cartographic.fromCartesian(positions[0]);
                let cartographic2 = Cesium.Cartographic.fromCartesian(positions[1]);
                if (cartographic.height > cartographic2.height) {
                    return Cesium.Cartesian3.fromDegrees(
                        Cesium.Math.toDegrees(cartographic2.longitude),
                        Cesium.Math.toDegrees(cartographic2.latitude),
                        cartographic.height
                    );
                }

                return Cesium.Cartesian3.fromDegrees(
                    Cesium.Math.toDegrees(cartographic.longitude),
                    Cesium.Math.toDegrees(cartographic.latitude),
                    cartographic2.height
                );
            }

            // left
            this._handler.setInputAction((movement) => {
                this.clearPrompt()
                let refs = options.refs;
                refs.propertyRef.setLoadingState({
                    state: true,
                    tool: 'measure'
                });

                let position = this.getMousePosition(movement.position, 'height');

                if (!position && !position.z && _positions.length === 0) return false;
                if (_positions.length === 0) {
                    _positions.push(position.clone());
                    _positions.push(position.clone());
                    _tempPoints.push(position.clone());
                    _tempPoints.push(position.clone());
                    refs.cesiumRef.$emit('on-toggle-draggable', false);
                } else {
                    if (canvas.width <= 540) {
                        //直线
                        _positions.pop();
                        _positions.push(position.clone());
                        let horizontalPosition = _computesHorizontalLine(_positions);
                        //高度
                        _tempPoints.pop();
                        _tempPoints.push(horizontalPosition.clone());
                        //水平线
                        _tempPoints2.pop(), _tempPoints2.pop();
                        _tempPoints2.push(position.clone());
                        _tempPoints2.push(horizontalPosition.clone());
                        refs.floatingBarRef.$emit('on-complete-selection');
                    }
                    this.clear();
                    this.clearMeasurementSnapping();
                    refs.cesiumRef.$emit('on-toggle-draggable', true);
                    if (typeof options.callback === 'function') {
                        options.callback({ e: _positions, e2: _tempPoints, e3: _tempPoints2, id: ids });
                    }
                }
            }, Cesium.ScreenSpaceEventType.LEFT_DOWN);

            this._handler.setInputAction((movement) => {
                var refs = options.refs;
                refs.propertyRef.setLoadingState({
                    state: true,
                    tool: 'measure'
                });
                if (_positions.length > 0) {
                    let valid = this.validateMeasurementNumberOfPoints(_positions, 2, true)
                    if (!valid) return

                    this.clear();
                    this.clearMeasurementSnapping();
                    refs.cesiumRef.$emit('on-toggle-draggable', true);
                    if (typeof options.callback === 'function') {
                        options.callback({ e: _positions, e2: _tempPoints, e3: _tempPoints2, id: ids });
                    }
                }
            }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);
            // mouse
            this._handler.setInputAction((movement) => {
                //Handles Hover Event for GCP and Point Measurement
                this.mouseHoverHandler(movement.endPosition, options.refs.cesiumRef);

                let position = this.getMousePosition(movement.endPosition, 'height');

                if (position && _positions.length > 0) {
                    //直线
                    _positions.pop();
                    _positions.push(position.clone());
                    let horizontalPosition = _computesHorizontalLine(_positions);
                    //高度
                    _tempPoints.pop();
                    _tempPoints.push(horizontalPosition.clone());
                    //水平线
                    _tempPoints2.pop(), _tempPoints2.pop();
                    _tempPoints2.push(position.clone());
                    _tempPoints2.push(horizontalPosition.clone());
                }
            }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);


            if (options.p) {
                this.clear();
                var _pos = options.p;
                _positions = _pos.e;
                _tempPoints = _pos.e2;
                _tempPoints2 = _pos.e3;

                options.callback({ e: _positions, e2: _tempPoints, e3: _tempPoints2, id: ids });
            }

            // create entity
            //point1
            _trianglesEntity.position = new Cesium.CallbackProperty(function () {
                return _positions[0];
            }, false);
            _trianglesEntity.label = {
                text: new Cesium.CallbackProperty(function () {
                    if (_positions.length >= 2) {
                        let cartographic = Cesium.Cartographic.fromCartesian(_positions[0]);
                        let cartographic2 = Cesium.Cartographic.fromCartesian(_positions[1]);
                        if (cartographic.height > cartographic2.height) {
                            return $this._lang == 'ja' ?
                                '距離:' + $this.getPositionDistance($this.transformCartesianArrayToWGS84Array(_tempPoints)) + 'm' :
                                'Distance:' + $this.getPositionDistance($this.transformCartesianArrayToWGS84Array(_tempPoints)) + 'm';
                        }
                    }

                }, false),
                show: true,
                showBackground: true,
                backgroundColor: Cesium.Color.BLACK,
                font: '14px arial',
                horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
                verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                pixelOffset: new Cesium.Cartesian2(-0, -0), //left top
                disableDepthTestDistance: Number.POSITIVE_INFINITY
            };
            _trianglesEntity.point = {
                pixelSize: 7,
                color: Cesium.Color.CRIMSON.withAlpha(0.8),
                outlineColor: Cesium.Color.WHITE,
                outlineWidth: 1,
                disableDepthTestDistance: Number.POSITIVE_INFINITY
            };

            //point2
            _tempLineEntity.allowMovement = false;
            _tempLineEntity.polyline = {
                positions: new Cesium.CallbackProperty(function () {
                    return _tempPoints;
                }, false),
                ...options.style
            };
            _tempLineEntity.position = new Cesium.CallbackProperty(function () {
                return _tempPoints2[1];
            }, false);
            _tempLineEntity.point = {
                pixelSize: 7,
                color: Cesium.Color.CRIMSON.withAlpha(0.8),
                outlineColor: Cesium.Color.WHITE,
                outlineWidth: 1,
                disableDepthTestDistance: Number.POSITIVE_INFINITY
            };
            _tempLineEntity.label = {
                text: new Cesium.CallbackProperty(function () {

                    if (_positions.length >= 2) {
                        let cartographic = Cesium.Cartographic.fromCartesian(_positions[0]);
                        let cartographic2 = Cesium.Cartographic.fromCartesian(_positions[1]);
                        let opposite = _tempPoints2;
                        if (cartographic2.height > cartographic.height) {
                            opposite = _tempPoints;
                        }
                        return $this._lang == 'ja' ?
                            '高さ:' + _getHeading(opposite[0], opposite[1]) + 'm' :
                            'Height:' + _getHeading(opposite[0], opposite[1]) + 'm';
                    }

                }, false),
                show: true,
                showBackground: true,
                backgroundColor: Cesium.Color.BLACK,
                font: '14px arial',
                horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
                verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                pixelOffset: new Cesium.Cartesian2(-0, -0), //left top
                disableDepthTestDistance: Number.POSITIVE_INFINITY
            };
            //point3
            _tempLineEntity2.polyline = {
                positions: new Cesium.CallbackProperty(function () {
                    return _tempPoints2;
                }, false),
                ...options.style
            };
            _tempLineEntity2.position = new Cesium.CallbackProperty(function () {
                return _positions[1];
            }, false);
            _tempLineEntity2.point = {
                pixelSize: 7,
                color: Cesium.Color.CRIMSON.withAlpha(0.8),
                outlineColor: Cesium.Color.WHITE,
                outlineWidth: 1,
                disableDepthTestDistance: Number.POSITIVE_INFINITY
            };
            _tempLineEntity2.label = {
                text: new Cesium.CallbackProperty(function () {
                    if (_positions.length >= 2) {
                        let cartographic = Cesium.Cartographic.fromCartesian(_positions[0]);
                        let cartographic2 = Cesium.Cartographic.fromCartesian(_positions[1]);
                        if (cartographic2.height > cartographic.height) {
                            return $this._lang == 'ja' ?
                                '距離:' + $this.getPositionDistance($this.transformCartesianArrayToWGS84Array(_tempPoints2)) + 'm' :
                                'Distance:' + $this.getPositionDistance($this.transformCartesianArrayToWGS84Array(_tempPoints2)) + 'm';
                        }
                    }

                }, false),
                show: true,
                showBackground: true,
                backgroundColor: Cesium.Color.BLACK,
                font: '14px arial',
                horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
                verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                pixelOffset: new Cesium.Cartesian2(-0, -0), //left top
                disableDepthTestDistance: Number.POSITIVE_INFINITY
            };
            var e2 = this._drawLayer.entities.add(_tempLineEntity2);
            var e = this._drawLayer.entities.add(_tempLineEntity);
            var e3 = this._drawLayer.entities.add(_trianglesEntity);

            ids.push(e.id);
            ids.push(e3.id);
            ids.push(e2.id);
        }

    }

    drawManualClassGraphics(options = {}) {
        if (this._viewer && options) {
            if ("lang" in options) {
                this._lang = options.lang;
            }
            let manualClass = new ManualClassification(
                this._viewer,
                options,
                this);
            this._handler = manualClass._handler;
        }
    }

    drawManualClassGraphicsCrossSection(options = {}) {
        if (this._viewer && options) {
            if ("lang" in options) {
                this._lang = options.lang;
            }
            let mccs = new ManualClassificationCrossSection(
                this._viewer,
                options,
                this,
                {
                    // _handler: this._handler,
                    primitivesMapperForMCCS: this.primitivesMapperForMCCS,
                    manualClassificationCrossSection: this.manualClassificationCrossSection,
                    manualClassificationCrossSectionResult: this.manualClassificationCrossSectionResult
                });
            this._handler = mccs.handler;
        }
    }

    changeManualClassificationSelectedClass(selectedClass) {
        this.manualClassificationCrossSection.Class = selectedClass;
    }
    resetManualClassificationCrossSectionResult() {
        this.manualClassificationCrossSectionResult = [];
    }

    resetmanualClassificationCrossSection() {
        this.manualClassificationCrossSection = {
            Class: 'Building',
            ProfileWidth: 5,
            enable: false,
            cameraView: null
        };
    }

    removePreviousManualClassificationCrossSectionResult() {
        this.manualClassificationCrossSectionResult.pop();
    }

    getResultMCCS() {
        return this.manualClassificationCrossSectionResult;
    }

    controlCamera(viewer, onoff = false) {
        const cameraController = viewer.scene.screenSpaceCameraController;
        cameraController.enableLook = onoff;
        cameraController.enableRotate = onoff;
        // cameraController.enableZoom = onoff;
        cameraController.enableTilt = onoff;
        // cameraController.enableTranslate = onoff;
        // cameraController.enableInputs = onoff;
        if (!onoff) {
            cameraController.inertiaSpin = 0;
            cameraController.tiltEventTypes = undefined;
            viewer.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_UP);
            viewer.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
        }
        else {
            // Put back the default values
            cameraController.inertiaSpin = 0.9;
            cameraController.tiltEventTypes = [2, 4, { 'eventType': 0, 'modifier': 1 }, { 'eventType': 1, 'modifier': 1 }];
        }
    }

    toggleZooming(viewer, toggle) {
        // console.log("zooming");
        viewer.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);

        const handler = viewer.screenSpaceEventHandler;
        const $this = this;
        const pointEnt = {
            color: Cesium.Color.fromCssColorString('#68EBD0'),
            pixelSize: 25,
            heightReference: Cesium.HeightReference.NONE,
        };
        handler.setInputAction(function zooming(movement) {
            // console.log("double left click")

            let cartesian;
            try {
                cartesian = $this.getCartesian3FromPX(movement.position);

            } catch (e) {

                console.log('click again');
            }


            if (cartesian) {
                const camera = viewer.camera;

                // const zoomPos = Cesium.Cartesian3.lerp(cameraPos, cartesian, 0.9, new Cesium.Cartesian3());
                let carto = Cesium.Cartographic.fromCartesian(cartesian);
                let carto2 = Cesium.Cartographic.fromCartesian(camera.position)
                let cameraHeight = Math.abs((carto2.height - carto.height));
                $this.zoomLevel = Math.abs(cameraHeight / 5.0);
                let initialZoom = cameraHeight - $this.zoomLevel;
                if ($this.zoomRate == 0.0) {
                    $this.zoomRate = initialZoom
                }
                else if ($this.zoomRate > $this.zoomLevel) {
                    $this.zoomRate -= $this.zoomLevel
                }
                carto.height = $this.zoomRate;
                const zoomPos = Cesium.Cartographic.toCartesian(carto);
                const entities = viewer.entities;
                let point = entities.add({
                    position: cartesian.clone(),
                    point: pointEnt
                });
                const hpr = new Cesium.HeadingPitchRange(
                    camera.heading,
                    camera.pitch,
                    camera.roll);
                viewer.flyTo(
                    point,
                    {
                        offset: new Cesium.HeadingPitchRange(
                            camera.heading,
                            camera.pitch,
                            $this.zoomRate),
                        minimumHeight: 500
                    }).then(function () {
                        point.show = false;
                        console.log("MapView: zooming to: flying finished");
                    });
                // camera.flyTo({
                //     destination: zoomPos.clone(),
                //     orientation: hpr,
                //     duration: 2,
                //     complete: () => {
                //         entities.remove(point);
                //     }
                // });
            }
        }, Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
    }
    // upper view positions (x,y,z) for DXF export
    getSampleHeights(data, recalc, pitch) {
        async function sampleHeights(viewer, positions, recalc, pitch) {
            let updatedPositions = [];
            positions.forEach((position) => {
                let p = new Cesium.Cartesian3(Number(position.x), Number(position.y), Number(position.z));
                updatedPositions.push(p);
            })
            // updatedPositions = convert_xyz(updatedPositions, $this.selectedEpsg, 'profile')
            // console.log(updatedPositions)
            // let p1 = new Cesium.Cartesian3(Number(positions[0].x), Number(positions[0].y), Number(positions[0].z));
            // let p2 = new Cesium.Cartesian3(Number(positions[1].x), Number(positions[1].y), Number(positions[1].z));
            // let twoPositions = [p1, p2];
            let crossSection = new CrossSection(viewer, worker);
            let sampleH = await crossSection.sampleHeight(updatedPositions, recalc, pitch, []);//promise
            return crossSection.getPositionsAndHeights(sampleH);
        }

        const viewer = this._viewer;
        return sampleHeights(viewer, data, recalc, pitch);
    }
    getHeights(data, recalc, pitch, sampleH = [], distanceRes = []) {
        async function sampleHeights(viewer, positions, recalc, pitch, sampleH, distanceRes) {
            //console.log(positions)
            let updatedPositions = [];
            positions.forEach((position) => {
                let p = new Cesium.Cartesian3(Number(position.x), Number(position.y), Number(position.z));
                updatedPositions.push(p);
            })
            // let p1 = new Cesium.Cartesian3(Number(positions[0].x), Number(positions[0].y), Number(positions[0].z));
            // let p2 = new Cesium.Cartesian3(Number(positions[1].x), Number(positions[1].y), Number(positions[1].z));
            // let twoPositions = [p1, p2];
            let crossSection = new CrossSection(viewer, worker);
            await crossSection.sampleHeight(updatedPositions, recalc, pitch, [], sampleH, {}, distanceRes);//promise
            return crossSection.getHeightsAndDistance();//Object
        }

        const viewer = this._viewer;
        return sampleHeights(viewer, data, recalc, pitch, sampleH, distanceRes);
    }

    drawProfileGraphics(options = {}, recalc, pitch) {
        options.style = options.style || {
            width: 1,
            material: Cesium.Color.WHITE,
            clampToGround: true
        };
        let refs = options.refs;
        this.options = options;
        const viewer = this._viewer;
        if (viewer && options) {
            if ("lang" in options) {
                this._lang = options.lang;
            }
            // let profileEntity = new Cesium.Entity(),
            // profileEntity2 = new Cesium.Entity(),
            // _pointEntities = [],
            let lineObj, _positions = [],
                _tempPoints = [],
                // _tempPoints2 = [],
                $this = this;
            // ids = [];
            // $this.entityIds = [];

            if (options.p) {//when creating from start
                if ($this._handler != null && !$this._handler.isDestroyed()) {
                    $this._handler.destroy();
                    $this._handler = null;
                }
                var _pos = options.p;
                _positions = _pos.e;
                $this.tempPos = _pos.e;
                _tempPoints = _pos.e2;
                $this.tempPoints = _pos.e2;
                // create point entity
                _positions.forEach((position) => {
                    $this.pointEntities.push(_addPoint(position, _positions.map((pos) => pos)));
                });
                // send previous cross-section result; ignore if empty
                let promise = null;
                if (!recalc) {
                    promise = $this.reDrawProfile(
                        viewer, 
                        _positions, 
                        $this.pointEntities, 
                        _pos.sampleHeights, 
                        _pos.distanceList);
                } else {
                    promise = $this.sampleHeights(
                        viewer, 
                        _positions, 
                        recalc, 
                        pitch, 
                        $this.pointEntities, 
                        _pos.sampleHeights, 
                        options, 
                        _pos.distanceList);
                }
                options.callback({
                    samplePromise: promise,
                    e: _positions,
                    data: {
                        e: _positions,
                        e2: _tempPoints,
                        // e3: _tempPoints2, 
                        id: $this.entityIds,
                        pitch: pitch
                    },
                    id: $this.entityIds
                });

                $this.tempPos = [];
                $this.lineObj = [];
                $this.tempPoints = [];
                $this.pointEntities = [];
                $this.entityIds = [];
            } else {
                if ($this._handler != null && !$this._handler.isDestroyed()) {
                    $this._handler.destroy();
                    $this._handler = null;
                }
                $this._handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
                // left
                $this._handler.setInputAction((movement) => {
                    $this.clearPrompt()
                    refs.cesiumRef.$emit('on-toggle-draggable', false);
                    // refs.cesiumRef.$emit('on-button-text-update');
                    refs.propertyRef.setLoadingState({
                        state: true,
                        tool: 'measure'
                    });
                    // var cartesian = $this.getCartesian3FromPX(movement.position);
                    let cartesian = $this.getMousePosition(movement.position, 'cross-section');
                    if (cartesian && cartesian.x) {
                        if (_positions.length > 0) {
                            _positions.pop();
                            $this.tempPos.pop();
                        }
                        // if (_positions.length === 0) {
                        //     _positions.push(cartesian.clone());
                        //     $this.tempPos = _positions;
                        // }
                        // _pointEntities.push(_addPoint(cartesian));
                        _positions.push(cartesian.clone());
                        $this.tempPos = _positions;
                        $this.pointEntities.push(_addPoint(cartesian));
                        _positions.push(cartesian.clone());
                        $this.tempPos.push(cartesian.clone());
                        if (!lineObj) create();
                    }
                }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

                $this._handler.setInputAction((movement) => {
                    //Handles Hover Event for GCP and Point Measurement
                    $this.mouseHoverHandler(movement.endPosition, refs.cesiumRef);
                    const cartesian = $this.getCartesian3FromPX(movement.endPosition);
                    if (_positions.length >= 2) {
                        if (cartesian && cartesian.x) {
                            _positions.pop();
                            $this.tempPos = _positions;
                            _positions.push(cartesian);
                            $this.tempPos = _positions;
                        }
                    }
                }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
                // right
                $this._handler.setInputAction((movement) => {
                    let valid = $this.validateMeasurementNumberOfPoints(_positions, 2)
                    if (!valid) this.resetManualClassificationCrossSectionResult
                    $this._handler.destroy();
                    $this._handler = null;

                    $this.clear();
                    _positions.pop();
                    if (_positions.length > 1) {
                        _positions.pop();
                        refs.cesiumRef.$emit('on-toggle-draggable', false);
                        if (typeof options.callback === 'function') {
                            let e = {
                                e: _positions,
                                e2: _tempPoints,
                                // e3: _tempPoints2, 
                                id: $this.entityIds
                            };
                            let result = $this.sampleHeights(viewer, e, recalc, pitch, $this.pointEntities, [], options);
                            e.samplePromise = result;
                            e.id = $this.entityIds;

                            //clear hovered position
                            $this.clearMeasurementSnapping();
                            options.callback(e);
                        }
                        $this._drawLayer.entities.remove($this.lineObj);
                    } else {
                        $this._drawLayer.entities.removeById($this.entityIds.pop());
                    }
                    // make sure to set the entityIds to empty after the process
                    $this.tempPos = [];
                    $this.lineObj = [];
                    $this.tempPoints = [];
                    $this.pointEntities = [];
                }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);
            }
            // profileEntity.label = {
            //     text: new Cesium.CallbackProperty(function() {
            //         return $this._lang === "ja" ? '距離:' + $this.getPositionDistance($this.transformCartesianArrayToWGS84Array(_tempPoints2)) + 'm' : 'Distance:' + $this.getPositionDistance($this.transformCartesianArrayToWGS84Array(_tempPoints2)) + 'm'
            //     }, false),
            //     show: true,
            //     showBackground: true,
            //     backgroundColor: Cesium.Color.BLACK,
            //     font: '14px arial',
            //     horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
            //     verticalOrigin: Cesium.VerticalOrigin.TOP,
            //     pixelOffset: new Cesium.Cartesian2(-150, -50) //left top
            // }
            function create() {
                let _lineEntity = new Cesium.Entity()
                _lineEntity.polyline = {
                    width: 2,
                    material: options.material || Cesium.Color.YELLOW.withAlpha(0.8),
                    clampToGround: options.clampToGround || false,
                };
                _lineEntity.polyline.positions = new Cesium.CallbackProperty(function () {
                    return _positions;
                }, false);

                lineObj = $this._drawLayer.entities.add(_lineEntity);
                $this.lineObj = lineObj;
                $this.entityIds.push(lineObj.id);
            }
            function _addPoint(position) {
                let _labelEntity = new Cesium.Entity();
                _labelEntity.position = position;
                _labelEntity.point = {
                    pixelSize: 7,
                    color: Cesium.Color.CRIMSON.withAlpha(0.8),
                    outlineColor: Cesium.Color.WHITE,
                    outlineWidth: 1
                };
                const e = $this._drawLayer.entities.add(_labelEntity);
                $this.entityIds.push(e.id);
                return e;
            }
        }
    }
    async sampleHeights(viewer, positions, recalc, pitch, _pointEntities, prevRes = [], options = {}, distanceRes = []) {
        let updatedPositions = this.updatePositions(positions);
        let crossSection = new CrossSection(viewer, worker);
        let sampleH = await crossSection.sampleHeight(updatedPositions, recalc, pitch, _pointEntities, prevRes, options, distanceRes);//promise
        return this.addPrimitives(sampleH, updatedPositions, viewer, crossSection);
    }
    reDrawProfile(viewer, positions, _pointEntities, sampleHeightRes, distanceRes) {
        let updatedPositions = this.updatePositions(positions);
        let crossSection = new CrossSection(viewer, worker);
        crossSection.sampleHeights = sampleHeightRes;
        crossSection.distanceList = distanceRes;
        let newPos = crossSection.getUpdatedPoints(positions, sampleHeightRes);
        crossSection.updatePointPosition(newPos, _pointEntities);
        return this.addPrimitives(sampleHeightRes, updatedPositions, viewer, crossSection);
    }
    updatePositions(positions) {
        if (positions.hasOwnProperty('e')) {
            let tempPositions = [];
            positions.e.forEach((position) => {
                return tempPositions.push({
                    'x': position.x,
                    'y': position.y,
                    'z': position.z
                })
            });
            positions = tempPositions;
        }
        let updatedPositions = [];
        positions.forEach((position) => {
            let p = new Cesium.Cartesian3(Number(position.x), Number(position.y), Number(position.z));
            updatedPositions.push(p);
        });
        return updatedPositions;
    }
    addPrimitives(sampleH, updatedPositions, viewer, crossSection) {
        const primitives = viewer.scene.primitives;
        // line
        let lineEntity = crossSection.addLine(sampleH);
        lineEntity = this._drawLayer.entities.add(lineEntity);
        this.entityIds.push(lineEntity.id);

        // wall
        for (let i = 0; i < updatedPositions.length; i++) {
            let nextIndex = i + 1;
            if (nextIndex < updatedPositions.length) {
                let wallPrimitive = crossSection.addWall([updatedPositions[i], updatedPositions[nextIndex]], sampleH);
                primitives.add(wallPrimitive);
                this.entityIds.push(wallPrimitive);
            }
        }
        let result = crossSection.getHeightsAndDistance();
        // return both the graph & viewer results
        return [result, sampleH];//Object
    }
    // drawMultiPointsProfileGraphics(options = {}) {
    //     // let refs = options.refs;
    //     const viewer = this._viewer;
    //     // const primitives = viewer.scene.primitives;
    //     let activeShapePoints = [];
    //     let activeShape;
    //     let floatingPoint;
    //     options.style = options.style || {
    //         width: 1,
    //         material: Cesium.Color.WHITE,
    //         clampToGround: true
    //     };
    //     let refs = options.refs;
    //     const primitives = viewer.scene.primitives;
    //     if (viewer && options) {
    //         let _positions = [],
    //             _tempPoints = [],
    //             _tempPoints2 = [],
    //             $this = this,
    //             ids = [];

    //         if (this._handler != null && !this._handler.isDestroyed()) {
    //             this._handler.destroy();
    //             this._handler = null;
    //         }
    //         this._handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
    //         // left
    //         this._handler.setInputAction((event) => {
    //             // We use `viewer.scene.pickPosition` here instead of `viewer.camera.pickEllipsoid` so that
    //             // we get the correct point when mousing over terrain.
    //             const earthPosition = $this.getCartesian3FromPX(event.position);
    //             // let p = new Cesium.Cartesian3(Number(earthPosition.x), Number(earthPosition.y), Number(earthPosition.z));
    //             if (activeShapePoints.length === 0) {
    //                 console.log("beforecreatePoint")
    //                 floatingPoint = createPoint(earthPosition);
    //                 console.log("aftercreatePoint")
    //                 activeShapePoints.push(earthPosition);
    //                 const dynamicPositions = new Cesium.CallbackProperty(function () {
    //                     return activeShapePoints;
    //                 }, false);
    //                 activeShape = drawShape(dynamicPositions);
    //             }
    //             activeShapePoints.push(earthPosition);
    //             createPoint(earthPosition);
    //         }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

    //         this._handler.setInputAction((event) => {
    //             if (Cesium.defined(floatingPoint)) {
    //                 const newPosition = viewer.scene.pickPosition(event.endPosition);
    //                 floatingPoint.position.setValue(newPosition);
    //                 activeShapePoints.pop();
    //                 activeShapePoints.push(newPosition);
    //             }
    //         }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
    //         this._handler.setInputAction((event) => {
    //             terminateShape();
    //         }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);

    //         function createPoint(worldPosition) {
    //             console.log(worldPosition)
    //             const point = $this._drawLayer.entities.add({
    //               position: worldPosition,
    //               point: {
    //                 pixelSize: 7,
    //                 color: Cesium.Color.CRIMSON.withAlpha(0.8),
    //                 outlineColor: Cesium.Color.WHITE,
    //                 outlineWidth: 1
    //               },
    //             });
    //             return point;
    //         }
    //         function drawShape(positionData) {
    //             console.log(positionData)
    //             let shape;
    //             shape = $this._drawLayer.entities.add({
    //                 polyline: {
    //                     positions: new Cesium.CallbackProperty(function() {
    //                         return positionData;
    //                     }, false),
    //                     width: 2,
    //                 },
    //             });
    //             return shape;
    //         }
    //         function terminateShape() {
    //             activeShapePoints.pop();
    //             drawShape(activeShapePoints);
    //             $this._drawLayer.entities.remove(floatingPoint);
    //             $this._drawLayer.entities.remove(activeShape);
    //             floatingPoint = undefined;
    //             activeShape = undefined;
    //             activeShapePoints = [];
    //         }
    //     }
    // }

    drawVolumeGraphics(options = {}, mode = 'CALC') {
        options.style = options.style || {
            width: 1,
            material: Cesium.Color.WHITE,
            clampToGround: true
        };
        let refs = options.refs;
        const viewer = this._viewer;

        if (this._handler != null || this._handler instanceof Cesium.ScreenSpaceEventHandler) {
            // this._handler.destroy();
            this._handler = null;
        }

        if (viewer && options) {
            if ("lang" in options) {
                this._lang = options.lang;
            }
            //let volumeMeasurement;
            if (mode === 'DRAW_BACK') {//when creating from start
                this.volumeMeasurement = new Volume3D(
                    viewer,
                    options.method,
                    options.resolution,
                    this._lang,
                    {
                        refs: refs,
                        t: options.t,
                        custom: options.custom,
                        handler: this._handler,
                        drawLayer: this._drawLayer,
                        mode: mode,
                        selectedEpsg: this.selectedEpsg,
                        measure: this,
                        isVolumeCutFillResult: options.isVolumeCutFillResult
                    },
                    options.callback
                );
                return this.volumeMeasurement.draw(options.positions, options.result);
            } else if (mode === 'RE_CALC') {
                this.volumeMeasurement = new Volume3D(
                    viewer,
                    options.method,
                    options.resolution,
                    this._lang,
                    {
                        refs: refs,
                        t: options.t,
                        custom: options.custom,
                        handler: this._handler,
                        drawLayer: this._drawLayer,
                        selectedEpsg: this.selectedEpsg,
                        measure: this,
                        mode: mode,
                        isVolumeCutFillResult: options.isVolumeCutFillResult
                    },
                    options.callback
                );
                this.volumeMeasurement.recalculate(options.polygonPoints);
            } else {

                this._handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);

                this.volumeMeasurement = new Volume3D(
                    viewer,
                    options.method,
                    options.resolution,
                    this._lang,
                    {
                        refs: refs,
                        t: options.t,
                        custom: options.custom,
                        handler: this._handler,
                        drawLayer: this._drawLayer,
                        mode: mode,
                        selectedEpsg: this.selectedEpsg,
                        measure: this
                        // polygonPoints: options.polygonPoints,
                    },
                    options.callback
                );
            }
        }
    }

    setSelectedEpsg(val) {
        this.selectedEpsg = val;
    }
}