const state = () => ({
    index: 0
})

// getters
const getters = {
    getIndexClip(state) {
        return state.index
    }
}

// mutations
const mutations = {
    updateIndex(state, index) {
        state.index = index
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations
}