import { createRouter, createWebHistory } from 'vue-router';

import AccountDetails from '@/pages/account-details';
import AddPayment from '@/pages/billing/AddPayment';
import AdminProjectsList from '@/pages/admin/ProjectsList';
import Announcements from '@/pages/admin/AnnouncementsList';
import AnnouncementsCreate from '@/pages/admin/AnnouncementCreate';
import AnnouncementsEdit from '@/pages/admin/AnnouncementEdit';
import Billing from '@/pages/billing/Billing';
import Customers from '@/pages/admin';
import CustomerDetails from '@/pages/admin/CustomerDetails';
import Demos from '@/pages/demos';
import Downloads from '@/pages/downloads';
import Home from '@/pages/home';
import KumikiAccessGrant from '@/pages/kumiki/AccessGrant';
import KumikiImport from '@/pages/kumiki/ImportPointCloud';
import LanguageRegion from '@/pages/language-region';
import Login from '@/pages/login';
import MonthlyUsage from '@/pages/monthly-usage';
import NotificationSettings from '@/pages/notification-settings';
import OrgDetails from '@/pages/admin/OrgDetails';
import OrgView from '@/pages/admin/OrganizationView';
import ProjectDetails from '@/pages/admin/ProjectDetails';
import Redirect from '@/pages/redirect';
import RenewalList from '@/pages/admin/RenewalList';
import ResetPassword from '@/pages/reset-password';
import Sandbox from '@/pages/sandbox';
import SecurityLogin from '@/pages/security-login';
import SetNewPassword from '@/pages/set-new-password';
import ShareView from '@/pages/share';
import Signup from '@/pages/signup';
import SignupUrlGenerator from '@/pages/admin/SignupUrlGenerator';
import Support from '@/pages/support';
import S3Uploads from '@/pages/s3-uploads';
import S3Uppy from '@/pages/s3-uploads/Uppy';
import UserManagement from '@/pages/user-management/';
import UserManagementOrg from '@/pages/user-management/OrgView';
import UserManagementSharedOrg from '@/pages/user-management/SharedOrg';
import Viewer from '@/pages/viewer';

//import Folder from "@/pages/folder";

const routes = [
    {
        path: '/admin',
        name: 'admin-org',
        component: OrgView,
        meta: {
            auth: true,
            admin: true
        }
    },
    {
        path: '/admin/customers',
        name: 'admin-customers',
        component: Customers,
        meta: {
            auth: true,
            admin: true
        }
    },
    {
        path: '/admin/customers/renewal',
        name: 'customer-for-renewal',
        component: RenewalList,
        meta: {
            auth: true,
            admin: true
        }
    },
    {
        path: '/admin/organization-details/:id',
        name: 'admin-organization-details',
        component: OrgDetails,
        meta: {
            auth: true,
            admin: true
        }
    },
    {
        path: '/admin/customer-details/:id',
        name: 'admin-customer-details',
        component: CustomerDetails,
        meta: {
            auth: true,
            admin: true
        }
    },
    {
        path: '/admin/announcements',
        name: 'admin-announcements',
        component: Announcements,
        meta: {
            auth: true,
            admin: true
        }
    },
    {
        path: '/admin/announcements/create',
        name: 'admin-create-announcement',
        component: AnnouncementsCreate,
        meta: {
            auth: true,
            admin: true
        }
    },
    {
        path: '/admin/announcements/:id/:edit',
        name: 'admin-edit-announcement',
        component: AnnouncementsEdit,
        meta: {
            auth: true,
            admin: true
        }
    },
    {
        path: '/admin/projects',
        name: 'admin-projects-list',
        component: AdminProjectsList,
        meta: {
            auth: true,
            admin: true
        }
    },
    {
        path: '/admin/project-details/:id',
        name: 'admin-project-details',
        component: ProjectDetails,
        meta: {
            auth: true,
            admin: true
        }
    },
    {
        path: '/admin/signup-url-generator',
        name: 'admin-signup-url-generator',
        component: SignupUrlGenerator,
        meta: {
            auth: true,
            admin: true
        }
    }, {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            auth: true
        }
    },
    {
        path: '/kumiki-integration',
        name: 'kumiki-access-grant',
        component: KumikiAccessGrant,
        meta: {
            auth: true
        }
    },
    {
        path: '/kumiki-point-cloud',
        name: 'kumiki-point-cloud',
        component: KumikiImport,
        meta: {
            auth: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            redirectIfLoggedin: true,
            hideForAuth: true
        },
        props: route => ({
            redirect: route.query.redirect,
        })
    },
    {
        path: '/redirect',
        name: 'redirect',
        component: Redirect,
        props: route => ({
            page: route.query.page,
            token: route.query.token,
            verify_email_token: route.query.verify_email_token,
            invite_token: route.query.invite_token,
        })
    },
    {
        path: '/downloads/:token',
        name: 'downloads',
        component: Downloads,
        meta: {
            auth: true
        },
        params: true
    },
    {
        path: '/forgot',
        name: 'forgot-password',
        component: ResetPassword,
    },
    {
        path: '/reset-password',
        name: 'set-new-password',
        component: SetNewPassword,
    },
    {
        path: '/create-account',
        name: 'create-account',
        component: Signup,
        meta: {
            redirectIfLoggedin: true,
            hideForAuth: true
        }
    },
    {
        path: '/demo',
        name: 'demo',
        component: Demos,
        meta: {
            auth: true
        }
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import( /* webpackChunkName: "about" */ '../pages/about/index.vue')
    },
    {
        path: '/account-details',
        name: 'account-details',
        component: AccountDetails,
        meta: {
            auth: true
        }
    },
    {
        path: '/user-management',
        name: 'user-management',
        component: UserManagementOrg,
        meta: {
            auth: true
        }
    },
    {
        path: '/user-management-members',
        name: 'user-management-members',
        component: UserManagement,
        meta: {
            auth: true
        }
    },
    {
        path: '/user-management-shared-org',
        name: 'user-management-shared-org',
        component: UserManagementSharedOrg,
        meta: {
            auth: true
        }
    },
    {
        path: '/monthly-usage',
        name: 'MonthlyUsage',
        component: MonthlyUsage,
        meta: {
            auth: true
        }
    },
    {
        path: '/notification-settings',
        name: 'NotificationSettings',
        component: NotificationSettings,
        meta: {
            auth: true
        }
    },
    {
        path: '/security',
        name: 'security-login',
        component: SecurityLogin,
        meta: {
            auth: true
        }
    },
    {
        path: '/language',
        name: 'language-region',
        component: LanguageRegion,
        meta: {
            auth: true
        }
    },
    {
        path: '/billing',
        name: 'billing',
        component: Billing,
        meta: {
            auth: true
        }
    },
    {
        path: '/payment/:plan_id/:name/:interval',
        name: 'payment',
        component: AddPayment,
        meta: {
            auth: true
        },
        params: true
    },
    {
        path: '/support',
        name: 'support',
        component: Support,
        meta: {
            auth: true
        }
    },
    {
        path: '/viewer/:project_id',
        name: 'Viewer',
        component: Viewer,
        meta: {
            auth: true
        },
        params: true
    },
    {
        path: '/share/:token',
        name: 'Share',
        component: ShareView,
        meta: {
            auth: false
        },
        params: true
    },
    {
        path: '/sandbox',
        name: 'sandbox',
        component: Sandbox
    },
    {
        path: '/folder/:folder_id',
        name: 'Folder',
        component: Home,
        meta: {
            auth: true
        },
        params: true
    },
    {
        path: '/s3-uploads',
        name: 'S3Uploads',
        component: S3Uploads,
        meta: {
            auth: false
        },
        params: true
    },
    {
        path: '/s3-uppy',
        name: 'S3Uppy',
        component: S3Uppy,
        meta: {
            auth: false
        },
        params: true
    }

];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    const { $cookies } = router.app.config.globalProperties;

    if(!$cookies.isKey('scx_token')){
        if(localStorage.getItem("scx_token")){
         $cookies.set('scx_token',localStorage.getItem("scx_token"))
        }
    }

    let isAuth = $cookies.isKey('scx_token');
    let hasAdminAccess = router.app.config.globalProperties.$store.state.auth.hasAdminAccess;

    if (to.matched.some(record => record.meta.hideForAuth)) {
        if (isAuth) {

            const urlParams = new URLSearchParams(window.location.search);

            if (urlParams.get('redirect') == '/kumiki-integration' || urlParams.get('redirect') == 'kumiki-integration') {
                next({
                    path: '/kumiki-integration',
                    query: { client_id: urlParams.get('client_id'), callback_url: urlParams.get('callback_url') }
                });
            }
            else if (urlParams.get('redirect') == '/kumiki-point-cloud' || urlParams.get('redirect') == 'kumiki-point-cloud') {
                next({
                    path: '/kumiki-point-cloud',
                    query: {
                        client_id: urlParams.get('client_id'),
                        access_token: urlParams.get('access_token'),
                        point_cloud_url: urlParams.get('point_cloud_url'),
                        name: urlParams.get('name'),
                        kumiki_project_id: urlParams.get('kumiki_project_id'),
                        kumiki_file_id: urlParams.get('kumiki_file_id'),
                        epsg: urlParams.get('epsg'),
                        date_time: urlParams.get('date_time')
                    }
                });
            }
            else {
                next({
                    path: '/',
                });
            }

        } else {
            next();
        }
    }

    if (to.meta.auth) {
        if (!isAuth) {
            if (to.fullPath != '/login' && to.fullPath != '/') {
                next({
                    path: '/login',
                    query: { redirect: to.fullPath }
                });
            } else {
                next({ path: '/login' })
            }
        }
    }

    //Restrict Access to admin pages
    setTimeout(() => {
        if (to.meta.admin && !hasAdminAccess) {
            next({ path: '/' });
        }

        // Redirect to dashboard if already logged in
        if (to.meta.redirectIfLoggedin) {
            if (isAuth) {
                next({ path: '/' });
            }
        }
    }, 10);

    next();
});

export default router;