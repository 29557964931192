/**
 * Document Package
 *
 * Integrates Document Handling features
 */
 
 
 export default {
 
     project_id: false,
     store: null,
 
     auth: null,
     authToken: null,
     share: {
         mode: false,
         list: {}
     },
     plugins: {},
 
 
     broadcastProvider: null,
 
     // Initialize 3d model integraiton
     init(project_id, store, plugins, share = null) {
 
         this.project_id = project_id;
         this.store = store;
         this.plugins = plugins;
 
         this.share = share != null ? share : this.share;
         this.notifications();
         //this.load();
     },
 
     notifications() {
         if (this.broadcastProvider) {
             this.broadcastProvider.channel(`App.Project.${this.project_id}`)
                 .listen('.document.completed', (e) =>
                  {
                    console.log("document",e);
                    if(e.document.document_url) {
                        if(e.document.document_url.includes(".pdf")) {
                            window.open(e.document.document_url, "_blank");
                        }
                    }
                    this.addDocument(e.document);
                    this.plugins.$toast.show(
                        `<div class="font-body flex items-center justify-between text-white"><span>Document Generated.</span><div>`, { type: "success", position: "top", duration: 3000 }
                    );
                 })
         }
     },
 
     // Load data from store
     async load() {
         let models = null
         if (this.share.mode) {
             let documents = this.share.list;
             this.store.commit("documents/setDocumentList", documents);
         } else {
             models = await this.store.dispatch('documents/loadDocuments', this.project_id )
         }
     },

     addDocument(document) {
         this.store.dispatch('documents/addDocument', { 'document': document });
     },

     clearToastr() { 
         setTimeout(this.plugins.$toast.clear, 0);        
     }
 
 }