import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-21489a4a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "image-container mb-3"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = { ref: "dashboardContainer" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("form", null, [
    ($data.previewPath)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("img", {
            src: $data.previewPath,
            alt: "Uploaded Image Preview"
          }, null, 8, _hoisted_2)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, null, 512)
    ])
  ]))
}