import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-338cc762"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col h-screen justify-between" }
const _hoisted_2 = { class: "flex flex-row mb-auto overflow-hidden font-body custom-bg" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "flex-grow flex justify-end items-end" }
const _hoisted_5 = { class: "w-full absolute" }
const _hoisted_6 = { class: "flex z-40" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_NavbarViewer = _resolveComponent("NavbarViewer")
  const _component_Dropdown = _resolveComponent("Dropdown")
  const _component_Notification = _resolveComponent("Notification")
  const _component_TopToolbar = _resolveComponent("TopToolbar")
  const _component_ViewerToolbar = _resolveComponent("ViewerToolbar")
  const _component_FileManager = _resolveComponent("FileManager")
  const _component_Process = _resolveComponent("Process")
  const _component_TIN = _resolveComponent("TIN")
  const _component_ViewerNotification = _resolveComponent("ViewerNotification")
  const _component_ChartViewer = _resolveComponent("ChartViewer")
  const _component_Cesium = _resolveComponent("Cesium")
  const _component_ShareModal = _resolveComponent("ShareModal")
  const _component_ThreeAxesHelper = _resolveComponent("ThreeAxesHelper")
  const _component_FloatingBar = _resolveComponent("FloatingBar")
  const _component_Properties = _resolveComponent("Properties")
  const _component_ModalInfo = _resolveComponent("ModalInfo")
  const _component_Classification = _resolveComponent("Classification")
  const _component_GalleryView = _resolveComponent("GalleryView")
  const _component_ViewerFooter = _resolveComponent("ViewerFooter")
  const _component_Loading = _resolveComponent("Loading")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.getSelectedProject)
      ? (_openBlock(), _createBlock(_component_NavbarViewer, {
          key: 0,
          project: _ctx.getSelectedProject,
          onOnclickDropdown: $options.handleDropdown,
          onOnclickLang: _cache[0] || (_cache[0] = $event => ($options.handleLang($event))),
          onOnClickShare: $options.handleShare,
          nameOnly: _ctx.nameOnly,
          nameFirstLetter: _ctx.nameFirstLetter,
          isShareMode: true
        }, null, 8, ["project", "onOnclickDropdown", "onOnClickShare", "nameOnly", "nameFirstLetter"]))
      : _createCommentVNode("", true),
    ($data.toggleAccount)
      ? (_openBlock(), _createBlock(_component_Dropdown, {
          key: 1,
          user: _ctx.getUser
        }, null, 8, ["user"]))
      : _createCommentVNode("", true),
    ($data.toggleNotification)
      ? (_openBlock(), _createBlock(_component_Notification, {
          key: 2,
          notifications: _ctx.notifications
        }, null, 8, ["notifications"]))
      : _createCommentVNode("", true),
    _createVNode(_component_TopToolbar, {
      onOnSelectTool: $options.handleSelectTool,
      onProcessNotif: $options.toggleProcessNotif,
      project: _ctx.getSelectedProject,
      "hide-processbar": true,
      isShareMode: true,
      isOwn: false
    }, null, 8, ["onOnSelectTool", "onProcessNotif", "project"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_ViewerToolbar, {
          class: "flex-shrink-0",
          onSelectedTool: $options.toggleTool,
          onDeactivateClip: $options.deactivateClip,
          isShareMode: true
        }, null, 8, ["onSelectedTool", "onDeactivateClip"]),
        (_ctx.getSelectedProject)
          ? _withDirectives((_openBlock(), _createBlock(_component_FileManager, {
              key: 0,
              projectFiles: _ctx.projectFiles,
              onOnClose: $options.handleClosePanel,
              onOnSelectPointcloud: $options.onSelectPointcloud,
              onOnActiveProperty: $options.handleActivePropert,
              onToggleDataLayer: $options.onAddLayer,
              onToggleVisibility: $options.toggleVisibility,
              ref: "fileManagerRef",
              compRef: _ctx.$refs,
              onOnToggleInfoBox: $options.toggleInfoBox,
              onOnDeleteMeasurement: $options.handleDeleteMeasurement,
              onStartReprocess: $options.startReprocess,
              shareMode: true,
              userId: 0,
              onSetSelectedTileset: $options.handleSelectTileset,
              onShowGallery: $options.showGallery
            }, null, 8, ["projectFiles", "onOnClose", "onOnSelectPointcloud", "onOnActiveProperty", "onToggleDataLayer", "onToggleVisibility", "compRef", "onOnToggleInfoBox", "onOnDeleteMeasurement", "onStartReprocess", "onSetSelectedTileset", "onShowGallery"])), [
              [_vShow, $data.selectedTool === 'layers']
            ])
          : _createCommentVNode("", true),
        ($data.selectedTool === 'process')
          ? (_openBlock(), _createBlock(_component_Process, {
              key: 1,
              onOnClose: $options.handleClosePanel,
              projectFiles: _ctx.projectFiles,
              cesiumRef: _ctx.$refs.cesiumRef,
              userId: 0
            }, null, 8, ["onOnClose", "projectFiles", "cesiumRef"]))
          : _createCommentVNode("", true),
        ($data.selectedTool === 'TIN')
          ? (_openBlock(), _createBlock(_component_TIN, {
              key: 2,
              onOnClose: $options.handleClosePanel,
              projectFiles: _ctx.projectFiles,
              userId: 0
            }, null, 8, ["onOnClose", "projectFiles"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        ($data.processNotification)
          ? (_openBlock(), _createBlock(_component_ViewerNotification, { key: 0 }))
          : _createCommentVNode("", true),
        _withDirectives(_createVNode(_component_ChartViewer, {
          heightProfileData: _ctx.heightProfileData,
          onOnToggle: $options.toggleHeightProfileModal
        }, null, 8, ["heightProfileData", "onOnToggle"]), [
          [_vShow, $data.showHeightProfileModal]
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        ($data.isLive)
          ? (_openBlock(), _createBlock(_component_Cesium, {
              key: 0,
              attribute: $data.attribute,
              selectedClass: $data.selected_class,
              projectFiles: _ctx.projectFiles,
              selectedTileset: $data.selectedTileset,
              tool: $data.tool,
              attribColor: $data.attribColor,
              classifications: $data.classifications,
              isClassChange: $data.isClassChange,
              onOnMouseMove: $options.onMouseMove,
              onOnCreateTool: $options.handleCreateTool,
              onOnCreateMeasure: $options.createMeasurement,
              onOnViewerInit: $options.onViewerInit,
              onOnAdjustingMeasure: $options.noAction,
              onOnSaveMeasure: $options.noAction,
              attenuation: $data.attenuation,
              eyeDome: $data.eyeDome,
              lightStrength: $data.lightStrength,
              lightRadius: $data.lightRadius,
              mapMode: $data.mapMode,
              draggablePoints: $data.draggablePoints,
              ref: "cesiumRef",
              "clip-deactive": $data.clip,
              shareMode: true,
              compRef: _ctx.$refs,
              settings: $data.settings,
              onOnChangeAttribColor: $options.onChangeAttribColor
            }, null, 8, ["attribute", "selectedClass", "projectFiles", "selectedTileset", "tool", "attribColor", "classifications", "isClassChange", "onOnMouseMove", "onOnCreateTool", "onOnCreateMeasure", "onOnViewerInit", "onOnAdjustingMeasure", "onOnSaveMeasure", "attenuation", "eyeDome", "lightStrength", "lightRadius", "mapMode", "draggablePoints", "clip-deactive", "compRef", "settings", "onOnChangeAttribColor"]))
          : _createCommentVNode("", true),
        _withDirectives(_createVNode(_component_ShareModal, null, null, 512), [
          [_vShow, $data.isShareModal]
        ])
      ]),
      _withDirectives(_createVNode(_component_ThreeAxesHelper, {
        class: "compass opacity-100 bg-transparent",
        isProperties: $data.propertiesPanel,
        compRef: _ctx.$refs,
        ref: "floatBarRef"
      }, null, 8, ["isProperties", "compRef"]), [
        [_vShow, false]
      ]),
      _createVNode(_component_FloatingBar, {
        isProperties: $data.propertiesPanel,
        selectedTileset: $data.selectedTileset,
        onOnZoomIn: $options.handleZoomIn,
        onOnZoomOut: $options.handleZoomOut,
        onOnFocus: _cache[1] || (_cache[1] = $event => ($options.onSelectPointcloud($data.selectedTileset))),
        onOpenSettings: _ctx.toggleSettings,
        compRef: _ctx.$refs,
        shareMode: true
      }, null, 8, ["isProperties", "selectedTileset", "onOnZoomIn", "onOnZoomOut", "onOpenSettings", "compRef"]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_Properties, {
          classifications: $data.classifications,
          projectFiles: _ctx.projectFiles,
          selectedTileset: $data.selectedTileset,
          selectedClass: $data.selected_class,
          onOnClickClass: $options.handleClass,
          onOnChangeAttrib: $options.handleChangeAttrib,
          onOnChangeAttribColor: $options.onChangeAttribColor,
          onOnChangeClassColor: $options.onChangeClassColor,
          onToggleProperties: $options.toggleProperties,
          onOnCalculations: $options.handleCalculations,
          onOnUpdateLabel: $options.handleUpdateLabel,
          onOnShowProfile: $options.handleShowHeightProfile,
          onOnDeleteMeasurement: $options.handleDeleteMeasurement,
          attenuation: $data.attenuation,
          eyeDome: $data.eyeDome,
          onOnChangeSettings: $options.setSettings,
          lightStrength: $data.lightStrength,
          lightRadius: $data.lightRadius,
          cesiumRef: _ctx.$refs.cesiumRef,
          fileManager: _ctx.$refs.fileManagerRef,
          ref: "propertyRef",
          shareMode: true,
          onOnToggleBasemap: $options.handleToggleBaseMap,
          onOnToggleTerrain3d: $options.handleToggleTerrain3d,
          vsettings: $data.settings,
          onOnUpdateSettings: $options.updateSettings,
          attribute: $data.attribute
        }, null, 8, ["classifications", "projectFiles", "selectedTileset", "selectedClass", "onOnClickClass", "onOnChangeAttrib", "onOnChangeAttribColor", "onOnChangeClassColor", "onToggleProperties", "onOnCalculations", "onOnUpdateLabel", "onOnShowProfile", "onOnDeleteMeasurement", "attenuation", "eyeDome", "onOnChangeSettings", "lightStrength", "lightRadius", "cesiumRef", "fileManager", "onOnToggleBasemap", "onOnToggleTerrain3d", "vsettings", "onOnUpdateSettings", "attribute"])
      ])
    ]),
    ($data.showInfoBox)
      ? (_openBlock(), _createBlock(_component_ModalInfo, {
          key: 3,
          onOnToggleInfoBox: $options.toggleInfoBox,
          lasinfo: $data.lasinfo,
          class: "absolute inset-0"
        }, null, 8, ["onOnToggleInfoBox", "lasinfo"]))
      : _createCommentVNode("", true),
    ($data.showClassification)
      ? (_openBlock(), _createBlock(_component_Classification, {
          key: 4,
          onCloseClassification: $options.toggleReprocess,
          onOnClassificationSave: $options.onExecuteReprocess,
          input: $data.input
        }, null, 8, ["onCloseClassification", "onOnClassificationSave", "input"]))
      : _createCommentVNode("", true),
    ($data.gallery.show)
      ? (_openBlock(), _createBlock(_component_GalleryView, {
          key: 5,
          galleryData: $data.gallery,
          onShowGallery: $options.showGallery,
          class: "absolute",
          compRef: _ctx.$refs
        }, null, 8, ["galleryData", "onShowGallery", "compRef"]))
      : _createCommentVNode("", true),
    ($data.loading == false)
      ? (_openBlock(), _createBlock(_component_ViewerFooter, {
          key: 6,
          ref: "wiewerFooterRef"
        }, null, 512))
      : _createCommentVNode("", true),
    ($data.loading == true)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 7 }))
      : _createCommentVNode("", true)
  ]))
}