import * as Cesium from '@/sdk/cesium';
import proj4 from 'proj4';

export default function convert_xyz(pos, selectedEpsg, type) {
    function convert(_p, selectedEpsg) {
        let cartographic =
            Cesium.Cartographic.fromCartesian(_p);
        let pos = {
            lon: Number(Cesium.Math.toDegrees(cartographic.longitude).toFixed(10)),
            lat: Number(Cesium.Math.toDegrees(cartographic.latitude).toFixed(10)),
            h: Number(cartographic.height.toFixed(10))
        };
        let epsgFrom = '4978';
        let epsgTo = String(selectedEpsg);
        let proj = proj4(epsgFrom, epsgTo, [Number(pos.lon), Number(pos.lat)]);

        let new_pos = {};
        if (type != 'volume') {
            new_pos.x = proj[1].toFixed(4);
            new_pos.y = proj[0].toFixed(4);
            new_pos.z = Number(pos.h).toFixed(4);
        } else {
            new_pos.x = Number(proj[1].toFixed(10));
            new_pos.y = Number(proj[0].toFixed(10));
            new_pos.z = Number((pos.h).toFixed(10));
        }
        let updatedPosition = new_pos
        if (selectedEpsg >= 6669 || selectedEpsg <= 6687) {
            updatedPosition = new Cesium.Cartesian3(new_pos.y, new_pos.x, new_pos.z);
        }
        return updatedPosition
    }
    if (type === 'point' || type === 'annotation' || type === 'vector') {
        return convert(pos, selectedEpsg);
    }
    else {
        let new_pos_ar = [];
        pos.forEach((p) => {
            new_pos_ar.push(convert(p, selectedEpsg));
        });
        return new_pos_ar;
    }
}

/**
 *
 * @param positions array w/ object that has string properties
 * @param selectedEpsg
 */
export function convert_ECEF(positions, selectedEpsg) {
    let epsgFrom = String(selectedEpsg);
    let proj = proj4(epsgFrom, '4978', [Number(positions.y), Number(positions.x), Number(positions.z)]);
    if (selectedEpsg >= 6669 || selectedEpsg <= 6687) {
        proj = proj4(epsgFrom, '4978', [Number(positions.x), Number(positions.y), Number(positions.z)]);
    }
    return Cesium.Cartographic.toCartesian(new Cesium.Cartographic(
        Cesium.Math.toRadians(proj[0]),
        Cesium.Math.toRadians(proj[1]),
        proj[2]));
}