import * as Cesium from '@/sdk/cesium';
import TreeAnalysis from '@/third-party/TreeAnalysis';


export function classification(tileset, classifications) {
    if (tileset instanceof Cesium.Cesium3DTileset) {

        let settings = tileset.settings
        let showClass = '';
        let s = this.selected_class;

        if (s != null) {

            for (let i = 0; i < s.length; i++) {
                const e = s[i];
                if (i == 0) {
                    showClass = '${Classification} === ' + e;
                } else {
                    showClass = showClass + ' || ${Classification} === ' + e;
                }
            }

        }

        let tileStyle = new Cesium.Cesium3DTileStyle({
            color: {
                conditions: [
                    [
                        '${Classification} === 0',
                        `color('${classifications[0].color}')`
                    ], // unclassified
                    [
                        '${Classification} === 1',
                        `color('${classifications[1].color}')`
                    ], // unclassified
                    [
                        '${Classification} === 2',
                        `color('${classifications[2].color}')`
                    ], // ground
                    [
                        '${Classification} === 3',
                        `color('${classifications[3].color}')`
                    ], // low vegetation
                    [
                        '${Classification} === 4',
                        `color('${classifications[4].color}')`
                    ], // medium vegetation
                    [
                        '${Classification} === 5',
                        `color('${classifications[5].color}')`
                    ], // high vegetation
                    [
                        '${Classification} === 6',
                        `color('${classifications[6].color}')`
                    ],
                    [
                        '${Classification} === 7',
                        `color('${classifications[7].color}')`
                    ],
                    [
                        '${Classification} === 8',
                        `color('${classifications[8].color}')`
                    ],
                    [
                        '${Classification} === 9',
                        `color('${classifications[9].color}')`
                    ],
                    [
                        '${Classification} === 12',
                        `color('${classifications[12].color}')`
                    ],
                    [
                        '${Classification} === 13',
                        `color('${classifications[13].color}')`
                    ],
                    [
                        '${Classification} === 18',
                        `color('${classifications[18].color}')`
                    ],
                    ['true', 'color(\'gray\')']
                ]
            },
            show: (tileset.isClassified == 0 && s !== null) ? s.find(n => n == 0 || n == '0') == 0 : showClass != '' ? showClass : false
        });

        tileset.style = tileStyle;

    }
}

export function rgb(tileset) {
    if (tileset instanceof Cesium.Cesium3DTileset) {
        if (tileset.isClassified) {
            let showClass = '';
            let s = this.selected_class;

            if (s != null) {
                for (let i = 0; i < s.length; i++) {
                    const e = s[i];
                    if (i === 0) {
                        showClass = '${Classification} === ' + e;
                    } else {
                        showClass = showClass + ' || ${Classification} === ' + e;
                    }
                }
            }

            let tileStyle = new Cesium.Cesium3DTileStyle({
                show: showClass !== '' ? showClass : false
            });
            tileset.style = tileStyle;
        } else {
            tileset.style = new Cesium.Cesium3DTileStyle({
                show: true
            });
        }
    }
}

export function color(tileset, color) {
    if (tileset instanceof Cesium.Cesium3DTileset) {

        tileset.style = new Cesium.Cesium3DTileStyle({
            color: `color('${color}')`
        });
    }
}

export function elevation(tileset) {
    if (tileset instanceof Cesium.Cesium3DTileset) {

        const elevationField = tileset.elevation_field ?? 'UserData';
        console.log("elevation", elevationField, tileset.elevation_field);
        if (elevationField === 'Intensity') {//new pointclouds
            console.log("using Intensity for elevation");
            tileset.style = new Cesium.Cesium3DTileStyle({
                defines: {
                    Height: 'clamp(${Intensity}, 0, 255)/255 + 0.39',
                    PointChange: '(255/63.75)'
                },
                color: {
                    'conditions': [
                        ['(${Intensity} >= 0.0) && (${Intensity} <= 64.0)', 'rgb(0,(${PointChange} * ${Intensity}),255)'],
                        ['(${Intensity} >= 65.0) && (${Intensity} <= 128.0)', 'rgb(0,255,(255 - (${PointChange} * (${Intensity}-65.0))) )'],
                        ['(${Intensity} >= 128.0) && (${Intensity} <= 192.0)', 'rgb((${PointChange} * (${Intensity}-128)),255,0)'],
                        ['(${Intensity} >= 193.0) && (${Intensity} <= 255.0)', 'rgb(255,(255 - (${PointChange} * (${Intensity}-193))),0)']
                    ]
                }
            });
        } else if (elevationField === 'GpsTime') {//new pointclouds
            console.log("using GpsTime for elevation");
            tileset.style = new Cesium.Cesium3DTileStyle({
                defines: {
                    Height: 'clamp(${GpsTime}, 0, 255)/255 + 0.39',
                    PointChange: '(255/63.75)'
                },
                color: {
                    'conditions': [
                        ['(${GpsTime} >= 0.0) && (${GpsTime} <= 64.0)', 'rgb(0,(${PointChange} * ${GpsTime}),255)'],
                        ['(${GpsTime} >= 65.0) && (${GpsTime} <= 128.0)', 'rgb(0,255,(255 - (${PointChange} * (${GpsTime}-65.0))) )'],
                        ['(${GpsTime} >= 128.0) && (${GpsTime} <= 192.0)', 'rgb((${PointChange} * (${GpsTime}-128)),255,0)'],
                        ['(${GpsTime} >= 193.0) && (${GpsTime} <= 255.0)', 'rgb(255,(255 - (${PointChange} * (${GpsTime}-193))),0)']
                    ]
                }
            });
        } else {//old pointclouds
            console.log("using UserData for elevation");
            tileset.style = new Cesium.Cesium3DTileStyle({
                defines: {
                    Height: 'clamp(${UserData}, 0, 255)/255 + 0.39',
                    PointChange: '(255/63.75)'
                },
                color: {
                    'conditions': [
                        ['(${UserData} >= 0.0) && (${UserData} <= 64.0)', 'rgb(0,(${PointChange} * ${UserData}),255)'],
                        ['(${UserData} >= 65.0) && (${UserData} <= 128.0)', 'rgb(0,255,(255 - (${PointChange} * (${UserData}-65.0))) )'],
                        ['(${UserData} >= 128.0) && (${UserData} <= 192.0)', 'rgb((${PointChange} * (${UserData}-128)),255,0)'],
                        ['(${UserData} >= 193.0) && (${UserData} <= 255.0)', 'rgb(255,(255 - (${PointChange} * (${UserData}-193))),0)']
                    ]
                }
            });
        }

    }
}

export function intensity(tileset) {
    if (tileset instanceof Cesium.Cesium3DTileset) {
        tileset.style = new Cesium.Cesium3DTileStyle({
            defines: {
                EmptyRGBValue: 'rgb(100,100,100)',
                IntensityRGBValue: 'rgb((${Intensity} > 100 ? ${Intensity}/255 : 100),(${Intensity} > 100 ? ${Intensity}/255 : 100),(${Intensity} > 100 ? ${Intensity}/255 : 100))'
            },
            color: tileset.hasIntensity ? '${IntensityRGBValue}' : '${EmptyRGBValue}'
        });

    }
}

export function treeAnalysis(task, id, data) {
    let tileset = this.tilesetData[this.selectedTileset]
    let viewer = this.viewer
    if (task == 'filter') {
        TreeAnalysis.filter({
            tileset: tileset,
            id: id,
            viewer: viewer,
            selectedEpsg: this.selectedEpsg,
            data: data.data,
            ref: this
        })
    } else {
        TreeAnalysis.showAll({
            tileset: tileset,
            viewer: viewer,
            selectedEpsg: this.selectedEpsg,
            data: data.data,
            ref: this
        })
    }
}