import Model from '@/models/Auth'

const state = () => ({
    user: null,
    authToken: null,
    loginInfo: null,
    loginHistory: {},
    hasAdminAccess: false
})

// getters  
const getters = {
    nameFirstLetter(state) {
        if (state.user) {
            return (state.user.name).charAt(0);
        }
        return false
    },
    nameOnly(state) {
        if (state.user) {
            return (state.user.name).replace(/ .*/, '');
        }
        return false
    },
    getUser(state) {
        if (state.user) {
            return state.user
        }
        return false
    },
    getToken(state) {
        if (state.authToken) {
            return state.authToken
        }
        return false
    },
    isAuth(state) {
        if (state.user) {
            return state.authToken
        }
        return false
    },
    getLoginInfo(state) {
        if (state.loginInfo) {
            return state.loginInfo
        }
        return false
    },
    getLoginHistory(state) {
        if (state.loginHistory) {
            return state.loginHistory
        }
        return false
    },
    getHasAdminAccess(state) {
        return state.hasAdminAccess;
    }
}

// actions
const actions = {
    async login(context, payload) {
        context.commit('setUser', payload.user)
        context.commit('setAuthToken', payload.token)
        context.commit('setHasAdminAccess', payload.user.hasAdminAccess)
    },
    async logout(context) {
        let response = await Model.logout();
        return new Promise((resolve) => {
            context.commit('logoutUser')
            resolve(response)
        })
    },
    async setUser(context, payload) {
        context.commit('setUser', payload)
        context.commit('setHasAdminAccess', payload.hasAdminAccess)
    },
    async setHasAdminAccess(context, payload) {
        context.commit('setHasAdminAccess', payload)
    },
    async setAuthToken(context, token) {
        context.commit('setAuthToken', token)
    },
    async setLoginInfo(context) {
        const data = await Model.getLoginInfo()
        const list = await Model.getLoginHistory()
        return new Promise((resolve, reject) => {
            if (data && list) {
                context.commit('setLoginInfo', data)
                context.commit('setLoginHistory', list)
                resolve({
                    login: data.data,
                    history: list.data
                });
            } else {
                reject({
                    error: 'Loginfo cannot be retrieved'
                });
            }
        })
    },
    async setProfileCompany(context, companyName) {
        context.commit('setProfileCompany', companyName)
    }
}

// mutations
const mutations = {
    setUser(state, user) {
        state.user = user
    },
    logoutUser(state) {
        state.user = null
        state.authToken = null
    },
    setAuthToken(state, token) {
        state.authToken = token
    },
    setHasAdminAccess(state, hasAdminAccess) {
        state.hasAdminAccess = hasAdminAccess
    },
    setLoginInfo(state, data) {
        state.loginInfo = data.data
    },
    setLoginHistory(state, data) {
        state.loginHistory = data.data
    },
    setProfileCompany(state, companyName) {
        state.user.profile.company_name = companyName
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}