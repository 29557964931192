
// Uses axios and Promises
// Everything that we need to communicate with the api will be declared here
import api from '@/providers/api'

const billing = {

    get(id) {
        return api.get('billing/details')
    },

    getCurrentPaymentMethod() {
        return api.get('billing/payment/methods/current');
    },

    addPayment(payload) {
        return api.post('billing/payment/methods/add', payload)
    },

    updatePayment(form) {
        return api.post('billing/payment/methods/update', form)
    },

    invoicePreview(payload) {
        return api.get('billing/swap/subscription/invoice/preview/' + payload.pricing_id)
    },

    detachPaymentMethod(payload) {
        return api.delete('billing/payment/methods/delete/' + payload.pm_key)
    }
}

export default billing
