import Button from '@/components/ui/common/Button';
import Input from '@/components/ui/common/Input';
import Skeleton from '@/components/ui/card/Skeleton';
import Alert from '@/components/ui/Alert';
import NavbarLight from '@/components/ui/navbar/NavbarLight';
import Navbar from '@/components/ui/navbar/Navbar';
import ToggleDark from '@/components/ui/ToggleDark';
import Footer from '@/components/ui/Footer';
import Toolbar from '@/components/ui/toolbar';
import Card from '@/components/ui/card';
import ListView from '@/components/ui/list';
import ProjectDetails from '@/components/ui/modals/create-project/ProjectDetails';
import Dropdown from '@/components/ui/dropdown';
import Notification from '@/components/ui/notification';
import Sidebar from '@/components/ui/sidebar';
import Cesium from '@/components/ui/cesium';
import Viewer from '@/components/ui/viewer';
import ModalRoot from '@/components/ui/modals/ModalRoot';
import CardProject from '@/components/ui/card/CardProject';
import Toastr from '@/components/ui/toastr';
import Classification from '@/components/ui/modals/create-project/Classification';
import UploadDataset from '@/components/ui/modals/create-project/UploadDataset';
import Chart from '@/components/ui/charts/MonthlyUsage';
import Actions from '@/components/ui/modals/actions';
import InviteForm from '@/components/ui/modals/user-share/InviteForm';
import UploadNotification from '@/components/ui/upload-notification';
import LoadingButton from '@/components/ui/common/LoadingButton';
import Loading from '@/components/ui/common/Loading';
import CreateFolder from '@/components/ui/modals/folders/CreateFolder';
import MoveToFolder from '@/components/ui/modals/folders/MoveToFolder';
import Permission from '@/components/ui/permissions';
import CPanel from '@/components/ui/panel/classification';
import FileManager from '@/components/ui/file-manager';
import Layers from '@/components/ui/file-manager/point-cloud-layers';
import TopToolbar from '@/components/ui/viewer-toolbar/top';
import Process from '@/components/ui/file-manager/process';
import TIN from '@/components/ui/file-manager/tin';
import CutFill from '@/components/ui/file-manager/cut-fill';
import Analysis from '@/components/ui/file-manager/analysis';
import Clip from '@/components/ui/file-manager/clip';
import Properties from '@/components/ui/panel/properties';
import ViewerFooter from '@/components/ui/viewer/ViewerFooter';
import Toggle from '@vueform/toggle';
import Slider from '@vueform/slider';
import ViewerNotification from '@/components/ui/notification/viewer-notif';
import FloatingBar from '@/components/ui/viewer-toolbar/floating-bar';
import MeasurementToolInfo from '@/components/ui/modals/measurement-tool-info';
import ThreeAxesHelper from '@/components/ui/viewer-toolbar/three-axes-helper';
import DataLayerModal from '@/components/ui/modals/data-layer';
import Tab from '@/components/ui/tab/Tab';
import TabWrapper from '@/components/ui/tab/TabWrapper';
import ChartViewer from '@/components/ui/panel/bottom-chart';
import ShareModal from '@/components/ui/modals/share';
import ModalInfo from '@/components/ui/modals/modal-info';
import SearchResults from '@/components/ui/modals/search-results';
import ViewerDownload from '@/components/ui/dropdown/ViewerDownload';
import PanelLoading from '@/components/ui/common/PanelLoading';
import PaymentOverdue from '@/components/ui/notification/payment-overdue';

import AdminSidebar from '@/components/ui/admin-sidebar';
import ActionButtonDropdown from '@/components/ui/action-button-dropdown';

import SkeletonFolder from '@/components/ui/card/SkeletonFolder';
import CoordinateSystem from '@/components/ui/modals/create-project/CoordinateSystem';
import SuccessNotification from '@/components/ui/modals/create-project/SuccessNotification';
import CardProjectDemo from '@/components/ui/card/CardProjectDemo';
import Uploader from '@/components/ui/uploader';

import ViewInfo from '@/components/ui/modals/view-info';
import ImportGcp from '@/components/ui/modals/data-layer/ImportGcp';
import GcpColorPicker from '@/components/ui/modals/gcp-color-picker';

import ConnectionStatus from '@/components/ui/notification/connection-status';
import Language from '@/components/ui/common/Language';

import UiUpdateStatus from '@/components/ui/notification/ui-update-status';

import CommonSkeleton from '@/components/ui/common/Skeleton';
import OrgViewSkeleton from '@/components/ui/common/OrgViewSkeleton';

export default {
    ActionButtonDropdown,
    Button,
    Input,
    Alert,
    NavbarLight,
    Footer,
    Navbar,
    ToggleDark,
    Toolbar,
    Card,
    ListView,
    ProjectDetails,
    Dropdown,
    Notification,
    Sidebar,
    Cesium,
    Viewer,
    ModalRoot,
    CardProject,
    Toastr,
    Classification,
    UploadDataset,
    Chart,
    Actions,
    InviteForm,
    UploadNotification,
    LoadingButton,
    Loading,
    CreateFolder,
    MoveToFolder,
    Permission,
    CPanel,
    FileManager,
    Layers,
    TopToolbar,
    Process,
    TIN,
    Properties,
    ViewerFooter,
    Toggle,
    Slider,
    ViewerNotification,
    FloatingBar,
    MeasurementToolInfo,
    ThreeAxesHelper,
    DataLayerModal,
    ChartViewer,
    Tab,
    TabWrapper,
    Clip,
    ShareModal,
    ModalInfo,
    SearchResults,
    ViewerDownload,
    Skeleton,
    PanelLoading,
    Analysis,
    PaymentOverdue,
    AdminSidebar,
    SkeletonFolder,
    CoordinateSystem,
    SuccessNotification,
    CardProjectDemo,
    ViewInfo,
    Uploader,
    ImportGcp,
    GcpColorPicker,
    ConnectionStatus,
    Language,
    CutFill,
    UiUpdateStatus,
    CommonSkeleton,
    OrgViewSkeleton
};
