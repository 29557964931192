
// Uses axios and Promises
// Everything that we need to communicate with the api will be declared here
import api from '@/providers/api'

const subscription = {
    intent() {
        return api.get('subscriptions/intent/get')
    },
    status() {
        return api.get('subscriptions/status');
    },
    subscribe(price_id, payment_method_id = '', coupon = '') {
        if (price_id != '') {
            return api.post('subscriptions/subscribe', {
                price_id: price_id,
                payment_method: payment_method_id,
                promo_code: coupon
            });
        }
    },
    requestInvoice(price_id) {
        if (price_id !== '') {
            return api.post('subscriptions/request/invoice', {
                price_id: price_id
            });
        }
    },
    cancel() {
        return api.get('subscriptions/cancel');
    },
    nextRenewalDate() {
        return api.get('billing/invoice/next')
    },
    planRequestUpgrade(payload) {
        return api.get('subscriptions/request/upgrade/' + payload)
    },
    contactRequest(plan_request = 'inquiry') {
        return api.get('subscriptions/request/contact/' + plan_request)
    },
    getCurrentInvoice() {
        return api.post('subscriptions/current/invoice');
    },
    payOutstanding() {
        return api.post('subscriptions/pay/outstanding');
    },
}

export default subscription
