// Uses axios and Promises
// Everything that we need to communicate with the api will be declared here
import api from '@/providers/api'

const project_resources = {

    models(id) {
        return api.get(`project/resources/models/${id}`);
    },

    pointclouds(id) {
        return api.get(`project/resources/pointclouds/${id}`);
    },

    documents(id) {
        return api.get(`project/resources/documents/${id}`);
    },

    datatypes(id) {
        return api.get(`project/resources/datatypes`);
    },

    removePointcloud(payload) {
        return api.delete(`project/resources/pointclouds/delete/${payload.project_id}/${payload.file_id}`);
    },

    removeModel(payload) {
        return api.delete(`project/resources/models/delete/${payload.project_id}/${payload.model_id}`);
    },

    removeDocument(payload) {
        return api.delete(`project/resources/documents/delete/${payload.project_id}/${payload.document_id}`)
    },

    lasinfo(file_id) {
        return api.get(`project/resources/lasinfo/${file_id}`);
    },

    download(payload, password = null) {
        return api.get(`project/resources/download/pointcloud/${payload.file_id}?password=${password}`);
    },

    download_model(payload, password = null) {
        return api.get(`project/resources/download/model/${payload.model_id}?password=${password}`);
    },

    download_document(payload, password = null) {
        return api.get(`project/resources/download/document/${payload.document_id}?password=${password}`);
    },

    updateModel(payload) {
        return api.post(`project/resources/model/update/${payload.model_id}`, payload);
    },

    updateCoordinates(payload) {
        return api.put(`project/resources/model/update/coordinates/${payload.model_id}`, payload);
    },

    importModel(project_id, payload) {
        return api.post(`project/resources/import/model/${project_id}`, payload);
    },

    importShapeFile(project_id, payload) {
        return api.post(`project/resources/import/shp/${project_id}`, payload);
    },

    importGeoJSON(project_id, payload) {
        return api.post(`project/resources/import/geojson/${project_id}`, payload);
    },

    importPointCloud(project_id, payload) {
        return api.post(`project/resources/import/pointcloud/${project_id}`, payload);
    },

    importKml(project_id, payload) {
        return api.post(`project/resources/import/kml/${project_id}`, payload);
    },

    importGcp(project_id, payload) {
        return api.post(`project/resources/import/gcp/${project_id}`, payload);
    },

    renamePointcloud(payload) {
        console.log(payload)
        return api.post(`project/resources/pointclouds/rename/${payload.project_id}/${payload.file_id}`, payload);
    },

    getTreeData(payload) {
        return api.get(`project/resources/tree-canopy/${payload.file_id}/${payload.tree_id}`);
    },

    groupData(payload) {
        return api.post(`project/resources/files/group`, payload);
    },

    ungroupData(payload) {
        return api.post(`project/resources/files/ungroup`, payload);
    },

    setGroupProperty(payload) {
        return api.post(`project/resources/files/set/group/property`, payload);
    },

    removeFileFromGroup(payload) {
        return api.post(`project/resources/files/remove/from/group`, payload);
    },

    addToGroup(payload) {
        return api.post(`project/resources/files/add/to/group`, payload);
    },

    UploadAnnotationImages(payload) {
        return api.post(`project/resources/import/annotation/images`, payload);
    },

    fetchAnnotationImages(payload) {
        return api.get(`project/resources/annotation/image/list/` + payload.project_measurement_id);
    },

    removeAnnotationImages(image_id) {
        return api.delete(`project/resources/annotation/image/delete/` + image_id);
    },

    updateModelsVisibility(project_id, payload) {
        return api.post(`project/resources/models/group/update/visibility/` + project_id, payload);
    }
}

export default project_resources