export let geoidMap = {
    '241_149': 22.0369,
    '241_150': 22.1226,
    '241_151': 22.1981,
    '241_152': 22.2543,
    '241_153': 22.2936,
    '241_154': 22.3127,
    '242_149': 22.334,
    '242_150': 22.4032,
    '242_151': 22.4686,
    '242_152': 22.5222,
    '242_153': 22.5633,
    '242_154': 22.5757,
    '243_149': 22.6134,
    '243_150': 22.6717,
    '243_151': 22.7296,
    '243_152': 22.7792,
    '243_153': 22.8197,
    '243_154': 22.8258,
    '244_149': 22.8717,
    '244_150': 22.9195,
    '244_151': 22.9684,
    '244_152': 23.0119,
    '244_153': 23.0492,
    '244_154': 23.0555,
    '245_149': 23.1039,
    '245_150': 23.1464,
    '245_151': 23.1878,
    '245_152': 23.2248,
    '245_153': 23.2563,
    '245_154': 23.2669,
    '246_149': 23.3213,
    '246_150': 23.3624,
    '246_151': 23.4,
    '246_152': 23.4324,
    '246_153': 23.4572,
    '246_154': 23.4709,
    '251_156': 24.5995,
    '251_157': 24.5937,
    '251_158': 24.5848,
    '251_159': 24.5715,
    '251_160': 24.5556,
    '251_161': 24.5401,
    '251_162': 24.518,
    '251_163': 24.5105,
    '252_156': 24.8227,
    '252_157': 24.8209,
    '252_158': 24.8191,
    '252_159': 24.8105,
    '252_160': 24.7998,
    '252_161': 24.7882,
    '252_162': 24.7745,
    '252_163': 24.7651,
    '253_156': 25.0471,
    '253_157': 25.0475,
    '253_158': 25.0452,
    '253_159': 25.0398,
    '253_160': 25.0328,
    '253_161': 25.0252,
    '253_162': 25.0201,
    '253_163': 25.0124,
    '254_156': 25.2672,
    '254_157': 25.2693,
    '254_158': 25.2685,
    '254_159': 25.2652,
    '254_160': 25.2612,
    '254_161': 25.2568,
    '254_162': 25.2557,
    '254_163': 25.2501,
    '255_145': 24.4985,
    '255_146': 24.6063,
    '255_147': 24.7084,
    '255_148': 24.8076,
    '255_149': 24.9065,
    '255_150': 25.0087,
    '255_151': 25.1113,
    '255_152': 25.2052,
    '255_153': 25.2753,
    '255_154': 25.3343,
    '255_155': 25.3802,
    '255_156': 25.4144,
    '255_157': 25.4838,
    '255_158': 25.4846,
    '255_159': 25.483,
    '255_160': 25.4812,
    '255_161': 25.4797,
    '255_162': 25.4816,
    '255_163': 25.4787,
    '256_145': 24.6257,
    '256_146': 24.7382,
    '256_147': 24.8444,
    '256_148': 24.9526,
    '256_149': 25.0576,
    '256_150': 25.1656,
    '256_151': 25.2763,
    '256_152': 25.3799,
    '256_153': 25.4609,
    '256_154': 25.5278,
    '256_155': 25.5786,
    '256_156': 25.6112,
    '256_157': 25.6407,
    '256_158': 25.663,
    '256_159': 25.6902,
    '256_160': 25.6909,
    '256_161': 25.6921,
    '256_162': 25.6963,
    '256_163': 25.6965,
    '257_145': 24.7439,
    '257_146': 24.8583,
    '257_147': 24.9797,
    '257_148': 25.1015,
    '257_149': 25.2054,
    '257_150': 25.3212,
    '257_151': 25.4461,
    '257_152': 25.5566,
    '257_153': 25.6394,
    '257_154': 25.7064,
    '257_155': 25.758,
    '257_156': 25.7952,
    '257_157': 25.8284,
    '257_158': 25.8549,
    '257_159': 25.8782,
    '257_160': 25.9011,
    '257_161': 25.9237,
    '257_162': 25.9463,
    '257_163': 25.8978,
    '257_164': 25.9219,
    '257_165': 25.9443,
    '257_166': 25.962,
    '257_167': 25.9695,
    '257_168': 25.9571,
    '257_169': 25.9424,
    '257_170': 25.9385,
    '257_171': 25.9365,
    '257_172': 25.9311,
    '257_173': 25.9284,
    '257_174': 25.9274,
    '257_175': 25.9336,
    '257_176': 25.9394,
    '258_145': 24.8535,
    '258_146': 24.9724,
    '258_147': 25.101,
    '258_148': 25.2282,
    '258_149': 25.3423,
    '258_150': 25.4598,
    '258_151': 25.5924,
    '258_152': 25.7051,
    '258_153': 25.7976,
    '258_154': 25.8671,
    '258_155': 25.9262,
    '258_156': 25.9643,
    '258_157': 25.9998,
    '258_158': 26.0315,
    '258_159': 26.0593,
    '258_160': 26.0859,
    '258_161': 26.1122,
    '258_162': 26.1347,
    '258_163': 26.0828,
    '258_164': 26.1112,
    '258_165': 26.1402,
    '258_166': 26.1625,
    '258_167': 26.1752,
    '258_168': 26.1705,
    '258_169': 26.1622,
    '258_170': 26.16,
    '258_171': 26.1537,
    '258_172': 26.1453,
    '258_173': 26.1405,
    '258_174': 26.1391,
    '258_175': 26.1393,
    '258_176': 26.1416,
    '259_145': 24.9546,
    '259_146': 25.0752,
    '259_147': 25.2,
    '259_148': 25.3287,
    '259_149': 25.4544,
    '259_150': 25.5721,
    '259_151': 25.7085,
    '259_152': 25.834,
    '259_153': 25.9408,
    '259_154': 26.0201,
    '259_155': 26.084,
    '259_156': 26.1207,
    '259_157': 26.1564,
    '259_158': 26.1944,
    '259_159': 26.2234,
    '259_160': 26.2542,
    '259_161': 26.2868,
    '259_162': 26.3123,
    '259_163': 26.2574,
    '259_164': 26.2895,
    '259_165': 26.322,
    '259_166': 26.3482,
    '259_167': 26.3648,
    '259_168': 26.3703,
    '259_169': 26.3719,
    '259_170': 26.3737,
    '259_171': 26.3638,
    '259_172': 26.3521,
    '259_173': 26.3451,
    '259_174': 26.3408,
    '259_175': 26.3369,
    '259_176': 26.336,
    '260_145': 25.0415,
    '260_146': 25.1612,
    '260_147': 25.2823,
    '260_148': 25.408,
    '260_149': 25.5363,
    '260_150': 25.6628,
    '260_151': 25.805,
    '260_152': 25.9458,
    '260_153': 26.0571,
    '260_154': 26.1496,
    '260_155': 26.2253,
    '260_156': 26.2671,
    '260_157': 26.306,
    '260_158': 26.3414,
    '260_159': 26.3768,
    '260_160': 26.4105,
    '260_161': 26.4468,
    '260_162': 26.476,
    '260_163': 26.4176,
    '260_164': 26.4532,
    '260_165': 26.4885,
    '260_166': 26.5201,
    '260_167': 26.5421,
    '260_168': 26.5538,
    '260_169': 26.5616,
    '260_170': 26.5653,
    '260_171': 26.5599,
    '260_172': 26.5509,
    '260_173': 26.5431,
    '260_174': 26.5363,
    '260_175': 26.5301,
    '260_176': 26.5266,
    '261_145': 25.0923,
    '261_146': 25.2219,
    '261_147': 25.3472,
    '261_148': 25.4732,
    '261_149': 25.6032,
    '261_150': 25.739,
    '261_151': 25.8886,
    '261_152': 26.0281,
    '261_153': 26.1486,
    '261_154': 26.2551,
    '261_155': 26.3446,
    '261_156': 26.4038,
    '261_157': 26.443,
    '261_158': 26.4756,
    '261_159': 26.5139,
    '261_160': 26.5509,
    '261_161': 26.5915,
    '261_162': 26.6249,
    '261_163': 26.5629,
    '261_164': 26.6022,
    '261_165': 26.6421,
    '261_166': 26.6797,
    '261_167': 26.7085,
    '261_168': 26.7241,
    '261_169': 26.7382,
    '261_170': 26.7444,
    '261_171': 26.7433,
    '261_172': 26.7383,
    '261_173': 26.732,
    '261_174': 26.7243,
    '261_175': 26.7149,
    '261_176': 26.7065,
    '262_145': 25.132,
    '262_146': 25.2728,
    '262_147': 25.4003,
    '262_148': 25.5279,
    '262_149': 25.66,
    '262_150': 25.7992,
    '262_151': 25.9494,
    '262_152': 26.0865,
    '262_153': 26.2176,
    '262_154': 26.3341,
    '262_155': 26.4303,
    '262_156': 26.4998,
    '262_157': 26.5471,
    '262_158': 26.5886,
    '262_159': 26.632,
    '262_160': 26.6754,
    '262_161': 26.7212,
    '262_162': 26.7616,
    '262_163': 26.6943,
    '262_164': 26.7373,
    '262_165': 26.7835,
    '262_166': 26.8296,
    '262_167': 26.8629,
    '262_168': 26.8823,
    '262_169': 26.9025,
    '262_170': 26.9103,
    '262_171': 26.9149,
    '262_172': 26.9153,
    '262_173': 26.9122,
    '262_174': 26.9052,
    '262_175': 26.8928,
    '262_176': 26.876,
    '263_145': 25.1655,
    '263_146': 25.313,
    '263_147': 25.4461,
    '263_148': 25.5713,
    '263_149': 25.7036,
    '263_150': 25.8438,
    '263_151': 25.9879,
    '263_152': 26.1228,
    '263_153': 26.2544,
    '263_154': 26.3807,
    '263_155': 26.4774,
    '263_156': 26.5528,
    '263_157': 26.6185,
    '263_158': 26.6777,
    '263_159': 26.732,
    '263_160': 26.7839,
    '263_161': 26.8361,
    '263_162': 26.8818,
    '263_163': 26.8118,
    '263_164': 26.8579,
    '263_165': 26.9069,
    '263_166': 26.957,
    '263_167': 26.9998,
    '263_168': 27.0271,
    '263_169': 27.0519,
    '263_170': 27.0662,
    '263_171': 27.0762,
    '263_172': 27.0825,
    '263_173': 27.0837,
    '263_174': 27.0796,
    '263_175': 27.0668,
    '263_176': 27.0432,
    '264_115': 21.8924,
    '264_116': 22.0637,
    '264_117': 22.2331,
    '264_118': 22.3972,
    '264_119': 22.5529,
    '264_120': 22.6987,
    '264_121': 22.8346,
    '264_122': 22.9627,
    '264_123': 23.0853,
    '264_124': 23.205,
    '264_145': 25.1905,
    '264_146': 25.3423,
    '264_147': 25.4756,
    '264_148': 25.6025,
    '264_149': 25.735,
    '264_150': 25.8736,
    '264_151': 26.014,
    '264_152': 26.1486,
    '264_153': 26.2767,
    '264_154': 26.4022,
    '264_155': 26.5068,
    '264_156': 26.5975,
    '264_157': 26.6794,
    '264_158': 26.7545,
    '264_159': 26.8193,
    '264_160': 26.8801,
    '264_161': 26.937,
    '264_162': 26.9873,
    '264_163': 26.9171,
    '264_164': 26.9652,
    '264_165': 27.0178,
    '264_166': 27.0743,
    '264_167': 27.1278,
    '264_168': 27.1633,
    '264_169': 27.1914,
    '264_170': 27.212,
    '264_171': 27.2269,
    '264_172': 27.239,
    '264_173': 27.2447,
    '264_174': 27.2432,
    '264_175': 27.2306,
    '264_176': 27.2062,
    '265_115': 22.0018,
    '265_116': 22.1791,
    '265_117': 22.3534,
    '265_118': 22.521,
    '265_119': 22.678,
    '265_120': 22.8222,
    '265_121': 22.9538,
    '265_122': 23.077,
    '265_123': 23.1947,
    '265_124': 23.3094,
    '265_145': 25.2005,
    '265_146': 25.345,
    '265_147': 25.4859,
    '265_148': 25.6227,
    '265_149': 25.7558,
    '265_150': 25.8941,
    '265_151': 26.034,
    '265_152': 26.1697,
    '265_153': 26.2996,
    '265_154': 26.4249,
    '265_155': 26.5389,
    '265_156': 26.6443,
    '265_157': 26.7364,
    '265_158': 26.8183,
    '265_159': 26.8908,
    '265_160': 26.9602,
    '265_162': 26.9506,
    '265_163': 27.0064,
    '265_164': 27.0604,
    '265_165': 27.1195,
    '265_166': 27.1904,
    '265_167': 27.2558,
    '265_168': 27.2916,
    '265_169': 27.3204,
    '265_170': 27.3457,
    '265_171': 27.3654,
    '265_172': 27.3825,
    '265_173': 27.3944,
    '265_174': 27.3984,
    '265_175': 27.3893,
    '265_176': 27.3646,
    '266_115': 22.1034,
    '266_116': 22.2874,
    '266_117': 22.4665,
    '266_118': 22.6398,
    '266_119': 22.7987,
    '266_120': 22.9419,
    '266_121': 23.0647,
    '266_122': 23.1812,
    '266_123': 23.2918,
    '266_124': 23.3988,
    '266_145': 25.2086,
    '266_146': 25.3474,
    '266_147': 25.4905,
    '266_148': 25.6327,
    '266_149': 25.7695,
    '266_150': 25.9084,
    '266_151': 26.0482,
    '266_152': 26.1857,
    '266_153': 26.3237,
    '266_154': 26.4535,
    '266_155': 26.574,
    '266_156': 26.6846,
    '266_157': 26.7843,
    '266_158': 26.873,
    '266_159': 26.9498,
    '266_160': 27.0263,
    '266_162': 27.0224,
    '266_163': 27.0859,
    '266_164': 27.1462,
    '266_165': 27.2092,
    '266_166': 27.2878,
    '266_167': 27.3588,
    '266_168': 27.4074,
    '266_169': 27.4402,
    '266_170': 27.4663,
    '266_171': 27.4902,
    '266_172': 27.5126,
    '266_173': 27.5312,
    '266_174': 27.5417,
    '266_175': 27.5347,
    '266_176': 27.5164,
    '267_115': 22.2013,
    '267_116': 22.388,
    '267_117': 22.5745,
    '267_118': 22.755,
    '267_119': 22.9161,
    '267_120': 23.0554,
    '267_121': 23.1684,
    '267_122': 23.2713,
    '267_123': 23.376,
    '267_124': 23.4755,
    '267_145': 25.219,
    '267_146': 25.3521,
    '267_147': 25.4942,
    '267_148': 25.6385,
    '267_149': 25.7807,
    '267_150': 25.9176,
    '267_151': 26.0568,
    '267_152': 26.1961,
    '267_153': 26.2895,
    '267_154': 26.4234,
    '267_162': 27.0814,
    '267_163': 27.1496,
    '267_164': 27.2149,
    '267_165': 27.2826,
    '267_166': 27.3537,
    '267_167': 27.4238,
    '267_168': 27.4844,
    '267_169': 27.5329,
    '267_170': 27.5665,
    '267_171': 27.5977,
    '267_172': 27.6273,
    '267_173': 27.6522,
    '267_174': 27.6683,
    '267_175': 27.6697,
    '267_176': 27.6592,
    '268_115': 22.2944,
    '268_116': 22.48,
    '268_117': 22.6675,
    '268_118': 22.8505,
    '268_119': 23.0057,
    '268_120': 23.1437,
    '268_121': 23.2489,
    '268_122': 23.3457,
    '268_123': 23.4447,
    '268_124': 23.5394,
    '268_151': 26.0037,
    '268_152': 26.1397,
    '268_153': 26.2819,
    '268_154': 26.4255,
    '268_162': 27.1325,
    '268_163': 27.2051,
    '268_164': 27.2748,
    '268_165': 27.3447,
    '268_166': 27.4126,
    '268_167': 27.4803,
    '268_168': 27.5444,
    '268_169': 27.606,
    '268_170': 27.6539,
    '268_171': 27.6903,
    '268_172': 27.7277,
    '268_173': 27.7604,
    '268_174': 27.7838,
    '268_175': 27.7936,
    '268_176': 27.7925,
    '269_115': 22.3795,
    '269_116': 22.5585,
    '269_117': 22.7343,
    '269_118': 22.9043,
    '269_119': 23.0548,
    '269_120': 23.1876,
    '269_121': 23.2982,
    '269_122': 23.4007,
    '269_123': 23.4977,
    '269_124': 23.5912,
    '269_151': 25.9806,
    '269_152': 26.119,
    '269_153': 26.2654,
    '269_154': 26.4165,
    '269_162': 27.1784,
    '269_163': 27.2563,
    '269_164': 27.3285,
    '269_165': 27.3998,
    '269_166': 27.469,
    '269_167': 27.5372,
    '269_168': 27.6008,
    '269_169': 27.6656,
    '269_170': 27.729,
    '269_171': 27.7728,
    '269_172': 27.8165,
    '269_173': 27.8585,
    '269_174': 27.892,
    '269_175': 27.9107,
    '269_176': 27.9191,
    '270_115': 22.4545,
    '270_116': 22.6221,
    '270_117': 22.7868,
    '270_118': 22.9444,
    '270_119': 23.0888,
    '270_120': 23.2183,
    '270_121': 23.3329,
    '270_122': 23.4377,
    '270_123': 23.5368,
    '270_124': 23.6316,
    '270_151': 25.9424,
    '270_152': 26.0867,
    '270_153': 26.2357,
    '270_154': 26.3887,
    '270_162': 27.214,
    '270_163': 27.2982,
    '270_164': 27.3754,
    '270_165': 27.4488,
    '270_166': 27.5193,
    '270_167': 27.5859,
    '270_168': 27.6499,
    '270_169': 27.7157,
    '270_170': 27.783,
    '270_171': 27.8392,
    '270_172': 27.8925,
    '270_173': 27.9452,
    '270_174': 27.9905,
    '270_175': 28.0214,
    '270_176': 28.0385,
    '271_115': 22.518,
    '271_116': 22.6736,
    '271_117': 22.8266,
    '271_118': 22.974,
    '271_119': 23.1119,
    '271_120': 23.2389,
    '271_121': 23.3549,
    '271_122': 23.4625,
    '271_123': 23.5641,
    '271_124': 23.6614,
    '271_162': 27.2391,
    '271_163': 27.3297,
    '271_164': 27.4149,
    '271_165': 27.4898,
    '271_166': 27.5582,
    '271_167': 27.6238,
    '271_168': 27.6886,
    '271_169': 27.7554,
    '271_170': 27.8232,
    '271_171': 27.8888,
    '271_172': 27.9545,
    '271_173': 28.0163,
    '271_174': 28.075,
    '271_175': 28.1161,
    '271_176': 28.1426,
    '272_162': 27.2529,
    '272_163': 27.3472,
    '272_164': 27.4418,
    '272_165': 27.5179,
    '272_166': 27.585,
    '272_167': 27.6493,
    '272_168': 27.7164,
    '272_169': 27.7839,
    '272_170': 27.8525,
    '272_171': 27.9218,
    '272_172': 27.9935,
    '272_173': 28.0631,
    '272_174': 28.1345,
    '272_175': 28.1796,
    '272_176': 28.2091,
    '273_162': 27.2548,
    '273_163': 27.3558,
    '273_164': 27.451,
    '273_165': 27.5322,
    '273_166': 27.6003,
    '273_167': 27.6641,
    '273_168': 27.7337,
    '273_169': 27.8036,
    '273_170': 27.8719,
    '273_171': 27.9413,
    '273_172': 28.0142,
    '273_173': 28.0771,
    '273_174': 28.1471,
    '273_175': 28.2065,
    '273_176': 28.2497,
    '274_162': 27.244,
    '274_163': 27.3526,
    '274_164': 27.4515,
    '274_165': 27.5371,
    '274_166': 27.6066,
    '274_167': 27.6694,
    '274_168': 27.742,
    '274_169': 27.815,
    '274_170': 27.8847,
    '274_171': 27.9537,
    '274_172': 28.0242,
    '274_173': 28.0821,
    '274_174': 28.1446,
    '274_175': 28.2048,
    '274_176': 28.2731,
    '275_162': 27.2191,
    '275_163': 27.3348,
    '275_164': 27.4391,
    '275_165': 27.5277,
    '275_166': 27.6027,
    '275_167': 27.6664,
    '275_168': 27.7394,
    '275_169': 27.8191,
    '275_170': 27.8934,
    '275_171': 27.9653,
    '275_172': 28.037,
    '275_173': 28.1075,
    '275_174': 28.1696,
    '275_175': 28.2378,
    '275_176': 28.3093,
    '276_162': 27.1803,
    '276_163': 27.3027,
    '276_164': 27.4125,
    '276_165': 27.5054,
    '276_166': 27.5869,
    '276_167': 27.6601,
    '276_168': 27.733,
    '276_169': 27.819,
    '276_170': 27.8988,
    '276_171': 27.9754,
    '276_172': 28.0524,
    '276_173': 28.1304,
    '276_174': 28.2015,
    '276_175': 28.2748,
    '276_176': 28.3445,
    '277_162': 27.1335,
    '277_163': 27.2602,
    '277_164': 27.3741,
    '277_165': 27.4737,
    '277_166': 27.5638,
    '277_167': 27.6466,
    '277_168': 27.7206,
    '277_169': 27.8081,
    '277_170': 27.8974,
    '277_171': 27.9829,
    '277_172': 28.0672,
    '277_173': 28.1512,
    '277_174': 28.2326,
    '277_175': 28.3109,
    '277_176': 28.3792,
    '277_185': 28.7528,
    '277_186': 28.7914,
    '277_187': 28.8294,
    '277_188': 28.8638,
    '277_189': 28.8935,
    '277_190': 28.9144,
    '277_191': 28.9309,
    '278_162': 27.0778,
    '278_163': 27.2072,
    '278_164': 27.3242,
    '278_165': 27.4301,
    '278_166': 27.5276,
    '278_167': 27.618,
    '278_168': 27.7005,
    '278_169': 27.7932,
    '278_170': 27.8901,
    '278_171': 27.9833,
    '278_172': 28.0735,
    '278_173': 28.164,
    '278_174': 28.2541,
    '278_175': 28.3393,
    '278_176': 28.4068,
    '278_185': 28.8133,
    '278_186': 28.8542,
    '278_187': 28.8938,
    '278_188': 28.9281,
    '278_189': 28.9559,
    '278_190': 28.9769,
    '278_191': 28.9914,
    '279_162': 27.0129,
    '279_163': 27.1453,
    '279_164': 27.267,
    '279_165': 27.378,
    '279_166': 27.4827,
    '279_167': 27.5817,
    '279_168': 27.6766,
    '279_169': 27.7767,
    '279_170': 27.8773,
    '279_171': 27.9709,
    '279_172': 28.0647,
    '279_173': 28.1601,
    '279_174': 28.2569,
    '279_175': 28.3528,
    '279_176': 28.4192,
    '279_185': 28.8788,
    '279_186': 28.9173,
    '279_187': 28.9554,
    '279_188': 28.9884,
    '279_189': 29.0141,
    '279_190': 29.0336,
    '279_191': 29.0458,
    '280_185': 28.9446,
    '280_186': 28.9778,
    '280_187': 29.0122,
    '280_188': 29.0433,
    '280_189': 29.0672,
    '280_190': 29.0854,
    '280_191': 29.0956,
    '281_185': 28.9866,
    '281_186': 29.025,
    '281_187': 29.0604,
    '281_188': 29.0912,
    '281_189': 29.1154,
    '281_190': 29.1329,
    '281_191': 29.1412,
    '281_209': 28.4604,
    '281_210': 28.3886,
    '281_211': 28.3197,
    '281_212': 28.2515,
    '281_213': 28.1804,
    '281_214': 28.1027,
    '281_215': 28.0281,
    '281_216': 27.9717,
    '281_217': 27.9262,
    '281_218': 27.8865,
    '281_219': 27.8514,
    '281_220': 27.8077,
    '282_185': 29.0217,
    '282_186': 29.0653,
    '282_187': 29.1019,
    '282_188': 29.1332,
    '282_189': 29.1591,
    '282_190': 29.1761,
    '282_191': 29.182,
    '282_209': 28.5716,
    '282_210': 28.5042,
    '282_211': 28.4363,
    '282_212': 28.3711,
    '282_213': 28.307,
    '282_214': 28.2342,
    '282_215': 28.1604,
    '282_216': 28.0979,
    '282_217': 28.0451,
    '282_218': 27.9976,
    '282_219': 27.9539,
    '282_220': 27.9073,
    '283_209': 28.6766,
    '283_210': 28.6137,
    '283_211': 28.5499,
    '283_212': 28.4894,
    '283_213': 28.4315,
    '283_214': 28.3671,
    '283_215': 28.2903,
    '283_216': 28.2236,
    '283_217': 28.1642,
    '283_218': 28.1085,
    '283_219': 28.0561,
    '283_220': 28.0048,
    '284_186': 29.1713,
    '284_187': 29.204,
    '284_188': 29.2298,
    '284_189': 29.2488,
    '284_190': 29.2591,
    '284_209': 28.7773,
    '284_210': 28.7174,
    '284_211': 28.6585,
    '284_212': 28.602,
    '284_213': 28.5505,
    '284_214': 28.4931,
    '284_215': 28.4156,
    '284_216': 28.3487,
    '284_217': 28.2848,
    '284_218': 28.2185,
    '284_219': 28.1575,
    '284_220': 28.101,
    '285_186': 29.1926,
    '285_187': 29.2268,
    '285_188': 29.2525,
    '285_189': 29.2715,
    '285_190': 29.2811,
    '285_209': 28.8711,
    '285_210': 28.8131,
    '285_211': 28.7587,
    '285_212': 28.7062,
    '285_213': 28.6514,
    '285_214': 28.5893,
    '285_215': 28.5217,
    '285_216': 28.461,
    '285_217': 28.3944,
    '285_218': 28.3199,
    '285_219': 28.2559,
    '285_220': 28.1947,
    '286_186': 29.2083,
    '286_187': 29.2436,
    '286_188': 29.2689,
    '286_189': 29.2871,
    '286_190': 29.2953,
    '286_204': 29.2901,
    '286_205': 29.1901,
    '286_206': 29.0894,
    '286_207': 28.9762,
    '286_208': 28.8539,
    '286_209': 28.7237,
    '286_210': 28.8983,
    '286_211': 28.8476,
    '286_212': 28.8029,
    '286_213': 28.7409,
    '286_214': 28.6707,
    '286_215': 28.6119,
    '286_216': 28.5516,
    '286_217': 28.4841,
    '286_218': 28.4127,
    '286_219': 28.3465,
    '286_220': 28.2804,
    '287_186': 29.2194,
    '287_187': 29.255,
    '287_188': 29.2792,
    '287_189': 29.2955,
    '287_190': 29.3014,
    '287_204': 29.3267,
    '287_205': 29.2292,
    '287_206': 29.1284,
    '287_207': 29.0206,
    '287_208': 28.9051,
    '287_209': 28.781,
    '287_210': 28.9776,
    '287_211': 28.9361,
    '287_212': 28.8995,
    '287_213': 28.8218,
    '287_214': 28.7454,
    '287_215': 28.6941,
    '287_216': 28.6353,
    '287_217': 28.5699,
    '287_218': 28.5022,
    '287_219': 28.4317,
    '287_220': 28.3611,
    '288_186': 29.2275,
    '288_187': 29.2633,
    '288_188': 29.2854,
    '288_189': 29.2981,
    '288_190': 29.3007,
    '288_204': 29.3514,
    '288_205': 29.2574,
    '288_206': 29.161,
    '288_207': 29.0586,
    '288_208': 28.9503,
    '288_209': 28.8334,
    '288_210': 29.0728,
    '288_211': 29.0596,
    '288_212': 29.0009,
    '288_213': 28.8947,
    '288_214': 28.8325,
    '288_215': 28.7809,
    '288_216': 28.7226,
    '288_217': 28.6594,
    '288_218': 28.5895,
    '288_219': 28.5163,
    '288_220': 28.4421,
    '289_204': 29.3661,
    '289_205': 29.2807,
    '289_206': 29.1909,
    '289_207': 29.0957,
    '289_208': 28.9955,
    '289_209': 28.8815,
    '289_210': 29.1663,
    '289_211': 29.1552,
    '289_212': 29.0884,
    '289_213': 28.9812,
    '289_214': 28.9265,
    '289_215': 28.8743,
    '289_216': 28.8139,
    '289_217': 28.748,
    '289_218': 28.677,
    '289_219': 28.6023,
    '289_220': 28.5257,
    '290_204': 29.3767,
    '290_205': 29.2983,
    '290_206': 29.215,
    '290_207': 29.1276,
    '290_208': 29.0321,
    '290_209': 28.9215,
    '290_210': 29.2408,
    '290_211': 29.2203,
    '290_212': 29.172,
    '290_213': 29.0853,
    '290_214': 29.0228,
    '290_215': 28.964,
    '290_216': 28.9016,
    '290_217': 28.8349,
    '290_218': 28.764,
    '290_219': 28.6897,
    '290_220': 28.6141,
    '291_204': 29.3843,
    '291_205': 29.3123,
    '291_206': 29.2351,
    '291_207': 29.1539,
    '291_208': 29.0604,
    '291_209': 28.9564,
    '291_210': 29.3046,
    '291_211': 29.2826,
    '291_212': 29.2454,
    '291_213': 29.173,
    '291_214': 29.1105,
    '291_215': 29.0494,
    '291_216': 28.9865,
    '291_217': 28.9206,
    '291_218': 28.8507,
    '291_219': 28.7774,
    '291_220': 28.7026,
    '292_204': 29.3899,
    '292_205': 29.3224,
    '292_206': 29.2512,
    '292_207': 29.1736,
    '292_208': 29.0852,
    '292_209': 28.9871,
    '292_210': 29.3603,
    '292_211': 29.3355,
    '292_212': 29.2988,
    '292_213': 29.246,
    '292_214': 29.1878,
    '292_215': 29.1285,
    '292_216': 29.0674,
    '292_217': 29.0035,
    '292_218': 28.936,
    '292_219': 28.8643,
    '292_220': 28.7901,
    '293_204': 29.3851,
    '293_205': 29.3287,
    '293_206': 29.2649,
    '293_207': 29.1926,
    '293_208': 29.1113,
    '293_209': 29.0166,
    '293_210': 29.4113,
    '293_211': 29.3859,
    '293_212': 29.351,
    '293_213': 29.309,
    '293_214': 29.2575,
    '293_215': 29.2022,
    '293_216': 29.144,
    '293_217': 29.083,
    '293_218': 29.0186,
    '293_219': 28.9487,
    '293_220': 28.8744,
    '294_208': 29.4898,
    '294_209': 29.4764,
    '294_210': 29.4583,
    '294_211': 29.4344,
    '294_212': 29.403,
    '294_213': 29.3676,
    '294_214': 29.3219,
    '294_215': 29.2707,
    '294_216': 29.2156,
    '294_217': 29.1573,
    '294_218': 29.0956,
    '294_219': 29.0296,
    '294_220': 28.9572,
    '295_208': 29.5248,
    '295_209': 29.5164,
    '295_210': 29.502,
    '295_211': 29.4812,
    '295_212': 29.454,
    '295_213': 29.4236,
    '295_214': 29.3827,
    '295_215': 29.3354,
    '295_216': 29.2834,
    '295_217': 29.2276,
    '295_218': 29.1687,
    '295_219': 29.1064,
    '295_220': 29.0364,
    '296_208': 29.5509,
    '296_209': 29.5498,
    '296_210': 29.5411,
    '296_211': 29.5255,
    '296_212': 29.5028,
    '296_213': 29.4765,
    '296_214': 29.4397,
    '296_215': 29.3961,
    '296_216': 29.3472,
    '296_217': 29.2939,
    '296_218': 29.2381,
    '296_219': 29.1776,
    '296_220': 29.1066,
    '297_208': 29.5732,
    '297_209': 29.5797,
    '297_210': 29.5773,
    '297_211': 29.5699,
    '297_212': 29.5518,
    '297_213': 29.5267,
    '297_214': 29.494,
    '297_215': 29.4542,
    '297_216': 29.4082,
    '297_217': 29.3573,
    '297_218': 29.3051,
    '297_219': 29.2483,
    '297_220': 29.1787,
    '298_208': 29.5966,
    '298_209': 29.6101,
    '298_210': 29.6134,
    '298_211': 29.6084,
    '298_212': 29.5951,
    '298_213': 29.5742,
    '298_214': 29.546,
    '298_215': 29.5107,
    '298_216': 29.4685,
    '298_217': 29.4207,
    '298_218': 29.3697,
    '298_219': 29.3148,
    '298_220': 29.2458,
    '299_208': 29.6151,
    '299_209': 29.6347,
    '299_210': 29.6435,
    '299_211': 29.6429,
    '299_212': 29.6345,
    '299_213': 29.6187,
    '299_214': 29.5958,
    '299_215': 29.5657,
    '299_216': 29.5279,
    '299_217': 29.4824,
    '299_218': 29.4302,
    '299_219': 29.3731,
    '299_220': 29.3088,
    '300_208': 29.6288,
    '300_209': 29.6542,
    '300_210': 29.6677,
    '300_211': 29.672,
    '300_212': 29.6686,
    '300_213': 29.6584,
    '300_214': 29.6418,
    '300_215': 29.6181,
    '300_216': 29.5865,
    '300_217': 29.5467,
    '300_218': 29.4983,
    '300_219': 29.4402,
    '300_220': 29.3747,
    '301_208': 29.6366,
    '301_209': 29.6671,
    '301_210': 29.6854,
    '301_211': 29.6944,
    '301_212': 29.6961,
    '301_213': 29.692,
    '301_214': 29.6821,
    '301_215': 29.6658,
    '301_216': 29.6413,
    '301_217': 29.607,
    '301_218': 29.5615,
    '301_219': 29.5041,
    '301_220': 29.4386,
    '302_208': 29.6346,
    '302_209': 29.6725,
    '302_210': 29.696,
    '302_211': 29.7091,
    '302_212': 29.7156,
    '302_213': 29.7175,
    '302_214': 29.7152,
    '302_215': 29.7071,
    '302_216': 29.6904,
    '302_217': 29.6618,
    '302_218': 29.6184,
    '302_219': 29.5622,
    '302_220': 29.496,
    '303_208': 29.629,
    '303_209': 29.673,
    '303_210': 29.6998,
    '303_211': 29.7156,
    '303_212': 29.7257,
    '303_213': 29.7335,
    '303_214': 29.7393,
    '303_215': 29.7403,
    '303_216': 29.7318,
    '303_217': 29.7095,
    '303_218': 29.668,
    '303_219': 29.6129,
    '303_220': 29.5483,
    '304_208': 29.6195,
    '304_209': 29.6678,
    '304_210': 29.6955,
    '304_211': 29.7111,
    '304_212': 29.723,
    '304_213': 29.7362,
    '304_214': 29.7506,
    '304_215': 29.7614,
    '304_216': 29.7593,
    '304_217': 29.7398,
    '304_218': 29.7053,
    '304_219': 29.6521,
    '304_220': 29.5886,
    '348_448': 35.9688,
    '348_449': 36.1108,
    '348_450': 36.2206,
    '348_451': 36.305,
    '348_452': 36.358,
    '349_448': 35.9944,
    '349_449': 36.1553,
    '349_450': 36.2817,
    '349_451': 36.3779,
    '349_452': 36.4275,
    '350_448': 36.0131,
    '350_449': 36.1809,
    '350_450': 36.3156,
    '350_451': 36.415,
    '350_452': 36.4814,
    '351_448': 36.0326,
    '351_449': 36.2007,
    '351_450': 36.338,
    '351_451': 36.4415,
    '351_452': 36.5174,
    '352_448': 36.0533,
    '352_449': 36.2215,
    '352_450': 36.3591,
    '352_451': 36.4617,
    '352_452': 36.5394,
    '353_448': 36.0704,
    '353_449': 36.2391,
    '353_450': 36.3762,
    '353_451': 36.4743,
    '353_452': 36.5483,
    '355_451': 36.794,
    '355_452': 36.848,
    '355_453': 36.8847,
    '355_454': 36.9074,
    '356_451': 36.7651,
    '356_452': 36.8232,
    '356_453': 36.8657,
    '356_454': 36.9008,
    '357_451': 36.7342,
    '357_452': 36.7942,
    '357_453': 36.8419,
    '357_454': 36.8865,
    '358_451': 36.6979,
    '358_452': 36.7564,
    '358_453': 36.8087,
    '358_454': 36.8613,
    '360_302': 29.7704,
    '360_303': 29.7304,
    '360_304': 29.6908,
    '360_305': 29.6464,
    '360_306': 29.6009,
    '360_307': 29.5584,
    '360_308': 29.5223,
    '360_309': 29.5034,
    '360_310': 29.4911,
    '360_311': 29.4782,
    '360_312': 29.4583,
    '360_313': 29.4339,
    '360_314': 29.4039,
    '360_315': 29.3768,
    '360_316': 29.3458,
    '360_317': 29.3088,
    '360_318': 29.2787,
    '360_319': 29.2435,
    '360_320': 29.2068,
    '360_321': 29.1677,
    '360_322': 29.1232,
    '361_302': 29.9592,
    '361_303': 29.9218,
    '361_304': 29.8796,
    '361_305': 29.8263,
    '361_306': 29.7707,
    '361_307': 29.7225,
    '361_308': 29.6926,
    '361_309': 29.6711,
    '361_310': 29.6568,
    '361_311': 29.6433,
    '361_312': 29.6209,
    '361_313': 29.5895,
    '361_314': 29.5562,
    '361_315': 29.5236,
    '361_316': 29.489,
    '361_317': 29.4446,
    '361_318': 29.4024,
    '361_319': 29.3627,
    '361_320': 29.3193,
    '361_321': 29.2722,
    '361_322': 29.2207,
    '362_302': 30.149,
    '362_303': 30.107,
    '362_304': 30.0619,
    '362_305': 30.0119,
    '362_306': 29.9522,
    '362_307': 29.9065,
    '362_308': 29.8732,
    '362_309': 29.8455,
    '362_310': 29.825,
    '362_311': 29.8047,
    '362_312': 29.777,
    '362_313': 29.7429,
    '362_314': 29.7081,
    '362_315': 29.6687,
    '362_316': 29.6275,
    '362_317': 29.5791,
    '362_318': 29.5297,
    '362_319': 29.4826,
    '362_320': 29.4318,
    '362_321': 29.3767,
    '362_322': 29.3181,
    '363_302': 30.3333,
    '363_303': 30.2853,
    '363_304': 30.2348,
    '363_305': 30.1842,
    '363_306': 30.1257,
    '363_307': 30.0858,
    '363_308': 30.0519,
    '363_309': 30.0169,
    '363_310': 29.9906,
    '363_311': 29.9645,
    '363_312': 29.9312,
    '363_313': 29.8964,
    '363_314': 29.8597,
    '363_315': 29.8121,
    '363_316': 29.766,
    '363_317': 29.7133,
    '363_318': 29.6594,
    '363_319': 29.6041,
    '363_320': 29.5444,
    '363_321': 29.4809,
    '363_322': 29.416,
    '364_302': 30.5048,
    '364_303': 30.4548,
    '364_304': 30.4056,
    '364_305': 30.353,
    '364_306': 30.2987,
    '364_307': 30.2563,
    '364_308': 30.219,
    '364_309': 30.1817,
    '364_310': 30.1481,
    '364_311': 30.1144,
    '364_312': 30.0768,
    '364_313': 30.0427,
    '364_314': 30.0029,
    '364_315': 29.9535,
    '364_316': 29.9005,
    '364_317': 29.8447,
    '364_318': 29.786,
    '364_319': 29.7233,
    '364_320': 29.652,
    '364_321': 29.5826,
    '364_322': 29.5107,
    '365_286': 31.2768,
    '365_287': 31.3259,
    '365_288': 31.3642,
    '365_289': 31.3826,
    '365_290': 31.3784,
    '365_291': 31.3583,
    '365_292': 31.3251,
    '365_293': 31.2796,
    '365_294': 31.2226,
    '365_295': 31.1626,
    '365_296': 31.1107,
    '365_297': 31.0573,
    '365_298': 31.0105,
    '365_299': 30.9696,
    '365_302': 30.6709,
    '365_303': 30.6186,
    '365_304': 30.57,
    '365_305': 30.5163,
    '365_306': 30.466,
    '365_307': 30.4238,
    '365_308': 30.3812,
    '365_309': 30.3409,
    '365_310': 30.3017,
    '365_311': 30.2635,
    '365_312': 30.2252,
    '365_313': 30.1871,
    '365_314': 30.1434,
    '365_315': 30.0924,
    '365_316': 30.0344,
    '365_317': 29.9778,
    '365_318': 29.9169,
    '365_319': 29.8445,
    '365_320': 29.763,
    '365_321': 29.686,
    '365_322': 29.6062,
    '366_286': 31.4166,
    '366_287': 31.4648,
    '366_288': 31.5038,
    '366_289': 31.5155,
    '366_290': 31.5065,
    '366_291': 31.483,
    '366_292': 31.4484,
    '366_293': 31.4039,
    '366_294': 31.3511,
    '366_295': 31.2954,
    '366_296': 31.243,
    '366_297': 31.1927,
    '366_298': 31.1477,
    '366_299': 31.1088,
    '366_302': 30.8346,
    '366_303': 30.7816,
    '366_304': 30.7283,
    '366_305': 30.6746,
    '366_306': 30.6256,
    '366_307': 30.5841,
    '366_308': 30.5418,
    '366_309': 30.4996,
    '366_310': 30.4507,
    '366_311': 30.4098,
    '366_312': 30.3704,
    '366_313': 30.3309,
    '366_314': 30.2839,
    '366_315': 30.2286,
    '366_316': 30.1706,
    '366_317': 30.1163,
    '366_318': 30.0669,
    '366_319': 29.9655,
    '366_320': 29.877,
    '366_321': 29.7904,
    '366_322': 29.7006,
    '367_286': 31.5513,
    '367_287': 31.5985,
    '367_288': 31.637,
    '367_289': 31.6409,
    '367_290': 31.6268,
    '367_291': 31.6006,
    '367_292': 31.5649,
    '367_293': 31.521,
    '367_294': 31.4714,
    '367_295': 31.4189,
    '367_296': 31.3669,
    '367_297': 31.318,
    '367_298': 31.282,
    '367_299': 31.2414,
    '367_302': 30.9947,
    '367_303': 30.9407,
    '367_304': 30.8846,
    '367_305': 30.8287,
    '367_306': 30.7781,
    '367_307': 30.7344,
    '367_308': 30.6954,
    '367_309': 30.6511,
    '367_310': 30.5966,
    '367_311': 30.5498,
    '367_312': 30.5072,
    '367_313': 30.4626,
    '367_314': 30.4129,
    '367_315': 30.3585,
    '367_316': 30.3023,
    '367_317': 30.2391,
    '367_318': 30.171,
    '367_319': 30.0799,
    '367_320': 29.9851,
    '367_321': 29.8939,
    '367_322': 29.7891,
    '368_286': 31.6806,
    '368_287': 31.7209,
    '368_288': 31.7482,
    '368_289': 31.7517,
    '368_290': 31.7374,
    '368_291': 31.7106,
    '368_292': 31.6749,
    '368_293': 31.6313,
    '368_294': 31.5845,
    '368_295': 31.5358,
    '368_296': 31.4864,
    '368_297': 31.4395,
    '368_298': 31.3991,
    '368_299': 31.3619,
    '368_302': 31.1489,
    '368_303': 31.0911,
    '368_304': 31.0335,
    '368_305': 30.9765,
    '368_306': 30.9244,
    '368_307': 30.8782,
    '368_308': 30.8359,
    '368_309': 30.7896,
    '368_310': 30.738,
    '368_311': 30.6893,
    '368_312': 30.6398,
    '368_313': 30.5894,
    '368_314': 30.5365,
    '368_315': 30.4807,
    '368_316': 30.4215,
    '368_317': 30.3546,
    '368_318': 30.2785,
    '368_319': 30.1874,
    '368_320': 30.0956,
    '368_321': 30.006,
    '368_322': 29.8836,
    '369_286': 31.802,
    '369_287': 31.8353,
    '369_288': 31.855,
    '369_289': 31.8565,
    '369_290': 31.8419,
    '369_291': 31.8136,
    '369_292': 31.7783,
    '369_293': 31.7373,
    '369_294': 31.694,
    '369_295': 31.6457,
    '369_296': 31.6003,
    '369_297': 31.5564,
    '369_298': 31.5177,
    '369_299': 31.484,
    '369_302': 31.2899,
    '369_303': 31.2313,
    '369_304': 31.1745,
    '369_305': 31.1178,
    '369_306': 31.0651,
    '369_307': 31.0175,
    '369_308': 30.9719,
    '369_309': 30.9223,
    '369_310': 30.8735,
    '369_311': 30.827,
    '369_312': 30.7724,
    '369_313': 30.7138,
    '369_314': 30.6553,
    '369_315': 30.5965,
    '369_316': 30.5353,
    '369_317': 30.467,
    '369_318': 30.3898,
    '369_319': 30.2965,
    '369_320': 30.183,
    '369_321': 30.067,
    '369_322': 29.9534,
    '370_286': 31.9217,
    '370_287': 31.9496,
    '370_288': 31.9617,
    '370_289': 31.9596,
    '370_290': 31.9432,
    '370_291': 31.9136,
    '370_292': 31.8784,
    '370_293': 31.8382,
    '370_294': 31.7991,
    '370_295': 31.75,
    '370_296': 31.7065,
    '370_297': 31.6647,
    '370_298': 31.6279,
    '370_299': 31.5975,
    '370_302': 31.4215,
    '370_303': 31.3652,
    '370_304': 31.3093,
    '370_305': 31.2529,
    '370_306': 31.2003,
    '370_307': 31.1514,
    '370_308': 31.1032,
    '370_309': 31.051,
    '370_310': 31.0021,
    '370_311': 30.9503,
    '370_312': 30.8943,
    '370_313': 30.8461,
    '370_314': 30.7702,
    '370_315': 30.7068,
    '370_316': 30.6435,
    '370_317': 30.5742,
    '370_318': 30.4996,
    '370_319': 30.4109,
    '370_320': 30.279,
    '370_321': 30.1511,
    '370_322': 30.035,
    '371_286': 32.0373,
    '371_287': 32.0648,
    '371_288': 32.0708,
    '371_289': 32.0616,
    '371_290': 32.0404,
    '371_291': 32.0096,
    '371_292': 31.9723,
    '371_293': 31.9316,
    '371_294': 31.895,
    '371_295': 31.8476,
    '371_296': 31.8036,
    '371_297': 31.7629,
    '371_298': 31.7271,
    '371_299': 31.6995,
    '371_302': 31.5443,
    '371_303': 31.4915,
    '371_304': 31.4364,
    '371_305': 31.3809,
    '371_306': 31.3292,
    '371_307': 31.2798,
    '371_308': 31.2296,
    '371_309': 31.1764,
    '371_310': 31.1243,
    '371_311': 31.0668,
    '371_312': 31.0078,
    '371_313': 30.9485,
    '371_314': 30.8788,
    '371_315': 30.8095,
    '371_316': 30.741,
    '371_317': 30.6688,
    '371_318': 30.5892,
    '371_319': 30.4956,
    '371_320': 30.3744,
    '371_321': 30.2412,
    '371_322': 30.1202,
    '372_286': 32.1481,
    '372_287': 32.1805,
    '372_288': 32.1806,
    '372_289': 32.1619,
    '372_290': 32.1347,
    '372_291': 32.1016,
    '372_292': 32.0592,
    '372_293': 32.0176,
    '372_294': 31.9804,
    '372_295': 31.9367,
    '372_296': 31.8925,
    '372_297': 31.8529,
    '372_298': 31.8207,
    '372_299': 31.7941,
    '372_302': 31.6583,
    '372_303': 31.6079,
    '372_304': 31.5553,
    '372_305': 31.5024,
    '372_306': 31.4519,
    '372_307': 31.4019,
    '372_308': 31.3533,
    '372_309': 31.3025,
    '372_310': 31.2442,
    '372_311': 31.1813,
    '372_312': 31.1187,
    '372_313': 31.0539,
    '372_314': 30.9833,
    '372_315': 30.9091,
    '372_316': 30.8354,
    '372_317': 30.7578,
    '372_318': 30.67,
    '372_319': 30.5699,
    '372_320': 30.457,
    '372_321': 30.3325,
    '372_322': 30.199,
    '373_286': 32.2576,
    '373_287': 32.2833,
    '373_288': 32.2798,
    '373_289': 32.2557,
    '373_290': 32.2238,
    '373_291': 32.1834,
    '373_292': 32.1392,
    '373_293': 32.0942,
    '373_294': 32.0537,
    '373_295': 32.0125,
    '373_296': 31.9711,
    '373_297': 31.9342,
    '373_298': 31.9047,
    '373_299': 31.8787,
    '373_302': 31.7619,
    '373_303': 31.7155,
    '373_304': 31.6677,
    '373_305': 31.6181,
    '373_306': 31.5692,
    '373_307': 31.5203,
    '373_308': 31.4733,
    '373_309': 31.4264,
    '373_310': 31.3638,
    '373_311': 31.2961,
    '373_312': 31.2306,
    '373_313': 31.1626,
    '373_314': 31.0895,
    '373_315': 31.0127,
    '373_316': 30.9328,
    '373_317': 30.8487,
    '373_318': 30.7576,
    '373_319': 30.6571,
    '373_320': 30.5445,
    '373_321': 30.4243,
    '373_322': 30.2844,
    '374_286': 32.357,
    '374_287': 32.3764,
    '374_288': 32.3704,
    '374_289': 32.3421,
    '374_290': 32.3035,
    '374_291': 32.2579,
    '374_292': 32.2115,
    '374_293': 32.1616,
    '374_294': 32.1153,
    '374_295': 32.0744,
    '374_296': 32.0373,
    '374_297': 32.0033,
    '374_298': 31.9763,
    '374_299': 31.9516,
    '374_302': 31.8549,
    '374_303': 31.8135,
    '374_304': 31.7718,
    '374_305': 31.7275,
    '374_306': 31.6812,
    '374_307': 31.6343,
    '374_308': 31.589,
    '374_309': 31.5435,
    '374_310': 31.4829,
    '374_311': 31.4136,
    '374_312': 31.3435,
    '374_313': 31.272,
    '374_314': 31.1957,
    '374_315': 31.1153,
    '374_316': 31.0306,
    '374_317': 30.9413,
    '374_318': 30.8488,
    '374_319': 30.752,
    '374_320': 30.6375,
    '374_321': 30.5158,
    '374_322': 30.3762,
    '375_266': 31.326,
    '375_267': 31.4529,
    '375_268': 31.5881,
    '375_269': 31.7255,
    '375_270': 31.8586,
    '375_271': 31.9768,
    '375_272': 32.073,
    '375_273': 32.1473,
    '375_274': 32.1859,
    '375_275': 32.2208,
    '375_276': 32.2481,
    '375_277': 32.2658,
    '375_278': 32.278,
    '375_279': 32.2862,
    '375_286': 32.4512,
    '375_287': 32.4659,
    '375_288': 32.455,
    '375_289': 32.4221,
    '375_290': 32.3748,
    '375_291': 32.3225,
    '375_292': 32.2685,
    '375_293': 32.214,
    '375_294': 32.1652,
    '375_295': 32.1238,
    '375_296': 32.0875,
    '375_297': 32.057,
    '375_298': 32.0327,
    '375_299': 32.0082,
    '375_302': 31.9336,
    '375_303': 31.8983,
    '375_304': 31.8648,
    '375_305': 31.8284,
    '375_306': 31.7847,
    '375_307': 31.7407,
    '375_308': 31.6974,
    '375_309': 31.6517,
    '375_310': 31.5968,
    '375_311': 31.5322,
    '375_312': 31.4552,
    '375_313': 31.3797,
    '375_314': 31.2998,
    '375_315': 31.2152,
    '375_316': 31.1263,
    '375_317': 31.0311,
    '375_318': 30.9347,
    '375_319': 30.8451,
    '375_320': 30.7214,
    '375_321': 30.5951,
    '375_322': 30.4547,
    '375_323': 30.3023,
    '375_324': 30.1496,
    '375_325': 30.0026,
    '375_326': 29.863,
    '375_327': 29.7299,
    '375_328': 29.6064,
    '376_266': 31.4239,
    '376_267': 31.5565,
    '376_268': 31.6911,
    '376_269': 31.8331,
    '376_270': 31.9745,
    '376_271': 32.0914,
    '376_272': 32.1836,
    '376_273': 32.2485,
    '376_274': 32.2867,
    '376_275': 32.3155,
    '376_276': 32.3494,
    '376_277': 32.3722,
    '376_278': 32.3909,
    '376_279': 32.402,
    '376_286': 32.5399,
    '376_287': 32.5455,
    '376_288': 32.5304,
    '376_289': 32.4907,
    '376_290': 32.4382,
    '376_291': 32.3775,
    '376_292': 32.315,
    '376_293': 32.2533,
    '376_294': 32.2033,
    '376_295': 32.1607,
    '376_296': 32.1227,
    '376_297': 32.093,
    '376_298': 32.0729,
    '376_299': 32.0477,
    '376_302': 32.0002,
    '376_303': 31.9719,
    '376_304': 31.9453,
    '376_305': 31.9146,
    '376_306': 31.8774,
    '376_307': 31.838,
    '376_308': 31.7963,
    '376_309': 31.7513,
    '376_310': 31.7019,
    '376_311': 31.6438,
    '376_312': 31.5671,
    '376_313': 31.486,
    '376_314': 31.4021,
    '376_315': 31.3135,
    '376_316': 31.2206,
    '376_317': 31.1228,
    '376_318': 31.0223,
    '376_319': 30.9208,
    '376_320': 30.7981,
    '376_321': 30.6659,
    '376_322': 30.5234,
    '376_323': 30.3717,
    '376_324': 30.2171,
    '376_325': 30.0643,
    '376_326': 29.921,
    '376_327': 29.7838,
    '376_328': 29.6552,
    '377_266': 31.5124,
    '377_267': 31.6519,
    '377_268': 31.7836,
    '377_269': 31.9305,
    '377_270': 32.0715,
    '377_271': 32.1883,
    '377_272': 32.2798,
    '377_273': 32.3368,
    '377_274': 32.3757,
    '377_275': 32.4034,
    '377_276': 32.4323,
    '377_277': 32.4583,
    '377_278': 32.4856,
    '377_279': 32.5013,
    '377_286': 32.6164,
    '377_287': 32.6153,
    '377_288': 32.5893,
    '377_289': 32.5451,
    '377_290': 32.4885,
    '377_291': 32.4233,
    '377_292': 32.3532,
    '377_293': 32.2815,
    '377_294': 32.2262,
    '377_295': 32.1844,
    '377_296': 32.1404,
    '377_297': 32.1044,
    '377_298': 32.0829,
    '377_299': 32.0607,
    '377_302': 32.0544,
    '377_303': 32.0346,
    '377_304': 32.0165,
    '377_305': 31.9936,
    '377_306': 31.9636,
    '377_307': 31.9286,
    '377_308': 31.8898,
    '377_309': 31.8462,
    '377_310': 31.7988,
    '377_311': 31.7462,
    '377_312': 31.6768,
    '377_313': 31.5904,
    '377_314': 31.5027,
    '377_315': 31.4098,
    '377_316': 31.313,
    '377_317': 31.2134,
    '377_318': 31.1093,
    '377_319': 30.9982,
    '377_320': 30.8727,
    '377_321': 30.7368,
    '377_322': 30.5916,
    '377_323': 30.4395,
    '377_324': 30.2847,
    '377_325': 30.1284,
    '377_326': 29.9826,
    '377_327': 29.8419,
    '377_328': 29.7102,
    '378_266': 31.6019,
    '378_267': 31.743,
    '378_268': 31.8811,
    '378_269': 32.0242,
    '378_270': 32.158,
    '378_271': 32.2793,
    '378_272': 32.3704,
    '378_273': 32.4136,
    '378_274': 32.4462,
    '378_275': 32.4703,
    '378_276': 32.4954,
    '378_277': 32.5223,
    '378_278': 32.5499,
    '378_279': 32.5706,
    '378_302': 32.0981,
    '378_303': 32.0879,
    '378_304': 32.0792,
    '378_305': 32.0659,
    '378_306': 32.0438,
    '378_307': 32.0147,
    '378_308': 31.9799,
    '378_309': 31.939,
    '378_310': 31.8931,
    '378_311': 31.8431,
    '378_312': 31.7795,
    '378_313': 31.6934,
    '378_314': 31.6026,
    '378_315': 31.5052,
    '378_316': 31.404,
    '378_317': 31.3035,
    '378_318': 31.1955,
    '378_319': 31.079,
    '378_320': 30.951,
    '378_321': 30.8109,
    '378_322': 30.6624,
    '378_323': 30.5069,
    '378_324': 30.351,
    '378_325': 30.1953,
    '378_326': 30.0462,
    '378_327': 29.9035,
    '378_328': 29.7691,
    '379_266': 31.6721,
    '379_267': 31.8181,
    '379_268': 31.9615,
    '379_269': 32.1028,
    '379_270': 32.2318,
    '379_271': 32.3496,
    '379_272': 32.4273,
    '379_273': 32.4619,
    '379_274': 32.4882,
    '379_275': 32.5079,
    '379_276': 32.5311,
    '379_277': 32.5597,
    '379_278': 32.5896,
    '379_279': 32.6156,
    '379_306': 32.1152,
    '379_307': 32.0923,
    '379_308': 32.0632,
    '379_309': 32.0284,
    '379_310': 31.9879,
    '379_311': 31.9425,
    '379_312': 31.8817,
    '379_313': 31.7962,
    '379_314': 31.7024,
    '379_315': 31.6021,
    '379_316': 31.4943,
    '379_317': 31.3942,
    '379_318': 31.284,
    '379_319': 31.1634,
    '379_320': 31.0324,
    '379_321': 30.8899,
    '379_322': 30.7398,
    '379_323': 30.583,
    '379_324': 30.4215,
    '379_325': 30.2631,
    '379_326': 30.1107,
    '379_327': 29.966,
    '379_328': 29.8307,
    '380_266': 31.7241,
    '380_267': 31.876,
    '380_268': 32.0198,
    '380_269': 32.1607,
    '380_270': 32.286,
    '380_271': 32.3909,
    '380_272': 32.4542,
    '380_273': 32.4832,
    '380_274': 32.498,
    '380_275': 32.5119,
    '380_276': 32.5338,
    '380_277': 32.5687,
    '380_278': 32.6042,
    '380_279': 32.6347,
    '380_283': 32.9517,
    '380_284': 32.9139,
    '380_285': 32.8755,
    '380_286': 32.8341,
    '380_287': 32.7815,
    '380_306': 32.1748,
    '380_307': 32.1599,
    '380_308': 32.1392,
    '380_309': 32.1131,
    '380_310': 32.0843,
    '380_311': 32.0395,
    '380_312': 31.9795,
    '380_313': 31.8966,
    '380_314': 31.8019,
    '380_315': 31.7022,
    '380_316': 31.596,
    '380_317': 31.4881,
    '380_318': 31.3754,
    '380_319': 31.2527,
    '380_320': 31.1186,
    '380_321': 30.9751,
    '380_322': 30.823,
    '380_323': 30.6628,
    '380_324': 30.496,
    '380_325': 30.3345,
    '380_326': 30.1777,
    '380_327': 30.0299,
    '380_328': 29.8943,
    '381_266': 31.762,
    '381_267': 31.9166,
    '381_268': 32.0623,
    '381_269': 32.2042,
    '381_270': 32.323,
    '381_271': 32.4133,
    '381_272': 32.4631,
    '381_273': 32.4779,
    '381_274': 32.4792,
    '381_275': 32.4853,
    '381_276': 32.5049,
    '381_277': 32.5431,
    '381_278': 32.5856,
    '381_279': 32.6179,
    '381_283': 32.9801,
    '381_284': 32.9407,
    '381_285': 32.8965,
    '381_286': 32.8525,
    '381_287': 32.7956,
    '381_306': 32.2133,
    '381_307': 32.2143,
    '381_308': 32.2063,
    '381_309': 32.1896,
    '381_310': 32.1648,
    '381_311': 32.1263,
    '381_312': 32.0696,
    '381_313': 31.993,
    '381_314': 31.9016,
    '381_315': 31.8003,
    '381_316': 31.6925,
    '381_317': 31.5831,
    '381_318': 31.4696,
    '381_319': 31.3469,
    '381_320': 31.2124,
    '381_321': 31.0663,
    '381_322': 30.9135,
    '381_323': 30.7526,
    '381_324': 30.5829,
    '381_325': 30.4138,
    '381_326': 30.2496,
    '381_327': 30.1,
    '381_328': 29.9611,
    '382_266': 31.7863,
    '382_267': 31.9352,
    '382_268': 32.0823,
    '382_269': 32.2221,
    '382_270': 32.3361,
    '382_271': 32.4157,
    '382_272': 32.4435,
    '382_273': 32.437,
    '382_274': 32.4265,
    '382_275': 32.425,
    '382_276': 32.4402,
    '382_277': 32.4755,
    '382_278': 32.5157,
    '382_279': 32.5546,
    '382_283': 32.9485,
    '382_284': 32.926,
    '382_285': 32.8921,
    '382_286': 32.8536,
    '382_287': 32.8005,
    '382_306': 32.2613,
    '382_307': 32.2708,
    '382_308': 32.2717,
    '382_309': 32.2639,
    '382_310': 32.2451,
    '382_311': 32.2089,
    '382_312': 32.1562,
    '382_313': 32.0866,
    '382_314': 31.9954,
    '382_315': 31.8938,
    '382_316': 31.7869,
    '382_317': 31.6784,
    '382_318': 31.5651,
    '382_319': 31.4459,
    '382_320': 31.3108,
    '382_321': 31.1628,
    '382_322': 31.0101,
    '382_323': 30.8505,
    '382_324': 30.679,
    '382_325': 30.507,
    '382_326': 30.3423,
    '382_327': 30.1884,
    '382_328': 30.0475,
    '383_266': 31.7918,
    '383_267': 31.9362,
    '383_268': 32.0762,
    '383_269': 32.2034,
    '383_270': 32.309,
    '383_271': 32.3783,
    '383_272': 32.3847,
    '383_273': 32.3698,
    '383_274': 32.3479,
    '383_275': 32.3389,
    '383_276': 32.3495,
    '383_277': 32.3793,
    '383_278': 32.4183,
    '383_279': 32.4591,
    '383_283': 32.8878,
    '383_284': 32.8856,
    '383_285': 32.8706,
    '383_286': 32.8434,
    '383_287': 32.8005,
    '383_306': 32.3164,
    '383_307': 32.3292,
    '383_308': 32.3366,
    '383_309': 32.3368,
    '383_310': 32.3222,
    '383_311': 32.2882,
    '383_312': 32.2395,
    '383_313': 32.1722,
    '383_314': 32.0825,
    '383_315': 31.9837,
    '383_316': 31.8795,
    '383_317': 31.772,
    '383_318': 31.6603,
    '383_319': 31.5419,
    '383_320': 31.4129,
    '383_321': 31.2613,
    '383_322': 31.108,
    '383_323': 30.948,
    '383_324': 30.7797,
    '383_325': 30.6096,
    '383_326': 30.4483,
    '383_327': 30.292,
    '383_328': 30.1512,
    '384_266': 31.7884,
    '384_267': 31.9295,
    '384_268': 32.0583,
    '384_269': 32.1658,
    '384_270': 32.2511,
    '384_271': 32.3018,
    '384_272': 32.3062,
    '384_273': 32.2834,
    '384_274': 32.2506,
    '384_275': 32.2338,
    '384_276': 32.2392,
    '384_277': 32.2669,
    '384_278': 32.3061,
    '384_279': 32.3447,
    '384_283': 32.8176,
    '384_284': 32.8362,
    '384_285': 32.8405,
    '384_286': 32.8277,
    '384_287': 32.7987,
    '384_306': 32.3646,
    '384_307': 32.3815,
    '384_308': 32.3966,
    '384_309': 32.4063,
    '384_310': 32.3925,
    '384_311': 32.3609,
    '384_312': 32.3132,
    '384_313': 32.2439,
    '384_314': 32.1604,
    '384_315': 32.0679,
    '384_316': 31.969,
    '384_317': 31.8646,
    '384_318': 31.7545,
    '384_319': 31.636,
    '384_320': 31.5065,
    '384_321': 31.3602,
    '384_322': 31.2088,
    '384_323': 31.0516,
    '384_324': 30.8891,
    '384_325': 30.7259,
    '384_326': 30.5683,
    '384_327': 30.4166,
    '384_328': 30.2758,
    '385_266': 31.7874,
    '385_267': 31.9172,
    '385_268': 32.0323,
    '385_269': 32.1287,
    '385_270': 32.1964,
    '385_271': 32.2352,
    '385_272': 32.2392,
    '385_273': 32.2082,
    '385_274': 32.1699,
    '385_275': 32.1412,
    '385_276': 32.1352,
    '385_277': 32.1533,
    '385_278': 32.1859,
    '385_279': 32.2247,
    '385_306': 32.4096,
    '385_307': 32.4277,
    '385_308': 32.4471,
    '385_309': 32.4609,
    '385_310': 32.4486,
    '385_311': 32.4218,
    '385_312': 32.3766,
    '385_313': 32.3085,
    '385_314': 32.2322,
    '385_315': 32.1485,
    '385_316': 32.0572,
    '385_317': 31.9571,
    '385_318': 31.8483,
    '385_319': 31.7313,
    '385_320': 31.6028,
    '385_321': 31.4616,
    '385_322': 31.3142,
    '385_323': 31.1611,
    '385_324': 31.0046,
    '385_325': 30.8481,
    '385_326': 30.6952,
    '385_327': 30.5473,
    '385_328': 30.4055,
    '386_266': 31.7809,
    '386_267': 31.8983,
    '386_268': 32.0028,
    '386_269': 32.0855,
    '386_270': 32.1432,
    '386_271': 32.1741,
    '386_272': 32.1751,
    '386_273': 32.1484,
    '386_274': 32.1113,
    '386_275': 32.0785,
    '386_276': 32.0618,
    '386_277': 32.0634,
    '386_278': 32.0807,
    '386_279': 32.1106,
    '386_306': 32.4539,
    '386_307': 32.4687,
    '386_308': 32.4883,
    '386_309': 32.5029,
    '386_310': 32.49,
    '386_311': 32.4661,
    '386_312': 32.4265,
    '386_313': 32.3684,
    '386_314': 32.3029,
    '386_315': 32.2284,
    '386_316': 32.1456,
    '386_317': 32.051,
    '386_318': 31.9448,
    '386_319': 31.8275,
    '386_320': 31.7016,
    '386_321': 31.5661,
    '386_322': 31.423,
    '386_323': 31.2743,
    '386_324': 31.1228,
    '386_325': 30.9721,
    '386_326': 30.824,
    '386_327': 30.6789,
    '386_328': 30.5357,
    '387_306': 32.4985,
    '387_307': 32.5061,
    '387_308': 32.5198,
    '387_309': 32.5284,
    '387_310': 32.5203,
    '387_311': 32.5004,
    '387_312': 32.4689,
    '387_313': 32.4264,
    '387_314': 32.3745,
    '387_315': 32.3102,
    '387_316': 32.2354,
    '387_317': 32.1457,
    '387_318': 32.0416,
    '387_319': 31.9277,
    '387_320': 31.8056,
    '387_321': 31.6742,
    '387_322': 31.5355,
    '387_323': 31.3914,
    '387_324': 31.2441,
    '387_325': 31.0988,
    '387_326': 30.9552,
    '387_327': 30.812,
    '387_328': 30.6674,
    '388_306': 32.556,
    '388_307': 32.5491,
    '388_308': 32.5531,
    '388_309': 32.5557,
    '388_310': 32.5495,
    '388_311': 32.5343,
    '388_312': 32.51,
    '388_313': 32.4793,
    '388_314': 32.4428,
    '388_315': 32.392,
    '388_316': 32.3198,
    '388_317': 32.2343,
    '388_318': 32.1374,
    '388_319': 32.0313,
    '388_320': 31.9136,
    '388_321': 31.785,
    '388_322': 31.6509,
    '388_323': 31.5122,
    '388_324': 31.3708,
    '388_325': 31.2294,
    '388_326': 31.0878,
    '388_327': 30.9445,
    '388_328': 30.7983,
    '389_306': 32.6165,
    '389_307': 32.5986,
    '389_308': 32.5922,
    '389_309': 32.5885,
    '389_310': 32.5816,
    '389_311': 32.569,
    '389_312': 32.5502,
    '389_313': 32.525,
    '389_314': 32.4951,
    '389_315': 32.4551,
    '389_316': 32.3922,
    '389_317': 32.3174,
    '389_318': 32.2309,
    '389_319': 32.1329,
    '389_320': 32.0207,
    '389_321': 31.8963,
    '389_322': 31.7669,
    '389_323': 31.6332,
    '389_324': 31.4969,
    '389_325': 31.3589,
    '389_326': 31.2186,
    '389_327': 31.0734,
    '389_328': 30.9226,
    '390_306': 32.6809,
    '390_307': 32.6558,
    '390_308': 32.6377,
    '390_309': 32.6267,
    '390_310': 32.6176,
    '390_311': 32.6057,
    '390_312': 32.5897,
    '390_313': 32.5678,
    '390_314': 32.5398,
    '390_315': 32.4985,
    '390_316': 32.4502,
    '390_317': 32.3908,
    '390_318': 32.318,
    '390_319': 32.2292,
    '390_320': 32.1241,
    '390_321': 32.0083,
    '390_322': 31.8816,
    '390_323': 31.7522,
    '390_324': 31.6202,
    '390_325': 31.4854,
    '390_326': 31.3468,
    '390_327': 31.2001,
    '390_328': 31.0442,
    '390_329': 30.8833,
    '390_330': 30.7155,
    '390_331': 30.5405,
    '390_332': 30.359,
    '390_333': 30.1749,
    '390_334': 29.9897,
    '390_335': 29.8192,
    '390_336': 29.6517,
    '391_306': 32.7453,
    '391_307': 32.714,
    '391_308': 32.6864,
    '391_309': 32.6684,
    '391_310': 32.6562,
    '391_311': 32.6432,
    '391_312': 32.6262,
    '391_313': 32.6043,
    '391_314': 32.5747,
    '391_315': 32.5356,
    '391_316': 32.4961,
    '391_317': 32.4496,
    '391_318': 32.3918,
    '391_319': 32.318,
    '391_320': 32.2228,
    '391_321': 32.1156,
    '391_322': 31.9933,
    '391_323': 31.8662,
    '391_324': 31.7379,
    '391_325': 31.6049,
    '391_326': 31.4675,
    '391_327': 31.3222,
    '391_328': 31.163,
    '391_329': 31.001,
    '391_330': 30.8304,
    '391_331': 30.6502,
    '391_332': 30.4641,
    '391_333': 30.2782,
    '391_334': 30.0924,
    '391_335': 29.9197,
    '391_336': 29.7437,
    '392_306': 32.8122,
    '392_307': 32.7779,
    '392_308': 32.7463,
    '392_309': 32.7209,
    '392_310': 32.7036,
    '392_311': 32.6871,
    '392_312': 32.6679,
    '392_313': 32.6431,
    '392_314': 32.6118,
    '392_315': 32.5784,
    '392_316': 32.5425,
    '392_317': 32.5026,
    '392_318': 32.4559,
    '392_319': 32.3981,
    '392_320': 32.3199,
    '392_321': 32.2175,
    '392_322': 32.1001,
    '392_323': 31.9804,
    '392_324': 31.8565,
    '392_325': 31.7222,
    '392_326': 31.5843,
    '392_327': 31.4395,
    '392_328': 31.2861,
    '392_329': 31.127,
    '392_330': 30.9567,
    '392_331': 30.7747,
    '392_332': 30.5848,
    '392_333': 30.3899,
    '392_334': 30.1945,
    '392_335': 30.0152,
    '392_336': 29.8363,
    '393_287': 32.5063,
    '393_288': 32.6587,
    '393_289': 32.7784,
    '393_290': 32.8702,
    '393_291': 32.9297,
    '393_306': 32.8764,
    '393_307': 32.8422,
    '393_308': 32.8105,
    '393_309': 32.7829,
    '393_310': 32.7591,
    '393_311': 32.7376,
    '393_312': 32.7155,
    '393_313': 32.6884,
    '393_314': 32.6549,
    '393_315': 32.6234,
    '393_316': 32.5923,
    '393_317': 32.559,
    '393_318': 32.5189,
    '393_319': 32.4712,
    '393_320': 32.408,
    '393_321': 32.3143,
    '393_322': 32.2043,
    '393_323': 32.0919,
    '393_324': 31.973,
    '393_325': 31.841,
    '393_326': 31.7002,
    '393_327': 31.5532,
    '393_328': 31.405,
    '393_329': 31.251,
    '393_330': 31.0819,
    '393_331': 30.8991,
    '393_332': 30.7068,
    '393_333': 30.505,
    '393_334': 30.2997,
    '393_335': 30.1118,
    '393_336': 29.9289,
    '394_272': 32.0165,
    '394_273': 32.0532,
    '394_274': 32.0662,
    '394_275': 32.0509,
    '394_287': 32.4848,
    '394_288': 32.6314,
    '394_289': 32.7544,
    '394_290': 32.8512,
    '394_291': 32.9228,
    '394_310': 32.8208,
    '394_311': 32.7944,
    '394_312': 32.7702,
    '394_313': 32.7413,
    '394_314': 32.7105,
    '394_315': 32.6815,
    '394_316': 32.6503,
    '394_317': 32.6231,
    '394_318': 32.5856,
    '394_319': 32.5386,
    '394_320': 32.4849,
    '394_321': 32.405,
    '394_322': 32.307,
    '394_323': 32.2014,
    '394_324': 32.083,
    '394_325': 31.9512,
    '394_326': 31.8073,
    '394_327': 31.6598,
    '394_328': 31.5126,
    '394_329': 31.3578,
    '394_330': 31.1893,
    '394_331': 31.0071,
    '394_332': 30.8142,
    '394_333': 30.6091,
    '394_334': 30.3997,
    '394_335': 30.1987,
    '394_336': 30.0157,
    '395_272': 32.0009,
    '395_273': 32.0559,
    '395_274': 32.0944,
    '395_275': 32.0936,
    '395_287': 32.4718,
    '395_288': 32.6103,
    '395_289': 32.7345,
    '395_290': 32.832,
    '395_291': 32.9075,
    '395_310': 32.8869,
    '395_311': 32.8606,
    '395_312': 32.8338,
    '395_313': 32.7998,
    '395_314': 32.7777,
    '395_315': 32.7567,
    '395_316': 32.731,
    '395_317': 32.7021,
    '395_318': 32.6614,
    '395_319': 32.6106,
    '395_320': 32.5583,
    '395_321': 32.4864,
    '395_322': 32.4029,
    '395_323': 32.3016,
    '395_324': 32.1816,
    '395_325': 32.0501,
    '395_326': 31.9067,
    '395_327': 31.761,
    '395_328': 31.6148,
    '395_329': 31.4597,
    '395_330': 31.2911,
    '395_331': 31.1092,
    '395_332': 30.9155,
    '395_333': 30.7114,
    '395_334': 30.5028,
    '395_335': 30.299,
    '395_336': 30.1111,
    '396_272': 31.9971,
    '396_273': 32.0599,
    '396_274': 32.1102,
    '396_275': 32.1159,
    '396_287': 32.4691,
    '396_288': 32.5994,
    '396_289': 32.7198,
    '396_290': 32.8192,
    '396_291': 32.8973,
    '396_310': 32.9515,
    '396_311': 32.9305,
    '396_312': 32.9068,
    '396_313': 32.887,
    '396_314': 32.867,
    '396_315': 32.8471,
    '396_316': 32.8246,
    '396_317': 32.7917,
    '396_318': 32.7449,
    '396_319': 32.6853,
    '396_320': 32.6232,
    '396_321': 32.555,
    '396_322': 32.4798,
    '396_323': 32.3818,
    '396_324': 32.2696,
    '396_325': 32.1394,
    '396_326': 32.0018,
    '396_327': 31.8595,
    '396_328': 31.7156,
    '396_329': 31.5612,
    '396_330': 31.3919,
    '396_331': 31.21,
    '396_332': 31.0165,
    '396_333': 30.8147,
    '396_334': 30.6092,
    '396_335': 30.4063,
    '396_336': 30.214,
    '396_884': 45.2258,
    '396_885': 45.5435,
    '396_886': 45.7937,
    '396_887': 45.9629,
    '396_888': 46.046,
    '397_272': 31.9931,
    '397_273': 32.0587,
    '397_274': 32.1193,
    '397_275': 32.1287,
    '397_287': 32.4778,
    '397_288': 32.5997,
    '397_289': 32.7162,
    '397_290': 32.8162,
    '397_291': 32.8962,
    '397_310': 33.02,
    '397_311': 33.002,
    '397_312': 32.9848,
    '397_313': 32.9703,
    '397_314': 32.9514,
    '397_315': 32.9348,
    '397_316': 32.9216,
    '397_317': 32.8903,
    '397_318': 32.8326,
    '397_319': 32.7619,
    '397_320': 32.683,
    '397_321': 32.6104,
    '397_322': 32.5365,
    '397_323': 32.4503,
    '397_324': 32.3486,
    '397_325': 32.2268,
    '397_326': 32.0953,
    '397_327': 31.9603,
    '397_328': 31.8204,
    '397_329': 31.6666,
    '397_330': 31.4934,
    '397_331': 31.3099,
    '397_332': 31.1172,
    '397_333': 30.9183,
    '397_334': 30.7161,
    '397_335': 30.5154,
    '397_336': 30.3186,
    '397_884': 45.4226,
    '397_885': 45.7605,
    '397_886': 46.0313,
    '397_887': 46.2023,
    '397_888': 46.2527,
    '398_272': 31.9824,
    '398_273': 32.0412,
    '398_274': 32.0942,
    '398_275': 32.1137,
    '398_310': 33.0904,
    '398_311': 33.0727,
    '398_312': 33.0566,
    '398_313': 33.0408,
    '398_314': 33.025,
    '398_315': 33.0102,
    '398_316': 33.0002,
    '398_317': 32.9757,
    '398_318': 32.9115,
    '398_319': 32.8347,
    '398_320': 32.7459,
    '398_321': 32.6619,
    '398_322': 32.5858,
    '398_323': 32.5066,
    '398_324': 32.4162,
    '398_325': 32.3089,
    '398_326': 32.1852,
    '398_327': 32.0605,
    '398_328': 31.9261,
    '398_329': 31.7748,
    '398_330': 31.6006,
    '398_331': 31.41,
    '398_332': 31.2166,
    '398_333': 31.019,
    '398_334': 30.8168,
    '398_335': 30.6116,
    '398_336': 30.4036,
    '398_884': 45.6087,
    '398_885': 45.969,
    '398_886': 46.2649,
    '398_887': 46.436,
    '398_888': 46.4378,
    '399_310': 33.1594,
    '399_311': 33.1411,
    '399_312': 33.1214,
    '399_313': 33.102,
    '399_314': 33.0868,
    '399_315': 33.0699,
    '399_316': 33.0555,
    '399_317': 33.0286,
    '399_318': 32.9693,
    '399_319': 32.891,
    '399_320': 32.802,
    '399_321': 32.7176,
    '399_322': 32.6375,
    '399_323': 32.5577,
    '399_324': 32.4742,
    '399_325': 32.3803,
    '399_326': 32.2737,
    '399_327': 32.1595,
    '399_328': 32.0269,
    '399_329': 31.8779,
    '399_330': 31.7051,
    '399_331': 31.5122,
    '399_332': 31.3138,
    '399_333': 31.1169,
    '399_334': 30.9146,
    '399_335': 30.7085,
    '399_336': 30.4967,
    '399_884': 45.7823,
    '399_885': 46.1497,
    '399_886': 46.4512,
    '399_887': 46.5716,
    '399_888': 46.5674,
    '400_310': 33.2219,
    '400_311': 33.2022,
    '400_312': 33.1814,
    '400_313': 33.1603,
    '400_314': 33.1397,
    '400_315': 33.1191,
    '400_316': 33.1013,
    '400_317': 33.0732,
    '400_318': 33.0173,
    '400_319': 32.9378,
    '400_320': 32.8516,
    '400_321': 32.7683,
    '400_322': 32.6871,
    '400_323': 32.6067,
    '400_324': 32.5249,
    '400_325': 32.448,
    '400_326': 32.3572,
    '400_327': 32.249,
    '400_328': 32.1234,
    '400_329': 31.9751,
    '400_330': 31.8051,
    '400_331': 31.6099,
    '400_332': 31.4057,
    '400_333': 31.2077,
    '400_334': 31.0069,
    '400_335': 30.8015,
    '400_336': 30.5893,
    '400_884': 45.9482,
    '400_885': 46.2917,
    '400_886': 46.5604,
    '400_887': 46.6738,
    '400_888': 46.695,
    '401_310': 33.2748,
    '401_311': 33.2541,
    '401_312': 33.2335,
    '401_313': 33.2098,
    '401_314': 33.1843,
    '401_315': 33.1602,
    '401_316': 33.1379,
    '401_317': 33.1034,
    '401_318': 33.0441,
    '401_319': 32.9698,
    '401_320': 32.8912,
    '401_321': 32.8118,
    '401_322': 32.7301,
    '401_323': 32.6535,
    '401_324': 32.5765,
    '401_325': 32.5075,
    '401_326': 32.4246,
    '401_327': 32.323,
    '401_328': 32.2083,
    '401_329': 32.0628,
    '401_330': 31.8951,
    '401_331': 31.7024,
    '401_332': 31.4929,
    '401_333': 31.2927,
    '401_334': 31.0917,
    '401_335': 30.8867,
    '401_336': 30.6738,
    '401_884': 46.1111,
    '401_885': 46.4312,
    '401_886': 46.6716,
    '401_887': 46.786,
    '401_888': 46.8268,
    '402_309': 33.3273,
    '402_310': 33.3137,
    '402_311': 33.2999,
    '402_312': 33.2836,
    '402_313': 33.2615,
    '402_314': 33.2331,
    '402_315': 33.1893,
    '402_316': 33.1614,
    '402_317': 33.1181,
    '402_318': 33.0596,
    '402_319': 32.9931,
    '402_320': 32.9233,
    '402_321': 32.8518,
    '402_322': 32.7714,
    '402_323': 32.6971,
    '402_324': 32.6213,
    '402_325': 32.5491,
    '402_326': 32.4704,
    '402_327': 32.379,
    '402_328': 32.276,
    '402_329': 32.1424,
    '402_330': 31.9749,
    '402_331': 31.7866,
    '402_332': 31.5801,
    '402_333': 31.3715,
    '402_334': 31.167,
    '402_335': 30.958,
    '402_336': 30.7364,
    '402_884': 46.2665,
    '402_885': 46.5682,
    '402_886': 46.7947,
    '402_887': 46.9064,
    '402_888': 46.957,
    '403_309': 33.345,
    '403_310': 33.331,
    '403_311': 33.3199,
    '403_312': 33.3038,
    '403_313': 33.2769,
    '403_314': 33.2409,
    '403_315': 33.2047,
    '403_316': 33.1666,
    '403_317': 33.1224,
    '403_318': 33.07,
    '403_319': 33.0126,
    '403_320': 32.9504,
    '403_321': 32.884,
    '403_322': 32.8091,
    '403_323': 32.7353,
    '403_324': 32.6618,
    '403_325': 32.5852,
    '403_326': 32.5051,
    '403_327': 32.421,
    '403_328': 32.3304,
    '403_329': 32.2085,
    '403_330': 32.0426,
    '403_331': 31.8597,
    '403_332': 31.6573,
    '403_333': 31.4427,
    '403_334': 31.2322,
    '403_335': 31.017,
    '403_336': 30.7896,
    '403_884': 46.411,
    '403_885': 46.688,
    '403_886': 46.8931,
    '403_887': 47.0146,
    '403_888': 47.0775,
    '404_309': 33.3506,
    '404_310': 33.3341,
    '404_311': 33.3198,
    '404_312': 33.3004,
    '404_313': 33.2712,
    '404_314': 33.2348,
    '404_315': 33.211,
    '404_316': 33.1705,
    '404_317': 33.1259,
    '404_318': 33.0791,
    '404_319': 33.0323,
    '404_320': 32.9767,
    '404_321': 32.9157,
    '404_322': 32.8465,
    '404_323': 32.7752,
    '404_324': 32.6991,
    '404_325': 32.6223,
    '404_326': 32.5392,
    '404_327': 32.4564,
    '404_328': 32.3739,
    '404_329': 32.2613,
    '404_330': 32.1053,
    '404_331': 31.9282,
    '404_332': 31.7304,
    '404_333': 31.5118,
    '404_334': 31.2911,
    '404_335': 31.0716,
    '404_336': 30.8413,
    '405_309': 33.3451,
    '405_310': 33.3264,
    '405_311': 33.3082,
    '405_312': 33.2866,
    '405_313': 33.258,
    '405_314': 33.2236,
    '405_315': 33.2148,
    '405_316': 33.1764,
    '405_317': 33.1323,
    '405_318': 33.091,
    '405_319': 33.0528,
    '405_320': 33.0031,
    '405_321': 32.9483,
    '405_322': 32.8848,
    '405_323': 32.8154,
    '405_324': 32.7362,
    '405_325': 32.657,
    '405_326': 32.5726,
    '405_327': 32.4861,
    '405_328': 32.4023,
    '405_329': 32.2962,
    '405_330': 32.1594,
    '405_331': 31.9884,
    '405_332': 31.7927,
    '405_333': 31.5684,
    '405_334': 31.3401,
    '405_335': 31.1153,
    '405_336': 30.883,
    '406_309': 33.335,
    '406_310': 33.3155,
    '406_311': 33.2963,
    '406_312': 33.2744,
    '406_313': 33.2468,
    '406_314': 33.2162,
    '406_324': 32.7713,
    '406_325': 32.6869,
    '406_326': 32.6043,
    '406_327': 32.515,
    '406_328': 32.4228,
    '406_329': 32.3232,
    '406_330': 32.1996,
    '406_331': 32.0388,
    '406_332': 31.843,
    '406_333': 31.616,
    '406_334': 31.3825,
    '406_335': 31.1533,
    '406_336': 30.9178,
    '407_324': 32.805,
    '407_325': 32.7152,
    '407_326': 32.6312,
    '407_327': 32.5419,
    '407_328': 32.4421,
    '407_329': 32.3387,
    '407_330': 32.2254,
    '407_331': 32.0753,
    '407_332': 31.8829,
    '407_333': 31.6546,
    '407_334': 31.4181,
    '407_335': 31.1852,
    '407_336': 30.9469,
    '408_324': 32.8296,
    '408_325': 32.7362,
    '408_326': 32.6569,
    '408_327': 32.5629,
    '408_328': 32.4605,
    '408_329': 32.3524,
    '408_330': 32.2454,
    '408_331': 32.1024,
    '408_332': 31.9102,
    '408_333': 31.6848,
    '408_334': 31.4476,
    '408_335': 31.2142,
    '408_336': 30.9767,
    '409_324': 32.8534,
    '409_325': 32.7607,
    '409_326': 32.6726,
    '409_327': 32.5762,
    '409_328': 32.4728,
    '409_329': 32.3614,
    '409_330': 32.2527,
    '409_331': 32.1116,
    '409_332': 31.9233,
    '409_333': 31.7008,
    '409_334': 31.4685,
    '409_335': 31.2368,
    '409_336': 31.0048,
    '410_324': 32.8717,
    '410_325': 32.7776,
    '410_326': 32.6833,
    '410_327': 32.5835,
    '410_328': 32.4773,
    '410_329': 32.3628,
    '410_330': 32.2468,
    '410_331': 32.1086,
    '410_332': 31.9204,
    '410_333': 31.709,
    '410_334': 31.488,
    '410_335': 31.2642,
    '410_336': 31.0341,
    '411_324': 32.8838,
    '411_325': 32.7862,
    '411_326': 32.6863,
    '411_327': 32.5813,
    '411_328': 32.4697,
    '411_329': 32.3548,
    '411_330': 32.2308,
    '411_331': 32.0891,
    '411_332': 31.9085,
    '411_333': 31.7103,
    '411_334': 31.5056,
    '411_335': 31.2905,
    '411_336': 31.0667,
    '412_324': 32.8884,
    '412_325': 32.7873,
    '412_326': 32.6828,
    '412_327': 32.573,
    '412_328': 32.4572,
    '412_329': 32.3391,
    '412_330': 32.2095,
    '412_331': 32.0635,
    '412_332': 31.8956,
    '412_333': 31.7134,
    '412_334': 31.5249,
    '412_335': 31.3216,
    '412_336': 31.1078,
    '413_316': 33.5154,
    '413_317': 33.4508,
    '413_318': 33.3788,
    '413_319': 33.2992,
    '413_320': 33.2098,
    '413_321': 33.1095,
    '413_322': 32.9944,
    '413_324': 32.8881,
    '413_325': 32.7829,
    '413_326': 32.6744,
    '413_327': 32.5594,
    '413_328': 32.4378,
    '413_329': 32.3139,
    '413_330': 32.1803,
    '413_331': 32.0349,
    '413_332': 31.876,
    '413_333': 31.7123,
    '413_334': 31.5408,
    '413_335': 31.3551,
    '413_336': 31.1557,
    '414_316': 33.5721,
    '414_317': 33.5034,
    '414_318': 33.4264,
    '414_319': 33.3387,
    '414_320': 33.2427,
    '414_321': 33.1356,
    '414_322': 33.0171,
    '414_324': 32.8843,
    '414_325': 32.7758,
    '414_326': 32.6626,
    '414_327': 32.5412,
    '414_328': 32.4124,
    '414_329': 32.2797,
    '414_330': 32.1423,
    '414_331': 31.9999,
    '414_332': 31.8535,
    '414_333': 31.7051,
    '414_334': 31.5523,
    '414_335': 31.3855,
    '414_336': 31.2001,
    '415_316': 33.6224,
    '415_317': 33.5511,
    '415_318': 33.4709,
    '415_319': 33.3773,
    '415_320': 33.2767,
    '415_321': 33.1655,
    '415_322': 33.044,
    '415_324': 32.8809,
    '415_325': 32.7661,
    '415_326': 32.6448,
    '415_327': 32.516,
    '415_328': 32.3812,
    '415_329': 32.2431,
    '415_330': 32.1032,
    '415_331': 31.9625,
    '415_332': 31.8234,
    '415_333': 31.6867,
    '415_334': 31.5493,
    '415_335': 31.4019,
    '415_336': 31.2289,
    '416_316': 33.6629,
    '416_317': 33.5915,
    '416_318': 33.5096,
    '416_319': 33.4141,
    '416_320': 33.3113,
    '416_321': 33.1975,
    '416_322': 33.0726,
    '416_324': 32.8788,
    '416_325': 32.7562,
    '416_326': 32.6259,
    '416_327': 32.4887,
    '416_328': 32.3477,
    '416_329': 32.2046,
    '416_330': 32.0623,
    '416_331': 31.9216,
    '416_332': 31.7869,
    '416_333': 31.6587,
    '416_334': 31.5338,
    '416_335': 31.401,
    '416_336': 31.2503,
    '417_316': 33.6945,
    '417_317': 33.6237,
    '417_318': 33.5428,
    '417_319': 33.4491,
    '417_320': 33.3467,
    '417_321': 33.2313,
    '417_322': 33.1022,
    '418_316': 33.7173,
    '418_317': 33.6487,
    '418_318': 33.5709,
    '418_319': 33.4811,
    '418_320': 33.3819,
    '418_321': 33.2672,
    '418_322': 33.1318,
    '419_316': 33.7305,
    '419_317': 33.6667,
    '419_318': 33.5936,
    '419_319': 33.5093,
    '419_320': 33.4154,
    '419_321': 33.3055,
    '419_322': 33.1642,
    '419_334': 31.5124,
    '419_335': 31.4313,
    '419_336': 31.3429,
    '419_337': 31.2347,
    '419_338': 31.0975,
    '419_339': 30.9365,
    '419_340': 30.7589,
    '419_341': 30.5709,
    '420_316': 33.7347,
    '420_317': 33.6774,
    '420_318': 33.6105,
    '420_319': 33.531,
    '420_320': 33.4402,
    '420_321': 33.3319,
    '420_322': 33.1986,
    '420_334': 31.5016,
    '420_335': 31.4354,
    '420_336': 31.363,
    '420_337': 31.2657,
    '420_338': 31.1352,
    '420_339': 30.9745,
    '420_340': 30.7946,
    '420_341': 30.604,
    '420_885': 48.9143,
    '420_886': 49.1408,
    '420_887': 49.3094,
    '420_888': 49.4107,
    '420_889': 49.4265,
    '420_890': 49.3698,
    '420_891': 49.2728,
    '421_316': 33.7312,
    '421_317': 33.6814,
    '421_318': 33.6237,
    '421_319': 33.5491,
    '421_320': 33.4621,
    '421_321': 33.3589,
    '421_322': 33.2337,
    '421_334': 31.4907,
    '421_335': 31.4442,
    '421_336': 31.394,
    '421_337': 31.3086,
    '421_338': 31.1825,
    '421_339': 31.0138,
    '421_340': 30.8288,
    '421_341': 30.6341,
    '421_885': 49.0579,
    '421_886': 49.2963,
    '421_887': 49.4724,
    '421_888': 49.5798,
    '421_889': 49.6101,
    '421_890': 49.5725,
    '421_891': 49.4859,
    '422_316': 33.7216,
    '422_317': 33.6791,
    '422_318': 33.6291,
    '422_319': 33.5629,
    '422_320': 33.4832,
    '422_321': 33.3853,
    '422_322': 33.2662,
    '422_334': 31.492,
    '422_335': 31.4588,
    '422_336': 31.4227,
    '422_337': 31.3475,
    '422_338': 31.2234,
    '422_339': 31.0467,
    '422_340': 30.8565,
    '422_341': 30.6596,
    '422_885': 49.1895,
    '422_886': 49.4385,
    '422_887': 49.6262,
    '422_888': 49.7414,
    '422_889': 49.7811,
    '422_890': 49.758,
    '422_891': 49.6847,
    '423_316': 33.7072,
    '423_317': 33.6724,
    '423_318': 33.6285,
    '423_319': 33.5733,
    '423_320': 33.501,
    '423_321': 33.4097,
    '423_322': 33.2971,
    '423_334': 31.4804,
    '423_335': 31.449,
    '423_336': 31.4149,
    '423_337': 31.352,
    '423_338': 31.2305,
    '423_339': 31.058,
    '423_340': 30.8712,
    '423_341': 30.6773,
    '423_885': 49.2988,
    '423_886': 49.5648,
    '423_887': 49.772,
    '423_888': 49.9011,
    '423_889': 49.9453,
    '423_890': 49.928,
    '423_891': 49.8657,
    '424_316': 33.6921,
    '424_317': 33.6636,
    '424_318': 33.6252,
    '424_319': 33.5764,
    '424_320': 33.5118,
    '424_321': 33.4239,
    '424_322': 33.3181,
    '424_334': 31.4578,
    '424_335': 31.4211,
    '424_336': 31.3847,
    '424_337': 31.3276,
    '424_338': 31.2106,
    '424_339': 31.0492,
    '424_340': 30.8731,
    '424_341': 30.6878,
    '424_885': 49.4,
    '424_886': 49.6746,
    '424_887': 49.8918,
    '424_888': 50.0356,
    '424_889': 50.0929,
    '424_890': 50.0792,
    '424_891': 50.025,
    '425_316': 33.6768,
    '425_317': 33.6534,
    '425_318': 33.621,
    '425_319': 33.5763,
    '425_320': 33.5179,
    '425_321': 33.4371,
    '425_322': 33.3375,
    '425_334': 31.434,
    '425_335': 31.3798,
    '425_336': 31.3324,
    '425_337': 31.2662,
    '425_338': 31.162,
    '425_339': 31.023,
    '425_340': 30.8663,
    '425_341': 30.6939,
    '425_885': 49.4866,
    '425_886': 49.769,
    '425_887': 49.9933,
    '425_888': 50.1458,
    '425_889': 50.2124,
    '425_890': 50.2096,
    '425_891': 50.1627,
    '426_316': 33.6617,
    '426_317': 33.6444,
    '426_318': 33.6154,
    '426_319': 33.5753,
    '426_320': 33.521,
    '426_321': 33.4481,
    '426_322': 33.3546,
    '426_334': 31.4144,
    '426_335': 31.3407,
    '426_336': 31.2771,
    '426_337': 31.2041,
    '426_338': 31.1099,
    '426_339': 30.9951,
    '426_340': 30.8614,
    '426_341': 30.7038,
    '426_885': 49.562,
    '426_886': 49.8534,
    '426_887': 50.0863,
    '426_888': 50.2425,
    '426_889': 50.3125,
    '426_890': 50.3206,
    '426_891': 50.2778,
    '427_885': 49.6295,
    '427_886': 49.9282,
    '427_887': 50.1643,
    '427_888': 50.3266,
    '427_889': 50.4024,
    '427_890': 50.4162,
    '427_891': 50.3763,
    '428_885': 49.695,
    '428_886': 50.0001,
    '428_887': 50.2407,
    '428_888': 50.4023,
    '428_889': 50.4821,
    '428_890': 50.5012,
    '428_891': 50.4629,
    '429_885': 49.7603,
    '429_886': 50.0716,
    '429_887': 50.3217,
    '429_888': 50.4785,
    '429_889': 50.5592,
    '429_890': 50.5807,
    '429_891': 50.5428,
    '430_885': 49.8151,
    '430_886': 50.1331,
    '430_887': 50.39,
    '430_888': 50.5486,
    '430_889': 50.6329,
    '430_890': 50.6543,
    '430_891': 50.6167,
    '431_885': 49.8683,
    '431_886': 50.1853,
    '431_887': 50.442,
    '431_888': 50.6051,
    '431_889': 50.6964,
    '431_890': 50.7212,
    '431_891': 50.683,
    '432_885': 49.9186,
    '432_886': 50.229,
    '432_887': 50.4796,
    '432_888': 50.6511,
    '432_889': 50.7518,
    '432_890': 50.7817,
    '432_891': 50.7421,
    '433_885': 49.9655,
    '433_886': 50.2747,
    '433_887': 50.524,
    '433_888': 50.6997,
    '433_889': 50.8043,
    '433_890': 50.8362,
    '433_891': 50.7988,
    '438_339': 31.2083,
    '438_340': 31.2039,
    '438_341': 31.2094,
    '438_342': 31.1607,
    '438_343': 31.0548,
    '438_344': 30.9331,
    '438_345': 30.8154,
    '438_346': 30.6705,
    '438_347': 30.5081,
    '438_348': 30.3405,
    '438_349': 30.1696,
    '438_350': 29.9941,
    '439_339': 31.2556,
    '439_340': 31.2662,
    '439_341': 31.2793,
    '439_342': 31.2339,
    '439_343': 31.1443,
    '439_344': 31.0286,
    '439_345': 30.9053,
    '439_346': 30.7514,
    '439_347': 30.5835,
    '439_348': 30.4174,
    '439_349': 30.2525,
    '439_350': 30.0803,
    '440_339': 31.2974,
    '440_340': 31.3235,
    '440_341': 31.3432,
    '440_342': 31.3174,
    '440_343': 31.2462,
    '440_344': 31.1253,
    '440_345': 30.9851,
    '440_346': 30.8227,
    '440_347': 30.6508,
    '440_348': 30.4842,
    '440_349': 30.3256,
    '440_350': 30.1566,
    '441_339': 31.3602,
    '441_340': 31.3874,
    '441_341': 31.4106,
    '441_342': 31.3922,
    '441_343': 31.3226,
    '441_344': 31.2006,
    '441_345': 31.0521,
    '441_346': 30.8805,
    '441_347': 30.7014,
    '441_348': 30.5318,
    '441_349': 30.3872,
    '441_350': 30.2253,
    '442_339': 31.4237,
    '442_340': 31.4423,
    '442_341': 31.4574,
    '442_342': 31.4355,
    '442_343': 31.3643,
    '442_344': 31.245,
    '442_345': 31.1002,
    '442_346': 30.9251,
    '442_347': 30.7405,
    '442_348': 30.56,
    '442_349': 30.4263,
    '442_350': 30.2829,
    '443_339': 31.4688,
    '443_340': 31.4747,
    '443_341': 31.4764,
    '443_342': 31.4435,
    '443_343': 31.3691,
    '443_344': 31.2587,
    '443_345': 31.1258,
    '443_346': 30.9637,
    '443_347': 30.7945,
    '443_348': 30.6292,
    '443_349': 30.4847,
    '443_350': 30.3386,
    '444_339': 31.4925,
    '444_340': 31.4802,
    '444_341': 31.4645,
    '444_342': 31.4166,
    '444_343': 31.337,
    '444_344': 31.2343,
    '444_345': 31.1169,
    '444_346': 30.9839,
    '444_347': 30.8415,
    '444_348': 30.6908,
    '444_349': 30.5421,
    '444_350': 30.3918,
    '445_339': 31.497,
    '445_340': 31.4571,
    '445_341': 31.4146,
    '445_342': 31.3539,
    '445_343': 31.2732,
    '445_344': 31.1786,
    '445_345': 31.0781,
    '445_346': 30.9746,
    '445_347': 30.8659,
    '445_348': 30.7282,
    '445_349': 30.581,
    '445_350': 30.431,
    '446_339': 31.4862,
    '446_340': 31.4202,
    '446_341': 31.3555,
    '446_342': 31.2835,
    '446_343': 31.2004,
    '446_344': 31.1086,
    '446_345': 31.0174,
    '446_346': 30.9279,
    '446_347': 30.8349,
    '446_348': 30.7255,
    '446_349': 30.5916,
    '446_350': 30.4529,
    '447_339': 31.471,
    '447_340': 31.384,
    '447_341': 31.3028,
    '447_342': 31.2209,
    '447_343': 31.133,
    '447_344': 31.0392,
    '447_345': 30.9474,
    '447_346': 30.8618,
    '447_347': 30.7753,
    '447_348': 30.6802,
    '447_349': 30.5724,
    '447_350': 30.4562,
    '448_339': 31.4558,
    '448_340': 31.364,
    '448_341': 31.2749,
    '448_342': 31.1817,
    '448_343': 31.0824,
    '448_344': 30.979,
    '448_345': 30.8794,
    '448_346': 30.7945,
    '448_347': 30.7093,
    '448_348': 30.6218,
    '448_349': 30.5353,
    '448_350': 30.4408,
    '458_354': 30.4231,
    '458_355': 30.3449,
    '458_356': 30.2699,
    '458_357': 30.1715,
    '458_358': 30.0233,
    '458_359': 29.8643,
    '458_360': 29.7113,
    '458_361': 29.5524,
    '458_362': 29.3782,
    '458_363': 29.1863,
    '459_354': 30.4706,
    '459_355': 30.3936,
    '459_356': 30.3166,
    '459_357': 30.2124,
    '459_358': 30.0693,
    '459_359': 29.9171,
    '459_360': 29.7696,
    '459_361': 29.618,
    '459_362': 29.4425,
    '459_363': 29.2444,
    '460_354': 30.546,
    '460_355': 30.4605,
    '460_356': 30.3783,
    '460_357': 30.2767,
    '460_358': 30.1458,
    '460_359': 29.999,
    '460_360': 29.8516,
    '460_361': 29.6977,
    '460_362': 29.5145,
    '460_363': 29.3098,
    '461_354': 30.6275,
    '461_355': 30.535,
    '461_356': 30.4536,
    '461_357': 30.3659,
    '461_358': 30.2536,
    '461_359': 30.1119,
    '461_360': 29.951,
    '461_361': 29.7818,
    '461_362': 29.589,
    '461_363': 29.378,
    '462_354': 30.7094,
    '462_355': 30.6124,
    '462_356': 30.5395,
    '462_357': 30.4679,
    '462_358': 30.3697,
    '462_359': 30.2335,
    '462_360': 30.0511,
    '462_361': 29.8572,
    '462_362': 29.6522,
    '462_363': 29.4397,
    '463_354': 30.7906,
    '463_355': 30.6957,
    '463_356': 30.6323,
    '463_357': 30.5708,
    '463_358': 30.4785,
    '463_359': 30.3376,
    '463_360': 30.1416,
    '463_361': 29.9227,
    '463_362': 29.7089,
    '463_363': 29.5008,
    '464_354': 30.8714,
    '464_355': 30.7789,
    '464_356': 30.7185,
    '464_357': 30.6613,
    '464_358': 30.5711,
    '464_359': 30.4224,
    '464_360': 30.2164,
    '464_361': 29.9879,
    '464_362': 29.7715,
    '464_363': 29.5669,
    '465_354': 30.9487,
    '465_355': 30.8592,
    '465_356': 30.7958,
    '465_357': 30.7388,
    '465_358': 30.6429,
    '465_359': 30.4935,
    '465_360': 30.2861,
    '465_361': 30.0625,
    '465_362': 29.8493,
    '465_363': 29.6463,
    '466_354': 31.0131,
    '466_355': 30.926,
    '466_356': 30.8619,
    '466_357': 30.8004,
    '466_358': 30.7042,
    '466_359': 30.5631,
    '466_360': 30.3581,
    '466_361': 30.1425,
    '466_362': 29.9375,
    '466_363': 29.7355,
    '467_354': 31.063,
    '467_355': 30.9739,
    '467_356': 30.9133,
    '467_357': 30.8534,
    '467_358': 30.7622,
    '467_359': 30.6225,
    '467_360': 30.4258,
    '467_361': 30.2193,
    '467_362': 30.0268,
    '467_363': 29.8251,
    '468_354': 31.0981,
    '468_355': 31.0081,
    '468_356': 30.9493,
    '468_357': 30.8998,
    '468_358': 30.811,
    '468_359': 30.6595,
    '468_360': 30.475,
    '468_361': 30.2871,
    '468_362': 30.1053,
    '468_363': 29.9064,
    '469_354': 31.1252,
    '469_355': 31.0378,
    '469_356': 30.9818,
    '469_357': 30.9373,
    '469_358': 30.8456,
    '469_359': 30.6938,
    '469_360': 30.5206,
    '469_361': 30.3439,
    '469_362': 30.1635,
    '469_363': 29.9631,
    '470_354': 31.1531,
    '470_355': 31.0681,
    '470_356': 31.0134,
    '470_357': 30.9723,
    '470_358': 30.8828,
    '470_359': 30.7315,
    '470_360': 30.5654,
    '470_361': 30.3923,
    '470_362': 30.216,
    '470_363': 30.014,
    '471_354': 31.1809,
    '471_355': 31.099,
    '471_356': 31.0457,
    '471_357': 31.0079,
    '471_358': 30.9143,
    '471_359': 30.7639,
    '471_360': 30.6033,
    '471_361': 30.4372,
    '471_362': 30.2615,
    '471_363': 30.0621,
    '472_354': 31.2044,
    '472_355': 31.1274,
    '472_356': 31.0803,
    '472_357': 31.0324,
    '472_358': 30.9297,
    '472_359': 30.7884,
    '472_360': 30.6366,
    '472_361': 30.4797,
    '472_362': 30.3098,
    '472_363': 30.1115,
    '473_354': 31.2213,
    '473_355': 31.1468,
    '473_356': 31.0945,
    '473_357': 31.0339,
    '473_358': 30.94,
    '473_359': 30.8098,
    '473_360': 30.669,
    '473_361': 30.5193,
    '473_362': 30.3548,
    '473_363': 30.169,
    '474_354': 31.2322,
    '474_355': 31.1596,
    '474_356': 31.103,
    '474_357': 31.0385,
    '474_358': 30.9522,
    '474_359': 30.8375,
    '474_360': 30.7062,
    '474_361': 30.5625,
    '474_362': 30.4043,
    '474_363': 30.23,
    '475_354': 31.2412,
    '475_355': 31.1725,
    '475_356': 31.1179,
    '475_357': 31.0578,
    '475_358': 30.9762,
    '475_359': 30.872,
    '475_360': 30.7472,
    '475_361': 30.6067,
    '475_362': 30.4511,
    '475_363': 30.2822,
    '476_354': 31.2434,
    '476_355': 31.1785,
    '476_356': 31.1298,
    '476_357': 31.0753,
    '476_358': 30.9973,
    '476_359': 30.9022,
    '476_360': 30.7869,
    '476_361': 30.6491,
    '476_362': 30.4987,
    '476_363': 30.3378,
    '477_354': 31.2401,
    '477_355': 31.1779,
    '477_356': 31.1291,
    '477_357': 31.0733,
    '477_358': 31.0003,
    '477_359': 30.914,
    '477_360': 30.8104,
    '477_361': 30.6841,
    '477_362': 30.5462,
    '477_363': 30.3985,
    '479_364': 30.3378,
    '479_365': 30.1928,
    '479_366': 30.0465,
    '479_367': 29.8943,
    '479_368': 29.7344,
    '479_369': 29.573,
    '479_370': 29.4112,
    '479_371': 29.2428,
    '479_372': 29.0633,
    '479_373': 28.8782,
    '479_374': 28.6901,
    '479_375': 28.501,
    '479_376': 28.3082,
    '479_377': 28.1089,
    '479_378': 27.9086,
    '479_379': 27.7099,
    '479_380': 27.5154,
    '479_381': 27.3264,
    '479_382': 27.1284,
    '479_383': 26.9385,
    '479_384': 26.7552,
    '479_385': 26.5768,
    '479_386': 26.4093,
    '479_387': 26.2614,
    '479_388': 26.1254,
    '479_389': 26.0042,
    '479_390': 25.8986,
    '480_364': 30.4002,
    '480_365': 30.2596,
    '480_366': 30.1202,
    '480_367': 29.9752,
    '480_368': 29.8254,
    '480_369': 29.6722,
    '480_370': 29.5154,
    '480_371': 29.3498,
    '480_372': 29.1751,
    '480_373': 28.9941,
    '480_374': 28.8082,
    '480_375': 28.6187,
    '480_376': 28.4267,
    '480_377': 28.2266,
    '480_378': 28.0265,
    '480_379': 27.8207,
    '480_380': 27.6237,
    '480_381': 27.43,
    '480_382': 27.2288,
    '480_383': 27.0374,
    '480_384': 26.8529,
    '480_385': 26.6723,
    '480_386': 26.5049,
    '480_387': 26.362,
    '480_388': 26.2226,
    '480_389': 26.0944,
    '480_390': 25.9797,
    '481_364': 30.4623,
    '481_365': 30.3243,
    '481_366': 30.1934,
    '481_367': 30.052,
    '481_368': 29.9111,
    '481_369': 29.7696,
    '481_370': 29.6196,
    '481_371': 29.4559,
    '481_372': 29.2847,
    '481_373': 29.1079,
    '481_374': 28.9248,
    '481_375': 28.7362,
    '481_376': 28.544,
    '481_377': 28.3501,
    '481_378': 28.1477,
    '481_379': 27.939,
    '481_380': 27.7349,
    '481_381': 27.536,
    '481_382': 27.333,
    '481_383': 27.1399,
    '481_384': 26.9568,
    '481_385': 26.7774,
    '481_386': 26.6109,
    '481_387': 26.4615,
    '481_388': 26.3184,
    '481_389': 26.1849,
    '481_390': 26.0668,
    '482_364': 30.5074,
    '482_365': 30.3791,
    '482_366': 30.2579,
    '482_367': 30.1256,
    '482_368': 29.9941,
    '482_369': 29.8667,
    '482_370': 29.7151,
    '482_371': 29.5591,
    '482_372': 29.3947,
    '482_373': 29.223,
    '482_374': 29.0432,
    '482_375': 28.8569,
    '482_376': 28.6671,
    '482_377': 28.4773,
    '482_378': 28.2705,
    '482_379': 28.0563,
    '482_380': 27.8455,
    '482_381': 27.6439,
    '482_382': 27.4446,
    '482_383': 27.2524,
    '482_384': 27.0684,
    '482_385': 26.8868,
    '482_386': 26.7189,
    '482_387': 26.5665,
    '482_388': 26.4193,
    '482_389': 26.2836,
    '482_390': 26.167,
    '483_364': 30.5393,
    '483_365': 30.4232,
    '483_366': 30.3112,
    '483_367': 30.1939,
    '483_368': 30.0703,
    '483_369': 29.9479,
    '483_370': 29.8086,
    '483_371': 29.6634,
    '483_372': 29.5074,
    '483_373': 29.3416,
    '483_374': 29.1651,
    '483_375': 28.9798,
    '483_376': 28.7894,
    '483_377': 28.5941,
    '483_378': 28.3851,
    '483_379': 28.1697,
    '483_380': 27.9604,
    '483_381': 27.7575,
    '483_382': 27.5553,
    '483_383': 27.3615,
    '483_384': 27.1757,
    '483_385': 26.9973,
    '483_386': 26.8303,
    '483_387': 26.6756,
    '483_388': 26.5262,
    '483_389': 26.388,
    '483_390': 26.2751,
    '484_364': 30.5673,
    '484_365': 30.4623,
    '484_366': 30.3592,
    '484_367': 30.254,
    '484_368': 30.1433,
    '484_369': 30.0292,
    '484_370': 29.9041,
    '484_371': 29.773,
    '484_372': 29.6235,
    '484_373': 29.4654,
    '484_374': 29.2916,
    '484_375': 29.1052,
    '484_376': 28.9134,
    '484_377': 28.7135,
    '484_378': 28.5035,
    '484_379': 28.2884,
    '484_380': 28.0778,
    '484_381': 27.8688,
    '484_382': 27.6671,
    '484_383': 27.4742,
    '484_384': 27.2884,
    '484_385': 27.1134,
    '484_386': 26.9499,
    '484_387': 26.7958,
    '484_388': 26.6485,
    '484_389': 26.5015,
    '484_390': 26.3853,
    '485_364': 30.5879,
    '485_365': 30.4975,
    '485_366': 30.4049,
    '485_367': 30.3117,
    '485_368': 30.2152,
    '485_369': 30.116,
    '485_370': 30.0001,
    '485_371': 29.883,
    '485_372': 29.7421,
    '485_373': 29.5911,
    '485_374': 29.4236,
    '485_375': 29.2367,
    '485_376': 29.0422,
    '485_377': 28.8388,
    '485_378': 28.6286,
    '485_379': 28.4169,
    '485_380': 28.2092,
    '485_381': 28.0049,
    '485_382': 27.8004,
    '485_383': 27.6038,
    '485_384': 27.4153,
    '485_385': 27.2394,
    '485_386': 27.0744,
    '485_387': 26.9166,
    '485_388': 26.7652,
    '485_389': 26.6193,
    '485_390': 26.4986,
    '486_364': 30.6081,
    '486_365': 30.5296,
    '486_366': 30.4495,
    '486_367': 30.3688,
    '486_368': 30.2853,
    '486_369': 30.2003,
    '486_370': 30.0941,
    '486_371': 29.9871,
    '486_372': 29.8551,
    '486_373': 29.7096,
    '486_374': 29.5515,
    '486_375': 29.3694,
    '486_376': 29.1734,
    '486_377': 28.9684,
    '486_378': 28.7576,
    '486_379': 28.5484,
    '486_380': 28.3418,
    '486_381': 28.1374,
    '486_382': 27.9343,
    '486_383': 27.7354,
    '486_384': 27.5468,
    '486_385': 27.369,
    '486_386': 27.202,
    '486_387': 27.0401,
    '486_388': 26.8874,
    '486_389': 26.7438,
    '486_390': 26.6171,
    '487_364': 30.6274,
    '487_365': 30.5609,
    '487_366': 30.4948,
    '487_367': 30.4262,
    '487_368': 30.3533,
    '487_369': 30.2802,
    '487_370': 30.1798,
    '487_371': 30.0688,
    '487_372': 29.944,
    '487_373': 29.8088,
    '487_374': 29.6624,
    '487_375': 29.4977,
    '487_376': 29.3038,
    '487_377': 29.1008,
    '487_378': 28.8924,
    '487_379': 28.6841,
    '487_380': 28.4775,
    '487_381': 28.2726,
    '487_382': 28.0683,
    '487_383': 27.8693,
    '487_384': 27.6783,
    '487_385': 27.4989,
    '487_386': 27.3284,
    '487_387': 27.1622,
    '487_388': 27.0073,
    '487_389': 26.8658,
    '487_390': 26.7354,
    '488_364': 30.663,
    '488_365': 30.6016,
    '488_366': 30.5426,
    '488_367': 30.4818,
    '488_368': 30.4178,
    '488_369': 30.3502,
    '488_370': 30.251,
    '488_371': 30.1423,
    '488_372': 30.0259,
    '488_373': 29.9031,
    '488_374': 29.7674,
    '488_375': 29.6129,
    '488_376': 29.4284,
    '488_377': 29.2305,
    '488_378': 29.0251,
    '488_379': 28.8182,
    '488_380': 28.6124,
    '488_381': 28.4087,
    '488_382': 28.2065,
    '488_383': 28.0046,
    '488_384': 27.8096,
    '488_385': 27.6292,
    '488_386': 27.457,
    '488_387': 27.2853,
    '488_388': 27.1277,
    '488_389': 26.9875,
    '488_390': 26.858,
    '489_364': 30.6967,
    '489_365': 30.6422,
    '489_366': 30.5892,
    '489_367': 30.5359,
    '489_368': 30.4825,
    '489_369': 30.4109,
    '489_370': 30.3151,
    '489_371': 30.2158,
    '489_372': 30.1104,
    '489_373': 30,
    '489_374': 29.87,
    '489_375': 29.7198,
    '489_376': 29.5508,
    '489_377': 29.3561,
    '489_378': 29.1531,
    '489_379': 28.9472,
    '489_380': 28.7416,
    '489_381': 28.5378,
    '489_382': 28.3363,
    '489_383': 28.1375,
    '489_384': 27.9446,
    '489_385': 27.7618,
    '489_386': 27.5857,
    '489_387': 27.4163,
    '489_388': 27.2589,
    '489_389': 27.1144,
    '489_390': 26.982,
    '490_364': 30.7275,
    '490_365': 30.6819,
    '490_366': 30.6331,
    '490_367': 30.5863,
    '490_368': 30.5404,
    '490_369': 30.4611,
    '490_370': 30.3752,
    '490_371': 30.2866,
    '490_372': 30.195,
    '490_373': 30.0936,
    '490_374': 29.9694,
    '490_375': 29.8369,
    '490_376': 29.6798,
    '490_377': 29.4796,
    '490_378': 29.2767,
    '490_379': 29.071,
    '490_380': 28.8653,
    '490_381': 28.6604,
    '490_382': 28.4578,
    '490_383': 28.2647,
    '490_384': 28.0766,
    '490_385': 27.895,
    '490_386': 27.7171,
    '490_387': 27.5484,
    '490_388': 27.3917,
    '490_389': 27.2448,
    '490_390': 27.1087,
    '491_364': 30.7537,
    '491_365': 30.7171,
    '491_366': 30.6725,
    '491_367': 30.6298,
    '491_368': 30.5846,
    '491_369': 30.5099,
    '491_370': 30.4335,
    '491_371': 30.3568,
    '491_372': 30.2802,
    '491_373': 30.1905,
    '491_374': 30.0764,
    '491_375': 29.9538,
    '491_376': 29.7938,
    '491_377': 29.5996,
    '491_378': 29.3954,
    '491_379': 29.1887,
    '491_380': 28.9845,
    '491_381': 28.783,
    '491_382': 28.5828,
    '491_383': 28.3902,
    '491_384': 28.2049,
    '491_385': 28.0242,
    '491_386': 27.8469,
    '491_387': 27.6754,
    '491_388': 27.5146,
    '491_389': 27.3623,
    '491_390': 27.2229,
    '492_364': 30.7742,
    '492_365': 30.744,
    '492_366': 30.7055,
    '492_367': 30.6661,
    '492_368': 30.621,
    '492_369': 30.5591,
    '492_370': 30.4921,
    '492_371': 30.4223,
    '492_372': 30.3585,
    '492_373': 30.2766,
    '492_374': 30.1674,
    '492_375': 30.0477,
    '492_376': 29.902,
    '492_377': 29.7242,
    '492_378': 29.5133,
    '492_379': 29.3015,
    '492_380': 29.0978,
    '492_381': 28.8989,
    '492_382': 28.7024,
    '492_383': 28.5103,
    '492_384': 28.3243,
    '492_385': 28.1494,
    '492_386': 27.9755,
    '492_387': 27.8037,
    '492_388': 27.6377,
    '492_389': 27.4829,
    '492_390': 27.3346,
    '493_364': 30.7884,
    '493_365': 30.7648,
    '493_366': 30.7338,
    '493_367': 30.702,
    '493_368': 30.6634,
    '493_369': 30.612,
    '493_370': 30.5504,
    '493_371': 30.4816,
    '493_372': 30.4204,
    '493_373': 30.3417,
    '493_374': 30.2346,
    '493_375': 30.1209,
    '493_376': 29.9933,
    '493_377': 29.8223,
    '493_378': 29.6151,
    '493_379': 29.4071,
    '493_380': 29.2046,
    '493_381': 29.0095,
    '493_382': 28.818,
    '493_383': 28.6291,
    '493_384': 28.4448,
    '493_385': 28.2693,
    '493_386': 28.0978,
    '493_387': 27.923,
    '493_388': 27.7501,
    '493_389': 27.5872,
    '493_390': 27.4431,
    '494_364': 30.8028,
    '494_365': 30.7823,
    '494_366': 30.761,
    '494_367': 30.7395,
    '494_368': 30.7112,
    '494_369': 30.6672,
    '494_370': 30.6054,
    '494_371': 30.5403,
    '494_372': 30.4828,
    '494_373': 30.4041,
    '494_374': 30.302,
    '494_375': 30.1859,
    '494_376': 30.0589,
    '494_377': 29.8907,
    '494_378': 29.6978,
    '494_379': 29.5013,
    '494_380': 29.3062,
    '494_381': 29.1156,
    '494_382': 28.9293,
    '494_383': 28.744,
    '494_384': 28.5597,
    '494_385': 28.3821,
    '494_386': 28.2092,
    '494_387': 28.0385,
    '494_388': 27.8671,
    '494_389': 27.7009,
    '494_390': 27.5529,
    '494_394': 26.8931,
    '494_395': 26.859,
    '494_396': 26.8288,
    '494_397': 26.803,
    '494_398': 26.7792,
    '494_399': 26.7519,
    '494_400': 26.7124,
    '494_401': 26.6599,
    '494_402': 26.6019,
    '494_403': 26.5393,
    '494_404': 26.4691,
    '495_364': 30.8196,
    '495_365': 30.8002,
    '495_366': 30.7849,
    '495_367': 30.7672,
    '495_368': 30.7346,
    '495_369': 30.6973,
    '495_370': 30.6426,
    '495_371': 30.5906,
    '495_372': 30.5351,
    '495_373': 30.4592,
    '495_374': 30.367,
    '495_375': 30.2546,
    '495_376': 30.12,
    '495_377': 29.9667,
    '495_378': 29.787,
    '495_379': 29.5933,
    '495_380': 29.4053,
    '495_381': 29.2186,
    '495_382': 29.0353,
    '495_383': 28.8533,
    '495_384': 28.6725,
    '495_385': 28.4945,
    '495_386': 28.3203,
    '495_387': 28.1494,
    '495_388': 27.9788,
    '495_389': 27.8047,
    '495_390': 27.6506,
    '495_394': 26.9889,
    '495_395': 26.9584,
    '495_396': 26.9338,
    '495_397': 26.9102,
    '495_398': 26.8794,
    '495_399': 26.8349,
    '495_400': 26.7753,
    '495_401': 26.7083,
    '495_402': 26.642,
    '495_403': 26.5722,
    '495_404': 26.4959,
    '496_364': 30.8287,
    '496_365': 30.8105,
    '496_366': 30.7919,
    '496_367': 30.7716,
    '496_368': 30.7407,
    '496_369': 30.7089,
    '496_370': 30.6652,
    '496_371': 30.6219,
    '496_372': 30.573,
    '496_373': 30.5099,
    '496_374': 30.4266,
    '496_375': 30.3267,
    '496_376': 30.1931,
    '496_377': 30.0502,
    '496_378': 29.8812,
    '496_379': 29.6879,
    '496_380': 29.5055,
    '496_381': 29.3209,
    '496_382': 29.1406,
    '496_383': 28.9608,
    '496_384': 28.783,
    '496_385': 28.6043,
    '496_386': 28.4281,
    '496_387': 28.2536,
    '496_388': 28.0786,
    '496_389': 27.9057,
    '496_390': 27.7459,
    '496_394': 27.0775,
    '496_395': 27.051,
    '496_396': 27.034,
    '496_397': 27.0176,
    '496_398': 26.982,
    '496_399': 26.9202,
    '496_400': 26.8349,
    '496_401': 26.7489,
    '496_402': 26.6746,
    '496_403': 26.5991,
    '496_404': 26.5192,
    '497_364': 30.8319,
    '497_365': 30.8158,
    '497_366': 30.7933,
    '497_367': 30.7712,
    '497_368': 30.7488,
    '497_369': 30.7181,
    '497_370': 30.6926,
    '497_371': 30.6558,
    '497_372': 30.6189,
    '497_373': 30.571,
    '497_374': 30.4849,
    '497_375': 30.3827,
    '497_376': 30.2598,
    '497_377': 30.1253,
    '497_378': 29.9591,
    '497_379': 29.787,
    '497_380': 29.6138,
    '497_381': 29.4314,
    '497_382': 29.2502,
    '497_383': 29.0701,
    '497_384': 28.8916,
    '497_385': 28.7134,
    '497_386': 28.5361,
    '497_387': 28.3565,
    '497_388': 28.1768,
    '497_389': 28.0018,
    '497_390': 27.8384,
    '497_394': 27.1542,
    '497_395': 27.1311,
    '497_396': 27.1247,
    '497_397': 27.1113,
    '497_398': 27.0721,
    '497_399': 26.993,
    '497_400': 26.8876,
    '497_401': 26.7837,
    '497_402': 26.6969,
    '497_403': 26.6207,
    '497_404': 26.5404,
    '498_364': 30.8277,
    '498_365': 30.8144,
    '498_366': 30.7921,
    '498_367': 30.7698,
    '498_368': 30.7482,
    '498_369': 30.7245,
    '498_370': 30.7105,
    '498_371': 30.6882,
    '498_372': 30.659,
    '498_373': 30.6126,
    '498_374': 30.5267,
    '498_375': 30.4276,
    '498_376': 30.3179,
    '498_377': 30.1858,
    '498_378': 30.0406,
    '498_379': 29.8937,
    '498_380': 29.7314,
    '498_381': 29.5443,
    '498_382': 29.3585,
    '498_383': 29.1755,
    '498_384': 28.9953,
    '498_385': 28.8171,
    '498_386': 28.6397,
    '498_387': 28.456,
    '498_388': 28.2726,
    '498_389': 28.096,
    '498_390': 27.9318,
    '498_394': 27.216,
    '498_395': 27.1928,
    '498_396': 27.1897,
    '498_397': 27.1769,
    '498_398': 27.1145,
    '498_399': 27.0262,
    '498_400': 26.9107,
    '498_401': 26.8022,
    '498_402': 26.7091,
    '498_403': 26.637,
    '498_404': 26.5582,
    '499_364': 30.8204,
    '499_365': 30.8085,
    '499_366': 30.787,
    '499_367': 30.7633,
    '499_368': 30.739,
    '499_369': 30.7157,
    '499_370': 30.7,
    '499_371': 30.6877,
    '499_372': 30.6624,
    '499_373': 30.6237,
    '499_374': 30.5519,
    '499_375': 30.4609,
    '499_376': 30.3602,
    '499_377': 30.2415,
    '499_378': 30.1153,
    '499_379': 29.9749,
    '499_380': 29.8139,
    '499_381': 29.6369,
    '499_382': 29.4569,
    '499_383': 29.2725,
    '499_384': 29.09,
    '499_385': 28.9094,
    '499_386': 28.729,
    '499_387': 28.5463,
    '499_388': 28.3621,
    '499_389': 28.1845,
    '499_390': 28.0227,
    '499_394': 27.2732,
    '499_395': 27.2365,
    '499_396': 27.2229,
    '499_397': 27.1986,
    '499_398': 27.121,
    '499_399': 27.0233,
    '499_400': 26.9174,
    '499_401': 26.8149,
    '499_402': 26.724,
    '499_403': 26.6439,
    '499_404': 26.5607,
    '500_364': 30.7999,
    '500_365': 30.7947,
    '500_366': 30.7776,
    '500_367': 30.7527,
    '500_368': 30.727,
    '500_369': 30.7009,
    '500_370': 30.6798,
    '500_371': 30.6615,
    '500_372': 30.6388,
    '500_373': 30.6187,
    '500_374': 30.5623,
    '500_375': 30.4707,
    '500_376': 30.3801,
    '500_377': 30.2803,
    '500_378': 30.1645,
    '500_379': 30.026,
    '500_380': 29.8706,
    '500_381': 29.7178,
    '500_382': 29.5466,
    '500_383': 29.3605,
    '500_384': 29.176,
    '500_385': 28.9945,
    '500_386': 28.8134,
    '500_387': 28.6344,
    '500_388': 28.4509,
    '500_389': 28.2741,
    '500_390': 28.1083,
    '500_394': 27.321,
    '500_395': 27.2643,
    '500_396': 27.2304,
    '500_397': 27.1853,
    '500_398': 27.1102,
    '500_399': 27.0217,
    '500_400': 26.9266,
    '500_401': 26.8293,
    '500_402': 26.7355,
    '500_403': 26.649,
    '500_404': 26.5619,
    '501_364': 30.7751,
    '501_365': 30.7686,
    '501_366': 30.7551,
    '501_367': 30.7301,
    '501_368': 30.7062,
    '501_369': 30.6832,
    '501_370': 30.6595,
    '501_371': 30.6367,
    '501_372': 30.6111,
    '501_373': 30.5861,
    '501_374': 30.5413,
    '501_375': 30.4584,
    '501_376': 30.3766,
    '501_377': 30.294,
    '501_378': 30.1845,
    '501_379': 30.0592,
    '501_380': 29.9191,
    '501_381': 29.7825,
    '501_382': 29.6195,
    '501_383': 29.4395,
    '501_384': 29.2544,
    '501_385': 29.0755,
    '501_386': 28.8979,
    '501_387': 28.7204,
    '501_388': 28.5409,
    '501_389': 28.3633,
    '501_390': 28.1879,
    '501_394': 27.3782,
    '501_395': 27.3013,
    '501_396': 27.2387,
    '501_397': 27.177,
    '501_398': 27.1006,
    '501_399': 27.0164,
    '501_400': 26.9311,
    '501_401': 26.8382,
    '501_402': 26.7461,
    '501_403': 26.6527,
    '501_404': 26.5619,
    '502_364': 30.7278,
    '502_365': 30.727,
    '502_366': 30.7122,
    '502_367': 30.695,
    '502_368': 30.6782,
    '502_369': 30.6595,
    '502_370': 30.6397,
    '502_371': 30.6168,
    '502_372': 30.5873,
    '502_373': 30.5532,
    '502_374': 30.5066,
    '502_375': 30.4367,
    '502_376': 30.3647,
    '502_377': 30.2893,
    '502_378': 30.1862,
    '502_379': 30.0761,
    '502_380': 29.9454,
    '502_381': 29.8217,
    '502_382': 29.6734,
    '502_383': 29.5029,
    '502_384': 29.3251,
    '502_385': 29.1508,
    '502_386': 28.9781,
    '502_387': 28.8037,
    '502_388': 28.6267,
    '502_389': 28.4484,
    '502_390': 28.2668,
    '502_394': 27.4399,
    '502_395': 27.3421,
    '502_396': 27.2567,
    '502_397': 27.1759,
    '502_398': 27.0955,
    '502_399': 27.0115,
    '502_400': 26.929,
    '502_401': 26.8418,
    '502_402': 26.7507,
    '502_403': 26.655,
    '502_404': 26.5641,
    '503_364': 30.7006,
    '503_365': 30.6915,
    '503_366': 30.6803,
    '503_367': 30.6665,
    '503_368': 30.6508,
    '503_369': 30.6345,
    '503_370': 30.6167,
    '503_371': 30.5948,
    '503_372': 30.5654,
    '503_373': 30.5287,
    '503_374': 30.4789,
    '503_375': 30.4158,
    '503_376': 30.3466,
    '503_377': 30.2706,
    '503_378': 30.1779,
    '503_379': 30.0755,
    '503_380': 29.9569,
    '503_381': 29.8437,
    '503_382': 29.7152,
    '503_383': 29.5541,
    '503_384': 29.3896,
    '503_385': 29.2202,
    '503_386': 29.0529,
    '503_387': 28.8814,
    '503_388': 28.7043,
    '503_389': 28.5245,
    '503_390': 28.3431,
    '503_394': 27.509,
    '503_395': 27.3909,
    '503_396': 27.283,
    '503_397': 27.1849,
    '503_398': 27.0916,
    '503_399': 27.005,
    '503_400': 26.922,
    '503_401': 26.8389,
    '503_402': 26.7509,
    '503_403': 26.6514,
    '503_404': 26.5553,
    '504_364': 30.6805,
    '504_365': 30.6619,
    '504_366': 30.649,
    '504_367': 30.6396,
    '504_368': 30.6238,
    '504_369': 30.6085,
    '504_370': 30.5918,
    '504_371': 30.5701,
    '504_372': 30.5432,
    '504_373': 30.5079,
    '504_374': 30.4575,
    '504_375': 30.397,
    '504_376': 30.3295,
    '504_377': 30.2525,
    '504_378': 30.1663,
    '504_379': 30.0699,
    '504_380': 29.9646,
    '504_381': 29.8627,
    '504_382': 29.7519,
    '504_383': 29.6056,
    '504_384': 29.4442,
    '504_385': 29.2819,
    '504_386': 29.1234,
    '504_387': 28.9546,
    '504_388': 28.7747,
    '504_389': 28.5932,
    '504_390': 28.4114,
    '504_394': 27.5649,
    '504_395': 27.4367,
    '504_396': 27.3163,
    '504_397': 27.2027,
    '504_398': 27.0972,
    '504_399': 26.9988,
    '504_400': 26.9156,
    '504_401': 26.8329,
    '504_402': 26.7475,
    '504_403': 26.6502,
    '504_404': 26.5588,
    '505_364': 30.665,
    '505_365': 30.6424,
    '505_366': 30.6251,
    '505_367': 30.6135,
    '505_368': 30.6,
    '505_369': 30.5834,
    '505_370': 30.5644,
    '505_371': 30.5407,
    '505_372': 30.5099,
    '505_373': 30.4779,
    '505_374': 30.4325,
    '505_375': 30.3792,
    '505_376': 30.3157,
    '505_377': 30.2394,
    '505_378': 30.1554,
    '505_379': 30.0637,
    '505_380': 29.9683,
    '505_381': 29.8792,
    '505_382': 29.7731,
    '505_383': 29.6434,
    '505_384': 29.4847,
    '505_385': 29.3314,
    '505_386': 29.178,
    '505_387': 29.0169,
    '505_388': 28.8372,
    '505_389': 28.6529,
    '505_390': 28.4706,
    '505_394': 27.6204,
    '505_395': 27.4955,
    '505_396': 27.3569,
    '505_397': 27.2329,
    '505_398': 27.1178,
    '505_399': 27.0093,
    '505_400': 26.9123,
    '505_401': 26.819,
    '505_402': 26.7283,
    '505_403': 26.647,
    '505_404': 26.5554,
    '506_364': 30.656,
    '506_365': 30.6264,
    '506_366': 30.6034,
    '506_367': 30.5881,
    '506_368': 30.5733,
    '506_369': 30.5576,
    '506_370': 30.5378,
    '506_371': 30.5128,
    '506_372': 30.4754,
    '506_373': 30.4504,
    '506_374': 30.4093,
    '506_375': 30.3609,
    '506_376': 30.3017,
    '506_377': 30.2275,
    '506_378': 30.1458,
    '506_379': 30.0581,
    '506_380': 29.9677,
    '506_381': 29.8817,
    '506_382': 29.7804,
    '506_383': 29.6636,
    '506_384': 29.5176,
    '506_385': 29.3662,
    '506_386': 29.2148,
    '506_387': 29.0581,
    '506_388': 28.8862,
    '506_389': 28.7033,
    '506_390': 28.5207,
    '507_364': 30.6454,
    '507_365': 30.6164,
    '507_366': 30.5893,
    '507_367': 30.5678,
    '507_368': 30.5514,
    '507_369': 30.5352,
    '507_370': 30.516,
    '507_371': 30.4892,
    '507_372': 30.4551,
    '507_373': 30.4232,
    '507_374': 30.3811,
    '507_375': 30.3377,
    '507_376': 30.2822,
    '507_377': 30.2129,
    '507_378': 30.1346,
    '507_379': 30.0504,
    '507_380': 29.9621,
    '507_381': 29.8739,
    '507_382': 29.7809,
    '507_383': 29.6727,
    '507_384': 29.5423,
    '507_385': 29.3908,
    '507_386': 29.2415,
    '507_387': 29.0858,
    '507_388': 28.9229,
    '507_389': 28.7432,
    '507_390': 28.5623,
    '508_364': 30.6373,
    '508_365': 30.6099,
    '508_366': 30.5809,
    '508_367': 30.5581,
    '508_368': 30.5366,
    '508_369': 30.5161,
    '508_370': 30.4933,
    '508_371': 30.4695,
    '508_372': 30.4367,
    '508_373': 30.4034,
    '508_374': 30.3631,
    '508_375': 30.3172,
    '508_376': 30.2613,
    '508_377': 30.1937,
    '508_378': 30.1184,
    '508_379': 30.0378,
    '508_380': 29.9524,
    '508_381': 29.8636,
    '508_382': 29.7708,
    '508_383': 29.6688,
    '508_384': 29.5524,
    '508_385': 29.4097,
    '508_386': 29.2652,
    '508_387': 29.1171,
    '508_388': 28.9554,
    '508_389': 28.7774,
    '508_390': 28.5978,
    '509_364': 30.6313,
    '509_365': 30.6046,
    '509_366': 30.577,
    '509_367': 30.5542,
    '509_368': 30.5288,
    '509_369': 30.5046,
    '509_370': 30.4805,
    '509_371': 30.4556,
    '509_372': 30.4174,
    '509_373': 30.3781,
    '509_374': 30.334,
    '509_375': 30.2864,
    '509_376': 30.2308,
    '509_377': 30.1665,
    '509_378': 30.0959,
    '509_379': 30.0203,
    '509_380': 29.9403,
    '509_381': 29.8516,
    '509_382': 29.7587,
    '509_383': 29.6581,
    '509_384': 29.5465,
    '509_385': 29.4192,
    '509_386': 29.2854,
    '509_387': 29.1445,
    '509_388': 28.9817,
    '509_389': 28.8075,
    '509_390': 28.6295,
    '510_364': 30.6317,
    '510_365': 30.606,
    '510_366': 30.5774,
    '510_367': 30.5548,
    '510_368': 30.5244,
    '510_369': 30.4961,
    '510_370': 30.4684,
    '510_371': 30.4397,
    '510_372': 30.4034,
    '510_373': 30.358,
    '510_374': 30.3076,
    '510_375': 30.2589,
    '510_376': 30.2032,
    '510_377': 30.1407,
    '510_378': 30.0734,
    '510_379': 30.0027,
    '510_380': 29.9286,
    '510_381': 29.8407,
    '510_382': 29.7496,
    '510_383': 29.6501,
    '510_384': 29.5422,
    '510_385': 29.4254,
    '510_386': 29.3015,
    '510_387': 29.1645,
    '510_388': 29.0051,
    '510_389': 28.8366,
    '510_390': 28.6632,
    '511_364': 30.6411,
    '511_365': 30.6141,
    '511_366': 30.5884,
    '511_367': 30.5622,
    '511_368': 30.5236,
    '511_369': 30.4905,
    '511_370': 30.4608,
    '511_371': 30.4299,
    '511_372': 30.3931,
    '511_373': 30.3498,
    '511_374': 30.2948,
    '511_375': 30.2443,
    '511_376': 30.1875,
    '511_377': 30.1229,
    '511_378': 30.0556,
    '511_379': 29.99,
    '511_380': 29.9156,
    '511_381': 29.8325,
    '511_382': 29.7436,
    '511_383': 29.6432,
    '511_384': 29.5382,
    '511_385': 29.4265,
    '511_386': 29.3071,
    '511_387': 29.177,
    '511_388': 29.0282,
    '511_389': 28.8693,
    '511_390': 28.7058,
    '512_364': 30.653,
    '512_365': 30.6257,
    '512_366': 30.6024,
    '512_367': 30.5764,
    '512_368': 30.5432,
    '512_369': 30.5066,
    '512_370': 30.4659,
    '512_371': 30.4284,
    '512_372': 30.3838,
    '512_373': 30.3393,
    '512_374': 30.2924,
    '512_375': 30.2422,
    '512_376': 30.1857,
    '512_377': 30.1221,
    '512_378': 30.0567,
    '512_379': 29.9898,
    '512_380': 29.9111,
    '512_381': 29.829,
    '512_382': 29.741,
    '512_383': 29.636,
    '512_384': 29.5339,
    '512_385': 29.4263,
    '512_386': 29.3122,
    '512_387': 29.1881,
    '512_388': 29.0505,
    '512_389': 28.9021,
    '512_390': 28.7482,
    '513_364': 30.6648,
    '513_365': 30.6366,
    '513_366': 30.6175,
    '513_367': 30.5983,
    '513_368': 30.5668,
    '513_369': 30.529,
    '513_370': 30.4854,
    '513_371': 30.4398,
    '513_372': 30.3907,
    '513_373': 30.3423,
    '513_374': 30.2946,
    '513_375': 30.2455,
    '513_376': 30.19,
    '513_377': 30.1292,
    '513_378': 30.0644,
    '513_379': 29.9958,
    '513_380': 29.9215,
    '513_381': 29.8399,
    '513_382': 29.7361,
    '513_383': 29.633,
    '513_384': 29.5315,
    '513_385': 29.4284,
    '513_386': 29.3211,
    '513_387': 29.2043,
    '513_388': 29.0752,
    '513_389': 28.9343,
    '513_390': 28.785,
    '514_364': 30.6794,
    '514_365': 30.6488,
    '514_366': 30.6317,
    '514_367': 30.6167,
    '514_368': 30.5861,
    '514_369': 30.5489,
    '514_370': 30.504,
    '514_371': 30.4533,
    '514_372': 30.4005,
    '514_373': 30.3491,
    '514_374': 30.3015,
    '514_375': 30.251,
    '514_376': 30.1968,
    '514_377': 30.1381,
    '514_378': 30.0733,
    '514_379': 30.0038,
    '514_380': 29.9322,
    '514_381': 29.852,
    '514_382': 29.7527,
    '514_383': 29.6471,
    '514_384': 29.536,
    '514_385': 29.4336,
    '514_386': 29.334,
    '514_387': 29.2244,
    '514_388': 29.1019,
    '514_389': 28.9665,
    '514_390': 28.82,
    '547_367': 31.2525,
    '547_368': 31.235,
    '547_369': 31.2164,
    '547_370': 31.1785,
    '548_367': 31.292,
    '548_368': 31.2723,
    '548_369': 31.2436,
    '548_370': 31.2028,
    '549_367': 31.3342,
    '549_368': 31.3094,
    '549_369': 31.2717,
    '549_370': 31.2278,
    '550_367': 31.3751,
    '550_368': 31.3428,
    '550_369': 31.299,
    '550_370': 31.2522,
    '551_367': 31.4072,
    '551_368': 31.3727,
    '551_369': 31.3227,
    '551_370': 31.2716,
    '552_372': 31.1813,
    '552_373': 31.1119,
    '552_374': 31.0344,
    '553_372': 31.1897,
    '553_373': 31.126,
    '553_374': 31.0538,
    '554_372': 31.1981,
    '554_373': 31.1386,
    '554_374': 31.0718,
    '566_383': 30.6564,
    '566_384': 30.556,
    '566_385': 30.4481,
    '567_383': 30.6806,
    '567_384': 30.5969,
    '567_385': 30.4919,
    '568_383': 30.6986,
    '568_384': 30.6258,
    '568_385': 30.5233,
    '569_383': 30.7104,
    '569_384': 30.6325,
    '569_385': 30.5413,
    '575_387': 30.7014,
    '575_388': 30.6354,
    '575_389': 30.5494,
    '575_390': 30.4496,
    '576_387': 30.8182,
    '576_388': 30.7578,
    '576_389': 30.6781,
    '576_390': 30.5868,
    '577_387': 30.9336,
    '577_388': 30.8867,
    '577_389': 30.8095,
    '577_390': 30.7169,
    '578_387': 31.0421,
    '578_388': 31.0069,
    '578_389': 30.937,
    '578_390': 30.8267,
    '579_387': 31.1384,
    '579_388': 31.0967,
    '579_389': 31.0347,
    '579_390': 30.9233,
    '580_380': 31.4541,
    '580_381': 31.39,
    '580_382': 31.3258,
    '580_383': 31.2639,
    '580_387': 31.224,
    '580_388': 31.1642,
    '580_389': 31.0994,
    '580_390': 31.0066,
    '581_380': 31.5184,
    '581_381': 31.4514,
    '581_382': 31.3822,
    '581_383': 31.3165,
    '581_387': 31.3064,
    '581_388': 31.2346,
    '581_389': 31.1609,
    '581_390': 31.0781,
    '582_380': 31.579,
    '582_381': 31.5063,
    '582_382': 31.4338,
    '582_383': 31.365,
    '583_380': 31.634,
    '583_381': 31.556,
    '583_382': 31.4788,
    '583_383': 31.4064,
    '588_393': 31.0542,
    '588_394': 31.0124,
    '588_395': 30.9528,
    '588_396': 30.8717,
    '588_397': 30.7694,
    '588_398': 30.6632,
    '589_393': 31.087,
    '589_394': 31.0499,
    '589_395': 31.0048,
    '589_396': 30.923,
    '589_397': 30.8234,
    '589_398': 30.7238,
    '590_393': 31.1194,
    '590_394': 31.0909,
    '590_395': 31.0552,
    '590_396': 30.9681,
    '590_397': 30.8653,
    '590_398': 30.7651,
    '591_393': 31.1519,
    '591_394': 31.1392,
    '591_395': 31.0908,
    '591_396': 30.9936,
    '591_397': 30.9004,
    '591_398': 30.8036,
    '592_393': 31.1735,
    '592_394': 31.1534,
    '592_395': 31.0945,
    '592_396': 31.0041,
    '592_397': 30.9253,
    '592_398': 30.831,
    '593_393': 31.1851,
    '593_394': 31.1389,
    '593_395': 31.0768,
    '593_396': 31.0049,
    '593_397': 30.9362,
    '593_398': 30.8609,
    '594_393': 31.197,
    '594_394': 31.1339,
    '594_395': 31.0716,
    '594_396': 31.014,
    '594_397': 30.9591,
    '594_398': 30.8914,
    '596_395': 31.1832,
    '596_396': 31.1496,
    '596_397': 31.1075,
    '596_398': 31.0487,
    '596_399': 30.9755,
    '597_395': 31.2238,
    '597_396': 31.1878,
    '597_397': 31.1516,
    '597_398': 31.0886,
    '597_399': 31.0146,
    '598_395': 31.2639,
    '598_396': 31.2293,
    '598_397': 31.2004,
    '598_398': 31.1274,
    '598_399': 31.0558,
    '599_395': 31.3031,
    '599_396': 31.2669,
    '599_397': 31.2313,
    '599_398': 31.1669,
    '599_399': 31.1003,
    '600_395': 31.3485,
    '600_396': 31.3055,
    '600_397': 31.2634,
    '600_398': 31.2097,
    '600_399': 31.1468,
    '601_395': 31.3927,
    '601_396': 31.3454,
    '601_397': 31.2996,
    '601_398': 31.2503,
    '601_399': 31.1958,
    '602_395': 31.4303,
    '602_396': 31.3821,
    '602_397': 31.3353,
    '602_398': 31.2896,
    '602_399': 31.2446,
    '612_414': 30.7676,
    '612_415': 30.7795,
    '612_416': 30.7796,
    '612_417': 30.7627,
    '612_418': 30.7277,
    '612_419': 30.673,
    '612_420': 30.5988,
    '612_421': 30.5058,
    '612_422': 30.3869,
    '612_423': 30.2428,
    '612_424': 30.093,
    '612_425': 29.9407,
    '612_426': 29.7865,
    '612_427': 29.631,
    '612_428': 29.4789,
    '613_414': 30.8277,
    '613_415': 30.8486,
    '613_416': 30.8638,
    '613_417': 30.8669,
    '613_418': 30.8525,
    '613_419': 30.8107,
    '613_420': 30.7428,
    '613_421': 30.6508,
    '613_422': 30.5309,
    '613_423': 30.3828,
    '613_424': 30.2215,
    '613_425': 30.0578,
    '613_426': 29.8946,
    '613_427': 29.733,
    '613_428': 29.5758,
    '614_414': 30.8879,
    '614_415': 30.9229,
    '614_416': 30.9549,
    '614_417': 30.9809,
    '614_418': 30.9964,
    '614_419': 30.9738,
    '614_420': 30.9155,
    '614_421': 30.8283,
    '614_422': 30.7036,
    '614_423': 30.5399,
    '614_424': 30.3566,
    '614_425': 30.1783,
    '614_426': 30.0047,
    '614_427': 29.836,
    '614_428': 29.6736,
    '615_414': 30.9485,
    '615_415': 30.9984,
    '615_416': 31.0483,
    '615_417': 31.0995,
    '615_418': 31.1474,
    '615_419': 31.1589,
    '615_420': 31.1225,
    '615_421': 31.0448,
    '615_422': 30.9073,
    '615_423': 30.7221,
    '615_424': 30.498,
    '615_425': 30.2991,
    '615_426': 30.1134,
    '615_427': 29.938,
    '615_428': 29.7722,
    '616_414': 31.0137,
    '616_415': 31.0712,
    '616_416': 31.1387,
    '616_417': 31.2161,
    '616_418': 31.2882,
    '616_419': 31.3274,
    '616_420': 31.3154,
    '616_421': 31.2419,
    '616_422': 31.1033,
    '616_423': 30.907,
    '616_424': 30.6631,
    '616_425': 30.4216,
    '616_426': 30.2176,
    '616_427': 30.0331,
    '616_428': 29.8613,
    '617_414': 31.0616,
    '617_415': 31.1324,
    '617_416': 31.2239,
    '617_417': 31.325,
    '617_418': 31.4153,
    '617_419': 31.4762,
    '617_420': 31.471,
    '617_421': 31.3953,
    '617_422': 31.2606,
    '617_423': 31.0622,
    '617_424': 30.8183,
    '617_425': 30.5496,
    '617_426': 30.3158,
    '617_427': 30.1203,
    '617_428': 29.9412,
    '618_414': 31.1078,
    '618_415': 31.1973,
    '618_416': 31.3148,
    '618_417': 31.4333,
    '618_418': 31.535,
    '618_419': 31.5984,
    '618_420': 31.5903,
    '618_421': 31.5174,
    '618_422': 31.3782,
    '618_423': 31.1782,
    '618_424': 30.936,
    '618_425': 30.6656,
    '618_426': 30.4098,
    '618_427': 30.2019,
    '618_428': 30.0171,
    '619_414': 31.1444,
    '619_415': 31.2508,
    '619_416': 31.3933,
    '619_417': 31.5283,
    '619_418': 31.6306,
    '619_419': 31.6872,
    '619_420': 31.6722,
    '619_421': 31.5898,
    '619_422': 31.4451,
    '619_423': 31.2543,
    '619_424': 31.0154,
    '619_425': 30.7499,
    '619_426': 30.4918,
    '619_427': 30.274,
    '619_428': 30.0853,
    '619_432': 29.4763,
    '619_433': 29.3536,
    '619_434': 29.2267,
    '619_435': 29.0897,
    '619_436': 28.9362,
    '619_437': 28.7665,
    '619_438': 28.5818,
    '619_439': 28.3839,
    '619_440': 28.1742,
    '619_441': 27.9544,
    '619_442': 27.7251,
    '619_443': 27.4776,
    '619_444': 27.2299,
    '619_445': 26.9819,
    '620_414': 31.167,
    '620_415': 31.2861,
    '620_416': 31.4545,
    '620_417': 31.593,
    '620_418': 31.6874,
    '620_419': 31.727,
    '620_420': 31.7052,
    '620_421': 31.6349,
    '620_422': 31.4928,
    '620_423': 31.2965,
    '620_424': 31.0656,
    '620_425': 30.8154,
    '620_426': 30.5616,
    '620_427': 30.3356,
    '620_428': 30.1466,
    '620_432': 29.535,
    '620_433': 29.413,
    '620_434': 29.288,
    '620_435': 29.153,
    '620_436': 29.0026,
    '620_437': 28.8357,
    '620_438': 28.6528,
    '620_439': 28.4563,
    '620_440': 28.2481,
    '620_441': 28.0297,
    '620_442': 27.8014,
    '620_443': 27.5618,
    '620_444': 27.3189,
    '620_445': 27.0668,
    '621_414': 31.1819,
    '621_415': 31.3022,
    '621_416': 31.4815,
    '621_417': 31.6124,
    '621_418': 31.6967,
    '621_419': 31.7431,
    '621_420': 31.7152,
    '621_421': 31.6384,
    '621_422': 31.5032,
    '621_423': 31.3148,
    '621_424': 31.0997,
    '621_425': 30.8643,
    '621_426': 30.6129,
    '621_427': 30.3853,
    '621_428': 30.1972,
    '621_432': 29.5888,
    '621_433': 29.47,
    '621_434': 29.3504,
    '621_435': 29.2231,
    '621_436': 29.0736,
    '621_437': 28.908,
    '621_438': 28.7261,
    '621_439': 28.5304,
    '621_440': 28.3221,
    '621_441': 28.1031,
    '621_442': 27.8745,
    '621_443': 27.6371,
    '621_444': 27.3944,
    '621_445': 27.1449,
    '622_414': 31.1882,
    '622_415': 31.312,
    '622_416': 31.4714,
    '622_417': 31.5879,
    '622_418': 31.6653,
    '622_419': 31.7061,
    '622_420': 31.6887,
    '622_421': 31.6061,
    '622_422': 31.4732,
    '622_423': 31.3028,
    '622_424': 31.1016,
    '622_425': 30.871,
    '622_426': 30.6326,
    '622_427': 30.4188,
    '622_428': 30.2354,
    '622_432': 29.6389,
    '622_433': 29.5234,
    '622_434': 29.4115,
    '622_435': 29.2971,
    '622_436': 29.1485,
    '622_437': 28.9824,
    '622_438': 28.8008,
    '622_439': 28.6072,
    '622_440': 28.3985,
    '622_441': 28.1786,
    '622_442': 27.9496,
    '622_443': 27.7125,
    '622_444': 27.4693,
    '622_445': 27.2302,
    '623_414': 31.1811,
    '623_415': 31.2982,
    '623_416': 31.4252,
    '623_417': 31.5118,
    '623_418': 31.5946,
    '623_419': 31.6468,
    '623_420': 31.6235,
    '623_421': 31.5442,
    '623_422': 31.4214,
    '623_423': 31.2584,
    '623_424': 31.0683,
    '623_425': 30.8375,
    '623_426': 30.6207,
    '623_427': 30.4339,
    '623_428': 30.2653,
    '623_432': 29.6835,
    '623_433': 29.5731,
    '623_434': 29.4687,
    '623_435': 29.3635,
    '623_436': 29.2229,
    '623_437': 29.0581,
    '623_438': 28.8808,
    '623_439': 28.6864,
    '623_440': 28.4772,
    '623_441': 28.2579,
    '623_442': 28.0311,
    '623_443': 27.7986,
    '623_444': 27.5607,
    '623_445': 27.3239,
    '624_405': 31.1853,
    '624_406': 31.1537,
    '624_407': 31.1192,
    '624_408': 31.0856,
    '624_409': 31.0575,
    '624_410': 31.0427,
    '624_411': 31.0486,
    '624_414': 31.161,
    '624_415': 31.2561,
    '624_416': 31.3509,
    '624_417': 31.422,
    '624_418': 31.516,
    '624_419': 31.5728,
    '624_420': 31.5465,
    '624_421': 31.4571,
    '624_422': 31.3352,
    '624_423': 31.178,
    '624_424': 31.0028,
    '624_425': 30.7961,
    '624_426': 30.6052,
    '624_427': 30.4419,
    '624_428': 30.2855,
    '624_432': 29.719,
    '624_433': 29.6167,
    '624_434': 29.5206,
    '624_435': 29.4219,
    '624_436': 29.282,
    '624_437': 29.1291,
    '624_438': 28.9568,
    '624_439': 28.763,
    '624_440': 28.5547,
    '624_441': 28.3377,
    '624_442': 28.1145,
    '624_443': 27.8886,
    '624_444': 27.6555,
    '624_445': 27.419,
    '625_405': 31.1946,
    '625_406': 31.1687,
    '625_407': 31.1408,
    '625_408': 31.1149,
    '625_409': 31.0918,
    '625_410': 31.068,
    '625_411': 31.0652,
    '625_414': 31.1368,
    '625_415': 31.2083,
    '625_416': 31.2817,
    '625_417': 31.351,
    '625_418': 31.4365,
    '625_419': 31.4916,
    '625_420': 31.4717,
    '625_421': 31.385,
    '625_422': 31.2554,
    '625_423': 31.096,
    '625_424': 30.9433,
    '625_425': 30.7704,
    '625_426': 30.602,
    '625_427': 30.4486,
    '625_428': 30.2975,
    '625_432': 29.7518,
    '625_433': 29.6575,
    '625_434': 29.5713,
    '625_435': 29.4824,
    '625_436': 29.3551,
    '625_437': 29.2017,
    '625_438': 29.0297,
    '625_439': 28.8385,
    '625_440': 28.6328,
    '625_441': 28.4186,
    '625_442': 28.1981,
    '625_443': 27.9782,
    '625_444': 27.7516,
    '625_445': 27.5177,
    '626_405': 31.2056,
    '626_406': 31.1905,
    '626_407': 31.1727,
    '626_408': 31.1597,
    '626_409': 31.1456,
    '626_410': 31.097,
    '626_411': 31.0756,
    '626_414': 31.1075,
    '626_415': 31.1643,
    '626_416': 31.2248,
    '626_417': 31.2838,
    '626_418': 31.3505,
    '626_419': 31.3954,
    '626_420': 31.3863,
    '626_421': 31.3131,
    '626_422': 31.1956,
    '626_423': 31.0446,
    '626_424': 30.9022,
    '626_425': 30.7528,
    '626_426': 30.598,
    '626_427': 30.4475,
    '626_428': 30.3009,
    '626_432': 29.7839,
    '626_433': 29.6958,
    '626_434': 29.6151,
    '626_435': 29.5319,
    '626_436': 29.4173,
    '626_437': 29.2719,
    '626_438': 29.0956,
    '626_439': 28.9102,
    '626_440': 28.7102,
    '626_441': 28.5022,
    '626_442': 28.2879,
    '626_443': 28.0706,
    '626_444': 27.8474,
    '626_445': 27.6132,
    '627_405': 31.2215,
    '627_406': 31.2159,
    '627_407': 31.2088,
    '627_408': 31.195,
    '627_409': 31.1776,
    '627_410': 31.1136,
    '627_411': 31.0766,
    '627_414': 31.0754,
    '627_415': 31.1196,
    '627_416': 31.1689,
    '627_417': 31.2185,
    '627_418': 31.267,
    '627_419': 31.2991,
    '627_420': 31.2894,
    '627_421': 31.2302,
    '627_422': 31.1344,
    '627_423': 31.0056,
    '627_424': 30.8763,
    '627_425': 30.7351,
    '627_426': 30.5871,
    '627_427': 30.4402,
    '627_428': 30.2979,
    '627_432': 29.8121,
    '627_433': 29.7305,
    '627_434': 29.654,
    '627_435': 29.5711,
    '627_436': 29.4676,
    '627_437': 29.3298,
    '627_438': 29.1529,
    '627_439': 28.9724,
    '627_440': 28.7828,
    '627_441': 28.5832,
    '627_442': 28.3744,
    '627_443': 28.1585,
    '627_444': 27.9359,
    '627_445': 27.7066,
    '628_405': 31.2362,
    '628_406': 31.2372,
    '628_407': 31.2414,
    '628_408': 31.2067,
    '628_409': 31.1694,
    '628_410': 31.1076,
    '628_411': 31.068,
    '628_414': 31.0454,
    '628_415': 31.0795,
    '628_416': 31.118,
    '628_417': 31.1621,
    '628_418': 31.1976,
    '628_419': 31.2183,
    '628_420': 31.2085,
    '628_421': 31.1581,
    '628_422': 31.0755,
    '628_423': 30.9725,
    '628_424': 30.8525,
    '628_425': 30.7184,
    '628_426': 30.5734,
    '628_427': 30.4311,
    '628_428': 30.2936,
    '628_432': 29.8398,
    '628_433': 29.7618,
    '628_434': 29.6887,
    '628_435': 29.6007,
    '628_436': 29.5043,
    '628_437': 29.3716,
    '628_438': 29.2079,
    '628_439': 29.0328,
    '628_440': 28.8512,
    '628_441': 28.6591,
    '628_442': 28.4534,
    '628_443': 28.2395,
    '628_444': 28.0203,
    '628_445': 27.7929,
    '629_405': 31.2402,
    '629_406': 31.2342,
    '629_407': 31.2229,
    '629_408': 31.1863,
    '629_409': 31.1436,
    '629_410': 31.0937,
    '629_411': 31.0565,
    '629_414': 31.0292,
    '629_415': 31.0528,
    '629_416': 31.0833,
    '629_417': 31.116,
    '629_418': 31.1454,
    '629_419': 31.1565,
    '629_420': 31.1449,
    '629_421': 31.1036,
    '629_422': 31.0293,
    '629_423': 30.9386,
    '629_424': 30.8291,
    '629_425': 30.7014,
    '629_426': 30.5571,
    '629_427': 30.4224,
    '629_428': 30.2926,
    '629_432': 29.8666,
    '629_433': 29.7886,
    '629_434': 29.7104,
    '629_435': 29.624,
    '629_436': 29.5312,
    '629_437': 29.4124,
    '629_438': 29.2634,
    '629_439': 29.0928,
    '629_440': 28.9156,
    '629_441': 28.7279,
    '629_442': 28.5282,
    '629_443': 28.3201,
    '629_444': 28.107,
    '629_445': 27.8883,
    '630_405': 31.2373,
    '630_406': 31.2201,
    '630_407': 31.1971,
    '630_408': 31.1632,
    '630_409': 31.1253,
    '630_410': 31.0804,
    '630_411': 31.0416,
    '630_414': 31.0157,
    '630_415': 31.0329,
    '630_416': 31.0572,
    '630_417': 31.08,
    '630_418': 31.105,
    '630_419': 31.114,
    '630_420': 31.0998,
    '630_421': 31.054,
    '630_422': 30.9803,
    '630_423': 30.9024,
    '630_424': 30.7985,
    '630_425': 30.672,
    '630_426': 30.5376,
    '630_427': 30.4168,
    '630_428': 30.2985,
    '630_432': 29.8963,
    '630_433': 29.8185,
    '630_434': 29.7356,
    '630_435': 29.6482,
    '630_436': 29.5543,
    '630_437': 29.4483,
    '630_438': 29.3154,
    '630_439': 29.1521,
    '630_440': 28.979,
    '630_441': 28.7963,
    '630_442': 28.6017,
    '630_443': 28.3999,
    '630_444': 28.1955,
    '630_445': 27.9859,
    '631_432': 29.9282,
    '631_433': 29.8493,
    '631_434': 29.7679,
    '631_435': 29.6781,
    '631_436': 29.5841,
    '631_437': 29.4831,
    '631_438': 29.3626,
    '631_439': 29.2106,
    '631_440': 29.0439,
    '631_441': 28.8668,
    '631_442': 28.6768,
    '631_443': 28.4801,
    '631_444': 28.285,
    '631_445': 28.0832,
    '632_432': 29.9585,
    '632_433': 29.8807,
    '632_434': 29.801,
    '632_435': 29.7112,
    '632_436': 29.6168,
    '632_437': 29.5168,
    '632_438': 29.4051,
    '632_439': 29.2667,
    '632_440': 29.1114,
    '632_441': 28.9386,
    '632_442': 28.7529,
    '632_443': 28.5615,
    '632_444': 28.3682,
    '632_445': 28.1701,
    '633_432': 29.9962,
    '633_433': 29.9216,
    '633_434': 29.8402,
    '633_435': 29.75,
    '633_436': 29.6522,
    '633_437': 29.5527,
    '633_438': 29.4481,
    '633_439': 29.3266,
    '633_440': 29.1794,
    '633_441': 29.0104,
    '633_442': 28.8254,
    '633_443': 28.6367,
    '633_444': 28.4423,
    '633_445': 28.244,
    '634_432': 30.0419,
    '634_433': 29.9668,
    '634_434': 29.8809,
    '634_435': 29.7862,
    '634_436': 29.6881,
    '634_437': 29.59,
    '634_438': 29.4895,
    '634_439': 29.3825,
    '634_440': 29.2459,
    '634_441': 29.0793,
    '634_442': 28.8917,
    '634_443': 28.703,
    '634_444': 28.5092,
    '634_445': 28.3139,
    '635_432': 30.0846,
    '635_433': 30.0037,
    '635_434': 29.9136,
    '635_435': 29.8178,
    '635_436': 29.7254,
    '635_437': 29.6284,
    '635_438': 29.5298,
    '635_439': 29.4354,
    '635_440': 29.308,
    '635_441': 29.1443,
    '635_442': 28.9566,
    '635_443': 28.7666,
    '635_444': 28.5726,
    '635_445': 28.3833,
    '636_432': 30.1228,
    '636_433': 30.0374,
    '636_434': 29.9462,
    '636_435': 29.8535,
    '636_436': 29.762,
    '636_437': 29.6665,
    '636_438': 29.5748,
    '636_439': 29.4843,
    '636_440': 29.3628,
    '636_441': 29.2051,
    '636_442': 29.0248,
    '636_443': 28.8316,
    '636_444': 28.6413,
    '636_445': 28.4535,
    '637_432': 30.151,
    '637_433': 30.0661,
    '637_434': 29.9768,
    '637_435': 29.8864,
    '637_436': 29.7962,
    '637_437': 29.7025,
    '637_438': 29.6153,
    '637_439': 29.5279,
    '637_440': 29.4089,
    '637_441': 29.2549,
    '637_442': 29.082,
    '637_443': 28.888,
    '637_444': 28.7006,
    '637_445': 28.5133,
    '638_432': 30.1693,
    '638_433': 30.0893,
    '638_434': 30.0027,
    '638_435': 29.914,
    '638_436': 29.8268,
    '638_437': 29.7349,
    '638_438': 29.6504,
    '638_439': 29.5656,
    '638_440': 29.4464,
    '638_441': 29.294,
    '638_442': 29.1196,
    '638_443': 28.9352,
    '638_444': 28.7506,
    '638_445': 28.5651,
    '639_432': 30.1837,
    '639_433': 30.1092,
    '639_434': 30.0251,
    '639_435': 29.9372,
    '639_436': 29.8541,
    '639_437': 29.7625,
    '639_438': 29.6783,
    '639_439': 29.594,
    '639_440': 29.4747,
    '639_441': 29.3259,
    '639_442': 29.1516,
    '639_443': 28.9751,
    '639_444': 28.7935,
    '639_445': 28.612,
    '640_432': 30.1966,
    '640_433': 30.128,
    '640_434': 30.0466,
    '640_435': 29.9629,
    '640_436': 29.8765,
    '640_437': 29.7838,
    '640_438': 29.6977,
    '640_439': 29.6123,
    '640_440': 29.4962,
    '640_441': 29.355,
    '640_442': 29.189,
    '640_443': 29.01,
    '640_444': 28.8312,
    '640_445': 28.6492,
    '641_432': 30.2074,
    '641_433': 30.1447,
    '641_434': 30.062,
    '641_435': 29.9803,
    '641_436': 29.8949,
    '641_437': 29.8019,
    '641_438': 29.7123,
    '641_439': 29.6242,
    '641_440': 29.5133,
    '641_441': 29.3773,
    '641_442': 29.2199,
    '641_443': 29.0449,
    '641_444': 28.8668,
    '641_445': 28.6848,
    '642_432': 30.2091,
    '642_433': 30.1466,
    '642_434': 30.072,
    '642_435': 29.9935,
    '642_436': 29.9107,
    '642_437': 29.8187,
    '642_438': 29.7262,
    '642_439': 29.6328,
    '642_440': 29.5265,
    '642_441': 29.3966,
    '642_442': 29.2468,
    '642_443': 29.0791,
    '642_444': 28.9014,
    '642_445': 28.7211,
    '643_432': 30.2052,
    '643_433': 30.1442,
    '643_434': 30.0757,
    '643_435': 30.0015,
    '643_436': 29.9252,
    '643_437': 29.8377,
    '643_438': 29.7412,
    '643_439': 29.6445,
    '643_440': 29.5409,
    '643_441': 29.4186,
    '643_442': 29.2747,
    '643_443': 29.1074,
    '643_444': 28.9303,
    '643_445': 28.7495,
    '644_432': 30.2105,
    '644_433': 30.1494,
    '644_434': 30.0848,
    '644_435': 30.0125,
    '644_436': 29.9391,
    '644_437': 29.8535,
    '644_438': 29.7565,
    '644_439': 29.6573,
    '644_440': 29.5553,
    '644_441': 29.4427,
    '644_442': 29.302,
    '644_443': 29.1275,
    '644_444': 28.9525,
    '644_445': 28.7759,
    '645_432': 30.225,
    '645_433': 30.1594,
    '645_434': 30.0952,
    '645_435': 30.0247,
    '645_436': 29.9531,
    '645_437': 29.8686,
    '645_438': 29.7737,
    '645_439': 29.6692,
    '645_440': 29.5655,
    '645_441': 29.4743,
    '645_442': 29.324,
    '645_443': 29.1436,
    '645_444': 28.9716,
    '645_445': 28.7975,
    '646_410': 31.4156,
    '646_411': 31.4039,
    '646_412': 31.3704,
    '646_413': 31.3366,
    '646_432': 30.2385,
    '646_433': 30.1717,
    '646_434': 30.1068,
    '646_435': 30.0379,
    '646_436': 29.9672,
    '646_437': 29.8831,
    '646_438': 29.7876,
    '646_439': 29.6796,
    '646_440': 29.568,
    '646_441': 29.469,
    '646_442': 29.3308,
    '646_443': 29.1614,
    '646_444': 28.9942,
    '646_445': 28.8252,
    '647_410': 31.4183,
    '647_411': 31.418,
    '647_412': 31.4018,
    '647_413': 31.3593,
    '647_415': 31.1559,
    '647_416': 31.0967,
    '647_417': 31.051,
    '647_418': 31.0124,
    '647_432': 30.2525,
    '647_433': 30.1869,
    '647_434': 30.1226,
    '647_435': 30.054,
    '647_436': 29.9824,
    '647_437': 29.898,
    '647_438': 29.8009,
    '647_439': 29.6902,
    '647_440': 29.5761,
    '647_441': 29.4702,
    '647_442': 29.3388,
    '647_443': 29.1833,
    '647_444': 29.0166,
    '647_445': 28.8479,
    '648_396': 32.1463,
    '648_397': 32.1396,
    '648_398': 32.1147,
    '648_399': 32.0875,
    '648_410': 31.4197,
    '648_411': 31.4166,
    '648_412': 31.4072,
    '648_413': 31.3691,
    '648_415': 31.1945,
    '648_416': 31.1427,
    '648_417': 31.0989,
    '648_418': 31.0526,
    '648_432': 30.2638,
    '648_433': 30.2063,
    '648_434': 30.1444,
    '648_435': 30.0751,
    '648_436': 29.9978,
    '648_437': 29.9105,
    '648_438': 29.8108,
    '648_439': 29.699,
    '648_440': 29.5859,
    '648_441': 29.4744,
    '648_442': 29.3474,
    '648_443': 29.199,
    '648_444': 29.0386,
    '648_445': 28.8697,
    '649_396': 32.1632,
    '649_397': 32.169,
    '649_398': 32.1432,
    '649_399': 32.0954,
    '649_410': 31.4095,
    '649_411': 31.4043,
    '649_412': 31.3969,
    '649_413': 31.3694,
    '649_415': 31.2278,
    '649_416': 31.1817,
    '649_417': 31.1359,
    '649_418': 31.0805,
    '649_432': 30.2778,
    '649_433': 30.2241,
    '649_434': 30.1638,
    '649_435': 30.0931,
    '649_436': 30.0138,
    '649_437': 29.9128,
    '649_438': 29.8143,
    '649_439': 29.706,
    '649_440': 29.5898,
    '649_441': 29.4754,
    '649_442': 29.3544,
    '649_443': 29.2141,
    '649_444': 29.0636,
    '649_445': 28.8957,
    '650_396': 32.1667,
    '650_397': 32.1748,
    '650_398': 32.1445,
    '650_399': 32.0903,
    '650_415': 31.2459,
    '650_416': 31.2006,
    '650_417': 31.152,
    '650_418': 31.0917,
    '650_432': 30.2989,
    '650_433': 30.2443,
    '650_434': 30.1818,
    '650_435': 30.1056,
    '650_436': 30.0159,
    '650_437': 29.913,
    '650_438': 29.8146,
    '650_439': 29.7076,
    '650_440': 29.5903,
    '650_441': 29.4759,
    '650_442': 29.3574,
    '650_443': 29.2282,
    '650_444': 29.0881,
    '650_445': 28.9186,
    '651_396': 32.1571,
    '651_397': 32.1514,
    '651_398': 32.1228,
    '651_399': 32.0804,
    '651_432': 30.3212,
    '651_433': 30.261,
    '651_434': 30.1893,
    '651_435': 30.1144,
    '651_436': 30.0221,
    '651_437': 29.9238,
    '651_438': 29.8204,
    '651_439': 29.7098,
    '651_440': 29.5913,
    '651_441': 29.4771,
    '651_442': 29.361,
    '651_443': 29.2352,
    '651_444': 29.0958,
    '651_445': 28.9346,
    '652_396': 32.1417,
    '652_397': 32.1299,
    '652_398': 32.1054,
    '652_399': 32.0692,
    '652_432': 30.3431,
    '652_433': 30.272,
    '652_434': 30.197,
    '652_435': 30.1191,
    '652_436': 30.0286,
    '652_437': 29.9322,
    '652_438': 29.8214,
    '652_439': 29.7066,
    '652_440': 29.5947,
    '652_441': 29.4842,
    '652_442': 29.3697,
    '652_443': 29.2456,
    '652_444': 29.1079,
    '652_445': 28.9552,
    '653_432': 30.3687,
    '653_433': 30.2942,
    '653_434': 30.2082,
    '653_435': 30.1191,
    '653_436': 30.0269,
    '653_437': 29.9269,
    '653_438': 29.8186,
    '653_439': 29.7099,
    '653_440': 29.5992,
    '653_441': 29.4902,
    '653_442': 29.3778,
    '653_443': 29.2563,
    '653_444': 29.1226,
    '653_445': 28.9789,
    '657_400': 31.4806,
    '657_401': 31.4588,
    '657_402': 31.4458,
    '657_403': 31.4368,
    '657_404': 31.4275,
    '657_405': 31.4156,
    '657_406': 31.4013,
    '657_407': 31.3797,
    '657_408': 31.3554,
    '657_409': 31.3329,
    '657_410': 31.3149,
    '657_411': 31.2849,
    '657_412': 31.2535,
    '657_413': 31.217,
    '657_414': 31.1779,
    '657_415': 31.1286,
    '657_416': 31.0727,
    '657_417': 31.0152,
    '657_418': 30.974,
    '657_419': 30.9356,
    '657_420': 30.9093,
    '657_421': 30.906,
    '657_422': 30.9061,
    '657_423': 30.9157,
    '657_424': 30.9114,
    '657_425': 30.8962,
    '657_426': 30.8512,
    '657_427': 30.7924,
    '657_428': 30.7161,
    '657_429': 30.6292,
    '657_430': 30.5462,
    '657_431': 30.4494,
    '657_432': 30.3467,
    '657_433': 30.2434,
    '657_434': 30.1263,
    '657_435': 30.0042,
    '657_436': 29.8814,
    '657_437': 29.7593,
    '657_438': 29.6327,
    '657_439': 29.5054,
    '657_440': 29.3809,
    '657_441': 29.2536,
    '657_442': 29.1286,
    '657_443': 29.0011,
    '657_444': 28.8637,
    '657_445': 28.7236,
    '657_446': 28.5754,
    '657_447': 28.4154,
    '657_448': 28.2481,
    '657_449': 28.0712,
    '657_450': 27.8892,
    '657_451': 27.7047,
    '657_452': 27.5145,
    '657_453': 27.313,
    '657_454': 27.1168,
    '657_455': 26.9105,
    '657_456': 26.716,
    '657_457': 26.5535,
    '657_458': 26.4028,
    '657_459': 26.2786,
    '657_460': 26.1652,
    '657_461': 26.0778,
    '657_462': 26.0108,
    '657_463': 25.9613,
    '657_464': 25.9329,
    '658_400': 31.4967,
    '658_401': 31.474,
    '658_402': 31.4597,
    '658_403': 31.4511,
    '658_404': 31.4425,
    '658_405': 31.4283,
    '658_406': 31.4064,
    '658_407': 31.3817,
    '658_408': 31.3501,
    '658_409': 31.3224,
    '658_410': 31.2968,
    '658_411': 31.2749,
    '658_412': 31.2479,
    '658_413': 31.2148,
    '658_414': 31.1778,
    '658_415': 31.1203,
    '658_416': 31.0691,
    '658_417': 31.0167,
    '658_418': 30.9725,
    '658_419': 30.9425,
    '658_420': 30.9237,
    '658_421': 30.9262,
    '658_422': 30.9376,
    '658_423': 30.9454,
    '658_424': 30.9418,
    '658_425': 30.9175,
    '658_426': 30.8715,
    '658_427': 30.8221,
    '658_428': 30.7563,
    '658_429': 30.6738,
    '658_430': 30.5817,
    '658_431': 30.4903,
    '658_432': 30.3918,
    '658_433': 30.2852,
    '658_434': 30.1686,
    '658_435': 30.0489,
    '658_436': 29.9251,
    '658_437': 29.796,
    '658_438': 29.663,
    '658_439': 29.5302,
    '658_440': 29.401,
    '658_441': 29.2701,
    '658_442': 29.1371,
    '658_443': 29.0054,
    '658_444': 28.8685,
    '658_445': 28.7293,
    '658_446': 28.5835,
    '658_447': 28.4331,
    '658_448': 28.2774,
    '658_449': 28.1097,
    '658_450': 27.9352,
    '658_451': 27.7522,
    '658_452': 27.5619,
    '658_453': 27.3666,
    '658_454': 27.172,
    '658_455': 26.9699,
    '658_456': 26.7783,
    '658_457': 26.6021,
    '658_458': 26.4475,
    '658_459': 26.3189,
    '658_460': 26.2004,
    '658_461': 26.1112,
    '658_462': 26.039,
    '658_463': 25.9825,
    '658_464': 25.949,
    '659_400': 31.5226,
    '659_401': 31.4988,
    '659_402': 31.4805,
    '659_403': 31.4713,
    '659_404': 31.4645,
    '659_405': 31.4493,
    '659_406': 31.4228,
    '659_407': 31.3856,
    '659_408': 31.3491,
    '659_409': 31.3182,
    '659_410': 31.291,
    '659_411': 31.2677,
    '659_412': 31.2443,
    '659_413': 31.2134,
    '659_414': 31.1754,
    '659_415': 31.1226,
    '659_416': 31.0702,
    '659_417': 31.0137,
    '659_418': 30.9676,
    '659_419': 30.9266,
    '659_420': 30.933,
    '659_421': 30.9323,
    '659_422': 30.939,
    '659_423': 30.9509,
    '659_424': 30.9478,
    '659_425': 30.9221,
    '659_426': 30.888,
    '659_427': 30.8466,
    '659_428': 30.7908,
    '659_429': 30.7068,
    '659_430': 30.6135,
    '659_431': 30.5364,
    '659_432': 30.4415,
    '659_433': 30.3316,
    '659_434': 30.212,
    '659_435': 30.0895,
    '659_436': 29.9709,
    '659_437': 29.8389,
    '659_438': 29.7042,
    '659_439': 29.5678,
    '659_440': 29.4318,
    '659_441': 29.2953,
    '659_442': 29.1455,
    '659_443': 29.0074,
    '659_444': 28.8708,
    '659_445': 28.7305,
    '659_446': 28.5835,
    '659_447': 28.4444,
    '659_448': 28.3021,
    '659_449': 28.1431,
    '659_450': 27.9729,
    '659_451': 27.7915,
    '659_452': 27.606,
    '659_453': 27.4185,
    '659_454': 27.2255,
    '659_455': 27.0261,
    '659_456': 26.8309,
    '659_457': 26.6471,
    '659_458': 26.4919,
    '659_459': 26.3624,
    '659_460': 26.2466,
    '659_461': 26.1504,
    '659_462': 26.0654,
    '659_463': 26.0009,
    '659_464': 25.9578,
    '660_400': 31.5537,
    '660_401': 31.5285,
    '660_402': 31.5054,
    '660_403': 31.497,
    '660_404': 31.493,
    '660_405': 31.4754,
    '660_406': 31.4427,
    '660_407': 31.3982,
    '660_408': 31.3578,
    '660_409': 31.3206,
    '660_410': 31.2923,
    '660_411': 31.264,
    '660_412': 31.239,
    '660_413': 31.207,
    '660_414': 31.1637,
    '660_415': 31.1221,
    '660_416': 31.0661,
    '660_417': 31.0117,
    '660_418': 30.9594,
    '660_419': 30.93,
    '660_420': 30.9237,
    '660_421': 30.9219,
    '660_422': 30.9298,
    '660_423': 30.9408,
    '660_424': 30.9424,
    '660_425': 30.9227,
    '660_426': 30.9019,
    '660_427': 30.8726,
    '660_428': 30.8138,
    '660_429': 30.7404,
    '660_430': 30.6547,
    '660_431': 30.5762,
    '660_432': 30.4915,
    '660_433': 30.3885,
    '660_434': 30.2666,
    '660_435': 30.1315,
    '660_436': 30.0124,
    '660_437': 29.8808,
    '660_438': 29.7488,
    '660_439': 29.6066,
    '660_440': 29.4576,
    '660_441': 29.3117,
    '660_442': 29.1533,
    '660_443': 29.0155,
    '660_444': 28.8811,
    '660_445': 28.7241,
    '660_446': 28.5816,
    '660_447': 28.4551,
    '660_448': 28.3179,
    '660_449': 28.1615,
    '660_450': 27.9996,
    '660_451': 27.8222,
    '660_452': 27.6361,
    '660_453': 27.4608,
    '660_454': 27.2617,
    '660_455': 27.0739,
    '660_456': 26.8813,
    '660_457': 26.688,
    '660_458': 26.5382,
    '660_459': 26.406,
    '660_460': 26.2866,
    '660_461': 26.1839,
    '660_462': 26.0959,
    '660_463': 26.0261,
    '660_464': 25.9795,
    '661_400': 31.5883,
    '661_401': 31.5651,
    '661_402': 31.5449,
    '661_403': 31.5335,
    '661_404': 31.5235,
    '661_405': 31.5028,
    '661_406': 31.4686,
    '661_407': 31.4199,
    '661_408': 31.3732,
    '661_409': 31.3378,
    '661_410': 31.3076,
    '661_411': 31.2733,
    '661_412': 31.2391,
    '661_413': 31.202,
    '661_414': 31.1631,
    '661_415': 31.1141,
    '661_416': 31.0537,
    '661_417': 30.9961,
    '661_418': 30.9521,
    '661_419': 30.9233,
    '661_420': 30.9117,
    '661_421': 30.9079,
    '661_422': 30.9128,
    '661_423': 30.9234,
    '661_424': 30.9286,
    '661_425': 30.9154,
    '661_426': 30.9067,
    '661_427': 30.8929,
    '661_428': 30.8426,
    '661_429': 30.774,
    '661_430': 30.7009,
    '661_431': 30.6243,
    '661_432': 30.5406,
    '661_433': 30.4435,
    '661_434': 30.3305,
    '661_435': 30.1989,
    '661_436': 30.0674,
    '661_437': 29.9304,
    '661_438': 29.7988,
    '661_439': 29.6494,
    '661_440': 29.5004,
    '661_441': 29.3464,
    '661_442': 29.1903,
    '661_443': 29.0424,
    '661_444': 28.9053,
    '661_445': 28.7656,
    '661_446': 28.6304,
    '661_447': 28.4948,
    '661_448': 28.3481,
    '661_449': 28.196,
    '661_450': 28.0354,
    '661_451': 27.8662,
    '661_452': 27.6916,
    '661_453': 27.5105,
    '661_454': 27.3228,
    '661_455': 27.1385,
    '661_456': 26.9475,
    '661_457': 26.7586,
    '661_458': 26.5959,
    '661_459': 26.441,
    '661_460': 26.3144,
    '661_461': 26.217,
    '661_462': 26.127,
    '661_463': 26.0534,
    '661_464': 26.0004,
    '662_400': 31.6271,
    '662_401': 31.6083,
    '662_402': 31.5898,
    '662_403': 31.5736,
    '662_404': 31.5558,
    '662_405': 31.5292,
    '662_406': 31.4881,
    '662_407': 31.4393,
    '662_408': 31.3971,
    '662_409': 31.3627,
    '662_410': 31.3281,
    '662_411': 31.2864,
    '662_412': 31.239,
    '662_413': 31.2006,
    '662_414': 31.1531,
    '662_415': 31.107,
    '662_416': 31.0546,
    '662_417': 30.9877,
    '662_418': 30.9524,
    '662_419': 30.9211,
    '662_420': 30.9041,
    '662_421': 30.8969,
    '662_422': 30.8968,
    '662_423': 30.9076,
    '662_424': 30.9161,
    '662_425': 30.9062,
    '662_426': 30.9047,
    '662_427': 30.9046,
    '662_428': 30.8777,
    '662_429': 30.8162,
    '662_430': 30.7509,
    '662_431': 30.6764,
    '662_432': 30.5936,
    '662_433': 30.4991,
    '662_434': 30.3927,
    '662_435': 30.2711,
    '662_436': 30.1341,
    '662_437': 29.992,
    '662_438': 29.8477,
    '662_439': 29.6969,
    '662_440': 29.5432,
    '662_441': 29.3863,
    '662_442': 29.229,
    '662_443': 29.0743,
    '662_444': 28.9373,
    '662_445': 28.804,
    '662_446': 28.6575,
    '662_447': 28.5169,
    '662_448': 28.3719,
    '662_449': 28.2248,
    '662_450': 28.0683,
    '662_451': 27.9024,
    '662_452': 27.7328,
    '662_453': 27.5578,
    '662_454': 27.3819,
    '662_455': 27.2056,
    '662_456': 27.0169,
    '662_457': 26.8271,
    '662_458': 26.6543,
    '662_459': 26.4969,
    '662_460': 26.3619,
    '662_461': 26.2528,
    '662_462': 26.1527,
    '662_463': 26.0748,
    '662_464': 26.0156,
    '663_400': 31.6671,
    '663_401': 31.6506,
    '663_402': 31.6332,
    '663_403': 31.6136,
    '663_404': 31.5898,
    '663_405': 31.5565,
    '663_406': 31.5103,
    '663_407': 31.4598,
    '663_408': 31.4164,
    '663_409': 31.3868,
    '663_410': 31.3492,
    '663_411': 31.3028,
    '663_412': 31.249,
    '663_413': 31.2,
    '663_414': 31.1542,
    '663_415': 31.1038,
    '663_416': 31.0521,
    '663_417': 31.0044,
    '663_418': 30.9632,
    '663_419': 30.9226,
    '663_420': 30.886,
    '663_421': 30.8828,
    '663_422': 30.8892,
    '663_423': 30.8987,
    '663_424': 30.9019,
    '663_425': 30.8947,
    '663_426': 30.9022,
    '663_427': 30.9153,
    '663_428': 30.9074,
    '663_429': 30.8583,
    '663_430': 30.7972,
    '663_431': 30.7266,
    '663_432': 30.649,
    '663_433': 30.5576,
    '663_434': 30.4507,
    '663_435': 30.3298,
    '663_436': 30.1962,
    '663_437': 30.0514,
    '663_438': 29.8989,
    '663_439': 29.7457,
    '663_440': 29.588,
    '663_441': 29.4285,
    '663_442': 29.2701,
    '663_443': 29.1194,
    '663_444': 28.9734,
    '663_445': 28.8363,
    '663_446': 28.6926,
    '663_447': 28.551,
    '663_448': 28.4042,
    '663_449': 28.2556,
    '663_450': 28.0985,
    '663_451': 27.9344,
    '663_452': 27.77,
    '663_453': 27.602,
    '663_454': 27.4323,
    '663_455': 27.2575,
    '663_456': 27.0702,
    '663_457': 26.8817,
    '663_458': 26.7069,
    '663_459': 26.5504,
    '663_460': 26.4055,
    '663_461': 26.2779,
    '663_462': 26.169,
    '663_463': 26.0895,
    '663_464': 26.0254,
    '664_400': 31.7048,
    '664_401': 31.6895,
    '664_402': 31.6743,
    '664_403': 31.6534,
    '664_404': 31.6274,
    '664_405': 31.5901,
    '664_406': 31.5396,
    '664_407': 31.4836,
    '664_408': 31.4327,
    '664_409': 31.4096,
    '664_410': 31.3732,
    '664_411': 31.3244,
    '664_412': 31.2603,
    '664_413': 31.2019,
    '664_414': 31.1543,
    '664_415': 31.113,
    '664_416': 31.0712,
    '664_417': 31.0248,
    '664_418': 30.972,
    '664_419': 30.9263,
    '664_420': 30.8956,
    '664_421': 30.8807,
    '664_422': 30.8726,
    '664_423': 30.8782,
    '664_424': 30.8893,
    '664_425': 30.8856,
    '664_426': 30.8992,
    '664_427': 30.9211,
    '664_428': 30.9204,
    '664_429': 30.8932,
    '664_430': 30.8543,
    '664_431': 30.7933,
    '664_432': 30.705,
    '664_433': 30.6139,
    '664_434': 30.5072,
    '664_435': 30.3861,
    '664_436': 30.2537,
    '664_437': 30.1094,
    '664_438': 29.9586,
    '664_439': 29.8026,
    '664_440': 29.6385,
    '664_441': 29.4735,
    '664_442': 29.3081,
    '664_443': 29.1538,
    '664_444': 29.003,
    '664_445': 28.8656,
    '664_446': 28.7246,
    '664_447': 28.5831,
    '664_448': 28.4368,
    '664_449': 28.2837,
    '664_450': 28.1298,
    '664_451': 27.9699,
    '664_452': 27.8095,
    '664_453': 27.6462,
    '664_454': 27.4817,
    '664_455': 27.3069,
    '664_456': 27.1188,
    '664_457': 26.9292,
    '664_458': 26.7519,
    '664_459': 26.5947,
    '664_460': 26.4465,
    '664_461': 26.3123,
    '664_462': 26.1949,
    '664_463': 26.1033,
    '664_464': 26.035,
    '665_400': 31.7378,
    '665_401': 31.7229,
    '665_402': 31.7118,
    '665_403': 31.6902,
    '665_404': 31.6662,
    '665_405': 31.6271,
    '665_406': 31.5768,
    '665_407': 31.5239,
    '665_408': 31.4714,
    '665_409': 31.4418,
    '665_410': 31.4058,
    '665_411': 31.3534,
    '665_412': 31.286,
    '665_413': 31.2243,
    '665_414': 31.1869,
    '665_415': 31.1449,
    '665_416': 31.0969,
    '665_417': 31.0429,
    '665_418': 30.989,
    '665_419': 30.9418,
    '665_420': 30.9128,
    '665_421': 30.8938,
    '665_422': 30.8689,
    '665_423': 30.8741,
    '665_424': 30.8697,
    '665_425': 30.8685,
    '665_426': 30.8831,
    '665_427': 30.9049,
    '665_428': 30.913,
    '665_429': 30.9146,
    '665_430': 30.8987,
    '665_431': 30.8655,
    '665_432': 30.7796,
    '665_433': 30.677,
    '665_434': 30.5709,
    '665_435': 30.4475,
    '665_436': 30.3152,
    '665_437': 30.1688,
    '665_438': 30.0144,
    '665_439': 29.859,
    '665_440': 29.6998,
    '665_441': 29.5241,
    '665_442': 29.3561,
    '665_443': 29.1979,
    '665_444': 29.0442,
    '665_445': 28.8999,
    '665_446': 28.7582,
    '665_447': 28.6148,
    '665_448': 28.4652,
    '665_449': 28.3091,
    '665_450': 28.1602,
    '665_451': 28.0103,
    '665_452': 27.8524,
    '665_453': 27.6918,
    '665_454': 27.5278,
    '665_455': 27.354,
    '665_456': 27.1677,
    '665_457': 26.9807,
    '665_458': 26.8038,
    '665_459': 26.641,
    '665_460': 26.4838,
    '665_461': 26.3394,
    '665_462': 26.2216,
    '665_463': 26.1332,
    '665_464': 26.0488,
    '666_400': 31.7675,
    '666_401': 31.7536,
    '666_402': 31.739,
    '666_403': 31.7201,
    '666_404': 31.6985,
    '666_405': 31.667,
    '666_406': 31.6257,
    '666_407': 31.5815,
    '666_408': 31.5327,
    '666_409': 31.4941,
    '666_410': 31.4588,
    '666_411': 31.4033,
    '666_412': 31.3355,
    '666_413': 31.282,
    '666_414': 31.2352,
    '666_415': 31.1841,
    '666_416': 31.1244,
    '666_417': 31.0713,
    '666_418': 31.0221,
    '666_419': 30.9787,
    '666_420': 30.9353,
    '666_421': 30.9007,
    '666_422': 30.8763,
    '666_423': 30.8648,
    '666_424': 30.8525,
    '666_425': 30.8477,
    '666_426': 30.8509,
    '666_427': 30.8664,
    '666_428': 30.8857,
    '666_429': 30.9124,
    '666_430': 30.9238,
    '666_431': 30.9134,
    '666_432': 30.8578,
    '666_433': 30.7616,
    '666_434': 30.6656,
    '666_435': 30.5397,
    '666_436': 30.3893,
    '666_437': 30.2353,
    '666_438': 30.0789,
    '666_439': 29.9181,
    '666_440': 29.751,
    '666_441': 29.5844,
    '666_442': 29.4152,
    '666_443': 29.2507,
    '666_444': 29.0936,
    '666_445': 28.9425,
    '666_446': 28.7955,
    '666_447': 28.6488,
    '666_448': 28.4957,
    '666_449': 28.3457,
    '666_450': 28.1965,
    '666_451': 28.0484,
    '666_452': 27.8955,
    '666_453': 27.7362,
    '666_454': 27.572,
    '666_455': 27.3982,
    '666_456': 27.2129,
    '666_457': 27.0283,
    '666_458': 26.8517,
    '666_459': 26.6836,
    '666_460': 26.5178,
    '666_461': 26.365,
    '666_462': 26.248,
    '666_463': 26.1508,
    '666_464': 26.0669,
    '667_400': 31.7933,
    '667_401': 31.7799,
    '667_402': 31.7619,
    '667_403': 31.7465,
    '667_404': 31.7318,
    '667_405': 31.7092,
    '667_406': 31.6744,
    '667_407': 31.6327,
    '667_408': 31.5875,
    '667_409': 31.5458,
    '667_410': 31.5084,
    '667_411': 31.4601,
    '667_412': 31.401,
    '667_413': 31.3496,
    '667_414': 31.2871,
    '667_415': 31.2266,
    '667_416': 31.1694,
    '667_417': 31.1113,
    '667_418': 31.057,
    '667_419': 31.0125,
    '667_420': 30.9702,
    '667_421': 30.9329,
    '667_422': 30.8969,
    '667_423': 30.868,
    '667_424': 30.8418,
    '667_425': 30.8256,
    '667_426': 30.8188,
    '667_427': 30.8285,
    '667_428': 30.8464,
    '667_429': 30.8802,
    '667_430': 30.9245,
    '667_431': 30.9432,
    '667_432': 30.9169,
    '667_433': 30.8452,
    '667_434': 30.7561,
    '667_435': 30.6439,
    '667_436': 30.4936,
    '667_437': 30.3167,
    '667_438': 30.1486,
    '667_439': 29.9823,
    '667_440': 29.8123,
    '667_441': 29.638,
    '667_442': 29.4757,
    '667_443': 29.3119,
    '667_444': 29.1479,
    '667_445': 28.9881,
    '667_446': 28.835,
    '667_447': 28.6855,
    '667_448': 28.5376,
    '667_449': 28.3896,
    '667_450': 28.237,
    '667_451': 28.0894,
    '667_452': 27.9357,
    '667_453': 27.7764,
    '667_454': 27.6096,
    '667_455': 27.4346,
    '667_456': 27.2519,
    '667_457': 27.0712,
    '667_458': 26.8975,
    '667_459': 26.7265,
    '667_460': 26.5608,
    '667_461': 26.4107,
    '667_462': 26.2771,
    '667_463': 26.1668,
    '667_464': 26.0845,
    '668_400': 31.8167,
    '668_401': 31.804,
    '668_402': 31.7826,
    '668_403': 31.7715,
    '668_404': 31.7674,
    '668_405': 31.7561,
    '668_406': 31.7291,
    '668_407': 31.6933,
    '668_408': 31.6499,
    '668_409': 31.6062,
    '668_410': 31.5618,
    '668_411': 31.5159,
    '668_412': 31.4665,
    '668_413': 31.4075,
    '668_414': 31.3448,
    '668_415': 31.2804,
    '668_416': 31.2201,
    '668_417': 31.159,
    '668_418': 31.1001,
    '668_419': 31.0545,
    '668_420': 31.014,
    '668_421': 30.974,
    '668_422': 30.9313,
    '668_423': 30.8899,
    '668_424': 30.8527,
    '668_425': 30.8214,
    '668_426': 30.8014,
    '668_427': 30.804,
    '668_428': 30.813,
    '668_429': 30.848,
    '668_430': 30.909,
    '668_431': 30.9599,
    '668_432': 30.9467,
    '668_433': 30.8907,
    '668_434': 30.8096,
    '668_435': 30.7107,
    '668_436': 30.5827,
    '668_437': 30.416,
    '668_438': 30.2418,
    '668_439': 30.0512,
    '668_440': 29.8775,
    '668_441': 29.706,
    '668_442': 29.5385,
    '668_443': 29.3721,
    '668_444': 29.2043,
    '668_445': 29.041,
    '668_446': 28.8806,
    '668_447': 28.7277,
    '668_448': 28.5811,
    '668_449': 28.4306,
    '668_450': 28.2818,
    '668_451': 28.1348,
    '668_452': 27.9771,
    '668_453': 27.817,
    '668_454': 27.6475,
    '668_455': 27.471,
    '668_456': 27.2913,
    '668_457': 27.1109,
    '668_458': 26.9372,
    '668_459': 26.7684,
    '668_460': 26.6035,
    '668_461': 26.4492,
    '668_462': 26.3089,
    '668_463': 26.1881,
    '668_464': 26.0917,
    '669_400': 31.8372,
    '669_401': 31.8283,
    '669_402': 31.8119,
    '669_403': 31.8009,
    '669_404': 31.8018,
    '669_405': 31.8038,
    '669_406': 31.7875,
    '669_407': 31.7539,
    '669_408': 31.7062,
    '669_409': 31.6629,
    '669_410': 31.6302,
    '669_411': 31.5855,
    '669_412': 31.5303,
    '669_413': 31.4697,
    '669_414': 31.4066,
    '669_415': 31.3426,
    '669_416': 31.2797,
    '669_417': 31.2163,
    '669_418': 31.1534,
    '669_419': 31.1065,
    '669_420': 31.0683,
    '669_421': 31.0293,
    '669_422': 30.9786,
    '669_423': 30.9292,
    '669_424': 30.88,
    '669_425': 30.8377,
    '669_426': 30.8024,
    '669_427': 30.7894,
    '669_428': 30.7924,
    '669_429': 30.825,
    '669_430': 30.8865,
    '669_431': 30.9545,
    '669_432': 30.9481,
    '669_433': 30.9001,
    '669_434': 30.8261,
    '669_435': 30.7416,
    '669_436': 30.6395,
    '669_437': 30.4995,
    '669_438': 30.3274,
    '669_439': 30.1306,
    '669_440': 29.9452,
    '669_441': 29.7747,
    '669_442': 29.6027,
    '669_443': 29.4361,
    '669_444': 29.2641,
    '669_445': 29.0988,
    '669_446': 28.9347,
    '669_447': 28.7754,
    '669_448': 28.6254,
    '669_449': 28.4768,
    '669_450': 28.3259,
    '669_451': 28.1769,
    '669_452': 28.0229,
    '669_453': 27.8628,
    '669_454': 27.6886,
    '669_455': 27.5057,
    '669_456': 27.328,
    '669_457': 27.1474,
    '669_458': 26.9738,
    '669_459': 26.8083,
    '669_460': 26.6435,
    '669_461': 26.4844,
    '669_462': 26.3375,
    '669_463': 26.2035,
    '669_464': 26.0982,
    '670_400': 31.8516,
    '670_401': 31.8483,
    '670_402': 31.8364,
    '670_403': 31.8303,
    '670_404': 31.8367,
    '670_405': 31.8459,
    '670_406': 31.84,
    '670_407': 31.7995,
    '670_408': 31.7553,
    '670_409': 31.7308,
    '670_410': 31.7028,
    '670_411': 31.6547,
    '670_412': 31.5967,
    '670_413': 31.5363,
    '670_414': 31.4732,
    '670_415': 31.4087,
    '670_416': 31.3434,
    '670_417': 31.2772,
    '670_418': 31.211,
    '670_419': 31.165,
    '670_420': 31.1317,
    '670_421': 31.1061,
    '670_422': 31.0464,
    '670_423': 30.9884,
    '670_424': 30.9281,
    '670_425': 30.8729,
    '670_426': 30.8264,
    '670_427': 30.7982,
    '670_428': 30.7914,
    '670_429': 30.818,
    '670_430': 30.8719,
    '670_431': 30.9364,
    '670_432': 30.9368,
    '670_433': 30.8898,
    '670_434': 30.8285,
    '670_435': 30.7595,
    '670_436': 30.6736,
    '670_437': 30.5566,
    '670_438': 30.3991,
    '670_439': 30.2067,
    '670_440': 30.0122,
    '670_441': 29.837,
    '670_442': 29.6613,
    '670_443': 29.4839,
    '670_444': 29.3118,
    '670_445': 29.146,
    '670_446': 28.9794,
    '670_447': 28.8239,
    '670_448': 28.671,
    '670_449': 28.522,
    '670_450': 28.3719,
    '670_451': 28.2212,
    '670_452': 28.0691,
    '670_453': 27.9103,
    '670_454': 27.7369,
    '670_455': 27.5555,
    '670_456': 27.3741,
    '670_457': 27.189,
    '670_458': 27.0105,
    '670_459': 26.8471,
    '670_460': 26.6811,
    '670_461': 26.5188,
    '670_462': 26.3669,
    '670_463': 26.2268,
    '670_464': 26.1086,
    '671_400': 31.8661,
    '671_401': 31.867,
    '671_402': 31.8645,
    '671_403': 31.8596,
    '671_404': 31.8725,
    '671_405': 31.8935,
    '671_406': 31.8863,
    '671_407': 31.8459,
    '671_408': 31.8213,
    '671_409': 31.808,
    '671_410': 31.7786,
    '671_411': 31.7287,
    '671_412': 31.6697,
    '671_413': 31.6073,
    '671_414': 31.5431,
    '671_415': 31.4781,
    '671_416': 31.4129,
    '671_417': 31.349,
    '671_418': 31.2894,
    '671_419': 31.2408,
    '671_420': 31.2074,
    '671_421': 31.1867,
    '671_422': 31.1191,
    '671_423': 31.0529,
    '671_424': 30.9861,
    '671_425': 30.9149,
    '671_426': 30.858,
    '671_427': 30.8227,
    '671_428': 30.8069,
    '671_429': 30.8246,
    '671_430': 30.8671,
    '671_431': 30.9151,
    '671_432': 30.9186,
    '671_433': 30.8824,
    '671_434': 30.8339,
    '671_435': 30.7794,
    '671_436': 30.7064,
    '671_437': 30.6047,
    '671_438': 30.4638,
    '671_439': 30.2823,
    '671_440': 30.0799,
    '671_441': 29.8975,
    '671_442': 29.7218,
    '671_443': 29.5411,
    '671_444': 29.3598,
    '671_445': 29.1929,
    '671_446': 29.0297,
    '671_447': 28.8613,
    '671_448': 28.7031,
    '671_449': 28.5696,
    '671_450': 28.4187,
    '671_451': 28.266,
    '671_452': 28.1143,
    '671_453': 27.9563,
    '671_454': 27.786,
    '671_455': 27.6059,
    '671_456': 27.4192,
    '671_457': 27.2326,
    '671_458': 27.0537,
    '671_459': 26.8711,
    '671_460': 26.7052,
    '671_461': 26.544,
    '671_462': 26.3885,
    '671_463': 26.2428,
    '671_464': 26.1217,
    '672_400': 31.8835,
    '672_401': 31.8836,
    '672_402': 31.8854,
    '672_403': 31.8808,
    '672_404': 31.9044,
    '672_405': 31.9334,
    '672_406': 31.9126,
    '672_407': 31.9083,
    '672_408': 31.8937,
    '672_409': 31.8792,
    '672_410': 31.8471,
    '672_411': 31.7985,
    '672_412': 31.7409,
    '672_413': 31.6784,
    '672_414': 31.6137,
    '672_415': 31.5486,
    '672_416': 31.4847,
    '672_417': 31.4242,
    '672_418': 31.3694,
    '672_419': 31.3206,
    '672_420': 31.2842,
    '672_421': 31.2516,
    '672_422': 31.1841,
    '672_423': 31.1196,
    '672_424': 31.0455,
    '672_425': 30.9648,
    '672_426': 30.8981,
    '672_427': 30.8536,
    '672_428': 30.8288,
    '672_429': 30.8413,
    '672_430': 30.8753,
    '672_431': 30.9041,
    '672_432': 30.9067,
    '672_433': 30.8805,
    '672_434': 30.842,
    '672_435': 30.7985,
    '672_436': 30.7416,
    '672_437': 30.6487,
    '672_438': 30.5119,
    '672_439': 30.3443,
    '672_440': 30.1476,
    '672_441': 29.955,
    '672_442': 29.7786,
    '672_443': 29.5968,
    '672_444': 29.4131,
    '672_445': 29.2388,
    '672_446': 29.0695,
    '672_447': 28.9042,
    '672_448': 28.7533,
    '672_449': 28.6095,
    '672_450': 28.4633,
    '672_451': 28.3153,
    '672_452': 28.1616,
    '672_453': 28.0032,
    '672_454': 27.8311,
    '672_455': 27.6498,
    '672_456': 27.4598,
    '672_457': 27.2721,
    '672_458': 27.0896,
    '672_459': 26.9078,
    '672_460': 26.7321,
    '672_461': 26.5642,
    '672_462': 26.4032,
    '672_463': 26.2628,
    '672_464': 26.1401,
    '673_400': 31.8957,
    '673_401': 31.9042,
    '673_402': 31.9066,
    '673_403': 31.908,
    '673_404': 31.9198,
    '673_405': 31.9418,
    '673_406': 31.955,
    '673_407': 31.9636,
    '673_408': 31.9566,
    '673_409': 31.9422,
    '673_410': 31.9094,
    '673_411': 31.8638,
    '673_412': 31.8093,
    '673_413': 31.7484,
    '673_414': 31.6843,
    '673_415': 31.6189,
    '673_416': 31.5558,
    '673_417': 31.4976,
    '673_418': 31.4453,
    '673_419': 31.3982,
    '673_420': 31.3619,
    '673_421': 31.3248,
    '673_422': 31.2484,
    '673_423': 31.179,
    '673_424': 31.1063,
    '673_425': 31.0162,
    '673_426': 30.9364,
    '673_427': 30.8879,
    '673_428': 30.8621,
    '673_429': 30.8662,
    '673_430': 30.888,
    '673_431': 30.9013,
    '673_432': 30.9016,
    '673_433': 30.8839,
    '673_434': 30.8506,
    '673_435': 30.8127,
    '673_436': 30.7649,
    '673_437': 30.6844,
    '673_438': 30.5521,
    '673_439': 30.3862,
    '673_440': 30.2003,
    '673_441': 30.0181,
    '673_442': 29.8424,
    '673_443': 29.6591,
    '673_444': 29.4751,
    '673_445': 29.2922,
    '673_446': 29.1179,
    '673_447': 28.9593,
    '673_448': 28.8131,
    '673_449': 28.6597,
    '673_450': 28.5095,
    '673_451': 28.3626,
    '673_452': 28.216,
    '673_453': 28.0509,
    '673_454': 27.8769,
    '673_455': 27.6921,
    '673_456': 27.495,
    '673_457': 27.3078,
    '673_458': 27.123,
    '673_459': 26.9393,
    '673_460': 26.7585,
    '673_461': 26.584,
    '673_462': 26.4227,
    '673_463': 26.282,
    '673_464': 26.1551,
    '674_400': 31.9029,
    '674_401': 31.9119,
    '674_402': 31.9151,
    '674_403': 31.9196,
    '674_404': 31.9309,
    '674_405': 31.9619,
    '674_406': 31.9894,
    '674_407': 32.0033,
    '674_408': 32.0044,
    '674_409': 31.9929,
    '674_410': 31.9656,
    '674_411': 31.9252,
    '674_412': 31.8752,
    '674_413': 31.8177,
    '674_414': 31.7544,
    '674_415': 31.6882,
    '674_416': 31.6248,
    '674_417': 31.5677,
    '674_418': 31.5147,
    '674_419': 31.4706,
    '674_420': 31.4345,
    '674_421': 31.3943,
    '674_422': 31.3121,
    '674_423': 31.2305,
    '674_424': 31.1586,
    '674_425': 31.0557,
    '674_426': 30.9632,
    '674_427': 30.9181,
    '674_428': 30.8989,
    '674_429': 30.8928,
    '674_430': 30.899,
    '674_431': 30.9046,
    '674_432': 30.9026,
    '674_433': 30.8858,
    '674_434': 30.8534,
    '674_435': 30.8152,
    '674_436': 30.7743,
    '674_437': 30.702,
    '674_438': 30.5853,
    '674_439': 30.4386,
    '674_440': 30.2703,
    '674_441': 30.1063,
    '674_442': 29.9304,
    '674_443': 29.7339,
    '674_444': 29.543,
    '674_445': 29.3551,
    '674_446': 29.1771,
    '674_447': 29.0148,
    '674_448': 28.8679,
    '674_449': 28.7209,
    '674_450': 28.5613,
    '674_451': 28.4136,
    '674_452': 28.2631,
    '674_453': 28.0977,
    '674_454': 27.9206,
    '674_455': 27.7296,
    '674_456': 27.5328,
    '674_457': 27.348,
    '674_458': 27.1581,
    '674_459': 26.9691,
    '674_460': 26.7821,
    '674_461': 26.6021,
    '674_462': 26.4353,
    '674_463': 26.3005,
    '674_464': 26.1699,
    '675_400': 31.9128,
    '675_401': 31.9236,
    '675_402': 31.9304,
    '675_403': 31.9407,
    '675_404': 31.9613,
    '675_405': 31.988,
    '675_406': 32.0178,
    '675_407': 32.0351,
    '675_408': 32.0449,
    '675_409': 32.0423,
    '675_410': 32.0225,
    '675_411': 31.9861,
    '675_412': 31.9401,
    '675_413': 31.888,
    '675_414': 31.8261,
    '675_415': 31.7583,
    '675_416': 31.6938,
    '675_417': 31.6374,
    '675_418': 31.5865,
    '675_419': 31.543,
    '675_420': 31.5073,
    '675_421': 31.4658,
    '675_422': 31.3812,
    '675_423': 31.2835,
    '675_424': 31.1907,
    '675_425': 31.0739,
    '675_426': 30.9802,
    '675_427': 30.9349,
    '675_428': 30.929,
    '675_429': 30.9175,
    '675_430': 30.9074,
    '675_431': 30.9065,
    '675_432': 30.9071,
    '675_433': 30.8906,
    '675_434': 30.8598,
    '675_435': 30.8163,
    '675_436': 30.7711,
    '675_437': 30.6982,
    '675_438': 30.5997,
    '675_439': 30.4862,
    '675_440': 30.3455,
    '675_441': 30.1817,
    '675_442': 29.997,
    '675_443': 29.7994,
    '675_444': 29.6035,
    '675_445': 29.4134,
    '675_446': 29.2353,
    '675_447': 29.0695,
    '675_448': 28.9169,
    '675_449': 28.7686,
    '675_450': 28.6155,
    '675_451': 28.4683,
    '675_452': 28.3129,
    '675_453': 28.1439,
    '675_454': 27.9591,
    '675_455': 27.7721,
    '675_456': 27.5747,
    '675_457': 27.381,
    '675_458': 27.1899,
    '675_459': 26.9973,
    '675_460': 26.8075,
    '675_461': 26.6279,
    '675_462': 26.4627,
    '675_463': 26.3157,
    '675_464': 26.1796,
    '676_400': 31.9303,
    '676_401': 31.9456,
    '676_402': 31.9593,
    '676_403': 31.9717,
    '676_404': 31.991,
    '676_405': 32.0186,
    '676_406': 32.0431,
    '676_407': 32.0635,
    '676_408': 32.0828,
    '676_409': 32.0908,
    '676_410': 32.0825,
    '676_411': 32.0455,
    '676_412': 32.0048,
    '676_413': 31.9638,
    '676_414': 31.9041,
    '676_415': 31.8393,
    '676_416': 31.7768,
    '676_417': 31.7187,
    '676_418': 31.666,
    '676_419': 31.6208,
    '676_420': 31.5801,
    '676_421': 31.5308,
    '676_422': 31.4446,
    '676_423': 31.3285,
    '676_424': 31.2107,
    '676_425': 31.0877,
    '676_426': 30.9946,
    '676_427': 30.943,
    '676_428': 30.946,
    '676_429': 30.9238,
    '676_430': 30.9076,
    '676_431': 30.9059,
    '676_432': 30.9105,
    '676_433': 30.9013,
    '676_434': 30.8695,
    '676_435': 30.8157,
    '676_436': 30.755,
    '676_437': 30.6784,
    '676_438': 30.5941,
    '676_439': 30.514,
    '676_440': 30.3966,
    '676_441': 30.2295,
    '676_442': 30.0337,
    '676_443': 29.8411,
    '676_444': 29.6609,
    '676_445': 29.4719,
    '676_446': 29.2945,
    '676_447': 29.1303,
    '676_448': 28.9679,
    '676_449': 28.8195,
    '676_450': 28.6726,
    '676_451': 28.5251,
    '676_452': 28.3624,
    '676_453': 28.1868,
    '676_454': 28.0033,
    '676_455': 27.8165,
    '676_456': 27.6212,
    '676_457': 27.4264,
    '676_458': 27.2301,
    '676_459': 27.0287,
    '676_460': 26.8341,
    '676_461': 26.6515,
    '676_462': 26.4826,
    '676_463': 26.3312,
    '676_464': 26.192,
    '677_400': 31.9591,
    '677_401': 31.9732,
    '677_402': 31.989,
    '677_403': 32.0023,
    '677_404': 32.0224,
    '677_405': 32.0415,
    '677_406': 32.069,
    '677_407': 32.0978,
    '677_408': 32.1243,
    '677_409': 32.1391,
    '677_410': 32.1326,
    '677_411': 32.1008,
    '677_412': 32.0624,
    '677_413': 32.0316,
    '677_414': 31.979,
    '677_415': 31.9184,
    '677_416': 31.8581,
    '677_417': 31.7991,
    '677_418': 31.7457,
    '677_419': 31.6973,
    '677_420': 31.6502,
    '677_421': 31.5911,
    '677_422': 31.4928,
    '677_423': 31.3604,
    '677_424': 31.2258,
    '677_425': 31.1043,
    '677_426': 31.0113,
    '677_427': 30.9489,
    '677_428': 30.9221,
    '677_429': 30.8996,
    '677_430': 30.9,
    '677_431': 30.9037,
    '677_432': 30.9167,
    '677_433': 30.9133,
    '677_434': 30.8796,
    '677_435': 30.8181,
    '677_436': 30.7408,
    '677_437': 30.6605,
    '677_438': 30.5844,
    '677_439': 30.5224,
    '677_440': 30.4234,
    '677_441': 30.2615,
    '677_442': 30.0712,
    '677_443': 29.8798,
    '677_444': 29.6985,
    '677_445': 29.5186,
    '677_446': 29.3412,
    '677_447': 29.1752,
    '677_448': 29.0189,
    '677_449': 28.8713,
    '677_450': 28.7243,
    '677_451': 28.5733,
    '677_452': 28.4137,
    '677_453': 28.2367,
    '677_454': 28.0535,
    '677_455': 27.8668,
    '677_456': 27.672,
    '677_457': 27.478,
    '677_458': 27.2715,
    '677_459': 27.0602,
    '677_460': 26.8656,
    '677_461': 26.6711,
    '677_462': 26.4957,
    '677_463': 26.3401,
    '677_464': 26.2017,
    '678_400': 31.9949,
    '678_401': 32.0112,
    '678_402': 32.0298,
    '678_403': 32.0395,
    '678_404': 32.0571,
    '678_405': 32.0763,
    '678_406': 32.1001,
    '678_407': 32.13,
    '678_408': 32.1578,
    '678_409': 32.179,
    '678_410': 32.1822,
    '678_411': 32.147,
    '678_412': 32.1144,
    '678_413': 32.0861,
    '678_414': 32.0422,
    '678_415': 31.9871,
    '678_416': 31.9269,
    '678_417': 31.8684,
    '678_418': 31.8168,
    '678_419': 31.7684,
    '678_420': 31.7183,
    '678_421': 31.6474,
    '678_422': 31.5282,
    '678_423': 31.3763,
    '678_424': 31.2412,
    '678_425': 31.1252,
    '678_426': 31.03,
    '678_427': 30.9575,
    '678_428': 30.9142,
    '678_429': 30.8967,
    '678_430': 30.9006,
    '678_431': 30.9072,
    '678_432': 30.925,
    '678_433': 30.9254,
    '678_434': 30.8877,
    '678_435': 30.8161,
    '678_436': 30.7283,
    '678_437': 30.6478,
    '678_438': 30.5743,
    '678_439': 30.5122,
    '678_440': 30.4321,
    '678_441': 30.2977,
    '678_442': 30.1341,
    '678_443': 29.9439,
    '678_444': 29.7389,
    '678_445': 29.5539,
    '678_446': 29.3775,
    '678_447': 29.2103,
    '678_448': 29.064,
    '678_449': 28.9193,
    '678_450': 28.77,
    '678_451': 28.6149,
    '678_452': 28.4549,
    '678_453': 28.2872,
    '678_454': 28.1033,
    '678_455': 27.9145,
    '678_456': 27.7177,
    '678_457': 27.5136,
    '678_458': 27.3012,
    '678_459': 27.0834,
    '678_460': 26.8817,
    '678_461': 26.6842,
    '678_462': 26.5119,
    '678_463': 26.3584,
    '678_464': 26.2105,
    '679_400': 32.034,
    '679_401': 32.0514,
    '679_402': 32.0671,
    '679_403': 32.0813,
    '679_404': 32.0953,
    '679_405': 32.1145,
    '679_406': 32.1373,
    '679_407': 32.1632,
    '679_408': 32.1916,
    '679_409': 32.2144,
    '679_410': 32.2183,
    '679_411': 32.1863,
    '679_412': 32.1594,
    '679_413': 32.1297,
    '679_414': 32.0875,
    '679_415': 32.0397,
    '679_416': 31.9804,
    '679_417': 31.924,
    '679_418': 31.8758,
    '679_419': 31.8314,
    '679_420': 31.7742,
    '679_421': 31.686,
    '679_422': 31.5527,
    '679_423': 31.3984,
    '679_424': 31.2682,
    '679_425': 31.155,
    '679_426': 31.0555,
    '679_427': 30.9762,
    '679_428': 30.9233,
    '679_429': 30.9081,
    '679_430': 30.9118,
    '679_431': 30.9167,
    '679_432': 30.9281,
    '679_433': 30.9231,
    '679_434': 30.8787,
    '679_435': 30.8049,
    '679_436': 30.7215,
    '679_437': 30.6387,
    '679_438': 30.5602,
    '679_439': 30.4928,
    '679_440': 30.4249,
    '679_441': 30.312,
    '679_442': 30.167,
    '679_443': 29.9882,
    '679_444': 29.7827,
    '679_445': 29.5913,
    '679_446': 29.4175,
    '679_447': 29.2544,
    '679_448': 29.1125,
    '679_449': 28.966,
    '679_450': 28.818,
    '679_451': 28.6656,
    '679_452': 28.5053,
    '679_453': 28.3364,
    '679_454': 28.1551,
    '679_455': 27.9626,
    '679_456': 27.7611,
    '679_457': 27.556,
    '679_458': 27.3408,
    '679_459': 27.1185,
    '679_460': 26.9007,
    '679_461': 26.7137,
    '679_462': 26.5427,
    '679_463': 26.3819,
    '679_464': 26.2279,
    '680_400': 32.0774,
    '680_401': 32.0861,
    '680_402': 32.1021,
    '680_403': 32.1224,
    '680_404': 32.1356,
    '680_405': 32.1518,
    '680_406': 32.1714,
    '680_407': 32.1925,
    '680_408': 32.218,
    '680_409': 32.2452,
    '680_410': 32.2438,
    '680_411': 32.2187,
    '680_412': 32.1994,
    '680_413': 32.1636,
    '680_414': 32.1163,
    '680_415': 32.0726,
    '680_416': 32.0183,
    '680_417': 31.9678,
    '680_418': 31.9231,
    '680_419': 31.8818,
    '680_420': 31.8163,
    '680_421': 31.7071,
    '680_422': 31.5674,
    '680_423': 31.428,
    '680_424': 31.3043,
    '680_425': 31.1844,
    '680_426': 31.0788,
    '680_427': 30.9954,
    '680_428': 30.943,
    '680_429': 30.9293,
    '680_430': 30.9327,
    '680_431': 30.9345,
    '680_432': 30.9365,
    '680_433': 30.9233,
    '680_434': 30.8736,
    '680_435': 30.8016,
    '680_436': 30.7207,
    '680_437': 30.6354,
    '680_438': 30.5515,
    '680_439': 30.4734,
    '680_440': 30.4004,
    '680_441': 30.2974,
    '680_442': 30.1608,
    '680_443': 29.9868,
    '680_444': 29.8026,
    '680_445': 29.6271,
    '680_446': 29.462,
    '680_447': 29.3057,
    '680_448': 29.1605,
    '680_449': 29.0145,
    '680_450': 28.8667,
    '680_451': 28.7149,
    '680_452': 28.5555,
    '680_453': 28.3849,
    '680_454': 28.2041,
    '680_455': 28.0134,
    '680_456': 27.8122,
    '680_457': 27.6016,
    '680_458': 27.3803,
    '680_459': 27.1565,
    '680_460': 26.9407,
    '680_461': 26.7401,
    '680_462': 26.5604,
    '680_463': 26.3926,
    '680_464': 26.2444,
    '681_400': 32.1114,
    '681_401': 32.1211,
    '681_402': 32.1396,
    '681_403': 32.1536,
    '681_404': 32.1687,
    '681_405': 32.1865,
    '681_406': 32.2023,
    '681_407': 32.2201,
    '681_408': 32.2404,
    '681_409': 32.26,
    '681_410': 32.2548,
    '681_411': 32.241,
    '681_412': 32.22,
    '681_413': 32.1847,
    '681_414': 32.1356,
    '681_415': 32.0909,
    '681_416': 32.045,
    '681_417': 32.0015,
    '681_418': 31.9609,
    '681_419': 31.9193,
    '681_420': 31.849,
    '681_421': 31.727,
    '681_422': 31.584,
    '681_423': 31.4559,
    '681_424': 31.3376,
    '681_425': 31.2114,
    '681_426': 31.0983,
    '681_427': 31.0128,
    '681_428': 30.9671,
    '681_429': 30.9572,
    '681_430': 30.9621,
    '681_431': 30.9645,
    '681_432': 30.963,
    '681_433': 30.9393,
    '681_434': 30.8834,
    '681_435': 30.8093,
    '681_436': 30.7255,
    '681_437': 30.6375,
    '681_438': 30.5476,
    '681_439': 30.4578,
    '681_440': 30.3682,
    '681_441': 30.2639,
    '681_442': 30.1292,
    '681_443': 29.9748,
    '681_444': 29.8164,
    '681_445': 29.6597,
    '681_446': 29.5043,
    '681_447': 29.3537,
    '681_448': 29.2081,
    '681_449': 29.0625,
    '681_450': 28.915,
    '681_451': 28.763,
    '681_452': 28.6031,
    '681_453': 28.4327,
    '681_454': 28.2502,
    '681_455': 28.057,
    '681_456': 27.855,
    '681_457': 27.6441,
    '681_458': 27.4185,
    '681_459': 27.1924,
    '681_460': 26.9758,
    '681_461': 26.7621,
    '681_462': 26.579,
    '681_463': 26.4044,
    '681_464': 26.262,
    '682_400': 32.1397,
    '682_401': 32.1523,
    '682_402': 32.173,
    '682_403': 32.1834,
    '682_404': 32.1962,
    '682_405': 32.214,
    '682_406': 32.2299,
    '682_407': 32.2493,
    '682_408': 32.2621,
    '682_409': 32.2631,
    '682_410': 32.2651,
    '682_411': 32.2586,
    '682_412': 32.2253,
    '682_413': 32.1947,
    '682_414': 32.151,
    '682_415': 32.1057,
    '682_416': 32.0669,
    '682_417': 32.03,
    '682_418': 31.9928,
    '682_419': 31.9499,
    '682_420': 31.878,
    '682_421': 31.7494,
    '682_422': 31.6024,
    '682_423': 31.4847,
    '682_424': 31.3691,
    '682_425': 31.2334,
    '682_426': 31.1127,
    '682_427': 31.0286,
    '682_428': 30.9941,
    '682_429': 30.9906,
    '682_430': 30.9963,
    '682_431': 31.0055,
    '682_432': 31.004,
    '682_433': 30.9671,
    '682_434': 30.9043,
    '682_435': 30.824,
    '682_436': 30.7382,
    '682_437': 30.6477,
    '682_438': 30.5516,
    '682_439': 30.4525,
    '682_440': 30.3497,
    '682_441': 30.2399,
    '682_442': 30.1146,
    '682_443': 29.9799,
    '682_444': 29.8372,
    '682_445': 29.6912,
    '682_446': 29.5438,
    '682_447': 29.3978,
    '682_448': 29.2539,
    '682_449': 29.1096,
    '682_450': 28.9639,
    '682_451': 28.813,
    '682_452': 28.6524,
    '682_453': 28.4853,
    '682_454': 28.2968,
    '682_455': 28.095,
    '682_456': 27.8869,
    '682_457': 27.672,
    '682_458': 27.4564,
    '682_459': 27.2211,
    '682_460': 27.0043,
    '682_461': 26.7964,
    '682_462': 26.6104,
    '682_463': 26.4366,
    '682_464': 26.2853,
    '683_400': 32.1584,
    '683_401': 32.1763,
    '683_402': 32.1999,
    '683_403': 32.2058,
    '683_404': 32.2177,
    '683_405': 32.2358,
    '683_406': 32.2558,
    '683_407': 32.2724,
    '683_408': 32.2696,
    '683_409': 32.2637,
    '683_410': 32.2745,
    '683_411': 32.2693,
    '683_412': 32.2298,
    '683_413': 32.1964,
    '683_414': 32.1606,
    '683_415': 32.1193,
    '683_416': 32.0837,
    '683_417': 32.0555,
    '683_418': 32.0214,
    '683_419': 31.9785,
    '683_420': 31.9016,
    '683_421': 31.7686,
    '683_422': 31.621,
    '683_423': 31.4987,
    '683_424': 31.3821,
    '683_425': 31.2437,
    '683_426': 31.123,
    '683_427': 31.0496,
    '683_428': 31.0257,
    '683_429': 31.0293,
    '683_430': 31.0403,
    '683_431': 31.0554,
    '683_432': 31.0516,
    '683_433': 31.0071,
    '683_434': 30.9343,
    '683_435': 30.849,
    '683_436': 30.7588,
    '683_437': 30.6642,
    '683_438': 30.5655,
    '683_439': 30.4605,
    '683_440': 30.3506,
    '683_441': 30.2376,
    '683_442': 30.1207,
    '683_443': 29.9946,
    '683_444': 29.8611,
    '683_445': 29.7228,
    '683_446': 29.5811,
    '683_447': 29.4389,
    '683_448': 29.297,
    '683_449': 29.1544,
    '683_450': 29.0153,
    '683_451': 28.8709,
    '683_452': 28.7036,
    '683_453': 28.5317,
    '683_454': 28.3362,
    '683_455': 28.1321,
    '683_456': 27.9216,
    '683_457': 27.7077,
    '683_458': 27.4933,
    '683_459': 27.2591,
    '683_460': 27.0349,
    '683_461': 26.837,
    '683_462': 26.6491,
    '683_463': 26.4702,
    '683_464': 26.3059,
    '684_400': 32.1715,
    '684_401': 32.1874,
    '684_402': 32.2198,
    '684_403': 32.2285,
    '684_404': 32.2364,
    '684_405': 32.2517,
    '684_406': 32.277,
    '684_407': 32.2845,
    '684_408': 32.2689,
    '684_409': 32.2609,
    '684_410': 32.2673,
    '684_411': 32.2599,
    '684_412': 32.2292,
    '684_413': 32.1984,
    '684_414': 32.1698,
    '684_415': 32.1375,
    '684_416': 32.1049,
    '684_417': 32.0746,
    '684_418': 32.0486,
    '684_419': 32.0054,
    '684_420': 31.918,
    '684_421': 31.7793,
    '684_422': 31.6333,
    '684_423': 31.5082,
    '684_424': 31.3934,
    '684_425': 31.2494,
    '684_426': 31.1363,
    '684_427': 31.0769,
    '684_428': 31.0626,
    '684_429': 31.0728,
    '684_430': 31.0901,
    '684_431': 31.1114,
    '684_432': 31.1074,
    '684_433': 31.0594,
    '684_434': 30.9749,
    '684_435': 30.8815,
    '684_436': 30.7852,
    '684_437': 30.6857,
    '684_438': 30.5835,
    '684_439': 30.4764,
    '684_440': 30.3628,
    '684_441': 30.2485,
    '684_442': 30.1359,
    '684_443': 30.0163,
    '684_444': 29.8884,
    '684_445': 29.755,
    '684_446': 29.6174,
    '684_447': 29.4778,
    '684_448': 29.3373,
    '684_449': 29.196,
    '684_450': 29.0603,
    '684_451': 28.9227,
    '684_452': 28.7577,
    '684_453': 28.5796,
    '684_454': 28.3781,
    '684_455': 28.1699,
    '684_456': 27.9561,
    '684_457': 27.7384,
    '684_458': 27.5168,
    '684_459': 27.3043,
    '684_460': 27.0724,
    '684_461': 26.8692,
    '684_462': 26.6818,
    '684_463': 26.5008,
    '684_464': 26.3231,
    '685_400': 32.1742,
    '685_401': 32.1858,
    '685_402': 32.2095,
    '685_403': 32.227,
    '685_404': 32.2398,
    '685_405': 32.2569,
    '685_406': 32.2794,
    '685_407': 32.2798,
    '685_408': 32.2644,
    '685_409': 32.2567,
    '685_410': 32.2536,
    '685_411': 32.2448,
    '685_412': 32.2248,
    '685_413': 32.1996,
    '685_414': 32.1791,
    '685_415': 32.1564,
    '685_416': 32.1283,
    '685_417': 32.0949,
    '685_418': 32.0674,
    '685_419': 32.0239,
    '685_420': 31.9292,
    '685_421': 31.7803,
    '685_422': 31.637,
    '685_423': 31.5067,
    '685_424': 31.3756,
    '685_425': 31.241,
    '685_426': 31.1523,
    '685_427': 31.1106,
    '685_428': 31.1042,
    '685_429': 31.117,
    '685_430': 31.1442,
    '685_431': 31.1714,
    '685_432': 31.1711,
    '685_433': 31.1246,
    '685_434': 31.0237,
    '685_435': 30.9199,
    '685_436': 30.8177,
    '685_437': 30.7133,
    '685_438': 30.6067,
    '685_439': 30.498,
    '685_440': 30.3828,
    '685_441': 30.2686,
    '685_442': 30.1569,
    '685_443': 30.0434,
    '685_444': 29.9174,
    '685_445': 29.7869,
    '685_446': 29.6531,
    '685_447': 29.5151,
    '685_448': 29.3747,
    '685_449': 29.2338,
    '685_450': 29.0955,
    '685_451': 28.9611,
    '685_452': 28.8081,
    '685_453': 28.6338,
    '685_454': 28.4242,
    '685_455': 28.2091,
    '685_456': 27.9921,
    '685_457': 27.7755,
    '685_458': 27.5541,
    '685_459': 27.3364,
    '685_460': 27.1079,
    '685_461': 26.8978,
    '685_462': 26.7034,
    '685_463': 26.5265,
    '685_464': 26.3526,
    '686_400': 32.161,
    '686_401': 32.1784,
    '686_402': 32.1984,
    '686_403': 32.2166,
    '686_404': 32.2323,
    '686_405': 32.2483,
    '686_406': 32.2633,
    '686_407': 32.2647,
    '686_408': 32.2577,
    '686_409': 32.2517,
    '686_410': 32.2452,
    '686_411': 32.2358,
    '686_412': 32.2213,
    '686_413': 32.2017,
    '686_414': 32.1886,
    '686_415': 32.1739,
    '686_416': 32.1547,
    '686_417': 32.1208,
    '686_418': 32.0853,
    '686_419': 32.033,
    '686_420': 31.9298,
    '686_421': 31.7814,
    '686_422': 31.641,
    '686_423': 31.5042,
    '686_424': 31.3716,
    '686_425': 31.2525,
    '686_426': 31.1768,
    '686_427': 31.1458,
    '686_428': 31.1468,
    '686_429': 31.1662,
    '686_430': 31.2032,
    '686_431': 31.2404,
    '686_432': 31.2401,
    '686_433': 31.1935,
    '686_434': 31.0787,
    '686_435': 30.9625,
    '686_436': 30.8538,
    '686_437': 30.7461,
    '686_438': 30.6377,
    '686_439': 30.5263,
    '686_440': 30.41,
    '686_441': 30.2949,
    '686_442': 30.1841,
    '686_443': 30.0732,
    '686_444': 29.949,
    '686_445': 29.8207,
    '686_446': 29.6894,
    '686_447': 29.5522,
    '686_448': 29.4125,
    '686_449': 29.2712,
    '686_450': 29.1304,
    '686_451': 28.9894,
    '686_452': 28.8442,
    '686_453': 28.6774,
    '686_454': 28.4745,
    '686_455': 28.2491,
    '686_456': 28.024,
    '686_457': 27.8053,
    '686_458': 27.5843,
    '686_459': 27.361,
    '686_460': 27.1499,
    '686_461': 26.9293,
    '686_462': 26.7227,
    '686_463': 26.5494,
    '686_464': 26.3881,
    '687_400': 32.1376,
    '687_401': 32.1582,
    '687_402': 32.1823,
    '687_403': 32.1978,
    '687_404': 32.216,
    '687_405': 32.2315,
    '687_406': 32.2405,
    '687_407': 32.2469,
    '687_408': 32.2484,
    '687_409': 32.2448,
    '687_410': 32.2394,
    '687_411': 32.2309,
    '687_412': 32.2192,
    '687_413': 32.2045,
    '687_414': 32.1947,
    '687_415': 32.1912,
    '687_416': 32.1762,
    '687_417': 32.147,
    '687_418': 32.1025,
    '687_419': 32.0423,
    '687_420': 31.9262,
    '687_421': 31.7807,
    '687_422': 31.6425,
    '687_423': 31.5057,
    '687_424': 31.3764,
    '687_425': 31.2683,
    '687_426': 31.2016,
    '687_427': 31.1776,
    '687_428': 31.1867,
    '687_429': 31.2182,
    '687_430': 31.2692,
    '687_431': 31.3176,
    '687_432': 31.3148,
    '687_433': 31.2613,
    '687_434': 31.1366,
    '687_435': 31.0066,
    '687_436': 30.8914,
    '687_437': 30.7817,
    '687_438': 30.6739,
    '687_439': 30.5599,
    '687_440': 30.4428,
    '687_441': 30.3272,
    '687_442': 30.212,
    '687_443': 30.0981,
    '687_444': 29.9782,
    '687_445': 29.8549,
    '687_446': 29.7274,
    '687_447': 29.5941,
    '687_448': 29.453,
    '687_449': 29.3089,
    '687_450': 29.1689,
    '687_451': 29.0243,
    '687_452': 28.8732,
    '687_453': 28.703,
    '687_454': 28.4991,
    '687_455': 28.2748,
    '687_456': 28.0513,
    '687_457': 27.832,
    '687_458': 27.6135,
    '687_459': 27.3885,
    '687_460': 27.1784,
    '687_461': 26.9658,
    '687_462': 26.7616,
    '687_463': 26.582,
    '687_464': 26.4171,
    '688_400': 32.1166,
    '688_401': 32.1307,
    '688_402': 32.1605,
    '688_403': 32.1771,
    '688_404': 32.195,
    '688_405': 32.2097,
    '688_406': 32.2176,
    '688_407': 32.2297,
    '688_408': 32.2375,
    '688_409': 32.2368,
    '688_410': 32.2325,
    '688_411': 32.226,
    '688_412': 32.2171,
    '688_413': 32.2072,
    '688_414': 32.2019,
    '688_415': 32.2092,
    '688_416': 32.1938,
    '688_417': 32.1657,
    '688_418': 32.1151,
    '688_419': 32.0451,
    '688_420': 31.9207,
    '688_421': 31.78,
    '688_422': 31.6452,
    '688_423': 31.5116,
    '688_424': 31.3876,
    '688_425': 31.2895,
    '688_426': 31.2318,
    '688_427': 31.2145,
    '688_428': 31.2305,
    '688_429': 31.2692,
    '688_430': 31.3234,
    '688_431': 31.3726,
    '688_432': 31.374,
    '688_433': 31.3213,
    '688_434': 31.1974,
    '688_435': 31.0501,
    '688_436': 30.9315,
    '688_437': 30.8252,
    '688_438': 30.7149,
    '688_439': 30.5978,
    '688_440': 30.4785,
    '688_441': 30.3626,
    '688_442': 30.2456,
    '688_443': 30.13,
    '688_444': 30.011,
    '688_445': 29.8925,
    '688_446': 29.7741,
    '688_447': 29.646,
    '688_448': 29.5007,
    '688_449': 29.3486,
    '688_450': 29.2042,
    '688_451': 29.0616,
    '688_452': 28.9025,
    '688_453': 28.7202,
    '688_454': 28.517,
    '688_455': 28.3001,
    '688_456': 28.0751,
    '688_457': 27.8503,
    '688_458': 27.6264,
    '688_459': 27.4077,
    '688_460': 27.2106,
    '688_461': 27.0117,
    '688_462': 26.8044,
    '688_463': 26.6213,
    '688_464': 26.4532,
    '689_400': 32.0937,
    '689_401': 32.1024,
    '689_402': 32.128,
    '689_403': 32.1467,
    '689_404': 32.1689,
    '689_405': 32.1889,
    '689_406': 32.2049,
    '689_407': 32.2176,
    '689_408': 32.2268,
    '689_409': 32.2261,
    '689_410': 32.2233,
    '689_411': 32.2193,
    '689_412': 32.2134,
    '689_413': 32.2068,
    '689_414': 32.2033,
    '689_415': 32.2078,
    '689_416': 32.2003,
    '689_417': 32.1765,
    '689_418': 32.1251,
    '689_419': 32.0451,
    '689_420': 31.9165,
    '689_421': 31.78,
    '689_422': 31.6506,
    '689_423': 31.5238,
    '689_424': 31.4088,
    '689_425': 31.3195,
    '689_426': 31.2708,
    '689_427': 31.2547,
    '689_428': 31.2709,
    '689_429': 31.3116,
    '689_430': 31.3653,
    '689_431': 31.4079,
    '689_432': 31.4128,
    '689_433': 31.3603,
    '689_434': 31.2363,
    '689_435': 31.0893,
    '689_436': 30.9728,
    '689_437': 30.8662,
    '689_438': 30.754,
    '689_439': 30.6366,
    '689_440': 30.5169,
    '689_441': 30.4011,
    '689_442': 30.2855,
    '689_443': 30.168,
    '689_444': 30.0499,
    '689_445': 29.9345,
    '689_446': 29.8232,
    '689_447': 29.6965,
    '689_448': 29.554,
    '689_449': 29.3938,
    '689_450': 29.2366,
    '689_451': 29.0919,
    '689_452': 28.9312,
    '689_453': 28.7418,
    '689_454': 28.5471,
    '689_455': 28.3337,
    '689_456': 28.101,
    '689_457': 27.8731,
    '689_458': 27.6498,
    '689_459': 27.4467,
    '689_460': 27.2439,
    '689_461': 27.0436,
    '689_462': 26.8493,
    '689_463': 26.6606,
    '689_464': 26.4788,
    '690_400': 32.0717,
    '690_401': 32.0759,
    '690_402': 32.0916,
    '690_403': 32.1158,
    '690_404': 32.1317,
    '690_405': 32.1511,
    '690_406': 32.1775,
    '690_407': 32.1943,
    '690_408': 32.2061,
    '690_409': 32.2107,
    '690_410': 32.212,
    '690_411': 32.2115,
    '690_412': 32.2086,
    '690_413': 32.2049,
    '690_414': 32.2021,
    '690_415': 32.2033,
    '690_416': 32.1998,
    '690_417': 32.1813,
    '690_418': 32.1336,
    '690_419': 32.0471,
    '690_420': 31.9192,
    '690_421': 31.7859,
    '690_422': 31.6605,
    '690_423': 31.5399,
    '690_424': 31.4315,
    '690_425': 31.3501,
    '690_426': 31.3085,
    '690_427': 31.2937,
    '690_428': 31.3109,
    '690_429': 31.356,
    '690_430': 31.4087,
    '690_431': 31.4378,
    '690_432': 31.4351,
    '690_433': 31.3813,
    '690_434': 31.2581,
    '690_435': 31.1255,
    '690_436': 31.0123,
    '690_437': 30.905,
    '690_438': 30.7904,
    '690_439': 30.6727,
    '690_440': 30.5549,
    '690_441': 30.4404,
    '690_442': 30.3303,
    '690_443': 30.2089,
    '690_444': 30.0902,
    '690_445': 29.9754,
    '690_446': 29.8649,
    '690_447': 29.7465,
    '690_448': 29.6056,
    '690_449': 29.4402,
    '690_450': 29.2735,
    '690_451': 29.1205,
    '690_452': 28.9584,
    '690_453': 28.7675,
    '690_454': 28.5716,
    '690_455': 28.3601,
    '690_456': 28.1307,
    '690_457': 27.9069,
    '690_458': 27.6899,
    '690_459': 27.4853,
    '690_460': 27.2797,
    '690_461': 27.0814,
    '690_462': 26.8894,
    '690_463': 26.7008,
    '690_464': 26.5142,
    '691_400': 32.0491,
    '691_401': 32.0494,
    '691_402': 32.0698,
    '691_403': 32.0871,
    '691_404': 32.102,
    '691_405': 32.1251,
    '691_406': 32.151,
    '691_407': 32.1691,
    '691_408': 32.1823,
    '691_409': 32.1913,
    '691_410': 32.1972,
    '691_411': 32.2009,
    '691_412': 32.2017,
    '691_413': 32.2009,
    '691_414': 32.2001,
    '691_415': 32.1998,
    '691_416': 32.1939,
    '691_417': 32.1766,
    '691_418': 32.1333,
    '691_419': 32.0455,
    '691_420': 31.9199,
    '691_421': 31.7933,
    '691_422': 31.6703,
    '691_423': 31.558,
    '691_424': 31.4565,
    '691_425': 31.3822,
    '691_426': 31.342,
    '691_427': 31.3259,
    '691_428': 31.3412,
    '691_429': 31.3902,
    '691_430': 31.4376,
    '691_431': 31.4549,
    '691_432': 31.4446,
    '691_433': 31.3844,
    '691_434': 31.2747,
    '691_435': 31.1594,
    '691_436': 31.0481,
    '691_437': 30.9387,
    '691_438': 30.823,
    '691_439': 30.7063,
    '691_440': 30.5894,
    '691_441': 30.477,
    '691_442': 30.3671,
    '691_443': 30.249,
    '691_444': 30.1315,
    '691_445': 30.0174,
    '691_446': 29.8999,
    '691_447': 29.7796,
    '691_448': 29.6406,
    '691_449': 29.477,
    '691_450': 29.3136,
    '691_451': 29.1556,
    '691_452': 28.986,
    '691_453': 28.7917,
    '691_454': 28.5906,
    '691_455': 28.3804,
    '691_456': 28.1581,
    '691_457': 27.9379,
    '691_458': 27.7231,
    '691_459': 27.5157,
    '691_460': 27.3116,
    '691_461': 27.1116,
    '691_462': 26.9146,
    '691_463': 26.7242,
    '691_464': 26.5438,
    '692_400': 32.033,
    '692_401': 32.0292,
    '692_402': 32.0443,
    '692_403': 32.0574,
    '692_404': 32.0741,
    '692_405': 32.1035,
    '692_406': 32.1301,
    '692_407': 32.1463,
    '692_408': 32.1591,
    '692_409': 32.1719,
    '692_410': 32.1823,
    '692_411': 32.19,
    '692_412': 32.1945,
    '692_413': 32.1967,
    '692_414': 32.1989,
    '692_415': 32.2012,
    '692_416': 32.1955,
    '692_417': 32.1737,
    '692_418': 32.1298,
    '692_419': 32.0428,
    '692_420': 31.923,
    '692_421': 31.803,
    '692_422': 31.6836,
    '692_423': 31.5774,
    '692_424': 31.4814,
    '692_425': 31.4113,
    '692_426': 31.3706,
    '692_427': 31.3533,
    '692_428': 31.3637,
    '692_429': 31.4056,
    '692_430': 31.4429,
    '692_431': 31.4575,
    '692_432': 31.4475,
    '692_433': 31.3901,
    '692_434': 31.2994,
    '692_435': 31.1938,
    '692_436': 31.084,
    '692_437': 30.972,
    '692_438': 30.856,
    '692_439': 30.737,
    '692_440': 30.6235,
    '692_441': 30.5145,
    '692_442': 30.408,
    '692_443': 30.2923,
    '692_444': 30.1765,
    '692_445': 30.0622,
    '692_446': 29.9445,
    '692_447': 29.8218,
    '692_448': 29.6811,
    '692_449': 29.5199,
    '692_450': 29.3606,
    '692_451': 29.1959,
    '692_452': 29.0136,
    '692_453': 28.8164,
    '692_454': 28.6129,
    '692_455': 28.4036,
    '692_456': 28.1848,
    '692_457': 27.9653,
    '692_458': 27.7498,
    '692_459': 27.54,
    '692_460': 27.3361,
    '692_461': 27.1305,
    '692_462': 26.928,
    '692_463': 26.737,
    '692_464': 26.5685,
    '693_400': 32.025,
    '693_401': 32.0183,
    '693_402': 32.0264,
    '693_403': 32.0415,
    '693_404': 32.0605,
    '693_405': 32.0892,
    '693_406': 32.11,
    '693_407': 32.1243,
    '693_408': 32.1391,
    '693_409': 32.1551,
    '693_410': 32.1693,
    '693_411': 32.1807,
    '693_412': 32.1884,
    '693_413': 32.1932,
    '693_414': 32.1977,
    '693_415': 32.1988,
    '693_416': 32.1912,
    '693_417': 32.1708,
    '693_418': 32.1273,
    '693_419': 32.0427,
    '693_420': 31.9304,
    '693_421': 31.8165,
    '693_422': 31.699,
    '693_423': 31.5948,
    '693_424': 31.5069,
    '693_425': 31.4435,
    '693_426': 31.4086,
    '693_427': 31.3809,
    '693_428': 31.3758,
    '693_429': 31.3928,
    '693_430': 31.4156,
    '693_431': 31.4404,
    '693_432': 31.4438,
    '693_433': 31.4016,
    '693_434': 31.3228,
    '693_435': 31.2248,
    '693_436': 31.1177,
    '693_437': 31.0044,
    '693_438': 30.889,
    '693_439': 30.7703,
    '693_440': 30.6574,
    '693_441': 30.5495,
    '693_442': 30.4488,
    '693_443': 30.3409,
    '693_444': 30.2245,
    '693_445': 30.11,
    '693_446': 29.9944,
    '693_447': 29.8733,
    '693_448': 29.7287,
    '693_449': 29.5727,
    '693_450': 29.4104,
    '693_451': 29.2449,
    '693_452': 29.0473,
    '693_453': 28.845,
    '693_454': 28.6424,
    '693_455': 28.4287,
    '693_456': 28.2107,
    '693_457': 27.9899,
    '693_458': 27.7723,
    '693_459': 27.5589,
    '693_460': 27.3538,
    '693_461': 27.1491,
    '693_462': 26.9452,
    '693_463': 26.7591,
    '693_464': 26.5888,
    '694_400': 32.0156,
    '694_401': 32.004,
    '694_402': 32.0065,
    '694_403': 32.0226,
    '694_404': 32.0391,
    '694_405': 32.0617,
    '694_406': 32.0823,
    '694_407': 32.0998,
    '694_408': 32.1192,
    '694_409': 32.1413,
    '694_410': 32.16,
    '694_411': 32.1749,
    '694_412': 32.1853,
    '694_413': 32.1923,
    '694_414': 32.1995,
    '694_415': 32.1996,
    '694_416': 32.1888,
    '694_417': 32.1665,
    '694_418': 32.1213,
    '694_419': 32.0444,
    '694_420': 31.9406,
    '694_421': 31.8314,
    '694_422': 31.7175,
    '694_423': 31.6165,
    '694_424': 31.534,
    '694_425': 31.4855,
    '694_426': 31.4669,
    '694_427': 31.4211,
    '694_428': 31.3709,
    '694_429': 31.3548,
    '694_430': 31.3665,
    '694_431': 31.397,
    '694_432': 31.4242,
    '694_433': 31.4099,
    '694_434': 31.3442,
    '694_435': 31.2533,
    '694_436': 31.1492,
    '694_437': 31.0382,
    '694_438': 30.921,
    '694_439': 30.8049,
    '694_440': 30.6918,
    '694_441': 30.5851,
    '694_442': 30.4866,
    '694_443': 30.3853,
    '694_444': 30.2759,
    '694_445': 30.1638,
    '694_446': 30.0479,
    '694_447': 29.9245,
    '694_448': 29.7812,
    '694_449': 29.6312,
    '694_450': 29.4628,
    '694_451': 29.2894,
    '694_452': 29.0852,
    '694_453': 28.8738,
    '694_454': 28.668,
    '694_455': 28.4584,
    '694_456': 28.2416,
    '694_457': 28.0159,
    '694_458': 27.7961,
    '694_459': 27.5817,
    '694_460': 27.3768,
    '694_461': 27.1768,
    '694_462': 26.9759,
    '694_463': 26.784,
    '694_464': 26.6059,
    '695_384': 32.2479,
    '695_385': 32.2773,
    '695_386': 32.2979,
    '695_387': 32.3036,
    '695_388': 32.2799,
    '695_389': 32.246,
    '695_390': 32.2256,
    '695_391': 32.2104,
    '695_392': 32.2001,
    '695_400': 32.0093,
    '695_401': 31.9943,
    '695_402': 31.9942,
    '695_403': 32.0081,
    '695_404': 32.019,
    '695_405': 32.0378,
    '695_406': 32.0601,
    '695_407': 32.0794,
    '695_408': 32.1026,
    '695_409': 32.1323,
    '695_410': 32.1549,
    '695_411': 32.1722,
    '695_412': 32.1846,
    '695_413': 32.193,
    '695_414': 32.2012,
    '695_415': 32.2056,
    '695_416': 32.1881,
    '695_417': 32.1613,
    '695_418': 32.117,
    '695_419': 32.0476,
    '695_420': 31.9502,
    '695_421': 31.8484,
    '695_422': 31.7382,
    '695_423': 31.6397,
    '695_424': 31.5589,
    '695_425': 31.5201,
    '695_426': 31.4987,
    '695_427': 31.4343,
    '695_428': 31.3513,
    '695_429': 31.3192,
    '695_430': 31.3273,
    '695_431': 31.357,
    '695_432': 31.4017,
    '695_433': 31.4142,
    '695_434': 31.3628,
    '695_435': 31.2794,
    '695_436': 31.1787,
    '695_437': 31.07,
    '695_438': 30.9539,
    '695_439': 30.839,
    '695_440': 30.7265,
    '695_441': 30.6207,
    '695_442': 30.5241,
    '695_443': 30.4325,
    '695_444': 30.329,
    '695_445': 30.2213,
    '695_446': 30.1055,
    '695_447': 29.9791,
    '695_448': 29.842,
    '695_449': 29.6918,
    '695_450': 29.5218,
    '695_451': 29.3314,
    '695_452': 29.1223,
    '695_453': 28.9086,
    '695_454': 28.7005,
    '695_455': 28.4906,
    '695_456': 28.2694,
    '695_457': 28.041,
    '695_458': 27.8191,
    '695_459': 27.602,
    '695_460': 27.3973,
    '695_461': 27.1992,
    '695_462': 27,
    '695_463': 26.7996,
    '695_464': 26.612,
    '696_384': 32.2822,
    '696_385': 32.3156,
    '696_386': 32.3379,
    '696_387': 32.3346,
    '696_388': 32.3111,
    '696_389': 32.2782,
    '696_390': 32.2493,
    '696_391': 32.2249,
    '696_392': 32.204,
    '696_400': 32.0068,
    '696_401': 31.9872,
    '696_402': 31.9891,
    '696_403': 32.0024,
    '696_404': 32.0145,
    '696_405': 32.0323,
    '696_406': 32.0536,
    '696_407': 32.0723,
    '696_408': 32.0979,
    '696_409': 32.1283,
    '696_410': 32.1527,
    '696_411': 32.1718,
    '696_412': 32.1856,
    '696_413': 32.1952,
    '696_414': 32.2049,
    '696_415': 32.2083,
    '696_416': 32.1888,
    '696_417': 32.1596,
    '696_418': 32.1172,
    '696_419': 32.0518,
    '696_420': 31.9615,
    '696_421': 31.8635,
    '696_422': 31.7638,
    '696_423': 31.6671,
    '696_424': 31.5804,
    '696_425': 31.5265,
    '696_426': 31.491,
    '696_427': 31.4163,
    '696_428': 31.3275,
    '696_429': 31.2893,
    '696_430': 31.2993,
    '696_431': 31.3326,
    '696_432': 31.3817,
    '696_433': 31.4119,
    '696_434': 31.3759,
    '696_435': 31.2994,
    '696_436': 31.2056,
    '696_437': 31.101,
    '696_438': 30.9864,
    '696_439': 30.8713,
    '696_440': 30.7613,
    '696_441': 30.6571,
    '696_442': 30.5612,
    '696_443': 30.4723,
    '696_444': 30.3748,
    '696_445': 30.2713,
    '696_446': 30.1623,
    '696_447': 30.0419,
    '696_448': 29.9073,
    '696_449': 29.7569,
    '696_450': 29.5825,
    '696_451': 29.3793,
    '696_452': 29.1596,
    '696_453': 28.9437,
    '696_454': 28.7336,
    '696_455': 28.5162,
    '696_456': 28.296,
    '696_457': 28.0682,
    '696_458': 27.8437,
    '696_459': 27.6201,
    '696_460': 27.4133,
    '696_461': 27.2131,
    '696_462': 27.0074,
    '696_463': 26.8028,
    '696_464': 26.6123,
    '697_384': 32.2935,
    '697_385': 32.3317,
    '697_386': 32.3523,
    '697_387': 32.3522,
    '697_388': 32.3362,
    '697_389': 32.3071,
    '697_390': 32.2733,
    '697_391': 32.2458,
    '697_392': 32.2224,
    '697_405': 32.0322,
    '697_406': 32.0548,
    '697_407': 32.0693,
    '697_408': 32.0959,
    '697_409': 32.1285,
    '697_410': 32.153,
    '697_411': 32.1733,
    '697_412': 32.1888,
    '697_413': 32.1998,
    '697_414': 32.2088,
    '697_415': 32.2076,
    '697_416': 32.1891,
    '697_417': 32.1601,
    '697_418': 32.117,
    '697_419': 32.0555,
    '697_420': 31.9737,
    '697_421': 31.8843,
    '697_422': 31.7941,
    '697_423': 31.704,
    '697_424': 31.6076,
    '697_425': 31.5229,
    '697_426': 31.4573,
    '697_427': 31.3822,
    '697_428': 31.306,
    '697_429': 31.2654,
    '697_430': 31.2815,
    '697_431': 31.3162,
    '697_432': 31.3699,
    '697_433': 31.4099,
    '697_434': 31.386,
    '697_435': 31.3197,
    '697_436': 31.2317,
    '697_437': 31.1291,
    '697_438': 31.0183,
    '697_439': 30.9046,
    '697_440': 30.7936,
    '697_441': 30.6902,
    '697_442': 30.5952,
    '697_443': 30.5069,
    '697_444': 30.416,
    '697_445': 30.3191,
    '697_446': 30.2231,
    '697_447': 30.1067,
    '697_448': 29.9712,
    '697_449': 29.8142,
    '697_450': 29.6303,
    '697_451': 29.4195,
    '697_452': 29.1941,
    '697_453': 28.9742,
    '697_454': 28.759,
    '697_455': 28.5432,
    '697_456': 28.3294,
    '697_457': 28.113,
    '697_458': 27.8853,
    '697_459': 27.6504,
    '697_460': 27.4292,
    '697_461': 27.2178,
    '697_462': 27.0121,
    '697_463': 26.8093,
    '697_464': 26.6261,
    '698_384': 32.2819,
    '698_385': 32.3201,
    '698_386': 32.3472,
    '698_387': 32.3625,
    '698_388': 32.356,
    '698_389': 32.332,
    '698_390': 32.296,
    '698_391': 32.2665,
    '698_392': 32.2418,
    '698_405': 32.035,
    '698_406': 32.0532,
    '698_407': 32.0718,
    '698_408': 32.0976,
    '698_409': 32.1279,
    '698_410': 32.1547,
    '698_411': 32.1775,
    '698_412': 32.195,
    '698_413': 32.2078,
    '698_414': 32.2156,
    '698_415': 32.2102,
    '698_416': 32.192,
    '698_417': 32.1629,
    '698_418': 32.1213,
    '698_419': 32.0629,
    '698_420': 31.99,
    '698_421': 31.9102,
    '698_422': 31.829,
    '698_423': 31.7472,
    '698_424': 31.6505,
    '698_425': 31.5362,
    '698_426': 31.4442,
    '698_427': 31.3649,
    '698_428': 31.3022,
    '698_429': 31.2665,
    '698_430': 31.2817,
    '698_431': 31.3123,
    '698_432': 31.3584,
    '698_433': 31.4036,
    '698_434': 31.398,
    '698_435': 31.3415,
    '698_436': 31.2577,
    '698_437': 31.1593,
    '698_438': 31.0496,
    '698_439': 30.9374,
    '698_440': 30.8252,
    '698_441': 30.7215,
    '698_442': 30.6279,
    '698_443': 30.5459,
    '698_444': 30.4616,
    '698_445': 30.3695,
    '698_446': 30.2766,
    '698_447': 30.1634,
    '698_448': 30.0237,
    '698_449': 29.855,
    '698_450': 29.6616,
    '698_451': 29.4556,
    '698_452': 29.2379,
    '698_453': 29.013,
    '698_454': 28.7931,
    '698_455': 28.5782,
    '698_456': 28.3717,
    '698_457': 28.1604,
    '698_458': 27.924,
    '698_459': 27.677,
    '698_460': 27.4451,
    '698_461': 27.2246,
    '698_462': 27.0239,
    '698_463': 26.8245,
    '698_464': 26.6419,
    '699_384': 32.249,
    '699_385': 32.2958,
    '699_386': 32.3317,
    '699_387': 32.3674,
    '699_388': 32.3774,
    '699_389': 32.3596,
    '699_390': 32.321,
    '699_391': 32.2891,
    '699_392': 32.2631,
    '699_405': 32.0421,
    '699_406': 32.0579,
    '699_407': 32.0789,
    '699_408': 32.1043,
    '699_409': 32.1335,
    '699_410': 32.1616,
    '699_411': 32.1859,
    '699_412': 32.2048,
    '699_413': 32.218,
    '699_414': 32.2239,
    '699_415': 32.2174,
    '699_416': 32.1971,
    '699_417': 32.1677,
    '699_418': 32.1268,
    '699_419': 32.0707,
    '699_420': 32.0059,
    '699_421': 31.9398,
    '699_422': 31.8664,
    '699_423': 31.7875,
    '699_424': 31.6939,
    '699_425': 31.5624,
    '699_426': 31.4628,
    '699_427': 31.3845,
    '699_428': 31.3297,
    '699_429': 31.3002,
    '699_430': 31.3038,
    '699_431': 31.3244,
    '699_432': 31.3583,
    '699_433': 31.3993,
    '699_434': 31.4129,
    '699_435': 31.3647,
    '699_436': 31.2865,
    '699_437': 31.1908,
    '699_438': 31.0844,
    '699_439': 30.9731,
    '699_440': 30.8575,
    '699_441': 30.7495,
    '699_442': 30.656,
    '699_443': 30.5788,
    '699_444': 30.5002,
    '699_445': 30.4162,
    '699_446': 30.3238,
    '699_447': 30.2083,
    '699_448': 30.0634,
    '699_449': 29.8908,
    '699_450': 29.7068,
    '699_451': 29.5095,
    '699_452': 29.2898,
    '699_453': 29.0613,
    '699_454': 28.8371,
    '699_455': 28.6174,
    '699_456': 28.4147,
    '699_457': 28.202,
    '699_458': 27.9546,
    '699_459': 27.7015,
    '699_460': 27.4629,
    '699_461': 27.2371,
    '699_462': 27.0358,
    '699_463': 26.8416,
    '699_464': 26.6565,
    '700_384': 32.2075,
    '700_385': 32.2623,
    '700_386': 32.3064,
    '700_387': 32.3497,
    '700_388': 32.3862,
    '700_389': 32.3743,
    '700_390': 32.343,
    '700_391': 32.317,
    '700_392': 32.2958,
    '700_405': 32.0574,
    '700_406': 32.0749,
    '700_407': 32.0957,
    '700_408': 32.1215,
    '700_409': 32.1483,
    '700_410': 32.174,
    '700_411': 32.1972,
    '700_412': 32.2171,
    '700_413': 32.2302,
    '700_414': 32.2341,
    '700_415': 32.2283,
    '700_416': 32.2105,
    '700_417': 32.1821,
    '700_418': 32.1373,
    '700_419': 32.0792,
    '700_420': 32.0199,
    '700_421': 31.9654,
    '700_422': 31.8972,
    '700_423': 31.8154,
    '700_424': 31.7233,
    '700_425': 31.586,
    '700_426': 31.4896,
    '700_427': 31.4153,
    '700_428': 31.3623,
    '700_429': 31.3341,
    '700_430': 31.3314,
    '700_431': 31.347,
    '700_432': 31.3734,
    '700_433': 31.4113,
    '700_434': 31.4288,
    '700_435': 31.388,
    '700_436': 31.3167,
    '700_437': 31.2229,
    '700_438': 31.1177,
    '700_439': 31.0046,
    '700_440': 30.8868,
    '700_441': 30.7742,
    '700_442': 30.6759,
    '700_443': 30.5958,
    '700_444': 30.5226,
    '700_445': 30.4497,
    '700_446': 30.3616,
    '700_447': 30.2452,
    '700_448': 30.0995,
    '700_449': 29.9398,
    '700_450': 29.7679,
    '700_451': 29.5714,
    '700_452': 29.3439,
    '700_453': 29.1108,
    '700_454': 28.8848,
    '700_455': 28.6518,
    '700_456': 28.4521,
    '700_457': 28.2275,
    '700_458': 27.9732,
    '700_459': 27.719,
    '700_460': 27.4805,
    '700_461': 27.255,
    '700_462': 27.0492,
    '700_463': 26.8506,
    '700_464': 26.6572,
    '701_384': 32.1679,
    '701_385': 32.226,
    '701_386': 32.2789,
    '701_387': 32.3277,
    '701_388': 32.3809,
    '701_389': 32.3928,
    '701_390': 32.365,
    '701_391': 32.3474,
    '701_392': 32.3331,
    '701_405': 32.0792,
    '701_406': 32.0971,
    '701_407': 32.1175,
    '701_408': 32.1442,
    '701_409': 32.1687,
    '701_410': 32.1912,
    '701_411': 32.2108,
    '701_412': 32.2283,
    '701_413': 32.2441,
    '701_414': 32.2507,
    '701_415': 32.248,
    '701_416': 32.2312,
    '701_417': 32.2077,
    '701_418': 32.1564,
    '701_419': 32.0959,
    '701_420': 32.0409,
    '701_421': 31.9905,
    '701_422': 31.9174,
    '701_423': 31.8326,
    '701_424': 31.7372,
    '701_425': 31.604,
    '701_426': 31.5134,
    '701_427': 31.4442,
    '701_428': 31.3937,
    '701_429': 31.365,
    '701_430': 31.3589,
    '701_431': 31.3717,
    '701_432': 31.4008,
    '701_433': 31.4373,
    '701_434': 31.4501,
    '701_435': 31.4162,
    '701_436': 31.3493,
    '701_437': 31.2554,
    '701_438': 31.1483,
    '701_439': 31.0338,
    '701_440': 30.915,
    '701_441': 30.797,
    '701_442': 30.6921,
    '701_443': 30.6034,
    '701_444': 30.5278,
    '701_445': 30.4604,
    '701_446': 30.3786,
    '701_447': 30.2728,
    '701_448': 30.1378,
    '701_449': 29.9878,
    '701_450': 29.8158,
    '701_451': 29.6144,
    '701_452': 29.3772,
    '701_453': 29.1415,
    '701_454': 28.9128,
    '701_455': 28.6838,
    '701_456': 28.4829,
    '701_457': 28.2528,
    '701_458': 27.9932,
    '701_459': 27.7356,
    '701_460': 27.4944,
    '701_461': 27.2655,
    '701_462': 27.0544,
    '701_463': 26.8463,
    '701_464': 26.6519,
    '702_384': 32.1267,
    '702_385': 32.1902,
    '702_386': 32.2498,
    '702_387': 32.3051,
    '702_388': 32.3644,
    '702_389': 32.4007,
    '702_390': 32.3837,
    '702_391': 32.3761,
    '702_392': 32.3684,
    '702_405': 32.0974,
    '702_406': 32.1192,
    '702_407': 32.1426,
    '702_408': 32.1684,
    '702_409': 32.1925,
    '702_410': 32.2122,
    '702_411': 32.2284,
    '702_412': 32.2426,
    '702_413': 32.2564,
    '702_414': 32.2676,
    '702_415': 32.2684,
    '702_416': 32.2539,
    '702_417': 32.2328,
    '702_418': 32.1836,
    '702_419': 32.1234,
    '702_420': 32.0695,
    '702_421': 32.0098,
    '702_422': 31.9251,
    '702_423': 31.8394,
    '702_424': 31.739,
    '702_425': 31.6201,
    '702_426': 31.5358,
    '702_427': 31.4725,
    '702_428': 31.4235,
    '702_429': 31.394,
    '702_430': 31.3855,
    '702_431': 31.401,
    '702_432': 31.4344,
    '702_433': 31.4644,
    '702_434': 31.4733,
    '702_435': 31.4449,
    '702_436': 31.3815,
    '702_437': 31.2863,
    '702_438': 31.1754,
    '702_439': 31.06,
    '702_440': 30.9403,
    '702_441': 30.8181,
    '702_442': 30.707,
    '702_443': 30.6099,
    '702_444': 30.5269,
    '702_445': 30.4549,
    '702_446': 30.3797,
    '702_447': 30.2836,
    '702_448': 30.165,
    '702_449': 30.0212,
    '702_450': 29.844,
    '702_451': 29.6382,
    '702_452': 29.404,
    '702_453': 29.1656,
    '702_454': 28.937,
    '702_455': 28.7166,
    '702_456': 28.5108,
    '702_457': 28.2692,
    '702_458': 28.0059,
    '702_459': 27.7461,
    '702_460': 27.5038,
    '702_461': 27.2707,
    '702_462': 27.0491,
    '702_463': 26.8423,
    '702_464': 26.6568,
    '703_384': 32.0906,
    '703_385': 32.157,
    '703_386': 32.2185,
    '703_387': 32.2825,
    '703_388': 32.3443,
    '703_389': 32.3977,
    '703_390': 32.4028,
    '703_391': 32.3931,
    '703_392': 32.382,
    '703_405': 32.1181,
    '703_406': 32.1437,
    '703_407': 32.1704,
    '703_408': 32.1971,
    '703_409': 32.2218,
    '703_410': 32.2402,
    '703_411': 32.2534,
    '703_412': 32.264,
    '703_413': 32.2723,
    '703_414': 32.2839,
    '703_415': 32.2838,
    '703_416': 32.2707,
    '703_417': 32.2521,
    '703_418': 32.212,
    '703_419': 32.1554,
    '703_420': 32.0974,
    '703_421': 32.0267,
    '703_422': 31.9262,
    '703_423': 31.8352,
    '703_424': 31.7365,
    '703_425': 31.6393,
    '703_426': 31.5615,
    '703_427': 31.5067,
    '703_428': 31.4642,
    '703_429': 31.4367,
    '703_430': 31.4272,
    '703_431': 31.4383,
    '703_432': 31.4678,
    '703_433': 31.4887,
    '703_434': 31.4951,
    '703_435': 31.4723,
    '703_436': 31.413,
    '703_437': 31.3185,
    '703_438': 31.2045,
    '703_439': 31.0859,
    '703_440': 30.9623,
    '703_441': 30.8383,
    '703_442': 30.722,
    '703_443': 30.618,
    '703_444': 30.5271,
    '703_445': 30.4476,
    '703_446': 30.3708,
    '703_447': 30.2775,
    '703_448': 30.1714,
    '703_449': 30.0359,
    '703_450': 29.8628,
    '703_451': 29.6626,
    '703_452': 29.4397,
    '703_453': 29.1998,
    '703_454': 28.9713,
    '703_455': 28.7497,
    '703_456': 28.5383,
    '703_457': 28.2874,
    '703_458': 28.0223,
    '703_459': 27.7577,
    '703_460': 27.5136,
    '703_461': 27.2796,
    '703_462': 27.0567,
    '703_463': 26.8497,
    '703_464': 26.665,
    '704_384': 32.0565,
    '704_385': 32.1204,
    '704_386': 32.1861,
    '704_387': 32.2607,
    '704_388': 32.3233,
    '704_389': 32.3735,
    '704_390': 32.3975,
    '704_391': 32.3977,
    '704_392': 32.3891,
    '704_405': 32.143,
    '704_406': 32.1724,
    '704_407': 32.2027,
    '704_408': 32.233,
    '704_409': 32.2595,
    '704_410': 32.2804,
    '704_411': 32.2921,
    '704_412': 32.3036,
    '704_413': 32.3049,
    '704_414': 32.3077,
    '704_415': 32.2964,
    '704_416': 32.2783,
    '704_417': 32.2564,
    '704_418': 32.2273,
    '704_419': 32.1727,
    '704_420': 32.1118,
    '704_421': 32.034,
    '704_422': 31.9283,
    '704_423': 31.8348,
    '704_424': 31.7494,
    '704_425': 31.6719,
    '704_426': 31.6105,
    '704_427': 31.5605,
    '704_428': 31.521,
    '704_429': 31.4927,
    '704_430': 31.4778,
    '704_431': 31.4797,
    '704_432': 31.4964,
    '704_433': 31.5119,
    '704_434': 31.5193,
    '704_435': 31.5004,
    '704_436': 31.4416,
    '704_437': 31.3485,
    '704_438': 31.2326,
    '704_439': 31.1109,
    '704_440': 30.9853,
    '704_441': 30.8587,
    '704_442': 30.7387,
    '704_443': 30.6296,
    '704_444': 30.5323,
    '704_445': 30.4446,
    '704_446': 30.3606,
    '704_447': 30.2707,
    '704_448': 30.1662,
    '704_449': 30.0393,
    '704_450': 29.8823,
    '704_451': 29.6916,
    '704_452': 29.4767,
    '704_453': 29.2358,
    '704_454': 29.001,
    '704_455': 28.7784,
    '704_456': 28.5606,
    '704_457': 28.307,
    '704_458': 28.0358,
    '704_459': 27.7667,
    '704_460': 27.5212,
    '704_461': 27.2864,
    '704_462': 27.0597,
    '704_463': 26.8507,
    '704_464': 26.6654,
    '705_384': 32.0266,
    '705_385': 32.0877,
    '705_386': 32.1576,
    '705_387': 32.2411,
    '705_388': 32.3088,
    '705_389': 32.3596,
    '705_390': 32.393,
    '705_391': 32.4107,
    '705_392': 32.4218,
    '705_393': 32.4265,
    '705_394': 32.4512,
    '705_395': 32.4461,
    '705_396': 32.4129,
    '705_397': 32.3662,
    '705_398': 32.3206,
    '705_399': 32.2866,
    '705_400': 32.2654,
    '705_401': 32.2439,
    '705_402': 32.245,
    '705_405': 32.1684,
    '705_406': 32.2009,
    '705_407': 32.2355,
    '705_408': 32.2729,
    '705_409': 32.3038,
    '705_410': 32.3205,
    '705_411': 32.3226,
    '705_412': 32.3264,
    '705_413': 32.3313,
    '705_414': 32.3268,
    '705_415': 32.3029,
    '705_416': 32.2735,
    '705_417': 32.2461,
    '705_418': 32.222,
    '705_419': 32.1777,
    '705_420': 32.1184,
    '705_421': 32.041,
    '705_422': 31.9446,
    '705_423': 31.856,
    '705_424': 31.7816,
    '705_425': 31.7213,
    '705_426': 31.6732,
    '705_427': 31.6291,
    '705_428': 31.5937,
    '705_429': 31.5601,
    '705_430': 31.5296,
    '705_431': 31.518,
    '705_432': 31.5251,
    '705_433': 31.5386,
    '705_434': 31.5458,
    '705_435': 31.5269,
    '705_436': 31.465,
    '705_437': 31.3733,
    '705_438': 31.2604,
    '705_439': 31.1395,
    '705_440': 31.0131,
    '705_441': 30.8834,
    '705_442': 30.7594,
    '705_443': 30.6455,
    '705_444': 30.5433,
    '705_445': 30.4481,
    '705_446': 30.3584,
    '705_447': 30.2723,
    '705_448': 30.1721,
    '705_449': 30.0522,
    '705_450': 29.9055,
    '705_451': 29.7217,
    '705_452': 29.5024,
    '705_453': 29.2586,
    '705_454': 29.0223,
    '705_455': 28.7992,
    '705_456': 28.5744,
    '705_457': 28.3171,
    '705_458': 28.0447,
    '705_459': 27.773,
    '705_460': 27.5254,
    '705_461': 27.2914,
    '705_462': 27.0594,
    '705_463': 26.8498,
    '705_464': 26.6573,
    '706_384': 31.9988,
    '706_385': 32.0563,
    '706_386': 32.1201,
    '706_387': 32.2074,
    '706_388': 32.2886,
    '706_389': 32.3521,
    '706_390': 32.3955,
    '706_391': 32.4238,
    '706_392': 32.4468,
    '706_393': 32.4323,
    '706_394': 32.4508,
    '706_395': 32.4507,
    '706_396': 32.4295,
    '706_397': 32.3942,
    '706_398': 32.3534,
    '706_399': 32.3152,
    '706_400': 32.2841,
    '706_401': 32.2596,
    '706_402': 32.2595,
    '706_405': 32.1923,
    '706_406': 32.2277,
    '706_407': 32.2654,
    '706_408': 32.3091,
    '706_409': 32.3434,
    '706_410': 32.3513,
    '706_411': 32.3338,
    '706_412': 32.325,
    '706_413': 32.3236,
    '706_414': 32.3201,
    '706_415': 32.2963,
    '706_416': 32.2559,
    '706_417': 32.2259,
    '706_418': 32.2011,
    '706_419': 32.1694,
    '706_420': 32.1253,
    '706_421': 32.06,
    '706_422': 31.9757,
    '706_423': 31.8979,
    '706_424': 31.8361,
    '706_425': 31.783,
    '706_426': 31.7411,
    '706_427': 31.7007,
    '706_428': 31.6627,
    '706_429': 31.6241,
    '706_430': 31.5773,
    '706_431': 31.5499,
    '706_432': 31.5542,
    '706_433': 31.5685,
    '706_434': 31.5737,
    '706_435': 31.5539,
    '706_436': 31.4915,
    '706_437': 31.3982,
    '706_438': 31.2864,
    '706_439': 31.1657,
    '706_440': 31.0394,
    '706_441': 30.9089,
    '706_442': 30.7832,
    '706_443': 30.6668,
    '706_444': 30.5592,
    '706_445': 30.4591,
    '706_446': 30.3639,
    '706_447': 30.2751,
    '706_448': 30.1828,
    '706_449': 30.0679,
    '706_450': 29.9193,
    '706_451': 29.7342,
    '706_452': 29.5138,
    '706_453': 29.2722,
    '706_454': 29.0344,
    '706_455': 28.8009,
    '706_456': 28.5712,
    '706_457': 28.3135,
    '706_458': 28.043,
    '706_459': 27.7742,
    '706_460': 27.5196,
    '706_461': 27.2844,
    '706_462': 27.0554,
    '706_463': 26.8442,
    '706_464': 26.6526,
    '707_384': 31.9713,
    '707_385': 32.0243,
    '707_386': 32.0879,
    '707_387': 32.1764,
    '707_388': 32.2697,
    '707_389': 32.3464,
    '707_390': 32.396,
    '707_391': 32.4307,
    '707_392': 32.4597,
    '707_393': 32.4288,
    '707_394': 32.4452,
    '707_395': 32.4483,
    '707_396': 32.4388,
    '707_397': 32.4172,
    '707_398': 32.3851,
    '707_399': 32.3482,
    '707_400': 32.3152,
    '707_401': 32.2909,
    '707_402': 32.2881,
    '707_405': 32.2227,
    '707_406': 32.2569,
    '707_407': 32.2956,
    '707_408': 32.3323,
    '707_409': 32.3614,
    '707_410': 32.3625,
    '707_411': 32.3368,
    '707_412': 32.3169,
    '707_413': 32.3104,
    '707_414': 32.3095,
    '707_415': 32.2869,
    '707_416': 32.2477,
    '707_417': 32.2113,
    '707_418': 32.1865,
    '707_419': 32.1661,
    '707_420': 32.1406,
    '707_421': 32.0903,
    '707_422': 32.0242,
    '707_423': 31.9631,
    '707_424': 31.9095,
    '707_425': 31.8571,
    '707_426': 31.8159,
    '707_427': 31.7756,
    '707_428': 31.7308,
    '707_429': 31.68,
    '707_430': 31.6227,
    '707_431': 31.5858,
    '707_432': 31.5857,
    '707_433': 31.6024,
    '707_434': 31.6057,
    '707_435': 31.5846,
    '707_436': 31.5262,
    '707_437': 31.4318,
    '707_438': 31.3184,
    '707_439': 31.1964,
    '707_440': 31.0697,
    '707_441': 30.9403,
    '707_442': 30.8138,
    '707_443': 30.6944,
    '707_444': 30.5818,
    '707_445': 30.4757,
    '707_446': 30.3756,
    '707_447': 30.2799,
    '707_448': 30.1826,
    '707_449': 30.068,
    '707_450': 29.9143,
    '707_451': 29.7266,
    '707_452': 29.5074,
    '707_453': 29.2769,
    '707_454': 29.0337,
    '707_455': 28.7905,
    '707_456': 28.554,
    '707_457': 28.3038,
    '707_458': 28.0351,
    '707_459': 27.7691,
    '707_460': 27.5175,
    '707_461': 27.2794,
    '707_462': 27.05,
    '707_463': 26.8382,
    '707_464': 26.6523,
    '708_384': 31.9202,
    '708_385': 31.9651,
    '708_386': 32.044,
    '708_387': 32.1369,
    '708_388': 32.2321,
    '708_389': 32.3156,
    '708_390': 32.3797,
    '708_391': 32.4244,
    '708_392': 32.46,
    '708_393': 32.4183,
    '708_394': 32.4339,
    '708_395': 32.4449,
    '708_396': 32.4439,
    '708_397': 32.4291,
    '708_398': 32.4073,
    '708_399': 32.3819,
    '708_400': 32.3548,
    '708_401': 32.3319,
    '708_402': 32.3247,
    '708_405': 32.2558,
    '708_406': 32.2832,
    '708_407': 32.3143,
    '708_408': 32.345,
    '708_409': 32.3643,
    '708_410': 32.3564,
    '708_411': 32.3371,
    '708_412': 32.3204,
    '708_413': 32.3076,
    '708_414': 32.3035,
    '708_415': 32.2856,
    '708_416': 32.2453,
    '708_417': 32.2079,
    '708_418': 32.1926,
    '708_419': 32.1819,
    '708_420': 32.1611,
    '708_421': 32.1273,
    '708_422': 32.0794,
    '708_423': 32.0284,
    '708_424': 31.9853,
    '708_425': 31.9386,
    '708_426': 31.8966,
    '708_427': 31.8457,
    '708_428': 31.7882,
    '708_429': 31.7236,
    '708_430': 31.6638,
    '708_431': 31.6241,
    '708_432': 31.6193,
    '708_433': 31.6368,
    '708_434': 31.6413,
    '708_435': 31.6228,
    '708_436': 31.5666,
    '708_437': 31.471,
    '708_438': 31.356,
    '708_439': 31.2326,
    '708_440': 31.1048,
    '708_441': 30.9753,
    '708_442': 30.8472,
    '708_443': 30.7243,
    '708_444': 30.6083,
    '708_445': 30.4968,
    '708_446': 30.3933,
    '708_447': 30.2891,
    '708_448': 30.179,
    '708_449': 30.052,
    '708_450': 29.8891,
    '708_451': 29.696,
    '708_452': 29.4837,
    '708_453': 29.267,
    '708_454': 29.0222,
    '708_455': 28.7731,
    '708_456': 28.5282,
    '708_457': 28.2802,
    '708_458': 28.0166,
    '708_459': 27.7585,
    '708_460': 27.5122,
    '708_461': 27.2753,
    '708_462': 27.0453,
    '708_463': 26.8309,
    '708_464': 26.6521,
    '709_392': 32.3486,
    '709_393': 32.3908,
    '709_394': 32.4171,
    '709_395': 32.4402,
    '709_396': 32.4499,
    '709_397': 32.4389,
    '709_398': 32.4221,
    '709_399': 32.4101,
    '709_400': 32.3942,
    '709_401': 32.3792,
    '709_402': 32.3713,
    '709_405': 32.2888,
    '709_406': 32.3066,
    '709_407': 32.3282,
    '709_408': 32.3542,
    '709_409': 32.363,
    '709_410': 32.3506,
    '709_411': 32.3406,
    '709_412': 32.3273,
    '709_413': 32.3176,
    '709_414': 32.3137,
    '709_415': 32.2883,
    '709_416': 32.2494,
    '709_417': 32.2194,
    '709_418': 32.2174,
    '709_419': 32.2097,
    '709_420': 32.1887,
    '709_421': 32.1671,
    '709_422': 32.131,
    '709_423': 32.0893,
    '709_424': 32.0556,
    '709_425': 32.0174,
    '709_426': 31.9706,
    '709_427': 31.9064,
    '709_428': 31.8343,
    '709_429': 31.7596,
    '709_430': 31.7012,
    '709_431': 31.6659,
    '709_432': 31.661,
    '709_433': 31.6759,
    '709_434': 31.6826,
    '709_435': 31.6665,
    '709_436': 31.6096,
    '709_437': 31.5144,
    '709_438': 31.3995,
    '709_439': 31.2741,
    '709_440': 31.1445,
    '709_441': 31.0138,
    '709_442': 30.8842,
    '709_443': 30.7566,
    '709_444': 30.6351,
    '709_445': 30.5211,
    '709_446': 30.4164,
    '709_447': 30.3042,
    '709_448': 30.1829,
    '709_449': 30.0368,
    '709_450': 29.8651,
    '709_451': 29.6642,
    '709_452': 29.4539,
    '709_453': 29.2427,
    '709_454': 29.0061,
    '709_455': 28.7518,
    '709_456': 28.5003,
    '709_457': 28.251,
    '709_458': 27.9979,
    '709_459': 27.751,
    '709_460': 27.5123,
    '709_461': 27.2782,
    '709_462': 27.0527,
    '709_463': 26.8425,
    '709_464': 26.6559,
    '710_392': 32.3131,
    '710_393': 32.3607,
    '710_394': 32.3968,
    '710_395': 32.4274,
    '710_396': 32.4441,
    '710_397': 32.4359,
    '710_398': 32.4234,
    '710_399': 32.4167,
    '710_400': 32.4089,
    '710_401': 32.4022,
    '710_402': 32.3991,
    '710_405': 32.3123,
    '710_406': 32.3246,
    '710_407': 32.3409,
    '710_408': 32.3616,
    '710_409': 32.3673,
    '710_410': 32.3629,
    '710_411': 32.3558,
    '710_412': 32.3453,
    '710_413': 32.3399,
    '710_414': 32.3304,
    '710_415': 32.3014,
    '710_416': 32.2668,
    '710_417': 32.2416,
    '710_418': 32.2357,
    '710_419': 32.2308,
    '710_420': 32.2159,
    '710_421': 32.1991,
    '710_422': 32.1784,
    '710_423': 32.1556,
    '710_424': 32.1269,
    '710_425': 32.0904,
    '710_426': 32.0344,
    '710_427': 31.9569,
    '710_428': 31.8742,
    '710_429': 31.7968,
    '710_430': 31.7459,
    '710_431': 31.7196,
    '710_432': 31.7153,
    '710_433': 31.7266,
    '710_434': 31.7341,
    '710_435': 31.7184,
    '710_436': 31.663,
    '710_437': 31.5715,
    '710_438': 31.4537,
    '710_439': 31.321,
    '710_440': 31.1892,
    '710_441': 31.0559,
    '710_442': 30.9233,
    '710_443': 30.7907,
    '710_444': 30.6658,
    '710_445': 30.5487,
    '710_446': 30.4406,
    '710_447': 30.3241,
    '710_448': 30.197,
    '710_449': 30.0376,
    '710_450': 29.8542,
    '710_451': 29.6494,
    '710_452': 29.4345,
    '710_453': 29.2159,
    '710_454': 28.9837,
    '710_455': 28.733,
    '710_456': 28.4823,
    '710_457': 28.236,
    '710_458': 27.9913,
    '710_459': 27.7534,
    '710_460': 27.5183,
    '710_461': 27.2884,
    '710_462': 27.0667,
    '710_463': 26.8575,
    '710_464': 26.6656,
    '711_392': 32.2705,
    '711_393': 32.3333,
    '711_394': 32.3727,
    '711_395': 32.4031,
    '711_396': 32.4175,
    '711_397': 32.4206,
    '711_398': 32.415,
    '711_399': 32.4116,
    '711_400': 32.4071,
    '711_401': 32.4052,
    '711_402': 32.4043,
    '711_405': 32.3269,
    '711_406': 32.3367,
    '711_407': 32.352,
    '711_408': 32.3693,
    '711_409': 32.3813,
    '711_410': 32.3856,
    '711_411': 32.3813,
    '711_412': 32.3752,
    '711_413': 32.3667,
    '711_414': 32.3505,
    '711_415': 32.3251,
    '711_416': 32.2947,
    '711_417': 32.2728,
    '711_418': 32.262,
    '711_419': 32.2502,
    '711_420': 32.2316,
    '711_421': 32.219,
    '711_422': 32.2214,
    '711_423': 32.2165,
    '711_424': 32.1911,
    '711_425': 32.1515,
    '711_426': 32.0886,
    '711_427': 32.0002,
    '711_428': 31.9129,
    '711_429': 31.8428,
    '711_430': 31.7993,
    '711_431': 31.7812,
    '711_432': 31.7834,
    '711_433': 31.7921,
    '711_434': 31.7974,
    '711_435': 31.7801,
    '711_436': 31.7312,
    '711_437': 31.6399,
    '711_438': 31.5134,
    '711_439': 31.3709,
    '711_440': 31.2343,
    '711_441': 31.0977,
    '711_442': 30.9607,
    '711_443': 30.8247,
    '711_444': 30.6979,
    '711_445': 30.578,
    '711_446': 30.4635,
    '711_447': 30.3453,
    '711_448': 30.2134,
    '711_449': 30.0469,
    '711_450': 29.857,
    '711_451': 29.6523,
    '711_452': 29.4343,
    '711_453': 29.2085,
    '711_454': 28.9721,
    '711_455': 28.7244,
    '711_456': 28.4772,
    '711_457': 28.236,
    '711_458': 27.9959,
    '711_459': 27.7617,
    '711_460': 27.5303,
    '711_461': 27.3039,
    '711_462': 27.0844,
    '711_463': 26.8741,
    '711_464': 26.6759,
    '712_392': 32.226,
    '712_393': 32.2973,
    '712_394': 32.3464,
    '712_395': 32.3728,
    '712_396': 32.3888,
    '712_397': 32.3972,
    '712_398': 32.3971,
    '712_399': 32.3958,
    '712_400': 32.3934,
    '712_401': 32.3952,
    '712_402': 32.3993,
    '712_405': 32.3322,
    '712_406': 32.3433,
    '712_407': 32.359,
    '712_408': 32.3769,
    '712_409': 32.3952,
    '712_410': 32.4111,
    '712_411': 32.4105,
    '712_412': 32.4062,
    '712_413': 32.3971,
    '712_414': 32.3847,
    '712_415': 32.3663,
    '712_416': 32.3373,
    '712_417': 32.3121,
    '712_418': 32.2939,
    '712_419': 32.2787,
    '712_420': 32.246,
    '712_421': 32.2354,
    '712_422': 32.2501,
    '712_423': 32.2517,
    '712_424': 32.2339,
    '712_425': 32.1941,
    '712_426': 32.1263,
    '712_427': 32.0406,
    '712_428': 31.958,
    '712_429': 31.8955,
    '712_430': 31.86,
    '712_431': 31.8512,
    '712_432': 31.8619,
    '712_433': 31.872,
    '712_434': 31.8759,
    '712_435': 31.853,
    '712_436': 31.8033,
    '712_437': 31.7087,
    '712_438': 31.5698,
    '712_439': 31.4166,
    '712_440': 31.2743,
    '712_441': 31.1334,
    '712_442': 30.9903,
    '712_443': 30.8566,
    '712_444': 30.7301,
    '712_445': 30.6083,
    '712_446': 30.49,
    '712_447': 30.3695,
    '712_448': 30.2304,
    '712_449': 30.0594,
    '712_450': 29.869,
    '712_451': 29.6689,
    '712_452': 29.4504,
    '712_453': 29.2159,
    '712_454': 28.9742,
    '712_455': 28.729,
    '712_456': 28.4836,
    '712_457': 28.2426,
    '712_458': 28.0046,
    '712_459': 27.7736,
    '712_460': 27.5462,
    '712_461': 27.3235,
    '712_462': 27.107,
    '712_463': 26.899,
    '712_464': 26.7024,
    '713_392': 32.1898,
    '713_393': 32.2638,
    '713_394': 32.3175,
    '713_395': 32.347,
    '713_396': 32.3648,
    '713_397': 32.3736,
    '713_398': 32.3762,
    '713_399': 32.3756,
    '713_400': 32.3734,
    '713_401': 32.377,
    '713_402': 32.385,
    '713_405': 32.3434,
    '713_406': 32.3515,
    '713_407': 32.366,
    '713_408': 32.3858,
    '713_409': 32.4102,
    '713_410': 32.4369,
    '713_411': 32.4465,
    '713_412': 32.4466,
    '713_413': 32.4396,
    '713_414': 32.4346,
    '713_415': 32.4225,
    '713_416': 32.3909,
    '713_417': 32.3591,
    '713_418': 32.3244,
    '713_419': 32.3026,
    '713_420': 32.2704,
    '713_421': 32.2544,
    '713_422': 32.2648,
    '713_423': 32.2718,
    '713_424': 32.2598,
    '713_425': 32.2238,
    '713_426': 32.1614,
    '713_427': 32.0833,
    '713_428': 32.0065,
    '713_429': 31.9512,
    '713_430': 31.925,
    '713_431': 31.9244,
    '713_432': 31.942,
    '713_433': 31.9572,
    '713_434': 31.9597,
    '713_435': 31.9315,
    '713_436': 31.8671,
    '713_437': 31.7535,
    '713_438': 31.6133,
    '713_439': 31.4462,
    '713_440': 31.2972,
    '713_441': 31.1562,
    '713_442': 31.0184,
    '713_443': 30.8917,
    '713_444': 30.7647,
    '713_445': 30.6439,
    '713_446': 30.5222,
    '713_447': 30.3954,
    '713_448': 30.2485,
    '713_449': 30.0735,
    '713_450': 29.8853,
    '713_451': 29.6936,
    '713_452': 29.4738,
    '713_453': 29.2332,
    '713_454': 28.9894,
    '713_455': 28.7445,
    '713_456': 28.498,
    '713_457': 28.257,
    '713_458': 28.0203,
    '713_459': 27.7904,
    '713_460': 27.5686,
    '713_461': 27.3495,
    '713_462': 27.1356,
    '713_463': 26.929,
    '713_464': 26.7326,
    '714_392': 32.1617,
    '714_393': 32.2381,
    '714_394': 32.2932,
    '714_395': 32.3266,
    '714_396': 32.3436,
    '714_397': 32.3511,
    '714_398': 32.355,
    '714_399': 32.3548,
    '714_400': 32.3526,
    '714_401': 32.3574,
    '714_402': 32.3708,
    '714_405': 32.3504,
    '714_406': 32.3529,
    '714_407': 32.3697,
    '714_408': 32.396,
    '714_409': 32.4295,
    '714_410': 32.4651,
    '714_411': 32.4878,
    '714_412': 32.4922,
    '714_413': 32.4889,
    '714_414': 32.4892,
    '714_415': 32.479,
    '714_416': 32.4435,
    '714_417': 32.4014,
    '714_418': 32.3562,
    '714_419': 32.3272,
    '714_420': 32.298,
    '714_421': 32.2787,
    '714_422': 32.2778,
    '714_423': 32.287,
    '714_424': 32.2773,
    '714_425': 32.2509,
    '714_426': 32.1998,
    '714_427': 32.1289,
    '714_428': 32.0573,
    '714_429': 32.0062,
    '714_430': 31.9882,
    '714_431': 31.9997,
    '714_432': 32.0249,
    '714_433': 32.043,
    '714_434': 32.0404,
    '714_435': 32.0003,
    '714_436': 31.9226,
    '714_437': 31.8031,
    '714_438': 31.6381,
    '714_439': 31.4639,
    '714_440': 31.3125,
    '714_441': 31.1765,
    '714_442': 31.0479,
    '714_443': 30.926,
    '714_444': 30.8018,
    '714_445': 30.681,
    '714_446': 30.5564,
    '714_447': 30.4212,
    '714_448': 30.2684,
    '714_449': 30.0917,
    '714_450': 29.9048,
    '714_451': 29.7161,
    '714_452': 29.4976,
    '714_453': 29.2541,
    '714_454': 29.009,
    '714_455': 28.7663,
    '714_456': 28.5199,
    '714_457': 28.2783,
    '714_458': 28.0437,
    '714_459': 27.8155,
    '714_460': 27.5973,
    '714_461': 27.3813,
    '714_462': 27.1689,
    '714_463': 26.962,
    '714_464': 26.7638,
    '715_392': 32.1362,
    '715_393': 32.2165,
    '715_394': 32.2748,
    '715_395': 32.3104,
    '715_396': 32.3289,
    '715_397': 32.3346,
    '715_398': 32.3402,
    '715_399': 32.3423,
    '715_400': 32.3429,
    '715_401': 32.3494,
    '715_402': 32.3577,
    '715_405': 32.3472,
    '715_406': 32.3499,
    '715_407': 32.3738,
    '715_408': 32.4078,
    '715_409': 32.4504,
    '715_410': 32.4965,
    '715_411': 32.5272,
    '715_412': 32.5401,
    '715_413': 32.544,
    '715_414': 32.5495,
    '715_415': 32.5328,
    '715_416': 32.4882,
    '715_417': 32.4391,
    '715_418': 32.3943,
    '715_419': 32.3594,
    '715_420': 32.3324,
    '715_421': 32.3116,
    '715_422': 32.3031,
    '715_423': 32.304,
    '715_424': 32.2977,
    '715_425': 32.2837,
    '715_426': 32.2425,
    '715_427': 32.1727,
    '715_428': 32.1023,
    '715_429': 32.0578,
    '715_430': 32.0487,
    '715_431': 32.0726,
    '715_432': 32.1012,
    '715_433': 32.1169,
    '715_434': 32.1029,
    '715_435': 32.0529,
    '715_436': 31.9605,
    '715_437': 31.8249,
    '715_438': 31.6523,
    '715_439': 31.4786,
    '715_440': 31.33,
    '715_441': 31.1994,
    '715_442': 31.0827,
    '715_443': 30.9636,
    '715_444': 30.8419,
    '715_445': 30.7204,
    '715_446': 30.5895,
    '715_447': 30.4474,
    '715_448': 30.2908,
    '715_449': 30.1121,
    '715_450': 29.9246,
    '715_451': 29.7331,
    '715_452': 29.5148,
    '715_453': 29.2741,
    '715_454': 29.0312,
    '715_455': 28.7913,
    '715_456': 28.5466,
    '715_457': 28.3057,
    '715_458': 28.0721,
    '715_459': 27.8479,
    '715_460': 27.6341,
    '715_461': 27.4205,
    '715_462': 27.2085,
    '715_463': 27.0004,
    '715_464': 26.7994,
    '716_405': 32.351,
    '716_406': 32.3598,
    '716_407': 32.3842,
    '716_408': 32.4208,
    '716_409': 32.4707,
    '716_410': 32.5284,
    '716_411': 32.5678,
    '716_412': 32.5876,
    '716_413': 32.5992,
    '716_414': 32.6093,
    '716_415': 32.5797,
    '716_416': 32.5312,
    '716_417': 32.4786,
    '716_418': 32.4366,
    '716_419': 32.3992,
    '716_420': 32.3729,
    '716_421': 32.3508,
    '716_422': 32.3369,
    '716_423': 32.3296,
    '716_424': 32.3247,
    '716_425': 32.31,
    '716_426': 32.2684,
    '716_427': 32.2003,
    '716_428': 32.1379,
    '716_429': 32.1018,
    '716_430': 32.1008,
    '716_431': 32.1301,
    '716_432': 32.1576,
    '716_433': 32.1647,
    '716_434': 32.139,
    '716_435': 32.0755,
    '716_436': 31.9756,
    '716_437': 31.8307,
    '716_438': 31.6573,
    '716_439': 31.4923,
    '716_440': 31.3483,
    '716_441': 31.2246,
    '716_442': 31.1134,
    '716_443': 30.9995,
    '716_444': 30.88,
    '716_445': 30.756,
    '716_446': 30.623,
    '716_447': 30.4767,
    '716_448': 30.3165,
    '716_449': 30.138,
    '716_450': 29.9445,
    '716_451': 29.7448,
    '716_452': 29.5294,
    '716_453': 29.2941,
    '716_454': 29.0554,
    '716_455': 28.816,
    '716_456': 28.5755,
    '716_457': 28.3371,
    '716_458': 28.1034,
    '716_459': 27.8843,
    '716_460': 27.677,
    '716_461': 27.4655,
    '716_462': 27.2524,
    '716_463': 27.0421,
    '716_464': 26.8383,
    '717_405': 32.3579,
    '717_406': 32.3698,
    '717_407': 32.3958,
    '717_408': 32.4337,
    '717_409': 32.4878,
    '717_410': 32.5542,
    '717_411': 32.6053,
    '717_412': 32.6335,
    '717_413': 32.6505,
    '717_414': 32.6623,
    '717_415': 32.636,
    '717_416': 32.5822,
    '717_417': 32.5236,
    '717_418': 32.4794,
    '717_419': 32.4415,
    '717_420': 32.4149,
    '717_421': 32.3974,
    '717_422': 32.3809,
    '717_423': 32.3667,
    '717_424': 32.3539,
    '717_425': 32.3269,
    '717_426': 32.2796,
    '717_427': 32.2206,
    '717_428': 32.1684,
    '717_429': 32.1394,
    '717_430': 32.1431,
    '717_431': 32.169,
    '717_432': 32.186,
    '717_433': 32.1849,
    '717_434': 32.1512,
    '717_435': 32.0851,
    '717_436': 31.9699,
    '717_437': 31.823,
    '717_438': 31.6551,
    '717_439': 31.5014,
    '717_440': 31.3688,
    '717_441': 31.2558,
    '717_442': 31.1502,
    '717_443': 31.0396,
    '717_444': 30.925,
    '717_445': 30.8021,
    '717_446': 30.6685,
    '717_447': 30.5182,
    '717_448': 30.3513,
    '717_449': 30.1687,
    '717_450': 29.9716,
    '717_451': 29.7657,
    '717_452': 29.5513,
    '717_453': 29.3228,
    '717_454': 29.0858,
    '717_455': 28.8475,
    '717_456': 28.6098,
    '717_457': 28.3736,
    '717_458': 28.1407,
    '717_459': 27.9276,
    '717_460': 27.729,
    '717_461': 27.5174,
    '717_462': 27.3015,
    '717_463': 27.0881,
    '717_464': 26.8812,
    '717_465': 26.6845,
    '717_466': 26.5023,
    '717_467': 26.3287,
    '717_468': 26.1657,
    '717_469': 26.0145,
    '717_470': 25.8753,
    '717_471': 25.7636,
    '717_472': 25.6834,
    '717_473': 25.6396,
    '717_474': 25.6296,
    '717_475': 25.6337,
    '717_476': 25.6588,
    '717_477': 25.7025,
    '717_478': 25.7647,
    '717_479': 25.8388,
    '717_480': 25.9206,
    '718_405': 32.3737,
    '718_406': 32.388,
    '718_407': 32.411,
    '718_408': 32.4471,
    '718_409': 32.5026,
    '718_410': 32.5714,
    '718_411': 32.6284,
    '718_412': 32.6649,
    '718_413': 32.6952,
    '718_414': 32.709,
    '718_415': 32.6925,
    '718_416': 32.6315,
    '718_417': 32.5732,
    '718_418': 32.5266,
    '718_419': 32.4919,
    '718_420': 32.4643,
    '718_421': 32.447,
    '718_422': 32.426,
    '718_423': 32.4022,
    '718_424': 32.3761,
    '718_425': 32.339,
    '718_426': 32.2903,
    '718_427': 32.2421,
    '718_428': 32.2013,
    '718_429': 32.1791,
    '718_430': 32.1786,
    '718_431': 32.1907,
    '718_432': 32.196,
    '718_433': 32.1836,
    '718_434': 32.145,
    '718_435': 32.0666,
    '718_436': 31.9467,
    '718_437': 31.8043,
    '718_438': 31.6532,
    '718_439': 31.5161,
    '718_440': 31.3998,
    '718_441': 31.2994,
    '718_442': 31.2006,
    '718_443': 31.0944,
    '718_444': 30.987,
    '718_445': 30.8671,
    '718_446': 30.7322,
    '718_447': 30.5748,
    '718_448': 30.4002,
    '718_449': 30.2123,
    '718_450': 30.0134,
    '718_451': 29.8025,
    '718_452': 29.5872,
    '718_453': 29.3609,
    '718_454': 29.1258,
    '718_455': 28.8895,
    '718_456': 28.6544,
    '718_457': 28.4183,
    '718_458': 28.1868,
    '718_459': 27.9749,
    '718_460': 27.7871,
    '718_461': 27.5735,
    '718_462': 27.355,
    '718_463': 27.1405,
    '718_464': 26.9322,
    '718_465': 26.7329,
    '718_466': 26.5516,
    '718_467': 26.3778,
    '718_468': 26.2092,
    '718_469': 26.052,
    '718_470': 25.9128,
    '718_471': 25.8043,
    '718_472': 25.7249,
    '718_473': 25.6789,
    '718_474': 25.6651,
    '718_475': 25.6791,
    '718_476': 25.7015,
    '718_477': 25.7438,
    '718_478': 25.8049,
    '718_479': 25.8785,
    '718_480': 25.9603,
    '719_405': 32.3819,
    '719_406': 32.3986,
    '719_407': 32.4203,
    '719_408': 32.4545,
    '719_409': 32.509,
    '719_410': 32.5714,
    '719_411': 32.6308,
    '719_412': 32.6752,
    '719_413': 32.7116,
    '719_414': 32.7327,
    '719_415': 32.7265,
    '719_416': 32.674,
    '719_417': 32.6201,
    '719_418': 32.5733,
    '719_419': 32.5435,
    '719_420': 32.5117,
    '719_421': 32.4907,
    '719_422': 32.4623,
    '719_423': 32.4272,
    '719_424': 32.3906,
    '719_425': 32.347,
    '719_426': 32.3058,
    '719_427': 32.2706,
    '719_428': 32.2414,
    '719_429': 32.221,
    '719_430': 32.2012,
    '719_431': 32.1975,
    '719_432': 32.1858,
    '719_433': 32.1617,
    '719_434': 32.1188,
    '719_435': 32.0377,
    '719_436': 31.9182,
    '719_437': 31.7891,
    '719_438': 31.6622,
    '719_439': 31.5432,
    '719_440': 31.4402,
    '719_441': 31.3531,
    '719_442': 31.2638,
    '719_443': 31.1697,
    '719_444': 31.0719,
    '719_445': 30.948,
    '719_446': 30.8088,
    '719_447': 30.6431,
    '719_448': 30.4607,
    '719_449': 30.267,
    '719_450': 30.0606,
    '719_451': 29.8487,
    '719_452': 29.6314,
    '719_453': 29.4046,
    '719_454': 29.1735,
    '719_455': 28.9409,
    '719_456': 28.7064,
    '719_457': 28.4698,
    '719_458': 28.2365,
    '719_459': 28.0207,
    '719_460': 27.8228,
    '719_461': 27.6176,
    '719_462': 27.4065,
    '719_463': 27.1971,
    '719_464': 26.9936,
    '719_465': 26.7984,
    '719_466': 26.6126,
    '719_467': 26.4351,
    '719_468': 26.2614,
    '719_469': 26.1009,
    '719_470': 25.9667,
    '719_471': 25.8572,
    '719_472': 25.7761,
    '719_473': 25.7286,
    '719_474': 25.7154,
    '719_475': 25.7269,
    '719_476': 25.7452,
    '719_477': 25.7859,
    '719_478': 25.8454,
    '719_479': 25.9185,
    '719_480': 26.0015,
    '720_405': 32.3798,
    '720_406': 32.399,
    '720_407': 32.4215,
    '720_408': 32.4542,
    '720_409': 32.5006,
    '720_410': 32.5555,
    '720_411': 32.6122,
    '720_412': 32.6634,
    '720_413': 32.7029,
    '720_414': 32.7311,
    '720_415': 32.7353,
    '720_416': 32.6983,
    '720_417': 32.657,
    '720_418': 32.6195,
    '720_419': 32.5875,
    '720_420': 32.5518,
    '720_421': 32.5215,
    '720_422': 32.4846,
    '720_423': 32.4435,
    '720_424': 32.4013,
    '720_425': 32.3606,
    '720_426': 32.3292,
    '720_427': 32.3074,
    '720_428': 32.2898,
    '720_429': 32.2621,
    '720_430': 32.2192,
    '720_431': 32.1998,
    '720_432': 32.1696,
    '720_433': 32.1323,
    '720_434': 32.0881,
    '720_435': 32.0169,
    '720_436': 31.9086,
    '720_437': 31.7959,
    '720_438': 31.6864,
    '720_439': 31.5814,
    '720_440': 31.4867,
    '720_441': 31.412,
    '720_442': 31.3407,
    '720_443': 31.2587,
    '720_444': 31.1598,
    '720_445': 31.0357,
    '720_446': 30.8903,
    '720_447': 30.7208,
    '720_448': 30.5326,
    '720_449': 30.3309,
    '720_450': 30.1191,
    '720_451': 29.9042,
    '720_452': 29.6859,
    '720_453': 29.4588,
    '720_454': 29.2295,
    '720_455': 29.0004,
    '720_456': 28.7649,
    '720_457': 28.527,
    '720_458': 28.2938,
    '720_459': 28.0742,
    '720_460': 27.8682,
    '720_461': 27.6662,
    '720_462': 27.4612,
    '720_463': 27.2577,
    '720_464': 27.0595,
    '720_465': 26.8692,
    '720_466': 26.6798,
    '720_467': 26.4964,
    '720_468': 26.3221,
    '720_469': 26.1608,
    '720_470': 26.0278,
    '720_471': 25.9206,
    '720_472': 25.8419,
    '720_473': 25.7926,
    '720_474': 25.7712,
    '720_475': 25.7748,
    '720_476': 25.7963,
    '720_477': 25.8292,
    '720_478': 25.8805,
    '720_479': 25.9584,
    '720_480': 26.0454,
    '721_405': 32.3754,
    '721_406': 32.3931,
    '721_407': 32.415,
    '721_408': 32.4446,
    '721_409': 32.4833,
    '721_410': 32.5307,
    '721_411': 32.5783,
    '721_412': 32.6301,
    '721_413': 32.6731,
    '721_414': 32.7058,
    '721_415': 32.7203,
    '721_416': 32.7001,
    '721_417': 32.674,
    '721_418': 32.6505,
    '721_419': 32.6259,
    '721_420': 32.5884,
    '721_421': 32.5475,
    '721_422': 32.5052,
    '721_423': 32.461,
    '721_424': 32.4156,
    '721_425': 32.3811,
    '721_426': 32.3582,
    '721_427': 32.3462,
    '721_428': 32.3365,
    '721_429': 32.3046,
    '721_430': 32.2453,
    '721_431': 32.2061,
    '721_432': 32.1608,
    '721_433': 32.1178,
    '721_434': 32.0716,
    '721_435': 32.0115,
    '721_436': 31.9229,
    '721_437': 31.8278,
    '721_438': 31.7317,
    '721_439': 31.639,
    '721_440': 31.5503,
    '721_441': 31.4794,
    '721_442': 31.4156,
    '721_443': 31.3412,
    '721_444': 31.245,
    '721_445': 31.1249,
    '721_446': 30.9751,
    '721_447': 30.8023,
    '721_448': 30.614,
    '721_449': 30.4109,
    '721_450': 30.1955,
    '721_451': 29.9753,
    '721_452': 29.7515,
    '721_453': 29.523,
    '721_454': 29.2953,
    '721_455': 29.0644,
    '721_456': 28.8288,
    '721_457': 28.5921,
    '721_458': 28.3608,
    '721_459': 28.14,
    '721_460': 27.928,
    '721_461': 27.726,
    '721_462': 27.5238,
    '721_463': 27.3229,
    '721_464': 27.1257,
    '721_465': 26.9363,
    '721_466': 26.7495,
    '721_467': 26.5625,
    '721_468': 26.3901,
    '721_469': 26.2336,
    '721_470': 26.1005,
    '721_471': 25.9958,
    '721_472': 25.9171,
    '721_473': 25.863,
    '721_474': 25.83,
    '721_475': 25.8262,
    '721_476': 25.8438,
    '721_477': 25.8773,
    '721_478': 25.9281,
    '721_479': 25.9978,
    '721_480': 26.0784,
    '722_405': 32.3653,
    '722_406': 32.3844,
    '722_407': 32.4047,
    '722_408': 32.4298,
    '722_409': 32.4633,
    '722_410': 32.5012,
    '722_411': 32.5394,
    '722_412': 32.5845,
    '722_413': 32.6288,
    '722_414': 32.6647,
    '722_415': 32.6881,
    '722_416': 32.6834,
    '722_417': 32.6698,
    '722_418': 32.6662,
    '722_419': 32.6536,
    '722_420': 32.621,
    '722_421': 32.5766,
    '722_422': 32.5313,
    '722_423': 32.4854,
    '722_424': 32.4372,
    '722_425': 32.4074,
    '722_426': 32.3932,
    '722_427': 32.3875,
    '722_428': 32.3807,
    '722_429': 32.3488,
    '722_430': 32.282,
    '722_431': 32.2277,
    '722_432': 32.179,
    '722_433': 32.135,
    '722_434': 32.0873,
    '722_435': 32.0323,
    '722_436': 31.9627,
    '722_437': 31.8864,
    '722_438': 31.8057,
    '722_439': 31.7206,
    '722_440': 31.6353,
    '722_441': 31.5628,
    '722_442': 31.4921,
    '722_443': 31.4164,
    '722_444': 31.3242,
    '722_445': 31.205,
    '722_446': 31.0537,
    '722_447': 30.886,
    '722_448': 30.7061,
    '722_449': 30.5069,
    '722_450': 30.2879,
    '722_451': 30.0582,
    '722_452': 29.8274,
    '722_453': 29.5966,
    '722_454': 29.3659,
    '722_455': 29.1353,
    '722_456': 28.9002,
    '722_457': 28.6661,
    '722_458': 28.437,
    '722_459': 28.2161,
    '722_460': 28.0028,
    '722_461': 27.7995,
    '722_462': 27.5979,
    '722_463': 27.3978,
    '722_464': 27.2009,
    '722_465': 27.0082,
    '722_466': 26.8194,
    '722_467': 26.6355,
    '722_468': 26.4674,
    '722_469': 26.3155,
    '722_470': 26.1845,
    '722_471': 26.078,
    '722_472': 25.9951,
    '722_473': 25.9321,
    '722_474': 25.8885,
    '722_475': 25.8812,
    '722_476': 25.8971,
    '722_477': 25.9344,
    '722_478': 25.9807,
    '722_479': 26.0416,
    '722_480': 26.1159,
    '723_373': 30.7444,
    '723_374': 30.7787,
    '723_375': 30.8133,
    '723_376': 30.8404,
    '723_377': 30.8597,
    '723_378': 30.8814,
    '723_379': 30.9069,
    '723_380': 30.9328,
    '723_381': 30.9591,
    '723_382': 30.9725,
    '723_383': 30.9893,
    '723_384': 31.0124,
    '723_385': 31.051,
    '723_386': 31.101,
    '723_387': 31.1681,
    '723_388': 31.2608,
    '723_389': 31.3835,
    '723_390': 31.5255,
    '723_391': 31.6647,
    '723_392': 31.7792,
    '723_393': 31.869,
    '723_394': 31.9398,
    '723_395': 32.0041,
    '723_396': 32.055,
    '723_397': 32.0953,
    '723_398': 32.1423,
    '723_399': 32.1804,
    '723_400': 32.2074,
    '723_401': 32.2438,
    '723_402': 32.2882,
    '723_403': 32.3313,
    '723_404': 32.3632,
    '723_405': 32.3753,
    '723_406': 32.3866,
    '723_407': 32.3997,
    '723_408': 32.4177,
    '723_409': 32.4458,
    '723_410': 32.4733,
    '723_411': 32.5026,
    '723_412': 32.5372,
    '723_413': 32.5748,
    '723_414': 32.6103,
    '723_415': 32.642,
    '723_416': 32.6541,
    '723_417': 32.6575,
    '723_418': 32.6702,
    '723_419': 32.6682,
    '723_420': 32.6496,
    '723_421': 32.6099,
    '723_422': 32.5613,
    '723_423': 32.5117,
    '723_424': 32.4664,
    '723_425': 32.4404,
    '723_426': 32.4361,
    '723_427': 32.4383,
    '723_428': 32.4357,
    '723_429': 32.4017,
    '723_430': 32.3381,
    '723_431': 32.2778,
    '723_432': 32.2311,
    '723_433': 32.1906,
    '723_434': 32.1398,
    '723_435': 32.087,
    '723_436': 32.0277,
    '723_437': 31.966,
    '723_438': 31.8987,
    '723_439': 31.8193,
    '723_440': 31.7329,
    '723_441': 31.6588,
    '723_442': 31.5773,
    '723_443': 31.4909,
    '723_444': 31.3933,
    '723_445': 31.2765,
    '723_446': 31.1331,
    '723_447': 30.9767,
    '723_448': 30.7958,
    '723_449': 30.6094,
    '723_450': 30.3863,
    '723_451': 30.1508,
    '723_452': 29.9142,
    '723_453': 29.6791,
    '723_454': 29.4438,
    '723_455': 29.2128,
    '723_456': 28.9793,
    '723_457': 28.7472,
    '723_458': 28.5227,
    '723_459': 28.3042,
    '723_460': 28.0908,
    '723_461': 27.8853,
    '723_462': 27.6846,
    '723_463': 27.4836,
    '723_464': 27.2854,
    '723_465': 27.0912,
    '723_466': 26.9047,
    '723_467': 26.726,
    '723_468': 26.5572,
    '723_469': 26.4044,
    '723_470': 26.2743,
    '723_471': 26.165,
    '723_472': 26.0768,
    '723_473': 26.0089,
    '723_474': 25.9627,
    '723_475': 25.9497,
    '723_476': 25.958,
    '723_477': 25.9873,
    '723_478': 26.0333,
    '723_479': 26.0862,
    '723_480': 26.1566,
    '724_373': 30.7607,
    '724_374': 30.7924,
    '724_375': 30.8273,
    '724_376': 30.8581,
    '724_377': 30.8832,
    '724_378': 30.9099,
    '724_379': 30.9344,
    '724_380': 30.9637,
    '724_381': 30.9946,
    '724_382': 31.0156,
    '724_383': 31.0372,
    '724_384': 31.0568,
    '724_385': 31.0943,
    '724_386': 31.1402,
    '724_387': 31.2004,
    '724_388': 31.287,
    '724_389': 31.4036,
    '724_390': 31.5445,
    '724_391': 31.6863,
    '724_392': 31.8064,
    '724_393': 31.9002,
    '724_394': 31.9714,
    '724_395': 32.0298,
    '724_396': 32.0786,
    '724_397': 32.1261,
    '724_398': 32.1658,
    '724_399': 32.1961,
    '724_400': 32.2286,
    '724_401': 32.2645,
    '724_402': 32.3096,
    '724_403': 32.3499,
    '724_404': 32.3747,
    '724_405': 32.3861,
    '724_406': 32.3929,
    '724_407': 32.3996,
    '724_408': 32.4105,
    '724_409': 32.4303,
    '724_410': 32.4497,
    '724_411': 32.4712,
    '724_412': 32.4971,
    '724_413': 32.5276,
    '724_414': 32.5619,
    '724_415': 32.5993,
    '724_416': 32.6298,
    '724_417': 32.6508,
    '724_418': 32.6671,
    '724_419': 32.6797,
    '724_420': 32.6739,
    '724_421': 32.6401,
    '724_422': 32.59,
    '724_423': 32.5419,
    '724_424': 32.5021,
    '724_425': 32.4853,
    '724_426': 32.489,
    '724_427': 32.4978,
    '724_428': 32.4962,
    '724_429': 32.4632,
    '724_430': 32.4074,
    '724_431': 32.3512,
    '724_432': 32.3074,
    '724_433': 32.2715,
    '724_434': 32.2213,
    '724_435': 32.1695,
    '724_436': 32.1092,
    '724_437': 32.0532,
    '724_438': 31.9947,
    '724_439': 31.9166,
    '724_440': 31.8255,
    '724_441': 31.7445,
    '724_442': 31.6599,
    '724_443': 31.5628,
    '724_444': 31.4637,
    '724_445': 31.3523,
    '724_446': 31.2164,
    '724_447': 31.0623,
    '724_448': 30.8931,
    '724_449': 30.7081,
    '724_450': 30.4894,
    '724_451': 30.2543,
    '724_452': 30.0103,
    '724_453': 29.7672,
    '724_454': 29.5297,
    '724_455': 29.2964,
    '724_456': 29.0651,
    '724_457': 28.8388,
    '724_458': 28.6202,
    '724_459': 28.405,
    '724_460': 28.1907,
    '724_461': 27.9844,
    '724_462': 27.7831,
    '724_463': 27.5795,
    '724_464': 27.375,
    '724_465': 27.1863,
    '724_466': 27.0022,
    '724_467': 26.8269,
    '724_468': 26.6566,
    '724_469': 26.5004,
    '724_470': 26.3707,
    '724_471': 26.258,
    '724_472': 26.166,
    '724_473': 26.0965,
    '724_474': 26.0474,
    '724_475': 26.0243,
    '724_476': 26.0246,
    '724_477': 26.0492,
    '724_478': 26.0949,
    '724_479': 26.1419,
    '724_480': 26.2146,
    '725_373': 30.79,
    '725_374': 30.8173,
    '725_375': 30.8457,
    '725_376': 30.8813,
    '725_377': 30.9127,
    '725_378': 30.946,
    '725_379': 30.976,
    '725_380': 31.004,
    '725_381': 31.0363,
    '725_382': 31.0702,
    '725_383': 31.103,
    '725_384': 31.1233,
    '725_385': 31.151,
    '725_386': 31.1856,
    '725_387': 31.2394,
    '725_388': 31.3198,
    '725_389': 31.43,
    '725_390': 31.5678,
    '725_391': 31.7138,
    '725_392': 31.8392,
    '725_393': 31.9368,
    '725_394': 32.0068,
    '725_395': 32.0614,
    '725_396': 32.1099,
    '725_397': 32.1548,
    '725_398': 32.1937,
    '725_399': 32.2241,
    '725_400': 32.2565,
    '725_401': 32.2913,
    '725_402': 32.3325,
    '725_403': 32.3664,
    '725_404': 32.387,
    '725_405': 32.3977,
    '725_406': 32.4031,
    '725_407': 32.4058,
    '725_408': 32.4119,
    '725_409': 32.4228,
    '725_410': 32.4329,
    '725_411': 32.4451,
    '725_412': 32.4638,
    '725_413': 32.4923,
    '725_414': 32.5285,
    '725_415': 32.576,
    '725_416': 32.6234,
    '725_417': 32.6647,
    '725_418': 32.6759,
    '725_419': 32.6942,
    '725_420': 32.6933,
    '725_421': 32.6605,
    '725_422': 32.6139,
    '725_423': 32.5709,
    '725_424': 32.5455,
    '725_425': 32.5433,
    '725_426': 32.5552,
    '725_427': 32.5642,
    '725_428': 32.5554,
    '725_429': 32.5231,
    '725_430': 32.474,
    '725_431': 32.4245,
    '725_432': 32.3852,
    '725_433': 32.3455,
    '725_434': 32.301,
    '725_435': 32.2517,
    '725_436': 32.196,
    '725_437': 32.1401,
    '725_438': 32.0825,
    '725_439': 32.0064,
    '725_440': 31.911,
    '725_441': 31.8202,
    '725_442': 31.7332,
    '725_443': 31.6405,
    '725_444': 31.5459,
    '725_445': 31.4297,
    '725_446': 31.2951,
    '725_447': 31.144,
    '725_448': 30.9902,
    '725_449': 30.8042,
    '725_450': 30.593,
    '725_451': 30.3642,
    '725_452': 30.1157,
    '725_453': 29.8633,
    '725_454': 29.6215,
    '725_455': 29.3885,
    '725_456': 29.1608,
    '725_457': 28.9419,
    '725_458': 28.7282,
    '725_459': 28.514,
    '725_460': 28.301,
    '725_461': 28.0945,
    '725_462': 27.8946,
    '725_463': 27.6943,
    '725_464': 27.4889,
    '725_465': 27.2933,
    '725_466': 27.1082,
    '725_467': 26.9389,
    '725_468': 26.7698,
    '725_469': 26.6132,
    '725_470': 26.4764,
    '725_471': 26.3591,
    '725_472': 26.2646,
    '725_473': 26.1943,
    '725_474': 26.1374,
    '725_475': 26.1028,
    '725_476': 26.0937,
    '725_477': 26.1095,
    '725_478': 26.1498,
    '725_479': 26.2101,
    '725_480': 26.2776,
    '726_373': 30.8198,
    '726_374': 30.8452,
    '726_375': 30.8673,
    '726_376': 30.8995,
    '726_377': 30.9375,
    '726_378': 30.9796,
    '726_379': 31.0128,
    '726_380': 31.0395,
    '726_381': 31.0712,
    '726_382': 31.1122,
    '726_383': 31.1434,
    '726_384': 31.1613,
    '726_385': 31.1846,
    '726_386': 31.2204,
    '726_387': 31.2724,
    '726_388': 31.348,
    '726_389': 31.4539,
    '726_390': 31.5879,
    '726_391': 31.7331,
    '726_392': 31.8609,
    '726_393': 31.9601,
    '726_394': 32.0339,
    '726_395': 32.0903,
    '726_396': 32.1385,
    '726_397': 32.1773,
    '726_398': 32.2123,
    '726_399': 32.2445,
    '726_400': 32.2753,
    '726_401': 32.3069,
    '726_402': 32.3473,
    '726_403': 32.3808,
    '726_404': 32.4018,
    '726_405': 32.4147,
    '726_406': 32.4231,
    '726_407': 32.4203,
    '726_408': 32.4254,
    '726_409': 32.4303,
    '726_410': 32.4279,
    '726_411': 32.4271,
    '726_412': 32.439,
    '726_413': 32.4669,
    '726_414': 32.5083,
    '726_415': 32.569,
    '726_416': 32.6264,
    '726_417': 32.6749,
    '726_418': 32.695,
    '726_419': 32.7104,
    '726_420': 32.7077,
    '726_421': 32.6783,
    '726_422': 32.6413,
    '726_423': 32.61,
    '726_424': 32.6018,
    '726_425': 32.6115,
    '726_426': 32.6264,
    '726_427': 32.6261,
    '726_428': 32.6052,
    '726_429': 32.5682,
    '726_430': 32.5239,
    '726_431': 32.4782,
    '726_432': 32.4393,
    '726_433': 32.402,
    '726_434': 32.3654,
    '726_435': 32.3263,
    '726_436': 32.2787,
    '726_437': 32.2231,
    '726_438': 32.1649,
    '726_439': 32.0873,
    '726_440': 31.9902,
    '726_441': 31.8967,
    '726_442': 31.8072,
    '726_443': 31.7187,
    '726_444': 31.6239,
    '726_445': 31.5062,
    '726_446': 31.3745,
    '726_447': 31.2376,
    '726_448': 31.0897,
    '726_449': 30.9034,
    '726_450': 30.6957,
    '726_451': 30.4701,
    '726_452': 30.2213,
    '726_453': 29.9665,
    '726_454': 29.7226,
    '726_455': 29.4922,
    '726_456': 29.2692,
    '726_457': 29.0547,
    '726_458': 28.8446,
    '726_459': 28.6309,
    '726_460': 28.4178,
    '726_461': 28.2115,
    '726_462': 28.011,
    '726_463': 27.8172,
    '726_464': 27.6088,
    '726_465': 27.4082,
    '726_466': 27.2216,
    '726_467': 27.0523,
    '726_468': 26.8887,
    '726_469': 26.7352,
    '726_470': 26.5954,
    '726_471': 26.4755,
    '726_472': 26.3839,
    '726_473': 26.303,
    '726_474': 26.2363,
    '726_475': 26.1903,
    '726_476': 26.1777,
    '726_477': 26.1902,
    '726_478': 26.219,
    '726_479': 26.2673,
    '726_480': 26.3321,
    '727_373': 30.8452,
    '727_374': 30.8632,
    '727_375': 30.8802,
    '727_376': 30.9101,
    '727_377': 30.9539,
    '727_378': 30.9982,
    '727_379': 31.0373,
    '727_380': 31.0688,
    '727_381': 31.11,
    '727_382': 31.1531,
    '727_383': 31.1849,
    '727_384': 31.2021,
    '727_385': 31.221,
    '727_386': 31.2529,
    '727_387': 31.2999,
    '727_388': 31.368,
    '727_389': 31.4698,
    '727_390': 31.6035,
    '727_391': 31.7542,
    '727_392': 31.885,
    '727_393': 31.9838,
    '727_394': 32.0614,
    '727_395': 32.1212,
    '727_396': 32.1653,
    '727_397': 32.1981,
    '727_398': 32.2321,
    '727_399': 32.265,
    '727_400': 32.2966,
    '727_401': 32.3299,
    '727_402': 32.3665,
    '727_403': 32.3993,
    '727_404': 32.4204,
    '727_405': 32.4384,
    '727_406': 32.4492,
    '727_407': 32.4366,
    '727_408': 32.4285,
    '727_409': 32.4303,
    '727_410': 32.4243,
    '727_411': 32.4149,
    '727_412': 32.4238,
    '727_413': 32.4483,
    '727_414': 32.4931,
    '727_415': 32.5634,
    '727_416': 32.6299,
    '727_417': 32.6761,
    '727_418': 32.7082,
    '727_419': 32.726,
    '727_420': 32.7246,
    '727_421': 32.7012,
    '727_422': 32.6779,
    '727_423': 32.6654,
    '727_424': 32.6723,
    '727_425': 32.6845,
    '727_426': 32.6894,
    '727_427': 32.6745,
    '727_428': 32.6404,
    '727_429': 32.5966,
    '727_430': 32.5524,
    '727_431': 32.5062,
    '727_432': 32.4672,
    '727_433': 32.4341,
    '727_434': 32.4107,
    '727_435': 32.3855,
    '727_436': 32.3474,
    '727_437': 32.2983,
    '727_438': 32.2422,
    '727_439': 32.1623,
    '727_440': 32.0688,
    '727_441': 31.9768,
    '727_442': 31.8848,
    '727_443': 31.7936,
    '727_444': 31.6925,
    '727_445': 31.5784,
    '727_446': 31.4584,
    '727_447': 31.3303,
    '727_448': 31.1813,
    '727_449': 30.9965,
    '727_450': 30.7894,
    '727_451': 30.5646,
    '727_452': 30.3233,
    '727_453': 30.0756,
    '727_454': 29.8322,
    '727_455': 29.6046,
    '727_456': 29.3853,
    '727_457': 29.1752,
    '727_458': 28.9672,
    '727_459': 28.7522,
    '727_460': 28.5354,
    '727_461': 28.321,
    '727_462': 28.1183,
    '727_463': 27.9219,
    '727_464': 27.7197,
    '727_465': 27.5232,
    '727_466': 27.3415,
    '727_467': 27.1741,
    '727_468': 27.0149,
    '727_469': 26.8675,
    '727_470': 26.731,
    '727_471': 26.6092,
    '727_472': 26.5064,
    '727_473': 26.4168,
    '727_474': 26.3419,
    '727_475': 26.2846,
    '727_476': 26.2656,
    '727_477': 26.2697,
    '727_478': 26.2903,
    '727_479': 26.3277,
    '727_480': 26.3872,
    '728_373': 30.877,
    '728_374': 30.8853,
    '728_375': 30.8981,
    '728_376': 30.9272,
    '728_377': 30.9713,
    '728_378': 31.0123,
    '728_379': 31.0596,
    '728_380': 31.1035,
    '728_381': 31.1463,
    '728_382': 31.1875,
    '728_383': 31.2239,
    '728_384': 31.2395,
    '728_385': 31.257,
    '728_386': 31.2851,
    '728_387': 31.327,
    '728_388': 31.3881,
    '728_389': 31.4873,
    '728_390': 31.6213,
    '728_391': 31.7773,
    '728_392': 31.907,
    '728_393': 32.0076,
    '728_394': 32.0869,
    '728_395': 32.1486,
    '728_396': 32.1847,
    '728_397': 32.2185,
    '728_398': 32.2531,
    '728_399': 32.2868,
    '728_400': 32.3193,
    '728_401': 32.352,
    '728_402': 32.3847,
    '728_403': 32.4141,
    '728_404': 32.4382,
    '728_405': 32.464,
    '728_406': 32.4744,
    '728_407': 32.4493,
    '728_408': 32.4279,
    '728_409': 32.4173,
    '728_410': 32.4097,
    '728_411': 32.4047,
    '728_412': 32.4124,
    '728_413': 32.4343,
    '728_414': 32.4773,
    '728_415': 32.55,
    '728_416': 32.6162,
    '728_417': 32.6626,
    '728_418': 32.7041,
    '728_419': 32.7299,
    '728_420': 32.7331,
    '728_421': 32.7233,
    '728_422': 32.7183,
    '728_423': 32.7266,
    '728_424': 32.7403,
    '728_425': 32.7499,
    '728_426': 32.7385,
    '728_427': 32.7072,
    '728_428': 32.661,
    '728_429': 32.6069,
    '728_430': 32.5592,
    '728_431': 32.5079,
    '728_432': 32.4674,
    '728_433': 32.4407,
    '728_434': 32.432,
    '728_435': 32.4224,
    '728_436': 32.3996,
    '728_437': 32.3644,
    '728_438': 32.3119,
    '728_439': 32.2357,
    '728_440': 32.1433,
    '728_441': 32.0519,
    '728_442': 31.9592,
    '728_443': 31.8635,
    '728_444': 31.7552,
    '728_445': 31.6462,
    '728_446': 31.5373,
    '728_447': 31.4105,
    '728_448': 31.2582,
    '728_449': 31.0745,
    '728_450': 30.8687,
    '728_451': 30.6528,
    '728_452': 30.4248,
    '728_453': 30.1872,
    '728_454': 29.9484,
    '728_455': 29.7226,
    '728_456': 29.507,
    '728_457': 29.3026,
    '728_458': 29.0928,
    '728_459': 28.8766,
    '728_460': 28.6553,
    '728_461': 28.4383,
    '728_462': 28.2301,
    '728_463': 28.0303,
    '728_464': 27.8316,
    '728_465': 27.639,
    '728_466': 27.4631,
    '728_467': 27.2996,
    '728_468': 27.1445,
    '728_469': 27.0038,
    '728_470': 26.8704,
    '728_471': 26.7437,
    '728_472': 26.6308,
    '728_473': 26.5327,
    '728_474': 26.45,
    '728_475': 26.3876,
    '728_476': 26.3566,
    '728_477': 26.3528,
    '728_478': 26.3661,
    '728_479': 26.3979,
    '728_480': 26.4554,
    '729_373': 30.9129,
    '729_374': 30.9122,
    '729_375': 30.9195,
    '729_376': 30.939,
    '729_377': 30.975,
    '729_378': 31.0285,
    '729_379': 31.0847,
    '729_380': 31.129,
    '729_381': 31.1689,
    '729_382': 31.2014,
    '729_383': 31.2384,
    '729_384': 31.2646,
    '729_385': 31.2878,
    '729_386': 31.3164,
    '729_387': 31.3574,
    '729_388': 31.4145,
    '729_389': 31.505,
    '729_390': 31.6345,
    '729_391': 31.8037,
    '729_392': 31.9287,
    '729_393': 32.0276,
    '729_394': 32.105,
    '729_395': 32.1641,
    '729_396': 32.1995,
    '729_397': 32.2365,
    '729_398': 32.2729,
    '729_399': 32.3079,
    '729_400': 32.3414,
    '729_401': 32.3734,
    '729_402': 32.4027,
    '729_403': 32.4295,
    '729_404': 32.4542,
    '729_405': 32.4813,
    '729_406': 32.4959,
    '729_407': 32.4711,
    '729_408': 32.4407,
    '729_409': 32.4148,
    '729_410': 32.4023,
    '729_411': 32.3975,
    '729_412': 32.4036,
    '729_413': 32.4223,
    '729_414': 32.4601,
    '729_415': 32.5247,
    '729_416': 32.5905,
    '729_417': 32.64,
    '729_418': 32.6828,
    '729_419': 32.7143,
    '729_420': 32.7285,
    '729_421': 32.7352,
    '729_422': 32.7502,
    '729_423': 32.7747,
    '729_424': 32.7915,
    '729_425': 32.7924,
    '729_426': 32.7692,
    '729_427': 32.7236,
    '729_428': 32.6694,
    '729_429': 32.6087,
    '729_430': 32.5513,
    '729_431': 32.4961,
    '729_432': 32.451,
    '729_433': 32.431,
    '729_434': 32.4319,
    '729_435': 32.4351,
    '729_436': 32.4248,
    '729_437': 32.4009,
    '729_438': 32.3527,
    '729_439': 32.2871,
    '729_440': 32.2048,
    '729_441': 32.1169,
    '729_442': 32.025,
    '729_443': 31.9277,
    '729_444': 31.8199,
    '729_445': 31.7145,
    '729_446': 31.607,
    '729_447': 31.4745,
    '729_448': 31.3188,
    '729_449': 31.1396,
    '729_450': 30.9472,
    '729_451': 30.744,
    '729_452': 30.5239,
    '729_453': 30.2951,
    '729_454': 30.0668,
    '729_455': 29.8467,
    '729_456': 29.6362,
    '729_457': 29.4329,
    '729_458': 29.2207,
    '729_459': 29.0005,
    '729_460': 28.7803,
    '729_461': 28.5642,
    '729_462': 28.351,
    '729_463': 28.1488,
    '729_464': 27.9528,
    '729_465': 27.7652,
    '729_466': 27.591,
    '729_467': 27.4301,
    '729_468': 27.2794,
    '729_469': 27.1368,
    '729_470': 27.0012,
    '729_471': 26.8729,
    '729_472': 26.7538,
    '729_473': 26.6488,
    '729_474': 26.5611,
    '729_475': 26.4929,
    '729_476': 26.4531,
    '729_477': 26.4428,
    '729_478': 26.457,
    '729_479': 26.4901,
    '729_480': 26.5443,
    '730_373': 30.9518,
    '730_374': 30.9433,
    '730_375': 30.9445,
    '730_376': 30.9586,
    '730_377': 30.9976,
    '730_378': 31.0481,
    '730_379': 31.0982,
    '730_380': 31.1427,
    '730_381': 31.1821,
    '730_382': 31.2085,
    '730_383': 31.2496,
    '730_384': 31.2829,
    '730_385': 31.3132,
    '730_386': 31.3436,
    '730_387': 31.3847,
    '730_388': 31.4461,
    '730_389': 31.5341,
    '730_390': 31.6541,
    '730_391': 31.8013,
    '730_392': 31.9321,
    '730_393': 32.0364,
    '730_394': 32.1132,
    '730_395': 32.1699,
    '730_396': 32.2148,
    '730_397': 32.2544,
    '730_398': 32.2921,
    '730_399': 32.3283,
    '730_400': 32.3628,
    '730_401': 32.3952,
    '730_402': 32.4222,
    '730_403': 32.4471,
    '730_404': 32.4724,
    '730_405': 32.4994,
    '730_406': 32.5079,
    '730_407': 32.4873,
    '730_408': 32.4489,
    '730_409': 32.4208,
    '730_410': 32.4028,
    '730_411': 32.3937,
    '730_412': 32.3966,
    '730_413': 32.4128,
    '730_414': 32.4455,
    '730_415': 32.4987,
    '730_416': 32.5633,
    '730_417': 32.6195,
    '730_418': 32.6585,
    '730_419': 32.6932,
    '730_420': 32.7215,
    '730_421': 32.7432,
    '730_422': 32.7745,
    '730_423': 32.8069,
    '730_424': 32.8233,
    '730_425': 32.8174,
    '730_426': 32.782,
    '730_427': 32.7269,
    '730_428': 32.6649,
    '730_429': 32.5981,
    '730_430': 32.534,
    '730_431': 32.476,
    '730_432': 32.4296,
    '730_433': 32.4089,
    '730_434': 32.4143,
    '730_435': 32.4229,
    '730_436': 32.4199,
    '730_437': 32.4048,
    '730_438': 32.3714,
    '730_439': 32.3162,
    '730_440': 32.2493,
    '730_441': 32.1708,
    '730_442': 32.0825,
    '730_443': 31.9856,
    '730_444': 31.8868,
    '730_445': 31.781,
    '730_446': 31.6655,
    '730_447': 31.525,
    '730_448': 31.3624,
    '730_449': 31.1876,
    '730_450': 31.0108,
    '730_451': 30.8223,
    '730_452': 30.615,
    '730_453': 30.3965,
    '730_454': 30.1825,
    '730_455': 29.9793,
    '730_456': 29.7747,
    '730_457': 29.5672,
    '730_458': 29.3559,
    '730_459': 29.1331,
    '730_460': 28.9128,
    '730_461': 28.6946,
    '730_462': 28.4798,
    '730_463': 28.2755,
    '730_464': 28.0809,
    '730_465': 27.896,
    '730_466': 27.723,
    '730_467': 27.5622,
    '730_468': 27.4114,
    '730_469': 27.2687,
    '730_470': 27.1303,
    '730_471': 26.9999,
    '730_472': 26.8742,
    '730_473': 26.7657,
    '730_474': 26.6783,
    '730_475': 26.6078,
    '730_476': 26.5602,
    '730_477': 26.5484,
    '730_478': 26.5605,
    '730_479': 26.5907,
    '730_480': 26.6353,
    '731_373': 30.9832,
    '731_374': 30.9802,
    '731_375': 30.9679,
    '731_376': 30.9914,
    '731_377': 31.0292,
    '731_378': 31.0688,
    '731_379': 31.1079,
    '731_380': 31.15,
    '731_381': 31.1917,
    '731_382': 31.2232,
    '731_383': 31.2578,
    '731_384': 31.2904,
    '731_385': 31.3309,
    '731_386': 31.3698,
    '731_387': 31.4153,
    '731_388': 31.4764,
    '731_389': 31.5593,
    '731_390': 31.6658,
    '731_391': 31.795,
    '731_392': 31.9223,
    '731_393': 32.0275,
    '731_394': 32.1132,
    '731_395': 32.1742,
    '731_396': 32.2223,
    '731_397': 32.2676,
    '731_398': 32.3091,
    '731_399': 32.3474,
    '731_400': 32.383,
    '731_401': 32.4151,
    '731_402': 32.4421,
    '731_403': 32.4669,
    '731_404': 32.492,
    '731_405': 32.5171,
    '731_406': 32.5182,
    '731_407': 32.4996,
    '731_408': 32.4624,
    '731_409': 32.4326,
    '731_410': 32.4085,
    '731_411': 32.3931,
    '731_412': 32.3916,
    '731_413': 32.4069,
    '731_414': 32.4378,
    '731_415': 32.4839,
    '731_416': 32.5452,
    '731_417': 32.6028,
    '731_418': 32.6464,
    '731_419': 32.6918,
    '731_420': 32.7279,
    '731_421': 32.7604,
    '731_422': 32.8022,
    '731_423': 32.8334,
    '731_424': 32.8421,
    '731_425': 32.8255,
    '731_426': 32.7805,
    '731_427': 32.7187,
    '731_428': 32.6519,
    '731_429': 32.5835,
    '731_430': 32.5202,
    '731_431': 32.4623,
    '731_432': 32.4153,
    '731_433': 32.3877,
    '731_434': 32.3846,
    '731_435': 32.3894,
    '731_436': 32.3897,
    '731_437': 32.384,
    '731_438': 32.3613,
    '731_439': 32.3331,
    '731_440': 32.285,
    '731_441': 32.2176,
    '731_442': 32.136,
    '731_443': 32.0435,
    '731_444': 31.9486,
    '731_445': 31.8384,
    '731_446': 31.7154,
    '731_447': 31.5661,
    '731_448': 31.3988,
    '731_449': 31.2257,
    '731_450': 31.0578,
    '731_451': 30.8838,
    '731_452': 30.6925,
    '731_453': 30.4897,
    '731_454': 30.2968,
    '731_455': 30.1132,
    '731_456': 29.9113,
    '731_457': 29.7089,
    '731_458': 29.5023,
    '731_459': 29.2746,
    '731_460': 29.0495,
    '731_461': 28.8279,
    '731_462': 28.6121,
    '731_463': 28.4062,
    '731_464': 28.2128,
    '731_465': 28.0289,
    '731_466': 27.8564,
    '731_467': 27.6961,
    '731_468': 27.5462,
    '731_469': 27.4031,
    '731_470': 27.26,
    '731_471': 27.1215,
    '731_472': 26.9913,
    '731_473': 26.8821,
    '731_474': 26.7993,
    '731_475': 26.7286,
    '731_476': 26.6841,
    '731_477': 26.6673,
    '731_478': 26.6702,
    '731_479': 26.6894,
    '731_480': 26.724,
    '732_373': 31.018,
    '732_374': 31.033,
    '732_375': 31.0302,
    '732_376': 31.0417,
    '732_377': 31.065,
    '732_378': 31.0827,
    '732_379': 31.1137,
    '732_380': 31.1536,
    '732_381': 31.1995,
    '732_382': 31.2369,
    '732_383': 31.2744,
    '732_384': 31.3084,
    '732_385': 31.3471,
    '732_386': 31.3886,
    '732_387': 31.437,
    '732_388': 31.5007,
    '732_389': 31.5843,
    '732_390': 31.6848,
    '732_391': 31.797,
    '732_392': 31.9135,
    '732_393': 32.0236,
    '732_394': 32.11,
    '732_395': 32.1783,
    '732_396': 32.2285,
    '732_397': 32.2803,
    '732_398': 32.3252,
    '732_399': 32.3653,
    '732_400': 32.4029,
    '732_401': 32.4384,
    '732_402': 32.4647,
    '732_403': 32.4884,
    '732_404': 32.5095,
    '732_405': 32.53,
    '732_406': 32.5312,
    '732_407': 32.5191,
    '732_408': 32.4842,
    '732_409': 32.4497,
    '732_410': 32.4184,
    '732_411': 32.3946,
    '732_412': 32.3885,
    '732_413': 32.4059,
    '732_414': 32.4377,
    '732_415': 32.4796,
    '732_416': 32.5312,
    '732_417': 32.5856,
    '732_418': 32.6419,
    '732_419': 32.6972,
    '732_420': 32.7331,
    '732_421': 32.7685,
    '732_422': 32.8169,
    '732_423': 32.8451,
    '732_424': 32.8484,
    '732_425': 32.8252,
    '732_426': 32.7748,
    '732_427': 32.712,
    '732_428': 32.6417,
    '732_429': 32.5732,
    '732_430': 32.511,
    '732_431': 32.4583,
    '732_432': 32.416,
    '732_433': 32.3852,
    '732_434': 32.3689,
    '732_435': 32.3583,
    '732_436': 32.3529,
    '732_437': 32.3516,
    '732_438': 32.352,
    '732_439': 32.3497,
    '732_440': 32.3211,
    '732_441': 32.2647,
    '732_442': 32.1926,
    '732_443': 32.1045,
    '732_444': 32.004,
    '732_445': 31.8887,
    '732_446': 31.7581,
    '732_447': 31.6059,
    '732_448': 31.4431,
    '732_449': 31.2807,
    '732_450': 31.1075,
    '732_451': 30.937,
    '732_452': 30.7577,
    '732_453': 30.5751,
    '732_454': 30.4103,
    '732_455': 30.2402,
    '732_456': 30.0438,
    '732_457': 29.849,
    '732_458': 29.6541,
    '732_459': 29.4231,
    '732_460': 29.1897,
    '732_461': 28.9641,
    '732_462': 28.747,
    '732_463': 28.5398,
    '732_464': 28.3457,
    '732_465': 28.1615,
    '732_466': 27.989,
    '732_467': 27.8294,
    '732_468': 27.679,
    '732_469': 27.5342,
    '732_470': 27.3887,
    '732_471': 27.2458,
    '732_472': 27.114,
    '732_473': 27.0033,
    '732_474': 26.922,
    '732_475': 26.8565,
    '732_476': 26.8122,
    '732_477': 26.7886,
    '732_478': 26.7827,
    '732_479': 26.7923,
    '732_480': 26.8181,
    '733_373': 31.0446,
    '733_374': 31.064,
    '733_375': 31.0723,
    '733_376': 31.0831,
    '733_377': 31.0914,
    '733_378': 31.1038,
    '733_379': 31.1265,
    '733_380': 31.1612,
    '733_381': 31.2038,
    '733_382': 31.2457,
    '733_383': 31.2881,
    '733_384': 31.3236,
    '733_385': 31.3621,
    '733_386': 31.405,
    '733_387': 31.4523,
    '733_388': 31.5169,
    '733_389': 31.601,
    '733_390': 31.6933,
    '733_391': 31.7924,
    '733_392': 31.901,
    '733_393': 32.0115,
    '733_394': 32.1072,
    '733_395': 32.1809,
    '733_396': 32.2477,
    '733_397': 32.2989,
    '733_398': 32.343,
    '733_399': 32.383,
    '733_400': 32.4217,
    '733_401': 32.4621,
    '733_402': 32.4936,
    '733_403': 32.5148,
    '733_404': 32.528,
    '733_405': 32.541,
    '733_406': 32.5436,
    '733_407': 32.5375,
    '733_408': 32.5109,
    '733_409': 32.474,
    '733_410': 32.4369,
    '733_411': 32.4052,
    '733_412': 32.3948,
    '733_413': 32.4169,
    '733_414': 32.4482,
    '733_415': 32.485,
    '733_416': 32.5276,
    '733_417': 32.577,
    '733_418': 32.6279,
    '733_419': 32.6826,
    '733_420': 32.7226,
    '733_421': 32.765,
    '733_422': 32.8133,
    '733_423': 32.8434,
    '733_424': 32.8459,
    '733_425': 32.8191,
    '733_426': 32.7732,
    '733_427': 32.716,
    '733_428': 32.6473,
    '733_429': 32.5828,
    '733_430': 32.525,
    '733_431': 32.477,
    '733_432': 32.4403,
    '733_433': 32.4105,
    '733_434': 32.3836,
    '733_435': 32.3601,
    '733_436': 32.3412,
    '733_437': 32.3343,
    '733_438': 32.3468,
    '733_439': 32.3611,
    '733_440': 32.3539,
    '733_441': 32.3138,
    '733_442': 32.2531,
    '733_443': 32.1681,
    '733_444': 32.0581,
    '733_445': 31.9369,
    '733_446': 31.8038,
    '733_447': 31.6619,
    '733_448': 31.5138,
    '733_449': 31.3512,
    '733_450': 31.1819,
    '733_451': 31.0096,
    '733_452': 30.8417,
    '733_453': 30.6701,
    '733_454': 30.5205,
    '733_455': 30.3591,
    '733_456': 30.1649,
    '733_457': 29.9811,
    '733_458': 29.8042,
    '733_459': 29.5727,
    '733_460': 29.3328,
    '733_461': 29.1031,
    '733_462': 28.8845,
    '733_463': 28.6742,
    '733_464': 28.4788,
    '733_465': 28.2939,
    '733_466': 28.1205,
    '733_467': 27.962,
    '733_468': 27.8089,
    '733_469': 27.6602,
    '733_470': 27.5135,
    '733_471': 27.3719,
    '733_472': 27.2447,
    '733_473': 27.1393,
    '733_474': 27.0567,
    '733_475': 26.9928,
    '733_476': 26.9441,
    '733_477': 26.9141,
    '733_478': 26.9016,
    '733_479': 26.9041,
    '733_480': 26.9225,
    '734_373': 31.0709,
    '734_374': 31.0903,
    '734_375': 31.1058,
    '734_376': 31.1159,
    '734_377': 31.1229,
    '734_378': 31.1307,
    '734_379': 31.1509,
    '734_380': 31.1814,
    '734_381': 31.216,
    '734_382': 31.2551,
    '734_383': 31.2955,
    '734_384': 31.3328,
    '734_385': 31.373,
    '734_386': 31.417,
    '734_387': 31.4654,
    '734_388': 31.5328,
    '734_389': 31.6128,
    '734_390': 31.6978,
    '734_391': 31.7937,
    '734_392': 31.9028,
    '734_393': 32.013,
    '734_394': 32.1057,
    '734_395': 32.1849,
    '734_396': 32.2582,
    '734_397': 32.3124,
    '734_398': 32.3582,
    '734_399': 32.4006,
    '734_400': 32.4411,
    '734_401': 32.4877,
    '734_402': 32.5199,
    '734_403': 32.542,
    '734_404': 32.5506,
    '734_405': 32.5577,
    '734_406': 32.5608,
    '734_407': 32.557,
    '734_408': 32.538,
    '734_409': 32.5059,
    '734_410': 32.4718,
    '734_411': 32.4435,
    '734_412': 32.4409,
    '734_413': 32.4525,
    '734_414': 32.4737,
    '734_415': 32.5011,
    '734_416': 32.5342,
    '734_417': 32.5747,
    '734_418': 32.6187,
    '734_419': 32.6621,
    '734_420': 32.704,
    '734_421': 32.7437,
    '734_422': 32.7925,
    '734_423': 32.8304,
    '734_424': 32.8389,
    '734_425': 32.8175,
    '734_426': 32.779,
    '734_427': 32.7356,
    '734_428': 32.6794,
    '734_429': 32.6239,
    '734_430': 32.5704,
    '734_431': 32.5255,
    '734_432': 32.4918,
    '734_433': 32.4605,
    '734_434': 32.427,
    '734_435': 32.3925,
    '734_436': 32.3591,
    '734_437': 32.3366,
    '734_438': 32.3427,
    '734_439': 32.3665,
    '734_440': 32.3784,
    '734_441': 32.3567,
    '734_442': 32.3091,
    '734_443': 32.2285,
    '734_444': 32.1176,
    '734_445': 31.9941,
    '734_446': 31.8658,
    '734_447': 31.7425,
    '734_448': 31.5961,
    '734_449': 31.4326,
    '734_450': 31.2659,
    '734_451': 31.1037,
    '734_452': 30.9372,
    '734_453': 30.7711,
    '734_454': 30.6258,
    '734_455': 30.4656,
    '734_456': 30.2778,
    '734_457': 30.119,
    '734_458': 29.9471,
    '734_459': 29.7187,
    '734_460': 29.4736,
    '734_461': 29.2394,
    '734_462': 29.0181,
    '734_463': 28.8069,
    '734_464': 28.611,
    '734_465': 28.4275,
    '734_466': 28.2557,
    '734_467': 28.0961,
    '734_468': 27.9388,
    '734_469': 27.7881,
    '734_470': 27.6431,
    '734_471': 27.5051,
    '734_472': 27.3858,
    '734_473': 27.2815,
    '734_474': 27.1946,
    '734_475': 27.1266,
    '734_476': 27.075,
    '734_477': 27.0411,
    '734_478': 27.0257,
    '734_479': 27.0242,
    '734_480': 27.0356,
    '735_373': 31.0943,
    '735_374': 31.1137,
    '735_375': 31.1354,
    '735_376': 31.1452,
    '735_377': 31.1531,
    '735_378': 31.1551,
    '735_379': 31.1726,
    '735_380': 31.2047,
    '735_381': 31.2307,
    '735_382': 31.2661,
    '735_383': 31.3043,
    '735_384': 31.3393,
    '735_385': 31.3795,
    '735_386': 31.426,
    '735_387': 31.4705,
    '735_388': 31.5391,
    '735_389': 31.6155,
    '735_390': 31.6972,
    '735_391': 31.7914,
    '735_392': 31.9005,
    '735_393': 32.0016,
    '735_394': 32.0922,
    '735_395': 32.1769,
    '735_396': 32.256,
    '735_397': 32.318,
    '735_398': 32.3694,
    '735_399': 32.4184,
    '735_400': 32.4615,
    '735_401': 32.5045,
    '735_402': 32.5414,
    '735_403': 32.568,
    '735_404': 32.5769,
    '735_405': 32.5807,
    '735_406': 32.5832,
    '735_407': 32.5803,
    '735_408': 32.5663,
    '735_409': 32.5458,
    '735_410': 32.5198,
    '735_411': 32.4979,
    '735_412': 32.4915,
    '735_413': 32.4962,
    '735_414': 32.509,
    '735_415': 32.527,
    '735_416': 32.5506,
    '735_417': 32.582,
    '735_418': 32.6259,
    '735_419': 32.6602,
    '735_420': 32.6908,
    '735_421': 32.7244,
    '735_422': 32.7713,
    '735_423': 32.814,
    '735_424': 32.8334,
    '735_425': 32.8235,
    '735_426': 32.8032,
    '735_427': 32.7813,
    '735_428': 32.7439,
    '735_429': 32.6964,
    '735_430': 32.6475,
    '735_431': 32.6014,
    '735_432': 32.5685,
    '735_433': 32.5297,
    '735_434': 32.4915,
    '735_435': 32.4518,
    '735_436': 32.405,
    '735_437': 32.3678,
    '735_438': 32.3543,
    '735_439': 32.3688,
    '735_440': 32.3906,
    '735_441': 32.388,
    '735_442': 32.3559,
    '735_443': 32.2881,
    '735_444': 32.1844,
    '735_445': 32.0688,
    '735_446': 31.9486,
    '735_447': 31.833,
    '735_448': 31.6882,
    '735_449': 31.5265,
    '735_450': 31.3561,
    '735_451': 31.2028,
    '735_452': 31.0367,
    '735_453': 30.8759,
    '735_454': 30.7254,
    '735_455': 30.5595,
    '735_456': 30.393,
    '735_457': 30.2576,
    '735_458': 30.0822,
    '735_459': 29.8498,
    '735_460': 29.6105,
    '735_461': 29.374,
    '735_462': 29.1476,
    '735_463': 28.9356,
    '735_464': 28.7398,
    '735_465': 28.5587,
    '735_466': 28.3887,
    '735_467': 28.2221,
    '735_468': 28.066,
    '735_469': 27.9193,
    '735_470': 27.7813,
    '735_471': 27.6523,
    '735_472': 27.535,
    '735_473': 27.427,
    '735_474': 27.3349,
    '735_475': 27.2616,
    '735_476': 27.2065,
    '735_477': 27.1684,
    '735_478': 27.1515,
    '735_479': 27.1489,
    '735_480': 27.1589,
    '736_373': 31.1018,
    '736_374': 31.1218,
    '736_375': 31.1528,
    '736_376': 31.1668,
    '736_377': 31.1731,
    '736_378': 31.1871,
    '736_379': 31.2081,
    '736_380': 31.2304,
    '736_381': 31.2513,
    '736_382': 31.2804,
    '736_383': 31.3138,
    '736_384': 31.3478,
    '736_385': 31.3858,
    '736_386': 31.4308,
    '736_387': 31.4789,
    '736_388': 31.5427,
    '736_389': 31.6164,
    '736_390': 31.7002,
    '736_391': 31.7958,
    '736_392': 31.9011,
    '736_393': 31.9936,
    '736_394': 32.078,
    '736_395': 32.1654,
    '736_396': 32.2527,
    '736_397': 32.3208,
    '736_398': 32.3767,
    '736_399': 32.4288,
    '736_400': 32.4806,
    '736_401': 32.5175,
    '736_402': 32.5557,
    '736_403': 32.5919,
    '736_404': 32.6113,
    '736_405': 32.6094,
    '736_406': 32.61,
    '736_407': 32.605,
    '736_408': 32.5977,
    '736_409': 32.5926,
    '736_410': 32.574,
    '736_411': 32.5507,
    '736_412': 32.5415,
    '736_413': 32.5415,
    '736_414': 32.548,
    '736_415': 32.5592,
    '736_416': 32.5745,
    '736_417': 32.5973,
    '736_418': 32.6302,
    '736_419': 32.6592,
    '736_420': 32.6828,
    '736_421': 32.7149,
    '736_422': 32.7598,
    '736_423': 32.7998,
    '736_424': 32.8252,
    '736_425': 32.8445,
    '736_426': 32.8536,
    '736_427': 32.845,
    '736_428': 32.8215,
    '736_429': 32.7853,
    '736_430': 32.7395,
    '736_431': 32.6948,
    '736_432': 32.6593,
    '736_433': 32.6152,
    '736_434': 32.5712,
    '736_435': 32.5306,
    '736_436': 32.477,
    '736_437': 32.4295,
    '736_438': 32.3986,
    '736_439': 32.3923,
    '736_440': 32.4029,
    '736_441': 32.411,
    '736_442': 32.4,
    '736_443': 32.3497,
    '736_444': 32.2623,
    '736_445': 32.1537,
    '736_446': 32.0436,
    '736_447': 31.9292,
    '736_448': 31.7821,
    '736_449': 31.6217,
    '736_450': 31.4507,
    '736_451': 31.299,
    '736_452': 31.1385,
    '736_453': 30.9753,
    '736_454': 30.8149,
    '736_455': 30.6473,
    '736_456': 30.5055,
    '736_457': 30.3816,
    '736_458': 30.2083,
    '736_459': 29.9818,
    '736_460': 29.7424,
    '736_461': 29.5018,
    '736_462': 29.2742,
    '736_463': 29.0602,
    '736_464': 28.8626,
    '736_465': 28.6826,
    '736_466': 28.5126,
    '736_467': 28.3488,
    '736_468': 28.1952,
    '736_469': 28.0548,
    '736_470': 27.9237,
    '736_471': 27.7995,
    '736_472': 27.6797,
    '736_473': 27.5687,
    '736_474': 27.4735,
    '736_475': 27.3975,
    '736_476': 27.339,
    '736_477': 27.2974,
    '736_478': 27.2729,
    '736_479': 27.2638,
    '736_480': 27.2771,
    '737_373': 31.1169,
    '737_374': 31.1324,
    '737_375': 31.1608,
    '737_376': 31.1868,
    '737_377': 31.2084,
    '737_378': 31.2278,
    '737_379': 31.2504,
    '737_380': 31.2656,
    '737_381': 31.2789,
    '737_382': 31.3021,
    '737_383': 31.3302,
    '737_384': 31.3596,
    '737_385': 31.3924,
    '737_386': 31.4367,
    '737_387': 31.4835,
    '737_388': 31.5438,
    '737_389': 31.6153,
    '737_390': 31.7002,
    '737_391': 31.7927,
    '737_392': 31.8913,
    '737_393': 31.9848,
    '737_394': 32.0673,
    '737_395': 32.1524,
    '737_396': 32.2463,
    '737_397': 32.3218,
    '737_398': 32.3829,
    '737_399': 32.4378,
    '737_400': 32.4928,
    '737_401': 32.534,
    '737_402': 32.5642,
    '737_403': 32.6024,
    '737_404': 32.6384,
    '737_405': 32.6446,
    '737_406': 32.6369,
    '737_407': 32.6312,
    '737_408': 32.6274,
    '737_409': 32.628,
    '737_410': 32.6226,
    '737_411': 32.5983,
    '737_412': 32.588,
    '737_413': 32.5863,
    '737_414': 32.5886,
    '737_415': 32.5939,
    '737_416': 32.6025,
    '737_417': 32.6167,
    '737_418': 32.6381,
    '737_419': 32.661,
    '737_420': 32.6851,
    '737_421': 32.7174,
    '737_422': 32.7595,
    '737_423': 32.7966,
    '737_424': 32.8306,
    '737_425': 32.877,
    '737_426': 32.9049,
    '737_427': 32.9166,
    '737_428': 32.9029,
    '737_429': 32.8784,
    '737_430': 32.835,
    '737_431': 32.7993,
    '737_432': 32.7613,
    '737_433': 32.7144,
    '737_434': 32.6635,
    '737_435': 32.6279,
    '737_436': 32.5681,
    '737_437': 32.5132,
    '737_438': 32.4725,
    '737_439': 32.4505,
    '737_440': 32.4463,
    '737_441': 32.4566,
    '737_442': 32.4514,
    '737_443': 32.418,
    '737_444': 32.3489,
    '737_445': 32.2441,
    '737_446': 32.142,
    '737_447': 32.0266,
    '737_448': 31.8771,
    '737_449': 31.7121,
    '737_450': 31.5463,
    '737_451': 31.3926,
    '737_452': 31.2342,
    '737_453': 31.0619,
    '737_454': 30.8898,
    '737_455': 30.7295,
    '737_456': 30.6054,
    '737_457': 30.4844,
    '737_458': 30.3183,
    '737_459': 30.0996,
    '737_460': 29.869,
    '737_461': 29.6254,
    '737_462': 29.3941,
    '737_463': 29.1808,
    '737_464': 28.9839,
    '737_465': 28.8046,
    '737_466': 28.6361,
    '737_467': 28.4765,
    '737_468': 28.3286,
    '737_469': 28.1924,
    '737_470': 28.0644,
    '737_471': 27.9419,
    '737_472': 27.8207,
    '737_473': 27.7083,
    '737_474': 27.6115,
    '737_475': 27.5348,
    '737_476': 27.4744,
    '737_477': 27.4303,
    '737_478': 27.4057,
    '737_479': 27.3943,
    '737_480': 27.3968,
    '738_373': 31.1275,
    '738_374': 31.1408,
    '738_375': 31.1654,
    '738_376': 31.1963,
    '738_377': 31.2349,
    '738_378': 31.2733,
    '738_379': 31.3053,
    '738_380': 31.3192,
    '738_381': 31.3256,
    '738_382': 31.3393,
    '738_383': 31.359,
    '738_384': 31.3748,
    '738_385': 31.4016,
    '738_386': 31.4411,
    '738_387': 31.4888,
    '738_388': 31.5461,
    '738_389': 31.6161,
    '738_390': 31.7019,
    '738_391': 31.7917,
    '738_392': 31.8827,
    '738_393': 31.9737,
    '738_394': 32.0605,
    '738_395': 32.148,
    '738_396': 32.2413,
    '738_397': 32.3207,
    '738_398': 32.3883,
    '738_399': 32.4478,
    '738_400': 32.501,
    '738_401': 32.5439,
    '738_402': 32.5777,
    '738_403': 32.6139,
    '738_404': 32.6547,
    '738_405': 32.6718,
    '738_406': 32.6618,
    '738_407': 32.6586,
    '738_408': 32.6546,
    '738_409': 32.651,
    '738_410': 32.6486,
    '738_411': 32.6349,
    '738_412': 32.6292,
    '738_413': 32.6299,
    '738_414': 32.63,
    '738_415': 32.6294,
    '738_416': 32.6317,
    '738_417': 32.6386,
    '738_418': 32.6521,
    '738_419': 32.6726,
    '738_420': 32.6981,
    '738_421': 32.7324,
    '738_422': 32.775,
    '738_423': 32.8083,
    '738_424': 32.8466,
    '738_425': 32.9027,
    '738_426': 32.9487,
    '738_427': 32.9759,
    '738_428': 32.976,
    '738_429': 32.9615,
    '738_430': 32.9233,
    '738_431': 32.901,
    '738_432': 32.8693,
    '738_433': 32.8206,
    '738_434': 32.7654,
    '738_435': 32.734,
    '738_436': 32.6746,
    '738_437': 32.6132,
    '738_438': 32.569,
    '738_439': 32.5397,
    '738_440': 32.5204,
    '738_441': 32.5173,
    '738_442': 32.5078,
    '738_443': 32.4884,
    '738_444': 32.4317,
    '738_445': 32.3415,
    '738_446': 32.2369,
    '738_447': 32.1168,
    '738_448': 31.9681,
    '738_449': 31.7969,
    '738_450': 31.633,
    '738_451': 31.4766,
    '738_452': 31.3136,
    '738_453': 31.1364,
    '738_454': 30.9546,
    '738_455': 30.8008,
    '738_456': 30.6815,
    '738_457': 30.5572,
    '738_458': 30.401,
    '738_459': 30.1988,
    '738_460': 29.9774,
    '738_461': 29.741,
    '738_462': 29.5082,
    '738_463': 29.2983,
    '738_464': 29.1023,
    '738_465': 28.9246,
    '738_466': 28.7586,
    '738_467': 28.6032,
    '738_468': 28.4592,
    '738_469': 28.3252,
    '738_470': 28.1966,
    '738_471': 28.0749,
    '738_472': 27.9587,
    '738_473': 27.8514,
    '738_474': 27.7565,
    '738_475': 27.6769,
    '738_476': 27.6142,
    '738_477': 27.5654,
    '738_478': 27.533,
    '738_479': 27.5162,
    '738_480': 27.5157,
    '739_373': 31.1396,
    '739_374': 31.1503,
    '739_375': 31.172,
    '739_376': 31.2095,
    '739_377': 31.2579,
    '739_378': 31.3114,
    '739_379': 31.3526,
    '739_380': 31.3729,
    '739_381': 31.3745,
    '739_382': 31.3815,
    '739_383': 31.3973,
    '739_384': 31.3961,
    '739_385': 31.4146,
    '739_386': 31.4492,
    '739_387': 31.4958,
    '739_388': 31.5489,
    '739_389': 31.6144,
    '739_390': 31.7029,
    '739_391': 31.7872,
    '739_392': 31.8743,
    '739_393': 31.9657,
    '739_394': 32.056,
    '739_395': 32.1428,
    '739_396': 32.237,
    '739_397': 32.3213,
    '739_398': 32.3963,
    '739_399': 32.4631,
    '739_400': 32.5213,
    '739_401': 32.5645,
    '739_402': 32.6051,
    '739_403': 32.6327,
    '739_404': 32.6666,
    '739_405': 32.6873,
    '739_406': 32.6867,
    '739_407': 32.6907,
    '739_408': 32.684,
    '739_409': 32.6729,
    '739_410': 32.6695,
    '739_411': 32.6663,
    '739_412': 32.6669,
    '739_413': 32.6722,
    '739_414': 32.6782,
    '739_415': 32.6651,
    '739_416': 32.6611,
    '739_417': 32.6624,
    '739_418': 32.6709,
    '739_419': 32.6929,
    '739_420': 32.7252,
    '739_421': 32.7611,
    '739_422': 32.7963,
    '739_423': 32.8255,
    '739_424': 32.8609,
    '739_425': 32.9163,
    '739_426': 32.9777,
    '739_427': 33.0187,
    '739_428': 33.0353,
    '739_429': 33.0316,
    '739_430': 33.0065,
    '739_431': 32.9921,
    '739_432': 32.9668,
    '739_433': 32.9184,
    '739_434': 32.8691,
    '739_435': 32.8457,
    '739_436': 32.7968,
    '739_437': 32.732,
    '739_438': 32.6873,
    '739_439': 32.6503,
    '739_440': 32.6187,
    '739_441': 32.5894,
    '739_442': 32.5711,
    '739_443': 32.561,
    '739_444': 32.5112,
    '739_445': 32.4314,
    '739_446': 32.3237,
    '739_447': 32.2019,
    '739_448': 32.0553,
    '739_449': 31.8798,
    '739_450': 31.7084,
    '739_451': 31.5429,
    '739_452': 31.3744,
    '739_453': 31.199,
    '739_454': 31.023,
    '739_455': 30.8615,
    '739_456': 30.7312,
    '739_457': 30.6041,
    '739_458': 30.4504,
    '739_459': 30.2664,
    '739_460': 30.0577,
    '739_461': 29.8323,
    '739_462': 29.6125,
    '739_463': 29.4135,
    '739_464': 29.2193,
    '739_465': 29.0429,
    '739_466': 28.8791,
    '739_467': 28.7263,
    '739_468': 28.5846,
    '739_469': 28.4506,
    '739_470': 28.3236,
    '739_471': 28.2061,
    '739_472': 28.0964,
    '739_473': 27.9948,
    '739_474': 27.9034,
    '739_475': 27.8225,
    '739_476': 27.7526,
    '739_477': 27.6935,
    '739_478': 27.6531,
    '739_479': 27.6322,
    '739_480': 27.6303,
    '740_373': 31.149,
    '740_374': 31.1642,
    '740_375': 31.1863,
    '740_376': 31.2197,
    '740_377': 31.2711,
    '740_378': 31.3265,
    '740_379': 31.3754,
    '740_380': 31.4087,
    '740_381': 31.4223,
    '740_382': 31.4274,
    '740_383': 31.4287,
    '740_384': 31.4242,
    '740_385': 31.4369,
    '740_386': 31.4637,
    '740_387': 31.5032,
    '740_388': 31.5524,
    '740_389': 31.6155,
    '740_390': 31.6979,
    '740_391': 31.7803,
    '740_392': 31.8694,
    '740_393': 31.9612,
    '740_394': 32.0548,
    '740_395': 32.1446,
    '740_396': 32.2361,
    '740_397': 32.3242,
    '740_398': 32.4058,
    '740_399': 32.4822,
    '740_400': 32.5473,
    '740_401': 32.5931,
    '740_402': 32.6359,
    '740_403': 32.6554,
    '740_404': 32.6831,
    '740_405': 32.7039,
    '740_406': 32.7121,
    '740_407': 32.7164,
    '740_408': 32.7173,
    '740_409': 32.6953,
    '740_410': 32.6945,
    '740_411': 32.6963,
    '740_412': 32.7008,
    '740_413': 32.7085,
    '740_414': 32.7185,
    '740_415': 32.6998,
    '740_416': 32.6888,
    '740_417': 32.685,
    '740_418': 32.6888,
    '740_419': 32.7059,
    '740_420': 32.7403,
    '740_421': 32.7842,
    '740_422': 32.8207,
    '740_423': 32.8435,
    '740_424': 32.8755,
    '740_425': 32.9303,
    '740_426': 32.9906,
    '740_427': 33.0438,
    '740_428': 33.079,
    '740_429': 33.0904,
    '740_430': 33.0848,
    '740_431': 33.0778,
    '740_432': 33.0531,
    '740_433': 33.0048,
    '740_434': 32.9628,
    '740_435': 32.9537,
    '740_436': 32.921,
    '740_437': 32.8671,
    '740_438': 32.8187,
    '740_439': 32.7683,
    '740_440': 32.729,
    '740_441': 32.6871,
    '740_442': 32.6659,
    '740_443': 32.6437,
    '740_444': 32.5926,
    '740_445': 32.5023,
    '740_446': 32.3994,
    '740_447': 32.2846,
    '740_448': 32.1363,
    '740_449': 31.9599,
    '740_450': 31.7754,
    '740_451': 31.5958,
    '740_452': 31.4187,
    '740_453': 31.248,
    '740_454': 31.081,
    '740_455': 30.9144,
    '740_456': 30.7638,
    '740_457': 30.621,
    '740_458': 30.4737,
    '740_459': 30.3039,
    '740_460': 30.1139,
    '740_461': 29.9093,
    '740_462': 29.7046,
    '740_463': 29.5183,
    '740_464': 29.334,
    '740_465': 29.1591,
    '740_466': 28.9961,
    '740_467': 28.8449,
    '740_468': 28.7063,
    '740_469': 28.5725,
    '740_470': 28.4466,
    '740_471': 28.3339,
    '740_472': 28.2307,
    '740_473': 28.1331,
    '740_474': 28.0445,
    '740_475': 27.9592,
    '740_476': 27.8773,
    '740_477': 27.8114,
    '740_478': 27.7667,
    '740_479': 27.7441,
    '740_480': 27.7411,
    '741_373': 31.1579,
    '741_374': 31.1779,
    '741_375': 31.2022,
    '741_376': 31.234,
    '741_377': 31.2809,
    '741_378': 31.3315,
    '741_379': 31.3799,
    '741_380': 31.4215,
    '741_381': 31.4554,
    '741_382': 31.4671,
    '741_383': 31.4631,
    '741_384': 31.4582,
    '741_385': 31.4643,
    '741_386': 31.4829,
    '741_387': 31.5153,
    '741_388': 31.5582,
    '741_389': 31.6147,
    '741_390': 31.6928,
    '741_391': 31.7729,
    '741_392': 31.8667,
    '741_393': 31.9589,
    '741_394': 32.0557,
    '741_395': 32.1484,
    '741_396': 32.2404,
    '741_397': 32.3287,
    '741_398': 32.4127,
    '741_399': 32.4957,
    '741_400': 32.5697,
    '741_401': 32.6214,
    '741_402': 32.6609,
    '741_403': 32.6834,
    '741_404': 32.7101,
    '741_405': 32.7288,
    '741_406': 32.7358,
    '741_407': 32.7393,
    '741_408': 32.7415,
    '741_409': 32.7275,
    '741_410': 32.7255,
    '741_411': 32.7271,
    '741_412': 32.732,
    '741_413': 32.7386,
    '741_414': 32.7401,
    '741_415': 32.7262,
    '741_416': 32.7131,
    '741_417': 32.7049,
    '741_418': 32.7035,
    '741_419': 32.7133,
    '741_420': 32.7403,
    '741_421': 32.7867,
    '741_422': 32.8364,
    '741_423': 32.8634,
    '741_424': 32.8951,
    '741_425': 32.9363,
    '741_426': 32.9892,
    '741_427': 33.0545,
    '741_428': 33.1086,
    '741_429': 33.1381,
    '741_430': 33.1531,
    '741_431': 33.1544,
    '741_432': 33.1294,
    '741_433': 33.0814,
    '741_434': 33.0499,
    '741_435': 33.0529,
    '741_436': 33.0342,
    '741_437': 32.995,
    '741_438': 32.9461,
    '741_439': 32.8886,
    '741_440': 32.8421,
    '741_441': 32.7952,
    '741_442': 32.7672,
    '741_443': 32.7314,
    '741_444': 32.67,
    '741_445': 32.5748,
    '741_446': 32.4686,
    '741_447': 32.3591,
    '741_448': 32.2185,
    '741_449': 32.0416,
    '741_450': 31.8465,
    '741_451': 31.6519,
    '741_452': 31.4691,
    '741_453': 31.2938,
    '741_454': 31.1281,
    '741_455': 30.9686,
    '741_456': 30.8095,
    '741_457': 30.6477,
    '741_458': 30.4865,
    '741_459': 30.3265,
    '741_460': 30.1586,
    '741_461': 29.9779,
    '741_462': 29.7897,
    '741_463': 29.6114,
    '741_464': 29.4421,
    '741_465': 29.2715,
    '741_466': 29.1091,
    '741_467': 28.9601,
    '741_468': 28.8221,
    '741_469': 28.6909,
    '741_470': 28.5678,
    '741_471': 28.4558,
    '741_472': 28.3538,
    '741_473': 28.26,
    '741_474': 28.1746,
    '741_475': 28.0802,
    '741_476': 27.9925,
    '741_477': 27.9248,
    '741_478': 27.8803,
    '741_479': 27.8562,
    '741_480': 27.8523,
    '742_373': 31.1666,
    '742_374': 31.1887,
    '742_375': 31.2131,
    '742_376': 31.2458,
    '742_377': 31.2883,
    '742_378': 31.3364,
    '742_379': 31.3766,
    '742_380': 31.4153,
    '742_381': 31.4635,
    '742_382': 31.4944,
    '742_383': 31.4912,
    '742_384': 31.4894,
    '742_385': 31.4925,
    '742_386': 31.5034,
    '742_387': 31.5327,
    '742_388': 31.573,
    '742_389': 31.6289,
    '742_390': 31.6988,
    '742_391': 31.7767,
    '742_392': 31.8684,
    '742_393': 31.9654,
    '742_394': 32.0648,
    '742_395': 32.1571,
    '742_396': 32.2488,
    '742_397': 32.3322,
    '742_398': 32.4131,
    '742_399': 32.495,
    '742_400': 32.5711,
    '742_401': 32.6321,
    '742_402': 32.6798,
    '742_403': 32.7059,
    '742_404': 32.7319,
    '742_405': 32.7511,
    '742_406': 32.7575,
    '742_407': 32.7571,
    '742_408': 32.7587,
    '742_409': 32.7556,
    '742_410': 32.7547,
    '742_411': 32.757,
    '742_412': 32.7603,
    '742_413': 32.7645,
    '742_414': 32.7628,
    '742_415': 32.7497,
    '742_416': 32.7348,
    '742_417': 32.7233,
    '742_418': 32.7169,
    '742_419': 32.7206,
    '742_420': 32.7415,
    '742_421': 32.7849,
    '742_422': 32.8388,
    '742_423': 32.8769,
    '742_424': 32.9137,
    '742_425': 32.9509,
    '742_426': 32.9854,
    '742_427': 33.0543,
    '742_428': 33.1261,
    '742_429': 33.1755,
    '742_430': 33.2071,
    '742_431': 33.211,
    '742_432': 33.1885,
    '742_433': 33.1476,
    '742_434': 33.1273,
    '742_435': 33.1337,
    '742_436': 33.1293,
    '742_437': 33.103,
    '742_438': 33.0631,
    '742_439': 33.0074,
    '742_440': 32.9509,
    '742_441': 32.8968,
    '742_442': 32.8644,
    '742_443': 32.8117,
    '742_444': 32.7401,
    '742_445': 32.6425,
    '742_446': 32.5375,
    '742_447': 32.428,
    '742_448': 32.2898,
    '742_449': 32.1167,
    '742_450': 31.925,
    '742_451': 31.7233,
    '742_452': 31.5289,
    '742_453': 31.349,
    '742_454': 31.1814,
    '742_455': 31.0254,
    '742_456': 30.8728,
    '742_457': 30.6982,
    '742_458': 30.5238,
    '742_459': 30.359,
    '742_460': 30.1919,
    '742_461': 30.0339,
    '742_462': 29.8614,
    '742_463': 29.7058,
    '742_464': 29.5457,
    '742_465': 29.3761,
    '742_466': 29.2157,
    '742_467': 29.07,
    '742_468': 28.9341,
    '742_469': 28.8055,
    '742_470': 28.6844,
    '742_471': 28.5728,
    '742_472': 28.4716,
    '742_473': 28.3796,
    '742_474': 28.2911,
    '742_475': 28.1973,
    '742_476': 28.1147,
    '742_477': 28.0476,
    '742_478': 28.0001,
    '742_479': 27.9746,
    '742_480': 27.972,
    '743_373': 31.1754,
    '743_374': 31.1979,
    '743_375': 31.2202,
    '743_376': 31.2543,
    '743_377': 31.2927,
    '743_378': 31.3406,
    '743_379': 31.3773,
    '743_380': 31.4158,
    '743_381': 31.469,
    '743_382': 31.5071,
    '743_383': 31.5112,
    '743_384': 31.5232,
    '743_385': 31.5262,
    '743_386': 31.5315,
    '743_387': 31.555,
    '743_388': 31.5954,
    '743_389': 31.648,
    '743_390': 31.7016,
    '743_391': 31.7805,
    '743_392': 31.8728,
    '743_393': 31.9716,
    '743_394': 32.0687,
    '743_395': 32.1608,
    '743_396': 32.2514,
    '743_397': 32.3285,
    '743_398': 32.4045,
    '743_399': 32.4833,
    '743_400': 32.5638,
    '743_401': 32.6346,
    '743_402': 32.6896,
    '743_403': 32.7233,
    '743_404': 32.7493,
    '743_405': 32.7612,
    '743_406': 32.7715,
    '743_407': 32.7749,
    '743_408': 32.7758,
    '743_409': 32.7783,
    '743_410': 32.7844,
    '743_411': 32.7899,
    '743_412': 32.7953,
    '743_413': 32.7955,
    '743_414': 32.7906,
    '743_415': 32.7789,
    '743_416': 32.7599,
    '743_417': 32.7441,
    '743_418': 32.7315,
    '743_419': 32.73,
    '743_420': 32.7447,
    '743_421': 32.7814,
    '743_422': 32.8227,
    '743_423': 32.8678,
    '743_424': 32.9117,
    '743_425': 32.9525,
    '743_426': 32.9879,
    '743_427': 33.0563,
    '743_428': 33.1355,
    '743_429': 33.2005,
    '743_430': 33.2404,
    '743_431': 33.2506,
    '743_432': 33.2314,
    '743_433': 33.2011,
    '743_434': 33.1951,
    '743_435': 33.2034,
    '743_436': 33.2071,
    '743_437': 33.1986,
    '743_438': 33.1673,
    '743_439': 33.1154,
    '743_440': 33.0541,
    '743_441': 32.9936,
    '743_442': 32.9521,
    '743_443': 32.8904,
    '743_444': 32.805,
    '743_445': 32.706,
    '743_446': 32.6052,
    '743_447': 32.4951,
    '743_448': 32.3543,
    '743_449': 32.185,
    '743_450': 31.9931,
    '743_451': 31.7919,
    '743_452': 31.592,
    '743_453': 31.4084,
    '743_454': 31.2413,
    '743_455': 31.0843,
    '743_456': 30.9349,
    '743_457': 30.765,
    '743_458': 30.5899,
    '743_459': 30.413,
    '743_460': 30.2401,
    '743_461': 30.0868,
    '743_462': 29.9344,
    '743_463': 29.7925,
    '743_464': 29.6357,
    '743_465': 29.4713,
    '743_466': 29.3171,
    '743_467': 29.1761,
    '743_468': 29.0433,
    '743_469': 28.9175,
    '743_470': 28.7982,
    '743_471': 28.6878,
    '743_472': 28.5867,
    '743_473': 28.4967,
    '743_474': 28.4121,
    '743_475': 28.3257,
    '743_476': 28.2437,
    '743_477': 28.1763,
    '743_478': 28.1276,
    '743_479': 28.1053,
    '743_480': 28.1046,
    '744_373': 31.1903,
    '744_374': 31.2118,
    '744_375': 31.2268,
    '744_376': 31.2615,
    '744_377': 31.3014,
    '744_378': 31.3568,
    '744_379': 31.3873,
    '744_380': 31.4258,
    '744_381': 31.4802,
    '744_382': 31.5193,
    '744_383': 31.5356,
    '744_384': 31.5533,
    '744_385': 31.5643,
    '744_386': 31.5708,
    '744_387': 31.5914,
    '744_388': 31.6223,
    '744_389': 31.6648,
    '744_390': 31.7207,
    '744_391': 31.7928,
    '744_392': 31.8804,
    '744_393': 31.9778,
    '744_394': 32.0675,
    '744_395': 32.1546,
    '744_396': 32.2386,
    '744_397': 32.3105,
    '744_398': 32.3831,
    '744_399': 32.4619,
    '744_400': 32.5462,
    '744_401': 32.6297,
    '744_402': 32.6874,
    '744_403': 32.7296,
    '744_404': 32.7588,
    '744_405': 32.7701,
    '744_406': 32.7806,
    '744_407': 32.7853,
    '744_408': 32.7835,
    '744_409': 32.7921,
    '744_410': 32.8088,
    '744_411': 32.8218,
    '744_412': 32.8377,
    '744_413': 32.8361,
    '744_414': 32.825,
    '744_415': 32.8208,
    '744_416': 32.7887,
    '744_417': 32.7601,
    '744_418': 32.7429,
    '744_419': 32.7364,
    '744_420': 32.743,
    '744_421': 32.7666,
    '744_422': 32.8012,
    '744_423': 32.8415,
    '744_424': 32.889,
    '744_425': 32.9372,
    '744_426': 32.9867,
    '744_427': 33.0613,
    '744_428': 33.1374,
    '744_429': 33.2125,
    '744_430': 33.2595,
    '744_431': 33.2732,
    '744_432': 33.2584,
    '744_433': 33.243,
    '744_434': 33.2482,
    '744_435': 33.2608,
    '744_436': 33.2765,
    '744_437': 33.2823,
    '744_438': 33.2637,
    '744_439': 33.2157,
    '744_440': 33.1529,
    '744_441': 33.0872,
    '744_442': 33.0254,
    '744_443': 32.9543,
    '744_444': 32.8607,
    '744_445': 32.7572,
    '744_446': 32.6578,
    '744_447': 32.5486,
    '744_448': 32.4097,
    '744_449': 32.237,
    '744_450': 32.0508,
    '744_451': 31.8597,
    '744_452': 31.6718,
    '744_453': 31.4814,
    '744_454': 31.316,
    '744_455': 31.1535,
    '744_456': 30.9916,
    '744_457': 30.8162,
    '744_458': 30.6563,
    '744_459': 30.4834,
    '744_460': 30.305,
    '744_461': 30.155,
    '744_462': 30.0073,
    '744_463': 29.8643,
    '744_464': 29.7097,
    '744_465': 29.5556,
    '744_466': 29.4116,
    '744_467': 29.2764,
    '744_468': 29.1453,
    '744_469': 29.0223,
    '744_470': 28.9045,
    '744_471': 28.7983,
    '744_472': 28.7023,
    '744_473': 28.6121,
    '744_474': 28.52,
    '744_475': 28.4402,
    '744_476': 28.3662,
    '744_477': 28.3037,
    '744_478': 28.2605,
    '744_479': 28.2386,
    '744_480': 28.2365,
    '745_373': 31.2054,
    '745_374': 31.2316,
    '745_375': 31.2582,
    '745_376': 31.2871,
    '745_377': 31.3275,
    '745_378': 31.372,
    '745_379': 31.4068,
    '745_380': 31.4431,
    '745_381': 31.4976,
    '745_382': 31.5381,
    '745_383': 31.5615,
    '745_384': 31.5821,
    '745_385': 31.6007,
    '745_386': 31.6143,
    '745_387': 31.6367,
    '745_388': 31.6643,
    '745_389': 31.702,
    '745_390': 31.7518,
    '745_391': 31.8154,
    '745_392': 31.8939,
    '745_393': 31.9874,
    '745_394': 32.0781,
    '745_395': 32.1574,
    '745_396': 32.2255,
    '745_397': 32.2851,
    '745_398': 32.3538,
    '745_399': 32.4337,
    '745_400': 32.5203,
    '745_401': 32.6089,
    '745_402': 32.673,
    '745_403': 32.7219,
    '745_404': 32.7573,
    '745_405': 32.7689,
    '745_406': 32.7835,
    '745_407': 32.784,
    '745_408': 32.7822,
    '745_409': 32.7973,
    '745_410': 32.8255,
    '745_411': 32.8496,
    '745_412': 32.8813,
    '745_413': 32.8863,
    '745_414': 32.8567,
    '745_415': 32.8483,
    '745_416': 32.8104,
    '745_417': 32.7736,
    '745_418': 32.7511,
    '745_419': 32.7405,
    '745_420': 32.7407,
    '745_421': 32.7539,
    '745_422': 32.7836,
    '745_423': 32.8275,
    '745_424': 32.8757,
    '745_425': 32.9223,
    '745_426': 32.9785,
    '745_427': 33.0574,
    '745_428': 33.1311,
    '745_429': 33.2153,
    '745_430': 33.2701,
    '745_431': 33.2894,
    '745_432': 33.2921,
    '745_433': 33.294,
    '745_434': 33.2945,
    '745_435': 33.3126,
    '745_436': 33.3432,
    '745_437': 33.3565,
    '745_438': 33.3439,
    '745_439': 33.3055,
    '745_440': 33.2446,
    '745_441': 33.1751,
    '745_442': 33.0946,
    '745_443': 33.005,
    '745_444': 32.9011,
    '745_445': 32.7987,
    '745_446': 32.6989,
    '745_447': 32.5873,
    '745_448': 32.4499,
    '745_449': 32.2884,
    '745_450': 32.1126,
    '745_451': 31.9371,
    '745_452': 31.7542,
    '745_453': 31.5657,
    '745_454': 31.3993,
    '745_455': 31.2268,
    '745_456': 31.0489,
    '745_457': 30.8557,
    '745_458': 30.6995,
    '745_459': 30.5434,
    '745_460': 30.3792,
    '745_461': 30.2354,
    '745_462': 30.0937,
    '745_463': 29.9395,
    '745_464': 29.7851,
    '745_465': 29.6395,
    '745_466': 29.5026,
    '745_467': 29.3723,
    '745_468': 29.2376,
    '745_469': 29.1085,
    '745_470': 28.9977,
    '745_471': 28.897,
    '745_472': 28.8046,
    '745_473': 28.711,
    '745_474': 28.6242,
    '745_475': 28.5527,
    '745_476': 28.4871,
    '745_477': 28.4316,
    '745_478': 28.3914,
    '745_479': 28.3687,
    '745_480': 28.3636,
    '746_373': 31.2174,
    '746_374': 31.2447,
    '746_375': 31.2734,
    '746_376': 31.3038,
    '746_377': 31.3407,
    '746_378': 31.3812,
    '746_379': 31.4148,
    '746_380': 31.4528,
    '746_381': 31.506,
    '746_382': 31.55,
    '746_383': 31.5821,
    '746_384': 31.6104,
    '746_385': 31.636,
    '746_386': 31.6563,
    '746_387': 31.68,
    '746_388': 31.7062,
    '746_389': 31.7428,
    '746_390': 31.7852,
    '746_391': 31.8392,
    '746_392': 31.9093,
    '746_393': 31.9934,
    '746_394': 32.0794,
    '746_395': 32.1501,
    '746_396': 32.209,
    '746_397': 32.2596,
    '746_398': 32.3275,
    '746_399': 32.4051,
    '746_400': 32.4896,
    '746_401': 32.5838,
    '746_402': 32.6599,
    '746_403': 32.715,
    '746_404': 32.7499,
    '746_405': 32.7544,
    '746_406': 32.7619,
    '746_407': 32.7633,
    '746_408': 32.7713,
    '746_409': 32.7953,
    '746_410': 32.8321,
    '746_411': 32.8686,
    '746_412': 32.8936,
    '746_413': 32.9056,
    '746_414': 32.8818,
    '746_415': 32.8657,
    '746_416': 32.8271,
    '746_417': 32.7855,
    '746_418': 32.7589,
    '746_419': 32.7455,
    '746_420': 32.7421,
    '746_421': 32.7486,
    '746_422': 32.7708,
    '746_423': 32.8091,
    '746_424': 32.8651,
    '746_425': 32.9208,
    '746_426': 32.9702,
    '746_427': 33.044,
    '746_428': 33.1279,
    '746_429': 33.2195,
    '746_430': 33.2799,
    '746_431': 33.3096,
    '746_432': 33.3304,
    '746_433': 33.3423,
    '746_434': 33.3388,
    '746_435': 33.3616,
    '746_436': 33.3936,
    '746_437': 33.4162,
    '746_438': 33.4155,
    '746_439': 33.3897,
    '746_440': 33.3333,
    '746_441': 33.256,
    '746_442': 33.1644,
    '746_443': 33.0601,
    '746_444': 32.9505,
    '746_445': 32.8381,
    '746_446': 32.7343,
    '746_447': 32.6237,
    '746_448': 32.4884,
    '746_449': 32.3388,
    '746_450': 32.1818,
    '746_451': 32.0165,
    '746_452': 31.8345,
    '746_453': 31.6464,
    '746_454': 31.4693,
    '746_455': 31.2878,
    '746_456': 31.0993,
    '746_457': 30.8974,
    '746_458': 30.7396,
    '746_459': 30.6036,
    '746_460': 30.4642,
    '746_461': 30.3198,
    '746_462': 30.1792,
    '746_463': 30.0239,
    '746_464': 29.8681,
    '746_465': 29.7264,
    '746_466': 29.5914,
    '746_467': 29.4633,
    '746_468': 29.3318,
    '746_469': 29.2053,
    '746_470': 29.0918,
    '746_471': 28.988,
    '746_472': 28.8919,
    '746_473': 28.8017,
    '746_474': 28.7243,
    '746_475': 28.6649,
    '746_476': 28.608,
    '746_477': 28.5578,
    '746_478': 28.5194,
    '746_479': 28.4952,
    '746_480': 28.4866,
    '746_790': 43.5409,
    '746_791': 43.5985,
    '746_792': 43.6196,
    '747_373': 31.221,
    '747_374': 31.2522,
    '747_375': 31.2835,
    '747_376': 31.3161,
    '747_377': 31.3516,
    '747_378': 31.3926,
    '747_379': 31.4266,
    '747_380': 31.4724,
    '747_381': 31.5232,
    '747_382': 31.5672,
    '747_383': 31.6035,
    '747_384': 31.6399,
    '747_385': 31.6727,
    '747_386': 31.7012,
    '747_387': 31.7318,
    '747_388': 31.7621,
    '747_389': 31.7929,
    '747_390': 31.8231,
    '747_391': 31.8649,
    '747_392': 31.9249,
    '747_393': 31.9981,
    '747_394': 32.0771,
    '747_395': 32.1406,
    '747_396': 32.1939,
    '747_397': 32.2446,
    '747_398': 32.3068,
    '747_399': 32.3785,
    '747_400': 32.4574,
    '747_401': 32.5459,
    '747_402': 32.6335,
    '747_403': 32.6954,
    '747_404': 32.7349,
    '747_405': 32.7345,
    '747_406': 32.7355,
    '747_407': 32.7373,
    '747_408': 32.7476,
    '747_409': 32.7771,
    '747_410': 32.8177,
    '747_411': 32.8631,
    '747_412': 32.8824,
    '747_413': 32.8995,
    '747_414': 32.8881,
    '747_415': 32.8684,
    '747_416': 32.8396,
    '747_417': 32.8004,
    '747_418': 32.7697,
    '747_419': 32.7533,
    '747_420': 32.7479,
    '747_421': 32.7519,
    '747_422': 32.7672,
    '747_423': 32.7948,
    '747_424': 32.8482,
    '747_425': 32.9146,
    '747_426': 32.9683,
    '747_427': 33.0439,
    '747_428': 33.1334,
    '747_429': 33.2263,
    '747_430': 33.2922,
    '747_431': 33.3337,
    '747_432': 33.3656,
    '747_433': 33.383,
    '747_434': 33.3813,
    '747_435': 33.4003,
    '747_436': 33.4354,
    '747_437': 33.4713,
    '747_438': 33.4765,
    '747_439': 33.4514,
    '747_440': 33.4076,
    '747_441': 33.3321,
    '747_442': 33.2319,
    '747_443': 33.1162,
    '747_444': 32.9999,
    '747_445': 32.8829,
    '747_446': 32.7674,
    '747_447': 32.6525,
    '747_448': 32.5234,
    '747_449': 32.3824,
    '747_450': 32.2357,
    '747_451': 32.0778,
    '747_452': 31.902,
    '747_453': 31.7106,
    '747_454': 31.5206,
    '747_455': 31.3291,
    '747_456': 31.1374,
    '747_457': 30.9495,
    '747_458': 30.7984,
    '747_459': 30.6765,
    '747_460': 30.555,
    '747_461': 30.4037,
    '747_462': 30.2549,
    '747_463': 30.1075,
    '747_464': 29.9549,
    '747_465': 29.8121,
    '747_466': 29.6793,
    '747_467': 29.5519,
    '747_468': 29.4261,
    '747_469': 29.303,
    '747_470': 29.1869,
    '747_471': 29.0795,
    '747_472': 28.9815,
    '747_473': 28.8978,
    '747_474': 28.8361,
    '747_475': 28.7844,
    '747_476': 28.73,
    '747_477': 28.6816,
    '747_478': 28.6433,
    '747_479': 28.6169,
    '747_480': 28.6037,
    '747_790': 43.5961,
    '747_791': 43.6534,
    '747_792': 43.6461,
    '748_373': 31.2282,
    '748_374': 31.2629,
    '748_375': 31.2964,
    '748_376': 31.334,
    '748_377': 31.3764,
    '748_378': 31.4185,
    '748_379': 31.4498,
    '748_380': 31.4928,
    '748_381': 31.5418,
    '748_382': 31.5891,
    '748_383': 31.6292,
    '748_384': 31.6735,
    '748_385': 31.7133,
    '748_386': 31.7459,
    '748_387': 31.7822,
    '748_388': 31.8186,
    '748_389': 31.8455,
    '748_390': 31.8688,
    '748_391': 31.9026,
    '748_392': 31.9506,
    '748_393': 32.0106,
    '748_394': 32.0773,
    '748_395': 32.1332,
    '748_396': 32.1803,
    '748_397': 32.2274,
    '748_398': 32.2846,
    '748_399': 32.3515,
    '748_400': 32.4249,
    '748_401': 32.5054,
    '748_402': 32.5923,
    '748_403': 32.6638,
    '748_404': 32.7058,
    '748_405': 32.7072,
    '748_406': 32.7039,
    '748_407': 32.7047,
    '748_408': 32.7128,
    '748_409': 32.7367,
    '748_410': 32.7749,
    '748_411': 32.8188,
    '748_412': 32.8447,
    '748_413': 32.8727,
    '748_414': 32.8785,
    '748_415': 32.8568,
    '748_416': 32.8381,
    '748_417': 32.8132,
    '748_418': 32.7772,
    '748_419': 32.7595,
    '748_420': 32.7538,
    '748_421': 32.7567,
    '748_422': 32.7694,
    '748_423': 32.7926,
    '748_424': 32.8321,
    '748_425': 32.8967,
    '748_426': 32.9596,
    '748_427': 33.0429,
    '748_428': 33.1313,
    '748_429': 33.226,
    '748_430': 33.2941,
    '748_431': 33.347,
    '748_432': 33.3902,
    '748_433': 33.4152,
    '748_434': 33.4195,
    '748_435': 33.4305,
    '748_436': 33.4638,
    '748_437': 33.4991,
    '748_438': 33.5109,
    '748_439': 33.5034,
    '748_440': 33.462,
    '748_441': 33.3899,
    '748_442': 33.2872,
    '748_443': 33.1701,
    '748_444': 33.0548,
    '748_445': 32.9372,
    '748_446': 32.8181,
    '748_447': 32.6925,
    '748_448': 32.5544,
    '748_449': 32.4143,
    '748_450': 32.2743,
    '748_451': 32.1221,
    '748_452': 31.9497,
    '748_453': 31.7586,
    '748_454': 31.5709,
    '748_455': 31.3743,
    '748_456': 31.1971,
    '748_457': 31.0165,
    '748_458': 30.8678,
    '748_459': 30.7426,
    '748_460': 30.6252,
    '748_461': 30.4778,
    '748_462': 30.3219,
    '748_463': 30.1796,
    '748_464': 30.0373,
    '748_465': 29.8936,
    '748_466': 29.7621,
    '748_467': 29.6401,
    '748_468': 29.5232,
    '748_469': 29.4006,
    '748_470': 29.2821,
    '748_471': 29.174,
    '748_472': 29.078,
    '748_473': 29.0048,
    '748_474': 28.9491,
    '748_475': 28.8972,
    '748_476': 28.846,
    '748_477': 28.7988,
    '748_478': 28.761,
    '748_479': 28.7329,
    '748_480': 28.715,
    '748_790': 43.6134,
    '748_791': 43.66,
    '748_792': 43.6529,
    '749_373': 31.2356,
    '749_374': 31.2796,
    '749_375': 31.3151,
    '749_376': 31.3577,
    '749_377': 31.4066,
    '749_378': 31.4498,
    '749_379': 31.4859,
    '749_380': 31.5219,
    '749_381': 31.5676,
    '749_382': 31.6157,
    '749_383': 31.6643,
    '749_384': 31.7132,
    '749_385': 31.7597,
    '749_386': 31.7963,
    '749_387': 31.8358,
    '749_388': 31.8741,
    '749_389': 31.9005,
    '749_390': 31.9164,
    '749_391': 31.9438,
    '749_392': 31.9815,
    '749_393': 32.0277,
    '749_394': 32.0846,
    '749_395': 32.1339,
    '749_396': 32.1741,
    '749_397': 32.2121,
    '749_398': 32.2605,
    '749_399': 32.3267,
    '749_400': 32.396,
    '749_401': 32.4711,
    '749_402': 32.5512,
    '749_403': 32.6242,
    '749_404': 32.6719,
    '749_405': 32.6761,
    '749_406': 32.6707,
    '749_407': 32.6711,
    '749_408': 32.6767,
    '749_409': 32.6948,
    '749_410': 32.7238,
    '749_411': 32.7605,
    '749_412': 32.7949,
    '749_413': 32.8337,
    '749_414': 32.8516,
    '749_415': 32.8356,
    '749_416': 32.8238,
    '749_417': 32.8074,
    '749_418': 32.7807,
    '749_419': 32.764,
    '749_420': 32.7581,
    '749_421': 32.761,
    '749_422': 32.7732,
    '749_423': 32.7935,
    '749_424': 32.8211,
    '749_425': 32.8751,
    '749_426': 32.9538,
    '749_427': 33.0432,
    '749_428': 33.1276,
    '749_429': 33.2103,
    '749_430': 33.2769,
    '749_431': 33.34,
    '749_432': 33.392,
    '749_433': 33.4275,
    '749_434': 33.4471,
    '749_435': 33.4577,
    '749_436': 33.4816,
    '749_437': 33.5167,
    '749_438': 33.5384,
    '749_439': 33.5426,
    '749_440': 33.5085,
    '749_441': 33.4463,
    '749_442': 33.344,
    '749_443': 33.2234,
    '749_444': 33.1168,
    '749_445': 33.0117,
    '749_446': 32.889,
    '749_447': 32.7515,
    '749_448': 32.6101,
    '749_449': 32.4647,
    '749_450': 32.3053,
    '749_451': 32.1472,
    '749_452': 31.979,
    '749_453': 31.7923,
    '749_454': 31.6179,
    '749_455': 31.4324,
    '749_456': 31.2731,
    '749_457': 31.1032,
    '749_458': 30.9376,
    '749_459': 30.7993,
    '749_460': 30.6802,
    '749_461': 30.5454,
    '749_462': 30.3921,
    '749_463': 30.2437,
    '749_464': 30.1094,
    '749_465': 29.9809,
    '749_466': 29.8444,
    '749_467': 29.7267,
    '749_468': 29.6191,
    '749_469': 29.4964,
    '749_470': 29.3775,
    '749_471': 29.2731,
    '749_472': 29.1832,
    '749_473': 29.111,
    '749_474': 29.0541,
    '749_475': 29.0046,
    '749_476': 28.9566,
    '749_477': 28.9108,
    '749_478': 28.8733,
    '749_479': 28.845,
    '749_480': 28.8239,
    '749_790': 43.5954,
    '749_791': 43.6359,
    '749_792': 43.6464,
    '750_373': 31.246,
    '750_374': 31.301,
    '750_375': 31.3472,
    '750_376': 31.3913,
    '750_377': 31.4416,
    '750_378': 31.4861,
    '750_379': 31.5254,
    '750_380': 31.5633,
    '750_381': 31.6093,
    '750_382': 31.6582,
    '750_383': 31.7136,
    '750_384': 31.765,
    '750_385': 31.8126,
    '750_386': 31.8572,
    '750_387': 31.9007,
    '750_388': 31.9373,
    '750_389': 31.957,
    '750_390': 31.9705,
    '750_391': 31.9897,
    '750_392': 32.0215,
    '750_393': 32.0571,
    '750_394': 32.1045,
    '750_395': 32.1441,
    '750_396': 32.1763,
    '750_397': 32.2086,
    '750_398': 32.2526,
    '750_399': 32.3131,
    '750_400': 32.3768,
    '750_401': 32.4438,
    '750_402': 32.5137,
    '750_403': 32.5808,
    '750_404': 32.6257,
    '750_405': 32.6374,
    '750_406': 32.6363,
    '750_407': 32.6396,
    '750_408': 32.6439,
    '750_409': 32.6566,
    '750_410': 32.6769,
    '750_411': 32.7078,
    '750_412': 32.7437,
    '750_413': 32.7815,
    '750_414': 32.8037,
    '750_415': 32.8027,
    '750_416': 32.7987,
    '750_417': 32.7922,
    '750_418': 32.7772,
    '750_419': 32.7626,
    '750_420': 32.7585,
    '750_421': 32.7625,
    '750_422': 32.7749,
    '750_423': 32.7927,
    '750_424': 32.8165,
    '750_425': 32.8572,
    '750_426': 32.9301,
    '750_427': 33.0232,
    '750_428': 33.1047,
    '750_429': 33.177,
    '750_430': 33.2404,
    '750_431': 33.3124,
    '750_432': 33.3751,
    '750_433': 33.4227,
    '750_434': 33.4585,
    '750_435': 33.4826,
    '750_436': 33.5038,
    '750_437': 33.5342,
    '750_438': 33.5561,
    '750_439': 33.5631,
    '750_440': 33.5374,
    '750_441': 33.4884,
    '750_442': 33.3932,
    '750_443': 33.2839,
    '750_444': 33.1877,
    '750_445': 33.0858,
    '750_446': 32.9594,
    '750_447': 32.8166,
    '750_448': 32.6754,
    '750_449': 32.5299,
    '750_450': 32.3593,
    '750_451': 32.1797,
    '750_452': 32.0051,
    '750_453': 31.8282,
    '750_454': 31.6658,
    '750_455': 31.5078,
    '750_456': 31.3595,
    '750_457': 31.1873,
    '750_458': 31.0099,
    '750_459': 30.8575,
    '750_460': 30.7268,
    '750_461': 30.6016,
    '750_462': 30.4602,
    '750_463': 30.3185,
    '750_464': 30.1869,
    '750_465': 30.0635,
    '750_466': 29.9268,
    '750_467': 29.8077,
    '750_468': 29.7,
    '750_469': 29.5836,
    '750_470': 29.4692,
    '750_471': 29.3695,
    '750_472': 29.2841,
    '750_473': 29.2116,
    '750_474': 29.1539,
    '750_475': 29.1067,
    '750_476': 29.059,
    '750_477': 29.0155,
    '750_478': 28.9792,
    '750_479': 28.9496,
    '750_480': 28.927,
    '750_481': 28.9184,
    '750_482': 28.9275,
    '750_483': 28.9553,
    '750_484': 28.9944,
    '750_485': 29.0366,
    '750_486': 29.0808,
    '750_487': 29.1301,
    '750_488': 29.1863,
    '750_489': 29.2482,
    '750_490': 29.3258,
    '750_491': 29.4175,
    '750_492': 29.5198,
    '750_493': 29.6261,
    '750_494': 29.7353,
    '750_495': 29.8483,
    '750_496': 29.968,
    '750_497': 30.0863,
    '750_498': 30.207,
    '750_499': 30.3338,
    '750_500': 30.4657,
    '750_501': 30.6029,
    '750_502': 30.7625,
    '750_503': 30.9269,
    '750_504': 31.0989,
    '750_505': 31.2821,
    '750_506': 31.4699,
    '750_507': 31.6614,
    '750_508': 31.8521,
    '750_509': 32.0334,
    '750_510': 32.2019,
    '750_511': 32.3638,
    '750_512': 32.5173,
    '750_513': 32.665,
    '750_514': 32.7995,
    '750_515': 32.9216,
    '750_516': 33.0284,
    '750_517': 33.1276,
    '750_518': 33.2159,
    '750_519': 33.2939,
    '750_520': 33.3628,
    '750_521': 33.423,
    '750_522': 33.4701,
    '750_523': 33.5045,
    '750_524': 33.5346,
    '750_525': 33.5656,
    '750_526': 33.6016,
    '750_527': 33.6463,
    '750_528': 33.6952,
    '750_529': 33.7381,
    '750_530': 33.779,
    '750_531': 33.814,
    '750_532': 33.847,
    '750_533': 33.8775,
    '750_534': 33.9127,
    '750_535': 33.9625,
    '750_536': 34.0119,
    '750_537': 34.0629,
    '750_538': 34.1175,
    '750_539': 34.1766,
    '750_540': 34.2235,
    '750_541': 34.2767,
    '750_542': 34.3529,
    '750_543': 34.4217,
    '750_544': 34.496,
    '751_341': 30.3332,
    '751_342': 30.4012,
    '751_343': 30.4628,
    '751_344': 30.5191,
    '751_345': 30.572,
    '751_346': 30.6264,
    '751_347': 30.6564,
    '751_348': 30.6911,
    '751_349': 30.7229,
    '751_350': 30.7492,
    '751_351': 30.7745,
    '751_352': 30.8075,
    '751_353': 30.841,
    '751_354': 30.8806,
    '751_355': 30.9286,
    '751_356': 30.9759,
    '751_357': 31.0143,
    '751_358': 31.0448,
    '751_359': 31.0695,
    '751_360': 31.093,
    '751_373': 31.2594,
    '751_374': 31.3185,
    '751_375': 31.3712,
    '751_376': 31.4196,
    '751_377': 31.4719,
    '751_378': 31.5186,
    '751_379': 31.5592,
    '751_380': 31.6003,
    '751_381': 31.6477,
    '751_382': 31.6996,
    '751_383': 31.7533,
    '751_384': 31.8069,
    '751_385': 31.8587,
    '751_386': 31.9043,
    '751_387': 31.9498,
    '751_388': 31.9867,
    '751_389': 32.0092,
    '751_390': 32.0224,
    '751_391': 32.033,
    '751_392': 32.0524,
    '751_393': 32.0867,
    '751_394': 32.1297,
    '751_395': 32.1544,
    '751_396': 32.1773,
    '751_397': 32.2059,
    '751_398': 32.2475,
    '751_399': 32.3029,
    '751_400': 32.3623,
    '751_401': 32.4234,
    '751_402': 32.4797,
    '751_403': 32.5347,
    '751_404': 32.5785,
    '751_405': 32.6039,
    '751_406': 32.6086,
    '751_407': 32.6134,
    '751_408': 32.6161,
    '751_409': 32.6248,
    '751_410': 32.6398,
    '751_411': 32.662,
    '751_412': 32.6921,
    '751_413': 32.7238,
    '751_414': 32.7485,
    '751_415': 32.7639,
    '751_416': 32.77,
    '751_417': 32.7709,
    '751_418': 32.7647,
    '751_419': 32.7571,
    '751_420': 32.7567,
    '751_421': 32.7633,
    '751_422': 32.776,
    '751_423': 32.7928,
    '751_424': 32.8128,
    '751_425': 32.845,
    '751_426': 32.9029,
    '751_427': 32.9929,
    '751_428': 33.0661,
    '751_429': 33.1338,
    '751_430': 33.1998,
    '751_431': 33.2756,
    '751_432': 33.3426,
    '751_433': 33.4007,
    '751_434': 33.4558,
    '751_435': 33.5008,
    '751_436': 33.5314,
    '751_437': 33.5536,
    '751_438': 33.5693,
    '751_439': 33.5799,
    '751_440': 33.5688,
    '751_441': 33.5179,
    '751_442': 33.4424,
    '751_443': 33.347,
    '751_444': 33.2549,
    '751_445': 33.1448,
    '751_446': 33.0225,
    '751_447': 32.8768,
    '751_448': 32.7404,
    '751_449': 32.5967,
    '751_450': 32.4274,
    '751_451': 32.2419,
    '751_452': 32.0607,
    '751_453': 31.8923,
    '751_454': 31.7413,
    '751_455': 31.5988,
    '751_456': 31.445,
    '751_457': 31.268,
    '751_458': 31.0934,
    '751_459': 30.9288,
    '751_460': 30.7832,
    '751_461': 30.6484,
    '751_462': 30.5182,
    '751_463': 30.3958,
    '751_464': 30.2657,
    '751_465': 30.1391,
    '751_466': 30.0065,
    '751_467': 29.8847,
    '751_468': 29.7749,
    '751_469': 29.6649,
    '751_470': 29.5578,
    '751_471': 29.4625,
    '751_472': 29.3789,
    '751_473': 29.3068,
    '751_474': 29.2502,
    '751_475': 29.2027,
    '751_476': 29.1573,
    '751_477': 29.1173,
    '751_478': 29.0828,
    '751_479': 29.0532,
    '751_480': 29.0303,
    '751_481': 29.0199,
    '751_482': 29.0245,
    '751_483': 29.0507,
    '751_484': 29.0879,
    '751_485': 29.1267,
    '751_486': 29.1703,
    '751_487': 29.2205,
    '751_488': 29.2782,
    '751_489': 29.3422,
    '751_490': 29.421,
    '751_491': 29.5125,
    '751_492': 29.6138,
    '751_493': 29.7204,
    '751_494': 29.8297,
    '751_495': 29.9432,
    '751_496': 30.0641,
    '751_497': 30.193,
    '751_498': 30.3154,
    '751_499': 30.4435,
    '751_500': 30.5799,
    '751_501': 30.7231,
    '751_502': 30.8812,
    '751_503': 31.0427,
    '751_504': 31.2163,
    '751_505': 31.3998,
    '751_506': 31.591,
    '751_507': 31.7829,
    '751_508': 31.9699,
    '751_509': 32.1514,
    '751_510': 32.3237,
    '751_511': 32.4867,
    '751_512': 32.6347,
    '751_513': 32.7765,
    '751_514': 32.9062,
    '751_515': 33.0223,
    '751_516': 33.122,
    '751_517': 33.2116,
    '751_518': 33.2958,
    '751_519': 33.3708,
    '751_520': 33.4371,
    '751_521': 33.4981,
    '751_522': 33.5459,
    '751_523': 33.5748,
    '751_524': 33.5949,
    '751_525': 33.6189,
    '751_526': 33.6515,
    '751_527': 33.6906,
    '751_528': 33.7292,
    '751_529': 33.7693,
    '751_530': 33.8135,
    '751_531': 33.8477,
    '751_532': 33.8746,
    '751_533': 33.8938,
    '751_534': 33.9218,
    '751_535': 33.9631,
    '751_536': 34.0139,
    '751_537': 34.0634,
    '751_538': 34.1151,
    '751_539': 34.1721,
    '751_540': 34.2196,
    '751_541': 34.2733,
    '751_542': 34.3515,
    '751_543': 34.4213,
    '751_544': 34.4887,
    '752_341': 30.3554,
    '752_342': 30.4214,
    '752_343': 30.4855,
    '752_344': 30.5466,
    '752_345': 30.6024,
    '752_346': 30.6493,
    '752_347': 30.6764,
    '752_348': 30.7038,
    '752_349': 30.7296,
    '752_350': 30.7504,
    '752_351': 30.7686,
    '752_352': 30.7923,
    '752_353': 30.8187,
    '752_354': 30.8549,
    '752_355': 30.9105,
    '752_356': 30.9657,
    '752_357': 31.0093,
    '752_358': 31.046,
    '752_359': 31.0776,
    '752_360': 31.1069,
    '752_373': 31.2783,
    '752_374': 31.3374,
    '752_375': 31.393,
    '752_376': 31.4453,
    '752_377': 31.4977,
    '752_378': 31.5532,
    '752_379': 31.5928,
    '752_380': 31.6321,
    '752_381': 31.6802,
    '752_382': 31.7347,
    '752_383': 31.7862,
    '752_384': 31.8415,
    '752_385': 31.8986,
    '752_386': 31.949,
    '752_387': 31.9931,
    '752_388': 32.0305,
    '752_389': 32.0587,
    '752_390': 32.0777,
    '752_391': 32.0843,
    '752_392': 32.093,
    '752_393': 32.1141,
    '752_394': 32.1482,
    '752_395': 32.1606,
    '752_396': 32.1769,
    '752_397': 32.2009,
    '752_398': 32.2388,
    '752_399': 32.2912,
    '752_400': 32.3452,
    '752_401': 32.4003,
    '752_402': 32.4446,
    '752_403': 32.4884,
    '752_404': 32.5322,
    '752_405': 32.5663,
    '752_406': 32.5802,
    '752_407': 32.5864,
    '752_408': 32.5876,
    '752_409': 32.593,
    '752_410': 32.6034,
    '752_411': 32.62,
    '752_412': 32.6416,
    '752_413': 32.6655,
    '752_414': 32.6935,
    '752_415': 32.7206,
    '752_416': 32.7391,
    '752_417': 32.7477,
    '752_418': 32.7484,
    '752_419': 32.7484,
    '752_420': 32.7528,
    '752_421': 32.7615,
    '752_422': 32.7745,
    '752_423': 32.7891,
    '752_424': 32.8075,
    '752_425': 32.8344,
    '752_426': 32.8792,
    '752_427': 32.951,
    '752_428': 33.0225,
    '752_429': 33.0875,
    '752_430': 33.1674,
    '752_431': 33.2441,
    '752_432': 33.3058,
    '752_433': 33.3696,
    '752_434': 33.4432,
    '752_435': 33.507,
    '752_436': 33.551,
    '752_437': 33.5717,
    '752_438': 33.5887,
    '752_439': 33.5941,
    '752_440': 33.5969,
    '752_441': 33.5485,
    '752_442': 33.4838,
    '752_443': 33.3976,
    '752_444': 33.3106,
    '752_445': 33.2093,
    '752_446': 33.0772,
    '752_447': 32.9334,
    '752_448': 32.8021,
    '752_449': 32.659,
    '752_450': 32.4905,
    '752_451': 32.3044,
    '752_452': 32.1263,
    '752_453': 31.9699,
    '752_454': 31.8307,
    '752_455': 31.6919,
    '752_456': 31.5299,
    '752_457': 31.3567,
    '752_458': 31.1849,
    '752_459': 31.011,
    '752_460': 30.8489,
    '752_461': 30.7043,
    '752_462': 30.578,
    '752_463': 30.462,
    '752_464': 30.3363,
    '752_465': 30.2081,
    '752_466': 30.0791,
    '752_467': 29.9565,
    '752_468': 29.8476,
    '752_469': 29.7434,
    '752_470': 29.6428,
    '752_471': 29.5519,
    '752_472': 29.4708,
    '752_473': 29.4009,
    '752_474': 29.3441,
    '752_475': 29.2967,
    '752_476': 29.2532,
    '752_477': 29.2156,
    '752_478': 29.1821,
    '752_479': 29.1521,
    '752_480': 29.1303,
    '752_481': 29.1206,
    '752_482': 29.1239,
    '752_483': 29.1438,
    '752_484': 29.1749,
    '752_485': 29.2123,
    '752_486': 29.2562,
    '752_487': 29.3072,
    '752_488': 29.3682,
    '752_489': 29.4351,
    '752_490': 29.5145,
    '752_491': 29.6066,
    '752_492': 29.7084,
    '752_493': 29.8156,
    '752_494': 29.9241,
    '752_495': 30.0372,
    '752_496': 30.1595,
    '752_497': 30.2886,
    '752_498': 30.4201,
    '752_499': 30.5539,
    '752_500': 30.692,
    '752_501': 30.8382,
    '752_502': 30.9929,
    '752_503': 31.1611,
    '752_504': 31.3367,
    '752_505': 31.5212,
    '752_506': 31.7085,
    '752_507': 31.8954,
    '752_508': 32.0816,
    '752_509': 32.2646,
    '752_510': 32.4378,
    '752_511': 32.5969,
    '752_512': 32.7442,
    '752_513': 32.881,
    '752_514': 33.0057,
    '752_515': 33.1161,
    '752_516': 33.2103,
    '752_517': 33.2945,
    '752_518': 33.3729,
    '752_519': 33.444,
    '752_520': 33.506,
    '752_521': 33.5685,
    '752_522': 33.6174,
    '752_523': 33.6388,
    '752_524': 33.6478,
    '752_525': 33.6622,
    '752_526': 33.6919,
    '752_527': 33.7283,
    '752_528': 33.7652,
    '752_529': 33.7946,
    '752_530': 33.8406,
    '752_531': 33.8735,
    '752_532': 33.8928,
    '752_533': 33.9077,
    '752_534': 33.935,
    '752_535': 33.9725,
    '752_536': 34.0234,
    '752_537': 34.0682,
    '752_538': 34.1167,
    '752_539': 34.1687,
    '752_540': 34.2204,
    '752_541': 34.2784,
    '752_542': 34.3531,
    '752_543': 34.4211,
    '752_544': 34.4836,
    '753_341': 30.3752,
    '753_342': 30.445,
    '753_343': 30.5121,
    '753_344': 30.5784,
    '753_345': 30.6339,
    '753_346': 30.668,
    '753_347': 30.6973,
    '753_348': 30.7245,
    '753_349': 30.7495,
    '753_350': 30.771,
    '753_351': 30.7901,
    '753_352': 30.8111,
    '753_353': 30.8368,
    '753_354': 30.8724,
    '753_355': 30.9219,
    '753_356': 30.9732,
    '753_357': 31.0176,
    '753_358': 31.0586,
    '753_359': 31.0968,
    '753_360': 31.1331,
    '753_373': 31.2984,
    '753_374': 31.3587,
    '753_375': 31.4166,
    '753_376': 31.4724,
    '753_377': 31.5273,
    '753_378': 31.5802,
    '753_379': 31.6181,
    '753_380': 31.659,
    '753_381': 31.7073,
    '753_382': 31.7609,
    '753_383': 31.8153,
    '753_384': 31.8726,
    '753_385': 31.9314,
    '753_386': 31.9841,
    '753_387': 32.0293,
    '753_388': 32.0673,
    '753_389': 32.097,
    '753_390': 32.1219,
    '753_391': 32.1403,
    '753_392': 32.1443,
    '753_393': 32.1506,
    '753_394': 32.1666,
    '753_395': 32.172,
    '753_396': 32.1855,
    '753_397': 32.2023,
    '753_398': 32.232,
    '753_399': 32.2749,
    '753_400': 32.3214,
    '753_401': 32.3675,
    '753_402': 32.4071,
    '753_403': 32.4462,
    '753_404': 32.4855,
    '753_405': 32.5201,
    '753_406': 32.5409,
    '753_407': 32.5512,
    '753_408': 32.5535,
    '753_409': 32.5587,
    '753_410': 32.5655,
    '753_411': 32.5775,
    '753_412': 32.5916,
    '753_413': 32.6088,
    '753_414': 32.64,
    '753_415': 32.6748,
    '753_416': 32.7045,
    '753_417': 32.7237,
    '753_418': 32.7321,
    '753_419': 32.7376,
    '753_420': 32.7446,
    '753_421': 32.7554,
    '753_422': 32.7689,
    '753_423': 32.7826,
    '753_424': 32.7996,
    '753_425': 32.8225,
    '753_426': 32.8587,
    '753_427': 32.9128,
    '753_428': 32.9777,
    '753_429': 33.0408,
    '753_430': 33.1191,
    '753_431': 33.2012,
    '753_432': 33.2705,
    '753_433': 33.3405,
    '753_434': 33.4217,
    '753_435': 33.4974,
    '753_436': 33.5494,
    '753_437': 33.5803,
    '753_438': 33.6003,
    '753_439': 33.6121,
    '753_440': 33.6014,
    '753_441': 33.5627,
    '753_442': 33.5067,
    '753_443': 33.4349,
    '753_444': 33.3458,
    '753_445': 33.2472,
    '753_446': 33.1205,
    '753_447': 32.9815,
    '753_448': 32.8529,
    '753_449': 32.7122,
    '753_450': 32.5432,
    '753_451': 32.3613,
    '753_452': 32.1973,
    '753_453': 32.0453,
    '753_454': 31.9155,
    '753_455': 31.7776,
    '753_456': 31.6181,
    '753_457': 31.4539,
    '753_458': 31.2838,
    '753_459': 31.1064,
    '753_460': 30.9264,
    '753_461': 30.767,
    '753_462': 30.6338,
    '753_463': 30.5166,
    '753_464': 30.3962,
    '753_465': 30.271,
    '753_466': 30.1481,
    '753_467': 30.0314,
    '753_468': 29.9227,
    '753_469': 29.8208,
    '753_470': 29.7255,
    '753_471': 29.6377,
    '753_472': 29.5586,
    '753_473': 29.4896,
    '753_474': 29.4321,
    '753_475': 29.3842,
    '753_476': 29.3423,
    '753_477': 29.3063,
    '753_478': 29.2726,
    '753_479': 29.243,
    '753_480': 29.2215,
    '753_481': 29.2113,
    '753_482': 29.2151,
    '753_483': 29.2316,
    '753_484': 29.2585,
    '753_485': 29.2949,
    '753_486': 29.3399,
    '753_487': 29.3924,
    '753_488': 29.4544,
    '753_489': 29.5235,
    '753_490': 29.6032,
    '753_491': 29.6978,
    '753_492': 29.8017,
    '753_493': 29.9111,
    '753_494': 30.0193,
    '753_495': 30.1346,
    '753_496': 30.2585,
    '753_497': 30.392,
    '753_498': 30.5334,
    '753_499': 30.6678,
    '753_500': 30.805,
    '753_501': 30.9542,
    '753_502': 31.1126,
    '753_503': 31.2823,
    '753_504': 31.4585,
    '753_505': 31.6399,
    '753_506': 31.8182,
    '753_507': 32.0025,
    '753_508': 32.1893,
    '753_509': 32.3722,
    '753_510': 32.5453,
    '753_511': 32.7002,
    '753_512': 32.8442,
    '753_513': 32.9782,
    '753_514': 33.0992,
    '753_515': 33.2044,
    '753_516': 33.2918,
    '753_517': 33.3705,
    '753_518': 33.4411,
    '753_519': 33.5092,
    '753_520': 33.5705,
    '753_521': 33.6275,
    '753_522': 33.665,
    '753_523': 33.682,
    '753_524': 33.69,
    '753_525': 33.7021,
    '753_526': 33.7279,
    '753_527': 33.76,
    '753_528': 33.7945,
    '753_529': 33.8212,
    '753_530': 33.8667,
    '753_531': 33.8933,
    '753_532': 33.9115,
    '753_533': 33.9281,
    '753_534': 33.9542,
    '753_535': 33.9892,
    '753_536': 34.0322,
    '753_537': 34.0783,
    '753_538': 34.1269,
    '753_539': 34.1787,
    '753_540': 34.2292,
    '753_541': 34.288,
    '753_542': 34.3572,
    '753_543': 34.4243,
    '753_544': 34.4854,
    '754_341': 30.3958,
    '754_342': 30.4655,
    '754_343': 30.5307,
    '754_344': 30.593,
    '754_345': 30.6459,
    '754_346': 30.6852,
    '754_347': 30.7188,
    '754_348': 30.7496,
    '754_349': 30.7778,
    '754_350': 30.8032,
    '754_351': 30.8252,
    '754_352': 30.8458,
    '754_353': 30.871,
    '754_354': 30.9045,
    '754_355': 30.9476,
    '754_356': 30.9936,
    '754_357': 31.0359,
    '754_358': 31.0774,
    '754_359': 31.1177,
    '754_360': 31.1562,
    '754_373': 31.3233,
    '754_374': 31.3829,
    '754_375': 31.4408,
    '754_376': 31.4971,
    '754_377': 31.5507,
    '754_378': 31.5988,
    '754_379': 31.6348,
    '754_380': 31.6779,
    '754_381': 31.7274,
    '754_382': 31.7811,
    '754_383': 31.8371,
    '754_384': 31.8951,
    '754_385': 31.9535,
    '754_386': 32.0108,
    '754_387': 32.0568,
    '754_388': 32.0945,
    '754_389': 32.1273,
    '754_390': 32.1572,
    '754_391': 32.1803,
    '754_392': 32.189,
    '754_393': 32.1875,
    '754_394': 32.188,
    '754_395': 32.1893,
    '754_396': 32.1959,
    '754_397': 32.2051,
    '754_398': 32.2266,
    '754_399': 32.2617,
    '754_400': 32.3005,
    '754_401': 32.3396,
    '754_402': 32.3751,
    '754_403': 32.4063,
    '754_404': 32.4395,
    '754_405': 32.4765,
    '754_406': 32.5008,
    '754_407': 32.5156,
    '754_408': 32.5243,
    '754_409': 32.5293,
    '754_410': 32.5321,
    '754_411': 32.5406,
    '754_412': 32.5502,
    '754_413': 32.5651,
    '754_414': 32.5916,
    '754_415': 32.6272,
    '754_416': 32.664,
    '754_417': 32.6939,
    '754_418': 32.7109,
    '754_419': 32.7226,
    '754_420': 32.7314,
    '754_421': 32.7447,
    '754_422': 32.7584,
    '754_423': 32.773,
    '754_424': 32.7895,
    '754_425': 32.8101,
    '754_426': 32.8402,
    '754_427': 32.8828,
    '754_428': 32.9385,
    '754_429': 32.9999,
    '754_430': 33.0745,
    '754_431': 33.1522,
    '754_432': 33.2271,
    '754_433': 33.3085,
    '754_434': 33.3918,
    '754_435': 33.4675,
    '754_436': 33.5239,
    '754_437': 33.5622,
    '754_438': 33.5876,
    '754_439': 33.602,
    '754_440': 33.5916,
    '754_441': 33.5732,
    '754_442': 33.5193,
    '754_443': 33.4594,
    '754_444': 33.3707,
    '754_445': 33.2717,
    '754_446': 33.1483,
    '754_447': 33.0204,
    '754_448': 32.8971,
    '754_449': 32.7562,
    '754_450': 32.5901,
    '754_451': 32.4167,
    '754_452': 32.2641,
    '754_453': 32.1165,
    '754_454': 31.986,
    '754_455': 31.846,
    '754_456': 31.6889,
    '754_457': 31.5329,
    '754_458': 31.3645,
    '754_459': 31.1925,
    '754_460': 31.005,
    '754_461': 30.8388,
    '754_462': 30.7028,
    '754_463': 30.5783,
    '754_464': 30.4571,
    '754_465': 30.3355,
    '754_466': 30.2187,
    '754_467': 30.106,
    '754_468': 30.0004,
    '754_469': 29.9018,
    '754_470': 29.8092,
    '754_471': 29.7226,
    '754_472': 29.6441,
    '754_473': 29.5746,
    '754_474': 29.5164,
    '754_475': 29.4677,
    '754_476': 29.426,
    '754_477': 29.3905,
    '754_478': 29.3578,
    '754_479': 29.3272,
    '754_480': 29.3046,
    '754_481': 29.2938,
    '754_482': 29.2984,
    '754_483': 29.3159,
    '754_484': 29.3407,
    '754_485': 29.3764,
    '754_486': 29.4214,
    '754_487': 29.4761,
    '754_488': 29.5413,
    '754_489': 29.6124,
    '754_490': 29.694,
    '754_491': 29.7885,
    '754_492': 29.8911,
    '754_493': 30.0001,
    '754_494': 30.1131,
    '754_495': 30.2333,
    '754_496': 30.3608,
    '754_497': 30.4953,
    '754_498': 30.6365,
    '754_499': 30.7814,
    '754_500': 30.9235,
    '754_501': 31.0732,
    '754_502': 31.233,
    '754_503': 31.4033,
    '754_504': 31.576,
    '754_505': 31.7507,
    '754_506': 31.9298,
    '754_507': 32.1109,
    '754_508': 32.2924,
    '754_509': 32.4687,
    '754_510': 32.6363,
    '754_511': 32.7929,
    '754_512': 32.9378,
    '754_513': 33.0691,
    '754_514': 33.1857,
    '754_515': 33.2842,
    '754_516': 33.3687,
    '754_517': 33.4435,
    '754_518': 33.5088,
    '754_519': 33.572,
    '754_520': 33.6278,
    '754_521': 33.6783,
    '754_522': 33.7099,
    '754_523': 33.7242,
    '754_524': 33.7334,
    '754_525': 33.7477,
    '754_526': 33.7682,
    '754_527': 33.7938,
    '754_528': 33.8235,
    '754_529': 33.8483,
    '754_530': 33.8822,
    '754_531': 33.9133,
    '754_532': 33.9319,
    '754_533': 33.9503,
    '754_534': 33.976,
    '754_535': 34.0127,
    '754_536': 34.0569,
    '754_537': 34.1011,
    '754_538': 34.1441,
    '754_539': 34.1911,
    '754_540': 34.2416,
    '754_541': 34.3006,
    '754_542': 34.3662,
    '754_543': 34.4312,
    '754_544': 34.4914,
    '755_341': 30.4059,
    '755_342': 30.476,
    '755_343': 30.5412,
    '755_344': 30.602,
    '755_345': 30.6556,
    '755_346': 30.7011,
    '755_347': 30.7403,
    '755_348': 30.7768,
    '755_349': 30.8094,
    '755_350': 30.8391,
    '755_351': 30.8643,
    '755_352': 30.884,
    '755_353': 30.9082,
    '755_354': 30.9397,
    '755_355': 30.9784,
    '755_356': 31.0197,
    '755_357': 31.0605,
    '755_358': 31.0995,
    '755_359': 31.1389,
    '755_360': 31.1765,
    '755_373': 31.3481,
    '755_374': 31.4065,
    '755_375': 31.4632,
    '755_376': 31.5182,
    '755_377': 31.5672,
    '755_378': 31.6041,
    '755_379': 31.6451,
    '755_380': 31.691,
    '755_381': 31.7415,
    '755_382': 31.7955,
    '755_383': 31.8519,
    '755_384': 31.91,
    '755_385': 31.9675,
    '755_386': 32.0249,
    '755_387': 32.0743,
    '755_388': 32.1133,
    '755_389': 32.1465,
    '755_390': 32.1831,
    '755_391': 32.2148,
    '755_392': 32.2306,
    '755_393': 32.2269,
    '755_394': 32.2203,
    '755_395': 32.2157,
    '755_396': 32.2157,
    '755_397': 32.2182,
    '755_398': 32.2332,
    '755_399': 32.2583,
    '755_400': 32.288,
    '755_401': 32.3183,
    '755_402': 32.3459,
    '755_403': 32.3704,
    '755_404': 32.3961,
    '755_405': 32.436,
    '755_406': 32.4682,
    '755_407': 32.491,
    '755_408': 32.5045,
    '755_409': 32.5105,
    '755_410': 32.5084,
    '755_411': 32.5095,
    '755_412': 32.5112,
    '755_413': 32.5224,
    '755_414': 32.5428,
    '755_415': 32.578,
    '755_416': 32.6181,
    '755_417': 32.655,
    '755_418': 32.6798,
    '755_419': 32.6992,
    '755_420': 32.7133,
    '755_421': 32.7287,
    '755_422': 32.7456,
    '755_423': 32.7619,
    '755_424': 32.7787,
    '755_425': 32.7982,
    '755_426': 32.8238,
    '755_427': 32.8572,
    '755_428': 32.9036,
    '755_429': 32.9644,
    '755_430': 33.0304,
    '755_431': 33.1107,
    '755_432': 33.1807,
    '755_433': 33.2596,
    '755_434': 33.3414,
    '755_435': 33.4155,
    '755_436': 33.4741,
    '755_437': 33.5212,
    '755_438': 33.5541,
    '755_439': 33.5714,
    '755_440': 33.5719,
    '755_441': 33.5562,
    '755_442': 33.513,
    '755_443': 33.4567,
    '755_444': 33.3784,
    '755_445': 33.2881,
    '755_446': 33.1724,
    '755_447': 33.0537,
    '755_448': 32.9356,
    '755_449': 32.7987,
    '755_450': 32.639,
    '755_451': 32.4745,
    '755_452': 32.3276,
    '755_453': 32.1829,
    '755_454': 32.0451,
    '755_455': 31.8982,
    '755_456': 31.7392,
    '755_457': 31.5868,
    '755_458': 31.421,
    '755_459': 31.2552,
    '755_460': 31.0799,
    '755_461': 30.9277,
    '755_462': 30.7957,
    '755_463': 30.6643,
    '755_464': 30.5402,
    '755_465': 30.4179,
    '755_466': 30.2992,
    '755_467': 30.1859,
    '755_468': 30.083,
    '755_469': 29.9907,
    '755_470': 29.8983,
    '755_471': 29.8097,
    '755_472': 29.7306,
    '755_473': 29.6607,
    '755_474': 29.6003,
    '755_475': 29.5505,
    '755_476': 29.5086,
    '755_477': 29.4746,
    '755_478': 29.4429,
    '755_479': 29.4087,
    '755_480': 29.3856,
    '755_481': 29.3758,
    '755_482': 29.3814,
    '755_483': 29.4016,
    '755_484': 29.427,
    '755_485': 29.4613,
    '755_486': 29.5058,
    '755_487': 29.5629,
    '755_488': 29.6299,
    '755_489': 29.704,
    '755_490': 29.785,
    '755_491': 29.8784,
    '755_492': 29.9803,
    '755_493': 30.0901,
    '755_494': 30.2076,
    '755_495': 30.3339,
    '755_496': 30.4662,
    '755_497': 30.6033,
    '755_498': 30.7497,
    '755_499': 30.9014,
    '755_500': 31.0448,
    '755_501': 31.1937,
    '755_502': 31.3525,
    '755_503': 31.5246,
    '755_504': 31.6942,
    '755_505': 31.8655,
    '755_506': 32.0411,
    '755_507': 32.2157,
    '755_508': 32.3887,
    '755_509': 32.5607,
    '755_510': 32.7265,
    '755_511': 32.88,
    '755_512': 33.0218,
    '755_513': 33.1511,
    '755_514': 33.2623,
    '755_515': 33.3584,
    '755_516': 33.4428,
    '755_517': 33.5167,
    '755_518': 33.5833,
    '755_519': 33.6382,
    '755_520': 33.6878,
    '755_521': 33.7353,
    '755_522': 33.7641,
    '755_523': 33.7801,
    '755_524': 33.789,
    '755_525': 33.7992,
    '755_526': 33.8118,
    '755_527': 33.8289,
    '755_528': 33.8501,
    '755_529': 33.8701,
    '755_530': 33.8963,
    '755_531': 33.9266,
    '755_532': 33.9496,
    '755_533': 33.9722,
    '755_534': 33.9996,
    '755_535': 34.037,
    '755_536': 34.0816,
    '755_537': 34.1232,
    '755_538': 34.1623,
    '755_539': 34.2067,
    '755_540': 34.2554,
    '755_541': 34.3144,
    '755_542': 34.378,
    '755_543': 34.4365,
    '755_544': 34.4928,
    '756_341': 30.414,
    '756_342': 30.4824,
    '756_343': 30.5473,
    '756_344': 30.6092,
    '756_345': 30.6669,
    '756_346': 30.7175,
    '756_347': 30.7625,
    '756_348': 30.8061,
    '756_349': 30.841,
    '756_350': 30.8686,
    '756_351': 30.8929,
    '756_352': 30.9154,
    '756_353': 30.9423,
    '756_354': 30.9736,
    '756_355': 31.0087,
    '756_356': 31.0466,
    '756_357': 31.085,
    '756_358': 31.1219,
    '756_359': 31.1601,
    '756_360': 31.1979,
    '756_373': 31.3724,
    '756_374': 31.427,
    '756_375': 31.4818,
    '756_376': 31.5361,
    '756_377': 31.5848,
    '756_378': 31.6141,
    '756_379': 31.6542,
    '756_380': 31.7002,
    '756_381': 31.75,
    '756_382': 31.8027,
    '756_383': 31.8577,
    '756_384': 31.9145,
    '756_385': 31.9676,
    '756_386': 32.026,
    '756_387': 32.0801,
    '756_388': 32.1229,
    '756_389': 32.1545,
    '756_390': 32.1937,
    '756_391': 32.2348,
    '756_392': 32.2688,
    '756_393': 32.2696,
    '756_394': 32.2607,
    '756_395': 32.2491,
    '756_396': 32.2451,
    '756_397': 32.2404,
    '756_398': 32.245,
    '756_399': 32.2607,
    '756_400': 32.2822,
    '756_401': 32.3056,
    '756_402': 32.3281,
    '756_403': 32.3499,
    '756_404': 32.3757,
    '756_405': 32.4112,
    '756_406': 32.4468,
    '756_407': 32.4751,
    '756_408': 32.4883,
    '756_409': 32.4911,
    '756_410': 32.4836,
    '756_411': 32.4771,
    '756_412': 32.4737,
    '756_413': 32.4821,
    '756_414': 32.4997,
    '756_415': 32.5283,
    '756_416': 32.5669,
    '756_417': 32.6102,
    '756_418': 32.643,
    '756_419': 32.6696,
    '756_420': 32.691,
    '756_421': 32.7098,
    '756_422': 32.7316,
    '756_423': 32.7507,
    '756_424': 32.7688,
    '756_425': 32.788,
    '756_426': 32.8098,
    '756_427': 32.8374,
    '756_428': 32.8759,
    '756_429': 32.9355,
    '756_430': 32.9954,
    '756_431': 33.0679,
    '756_432': 33.1279,
    '756_433': 33.1916,
    '756_434': 33.2666,
    '756_435': 33.3345,
    '756_436': 33.3954,
    '756_437': 33.4503,
    '756_438': 33.4959,
    '756_439': 33.5141,
    '756_440': 33.5202,
    '756_441': 33.5111,
    '756_442': 33.487,
    '756_443': 33.4406,
    '756_444': 33.3736,
    '756_445': 33.2786,
    '756_446': 33.1819,
    '756_447': 33.0707,
    '756_448': 32.9578,
    '756_449': 32.8295,
    '756_450': 32.6833,
    '756_451': 32.531,
    '756_452': 32.3848,
    '756_453': 32.2396,
    '756_454': 32.093,
    '756_455': 31.9347,
    '756_456': 31.7748,
    '756_457': 31.6249,
    '756_458': 31.4678,
    '756_459': 31.3146,
    '756_460': 31.1726,
    '756_461': 31.03,
    '756_462': 30.901,
    '756_463': 30.7672,
    '756_464': 30.6446,
    '756_465': 30.5178,
    '756_466': 30.3905,
    '756_467': 30.2725,
    '756_468': 30.1703,
    '756_469': 30.0843,
    '756_470': 29.9909,
    '756_471': 29.899,
    '756_472': 29.8171,
    '756_473': 29.7463,
    '756_474': 29.6837,
    '756_475': 29.6332,
    '756_476': 29.5906,
    '756_477': 29.5596,
    '756_478': 29.5323,
    '756_479': 29.4957,
    '756_480': 29.4668,
    '756_481': 29.4568,
    '756_482': 29.4628,
    '756_483': 29.4842,
    '756_484': 29.5128,
    '756_485': 29.5477,
    '756_486': 29.5939,
    '756_487': 29.6514,
    '756_488': 29.719,
    '756_489': 29.7943,
    '756_490': 29.8772,
    '756_491': 29.9697,
    '756_492': 30.0715,
    '756_493': 30.1831,
    '756_494': 30.3061,
    '756_495': 30.437,
    '756_496': 30.5741,
    '756_497': 30.7139,
    '756_498': 30.8608,
    '756_499': 31.0133,
    '756_500': 31.1703,
    '756_501': 31.3165,
    '756_502': 31.4735,
    '756_503': 31.6393,
    '756_504': 31.8068,
    '756_505': 31.9744,
    '756_506': 32.1445,
    '756_507': 32.3133,
    '756_508': 32.4805,
    '756_509': 32.6472,
    '756_510': 32.8129,
    '756_511': 32.961,
    '756_512': 33.0971,
    '756_513': 33.2229,
    '756_514': 33.3336,
    '756_515': 33.431,
    '756_516': 33.5168,
    '756_517': 33.5912,
    '756_518': 33.6582,
    '756_519': 33.7096,
    '756_520': 33.7509,
    '756_521': 33.7907,
    '756_522': 33.8193,
    '756_523': 33.837,
    '756_524': 33.8466,
    '756_525': 33.8521,
    '756_526': 33.8564,
    '756_527': 33.8637,
    '756_528': 33.8804,
    '756_529': 33.8981,
    '756_530': 33.9189,
    '756_531': 33.9445,
    '756_532': 33.9689,
    '756_533': 33.9957,
    '756_534': 34.0257,
    '756_535': 34.062,
    '756_536': 34.1086,
    '756_537': 34.1487,
    '756_538': 34.186,
    '756_539': 34.2254,
    '756_540': 34.2725,
    '756_541': 34.3259,
    '756_542': 34.3818,
    '756_543': 34.4356,
    '756_544': 34.487,
    '757_341': 30.42,
    '757_342': 30.4868,
    '757_343': 30.5517,
    '757_344': 30.6164,
    '757_345': 30.675,
    '757_346': 30.7298,
    '757_347': 30.7855,
    '757_348': 30.8387,
    '757_349': 30.8785,
    '757_350': 30.899,
    '757_351': 30.9168,
    '757_352': 30.9417,
    '757_353': 30.9713,
    '757_354': 31.0034,
    '757_355': 31.0368,
    '757_356': 31.0731,
    '757_357': 31.1087,
    '757_358': 31.1434,
    '757_359': 31.18,
    '757_360': 31.2169,
    '757_373': 31.3968,
    '757_374': 31.4458,
    '757_375': 31.4957,
    '757_376': 31.5463,
    '757_377': 31.5911,
    '757_378': 31.6244,
    '757_379': 31.6628,
    '757_380': 31.707,
    '757_381': 31.7546,
    '757_382': 31.8039,
    '757_383': 31.8544,
    '757_384': 31.9061,
    '757_385': 31.9594,
    '757_386': 32.0171,
    '757_387': 32.0718,
    '757_388': 32.1181,
    '757_389': 32.1541,
    '757_390': 32.1954,
    '757_391': 32.2438,
    '757_392': 32.2935,
    '757_393': 32.3143,
    '757_394': 32.3102,
    '757_395': 32.2971,
    '757_396': 32.2803,
    '757_397': 32.2656,
    '757_398': 32.2596,
    '757_399': 32.2661,
    '757_400': 32.2816,
    '757_401': 32.3004,
    '757_402': 32.3197,
    '757_403': 32.3397,
    '757_404': 32.3631,
    '757_405': 32.3975,
    '757_406': 32.4341,
    '757_407': 32.4643,
    '757_408': 32.4801,
    '757_409': 32.4804,
    '757_410': 32.4601,
    '757_411': 32.4453,
    '757_412': 32.4365,
    '757_413': 32.4402,
    '757_414': 32.4524,
    '757_415': 32.474,
    '757_416': 32.5081,
    '757_417': 32.5529,
    '757_418': 32.6014,
    '757_419': 32.6418,
    '757_420': 32.6664,
    '757_421': 32.6903,
    '757_422': 32.7159,
    '757_423': 32.741,
    '757_424': 32.7625,
    '757_425': 32.7814,
    '757_426': 32.799,
    '757_427': 32.8227,
    '757_428': 32.8573,
    '757_429': 32.9093,
    '757_430': 32.9608,
    '757_431': 33.0214,
    '757_432': 33.0705,
    '757_433': 33.1201,
    '757_434': 33.1823,
    '757_435': 33.2401,
    '757_436': 33.3004,
    '757_437': 33.3609,
    '757_438': 33.4124,
    '757_439': 33.4416,
    '757_440': 33.4513,
    '757_441': 33.4597,
    '757_442': 33.4463,
    '757_443': 33.4047,
    '757_444': 33.345,
    '757_445': 33.2681,
    '757_446': 33.1729,
    '757_447': 33.0763,
    '757_448': 32.9704,
    '757_449': 32.8517,
    '757_450': 32.7226,
    '757_451': 32.5836,
    '757_452': 32.4356,
    '757_453': 32.2846,
    '757_454': 32.1302,
    '757_455': 31.9656,
    '757_456': 31.8091,
    '757_457': 31.6628,
    '757_458': 31.5283,
    '757_459': 31.4069,
    '757_460': 31.2813,
    '757_461': 31.1392,
    '757_462': 31.0089,
    '757_463': 30.8878,
    '757_464': 30.753,
    '757_465': 30.6131,
    '757_466': 30.4924,
    '757_467': 30.3691,
    '757_468': 30.2613,
    '757_469': 30.1752,
    '757_470': 30.0832,
    '757_471': 29.987,
    '757_472': 29.9033,
    '757_473': 29.8318,
    '757_474': 29.7699,
    '757_475': 29.7186,
    '757_476': 29.675,
    '757_477': 29.6424,
    '757_478': 29.6145,
    '757_479': 29.5822,
    '757_480': 29.5523,
    '757_481': 29.5426,
    '757_482': 29.55,
    '757_483': 29.5705,
    '757_484': 29.6005,
    '757_485': 29.6334,
    '757_486': 29.681,
    '757_487': 29.7393,
    '757_488': 29.8075,
    '757_489': 29.8848,
    '757_490': 29.9693,
    '757_491': 30.0615,
    '757_492': 30.1653,
    '757_493': 30.2826,
    '757_494': 30.4074,
    '757_495': 30.54,
    '757_496': 30.6793,
    '757_497': 30.8224,
    '757_498': 30.9716,
    '757_499': 31.1283,
    '757_500': 31.2875,
    '757_501': 31.44,
    '757_502': 31.5888,
    '757_503': 31.7496,
    '757_504': 31.9153,
    '757_505': 32.0811,
    '757_506': 32.2453,
    '757_507': 32.4093,
    '757_508': 32.5736,
    '757_509': 32.7354,
    '757_510': 32.8923,
    '757_511': 33.036,
    '757_512': 33.168,
    '757_513': 33.2901,
    '757_514': 33.4022,
    '757_515': 33.5008,
    '757_516': 33.5872,
    '757_517': 33.657,
    '757_518': 33.7181,
    '757_519': 33.7696,
    '757_520': 33.8193,
    '757_521': 33.8521,
    '757_522': 33.8786,
    '757_523': 33.8964,
    '757_524': 33.9079,
    '757_525': 33.9076,
    '757_526': 33.9063,
    '757_527': 33.9096,
    '757_528': 33.9203,
    '757_529': 33.9332,
    '757_530': 33.9485,
    '757_531': 33.9679,
    '757_532': 33.9916,
    '757_533': 34.0232,
    '757_534': 34.0558,
    '757_535': 34.091,
    '757_536': 34.1312,
    '757_537': 34.1729,
    '757_538': 34.2131,
    '757_539': 34.2503,
    '757_540': 34.2928,
    '757_541': 34.3388,
    '757_542': 34.386,
    '757_543': 34.4366,
    '757_544': 34.4863,
    '758_341': 30.4211,
    '758_342': 30.4872,
    '758_343': 30.5515,
    '758_344': 30.6155,
    '758_345': 30.677,
    '758_346': 30.7358,
    '758_347': 30.7942,
    '758_348': 30.8545,
    '758_349': 30.9015,
    '758_350': 30.9262,
    '758_351': 30.9405,
    '758_352': 30.9656,
    '758_353': 30.9973,
    '758_354': 31.0306,
    '758_355': 31.0642,
    '758_356': 31.0968,
    '758_357': 31.1284,
    '758_358': 31.1622,
    '758_359': 31.1973,
    '758_360': 31.2327,
    '758_373': 31.4206,
    '758_374': 31.4632,
    '758_375': 31.5066,
    '758_376': 31.5547,
    '758_377': 31.5997,
    '758_378': 31.6286,
    '758_379': 31.6678,
    '758_380': 31.7111,
    '758_381': 31.7562,
    '758_382': 31.8016,
    '758_383': 31.8468,
    '758_384': 31.8928,
    '758_385': 31.9437,
    '758_386': 31.9988,
    '758_387': 32.0521,
    '758_388': 32.0997,
    '758_389': 32.1452,
    '758_390': 32.1932,
    '758_391': 32.2474,
    '758_392': 32.3067,
    '758_393': 32.3468,
    '758_394': 32.355,
    '758_395': 32.3468,
    '758_396': 32.3185,
    '758_397': 32.2926,
    '758_398': 32.2771,
    '758_399': 32.2739,
    '758_400': 32.284,
    '758_401': 32.2992,
    '758_402': 32.3155,
    '758_403': 32.3343,
    '758_404': 32.3575,
    '758_405': 32.3898,
    '758_406': 32.4248,
    '758_407': 32.4567,
    '758_408': 32.4756,
    '758_409': 32.4745,
    '758_410': 32.4463,
    '758_411': 32.4252,
    '758_412': 32.4131,
    '758_413': 32.408,
    '758_414': 32.4115,
    '758_415': 32.4225,
    '758_416': 32.4497,
    '758_417': 32.4887,
    '758_418': 32.5441,
    '758_419': 32.5971,
    '758_420': 32.6387,
    '758_421': 32.6714,
    '758_422': 32.7043,
    '758_423': 32.7364,
    '758_424': 32.7606,
    '758_425': 32.776,
    '758_426': 32.7864,
    '758_427': 32.8056,
    '758_428': 32.8374,
    '758_429': 32.8798,
    '758_430': 32.9196,
    '758_431': 32.9708,
    '758_432': 33.0156,
    '758_433': 33.0647,
    '758_434': 33.1221,
    '758_435': 33.1713,
    '758_436': 33.2234,
    '758_437': 33.2773,
    '758_438': 33.3253,
    '758_439': 33.3573,
    '758_440': 33.3759,
    '758_441': 33.394,
    '758_442': 33.3873,
    '758_443': 33.3547,
    '758_444': 33.309,
    '758_445': 33.245,
    '758_446': 33.1561,
    '758_447': 33.0641,
    '758_448': 32.9692,
    '758_449': 32.8595,
    '758_450': 32.7474,
    '758_451': 32.6172,
    '758_452': 32.4707,
    '758_453': 32.3162,
    '758_454': 32.1615,
    '758_455': 31.9997,
    '758_456': 31.8511,
    '758_457': 31.7251,
    '758_458': 31.6126,
    '758_459': 31.5142,
    '758_460': 31.3939,
    '758_461': 31.2552,
    '758_462': 31.1264,
    '758_463': 31.0103,
    '758_464': 30.8684,
    '758_465': 30.7158,
    '758_466': 30.5941,
    '758_467': 30.4688,
    '758_468': 30.3527,
    '758_469': 30.2633,
    '758_470': 30.1669,
    '758_471': 30.0728,
    '758_472': 29.9882,
    '758_473': 29.9162,
    '758_474': 29.8535,
    '758_475': 29.8003,
    '758_476': 29.7562,
    '758_477': 29.7243,
    '758_478': 29.6989,
    '758_479': 29.6717,
    '758_480': 29.6434,
    '758_481': 29.6334,
    '758_482': 29.6388,
    '758_483': 29.6567,
    '758_484': 29.686,
    '758_485': 29.7214,
    '758_486': 29.7685,
    '758_487': 29.8259,
    '758_488': 29.894,
    '758_489': 29.9736,
    '758_490': 30.0595,
    '758_491': 30.1513,
    '758_492': 30.2554,
    '758_493': 30.376,
    '758_494': 30.5051,
    '758_495': 30.6407,
    '758_496': 30.7823,
    '758_497': 30.928,
    '758_498': 31.0784,
    '758_499': 31.2378,
    '758_500': 31.3956,
    '758_501': 31.5475,
    '758_502': 31.6989,
    '758_503': 31.8524,
    '758_504': 32.0118,
    '758_505': 32.174,
    '758_506': 32.3391,
    '758_507': 32.5019,
    '758_508': 32.6632,
    '758_509': 32.8182,
    '758_510': 32.971,
    '758_511': 33.1129,
    '758_512': 33.2461,
    '758_513': 33.3646,
    '758_514': 33.4726,
    '758_515': 33.5667,
    '758_516': 33.6508,
    '758_517': 33.7191,
    '758_518': 33.7787,
    '758_519': 33.8325,
    '758_520': 33.88,
    '758_521': 33.9166,
    '758_522': 33.9398,
    '758_523': 33.951,
    '758_524': 33.958,
    '758_525': 33.9571,
    '758_526': 33.9539,
    '758_527': 33.9555,
    '758_528': 33.9633,
    '758_529': 33.9728,
    '758_530': 33.985,
    '758_531': 33.998,
    '758_532': 34.0187,
    '758_533': 34.0541,
    '758_534': 34.0883,
    '758_535': 34.1214,
    '758_536': 34.1559,
    '758_537': 34.1982,
    '758_538': 34.2391,
    '758_539': 34.2766,
    '758_540': 34.3163,
    '758_541': 34.3571,
    '758_542': 34.3994,
    '758_543': 34.4461,
    '758_544': 34.4947,
    '759_341': 30.4181,
    '759_342': 30.4839,
    '759_343': 30.5478,
    '759_344': 30.6116,
    '759_345': 30.6768,
    '759_346': 30.7329,
    '759_347': 30.7962,
    '759_348': 30.8601,
    '759_349': 30.9084,
    '759_350': 30.9434,
    '759_351': 30.9692,
    '759_352': 30.9886,
    '759_353': 31.0217,
    '759_354': 31.0582,
    '759_355': 31.0871,
    '759_356': 31.1156,
    '759_357': 31.1447,
    '759_358': 31.1775,
    '759_359': 31.2117,
    '759_360': 31.2461,
    '759_373': 31.4445,
    '759_374': 31.4812,
    '759_375': 31.5179,
    '759_376': 31.5596,
    '759_377': 31.6015,
    '759_378': 31.6353,
    '759_379': 31.674,
    '759_380': 31.7154,
    '759_381': 31.7577,
    '759_382': 31.7996,
    '759_383': 31.8411,
    '759_384': 31.8832,
    '759_385': 31.9286,
    '759_386': 31.9811,
    '759_387': 32.0342,
    '759_388': 32.0843,
    '759_389': 32.1344,
    '759_390': 32.1864,
    '759_391': 32.2434,
    '759_392': 32.3086,
    '759_393': 32.3665,
    '759_394': 32.4059,
    '759_395': 32.4019,
    '759_396': 32.3646,
    '759_397': 32.3233,
    '759_398': 32.2973,
    '759_399': 32.2842,
    '759_400': 32.2888,
    '759_401': 32.3008,
    '759_402': 32.3135,
    '759_403': 32.3292,
    '759_404': 32.3515,
    '759_405': 32.3837,
    '759_406': 32.4238,
    '759_407': 32.4544,
    '759_408': 32.4671,
    '759_409': 32.4642,
    '759_410': 32.4402,
    '759_411': 32.4205,
    '759_412': 32.4054,
    '759_413': 32.3936,
    '759_414': 32.3877,
    '759_415': 32.3901,
    '759_416': 32.4064,
    '759_417': 32.4359,
    '759_418': 32.481,
    '759_419': 32.5324,
    '759_420': 32.5904,
    '759_421': 32.6419,
    '759_422': 32.6875,
    '759_423': 32.7263,
    '759_424': 32.7515,
    '759_425': 32.7635,
    '759_426': 32.7666,
    '759_427': 32.7824,
    '759_428': 32.8107,
    '759_429': 32.8456,
    '759_430': 32.8816,
    '759_431': 32.9269,
    '759_432': 32.9712,
    '759_433': 33.0291,
    '759_434': 33.0886,
    '759_435': 33.1458,
    '759_436': 33.1883,
    '759_437': 33.2359,
    '759_438': 33.2778,
    '759_439': 33.2992,
    '759_440': 33.3116,
    '759_441': 33.3222,
    '759_442': 33.3205,
    '759_443': 33.2964,
    '759_444': 33.2663,
    '759_445': 33.2153,
    '759_446': 33.1369,
    '759_447': 33.0518,
    '759_448': 32.9611,
    '759_449': 32.8586,
    '759_450': 32.759,
    '759_451': 32.6377,
    '759_452': 32.4923,
    '759_453': 32.3354,
    '759_454': 32.1874,
    '759_455': 32.034,
    '759_456': 31.9104,
    '759_457': 31.8105,
    '759_458': 31.7141,
    '759_459': 31.626,
    '759_460': 31.5118,
    '759_461': 31.3755,
    '759_462': 31.2428,
    '759_463': 31.1154,
    '759_464': 30.9688,
    '759_465': 30.8185,
    '759_466': 30.7014,
    '759_467': 30.5693,
    '759_468': 30.438,
    '759_469': 30.3425,
    '759_470': 30.2517,
    '759_471': 30.1578,
    '759_472': 30.075,
    '759_473': 30.0028,
    '759_474': 29.9365,
    '759_475': 29.8814,
    '759_476': 29.8364,
    '759_477': 29.8036,
    '759_478': 29.7802,
    '759_479': 29.7528,
    '759_480': 29.7292,
    '759_481': 29.7216,
    '759_482': 29.7268,
    '759_483': 29.744,
    '759_484': 29.7725,
    '759_485': 29.8084,
    '759_486': 29.8551,
    '759_487': 29.9117,
    '759_488': 29.9784,
    '759_489': 30.0592,
    '759_490': 30.1458,
    '759_491': 30.2408,
    '759_492': 30.3494,
    '759_493': 30.4705,
    '759_494': 30.6017,
    '759_495': 30.7398,
    '759_496': 30.8841,
    '759_497': 31.0331,
    '759_498': 31.186,
    '759_499': 31.343,
    '759_500': 31.4949,
    '759_501': 31.6503,
    '759_502': 31.8046,
    '759_503': 31.9533,
    '759_504': 32.1143,
    '759_505': 32.2727,
    '759_506': 32.4328,
    '759_507': 32.592,
    '759_508': 32.7491,
    '759_509': 32.9041,
    '759_510': 33.05,
    '759_511': 33.1899,
    '759_512': 33.3158,
    '759_513': 33.4336,
    '759_514': 33.5397,
    '759_515': 33.6315,
    '759_516': 33.7151,
    '759_517': 33.7893,
    '759_518': 33.8499,
    '759_519': 33.9013,
    '759_520': 33.9467,
    '759_521': 33.9811,
    '759_522': 34.0035,
    '759_523': 34.0061,
    '759_524': 34.0107,
    '759_525': 34.0066,
    '759_526': 34.0001,
    '759_527': 34,
    '759_528': 34.0044,
    '759_529': 34.0121,
    '759_530': 34.0232,
    '759_531': 34.0329,
    '759_532': 34.0444,
    '759_533': 34.0807,
    '759_534': 34.1204,
    '759_535': 34.1559,
    '759_536': 34.1907,
    '759_537': 34.231,
    '759_538': 34.269,
    '759_539': 34.3032,
    '759_540': 34.3444,
    '759_541': 34.3788,
    '759_542': 34.4136,
    '759_543': 34.4579,
    '759_544': 34.5049,
    '760_341': 30.4139,
    '760_342': 30.4794,
    '760_343': 30.5429,
    '760_344': 30.606,
    '760_345': 30.6708,
    '760_346': 30.7294,
    '760_347': 30.7959,
    '760_348': 30.8572,
    '760_349': 30.9058,
    '760_350': 30.9521,
    '760_351': 30.9903,
    '760_352': 31.0109,
    '760_353': 31.0394,
    '760_354': 31.0723,
    '760_355': 31.0991,
    '760_356': 31.1256,
    '760_357': 31.1565,
    '760_373': 31.4683,
    '760_374': 31.5023,
    '760_375': 31.5353,
    '760_376': 31.5683,
    '760_377': 31.6086,
    '760_378': 31.6438,
    '760_379': 31.682,
    '760_380': 31.7211,
    '760_381': 31.7606,
    '760_382': 31.7999,
    '760_383': 31.8391,
    '760_384': 31.8792,
    '760_385': 31.9228,
    '760_386': 31.9707,
    '760_387': 32.0234,
    '760_388': 32.0741,
    '760_389': 32.1264,
    '760_390': 32.1815,
    '760_391': 32.2419,
    '760_392': 32.309,
    '760_393': 32.3796,
    '760_394': 32.4365,
    '760_395': 32.4562,
    '760_396': 32.4165,
    '760_397': 32.363,
    '760_398': 32.3248,
    '760_399': 32.3028,
    '760_400': 32.2985,
    '760_401': 32.3039,
    '760_402': 32.313,
    '760_403': 32.3257,
    '760_404': 32.345,
    '760_405': 32.3749,
    '760_406': 32.4145,
    '760_407': 32.4485,
    '760_408': 32.466,
    '760_409': 32.4677,
    '760_410': 32.456,
    '760_411': 32.4398,
    '760_412': 32.4205,
    '760_413': 32.3997,
    '760_414': 32.3816,
    '760_415': 32.3754,
    '760_416': 32.3809,
    '760_417': 32.3983,
    '760_418': 32.4319,
    '760_419': 32.4782,
    '760_420': 32.531,
    '760_421': 32.5913,
    '760_422': 32.6431,
    '760_423': 32.6883,
    '760_424': 32.719,
    '760_425': 32.7347,
    '760_426': 32.7359,
    '760_427': 32.7534,
    '760_428': 32.7815,
    '760_429': 32.8119,
    '760_430': 32.8441,
    '760_431': 32.8854,
    '760_432': 32.9327,
    '760_433': 32.9971,
    '760_434': 33.0735,
    '760_435': 33.1421,
    '760_436': 33.1815,
    '760_437': 33.2233,
    '760_438': 33.26,
    '760_439': 33.275,
    '760_440': 33.2769,
    '760_441': 33.2751,
    '760_442': 33.2649,
    '760_443': 33.242,
    '760_444': 33.217,
    '760_445': 33.1778,
    '760_446': 33.1123,
    '760_447': 33.0368,
    '760_448': 32.9512,
    '760_449': 32.8583,
    '760_450': 32.7602,
    '760_451': 32.6421,
    '760_452': 32.4995,
    '760_453': 32.3466,
    '760_454': 32.2105,
    '760_455': 32.0783,
    '760_456': 31.9739,
    '760_457': 31.8933,
    '760_458': 31.8106,
    '760_459': 31.7348,
    '760_460': 31.6344,
    '760_461': 31.5061,
    '760_462': 31.3615,
    '760_463': 31.2147,
    '760_464': 31.0551,
    '760_465': 30.9086,
    '760_466': 30.7925,
    '760_467': 30.6574,
    '760_468': 30.5225,
    '760_469': 30.4256,
    '760_470': 30.3443,
    '760_471': 30.2498,
    '760_472': 30.1647,
    '760_473': 30.0908,
    '760_474': 30.0236,
    '760_475': 29.9638,
    '760_476': 29.9158,
    '760_477': 29.879,
    '760_478': 29.8548,
    '760_479': 29.8302,
    '760_480': 29.8105,
    '760_481': 29.8056,
    '760_482': 29.8129,
    '760_483': 29.8301,
    '760_484': 29.8574,
    '760_485': 29.8944,
    '760_486': 29.9429,
    '760_487': 30.0005,
    '760_488': 30.0716,
    '760_489': 30.1514,
    '760_490': 30.2378,
    '760_491': 30.335,
    '760_492': 30.4451,
    '760_493': 30.5668,
    '760_494': 30.6988,
    '760_495': 30.838,
    '760_496': 30.9839,
    '760_497': 31.1352,
    '760_498': 31.2865,
    '760_499': 31.4373,
    '760_500': 31.5936,
    '760_501': 31.7503,
    '760_502': 31.9018,
    '760_503': 32.0549,
    '760_504': 32.211,
    '760_505': 32.3654,
    '760_506': 32.5195,
    '760_507': 32.6725,
    '760_508': 32.8257,
    '760_509': 32.9829,
    '760_510': 33.1325,
    '760_511': 33.2626,
    '760_512': 33.3855,
    '760_513': 33.5006,
    '760_514': 33.6032,
    '760_515': 33.6922,
    '760_516': 33.7805,
    '760_517': 33.8586,
    '760_518': 33.9214,
    '760_519': 33.9714,
    '760_520': 34.0192,
    '760_521': 34.0489,
    '760_522': 34.073,
    '760_523': 34.074,
    '760_524': 34.0707,
    '760_525': 34.062,
    '760_526': 34.0512,
    '760_527': 34.0526,
    '760_528': 34.0495,
    '760_529': 34.0531,
    '760_530': 34.0643,
    '760_531': 34.0801,
    '760_532': 34.0915,
    '760_533': 34.1179,
    '760_534': 34.1507,
    '760_535': 34.1885,
    '760_536': 34.2268,
    '760_537': 34.269,
    '760_538': 34.3066,
    '760_539': 34.3388,
    '760_540': 34.3739,
    '760_541': 34.4038,
    '760_542': 34.4357,
    '760_543': 34.4766,
    '760_544': 34.5197,
    '761_341': 30.4067,
    '761_342': 30.472,
    '761_343': 30.5354,
    '761_344': 30.5979,
    '761_345': 30.6609,
    '761_346': 30.7271,
    '761_347': 30.8017,
    '761_348': 30.8594,
    '761_349': 30.904,
    '761_350': 30.9578,
    '761_351': 30.9954,
    '761_352': 31.0216,
    '761_353': 31.0468,
    '761_354': 31.0756,
    '761_355': 31.1028,
    '761_356': 31.1333,
    '761_357': 31.1655,
    '761_358': 31.2062,
    '761_359': 31.2382,
    '761_360': 31.2699,
    '761_361': 31.3006,
    '761_362': 31.3301,
    '761_363': 31.3584,
    '761_364': 31.3857,
    '761_365': 31.4123,
    '761_366': 31.4397,
    '761_367': 31.4694,
    '761_368': 31.5029,
    '761_369': 31.5412,
    '761_370': 31.5837,
    '761_373': 31.4848,
    '761_374': 31.5195,
    '761_375': 31.5528,
    '761_376': 31.5813,
    '761_377': 31.6182,
    '761_378': 31.653,
    '761_379': 31.689,
    '761_380': 31.7252,
    '761_381': 31.7619,
    '761_382': 31.7994,
    '761_383': 31.8367,
    '761_384': 31.8759,
    '761_385': 31.9208,
    '761_386': 31.9688,
    '761_387': 32.0182,
    '761_388': 32.0678,
    '761_389': 32.1216,
    '761_390': 32.1786,
    '761_391': 32.24,
    '761_392': 32.3061,
    '761_393': 32.3742,
    '761_394': 32.4385,
    '761_395': 32.4798,
    '761_396': 32.4515,
    '761_397': 32.3905,
    '761_398': 32.3455,
    '761_399': 32.3167,
    '761_400': 32.3073,
    '761_401': 32.3076,
    '761_402': 32.312,
    '761_403': 32.3204,
    '761_404': 32.3349,
    '761_405': 32.3594,
    '761_406': 32.3929,
    '761_407': 32.4305,
    '761_408': 32.4642,
    '761_409': 32.4851,
    '761_410': 32.4917,
    '761_411': 32.4805,
    '761_412': 32.4553,
    '761_413': 32.4221,
    '761_414': 32.3877,
    '761_415': 32.3664,
    '761_416': 32.3634,
    '761_417': 32.3765,
    '761_418': 32.4013,
    '761_419': 32.4407,
    '761_420': 32.4857,
    '761_421': 32.5376,
    '761_422': 32.5904,
    '761_423': 32.6398,
    '761_424': 32.6681,
    '761_425': 32.6861,
    '761_426': 32.6944,
    '761_427': 32.7177,
    '761_428': 32.7513,
    '761_429': 32.7762,
    '761_430': 32.8033,
    '761_431': 32.8433,
    '761_432': 32.8943,
    '761_433': 32.9634,
    '761_434': 33.0503,
    '761_435': 33.1238,
    '761_436': 33.1818,
    '761_437': 33.2256,
    '761_438': 33.2536,
    '761_439': 33.2666,
    '761_440': 33.2653,
    '761_441': 33.2562,
    '761_442': 33.2374,
    '761_443': 33.2089,
    '761_444': 33.1753,
    '761_445': 33.1346,
    '761_446': 33.0796,
    '761_447': 33.0153,
    '761_448': 32.9409,
    '761_449': 32.859,
    '761_450': 32.7576,
    '761_451': 32.6357,
    '761_452': 32.5026,
    '761_453': 32.3656,
    '761_454': 32.2459,
    '761_455': 32.1375,
    '761_456': 32.0479,
    '761_457': 31.9796,
    '761_458': 31.9089,
    '761_459': 31.8301,
    '761_460': 31.7366,
    '761_461': 31.6193,
    '761_462': 31.4723,
    '761_463': 31.3124,
    '761_464': 31.1486,
    '761_465': 30.9957,
    '761_466': 30.8704,
    '761_467': 30.7345,
    '761_468': 30.6084,
    '761_469': 30.5187,
    '761_470': 30.4429,
    '761_471': 30.3514,
    '761_472': 30.2594,
    '761_473': 30.1786,
    '761_474': 30.1102,
    '761_475': 30.0473,
    '761_476': 29.9967,
    '761_477': 29.9585,
    '761_478': 29.9331,
    '761_479': 29.9093,
    '761_480': 29.893,
    '761_481': 29.8891,
    '761_482': 29.898,
    '761_483': 29.915,
    '761_484': 29.9409,
    '761_485': 29.9786,
    '761_486': 30.0305,
    '761_487': 30.0934,
    '761_488': 30.1626,
    '761_489': 30.2395,
    '761_490': 30.3261,
    '761_491': 30.4244,
    '761_492': 30.5351,
    '761_493': 30.6579,
    '761_494': 30.791,
    '761_495': 30.9308,
    '761_496': 31.0776,
    '761_497': 31.2318,
    '761_498': 31.3802,
    '761_499': 31.5324,
    '761_500': 31.691,
    '761_501': 31.8515,
    '761_502': 32.0033,
    '761_503': 32.1543,
    '761_504': 32.3041,
    '761_505': 32.4535,
    '761_506': 32.6029,
    '761_507': 32.7522,
    '761_508': 32.9058,
    '761_509': 33.0613,
    '761_510': 33.2081,
    '761_511': 33.3373,
    '761_512': 33.4546,
    '761_513': 33.5648,
    '761_514': 33.6659,
    '761_515': 33.762,
    '761_516': 33.8495,
    '761_517': 33.9264,
    '761_518': 33.9861,
    '761_519': 34.0337,
    '761_520': 34.0847,
    '761_521': 34.1282,
    '761_522': 34.157,
    '761_523': 34.1501,
    '761_524': 34.1354,
    '761_525': 34.1238,
    '761_526': 34.1144,
    '761_527': 34.1053,
    '761_528': 34.0956,
    '761_529': 34.0965,
    '761_530': 34.1072,
    '761_531': 34.1248,
    '761_532': 34.1397,
    '761_533': 34.1631,
    '761_534': 34.193,
    '761_535': 34.2268,
    '761_536': 34.2639,
    '761_537': 34.3016,
    '761_538': 34.3389,
    '761_539': 34.3806,
    '761_540': 34.411,
    '761_541': 34.4362,
    '761_542': 34.4665,
    '761_543': 34.5038,
    '761_544': 34.5411,
    '762_341': 30.3973,
    '762_342': 30.463,
    '762_343': 30.5267,
    '762_344': 30.5892,
    '762_345': 30.6516,
    '762_346': 30.7203,
    '762_347': 30.7999,
    '762_348': 30.862,
    '762_349': 30.9029,
    '762_350': 30.9579,
    '762_351': 30.9977,
    '762_352': 31.0275,
    '762_353': 31.0526,
    '762_354': 31.0772,
    '762_355': 31.1071,
    '762_356': 31.1408,
    '762_357': 31.1734,
    '762_358': 31.2136,
    '762_359': 31.2464,
    '762_360': 31.2786,
    '762_361': 31.3098,
    '762_362': 31.34,
    '762_363': 31.3694,
    '762_364': 31.3982,
    '762_365': 31.4269,
    '762_366': 31.4563,
    '762_367': 31.4875,
    '762_368': 31.5215,
    '762_369': 31.5586,
    '762_370': 31.598,
    '762_373': 31.495,
    '762_374': 31.528,
    '762_375': 31.5591,
    '762_376': 31.5871,
    '762_377': 31.6176,
    '762_378': 31.6544,
    '762_379': 31.6903,
    '762_380': 31.7244,
    '762_381': 31.7592,
    '762_382': 31.7956,
    '762_383': 31.8317,
    '762_384': 31.8692,
    '762_385': 31.9177,
    '762_386': 31.9685,
    '762_387': 32.0143,
    '762_388': 32.0647,
    '762_389': 32.1189,
    '762_390': 32.1772,
    '762_391': 32.2376,
    '762_392': 32.2998,
    '762_393': 32.3639,
    '762_394': 32.4248,
    '762_395': 32.4714,
    '762_396': 32.4611,
    '762_397': 32.405,
    '762_398': 32.3583,
    '762_399': 32.3285,
    '762_400': 32.3162,
    '762_401': 32.3126,
    '762_402': 32.3129,
    '762_403': 32.3168,
    '762_404': 32.3254,
    '762_405': 32.3422,
    '762_406': 32.3687,
    '762_407': 32.4078,
    '762_408': 32.4572,
    '762_409': 32.5052,
    '762_410': 32.5378,
    '762_411': 32.5404,
    '762_412': 32.5093,
    '762_413': 32.4575,
    '762_414': 32.4019,
    '762_415': 32.3648,
    '762_416': 32.3524,
    '762_417': 32.3612,
    '762_418': 32.3787,
    '762_419': 32.4153,
    '762_420': 32.4553,
    '762_421': 32.4988,
    '762_422': 32.5434,
    '762_423': 32.586,
    '762_424': 32.611,
    '762_425': 32.6304,
    '762_426': 32.6474,
    '762_427': 32.6744,
    '762_428': 32.7079,
    '762_429': 32.7343,
    '762_430': 32.7642,
    '762_431': 32.8055,
    '762_432': 32.8606,
    '762_433': 32.931,
    '762_434': 33.0152,
    '762_435': 33.096,
    '762_436': 33.1668,
    '762_437': 33.2232,
    '762_438': 33.2539,
    '762_439': 33.2649,
    '762_440': 33.264,
    '762_441': 33.2518,
    '762_442': 33.2268,
    '762_443': 33.1928,
    '762_444': 33.154,
    '762_445': 33.11,
    '762_446': 33.0554,
    '762_447': 32.996,
    '762_448': 32.9337,
    '762_449': 32.8582,
    '762_450': 32.7549,
    '762_451': 32.6302,
    '762_452': 32.5072,
    '762_453': 32.3952,
    '762_454': 32.2987,
    '762_455': 32.2101,
    '762_456': 32.1288,
    '762_457': 32.0658,
    '762_458': 31.9976,
    '762_459': 31.914,
    '762_460': 31.8258,
    '762_461': 31.7028,
    '762_462': 31.5552,
    '762_463': 31.393,
    '762_464': 31.2303,
    '762_465': 31.0735,
    '762_466': 30.9331,
    '762_467': 30.8003,
    '762_468': 30.6925,
    '762_469': 30.6111,
    '762_470': 30.5459,
    '762_471': 30.4637,
    '762_472': 30.3656,
    '762_473': 30.2722,
    '762_474': 30.2027,
    '762_475': 30.1334,
    '762_476': 30.079,
    '762_477': 30.0371,
    '762_478': 30.009,
    '762_479': 29.9877,
    '762_480': 29.9753,
    '762_481': 29.9748,
    '762_482': 29.9855,
    '762_483': 30.0016,
    '762_484': 30.0259,
    '762_485': 30.0644,
    '762_486': 30.117,
    '762_487': 30.1819,
    '762_488': 30.2511,
    '762_489': 30.3255,
    '762_490': 30.4113,
    '762_491': 30.5098,
    '762_492': 30.6202,
    '762_493': 30.7432,
    '762_494': 30.8756,
    '762_495': 31.0167,
    '762_496': 31.1662,
    '762_497': 31.3216,
    '762_498': 31.4689,
    '762_499': 31.6202,
    '762_500': 31.7786,
    '762_501': 31.9403,
    '762_502': 32.0983,
    '762_503': 32.2463,
    '762_504': 32.392,
    '762_505': 32.5387,
    '762_506': 32.6864,
    '762_507': 32.8349,
    '762_508': 32.9864,
    '762_509': 33.1414,
    '762_510': 33.2864,
    '762_511': 33.4123,
    '762_512': 33.5253,
    '762_513': 33.6339,
    '762_514': 33.7352,
    '762_515': 33.8338,
    '762_516': 33.9182,
    '762_517': 33.9937,
    '762_518': 34.0584,
    '762_519': 34.1147,
    '762_520': 34.1668,
    '762_521': 34.2068,
    '762_522': 34.2275,
    '762_523': 34.2129,
    '762_524': 34.1903,
    '762_525': 34.1752,
    '762_526': 34.1686,
    '762_527': 34.1612,
    '762_528': 34.1556,
    '762_529': 34.1568,
    '762_530': 34.1712,
    '762_531': 34.1899,
    '762_532': 34.2002,
    '762_533': 34.2154,
    '762_534': 34.2388,
    '762_535': 34.2681,
    '762_536': 34.3013,
    '762_537': 34.3368,
    '762_538': 34.3735,
    '762_539': 34.4116,
    '762_540': 34.4484,
    '762_541': 34.4774,
    '762_542': 34.5092,
    '762_543': 34.5369,
    '762_544': 34.5633,
    '763_341': 30.3879,
    '763_342': 30.4537,
    '763_343': 30.5177,
    '763_344': 30.5808,
    '763_345': 30.6416,
    '763_346': 30.7087,
    '763_347': 30.7854,
    '763_348': 30.8531,
    '763_349': 30.8962,
    '763_350': 30.9482,
    '763_351': 30.9956,
    '763_352': 31.0341,
    '763_353': 31.0585,
    '763_354': 31.0803,
    '763_355': 31.112,
    '763_356': 31.1466,
    '763_357': 31.1809,
    '763_358': 31.221,
    '763_359': 31.2551,
    '763_360': 31.288,
    '763_361': 31.3194,
    '763_362': 31.35,
    '763_363': 31.3802,
    '763_364': 31.4101,
    '763_365': 31.4402,
    '763_366': 31.4709,
    '763_367': 31.503,
    '763_368': 31.5369,
    '763_369': 31.5726,
    '763_370': 31.6096,
    '763_373': 31.5024,
    '763_374': 31.5337,
    '763_375': 31.5634,
    '763_376': 31.5921,
    '763_377': 31.6224,
    '763_378': 31.6505,
    '763_379': 31.6824,
    '763_380': 31.7152,
    '763_381': 31.7492,
    '763_382': 31.7845,
    '763_383': 31.8205,
    '763_384': 31.8593,
    '763_385': 31.9072,
    '763_386': 31.969,
    '763_387': 32.0188,
    '763_388': 32.0693,
    '763_389': 32.1252,
    '763_390': 32.1819,
    '763_391': 32.2381,
    '763_392': 32.2947,
    '763_393': 32.356,
    '763_394': 32.4107,
    '763_395': 32.4528,
    '763_396': 32.4585,
    '763_397': 32.417,
    '763_398': 32.3676,
    '763_399': 32.3403,
    '763_400': 32.3269,
    '763_401': 32.3214,
    '763_402': 32.3181,
    '763_403': 32.3184,
    '763_404': 32.3219,
    '763_405': 32.3324,
    '763_406': 32.3514,
    '763_407': 32.3889,
    '763_408': 32.4477,
    '763_409': 32.5255,
    '763_410': 32.5861,
    '763_411': 32.6032,
    '763_412': 32.5686,
    '763_413': 32.4983,
    '763_414': 32.4265,
    '763_415': 32.3745,
    '763_416': 32.3552,
    '763_417': 32.3572,
    '763_418': 32.3726,
    '763_419': 32.3996,
    '763_420': 32.4333,
    '763_421': 32.4693,
    '763_422': 32.5038,
    '763_423': 32.5354,
    '763_424': 32.5597,
    '763_425': 32.5808,
    '763_426': 32.6033,
    '763_427': 32.6305,
    '763_428': 32.6594,
    '763_429': 32.6904,
    '763_430': 32.7283,
    '763_431': 32.7713,
    '763_432': 32.8288,
    '763_433': 32.9033,
    '763_434': 32.9847,
    '763_435': 33.0648,
    '763_436': 33.1422,
    '763_437': 33.2053,
    '763_438': 33.2453,
    '763_439': 33.2618,
    '763_440': 33.2631,
    '763_441': 33.2524,
    '763_442': 33.2281,
    '763_443': 33.193,
    '763_444': 33.1503,
    '763_445': 33.1022,
    '763_446': 33.0462,
    '763_447': 32.9858,
    '763_448': 32.9243,
    '763_449': 32.8547,
    '763_450': 32.7578,
    '763_451': 32.6431,
    '763_452': 32.5315,
    '763_453': 32.4377,
    '763_454': 32.3518,
    '763_455': 32.2794,
    '763_456': 32.203,
    '763_457': 32.1397,
    '763_458': 32.0753,
    '763_459': 31.9939,
    '763_460': 31.9017,
    '763_461': 31.7793,
    '763_462': 31.6203,
    '763_463': 31.4559,
    '763_464': 31.2983,
    '763_465': 31.1399,
    '763_466': 30.9909,
    '763_467': 30.8714,
    '763_468': 30.7738,
    '763_469': 30.6877,
    '763_470': 30.6256,
    '763_471': 30.555,
    '763_472': 30.4705,
    '763_473': 30.3724,
    '763_474': 30.2975,
    '763_475': 30.2241,
    '763_476': 30.1666,
    '763_477': 30.1231,
    '763_478': 30.0885,
    '763_479': 30.0667,
    '763_480': 30.056,
    '763_481': 30.0581,
    '763_482': 30.0729,
    '763_483': 30.0895,
    '763_484': 30.1148,
    '763_485': 30.1533,
    '763_486': 30.2079,
    '763_487': 30.2733,
    '763_488': 30.3417,
    '763_489': 30.4131,
    '763_490': 30.4965,
    '763_491': 30.5952,
    '763_492': 30.7058,
    '763_493': 30.8259,
    '763_494': 30.9557,
    '763_495': 31.0976,
    '763_496': 31.249,
    '763_497': 31.4009,
    '763_498': 31.5424,
    '763_499': 31.6963,
    '763_500': 31.8573,
    '763_501': 32.0218,
    '763_502': 32.1901,
    '763_503': 32.3349,
    '763_504': 32.4744,
    '763_505': 32.6201,
    '763_506': 32.7676,
    '763_507': 32.9162,
    '763_508': 33.0669,
    '763_509': 33.2201,
    '763_510': 33.3619,
    '763_511': 33.4903,
    '763_512': 33.6053,
    '763_513': 33.7079,
    '763_514': 33.8037,
    '763_515': 33.8949,
    '763_516': 33.9814,
    '763_517': 34.0603,
    '763_518': 34.1319,
    '763_519': 34.1979,
    '763_520': 34.2517,
    '763_521': 34.279,
    '763_522': 34.2794,
    '763_523': 34.262,
    '763_524': 34.2398,
    '763_525': 34.2249,
    '763_526': 34.2162,
    '763_527': 34.2118,
    '763_528': 34.2089,
    '763_529': 34.2117,
    '763_530': 34.2263,
    '763_531': 34.245,
    '763_532': 34.2589,
    '763_533': 34.2734,
    '763_534': 34.2926,
    '763_535': 34.3203,
    '763_536': 34.3493,
    '763_537': 34.3795,
    '763_538': 34.411,
    '763_539': 34.4456,
    '763_540': 34.4788,
    '763_541': 34.5062,
    '763_542': 34.5346,
    '763_543': 34.5642,
    '763_544': 34.585,
    '764_341': 30.3776,
    '764_342': 30.4425,
    '764_343': 30.5061,
    '764_344': 30.5692,
    '764_345': 30.629,
    '764_346': 30.6934,
    '764_347': 30.7633,
    '764_348': 30.8276,
    '764_349': 30.8792,
    '764_350': 30.9306,
    '764_351': 30.98,
    '764_352': 31.0277,
    '764_353': 31.0542,
    '764_354': 31.0813,
    '764_355': 31.1148,
    '764_356': 31.1506,
    '764_357': 31.1914,
    '764_358': 31.2285,
    '764_359': 31.2647,
    '764_360': 31.2985,
    '764_361': 31.3278,
    '764_362': 31.3585,
    '764_363': 31.3893,
    '764_364': 31.4203,
    '764_365': 31.4513,
    '764_366': 31.4827,
    '764_367': 31.5151,
    '764_368': 31.5485,
    '764_369': 31.5831,
    '764_370': 31.6185,
    '764_373': 31.5077,
    '764_374': 31.537,
    '764_375': 31.5646,
    '764_376': 31.59,
    '764_377': 31.6142,
    '764_378': 31.6399,
    '764_379': 31.6697,
    '764_380': 31.7016,
    '764_381': 31.7355,
    '764_382': 31.771,
    '764_383': 31.8081,
    '764_384': 31.8477,
    '764_385': 31.8931,
    '764_386': 31.9517,
    '764_387': 32.013,
    '764_388': 32.0784,
    '764_389': 32.1366,
    '764_390': 32.1897,
    '764_391': 32.2427,
    '764_392': 32.296,
    '764_393': 32.3538,
    '764_394': 32.4025,
    '764_395': 32.4312,
    '764_396': 32.4462,
    '764_397': 32.4263,
    '764_398': 32.3822,
    '764_399': 32.353,
    '764_400': 32.3377,
    '764_401': 32.3327,
    '764_402': 32.3287,
    '764_403': 32.3259,
    '764_404': 32.3251,
    '764_405': 32.3317,
    '764_406': 32.3472,
    '764_407': 32.3813,
    '764_408': 32.4469,
    '764_409': 32.5435,
    '764_410': 32.623,
    '764_411': 32.6551,
    '764_412': 32.6276,
    '764_413': 32.5461,
    '764_414': 32.4624,
    '764_415': 32.4002,
    '764_416': 32.3699,
    '764_417': 32.3646,
    '764_418': 32.3731,
    '764_419': 32.3938,
    '764_420': 32.4184,
    '764_421': 32.4477,
    '764_422': 32.4729,
    '764_423': 32.4974,
    '764_424': 32.5197,
    '764_425': 32.5424,
    '764_426': 32.5672,
    '764_427': 32.5938,
    '764_428': 32.6194,
    '764_429': 32.6497,
    '764_430': 32.6905,
    '764_431': 32.7382,
    '764_432': 32.7999,
    '764_433': 32.8809,
    '764_434': 32.9601,
    '764_435': 33.0408,
    '764_436': 33.1187,
    '764_437': 33.1862,
    '764_438': 33.2331,
    '764_439': 33.2581,
    '764_440': 33.2652,
    '764_441': 33.2572,
    '764_442': 33.2341,
    '764_443': 33.198,
    '764_444': 33.1553,
    '764_445': 33.1046,
    '764_446': 33.0477,
    '764_447': 32.9836,
    '764_448': 32.9188,
    '764_449': 32.8486,
    '764_450': 32.7639,
    '764_451': 32.6683,
    '764_452': 32.576,
    '764_453': 32.4944,
    '764_454': 32.408,
    '764_455': 32.3395,
    '764_456': 32.2697,
    '764_457': 32.2,
    '764_458': 32.1392,
    '764_459': 32.065,
    '764_460': 31.9657,
    '764_461': 31.8389,
    '764_462': 31.6807,
    '764_463': 31.5198,
    '764_464': 31.3603,
    '764_465': 31.194,
    '764_466': 31.0566,
    '764_467': 30.9589,
    '764_468': 30.8574,
    '764_469': 30.7602,
    '764_470': 30.6989,
    '764_471': 30.6374,
    '764_472': 30.5575,
    '764_473': 30.4701,
    '764_474': 30.3951,
    '764_475': 30.3154,
    '764_476': 30.2515,
    '764_477': 30.2056,
    '764_478': 30.1688,
    '764_479': 30.1453,
    '764_480': 30.1334,
    '764_481': 30.1348,
    '764_482': 30.1496,
    '764_483': 30.1701,
    '764_484': 30.2006,
    '764_485': 30.2422,
    '764_486': 30.2975,
    '764_487': 30.3634,
    '764_488': 30.4286,
    '764_489': 30.4988,
    '764_490': 30.5831,
    '764_491': 30.6801,
    '764_492': 30.7876,
    '764_493': 30.904,
    '764_494': 31.0307,
    '764_495': 31.1741,
    '764_496': 31.3217,
    '764_497': 31.4633,
    '764_498': 31.6114,
    '764_499': 31.7667,
    '764_500': 31.9277,
    '764_501': 32.0922,
    '764_502': 32.2621,
    '764_503': 32.4068,
    '764_504': 32.5482,
    '764_505': 32.6951,
    '764_506': 32.8438,
    '764_507': 32.9921,
    '764_508': 33.1409,
    '764_509': 33.2901,
    '764_510': 33.4315,
    '764_511': 33.5614,
    '764_512': 33.6777,
    '764_513': 33.7789,
    '764_514': 33.8712,
    '764_515': 33.9587,
    '764_516': 34.0444,
    '764_517': 34.1251,
    '764_518': 34.202,
    '764_519': 34.2749,
    '764_520': 34.3306,
    '764_521': 34.3416,
    '764_522': 34.3247,
    '764_523': 34.3019,
    '764_524': 34.2792,
    '764_525': 34.2686,
    '764_526': 34.2624,
    '764_527': 34.2623,
    '764_528': 34.2639,
    '764_529': 34.268,
    '764_530': 34.2801,
    '764_531': 34.2997,
    '764_532': 34.3184,
    '764_533': 34.3368,
    '764_534': 34.3584,
    '764_535': 34.3828,
    '764_536': 34.4051,
    '764_537': 34.4283,
    '764_538': 34.4553,
    '764_539': 34.4849,
    '764_540': 34.5133,
    '764_541': 34.5389,
    '764_542': 34.5636,
    '764_543': 34.587,
    '764_544': 34.6097,
    '765_341': 30.3632,
    '765_342': 30.4284,
    '765_343': 30.4919,
    '765_344': 30.5542,
    '765_345': 30.6153,
    '765_346': 30.6794,
    '765_347': 30.7458,
    '765_348': 30.8014,
    '765_349': 30.8565,
    '765_350': 30.9098,
    '765_351': 30.9641,
    '765_352': 31.0127,
    '765_353': 31.0445,
    '765_354': 31.0795,
    '765_355': 31.1174,
    '765_356': 31.153,
    '765_357': 31.1956,
    '765_358': 31.2336,
    '765_359': 31.2739,
    '765_360': 31.3089,
    '765_361': 31.3353,
    '765_362': 31.3659,
    '765_363': 31.3974,
    '765_364': 31.429,
    '765_365': 31.4606,
    '765_366': 31.4925,
    '765_367': 31.525,
    '765_368': 31.558,
    '765_369': 31.5917,
    '765_370': 31.6257,
    '765_373': 31.5124,
    '765_374': 31.5402,
    '765_375': 31.5662,
    '765_376': 31.5898,
    '765_377': 31.6113,
    '765_378': 31.6322,
    '765_379': 31.6579,
    '765_380': 31.6879,
    '765_381': 31.7219,
    '765_382': 31.7589,
    '765_383': 31.7991,
    '765_384': 31.8428,
    '765_385': 31.8924,
    '765_386': 31.9498,
    '765_387': 32.0126,
    '765_388': 32.0779,
    '765_389': 32.1378,
    '765_390': 32.1957,
    '765_391': 32.2498,
    '765_392': 32.3033,
    '765_393': 32.36,
    '765_394': 32.3993,
    '765_395': 32.4168,
    '765_396': 32.4335,
    '765_397': 32.4261,
    '765_398': 32.3917,
    '765_399': 32.3665,
    '765_400': 32.3537,
    '765_401': 32.3468,
    '765_402': 32.3424,
    '765_403': 32.3367,
    '765_404': 32.3342,
    '765_405': 32.3396,
    '765_406': 32.3541,
    '765_407': 32.3899,
    '765_408': 32.4575,
    '765_409': 32.5577,
    '765_410': 32.6501,
    '765_411': 32.6911,
    '765_412': 32.6714,
    '765_413': 32.5961,
    '765_414': 32.5065,
    '765_415': 32.4337,
    '765_416': 32.3974,
    '765_417': 32.3842,
    '765_418': 32.3879,
    '765_419': 32.4024,
    '765_420': 32.4169,
    '765_421': 32.4357,
    '765_422': 32.4541,
    '765_423': 32.4743,
    '765_424': 32.4948,
    '765_425': 32.5179,
    '765_426': 32.5417,
    '765_427': 32.5649,
    '765_428': 32.5899,
    '765_429': 32.6178,
    '765_430': 32.655,
    '765_431': 32.704,
    '765_432': 32.7673,
    '765_433': 32.851,
    '765_434': 32.9366,
    '765_435': 33.0198,
    '765_436': 33.0986,
    '765_437': 33.1699,
    '765_438': 33.2234,
    '765_439': 33.2572,
    '765_440': 33.2703,
    '765_441': 33.2659,
    '765_442': 33.2443,
    '765_443': 33.2076,
    '765_444': 33.1642,
    '765_445': 33.1146,
    '765_446': 33.0571,
    '765_447': 32.9929,
    '765_448': 32.9244,
    '765_449': 32.8534,
    '765_450': 32.7803,
    '765_451': 32.7035,
    '765_452': 32.6306,
    '765_453': 32.5612,
    '765_454': 32.477,
    '765_455': 32.3991,
    '765_456': 32.3268,
    '765_457': 32.2515,
    '765_458': 32.1972,
    '765_459': 32.1219,
    '765_460': 32.024,
    '765_461': 31.8922,
    '765_462': 31.7334,
    '765_463': 31.572,
    '765_464': 31.4057,
    '765_465': 31.2432,
    '765_466': 31.1338,
    '765_467': 31.0398,
    '765_468': 30.9298,
    '765_469': 30.8347,
    '765_470': 30.7704,
    '765_471': 30.7041,
    '765_472': 30.6291,
    '765_473': 30.5597,
    '765_474': 30.4892,
    '765_475': 30.404,
    '765_476': 30.3367,
    '765_477': 30.2877,
    '765_478': 30.252,
    '765_479': 30.2268,
    '765_480': 30.2139,
    '765_481': 30.2179,
    '765_482': 30.229,
    '765_483': 30.2506,
    '765_484': 30.286,
    '765_485': 30.3285,
    '765_486': 30.3831,
    '765_487': 30.4463,
    '765_488': 30.5132,
    '765_489': 30.5859,
    '765_490': 30.6696,
    '765_491': 30.7647,
    '765_492': 30.8697,
    '765_493': 30.9835,
    '765_494': 31.1086,
    '765_495': 31.2448,
    '765_496': 31.3837,
    '765_497': 31.5256,
    '765_498': 31.6763,
    '765_499': 31.8317,
    '765_500': 31.9912,
    '765_501': 32.1533,
    '765_502': 32.3165,
    '765_503': 32.4662,
    '765_504': 32.6143,
    '765_505': 32.7646,
    '765_506': 32.9158,
    '765_507': 33.0641,
    '765_508': 33.2117,
    '765_509': 33.359,
    '765_510': 33.5021,
    '765_511': 33.635,
    '765_512': 33.7549,
    '765_513': 33.8518,
    '765_514': 33.9399,
    '765_515': 34.0253,
    '765_516': 34.1082,
    '765_517': 34.1887,
    '765_518': 34.2644,
    '765_519': 34.3388,
    '765_520': 34.3923,
    '765_521': 34.3854,
    '765_522': 34.3618,
    '765_523': 34.3364,
    '765_524': 34.3121,
    '765_525': 34.3104,
    '765_526': 34.3067,
    '765_527': 34.3111,
    '765_528': 34.3167,
    '765_529': 34.3236,
    '765_530': 34.3365,
    '765_531': 34.3571,
    '765_532': 34.3804,
    '765_533': 34.402,
    '765_534': 34.4276,
    '765_535': 34.4507,
    '765_536': 34.4668,
    '765_537': 34.4856,
    '765_538': 34.5071,
    '765_539': 34.5321,
    '765_540': 34.5555,
    '765_541': 34.5783,
    '765_542': 34.602,
    '765_543': 34.6241,
    '765_544': 34.6418,
    '766_341': 30.3441,
    '766_342': 30.4104,
    '766_343': 30.4744,
    '766_344': 30.5369,
    '766_345': 30.5995,
    '766_346': 30.6634,
    '766_347': 30.7265,
    '766_348': 30.7791,
    '766_349': 30.8343,
    '766_350': 30.8894,
    '766_351': 30.9473,
    '766_352': 30.9985,
    '766_353': 31.0354,
    '766_354': 31.0784,
    '766_355': 31.125,
    '766_356': 31.1567,
    '766_357': 31.1965,
    '766_358': 31.2356,
    '766_359': 31.276,
    '766_360': 31.3153,
    '766_361': 31.3398,
    '766_362': 31.3713,
    '766_363': 31.4037,
    '766_364': 31.4359,
    '766_365': 31.4681,
    '766_366': 31.5001,
    '766_367': 31.5324,
    '766_368': 31.565,
    '766_369': 31.5979,
    '766_370': 31.6309,
    '766_373': 31.5165,
    '766_374': 31.5432,
    '766_375': 31.5682,
    '766_376': 31.5908,
    '766_377': 31.6105,
    '766_378': 31.6275,
    '766_379': 31.6464,
    '766_380': 31.6743,
    '766_381': 31.709,
    '766_382': 31.7484,
    '766_383': 31.792,
    '766_384': 31.8408,
    '766_385': 31.8947,
    '766_386': 31.9537,
    '766_387': 32.0175,
    '766_388': 32.0832,
    '766_389': 32.1459,
    '766_390': 32.2046,
    '766_391': 32.2591,
    '766_392': 32.3137,
    '766_393': 32.3657,
    '766_394': 32.3923,
    '766_395': 32.4099,
    '766_396': 32.4275,
    '766_397': 32.4259,
    '766_398': 32.3985,
    '766_399': 32.3849,
    '766_400': 32.3761,
    '766_401': 32.3658,
    '766_402': 32.3597,
    '766_403': 32.3558,
    '766_404': 32.3551,
    '766_405': 32.3576,
    '766_406': 32.3696,
    '766_407': 32.4063,
    '766_408': 32.4806,
    '766_409': 32.5739,
    '766_410': 32.6696,
    '766_411': 32.7116,
    '766_412': 32.6936,
    '766_413': 32.6296,
    '766_414': 32.5475,
    '766_415': 32.4673,
    '766_416': 32.4291,
    '766_417': 32.4115,
    '766_418': 32.4095,
    '766_419': 32.4178,
    '766_420': 32.4248,
    '766_421': 32.4353,
    '766_422': 32.4484,
    '766_423': 32.4654,
    '766_424': 32.4859,
    '766_425': 32.5099,
    '766_426': 32.5306,
    '766_427': 32.5484,
    '766_428': 32.5692,
    '766_429': 32.596,
    '766_430': 32.6297,
    '766_431': 32.6738,
    '766_432': 32.7326,
    '766_433': 32.8123,
    '766_434': 32.9045,
    '766_435': 32.9948,
    '766_436': 33.0782,
    '766_437': 33.1559,
    '766_438': 33.2187,
    '766_439': 33.2591,
    '766_440': 33.2759,
    '766_441': 33.2715,
    '766_442': 33.2507,
    '766_443': 33.2156,
    '766_444': 33.1738,
    '766_445': 33.1265,
    '766_446': 33.0713,
    '766_447': 33.0098,
    '766_448': 32.9417,
    '766_449': 32.8759,
    '766_450': 32.8143,
    '766_451': 32.7517,
    '766_452': 32.6954,
    '766_453': 32.6397,
    '766_454': 32.5583,
    '766_455': 32.4662,
    '766_456': 32.3837,
    '766_457': 32.3013,
    '766_458': 32.2373,
    '766_459': 32.156,
    '766_460': 32.0533,
    '766_461': 31.9245,
    '766_462': 31.7674,
    '766_463': 31.6056,
    '766_464': 31.4323,
    '766_465': 31.2973,
    '766_466': 31.204,
    '766_467': 31.1061,
    '766_468': 30.9951,
    '766_469': 30.9043,
    '766_470': 30.8309,
    '766_471': 30.7641,
    '766_472': 30.6986,
    '766_473': 30.6368,
    '766_474': 30.5691,
    '766_475': 30.4869,
    '766_476': 30.4222,
    '766_477': 30.3772,
    '766_478': 30.3395,
    '766_479': 30.3131,
    '766_480': 30.297,
    '766_481': 30.2945,
    '766_482': 30.3044,
    '766_483': 30.3304,
    '766_484': 30.3702,
    '766_485': 30.4119,
    '766_486': 30.464,
    '766_487': 30.5261,
    '766_488': 30.5946,
    '766_489': 30.669,
    '766_490': 30.7522,
    '766_491': 30.8463,
    '766_492': 30.9503,
    '766_493': 31.062,
    '766_494': 31.184,
    '766_495': 31.3161,
    '766_496': 31.4495,
    '766_497': 31.5906,
    '766_498': 31.7394,
    '766_499': 31.8927,
    '766_500': 32.0492,
    '766_501': 32.2082,
    '766_502': 32.3661,
    '766_503': 32.52,
    '766_504': 32.6728,
    '766_505': 32.8279,
    '766_506': 32.9851,
    '766_507': 33.1315,
    '766_508': 33.2782,
    '766_509': 33.4268,
    '766_510': 33.5769,
    '766_511': 33.7145,
    '766_512': 33.8361,
    '766_513': 33.929,
    '766_514': 34.0091,
    '766_515': 34.0922,
    '766_516': 34.171,
    '766_517': 34.248,
    '766_518': 34.3195,
    '766_519': 34.3844,
    '766_520': 34.4283,
    '766_521': 34.4142,
    '766_522': 34.3916,
    '766_523': 34.3699,
    '766_524': 34.3549,
    '766_525': 34.3536,
    '766_526': 34.356,
    '766_527': 34.3625,
    '766_528': 34.3716,
    '766_529': 34.3802,
    '766_530': 34.3939,
    '766_531': 34.4178,
    '766_532': 34.443,
    '766_533': 34.4658,
    '766_534': 34.4951,
    '766_535': 34.5128,
    '766_536': 34.5296,
    '766_537': 34.5512,
    '766_538': 34.5693,
    '766_539': 34.5881,
    '766_540': 34.6048,
    '766_541': 34.6232,
    '766_542': 34.6456,
    '766_543': 34.6651,
    '766_544': 34.6739,
    '767_341': 30.3237,
    '767_342': 30.3913,
    '767_343': 30.4562,
    '767_344': 30.5196,
    '767_345': 30.5829,
    '767_346': 30.6453,
    '767_347': 30.7052,
    '767_348': 30.7603,
    '767_349': 30.8159,
    '767_350': 30.8713,
    '767_351': 30.9276,
    '767_352': 30.9822,
    '767_353': 31.0266,
    '767_354': 31.0798,
    '767_355': 31.1245,
    '767_356': 31.162,
    '767_357': 31.1962,
    '767_358': 31.2342,
    '767_359': 31.2737,
    '767_360': 31.3136,
    '767_361': 31.3429,
    '767_362': 31.3764,
    '767_363': 31.41,
    '767_364': 31.4428,
    '767_365': 31.475,
    '767_366': 31.507,
    '767_367': 31.5391,
    '767_368': 31.5714,
    '767_369': 31.6038,
    '767_370': 31.6361,
    '767_373': 31.5212,
    '767_374': 31.5472,
    '767_375': 31.5714,
    '767_376': 31.5928,
    '767_377': 31.6097,
    '767_378': 31.6178,
    '767_379': 31.6329,
    '767_380': 31.6619,
    '767_381': 31.6989,
    '767_382': 31.7411,
    '767_383': 31.7883,
    '767_384': 31.8418,
    '767_385': 31.9005,
    '767_386': 31.9622,
    '767_387': 32.0286,
    '767_388': 32.0956,
    '767_389': 32.1611,
    '767_390': 32.22,
    '767_391': 32.2745,
    '767_392': 32.3294,
    '767_393': 32.3736,
    '767_394': 32.3895,
    '767_395': 32.4033,
    '767_396': 32.4267,
    '767_397': 32.4263,
    '767_398': 32.407,
    '767_399': 32.4058,
    '767_400': 32.3941,
    '767_401': 32.3812,
    '767_402': 32.3778,
    '767_403': 32.3719,
    '767_404': 32.3741,
    '767_405': 32.3819,
    '767_406': 32.3961,
    '767_407': 32.4313,
    '767_408': 32.4974,
    '767_409': 32.5891,
    '767_410': 32.6758,
    '767_411': 32.7136,
    '767_412': 32.6979,
    '767_413': 32.6374,
    '767_414': 32.5651,
    '767_415': 32.4978,
    '767_416': 32.4621,
    '767_417': 32.4432,
    '767_418': 32.4356,
    '767_419': 32.4373,
    '767_420': 32.4382,
    '767_421': 32.4438,
    '767_422': 32.4527,
    '767_423': 32.4688,
    '767_424': 32.492,
    '767_425': 32.522,
    '767_426': 32.5395,
    '767_427': 32.5477,
    '767_428': 32.5605,
    '767_429': 32.584,
    '767_430': 32.6179,
    '767_431': 32.6611,
    '767_432': 32.7101,
    '767_433': 32.7753,
    '767_434': 32.8646,
    '767_435': 32.9623,
    '767_436': 33.0533,
    '767_437': 33.1359,
    '767_438': 33.2069,
    '767_439': 33.2539,
    '767_440': 33.2688,
    '767_441': 33.2613,
    '767_442': 33.2409,
    '767_443': 33.2114,
    '767_444': 33.176,
    '767_445': 33.1362,
    '767_446': 33.0894,
    '767_447': 33.0334,
    '767_448': 32.9701,
    '767_449': 32.9109,
    '767_450': 32.8579,
    '767_451': 32.8061,
    '767_452': 32.7591,
    '767_453': 32.7083,
    '767_454': 32.6317,
    '767_455': 32.5347,
    '767_456': 32.4419,
    '767_457': 32.3551,
    '767_458': 32.2727,
    '767_459': 32.1833,
    '767_460': 32.0752,
    '767_461': 31.9411,
    '767_462': 31.7817,
    '767_463': 31.6225,
    '767_464': 31.4663,
    '767_465': 31.3506,
    '767_466': 31.2558,
    '767_467': 31.156,
    '767_468': 31.0562,
    '767_469': 30.9697,
    '767_470': 30.9023,
    '767_471': 30.8413,
    '767_472': 30.7767,
    '767_473': 30.7163,
    '767_474': 30.6539,
    '767_475': 30.5726,
    '767_476': 30.5107,
    '767_477': 30.469,
    '767_478': 30.4354,
    '767_479': 30.4059,
    '767_480': 30.381,
    '767_481': 30.3694,
    '767_482': 30.3727,
    '767_483': 30.4015,
    '767_484': 30.4416,
    '767_485': 30.4871,
    '767_486': 30.5402,
    '767_487': 30.6039,
    '767_488': 30.6746,
    '767_489': 30.7494,
    '767_490': 30.8308,
    '767_491': 30.9243,
    '767_492': 31.028,
    '767_493': 31.1375,
    '767_494': 31.2578,
    '767_495': 31.3851,
    '767_496': 31.5188,
    '767_497': 31.656,
    '767_498': 31.8008,
    '767_499': 31.9516,
    '767_500': 32.1059,
    '767_501': 32.2614,
    '767_502': 32.4171,
    '767_503': 32.5717,
    '767_504': 32.7262,
    '767_505': 32.8842,
    '767_506': 33.0501,
    '767_507': 33.1986,
    '767_508': 33.3432,
    '767_509': 33.4951,
    '767_510': 33.6517,
    '767_511': 33.7928,
    '767_512': 33.9145,
    '767_513': 34.005,
    '767_514': 34.0842,
    '767_515': 34.1611,
    '767_516': 34.238,
    '767_517': 34.3086,
    '767_518': 34.3678,
    '767_519': 34.4176,
    '767_520': 34.4467,
    '767_521': 34.4375,
    '767_522': 34.4186,
    '767_523': 34.4019,
    '767_524': 34.3955,
    '767_525': 34.3976,
    '767_526': 34.4051,
    '767_527': 34.4137,
    '767_528': 34.4248,
    '767_529': 34.4383,
    '767_530': 34.4565,
    '767_531': 34.4826,
    '767_532': 34.5135,
    '767_533': 34.5402,
    '767_534': 34.5637,
    '767_535': 34.5793,
    '767_536': 34.6025,
    '767_537': 34.6267,
    '767_538': 34.6381,
    '767_539': 34.6522,
    '767_540': 34.6629,
    '767_541': 34.6788,
    '767_542': 34.694,
    '767_543': 34.7032,
    '767_544': 34.704,
    '768_341': 30.3017,
    '768_342': 30.3705,
    '768_343': 30.4362,
    '768_344': 30.5004,
    '768_345': 30.564,
    '768_346': 30.6258,
    '768_347': 30.6857,
    '768_348': 30.7414,
    '768_349': 30.7975,
    '768_350': 30.8532,
    '768_351': 30.9096,
    '768_352': 30.9642,
    '768_353': 31.0168,
    '768_354': 31.0762,
    '768_355': 31.1184,
    '768_356': 31.1632,
    '768_357': 31.1936,
    '768_358': 31.2317,
    '768_359': 31.2702,
    '768_360': 31.3094,
    '768_361': 31.3447,
    '768_362': 31.3812,
    '768_363': 31.4162,
    '768_364': 31.4489,
    '768_365': 31.4808,
    '768_366': 31.5126,
    '768_367': 31.5444,
    '768_368': 31.5763,
    '768_369': 31.6083,
    '768_370': 31.6401,
    '768_373': 31.5259,
    '768_374': 31.5512,
    '768_375': 31.5745,
    '768_376': 31.5941,
    '768_377': 31.6073,
    '768_378': 31.6107,
    '768_379': 31.6265,
    '768_380': 31.6545,
    '768_381': 31.6931,
    '768_382': 31.7384,
    '768_383': 31.7892,
    '768_384': 31.8459,
    '768_385': 31.9085,
    '768_386': 31.9748,
    '768_387': 32.0441,
    '768_388': 32.1135,
    '768_389': 32.1801,
    '768_390': 32.2374,
    '768_391': 32.2919,
    '768_392': 32.3443,
    '768_393': 32.376,
    '768_394': 32.3853,
    '768_395': 32.397,
    '768_396': 32.4181,
    '768_397': 32.4226,
    '768_398': 32.4067,
    '768_399': 32.4036,
    '768_400': 32.4037,
    '768_401': 32.3934,
    '768_402': 32.3909,
    '768_403': 32.3903,
    '768_404': 32.3981,
    '768_405': 32.4101,
    '768_406': 32.4239,
    '768_407': 32.4599,
    '768_408': 32.5206,
    '768_409': 32.601,
    '768_410': 32.6714,
    '768_411': 32.6997,
    '768_412': 32.6828,
    '768_413': 32.634,
    '768_414': 32.5747,
    '768_415': 32.5236,
    '768_416': 32.4924,
    '768_417': 32.4738,
    '768_418': 32.4635,
    '768_419': 32.4601,
    '768_420': 32.4564,
    '768_421': 32.4599,
    '768_422': 32.4643,
    '768_423': 32.4805,
    '768_424': 32.5092,
    '768_425': 32.5486,
    '768_426': 32.5661,
    '768_427': 32.5586,
    '768_428': 32.5591,
    '768_429': 32.5801,
    '768_430': 32.6134,
    '768_431': 32.6562,
    '768_432': 32.7013,
    '768_433': 32.7548,
    '768_434': 32.8283,
    '768_435': 32.9224,
    '768_436': 33.0125,
    '768_437': 33.1011,
    '768_438': 33.1824,
    '768_439': 33.2338,
    '768_440': 33.25,
    '768_441': 33.2428,
    '768_442': 33.2205,
    '768_443': 33.1959,
    '768_444': 33.1679,
    '768_445': 33.1401,
    '768_446': 33.1052,
    '768_447': 33.0578,
    '768_448': 33.0011,
    '768_449': 32.9473,
    '768_450': 32.8953,
    '768_451': 32.8469,
    '768_452': 32.805,
    '768_453': 32.7531,
    '768_454': 32.6769,
    '768_455': 32.5819,
    '768_456': 32.4879,
    '768_457': 32.3984,
    '768_458': 32.3094,
    '768_459': 32.2011,
    '768_460': 32.0802,
    '768_461': 31.9428,
    '768_462': 31.7916,
    '768_463': 31.6402,
    '768_464': 31.4971,
    '768_465': 31.3851,
    '768_466': 31.2953,
    '768_467': 31.2055,
    '768_468': 31.1167,
    '768_469': 31.0344,
    '768_470': 30.9683,
    '768_471': 30.9167,
    '768_472': 30.8595,
    '768_473': 30.7988,
    '768_474': 30.7411,
    '768_475': 30.665,
    '768_476': 30.6014,
    '768_477': 30.5622,
    '768_478': 30.5293,
    '768_479': 30.4993,
    '768_480': 30.4673,
    '768_481': 30.4487,
    '768_482': 30.4462,
    '768_483': 30.4714,
    '768_484': 30.5109,
    '768_485': 30.5589,
    '768_486': 30.6144,
    '768_487': 30.6781,
    '768_488': 30.7511,
    '768_489': 30.8251,
    '768_490': 30.9042,
    '768_491': 30.9956,
    '768_492': 31.0964,
    '768_493': 31.2051,
    '768_494': 31.3245,
    '768_495': 31.4532,
    '768_496': 31.579,
    '768_497': 31.7152,
    '768_498': 31.8595,
    '768_499': 32.0089,
    '768_500': 32.1619,
    '768_501': 32.3137,
    '768_502': 32.4675,
    '768_503': 32.6214,
    '768_504': 32.7758,
    '768_505': 32.9339,
    '768_506': 33.1008,
    '768_507': 33.2628,
    '768_508': 33.4088,
    '768_509': 33.5617,
    '768_510': 33.7214,
    '768_511': 33.8633,
    '768_512': 33.9887,
    '768_513': 34.0836,
    '768_514': 34.1721,
    '768_515': 34.2372,
    '768_516': 34.3067,
    '768_517': 34.3712,
    '768_518': 34.4144,
    '768_519': 34.4426,
    '768_520': 34.4595,
    '768_521': 34.4552,
    '768_522': 34.4451,
    '768_523': 34.4368,
    '768_524': 34.4342,
    '768_525': 34.4384,
    '768_526': 34.4491,
    '768_527': 34.4621,
    '768_528': 34.4772,
    '768_529': 34.4961,
    '768_530': 34.5187,
    '768_531': 34.5472,
    '768_532': 34.5847,
    '768_533': 34.6199,
    '768_534': 34.6447,
    '768_535': 34.6618,
    '768_536': 34.6823,
    '768_537': 34.7034,
    '768_538': 34.7236,
    '768_539': 34.7272,
    '768_540': 34.7321,
    '768_541': 34.741,
    '768_542': 34.7513,
    '768_543': 34.748,
    '768_544': 34.7405,
    '769_341': 30.2796,
    '769_342': 30.3489,
    '769_343': 30.4153,
    '769_344': 30.48,
    '769_345': 30.5435,
    '769_346': 30.6064,
    '769_347': 30.6666,
    '769_348': 30.7238,
    '769_349': 30.7807,
    '769_350': 30.8371,
    '769_351': 30.8938,
    '769_352': 30.9491,
    '769_353': 31.0057,
    '769_354': 31.0688,
    '769_355': 31.1103,
    '769_356': 31.1548,
    '769_357': 31.1897,
    '769_358': 31.2292,
    '769_359': 31.2661,
    '769_360': 31.3062,
    '769_361': 31.3483,
    '769_362': 31.3899,
    '769_363': 31.4258,
    '769_364': 31.4554,
    '769_365': 31.4864,
    '769_366': 31.5176,
    '769_367': 31.5491,
    '769_368': 31.5808,
    '769_369': 31.6126,
    '769_370': 31.6441,
    '769_373': 31.5312,
    '769_374': 31.5562,
    '769_375': 31.5788,
    '769_376': 31.5974,
    '769_377': 31.6098,
    '769_378': 31.6132,
    '769_379': 31.6268,
    '769_380': 31.6524,
    '769_381': 31.6927,
    '769_382': 31.7406,
    '769_383': 31.7942,
    '769_384': 31.8541,
    '769_385': 31.9199,
    '769_386': 31.9908,
    '769_387': 32.0652,
    '769_388': 32.1386,
    '769_389': 32.2052,
    '769_390': 32.2581,
    '769_391': 32.3025,
    '769_392': 32.3452,
    '769_393': 32.369,
    '769_394': 32.3692,
    '769_395': 32.3791,
    '769_396': 32.3956,
    '769_397': 32.4093,
    '769_398': 32.3984,
    '769_399': 32.3921,
    '769_400': 32.3961,
    '769_401': 32.3937,
    '769_402': 32.3961,
    '769_403': 32.4027,
    '769_404': 32.4181,
    '769_405': 32.4303,
    '769_406': 32.4473,
    '769_407': 32.4821,
    '769_408': 32.5352,
    '769_409': 32.601,
    '769_410': 32.656,
    '769_411': 32.6788,
    '769_412': 32.6686,
    '769_413': 32.6332,
    '769_414': 32.587,
    '769_415': 32.5472,
    '769_416': 32.5208,
    '769_417': 32.5028,
    '769_418': 32.492,
    '769_419': 32.486,
    '769_420': 32.4792,
    '769_421': 32.4769,
    '769_422': 32.4804,
    '769_423': 32.4971,
    '769_424': 32.5315,
    '769_425': 32.5728,
    '769_426': 32.5897,
    '769_427': 32.5747,
    '769_428': 32.5621,
    '769_429': 32.5826,
    '769_430': 32.6148,
    '769_431': 32.6543,
    '769_432': 32.6988,
    '769_433': 32.7447,
    '769_434': 32.8037,
    '769_435': 32.8763,
    '769_436': 32.9579,
    '769_437': 33.0514,
    '769_438': 33.145,
    '769_439': 33.2041,
    '769_440': 33.2158,
    '769_441': 33.1986,
    '769_442': 33.1797,
    '769_443': 33.1656,
    '769_444': 33.1495,
    '769_445': 33.1408,
    '769_446': 33.1222,
    '769_447': 33.0817,
    '769_448': 33.0278,
    '769_449': 32.9718,
    '769_450': 32.9182,
    '769_451': 32.869,
    '769_452': 32.8309,
    '769_453': 32.7841,
    '769_454': 32.7058,
    '769_455': 32.6178,
    '769_456': 32.5149,
    '769_457': 32.4191,
    '769_458': 32.331,
    '769_459': 32.2203,
    '769_460': 32.088,
    '769_461': 31.9448,
    '769_462': 31.7959,
    '769_463': 31.6551,
    '769_464': 31.5237,
    '769_465': 31.4157,
    '769_466': 31.3302,
    '769_467': 31.2503,
    '769_468': 31.1716,
    '769_469': 31.0944,
    '769_470': 31.0301,
    '769_471': 30.9814,
    '769_472': 30.9364,
    '769_473': 30.8796,
    '769_474': 30.8255,
    '769_475': 30.7609,
    '769_476': 30.695,
    '769_477': 30.6561,
    '769_478': 30.6202,
    '769_479': 30.5853,
    '769_480': 30.5595,
    '769_481': 30.5337,
    '769_482': 30.5289,
    '769_483': 30.5463,
    '769_484': 30.5811,
    '769_485': 30.6279,
    '769_486': 30.6836,
    '769_487': 30.7444,
    '769_488': 30.8177,
    '769_489': 30.8908,
    '769_490': 30.9708,
    '769_491': 31.0646,
    '769_492': 31.1676,
    '769_493': 31.2777,
    '769_494': 31.3909,
    '769_495': 31.5128,
    '769_496': 31.6381,
    '769_497': 31.7726,
    '769_498': 31.9159,
    '769_499': 32.064,
    '769_500': 32.2149,
    '769_501': 32.3659,
    '769_502': 32.5182,
    '769_503': 32.671,
    '769_504': 32.824,
    '769_505': 32.9801,
    '769_506': 33.1457,
    '769_507': 33.32,
    '769_508': 33.4692,
    '769_509': 33.6215,
    '769_510': 33.7805,
    '769_511': 33.9263,
    '769_512': 34.0514,
    '769_513': 34.1641,
    '769_514': 34.2614,
    '769_515': 34.3238,
    '769_516': 34.3765,
    '769_517': 34.4282,
    '769_518': 34.4533,
    '769_519': 34.4717,
    '769_520': 34.48,
    '769_521': 34.4791,
    '769_522': 34.4728,
    '769_523': 34.4715,
    '769_524': 34.4718,
    '769_525': 34.478,
    '769_526': 34.4936,
    '769_527': 34.5129,
    '769_528': 34.5344,
    '769_529': 34.5567,
    '769_530': 34.5837,
    '769_531': 34.6173,
    '769_532': 34.6576,
    '769_533': 34.6975,
    '769_534': 34.7296,
    '769_535': 34.7483,
    '769_536': 34.769,
    '769_537': 34.786,
    '769_538': 34.8062,
    '769_539': 34.8158,
    '769_540': 34.8113,
    '769_541': 34.8155,
    '769_542': 34.8118,
    '769_543': 34.7953,
    '769_544': 34.7767,
    '770_341': 30.2532,
    '770_342': 30.3246,
    '770_343': 30.3927,
    '770_344': 30.4588,
    '770_345': 30.5232,
    '770_346': 30.5861,
    '770_347': 30.6472,
    '770_348': 30.7064,
    '770_349': 30.7645,
    '770_350': 30.8216,
    '770_351': 30.8782,
    '770_352': 30.9351,
    '770_353': 30.9918,
    '770_354': 31.0505,
    '770_355': 31.0996,
    '770_356': 31.1443,
    '770_357': 31.1851,
    '770_358': 31.2282,
    '770_359': 31.264,
    '770_360': 31.3056,
    '770_361': 31.351,
    '770_362': 31.3971,
    '770_363': 31.4335,
    '770_364': 31.4618,
    '770_365': 31.4913,
    '770_366': 31.5218,
    '770_367': 31.553,
    '770_368': 31.5846,
    '770_369': 31.6162,
    '770_370': 31.6477,
    '770_373': 31.5371,
    '770_374': 31.562,
    '770_375': 31.5845,
    '770_376': 31.6026,
    '770_377': 31.6143,
    '770_378': 31.6168,
    '770_379': 31.6315,
    '770_380': 31.6576,
    '770_381': 31.6987,
    '770_382': 31.7472,
    '770_383': 31.8025,
    '770_384': 31.8653,
    '770_385': 31.9345,
    '770_386': 32.0096,
    '770_387': 32.0912,
    '770_388': 32.1715,
    '770_389': 32.24,
    '770_390': 32.2857,
    '770_391': 32.3151,
    '770_392': 32.342,
    '770_393': 32.3518,
    '770_394': 32.3465,
    '770_395': 32.357,
    '770_396': 32.3758,
    '770_397': 32.3861,
    '770_398': 32.3839,
    '770_399': 32.3778,
    '770_400': 32.3812,
    '770_401': 32.3887,
    '770_402': 32.397,
    '770_403': 32.4069,
    '770_404': 32.4229,
    '770_405': 32.4407,
    '770_406': 32.4656,
    '770_407': 32.4984,
    '770_408': 32.5429,
    '770_409': 32.5951,
    '770_410': 32.6384,
    '770_411': 32.661,
    '770_412': 32.6581,
    '770_413': 32.6345,
    '770_414': 32.601,
    '770_415': 32.5715,
    '770_416': 32.5494,
    '770_417': 32.5324,
    '770_418': 32.5215,
    '770_419': 32.513,
    '770_420': 32.5023,
    '770_421': 32.4963,
    '770_422': 32.4986,
    '770_423': 32.5157,
    '770_424': 32.5561,
    '770_425': 32.5962,
    '770_426': 32.6028,
    '770_427': 32.5831,
    '770_428': 32.5691,
    '770_429': 32.5874,
    '770_430': 32.6209,
    '770_431': 32.6571,
    '770_432': 32.6976,
    '770_433': 32.7385,
    '770_434': 32.7876,
    '770_435': 32.8435,
    '770_436': 32.9105,
    '770_437': 32.998,
    '770_438': 33.0973,
    '770_439': 33.1601,
    '770_440': 33.1731,
    '770_441': 33.1562,
    '770_442': 33.1507,
    '770_443': 33.1475,
    '770_444': 33.143,
    '770_445': 33.1447,
    '770_446': 33.1375,
    '770_447': 33.1024,
    '770_448': 33.0496,
    '770_449': 32.9886,
    '770_450': 32.9287,
    '770_451': 32.8758,
    '770_452': 32.8348,
    '770_453': 32.7825,
    '770_454': 32.7076,
    '770_455': 32.616,
    '770_456': 32.5199,
    '770_457': 32.4279,
    '770_458': 32.3334,
    '770_459': 32.226,
    '770_460': 32.0992,
    '770_461': 31.946,
    '770_462': 31.8032,
    '770_463': 31.676,
    '770_464': 31.5597,
    '770_465': 31.4575,
    '770_466': 31.3728,
    '770_467': 31.293,
    '770_468': 31.2193,
    '770_469': 31.1491,
    '770_470': 31.0902,
    '770_471': 31.0395,
    '770_472': 30.996,
    '770_473': 30.9431,
    '770_474': 30.8887,
    '770_475': 30.838,
    '770_476': 30.7857,
    '770_477': 30.743,
    '770_478': 30.6962,
    '770_479': 30.661,
    '770_480': 30.6447,
    '770_481': 30.618,
    '770_482': 30.6089,
    '770_483': 30.6206,
    '770_484': 30.6498,
    '770_485': 30.6928,
    '770_486': 30.7452,
    '770_487': 30.8063,
    '770_488': 30.877,
    '770_489': 30.9526,
    '770_490': 31.0377,
    '770_491': 31.1331,
    '770_492': 31.237,
    '770_493': 31.3474,
    '770_494': 31.4561,
    '770_495': 31.5779,
    '770_496': 31.6977,
    '770_497': 31.8298,
    '770_498': 31.9718,
    '770_499': 32.1191,
    '770_500': 32.2689,
    '770_501': 32.4193,
    '770_502': 32.5701,
    '770_503': 32.7213,
    '770_504': 32.8729,
    '770_505': 33.0261,
    '770_506': 33.1865,
    '770_507': 33.3565,
    '770_508': 33.5119,
    '770_509': 33.6694,
    '770_510': 33.8302,
    '770_511': 33.973,
    '770_512': 34.1054,
    '770_513': 34.2372,
    '770_514': 34.3403,
    '770_515': 34.4086,
    '770_516': 34.451,
    '770_517': 34.484,
    '770_518': 34.4988,
    '770_519': 34.5081,
    '770_520': 34.5124,
    '770_521': 34.5115,
    '770_522': 34.5062,
    '770_523': 34.5048,
    '770_524': 34.5098,
    '770_525': 34.5211,
    '770_526': 34.5387,
    '770_527': 34.5642,
    '770_528': 34.593,
    '770_529': 34.618,
    '770_530': 34.6474,
    '770_531': 34.6853,
    '770_532': 34.7291,
    '770_533': 34.7724,
    '770_534': 34.8097,
    '770_535': 34.8371,
    '770_536': 34.8532,
    '770_537': 34.8655,
    '770_538': 34.8848,
    '770_539': 34.8926,
    '770_540': 34.8865,
    '770_541': 34.8801,
    '770_542': 34.8703,
    '770_543': 34.842,
    '770_544': 34.8165,
    '771_341': 30.2253,
    '771_342': 30.2984,
    '771_343': 30.3684,
    '771_344': 30.4362,
    '771_345': 30.5019,
    '771_346': 30.5658,
    '771_347': 30.628,
    '771_348': 30.6885,
    '771_349': 30.7475,
    '771_350': 30.8054,
    '771_351': 30.8625,
    '771_352': 30.9198,
    '771_353': 30.9758,
    '771_354': 31.0315,
    '771_355': 31.0846,
    '771_356': 31.1329,
    '771_357': 31.1794,
    '771_358': 31.2242,
    '771_359': 31.2631,
    '771_360': 31.3066,
    '771_361': 31.3535,
    '771_362': 31.4009,
    '771_363': 31.4372,
    '771_364': 31.4686,
    '771_365': 31.4939,
    '771_366': 31.5237,
    '771_367': 31.5549,
    '771_368': 31.5865,
    '771_369': 31.6182,
    '771_370': 31.6499,
    '771_373': 31.5423,
    '771_374': 31.5677,
    '771_375': 31.5907,
    '771_376': 31.6091,
    '771_377': 31.6213,
    '771_378': 31.6269,
    '771_379': 31.6412,
    '771_380': 31.6687,
    '771_381': 31.7098,
    '771_382': 31.758,
    '771_383': 31.8149,
    '771_384': 31.88,
    '771_385': 31.9518,
    '771_386': 32.0326,
    '771_387': 32.1194,
    '771_388': 32.2086,
    '771_389': 32.2735,
    '771_390': 32.3121,
    '771_391': 32.3211,
    '771_392': 32.3289,
    '771_393': 32.3268,
    '771_394': 32.3216,
    '771_395': 32.3308,
    '771_396': 32.3514,
    '771_397': 32.3535,
    '771_398': 32.3593,
    '771_399': 32.3623,
    '771_400': 32.3768,
    '771_401': 32.3907,
    '771_402': 32.404,
    '771_403': 32.4165,
    '771_404': 32.4322,
    '771_405': 32.4537,
    '771_406': 32.4829,
    '771_407': 32.5125,
    '771_408': 32.5495,
    '771_409': 32.5916,
    '771_410': 32.6274,
    '771_411': 32.6483,
    '771_412': 32.6514,
    '771_413': 32.6373,
    '771_414': 32.6155,
    '771_415': 32.5936,
    '771_416': 32.5756,
    '771_417': 32.559,
    '771_418': 32.548,
    '771_419': 32.5365,
    '771_420': 32.5229,
    '771_421': 32.5157,
    '771_422': 32.5163,
    '771_423': 32.5317,
    '771_424': 32.5688,
    '771_425': 32.6083,
    '771_426': 32.6063,
    '771_427': 32.583,
    '771_428': 32.5727,
    '771_429': 32.5907,
    '771_430': 32.6224,
    '771_431': 32.6572,
    '771_432': 32.6911,
    '771_433': 32.7282,
    '771_434': 32.7704,
    '771_435': 32.82,
    '771_436': 32.8788,
    '771_437': 32.956,
    '771_438': 33.0487,
    '771_439': 33.1151,
    '771_440': 33.1315,
    '771_441': 33.1363,
    '771_442': 33.1533,
    '771_443': 33.1602,
    '771_444': 33.1608,
    '771_445': 33.1592,
    '771_446': 33.1539,
    '771_447': 33.1191,
    '771_448': 33.0654,
    '771_449': 33.0012,
    '771_450': 32.9307,
    '771_451': 32.8692,
    '771_452': 32.8173,
    '771_453': 32.7625,
    '771_454': 32.6915,
    '771_455': 32.6056,
    '771_456': 32.5078,
    '771_457': 32.4041,
    '771_458': 32.3152,
    '771_459': 32.2222,
    '771_460': 32.1009,
    '771_461': 31.9582,
    '771_462': 31.8249,
    '771_463': 31.711,
    '771_464': 31.6087,
    '771_465': 31.508,
    '771_466': 31.4247,
    '771_467': 31.3434,
    '771_468': 31.2673,
    '771_469': 31.1951,
    '771_470': 31.1307,
    '771_471': 31.0782,
    '771_472': 31.0361,
    '771_473': 30.9909,
    '771_474': 30.9321,
    '771_475': 30.8906,
    '771_476': 30.851,
    '771_477': 30.8218,
    '771_478': 30.7708,
    '771_479': 30.7359,
    '771_480': 30.7103,
    '771_481': 30.6899,
    '771_482': 30.681,
    '771_483': 30.6898,
    '771_484': 30.715,
    '771_485': 30.7564,
    '771_486': 30.807,
    '771_487': 30.8665,
    '771_488': 30.9361,
    '771_489': 31.0146,
    '771_490': 31.1024,
    '771_491': 31.1987,
    '771_492': 31.3019,
    '771_493': 31.4093,
    '771_494': 31.5217,
    '771_495': 31.6378,
    '771_496': 31.7583,
    '771_497': 31.8881,
    '771_498': 32.0296,
    '771_499': 32.1762,
    '771_500': 32.3247,
    '771_501': 32.4735,
    '771_502': 32.6225,
    '771_503': 32.7719,
    '771_504': 32.9216,
    '771_505': 33.0721,
    '771_506': 33.2281,
    '771_507': 33.3935,
    '771_508': 33.5478,
    '771_509': 33.7036,
    '771_510': 33.8639,
    '771_511': 34.0135,
    '771_512': 34.1546,
    '771_513': 34.2917,
    '771_514': 34.4016,
    '771_515': 34.4754,
    '771_516': 34.5132,
    '771_517': 34.5322,
    '771_518': 34.5394,
    '771_519': 34.5475,
    '771_520': 34.5518,
    '771_521': 34.5503,
    '771_522': 34.5427,
    '771_523': 34.5395,
    '771_524': 34.5482,
    '771_525': 34.5636,
    '771_526': 34.5821,
    '771_527': 34.6128,
    '771_528': 34.6456,
    '771_529': 34.6754,
    '771_530': 34.7064,
    '771_531': 34.7499,
    '771_532': 34.7977,
    '771_533': 34.844,
    '771_534': 34.8835,
    '771_535': 34.9113,
    '771_536': 34.9344,
    '771_537': 34.9545,
    '771_538': 34.963,
    '771_539': 34.9567,
    '771_540': 34.9464,
    '771_541': 34.9312,
    '771_542': 34.914,
    '771_543': 34.8896,
    '771_544': 34.8567,
    '772_354': 31.0165,
    '772_355': 31.0694,
    '772_356': 31.1233,
    '772_357': 31.1682,
    '772_358': 31.2158,
    '772_359': 31.2607,
    '772_360': 31.3082,
    '772_361': 31.3543,
    '772_362': 31.4025,
    '772_363': 31.4484,
    '772_364': 31.4731,
    '772_365': 31.4947,
    '772_366': 31.5241,
    '772_367': 31.5556,
    '772_368': 31.5875,
    '772_369': 31.6193,
    '772_370': 31.651,
    '772_373': 31.5472,
    '772_374': 31.5736,
    '772_375': 31.5977,
    '772_376': 31.617,
    '772_377': 31.6301,
    '772_378': 31.6365,
    '772_379': 31.6497,
    '772_380': 31.6784,
    '772_381': 31.7216,
    '772_382': 31.773,
    '772_383': 31.8326,
    '772_384': 31.8975,
    '772_385': 31.9695,
    '772_386': 32.0545,
    '772_387': 32.1486,
    '772_388': 32.2439,
    '772_389': 32.3063,
    '772_390': 32.3357,
    '772_391': 32.3284,
    '772_392': 32.3156,
    '772_393': 32.3061,
    '772_394': 32.2983,
    '772_395': 32.2996,
    '772_396': 32.3124,
    '772_397': 32.3181,
    '772_398': 32.3353,
    '772_399': 32.3586,
    '772_400': 32.3864,
    '772_401': 32.405,
    '772_402': 32.4215,
    '772_403': 32.4371,
    '772_404': 32.4522,
    '772_405': 32.4754,
    '772_406': 32.503,
    '772_407': 32.527,
    '772_408': 32.5601,
    '772_409': 32.5958,
    '772_410': 32.6246,
    '772_411': 32.6438,
    '772_412': 32.6508,
    '772_413': 32.644,
    '772_414': 32.6287,
    '772_415': 32.612,
    '772_416': 32.5954,
    '772_417': 32.5815,
    '772_418': 32.5711,
    '772_419': 32.5575,
    '772_420': 32.5445,
    '772_421': 32.5375,
    '772_422': 32.5365,
    '772_423': 32.5467,
    '772_424': 32.5754,
    '772_425': 32.6064,
    '772_426': 32.604,
    '772_427': 32.5836,
    '772_428': 32.5792,
    '772_429': 32.5955,
    '772_430': 32.6244,
    '772_431': 32.6559,
    '772_432': 32.6874,
    '772_433': 32.7224,
    '772_434': 32.7622,
    '772_435': 32.8085,
    '772_436': 32.8648,
    '772_437': 32.9338,
    '772_438': 33.0126,
    '772_439': 33.0775,
    '772_440': 33.1033,
    '772_441': 33.1299,
    '772_442': 33.1595,
    '772_443': 33.1757,
    '772_444': 33.1819,
    '772_445': 33.1821,
    '772_446': 33.1737,
    '772_447': 33.1365,
    '772_448': 33.0812,
    '772_449': 33.0107,
    '772_450': 32.9292,
    '772_451': 32.8549,
    '772_452': 32.7899,
    '772_453': 32.7237,
    '772_454': 32.6487,
    '772_455': 32.5749,
    '772_456': 32.4861,
    '772_457': 32.3795,
    '772_458': 32.2864,
    '772_459': 32.2064,
    '772_460': 32.0907,
    '772_461': 31.9619,
    '772_462': 31.8496,
    '772_463': 31.7543,
    '772_464': 31.665,
    '772_465': 31.567,
    '772_466': 31.4857,
    '772_467': 31.4041,
    '772_468': 31.3198,
    '772_469': 31.2409,
    '772_470': 31.1674,
    '772_471': 31.1104,
    '772_472': 31.0671,
    '772_473': 31.0293,
    '772_474': 30.9779,
    '772_475': 30.9307,
    '772_476': 30.9045,
    '772_477': 30.8888,
    '772_478': 30.8473,
    '772_479': 30.8176,
    '772_480': 30.7792,
    '772_481': 30.7559,
    '772_482': 30.7491,
    '772_483': 30.7557,
    '772_484': 30.7787,
    '772_485': 30.8193,
    '772_486': 30.8687,
    '772_487': 30.9281,
    '772_488': 30.994,
    '772_489': 31.075,
    '772_490': 31.1653,
    '772_491': 31.2626,
    '772_492': 31.366,
    '772_493': 31.4681,
    '772_494': 31.5807,
    '772_495': 31.6962,
    '772_496': 31.8203,
    '772_497': 31.9519,
    '772_498': 32.0919,
    '772_499': 32.2369,
    '772_500': 32.3835,
    '772_501': 32.53,
    '772_502': 32.6766,
    '772_503': 32.824,
    '772_504': 32.9715,
    '772_505': 33.1194,
    '772_506': 33.2707,
    '772_507': 33.4279,
    '772_508': 33.583,
    '772_509': 33.7349,
    '772_510': 33.8974,
    '772_511': 34.0517,
    '772_512': 34.1911,
    '772_513': 34.3224,
    '772_514': 34.4296,
    '772_515': 34.5081,
    '772_516': 34.5463,
    '772_517': 34.5627,
    '772_518': 34.5784,
    '772_519': 34.5948,
    '772_520': 34.6011,
    '772_521': 34.5969,
    '772_522': 34.5881,
    '772_523': 34.5839,
    '772_524': 34.5898,
    '772_525': 34.6055,
    '772_526': 34.631,
    '772_527': 34.666,
    '772_528': 34.7024,
    '772_529': 34.7387,
    '772_530': 34.7776,
    '772_531': 34.8185,
    '772_532': 34.8666,
    '772_533': 34.9146,
    '772_534': 34.9565,
    '772_535': 34.9814,
    '772_536': 35.0032,
    '772_537': 35.0221,
    '772_538': 35.0264,
    '772_539': 35.0219,
    '772_540': 35.0058,
    '772_541': 34.9806,
    '772_542': 34.9569,
    '772_543': 34.9283,
    '772_544': 34.897,
    '773_354': 30.9992,
    '773_355': 31.0517,
    '773_356': 31.1046,
    '773_357': 31.1541,
    '773_358': 31.2044,
    '773_359': 31.2579,
    '773_360': 31.3079,
    '773_361': 31.3537,
    '773_362': 31.3975,
    '773_363': 31.4469,
    '773_364': 31.4689,
    '773_365': 31.4929,
    '773_366': 31.5231,
    '773_367': 31.5551,
    '773_368': 31.5874,
    '773_369': 31.6192,
    '773_370': 31.6508,
    '773_373': 31.5511,
    '773_374': 31.5789,
    '773_375': 31.6046,
    '773_376': 31.6251,
    '773_377': 31.637,
    '773_378': 31.6429,
    '773_379': 31.6551,
    '773_380': 31.6878,
    '773_381': 31.7336,
    '773_382': 31.7872,
    '773_383': 31.85,
    '773_384': 31.917,
    '773_385': 31.9876,
    '773_386': 32.0746,
    '773_387': 32.1743,
    '773_388': 32.2687,
    '773_389': 32.33,
    '773_390': 32.3556,
    '773_391': 32.3338,
    '773_392': 32.3071,
    '773_393': 32.2888,
    '773_394': 32.2763,
    '773_395': 32.2718,
    '773_396': 32.2798,
    '773_397': 32.2962,
    '773_398': 32.3227,
    '773_399': 32.358,
    '773_400': 32.3949,
    '773_401': 32.425,
    '773_402': 32.4513,
    '773_403': 32.4701,
    '773_404': 32.4829,
    '773_405': 32.5007,
    '773_406': 32.5229,
    '773_407': 32.5461,
    '773_408': 32.5749,
    '773_409': 32.6065,
    '773_410': 32.6285,
    '773_411': 32.6444,
    '773_412': 32.6523,
    '773_413': 32.6488,
    '773_414': 32.6381,
    '773_415': 32.6235,
    '773_416': 32.6103,
    '773_417': 32.6021,
    '773_418': 32.5915,
    '773_419': 32.5817,
    '773_420': 32.5716,
    '773_421': 32.5645,
    '773_422': 32.5622,
    '773_423': 32.5683,
    '773_424': 32.5857,
    '773_425': 32.6031,
    '773_426': 32.6007,
    '773_427': 32.5904,
    '773_428': 32.5921,
    '773_429': 32.6071,
    '773_430': 32.6296,
    '773_431': 32.6562,
    '773_432': 32.6876,
    '773_433': 32.7244,
    '773_434': 32.7662,
    '773_435': 32.8129,
    '773_436': 32.8693,
    '773_437': 32.936,
    '773_438': 33.008,
    '773_439': 33.064,
    '773_440': 33.0892,
    '773_441': 33.1224,
    '773_442': 33.1547,
    '773_443': 33.1763,
    '773_444': 33.1811,
    '773_445': 33.1914,
    '773_446': 33.1795,
    '773_447': 33.148,
    '773_448': 33.0921,
    '773_449': 33.0134,
    '773_450': 32.9235,
    '773_451': 32.836,
    '773_452': 32.7571,
    '773_453': 32.6815,
    '773_454': 32.6067,
    '773_455': 32.5354,
    '773_456': 32.4554,
    '773_457': 32.3521,
    '773_458': 32.2588,
    '773_459': 32.1789,
    '773_460': 32.0739,
    '773_461': 31.9652,
    '773_462': 31.87,
    '773_463': 31.793,
    '773_464': 31.7144,
    '773_465': 31.6249,
    '773_466': 31.5537,
    '773_467': 31.4746,
    '773_468': 31.379,
    '773_469': 31.2936,
    '773_470': 31.2118,
    '773_471': 31.1472,
    '773_472': 31.1008,
    '773_473': 31.0623,
    '773_474': 31.0175,
    '773_475': 30.9687,
    '773_476': 30.9529,
    '773_477': 30.9419,
    '773_478': 30.9166,
    '773_479': 30.8844,
    '773_480': 30.8465,
    '773_481': 30.8237,
    '773_482': 30.8172,
    '773_483': 30.8199,
    '773_484': 30.8383,
    '773_485': 30.8766,
    '773_486': 30.9248,
    '773_487': 30.9811,
    '773_488': 31.0501,
    '773_489': 31.134,
    '773_490': 31.2265,
    '773_491': 31.3248,
    '773_492': 31.4225,
    '773_493': 31.5335,
    '773_494': 31.6389,
    '773_495': 31.7573,
    '773_496': 31.8838,
    '773_497': 32.0169,
    '773_498': 32.1565,
    '773_499': 32.3006,
    '773_500': 32.4458,
    '773_501': 32.589,
    '773_502': 32.7325,
    '773_503': 32.8776,
    '773_504': 33.0232,
    '773_505': 33.1688,
    '773_506': 33.315,
    '773_507': 33.4633,
    '773_508': 33.6144,
    '773_509': 33.766,
    '773_510': 33.9319,
    '773_511': 34.0821,
    '773_512': 34.2138,
    '773_513': 34.3319,
    '773_514': 34.4319,
    '773_515': 34.5098,
    '773_516': 34.5566,
    '773_517': 34.5907,
    '773_518': 34.6162,
    '773_519': 34.6402,
    '773_520': 34.6491,
    '773_521': 34.6412,
    '773_522': 34.6309,
    '773_523': 34.627,
    '773_524': 34.6315,
    '773_525': 34.647,
    '773_526': 34.6796,
    '773_527': 34.72,
    '773_528': 34.7589,
    '773_529': 34.7989,
    '773_530': 34.8409,
    '773_531': 34.885,
    '773_532': 34.9323,
    '773_533': 34.9791,
    '773_534': 35.0211,
    '773_535': 35.0412,
    '773_536': 35.0595,
    '773_537': 35.076,
    '773_538': 35.0829,
    '773_539': 35.0807,
    '773_540': 35.0678,
    '773_541': 35.0408,
    '773_542': 35.0074,
    '773_543': 34.9699,
    '773_544': 34.9336,
    '774_354': 30.9794,
    '774_355': 31.033,
    '774_356': 31.0862,
    '774_357': 31.1389,
    '774_358': 31.1923,
    '774_359': 31.2472,
    '774_360': 31.3035,
    '774_361': 31.3492,
    '774_362': 31.3925,
    '774_363': 31.4405,
    '774_364': 31.4634,
    '774_365': 31.4893,
    '774_366': 31.5205,
    '774_367': 31.5532,
    '774_368': 31.5859,
    '774_369': 31.617,
    '774_370': 31.6483,
    '774_373': 31.5525,
    '774_374': 31.5819,
    '774_375': 31.6093,
    '774_376': 31.6305,
    '774_377': 31.6409,
    '774_378': 31.6453,
    '774_379': 31.6637,
    '774_380': 31.6993,
    '774_381': 31.7456,
    '774_382': 31.7993,
    '774_383': 31.8624,
    '774_384': 31.9311,
    '774_385': 32.0062,
    '774_386': 32.0943,
    '774_387': 32.2002,
    '774_388': 32.2939,
    '774_389': 32.353,
    '774_390': 32.3689,
    '774_391': 32.3329,
    '774_392': 32.2989,
    '774_393': 32.2734,
    '774_394': 32.256,
    '774_395': 32.248,
    '774_396': 32.2586,
    '774_397': 32.2827,
    '774_398': 32.3169,
    '774_399': 32.3603,
    '774_400': 32.4085,
    '774_401': 32.4512,
    '774_402': 32.4888,
    '774_403': 32.512,
    '774_404': 32.5224,
    '774_405': 32.5343,
    '774_406': 32.5486,
    '774_407': 32.5672,
    '774_408': 32.5902,
    '774_409': 32.6131,
    '774_410': 32.6306,
    '774_411': 32.6433,
    '774_412': 32.6512,
    '774_413': 32.6507,
    '774_414': 32.6435,
    '774_415': 32.6318,
    '774_416': 32.6244,
    '774_417': 32.6213,
    '774_418': 32.6142,
    '774_419': 32.6077,
    '774_420': 32.6002,
    '774_421': 32.5932,
    '774_422': 32.5878,
    '774_423': 32.588,
    '774_424': 32.5973,
    '774_425': 32.6061,
    '774_426': 32.6056,
    '774_427': 32.6038,
    '774_428': 32.6078,
    '774_429': 32.6179,
    '774_430': 32.6331,
    '774_431': 32.6552,
    '774_432': 32.6882,
    '774_433': 32.728,
    '774_434': 32.7739,
    '774_435': 32.8236,
    '774_436': 32.8835,
    '774_437': 32.9535,
    '774_438': 33.0226,
    '774_439': 33.0695,
    '774_440': 33.0875,
    '774_441': 33.1112,
    '774_442': 33.1402,
    '774_443': 33.1606,
    '774_444': 33.1765,
    '774_445': 33.1838,
    '774_446': 33.1787,
    '774_447': 33.1474,
    '774_448': 33.0908,
    '774_449': 33.0121,
    '774_450': 32.917,
    '774_451': 32.8212,
    '774_452': 32.7327,
    '774_453': 32.6523,
    '774_454': 32.5757,
    '774_455': 32.4965,
    '774_456': 32.4137,
    '774_457': 32.3233,
    '774_458': 32.2299,
    '774_459': 32.15,
    '774_460': 32.0619,
    '774_461': 31.9705,
    '774_462': 31.8853,
    '774_463': 31.8194,
    '774_464': 31.7542,
    '774_465': 31.6805,
    '774_466': 31.6119,
    '774_467': 31.5345,
    '774_468': 31.4359,
    '774_469': 31.3498,
    '774_470': 31.2683,
    '774_471': 31.1945,
    '774_472': 31.1372,
    '774_473': 31.0984,
    '774_474': 31.0521,
    '774_475': 31.0049,
    '774_476': 30.9907,
    '774_477': 30.9758,
    '774_478': 30.9618,
    '774_479': 30.9401,
    '774_480': 30.9024,
    '774_481': 30.8854,
    '774_482': 30.8819,
    '774_483': 30.8852,
    '774_484': 30.9024,
    '774_485': 30.9359,
    '774_486': 30.982,
    '774_487': 31.0363,
    '774_488': 31.103,
    '774_489': 31.1877,
    '774_490': 31.2829,
    '774_491': 31.3828,
    '774_492': 31.4827,
    '774_493': 31.5928,
    '774_494': 31.7032,
    '774_495': 31.8215,
    '774_496': 31.9479,
    '774_497': 32.081,
    '774_498': 32.2211,
    '774_499': 32.3676,
    '774_500': 32.5149,
    '774_501': 32.6503,
    '774_502': 32.7891,
    '774_503': 32.9321,
    '774_504': 33.0772,
    '774_505': 33.2207,
    '774_506': 33.3624,
    '774_507': 33.5035,
    '774_508': 33.6474,
    '774_509': 33.7991,
    '774_510': 33.9597,
    '774_511': 34.1035,
    '774_512': 34.2291,
    '774_513': 34.3373,
    '774_514': 34.4304,
    '774_515': 34.5128,
    '774_516': 34.5758,
    '774_517': 34.6158,
    '774_518': 34.6481,
    '774_519': 34.6756,
    '774_520': 34.6799,
    '774_521': 34.6756,
    '774_522': 34.6683,
    '774_523': 34.667,
    '774_524': 34.6739,
    '774_525': 34.6937,
    '774_526': 34.7278,
    '774_527': 34.7686,
    '774_528': 34.8111,
    '774_529': 34.8557,
    '774_530': 34.9016,
    '774_531': 34.9477,
    '774_532': 34.9936,
    '774_533': 35.0365,
    '774_534': 35.0787,
    '774_535': 35.099,
    '774_536': 35.1116,
    '774_537': 35.1266,
    '774_538': 35.1338,
    '774_539': 35.1321,
    '774_540': 35.1221,
    '774_541': 35.0975,
    '774_542': 35.0608,
    '774_543': 35.0184,
    '774_544': 34.9799,
    '775_354': 30.9589,
    '775_355': 31.0125,
    '775_356': 31.0683,
    '775_357': 31.1234,
    '775_358': 31.1805,
    '775_359': 31.2338,
    '775_360': 31.2925,
    '775_361': 31.3394,
    '775_362': 31.3907,
    '775_363': 31.4408,
    '775_364': 31.4615,
    '775_365': 31.4868,
    '775_366': 31.5181,
    '775_367': 31.5507,
    '775_368': 31.5833,
    '775_369': 31.6118,
    '775_370': 31.6424,
    '775_373': 31.5506,
    '775_374': 31.5818,
    '775_375': 31.6116,
    '775_376': 31.6346,
    '775_377': 31.6442,
    '775_378': 31.6379,
    '775_379': 31.6692,
    '775_380': 31.7105,
    '775_381': 31.7583,
    '775_382': 31.8124,
    '775_383': 31.874,
    '775_384': 31.9443,
    '775_385': 32.0224,
    '775_386': 32.11,
    '775_387': 32.2107,
    '775_388': 32.3018,
    '775_389': 32.3596,
    '775_390': 32.3733,
    '775_391': 32.3314,
    '775_392': 32.292,
    '775_393': 32.2616,
    '775_394': 32.2386,
    '775_395': 32.2291,
    '775_396': 32.2427,
    '775_397': 32.2725,
    '775_398': 32.3139,
    '775_399': 32.3667,
    '775_400': 32.4287,
    '775_401': 32.4845,
    '775_402': 32.5323,
    '775_403': 32.563,
    '775_404': 32.5746,
    '775_405': 32.5791,
    '775_406': 32.5815,
    '775_407': 32.5923,
    '775_408': 32.6041,
    '775_409': 32.6182,
    '775_410': 32.63,
    '775_411': 32.6391,
    '775_412': 32.6451,
    '775_413': 32.6459,
    '775_414': 32.6431,
    '775_415': 32.6382,
    '775_416': 32.6356,
    '775_417': 32.6321,
    '775_418': 32.6334,
    '775_419': 32.632,
    '775_420': 32.6285,
    '775_421': 32.6239,
    '775_422': 32.616,
    '775_423': 32.6107,
    '775_424': 32.6131,
    '775_425': 32.6216,
    '775_426': 32.6243,
    '775_427': 32.6245,
    '775_428': 32.6236,
    '775_429': 32.6254,
    '775_430': 32.6371,
    '775_431': 32.6573,
    '775_432': 32.6901,
    '775_433': 32.7329,
    '775_434': 32.7821,
    '775_435': 32.8376,
    '775_436': 32.906,
    '775_437': 32.984,
    '775_438': 33.0501,
    '775_439': 33.0865,
    '775_440': 33.0933,
    '775_441': 33.1013,
    '775_442': 33.118,
    '775_443': 33.1304,
    '775_444': 33.1423,
    '775_445': 33.1541,
    '775_446': 33.1574,
    '775_447': 33.1355,
    '775_448': 33.0829,
    '775_449': 33.0059,
    '775_450': 32.9134,
    '775_451': 32.8153,
    '775_452': 32.7214,
    '775_453': 32.6341,
    '775_454': 32.5545,
    '775_455': 32.4732,
    '775_456': 32.3854,
    '775_457': 32.2996,
    '775_458': 32.2082,
    '775_459': 32.1251,
    '775_460': 32.0478,
    '775_461': 31.9723,
    '775_462': 31.9,
    '775_463': 31.842,
    '775_464': 31.7864,
    '775_465': 31.7271,
    '775_466': 31.6576,
    '775_467': 31.5762,
    '775_468': 31.4828,
    '775_469': 31.4028,
    '775_470': 31.3275,
    '775_471': 31.2491,
    '775_472': 31.1802,
    '775_473': 31.1358,
    '775_474': 31.092,
    '775_475': 31.04,
    '775_476': 31.0216,
    '775_477': 31.0026,
    '775_478': 30.994,
    '775_479': 30.978,
    '775_480': 30.9572,
    '775_481': 30.9432,
    '775_482': 30.9442,
    '775_483': 30.9494,
    '775_484': 30.9637,
    '775_485': 30.9933,
    '775_486': 31.0377,
    '775_487': 31.0916,
    '775_488': 31.1568,
    '775_489': 31.2409,
    '775_490': 31.3395,
    '775_491': 31.4359,
    '775_492': 31.5392,
    '775_493': 31.6531,
    '775_494': 31.7661,
    '775_495': 31.8843,
    '775_496': 32.01,
    '775_497': 32.1426,
    '775_498': 32.2834,
    '775_499': 32.4343,
    '775_500': 32.5799,
    '775_501': 32.7103,
    '775_502': 32.8467,
    '775_503': 32.9892,
    '775_504': 33.1378,
    '775_505': 33.2804,
    '775_506': 33.4159,
    '775_507': 33.5506,
    '775_508': 33.6867,
    '775_509': 33.8337,
    '775_510': 33.9863,
    '775_511': 34.1203,
    '775_512': 34.2347,
    '775_513': 34.3386,
    '775_514': 34.4344,
    '775_515': 34.5186,
    '775_516': 34.5851,
    '775_517': 34.6274,
    '775_518': 34.6655,
    '775_519': 34.6916,
    '775_520': 34.6977,
    '775_521': 34.6971,
    '775_522': 34.6994,
    '775_523': 34.7037,
    '775_524': 34.7143,
    '775_525': 34.7382,
    '775_526': 34.7756,
    '775_527': 34.8175,
    '775_528': 34.8611,
    '775_529': 34.9119,
    '775_530': 34.9621,
    '775_531': 35.0104,
    '775_532': 35.0547,
    '775_533': 35.0942,
    '775_534': 35.1237,
    '775_535': 35.1506,
    '775_536': 35.1661,
    '775_537': 35.1771,
    '775_538': 35.1794,
    '775_539': 35.1745,
    '775_540': 35.1615,
    '775_541': 35.1385,
    '775_542': 35.115,
    '775_543': 35.0677,
    '775_544': 35.0251,
    '776_354': 30.9368,
    '776_355': 30.993,
    '776_356': 31.0502,
    '776_357': 31.1059,
    '776_358': 31.1615,
    '776_359': 31.216,
    '776_360': 31.2728,
    '776_361': 31.3275,
    '776_362': 31.3871,
    '776_363': 31.4346,
    '776_364': 31.4636,
    '776_365': 31.4861,
    '776_366': 31.514,
    '776_367': 31.5434,
    '776_368': 31.5734,
    '776_369': 31.6018,
    '776_370': 31.6325,
    '776_373': 31.5446,
    '776_374': 31.5779,
    '776_375': 31.6116,
    '776_376': 31.6394,
    '776_377': 31.6527,
    '776_378': 31.6494,
    '776_379': 31.6863,
    '776_380': 31.7267,
    '776_381': 31.7721,
    '776_382': 31.8257,
    '776_383': 31.8864,
    '776_384': 31.9545,
    '776_385': 32.0318,
    '776_386': 32.1205,
    '776_387': 32.2106,
    '776_388': 32.2952,
    '776_389': 32.3461,
    '776_390': 32.3612,
    '776_391': 32.3223,
    '776_392': 32.2827,
    '776_393': 32.2504,
    '776_394': 32.223,
    '776_395': 32.2142,
    '776_396': 32.2285,
    '776_397': 32.2621,
    '776_398': 32.3118,
    '776_399': 32.3771,
    '776_400': 32.4507,
    '776_401': 32.5203,
    '776_402': 32.5824,
    '776_403': 32.6207,
    '776_404': 32.6308,
    '776_405': 32.6231,
    '776_406': 32.6148,
    '776_407': 32.617,
    '776_408': 32.6167,
    '776_409': 32.6213,
    '776_410': 32.6268,
    '776_411': 32.6322,
    '776_412': 32.6362,
    '776_413': 32.6383,
    '776_414': 32.6407,
    '776_415': 32.6431,
    '776_416': 32.645,
    '776_417': 32.6477,
    '776_418': 32.6536,
    '776_419': 32.6579,
    '776_420': 32.6599,
    '776_421': 32.6583,
    '776_422': 32.6468,
    '776_423': 32.6351,
    '776_424': 32.637,
    '776_425': 32.6535,
    '776_426': 32.6535,
    '776_427': 32.6431,
    '776_428': 32.6327,
    '776_429': 32.6325,
    '776_430': 32.6443,
    '776_431': 32.6629,
    '776_432': 32.6936,
    '776_433': 32.7371,
    '776_434': 32.7895,
    '776_435': 32.8526,
    '776_436': 32.9348,
    '776_437': 33.0218,
    '776_438': 33.081,
    '776_439': 33.1104,
    '776_440': 33.1099,
    '776_441': 33.1002,
    '776_442': 33.0985,
    '776_443': 33.0999,
    '776_444': 33.1059,
    '776_445': 33.1189,
    '776_446': 33.1315,
    '776_447': 33.1173,
    '776_448': 33.0699,
    '776_449': 32.9969,
    '776_450': 32.909,
    '776_451': 32.8124,
    '776_452': 32.7159,
    '776_453': 32.6248,
    '776_454': 32.5405,
    '776_455': 32.4583,
    '776_456': 32.3745,
    '776_457': 32.2884,
    '776_458': 32.1983,
    '776_459': 32.1137,
    '776_460': 32.0362,
    '776_461': 31.97,
    '776_462': 31.9104,
    '776_463': 31.8604,
    '776_464': 31.805,
    '776_465': 31.7544,
    '776_466': 31.6933,
    '776_467': 31.6123,
    '776_468': 31.5275,
    '776_469': 31.4498,
    '776_470': 31.383,
    '776_471': 31.3029,
    '776_472': 31.2284,
    '776_473': 31.1725,
    '776_474': 31.1286,
    '776_475': 31.0772,
    '776_476': 31.0469,
    '776_477': 31.032,
    '776_478': 31.0258,
    '776_479': 31.0127,
    '776_480': 31.0007,
    '776_481': 30.9943,
    '776_482': 31.0039,
    '776_483': 31.0136,
    '776_484': 31.0206,
    '776_485': 31.0478,
    '776_486': 31.0921,
    '776_487': 31.1463,
    '776_488': 31.2126,
    '776_489': 31.2946,
    '776_490': 31.3988,
    '776_491': 31.4917,
    '776_492': 31.5997,
    '776_493': 31.7106,
    '776_494': 31.8242,
    '776_495': 31.9434,
    '776_496': 32.0687,
    '776_497': 32.2003,
    '776_498': 32.3399,
    '776_499': 32.4891,
    '776_500': 32.6383,
    '776_501': 32.7677,
    '776_502': 32.9065,
    '776_503': 33.0478,
    '776_504': 33.1994,
    '776_505': 33.3472,
    '776_506': 33.4853,
    '776_507': 33.6116,
    '776_508': 33.7332,
    '776_509': 33.8641,
    '776_510': 34.0046,
    '776_511': 34.1295,
    '776_512': 34.2343,
    '776_513': 34.3396,
    '776_514': 34.4398,
    '776_515': 34.5247,
    '776_516': 34.5902,
    '776_517': 34.6381,
    '776_518': 34.6727,
    '776_519': 34.6973,
    '776_520': 34.7134,
    '776_521': 34.7217,
    '776_522': 34.7308,
    '776_523': 34.7418,
    '776_524': 34.7577,
    '776_525': 34.785,
    '776_526': 34.8251,
    '776_527': 34.8714,
    '776_528': 34.9195,
    '776_529': 34.9716,
    '776_530': 35.0236,
    '776_531': 35.0677,
    '776_532': 35.1102,
    '776_533': 35.1469,
    '776_534': 35.1718,
    '776_535': 35.194,
    '776_536': 35.2118,
    '776_537': 35.221,
    '776_538': 35.2232,
    '776_539': 35.2165,
    '776_540': 35.2027,
    '776_541': 35.1819,
    '776_542': 35.1557,
    '776_543': 35.1207,
    '776_544': 35.0764,
    '777_354': 30.9224,
    '777_355': 30.978,
    '777_356': 31.0327,
    '777_357': 31.0879,
    '777_358': 31.1424,
    '777_359': 31.198,
    '777_360': 31.256,
    '777_361': 31.316,
    '777_362': 31.3715,
    '777_363': 31.421,
    '777_364': 31.455,
    '777_365': 31.4868,
    '777_366': 31.5107,
    '777_367': 31.5345,
    '777_368': 31.5599,
    '777_369': 31.5885,
    '777_370': 31.6193,
    '777_373': 31.5346,
    '777_374': 31.5691,
    '777_375': 31.6075,
    '777_376': 31.6412,
    '777_377': 31.658,
    '777_378': 31.6663,
    '777_379': 31.6984,
    '777_380': 31.7389,
    '777_381': 31.7833,
    '777_382': 31.8349,
    '777_383': 31.8946,
    '777_384': 31.9577,
    '777_385': 32.0353,
    '777_386': 32.1268,
    '777_387': 32.2073,
    '777_388': 32.2753,
    '777_389': 32.3213,
    '777_390': 32.3356,
    '777_391': 32.3057,
    '777_392': 32.2708,
    '777_393': 32.2385,
    '777_394': 32.2081,
    '777_395': 32.1998,
    '777_396': 32.2162,
    '777_397': 32.2538,
    '777_398': 32.312,
    '777_399': 32.3886,
    '777_400': 32.4716,
    '777_401': 32.5522,
    '777_402': 32.6252,
    '777_403': 32.6663,
    '777_404': 32.6755,
    '777_405': 32.6599,
    '777_406': 32.6417,
    '777_407': 32.63,
    '777_408': 32.6198,
    '777_409': 32.617,
    '777_410': 32.619,
    '777_411': 32.6217,
    '777_412': 32.6246,
    '777_413': 32.6289,
    '777_414': 32.6369,
    '777_415': 32.6451,
    '777_416': 32.6515,
    '777_417': 32.6632,
    '777_418': 32.676,
    '777_419': 32.6852,
    '777_420': 32.6926,
    '777_421': 32.6961,
    '777_422': 32.6813,
    '777_423': 32.6621,
    '777_424': 32.6604,
    '777_425': 32.6716,
    '777_426': 32.6664,
    '777_427': 32.6519,
    '777_428': 32.6413,
    '777_429': 32.6395,
    '777_430': 32.65,
    '777_431': 32.6679,
    '777_432': 32.6978,
    '777_433': 32.743,
    '777_434': 32.8005,
    '777_435': 32.8709,
    '777_436': 32.9632,
    '777_437': 33.0529,
    '777_438': 33.1074,
    '777_439': 33.1321,
    '777_440': 33.1322,
    '777_441': 33.1081,
    '777_442': 33.0904,
    '777_443': 33.082,
    '777_444': 33.0836,
    '777_445': 33.0932,
    '777_446': 33.1069,
    '777_447': 33.0955,
    '777_448': 33.0546,
    '777_449': 32.9859,
    '777_450': 32.9012,
    '777_451': 32.8096,
    '777_452': 32.7152,
    '777_453': 32.625,
    '777_454': 32.5374,
    '777_455': 32.4538,
    '777_456': 32.3736,
    '777_457': 32.2885,
    '777_458': 32.1957,
    '777_459': 32.1105,
    '777_460': 32.0332,
    '777_461': 31.9694,
    '777_462': 31.9161,
    '777_463': 31.8668,
    '777_464': 31.8146,
    '777_465': 31.7678,
    '777_466': 31.7216,
    '777_467': 31.6493,
    '777_468': 31.5752,
    '777_469': 31.5039,
    '777_470': 31.4344,
    '777_471': 31.3585,
    '777_472': 31.2831,
    '777_473': 31.2189,
    '777_474': 31.1635,
    '777_475': 31.1173,
    '777_476': 31.0806,
    '777_477': 31.0624,
    '777_478': 31.0503,
    '777_479': 31.0387,
    '777_480': 31.0317,
    '777_481': 31.0319,
    '777_482': 31.0428,
    '777_483': 31.0556,
    '777_484': 31.0672,
    '777_485': 31.0961,
    '777_486': 31.1411,
    '777_487': 31.1988,
    '777_488': 31.2703,
    '777_489': 31.3577,
    '777_490': 31.4517,
    '777_491': 31.5473,
    '777_492': 31.6533,
    '777_493': 31.7636,
    '777_494': 31.878,
    '777_495': 31.9976,
    '777_496': 32.1229,
    '777_497': 32.2542,
    '777_498': 32.3924,
    '777_499': 32.5368,
    '777_500': 32.6857,
    '777_501': 32.8259,
    '777_502': 32.9683,
    '777_503': 33.1096,
    '777_504': 33.2562,
    '777_505': 33.4021,
    '777_506': 33.5425,
    '777_507': 33.67,
    '777_508': 33.7891,
    '777_509': 33.9045,
    '777_510': 34.0239,
    '777_511': 34.1324,
    '777_512': 34.2297,
    '777_513': 34.3299,
    '777_514': 34.4288,
    '777_515': 34.5183,
    '777_516': 34.5865,
    '777_517': 34.641,
    '777_518': 34.6785,
    '777_519': 34.7086,
    '777_520': 34.7356,
    '777_521': 34.7514,
    '777_522': 34.767,
    '777_523': 34.7824,
    '777_524': 34.8017,
    '777_525': 34.8305,
    '777_526': 34.8756,
    '777_527': 34.9269,
    '777_528': 34.9763,
    '777_529': 35.0271,
    '777_530': 35.0757,
    '777_531': 35.1192,
    '777_532': 35.1594,
    '777_533': 35.1928,
    '777_534': 35.213,
    '777_535': 35.2293,
    '777_536': 35.2448,
    '777_537': 35.2572,
    '777_538': 35.2619,
    '777_539': 35.2566,
    '777_540': 35.2451,
    '777_541': 35.2284,
    '777_542': 35.2054,
    '777_543': 35.1686,
    '777_544': 35.1289,
    '777_545': 35.0845,
    '777_546': 35.0294,
    '777_547': 34.998,
    '777_548': 34.9782,
    '777_549': 34.9415,
    '777_550': 34.9157,
    '777_551': 34.9155,
    '777_552': 34.9281,
    '777_553': 34.9281,
    '777_554': 34.9154,
    '777_555': 34.913,
    '777_556': 34.929,
    '777_557': 34.9535,
    '777_558': 34.9826,
    '777_559': 35.0193,
    '777_560': 35.0611,
    '777_561': 35.113,
    '777_562': 35.1567,
    '777_563': 35.21,
    '777_564': 35.2618,
    '777_565': 35.3092,
    '777_566': 35.359,
    '777_567': 35.4068,
    '777_568': 35.4311,
    '777_569': 35.4098,
    '777_570': 35.3738,
    '777_571': 35.3476,
    '777_572': 35.333,
    '777_573': 35.3225,
    '777_574': 35.3275,
    '777_575': 35.3293,
    '777_576': 35.3235,
    '777_577': 35.3077,
    '777_578': 35.2868,
    '777_579': 35.2707,
    '777_580': 35.2612,
    '777_581': 35.2214,
    '777_582': 35.1978,
    '777_583': 35.1692,
    '777_584': 35.1257,
    '777_585': 35.0783,
    '777_586': 35.0297,
    '777_587': 34.9824,
    '777_588': 34.9326,
    '777_589': 34.883,
    '777_590': 34.8301,
    '777_591': 34.7713,
    '777_592': 34.7078,
    '777_593': 34.6429,
    '777_594': 34.5821,
    '777_595': 34.5212,
    '777_596': 34.4982,
    '777_597': 34.438,
    '777_598': 34.3712,
    '777_599': 34.3066,
    '777_600': 34.2501,
    '777_601': 34.2028,
    '777_602': 34.1611,
    '777_603': 34.1133,
    '777_604': 34.0854,
    '777_605': 34.063,
    '777_606': 34.0439,
    '777_607': 34.0236,
    '777_608': 34.0096,
    '777_609': 34.0005,
    '777_610': 33.9904,
    '777_611': 33.9809,
    '777_612': 33.9659,
    '777_613': 33.9378,
    '777_614': 33.9049,
    '777_615': 33.8716,
    '777_616': 33.8295,
    '777_617': 33.7961,
    '777_618': 33.7723,
    '777_619': 33.7487,
    '777_620': 33.7334,
    '777_621': 33.7343,
    '777_622': 33.7326,
    '777_623': 33.7384,
    '777_624': 33.741,
    '777_625': 33.7354,
    '777_626': 33.7341,
    '777_627': 33.7398,
    '777_628': 33.7519,
    '777_629': 33.7725,
    '777_630': 33.8067,
    '777_631': 33.8472,
    '777_632': 33.8907,
    '777_633': 33.9256,
    '777_634': 33.9563,
    '777_635': 33.9756,
    '777_636': 33.9965,
    '777_637': 34.0165,
    '777_638': 34.0385,
    '777_639': 34.0631,
    '777_640': 34.094,
    '777_641': 34.1333,
    '777_642': 34.1773,
    '777_643': 34.2163,
    '777_644': 34.2533,
    '777_645': 34.2932,
    '777_646': 34.3286,
    '777_647': 34.3654,
    '777_648': 34.4091,
    '777_649': 34.4589,
    '777_650': 34.5105,
    '777_651': 34.5631,
    '777_652': 34.6101,
    '777_653': 34.6507,
    '777_654': 34.6864,
    '777_655': 34.7182,
    '777_656': 34.7477,
    '777_657': 34.7717,
    '777_658': 34.7957,
    '777_659': 34.8205,
    '777_660': 34.8455,
    '778_354': 30.9045,
    '778_355': 30.9609,
    '778_356': 31.0141,
    '778_357': 31.0674,
    '778_358': 31.1221,
    '778_359': 31.1772,
    '778_360': 31.2349,
    '778_361': 31.2924,
    '778_362': 31.349,
    '778_363': 31.3992,
    '778_364': 31.436,
    '778_365': 31.4757,
    '778_366': 31.5087,
    '778_367': 31.5263,
    '778_368': 31.5443,
    '778_369': 31.5718,
    '778_370': 31.6028,
    '778_371': 31.6357,
    '778_373': 31.5214,
    '778_374': 31.5552,
    '778_375': 31.5975,
    '778_376': 31.637,
    '778_377': 31.6581,
    '778_378': 31.6768,
    '778_379': 31.7072,
    '778_380': 31.7456,
    '778_381': 31.7877,
    '778_382': 31.8352,
    '778_383': 31.8912,
    '778_384': 31.9542,
    '778_385': 32.0327,
    '778_386': 32.1241,
    '778_387': 32.2043,
    '778_388': 32.2607,
    '778_389': 32.294,
    '778_390': 32.3001,
    '778_391': 32.2795,
    '778_392': 32.2561,
    '778_393': 32.2246,
    '778_394': 32.1975,
    '778_395': 32.1908,
    '778_396': 32.2075,
    '778_397': 32.2477,
    '778_398': 32.3138,
    '778_399': 32.4024,
    '778_400': 32.4938,
    '778_401': 32.5761,
    '778_402': 32.6489,
    '778_403': 32.6925,
    '778_404': 32.6985,
    '778_405': 32.6784,
    '778_406': 32.65,
    '778_407': 32.6271,
    '778_408': 32.6116,
    '778_409': 32.6059,
    '778_410': 32.6067,
    '778_411': 32.6091,
    '778_412': 32.6135,
    '778_413': 32.6217,
    '778_414': 32.6339,
    '778_415': 32.6464,
    '778_416': 32.6577,
    '778_417': 32.6781,
    '778_418': 32.6963,
    '778_419': 32.7094,
    '778_420': 32.722,
    '778_421': 32.7327,
    '778_422': 32.715,
    '778_423': 32.6898,
    '778_424': 32.6803,
    '778_425': 32.6803,
    '778_426': 32.6777,
    '778_427': 32.6631,
    '778_428': 32.6483,
    '778_429': 32.6446,
    '778_430': 32.6562,
    '778_431': 32.6775,
    '778_432': 32.7106,
    '778_433': 32.7578,
    '778_434': 32.8194,
    '778_435': 32.8956,
    '778_436': 32.9876,
    '778_437': 33.0755,
    '778_438': 33.1282,
    '778_439': 33.1507,
    '778_440': 33.1477,
    '778_441': 33.123,
    '778_442': 33.0934,
    '778_443': 33.0815,
    '778_444': 33.0792,
    '778_445': 33.0824,
    '778_446': 33.0905,
    '778_447': 33.0746,
    '778_448': 33.0335,
    '778_449': 32.9672,
    '778_450': 32.889,
    '778_451': 32.8036,
    '778_452': 32.7156,
    '778_453': 32.6284,
    '778_454': 32.5413,
    '778_455': 32.4602,
    '778_456': 32.3801,
    '778_457': 32.2945,
    '778_458': 32.2017,
    '778_459': 32.1137,
    '778_460': 32.0378,
    '778_461': 31.9745,
    '778_462': 31.9185,
    '778_463': 31.8654,
    '778_464': 31.8156,
    '778_465': 31.772,
    '778_466': 31.733,
    '778_467': 31.6698,
    '778_468': 31.6059,
    '778_469': 31.5521,
    '778_470': 31.492,
    '778_471': 31.4247,
    '778_472': 31.3495,
    '778_473': 31.2739,
    '778_474': 31.2126,
    '778_475': 31.1665,
    '778_476': 31.1244,
    '778_477': 31.0949,
    '778_478': 31.0774,
    '778_479': 31.0654,
    '778_480': 31.0591,
    '778_481': 31.0601,
    '778_482': 31.0694,
    '778_483': 31.0845,
    '778_484': 31.104,
    '778_485': 31.1356,
    '778_486': 31.1836,
    '778_487': 31.2444,
    '778_488': 31.3178,
    '778_489': 31.4038,
    '778_490': 31.4972,
    '778_491': 31.5953,
    '778_492': 31.7015,
    '778_493': 31.8126,
    '778_494': 31.9278,
    '778_495': 32.0474,
    '778_496': 32.173,
    '778_497': 32.3045,
    '778_498': 32.4419,
    '778_499': 32.5863,
    '778_500': 32.7367,
    '778_501': 32.8831,
    '778_502': 33.0324,
    '778_503': 33.1762,
    '778_504': 33.3187,
    '778_505': 33.4595,
    '778_506': 33.5957,
    '778_507': 33.724,
    '778_508': 33.8449,
    '778_509': 33.9567,
    '778_510': 34.0568,
    '778_511': 34.1513,
    '778_512': 34.2415,
    '778_513': 34.334,
    '778_514': 34.4255,
    '778_515': 34.5089,
    '778_516': 34.5773,
    '778_517': 34.6399,
    '778_518': 34.6849,
    '778_519': 34.7253,
    '778_520': 34.7605,
    '778_521': 34.7847,
    '778_522': 34.8065,
    '778_523': 34.8257,
    '778_524': 34.8473,
    '778_525': 34.8786,
    '778_526': 34.9264,
    '778_527': 34.9803,
    '778_528': 35.03,
    '778_529': 35.0799,
    '778_530': 35.1264,
    '778_531': 35.1659,
    '778_532': 35.2024,
    '778_533': 35.2296,
    '778_534': 35.2495,
    '778_535': 35.266,
    '778_536': 35.2801,
    '778_537': 35.2919,
    '778_538': 35.2982,
    '778_539': 35.2938,
    '778_540': 35.2838,
    '778_541': 35.2691,
    '778_542': 35.2478,
    '778_543': 35.2164,
    '778_544': 35.1747,
    '778_545': 35.1287,
    '778_546': 35.081,
    '778_547': 35.0347,
    '778_548': 34.9936,
    '778_549': 34.9658,
    '778_550': 34.9499,
    '778_551': 34.949,
    '778_552': 34.9536,
    '778_553': 34.9466,
    '778_554': 34.935,
    '778_555': 34.9333,
    '778_556': 34.9493,
    '778_557': 34.9733,
    '778_558': 35.0064,
    '778_559': 35.0455,
    '778_560': 35.0895,
    '778_561': 35.1396,
    '778_562': 35.1895,
    '778_563': 35.2416,
    '778_564': 35.294,
    '778_565': 35.3504,
    '778_566': 35.4061,
    '778_567': 35.4509,
    '778_568': 35.4807,
    '778_569': 35.4662,
    '778_570': 35.4299,
    '778_571': 35.4017,
    '778_572': 35.3937,
    '778_573': 35.3866,
    '778_574': 35.3905,
    '778_575': 35.3897,
    '778_576': 35.3819,
    '778_577': 35.3715,
    '778_578': 35.3598,
    '778_579': 35.3469,
    '778_580': 35.3402,
    '778_581': 35.3106,
    '778_582': 35.2842,
    '778_583': 35.2641,
    '778_584': 35.225,
    '778_585': 35.1764,
    '778_586': 35.1203,
    '778_587': 35.0686,
    '778_588': 35.0208,
    '778_589': 34.9757,
    '778_590': 34.9233,
    '778_591': 34.8632,
    '778_592': 34.7994,
    '778_593': 34.7354,
    '778_594': 34.6726,
    '778_595': 34.6126,
    '778_596': 34.5938,
    '778_597': 34.5278,
    '778_598': 34.4598,
    '778_599': 34.3953,
    '778_600': 34.34,
    '778_601': 34.2907,
    '778_602': 34.2416,
    '778_603': 34.1959,
    '778_604': 34.1604,
    '778_605': 34.1303,
    '778_606': 34.1033,
    '778_607': 34.0789,
    '778_608': 34.0717,
    '778_609': 34.0701,
    '778_610': 34.0618,
    '778_611': 34.0535,
    '778_612': 34.0391,
    '778_613': 34.0097,
    '778_614': 33.9744,
    '778_615': 33.9367,
    '778_616': 33.899,
    '778_617': 33.8701,
    '778_618': 33.8455,
    '778_619': 33.8239,
    '778_620': 33.8098,
    '778_621': 33.8054,
    '778_622': 33.8023,
    '778_623': 33.806,
    '778_624': 33.8132,
    '778_625': 33.8048,
    '778_626': 33.8042,
    '778_627': 33.8121,
    '778_628': 33.8267,
    '778_629': 33.849,
    '778_630': 33.8838,
    '778_631': 33.9255,
    '778_632': 33.9661,
    '778_633': 34.003,
    '778_634': 34.0334,
    '778_635': 34.0498,
    '778_636': 34.0683,
    '778_637': 34.0849,
    '778_638': 34.1019,
    '778_639': 34.1223,
    '778_640': 34.151,
    '778_641': 34.1924,
    '778_642': 34.2278,
    '778_643': 34.2632,
    '778_644': 34.3024,
    '778_645': 34.3425,
    '778_646': 34.3797,
    '778_647': 34.416,
    '778_648': 34.4524,
    '778_649': 34.5042,
    '778_650': 34.5611,
    '778_651': 34.6132,
    '778_652': 34.6595,
    '778_653': 34.699,
    '778_654': 34.731,
    '778_655': 34.7578,
    '778_656': 34.7816,
    '778_657': 34.8056,
    '778_658': 34.8299,
    '778_659': 34.8549,
    '778_660': 34.8799,
    '779_354': 30.8819,
    '779_355': 30.9368,
    '779_356': 30.9923,
    '779_357': 31.0462,
    '779_358': 31.0998,
    '779_359': 31.155,
    '779_360': 31.2102,
    '779_361': 31.2662,
    '779_362': 31.3233,
    '779_363': 31.3717,
    '779_364': 31.4054,
    '779_365': 31.4479,
    '779_366': 31.4913,
    '779_367': 31.5085,
    '779_368': 31.525,
    '779_369': 31.5525,
    '779_370': 31.5842,
    '779_371': 31.6177,
    '779_373': 31.5102,
    '779_374': 31.5458,
    '779_375': 31.5835,
    '779_376': 31.62,
    '779_377': 31.6483,
    '779_378': 31.6764,
    '779_379': 31.7091,
    '779_380': 31.7473,
    '779_381': 31.7868,
    '779_382': 31.8311,
    '779_383': 31.883,
    '779_384': 31.9451,
    '779_385': 32.0207,
    '779_386': 32.1092,
    '779_387': 32.1925,
    '779_388': 32.249,
    '779_389': 32.268,
    '779_390': 32.2647,
    '779_391': 32.2533,
    '779_392': 32.2363,
    '779_393': 32.2065,
    '779_394': 32.183,
    '779_395': 32.1806,
    '779_396': 32.1993,
    '779_397': 32.2423,
    '779_398': 32.3159,
    '779_399': 32.4145,
    '779_400': 32.5125,
    '779_401': 32.5961,
    '779_402': 32.6604,
    '779_403': 32.6974,
    '779_404': 32.696,
    '779_405': 32.6702,
    '779_406': 32.6358,
    '779_407': 32.6094,
    '779_408': 32.5927,
    '779_409': 32.5864,
    '779_410': 32.5895,
    '779_411': 32.5948,
    '779_412': 32.6035,
    '779_413': 32.6167,
    '779_414': 32.6337,
    '779_415': 32.6527,
    '779_416': 32.6716,
    '779_417': 32.6945,
    '779_418': 32.7169,
    '779_419': 32.7308,
    '779_420': 32.7456,
    '779_421': 32.761,
    '779_422': 32.7414,
    '779_423': 32.72,
    '779_424': 32.703,
    '779_425': 32.6967,
    '779_426': 32.6906,
    '779_427': 32.6755,
    '779_428': 32.6611,
    '779_429': 32.6619,
    '779_430': 32.6772,
    '779_431': 32.7043,
    '779_432': 32.7418,
    '779_433': 32.7898,
    '779_434': 32.8511,
    '779_435': 32.9228,
    '779_436': 33.0093,
    '779_437': 33.0905,
    '779_438': 33.1429,
    '779_439': 33.1654,
    '779_440': 33.1633,
    '779_441': 33.1411,
    '779_442': 33.1121,
    '779_443': 33.1025,
    '779_444': 33.0945,
    '779_445': 33.0893,
    '779_446': 33.0816,
    '779_447': 33.0613,
    '779_448': 33.016,
    '779_449': 32.9503,
    '779_450': 32.8762,
    '779_451': 32.8007,
    '779_452': 32.7207,
    '779_453': 32.6358,
    '779_454': 32.5541,
    '779_455': 32.4775,
    '779_456': 32.3987,
    '779_457': 32.3101,
    '779_458': 32.217,
    '779_459': 32.1281,
    '779_460': 32.052,
    '779_461': 31.9863,
    '779_462': 31.9269,
    '779_463': 31.8687,
    '779_464': 31.8167,
    '779_465': 31.7695,
    '779_466': 31.728,
    '779_467': 31.676,
    '779_468': 31.6225,
    '779_469': 31.579,
    '779_470': 31.5301,
    '779_471': 31.4769,
    '779_472': 31.4153,
    '779_473': 31.3379,
    '779_474': 31.2744,
    '779_475': 31.2217,
    '779_476': 31.1712,
    '779_477': 31.1375,
    '779_478': 31.1119,
    '779_479': 31.0945,
    '779_480': 31.0857,
    '779_481': 31.0844,
    '779_482': 31.0924,
    '779_483': 31.1068,
    '779_484': 31.1296,
    '779_485': 31.1681,
    '779_486': 31.2218,
    '779_487': 31.2877,
    '779_488': 31.3661,
    '779_489': 31.4486,
    '779_490': 31.5419,
    '779_491': 31.6421,
    '779_492': 31.7485,
    '779_493': 31.86,
    '779_494': 31.9756,
    '779_495': 32.0956,
    '779_496': 32.2214,
    '779_497': 32.3525,
    '779_498': 32.4901,
    '779_499': 32.636,
    '779_500': 32.792,
    '779_501': 32.9538,
    '779_502': 33.1132,
    '779_503': 33.257,
    '779_504': 33.3972,
    '779_505': 33.5314,
    '779_506': 33.6611,
    '779_507': 33.7848,
    '779_508': 33.9048,
    '779_509': 34.012,
    '779_510': 34.1024,
    '779_511': 34.1883,
    '779_512': 34.2741,
    '779_513': 34.3606,
    '779_514': 34.4491,
    '779_515': 34.5233,
    '779_516': 34.5856,
    '779_517': 34.6443,
    '779_518': 34.7004,
    '779_519': 34.7504,
    '779_520': 34.7909,
    '779_521': 34.825,
    '779_522': 34.8515,
    '779_523': 34.8731,
    '779_524': 34.8959,
    '779_525': 34.9264,
    '779_526': 34.9791,
    '779_527': 35.0373,
    '779_528': 35.0873,
    '779_529': 35.1321,
    '779_530': 35.174,
    '779_531': 35.2118,
    '779_532': 35.2447,
    '779_533': 35.2687,
    '779_534': 35.2854,
    '779_535': 35.3014,
    '779_536': 35.3138,
    '779_537': 35.323,
    '779_538': 35.3275,
    '779_539': 35.3254,
    '779_540': 35.3178,
    '779_541': 35.3052,
    '779_542': 35.2851,
    '779_543': 35.2537,
    '779_544': 35.2135,
    '779_545': 35.1689,
    '779_546': 35.1243,
    '779_547': 35.078,
    '779_548': 35.0229,
    '779_549': 34.9937,
    '779_550': 34.9821,
    '779_551': 34.9801,
    '779_552': 34.9768,
    '779_553': 34.9644,
    '779_554': 34.9532,
    '779_555': 34.9519,
    '779_556': 34.968,
    '779_557': 34.9931,
    '779_558': 35.0341,
    '779_559': 35.0769,
    '779_560': 35.1229,
    '779_561': 35.1729,
    '779_562': 35.2288,
    '779_563': 35.2815,
    '779_564': 35.3317,
    '779_565': 35.393,
    '779_566': 35.4489,
    '779_567': 35.4929,
    '779_568': 35.5148,
    '779_569': 35.5076,
    '779_570': 35.4762,
    '779_571': 35.4472,
    '779_572': 35.4381,
    '779_573': 35.4394,
    '779_574': 35.4494,
    '779_575': 35.4469,
    '779_576': 35.4374,
    '779_577': 35.4212,
    '779_578': 35.4165,
    '779_579': 35.417,
    '779_580': 35.4178,
    '779_581': 35.3918,
    '779_582': 35.3691,
    '779_583': 35.3489,
    '779_584': 35.3146,
    '779_585': 35.2691,
    '779_586': 35.2057,
    '779_587': 35.1474,
    '779_588': 35.0982,
    '779_589': 35.0644,
    '779_590': 35.0112,
    '779_591': 34.9516,
    '779_592': 34.889,
    '779_593': 34.8258,
    '779_594': 34.7638,
    '779_595': 34.7085,
    '779_596': 34.6906,
    '779_597': 34.6199,
    '779_598': 34.5467,
    '779_599': 34.4779,
    '779_600': 34.4216,
    '779_601': 34.3722,
    '779_602': 34.3237,
    '779_603': 34.278,
    '779_604': 34.2375,
    '779_605': 34.2023,
    '779_606': 34.1678,
    '779_607': 34.1342,
    '779_608': 34.1362,
    '779_609': 34.143,
    '779_610': 34.133,
    '779_611': 34.1236,
    '779_612': 34.1064,
    '779_613': 34.0781,
    '779_614': 34.0428,
    '779_615': 34.005,
    '779_616': 33.9668,
    '779_617': 33.9392,
    '779_618': 33.9194,
    '779_619': 33.903,
    '779_620': 33.8916,
    '779_621': 33.8854,
    '779_622': 33.8798,
    '779_623': 33.8808,
    '779_624': 33.8951,
    '779_625': 33.8919,
    '779_626': 33.8868,
    '779_627': 33.889,
    '779_628': 33.9049,
    '779_629': 33.9308,
    '779_630': 33.9661,
    '779_631': 34.0068,
    '779_632': 34.0445,
    '779_633': 34.0803,
    '779_634': 34.1099,
    '779_635': 34.1336,
    '779_636': 34.1524,
    '779_637': 34.1641,
    '779_638': 34.1752,
    '779_639': 34.1923,
    '779_640': 34.222,
    '779_641': 34.252,
    '779_642': 34.2849,
    '779_643': 34.3217,
    '779_644': 34.3594,
    '779_645': 34.3986,
    '779_646': 34.437,
    '779_647': 34.4726,
    '779_648': 34.4987,
    '779_649': 34.5549,
    '779_650': 34.6174,
    '779_651': 34.6686,
    '779_652': 34.7144,
    '779_653': 34.7527,
    '779_654': 34.78,
    '779_655': 34.8016,
    '779_656': 34.8213,
    '779_657': 34.8425,
    '779_658': 34.866,
    '779_659': 34.8907,
    '779_660': 34.9153,
    '780_354': 30.8588,
    '780_355': 30.9149,
    '780_356': 30.9706,
    '780_357': 31.0234,
    '780_358': 31.076,
    '780_359': 31.1306,
    '780_360': 31.1846,
    '780_361': 31.2406,
    '780_362': 31.3019,
    '780_363': 31.3479,
    '780_364': 31.3752,
    '780_365': 31.4112,
    '780_366': 31.4509,
    '780_367': 31.4762,
    '780_368': 31.4976,
    '780_369': 31.5254,
    '780_370': 31.5611,
    '780_371': 31.5948,
    '780_373': 31.4961,
    '780_374': 31.5354,
    '780_375': 31.5718,
    '780_376': 31.6049,
    '780_377': 31.6326,
    '780_378': 31.6709,
    '780_379': 31.7062,
    '780_380': 31.7402,
    '780_381': 31.7776,
    '780_382': 31.8201,
    '780_383': 31.8693,
    '780_384': 31.9288,
    '780_385': 32.0018,
    '780_386': 32.0865,
    '780_387': 32.1713,
    '780_388': 32.2292,
    '780_389': 32.2385,
    '780_390': 32.2317,
    '780_391': 32.2277,
    '780_392': 32.2115,
    '780_393': 32.1865,
    '780_394': 32.1709,
    '780_395': 32.1733,
    '780_396': 32.1954,
    '780_397': 32.2413,
    '780_398': 32.3189,
    '780_399': 32.4212,
    '780_400': 32.5207,
    '780_401': 32.6019,
    '780_402': 32.6528,
    '780_403': 32.6763,
    '780_404': 32.6684,
    '780_405': 32.6362,
    '780_406': 32.6035,
    '780_407': 32.5803,
    '780_408': 32.5638,
    '780_409': 32.5619,
    '780_410': 32.5688,
    '780_411': 32.5788,
    '780_412': 32.5927,
    '780_413': 32.6105,
    '780_414': 32.6319,
    '780_415': 32.6558,
    '780_416': 32.6805,
    '780_417': 32.7069,
    '780_418': 32.7327,
    '780_419': 32.7494,
    '780_420': 32.7627,
    '780_421': 32.773,
    '780_422': 32.7593,
    '780_423': 32.744,
    '780_424': 32.7268,
    '780_425': 32.7166,
    '780_426': 32.7065,
    '780_427': 32.6944,
    '780_428': 32.6856,
    '780_429': 32.6937,
    '780_430': 32.7148,
    '780_431': 32.745,
    '780_432': 32.7865,
    '780_433': 32.8351,
    '780_434': 32.8911,
    '780_435': 32.9567,
    '780_436': 33.0327,
    '780_437': 33.104,
    '780_438': 33.155,
    '780_439': 33.1792,
    '780_440': 33.1781,
    '780_441': 33.1599,
    '780_442': 33.1375,
    '780_443': 33.1237,
    '780_444': 33.1111,
    '780_445': 33.0997,
    '780_446': 33.084,
    '780_447': 33.0578,
    '780_448': 33.011,
    '780_449': 32.9427,
    '780_450': 32.8694,
    '780_451': 32.7984,
    '780_452': 32.7242,
    '780_453': 32.6487,
    '780_454': 32.5731,
    '780_455': 32.498,
    '780_456': 32.4209,
    '780_457': 32.3331,
    '780_458': 32.2399,
    '780_459': 32.1488,
    '780_460': 32.0723,
    '780_461': 32.0076,
    '780_462': 31.9423,
    '780_463': 31.8762,
    '780_464': 31.8221,
    '780_465': 31.7696,
    '780_466': 31.7228,
    '780_467': 31.6768,
    '780_468': 31.631,
    '780_469': 31.5918,
    '780_470': 31.5558,
    '780_471': 31.5138,
    '780_472': 31.4577,
    '780_473': 31.399,
    '780_474': 31.3458,
    '780_475': 31.2775,
    '780_476': 31.2189,
    '780_477': 31.1793,
    '780_478': 31.1486,
    '780_479': 31.1268,
    '780_480': 31.1143,
    '780_481': 31.1091,
    '780_482': 31.1131,
    '780_483': 31.1248,
    '780_484': 31.1503,
    '780_485': 31.1945,
    '780_486': 31.2546,
    '780_487': 31.3263,
    '780_488': 31.3964,
    '780_489': 31.4813,
    '780_490': 31.5808,
    '780_491': 31.6854,
    '780_492': 31.7926,
    '780_493': 31.904,
    '780_494': 32.02,
    '780_495': 32.1404,
    '780_496': 32.2663,
    '780_497': 32.3969,
    '780_498': 32.5351,
    '780_499': 32.6839,
    '780_500': 32.849,
    '780_501': 33.0296,
    '780_502': 33.2013,
    '780_503': 33.3467,
    '780_504': 33.4834,
    '780_505': 33.6142,
    '780_506': 33.7368,
    '780_507': 33.8434,
    '780_508': 33.9599,
    '780_509': 34.0634,
    '780_510': 34.1529,
    '780_511': 34.2384,
    '780_512': 34.3203,
    '780_513': 34.4007,
    '780_514': 34.4787,
    '780_515': 34.5473,
    '780_516': 34.6047,
    '780_517': 34.6636,
    '780_518': 34.7303,
    '780_519': 34.7836,
    '780_520': 34.8252,
    '780_521': 34.8721,
    '780_522': 34.8991,
    '780_523': 34.9244,
    '780_524': 34.9513,
    '780_525': 34.9848,
    '780_526': 35.0323,
    '780_527': 35.0864,
    '780_528': 35.1368,
    '780_529': 35.1769,
    '780_530': 35.2152,
    '780_531': 35.2525,
    '780_532': 35.2846,
    '780_533': 35.3,
    '780_534': 35.3185,
    '780_535': 35.3342,
    '780_536': 35.3447,
    '780_537': 35.3509,
    '780_538': 35.3532,
    '780_539': 35.3526,
    '780_540': 35.3467,
    '780_541': 35.3356,
    '780_542': 35.3173,
    '780_543': 35.2856,
    '780_544': 35.2469,
    '780_545': 35.2044,
    '780_546': 35.1559,
    '780_547': 35.1027,
    '780_548': 35.0487,
    '780_549': 35.0202,
    '780_550': 35.0096,
    '780_551': 35.004,
    '780_552': 34.9912,
    '780_553': 34.9784,
    '780_554': 34.9706,
    '780_555': 34.9749,
    '780_556': 34.9948,
    '780_557': 35.0251,
    '780_558': 35.0636,
    '780_559': 35.109,
    '780_560': 35.1581,
    '780_561': 35.211,
    '780_562': 35.2676,
    '780_563': 35.3233,
    '780_564': 35.3749,
    '780_565': 35.4363,
    '780_566': 35.4927,
    '780_567': 35.5454,
    '780_568': 35.5811,
    '780_569': 35.5676,
    '780_570': 35.5345,
    '780_571': 35.5025,
    '780_572': 35.4958,
    '780_573': 35.4931,
    '780_574': 35.4942,
    '780_575': 35.4905,
    '780_576': 35.4771,
    '780_577': 35.4674,
    '780_578': 35.4599,
    '780_579': 35.4634,
    '780_580': 35.464,
    '780_581': 35.4474,
    '780_582': 35.4317,
    '780_583': 35.4139,
    '780_584': 35.384,
    '780_585': 35.3442,
    '780_586': 35.2872,
    '780_587': 35.2236,
    '780_588': 35.1766,
    '780_589': 35.1404,
    '780_590': 35.0777,
    '780_591': 35.0314,
    '780_592': 34.979,
    '780_593': 34.9132,
    '780_594': 34.8549,
    '780_595': 34.798,
    '780_596': 34.775,
    '780_597': 34.7032,
    '780_598': 34.6291,
    '780_599': 34.5593,
    '780_600': 34.4988,
    '780_601': 34.4464,
    '780_602': 34.3974,
    '780_603': 34.3526,
    '780_604': 34.3108,
    '780_605': 34.2749,
    '780_606': 34.2379,
    '780_607': 34.2136,
    '780_608': 34.2091,
    '780_609': 34.2116,
    '780_610': 34.203,
    '780_611': 34.1907,
    '780_612': 34.1718,
    '780_613': 34.1445,
    '780_614': 34.1086,
    '780_615': 34.0702,
    '780_616': 34.0347,
    '780_617': 34.0068,
    '780_618': 33.9904,
    '780_619': 33.9796,
    '780_620': 33.9729,
    '780_621': 33.9706,
    '780_622': 33.966,
    '780_623': 33.9663,
    '780_624': 33.9746,
    '780_625': 33.9754,
    '780_626': 33.9727,
    '780_627': 33.9694,
    '780_628': 33.9865,
    '780_629': 34.0132,
    '780_630': 34.0468,
    '780_631': 34.0835,
    '780_632': 34.121,
    '780_633': 34.1577,
    '780_634': 34.1988,
    '780_635': 34.224,
    '780_636': 34.2412,
    '780_637': 34.2493,
    '780_638': 34.2564,
    '780_639': 34.2701,
    '780_640': 34.314,
    '780_641': 34.319,
    '780_642': 34.3456,
    '780_643': 34.3802,
    '780_644': 34.4183,
    '780_645': 34.4576,
    '780_646': 34.4958,
    '780_647': 34.5316,
    '780_648': 34.5647,
    '780_649': 34.6125,
    '780_650': 34.6662,
    '780_651': 34.7202,
    '780_652': 34.768,
    '780_653': 34.8058,
    '780_654': 34.8287,
    '780_655': 34.8455,
    '780_656': 34.8614,
    '780_657': 34.8792,
    '780_658': 34.9018,
    '780_659': 34.9275,
    '780_660': 34.9539,
    '781_354': 30.8347,
    '781_355': 30.8917,
    '781_356': 30.9482,
    '781_357': 30.9988,
    '781_358': 31.051,
    '781_359': 31.1047,
    '781_360': 31.1577,
    '781_361': 31.2128,
    '781_362': 31.2744,
    '781_363': 31.3146,
    '781_364': 31.3445,
    '781_365': 31.3773,
    '781_366': 31.4126,
    '781_367': 31.441,
    '781_368': 31.4672,
    '781_369': 31.4959,
    '781_370': 31.5309,
    '781_371': 31.5669,
    '781_373': 31.4725,
    '781_374': 31.5133,
    '781_375': 31.5557,
    '781_376': 31.5952,
    '781_377': 31.6292,
    '781_378': 31.6646,
    '781_379': 31.6974,
    '781_380': 31.7271,
    '781_381': 31.7621,
    '781_382': 31.8031,
    '781_383': 31.8533,
    '781_384': 31.912,
    '781_385': 31.9825,
    '781_386': 32.0659,
    '781_387': 32.152,
    '781_388': 32.206,
    '781_389': 32.2159,
    '781_390': 32.2039,
    '781_391': 32.2,
    '781_392': 32.1888,
    '781_393': 32.174,
    '781_394': 32.1651,
    '781_395': 32.1718,
    '781_396': 32.1982,
    '781_397': 32.2476,
    '781_398': 32.3247,
    '781_399': 32.4243,
    '781_400': 32.5143,
    '781_401': 32.5863,
    '781_402': 32.6252,
    '781_403': 32.635,
    '781_404': 32.6222,
    '781_405': 32.5918,
    '781_406': 32.5619,
    '781_407': 32.5418,
    '781_408': 32.5336,
    '781_409': 32.5382,
    '781_410': 32.5495,
    '781_411': 32.564,
    '781_412': 32.582,
    '781_413': 32.6033,
    '781_414': 32.6286,
    '781_415': 32.6558,
    '781_416': 32.6838,
    '781_417': 32.7132,
    '781_418': 32.7427,
    '781_419': 32.7686,
    '781_420': 32.7874,
    '781_421': 32.7893,
    '781_422': 32.7802,
    '781_423': 32.7703,
    '781_424': 32.7557,
    '781_425': 32.744,
    '781_426': 32.7346,
    '781_427': 32.7236,
    '781_428': 32.7226,
    '781_429': 32.7388,
    '781_430': 32.7643,
    '781_431': 32.7918,
    '781_432': 32.8386,
    '781_433': 32.8887,
    '781_434': 32.9399,
    '781_435': 33.0018,
    '781_436': 33.0676,
    '781_437': 33.1277,
    '781_438': 33.1715,
    '781_439': 33.1887,
    '781_440': 33.1852,
    '781_441': 33.1703,
    '781_442': 33.1497,
    '781_443': 33.1355,
    '781_444': 33.1216,
    '781_445': 33.1069,
    '781_446': 33.0886,
    '781_447': 33.0625,
    '781_448': 33.0141,
    '781_449': 32.9441,
    '781_450': 32.8683,
    '781_451': 32.797,
    '781_452': 32.7248,
    '781_453': 32.6568,
    '781_454': 32.5906,
    '781_455': 32.5213,
    '781_456': 32.4468,
    '781_457': 32.3612,
    '781_458': 32.2702,
    '781_459': 32.1767,
    '781_460': 32.0962,
    '781_461': 32.0272,
    '781_462': 31.9605,
    '781_463': 31.8894,
    '781_464': 31.8279,
    '781_465': 31.774,
    '781_466': 31.727,
    '781_467': 31.683,
    '781_468': 31.6433,
    '781_469': 31.6134,
    '781_470': 31.5872,
    '781_471': 31.5463,
    '781_472': 31.5,
    '781_473': 31.4553,
    '781_474': 31.4056,
    '781_475': 31.3393,
    '781_476': 31.2735,
    '781_477': 31.222,
    '781_478': 31.187,
    '781_479': 31.1621,
    '781_480': 31.1462,
    '781_481': 31.1367,
    '781_482': 31.1345,
    '781_483': 31.1426,
    '781_484': 31.1712,
    '781_485': 31.2194,
    '781_486': 31.2803,
    '781_487': 31.3478,
    '781_488': 31.4151,
    '781_489': 31.5051,
    '781_490': 31.616,
    '781_491': 31.7247,
    '781_492': 31.833,
    '781_493': 31.9447,
    '781_494': 32.0614,
    '781_495': 32.1829,
    '781_496': 32.3096,
    '781_497': 32.4383,
    '781_498': 32.5775,
    '781_499': 32.7313,
    '781_500': 32.9108,
    '781_501': 33.1037,
    '781_502': 33.2765,
    '781_503': 33.4199,
    '781_504': 33.5579,
    '781_505': 33.6974,
    '781_506': 33.811,
    '781_507': 33.904,
    '781_508': 34.0137,
    '781_509': 34.1082,
    '781_510': 34.199,
    '781_511': 34.2967,
    '781_512': 34.3746,
    '781_513': 34.4411,
    '781_514': 34.5045,
    '781_515': 34.5747,
    '781_516': 34.6337,
    '781_517': 34.694,
    '781_518': 34.7655,
    '781_519': 34.8207,
    '781_520': 34.8627,
    '781_521': 34.906,
    '781_522': 34.9441,
    '781_523': 34.9778,
    '781_524': 35.0096,
    '781_525': 35.0389,
    '781_526': 35.0798,
    '781_527': 35.1269,
    '781_528': 35.1712,
    '781_529': 35.2095,
    '781_530': 35.2459,
    '781_531': 35.2831,
    '781_532': 35.3145,
    '781_533': 35.3317,
    '781_534': 35.3519,
    '781_535': 35.3659,
    '781_536': 35.3741,
    '781_537': 35.3779,
    '781_538': 35.3794,
    '781_539': 35.3786,
    '781_540': 35.372,
    '781_541': 35.3596,
    '781_542': 35.34,
    '781_543': 35.3109,
    '781_544': 35.2761,
    '781_545': 35.2366,
    '781_546': 35.1886,
    '781_547': 35.1344,
    '781_548': 35.0839,
    '781_549': 35.0543,
    '781_550': 35.0381,
    '781_551': 35.0252,
    '781_552': 35.0052,
    '781_553': 34.9941,
    '781_554': 34.9888,
    '781_555': 34.9985,
    '781_556': 35.0236,
    '781_557': 35.0561,
    '781_558': 35.093,
    '781_559': 35.1393,
    '781_560': 35.193,
    '781_561': 35.2525,
    '781_562': 35.3143,
    '781_563': 35.3759,
    '781_564': 35.43,
    '781_565': 35.4828,
    '781_566': 35.5321,
    '781_567': 35.5846,
    '781_568': 35.6186,
    '781_569': 35.6145,
    '781_570': 35.5855,
    '781_571': 35.5592,
    '781_572': 35.5509,
    '781_573': 35.5395,
    '781_574': 35.5314,
    '781_575': 35.5278,
    '781_576': 35.5202,
    '781_577': 35.5056,
    '781_578': 35.4915,
    '781_579': 35.4938,
    '781_580': 35.4951,
    '781_581': 35.4795,
    '781_582': 35.4693,
    '781_583': 35.4506,
    '781_584': 35.4287,
    '781_585': 35.3996,
    '781_586': 35.3477,
    '781_587': 35.292,
    '781_588': 35.2454,
    '781_589': 35.2038,
    '781_590': 35.1532,
    '781_591': 35.109,
    '781_592': 35.0623,
    '781_593': 35.0011,
    '781_594': 34.9421,
    '781_595': 34.8795,
    '781_596': 34.8484,
    '781_597': 34.7761,
    '781_598': 34.7029,
    '781_599': 34.6323,
    '781_600': 34.5687,
    '781_601': 34.518,
    '781_602': 34.4741,
    '781_603': 34.4268,
    '781_604': 34.3826,
    '781_605': 34.3435,
    '781_606': 34.3094,
    '781_607': 34.2916,
    '781_608': 34.2803,
    '781_609': 34.2778,
    '781_610': 34.2699,
    '781_611': 34.2579,
    '781_612': 34.2401,
    '781_613': 34.2109,
    '781_614': 34.1748,
    '781_615': 34.1369,
    '781_616': 34.1057,
    '781_617': 34.082,
    '781_618': 34.066,
    '781_619': 34.0508,
    '781_620': 34.0493,
    '781_621': 34.053,
    '781_622': 34.0537,
    '781_623': 34.055,
    '781_624': 34.0643,
    '781_625': 34.0705,
    '781_626': 34.0739,
    '781_627': 34.0703,
    '781_628': 34.0788,
    '781_629': 34.0993,
    '781_630': 34.1288,
    '781_631': 34.1618,
    '781_632': 34.202,
    '781_633': 34.2467,
    '781_634': 34.2901,
    '781_635': 34.3158,
    '781_636': 34.3321,
    '781_637': 34.341,
    '781_638': 34.3494,
    '781_639': 34.3547,
    '781_640': 34.3805,
    '781_641': 34.3941,
    '781_642': 34.4155,
    '781_643': 34.4457,
    '781_644': 34.4834,
    '781_645': 34.5224,
    '781_646': 34.5601,
    '781_647': 34.5955,
    '781_648': 34.6321,
    '781_649': 34.6748,
    '781_650': 34.7242,
    '781_651': 34.7751,
    '781_652': 34.822,
    '781_653': 34.8543,
    '781_654': 34.8762,
    '781_655': 34.8883,
    '781_656': 34.8963,
    '781_657': 34.9146,
    '781_658': 34.9383,
    '781_659': 34.9655,
    '781_660': 34.9935,
    '781_786': 43.3503,
    '781_787': 43.4657,
    '781_788': 43.5864,
    '781_789': 43.7017,
    '781_790': 43.8054,
    '781_791': 43.8936,
    '781_792': 43.9621,
    '781_793': 44.0031,
    '781_794': 44.0146,
    '781_795': 44.0037,
    '781_796': 43.9709,
    '782_354': 30.8093,
    '782_355': 30.8647,
    '782_356': 30.9178,
    '782_357': 30.9711,
    '782_358': 31.0255,
    '782_359': 31.0781,
    '782_360': 31.1304,
    '782_361': 31.1824,
    '782_362': 31.2372,
    '782_363': 31.2773,
    '782_364': 31.3117,
    '782_365': 31.3441,
    '782_366': 31.3804,
    '782_367': 31.4071,
    '782_368': 31.4343,
    '782_369': 31.4637,
    '782_370': 31.4974,
    '782_371': 31.5349,
    '782_373': 31.4474,
    '782_374': 31.4927,
    '782_375': 31.5397,
    '782_376': 31.5856,
    '782_377': 31.6225,
    '782_378': 31.6549,
    '782_379': 31.6853,
    '782_380': 31.7118,
    '782_381': 31.743,
    '782_382': 31.7818,
    '782_383': 31.8314,
    '782_384': 31.8953,
    '782_385': 31.9632,
    '782_386': 32.0427,
    '782_387': 32.1258,
    '782_388': 32.1781,
    '782_389': 32.1901,
    '782_390': 32.1834,
    '782_391': 32.1787,
    '782_392': 32.1738,
    '782_393': 32.1675,
    '782_394': 32.1626,
    '782_395': 32.1742,
    '782_396': 32.2059,
    '782_397': 32.2613,
    '782_398': 32.3367,
    '782_399': 32.4217,
    '782_400': 32.4964,
    '782_401': 32.5487,
    '782_402': 32.5775,
    '782_403': 32.5802,
    '782_404': 32.5636,
    '782_405': 32.5412,
    '782_406': 32.5182,
    '782_407': 32.5046,
    '782_408': 32.5052,
    '782_409': 32.5169,
    '782_410': 32.5323,
    '782_411': 32.55,
    '782_412': 32.5708,
    '782_413': 32.5946,
    '782_414': 32.6225,
    '782_415': 32.6527,
    '782_416': 32.6831,
    '782_417': 32.7153,
    '782_418': 32.7483,
    '782_419': 32.7797,
    '782_420': 32.806,
    '782_421': 32.8107,
    '782_422': 32.8072,
    '782_423': 32.8014,
    '782_424': 32.7906,
    '782_425': 32.7787,
    '782_426': 32.7701,
    '782_427': 32.7655,
    '782_428': 32.7724,
    '782_429': 32.7969,
    '782_430': 32.8221,
    '782_431': 32.8477,
    '782_432': 32.8987,
    '782_433': 32.9524,
    '782_434': 32.998,
    '782_435': 33.0534,
    '782_436': 33.1087,
    '782_437': 33.1586,
    '782_438': 33.1891,
    '782_439': 33.1964,
    '782_440': 33.1888,
    '782_441': 33.1716,
    '782_442': 33.1484,
    '782_443': 33.13,
    '782_444': 33.1169,
    '782_445': 33.1073,
    '782_446': 33.0925,
    '782_447': 33.0663,
    '782_448': 33.025,
    '782_449': 32.9601,
    '782_450': 32.8849,
    '782_451': 32.8055,
    '782_452': 32.7284,
    '782_453': 32.6589,
    '782_454': 32.5951,
    '782_455': 32.5324,
    '782_456': 32.4674,
    '782_457': 32.3888,
    '782_458': 32.3101,
    '782_459': 32.2181,
    '782_460': 32.1281,
    '782_461': 32.0488,
    '782_462': 31.9737,
    '782_463': 31.9056,
    '782_464': 31.8383,
    '782_465': 31.782,
    '782_466': 31.7362,
    '782_467': 31.6928,
    '782_468': 31.6536,
    '782_469': 31.6259,
    '782_470': 31.605,
    '782_471': 31.574,
    '782_472': 31.5431,
    '782_473': 31.4988,
    '782_474': 31.4475,
    '782_475': 31.3911,
    '782_476': 31.3336,
    '782_477': 31.2669,
    '782_478': 31.2275,
    '782_479': 31.2016,
    '782_480': 31.1831,
    '782_481': 31.1698,
    '782_482': 31.1648,
    '782_483': 31.1739,
    '782_484': 31.2058,
    '782_485': 31.2588,
    '782_486': 31.3221,
    '782_487': 31.3728,
    '782_488': 31.4348,
    '782_489': 31.5308,
    '782_490': 31.6535,
    '782_491': 31.7631,
    '782_492': 31.8708,
    '782_493': 31.9819,
    '782_494': 32.0979,
    '782_495': 32.2191,
    '782_496': 32.3471,
    '782_497': 32.476,
    '782_498': 32.617,
    '782_499': 32.775,
    '782_500': 32.9651,
    '782_501': 33.158,
    '782_502': 33.3329,
    '782_503': 33.4892,
    '782_504': 33.6323,
    '782_505': 33.7708,
    '782_506': 33.8773,
    '782_507': 33.9705,
    '782_508': 34.0596,
    '782_509': 34.1495,
    '782_510': 34.2517,
    '782_511': 34.3479,
    '782_512': 34.4229,
    '782_513': 34.4812,
    '782_514': 34.5409,
    '782_515': 34.6139,
    '782_516': 34.6723,
    '782_517': 34.7305,
    '782_518': 34.7967,
    '782_519': 34.8583,
    '782_520': 34.9063,
    '782_521': 34.9463,
    '782_522': 34.991,
    '782_523': 35.0309,
    '782_524': 35.0642,
    '782_525': 35.0876,
    '782_526': 35.1207,
    '782_527': 35.1607,
    '782_528': 35.2009,
    '782_529': 35.2369,
    '782_530': 35.2711,
    '782_531': 35.3096,
    '782_532': 35.3436,
    '782_533': 35.3653,
    '782_534': 35.3837,
    '782_535': 35.3947,
    '782_536': 35.3995,
    '782_537': 35.4027,
    '782_538': 35.404,
    '782_539': 35.4035,
    '782_540': 35.3959,
    '782_541': 35.3817,
    '782_542': 35.3607,
    '782_543': 35.3329,
    '782_544': 35.3017,
    '782_545': 35.2667,
    '782_546': 35.2253,
    '782_547': 35.1782,
    '782_548': 35.1214,
    '782_549': 35.0939,
    '782_550': 35.0787,
    '782_551': 35.0595,
    '782_552': 35.0308,
    '782_553': 35.0193,
    '782_554': 35.0159,
    '782_555': 35.0265,
    '782_556': 35.0569,
    '782_557': 35.0929,
    '782_558': 35.1307,
    '782_559': 35.1783,
    '782_560': 35.2347,
    '782_561': 35.2993,
    '782_562': 35.3658,
    '782_563': 35.432,
    '782_564': 35.4851,
    '782_565': 35.5317,
    '782_566': 35.5809,
    '782_567': 35.6264,
    '782_568': 35.6552,
    '782_569': 35.6503,
    '782_570': 35.6288,
    '782_571': 35.5994,
    '782_572': 35.5855,
    '782_573': 35.5742,
    '782_574': 35.5637,
    '782_575': 35.557,
    '782_576': 35.5472,
    '782_577': 35.5322,
    '782_578': 35.5115,
    '782_579': 35.5129,
    '782_580': 35.5138,
    '782_581': 35.499,
    '782_582': 35.4878,
    '782_583': 35.4722,
    '782_584': 35.4482,
    '782_585': 35.4204,
    '782_586': 35.3802,
    '782_587': 35.3364,
    '782_588': 35.2965,
    '782_589': 35.2584,
    '782_590': 35.2151,
    '782_591': 35.172,
    '782_592': 35.1262,
    '782_593': 35.0721,
    '782_594': 35.0154,
    '782_595': 34.9481,
    '782_596': 34.9089,
    '782_597': 34.8377,
    '782_598': 34.7676,
    '782_599': 34.6983,
    '782_600': 34.6344,
    '782_601': 34.5815,
    '782_602': 34.5396,
    '782_603': 34.4934,
    '782_604': 34.4497,
    '782_605': 34.4113,
    '782_606': 34.3808,
    '782_607': 34.3618,
    '782_608': 34.35,
    '782_609': 34.344,
    '782_610': 34.336,
    '782_611': 34.3253,
    '782_612': 34.31,
    '782_613': 34.278,
    '782_614': 34.2426,
    '782_615': 34.2087,
    '782_616': 34.1777,
    '782_617': 34.1556,
    '782_618': 34.139,
    '782_619': 34.1179,
    '782_620': 34.1233,
    '782_621': 34.1348,
    '782_622': 34.1445,
    '782_623': 34.151,
    '782_624': 34.1591,
    '782_625': 34.1674,
    '782_626': 34.1733,
    '782_627': 34.1689,
    '782_628': 34.1709,
    '782_629': 34.1853,
    '782_630': 34.2108,
    '782_631': 34.2559,
    '782_632': 34.2973,
    '782_633': 34.3412,
    '782_634': 34.383,
    '782_635': 34.4082,
    '782_636': 34.4256,
    '782_637': 34.4394,
    '782_638': 34.4554,
    '782_639': 34.4521,
    '782_640': 34.4599,
    '782_641': 34.4692,
    '782_642': 34.4914,
    '782_643': 34.5219,
    '782_644': 34.5575,
    '782_645': 34.5952,
    '782_646': 34.6326,
    '782_647': 34.6678,
    '782_648': 34.7008,
    '782_649': 34.7349,
    '782_650': 34.7743,
    '782_651': 34.8278,
    '782_652': 34.8791,
    '782_653': 34.9093,
    '782_654': 34.9315,
    '782_655': 34.9415,
    '782_656': 34.9474,
    '782_657': 34.9596,
    '782_658': 34.9813,
    '782_659': 35.0084,
    '782_660': 35.0363,
    '782_786': 43.3483,
    '782_787': 43.4652,
    '782_788': 43.5822,
    '782_789': 43.6939,
    '782_790': 43.8021,
    '782_791': 43.895,
    '782_792': 43.965,
    '782_793': 44.0051,
    '782_794': 44.013,
    '782_795': 43.9972,
    '782_796': 43.9613,
    '783_354': 30.785,
    '783_355': 30.8411,
    '783_356': 30.8967,
    '783_357': 30.9535,
    '783_358': 31.0041,
    '783_359': 31.0535,
    '783_360': 31.1026,
    '783_361': 31.1522,
    '783_362': 31.2007,
    '783_363': 31.2457,
    '783_364': 31.2885,
    '783_365': 31.3142,
    '783_366': 31.3451,
    '783_367': 31.3726,
    '783_368': 31.3998,
    '783_369': 31.4288,
    '783_370': 31.4612,
    '783_371': 31.4986,
    '783_373': 31.4183,
    '783_374': 31.4683,
    '783_375': 31.5182,
    '783_376': 31.5647,
    '783_377': 31.6028,
    '783_378': 31.6346,
    '783_379': 31.6634,
    '783_380': 31.6889,
    '783_381': 31.7183,
    '783_382': 31.7578,
    '783_383': 31.8085,
    '783_384': 31.8708,
    '783_385': 31.9379,
    '783_386': 32.0124,
    '783_387': 32.0921,
    '783_388': 32.1432,
    '783_389': 32.1604,
    '783_390': 32.1642,
    '783_391': 32.1636,
    '783_392': 32.1625,
    '783_393': 32.1631,
    '783_394': 32.1667,
    '783_395': 32.1842,
    '783_396': 32.2246,
    '783_397': 32.2794,
    '783_398': 32.3422,
    '783_399': 32.4125,
    '783_400': 32.4703,
    '783_401': 32.5033,
    '783_402': 32.5183,
    '783_403': 32.5183,
    '783_404': 32.5041,
    '783_405': 32.4874,
    '783_406': 32.4724,
    '783_407': 32.4681,
    '783_408': 32.4781,
    '783_409': 32.4958,
    '783_410': 32.5156,
    '783_411': 32.536,
    '783_412': 32.5583,
    '783_413': 32.5837,
    '783_414': 32.6136,
    '783_415': 32.6461,
    '783_416': 32.6784,
    '783_417': 32.7138,
    '783_418': 32.7492,
    '783_419': 32.783,
    '783_420': 32.816,
    '783_421': 32.8287,
    '783_422': 32.8316,
    '783_423': 32.8358,
    '783_424': 32.8282,
    '783_425': 32.8185,
    '783_426': 32.8124,
    '783_427': 32.8135,
    '783_428': 32.8277,
    '783_429': 32.8579,
    '783_430': 32.8826,
    '783_431': 32.9066,
    '783_432': 32.9627,
    '783_433': 33.0201,
    '783_434': 33.0591,
    '783_435': 33.1015,
    '783_436': 33.1476,
    '783_437': 33.1798,
    '783_438': 33.1967,
    '783_439': 33.1956,
    '783_440': 33.1834,
    '783_441': 33.1603,
    '783_442': 33.1314,
    '783_443': 33.1068,
    '783_444': 33.0966,
    '783_445': 33.0951,
    '783_446': 33.0898,
    '783_447': 33.0756,
    '783_448': 33.0475,
    '783_449': 32.9985,
    '783_450': 32.9278,
    '783_451': 32.8411,
    '783_452': 32.7489,
    '783_453': 32.6643,
    '783_454': 32.5907,
    '783_455': 32.526,
    '783_456': 32.4698,
    '783_457': 32.4092,
    '783_458': 32.3471,
    '783_459': 32.2651,
    '783_460': 32.1658,
    '783_461': 32.0755,
    '783_462': 31.9942,
    '783_463': 31.9241,
    '783_464': 31.8542,
    '783_465': 31.7939,
    '783_466': 31.7436,
    '783_467': 31.702,
    '783_468': 31.6651,
    '783_469': 31.6341,
    '783_470': 31.6138,
    '783_471': 31.5992,
    '783_472': 31.5775,
    '783_473': 31.5285,
    '783_474': 31.4641,
    '783_475': 31.4137,
    '783_476': 31.3654,
    '783_477': 31.3074,
    '783_478': 31.2707,
    '783_479': 31.2487,
    '783_480': 31.225,
    '783_481': 31.2065,
    '783_482': 31.1968,
    '783_483': 31.2037,
    '783_484': 31.2359,
    '783_485': 31.2889,
    '783_486': 31.3456,
    '783_487': 31.3964,
    '783_488': 31.47,
    '783_489': 31.5817,
    '783_490': 31.6921,
    '783_491': 31.7982,
    '783_492': 31.9047,
    '783_493': 32.0149,
    '783_494': 32.1296,
    '783_495': 32.2501,
    '783_496': 32.3778,
    '783_497': 32.5088,
    '783_498': 32.6521,
    '783_499': 32.8107,
    '783_500': 32.9961,
    '783_501': 33.1826,
    '783_502': 33.3638,
    '783_503': 33.5339,
    '783_504': 33.688,
    '783_505': 33.8258,
    '783_506': 33.9388,
    '783_507': 34.0306,
    '783_508': 34.1026,
    '783_509': 34.1955,
    '783_510': 34.2973,
    '783_511': 34.3794,
    '783_512': 34.4496,
    '783_513': 34.5083,
    '783_514': 34.5876,
    '783_515': 34.6657,
    '783_516': 34.7216,
    '783_517': 34.7764,
    '783_518': 34.8371,
    '783_519': 34.9027,
    '783_520': 34.9535,
    '783_521': 35,
    '783_522': 35.0506,
    '783_523': 35.0926,
    '783_524': 35.1186,
    '783_525': 35.1359,
    '783_526': 35.1577,
    '783_527': 35.1929,
    '783_528': 35.2312,
    '783_529': 35.2657,
    '783_530': 35.2991,
    '783_531': 35.3342,
    '783_532': 35.3635,
    '783_533': 35.3839,
    '783_534': 35.4101,
    '783_535': 35.4202,
    '783_536': 35.4229,
    '783_537': 35.427,
    '783_538': 35.4296,
    '783_539': 35.4292,
    '783_540': 35.4195,
    '783_541': 35.403,
    '783_542': 35.3803,
    '783_543': 35.3521,
    '783_544': 35.3239,
    '783_545': 35.2936,
    '783_546': 35.2541,
    '783_547': 35.201,
    '783_548': 35.1578,
    '783_549': 35.1307,
    '783_550': 35.1096,
    '783_551': 35.0864,
    '783_552': 35.062,
    '783_553': 35.0452,
    '783_554': 35.047,
    '783_555': 35.0619,
    '783_556': 35.0922,
    '783_557': 35.1293,
    '783_558': 35.1703,
    '783_559': 35.2215,
    '783_560': 35.2806,
    '783_561': 35.3519,
    '783_562': 35.4236,
    '783_563': 35.4884,
    '783_564': 35.5412,
    '783_565': 35.5902,
    '783_566': 35.6357,
    '783_567': 35.6713,
    '783_568': 35.6919,
    '783_569': 35.6838,
    '783_570': 35.668,
    '783_571': 35.6442,
    '783_572': 35.6254,
    '783_573': 35.606,
    '783_574': 35.5883,
    '783_575': 35.577,
    '783_576': 35.561,
    '783_577': 35.5428,
    '783_578': 35.5251,
    '783_579': 35.5183,
    '783_580': 35.516,
    '783_581': 35.5018,
    '783_582': 35.4936,
    '783_583': 35.4828,
    '783_584': 35.4538,
    '783_585': 35.4367,
    '783_586': 35.4101,
    '783_587': 35.3707,
    '783_588': 35.3316,
    '783_589': 35.297,
    '783_590': 35.2586,
    '783_591': 35.2176,
    '783_592': 35.175,
    '783_593': 35.1274,
    '783_594': 35.0634,
    '783_595': 34.9891,
    '783_596': 34.9483,
    '783_597': 34.8831,
    '783_598': 34.8164,
    '783_599': 34.7513,
    '783_600': 34.6886,
    '783_601': 34.6339,
    '783_602': 34.5931,
    '783_603': 34.5499,
    '783_604': 34.5088,
    '783_605': 34.4725,
    '783_606': 34.4438,
    '783_607': 34.4242,
    '783_608': 34.4127,
    '783_609': 34.4069,
    '783_610': 34.4009,
    '783_611': 34.3927,
    '783_612': 34.3707,
    '783_613': 34.3379,
    '783_614': 34.3047,
    '783_615': 34.2746,
    '783_616': 34.2454,
    '783_617': 34.2246,
    '783_618': 34.2112,
    '783_619': 34.2012,
    '783_620': 34.2051,
    '783_621': 34.2156,
    '783_622': 34.2325,
    '783_623': 34.2472,
    '783_624': 34.2567,
    '783_625': 34.2627,
    '783_626': 34.2668,
    '783_627': 34.2624,
    '783_628': 34.2611,
    '783_629': 34.2748,
    '783_630': 34.3131,
    '783_631': 34.3571,
    '783_632': 34.3974,
    '783_633': 34.4366,
    '783_634': 34.4747,
    '783_635': 34.501,
    '783_636': 34.5207,
    '783_637': 34.542,
    '783_638': 34.5553,
    '783_639': 34.5535,
    '783_640': 34.5537,
    '783_641': 34.5564,
    '783_642': 34.5754,
    '783_643': 34.6041,
    '783_644': 34.6375,
    '783_645': 34.6736,
    '783_646': 34.7101,
    '783_647': 34.7453,
    '783_648': 34.7719,
    '783_649': 34.7996,
    '783_650': 34.8335,
    '783_651': 34.8785,
    '783_652': 34.9246,
    '783_653': 34.9605,
    '783_654': 34.9883,
    '783_655': 34.9982,
    '783_656': 35.003,
    '783_657': 35.0146,
    '783_658': 35.033,
    '783_659': 35.0554,
    '783_660': 35.0788,
    '783_786': 43.3508,
    '783_787': 43.4681,
    '783_788': 43.5845,
    '783_789': 43.6952,
    '783_790': 43.8056,
    '783_791': 43.9011,
    '783_792': 43.9753,
    '783_793': 44.0148,
    '783_794': 44.0089,
    '783_795': 43.9852,
    '783_796': 43.95,
    '784_354': 30.7616,
    '784_355': 30.8182,
    '784_356': 30.8732,
    '784_357': 30.9283,
    '784_358': 30.9794,
    '784_359': 31.0282,
    '784_360': 31.0761,
    '784_361': 31.1232,
    '784_362': 31.1702,
    '784_363': 31.2173,
    '784_364': 31.2685,
    '784_365': 31.2867,
    '784_366': 31.3132,
    '784_367': 31.3398,
    '784_368': 31.3661,
    '784_369': 31.3936,
    '784_370': 31.424,
    '784_373': 31.3873,
    '784_374': 31.4436,
    '784_375': 31.4958,
    '784_376': 31.5414,
    '784_377': 31.5777,
    '784_378': 31.6076,
    '784_379': 31.6357,
    '784_380': 31.6603,
    '784_381': 31.6893,
    '784_382': 31.7306,
    '784_383': 31.7838,
    '784_384': 31.844,
    '784_385': 31.9097,
    '784_386': 31.9805,
    '784_387': 32.0525,
    '784_388': 32.1039,
    '784_389': 32.1302,
    '784_390': 32.1458,
    '784_391': 32.1529,
    '784_392': 32.1552,
    '784_393': 32.1614,
    '784_394': 32.1751,
    '784_395': 32.1986,
    '784_396': 32.2488,
    '784_397': 32.3016,
    '784_398': 32.3489,
    '784_399': 32.3949,
    '784_400': 32.436,
    '784_401': 32.4515,
    '784_402': 32.4656,
    '784_403': 32.4591,
    '784_404': 32.4464,
    '784_405': 32.4356,
    '784_406': 32.4268,
    '784_407': 32.4325,
    '784_408': 32.4504,
    '784_409': 32.4741,
    '784_410': 32.4969,
    '784_411': 32.5198,
    '784_412': 32.5434,
    '784_413': 32.5701,
    '784_414': 32.6009,
    '784_415': 32.6352,
    '784_416': 32.6707,
    '784_417': 32.7092,
    '784_418': 32.7479,
    '784_419': 32.7823,
    '784_420': 32.8176,
    '784_421': 32.8415,
    '784_422': 32.852,
    '784_423': 32.8627,
    '784_424': 32.86,
    '784_425': 32.8585,
    '784_426': 32.8631,
    '784_427': 32.8689,
    '784_428': 32.8792,
    '784_429': 32.9143,
    '784_430': 32.9408,
    '784_431': 32.9652,
    '784_432': 33.0181,
    '784_433': 33.0784,
    '784_434': 33.1164,
    '784_435': 33.1469,
    '784_436': 33.1813,
    '784_437': 33.1973,
    '784_438': 33.1984,
    '784_439': 33.1874,
    '784_440': 33.1713,
    '784_441': 33.1431,
    '784_442': 33.1071,
    '784_443': 33.079,
    '784_444': 33.0726,
    '784_445': 33.0809,
    '784_446': 33.0904,
    '784_447': 33.091,
    '784_448': 33.0783,
    '784_449': 33.0423,
    '784_450': 32.9805,
    '784_451': 32.8937,
    '784_452': 32.7875,
    '784_453': 32.681,
    '784_454': 32.587,
    '784_455': 32.5131,
    '784_456': 32.4587,
    '784_457': 32.4105,
    '784_458': 32.3593,
    '784_459': 32.2934,
    '784_460': 32.2036,
    '784_461': 32.1075,
    '784_462': 32.0198,
    '784_463': 31.9427,
    '784_464': 31.8694,
    '784_465': 31.8047,
    '784_466': 31.7532,
    '784_467': 31.7151,
    '784_468': 31.6811,
    '784_469': 31.6459,
    '784_470': 31.621,
    '784_471': 31.6157,
    '784_472': 31.5891,
    '784_473': 31.5414,
    '784_474': 31.472,
    '784_475': 31.4188,
    '784_476': 31.3759,
    '784_477': 31.3318,
    '784_478': 31.3022,
    '784_479': 31.2787,
    '784_480': 31.2616,
    '784_481': 31.24,
    '784_482': 31.2292,
    '784_483': 31.2342,
    '784_484': 31.2613,
    '784_485': 31.315,
    '784_486': 31.364,
    '784_487': 31.421,
    '784_488': 31.5169,
    '784_489': 31.6223,
    '784_490': 31.7265,
    '784_491': 31.8299,
    '784_492': 31.9354,
    '784_493': 32.0446,
    '784_494': 32.1582,
    '784_495': 32.2773,
    '784_496': 32.4034,
    '784_497': 32.5352,
    '784_498': 32.6754,
    '784_499': 32.8315,
    '784_500': 33.0097,
    '784_501': 33.1885,
    '784_502': 33.3724,
    '784_503': 33.5516,
    '784_504': 33.7147,
    '784_505': 33.8563,
    '784_506': 33.9778,
    '784_507': 34.0749,
    '784_508': 34.1522,
    '784_509': 34.2416,
    '784_510': 34.33,
    '784_511': 34.4047,
    '784_512': 34.4711,
    '784_513': 34.5424,
    '784_514': 34.6377,
    '784_515': 34.7127,
    '784_516': 34.7775,
    '784_517': 34.8296,
    '784_518': 34.8889,
    '784_519': 34.9563,
    '784_520': 35.0121,
    '784_521': 35.0636,
    '784_522': 35.1154,
    '784_523': 35.1598,
    '784_524': 35.1748,
    '784_525': 35.1812,
    '784_526': 35.1952,
    '784_527': 35.2274,
    '784_528': 35.2642,
    '784_529': 35.2968,
    '784_530': 35.3286,
    '784_531': 35.3628,
    '784_532': 35.3909,
    '784_533': 35.4143,
    '784_534': 35.4367,
    '784_535': 35.4452,
    '784_536': 35.4496,
    '784_537': 35.4524,
    '784_538': 35.4535,
    '784_539': 35.4526,
    '784_540': 35.4429,
    '784_541': 35.4255,
    '784_542': 35.4013,
    '784_543': 35.3705,
    '784_544': 35.3442,
    '784_545': 35.3187,
    '784_546': 35.2888,
    '784_547': 35.2372,
    '784_548': 35.1954,
    '784_549': 35.1681,
    '784_550': 35.1413,
    '784_551': 35.1119,
    '784_552': 35.0884,
    '784_553': 35.0763,
    '784_554': 35.081,
    '784_555': 35.0999,
    '784_556': 35.1311,
    '784_557': 35.171,
    '784_558': 35.2178,
    '784_559': 35.2728,
    '784_560': 35.3347,
    '784_561': 35.4074,
    '784_562': 35.479,
    '784_563': 35.5505,
    '784_564': 35.6046,
    '784_565': 35.6508,
    '784_566': 35.6911,
    '784_567': 35.7271,
    '784_568': 35.7435,
    '784_569': 35.7347,
    '784_570': 35.7114,
    '784_571': 35.6796,
    '784_572': 35.6571,
    '784_573': 35.631,
    '784_574': 35.6065,
    '784_575': 35.5839,
    '784_576': 35.5639,
    '784_577': 35.5435,
    '784_578': 35.5273,
    '784_579': 35.5138,
    '784_580': 35.5123,
    '784_581': 35.5067,
    '784_582': 35.492,
    '784_583': 35.4792,
    '784_584': 35.4674,
    '784_585': 35.4488,
    '784_586': 35.4223,
    '784_587': 35.3856,
    '784_588': 35.3459,
    '784_589': 35.3119,
    '784_590': 35.281,
    '784_591': 35.2423,
    '784_592': 35.2007,
    '784_593': 35.1553,
    '784_594': 35.0905,
    '784_595': 35.0159,
    '784_596': 34.9774,
    '784_597': 34.9127,
    '784_598': 34.853,
    '784_599': 34.7921,
    '784_600': 34.7329,
    '784_601': 34.6802,
    '784_602': 34.6377,
    '784_603': 34.5977,
    '784_604': 34.5595,
    '784_605': 34.5251,
    '784_606': 34.4971,
    '784_607': 34.477,
    '784_608': 34.4645,
    '784_609': 34.4581,
    '784_610': 34.4544,
    '784_611': 34.4484,
    '784_612': 34.4278,
    '784_613': 34.3957,
    '784_614': 34.3609,
    '784_615': 34.3297,
    '784_616': 34.3063,
    '784_617': 34.291,
    '784_618': 34.2857,
    '784_619': 34.286,
    '784_620': 34.2934,
    '784_621': 34.3048,
    '784_622': 34.3256,
    '784_623': 34.3461,
    '784_624': 34.3565,
    '784_625': 34.3595,
    '784_626': 34.36,
    '784_627': 34.3589,
    '784_628': 34.3599,
    '784_629': 34.3817,
    '784_630': 34.419,
    '784_631': 34.4607,
    '784_632': 34.4999,
    '784_633': 34.5374,
    '784_634': 34.5739,
    '784_635': 34.6025,
    '784_636': 34.6271,
    '784_637': 34.6528,
    '784_638': 34.6639,
    '784_639': 34.6599,
    '784_640': 34.6574,
    '784_641': 34.6524,
    '784_642': 34.6669,
    '784_643': 34.6899,
    '784_644': 34.722,
    '784_645': 34.7554,
    '784_646': 34.787,
    '784_647': 34.8156,
    '784_648': 34.84,
    '784_649': 34.8638,
    '784_650': 34.8917,
    '784_651': 34.9294,
    '784_652': 34.9708,
    '784_653': 35.0083,
    '784_654': 35.0326,
    '784_655': 35.0536,
    '784_656': 35.0612,
    '784_657': 35.0734,
    '784_658': 35.0896,
    '784_659': 35.1079,
    '784_660': 35.1274,
    '784_786': 43.3507,
    '784_787': 43.4726,
    '784_788': 43.5943,
    '784_789': 43.7115,
    '784_790': 43.8149,
    '784_791': 43.913,
    '784_792': 43.9982,
    '784_793': 44.0323,
    '784_794': 44.0035,
    '784_795': 43.9676,
    '784_796': 43.9309,
    '785_354': 30.7382,
    '785_355': 30.7956,
    '785_356': 30.8498,
    '785_357': 30.903,
    '785_358': 30.9538,
    '785_359': 31.0025,
    '785_360': 31.0498,
    '785_361': 31.096,
    '785_362': 31.1419,
    '785_363': 31.1868,
    '785_364': 31.2321,
    '785_365': 31.2593,
    '785_366': 31.2835,
    '785_367': 31.3088,
    '785_368': 31.3338,
    '785_369': 31.3591,
    '785_370': 31.3867,
    '785_373': 31.3552,
    '785_374': 31.4209,
    '785_375': 31.4728,
    '785_376': 31.5131,
    '785_377': 31.5452,
    '785_378': 31.5718,
    '785_379': 31.5978,
    '785_380': 31.6261,
    '785_381': 31.6541,
    '785_382': 31.6981,
    '785_383': 31.7589,
    '785_384': 31.8197,
    '785_385': 31.8857,
    '785_386': 31.9538,
    '785_387': 32.021,
    '785_388': 32.0711,
    '785_389': 32.1032,
    '785_390': 32.1307,
    '785_391': 32.1439,
    '785_392': 32.1519,
    '785_393': 32.1644,
    '785_394': 32.1893,
    '785_395': 32.2094,
    '785_396': 32.2583,
    '785_397': 32.3131,
    '785_398': 32.3441,
    '785_399': 32.3674,
    '785_400': 32.395,
    '785_401': 32.4052,
    '785_402': 32.4164,
    '785_403': 32.404,
    '785_404': 32.3931,
    '785_405': 32.387,
    '785_406': 32.3863,
    '785_407': 32.3978,
    '785_408': 32.4203,
    '785_409': 32.4483,
    '785_410': 32.4743,
    '785_411': 32.4991,
    '785_412': 32.5235,
    '785_413': 32.5504,
    '785_414': 32.5828,
    '785_415': 32.6186,
    '785_416': 32.6558,
    '785_417': 32.6953,
    '785_418': 32.7363,
    '785_419': 32.7758,
    '785_420': 32.8179,
    '785_421': 32.8505,
    '785_422': 32.871,
    '785_423': 32.8882,
    '785_424': 32.8954,
    '785_425': 32.9026,
    '785_426': 32.9125,
    '785_427': 32.9129,
    '785_428': 32.9266,
    '785_429': 32.967,
    '785_430': 32.9921,
    '785_431': 33.0258,
    '785_432': 33.0697,
    '785_433': 33.1237,
    '785_434': 33.1594,
    '785_435': 33.1842,
    '785_436': 33.2022,
    '785_437': 33.2061,
    '785_438': 33.1918,
    '785_439': 33.1729,
    '785_440': 33.1512,
    '785_441': 33.1197,
    '785_442': 33.0781,
    '785_443': 33.0512,
    '785_444': 33.0511,
    '785_445': 33.0711,
    '785_446': 33.0944,
    '785_447': 33.106,
    '785_448': 33.0969,
    '785_449': 33.0562,
    '785_450': 33.0003,
    '785_451': 32.9306,
    '785_452': 32.8264,
    '785_453': 32.7082,
    '785_454': 32.5963,
    '785_455': 32.5066,
    '785_456': 32.4402,
    '785_457': 32.3906,
    '785_458': 32.346,
    '785_459': 32.289,
    '785_460': 32.2178,
    '785_461': 32.1331,
    '785_462': 32.047,
    '785_463': 31.9676,
    '785_464': 31.8894,
    '785_465': 31.8157,
    '785_466': 31.7635,
    '785_467': 31.7267,
    '785_468': 31.6919,
    '785_469': 31.6581,
    '785_470': 31.6258,
    '785_471': 31.6132,
    '785_472': 31.596,
    '785_473': 31.5479,
    '785_474': 31.4814,
    '785_475': 31.428,
    '785_476': 31.386,
    '785_477': 31.3502,
    '785_478': 31.3241,
    '785_479': 31.3031,
    '785_480': 31.2882,
    '785_481': 31.2696,
    '785_482': 31.2608,
    '785_483': 31.2645,
    '785_484': 31.2866,
    '785_485': 31.337,
    '785_486': 31.3804,
    '785_487': 31.4546,
    '785_488': 31.5607,
    '785_489': 31.6591,
    '785_490': 31.7579,
    '785_491': 31.8588,
    '785_492': 31.9631,
    '785_493': 32.0714,
    '785_494': 32.1837,
    '785_495': 32.301,
    '785_496': 32.4252,
    '785_497': 32.5557,
    '785_498': 32.6951,
    '785_499': 32.851,
    '785_500': 33.0217,
    '785_501': 33.1895,
    '785_502': 33.3702,
    '785_503': 33.5518,
    '785_504': 33.7269,
    '785_505': 33.8694,
    '785_506': 33.9965,
    '785_507': 34.1056,
    '785_508': 34.197,
    '785_509': 34.2725,
    '785_510': 34.3432,
    '785_511': 34.4177,
    '785_512': 34.4919,
    '785_513': 34.5774,
    '785_514': 34.682,
    '785_515': 34.762,
    '785_516': 34.8275,
    '785_517': 34.8882,
    '785_518': 34.9483,
    '785_519': 35.0151,
    '785_520': 35.0776,
    '785_521': 35.1324,
    '785_522': 35.1823,
    '785_523': 35.2187,
    '785_524': 35.2235,
    '785_525': 35.2337,
    '785_526': 35.2472,
    '785_527': 35.2673,
    '785_528': 35.2977,
    '785_529': 35.3301,
    '785_530': 35.362,
    '785_531': 35.3953,
    '785_532': 35.4166,
    '785_533': 35.442,
    '785_534': 35.4608,
    '785_535': 35.4679,
    '785_536': 35.4756,
    '785_537': 35.4775,
    '785_538': 35.4766,
    '785_539': 35.4784,
    '785_540': 35.4694,
    '785_541': 35.452,
    '785_542': 35.4262,
    '785_543': 35.3952,
    '785_544': 35.3658,
    '785_545': 35.34,
    '785_546': 35.3063,
    '785_547': 35.2625,
    '785_548': 35.2277,
    '785_549': 35.2034,
    '785_550': 35.1662,
    '785_551': 35.1337,
    '785_552': 35.1122,
    '785_553': 35.105,
    '785_554': 35.1131,
    '785_555': 35.1341,
    '785_556': 35.1675,
    '785_557': 35.2124,
    '785_558': 35.2663,
    '785_559': 35.3258,
    '785_560': 35.3869,
    '785_561': 35.4601,
    '785_562': 35.5323,
    '785_563': 35.6164,
    '785_564': 35.6692,
    '785_565': 35.7166,
    '785_566': 35.7587,
    '785_567': 35.7908,
    '785_568': 35.7975,
    '785_569': 35.7775,
    '785_570': 35.7489,
    '785_571': 35.7142,
    '785_572': 35.6811,
    '785_573': 35.6441,
    '785_574': 35.609,
    '785_575': 35.5787,
    '785_576': 35.5578,
    '785_577': 35.5355,
    '785_578': 35.5242,
    '785_579': 35.5099,
    '785_580': 35.5013,
    '785_581': 35.4865,
    '785_582': 35.4812,
    '785_583': 35.4842,
    '785_584': 35.4787,
    '785_585': 35.4524,
    '785_586': 35.4254,
    '785_587': 35.3858,
    '785_588': 35.3418,
    '785_589': 35.31,
    '785_590': 35.283,
    '785_591': 35.2463,
    '785_592': 35.2048,
    '785_593': 35.157,
    '785_594': 35.097,
    '785_595': 35.0261,
    '785_596': 34.9898,
    '785_597': 34.9323,
    '785_598': 34.8803,
    '785_599': 34.8217,
    '785_600': 34.7645,
    '785_601': 34.7137,
    '785_602': 34.6731,
    '785_603': 34.6373,
    '785_604': 34.6018,
    '785_605': 34.5682,
    '785_606': 34.5408,
    '785_607': 34.5202,
    '785_608': 34.5063,
    '785_609': 34.4988,
    '785_610': 34.4966,
    '785_611': 34.4933,
    '785_612': 34.4802,
    '785_613': 34.4519,
    '785_614': 34.42,
    '785_615': 34.3912,
    '785_616': 34.3684,
    '785_617': 34.3554,
    '785_618': 34.3563,
    '785_619': 34.3673,
    '785_620': 34.384,
    '785_621': 34.3989,
    '785_622': 34.4155,
    '785_623': 34.4372,
    '785_624': 34.4548,
    '785_625': 34.4549,
    '785_626': 34.4551,
    '785_627': 34.4616,
    '785_628': 34.4751,
    '785_629': 34.499,
    '785_630': 34.5319,
    '785_631': 34.5696,
    '785_632': 34.6087,
    '785_633': 34.6465,
    '785_634': 34.6815,
    '785_635': 34.7113,
    '785_636': 34.7393,
    '785_637': 34.7697,
    '785_638': 34.7779,
    '785_639': 34.7708,
    '785_640': 34.7623,
    '785_641': 34.7589,
    '785_642': 34.7727,
    '785_643': 34.7909,
    '785_644': 34.8162,
    '785_645': 34.8429,
    '785_646': 34.8667,
    '785_647': 34.8866,
    '785_648': 34.9087,
    '785_649': 34.9299,
    '785_650': 34.9523,
    '785_651': 34.9839,
    '785_652': 35.0225,
    '785_653': 35.0652,
    '785_654': 35.096,
    '785_655': 35.1217,
    '785_656': 35.1266,
    '785_657': 35.1359,
    '785_658': 35.1487,
    '785_659': 35.164,
    '785_660': 35.181,
    '785_786': 43.3513,
    '785_787': 43.4746,
    '785_788': 43.5947,
    '785_789': 43.7074,
    '785_790': 43.8101,
    '785_791': 43.91,
    '785_792': 44.0035,
    '785_793': 44.0332,
    '785_794': 43.9905,
    '785_795': 43.9382,
    '785_796': 43.9007,
    '786_354': 30.715,
    '786_355': 30.7716,
    '786_356': 30.8258,
    '786_357': 30.8779,
    '786_358': 30.9282,
    '786_359': 30.9768,
    '786_360': 31.0239,
    '786_361': 31.0698,
    '786_362': 31.1143,
    '786_363': 31.1582,
    '786_364': 31.2002,
    '786_365': 31.2339,
    '786_366': 31.2562,
    '786_367': 31.2809,
    '786_368': 31.3048,
    '786_369': 31.3285,
    '786_370': 31.3535,
    '786_373': 31.3275,
    '786_374': 31.4059,
    '786_375': 31.4532,
    '786_376': 31.4866,
    '786_377': 31.5144,
    '786_378': 31.5373,
    '786_379': 31.559,
    '786_380': 31.5989,
    '786_381': 31.6388,
    '786_382': 31.6842,
    '786_383': 31.7384,
    '786_384': 31.7978,
    '786_385': 31.8628,
    '786_386': 31.931,
    '786_387': 31.9956,
    '786_388': 32.0411,
    '786_389': 32.0784,
    '786_390': 32.1119,
    '786_391': 32.133,
    '786_392': 32.1477,
    '786_393': 32.1646,
    '786_394': 32.1947,
    '786_395': 32.2125,
    '786_396': 32.2612,
    '786_397': 32.3096,
    '786_398': 32.3321,
    '786_399': 32.3405,
    '786_400': 32.3548,
    '786_401': 32.3692,
    '786_402': 32.3742,
    '786_403': 32.36,
    '786_404': 32.3511,
    '786_405': 32.3479,
    '786_406': 32.3529,
    '786_407': 32.367,
    '786_408': 32.3937,
    '786_409': 32.4238,
    '786_410': 32.4499,
    '786_411': 32.4754,
    '786_412': 32.4999,
    '786_413': 32.5273,
    '786_414': 32.5608,
    '786_415': 32.5972,
    '786_416': 32.6349,
    '786_417': 32.6753,
    '786_418': 32.7185,
    '786_419': 32.7643,
    '786_420': 32.8098,
    '786_421': 32.8478,
    '786_422': 32.8863,
    '786_423': 32.9122,
    '786_424': 32.9268,
    '786_425': 32.9359,
    '786_426': 32.9427,
    '786_427': 32.9498,
    '786_428': 32.963,
    '786_429': 33.0022,
    '786_430': 33.0299,
    '786_431': 33.0755,
    '786_432': 33.1186,
    '786_433': 33.16,
    '786_434': 33.1873,
    '786_435': 33.2068,
    '786_436': 33.216,
    '786_437': 33.2075,
    '786_438': 33.1838,
    '786_439': 33.154,
    '786_440': 33.1269,
    '786_441': 33.0926,
    '786_442': 33.0527,
    '786_443': 33.0279,
    '786_444': 33.0331,
    '786_445': 33.0619,
    '786_446': 33.0914,
    '786_447': 33.1056,
    '786_448': 33.0942,
    '786_449': 33.0665,
    '786_450': 33.0129,
    '786_451': 32.9388,
    '786_452': 32.8416,
    '786_453': 32.7261,
    '786_454': 32.6087,
    '786_455': 32.5081,
    '786_456': 32.4232,
    '786_457': 32.361,
    '786_458': 32.3131,
    '786_459': 32.2576,
    '786_460': 32.2024,
    '786_461': 32.1383,
    '786_462': 32.0628,
    '786_463': 31.9819,
    '786_464': 31.9005,
    '786_465': 31.8234,
    '786_466': 31.7665,
    '786_467': 31.7316,
    '786_468': 31.699,
    '786_469': 31.6662,
    '786_470': 31.6365,
    '786_471': 31.6077,
    '786_472': 31.5903,
    '786_473': 31.5567,
    '786_474': 31.5042,
    '786_475': 31.4535,
    '786_476': 31.4067,
    '786_477': 31.3717,
    '786_478': 31.3458,
    '786_479': 31.3267,
    '786_480': 31.3132,
    '786_481': 31.2999,
    '786_482': 31.293,
    '786_483': 31.2986,
    '786_484': 31.3196,
    '786_485': 31.3615,
    '786_486': 31.4098,
    '786_487': 31.4969,
    '786_488': 31.5921,
    '786_489': 31.6872,
    '786_490': 31.7843,
    '786_491': 31.8845,
    '786_492': 31.9886,
    '786_493': 32.0967,
    '786_494': 32.2066,
    '786_495': 32.3222,
    '786_496': 32.4448,
    '786_497': 32.5746,
    '786_498': 32.7129,
    '786_499': 32.8673,
    '786_500': 33.032,
    '786_501': 33.1948,
    '786_502': 33.3668,
    '786_503': 33.5486,
    '786_504': 33.734,
    '786_505': 33.8898,
    '786_506': 34.0274,
    '786_507': 34.1411,
    '786_508': 34.2232,
    '786_509': 34.2893,
    '786_510': 34.3601,
    '786_511': 34.4351,
    '786_512': 34.5118,
    '786_513': 34.6037,
    '786_514': 34.7198,
    '786_515': 34.8077,
    '786_516': 34.8698,
    '786_517': 34.9385,
    '786_518': 34.997,
    '786_519': 35.0639,
    '786_520': 35.1364,
    '786_521': 35.196,
    '786_522': 35.2429,
    '786_523': 35.272,
    '786_524': 35.274,
    '786_525': 35.2934,
    '786_526': 35.3018,
    '786_527': 35.3129,
    '786_528': 35.3367,
    '786_529': 35.3695,
    '786_530': 35.4028,
    '786_531': 35.4292,
    '786_532': 35.4496,
    '786_533': 35.4681,
    '786_534': 35.4829,
    '786_535': 35.4955,
    '786_536': 35.4997,
    '786_537': 35.502,
    '786_538': 35.5016,
    '786_539': 35.5052,
    '786_540': 35.4997,
    '786_541': 35.4882,
    '786_542': 35.4593,
    '786_543': 35.4237,
    '786_544': 35.3913,
    '786_545': 35.3615,
    '786_546': 35.3264,
    '786_547': 35.2874,
    '786_548': 35.2552,
    '786_549': 35.2366,
    '786_550': 35.1945,
    '786_551': 35.1584,
    '786_552': 35.139,
    '786_553': 35.136,
    '786_554': 35.1482,
    '786_555': 35.1724,
    '786_556': 35.2091,
    '786_557': 35.2618,
    '786_558': 35.3167,
    '786_559': 35.3802,
    '786_560': 35.4462,
    '786_561': 35.5234,
    '786_562': 35.5962,
    '786_563': 35.669,
    '786_564': 35.7255,
    '786_565': 35.7817,
    '786_566': 35.8281,
    '786_567': 35.8555,
    '786_568': 35.8556,
    '786_569': 35.8207,
    '786_570': 35.7778,
    '786_571': 35.7331,
    '786_572': 35.6889,
    '786_573': 35.6461,
    '786_574': 35.6044,
    '786_575': 35.5698,
    '786_576': 35.5417,
    '786_577': 35.5207,
    '786_578': 35.5069,
    '786_579': 35.4937,
    '786_580': 35.4834,
    '786_581': 35.4737,
    '786_582': 35.47,
    '786_583': 35.4676,
    '786_584': 35.4554,
    '786_585': 35.4319,
    '786_586': 35.4185,
    '786_587': 35.3793,
    '786_588': 35.3388,
    '786_589': 35.3005,
    '786_590': 35.2694,
    '786_591': 35.2344,
    '786_592': 35.1941,
    '786_593': 35.1457,
    '786_594': 35.0857,
    '786_595': 35.019,
    '786_596': 34.9923,
    '786_597': 34.9385,
    '786_598': 34.8914,
    '786_599': 34.8383,
    '786_600': 34.7855,
    '786_601': 34.7379,
    '786_602': 34.7,
    '786_603': 34.6668,
    '786_604': 34.6349,
    '786_605': 34.6047,
    '786_606': 34.5774,
    '786_607': 34.5564,
    '786_608': 34.5411,
    '786_609': 34.5323,
    '786_610': 34.5323,
    '786_611': 34.5323,
    '786_612': 34.525,
    '786_613': 34.5028,
    '786_614': 34.4763,
    '786_615': 34.4517,
    '786_616': 34.434,
    '786_617': 34.4247,
    '786_618': 34.4274,
    '786_619': 34.4452,
    '786_620': 34.4686,
    '786_621': 34.4926,
    '786_622': 34.5147,
    '786_623': 34.5336,
    '786_624': 34.5502,
    '786_625': 34.5549,
    '786_626': 34.5671,
    '786_627': 34.5788,
    '786_628': 34.5993,
    '786_629': 34.6231,
    '786_630': 34.652,
    '786_631': 34.6857,
    '786_632': 34.7252,
    '786_633': 34.7631,
    '786_634': 34.7958,
    '786_635': 34.8249,
    '786_636': 34.8508,
    '786_637': 34.8741,
    '786_638': 34.8834,
    '786_639': 34.8841,
    '786_640': 34.8775,
    '786_641': 34.8793,
    '786_642': 34.8927,
    '786_643': 34.9013,
    '786_644': 34.9169,
    '786_645': 34.935,
    '786_646': 34.9517,
    '786_647': 34.9644,
    '786_648': 34.9816,
    '786_649': 34.9999,
    '786_650': 35.018,
    '786_651': 35.0468,
    '786_652': 35.0831,
    '786_653': 35.1221,
    '786_654': 35.1522,
    '786_655': 35.1757,
    '786_656': 35.1876,
    '786_657': 35.1967,
    '786_658': 35.207,
    '786_659': 35.2191,
    '786_660': 35.2322,
    '786_786': 43.349,
    '786_787': 43.4723,
    '786_788': 43.5877,
    '786_789': 43.6917,
    '786_790': 43.7916,
    '786_791': 43.8849,
    '786_792': 43.9644,
    '786_793': 43.9935,
    '786_794': 43.9556,
    '786_795': 43.8965,
    '786_796': 43.8614,
    '787_354': 30.6994,
    '787_355': 30.7514,
    '787_356': 30.8028,
    '787_357': 30.8535,
    '787_358': 30.9031,
    '787_359': 30.9517,
    '787_360': 30.9988,
    '787_361': 31.0446,
    '787_362': 31.0889,
    '787_363': 31.1323,
    '787_364': 31.1739,
    '787_365': 31.2064,
    '787_366': 31.2318,
    '787_367': 31.2568,
    '787_368': 31.2809,
    '787_369': 31.3046,
    '787_370': 31.33,
    '787_373': 31.3215,
    '787_374': 31.392,
    '787_375': 31.4334,
    '787_376': 31.463,
    '787_377': 31.4927,
    '787_378': 31.5212,
    '787_379': 31.5527,
    '787_380': 31.5926,
    '787_381': 31.6349,
    '787_382': 31.6769,
    '787_383': 31.7231,
    '787_384': 31.7789,
    '787_385': 31.8423,
    '787_386': 31.9103,
    '787_387': 31.9748,
    '787_388': 32.0229,
    '787_389': 32.0597,
    '787_390': 32.0959,
    '787_391': 32.1219,
    '787_392': 32.1379,
    '787_393': 32.1599,
    '787_394': 32.19,
    '787_395': 32.2076,
    '787_396': 32.2516,
    '787_397': 32.2916,
    '787_398': 32.3123,
    '787_399': 32.3182,
    '787_400': 32.3284,
    '787_401': 32.3428,
    '787_402': 32.3417,
    '787_403': 32.3292,
    '787_404': 32.3215,
    '787_405': 32.3215,
    '787_406': 32.3287,
    '787_407': 32.3443,
    '787_408': 32.3684,
    '787_409': 32.3975,
    '787_410': 32.4219,
    '787_411': 32.4463,
    '787_412': 32.4718,
    '787_413': 32.5003,
    '787_414': 32.5345,
    '787_415': 32.5711,
    '787_416': 32.6093,
    '787_417': 32.6508,
    '787_418': 32.6949,
    '787_419': 32.7423,
    '787_420': 32.7902,
    '787_421': 32.8382,
    '787_422': 32.8903,
    '787_423': 32.9277,
    '787_424': 32.9506,
    '787_425': 32.9616,
    '787_426': 32.9718,
    '787_427': 32.9774,
    '787_428': 32.9889,
    '787_429': 33.0243,
    '787_430': 33.0661,
    '787_431': 33.1155,
    '787_432': 33.1548,
    '787_433': 33.1835,
    '787_434': 33.205,
    '787_435': 33.2185,
    '787_436': 33.2204,
    '787_437': 33.2052,
    '787_438': 33.1729,
    '787_439': 33.1344,
    '787_440': 33.1028,
    '787_441': 33.0666,
    '787_442': 33.0287,
    '787_443': 33.0079,
    '787_444': 33.0159,
    '787_445': 33.0484,
    '787_446': 33.079,
    '787_447': 33.0899,
    '787_448': 33.0744,
    '787_449': 33.04,
    '787_450': 32.9934,
    '787_451': 32.9315,
    '787_452': 32.8458,
    '787_453': 32.7313,
    '787_454': 32.6128,
    '787_455': 32.5074,
    '787_456': 32.4118,
    '787_457': 32.3358,
    '787_458': 32.2748,
    '787_459': 32.215,
    '787_460': 32.1596,
    '787_461': 32.1125,
    '787_462': 32.0546,
    '787_463': 31.9746,
    '787_464': 31.8904,
    '787_465': 31.8163,
    '787_466': 31.7602,
    '787_467': 31.7318,
    '787_468': 31.7061,
    '787_469': 31.6782,
    '787_470': 31.6532,
    '787_471': 31.6175,
    '787_472': 31.5824,
    '787_473': 31.5519,
    '787_474': 31.5112,
    '787_475': 31.4746,
    '787_476': 31.4314,
    '787_477': 31.3937,
    '787_478': 31.367,
    '787_479': 31.3478,
    '787_480': 31.3347,
    '787_481': 31.326,
    '787_482': 31.3238,
    '787_483': 31.333,
    '787_484': 31.3545,
    '787_485': 31.3871,
    '787_486': 31.4512,
    '787_487': 31.5304,
    '787_488': 31.6181,
    '787_489': 31.7109,
    '787_490': 31.8076,
    '787_491': 31.9078,
    '787_492': 32.0115,
    '787_493': 32.118,
    '787_494': 32.2271,
    '787_495': 32.3413,
    '787_496': 32.4625,
    '787_497': 32.5913,
    '787_498': 32.7291,
    '787_499': 32.8796,
    '787_500': 33.0372,
    '787_501': 33.2002,
    '787_502': 33.3694,
    '787_503': 33.5518,
    '787_504': 33.7476,
    '787_505': 33.9247,
    '787_506': 34.0603,
    '787_507': 34.1628,
    '787_508': 34.2359,
    '787_509': 34.3071,
    '787_510': 34.3773,
    '787_511': 34.4509,
    '787_512': 34.5351,
    '787_513': 34.6295,
    '787_514': 34.7529,
    '787_515': 34.8455,
    '787_516': 34.9185,
    '787_517': 34.99,
    '787_518': 35.0469,
    '787_519': 35.1063,
    '787_520': 35.1777,
    '787_521': 35.2438,
    '787_522': 35.2942,
    '787_523': 35.3204,
    '787_524': 35.3286,
    '787_525': 35.3474,
    '787_526': 35.3618,
    '787_527': 35.3696,
    '787_528': 35.3851,
    '787_529': 35.4118,
    '787_530': 35.4387,
    '787_531': 35.4592,
    '787_532': 35.4743,
    '787_533': 35.4848,
    '787_534': 35.4947,
    '787_535': 35.5117,
    '787_536': 35.5206,
    '787_537': 35.5195,
    '787_538': 35.5147,
    '787_539': 35.5182,
    '787_540': 35.5271,
    '787_541': 35.514,
    '787_542': 35.482,
    '787_543': 35.4495,
    '787_544': 35.419,
    '787_545': 35.3915,
    '787_546': 35.3547,
    '787_547': 35.319,
    '787_548': 35.2926,
    '787_549': 35.2685,
    '787_550': 35.2247,
    '787_551': 35.1953,
    '787_552': 35.1804,
    '787_553': 35.18,
    '787_554': 35.1936,
    '787_555': 35.2223,
    '787_556': 35.2644,
    '787_557': 35.3184,
    '787_558': 35.3772,
    '787_559': 35.4438,
    '787_560': 35.5155,
    '787_561': 35.5848,
    '787_562': 35.661,
    '787_563': 35.732,
    '787_564': 35.7951,
    '787_565': 35.8537,
    '787_566': 35.9003,
    '787_567': 35.9197,
    '787_568': 35.9057,
    '787_569': 35.8566,
    '787_570': 35.803,
    '787_571': 35.7473,
    '787_572': 35.6944,
    '787_573': 35.6461,
    '787_574': 35.6012,
    '787_575': 35.569,
    '787_576': 35.5395,
    '787_577': 35.5113,
    '787_578': 35.486,
    '787_579': 35.4744,
    '787_580': 35.4622,
    '787_581': 35.4548,
    '787_582': 35.4502,
    '787_583': 35.4401,
    '787_584': 35.4257,
    '787_585': 35.4054,
    '787_586': 35.3938,
    '787_587': 35.3572,
    '787_588': 35.3267,
    '787_589': 35.2856,
    '787_590': 35.2496,
    '787_591': 35.2137,
    '787_592': 35.1744,
    '787_593': 35.1259,
    '787_594': 35.063,
    '787_595': 34.9974,
    '787_596': 34.974,
    '787_597': 34.9297,
    '787_598': 34.8891,
    '787_599': 34.8457,
    '787_600': 34.8005,
    '787_601': 34.7588,
    '787_602': 34.7249,
    '787_603': 34.6925,
    '787_604': 34.6623,
    '787_605': 34.6345,
    '787_606': 34.6093,
    '787_607': 34.5887,
    '787_608': 34.574,
    '787_609': 34.5668,
    '787_610': 34.567,
    '787_611': 34.5684,
    '787_612': 34.5655,
    '787_613': 34.5497,
    '787_614': 34.5308,
    '787_615': 34.5144,
    '787_616': 34.5017,
    '787_617': 34.4969,
    '787_618': 34.5031,
    '787_619': 34.5237,
    '787_620': 34.5507,
    '787_621': 34.5802,
    '787_622': 34.61,
    '787_623': 34.6312,
    '787_624': 34.6534,
    '787_625': 34.6711,
    '787_626': 34.6929,
    '787_627': 34.7047,
    '787_628': 34.7276,
    '787_629': 34.7526,
    '787_630': 34.7805,
    '787_631': 34.8128,
    '787_632': 34.8536,
    '787_633': 34.8926,
    '787_634': 34.9234,
    '787_635': 34.9468,
    '787_636': 34.9739,
    '787_637': 34.9848,
    '787_638': 34.9881,
    '787_639': 34.9924,
    '787_640': 34.995,
    '787_641': 35.0157,
    '787_642': 35.0168,
    '787_643': 35.0101,
    '787_644': 35.015,
    '787_645': 35.0227,
    '787_646': 35.0309,
    '787_647': 35.0384,
    '787_648': 35.052,
    '787_649': 35.0665,
    '787_650': 35.0825,
    '787_651': 35.11,
    '787_652': 35.1424,
    '787_653': 35.1752,
    '787_654': 35.2034,
    '787_655': 35.2261,
    '787_656': 35.2425,
    '787_657': 35.2527,
    '787_658': 35.2631,
    '787_659': 35.2747,
    '787_660': 35.2871,
    '787_786': 43.332,
    '787_787': 43.4602,
    '787_788': 43.5736,
    '787_789': 43.6659,
    '787_790': 43.7638,
    '787_791': 43.8451,
    '787_792': 43.8928,
    '787_793': 43.9141,
    '787_794': 43.8916,
    '787_795': 43.8514,
    '787_796': 43.821,
    '788_354': 30.6762,
    '788_355': 30.7277,
    '788_356': 30.7783,
    '788_357': 30.8281,
    '788_358': 30.8775,
    '788_359': 30.9261,
    '788_360': 30.9738,
    '788_361': 31.02,
    '788_362': 31.0646,
    '788_363': 31.108,
    '788_364': 31.1499,
    '788_365': 31.1805,
    '788_366': 31.2094,
    '788_367': 31.2361,
    '788_368': 31.2617,
    '788_369': 31.2869,
    '788_370': 31.3144,
    '788_373': 31.3164,
    '788_374': 31.3722,
    '788_375': 31.4116,
    '788_376': 31.4428,
    '788_377': 31.4742,
    '788_378': 31.5073,
    '788_379': 31.5429,
    '788_380': 31.5834,
    '788_381': 31.6279,
    '788_382': 31.6687,
    '788_383': 31.7113,
    '788_384': 31.7656,
    '788_385': 31.8277,
    '788_386': 31.8929,
    '788_387': 31.9546,
    '788_388': 32.0085,
    '788_389': 32.0485,
    '788_390': 32.0848,
    '788_391': 32.1138,
    '788_392': 32.1329,
    '788_393': 32.1567,
    '788_394': 32.184,
    '788_395': 32.2028,
    '788_396': 32.2351,
    '788_397': 32.2709,
    '788_398': 32.2935,
    '788_399': 32.3003,
    '788_400': 32.3121,
    '788_401': 32.3226,
    '788_402': 32.3224,
    '788_403': 32.315,
    '788_404': 32.3104,
    '788_405': 32.3044,
    '788_406': 32.3098,
    '788_407': 32.3246,
    '788_408': 32.3457,
    '788_409': 32.3689,
    '788_410': 32.3919,
    '788_411': 32.4156,
    '788_412': 32.4408,
    '788_413': 32.4682,
    '788_414': 32.5007,
    '788_415': 32.5381,
    '788_416': 32.577,
    '788_417': 32.619,
    '788_418': 32.6648,
    '788_419': 32.7151,
    '788_420': 32.7693,
    '788_421': 32.8284,
    '788_422': 32.8873,
    '788_423': 32.9262,
    '788_424': 32.9583,
    '788_425': 32.9753,
    '788_426': 32.9977,
    '788_427': 33.0088,
    '788_428': 33.02,
    '788_429': 33.0557,
    '788_430': 33.1036,
    '788_431': 33.1453,
    '788_432': 33.1732,
    '788_433': 33.1939,
    '788_434': 33.2147,
    '788_435': 33.229,
    '788_436': 33.2232,
    '788_437': 33.199,
    '788_438': 33.1615,
    '788_439': 33.1176,
    '788_440': 33.0821,
    '788_441': 33.0439,
    '788_442': 33.0076,
    '788_443': 32.9891,
    '788_444': 32.996,
    '788_445': 33.025,
    '788_446': 33.0501,
    '788_447': 33.0496,
    '788_448': 33.0289,
    '788_449': 32.9908,
    '788_450': 32.9525,
    '788_451': 32.9059,
    '788_452': 32.8304,
    '788_453': 32.725,
    '788_454': 32.6074,
    '788_455': 32.5016,
    '788_456': 32.4025,
    '788_457': 32.3158,
    '788_458': 32.2382,
    '788_459': 32.1679,
    '788_460': 32.1047,
    '788_461': 32.0588,
    '788_462': 32.0178,
    '788_463': 31.9531,
    '788_464': 31.8724,
    '788_465': 31.7969,
    '788_466': 31.7392,
    '788_467': 31.7218,
    '788_468': 31.7124,
    '788_469': 31.689,
    '788_470': 31.6632,
    '788_471': 31.6267,
    '788_472': 31.59,
    '788_473': 31.5534,
    '788_474': 31.5167,
    '788_475': 31.4828,
    '788_476': 31.4454,
    '788_477': 31.4114,
    '788_478': 31.3848,
    '788_479': 31.366,
    '788_480': 31.3538,
    '788_481': 31.3484,
    '788_482': 31.351,
    '788_483': 31.3627,
    '788_484': 31.3843,
    '788_485': 31.4207,
    '788_486': 31.4853,
    '788_487': 31.5572,
    '788_488': 31.6402,
    '788_489': 31.7314,
    '788_490': 31.8285,
    '788_491': 31.9293,
    '788_492': 32.0325,
    '788_493': 32.1372,
    '788_494': 32.2452,
    '788_495': 32.3581,
    '788_496': 32.4783,
    '788_497': 32.6069,
    '788_498': 32.7467,
    '788_499': 32.8929,
    '788_500': 33.0464,
    '788_501': 33.2097,
    '788_502': 33.3854,
    '788_503': 33.5697,
    '788_504': 33.7647,
    '788_505': 33.953,
    '788_506': 34.0869,
    '788_507': 34.1788,
    '788_508': 34.248,
    '788_509': 34.3095,
    '788_510': 34.3781,
    '788_511': 34.4547,
    '788_512': 34.547,
    '788_513': 34.6586,
    '788_514': 34.7843,
    '788_515': 34.884,
    '788_516': 34.9689,
    '788_517': 35.0423,
    '788_518': 35.0961,
    '788_519': 35.1522,
    '788_520': 35.2173,
    '788_521': 35.2815,
    '788_522': 35.334,
    '788_523': 35.3642,
    '788_524': 35.3805,
    '788_525': 35.4002,
    '788_526': 35.4245,
    '788_527': 35.4265,
    '788_528': 35.4356,
    '788_529': 35.4552,
    '788_530': 35.474,
    '788_531': 35.4868,
    '788_532': 35.4961,
    '788_533': 35.5011,
    '788_534': 35.5107,
    '788_535': 35.5252,
    '788_536': 35.5357,
    '788_537': 35.5383,
    '788_538': 35.5358,
    '788_539': 35.5398,
    '788_540': 35.5466,
    '788_541': 35.5372,
    '788_542': 35.5142,
    '788_543': 35.4845,
    '788_544': 35.4526,
    '788_545': 35.421,
    '788_546': 35.3862,
    '788_547': 35.354,
    '788_548': 35.3301,
    '788_549': 35.2973,
    '788_550': 35.2649,
    '788_551': 35.2421,
    '788_552': 35.2307,
    '788_553': 35.2326,
    '788_554': 35.2464,
    '788_555': 35.2792,
    '788_556': 35.324,
    '788_557': 35.3774,
    '788_558': 35.4368,
    '788_559': 35.5055,
    '788_560': 35.5782,
    '788_561': 35.652,
    '788_562': 35.729,
    '788_563': 35.8023,
    '788_564': 35.8694,
    '788_565': 35.9276,
    '788_566': 35.972,
    '788_567': 35.9884,
    '788_568': 35.9636,
    '788_569': 35.8949,
    '788_570': 35.826,
    '788_571': 35.7626,
    '788_572': 35.7036,
    '788_573': 35.6547,
    '788_574': 35.6108,
    '788_575': 35.5677,
    '788_576': 35.5266,
    '788_577': 35.4886,
    '788_578': 35.4583,
    '788_579': 35.4519,
    '788_580': 35.4328,
    '788_581': 35.4249,
    '788_582': 35.4193,
    '788_583': 35.4101,
    '788_584': 35.3946,
    '788_585': 35.3755,
    '788_586': 35.3598,
    '788_587': 35.3285,
    '788_588': 35.2963,
    '788_589': 35.2572,
    '788_590': 35.2212,
    '788_591': 35.1852,
    '788_592': 35.1476,
    '788_593': 35.0977,
    '788_594': 35.0284,
    '788_595': 34.9709,
    '788_596': 34.957,
    '788_597': 34.9185,
    '788_598': 34.8846,
    '788_599': 34.8503,
    '788_600': 34.8124,
    '788_601': 34.7747,
    '788_602': 34.7416,
    '788_603': 34.7117,
    '788_604': 34.6839,
    '788_605': 34.6614,
    '788_606': 34.6366,
    '788_607': 34.6173,
    '788_608': 34.6048,
    '788_609': 34.5994,
    '788_610': 34.6004,
    '788_611': 34.6024,
    '788_612': 34.6026,
    '788_613': 34.5922,
    '788_614': 34.5807,
    '788_615': 34.5719,
    '788_616': 34.5661,
    '788_617': 34.5684,
    '788_618': 34.5811,
    '788_619': 34.6027,
    '788_620': 34.6318,
    '788_621': 34.6648,
    '788_622': 34.6955,
    '788_623': 34.7334,
    '788_624': 34.7665,
    '788_625': 34.792,
    '788_626': 34.8124,
    '788_627': 34.838,
    '788_628': 34.8574,
    '788_629': 34.8873,
    '788_630': 34.9175,
    '788_631': 34.9509,
    '788_632': 34.9942,
    '788_633': 35.0344,
    '788_634': 35.0618,
    '788_635': 35.0657,
    '788_636': 35.0887,
    '788_637': 35.0975,
    '788_638': 35.1,
    '788_639': 35.1078,
    '788_640': 35.1063,
    '788_641': 35.1163,
    '788_642': 35.1142,
    '788_643': 35.104,
    '788_644': 35.1019,
    '788_645': 35.1055,
    '788_646': 35.1108,
    '788_647': 35.1171,
    '788_648': 35.1258,
    '788_649': 35.1368,
    '788_650': 35.1519,
    '788_651': 35.1745,
    '788_652': 35.2013,
    '788_653': 35.228,
    '788_654': 35.2524,
    '788_655': 35.2727,
    '788_656': 35.2888,
    '788_657': 35.302,
    '788_658': 35.3146,
    '788_659': 35.3279,
    '788_660': 35.3412,
    '788_786': 43.3024,
    '788_787': 43.4336,
    '788_788': 43.5335,
    '788_789': 43.6226,
    '788_790': 43.7244,
    '788_791': 43.7952,
    '788_792': 43.812,
    '788_793': 43.8252,
    '788_794': 43.8204,
    '788_795': 43.7997,
    '788_796': 43.7768,
    '789_350': 30.4147,
    '789_351': 30.4805,
    '789_352': 30.5397,
    '789_353': 30.5951,
    '789_354': 30.6483,
    '789_355': 30.701,
    '789_356': 30.7516,
    '789_357': 30.8006,
    '789_358': 30.8503,
    '789_359': 30.8997,
    '789_360': 30.9484,
    '789_361': 30.9957,
    '789_362': 31.0411,
    '789_363': 31.0844,
    '789_364': 31.1249,
    '789_365': 31.159,
    '789_366': 31.19,
    '789_367': 31.2189,
    '789_368': 31.2462,
    '789_369': 31.2735,
    '789_370': 31.3032,
    '789_373': 31.2969,
    '789_374': 31.3486,
    '789_375': 31.3898,
    '789_376': 31.4229,
    '789_377': 31.4566,
    '789_378': 31.4921,
    '789_379': 31.5294,
    '789_380': 31.5704,
    '789_381': 31.6147,
    '789_382': 31.6568,
    '789_383': 31.7016,
    '789_384': 31.7553,
    '789_385': 31.8159,
    '789_386': 31.8782,
    '789_387': 31.9392,
    '789_388': 31.9967,
    '789_389': 32.0389,
    '789_390': 32.081,
    '789_391': 32.1147,
    '789_392': 32.1441,
    '789_393': 32.1618,
    '789_394': 32.1848,
    '789_395': 32.2038,
    '789_396': 32.2298,
    '789_397': 32.2604,
    '789_398': 32.2857,
    '789_399': 32.2919,
    '789_400': 32.2983,
    '789_401': 32.3095,
    '789_402': 32.3105,
    '789_403': 32.3098,
    '789_404': 32.3018,
    '789_405': 32.2904,
    '789_406': 32.2941,
    '789_407': 32.3084,
    '789_408': 32.3259,
    '789_409': 32.3446,
    '789_410': 32.365,
    '789_411': 32.3873,
    '789_412': 32.4105,
    '789_413': 32.4362,
    '789_414': 32.4675,
    '789_415': 32.5036,
    '789_416': 32.5424,
    '789_417': 32.5846,
    '789_418': 32.634,
    '789_419': 32.6882,
    '789_420': 32.7476,
    '789_421': 32.8163,
    '789_422': 32.8745,
    '789_423': 32.9156,
    '789_424': 32.956,
    '789_425': 32.9788,
    '789_426': 33.0097,
    '789_427': 33.0317,
    '789_428': 33.0492,
    '789_429': 33.0863,
    '789_430': 33.1329,
    '789_431': 33.1656,
    '789_432': 33.1817,
    '789_433': 33.2014,
    '789_434': 33.2279,
    '789_435': 33.2444,
    '789_436': 33.2313,
    '789_437': 33.199,
    '789_438': 33.1579,
    '789_439': 33.1048,
    '789_440': 33.061,
    '789_441': 33.0262,
    '789_442': 32.9912,
    '789_443': 32.9731,
    '789_444': 32.9784,
    '789_445': 32.9975,
    '789_446': 33.0104,
    '789_447': 32.9997,
    '789_448': 32.9718,
    '789_449': 32.9378,
    '789_450': 32.9093,
    '789_451': 32.8755,
    '789_452': 32.808,
    '789_453': 32.7118,
    '789_454': 32.5981,
    '789_455': 32.4904,
    '789_456': 32.3858,
    '789_457': 32.2932,
    '789_458': 32.2035,
    '789_459': 32.1218,
    '789_460': 32.0496,
    '789_461': 31.9924,
    '789_462': 31.957,
    '789_463': 31.9064,
    '789_464': 31.8268,
    '789_465': 31.7472,
    '789_466': 31.701,
    '789_467': 31.6935,
    '789_468': 31.7036,
    '789_469': 31.6959,
    '789_470': 31.6757,
    '789_471': 31.6425,
    '789_472': 31.6055,
    '789_473': 31.5665,
    '789_474': 31.5283,
    '789_475': 31.4926,
    '789_476': 31.4572,
    '789_477': 31.4251,
    '789_478': 31.4006,
    '789_479': 31.3829,
    '789_480': 31.3715,
    '789_481': 31.3687,
    '789_482': 31.3746,
    '789_483': 31.3889,
    '789_484': 31.4094,
    '789_485': 31.4635,
    '789_486': 31.5065,
    '789_487': 31.5755,
    '789_488': 31.6577,
    '789_489': 31.7488,
    '789_490': 31.8468,
    '789_491': 31.9491,
    '789_492': 32.0526,
    '789_493': 32.1543,
    '789_494': 32.2604,
    '789_495': 32.3719,
    '789_496': 32.4908,
    '789_497': 32.6205,
    '789_498': 32.7684,
    '789_499': 32.9156,
    '789_500': 33.0659,
    '789_501': 33.2246,
    '789_502': 33.4004,
    '789_503': 33.5896,
    '789_504': 33.7853,
    '789_505': 33.9755,
    '789_506': 34.106,
    '789_507': 34.1902,
    '789_508': 34.247,
    '789_509': 34.3064,
    '789_510': 34.3784,
    '789_511': 34.4617,
    '789_512': 34.5544,
    '789_513': 34.6782,
    '789_514': 34.802,
    '789_515': 34.9147,
    '789_516': 35.0033,
    '789_517': 35.0773,
    '789_518': 35.1333,
    '789_519': 35.1908,
    '789_520': 35.2561,
    '789_521': 35.3153,
    '789_522': 35.3646,
    '789_523': 35.4031,
    '789_524': 35.4279,
    '789_525': 35.4534,
    '789_526': 35.4839,
    '789_527': 35.4892,
    '789_528': 35.4903,
    '789_529': 35.5019,
    '789_530': 35.5042,
    '789_531': 35.51,
    '789_532': 35.5133,
    '789_533': 35.5142,
    '789_534': 35.5242,
    '789_535': 35.5378,
    '789_536': 35.5498,
    '789_537': 35.5579,
    '789_538': 35.5545,
    '789_539': 35.5583,
    '789_540': 35.5632,
    '789_541': 35.5601,
    '789_542': 35.5446,
    '789_543': 35.5206,
    '789_544': 35.4921,
    '789_545': 35.4605,
    '789_546': 35.4278,
    '789_547': 35.3978,
    '789_548': 35.3774,
    '789_549': 35.3463,
    '789_550': 35.318,
    '789_551': 35.298,
    '789_552': 35.289,
    '789_553': 35.295,
    '789_554': 35.3107,
    '789_555': 35.343,
    '789_556': 35.3876,
    '789_557': 35.4408,
    '789_558': 35.5031,
    '789_559': 35.5727,
    '789_560': 35.6479,
    '789_561': 35.727,
    '789_562': 35.8071,
    '789_563': 35.8851,
    '789_564': 35.9529,
    '789_565': 36.0059,
    '789_566': 36.0469,
    '789_567': 36.0583,
    '789_568': 36.0147,
    '789_569': 35.9214,
    '789_570': 35.8453,
    '789_571': 35.7785,
    '789_572': 35.7157,
    '789_573': 35.6633,
    '789_574': 35.6156,
    '789_575': 35.5638,
    '789_576': 35.5123,
    '789_577': 35.4661,
    '789_578': 35.4367,
    '789_579': 35.4198,
    '789_580': 35.4006,
    '789_581': 35.3939,
    '789_582': 35.3892,
    '789_583': 35.3792,
    '789_584': 35.3641,
    '789_585': 35.3476,
    '789_586': 35.3307,
    '789_587': 35.2961,
    '789_588': 35.2636,
    '789_589': 35.2248,
    '789_590': 35.1871,
    '789_591': 35.1501,
    '789_592': 35.1161,
    '789_593': 35.0677,
    '789_594': 35.0026,
    '789_595': 34.9473,
    '789_596': 34.938,
    '789_597': 34.9037,
    '789_598': 34.8767,
    '789_599': 34.8492,
    '789_600': 34.8177,
    '789_601': 34.782,
    '789_602': 34.7504,
    '789_603': 34.7215,
    '789_604': 34.6953,
    '789_605': 34.6783,
    '789_606': 34.656,
    '789_607': 34.6406,
    '789_608': 34.6315,
    '789_609': 34.6285,
    '789_610': 34.6327,
    '789_611': 34.6392,
    '789_612': 34.6435,
    '789_613': 34.6345,
    '789_614': 34.6283,
    '789_615': 34.6265,
    '789_616': 34.6258,
    '789_617': 34.6342,
    '789_618': 34.6531,
    '789_619': 34.6775,
    '789_620': 34.71,
    '789_621': 34.7484,
    '789_622': 34.7858,
    '789_623': 34.8349,
    '789_624': 34.882,
    '789_625': 34.9161,
    '789_626': 34.9452,
    '789_627': 34.9681,
    '789_628': 34.9962,
    '789_629': 35.0301,
    '789_630': 35.0635,
    '789_631': 35.1009,
    '789_632': 35.1454,
    '789_633': 35.185,
    '789_634': 35.2077,
    '789_635': 35.2099,
    '789_636': 35.2169,
    '789_637': 35.2184,
    '789_638': 35.2176,
    '789_639': 35.2175,
    '789_640': 35.2143,
    '789_641': 35.2154,
    '789_642': 35.2104,
    '789_643': 35.1991,
    '789_644': 35.1877,
    '789_645': 35.1874,
    '789_646': 35.1893,
    '789_647': 35.1921,
    '789_648': 35.1973,
    '789_649': 35.205,
    '789_650': 35.2174,
    '789_651': 35.2358,
    '789_652': 35.2581,
    '789_653': 35.281,
    '789_654': 35.3004,
    '789_655': 35.3175,
    '789_656': 35.3331,
    '789_657': 35.3469,
    '789_658': 35.361,
    '789_659': 35.3761,
    '789_660': 35.3913,
    '789_786': 43.2468,
    '789_787': 43.3671,
    '789_788': 43.4649,
    '789_789': 43.5523,
    '789_790': 43.6417,
    '789_791': 43.7039,
    '789_792': 43.7217,
    '789_793': 43.7417,
    '789_794': 43.7506,
    '789_795': 43.7395,
    '789_796': 43.7291,
    '790_350': 30.3912,
    '790_351': 30.4534,
    '790_352': 30.5103,
    '790_353': 30.5646,
    '790_354': 30.6177,
    '790_355': 30.6708,
    '790_356': 30.7224,
    '790_357': 30.7719,
    '790_358': 30.8222,
    '790_359': 30.8728,
    '790_360': 30.9234,
    '790_361': 30.9728,
    '790_362': 31.0195,
    '790_363': 31.0637,
    '790_364': 31.1053,
    '790_365': 31.1451,
    '790_366': 31.1753,
    '790_367': 31.2051,
    '790_368': 31.2339,
    '790_369': 31.2644,
    '790_370': 31.2984,
    '790_373': 31.283,
    '790_374': 31.3315,
    '790_375': 31.3771,
    '790_376': 31.4095,
    '790_377': 31.4431,
    '790_378': 31.4789,
    '790_379': 31.5166,
    '790_380': 31.5581,
    '790_381': 31.6036,
    '790_382': 31.6461,
    '790_383': 31.695,
    '790_384': 31.7526,
    '790_385': 31.8093,
    '790_386': 31.8685,
    '790_387': 31.9291,
    '790_388': 31.9902,
    '790_389': 32.0381,
    '790_390': 32.0969,
    '790_391': 32.1364,
    '790_392': 32.1748,
    '790_393': 32.1811,
    '790_394': 32.1893,
    '790_395': 32.208,
    '790_396': 32.2302,
    '790_397': 32.2565,
    '790_398': 32.282,
    '790_399': 32.2859,
    '790_400': 32.2863,
    '790_401': 32.2936,
    '790_402': 32.2997,
    '790_403': 32.31,
    '790_404': 32.2985,
    '790_405': 32.2864,
    '790_406': 32.2896,
    '790_407': 32.3028,
    '790_408': 32.3165,
    '790_409': 32.331,
    '790_410': 32.3481,
    '790_411': 32.3669,
    '790_412': 32.3873,
    '790_413': 32.4111,
    '790_414': 32.44,
    '790_415': 32.4732,
    '790_416': 32.5109,
    '790_417': 32.5546,
    '790_418': 32.6057,
    '790_419': 32.663,
    '790_420': 32.7244,
    '790_421': 32.7907,
    '790_422': 32.8474,
    '790_423': 32.8937,
    '790_424': 32.9392,
    '790_425': 32.9714,
    '790_426': 33.0094,
    '790_427': 33.0426,
    '790_428': 33.0658,
    '790_429': 33.1005,
    '790_430': 33.1437,
    '790_431': 33.1724,
    '790_432': 33.1952,
    '790_433': 33.2185,
    '790_434': 33.2439,
    '790_435': 33.2561,
    '790_436': 33.2408,
    '790_437': 33.2029,
    '790_438': 33.1546,
    '790_439': 33.0936,
    '790_440': 33.039,
    '790_441': 33.0073,
    '790_442': 32.9802,
    '790_443': 32.9673,
    '790_444': 32.9691,
    '790_445': 32.9725,
    '790_446': 32.9663,
    '790_447': 32.9471,
    '790_448': 32.9197,
    '790_449': 32.8946,
    '790_450': 32.877,
    '790_451': 32.8478,
    '790_452': 32.7919,
    '790_453': 32.6976,
    '790_454': 32.5833,
    '790_455': 32.468,
    '790_456': 32.3524,
    '790_457': 32.2459,
    '790_458': 32.1489,
    '790_459': 32.0662,
    '790_460': 31.9882,
    '790_461': 31.9235,
    '790_462': 31.8743,
    '790_463': 31.8276,
    '790_464': 31.7543,
    '790_465': 31.6864,
    '790_466': 31.6548,
    '790_467': 31.6533,
    '790_468': 31.6685,
    '790_469': 31.6789,
    '790_470': 31.6782,
    '790_471': 31.6538,
    '790_472': 31.6228,
    '790_473': 31.5837,
    '790_474': 31.543,
    '790_475': 31.5048,
    '790_476': 31.4687,
    '790_477': 31.4372,
    '790_478': 31.4132,
    '790_479': 31.3966,
    '790_480': 31.3879,
    '790_481': 31.387,
    '790_482': 31.3955,
    '790_483': 31.4123,
    '790_484': 31.44,
    '790_485': 31.4885,
    '790_486': 31.5318,
    '790_487': 31.5958,
    '790_488': 31.6749,
    '790_489': 31.7647,
    '790_490': 31.8627,
    '790_491': 31.9657,
    '790_492': 32.0667,
    '790_493': 32.1672,
    '790_494': 32.2727,
    '790_495': 32.3833,
    '790_496': 32.501,
    '790_497': 32.6274,
    '790_498': 32.7685,
    '790_499': 32.9132,
    '790_500': 33.0614,
    '790_501': 33.2275,
    '790_502': 33.4022,
    '790_503': 33.6024,
    '790_504': 33.8118,
    '790_505': 33.9989,
    '790_506': 34.1231,
    '790_507': 34.1953,
    '790_508': 34.2452,
    '790_509': 34.3114,
    '790_510': 34.3832,
    '790_511': 34.4691,
    '790_512': 34.5667,
    '790_513': 34.6911,
    '790_514': 34.8133,
    '790_515': 34.9288,
    '790_516': 35.0213,
    '790_517': 35.1001,
    '790_518': 35.1622,
    '790_519': 35.2223,
    '790_520': 35.2879,
    '790_521': 35.3472,
    '790_522': 35.3953,
    '790_523': 35.4383,
    '790_524': 35.4775,
    '790_525': 35.5098,
    '790_526': 35.5431,
    '790_527': 35.556,
    '790_528': 35.545,
    '790_529': 35.5424,
    '790_530': 35.5354,
    '790_531': 35.535,
    '790_532': 35.5345,
    '790_533': 35.5329,
    '790_534': 35.5395,
    '790_535': 35.551,
    '790_536': 35.563,
    '790_537': 35.5763,
    '790_538': 35.5772,
    '790_539': 35.5774,
    '790_540': 35.5795,
    '790_541': 35.5772,
    '790_542': 35.5658,
    '790_543': 35.5473,
    '790_544': 35.5231,
    '790_545': 35.4954,
    '790_546': 35.4725,
    '790_547': 35.4482,
    '790_548': 35.4205,
    '790_549': 35.3971,
    '790_550': 35.3759,
    '790_551': 35.3609,
    '790_552': 35.3552,
    '790_553': 35.3614,
    '790_554': 35.373,
    '790_555': 35.4057,
    '790_556': 35.4531,
    '790_557': 35.5024,
    '790_558': 35.5633,
    '790_559': 35.6443,
    '790_560': 35.7315,
    '790_561': 35.8201,
    '790_562': 35.897,
    '790_563': 35.9876,
    '790_564': 36.0494,
    '790_565': 36.0908,
    '790_566': 36.1207,
    '790_567': 36.1199,
    '790_568': 36.0583,
    '790_569': 35.9554,
    '790_570': 35.8673,
    '790_571': 35.7928,
    '790_572': 35.7259,
    '790_573': 35.6707,
    '790_574': 35.6192,
    '790_575': 35.5622,
    '790_576': 35.5059,
    '790_577': 35.4573,
    '790_578': 35.4234,
    '790_579': 35.3991,
    '790_580': 35.3779,
    '790_581': 35.3683,
    '790_582': 35.3628,
    '790_583': 35.3499,
    '790_584': 35.3308,
    '790_585': 35.3166,
    '790_586': 35.3006,
    '790_587': 35.2665,
    '790_588': 35.2296,
    '790_589': 35.1896,
    '790_590': 35.1489,
    '790_591': 35.1063,
    '790_592': 35.071,
    '790_593': 35.0243,
    '790_594': 34.9681,
    '790_595': 34.9196,
    '790_596': 34.9142,
    '790_597': 34.8854,
    '790_598': 34.8638,
    '790_599': 34.8418,
    '790_600': 34.8141,
    '790_601': 34.7809,
    '790_602': 34.7529,
    '790_603': 34.7288,
    '790_604': 34.705,
    '790_605': 34.6926,
    '790_606': 34.6743,
    '790_607': 34.6615,
    '790_608': 34.6561,
    '790_609': 34.657,
    '790_610': 34.664,
    '790_611': 34.6733,
    '790_612': 34.6823,
    '790_613': 34.681,
    '790_614': 34.6795,
    '790_615': 34.682,
    '790_616': 34.6837,
    '790_617': 34.6969,
    '790_618': 34.7206,
    '790_619': 34.7496,
    '790_620': 34.787,
    '790_621': 34.8316,
    '790_622': 34.8829,
    '790_623': 34.9388,
    '790_624': 34.992,
    '790_625': 35.0361,
    '790_626': 35.0736,
    '790_627': 35.1051,
    '790_628': 35.1377,
    '790_629': 35.1756,
    '790_630': 35.214,
    '790_631': 35.254,
    '790_632': 35.3041,
    '790_633': 35.3447,
    '790_634': 35.3576,
    '790_635': 35.3548,
    '790_636': 35.3487,
    '790_637': 35.3449,
    '790_638': 35.3388,
    '790_639': 35.3303,
    '790_640': 35.32,
    '790_641': 35.3167,
    '790_642': 35.3104,
    '790_643': 35.2989,
    '790_644': 35.2791,
    '790_645': 35.272,
    '790_646': 35.2643,
    '790_647': 35.2595,
    '790_648': 35.2629,
    '790_649': 35.2677,
    '790_650': 35.2767,
    '790_651': 35.2914,
    '790_652': 35.3101,
    '790_653': 35.3301,
    '790_654': 35.3444,
    '790_655': 35.3581,
    '790_656': 35.3715,
    '790_657': 35.3863,
    '790_658': 35.4012,
    '790_659': 35.4165,
    '790_660': 35.4313,
    '790_786': 43.179,
    '790_787': 43.2924,
    '790_788': 43.3883,
    '790_789': 43.4708,
    '790_790': 43.544,
    '790_791': 43.5976,
    '790_792': 43.6295,
    '790_793': 43.6584,
    '790_794': 43.6748,
    '790_795': 43.6738,
    '790_796': 43.676,
    '791_350': 30.358,
    '791_351': 30.4195,
    '791_352': 30.4764,
    '791_353': 30.5309,
    '791_354': 30.5843,
    '791_355': 30.6379,
    '791_356': 30.6901,
    '791_357': 30.7414,
    '791_358': 30.7928,
    '791_359': 30.8446,
    '791_360': 30.8967,
    '791_361': 30.9481,
    '791_362': 30.9977,
    '791_363': 31.0436,
    '791_364': 31.0875,
    '791_365': 31.132,
    '791_366': 31.1612,
    '791_367': 31.1897,
    '791_368': 31.2203,
    '791_369': 31.2544,
    '791_370': 31.2929,
    '791_373': 31.2747,
    '791_374': 31.3235,
    '791_375': 31.369,
    '791_376': 31.4056,
    '791_377': 31.4383,
    '791_378': 31.4673,
    '791_379': 31.5031,
    '791_380': 31.5448,
    '791_381': 31.5909,
    '791_382': 31.639,
    '791_383': 31.6925,
    '791_384': 31.7481,
    '791_385': 31.8038,
    '791_386': 31.8601,
    '791_387': 31.922,
    '791_388': 31.9895,
    '791_389': 32.0422,
    '791_390': 32.1115,
    '791_391': 32.1605,
    '791_392': 32.1979,
    '791_393': 32.1999,
    '791_394': 32.1918,
    '791_395': 32.2053,
    '791_396': 32.2287,
    '791_397': 32.2476,
    '791_398': 32.2659,
    '791_399': 32.2759,
    '791_400': 32.2801,
    '791_401': 32.286,
    '791_402': 32.2944,
    '791_403': 32.311,
    '791_404': 32.3016,
    '791_405': 32.2966,
    '791_406': 32.3007,
    '791_407': 32.3103,
    '791_408': 32.3192,
    '791_409': 32.3296,
    '791_410': 32.3418,
    '791_411': 32.3568,
    '791_412': 32.3742,
    '791_413': 32.3953,
    '791_414': 32.4215,
    '791_415': 32.4525,
    '791_416': 32.4878,
    '791_417': 32.5308,
    '791_418': 32.5822,
    '791_419': 32.6397,
    '791_420': 32.701,
    '791_421': 32.7628,
    '791_422': 32.8172,
    '791_423': 32.8674,
    '791_424': 32.9143,
    '791_425': 32.958,
    '791_426': 32.9994,
    '791_427': 33.0418,
    '791_428': 33.0721,
    '791_429': 33.1015,
    '791_430': 33.143,
    '791_431': 33.1775,
    '791_432': 33.205,
    '791_433': 33.229,
    '791_434': 33.2489,
    '791_435': 33.2503,
    '791_436': 33.2274,
    '791_437': 33.1887,
    '791_438': 33.1365,
    '791_439': 33.0726,
    '791_440': 33.0156,
    '791_441': 32.9921,
    '791_442': 32.9747,
    '791_443': 32.9687,
    '791_444': 32.9647,
    '791_445': 32.9551,
    '791_446': 32.9327,
    '791_447': 32.9047,
    '791_448': 32.8784,
    '791_449': 32.8599,
    '791_450': 32.8474,
    '791_451': 32.8216,
    '791_452': 32.7657,
    '791_453': 32.6749,
    '791_454': 32.5597,
    '791_455': 32.4351,
    '791_456': 32.3117,
    '791_457': 32.1966,
    '791_458': 32.095,
    '791_459': 32.0125,
    '791_460': 31.9329,
    '791_461': 31.8575,
    '791_462': 31.7925,
    '791_463': 31.7354,
    '791_464': 31.6727,
    '791_465': 31.6278,
    '791_466': 31.609,
    '791_467': 31.6047,
    '791_468': 31.62,
    '791_469': 31.6386,
    '791_470': 31.6504,
    '791_471': 31.6436,
    '791_472': 31.6298,
    '791_473': 31.5966,
    '791_474': 31.557,
    '791_475': 31.5162,
    '791_476': 31.4784,
    '791_477': 31.4464,
    '791_478': 31.4224,
    '791_479': 31.4071,
    '791_480': 31.4011,
    '791_481': 31.4044,
    '791_482': 31.4176,
    '791_483': 31.4392,
    '791_484': 31.4741,
    '791_485': 31.5126,
    '791_486': 31.5551,
    '791_487': 31.6151,
    '791_488': 31.6907,
    '791_489': 31.7778,
    '791_490': 31.8731,
    '791_491': 31.9729,
    '791_492': 32.0735,
    '791_493': 32.1758,
    '791_494': 32.2821,
    '791_495': 32.393,
    '791_496': 32.5099,
    '791_497': 32.6313,
    '791_498': 32.7638,
    '791_499': 32.902,
    '791_500': 33.0516,
    '791_501': 33.2231,
    '791_502': 33.3952,
    '791_503': 33.6059,
    '791_504': 33.8238,
    '791_505': 34.0039,
    '791_506': 34.1264,
    '791_507': 34.1938,
    '791_508': 34.2497,
    '791_509': 34.3129,
    '791_510': 34.3891,
    '791_511': 34.4786,
    '791_512': 34.582,
    '791_513': 34.7076,
    '791_514': 34.8201,
    '791_515': 34.9293,
    '791_516': 35.0255,
    '791_517': 35.112,
    '791_518': 35.1781,
    '791_519': 35.2436,
    '791_520': 35.3175,
    '791_521': 35.3824,
    '791_522': 35.431,
    '791_523': 35.4759,
    '791_524': 35.5186,
    '791_525': 35.5584,
    '791_526': 35.5942,
    '791_527': 35.6162,
    '791_528': 35.6059,
    '791_529': 35.5862,
    '791_530': 35.5693,
    '791_531': 35.5612,
    '791_532': 35.5548,
    '791_533': 35.5498,
    '791_534': 35.5542,
    '791_535': 35.5642,
    '791_536': 35.5762,
    '791_537': 35.5906,
    '791_538': 35.5963,
    '791_539': 35.595,
    '791_540': 35.5972,
    '791_541': 35.5962,
    '791_542': 35.5887,
    '791_543': 35.5747,
    '791_544': 35.5562,
    '791_545': 35.537,
    '791_546': 35.5173,
    '791_547': 35.4962,
    '791_548': 35.4725,
    '791_549': 35.448,
    '791_550': 35.4255,
    '791_551': 35.4087,
    '791_552': 35.4065,
    '791_553': 35.4197,
    '791_554': 35.4403,
    '791_555': 35.4701,
    '791_556': 35.5183,
    '791_557': 35.5758,
    '791_558': 35.6416,
    '791_559': 35.7204,
    '791_560': 35.8023,
    '791_561': 35.8929,
    '791_562': 35.9784,
    '791_563': 36.0579,
    '791_564': 36.1216,
    '791_565': 36.1676,
    '791_566': 36.1918,
    '791_567': 36.1794,
    '791_568': 36.1081,
    '791_569': 35.9865,
    '791_570': 35.8928,
    '791_571': 35.8144,
    '791_572': 35.7444,
    '791_573': 35.6819,
    '791_574': 35.6227,
    '791_575': 35.5643,
    '791_576': 35.5052,
    '791_577': 35.4551,
    '791_578': 35.4158,
    '791_579': 35.3846,
    '791_580': 35.3589,
    '791_581': 35.3437,
    '791_582': 35.3323,
    '791_583': 35.3213,
    '791_584': 35.302,
    '791_585': 35.2821,
    '791_586': 35.2607,
    '791_587': 35.2297,
    '791_588': 35.1923,
    '791_589': 35.1501,
    '791_590': 35.1125,
    '791_591': 35.0738,
    '791_592': 35.0342,
    '791_593': 34.9919,
    '791_594': 34.9401,
    '791_595': 34.8939,
    '791_596': 34.8924,
    '791_597': 34.8708,
    '791_598': 34.8524,
    '791_599': 34.8356,
    '791_600': 34.8113,
    '791_601': 34.7798,
    '791_602': 34.7528,
    '791_603': 34.7325,
    '791_604': 34.7109,
    '791_605': 34.6972,
    '791_606': 34.6844,
    '791_607': 34.6765,
    '791_608': 34.675,
    '791_609': 34.6786,
    '791_610': 34.6916,
    '791_611': 34.7042,
    '791_612': 34.7198,
    '791_613': 34.7257,
    '791_614': 34.7278,
    '791_615': 34.7343,
    '791_616': 34.7468,
    '791_617': 34.7677,
    '791_618': 34.7951,
    '791_619': 34.8273,
    '791_620': 34.8742,
    '791_621': 34.9321,
    '791_622': 34.9889,
    '791_623': 35.0497,
    '791_624': 35.1084,
    '791_625': 35.1612,
    '791_626': 35.2081,
    '791_627': 35.2491,
    '791_628': 35.2869,
    '791_629': 35.3257,
    '791_630': 35.3746,
    '791_631': 35.421,
    '791_632': 35.4715,
    '791_633': 35.5147,
    '791_634': 35.5154,
    '791_635': 35.5058,
    '791_636': 35.4934,
    '791_637': 35.4817,
    '791_638': 35.4685,
    '791_639': 35.4533,
    '791_640': 35.4383,
    '791_641': 35.4254,
    '791_642': 35.4123,
    '791_643': 35.3966,
    '791_644': 35.378,
    '791_645': 35.3615,
    '791_646': 35.3387,
    '791_647': 35.3206,
    '791_648': 35.3207,
    '791_649': 35.3242,
    '791_650': 35.33,
    '791_651': 35.3401,
    '791_652': 35.3525,
    '791_653': 35.3657,
    '791_654': 35.38,
    '791_655': 35.3941,
    '791_656': 35.4081,
    '791_657': 35.4218,
    '791_658': 35.4364,
    '791_659': 35.4518,
    '791_660': 35.4678,
    '791_786': 43.102,
    '791_787': 43.2057,
    '791_788': 43.3064,
    '791_789': 43.393,
    '791_790': 43.4596,
    '791_791': 43.5079,
    '791_792': 43.5425,
    '791_793': 43.5768,
    '791_794': 43.6012,
    '791_795': 43.6074,
    '791_796': 43.6197,
    '792_350': 30.3222,
    '792_351': 30.3828,
    '792_352': 30.44,
    '792_353': 30.4948,
    '792_354': 30.5484,
    '792_355': 30.6016,
    '792_356': 30.655,
    '792_357': 30.7074,
    '792_358': 30.7599,
    '792_359': 30.8127,
    '792_360': 30.866,
    '792_361': 30.9201,
    '792_362': 30.9726,
    '792_363': 31.0188,
    '792_364': 31.0637,
    '792_365': 31.111,
    '792_366': 31.137,
    '792_367': 31.167,
    '792_368': 31.2006,
    '792_369': 31.2391,
    '792_370': 31.2827,
    '792_373': 31.2639,
    '792_374': 31.3105,
    '792_375': 31.358,
    '792_376': 31.3996,
    '792_377': 31.43,
    '792_378': 31.4564,
    '792_379': 31.4888,
    '792_380': 31.5305,
    '792_381': 31.5779,
    '792_382': 31.6303,
    '792_383': 31.6874,
    '792_384': 31.7446,
    '792_385': 31.8004,
    '792_386': 31.8552,
    '792_387': 31.9179,
    '792_388': 31.9848,
    '792_389': 32.0404,
    '792_390': 32.1059,
    '792_391': 32.1608,
    '792_392': 32.2069,
    '792_393': 32.2088,
    '792_394': 32.192,
    '792_395': 32.206,
    '792_396': 32.2315,
    '792_397': 32.2386,
    '792_398': 32.2525,
    '792_399': 32.2681,
    '792_400': 32.2848,
    '792_401': 32.29,
    '792_402': 32.2987,
    '792_403': 32.3153,
    '792_404': 32.3151,
    '792_405': 32.3175,
    '792_406': 32.3223,
    '792_407': 32.3295,
    '792_408': 32.3352,
    '792_409': 32.3402,
    '792_410': 32.3467,
    '792_411': 32.3575,
    '792_412': 32.3721,
    '792_413': 32.3896,
    '792_414': 32.4113,
    '792_415': 32.4387,
    '792_416': 32.4713,
    '792_417': 32.5118,
    '792_418': 32.5626,
    '792_419': 32.6209,
    '792_420': 32.6827,
    '792_421': 32.7423,
    '792_422': 32.7951,
    '792_423': 32.8447,
    '792_424': 32.8916,
    '792_425': 32.9389,
    '792_426': 32.9797,
    '792_427': 33.023,
    '792_428': 33.059,
    '792_429': 33.0961,
    '792_430': 33.1421,
    '792_431': 33.1807,
    '792_432': 33.2066,
    '792_433': 33.2286,
    '792_434': 33.2416,
    '792_435': 33.2327,
    '792_436': 33.2025,
    '792_437': 33.1556,
    '792_438': 33.1023,
    '792_439': 33.0423,
    '792_440': 32.99,
    '792_441': 32.9703,
    '792_442': 32.9629,
    '792_443': 32.9612,
    '792_444': 32.9563,
    '792_445': 32.9401,
    '792_446': 32.9107,
    '792_447': 32.8751,
    '792_448': 32.8465,
    '792_449': 32.8282,
    '792_450': 32.8189,
    '792_451': 32.7958,
    '792_452': 32.7408,
    '792_453': 32.6505,
    '792_454': 32.5415,
    '792_455': 32.4274,
    '792_456': 32.3101,
    '792_457': 32.1881,
    '792_458': 32.0778,
    '792_459': 31.9858,
    '792_460': 31.8988,
    '792_461': 31.8068,
    '792_462': 31.7278,
    '792_463': 31.6597,
    '792_464': 31.602,
    '792_465': 31.5653,
    '792_466': 31.5543,
    '792_467': 31.5513,
    '792_468': 31.5645,
    '792_469': 31.5841,
    '792_470': 31.5999,
    '792_471': 31.6068,
    '792_472': 31.6091,
    '792_473': 31.6007,
    '792_474': 31.572,
    '792_475': 31.5239,
    '792_476': 31.4849,
    '792_477': 31.4522,
    '792_478': 31.4284,
    '792_479': 31.4154,
    '792_480': 31.4124,
    '792_481': 31.4219,
    '792_482': 31.4428,
    '792_483': 31.4746,
    '792_484': 31.5032,
    '792_485': 31.5365,
    '792_486': 31.5777,
    '792_487': 31.6329,
    '792_488': 31.7049,
    '792_489': 31.789,
    '792_490': 31.8816,
    '792_491': 31.9788,
    '792_492': 32.0789,
    '792_493': 32.1823,
    '792_494': 32.2894,
    '792_495': 32.4012,
    '792_496': 32.5163,
    '792_497': 32.6343,
    '792_498': 32.7619,
    '792_499': 32.8951,
    '792_500': 33.0442,
    '792_501': 33.2148,
    '792_502': 33.3817,
    '792_503': 33.5908,
    '792_504': 33.8114,
    '792_505': 33.9905,
    '792_506': 34.115,
    '792_507': 34.1845,
    '792_508': 34.2396,
    '792_509': 34.3063,
    '792_510': 34.392,
    '792_511': 34.492,
    '792_512': 34.5914,
    '792_513': 34.7107,
    '792_514': 34.8144,
    '792_515': 34.9239,
    '792_516': 35.0252,
    '792_517': 35.1213,
    '792_518': 35.1976,
    '792_519': 35.2739,
    '792_520': 35.3577,
    '792_521': 35.4276,
    '792_522': 35.4794,
    '792_523': 35.5215,
    '792_524': 35.5604,
    '792_525': 35.5964,
    '792_526': 35.631,
    '792_527': 35.6571,
    '792_528': 35.6592,
    '792_529': 35.6417,
    '792_530': 35.6086,
    '792_531': 35.5886,
    '792_532': 35.5766,
    '792_533': 35.5692,
    '792_534': 35.568,
    '792_535': 35.5739,
    '792_536': 35.5858,
    '792_537': 35.6011,
    '792_538': 35.6133,
    '792_539': 35.617,
    '792_540': 35.6184,
    '792_541': 35.618,
    '792_542': 35.6143,
    '792_543': 35.604,
    '792_544': 35.5899,
    '792_545': 35.5771,
    '792_546': 35.5608,
    '792_547': 35.5434,
    '792_548': 35.5248,
    '792_549': 35.5003,
    '792_550': 35.4785,
    '792_551': 35.4655,
    '792_552': 35.4652,
    '792_553': 35.4787,
    '792_554': 35.4976,
    '792_555': 35.529,
    '792_556': 35.5812,
    '792_557': 35.642,
    '792_558': 35.7105,
    '792_559': 35.7971,
    '792_560': 35.8833,
    '792_561': 35.9693,
    '792_562': 36.0578,
    '792_563': 36.1381,
    '792_564': 36.2015,
    '792_565': 36.2464,
    '792_566': 36.2657,
    '792_567': 36.2421,
    '792_568': 36.1634,
    '792_569': 36.0426,
    '792_570': 35.9372,
    '792_571': 35.8502,
    '792_572': 35.7723,
    '792_573': 35.7043,
    '792_574': 35.6396,
    '792_575': 35.5773,
    '792_576': 35.5132,
    '792_577': 35.4593,
    '792_578': 35.4167,
    '792_579': 35.3809,
    '792_580': 35.351,
    '792_581': 35.3285,
    '792_582': 35.3093,
    '792_583': 35.2938,
    '792_584': 35.2756,
    '792_585': 35.2526,
    '792_586': 35.2276,
    '792_587': 35.1935,
    '792_588': 35.1546,
    '792_589': 35.1122,
    '792_590': 35.0754,
    '792_591': 35.0321,
    '792_592': 34.9925,
    '792_593': 34.9511,
    '792_594': 34.9078,
    '792_595': 34.867,
    '792_596': 34.8686,
    '792_597': 34.852,
    '792_598': 34.8391,
    '792_599': 34.8267,
    '792_600': 34.8046,
    '792_601': 34.7784,
    '792_602': 34.7568,
    '792_603': 34.7408,
    '792_604': 34.7203,
    '792_605': 34.7034,
    '792_606': 34.6909,
    '792_607': 34.6865,
    '792_608': 34.6868,
    '792_609': 34.6975,
    '792_610': 34.7167,
    '792_611': 34.7373,
    '792_612': 34.7616,
    '792_613': 34.771,
    '792_614': 34.7749,
    '792_615': 34.7865,
    '792_616': 34.8098,
    '792_617': 34.8386,
    '792_618': 34.8681,
    '792_619': 34.9143,
    '792_620': 34.973,
    '792_621': 35.0394,
    '792_622': 35.0997,
    '792_623': 35.1677,
    '792_624': 35.2332,
    '792_625': 35.2952,
    '792_626': 35.3524,
    '792_627': 35.4038,
    '792_628': 35.4511,
    '792_629': 35.4976,
    '792_630': 35.5468,
    '792_631': 35.5909,
    '792_632': 35.631,
    '792_633': 35.6645,
    '792_634': 35.6735,
    '792_635': 35.6595,
    '792_636': 35.641,
    '792_637': 35.6247,
    '792_638': 35.6055,
    '792_639': 35.5809,
    '792_640': 35.5587,
    '792_641': 35.5354,
    '792_642': 35.5137,
    '792_643': 35.4919,
    '792_644': 35.4687,
    '792_645': 35.4423,
    '792_646': 35.4132,
    '792_647': 35.3912,
    '792_648': 35.3821,
    '792_649': 35.3799,
    '792_650': 35.3795,
    '792_651': 35.3844,
    '792_652': 35.3908,
    '792_653': 35.4009,
    '792_654': 35.4121,
    '792_655': 35.4248,
    '792_656': 35.4382,
    '792_657': 35.4515,
    '792_658': 35.4655,
    '792_659': 35.481,
    '792_660': 35.4979,
    '793_350': 30.2877,
    '793_351': 30.346,
    '793_352': 30.4024,
    '793_353': 30.4571,
    '793_354': 30.5107,
    '793_355': 30.564,
    '793_356': 30.6172,
    '793_357': 30.6702,
    '793_358': 30.7234,
    '793_359': 30.7768,
    '793_360': 30.8307,
    '793_361': 30.8842,
    '793_362': 30.9358,
    '793_363': 30.9832,
    '793_364': 31.0279,
    '793_365': 31.0702,
    '793_366': 31.1026,
    '793_367': 31.1359,
    '793_368': 31.1728,
    '793_369': 31.2158,
    '793_370': 31.2652,
    '793_373': 31.2473,
    '793_374': 31.2909,
    '793_375': 31.3379,
    '793_376': 31.3861,
    '793_377': 31.4152,
    '793_378': 31.4502,
    '793_379': 31.4767,
    '793_380': 31.5159,
    '793_381': 31.564,
    '793_382': 31.6216,
    '793_383': 31.6855,
    '793_384': 31.7465,
    '793_385': 31.7984,
    '793_386': 31.8513,
    '793_387': 31.9171,
    '793_388': 31.9795,
    '793_389': 32.0415,
    '793_390': 32.1005,
    '793_391': 32.1559,
    '793_392': 32.2052,
    '793_393': 32.2072,
    '793_394': 32.1882,
    '793_395': 32.2079,
    '793_396': 32.2322,
    '793_397': 32.2325,
    '793_398': 32.2449,
    '793_399': 32.2636,
    '793_400': 32.2902,
    '793_401': 32.3043,
    '793_402': 32.3136,
    '793_403': 32.3307,
    '793_404': 32.3463,
    '793_405': 32.3574,
    '793_406': 32.3615,
    '793_407': 32.3671,
    '793_408': 32.3647,
    '793_409': 32.3627,
    '793_410': 32.363,
    '793_411': 32.3687,
    '793_412': 32.3783,
    '793_413': 32.3921,
    '793_414': 32.4081,
    '793_415': 32.4308,
    '793_416': 32.4594,
    '793_417': 32.4974,
    '793_418': 32.5469,
    '793_419': 32.6053,
    '793_420': 32.6687,
    '793_421': 32.7285,
    '793_422': 32.7813,
    '793_423': 32.8294,
    '793_424': 32.878,
    '793_425': 32.9241,
    '793_426': 32.9641,
    '793_427': 33.0067,
    '793_428': 33.0466,
    '793_429': 33.0882,
    '793_430': 33.1328,
    '793_431': 33.1683,
    '793_432': 33.195,
    '793_433': 33.2151,
    '793_434': 33.2221,
    '793_435': 33.2036,
    '793_436': 33.1628,
    '793_437': 33.1119,
    '793_438': 33.0572,
    '793_439': 33.0052,
    '793_440': 32.9623,
    '793_441': 32.9461,
    '793_442': 32.9437,
    '793_443': 32.9461,
    '793_444': 32.9443,
    '793_445': 32.9297,
    '793_446': 32.8976,
    '793_447': 32.8541,
    '793_448': 32.8212,
    '793_449': 32.8025,
    '793_450': 32.7933,
    '793_451': 32.7729,
    '793_452': 32.7221,
    '793_453': 32.6351,
    '793_454': 32.5409,
    '793_455': 32.4484,
    '793_456': 32.3413,
    '793_457': 32.2153,
    '793_458': 32.0909,
    '793_459': 31.9855,
    '793_460': 31.8794,
    '793_461': 31.7715,
    '793_462': 31.6799,
    '793_463': 31.602,
    '793_464': 31.5402,
    '793_465': 31.5022,
    '793_466': 31.4907,
    '793_467': 31.4888,
    '793_468': 31.5001,
    '793_469': 31.5199,
    '793_470': 31.5429,
    '793_471': 31.5613,
    '793_472': 31.5715,
    '793_473': 31.5796,
    '793_474': 31.5639,
    '793_475': 31.5266,
    '793_476': 31.4884,
    '793_477': 31.4565,
    '793_478': 31.4352,
    '793_479': 31.4257,
    '793_480': 31.4263,
    '793_481': 31.4378,
    '793_482': 31.4599,
    '793_483': 31.4908,
    '793_484': 31.5216,
    '793_485': 31.5559,
    '793_486': 31.5973,
    '793_487': 31.6504,
    '793_488': 31.7184,
    '793_489': 31.7997,
    '793_490': 31.8899,
    '793_491': 31.9855,
    '793_492': 32.0848,
    '793_493': 32.1878,
    '793_494': 32.2943,
    '793_495': 32.4048,
    '793_496': 32.5179,
    '793_497': 32.6352,
    '793_498': 32.7591,
    '793_499': 32.8915,
    '793_500': 33.037,
    '793_501': 33.1978,
    '793_502': 33.3631,
    '793_503': 33.5575,
    '793_504': 33.7747,
    '793_505': 33.956,
    '793_506': 34.0836,
    '793_507': 34.1611,
    '793_508': 34.219,
    '793_509': 34.2949,
    '793_510': 34.3936,
    '793_511': 34.5042,
    '793_512': 34.6052,
    '793_513': 34.7155,
    '793_514': 34.8205,
    '793_515': 34.9276,
    '793_516': 35.0342,
    '793_517': 35.1385,
    '793_518': 35.2358,
    '793_519': 35.3141,
    '793_520': 35.3982,
    '793_521': 35.4768,
    '793_522': 35.5301,
    '793_523': 35.5702,
    '793_524': 35.6049,
    '793_525': 35.6341,
    '793_526': 35.6644,
    '793_527': 35.6868,
    '793_528': 35.6945,
    '793_529': 35.6797,
    '793_530': 35.6408,
    '793_531': 35.6103,
    '793_532': 35.5937,
    '793_533': 35.5841,
    '793_534': 35.5782,
    '793_535': 35.5799,
    '793_536': 35.5918,
    '793_537': 35.6071,
    '793_538': 35.6212,
    '793_539': 35.6332,
    '793_540': 35.6362,
    '793_541': 35.6373,
    '793_542': 35.6393,
    '793_543': 35.6346,
    '793_544': 35.6266,
    '793_545': 35.6157,
    '793_546': 35.6015,
    '793_547': 35.5865,
    '793_548': 35.5718,
    '793_549': 35.5513,
    '793_550': 35.5333,
    '793_551': 35.5263,
    '793_552': 35.5282,
    '793_553': 35.5446,
    '793_554': 35.5638,
    '793_555': 35.5998,
    '793_556': 35.6539,
    '793_557': 35.7172,
    '793_558': 35.7932,
    '793_559': 35.8813,
    '793_560': 35.9674,
    '793_561': 36.0555,
    '793_562': 36.1403,
    '793_563': 36.2185,
    '793_564': 36.2827,
    '793_565': 36.3286,
    '793_566': 36.348,
    '793_567': 36.3213,
    '793_568': 36.2344,
    '793_569': 36.1055,
    '793_570': 35.9918,
    '793_571': 35.8975,
    '793_572': 35.8139,
    '793_573': 35.7415,
    '793_574': 35.6743,
    '793_575': 35.6081,
    '793_576': 35.54,
    '793_577': 35.4798,
    '793_578': 35.432,
    '793_579': 35.3908,
    '793_580': 35.3562,
    '793_581': 35.3278,
    '793_582': 35.3011,
    '793_583': 35.2801,
    '793_584': 35.2555,
    '793_585': 35.2264,
    '793_586': 35.2002,
    '793_587': 35.1627,
    '793_588': 35.1232,
    '793_589': 35.0813,
    '793_590': 35.0408,
    '793_591': 34.9974,
    '793_592': 34.9567,
    '793_593': 34.9177,
    '793_594': 34.8792,
    '793_595': 34.8449,
    '793_596': 34.8523,
    '793_597': 34.8366,
    '793_598': 34.8247,
    '793_599': 34.8141,
    '793_600': 34.7973,
    '793_601': 34.7774,
    '793_602': 34.7581,
    '793_603': 34.7417,
    '793_604': 34.725,
    '793_605': 34.7118,
    '793_606': 34.7019,
    '793_607': 34.6981,
    '793_608': 34.6972,
    '793_609': 34.7154,
    '793_610': 34.7362,
    '793_611': 34.7637,
    '793_612': 34.7923,
    '793_613': 34.8094,
    '793_614': 34.8257,
    '793_615': 34.8441,
    '793_616': 34.8736,
    '793_617': 34.9078,
    '793_618': 34.9492,
    '793_619': 35.0064,
    '793_620': 35.0735,
    '793_621': 35.1488,
    '793_622': 35.2179,
    '793_623': 35.2914,
    '793_624': 35.3652,
    '793_625': 35.4384,
    '793_626': 35.5064,
    '793_627': 35.5684,
    '793_628': 35.625,
    '793_629': 35.6777,
    '793_630': 35.7265,
    '793_631': 35.7675,
    '793_632': 35.8015,
    '793_633': 35.8238,
    '793_634': 35.838,
    '793_635': 35.8194,
    '793_636': 35.7974,
    '793_637': 35.7753,
    '793_638': 35.7476,
    '793_639': 35.7088,
    '793_640': 35.6749,
    '793_641': 35.6425,
    '793_642': 35.6126,
    '793_643': 35.5843,
    '793_644': 35.5554,
    '793_645': 35.52,
    '793_646': 35.4824,
    '793_647': 35.4508,
    '793_648': 35.4396,
    '793_649': 35.4343,
    '793_650': 35.4273,
    '793_651': 35.423,
    '793_652': 35.4234,
    '793_653': 35.431,
    '793_654': 35.438,
    '793_655': 35.4491,
    '793_656': 35.4625,
    '793_657': 35.4743,
    '793_658': 35.4874,
    '793_659': 35.503,
    '793_660': 35.5215,
    '794_350': 30.2519,
    '794_351': 30.3088,
    '794_352': 30.3643,
    '794_353': 30.4184,
    '794_354': 30.4716,
    '794_355': 30.5246,
    '794_356': 30.5778,
    '794_357': 30.6312,
    '794_358': 30.6847,
    '794_359': 30.7384,
    '794_360': 30.7921,
    '794_361': 30.8446,
    '794_362': 30.8953,
    '794_363': 30.943,
    '794_364': 30.9876,
    '794_365': 31.0277,
    '794_366': 31.0629,
    '794_367': 31.0978,
    '794_368': 31.136,
    '794_369': 31.1818,
    '794_370': 31.2373,
    '794_373': 31.2212,
    '794_374': 31.2612,
    '794_375': 31.3059,
    '794_376': 31.3544,
    '794_377': 31.3909,
    '794_378': 31.4332,
    '794_379': 31.467,
    '794_380': 31.4997,
    '794_381': 31.5484,
    '794_382': 31.6093,
    '794_383': 31.6822,
    '794_384': 31.7516,
    '794_385': 31.7994,
    '794_386': 31.8494,
    '794_387': 31.9156,
    '794_388': 31.9774,
    '794_389': 32.0384,
    '794_390': 32.0948,
    '794_391': 32.149,
    '794_392': 32.1963,
    '794_393': 32.1986,
    '794_394': 32.1784,
    '794_395': 32.1997,
    '794_396': 32.2154,
    '794_397': 32.2205,
    '794_398': 32.2377,
    '794_399': 32.2632,
    '794_400': 32.2993,
    '794_401': 32.3235,
    '794_402': 32.3423,
    '794_403': 32.3625,
    '794_404': 32.3935,
    '794_405': 32.4076,
    '794_406': 32.406,
    '794_407': 32.4081,
    '794_408': 32.401,
    '794_409': 32.3944,
    '794_410': 32.3905,
    '794_411': 32.3905,
    '794_412': 32.3954,
    '794_413': 32.4041,
    '794_414': 32.413,
    '794_415': 32.4281,
    '794_416': 32.4504,
    '794_417': 32.4842,
    '794_418': 32.5318,
    '794_419': 32.5905,
    '794_420': 32.6536,
    '794_421': 32.7166,
    '794_422': 32.7727,
    '794_423': 32.824,
    '794_424': 32.8764,
    '794_425': 32.9214,
    '794_426': 32.9592,
    '794_427': 33.0021,
    '794_428': 33.0413,
    '794_429': 33.0778,
    '794_430': 33.1105,
    '794_431': 33.1436,
    '794_432': 33.1743,
    '794_433': 33.1923,
    '794_434': 33.192,
    '794_435': 33.1664,
    '794_436': 33.1248,
    '794_437': 33.0696,
    '794_438': 33.0186,
    '794_439': 32.9682,
    '794_440': 32.9348,
    '794_441': 32.9227,
    '794_442': 32.9235,
    '794_443': 32.9303,
    '794_444': 32.9298,
    '794_445': 32.9133,
    '794_446': 32.8799,
    '794_447': 32.8388,
    '794_448': 32.8111,
    '794_449': 32.7969,
    '794_450': 32.7846,
    '794_451': 32.7615,
    '794_452': 32.7147,
    '794_453': 32.6307,
    '794_454': 32.5483,
    '794_455': 32.4677,
    '794_456': 32.3677,
    '794_457': 32.2452,
    '794_458': 32.1187,
    '794_459': 32.0042,
    '794_460': 31.8796,
    '794_461': 31.7559,
    '794_462': 31.6464,
    '794_463': 31.5556,
    '794_464': 31.4877,
    '794_465': 31.4461,
    '794_466': 31.4282,
    '794_467': 31.4238,
    '794_468': 31.4323,
    '794_469': 31.4493,
    '794_470': 31.4749,
    '794_471': 31.5035,
    '794_472': 31.5278,
    '794_473': 31.5436,
    '794_474': 31.5381,
    '794_475': 31.5176,
    '794_476': 31.4897,
    '794_477': 31.464,
    '794_478': 31.4457,
    '794_479': 31.4374,
    '794_480': 31.44,
    '794_481': 31.4529,
    '794_482': 31.4759,
    '794_483': 31.5048,
    '794_484': 31.5368,
    '794_485': 31.5727,
    '794_486': 31.6147,
    '794_487': 31.6659,
    '794_488': 31.7309,
    '794_489': 31.8107,
    '794_490': 31.8995,
    '794_491': 31.9937,
    '794_492': 32.0919,
    '794_493': 32.1937,
    '794_494': 32.299,
    '794_495': 32.4078,
    '794_496': 32.5207,
    '794_497': 32.6373,
    '794_498': 32.759,
    '794_499': 32.8901,
    '794_500': 33.0329,
    '794_501': 33.1899,
    '794_502': 33.3543,
    '794_503': 33.5329,
    '794_504': 33.7299,
    '794_505': 33.9056,
    '794_506': 34.0364,
    '794_507': 34.1224,
    '794_508': 34.1983,
    '794_509': 34.2955,
    '794_510': 34.4072,
    '794_511': 34.5255,
    '794_512': 34.6358,
    '794_513': 34.7407,
    '794_514': 34.8427,
    '794_515': 34.9557,
    '794_516': 35.0746,
    '794_517': 35.1807,
    '794_518': 35.2815,
    '794_519': 35.3564,
    '794_520': 35.4401,
    '794_521': 35.5243,
    '794_522': 35.5817,
    '794_523': 35.6203,
    '794_524': 35.6496,
    '794_525': 35.6708,
    '794_526': 35.6952,
    '794_527': 35.716,
    '794_528': 35.7231,
    '794_529': 35.7098,
    '794_530': 35.6673,
    '794_531': 35.6281,
    '794_532': 35.6047,
    '794_533': 35.5893,
    '794_534': 35.5856,
    '794_535': 35.5896,
    '794_536': 35.6004,
    '794_537': 35.6145,
    '794_538': 35.6296,
    '794_539': 35.6437,
    '794_540': 35.6475,
    '794_541': 35.6476,
    '794_542': 35.6547,
    '794_543': 35.6604,
    '794_544': 35.6616,
    '794_545': 35.6537,
    '794_546': 35.6414,
    '794_547': 35.6279,
    '794_548': 35.618,
    '794_549': 35.604,
    '794_550': 35.595,
    '794_551': 35.5937,
    '794_552': 35.5979,
    '794_553': 35.6086,
    '794_554': 35.6295,
    '794_555': 35.6727,
    '794_556': 35.7313,
    '794_557': 35.7948,
    '794_558': 35.8721,
    '794_559': 35.9624,
    '794_560': 36.0528,
    '794_561': 36.1396,
    '794_562': 36.224,
    '794_563': 36.3031,
    '794_564': 36.3667,
    '794_565': 36.41,
    '794_566': 36.4244,
    '794_567': 36.3923,
    '794_568': 36.3005,
    '794_569': 36.1732,
    '794_570': 36.0488,
    '794_571': 35.9458,
    '794_572': 35.8557,
    '794_573': 35.7708,
    '794_574': 35.6978,
    '794_575': 35.6371,
    '794_576': 35.571,
    '794_577': 35.5072,
    '794_578': 35.4546,
    '794_579': 35.409,
    '794_580': 35.3695,
    '794_581': 35.3375,
    '794_582': 35.3026,
    '794_583': 35.2759,
    '794_584': 35.2504,
    '794_585': 35.2156,
    '794_586': 35.1819,
    '794_587': 35.1366,
    '794_588': 35.0939,
    '794_589': 35.0527,
    '794_590': 35.0074,
    '794_591': 34.9652,
    '794_592': 34.9249,
    '794_593': 34.8879,
    '794_594': 34.8529,
    '794_595': 34.8254,
    '794_596': 34.8385,
    '794_597': 34.8234,
    '794_598': 34.8132,
    '794_599': 34.8065,
    '794_600': 34.7922,
    '794_601': 34.776,
    '794_602': 34.7589,
    '794_603': 34.7426,
    '794_604': 34.7276,
    '794_605': 34.7178,
    '794_606': 34.7113,
    '794_607': 34.7083,
    '794_608': 34.715,
    '794_609': 34.7324,
    '794_610': 34.7522,
    '794_611': 34.784,
    '794_612': 34.8158,
    '794_613': 34.8402,
    '794_614': 34.8672,
    '794_615': 34.8962,
    '794_616': 34.9355,
    '794_617': 34.9861,
    '794_618': 35.0402,
    '794_619': 35.1036,
    '794_620': 35.1733,
    '794_621': 35.2554,
    '794_622': 35.3361,
    '794_623': 35.4202,
    '794_624': 35.5056,
    '794_625': 35.5902,
    '794_626': 35.67,
    '794_627': 35.7422,
    '794_628': 35.8066,
    '794_629': 35.8642,
    '794_630': 35.9138,
    '794_631': 35.9525,
    '794_632': 35.9799,
    '794_633': 35.9975,
    '794_634': 36.0071,
    '794_635': 35.9946,
    '794_636': 35.9657,
    '794_637': 35.9338,
    '794_638': 35.8958,
    '794_639': 35.8479,
    '794_640': 35.7964,
    '794_641': 35.7492,
    '794_642': 35.7088,
    '794_643': 35.6729,
    '794_644': 35.6376,
    '794_645': 35.5963,
    '794_646': 35.5544,
    '794_647': 35.5196,
    '794_648': 35.4983,
    '794_649': 35.4837,
    '794_650': 35.4709,
    '794_651': 35.4574,
    '794_652': 35.4515,
    '794_653': 35.4507,
    '794_654': 35.4532,
    '794_655': 35.4632,
    '794_656': 35.477,
    '794_657': 35.4878,
    '794_658': 35.5002,
    '794_659': 35.5169,
    '794_660': 35.5386,
    '795_350': 30.2191,
    '795_351': 30.2741,
    '795_352': 30.3277,
    '795_353': 30.3799,
    '795_354': 30.432,
    '795_355': 30.4845,
    '795_356': 30.5377,
    '795_357': 30.5913,
    '795_358': 30.6451,
    '795_359': 30.6989,
    '795_360': 30.7523,
    '795_361': 30.8046,
    '795_362': 30.8551,
    '795_363': 30.9037,
    '795_364': 30.9495,
    '795_365': 30.9894,
    '795_366': 31.0228,
    '795_367': 31.0568,
    '795_368': 31.0931,
    '795_369': 31.1376,
    '795_370': 31.1984,
    '795_373': 31.1866,
    '795_374': 31.225,
    '795_375': 31.269,
    '795_376': 31.3172,
    '795_377': 31.3671,
    '795_378': 31.4175,
    '795_379': 31.4557,
    '795_380': 31.483,
    '795_381': 31.5304,
    '795_382': 31.5927,
    '795_383': 31.6685,
    '795_384': 31.7451,
    '795_385': 31.801,
    '795_386': 31.8469,
    '795_387': 31.9026,
    '795_388': 31.9609,
    '795_389': 32.0201,
    '795_390': 32.0764,
    '795_391': 32.1336,
    '795_392': 32.1771,
    '795_393': 32.1772,
    '795_394': 32.1541,
    '795_395': 32.1713,
    '795_396': 32.1846,
    '795_397': 32.2034,
    '795_398': 32.2267,
    '795_399': 32.262,
    '795_400': 32.3072,
    '795_401': 32.3511,
    '795_402': 32.3762,
    '795_403': 32.3951,
    '795_404': 32.4268,
    '795_405': 32.4458,
    '795_406': 32.4494,
    '795_407': 32.4474,
    '795_408': 32.4418,
    '795_409': 32.4353,
    '795_410': 32.4279,
    '795_411': 32.4244,
    '795_412': 32.4232,
    '795_413': 32.4228,
    '795_414': 32.4241,
    '795_415': 32.432,
    '795_416': 32.447,
    '795_417': 32.4761,
    '795_418': 32.5219,
    '795_419': 32.5784,
    '795_420': 32.6386,
    '795_421': 32.7033,
    '795_422': 32.7648,
    '795_423': 32.825,
    '795_424': 32.8796,
    '795_425': 32.9242,
    '795_426': 32.9589,
    '795_427': 32.9921,
    '795_428': 33.0247,
    '795_429': 33.0559,
    '795_430': 33.0808,
    '795_431': 33.1091,
    '795_432': 33.1361,
    '795_433': 33.1499,
    '795_434': 33.1472,
    '795_435': 33.1281,
    '795_436': 33.0854,
    '795_437': 33.0327,
    '795_438': 32.9814,
    '795_439': 32.936,
    '795_440': 32.9115,
    '795_441': 32.9048,
    '795_442': 32.9103,
    '795_443': 32.9181,
    '795_444': 32.9103,
    '795_445': 32.893,
    '795_446': 32.8604,
    '795_447': 32.8255,
    '795_448': 32.808,
    '795_449': 32.8008,
    '795_450': 32.7887,
    '795_451': 32.7643,
    '795_452': 32.7169,
    '795_453': 32.6358,
    '795_454': 32.556,
    '795_455': 32.4828,
    '795_456': 32.3887,
    '795_457': 32.2719,
    '795_458': 32.1486,
    '795_459': 32.0201,
    '795_460': 31.8779,
    '795_461': 31.7425,
    '795_462': 31.6145,
    '795_463': 31.5171,
    '795_464': 31.4464,
    '795_465': 31.3999,
    '795_466': 31.3748,
    '795_467': 31.3661,
    '795_468': 31.3687,
    '795_469': 31.38,
    '795_470': 31.4022,
    '795_471': 31.4324,
    '795_472': 31.4633,
    '795_473': 31.4894,
    '795_474': 31.5007,
    '795_475': 31.4982,
    '795_476': 31.4864,
    '795_477': 31.4665,
    '795_478': 31.4531,
    '795_479': 31.4481,
    '795_480': 31.4532,
    '795_481': 31.4692,
    '795_482': 31.4895,
    '795_483': 31.5188,
    '795_484': 31.5528,
    '795_485': 31.5905,
    '795_486': 31.6336,
    '795_487': 31.6845,
    '795_488': 31.7468,
    '795_489': 31.8252,
    '795_490': 31.9124,
    '795_491': 32.0049,
    '795_492': 32.1015,
    '795_493': 32.2018,
    '795_494': 32.3056,
    '795_495': 32.4129,
    '795_496': 32.5254,
    '795_497': 32.6414,
    '795_498': 32.7622,
    '795_499': 32.8922,
    '795_500': 33.0334,
    '795_501': 33.1887,
    '795_502': 33.3591,
    '795_503': 33.5282,
    '795_504': 33.7103,
    '795_505': 33.8649,
    '795_506': 33.9866,
    '795_507': 34.0866,
    '795_508': 34.1882,
    '795_509': 34.3073,
    '795_510': 34.4367,
    '795_511': 34.5597,
    '795_512': 34.6744,
    '795_513': 34.7762,
    '795_514': 34.8852,
    '795_515': 35.0049,
    '795_516': 35.1265,
    '795_517': 35.2357,
    '795_518': 35.3263,
    '795_519': 35.3991,
    '795_520': 35.4865,
    '795_521': 35.572,
    '795_522': 35.6287,
    '795_523': 35.6693,
    '795_524': 35.6918,
    '795_525': 35.7071,
    '795_526': 35.7282,
    '795_527': 35.7454,
    '795_528': 35.7504,
    '795_529': 35.7308,
    '795_530': 35.6888,
    '795_531': 35.6486,
    '795_532': 35.6181,
    '795_533': 35.6011,
    '795_534': 35.5967,
    '795_535': 35.6015,
    '795_536': 35.6117,
    '795_537': 35.6237,
    '795_538': 35.637,
    '795_539': 35.652,
    '795_540': 35.659,
    '795_541': 35.6557,
    '795_542': 35.6703,
    '795_543': 35.6862,
    '795_544': 35.6951,
    '795_545': 35.6928,
    '795_546': 35.6851,
    '795_547': 35.6758,
    '795_548': 35.6669,
    '795_549': 35.6579,
    '795_550': 35.6577,
    '795_551': 35.6603,
    '795_552': 35.6588,
    '795_553': 35.6753,
    '795_554': 35.7041,
    '795_555': 35.7501,
    '795_556': 35.8124,
    '795_557': 35.8849,
    '795_558': 35.9664,
    '795_559': 36.0512,
    '795_560': 36.1411,
    '795_561': 36.2279,
    '795_562': 36.3146,
    '795_563': 36.3953,
    '795_564': 36.4562,
    '795_565': 36.4959,
    '795_566': 36.5077,
    '795_567': 36.4773,
    '795_568': 36.3744,
    '795_569': 36.2414,
    '795_570': 36.1062,
    '795_571': 35.9928,
    '795_572': 35.8966,
    '795_573': 35.8134,
    '795_574': 35.7352,
    '795_575': 35.6683,
    '795_576': 35.6003,
    '795_577': 35.5379,
    '795_578': 35.484,
    '795_579': 35.4352,
    '795_580': 35.3907,
    '795_581': 35.3522,
    '795_582': 35.3149,
    '795_583': 35.2833,
    '795_584': 35.2525,
    '795_585': 35.2154,
    '795_586': 35.1784,
    '795_587': 35.1349,
    '795_588': 35.0887,
    '795_589': 35.0425,
    '795_590': 34.9996,
    '795_591': 34.9578,
    '795_592': 34.915,
    '795_593': 34.8706,
    '795_594': 34.8348,
    '795_595': 34.8103,
    '795_596': 34.8269,
    '795_597': 34.8136,
    '795_598': 34.8044,
    '795_599': 34.7989,
    '795_600': 34.7884,
    '795_601': 34.7759,
    '795_602': 34.7633,
    '795_603': 34.7477,
    '795_604': 34.7344,
    '795_605': 34.7267,
    '795_606': 34.7244,
    '795_607': 34.7278,
    '795_608': 34.7396,
    '795_609': 34.7602,
    '795_610': 34.7853,
    '795_611': 34.8147,
    '795_612': 34.8472,
    '795_613': 34.8778,
    '795_614': 34.9126,
    '795_615': 34.9532,
    '795_616': 35.0045,
    '795_617': 35.0661,
    '795_618': 35.1338,
    '795_619': 35.2028,
    '795_620': 35.2822,
    '795_621': 35.3699,
    '795_622': 35.4619,
    '795_623': 35.5559,
    '795_624': 35.6532,
    '795_625': 35.7521,
    '795_626': 35.8468,
    '795_627': 35.9291,
    '795_628': 35.9987,
    '795_629': 36.0599,
    '795_630': 36.1109,
    '795_631': 36.1487,
    '795_632': 36.1696,
    '795_633': 36.1832,
    '795_634': 36.1879,
    '795_635': 36.172,
    '795_636': 36.1381,
    '795_637': 36.0974,
    '795_638': 36.0485,
    '795_639': 35.9876,
    '795_640': 35.9243,
    '795_641': 35.8574,
    '795_642': 35.8034,
    '795_643': 35.7585,
    '795_644': 35.7163,
    '795_645': 35.6687,
    '795_646': 35.6221,
    '795_647': 35.5827,
    '795_648': 35.5512,
    '795_649': 35.5268,
    '795_650': 35.5067,
    '795_651': 35.4871,
    '795_652': 35.4737,
    '795_653': 35.4655,
    '795_654': 35.4599,
    '795_655': 35.4676,
    '795_656': 35.4812,
    '795_657': 35.4906,
    '795_658': 35.5024,
    '795_659': 35.5191,
    '795_660': 35.5421,
    '796_350': 30.1897,
    '796_351': 30.242,
    '796_352': 30.2918,
    '796_353': 30.341,
    '796_354': 30.3912,
    '796_355': 30.443,
    '796_356': 30.4961,
    '796_357': 30.55,
    '796_358': 30.6043,
    '796_359': 30.6586,
    '796_360': 30.712,
    '796_361': 30.7644,
    '796_362': 30.8151,
    '796_363': 30.8651,
    '796_364': 30.9153,
    '796_365': 30.9542,
    '796_366': 30.9851,
    '796_367': 31.0177,
    '796_368': 31.0509,
    '796_369': 31.0895,
    '796_370': 31.1535,
    '796_373': 31.1456,
    '796_374': 31.1866,
    '796_375': 31.2324,
    '796_376': 31.2835,
    '796_377': 31.3426,
    '796_378': 31.4002,
    '796_379': 31.4397,
    '796_380': 31.4671,
    '796_381': 31.5108,
    '796_382': 31.57,
    '796_383': 31.6407,
    '796_384': 31.7186,
    '796_385': 31.7851,
    '796_386': 31.8336,
    '796_387': 31.8869,
    '796_388': 31.9375,
    '796_389': 31.995,
    '796_390': 32.056,
    '796_391': 32.1102,
    '796_392': 32.1414,
    '796_393': 32.1361,
    '796_394': 32.1196,
    '796_395': 32.1344,
    '796_396': 32.1575,
    '796_397': 32.1876,
    '796_398': 32.2175,
    '796_399': 32.265,
    '796_400': 32.3248,
    '796_401': 32.3805,
    '796_402': 32.4133,
    '796_403': 32.4341,
    '796_404': 32.4583,
    '796_405': 32.4792,
    '796_406': 32.4908,
    '796_407': 32.4908,
    '796_408': 32.4867,
    '796_409': 32.4802,
    '796_410': 32.4709,
    '796_411': 32.4636,
    '796_412': 32.457,
    '796_413': 32.4502,
    '796_414': 32.4441,
    '796_415': 32.4462,
    '796_416': 32.4553,
    '796_417': 32.4791,
    '796_418': 32.5175,
    '796_419': 32.5663,
    '796_420': 32.6217,
    '796_421': 32.6847,
    '796_422': 32.7519,
    '796_423': 32.8235,
    '796_424': 32.8794,
    '796_425': 32.924,
    '796_426': 32.9603,
    '796_427': 32.9841,
    '796_428': 33.0136,
    '796_429': 33.0348,
    '796_430': 33.0509,
    '796_431': 33.0682,
    '796_432': 33.0815,
    '796_433': 33.088,
    '796_434': 33.0868,
    '796_435': 33.0745,
    '796_436': 33.038,
    '796_437': 32.9875,
    '796_438': 32.9436,
    '796_439': 32.9078,
    '796_440': 32.8938,
    '796_441': 32.8928,
    '796_442': 32.9056,
    '796_443': 32.9071,
    '796_444': 32.8912,
    '796_445': 32.8651,
    '796_446': 32.8369,
    '796_447': 32.8138,
    '796_448': 32.8056,
    '796_449': 32.8016,
    '796_450': 32.7899,
    '796_451': 32.7659,
    '796_452': 32.7201,
    '796_453': 32.6499,
    '796_454': 32.5665,
    '796_455': 32.4961,
    '796_456': 32.4105,
    '796_457': 32.3001,
    '796_458': 32.168,
    '796_459': 32.0222,
    '796_460': 31.8588,
    '796_461': 31.7117,
    '796_462': 31.5814,
    '796_463': 31.4846,
    '796_464': 31.4122,
    '796_465': 31.3606,
    '796_466': 31.3306,
    '796_467': 31.3166,
    '796_468': 31.3131,
    '796_469': 31.3186,
    '796_470': 31.3347,
    '796_471': 31.3625,
    '796_472': 31.3925,
    '796_473': 31.4229,
    '796_474': 31.4505,
    '796_475': 31.4631,
    '796_476': 31.4656,
    '796_477': 31.4569,
    '796_478': 31.452,
    '796_479': 31.4527,
    '796_480': 31.4633,
    '796_481': 31.4807,
    '796_482': 31.4999,
    '796_483': 31.534,
    '796_484': 31.5705,
    '796_485': 31.6098,
    '796_486': 31.6543,
    '796_487': 31.706,
    '796_488': 31.7676,
    '796_489': 31.8439,
    '796_490': 31.9288,
    '796_491': 32.019,
    '796_492': 32.1135,
    '796_493': 32.2123,
    '796_494': 32.3146,
    '796_495': 32.4208,
    '796_496': 32.5323,
    '796_497': 32.6481,
    '796_498': 32.7686,
    '796_499': 32.8991,
    '796_500': 33.0412,
    '796_501': 33.1926,
    '796_502': 33.3542,
    '796_503': 33.5239,
    '796_504': 33.6942,
    '796_505': 33.8376,
    '796_506': 33.9557,
    '796_507': 34.0708,
    '796_508': 34.1908,
    '796_509': 34.3161,
    '796_510': 34.461,
    '796_511': 34.5986,
    '796_512': 34.7176,
    '796_513': 34.823,
    '796_514': 34.9363,
    '796_515': 35.056,
    '796_516': 35.1771,
    '796_517': 35.281,
    '796_518': 35.3603,
    '796_519': 35.4432,
    '796_520': 35.5303,
    '796_521': 35.617,
    '796_522': 35.6726,
    '796_523': 35.7097,
    '796_524': 35.7261,
    '796_525': 35.7422,
    '796_526': 35.7616,
    '796_527': 35.7738,
    '796_528': 35.7699,
    '796_529': 35.7394,
    '796_530': 35.7036,
    '796_531': 35.6642,
    '796_532': 35.6318,
    '796_533': 35.6132,
    '796_534': 35.6088,
    '796_535': 35.6147,
    '796_536': 35.6253,
    '796_537': 35.6356,
    '796_538': 35.6475,
    '796_539': 35.6632,
    '796_540': 35.6786,
    '796_541': 35.6797,
    '796_542': 35.6958,
    '796_543': 35.7166,
    '796_544': 35.7322,
    '796_545': 35.7338,
    '796_546': 35.7316,
    '796_547': 35.728,
    '796_548': 35.7236,
    '796_549': 35.7196,
    '796_550': 35.724,
    '796_551': 35.729,
    '796_552': 35.7323,
    '796_553': 35.751,
    '796_554': 35.7835,
    '796_555': 35.8322,
    '796_556': 35.9013,
    '796_557': 35.9745,
    '796_558': 36.0546,
    '796_559': 36.141,
    '796_560': 36.2312,
    '796_561': 36.3231,
    '796_562': 36.412,
    '796_563': 36.4885,
    '796_564': 36.5471,
    '796_565': 36.5834,
    '796_566': 36.592,
    '796_567': 36.5606,
    '796_568': 36.4493,
    '796_569': 36.3114,
    '796_570': 36.1678,
    '796_571': 36.0416,
    '796_572': 35.9378,
    '796_573': 35.8571,
    '796_574': 35.7854,
    '796_575': 35.7169,
    '796_576': 35.6357,
    '796_577': 35.5751,
    '796_578': 35.5215,
    '796_579': 35.4687,
    '796_580': 35.42,
    '796_581': 35.3746,
    '796_582': 35.3328,
    '796_583': 35.298,
    '796_584': 35.26,
    '796_585': 35.215,
    '796_586': 35.175,
    '796_587': 35.1294,
    '796_588': 35.0815,
    '796_589': 35.0415,
    '796_590': 35.0023,
    '796_591': 34.9626,
    '796_592': 34.9219,
    '796_593': 34.8773,
    '796_594': 34.8374,
    '796_595': 34.8055,
    '796_596': 34.825,
    '796_597': 34.8174,
    '796_598': 34.8107,
    '796_599': 34.8045,
    '796_600': 34.7916,
    '796_601': 34.7803,
    '796_602': 34.7713,
    '796_603': 34.7581,
    '796_604': 34.7471,
    '796_605': 34.7421,
    '796_606': 34.7442,
    '796_607': 34.7528,
    '796_608': 34.7683,
    '796_609': 34.7913,
    '796_610': 34.8203,
    '796_611': 34.8541,
    '796_612': 34.8904,
    '796_613': 34.9244,
    '796_614': 34.9663,
    '796_615': 35.0184,
    '796_616': 35.0784,
    '796_617': 35.1467,
    '796_618': 35.2222,
    '796_619': 35.3021,
    '796_620': 35.3919,
    '796_621': 35.4889,
    '796_622': 35.5911,
    '796_623': 35.6956,
    '796_624': 35.8036,
    '796_625': 35.92,
    '796_626': 36.039,
    '796_627': 36.1365,
    '796_628': 36.2047,
    '796_629': 36.2647,
    '796_630': 36.3144,
    '796_631': 36.3495,
    '796_632': 36.3707,
    '796_633': 36.3785,
    '796_634': 36.383,
    '796_635': 36.3567,
    '796_636': 36.3136,
    '796_637': 36.2641,
    '796_638': 36.2,
    '796_639': 36.1254,
    '796_640': 36.0446,
    '796_641': 35.9688,
    '796_642': 35.8979,
    '796_643': 35.8406,
    '796_644': 35.7881,
    '796_645': 35.7325,
    '796_646': 35.6792,
    '796_647': 35.633,
    '796_648': 35.5932,
    '796_649': 35.5597,
    '796_650': 35.5308,
    '796_651': 35.5063,
    '796_652': 35.487,
    '796_653': 35.4732,
    '796_654': 35.4639,
    '796_655': 35.4651,
    '796_656': 35.4755,
    '796_657': 35.4829,
    '796_658': 35.4941,
    '796_659': 35.5096,
    '796_660': 35.5318,
    '797_350': 30.1574,
    '797_351': 30.2093,
    '797_352': 30.2551,
    '797_353': 30.3004,
    '797_354': 30.3489,
    '797_355': 30.4005,
    '797_356': 30.4539,
    '797_357': 30.5086,
    '797_358': 30.5635,
    '797_359': 30.6183,
    '797_360': 30.6723,
    '797_361': 30.725,
    '797_362': 30.7761,
    '797_363': 30.8261,
    '797_364': 30.8753,
    '797_365': 30.9167,
    '797_366': 30.951,
    '797_367': 30.987,
    '797_368': 31.025,
    '797_369': 31.0694,
    '797_370': 31.1267,
    '797_373': 31.1027,
    '797_374': 31.147,
    '797_375': 31.1963,
    '797_376': 31.2505,
    '797_377': 31.3113,
    '797_378': 31.3751,
    '797_379': 31.418,
    '797_380': 31.4513,
    '797_381': 31.4918,
    '797_382': 31.546,
    '797_383': 31.6124,
    '797_384': 31.6882,
    '797_385': 31.7543,
    '797_386': 31.8115,
    '797_387': 31.8691,
    '797_388': 31.919,
    '797_389': 31.9728,
    '797_390': 32.0268,
    '797_391': 32.0715,
    '797_392': 32.0947,
    '797_393': 32.0837,
    '797_394': 32.0845,
    '797_395': 32.1071,
    '797_396': 32.1389,
    '797_397': 32.1734,
    '797_398': 32.2125,
    '797_399': 32.2615,
    '797_400': 32.3349,
    '797_401': 32.4072,
    '797_402': 32.4553,
    '797_403': 32.4778,
    '797_404': 32.5023,
    '797_405': 32.5293,
    '797_406': 32.5452,
    '797_407': 32.5487,
    '797_408': 32.5449,
    '797_409': 32.5354,
    '797_410': 32.5233,
    '797_411': 32.5113,
    '797_412': 32.4994,
    '797_413': 32.4894,
    '797_414': 32.4775,
    '797_415': 32.4743,
    '797_416': 32.4781,
    '797_417': 32.494,
    '797_418': 32.5243,
    '797_419': 32.5623,
    '797_420': 32.6087,
    '797_421': 32.6657,
    '797_422': 32.7303,
    '797_423': 32.799,
    '797_424': 32.8588,
    '797_425': 32.9111,
    '797_426': 32.9474,
    '797_427': 32.9674,
    '797_428': 32.9955,
    '797_429': 33.0147,
    '797_430': 33.0185,
    '797_431': 33.0189,
    '797_432': 33.015,
    '797_433': 33.0163,
    '797_434': 33.0149,
    '797_435': 33.0015,
    '797_436': 32.9714,
    '797_437': 32.9331,
    '797_438': 32.9034,
    '797_439': 32.8834,
    '797_440': 32.8805,
    '797_441': 32.8878,
    '797_442': 32.9014,
    '797_443': 32.8993,
    '797_444': 32.8789,
    '797_445': 32.8528,
    '797_446': 32.8297,
    '797_447': 32.8168,
    '797_448': 32.809,
    '797_449': 32.7993,
    '797_450': 32.7855,
    '797_451': 32.7599,
    '797_452': 32.721,
    '797_453': 32.6604,
    '797_454': 32.5828,
    '797_455': 32.5088,
    '797_456': 32.4208,
    '797_457': 32.3143,
    '797_458': 32.1771,
    '797_459': 32.0068,
    '797_460': 31.8329,
    '797_461': 31.68,
    '797_462': 31.557,
    '797_463': 31.4603,
    '797_464': 31.3852,
    '797_465': 31.3303,
    '797_466': 31.2957,
    '797_467': 31.2761,
    '797_468': 31.2687,
    '797_469': 31.268,
    '797_470': 31.2774,
    '797_471': 31.2977,
    '797_472': 31.3255,
    '797_473': 31.3547,
    '797_474': 31.386,
    '797_475': 31.4087,
    '797_476': 31.425,
    '797_477': 31.4337,
    '797_478': 31.4394,
    '797_479': 31.4486,
    '797_480': 31.4681,
    '797_481': 31.488,
    '797_482': 31.5168,
    '797_483': 31.5555,
    '797_484': 31.5911,
    '797_485': 31.6307,
    '797_486': 31.6767,
    '797_487': 31.7301,
    '797_488': 31.7929,
    '797_489': 31.8666,
    '797_490': 31.9484,
    '797_491': 32.0359,
    '797_492': 32.1282,
    '797_493': 32.225,
    '797_494': 32.3258,
    '797_495': 32.4312,
    '797_496': 32.5429,
    '797_497': 32.6591,
    '797_498': 32.7796,
    '797_499': 32.9092,
    '797_500': 33.0511,
    '797_501': 33.1986,
    '797_502': 33.3576,
    '797_503': 33.5246,
    '797_504': 33.677,
    '797_505': 33.818,
    '797_506': 33.9465,
    '797_507': 34.0719,
    '797_508': 34.1959,
    '797_509': 34.3279,
    '797_510': 34.4767,
    '797_511': 34.6199,
    '797_512': 34.7492,
    '797_513': 34.8697,
    '797_514': 34.9897,
    '797_515': 35.1066,
    '797_516': 35.2212,
    '797_517': 35.3165,
    '797_518': 35.3892,
    '797_519': 35.4818,
    '797_520': 35.5725,
    '797_521': 35.658,
    '797_522': 35.7126,
    '797_523': 35.745,
    '797_524': 35.7596,
    '797_525': 35.7797,
    '797_526': 35.7963,
    '797_527': 35.8033,
    '797_528': 35.7946,
    '797_529': 35.7597,
    '797_530': 35.7174,
    '797_531': 35.6811,
    '797_532': 35.6481,
    '797_533': 35.6268,
    '797_534': 35.6227,
    '797_535': 35.63,
    '797_536': 35.6422,
    '797_537': 35.6496,
    '797_538': 35.6586,
    '797_539': 35.6728,
    '797_540': 35.6944,
    '797_541': 35.7103,
    '797_542': 35.7272,
    '797_543': 35.7501,
    '797_544': 35.7692,
    '797_545': 35.7752,
    '797_546': 35.7802,
    '797_547': 35.7842,
    '797_548': 35.7875,
    '797_549': 35.7911,
    '797_550': 35.7956,
    '797_551': 35.7997,
    '797_552': 35.8076,
    '797_553': 35.8313,
    '797_554': 35.8678,
    '797_555': 35.9203,
    '797_556': 35.9882,
    '797_557': 36.0627,
    '797_558': 36.1429,
    '797_559': 36.2336,
    '797_560': 36.3272,
    '797_561': 36.4215,
    '797_562': 36.5079,
    '797_563': 36.5827,
    '797_564': 36.6404,
    '797_565': 36.6743,
    '797_566': 36.679,
    '797_567': 36.6406,
    '797_568': 36.5275,
    '797_569': 36.386,
    '797_570': 36.2368,
    '797_571': 36.0996,
    '797_572': 35.9934,
    '797_573': 35.9102,
    '797_574': 35.8386,
    '797_575': 35.7674,
    '797_576': 35.6929,
    '797_577': 35.6303,
    '797_578': 35.5697,
    '797_579': 35.5109,
    '797_580': 35.4546,
    '797_581': 35.4046,
    '797_582': 35.3563,
    '797_583': 35.3166,
    '797_584': 35.2792,
    '797_585': 35.2305,
    '797_586': 35.1808,
    '797_587': 35.1328,
    '797_588': 35.0848,
    '797_589': 35.0388,
    '797_590': 35.0009,
    '797_591': 34.9624,
    '797_592': 34.9208,
    '797_593': 34.8781,
    '797_594': 34.8452,
    '797_595': 34.8128,
    '797_596': 34.8282,
    '797_597': 34.8239,
    '797_598': 34.8184,
    '797_599': 34.8118,
    '797_600': 34.8033,
    '797_601': 34.795,
    '797_602': 34.7874,
    '797_603': 34.7801,
    '797_604': 34.7737,
    '797_605': 34.7714,
    '797_606': 34.7757,
    '797_607': 34.7852,
    '797_608': 34.8021,
    '797_609': 34.8279,
    '797_610': 34.8608,
    '797_611': 34.8992,
    '797_612': 34.9387,
    '797_613': 34.9766,
    '797_614': 35.0292,
    '797_615': 35.0908,
    '797_616': 35.1608,
    '797_617': 35.2374,
    '797_618': 35.3195,
    '797_619': 35.4069,
    '797_620': 35.507,
    '797_621': 35.6131,
    '797_622': 35.7269,
    '797_623': 35.8433,
    '797_624': 35.9655,
    '797_625': 36.1011,
    '797_626': 36.2483,
    '797_627': 36.385,
    '797_628': 36.436,
    '797_629': 36.4785,
    '797_630': 36.5227,
    '797_631': 36.5596,
    '797_632': 36.582,
    '797_633': 36.5813,
    '797_634': 36.5697,
    '797_635': 36.5425,
    '797_636': 36.4914,
    '797_637': 36.4305,
    '797_638': 36.358,
    '797_639': 36.2656,
    '797_640': 36.1722,
    '797_641': 36.0838,
    '797_642': 35.9981,
    '797_643': 35.9219,
    '797_644': 35.8537,
    '797_645': 35.7879,
    '797_646': 35.7261,
    '797_647': 35.6711,
    '797_648': 35.6257,
    '797_649': 35.586,
    '797_650': 35.5506,
    '797_651': 35.5188,
    '797_652': 35.4922,
    '797_653': 35.4724,
    '797_654': 35.4615,
    '797_655': 35.4606,
    '797_656': 35.4664,
    '797_657': 35.4694,
    '797_658': 35.4804,
    '797_659': 35.4944,
    '797_660': 35.5153,
    '798_350': 30.1256,
    '798_351': 30.1748,
    '798_352': 30.2163,
    '798_353': 30.2578,
    '798_354': 30.3064,
    '798_355': 30.3587,
    '798_356': 30.4129,
    '798_357': 30.4683,
    '798_358': 30.5242,
    '798_359': 30.58,
    '798_360': 30.6349,
    '798_361': 30.6884,
    '798_362': 30.7401,
    '798_363': 30.7901,
    '798_364': 30.8375,
    '798_365': 30.8806,
    '798_366': 30.9202,
    '798_367': 30.9611,
    '798_368': 31.0041,
    '798_369': 31.0506,
    '798_370': 31.1004,
    '798_373': 31.0584,
    '798_374': 31.1085,
    '798_375': 31.1626,
    '798_376': 31.2208,
    '798_377': 31.2845,
    '798_378': 31.3528,
    '798_379': 31.4012,
    '798_380': 31.4386,
    '798_381': 31.4736,
    '798_382': 31.5225,
    '798_383': 31.5872,
    '798_384': 31.659,
    '798_385': 31.7237,
    '798_386': 31.7893,
    '798_387': 31.8533,
    '798_388': 31.9009,
    '798_389': 31.9462,
    '798_390': 31.9981,
    '798_391': 32.0346,
    '798_392': 32.0457,
    '798_393': 32.0379,
    '798_394': 32.0548,
    '798_395': 32.0917,
    '798_396': 32.1314,
    '798_397': 32.1593,
    '798_398': 32.2033,
    '798_399': 32.2544,
    '798_400': 32.3307,
    '798_401': 32.4094,
    '798_402': 32.4718,
    '798_403': 32.5157,
    '798_404': 32.5567,
    '798_405': 32.6013,
    '798_406': 32.6293,
    '798_407': 32.6247,
    '798_408': 32.6159,
    '798_409': 32.6015,
    '798_410': 32.5825,
    '798_411': 32.5652,
    '798_412': 32.5526,
    '798_413': 32.5398,
    '798_414': 32.525,
    '798_415': 32.5171,
    '798_416': 32.5162,
    '798_417': 32.5275,
    '798_418': 32.5475,
    '798_419': 32.5738,
    '798_420': 32.6084,
    '798_421': 32.6532,
    '798_422': 32.7097,
    '798_423': 32.7639,
    '798_424': 32.8127,
    '798_425': 32.8647,
    '798_426': 32.9084,
    '798_427': 32.9348,
    '798_428': 32.959,
    '798_429': 32.9734,
    '798_430': 32.9706,
    '798_431': 32.9567,
    '798_432': 32.9476,
    '798_433': 32.9492,
    '798_434': 32.9479,
    '798_435': 32.9335,
    '798_436': 32.9072,
    '798_437': 32.8839,
    '798_438': 32.869,
    '798_439': 32.869,
    '798_440': 32.8765,
    '798_441': 32.8922,
    '798_442': 32.9086,
    '798_443': 32.9071,
    '798_444': 32.8843,
    '798_445': 32.8593,
    '798_446': 32.8359,
    '798_447': 32.8269,
    '798_448': 32.8162,
    '798_449': 32.7994,
    '798_450': 32.7781,
    '798_451': 32.7506,
    '798_452': 32.7181,
    '798_453': 32.6649,
    '798_454': 32.591,
    '798_455': 32.5134,
    '798_456': 32.4292,
    '798_457': 32.3219,
    '798_458': 32.1764,
    '798_459': 31.9967,
    '798_460': 31.8238,
    '798_461': 31.673,
    '798_462': 31.5485,
    '798_463': 31.4525,
    '798_464': 31.3733,
    '798_465': 31.3149,
    '798_466': 31.2755,
    '798_467': 31.2518,
    '798_468': 31.2387,
    '798_469': 31.2329,
    '798_470': 31.2346,
    '798_471': 31.2464,
    '798_472': 31.2647,
    '798_473': 31.2897,
    '798_474': 31.3173,
    '798_475': 31.3495,
    '798_476': 31.3797,
    '798_477': 31.4038,
    '798_478': 31.42,
    '798_479': 31.4366,
    '798_480': 31.4682,
    '798_481': 31.5006,
    '798_482': 31.5377,
    '798_483': 31.5772,
    '798_484': 31.6118,
    '798_485': 31.6523,
    '798_486': 31.6996,
    '798_487': 31.7546,
    '798_488': 31.8181,
    '798_489': 31.8906,
    '798_490': 31.9704,
    '798_491': 32.056,
    '798_492': 32.1463,
    '798_493': 32.2414,
    '798_494': 32.3409,
    '798_495': 32.4462,
    '798_496': 32.5611,
    '798_497': 32.6797,
    '798_498': 32.8007,
    '798_499': 32.9264,
    '798_500': 33.0629,
    '798_501': 33.2103,
    '798_502': 33.3695,
    '798_503': 33.531,
    '798_504': 33.6792,
    '798_505': 33.8208,
    '798_506': 33.9576,
    '798_507': 34.0835,
    '798_508': 34.2068,
    '798_509': 34.3361,
    '798_510': 34.4776,
    '798_511': 34.6217,
    '798_512': 34.761,
    '798_513': 34.895,
    '798_514': 35.027,
    '798_515': 35.1533,
    '798_516': 35.2683,
    '798_517': 35.3538,
    '798_518': 35.4216,
    '798_519': 35.5102,
    '798_520': 35.6088,
    '798_521': 35.692,
    '798_522': 35.7438,
    '798_523': 35.7736,
    '798_524': 35.7918,
    '798_525': 35.8194,
    '798_526': 35.8351,
    '798_527': 35.8316,
    '798_528': 35.8115,
    '798_529': 35.779,
    '798_530': 35.7369,
    '798_531': 35.7031,
    '798_532': 35.6723,
    '798_533': 35.6521,
    '798_534': 35.644,
    '798_535': 35.6498,
    '798_536': 35.659,
    '798_537': 35.6666,
    '798_538': 35.6762,
    '798_539': 35.6917,
    '798_540': 35.7142,
    '798_541': 35.7384,
    '798_542': 35.7622,
    '798_543': 35.7873,
    '798_544': 35.8109,
    '798_545': 35.8225,
    '798_546': 35.832,
    '798_547': 35.8414,
    '798_548': 35.8506,
    '798_549': 35.8567,
    '798_550': 35.8649,
    '798_551': 35.8739,
    '798_552': 35.8892,
    '798_553': 35.9184,
    '798_554': 35.9597,
    '798_555': 36.0131,
    '798_556': 36.0799,
    '798_557': 36.1558,
    '798_558': 36.24,
    '798_559': 36.3305,
    '798_560': 36.4258,
    '798_561': 36.5176,
    '798_562': 36.6037,
    '798_563': 36.6789,
    '798_564': 36.7395,
    '798_565': 36.7768,
    '798_566': 36.7765,
    '798_567': 36.7281,
    '798_568': 36.6145,
    '798_569': 36.4669,
    '798_570': 36.3156,
    '798_571': 36.176,
    '798_572': 36.0618,
    '798_573': 35.9719,
    '798_574': 35.8944,
    '798_575': 35.8255,
    '798_576': 35.7525,
    '798_577': 35.6822,
    '798_578': 35.6242,
    '798_579': 35.5634,
    '798_580': 35.5029,
    '798_581': 35.4434,
    '798_582': 35.3857,
    '798_583': 35.3474,
    '798_584': 35.3012,
    '798_585': 35.2487,
    '798_586': 35.1957,
    '798_587': 35.1464,
    '798_588': 35.0977,
    '798_589': 35.0457,
    '798_590': 35.0076,
    '798_591': 34.9681,
    '798_592': 34.9249,
    '798_593': 34.8798,
    '798_594': 34.844,
    '798_595': 34.8159,
    '798_596': 34.8268,
    '798_597': 34.8198,
    '798_598': 34.8165,
    '798_599': 34.8174,
    '798_600': 34.8148,
    '798_601': 34.8121,
    '798_602': 34.8086,
    '798_603': 34.805,
    '798_604': 34.802,
    '798_605': 34.8017,
    '798_606': 34.8114,
    '798_607': 34.8254,
    '798_608': 34.8457,
    '798_609': 34.8757,
    '798_610': 34.9124,
    '798_611': 34.9541,
    '798_612': 34.9985,
    '798_613': 35.0468,
    '798_614': 35.1064,
    '798_615': 35.1769,
    '798_616': 35.2548,
    '798_617': 35.3392,
    '798_618': 35.429,
    '798_619': 35.5268,
    '798_620': 35.6347,
    '798_621': 35.7491,
    '798_622': 35.8697,
    '798_623': 35.9957,
    '798_624': 36.1251,
    '798_625': 36.2648,
    '798_626': 36.4096,
    '798_627': 36.5392,
    '798_628': 36.6195,
    '798_629': 36.6787,
    '798_630': 36.7291,
    '798_631': 36.7677,
    '798_632': 36.7941,
    '798_633': 36.7989,
    '798_634': 36.7885,
    '798_635': 36.7483,
    '798_636': 36.6848,
    '798_637': 36.6123,
    '798_638': 36.5196,
    '798_639': 36.4124,
    '798_640': 36.3035,
    '798_641': 36.2003,
    '798_642': 36.0949,
    '798_643': 36.0006,
    '798_644': 35.9152,
    '798_645': 35.8376,
    '798_646': 35.7681,
    '798_647': 35.7053,
    '798_648': 35.6538,
    '798_649': 35.6083,
    '798_650': 35.566,
    '798_651': 35.5263,
    '798_652': 35.4922,
    '798_653': 35.4659,
    '798_654': 35.4527,
    '798_655': 35.4494,
    '798_656': 35.4523,
    '798_657': 35.4561,
    '798_658': 35.4658,
    '798_659': 35.4778,
    '798_660': 35.4982,
    '799_350': 30.0946,
    '799_351': 30.131,
    '799_352': 30.1731,
    '799_353': 30.213,
    '799_354': 30.2639,
    '799_355': 30.3173,
    '799_356': 30.3723,
    '799_357': 30.4286,
    '799_358': 30.4858,
    '799_359': 30.5428,
    '799_360': 30.5987,
    '799_361': 30.6529,
    '799_362': 30.7056,
    '799_363': 30.757,
    '799_364': 30.8043,
    '799_365': 30.8479,
    '799_366': 30.8896,
    '799_367': 30.934,
    '799_368': 30.9807,
    '799_369': 31.0268,
    '799_370': 31.0714,
    '799_373': 31.0221,
    '799_374': 31.0742,
    '799_375': 31.131,
    '799_376': 31.1911,
    '799_377': 31.2564,
    '799_378': 31.3307,
    '799_379': 31.3911,
    '799_380': 31.4282,
    '799_381': 31.4546,
    '799_382': 31.5004,
    '799_383': 31.5657,
    '799_384': 31.6364,
    '799_385': 31.7001,
    '799_386': 31.7627,
    '799_387': 31.822,
    '799_388': 31.8673,
    '799_389': 31.9108,
    '799_390': 31.9606,
    '799_391': 31.9841,
    '799_392': 31.9899,
    '799_393': 31.9961,
    '799_394': 32.025,
    '799_395': 32.0707,
    '799_396': 32.1171,
    '799_397': 32.1471,
    '799_398': 32.1929,
    '799_399': 32.2475,
    '799_400': 32.3208,
    '799_401': 32.3984,
    '799_402': 32.4746,
    '799_403': 32.5503,
    '799_404': 32.6187,
    '799_405': 32.6818,
    '799_406': 32.7156,
    '799_407': 32.7088,
    '799_408': 32.6942,
    '799_409': 32.6757,
    '799_410': 32.6479,
    '799_411': 32.6259,
    '799_412': 32.6121,
    '799_413': 32.602,
    '799_414': 32.586,
    '799_415': 32.5733,
    '799_416': 32.5691,
    '799_417': 32.573,
    '799_418': 32.5831,
    '799_419': 32.5995,
    '799_420': 32.6242,
    '799_421': 32.655,
    '799_422': 32.6891,
    '799_423': 32.7219,
    '799_424': 32.7567,
    '799_425': 32.7972,
    '799_426': 32.8355,
    '799_427': 32.8623,
    '799_428': 32.8854,
    '799_429': 32.9005,
    '799_430': 32.8977,
    '799_431': 32.8897,
    '799_432': 32.886,
    '799_433': 32.8933,
    '799_434': 32.8967,
    '799_435': 32.8881,
    '799_436': 32.8688,
    '799_437': 32.8536,
    '799_438': 32.8515,
    '799_439': 32.8631,
    '799_440': 32.8806,
    '799_441': 32.9013,
    '799_442': 32.9165,
    '799_443': 32.9189,
    '799_444': 32.9003,
    '799_445': 32.8721,
    '799_446': 32.8459,
    '799_447': 32.8324,
    '799_448': 32.8171,
    '799_449': 32.7945,
    '799_450': 32.77,
    '799_451': 32.7409,
    '799_452': 32.7073,
    '799_453': 32.6556,
    '799_454': 32.5869,
    '799_455': 32.507,
    '799_456': 32.4215,
    '799_457': 32.3211,
    '799_458': 32.1803,
    '799_459': 32.0032,
    '799_460': 31.8331,
    '799_461': 31.6899,
    '799_462': 31.5701,
    '799_463': 31.4708,
    '799_464': 31.3929,
    '799_465': 31.3332,
    '799_466': 31.2906,
    '799_467': 31.2622,
    '799_468': 31.2428,
    '799_469': 31.2305,
    '799_470': 31.2224,
    '799_471': 31.221,
    '799_472': 31.2282,
    '799_473': 31.2444,
    '799_474': 31.2678,
    '799_475': 31.2975,
    '799_476': 31.3294,
    '799_477': 31.3619,
    '799_478': 31.3947,
    '799_479': 31.425,
    '799_480': 31.4623,
    '799_481': 31.5106,
    '799_482': 31.5558,
    '799_483': 31.596,
    '799_484': 31.6321,
    '799_485': 31.6733,
    '799_486': 31.7212,
    '799_487': 31.7767,
    '799_488': 31.8403,
    '799_489': 31.912,
    '799_490': 31.9917,
    '799_491': 32.0767,
    '799_492': 32.1661,
    '799_493': 32.2599,
    '799_494': 32.3581,
    '799_495': 32.4674,
    '799_496': 32.5929,
    '799_497': 32.717,
    '799_498': 32.8427,
    '799_499': 32.9584,
    '799_500': 33.0883,
    '799_501': 33.2355,
    '799_502': 33.3986,
    '799_503': 33.5522,
    '799_504': 33.7013,
    '799_505': 33.8444,
    '799_506': 33.9819,
    '799_507': 34.1044,
    '799_508': 34.2204,
    '799_509': 34.35,
    '799_510': 34.4908,
    '799_511': 34.63,
    '799_512': 34.7624,
    '799_513': 34.9037,
    '799_514': 35.0474,
    '799_515': 35.1852,
    '799_516': 35.3043,
    '799_517': 35.3866,
    '799_518': 35.4554,
    '799_519': 35.5376,
    '799_520': 35.6371,
    '799_521': 35.7213,
    '799_522': 35.7747,
    '799_523': 35.8049,
    '799_524': 35.8255,
    '799_525': 35.8503,
    '799_526': 35.8645,
    '799_527': 35.8567,
    '799_528': 35.8241,
    '799_529': 35.792,
    '799_530': 35.7588,
    '799_531': 35.7292,
    '799_532': 35.702,
    '799_533': 35.684,
    '799_534': 35.6752,
    '799_535': 35.676,
    '799_536': 35.6808,
    '799_537': 35.686,
    '799_538': 35.6952,
    '799_539': 35.7114,
    '799_540': 35.7345,
    '799_541': 35.7637,
    '799_542': 35.7948,
    '799_543': 35.8214,
    '799_544': 35.8481,
    '799_545': 35.8661,
    '799_546': 35.8808,
    '799_547': 35.8945,
    '799_548': 35.9073,
    '799_549': 35.9187,
    '799_550': 35.9329,
    '799_551': 35.9477,
    '799_552': 35.9695,
    '799_553': 36.0054,
    '799_554': 36.0539,
    '799_555': 36.1104,
    '799_556': 36.1751,
    '799_557': 36.2512,
    '799_558': 36.3382,
    '799_559': 36.4269,
    '799_560': 36.5251,
    '799_561': 36.6159,
    '799_562': 36.7002,
    '799_563': 36.7753,
    '799_564': 36.8392,
    '799_565': 36.8871,
    '799_566': 36.8825,
    '799_567': 36.8212,
    '799_568': 36.7031,
    '799_569': 36.5466,
    '799_570': 36.3975,
    '799_571': 36.2615,
    '799_572': 36.1442,
    '799_573': 36.0475,
    '799_574': 35.9654,
    '799_575': 35.8961,
    '799_576': 35.8261,
    '799_577': 35.757,
    '799_578': 35.6906,
    '799_579': 35.6225,
    '799_580': 35.5535,
    '799_581': 35.4927,
    '799_582': 35.4303,
    '799_583': 35.3839,
    '799_584': 35.3258,
    '799_585': 35.262,
    '799_586': 35.2124,
    '799_587': 35.1598,
    '799_588': 35.1095,
    '799_589': 35.0617,
    '799_590': 35.0229,
    '799_591': 34.9807,
    '799_592': 34.9373,
    '799_593': 34.8916,
    '799_594': 34.8581,
    '799_595': 34.8302,
    '799_596': 34.8356,
    '799_597': 34.8202,
    '799_598': 34.8145,
    '799_599': 34.8187,
    '799_600': 34.8161,
    '799_601': 34.8214,
    '799_602': 34.8251,
    '799_603': 34.8166,
    '799_604': 34.8164,
    '799_605': 34.8233,
    '799_606': 34.8367,
    '799_607': 34.8614,
    '799_608': 34.8886,
    '799_609': 34.9238,
    '799_610': 34.9659,
    '799_611': 35.0138,
    '799_612': 35.0662,
    '799_613': 35.1233,
    '799_614': 35.19,
    '799_615': 35.2683,
    '799_616': 35.3567,
    '799_617': 35.4511,
    '799_618': 35.5487,
    '799_619': 35.6559,
    '799_620': 35.7715,
    '799_621': 35.8934,
    '799_622': 36.0209,
    '799_623': 36.1537,
    '799_624': 36.2907,
    '799_625': 36.4307,
    '799_626': 36.5673,
    '799_627': 36.6935,
    '799_628': 36.7959,
    '799_629': 36.8765,
    '799_630': 36.9394,
    '799_631': 36.9829,
    '799_632': 37.0074,
    '799_633': 37.017,
    '799_634': 36.9969,
    '799_635': 36.9444,
    '799_636': 36.875,
    '799_637': 36.7973,
    '799_638': 36.69,
    '799_639': 36.5678,
    '799_640': 36.4379,
    '799_641': 36.3091,
    '799_642': 36.1806,
    '799_643': 36.0749,
    '799_644': 35.976,
    '799_645': 35.8813,
    '799_646': 35.8069,
    '799_647': 35.7345,
    '799_648': 35.6776,
    '799_649': 35.6259,
    '799_650': 35.5765,
    '799_651': 35.5282,
    '799_652': 35.4873,
    '799_653': 35.4566,
    '799_654': 35.4381,
    '799_655': 35.4311,
    '799_656': 35.4321,
    '799_657': 35.4344,
    '799_658': 35.4407,
    '799_659': 35.4538,
    '799_660': 35.4717,
    '800_350': 30.0741,
    '800_351': 30.1056,
    '800_352': 30.1422,
    '800_353': 30.1797,
    '800_354': 30.2277,
    '800_355': 30.2791,
    '800_356': 30.3334,
    '800_357': 30.3904,
    '800_358': 30.449,
    '800_359': 30.5076,
    '800_360': 30.5648,
    '800_361': 30.6196,
    '800_362': 30.6725,
    '800_363': 30.7267,
    '800_364': 30.7744,
    '800_365': 30.8174,
    '800_366': 30.8578,
    '800_367': 30.9034,
    '800_368': 30.9536,
    '800_369': 30.9981,
    '800_370': 31.0402,
    '800_373': 30.9938,
    '800_374': 31.0448,
    '800_375': 31.1021,
    '800_376': 31.163,
    '800_377': 31.2279,
    '800_378': 31.3028,
    '800_379': 31.3694,
    '800_380': 31.4003,
    '800_381': 31.4342,
    '800_382': 31.4782,
    '800_383': 31.5399,
    '800_384': 31.6106,
    '800_385': 31.6717,
    '800_386': 31.7288,
    '800_387': 31.7798,
    '800_388': 31.8229,
    '800_389': 31.8654,
    '800_390': 31.9109,
    '800_391': 31.9303,
    '800_392': 31.9394,
    '800_393': 31.9595,
    '800_394': 31.9936,
    '800_395': 32.0392,
    '800_396': 32.0871,
    '800_397': 32.1266,
    '800_398': 32.1779,
    '800_399': 32.2366,
    '800_400': 32.3066,
    '800_401': 32.3907,
    '800_402': 32.4935,
    '800_403': 32.5973,
    '800_404': 32.6782,
    '800_405': 32.7455,
    '800_406': 32.7852,
    '800_407': 32.7832,
    '800_408': 32.7693,
    '800_409': 32.7449,
    '800_410': 32.7088,
    '800_411': 32.6991,
    '800_412': 32.6913,
    '800_413': 32.6781,
    '800_414': 32.6602,
    '800_415': 32.6417,
    '800_416': 32.6317,
    '800_417': 32.6274,
    '800_418': 32.6271,
    '800_419': 32.633,
    '800_420': 32.6451,
    '800_421': 32.6645,
    '800_422': 32.6823,
    '800_423': 32.702,
    '800_424': 32.7244,
    '800_425': 32.7508,
    '800_426': 32.7722,
    '800_427': 32.7903,
    '800_428': 32.8074,
    '800_429': 32.8212,
    '800_430': 32.8267,
    '800_431': 32.8278,
    '800_432': 32.8347,
    '800_433': 32.8507,
    '800_434': 32.8676,
    '800_435': 32.871,
    '800_436': 32.8605,
    '800_437': 32.8502,
    '800_438': 32.8535,
    '800_439': 32.8707,
    '800_440': 32.893,
    '800_441': 32.914,
    '800_442': 32.9296,
    '800_443': 32.93,
    '800_444': 32.9105,
    '800_445': 32.8781,
    '800_446': 32.8471,
    '800_447': 32.8261,
    '800_448': 32.8067,
    '800_449': 32.7839,
    '800_450': 32.7564,
    '800_451': 32.7272,
    '800_452': 32.6881,
    '800_453': 32.6347,
    '800_454': 32.5689,
    '800_455': 32.4969,
    '800_456': 32.4158,
    '800_457': 32.3187,
    '800_458': 32.187,
    '800_459': 32.0209,
    '800_460': 31.8556,
    '800_461': 31.7217,
    '800_462': 31.6089,
    '800_463': 31.5115,
    '800_464': 31.4263,
    '800_465': 31.3644,
    '800_466': 31.3167,
    '800_467': 31.2853,
    '800_468': 31.2613,
    '800_469': 31.2422,
    '800_470': 31.225,
    '800_471': 31.2127,
    '800_472': 31.2083,
    '800_473': 31.2139,
    '800_474': 31.2296,
    '800_475': 31.2524,
    '800_476': 31.2802,
    '800_477': 31.3138,
    '800_478': 31.3563,
    '800_479': 31.412,
    '800_480': 31.4651,
    '800_481': 31.5255,
    '800_482': 31.5758,
    '800_483': 31.6172,
    '800_484': 31.655,
    '800_485': 31.6951,
    '800_486': 31.7426,
    '800_487': 31.7979,
    '800_488': 31.8612,
    '800_489': 31.9332,
    '800_490': 32.0132,
    '800_491': 32.0983,
    '800_492': 32.1875,
    '800_493': 32.2808,
    '800_494': 32.3783,
    '800_495': 32.4877,
    '800_496': 32.6155,
    '800_497': 32.7493,
    '800_498': 32.8827,
    '800_499': 33.0042,
    '800_500': 33.1343,
    '800_501': 33.2766,
    '800_502': 33.4255,
    '800_503': 33.5727,
    '800_504': 33.7155,
    '800_505': 33.8552,
    '800_506': 33.9884,
    '800_507': 34.1128,
    '800_508': 34.2325,
    '800_509': 34.3673,
    '800_510': 34.5075,
    '800_511': 34.6398,
    '800_512': 34.7693,
    '800_513': 34.9208,
    '800_514': 35.0725,
    '800_515': 35.2115,
    '800_516': 35.3322,
    '800_517': 35.4128,
    '800_518': 35.4838,
    '800_519': 35.573,
    '800_520': 35.6676,
    '800_521': 35.7525,
    '800_522': 35.8105,
    '800_523': 35.8429,
    '800_524': 35.8607,
    '800_525': 35.8771,
    '800_526': 35.8877,
    '800_527': 35.8782,
    '800_528': 35.8446,
    '800_529': 35.8131,
    '800_530': 35.7892,
    '800_531': 35.7605,
    '800_532': 35.7365,
    '800_533': 35.7206,
    '800_534': 35.7102,
    '800_535': 35.7059,
    '800_536': 35.7054,
    '800_537': 35.7086,
    '800_538': 35.7172,
    '800_539': 35.7331,
    '800_540': 35.7558,
    '800_541': 35.7867,
    '800_542': 35.8281,
    '800_543': 35.8591,
    '800_544': 35.8853,
    '800_545': 35.9088,
    '800_546': 35.928,
    '800_547': 35.9452,
    '800_548': 35.9607,
    '800_549': 35.9769,
    '800_550': 35.9975,
    '800_551': 36.0211,
    '800_552': 36.052,
    '800_553': 36.097,
    '800_554': 36.1514,
    '800_555': 36.2111,
    '800_556': 36.274,
    '800_557': 36.348,
    '800_558': 36.4332,
    '800_559': 36.5237,
    '800_560': 36.619,
    '800_561': 36.7112,
    '800_562': 36.7962,
    '800_563': 36.8742,
    '800_564': 36.9416,
    '800_565': 36.9929,
    '800_566': 36.989,
    '800_567': 36.9207,
    '800_568': 36.7971,
    '800_569': 36.6254,
    '800_570': 36.4826,
    '800_571': 36.3509,
    '800_572': 36.2323,
    '800_573': 36.1324,
    '800_574': 36.0458,
    '800_575': 35.9768,
    '800_576': 35.9097,
    '800_577': 35.8415,
    '800_578': 35.7666,
    '800_579': 35.69,
    '800_580': 35.6132,
    '800_581': 35.5402,
    '800_582': 35.4709,
    '800_583': 35.4245,
    '800_584': 35.3607,
    '800_585': 35.2917,
    '800_586': 35.2371,
    '800_587': 35.1779,
    '800_588': 35.1254,
    '800_589': 35.0767,
    '800_590': 35.0342,
    '800_591': 34.9909,
    '800_592': 34.9476,
    '800_593': 34.9114,
    '800_594': 34.8805,
    '800_595': 34.8521,
    '800_596': 34.8543,
    '800_597': 34.834,
    '800_598': 34.8321,
    '800_599': 34.8372,
    '800_600': 34.8401,
    '800_601': 34.8457,
    '800_602': 34.8501,
    '800_603': 34.8408,
    '800_604': 34.8373,
    '800_605': 34.8467,
    '800_606': 34.8604,
    '800_607': 34.8912,
    '800_608': 34.9289,
    '800_609': 34.9645,
    '800_610': 35.0127,
    '800_611': 35.071,
    '800_612': 35.1371,
    '800_613': 35.2063,
    '800_614': 35.2818,
    '800_615': 35.3693,
    '800_616': 35.4696,
    '800_617': 35.5762,
    '800_618': 35.6832,
    '800_619': 35.796,
    '800_620': 35.9184,
    '800_621': 36.0465,
    '800_622': 36.1799,
    '800_623': 36.3183,
    '800_624': 36.466,
    '800_625': 36.6165,
    '800_626': 36.762,
    '800_627': 36.8904,
    '800_628': 37.0001,
    '800_629': 37.0933,
    '800_630': 37.163,
    '800_631': 37.2052,
    '800_632': 37.236,
    '800_633': 37.2446,
    '800_634': 37.2169,
    '800_635': 37.1499,
    '800_636': 37.0642,
    '800_637': 36.9677,
    '800_638': 36.8538,
    '800_639': 36.7229,
    '800_640': 36.5761,
    '800_641': 36.4212,
    '800_642': 36.273,
    '800_643': 36.146,
    '800_644': 36.034,
    '800_645': 35.9365,
    '800_646': 35.8443,
    '800_647': 35.7588,
    '800_648': 35.6968,
    '800_649': 35.6376,
    '800_650': 35.5782,
    '800_651': 35.5226,
    '800_652': 35.4755,
    '800_653': 35.4407,
    '800_654': 35.4171,
    '800_655': 35.407,
    '800_656': 35.4107,
    '800_657': 35.4071,
    '800_658': 35.4066,
    '800_659': 35.419,
    '800_660': 35.4428,
    '801_350': 30.0551,
    '801_351': 30.0792,
    '801_352': 30.103,
    '801_353': 30.1423,
    '801_354': 30.1894,
    '801_355': 30.2416,
    '801_356': 30.2956,
    '801_357': 30.3535,
    '801_358': 30.4142,
    '801_359': 30.4752,
    '801_360': 30.5342,
    '801_361': 30.5894,
    '801_362': 30.6402,
    '801_363': 30.6984,
    '801_364': 30.7448,
    '801_365': 30.7874,
    '801_366': 30.8234,
    '801_367': 30.8678,
    '801_368': 30.9189,
    '801_369': 30.9636,
    '801_370': 31.0049,
    '801_373': 30.9674,
    '801_374': 31.0174,
    '801_375': 31.0753,
    '801_376': 31.1379,
    '801_377': 31.2017,
    '801_378': 31.263,
    '801_379': 31.3239,
    '801_380': 31.3656,
    '801_381': 31.4088,
    '801_382': 31.4567,
    '801_383': 31.5121,
    '801_384': 31.5763,
    '801_385': 31.6358,
    '801_386': 31.6875,
    '801_387': 31.734,
    '801_388': 31.7754,
    '801_389': 31.8172,
    '801_390': 31.8604,
    '801_391': 31.8811,
    '801_392': 31.9019,
    '801_393': 31.9297,
    '801_394': 31.9661,
    '801_395': 32.0119,
    '801_396': 32.0587,
    '801_397': 32.1027,
    '801_398': 32.16,
    '801_399': 32.2245,
    '801_400': 32.2949,
    '801_401': 32.3896,
    '801_402': 32.5181,
    '801_403': 32.6387,
    '801_404': 32.7186,
    '801_405': 32.7825,
    '801_406': 32.8238,
    '801_407': 32.8239,
    '801_408': 32.8098,
    '801_409': 32.7859,
    '801_410': 32.757,
    '801_411': 32.7606,
    '801_412': 32.7661,
    '801_413': 32.7645,
    '801_414': 32.7455,
    '801_415': 32.7188,
    '801_416': 32.7005,
    '801_417': 32.6894,
    '801_418': 32.6763,
    '801_419': 32.6676,
    '801_420': 32.6645,
    '801_421': 32.671,
    '801_422': 32.6828,
    '801_423': 32.6965,
    '801_424': 32.7138,
    '801_425': 32.7322,
    '801_426': 32.742,
    '801_427': 32.7508,
    '801_428': 32.7644,
    '801_429': 32.7804,
    '801_430': 32.7935,
    '801_431': 32.8046,
    '801_432': 32.8186,
    '801_433': 32.8398,
    '801_434': 32.867,
    '801_435': 32.8743,
    '801_436': 32.8734,
    '801_437': 32.8708,
    '801_438': 32.8796,
    '801_439': 32.9013,
    '801_440': 32.9243,
    '801_441': 32.9392,
    '801_442': 32.9463,
    '801_443': 32.9369,
    '801_444': 32.9081,
    '801_445': 32.8701,
    '801_446': 32.8344,
    '801_447': 32.8113,
    '801_448': 32.7865,
    '801_449': 32.7618,
    '801_450': 32.733,
    '801_451': 32.6999,
    '801_452': 32.6595,
    '801_453': 32.6064,
    '801_454': 32.5465,
    '801_455': 32.4847,
    '801_456': 32.4106,
    '801_457': 32.3182,
    '801_458': 32.1958,
    '801_459': 32.0462,
    '801_460': 31.895,
    '801_461': 31.7675,
    '801_462': 31.6594,
    '801_463': 31.5655,
    '801_464': 31.4808,
    '801_465': 31.4147,
    '801_466': 31.3606,
    '801_467': 31.3193,
    '801_468': 31.2905,
    '801_469': 31.2672,
    '801_470': 31.2424,
    '801_471': 31.2198,
    '801_472': 31.2041,
    '801_473': 31.1974,
    '801_474': 31.2021,
    '801_475': 31.2155,
    '801_476': 31.2371,
    '801_477': 31.2709,
    '801_478': 31.3249,
    '801_479': 31.3849,
    '801_480': 31.4498,
    '801_481': 31.5312,
    '801_482': 31.5896,
    '801_483': 31.6342,
    '801_484': 31.6769,
    '801_485': 31.7219,
    '801_486': 31.7682,
    '801_487': 31.8195,
    '801_488': 31.8805,
    '801_489': 31.9523,
    '801_490': 32.0332,
    '801_491': 32.1194,
    '801_492': 32.2093,
    '801_493': 32.3031,
    '801_494': 32.4004,
    '801_495': 32.5056,
    '801_496': 32.6252,
    '801_497': 32.7617,
    '801_498': 32.9048,
    '801_499': 33.0373,
    '801_500': 33.163,
    '801_501': 33.299,
    '801_502': 33.4477,
    '801_503': 33.5878,
    '801_504': 33.7122,
    '801_505': 33.8389,
    '801_506': 33.9665,
    '801_507': 34.0992,
    '801_508': 34.2284,
    '801_509': 34.3666,
    '801_510': 34.5085,
    '801_511': 34.6434,
    '801_512': 34.7781,
    '801_513': 34.9381,
    '801_514': 35.0994,
    '801_515': 35.2389,
    '801_516': 35.3546,
    '801_517': 35.44,
    '801_518': 35.5162,
    '801_519': 35.606,
    '801_520': 35.6974,
    '801_521': 35.782,
    '801_522': 35.8468,
    '801_523': 35.8827,
    '801_524': 35.9,
    '801_525': 35.9126,
    '801_526': 35.9195,
    '801_527': 35.9053,
    '801_528': 35.8737,
    '801_529': 35.8506,
    '801_530': 35.8284,
    '801_531': 35.7954,
    '801_532': 35.7768,
    '801_533': 35.7635,
    '801_534': 35.7487,
    '801_535': 35.7399,
    '801_536': 35.7346,
    '801_537': 35.736,
    '801_538': 35.7447,
    '801_539': 35.7605,
    '801_540': 35.7839,
    '801_541': 35.8192,
    '801_542': 35.8624,
    '801_543': 35.9007,
    '801_544': 35.9336,
    '801_545': 35.9564,
    '801_546': 35.9758,
    '801_547': 35.9958,
    '801_548': 36.014,
    '801_549': 36.0355,
    '801_550': 36.062,
    '801_551': 36.0949,
    '801_552': 36.1365,
    '801_553': 36.1884,
    '801_554': 36.2469,
    '801_555': 36.307,
    '801_556': 36.3705,
    '801_557': 36.4425,
    '801_558': 36.5278,
    '801_559': 36.6184,
    '801_560': 36.7113,
    '801_561': 36.8047,
    '801_562': 36.8941,
    '801_563': 36.9812,
    '801_564': 37.0513,
    '801_565': 37.0943,
    '801_566': 37.0898,
    '801_567': 37.0249,
    '801_568': 36.8965,
    '801_569': 36.732,
    '801_570': 36.585,
    '801_571': 36.4503,
    '801_572': 36.33,
    '801_573': 36.2315,
    '801_574': 36.1466,
    '801_575': 36.0728,
    '801_576': 36.0009,
    '801_577': 35.9281,
    '801_578': 35.8464,
    '801_579': 35.7633,
    '801_580': 35.6816,
    '801_581': 35.6006,
    '801_582': 35.5261,
    '801_583': 35.4687,
    '801_584': 35.3952,
    '801_585': 35.3207,
    '801_586': 35.2672,
    '801_587': 35.2082,
    '801_588': 35.152,
    '801_589': 35.0994,
    '801_590': 35.0532,
    '801_591': 35.0084,
    '801_592': 34.9659,
    '801_593': 34.9292,
    '801_594': 34.8974,
    '801_595': 34.8684,
    '801_596': 34.8705,
    '801_597': 34.8563,
    '801_598': 34.8567,
    '801_599': 34.8615,
    '801_600': 34.8684,
    '801_601': 34.8727,
    '801_602': 34.8751,
    '801_603': 34.8765,
    '801_604': 34.8805,
    '801_605': 34.8928,
    '801_606': 34.9122,
    '801_607': 34.9449,
    '801_608': 34.9865,
    '801_609': 35.0303,
    '801_610': 35.0858,
    '801_611': 35.1527,
    '801_612': 35.2284,
    '801_613': 35.3056,
    '801_614': 35.3899,
    '801_615': 35.4856,
    '801_616': 35.5951,
    '801_617': 35.711,
    '801_618': 35.8323,
    '801_619': 35.9488,
    '801_620': 36.0778,
    '801_621': 36.2106,
    '801_622': 36.3477,
    '801_623': 36.4877,
    '801_624': 36.6471,
    '801_625': 36.8101,
    '801_626': 36.9679,
    '801_627': 37.0977,
    '801_628': 37.2081,
    '801_629': 37.303,
    '801_630': 37.3744,
    '801_631': 37.4208,
    '801_632': 37.4653,
    '801_633': 37.4661,
    '801_634': 37.4328,
    '801_635': 37.3606,
    '801_636': 37.2563,
    '801_637': 37.1325,
    '801_638': 37.011,
    '801_639': 36.8677,
    '801_640': 36.7066,
    '801_641': 36.5334,
    '801_642': 36.3639,
    '801_643': 36.22,
    '801_644': 36.0927,
    '801_645': 35.9781,
    '801_646': 35.8704,
    '801_647': 35.7799,
    '801_648': 35.7074,
    '801_649': 35.6414,
    '801_650': 35.5747,
    '801_651': 35.5123,
    '801_652': 35.4593,
    '801_653': 35.4206,
    '801_654': 35.3923,
    '801_655': 35.3762,
    '801_656': 35.3837,
    '801_657': 35.3751,
    '801_658': 35.3671,
    '801_659': 35.3777,
    '801_660': 35.4023,
    '802_373': 30.9398,
    '802_374': 30.9904,
    '802_375': 31.0501,
    '802_376': 31.1155,
    '802_377': 31.1805,
    '802_378': 31.2288,
    '802_379': 31.2811,
    '802_380': 31.3296,
    '802_381': 31.3799,
    '802_382': 31.4307,
    '802_383': 31.4836,
    '802_384': 31.5417,
    '802_385': 31.597,
    '802_386': 31.6477,
    '802_387': 31.694,
    '802_388': 31.7353,
    '802_389': 31.7754,
    '802_390': 31.8142,
    '802_391': 31.8435,
    '802_392': 31.8724,
    '802_393': 31.9069,
    '802_394': 31.9435,
    '802_395': 31.9889,
    '802_396': 32.0374,
    '802_397': 32.0869,
    '802_398': 32.1402,
    '802_399': 32.2085,
    '802_400': 32.2881,
    '802_401': 32.3961,
    '802_402': 32.5327,
    '802_403': 32.6568,
    '802_404': 32.7394,
    '802_405': 32.8034,
    '802_406': 32.842,
    '802_407': 32.8462,
    '802_408': 32.831,
    '802_409': 32.8143,
    '802_410': 32.8008,
    '802_411': 32.8098,
    '802_412': 32.8265,
    '802_413': 32.8332,
    '802_414': 32.8232,
    '802_415': 32.8087,
    '802_416': 32.7793,
    '802_417': 32.7637,
    '802_418': 32.7361,
    '802_419': 32.7074,
    '802_420': 32.6876,
    '802_421': 32.6828,
    '802_422': 32.6891,
    '802_423': 32.6987,
    '802_424': 32.7122,
    '802_425': 32.7252,
    '802_426': 32.7315,
    '802_427': 32.7393,
    '802_428': 32.7531,
    '802_429': 32.7737,
    '802_430': 32.7953,
    '802_431': 32.8153,
    '802_432': 32.8342,
    '802_433': 32.8521,
    '802_434': 32.8785,
    '802_435': 32.889,
    '802_436': 32.8976,
    '802_437': 32.9133,
    '802_438': 32.9257,
    '802_439': 32.9438,
    '802_440': 32.9575,
    '802_441': 32.9576,
    '802_442': 32.9504,
    '802_443': 32.9328,
    '802_444': 32.9008,
    '802_445': 32.8618,
    '802_446': 32.8255,
    '802_447': 32.7997,
    '802_448': 32.7738,
    '802_449': 32.7485,
    '802_450': 32.714,
    '802_451': 32.6651,
    '802_452': 32.6173,
    '802_453': 32.5665,
    '802_454': 32.5114,
    '802_455': 32.4581,
    '802_456': 32.3921,
    '802_457': 32.3116,
    '802_458': 32.2103,
    '802_459': 32.0856,
    '802_460': 31.9535,
    '802_461': 31.8266,
    '802_462': 31.7179,
    '802_463': 31.6261,
    '802_464': 31.5429,
    '802_465': 31.4758,
    '802_466': 31.4191,
    '802_467': 31.3749,
    '802_468': 31.3369,
    '802_469': 31.3019,
    '802_470': 31.2708,
    '802_471': 31.239,
    '802_472': 31.2117,
    '802_473': 31.1922,
    '802_474': 31.1843,
    '802_475': 31.1878,
    '802_476': 31.2026,
    '802_477': 31.2342,
    '802_478': 31.2886,
    '802_479': 31.3336,
    '802_480': 31.4071,
    '802_481': 31.4935,
    '802_482': 31.5763,
    '802_483': 31.6366,
    '802_484': 31.6904,
    '802_485': 31.7477,
    '802_486': 31.7937,
    '802_487': 31.8412,
    '802_488': 31.8984,
    '802_489': 31.9685,
    '802_490': 32.0499,
    '802_491': 32.1384,
    '802_492': 32.2298,
    '802_493': 32.3251,
    '802_494': 32.4251,
    '802_495': 32.5279,
    '802_496': 32.6449,
    '802_497': 32.7805,
    '802_498': 32.9267,
    '802_499': 33.0543,
    '802_500': 33.1776,
    '802_501': 33.3161,
    '802_502': 33.4674,
    '802_503': 33.6008,
    '802_504': 33.7107,
    '802_505': 33.82,
    '802_506': 33.9346,
    '802_507': 34.074,
    '802_508': 34.2069,
    '802_509': 34.3469,
    '802_510': 34.4912,
    '802_511': 34.6375,
    '802_512': 34.7913,
    '802_513': 34.9564,
    '802_514': 35.1238,
    '802_515': 35.2635,
    '802_516': 35.3759,
    '802_517': 35.4674,
    '802_518': 35.5551,
    '802_519': 35.6425,
    '802_520': 35.7257,
    '802_521': 35.805,
    '802_522': 35.8756,
    '802_523': 35.9158,
    '802_524': 35.9333,
    '802_525': 35.9447,
    '802_526': 35.953,
    '802_527': 35.9453,
    '802_528': 35.9146,
    '802_529': 35.8898,
    '802_530': 35.862,
    '802_531': 35.8325,
    '802_532': 35.8173,
    '802_533': 35.8088,
    '802_534': 35.7923,
    '802_535': 35.7807,
    '802_536': 35.771,
    '802_537': 35.77,
    '802_538': 35.7792,
    '802_539': 35.7953,
    '802_540': 35.8197,
    '802_541': 35.8547,
    '802_542': 35.8982,
    '802_543': 35.9412,
    '802_544': 35.9769,
    '802_545': 36.0025,
    '802_546': 36.0222,
    '802_547': 36.0431,
    '802_548': 36.0646,
    '802_549': 36.0894,
    '802_550': 36.1213,
    '802_551': 36.1657,
    '802_552': 36.2173,
    '802_553': 36.2753,
    '802_554': 36.3358,
    '802_555': 36.3977,
    '802_556': 36.4656,
    '802_557': 36.5401,
    '802_558': 36.6252,
    '802_559': 36.7144,
    '802_560': 36.8053,
    '802_561': 36.8996,
    '802_562': 36.9951,
    '802_563': 37.087,
    '802_564': 37.1565,
    '802_565': 37.1924,
    '802_566': 37.1837,
    '802_567': 37.1238,
    '802_568': 36.9943,
    '802_569': 36.8379,
    '802_570': 36.6914,
    '802_571': 36.556,
    '802_572': 36.4381,
    '802_573': 36.3391,
    '802_574': 36.2558,
    '802_575': 36.1798,
    '802_576': 36.1025,
    '802_577': 36.0204,
    '802_578': 35.9301,
    '802_579': 35.8406,
    '802_580': 35.7534,
    '802_581': 35.6685,
    '802_582': 35.5806,
    '802_583': 35.5121,
    '802_584': 35.4391,
    '802_585': 35.3671,
    '802_586': 35.3082,
    '802_587': 35.248,
    '802_588': 35.1894,
    '802_589': 35.1342,
    '802_590': 35.0834,
    '802_591': 35.0341,
    '802_592': 34.9876,
    '802_593': 34.95,
    '802_594': 34.9177,
    '802_595': 34.8889,
    '802_596': 34.8915,
    '802_597': 34.8773,
    '802_598': 34.8735,
    '802_599': 34.885,
    '802_600': 34.8939,
    '802_601': 34.9012,
    '802_602': 34.9063,
    '802_603': 34.918,
    '802_604': 34.9285,
    '802_605': 34.9434,
    '802_606': 34.9712,
    '802_607': 35.0076,
    '802_608': 35.0509,
    '802_609': 35.1047,
    '802_610': 35.1694,
    '802_611': 35.2435,
    '802_612': 35.3256,
    '802_613': 35.4135,
    '802_614': 35.5096,
    '802_615': 35.6165,
    '802_616': 35.7372,
    '802_617': 35.8646,
    '802_618': 35.9948,
    '802_619': 36.1239,
    '802_620': 36.2575,
    '802_621': 36.3929,
    '802_622': 36.5361,
    '802_623': 36.67,
    '802_624': 36.836,
    '802_625': 37.0011,
    '802_626': 37.1566,
    '802_627': 37.2902,
    '802_628': 37.4087,
    '802_629': 37.508,
    '802_630': 37.5815,
    '802_631': 37.6334,
    '802_632': 37.6704,
    '802_633': 37.67,
    '802_634': 37.6338,
    '802_635': 37.5617,
    '802_636': 37.4535,
    '802_637': 37.3202,
    '802_638': 37.1745,
    '802_639': 37.0085,
    '802_640': 36.8305,
    '802_641': 36.6442,
    '802_642': 36.461,
    '802_643': 36.2969,
    '802_644': 36.1505,
    '802_645': 36.02,
    '802_646': 35.9011,
    '802_647': 35.7935,
    '802_648': 35.7125,
    '802_649': 35.6417,
    '802_650': 35.5703,
    '802_651': 35.5044,
    '802_652': 35.4499,
    '802_653': 35.4074,
    '802_654': 35.3769,
    '802_655': 35.3599,
    '802_656': 35.3561,
    '802_657': 35.3467,
    '802_658': 35.3404,
    '802_659': 35.3484,
    '802_660': 35.3712,
    '803_373': 30.9117,
    '803_374': 30.9624,
    '803_375': 31.0229,
    '803_376': 31.0883,
    '803_377': 31.1534,
    '803_378': 31.2001,
    '803_379': 31.2508,
    '803_380': 31.3018,
    '803_381': 31.3543,
    '803_382': 31.4039,
    '803_383': 31.4546,
    '803_384': 31.5084,
    '803_385': 31.5612,
    '803_386': 31.6122,
    '803_387': 31.6602,
    '803_388': 31.7019,
    '803_389': 31.7418,
    '803_390': 31.7791,
    '803_391': 31.8127,
    '803_392': 31.8471,
    '803_393': 31.8888,
    '803_394': 31.9244,
    '803_395': 31.9709,
    '803_396': 32.0251,
    '803_397': 32.0751,
    '803_398': 32.1251,
    '803_399': 32.1915,
    '803_400': 32.2768,
    '803_401': 32.3901,
    '803_402': 32.5279,
    '803_403': 32.6524,
    '803_404': 32.7405,
    '803_405': 32.8068,
    '803_406': 32.8463,
    '803_407': 32.8585,
    '803_408': 32.8527,
    '803_409': 32.845,
    '803_410': 32.8485,
    '803_411': 32.8642,
    '803_412': 32.8804,
    '803_413': 32.8908,
    '803_414': 32.8944,
    '803_415': 32.8842,
    '803_416': 32.8566,
    '803_417': 32.8345,
    '803_418': 32.8016,
    '803_419': 32.7498,
    '803_420': 32.7108,
    '803_421': 32.6943,
    '803_422': 32.6953,
    '803_423': 32.7033,
    '803_424': 32.7158,
    '803_425': 32.7291,
    '803_426': 32.7331,
    '803_427': 32.7411,
    '803_428': 32.7567,
    '803_429': 32.7821,
    '803_430': 32.8139,
    '803_431': 32.8453,
    '803_432': 32.8717,
    '803_433': 32.8861,
    '803_434': 32.8964,
    '803_435': 32.9098,
    '803_436': 32.9296,
    '803_437': 32.9595,
    '803_438': 32.9771,
    '803_439': 32.9924,
    '803_440': 32.9897,
    '803_441': 32.9699,
    '803_442': 32.9459,
    '803_443': 32.9234,
    '803_444': 32.8963,
    '803_445': 32.8592,
    '803_446': 32.8242,
    '803_447': 32.7976,
    '803_448': 32.7731,
    '803_449': 32.7452,
    '803_450': 32.706,
    '803_451': 32.6458,
    '803_452': 32.5849,
    '803_453': 32.5276,
    '803_454': 32.4737,
    '803_455': 32.423,
    '803_456': 32.3624,
    '803_457': 32.2982,
    '803_458': 32.2179,
    '803_459': 32.1226,
    '803_460': 32.016,
    '803_461': 31.8969,
    '803_462': 31.7813,
    '803_463': 31.6872,
    '803_464': 31.6071,
    '803_465': 31.5437,
    '803_466': 31.4844,
    '803_467': 31.4369,
    '803_468': 31.3931,
    '803_469': 31.3464,
    '803_470': 31.3007,
    '803_471': 31.2605,
    '803_472': 31.2224,
    '803_473': 31.1896,
    '803_474': 31.1727,
    '803_475': 31.1684,
    '803_476': 31.1776,
    '803_477': 31.2082,
    '803_478': 31.2449,
    '803_479': 31.2847,
    '803_480': 31.3548,
    '803_481': 31.4397,
    '803_482': 31.5276,
    '803_483': 31.6139,
    '803_484': 31.6845,
    '803_485': 31.7512,
    '803_486': 31.8118,
    '803_487': 31.8563,
    '803_488': 31.9143,
    '803_489': 31.9823,
    '803_490': 32.0624,
    '803_491': 32.1496,
    '803_492': 32.2429,
    '803_493': 32.3426,
    '803_494': 32.4476,
    '803_495': 32.5496,
    '803_496': 32.6707,
    '803_497': 32.7953,
    '803_498': 32.9402,
    '803_499': 33.0605,
    '803_500': 33.1874,
    '803_501': 33.3284,
    '803_502': 33.4746,
    '803_503': 33.601,
    '803_504': 33.7046,
    '803_505': 33.8092,
    '803_506': 33.9152,
    '803_507': 34.0507,
    '803_508': 34.1895,
    '803_509': 34.3282,
    '803_510': 34.4808,
    '803_511': 34.6429,
    '803_512': 34.815,
    '803_513': 34.9813,
    '803_514': 35.1485,
    '803_515': 35.2862,
    '803_516': 35.4005,
    '803_517': 35.4993,
    '803_518': 35.5983,
    '803_519': 35.6818,
    '803_520': 35.7559,
    '803_521': 35.8265,
    '803_522': 35.8936,
    '803_523': 35.9395,
    '803_524': 35.9584,
    '803_525': 35.9689,
    '803_526': 35.9793,
    '803_527': 35.9754,
    '803_528': 35.9465,
    '803_529': 35.9194,
    '803_530': 35.8921,
    '803_531': 35.8661,
    '803_532': 35.8567,
    '803_533': 35.8546,
    '803_534': 35.8401,
    '803_535': 35.8307,
    '803_536': 35.8194,
    '803_537': 35.8136,
    '803_538': 35.822,
    '803_539': 35.8356,
    '803_540': 35.8578,
    '803_541': 35.89,
    '803_542': 35.9353,
    '803_543': 35.9834,
    '803_544': 36.0247,
    '803_545': 36.0507,
    '803_546': 36.0714,
    '803_547': 36.0927,
    '803_548': 36.1172,
    '803_549': 36.1461,
    '803_550': 36.1871,
    '803_551': 36.2369,
    '803_552': 36.2932,
    '803_553': 36.3546,
    '803_554': 36.4181,
    '803_555': 36.4844,
    '803_556': 36.5588,
    '803_557': 36.6383,
    '803_558': 36.7221,
    '803_559': 36.808,
    '803_560': 36.8968,
    '803_561': 36.9942,
    '803_562': 37.103,
    '803_563': 37.1959,
    '803_564': 37.2582,
    '803_565': 37.2937,
    '803_566': 37.2782,
    '803_567': 37.2166,
    '803_568': 37.0902,
    '803_569': 36.9392,
    '803_570': 36.7921,
    '803_571': 36.6559,
    '803_572': 36.5436,
    '803_573': 36.4479,
    '803_574': 36.3655,
    '803_575': 36.2889,
    '803_576': 36.2077,
    '803_577': 36.121,
    '803_578': 36.0182,
    '803_579': 35.9243,
    '803_580': 35.835,
    '803_581': 35.7553,
    '803_582': 35.6589,
    '803_583': 35.5767,
    '803_584': 35.4977,
    '803_585': 35.4221,
    '803_586': 35.3616,
    '803_587': 35.2973,
    '803_588': 35.2346,
    '803_589': 35.1759,
    '803_590': 35.1217,
    '803_591': 35.0685,
    '803_592': 35.0172,
    '803_593': 34.9775,
    '803_594': 34.9439,
    '803_595': 34.9152,
    '803_596': 34.918,
    '803_597': 34.9046,
    '803_598': 34.9,
    '803_599': 34.9096,
    '803_600': 34.9171,
    '803_601': 34.9256,
    '803_602': 34.9366,
    '803_603': 34.9539,
    '803_604': 34.9709,
    '803_605': 34.9934,
    '803_606': 35.0302,
    '803_607': 35.0729,
    '803_608': 35.1201,
    '803_609': 35.1824,
    '803_610': 35.2563,
    '803_611': 35.3368,
    '803_612': 35.4257,
    '803_613': 35.5261,
    '803_614': 35.6368,
    '803_615': 35.7582,
    '803_616': 35.8912,
    '803_617': 36.03,
    '803_618': 36.1705,
    '803_619': 36.3115,
    '803_620': 36.4546,
    '803_621': 36.5986,
    '803_622': 36.7457,
    '803_623': 36.8905,
    '803_624': 37.0382,
    '803_625': 37.2038,
    '803_626': 37.352,
    '803_627': 37.4841,
    '803_628': 37.6105,
    '803_629': 37.7091,
    '803_630': 37.7907,
    '803_631': 37.8472,
    '803_632': 37.8738,
    '803_633': 37.866,
    '803_634': 37.8243,
    '803_635': 37.7484,
    '803_636': 37.6311,
    '803_637': 37.4943,
    '803_638': 37.336,
    '803_639': 37.1541,
    '803_640': 36.9557,
    '803_641': 36.7592,
    '803_642': 36.5534,
    '803_643': 36.3676,
    '803_644': 36.196,
    '803_645': 36.0557,
    '803_646': 35.926,
    '803_647': 35.8094,
    '803_648': 35.7172,
    '803_649': 35.6417,
    '803_650': 35.5647,
    '803_651': 35.4976,
    '803_652': 35.4398,
    '803_653': 35.3921,
    '803_654': 35.3594,
    '803_655': 35.3402,
    '803_656': 35.3299,
    '803_657': 35.317,
    '803_658': 35.3098,
    '803_659': 35.3143,
    '803_660': 35.3316,
    '804_373': 30.8871,
    '804_374': 30.9361,
    '804_375': 30.9952,
    '804_376': 31.0593,
    '804_377': 31.124,
    '804_378': 31.1744,
    '804_379': 31.2264,
    '804_380': 31.2789,
    '804_381': 31.3326,
    '804_382': 31.3839,
    '804_383': 31.4293,
    '804_384': 31.4798,
    '804_385': 31.5294,
    '804_386': 31.5815,
    '804_387': 31.6298,
    '804_388': 31.6736,
    '804_389': 31.7157,
    '804_390': 31.7542,
    '804_391': 31.7894,
    '804_392': 31.8264,
    '804_393': 31.8708,
    '804_394': 31.9105,
    '804_395': 31.9609,
    '804_396': 32.0192,
    '804_397': 32.0658,
    '804_398': 32.1135,
    '804_399': 32.1744,
    '804_400': 32.2566,
    '804_401': 32.3618,
    '804_402': 32.496,
    '804_403': 32.6203,
    '804_404': 32.7172,
    '804_405': 32.7916,
    '804_406': 32.8379,
    '804_407': 32.8603,
    '804_408': 32.8625,
    '804_409': 32.8663,
    '804_410': 32.8849,
    '804_411': 32.9047,
    '804_412': 32.9225,
    '804_413': 32.9377,
    '804_414': 32.9481,
    '804_415': 32.9405,
    '804_416': 32.9115,
    '804_417': 32.8828,
    '804_418': 32.8498,
    '804_419': 32.7882,
    '804_420': 32.7364,
    '804_421': 32.7047,
    '804_422': 32.6972,
    '804_423': 32.7027,
    '804_424': 32.7167,
    '804_425': 32.7332,
    '804_426': 32.7429,
    '804_427': 32.7557,
    '804_428': 32.7748,
    '804_429': 32.803,
    '804_430': 32.8431,
    '804_431': 32.8796,
    '804_432': 32.9124,
    '804_433': 32.9284,
    '804_434': 32.9286,
    '804_435': 32.9373,
    '804_436': 32.9653,
    '804_437': 33.0007,
    '804_438': 33.0233,
    '804_439': 33.0363,
    '804_440': 33.0227,
    '804_441': 32.9875,
    '804_442': 32.9486,
    '804_443': 32.9199,
    '804_444': 32.8886,
    '804_445': 32.8536,
    '804_446': 32.8197,
    '804_447': 32.793,
    '804_448': 32.7704,
    '804_449': 32.7455,
    '804_450': 32.704,
    '804_451': 32.6411,
    '804_452': 32.5708,
    '804_453': 32.5051,
    '804_454': 32.4505,
    '804_455': 32.4001,
    '804_456': 32.3455,
    '804_457': 32.287,
    '804_458': 32.2185,
    '804_459': 32.1433,
    '804_460': 32.0546,
    '804_461': 31.9502,
    '804_462': 31.8421,
    '804_463': 31.7498,
    '804_464': 31.6699,
    '804_465': 31.606,
    '804_466': 31.5476,
    '804_467': 31.4983,
    '804_468': 31.4501,
    '804_469': 31.3961,
    '804_470': 31.3413,
    '804_471': 31.2896,
    '804_472': 31.2417,
    '804_473': 31.1985,
    '804_474': 31.1717,
    '804_475': 31.1602,
    '804_476': 31.1642,
    '804_477': 31.1848,
    '804_478': 31.2096,
    '804_479': 31.2382,
    '804_480': 31.2996,
    '804_481': 31.3777,
    '804_482': 31.4633,
    '804_483': 31.553,
    '804_484': 31.6483,
    '804_485': 31.7358,
    '804_486': 31.8052,
    '804_487': 31.8638,
    '804_488': 31.9245,
    '804_489': 31.9914,
    '804_490': 32.066,
    '804_491': 32.1482,
    '804_492': 32.2459,
    '804_493': 32.3495,
    '804_494': 32.4578,
    '804_495': 32.5641,
    '804_496': 32.6864,
    '804_497': 32.8125,
    '804_498': 32.9461,
    '804_499': 33.0715,
    '804_500': 33.1976,
    '804_501': 33.3351,
    '804_502': 33.4759,
    '804_503': 33.5965,
    '804_504': 33.6991,
    '804_505': 33.8023,
    '804_506': 33.9097,
    '804_507': 34.0368,
    '804_508': 34.1753,
    '804_509': 34.3262,
    '804_510': 34.4938,
    '804_511': 34.6666,
    '804_512': 34.8424,
    '804_513': 35.019,
    '804_514': 35.1804,
    '804_515': 35.3143,
    '804_516': 35.4358,
    '804_517': 35.5432,
    '804_518': 35.6432,
    '804_519': 35.7256,
    '804_520': 35.7942,
    '804_521': 35.8582,
    '804_522': 35.9206,
    '804_523': 35.964,
    '804_524': 35.983,
    '804_525': 35.9936,
    '804_526': 36.0002,
    '804_527': 35.9928,
    '804_528': 35.9638,
    '804_529': 35.9365,
    '804_530': 35.9163,
    '804_531': 35.8984,
    '804_532': 35.8922,
    '804_533': 35.8972,
    '804_534': 35.8898,
    '804_535': 35.8859,
    '804_536': 35.8837,
    '804_537': 35.8787,
    '804_538': 35.8804,
    '804_539': 35.8879,
    '804_540': 35.9033,
    '804_541': 35.929,
    '804_542': 35.976,
    '804_543': 36.0262,
    '804_544': 36.0679,
    '804_545': 36.0961,
    '804_546': 36.1175,
    '804_547': 36.1434,
    '804_548': 36.1747,
    '804_549': 36.211,
    '804_550': 36.2562,
    '804_551': 36.3084,
    '804_552': 36.367,
    '804_553': 36.4306,
    '804_554': 36.4991,
    '804_555': 36.5716,
    '804_556': 36.6499,
    '804_557': 36.732,
    '804_558': 36.8134,
    '804_559': 36.897,
    '804_560': 36.9842,
    '804_561': 37.084,
    '804_562': 37.2056,
    '804_563': 37.3052,
    '804_564': 37.3571,
    '804_565': 37.3888,
    '804_566': 37.3708,
    '804_567': 37.3093,
    '804_568': 37.1802,
    '804_569': 37.0346,
    '804_570': 36.8884,
    '804_571': 36.7502,
    '804_572': 36.6455,
    '804_573': 36.5516,
    '804_574': 36.4692,
    '804_575': 36.389,
    '804_576': 36.3042,
    '804_577': 36.214,
    '804_578': 36.112,
    '804_579': 36.0186,
    '804_580': 35.9344,
    '804_581': 35.8496,
    '804_582': 35.7445,
    '804_583': 35.6584,
    '804_584': 35.5747,
    '804_585': 35.4945,
    '804_586': 35.4243,
    '804_587': 35.3543,
    '804_588': 35.2874,
    '804_589': 35.2251,
    '804_590': 35.1665,
    '804_591': 35.1117,
    '804_592': 35.0559,
    '804_593': 35.0145,
    '804_594': 34.9789,
    '804_595': 34.9492,
    '804_596': 34.9514,
    '804_597': 34.9399,
    '804_598': 34.9344,
    '804_599': 34.9366,
    '804_600': 34.9424,
    '804_601': 34.9521,
    '804_602': 34.9678,
    '804_603': 34.9917,
    '804_604': 35.0124,
    '804_605': 35.0393,
    '804_606': 35.0809,
    '804_607': 35.1329,
    '804_608': 35.1951,
    '804_609': 35.2626,
    '804_610': 35.3448,
    '804_611': 35.4313,
    '804_612': 35.528,
    '804_613': 35.6436,
    '804_614': 35.7705,
    '804_615': 35.9071,
    '804_616': 36.0544,
    '804_617': 36.2049,
    '804_618': 36.3557,
    '804_619': 36.5063,
    '804_620': 36.6583,
    '804_621': 36.8067,
    '804_622': 36.9485,
    '804_623': 37.093,
    '804_624': 37.236,
    '804_625': 37.403,
    '804_626': 37.5578,
    '804_627': 37.7009,
    '804_628': 37.8339,
    '804_629': 37.9397,
    '804_630': 38.0179,
    '804_631': 38.059,
    '804_632': 38.0735,
    '804_633': 38.0554,
    '804_634': 38.0057,
    '804_635': 37.9233,
    '804_636': 37.804,
    '804_637': 37.6535,
    '804_638': 37.4796,
    '804_639': 37.2866,
    '804_640': 37.0781,
    '804_641': 36.8664,
    '804_642': 36.6441,
    '804_643': 36.4418,
    '804_644': 36.2565,
    '804_645': 36.1,
    '804_646': 35.9551,
    '804_647': 35.8257,
    '804_648': 35.7194,
    '804_649': 35.6335,
    '804_650': 35.552,
    '804_651': 35.4835,
    '804_652': 35.4261,
    '804_653': 35.3771,
    '804_654': 35.3385,
    '804_655': 35.3143,
    '804_656': 35.2982,
    '804_657': 35.2822,
    '804_658': 35.2745,
    '804_659': 35.2769,
    '804_660': 35.291,
    '805_373': 30.8674,
    '805_374': 30.9122,
    '805_375': 30.9688,
    '805_376': 31.0316,
    '805_377': 31.0957,
    '805_378': 31.1498,
    '805_379': 31.2035,
    '805_380': 31.2571,
    '805_381': 31.3098,
    '805_382': 31.3596,
    '805_383': 31.405,
    '805_384': 31.4534,
    '805_385': 31.5026,
    '805_386': 31.5544,
    '805_387': 31.6029,
    '805_388': 31.649,
    '805_389': 31.6951,
    '805_390': 31.7368,
    '805_391': 31.7709,
    '805_392': 31.8086,
    '805_393': 31.8539,
    '805_394': 31.9068,
    '805_395': 31.9575,
    '805_396': 32.0111,
    '805_397': 32.0573,
    '805_398': 32.1029,
    '805_399': 32.1599,
    '805_400': 32.23,
    '805_401': 32.3276,
    '805_402': 32.4448,
    '805_403': 32.5647,
    '805_404': 32.675,
    '805_405': 32.7609,
    '805_406': 32.8186,
    '805_407': 32.8527,
    '805_408': 32.8619,
    '805_409': 32.8781,
    '805_410': 32.9014,
    '805_411': 32.9242,
    '805_412': 32.9473,
    '805_413': 32.9665,
    '805_414': 32.9797,
    '805_415': 32.9713,
    '805_416': 32.9352,
    '805_417': 32.8965,
    '805_418': 32.8667,
    '805_419': 32.8115,
    '805_420': 32.753,
    '805_421': 32.7125,
    '805_422': 32.6988,
    '805_423': 32.7009,
    '805_424': 32.7154,
    '805_425': 32.7345,
    '805_426': 32.7533,
    '805_427': 32.7754,
    '805_428': 32.8007,
    '805_429': 32.831,
    '805_430': 32.8722,
    '805_431': 32.9084,
    '805_432': 32.9453,
    '805_433': 32.9666,
    '805_434': 32.9676,
    '805_435': 32.976,
    '805_436': 33.0069,
    '805_437': 33.0424,
    '805_438': 33.0656,
    '805_439': 33.072,
    '805_440': 33.0473,
    '805_441': 33.0086,
    '805_442': 32.9631,
    '805_443': 32.9205,
    '805_444': 32.8788,
    '805_445': 32.8472,
    '805_446': 32.81,
    '805_447': 32.7829,
    '805_448': 32.7595,
    '805_449': 32.7363,
    '805_450': 32.6966,
    '805_451': 32.6319,
    '805_452': 32.5614,
    '805_453': 32.4919,
    '805_454': 32.4409,
    '805_455': 32.3911,
    '805_456': 32.3383,
    '805_457': 32.2842,
    '805_458': 32.2214,
    '805_459': 32.1538,
    '805_460': 32.0777,
    '805_461': 31.9909,
    '805_462': 31.9013,
    '805_463': 31.8172,
    '805_464': 31.7359,
    '805_465': 31.6673,
    '805_466': 31.6108,
    '805_467': 31.5604,
    '805_468': 31.5048,
    '805_469': 31.4401,
    '805_470': 31.3785,
    '805_471': 31.3202,
    '805_472': 31.2673,
    '805_473': 31.2169,
    '805_474': 31.1798,
    '805_475': 31.1601,
    '805_476': 31.1583,
    '805_477': 31.1645,
    '805_478': 31.1744,
    '805_479': 31.1958,
    '805_480': 31.2475,
    '805_481': 31.3179,
    '805_482': 31.4001,
    '805_483': 31.4929,
    '805_484': 31.5929,
    '805_485': 31.6918,
    '805_486': 31.7727,
    '805_487': 31.8548,
    '805_488': 31.9319,
    '805_489': 31.9958,
    '805_490': 32.0679,
    '805_491': 32.1505,
    '805_492': 32.2457,
    '805_493': 32.3479,
    '805_494': 32.4557,
    '805_495': 32.5679,
    '805_496': 32.6938,
    '805_497': 32.8242,
    '805_498': 32.9482,
    '805_499': 33.0774,
    '805_500': 33.2028,
    '805_501': 33.3373,
    '805_502': 33.4722,
    '805_503': 33.5883,
    '805_504': 33.6918,
    '805_505': 33.7973,
    '805_506': 33.9134,
    '805_507': 34.0352,
    '805_508': 34.1684,
    '805_509': 34.3303,
    '805_510': 34.5112,
    '805_511': 34.6958,
    '805_512': 34.8769,
    '805_513': 35.0576,
    '805_514': 35.2167,
    '805_515': 35.353,
    '805_516': 35.4809,
    '805_517': 35.5909,
    '805_518': 35.689,
    '805_519': 35.773,
    '805_520': 35.8436,
    '805_521': 35.9059,
    '805_522': 35.965,
    '805_523': 35.9991,
    '805_524': 36.0118,
    '805_525': 36.0139,
    '805_526': 36.0155,
    '805_527': 36.0085,
    '805_528': 35.9877,
    '805_529': 35.9632,
    '805_530': 35.9488,
    '805_531': 35.9371,
    '805_532': 35.9301,
    '805_533': 35.9355,
    '805_534': 35.9311,
    '805_535': 35.9326,
    '805_536': 35.9401,
    '805_537': 35.9451,
    '805_538': 35.9468,
    '805_539': 35.9465,
    '805_540': 35.96,
    '805_541': 35.986,
    '805_542': 36.0271,
    '805_543': 36.0734,
    '805_544': 36.1155,
    '805_545': 36.1427,
    '805_546': 36.1637,
    '805_547': 36.1963,
    '805_548': 36.2343,
    '805_549': 36.2759,
    '805_550': 36.3237,
    '805_551': 36.3796,
    '805_552': 36.4414,
    '805_553': 36.5083,
    '805_554': 36.5801,
    '805_555': 36.6559,
    '805_556': 36.7351,
    '805_557': 36.8169,
    '805_558': 36.899,
    '805_559': 36.9819,
    '805_560': 37.0694,
    '805_561': 37.1676,
    '805_562': 37.2899,
    '805_563': 37.3955,
    '805_564': 37.4574,
    '805_565': 37.4805,
    '805_566': 37.4629,
    '805_567': 37.4015,
    '805_568': 37.2748,
    '805_569': 37.1383,
    '805_570': 36.9938,
    '805_571': 36.8615,
    '805_572': 36.7503,
    '805_573': 36.6497,
    '805_574': 36.5638,
    '805_575': 36.4823,
    '805_576': 36.3917,
    '805_577': 36.295,
    '805_578': 36.1954,
    '805_579': 36.1067,
    '805_580': 36.0248,
    '805_581': 35.9434,
    '805_582': 35.8531,
    '805_583': 35.7585,
    '805_584': 35.6643,
    '805_585': 35.5739,
    '805_586': 35.494,
    '805_587': 35.4196,
    '805_588': 35.3495,
    '805_589': 35.2841,
    '805_590': 35.2183,
    '805_591': 35.1578,
    '805_592': 35.1023,
    '805_593': 35.0617,
    '805_594': 35.0231,
    '805_595': 34.9917,
    '805_596': 34.9924,
    '805_597': 34.9823,
    '805_598': 34.9749,
    '805_599': 34.9719,
    '805_600': 34.9748,
    '805_601': 34.9844,
    '805_602': 35.002,
    '805_603': 35.0305,
    '805_604': 35.0576,
    '805_605': 35.0923,
    '805_606': 35.1355,
    '805_607': 35.1921,
    '805_608': 35.2634,
    '805_609': 35.3392,
    '805_610': 35.4244,
    '805_611': 35.5215,
    '805_612': 35.6361,
    '805_613': 35.7685,
    '805_614': 35.9127,
    '805_615': 36.0665,
    '805_616': 36.2272,
    '805_617': 36.3874,
    '805_618': 36.5462,
    '805_619': 36.7046,
    '805_620': 36.8645,
    '805_621': 37.0183,
    '805_622': 37.1676,
    '805_623': 37.3096,
    '805_624': 37.453,
    '805_625': 37.616,
    '805_626': 37.7679,
    '805_627': 37.9075,
    '805_628': 38.031,
    '805_629': 38.1305,
    '805_630': 38.2042,
    '805_631': 38.2442,
    '805_632': 38.2533,
    '805_633': 38.2331,
    '805_634': 38.1794,
    '805_635': 38.0938,
    '805_636': 37.9733,
    '805_637': 37.816,
    '805_638': 37.6266,
    '805_639': 37.4121,
    '805_640': 37.1797,
    '805_641': 36.9563,
    '805_642': 36.7372,
    '805_643': 36.5171,
    '805_644': 36.3187,
    '805_645': 36.1427,
    '805_646': 35.9877,
    '805_647': 35.8479,
    '805_648': 35.7297,
    '805_649': 35.6315,
    '805_650': 35.5391,
    '805_651': 35.4646,
    '805_652': 35.4006,
    '805_653': 35.3507,
    '805_654': 35.3136,
    '805_655': 35.285,
    '805_656': 35.2631,
    '805_657': 35.2445,
    '805_658': 35.2362,
    '805_659': 35.2372,
    '805_660': 35.2489,
    '806_373': 30.8481,
    '806_374': 30.8889,
    '806_375': 30.9437,
    '806_376': 31.0059,
    '806_377': 31.07,
    '806_378': 31.1264,
    '806_379': 31.1818,
    '806_380': 31.2369,
    '806_381': 31.2906,
    '806_382': 31.3386,
    '806_383': 31.3841,
    '806_384': 31.431,
    '806_385': 31.4789,
    '806_386': 31.53,
    '806_387': 31.5788,
    '806_388': 31.6262,
    '806_389': 31.6734,
    '806_390': 31.7192,
    '806_391': 31.757,
    '806_392': 31.7955,
    '806_393': 31.8437,
    '806_394': 31.8943,
    '806_395': 31.9467,
    '806_396': 31.9978,
    '806_397': 32.0433,
    '806_398': 32.0821,
    '806_399': 32.1361,
    '806_400': 32.2048,
    '806_401': 32.2969,
    '806_402': 32.4,
    '806_403': 32.5138,
    '806_404': 32.6293,
    '806_405': 32.7209,
    '806_406': 32.7918,
    '806_407': 32.8377,
    '806_408': 32.8588,
    '806_409': 32.8847,
    '806_410': 32.9095,
    '806_411': 32.9312,
    '806_412': 32.9559,
    '806_413': 32.974,
    '806_414': 32.9812,
    '806_415': 32.9702,
    '806_416': 32.9306,
    '806_417': 32.8823,
    '806_418': 32.8547,
    '806_419': 32.8124,
    '806_420': 32.7651,
    '806_421': 32.7233,
    '806_422': 32.7034,
    '806_423': 32.7009,
    '806_424': 32.7154,
    '806_425': 32.7394,
    '806_426': 32.7671,
    '806_427': 32.7999,
    '806_428': 32.8329,
    '806_429': 32.866,
    '806_430': 32.9027,
    '806_431': 32.938,
    '806_432': 32.9733,
    '806_433': 32.9966,
    '806_434': 33.0031,
    '806_435': 33.0186,
    '806_436': 33.0534,
    '806_437': 33.0871,
    '806_438': 33.1036,
    '806_439': 33.0992,
    '806_440': 33.0719,
    '806_441': 33.0446,
    '806_442': 32.9947,
    '806_443': 32.9378,
    '806_444': 32.8792,
    '806_445': 32.8401,
    '806_446': 32.8025,
    '806_447': 32.7714,
    '806_448': 32.7446,
    '806_449': 32.722,
    '806_450': 32.6887,
    '806_451': 32.6256,
    '806_452': 32.5527,
    '806_453': 32.4838,
    '806_454': 32.4323,
    '806_455': 32.389,
    '806_456': 32.3413,
    '806_457': 32.2894,
    '806_458': 32.2342,
    '806_459': 32.1729,
    '806_460': 32.1038,
    '806_461': 32.0336,
    '806_462': 31.9658,
    '806_463': 31.8873,
    '806_464': 31.8048,
    '806_465': 31.7366,
    '806_466': 31.6792,
    '806_467': 31.6242,
    '806_468': 31.5581,
    '806_469': 31.4822,
    '806_470': 31.4131,
    '806_471': 31.3477,
    '806_472': 31.2886,
    '806_473': 31.2366,
    '806_474': 31.1952,
    '806_475': 31.1678,
    '806_476': 31.1543,
    '806_477': 31.1445,
    '806_478': 31.1455,
    '806_479': 31.1614,
    '806_480': 31.202,
    '806_481': 31.2616,
    '806_482': 31.3381,
    '806_483': 31.4306,
    '806_484': 31.5298,
    '806_485': 31.6297,
    '806_486': 31.7234,
    '806_487': 31.8201,
    '806_488': 31.9163,
    '806_489': 31.9943,
    '806_490': 32.0664,
    '806_491': 32.1444,
    '806_492': 32.2387,
    '806_493': 32.3407,
    '806_494': 32.4457,
    '806_495': 32.5574,
    '806_496': 32.6821,
    '806_497': 32.8202,
    '806_498': 32.9446,
    '806_499': 33.0768,
    '806_500': 33.2015,
    '806_501': 33.3248,
    '806_502': 33.4535,
    '806_503': 33.5702,
    '806_504': 33.6827,
    '806_505': 33.7925,
    '806_506': 33.9112,
    '806_507': 34.0305,
    '806_508': 34.1672,
    '806_509': 34.339,
    '806_510': 34.5287,
    '806_511': 34.7191,
    '806_512': 34.9131,
    '806_513': 35.0966,
    '806_514': 35.2566,
    '806_515': 35.3983,
    '806_516': 35.5326,
    '806_517': 35.6442,
    '806_518': 35.7357,
    '806_519': 35.8224,
    '806_520': 35.8956,
    '806_521': 35.959,
    '806_522': 36.0154,
    '806_523': 36.0491,
    '806_524': 36.0539,
    '806_525': 36.0401,
    '806_526': 36.0294,
    '806_527': 36.0204,
    '806_528': 36.0094,
    '806_529': 35.9948,
    '806_530': 35.9978,
    '806_531': 35.9935,
    '806_532': 35.9771,
    '806_533': 35.9781,
    '806_534': 35.9739,
    '806_535': 35.9782,
    '806_536': 35.9836,
    '806_537': 35.9913,
    '806_538': 35.9968,
    '806_539': 36.0024,
    '806_540': 36.0173,
    '806_541': 36.0443,
    '806_542': 36.0817,
    '806_543': 36.1234,
    '806_544': 36.1607,
    '806_545': 36.1836,
    '806_546': 36.2037,
    '806_547': 36.2459,
    '806_548': 36.2918,
    '806_549': 36.3405,
    '806_550': 36.3933,
    '806_551': 36.4522,
    '806_552': 36.5166,
    '806_553': 36.5861,
    '806_554': 36.66,
    '806_555': 36.7371,
    '806_556': 36.8176,
    '806_557': 36.8994,
    '806_558': 36.9849,
    '806_559': 37.0723,
    '806_560': 37.1619,
    '806_561': 37.2551,
    '806_562': 37.3647,
    '806_563': 37.4697,
    '806_564': 37.5439,
    '806_565': 37.5689,
    '806_566': 37.5569,
    '806_567': 37.4942,
    '806_568': 37.3748,
    '806_569': 37.2424,
    '806_570': 37.0961,
    '806_571': 36.9678,
    '806_572': 36.8545,
    '806_573': 36.7487,
    '806_574': 36.6553,
    '806_575': 36.5715,
    '806_576': 36.4773,
    '806_577': 36.3753,
    '806_578': 36.2737,
    '806_579': 36.1922,
    '806_580': 36.1173,
    '806_581': 36.0331,
    '806_582': 35.942,
    '806_583': 35.8546,
    '806_584': 35.7569,
    '806_585': 35.6604,
    '806_586': 35.5757,
    '806_587': 35.4988,
    '806_588': 35.4272,
    '806_589': 35.3594,
    '806_590': 35.2892,
    '806_591': 35.221,
    '806_592': 35.1601,
    '806_593': 35.116,
    '806_594': 35.0776,
    '806_595': 35.0444,
    '806_596': 35.0411,
    '806_597': 35.0273,
    '806_598': 35.0192,
    '806_599': 35.0163,
    '806_600': 35.0185,
    '806_601': 35.0276,
    '806_602': 35.0469,
    '806_603': 35.0763,
    '806_604': 35.1106,
    '806_605': 35.1533,
    '806_606': 35.203,
    '806_607': 35.2656,
    '806_608': 35.3408,
    '806_609': 35.4237,
    '806_610': 35.5166,
    '806_611': 35.6232,
    '806_612': 35.7489,
    '806_613': 35.8989,
    '806_614': 36.0623,
    '806_615': 36.2328,
    '806_616': 36.4071,
    '806_617': 36.5764,
    '806_618': 36.744,
    '806_619': 36.9093,
    '806_620': 37.0713,
    '806_621': 37.2245,
    '806_622': 37.3737,
    '806_623': 37.5186,
    '806_624': 37.6658,
    '806_625': 37.8181,
    '806_626': 37.9638,
    '806_627': 38.0966,
    '806_628': 38.2134,
    '806_629': 38.3145,
    '806_630': 38.3864,
    '806_631': 38.4228,
    '806_632': 38.4274,
    '806_633': 38.3983,
    '806_634': 38.3433,
    '806_635': 38.2557,
    '806_636': 38.1324,
    '806_637': 37.9761,
    '806_638': 37.7763,
    '806_639': 37.5492,
    '806_640': 37.3039,
    '806_641': 37.0369,
    '806_642': 36.8252,
    '806_643': 36.5979,
    '806_644': 36.3873,
    '806_645': 36.1986,
    '806_646': 36.0261,
    '806_647': 35.873,
    '806_648': 35.7398,
    '806_649': 35.6328,
    '806_650': 35.5395,
    '806_651': 35.4525,
    '806_652': 35.3785,
    '806_653': 35.3242,
    '806_654': 35.2884,
    '806_655': 35.2596,
    '806_656': 35.2337,
    '806_657': 35.2139,
    '806_658': 35.199,
    '806_659': 35.1966,
    '806_660': 35.2034,
    '807_373': 30.8356,
    '807_374': 30.8681,
    '807_375': 30.92,
    '807_376': 30.9801,
    '807_377': 31.0423,
    '807_378': 31.1014,
    '807_379': 31.1591,
    '807_380': 31.2154,
    '807_381': 31.2697,
    '807_382': 31.3188,
    '807_383': 31.3638,
    '807_384': 31.4092,
    '807_385': 31.4554,
    '807_386': 31.5034,
    '807_387': 31.5538,
    '807_388': 31.6022,
    '807_389': 31.6504,
    '807_390': 31.6955,
    '807_391': 31.7376,
    '807_392': 31.7791,
    '807_393': 31.827,
    '807_394': 31.8707,
    '807_395': 31.9271,
    '807_396': 31.975,
    '807_397': 32.0185,
    '807_398': 32.0554,
    '807_399': 32.1084,
    '807_400': 32.1783,
    '807_401': 32.265,
    '807_402': 32.3685,
    '807_403': 32.4896,
    '807_404': 32.6018,
    '807_405': 32.6926,
    '807_406': 32.7686,
    '807_407': 32.8221,
    '807_408': 32.8507,
    '807_409': 32.8845,
    '807_410': 32.9127,
    '807_411': 32.9272,
    '807_412': 32.9479,
    '807_413': 32.9542,
    '807_414': 32.9467,
    '807_415': 32.9334,
    '807_416': 32.8959,
    '807_417': 32.844,
    '807_418': 32.8237,
    '807_419': 32.7966,
    '807_420': 32.7603,
    '807_421': 32.7264,
    '807_422': 32.7044,
    '807_423': 32.7032,
    '807_424': 32.7197,
    '807_425': 32.751,
    '807_426': 32.7891,
    '807_427': 32.8199,
    '807_428': 32.8544,
    '807_429': 32.8972,
    '807_430': 32.938,
    '807_431': 32.9733,
    '807_432': 33.0005,
    '807_433': 33.0194,
    '807_434': 33.0277,
    '807_435': 33.049,
    '807_436': 33.0874,
    '807_437': 33.1207,
    '807_438': 33.1333,
    '807_439': 33.1211,
    '807_440': 33.1015,
    '807_441': 33.0755,
    '807_442': 33.0227,
    '807_443': 32.9591,
    '807_444': 32.8929,
    '807_445': 32.8385,
    '807_446': 32.7988,
    '807_447': 32.7644,
    '807_448': 32.7352,
    '807_449': 32.7111,
    '807_450': 32.6748,
    '807_451': 32.6135,
    '807_452': 32.5478,
    '807_453': 32.4807,
    '807_454': 32.4327,
    '807_455': 32.3967,
    '807_456': 32.3537,
    '807_457': 32.3069,
    '807_458': 32.2572,
    '807_459': 32.204,
    '807_460': 32.1381,
    '807_461': 32.0816,
    '807_462': 32.0308,
    '807_463': 31.9624,
    '807_464': 31.8834,
    '807_465': 31.8125,
    '807_466': 31.7473,
    '807_467': 31.6782,
    '807_468': 31.6016,
    '807_469': 31.5215,
    '807_470': 31.4449,
    '807_471': 31.3724,
    '807_472': 31.3085,
    '807_473': 31.2575,
    '807_474': 31.2147,
    '807_475': 31.1831,
    '807_476': 31.1557,
    '807_477': 31.1315,
    '807_478': 31.1248,
    '807_479': 31.1345,
    '807_480': 31.1638,
    '807_481': 31.211,
    '807_482': 31.2783,
    '807_483': 31.3688,
    '807_484': 31.4674,
    '807_485': 31.566,
    '807_486': 31.6612,
    '807_487': 31.7592,
    '807_488': 31.8593,
    '807_489': 31.946,
    '807_490': 32.0368,
    '807_491': 32.1278,
    '807_492': 32.2201,
    '807_493': 32.3248,
    '807_494': 32.4302,
    '807_495': 32.5404,
    '807_496': 32.6609,
    '807_497': 32.7916,
    '807_498': 32.9287,
    '807_499': 33.0653,
    '807_500': 33.1852,
    '807_501': 33.2962,
    '807_502': 33.4131,
    '807_503': 33.5381,
    '807_504': 33.6544,
    '807_505': 33.7689,
    '807_506': 33.8935,
    '807_507': 34.0161,
    '807_508': 34.1662,
    '807_509': 34.3467,
    '807_510': 34.5358,
    '807_511': 34.7356,
    '807_512': 34.9403,
    '807_513': 35.1319,
    '807_514': 35.3011,
    '807_515': 35.4534,
    '807_516': 35.5873,
    '807_517': 35.6976,
    '807_518': 35.7911,
    '807_519': 35.8777,
    '807_520': 35.9504,
    '807_521': 36.0103,
    '807_522': 36.0627,
    '807_523': 36.089,
    '807_524': 36.0941,
    '807_525': 36.0862,
    '807_526': 36.0726,
    '807_527': 36.0522,
    '807_528': 36.0422,
    '807_529': 36.0413,
    '807_530': 36.0452,
    '807_531': 36.0361,
    '807_532': 36.0292,
    '807_533': 36.0154,
    '807_534': 36.0104,
    '807_535': 36.0208,
    '807_536': 36.0309,
    '807_537': 36.0374,
    '807_538': 36.0437,
    '807_539': 36.0526,
    '807_540': 36.0705,
    '807_541': 36.0985,
    '807_542': 36.1348,
    '807_543': 36.176,
    '807_544': 36.2138,
    '807_545': 36.2327,
    '807_546': 36.2531,
    '807_547': 36.3021,
    '807_548': 36.3524,
    '807_549': 36.4049,
    '807_550': 36.4617,
    '807_551': 36.5237,
    '807_552': 36.5907,
    '807_553': 36.6624,
    '807_554': 36.738,
    '807_555': 36.8165,
    '807_556': 36.8984,
    '807_557': 36.9833,
    '807_558': 37.0777,
    '807_559': 37.1744,
    '807_560': 37.2616,
    '807_561': 37.3536,
    '807_562': 37.438,
    '807_563': 37.5359,
    '807_564': 37.617,
    '807_565': 37.6539,
    '807_566': 37.6436,
    '807_567': 37.5787,
    '807_568': 37.4701,
    '807_569': 37.3467,
    '807_570': 37.1928,
    '807_571': 37.0606,
    '807_572': 36.944,
    '807_573': 36.8385,
    '807_574': 36.7434,
    '807_575': 36.6579,
    '807_576': 36.5639,
    '807_577': 36.4606,
    '807_578': 36.3578,
    '807_579': 36.2746,
    '807_580': 36.1975,
    '807_581': 36.1161,
    '807_582': 36.0285,
    '807_583': 35.944,
    '807_584': 35.8469,
    '807_585': 35.7457,
    '807_586': 35.6611,
    '807_587': 35.5825,
    '807_588': 35.5086,
    '807_589': 35.438,
    '807_590': 35.3669,
    '807_591': 35.2938,
    '807_592': 35.2286,
    '807_593': 35.1789,
    '807_594': 35.1385,
    '807_595': 35.1014,
    '807_596': 35.0925,
    '807_597': 35.0762,
    '807_598': 35.067,
    '807_599': 35.0642,
    '807_600': 35.0667,
    '807_601': 35.0769,
    '807_602': 35.0992,
    '807_603': 35.1296,
    '807_604': 35.1675,
    '807_605': 35.2146,
    '807_606': 35.2743,
    '807_607': 35.3454,
    '807_608': 35.4266,
    '807_609': 35.5163,
    '807_610': 35.6169,
    '807_611': 35.732,
    '807_612': 35.8681,
    '807_613': 36.0347,
    '807_614': 36.2174,
    '807_615': 36.4052,
    '807_616': 36.5884,
    '807_617': 36.7662,
    '807_618': 36.94,
    '807_619': 37.1106,
    '807_620': 37.2746,
    '807_621': 37.4248,
    '807_622': 37.5742,
    '807_623': 37.7215,
    '807_624': 37.8677,
    '807_625': 38.012,
    '807_626': 38.1503,
    '807_627': 38.2758,
    '807_628': 38.3857,
    '807_629': 38.48,
    '807_630': 38.5456,
    '807_631': 38.5819,
    '807_632': 38.5829,
    '807_633': 38.5549,
    '807_634': 38.4972,
    '807_635': 38.4035,
    '807_636': 38.2797,
    '807_637': 38.1192,
    '807_638': 37.9153,
    '807_639': 37.6796,
    '807_640': 37.4279,
    '807_641': 37.1725,
    '807_642': 36.9175,
    '807_643': 36.6777,
    '807_644': 36.4545,
    '807_645': 36.2563,
    '807_646': 36.0748,
    '807_647': 35.9056,
    '807_648': 35.752,
    '807_649': 35.6401,
    '807_650': 35.5368,
    '807_651': 35.4439,
    '807_652': 35.3617,
    '807_653': 35.2969,
    '807_654': 35.2615,
    '807_655': 35.2278,
    '807_656': 35.2012,
    '807_657': 35.1842,
    '807_658': 35.1694,
    '807_659': 35.1603,
    '807_660': 35.1581,
    '808_373': 30.8279,
    '808_374': 30.8558,
    '808_375': 30.9017,
    '808_376': 30.9578,
    '808_377': 31.0177,
    '808_378': 31.0775,
    '808_379': 31.1372,
    '808_380': 31.195,
    '808_381': 31.2502,
    '808_382': 31.3016,
    '808_383': 31.3462,
    '808_384': 31.3877,
    '808_385': 31.4321,
    '808_386': 31.4782,
    '808_387': 31.5256,
    '808_388': 31.5757,
    '808_389': 31.6231,
    '808_390': 31.6686,
    '808_391': 31.7128,
    '808_392': 31.7571,
    '808_393': 31.8037,
    '808_394': 31.847,
    '808_395': 31.9004,
    '808_396': 31.9494,
    '808_397': 31.9919,
    '808_398': 32.0272,
    '808_399': 32.0818,
    '808_400': 32.1508,
    '808_401': 32.2352,
    '808_402': 32.3426,
    '808_403': 32.4618,
    '808_404': 32.5731,
    '808_405': 32.6657,
    '808_406': 32.74,
    '808_407': 32.7943,
    '808_408': 32.8263,
    '808_409': 32.8644,
    '808_410': 32.8937,
    '808_411': 32.9045,
    '808_412': 32.9105,
    '808_413': 32.9006,
    '808_414': 32.876,
    '808_415': 32.8649,
    '808_416': 32.8345,
    '808_417': 32.7922,
    '808_418': 32.7841,
    '808_419': 32.7689,
    '808_420': 32.7398,
    '808_421': 32.7133,
    '808_422': 32.7029,
    '808_423': 32.7123,
    '808_424': 32.7355,
    '808_425': 32.7716,
    '808_426': 32.8177,
    '808_427': 32.8468,
    '808_428': 32.8811,
    '808_429': 32.9284,
    '808_430': 32.9639,
    '808_431': 32.9962,
    '808_432': 33.0196,
    '808_433': 33.029,
    '808_434': 33.0365,
    '808_435': 33.0564,
    '808_436': 33.0986,
    '808_437': 33.1365,
    '808_438': 33.1531,
    '808_439': 33.1422,
    '808_440': 33.126,
    '808_441': 33.0972,
    '808_442': 33.039,
    '808_443': 32.9729,
    '808_444': 32.9021,
    '808_445': 32.8447,
    '808_446': 32.7951,
    '808_447': 32.755,
    '808_448': 32.726,
    '808_449': 32.6956,
    '808_450': 32.6535,
    '808_451': 32.5982,
    '808_452': 32.5455,
    '808_453': 32.4809,
    '808_454': 32.4365,
    '808_455': 32.4112,
    '808_456': 32.3729,
    '808_457': 32.3308,
    '808_458': 32.2816,
    '808_459': 32.2312,
    '808_460': 32.175,
    '808_461': 32.1306,
    '808_462': 32.0836,
    '808_463': 32.0226,
    '808_464': 31.9524,
    '808_465': 31.8801,
    '808_466': 31.8054,
    '808_467': 31.7227,
    '808_468': 31.6389,
    '808_469': 31.558,
    '808_470': 31.4747,
    '808_471': 31.3972,
    '808_472': 31.3291,
    '808_473': 31.2829,
    '808_474': 31.2401,
    '808_475': 31.2027,
    '808_476': 31.1664,
    '808_477': 31.1324,
    '808_478': 31.1143,
    '808_479': 31.1191,
    '808_480': 31.139,
    '808_481': 31.1773,
    '808_482': 31.2362,
    '808_483': 31.3168,
    '808_484': 31.4073,
    '808_485': 31.5005,
    '808_486': 31.5925,
    '808_487': 31.6855,
    '808_488': 31.7812,
    '808_489': 31.8764,
    '808_490': 31.9785,
    '808_491': 32.0874,
    '808_492': 32.1926,
    '808_493': 32.3066,
    '808_494': 32.4138,
    '808_495': 32.5316,
    '808_496': 32.6516,
    '808_497': 32.7794,
    '808_498': 32.9122,
    '808_499': 33.0386,
    '808_500': 33.1496,
    '808_501': 33.2514,
    '808_502': 33.3621,
    '808_503': 33.4967,
    '808_504': 33.6187,
    '808_505': 33.7345,
    '808_506': 33.8648,
    '808_507': 34.0011,
    '808_508': 34.166,
    '808_509': 34.3486,
    '808_510': 34.5445,
    '808_511': 34.7449,
    '808_512': 34.9559,
    '808_513': 35.1563,
    '808_514': 35.3382,
    '808_515': 35.4967,
    '808_516': 35.6309,
    '808_517': 35.7389,
    '808_518': 35.8337,
    '808_519': 35.9201,
    '808_520': 35.9952,
    '808_521': 36.057,
    '808_522': 36.1011,
    '808_523': 36.1254,
    '808_524': 36.1218,
    '808_525': 36.1184,
    '808_526': 36.1126,
    '808_527': 36.1061,
    '808_528': 36.0991,
    '808_529': 36.0877,
    '808_530': 36.0726,
    '808_531': 36.0564,
    '808_532': 36.0576,
    '808_533': 36.0504,
    '808_534': 36.0415,
    '808_535': 36.0512,
    '808_536': 36.0684,
    '808_537': 36.0813,
    '808_538': 36.0894,
    '808_539': 36.0998,
    '808_540': 36.1214,
    '808_541': 36.1489,
    '808_542': 36.1834,
    '808_543': 36.2235,
    '808_544': 36.2609,
    '808_545': 36.2867,
    '808_546': 36.3145,
    '808_547': 36.3581,
    '808_548': 36.4108,
    '808_549': 36.4677,
    '808_550': 36.5287,
    '808_551': 36.5941,
    '808_552': 36.664,
    '808_553': 36.7384,
    '808_554': 36.816,
    '808_555': 36.8958,
    '808_556': 36.9787,
    '808_557': 37.068,
    '808_558': 37.1751,
    '808_559': 37.2858,
    '808_560': 37.3668,
    '808_561': 37.4578,
    '808_562': 37.5251,
    '808_563': 37.6093,
    '808_564': 37.6874,
    '808_565': 37.7284,
    '808_566': 37.7179,
    '808_567': 37.6563,
    '808_568': 37.5612,
    '808_569': 37.4372,
    '808_570': 37.2828,
    '808_571': 37.1436,
    '808_572': 37.0247,
    '808_573': 36.9197,
    '808_574': 36.8211,
    '808_575': 36.7362,
    '808_576': 36.6492,
    '808_577': 36.5476,
    '808_578': 36.4465,
    '808_579': 36.358,
    '808_580': 36.28,
    '808_581': 36.1936,
    '808_582': 36.1047,
    '808_583': 36.0241,
    '808_584': 35.9356,
    '808_585': 35.835,
    '808_586': 35.7469,
    '808_587': 35.6666,
    '808_588': 35.5909,
    '808_589': 35.5178,
    '808_590': 35.4444,
    '808_591': 35.3682,
    '808_592': 35.2979,
    '808_593': 35.2442,
    '808_594': 35.1995,
    '808_595': 35.1609,
    '808_596': 35.149,
    '808_597': 35.1301,
    '808_598': 35.1196,
    '808_599': 35.1166,
    '808_600': 35.1212,
    '808_601': 35.1343,
    '808_602': 35.1575,
    '808_603': 35.1909,
    '808_604': 35.2331,
    '808_605': 35.2861,
    '808_606': 35.3539,
    '808_607': 35.4331,
    '808_608': 35.5211,
    '808_609': 35.617,
    '808_610': 35.7243,
    '808_611': 35.8468,
    '808_612': 35.993,
    '808_613': 36.1739,
    '808_614': 36.3729,
    '808_615': 36.5747,
    '808_616': 36.7657,
    '808_617': 36.95,
    '808_618': 37.1274,
    '808_619': 37.3008,
    '808_620': 37.4641,
    '808_621': 37.6181,
    '808_622': 37.7688,
    '808_623': 37.9168,
    '808_624': 38.0607,
    '808_625': 38.1993,
    '808_626': 38.3312,
    '808_627': 38.4499,
    '808_628': 38.5548,
    '808_629': 38.6349,
    '808_630': 38.6901,
    '808_631': 38.7188,
    '808_632': 38.7178,
    '808_633': 38.6925,
    '808_634': 38.632,
    '808_635': 38.5377,
    '808_636': 38.4078,
    '808_637': 38.2471,
    '808_638': 38.0435,
    '808_639': 37.7986,
    '808_640': 37.5353,
    '808_641': 37.2805,
    '808_642': 37.0099,
    '808_643': 36.7611,
    '808_644': 36.5376,
    '808_645': 36.3153,
    '808_646': 36.121,
    '808_647': 35.9428,
    '808_648': 35.787,
    '808_649': 35.6586,
    '808_650': 35.5454,
    '808_651': 35.4492,
    '808_652': 35.3563,
    '808_653': 35.2846,
    '808_654': 35.2325,
    '808_655': 35.1916,
    '808_656': 35.1647,
    '808_657': 35.1467,
    '808_658': 35.1332,
    '808_659': 35.1236,
    '808_660': 35.1202,
    '809_373': 30.8228,
    '809_374': 30.845,
    '809_375': 30.888,
    '809_376': 30.9417,
    '809_377': 31,
    '809_378': 31.0592,
    '809_379': 31.1191,
    '809_380': 31.1773,
    '809_381': 31.2356,
    '809_382': 31.2905,
    '809_383': 31.3309,
    '809_384': 31.3683,
    '809_385': 31.4109,
    '809_386': 31.4554,
    '809_387': 31.5009,
    '809_388': 31.5496,
    '809_389': 31.5957,
    '809_390': 31.6413,
    '809_391': 31.6855,
    '809_392': 31.7297,
    '809_393': 31.7761,
    '809_394': 31.8272,
    '809_395': 31.8788,
    '809_396': 31.9257,
    '809_397': 31.9682,
    '809_398': 32.0025,
    '809_399': 32.0558,
    '809_400': 32.1218,
    '809_401': 32.2006,
    '809_402': 32.2964,
    '809_403': 32.4022,
    '809_404': 32.5112,
    '809_405': 32.6068,
    '809_406': 32.677,
    '809_407': 32.7303,
    '809_408': 32.7669,
    '809_409': 32.8112,
    '809_410': 32.8418,
    '809_411': 32.8529,
    '809_412': 32.8449,
    '809_413': 32.8194,
    '809_414': 32.7947,
    '809_415': 32.7916,
    '809_416': 32.7696,
    '809_417': 32.7413,
    '809_418': 32.7387,
    '809_419': 32.7284,
    '809_420': 32.7145,
    '809_421': 32.702,
    '809_422': 32.7095,
    '809_423': 32.735,
    '809_424': 32.7648,
    '809_425': 32.8008,
    '809_426': 32.8423,
    '809_427': 32.8752,
    '809_428': 32.9081,
    '809_429': 32.9449,
    '809_430': 32.9744,
    '809_431': 32.9998,
    '809_432': 33.0164,
    '809_433': 33.0288,
    '809_434': 33.0347,
    '809_435': 33.0471,
    '809_436': 33.0909,
    '809_437': 33.1342,
    '809_438': 33.1592,
    '809_439': 33.1587,
    '809_440': 33.145,
    '809_441': 33.1125,
    '809_442': 33.0509,
    '809_443': 32.9829,
    '809_444': 32.9223,
    '809_445': 32.8608,
    '809_446': 32.8024,
    '809_447': 32.7493,
    '809_448': 32.7168,
    '809_449': 32.6862,
    '809_450': 32.6365,
    '809_451': 32.5806,
    '809_452': 32.5342,
    '809_453': 32.4806,
    '809_454': 32.4404,
    '809_455': 32.4167,
    '809_456': 32.389,
    '809_457': 32.3476,
    '809_458': 32.3,
    '809_459': 32.2555,
    '809_460': 32.2098,
    '809_461': 32.1643,
    '809_462': 32.1172,
    '809_463': 32.0601,
    '809_464': 31.9964,
    '809_465': 31.9258,
    '809_466': 31.8488,
    '809_467': 31.7643,
    '809_468': 31.68,
    '809_469': 31.5939,
    '809_470': 31.507,
    '809_471': 31.4283,
    '809_472': 31.3607,
    '809_473': 31.3122,
    '809_474': 31.2718,
    '809_475': 31.2287,
    '809_476': 31.1818,
    '809_477': 31.1392,
    '809_478': 31.1116,
    '809_479': 31.1084,
    '809_480': 31.1223,
    '809_481': 31.1543,
    '809_482': 31.2053,
    '809_483': 31.2751,
    '809_484': 31.3542,
    '809_485': 31.4374,
    '809_486': 31.5232,
    '809_487': 31.6115,
    '809_488': 31.7031,
    '809_489': 31.8018,
    '809_490': 31.909,
    '809_491': 32.0255,
    '809_492': 32.149,
    '809_493': 32.2796,
    '809_494': 32.3965,
    '809_495': 32.5264,
    '809_496': 32.6426,
    '809_497': 32.7749,
    '809_498': 32.9132,
    '809_499': 33.0225,
    '809_500': 33.1124,
    '809_501': 33.2059,
    '809_502': 33.3177,
    '809_503': 33.4507,
    '809_504': 33.5821,
    '809_505': 33.7084,
    '809_506': 33.8417,
    '809_507': 33.9895,
    '809_508': 34.1657,
    '809_509': 34.3482,
    '809_510': 34.5458,
    '809_511': 34.7485,
    '809_512': 34.9574,
    '809_513': 35.1703,
    '809_514': 35.3628,
    '809_515': 35.5252,
    '809_516': 35.6626,
    '809_517': 35.7693,
    '809_518': 35.8639,
    '809_519': 35.9557,
    '809_520': 36.0313,
    '809_521': 36.0858,
    '809_522': 36.1274,
    '809_523': 36.1475,
    '809_524': 36.1474,
    '809_525': 36.1387,
    '809_526': 36.1364,
    '809_527': 36.1399,
    '809_528': 36.1378,
    '809_529': 36.1182,
    '809_530': 36.0926,
    '809_531': 36.0733,
    '809_532': 36.076,
    '809_533': 36.0858,
    '809_534': 36.0877,
    '809_535': 36.0885,
    '809_536': 36.0991,
    '809_537': 36.1127,
    '809_538': 36.1275,
    '809_539': 36.1463,
    '809_540': 36.1714,
    '809_541': 36.1987,
    '809_542': 36.2316,
    '809_543': 36.2715,
    '809_544': 36.3121,
    '809_545': 36.3427,
    '809_546': 36.3765,
    '809_547': 36.4205,
    '809_548': 36.4736,
    '809_549': 36.5331,
    '809_550': 36.5977,
    '809_551': 36.6665,
    '809_552': 36.7395,
    '809_553': 36.8171,
    '809_554': 36.8979,
    '809_555': 36.9795,
    '809_556': 37.0604,
    '809_557': 37.1552,
    '809_558': 37.2713,
    '809_559': 37.3864,
    '809_560': 37.4656,
    '809_561': 37.5555,
    '809_562': 37.6243,
    '809_563': 37.693,
    '809_564': 37.7558,
    '809_565': 37.7908,
    '809_566': 37.7796,
    '809_567': 37.7285,
    '809_568': 37.6429,
    '809_569': 37.511,
    '809_570': 37.3639,
    '809_571': 37.2226,
    '809_572': 37.1066,
    '809_573': 37.0011,
    '809_574': 36.8962,
    '809_575': 36.8109,
    '809_576': 36.7283,
    '809_577': 36.6306,
    '809_578': 36.5301,
    '809_579': 36.4351,
    '809_580': 36.3568,
    '809_581': 36.2696,
    '809_582': 36.1818,
    '809_583': 36.0994,
    '809_584': 36.0171,
    '809_585': 35.9177,
    '809_586': 35.8316,
    '809_587': 35.7517,
    '809_588': 35.6753,
    '809_589': 35.6008,
    '809_590': 35.5262,
    '809_591': 35.4484,
    '809_592': 35.3759,
    '809_593': 35.3166,
    '809_594': 35.2671,
    '809_595': 35.2258,
    '809_596': 35.2116,
    '809_597': 35.1909,
    '809_598': 35.1793,
    '809_599': 35.1771,
    '809_600': 35.1841,
    '809_601': 35.2006,
    '809_602': 35.2276,
    '809_603': 35.264,
    '809_604': 35.3109,
    '809_605': 35.3696,
    '809_606': 35.4457,
    '809_607': 35.5327,
    '809_608': 35.6277,
    '809_609': 35.7296,
    '809_610': 35.8416,
    '809_611': 35.9684,
    '809_612': 36.1205,
    '809_613': 36.3149,
    '809_614': 36.5279,
    '809_615': 36.7391,
    '809_616': 36.9356,
    '809_617': 37.1229,
    '809_618': 37.3033,
    '809_619': 37.4785,
    '809_620': 37.6456,
    '809_621': 37.8049,
    '809_622': 37.9591,
    '809_623': 38.1078,
    '809_624': 38.2496,
    '809_625': 38.3834,
    '809_626': 38.5133,
    '809_627': 38.6258,
    '809_628': 38.7179,
    '809_629': 38.7877,
    '809_630': 38.8329,
    '809_631': 38.8522,
    '809_632': 38.8429,
    '809_633': 38.8101,
    '809_634': 38.748,
    '809_635': 38.6546,
    '809_636': 38.5258,
    '809_637': 38.3687,
    '809_638': 38.1662,
    '809_639': 37.9181,
    '809_640': 37.6505,
    '809_641': 37.3797,
    '809_642': 37.0985,
    '809_643': 36.8283,
    '809_644': 36.5787,
    '809_645': 36.3756,
    '809_646': 36.1718,
    '809_647': 35.9811,
    '809_648': 35.8189,
    '809_649': 35.6771,
    '809_650': 35.551,
    '809_651': 35.4458,
    '809_652': 35.3516,
    '809_653': 35.2718,
    '809_654': 35.2075,
    '809_655': 35.158,
    '809_656': 35.1275,
    '809_657': 35.1086,
    '809_658': 35.0951,
    '809_659': 35.0829,
    '809_660': 35.0765,
    '810_373': 30.8228,
    '810_374': 30.8427,
    '810_375': 30.8806,
    '810_376': 30.9314,
    '810_377': 30.9876,
    '810_378': 31.045,
    '810_379': 31.1034,
    '810_380': 31.16,
    '810_381': 31.216,
    '810_382': 31.2697,
    '810_383': 31.3104,
    '810_384': 31.3488,
    '810_385': 31.3903,
    '810_386': 31.4333,
    '810_387': 31.4767,
    '810_388': 31.5208,
    '810_389': 31.5656,
    '810_390': 31.6133,
    '810_391': 31.6575,
    '810_392': 31.7022,
    '810_393': 31.749,
    '810_394': 31.8012,
    '810_395': 31.8518,
    '810_396': 31.9,
    '810_397': 31.9452,
    '810_398': 31.9824,
    '810_399': 32.0287,
    '810_400': 32.0896,
    '810_401': 32.1614,
    '810_402': 32.2423,
    '810_403': 32.33,
    '810_404': 32.4229,
    '810_405': 32.512,
    '810_406': 32.5769,
    '810_407': 32.6317,
    '810_408': 32.6705,
    '810_409': 32.7217,
    '810_410': 32.7595,
    '810_411': 32.7791,
    '810_412': 32.7679,
    '810_413': 32.7431,
    '810_414': 32.7311,
    '810_415': 32.7398,
    '810_416': 32.7202,
    '810_417': 32.6929,
    '810_418': 32.6908,
    '810_419': 32.6882,
    '810_420': 32.6895,
    '810_421': 32.6977,
    '810_422': 32.7209,
    '810_423': 32.7586,
    '810_424': 32.7945,
    '810_425': 32.8254,
    '810_426': 32.8528,
    '810_427': 32.8793,
    '810_428': 32.9006,
    '810_429': 32.9245,
    '810_430': 32.9448,
    '810_431': 32.968,
    '810_432': 32.9923,
    '810_433': 33.0126,
    '810_434': 33.0197,
    '810_435': 33.0316,
    '810_436': 33.0742,
    '810_437': 33.121,
    '810_438': 33.1518,
    '810_439': 33.1634,
    '810_440': 33.155,
    '810_441': 33.1204,
    '810_442': 33.0602,
    '810_443': 32.9965,
    '810_444': 32.9354,
    '810_445': 32.8774,
    '810_446': 32.8178,
    '810_447': 32.7489,
    '810_448': 32.7118,
    '810_449': 32.678,
    '810_450': 32.6206,
    '810_451': 32.564,
    '810_452': 32.5121,
    '810_453': 32.4625,
    '810_454': 32.4271,
    '810_455': 32.4025,
    '810_456': 32.3789,
    '810_457': 32.3446,
    '810_458': 32.3126,
    '810_459': 32.2811,
    '810_460': 32.2344,
    '810_461': 32.1825,
    '810_462': 32.1375,
    '810_463': 32.0875,
    '810_464': 32.028,
    '810_465': 31.9552,
    '810_466': 31.8817,
    '810_467': 31.799,
    '810_468': 31.7139,
    '810_469': 31.624,
    '810_470': 31.5367,
    '810_471': 31.4582,
    '810_472': 31.3902,
    '810_473': 31.3394,
    '810_474': 31.298,
    '810_475': 31.2522,
    '810_476': 31.1982,
    '810_477': 31.1486,
    '810_478': 31.1139,
    '810_479': 31.1052,
    '810_480': 31.1147,
    '810_481': 31.1404,
    '810_482': 31.183,
    '810_483': 31.2413,
    '810_484': 31.3074,
    '810_485': 31.3785,
    '810_486': 31.4545,
    '810_487': 31.5359,
    '810_488': 31.6248,
    '810_489': 31.7232,
    '810_490': 31.8328,
    '810_491': 31.9542,
    '810_492': 32.0828,
    '810_493': 32.2256,
    '810_494': 32.367,
    '810_495': 32.5068,
    '810_496': 32.6364,
    '810_497': 32.7684,
    '810_498': 32.8995,
    '810_499': 33.0077,
    '810_500': 33.0863,
    '810_501': 33.1706,
    '810_502': 33.2779,
    '810_503': 33.411,
    '810_504': 33.5522,
    '810_505': 33.6973,
    '810_506': 33.8441,
    '810_507': 33.9917,
    '810_508': 34.1689,
    '810_509': 34.3521,
    '810_510': 34.5409,
    '810_511': 34.7436,
    '810_512': 34.9535,
    '810_513': 35.1689,
    '810_514': 35.3702,
    '810_515': 35.5366,
    '810_516': 35.6776,
    '810_517': 35.7864,
    '810_518': 35.8739,
    '810_519': 35.9598,
    '810_520': 36.0343,
    '810_521': 36.0927,
    '810_522': 36.1329,
    '810_523': 36.1595,
    '810_524': 36.1645,
    '810_525': 36.1577,
    '810_526': 36.1613,
    '810_527': 36.1631,
    '810_528': 36.1601,
    '810_529': 36.1456,
    '810_530': 36.1215,
    '810_531': 36.101,
    '810_532': 36.103,
    '810_533': 36.1189,
    '810_534': 36.1341,
    '810_535': 36.145,
    '810_536': 36.153,
    '810_537': 36.156,
    '810_538': 36.1728,
    '810_539': 36.1945,
    '810_540': 36.2224,
    '810_541': 36.2504,
    '810_542': 36.2809,
    '810_543': 36.3165,
    '810_544': 36.3561,
    '810_545': 36.3937,
    '810_546': 36.4343,
    '810_547': 36.482,
    '810_548': 36.5374,
    '810_549': 36.5995,
    '810_550': 36.668,
    '810_551': 36.741,
    '810_552': 36.8172,
    '810_553': 36.8984,
    '810_554': 36.9856,
    '810_555': 37.0718,
    '810_556': 37.1482,
    '810_557': 37.243,
    '810_558': 37.3596,
    '810_559': 37.4785,
    '810_560': 37.5634,
    '810_561': 37.6466,
    '810_562': 37.728,
    '810_563': 37.7907,
    '810_564': 37.8268,
    '810_565': 37.8426,
    '810_566': 37.8286,
    '810_567': 37.7867,
    '810_568': 37.7079,
    '810_569': 37.5753,
    '810_570': 37.4303,
    '810_571': 37.2974,
    '810_572': 37.1817,
    '810_573': 37.075,
    '810_574': 36.9734,
    '810_575': 36.8833,
    '810_576': 36.798,
    '810_577': 36.7135,
    '810_578': 36.6123,
    '810_579': 36.5132,
    '810_580': 36.4275,
    '810_581': 36.3399,
    '810_582': 36.2529,
    '810_583': 36.1697,
    '810_584': 36.0885,
    '810_585': 36.007,
    '810_586': 35.919,
    '810_587': 35.8381,
    '810_588': 35.7605,
    '810_589': 35.6846,
    '810_590': 35.6091,
    '810_591': 35.5307,
    '810_592': 35.4566,
    '810_593': 35.3933,
    '810_594': 35.3387,
    '810_595': 35.2941,
    '810_596': 35.2779,
    '810_597': 35.2561,
    '810_598': 35.2443,
    '810_599': 35.2428,
    '810_600': 35.253,
    '810_601': 35.273,
    '810_602': 35.3032,
    '810_603': 35.3461,
    '810_604': 35.4007,
    '810_605': 35.4678,
    '810_606': 35.5502,
    '810_607': 35.6439,
    '810_608': 35.7458,
    '810_609': 35.8535,
    '810_610': 35.9689,
    '810_611': 36.0985,
    '810_612': 36.2552,
    '810_613': 36.4578,
    '810_614': 36.6806,
    '810_615': 36.8958,
    '810_616': 37.0968,
    '810_617': 37.2876,
    '810_618': 37.4713,
    '810_619': 37.6496,
    '810_620': 37.8219,
    '810_621': 37.9871,
    '810_622': 38.1483,
    '810_623': 38.2981,
    '810_624': 38.4382,
    '810_625': 38.5674,
    '810_626': 38.6881,
    '810_627': 38.7955,
    '810_628': 38.8772,
    '810_629': 38.9359,
    '810_630': 38.9749,
    '810_631': 38.9845,
    '810_632': 38.9647,
    '810_633': 38.9229,
    '810_634': 38.858,
    '810_635': 38.7596,
    '810_636': 38.6333,
    '810_637': 38.4761,
    '810_638': 38.2791,
    '810_639': 38.031,
    '810_640': 37.7586,
    '810_641': 37.4726,
    '810_642': 37.1854,
    '810_643': 36.9109,
    '810_644': 36.6639,
    '810_645': 36.454,
    '810_646': 36.2252,
    '810_647': 36.0284,
    '810_648': 35.8566,
    '810_649': 35.7052,
    '810_650': 35.5652,
    '810_651': 35.448,
    '810_652': 35.3487,
    '810_653': 35.2597,
    '810_654': 35.1895,
    '810_655': 35.135,
    '810_656': 35.0983,
    '810_657': 35.0727,
    '810_658': 35.0577,
    '810_659': 35.0479,
    '810_660': 35.0399,
    '811_373': 30.8264,
    '811_374': 30.8457,
    '811_375': 30.8772,
    '811_376': 30.9258,
    '811_377': 30.9798,
    '811_378': 31.0338,
    '811_379': 31.0884,
    '811_380': 31.1428,
    '811_381': 31.1947,
    '811_382': 31.2442,
    '811_383': 31.2882,
    '811_384': 31.3283,
    '811_385': 31.3693,
    '811_386': 31.4107,
    '811_387': 31.4523,
    '811_388': 31.4944,
    '811_389': 31.5378,
    '811_390': 31.5821,
    '811_391': 31.6261,
    '811_392': 31.6747,
    '811_393': 31.7214,
    '811_394': 31.7704,
    '811_395': 31.8185,
    '811_396': 31.867,
    '811_397': 31.9145,
    '811_398': 31.9531,
    '811_399': 31.9982,
    '811_400': 32.0516,
    '811_401': 32.12,
    '811_402': 32.1912,
    '811_403': 32.266,
    '811_404': 32.3409,
    '811_405': 32.4131,
    '811_406': 32.4735,
    '811_407': 32.5263,
    '811_408': 32.5726,
    '811_409': 32.6198,
    '811_410': 32.6588,
    '811_411': 32.6873,
    '811_412': 32.6886,
    '811_413': 32.6683,
    '811_414': 32.673,
    '811_415': 32.6835,
    '811_416': 32.6654,
    '811_417': 32.6482,
    '811_418': 32.65,
    '811_419': 32.6579,
    '811_420': 32.6745,
    '811_421': 32.7032,
    '811_422': 32.7386,
    '811_423': 32.7859,
    '811_424': 32.8228,
    '811_425': 32.8384,
    '811_426': 32.8492,
    '811_427': 32.8588,
    '811_428': 32.8646,
    '811_429': 32.8766,
    '811_430': 32.889,
    '811_431': 32.9157,
    '811_432': 32.9599,
    '811_433': 32.9824,
    '811_434': 32.9909,
    '811_435': 33.0094,
    '811_436': 33.0521,
    '811_437': 33.0971,
    '811_438': 33.1342,
    '811_439': 33.1534,
    '811_440': 33.1481,
    '811_441': 33.1142,
    '811_442': 33.0592,
    '811_443': 32.9944,
    '811_444': 32.9318,
    '811_445': 32.8732,
    '811_446': 32.8131,
    '811_447': 32.7411,
    '811_448': 32.6959,
    '811_449': 32.653,
    '811_450': 32.5998,
    '811_451': 32.5446,
    '811_452': 32.4912,
    '811_453': 32.4436,
    '811_454': 32.4067,
    '811_455': 32.3799,
    '811_456': 32.3585,
    '811_457': 32.3335,
    '811_458': 32.3172,
    '811_459': 32.2853,
    '811_460': 32.2464,
    '811_461': 32.1959,
    '811_462': 32.1569,
    '811_463': 32.1106,
    '811_464': 32.054,
    '811_465': 31.9819,
    '811_466': 31.9064,
    '811_467': 31.8243,
    '811_468': 31.7391,
    '811_469': 31.6497,
    '811_470': 31.5625,
    '811_471': 31.4852,
    '811_472': 31.4172,
    '811_473': 31.3644,
    '811_474': 31.3193,
    '811_475': 31.2703,
    '811_476': 31.213,
    '811_477': 31.1595,
    '811_478': 31.1205,
    '811_479': 31.1077,
    '811_480': 31.112,
    '811_481': 31.1303,
    '811_482': 31.164,
    '811_483': 31.2132,
    '811_484': 31.2657,
    '811_485': 31.3234,
    '811_486': 31.3879,
    '811_487': 31.4601,
    '811_488': 31.5426,
    '811_489': 31.6381,
    '811_490': 31.7514,
    '811_491': 31.8774,
    '811_492': 32.0049,
    '811_493': 32.1459,
    '811_494': 32.2952,
    '811_495': 32.4536,
    '811_496': 32.6102,
    '811_497': 32.7601,
    '811_498': 32.8867,
    '811_499': 32.9957,
    '811_500': 33.0766,
    '811_501': 33.1536,
    '811_502': 33.244,
    '811_503': 33.3738,
    '811_504': 33.5362,
    '811_505': 33.6951,
    '811_506': 33.8373,
    '811_507': 33.9833,
    '811_508': 34.16,
    '811_509': 34.3467,
    '811_510': 34.5335,
    '811_511': 34.7329,
    '811_512': 34.9426,
    '811_513': 35.1582,
    '811_514': 35.356,
    '811_515': 35.5317,
    '811_516': 35.6758,
    '811_517': 35.7885,
    '811_518': 35.8776,
    '811_519': 35.9544,
    '811_520': 36.022,
    '811_521': 36.0777,
    '811_522': 36.1195,
    '811_523': 36.1531,
    '811_524': 36.1655,
    '811_525': 36.1674,
    '811_526': 36.1873,
    '811_527': 36.1974,
    '811_528': 36.1845,
    '811_529': 36.1662,
    '811_530': 36.1481,
    '811_531': 36.1352,
    '811_532': 36.1402,
    '811_533': 36.1547,
    '811_534': 36.1737,
    '811_535': 36.1921,
    '811_536': 36.2133,
    '811_537': 36.2161,
    '811_538': 36.2345,
    '811_539': 36.2562,
    '811_540': 36.2845,
    '811_541': 36.3092,
    '811_542': 36.3319,
    '811_543': 36.3643,
    '811_544': 36.4029,
    '811_545': 36.4444,
    '811_546': 36.4896,
    '811_547': 36.5417,
    '811_548': 36.6002,
    '811_549': 36.6647,
    '811_550': 36.7383,
    '811_551': 36.8186,
    '811_552': 36.8977,
    '811_553': 36.9885,
    '811_554': 37.0881,
    '811_555': 37.1786,
    '811_556': 37.2489,
    '811_557': 37.3408,
    '811_558': 37.4507,
    '811_559': 37.5686,
    '811_560': 37.6654,
    '811_561': 37.7389,
    '811_562': 37.8233,
    '811_563': 37.8876,
    '811_564': 37.9133,
    '811_565': 37.903,
    '811_566': 37.8874,
    '811_567': 37.8471,
    '811_568': 37.7672,
    '811_569': 37.6373,
    '811_570': 37.4957,
    '811_571': 37.3703,
    '811_572': 37.2508,
    '811_573': 37.1444,
    '811_574': 37.0442,
    '811_575': 36.9515,
    '811_576': 36.8642,
    '811_577': 36.7787,
    '811_578': 36.6825,
    '811_579': 36.5808,
    '811_580': 36.4932,
    '811_581': 36.4048,
    '811_582': 36.3174,
    '811_583': 36.2354,
    '811_584': 36.1579,
    '811_585': 36.0815,
    '811_586': 36.0001,
    '811_587': 35.922,
    '811_588': 35.8437,
    '811_589': 35.7655,
    '811_590': 35.6881,
    '811_591': 35.6119,
    '811_592': 35.5401,
    '811_593': 35.4719,
    '811_594': 35.4118,
    '811_595': 35.3625,
    '811_596': 35.3433,
    '811_597': 35.3234,
    '811_598': 35.3146,
    '811_599': 35.3159,
    '811_600': 35.3297,
    '811_601': 35.3529,
    '811_602': 35.3869,
    '811_603': 35.4364,
    '811_604': 35.4987,
    '811_605': 35.5737,
    '811_606': 35.6626,
    '811_607': 35.7633,
    '811_608': 35.8725,
    '811_609': 35.9867,
    '811_610': 36.1043,
    '811_611': 36.2348,
    '811_612': 36.3927,
    '811_613': 36.5978,
    '811_614': 36.8237,
    '811_615': 37.0469,
    '811_616': 37.2514,
    '811_617': 37.4455,
    '811_618': 37.6327,
    '811_619': 37.8151,
    '811_620': 37.9953,
    '811_621': 38.168,
    '811_622': 38.3324,
    '811_623': 38.4844,
    '811_624': 38.6192,
    '811_625': 38.7452,
    '811_626': 38.8547,
    '811_627': 38.9492,
    '811_628': 39.0257,
    '811_629': 39.0731,
    '811_630': 39.1008,
    '811_631': 39.1062,
    '811_632': 39.0832,
    '811_633': 39.0352,
    '811_634': 38.9673,
    '811_635': 38.8681,
    '811_636': 38.7357,
    '811_637': 38.5765,
    '811_638': 38.3835,
    '811_639': 38.1476,
    '811_640': 37.8655,
    '811_641': 37.5643,
    '811_642': 37.2689,
    '811_643': 36.9874,
    '811_644': 36.7349,
    '811_645': 36.5143,
    '811_646': 36.298,
    '811_647': 36.08,
    '811_648': 35.8977,
    '811_649': 35.7296,
    '811_650': 35.581,
    '811_651': 35.459,
    '811_652': 35.3505,
    '811_653': 35.2579,
    '811_654': 35.1813,
    '811_655': 35.1211,
    '811_656': 35.0789,
    '811_657': 35.0476,
    '811_658': 35.0253,
    '811_659': 35.0141,
    '811_660': 35.0085,
    '812_373': 30.8315,
    '812_374': 30.8501,
    '812_375': 30.8797,
    '812_376': 30.9266,
    '812_377': 30.9784,
    '812_378': 31.0266,
    '812_379': 31.0768,
    '812_380': 31.1264,
    '812_381': 31.1746,
    '812_382': 31.221,
    '812_383': 31.2658,
    '812_384': 31.3091,
    '812_385': 31.3492,
    '812_386': 31.3888,
    '812_387': 31.4284,
    '812_388': 31.4692,
    '812_389': 31.5121,
    '812_390': 31.5559,
    '812_391': 31.5995,
    '812_392': 31.6477,
    '812_393': 31.6939,
    '812_394': 31.7399,
    '812_395': 31.7854,
    '812_396': 31.8322,
    '812_397': 31.8764,
    '812_398': 31.918,
    '812_399': 31.9667,
    '812_400': 32.0167,
    '812_401': 32.0776,
    '812_402': 32.1429,
    '812_403': 32.213,
    '812_404': 32.2791,
    '812_405': 32.3409,
    '812_406': 32.3967,
    '812_407': 32.4467,
    '812_408': 32.4921,
    '812_409': 32.5335,
    '812_410': 32.5687,
    '812_411': 32.6031,
    '812_412': 32.6155,
    '812_413': 32.6014,
    '812_414': 32.6079,
    '812_415': 32.6182,
    '812_416': 32.6115,
    '812_417': 32.61,
    '812_418': 32.619,
    '812_419': 32.6383,
    '812_420': 32.6717,
    '812_421': 32.7188,
    '812_422': 32.7602,
    '812_423': 32.8197,
    '812_424': 32.8421,
    '812_425': 32.8405,
    '812_426': 32.8328,
    '812_427': 32.8308,
    '812_428': 32.8256,
    '812_429': 32.8298,
    '812_430': 32.8429,
    '812_431': 32.8726,
    '812_432': 32.9196,
    '812_433': 32.9424,
    '812_434': 32.955,
    '812_435': 32.9867,
    '812_436': 33.0294,
    '812_437': 33.0736,
    '812_438': 33.108,
    '812_439': 33.1292,
    '812_440': 33.1255,
    '812_441': 33.0958,
    '812_442': 33.0427,
    '812_443': 32.9763,
    '812_444': 32.9155,
    '812_445': 32.8517,
    '812_446': 32.7923,
    '812_447': 32.7264,
    '812_448': 32.6729,
    '812_449': 32.6259,
    '812_450': 32.5796,
    '812_451': 32.5246,
    '812_452': 32.4741,
    '812_453': 32.4279,
    '812_454': 32.389,
    '812_455': 32.3588,
    '812_456': 32.3334,
    '812_457': 32.3122,
    '812_458': 32.2981,
    '812_459': 32.267,
    '812_460': 32.2402,
    '812_461': 32.2081,
    '812_462': 32.1763,
    '812_463': 32.1347,
    '812_464': 32.0799,
    '812_465': 32.0085,
    '812_466': 31.929,
    '812_467': 31.8457,
    '812_468': 31.7609,
    '812_469': 31.674,
    '812_470': 31.5872,
    '812_471': 31.5116,
    '812_472': 31.4453,
    '812_473': 31.3915,
    '812_474': 31.3423,
    '812_475': 31.2867,
    '812_476': 31.2285,
    '812_477': 31.174,
    '812_478': 31.1326,
    '812_479': 31.1166,
    '812_480': 31.1138,
    '812_481': 31.1249,
    '812_482': 31.1503,
    '812_483': 31.1907,
    '812_484': 31.2287,
    '812_485': 31.274,
    '812_486': 31.3282,
    '812_487': 31.3918,
    '812_488': 31.468,
    '812_489': 31.5602,
    '812_490': 31.6694,
    '812_491': 31.7894,
    '812_492': 31.9213,
    '812_493': 32.0601,
    '812_494': 32.211,
    '812_495': 32.3735,
    '812_496': 32.5411,
    '812_497': 32.7075,
    '812_498': 32.8552,
    '812_499': 32.9696,
    '812_500': 33.0476,
    '812_501': 33.127,
    '812_502': 33.2204,
    '812_503': 33.346,
    '812_504': 33.4979,
    '812_505': 33.6499,
    '812_506': 33.7965,
    '812_507': 33.9568,
    '812_508': 34.1406,
    '812_509': 34.3219,
    '812_510': 34.51,
    '812_511': 34.7086,
    '812_512': 34.9149,
    '812_513': 35.1262,
    '812_514': 35.3275,
    '812_515': 35.5075,
    '812_516': 35.662,
    '812_517': 35.7854,
    '812_518': 35.8812,
    '812_519': 35.9494,
    '812_520': 36.0018,
    '812_521': 36.0601,
    '812_522': 36.1079,
    '812_523': 36.1445,
    '812_524': 36.16,
    '812_525': 36.1695,
    '812_526': 36.2053,
    '812_527': 36.2272,
    '812_528': 36.2214,
    '812_529': 36.1934,
    '812_530': 36.1714,
    '812_531': 36.174,
    '812_532': 36.1913,
    '812_533': 36.2148,
    '812_534': 36.2302,
    '812_535': 36.2485,
    '812_536': 36.2685,
    '812_537': 36.2784,
    '812_538': 36.2974,
    '812_539': 36.3162,
    '812_540': 36.3409,
    '812_541': 36.3677,
    '812_542': 36.3896,
    '812_543': 36.4207,
    '812_544': 36.46,
    '812_545': 36.5029,
    '812_546': 36.5489,
    '812_547': 36.6023,
    '812_548': 36.6636,
    '812_549': 36.7326,
    '812_550': 36.8122,
    '812_551': 36.902,
    '812_552': 36.9929,
    '812_553': 37.0896,
    '812_554': 37.1976,
    '812_555': 37.2868,
    '812_556': 37.3526,
    '812_557': 37.4442,
    '812_558': 37.5502,
    '812_559': 37.6646,
    '812_560': 37.7676,
    '812_561': 37.8433,
    '812_562': 37.9129,
    '812_563': 37.9775,
    '812_564': 37.9991,
    '812_565': 37.9828,
    '812_566': 37.9689,
    '812_567': 37.9215,
    '812_568': 37.8348,
    '812_569': 37.704,
    '812_570': 37.5688,
    '812_571': 37.4447,
    '812_572': 37.3203,
    '812_573': 37.211,
    '812_574': 37.1104,
    '812_575': 37.0142,
    '812_576': 36.9219,
    '812_577': 36.8325,
    '812_578': 36.7497,
    '812_579': 36.6515,
    '812_580': 36.5612,
    '812_581': 36.4671,
    '812_582': 36.3763,
    '812_583': 36.2946,
    '812_584': 36.2193,
    '812_585': 36.1475,
    '812_586': 36.0794,
    '812_587': 36.0049,
    '812_588': 35.9259,
    '812_589': 35.8453,
    '812_590': 35.7656,
    '812_591': 35.6894,
    '812_592': 35.617,
    '812_593': 35.5484,
    '812_594': 35.4868,
    '812_595': 35.4355,
    '812_596': 35.4131,
    '812_597': 35.3969,
    '812_598': 35.3931,
    '812_599': 35.3999,
    '812_600': 35.4171,
    '812_601': 35.4438,
    '812_602': 35.482,
    '812_603': 35.5366,
    '812_604': 35.6047,
    '812_605': 35.6856,
    '812_606': 35.7812,
    '812_607': 35.889,
    '812_608': 36.0058,
    '812_609': 36.1266,
    '812_610': 36.2465,
    '812_611': 36.3773,
    '812_612': 36.5341,
    '812_613': 36.7388,
    '812_614': 36.9645,
    '812_615': 37.1905,
    '812_616': 37.3992,
    '812_617': 37.5983,
    '812_618': 37.7898,
    '812_619': 37.9781,
    '812_620': 38.1597,
    '812_621': 38.3394,
    '812_622': 38.509,
    '812_623': 38.6623,
    '812_624': 38.793,
    '812_625': 38.9084,
    '812_626': 39.0028,
    '812_627': 39.0823,
    '812_628': 39.1524,
    '812_629': 39.1907,
    '812_630': 39.2098,
    '812_631': 39.2104,
    '812_632': 39.1887,
    '812_633': 39.1427,
    '812_634': 39.0751,
    '812_635': 38.9731,
    '812_636': 38.8396,
    '812_637': 38.674,
    '812_638': 38.4808,
    '812_639': 38.2513,
    '812_640': 37.972,
    '812_641': 37.6647,
    '812_642': 37.36,
    '812_643': 37.0716,
    '812_644': 36.8079,
    '812_645': 36.5691,
    '812_646': 36.3496,
    '812_647': 36.138,
    '812_648': 35.9429,
    '812_649': 35.767,
    '812_650': 35.6096,
    '812_651': 35.476,
    '812_652': 35.3619,
    '812_653': 35.2649,
    '812_654': 35.1803,
    '812_655': 35.1124,
    '812_656': 35.0629,
    '812_657': 35.0252,
    '812_658': 34.9987,
    '812_659': 34.9845,
    '812_660': 34.9801,
    '813_373': 30.8327,
    '813_374': 30.8502,
    '813_375': 30.8773,
    '813_376': 30.9289,
    '813_377': 30.9813,
    '813_378': 31.0243,
    '813_379': 31.068,
    '813_380': 31.1127,
    '813_381': 31.1576,
    '813_382': 31.2018,
    '813_383': 31.2456,
    '813_384': 31.2904,
    '813_385': 31.3287,
    '813_386': 31.3663,
    '813_387': 31.4036,
    '813_388': 31.4435,
    '813_389': 31.4873,
    '813_390': 31.5321,
    '813_391': 31.5749,
    '813_392': 31.6176,
    '813_393': 31.6663,
    '813_394': 31.7111,
    '813_395': 31.7548,
    '813_396': 31.798,
    '813_397': 31.84,
    '813_398': 31.884,
    '813_399': 31.9334,
    '813_400': 31.983,
    '813_401': 32.0414,
    '813_402': 32.1026,
    '813_403': 32.1651,
    '813_404': 32.2289,
    '813_405': 32.2858,
    '813_406': 32.337,
    '813_407': 32.3846,
    '813_408': 32.4265,
    '813_409': 32.463,
    '813_410': 32.501,
    '813_411': 32.5395,
    '813_412': 32.5501,
    '813_413': 32.5444,
    '813_414': 32.5514,
    '813_415': 32.5617,
    '813_416': 32.5686,
    '813_417': 32.5782,
    '813_418': 32.5968,
    '813_419': 32.6258,
    '813_420': 32.6671,
    '813_421': 32.7157,
    '813_422': 32.7732,
    '813_423': 32.8378,
    '813_424': 32.8481,
    '813_425': 32.8322,
    '813_426': 32.8156,
    '813_427': 32.8092,
    '813_428': 32.7973,
    '813_429': 32.7955,
    '813_430': 32.8103,
    '813_431': 32.8346,
    '813_432': 32.8738,
    '813_433': 32.8967,
    '813_434': 32.9181,
    '813_435': 32.9625,
    '813_436': 33.0086,
    '813_437': 33.0516,
    '813_438': 33.0745,
    '813_439': 33.0916,
    '813_440': 33.0837,
    '813_441': 33.0604,
    '813_442': 33.0109,
    '813_443': 32.9477,
    '813_444': 32.887,
    '813_445': 32.8253,
    '813_446': 32.767,
    '813_447': 32.7098,
    '813_448': 32.6549,
    '813_449': 32.606,
    '813_450': 32.5593,
    '813_451': 32.5065,
    '813_452': 32.457,
    '813_453': 32.4129,
    '813_454': 32.3741,
    '813_455': 32.3405,
    '813_456': 32.3115,
    '813_457': 32.2886,
    '813_458': 32.2699,
    '813_459': 32.249,
    '813_460': 32.2334,
    '813_461': 32.2115,
    '813_462': 32.1875,
    '813_463': 32.1551,
    '813_464': 32.107,
    '813_465': 32.0352,
    '813_466': 31.9501,
    '813_467': 31.862,
    '813_468': 31.7765,
    '813_469': 31.6903,
    '813_470': 31.6067,
    '813_471': 31.534,
    '813_472': 31.4708,
    '813_473': 31.4195,
    '813_474': 31.3656,
    '813_475': 31.3051,
    '813_476': 31.2471,
    '813_477': 31.1924,
    '813_478': 31.1488,
    '813_479': 31.1307,
    '813_480': 31.1198,
    '813_481': 31.1215,
    '813_482': 31.135,
    '813_483': 31.158,
    '813_484': 31.1909,
    '813_485': 31.231,
    '813_486': 31.2797,
    '813_487': 31.3394,
    '813_488': 31.4119,
    '813_489': 31.4981,
    '813_490': 31.5976,
    '813_491': 31.7079,
    '813_492': 31.8307,
    '813_493': 31.9687,
    '813_494': 32.1175,
    '813_495': 32.2765,
    '813_496': 32.4419,
    '813_497': 32.6142,
    '813_498': 32.7861,
    '813_499': 32.9167,
    '813_500': 33.0088,
    '813_501': 33.0892,
    '813_502': 33.1959,
    '813_503': 33.3439,
    '813_504': 33.4864,
    '813_505': 33.6126,
    '813_506': 33.7539,
    '813_507': 33.9207,
    '813_508': 34.0997,
    '813_509': 34.28,
    '813_510': 34.4681,
    '813_511': 34.6588,
    '813_512': 34.8613,
    '813_513': 35.072,
    '813_514': 35.2762,
    '813_515': 35.4637,
    '813_516': 35.6357,
    '813_517': 35.7719,
    '813_518': 35.8756,
    '813_519': 35.9412,
    '813_520': 35.9939,
    '813_521': 36.0641,
    '813_522': 36.1276,
    '813_523': 36.1661,
    '813_524': 36.1796,
    '813_525': 36.187,
    '813_526': 36.2108,
    '813_527': 36.239,
    '813_528': 36.2411,
    '813_529': 36.2233,
    '813_530': 36.2168,
    '813_531': 36.2317,
    '813_532': 36.2542,
    '813_533': 36.2804,
    '813_534': 36.2986,
    '813_535': 36.3209,
    '813_536': 36.3424,
    '813_537': 36.3591,
    '813_538': 36.373,
    '813_539': 36.3864,
    '813_540': 36.4028,
    '813_541': 36.4264,
    '813_542': 36.451,
    '813_543': 36.4834,
    '813_544': 36.521,
    '813_545': 36.5658,
    '813_546': 36.6109,
    '813_547': 36.6655,
    '813_548': 36.7304,
    '813_549': 36.8029,
    '813_550': 36.8862,
    '813_551': 36.981,
    '813_552': 37.0852,
    '813_553': 37.19,
    '813_554': 37.3054,
    '813_555': 37.3955,
    '813_556': 37.457,
    '813_557': 37.5516,
    '813_558': 37.6505,
    '813_559': 37.7591,
    '813_560': 37.8672,
    '813_561': 37.9465,
    '813_562': 37.9992,
    '813_563': 38.056,
    '813_564': 38.0761,
    '813_565': 38.0649,
    '813_566': 38.0484,
    '813_567': 37.9934,
    '813_568': 37.8996,
    '813_569': 37.7679,
    '813_570': 37.6434,
    '813_571': 37.5157,
    '813_572': 37.39,
    '813_573': 37.2761,
    '813_574': 37.1732,
    '813_575': 37.0744,
    '813_576': 36.9793,
    '813_577': 36.8901,
    '813_578': 36.8046,
    '813_579': 36.7085,
    '813_580': 36.6196,
    '813_581': 36.5216,
    '813_582': 36.4271,
    '813_583': 36.3472,
    '813_584': 36.2748,
    '813_585': 36.2072,
    '813_586': 36.1436,
    '813_587': 36.0791,
    '813_588': 36.0024,
    '813_589': 35.9214,
    '813_590': 35.8409,
    '813_591': 35.7644,
    '813_592': 35.6917,
    '813_593': 35.6236,
    '813_594': 35.5617,
    '813_595': 35.5104,
    '813_596': 35.4885,
    '813_597': 35.4756,
    '813_598': 35.4777,
    '813_599': 35.4906,
    '813_600': 35.5118,
    '813_601': 35.5422,
    '813_602': 35.5845,
    '813_603': 35.6435,
    '813_604': 35.7166,
    '813_605': 35.803,
    '813_606': 35.9029,
    '813_607': 36.017,
    '813_608': 36.1407,
    '813_609': 36.268,
    '813_610': 36.3918,
    '813_611': 36.5255,
    '813_612': 36.6828,
    '813_613': 36.8831,
    '813_614': 37.1031,
    '813_615': 37.3259,
    '813_616': 37.5399,
    '813_617': 37.7466,
    '813_618': 37.943,
    '813_619': 38.1336,
    '813_620': 38.3123,
    '813_621': 38.4913,
    '813_622': 38.6604,
    '813_623': 38.8132,
    '813_624': 38.94,
    '813_625': 39.0448,
    '813_626': 39.1302,
    '813_627': 39.2002,
    '813_628': 39.2589,
    '813_629': 39.2994,
    '813_630': 39.3196,
    '813_631': 39.3137,
    '813_632': 39.2958,
    '813_633': 39.248,
    '813_634': 39.1738,
    '813_635': 39.0701,
    '813_636': 38.9371,
    '813_637': 38.7688,
    '813_638': 38.5663,
    '813_639': 38.3315,
    '813_640': 38.059,
    '813_641': 37.7629,
    '813_642': 37.4587,
    '813_643': 37.161,
    '813_644': 36.8862,
    '813_645': 36.6327,
    '813_646': 36.4014,
    '813_647': 36.1862,
    '813_648': 35.9843,
    '813_649': 35.8014,
    '813_650': 35.6346,
    '813_651': 35.493,
    '813_652': 35.3736,
    '813_653': 35.2728,
    '813_654': 35.1866,
    '813_655': 35.115,
    '813_656': 35.0528,
    '813_657': 35.0083,
    '813_658': 34.9769,
    '813_659': 34.9608,
    '813_660': 34.9492,
    '814_373': 30.8333,
    '814_374': 30.8508,
    '814_375': 30.8754,
    '814_376': 30.9312,
    '814_377': 30.9761,
    '814_378': 31.0163,
    '814_379': 31.0573,
    '814_380': 31.0995,
    '814_381': 31.1424,
    '814_382': 31.1851,
    '814_383': 31.2274,
    '814_384': 31.269,
    '814_385': 31.307,
    '814_386': 31.3436,
    '814_387': 31.3779,
    '814_388': 31.4167,
    '814_389': 31.4652,
    '814_390': 31.5142,
    '814_391': 31.5555,
    '814_392': 31.5915,
    '814_393': 31.6392,
    '814_394': 31.6834,
    '814_395': 31.7256,
    '814_396': 31.7675,
    '814_397': 31.8087,
    '814_398': 31.8495,
    '814_399': 31.8984,
    '814_400': 31.9514,
    '814_401': 32.0118,
    '814_402': 32.0707,
    '814_403': 32.1285,
    '814_404': 32.187,
    '814_405': 32.2398,
    '814_406': 32.2918,
    '814_407': 32.3399,
    '814_408': 32.3747,
    '814_409': 32.4111,
    '814_410': 32.448,
    '814_411': 32.4796,
    '814_412': 32.4943,
    '814_413': 32.4986,
    '814_414': 32.5076,
    '814_415': 32.5208,
    '814_416': 32.5354,
    '814_417': 32.5541,
    '814_418': 32.5821,
    '814_419': 32.6204,
    '814_420': 32.6612,
    '814_421': 32.7121,
    '814_422': 32.7821,
    '814_423': 32.8432,
    '814_424': 32.8397,
    '814_425': 32.8133,
    '814_426': 32.7944,
    '814_427': 32.782,
    '814_428': 32.7704,
    '814_429': 32.7712,
    '814_430': 32.7851,
    '814_431': 32.807,
    '814_432': 32.8353,
    '814_433': 32.8552,
    '814_434': 32.8833,
    '814_435': 32.9358,
    '814_436': 32.9858,
    '814_437': 33.0274,
    '814_438': 33.0388,
    '814_439': 33.0509,
    '814_440': 33.0407,
    '814_441': 33.0213,
    '814_442': 32.9774,
    '814_443': 32.9181,
    '814_444': 32.8576,
    '814_445': 32.7967,
    '814_446': 32.7435,
    '814_447': 32.6916,
    '814_448': 32.6398,
    '814_449': 32.5901,
    '814_450': 32.5442,
    '814_451': 32.4931,
    '814_452': 32.4434,
    '814_453': 32.3992,
    '814_454': 32.3601,
    '814_455': 32.3245,
    '814_456': 32.2948,
    '814_457': 32.2711,
    '814_458': 32.2521,
    '814_459': 32.2379,
    '814_460': 32.2283,
    '814_461': 32.2148,
    '814_462': 32.1993,
    '814_463': 32.1738,
    '814_464': 32.1299,
    '814_465': 32.0578,
    '814_466': 31.9672,
    '814_467': 31.8742,
    '814_468': 31.7847,
    '814_469': 31.7012,
    '814_470': 31.622,
    '814_471': 31.5518,
    '814_472': 31.4905,
    '814_473': 31.4406,
    '814_474': 31.3876,
    '814_475': 31.3275,
    '814_476': 31.2689,
    '814_477': 31.2151,
    '814_478': 31.1683,
    '814_479': 31.1411,
    '814_480': 31.1262,
    '814_481': 31.1229,
    '814_482': 31.129,
    '814_483': 31.1435,
    '814_484': 31.1675,
    '814_485': 31.2034,
    '814_486': 31.2483,
    '814_487': 31.3029,
    '814_488': 31.3691,
    '814_489': 31.447,
    '814_490': 31.5362,
    '814_491': 31.6355,
    '814_492': 31.7475,
    '814_493': 31.8786,
    '814_494': 32.0253,
    '814_495': 32.1802,
    '814_496': 32.3417,
    '814_497': 32.5141,
    '814_498': 32.6957,
    '814_499': 32.8436,
    '814_500': 32.9574,
    '814_501': 33.0491,
    '814_502': 33.1801,
    '814_503': 33.3461,
    '814_504': 33.4821,
    '814_505': 33.604,
    '814_506': 33.7343,
    '814_507': 33.8844,
    '814_508': 34.0501,
    '814_509': 34.2265,
    '814_510': 34.4155,
    '814_511': 34.5981,
    '814_512': 34.7943,
    '814_513': 35.0013,
    '814_514': 35.2107,
    '814_515': 35.4111,
    '814_516': 35.5925,
    '814_517': 35.7381,
    '814_518': 35.8464,
    '814_519': 35.9253,
    '814_520': 35.9856,
    '814_521': 36.078,
    '814_522': 36.1547,
    '814_523': 36.2018,
    '814_524': 36.2152,
    '814_525': 36.2229,
    '814_526': 36.2295,
    '814_527': 36.2422,
    '814_528': 36.2577,
    '814_529': 36.2714,
    '814_530': 36.2776,
    '814_531': 36.2964,
    '814_532': 36.3131,
    '814_533': 36.3419,
    '814_534': 36.371,
    '814_535': 36.4019,
    '814_536': 36.4401,
    '814_537': 36.4643,
    '814_538': 36.4697,
    '814_539': 36.4721,
    '814_540': 36.4857,
    '814_541': 36.5048,
    '814_542': 36.5286,
    '814_543': 36.5588,
    '814_544': 36.594,
    '814_545': 36.6347,
    '814_546': 36.6799,
    '814_547': 36.7357,
    '814_548': 36.8041,
    '814_549': 36.8804,
    '814_550': 36.9639,
    '814_551': 37.0617,
    '814_552': 37.1682,
    '814_553': 37.2905,
    '814_554': 37.4041,
    '814_555': 37.4975,
    '814_556': 37.5634,
    '814_557': 37.6541,
    '814_558': 37.7535,
    '814_559': 37.8523,
    '814_560': 37.9628,
    '814_561': 38.0425,
    '814_562': 38.085,
    '814_563': 38.1274,
    '814_564': 38.1464,
    '814_565': 38.141,
    '814_566': 38.1242,
    '814_567': 38.0636,
    '814_568': 37.9626,
    '814_569': 37.8302,
    '814_570': 37.7027,
    '814_571': 37.5779,
    '814_572': 37.4562,
    '814_573': 37.3406,
    '814_574': 37.2349,
    '814_575': 37.1344,
    '814_576': 37.0373,
    '814_577': 36.9446,
    '814_578': 36.8555,
    '814_579': 36.7704,
    '814_580': 36.6723,
    '814_581': 36.5716,
    '814_582': 36.4771,
    '814_583': 36.3984,
    '814_584': 36.3278,
    '814_585': 36.2628,
    '814_586': 36.2031,
    '814_587': 36.1443,
    '814_588': 36.0721,
    '814_589': 35.9929,
    '814_590': 35.9136,
    '814_591': 35.8378,
    '814_592': 35.7664,
    '814_593': 35.6993,
    '814_594': 35.6369,
    '814_595': 35.5854,
    '814_596': 35.5645,
    '814_597': 35.5567,
    '814_598': 35.5658,
    '814_599': 35.586,
    '814_600': 35.6115,
    '814_601': 35.6456,
    '814_602': 35.6923,
    '814_603': 35.7551,
    '814_604': 35.8325,
    '814_605': 35.9234,
    '814_606': 36.0271,
    '814_607': 36.146,
    '814_608': 36.2744,
    '814_609': 36.4062,
    '814_610': 36.5368,
    '814_611': 36.6769,
    '814_612': 36.8384,
    '814_613': 37.0314,
    '814_614': 37.2434,
    '814_615': 37.4639,
    '814_616': 37.68,
    '814_617': 37.8877,
    '814_618': 38.0821,
    '814_619': 38.2769,
    '814_620': 38.4587,
    '814_621': 38.6331,
    '814_622': 38.7997,
    '814_623': 38.9453,
    '814_624': 39.0677,
    '814_625': 39.1674,
    '814_626': 39.2487,
    '814_627': 39.3103,
    '814_628': 39.3678,
    '814_629': 39.4129,
    '814_630': 39.4271,
    '814_631': 39.4169,
    '814_632': 39.3996,
    '814_633': 39.3487,
    '814_634': 39.271,
    '814_635': 39.165,
    '814_636': 39.0309,
    '814_637': 38.8587,
    '814_638': 38.655,
    '814_639': 38.4149,
    '814_640': 38.1485,
    '814_641': 37.8617,
    '814_642': 37.5579,
    '814_643': 37.2532,
    '814_644': 36.9699,
    '814_645': 36.7044,
    '814_646': 36.4594,
    '814_647': 36.2368,
    '814_648': 36.0395,
    '814_649': 35.8472,
    '814_650': 35.6706,
    '814_651': 35.5151,
    '814_652': 35.3867,
    '814_653': 35.2796,
    '814_654': 35.1888,
    '814_655': 35.1115,
    '814_656': 35.0452,
    '814_657': 35.0032,
    '814_658': 34.9645,
    '814_659': 34.9363,
    '814_660': 34.9257,
    '815_373': 30.8327,
    '815_374': 30.8553,
    '815_375': 30.8811,
    '815_376': 30.9203,
    '815_377': 30.9608,
    '815_378': 31.0012,
    '815_379': 31.0423,
    '815_380': 31.0842,
    '815_381': 31.1268,
    '815_382': 31.1685,
    '815_383': 31.2087,
    '815_384': 31.2479,
    '815_385': 31.2854,
    '815_386': 31.3214,
    '815_387': 31.3509,
    '815_388': 31.3869,
    '815_389': 31.4344,
    '815_390': 31.4813,
    '815_391': 31.5242,
    '815_392': 31.5656,
    '815_393': 31.6107,
    '815_394': 31.6559,
    '815_395': 31.6971,
    '815_396': 31.738,
    '815_397': 31.7753,
    '815_398': 31.819,
    '815_399': 31.8675,
    '815_400': 31.92,
    '815_401': 31.9786,
    '815_402': 32.0346,
    '815_403': 32.0904,
    '815_404': 32.1466,
    '815_405': 32.1967,
    '815_406': 32.2485,
    '815_407': 32.2961,
    '815_408': 32.3362,
    '815_409': 32.3725,
    '815_410': 32.4064,
    '815_411': 32.4354,
    '815_412': 32.4529,
    '815_413': 32.4633,
    '815_414': 32.4762,
    '815_415': 32.4913,
    '815_416': 32.5101,
    '815_417': 32.5375,
    '815_418': 32.5713,
    '815_419': 32.613,
    '815_420': 32.6571,
    '815_421': 32.7178,
    '815_422': 32.7944,
    '815_423': 32.8441,
    '815_424': 32.8327,
    '815_425': 32.8017,
    '815_426': 32.7763,
    '815_427': 32.7607,
    '815_428': 32.7514,
    '815_429': 32.7534,
    '815_430': 32.7683,
    '815_431': 32.7926,
    '815_432': 32.8074,
    '815_433': 32.8226,
    '815_434': 32.8502,
    '815_435': 32.9014,
    '815_436': 32.9506,
    '815_437': 32.9891,
    '815_438': 32.9992,
    '815_439': 33.0052,
    '815_440': 32.997,
    '815_441': 32.9817,
    '815_442': 32.943,
    '815_443': 32.8883,
    '815_444': 32.8276,
    '815_445': 32.7684,
    '815_446': 32.7199,
    '815_447': 32.672,
    '815_448': 32.6232,
    '815_449': 32.575,
    '815_450': 32.5298,
    '815_451': 32.4811,
    '815_452': 32.4311,
    '815_453': 32.3861,
    '815_454': 32.3456,
    '815_455': 32.3095,
    '815_456': 32.2792,
    '815_457': 32.2554,
    '815_458': 32.2373,
    '815_459': 32.2256,
    '815_460': 32.2171,
    '815_461': 32.2115,
    '815_462': 32.2054,
    '815_463': 32.1892,
    '815_464': 32.1451,
    '815_465': 32.0672,
    '815_466': 31.975,
    '815_467': 31.8807,
    '815_468': 31.7925,
    '815_469': 31.7103,
    '815_470': 31.6332,
    '815_471': 31.5648,
    '815_472': 31.5052,
    '815_473': 31.459,
    '815_474': 31.4054,
    '815_475': 31.3468,
    '815_476': 31.2889,
    '815_477': 31.2354,
    '815_478': 31.1892,
    '815_479': 31.1574,
    '815_480': 31.1379,
    '815_481': 31.1306,
    '815_482': 31.1323,
    '815_483': 31.1411,
    '815_484': 31.1596,
    '815_485': 31.1886,
    '815_486': 31.228,
    '815_487': 31.2759,
    '815_488': 31.335,
    '815_489': 31.4048,
    '815_490': 31.4845,
    '815_491': 31.5738,
    '815_492': 31.6764,
    '815_493': 31.8001,
    '815_494': 31.938,
    '815_495': 32.0846,
    '815_496': 32.2425,
    '815_497': 32.4141,
    '815_498': 32.5954,
    '815_499': 32.7594,
    '815_500': 32.8898,
    '815_501': 33.0169,
    '815_502': 33.1739,
    '815_503': 33.3326,
    '815_504': 33.4652,
    '815_505': 33.5982,
    '815_506': 33.7243,
    '815_507': 33.8669,
    '815_508': 34.0265,
    '815_509': 34.1924,
    '815_510': 34.37,
    '815_511': 34.5536,
    '815_512': 34.7508,
    '815_513': 34.9521,
    '815_514': 35.1565,
    '815_515': 35.3521,
    '815_516': 35.5337,
    '815_517': 35.6901,
    '815_518': 35.8056,
    '815_519': 35.8921,
    '815_520': 35.9723,
    '815_521': 36.083,
    '815_522': 36.174,
    '815_523': 36.2338,
    '815_524': 36.258,
    '815_525': 36.2652,
    '815_526': 36.2611,
    '815_527': 36.2712,
    '815_528': 36.3078,
    '815_529': 36.3427,
    '815_530': 36.3627,
    '815_531': 36.3781,
    '815_532': 36.3875,
    '815_533': 36.4257,
    '815_534': 36.4595,
    '815_535': 36.4889,
    '815_536': 36.5375,
    '815_537': 36.5609,
    '815_538': 36.563,
    '815_539': 36.5659,
    '815_540': 36.587,
    '815_541': 36.607,
    '815_542': 36.6286,
    '815_543': 36.6534,
    '815_544': 36.6835,
    '815_545': 36.7166,
    '815_546': 36.7605,
    '815_547': 36.8156,
    '815_548': 36.8851,
    '815_549': 36.9682,
    '815_550': 37.0515,
    '815_551': 37.1483,
    '815_552': 37.2598,
    '815_553': 37.3848,
    '815_554': 37.4993,
    '815_555': 37.5933,
    '815_556': 37.6693,
    '815_557': 37.7528,
    '815_558': 37.8535,
    '815_559': 37.9433,
    '815_560': 38.0466,
    '815_561': 38.1311,
    '815_562': 38.178,
    '815_563': 38.207,
    '815_564': 38.2193,
    '815_565': 38.217,
    '815_566': 38.1974,
    '815_567': 38.133,
    '815_568': 38.0261,
    '815_569': 37.8985,
    '815_570': 37.7738,
    '815_571': 37.6493,
    '815_572': 37.5309,
    '815_573': 37.4095,
    '815_574': 37.2992,
    '815_575': 37.1927,
    '815_576': 37.0935,
    '815_577': 36.9968,
    '815_578': 36.9029,
    '815_579': 36.8108,
    '815_580': 36.7142,
    '815_581': 36.6176,
    '815_582': 36.5276,
    '815_583': 36.4495,
    '815_584': 36.379,
    '815_585': 36.3149,
    '815_586': 36.2578,
    '815_587': 36.1997,
    '815_588': 36.1343,
    '815_589': 36.058,
    '815_590': 35.9804,
    '815_591': 35.9079,
    '815_592': 35.8399,
    '815_593': 35.7754,
    '815_594': 35.7109,
    '815_595': 35.6572,
    '815_596': 35.6389,
    '815_597': 35.6373,
    '815_598': 35.6543,
    '815_599': 35.6816,
    '815_600': 35.7124,
    '815_601': 35.7503,
    '815_602': 35.8002,
    '815_603': 35.8676,
    '815_604': 35.9497,
    '815_605': 36.0445,
    '815_606': 36.1518,
    '815_607': 36.2745,
    '815_608': 36.4067,
    '815_609': 36.5426,
    '815_610': 36.6776,
    '815_611': 36.8222,
    '815_612': 36.986,
    '815_613': 37.1741,
    '815_614': 37.3803,
    '815_615': 37.5971,
    '815_616': 37.8146,
    '815_617': 38.0229,
    '815_618': 38.2112,
    '815_619': 38.4001,
    '815_620': 38.5881,
    '815_621': 38.7647,
    '815_622': 38.926,
    '815_623': 39.0672,
    '815_624': 39.184,
    '815_625': 39.2755,
    '815_626': 39.3561,
    '815_627': 39.4184,
    '815_628': 39.4712,
    '815_629': 39.5191,
    '815_630': 39.5306,
    '815_631': 39.5197,
    '815_632': 39.4986,
    '815_633': 39.4418,
    '815_634': 39.3614,
    '815_635': 39.2525,
    '815_636': 39.1144,
    '815_637': 38.947,
    '815_638': 38.7438,
    '815_639': 38.506,
    '815_640': 38.2407,
    '815_641': 37.9585,
    '815_642': 37.6592,
    '815_643': 37.345,
    '815_644': 37.0571,
    '815_645': 36.7855,
    '815_646': 36.5267,
    '815_647': 36.2956,
    '815_648': 36.0915,
    '815_649': 35.8947,
    '815_650': 35.7101,
    '815_651': 35.5456,
    '815_652': 35.4045,
    '815_653': 35.288,
    '815_654': 35.1925,
    '815_655': 35.1117,
    '815_656': 35.0395,
    '815_657': 34.9938,
    '815_658': 34.9572,
    '815_659': 34.9258,
    '815_660': 34.904,
    '816_373': 30.8252,
    '816_374': 30.8522,
    '816_375': 30.8738,
    '816_376': 30.9027,
    '816_377': 30.9412,
    '816_378': 30.9831,
    '816_379': 31.0254,
    '816_380': 31.0681,
    '816_381': 31.113,
    '816_382': 31.1543,
    '816_383': 31.1903,
    '816_384': 31.2267,
    '816_385': 31.2623,
    '816_386': 31.2974,
    '816_387': 31.3293,
    '816_388': 31.3582,
    '816_389': 31.4015,
    '816_390': 31.4448,
    '816_391': 31.4889,
    '816_392': 31.5336,
    '816_393': 31.5805,
    '816_394': 31.627,
    '816_395': 31.6682,
    '816_396': 31.7094,
    '816_397': 31.7463,
    '816_398': 31.7904,
    '816_399': 31.8386,
    '816_400': 31.8898,
    '816_401': 31.943,
    '816_402': 31.9969,
    '816_403': 32.0537,
    '816_404': 32.1062,
    '816_405': 32.1574,
    '816_406': 32.2097,
    '816_407': 32.2623,
    '816_408': 32.3075,
    '816_409': 32.3441,
    '816_410': 32.3754,
    '816_411': 32.4062,
    '816_412': 32.4274,
    '816_413': 32.4408,
    '816_414': 32.4558,
    '816_415': 32.4737,
    '816_416': 32.4952,
    '816_417': 32.5263,
    '816_418': 32.5629,
    '816_419': 32.6044,
    '816_420': 32.6579,
    '816_421': 32.7322,
    '816_422': 32.8067,
    '816_423': 32.8409,
    '816_424': 32.8359,
    '816_425': 32.8036,
    '816_426': 32.761,
    '816_427': 32.7413,
    '816_428': 32.7348,
    '816_429': 32.7392,
    '816_430': 32.7559,
    '816_431': 32.7816,
    '816_432': 32.7867,
    '816_433': 32.7992,
    '816_434': 32.8246,
    '816_435': 32.8695,
    '816_436': 32.9135,
    '816_437': 32.9454,
    '816_438': 32.9567,
    '816_439': 32.958,
    '816_440': 32.9544,
    '816_441': 32.9362,
    '816_442': 32.9026,
    '816_443': 32.8539,
    '816_444': 32.7961,
    '816_445': 32.7437,
    '816_446': 32.6985,
    '816_447': 32.6542,
    '816_448': 32.6079,
    '816_449': 32.5602,
    '816_450': 32.515,
    '816_451': 32.4689,
    '816_452': 32.4199,
    '816_453': 32.3738,
    '816_454': 32.3316,
    '816_455': 32.2938,
    '816_456': 32.2629,
    '816_457': 32.2386,
    '816_458': 32.221,
    '816_459': 32.2103,
    '816_460': 32.2065,
    '816_461': 32.2086,
    '816_462': 32.2073,
    '816_463': 32.1862,
    '816_464': 32.1388,
    '816_465': 32.0622,
    '816_466': 31.9736,
    '816_467': 31.8832,
    '816_468': 31.7963,
    '816_469': 31.7156,
    '816_470': 31.6428,
    '816_471': 31.5756,
    '816_472': 31.5176,
    '816_473': 31.4744,
    '816_474': 31.4216,
    '816_475': 31.3646,
    '816_476': 31.3082,
    '816_477': 31.2559,
    '816_478': 31.2099,
    '816_479': 31.1818,
    '816_480': 31.1616,
    '816_481': 31.1474,
    '816_482': 31.1431,
    '816_483': 31.147,
    '816_484': 31.1618,
    '816_485': 31.1853,
    '816_486': 31.2175,
    '816_487': 31.2579,
    '816_488': 31.3101,
    '816_489': 31.3724,
    '816_490': 31.4438,
    '816_491': 31.5247,
    '816_492': 31.6187,
    '816_493': 31.7327,
    '816_494': 31.8594,
    '816_495': 31.9955,
    '816_496': 32.1463,
    '816_497': 32.311,
    '816_498': 32.4858,
    '816_499': 32.6565,
    '816_500': 32.8167,
    '816_501': 32.9812,
    '816_502': 33.1487,
    '816_503': 33.301,
    '816_504': 33.438,
    '816_505': 33.5778,
    '816_506': 33.7094,
    '816_507': 33.8497,
    '816_508': 34.0075,
    '816_509': 34.1771,
    '816_510': 34.348,
    '816_511': 34.5282,
    '816_512': 34.7155,
    '816_513': 34.9155,
    '816_514': 35.1148,
    '816_515': 35.298,
    '816_516': 35.4683,
    '816_517': 35.6152,
    '816_518': 35.7399,
    '816_519': 35.8443,
    '816_520': 35.9524,
    '816_521': 36.0746,
    '816_522': 36.1792,
    '816_523': 36.245,
    '816_524': 36.2824,
    '816_525': 36.2966,
    '816_526': 36.2875,
    '816_527': 36.3053,
    '816_528': 36.3566,
    '816_529': 36.4049,
    '816_530': 36.433,
    '816_531': 36.4469,
    '816_532': 36.4555,
    '816_533': 36.5087,
    '816_534': 36.5482,
    '816_535': 36.5768,
    '816_536': 36.6266,
    '816_537': 36.6506,
    '816_538': 36.6532,
    '816_539': 36.6624,
    '816_540': 36.696,
    '816_541': 36.725,
    '816_542': 36.7489,
    '816_543': 36.7709,
    '816_544': 36.791,
    '816_545': 36.814,
    '816_546': 36.8524,
    '816_547': 36.9057,
    '816_548': 36.9729,
    '816_549': 37.0591,
    '816_550': 37.1496,
    '816_551': 37.2431,
    '816_552': 37.3528,
    '816_553': 37.4758,
    '816_554': 37.5952,
    '816_555': 37.6913,
    '816_556': 37.7722,
    '816_557': 37.8494,
    '816_558': 37.9511,
    '816_559': 38.0348,
    '816_560': 38.1249,
    '816_561': 38.2163,
    '816_562': 38.2721,
    '816_563': 38.2987,
    '816_564': 38.2978,
    '816_565': 38.2906,
    '816_566': 38.2676,
    '816_567': 38.2005,
    '816_568': 38.0909,
    '816_569': 37.9734,
    '816_570': 37.8517,
    '816_571': 37.7195,
    '816_572': 37.5909,
    '816_573': 37.4676,
    '816_574': 37.3567,
    '816_575': 37.2509,
    '816_576': 37.1494,
    '816_577': 37.049,
    '816_578': 36.9497,
    '816_579': 36.851,
    '816_580': 36.7558,
    '816_581': 36.6623,
    '816_582': 36.5764,
    '816_583': 36.5005,
    '816_584': 36.4297,
    '816_585': 36.3669,
    '816_586': 36.3111,
    '816_587': 36.253,
    '816_588': 36.1891,
    '816_589': 36.1178,
    '816_590': 36.0439,
    '816_591': 35.975,
    '816_592': 35.9099,
    '816_593': 35.8473,
    '816_594': 35.7835,
    '816_595': 35.7304,
    '816_596': 35.7113,
    '816_597': 35.7174,
    '816_598': 35.7432,
    '816_599': 35.7783,
    '816_600': 35.8148,
    '816_601': 35.8569,
    '816_602': 35.9102,
    '816_603': 35.9817,
    '816_604': 36.0678,
    '816_605': 36.1665,
    '816_606': 36.2767,
    '816_607': 36.4022,
    '816_608': 36.5367,
    '816_609': 36.6746,
    '816_610': 36.8119,
    '816_611': 36.9573,
    '816_612': 37.1187,
    '816_613': 37.307,
    '816_614': 37.5105,
    '816_615': 37.7231,
    '816_616': 37.9403,
    '816_617': 38.1492,
    '816_618': 38.3351,
    '816_619': 38.5103,
    '816_620': 38.6968,
    '816_621': 38.8718,
    '816_622': 39.0307,
    '816_623': 39.1775,
    '816_624': 39.292,
    '816_625': 39.3842,
    '816_626': 39.4653,
    '816_627': 39.5238,
    '816_628': 39.5712,
    '816_629': 39.6181,
    '816_630': 39.6316,
    '816_631': 39.6174,
    '816_632': 39.5924,
    '816_633': 39.531,
    '816_634': 39.4478,
    '816_635': 39.3394,
    '816_636': 39.2014,
    '816_637': 39.0342,
    '816_638': 38.8312,
    '816_639': 38.5976,
    '816_640': 38.3383,
    '816_641': 38.065,
    '816_642': 37.7731,
    '816_643': 37.446,
    '816_644': 37.1498,
    '816_645': 36.8718,
    '816_646': 36.605,
    '816_647': 36.3623,
    '816_648': 36.1499,
    '816_649': 35.9471,
    '816_650': 35.7542,
    '816_651': 35.5817,
    '816_652': 35.4313,
    '816_653': 35.3044,
    '816_654': 35.2015,
    '816_655': 35.1147,
    '816_656': 35.0416,
    '816_657': 34.9917,
    '816_658': 34.9538,
    '816_659': 34.9211,
    '816_660': 34.8876,
    '817_373': 30.8112,
    '817_374': 30.8364,
    '817_375': 30.8574,
    '817_376': 30.8816,
    '817_377': 30.9228,
    '817_378': 30.967,
    '817_379': 31.0104,
    '817_380': 31.0532,
    '817_381': 31.0979,
    '817_382': 31.1391,
    '817_383': 31.1738,
    '817_384': 31.2068,
    '817_385': 31.2397,
    '817_386': 31.2728,
    '817_387': 31.3036,
    '817_388': 31.3328,
    '817_389': 31.3688,
    '817_390': 31.4086,
    '817_391': 31.4528,
    '817_392': 31.4991,
    '817_393': 31.5511,
    '817_394': 31.5977,
    '817_395': 31.6403,
    '817_396': 31.6819,
    '817_397': 31.7199,
    '817_398': 31.7645,
    '817_399': 31.8125,
    '817_400': 31.8628,
    '817_401': 31.9144,
    '817_402': 31.9669,
    '817_403': 32.0227,
    '817_404': 32.0744,
    '817_405': 32.1261,
    '817_406': 32.1794,
    '817_407': 32.2336,
    '817_408': 32.2813,
    '817_409': 32.3193,
    '817_410': 32.3502,
    '817_411': 32.3829,
    '817_412': 32.4117,
    '817_413': 32.4265,
    '817_414': 32.4437,
    '817_415': 32.4644,
    '817_416': 32.4891,
    '817_417': 32.5223,
    '817_418': 32.562,
    '817_419': 32.607,
    '817_420': 32.6661,
    '817_421': 32.7374,
    '817_422': 32.808,
    '817_423': 32.8459,
    '817_424': 32.8518,
    '817_425': 32.8217,
    '817_426': 32.7562,
    '817_427': 32.727,
    '817_428': 32.72,
    '817_429': 32.726,
    '817_430': 32.7456,
    '817_431': 32.7674,
    '817_432': 32.7721,
    '817_433': 32.7844,
    '817_434': 32.808,
    '817_435': 32.8491,
    '817_436': 32.8843,
    '817_437': 32.9011,
    '817_438': 32.9111,
    '817_439': 32.9145,
    '817_440': 32.911,
    '817_441': 32.8937,
    '817_442': 32.8608,
    '817_443': 32.8165,
    '817_444': 32.7671,
    '817_445': 32.7219,
    '817_446': 32.6801,
    '817_447': 32.6388,
    '817_448': 32.5943,
    '817_449': 32.5479,
    '817_450': 32.5021,
    '817_451': 32.4559,
    '817_452': 32.4079,
    '817_453': 32.3616,
    '817_454': 32.3181,
    '817_455': 32.2784,
    '817_456': 32.2459,
    '817_457': 32.2217,
    '817_458': 32.2049,
    '817_459': 32.1959,
    '817_460': 32.1936,
    '817_461': 32.1943,
    '817_462': 32.1871,
    '817_463': 32.1662,
    '817_464': 32.1165,
    '817_465': 32.0448,
    '817_466': 31.9639,
    '817_467': 31.8812,
    '817_468': 31.7975,
    '817_469': 31.7217,
    '817_470': 31.6531,
    '817_471': 31.5884,
    '817_472': 31.5308,
    '817_473': 31.4897,
    '817_474': 31.4377,
    '817_475': 31.3824,
    '817_476': 31.3278,
    '817_477': 31.2785,
    '817_478': 31.2413,
    '817_479': 31.2134,
    '817_480': 31.1897,
    '817_481': 31.1736,
    '817_482': 31.1655,
    '817_483': 31.1637,
    '817_484': 31.1755,
    '817_485': 31.1937,
    '817_486': 31.2196,
    '817_487': 31.2536,
    '817_488': 31.2978,
    '817_489': 31.3519,
    '817_490': 31.4155,
    '817_491': 31.4887,
    '817_492': 31.5743,
    '817_493': 31.6786,
    '817_494': 31.7947,
    '817_495': 31.9201,
    '817_496': 32.058,
    '817_497': 32.2115,
    '817_498': 32.3765,
    '817_499': 32.546,
    '817_500': 32.72,
    '817_501': 32.9022,
    '817_502': 33.0782,
    '817_503': 33.2369,
    '817_504': 33.3877,
    '817_505': 33.5329,
    '817_506': 33.6746,
    '817_507': 33.817,
    '817_508': 33.9735,
    '817_509': 34.1513,
    '817_510': 34.3276,
    '817_511': 34.504,
    '817_512': 34.6828,
    '817_513': 34.8812,
    '817_514': 35.0761,
    '817_515': 35.2499,
    '817_516': 35.4056,
    '817_517': 35.5433,
    '817_518': 35.671,
    '817_519': 35.7903,
    '817_520': 35.9173,
    '817_521': 36.0467,
    '817_522': 36.1584,
    '817_523': 36.2385,
    '817_524': 36.2894,
    '817_525': 36.3133,
    '817_526': 36.3243,
    '817_527': 36.3441,
    '817_528': 36.3955,
    '817_529': 36.452,
    '817_530': 36.4834,
    '817_531': 36.4986,
    '817_532': 36.5157,
    '817_533': 36.574,
    '817_534': 36.6216,
    '817_535': 36.6601,
    '817_536': 36.7129,
    '817_537': 36.7416,
    '817_538': 36.7524,
    '817_539': 36.7661,
    '817_540': 36.7993,
    '817_541': 36.8361,
    '817_542': 36.8621,
    '817_543': 36.886,
    '817_544': 36.9002,
    '817_545': 36.9239,
    '817_546': 36.9565,
    '817_547': 37.0091,
    '817_548': 37.0743,
    '817_549': 37.1501,
    '817_550': 37.2431,
    '817_551': 37.3378,
    '817_552': 37.4408,
    '817_553': 37.5595,
    '817_554': 37.6809,
    '817_555': 37.7823,
    '817_556': 37.8688,
    '817_557': 37.944,
    '817_558': 38.0435,
    '817_559': 38.1296,
    '817_560': 38.2076,
    '817_561': 38.3013,
    '817_562': 38.3623,
    '817_563': 38.3868,
    '817_564': 38.3767,
    '817_565': 38.3627,
    '817_566': 38.3356,
    '817_567': 38.267,
    '817_568': 38.159,
    '817_569': 38.0509,
    '817_570': 37.9344,
    '817_571': 37.7973,
    '817_572': 37.6589,
    '817_573': 37.5316,
    '817_574': 37.4229,
    '817_575': 37.3145,
    '817_576': 37.2079,
    '817_577': 37.1054,
    '817_578': 37.0036,
    '817_579': 36.9033,
    '817_580': 36.8063,
    '817_581': 36.7125,
    '817_582': 36.6301,
    '817_583': 36.555,
    '817_584': 36.4812,
    '817_585': 36.4184,
    '817_586': 36.364,
    '817_587': 36.3065,
    '817_588': 36.2436,
    '817_589': 36.177,
    '817_590': 36.1076,
    '817_591': 36.041,
    '817_592': 35.9781,
    '817_593': 35.9172,
    '817_594': 35.8554,
    '817_595': 35.8048,
    '817_596': 35.7886,
    '817_597': 35.8005,
    '817_598': 35.8348,
    '817_599': 35.8786,
    '817_600': 35.9197,
    '817_601': 35.9657,
    '817_602': 36.0231,
    '817_603': 36.0978,
    '817_604': 36.1871,
    '817_605': 36.2895,
    '817_606': 36.4034,
    '817_607': 36.5295,
    '817_608': 36.6643,
    '817_609': 36.8021,
    '817_610': 36.9418,
    '817_611': 37.0893,
    '817_612': 37.2511,
    '817_613': 37.4368,
    '817_614': 37.6358,
    '817_615': 37.841,
    '817_616': 38.0535,
    '817_617': 38.2653,
    '817_618': 38.4532,
    '817_619': 38.6169,
    '817_620': 38.7942,
    '817_621': 38.9633,
    '817_622': 39.1259,
    '817_623': 39.2736,
    '817_624': 39.3967,
    '817_625': 39.4999,
    '817_626': 39.5803,
    '817_627': 39.6317,
    '817_628': 39.6735,
    '817_629': 39.7144,
    '817_630': 39.7307,
    '817_631': 39.7173,
    '817_632': 39.6865,
    '817_633': 39.6248,
    '817_634': 39.5396,
    '817_635': 39.4333,
    '817_636': 39.2938,
    '817_637': 39.1233,
    '817_638': 38.9223,
    '817_639': 38.6889,
    '817_640': 38.4355,
    '817_641': 38.1649,
    '817_642': 37.8744,
    '817_643': 37.5492,
    '817_644': 37.2494,
    '817_645': 36.9647,
    '817_646': 36.6896,
    '817_647': 36.4338,
    '817_648': 36.2019,
    '817_649': 36.0014,
    '817_650': 35.8023,
    '817_651': 35.6214,
    '817_652': 35.4634,
    '817_653': 35.33,
    '817_654': 35.2203,
    '817_655': 35.1285,
    '817_656': 35.053,
    '817_657': 34.9985,
    '817_658': 34.9555,
    '817_659': 34.9171,
    '817_660': 34.8854,
    '818_373': 30.795,
    '818_374': 30.8213,
    '818_375': 30.8458,
    '818_376': 30.8717,
    '818_377': 30.9131,
    '818_378': 30.9567,
    '818_379': 30.9987,
    '818_380': 31.0402,
    '818_381': 31.0814,
    '818_382': 31.1228,
    '818_383': 31.1595,
    '818_384': 31.1886,
    '818_385': 31.2195,
    '818_386': 31.2513,
    '818_387': 31.2812,
    '818_388': 31.3101,
    '818_389': 31.3445,
    '818_390': 31.3848,
    '818_391': 31.4267,
    '818_392': 31.472,
    '818_393': 31.5236,
    '818_394': 31.5698,
    '818_395': 31.6131,
    '818_396': 31.6523,
    '818_397': 31.6951,
    '818_398': 31.7403,
    '818_399': 31.7884,
    '818_400': 31.8385,
    '818_401': 31.8896,
    '818_402': 31.9412,
    '818_403': 31.9934,
    '818_404': 32.045,
    '818_405': 32.0988,
    '818_406': 32.1506,
    '818_407': 32.2019,
    '818_408': 32.2528,
    '818_409': 32.294,
    '818_410': 32.3278,
    '818_411': 32.362,
    '818_412': 32.3936,
    '818_413': 32.4134,
    '818_414': 32.4352,
    '818_415': 32.4606,
    '818_416': 32.4892,
    '818_417': 32.5243,
    '818_418': 32.5657,
    '818_419': 32.6156,
    '818_420': 32.6769,
    '818_421': 32.7508,
    '818_422': 32.8245,
    '818_423': 32.8647,
    '818_424': 32.858,
    '818_425': 32.8162,
    '818_426': 32.7507,
    '818_427': 32.7173,
    '818_428': 32.7083,
    '818_429': 32.7149,
    '818_430': 32.737,
    '818_431': 32.7478,
    '818_432': 32.7586,
    '818_433': 32.7736,
    '818_434': 32.7979,
    '818_435': 32.8348,
    '818_436': 32.8623,
    '818_437': 32.8634,
    '818_438': 32.8658,
    '818_439': 32.8724,
    '818_440': 32.8693,
    '818_441': 32.8528,
    '818_442': 32.8228,
    '818_443': 32.7835,
    '818_444': 32.7425,
    '818_445': 32.7025,
    '818_446': 32.663,
    '818_447': 32.623,
    '818_448': 32.5803,
    '818_449': 32.5352,
    '818_450': 32.49,
    '818_451': 32.4439,
    '818_452': 32.3967,
    '818_453': 32.3501,
    '818_454': 32.3057,
    '818_455': 32.2632,
    '818_456': 32.2282,
    '818_457': 32.2039,
    '818_458': 32.1875,
    '818_459': 32.1785,
    '818_460': 32.1758,
    '818_461': 32.1731,
    '818_462': 32.1625,
    '818_463': 32.1381,
    '818_464': 32.0898,
    '818_465': 32.0263,
    '818_466': 31.9522,
    '818_467': 31.875,
    '818_468': 31.7989,
    '818_469': 31.7301,
    '818_470': 31.6658,
    '818_471': 31.6062,
    '818_472': 31.5523,
    '818_473': 31.5033,
    '818_474': 31.4523,
    '818_475': 31.3995,
    '818_476': 31.3454,
    '818_477': 31.2945,
    '818_478': 31.2636,
    '818_479': 31.2389,
    '818_480': 31.2185,
    '818_481': 31.2042,
    '818_482': 31.1963,
    '818_483': 31.1949,
    '818_484': 31.2026,
    '818_485': 31.2135,
    '818_486': 31.2324,
    '818_487': 31.2602,
    '818_488': 31.2967,
    '818_489': 31.3426,
    '818_490': 31.3986,
    '818_491': 31.465,
    '818_492': 31.5431,
    '818_493': 31.6376,
    '818_494': 31.7436,
    '818_495': 31.859,
    '818_496': 31.9856,
    '818_497': 32.1241,
    '818_498': 32.2741,
    '818_499': 32.4349,
    '818_500': 32.6026,
    '818_501': 32.7803,
    '818_502': 32.9607,
    '818_503': 33.1328,
    '818_504': 33.3001,
    '818_505': 33.4651,
    '818_506': 33.622,
    '818_507': 33.7713,
    '818_508': 33.9283,
    '818_509': 34.1095,
    '818_510': 34.2991,
    '818_511': 34.481,
    '818_512': 34.6567,
    '818_513': 34.8549,
    '818_514': 35.0469,
    '818_515': 35.2147,
    '818_516': 35.3606,
    '818_517': 35.4964,
    '818_518': 35.6276,
    '818_519': 35.7532,
    '818_520': 35.8793,
    '818_521': 36.0125,
    '818_522': 36.132,
    '818_523': 36.2232,
    '818_524': 36.2837,
    '818_525': 36.3263,
    '818_526': 36.357,
    '818_527': 36.3955,
    '818_528': 36.4425,
    '818_529': 36.5013,
    '818_530': 36.5355,
    '818_531': 36.551,
    '818_532': 36.5779,
    '818_533': 36.6245,
    '818_534': 36.6794,
    '818_535': 36.7332,
    '818_536': 36.7908,
    '818_537': 36.8277,
    '818_538': 36.8501,
    '818_539': 36.8769,
    '818_540': 36.9094,
    '818_541': 36.9414,
    '818_542': 36.9753,
    '818_543': 37.0045,
    '818_544': 37.0197,
    '818_545': 37.0405,
    '818_546': 37.0773,
    '818_547': 37.1285,
    '818_548': 37.1889,
    '818_549': 37.2566,
    '818_550': 37.3391,
    '818_551': 37.4255,
    '818_552': 37.5238,
    '818_553': 37.6352,
    '818_554': 37.7524,
    '818_555': 37.8548,
    '818_556': 37.9515,
    '818_557': 38.0428,
    '818_558': 38.1399,
    '818_559': 38.2239,
    '818_560': 38.2958,
    '818_561': 38.3799,
    '818_562': 38.4437,
    '818_563': 38.4668,
    '818_564': 38.4534,
    '818_565': 38.4369,
    '818_566': 38.4029,
    '818_567': 38.3336,
    '818_568': 38.2267,
    '818_569': 38.1152,
    '818_570': 37.9963,
    '818_571': 37.8611,
    '818_572': 37.7223,
    '818_573': 37.5997,
    '818_574': 37.492,
    '818_575': 37.3798,
    '818_576': 37.2691,
    '818_577': 37.164,
    '818_578': 37.0611,
    '818_579': 36.9611,
    '818_580': 36.8652,
    '818_581': 36.771,
    '818_582': 36.6866,
    '818_583': 36.6092,
    '818_584': 36.5365,
    '818_585': 36.4721,
    '818_586': 36.415,
    '818_587': 36.3577,
    '818_588': 36.294,
    '818_589': 36.229,
    '818_590': 36.1648,
    '818_591': 36.1024,
    '818_592': 36.0427,
    '818_593': 35.9847,
    '818_594': 35.9243,
    '818_595': 35.8757,
    '818_596': 35.8626,
    '818_597': 35.8817,
    '818_598': 35.9239,
    '818_599': 35.9753,
    '818_600': 36.0213,
    '818_601': 36.0722,
    '818_602': 36.1342,
    '818_603': 36.2112,
    '818_604': 36.3025,
    '818_605': 36.4074,
    '818_606': 36.5232,
    '818_607': 36.6506,
    '818_608': 36.7867,
    '818_609': 36.925,
    '818_610': 37.0667,
    '818_611': 37.2171,
    '818_612': 37.3808,
    '818_613': 37.561,
    '818_614': 37.7523,
    '818_615': 37.9495,
    '818_616': 38.1542,
    '818_617': 38.3641,
    '818_618': 38.5567,
    '818_619': 38.7161,
    '818_620': 38.8864,
    '818_621': 39.0541,
    '818_622': 39.2185,
    '818_623': 39.3625,
    '818_624': 39.4918,
    '818_625': 39.6047,
    '818_626': 39.6929,
    '818_627': 39.7457,
    '818_628': 39.777,
    '818_629': 39.8076,
    '818_630': 39.8267,
    '818_631': 39.8144,
    '818_632': 39.7826,
    '818_633': 39.7214,
    '818_634': 39.6345,
    '818_635': 39.5305,
    '818_636': 39.3907,
    '818_637': 39.2161,
    '818_638': 39.015,
    '818_639': 38.7842,
    '818_640': 38.5304,
    '818_641': 38.256,
    '818_642': 37.9627,
    '818_643': 37.6528,
    '818_644': 37.3556,
    '818_645': 37.0668,
    '818_646': 36.7832,
    '818_647': 36.5136,
    '818_648': 36.2705,
    '818_649': 36.0545,
    '818_650': 35.852,
    '818_651': 35.6629,
    '818_652': 35.4946,
    '818_653': 35.3611,
    '818_654': 35.2459,
    '818_655': 35.1496,
    '818_656': 35.0702,
    '818_657': 35.0112,
    '818_658': 34.963,
    '818_659': 34.9154,
    '818_660': 34.8779,
    '819_400': 31.8151,
    '819_401': 31.8664,
    '819_402': 31.9176,
    '819_403': 31.9687,
    '819_404': 32.0201,
    '819_405': 32.0731,
    '819_406': 32.1236,
    '819_407': 32.1735,
    '819_408': 32.2235,
    '819_409': 32.272,
    '819_410': 32.3061,
    '819_411': 32.341,
    '819_412': 32.3732,
    '819_413': 32.3986,
    '819_414': 32.4263,
    '819_415': 32.4571,
    '819_416': 32.4894,
    '819_417': 32.5261,
    '819_418': 32.5718,
    '819_419': 32.6318,
    '819_420': 32.6914,
    '819_421': 32.7742,
    '819_422': 32.8443,
    '819_423': 32.8756,
    '819_424': 32.8571,
    '819_425': 32.8094,
    '819_426': 32.747,
    '819_427': 32.7127,
    '819_428': 32.7019,
    '819_429': 32.7051,
    '819_430': 32.7241,
    '819_431': 32.7332,
    '819_432': 32.7486,
    '819_433': 32.7694,
    '819_434': 32.7971,
    '819_435': 32.8336,
    '819_436': 32.8446,
    '819_437': 32.8331,
    '819_438': 32.8295,
    '819_439': 32.835,
    '819_440': 32.8316,
    '819_441': 32.8157,
    '819_442': 32.79,
    '819_443': 32.7569,
    '819_444': 32.7213,
    '819_445': 32.6844,
    '819_446': 32.6459,
    '819_447': 32.6068,
    '819_448': 32.5657,
    '819_449': 32.5229,
    '819_450': 32.4788,
    '819_451': 32.4333,
    '819_452': 32.3863,
    '819_453': 32.3392,
    '819_454': 32.2944,
    '819_455': 32.2516,
    '819_456': 32.2143,
    '819_457': 32.1869,
    '819_458': 32.1692,
    '819_459': 32.1591,
    '819_460': 32.1545,
    '819_461': 32.1486,
    '819_462': 32.1323,
    '819_463': 32.1053,
    '819_464': 32.0594,
    '819_465': 32.0033,
    '819_466': 31.937,
    '819_467': 31.8672,
    '819_468': 31.7995,
    '819_469': 31.7369,
    '819_470': 31.6773,
    '819_471': 31.6221,
    '819_472': 31.5689,
    '819_473': 31.5168,
    '819_474': 31.4673,
    '819_475': 31.4168,
    '819_476': 31.3614,
    '819_477': 31.311,
    '819_478': 31.2834,
    '819_479': 31.2638,
    '819_480': 31.2486,
    '819_481': 31.2377,
    '819_482': 31.2314,
    '819_483': 31.2303,
    '819_484': 31.2346,
    '819_485': 31.2424,
    '819_486': 31.2541,
    '819_487': 31.2754,
    '819_488': 31.3048,
    '819_489': 31.3427,
    '819_490': 31.3904,
    '819_491': 31.4504,
    '819_492': 31.521,
    '819_493': 31.6061,
    '819_494': 31.7026,
    '819_495': 31.8085,
    '819_496': 31.9246,
    '819_497': 32.0498,
    '819_498': 32.1848,
    '819_499': 32.3338,
    '819_500': 32.4896,
    '819_501': 32.6541,
    '819_502': 32.8248,
    '819_503': 32.9977,
    '819_504': 33.1812,
    '819_505': 33.3757,
    '819_506': 33.5528,
    '819_507': 33.7133,
    '819_508': 33.8802,
    '819_509': 34.0662,
    '819_510': 34.2564,
    '819_511': 34.4445,
    '819_512': 34.6233,
    '819_513': 34.8257,
    '819_514': 35.0189,
    '819_515': 35.1825,
    '819_516': 35.3235,
    '819_517': 35.4624,
    '819_518': 35.5971,
    '819_519': 35.7243,
    '819_520': 35.8507,
    '819_521': 35.9823,
    '819_522': 36.1051,
    '819_523': 36.2078,
    '819_524': 36.2911,
    '819_525': 36.3547,
    '819_526': 36.4018,
    '819_527': 36.4555,
    '819_528': 36.4988,
    '819_529': 36.5539,
    '819_530': 36.5892,
    '819_531': 36.613,
    '819_532': 36.6484,
    '819_533': 36.6856,
    '819_534': 36.7324,
    '819_535': 36.7956,
    '819_536': 36.8603,
    '819_537': 36.9061,
    '819_538': 36.9415,
    '819_539': 36.9804,
    '819_540': 37.0193,
    '819_541': 37.0552,
    '819_542': 37.0928,
    '819_543': 37.1241,
    '819_544': 37.1427,
    '819_545': 37.1656,
    '819_546': 37.2047,
    '819_547': 37.2498,
    '819_548': 37.3103,
    '819_549': 37.3861,
    '819_550': 37.4533,
    '819_551': 37.5151,
    '819_552': 37.6007,
    '819_553': 37.7037,
    '819_554': 37.8127,
    '819_555': 37.9117,
    '819_556': 38.02,
    '819_557': 38.127,
    '819_558': 38.2297,
    '819_559': 38.3149,
    '819_560': 38.3805,
    '819_561': 38.4526,
    '819_562': 38.5144,
    '819_563': 38.5381,
    '819_564': 38.5274,
    '819_565': 38.508,
    '819_566': 38.4684,
    '819_567': 38.3967,
    '819_568': 38.2888,
    '819_569': 38.1644,
    '819_570': 38.0409,
    '819_571': 37.9088,
    '819_572': 37.7816,
    '819_573': 37.6682,
    '819_574': 37.5633,
    '819_575': 37.4494,
    '819_576': 37.3333,
    '819_577': 37.2252,
    '819_578': 37.1218,
    '819_579': 37.0205,
    '819_580': 36.9225,
    '819_581': 36.8286,
    '819_582': 36.7412,
    '819_583': 36.6614,
    '819_584': 36.5875,
    '819_585': 36.5206,
    '819_586': 36.4602,
    '819_587': 36.4012,
    '819_588': 36.3376,
    '819_589': 36.2745,
    '819_590': 36.214,
    '819_591': 36.1571,
    '819_592': 36.1011,
    '819_593': 36.0464,
    '819_594': 35.988,
    '819_595': 35.9426,
    '819_596': 35.9353,
    '819_597': 35.9598,
    '819_598': 36.0068,
    '819_599': 36.0626,
    '819_600': 36.1143,
    '819_601': 36.1705,
    '819_602': 36.2369,
    '819_603': 36.3164,
    '819_604': 36.4099,
    '819_605': 36.5164,
    '819_606': 36.6328,
    '819_607': 36.7601,
    '819_608': 36.896,
    '819_609': 37.0364,
    '819_610': 37.1811,
    '819_611': 37.3343,
    '819_612': 37.4988,
    '819_613': 37.6764,
    '819_614': 37.863,
    '819_615': 38.0506,
    '819_616': 38.2435,
    '819_617': 38.4488,
    '819_618': 38.6448,
    '819_619': 38.8138,
    '819_620': 38.9751,
    '819_621': 39.1373,
    '819_622': 39.2985,
    '819_623': 39.4422,
    '819_624': 39.5769,
    '819_625': 39.701,
    '819_626': 39.7985,
    '819_627': 39.8537,
    '819_628': 39.881,
    '819_629': 39.9045,
    '819_630': 39.9215,
    '819_631': 39.9047,
    '819_632': 39.8708,
    '819_633': 39.8154,
    '819_634': 39.7339,
    '819_635': 39.6348,
    '819_636': 39.495,
    '819_637': 39.3139,
    '819_638': 39.1161,
    '819_639': 38.8865,
    '819_640': 38.63,
    '819_641': 38.3497,
    '819_642': 38.056,
    '819_643': 37.7547,
    '819_644': 37.468,
    '819_645': 37.1788,
    '819_646': 36.8892,
    '819_647': 36.6108,
    '819_648': 36.3538,
    '819_649': 36.1199,
    '819_650': 35.9147,
    '819_651': 35.7177,
    '819_652': 35.5291,
    '819_653': 35.3974,
    '819_654': 35.276,
    '819_655': 35.1731,
    '819_656': 35.092,
    '819_657': 35.0261,
    '819_658': 34.9716,
    '819_659': 34.9192,
    '819_660': 34.8797,
    '820_383': 31.3654,
    '820_384': 31.3832,
    '820_385': 31.4006,
    '820_386': 31.4194,
    '820_387': 31.4379,
    '820_388': 31.4528,
    '820_389': 31.469,
    '820_390': 31.4898,
    '820_391': 31.5135,
    '820_392': 31.5399,
    '820_393': 31.5691,
    '820_394': 31.5989,
    '820_400': 31.7878,
    '820_401': 31.8404,
    '820_402': 31.892,
    '820_403': 31.9434,
    '820_404': 31.998,
    '820_405': 32.0476,
    '820_406': 32.0971,
    '820_407': 32.1479,
    '820_408': 32.1969,
    '820_409': 32.2439,
    '820_410': 32.2831,
    '820_411': 32.3212,
    '820_412': 32.3591,
    '820_413': 32.3866,
    '820_414': 32.4185,
    '820_415': 32.4536,
    '820_416': 32.4885,
    '820_417': 32.5271,
    '820_418': 32.5765,
    '820_419': 32.6378,
    '820_420': 32.7012,
    '820_421': 32.7868,
    '820_422': 32.853,
    '820_423': 32.869,
    '820_424': 32.845,
    '820_425': 32.8047,
    '820_426': 32.75,
    '820_427': 32.7132,
    '820_428': 32.6963,
    '820_429': 32.6973,
    '820_430': 32.7119,
    '820_431': 32.7257,
    '820_432': 32.7468,
    '820_433': 32.7771,
    '820_434': 32.8086,
    '820_435': 32.8365,
    '820_436': 32.8343,
    '820_437': 32.8146,
    '820_438': 32.8044,
    '820_439': 32.8037,
    '820_440': 32.8005,
    '820_441': 32.7878,
    '820_442': 32.7653,
    '820_443': 32.7363,
    '820_444': 32.7031,
    '820_445': 32.6683,
    '820_446': 32.6317,
    '820_447': 32.5937,
    '820_448': 32.5539,
    '820_449': 32.5129,
    '820_450': 32.4703,
    '820_451': 32.426,
    '820_452': 32.3797,
    '820_453': 32.3325,
    '820_454': 32.2872,
    '820_455': 32.2441,
    '820_456': 32.2056,
    '820_457': 32.1744,
    '820_458': 32.1523,
    '820_459': 32.1402,
    '820_460': 32.1328,
    '820_461': 32.1208,
    '820_462': 32.1026,
    '820_463': 32.0718,
    '820_464': 32.032,
    '820_465': 31.9818,
    '820_466': 31.9228,
    '820_467': 31.8611,
    '820_468': 31.8017,
    '820_469': 31.7438,
    '820_470': 31.6889,
    '820_471': 31.6383,
    '820_472': 31.5867,
    '820_473': 31.5323,
    '820_474': 31.4866,
    '820_475': 31.4385,
    '820_476': 31.3846,
    '820_477': 31.3381,
    '820_478': 31.3122,
    '820_479': 31.2958,
    '820_480': 31.2841,
    '820_481': 31.2761,
    '820_482': 31.2715,
    '820_483': 31.2705,
    '820_484': 31.273,
    '820_485': 31.2783,
    '820_486': 31.287,
    '820_487': 31.3014,
    '820_488': 31.3231,
    '820_489': 31.3527,
    '820_490': 31.392,
    '820_491': 31.4423,
    '820_492': 31.507,
    '820_493': 31.5828,
    '820_494': 31.6706,
    '820_495': 31.7681,
    '820_496': 31.875,
    '820_497': 31.9897,
    '820_498': 32.1125,
    '820_499': 32.2457,
    '820_500': 32.3906,
    '820_501': 32.5406,
    '820_502': 32.6985,
    '820_503': 32.864,
    '820_504': 33.0463,
    '820_505': 33.2507,
    '820_506': 33.4477,
    '820_507': 33.6266,
    '820_508': 33.8129,
    '820_509': 34.0101,
    '820_510': 34.2014,
    '820_511': 34.3899,
    '820_512': 34.5751,
    '820_513': 34.7852,
    '820_514': 34.9812,
    '820_515': 35.1452,
    '820_516': 35.2876,
    '820_517': 35.4303,
    '820_518': 35.5664,
    '820_519': 35.6966,
    '820_520': 35.8281,
    '820_521': 35.9606,
    '820_522': 36.0872,
    '820_523': 36.2017,
    '820_524': 36.3044,
    '820_525': 36.3891,
    '820_526': 36.4531,
    '820_527': 36.5111,
    '820_528': 36.5624,
    '820_529': 36.6146,
    '820_530': 36.6532,
    '820_531': 36.6813,
    '820_532': 36.7144,
    '820_533': 36.7478,
    '820_534': 36.7995,
    '820_535': 36.8656,
    '820_536': 36.9277,
    '820_537': 36.9761,
    '820_538': 37.0174,
    '820_539': 37.0607,
    '820_540': 37.1069,
    '820_541': 37.1533,
    '820_542': 37.1954,
    '820_543': 37.2279,
    '820_544': 37.2519,
    '820_545': 37.2803,
    '820_546': 37.3186,
    '820_547': 37.365,
    '820_548': 37.432,
    '820_549': 37.513,
    '820_550': 37.5823,
    '820_551': 37.6344,
    '820_552': 37.7029,
    '820_553': 37.7759,
    '820_554': 37.8692,
    '820_555': 37.9639,
    '820_556': 38.075,
    '820_557': 38.1891,
    '820_558': 38.3021,
    '820_559': 38.3943,
    '820_560': 38.4656,
    '820_561': 38.5276,
    '820_562': 38.5831,
    '820_563': 38.6059,
    '820_564': 38.5999,
    '820_565': 38.5783,
    '820_566': 38.5346,
    '820_567': 38.4578,
    '820_568': 38.3442,
    '820_569': 38.2187,
    '820_570': 38.0987,
    '820_571': 37.9689,
    '820_572': 37.8462,
    '820_573': 37.735,
    '820_574': 37.631,
    '820_575': 37.5176,
    '820_576': 37.4025,
    '820_577': 37.2914,
    '820_578': 37.1883,
    '820_579': 37.0838,
    '820_580': 36.9819,
    '820_581': 36.8846,
    '820_582': 36.794,
    '820_583': 36.7109,
    '820_584': 36.6346,
    '820_585': 36.5645,
    '820_586': 36.5006,
    '820_587': 36.4391,
    '820_588': 36.3769,
    '820_589': 36.3169,
    '820_590': 36.2606,
    '820_591': 36.2101,
    '820_592': 36.1546,
    '820_593': 36.1007,
    '820_594': 36.0483,
    '820_595': 36.0084,
    '820_596': 36.009,
    '820_597': 36.0376,
    '820_598': 36.0868,
    '820_599': 36.1448,
    '820_600': 36.2009,
    '820_601': 36.2617,
    '820_602': 36.3315,
    '820_603': 36.4132,
    '820_604': 36.5101,
    '820_605': 36.6183,
    '820_606': 36.7343,
    '820_607': 36.8613,
    '820_608': 36.9971,
    '820_609': 37.1394,
    '820_610': 37.2864,
    '820_611': 37.4415,
    '820_612': 37.6067,
    '820_613': 37.7811,
    '820_614': 37.9654,
    '820_615': 38.1464,
    '820_616': 38.3291,
    '820_617': 38.5247,
    '820_618': 38.7243,
    '820_619': 38.9061,
    '820_620': 39.0709,
    '820_621': 39.2207,
    '820_622': 39.3758,
    '820_623': 39.5197,
    '820_624': 39.6624,
    '820_625': 39.7882,
    '820_626': 39.8926,
    '820_627': 39.9487,
    '820_628': 39.9793,
    '820_629': 39.9984,
    '820_630': 40.0091,
    '820_631': 39.989,
    '820_632': 39.958,
    '820_633': 39.9145,
    '820_634': 39.8393,
    '820_635': 39.7431,
    '820_636': 39.602,
    '820_637': 39.4269,
    '820_638': 39.2214,
    '820_639': 38.9903,
    '820_640': 38.7323,
    '820_641': 38.4525,
    '820_642': 38.1626,
    '820_643': 37.8698,
    '820_644': 37.5933,
    '820_645': 37.3025,
    '820_646': 37.0058,
    '820_647': 36.7177,
    '820_648': 36.4539,
    '820_649': 36.2117,
    '820_650': 35.9928,
    '820_651': 35.7905,
    '820_652': 35.594,
    '820_653': 35.4517,
    '820_654': 35.3232,
    '820_655': 35.2129,
    '820_656': 35.1215,
    '820_657': 35.0436,
    '820_658': 34.9889,
    '820_659': 34.9319,
    '820_660': 34.8896,
    '821_383': 31.3602,
    '821_384': 31.3779,
    '821_385': 31.3958,
    '821_386': 31.4146,
    '821_387': 31.4336,
    '821_388': 31.4502,
    '821_389': 31.4663,
    '821_390': 31.4835,
    '821_391': 31.5036,
    '821_392': 31.5267,
    '821_393': 31.5551,
    '821_394': 31.5839,
    '821_400': 31.766,
    '821_401': 31.8189,
    '821_402': 31.8716,
    '821_403': 31.9236,
    '821_404': 31.9777,
    '821_405': 32.0248,
    '821_406': 32.0724,
    '821_407': 32.1221,
    '821_408': 32.1706,
    '821_409': 32.218,
    '821_410': 32.2598,
    '821_411': 32.3004,
    '821_412': 32.3401,
    '821_413': 32.3723,
    '821_414': 32.4065,
    '821_415': 32.4427,
    '821_416': 32.4799,
    '821_417': 32.5199,
    '821_418': 32.5699,
    '821_419': 32.6312,
    '821_420': 32.6959,
    '821_421': 32.7785,
    '821_422': 32.8366,
    '821_423': 32.84,
    '821_424': 32.8157,
    '821_425': 32.787,
    '821_426': 32.7532,
    '821_427': 32.713,
    '821_428': 32.6923,
    '821_429': 32.6912,
    '821_430': 32.7052,
    '821_431': 32.7251,
    '821_432': 32.7585,
    '821_433': 32.8023,
    '821_434': 32.8263,
    '821_435': 32.8356,
    '821_436': 32.8171,
    '821_437': 32.7971,
    '821_438': 32.786,
    '821_439': 32.7807,
    '821_440': 32.7771,
    '821_441': 32.7671,
    '821_442': 32.7466,
    '821_443': 32.7197,
    '821_444': 32.6882,
    '821_445': 32.6548,
    '821_446': 32.6195,
    '821_447': 32.5824,
    '821_448': 32.5438,
    '821_449': 32.5042,
    '821_450': 32.4633,
    '821_451': 32.4208,
    '821_452': 32.3755,
    '821_453': 32.3284,
    '821_454': 32.2824,
    '821_455': 32.2388,
    '821_456': 32.1989,
    '821_457': 32.164,
    '821_458': 32.1362,
    '821_459': 32.1211,
    '821_460': 32.1097,
    '821_461': 32.0955,
    '821_462': 32.0737,
    '821_463': 32.0434,
    '821_464': 32.0079,
    '821_465': 31.9627,
    '821_466': 31.9117,
    '821_467': 31.8579,
    '821_468': 31.8043,
    '821_469': 31.7513,
    '821_470': 31.7018,
    '821_471': 31.6527,
    '821_472': 31.6024,
    '821_473': 31.5528,
    '821_474': 31.5096,
    '821_475': 31.4611,
    '821_476': 31.4122,
    '821_477': 31.373,
    '821_478': 31.3493,
    '821_479': 31.335,
    '821_480': 31.3254,
    '821_481': 31.3194,
    '821_482': 31.3163,
    '821_483': 31.3156,
    '821_484': 31.3175,
    '821_485': 31.3217,
    '821_486': 31.3283,
    '821_487': 31.3377,
    '821_488': 31.3515,
    '821_489': 31.372,
    '821_490': 31.4032,
    '821_491': 31.4453,
    '821_492': 31.4992,
    '821_493': 31.565,
    '821_494': 31.6454,
    '821_495': 31.7357,
    '821_496': 31.8347,
    '821_497': 31.9409,
    '821_498': 32.0543,
    '821_499': 32.1756,
    '821_500': 32.305,
    '821_501': 32.442,
    '821_502': 32.5872,
    '821_503': 32.7417,
    '821_504': 32.9145,
    '821_505': 33.1093,
    '821_506': 33.3165,
    '821_507': 33.5215,
    '821_508': 33.7215,
    '821_509': 33.9289,
    '821_510': 34.1307,
    '821_511': 34.3206,
    '821_512': 34.5079,
    '821_513': 34.7212,
    '821_514': 34.923,
    '821_515': 35.0976,
    '821_516': 35.2543,
    '821_517': 35.401,
    '821_518': 35.5399,
    '821_519': 35.6733,
    '821_520': 35.8108,
    '821_521': 35.9432,
    '821_522': 36.0737,
    '821_523': 36.1984,
    '821_524': 36.3153,
    '821_525': 36.4168,
    '821_526': 36.5003,
    '821_527': 36.563,
    '821_528': 36.617,
    '821_529': 36.6694,
    '821_530': 36.7127,
    '821_531': 36.7478,
    '821_532': 36.7802,
    '821_533': 36.8119,
    '821_534': 36.8588,
    '821_535': 36.93,
    '821_536': 36.9938,
    '821_537': 37.0381,
    '821_538': 37.072,
    '821_539': 37.1106,
    '821_540': 37.1606,
    '821_541': 37.214,
    '821_542': 37.2665,
    '821_543': 37.311,
    '821_544': 37.3472,
    '821_545': 37.3799,
    '821_546': 37.4142,
    '821_547': 37.4629,
    '821_548': 37.534,
    '821_549': 37.6261,
    '821_550': 37.7025,
    '821_551': 37.7588,
    '821_552': 37.8225,
    '821_553': 37.874,
    '821_554': 37.9305,
    '821_555': 38.0138,
    '821_556': 38.1213,
    '821_557': 38.2341,
    '821_558': 38.3552,
    '821_559': 38.4563,
    '821_560': 38.5375,
    '821_561': 38.5997,
    '821_562': 38.6481,
    '821_563': 38.6714,
    '821_564': 38.6647,
    '821_565': 38.6379,
    '821_566': 38.5878,
    '821_567': 38.5109,
    '821_568': 38.4004,
    '821_569': 38.2753,
    '821_570': 38.1569,
    '821_571': 38.0317,
    '821_572': 37.9061,
    '821_573': 37.7973,
    '821_574': 37.6981,
    '821_575': 37.5911,
    '821_576': 37.4751,
    '821_577': 37.3608,
    '821_578': 37.2593,
    '821_579': 37.1545,
    '821_580': 37.04,
    '821_581': 36.9378,
    '821_582': 36.843,
    '821_583': 36.7565,
    '821_584': 36.6773,
    '821_585': 36.6044,
    '821_586': 36.5377,
    '821_587': 36.4742,
    '821_588': 36.4143,
    '821_589': 36.3583,
    '821_590': 36.3083,
    '821_591': 36.2648,
    '821_592': 36.2089,
    '821_593': 36.1585,
    '821_594': 36.114,
    '821_595': 36.0837,
    '821_596': 36.0883,
    '821_597': 36.1165,
    '821_598': 36.1648,
    '821_599': 36.2234,
    '821_600': 36.2824,
    '821_601': 36.347,
    '821_602': 36.4207,
    '821_603': 36.506,
    '821_604': 36.6049,
    '821_605': 36.7138,
    '821_606': 36.8277,
    '821_607': 36.9536,
    '821_608': 37.0895,
    '821_609': 37.233,
    '821_610': 37.3824,
    '821_611': 37.5391,
    '821_612': 37.7041,
    '821_613': 37.8757,
    '821_614': 38.0536,
    '821_615': 38.2306,
    '821_616': 38.4113,
    '821_617': 38.5972,
    '821_618': 38.7896,
    '821_619': 38.9783,
    '821_620': 39.1557,
    '821_621': 39.3087,
    '821_622': 39.4516,
    '821_623': 39.597,
    '821_624': 39.7433,
    '821_625': 39.8694,
    '821_626': 39.9756,
    '821_627': 40.0399,
    '821_628': 40.0732,
    '821_629': 40.0873,
    '821_630': 40.0913,
    '821_631': 40.0703,
    '821_632': 40.0414,
    '821_633': 40.0062,
    '821_634': 39.9458,
    '821_635': 39.8499,
    '821_636': 39.7103,
    '821_637': 39.5321,
    '821_638': 39.3242,
    '821_639': 39.0912,
    '821_640': 38.8365,
    '821_641': 38.5621,
    '821_642': 38.2781,
    '821_643': 37.9904,
    '821_644': 37.7133,
    '821_645': 37.4304,
    '821_646': 37.1325,
    '821_647': 36.8372,
    '821_648': 36.5681,
    '821_649': 36.3188,
    '821_650': 36.09,
    '821_651': 35.8821,
    '821_652': 35.6886,
    '821_653': 35.5214,
    '821_654': 35.3836,
    '821_655': 35.2661,
    '821_656': 35.1658,
    '821_657': 35.0789,
    '821_658': 35.0097,
    '821_659': 34.9534,
    '821_660': 34.9077,
    '822_383': 31.3529,
    '822_384': 31.3722,
    '822_385': 31.3912,
    '822_386': 31.4107,
    '822_387': 31.434,
    '822_388': 31.453,
    '822_389': 31.469,
    '822_390': 31.4795,
    '822_391': 31.4969,
    '822_392': 31.5181,
    '822_393': 31.543,
    '822_394': 31.5697,
    '822_400': 31.7455,
    '822_401': 31.7985,
    '822_402': 31.8515,
    '822_403': 31.9031,
    '822_404': 31.9567,
    '822_405': 32.0009,
    '822_406': 32.0467,
    '822_407': 32.0945,
    '822_408': 32.1439,
    '822_409': 32.1917,
    '822_410': 32.2366,
    '822_411': 32.2794,
    '822_412': 32.3196,
    '822_413': 32.3556,
    '822_414': 32.392,
    '822_415': 32.4269,
    '822_416': 32.4635,
    '822_417': 32.504,
    '822_418': 32.5527,
    '822_419': 32.6093,
    '822_420': 32.673,
    '822_421': 32.7537,
    '822_422': 32.8077,
    '822_423': 32.8043,
    '822_424': 32.7705,
    '822_425': 32.755,
    '822_426': 32.735,
    '822_427': 32.7093,
    '822_428': 32.6908,
    '822_429': 32.6904,
    '822_430': 32.7034,
    '822_431': 32.7311,
    '822_432': 32.7818,
    '822_433': 32.8354,
    '822_434': 32.8426,
    '822_435': 32.8372,
    '822_436': 32.8087,
    '822_437': 32.7856,
    '822_438': 32.7705,
    '822_439': 32.7636,
    '822_440': 32.7602,
    '822_441': 32.7529,
    '822_442': 32.7327,
    '822_443': 32.7059,
    '822_444': 32.6759,
    '822_445': 32.6433,
    '822_446': 32.6089,
    '822_447': 32.5726,
    '822_448': 32.535,
    '822_449': 32.4963,
    '822_450': 32.4567,
    '822_451': 32.4159,
    '822_452': 32.3707,
    '822_453': 32.3245,
    '822_454': 32.2789,
    '822_455': 32.2354,
    '822_456': 32.195,
    '822_457': 32.1588,
    '822_458': 32.1291,
    '822_459': 32.1077,
    '822_460': 32.0896,
    '822_461': 32.0712,
    '822_462': 32.0486,
    '822_463': 32.0213,
    '822_464': 31.989,
    '822_465': 31.9506,
    '822_466': 31.9066,
    '822_467': 31.8596,
    '822_468': 31.8091,
    '822_469': 31.7589,
    '822_470': 31.7148,
    '822_471': 31.666,
    '822_472': 31.6164,
    '822_473': 31.5691,
    '822_474': 31.5281,
    '822_475': 31.4842,
    '822_476': 31.4432,
    '822_477': 31.4121,
    '822_478': 31.3922,
    '822_479': 31.3798,
    '822_480': 31.3721,
    '822_481': 31.3677,
    '822_482': 31.3656,
    '822_483': 31.3656,
    '822_484': 31.3674,
    '822_485': 31.3717,
    '822_486': 31.3772,
    '822_487': 31.3823,
    '822_488': 31.3896,
    '822_489': 31.4021,
    '822_490': 31.4227,
    '822_491': 31.4553,
    '822_492': 31.496,
    '822_493': 31.5529,
    '822_494': 31.6267,
    '822_495': 31.7106,
    '822_496': 31.8026,
    '822_497': 31.9016,
    '822_498': 32.0075,
    '822_499': 32.1194,
    '822_500': 32.2353,
    '822_501': 32.3563,
    '822_502': 32.488,
    '822_503': 32.6345,
    '822_504': 32.7954,
    '822_505': 32.9814,
    '822_506': 33.1848,
    '822_507': 33.4042,
    '822_508': 33.6164,
    '822_509': 33.8291,
    '822_510': 34.0391,
    '822_511': 34.2308,
    '822_512': 34.4146,
    '822_513': 34.6295,
    '822_514': 34.8402,
    '822_515': 35.0375,
    '822_516': 35.2145,
    '822_517': 35.3736,
    '822_518': 35.5203,
    '822_519': 35.6599,
    '822_520': 35.7995,
    '822_521': 35.9311,
    '822_522': 36.067,
    '822_523': 36.199,
    '822_524': 36.3245,
    '822_525': 36.4389,
    '822_526': 36.5319,
    '822_527': 36.6062,
    '822_528': 36.6606,
    '822_529': 36.7113,
    '822_530': 36.7572,
    '822_531': 36.7983,
    '822_532': 36.8385,
    '822_533': 36.8771,
    '822_534': 36.9284,
    '822_535': 36.9974,
    '822_536': 37.0539,
    '822_537': 37.0898,
    '822_538': 37.114,
    '822_539': 37.1402,
    '822_540': 37.1854,
    '822_541': 37.2434,
    '822_542': 37.3072,
    '822_543': 37.3655,
    '822_544': 37.4153,
    '822_545': 37.4597,
    '822_546': 37.5005,
    '822_547': 37.5487,
    '822_548': 37.623,
    '822_549': 37.7232,
    '822_550': 37.8091,
    '822_551': 37.8765,
    '822_552': 37.9422,
    '822_553': 37.9852,
    '822_554': 38.0135,
    '822_555': 38.0739,
    '822_556': 38.169,
    '822_557': 38.2789,
    '822_558': 38.3962,
    '822_559': 38.5095,
    '822_560': 38.5961,
    '822_561': 38.6622,
    '822_562': 38.7087,
    '822_563': 38.7258,
    '822_564': 38.7177,
    '822_565': 38.6818,
    '822_566': 38.6278,
    '822_567': 38.5531,
    '822_568': 38.4547,
    '822_569': 38.3419,
    '822_570': 38.2282,
    '822_571': 38.1031,
    '822_572': 37.9669,
    '822_573': 37.8615,
    '822_574': 37.7713,
    '822_575': 37.6762,
    '822_576': 37.5595,
    '822_577': 37.4338,
    '822_578': 37.3201,
    '822_579': 37.2143,
    '822_580': 37.1013,
    '822_581': 36.9888,
    '822_582': 36.8877,
    '822_583': 36.7982,
    '822_584': 36.7168,
    '822_585': 36.6422,
    '822_586': 36.5746,
    '822_587': 36.5114,
    '822_588': 36.4519,
    '822_589': 36.3976,
    '822_590': 36.3496,
    '822_591': 36.3111,
    '822_592': 36.2688,
    '822_593': 36.221,
    '822_594': 36.1821,
    '822_595': 36.1581,
    '822_596': 36.1645,
    '822_597': 36.1935,
    '822_598': 36.2404,
    '822_599': 36.2976,
    '822_600': 36.3594,
    '822_601': 36.4277,
    '822_602': 36.5048,
    '822_603': 36.5925,
    '822_604': 36.6935,
    '822_605': 36.8013,
    '822_606': 36.9148,
    '822_607': 37.0395,
    '822_608': 37.1752,
    '822_609': 37.3198,
    '822_610': 37.4716,
    '822_611': 37.6301,
    '822_612': 37.7949,
    '822_613': 37.9641,
    '822_614': 38.1369,
    '822_615': 38.3116,
    '822_616': 38.4907,
    '822_617': 38.671,
    '822_618': 38.8557,
    '822_619': 39.0466,
    '822_620': 39.2325,
    '822_621': 39.3921,
    '822_622': 39.5356,
    '822_623': 39.6782,
    '822_624': 39.8239,
    '822_625': 39.9486,
    '822_626': 40.0507,
    '822_627': 40.1205,
    '822_628': 40.1597,
    '822_629': 40.1721,
    '822_630': 40.1675,
    '822_631': 40.1436,
    '822_632': 40.109,
    '822_633': 40.0744,
    '822_634': 40.0264,
    '822_635': 39.9351,
    '822_636': 39.7944,
    '822_637': 39.6146,
    '822_638': 39.4207,
    '822_639': 39.1966,
    '822_640': 38.9436,
    '822_641': 38.6775,
    '822_642': 38.4019,
    '822_643': 38.1206,
    '822_644': 37.8423,
    '822_645': 37.5669,
    '822_646': 37.273,
    '822_647': 36.9742,
    '822_648': 36.6976,
    '822_649': 36.4394,
    '822_650': 36.2003,
    '822_651': 35.9859,
    '822_652': 35.7898,
    '822_653': 35.6164,
    '822_654': 35.4634,
    '822_655': 35.3355,
    '822_656': 35.2269,
    '822_657': 35.1362,
    '822_658': 35.0578,
    '822_659': 34.994,
    '822_660': 34.9404,
    '823_383': 31.3433,
    '823_384': 31.368,
    '823_385': 31.389,
    '823_386': 31.4096,
    '823_387': 31.4314,
    '823_388': 31.4548,
    '823_389': 31.4711,
    '823_390': 31.4771,
    '823_391': 31.4925,
    '823_392': 31.5112,
    '823_393': 31.5322,
    '823_394': 31.5562,
    '823_400': 31.7246,
    '823_401': 31.7774,
    '823_402': 31.8301,
    '823_403': 31.8785,
    '823_404': 31.9275,
    '823_405': 31.9723,
    '823_406': 32.0189,
    '823_407': 32.067,
    '823_408': 32.1167,
    '823_409': 32.1664,
    '823_410': 32.2146,
    '823_411': 32.2598,
    '823_412': 32.3012,
    '823_413': 32.3389,
    '823_414': 32.375,
    '823_415': 32.409,
    '823_416': 32.4442,
    '823_417': 32.483,
    '823_418': 32.5299,
    '823_419': 32.5801,
    '823_420': 32.6372,
    '823_421': 32.7082,
    '823_422': 32.7665,
    '823_423': 32.7666,
    '823_424': 32.7299,
    '823_425': 32.7166,
    '823_426': 32.7084,
    '823_427': 32.6953,
    '823_428': 32.688,
    '823_429': 32.6902,
    '823_430': 32.7056,
    '823_431': 32.746,
    '823_432': 32.8151,
    '823_433': 32.8654,
    '823_434': 32.8592,
    '823_435': 32.8471,
    '823_436': 32.8146,
    '823_437': 32.781,
    '823_438': 32.7587,
    '823_439': 32.749,
    '823_440': 32.7467,
    '823_441': 32.7405,
    '823_442': 32.7204,
    '823_443': 32.6938,
    '823_444': 32.6641,
    '823_445': 32.6324,
    '823_446': 32.5988,
    '823_447': 32.5635,
    '823_448': 32.5266,
    '823_449': 32.4882,
    '823_450': 32.4484,
    '823_451': 32.4074,
    '823_452': 32.3644,
    '823_453': 32.3201,
    '823_454': 32.2758,
    '823_455': 32.2329,
    '823_456': 32.1924,
    '823_457': 32.1554,
    '823_458': 32.1228,
    '823_459': 32.095,
    '823_460': 32.0727,
    '823_461': 32.0522,
    '823_462': 32.0302,
    '823_463': 32.0059,
    '823_464': 31.9775,
    '823_465': 31.945,
    '823_466': 31.9076,
    '823_467': 31.8669,
    '823_468': 31.8184,
    '823_469': 31.77,
    '823_470': 31.7286,
    '823_471': 31.681,
    '823_472': 31.6315,
    '823_473': 31.5859,
    '823_474': 31.5461,
    '823_475': 31.5081,
    '823_476': 31.4749,
    '823_477': 31.4527,
    '823_478': 31.4382,
    '823_479': 31.4289,
    '823_480': 31.4228,
    '823_481': 31.4195,
    '823_482': 31.4186,
    '823_483': 31.419,
    '823_484': 31.4197,
    '823_485': 31.4261,
    '823_486': 31.4329,
    '823_487': 31.4332,
    '823_488': 31.4349,
    '823_489': 31.4411,
    '823_490': 31.4536,
    '823_491': 31.4744,
    '823_492': 31.504,
    '823_493': 31.5483,
    '823_494': 31.6114,
    '823_495': 31.6906,
    '823_496': 31.7765,
    '823_497': 31.8697,
    '823_498': 31.9699,
    '823_499': 32.0759,
    '823_500': 32.1813,
    '823_501': 32.2899,
    '823_502': 32.4102,
    '823_503': 32.5503,
    '823_504': 32.7032,
    '823_505': 32.8757,
    '823_506': 33.0674,
    '823_507': 33.278,
    '823_508': 33.4926,
    '823_509': 33.7101,
    '823_510': 33.9247,
    '823_511': 34.1195,
    '823_512': 34.3072,
    '823_513': 34.5218,
    '823_514': 34.7388,
    '823_515': 34.9584,
    '823_516': 35.1557,
    '823_517': 35.3362,
    '823_518': 35.4996,
    '823_519': 35.648,
    '823_520': 35.7862,
    '823_521': 35.9185,
    '823_522': 36.0593,
    '823_523': 36.1973,
    '823_524': 36.3302,
    '823_525': 36.4454,
    '823_526': 36.5514,
    '823_527': 36.6295,
    '823_528': 36.6864,
    '823_529': 36.7362,
    '823_530': 36.7844,
    '823_531': 36.8326,
    '823_532': 36.8798,
    '823_533': 36.9294,
    '823_534': 36.9907,
    '823_535': 37.0564,
    '823_536': 37.1056,
    '823_537': 37.1315,
    '823_538': 37.153,
    '823_539': 37.1714,
    '823_540': 37.2045,
    '823_541': 37.2524,
    '823_542': 37.3194,
    '823_543': 37.3916,
    '823_544': 37.4533,
    '823_545': 37.5105,
    '823_546': 37.5632,
    '823_547': 37.6175,
    '823_548': 37.6978,
    '823_549': 37.8013,
    '823_550': 37.8954,
    '823_551': 37.9748,
    '823_552': 38.0429,
    '823_553': 38.0895,
    '823_554': 38.1155,
    '823_555': 38.1574,
    '823_556': 38.2347,
    '823_557': 38.3377,
    '823_558': 38.4449,
    '823_559': 38.5521,
    '823_560': 38.6434,
    '823_561': 38.7075,
    '823_562': 38.7512,
    '823_563': 38.7653,
    '823_564': 38.7505,
    '823_565': 38.7076,
    '823_566': 38.6495,
    '823_567': 38.5812,
    '823_568': 38.4969,
    '823_569': 38.3984,
    '823_570': 38.2893,
    '823_571': 38.1677,
    '823_572': 38.0362,
    '823_573': 37.93,
    '823_574': 37.8361,
    '823_575': 37.7438,
    '823_576': 37.6394,
    '823_577': 37.5134,
    '823_578': 37.3847,
    '823_579': 37.265,
    '823_580': 37.1486,
    '823_581': 37.0323,
    '823_582': 36.9272,
    '823_583': 36.8358,
    '823_584': 36.7529,
    '823_585': 36.6776,
    '823_586': 36.61,
    '823_587': 36.5473,
    '823_588': 36.4891,
    '823_589': 36.4366,
    '823_590': 36.3916,
    '823_591': 36.356,
    '823_592': 36.3224,
    '823_593': 36.2821,
    '823_594': 36.249,
    '823_595': 36.2321,
    '823_596': 36.2437,
    '823_597': 36.2714,
    '823_598': 36.3155,
    '823_599': 36.3707,
    '823_600': 36.4342,
    '823_601': 36.5054,
    '823_602': 36.5849,
    '823_603': 36.6747,
    '823_604': 36.7779,
    '823_605': 36.8838,
    '823_606': 36.9958,
    '823_607': 37.1194,
    '823_608': 37.2551,
    '823_609': 37.4019,
    '823_610': 37.5567,
    '823_611': 37.7172,
    '823_612': 37.8818,
    '823_613': 38.0498,
    '823_614': 38.2203,
    '823_615': 38.3926,
    '823_616': 38.5691,
    '823_617': 38.7498,
    '823_618': 38.9282,
    '823_619': 39.1102,
    '823_620': 39.2961,
    '823_621': 39.4622,
    '823_622': 39.616,
    '823_623': 39.7525,
    '823_624': 39.8968,
    '823_625': 40.019,
    '823_626': 40.1187,
    '823_627': 40.1884,
    '823_628': 40.2285,
    '823_629': 40.241,
    '823_630': 40.2287,
    '823_631': 40.1976,
    '823_632': 40.1628,
    '823_633': 40.1231,
    '823_634': 40.0822,
    '823_635': 39.9961,
    '823_636': 39.8516,
    '823_637': 39.6771,
    '823_638': 39.4938,
    '823_639': 39.2804,
    '823_640': 39.0442,
    '823_641': 38.795,
    '823_642': 38.5286,
    '823_643': 38.2553,
    '823_644': 37.9812,
    '823_645': 37.7081,
    '823_646': 37.4228,
    '823_647': 37.1206,
    '823_648': 36.8395,
    '823_649': 36.5743,
    '823_650': 36.3268,
    '823_651': 36.1051,
    '823_652': 35.9034,
    '823_653': 35.7209,
    '823_654': 35.5594,
    '823_655': 35.4212,
    '823_656': 35.3042,
    '823_657': 35.2063,
    '823_658': 35.1197,
    '823_659': 35.044,
    '823_660': 34.9892,
    '824_383': 31.331,
    '824_384': 31.3582,
    '824_385': 31.3812,
    '824_386': 31.4033,
    '824_387': 31.4265,
    '824_388': 31.4539,
    '824_389': 31.472,
    '824_390': 31.4792,
    '824_391': 31.4929,
    '824_392': 31.5084,
    '824_393': 31.5219,
    '824_394': 31.5432,
    '824_400': 31.7047,
    '824_401': 31.7559,
    '824_402': 31.807,
    '824_403': 31.8541,
    '824_404': 31.8986,
    '824_405': 31.9446,
    '824_406': 31.9917,
    '824_407': 32.0403,
    '824_408': 32.0915,
    '824_409': 32.1436,
    '824_410': 32.1947,
    '824_411': 32.2421,
    '824_412': 32.2839,
    '824_413': 32.3213,
    '824_414': 32.3567,
    '824_415': 32.3915,
    '824_416': 32.425,
    '824_417': 32.462,
    '824_418': 32.5045,
    '824_419': 32.5507,
    '824_420': 32.6007,
    '824_421': 32.6596,
    '824_422': 32.7168,
    '824_423': 32.7239,
    '824_424': 32.696,
    '824_425': 32.6811,
    '824_426': 32.6803,
    '824_427': 32.6818,
    '824_428': 32.6847,
    '824_429': 32.6883,
    '824_430': 32.7091,
    '824_431': 32.7672,
    '824_432': 32.8481,
    '824_433': 32.8816,
    '824_434': 32.8712,
    '824_435': 32.8645,
    '824_436': 32.8308,
    '824_437': 32.7828,
    '824_438': 32.7547,
    '824_439': 32.74,
    '824_440': 32.7344,
    '824_441': 32.7274,
    '824_442': 32.7072,
    '824_443': 32.6814,
    '824_444': 32.6525,
    '824_445': 32.6216,
    '824_446': 32.5893,
    '824_447': 32.5553,
    '824_448': 32.5195,
    '824_449': 32.4817,
    '824_450': 32.442,
    '824_451': 32.4018,
    '824_452': 32.36,
    '824_453': 32.317,
    '824_454': 32.2738,
    '824_455': 32.2316,
    '824_456': 32.1912,
    '824_457': 32.1538,
    '824_458': 32.12,
    '824_459': 32.0897,
    '824_460': 32.0631,
    '824_461': 32.0399,
    '824_462': 32.0178,
    '824_463': 31.9968,
    '824_464': 31.9723,
    '824_465': 31.9443,
    '824_466': 31.9123,
    '824_467': 31.878,
    '824_468': 31.8327,
    '824_469': 31.7886,
    '824_470': 31.7436,
    '824_471': 31.6946,
    '824_472': 31.6467,
    '824_473': 31.605,
    '824_474': 31.5686,
    '824_475': 31.5367,
    '824_476': 31.5122,
    '824_477': 31.4972,
    '824_478': 31.4882,
    '824_479': 31.4821,
    '824_480': 31.4757,
    '824_481': 31.4729,
    '824_482': 31.474,
    '824_483': 31.4745,
    '824_484': 31.475,
    '824_485': 31.4806,
    '824_486': 31.4879,
    '824_487': 31.4859,
    '824_488': 31.4837,
    '824_489': 31.4854,
    '824_490': 31.4916,
    '824_491': 31.5027,
    '824_492': 31.5221,
    '824_493': 31.5563,
    '824_494': 31.6062,
    '824_495': 31.6736,
    '824_496': 31.7556,
    '824_497': 31.8436,
    '824_498': 31.9373,
    '824_499': 32.0397,
    '824_500': 32.1369,
    '824_501': 32.2384,
    '824_502': 32.3514,
    '824_503': 32.4797,
    '824_504': 32.6252,
    '824_505': 32.7887,
    '824_506': 32.967,
    '824_507': 33.1634,
    '824_508': 33.3759,
    '824_509': 33.5955,
    '824_510': 33.805,
    '824_511': 33.9987,
    '824_512': 34.1906,
    '824_513': 34.4031,
    '824_514': 34.6255,
    '824_515': 34.859,
    '824_516': 35.0749,
    '824_517': 35.2711,
    '824_518': 35.4513,
    '824_519': 35.6103,
    '824_520': 35.7574,
    '824_521': 35.9008,
    '824_522': 36.0484,
    '824_523': 36.1881,
    '824_524': 36.3275,
    '824_525': 36.451,
    '824_526': 36.555,
    '824_527': 36.6328,
    '824_528': 36.6963,
    '824_529': 36.7498,
    '824_530': 36.8023,
    '824_531': 36.8552,
    '824_532': 36.905,
    '824_533': 36.9602,
    '824_534': 37.0311,
    '824_535': 37.0958,
    '824_536': 37.1434,
    '824_537': 37.1649,
    '824_538': 37.1842,
    '824_539': 37.2059,
    '824_540': 37.2328,
    '824_541': 37.2641,
    '824_542': 37.3194,
    '824_543': 37.3941,
    '824_544': 37.4617,
    '824_545': 37.5304,
    '824_546': 37.5947,
    '824_547': 37.661,
    '824_548': 37.7559,
    '824_549': 37.8608,
    '824_550': 37.9584,
    '824_551': 38.0423,
    '824_552': 38.1195,
    '824_553': 38.1788,
    '824_554': 38.2152,
    '824_555': 38.2556,
    '824_556': 38.3148,
    '824_557': 38.4106,
    '824_558': 38.5102,
    '824_559': 38.6026,
    '824_560': 38.684,
    '824_561': 38.7458,
    '824_562': 38.7856,
    '824_563': 38.7933,
    '824_564': 38.7725,
    '824_565': 38.7259,
    '824_566': 38.6629,
    '824_567': 38.597,
    '824_568': 38.523,
    '824_569': 38.4326,
    '824_570': 38.3279,
    '824_571': 38.2138,
    '824_572': 38.095,
    '824_573': 37.9881,
    '824_574': 37.8897,
    '824_575': 37.7959,
    '824_576': 37.6961,
    '824_577': 37.5742,
    '824_578': 37.4381,
    '824_579': 37.3104,
    '824_580': 37.1883,
    '824_581': 37.0712,
    '824_582': 36.9666,
    '824_583': 36.8713,
    '824_584': 36.7866,
    '824_585': 36.7111,
    '824_586': 36.644,
    '824_587': 36.5825,
    '824_588': 36.5256,
    '824_589': 36.474,
    '824_590': 36.4288,
    '824_591': 36.396,
    '824_592': 36.368,
    '824_593': 36.3398,
    '824_594': 36.3122,
    '824_595': 36.3026,
    '824_596': 36.3193,
    '824_597': 36.3481,
    '824_598': 36.3903,
    '824_599': 36.4439,
    '824_600': 36.5085,
    '824_601': 36.5818,
    '824_602': 36.663,
    '824_603': 36.7527,
    '824_604': 36.8544,
    '824_605': 36.9611,
    '824_606': 37.073,
    '824_607': 37.1952,
    '824_608': 37.3312,
    '824_609': 37.482,
    '824_610': 37.6409,
    '824_611': 37.8036,
    '824_612': 37.9686,
    '824_613': 38.1358,
    '824_614': 38.3055,
    '824_615': 38.4768,
    '824_616': 38.6514,
    '824_617': 38.8307,
    '824_618': 39.0098,
    '824_619': 39.1872,
    '824_620': 39.3593,
    '824_621': 39.53,
    '824_622': 39.6853,
    '824_623': 39.8171,
    '824_624': 39.9538,
    '824_625': 40.0724,
    '824_626': 40.1686,
    '824_627': 40.2364,
    '824_628': 40.2748,
    '824_629': 40.2859,
    '824_630': 40.2699,
    '824_631': 40.2365,
    '824_632': 40.2063,
    '824_633': 40.1582,
    '824_634': 40.1193,
    '824_635': 40.0412,
    '824_636': 39.9016,
    '824_637': 39.7363,
    '824_638': 39.5611,
    '824_639': 39.3636,
    '824_640': 39.146,
    '824_641': 38.9119,
    '824_642': 38.6558,
    '824_643': 38.3909,
    '824_644': 38.1216,
    '824_645': 37.8517,
    '824_646': 37.5706,
    '824_647': 37.276,
    '824_648': 36.9945,
    '824_649': 36.7232,
    '824_650': 36.4681,
    '824_651': 36.2392,
    '824_652': 36.031,
    '824_653': 35.8407,
    '824_654': 35.669,
    '824_655': 35.5196,
    '824_656': 35.3917,
    '824_657': 35.2874,
    '824_658': 35.1974,
    '824_659': 35.1135,
    '824_660': 35.0445,
    '825_383': 31.316,
    '825_384': 31.3445,
    '825_385': 31.3695,
    '825_386': 31.394,
    '825_387': 31.419,
    '825_388': 31.4454,
    '825_389': 31.4645,
    '825_390': 31.4746,
    '825_391': 31.4881,
    '825_392': 31.5002,
    '825_393': 31.5106,
    '825_394': 31.5298,
    '825_400': 31.6836,
    '825_401': 31.734,
    '825_402': 31.7858,
    '825_403': 31.8339,
    '825_404': 31.8799,
    '825_405': 31.924,
    '825_406': 31.9694,
    '825_407': 32.0171,
    '825_408': 32.0696,
    '825_409': 32.1236,
    '825_410': 32.1772,
    '825_411': 32.2262,
    '825_412': 32.267,
    '825_413': 32.3014,
    '825_414': 32.3356,
    '825_415': 32.3701,
    '825_416': 32.4061,
    '825_417': 32.4398,
    '825_418': 32.4781,
    '825_419': 32.5203,
    '825_420': 32.5654,
    '825_421': 32.6137,
    '825_422': 32.6582,
    '825_423': 32.6737,
    '825_424': 32.6653,
    '825_425': 32.6565,
    '825_426': 32.6544,
    '825_427': 32.6649,
    '825_428': 32.6729,
    '825_429': 32.678,
    '825_430': 32.7071,
    '825_431': 32.7776,
    '825_432': 32.8565,
    '825_433': 32.8775,
    '825_434': 32.8664,
    '825_435': 32.8604,
    '825_436': 32.843,
    '825_437': 32.7984,
    '825_438': 32.7619,
    '825_439': 32.7395,
    '825_440': 32.7263,
    '825_441': 32.7146,
    '825_442': 32.6939,
    '825_443': 32.6688,
    '825_444': 32.6408,
    '825_445': 32.6109,
    '825_446': 32.5801,
    '825_447': 32.5481,
    '825_448': 32.5143,
    '825_449': 32.4781,
    '825_450': 32.4397,
    '825_451': 32.3995,
    '825_452': 32.3581,
    '825_453': 32.3158,
    '825_454': 32.2733,
    '825_455': 32.2314,
    '825_456': 32.1912,
    '825_457': 32.1539,
    '825_458': 32.1202,
    '825_459': 32.0896,
    '825_460': 32.0613,
    '825_461': 32.035,
    '825_462': 32.0111,
    '825_463': 31.9941,
    '825_464': 31.9727,
    '825_465': 31.9467,
    '825_466': 31.9169,
    '825_467': 31.884,
    '825_468': 31.8458,
    '825_469': 31.805,
    '825_470': 31.7572,
    '825_471': 31.7091,
    '825_472': 31.6654,
    '825_473': 31.628,
    '825_474': 31.5959,
    '825_475': 31.5697,
    '825_476': 31.5536,
    '825_477': 31.5449,
    '825_478': 31.5416,
    '825_479': 31.5394,
    '825_480': 31.5335,
    '825_481': 31.53,
    '825_482': 31.5301,
    '825_483': 31.5295,
    '825_484': 31.5297,
    '825_485': 31.5299,
    '825_486': 31.5338,
    '825_487': 31.5331,
    '825_488': 31.5311,
    '825_489': 31.5317,
    '825_490': 31.5346,
    '825_491': 31.5381,
    '825_492': 31.5486,
    '825_493': 31.5761,
    '825_494': 31.619,
    '825_495': 31.6768,
    '825_496': 31.7479,
    '825_497': 31.8268,
    '825_498': 31.9095,
    '825_499': 32.0006,
    '825_500': 32.0957,
    '825_501': 32.1975,
    '825_502': 32.3069,
    '825_503': 32.4283,
    '825_504': 32.5622,
    '825_505': 32.7156,
    '825_506': 32.8848,
    '825_507': 33.0701,
    '825_508': 33.2698,
    '825_509': 33.4826,
    '825_510': 33.6924,
    '825_511': 33.8854,
    '825_512': 34.0773,
    '825_513': 34.2847,
    '825_514': 34.5098,
    '825_515': 34.735,
    '825_516': 34.9608,
    '825_517': 35.1781,
    '825_518': 35.3738,
    '825_519': 35.5462,
    '825_520': 35.7041,
    '825_521': 35.8567,
    '825_522': 36.0114,
    '825_523': 36.1512,
    '825_524': 36.296,
    '825_525': 36.4293,
    '825_526': 36.5286,
    '825_527': 36.6237,
    '825_528': 36.696,
    '825_529': 36.7531,
    '825_530': 36.8088,
    '825_531': 36.8604,
    '825_532': 36.9121,
    '825_533': 36.9696,
    '825_534': 37.0389,
    '825_535': 37.1026,
    '825_536': 37.1534,
    '825_537': 37.1839,
    '825_538': 37.2032,
    '825_539': 37.2272,
    '825_540': 37.2592,
    '825_541': 37.2834,
    '825_542': 37.3261,
    '825_543': 37.3907,
    '825_544': 37.456,
    '825_545': 37.5246,
    '825_546': 37.5981,
    '825_547': 37.6797,
    '825_548': 37.7838,
    '825_549': 37.8891,
    '825_550': 37.988,
    '825_551': 38.0819,
    '825_552': 38.1689,
    '825_553': 38.2411,
    '825_554': 38.3033,
    '825_555': 38.3498,
    '825_556': 38.4021,
    '825_557': 38.4847,
    '825_558': 38.5769,
    '825_559': 38.6617,
    '825_560': 38.7335,
    '825_561': 38.7818,
    '825_562': 38.8112,
    '825_563': 38.814,
    '825_564': 38.7865,
    '825_565': 38.7379,
    '825_566': 38.6745,
    '825_567': 38.6055,
    '825_568': 38.5331,
    '825_569': 38.446,
    '825_570': 38.347,
    '825_571': 38.2431,
    '825_572': 38.1329,
    '825_573': 38.0246,
    '825_574': 37.9205,
    '825_575': 37.8269,
    '825_576': 37.7267,
    '825_577': 37.6088,
    '825_578': 37.4771,
    '825_579': 37.3534,
    '825_580': 37.2329,
    '825_581': 37.1161,
    '825_582': 37.0109,
    '825_583': 36.907,
    '825_584': 36.8182,
    '825_585': 36.7432,
    '825_586': 36.6769,
    '825_587': 36.6168,
    '825_588': 36.5622,
    '825_589': 36.5121,
    '825_590': 36.4669,
    '825_591': 36.4358,
    '825_592': 36.4123,
    '825_593': 36.3921,
    '825_594': 36.3757,
    '825_595': 36.372,
    '825_596': 36.3939,
    '825_597': 36.4245,
    '825_598': 36.4656,
    '825_599': 36.5181,
    '825_600': 36.5835,
    '825_601': 36.6587,
    '825_602': 36.7421,
    '825_603': 36.834,
    '825_604': 36.9326,
    '825_605': 37.0385,
    '825_606': 37.1499,
    '825_607': 37.2711,
    '825_608': 37.4079,
    '825_609': 37.5653,
    '825_610': 37.7284,
    '825_611': 37.8925,
    '825_612': 38.0571,
    '825_613': 38.2237,
    '825_614': 38.3941,
    '825_615': 38.5663,
    '825_616': 38.7411,
    '825_617': 38.9194,
    '825_618': 39.0968,
    '825_619': 39.2744,
    '825_620': 39.4354,
    '825_621': 39.6034,
    '825_622': 39.7505,
    '825_623': 39.8781,
    '825_624': 39.9979,
    '825_625': 40.1126,
    '825_626': 40.2058,
    '825_627': 40.2728,
    '825_628': 40.3055,
    '825_629': 40.3112,
    '825_630': 40.3005,
    '825_631': 40.2718,
    '825_632': 40.2383,
    '825_633': 40.1903,
    '825_634': 40.1436,
    '825_635': 40.0839,
    '825_636': 39.9632,
    '825_637': 39.8047,
    '825_638': 39.6414,
    '825_639': 39.4563,
    '825_640': 39.2522,
    '825_641': 39.0257,
    '825_642': 38.7813,
    '825_643': 38.529,
    '825_644': 38.2702,
    '825_645': 38.0032,
    '825_646': 37.7269,
    '825_647': 37.4435,
    '825_648': 37.164,
    '825_649': 36.8859,
    '825_650': 36.6203,
    '825_651': 36.3866,
    '825_652': 36.1732,
    '825_653': 35.9754,
    '825_654': 35.7941,
    '825_655': 35.6312,
    '825_656': 35.489,
    '825_657': 35.3787,
    '825_658': 35.2824,
    '825_659': 35.1934,
    '825_660': 35.1121,
    '826_383': 31.2989,
    '826_384': 31.325,
    '826_385': 31.3545,
    '826_386': 31.3808,
    '826_387': 31.4074,
    '826_388': 31.4332,
    '826_389': 31.453,
    '826_390': 31.4664,
    '826_391': 31.4781,
    '826_392': 31.4889,
    '826_393': 31.4978,
    '826_394': 31.5156,
    '826_408': 32.0551,
    '826_409': 32.1085,
    '826_410': 32.1616,
    '826_411': 32.2097,
    '826_412': 32.2489,
    '826_413': 32.281,
    '826_414': 32.314,
    '826_415': 32.3481,
    '826_416': 32.3838,
    '826_417': 32.4173,
    '826_418': 32.4507,
    '826_419': 32.4899,
    '826_420': 32.5309,
    '826_421': 32.5721,
    '826_422': 32.6081,
    '826_423': 32.625,
    '826_424': 32.6355,
    '826_425': 32.6348,
    '826_426': 32.6313,
    '826_427': 32.6361,
    '826_428': 32.6463,
    '826_429': 32.6594,
    '826_430': 32.6981,
    '826_431': 32.7736,
    '826_432': 32.8471,
    '826_433': 32.8601,
    '826_434': 32.8469,
    '826_435': 32.8378,
    '826_436': 32.8416,
    '826_437': 32.8134,
    '826_438': 32.7729,
    '826_439': 32.7368,
    '826_440': 32.7157,
    '826_441': 32.7003,
    '826_442': 32.6799,
    '826_443': 32.6558,
    '826_444': 32.6288,
    '826_445': 32.6,
    '826_446': 32.5708,
    '826_447': 32.5412,
    '826_448': 32.5101,
    '826_449': 32.4759,
    '826_450': 32.4383,
    '826_451': 32.3988,
    '826_452': 32.3581,
    '826_453': 32.3165,
    '826_454': 32.2744,
    '826_455': 32.2326,
    '826_456': 32.1924,
    '826_457': 32.1555,
    '826_458': 32.123,
    '826_459': 32.0937,
    '826_460': 32.0661,
    '826_461': 32.0387,
    '826_462': 32.0125,
    '826_463': 31.9986,
    '826_464': 31.9769,
    '826_465': 31.9524,
    '826_466': 31.9236,
    '826_467': 31.8917,
    '826_468': 31.8566,
    '826_469': 31.8144,
    '826_470': 31.7688,
    '826_471': 31.7258,
    '826_472': 31.6876,
    '826_473': 31.655,
    '826_474': 31.6286,
    '826_475': 31.6098,
    '826_476': 31.5984,
    '826_477': 31.5941,
    '826_478': 31.5988,
    '826_479': 31.6029,
    '826_480': 31.5944,
    '826_481': 31.5888,
    '826_482': 31.585,
    '826_483': 31.5829,
    '826_484': 31.5799,
    '826_485': 31.5754,
    '826_486': 31.5747,
    '826_487': 31.5756,
    '826_488': 31.5764,
    '826_489': 31.578,
    '826_490': 31.5806,
    '826_491': 31.5769,
    '826_492': 31.5803,
    '826_493': 31.6024,
    '826_494': 31.6407,
    '826_495': 31.6906,
    '826_496': 31.7549,
    '826_497': 31.8254,
    '826_498': 31.8963,
    '826_499': 31.9772,
    '826_500': 32.0681,
    '826_501': 32.1668,
    '826_502': 32.2732,
    '826_503': 32.3898,
    '826_504': 32.5171,
    '826_505': 32.6597,
    '826_506': 32.8214,
    '826_507': 32.9973,
    '826_508': 33.1843,
    '826_509': 33.3826,
    '826_510': 33.5871,
    '826_511': 33.7843,
    '826_512': 33.9702,
    '826_513': 34.173,
    '826_514': 34.3934,
    '826_515': 34.614,
    '826_516': 34.8416,
    '826_517': 35.0594,
    '826_518': 35.2698,
    '826_519': 35.4557,
    '826_520': 35.6298,
    '826_521': 35.7905,
    '826_522': 35.9523,
    '826_523': 36.113,
    '826_524': 36.2381,
    '826_525': 36.3681,
    '826_526': 36.4894,
    '826_527': 36.5889,
    '826_528': 36.6779,
    '826_529': 36.7483,
    '826_530': 36.8103,
    '826_531': 36.8644,
    '826_532': 36.908,
    '826_533': 36.9614,
    '826_534': 37.0277,
    '826_535': 37.0948,
    '826_536': 37.1461,
    '826_537': 37.1782,
    '826_538': 37.2053,
    '826_539': 37.2351,
    '826_540': 37.2755,
    '826_541': 37.309,
    '826_542': 37.3551,
    '826_543': 37.414,
    '826_544': 37.4684,
    '826_545': 37.5222,
    '826_546': 37.5889,
    '826_547': 37.6741,
    '826_548': 37.7796,
    '826_549': 37.8869,
    '826_550': 37.9903,
    '826_551': 38.0871,
    '826_552': 38.187,
    '826_553': 38.2819,
    '826_554': 38.3597,
    '826_555': 38.4274,
    '826_556': 38.4903,
    '826_557': 38.566,
    '826_558': 38.6485,
    '826_559': 38.7262,
    '826_560': 38.7837,
    '826_561': 38.8294,
    '826_562': 38.8482,
    '826_563': 38.8349,
    '826_564': 38.8045,
    '826_565': 38.7539,
    '826_566': 38.6915,
    '826_567': 38.6195,
    '826_568': 38.543,
    '826_569': 38.4558,
    '826_570': 38.3588,
    '826_571': 38.2614,
    '826_572': 38.1542,
    '826_573': 38.0488,
    '826_574': 37.9387,
    '826_575': 37.8402,
    '826_576': 37.7338,
    '826_577': 37.6183,
    '826_578': 37.4994,
    '826_579': 37.3817,
    '826_580': 37.2637,
    '826_581': 37.1509,
    '826_582': 37.045,
    '826_583': 36.9422,
    '826_584': 36.8516,
    '826_585': 36.7752,
    '826_586': 36.709,
    '826_587': 36.6495,
    '826_588': 36.5962,
    '826_589': 36.549,
    '826_590': 36.5047,
    '826_591': 36.4768,
    '826_592': 36.4583,
    '826_593': 36.4458,
    '826_594': 36.439,
    '826_595': 36.4393,
    '826_596': 36.4661,
    '826_597': 36.4996,
    '826_598': 36.5395,
    '826_599': 36.5905,
    '826_600': 36.6577,
    '826_601': 36.7349,
    '826_602': 36.818,
    '826_603': 36.908,
    '826_604': 37.0096,
    '826_605': 37.1182,
    '826_606': 37.232,
    '826_607': 37.3558,
    '826_608': 37.5005,
    '826_609': 37.6598,
    '826_610': 37.8236,
    '826_611': 37.9869,
    '826_612': 38.1501,
    '826_613': 38.3132,
    '826_614': 38.4853,
    '826_615': 38.6629,
    '826_616': 38.8442,
    '826_617': 39.0228,
    '826_618': 39.1935,
    '826_619': 39.3636,
    '826_620': 39.5125,
    '826_621': 39.6666,
    '826_622': 39.8125,
    '826_623': 39.9375,
    '826_624': 40.0485,
    '826_625': 40.1604,
    '826_626': 40.2513,
    '826_627': 40.3135,
    '826_628': 40.3355,
    '826_629': 40.3291,
    '826_630': 40.3245,
    '826_631': 40.3081,
    '826_632': 40.2764,
    '826_633': 40.224,
    '826_634': 40.1723,
    '826_635': 40.1204,
    '826_636': 40.0149,
    '826_637': 39.8832,
    '826_638': 39.7362,
    '826_639': 39.5484,
    '826_640': 39.3513,
    '826_641': 39.1371,
    '826_642': 38.9052,
    '826_643': 38.6645,
    '826_644': 38.4165,
    '826_645': 38.158,
    '826_646': 37.8916,
    '826_647': 37.6216,
    '826_648': 37.3474,
    '826_649': 37.0656,
    '826_650': 36.7924,
    '826_651': 36.5522,
    '826_652': 36.3316,
    '826_653': 36.125,
    '826_654': 35.9336,
    '826_655': 35.7599,
    '826_656': 35.6101,
    '826_657': 35.488,
    '826_658': 35.3822,
    '826_659': 35.2862,
    '826_660': 35.1948,
    '827_383': 31.2797,
    '827_384': 31.3055,
    '827_385': 31.3363,
    '827_386': 31.3645,
    '827_387': 31.3948,
    '827_388': 31.4213,
    '827_389': 31.4432,
    '827_390': 31.4561,
    '827_391': 31.4642,
    '827_392': 31.4751,
    '827_393': 31.4836,
    '827_394': 31.5008,
    '827_408': 32.0394,
    '827_409': 32.0916,
    '827_410': 32.1438,
    '827_411': 32.1915,
    '827_412': 32.2277,
    '827_413': 32.2584,
    '827_414': 32.2909,
    '827_415': 32.3244,
    '827_416': 32.3594,
    '827_417': 32.3884,
    '827_418': 32.422,
    '827_419': 32.4598,
    '827_420': 32.5015,
    '827_421': 32.5337,
    '827_422': 32.5626,
    '827_423': 32.5834,
    '827_424': 32.6013,
    '827_425': 32.6117,
    '827_426': 32.6075,
    '827_427': 32.6064,
    '827_428': 32.6168,
    '827_429': 32.6371,
    '827_430': 32.6864,
    '827_431': 32.768,
    '827_432': 32.8273,
    '827_433': 32.8362,
    '827_434': 32.8208,
    '827_435': 32.8109,
    '827_436': 32.8227,
    '827_437': 32.8019,
    '827_438': 32.7626,
    '827_439': 32.7271,
    '827_440': 32.7045,
    '827_441': 32.6877,
    '827_442': 32.6671,
    '827_443': 32.6432,
    '827_444': 32.6168,
    '827_445': 32.5887,
    '827_446': 32.5607,
    '827_447': 32.533,
    '827_448': 32.5048,
    '827_449': 32.4723,
    '827_450': 32.4367,
    '827_451': 32.3989,
    '827_452': 32.3595,
    '827_453': 32.3188,
    '827_454': 32.2772,
    '827_455': 32.2353,
    '827_456': 32.1946,
    '827_457': 32.1579,
    '827_458': 32.1279,
    '827_459': 32.1017,
    '827_460': 32.0768,
    '827_461': 32.0507,
    '827_462': 32.0207,
    '827_463': 32.0016,
    '827_464': 31.9811,
    '827_465': 31.9588,
    '827_466': 31.9314,
    '827_467': 31.902,
    '827_468': 31.8662,
    '827_469': 31.8235,
    '827_470': 31.7813,
    '827_471': 31.7447,
    '827_472': 31.712,
    '827_473': 31.6839,
    '827_474': 31.6617,
    '827_475': 31.6474,
    '827_476': 31.6412,
    '827_477': 31.6439,
    '827_478': 31.6546,
    '827_479': 31.6695,
    '827_480': 31.6558,
    '827_481': 31.647,
    '827_482': 31.6419,
    '827_483': 31.6334,
    '827_484': 31.628,
    '827_485': 31.6197,
    '827_486': 31.6155,
    '827_487': 31.6145,
    '827_488': 31.6196,
    '827_489': 31.6313,
    '827_490': 31.6263,
    '827_491': 31.6174,
    '827_492': 31.613,
    '827_493': 31.6314,
    '827_494': 31.6665,
    '827_495': 31.7128,
    '827_496': 31.7742,
    '827_497': 31.8331,
    '827_498': 31.8939,
    '827_499': 31.9638,
    '827_500': 32.0504,
    '827_501': 32.1485,
    '827_502': 32.2525,
    '827_503': 32.3662,
    '827_504': 32.4896,
    '827_505': 32.6266,
    '827_506': 32.7803,
    '827_507': 32.9445,
    '827_508': 33.1207,
    '827_509': 33.3067,
    '827_510': 33.499,
    '827_511': 33.6921,
    '827_512': 33.8796,
    '827_513': 34.0796,
    '827_514': 34.2916,
    '827_515': 34.5084,
    '827_516': 34.7311,
    '827_517': 34.9456,
    '827_518': 35.1554,
    '827_519': 35.3509,
    '827_520': 35.5334,
    '827_521': 35.6984,
    '827_522': 35.8599,
    '827_523': 36.0276,
    '827_524': 36.1679,
    '827_525': 36.2918,
    '827_526': 36.4112,
    '827_527': 36.527,
    '827_528': 36.624,
    '827_529': 36.7138,
    '827_530': 36.7786,
    '827_531': 36.8414,
    '827_532': 36.8974,
    '827_533': 36.9512,
    '827_534': 37.0084,
    '827_535': 37.068,
    '827_536': 37.1249,
    '827_537': 37.1709,
    '827_538': 37.2024,
    '827_539': 37.2414,
    '827_540': 37.2907,
    '827_541': 37.3443,
    '827_542': 37.3957,
    '827_543': 37.4571,
    '827_544': 37.509,
    '827_545': 37.5505,
    '827_546': 37.6056,
    '827_547': 37.6744,
    '827_548': 37.7631,
    '827_549': 37.8585,
    '827_550': 37.9605,
    '827_551': 38.0626,
    '827_552': 38.1792,
    '827_553': 38.2953,
    '827_554': 38.3949,
    '827_555': 38.4859,
    '827_556': 38.5615,
    '827_557': 38.6383,
    '827_558': 38.7158,
    '827_559': 38.7879,
    '827_560': 38.8419,
    '827_561': 38.8804,
    '827_562': 38.8801,
    '827_563': 38.8706,
    '827_564': 38.8368,
    '827_565': 38.7884,
    '827_566': 38.7315,
    '827_567': 38.6641,
    '827_568': 38.5778,
    '827_569': 38.4795,
    '827_570': 38.3764,
    '827_571': 38.2766,
    '827_572': 38.1661,
    '827_573': 38.065,
    '827_574': 37.9532,
    '827_575': 37.8463,
    '827_576': 37.7352,
    '827_577': 37.6203,
    '827_578': 37.5082,
    '827_579': 37.4,
    '827_580': 37.2888,
    '827_581': 37.1836,
    '827_582': 37.0771,
    '827_583': 36.9796,
    '827_584': 36.8892,
    '827_585': 36.8108,
    '827_586': 36.7422,
    '827_587': 36.6811,
    '827_588': 36.6277,
    '827_589': 36.5828,
    '827_590': 36.5452,
    '827_591': 36.52,
    '827_592': 36.5053,
    '827_593': 36.499,
    '827_594': 36.4999,
    '827_595': 36.5088,
    '827_596': 36.5369,
    '827_597': 36.5703,
    '827_598': 36.6109,
    '827_599': 36.6625,
    '827_600': 36.7319,
    '827_601': 36.8117,
    '827_602': 36.8961,
    '827_603': 36.9882,
    '827_604': 37.091,
    '827_605': 37.2028,
    '827_606': 37.3219,
    '827_607': 37.4526,
    '827_608': 37.5995,
    '827_609': 37.7598,
    '827_610': 37.9266,
    '827_611': 38.0884,
    '827_612': 38.2494,
    '827_613': 38.4034,
    '827_614': 38.5745,
    '827_615': 38.7617,
    '827_616': 38.9469,
    '827_617': 39.1204,
    '827_618': 39.2916,
    '827_619': 39.4549,
    '827_620': 39.5857,
    '827_621': 39.7265,
    '827_622': 39.8745,
    '827_623': 39.9978,
    '827_624': 40.1062,
    '827_625': 40.212,
    '827_626': 40.3016,
    '827_627': 40.3561,
    '827_628': 40.3686,
    '827_629': 40.3556,
    '827_630': 40.343,
    '827_631': 40.3315,
    '827_632': 40.3073,
    '827_633': 40.2625,
    '827_634': 40.2036,
    '827_635': 40.1482,
    '827_636': 40.0664,
    '827_637': 39.9699,
    '827_638': 39.8319,
    '827_639': 39.6428,
    '827_640': 39.4497,
    '827_641': 39.2451,
    '827_642': 39.0242,
    '827_643': 38.7949,
    '827_644': 38.558,
    '827_645': 38.3116,
    '827_646': 38.0554,
    '827_647': 37.7935,
    '827_648': 37.5322,
    '827_649': 37.2578,
    '827_650': 36.9831,
    '827_651': 36.7362,
    '827_652': 36.5066,
    '827_653': 36.2895,
    '827_654': 36.0853,
    '827_655': 35.9025,
    '827_656': 35.7408,
    '827_657': 35.606,
    '827_658': 35.4934,
    '827_659': 35.3916,
    '827_660': 35.2927,
    '828_383': 31.2526,
    '828_384': 31.2802,
    '828_385': 31.3137,
    '828_386': 31.3439,
    '828_387': 31.3751,
    '828_388': 31.4058,
    '828_389': 31.4273,
    '828_390': 31.4389,
    '828_391': 31.4471,
    '828_392': 31.4578,
    '828_393': 31.4671,
    '828_394': 31.4844,
    '828_408': 32.0171,
    '828_409': 32.0672,
    '828_410': 32.1159,
    '828_411': 32.1605,
    '828_412': 32.1978,
    '828_413': 32.2321,
    '828_414': 32.2666,
    '828_415': 32.3009,
    '828_416': 32.3329,
    '828_417': 32.3599,
    '828_418': 32.3921,
    '828_419': 32.4279,
    '828_420': 32.4697,
    '828_421': 32.4975,
    '828_422': 32.5247,
    '828_423': 32.5487,
    '828_424': 32.5686,
    '828_425': 32.5816,
    '828_426': 32.5832,
    '828_427': 32.5769,
    '828_428': 32.5848,
    '828_429': 32.6091,
    '828_430': 32.6643,
    '828_431': 32.7436,
    '828_432': 32.7968,
    '828_433': 32.8024,
    '828_434': 32.7856,
    '828_435': 32.7772,
    '828_436': 32.7835,
    '828_437': 32.759,
    '828_438': 32.7304,
    '828_439': 32.7057,
    '828_440': 32.6894,
    '828_441': 32.6742,
    '828_442': 32.6535,
    '828_443': 32.6299,
    '828_444': 32.6037,
    '828_445': 32.5756,
    '828_446': 32.5478,
    '828_447': 32.5213,
    '828_448': 32.4953,
    '828_449': 32.4664,
    '828_450': 32.434,
    '828_451': 32.3984,
    '828_452': 32.3607,
    '828_453': 32.3215,
    '828_454': 32.2804,
    '828_455': 32.2384,
    '828_456': 32.1973,
    '828_457': 32.1603,
    '828_458': 32.1339,
    '828_459': 32.1112,
    '828_460': 32.0891,
    '828_461': 32.0644,
    '828_462': 32.0367,
    '828_463': 32.0105,
    '828_464': 31.9889,
    '828_465': 31.963,
    '828_466': 31.9347,
    '828_467': 31.905,
    '828_468': 31.8709,
    '828_469': 31.833,
    '828_470': 31.7981,
    '828_471': 31.7666,
    '828_472': 31.7383,
    '828_473': 31.714,
    '828_474': 31.6959,
    '828_475': 31.687,
    '828_476': 31.6851,
    '828_477': 31.6912,
    '828_478': 31.7025,
    '828_479': 31.7149,
    '828_480': 31.707,
    '828_481': 31.6986,
    '828_482': 31.6922,
    '828_483': 31.6843,
    '828_484': 31.673,
    '828_485': 31.6635,
    '828_486': 31.6565,
    '828_487': 31.655,
    '828_488': 31.6616,
    '828_489': 31.6735,
    '828_490': 31.6572,
    '828_491': 31.647,
    '828_492': 31.6446,
    '828_493': 31.6622,
    '828_494': 31.6942,
    '828_495': 31.7361,
    '828_496': 31.7965,
    '828_497': 31.8487,
    '828_498': 31.903,
    '828_499': 31.9688,
    '828_500': 32.0506,
    '828_501': 32.146,
    '828_502': 32.2498,
    '828_503': 32.3591,
    '828_504': 32.4757,
    '828_505': 32.6056,
    '828_506': 32.752,
    '828_507': 32.908,
    '828_508': 33.0756,
    '828_509': 33.2521,
    '828_510': 33.4362,
    '828_511': 33.6247,
    '828_512': 33.8162,
    '828_513': 34.0155,
    '828_514': 34.2239,
    '828_515': 34.4358,
    '828_516': 34.6478,
    '828_517': 34.8592,
    '828_518': 35.0613,
    '828_519': 35.251,
    '828_520': 35.4318,
    '828_521': 35.595,
    '828_522': 35.7557,
    '828_523': 35.9178,
    '828_524': 36.0584,
    '828_525': 36.1793,
    '828_526': 36.3021,
    '828_527': 36.4364,
    '828_528': 36.5477,
    '828_529': 36.6511,
    '828_530': 36.7333,
    '828_531': 36.8109,
    '828_532': 36.877,
    '828_533': 36.9344,
    '828_534': 36.9914,
    '828_535': 37.0478,
    '828_536': 37.1146,
    '828_537': 37.1669,
    '828_538': 37.211,
    '828_539': 37.261,
    '828_540': 37.3168,
    '828_541': 37.3787,
    '828_542': 37.4306,
    '828_543': 37.4975,
    '828_544': 37.5533,
    '828_545': 37.591,
    '828_546': 37.6353,
    '828_547': 37.6956,
    '828_548': 37.7788,
    '828_549': 37.8594,
    '828_550': 37.9426,
    '828_551': 38.0362,
    '828_552': 38.1586,
    '828_553': 38.2899,
    '828_554': 38.41,
    '828_555': 38.5051,
    '828_556': 38.599,
    '828_557': 38.6879,
    '828_558': 38.7619,
    '828_559': 38.8365,
    '828_560': 38.8824,
    '828_561': 38.9189,
    '828_562': 38.9238,
    '828_563': 38.9139,
    '828_564': 38.8854,
    '828_565': 38.8422,
    '828_566': 38.7875,
    '828_567': 38.7174,
    '828_568': 38.6209,
    '828_569': 38.5155,
    '828_570': 38.4038,
    '828_571': 38.2985,
    '828_572': 38.1873,
    '828_573': 38.084,
    '828_574': 37.9755,
    '828_575': 37.8622,
    '828_576': 37.7474,
    '828_577': 37.6313,
    '828_578': 37.5147,
    '828_579': 37.4053,
    '828_580': 37.3058,
    '828_581': 37.2136,
    '828_582': 37.1108,
    '828_583': 37.0085,
    '828_584': 36.9172,
    '828_585': 36.8453,
    '828_586': 36.7816,
    '828_587': 36.7138,
    '828_588': 36.6576,
    '828_589': 36.6145,
    '828_590': 36.5813,
    '828_591': 36.5597,
    '828_592': 36.549,
    '828_593': 36.5482,
    '828_594': 36.5567,
    '828_595': 36.5742,
    '828_596': 36.6063,
    '828_597': 36.6388,
    '828_598': 36.6806,
    '828_599': 36.7346,
    '828_600': 36.8032,
    '828_601': 36.8827,
    '828_602': 36.9698,
    '828_603': 37.0653,
    '828_604': 37.1706,
    '828_605': 37.2857,
    '828_606': 37.4093,
    '828_607': 37.5444,
    '828_608': 37.6936,
    '828_609': 37.853,
    '828_610': 38.0185,
    '828_611': 38.1851,
    '828_612': 38.3467,
    '828_613': 38.4974,
    '828_614': 38.6633,
    '828_615': 38.8433,
    '828_616': 39.0287,
    '828_617': 39.2024,
    '828_618': 39.3726,
    '828_619': 39.5293,
    '828_620': 39.6537,
    '828_621': 39.7856,
    '828_622': 39.9301,
    '828_623': 40.0586,
    '828_624': 40.165,
    '828_625': 40.2655,
    '828_626': 40.3519,
    '828_627': 40.3963,
    '828_628': 40.4023,
    '828_629': 40.3854,
    '828_630': 40.3654,
    '828_631': 40.35,
    '828_632': 40.3304,
    '828_633': 40.2972,
    '828_634': 40.2405,
    '828_635': 40.1777,
    '828_636': 40.1225,
    '828_637': 40.045,
    '828_638': 39.9106,
    '828_639': 39.7332,
    '828_640': 39.5522,
    '828_641': 39.3488,
    '828_642': 39.1406,
    '828_643': 38.9226,
    '828_644': 38.698,
    '828_645': 38.4652,
    '828_646': 38.2226,
    '828_647': 37.9746,
    '828_648': 37.7211,
    '828_649': 37.459,
    '828_650': 37.1859,
    '828_651': 36.9334,
    '828_652': 36.6962,
    '828_653': 36.4714,
    '828_654': 36.2603,
    '828_655': 36.0649,
    '828_656': 35.8918,
    '828_657': 35.7502,
    '828_658': 35.6135,
    '828_659': 35.5141,
    '828_660': 35.4115,
    '829_383': 31.2232,
    '829_384': 31.2528,
    '829_385': 31.2887,
    '829_386': 31.3202,
    '829_387': 31.3529,
    '829_388': 31.3857,
    '829_389': 31.4061,
    '829_390': 31.4191,
    '829_391': 31.4279,
    '829_392': 31.4346,
    '829_393': 31.4483,
    '829_394': 31.4661,
    '829_408': 31.9887,
    '829_409': 32.0371,
    '829_410': 32.0831,
    '829_411': 32.1255,
    '829_412': 32.1629,
    '829_413': 32.1993,
    '829_414': 32.2369,
    '829_415': 32.2732,
    '829_416': 32.3051,
    '829_417': 32.331,
    '829_418': 32.3616,
    '829_419': 32.3958,
    '829_420': 32.4363,
    '829_421': 32.4647,
    '829_422': 32.4946,
    '829_423': 32.5228,
    '829_424': 32.5457,
    '829_425': 32.5559,
    '829_426': 32.5551,
    '829_427': 32.546,
    '829_428': 32.5519,
    '829_429': 32.5768,
    '829_430': 32.6299,
    '829_431': 32.7093,
    '829_432': 32.7597,
    '829_433': 32.7642,
    '829_434': 32.7449,
    '829_435': 32.7339,
    '829_436': 32.7303,
    '829_437': 32.7133,
    '829_438': 32.6964,
    '829_439': 32.6825,
    '829_440': 32.6715,
    '829_441': 32.6569,
    '829_442': 32.6382,
    '829_443': 32.6161,
    '829_444': 32.5906,
    '829_445': 32.5624,
    '829_446': 32.5341,
    '829_447': 32.509,
    '829_448': 32.4858,
    '829_449': 32.4609,
    '829_450': 32.4316,
    '829_451': 32.3974,
    '829_452': 32.3616,
    '829_453': 32.3241,
    '829_454': 32.2843,
    '829_455': 32.2423,
    '829_456': 32.2024,
    '829_457': 32.1677,
    '829_458': 32.1417,
    '829_459': 32.1202,
    '829_460': 32.1001,
    '829_461': 32.0745,
    '829_462': 32.0465,
    '829_463': 32.0177,
    '829_464': 31.9909,
    '829_465': 31.9648,
    '829_466': 31.9376,
    '829_467': 31.9096,
    '829_468': 31.8797,
    '829_469': 31.8495,
    '829_470': 31.8195,
    '829_471': 31.792,
    '829_472': 31.7671,
    '829_473': 31.7461,
    '829_474': 31.7315,
    '829_475': 31.7269,
    '829_476': 31.7291,
    '829_477': 31.7364,
    '829_478': 31.7446,
    '829_479': 31.752,
    '829_480': 31.7506,
    '829_481': 31.7442,
    '829_482': 31.7375,
    '829_483': 31.7326,
    '829_484': 31.7203,
    '829_485': 31.709,
    '829_486': 31.6995,
    '829_487': 31.6936,
    '829_488': 31.6941,
    '829_489': 31.6967,
    '829_490': 31.689,
    '829_491': 31.6857,
    '829_492': 31.6862,
    '829_493': 31.7,
    '829_494': 31.7275,
    '829_495': 31.7639,
    '829_496': 31.8128,
    '829_497': 31.8632,
    '829_498': 31.9155,
    '829_499': 31.9794,
    '829_500': 32.0603,
    '829_501': 32.1586,
    '829_502': 32.2667,
    '829_503': 32.373,
    '829_504': 32.4771,
    '829_505': 32.5973,
    '829_506': 32.7364,
    '829_507': 32.8866,
    '829_508': 33.0486,
    '829_509': 33.2167,
    '829_510': 33.3933,
    '829_511': 33.5797,
    '829_512': 33.7756,
    '829_513': 33.9831,
    '829_514': 34.1936,
    '829_515': 34.4074,
    '829_516': 34.6115,
    '829_517': 34.8182,
    '829_518': 35.0018,
    '829_519': 35.1723,
    '829_520': 35.3393,
    '829_521': 35.4895,
    '829_522': 35.644,
    '829_523': 35.7978,
    '829_524': 35.9265,
    '829_525': 36.0464,
    '829_526': 36.1742,
    '829_527': 36.3139,
    '829_528': 36.4366,
    '829_529': 36.5426,
    '829_530': 36.6517,
    '829_531': 36.7437,
    '829_532': 36.8271,
    '829_533': 36.8956,
    '829_534': 36.9654,
    '829_535': 37.0323,
    '829_536': 37.0964,
    '829_537': 37.1653,
    '829_538': 37.2196,
    '829_539': 37.2766,
    '829_540': 37.3391,
    '829_541': 37.3994,
    '829_542': 37.4557,
    '829_543': 37.5245,
    '829_544': 37.5792,
    '829_545': 37.6177,
    '829_546': 37.6522,
    '829_547': 37.7108,
    '829_548': 37.7938,
    '829_549': 37.8717,
    '829_550': 37.9479,
    '829_551': 38.0239,
    '829_552': 38.1397,
    '829_553': 38.2771,
    '829_554': 38.4063,
    '829_555': 38.5112,
    '829_556': 38.6121,
    '829_557': 38.7094,
    '829_558': 38.787,
    '829_559': 38.8636,
    '829_560': 38.9019,
    '829_561': 38.9334,
    '829_562': 38.9512,
    '829_563': 38.9423,
    '829_564': 38.9166,
    '829_565': 38.8781,
    '829_566': 38.8203,
    '829_567': 38.75,
    '829_568': 38.664,
    '829_569': 38.5579,
    '829_570': 38.4437,
    '829_571': 38.3295,
    '829_572': 38.2197,
    '829_573': 38.122,
    '829_574': 38.0189,
    '829_575': 37.903,
    '829_576': 37.7824,
    '829_577': 37.655,
    '829_578': 37.5327,
    '829_579': 37.4091,
    '829_580': 37.3091,
    '829_581': 37.2189,
    '829_582': 37.1191,
    '829_583': 37.0183,
    '829_584': 36.9274,
    '829_585': 36.8576,
    '829_586': 36.7987,
    '829_587': 36.7437,
    '829_588': 36.6915,
    '829_589': 36.6436,
    '829_590': 36.6107,
    '829_591': 36.5935,
    '829_592': 36.5866,
    '829_593': 36.5904,
    '829_594': 36.6041,
    '829_595': 36.6264,
    '829_596': 36.6632,
    '829_597': 36.6983,
    '829_598': 36.7427,
    '829_599': 36.7991,
    '829_600': 36.8683,
    '829_601': 36.9486,
    '829_602': 37.0381,
    '829_603': 37.1365,
    '829_604': 37.2446,
    '829_605': 37.3626,
    '829_606': 37.4889,
    '829_607': 37.6285,
    '829_608': 37.7809,
    '829_609': 37.9386,
    '829_610': 38.103,
    '829_611': 38.2723,
    '829_612': 38.4374,
    '829_613': 38.589,
    '829_614': 38.7514,
    '829_615': 38.9268,
    '829_616': 39.1066,
    '829_617': 39.2825,
    '829_618': 39.4479,
    '829_619': 39.5979,
    '829_620': 39.724,
    '829_621': 39.851,
    '829_622': 39.9853,
    '829_623': 40.1143,
    '829_624': 40.2251,
    '829_625': 40.3191,
    '829_626': 40.4006,
    '829_627': 40.4404,
    '829_628': 40.4441,
    '829_629': 40.4234,
    '829_630': 40.3956,
    '829_631': 40.3738,
    '829_632': 40.3534,
    '829_633': 40.3291,
    '829_634': 40.2817,
    '829_635': 40.2233,
    '829_636': 40.1798,
    '829_637': 40.1068,
    '829_638': 39.9835,
    '829_639': 39.8215,
    '829_640': 39.6464,
    '829_641': 39.4542,
    '829_642': 39.2556,
    '829_643': 39.0484,
    '829_644': 38.8349,
    '829_645': 38.6152,
    '829_646': 38.3847,
    '829_647': 38.1488,
    '829_648': 37.9038,
    '829_649': 37.6516,
    '829_650': 37.3882,
    '829_651': 37.1364,
    '829_652': 36.8939,
    '829_653': 36.6622,
    '829_654': 36.4434,
    '829_655': 36.2404,
    '829_656': 36.0571,
    '829_657': 35.9068,
    '829_658': 35.7695,
    '829_659': 35.6501,
    '829_660': 35.5306,
    '830_383': 31.2026,
    '830_384': 31.2305,
    '830_385': 31.2656,
    '830_386': 31.2974,
    '830_387': 31.3299,
    '830_388': 31.3619,
    '830_389': 31.3845,
    '830_390': 31.4016,
    '830_391': 31.4098,
    '830_392': 31.4159,
    '830_393': 31.4301,
    '830_394': 31.4482,
    '830_408': 31.9563,
    '830_409': 32.005,
    '830_410': 32.0501,
    '830_411': 32.0917,
    '830_412': 32.1301,
    '830_413': 32.1657,
    '830_414': 32.2079,
    '830_415': 32.2465,
    '830_416': 32.2788,
    '830_417': 32.3043,
    '830_418': 32.3333,
    '830_419': 32.366,
    '830_420': 32.4039,
    '830_421': 32.4343,
    '830_422': 32.4674,
    '830_423': 32.5031,
    '830_424': 32.5179,
    '830_425': 32.5228,
    '830_426': 32.5223,
    '830_427': 32.5176,
    '830_428': 32.5211,
    '830_429': 32.5441,
    '830_430': 32.5907,
    '830_431': 32.666,
    '830_432': 32.7164,
    '830_433': 32.7222,
    '830_434': 32.7071,
    '830_435': 32.6948,
    '830_436': 32.6898,
    '830_437': 32.6843,
    '830_438': 32.6755,
    '830_439': 32.6671,
    '830_440': 32.656,
    '830_441': 32.6422,
    '830_442': 32.6255,
    '830_443': 32.6055,
    '830_444': 32.5822,
    '830_445': 32.5548,
    '830_446': 32.526,
    '830_447': 32.5016,
    '830_448': 32.4807,
    '830_449': 32.4601,
    '830_450': 32.4336,
    '830_451': 32.3988,
    '830_452': 32.3635,
    '830_453': 32.3276,
    '830_454': 32.2892,
    '830_455': 32.2471,
    '830_456': 32.2081,
    '830_457': 32.1734,
    '830_458': 32.1486,
    '830_459': 32.1282,
    '830_460': 32.1082,
    '830_461': 32.0849,
    '830_462': 32.0588,
    '830_463': 32.031,
    '830_464': 32.0031,
    '830_465': 31.976,
    '830_466': 31.9483,
    '830_467': 31.9225,
    '830_468': 31.8964,
    '830_469': 31.8711,
    '830_470': 31.8462,
    '830_471': 31.8226,
    '830_472': 31.801,
    '830_473': 31.7826,
    '830_474': 31.7706,
    '830_475': 31.7691,
    '830_476': 31.7732,
    '830_477': 31.7808,
    '830_478': 31.7892,
    '830_479': 31.7956,
    '830_480': 31.7966,
    '830_481': 31.7897,
    '830_482': 31.7826,
    '830_483': 31.7784,
    '830_484': 31.7734,
    '830_485': 31.7628,
    '830_486': 31.751,
    '830_487': 31.7362,
    '830_488': 31.7316,
    '830_489': 31.7304,
    '830_490': 31.7286,
    '830_491': 31.7298,
    '830_492': 31.733,
    '830_493': 31.7437,
    '830_494': 31.768,
    '830_495': 31.7985,
    '830_496': 31.838,
    '830_497': 31.8833,
    '830_498': 31.9323,
    '830_499': 31.9936,
    '830_500': 32.0731,
    '830_501': 32.1777,
    '830_502': 32.2995,
    '830_503': 32.4207,
    '830_504': 32.4983,
    '830_505': 32.6014,
    '830_506': 32.7333,
    '830_507': 32.8792,
    '830_508': 33.0368,
    '830_509': 33.1996,
    '830_510': 33.3691,
    '830_511': 33.5536,
    '830_512': 33.7535,
    '830_513': 33.9722,
    '830_514': 34.1896,
    '830_515': 34.4031,
    '830_516': 34.6005,
    '830_517': 34.8009,
    '830_518': 34.971,
    '830_519': 35.115,
    '830_520': 35.246,
    '830_521': 35.3761,
    '830_522': 35.5209,
    '830_523': 35.6649,
    '830_524': 35.7897,
    '830_525': 35.9054,
    '830_526': 36.0323,
    '830_527': 36.1762,
    '830_528': 36.3042,
    '830_529': 36.4194,
    '830_530': 36.5509,
    '830_531': 36.6586,
    '830_532': 36.7578,
    '830_533': 36.8489,
    '830_534': 36.9248,
    '830_535': 36.9987,
    '830_536': 37.0719,
    '830_537': 37.1333,
    '830_538': 37.1929,
    '830_539': 37.2583,
    '830_540': 37.3304,
    '830_541': 37.4015,
    '830_542': 37.4608,
    '830_543': 37.5337,
    '830_544': 37.5849,
    '830_545': 37.6179,
    '830_546': 37.6492,
    '830_547': 37.7122,
    '830_548': 37.7973,
    '830_549': 37.8739,
    '830_550': 37.943,
    '830_551': 38.015,
    '830_552': 38.1216,
    '830_553': 38.259,
    '830_554': 38.3808,
    '830_555': 38.4883,
    '830_556': 38.5947,
    '830_557': 38.6894,
    '830_558': 38.7757,
    '830_559': 38.8461,
    '830_560': 38.9007,
    '830_561': 38.9383,
    '830_562': 38.9482,
    '830_563': 38.95,
    '830_564': 38.9291,
    '830_565': 38.8994,
    '830_566': 38.8436,
    '830_567': 38.7683,
    '830_568': 38.676,
    '830_569': 38.5786,
    '830_570': 38.4718,
    '830_571': 38.3642,
    '830_572': 38.2597,
    '830_573': 38.1711,
    '830_574': 38.0689,
    '830_575': 37.9507,
    '830_576': 37.8179,
    '830_577': 37.6858,
    '830_578': 37.55,
    '830_579': 37.4211,
    '830_580': 37.3118,
    '830_581': 37.2186,
    '830_582': 37.1164,
    '830_583': 37.0203,
    '830_584': 36.9323,
    '830_585': 36.8597,
    '830_586': 36.8023,
    '830_587': 36.7531,
    '830_588': 36.7063,
    '830_589': 36.6661,
    '830_590': 36.6379,
    '830_591': 36.6225,
    '830_592': 36.6191,
    '830_593': 36.6269,
    '830_594': 36.6452,
    '830_595': 36.6705,
    '830_596': 36.7101,
    '830_597': 36.7495,
    '830_598': 36.7976,
    '830_599': 36.8571,
    '830_600': 36.9285,
    '830_601': 37.0106,
    '830_602': 37.1025,
    '830_603': 37.2036,
    '830_604': 37.314,
    '830_605': 37.4341,
    '830_606': 37.563,
    '830_607': 37.7063,
    '830_608': 37.8616,
    '830_609': 38.0176,
    '830_610': 38.1812,
    '830_611': 38.3462,
    '830_612': 38.5164,
    '830_613': 38.6749,
    '830_614': 38.8367,
    '830_615': 39.0107,
    '830_616': 39.1878,
    '830_617': 39.3614,
    '830_618': 39.5241,
    '830_619': 39.671,
    '830_620': 39.8013,
    '830_621': 39.9268,
    '830_622': 40.0564,
    '830_623': 40.1797,
    '830_624': 40.2924,
    '830_625': 40.3818,
    '830_626': 40.454,
    '830_627': 40.4933,
    '830_628': 40.4952,
    '830_629': 40.4726,
    '830_630': 40.4412,
    '830_631': 40.4143,
    '830_632': 40.394,
    '830_633': 40.3698,
    '830_634': 40.3261,
    '830_635': 40.2768,
    '830_636': 40.226,
    '830_637': 40.162,
    '830_638': 40.0523,
    '830_639': 39.9009,
    '830_640': 39.7349,
    '830_641': 39.5568,
    '830_642': 39.3704,
    '830_643': 39.1726,
    '830_644': 38.9678,
    '830_645': 38.7568,
    '830_646': 38.5379,
    '830_647': 38.3128,
    '830_648': 38.0792,
    '830_649': 37.8398,
    '830_650': 37.5944,
    '830_651': 37.3456,
    '830_652': 37.0997,
    '830_653': 36.862,
    '830_654': 36.636,
    '830_655': 36.4341,
    '830_656': 36.2526,
    '830_657': 36.0829,
    '830_658': 35.9337,
    '830_659': 35.8017,
    '830_660': 35.6541,
    '830_782': 40.795,
    '830_783': 40.8792,
    '830_784': 40.9431,
    '830_785': 40.9711,
    '830_786': 40.9719,
    '831_383': 31.1826,
    '831_384': 31.2095,
    '831_385': 31.2434,
    '831_386': 31.275,
    '831_387': 31.3061,
    '831_388': 31.3369,
    '831_389': 31.3607,
    '831_390': 31.3786,
    '831_391': 31.3914,
    '831_392': 31.3993,
    '831_393': 31.414,
    '831_394': 31.4323,
    '831_401': 31.7519,
    '831_402': 31.8004,
    '831_408': 31.9246,
    '831_409': 31.9741,
    '831_410': 32.0194,
    '831_411': 32.0609,
    '831_412': 32.1011,
    '831_413': 32.1335,
    '831_414': 32.1841,
    '831_415': 32.2219,
    '831_416': 32.2532,
    '831_417': 32.2815,
    '831_418': 32.3061,
    '831_419': 32.3374,
    '831_420': 32.372,
    '831_421': 32.4045,
    '831_422': 32.4443,
    '831_423': 32.4814,
    '831_424': 32.4841,
    '831_425': 32.4866,
    '831_426': 32.4895,
    '831_427': 32.4876,
    '831_428': 32.4923,
    '831_429': 32.5113,
    '831_430': 32.5519,
    '831_431': 32.6139,
    '831_432': 32.6609,
    '831_433': 32.669,
    '831_434': 32.6646,
    '831_435': 32.6584,
    '831_436': 32.6667,
    '831_437': 32.6802,
    '831_438': 32.6687,
    '831_439': 32.661,
    '831_440': 32.645,
    '831_441': 32.6311,
    '831_442': 32.6159,
    '831_443': 32.5985,
    '831_444': 32.5788,
    '831_445': 32.5557,
    '831_446': 32.5237,
    '831_447': 32.4988,
    '831_448': 32.4802,
    '831_449': 32.463,
    '831_450': 32.4383,
    '831_451': 32.4032,
    '831_452': 32.3639,
    '831_453': 32.3256,
    '831_454': 32.2884,
    '831_455': 32.2509,
    '831_456': 32.2156,
    '831_457': 32.1834,
    '831_458': 32.1589,
    '831_459': 32.1394,
    '831_460': 32.1204,
    '831_461': 32.0996,
    '831_462': 32.0764,
    '831_463': 32.0504,
    '831_464': 32.0231,
    '831_465': 31.9962,
    '831_466': 31.9695,
    '831_467': 31.9449,
    '831_468': 31.9219,
    '831_469': 31.9,
    '831_470': 31.8791,
    '831_471': 31.8592,
    '831_472': 31.8407,
    '831_473': 31.8246,
    '831_474': 31.8147,
    '831_475': 31.815,
    '831_476': 31.8197,
    '831_477': 31.8268,
    '831_478': 31.8344,
    '831_479': 31.8394,
    '831_480': 31.8426,
    '831_481': 31.8365,
    '831_482': 31.8273,
    '831_483': 31.8206,
    '831_484': 31.8164,
    '831_485': 31.8185,
    '831_486': 31.8095,
    '831_487': 31.7834,
    '831_488': 31.7777,
    '831_489': 31.7769,
    '831_490': 31.7784,
    '831_491': 31.7783,
    '831_492': 31.7799,
    '831_493': 31.792,
    '831_494': 31.8134,
    '831_495': 31.8405,
    '831_496': 31.8733,
    '831_497': 31.9103,
    '831_498': 31.955,
    '831_499': 32.0136,
    '831_500': 32.0926,
    '831_501': 32.1943,
    '831_502': 32.3079,
    '831_503': 32.4211,
    '831_504': 32.5115,
    '831_505': 32.6129,
    '831_506': 32.7415,
    '831_507': 32.8829,
    '831_508': 33.034,
    '831_509': 33.1921,
    '831_510': 33.3585,
    '831_511': 33.5447,
    '831_512': 33.7459,
    '831_513': 33.9656,
    '831_514': 34.1914,
    '831_515': 34.403,
    '831_516': 34.5911,
    '831_517': 34.78,
    '831_518': 34.9438,
    '831_519': 35.0695,
    '831_520': 35.1605,
    '831_521': 35.2629,
    '831_522': 35.3864,
    '831_523': 35.5151,
    '831_524': 35.6378,
    '831_525': 35.7576,
    '831_526': 35.8767,
    '831_527': 36.0202,
    '831_528': 36.1497,
    '831_529': 36.2919,
    '831_530': 36.4379,
    '831_531': 36.5551,
    '831_532': 36.6664,
    '831_533': 36.7649,
    '831_534': 36.8698,
    '831_535': 36.9544,
    '831_536': 37.026,
    '831_537': 37.0847,
    '831_538': 37.145,
    '831_539': 37.2122,
    '831_540': 37.2907,
    '831_541': 37.37,
    '831_542': 37.4453,
    '831_543': 37.5111,
    '831_544': 37.5678,
    '831_545': 37.5993,
    '831_546': 37.6325,
    '831_547': 37.7016,
    '831_548': 37.7793,
    '831_549': 37.8508,
    '831_550': 37.9193,
    '831_551': 37.9886,
    '831_552': 38.0969,
    '831_553': 38.2278,
    '831_554': 38.3465,
    '831_555': 38.4479,
    '831_556': 38.5526,
    '831_557': 38.6597,
    '831_558': 38.757,
    '831_559': 38.8258,
    '831_560': 38.8771,
    '831_561': 38.9229,
    '831_562': 38.9388,
    '831_563': 38.9338,
    '831_564': 38.9038,
    '831_565': 38.8753,
    '831_566': 38.8309,
    '831_567': 38.7631,
    '831_568': 38.6782,
    '831_569': 38.5846,
    '831_570': 38.4884,
    '831_571': 38.3886,
    '831_572': 38.3029,
    '831_573': 38.2163,
    '831_574': 38.1103,
    '831_575': 37.9873,
    '831_576': 37.8528,
    '831_577': 37.7198,
    '831_578': 37.5825,
    '831_579': 37.4424,
    '831_580': 37.3199,
    '831_581': 37.2242,
    '831_582': 37.1257,
    '831_583': 37.0255,
    '831_584': 36.9369,
    '831_585': 36.8633,
    '831_586': 36.804,
    '831_587': 36.7562,
    '831_588': 36.717,
    '831_589': 36.6843,
    '831_590': 36.6618,
    '831_591': 36.6499,
    '831_592': 36.6496,
    '831_593': 36.661,
    '831_594': 36.682,
    '831_595': 36.71,
    '831_596': 36.7521,
    '831_597': 36.7947,
    '831_598': 36.8457,
    '831_599': 36.909,
    '831_600': 36.9835,
    '831_601': 37.0681,
    '831_602': 37.1625,
    '831_603': 37.266,
    '831_604': 37.3782,
    '831_605': 37.4984,
    '831_606': 37.6299,
    '831_607': 37.7761,
    '831_608': 37.9308,
    '831_609': 38.0872,
    '831_610': 38.2505,
    '831_611': 38.4135,
    '831_612': 38.5853,
    '831_613': 38.7512,
    '831_614': 38.9147,
    '831_615': 39.0862,
    '831_616': 39.2606,
    '831_617': 39.4333,
    '831_618': 39.5969,
    '831_619': 39.7499,
    '831_620': 39.8865,
    '831_621': 40.0135,
    '831_622': 40.1367,
    '831_623': 40.2527,
    '831_624': 40.3608,
    '831_625': 40.451,
    '831_626': 40.5154,
    '831_627': 40.5497,
    '831_628': 40.5506,
    '831_629': 40.5272,
    '831_630': 40.4947,
    '831_631': 40.4686,
    '831_632': 40.4551,
    '831_633': 40.4287,
    '831_634': 40.3847,
    '831_635': 40.3301,
    '831_636': 40.2772,
    '831_637': 40.2186,
    '831_638': 40.115,
    '831_639': 39.9855,
    '831_640': 39.8341,
    '831_641': 39.6665,
    '831_642': 39.4875,
    '831_643': 39.2983,
    '831_644': 39.1006,
    '831_645': 38.8969,
    '831_646': 38.6866,
    '831_647': 38.4706,
    '831_648': 38.2489,
    '831_649': 38.023,
    '831_650': 37.7929,
    '831_651': 37.5551,
    '831_652': 37.3129,
    '831_653': 37.0734,
    '831_654': 36.8441,
    '831_655': 36.6311,
    '831_656': 36.4344,
    '831_657': 36.258,
    '831_658': 36.1003,
    '831_659': 35.9685,
    '831_660': 35.8365,
    '831_782': 40.8422,
    '831_783': 40.9434,
    '831_784': 41.0211,
    '831_785': 41.0286,
    '831_786': 40.996,
    '832_383': 31.1606,
    '832_384': 31.1868,
    '832_385': 31.2163,
    '832_386': 31.2511,
    '832_387': 31.2817,
    '832_388': 31.3107,
    '832_389': 31.3349,
    '832_390': 31.3557,
    '832_391': 31.373,
    '832_392': 31.384,
    '832_393': 31.4,
    '832_394': 31.4192,
    '832_401': 31.7313,
    '832_402': 31.7786,
    '832_408': 31.8915,
    '832_409': 31.9439,
    '832_410': 31.9909,
    '832_411': 32.0338,
    '832_412': 32.0729,
    '832_413': 32.1119,
    '832_414': 32.1546,
    '832_415': 32.1906,
    '832_416': 32.2218,
    '832_417': 32.2514,
    '832_418': 32.276,
    '832_419': 32.3069,
    '832_420': 32.3405,
    '832_421': 32.3777,
    '832_422': 32.4249,
    '832_423': 32.442,
    '832_424': 32.4437,
    '832_425': 32.4474,
    '832_426': 32.4511,
    '832_427': 32.4541,
    '832_428': 32.462,
    '832_429': 32.4798,
    '832_430': 32.5151,
    '832_431': 32.5585,
    '832_432': 32.5937,
    '832_433': 32.6102,
    '832_434': 32.6153,
    '832_435': 32.6163,
    '832_436': 32.636,
    '832_437': 32.6706,
    '832_438': 32.6676,
    '832_439': 32.6493,
    '832_440': 32.633,
    '832_441': 32.6202,
    '832_442': 32.6068,
    '832_443': 32.5912,
    '832_444': 32.5724,
    '832_445': 32.5484,
    '832_446': 32.5195,
    '832_447': 32.4996,
    '832_448': 32.4879,
    '832_449': 32.4601,
    '832_450': 32.4271,
    '832_451': 32.3947,
    '832_452': 32.3576,
    '832_453': 32.3217,
    '832_454': 32.2885,
    '832_455': 32.2558,
    '832_456': 32.2251,
    '832_457': 32.1946,
    '832_458': 32.1715,
    '832_459': 32.1544,
    '832_460': 32.1366,
    '832_461': 32.118,
    '832_462': 32.0982,
    '832_463': 32.0742,
    '832_464': 32.0475,
    '832_465': 32.0212,
    '832_466': 31.9959,
    '832_467': 31.9729,
    '832_468': 31.9526,
    '832_469': 31.934,
    '832_470': 31.917,
    '832_471': 31.9009,
    '832_472': 31.8851,
    '832_473': 31.8714,
    '832_474': 31.865,
    '832_475': 31.8645,
    '832_476': 31.8677,
    '832_477': 31.8731,
    '832_478': 31.8775,
    '832_479': 31.8829,
    '832_480': 31.886,
    '832_481': 31.8801,
    '832_482': 31.8711,
    '832_483': 31.8642,
    '832_484': 31.8595,
    '832_485': 31.8609,
    '832_486': 31.8488,
    '832_487': 31.8312,
    '832_488': 31.8306,
    '832_489': 31.8346,
    '832_490': 31.8326,
    '832_491': 31.8283,
    '832_492': 31.8297,
    '832_493': 31.8439,
    '832_494': 31.8659,
    '832_495': 31.8821,
    '832_496': 31.9079,
    '832_497': 31.9405,
    '832_498': 31.9818,
    '832_499': 32.0372,
    '832_500': 32.1144,
    '832_501': 32.2106,
    '832_502': 32.318,
    '832_503': 32.4237,
    '832_504': 32.5239,
    '832_505': 32.6311,
    '832_506': 32.7562,
    '832_507': 32.8955,
    '832_508': 33.0392,
    '832_509': 33.1922,
    '832_510': 33.3568,
    '832_511': 33.5471,
    '832_512': 33.7488,
    '832_513': 33.9616,
    '832_514': 34.1874,
    '832_515': 34.3989,
    '832_516': 34.5865,
    '832_517': 34.7602,
    '832_518': 34.9161,
    '832_519': 35.0277,
    '832_520': 35.0955,
    '832_521': 35.1586,
    '832_522': 35.2482,
    '832_523': 35.3602,
    '832_524': 35.4816,
    '832_525': 35.6006,
    '832_526': 35.7137,
    '832_527': 35.847,
    '832_528': 35.9967,
    '832_529': 36.1559,
    '832_530': 36.3023,
    '832_531': 36.4288,
    '832_532': 36.542,
    '832_533': 36.6659,
    '832_534': 36.7846,
    '832_535': 36.8782,
    '832_536': 36.9614,
    '832_537': 37.0198,
    '832_538': 37.0717,
    '832_539': 37.1302,
    '832_540': 37.2174,
    '832_541': 37.3114,
    '832_542': 37.3938,
    '832_543': 37.462,
    '832_544': 37.5197,
    '832_545': 37.5598,
    '832_546': 37.6081,
    '832_547': 37.6759,
    '832_548': 37.7461,
    '832_549': 37.8091,
    '832_550': 37.8726,
    '832_551': 37.9487,
    '832_552': 38.0626,
    '832_553': 38.184,
    '832_554': 38.2977,
    '832_555': 38.4024,
    '832_556': 38.5096,
    '832_557': 38.6108,
    '832_558': 38.7036,
    '832_559': 38.7851,
    '832_560': 38.8392,
    '832_561': 38.8668,
    '832_562': 38.8816,
    '832_563': 38.8882,
    '832_564': 38.8747,
    '832_565': 38.8438,
    '832_566': 38.782,
    '832_567': 38.7175,
    '832_568': 38.6527,
    '832_569': 38.5732,
    '832_570': 38.4929,
    '832_571': 38.4048,
    '832_572': 38.3239,
    '832_573': 38.2396,
    '832_574': 38.1318,
    '832_575': 38.001,
    '832_576': 37.8644,
    '832_577': 37.7346,
    '832_578': 37.6097,
    '832_579': 37.474,
    '832_580': 37.3401,
    '832_581': 37.2418,
    '832_582': 37.1364,
    '832_583': 37.0337,
    '832_584': 36.9429,
    '832_585': 36.8682,
    '832_586': 36.8075,
    '832_587': 36.7603,
    '832_588': 36.7245,
    '832_589': 36.6984,
    '832_590': 36.6819,
    '832_591': 36.6746,
    '832_592': 36.6771,
    '832_593': 36.6905,
    '832_594': 36.7146,
    '832_595': 36.7451,
    '832_596': 36.7896,
    '832_597': 36.8341,
    '832_598': 36.8863,
    '832_599': 36.9543,
    '832_600': 37.0323,
    '832_601': 37.1192,
    '832_602': 37.2159,
    '832_603': 37.3221,
    '832_604': 37.4357,
    '832_605': 37.5557,
    '832_606': 37.6866,
    '832_607': 37.8317,
    '832_608': 37.9864,
    '832_609': 38.1474,
    '832_610': 38.3144,
    '832_611': 38.4814,
    '832_612': 38.6523,
    '832_613': 38.825,
    '832_614': 38.9934,
    '832_615': 39.1675,
    '832_616': 39.3379,
    '832_617': 39.5027,
    '832_618': 39.6591,
    '832_619': 39.8152,
    '832_620': 39.9619,
    '832_621': 40.0945,
    '832_622': 40.216,
    '832_623': 40.3285,
    '832_624': 40.4291,
    '832_625': 40.5178,
    '832_626': 40.5783,
    '832_627': 40.6098,
    '832_628': 40.6057,
    '832_629': 40.5859,
    '832_630': 40.5598,
    '832_631': 40.5385,
    '832_632': 40.5319,
    '832_633': 40.5073,
    '832_634': 40.458,
    '832_635': 40.3894,
    '832_636': 40.3293,
    '832_637': 40.2679,
    '832_638': 40.1791,
    '832_639': 40.0828,
    '832_640': 39.9517,
    '832_641': 39.7857,
    '832_642': 39.6119,
    '832_643': 39.4278,
    '832_644': 39.2344,
    '832_645': 39.036,
    '832_646': 38.8325,
    '832_647': 38.6244,
    '832_648': 38.4126,
    '832_649': 38.1982,
    '832_650': 37.9812,
    '832_651': 37.759,
    '832_652': 37.5247,
    '832_653': 37.2877,
    '832_654': 37.0559,
    '832_655': 36.8355,
    '832_656': 36.6304,
    '832_657': 36.4487,
    '832_658': 36.2908,
    '832_659': 36.153,
    '832_660': 36.0189,
    '832_782': 40.8819,
    '832_783': 41.002,
    '832_784': 41.0889,
    '832_785': 41.0795,
    '832_786': 41.006,
    '833_383': 31.1355,
    '833_384': 31.1619,
    '833_385': 31.1903,
    '833_386': 31.2246,
    '833_387': 31.2554,
    '833_388': 31.2843,
    '833_389': 31.307,
    '833_390': 31.331,
    '833_391': 31.3522,
    '833_392': 31.3702,
    '833_393': 31.3892,
    '833_394': 31.407,
    '833_401': 31.7063,
    '833_402': 31.7531,
    '833_408': 31.8627,
    '833_409': 31.9136,
    '833_410': 31.9606,
    '833_411': 32.0047,
    '833_412': 32.0415,
    '833_413': 32.0852,
    '833_414': 32.122,
    '833_415': 32.1507,
    '833_416': 32.1815,
    '833_417': 32.2135,
    '833_418': 32.2417,
    '833_419': 32.2732,
    '833_420': 32.3074,
    '833_421': 32.3443,
    '833_422': 32.3836,
    '833_423': 32.3958,
    '833_424': 32.4017,
    '833_425': 32.4064,
    '833_426': 32.4111,
    '833_427': 32.4169,
    '833_428': 32.4282,
    '833_429': 32.4478,
    '833_430': 32.4834,
    '833_431': 32.5174,
    '833_432': 32.5395,
    '833_433': 32.557,
    '833_434': 32.5652,
    '833_435': 32.5718,
    '833_436': 32.5973,
    '833_437': 32.6345,
    '833_438': 32.6544,
    '833_439': 32.6368,
    '833_440': 32.6201,
    '833_441': 32.6088,
    '833_442': 32.5977,
    '833_443': 32.5849,
    '833_444': 32.5695,
    '833_445': 32.5522,
    '833_446': 32.5311,
    '833_447': 32.5095,
    '833_448': 32.4779,
    '833_449': 32.4501,
    '833_450': 32.4222,
    '833_451': 32.3929,
    '833_452': 32.3606,
    '833_453': 32.3281,
    '833_454': 32.2968,
    '833_455': 32.2667,
    '833_456': 32.2381,
    '833_457': 32.2111,
    '833_458': 32.1893,
    '833_459': 32.1737,
    '833_460': 32.156,
    '833_461': 32.1387,
    '833_462': 32.1229,
    '833_463': 32.1008,
    '833_464': 32.0746,
    '833_465': 32.05,
    '833_466': 32.0265,
    '833_467': 32.0055,
    '833_468': 31.9876,
    '833_469': 31.9723,
    '833_470': 31.9595,
    '833_471': 31.9476,
    '833_472': 31.9337,
    '833_473': 31.9193,
    '833_474': 31.9146,
    '833_475': 31.9143,
    '833_476': 31.9161,
    '833_477': 31.9191,
    '833_478': 31.921,
    '833_479': 31.9241,
    '833_480': 31.9249,
    '833_481': 31.9204,
    '833_482': 31.9132,
    '833_483': 31.9084,
    '833_484': 31.9085,
    '833_485': 31.8999,
    '833_486': 31.8838,
    '833_487': 31.8787,
    '833_488': 31.8913,
    '833_489': 31.911,
    '833_490': 31.9058,
    '833_491': 31.8848,
    '833_492': 31.8786,
    '833_493': 31.8886,
    '833_494': 31.9119,
    '833_495': 31.9198,
    '833_496': 31.9433,
    '833_497': 31.9737,
    '833_498': 32.0134,
    '833_499': 32.0684,
    '833_500': 32.1424,
    '833_501': 32.2327,
    '833_502': 32.3325,
    '833_503': 32.4352,
    '833_504': 32.5392,
    '833_505': 32.6484,
    '833_506': 32.7756,
    '833_507': 32.9128,
    '833_508': 33.0501,
    '833_509': 33.1994,
    '833_510': 33.359,
    '833_511': 33.5512,
    '833_512': 33.7553,
    '833_513': 33.9641,
    '833_514': 34.1756,
    '833_515': 34.3847,
    '833_516': 34.5813,
    '833_517': 34.7556,
    '833_518': 34.9015,
    '833_519': 34.9977,
    '833_520': 35.048,
    '833_521': 35.0827,
    '833_522': 35.1356,
    '833_523': 35.2209,
    '833_524': 35.3273,
    '833_525': 35.4376,
    '833_526': 35.5557,
    '833_527': 35.6818,
    '833_528': 35.8331,
    '833_529': 35.9979,
    '833_530': 36.1486,
    '833_531': 36.2771,
    '833_532': 36.3985,
    '833_533': 36.5411,
    '833_534': 36.6695,
    '833_535': 36.7885,
    '833_536': 36.8629,
    '833_537': 36.9292,
    '833_538': 36.9879,
    '833_539': 37.0414,
    '833_540': 37.1222,
    '833_541': 37.2209,
    '833_542': 37.3075,
    '833_543': 37.3849,
    '833_544': 37.4434,
    '833_545': 37.4989,
    '833_546': 37.5582,
    '833_547': 37.6294,
    '833_548': 37.6973,
    '833_549': 37.7581,
    '833_550': 37.821,
    '833_551': 37.9116,
    '833_552': 38.0228,
    '833_553': 38.1296,
    '833_554': 38.2473,
    '833_555': 38.3645,
    '833_556': 38.476,
    '833_557': 38.575,
    '833_558': 38.6596,
    '833_559': 38.7307,
    '833_560': 38.7825,
    '833_561': 38.8082,
    '833_562': 38.8178,
    '833_563': 38.8159,
    '833_564': 38.793,
    '833_565': 38.7679,
    '833_566': 38.7206,
    '833_567': 38.6591,
    '833_568': 38.5991,
    '833_569': 38.5413,
    '833_570': 38.4712,
    '833_571': 38.4036,
    '833_572': 38.329,
    '833_573': 38.2371,
    '833_574': 38.1288,
    '833_575': 37.9938,
    '833_576': 37.8523,
    '833_577': 37.7271,
    '833_578': 37.6159,
    '833_579': 37.4862,
    '833_580': 37.348,
    '833_581': 37.247,
    '833_582': 37.1424,
    '833_583': 37.0507,
    '833_584': 36.9566,
    '833_585': 36.8751,
    '833_586': 36.8148,
    '833_587': 36.7655,
    '833_588': 36.7331,
    '833_589': 36.712,
    '833_590': 36.7006,
    '833_591': 36.6973,
    '833_592': 36.7013,
    '833_593': 36.7164,
    '833_594': 36.7432,
    '833_595': 36.7765,
    '833_596': 36.8238,
    '833_597': 36.8708,
    '833_598': 36.9214,
    '833_599': 36.9954,
    '833_600': 37.0752,
    '833_601': 37.1618,
    '833_602': 37.2607,
    '833_603': 37.3712,
    '833_604': 37.4872,
    '833_605': 37.6063,
    '833_606': 37.7351,
    '833_607': 37.8793,
    '833_608': 38.0355,
    '833_609': 38.1984,
    '833_610': 38.3708,
    '833_611': 38.5481,
    '833_612': 38.7172,
    '833_613': 38.8902,
    '833_614': 39.0662,
    '833_615': 39.2467,
    '833_616': 39.4144,
    '833_617': 39.5652,
    '833_618': 39.7118,
    '833_619': 39.8665,
    '833_620': 40.0146,
    '833_621': 40.1519,
    '833_622': 40.2749,
    '833_623': 40.3892,
    '833_624': 40.4916,
    '833_625': 40.5803,
    '833_626': 40.6401,
    '833_627': 40.671,
    '833_628': 40.6728,
    '833_629': 40.6622,
    '833_630': 40.6389,
    '833_631': 40.6194,
    '833_632': 40.6195,
    '833_633': 40.596,
    '833_634': 40.5467,
    '833_635': 40.4681,
    '833_636': 40.3959,
    '833_637': 40.3352,
    '833_638': 40.2564,
    '833_639': 40.1772,
    '833_640': 40.0589,
    '833_641': 39.915,
    '833_642': 39.7537,
    '833_643': 39.5674,
    '833_644': 39.3721,
    '833_645': 39.1779,
    '833_646': 38.9785,
    '833_647': 38.7757,
    '833_648': 38.5714,
    '833_649': 38.3651,
    '833_650': 38.1571,
    '833_651': 37.9454,
    '833_652': 37.726,
    '833_653': 37.4958,
    '833_654': 37.2659,
    '833_655': 37.0428,
    '833_656': 36.8332,
    '833_657': 36.6465,
    '833_658': 36.4887,
    '833_659': 36.3476,
    '833_660': 36.2108,
    '833_782': 40.8907,
    '833_783': 41.0065,
    '833_784': 41.0852,
    '833_785': 41.0593,
    '833_786': 40.9839,
    '834_383': 31.1115,
    '834_384': 31.1376,
    '834_385': 31.1648,
    '834_386': 31.195,
    '834_387': 31.2262,
    '834_388': 31.2567,
    '834_389': 31.2853,
    '834_390': 31.313,
    '834_391': 31.3378,
    '834_392': 31.3578,
    '834_393': 31.3831,
    '834_394': 31.3943,
    '834_408': 31.8291,
    '834_409': 31.8775,
    '834_410': 31.9235,
    '834_411': 31.9668,
    '834_412': 32.0067,
    '834_413': 32.0476,
    '834_414': 32.0838,
    '834_415': 32.1112,
    '834_416': 32.143,
    '834_417': 32.1786,
    '834_418': 32.2058,
    '834_419': 32.2377,
    '834_420': 32.2726,
    '834_421': 32.3058,
    '834_422': 32.336,
    '834_423': 32.3532,
    '834_424': 32.3633,
    '834_425': 32.3689,
    '834_426': 32.3737,
    '834_427': 32.3817,
    '834_428': 32.3956,
    '834_429': 32.4197,
    '834_430': 32.4619,
    '834_431': 32.4934,
    '834_432': 32.5012,
    '834_433': 32.5075,
    '834_434': 32.5172,
    '834_435': 32.5301,
    '834_436': 32.5576,
    '834_437': 32.5907,
    '834_438': 32.6194,
    '834_439': 32.6178,
    '834_440': 32.6076,
    '834_441': 32.5983,
    '834_442': 32.5902,
    '834_443': 32.5806,
    '834_444': 32.5692,
    '834_445': 32.5585,
    '834_446': 32.5407,
    '834_447': 32.5142,
    '834_448': 32.4825,
    '834_449': 32.454,
    '834_450': 32.4274,
    '834_451': 32.4004,
    '834_452': 32.3711,
    '834_453': 32.3409,
    '834_454': 32.3113,
    '834_455': 32.2827,
    '834_456': 32.2559,
    '834_457': 32.2316,
    '834_458': 32.2114,
    '834_459': 32.1949,
    '834_460': 32.1787,
    '834_461': 32.1632,
    '834_462': 32.1478,
    '834_463': 32.1287,
    '834_464': 32.1072,
    '834_465': 32.0861,
    '834_466': 32.0656,
    '834_467': 32.047,
    '834_468': 32.0308,
    '834_469': 32.0179,
    '834_470': 32.0094,
    '834_471': 32.0021,
    '834_472': 31.9911,
    '834_473': 31.976,
    '834_474': 31.9703,
    '834_475': 31.9682,
    '834_476': 31.9672,
    '834_477': 31.9674,
    '834_478': 31.968,
    '834_479': 31.9679,
    '834_480': 31.9684,
    '834_481': 31.965,
    '834_482': 31.9564,
    '834_483': 31.9557,
    '834_484': 31.9629,
    '834_485': 31.9408,
    '834_486': 31.924,
    '834_487': 31.9274,
    '834_488': 31.9548,
    '834_489': 31.9758,
    '834_490': 31.9805,
    '834_491': 31.9486,
    '834_492': 31.9312,
    '834_493': 31.9321,
    '834_494': 31.9463,
    '834_495': 31.9595,
    '834_496': 31.9802,
    '834_497': 32.0104,
    '834_498': 32.0464,
    '834_499': 32.0964,
    '834_500': 32.1693,
    '834_501': 32.2577,
    '834_502': 32.3541,
    '834_503': 32.4548,
    '834_504': 32.5597,
    '834_505': 32.6691,
    '834_506': 32.7943,
    '834_507': 32.9257,
    '834_508': 33.0613,
    '834_509': 33.2046,
    '834_510': 33.3629,
    '834_511': 33.5523,
    '834_512': 33.7611,
    '834_513': 33.9691,
    '834_514': 34.1723,
    '834_515': 34.369,
    '834_516': 34.5659,
    '834_517': 34.7383,
    '834_518': 34.8756,
    '834_519': 34.9656,
    '834_520': 35.0097,
    '834_521': 35.0276,
    '834_522': 35.0502,
    '834_523': 35.1123,
    '834_524': 35.1994,
    '834_525': 35.2995,
    '834_526': 35.4116,
    '834_527': 35.5341,
    '834_528': 35.6725,
    '834_529': 35.8247,
    '834_530': 35.9714,
    '834_531': 36.1029,
    '834_532': 36.2329,
    '834_533': 36.3774,
    '834_534': 36.52,
    '834_535': 36.6382,
    '834_536': 36.7369,
    '834_537': 36.8159,
    '834_538': 36.8854,
    '834_539': 36.9461,
    '834_540': 37.0153,
    '834_541': 37.1083,
    '834_542': 37.2019,
    '834_543': 37.2751,
    '834_544': 37.343,
    '834_545': 37.4104,
    '834_546': 37.4841,
    '834_547': 37.5674,
    '834_548': 37.6404,
    '834_549': 37.7041,
    '834_550': 37.7728,
    '834_551': 37.8734,
    '834_552': 37.9802,
    '834_553': 38.082,
    '834_554': 38.2033,
    '834_555': 38.3235,
    '834_556': 38.4367,
    '834_557': 38.5436,
    '834_558': 38.6267,
    '834_559': 38.6856,
    '834_560': 38.7214,
    '834_561': 38.7296,
    '834_562': 38.7238,
    '834_563': 38.7151,
    '834_564': 38.7082,
    '834_565': 38.6775,
    '834_566': 38.6319,
    '834_567': 38.5815,
    '834_568': 38.534,
    '834_569': 38.4889,
    '834_570': 38.4319,
    '834_571': 38.3714,
    '834_572': 38.2984,
    '834_573': 38.2178,
    '834_574': 38.1117,
    '834_575': 37.9794,
    '834_576': 37.8355,
    '834_577': 37.7065,
    '834_578': 37.6014,
    '834_579': 37.4822,
    '834_580': 37.3513,
    '834_581': 37.2372,
    '834_582': 37.1396,
    '834_583': 37.0551,
    '834_584': 36.9636,
    '834_585': 36.8804,
    '834_586': 36.8243,
    '834_587': 36.7732,
    '834_588': 36.7408,
    '834_589': 36.7247,
    '834_590': 36.7174,
    '834_591': 36.7178,
    '834_592': 36.7247,
    '834_593': 36.741,
    '834_594': 36.77,
    '834_595': 36.806,
    '834_596': 36.8567,
    '834_597': 36.909,
    '834_598': 36.9669,
    '834_599': 37.0362,
    '834_600': 37.1148,
    '834_601': 37.2007,
    '834_602': 37.2993,
    '834_603': 37.4127,
    '834_604': 37.5383,
    '834_605': 37.6569,
    '834_606': 37.7806,
    '834_607': 37.9241,
    '834_608': 38.0814,
    '834_609': 38.2505,
    '834_610': 38.4244,
    '834_611': 38.5998,
    '834_612': 38.7751,
    '834_613': 38.9511,
    '834_614': 39.1302,
    '834_615': 39.3087,
    '834_616': 39.4737,
    '834_617': 39.6194,
    '834_618': 39.7649,
    '834_619': 39.9194,
    '834_620': 40.0616,
    '834_621': 40.1978,
    '834_622': 40.3266,
    '834_623': 40.443,
    '834_624': 40.5475,
    '834_625': 40.6331,
    '834_626': 40.6914,
    '834_627': 40.7226,
    '834_628': 40.7318,
    '834_629': 40.7281,
    '834_630': 40.7127,
    '834_631': 40.6936,
    '834_632': 40.7007,
    '834_633': 40.6856,
    '834_634': 40.6349,
    '834_635': 40.5535,
    '834_636': 40.4829,
    '834_637': 40.414,
    '834_638': 40.3315,
    '834_639': 40.2562,
    '834_640': 40.1523,
    '834_641': 40.0313,
    '834_642': 39.8859,
    '834_643': 39.7065,
    '834_644': 39.5221,
    '834_645': 39.3291,
    '834_646': 39.1283,
    '834_647': 38.9288,
    '834_648': 38.7289,
    '834_649': 38.5274,
    '834_650': 38.3251,
    '834_651': 38.1211,
    '834_652': 37.9127,
    '834_653': 37.6952,
    '834_654': 37.4723,
    '834_655': 37.2506,
    '834_656': 37.0401,
    '834_657': 36.8543,
    '834_658': 36.6917,
    '834_659': 36.543,
    '834_660': 36.3971,
    '834_782': 40.8716,
    '834_783': 40.9597,
    '834_784': 41.0181,
    '834_785': 40.9902,
    '834_786': 40.9342,
    '835_408': 31.7918,
    '835_409': 31.8369,
    '835_410': 31.8809,
    '835_411': 31.9233,
    '835_412': 31.9642,
    '835_413': 32.0038,
    '835_414': 32.0403,
    '835_415': 32.0685,
    '835_416': 32.1003,
    '835_417': 32.1349,
    '835_418': 32.1662,
    '835_419': 32.1989,
    '835_420': 32.2333,
    '835_421': 32.2709,
    '835_422': 32.2988,
    '835_423': 32.3189,
    '835_424': 32.3328,
    '835_425': 32.3366,
    '835_426': 32.342,
    '835_427': 32.3504,
    '835_428': 32.3652,
    '835_429': 32.3917,
    '835_430': 32.4291,
    '835_431': 32.4519,
    '835_432': 32.4579,
    '835_433': 32.4633,
    '835_434': 32.4739,
    '835_435': 32.4925,
    '835_436': 32.5253,
    '835_437': 32.5577,
    '835_438': 32.5877,
    '835_439': 32.5973,
    '835_440': 32.5921,
    '835_441': 32.5852,
    '835_442': 32.5812,
    '835_443': 32.5759,
    '835_444': 32.5689,
    '835_445': 32.5567,
    '835_446': 32.539,
    '835_447': 32.5141,
    '835_448': 32.4873,
    '835_449': 32.461,
    '835_450': 32.4366,
    '835_451': 32.4123,
    '835_452': 32.3844,
    '835_453': 32.3559,
    '835_454': 32.3274,
    '835_455': 32.2999,
    '835_456': 32.2745,
    '835_457': 32.252,
    '835_458': 32.2333,
    '835_459': 32.2172,
    '835_460': 32.2025,
    '835_461': 32.1889,
    '835_462': 32.175,
    '835_463': 32.1593,
    '835_464': 32.1425,
    '835_465': 32.1258,
    '835_466': 32.1095,
    '835_467': 32.0948,
    '835_468': 32.0825,
    '835_469': 32.0728,
    '835_470': 32.0658,
    '835_471': 32.0595,
    '835_472': 32.0513,
    '835_473': 32.0406,
    '835_474': 32.0316,
    '835_475': 32.0249,
    '835_476': 32.0194,
    '835_477': 32.0161,
    '835_478': 32.0134,
    '835_479': 32.0148,
    '835_480': 32.0192,
    '835_481': 32.0136,
    '835_482': 31.9993,
    '835_483': 31.9978,
    '835_484': 32.0023,
    '835_485': 31.9772,
    '835_486': 31.9631,
    '835_487': 31.9681,
    '835_488': 31.9943,
    '835_489': 32.0237,
    '835_490': 32.0344,
    '835_491': 32.0072,
    '835_492': 31.978,
    '835_493': 31.9725,
    '835_494': 31.9825,
    '835_495': 32.0016,
    '835_496': 32.0241,
    '835_497': 32.0506,
    '835_498': 32.0828,
    '835_499': 32.1285,
    '835_500': 32.1977,
    '835_501': 32.2852,
    '835_502': 32.3791,
    '835_503': 32.477,
    '835_504': 32.5827,
    '835_505': 32.6902,
    '835_506': 32.8076,
    '835_507': 32.9373,
    '835_508': 33.072,
    '835_509': 33.2112,
    '835_510': 33.368,
    '835_511': 33.5522,
    '835_512': 33.7594,
    '835_513': 33.9755,
    '835_514': 34.1794,
    '835_515': 34.3601,
    '835_516': 34.537,
    '835_517': 34.7028,
    '835_518': 34.8326,
    '835_519': 34.9177,
    '835_520': 34.9578,
    '835_521': 34.9655,
    '835_522': 34.9757,
    '835_523': 35.0255,
    '835_524': 35.1023,
    '835_525': 35.1956,
    '835_526': 35.3004,
    '835_527': 35.4139,
    '835_528': 35.5364,
    '835_529': 35.6683,
    '835_530': 35.8008,
    '835_531': 35.926,
    '835_532': 36.0538,
    '835_533': 36.19,
    '835_534': 36.3325,
    '835_535': 36.4484,
    '835_536': 36.561,
    '835_537': 36.6581,
    '835_538': 36.7503,
    '835_539': 36.8278,
    '835_540': 36.8981,
    '835_541': 36.9799,
    '835_542': 37.0684,
    '835_543': 37.149,
    '835_544': 37.2259,
    '835_545': 37.3073,
    '835_546': 37.3914,
    '835_547': 37.4884,
    '835_548': 37.5682,
    '835_549': 37.6354,
    '835_550': 37.7104,
    '835_551': 37.815,
    '835_552': 37.9213,
    '835_553': 38.0262,
    '835_554': 38.1462,
    '835_555': 38.2736,
    '835_556': 38.3918,
    '835_557': 38.4879,
    '835_558': 38.5722,
    '835_559': 38.618,
    '835_560': 38.6379,
    '835_561': 38.6407,
    '835_562': 38.6189,
    '835_563': 38.6012,
    '835_564': 38.5966,
    '835_565': 38.5745,
    '835_566': 38.5357,
    '835_567': 38.489,
    '835_568': 38.4405,
    '835_569': 38.4079,
    '835_570': 38.3628,
    '835_571': 38.3054,
    '835_572': 38.2428,
    '835_573': 38.1685,
    '835_574': 38.0702,
    '835_575': 37.9533,
    '835_576': 37.8168,
    '835_577': 37.6845,
    '835_578': 37.5728,
    '835_579': 37.4624,
    '835_580': 37.3457,
    '835_581': 37.2348,
    '835_582': 37.1319,
    '835_583': 37.0406,
    '835_584': 36.9574,
    '835_585': 36.8814,
    '835_586': 36.8259,
    '835_587': 36.7794,
    '835_588': 36.7494,
    '835_589': 36.7362,
    '835_590': 36.732,
    '835_591': 36.7352,
    '835_592': 36.7459,
    '835_593': 36.7651,
    '835_594': 36.7937,
    '835_595': 36.8308,
    '835_596': 36.8829,
    '835_597': 36.938,
    '835_598': 37.0015,
    '835_599': 37.0706,
    '835_600': 37.149,
    '835_601': 37.2342,
    '835_602': 37.3314,
    '835_603': 37.444,
    '835_604': 37.5736,
    '835_605': 37.6955,
    '835_606': 37.8242,
    '835_607': 37.9698,
    '835_608': 38.1273,
    '835_609': 38.2949,
    '835_610': 38.4717,
    '835_611': 38.6393,
    '835_612': 38.8106,
    '835_613': 38.9936,
    '835_614': 39.1803,
    '835_615': 39.3573,
    '835_616': 39.5191,
    '835_617': 39.6626,
    '835_618': 39.8147,
    '835_619': 39.9711,
    '835_620': 40.1121,
    '835_621': 40.2447,
    '835_622': 40.372,
    '835_623': 40.4904,
    '835_624': 40.5952,
    '835_625': 40.6779,
    '835_626': 40.7316,
    '835_627': 40.7576,
    '835_628': 40.7687,
    '835_629': 40.7687,
    '835_630': 40.7544,
    '835_631': 40.7449,
    '835_632': 40.7586,
    '835_633': 40.7593,
    '835_634': 40.7196,
    '835_635': 40.6449,
    '835_636': 40.5676,
    '835_637': 40.4872,
    '835_638': 40.4071,
    '835_639': 40.321,
    '835_640': 40.2334,
    '835_641': 40.1333,
    '835_642': 40.0031,
    '835_643': 39.841,
    '835_644': 39.6711,
    '835_645': 39.4877,
    '835_646': 39.283,
    '835_647': 39.088,
    '835_648': 38.8871,
    '835_649': 38.6844,
    '835_650': 38.4849,
    '835_651': 38.286,
    '835_652': 38.0862,
    '835_653': 37.8829,
    '835_654': 37.6699,
    '835_655': 37.4528,
    '835_656': 37.2418,
    '835_657': 37.0579,
    '835_658': 36.8922,
    '835_659': 36.7361,
    '835_660': 36.5817,
    '835_782': 40.8435,
    '835_783': 40.8973,
    '835_784': 40.924,
    '835_785': 40.9073,
    '835_786': 40.8703,
    '836_408': 31.7504,
    '836_409': 31.7928,
    '836_410': 31.8346,
    '836_411': 31.8756,
    '836_412': 31.9156,
    '836_413': 31.9545,
    '836_414': 31.9915,
    '836_415': 32.026,
    '836_416': 32.0546,
    '836_417': 32.0889,
    '836_418': 32.1242,
    '836_419': 32.1592,
    '836_420': 32.1938,
    '836_421': 32.2316,
    '836_422': 32.2593,
    '836_423': 32.2818,
    '836_424': 32.3009,
    '836_425': 32.3126,
    '836_426': 32.3161,
    '836_427': 32.325,
    '836_428': 32.339,
    '836_429': 32.3648,
    '836_430': 32.3933,
    '836_431': 32.4092,
    '836_432': 32.416,
    '836_433': 32.4232,
    '836_434': 32.436,
    '836_435': 32.4593,
    '836_436': 32.4942,
    '836_437': 32.5278,
    '836_438': 32.5551,
    '836_439': 32.5721,
    '836_440': 32.5726,
    '836_441': 32.5686,
    '836_442': 32.5689,
    '836_443': 32.5662,
    '836_444': 32.5604,
    '836_445': 32.5501,
    '836_446': 32.5353,
    '836_447': 32.5131,
    '836_448': 32.4886,
    '836_449': 32.4651,
    '836_450': 32.4438,
    '836_451': 32.4236,
    '836_452': 32.3984,
    '836_453': 32.371,
    '836_454': 32.3434,
    '836_455': 32.3166,
    '836_456': 32.2924,
    '836_457': 32.2717,
    '836_458': 32.2546,
    '836_459': 32.2402,
    '836_460': 32.2276,
    '836_461': 32.2163,
    '836_462': 32.2051,
    '836_463': 32.1929,
    '836_464': 32.1804,
    '836_465': 32.1683,
    '836_466': 32.1564,
    '836_467': 32.1457,
    '836_468': 32.1376,
    '836_469': 32.1311,
    '836_470': 32.1257,
    '836_471': 32.12,
    '836_472': 32.1148,
    '836_473': 32.11,
    '836_474': 32.0949,
    '836_475': 32.0829,
    '836_476': 32.0715,
    '836_477': 32.0645,
    '836_478': 32.0616,
    '836_479': 32.0652,
    '836_480': 32.0704,
    '836_481': 32.054,
    '836_482': 32.0373,
    '836_483': 32.0313,
    '836_484': 32.0327,
    '836_485': 32.0137,
    '836_486': 32.0022,
    '836_487': 32.0053,
    '836_488': 32.0243,
    '836_489': 32.0504,
    '836_490': 32.06,
    '836_491': 32.0369,
    '836_492': 32.0155,
    '836_493': 32.0116,
    '836_494': 32.0215,
    '836_495': 32.0391,
    '836_496': 32.065,
    '836_497': 32.1009,
    '836_498': 32.1284,
    '836_499': 32.1716,
    '836_500': 32.234,
    '836_501': 32.3209,
    '836_502': 32.4104,
    '836_503': 32.5039,
    '836_504': 32.6079,
    '836_505': 32.7168,
    '836_506': 32.8262,
    '836_507': 32.9467,
    '836_508': 33.0767,
    '836_509': 33.2155,
    '836_510': 33.3697,
    '836_511': 33.5467,
    '836_512': 33.7495,
    '836_513': 33.9687,
    '836_514': 34.1735,
    '836_515': 34.3465,
    '836_516': 34.5041,
    '836_517': 34.6509,
    '836_518': 34.7709,
    '836_519': 34.8501,
    '836_520': 34.8877,
    '836_521': 34.8956,
    '836_522': 34.9098,
    '836_523': 34.9567,
    '836_524': 35.0289,
    '836_525': 35.1181,
    '836_526': 35.2169,
    '836_527': 35.3223,
    '836_528': 35.4341,
    '836_529': 35.5545,
    '836_530': 35.6729,
    '836_531': 35.7805,
    '836_532': 35.8924,
    '836_533': 36.0135,
    '836_534': 36.1429,
    '836_535': 36.2603,
    '836_536': 36.3715,
    '836_537': 36.4763,
    '836_538': 36.5909,
    '836_539': 36.6834,
    '836_540': 36.7632,
    '836_541': 36.8458,
    '836_542': 36.9339,
    '836_543': 37.0214,
    '836_544': 37.1088,
    '836_545': 37.2018,
    '836_546': 37.2964,
    '836_547': 37.3954,
    '836_548': 37.4795,
    '836_549': 37.5492,
    '836_550': 37.626,
    '836_551': 37.7298,
    '836_552': 37.8398,
    '836_553': 37.9485,
    '836_554': 38.0749,
    '836_555': 38.1993,
    '836_556': 38.3212,
    '836_557': 38.4216,
    '836_558': 38.5061,
    '836_559': 38.5392,
    '836_560': 38.5524,
    '836_561': 38.5296,
    '836_562': 38.504,
    '836_563': 38.4848,
    '836_564': 38.4811,
    '836_565': 38.462,
    '836_566': 38.4283,
    '836_567': 38.3914,
    '836_568': 38.3484,
    '836_569': 38.314,
    '836_570': 38.2721,
    '836_571': 38.2143,
    '836_572': 38.1578,
    '836_573': 38.0874,
    '836_574': 38.0041,
    '836_575': 37.9072,
    '836_576': 37.8031,
    '836_577': 37.686,
    '836_578': 37.5669,
    '836_579': 37.4518,
    '836_580': 37.3392,
    '836_581': 37.2305,
    '836_582': 37.1324,
    '836_583': 37.0351,
    '836_584': 36.9526,
    '836_585': 36.8834,
    '836_586': 36.8292,
    '836_587': 36.7865,
    '836_588': 36.7589,
    '836_589': 36.7481,
    '836_590': 36.746,
    '836_591': 36.7511,
    '836_592': 36.7638,
    '836_593': 36.7848,
    '836_594': 36.8138,
    '836_595': 36.851,
    '836_596': 36.9027,
    '836_597': 36.9576,
    '836_598': 37.0218,
    '836_599': 37.1003,
    '836_600': 37.1787,
    '836_601': 37.2642,
    '836_602': 37.3592,
    '836_603': 37.4711,
    '836_604': 37.6012,
    '836_605': 37.7336,
    '836_606': 37.8699,
    '836_607': 38.0205,
    '836_608': 38.1755,
    '836_609': 38.3385,
    '836_610': 38.504,
    '836_611': 38.6719,
    '836_612': 38.8374,
    '836_613': 39.0205,
    '836_614': 39.2143,
    '836_615': 39.3881,
    '836_616': 39.5544,
    '836_617': 39.7007,
    '836_618': 39.8622,
    '836_619': 40.0194,
    '836_620': 40.1628,
    '836_621': 40.2956,
    '836_622': 40.4193,
    '836_623': 40.5357,
    '836_624': 40.6407,
    '836_625': 40.721,
    '836_626': 40.7671,
    '836_627': 40.7856,
    '836_628': 40.7954,
    '836_629': 40.7914,
    '836_630': 40.7801,
    '836_631': 40.7785,
    '836_632': 40.8026,
    '836_633': 40.8197,
    '836_634': 40.795,
    '836_635': 40.7345,
    '836_636': 40.6655,
    '836_637': 40.578,
    '836_638': 40.4851,
    '836_639': 40.3943,
    '836_640': 40.3091,
    '836_641': 40.2163,
    '836_642': 40.1038,
    '836_643': 39.9614,
    '836_644': 39.8084,
    '836_645': 39.6375,
    '836_646': 39.4371,
    '836_647': 39.2475,
    '836_648': 39.0415,
    '836_649': 38.839,
    '836_650': 38.6374,
    '836_651': 38.4406,
    '836_652': 38.245,
    '836_653': 38.049,
    '836_654': 37.8492,
    '836_655': 37.6449,
    '836_656': 37.4376,
    '836_657': 37.2572,
    '836_658': 37.0898,
    '836_659': 36.9285,
    '836_660': 36.7685,
    '837_408': 31.7059,
    '837_409': 31.7456,
    '837_410': 31.785,
    '837_411': 31.824,
    '837_412': 31.8626,
    '837_413': 31.9005,
    '837_414': 31.9376,
    '837_415': 31.974,
    '837_416': 32.0092,
    '837_417': 32.0449,
    '837_418': 32.0846,
    '837_419': 32.1229,
    '837_420': 32.1584,
    '837_421': 32.1908,
    '837_422': 32.2186,
    '837_423': 32.2435,
    '837_424': 32.2686,
    '837_425': 32.2871,
    '837_426': 32.2956,
    '837_427': 32.308,
    '837_428': 32.3234,
    '837_429': 32.3437,
    '837_430': 32.3639,
    '837_431': 32.3771,
    '837_432': 32.3849,
    '837_433': 32.3925,
    '837_434': 32.4054,
    '837_435': 32.4284,
    '837_436': 32.4647,
    '837_437': 32.5001,
    '837_438': 32.5238,
    '837_439': 32.5404,
    '837_440': 32.548,
    '837_441': 32.5476,
    '837_442': 32.5509,
    '837_443': 32.5518,
    '837_444': 32.5478,
    '837_445': 32.5414,
    '837_446': 32.5308,
    '837_447': 32.5127,
    '837_448': 32.4873,
    '837_449': 32.4647,
    '837_450': 32.4453,
    '837_451': 32.4286,
    '837_452': 32.407,
    '837_453': 32.3833,
    '837_454': 32.3571,
    '837_455': 32.3304,
    '837_456': 32.308,
    '837_457': 32.2895,
    '837_458': 32.275,
    '837_459': 32.2631,
    '837_460': 32.2533,
    '837_461': 32.245,
    '837_462': 32.2371,
    '837_463': 32.2285,
    '837_464': 32.22,
    '837_465': 32.212,
    '837_466': 32.2045,
    '837_467': 32.1979,
    '837_468': 32.1934,
    '837_469': 32.1901,
    '837_470': 32.1867,
    '837_471': 32.1825,
    '837_472': 32.1784,
    '837_473': 32.1732,
    '837_474': 32.1582,
    '837_475': 32.1393,
    '837_476': 32.1246,
    '837_477': 32.1149,
    '837_478': 32.1116,
    '837_479': 32.1151,
    '837_480': 32.1107,
    '837_481': 32.089,
    '837_482': 32.0733,
    '837_483': 32.0669,
    '837_484': 32.0603,
    '837_485': 32.0497,
    '837_486': 32.046,
    '837_487': 32.045,
    '837_488': 32.0526,
    '837_489': 32.0649,
    '837_490': 32.0681,
    '837_491': 32.0555,
    '837_492': 32.0468,
    '837_493': 32.049,
    '837_494': 32.0619,
    '837_495': 32.0777,
    '837_496': 32.1032,
    '837_497': 32.1387,
    '837_498': 32.1731,
    '837_499': 32.221,
    '837_500': 32.2804,
    '837_501': 32.3547,
    '837_502': 32.4362,
    '837_503': 32.531,
    '837_504': 32.6429,
    '837_505': 32.7491,
    '837_506': 32.8582,
    '837_507': 32.9702,
    '837_508': 33.0891,
    '837_509': 33.2224,
    '837_510': 33.3704,
    '837_511': 33.5386,
    '837_512': 33.7287,
    '837_513': 33.9385,
    '837_514': 34.1424,
    '837_515': 34.3119,
    '837_516': 34.4568,
    '837_517': 34.5862,
    '837_518': 34.6949,
    '837_519': 34.7692,
    '837_520': 34.8081,
    '837_521': 34.8287,
    '837_522': 34.8538,
    '837_523': 34.9027,
    '837_524': 34.9746,
    '837_525': 35.0603,
    '837_526': 35.1536,
    '837_527': 35.2517,
    '837_528': 35.354,
    '837_529': 35.4607,
    '837_530': 35.5668,
    '837_531': 35.6666,
    '837_532': 35.7688,
    '837_533': 35.8799,
    '837_534': 35.9935,
    '837_535': 36.0984,
    '837_536': 36.1936,
    '837_537': 36.2922,
    '837_538': 36.4031,
    '837_539': 36.5042,
    '837_540': 36.5986,
    '837_541': 36.6989,
    '837_542': 36.8001,
    '837_543': 36.8985,
    '837_544': 36.9948,
    '837_545': 37.0905,
    '837_546': 37.19,
    '837_547': 37.2886,
    '837_548': 37.3768,
    '837_549': 37.4515,
    '837_550': 37.5288,
    '837_551': 37.625,
    '837_552': 37.7377,
    '837_553': 37.8541,
    '837_554': 37.9819,
    '837_555': 38.1093,
    '837_556': 38.223,
    '837_557': 38.3268,
    '837_558': 38.3976,
    '837_559': 38.428,
    '837_560': 38.43,
    '837_561': 38.4098,
    '837_562': 38.3831,
    '837_563': 38.3631,
    '837_564': 38.3615,
    '837_565': 38.3366,
    '837_566': 38.3102,
    '837_567': 38.2893,
    '837_568': 38.2557,
    '837_569': 38.2185,
    '837_570': 38.1759,
    '837_571': 38.1123,
    '837_572': 38.053,
    '837_573': 37.9907,
    '837_574': 37.9118,
    '837_575': 37.8361,
    '837_576': 37.7654,
    '837_577': 37.679,
    '837_578': 37.5742,
    '837_579': 37.4672,
    '837_580': 37.3614,
    '837_581': 37.2457,
    '837_582': 37.1286,
    '837_583': 37.0342,
    '837_584': 36.954,
    '837_585': 36.8868,
    '837_586': 36.8353,
    '837_587': 36.7955,
    '837_588': 36.7691,
    '837_589': 36.7602,
    '837_590': 36.7595,
    '837_591': 36.7659,
    '837_592': 36.7796,
    '837_593': 36.8014,
    '837_594': 36.8306,
    '837_595': 36.8676,
    '837_596': 36.9189,
    '837_597': 36.9745,
    '837_598': 37.0419,
    '837_599': 37.1196,
    '837_600': 37.1999,
    '837_601': 37.2865,
    '837_602': 37.3813,
    '837_603': 37.492,
    '837_604': 37.6206,
    '837_605': 37.7606,
    '837_606': 37.9078,
    '837_607': 38.0695,
    '837_608': 38.2255,
    '837_609': 38.3835,
    '837_610': 38.5408,
    '837_611': 38.6996,
    '837_612': 38.867,
    '837_613': 39.0456,
    '837_614': 39.2339,
    '837_615': 39.4009,
    '837_616': 39.5721,
    '837_617': 39.732,
    '837_618': 39.9001,
    '837_619': 40.0607,
    '837_620': 40.2133,
    '837_621': 40.3467,
    '837_622': 40.4653,
    '837_623': 40.5767,
    '837_624': 40.6829,
    '837_625': 40.7604,
    '837_626': 40.8054,
    '837_627': 40.823,
    '837_628': 40.8292,
    '837_629': 40.8227,
    '837_630': 40.8167,
    '837_631': 40.8237,
    '837_632': 40.8459,
    '837_633': 40.8686,
    '837_634': 40.859,
    '837_635': 40.8235,
    '837_636': 40.769,
    '837_637': 40.6824,
    '837_638': 40.5778,
    '837_639': 40.4772,
    '837_640': 40.3831,
    '837_641': 40.2917,
    '837_642': 40.1963,
    '837_643': 40.0718,
    '837_644': 39.9382,
    '837_645': 39.7817,
    '837_646': 39.5934,
    '837_647': 39.3994,
    '837_648': 39.1953,
    '837_649': 38.9886,
    '837_650': 38.7828,
    '837_651': 38.5863,
    '837_652': 38.3918,
    '837_653': 38.1994,
    '837_654': 38.0067,
    '837_655': 37.814,
    '837_656': 37.6261,
    '837_657': 37.451,
    '837_658': 37.2823,
    '837_659': 37.1166,
    '837_660': 36.9516,
    '837_661': 36.7836,
    '837_662': 36.6223,
    '837_663': 36.4786,
    '837_664': 36.3679,
    '837_665': 36.2767,
    '837_666': 36.1981,
    '837_667': 36.1167,
    '837_668': 36.0753,
    '837_669': 36.0564,
    '837_670': 36.0481,
    '837_671': 36.0585,
    '837_672': 36.0789,
    '837_673': 36.1099,
    '837_674': 36.1271,
    '837_675': 36.134,
    '837_676': 36.1366,
    '837_677': 36.1322,
    '837_678': 36.1216,
    '837_679': 36.1047,
    '837_680': 36.0854,
    '837_681': 36.0692,
    '837_682': 36.0628,
    '837_683': 36.0737,
    '837_684': 36.0986,
    '837_685': 36.1356,
    '837_686': 36.1837,
    '837_687': 36.2427,
    '837_688': 36.3105,
    '837_689': 36.387,
    '837_690': 36.474,
    '837_691': 36.5588,
    '837_692': 36.638,
    '837_693': 36.7222,
    '837_694': 36.8005,
    '837_695': 36.8642,
    '837_696': 36.9174,
    '837_697': 36.9791,
    '837_698': 37.0309,
    '837_699': 37.0674,
    '837_700': 37.0969,
    '837_701': 37.1304,
    '837_702': 37.1743,
    '837_703': 37.2273,
    '837_704': 37.2862,
    '838_408': 31.6592,
    '838_409': 31.6958,
    '838_410': 31.7326,
    '838_411': 31.7695,
    '838_412': 31.8063,
    '838_413': 31.8426,
    '838_414': 31.8787,
    '838_415': 31.9147,
    '838_416': 31.9505,
    '838_417': 31.9882,
    '838_418': 32.0341,
    '838_419': 32.0771,
    '838_420': 32.1151,
    '838_421': 32.1481,
    '838_422': 32.1777,
    '838_423': 32.209,
    '838_424': 32.2393,
    '838_425': 32.2636,
    '838_426': 32.282,
    '838_427': 32.2981,
    '838_428': 32.3142,
    '838_429': 32.3308,
    '838_430': 32.3457,
    '838_431': 32.3564,
    '838_432': 32.3636,
    '838_433': 32.3686,
    '838_434': 32.3817,
    '838_435': 32.4061,
    '838_436': 32.4403,
    '838_437': 32.4763,
    '838_438': 32.5011,
    '838_439': 32.5128,
    '838_440': 32.5178,
    '838_441': 32.5212,
    '838_442': 32.5264,
    '838_443': 32.5298,
    '838_444': 32.5297,
    '838_445': 32.5276,
    '838_446': 32.5217,
    '838_447': 32.5074,
    '838_448': 32.4835,
    '838_449': 32.4604,
    '838_450': 32.4431,
    '838_451': 32.4268,
    '838_452': 32.4069,
    '838_453': 32.3852,
    '838_454': 32.3631,
    '838_455': 32.3392,
    '838_456': 32.3201,
    '838_457': 32.3054,
    '838_458': 32.2942,
    '838_459': 32.2859,
    '838_460': 32.2792,
    '838_461': 32.2745,
    '838_462': 32.2702,
    '838_463': 32.2653,
    '838_464': 32.2601,
    '838_465': 32.2559,
    '838_466': 32.2524,
    '838_467': 32.2498,
    '838_468': 32.2487,
    '838_469': 32.2479,
    '838_470': 32.2457,
    '838_471': 32.244,
    '838_472': 32.2386,
    '838_473': 32.2281,
    '838_474': 32.2138,
    '838_475': 32.1951,
    '838_476': 32.1779,
    '838_477': 32.1663,
    '838_478': 32.1619,
    '838_479': 32.1563,
    '838_480': 32.1423,
    '838_481': 32.1248,
    '838_482': 32.1135,
    '838_483': 32.102,
    '838_484': 32.0945,
    '838_485': 32.0972,
    '838_486': 32.1064,
    '838_487': 32.0949,
    '838_488': 32.0835,
    '838_489': 32.082,
    '838_490': 32.0813,
    '838_491': 32.0783,
    '838_492': 32.0783,
    '838_493': 32.0849,
    '838_494': 32.0987,
    '838_495': 32.1189,
    '838_496': 32.1422,
    '838_497': 32.1755,
    '838_498': 32.2161,
    '838_499': 32.2674,
    '838_500': 32.3283,
    '838_501': 32.3984,
    '838_502': 32.4792,
    '838_503': 32.5694,
    '838_504': 32.6857,
    '838_505': 32.7892,
    '838_506': 32.8908,
    '838_507': 32.9956,
    '838_508': 33.1049,
    '838_509': 33.2305,
    '838_510': 33.3731,
    '838_511': 33.5332,
    '838_512': 33.707,
    '838_513': 33.8967,
    '838_514': 34.0878,
    '838_515': 34.2543,
    '838_516': 34.3908,
    '838_517': 34.5106,
    '838_518': 34.6096,
    '838_519': 34.6847,
    '838_520': 34.7303,
    '838_521': 34.7681,
    '838_522': 34.8114,
    '838_523': 34.8621,
    '838_524': 34.9347,
    '838_525': 35.0184,
    '838_526': 35.1067,
    '838_527': 35.1983,
    '838_528': 35.2916,
    '838_529': 35.3874,
    '838_530': 35.4834,
    '838_531': 35.5768,
    '838_532': 35.6716,
    '838_533': 35.7712,
    '838_534': 35.8729,
    '838_535': 35.9688,
    '838_536': 36.0588,
    '838_537': 36.1391,
    '838_538': 36.2306,
    '838_539': 36.3261,
    '838_540': 36.4223,
    '838_541': 36.5279,
    '838_542': 36.6417,
    '838_543': 36.7545,
    '838_544': 36.868,
    '838_545': 36.9803,
    '838_546': 37.0909,
    '838_547': 37.1874,
    '838_548': 37.272,
    '838_549': 37.3529,
    '838_550': 37.435,
    '838_551': 37.5268,
    '838_552': 37.6397,
    '838_553': 37.7574,
    '838_554': 37.8792,
    '838_555': 38.0027,
    '838_556': 38.1139,
    '838_557': 38.2052,
    '838_558': 38.2674,
    '838_559': 38.2981,
    '838_560': 38.2907,
    '838_561': 38.2726,
    '838_562': 38.2577,
    '838_563': 38.2449,
    '838_564': 38.2421,
    '838_565': 38.2207,
    '838_566': 38.1978,
    '838_567': 38.1843,
    '838_568': 38.1634,
    '838_569': 38.1293,
    '838_570': 38.0904,
    '838_571': 38.0309,
    '838_572': 37.9647,
    '838_573': 37.8915,
    '838_574': 37.8076,
    '838_575': 37.7403,
    '838_576': 37.693,
    '838_577': 37.6288,
    '838_578': 37.5411,
    '838_579': 37.4494,
    '838_580': 37.3539,
    '838_581': 37.2406,
    '838_582': 37.124,
    '838_583': 37.0379,
    '838_584': 36.9576,
    '838_585': 36.8925,
    '838_586': 36.8428,
    '838_587': 36.8068,
    '838_588': 36.7833,
    '838_589': 36.7745,
    '838_590': 36.7737,
    '838_591': 36.7802,
    '838_592': 36.7943,
    '838_593': 36.8162,
    '838_594': 36.8453,
    '838_595': 36.8817,
    '838_596': 36.9317,
    '838_597': 36.9859,
    '838_598': 37.052,
    '838_599': 37.1366,
    '838_600': 37.2171,
    '838_601': 37.3047,
    '838_602': 37.3998,
    '838_603': 37.5108,
    '838_604': 37.6404,
    '838_605': 37.7787,
    '838_606': 37.9309,
    '838_607': 38.0924,
    '838_608': 38.2472,
    '838_609': 38.4079,
    '838_610': 38.5785,
    '838_611': 38.7374,
    '838_612': 38.8974,
    '838_613': 39.0719,
    '838_614': 39.2501,
    '838_615': 39.4186,
    '838_616': 39.5956,
    '838_617': 39.7686,
    '838_618': 39.9444,
    '838_619': 40.1095,
    '838_620': 40.2641,
    '838_621': 40.3962,
    '838_622': 40.5099,
    '838_623': 40.6153,
    '838_624': 40.7197,
    '838_625': 40.8016,
    '838_626': 40.8473,
    '838_627': 40.8632,
    '838_628': 40.8647,
    '838_629': 40.8604,
    '838_630': 40.8655,
    '838_631': 40.8772,
    '838_632': 40.8921,
    '838_633': 40.9138,
    '838_634': 40.9203,
    '838_635': 40.9103,
    '838_636': 40.8653,
    '838_637': 40.7792,
    '838_638': 40.67,
    '838_639': 40.5669,
    '838_640': 40.4657,
    '838_641': 40.3747,
    '838_642': 40.2865,
    '838_643': 40.1843,
    '838_644': 40.0642,
    '838_645': 39.9139,
    '838_646': 39.7308,
    '838_647': 39.5288,
    '838_648': 39.3221,
    '838_649': 39.12,
    '838_650': 38.9247,
    '838_651': 38.7275,
    '838_652': 38.5305,
    '838_653': 38.3401,
    '838_654': 38.1523,
    '838_655': 37.9666,
    '838_656': 37.7881,
    '838_657': 37.6284,
    '838_658': 37.4638,
    '838_659': 37.2972,
    '838_660': 37.1293,
    '838_661': 36.9621,
    '838_662': 36.802,
    '838_663': 36.6595,
    '838_664': 36.5494,
    '838_665': 36.4579,
    '838_666': 36.3746,
    '838_667': 36.2877,
    '838_668': 36.2353,
    '838_669': 36.2079,
    '838_670': 36.1984,
    '838_671': 36.2012,
    '838_672': 36.2143,
    '838_673': 36.2362,
    '838_674': 36.2494,
    '838_675': 36.252,
    '838_676': 36.249,
    '838_677': 36.2381,
    '838_678': 36.2201,
    '838_679': 36.1944,
    '838_680': 36.1657,
    '838_681': 36.1395,
    '838_682': 36.1233,
    '838_683': 36.1261,
    '838_684': 36.1434,
    '838_685': 36.1729,
    '838_686': 36.2131,
    '838_687': 36.265,
    '838_688': 36.3254,
    '838_689': 36.3934,
    '838_690': 36.4825,
    '838_691': 36.5627,
    '838_692': 36.6349,
    '838_693': 36.7208,
    '838_694': 36.8006,
    '838_695': 36.867,
    '838_696': 36.9257,
    '838_697': 36.9849,
    '838_698': 37.0364,
    '838_699': 37.0746,
    '838_700': 37.1087,
    '838_701': 37.1465,
    '838_702': 37.1937,
    '838_703': 37.2485,
    '838_704': 37.3076,
    '839_408': 31.609,
    '839_409': 31.642,
    '839_410': 31.6758,
    '839_411': 31.7104,
    '839_412': 31.7452,
    '839_413': 31.7797,
    '839_414': 31.8142,
    '839_415': 31.8494,
    '839_416': 31.8855,
    '839_417': 31.9248,
    '839_418': 31.9704,
    '839_419': 32.0206,
    '839_420': 32.063,
    '839_421': 32.098,
    '839_422': 32.1297,
    '839_423': 32.1725,
    '839_424': 32.2067,
    '839_425': 32.2363,
    '839_426': 32.2608,
    '839_427': 32.2801,
    '839_428': 32.2974,
    '839_429': 32.313,
    '839_430': 32.3258,
    '839_431': 32.3349,
    '839_432': 32.3407,
    '839_433': 32.3489,
    '839_434': 32.3637,
    '839_435': 32.3862,
    '839_436': 32.4184,
    '839_437': 32.4534,
    '839_438': 32.4796,
    '839_439': 32.4935,
    '839_440': 32.4921,
    '839_441': 32.4955,
    '839_442': 32.5008,
    '839_443': 32.5051,
    '839_444': 32.5082,
    '839_445': 32.5086,
    '839_446': 32.5065,
    '839_447': 32.4982,
    '839_448': 32.4753,
    '839_449': 32.4509,
    '839_450': 32.4354,
    '839_451': 32.4196,
    '839_452': 32.4006,
    '839_453': 32.3781,
    '839_454': 32.3586,
    '839_455': 32.3416,
    '839_456': 32.3284,
    '839_457': 32.3185,
    '839_458': 32.312,
    '839_459': 32.3059,
    '839_460': 32.3026,
    '839_461': 32.3021,
    '839_462': 32.3026,
    '839_463': 32.302,
    '839_464': 32.2987,
    '839_465': 32.2973,
    '839_466': 32.2973,
    '839_467': 32.2989,
    '839_468': 32.3008,
    '839_469': 32.3019,
    '839_470': 32.3001,
    '839_471': 32.3008,
    '839_472': 32.295,
    '839_473': 32.2786,
    '839_474': 32.2602,
    '839_475': 32.2441,
    '839_476': 32.2347,
    '839_477': 32.2167,
    '839_478': 32.2025,
    '839_479': 32.1893,
    '839_480': 32.1733,
    '839_481': 32.164,
    '839_482': 32.158,
    '839_483': 32.1463,
    '839_484': 32.1365,
    '839_485': 32.1454,
    '839_486': 32.1507,
    '839_487': 32.1413,
    '839_488': 32.122,
    '839_489': 32.1082,
    '839_490': 32.1049,
    '839_491': 32.1058,
    '839_492': 32.1099,
    '839_493': 32.1192,
    '839_494': 32.1341,
    '839_495': 32.1565,
    '839_496': 32.1806,
    '839_497': 32.214,
    '839_498': 32.2574,
    '839_499': 32.3104,
    '839_500': 32.3728,
    '839_501': 32.4425,
    '839_502': 32.5234,
    '839_503': 32.6056,
    '839_504': 32.7121,
    '839_505': 32.8214,
    '839_506': 32.9181,
    '839_507': 33.0186,
    '839_508': 33.124,
    '839_509': 33.2417,
    '839_510': 33.3803,
    '839_511': 33.534,
    '839_512': 33.6976,
    '839_513': 33.8675,
    '839_514': 34.0352,
    '839_515': 34.1939,
    '839_516': 34.3243,
    '839_517': 34.4308,
    '839_518': 34.5249,
    '839_519': 34.6079,
    '839_520': 34.6654,
    '839_521': 34.7126,
    '839_522': 34.7707,
    '839_523': 34.8295,
    '839_524': 34.9045,
    '839_525': 34.9853,
    '839_526': 35.0692,
    '839_527': 35.1552,
    '839_528': 35.2424,
    '839_529': 35.3297,
    '839_530': 35.4178,
    '839_531': 35.5048,
    '839_532': 35.5925,
    '839_533': 35.6833,
    '839_534': 35.7733,
    '839_535': 35.8614,
    '839_536': 35.9478,
    '839_537': 36.0218,
    '839_538': 36.1044,
    '839_539': 36.1937,
    '839_540': 36.286,
    '839_541': 36.3837,
    '839_542': 36.4892,
    '839_543': 36.6032,
    '839_544': 36.7248,
    '839_545': 36.8519,
    '839_546': 36.9799,
    '839_547': 37.0924,
    '839_548': 37.1851,
    '839_549': 37.2728,
    '839_550': 37.3554,
    '839_551': 37.4429,
    '839_552': 37.5437,
    '839_553': 37.6611,
    '839_554': 37.7771,
    '839_555': 37.8823,
    '839_556': 37.9861,
    '839_557': 38.072,
    '839_558': 38.13,
    '839_559': 38.1572,
    '839_560': 38.148,
    '839_561': 38.128,
    '839_562': 38.1216,
    '839_563': 38.1136,
    '839_564': 38.119,
    '839_565': 38.1071,
    '839_566': 38.0942,
    '839_567': 38.0958,
    '839_568': 38.0855,
    '839_569': 38.05,
    '839_570': 38.0052,
    '839_571': 37.9527,
    '839_572': 37.8921,
    '839_573': 37.8105,
    '839_574': 37.7209,
    '839_575': 37.6459,
    '839_576': 37.593,
    '839_577': 37.5325,
    '839_578': 37.4605,
    '839_579': 37.3852,
    '839_580': 37.3018,
    '839_581': 37.2006,
    '839_582': 37.1015,
    '839_583': 37.027,
    '839_584': 36.9545,
    '839_585': 36.8948,
    '839_586': 36.8498,
    '839_587': 36.817,
    '839_588': 36.7968,
    '839_589': 36.7868,
    '839_590': 36.7858,
    '839_591': 36.7922,
    '839_592': 36.8063,
    '839_593': 36.8282,
    '839_594': 36.857,
    '839_595': 36.8929,
    '839_596': 36.9421,
    '839_597': 36.9964,
    '839_598': 37.0642,
    '839_599': 37.1494,
    '839_600': 37.2319,
    '839_601': 37.3194,
    '839_602': 37.4137,
    '839_603': 37.5228,
    '839_604': 37.6508,
    '839_605': 37.7917,
    '839_606': 37.9416,
    '839_607': 38.0997,
    '839_608': 38.2547,
    '839_609': 38.4243,
    '839_610': 38.6016,
    '839_611': 38.7654,
    '839_612': 38.9291,
    '839_613': 39.1002,
    '839_614': 39.2747,
    '839_615': 39.4463,
    '839_616': 39.6278,
    '839_617': 39.8064,
    '839_618': 39.99,
    '839_619': 40.1612,
    '839_620': 40.3139,
    '839_621': 40.4415,
    '839_622': 40.5491,
    '839_623': 40.6515,
    '839_624': 40.7553,
    '839_625': 40.8345,
    '839_626': 40.8827,
    '839_627': 40.9048,
    '839_628': 40.9079,
    '839_629': 40.9051,
    '839_630': 40.9175,
    '839_631': 40.9294,
    '839_632': 40.9371,
    '839_633': 40.9704,
    '839_634': 40.9902,
    '839_635': 40.989,
    '839_636': 40.9471,
    '839_637': 40.8641,
    '839_638': 40.7548,
    '839_639': 40.6526,
    '839_640': 40.5517,
    '839_641': 40.461,
    '839_642': 40.3773,
    '839_643': 40.2936,
    '839_644': 40.1815,
    '839_645': 40.0363,
    '839_646': 39.8571,
    '839_647': 39.6596,
    '839_648': 39.4538,
    '839_649': 39.2471,
    '839_650': 39.0578,
    '839_651': 38.8574,
    '839_652': 38.6603,
    '839_653': 38.473,
    '839_654': 38.2907,
    '839_655': 38.1126,
    '839_656': 37.9423,
    '839_657': 37.7843,
    '839_658': 37.6262,
    '839_659': 37.4663,
    '839_660': 37.3021,
    '839_661': 37.1382,
    '839_662': 36.9818,
    '839_663': 36.8428,
    '839_664': 36.7318,
    '839_665': 36.638,
    '839_666': 36.5543,
    '839_667': 36.4752,
    '839_668': 36.4065,
    '839_669': 36.3662,
    '839_670': 36.3552,
    '839_671': 36.3495,
    '839_672': 36.355,
    '839_673': 36.3682,
    '839_674': 36.3696,
    '839_675': 36.3725,
    '839_676': 36.3649,
    '839_677': 36.3476,
    '839_678': 36.3227,
    '839_679': 36.2878,
    '839_680': 36.249,
    '839_681': 36.2123,
    '839_682': 36.1857,
    '839_683': 36.1798,
    '839_684': 36.1895,
    '839_685': 36.2126,
    '839_686': 36.2428,
    '839_687': 36.2896,
    '839_688': 36.3441,
    '839_689': 36.3953,
    '839_690': 36.4873,
    '839_691': 36.5783,
    '839_692': 36.6561,
    '839_693': 36.736,
    '839_694': 36.8132,
    '839_695': 36.8784,
    '839_696': 36.9379,
    '839_697': 36.9934,
    '839_698': 37.0384,
    '839_699': 37.0784,
    '839_700': 37.1176,
    '839_701': 37.1605,
    '839_702': 37.2131,
    '839_703': 37.2724,
    '839_704': 37.3352,
    '840_408': 31.5553,
    '840_409': 31.5847,
    '840_410': 31.6153,
    '840_411': 31.6474,
    '840_412': 31.6801,
    '840_413': 31.7125,
    '840_414': 31.745,
    '840_415': 31.7789,
    '840_416': 31.8152,
    '840_417': 31.8553,
    '840_418': 31.9005,
    '840_419': 31.9522,
    '840_420': 31.9975,
    '840_421': 32.0336,
    '840_422': 32.0656,
    '840_423': 32.1051,
    '840_424': 32.1531,
    '840_425': 32.1974,
    '840_426': 32.2325,
    '840_427': 32.2518,
    '840_428': 32.2715,
    '840_429': 32.2883,
    '840_430': 32.3038,
    '840_431': 32.3096,
    '840_432': 32.3201,
    '840_433': 32.3339,
    '840_434': 32.3516,
    '840_435': 32.3733,
    '840_436': 32.4016,
    '840_437': 32.4325,
    '840_438': 32.4567,
    '840_439': 32.4711,
    '840_440': 32.4701,
    '840_441': 32.4721,
    '840_442': 32.4766,
    '840_443': 32.4817,
    '840_444': 32.4864,
    '840_445': 32.489,
    '840_446': 32.488,
    '840_447': 32.482,
    '840_448': 32.4636,
    '840_449': 32.4412,
    '840_450': 32.428,
    '840_451': 32.4139,
    '840_452': 32.3934,
    '840_453': 32.3718,
    '840_454': 32.3555,
    '840_455': 32.3431,
    '840_456': 32.3349,
    '840_457': 32.3297,
    '840_458': 32.3283,
    '840_459': 32.3247,
    '840_460': 32.3242,
    '840_461': 32.3288,
    '840_462': 32.334,
    '840_463': 32.3402,
    '840_464': 32.3369,
    '840_465': 32.3365,
    '840_466': 32.3398,
    '840_467': 32.3447,
    '840_468': 32.3506,
    '840_469': 32.3495,
    '840_470': 32.3465,
    '840_471': 32.3442,
    '840_472': 32.3427,
    '840_473': 32.3287,
    '840_474': 32.3053,
    '840_475': 32.292,
    '840_476': 32.2899,
    '840_477': 32.2743,
    '840_478': 32.2486,
    '840_479': 32.2205,
    '840_480': 32.2058,
    '840_481': 32.1994,
    '840_482': 32.1931,
    '840_483': 32.1843,
    '840_484': 32.1758,
    '840_485': 32.1743,
    '840_486': 32.1748,
    '840_487': 32.176,
    '840_488': 32.1676,
    '840_489': 32.1392,
    '840_490': 32.1332,
    '840_491': 32.1362,
    '840_492': 32.1415,
    '840_493': 32.1527,
    '840_494': 32.1689,
    '840_495': 32.193,
    '840_496': 32.2196,
    '840_497': 32.255,
    '840_498': 32.2987,
    '840_499': 32.352,
    '840_500': 32.4137,
    '840_501': 32.4823,
    '840_502': 32.559,
    '840_503': 32.641,
    '840_504': 32.7361,
    '840_505': 32.8451,
    '840_506': 32.9454,
    '840_507': 33.0481,
    '840_508': 33.1544,
    '840_509': 33.2698,
    '840_510': 33.397,
    '840_511': 33.5387,
    '840_512': 33.6888,
    '840_513': 33.8468,
    '840_514': 33.9984,
    '840_515': 34.1462,
    '840_516': 34.2686,
    '840_517': 34.3638,
    '840_518': 34.4526,
    '840_519': 34.5401,
    '840_520': 34.6019,
    '840_521': 34.6619,
    '840_522': 34.7298,
    '840_523': 34.8007,
    '840_524': 34.8794,
    '840_525': 34.9587,
    '840_526': 35.039,
    '840_527': 35.1203,
    '840_528': 35.2021,
    '840_529': 35.2837,
    '840_530': 35.3659,
    '840_531': 35.4477,
    '840_532': 35.5299,
    '840_533': 35.6134,
    '840_534': 35.6967,
    '840_535': 35.7773,
    '840_536': 35.8565,
    '840_537': 35.9323,
    '840_538': 36.0117,
    '840_539': 36.0956,
    '840_540': 36.1829,
    '840_541': 36.2741,
    '840_542': 36.3717,
    '840_543': 36.4802,
    '840_544': 36.6019,
    '840_545': 36.7326,
    '840_546': 36.8656,
    '840_547': 36.99,
    '840_548': 37.0944,
    '840_549': 37.1934,
    '840_550': 37.281,
    '840_551': 37.3614,
    '840_552': 37.4548,
    '840_553': 37.5675,
    '840_554': 37.673,
    '840_555': 37.7609,
    '840_556': 37.8545,
    '840_557': 37.9301,
    '840_558': 37.9781,
    '840_559': 38.0124,
    '840_560': 38.0162,
    '840_561': 37.9974,
    '840_562': 37.99,
    '840_563': 37.9875,
    '840_564': 37.9985,
    '840_565': 37.993,
    '840_566': 37.982,
    '840_567': 38.0004,
    '840_568': 37.9963,
    '840_569': 37.9579,
    '840_570': 37.9062,
    '840_571': 37.8614,
    '840_572': 37.8108,
    '840_573': 37.7367,
    '840_574': 37.6444,
    '840_575': 37.5661,
    '840_576': 37.5061,
    '840_577': 37.4449,
    '840_578': 37.3774,
    '840_579': 37.3032,
    '840_580': 37.2271,
    '840_581': 37.1435,
    '840_582': 37.0661,
    '840_583': 37.001,
    '840_584': 36.9416,
    '840_585': 36.8908,
    '840_586': 36.8513,
    '840_587': 36.8237,
    '840_588': 36.8046,
    '840_589': 36.7954,
    '840_590': 36.7948,
    '840_591': 36.8015,
    '840_592': 36.8157,
    '840_593': 36.8376,
    '840_594': 36.8662,
    '840_595': 36.9019,
    '840_596': 36.9504,
    '840_597': 37.0031,
    '840_598': 37.0683,
    '840_599': 37.1537,
    '840_600': 37.2403,
    '840_601': 37.3294,
    '840_602': 37.424,
    '840_603': 37.5311,
    '840_604': 37.6529,
    '840_605': 37.7883,
    '840_606': 37.9362,
    '840_607': 38.0949,
    '840_608': 38.2543,
    '840_609': 38.4347,
    '840_610': 38.6144,
    '840_611': 38.783,
    '840_612': 38.9471,
    '840_613': 39.1216,
    '840_614': 39.3014,
    '840_615': 39.4798,
    '840_616': 39.6543,
    '840_617': 39.8339,
    '840_618': 40.0187,
    '840_619': 40.1897,
    '840_620': 40.3455,
    '840_621': 40.4724,
    '840_622': 40.581,
    '840_623': 40.6844,
    '840_624': 40.7863,
    '840_625': 40.8605,
    '840_626': 40.9095,
    '840_627': 40.9323,
    '840_628': 40.9468,
    '840_629': 40.9556,
    '840_630': 40.9692,
    '840_631': 40.9763,
    '840_632': 40.9838,
    '840_633': 41.0301,
    '840_634': 41.0585,
    '840_635': 41.0572,
    '840_636': 41.0166,
    '840_637': 40.9341,
    '840_638': 40.8234,
    '840_639': 40.7224,
    '840_640': 40.6318,
    '840_641': 40.5545,
    '840_642': 40.4777,
    '840_643': 40.3977,
    '840_644': 40.2893,
    '840_645': 40.148,
    '840_646': 39.977,
    '840_647': 39.7792,
    '840_648': 39.573,
    '840_649': 39.3758,
    '840_650': 39.1824,
    '840_651': 38.9759,
    '840_652': 38.7809,
    '840_653': 38.5981,
    '840_654': 38.422,
    '840_655': 38.2518,
    '840_656': 38.0884,
    '840_657': 37.9311,
    '840_658': 37.776,
    '840_659': 37.6207,
    '840_660': 37.4639,
    '840_661': 37.3083,
    '840_662': 37.1591,
    '840_663': 37.0278,
    '840_664': 36.9144,
    '840_665': 36.8145,
    '840_666': 36.7251,
    '840_667': 36.6503,
    '840_668': 36.5888,
    '840_669': 36.5359,
    '840_670': 36.5144,
    '840_671': 36.5048,
    '840_672': 36.5029,
    '840_673': 36.5061,
    '840_674': 36.4973,
    '840_675': 36.4897,
    '840_676': 36.477,
    '840_677': 36.4556,
    '840_678': 36.4259,
    '840_679': 36.3814,
    '840_680': 36.3339,
    '840_681': 36.2879,
    '840_682': 36.2528,
    '840_683': 36.2369,
    '840_684': 36.2374,
    '840_685': 36.2564,
    '840_686': 36.2766,
    '840_687': 36.322,
    '840_688': 36.3746,
    '840_689': 36.434,
    '840_690': 36.514,
    '840_691': 36.5995,
    '840_692': 36.6793,
    '840_693': 36.7523,
    '840_694': 36.8213,
    '840_695': 36.8881,
    '840_696': 36.9483,
    '840_697': 37.0016,
    '840_698': 37.047,
    '840_699': 37.0873,
    '840_700': 37.129,
    '840_701': 37.1754,
    '840_702': 37.2336,
    '840_703': 37.2984,
    '840_704': 37.3666,
    '841_408': 31.5,
    '841_409': 31.5256,
    '841_410': 31.5531,
    '841_411': 31.5824,
    '841_412': 31.6129,
    '841_413': 31.6427,
    '841_414': 31.6723,
    '841_415': 31.7045,
    '841_416': 31.741,
    '841_417': 31.7833,
    '841_418': 31.8316,
    '841_419': 31.8835,
    '841_420': 31.9288,
    '841_421': 31.9597,
    '841_422': 31.9786,
    '841_423': 32.0108,
    '841_424': 32.0691,
    '841_425': 32.1481,
    '841_426': 32.2008,
    '841_427': 32.2255,
    '841_428': 32.2464,
    '841_429': 32.2646,
    '841_430': 32.2822,
    '841_431': 32.2912,
    '841_432': 32.309,
    '841_433': 32.3265,
    '841_434': 32.3465,
    '841_435': 32.3646,
    '841_436': 32.3903,
    '841_437': 32.4205,
    '841_438': 32.4461,
    '841_439': 32.4621,
    '841_440': 32.4586,
    '841_441': 32.4547,
    '841_442': 32.4582,
    '841_443': 32.4645,
    '841_444': 32.4711,
    '841_445': 32.4764,
    '841_446': 32.4741,
    '841_447': 32.4677,
    '841_448': 32.4525,
    '841_449': 32.4376,
    '841_450': 32.4254,
    '841_451': 32.4121,
    '841_452': 32.3926,
    '841_453': 32.3745,
    '841_454': 32.3593,
    '841_455': 32.3502,
    '841_456': 32.3446,
    '841_457': 32.3431,
    '841_458': 32.346,
    '841_459': 32.3446,
    '841_460': 32.3453,
    '841_461': 32.3526,
    '841_462': 32.3575,
    '841_463': 32.3654,
    '841_464': 32.3711,
    '841_465': 32.3757,
    '841_466': 32.3821,
    '841_467': 32.3889,
    '841_468': 32.3951,
    '841_469': 32.3953,
    '841_470': 32.3924,
    '841_471': 32.3852,
    '841_472': 32.38,
    '841_473': 32.3714,
    '841_474': 32.3546,
    '841_475': 32.343,
    '841_476': 32.3345,
    '841_477': 32.3174,
    '841_478': 32.2896,
    '841_479': 32.256,
    '841_480': 32.2398,
    '841_481': 32.2353,
    '841_482': 32.2238,
    '841_483': 32.2127,
    '841_484': 32.2029,
    '841_485': 32.1997,
    '841_486': 32.2016,
    '841_487': 32.2074,
    '841_488': 32.2055,
    '841_489': 32.1721,
    '841_490': 32.1635,
    '841_491': 32.1661,
    '841_492': 32.1737,
    '841_493': 32.1869,
    '841_494': 32.2041,
    '841_495': 32.2281,
    '841_496': 32.2605,
    '841_497': 32.2989,
    '841_498': 32.3406,
    '841_499': 32.3958,
    '841_500': 32.4573,
    '841_501': 32.5239,
    '841_502': 32.5993,
    '841_503': 32.682,
    '841_504': 32.774,
    '841_505': 32.8738,
    '841_506': 32.9747,
    '841_507': 33.0789,
    '841_508': 33.1852,
    '841_509': 33.2977,
    '841_510': 33.4162,
    '841_511': 33.5445,
    '841_512': 33.6801,
    '841_513': 33.8235,
    '841_514': 33.9633,
    '841_515': 34.1022,
    '841_516': 34.2239,
    '841_517': 34.3131,
    '841_518': 34.3954,
    '841_519': 34.4787,
    '841_520': 34.5478,
    '841_521': 34.6209,
    '841_522': 34.6978,
    '841_523': 34.7776,
    '841_524': 34.8596,
    '841_525': 34.9382,
    '841_526': 35.0156,
    '841_527': 35.0932,
    '841_528': 35.171,
    '841_529': 35.2488,
    '841_530': 35.3265,
    '841_531': 35.4039,
    '841_532': 35.4814,
    '841_533': 35.5589,
    '841_534': 35.6365,
    '841_535': 35.7126,
    '841_536': 35.7885,
    '841_537': 35.8636,
    '841_538': 35.9404,
    '841_539': 36.0202,
    '841_540': 36.103,
    '841_541': 36.1887,
    '841_542': 36.2805,
    '841_543': 36.3836,
    '841_544': 36.506,
    '841_545': 36.6386,
    '841_546': 36.7707,
    '841_547': 36.8918,
    '841_548': 37.0074,
    '841_549': 37.1195,
    '841_550': 37.2088,
    '841_551': 37.285,
    '841_552': 37.3688,
    '841_553': 37.4662,
    '841_554': 37.5596,
    '841_555': 37.6375,
    '841_556': 37.7222,
    '841_557': 37.7855,
    '841_558': 37.8275,
    '841_559': 37.8671,
    '841_560': 37.8833,
    '841_561': 37.8753,
    '841_562': 37.8762,
    '841_563': 37.8811,
    '841_564': 37.8982,
    '841_565': 37.8958,
    '841_566': 37.8769,
    '841_567': 37.8933,
    '841_568': 37.8895,
    '841_569': 37.8452,
    '841_570': 37.7965,
    '841_571': 37.7537,
    '841_572': 37.7129,
    '841_573': 37.6538,
    '841_574': 37.5767,
    '841_575': 37.5004,
    '841_576': 37.4264,
    '841_577': 37.368,
    '841_578': 37.2996,
    '841_579': 37.2276,
    '841_580': 37.1597,
    '841_581': 37.0883,
    '841_582': 37.0216,
    '841_583': 36.9692,
    '841_584': 36.9233,
    '841_585': 36.8814,
    '841_586': 36.8483,
    '841_587': 36.8253,
    '841_588': 36.8092,
    '841_589': 36.8017,
    '841_590': 36.8023,
    '841_591': 36.8097,
    '841_592': 36.8244,
    '841_593': 36.8465,
    '841_594': 36.8754,
    '841_595': 36.9112,
    '841_596': 36.9597,
    '841_597': 37.0131,
    '841_598': 37.0789,
    '841_599': 37.1596,
    '841_600': 37.2473,
    '841_601': 37.3371,
    '841_602': 37.4333,
    '841_603': 37.538,
    '841_604': 37.6558,
    '841_605': 37.786,
    '841_606': 37.9287,
    '841_607': 38.082,
    '841_608': 38.2478,
    '841_609': 38.4279,
    '841_610': 38.6068,
    '841_611': 38.7745,
    '841_612': 38.9396,
    '841_613': 39.1225,
    '841_614': 39.3102,
    '841_615': 39.4914,
    '841_616': 39.6633,
    '841_617': 39.8407,
    '841_618': 40.0272,
    '841_619': 40.1995,
    '841_620': 40.3541,
    '841_621': 40.4892,
    '841_622': 40.607,
    '841_623': 40.7155,
    '841_624': 40.8062,
    '841_625': 40.8769,
    '841_626': 40.9221,
    '841_627': 40.9495,
    '841_628': 40.9704,
    '841_629': 40.9919,
    '841_630': 41.0082,
    '841_631': 41.0121,
    '841_632': 41.0317,
    '841_633': 41.0879,
    '841_634': 41.122,
    '841_635': 41.118,
    '841_636': 41.0796,
    '841_637': 40.9996,
    '841_638': 40.8915,
    '841_639': 40.7929,
    '841_640': 40.7157,
    '841_641': 40.6527,
    '841_642': 40.579,
    '841_643': 40.4945,
    '841_644': 40.3909,
    '841_645': 40.2527,
    '841_646': 40.0851,
    '841_647': 39.8869,
    '841_648': 39.6945,
    '841_649': 39.4981,
    '841_650': 39.2913,
    '841_651': 39.0897,
    '841_652': 38.8942,
    '841_653': 38.7166,
    '841_654': 38.5469,
    '841_655': 38.3834,
    '841_656': 38.2258,
    '841_657': 38.0714,
    '841_658': 37.9194,
    '841_659': 37.7691,
    '841_660': 37.6197,
    '841_661': 37.4712,
    '841_662': 37.3306,
    '841_663': 37.2083,
    '841_664': 37.0952,
    '841_665': 36.9929,
    '841_666': 36.9009,
    '841_667': 36.8227,
    '841_668': 36.7575,
    '841_669': 36.7057,
    '841_670': 36.6802,
    '841_671': 36.6675,
    '841_672': 36.6604,
    '841_673': 36.651,
    '841_674': 36.6334,
    '841_675': 36.6165,
    '841_676': 36.5931,
    '841_677': 36.5602,
    '841_678': 36.5195,
    '841_679': 36.4687,
    '841_680': 36.4143,
    '841_681': 36.3619,
    '841_682': 36.3204,
    '841_683': 36.2999,
    '841_684': 36.2916,
    '841_685': 36.3126,
    '841_686': 36.3368,
    '841_687': 36.3773,
    '841_688': 36.4284,
    '841_689': 36.4852,
    '841_690': 36.5527,
    '841_691': 36.6257,
    '841_692': 36.7021,
    '841_693': 36.7721,
    '841_694': 36.8347,
    '841_695': 36.8988,
    '841_696': 36.9598,
    '841_697': 37.0151,
    '841_698': 37.0643,
    '841_699': 37.1056,
    '841_700': 37.1475,
    '841_701': 37.1955,
    '841_702': 37.2585,
    '841_703': 37.3285,
    '841_704': 37.4029,
    '841_778': 40.7394,
    '841_779': 40.7427,
    '841_780': 40.7138,
    '841_781': 40.6769,
    '841_782': 40.6218,
    '841_783': 40.5526,
    '841_784': 40.4794,
    '842_408': 31.4414,
    '842_409': 31.4628,
    '842_410': 31.4866,
    '842_411': 31.5128,
    '842_412': 31.5406,
    '842_413': 31.5671,
    '842_414': 31.5927,
    '842_415': 31.6225,
    '842_416': 31.6588,
    '842_417': 31.7029,
    '842_418': 31.7548,
    '842_419': 31.81,
    '842_420': 31.857,
    '842_421': 31.8796,
    '842_422': 31.879,
    '842_423': 31.9082,
    '842_424': 31.9838,
    '842_425': 32.1073,
    '842_426': 32.1682,
    '842_427': 32.2011,
    '842_428': 32.2211,
    '842_429': 32.243,
    '842_430': 32.2622,
    '842_431': 32.2785,
    '842_432': 32.3057,
    '842_433': 32.324,
    '842_434': 32.3408,
    '842_435': 32.3573,
    '842_436': 32.3826,
    '842_437': 32.4188,
    '842_438': 32.4496,
    '842_439': 32.4598,
    '842_440': 32.4528,
    '842_441': 32.4433,
    '842_442': 32.4463,
    '842_443': 32.4539,
    '842_444': 32.4647,
    '842_445': 32.4727,
    '842_446': 32.4698,
    '842_447': 32.4625,
    '842_448': 32.4502,
    '842_449': 32.4399,
    '842_450': 32.4283,
    '842_451': 32.4122,
    '842_452': 32.3987,
    '842_453': 32.3855,
    '842_454': 32.3698,
    '842_455': 32.3618,
    '842_456': 32.3587,
    '842_457': 32.3612,
    '842_458': 32.368,
    '842_459': 32.3662,
    '842_460': 32.3665,
    '842_461': 32.3725,
    '842_462': 32.3795,
    '842_463': 32.3896,
    '842_464': 32.403,
    '842_465': 32.417,
    '842_466': 32.4261,
    '842_467': 32.4338,
    '842_468': 32.439,
    '842_469': 32.4399,
    '842_470': 32.4375,
    '842_471': 32.4274,
    '842_472': 32.419,
    '842_473': 32.4125,
    '842_474': 32.4057,
    '842_475': 32.3977,
    '842_476': 32.3863,
    '842_477': 32.3642,
    '842_478': 32.3311,
    '842_479': 32.2956,
    '842_480': 32.275,
    '842_481': 32.266,
    '842_482': 32.2507,
    '842_483': 32.2384,
    '842_484': 32.2286,
    '842_485': 32.2359,
    '842_486': 32.2445,
    '842_487': 32.2399,
    '842_488': 32.2269,
    '842_489': 32.2008,
    '842_490': 32.1951,
    '842_491': 32.1978,
    '842_492': 32.2067,
    '842_493': 32.2208,
    '842_494': 32.2376,
    '842_495': 32.2632,
    '842_496': 32.2972,
    '842_497': 32.3366,
    '842_498': 32.3804,
    '842_499': 32.434,
    '842_500': 32.4965,
    '842_501': 32.5675,
    '842_502': 32.6426,
    '842_503': 32.7232,
    '842_504': 32.8135,
    '842_505': 32.9076,
    '842_506': 33.0061,
    '842_507': 33.1095,
    '842_508': 33.2144,
    '842_509': 33.3234,
    '842_510': 33.4365,
    '842_511': 33.5528,
    '842_512': 33.6749,
    '842_513': 33.8014,
    '842_514': 33.9292,
    '842_515': 34.0587,
    '842_516': 34.1783,
    '842_517': 34.2687,
    '842_518': 34.353,
    '842_519': 34.4284,
    '842_520': 34.5068,
    '842_521': 34.5894,
    '842_522': 34.6721,
    '842_523': 34.7565,
    '842_524': 34.8399,
    '842_525': 34.9192,
    '842_526': 34.9956,
    '842_527': 35.0709,
    '842_528': 35.1457,
    '842_529': 35.2201,
    '842_530': 35.2943,
    '842_531': 35.368,
    '842_532': 35.4417,
    '842_533': 35.5154,
    '842_534': 35.5889,
    '842_535': 35.6616,
    '842_536': 35.7343,
    '842_537': 35.8073,
    '842_538': 35.8815,
    '842_539': 35.9579,
    '842_540': 36.037,
    '842_541': 36.119,
    '842_542': 36.2056,
    '842_543': 36.3024,
    '842_544': 36.4182,
    '842_545': 36.5483,
    '842_546': 36.6734,
    '842_547': 36.7897,
    '842_548': 36.9089,
    '842_549': 37.0346,
    '842_550': 37.1347,
    '842_551': 37.2176,
    '842_552': 37.2893,
    '842_553': 37.366,
    '842_554': 37.4411,
    '842_555': 37.5107,
    '842_556': 37.5846,
    '842_557': 37.6429,
    '842_558': 37.6875,
    '842_559': 37.7258,
    '842_560': 37.7517,
    '842_561': 37.7629,
    '842_562': 37.7734,
    '842_563': 37.783,
    '842_564': 37.8027,
    '842_565': 37.802,
    '842_566': 37.7784,
    '842_567': 37.7715,
    '842_568': 37.7642,
    '842_569': 37.7173,
    '842_570': 37.6801,
    '842_571': 37.6457,
    '842_572': 37.6088,
    '842_573': 37.5629,
    '842_574': 37.5009,
    '842_575': 37.4315,
    '842_576': 37.3534,
    '842_577': 37.2853,
    '842_578': 37.2166,
    '842_579': 37.1524,
    '842_580': 37.093,
    '842_581': 37.0343,
    '842_582': 36.9772,
    '842_583': 36.9323,
    '842_584': 36.8937,
    '842_585': 36.8639,
    '842_586': 36.8398,
    '842_587': 36.8216,
    '842_588': 36.8094,
    '842_589': 36.8046,
    '842_590': 36.8068,
    '842_591': 36.8155,
    '842_592': 36.8308,
    '842_593': 36.8536,
    '842_594': 36.8827,
    '842_595': 36.9187,
    '842_596': 36.9672,
    '842_597': 37.0206,
    '842_598': 37.0851,
    '842_599': 37.1619,
    '842_600': 37.2488,
    '842_601': 37.3399,
    '842_602': 37.4368,
    '842_603': 37.5426,
    '842_604': 37.6584,
    '842_605': 37.7865,
    '842_606': 37.9259,
    '842_607': 38.076,
    '842_608': 38.2381,
    '842_609': 38.4111,
    '842_610': 38.5832,
    '842_611': 38.7483,
    '842_612': 38.9223,
    '842_613': 39.1104,
    '842_614': 39.2945,
    '842_615': 39.4738,
    '842_616': 39.6462,
    '842_617': 39.8285,
    '842_618': 40.0122,
    '842_619': 40.1863,
    '842_620': 40.3428,
    '842_621': 40.489,
    '842_622': 40.6186,
    '842_623': 40.7306,
    '842_624': 40.8159,
    '842_625': 40.8862,
    '842_626': 40.9285,
    '842_627': 40.9559,
    '842_628': 40.9799,
    '842_629': 41.0085,
    '842_630': 41.0295,
    '842_631': 41.0391,
    '842_632': 41.0719,
    '842_633': 41.1304,
    '842_634': 41.1692,
    '842_635': 41.1758,
    '842_636': 41.1413,
    '842_637': 41.065,
    '842_638': 40.96,
    '842_639': 40.8624,
    '842_640': 40.7996,
    '842_641': 40.7399,
    '842_642': 40.6695,
    '842_643': 40.5818,
    '842_644': 40.4766,
    '842_645': 40.3383,
    '842_646': 40.172,
    '842_647': 39.9737,
    '842_648': 39.7815,
    '842_649': 39.5941,
    '842_650': 39.3823,
    '842_651': 39.183,
    '842_652': 38.9982,
    '842_653': 38.8271,
    '842_654': 38.6634,
    '842_655': 38.5058,
    '842_656': 38.3533,
    '842_657': 38.2033,
    '842_658': 38.0553,
    '842_659': 37.9101,
    '842_660': 37.7677,
    '842_661': 37.6272,
    '842_662': 37.4953,
    '842_663': 37.3764,
    '842_664': 37.2676,
    '842_665': 37.1682,
    '842_666': 37.0763,
    '842_667': 36.9944,
    '842_668': 36.9254,
    '842_669': 36.8707,
    '842_670': 36.8461,
    '842_671': 36.8299,
    '842_672': 36.8184,
    '842_673': 36.797,
    '842_674': 36.7706,
    '842_675': 36.7492,
    '842_676': 36.7106,
    '842_677': 36.665,
    '842_678': 36.6114,
    '842_679': 36.5491,
    '842_680': 36.475,
    '842_681': 36.4307,
    '842_682': 36.3904,
    '842_683': 36.376,
    '842_684': 36.368,
    '842_685': 36.3778,
    '842_686': 36.4025,
    '842_687': 36.4397,
    '842_688': 36.4865,
    '842_689': 36.5394,
    '842_690': 36.5995,
    '842_691': 36.6637,
    '842_692': 36.7305,
    '842_693': 36.7896,
    '842_694': 36.8453,
    '842_695': 36.9115,
    '842_696': 36.9742,
    '842_697': 37.031,
    '842_698': 37.0815,
    '842_699': 37.1265,
    '842_700': 37.1709,
    '842_701': 37.2209,
    '842_702': 37.2864,
    '842_703': 37.36,
    '842_704': 37.4392,
    '842_778': 40.8102,
    '842_779': 40.8111,
    '842_780': 40.7922,
    '842_781': 40.7499,
    '842_782': 40.6806,
    '842_783': 40.5927,
    '842_784': 40.4981,
    '843_364': 30.1815,
    '843_365': 30.2852,
    '843_366': 30.3988,
    '843_367': 30.4909,
    '843_368': 30.5567,
    '843_369': 30.6046,
    '843_370': 30.6359,
    '843_371': 30.6524,
    '843_372': 30.6592,
    '843_373': 30.6604,
    '843_374': 30.6639,
    '843_375': 30.6614,
    '843_376': 30.6439,
    '843_377': 30.6327,
    '843_378': 30.6299,
    '843_379': 30.6353,
    '843_380': 30.6487,
    '843_381': 30.6758,
    '843_382': 30.7116,
    '843_408': 31.3791,
    '843_409': 31.3956,
    '843_410': 31.4148,
    '843_411': 31.4368,
    '843_412': 31.4605,
    '843_413': 31.4814,
    '843_414': 31.5037,
    '843_415': 31.5313,
    '843_416': 31.5665,
    '843_417': 31.6109,
    '843_418': 31.6648,
    '843_419': 31.7244,
    '843_420': 31.7737,
    '843_421': 31.7756,
    '843_422': 31.7782,
    '843_423': 31.8404,
    '843_424': 31.9719,
    '843_425': 32.075,
    '843_426': 32.1314,
    '843_427': 32.167,
    '843_428': 32.1971,
    '843_429': 32.2282,
    '843_430': 32.2479,
    '843_431': 32.2759,
    '843_432': 32.2987,
    '843_433': 32.3168,
    '843_434': 32.3336,
    '843_435': 32.3521,
    '843_436': 32.3819,
    '843_437': 32.4248,
    '843_438': 32.4567,
    '843_439': 32.4579,
    '843_440': 32.4453,
    '843_441': 32.4388,
    '843_442': 32.4422,
    '843_443': 32.4538,
    '843_444': 32.4654,
    '843_445': 32.4736,
    '843_446': 32.4706,
    '843_447': 32.467,
    '843_448': 32.4605,
    '843_449': 32.4483,
    '843_450': 32.4372,
    '843_451': 32.4211,
    '843_452': 32.4103,
    '843_453': 32.4009,
    '843_454': 32.3867,
    '843_455': 32.3771,
    '843_456': 32.3756,
    '843_457': 32.3819,
    '843_458': 32.3916,
    '843_459': 32.3894,
    '843_460': 32.3905,
    '843_461': 32.397,
    '843_462': 32.4047,
    '843_463': 32.4182,
    '843_464': 32.4384,
    '843_465': 32.4672,
    '843_466': 32.4777,
    '843_467': 32.484,
    '843_468': 32.4865,
    '843_469': 32.4835,
    '843_470': 32.4793,
    '843_471': 32.4725,
    '843_472': 32.4669,
    '843_473': 32.468,
    '843_474': 32.4717,
    '843_475': 32.4684,
    '843_476': 32.4577,
    '843_477': 32.4308,
    '843_478': 32.389,
    '843_479': 32.3468,
    '843_480': 32.3169,
    '843_481': 32.299,
    '843_482': 32.2797,
    '843_483': 32.2686,
    '843_484': 32.2632,
    '843_485': 32.2785,
    '843_486': 32.291,
    '843_487': 32.2872,
    '843_488': 32.2539,
    '843_489': 32.2287,
    '843_490': 32.224,
    '843_491': 32.2271,
    '843_492': 32.2366,
    '843_493': 32.2516,
    '843_494': 32.2697,
    '843_495': 32.2957,
    '843_496': 32.3303,
    '843_497': 32.3716,
    '843_498': 32.4184,
    '843_499': 32.4732,
    '843_500': 32.5369,
    '843_501': 32.6089,
    '843_502': 32.6813,
    '843_503': 32.7618,
    '843_504': 32.8492,
    '843_505': 32.9427,
    '843_506': 33.0392,
    '843_507': 33.1392,
    '843_508': 33.2407,
    '843_509': 33.3463,
    '843_510': 33.4554,
    '843_511': 33.5652,
    '843_512': 33.6762,
    '843_513': 33.7906,
    '843_514': 33.9062,
    '843_515': 34.022,
    '843_516': 34.1324,
    '843_517': 34.2261,
    '843_518': 34.3171,
    '843_519': 34.3956,
    '843_520': 34.4778,
    '843_521': 34.5642,
    '843_522': 34.6515,
    '843_523': 34.7386,
    '843_524': 34.8238,
    '843_525': 34.9024,
    '843_526': 34.9789,
    '843_527': 35.0527,
    '843_528': 35.1254,
    '843_529': 35.1972,
    '843_530': 35.2684,
    '843_531': 35.3391,
    '843_532': 35.4096,
    '843_533': 35.4801,
    '843_534': 35.5503,
    '843_535': 35.6201,
    '843_536': 35.6903,
    '843_537': 35.7609,
    '843_538': 35.8325,
    '843_539': 35.906,
    '843_540': 35.9818,
    '843_541': 36.0601,
    '843_542': 36.1418,
    '843_543': 36.2309,
    '843_544': 36.331,
    '843_545': 36.4449,
    '843_546': 36.5657,
    '843_547': 36.6828,
    '843_548': 36.7974,
    '843_549': 36.9283,
    '843_550': 37.0396,
    '843_551': 37.1353,
    '843_552': 37.215,
    '843_553': 37.2873,
    '843_554': 37.3601,
    '843_555': 37.4223,
    '843_556': 37.4904,
    '843_557': 37.5493,
    '843_558': 37.5936,
    '843_559': 37.6304,
    '843_560': 37.6594,
    '843_561': 37.677,
    '843_562': 37.6852,
    '843_563': 37.6902,
    '843_564': 37.6987,
    '843_565': 37.6963,
    '843_566': 37.6764,
    '843_567': 37.6565,
    '843_568': 37.6429,
    '843_569': 37.6007,
    '843_570': 37.5689,
    '843_571': 37.5359,
    '843_572': 37.5002,
    '843_573': 37.4569,
    '843_574': 37.4,
    '843_575': 37.3413,
    '843_576': 37.2792,
    '843_577': 37.2096,
    '843_578': 37.1477,
    '843_579': 37.0903,
    '843_580': 37.0398,
    '843_581': 36.9903,
    '843_582': 36.9371,
    '843_583': 36.897,
    '843_584': 36.8642,
    '843_585': 36.8439,
    '843_586': 36.8279,
    '843_587': 36.8153,
    '843_588': 36.8072,
    '843_589': 36.8052,
    '843_590': 36.8094,
    '843_591': 36.8194,
    '843_592': 36.8358,
    '843_593': 36.8591,
    '843_594': 36.8885,
    '843_595': 36.9246,
    '843_596': 36.9724,
    '843_597': 37.0249,
    '843_598': 37.0869,
    '843_599': 37.16,
    '843_600': 37.2482,
    '843_601': 37.3402,
    '843_602': 37.4368,
    '843_603': 37.5424,
    '843_604': 37.6609,
    '843_605': 37.7887,
    '843_606': 37.9297,
    '843_607': 38.0799,
    '843_608': 38.2322,
    '843_609': 38.3978,
    '843_610': 38.5619,
    '843_611': 38.7332,
    '843_612': 38.9104,
    '843_613': 39.0869,
    '843_614': 39.2626,
    '843_615': 39.4374,
    '843_616': 39.6107,
    '843_617': 39.7926,
    '843_618': 39.974,
    '843_619': 40.1483,
    '843_620': 40.3143,
    '843_621': 40.4707,
    '843_622': 40.6117,
    '843_623': 40.7274,
    '843_624': 40.821,
    '843_625': 40.8934,
    '843_626': 40.9404,
    '843_627': 40.9698,
    '843_628': 40.9925,
    '843_629': 41.015,
    '843_630': 41.0349,
    '843_631': 41.0587,
    '843_632': 41.0987,
    '843_633': 41.1607,
    '843_634': 41.2115,
    '843_635': 41.2286,
    '843_636': 41.2018,
    '843_637': 41.1315,
    '843_638': 41.0305,
    '843_639': 40.9318,
    '843_640': 40.8682,
    '843_641': 40.8086,
    '843_642': 40.7447,
    '843_643': 40.6612,
    '843_644': 40.5508,
    '843_645': 40.4075,
    '843_646': 40.2369,
    '843_647': 40.0363,
    '843_648': 39.8376,
    '843_649': 39.6606,
    '843_650': 39.4619,
    '843_651': 39.2715,
    '843_652': 39.0959,
    '843_653': 38.9305,
    '843_654': 38.7723,
    '843_655': 38.6198,
    '843_656': 38.4721,
    '843_657': 38.3269,
    '843_658': 38.1827,
    '843_659': 38.0416,
    '843_660': 37.9051,
    '843_661': 37.7713,
    '843_662': 37.6464,
    '843_663': 37.5337,
    '843_664': 37.4313,
    '843_665': 37.3386,
    '843_666': 37.2527,
    '843_667': 37.1663,
    '843_668': 37.0924,
    '843_669': 37.0358,
    '843_670': 37.008,
    '843_671': 36.9861,
    '843_672': 36.9687,
    '843_673': 36.9429,
    '843_674': 36.9107,
    '843_675': 36.8751,
    '843_676': 36.8262,
    '843_677': 36.7701,
    '843_678': 36.7055,
    '843_679': 36.6385,
    '843_680': 36.5667,
    '843_681': 36.5086,
    '843_682': 36.4613,
    '843_683': 36.4427,
    '843_684': 36.4422,
    '843_685': 36.45,
    '843_686': 36.4719,
    '843_687': 36.5044,
    '843_688': 36.5458,
    '843_689': 36.5949,
    '843_690': 36.6504,
    '843_691': 36.7064,
    '843_692': 36.7635,
    '843_693': 36.8167,
    '843_694': 36.8701,
    '843_695': 36.9319,
    '843_696': 36.9947,
    '843_697': 37.0524,
    '843_698': 37.1055,
    '843_699': 37.1517,
    '843_700': 37.1986,
    '843_701': 37.2511,
    '843_702': 37.3158,
    '843_703': 37.3907,
    '843_704': 37.4727,
    '843_778': 40.8646,
    '843_779': 40.8796,
    '843_780': 40.8777,
    '843_781': 40.8334,
    '843_782': 40.742,
    '843_783': 40.6301,
    '843_784': 40.5129,
    '844_364': 30.1611,
    '844_365': 30.2767,
    '844_366': 30.3878,
    '844_367': 30.4815,
    '844_368': 30.5529,
    '844_369': 30.605,
    '844_370': 30.64,
    '844_371': 30.6606,
    '844_372': 30.6715,
    '844_373': 30.6766,
    '844_374': 30.6795,
    '844_375': 30.6752,
    '844_376': 30.6514,
    '844_377': 30.6322,
    '844_378': 30.623,
    '844_379': 30.626,
    '844_380': 30.6382,
    '844_381': 30.6577,
    '844_382': 30.6842,
    '844_420': 31.6716,
    '844_421': 31.6785,
    '844_422': 31.7205,
    '844_423': 31.8506,
    '844_424': 31.9615,
    '844_425': 32.0379,
    '844_426': 32.0877,
    '844_427': 32.125,
    '844_428': 32.1606,
    '844_429': 32.1997,
    '844_430': 32.2332,
    '844_431': 32.2632,
    '844_432': 32.285,
    '844_433': 32.3042,
    '844_434': 32.3204,
    '844_435': 32.3441,
    '844_436': 32.3795,
    '844_437': 32.4293,
    '844_438': 32.4583,
    '844_439': 32.4646,
    '844_440': 32.4481,
    '844_441': 32.4411,
    '844_442': 32.4436,
    '844_443': 32.4579,
    '844_444': 32.4734,
    '844_445': 32.481,
    '844_446': 32.4822,
    '844_447': 32.4804,
    '844_448': 32.4799,
    '844_449': 32.4682,
    '844_450': 32.453,
    '844_451': 32.4395,
    '844_452': 32.4287,
    '844_453': 32.4218,
    '844_454': 32.4111,
    '844_455': 32.3986,
    '844_456': 32.3952,
    '844_457': 32.4006,
    '844_458': 32.407,
    '844_459': 32.4107,
    '844_460': 32.4176,
    '844_461': 32.4315,
    '844_462': 32.438,
    '844_463': 32.4522,
    '844_464': 32.4799,
    '844_465': 32.5215,
    '844_466': 32.539,
    '844_467': 32.5312,
    '844_468': 32.528,
    '844_469': 32.5287,
    '844_470': 32.5264,
    '844_471': 32.5248,
    '844_472': 32.5288,
    '844_473': 32.5401,
    '844_474': 32.5464,
    '844_475': 32.5375,
    '844_476': 32.5252,
    '844_477': 32.5029,
    '844_478': 32.4672,
    '844_479': 32.4181,
    '844_480': 32.3688,
    '844_481': 32.3362,
    '844_482': 32.3108,
    '844_483': 32.2972,
    '844_484': 32.2947,
    '844_485': 32.3128,
    '844_486': 32.3384,
    '844_487': 32.3285,
    '844_488': 32.2844,
    '844_489': 32.2556,
    '844_490': 32.2507,
    '844_491': 32.2541,
    '844_492': 32.2643,
    '844_493': 32.2806,
    '844_494': 32.3017,
    '844_495': 32.3267,
    '844_496': 32.3621,
    '844_497': 32.4078,
    '844_498': 32.4568,
    '844_499': 32.5151,
    '844_500': 32.5783,
    '844_501': 32.648,
    '844_502': 32.7194,
    '844_503': 32.7989,
    '844_504': 32.8836,
    '844_505': 32.9735,
    '844_506': 33.0681,
    '844_507': 33.1643,
    '844_508': 33.2654,
    '844_509': 33.3669,
    '844_510': 33.4718,
    '844_511': 33.5765,
    '844_512': 33.6821,
    '844_513': 33.7872,
    '844_514': 33.8934,
    '844_515': 33.9981,
    '844_516': 34.0994,
    '844_517': 34.1944,
    '844_518': 34.2888,
    '844_519': 34.3746,
    '844_520': 34.4568,
    '844_521': 34.545,
    '844_522': 34.6348,
    '844_523': 34.7217,
    '844_524': 34.8065,
    '844_525': 34.8866,
    '844_526': 34.9636,
    '844_527': 35.0369,
    '844_528': 35.1079,
    '844_529': 35.1776,
    '844_530': 35.2464,
    '844_531': 35.3146,
    '844_532': 35.3824,
    '844_533': 35.4503,
    '844_534': 35.5177,
    '844_535': 35.585,
    '844_536': 35.6528,
    '844_537': 35.7211,
    '844_538': 35.7904,
    '844_539': 35.8613,
    '844_540': 35.9341,
    '844_541': 36.0089,
    '844_542': 36.0864,
    '844_543': 36.1684,
    '844_544': 36.256,
    '844_545': 36.3518,
    '844_546': 36.4576,
    '844_547': 36.569,
    '844_548': 36.6822,
    '844_549': 36.8045,
    '844_550': 36.9231,
    '844_551': 37.0356,
    '844_552': 37.1285,
    '844_553': 37.211,
    '844_554': 37.285,
    '844_555': 37.3539,
    '844_556': 37.4279,
    '844_557': 37.4887,
    '844_558': 37.5349,
    '844_559': 37.5822,
    '844_560': 37.6187,
    '844_561': 37.6345,
    '844_562': 37.6389,
    '844_563': 37.6328,
    '844_564': 37.6248,
    '844_565': 37.6135,
    '844_566': 37.5913,
    '844_567': 37.5662,
    '844_568': 37.5412,
    '844_569': 37.5017,
    '844_570': 37.4666,
    '844_571': 37.4314,
    '844_572': 37.397,
    '844_573': 37.3579,
    '844_574': 37.3052,
    '844_575': 37.2546,
    '844_576': 37.1984,
    '844_577': 37.1376,
    '844_578': 37.0848,
    '844_579': 37.0329,
    '844_580': 36.9889,
    '844_581': 36.9492,
    '844_582': 36.901,
    '844_583': 36.8624,
    '844_584': 36.8382,
    '844_585': 36.8259,
    '844_586': 36.8159,
    '844_587': 36.8074,
    '844_588': 36.8024,
    '844_589': 36.8029,
    '844_590': 36.8091,
    '844_591': 36.8206,
    '844_592': 36.8381,
    '844_593': 36.862,
    '844_594': 36.8917,
    '844_595': 36.9275,
    '844_596': 36.9745,
    '844_597': 37.0263,
    '844_598': 37.0879,
    '844_599': 37.1611,
    '844_600': 37.2475,
    '844_601': 37.339,
    '844_602': 37.4326,
    '844_603': 37.537,
    '844_604': 37.6602,
    '844_605': 37.7915,
    '844_606': 37.9314,
    '844_607': 38.0808,
    '844_608': 38.2335,
    '844_609': 38.3934,
    '844_610': 38.5602,
    '844_611': 38.7338,
    '844_612': 38.9116,
    '844_613': 39.0827,
    '844_614': 39.2454,
    '844_615': 39.4085,
    '844_616': 39.5781,
    '844_617': 39.7458,
    '844_618': 39.9206,
    '844_619': 40.0964,
    '844_620': 40.27,
    '844_621': 40.4361,
    '844_622': 40.5833,
    '844_623': 40.7088,
    '844_624': 40.8087,
    '844_625': 40.8847,
    '844_626': 40.9441,
    '844_627': 40.9859,
    '844_628': 41.0118,
    '844_629': 41.0273,
    '844_630': 41.0416,
    '844_631': 41.0819,
    '844_632': 41.1272,
    '844_633': 41.1853,
    '844_634': 41.2424,
    '844_635': 41.278,
    '844_636': 41.2604,
    '844_637': 41.1988,
    '844_638': 41.1027,
    '844_639': 41.0009,
    '844_640': 40.9347,
    '844_641': 40.8722,
    '844_642': 40.8079,
    '844_643': 40.7255,
    '844_644': 40.6137,
    '844_645': 40.4626,
    '844_646': 40.2867,
    '844_647': 40.0841,
    '844_648': 39.8863,
    '844_649': 39.7104,
    '844_650': 39.5309,
    '844_651': 39.3558,
    '844_652': 39.1877,
    '844_653': 39.0267,
    '844_654': 38.8729,
    '844_655': 38.7251,
    '844_656': 38.582,
    '844_657': 38.4408,
    '844_658': 38.2993,
    '844_659': 38.1607,
    '844_660': 38.0277,
    '844_661': 37.9025,
    '844_662': 37.7867,
    '844_663': 37.6803,
    '844_664': 37.5824,
    '844_665': 37.4923,
    '844_666': 37.4071,
    '844_667': 37.324,
    '844_668': 37.2517,
    '844_669': 37.1965,
    '844_670': 37.1644,
    '844_671': 37.1427,
    '844_672': 37.1203,
    '844_673': 37.0892,
    '844_674': 37.0495,
    '844_675': 37.0036,
    '844_676': 36.9447,
    '844_677': 36.8808,
    '844_678': 36.8032,
    '844_679': 36.7303,
    '844_680': 36.6624,
    '844_681': 36.5967,
    '844_682': 36.5407,
    '844_683': 36.5119,
    '844_684': 36.5103,
    '844_685': 36.5205,
    '844_686': 36.5424,
    '844_687': 36.5702,
    '844_688': 36.6063,
    '844_689': 36.6511,
    '844_690': 36.7033,
    '844_691': 36.7563,
    '844_692': 36.8057,
    '844_693': 36.8525,
    '844_694': 36.9008,
    '844_695': 36.9586,
    '844_696': 37.0187,
    '844_697': 37.0756,
    '844_698': 37.1281,
    '844_699': 37.1758,
    '844_700': 37.2244,
    '844_701': 37.276,
    '844_702': 37.3406,
    '844_703': 37.4166,
    '844_704': 37.5008,
    '844_778': 40.8993,
    '844_779': 40.9255,
    '844_780': 40.9399,
    '844_781': 40.9031,
    '844_782': 40.7927,
    '844_783': 40.6553,
    '844_784': 40.5202,
    '845_364': 30.1427,
    '845_365': 30.2629,
    '845_366': 30.376,
    '845_367': 30.4738,
    '845_368': 30.5501,
    '845_369': 30.6076,
    '845_370': 30.6459,
    '845_371': 30.6705,
    '845_372': 30.6852,
    '845_373': 30.6931,
    '845_374': 30.6942,
    '845_375': 30.6847,
    '845_376': 30.6618,
    '845_377': 30.6368,
    '845_378': 30.6231,
    '845_379': 30.6205,
    '845_380': 30.629,
    '845_381': 30.6435,
    '845_382': 30.6616,
    '845_420': 31.5406,
    '845_421': 31.5951,
    '845_422': 31.7142,
    '845_423': 31.8469,
    '845_424': 31.9354,
    '845_425': 31.9967,
    '845_426': 32.044,
    '845_427': 32.0888,
    '845_428': 32.1351,
    '845_429': 32.1876,
    '845_430': 32.2165,
    '845_431': 32.2384,
    '845_432': 32.262,
    '845_433': 32.287,
    '845_434': 32.3059,
    '845_435': 32.3332,
    '845_436': 32.3734,
    '845_437': 32.4202,
    '845_438': 32.4507,
    '845_439': 32.4613,
    '845_440': 32.457,
    '845_441': 32.4542,
    '845_442': 32.4522,
    '845_443': 32.4621,
    '845_444': 32.4837,
    '845_445': 32.4938,
    '845_446': 32.5018,
    '845_447': 32.4999,
    '845_448': 32.5013,
    '845_449': 32.4937,
    '845_450': 32.4791,
    '845_451': 32.467,
    '845_452': 32.4535,
    '845_453': 32.4472,
    '845_454': 32.4399,
    '845_455': 32.4276,
    '845_456': 32.416,
    '845_457': 32.4182,
    '845_458': 32.4262,
    '845_459': 32.4389,
    '845_460': 32.4504,
    '845_461': 32.4679,
    '845_462': 32.4779,
    '845_463': 32.4866,
    '845_464': 32.5203,
    '845_465': 32.5667,
    '845_466': 32.5852,
    '845_467': 32.5698,
    '845_468': 32.5704,
    '845_469': 32.5751,
    '845_470': 32.5849,
    '845_471': 32.5853,
    '845_472': 32.5976,
    '845_473': 32.6154,
    '845_474': 32.6159,
    '845_475': 32.6001,
    '845_476': 32.5823,
    '845_477': 32.5648,
    '845_478': 32.5405,
    '845_479': 32.4991,
    '845_480': 32.4332,
    '845_481': 32.3839,
    '845_482': 32.3503,
    '845_483': 32.336,
    '845_484': 32.3392,
    '845_485': 32.3622,
    '845_486': 32.3849,
    '845_487': 32.3688,
    '845_488': 32.3168,
    '845_489': 32.2827,
    '845_490': 32.2768,
    '845_491': 32.2803,
    '845_492': 32.2911,
    '845_493': 32.3086,
    '845_494': 32.3315,
    '845_495': 32.3583,
    '845_496': 32.3952,
    '845_497': 32.4411,
    '845_498': 32.497,
    '845_499': 32.5625,
    '845_500': 32.6203,
    '845_501': 32.6852,
    '845_502': 32.7561,
    '845_503': 32.8299,
    '845_504': 32.9133,
    '845_505': 33.0012,
    '845_506': 33.0937,
    '845_507': 33.1874,
    '845_508': 33.2855,
    '845_509': 33.3847,
    '845_510': 33.4866,
    '845_511': 33.5876,
    '845_512': 33.6888,
    '845_513': 33.7895,
    '845_514': 33.8892,
    '845_515': 33.9862,
    '845_516': 34.0826,
    '845_517': 34.1778,
    '845_518': 34.2735,
    '845_519': 34.3617,
    '845_520': 34.4426,
    '845_521': 34.5312,
    '845_522': 34.6209,
    '845_523': 34.7074,
    '845_524': 34.7918,
    '845_525': 34.8728,
    '845_526': 34.9505,
    '845_527': 35.0231,
    '845_528': 35.0927,
    '845_529': 35.1606,
    '845_530': 35.2274,
    '845_531': 35.2935,
    '845_532': 35.3592,
    '845_533': 35.4246,
    '845_534': 35.4897,
    '845_535': 35.5548,
    '845_536': 35.6203,
    '845_537': 35.6865,
    '845_538': 35.7536,
    '845_539': 35.8223,
    '845_540': 35.8926,
    '845_541': 35.9642,
    '845_542': 36.0379,
    '845_543': 36.1143,
    '845_544': 36.1942,
    '845_545': 36.2795,
    '845_546': 36.3723,
    '845_547': 36.4735,
    '845_548': 36.5813,
    '845_549': 36.6945,
    '845_550': 36.804,
    '845_551': 36.9222,
    '845_552': 37.0284,
    '845_553': 37.1269,
    '845_554': 37.2122,
    '845_555': 37.2859,
    '845_556': 37.3659,
    '845_557': 37.429,
    '845_558': 37.4806,
    '845_559': 37.5267,
    '845_560': 37.5705,
    '845_561': 37.5976,
    '845_562': 37.6034,
    '845_563': 37.5955,
    '845_564': 37.5916,
    '845_565': 37.5719,
    '845_566': 37.5415,
    '845_567': 37.5017,
    '845_568': 37.4679,
    '845_569': 37.4282,
    '845_570': 37.391,
    '845_571': 37.3526,
    '845_572': 37.3143,
    '845_573': 37.2739,
    '845_574': 37.2276,
    '845_575': 37.1776,
    '845_576': 37.1257,
    '845_577': 37.076,
    '845_578': 37.0296,
    '845_579': 36.9839,
    '845_580': 36.941,
    '845_581': 36.9014,
    '845_582': 36.8623,
    '845_583': 36.8312,
    '845_584': 36.8139,
    '845_585': 36.8105,
    '845_586': 36.8042,
    '845_587': 36.798,
    '845_588': 36.7952,
    '845_589': 36.7979,
    '845_590': 36.8061,
    '845_591': 36.8193,
    '845_592': 36.8381,
    '845_593': 36.8626,
    '845_594': 36.8923,
    '845_595': 36.9276,
    '845_596': 36.9733,
    '845_597': 37.0239,
    '845_598': 37.0841,
    '845_599': 37.156,
    '845_600': 37.2413,
    '845_601': 37.3345,
    '845_602': 37.4282,
    '845_603': 37.5338,
    '845_604': 37.655,
    '845_605': 37.7879,
    '845_606': 37.934,
    '845_607': 38.0867,
    '845_608': 38.2527,
    '845_609': 38.4161,
    '845_610': 38.5836,
    '845_611': 38.7476,
    '845_612': 38.914,
    '845_613': 39.0802,
    '845_614': 39.2383,
    '845_615': 39.3869,
    '845_616': 39.5429,
    '845_617': 39.6973,
    '845_618': 39.8658,
    '845_619': 40.0397,
    '845_620': 40.2135,
    '845_621': 40.3823,
    '845_622': 40.5391,
    '845_623': 40.6718,
    '845_624': 40.7763,
    '845_625': 40.863,
    '845_626': 40.9297,
    '845_627': 40.9807,
    '845_628': 41.0176,
    '845_629': 41.0362,
    '845_630': 41.0478,
    '845_631': 41.0944,
    '845_632': 41.1518,
    '845_633': 41.2117,
    '845_634': 41.2711,
    '845_635': 41.3116,
    '845_636': 41.3094,
    '845_637': 41.2565,
    '845_638': 41.1658,
    '845_639': 41.0675,
    '845_640': 40.9979,
    '845_641': 40.9361,
    '845_642': 40.8623,
    '845_643': 40.7801,
    '845_644': 40.6754,
    '845_645': 40.5229,
    '845_646': 40.3418,
    '845_647': 40.1471,
    '845_648': 39.9577,
    '845_649': 39.7801,
    '845_650': 39.6021,
    '845_651': 39.4391,
    '845_652': 39.2766,
    '845_653': 39.1164,
    '845_654': 38.9663,
    '845_655': 38.8231,
    '845_656': 38.6845,
    '845_657': 38.5472,
    '845_658': 38.4074,
    '845_659': 38.2703,
    '845_660': 38.1409,
    '845_661': 38.0229,
    '845_662': 37.9161,
    '845_663': 37.8182,
    '845_664': 37.7225,
    '845_665': 37.6338,
    '845_666': 37.55,
    '845_667': 37.4705,
    '845_668': 37.4,
    '845_669': 37.3442,
    '845_670': 37.3126,
    '845_671': 37.2913,
    '845_672': 37.269,
    '845_673': 37.2337,
    '845_674': 37.1871,
    '845_675': 37.1363,
    '845_676': 37.0695,
    '845_677': 36.9991,
    '845_678': 36.9141,
    '845_679': 36.8287,
    '845_680': 36.7573,
    '845_681': 36.6905,
    '845_682': 36.6273,
    '845_683': 36.5892,
    '845_684': 36.5895,
    '845_685': 36.6005,
    '845_686': 36.6184,
    '845_687': 36.6392,
    '845_688': 36.6679,
    '845_689': 36.713,
    '845_690': 36.7615,
    '845_691': 36.8126,
    '845_692': 36.8568,
    '845_693': 36.8977,
    '845_694': 36.9404,
    '845_695': 36.9937,
    '845_696': 37.0501,
    '845_697': 37.1047,
    '845_698': 37.1555,
    '845_699': 37.2014,
    '845_700': 37.2494,
    '845_701': 37.2967,
    '845_702': 37.3605,
    '845_703': 37.437,
    '845_704': 37.5231,
    '845_778': 40.9128,
    '845_779': 40.9438,
    '845_780': 40.9671,
    '845_781': 40.9277,
    '845_782': 40.814,
    '845_783': 40.6604,
    '845_784': 40.5167,
    '846_364': 30.1205,
    '846_365': 30.2472,
    '846_366': 30.3644,
    '846_367': 30.4703,
    '846_368': 30.5529,
    '846_369': 30.6169,
    '846_370': 30.6572,
    '846_371': 30.6825,
    '846_372': 30.6997,
    '846_373': 30.7103,
    '846_374': 30.7128,
    '846_375': 30.7026,
    '846_376': 30.6802,
    '846_377': 30.6521,
    '846_378': 30.6297,
    '846_379': 30.6188,
    '846_380': 30.6204,
    '846_381': 30.6346,
    '846_382': 30.6462,
    '846_420': 31.4612,
    '846_421': 31.5831,
    '846_422': 31.7293,
    '846_423': 31.8297,
    '846_424': 31.9014,
    '846_425': 31.9555,
    '846_426': 32.0052,
    '846_427': 32.0652,
    '846_428': 32.1063,
    '846_429': 32.1515,
    '846_430': 32.1818,
    '846_431': 32.2118,
    '846_432': 32.2397,
    '846_433': 32.2687,
    '846_434': 32.2908,
    '846_435': 32.3223,
    '846_436': 32.3594,
    '846_437': 32.4048,
    '846_438': 32.442,
    '846_439': 32.449,
    '846_440': 32.4599,
    '846_441': 32.46,
    '846_442': 32.4631,
    '846_443': 32.4728,
    '846_444': 32.4931,
    '846_445': 32.5045,
    '846_446': 32.5125,
    '846_447': 32.5195,
    '846_448': 32.5269,
    '846_449': 32.528,
    '846_450': 32.5167,
    '846_451': 32.4987,
    '846_452': 32.4798,
    '846_453': 32.4717,
    '846_454': 32.4739,
    '846_455': 32.463,
    '846_456': 32.4426,
    '846_457': 32.4494,
    '846_458': 32.4605,
    '846_459': 32.475,
    '846_460': 32.4847,
    '846_461': 32.4967,
    '846_462': 32.51,
    '846_463': 32.5206,
    '846_464': 32.5393,
    '846_465': 32.5885,
    '846_466': 32.614,
    '846_467': 32.6047,
    '846_468': 32.6146,
    '846_469': 32.6258,
    '846_470': 32.6435,
    '846_471': 32.6498,
    '846_472': 32.6618,
    '846_473': 32.6789,
    '846_474': 32.676,
    '846_475': 32.6597,
    '846_476': 32.6389,
    '846_477': 32.6227,
    '846_478': 32.602,
    '846_479': 32.5643,
    '846_480': 32.5036,
    '846_481': 32.4514,
    '846_482': 32.4127,
    '846_483': 32.395,
    '846_484': 32.3925,
    '846_485': 32.4092,
    '846_486': 32.4184,
    '846_487': 32.3965,
    '846_488': 32.3414,
    '846_489': 32.3092,
    '846_490': 32.3031,
    '846_491': 32.3067,
    '846_492': 32.318,
    '846_493': 32.3365,
    '846_494': 32.3614,
    '846_495': 32.3917,
    '846_496': 32.4301,
    '846_497': 32.4761,
    '846_498': 32.54,
    '846_499': 32.6157,
    '846_500': 32.6677,
    '846_501': 32.7283,
    '846_502': 32.7931,
    '846_503': 32.8624,
    '846_504': 32.9398,
    '846_505': 33.0274,
    '846_506': 33.1182,
    '846_507': 33.2115,
    '846_508': 33.307,
    '846_509': 33.4038,
    '846_510': 33.5016,
    '846_511': 33.5995,
    '846_512': 33.6974,
    '846_513': 33.7941,
    '846_514': 33.8892,
    '846_515': 33.9817,
    '846_516': 34.0751,
    '846_517': 34.1684,
    '846_518': 34.2616,
    '846_519': 34.3466,
    '846_520': 34.4315,
    '846_521': 34.5216,
    '846_522': 34.6093,
    '846_523': 34.6963,
    '846_524': 34.7804,
    '846_525': 34.862,
    '846_526': 34.9393,
    '846_527': 35.0111,
    '846_528': 35.079,
    '846_529': 35.1456,
    '846_530': 35.2109,
    '846_531': 35.2753,
    '846_532': 35.3393,
    '846_533': 35.4026,
    '846_534': 35.4657,
    '846_535': 35.5287,
    '846_536': 35.5922,
    '846_537': 35.656,
    '846_538': 35.7212,
    '846_539': 35.7883,
    '846_540': 35.8566,
    '846_541': 35.9258,
    '846_542': 35.996,
    '846_543': 36.0683,
    '846_544': 36.1433,
    '846_545': 36.2225,
    '846_546': 36.3077,
    '846_547': 36.4002,
    '846_548': 36.4973,
    '846_549': 36.5967,
    '846_550': 36.6963,
    '846_551': 36.8032,
    '846_552': 36.914,
    '846_553': 37.0279,
    '846_554': 37.1262,
    '846_555': 37.2106,
    '846_556': 37.2919,
    '846_557': 37.3617,
    '846_558': 37.4144,
    '846_559': 37.454,
    '846_560': 37.5054,
    '846_561': 37.5458,
    '846_562': 37.5611,
    '846_563': 37.556,
    '846_564': 37.5567,
    '846_565': 37.5408,
    '846_566': 37.5063,
    '846_567': 37.4639,
    '846_568': 37.4332,
    '846_569': 37.3878,
    '846_570': 37.3462,
    '846_571': 37.3017,
    '846_572': 37.2589,
    '846_573': 37.2136,
    '846_574': 37.166,
    '846_575': 37.1155,
    '846_576': 37.0656,
    '846_577': 37.023,
    '846_578': 36.9804,
    '846_579': 36.9393,
    '846_580': 36.8956,
    '846_581': 36.8564,
    '846_582': 36.8231,
    '846_583': 36.8,
    '846_584': 36.7902,
    '846_585': 36.7965,
    '846_586': 36.7909,
    '846_587': 36.7912,
    '846_588': 36.7907,
    '846_589': 36.7957,
    '846_590': 36.8061,
    '846_591': 36.8211,
    '846_592': 36.841,
    '846_593': 36.8657,
    '846_594': 36.8945,
    '846_595': 36.9283,
    '846_596': 36.9695,
    '846_597': 37.0181,
    '846_598': 37.0754,
    '846_599': 37.1438,
    '846_600': 37.2272,
    '846_601': 37.3191,
    '846_602': 37.4152,
    '846_603': 37.5226,
    '846_604': 37.6424,
    '846_605': 37.773,
    '846_606': 37.9205,
    '846_607': 38.0816,
    '846_608': 38.2512,
    '846_609': 38.4214,
    '846_610': 38.5825,
    '846_611': 38.7394,
    '846_612': 38.9015,
    '846_613': 39.0691,
    '846_614': 39.2235,
    '846_615': 39.3677,
    '846_616': 39.5157,
    '846_617': 39.6656,
    '846_618': 39.8203,
    '846_619': 39.9803,
    '846_620': 40.1479,
    '846_621': 40.315,
    '846_622': 40.4748,
    '846_623': 40.6156,
    '846_624': 40.7302,
    '846_625': 40.8241,
    '846_626': 40.8996,
    '846_627': 40.9615,
    '846_628': 41.0062,
    '846_629': 41.0319,
    '846_630': 41.0498,
    '846_631': 41.0949,
    '846_632': 41.1617,
    '846_633': 41.2272,
    '846_634': 41.2917,
    '846_635': 41.3361,
    '846_636': 41.3444,
    '846_637': 41.3118,
    '846_638': 41.2257,
    '846_639': 41.1312,
    '846_640': 41.0511,
    '846_641': 40.9928,
    '846_642': 40.9188,
    '846_643': 40.8376,
    '846_644': 40.7431,
    '846_645': 40.6029,
    '846_646': 40.42,
    '846_647': 40.2197,
    '846_648': 40.0277,
    '846_649': 39.8474,
    '846_650': 39.676,
    '846_651': 39.522,
    '846_652': 39.3647,
    '846_653': 39.2013,
    '846_654': 39.054,
    '846_655': 38.9151,
    '846_656': 38.7805,
    '846_657': 38.6463,
    '846_658': 38.5076,
    '846_659': 38.371,
    '846_660': 38.2435,
    '846_661': 38.1315,
    '846_662': 38.0311,
    '846_663': 37.9378,
    '846_664': 37.8482,
    '846_665': 37.7644,
    '846_666': 37.6852,
    '846_667': 37.6098,
    '846_668': 37.5431,
    '846_669': 37.4892,
    '846_670': 37.4567,
    '846_671': 37.4334,
    '846_672': 37.4091,
    '846_673': 37.3739,
    '846_674': 37.3203,
    '846_675': 37.2603,
    '846_676': 37.19,
    '846_677': 37.118,
    '846_678': 37.0326,
    '846_679': 36.9432,
    '846_680': 36.8614,
    '846_681': 36.7874,
    '846_682': 36.7299,
    '846_683': 36.6976,
    '846_684': 36.689,
    '846_685': 36.6889,
    '846_686': 36.7019,
    '846_687': 36.7174,
    '846_688': 36.7396,
    '846_689': 36.7789,
    '846_690': 36.8243,
    '846_691': 36.8751,
    '846_692': 36.9156,
    '846_693': 36.9532,
    '846_694': 36.9932,
    '846_695': 37.0387,
    '846_696': 37.0891,
    '846_697': 37.1406,
    '846_698': 37.1902,
    '846_699': 37.2342,
    '846_700': 37.2676,
    '846_701': 37.3149,
    '846_702': 37.3772,
    '846_703': 37.4525,
    '846_704': 37.5401,
    '846_778': 40.9066,
    '846_779': 40.9328,
    '846_780': 40.9518,
    '846_781': 40.9123,
    '846_782': 40.796,
    '846_783': 40.6442,
    '846_784': 40.4988,
    '847_364': 30.0959,
    '847_365': 30.2247,
    '847_366': 30.3491,
    '847_367': 30.4667,
    '847_368': 30.5626,
    '847_369': 30.6302,
    '847_370': 30.6776,
    '847_371': 30.6977,
    '847_372': 30.7146,
    '847_373': 30.7276,
    '847_374': 30.7334,
    '847_375': 30.7242,
    '847_376': 30.7026,
    '847_377': 30.6705,
    '847_378': 30.6408,
    '847_379': 30.6237,
    '847_380': 30.6213,
    '847_381': 30.6299,
    '847_382': 30.6401,
    '847_420': 31.4452,
    '847_421': 31.5837,
    '847_422': 31.7132,
    '847_423': 31.8013,
    '847_424': 31.8665,
    '847_425': 31.9203,
    '847_426': 31.9768,
    '847_427': 32.0435,
    '847_428': 32.0715,
    '847_429': 32.1157,
    '847_430': 32.1512,
    '847_431': 32.1876,
    '847_432': 32.221,
    '847_433': 32.2533,
    '847_434': 32.2774,
    '847_435': 32.3111,
    '847_436': 32.3385,
    '847_437': 32.3793,
    '847_438': 32.4253,
    '847_439': 32.4378,
    '847_440': 32.4577,
    '847_441': 32.4613,
    '847_442': 32.4738,
    '847_443': 32.4931,
    '847_444': 32.5086,
    '847_445': 32.518,
    '847_446': 32.5264,
    '847_447': 32.5402,
    '847_448': 32.5569,
    '847_449': 32.5649,
    '847_450': 32.5578,
    '847_451': 32.5309,
    '847_452': 32.5088,
    '847_453': 32.4971,
    '847_454': 32.4982,
    '847_455': 32.4931,
    '847_456': 32.4757,
    '847_457': 32.4819,
    '847_458': 32.4922,
    '847_459': 32.5062,
    '847_460': 32.5128,
    '847_461': 32.5167,
    '847_462': 32.5386,
    '847_463': 32.555,
    '847_464': 32.5587,
    '847_465': 32.5978,
    '847_466': 32.633,
    '847_467': 32.6411,
    '847_468': 32.6492,
    '847_469': 32.6645,
    '847_470': 32.6882,
    '847_471': 32.705,
    '847_472': 32.7203,
    '847_473': 32.7318,
    '847_474': 32.7275,
    '847_475': 32.7115,
    '847_476': 32.6921,
    '847_477': 32.6767,
    '847_478': 32.6567,
    '847_479': 32.6179,
    '847_480': 32.5672,
    '847_481': 32.525,
    '847_482': 32.49,
    '847_483': 32.4705,
    '847_484': 32.4515,
    '847_485': 32.4498,
    '847_486': 32.4448,
    '847_487': 32.4177,
    '847_488': 32.3655,
    '847_489': 32.3373,
    '847_490': 32.3309,
    '847_491': 32.3347,
    '847_492': 32.345,
    '847_493': 32.3646,
    '847_494': 32.3923,
    '847_495': 32.4241,
    '847_496': 32.463,
    '847_497': 32.5085,
    '847_498': 32.5696,
    '847_499': 32.6432,
    '847_500': 32.7088,
    '847_501': 32.7754,
    '847_502': 32.8253,
    '847_503': 32.8927,
    '847_504': 32.9685,
    '847_505': 33.0548,
    '847_506': 33.144,
    '847_507': 33.2356,
    '847_508': 33.329,
    '847_509': 33.4235,
    '847_510': 33.5183,
    '847_511': 33.612,
    '847_512': 33.7079,
    '847_513': 33.8019,
    '847_514': 33.8932,
    '847_515': 33.9824,
    '847_516': 34.0736,
    '847_517': 34.1647,
    '847_518': 34.2553,
    '847_519': 34.3377,
    '847_520': 34.4233,
    '847_521': 34.5146,
    '847_522': 34.6027,
    '847_523': 34.6863,
    '847_524': 34.7698,
    '847_525': 34.8513,
    '847_526': 34.9304,
    '847_527': 35.0007,
    '847_528': 35.067,
    '847_529': 35.1326,
    '847_530': 35.197,
    '847_531': 35.2605,
    '847_532': 35.323,
    '847_533': 35.3848,
    '847_534': 35.4459,
    '847_535': 35.5069,
    '847_536': 35.5685,
    '847_537': 35.6304,
    '847_538': 35.6939,
    '847_539': 35.7593,
    '847_540': 35.8257,
    '847_541': 35.8933,
    '847_542': 35.9607,
    '847_543': 36.0299,
    '847_544': 36.1017,
    '847_545': 36.1771,
    '847_546': 36.2571,
    '847_547': 36.3415,
    '847_548': 36.4291,
    '847_549': 36.5181,
    '847_550': 36.611,
    '847_551': 36.7067,
    '847_552': 36.8098,
    '847_553': 36.9187,
    '847_554': 37.024,
    '847_555': 37.1153,
    '847_556': 37.199,
    '847_557': 37.2758,
    '847_558': 37.332,
    '847_559': 37.3734,
    '847_560': 37.4301,
    '847_561': 37.4791,
    '847_562': 37.5013,
    '847_563': 37.5008,
    '847_564': 37.5071,
    '847_565': 37.4994,
    '847_566': 37.4674,
    '847_567': 37.4261,
    '847_568': 37.4005,
    '847_569': 37.3674,
    '847_570': 37.3254,
    '847_571': 37.2744,
    '847_572': 37.2376,
    '847_573': 37.1851,
    '847_574': 37.1324,
    '847_575': 37.0788,
    '847_576': 37.0262,
    '847_577': 36.9812,
    '847_578': 36.938,
    '847_579': 36.8984,
    '847_580': 36.8589,
    '847_581': 36.823,
    '847_582': 36.7935,
    '847_583': 36.7752,
    '847_584': 36.7671,
    '847_585': 36.7682,
    '847_586': 36.7692,
    '847_587': 36.7748,
    '847_588': 36.7785,
    '847_589': 36.7871,
    '847_590': 36.8004,
    '847_591': 36.8178,
    '847_592': 36.8394,
    '847_593': 36.8649,
    '847_594': 36.8934,
    '847_595': 36.9258,
    '847_596': 36.9649,
    '847_597': 37.0109,
    '847_598': 37.0661,
    '847_599': 37.1329,
    '847_600': 37.2114,
    '847_601': 37.3003,
    '847_602': 37.3961,
    '847_603': 37.5025,
    '847_604': 37.6189,
    '847_605': 37.7459,
    '847_606': 37.8876,
    '847_607': 38.0464,
    '847_608': 38.2061,
    '847_609': 38.3773,
    '847_610': 38.5445,
    '847_611': 38.6975,
    '847_612': 38.8565,
    '847_613': 39.015,
    '847_614': 39.1729,
    '847_615': 39.3232,
    '847_616': 39.4749,
    '847_617': 39.627,
    '847_618': 39.7809,
    '847_619': 39.9316,
    '847_620': 40.0819,
    '847_621': 40.244,
    '847_622': 40.406,
    '847_623': 40.5529,
    '847_624': 40.6782,
    '847_625': 40.7775,
    '847_626': 40.8568,
    '847_627': 40.9239,
    '847_628': 40.9758,
    '847_629': 41.0108,
    '847_630': 41.0425,
    '847_631': 41.0906,
    '847_632': 41.1641,
    '847_633': 41.2396,
    '847_634': 41.3107,
    '847_635': 41.3669,
    '847_636': 41.3775,
    '847_637': 41.3478,
    '847_638': 41.2751,
    '847_639': 41.1863,
    '847_640': 41.0984,
    '847_641': 41.0437,
    '847_642': 40.9793,
    '847_643': 40.9001,
    '847_644': 40.8116,
    '847_645': 40.6805,
    '847_646': 40.5028,
    '847_647': 40.2976,
    '847_648': 40.1067,
    '847_649': 39.9164,
    '847_650': 39.7521,
    '847_651': 39.5933,
    '847_652': 39.4389,
    '847_653': 39.2843,
    '847_654': 39.1396,
    '847_655': 39.0036,
    '847_656': 38.8719,
    '847_657': 38.7397,
    '847_658': 38.603,
    '847_659': 38.4669,
    '847_660': 38.3396,
    '847_661': 38.2338,
    '847_662': 38.1394,
    '847_663': 38.0504,
    '847_664': 37.9657,
    '847_665': 37.8864,
    '847_666': 37.8122,
    '847_667': 37.7425,
    '847_668': 37.6822,
    '847_669': 37.6331,
    '847_670': 37.5982,
    '847_671': 37.5708,
    '847_672': 37.5427,
    '847_673': 37.5047,
    '847_674': 37.447,
    '847_675': 37.3802,
    '847_676': 37.3062,
    '847_677': 37.2324,
    '847_678': 37.149,
    '847_679': 37.0606,
    '847_680': 36.9749,
    '847_681': 36.899,
    '847_682': 36.8398,
    '847_683': 36.8069,
    '847_684': 36.7927,
    '847_685': 36.7902,
    '847_686': 36.7993,
    '847_687': 36.8087,
    '847_688': 36.8248,
    '847_689': 36.8585,
    '847_690': 36.8999,
    '847_691': 36.9437,
    '847_692': 36.9803,
    '847_693': 37.0133,
    '847_694': 37.047,
    '847_695': 37.0874,
    '847_696': 37.1324,
    '847_697': 37.1791,
    '847_698': 37.2244,
    '847_699': 37.2625,
    '847_700': 37.2967,
    '847_701': 37.3397,
    '847_702': 37.3944,
    '847_703': 37.4641,
    '847_704': 37.5527,
    '847_778': 40.8844,
    '847_779': 40.9036,
    '847_780': 40.9095,
    '847_781': 40.864,
    '847_782': 40.7533,
    '847_783': 40.6129,
    '847_784': 40.4712,
    '848_364': 30.0674,
    '848_365': 30.2006,
    '848_366': 30.3301,
    '848_367': 30.4593,
    '848_368': 30.5703,
    '848_369': 30.6458,
    '848_370': 30.6945,
    '848_371': 30.716,
    '848_372': 30.7267,
    '848_373': 30.7401,
    '848_374': 30.7464,
    '848_375': 30.7384,
    '848_376': 30.7189,
    '848_377': 30.6883,
    '848_378': 30.6583,
    '848_379': 30.6382,
    '848_380': 30.6294,
    '848_381': 30.6279,
    '848_382': 30.6355,
    '848_420': 31.4522,
    '848_421': 31.5888,
    '848_422': 31.6924,
    '848_423': 31.7697,
    '848_424': 31.8335,
    '848_425': 31.8902,
    '848_426': 31.9473,
    '848_427': 32.0052,
    '848_428': 32.0477,
    '848_429': 32.0916,
    '848_430': 32.1294,
    '848_431': 32.1696,
    '848_432': 32.2104,
    '848_433': 32.2425,
    '848_434': 32.2659,
    '848_435': 32.2943,
    '848_436': 32.3214,
    '848_437': 32.36,
    '848_438': 32.409,
    '848_439': 32.4373,
    '848_440': 32.4599,
    '848_441': 32.4754,
    '848_442': 32.4968,
    '848_443': 32.5208,
    '848_444': 32.5314,
    '848_445': 32.5389,
    '848_446': 32.5451,
    '848_447': 32.5617,
    '848_448': 32.5829,
    '848_449': 32.5932,
    '848_450': 32.5899,
    '848_451': 32.5674,
    '848_452': 32.5414,
    '848_453': 32.5274,
    '848_454': 32.5264,
    '848_455': 32.5227,
    '848_456': 32.5124,
    '848_457': 32.5019,
    '848_458': 32.5027,
    '848_459': 32.5146,
    '848_460': 32.5239,
    '848_461': 32.5319,
    '848_462': 32.5619,
    '848_463': 32.5835,
    '848_464': 32.5855,
    '848_465': 32.61,
    '848_466': 32.6515,
    '848_467': 32.6681,
    '848_468': 32.6757,
    '848_469': 32.6937,
    '848_470': 32.7298,
    '848_471': 32.761,
    '848_472': 32.7791,
    '848_473': 32.7837,
    '848_474': 32.7806,
    '848_475': 32.7667,
    '848_476': 32.7426,
    '848_477': 32.7247,
    '848_478': 32.704,
    '848_479': 32.6635,
    '848_480': 32.6282,
    '848_481': 32.5976,
    '848_482': 32.5575,
    '848_483': 32.5268,
    '848_484': 32.495,
    '848_485': 32.4834,
    '848_486': 32.468,
    '848_487': 32.4391,
    '848_488': 32.3921,
    '848_489': 32.3661,
    '848_490': 32.3582,
    '848_491': 32.364,
    '848_492': 32.3757,
    '848_493': 32.3969,
    '848_494': 32.4242,
    '848_495': 32.4566,
    '848_496': 32.495,
    '848_497': 32.5404,
    '848_498': 32.6001,
    '848_499': 32.6657,
    '848_500': 32.7326,
    '848_501': 32.8071,
    '848_502': 32.8589,
    '848_503': 32.9243,
    '848_504': 33.0004,
    '848_505': 33.0844,
    '848_506': 33.1715,
    '848_507': 33.2614,
    '848_508': 33.353,
    '848_509': 33.4448,
    '848_510': 33.5366,
    '848_511': 33.6272,
    '848_512': 33.7196,
    '848_513': 33.8118,
    '848_514': 33.8999,
    '848_515': 33.9858,
    '848_516': 34.0732,
    '848_517': 34.1612,
    '848_518': 34.2495,
    '848_519': 34.3321,
    '848_520': 34.4177,
    '848_521': 34.5091,
    '848_522': 34.5984,
    '848_523': 34.6791,
    '848_524': 34.7592,
    '848_525': 34.8382,
    '848_526': 34.9151,
    '848_527': 34.9864,
    '848_528': 35.0539,
    '848_529': 35.1202,
    '848_530': 35.1847,
    '848_531': 35.2478,
    '848_532': 35.3096,
    '848_533': 35.37,
    '848_534': 35.4293,
    '848_535': 35.4883,
    '848_536': 35.5479,
    '848_537': 35.6083,
    '848_538': 35.6699,
    '848_539': 35.7334,
    '848_540': 35.7982,
    '848_541': 35.8647,
    '848_542': 35.9299,
    '848_543': 35.997,
    '848_544': 36.0668,
    '848_545': 36.1404,
    '848_546': 36.2187,
    '848_547': 36.2991,
    '848_548': 36.3771,
    '848_549': 36.4594,
    '848_550': 36.5474,
    '848_551': 36.6373,
    '848_552': 36.7349,
    '848_553': 36.8253,
    '848_554': 36.9179,
    '848_555': 37.0076,
    '848_556': 37.0941,
    '848_557': 37.1729,
    '848_558': 37.2319,
    '848_559': 37.2858,
    '848_560': 37.3462,
    '848_561': 37.3974,
    '848_562': 37.4257,
    '848_563': 37.4298,
    '848_564': 37.4416,
    '848_565': 37.4413,
    '848_566': 37.4177,
    '848_567': 37.3906,
    '848_568': 37.3692,
    '848_569': 37.346,
    '848_570': 37.3059,
    '848_571': 37.2554,
    '848_572': 37.2252,
    '848_573': 37.1795,
    '848_574': 37.1279,
    '848_575': 37.0782,
    '848_576': 37.0184,
    '848_577': 36.9619,
    '848_578': 36.9116,
    '848_579': 36.8702,
    '848_580': 36.8337,
    '848_581': 36.8017,
    '848_582': 36.7741,
    '848_583': 36.7564,
    '848_584': 36.7457,
    '848_585': 36.7433,
    '848_586': 36.7473,
    '848_587': 36.7574,
    '848_588': 36.7655,
    '848_589': 36.7779,
    '848_590': 36.7946,
    '848_591': 36.8149,
    '848_592': 36.8387,
    '848_593': 36.8654,
    '848_594': 36.8933,
    '848_595': 36.9236,
    '848_596': 36.9597,
    '848_597': 37.0019,
    '848_598': 37.0526,
    '848_599': 37.1146,
    '848_600': 37.1913,
    '848_601': 37.2787,
    '848_602': 37.3736,
    '848_603': 37.4779,
    '848_604': 37.5904,
    '848_605': 37.7128,
    '848_606': 37.8471,
    '848_607': 37.9913,
    '848_608': 38.1486,
    '848_609': 38.3147,
    '848_610': 38.4727,
    '848_611': 38.6229,
    '848_612': 38.7829,
    '848_613': 38.9286,
    '848_614': 39.0858,
    '848_615': 39.2411,
    '848_616': 39.3995,
    '848_617': 39.5628,
    '848_618': 39.728,
    '848_619': 39.8844,
    '848_620': 40.0313,
    '848_621': 40.185,
    '848_622': 40.3431,
    '848_623': 40.4917,
    '848_624': 40.6242,
    '848_625': 40.7315,
    '848_626': 40.8142,
    '848_627': 40.8814,
    '848_628': 40.9335,
    '848_629': 40.9777,
    '848_630': 41.0204,
    '848_631': 41.0822,
    '848_632': 41.16,
    '848_633': 41.2392,
    '848_634': 41.314,
    '848_635': 41.376,
    '848_636': 41.4013,
    '848_637': 41.3761,
    '848_638': 41.3153,
    '848_639': 41.2247,
    '848_640': 41.1396,
    '848_641': 41.0874,
    '848_642': 41.0359,
    '848_643': 40.9675,
    '848_644': 40.883,
    '848_645': 40.756,
    '848_646': 40.581,
    '848_647': 40.3777,
    '848_648': 40.1789,
    '848_649': 39.9868,
    '848_650': 39.8215,
    '848_651': 39.662,
    '848_652': 39.5073,
    '848_653': 39.3605,
    '848_654': 39.2206,
    '848_655': 39.0883,
    '848_656': 38.9604,
    '848_657': 38.8324,
    '848_658': 38.6982,
    '848_659': 38.5638,
    '848_660': 38.4379,
    '848_661': 38.3367,
    '848_662': 38.2459,
    '848_663': 38.1595,
    '848_664': 38.0768,
    '848_665': 37.999,
    '848_666': 37.9275,
    '848_667': 37.8647,
    '848_668': 37.8119,
    '848_669': 37.7678,
    '848_670': 37.7319,
    '848_671': 37.7007,
    '848_672': 37.6681,
    '848_673': 37.6265,
    '848_674': 37.5673,
    '848_675': 37.499,
    '848_676': 37.4248,
    '848_677': 37.3518,
    '848_678': 37.2679,
    '848_679': 37.1774,
    '848_680': 37.0886,
    '848_681': 37.012,
    '848_682': 36.9525,
    '848_683': 36.9191,
    '848_684': 36.8999,
    '848_685': 36.8944,
    '848_686': 36.8976,
    '848_687': 36.9046,
    '848_688': 36.9167,
    '848_689': 36.9461,
    '848_690': 36.9822,
    '848_691': 37.019,
    '848_692': 37.0519,
    '848_693': 37.0799,
    '848_694': 37.106,
    '848_695': 37.14,
    '848_696': 37.1778,
    '848_697': 37.2176,
    '848_698': 37.2567,
    '848_699': 37.291,
    '848_700': 37.3253,
    '848_701': 37.3655,
    '848_702': 37.4112,
    '848_703': 37.4717,
    '848_704': 37.56,
    '848_778': 40.8576,
    '848_779': 40.8638,
    '848_780': 40.8519,
    '848_781': 40.8007,
    '848_782': 40.7036,
    '848_783': 40.5775,
    '848_784': 40.4377,
    '849_364': 30.0428,
    '849_365': 30.1704,
    '849_366': 30.3043,
    '849_367': 30.4398,
    '849_368': 30.5604,
    '849_369': 30.6493,
    '849_370': 30.7082,
    '849_371': 30.7279,
    '849_372': 30.7341,
    '849_373': 30.7474,
    '849_374': 30.7556,
    '849_375': 30.7474,
    '849_376': 30.7302,
    '849_377': 30.702,
    '849_378': 30.6728,
    '849_379': 30.6521,
    '849_380': 30.6392,
    '849_381': 30.6348,
    '849_382': 30.6372,
    '849_420': 31.4588,
    '849_421': 31.5797,
    '849_422': 31.6664,
    '849_423': 31.7356,
    '849_424': 31.7996,
    '849_425': 31.8564,
    '849_426': 31.9125,
    '849_427': 31.9676,
    '849_428': 32.0193,
    '849_429': 32.0681,
    '849_430': 32.1138,
    '849_431': 32.1575,
    '849_432': 32.1996,
    '849_433': 32.2343,
    '849_434': 32.2572,
    '849_435': 32.2821,
    '849_436': 32.3111,
    '849_437': 32.35,
    '849_438': 32.4004,
    '849_439': 32.4471,
    '849_440': 32.4795,
    '849_441': 32.5027,
    '849_442': 32.5219,
    '849_443': 32.5438,
    '849_444': 32.5495,
    '849_445': 32.5598,
    '849_446': 32.5652,
    '849_447': 32.5776,
    '849_448': 32.5991,
    '849_449': 32.6106,
    '849_450': 32.6085,
    '849_451': 32.5959,
    '849_452': 32.5741,
    '849_453': 32.5551,
    '849_454': 32.553,
    '849_455': 32.5565,
    '849_456': 32.5538,
    '849_457': 32.5301,
    '849_458': 32.5173,
    '849_459': 32.5234,
    '849_460': 32.5308,
    '849_461': 32.5482,
    '849_462': 32.5862,
    '849_463': 32.6072,
    '849_464': 32.6119,
    '849_465': 32.6241,
    '849_466': 32.6629,
    '849_467': 32.6825,
    '849_468': 32.695,
    '849_469': 32.7244,
    '849_470': 32.7657,
    '849_471': 32.8092,
    '849_472': 32.8363,
    '849_473': 32.8404,
    '849_474': 32.8354,
    '849_475': 32.8197,
    '849_476': 32.7907,
    '849_477': 32.7666,
    '849_478': 32.7416,
    '849_479': 32.702,
    '849_480': 32.6749,
    '849_481': 32.6461,
    '849_482': 32.6039,
    '849_483': 32.5662,
    '849_484': 32.5277,
    '849_485': 32.5154,
    '849_486': 32.4917,
    '849_487': 32.4615,
    '849_488': 32.423,
    '849_489': 32.3973,
    '849_490': 32.3882,
    '849_491': 32.3939,
    '849_492': 32.4074,
    '849_493': 32.428,
    '849_494': 32.4528,
    '849_495': 32.4879,
    '849_496': 32.5361,
    '849_497': 32.5728,
    '849_498': 32.6329,
    '849_499': 32.7069,
    '849_500': 32.765,
    '849_501': 32.8316,
    '849_502': 32.8913,
    '849_503': 32.9591,
    '849_504': 33.0361,
    '849_505': 33.115,
    '849_506': 33.1999,
    '849_507': 33.2885,
    '849_508': 33.3794,
    '849_509': 33.468,
    '849_510': 33.5567,
    '849_511': 33.6452,
    '849_512': 33.735,
    '849_513': 33.8271,
    '849_514': 33.9092,
    '849_515': 33.9914,
    '849_516': 34.0743,
    '849_517': 34.1576,
    '849_518': 34.2423,
    '849_519': 34.3259,
    '849_520': 34.4122,
    '849_521': 34.5001,
    '849_522': 34.5901,
    '849_523': 34.6741,
    '849_524': 34.748,
    '849_525': 34.8248,
    '849_526': 34.8999,
    '849_527': 34.9716,
    '849_528': 35.0405,
    '849_529': 35.1076,
    '849_530': 35.1729,
    '849_531': 35.2362,
    '849_532': 35.2973,
    '849_533': 35.3565,
    '849_534': 35.4139,
    '849_535': 35.4709,
    '849_536': 35.5284,
    '849_537': 35.5867,
    '849_538': 35.6468,
    '849_539': 35.7084,
    '849_540': 35.7715,
    '849_541': 35.8358,
    '849_542': 35.9004,
    '849_543': 35.9668,
    '849_544': 36.0359,
    '849_545': 36.1094,
    '849_546': 36.1909,
    '849_547': 36.272,
    '849_548': 36.3375,
    '849_549': 36.4161,
    '849_550': 36.4983,
    '849_551': 36.585,
    '849_552': 36.6779,
    '849_553': 36.7574,
    '849_554': 36.8314,
    '849_555': 36.9105,
    '849_556': 36.9909,
    '849_557': 37.0661,
    '849_558': 37.1283,
    '849_559': 37.1902,
    '849_560': 37.2513,
    '849_561': 37.3013,
    '849_562': 37.3346,
    '849_563': 37.3454,
    '849_564': 37.3601,
    '849_565': 37.3684,
    '849_566': 37.3586,
    '849_567': 37.3446,
    '849_568': 37.3269,
    '849_569': 37.3098,
    '849_570': 37.2726,
    '849_571': 37.2358,
    '849_572': 37.205,
    '849_573': 37.1679,
    '849_574': 37.1206,
    '849_575': 37.0829,
    '849_576': 37.0295,
    '849_577': 36.9673,
    '849_578': 36.9068,
    '849_579': 36.8605,
    '849_580': 36.8236,
    '849_581': 36.7932,
    '849_582': 36.7644,
    '849_583': 36.7441,
    '849_584': 36.7298,
    '849_585': 36.7236,
    '849_586': 36.7281,
    '849_587': 36.7411,
    '849_588': 36.7526,
    '849_589': 36.7683,
    '849_590': 36.7884,
    '849_591': 36.8119,
    '849_592': 36.8375,
    '849_593': 36.8666,
    '849_594': 36.8937,
    '849_595': 36.9213,
    '849_596': 36.9539,
    '849_597': 36.992,
    '849_598': 37.0376,
    '849_599': 37.0938,
    '849_600': 37.1696,
    '849_601': 37.2558,
    '849_602': 37.35,
    '849_603': 37.4516,
    '849_604': 37.5595,
    '849_605': 37.6758,
    '849_606': 37.8006,
    '849_607': 37.9329,
    '849_608': 38.0773,
    '849_609': 38.2315,
    '849_610': 38.3855,
    '849_611': 38.5369,
    '849_612': 38.6943,
    '849_613': 38.844,
    '849_614': 39.0012,
    '849_615': 39.1631,
    '849_616': 39.3313,
    '849_617': 39.4994,
    '849_618': 39.6663,
    '849_619': 39.8306,
    '849_620': 39.9863,
    '849_621': 40.1384,
    '849_622': 40.2877,
    '849_623': 40.4308,
    '849_624': 40.5646,
    '849_625': 40.68,
    '849_626': 40.7682,
    '849_627': 40.8367,
    '849_628': 40.8922,
    '849_629': 40.9424,
    '849_630': 40.9921,
    '849_631': 41.0652,
    '849_632': 41.148,
    '849_633': 41.2291,
    '849_634': 41.3088,
    '849_635': 41.3773,
    '849_636': 41.4114,
    '849_637': 41.4006,
    '849_638': 41.3428,
    '849_639': 41.2585,
    '849_640': 41.1784,
    '849_641': 41.1244,
    '849_642': 41.0872,
    '849_643': 41.0322,
    '849_644': 40.9529,
    '849_645': 40.8309,
    '849_646': 40.6634,
    '849_647': 40.4606,
    '849_648': 40.2507,
    '849_649': 40.0645,
    '849_650': 39.8924,
    '849_651': 39.7345,
    '849_652': 39.5759,
    '849_653': 39.4319,
    '849_654': 39.2968,
    '849_655': 39.1684,
    '849_656': 39.0433,
    '849_657': 38.9208,
    '849_658': 38.7918,
    '849_659': 38.6636,
    '849_660': 38.5449,
    '849_661': 38.4431,
    '849_662': 38.352,
    '849_663': 38.2661,
    '849_664': 38.1827,
    '849_665': 38.1037,
    '849_666': 38.0318,
    '849_667': 37.9763,
    '849_668': 37.9311,
    '849_669': 37.8919,
    '849_670': 37.8556,
    '849_671': 37.8213,
    '849_672': 37.7845,
    '849_673': 37.7388,
    '849_674': 37.6816,
    '849_675': 37.617,
    '849_676': 37.5477,
    '849_677': 37.4753,
    '849_678': 37.3941,
    '849_679': 37.3059,
    '849_680': 37.2163,
    '849_681': 37.1372,
    '849_682': 37.0789,
    '849_683': 37.0424,
    '849_684': 37.0095,
    '849_685': 36.997,
    '849_686': 36.9912,
    '849_687': 36.997,
    '849_688': 37.0099,
    '849_689': 37.0364,
    '849_690': 37.0678,
    '849_691': 37.1003,
    '849_692': 37.1311,
    '849_693': 37.155,
    '849_694': 37.1742,
    '849_695': 37.1979,
    '849_696': 37.2251,
    '849_697': 37.2566,
    '849_698': 37.2883,
    '849_699': 37.3185,
    '849_700': 37.3502,
    '849_701': 37.3867,
    '849_702': 37.4321,
    '849_703': 37.4902,
    '849_704': 37.5659,
    '849_778': 40.8284,
    '849_779': 40.8226,
    '849_780': 40.8004,
    '849_781': 40.7469,
    '849_782': 40.6562,
    '849_783': 40.5412,
    '849_784': 40.3996,
    '850_364': 30.0098,
    '850_365': 30.1379,
    '850_366': 30.2748,
    '850_367': 30.4141,
    '850_368': 30.5448,
    '850_369': 30.6514,
    '850_370': 30.7129,
    '850_371': 30.732,
    '850_372': 30.7364,
    '850_373': 30.7475,
    '850_374': 30.7564,
    '850_375': 30.7525,
    '850_376': 30.7408,
    '850_377': 30.714,
    '850_378': 30.6854,
    '850_379': 30.6649,
    '850_380': 30.6491,
    '850_381': 30.6382,
    '850_382': 30.6353,
    '850_420': 31.4563,
    '850_421': 31.5558,
    '850_422': 31.6349,
    '850_423': 31.7029,
    '850_424': 31.766,
    '850_425': 31.8205,
    '850_426': 31.8755,
    '850_427': 31.9317,
    '850_428': 31.9884,
    '850_429': 32.0431,
    '850_430': 32.0939,
    '850_431': 32.141,
    '850_432': 32.1868,
    '850_433': 32.2228,
    '850_434': 32.2506,
    '850_435': 32.2745,
    '850_436': 32.3056,
    '850_437': 32.3458,
    '850_438': 32.4009,
    '850_439': 32.4617,
    '850_440': 32.5065,
    '850_441': 32.5386,
    '850_442': 32.5464,
    '850_443': 32.5515,
    '850_444': 32.5601,
    '850_445': 32.5768,
    '850_446': 32.586,
    '850_447': 32.5935,
    '850_448': 32.6037,
    '850_449': 32.6135,
    '850_450': 32.6128,
    '850_451': 32.6077,
    '850_452': 32.5928,
    '850_453': 32.5783,
    '850_454': 32.5817,
    '850_455': 32.5927,
    '850_456': 32.5932,
    '850_457': 32.5644,
    '850_458': 32.544,
    '850_459': 32.542,
    '850_460': 32.5483,
    '850_461': 32.5628,
    '850_462': 32.6035,
    '850_463': 32.6264,
    '850_464': 32.6388,
    '850_465': 32.6421,
    '850_466': 32.6682,
    '850_467': 32.6978,
    '850_468': 32.7212,
    '850_469': 32.7622,
    '850_470': 32.8037,
    '850_471': 32.8508,
    '850_472': 32.8847,
    '850_473': 32.8902,
    '850_474': 32.8887,
    '850_475': 32.8674,
    '850_476': 32.8319,
    '850_477': 32.8039,
    '850_478': 32.7742,
    '850_479': 32.736,
    '850_480': 32.7072,
    '850_481': 32.6781,
    '850_482': 32.6365,
    '850_483': 32.5999,
    '850_484': 32.5715,
    '850_485': 32.5539,
    '850_486': 32.5246,
    '850_487': 32.4935,
    '850_488': 32.461,
    '850_489': 32.4329,
    '850_490': 32.4204,
    '850_491': 32.4249,
    '850_492': 32.4378,
    '850_493': 32.4576,
    '850_494': 32.4823,
    '850_495': 32.5179,
    '850_496': 32.5654,
    '850_497': 32.6042,
    '850_498': 32.6612,
    '850_499': 32.7307,
    '850_500': 32.7986,
    '850_501': 32.8677,
    '850_502': 32.927,
    '850_503': 32.9968,
    '850_504': 33.0733,
    '850_505': 33.1504,
    '850_506': 33.2342,
    '850_507': 33.3219,
    '850_508': 33.41,
    '850_509': 33.4992,
    '850_510': 33.584,
    '850_511': 33.6679,
    '850_512': 33.7528,
    '850_513': 33.8475,
    '850_514': 33.9281,
    '850_515': 33.9959,
    '850_516': 34.0748,
    '850_517': 34.1555,
    '850_518': 34.2374,
    '850_519': 34.3197,
    '850_520': 34.4048,
    '850_521': 34.4946,
    '850_522': 34.5916,
    '850_523': 34.6664,
    '850_524': 34.7392,
    '850_525': 34.811,
    '850_526': 34.8849,
    '850_527': 34.9567,
    '850_528': 35.0264,
    '850_529': 35.0945,
    '850_530': 35.1609,
    '850_531': 35.2249,
    '850_532': 35.285,
    '850_533': 35.3439,
    '850_534': 35.3983,
    '850_535': 35.4534,
    '850_536': 35.5088,
    '850_537': 35.5654,
    '850_538': 35.6237,
    '850_539': 35.6838,
    '850_540': 35.7454,
    '850_541': 35.8083,
    '850_542': 35.8725,
    '850_543': 35.9386,
    '850_544': 36.0074,
    '850_545': 36.0809,
    '850_546': 36.1649,
    '850_547': 36.2469,
    '850_548': 36.3048,
    '850_549': 36.3775,
    '850_550': 36.4569,
    '850_551': 36.5388,
    '850_552': 36.6238,
    '850_553': 36.6939,
    '850_554': 36.7606,
    '850_555': 36.834,
    '850_556': 36.9098,
    '850_557': 36.9796,
    '850_558': 37.0421,
    '850_559': 37.0976,
    '850_560': 37.1556,
    '850_561': 37.2041,
    '850_562': 37.237,
    '850_563': 37.2517,
    '850_564': 37.2709,
    '850_565': 37.2873,
    '850_566': 37.2904,
    '850_567': 37.288,
    '850_568': 37.2789,
    '850_569': 37.2628,
    '850_570': 37.2275,
    '850_571': 37.2016,
    '850_572': 37.175,
    '850_573': 37.141,
    '850_574': 37.0989,
    '850_575': 37.0665,
    '850_576': 37.0197,
    '850_577': 36.9631,
    '850_578': 36.9059,
    '850_579': 36.866,
    '850_580': 36.8307,
    '850_581': 36.8035,
    '850_582': 36.7703,
    '850_583': 36.7426,
    '850_584': 36.7236,
    '850_585': 36.7106,
    '850_586': 36.7126,
    '850_587': 36.7273,
    '850_588': 36.7404,
    '850_589': 36.7586,
    '850_590': 36.7817,
    '850_591': 36.8083,
    '850_592': 36.837,
    '850_593': 36.8693,
    '850_594': 36.8934,
    '850_595': 36.9181,
    '850_596': 36.9476,
    '850_597': 36.9827,
    '850_598': 37.0263,
    '850_599': 37.0821,
    '850_600': 37.1516,
    '850_601': 37.2335,
    '850_602': 37.3245,
    '850_603': 37.4229,
    '850_604': 37.5281,
    '850_605': 37.6398,
    '850_606': 37.758,
    '850_607': 37.8832,
    '850_608': 38.0191,
    '850_609': 38.1633,
    '850_610': 38.3125,
    '850_611': 38.4657,
    '850_612': 38.6193,
    '850_613': 38.7788,
    '850_614': 38.9346,
    '850_615': 39.0953,
    '850_616': 39.2644,
    '850_617': 39.4372,
    '850_618': 39.6052,
    '850_619': 39.7644,
    '850_620': 39.9264,
    '850_621': 40.0798,
    '850_622': 40.2264,
    '850_623': 40.3662,
    '850_624': 40.5011,
    '850_625': 40.6178,
    '850_626': 40.7098,
    '850_627': 40.7838,
    '850_628': 40.845,
    '850_629': 40.9016,
    '850_630': 40.9611,
    '850_631': 41.0383,
    '850_632': 41.1194,
    '850_633': 41.2034,
    '850_634': 41.29,
    '850_635': 41.367,
    '850_636': 41.3976,
    '850_637': 41.4028,
    '850_638': 41.3542,
    '850_639': 41.2829,
    '850_640': 41.2116,
    '850_641': 41.1645,
    '850_642': 41.1325,
    '850_643': 41.0892,
    '850_644': 41.0091,
    '850_645': 40.8945,
    '850_646': 40.7356,
    '850_647': 40.5367,
    '850_648': 40.3305,
    '850_649': 40.1366,
    '850_650': 39.9708,
    '850_651': 39.8072,
    '850_652': 39.6457,
    '850_653': 39.5003,
    '850_654': 39.3671,
    '850_655': 39.241,
    '850_656': 39.118,
    '850_657': 38.9982,
    '850_658': 38.8776,
    '850_659': 38.7567,
    '850_660': 38.6443,
    '850_661': 38.5443,
    '850_662': 38.4545,
    '850_663': 38.3698,
    '850_664': 38.2855,
    '850_665': 38.206,
    '850_666': 38.1348,
    '850_667': 38.0826,
    '850_668': 38.0413,
    '850_669': 38.0053,
    '850_670': 37.9689,
    '850_671': 37.9338,
    '850_672': 37.8959,
    '850_673': 37.8479,
    '850_674': 37.7923,
    '850_675': 37.7316,
    '850_676': 37.6675,
    '850_677': 37.6019,
    '850_678': 37.5271,
    '850_679': 37.4408,
    '850_680': 37.3483,
    '850_681': 37.2643,
    '850_682': 37.205,
    '850_683': 37.1701,
    '850_684': 37.1379,
    '850_685': 37.1142,
    '850_686': 37.0978,
    '850_687': 37.0953,
    '850_688': 37.1031,
    '850_689': 37.1232,
    '850_690': 37.1515,
    '850_691': 37.1849,
    '850_692': 37.2139,
    '850_693': 37.2345,
    '850_694': 37.2465,
    '850_695': 37.2591,
    '850_696': 37.2751,
    '850_697': 37.2978,
    '850_698': 37.3219,
    '850_699': 37.3471,
    '850_700': 37.3761,
    '850_701': 37.4112,
    '850_702': 37.4537,
    '850_703': 37.5071,
    '850_704': 37.571,
    '850_762': 40.7943,
    '850_763': 40.8278,
    '850_764': 40.8464,
    '850_765': 40.838,
    '850_766': 40.8196,
    '850_767': 40.7877,
    '850_768': 40.7432,
    '850_769': 40.7082,
    '850_770': 40.6773,
    '851_364': 29.9851,
    '851_365': 30.1092,
    '851_366': 30.2452,
    '851_367': 30.3867,
    '851_368': 30.5234,
    '851_369': 30.6395,
    '851_370': 30.7034,
    '851_371': 30.7243,
    '851_372': 30.7318,
    '851_373': 30.744,
    '851_374': 30.7555,
    '851_375': 30.7542,
    '851_376': 30.7509,
    '851_377': 30.7268,
    '851_378': 30.7018,
    '851_379': 30.6796,
    '851_380': 30.6599,
    '851_381': 30.6414,
    '851_382': 30.6345,
    '851_420': 31.4374,
    '851_421': 31.5264,
    '851_422': 31.6009,
    '851_423': 31.6526,
    '851_424': 31.7156,
    '851_425': 31.7757,
    '851_426': 31.8348,
    '851_427': 31.8954,
    '851_428': 31.9585,
    '851_429': 32.0194,
    '851_430': 32.0748,
    '851_431': 32.1249,
    '851_432': 32.1714,
    '851_433': 32.2099,
    '851_434': 32.2419,
    '851_435': 32.2692,
    '851_436': 32.303,
    '851_437': 32.3465,
    '851_438': 32.4088,
    '851_439': 32.4717,
    '851_440': 32.5291,
    '851_441': 32.5662,
    '851_442': 32.5615,
    '851_443': 32.5551,
    '851_444': 32.5689,
    '851_445': 32.5882,
    '851_446': 32.6047,
    '851_447': 32.6113,
    '851_448': 32.6172,
    '851_449': 32.6252,
    '851_450': 32.6238,
    '851_451': 32.6159,
    '851_452': 32.6051,
    '851_453': 32.601,
    '851_454': 32.609,
    '851_455': 32.6255,
    '851_456': 32.6342,
    '851_457': 32.6091,
    '851_458': 32.5912,
    '851_459': 32.5765,
    '851_460': 32.5709,
    '851_461': 32.5816,
    '851_462': 32.6123,
    '851_463': 32.6376,
    '851_464': 32.6566,
    '851_465': 32.665,
    '851_466': 32.6806,
    '851_467': 32.7166,
    '851_468': 32.7591,
    '851_469': 32.8046,
    '851_470': 32.849,
    '851_471': 32.8906,
    '851_472': 32.9233,
    '851_473': 32.9318,
    '851_474': 32.9344,
    '851_475': 32.908,
    '851_476': 32.8692,
    '851_477': 32.8391,
    '851_478': 32.8097,
    '851_479': 32.7708,
    '851_480': 32.7353,
    '851_481': 32.7074,
    '851_482': 32.6709,
    '851_483': 32.6483,
    '851_484': 32.6327,
    '851_485': 32.6072,
    '851_486': 32.5793,
    '851_487': 32.5461,
    '851_488': 32.5127,
    '851_489': 32.478,
    '851_490': 32.4577,
    '851_491': 32.4578,
    '851_492': 32.4697,
    '851_493': 32.4879,
    '851_494': 32.5134,
    '851_495': 32.5477,
    '851_496': 32.5915,
    '851_497': 32.6388,
    '851_498': 32.6948,
    '851_499': 32.7577,
    '851_500': 32.8263,
    '851_501': 32.9013,
    '851_502': 32.9679,
    '851_503': 33.0393,
    '851_504': 33.1152,
    '851_505': 33.1923,
    '851_506': 33.2781,
    '851_507': 33.3653,
    '851_508': 33.4527,
    '851_509': 33.5384,
    '851_510': 33.6113,
    '851_511': 33.6823,
    '851_512': 33.7618,
    '851_513': 33.8485,
    '851_514': 33.9306,
    '851_515': 34.001,
    '851_516': 34.0761,
    '851_517': 34.1543,
    '851_518': 34.2342,
    '851_519': 34.3171,
    '851_520': 34.4007,
    '851_521': 34.4909,
    '851_522': 34.5853,
    '851_523': 34.656,
    '851_524': 34.7272,
    '851_525': 34.799,
    '851_526': 34.8714,
    '851_527': 34.9426,
    '851_528': 35.0127,
    '851_529': 35.0814,
    '851_530': 35.1486,
    '851_531': 35.2147,
    '851_532': 35.2722,
    '851_533': 35.3294,
    '851_534': 35.382,
    '851_535': 35.4351,
    '851_536': 35.4891,
    '851_537': 35.5445,
    '851_538': 35.6014,
    '851_539': 35.6602,
    '851_540': 35.7207,
    '851_541': 35.7828,
    '851_542': 35.8466,
    '851_543': 35.9126,
    '851_544': 35.9812,
    '851_545': 36.0537,
    '851_546': 36.134,
    '851_547': 36.2145,
    '851_548': 36.2741,
    '851_549': 36.3433,
    '851_550': 36.4241,
    '851_551': 36.5042,
    '851_552': 36.5841,
    '851_553': 36.6355,
    '851_554': 36.699,
    '851_555': 36.7731,
    '851_556': 36.8493,
    '851_557': 36.9163,
    '851_558': 36.97,
    '851_559': 37.0144,
    '851_560': 37.064,
    '851_561': 37.1114,
    '851_562': 37.1424,
    '851_563': 37.1682,
    '851_564': 37.1969,
    '851_565': 37.2182,
    '851_566': 37.2296,
    '851_567': 37.2325,
    '851_568': 37.2325,
    '851_569': 37.2131,
    '851_570': 37.178,
    '851_571': 37.1488,
    '851_572': 37.1215,
    '851_573': 37.0931,
    '851_574': 37.0591,
    '851_575': 37.026,
    '851_576': 36.9787,
    '851_577': 36.9375,
    '851_578': 36.89,
    '851_579': 36.8577,
    '851_580': 36.838,
    '851_581': 36.8121,
    '851_582': 36.7793,
    '851_583': 36.7459,
    '851_584': 36.7186,
    '851_585': 36.7046,
    '851_586': 36.7038,
    '851_587': 36.7172,
    '851_588': 36.7296,
    '851_589': 36.75,
    '851_590': 36.7767,
    '851_591': 36.8063,
    '851_592': 36.8359,
    '851_593': 36.8656,
    '851_594': 36.8907,
    '851_595': 36.9143,
    '851_596': 36.9413,
    '851_597': 36.9733,
    '851_598': 37.0127,
    '851_599': 37.0626,
    '851_600': 37.1314,
    '851_601': 37.2109,
    '851_602': 37.2986,
    '851_603': 37.3938,
    '851_604': 37.4957,
    '851_605': 37.6034,
    '851_606': 37.7197,
    '851_607': 37.8398,
    '851_608': 37.9712,
    '851_609': 38.1059,
    '851_610': 38.2511,
    '851_611': 38.4027,
    '851_612': 38.5526,
    '851_613': 38.711,
    '851_614': 38.874,
    '851_615': 39.0375,
    '851_616': 39.2043,
    '851_617': 39.3734,
    '851_618': 39.545,
    '851_619': 39.6985,
    '851_620': 39.854,
    '851_621': 40.0065,
    '851_622': 40.1545,
    '851_623': 40.2975,
    '851_624': 40.4275,
    '851_625': 40.5427,
    '851_626': 40.6355,
    '851_627': 40.7165,
    '851_628': 40.7848,
    '851_629': 40.8481,
    '851_630': 40.9158,
    '851_631': 40.9982,
    '851_632': 41.0825,
    '851_633': 41.1655,
    '851_634': 41.2553,
    '851_635': 41.3386,
    '851_636': 41.3682,
    '851_637': 41.3895,
    '851_638': 41.3571,
    '851_639': 41.3012,
    '851_640': 41.2375,
    '851_641': 41.1976,
    '851_642': 41.17,
    '851_643': 41.1201,
    '851_644': 41.0396,
    '851_645': 40.9361,
    '851_646': 40.7858,
    '851_647': 40.5997,
    '851_648': 40.3983,
    '851_649': 40.2006,
    '851_650': 40.041,
    '851_651': 39.8774,
    '851_652': 39.7186,
    '851_653': 39.5695,
    '851_654': 39.4357,
    '851_655': 39.3116,
    '851_656': 39.1894,
    '851_657': 39.0712,
    '851_658': 38.9546,
    '851_659': 38.8405,
    '851_660': 38.7336,
    '851_661': 38.6366,
    '851_662': 38.549,
    '851_663': 38.4669,
    '851_664': 38.3833,
    '851_665': 38.306,
    '851_666': 38.2381,
    '851_667': 38.1848,
    '851_668': 38.1422,
    '851_669': 38.1053,
    '851_670': 38.0703,
    '851_671': 38.0369,
    '851_672': 38.0011,
    '851_673': 37.9525,
    '851_674': 37.8988,
    '851_675': 37.8425,
    '851_676': 37.7839,
    '851_677': 37.7252,
    '851_678': 37.6588,
    '851_679': 37.5735,
    '851_680': 37.4822,
    '851_681': 37.397,
    '851_682': 37.3301,
    '851_683': 37.2963,
    '851_684': 37.2698,
    '851_685': 37.242,
    '851_686': 37.2264,
    '851_687': 37.2178,
    '851_688': 37.2136,
    '851_689': 37.2268,
    '851_690': 37.2484,
    '851_691': 37.2704,
    '851_692': 37.287,
    '851_693': 37.3069,
    '851_694': 37.3164,
    '851_695': 37.3245,
    '851_696': 37.3347,
    '851_697': 37.346,
    '851_698': 37.36,
    '851_699': 37.3781,
    '851_700': 37.4021,
    '851_701': 37.4327,
    '851_702': 37.4721,
    '851_703': 37.521,
    '851_704': 37.581,
    '851_762': 40.7477,
    '851_763': 40.7919,
    '851_764': 40.8204,
    '851_765': 40.8291,
    '851_766': 40.8189,
    '851_767': 40.7859,
    '851_768': 40.7391,
    '851_769': 40.6926,
    '851_770': 40.6526,
    '852_364': 29.9596,
    '852_365': 30.0802,
    '852_366': 30.214,
    '852_367': 30.3561,
    '852_368': 30.4968,
    '852_369': 30.6103,
    '852_370': 30.6831,
    '852_371': 30.7226,
    '852_372': 30.7256,
    '852_373': 30.738,
    '852_374': 30.7504,
    '852_375': 30.7565,
    '852_376': 30.7587,
    '852_377': 30.7429,
    '852_378': 30.7192,
    '852_379': 30.695,
    '852_380': 30.6714,
    '852_381': 30.65,
    '852_382': 30.6419,
    '852_420': 31.4187,
    '852_421': 31.5033,
    '852_422': 31.5592,
    '852_423': 31.6101,
    '852_424': 31.671,
    '852_425': 31.7331,
    '852_426': 31.7962,
    '852_427': 31.8629,
    '852_428': 31.9307,
    '852_429': 31.9978,
    '852_430': 32.0571,
    '852_431': 32.1094,
    '852_432': 32.1579,
    '852_433': 32.1992,
    '852_434': 32.2353,
    '852_435': 32.2667,
    '852_436': 32.3039,
    '852_437': 32.3515,
    '852_438': 32.4186,
    '852_439': 32.4894,
    '852_440': 32.5388,
    '852_441': 32.5704,
    '852_442': 32.5657,
    '852_443': 32.5636,
    '852_444': 32.5792,
    '852_445': 32.5999,
    '852_446': 32.6232,
    '852_447': 32.6325,
    '852_448': 32.6403,
    '852_449': 32.6446,
    '852_450': 32.6446,
    '852_451': 32.6334,
    '852_452': 32.6224,
    '852_453': 32.6257,
    '852_454': 32.6358,
    '852_455': 32.6582,
    '852_456': 32.6733,
    '852_457': 32.6637,
    '852_458': 32.6467,
    '852_459': 32.627,
    '852_460': 32.61,
    '852_461': 32.6099,
    '852_462': 32.6255,
    '852_463': 32.6521,
    '852_464': 32.6787,
    '852_465': 32.6962,
    '852_466': 32.7133,
    '852_467': 32.7421,
    '852_468': 32.7974,
    '852_469': 32.8518,
    '852_470': 32.8976,
    '852_471': 32.9331,
    '852_472': 32.9608,
    '852_473': 32.9721,
    '852_474': 32.9783,
    '852_475': 32.9553,
    '852_476': 32.9154,
    '852_477': 32.8815,
    '852_478': 32.8528,
    '852_479': 32.8146,
    '852_480': 32.7712,
    '852_481': 32.7453,
    '852_482': 32.7177,
    '852_483': 32.7018,
    '852_484': 32.6908,
    '852_485': 32.6661,
    '852_486': 32.6408,
    '852_487': 32.6083,
    '852_488': 32.5592,
    '852_489': 32.5199,
    '852_490': 32.4983,
    '852_491': 32.4954,
    '852_492': 32.5047,
    '852_493': 32.5221,
    '852_494': 32.549,
    '852_495': 32.5826,
    '852_496': 32.629,
    '852_497': 32.6845,
    '852_498': 32.7365,
    '852_499': 32.7937,
    '852_500': 32.8581,
    '852_501': 32.9344,
    '852_502': 33.0168,
    '852_503': 33.0922,
    '852_504': 33.1625,
    '852_505': 33.2412,
    '852_506': 33.3261,
    '852_507': 33.4072,
    '852_508': 33.4891,
    '852_509': 33.5634,
    '852_510': 33.6297,
    '852_511': 33.6979,
    '852_512': 33.7718,
    '852_513': 33.8499,
    '852_514': 33.9293,
    '852_515': 34.0057,
    '852_516': 34.0804,
    '852_517': 34.1565,
    '852_518': 34.2378,
    '852_519': 34.3262,
    '852_520': 34.4026,
    '852_521': 34.4874,
    '852_522': 34.5713,
    '852_523': 34.6477,
    '852_524': 34.7216,
    '852_525': 34.7911,
    '852_526': 34.8601,
    '852_527': 34.9304,
    '852_528': 35.0002,
    '852_529': 35.0688,
    '852_530': 35.1353,
    '852_531': 35.1993,
    '852_532': 35.2561,
    '852_533': 35.3112,
    '852_534': 35.3642,
    '852_535': 35.4166,
    '852_536': 35.4699,
    '852_537': 35.5243,
    '852_538': 35.5803,
    '852_539': 35.6381,
    '852_540': 35.6979,
    '852_541': 35.7596,
    '852_542': 35.8232,
    '852_543': 35.8894,
    '852_544': 35.9581,
    '852_545': 36.0299,
    '852_546': 36.1054,
    '852_547': 36.1793,
    '852_548': 36.2411,
    '852_549': 36.3132,
    '852_550': 36.3943,
    '852_551': 36.4682,
    '852_552': 36.5374,
    '852_553': 36.585,
    '852_554': 36.6506,
    '852_555': 36.7266,
    '852_556': 36.7986,
    '852_557': 36.8559,
    '852_558': 36.9015,
    '852_559': 36.9432,
    '852_560': 36.9853,
    '852_561': 37.0283,
    '852_562': 37.0617,
    '852_563': 37.0942,
    '852_564': 37.1247,
    '852_565': 37.1496,
    '852_566': 37.1664,
    '852_567': 37.1761,
    '852_568': 37.186,
    '852_569': 37.1719,
    '852_570': 37.1347,
    '852_571': 37.0988,
    '852_572': 37.0614,
    '852_573': 37.0282,
    '852_574': 36.9985,
    '852_575': 36.968,
    '852_576': 36.9276,
    '852_577': 36.8923,
    '852_578': 36.8583,
    '852_579': 36.8328,
    '852_580': 36.8257,
    '852_581': 36.8046,
    '852_582': 36.7686,
    '852_583': 36.7346,
    '852_584': 36.7102,
    '852_585': 36.7006,
    '852_586': 36.6979,
    '852_587': 36.7104,
    '852_588': 36.7236,
    '852_589': 36.7466,
    '852_590': 36.7812,
    '852_591': 36.816,
    '852_592': 36.8479,
    '852_593': 36.8714,
    '852_594': 36.8929,
    '852_595': 36.9137,
    '852_596': 36.9379,
    '852_597': 36.9674,
    '852_598': 37.0047,
    '852_599': 37.0534,
    '852_600': 37.1165,
    '852_601': 37.191,
    '852_602': 37.2745,
    '852_603': 37.3658,
    '852_604': 37.4639,
    '852_605': 37.5681,
    '852_606': 37.681,
    '852_607': 37.7998,
    '852_608': 37.9251,
    '852_609': 38.0574,
    '852_610': 38.1991,
    '852_611': 38.3476,
    '852_612': 38.4941,
    '852_613': 38.6458,
    '852_614': 38.811,
    '852_615': 38.9789,
    '852_616': 39.1459,
    '852_617': 39.3136,
    '852_618': 39.4814,
    '852_619': 39.6322,
    '852_620': 39.7775,
    '852_621': 39.9247,
    '852_622': 40.0721,
    '852_623': 40.2179,
    '852_624': 40.3467,
    '852_625': 40.4587,
    '852_626': 40.5541,
    '852_627': 40.6385,
    '852_628': 40.7124,
    '852_629': 40.7799,
    '852_630': 40.8573,
    '852_631': 40.9459,
    '852_632': 41.0341,
    '852_633': 41.1207,
    '852_634': 41.211,
    '852_635': 41.2924,
    '852_636': 41.3487,
    '852_637': 41.3751,
    '852_638': 41.3528,
    '852_639': 41.3121,
    '852_640': 41.2547,
    '852_641': 41.214,
    '852_642': 41.1773,
    '852_643': 41.1257,
    '852_644': 41.0577,
    '852_645': 40.9584,
    '852_646': 40.8175,
    '852_647': 40.6418,
    '852_648': 40.4529,
    '852_649': 40.267,
    '852_650': 40.0993,
    '852_651': 39.9358,
    '852_652': 39.7829,
    '852_653': 39.639,
    '852_654': 39.5074,
    '852_655': 39.3887,
    '852_656': 39.265,
    '852_657': 39.1472,
    '852_658': 39.0305,
    '852_659': 38.9194,
    '852_660': 38.8157,
    '852_661': 38.7201,
    '852_662': 38.6327,
    '852_663': 38.5506,
    '852_664': 38.4713,
    '852_665': 38.3984,
    '852_666': 38.3334,
    '852_667': 38.2796,
    '852_668': 38.2354,
    '852_669': 38.1969,
    '852_670': 38.1619,
    '852_671': 38.1282,
    '852_672': 38.092,
    '852_673': 38.0477,
    '852_674': 37.9991,
    '852_675': 37.9482,
    '852_676': 37.8956,
    '852_677': 37.8407,
    '852_678': 37.7794,
    '852_679': 37.7008,
    '852_680': 37.616,
    '852_681': 37.5368,
    '852_682': 37.4734,
    '852_683': 37.4319,
    '852_684': 37.4011,
    '852_685': 37.3754,
    '852_686': 37.363,
    '852_687': 37.3518,
    '852_688': 37.3345,
    '852_689': 37.3427,
    '852_690': 37.3565,
    '852_691': 37.3679,
    '852_692': 37.3753,
    '852_693': 37.3808,
    '852_694': 37.384,
    '852_695': 37.3926,
    '852_696': 37.3947,
    '852_697': 37.3982,
    '852_698': 37.4042,
    '852_699': 37.4145,
    '852_700': 37.433,
    '852_701': 37.4581,
    '852_702': 37.4908,
    '852_703': 37.5352,
    '852_704': 37.5923,
    '852_762': 40.6924,
    '852_763': 40.7465,
    '852_764': 40.7866,
    '852_765': 40.8119,
    '852_766': 40.8172,
    '852_767': 40.7844,
    '852_768': 40.7314,
    '852_769': 40.6762,
    '852_770': 40.6241,
    '853_364': 29.9301,
    '853_365': 30.0478,
    '853_366': 30.1788,
    '853_367': 30.3195,
    '853_368': 30.4607,
    '853_369': 30.5757,
    '853_370': 30.6584,
    '853_371': 30.71,
    '853_372': 30.7209,
    '853_373': 30.7337,
    '853_374': 30.7459,
    '853_375': 30.7569,
    '853_376': 30.7679,
    '853_377': 30.7601,
    '853_378': 30.7368,
    '853_379': 30.7113,
    '853_380': 30.6845,
    '853_381': 30.6565,
    '853_382': 30.6452,
    '853_420': 31.3919,
    '853_421': 31.4682,
    '853_422': 31.5224,
    '853_423': 31.5748,
    '853_424': 31.6331,
    '853_425': 31.6953,
    '853_426': 31.7615,
    '853_427': 31.8324,
    '853_428': 31.9026,
    '853_429': 31.9729,
    '853_430': 32.0376,
    '853_431': 32.0933,
    '853_432': 32.1435,
    '853_433': 32.1881,
    '853_434': 32.2289,
    '853_435': 32.2667,
    '853_436': 32.3071,
    '853_437': 32.3619,
    '853_438': 32.434,
    '853_439': 32.5037,
    '853_440': 32.5411,
    '853_441': 32.5566,
    '853_442': 32.5618,
    '853_443': 32.573,
    '853_444': 32.5916,
    '853_445': 32.6133,
    '853_446': 32.6385,
    '853_447': 32.6547,
    '853_448': 32.6602,
    '853_449': 32.6638,
    '853_450': 32.6664,
    '853_451': 32.6574,
    '853_452': 32.6478,
    '853_453': 32.6512,
    '853_454': 32.6631,
    '853_455': 32.6911,
    '853_456': 32.7084,
    '853_457': 32.7118,
    '853_458': 32.7028,
    '853_459': 32.682,
    '853_460': 32.6616,
    '853_461': 32.6507,
    '853_462': 32.6565,
    '853_463': 32.6767,
    '853_464': 32.7037,
    '853_465': 32.7214,
    '853_466': 32.7428,
    '853_467': 32.7748,
    '853_468': 32.8353,
    '853_469': 32.9021,
    '853_470': 32.9524,
    '853_471': 32.9797,
    '853_472': 32.9987,
    '853_473': 33.0116,
    '853_474': 33.0208,
    '853_475': 33.0063,
    '853_476': 32.971,
    '853_477': 32.9334,
    '853_478': 32.8954,
    '853_479': 32.8517,
    '853_480': 32.8122,
    '853_481': 32.7905,
    '853_482': 32.7707,
    '853_483': 32.7456,
    '853_484': 32.7315,
    '853_485': 32.7105,
    '853_486': 32.687,
    '853_487': 32.6549,
    '853_488': 32.6087,
    '853_489': 32.5646,
    '853_490': 32.5418,
    '853_491': 32.5375,
    '853_492': 32.5443,
    '853_493': 32.56,
    '853_494': 32.5849,
    '853_495': 32.6221,
    '853_496': 32.6792,
    '853_497': 32.735,
    '853_498': 32.7733,
    '853_499': 32.8305,
    '853_500': 32.8961,
    '853_501': 32.9723,
    '853_502': 33.0625,
    '853_503': 33.1532,
    '853_504': 33.2162,
    '853_505': 33.2974,
    '853_506': 33.3834,
    '853_507': 33.4593,
    '853_508': 33.5258,
    '853_509': 33.5897,
    '853_510': 33.6528,
    '853_511': 33.7181,
    '853_512': 33.7868,
    '853_513': 33.8598,
    '853_514': 33.9389,
    '853_515': 34.0207,
    '853_516': 34.0961,
    '853_517': 34.1617,
    '853_518': 34.239,
    '853_519': 34.3245,
    '853_520': 34.4094,
    '853_521': 34.4984,
    '853_522': 34.5705,
    '853_523': 34.6446,
    '853_524': 34.7165,
    '853_525': 34.7822,
    '853_526': 34.8505,
    '853_527': 34.9189,
    '853_528': 34.9881,
    '853_529': 35.056,
    '853_530': 35.1212,
    '853_531': 35.1819,
    '853_532': 35.2383,
    '853_533': 35.2922,
    '853_534': 35.3449,
    '853_535': 35.3972,
    '853_536': 35.4502,
    '853_537': 35.5041,
    '853_538': 35.5595,
    '853_539': 35.6168,
    '853_540': 35.6759,
    '853_541': 35.7374,
    '853_542': 35.8012,
    '853_543': 35.8679,
    '853_544': 35.9376,
    '853_545': 36.0114,
    '853_546': 36.0845,
    '853_547': 36.1449,
    '853_548': 36.2107,
    '853_549': 36.2828,
    '853_550': 36.3582,
    '853_551': 36.421,
    '853_552': 36.4839,
    '853_553': 36.5398,
    '853_554': 36.6023,
    '853_555': 36.671,
    '853_556': 36.7382,
    '853_557': 36.7924,
    '853_558': 36.8373,
    '853_559': 36.881,
    '853_560': 36.921,
    '853_561': 36.9578,
    '853_562': 36.9894,
    '853_563': 37.0182,
    '853_564': 37.044,
    '853_565': 37.0682,
    '853_566': 37.0865,
    '853_567': 37.105,
    '853_568': 37.1246,
    '853_569': 37.1217,
    '853_570': 37.0929,
    '853_571': 37.0599,
    '853_572': 37.0135,
    '853_573': 36.9794,
    '853_574': 36.9473,
    '853_575': 36.9163,
    '853_576': 36.8812,
    '853_577': 36.8491,
    '853_578': 36.8218,
    '853_579': 36.8014,
    '853_580': 36.7949,
    '853_581': 36.7747,
    '853_582': 36.7451,
    '853_583': 36.7198,
    '853_584': 36.7016,
    '853_585': 36.6947,
    '853_586': 36.693,
    '853_587': 36.7052,
    '853_588': 36.7212,
    '853_589': 36.7471,
    '853_590': 36.7883,
    '853_591': 36.8366,
    '853_592': 36.8781,
    '853_593': 36.8969,
    '853_594': 36.9031,
    '853_595': 36.9182,
    '853_596': 36.9377,
    '853_597': 36.9636,
    '853_598': 36.9977,
    '853_599': 37.0429,
    '853_600': 37.102,
    '853_601': 37.1721,
    '853_602': 37.2516,
    '853_603': 37.3389,
    '853_604': 37.4324,
    '853_605': 37.5339,
    '853_606': 37.644,
    '853_607': 37.7617,
    '853_608': 37.8827,
    '853_609': 38.0162,
    '853_610': 38.1569,
    '853_611': 38.3042,
    '853_612': 38.4456,
    '853_613': 38.5892,
    '853_614': 38.7521,
    '853_615': 38.9195,
    '853_616': 39.0919,
    '853_617': 39.2595,
    '853_618': 39.4147,
    '853_619': 39.5593,
    '853_620': 39.699,
    '853_621': 39.8395,
    '853_622': 39.9834,
    '853_623': 40.1268,
    '853_624': 40.2557,
    '853_625': 40.3641,
    '853_626': 40.4582,
    '853_627': 40.5457,
    '853_628': 40.6264,
    '853_629': 40.7039,
    '853_630': 40.7895,
    '853_631': 40.8835,
    '853_632': 40.9752,
    '853_633': 41.062,
    '853_634': 41.1521,
    '853_635': 41.2365,
    '853_636': 41.3047,
    '853_637': 41.3374,
    '853_638': 41.3337,
    '853_639': 41.3029,
    '853_640': 41.2544,
    '853_641': 41.2108,
    '853_642': 41.1672,
    '853_643': 41.1189,
    '853_644': 41.0537,
    '853_645': 40.9584,
    '853_646': 40.8318,
    '853_647': 40.6692,
    '853_648': 40.5009,
    '853_649': 40.3277,
    '853_650': 40.1624,
    '853_651': 39.9977,
    '853_652': 39.8452,
    '853_653': 39.7084,
    '853_654': 39.5851,
    '853_655': 39.4697,
    '853_656': 39.3439,
    '853_657': 39.2298,
    '853_658': 39.1105,
    '853_659': 38.997,
    '853_660': 38.8943,
    '853_661': 38.7961,
    '853_662': 38.7087,
    '853_663': 38.627,
    '853_664': 38.5509,
    '853_665': 38.4812,
    '853_666': 38.4183,
    '853_667': 38.3645,
    '853_668': 38.3193,
    '853_669': 38.28,
    '853_670': 38.2445,
    '853_671': 38.2109,
    '853_672': 38.176,
    '853_673': 38.1356,
    '853_674': 38.0913,
    '853_675': 38.0445,
    '853_676': 37.9954,
    '853_677': 37.9436,
    '853_678': 37.8863,
    '853_679': 37.8183,
    '853_680': 37.742,
    '853_681': 37.6701,
    '853_682': 37.6112,
    '853_683': 37.5674,
    '853_684': 37.5331,
    '853_685': 37.5048,
    '853_686': 37.4884,
    '853_687': 37.473,
    '853_688': 37.459,
    '853_689': 37.4632,
    '853_690': 37.4672,
    '853_691': 37.4657,
    '853_692': 37.4631,
    '853_693': 37.4563,
    '853_694': 37.4526,
    '853_695': 37.4527,
    '853_696': 37.4516,
    '853_697': 37.4528,
    '853_698': 37.4551,
    '853_699': 37.4598,
    '853_700': 37.4717,
    '853_701': 37.4908,
    '853_702': 37.5132,
    '853_703': 37.5515,
    '853_704': 37.6034,
    '853_762': 40.6347,
    '853_763': 40.6965,
    '853_764': 40.7464,
    '853_765': 40.7845,
    '853_766': 40.8039,
    '853_767': 40.7741,
    '853_768': 40.7135,
    '853_769': 40.6536,
    '853_770': 40.5961,
    '854_364': 29.8974,
    '854_365': 30.0116,
    '854_366': 30.14,
    '854_367': 30.2781,
    '854_368': 30.4207,
    '854_369': 30.5435,
    '854_370': 30.6338,
    '854_371': 30.6909,
    '854_372': 30.7161,
    '854_373': 30.728,
    '854_374': 30.7443,
    '854_375': 30.7624,
    '854_376': 30.7775,
    '854_377': 30.7792,
    '854_378': 30.7568,
    '854_379': 30.7301,
    '854_380': 30.7011,
    '854_381': 30.6707,
    '854_382': 30.6508,
    '854_420': 31.3623,
    '854_421': 31.4251,
    '854_422': 31.4809,
    '854_423': 31.5372,
    '854_424': 31.596,
    '854_425': 31.6587,
    '854_426': 31.7268,
    '854_427': 31.8012,
    '854_428': 31.8692,
    '854_429': 31.9434,
    '854_430': 32.0114,
    '854_431': 32.0715,
    '854_432': 32.1247,
    '854_433': 32.1731,
    '854_434': 32.2184,
    '854_435': 32.2622,
    '854_436': 32.3094,
    '854_437': 32.3707,
    '854_438': 32.4379,
    '854_439': 32.4947,
    '854_440': 32.5305,
    '854_441': 32.5443,
    '854_442': 32.5595,
    '854_443': 32.5828,
    '854_444': 32.605,
    '854_445': 32.6203,
    '854_446': 32.6466,
    '854_447': 32.6683,
    '854_448': 32.6741,
    '854_449': 32.6762,
    '854_450': 32.6755,
    '854_451': 32.6721,
    '854_452': 32.6759,
    '854_453': 32.6786,
    '854_454': 32.6884,
    '854_455': 32.7181,
    '854_456': 32.7361,
    '854_457': 32.7435,
    '854_458': 32.742,
    '854_459': 32.7248,
    '854_460': 32.7059,
    '854_461': 32.6991,
    '854_462': 32.7052,
    '854_463': 32.7143,
    '854_464': 32.7312,
    '854_465': 32.7463,
    '854_466': 32.7672,
    '854_467': 32.813,
    '854_468': 32.8758,
    '854_469': 32.9465,
    '854_470': 33.0033,
    '854_471': 33.0289,
    '854_472': 33.0397,
    '854_473': 33.0534,
    '854_474': 33.0653,
    '854_475': 33.059,
    '854_476': 33.0298,
    '854_477': 32.9813,
    '854_478': 32.9401,
    '854_479': 32.8927,
    '854_480': 32.8573,
    '854_481': 32.8387,
    '854_482': 32.8207,
    '854_483': 32.793,
    '854_484': 32.7756,
    '854_485': 32.7492,
    '854_486': 32.7272,
    '854_487': 32.7032,
    '854_488': 32.6647,
    '854_489': 32.6148,
    '854_490': 32.5897,
    '854_491': 32.5866,
    '854_492': 32.5917,
    '854_493': 32.602,
    '854_494': 32.6241,
    '854_495': 32.6608,
    '854_496': 32.7201,
    '854_497': 32.7714,
    '854_498': 32.8072,
    '854_499': 32.8675,
    '854_500': 32.9355,
    '854_501': 33.0122,
    '854_502': 33.102,
    '854_503': 33.1998,
    '854_504': 33.2743,
    '854_505': 33.355,
    '854_506': 33.4519,
    '854_507': 33.5326,
    '854_508': 33.5792,
    '854_509': 33.6296,
    '854_510': 33.6847,
    '854_511': 33.7432,
    '854_512': 33.8066,
    '854_513': 33.8744,
    '854_514': 33.9476,
    '854_515': 34.0256,
    '854_516': 34.1018,
    '854_517': 34.1681,
    '854_518': 34.2401,
    '854_519': 34.3206,
    '854_520': 34.4098,
    '854_521': 34.5043,
    '854_522': 34.5675,
    '854_523': 34.6357,
    '854_524': 34.7062,
    '854_525': 34.7743,
    '854_526': 34.8433,
    '854_527': 34.9096,
    '854_528': 34.9768,
    '854_529': 35.0424,
    '854_530': 35.1066,
    '854_531': 35.1642,
    '854_532': 35.2189,
    '854_533': 35.2722,
    '854_534': 35.3246,
    '854_535': 35.3769,
    '854_536': 35.4296,
    '854_537': 35.4832,
    '854_538': 35.5381,
    '854_539': 35.5948,
    '854_540': 35.6536,
    '854_541': 35.7149,
    '854_542': 35.7789,
    '854_543': 35.8465,
    '854_544': 35.9182,
    '854_545': 35.9846,
    '854_546': 36.0509,
    '854_547': 36.1122,
    '854_548': 36.1794,
    '854_549': 36.2527,
    '854_550': 36.3145,
    '854_551': 36.3743,
    '854_552': 36.4368,
    '854_553': 36.4966,
    '854_554': 36.5564,
    '854_555': 36.6181,
    '854_556': 36.6788,
    '854_557': 36.7335,
    '854_558': 36.7828,
    '854_559': 36.8248,
    '854_560': 36.8615,
    '854_561': 36.8936,
    '854_562': 36.9231,
    '854_563': 36.9459,
    '854_564': 36.9683,
    '854_565': 36.9927,
    '854_566': 37.0132,
    '854_567': 37.0342,
    '854_568': 37.0556,
    '854_569': 37.0578,
    '854_570': 37.0397,
    '854_571': 37.0151,
    '854_572': 36.9741,
    '854_573': 36.9377,
    '854_574': 36.9081,
    '854_575': 36.8816,
    '854_576': 36.8516,
    '854_577': 36.8225,
    '854_578': 36.7977,
    '854_579': 36.7776,
    '854_580': 36.7627,
    '854_581': 36.7438,
    '854_582': 36.7232,
    '854_583': 36.7075,
    '854_584': 36.695,
    '854_585': 36.6888,
    '854_586': 36.6895,
    '854_587': 36.7031,
    '854_588': 36.721,
    '854_589': 36.7494,
    '854_590': 36.7884,
    '854_591': 36.8421,
    '854_592': 36.8933,
    '854_593': 36.9242,
    '854_594': 36.9303,
    '854_595': 36.9303,
    '854_596': 36.941,
    '854_597': 36.962,
    '854_598': 36.9914,
    '854_599': 37.0329,
    '854_600': 37.0872,
    '854_601': 37.1529,
    '854_602': 37.2285,
    '854_603': 37.3125,
    '854_604': 37.4028,
    '854_605': 37.5009,
    '854_606': 37.6085,
    '854_607': 37.7242,
    '854_608': 37.8423,
    '854_609': 37.9731,
    '854_610': 38.1091,
    '854_611': 38.2521,
    '854_612': 38.3936,
    '854_613': 38.5335,
    '854_614': 38.6847,
    '854_615': 38.8574,
    '854_616': 39.0369,
    '854_617': 39.2043,
    '854_618': 39.3513,
    '854_619': 39.4865,
    '854_620': 39.6214,
    '854_621': 39.7533,
    '854_622': 39.8832,
    '854_623': 40.0205,
    '854_624': 40.1488,
    '854_625': 40.2581,
    '854_626': 40.3513,
    '854_627': 40.4446,
    '854_628': 40.5315,
    '854_629': 40.6166,
    '854_630': 40.7068,
    '854_631': 40.8053,
    '854_632': 40.9028,
    '854_633': 40.9936,
    '854_634': 41.0836,
    '854_635': 41.1747,
    '854_636': 41.2462,
    '854_637': 41.2884,
    '854_638': 41.3041,
    '854_639': 41.2736,
    '854_640': 41.2285,
    '854_641': 41.1785,
    '854_642': 41.136,
    '854_643': 41.083,
    '854_644': 41.0256,
    '854_645': 40.95,
    '854_646': 40.8375,
    '854_647': 40.6903,
    '854_648': 40.5362,
    '854_649': 40.3833,
    '854_650': 40.2184,
    '854_651': 40.0584,
    '854_652': 39.9226,
    '854_653': 39.7943,
    '854_654': 39.6699,
    '854_655': 39.5526,
    '854_656': 39.4237,
    '854_657': 39.3122,
    '854_658': 39.1858,
    '854_659': 39.0723,
    '854_660': 38.9654,
    '854_661': 38.8676,
    '854_662': 38.7806,
    '854_663': 38.6978,
    '854_664': 38.6238,
    '854_665': 38.5555,
    '854_666': 38.4932,
    '854_667': 38.4395,
    '854_668': 38.3939,
    '854_669': 38.3544,
    '854_670': 38.3194,
    '854_671': 38.2867,
    '854_672': 38.2554,
    '854_673': 38.2182,
    '854_674': 38.1757,
    '854_675': 38.1318,
    '854_676': 38.0851,
    '854_677': 38.0362,
    '854_678': 37.9837,
    '854_679': 37.9247,
    '854_680': 37.8559,
    '854_681': 37.7908,
    '854_682': 37.7384,
    '854_683': 37.6977,
    '854_684': 37.6654,
    '854_685': 37.6385,
    '854_686': 37.613,
    '854_687': 37.5897,
    '854_688': 37.5709,
    '854_689': 37.57,
    '854_690': 37.5676,
    '854_691': 37.5606,
    '854_692': 37.5533,
    '854_693': 37.538,
    '854_694': 37.5279,
    '854_695': 37.5203,
    '854_696': 37.5118,
    '854_697': 37.5052,
    '854_698': 37.5041,
    '854_699': 37.5078,
    '854_700': 37.5171,
    '854_701': 37.5273,
    '854_702': 37.5459,
    '854_703': 37.5767,
    '854_704': 37.6179,
    '854_762': 40.5784,
    '854_763': 40.643,
    '854_764': 40.6978,
    '854_765': 40.7418,
    '854_766': 40.7642,
    '854_767': 40.7381,
    '854_768': 40.6866,
    '854_769': 40.6279,
    '854_770': 40.5712,
    '855_364': 29.8617,
    '855_365': 29.9729,
    '855_366': 30.0994,
    '855_367': 30.2364,
    '855_368': 30.3819,
    '855_369': 30.5078,
    '855_370': 30.606,
    '855_371': 30.6599,
    '855_372': 30.6968,
    '855_373': 30.7176,
    '855_374': 30.742,
    '855_375': 30.7662,
    '855_376': 30.7844,
    '855_377': 30.7917,
    '855_378': 30.7706,
    '855_379': 30.7468,
    '855_380': 30.7193,
    '855_381': 30.6845,
    '855_382': 30.6567,
    '855_420': 31.3281,
    '855_421': 31.3861,
    '855_422': 31.4445,
    '855_423': 31.5018,
    '855_424': 31.5608,
    '855_425': 31.6252,
    '855_426': 31.6973,
    '855_427': 31.7706,
    '855_428': 31.8289,
    '855_429': 31.9114,
    '855_430': 31.9822,
    '855_431': 32.0461,
    '855_432': 32.1028,
    '855_433': 32.1553,
    '855_434': 32.2051,
    '855_435': 32.2539,
    '855_436': 32.3113,
    '855_437': 32.3759,
    '855_438': 32.434,
    '855_439': 32.4768,
    '855_440': 32.5125,
    '855_441': 32.5384,
    '855_442': 32.565,
    '855_443': 32.5964,
    '855_444': 32.6182,
    '855_445': 32.6268,
    '855_446': 32.6504,
    '855_447': 32.6723,
    '855_448': 32.679,
    '855_449': 32.6791,
    '855_450': 32.6762,
    '855_451': 32.6792,
    '855_452': 32.694,
    '855_453': 32.6995,
    '855_454': 32.7081,
    '855_455': 32.7299,
    '855_456': 32.7566,
    '855_457': 32.7648,
    '855_458': 32.7628,
    '855_459': 32.7509,
    '855_460': 32.7435,
    '855_461': 32.7403,
    '855_462': 32.7477,
    '855_463': 32.7576,
    '855_464': 32.7648,
    '855_465': 32.781,
    '855_466': 32.8,
    '855_467': 32.857,
    '855_468': 32.9237,
    '855_469': 32.9854,
    '855_470': 33.0437,
    '855_471': 33.0717,
    '855_472': 33.0813,
    '855_473': 33.0983,
    '855_474': 33.1138,
    '855_475': 33.1151,
    '855_476': 33.0869,
    '855_477': 33.0287,
    '855_478': 32.9756,
    '855_479': 32.9366,
    '855_480': 32.9066,
    '855_481': 32.8848,
    '855_482': 32.8687,
    '855_483': 32.8432,
    '855_484': 32.8234,
    '855_485': 32.7993,
    '855_486': 32.7813,
    '855_487': 32.7683,
    '855_488': 32.7329,
    '855_489': 32.6743,
    '855_490': 32.6433,
    '855_491': 32.642,
    '855_492': 32.6526,
    '855_493': 32.6508,
    '855_494': 32.666,
    '855_495': 32.6977,
    '855_496': 32.7498,
    '855_497': 32.7982,
    '855_498': 32.8461,
    '855_499': 32.9138,
    '855_500': 32.9782,
    '855_501': 33.0597,
    '855_502': 33.1548,
    '855_503': 33.2487,
    '855_504': 33.3438,
    '855_505': 33.4258,
    '855_506': 33.5318,
    '855_507': 33.6169,
    '855_508': 33.6546,
    '855_509': 33.6836,
    '855_510': 33.7283,
    '855_511': 33.776,
    '855_512': 33.8329,
    '855_513': 33.8953,
    '855_514': 33.9616,
    '855_515': 34.0331,
    '855_516': 34.107,
    '855_517': 34.1818,
    '855_518': 34.2477,
    '855_519': 34.325,
    '855_520': 34.4114,
    '855_521': 34.5004,
    '855_522': 34.566,
    '855_523': 34.6339,
    '855_524': 34.7043,
    '855_525': 34.7722,
    '855_526': 34.8438,
    '855_527': 34.9028,
    '855_528': 34.967,
    '855_529': 35.0287,
    '855_530': 35.0881,
    '855_531': 35.1446,
    '855_532': 35.1991,
    '855_533': 35.2522,
    '855_534': 35.3047,
    '855_535': 35.3569,
    '855_536': 35.4094,
    '855_537': 35.4628,
    '855_538': 35.517,
    '855_539': 35.5731,
    '855_540': 35.6312,
    '855_541': 35.6921,
    '855_542': 35.756,
    '855_543': 35.8241,
    '855_544': 35.8932,
    '855_545': 35.9547,
    '855_546': 36.0179,
    '855_547': 36.0807,
    '855_548': 36.1457,
    '855_549': 36.213,
    '855_550': 36.2738,
    '855_551': 36.335,
    '855_552': 36.3987,
    '855_553': 36.4586,
    '855_554': 36.5174,
    '855_555': 36.5783,
    '855_556': 36.6376,
    '855_557': 36.6884,
    '855_558': 36.7359,
    '855_559': 36.7749,
    '855_560': 36.8077,
    '855_561': 36.8362,
    '855_562': 36.8653,
    '855_563': 36.8886,
    '855_564': 36.9093,
    '855_565': 36.9335,
    '855_566': 36.9554,
    '855_567': 36.9748,
    '855_568': 36.9914,
    '855_569': 36.9966,
    '855_570': 36.9897,
    '855_571': 36.9733,
    '855_572': 36.939,
    '855_573': 36.9069,
    '855_574': 36.8815,
    '855_575': 36.8591,
    '855_576': 36.8335,
    '855_577': 36.8068,
    '855_578': 36.7841,
    '855_579': 36.764,
    '855_580': 36.7461,
    '855_581': 36.7285,
    '855_582': 36.7114,
    '855_583': 36.6985,
    '855_584': 36.6892,
    '855_585': 36.6852,
    '855_586': 36.6924,
    '855_587': 36.7064,
    '855_588': 36.7277,
    '855_589': 36.7512,
    '855_590': 36.7828,
    '855_591': 36.8317,
    '855_592': 36.886,
    '855_593': 36.9319,
    '855_594': 36.9564,
    '855_595': 36.9572,
    '855_596': 36.9562,
    '855_597': 36.965,
    '855_598': 36.985,
    '855_599': 37.0209,
    '855_600': 37.0706,
    '855_601': 37.1324,
    '855_602': 37.2046,
    '855_603': 37.2872,
    '855_604': 37.3766,
    '855_605': 37.4711,
    '855_606': 37.574,
    '855_607': 37.684,
    '855_608': 37.8014,
    '855_609': 37.9264,
    '855_610': 38.0553,
    '855_611': 38.1887,
    '855_612': 38.3283,
    '855_613': 38.4686,
    '855_614': 38.6121,
    '855_615': 38.7719,
    '855_616': 38.9417,
    '855_617': 39.1072,
    '855_618': 39.26,
    '855_619': 39.4038,
    '855_620': 39.5367,
    '855_621': 39.6596,
    '855_622': 39.7695,
    '855_623': 39.8952,
    '855_624': 40.0237,
    '855_625': 40.1375,
    '855_626': 40.2375,
    '855_627': 40.3381,
    '855_628': 40.431,
    '855_629': 40.5171,
    '855_630': 40.6051,
    '855_631': 40.7096,
    '855_632': 40.8137,
    '855_633': 40.9124,
    '855_634': 41.0074,
    '855_635': 41.1015,
    '855_636': 41.179,
    '855_637': 41.2315,
    '855_638': 41.2444,
    '855_639': 41.2221,
    '855_640': 41.1787,
    '855_641': 41.1344,
    '855_642': 41.093,
    '855_643': 41.0476,
    '855_644': 41.0028,
    '855_645': 40.9406,
    '855_646': 40.8402,
    '855_647': 40.7038,
    '855_648': 40.5595,
    '855_649': 40.426,
    '855_650': 40.2768,
    '855_651': 40.1274,
    '855_652': 40.0048,
    '855_653': 39.8753,
    '855_654': 39.7456,
    '855_655': 39.627,
    '855_656': 39.5005,
    '855_657': 39.3796,
    '855_658': 39.2507,
    '855_659': 39.1369,
    '855_660': 39.0309,
    '855_661': 38.9329,
    '855_662': 38.8473,
    '855_663': 38.7674,
    '855_664': 38.6963,
    '855_665': 38.6251,
    '855_666': 38.562,
    '855_667': 38.5073,
    '855_668': 38.4615,
    '855_669': 38.4225,
    '855_670': 38.3903,
    '855_671': 38.3585,
    '855_672': 38.3272,
    '855_673': 38.2932,
    '855_674': 38.2548,
    '855_675': 38.2127,
    '855_676': 38.1673,
    '855_677': 38.1207,
    '855_678': 38.0713,
    '855_679': 38.017,
    '855_680': 37.958,
    '855_681': 37.9018,
    '855_682': 37.8562,
    '855_683': 37.8199,
    '855_684': 37.7904,
    '855_685': 37.7637,
    '855_686': 37.7326,
    '855_687': 37.7028,
    '855_688': 37.68,
    '855_689': 37.6757,
    '855_690': 37.6662,
    '855_691': 37.6523,
    '855_692': 37.6386,
    '855_693': 37.6178,
    '855_694': 37.5996,
    '855_695': 37.5905,
    '855_696': 37.5761,
    '855_697': 37.5643,
    '855_698': 37.5654,
    '855_699': 37.5622,
    '855_700': 37.5616,
    '855_701': 37.5651,
    '855_702': 37.5823,
    '855_703': 37.6068,
    '855_704': 37.6403,
    '855_762': 40.5246,
    '855_763': 40.5869,
    '855_764': 40.6402,
    '855_765': 40.6826,
    '855_766': 40.7057,
    '855_767': 40.694,
    '855_768': 40.6586,
    '855_769': 40.6071,
    '855_770': 40.5491,
    '856_364': 29.8217,
    '856_365': 29.9312,
    '856_366': 30.0563,
    '856_367': 30.1912,
    '856_368': 30.3319,
    '856_369': 30.4608,
    '856_370': 30.5623,
    '856_371': 30.6206,
    '856_372': 30.668,
    '856_373': 30.7041,
    '856_374': 30.7382,
    '856_375': 30.7692,
    '856_376': 30.7909,
    '856_377': 30.8004,
    '856_378': 30.7808,
    '856_379': 30.7593,
    '856_380': 30.7318,
    '856_381': 30.6982,
    '856_382': 30.6687,
    '856_420': 31.2896,
    '856_421': 31.3543,
    '856_422': 31.4124,
    '856_423': 31.4678,
    '856_424': 31.5254,
    '856_425': 31.5904,
    '856_426': 31.6644,
    '856_427': 31.7377,
    '856_428': 31.8032,
    '856_429': 31.8749,
    '856_430': 31.9468,
    '856_431': 32.0144,
    '856_432': 32.0761,
    '856_433': 32.1335,
    '856_434': 32.1862,
    '856_435': 32.2426,
    '856_436': 32.3063,
    '856_437': 32.3683,
    '856_438': 32.4227,
    '856_439': 32.4649,
    '856_440': 32.5024,
    '856_441': 32.5434,
    '856_442': 32.5757,
    '856_443': 32.607,
    '856_444': 32.6255,
    '856_445': 32.6315,
    '856_446': 32.6591,
    '856_447': 32.6776,
    '856_448': 32.6851,
    '856_449': 32.6894,
    '856_450': 32.6816,
    '856_451': 32.684,
    '856_452': 32.7004,
    '856_453': 32.7075,
    '856_454': 32.7144,
    '856_455': 32.739,
    '856_456': 32.765,
    '856_457': 32.7702,
    '856_458': 32.7634,
    '856_459': 32.7592,
    '856_460': 32.7623,
    '856_461': 32.7674,
    '856_462': 32.776,
    '856_463': 32.7926,
    '856_464': 32.8069,
    '856_465': 32.8207,
    '856_466': 32.8377,
    '856_467': 32.8954,
    '856_468': 32.9608,
    '856_469': 33.0176,
    '856_470': 33.0721,
    '856_471': 33.1041,
    '856_472': 33.1209,
    '856_473': 33.1456,
    '856_474': 33.1616,
    '856_475': 33.1599,
    '856_476': 33.1329,
    '856_477': 33.0791,
    '856_478': 33.018,
    '856_479': 32.9748,
    '856_480': 32.9556,
    '856_481': 32.9366,
    '856_482': 32.9176,
    '856_483': 32.8982,
    '856_484': 32.8739,
    '856_485': 32.8452,
    '856_486': 32.8292,
    '856_487': 32.8235,
    '856_488': 32.7949,
    '856_489': 32.7445,
    '856_490': 32.707,
    '856_491': 32.6921,
    '856_492': 32.7081,
    '856_493': 32.7104,
    '856_494': 32.7106,
    '856_495': 32.7345,
    '856_496': 32.7743,
    '856_497': 32.8226,
    '856_498': 32.8841,
    '856_499': 32.9583,
    '856_500': 33.0244,
    '856_501': 33.1147,
    '856_502': 33.2184,
    '856_503': 33.3178,
    '856_504': 33.4188,
    '856_505': 33.4963,
    '856_506': 33.592,
    '856_507': 33.6789,
    '856_508': 33.7196,
    '856_509': 33.7335,
    '856_510': 33.7728,
    '856_511': 33.8132,
    '856_512': 33.8662,
    '856_513': 33.9232,
    '856_514': 33.9851,
    '856_515': 34.051,
    '856_516': 34.1207,
    '856_517': 34.1949,
    '856_518': 34.2595,
    '856_519': 34.3353,
    '856_520': 34.4209,
    '856_521': 34.5015,
    '856_522': 34.5648,
    '856_523': 34.643,
    '856_524': 34.7152,
    '856_525': 34.7715,
    '856_526': 34.8363,
    '856_527': 34.8948,
    '856_528': 34.9566,
    '856_529': 35.0186,
    '856_530': 35.07,
    '856_531': 35.1249,
    '856_532': 35.1791,
    '856_533': 35.2322,
    '856_534': 35.2847,
    '856_535': 35.3368,
    '856_536': 35.3892,
    '856_537': 35.442,
    '856_538': 35.4957,
    '856_539': 35.5507,
    '856_540': 35.6076,
    '856_541': 35.6673,
    '856_542': 35.7302,
    '856_543': 35.7962,
    '856_544': 35.8617,
    '856_545': 35.9258,
    '856_546': 35.9883,
    '856_547': 36.0507,
    '856_548': 36.1136,
    '856_549': 36.1772,
    '856_550': 36.2389,
    '856_551': 36.3008,
    '856_552': 36.3646,
    '856_553': 36.4265,
    '856_554': 36.4876,
    '856_555': 36.5464,
    '856_556': 36.6059,
    '856_557': 36.6514,
    '856_558': 36.6981,
    '856_559': 36.7336,
    '856_560': 36.7614,
    '856_561': 36.7833,
    '856_562': 36.8096,
    '856_563': 36.8369,
    '856_564': 36.8626,
    '856_565': 36.8889,
    '856_566': 36.9136,
    '856_567': 36.9299,
    '856_568': 36.943,
    '856_569': 36.9498,
    '856_570': 36.9517,
    '856_571': 36.9405,
    '856_572': 36.9142,
    '856_573': 36.8876,
    '856_574': 36.8636,
    '856_575': 36.8419,
    '856_576': 36.8191,
    '856_577': 36.7963,
    '856_578': 36.7748,
    '856_579': 36.7553,
    '856_580': 36.7376,
    '856_581': 36.7211,
    '856_582': 36.7064,
    '856_583': 36.6941,
    '856_584': 36.6862,
    '856_585': 36.6837,
    '856_586': 36.6925,
    '856_587': 36.7107,
    '856_588': 36.7373,
    '856_589': 36.7582,
    '856_590': 36.7769,
    '856_591': 36.8176,
    '856_592': 36.8691,
    '856_593': 36.9236,
    '856_594': 36.9596,
    '856_595': 36.9743,
    '856_596': 36.9789,
    '856_597': 36.979,
    '856_598': 36.9799,
    '856_599': 37.0046,
    '856_600': 37.0494,
    '856_601': 37.1078,
    '856_602': 37.179,
    '856_603': 37.2643,
    '856_604': 37.3578,
    '856_605': 37.4541,
    '856_606': 37.5551,
    '856_607': 37.664,
    '856_608': 37.7752,
    '856_609': 37.8911,
    '856_610': 38.0121,
    '856_611': 38.1363,
    '856_612': 38.2679,
    '856_613': 38.4029,
    '856_614': 38.5433,
    '856_615': 38.6895,
    '856_616': 38.8409,
    '856_617': 38.996,
    '856_618': 39.1474,
    '856_619': 39.2967,
    '856_620': 39.4366,
    '856_621': 39.5561,
    '856_622': 39.6514,
    '856_623': 39.7624,
    '856_624': 39.8883,
    '856_625': 40.008,
    '856_626': 40.1176,
    '856_627': 40.2264,
    '856_628': 40.3216,
    '856_629': 40.404,
    '856_630': 40.4901,
    '856_631': 40.6018,
    '856_632': 40.7107,
    '856_633': 40.8191,
    '856_634': 40.9209,
    '856_635': 41.0194,
    '856_636': 41.103,
    '856_637': 41.1605,
    '856_638': 41.1774,
    '856_639': 41.1582,
    '856_640': 41.1113,
    '856_641': 41.082,
    '856_642': 41.0584,
    '856_643': 41.0244,
    '856_644': 40.9867,
    '856_645': 40.9266,
    '856_646': 40.8352,
    '856_647': 40.7037,
    '856_648': 40.5696,
    '856_649': 40.449,
    '856_650': 40.3229,
    '856_651': 40.2003,
    '856_652': 40.0729,
    '856_653': 39.9398,
    '856_654': 39.8073,
    '856_655': 39.6841,
    '856_656': 39.5614,
    '856_657': 39.4451,
    '856_658': 39.3177,
    '856_659': 39.2036,
    '856_660': 39.0942,
    '856_661': 38.9963,
    '856_662': 38.9105,
    '856_663': 38.8315,
    '856_664': 38.7649,
    '856_665': 38.6934,
    '856_666': 38.6262,
    '856_667': 38.5679,
    '856_668': 38.5218,
    '856_669': 38.4849,
    '856_670': 38.4512,
    '856_671': 38.4204,
    '856_672': 38.389,
    '856_673': 38.3562,
    '856_674': 38.3222,
    '856_675': 38.2836,
    '856_676': 38.241,
    '856_677': 38.1964,
    '856_678': 38.1505,
    '856_679': 38.1012,
    '856_680': 38.051,
    '856_681': 38.0041,
    '856_682': 37.9639,
    '856_683': 37.9309,
    '856_684': 37.9029,
    '856_685': 37.8763,
    '856_686': 37.8435,
    '856_687': 37.8119,
    '856_688': 37.7863,
    '856_689': 37.774,
    '856_690': 37.7603,
    '856_691': 37.7415,
    '856_692': 37.7161,
    '856_693': 37.6944,
    '856_694': 37.6672,
    '856_695': 37.6563,
    '856_696': 37.642,
    '856_697': 37.6323,
    '856_698': 37.6248,
    '856_699': 37.6175,
    '856_700': 37.6099,
    '856_701': 37.6068,
    '856_702': 37.6261,
    '856_703': 37.6436,
    '856_704': 37.6687,
    '856_762': 40.4734,
    '856_763': 40.5292,
    '856_764': 40.5794,
    '856_765': 40.621,
    '856_766': 40.6505,
    '856_767': 40.6489,
    '856_768': 40.6268,
    '856_769': 40.5808,
    '856_770': 40.5245,
    '857_364': 29.7816,
    '857_365': 29.8898,
    '857_366': 30.0131,
    '857_367': 30.1451,
    '857_368': 30.2822,
    '857_369': 30.4054,
    '857_370': 30.5066,
    '857_371': 30.5795,
    '857_372': 30.6358,
    '857_373': 30.6861,
    '857_374': 30.7305,
    '857_375': 30.7694,
    '857_376': 30.7938,
    '857_377': 30.806,
    '857_378': 30.7972,
    '857_379': 30.7763,
    '857_380': 30.7496,
    '857_381': 30.7177,
    '857_382': 30.6857,
    '857_420': 31.2554,
    '857_421': 31.3182,
    '857_422': 31.3791,
    '857_423': 31.4341,
    '857_424': 31.4925,
    '857_425': 31.5591,
    '857_426': 31.6325,
    '857_427': 31.706,
    '857_428': 31.775,
    '857_429': 31.8374,
    '857_430': 31.9101,
    '857_431': 31.9807,
    '857_432': 32.0472,
    '857_433': 32.1093,
    '857_434': 32.1662,
    '857_435': 32.2274,
    '857_436': 32.2904,
    '857_437': 32.3536,
    '857_438': 32.405,
    '857_439': 32.4531,
    '857_440': 32.5039,
    '857_441': 32.5541,
    '857_442': 32.588,
    '857_443': 32.6115,
    '857_444': 32.6175,
    '857_445': 32.6258,
    '857_446': 32.6529,
    '857_447': 32.6729,
    '857_448': 32.6807,
    '857_449': 32.6935,
    '857_450': 32.687,
    '857_451': 32.6935,
    '857_452': 32.7027,
    '857_453': 32.7086,
    '857_454': 32.7186,
    '857_455': 32.7372,
    '857_456': 32.7556,
    '857_457': 32.7606,
    '857_458': 32.7561,
    '857_459': 32.7584,
    '857_460': 32.7682,
    '857_461': 32.7854,
    '857_462': 32.7967,
    '857_463': 32.8165,
    '857_464': 32.8426,
    '857_465': 32.8604,
    '857_466': 32.8804,
    '857_467': 32.9284,
    '857_468': 32.9853,
    '857_469': 33.0438,
    '857_470': 33.0964,
    '857_471': 33.131,
    '857_472': 33.1576,
    '857_473': 33.1864,
    '857_474': 33.201,
    '857_475': 33.1971,
    '857_476': 33.1716,
    '857_477': 33.1284,
    '857_478': 33.0654,
    '857_479': 33.0176,
    '857_480': 33.0007,
    '857_481': 32.9874,
    '857_482': 32.9711,
    '857_483': 32.9549,
    '857_484': 32.9291,
    '857_485': 32.8922,
    '857_486': 32.8727,
    '857_487': 32.8675,
    '857_488': 32.8501,
    '857_489': 32.8154,
    '857_490': 32.783,
    '857_491': 32.7458,
    '857_492': 32.7515,
    '857_493': 32.7607,
    '857_494': 32.7558,
    '857_495': 32.7722,
    '857_496': 32.8065,
    '857_497': 32.8536,
    '857_498': 32.9148,
    '857_499': 32.9851,
    '857_500': 33.0625,
    '857_501': 33.1644,
    '857_502': 33.2714,
    '857_503': 33.3712,
    '857_504': 33.4682,
    '857_505': 33.5452,
    '857_506': 33.6327,
    '857_507': 33.7165,
    '857_508': 33.7586,
    '857_509': 33.7795,
    '857_510': 33.8174,
    '857_511': 33.8633,
    '857_512': 33.9065,
    '857_513': 33.959,
    '857_514': 34.0174,
    '857_515': 34.0789,
    '857_516': 34.1434,
    '857_517': 34.2115,
    '857_518': 34.2753,
    '857_519': 34.3473,
    '857_520': 34.4295,
    '857_521': 34.5038,
    '857_522': 34.5656,
    '857_523': 34.6411,
    '857_524': 34.7185,
    '857_525': 34.7769,
    '857_526': 34.8305,
    '857_527': 34.8892,
    '857_528': 34.9479,
    '857_529': 35.004,
    '857_530': 35.0537,
    '857_531': 35.1074,
    '857_532': 35.1611,
    '857_533': 35.2142,
    '857_534': 35.2665,
    '857_535': 35.3184,
    '857_536': 35.3705,
    '857_537': 35.4228,
    '857_538': 35.4755,
    '857_539': 35.5293,
    '857_540': 35.5846,
    '857_541': 35.6425,
    '857_542': 35.7032,
    '857_543': 35.767,
    '857_544': 35.8316,
    '857_545': 35.8965,
    '857_546': 35.9591,
    '857_547': 36.0217,
    '857_548': 36.0839,
    '857_549': 36.1458,
    '857_550': 36.2074,
    '857_551': 36.2697,
    '857_552': 36.3317,
    '857_553': 36.3939,
    '857_554': 36.4559,
    '857_555': 36.5217,
    '857_556': 36.5822,
    '857_557': 36.622,
    '857_558': 36.6667,
    '857_559': 36.7024,
    '857_560': 36.726,
    '857_561': 36.7452,
    '857_562': 36.766,
    '857_563': 36.7957,
    '857_564': 36.8273,
    '857_565': 36.8552,
    '857_566': 36.882,
    '857_567': 36.8986,
    '857_568': 36.9098,
    '857_569': 36.9175,
    '857_570': 36.9182,
    '857_571': 36.9083,
    '857_572': 36.8909,
    '857_573': 36.8709,
    '857_574': 36.8509,
    '857_575': 36.831,
    '857_576': 36.8103,
    '857_577': 36.7896,
    '857_578': 36.7695,
    '857_579': 36.751,
    '857_580': 36.7339,
    '857_581': 36.7183,
    '857_582': 36.7049,
    '857_583': 36.6943,
    '857_584': 36.6873,
    '857_585': 36.6848,
    '857_586': 36.6944,
    '857_587': 36.7157,
    '857_588': 36.7407,
    '857_589': 36.7604,
    '857_590': 36.7727,
    '857_591': 36.8053,
    '857_592': 36.8545,
    '857_593': 36.9066,
    '857_594': 36.9448,
    '857_595': 36.9673,
    '857_596': 36.9787,
    '857_597': 36.9759,
    '857_598': 36.9662,
    '857_599': 36.9849,
    '857_600': 37.0255,
    '857_601': 37.0804,
    '857_602': 37.1493,
    '857_603': 37.235,
    '857_604': 37.3347,
    '857_605': 37.4305,
    '857_606': 37.5305,
    '857_607': 37.6429,
    '857_608': 37.7586,
    '857_609': 37.8716,
    '857_610': 37.9874,
    '857_611': 38.1025,
    '857_612': 38.2271,
    '857_613': 38.3581,
    '857_614': 38.4923,
    '857_615': 38.6319,
    '857_616': 38.7733,
    '857_617': 38.9162,
    '857_618': 39.0562,
    '857_619': 39.1843,
    '857_620': 39.317,
    '857_621': 39.4388,
    '857_622': 39.5396,
    '857_623': 39.6427,
    '857_624': 39.7676,
    '857_625': 39.8862,
    '857_626': 39.9967,
    '857_627': 40.1029,
    '857_628': 40.2011,
    '857_629': 40.2832,
    '857_630': 40.3765,
    '857_631': 40.4896,
    '857_632': 40.6022,
    '857_633': 40.7111,
    '857_634': 40.8198,
    '857_635': 40.9205,
    '857_636': 41.0065,
    '857_637': 41.0665,
    '857_638': 41.0883,
    '857_639': 41.0743,
    '857_640': 41.0409,
    '857_641': 41.0367,
    '857_642': 41.0307,
    '857_643': 41.009,
    '857_644': 40.9754,
    '857_645': 40.9191,
    '857_646': 40.8287,
    '857_647': 40.7078,
    '857_648': 40.5779,
    '857_649': 40.4584,
    '857_650': 40.3531,
    '857_651': 40.2482,
    '857_652': 40.1262,
    '857_653': 39.9975,
    '857_654': 39.8597,
    '857_655': 39.7246,
    '857_656': 39.6045,
    '857_657': 39.4963,
    '857_658': 39.3823,
    '857_659': 39.2682,
    '857_660': 39.1565,
    '857_661': 39.0622,
    '857_662': 38.9772,
    '857_663': 38.8936,
    '857_664': 38.8232,
    '857_665': 38.7567,
    '857_666': 38.6834,
    '857_667': 38.6245,
    '857_668': 38.5786,
    '857_669': 38.5387,
    '857_670': 38.5039,
    '857_671': 38.4719,
    '857_672': 38.4408,
    '857_673': 38.4092,
    '857_674': 38.3784,
    '857_675': 38.3444,
    '857_676': 38.3056,
    '857_677': 38.2629,
    '857_678': 38.2203,
    '857_679': 38.1771,
    '857_680': 38.1351,
    '857_681': 38.0967,
    '857_682': 38.0629,
    '857_683': 38.0313,
    '857_684': 38.0025,
    '857_685': 37.9743,
    '857_686': 37.9448,
    '857_687': 37.9158,
    '857_688': 37.8911,
    '857_689': 37.8717,
    '857_690': 37.8533,
    '857_691': 37.8302,
    '857_692': 37.7959,
    '857_693': 37.763,
    '857_694': 37.7299,
    '857_695': 37.7128,
    '857_696': 37.7037,
    '857_697': 37.6958,
    '857_698': 37.6873,
    '857_699': 37.6784,
    '857_700': 37.6676,
    '857_701': 37.6581,
    '857_702': 37.6705,
    '857_703': 37.6869,
    '857_704': 37.7127,
    '858_364': 29.7389,
    '858_365': 29.8472,
    '858_366': 29.9674,
    '858_367': 30.0975,
    '858_368': 30.2327,
    '858_369': 30.351,
    '858_370': 30.4499,
    '858_371': 30.5356,
    '858_372': 30.6031,
    '858_373': 30.663,
    '858_374': 30.7159,
    '858_375': 30.7591,
    '858_376': 30.79,
    '858_377': 30.8063,
    '858_378': 30.8063,
    '858_379': 30.7923,
    '858_380': 30.7728,
    '858_381': 30.7504,
    '858_382': 30.7277,
    '858_420': 31.226,
    '858_421': 31.2835,
    '858_422': 31.3424,
    '858_423': 31.3995,
    '858_424': 31.4621,
    '858_425': 31.5303,
    '858_426': 31.6023,
    '858_427': 31.6731,
    '858_428': 31.7393,
    '858_429': 31.8025,
    '858_430': 31.8716,
    '858_431': 31.9452,
    '858_432': 32.0163,
    '858_433': 32.0832,
    '858_434': 32.1434,
    '858_435': 32.2059,
    '858_436': 32.2705,
    '858_437': 32.3344,
    '858_438': 32.3881,
    '858_439': 32.44,
    '858_440': 32.5029,
    '858_441': 32.5583,
    '858_442': 32.5889,
    '858_443': 32.6001,
    '858_444': 32.6023,
    '858_445': 32.6131,
    '858_446': 32.6333,
    '858_447': 32.6502,
    '858_448': 32.6536,
    '858_449': 32.6699,
    '858_450': 32.6822,
    '858_451': 32.6901,
    '858_452': 32.7065,
    '858_453': 32.7094,
    '858_454': 32.7182,
    '858_455': 32.7233,
    '858_456': 32.735,
    '858_457': 32.7453,
    '858_458': 32.7529,
    '858_459': 32.757,
    '858_460': 32.7673,
    '858_461': 32.7914,
    '858_462': 32.8105,
    '858_463': 32.8291,
    '858_464': 32.8608,
    '858_465': 32.8923,
    '858_466': 32.9236,
    '858_467': 32.9605,
    '858_468': 33.0081,
    '858_469': 33.0691,
    '858_470': 33.1237,
    '858_471': 33.1596,
    '858_472': 33.1919,
    '858_473': 33.2165,
    '858_474': 33.2291,
    '858_475': 33.2234,
    '858_476': 33.2039,
    '858_477': 33.1632,
    '858_478': 33.1083,
    '858_479': 33.0663,
    '858_480': 33.0477,
    '858_481': 33.0395,
    '858_482': 33.0273,
    '858_483': 33.0173,
    '858_484': 32.9891,
    '858_485': 32.9495,
    '858_486': 32.9294,
    '858_487': 32.9131,
    '858_488': 32.9001,
    '858_489': 32.8779,
    '858_490': 32.8516,
    '858_491': 32.8082,
    '858_492': 32.7892,
    '858_493': 32.7908,
    '858_494': 32.7939,
    '858_495': 32.8111,
    '858_496': 32.8437,
    '858_497': 32.8899,
    '858_498': 32.9468,
    '858_499': 33.016,
    '858_500': 33.1038,
    '858_501': 33.2166,
    '858_502': 33.3205,
    '858_503': 33.4164,
    '858_504': 33.5061,
    '858_505': 33.5794,
    '858_506': 33.6564,
    '858_507': 33.7357,
    '858_508': 33.7841,
    '858_509': 33.8262,
    '858_510': 33.8731,
    '858_511': 33.9234,
    '858_512': 33.9554,
    '858_513': 34.0022,
    '858_514': 34.0592,
    '858_515': 34.1146,
    '858_516': 34.1718,
    '858_517': 34.2332,
    '858_518': 34.296,
    '858_519': 34.3635,
    '858_520': 34.4359,
    '858_521': 34.5052,
    '858_522': 34.5708,
    '858_523': 34.6413,
    '858_524': 34.7096,
    '858_525': 34.772,
    '858_526': 34.8282,
    '858_527': 34.8896,
    '858_528': 34.9439,
    '858_529': 34.9902,
    '858_530': 35.0401,
    '858_531': 35.0928,
    '858_532': 35.146,
    '858_533': 35.1988,
    '858_534': 35.251,
    '858_535': 35.3026,
    '858_536': 35.3542,
    '858_537': 35.4057,
    '858_538': 35.4576,
    '858_539': 35.5103,
    '858_540': 35.5639,
    '858_541': 35.6198,
    '858_542': 35.678,
    '858_543': 35.74,
    '858_544': 35.804,
    '858_545': 35.868,
    '858_546': 35.9319,
    '858_547': 35.9961,
    '858_548': 36.0566,
    '858_549': 36.1175,
    '858_550': 36.1782,
    '858_551': 36.2391,
    '858_552': 36.2995,
    '858_553': 36.363,
    '858_554': 36.4247,
    '858_555': 36.4939,
    '858_556': 36.555,
    '858_557': 36.6015,
    '858_558': 36.6438,
    '858_559': 36.6772,
    '858_560': 36.7004,
    '858_561': 36.7212,
    '858_562': 36.7419,
    '858_563': 36.7668,
    '858_564': 36.7994,
    '858_565': 36.8326,
    '858_566': 36.8605,
    '858_567': 36.8763,
    '858_568': 36.8876,
    '858_569': 36.8949,
    '858_570': 36.897,
    '858_571': 36.8874,
    '858_572': 36.8743,
    '858_573': 36.8588,
    '858_574': 36.8419,
    '858_575': 36.8242,
    '858_576': 36.8053,
    '858_577': 36.7863,
    '858_578': 36.7675,
    '858_579': 36.75,
    '858_580': 36.7337,
    '858_581': 36.719,
    '858_582': 36.7064,
    '858_583': 36.6967,
    '858_584': 36.6904,
    '858_585': 36.6882,
    '858_586': 36.6977,
    '858_587': 36.7208,
    '858_588': 36.7438,
    '858_589': 36.7617,
    '858_590': 36.77,
    '858_591': 36.7991,
    '858_592': 36.8429,
    '858_593': 36.884,
    '858_594': 36.9176,
    '858_595': 36.941,
    '858_596': 36.9508,
    '858_597': 36.9443,
    '858_598': 36.9421,
    '858_599': 36.9611,
    '858_600': 36.9974,
    '858_601': 37.0484,
    '858_602': 37.1147,
    '858_603': 37.1984,
    '858_604': 37.2963,
    '858_605': 37.389,
    '858_606': 37.4871,
    '858_607': 37.603,
    '858_608': 37.722,
    '858_609': 37.838,
    '858_610': 37.9519,
    '858_611': 38.0652,
    '858_612': 38.1933,
    '858_613': 38.3289,
    '858_614': 38.4654,
    '858_615': 38.5996,
    '858_616': 38.735,
    '858_617': 38.8681,
    '858_618': 38.9958,
    '858_619': 39.1099,
    '858_620': 39.2211,
    '858_621': 39.3305,
    '858_622': 39.4331,
    '858_623': 39.5353,
    '858_624': 39.651,
    '858_625': 39.767,
    '858_626': 39.8713,
    '858_627': 39.9729,
    '858_628': 40.0714,
    '858_629': 40.1632,
    '858_630': 40.2697,
    '858_631': 40.3771,
    '858_632': 40.4884,
    '858_633': 40.598,
    '858_634': 40.7077,
    '858_635': 40.8117,
    '858_636': 40.9021,
    '858_637': 40.9639,
    '858_638': 40.9893,
    '858_639': 40.9887,
    '858_640': 40.9782,
    '858_641': 40.9948,
    '858_642': 41.0035,
    '858_643': 40.9941,
    '858_644': 40.9641,
    '858_645': 40.9141,
    '858_646': 40.8303,
    '858_647': 40.7216,
    '858_648': 40.6015,
    '858_649': 40.4743,
    '858_650': 40.3685,
    '858_651': 40.2701,
    '858_652': 40.1575,
    '858_653': 40.0384,
    '858_654': 39.9018,
    '858_655': 39.7632,
    '858_656': 39.6352,
    '858_657': 39.5326,
    '858_658': 39.437,
    '858_659': 39.3282,
    '858_660': 39.2229,
    '858_661': 39.1351,
    '858_662': 39.0539,
    '858_663': 38.962,
    '858_664': 38.8796,
    '858_665': 38.8071,
    '858_666': 38.7336,
    '858_667': 38.6735,
    '858_668': 38.6286,
    '858_669': 38.5882,
    '858_670': 38.5534,
    '858_671': 38.5191,
    '858_672': 38.4878,
    '858_673': 38.4562,
    '858_674': 38.4263,
    '858_675': 38.3949,
    '858_676': 38.3576,
    '858_677': 38.3195,
    '858_678': 38.2815,
    '858_679': 38.2442,
    '858_680': 38.2089,
    '858_681': 38.1768,
    '858_682': 38.1485,
    '858_683': 38.1195,
    '858_684': 38.0927,
    '858_685': 38.0661,
    '858_686': 38.0388,
    '858_687': 38.011,
    '858_688': 37.9855,
    '858_689': 37.9629,
    '858_690': 37.9412,
    '858_691': 37.916,
    '858_692': 37.8779,
    '858_693': 37.839,
    '858_694': 37.8045,
    '858_695': 37.7837,
    '858_696': 37.7726,
    '858_697': 37.7637,
    '858_698': 37.7527,
    '858_699': 37.7412,
    '858_700': 37.7281,
    '858_701': 37.715,
    '858_702': 37.7222,
    '858_703': 37.7329,
    '858_704': 37.7543,
    '858_767': 40.5628,
    '858_768': 40.6115,
    '858_769': 40.6372,
    '858_770': 40.387,
    '858_771': 40.3454,
    '858_772': 40.3081,
    '858_773': 40.2663,
    '859_364': 29.7039,
    '859_365': 29.8015,
    '859_366': 29.9186,
    '859_367': 30.0486,
    '859_368': 30.1831,
    '859_369': 30.3014,
    '859_370': 30.4003,
    '859_371': 30.4928,
    '859_372': 30.5706,
    '859_373': 30.6397,
    '859_374': 30.7004,
    '859_375': 30.7484,
    '859_376': 30.7854,
    '859_377': 30.8106,
    '859_378': 30.8176,
    '859_379': 30.8076,
    '859_380': 30.7943,
    '859_381': 30.7767,
    '859_382': 30.7536,
    '859_420': 31.1974,
    '859_421': 31.2502,
    '859_422': 31.3061,
    '859_423': 31.3676,
    '859_424': 31.4339,
    '859_425': 31.504,
    '859_426': 31.5748,
    '859_427': 31.6429,
    '859_428': 31.7065,
    '859_429': 31.7698,
    '859_430': 31.8394,
    '859_431': 31.9127,
    '859_432': 31.9855,
    '859_433': 32.055,
    '859_434': 32.1187,
    '859_435': 32.1828,
    '859_436': 32.2478,
    '859_437': 32.3097,
    '859_438': 32.3663,
    '859_439': 32.4143,
    '859_440': 32.4733,
    '859_441': 32.5271,
    '859_442': 32.5564,
    '859_443': 32.5681,
    '859_444': 32.5824,
    '859_445': 32.594,
    '859_446': 32.6038,
    '859_447': 32.6103,
    '859_448': 32.6208,
    '859_449': 32.6408,
    '859_450': 32.6639,
    '859_451': 32.6724,
    '859_452': 32.6865,
    '859_453': 32.6924,
    '859_454': 32.6973,
    '859_455': 32.7001,
    '859_456': 32.706,
    '859_457': 32.7252,
    '859_458': 32.7417,
    '859_459': 32.7494,
    '859_460': 32.7625,
    '859_461': 32.7923,
    '859_462': 32.8132,
    '859_463': 32.8362,
    '859_464': 32.8682,
    '859_465': 32.9113,
    '859_466': 32.9514,
    '859_467': 32.9867,
    '859_468': 33.0308,
    '859_469': 33.0914,
    '859_470': 33.1483,
    '859_471': 33.1885,
    '859_472': 33.2173,
    '859_473': 33.2377,
    '859_474': 33.2448,
    '859_475': 33.2381,
    '859_476': 33.2179,
    '859_477': 33.184,
    '859_478': 33.1422,
    '859_479': 33.1164,
    '859_480': 33.095,
    '859_481': 33.0955,
    '859_482': 33.0893,
    '859_483': 33.0808,
    '859_484': 33.0541,
    '859_485': 33.0156,
    '859_486': 32.9949,
    '859_487': 32.9728,
    '859_488': 32.9569,
    '859_489': 32.9429,
    '859_490': 32.9265,
    '859_491': 32.8858,
    '859_492': 32.8462,
    '859_493': 32.8319,
    '859_494': 32.8357,
    '859_495': 32.8539,
    '859_496': 32.8839,
    '859_497': 32.9278,
    '859_498': 32.9847,
    '859_499': 33.0534,
    '859_500': 33.1466,
    '859_501': 33.2649,
    '859_502': 33.3689,
    '859_503': 33.4582,
    '859_504': 33.5483,
    '859_505': 33.6195,
    '859_506': 33.6845,
    '859_507': 33.7516,
    '859_508': 33.8119,
    '859_509': 33.8739,
    '859_510': 33.9301,
    '859_511': 33.9757,
    '859_512': 34.0102,
    '859_513': 34.0548,
    '859_514': 34.1107,
    '859_515': 34.1599,
    '859_516': 34.2086,
    '859_517': 34.2638,
    '859_518': 34.3234,
    '859_519': 34.3871,
    '859_520': 34.453,
    '859_521': 34.5189,
    '859_522': 34.5834,
    '859_523': 34.6508,
    '859_524': 34.7072,
    '859_525': 34.7666,
    '859_526': 34.8251,
    '859_527': 34.8869,
    '859_528': 34.939,
    '859_529': 34.9797,
    '859_530': 35.0294,
    '859_531': 35.0818,
    '859_532': 35.1348,
    '859_533': 35.1875,
    '859_534': 35.2393,
    '859_535': 35.2904,
    '859_536': 35.3412,
    '859_537': 35.3921,
    '859_538': 35.4431,
    '859_539': 35.4946,
    '859_540': 35.5471,
    '859_541': 35.602,
    '859_542': 35.6589,
    '859_543': 35.7185,
    '859_544': 35.7804,
    '859_545': 35.8433,
    '859_546': 35.9081,
    '859_547': 35.9744,
    '859_548': 36.0319,
    '859_549': 36.0916,
    '859_550': 36.1512,
    '859_551': 36.2102,
    '859_552': 36.2692,
    '859_553': 36.3299,
    '859_554': 36.3912,
    '859_555': 36.4569,
    '859_556': 36.5213,
    '859_557': 36.5896,
    '859_558': 36.6409,
    '859_559': 36.6633,
    '859_560': 36.6812,
    '859_561': 36.7038,
    '859_562': 36.7201,
    '859_563': 36.7428,
    '859_564': 36.7749,
    '859_565': 36.8136,
    '859_566': 36.8376,
    '859_567': 36.8508,
    '859_568': 36.8687,
    '859_569': 36.8801,
    '859_570': 36.8892,
    '859_571': 36.8752,
    '859_572': 36.8641,
    '859_573': 36.8514,
    '859_574': 36.837,
    '859_575': 36.8211,
    '859_576': 36.8039,
    '859_577': 36.7862,
    '859_578': 36.7686,
    '859_579': 36.7519,
    '859_580': 36.7364,
    '859_581': 36.7224,
    '859_582': 36.7105,
    '859_583': 36.7015,
    '859_584': 36.6975,
    '859_585': 36.696,
    '859_586': 36.7029,
    '859_587': 36.7202,
    '859_588': 36.7411,
    '859_589': 36.7594,
    '859_590': 36.7712,
    '859_591': 36.8014,
    '859_592': 36.843,
    '859_593': 36.8737,
    '859_594': 36.893,
    '859_595': 36.9111,
    '859_596': 36.9187,
    '859_597': 36.912,
    '859_598': 36.9149,
    '859_599': 36.9342,
    '859_600': 36.9667,
    '859_601': 37.013,
    '859_602': 37.074,
    '859_603': 37.1506,
    '859_604': 37.2402,
    '859_605': 37.3313,
    '859_606': 37.4295,
    '859_607': 37.5434,
    '859_608': 37.6616,
    '859_609': 37.779,
    '859_610': 37.8944,
    '859_611': 38.0129,
    '859_612': 38.142,
    '859_613': 38.2834,
    '859_614': 38.4256,
    '859_615': 38.5636,
    '859_616': 38.703,
    '859_617': 38.837,
    '859_618': 38.9627,
    '859_619': 39.0702,
    '859_620': 39.1735,
    '859_621': 39.2711,
    '859_622': 39.3629,
    '859_623': 39.4554,
    '859_624': 39.5532,
    '859_625': 39.6567,
    '859_626': 39.7554,
    '859_627': 39.8513,
    '859_628': 39.9486,
    '859_629': 40.0508,
    '859_630': 40.1631,
    '859_631': 40.2722,
    '859_632': 40.3803,
    '859_633': 40.4878,
    '859_634': 40.5922,
    '859_635': 40.6969,
    '859_636': 40.7879,
    '859_637': 40.8504,
    '859_638': 40.8852,
    '859_639': 40.9014,
    '859_640': 40.9253,
    '859_641': 40.9566,
    '859_642': 40.9739,
    '859_643': 40.9683,
    '859_644': 40.9485,
    '859_645': 40.8971,
    '859_646': 40.8215,
    '859_647': 40.7269,
    '859_648': 40.6171,
    '859_649': 40.4887,
    '859_650': 40.3706,
    '859_651': 40.2659,
    '859_652': 40.1627,
    '859_653': 40.0553,
    '859_654': 39.9253,
    '859_655': 39.7906,
    '859_656': 39.6629,
    '859_657': 39.5652,
    '859_658': 39.4836,
    '859_659': 39.3888,
    '859_660': 39.2916,
    '859_661': 39.2065,
    '859_662': 39.1255,
    '859_663': 39.0273,
    '859_664': 38.9354,
    '859_665': 38.8535,
    '859_666': 38.7784,
    '859_667': 38.7158,
    '859_668': 38.6697,
    '859_669': 38.6333,
    '859_670': 38.5985,
    '859_671': 38.5644,
    '859_672': 38.5321,
    '859_673': 38.4995,
    '859_674': 38.4689,
    '859_675': 38.4373,
    '859_676': 38.4028,
    '859_677': 38.369,
    '859_678': 38.3355,
    '859_679': 38.303,
    '859_680': 38.2724,
    '859_681': 38.2441,
    '859_682': 38.2184,
    '859_683': 38.1952,
    '859_684': 38.1738,
    '859_685': 38.1528,
    '859_686': 38.1256,
    '859_687': 38.0978,
    '859_688': 38.0707,
    '859_689': 38.046,
    '859_690': 38.021,
    '859_691': 37.9933,
    '859_692': 37.9556,
    '859_693': 37.9158,
    '859_694': 37.8797,
    '859_695': 37.8587,
    '859_696': 37.8474,
    '859_697': 37.8387,
    '859_698': 37.8276,
    '859_699': 37.8065,
    '859_700': 37.783,
    '859_701': 37.7649,
    '859_702': 37.7745,
    '859_703': 37.7929,
    '859_704': 37.815,
    '859_767': 40.5357,
    '859_768': 40.5922,
    '859_769': 40.6249,
    '859_770': 40.3806,
    '859_771': 40.3408,
    '859_772': 40.2929,
    '859_773': 40.2429,
    '860_364': 29.67,
    '860_365': 29.7599,
    '860_366': 29.8718,
    '860_367': 29.9986,
    '860_368': 30.1314,
    '860_369': 30.2485,
    '860_370': 30.3551,
    '860_371': 30.4534,
    '860_372': 30.5402,
    '860_373': 30.6163,
    '860_374': 30.6829,
    '860_375': 30.7381,
    '860_376': 30.7807,
    '860_377': 30.8148,
    '860_378': 30.8325,
    '860_379': 30.824,
    '860_380': 30.8137,
    '860_381': 30.7974,
    '860_382': 30.775,
    '860_420': 31.1714,
    '860_421': 31.2221,
    '860_422': 31.2786,
    '860_423': 31.341,
    '860_424': 31.4067,
    '860_425': 31.4771,
    '860_426': 31.5471,
    '860_427': 31.6129,
    '860_428': 31.676,
    '860_429': 31.7398,
    '860_430': 31.8089,
    '860_431': 31.8825,
    '860_432': 31.9565,
    '860_433': 32.0261,
    '860_434': 32.0939,
    '860_435': 32.1587,
    '860_436': 32.222,
    '860_437': 32.2805,
    '860_438': 32.3367,
    '860_439': 32.3802,
    '860_440': 32.43,
    '860_441': 32.4766,
    '860_442': 32.5024,
    '860_443': 32.5223,
    '860_444': 32.545,
    '860_445': 32.5595,
    '860_446': 32.5706,
    '860_447': 32.5676,
    '860_448': 32.5864,
    '860_449': 32.6072,
    '860_450': 32.6247,
    '860_451': 32.6368,
    '860_452': 32.6483,
    '860_453': 32.6565,
    '860_454': 32.6699,
    '860_455': 32.674,
    '860_456': 32.676,
    '860_457': 32.694,
    '860_458': 32.717,
    '860_459': 32.7339,
    '860_460': 32.7549,
    '860_461': 32.792,
    '860_462': 32.8142,
    '860_463': 32.8434,
    '860_464': 32.8814,
    '860_465': 32.9205,
    '860_466': 32.9634,
    '860_467': 33.0018,
    '860_468': 33.05,
    '860_469': 33.1046,
    '860_470': 33.1657,
    '860_471': 33.2098,
    '860_472': 33.2335,
    '860_473': 33.2437,
    '860_474': 33.2437,
    '860_475': 33.2348,
    '860_476': 33.2185,
    '860_477': 33.1895,
    '860_478': 33.1663,
    '860_479': 33.1592,
    '860_480': 33.1409,
    '860_481': 33.1501,
    '860_482': 33.1552,
    '860_483': 33.1497,
    '860_484': 33.1227,
    '860_485': 33.0791,
    '860_486': 33.0539,
    '860_487': 33.0398,
    '860_488': 33.0238,
    '860_489': 33.0133,
    '860_490': 33.0038,
    '860_491': 32.9696,
    '860_492': 32.9168,
    '860_493': 32.8888,
    '860_494': 32.8866,
    '860_495': 32.9008,
    '860_496': 32.928,
    '860_497': 32.9692,
    '860_498': 33.0235,
    '860_499': 33.0923,
    '860_500': 33.1801,
    '860_501': 33.2906,
    '860_502': 33.4016,
    '860_503': 33.4979,
    '860_504': 33.5874,
    '860_505': 33.6626,
    '860_506': 33.7252,
    '860_507': 33.7842,
    '860_508': 33.8435,
    '860_509': 33.9128,
    '860_510': 33.9826,
    '860_511': 34.0325,
    '860_512': 34.0727,
    '860_513': 34.1196,
    '860_514': 34.1775,
    '860_515': 34.2236,
    '860_516': 34.2571,
    '860_517': 34.3064,
    '860_518': 34.3606,
    '860_519': 34.4233,
    '860_520': 34.4841,
    '860_521': 34.5482,
    '860_522': 34.6049,
    '860_523': 34.6623,
    '860_524': 34.7158,
    '860_525': 34.7716,
    '860_526': 34.8261,
    '860_527': 34.88,
    '860_528': 34.9286,
    '860_529': 34.9733,
    '860_530': 35.0231,
    '860_531': 35.0772,
    '860_532': 35.1296,
    '860_533': 35.1819,
    '860_534': 35.2327,
    '860_535': 35.2827,
    '860_536': 35.3324,
    '860_537': 35.3825,
    '860_538': 35.4328,
    '860_539': 35.4822,
    '860_540': 35.5345,
    '860_541': 35.5882,
    '860_542': 35.6438,
    '860_543': 35.7014,
    '860_544': 35.7616,
    '860_545': 35.8245,
    '860_546': 35.8866,
    '860_547': 35.9506,
    '860_548': 36.0106,
    '860_549': 36.0692,
    '860_550': 36.1267,
    '860_551': 36.1838,
    '860_552': 36.2413,
    '860_553': 36.2993,
    '860_554': 36.3598,
    '860_555': 36.424,
    '860_556': 36.4919,
    '860_557': 36.5676,
    '860_558': 36.6265,
    '860_559': 36.6481,
    '860_560': 36.6579,
    '860_561': 36.6829,
    '860_562': 36.6992,
    '860_563': 36.7217,
    '860_564': 36.753,
    '860_565': 36.7887,
    '860_566': 36.8128,
    '860_567': 36.8295,
    '860_568': 36.8499,
    '860_569': 36.8672,
    '860_570': 36.8758,
    '860_571': 36.8664,
    '860_572': 36.8578,
    '860_573': 36.8476,
    '860_574': 36.8353,
    '860_575': 36.8212,
    '860_576': 36.8052,
    '860_577': 36.7887,
    '860_578': 36.772,
    '860_579': 36.7561,
    '860_580': 36.7412,
    '860_581': 36.7278,
    '860_582': 36.7165,
    '860_583': 36.7081,
    '860_584': 36.7042,
    '860_585': 36.7033,
    '860_586': 36.7084,
    '860_587': 36.7214,
    '860_588': 36.7396,
    '860_589': 36.7587,
    '860_590': 36.7805,
    '860_591': 36.812,
    '860_592': 36.8526,
    '860_593': 36.8832,
    '860_594': 36.8899,
    '860_595': 36.897,
    '860_596': 36.8954,
    '860_597': 36.886,
    '860_598': 36.8888,
    '860_599': 36.9063,
    '860_600': 36.9357,
    '860_601': 36.9764,
    '860_602': 37.0312,
    '860_603': 37.1003,
    '860_604': 37.1821,
    '860_605': 37.272,
    '860_606': 37.3705,
    '860_607': 37.4759,
    '860_608': 37.5881,
    '860_609': 37.7034,
    '860_610': 37.8204,
    '860_611': 37.9433,
    '860_612': 38.0749,
    '860_613': 38.2184,
    '860_614': 38.362,
    '860_615': 38.5055,
    '860_616': 38.6563,
    '860_617': 38.7975,
    '860_618': 38.9246,
    '860_619': 39.0351,
    '860_620': 39.1427,
    '860_621': 39.2379,
    '860_622': 39.3239,
    '860_623': 39.4028,
    '860_624': 39.4828,
    '860_625': 39.5748,
    '860_626': 39.6659,
    '860_627': 39.7525,
    '860_628': 39.8441,
    '860_629': 39.9471,
    '860_630': 40.0588,
    '860_631': 40.1682,
    '860_632': 40.272,
    '860_633': 40.3754,
    '860_634': 40.4771,
    '860_635': 40.5801,
    '860_636': 40.6696,
    '860_637': 40.7338,
    '860_638': 40.7804,
    '860_639': 40.8275,
    '860_640': 40.8763,
    '860_641': 40.9146,
    '860_642': 40.9384,
    '860_643': 40.9421,
    '860_644': 40.9236,
    '860_645': 40.8761,
    '860_646': 40.8017,
    '860_647': 40.7146,
    '860_648': 40.6193,
    '860_649': 40.4991,
    '860_650': 40.3706,
    '860_651': 40.2503,
    '860_652': 40.1474,
    '860_653': 40.0471,
    '860_654': 39.9258,
    '860_655': 39.8033,
    '860_656': 39.6759,
    '860_657': 39.5819,
    '860_658': 39.507,
    '860_659': 39.4251,
    '860_660': 39.3404,
    '860_661': 39.2593,
    '860_662': 39.168,
    '860_663': 39.0666,
    '860_664': 38.9758,
    '860_665': 38.8948,
    '860_666': 38.8224,
    '860_667': 38.7596,
    '860_668': 38.7108,
    '860_669': 38.6721,
    '860_670': 38.6403,
    '860_671': 38.6061,
    '860_672': 38.5724,
    '860_673': 38.5376,
    '860_674': 38.5076,
    '860_675': 38.4757,
    '860_676': 38.4438,
    '860_677': 38.4135,
    '860_678': 38.3838,
    '860_679': 38.3553,
    '860_680': 38.3285,
    '860_681': 38.3034,
    '860_682': 38.2807,
    '860_683': 38.2606,
    '860_684': 38.2424,
    '860_685': 38.2242,
    '860_686': 38.2011,
    '860_687': 38.1767,
    '860_688': 38.1514,
    '860_689': 38.1245,
    '860_690': 38.0957,
    '860_691': 38.0651,
    '860_692': 38.0306,
    '860_693': 37.9926,
    '860_694': 37.9549,
    '860_695': 37.935,
    '860_696': 37.9243,
    '860_697': 37.9149,
    '860_698': 37.9007,
    '860_699': 37.8747,
    '860_700': 37.8453,
    '860_701': 37.8232,
    '860_702': 37.8246,
    '860_703': 37.8427,
    '860_704': 37.8683,
    '860_767': 40.5144,
    '860_768': 40.573,
    '860_769': 40.6122,
    '860_770': 40.3767,
    '860_771': 40.339,
    '860_772': 40.2793,
    '860_773': 40.2215,
    '861_364': 29.6376,
    '861_365': 29.7172,
    '861_366': 29.8206,
    '861_367': 29.9409,
    '861_368': 30.0699,
    '861_369': 30.1952,
    '861_370': 30.312,
    '861_371': 30.4187,
    '861_372': 30.5125,
    '861_373': 30.5946,
    '861_374': 30.667,
    '861_375': 30.7289,
    '861_376': 30.7725,
    '861_377': 30.8103,
    '861_378': 30.8336,
    '861_379': 30.8315,
    '861_380': 30.8273,
    '861_381': 30.8105,
    '861_382': 30.7862,
    '861_420': 31.1476,
    '861_421': 31.196,
    '861_422': 31.2533,
    '861_423': 31.3159,
    '861_424': 31.3782,
    '861_425': 31.4482,
    '861_426': 31.5164,
    '861_427': 31.5829,
    '861_428': 31.6472,
    '861_429': 31.7106,
    '861_430': 31.7806,
    '861_431': 31.8552,
    '861_432': 31.9306,
    '861_433': 32.0013,
    '861_434': 32.0699,
    '861_435': 32.1335,
    '861_436': 32.1915,
    '861_437': 32.2462,
    '861_438': 32.2954,
    '861_439': 32.3372,
    '861_440': 32.3839,
    '861_441': 32.4172,
    '861_442': 32.4414,
    '861_443': 32.4673,
    '861_444': 32.4893,
    '861_445': 32.5044,
    '861_446': 32.5186,
    '861_447': 32.5179,
    '861_448': 32.5422,
    '861_449': 32.559,
    '861_450': 32.5675,
    '861_451': 32.5809,
    '861_452': 32.5993,
    '861_453': 32.6198,
    '861_454': 32.6386,
    '861_455': 32.6437,
    '861_456': 32.6484,
    '861_457': 32.6591,
    '861_458': 32.6844,
    '861_459': 32.7113,
    '861_460': 32.7405,
    '861_461': 32.7839,
    '861_462': 32.8135,
    '861_463': 32.8487,
    '861_464': 32.8947,
    '861_465': 32.9336,
    '861_466': 32.9747,
    '861_467': 33.0154,
    '861_468': 33.0692,
    '861_469': 33.1178,
    '861_470': 33.1754,
    '861_471': 33.2195,
    '861_472': 33.2392,
    '861_473': 33.2446,
    '861_474': 33.2378,
    '861_475': 33.2223,
    '861_476': 33.2045,
    '861_477': 33.1901,
    '861_478': 33.1871,
    '861_479': 33.1949,
    '861_480': 33.1872,
    '861_481': 33.1973,
    '861_482': 33.2142,
    '861_483': 33.2175,
    '861_484': 33.1961,
    '861_485': 33.1524,
    '861_486': 33.117,
    '861_487': 33.1051,
    '861_488': 33.0929,
    '861_489': 33.0809,
    '861_490': 33.0737,
    '861_491': 33.0441,
    '861_492': 32.9942,
    '861_493': 32.9585,
    '861_494': 32.9453,
    '861_495': 32.9537,
    '861_496': 32.978,
    '861_497': 33.0158,
    '861_498': 33.0668,
    '861_499': 33.1334,
    '861_500': 33.2154,
    '861_501': 33.313,
    '861_502': 33.4267,
    '861_503': 33.5384,
    '861_504': 33.6263,
    '861_505': 33.7076,
    '861_506': 33.7793,
    '861_507': 33.8299,
    '861_508': 33.8835,
    '861_509': 33.9498,
    '861_510': 34.0246,
    '861_511': 34.0857,
    '861_512': 34.1357,
    '861_513': 34.1884,
    '861_514': 34.2454,
    '861_515': 34.2864,
    '861_516': 34.315,
    '861_517': 34.3613,
    '861_518': 34.4117,
    '861_519': 34.4705,
    '861_520': 34.5272,
    '861_521': 34.5827,
    '861_522': 34.6405,
    '861_523': 34.6882,
    '861_524': 34.729,
    '861_525': 34.779,
    '861_526': 34.8286,
    '861_527': 34.8776,
    '861_528': 34.9246,
    '861_529': 34.9716,
    '861_530': 35.0214,
    '861_531': 35.0768,
    '861_532': 35.1345,
    '861_533': 35.1854,
    '861_534': 35.2326,
    '861_535': 35.2806,
    '861_536': 35.3281,
    '861_537': 35.376,
    '861_538': 35.4246,
    '861_539': 35.473,
    '861_540': 35.524,
    '861_541': 35.5787,
    '861_542': 35.6342,
    '861_543': 35.6893,
    '861_544': 35.7465,
    '861_545': 35.8069,
    '861_546': 35.8682,
    '861_547': 35.9317,
    '861_548': 35.9955,
    '861_549': 36.0512,
    '861_550': 36.1063,
    '861_551': 36.162,
    '861_552': 36.2168,
    '861_553': 36.273,
    '861_554': 36.3336,
    '861_555': 36.397,
    '861_556': 36.4678,
    '861_557': 36.5433,
    '861_558': 36.5935,
    '861_559': 36.6122,
    '861_560': 36.6289,
    '861_561': 36.6568,
    '861_562': 36.6787,
    '861_563': 36.7057,
    '861_564': 36.7413,
    '861_565': 36.7708,
    '861_566': 36.801,
    '861_567': 36.8149,
    '861_568': 36.8348,
    '861_569': 36.8521,
    '861_570': 36.8599,
    '861_571': 36.8589,
    '861_572': 36.8544,
    '861_573': 36.8469,
    '861_574': 36.8365,
    '861_575': 36.8238,
    '861_576': 36.809,
    '861_577': 36.7932,
    '861_578': 36.7772,
    '861_579': 36.7619,
    '861_580': 36.7476,
    '861_581': 36.7347,
    '861_582': 36.7239,
    '861_583': 36.7159,
    '861_584': 36.712,
    '861_585': 36.7109,
    '861_586': 36.7151,
    '861_587': 36.725,
    '861_588': 36.7444,
    '861_589': 36.7677,
    '861_590': 36.7972,
    '861_591': 36.8322,
    '861_592': 36.8698,
    '861_593': 36.9061,
    '861_594': 36.9101,
    '861_595': 36.901,
    '861_596': 36.881,
    '861_597': 36.8628,
    '861_598': 36.8646,
    '861_599': 36.882,
    '861_600': 36.9088,
    '861_601': 36.9435,
    '861_602': 36.9923,
    '861_603': 37.0555,
    '861_604': 37.132,
    '861_605': 37.2182,
    '861_606': 37.3133,
    '861_607': 37.4142,
    '861_608': 37.5193,
    '861_609': 37.6297,
    '861_610': 37.7453,
    '861_611': 37.8675,
    '861_612': 37.9977,
    '861_613': 38.1361,
    '861_614': 38.2786,
    '861_615': 38.4254,
    '861_616': 38.5846,
    '861_617': 38.7316,
    '861_618': 38.8682,
    '861_619': 38.986,
    '861_620': 39.0994,
    '861_621': 39.2012,
    '861_622': 39.294,
    '861_623': 39.3693,
    '861_624': 39.4353,
    '861_625': 39.5175,
    '861_626': 39.6047,
    '861_627': 39.6836,
    '861_628': 39.7634,
    '861_629': 39.8579,
    '861_630': 39.9593,
    '861_631': 40.0636,
    '861_632': 40.1622,
    '861_633': 40.262,
    '861_634': 40.3628,
    '861_635': 40.4608,
    '861_636': 40.5456,
    '861_637': 40.6139,
    '861_638': 40.6804,
    '861_639': 40.7502,
    '861_640': 40.8105,
    '861_641': 40.8563,
    '861_642': 40.8885,
    '861_643': 40.9045,
    '861_644': 40.8924,
    '861_645': 40.846,
    '861_646': 40.7669,
    '861_647': 40.685,
    '861_648': 40.5979,
    '861_649': 40.4951,
    '861_650': 40.3792,
    '861_651': 40.2506,
    '861_652': 40.1264,
    '861_653': 40.0212,
    '861_654': 39.907,
    '861_655': 39.799,
    '861_656': 39.679,
    '861_657': 39.5891,
    '861_658': 39.5217,
    '861_659': 39.4388,
    '861_660': 39.3611,
    '861_661': 39.2871,
    '861_662': 39.1937,
    '861_663': 39.0967,
    '861_664': 39.0066,
    '861_665': 38.9349,
    '861_666': 38.8643,
    '861_667': 38.8089,
    '861_668': 38.7557,
    '861_669': 38.7145,
    '861_670': 38.6795,
    '861_671': 38.6408,
    '861_672': 38.6053,
    '861_673': 38.5701,
    '861_674': 38.5412,
    '861_675': 38.5109,
    '861_676': 38.4816,
    '861_677': 38.4538,
    '861_678': 38.4272,
    '861_679': 38.402,
    '861_680': 38.3785,
    '861_681': 38.3565,
    '861_682': 38.336,
    '861_683': 38.3177,
    '861_684': 38.3002,
    '861_685': 38.2825,
    '861_686': 38.2662,
    '861_687': 38.248,
    '861_688': 38.2264,
    '861_689': 38.1989,
    '861_690': 38.1681,
    '861_691': 38.143,
    '861_692': 38.107,
    '861_693': 38.0676,
    '861_694': 38.0323,
    '861_695': 38.0136,
    '861_696': 38.0035,
    '861_697': 37.994,
    '861_698': 37.978,
    '861_699': 37.9486,
    '861_700': 37.9156,
    '861_701': 37.8904,
    '861_702': 37.887,
    '861_703': 37.8989,
    '861_704': 37.9192,
    '861_767': 40.4916,
    '861_768': 40.5471,
    '861_769': 40.5905,
    '861_770': 40.3673,
    '861_771': 40.3269,
    '861_772': 40.2592,
    '861_773': 40.1974,
    '862_364': 29.6037,
    '862_365': 29.6729,
    '862_366': 29.7664,
    '862_367': 29.8794,
    '862_368': 30.0063,
    '862_369': 30.1387,
    '862_370': 30.2645,
    '862_371': 30.3821,
    '862_372': 30.4845,
    '862_373': 30.5752,
    '862_374': 30.6501,
    '862_375': 30.7132,
    '862_376': 30.7588,
    '862_377': 30.7969,
    '862_378': 30.8206,
    '862_379': 30.8327,
    '862_380': 30.8323,
    '862_381': 30.8204,
    '862_382': 30.7977,
    '862_420': 31.1249,
    '862_421': 31.1715,
    '862_422': 31.2284,
    '862_423': 31.2901,
    '862_424': 31.3534,
    '862_425': 31.4158,
    '862_426': 31.4848,
    '862_427': 31.5542,
    '862_428': 31.6215,
    '862_429': 31.6846,
    '862_430': 31.7526,
    '862_431': 31.8269,
    '862_432': 31.9055,
    '862_433': 31.9783,
    '862_434': 32.045,
    '862_435': 32.1047,
    '862_436': 32.1576,
    '862_437': 32.2058,
    '862_438': 32.2488,
    '862_439': 32.2878,
    '862_440': 32.3237,
    '862_441': 32.3506,
    '862_442': 32.3777,
    '862_443': 32.403,
    '862_444': 32.4239,
    '862_445': 32.4435,
    '862_446': 32.4564,
    '862_447': 32.4667,
    '862_448': 32.4891,
    '862_449': 32.5006,
    '862_450': 32.5121,
    '862_451': 32.5313,
    '862_452': 32.5535,
    '862_453': 32.5795,
    '862_454': 32.5937,
    '862_455': 32.6025,
    '862_456': 32.617,
    '862_457': 32.6354,
    '862_458': 32.6592,
    '862_459': 32.7007,
    '862_460': 32.7312,
    '862_461': 32.7754,
    '862_462': 32.8125,
    '862_463': 32.854,
    '862_464': 32.9038,
    '862_465': 32.9536,
    '862_466': 32.9999,
    '862_467': 33.0295,
    '862_468': 33.076,
    '862_469': 33.1245,
    '862_470': 33.1797,
    '862_471': 33.2213,
    '862_472': 33.2378,
    '862_473': 33.2439,
    '862_474': 33.2414,
    '862_475': 33.2186,
    '862_476': 33.2033,
    '862_477': 33.2117,
    '862_478': 33.2259,
    '862_479': 33.2339,
    '862_480': 33.23,
    '862_481': 33.2359,
    '862_482': 33.2632,
    '862_483': 33.2789,
    '862_484': 33.266,
    '862_485': 33.23,
    '862_486': 33.1937,
    '862_487': 33.178,
    '862_488': 33.1699,
    '862_489': 33.1582,
    '862_490': 33.1523,
    '862_491': 33.1333,
    '862_492': 33.0866,
    '862_493': 33.0464,
    '862_494': 33.017,
    '862_495': 33.0181,
    '862_496': 33.0372,
    '862_497': 33.0698,
    '862_498': 33.1168,
    '862_499': 33.1806,
    '862_500': 33.2597,
    '862_501': 33.3491,
    '862_502': 33.4561,
    '862_503': 33.5754,
    '862_504': 33.6689,
    '862_505': 33.7431,
    '862_506': 33.819,
    '862_507': 33.8716,
    '862_508': 33.9222,
    '862_509': 33.9839,
    '862_510': 34.0578,
    '862_511': 34.1296,
    '862_512': 34.1899,
    '862_513': 34.2421,
    '862_514': 34.2881,
    '862_515': 34.3251,
    '862_516': 34.3653,
    '862_517': 34.4161,
    '862_518': 34.4583,
    '862_519': 34.5013,
    '862_520': 34.5534,
    '862_521': 34.6067,
    '862_522': 34.6662,
    '862_523': 34.7169,
    '862_524': 34.7474,
    '862_525': 34.7932,
    '862_526': 34.8384,
    '862_527': 34.885,
    '862_528': 34.9358,
    '862_529': 34.9776,
    '862_530': 35.025,
    '862_531': 35.0792,
    '862_532': 35.1345,
    '862_533': 35.1904,
    '862_534': 35.242,
    '862_535': 35.2873,
    '862_536': 35.3288,
    '862_537': 35.3731,
    '862_538': 35.4195,
    '862_539': 35.4679,
    '862_540': 35.5192,
    '862_541': 35.5742,
    '862_542': 35.6305,
    '862_543': 35.6808,
    '862_544': 35.7355,
    '862_545': 35.7935,
    '862_546': 35.8538,
    '862_547': 35.9168,
    '862_548': 35.9844,
    '862_549': 36.0375,
    '862_550': 36.0876,
    '862_551': 36.1431,
    '862_552': 36.1959,
    '862_553': 36.2512,
    '862_554': 36.3096,
    '862_555': 36.3716,
    '862_556': 36.4403,
    '862_557': 36.5022,
    '862_558': 36.5486,
    '862_559': 36.5721,
    '862_560': 36.5981,
    '862_561': 36.6283,
    '862_562': 36.6572,
    '862_563': 36.688,
    '862_564': 36.725,
    '862_565': 36.7552,
    '862_566': 36.7889,
    '862_567': 36.8039,
    '862_568': 36.8249,
    '862_569': 36.8409,
    '862_570': 36.8507,
    '862_571': 36.8548,
    '862_572': 36.8542,
    '862_573': 36.8494,
    '862_574': 36.8407,
    '862_575': 36.829,
    '862_576': 36.8149,
    '862_577': 36.7995,
    '862_578': 36.784,
    '862_579': 36.7691,
    '862_580': 36.7552,
    '862_581': 36.7428,
    '862_582': 36.7325,
    '862_583': 36.725,
    '862_584': 36.7211,
    '862_585': 36.7203,
    '862_586': 36.7247,
    '862_587': 36.7359,
    '862_588': 36.7575,
    '862_589': 36.7827,
    '862_590': 36.8149,
    '862_591': 36.8518,
    '862_592': 36.8899,
    '862_593': 36.9322,
    '862_594': 36.9349,
    '862_595': 36.9142,
    '862_596': 36.8847,
    '862_597': 36.8564,
    '862_598': 36.8525,
    '862_599': 36.8683,
    '862_600': 36.8935,
    '862_601': 36.9252,
    '862_602': 36.9684,
    '862_603': 37.0265,
    '862_604': 37.0977,
    '862_605': 37.179,
    '862_606': 37.268,
    '862_607': 37.3625,
    '862_608': 37.4606,
    '862_609': 37.5636,
    '862_610': 37.6739,
    '862_611': 37.7929,
    '862_612': 37.9182,
    '862_613': 38.052,
    '862_614': 38.1925,
    '862_615': 38.341,
    '862_616': 38.4938,
    '862_617': 38.6433,
    '862_618': 38.7844,
    '862_619': 38.9112,
    '862_620': 39.0306,
    '862_621': 39.1351,
    '862_622': 39.2367,
    '862_623': 39.3202,
    '862_624': 39.391,
    '862_625': 39.4801,
    '862_626': 39.5665,
    '862_627': 39.6373,
    '862_628': 39.7055,
    '862_629': 39.7864,
    '862_630': 39.8741,
    '862_631': 39.9689,
    '862_632': 40.0621,
    '862_633': 40.1598,
    '862_634': 40.2549,
    '862_635': 40.3459,
    '862_636': 40.4253,
    '862_637': 40.4991,
    '862_638': 40.58,
    '862_639': 40.6642,
    '862_640': 40.7313,
    '862_641': 40.789,
    '862_642': 40.8334,
    '862_643': 40.8572,
    '862_644': 40.848,
    '862_645': 40.8034,
    '862_646': 40.7277,
    '862_647': 40.6464,
    '862_648': 40.563,
    '862_649': 40.469,
    '862_650': 40.3684,
    '862_651': 40.249,
    '862_652': 40.1162,
    '862_653': 40.0029,
    '862_654': 39.8889,
    '862_655': 39.7841,
    '862_656': 39.68,
    '862_657': 39.5832,
    '862_658': 39.5121,
    '862_659': 39.4343,
    '862_660': 39.3717,
    '862_661': 39.3068,
    '862_662': 39.2167,
    '862_663': 39.1194,
    '862_664': 39.0376,
    '862_665': 38.9755,
    '862_666': 38.9094,
    '862_667': 38.8534,
    '862_668': 38.8021,
    '862_669': 38.7594,
    '862_670': 38.7223,
    '862_671': 38.6742,
    '862_672': 38.6351,
    '862_673': 38.6014,
    '862_674': 38.573,
    '862_675': 38.5437,
    '862_676': 38.5165,
    '862_677': 38.4898,
    '862_678': 38.4664,
    '862_679': 38.4443,
    '862_680': 38.424,
    '862_681': 38.4052,
    '862_682': 38.3878,
    '862_683': 38.3708,
    '862_684': 38.3544,
    '862_685': 38.3387,
    '862_686': 38.3272,
    '862_687': 38.3134,
    '862_688': 38.2933,
    '862_689': 38.2632,
    '862_690': 38.2309,
    '862_691': 38.2138,
    '862_692': 38.1808,
    '862_693': 38.1429,
    '862_694': 38.1094,
    '862_695': 38.0939,
    '862_696': 38.0853,
    '862_697': 38.0761,
    '862_698': 38.0592,
    '862_699': 38.0271,
    '862_700': 37.9912,
    '862_701': 37.9638,
    '862_702': 37.9543,
    '862_703': 37.9593,
    '862_704': 37.9735,
    '862_768': 40.3318,
    '862_769': 40.344,
    '862_770': 40.3416,
    '862_771': 40.3036,
    '862_772': 40.2357,
    '862_773': 40.1765,
    '863_364': 29.5729,
    '863_365': 29.6325,
    '863_366': 29.7133,
    '863_367': 29.8164,
    '863_368': 29.9402,
    '863_369': 30.0766,
    '863_370': 30.2131,
    '863_371': 30.3435,
    '863_372': 30.4575,
    '863_373': 30.5545,
    '863_374': 30.6324,
    '863_375': 30.6951,
    '863_376': 30.7433,
    '863_377': 30.782,
    '863_378': 30.8067,
    '863_379': 30.8207,
    '863_380': 30.8287,
    '863_381': 30.825,
    '863_382': 30.8058,
    '863_420': 31.1009,
    '863_421': 31.1485,
    '863_422': 31.2048,
    '863_423': 31.2662,
    '863_424': 31.3292,
    '863_425': 31.3895,
    '863_426': 31.4554,
    '863_427': 31.5256,
    '863_428': 31.5953,
    '863_429': 31.6554,
    '863_430': 31.7223,
    '863_431': 31.797,
    '863_432': 31.8797,
    '863_433': 31.9562,
    '863_434': 32.019,
    '863_435': 32.0742,
    '863_436': 32.1233,
    '863_437': 32.166,
    '863_438': 32.2059,
    '863_439': 32.2416,
    '863_440': 32.2736,
    '863_441': 32.3019,
    '863_442': 32.3329,
    '863_443': 32.3541,
    '863_444': 32.373,
    '863_445': 32.3928,
    '863_446': 32.4021,
    '863_447': 32.419,
    '863_448': 32.4397,
    '863_449': 32.4554,
    '863_450': 32.4668,
    '863_451': 32.4802,
    '863_452': 32.4976,
    '863_453': 32.5245,
    '863_454': 32.5431,
    '863_455': 32.5593,
    '863_456': 32.5774,
    '863_457': 32.6071,
    '863_458': 32.6471,
    '863_459': 32.6949,
    '863_460': 32.7311,
    '863_461': 32.7731,
    '863_462': 32.8133,
    '863_463': 32.8572,
    '863_464': 32.9141,
    '863_465': 32.9703,
    '863_466': 33.0187,
    '863_467': 33.0466,
    '863_468': 33.0747,
    '863_469': 33.116,
    '863_470': 33.1718,
    '863_471': 33.2144,
    '863_472': 33.2321,
    '863_473': 33.2397,
    '863_474': 33.2427,
    '863_475': 33.2208,
    '863_476': 33.2189,
    '863_477': 33.2481,
    '863_478': 33.2756,
    '863_479': 33.2879,
    '863_480': 33.2812,
    '863_481': 33.2825,
    '863_482': 33.3109,
    '863_483': 33.3372,
    '863_484': 33.3338,
    '863_485': 33.309,
    '863_486': 33.2821,
    '863_487': 33.2705,
    '863_488': 33.2625,
    '863_489': 33.2463,
    '863_490': 33.2304,
    '863_491': 33.2173,
    '863_492': 33.1836,
    '863_493': 33.1456,
    '863_494': 33.0992,
    '863_495': 33.0947,
    '863_496': 33.1112,
    '863_497': 33.1314,
    '863_498': 33.1712,
    '863_499': 33.2312,
    '863_500': 33.3083,
    '863_501': 33.4011,
    '863_502': 33.4986,
    '863_503': 33.6061,
    '863_504': 33.7084,
    '863_505': 33.7764,
    '863_506': 33.8497,
    '863_507': 33.9032,
    '863_508': 33.9558,
    '863_509': 34.016,
    '863_510': 34.0853,
    '863_511': 34.1603,
    '863_512': 34.2253,
    '863_513': 34.2747,
    '863_514': 34.3153,
    '863_515': 34.3531,
    '863_516': 34.4013,
    '863_517': 34.4551,
    '863_518': 34.4905,
    '863_519': 34.5286,
    '863_520': 34.5766,
    '863_521': 34.6272,
    '863_522': 34.685,
    '863_523': 34.733,
    '863_524': 34.7738,
    '863_525': 34.8247,
    '863_526': 34.8604,
    '863_527': 34.8999,
    '863_528': 34.9451,
    '863_529': 34.9867,
    '863_530': 35.0329,
    '863_531': 35.0848,
    '863_532': 35.1426,
    '863_533': 35.2015,
    '863_534': 35.2625,
    '863_535': 35.305,
    '863_536': 35.3344,
    '863_537': 35.373,
    '863_538': 35.4171,
    '863_539': 35.4651,
    '863_540': 35.5161,
    '863_541': 35.5712,
    '863_542': 35.6244,
    '863_543': 35.673,
    '863_544': 35.7266,
    '863_545': 35.7827,
    '863_546': 35.8413,
    '863_547': 35.9021,
    '863_548': 35.9635,
    '863_549': 36.0188,
    '863_550': 36.068,
    '863_551': 36.1245,
    '863_552': 36.1757,
    '863_553': 36.2302,
    '863_554': 36.2857,
    '863_555': 36.3433,
    '863_556': 36.4023,
    '863_557': 36.4569,
    '863_558': 36.4995,
    '863_559': 36.5329,
    '863_560': 36.5661,
    '863_561': 36.6004,
    '863_562': 36.6351,
    '863_563': 36.6664,
    '863_564': 36.701,
    '863_565': 36.7355,
    '863_566': 36.7719,
    '863_567': 36.7916,
    '863_568': 36.8137,
    '863_569': 36.8323,
    '863_570': 36.8456,
    '863_571': 36.854,
    '863_572': 36.8569,
    '863_573': 36.8544,
    '863_574': 36.8469,
    '863_575': 36.8356,
    '863_576': 36.8216,
    '863_577': 36.8065,
    '863_578': 36.7911,
    '863_579': 36.7763,
    '863_580': 36.7628,
    '863_581': 36.7509,
    '863_582': 36.7411,
    '863_583': 36.7342,
    '863_584': 36.7306,
    '863_585': 36.7303,
    '863_586': 36.7349,
    '863_587': 36.7463,
    '863_588': 36.7691,
    '863_589': 36.7978,
    '863_590': 36.8321,
    '863_591': 36.871,
    '863_592': 36.9094,
    '863_593': 36.9465,
    '863_594': 36.9517,
    '863_595': 36.9275,
    '863_596': 36.8942,
    '863_597': 36.8587,
    '863_598': 36.8485,
    '863_599': 36.8609,
    '863_600': 36.8852,
    '863_601': 36.9156,
    '863_602': 36.9536,
    '863_603': 37.0057,
    '863_604': 37.0707,
    '863_605': 37.146,
    '863_606': 37.2295,
    '863_607': 37.3188,
    '863_608': 37.411,
    '863_609': 37.5065,
    '863_610': 37.6101,
    '863_611': 37.722,
    '863_612': 37.8428,
    '863_613': 37.9725,
    '863_614': 38.1102,
    '863_615': 38.2543,
    '863_616': 38.4014,
    '863_617': 38.5459,
    '863_618': 38.6801,
    '863_619': 38.8122,
    '863_620': 38.9381,
    '863_621': 39.0411,
    '863_622': 39.1471,
    '863_623': 39.2425,
    '863_624': 39.3319,
    '863_625': 39.4402,
    '863_626': 39.5383,
    '863_627': 39.6115,
    '863_628': 39.6609,
    '863_629': 39.722,
    '863_630': 39.8005,
    '863_631': 39.8869,
    '863_632': 39.9751,
    '863_633': 40.0697,
    '863_634': 40.1604,
    '863_635': 40.2457,
    '863_636': 40.3252,
    '863_637': 40.4051,
    '863_638': 40.4911,
    '863_639': 40.5752,
    '863_640': 40.6465,
    '863_641': 40.716,
    '863_642': 40.7715,
    '863_643': 40.8008,
    '863_644': 40.7951,
    '863_645': 40.7531,
    '863_646': 40.6839,
    '863_647': 40.6041,
    '863_648': 40.5155,
    '863_649': 40.4249,
    '863_650': 40.3423,
    '863_651': 40.2345,
    '863_652': 40.1099,
    '863_653': 40.0007,
    '863_654': 39.8858,
    '863_655': 39.7738,
    '863_656': 39.6705,
    '863_657': 39.5767,
    '863_658': 39.4942,
    '863_659': 39.4207,
    '863_660': 39.3665,
    '863_661': 39.3079,
    '863_662': 39.2296,
    '863_663': 39.1345,
    '863_664': 39.051,
    '863_665': 38.9985,
    '863_666': 38.9421,
    '863_667': 38.884,
    '863_668': 38.8414,
    '863_669': 38.8018,
    '863_670': 38.7605,
    '863_671': 38.7096,
    '863_672': 38.6685,
    '863_673': 38.6332,
    '863_674': 38.6054,
    '863_675': 38.5754,
    '863_676': 38.5477,
    '863_677': 38.522,
    '863_678': 38.5009,
    '863_679': 38.4816,
    '863_680': 38.4644,
    '863_681': 38.4486,
    '863_682': 38.4338,
    '863_683': 38.42,
    '863_684': 38.4065,
    '863_685': 38.3944,
    '863_686': 38.3858,
    '863_687': 38.3742,
    '863_688': 38.3551,
    '863_689': 38.3296,
    '863_690': 38.3039,
    '863_691': 38.2837,
    '863_692': 38.2534,
    '863_693': 38.2205,
    '863_694': 38.1923,
    '863_695': 38.1775,
    '863_696': 38.1689,
    '863_697': 38.1596,
    '863_698': 38.1423,
    '863_699': 38.1063,
    '863_700': 38.0661,
    '863_701': 38.0339,
    '863_702': 38.0207,
    '863_703': 38.0204,
    '863_704': 38.0282,
    '863_768': 40.2998,
    '863_769': 40.311,
    '863_770': 40.308,
    '863_771': 40.2822,
    '863_772': 40.2149,
    '863_773': 40.1568,
    '864_364': 29.5459,
    '864_365': 29.5971,
    '864_366': 29.6686,
    '864_367': 29.7618,
    '864_368': 29.8774,
    '864_369': 30.0173,
    '864_370': 30.1629,
    '864_371': 30.3057,
    '864_372': 30.431,
    '864_373': 30.532,
    '864_374': 30.6154,
    '864_375': 30.6755,
    '864_376': 30.7268,
    '864_377': 30.7662,
    '864_378': 30.7944,
    '864_379': 30.8069,
    '864_380': 30.813,
    '864_381': 30.8157,
    '864_382': 30.8074,
    '864_420': 31.0721,
    '864_421': 31.1219,
    '864_422': 31.1788,
    '864_423': 31.2394,
    '864_424': 31.3003,
    '864_425': 31.3601,
    '864_426': 31.4227,
    '864_427': 31.4915,
    '864_428': 31.5615,
    '864_429': 31.6253,
    '864_430': 31.6929,
    '864_431': 31.7659,
    '864_432': 31.8469,
    '864_433': 31.9228,
    '864_434': 31.9877,
    '864_435': 32.0409,
    '864_436': 32.0868,
    '864_437': 32.1272,
    '864_438': 32.1673,
    '864_439': 32.2037,
    '864_440': 32.238,
    '864_441': 32.2651,
    '864_442': 32.297,
    '864_443': 32.3214,
    '864_444': 32.3372,
    '864_445': 32.3505,
    '864_446': 32.3575,
    '864_447': 32.3777,
    '864_448': 32.4018,
    '864_449': 32.4151,
    '864_450': 32.4264,
    '864_451': 32.4364,
    '864_452': 32.4489,
    '864_453': 32.4689,
    '864_454': 32.495,
    '864_455': 32.5146,
    '864_456': 32.5341,
    '864_457': 32.5833,
    '864_458': 32.6423,
    '864_459': 32.6888,
    '864_460': 32.7312,
    '864_461': 32.7747,
    '864_462': 32.8162,
    '864_463': 32.8613,
    '864_464': 32.9274,
    '864_465': 32.9803,
    '864_466': 33.0295,
    '864_467': 33.0601,
    '864_468': 33.0805,
    '864_469': 33.1046,
    '864_470': 33.1501,
    '864_471': 33.1977,
    '864_472': 33.2206,
    '864_473': 33.2262,
    '864_474': 33.2364,
    '864_475': 33.2286,
    '864_476': 33.234,
    '864_477': 33.2772,
    '864_478': 33.3199,
    '864_479': 33.3424,
    '864_480': 33.3431,
    '864_481': 33.3473,
    '864_482': 33.3695,
    '864_483': 33.3996,
    '864_484': 33.4057,
    '864_485': 33.3941,
    '864_486': 33.3744,
    '864_487': 33.365,
    '864_488': 33.3571,
    '864_489': 33.3364,
    '864_490': 33.31,
    '864_491': 33.2917,
    '864_492': 33.2657,
    '864_493': 33.2314,
    '864_494': 33.1812,
    '864_495': 33.171,
    '864_496': 33.1889,
    '864_497': 33.2027,
    '864_498': 33.2313,
    '864_499': 33.2864,
    '864_500': 33.3615,
    '864_501': 33.4578,
    '864_502': 33.5525,
    '864_503': 33.6394,
    '864_504': 33.7334,
    '864_505': 33.8081,
    '864_506': 33.884,
    '864_507': 33.9365,
    '864_508': 33.9903,
    '864_509': 34.0503,
    '864_510': 34.1154,
    '864_511': 34.1878,
    '864_512': 34.2508,
    '864_513': 34.3002,
    '864_514': 34.3455,
    '864_515': 34.3928,
    '864_516': 34.4416,
    '864_517': 34.4891,
    '864_518': 34.5219,
    '864_519': 34.5594,
    '864_520': 34.6064,
    '864_521': 34.6608,
    '864_522': 34.7189,
    '864_523': 34.7656,
    '864_524': 34.8146,
    '864_525': 34.8681,
    '864_526': 34.9006,
    '864_527': 34.924,
    '864_528': 34.9603,
    '864_529': 35.0001,
    '864_530': 35.044,
    '864_531': 35.0963,
    '864_532': 35.1579,
    '864_533': 35.2198,
    '864_534': 35.2835,
    '864_535': 35.3222,
    '864_536': 35.3402,
    '864_537': 35.3743,
    '864_538': 35.4162,
    '864_539': 35.4633,
    '864_540': 35.5136,
    '864_541': 35.5663,
    '864_542': 35.6166,
    '864_543': 35.666,
    '864_544': 35.7183,
    '864_545': 35.7727,
    '864_546': 35.8291,
    '864_547': 35.8875,
    '864_548': 35.942,
    '864_549': 35.9965,
    '864_550': 36.0483,
    '864_551': 36.1036,
    '864_552': 36.1562,
    '864_553': 36.2092,
    '864_554': 36.2614,
    '864_555': 36.3147,
    '864_556': 36.3665,
    '864_557': 36.4151,
    '864_558': 36.4575,
    '864_559': 36.4954,
    '864_560': 36.5327,
    '864_561': 36.5709,
    '864_562': 36.6088,
    '864_563': 36.6425,
    '864_564': 36.6778,
    '864_565': 36.7138,
    '864_566': 36.7489,
    '864_567': 36.7762,
    '864_568': 36.8019,
    '864_569': 36.8249,
    '864_570': 36.8432,
    '864_571': 36.8554,
    '864_572': 36.8616,
    '864_573': 36.8612,
    '864_574': 36.8542,
    '864_575': 36.8425,
    '864_576': 36.8279,
    '864_577': 36.8124,
    '864_578': 36.7968,
    '864_579': 36.7822,
    '864_580': 36.7689,
    '864_581': 36.7575,
    '864_582': 36.7484,
    '864_583': 36.7421,
    '864_584': 36.7391,
    '864_585': 36.7396,
    '864_586': 36.7451,
    '864_587': 36.7565,
    '864_588': 36.7771,
    '864_589': 36.8065,
    '864_590': 36.842,
    '864_591': 36.8844,
    '864_592': 36.926,
    '864_593': 36.9575,
    '864_594': 36.962,
    '864_595': 36.942,
    '864_596': 36.9078,
    '864_597': 36.8675,
    '864_598': 36.8502,
    '864_599': 36.8567,
    '864_600': 36.8788,
    '864_601': 36.9072,
    '864_602': 36.9412,
    '864_603': 36.9874,
    '864_604': 37.0467,
    '864_605': 37.1166,
    '864_606': 37.1948,
    '864_607': 37.2786,
    '864_608': 37.3652,
    '864_609': 37.4551,
    '864_610': 37.5528,
    '864_611': 37.6597,
    '864_612': 37.7753,
    '864_613': 37.8982,
    '864_614': 38.0286,
    '864_615': 38.1668,
    '864_616': 38.3093,
    '864_617': 38.4488,
    '864_618': 38.5773,
    '864_619': 38.7053,
    '864_620': 38.8302,
    '864_621': 38.9395,
    '864_622': 39.0458,
    '864_623': 39.1468,
    '864_624': 39.2567,
    '864_625': 39.3807,
    '864_626': 39.4951,
    '864_627': 39.5788,
    '864_628': 39.6196,
    '864_629': 39.6612,
    '864_630': 39.7274,
    '864_631': 39.8121,
    '864_632': 39.9045,
    '864_633': 39.9996,
    '864_634': 40.0898,
    '864_635': 40.1741,
    '864_636': 40.2549,
    '864_637': 40.3379,
    '864_638': 40.4224,
    '864_639': 40.4998,
    '864_640': 40.5735,
    '864_641': 40.6455,
    '864_642': 40.7016,
    '864_643': 40.7358,
    '864_644': 40.7338,
    '864_645': 40.6931,
    '864_646': 40.636,
    '864_647': 40.5683,
    '864_648': 40.4832,
    '864_649': 40.3946,
    '864_650': 40.3137,
    '864_651': 40.2184,
    '864_652': 40.1085,
    '864_653': 40.0043,
    '864_654': 39.8931,
    '864_655': 39.7816,
    '864_656': 39.6725,
    '864_657': 39.5732,
    '864_658': 39.4836,
    '864_659': 39.402,
    '864_660': 39.3409,
    '864_661': 39.2841,
    '864_662': 39.2144,
    '864_663': 39.1341,
    '864_664': 39.0536,
    '864_665': 39.0009,
    '864_666': 38.9544,
    '864_667': 38.9009,
    '864_668': 38.8641,
    '864_669': 38.8273,
    '864_670': 38.7854,
    '864_671': 38.7425,
    '864_672': 38.7038,
    '864_673': 38.6648,
    '864_674': 38.6316,
    '864_675': 38.6039,
    '864_676': 38.5757,
    '864_677': 38.5492,
    '864_678': 38.5297,
    '864_679': 38.5132,
    '864_680': 38.4993,
    '864_681': 38.4872,
    '864_682': 38.4764,
    '864_683': 38.4652,
    '864_684': 38.4545,
    '864_685': 38.4448,
    '864_686': 38.4394,
    '864_687': 38.4284,
    '864_688': 38.4125,
    '864_689': 38.3915,
    '864_690': 38.3695,
    '864_691': 38.3472,
    '864_692': 38.3223,
    '864_693': 38.296,
    '864_694': 38.2736,
    '864_695': 38.2594,
    '864_696': 38.2501,
    '864_697': 38.2397,
    '864_698': 38.2204,
    '864_699': 38.1828,
    '864_700': 38.1395,
    '864_701': 38.1029,
    '864_702': 38.0884,
    '864_703': 38.0859,
    '864_704': 38.091,
    '864_768': 40.2704,
    '864_769': 40.2803,
    '864_770': 40.277,
    '864_771': 40.2561,
    '864_772': 40.1901,
    '864_773': 40.1343,
    '865_364': 29.5222,
    '865_365': 29.567,
    '865_366': 29.6281,
    '865_367': 29.7146,
    '865_368': 29.8229,
    '865_369': 29.964,
    '865_370': 30.1148,
    '865_371': 30.2657,
    '865_372': 30.3995,
    '865_373': 30.5089,
    '865_374': 30.592,
    '865_375': 30.6551,
    '865_376': 30.7087,
    '865_377': 30.7521,
    '865_378': 30.7849,
    '865_379': 30.7962,
    '865_380': 30.8037,
    '865_381': 30.8101,
    '865_382': 30.8078,
    '865_420': 31.0424,
    '865_421': 31.094,
    '865_422': 31.152,
    '865_423': 31.2128,
    '865_424': 31.2723,
    '865_425': 31.3316,
    '865_426': 31.3921,
    '865_427': 31.4552,
    '865_428': 31.5212,
    '865_429': 31.5916,
    '865_430': 31.6644,
    '865_431': 31.7404,
    '865_432': 31.8172,
    '865_433': 31.8867,
    '865_434': 31.9472,
    '865_435': 32.0008,
    '865_436': 32.0473,
    '865_437': 32.088,
    '865_438': 32.1279,
    '865_439': 32.1687,
    '865_440': 32.2009,
    '865_441': 32.2204,
    '865_442': 32.2473,
    '865_443': 32.2758,
    '865_444': 32.2922,
    '865_445': 32.302,
    '865_446': 32.3178,
    '865_447': 32.3437,
    '865_448': 32.3687,
    '865_449': 32.3806,
    '865_450': 32.3934,
    '865_451': 32.4016,
    '865_452': 32.4117,
    '865_453': 32.4269,
    '865_454': 32.4486,
    '865_455': 32.4699,
    '865_456': 32.4988,
    '865_457': 32.5526,
    '865_458': 32.6236,
    '865_459': 32.675,
    '865_460': 32.7245,
    '865_461': 32.7703,
    '865_462': 32.811,
    '865_463': 32.8614,
    '865_464': 32.932,
    '865_465': 32.9901,
    '865_466': 33.0344,
    '865_467': 33.0683,
    '865_468': 33.0923,
    '865_469': 33.1036,
    '865_470': 33.1323,
    '865_471': 33.1782,
    '865_472': 33.2104,
    '865_473': 33.2132,
    '865_474': 33.2208,
    '865_475': 33.2302,
    '865_476': 33.2491,
    '865_477': 33.2977,
    '865_478': 33.3518,
    '865_479': 33.3879,
    '865_480': 33.4044,
    '865_481': 33.4188,
    '865_482': 33.4392,
    '865_483': 33.4667,
    '865_484': 33.4793,
    '865_485': 33.4777,
    '865_486': 33.4658,
    '865_487': 33.4561,
    '865_488': 33.4459,
    '865_489': 33.4242,
    '865_490': 33.3926,
    '865_491': 33.363,
    '865_492': 33.3346,
    '865_493': 33.3058,
    '865_494': 33.2653,
    '865_495': 33.2495,
    '865_496': 33.2606,
    '865_497': 33.2801,
    '865_498': 33.2981,
    '865_499': 33.3469,
    '865_500': 33.4222,
    '865_501': 33.5186,
    '865_502': 33.6167,
    '865_503': 33.6896,
    '865_504': 33.7561,
    '865_505': 33.8341,
    '865_506': 33.9183,
    '865_507': 33.9751,
    '865_508': 34.0268,
    '865_509': 34.0855,
    '865_510': 34.1495,
    '865_511': 34.2207,
    '865_512': 34.2817,
    '865_513': 34.3312,
    '865_514': 34.3787,
    '865_515': 34.4312,
    '865_516': 34.4831,
    '865_517': 34.5269,
    '865_518': 34.5611,
    '865_519': 34.5978,
    '865_520': 34.6466,
    '865_521': 34.7081,
    '865_522': 34.7712,
    '865_523': 34.8206,
    '865_524': 34.8595,
    '865_525': 34.9043,
    '865_526': 34.9383,
    '865_527': 34.9567,
    '865_528': 34.9853,
    '865_529': 35.0209,
    '865_530': 35.0591,
    '865_531': 35.1107,
    '865_532': 35.1749,
    '865_533': 35.2358,
    '865_534': 35.2975,
    '865_535': 35.3383,
    '865_536': 35.3481,
    '865_537': 35.3771,
    '865_538': 35.4171,
    '865_539': 35.4635,
    '865_540': 35.5132,
    '865_541': 35.5631,
    '865_542': 35.6118,
    '865_543': 35.6608,
    '865_544': 35.7117,
    '865_545': 35.7643,
    '865_546': 35.8179,
    '865_547': 35.8724,
    '865_548': 35.9253,
    '865_549': 35.9786,
    '865_550': 36.0319,
    '865_551': 36.0868,
    '865_552': 36.1395,
    '865_553': 36.1905,
    '865_554': 36.24,
    '865_555': 36.2882,
    '865_556': 36.3356,
    '865_557': 36.3803,
    '865_558': 36.4211,
    '865_559': 36.4609,
    '865_560': 36.5003,
    '865_561': 36.5407,
    '865_562': 36.5807,
    '865_563': 36.6179,
    '865_564': 36.6543,
    '865_565': 36.6915,
    '865_566': 36.727,
    '865_567': 36.76,
    '865_568': 36.7912,
    '865_569': 36.821,
    '865_570': 36.8459,
    '865_571': 36.8596,
    '865_572': 36.8692,
    '865_573': 36.8709,
    '865_574': 36.863,
    '865_575': 36.8494,
    '865_576': 36.8335,
    '865_577': 36.8172,
    '865_578': 36.8013,
    '865_579': 36.7867,
    '865_580': 36.7737,
    '865_581': 36.7628,
    '865_582': 36.7543,
    '865_583': 36.749,
    '865_584': 36.7468,
    '865_585': 36.7485,
    '865_586': 36.7549,
    '865_587': 36.7662,
    '865_588': 36.7835,
    '865_589': 36.8089,
    '865_590': 36.8443,
    '865_591': 36.8898,
    '865_592': 36.9393,
    '865_593': 36.9704,
    '865_594': 36.9722,
    '865_595': 36.9605,
    '865_596': 36.9281,
    '865_597': 36.8852,
    '865_598': 36.8605,
    '865_599': 36.8591,
    '865_600': 36.8743,
    '865_601': 36.8979,
    '865_602': 36.9287,
    '865_603': 36.9702,
    '865_604': 37.0244,
    '865_605': 37.0896,
    '865_606': 37.1628,
    '865_607': 37.2412,
    '865_608': 37.3217,
    '865_609': 37.4065,
    '865_610': 37.5004,
    '865_611': 37.603,
    '865_612': 37.7129,
    '865_613': 37.8284,
    '865_614': 37.951,
    '865_615': 38.0819,
    '865_616': 38.2184,
    '865_617': 38.3527,
    '865_618': 38.4738,
    '865_619': 38.5977,
    '865_620': 38.7205,
    '865_621': 38.8353,
    '865_622': 38.9408,
    '865_623': 39.0475,
    '865_624': 39.1704,
    '865_625': 39.3036,
    '865_626': 39.4309,
    '865_627': 39.5219,
    '865_628': 39.5629,
    '865_629': 39.5929,
    '865_630': 39.6532,
    '865_631': 39.74,
    '865_632': 39.839,
    '865_633': 39.9426,
    '865_634': 40.0415,
    '865_635': 40.1289,
    '865_636': 40.2092,
    '865_637': 40.2855,
    '865_638': 40.3612,
    '865_639': 40.4351,
    '865_640': 40.5045,
    '865_641': 40.5722,
    '865_642': 40.6274,
    '865_643': 40.6638,
    '865_644': 40.6677,
    '865_645': 40.6363,
    '865_646': 40.5929,
    '865_647': 40.5399,
    '865_648': 40.4755,
    '865_649': 40.3884,
    '865_650': 40.2988,
    '865_651': 40.2032,
    '865_652': 40.0997,
    '865_653': 39.9961,
    '865_654': 39.8876,
    '865_655': 39.7803,
    '865_656': 39.678,
    '865_657': 39.5791,
    '865_658': 39.4803,
    '865_659': 39.3881,
    '865_660': 39.312,
    '865_661': 39.2464,
    '865_662': 39.1784,
    '865_663': 39.1049,
    '865_664': 39.0364,
    '865_665': 38.9837,
    '865_666': 38.9483,
    '865_667': 38.9077,
    '865_668': 38.8768,
    '865_669': 38.8387,
    '865_670': 38.803,
    '865_671': 38.7717,
    '865_672': 38.7327,
    '865_673': 38.686,
    '865_674': 38.6483,
    '865_675': 38.6207,
    '865_676': 38.5971,
    '865_677': 38.5726,
    '865_678': 38.5532,
    '865_679': 38.5395,
    '865_680': 38.5291,
    '865_681': 38.5205,
    '865_682': 38.513,
    '865_683': 38.506,
    '865_684': 38.5,
    '865_685': 38.4951,
    '865_686': 38.4879,
    '865_687': 38.4776,
    '865_688': 38.4644,
    '865_689': 38.4483,
    '865_690': 38.4314,
    '865_691': 38.4132,
    '865_692': 38.391,
    '865_693': 38.3683,
    '865_694': 38.3483,
    '865_695': 38.337,
    '865_696': 38.3283,
    '865_697': 38.3173,
    '865_698': 38.2975,
    '865_699': 38.2601,
    '865_700': 38.2167,
    '865_701': 38.179,
    '865_702': 38.1616,
    '865_703': 38.1552,
    '865_704': 38.1565,
    '865_768': 40.2556,
    '865_769': 40.2558,
    '865_770': 40.2428,
    '865_771': 40.2149,
    '865_772': 40.159,
    '865_773': 40.1028,
    '866_364': 29.5006,
    '866_365': 29.5425,
    '866_366': 29.5995,
    '866_367': 29.6783,
    '866_368': 29.7791,
    '866_369': 29.9133,
    '866_370': 30.067,
    '866_371': 30.2243,
    '866_372': 30.365,
    '866_373': 30.481,
    '866_374': 30.5698,
    '866_375': 30.6339,
    '866_376': 30.6887,
    '866_377': 30.7326,
    '866_378': 30.7664,
    '866_379': 30.7866,
    '866_380': 30.8022,
    '866_381': 30.8119,
    '866_382': 30.8163,
    '866_420': 31.0158,
    '866_421': 31.0667,
    '866_422': 31.1252,
    '866_423': 31.185,
    '866_424': 31.2445,
    '866_425': 31.3048,
    '866_426': 31.3664,
    '866_427': 31.4236,
    '866_428': 31.4891,
    '866_429': 31.5595,
    '866_430': 31.6335,
    '866_431': 31.7098,
    '866_432': 31.7851,
    '866_433': 31.8473,
    '866_434': 31.9012,
    '866_435': 31.9522,
    '866_436': 32.0004,
    '866_437': 32.0449,
    '866_438': 32.0854,
    '866_439': 32.1237,
    '866_440': 32.1527,
    '866_441': 32.1735,
    '866_442': 32.1959,
    '866_443': 32.2194,
    '866_444': 32.2394,
    '866_445': 32.2573,
    '866_446': 32.2785,
    '866_447': 32.3032,
    '866_448': 32.3264,
    '866_449': 32.3443,
    '866_450': 32.3616,
    '866_451': 32.3692,
    '866_452': 32.3797,
    '866_453': 32.3937,
    '866_454': 32.4118,
    '866_455': 32.435,
    '866_456': 32.4683,
    '866_457': 32.5231,
    '866_458': 32.5955,
    '866_459': 32.651,
    '866_460': 32.7033,
    '866_461': 32.7566,
    '866_462': 32.8034,
    '866_463': 32.8601,
    '866_464': 32.9297,
    '866_465': 32.9948,
    '866_466': 33.0402,
    '866_467': 33.0753,
    '866_468': 33.1035,
    '866_469': 33.109,
    '866_470': 33.1258,
    '866_471': 33.1616,
    '866_472': 33.2001,
    '866_473': 33.2084,
    '866_474': 33.2094,
    '866_475': 33.2251,
    '866_476': 33.2611,
    '866_477': 33.3196,
    '866_478': 33.3767,
    '866_479': 33.4249,
    '866_480': 33.4582,
    '866_481': 33.4879,
    '866_482': 33.5176,
    '866_483': 33.5409,
    '866_484': 33.5529,
    '866_485': 33.5553,
    '866_486': 33.5495,
    '866_487': 33.5383,
    '866_488': 33.5237,
    '866_489': 33.5011,
    '866_490': 33.4732,
    '866_491': 33.4385,
    '866_492': 33.4082,
    '866_493': 33.3877,
    '866_494': 33.3606,
    '866_495': 33.3355,
    '866_496': 33.3337,
    '866_497': 33.3517,
    '866_498': 33.3629,
    '866_499': 33.4027,
    '866_500': 33.4798,
    '866_501': 33.5703,
    '866_502': 33.6626,
    '866_503': 33.7371,
    '866_504': 33.7986,
    '866_505': 33.8727,
    '866_506': 33.948,
    '866_507': 34.0152,
    '866_508': 34.0677,
    '866_509': 34.1261,
    '866_510': 34.1879,
    '866_511': 34.2497,
    '866_512': 34.3054,
    '866_513': 34.3561,
    '866_514': 34.4065,
    '866_515': 34.4633,
    '866_516': 34.5209,
    '866_517': 34.5684,
    '866_518': 34.6023,
    '866_519': 34.6362,
    '866_520': 34.6927,
    '866_521': 34.7644,
    '866_522': 34.8321,
    '866_523': 34.8826,
    '866_524': 34.9149,
    '866_525': 34.9421,
    '866_526': 34.9676,
    '866_527': 34.9891,
    '866_528': 35.0132,
    '866_529': 35.0437,
    '866_530': 35.0795,
    '866_531': 35.1311,
    '866_532': 35.193,
    '866_533': 35.2516,
    '866_534': 35.3068,
    '866_535': 35.3445,
    '866_536': 35.356,
    '866_537': 35.3808,
    '866_538': 35.4199,
    '866_539': 35.4665,
    '866_540': 35.5164,
    '866_541': 35.5644,
    '866_542': 35.611,
    '866_543': 35.6588,
    '866_544': 35.7079,
    '866_545': 35.7581,
    '866_546': 35.8091,
    '866_547': 35.8606,
    '866_548': 35.9122,
    '866_549': 35.9645,
    '866_550': 36.018,
    '866_551': 36.0725,
    '866_552': 36.1264,
    '866_553': 36.1749,
    '866_554': 36.2213,
    '866_555': 36.266,
    '866_556': 36.3095,
    '866_557': 36.3504,
    '866_558': 36.3897,
    '866_559': 36.4298,
    '866_560': 36.47,
    '866_561': 36.511,
    '866_562': 36.5524,
    '866_563': 36.5924,
    '866_564': 36.6307,
    '866_565': 36.6692,
    '866_566': 36.7066,
    '866_567': 36.7437,
    '866_568': 36.7805,
    '866_569': 36.8165,
    '866_570': 36.8482,
    '866_571': 36.866,
    '866_572': 36.8792,
    '866_573': 36.8833,
    '866_574': 36.8725,
    '866_575': 36.8553,
    '866_576': 36.8374,
    '866_577': 36.8201,
    '866_578': 36.8039,
    '866_579': 36.7894,
    '866_580': 36.7767,
    '866_581': 36.7663,
    '866_582': 36.7586,
    '866_583': 36.7542,
    '866_584': 36.7528,
    '866_585': 36.7558,
    '866_586': 36.7636,
    '866_587': 36.7754,
    '866_588': 36.7901,
    '866_589': 36.8121,
    '866_590': 36.843,
    '866_591': 36.8853,
    '866_592': 36.9375,
    '866_593': 36.9746,
    '866_594': 36.985,
    '866_595': 36.9781,
    '866_596': 36.9543,
    '866_597': 36.9142,
    '866_598': 36.8805,
    '866_599': 36.8688,
    '866_600': 36.8747,
    '866_601': 36.8924,
    '866_602': 36.9189,
    '866_603': 36.956,
    '866_604': 37.0052,
    '866_605': 37.0649,
    '866_606': 37.1318,
    '866_607': 37.2037,
    '866_608': 37.2794,
    '866_609': 37.3616,
    '866_610': 37.4529,
    '866_611': 37.5503,
    '866_612': 37.6541,
    '866_613': 37.763,
    '866_614': 37.8783,
    '866_615': 38.0009,
    '866_616': 38.1263,
    '866_617': 38.2526,
    '866_618': 38.3731,
    '866_619': 38.4956,
    '866_620': 38.6159,
    '866_621': 38.7298,
    '866_622': 38.8365,
    '866_623': 38.9494,
    '866_624': 39.0763,
    '866_625': 39.2111,
    '866_626': 39.347,
    '866_627': 39.4477,
    '866_628': 39.4896,
    '866_629': 39.5164,
    '866_630': 39.5756,
    '866_631': 39.6595,
    '866_632': 39.763,
    '866_633': 39.8782,
    '866_634': 39.9888,
    '866_635': 40.0891,
    '866_636': 40.1729,
    '866_637': 40.2392,
    '866_638': 40.3044,
    '866_639': 40.3741,
    '866_640': 40.442,
    '866_641': 40.5064,
    '866_642': 40.5607,
    '866_643': 40.601,
    '866_644': 40.615,
    '866_645': 40.5994,
    '866_646': 40.5665,
    '866_647': 40.5265,
    '866_648': 40.4789,
    '866_649': 40.4025,
    '866_650': 40.3102,
    '866_651': 40.2078,
    '866_652': 40.0982,
    '866_653': 39.9886,
    '866_654': 39.8781,
    '866_655': 39.7719,
    '866_656': 39.6686,
    '866_657': 39.5722,
    '866_658': 39.4701,
    '866_659': 39.3787,
    '866_660': 39.2937,
    '866_661': 39.2187,
    '866_662': 39.1474,
    '866_663': 39.0765,
    '866_664': 39.0091,
    '866_665': 38.9541,
    '866_666': 38.918,
    '866_667': 38.893,
    '866_668': 38.8726,
    '866_669': 38.8318,
    '866_670': 38.8086,
    '866_671': 38.7869,
    '866_672': 38.7482,
    '866_673': 38.6991,
    '866_674': 38.6582,
    '866_675': 38.6301,
    '866_676': 38.6073,
    '866_677': 38.5862,
    '866_678': 38.5701,
    '866_679': 38.5607,
    '866_680': 38.5545,
    '866_681': 38.5496,
    '866_682': 38.5457,
    '866_683': 38.5419,
    '866_684': 38.5387,
    '866_685': 38.5358,
    '866_686': 38.5306,
    '866_687': 38.5218,
    '866_688': 38.5105,
    '866_689': 38.5004,
    '866_690': 38.4888,
    '866_691': 38.4742,
    '866_692': 38.4577,
    '866_693': 38.44,
    '866_694': 38.4244,
    '866_695': 38.4134,
    '866_696': 38.4043,
    '866_697': 38.3929,
    '866_698': 38.3736,
    '866_699': 38.3382,
    '866_700': 38.2978,
    '866_701': 38.2622,
    '866_702': 38.2394,
    '866_703': 38.2261,
    '866_704': 38.221,
    '866_768': 40.2465,
    '866_769': 40.2352,
    '866_770': 40.2116,
    '866_771': 40.1745,
    '866_772': 40.123,
    '866_773': 40.0687,
    '867_364': 29.4805,
    '867_365': 29.5189,
    '867_366': 29.5726,
    '867_367': 29.6494,
    '867_368': 29.7477,
    '867_369': 29.8674,
    '867_370': 30.0209,
    '867_371': 30.1818,
    '867_372': 30.3286,
    '867_373': 30.4519,
    '867_374': 30.5484,
    '867_375': 30.6149,
    '867_376': 30.6689,
    '867_377': 30.712,
    '867_378': 30.7458,
    '867_379': 30.7695,
    '867_380': 30.7875,
    '867_381': 30.804,
    '867_382': 30.812,
    '867_420': 30.9942,
    '867_421': 31.0445,
    '867_422': 31.1008,
    '867_423': 31.1572,
    '867_424': 31.2148,
    '867_425': 31.2734,
    '867_426': 31.3331,
    '867_427': 31.3913,
    '867_428': 31.454,
    '867_429': 31.5245,
    '867_430': 31.5977,
    '867_431': 31.6709,
    '867_432': 31.7418,
    '867_433': 31.7997,
    '867_434': 31.8543,
    '867_435': 31.9029,
    '867_436': 31.952,
    '867_437': 32.0014,
    '867_438': 32.0412,
    '867_439': 32.0748,
    '867_440': 32.1028,
    '867_441': 32.1255,
    '867_442': 32.1465,
    '867_443': 32.1671,
    '867_444': 32.1905,
    '867_445': 32.2141,
    '867_446': 32.2395,
    '867_447': 32.2641,
    '867_448': 32.2871,
    '867_449': 32.3069,
    '867_450': 32.3241,
    '867_451': 32.3364,
    '867_452': 32.3493,
    '867_453': 32.3643,
    '867_454': 32.3822,
    '867_455': 32.4073,
    '867_456': 32.4417,
    '867_457': 32.495,
    '867_458': 32.5617,
    '867_459': 32.6238,
    '867_460': 32.6784,
    '867_461': 32.7371,
    '867_462': 32.7988,
    '867_463': 32.8652,
    '867_464': 32.9307,
    '867_465': 32.9929,
    '867_466': 33.0415,
    '867_467': 33.0757,
    '867_468': 33.1028,
    '867_469': 33.1079,
    '867_470': 33.1143,
    '867_471': 33.1401,
    '867_472': 33.1836,
    '867_473': 33.204,
    '867_474': 33.214,
    '867_475': 33.2292,
    '867_476': 33.2826,
    '867_477': 33.3463,
    '867_478': 33.4059,
    '867_479': 33.4597,
    '867_480': 33.5046,
    '867_481': 33.5511,
    '867_482': 33.5901,
    '867_483': 33.6193,
    '867_484': 33.6249,
    '867_485': 33.6248,
    '867_486': 33.6218,
    '867_487': 33.6113,
    '867_488': 33.5902,
    '867_489': 33.5682,
    '867_490': 33.5438,
    '867_491': 33.5152,
    '867_492': 33.4899,
    '867_493': 33.4696,
    '867_494': 33.4441,
    '867_495': 33.4163,
    '867_496': 33.4036,
    '867_497': 33.4174,
    '867_498': 33.4336,
    '867_499': 33.4599,
    '867_500': 33.5284,
    '867_501': 33.6152,
    '867_502': 33.693,
    '867_503': 33.7765,
    '867_504': 33.8518,
    '867_505': 33.9188,
    '867_506': 33.9843,
    '867_507': 34.0502,
    '867_508': 34.1102,
    '867_509': 34.1727,
    '867_510': 34.2353,
    '867_511': 34.2844,
    '867_512': 34.3344,
    '867_513': 34.3856,
    '867_514': 34.4375,
    '867_515': 34.4992,
    '867_516': 34.5582,
    '867_517': 34.6062,
    '867_518': 34.648,
    '867_519': 34.6892,
    '867_520': 34.7454,
    '867_521': 34.8135,
    '867_522': 34.8832,
    '867_523': 34.9345,
    '867_524': 34.9701,
    '867_525': 34.9906,
    '867_526': 35.0011,
    '867_527': 35.0152,
    '867_528': 35.0405,
    '867_529': 35.0703,
    '867_530': 35.1048,
    '867_531': 35.1558,
    '867_532': 35.2115,
    '867_533': 35.261,
    '867_534': 35.3074,
    '867_535': 35.3399,
    '867_536': 35.3601,
    '867_537': 35.3885,
    '867_538': 35.4263,
    '867_539': 35.472,
    '867_540': 35.5237,
    '867_541': 35.5704,
    '867_542': 35.6144,
    '867_543': 35.6599,
    '867_544': 35.7068,
    '867_545': 35.7547,
    '867_546': 35.8032,
    '867_547': 35.8527,
    '867_548': 35.9024,
    '867_549': 35.9532,
    '867_550': 36.0058,
    '867_551': 36.0607,
    '867_552': 36.1138,
    '867_553': 36.1598,
    '867_554': 36.2056,
    '867_555': 36.2496,
    '867_556': 36.2898,
    '867_557': 36.3261,
    '867_558': 36.3627,
    '867_559': 36.4016,
    '867_560': 36.4409,
    '867_561': 36.4818,
    '867_562': 36.5251,
    '867_563': 36.5676,
    '867_564': 36.607,
    '867_565': 36.6466,
    '867_566': 36.6868,
    '867_567': 36.7271,
    '867_568': 36.7678,
    '867_569': 36.8086,
    '867_570': 36.8488,
    '867_571': 36.8726,
    '867_572': 36.889,
    '867_573': 36.8927,
    '867_574': 36.8791,
    '867_575': 36.8572,
    '867_576': 36.8377,
    '867_577': 36.8196,
    '867_578': 36.8033,
    '867_579': 36.7889,
    '867_580': 36.7767,
    '867_581': 36.7669,
    '867_582': 36.7599,
    '867_583': 36.7562,
    '867_584': 36.7555,
    '867_585': 36.7596,
    '867_586': 36.7683,
    '867_587': 36.7803,
    '867_588': 36.7942,
    '867_589': 36.8141,
    '867_590': 36.8411,
    '867_591': 36.8787,
    '867_592': 36.9286,
    '867_593': 36.9691,
    '867_594': 36.9891,
    '867_595': 36.9977,
    '867_596': 36.9891,
    '867_597': 36.9505,
    '867_598': 36.9085,
    '867_599': 36.8843,
    '867_600': 36.8807,
    '867_601': 36.8914,
    '867_602': 36.9123,
    '867_603': 36.9435,
    '867_604': 36.986,
    '867_605': 37.0385,
    '867_606': 37.0984,
    '867_607': 37.1648,
    '867_608': 37.2375,
    '867_609': 37.3179,
    '867_610': 37.4054,
    '867_611': 37.4978,
    '867_612': 37.5964,
    '867_613': 37.6998,
    '867_614': 37.8083,
    '867_615': 37.9221,
    '867_616': 38.0387,
    '867_617': 38.1581,
    '867_618': 38.2775,
    '867_619': 38.396,
    '867_620': 38.5158,
    '867_621': 38.6314,
    '867_622': 38.7395,
    '867_623': 38.8529,
    '867_624': 38.9752,
    '867_625': 39.1077,
    '867_626': 39.2454,
    '867_627': 39.3571,
    '867_628': 39.4101,
    '867_629': 39.4355,
    '867_630': 39.4959,
    '867_631': 39.5794,
    '867_632': 39.6815,
    '867_633': 39.8042,
    '867_634': 39.9282,
    '867_635': 40.0373,
    '867_636': 40.1253,
    '867_637': 40.1861,
    '867_638': 40.2456,
    '867_639': 40.3136,
    '867_640': 40.3836,
    '867_641': 40.4507,
    '867_642': 40.5069,
    '867_643': 40.5461,
    '867_644': 40.5636,
    '867_645': 40.5562,
    '867_646': 40.5372,
    '867_647': 40.5097,
    '867_648': 40.4677,
    '867_649': 40.401,
    '867_650': 40.3133,
    '867_651': 40.2149,
    '867_652': 40.1101,
    '867_653': 40.0031,
    '867_654': 39.8888,
    '867_655': 39.7677,
    '867_656': 39.6522,
    '867_657': 39.5544,
    '867_658': 39.4503,
    '867_659': 39.3589,
    '867_660': 39.2766,
    '867_661': 39.2015,
    '867_662': 39.1245,
    '867_663': 39.0573,
    '867_664': 38.9894,
    '867_665': 38.932,
    '867_666': 38.8857,
    '867_667': 38.8628,
    '867_668': 38.8454,
    '867_669': 38.8092,
    '867_670': 38.7937,
    '867_671': 38.7843,
    '867_672': 38.7556,
    '867_673': 38.7091,
    '867_674': 38.6633,
    '867_675': 38.633,
    '867_676': 38.6109,
    '867_677': 38.593,
    '867_678': 38.5821,
    '867_679': 38.5777,
    '867_680': 38.5759,
    '867_681': 38.5742,
    '867_682': 38.5735,
    '867_683': 38.5736,
    '867_684': 38.5739,
    '867_685': 38.5765,
    '867_686': 38.5713,
    '867_687': 38.5643,
    '867_688': 38.5568,
    '867_689': 38.5513,
    '867_690': 38.5451,
    '867_691': 38.5362,
    '867_692': 38.523,
    '867_693': 38.5084,
    '867_694': 38.4944,
    '867_695': 38.4854,
    '867_696': 38.4762,
    '867_697': 38.4648,
    '867_698': 38.4464,
    '867_699': 38.4137,
    '867_700': 38.3771,
    '867_701': 38.3435,
    '867_702': 38.3177,
    '867_703': 38.298,
    '867_704': 38.2852,
    '867_768': 40.2273,
    '867_769': 40.211,
    '867_770': 40.1817,
    '867_771': 40.1409,
    '867_772': 40.09,
    '867_773': 40.0356,
    '868_364': 29.4563,
    '868_365': 29.4923,
    '868_366': 29.5426,
    '868_367': 29.6173,
    '868_368': 29.7116,
    '868_369': 29.8286,
    '868_370': 29.9815,
    '868_371': 30.146,
    '868_372': 30.2973,
    '868_373': 30.422,
    '868_374': 30.5282,
    '868_375': 30.6051,
    '868_376': 30.6546,
    '868_377': 30.6948,
    '868_378': 30.7276,
    '868_379': 30.752,
    '868_380': 30.7704,
    '868_381': 30.7891,
    '868_382': 30.8013,
    '868_420': 30.9746,
    '868_421': 31.0226,
    '868_422': 31.0747,
    '868_423': 31.1291,
    '868_424': 31.1849,
    '868_425': 31.242,
    '868_426': 31.3006,
    '868_427': 31.3604,
    '868_428': 31.4232,
    '868_429': 31.4911,
    '868_430': 31.5619,
    '868_431': 31.6325,
    '868_432': 31.6971,
    '868_433': 31.7562,
    '868_434': 31.8111,
    '868_435': 31.8624,
    '868_436': 31.9084,
    '868_437': 31.9618,
    '868_438': 31.9974,
    '868_439': 32.0271,
    '868_440': 32.0545,
    '868_441': 32.0795,
    '868_442': 32.1018,
    '868_443': 32.124,
    '868_444': 32.1489,
    '868_445': 32.1753,
    '868_446': 32.202,
    '868_447': 32.2282,
    '868_448': 32.2521,
    '868_449': 32.2726,
    '868_450': 32.2905,
    '868_451': 32.3061,
    '868_452': 32.3216,
    '868_453': 32.3389,
    '868_454': 32.3605,
    '868_455': 32.3866,
    '868_456': 32.4213,
    '868_457': 32.4696,
    '868_458': 32.5306,
    '868_459': 32.5996,
    '868_460': 32.6685,
    '868_461': 32.73,
    '868_462': 32.8023,
    '868_463': 32.8745,
    '868_464': 32.9337,
    '868_465': 32.9917,
    '868_466': 33.0411,
    '868_467': 33.0738,
    '868_468': 33.096,
    '868_469': 33.1008,
    '868_470': 33.1015,
    '868_471': 33.1215,
    '868_472': 33.1612,
    '868_473': 33.193,
    '868_474': 33.2187,
    '868_475': 33.2375,
    '868_476': 33.299,
    '868_477': 33.3689,
    '868_478': 33.4381,
    '868_479': 33.4982,
    '868_480': 33.5478,
    '868_481': 33.604,
    '868_482': 33.6553,
    '868_483': 33.6848,
    '868_484': 33.6923,
    '868_485': 33.6886,
    '868_486': 33.686,
    '868_487': 33.6795,
    '868_488': 33.6599,
    '868_489': 33.6329,
    '868_490': 33.6003,
    '868_491': 33.5774,
    '868_492': 33.5641,
    '868_493': 33.5464,
    '868_494': 33.5156,
    '868_495': 33.4955,
    '868_496': 33.4769,
    '868_497': 33.482,
    '868_498': 33.4985,
    '868_499': 33.5266,
    '868_500': 33.5796,
    '868_501': 33.6554,
    '868_502': 33.7337,
    '868_503': 33.8146,
    '868_504': 33.8992,
    '868_505': 33.9647,
    '868_506': 34.0256,
    '868_507': 34.0914,
    '868_508': 34.16,
    '868_509': 34.222,
    '868_510': 34.2813,
    '868_511': 34.3295,
    '868_512': 34.3747,
    '868_513': 34.4236,
    '868_514': 34.4759,
    '868_515': 34.5316,
    '868_516': 34.591,
    '868_517': 34.6529,
    '868_518': 34.6999,
    '868_519': 34.7449,
    '868_520': 34.8,
    '868_521': 34.8592,
    '868_522': 34.9186,
    '868_523': 34.9649,
    '868_524': 35.0031,
    '868_525': 35.027,
    '868_526': 35.0355,
    '868_527': 35.0508,
    '868_528': 35.0749,
    '868_529': 35.1034,
    '868_530': 35.1356,
    '868_531': 35.182,
    '868_532': 35.2258,
    '868_533': 35.265,
    '868_534': 35.3061,
    '868_535': 35.3346,
    '868_536': 35.3644,
    '868_537': 35.3986,
    '868_538': 35.4368,
    '868_539': 35.4795,
    '868_540': 35.5282,
    '868_541': 35.5776,
    '868_542': 35.6259,
    '868_543': 35.668,
    '868_544': 35.7109,
    '868_545': 35.756,
    '868_546': 35.8015,
    '868_547': 35.8481,
    '868_548': 35.8956,
    '868_549': 35.9448,
    '868_550': 35.997,
    '868_551': 36.0524,
    '868_552': 36.1056,
    '868_553': 36.1513,
    '868_554': 36.1986,
    '868_555': 36.2433,
    '868_556': 36.2801,
    '868_557': 36.3105,
    '868_558': 36.3404,
    '868_559': 36.3777,
    '868_560': 36.4143,
    '868_561': 36.4559,
    '868_562': 36.5019,
    '868_563': 36.5503,
    '868_564': 36.5862,
    '868_565': 36.6249,
    '868_566': 36.6693,
    '868_567': 36.7122,
    '868_568': 36.7551,
    '868_569': 36.8016,
    '868_570': 36.8495,
    '868_571': 36.8813,
    '868_572': 36.8935,
    '868_573': 36.8962,
    '868_574': 36.885,
    '868_575': 36.8559,
    '868_576': 36.8346,
    '868_577': 36.816,
    '868_578': 36.7998,
    '868_579': 36.7857,
    '868_580': 36.7741,
    '868_581': 36.7649,
    '868_582': 36.7587,
    '868_583': 36.7557,
    '868_584': 36.7555,
    '868_585': 36.7601,
    '868_586': 36.7694,
    '868_587': 36.7819,
    '868_588': 36.796,
    '868_589': 36.8147,
    '868_590': 36.8385,
    '868_591': 36.8701,
    '868_592': 36.9123,
    '868_593': 36.9549,
    '868_594': 36.9858,
    '868_595': 37.0117,
    '868_596': 37.0257,
    '868_597': 36.9976,
    '868_598': 36.9477,
    '868_599': 36.9074,
    '868_600': 36.8917,
    '868_601': 36.8944,
    '868_602': 36.9087,
    '868_603': 36.9326,
    '868_604': 36.9678,
    '868_605': 37.0131,
    '868_606': 37.0667,
    '868_607': 37.1284,
    '868_608': 37.1984,
    '868_609': 37.276,
    '868_610': 37.3598,
    '868_611': 37.4481,
    '868_612': 37.5415,
    '868_613': 37.6402,
    '868_614': 37.7431,
    '868_615': 37.8503,
    '868_616': 37.9597,
    '868_617': 38.0717,
    '868_618': 38.1875,
    '868_619': 38.3008,
    '868_620': 38.417,
    '868_621': 38.5336,
    '868_622': 38.6458,
    '868_623': 38.7557,
    '868_624': 38.8743,
    '868_625': 39.0019,
    '868_626': 39.1405,
    '868_627': 39.2602,
    '868_628': 39.3212,
    '868_629': 39.3535,
    '868_630': 39.4173,
    '868_631': 39.5033,
    '868_632': 39.6014,
    '868_633': 39.7195,
    '868_634': 39.8515,
    '868_635': 39.9682,
    '868_636': 40.0633,
    '868_637': 40.1243,
    '868_638': 40.1852,
    '868_639': 40.2542,
    '868_640': 40.3288,
    '868_641': 40.4011,
    '868_642': 40.459,
    '868_643': 40.4956,
    '868_644': 40.5089,
    '868_645': 40.506,
    '868_646': 40.4946,
    '868_647': 40.4721,
    '868_648': 40.4358,
    '868_649': 40.3746,
    '868_650': 40.2893,
    '868_651': 40.1953,
    '868_652': 40.1021,
    '868_653': 40.0036,
    '868_654': 39.8885,
    '868_655': 39.7646,
    '868_656': 39.6414,
    '868_657': 39.5358,
    '868_658': 39.4266,
    '868_659': 39.331,
    '868_660': 39.2504,
    '868_661': 39.1726,
    '868_662': 39.0932,
    '868_663': 39.0214,
    '868_664': 38.9552,
    '868_665': 38.8997,
    '868_666': 38.8521,
    '868_667': 38.821,
    '868_668': 38.797,
    '868_669': 38.769,
    '868_670': 38.7591,
    '868_671': 38.7536,
    '868_672': 38.7337,
    '868_673': 38.6971,
    '868_674': 38.6625,
    '868_675': 38.6362,
    '868_676': 38.6153,
    '868_677': 38.6001,
    '868_678': 38.5944,
    '868_679': 38.5943,
    '868_680': 38.5963,
    '868_681': 38.5971,
    '868_682': 38.599,
    '868_683': 38.6042,
    '868_684': 38.608,
    '868_685': 38.6062,
    '868_686': 38.6064,
    '868_687': 38.6052,
    '868_688': 38.6033,
    '868_689': 38.6019,
    '868_690': 38.5999,
    '868_691': 38.595,
    '868_692': 38.5862,
    '868_693': 38.5765,
    '868_694': 38.5677,
    '868_695': 38.5566,
    '868_696': 38.5457,
    '868_697': 38.533,
    '868_698': 38.5143,
    '868_699': 38.4864,
    '868_700': 38.4546,
    '868_701': 38.4246,
    '868_702': 38.3998,
    '868_703': 38.3796,
    '868_704': 38.3653,
    '868_769': 40.4603,
    '868_770': 40.443,
    '868_771': 40.4153,
    '868_772': 40.3793,
    '868_773': 40.3374,
    '868_774': 40.3086,
    '869_364': 29.4284,
    '869_365': 29.4634,
    '869_366': 29.5096,
    '869_367': 29.5796,
    '869_368': 29.6755,
    '869_369': 29.7967,
    '869_370': 29.9428,
    '869_371': 30.1033,
    '869_372': 30.2626,
    '869_373': 30.396,
    '869_374': 30.5084,
    '869_375': 30.5941,
    '869_376': 30.6435,
    '869_377': 30.6828,
    '869_378': 30.7127,
    '869_379': 30.7373,
    '869_380': 30.7573,
    '869_381': 30.7738,
    '869_382': 30.7876,
    '869_420': 30.9561,
    '869_421': 31.0017,
    '869_422': 31.0503,
    '869_423': 31.102,
    '869_424': 31.1566,
    '869_425': 31.2127,
    '869_426': 31.271,
    '869_427': 31.3307,
    '869_428': 31.392,
    '869_429': 31.456,
    '869_430': 31.524,
    '869_431': 31.5905,
    '869_432': 31.6497,
    '869_433': 31.7095,
    '869_434': 31.765,
    '869_435': 31.8172,
    '869_436': 31.8668,
    '869_437': 31.9163,
    '869_438': 31.95,
    '869_439': 31.9789,
    '869_440': 32.0064,
    '869_441': 32.0307,
    '869_442': 32.0561,
    '869_443': 32.0816,
    '869_444': 32.1095,
    '869_445': 32.1387,
    '869_446': 32.168,
    '869_447': 32.1969,
    '869_448': 32.2226,
    '869_449': 32.2443,
    '869_450': 32.2634,
    '869_451': 32.2812,
    '869_452': 32.299,
    '869_453': 32.3188,
    '869_454': 32.3425,
    '869_455': 32.3699,
    '869_456': 32.405,
    '869_457': 32.4515,
    '869_458': 32.5106,
    '869_459': 32.5874,
    '869_460': 32.6622,
    '869_461': 32.7299,
    '869_462': 32.8027,
    '869_463': 32.8808,
    '869_464': 32.9397,
    '869_465': 32.9923,
    '869_466': 33.0387,
    '869_467': 33.0705,
    '869_468': 33.0863,
    '869_469': 33.089,
    '869_470': 33.0945,
    '869_471': 33.1096,
    '869_472': 33.1396,
    '869_473': 33.1787,
    '869_474': 33.2133,
    '869_475': 33.2375,
    '869_476': 33.303,
    '869_477': 33.3819,
    '869_478': 33.4647,
    '869_479': 33.5287,
    '869_480': 33.5808,
    '869_481': 33.6435,
    '869_482': 33.7056,
    '869_483': 33.7429,
    '869_484': 33.7529,
    '869_485': 33.7471,
    '869_486': 33.7429,
    '869_487': 33.74,
    '869_488': 33.7228,
    '869_489': 33.6901,
    '869_490': 33.6563,
    '869_491': 33.6304,
    '869_492': 33.6212,
    '869_493': 33.6114,
    '869_494': 33.5845,
    '869_495': 33.5669,
    '869_496': 33.557,
    '869_497': 33.5675,
    '869_498': 33.5776,
    '869_499': 33.6081,
    '869_500': 33.6474,
    '869_501': 33.7094,
    '869_502': 33.7963,
    '869_503': 33.8694,
    '869_504': 33.9515,
    '869_505': 34.0157,
    '869_506': 34.0683,
    '869_507': 34.1336,
    '869_508': 34.2073,
    '869_509': 34.2751,
    '869_510': 34.3265,
    '869_511': 34.3721,
    '869_512': 34.4202,
    '869_513': 34.4666,
    '869_514': 34.5174,
    '869_515': 34.5758,
    '869_516': 34.632,
    '869_517': 34.6926,
    '869_518': 34.742,
    '869_519': 34.7903,
    '869_520': 34.8451,
    '869_521': 34.8998,
    '869_522': 34.95,
    '869_523': 34.9865,
    '869_524': 35.0178,
    '869_525': 35.0466,
    '869_526': 35.0677,
    '869_527': 35.0902,
    '869_528': 35.1106,
    '869_529': 35.1367,
    '869_530': 35.1719,
    '869_531': 35.2112,
    '869_532': 35.2476,
    '869_533': 35.275,
    '869_534': 35.3093,
    '869_535': 35.3414,
    '869_536': 35.376,
    '869_537': 35.4149,
    '869_538': 35.452,
    '869_539': 35.4914,
    '869_540': 35.5349,
    '869_541': 35.5839,
    '869_542': 35.6347,
    '869_543': 35.6812,
    '869_544': 35.7208,
    '869_545': 35.7633,
    '869_546': 35.8042,
    '869_547': 35.8467,
    '869_548': 35.8916,
    '869_549': 35.9392,
    '869_550': 35.9917,
    '869_551': 36.0502,
    '869_552': 36.1015,
    '869_553': 36.1506,
    '869_554': 36.2014,
    '869_555': 36.2341,
    '869_556': 36.2651,
    '869_557': 36.2945,
    '869_558': 36.3197,
    '869_559': 36.3528,
    '869_560': 36.3901,
    '869_561': 36.4326,
    '869_562': 36.4779,
    '869_563': 36.5281,
    '869_564': 36.5633,
    '869_565': 36.6012,
    '869_566': 36.647,
    '869_567': 36.6954,
    '869_568': 36.7444,
    '869_569': 36.7999,
    '869_570': 36.8544,
    '869_571': 36.9028,
    '869_572': 36.9018,
    '869_573': 36.8981,
    '869_574': 36.8798,
    '869_575': 36.8498,
    '869_576': 36.8271,
    '869_577': 36.8084,
    '869_578': 36.7925,
    '869_579': 36.7791,
    '869_580': 36.7682,
    '869_581': 36.7598,
    '869_582': 36.7542,
    '869_583': 36.7518,
    '869_584': 36.752,
    '869_585': 36.7569,
    '869_586': 36.7663,
    '869_587': 36.779,
    '869_588': 36.7941,
    '869_589': 36.8124,
    '869_590': 36.8338,
    '869_591': 36.8602,
    '869_592': 36.8932,
    '869_593': 36.9348,
    '869_594': 36.9748,
    '869_595': 37.0148,
    '869_596': 37.0433,
    '869_597': 37.0391,
    '869_598': 36.9934,
    '869_599': 36.9363,
    '869_600': 36.9071,
    '869_601': 36.8997,
    '869_602': 36.9062,
    '869_603': 36.9231,
    '869_604': 36.9514,
    '869_605': 36.9903,
    '869_606': 37.0384,
    '869_607': 37.0957,
    '869_608': 37.1624,
    '869_609': 37.2373,
    '869_610': 37.3176,
    '869_611': 37.4016,
    '869_612': 37.4898,
    '869_613': 37.5832,
    '869_614': 37.6818,
    '869_615': 37.7844,
    '869_616': 37.8886,
    '869_617': 37.9939,
    '869_618': 38.103,
    '869_619': 38.2101,
    '869_620': 38.321,
    '869_621': 38.4359,
    '869_622': 38.5478,
    '869_623': 38.659,
    '869_624': 38.7728,
    '869_625': 38.898,
    '869_626': 39.0342,
    '869_627': 39.1583,
    '869_628': 39.2226,
    '869_629': 39.2672,
    '869_630': 39.3384,
    '869_631': 39.4246,
    '869_632': 39.5206,
    '869_633': 39.6309,
    '869_634': 39.7581,
    '869_635': 39.882,
    '869_636': 39.9883,
    '869_637': 40.0583,
    '869_638': 40.1245,
    '869_639': 40.1979,
    '869_640': 40.2764,
    '869_641': 40.3506,
    '869_642': 40.4073,
    '869_643': 40.4427,
    '869_644': 40.4521,
    '869_645': 40.4488,
    '869_646': 40.439,
    '869_647': 40.4149,
    '869_648': 40.3782,
    '869_649': 40.3236,
    '869_650': 40.2438,
    '869_651': 40.1553,
    '869_652': 40.0721,
    '869_653': 39.9808,
    '869_654': 39.8697,
    '869_655': 39.7546,
    '869_656': 39.6377,
    '869_657': 39.5226,
    '869_658': 39.407,
    '869_659': 39.3047,
    '869_660': 39.2145,
    '869_661': 39.1324,
    '869_662': 39.0502,
    '869_663': 38.9799,
    '869_664': 38.9167,
    '869_665': 38.8626,
    '869_666': 38.8158,
    '869_667': 38.7789,
    '869_668': 38.7518,
    '869_669': 38.727,
    '869_670': 38.7124,
    '869_671': 38.7043,
    '869_672': 38.6912,
    '869_673': 38.6671,
    '869_674': 38.6459,
    '869_675': 38.6328,
    '869_676': 38.6199,
    '869_677': 38.608,
    '869_678': 38.6044,
    '869_679': 38.609,
    '869_680': 38.6144,
    '869_681': 38.6194,
    '869_682': 38.6255,
    '869_683': 38.6324,
    '869_684': 38.6373,
    '869_685': 38.6388,
    '869_686': 38.6419,
    '869_687': 38.6446,
    '869_688': 38.6466,
    '869_689': 38.6501,
    '869_690': 38.6523,
    '869_691': 38.6516,
    '869_692': 38.6446,
    '869_693': 38.6365,
    '869_694': 38.6285,
    '869_695': 38.62,
    '869_696': 38.6109,
    '869_697': 38.5994,
    '869_698': 38.583,
    '869_699': 38.5584,
    '869_700': 38.5308,
    '869_701': 38.5047,
    '869_702': 38.4842,
    '869_703': 38.4682,
    '869_704': 38.4567,
    '869_769': 40.4155,
    '869_770': 40.4026,
    '869_771': 40.3774,
    '869_772': 40.3405,
    '869_773': 40.2981,
    '869_774': 40.2711,
    '870_364': 29.4023,
    '870_365': 29.4374,
    '870_366': 29.4812,
    '870_367': 29.5433,
    '870_368': 29.6345,
    '870_369': 29.7518,
    '870_370': 29.8966,
    '870_371': 30.0561,
    '870_372': 30.2185,
    '870_373': 30.3628,
    '870_374': 30.4807,
    '870_375': 30.5743,
    '870_376': 30.6355,
    '870_377': 30.6746,
    '870_378': 30.6997,
    '870_379': 30.7213,
    '870_380': 30.7398,
    '870_381': 30.7547,
    '870_382': 30.7699,
    '870_420': 30.9394,
    '870_421': 30.9813,
    '870_422': 31.0263,
    '870_423': 31.0751,
    '870_424': 31.129,
    '870_425': 31.1848,
    '870_426': 31.243,
    '870_427': 31.3024,
    '870_428': 31.3626,
    '870_429': 31.4236,
    '870_430': 31.4857,
    '870_431': 31.5471,
    '870_432': 31.6048,
    '870_433': 31.6627,
    '870_434': 31.7179,
    '870_435': 31.7695,
    '870_436': 31.8188,
    '870_437': 31.8655,
    '870_438': 31.9037,
    '870_439': 31.9347,
    '870_440': 31.9607,
    '870_441': 31.982,
    '870_442': 32.0075,
    '870_443': 32.0383,
    '870_444': 32.0702,
    '870_445': 32.1029,
    '870_446': 32.135,
    '870_447': 32.1674,
    '870_448': 32.1964,
    '870_449': 32.22,
    '870_450': 32.2417,
    '870_451': 32.2625,
    '870_452': 32.2816,
    '870_453': 32.3034,
    '870_454': 32.3281,
    '870_455': 32.356,
    '870_456': 32.3921,
    '870_457': 32.4396,
    '870_458': 32.4976,
    '870_459': 32.5684,
    '870_460': 32.6461,
    '870_461': 32.7278,
    '870_462': 32.8016,
    '870_463': 32.8763,
    '870_464': 32.9396,
    '870_465': 32.9902,
    '870_466': 33.0311,
    '870_467': 33.0589,
    '870_468': 33.0714,
    '870_469': 33.0801,
    '870_470': 33.0915,
    '870_471': 33.1034,
    '870_472': 33.1265,
    '870_473': 33.1577,
    '870_474': 33.2008,
    '870_475': 33.2398,
    '870_476': 33.311,
    '870_477': 33.393,
    '870_478': 33.4802,
    '870_479': 33.5487,
    '870_480': 33.6022,
    '870_481': 33.6694,
    '870_482': 33.737,
    '870_483': 33.7818,
    '870_484': 33.8002,
    '870_485': 33.7997,
    '870_486': 33.7958,
    '870_487': 33.7946,
    '870_488': 33.7755,
    '870_489': 33.7447,
    '870_490': 33.7188,
    '870_491': 33.6915,
    '870_492': 33.6689,
    '870_493': 33.666,
    '870_494': 33.6568,
    '870_495': 33.6389,
    '870_496': 33.6287,
    '870_497': 33.643,
    '870_498': 33.6561,
    '870_499': 33.6818,
    '870_500': 33.7205,
    '870_501': 33.7815,
    '870_502': 33.8755,
    '870_503': 33.9409,
    '870_504': 33.9962,
    '870_505': 34.0613,
    '870_506': 34.1157,
    '870_507': 34.1769,
    '870_508': 34.255,
    '870_509': 34.3263,
    '870_510': 34.3745,
    '870_511': 34.412,
    '870_512': 34.4572,
    '870_513': 34.5043,
    '870_514': 34.5597,
    '870_515': 34.6148,
    '870_516': 34.6687,
    '870_517': 34.7236,
    '870_518': 34.776,
    '870_519': 34.83,
    '870_520': 34.8837,
    '870_521': 34.9348,
    '870_522': 34.9821,
    '870_523': 35.0131,
    '870_524': 35.0395,
    '870_525': 35.0643,
    '870_526': 35.0897,
    '870_527': 35.1183,
    '870_528': 35.1431,
    '870_529': 35.1694,
    '870_530': 35.2041,
    '870_531': 35.2406,
    '870_532': 35.2726,
    '870_533': 35.2976,
    '870_534': 35.3222,
    '870_535': 35.3574,
    '870_536': 35.3926,
    '870_537': 35.4313,
    '870_538': 35.4711,
    '870_539': 35.5106,
    '870_540': 35.5509,
    '870_541': 35.5988,
    '870_542': 35.6454,
    '870_543': 35.6941,
    '870_544': 35.7386,
    '870_545': 35.7781,
    '870_546': 35.8108,
    '870_547': 35.8477,
    '870_548': 35.8899,
    '870_549': 35.9359,
    '870_550': 35.987,
    '870_551': 36.0458,
    '870_552': 36.1005,
    '870_553': 36.1494,
    '870_554': 36.1943,
    '870_555': 36.2212,
    '870_556': 36.2469,
    '870_557': 36.2798,
    '870_558': 36.3029,
    '870_559': 36.332,
    '870_560': 36.3675,
    '870_561': 36.4084,
    '870_562': 36.4501,
    '870_563': 36.4949,
    '870_564': 36.5345,
    '870_565': 36.5756,
    '870_566': 36.6213,
    '870_567': 36.6751,
    '870_568': 36.7331,
    '870_569': 36.7887,
    '870_570': 36.8555,
    '870_571': 36.9097,
    '870_572': 36.9158,
    '870_573': 36.9091,
    '870_574': 36.8749,
    '870_575': 36.8379,
    '870_576': 36.8142,
    '870_577': 36.796,
    '870_578': 36.7785,
    '870_579': 36.7663,
    '870_580': 36.7566,
    '870_581': 36.7493,
    '870_582': 36.7447,
    '870_583': 36.7431,
    '870_584': 36.7445,
    '870_585': 36.7493,
    '870_586': 36.7587,
    '870_587': 36.7719,
    '870_588': 36.7881,
    '870_589': 36.8068,
    '870_590': 36.8263,
    '870_591': 36.8492,
    '870_592': 36.8765,
    '870_593': 36.9122,
    '870_594': 36.9529,
    '870_595': 37,
    '870_596': 37.0362,
    '870_597': 37.0575,
    '870_598': 37.0322,
    '870_599': 36.973,
    '870_600': 36.9286,
    '870_601': 36.908,
    '870_602': 36.9055,
    '870_603': 36.9162,
    '870_604': 36.9381,
    '870_605': 36.971,
    '870_606': 37.0137,
    '870_607': 37.0667,
    '870_608': 37.1296,
    '870_609': 37.2006,
    '870_610': 37.2772,
    '870_611': 37.3567,
    '870_612': 37.4395,
    '870_613': 37.5276,
    '870_614': 37.6223,
    '870_615': 37.7221,
    '870_616': 37.8222,
    '870_617': 37.9197,
    '870_618': 38.0206,
    '870_619': 38.122,
    '870_620': 38.228,
    '870_621': 38.3397,
    '870_622': 38.4499,
    '870_623': 38.5606,
    '870_624': 38.672,
    '870_625': 38.795,
    '870_626': 38.9312,
    '870_627': 39.0547,
    '870_628': 39.1219,
    '870_629': 39.1805,
    '870_630': 39.2559,
    '870_631': 39.3419,
    '870_632': 39.4358,
    '870_633': 39.5404,
    '870_634': 39.6575,
    '870_635': 39.7812,
    '870_636': 39.8986,
    '870_637': 39.987,
    '870_638': 40.0634,
    '870_639': 40.1416,
    '870_640': 40.2181,
    '870_641': 40.2909,
    '870_642': 40.344,
    '870_643': 40.3804,
    '870_644': 40.3926,
    '870_645': 40.3882,
    '870_646': 40.3776,
    '870_647': 40.3536,
    '870_648': 40.3138,
    '870_649': 40.2612,
    '870_650': 40.1838,
    '870_651': 40.1022,
    '870_652': 40.0271,
    '870_653': 39.9452,
    '870_654': 39.8513,
    '870_655': 39.7493,
    '870_656': 39.64,
    '870_657': 39.5184,
    '870_658': 39.3901,
    '870_659': 39.2775,
    '870_660': 39.1742,
    '870_661': 39.0841,
    '870_662': 39.0037,
    '870_663': 38.937,
    '870_664': 38.8782,
    '870_665': 38.8264,
    '870_666': 38.7795,
    '870_667': 38.7397,
    '870_668': 38.7079,
    '870_669': 38.6847,
    '870_670': 38.6698,
    '870_671': 38.6604,
    '870_672': 38.6525,
    '870_673': 38.638,
    '870_674': 38.6247,
    '870_675': 38.6187,
    '870_676': 38.6142,
    '870_677': 38.6084,
    '870_678': 38.611,
    '870_679': 38.6188,
    '870_680': 38.6279,
    '870_681': 38.6366,
    '870_682': 38.647,
    '870_683': 38.6549,
    '870_684': 38.6619,
    '870_685': 38.6676,
    '870_686': 38.6748,
    '870_687': 38.6825,
    '870_688': 38.6897,
    '870_689': 38.6951,
    '870_690': 38.6988,
    '870_691': 38.699,
    '870_692': 38.6956,
    '870_693': 38.6893,
    '870_694': 38.6819,
    '870_695': 38.6777,
    '870_696': 38.6721,
    '870_697': 38.6632,
    '870_698': 38.6493,
    '870_699': 38.6284,
    '870_700': 38.6051,
    '870_701': 38.5839,
    '870_702': 38.5675,
    '870_703': 38.5552,
    '870_704': 38.5454,
    '870_705': 38.5335,
    '870_706': 38.5208,
    '870_707': 38.5106,
    '870_708': 38.5071,
    '870_709': 38.5076,
    '870_710': 38.5111,
    '870_711': 38.5172,
    '870_712': 38.5247,
    '870_713': 38.5331,
    '870_714': 38.5424,
    '870_715': 38.5527,
    '870_716': 38.5696,
    '870_717': 38.5974,
    '870_718': 38.6434,
    '870_719': 38.7039,
    '870_720': 38.7745,
    '870_721': 38.8471,
    '870_722': 38.9166,
    '870_723': 38.9831,
    '870_724': 39.0532,
    '870_725': 39.1204,
    '870_726': 39.1902,
    '870_727': 39.246,
    '870_728': 39.2848,
    '870_729': 39.3047,
    '870_730': 39.3115,
    '870_731': 39.3047,
    '870_732': 39.2798,
    '870_733': 39.2379,
    '870_734': 39.1645,
    '870_735': 39.0685,
    '870_736': 38.9295,
    '870_737': 38.7575,
    '870_738': 38.5977,
    '870_739': 38.4694,
    '870_740': 38.3853,
    '870_741': 38.3529,
    '870_742': 38.3591,
    '870_743': 38.4056,
    '870_744': 38.5016,
    '870_745': 38.6405,
    '870_746': 38.8154,
    '870_747': 38.9977,
    '870_748': 39.1829,
    '870_749': 39.3587,
    '870_750': 39.5188,
    '870_751': 39.6592,
    '870_752': 39.7702,
    '870_753': 39.8472,
    '870_754': 39.8957,
    '870_755': 39.9273,
    '870_756': 39.955,
    '870_757': 39.9875,
    '870_758': 40.01,
    '870_759': 40.0328,
    '870_760': 40.055,
    '870_761': 40.0807,
    '870_762': 40.1091,
    '870_763': 40.1258,
    '870_764': 40.1412,
    '870_769': 40.3665,
    '870_770': 40.3613,
    '870_771': 40.3427,
    '870_772': 40.3065,
    '870_773': 40.2638,
    '870_774': 40.2332,
    '871_364': 29.3798,
    '871_365': 29.4162,
    '871_366': 29.4552,
    '871_367': 29.5111,
    '871_368': 29.5943,
    '871_369': 29.7055,
    '871_370': 29.8486,
    '871_371': 30.0068,
    '871_372': 30.1679,
    '871_373': 30.3161,
    '871_374': 30.4399,
    '871_375': 30.5408,
    '871_376': 30.6164,
    '871_377': 30.6624,
    '871_378': 30.6872,
    '871_379': 30.7055,
    '871_380': 30.7227,
    '871_381': 30.7374,
    '871_382': 30.7509,
    '871_420': 30.9252,
    '871_421': 30.963,
    '871_422': 31.0039,
    '871_423': 31.049,
    '871_424': 31.1014,
    '871_425': 31.1581,
    '871_426': 31.2165,
    '871_427': 31.2756,
    '871_428': 31.3348,
    '871_429': 31.3925,
    '871_430': 31.4471,
    '871_431': 31.5045,
    '871_432': 31.56,
    '871_433': 31.6167,
    '871_434': 31.6704,
    '871_435': 31.721,
    '871_436': 31.7702,
    '871_437': 31.8186,
    '871_438': 31.8601,
    '871_439': 31.8922,
    '871_440': 31.915,
    '871_441': 31.9359,
    '871_442': 31.9664,
    '871_443': 32.0003,
    '871_444': 32.0344,
    '871_445': 32.0683,
    '871_446': 32.1054,
    '871_447': 32.1418,
    '871_448': 32.1745,
    '871_449': 32.1996,
    '871_450': 32.2229,
    '871_451': 32.2448,
    '871_452': 32.2678,
    '871_453': 32.2923,
    '871_454': 32.3187,
    '871_455': 32.3471,
    '871_456': 32.3809,
    '871_457': 32.4278,
    '871_458': 32.4872,
    '871_459': 32.5573,
    '871_460': 32.6251,
    '871_461': 32.7123,
    '871_462': 32.797,
    '871_463': 32.8695,
    '871_464': 32.931,
    '871_465': 32.9788,
    '871_466': 33.0123,
    '871_467': 33.0391,
    '871_468': 33.0582,
    '871_469': 33.0756,
    '871_470': 33.0921,
    '871_471': 33.0966,
    '871_472': 33.1128,
    '871_473': 33.1381,
    '871_474': 33.1852,
    '871_475': 33.2391,
    '871_476': 33.3219,
    '871_477': 33.4014,
    '871_478': 33.4846,
    '871_479': 33.5536,
    '871_480': 33.6105,
    '871_481': 33.684,
    '871_482': 33.7548,
    '871_483': 33.8099,
    '871_484': 33.8377,
    '871_485': 33.8473,
    '871_486': 33.8457,
    '871_487': 33.8414,
    '871_488': 33.8233,
    '871_489': 33.7955,
    '871_490': 33.7772,
    '871_491': 33.7555,
    '871_492': 33.7256,
    '871_493': 33.7139,
    '871_494': 33.7194,
    '871_495': 33.7127,
    '871_496': 33.7016,
    '871_497': 33.709,
    '871_498': 33.7279,
    '871_499': 33.7512,
    '871_500': 33.7828,
    '871_501': 33.8449,
    '871_502': 33.9353,
    '871_503': 34.0106,
    '871_504': 34.0554,
    '871_505': 34.0993,
    '871_506': 34.1586,
    '871_507': 34.224,
    '871_508': 34.3025,
    '871_509': 34.369,
    '871_510': 34.4157,
    '871_511': 34.452,
    '871_512': 34.4968,
    '871_513': 34.5497,
    '871_514': 34.6053,
    '871_515': 34.6581,
    '871_516': 34.7063,
    '871_517': 34.7572,
    '871_518': 34.8138,
    '871_519': 34.8682,
    '871_520': 34.9206,
    '871_521': 34.9702,
    '871_522': 35.0171,
    '871_523': 35.0526,
    '871_524': 35.0782,
    '871_525': 35.095,
    '871_526': 35.1113,
    '871_527': 35.1403,
    '871_528': 35.1701,
    '871_529': 35.1968,
    '871_530': 35.2202,
    '871_531': 35.2488,
    '871_532': 35.2817,
    '871_533': 35.3145,
    '871_534': 35.3391,
    '871_535': 35.3761,
    '871_536': 35.4183,
    '871_537': 35.4552,
    '871_538': 35.4947,
    '871_539': 35.5336,
    '871_540': 35.5751,
    '871_541': 35.6207,
    '871_542': 35.6666,
    '871_543': 35.7064,
    '871_544': 35.7502,
    '871_545': 35.7889,
    '871_546': 35.8195,
    '871_547': 35.853,
    '871_548': 35.8915,
    '871_549': 35.9345,
    '871_550': 35.9829,
    '871_551': 36.0385,
    '871_552': 36.0928,
    '871_553': 36.1361,
    '871_554': 36.1763,
    '871_555': 36.2031,
    '871_556': 36.2253,
    '871_557': 36.2602,
    '871_558': 36.2866,
    '871_559': 36.3138,
    '871_560': 36.3466,
    '871_561': 36.3842,
    '871_562': 36.4243,
    '871_563': 36.466,
    '871_564': 36.5077,
    '871_565': 36.5506,
    '871_566': 36.5957,
    '871_567': 36.6461,
    '871_568': 36.701,
    '871_569': 36.759,
    '871_570': 36.8302,
    '871_571': 36.8855,
    '871_572': 36.9063,
    '871_573': 36.9007,
    '871_574': 36.8626,
    '871_575': 36.8188,
    '871_576': 36.7959,
    '871_577': 36.7793,
    '871_578': 36.7637,
    '871_579': 36.7529,
    '871_580': 36.7445,
    '871_581': 36.7382,
    '871_582': 36.7342,
    '871_583': 36.7329,
    '871_584': 36.7342,
    '871_585': 36.7385,
    '871_586': 36.7473,
    '871_587': 36.7601,
    '871_588': 36.7776,
    '871_589': 36.7973,
    '871_590': 36.8167,
    '871_591': 36.8378,
    '871_592': 36.8607,
    '871_593': 36.8883,
    '871_594': 36.9238,
    '871_595': 36.9647,
    '871_596': 37.0057,
    '871_597': 37.0425,
    '871_598': 37.0389,
    '871_599': 36.996,
    '871_600': 36.9441,
    '871_601': 36.9149,
    '871_602': 36.9066,
    '871_603': 36.9124,
    '871_604': 36.9294,
    '871_605': 36.9564,
    '871_606': 36.9938,
    '871_607': 37.0418,
    '871_608': 37.0998,
    '871_609': 37.1657,
    '871_610': 37.2368,
    '871_611': 37.3112,
    '871_612': 37.3893,
    '871_613': 37.4731,
    '871_614': 37.5647,
    '871_615': 37.6614,
    '871_616': 37.757,
    '871_617': 37.8476,
    '871_618': 37.942,
    '871_619': 38.0395,
    '871_620': 38.1413,
    '871_621': 38.2484,
    '871_622': 38.3554,
    '871_623': 38.4637,
    '871_624': 38.5755,
    '871_625': 38.696,
    '871_626': 38.8312,
    '871_627': 38.9539,
    '871_628': 39.0279,
    '871_629': 39.0959,
    '871_630': 39.1726,
    '871_631': 39.2591,
    '871_632': 39.352,
    '871_633': 39.4541,
    '871_634': 39.5689,
    '871_635': 39.6961,
    '871_636': 39.8174,
    '871_637': 39.9186,
    '871_638': 40.0008,
    '871_639': 40.0789,
    '871_640': 40.154,
    '871_641': 40.22,
    '871_642': 40.2695,
    '871_643': 40.3096,
    '871_644': 40.3275,
    '871_645': 40.3239,
    '871_646': 40.3127,
    '871_647': 40.2935,
    '871_648': 40.2497,
    '871_649': 40.1952,
    '871_650': 40.119,
    '871_651': 40.0449,
    '871_652': 39.9762,
    '871_653': 39.9004,
    '871_654': 39.8159,
    '871_655': 39.7177,
    '871_656': 39.6097,
    '871_657': 39.4866,
    '871_658': 39.356,
    '871_659': 39.2368,
    '871_660': 39.133,
    '871_661': 39.0403,
    '871_662': 38.9589,
    '871_663': 38.8917,
    '871_664': 38.8361,
    '871_665': 38.7872,
    '871_666': 38.7429,
    '871_667': 38.7035,
    '871_668': 38.67,
    '871_669': 38.645,
    '871_670': 38.6273,
    '871_671': 38.6176,
    '871_672': 38.6125,
    '871_673': 38.607,
    '871_674': 38.6045,
    '871_675': 38.6051,
    '871_676': 38.6049,
    '871_677': 38.6061,
    '871_678': 38.6134,
    '871_679': 38.6237,
    '871_680': 38.6347,
    '871_681': 38.6466,
    '871_682': 38.6605,
    '871_683': 38.6727,
    '871_684': 38.6832,
    '871_685': 38.6925,
    '871_686': 38.704,
    '871_687': 38.7164,
    '871_688': 38.7287,
    '871_689': 38.7362,
    '871_690': 38.7429,
    '871_691': 38.7475,
    '871_692': 38.7458,
    '871_693': 38.742,
    '871_694': 38.7373,
    '871_695': 38.7359,
    '871_696': 38.7328,
    '871_697': 38.7264,
    '871_698': 38.7149,
    '871_699': 38.6966,
    '871_700': 38.676,
    '871_701': 38.6577,
    '871_702': 38.6466,
    '871_703': 38.6394,
    '871_704': 38.6335,
    '871_705': 38.621,
    '871_706': 38.6066,
    '871_707': 38.5946,
    '871_708': 38.5905,
    '871_709': 38.5889,
    '871_710': 38.5885,
    '871_711': 38.5893,
    '871_712': 38.5914,
    '871_713': 38.5944,
    '871_714': 38.5994,
    '871_715': 38.606,
    '871_716': 38.6195,
    '871_717': 38.6444,
    '871_718': 38.6861,
    '871_719': 38.7408,
    '871_720': 38.8057,
    '871_721': 38.8776,
    '871_722': 38.9502,
    '871_723': 39.0208,
    '871_724': 39.0885,
    '871_725': 39.1537,
    '871_726': 39.2191,
    '871_727': 39.2759,
    '871_728': 39.3219,
    '871_729': 39.3484,
    '871_730': 39.3562,
    '871_731': 39.3466,
    '871_732': 39.3169,
    '871_733': 39.2692,
    '871_734': 39.1999,
    '871_735': 39.0992,
    '871_736': 38.963,
    '871_737': 38.7995,
    '871_738': 38.6532,
    '871_739': 38.5359,
    '871_740': 38.4442,
    '871_741': 38.4018,
    '871_742': 38.4013,
    '871_743': 38.4414,
    '871_744': 38.5366,
    '871_745': 38.6743,
    '871_746': 38.8499,
    '871_747': 39.0341,
    '871_748': 39.2223,
    '871_749': 39.3945,
    '871_750': 39.5494,
    '871_751': 39.6973,
    '871_752': 39.8116,
    '871_753': 39.8982,
    '871_754': 39.9576,
    '871_755': 39.9926,
    '871_756': 40.0226,
    '871_757': 40.042,
    '871_758': 40.0497,
    '871_759': 40.0617,
    '871_760': 40.0734,
    '871_761': 40.087,
    '871_762': 40.1036,
    '871_763': 40.1081,
    '871_764': 40.1107,
    '871_769': 40.3163,
    '871_770': 40.3211,
    '871_771': 40.3169,
    '871_772': 40.2761,
    '871_773': 40.2273,
    '871_774': 40.201,
    '872_364': 29.3593,
    '872_365': 29.3911,
    '872_366': 29.4272,
    '872_367': 29.4773,
    '872_368': 29.5558,
    '872_369': 29.6633,
    '872_370': 29.8033,
    '872_371': 29.9593,
    '872_372': 30.1181,
    '872_373': 30.2672,
    '872_374': 30.3959,
    '872_375': 30.5042,
    '872_376': 30.5915,
    '872_377': 30.6472,
    '872_378': 30.6751,
    '872_379': 30.6917,
    '872_380': 30.7055,
    '872_381': 30.7186,
    '872_382': 30.7313,
    '872_420': 30.9099,
    '872_421': 30.947,
    '872_422': 30.9872,
    '872_423': 31.0311,
    '872_424': 31.0822,
    '872_425': 31.1368,
    '872_426': 31.1933,
    '872_427': 31.2509,
    '872_428': 31.3086,
    '872_429': 31.364,
    '872_430': 31.412,
    '872_431': 31.4622,
    '872_432': 31.5155,
    '872_433': 31.5725,
    '872_434': 31.6233,
    '872_435': 31.6698,
    '872_436': 31.7182,
    '872_437': 31.776,
    '872_438': 31.82,
    '872_439': 31.8525,
    '872_440': 31.8762,
    '872_441': 31.9017,
    '872_442': 31.9353,
    '872_443': 31.9713,
    '872_444': 32.0073,
    '872_445': 32.0437,
    '872_446': 32.0819,
    '872_447': 32.1198,
    '872_448': 32.1543,
    '872_449': 32.1822,
    '872_450': 32.2086,
    '872_451': 32.2337,
    '872_452': 32.2597,
    '872_453': 32.2861,
    '872_454': 32.3154,
    '872_455': 32.3453,
    '872_456': 32.3772,
    '872_457': 32.4176,
    '872_458': 32.4699,
    '872_459': 32.5328,
    '872_460': 32.5985,
    '872_461': 32.6909,
    '872_462': 32.7845,
    '872_463': 32.8614,
    '872_464': 32.9207,
    '872_465': 32.9598,
    '872_466': 32.9878,
    '872_467': 33.0162,
    '872_468': 33.0472,
    '872_469': 33.0711,
    '872_470': 33.0879,
    '872_471': 33.0905,
    '872_472': 33.101,
    '872_473': 33.1269,
    '872_474': 33.1732,
    '872_475': 33.2401,
    '872_476': 33.3246,
    '872_477': 33.4001,
    '872_478': 33.4808,
    '872_479': 33.5493,
    '872_480': 33.6084,
    '872_481': 33.6855,
    '872_482': 33.7625,
    '872_483': 33.8247,
    '872_484': 33.8704,
    '872_485': 33.892,
    '872_486': 33.8956,
    '872_487': 33.886,
    '872_488': 33.8657,
    '872_489': 33.837,
    '872_490': 33.8237,
    '872_491': 33.8126,
    '872_492': 33.788,
    '872_493': 33.7629,
    '872_494': 33.7707,
    '872_495': 33.7725,
    '872_496': 33.7674,
    '872_497': 33.7751,
    '872_498': 33.7997,
    '872_499': 33.8247,
    '872_500': 33.8614,
    '872_501': 33.9067,
    '872_502': 33.9842,
    '872_503': 34.0664,
    '872_504': 34.1136,
    '872_505': 34.1467,
    '872_506': 34.1897,
    '872_507': 34.2608,
    '872_508': 34.3451,
    '872_509': 34.409,
    '872_510': 34.456,
    '872_511': 34.4946,
    '872_512': 34.5414,
    '872_513': 34.5949,
    '872_514': 34.6455,
    '872_515': 34.694,
    '872_516': 34.7431,
    '872_517': 34.794,
    '872_518': 34.8479,
    '872_519': 34.9012,
    '872_520': 34.9541,
    '872_521': 35.0043,
    '872_522': 35.0545,
    '872_523': 35.097,
    '872_524': 35.1223,
    '872_525': 35.1338,
    '872_526': 35.1487,
    '872_527': 35.1708,
    '872_528': 35.1977,
    '872_529': 35.2232,
    '872_530': 35.2377,
    '872_531': 35.2607,
    '872_532': 35.2912,
    '872_533': 35.3232,
    '872_534': 35.3546,
    '872_535': 35.3961,
    '872_536': 35.4452,
    '872_537': 35.4844,
    '872_538': 35.5221,
    '872_539': 35.5648,
    '872_540': 35.603,
    '872_541': 35.6496,
    '872_542': 35.6946,
    '872_543': 35.7293,
    '872_544': 35.766,
    '872_545': 35.8008,
    '872_546': 35.8313,
    '872_547': 35.8619,
    '872_548': 35.8966,
    '872_549': 35.9367,
    '872_550': 35.9811,
    '872_551': 36.0322,
    '872_552': 36.0806,
    '872_553': 36.1159,
    '872_554': 36.1523,
    '872_555': 36.1757,
    '872_556': 36.1994,
    '872_557': 36.2361,
    '872_558': 36.2755,
    '872_559': 36.3037,
    '872_560': 36.3307,
    '872_561': 36.3649,
    '872_562': 36.4028,
    '872_563': 36.4432,
    '872_564': 36.4849,
    '872_565': 36.528,
    '872_566': 36.5711,
    '872_567': 36.6163,
    '872_568': 36.6635,
    '872_569': 36.7143,
    '872_570': 36.7751,
    '872_571': 36.8311,
    '872_572': 36.8578,
    '872_573': 36.8596,
    '872_574': 36.825,
    '872_575': 36.7921,
    '872_576': 36.7737,
    '872_577': 36.7603,
    '872_578': 36.7472,
    '872_579': 36.7384,
    '872_580': 36.7315,
    '872_581': 36.7263,
    '872_582': 36.7231,
    '872_583': 36.7221,
    '872_584': 36.7233,
    '872_585': 36.7268,
    '872_586': 36.7345,
    '872_587': 36.7462,
    '872_588': 36.7643,
    '872_589': 36.7848,
    '872_590': 36.8049,
    '872_591': 36.8254,
    '872_592': 36.8457,
    '872_593': 36.8665,
    '872_594': 36.8953,
    '872_595': 36.9282,
    '872_596': 36.9641,
    '872_597': 37.0046,
    '872_598': 37.0259,
    '872_599': 37.0067,
    '872_600': 36.9609,
    '872_601': 36.9268,
    '872_602': 36.9129,
    '872_603': 36.9146,
    '872_604': 36.926,
    '872_605': 36.9467,
    '872_606': 36.9781,
    '872_607': 37.0209,
    '872_608': 37.0737,
    '872_609': 37.1343,
    '872_610': 37.2,
    '872_611': 37.269,
    '872_612': 37.3424,
    '872_613': 37.4222,
    '872_614': 37.5089,
    '872_615': 37.6006,
    '872_616': 37.6921,
    '872_617': 37.7799,
    '872_618': 37.8709,
    '872_619': 37.9655,
    '872_620': 38.0638,
    '872_621': 38.1655,
    '872_622': 38.2672,
    '872_623': 38.3728,
    '872_624': 38.4844,
    '872_625': 38.6076,
    '872_626': 38.7444,
    '872_627': 38.859,
    '872_628': 38.9393,
    '872_629': 39.015,
    '872_630': 39.0916,
    '872_631': 39.1777,
    '872_632': 39.2703,
    '872_633': 39.3743,
    '872_634': 39.4977,
    '872_635': 39.6392,
    '872_636': 39.7555,
    '872_637': 39.8585,
    '872_638': 39.9443,
    '872_639': 40.0137,
    '872_640': 40.0808,
    '872_641': 40.1409,
    '872_642': 40.1896,
    '872_643': 40.2325,
    '872_644': 40.2545,
    '872_645': 40.2556,
    '872_646': 40.2471,
    '872_647': 40.2309,
    '872_648': 40.1913,
    '872_649': 40.1391,
    '872_650': 40.0614,
    '872_651': 39.9911,
    '872_652': 39.9249,
    '872_653': 39.8437,
    '872_654': 39.758,
    '872_655': 39.6608,
    '872_656': 39.5565,
    '872_657': 39.4447,
    '872_658': 39.3176,
    '872_659': 39.186,
    '872_660': 39.0802,
    '872_661': 38.9899,
    '872_662': 38.9133,
    '872_663': 38.8458,
    '872_664': 38.7921,
    '872_665': 38.7458,
    '872_666': 38.7044,
    '872_667': 38.6678,
    '872_668': 38.6372,
    '872_669': 38.6123,
    '872_670': 38.5949,
    '872_671': 38.5826,
    '872_672': 38.5755,
    '872_673': 38.5763,
    '872_674': 38.5807,
    '872_675': 38.5876,
    '872_676': 38.5938,
    '872_677': 38.6,
    '872_678': 38.6097,
    '872_679': 38.6228,
    '872_680': 38.637,
    '872_681': 38.6549,
    '872_682': 38.6723,
    '872_683': 38.6887,
    '872_684': 38.7037,
    '872_685': 38.7172,
    '872_686': 38.7315,
    '872_687': 38.7454,
    '872_688': 38.7592,
    '872_689': 38.7711,
    '872_690': 38.7815,
    '872_691': 38.7894,
    '872_692': 38.7935,
    '872_693': 38.7958,
    '872_694': 38.797,
    '872_695': 38.7967,
    '872_696': 38.7947,
    '872_697': 38.7903,
    '872_698': 38.7818,
    '872_699': 38.7651,
    '872_700': 38.7468,
    '872_701': 38.7321,
    '872_702': 38.724,
    '872_703': 38.7199,
    '872_704': 38.7161,
    '872_705': 38.7086,
    '872_706': 38.6982,
    '872_707': 38.6888,
    '872_708': 38.6828,
    '872_709': 38.678,
    '872_710': 38.6734,
    '872_711': 38.6687,
    '872_712': 38.6648,
    '872_713': 38.6612,
    '872_714': 38.6601,
    '872_715': 38.6645,
    '872_716': 38.6763,
    '872_717': 38.6992,
    '872_718': 38.7374,
    '872_719': 38.7876,
    '872_720': 38.8482,
    '872_721': 38.9197,
    '872_722': 38.9942,
    '872_723': 39.0678,
    '872_724': 39.1337,
    '872_725': 39.1972,
    '872_726': 39.26,
    '872_727': 39.3121,
    '872_728': 39.3532,
    '872_729': 39.3858,
    '872_730': 39.3988,
    '872_731': 39.3881,
    '872_732': 39.3548,
    '872_733': 39.3033,
    '872_734': 39.2376,
    '872_735': 39.1206,
    '872_736': 38.9948,
    '872_737': 38.8572,
    '872_738': 38.7277,
    '872_739': 38.6117,
    '872_740': 38.5079,
    '872_741': 38.4587,
    '872_742': 38.4549,
    '872_743': 38.494,
    '872_744': 38.5873,
    '872_745': 38.7235,
    '872_746': 38.8953,
    '872_747': 39.0813,
    '872_748': 39.2706,
    '872_749': 39.4407,
    '872_750': 39.5923,
    '872_751': 39.742,
    '872_752': 39.8575,
    '872_753': 39.9455,
    '872_754': 40.009,
    '872_755': 40.0576,
    '872_756': 40.0782,
    '872_757': 40.0972,
    '872_758': 40.0949,
    '872_759': 40.0923,
    '872_760': 40.0924,
    '872_761': 40.0931,
    '872_762': 40.0988,
    '872_763': 40.0883,
    '872_764': 40.078,
    '872_769': 40.2654,
    '872_770': 40.2752,
    '872_771': 40.2735,
    '872_772': 40.2394,
    '872_773': 40.1937,
    '872_774': 40.1689,
    '873_364': 29.3399,
    '873_365': 29.3713,
    '873_366': 29.4021,
    '873_367': 29.4452,
    '873_368': 29.5189,
    '873_369': 29.6251,
    '873_370': 29.76,
    '873_371': 29.9142,
    '873_372': 30.0745,
    '873_373': 30.2269,
    '873_374': 30.3615,
    '873_375': 30.4711,
    '873_376': 30.5626,
    '873_377': 30.6274,
    '873_378': 30.6565,
    '873_379': 30.6735,
    '873_380': 30.686,
    '873_381': 30.6987,
    '873_382': 30.7105,
    '873_420': 30.8956,
    '873_421': 30.9332,
    '873_422': 30.9745,
    '873_423': 31.0189,
    '873_424': 31.0672,
    '873_425': 31.118,
    '873_426': 31.1705,
    '873_427': 31.2239,
    '873_428': 31.2774,
    '873_429': 31.3264,
    '873_430': 31.373,
    '873_431': 31.4224,
    '873_432': 31.4739,
    '873_433': 31.527,
    '873_434': 31.5757,
    '873_435': 31.6194,
    '873_436': 31.6689,
    '873_437': 31.7256,
    '873_438': 31.776,
    '873_439': 31.8125,
    '873_440': 31.8395,
    '873_441': 31.8734,
    '873_442': 31.9105,
    '873_443': 31.9476,
    '873_444': 31.9842,
    '873_445': 32.0213,
    '873_446': 32.0594,
    '873_447': 32.098,
    '873_448': 32.1331,
    '873_449': 32.1646,
    '873_450': 32.1941,
    '873_451': 32.2232,
    '873_452': 32.2519,
    '873_453': 32.2813,
    '873_454': 32.3123,
    '873_455': 32.3433,
    '873_456': 32.3714,
    '873_457': 32.4052,
    '873_458': 32.4526,
    '873_459': 32.509,
    '873_460': 32.5736,
    '873_461': 32.6581,
    '873_462': 32.7548,
    '873_463': 32.8445,
    '873_464': 32.9036,
    '873_465': 32.9359,
    '873_466': 32.962,
    '873_467': 32.9902,
    '873_468': 33.0307,
    '873_469': 33.0623,
    '873_470': 33.0781,
    '873_471': 33.0798,
    '873_472': 33.0899,
    '873_473': 33.1162,
    '873_474': 33.1688,
    '873_475': 33.2388,
    '873_476': 33.3185,
    '873_477': 33.3904,
    '873_478': 33.4677,
    '873_479': 33.5404,
    '873_480': 33.5988,
    '873_481': 33.6738,
    '873_482': 33.7605,
    '873_483': 33.8383,
    '873_484': 33.8975,
    '873_485': 33.9354,
    '873_486': 33.9436,
    '873_487': 33.9276,
    '873_488': 33.9008,
    '873_489': 33.8716,
    '873_490': 33.8557,
    '873_491': 33.8548,
    '873_492': 33.8406,
    '873_493': 33.8178,
    '873_494': 33.8143,
    '873_495': 33.8209,
    '873_496': 33.8301,
    '873_497': 33.8531,
    '873_498': 33.8707,
    '873_499': 33.9084,
    '873_500': 33.9478,
    '873_501': 33.9838,
    '873_502': 34.0403,
    '873_503': 34.1125,
    '873_504': 34.1635,
    '873_505': 34.1927,
    '873_506': 34.2255,
    '873_507': 34.2869,
    '873_508': 34.3761,
    '873_509': 34.4465,
    '873_510': 34.5023,
    '873_511': 34.5421,
    '873_512': 34.5841,
    '873_513': 34.635,
    '873_514': 34.6826,
    '873_515': 34.7323,
    '873_516': 34.7845,
    '873_517': 34.8332,
    '873_518': 34.88,
    '873_519': 34.9307,
    '873_520': 34.9836,
    '873_521': 35.034,
    '873_522': 35.0875,
    '873_523': 35.1318,
    '873_524': 35.155,
    '873_525': 35.1702,
    '873_526': 35.1917,
    '873_527': 35.2159,
    '873_528': 35.2291,
    '873_529': 35.2494,
    '873_530': 35.258,
    '873_531': 35.2835,
    '873_532': 35.3168,
    '873_533': 35.3472,
    '873_534': 35.38,
    '873_535': 35.4186,
    '873_536': 35.462,
    '873_537': 35.5071,
    '873_538': 35.5501,
    '873_539': 35.5941,
    '873_540': 35.6335,
    '873_541': 35.6768,
    '873_542': 35.7272,
    '873_543': 35.7631,
    '873_544': 35.7966,
    '873_545': 35.8249,
    '873_546': 35.8454,
    '873_547': 35.8707,
    '873_548': 35.9024,
    '873_549': 35.9404,
    '873_550': 35.9784,
    '873_551': 36.0227,
    '873_552': 36.0618,
    '873_553': 36.089,
    '873_554': 36.1181,
    '873_555': 36.143,
    '873_556': 36.172,
    '873_557': 36.2099,
    '873_558': 36.2561,
    '873_559': 36.294,
    '873_560': 36.3167,
    '873_561': 36.349,
    '873_562': 36.3841,
    '873_563': 36.4237,
    '873_564': 36.4647,
    '873_565': 36.5065,
    '873_566': 36.5473,
    '873_567': 36.5884,
    '873_568': 36.63,
    '873_569': 36.6715,
    '873_570': 36.7144,
    '873_571': 36.7538,
    '873_572': 36.7797,
    '873_573': 36.7954,
    '873_574': 36.7784,
    '873_575': 36.7601,
    '873_576': 36.7477,
    '873_577': 36.738,
    '873_578': 36.7279,
    '873_579': 36.7213,
    '873_580': 36.716,
    '873_581': 36.7121,
    '873_582': 36.7098,
    '873_583': 36.7093,
    '873_584': 36.7106,
    '873_585': 36.7138,
    '873_586': 36.7207,
    '873_587': 36.7318,
    '873_588': 36.7496,
    '873_589': 36.7705,
    '873_590': 36.7908,
    '873_591': 36.8095,
    '873_592': 36.827,
    '873_593': 36.8456,
    '873_594': 36.8686,
    '873_595': 36.8961,
    '873_596': 36.9279,
    '873_597': 36.9643,
    '873_598': 37.0016,
    '873_599': 37.0148,
    '873_600': 36.9821,
    '873_601': 36.9422,
    '873_602': 36.9222,
    '873_603': 36.92,
    '873_604': 36.9266,
    '873_605': 36.9405,
    '873_606': 36.9659,
    '873_607': 37.0032,
    '873_608': 37.0501,
    '873_609': 37.1042,
    '873_610': 37.1637,
    '873_611': 37.2274,
    '873_612': 37.2964,
    '873_613': 37.3717,
    '873_614': 37.4535,
    '873_615': 37.5398,
    '873_616': 37.6268,
    '873_617': 37.7134,
    '873_618': 37.8024,
    '873_619': 37.8956,
    '873_620': 37.9913,
    '873_621': 38.0877,
    '873_622': 38.1843,
    '873_623': 38.2869,
    '873_624': 38.3985,
    '873_625': 38.5272,
    '873_626': 38.6644,
    '873_627': 38.7754,
    '873_628': 38.8603,
    '873_629': 38.9374,
    '873_630': 39.0125,
    '873_631': 39.0972,
    '873_632': 39.1899,
    '873_633': 39.2946,
    '873_634': 39.4271,
    '873_635': 39.581,
    '873_636': 39.7026,
    '873_637': 39.8068,
    '873_638': 39.8944,
    '873_639': 39.9526,
    '873_640': 40.0015,
    '873_641': 40.0507,
    '873_642': 40.0985,
    '873_643': 40.1432,
    '873_644': 40.1671,
    '873_645': 40.177,
    '873_646': 40.1756,
    '873_647': 40.1625,
    '873_648': 40.1295,
    '873_649': 40.0817,
    '873_650': 40.0035,
    '873_651': 39.9297,
    '873_652': 39.865,
    '873_653': 39.7754,
    '873_654': 39.6857,
    '873_655': 39.591,
    '873_656': 39.4875,
    '873_657': 39.3844,
    '873_658': 39.2662,
    '873_659': 39.131,
    '873_660': 39.0223,
    '873_661': 38.935,
    '873_662': 38.8629,
    '873_663': 38.7993,
    '873_664': 38.7451,
    '873_665': 38.702,
    '873_666': 38.6633,
    '873_667': 38.63,
    '873_668': 38.6028,
    '873_669': 38.5819,
    '873_670': 38.5662,
    '873_671': 38.5531,
    '873_672': 38.5472,
    '873_673': 38.5494,
    '873_674': 38.5569,
    '873_675': 38.5674,
    '873_676': 38.5789,
    '873_677': 38.5911,
    '873_678': 38.606,
    '873_679': 38.6229,
    '873_680': 38.6414,
    '873_681': 38.6637,
    '873_682': 38.6837,
    '873_683': 38.7038,
    '873_684': 38.7227,
    '873_685': 38.7403,
    '873_686': 38.7581,
    '873_687': 38.774,
    '873_688': 38.7894,
    '873_689': 38.8035,
    '873_690': 38.817,
    '873_691': 38.8285,
    '873_692': 38.8378,
    '873_693': 38.8457,
    '873_694': 38.8519,
    '873_695': 38.8539,
    '873_696': 38.8535,
    '873_697': 38.8505,
    '873_698': 38.843,
    '873_699': 38.8293,
    '873_700': 38.8135,
    '873_701': 38.8012,
    '873_702': 38.7978,
    '873_703': 38.7982,
    '873_704': 38.7987,
    '873_705': 38.7963,
    '873_706': 38.791,
    '873_707': 38.7849,
    '873_708': 38.7783,
    '873_709': 38.7713,
    '873_710': 38.7638,
    '873_711': 38.755,
    '873_712': 38.7445,
    '873_713': 38.7341,
    '873_714': 38.7278,
    '873_715': 38.7288,
    '873_716': 38.7382,
    '873_717': 38.7585,
    '873_718': 38.7956,
    '873_719': 38.8438,
    '873_720': 38.9021,
    '873_721': 38.97,
    '873_722': 39.0421,
    '873_723': 39.1147,
    '873_724': 39.1784,
    '873_725': 39.2389,
    '873_726': 39.2977,
    '873_727': 39.3524,
    '873_728': 39.3926,
    '873_729': 39.4212,
    '873_730': 39.4234,
    '873_731': 39.41,
    '873_732': 39.3837,
    '873_733': 39.3297,
    '873_734': 39.2558,
    '873_735': 39.1469,
    '873_736': 39.0395,
    '873_737': 38.9275,
    '873_738': 38.8098,
    '873_739': 38.6936,
    '873_740': 38.5851,
    '873_741': 38.5322,
    '873_742': 38.5215,
    '873_743': 38.5558,
    '873_744': 38.647,
    '873_745': 38.781,
    '873_746': 38.9497,
    '873_747': 39.1343,
    '873_748': 39.3194,
    '873_749': 39.4881,
    '873_750': 39.6411,
    '873_751': 39.7878,
    '873_752': 39.9038,
    '873_753': 39.9948,
    '873_754': 40.0635,
    '873_755': 40.1105,
    '873_756': 40.1382,
    '873_757': 40.1542,
    '873_758': 40.1458,
    '873_759': 40.1204,
    '873_760': 40.1024,
    '873_761': 40.0926,
    '873_762': 40.0788,
    '873_763': 40.0629,
    '873_764': 40.047,
    '873_769': 40.2141,
    '873_770': 40.2217,
    '873_771': 40.2147,
    '873_772': 40.1907,
    '873_773': 40.1584,
    '873_774': 40.1339,
    '874_364': 29.3199,
    '874_365': 29.3525,
    '874_366': 29.3799,
    '874_367': 29.4182,
    '874_368': 29.4837,
    '874_369': 29.5856,
    '874_370': 29.7158,
    '874_371': 29.87,
    '874_372': 30.0345,
    '874_373': 30.1898,
    '874_374': 30.3249,
    '874_375': 30.4391,
    '874_376': 30.5254,
    '874_377': 30.5942,
    '874_378': 30.6301,
    '874_379': 30.6509,
    '874_380': 30.6639,
    '874_381': 30.6782,
    '874_382': 30.6915,
    '874_420': 30.8822,
    '874_421': 30.9218,
    '874_422': 30.9657,
    '874_423': 31.0096,
    '874_424': 31.055,
    '874_425': 31.1013,
    '874_426': 31.1482,
    '874_427': 31.1954,
    '874_428': 31.2429,
    '874_429': 31.2884,
    '874_430': 31.3357,
    '874_431': 31.3835,
    '874_432': 31.4315,
    '874_433': 31.4813,
    '874_434': 31.5254,
    '874_435': 31.5694,
    '874_436': 31.6183,
    '874_437': 31.673,
    '874_438': 31.7254,
    '874_439': 31.7738,
    '874_440': 31.8102,
    '874_441': 31.8511,
    '874_442': 31.8904,
    '874_443': 31.9275,
    '874_444': 31.9635,
    '874_445': 31.9995,
    '874_446': 32.0363,
    '874_447': 32.0747,
    '874_448': 32.112,
    '874_449': 32.1475,
    '874_450': 32.1816,
    '874_451': 32.214,
    '874_452': 32.2438,
    '874_453': 32.2744,
    '874_454': 32.306,
    '874_455': 32.3351,
    '874_456': 32.357,
    '874_457': 32.3918,
    '874_458': 32.4382,
    '874_459': 32.4922,
    '874_460': 32.5543,
    '874_461': 32.6284,
    '874_462': 32.7183,
    '874_463': 32.8184,
    '874_464': 32.8858,
    '874_465': 32.9201,
    '874_466': 32.9396,
    '874_467': 32.9662,
    '874_468': 33.0115,
    '874_469': 33.0483,
    '874_470': 33.0636,
    '874_471': 33.0681,
    '874_472': 33.0787,
    '874_473': 33.1122,
    '874_474': 33.167,
    '874_475': 33.2331,
    '874_476': 33.3054,
    '874_477': 33.3774,
    '874_478': 33.4546,
    '874_479': 33.5292,
    '874_480': 33.5913,
    '874_481': 33.664,
    '874_482': 33.7615,
    '874_483': 33.8524,
    '874_484': 33.9296,
    '874_485': 33.9758,
    '874_486': 33.9857,
    '874_487': 33.9701,
    '874_488': 33.9349,
    '874_489': 33.9047,
    '874_490': 33.8874,
    '874_491': 33.8868,
    '874_492': 33.8838,
    '874_493': 33.8727,
    '874_494': 33.88,
    '874_495': 33.8838,
    '874_496': 33.9041,
    '874_497': 33.9292,
    '874_498': 33.9459,
    '874_499': 33.9924,
    '874_500': 34.0407,
    '874_501': 34.0751,
    '874_502': 34.1136,
    '874_503': 34.1604,
    '874_504': 34.2066,
    '874_505': 34.2385,
    '874_506': 34.2722,
    '874_507': 34.3175,
    '874_508': 34.3975,
    '874_509': 34.4696,
    '874_510': 34.5373,
    '874_511': 34.5836,
    '874_512': 34.6203,
    '874_513': 34.6654,
    '874_514': 34.7161,
    '874_515': 34.7692,
    '874_516': 34.8195,
    '874_517': 34.8662,
    '874_518': 34.9093,
    '874_519': 34.9553,
    '874_520': 35.0046,
    '874_521': 35.0555,
    '874_522': 35.1066,
    '874_523': 35.1503,
    '874_524': 35.1766,
    '874_525': 35.2007,
    '874_526': 35.2279,
    '874_527': 35.255,
    '874_528': 35.2716,
    '874_529': 35.2804,
    '874_530': 35.2923,
    '874_531': 35.3218,
    '874_532': 35.3615,
    '874_533': 35.3912,
    '874_534': 35.4216,
    '874_535': 35.4561,
    '874_536': 35.4948,
    '874_537': 35.5329,
    '874_538': 35.5736,
    '874_539': 35.6162,
    '874_540': 35.6581,
    '874_541': 35.702,
    '874_542': 35.76,
    '874_543': 35.8054,
    '874_544': 35.8362,
    '874_545': 35.8582,
    '874_546': 35.8665,
    '874_547': 35.8838,
    '874_548': 35.907,
    '874_549': 35.9389,
    '874_550': 35.9716,
    '874_551': 36.007,
    '874_552': 36.0388,
    '874_553': 36.0639,
    '874_554': 36.0893,
    '874_555': 36.1166,
    '874_556': 36.1473,
    '874_557': 36.1861,
    '874_558': 36.2336,
    '874_559': 36.2795,
    '874_560': 36.3098,
    '874_561': 36.3382,
    '874_562': 36.3692,
    '874_563': 36.4073,
    '874_564': 36.4478,
    '874_565': 36.4882,
    '874_566': 36.5265,
    '874_567': 36.5643,
    '874_568': 36.6009,
    '874_569': 36.6359,
    '874_570': 36.6683,
    '874_571': 36.696,
    '874_572': 36.7166,
    '874_573': 36.7303,
    '874_574': 36.7321,
    '874_575': 36.7263,
    '874_576': 36.7199,
    '874_577': 36.7141,
    '874_578': 36.7072,
    '874_579': 36.7029,
    '874_580': 36.6994,
    '874_581': 36.6968,
    '874_582': 36.6956,
    '874_583': 36.6958,
    '874_584': 36.6975,
    '874_585': 36.7009,
    '874_586': 36.7078,
    '874_587': 36.7185,
    '874_588': 36.7354,
    '874_589': 36.7556,
    '874_590': 36.7761,
    '874_591': 36.7938,
    '874_592': 36.8097,
    '874_593': 36.8274,
    '874_594': 36.8459,
    '874_595': 36.8686,
    '874_596': 36.8975,
    '874_597': 36.9323,
    '874_598': 36.9737,
    '874_599': 37.0098,
    '874_600': 37.001,
    '874_601': 36.9645,
    '874_602': 36.935,
    '874_603': 36.9283,
    '874_604': 36.9305,
    '874_605': 36.9392,
    '874_606': 36.9585,
    '874_607': 36.9897,
    '874_608': 37.03,
    '874_609': 37.0774,
    '874_610': 37.1307,
    '874_611': 37.1895,
    '874_612': 37.2542,
    '874_613': 37.3246,
    '874_614': 37.4006,
    '874_615': 37.4812,
    '874_616': 37.5638,
    '874_617': 37.6481,
    '874_618': 37.7357,
    '874_619': 37.8275,
    '874_620': 37.9216,
    '874_621': 38.0137,
    '874_622': 38.1054,
    '874_623': 38.2046,
    '874_624': 38.3161,
    '874_625': 38.4503,
    '874_626': 38.5899,
    '874_627': 38.7047,
    '874_628': 38.7894,
    '874_629': 38.8661,
    '874_630': 38.939,
    '874_631': 39.0189,
    '874_632': 39.1107,
    '874_633': 39.2168,
    '874_634': 39.3517,
    '874_635': 39.5135,
    '874_636': 39.6434,
    '874_637': 39.749,
    '874_638': 39.8325,
    '874_639': 39.8862,
    '874_640': 39.9255,
    '874_641': 39.9599,
    '874_642': 39.9982,
    '874_643': 40.0377,
    '874_644': 40.0662,
    '874_645': 40.0849,
    '874_646': 40.0916,
    '874_647': 40.0834,
    '874_648': 40.0601,
    '874_649': 40.0169,
    '874_650': 39.9416,
    '874_651': 39.8572,
    '874_652': 39.7891,
    '874_653': 39.7043,
    '874_654': 39.6079,
    '874_655': 39.5178,
    '874_656': 39.4052,
    '874_657': 39.3016,
    '874_658': 39.1942,
    '874_659': 39.0645,
    '874_660': 38.9603,
    '874_661': 38.8792,
    '874_662': 38.8103,
    '874_663': 38.7497,
    '874_664': 38.6995,
    '874_665': 38.6577,
    '874_666': 38.6218,
    '874_667': 38.5913,
    '874_668': 38.5674,
    '874_669': 38.5499,
    '874_670': 38.5354,
    '874_671': 38.5249,
    '874_672': 38.5215,
    '874_673': 38.5259,
    '874_674': 38.5354,
    '874_675': 38.549,
    '874_676': 38.5646,
    '874_677': 38.5826,
    '874_678': 38.6035,
    '874_679': 38.6221,
    '874_680': 38.6444,
    '874_681': 38.6714,
    '874_682': 38.6954,
    '874_683': 38.7188,
    '874_684': 38.7416,
    '874_685': 38.764,
    '874_686': 38.7847,
    '874_687': 38.8031,
    '874_688': 38.8207,
    '874_689': 38.8377,
    '874_690': 38.8531,
    '874_691': 38.8673,
    '874_692': 38.8801,
    '874_693': 38.8917,
    '874_694': 38.9011,
    '874_695': 38.907,
    '874_696': 38.9095,
    '874_697': 38.9091,
    '874_698': 38.9049,
    '874_699': 38.8933,
    '874_700': 38.8798,
    '874_701': 38.8701,
    '874_702': 38.8719,
    '874_703': 38.8775,
    '874_704': 38.8828,
    '874_705': 38.8848,
    '874_706': 38.8837,
    '874_707': 38.881,
    '874_708': 38.8743,
    '874_709': 38.8663,
    '874_710': 38.8579,
    '874_711': 38.8474,
    '874_712': 38.8298,
    '874_713': 38.8121,
    '874_714': 38.8005,
    '874_715': 38.8,
    '874_716': 38.809,
    '874_717': 38.8284,
    '874_718': 38.8636,
    '874_719': 38.9081,
    '874_720': 38.9607,
    '874_721': 39.0255,
    '874_722': 39.0912,
    '874_723': 39.1584,
    '874_724': 39.2221,
    '874_725': 39.2824,
    '874_726': 39.3418,
    '874_727': 39.3968,
    '874_728': 39.4321,
    '874_729': 39.453,
    '874_730': 39.4541,
    '874_731': 39.4329,
    '874_732': 39.4062,
    '874_733': 39.3552,
    '874_734': 39.28,
    '874_735': 39.1865,
    '874_736': 39.0963,
    '874_737': 39.005,
    '874_738': 38.8982,
    '874_739': 38.7834,
    '874_740': 38.6689,
    '874_741': 38.6141,
    '874_742': 38.5972,
    '874_743': 38.6256,
    '874_744': 38.7156,
    '874_745': 38.8488,
    '874_746': 39.0131,
    '874_747': 39.195,
    '874_748': 39.3761,
    '874_749': 39.5454,
    '874_750': 39.6896,
    '874_751': 39.8292,
    '874_752': 39.9463,
    '874_753': 40.0386,
    '874_754': 40.1076,
    '874_755': 40.1539,
    '874_756': 40.1796,
    '874_757': 40.1872,
    '874_758': 40.1735,
    '874_759': 40.143,
    '874_760': 40.1115,
    '874_761': 40.0897,
    '874_762': 40.0656,
    '874_763': 40.0424,
    '874_764': 40.0218,
    '874_769': 40.1699,
    '874_770': 40.1718,
    '874_771': 40.1605,
    '874_772': 40.1419,
    '874_773': 40.1182,
    '874_774': 40.0933,
    '875_364': 29.3033,
    '875_365': 29.3333,
    '875_366': 29.3568,
    '875_367': 29.3919,
    '875_368': 29.4521,
    '875_369': 29.5478,
    '875_370': 29.6748,
    '875_371': 29.8237,
    '875_372': 29.9856,
    '875_373': 30.1453,
    '875_374': 30.2782,
    '875_375': 30.3897,
    '875_376': 30.484,
    '875_377': 30.5538,
    '875_378': 30.5973,
    '875_379': 30.6244,
    '875_380': 30.6382,
    '875_381': 30.6535,
    '875_382': 30.6696,
    '875_420': 30.8708,
    '875_421': 30.9137,
    '875_422': 30.9585,
    '875_423': 31.0024,
    '875_424': 31.0454,
    '875_425': 31.0876,
    '875_426': 31.1288,
    '875_427': 31.1696,
    '875_428': 31.212,
    '875_429': 31.2564,
    '875_430': 31.3035,
    '875_431': 31.3506,
    '875_432': 31.3944,
    '875_433': 31.438,
    '875_434': 31.4762,
    '875_435': 31.5237,
    '875_436': 31.5712,
    '875_437': 31.6221,
    '875_438': 31.6744,
    '875_439': 31.7315,
    '875_440': 31.7884,
    '875_441': 31.834,
    '875_442': 31.8734,
    '875_443': 31.9094,
    '875_444': 31.9441,
    '875_445': 31.9788,
    '875_446': 32.014,
    '875_447': 32.0529,
    '875_448': 32.0923,
    '875_449': 32.1314,
    '875_450': 32.1693,
    '875_451': 32.2035,
    '875_452': 32.234,
    '875_453': 32.264,
    '875_454': 32.2942,
    '875_455': 32.3199,
    '875_456': 32.3405,
    '875_457': 32.379,
    '875_458': 32.4263,
    '875_459': 32.4799,
    '875_460': 32.5403,
    '875_461': 32.609,
    '875_462': 32.6907,
    '875_463': 32.7877,
    '875_464': 32.8603,
    '875_465': 32.9,
    '875_466': 32.9218,
    '875_467': 32.945,
    '875_468': 32.9842,
    '875_469': 33.0213,
    '875_470': 33.0439,
    '875_471': 33.054,
    '875_472': 33.0677,
    '875_473': 33.1065,
    '875_474': 33.161,
    '875_475': 33.2275,
    '875_476': 33.292,
    '875_477': 33.3627,
    '875_478': 33.443,
    '875_479': 33.517,
    '875_480': 33.5905,
    '875_481': 33.6709,
    '875_482': 33.769,
    '875_483': 33.8658,
    '875_484': 33.9506,
    '875_485': 34.0055,
    '875_486': 34.0267,
    '875_487': 34.0123,
    '875_488': 33.9807,
    '875_489': 33.954,
    '875_490': 33.9423,
    '875_491': 33.9353,
    '875_492': 33.929,
    '875_493': 33.9291,
    '875_494': 33.9484,
    '875_495': 33.9538,
    '875_496': 33.977,
    '875_497': 33.9965,
    '875_498': 34.0208,
    '875_499': 34.0658,
    '875_500': 34.1199,
    '875_501': 34.1571,
    '875_502': 34.1861,
    '875_503': 34.211,
    '875_504': 34.2496,
    '875_505': 34.2792,
    '875_506': 34.3116,
    '875_507': 34.3501,
    '875_508': 34.4149,
    '875_509': 34.4816,
    '875_510': 34.5551,
    '875_511': 34.6103,
    '875_512': 34.6518,
    '875_513': 34.6953,
    '875_514': 34.7453,
    '875_515': 34.7955,
    '875_516': 34.8445,
    '875_517': 34.8892,
    '875_518': 34.9307,
    '875_519': 34.974,
    '875_520': 35.0205,
    '875_521': 35.0693,
    '875_522': 35.1164,
    '875_523': 35.1593,
    '875_524': 35.1944,
    '875_525': 35.2281,
    '875_526': 35.2537,
    '875_527': 35.2823,
    '875_528': 35.3134,
    '875_529': 35.3321,
    '875_530': 35.3468,
    '875_531': 35.3747,
    '875_532': 35.4191,
    '875_533': 35.4519,
    '875_534': 35.4805,
    '875_535': 35.5095,
    '875_536': 35.5463,
    '875_537': 35.5737,
    '875_538': 35.6053,
    '875_539': 35.6427,
    '875_540': 35.6825,
    '875_541': 35.7284,
    '875_542': 35.7838,
    '875_543': 35.8352,
    '875_544': 35.8596,
    '875_545': 35.8812,
    '875_546': 35.8932,
    '875_547': 35.9018,
    '875_548': 35.9149,
    '875_549': 35.9364,
    '875_550': 35.9632,
    '875_551': 35.9932,
    '875_552': 36.0215,
    '875_553': 36.0453,
    '875_554': 36.071,
    '875_555': 36.0981,
    '875_556': 36.1281,
    '875_557': 36.1657,
    '875_558': 36.2088,
    '875_559': 36.2542,
    '875_560': 36.2925,
    '875_561': 36.3255,
    '875_562': 36.3568,
    '875_563': 36.3968,
    '875_564': 36.4378,
    '875_565': 36.4756,
    '875_566': 36.5105,
    '875_567': 36.5449,
    '875_568': 36.5772,
    '875_569': 36.6067,
    '875_570': 36.6332,
    '875_571': 36.6558,
    '875_572': 36.6734,
    '875_573': 36.6855,
    '875_574': 36.6925,
    '875_575': 36.6938,
    '875_576': 36.6925,
    '875_577': 36.6903,
    '875_578': 36.6866,
    '875_579': 36.6846,
    '875_580': 36.6827,
    '875_581': 36.6816,
    '875_582': 36.6815,
    '875_583': 36.6828,
    '875_584': 36.6853,
    '875_585': 36.6893,
    '875_586': 36.6965,
    '875_587': 36.707,
    '875_588': 36.7233,
    '875_589': 36.7414,
    '875_590': 36.7603,
    '875_591': 36.7774,
    '875_592': 36.7939,
    '875_593': 36.811,
    '875_594': 36.8279,
    '875_595': 36.8479,
    '875_596': 36.8735,
    '875_597': 36.9059,
    '875_598': 36.9465,
    '875_599': 36.9883,
    '875_600': 37.0005,
    '875_601': 36.9761,
    '875_602': 36.9517,
    '875_603': 36.94,
    '875_604': 36.9383,
    '875_605': 36.9432,
    '875_606': 36.9572,
    '875_607': 36.982,
    '875_608': 37.0155,
    '875_609': 37.0561,
    '875_610': 37.1035,
    '875_611': 37.157,
    '875_612': 37.2163,
    '875_613': 37.281,
    '875_614': 37.3508,
    '875_615': 37.4256,
    '875_616': 37.5033,
    '875_617': 37.5843,
    '875_618': 37.6698,
    '875_619': 37.76,
    '875_620': 37.8522,
    '875_621': 37.9414,
    '875_622': 38.0301,
    '875_623': 38.1256,
    '875_624': 38.2352,
    '875_625': 38.3713,
    '875_626': 38.5249,
    '875_627': 38.6377,
    '875_628': 38.721,
    '875_629': 38.7962,
    '875_630': 38.867,
    '875_631': 38.9434,
    '875_632': 39.0318,
    '875_633': 39.1379,
    '875_634': 39.2748,
    '875_635': 39.4363,
    '875_636': 39.5711,
    '875_637': 39.6747,
    '875_638': 39.754,
    '875_639': 39.8092,
    '875_640': 39.8503,
    '875_641': 39.8786,
    '875_642': 39.9013,
    '875_643': 39.927,
    '875_644': 39.9598,
    '875_645': 39.9793,
    '875_646': 39.9908,
    '875_647': 39.9907,
    '875_648': 39.978,
    '875_649': 39.9451,
    '875_650': 39.879,
    '875_651': 39.7852,
    '875_652': 39.6977,
    '875_653': 39.6145,
    '875_654': 39.523,
    '875_655': 39.4302,
    '875_656': 39.3165,
    '875_657': 39.2064,
    '875_658': 39.1036,
    '875_659': 38.9866,
    '875_660': 38.8916,
    '875_661': 38.8163,
    '875_662': 38.7516,
    '875_663': 38.696,
    '875_664': 38.6499,
    '875_665': 38.6122,
    '875_666': 38.5809,
    '875_667': 38.5529,
    '875_668': 38.5303,
    '875_669': 38.5152,
    '875_670': 38.5049,
    '875_671': 38.4993,
    '875_672': 38.4984,
    '875_673': 38.5042,
    '875_674': 38.5147,
    '875_675': 38.5303,
    '875_676': 38.5488,
    '875_677': 38.5701,
    '875_678': 38.5958,
    '875_679': 38.6214,
    '875_680': 38.6458,
    '875_681': 38.6755,
    '875_682': 38.7031,
    '875_683': 38.73,
    '875_684': 38.7579,
    '875_685': 38.7859,
    '875_686': 38.8102,
    '875_687': 38.834,
    '875_688': 38.8529,
    '875_689': 38.8733,
    '875_690': 38.8912,
    '875_691': 38.9066,
    '875_692': 38.9217,
    '875_693': 38.9359,
    '875_694': 38.9482,
    '875_695': 38.9575,
    '875_696': 38.9632,
    '875_697': 38.9655,
    '875_698': 38.964,
    '875_699': 38.9572,
    '875_700': 38.9484,
    '875_701': 38.9428,
    '875_702': 38.9488,
    '875_703': 38.9584,
    '875_704': 38.968,
    '875_705': 38.9725,
    '875_706': 38.9739,
    '875_707': 38.9732,
    '875_708': 38.9675,
    '875_709': 38.9594,
    '875_710': 38.9502,
    '875_711': 38.9383,
    '875_712': 38.9161,
    '875_713': 38.8934,
    '875_714': 38.8772,
    '875_715': 38.8784,
    '875_716': 38.8898,
    '875_717': 38.9108,
    '875_718': 38.9432,
    '875_719': 38.9847,
    '875_720': 39.0345,
    '875_721': 39.0918,
    '875_722': 39.1463,
    '875_723': 39.2074,
    '875_724': 39.2696,
    '875_725': 39.3291,
    '875_726': 39.385,
    '875_727': 39.433,
    '875_728': 39.4646,
    '875_729': 39.4776,
    '875_730': 39.4665,
    '875_731': 39.4402,
    '875_732': 39.4183,
    '875_733': 39.3778,
    '875_734': 39.3115,
    '875_735': 39.2332,
    '875_736': 39.1618,
    '875_737': 39.089,
    '875_738': 38.9932,
    '875_739': 38.8846,
    '875_740': 38.7706,
    '875_741': 38.7054,
    '875_742': 38.6832,
    '875_743': 38.7075,
    '875_744': 38.7926,
    '875_745': 38.9222,
    '875_746': 39.0833,
    '875_747': 39.2646,
    '875_748': 39.4393,
    '875_749': 39.6172,
    '875_750': 39.761,
    '875_751': 39.8888,
    '875_752': 39.9985,
    '875_753': 40.0873,
    '875_754': 40.1538,
    '875_755': 40.1967,
    '875_756': 40.2172,
    '875_757': 40.2182,
    '875_758': 40.2005,
    '875_759': 40.1671,
    '875_760': 40.1267,
    '875_761': 40.0892,
    '875_762': 40.0566,
    '875_763': 40.0219,
    '875_764': 39.9979,
    '876_364': 29.2854,
    '876_365': 29.3062,
    '876_366': 29.3273,
    '876_367': 29.3603,
    '876_368': 29.4148,
    '876_369': 29.5073,
    '876_370': 29.6295,
    '876_371': 29.7729,
    '876_372': 29.9312,
    '876_373': 30.0915,
    '876_374': 30.2204,
    '876_375': 30.3351,
    '876_376': 30.4356,
    '876_377': 30.5067,
    '876_378': 30.5561,
    '876_379': 30.5897,
    '876_380': 30.6088,
    '876_381': 30.6262,
    '876_382': 30.6439,
    '876_420': 30.8724,
    '876_421': 30.9139,
    '876_422': 30.9565,
    '876_423': 30.9986,
    '876_424': 31.0392,
    '876_425': 31.0778,
    '876_426': 31.1142,
    '876_427': 31.1506,
    '876_428': 31.1872,
    '876_429': 31.2301,
    '876_430': 31.2745,
    '876_431': 31.3183,
    '876_432': 31.3593,
    '876_433': 31.3967,
    '876_434': 31.4365,
    '876_435': 31.4792,
    '876_436': 31.5247,
    '876_437': 31.571,
    '876_438': 31.6223,
    '876_439': 31.6867,
    '876_440': 31.753,
    '876_441': 31.8099,
    '876_442': 31.8529,
    '876_443': 31.8898,
    '876_444': 31.9245,
    '876_445': 31.9592,
    '876_446': 31.9953,
    '876_447': 32.0335,
    '876_448': 32.0743,
    '876_449': 32.1168,
    '876_450': 32.1596,
    '876_451': 32.1945,
    '876_452': 32.225,
    '876_453': 32.2517,
    '876_454': 32.2768,
    '876_455': 32.3032,
    '876_456': 32.3336,
    '876_457': 32.3721,
    '876_458': 32.4172,
    '876_459': 32.4697,
    '876_460': 32.5292,
    '876_461': 32.5962,
    '876_462': 32.6736,
    '876_463': 32.7626,
    '876_464': 32.8243,
    '876_465': 32.8675,
    '876_466': 32.903,
    '876_467': 32.928,
    '876_468': 32.9597,
    '876_469': 32.9943,
    '876_470': 33.0184,
    '876_471': 33.0374,
    '876_472': 33.0554,
    '876_473': 33.0957,
    '876_474': 33.1472,
    '876_475': 33.2131,
    '876_476': 33.2813,
    '876_477': 33.3563,
    '876_478': 33.4307,
    '876_479': 33.5156,
    '876_480': 33.6015,
    '876_481': 33.6827,
    '876_482': 33.7775,
    '876_483': 33.8725,
    '876_484': 33.9652,
    '876_485': 34.0282,
    '876_486': 34.0555,
    '876_487': 34.0496,
    '876_488': 34.0317,
    '876_489': 34.0149,
    '876_490': 34.0104,
    '876_491': 34.0029,
    '876_492': 33.9884,
    '876_493': 33.9912,
    '876_494': 34.0163,
    '876_495': 34.0254,
    '876_496': 34.0392,
    '876_497': 34.0606,
    '876_498': 34.091,
    '876_499': 34.1375,
    '876_500': 34.1858,
    '876_501': 34.2243,
    '876_502': 34.2465,
    '876_503': 34.2686,
    '876_504': 34.2912,
    '876_505': 34.3214,
    '876_506': 34.3529,
    '876_507': 34.3887,
    '876_508': 34.4315,
    '876_509': 34.4893,
    '876_510': 34.5616,
    '876_511': 34.6269,
    '876_512': 34.678,
    '876_513': 34.7191,
    '876_514': 34.7625,
    '876_515': 34.8097,
    '876_516': 34.8586,
    '876_517': 34.9021,
    '876_518': 34.9443,
    '876_519': 34.9894,
    '876_520': 35.0367,
    '876_521': 35.0856,
    '876_522': 35.1324,
    '876_523': 35.1732,
    '876_524': 35.2114,
    '876_525': 35.2503,
    '876_526': 35.2827,
    '876_527': 35.3142,
    '876_528': 35.3561,
    '876_529': 35.3894,
    '876_530': 35.4105,
    '876_531': 35.4398,
    '876_532': 35.4913,
    '876_533': 35.527,
    '876_534': 35.5544,
    '876_535': 35.5783,
    '876_536': 35.6051,
    '876_537': 35.6269,
    '876_538': 35.6524,
    '876_539': 35.6836,
    '876_540': 35.7204,
    '876_541': 35.7584,
    '876_542': 35.7998,
    '876_543': 35.8453,
    '876_544': 35.8706,
    '876_545': 35.893,
    '876_546': 35.9153,
    '876_547': 35.9195,
    '876_548': 35.9243,
    '876_549': 35.9375,
    '876_550': 35.9579,
    '876_551': 35.9829,
    '876_552': 36.0097,
    '876_553': 36.0348,
    '876_554': 36.0606,
    '876_555': 36.0872,
    '876_556': 36.1165,
    '876_557': 36.1515,
    '876_558': 36.1905,
    '876_559': 36.2298,
    '876_560': 36.2698,
    '876_561': 36.3089,
    '876_562': 36.3453,
    '876_563': 36.3875,
    '876_564': 36.432,
    '876_565': 36.4704,
    '876_566': 36.4998,
    '876_567': 36.5308,
    '876_568': 36.5589,
    '876_569': 36.5832,
    '876_570': 36.6055,
    '876_571': 36.6247,
    '876_572': 36.6402,
    '876_573': 36.6518,
    '876_574': 36.6599,
    '876_575': 36.6644,
    '876_576': 36.6664,
    '876_577': 36.6672,
    '876_578': 36.6663,
    '876_579': 36.6665,
    '876_580': 36.6663,
    '876_581': 36.6665,
    '876_582': 36.6677,
    '876_583': 36.6702,
    '876_584': 36.6737,
    '876_585': 36.6786,
    '876_586': 36.6869,
    '876_587': 36.6981,
    '876_588': 36.7131,
    '876_589': 36.7285,
    '876_590': 36.7446,
    '876_591': 36.761,
    '876_592': 36.7776,
    '876_593': 36.7945,
    '876_594': 36.8119,
    '876_595': 36.8327,
    '876_596': 36.8571,
    '876_597': 36.8876,
    '876_598': 36.9225,
    '876_599': 36.9571,
    '876_600': 36.9811,
    '876_601': 36.9753,
    '876_602': 36.9627,
    '876_603': 36.9521,
    '876_604': 36.9503,
    '876_605': 36.9537,
    '876_606': 36.9632,
    '876_607': 36.9811,
    '876_608': 37.0075,
    '876_609': 37.041,
    '876_610': 37.0819,
    '876_611': 37.1294,
    '876_612': 37.1825,
    '876_613': 37.2407,
    '876_614': 37.3042,
    '876_615': 37.3731,
    '876_616': 37.4464,
    '876_617': 37.5233,
    '876_618': 37.6056,
    '876_619': 37.6935,
    '876_620': 37.7831,
    '876_621': 37.8697,
    '876_622': 37.9566,
    '876_623': 38.0498,
    '876_624': 38.1563,
    '876_625': 38.2897,
    '876_626': 38.4562,
    '876_627': 38.5759,
    '876_628': 38.6534,
    '876_629': 38.727,
    '876_630': 38.7955,
    '876_631': 38.8687,
    '876_632': 38.9536,
    '876_633': 39.0583,
    '876_634': 39.1945,
    '876_635': 39.3564,
    '876_636': 39.4931,
    '876_637': 39.5937,
    '876_638': 39.6676,
    '876_639': 39.7234,
    '876_640': 39.7655,
    '876_641': 39.7924,
    '876_642': 39.8105,
    '876_643': 39.8214,
    '876_644': 39.8452,
    '876_645': 39.8665,
    '876_646': 39.8823,
    '876_647': 39.8897,
    '876_648': 39.8849,
    '876_649': 39.861,
    '876_650': 39.8053,
    '876_651': 39.712,
    '876_652': 39.6072,
    '876_653': 39.5179,
    '876_654': 39.4287,
    '876_655': 39.33,
    '876_656': 39.225,
    '876_657': 39.1115,
    '876_658': 39.004,
    '876_659': 38.9013,
    '876_660': 38.8165,
    '876_661': 38.7472,
    '876_662': 38.6875,
    '876_663': 38.6358,
    '876_664': 38.5947,
    '876_665': 38.5627,
    '876_666': 38.5366,
    '876_667': 38.5116,
    '876_668': 38.4926,
    '876_669': 38.4791,
    '876_670': 38.4714,
    '876_671': 38.469,
    '876_672': 38.4721,
    '876_673': 38.4813,
    '876_674': 38.4942,
    '876_675': 38.5118,
    '876_676': 38.5326,
    '876_677': 38.5573,
    '876_678': 38.5858,
    '876_679': 38.6181,
    '876_680': 38.644,
    '876_681': 38.6742,
    '876_682': 38.7077,
    '876_683': 38.7408,
    '876_684': 38.7754,
    '876_685': 38.8139,
    '876_686': 38.8452,
    '876_687': 38.8607,
    '876_688': 38.8808,
    '876_689': 38.9089,
    '876_690': 38.9288,
    '876_691': 38.9457,
    '876_692': 38.9625,
    '876_693': 38.9791,
    '876_694': 38.9941,
    '876_695': 39.0064,
    '876_696': 39.0154,
    '876_697': 39.0213,
    '876_698': 39.0239,
    '876_699': 39.0229,
    '876_700': 39.0204,
    '876_701': 39.0205,
    '876_702': 39.028,
    '876_703': 39.0387,
    '876_704': 39.0494,
    '876_705': 39.0563,
    '876_706': 39.0599,
    '876_707': 39.0608,
    '876_708': 39.0565,
    '876_709': 39.0485,
    '876_710': 39.0379,
    '876_711': 39.0233,
    '876_712': 39.0024,
    '876_713': 38.9802,
    '876_714': 38.9643,
    '876_715': 38.9658,
    '876_716': 38.9777,
    '876_717': 38.9985,
    '876_718': 39.0282,
    '876_719': 39.0658,
    '876_720': 39.1102,
    '876_721': 39.1596,
    '876_722': 39.2105,
    '876_723': 39.266,
    '876_724': 39.3233,
    '876_725': 39.3788,
    '876_726': 39.4297,
    '876_727': 39.4714,
    '876_728': 39.4996,
    '876_729': 39.5072,
    '876_730': 39.4817,
    '876_731': 39.4448,
    '876_732': 39.434,
    '876_733': 39.4097,
    '876_734': 39.3486,
    '876_735': 39.2797,
    '876_736': 39.2192,
    '876_737': 39.1592,
    '876_738': 39.0839,
    '876_739': 38.9911,
    '876_740': 38.8636,
    '876_741': 38.8014,
    '876_742': 38.7738,
    '876_743': 38.7926,
    '876_744': 38.8731,
    '876_745': 39.0007,
    '876_746': 39.1645,
    '876_747': 39.346,
    '876_748': 39.5305,
    '876_749': 39.7015,
    '876_750': 39.8384,
    '876_751': 39.9566,
    '876_752': 40.0588,
    '876_753': 40.1439,
    '876_754': 40.2074,
    '876_755': 40.2443,
    '876_756': 40.2583,
    '876_757': 40.2541,
    '876_758': 40.2338,
    '876_759': 40.1986,
    '876_760': 40.1526,
    '876_761': 40.1013,
    '876_762': 40.0552,
    '876_763': 40.0098,
    '876_764': 39.9746,
    '876_765': 39.957,
    '876_766': 39.9262,
    '876_767': 39.9189,
    '876_768': 39.9231,
    '876_769': 39.9243,
    '876_770': 39.9103,
    '877_364': 29.2653,
    '877_365': 29.2802,
    '877_366': 29.2981,
    '877_367': 29.3302,
    '877_368': 29.3792,
    '877_369': 29.4641,
    '877_370': 29.5781,
    '877_371': 29.7149,
    '877_372': 29.8679,
    '877_373': 30.0271,
    '877_374': 30.1609,
    '877_375': 30.2803,
    '877_376': 30.3823,
    '877_377': 30.4583,
    '877_378': 30.5122,
    '877_379': 30.5545,
    '877_380': 30.579,
    '877_381': 30.5995,
    '877_382': 30.6177,
    '877_452': 32.2178,
    '877_453': 32.2466,
    '877_454': 32.2661,
    '877_455': 32.2924,
    '877_456': 32.3268,
    '877_457': 32.3653,
    '877_458': 32.4076,
    '877_459': 32.4588,
    '877_460': 32.5181,
    '877_461': 32.5843,
    '877_462': 32.6572,
    '877_463': 32.7333,
    '877_464': 32.7899,
    '877_465': 32.8364,
    '877_466': 32.8751,
    '877_467': 32.9075,
    '877_468': 32.9497,
    '877_469': 32.976,
    '877_470': 32.9981,
    '877_471': 33.0179,
    '877_472': 33.0375,
    '877_473': 33.0825,
    '877_474': 33.1405,
    '877_475': 33.2055,
    '877_476': 33.2756,
    '877_477': 33.3456,
    '877_478': 33.4251,
    '877_479': 33.5152,
    '877_480': 33.6078,
    '877_481': 33.6954,
    '877_482': 33.7874,
    '877_483': 33.8759,
    '877_484': 33.9702,
    '877_485': 34.0408,
    '877_486': 34.078,
    '877_487': 34.0874,
    '877_488': 34.0833,
    '877_489': 34.0797,
    '877_490': 34.0792,
    '877_491': 34.0711,
    '877_492': 34.0547,
    '877_493': 34.0573,
    '877_494': 34.0813,
    '877_495': 34.0925,
    '877_496': 34.097,
    '877_497': 34.1199,
    '877_498': 34.1544,
    '877_499': 34.2003,
    '877_500': 34.2422,
    '877_501': 34.2763,
    '877_502': 34.2975,
    '877_503': 34.3195,
    '877_504': 34.3327,
    '877_505': 34.354,
    '877_506': 34.3814,
    '877_507': 34.4181,
    '877_508': 34.4558,
    '877_509': 34.5062,
    '877_510': 34.5797,
    '877_511': 34.6497,
    '877_512': 34.701,
    '877_513': 34.7389,
    '877_514': 34.7744,
    '877_515': 34.8171,
    '877_516': 34.8642,
    '877_517': 34.9096,
    '877_518': 34.9554,
    '877_519': 35.0021,
    '877_520': 35.0517,
    '877_521': 35.1024,
    '877_522': 35.1496,
    '877_523': 35.1895,
    '877_524': 35.2269,
    '877_525': 35.272,
    '877_526': 35.32,
    '877_527': 35.357,
    '877_528': 35.4018,
    '877_529': 35.4465,
    '877_530': 35.4763,
    '877_531': 35.5153,
    '877_532': 35.5696,
    '877_533': 35.6111,
    '877_534': 35.6383,
    '877_535': 35.6562,
    '877_536': 35.6724,
    '877_537': 35.6842,
    '877_538': 35.7107,
    '877_539': 35.7491,
    '877_540': 35.7785,
    '877_541': 35.8057,
    '877_542': 35.8305,
    '877_543': 35.8589,
    '877_544': 35.882,
    '877_545': 35.9055,
    '877_546': 35.9195,
    '877_547': 35.922,
    '877_548': 35.928,
    '877_549': 35.9414,
    '877_550': 35.9609,
    '877_551': 35.9814,
    '877_552': 36.006,
    '877_553': 36.0323,
    '877_554': 36.0579,
    '877_555': 36.0821,
    '877_556': 36.1107,
    '877_557': 36.1444,
    '877_558': 36.1809,
    '877_559': 36.2173,
    '877_560': 36.2553,
    '877_561': 36.2951,
    '877_562': 36.3369,
    '877_563': 36.381,
    '877_564': 36.426,
    '877_565': 36.4649,
    '877_566': 36.494,
    '877_567': 36.5203,
    '877_568': 36.5436,
    '877_569': 36.5641,
    '877_570': 36.5829,
    '877_571': 36.5994,
    '877_572': 36.6132,
    '877_573': 36.624,
    '877_574': 36.6324,
    '877_575': 36.6382,
    '877_576': 36.6412,
    '877_577': 36.6442,
    '877_578': 36.6468,
    '877_579': 36.6489,
    '877_580': 36.6505,
    '877_581': 36.652,
    '877_582': 36.6543,
    '877_583': 36.658,
    '877_584': 36.6626,
    '877_585': 36.6684,
    '877_586': 36.6776,
    '877_587': 36.6889,
    '877_588': 36.7029,
    '877_589': 36.7162,
    '877_590': 36.7299,
    '877_591': 36.7457,
    '877_592': 36.7626,
    '877_593': 36.7793,
    '877_594': 36.7977,
    '877_595': 36.8197,
    '877_596': 36.8449,
    '877_597': 36.8735,
    '877_598': 36.9044,
    '877_599': 36.9346,
    '877_600': 36.9598,
    '877_601': 36.9713,
    '877_602': 36.9723,
    '877_603': 36.9681,
    '877_604': 36.9676,
    '877_605': 36.9699,
    '877_606': 36.9765,
    '877_607': 36.9882,
    '877_608': 37.007,
    '877_609': 37.033,
    '877_610': 37.0665,
    '877_611': 37.1068,
    '877_612': 37.1525,
    '877_613': 37.2035,
    '877_614': 37.261,
    '877_615': 37.3248,
    '877_616': 37.3935,
    '877_617': 37.4658,
    '877_618': 37.5441,
    '877_619': 37.6289,
    '877_620': 37.716,
    '877_621': 37.8005,
    '877_622': 37.8851,
    '877_623': 37.9765,
    '877_624': 38.08,
    '877_625': 38.2095,
    '877_626': 38.3771,
    '877_627': 38.5136,
    '877_628': 38.5912,
    '877_629': 38.6663,
    '877_630': 38.7268,
    '877_631': 38.7941,
    '877_632': 38.8746,
    '877_633': 38.9773,
    '877_634': 39.1126,
    '877_635': 39.2721,
    '877_636': 39.4106,
    '877_637': 39.5087,
    '877_638': 39.5772,
    '877_639': 39.6321,
    '877_640': 39.6757,
    '877_641': 39.701,
    '877_642': 39.7198,
    '877_643': 39.7253,
    '877_644': 39.7349,
    '877_645': 39.7549,
    '877_646': 39.7725,
    '877_647': 39.7858,
    '877_648': 39.7847,
    '877_649': 39.7657,
    '877_650': 39.7194,
    '877_651': 39.6297,
    '877_652': 39.5205,
    '877_653': 39.418,
    '877_654': 39.3241,
    '877_655': 39.2239,
    '877_656': 39.1222,
    '877_657': 39.016,
    '877_658': 38.9004,
    '877_659': 38.8109,
    '877_660': 38.7375,
    '877_661': 38.6742,
    '877_662': 38.6189,
    '877_663': 38.5718,
    '877_664': 38.5364,
    '877_665': 38.5107,
    '877_666': 38.488,
    '877_667': 38.4673,
    '877_668': 38.4515,
    '877_669': 38.4394,
    '877_670': 38.4341,
    '877_671': 38.4345,
    '877_672': 38.4414,
    '877_673': 38.4539,
    '877_674': 38.4701,
    '877_675': 38.4907,
    '877_676': 38.5151,
    '877_677': 38.5433,
    '877_678': 38.5751,
    '877_679': 38.6091,
    '877_680': 38.6401,
    '877_681': 38.6699,
    '877_682': 38.707,
    '877_683': 38.7449,
    '877_684': 38.7849,
    '877_685': 38.8299,
    '877_686': 38.8662,
    '877_687': 38.8901,
    '877_688': 38.9138,
    '877_689': 38.9421,
    '877_690': 38.9609,
    '877_691': 38.9782,
    '877_692': 39.0011,
    '877_693': 39.0205,
    '877_694': 39.0389,
    '877_695': 39.0544,
    '877_696': 39.067,
    '877_697': 39.0771,
    '877_698': 39.0849,
    '877_699': 39.0898,
    '877_700': 39.0935,
    '877_701': 39.0986,
    '877_702': 39.1071,
    '877_703': 39.1176,
    '877_704': 39.1286,
    '877_705': 39.1375,
    '877_706': 39.1426,
    '877_707': 39.1445,
    '877_708': 39.1424,
    '877_709': 39.1359,
    '877_710': 39.1254,
    '877_711': 39.1106,
    '877_712': 39.0921,
    '877_713': 39.0736,
    '877_714': 39.0608,
    '877_715': 39.0603,
    '877_716': 39.0701,
    '877_717': 39.0888,
    '877_718': 39.1153,
    '877_719': 39.1486,
    '877_720': 39.1874,
    '877_721': 39.2306,
    '877_722': 39.2773,
    '877_723': 39.3283,
    '877_724': 39.3808,
    '877_725': 39.4322,
    '877_726': 39.4789,
    '877_727': 39.5146,
    '877_728': 39.5371,
    '877_729': 39.539,
    '877_730': 39.516,
    '877_731': 39.4857,
    '877_732': 39.4658,
    '877_733': 39.4392,
    '877_734': 39.3862,
    '877_735': 39.3197,
    '877_736': 39.2661,
    '877_737': 39.2153,
    '877_738': 39.1609,
    '877_739': 39.091,
    '877_740': 38.9805,
    '877_741': 38.9026,
    '877_742': 38.8645,
    '877_743': 38.8762,
    '877_744': 38.9539,
    '877_745': 39.0831,
    '877_746': 39.2531,
    '877_747': 39.4377,
    '877_748': 39.6194,
    '877_749': 39.7796,
    '877_750': 39.9111,
    '877_751': 40.0251,
    '877_752': 40.1239,
    '877_753': 40.2082,
    '877_754': 40.2696,
    '877_755': 40.2975,
    '877_756': 40.3034,
    '877_757': 40.2949,
    '877_758': 40.2736,
    '877_759': 40.2385,
    '877_760': 40.1904,
    '877_761': 40.1301,
    '877_762': 40.0583,
    '877_763': 39.9982,
    '877_764': 39.9493,
    '877_765': 39.9163,
    '877_766': 39.8905,
    '877_767': 39.8732,
    '877_768': 39.8662,
    '877_769': 39.8674,
    '877_770': 39.8573,
    '878_364': 29.2505,
    '878_365': 29.261,
    '878_366': 29.2774,
    '878_367': 29.3055,
    '878_368': 29.3501,
    '878_369': 29.4209,
    '878_370': 29.5208,
    '878_371': 29.6527,
    '878_372': 29.8008,
    '878_373': 29.9568,
    '878_374': 30.0966,
    '878_375': 30.2174,
    '878_376': 30.3225,
    '878_377': 30.4098,
    '878_378': 30.473,
    '878_379': 30.5196,
    '878_380': 30.5499,
    '878_381': 30.5734,
    '878_382': 30.5949,
    '878_452': 32.2065,
    '878_453': 32.2363,
    '878_454': 32.2608,
    '878_455': 32.2888,
    '878_456': 32.3191,
    '878_457': 32.3562,
    '878_458': 32.3956,
    '878_459': 32.4465,
    '878_460': 32.506,
    '878_461': 32.5713,
    '878_462': 32.64,
    '878_463': 32.7086,
    '878_464': 32.7707,
    '878_465': 32.8227,
    '878_466': 32.8544,
    '878_467': 32.8892,
    '878_468': 32.9339,
    '878_469': 32.9588,
    '878_470': 32.9827,
    '878_471': 32.9963,
    '878_472': 33.0186,
    '878_473': 33.0656,
    '878_474': 33.1268,
    '878_475': 33.1926,
    '878_476': 33.2598,
    '878_477': 33.3262,
    '878_478': 33.4122,
    '878_479': 33.5066,
    '878_480': 33.5994,
    '878_481': 33.6954,
    '878_482': 33.7902,
    '878_483': 33.8785,
    '878_484': 33.9679,
    '878_485': 34.046,
    '878_486': 34.0944,
    '878_487': 34.1173,
    '878_488': 34.1298,
    '878_489': 34.136,
    '878_490': 34.1394,
    '878_491': 34.1349,
    '878_492': 34.1224,
    '878_493': 34.1346,
    '878_494': 34.1498,
    '878_495': 34.1568,
    '878_496': 34.1601,
    '878_497': 34.1831,
    '878_498': 34.2166,
    '878_499': 34.2564,
    '878_500': 34.2887,
    '878_501': 34.3129,
    '878_502': 34.3362,
    '878_503': 34.3582,
    '878_504': 34.371,
    '878_505': 34.3833,
    '878_506': 34.407,
    '878_507': 34.4387,
    '878_508': 34.4775,
    '878_509': 34.5237,
    '878_510': 34.5967,
    '878_511': 34.6674,
    '878_512': 34.7155,
    '878_513': 34.7496,
    '878_514': 34.7809,
    '878_515': 34.8217,
    '878_516': 34.8682,
    '878_517': 34.9179,
    '878_518': 34.965,
    '878_519': 35.0118,
    '878_520': 35.0635,
    '878_521': 35.1163,
    '878_522': 35.1669,
    '878_523': 35.2084,
    '878_524': 35.2495,
    '878_525': 35.3008,
    '878_526': 35.3576,
    '878_527': 35.4038,
    '878_528': 35.4477,
    '878_529': 35.4976,
    '878_530': 35.5407,
    '878_531': 35.5872,
    '878_532': 35.6402,
    '878_533': 35.6806,
    '878_534': 35.707,
    '878_535': 35.7206,
    '878_536': 35.7328,
    '878_537': 35.7423,
    '878_538': 35.7731,
    '878_539': 35.8129,
    '878_540': 35.8398,
    '878_541': 35.8636,
    '878_542': 35.8772,
    '878_543': 35.8933,
    '878_544': 35.914,
    '878_545': 35.9332,
    '878_546': 35.9347,
    '878_547': 35.932,
    '878_548': 35.9329,
    '878_549': 35.9472,
    '878_550': 35.9724,
    '878_551': 35.9915,
    '878_552': 36.0129,
    '878_553': 36.0319,
    '878_554': 36.0565,
    '878_555': 36.081,
    '878_556': 36.1095,
    '878_557': 36.1432,
    '878_558': 36.1795,
    '878_559': 36.2148,
    '878_560': 36.2516,
    '878_561': 36.2908,
    '878_562': 36.3348,
    '878_563': 36.3821,
    '878_564': 36.425,
    '878_565': 36.4596,
    '878_566': 36.4875,
    '878_567': 36.5118,
    '878_568': 36.532,
    '878_569': 36.5493,
    '878_570': 36.5648,
    '878_571': 36.5787,
    '878_572': 36.5907,
    '878_573': 36.6007,
    '878_574': 36.6087,
    '878_575': 36.6152,
    '878_576': 36.6199,
    '878_577': 36.6245,
    '878_578': 36.629,
    '878_579': 36.6338,
    '878_580': 36.6365,
    '878_581': 36.6388,
    '878_582': 36.6422,
    '878_583': 36.6471,
    '878_584': 36.6524,
    '878_585': 36.6589,
    '878_586': 36.6683,
    '878_587': 36.6794,
    '878_588': 36.6915,
    '878_589': 36.7041,
    '878_590': 36.7164,
    '878_591': 36.7323,
    '878_592': 36.75,
    '878_593': 36.7664,
    '878_594': 36.7859,
    '878_595': 36.8087,
    '878_596': 36.8345,
    '878_597': 36.8617,
    '878_598': 36.8903,
    '878_599': 36.9193,
    '878_600': 36.9463,
    '878_601': 36.9679,
    '878_602': 36.9834,
    '878_603': 36.9913,
    '878_604': 36.9947,
    '878_605': 36.9926,
    '878_606': 36.9954,
    '878_607': 37.0019,
    '878_608': 37.0138,
    '878_609': 37.0324,
    '878_610': 37.0577,
    '878_611': 37.0898,
    '878_612': 37.1277,
    '878_613': 37.1715,
    '878_614': 37.2226,
    '878_615': 37.2819,
    '878_616': 37.3466,
    '878_617': 37.4149,
    '878_618': 37.4887,
    '878_619': 37.5691,
    '878_620': 37.6534,
    '878_621': 37.7363,
    '878_622': 37.8182,
    '878_623': 37.9064,
    '878_624': 38.007,
    '878_625': 38.1323,
    '878_626': 38.2989,
    '878_627': 38.4479,
    '878_628': 38.5282,
    '878_629': 38.6056,
    '878_630': 38.6582,
    '878_631': 38.7174,
    '878_632': 38.7936,
    '878_633': 38.8947,
    '878_634': 39.0295,
    '878_635': 39.186,
    '878_636': 39.3203,
    '878_637': 39.4165,
    '878_638': 39.4822,
    '878_639': 39.5362,
    '878_640': 39.5803,
    '878_641': 39.6053,
    '878_642': 39.6188,
    '878_643': 39.6267,
    '878_644': 39.6308,
    '878_645': 39.6462,
    '878_646': 39.6646,
    '878_647': 39.6789,
    '878_648': 39.6796,
    '878_649': 39.668,
    '878_650': 39.6311,
    '878_651': 39.5472,
    '878_652': 39.4343,
    '878_653': 39.321,
    '878_654': 39.2206,
    '878_655': 39.1213,
    '878_656': 39.0205,
    '878_657': 38.9201,
    '878_658': 38.8076,
    '878_659': 38.7194,
    '878_660': 38.6554,
    '878_661': 38.5993,
    '878_662': 38.5489,
    '878_663': 38.5059,
    '878_664': 38.4755,
    '878_665': 38.4536,
    '878_666': 38.4372,
    '878_667': 38.419,
    '878_668': 38.4049,
    '878_669': 38.3946,
    '878_670': 38.3923,
    '878_671': 38.3972,
    '878_672': 38.4082,
    '878_673': 38.4245,
    '878_674': 38.4445,
    '878_675': 38.4677,
    '878_676': 38.4952,
    '878_677': 38.527,
    '878_678': 38.5629,
    '878_679': 38.6012,
    '878_680': 38.6393,
    '878_681': 38.6659,
    '878_682': 38.7042,
    '878_683': 38.7464,
    '878_684': 38.7892,
    '878_685': 38.8351,
    '878_686': 38.8763,
    '878_687': 38.9116,
    '878_688': 38.9438,
    '878_689': 38.9759,
    '878_690': 38.9977,
    '878_691': 39.0192,
    '878_692': 39.0389,
    '878_693': 39.0584,
    '878_694': 39.0833,
    '878_695': 39.1012,
    '878_696': 39.1175,
    '878_697': 39.1332,
    '878_698': 39.1465,
    '878_699': 39.1571,
    '878_700': 39.1662,
    '878_701': 39.1753,
    '878_702': 39.1854,
    '878_703': 39.1962,
    '878_704': 39.2081,
    '878_705': 39.2185,
    '878_706': 39.2254,
    '878_707': 39.229,
    '878_708': 39.2286,
    '878_709': 39.2238,
    '878_710': 39.2148,
    '878_711': 39.2004,
    '878_712': 39.1843,
    '878_713': 39.1688,
    '878_714': 39.1577,
    '878_715': 39.1561,
    '878_716': 39.1641,
    '878_717': 39.1806,
    '878_718': 39.2042,
    '878_719': 39.2333,
    '878_720': 39.267,
    '878_721': 39.3045,
    '878_722': 39.3462,
    '878_723': 39.3935,
    '878_724': 39.4423,
    '878_725': 39.4908,
    '878_726': 39.5325,
    '878_727': 39.5628,
    '878_728': 39.5769,
    '878_729': 39.575,
    '878_730': 39.5548,
    '878_731': 39.5266,
    '878_732': 39.4978,
    '878_733': 39.4624,
    '878_734': 39.4131,
    '878_735': 39.3591,
    '878_736': 39.3082,
    '878_737': 39.2623,
    '878_738': 39.2202,
    '878_739': 39.1691,
    '878_740': 39.0886,
    '878_741': 39.002,
    '878_742': 38.9518,
    '878_743': 38.9531,
    '878_744': 39.0332,
    '878_745': 39.1712,
    '878_746': 39.3454,
    '878_747': 39.5304,
    '878_748': 39.6978,
    '878_749': 39.8466,
    '878_750': 39.9766,
    '878_751': 40.093,
    '878_752': 40.1932,
    '878_753': 40.269,
    '878_754': 40.3233,
    '878_755': 40.3497,
    '878_756': 40.3509,
    '878_757': 40.3386,
    '878_758': 40.3173,
    '878_759': 40.2834,
    '878_760': 40.2343,
    '878_761': 40.1695,
    '878_762': 40.077,
    '878_763': 39.994,
    '878_764': 39.9312,
    '878_765': 39.8837,
    '878_766': 39.8507,
    '878_767': 39.8263,
    '878_768': 39.8152,
    '878_769': 39.8118,
    '878_770': 39.8025,
    '878_772': 39.8763,
    '878_773': 39.8413,
    '878_774': 39.8011,
    '878_775': 39.7486,
    '878_776': 39.6779,
    '878_777': 39.5789,
    '878_778': 39.4444,
    '878_779': 39.2691,
    '878_780': 39.0462,
    '879_364': 29.233,
    '879_365': 29.2432,
    '879_366': 29.2596,
    '879_367': 29.2847,
    '879_368': 29.3234,
    '879_369': 29.3827,
    '879_370': 29.4723,
    '879_371': 29.5932,
    '879_372': 29.7322,
    '879_373': 29.8801,
    '879_374': 30.0222,
    '879_375': 30.1454,
    '879_376': 30.2607,
    '879_377': 30.3578,
    '879_378': 30.4307,
    '879_379': 30.482,
    '879_380': 30.5187,
    '879_381': 30.547,
    '879_382': 30.5723,
    '879_452': 32.1901,
    '879_453': 32.2226,
    '879_454': 32.2528,
    '879_455': 32.2816,
    '879_456': 32.312,
    '879_457': 32.3495,
    '879_458': 32.3868,
    '879_459': 32.4351,
    '879_460': 32.494,
    '879_461': 32.559,
    '879_462': 32.6263,
    '879_463': 32.6938,
    '879_464': 32.7625,
    '879_465': 32.8191,
    '879_466': 32.8367,
    '879_467': 32.8687,
    '879_468': 32.9123,
    '879_469': 32.9399,
    '879_470': 32.9579,
    '879_471': 32.9732,
    '879_472': 33.0003,
    '879_473': 33.0461,
    '879_474': 33.1062,
    '879_475': 33.1717,
    '879_476': 33.2376,
    '879_477': 33.3069,
    '879_478': 33.3958,
    '879_479': 33.4869,
    '879_480': 33.5799,
    '879_481': 33.6805,
    '879_482': 33.7783,
    '879_483': 33.8755,
    '879_484': 33.9678,
    '879_485': 34.0523,
    '879_486': 34.1099,
    '879_487': 34.146,
    '879_488': 34.1699,
    '879_489': 34.1829,
    '879_490': 34.1878,
    '879_491': 34.189,
    '879_492': 34.1842,
    '879_493': 34.2027,
    '879_494': 34.2195,
    '879_495': 34.2243,
    '879_496': 34.2314,
    '879_497': 34.2532,
    '879_498': 34.2836,
    '879_499': 34.3143,
    '879_500': 34.3312,
    '879_501': 34.3405,
    '879_502': 34.3638,
    '879_503': 34.3888,
    '879_504': 34.4068,
    '879_505': 34.4197,
    '879_506': 34.4401,
    '879_507': 34.4617,
    '879_508': 34.4963,
    '879_509': 34.5403,
    '879_510': 34.6037,
    '879_511': 34.6722,
    '879_512': 34.719,
    '879_513': 34.7505,
    '879_514': 34.7834,
    '879_515': 34.8262,
    '879_516': 34.8746,
    '879_517': 34.9254,
    '879_518': 34.972,
    '879_519': 35.0201,
    '879_520': 35.0754,
    '879_521': 35.1318,
    '879_522': 35.1851,
    '879_523': 35.2323,
    '879_524': 35.2795,
    '879_525': 35.3333,
    '879_526': 35.3929,
    '879_527': 35.4462,
    '879_528': 35.4994,
    '879_529': 35.5513,
    '879_530': 35.5997,
    '879_531': 35.6516,
    '879_532': 35.6991,
    '879_533': 35.7343,
    '879_534': 35.7566,
    '879_535': 35.7667,
    '879_536': 35.7779,
    '879_537': 35.7972,
    '879_538': 35.8312,
    '879_539': 35.8691,
    '879_540': 35.8981,
    '879_541': 35.9212,
    '879_542': 35.9356,
    '879_543': 35.939,
    '879_544': 35.9441,
    '879_545': 35.9544,
    '879_546': 35.9507,
    '879_547': 35.9449,
    '879_548': 35.9453,
    '879_549': 35.9555,
    '879_550': 35.9775,
    '879_551': 35.9999,
    '879_552': 36.0244,
    '879_553': 36.0387,
    '879_554': 36.0609,
    '879_555': 36.0851,
    '879_556': 36.1131,
    '879_557': 36.1464,
    '879_558': 36.1843,
    '879_559': 36.2191,
    '879_560': 36.2546,
    '879_561': 36.2923,
    '879_562': 36.335,
    '879_563': 36.3776,
    '879_564': 36.4184,
    '879_565': 36.4556,
    '879_566': 36.4874,
    '879_567': 36.5109,
    '879_568': 36.5267,
    '879_569': 36.5391,
    '879_570': 36.5511,
    '879_571': 36.5624,
    '879_572': 36.5726,
    '879_573': 36.5813,
    '879_574': 36.5889,
    '879_575': 36.5955,
    '879_576': 36.6016,
    '879_577': 36.6073,
    '879_578': 36.6138,
    '879_579': 36.6234,
    '879_580': 36.6246,
    '879_581': 36.6272,
    '879_582': 36.6324,
    '879_583': 36.6392,
    '879_584': 36.6438,
    '879_585': 36.6504,
    '879_586': 36.6599,
    '879_587': 36.6707,
    '879_588': 36.6819,
    '879_589': 36.6935,
    '879_590': 36.7057,
    '879_591': 36.7195,
    '879_592': 36.7373,
    '879_593': 36.7555,
    '879_594': 36.7754,
    '879_595': 36.799,
    '879_596': 36.8248,
    '879_597': 36.8517,
    '879_598': 36.8793,
    '879_599': 36.9086,
    '879_600': 36.939,
    '879_601': 36.968,
    '879_602': 36.9926,
    '879_603': 37.0125,
    '879_604': 37.0281,
    '879_605': 37.025,
    '879_606': 37.0212,
    '879_607': 37.0224,
    '879_608': 37.0269,
    '879_609': 37.0382,
    '879_610': 37.0554,
    '879_611': 37.0794,
    '879_612': 37.1095,
    '879_613': 37.1462,
    '879_614': 37.1918,
    '879_615': 37.2462,
    '879_616': 37.3065,
    '879_617': 37.3708,
    '879_618': 37.4402,
    '879_619': 37.5159,
    '879_620': 37.5978,
    '879_621': 37.6794,
    '879_622': 37.7573,
    '879_623': 37.8412,
    '879_624': 37.9382,
    '879_625': 38.0596,
    '879_626': 38.2238,
    '879_627': 38.3806,
    '879_628': 38.4639,
    '879_629': 38.5377,
    '879_630': 38.5861,
    '879_631': 38.6399,
    '879_632': 38.7125,
    '879_633': 38.8108,
    '879_634': 38.9419,
    '879_635': 39.0962,
    '879_636': 39.2234,
    '879_637': 39.3186,
    '879_638': 39.3847,
    '879_639': 39.4364,
    '879_640': 39.4795,
    '879_641': 39.5043,
    '879_642': 39.5197,
    '879_643': 39.5236,
    '879_644': 39.5288,
    '879_645': 39.5394,
    '879_646': 39.5533,
    '879_647': 39.5654,
    '879_648': 39.5707,
    '879_649': 39.5658,
    '879_650': 39.5376,
    '879_651': 39.468,
    '879_652': 39.3587,
    '879_653': 39.2358,
    '879_654': 39.127,
    '879_655': 39.0264,
    '879_656': 38.9269,
    '879_657': 38.8248,
    '879_658': 38.721,
    '879_659': 38.6376,
    '879_660': 38.5756,
    '879_661': 38.5241,
    '879_662': 38.4793,
    '879_663': 38.4408,
    '879_664': 38.4143,
    '879_665': 38.3956,
    '879_666': 38.3834,
    '879_667': 38.3689,
    '879_668': 38.3561,
    '879_669': 38.347,
    '879_670': 38.3478,
    '879_671': 38.3574,
    '879_672': 38.373,
    '879_673': 38.3934,
    '879_674': 38.4163,
    '879_675': 38.4413,
    '879_676': 38.4712,
    '879_677': 38.5059,
    '879_678': 38.545,
    '879_679': 38.5855,
    '879_680': 38.6251,
    '879_681': 38.6589,
    '879_682': 38.7006,
    '879_683': 38.7465,
    '879_684': 38.7928,
    '879_685': 38.8393,
    '879_686': 38.8858,
    '879_687': 38.931,
    '879_688': 38.9697,
    '879_689': 39.0069,
    '879_690': 39.0357,
    '879_691': 39.0594,
    '879_692': 39.0834,
    '879_693': 39.1056,
    '879_694': 39.1269,
    '879_695': 39.1451,
    '879_696': 39.1641,
    '879_697': 39.1891,
    '879_698': 39.2078,
    '879_699': 39.2238,
    '879_700': 39.2375,
    '879_701': 39.2503,
    '879_702': 39.263,
    '879_703': 39.2756,
    '879_704': 39.2883,
    '879_705': 39.2999,
    '879_706': 39.3086,
    '879_707': 39.3141,
    '879_708': 39.3138,
    '879_709': 39.3114,
    '879_710': 39.3049,
    '879_711': 39.2934,
    '879_712': 39.2787,
    '879_713': 39.2631,
    '879_714': 39.2523,
    '879_715': 39.2503,
    '879_716': 39.2578,
    '879_717': 39.2734,
    '879_718': 39.2947,
    '879_719': 39.3205,
    '879_720': 39.3501,
    '879_721': 39.3819,
    '879_722': 39.4176,
    '879_723': 39.4613,
    '879_724': 39.5088,
    '879_725': 39.5527,
    '879_726': 39.5918,
    '879_727': 39.6161,
    '879_728': 39.6214,
    '879_729': 39.6139,
    '879_730': 39.5939,
    '879_731': 39.5649,
    '879_732': 39.5301,
    '879_733': 39.4881,
    '879_734': 39.4386,
    '879_735': 39.3912,
    '879_736': 39.3429,
    '879_737': 39.2996,
    '879_738': 39.2647,
    '879_739': 39.2263,
    '879_740': 39.1696,
    '879_741': 39.0879,
    '879_742': 39.035,
    '879_743': 39.0318,
    '879_744': 39.1071,
    '879_745': 39.2509,
    '879_746': 39.4268,
    '879_747': 39.6093,
    '879_748': 39.7664,
    '879_749': 39.9073,
    '879_750': 40.0356,
    '879_751': 40.1547,
    '879_752': 40.2554,
    '879_753': 40.3249,
    '879_754': 40.3732,
    '879_755': 40.4002,
    '879_756': 40.4022,
    '879_757': 40.3857,
    '879_758': 40.3624,
    '879_759': 40.3279,
    '879_760': 40.2717,
    '879_761': 40.1984,
    '879_762': 40.0999,
    '879_763': 39.9998,
    '879_764': 39.9157,
    '879_765': 39.8555,
    '879_766': 39.8069,
    '879_767': 39.7752,
    '879_768': 39.7606,
    '879_769': 39.7577,
    '879_770': 39.7509,
    '879_772': 39.8464,
    '879_773': 39.8192,
    '879_774': 39.7806,
    '879_775': 39.7277,
    '879_776': 39.6532,
    '879_777': 39.5476,
    '879_778': 39.4052,
    '879_779': 39.2223,
    '879_780': 38.991,
    '880_364': 29.2204,
    '880_365': 29.2312,
    '880_366': 29.2465,
    '880_367': 29.2678,
    '880_368': 29.3014,
    '880_369': 29.3531,
    '880_370': 29.4302,
    '880_371': 29.5366,
    '880_372': 29.6645,
    '880_373': 29.8038,
    '880_374': 29.9428,
    '880_375': 30.0728,
    '880_376': 30.195,
    '880_377': 30.3012,
    '880_378': 30.3839,
    '880_379': 30.4435,
    '880_380': 30.4865,
    '880_381': 30.5208,
    '880_382': 30.5542,
    '880_452': 32.1762,
    '880_453': 32.2082,
    '880_454': 32.24,
    '880_455': 32.2727,
    '880_456': 32.3064,
    '880_457': 32.3425,
    '880_458': 32.381,
    '880_459': 32.4254,
    '880_460': 32.4832,
    '880_461': 32.5474,
    '880_462': 32.6135,
    '880_463': 32.6781,
    '880_464': 32.7388,
    '880_465': 32.7855,
    '880_466': 32.8121,
    '880_467': 32.8445,
    '880_468': 32.8791,
    '880_469': 32.9071,
    '880_470': 32.9272,
    '880_471': 32.9544,
    '880_472': 32.9843,
    '880_473': 33.0286,
    '880_474': 33.0846,
    '880_475': 33.1502,
    '880_476': 33.2169,
    '880_477': 33.2899,
    '880_478': 33.3763,
    '880_479': 33.4639,
    '880_480': 33.5573,
    '880_481': 33.6585,
    '880_482': 33.7609,
    '880_483': 33.8706,
    '880_484': 33.9731,
    '880_485': 34.0615,
    '880_486': 34.1277,
    '880_487': 34.1731,
    '880_488': 34.2062,
    '880_489': 34.2208,
    '880_490': 34.2267,
    '880_491': 34.2322,
    '880_492': 34.2366,
    '880_493': 34.2608,
    '880_494': 34.2842,
    '880_495': 34.2956,
    '880_496': 34.3082,
    '880_497': 34.3259,
    '880_498': 34.3495,
    '880_499': 34.3658,
    '880_500': 34.367,
    '880_501': 34.3707,
    '880_502': 34.3915,
    '880_503': 34.4198,
    '880_504': 34.4427,
    '880_505': 34.4614,
    '880_506': 34.4781,
    '880_507': 34.4961,
    '880_508': 34.5213,
    '880_509': 34.5586,
    '880_510': 34.6062,
    '880_511': 34.6674,
    '880_512': 34.7142,
    '880_513': 34.7497,
    '880_514': 34.7883,
    '880_515': 34.8335,
    '880_516': 34.8836,
    '880_517': 34.9323,
    '880_518': 34.9785,
    '880_519': 35.0296,
    '880_520': 35.087,
    '880_521': 35.1487,
    '880_522': 35.2049,
    '880_523': 35.2558,
    '880_524': 35.3083,
    '880_525': 35.3631,
    '880_526': 35.4254,
    '880_527': 35.488,
    '880_528': 35.5489,
    '880_529': 35.6049,
    '880_530': 35.658,
    '880_531': 35.7069,
    '880_532': 35.7504,
    '880_533': 35.782,
    '880_534': 35.8003,
    '880_535': 35.8115,
    '880_536': 35.8266,
    '880_537': 35.8509,
    '880_538': 35.8834,
    '880_539': 35.9164,
    '880_540': 35.9434,
    '880_541': 35.9683,
    '880_542': 35.9854,
    '880_543': 35.9848,
    '880_544': 35.9706,
    '880_545': 35.9716,
    '880_546': 35.9663,
    '880_547': 35.9617,
    '880_548': 35.9626,
    '880_549': 35.9682,
    '880_550': 35.9852,
    '880_551': 36.007,
    '880_552': 36.0313,
    '880_553': 36.0502,
    '880_554': 36.0745,
    '880_555': 36.0962,
    '880_556': 36.123,
    '880_557': 36.1543,
    '880_558': 36.1913,
    '880_559': 36.2289,
    '880_560': 36.2655,
    '880_561': 36.306,
    '880_562': 36.3414,
    '880_563': 36.3781,
    '880_564': 36.4157,
    '880_565': 36.4558,
    '880_566': 36.4902,
    '880_567': 36.5148,
    '880_568': 36.5269,
    '880_569': 36.5347,
    '880_570': 36.5426,
    '880_571': 36.5511,
    '880_572': 36.5592,
    '880_573': 36.5667,
    '880_574': 36.5733,
    '880_575': 36.5796,
    '880_576': 36.5866,
    '880_577': 36.5927,
    '880_578': 36.5993,
    '880_579': 36.6072,
    '880_580': 36.6123,
    '880_581': 36.6185,
    '880_582': 36.6251,
    '880_583': 36.6339,
    '880_584': 36.6369,
    '880_585': 36.6437,
    '880_586': 36.6532,
    '880_587': 36.6639,
    '880_588': 36.6763,
    '880_589': 36.6875,
    '880_590': 36.6987,
    '880_591': 36.711,
    '880_592': 36.7267,
    '880_593': 36.7454,
    '880_594': 36.7664,
    '880_595': 36.7908,
    '880_596': 36.8173,
    '880_597': 36.8447,
    '880_598': 36.8726,
    '880_599': 36.902,
    '880_600': 36.9329,
    '880_601': 36.9661,
    '880_602': 36.9984,
    '880_603': 37.0305,
    '880_604': 37.0576,
    '880_605': 37.0661,
    '880_606': 37.0569,
    '880_607': 37.0507,
    '880_608': 37.0483,
    '880_609': 37.052,
    '880_610': 37.0608,
    '880_611': 37.0768,
    '880_612': 37.0999,
    '880_613': 37.1308,
    '880_614': 37.1705,
    '880_615': 37.2187,
    '880_616': 37.2738,
    '880_617': 37.3332,
    '880_618': 37.3978,
    '880_619': 37.4691,
    '880_620': 37.5487,
    '880_621': 37.63,
    '880_622': 37.7034,
    '880_623': 37.782,
    '880_624': 37.8742,
    '880_625': 37.9918,
    '880_626': 38.1539,
    '880_627': 38.314,
    '880_628': 38.3933,
    '880_629': 38.4615,
    '880_630': 38.5104,
    '880_631': 38.5638,
    '880_632': 38.6334,
    '880_633': 38.7287,
    '880_634': 38.8558,
    '880_635': 39.0045,
    '880_636': 39.1254,
    '880_637': 39.2181,
    '880_638': 39.286,
    '880_639': 39.3345,
    '880_640': 39.376,
    '880_641': 39.3992,
    '880_642': 39.417,
    '880_643': 39.4222,
    '880_644': 39.429,
    '880_645': 39.4344,
    '880_646': 39.4414,
    '880_647': 39.4494,
    '880_648': 39.4575,
    '880_649': 39.4593,
    '880_650': 39.4438,
    '880_651': 39.3888,
    '880_652': 39.2863,
    '880_653': 39.1635,
    '880_654': 39.0479,
    '880_655': 38.946,
    '880_656': 38.8445,
    '880_657': 38.7407,
    '880_658': 38.6428,
    '880_659': 38.5615,
    '880_660': 38.5013,
    '880_661': 38.4515,
    '880_662': 38.4099,
    '880_663': 38.3748,
    '880_664': 38.3495,
    '880_665': 38.3314,
    '880_666': 38.321,
    '880_667': 38.312,
    '880_668': 38.3011,
    '880_669': 38.2961,
    '880_670': 38.3004,
    '880_671': 38.3151,
    '880_672': 38.3356,
    '880_673': 38.3595,
    '880_674': 38.384,
    '880_675': 38.4104,
    '880_676': 38.4416,
    '880_677': 38.4785,
    '880_678': 38.5203,
    '880_679': 38.5636,
    '880_680': 38.6103,
    '880_681': 38.6523,
    '880_682': 38.6976,
    '880_683': 38.7465,
    '880_684': 38.7977,
    '880_685': 38.8482,
    '880_686': 38.8951,
    '880_687': 38.9438,
    '880_688': 38.9906,
    '880_689': 39.036,
    '880_690': 39.0731,
    '880_691': 39.0978,
    '880_692': 39.1263,
    '880_693': 39.1537,
    '880_694': 39.1779,
    '880_695': 39.1979,
    '880_696': 39.2201,
    '880_697': 39.244,
    '880_698': 39.2664,
    '880_699': 39.2873,
    '880_700': 39.3047,
    '880_701': 39.321,
    '880_702': 39.3366,
    '880_703': 39.3512,
    '880_704': 39.3657,
    '880_705': 39.3789,
    '880_706': 39.3898,
    '880_707': 39.398,
    '880_708': 39.4022,
    '880_709': 39.4015,
    '880_710': 39.3959,
    '880_711': 39.3848,
    '880_712': 39.3711,
    '880_713': 39.3559,
    '880_714': 39.3454,
    '880_715': 39.3436,
    '880_716': 39.3513,
    '880_717': 39.3672,
    '880_718': 39.3874,
    '880_719': 39.4117,
    '880_720': 39.4391,
    '880_721': 39.4663,
    '880_722': 39.4947,
    '880_723': 39.5338,
    '880_724': 39.5786,
    '880_725': 39.6192,
    '880_726': 39.6547,
    '880_727': 39.6745,
    '880_728': 39.6708,
    '880_729': 39.6581,
    '880_730': 39.6358,
    '880_731': 39.6045,
    '880_732': 39.5659,
    '880_733': 39.5199,
    '880_734': 39.4675,
    '880_735': 39.4192,
    '880_736': 39.3735,
    '880_737': 39.3311,
    '880_738': 39.2996,
    '880_739': 39.268,
    '880_740': 39.2243,
    '880_741': 39.1607,
    '880_742': 39.1119,
    '880_743': 39.1042,
    '880_744': 39.1745,
    '880_745': 39.316,
    '880_746': 39.4918,
    '880_747': 39.6692,
    '880_748': 39.8224,
    '880_749': 39.9609,
    '880_750': 40.0893,
    '880_751': 40.2085,
    '880_752': 40.3127,
    '880_753': 40.3862,
    '880_754': 40.4307,
    '880_755': 40.4561,
    '880_756': 40.4552,
    '880_757': 40.4367,
    '880_758': 40.4065,
    '880_759': 40.3655,
    '880_760': 40.3013,
    '880_761': 40.2228,
    '880_762': 40.122,
    '880_763': 40.0164,
    '880_764': 39.9068,
    '880_765': 39.8333,
    '880_766': 39.774,
    '880_767': 39.7333,
    '880_768': 39.7169,
    '880_769': 39.7084,
    '880_770': 39.7105,
    '880_772': 39.8193,
    '880_773': 39.7982,
    '880_774': 39.7673,
    '880_775': 39.718,
    '880_776': 39.6432,
    '880_777': 39.5333,
    '880_778': 39.3789,
    '880_779': 39.1772,
    '880_780': 38.9295,
    '881_364': 29.2095,
    '881_365': 29.2191,
    '881_366': 29.2354,
    '881_367': 29.2523,
    '881_368': 29.2807,
    '881_369': 29.324,
    '881_370': 29.3877,
    '881_371': 29.4811,
    '881_372': 29.6008,
    '881_373': 29.7328,
    '881_374': 29.8693,
    '881_375': 30.0017,
    '881_376': 30.1269,
    '881_377': 30.2405,
    '881_378': 30.3325,
    '881_379': 30.3989,
    '881_380': 30.4495,
    '881_381': 30.4897,
    '881_382': 30.5318,
    '881_452': 32.1613,
    '881_453': 32.1917,
    '881_454': 32.2234,
    '881_455': 32.259,
    '881_456': 32.2954,
    '881_457': 32.3305,
    '881_458': 32.37,
    '881_459': 32.4169,
    '881_460': 32.4754,
    '881_461': 32.5357,
    '881_462': 32.5987,
    '881_463': 32.6583,
    '881_464': 32.7093,
    '881_465': 32.7503,
    '881_466': 32.7842,
    '881_467': 32.8162,
    '881_468': 32.848,
    '881_469': 32.8778,
    '881_470': 32.9053,
    '881_471': 32.9366,
    '881_472': 32.9716,
    '881_473': 33.0157,
    '881_474': 33.0697,
    '881_475': 33.1357,
    '881_476': 33.209,
    '881_477': 33.283,
    '881_478': 33.3637,
    '881_479': 33.4511,
    '881_480': 33.5409,
    '881_481': 33.6392,
    '881_482': 33.7475,
    '881_483': 33.8656,
    '881_484': 33.9767,
    '881_485': 34.0701,
    '881_486': 34.1411,
    '881_487': 34.1944,
    '881_488': 34.2321,
    '881_489': 34.2524,
    '881_490': 34.2578,
    '881_491': 34.2662,
    '881_492': 34.2793,
    '881_493': 34.3075,
    '881_494': 34.3365,
    '881_495': 34.356,
    '881_496': 34.3722,
    '881_497': 34.384,
    '881_498': 34.3995,
    '881_499': 34.4053,
    '881_500': 34.3994,
    '881_501': 34.4055,
    '881_502': 34.4265,
    '881_503': 34.4576,
    '881_504': 34.4862,
    '881_505': 34.505,
    '881_506': 34.516,
    '881_507': 34.5309,
    '881_508': 34.555,
    '881_509': 34.5828,
    '881_510': 34.6161,
    '881_511': 34.6644,
    '881_512': 34.7105,
    '881_513': 34.7517,
    '881_514': 34.796,
    '881_515': 34.8432,
    '881_516': 34.8947,
    '881_517': 34.9439,
    '881_518': 34.9898,
    '881_519': 35.0369,
    '881_520': 35.0956,
    '881_521': 35.1603,
    '881_522': 35.22,
    '881_523': 35.2757,
    '881_524': 35.3347,
    '881_525': 35.3941,
    '881_526': 35.4616,
    '881_527': 35.5302,
    '881_528': 35.5938,
    '881_529': 35.6525,
    '881_530': 35.7055,
    '881_531': 35.752,
    '881_532': 35.7935,
    '881_533': 35.8258,
    '881_534': 35.8446,
    '881_535': 35.8558,
    '881_536': 35.87,
    '881_537': 35.8933,
    '881_538': 35.921,
    '881_539': 35.9463,
    '881_540': 35.9727,
    '881_541': 36.0014,
    '881_542': 36.0158,
    '881_543': 36.0195,
    '881_544': 36.0062,
    '881_545': 36.0066,
    '881_546': 35.9968,
    '881_547': 35.9927,
    '881_548': 35.9915,
    '881_549': 35.9916,
    '881_550': 36.0042,
    '881_551': 36.0209,
    '881_552': 36.0412,
    '881_553': 36.0634,
    '881_554': 36.0906,
    '881_555': 36.1095,
    '881_556': 36.1371,
    '881_557': 36.1685,
    '881_558': 36.2041,
    '881_559': 36.2425,
    '881_560': 36.2818,
    '881_561': 36.3213,
    '881_562': 36.3551,
    '881_563': 36.3865,
    '881_564': 36.4214,
    '881_565': 36.4602,
    '881_566': 36.493,
    '881_567': 36.5215,
    '881_568': 36.5322,
    '881_569': 36.5359,
    '881_570': 36.5384,
    '881_571': 36.5446,
    '881_572': 36.5509,
    '881_573': 36.5567,
    '881_574': 36.5616,
    '881_575': 36.567,
    '881_576': 36.5745,
    '881_577': 36.5806,
    '881_578': 36.5871,
    '881_579': 36.594,
    '881_580': 36.6007,
    '881_581': 36.6079,
    '881_582': 36.6149,
    '881_583': 36.6233,
    '881_584': 36.63,
    '881_585': 36.6381,
    '881_586': 36.6484,
    '881_587': 36.6593,
    '881_588': 36.6712,
    '881_589': 36.6829,
    '881_590': 36.6941,
    '881_591': 36.7066,
    '881_592': 36.7215,
    '881_593': 36.7393,
    '881_594': 36.7593,
    '881_595': 36.7835,
    '881_596': 36.8116,
    '881_597': 36.8403,
    '881_598': 36.8687,
    '881_599': 36.8992,
    '881_600': 36.9344,
    '881_601': 36.9692,
    '881_602': 37.0056,
    '881_603': 37.0488,
    '881_604': 37.0836,
    '881_605': 37.1066,
    '881_606': 37.1046,
    '881_607': 37.0935,
    '881_608': 37.0823,
    '881_609': 37.0762,
    '881_610': 37.076,
    '881_611': 37.0831,
    '881_612': 37.0987,
    '881_613': 37.1229,
    '881_614': 37.156,
    '881_615': 37.1974,
    '881_616': 37.2463,
    '881_617': 37.3005,
    '881_618': 37.3606,
    '881_619': 37.4272,
    '881_620': 37.5038,
    '881_621': 37.5854,
    '881_622': 37.6549,
    '881_623': 37.7279,
    '881_624': 37.8143,
    '881_625': 37.9253,
    '881_626': 38.0791,
    '881_627': 38.2375,
    '881_628': 38.3161,
    '881_629': 38.3813,
    '881_630': 38.4357,
    '881_631': 38.4876,
    '881_632': 38.5554,
    '881_633': 38.6483,
    '881_634': 38.7718,
    '881_635': 38.908,
    '881_636': 39.0216,
    '881_637': 39.1123,
    '881_638': 39.1827,
    '881_639': 39.2275,
    '881_640': 39.2663,
    '881_641': 39.2877,
    '881_642': 39.3047,
    '881_643': 39.315,
    '881_644': 39.323,
    '881_645': 39.3285,
    '881_646': 39.3316,
    '881_647': 39.3365,
    '881_648': 39.3469,
    '881_649': 39.3534,
    '881_650': 39.3499,
    '881_651': 39.3119,
    '881_652': 39.2219,
    '881_653': 39.1021,
    '881_654': 38.9824,
    '881_655': 38.874,
    '881_656': 38.7691,
    '881_657': 38.6676,
    '881_658': 38.5722,
    '881_659': 38.4924,
    '881_660': 38.4317,
    '881_661': 38.3812,
    '881_662': 38.3404,
    '881_663': 38.307,
    '881_664': 38.2823,
    '881_665': 38.2644,
    '881_666': 38.2545,
    '881_667': 38.247,
    '881_668': 38.2406,
    '881_669': 38.2415,
    '881_670': 38.2514,
    '881_671': 38.2703,
    '881_672': 38.2943,
    '881_673': 38.3198,
    '881_674': 38.346,
    '881_675': 38.3727,
    '881_676': 38.4037,
    '881_677': 38.4418,
    '881_678': 38.4865,
    '881_679': 38.5346,
    '881_680': 38.5899,
    '881_681': 38.6401,
    '881_682': 38.6924,
    '881_683': 38.7479,
    '881_684': 38.809,
    '881_685': 38.8667,
    '881_686': 38.9077,
    '881_687': 38.9548,
    '881_688': 39.0039,
    '881_689': 39.0531,
    '881_690': 39.1017,
    '881_691': 39.1379,
    '881_692': 39.1688,
    '881_693': 39.2019,
    '881_694': 39.2272,
    '881_695': 39.2498,
    '881_696': 39.2734,
    '881_697': 39.3019,
    '881_698': 39.3298,
    '881_699': 39.3566,
    '881_700': 39.3794,
    '881_701': 39.3983,
    '881_702': 39.4145,
    '881_703': 39.4302,
    '881_704': 39.4461,
    '881_705': 39.461,
    '881_706': 39.4736,
    '881_707': 39.4834,
    '881_708': 39.4898,
    '881_709': 39.4908,
    '881_710': 39.4858,
    '881_711': 39.4762,
    '881_712': 39.4636,
    '881_713': 39.449,
    '881_714': 39.4389,
    '881_715': 39.4372,
    '881_716': 39.4451,
    '881_717': 39.4616,
    '881_718': 39.4824,
    '881_719': 39.5078,
    '881_720': 39.5357,
    '881_721': 39.5588,
    '881_722': 39.5788,
    '881_723': 39.6121,
    '881_724': 39.6509,
    '881_725': 39.6905,
    '881_726': 39.7223,
    '881_727': 39.734,
    '881_728': 39.7244,
    '881_729': 39.707,
    '881_730': 39.6809,
    '881_731': 39.6463,
    '881_732': 39.6042,
    '881_733': 39.5552,
    '881_734': 39.4954,
    '881_735': 39.4427,
    '881_736': 39.3994,
    '881_737': 39.3567,
    '881_738': 39.3297,
    '881_739': 39.3063,
    '881_740': 39.2716,
    '881_741': 39.2257,
    '881_742': 39.1854,
    '881_743': 39.1746,
    '881_744': 39.2323,
    '881_745': 39.3697,
    '881_746': 39.5439,
    '881_747': 39.7154,
    '881_748': 39.8669,
    '881_749': 40.0077,
    '881_750': 40.1439,
    '881_751': 40.271,
    '881_752': 40.3738,
    '881_753': 40.4493,
    '881_754': 40.4991,
    '881_755': 40.515,
    '881_756': 40.5114,
    '881_757': 40.489,
    '881_758': 40.4503,
    '881_759': 40.3954,
    '881_760': 40.3209,
    '881_761': 40.2333,
    '881_762': 40.1291,
    '881_763': 40.0165,
    '881_764': 39.9032,
    '881_765': 39.8105,
    '881_766': 39.7496,
    '881_767': 39.7016,
    '881_768': 39.676,
    '881_769': 39.67,
    '881_770': 39.6779,
    '881_772': 39.803,
    '881_773': 39.7885,
    '881_774': 39.7625,
    '881_775': 39.7172,
    '881_776': 39.6453,
    '881_777': 39.5324,
    '881_778': 39.3595,
    '881_779': 39.1343,
    '881_780': 38.8816,
    '882_364': 29.2021,
    '882_365': 29.2095,
    '882_366': 29.2254,
    '882_367': 29.2393,
    '882_368': 29.2626,
    '882_369': 29.2996,
    '882_370': 29.3549,
    '882_371': 29.4361,
    '882_372': 29.5459,
    '882_373': 29.6713,
    '882_374': 29.8036,
    '882_375': 29.9353,
    '882_376': 30.0616,
    '882_377': 30.1771,
    '882_378': 30.2759,
    '882_379': 30.3527,
    '882_380': 30.415,
    '882_381': 30.465,
    '882_382': 30.5007,
    '882_452': 32.1442,
    '882_453': 32.1726,
    '882_454': 32.2028,
    '882_455': 32.2362,
    '882_456': 32.2715,
    '882_457': 32.3087,
    '882_458': 32.3516,
    '882_459': 32.4013,
    '882_460': 32.4602,
    '882_461': 32.5203,
    '882_462': 32.5798,
    '882_463': 32.6357,
    '882_464': 32.6799,
    '882_465': 32.7182,
    '882_466': 32.7557,
    '882_467': 32.7891,
    '882_468': 32.8249,
    '882_469': 32.8597,
    '882_470': 32.8926,
    '882_471': 32.9267,
    '882_472': 32.9643,
    '882_473': 33.0083,
    '882_474': 33.0621,
    '882_475': 33.1268,
    '882_476': 33.2022,
    '882_477': 33.2815,
    '882_478': 33.3619,
    '882_479': 33.4457,
    '882_480': 33.5312,
    '882_481': 33.6251,
    '882_482': 33.731,
    '882_483': 33.8551,
    '882_484': 33.9756,
    '882_485': 34.0733,
    '882_486': 34.1476,
    '882_487': 34.2066,
    '882_488': 34.2509,
    '882_489': 34.2755,
    '882_490': 34.2848,
    '882_491': 34.2968,
    '882_492': 34.3165,
    '882_493': 34.3456,
    '882_494': 34.3785,
    '882_495': 34.4047,
    '882_496': 34.4229,
    '882_497': 34.4286,
    '882_498': 34.434,
    '882_499': 34.4336,
    '882_500': 34.4333,
    '882_501': 34.4474,
    '882_502': 34.4688,
    '882_503': 34.4994,
    '882_504': 34.5256,
    '882_505': 34.5427,
    '882_506': 34.5523,
    '882_507': 34.5661,
    '882_508': 34.5843,
    '882_509': 34.6048,
    '882_510': 34.6279,
    '882_511': 34.6656,
    '882_512': 34.7127,
    '882_513': 34.7564,
    '882_514': 34.8038,
    '882_515': 34.8477,
    '882_516': 34.8973,
    '882_517': 34.9493,
    '882_518': 34.9975,
    '882_519': 35.0422,
    '882_520': 35.1017,
    '882_521': 35.1701,
    '882_522': 35.2326,
    '882_523': 35.2932,
    '882_524': 35.3592,
    '882_525': 35.4258,
    '882_526': 35.4988,
    '882_527': 35.569,
    '882_528': 35.6335,
    '882_529': 35.692,
    '882_530': 35.7426,
    '882_531': 35.7856,
    '882_532': 35.8267,
    '882_533': 35.861,
    '882_534': 35.8832,
    '882_535': 35.8962,
    '882_536': 35.9079,
    '882_537': 35.9248,
    '882_538': 35.9442,
    '882_539': 35.9656,
    '882_540': 35.9925,
    '882_541': 36.0225,
    '882_542': 36.0415,
    '882_543': 36.0477,
    '882_544': 36.0423,
    '882_545': 36.041,
    '882_546': 36.0311,
    '882_547': 36.0248,
    '882_548': 36.0232,
    '882_549': 36.0245,
    '882_550': 36.0384,
    '882_551': 36.047,
    '882_552': 36.0608,
    '882_553': 36.0844,
    '882_554': 36.111,
    '882_555': 36.1301,
    '882_556': 36.1576,
    '882_557': 36.1941,
    '882_558': 36.2294,
    '882_559': 36.2692,
    '882_560': 36.3046,
    '882_561': 36.3361,
    '882_562': 36.3675,
    '882_563': 36.3989,
    '882_564': 36.4298,
    '882_565': 36.4656,
    '882_566': 36.5035,
    '882_567': 36.5292,
    '882_568': 36.5389,
    '882_569': 36.5442,
    '882_570': 36.5421,
    '882_571': 36.5447,
    '882_572': 36.5498,
    '882_573': 36.5534,
    '882_574': 36.5549,
    '882_575': 36.5581,
    '882_576': 36.566,
    '882_577': 36.5722,
    '882_578': 36.5787,
    '882_579': 36.5855,
    '882_580': 36.5926,
    '882_581': 36.5996,
    '882_582': 36.6072,
    '882_583': 36.6159,
    '882_584': 36.6249,
    '882_585': 36.6346,
    '882_586': 36.6457,
    '882_587': 36.657,
    '882_588': 36.669,
    '882_589': 36.6815,
    '882_590': 36.6929,
    '882_591': 36.7057,
    '882_592': 36.7204,
    '882_593': 36.7364,
    '882_594': 36.754,
    '882_595': 36.7772,
    '882_596': 36.8055,
    '882_597': 36.8376,
    '882_598': 36.8683,
    '882_599': 36.9002,
    '882_600': 36.9347,
    '882_601': 36.9709,
    '882_602': 37.0128,
    '882_603': 37.062,
    '882_604': 37.1064,
    '882_605': 37.1441,
    '882_606': 37.1615,
    '882_607': 37.1589,
    '882_608': 37.1382,
    '882_609': 37.118,
    '882_610': 37.107,
    '882_611': 37.1043,
    '882_612': 37.1099,
    '882_613': 37.1247,
    '882_614': 37.1492,
    '882_615': 37.183,
    '882_616': 37.2244,
    '882_617': 37.2733,
    '882_618': 37.3286,
    '882_619': 37.3914,
    '882_620': 37.4646,
    '882_621': 37.5451,
    '882_622': 37.6113,
    '882_623': 37.6785,
    '882_624': 37.7589,
    '882_625': 37.8622,
    '882_626': 38.0057,
    '882_627': 38.155,
    '882_628': 38.2347,
    '882_629': 38.301,
    '882_630': 38.3574,
    '882_631': 38.4102,
    '882_632': 38.4775,
    '882_633': 38.5656,
    '882_634': 38.6791,
    '882_635': 38.8006,
    '882_636': 38.9095,
    '882_637': 38.9977,
    '882_638': 39.0707,
    '882_639': 39.1171,
    '882_640': 39.1559,
    '882_641': 39.174,
    '882_642': 39.1909,
    '882_643': 39.2016,
    '882_644': 39.2118,
    '882_645': 39.219,
    '882_646': 39.22,
    '882_647': 39.2255,
    '882_648': 39.2395,
    '882_649': 39.2512,
    '882_650': 39.2551,
    '882_651': 39.233,
    '882_652': 39.1637,
    '882_653': 39.0511,
    '882_654': 38.9246,
    '882_655': 38.8095,
    '882_656': 38.7046,
    '882_657': 38.6037,
    '882_658': 38.5074,
    '882_659': 38.427,
    '882_660': 38.3638,
    '882_661': 38.3124,
    '882_662': 38.2715,
    '882_663': 38.2393,
    '882_664': 38.2156,
    '882_665': 38.1988,
    '882_666': 38.1896,
    '882_667': 38.1837,
    '882_668': 38.1817,
    '882_669': 38.1874,
    '882_670': 38.2012,
    '882_671': 38.2224,
    '882_672': 38.2484,
    '882_673': 38.275,
    '882_674': 38.301,
    '882_675': 38.3274,
    '882_676': 38.3566,
    '882_677': 38.3942,
    '882_678': 38.4406,
    '882_679': 38.4969,
    '882_680': 38.5617,
    '882_681': 38.623,
    '882_682': 38.6861,
    '882_683': 38.7484,
    '882_684': 38.8095,
    '882_685': 38.8678,
    '882_686': 38.9217,
    '882_687': 38.9736,
    '882_688': 39.0191,
    '882_689': 39.0684,
    '882_690': 39.1194,
    '882_691': 39.1666,
    '882_692': 39.2072,
    '882_693': 39.2444,
    '882_694': 39.2746,
    '882_695': 39.3006,
    '882_696': 39.3269,
    '882_697': 39.36,
    '882_698': 39.3926,
    '882_699': 39.4232,
    '882_700': 39.4502,
    '882_701': 39.4713,
    '882_702': 39.4896,
    '882_703': 39.5073,
    '882_704': 39.5257,
    '882_705': 39.543,
    '882_706': 39.5588,
    '882_707': 39.5705,
    '882_708': 39.5787,
    '882_709': 39.5814,
    '882_710': 39.5778,
    '882_711': 39.57,
    '882_712': 39.5583,
    '882_713': 39.5441,
    '882_714': 39.5348,
    '882_715': 39.5338,
    '882_716': 39.5417,
    '882_717': 39.5593,
    '882_718': 39.5815,
    '882_719': 39.6089,
    '882_720': 39.6355,
    '882_721': 39.6521,
    '882_722': 39.6669,
    '882_723': 39.6959,
    '882_724': 39.7294,
    '882_725': 39.7635,
    '882_726': 39.7896,
    '882_727': 39.7929,
    '882_728': 39.7815,
    '882_729': 39.7598,
    '882_730': 39.7292,
    '882_731': 39.6902,
    '882_732': 39.6427,
    '882_733': 39.587,
    '882_734': 39.5265,
    '882_735': 39.4687,
    '882_736': 39.4229,
    '882_737': 39.3776,
    '882_738': 39.357,
    '882_739': 39.344,
    '882_740': 39.3212,
    '882_741': 39.2897,
    '882_742': 39.2578,
    '882_743': 39.2425,
    '882_744': 39.2829,
    '882_745': 39.408,
    '882_746': 39.5797,
    '882_747': 39.7475,
    '882_748': 39.8997,
    '882_749': 40.0469,
    '882_750': 40.1982,
    '882_751': 40.3255,
    '882_752': 40.4302,
    '882_753': 40.5132,
    '882_754': 40.5648,
    '882_755': 40.5731,
    '882_756': 40.5646,
    '882_757': 40.5398,
    '882_758': 40.4952,
    '882_759': 40.4273,
    '882_760': 40.3424,
    '882_761': 40.2461,
    '882_762': 40.1362,
    '882_763': 40.0179,
    '882_764': 39.9008,
    '882_765': 39.8038,
    '882_766': 39.7345,
    '882_767': 39.6773,
    '882_768': 39.6416,
    '882_769': 39.6364,
    '882_770': 39.6492,
    '882_772': 39.7918,
    '882_773': 39.7798,
    '882_774': 39.7592,
    '882_775': 39.7229,
    '882_776': 39.6554,
    '882_777': 39.5294,
    '882_778': 39.3298,
    '882_779': 39.0799,
    '882_780': 38.8262,
    '883_364': 29.2011,
    '883_365': 29.2084,
    '883_366': 29.2187,
    '883_367': 29.2283,
    '883_368': 29.2457,
    '883_369': 29.2761,
    '883_370': 29.3246,
    '883_371': 29.397,
    '883_372': 29.4976,
    '883_373': 29.6157,
    '883_374': 29.7437,
    '883_375': 29.8746,
    '883_376': 30.0015,
    '883_377': 30.1188,
    '883_378': 30.2212,
    '883_379': 30.3058,
    '883_380': 30.3748,
    '883_381': 30.431,
    '883_382': 30.4697,
    '883_443': 31.9768,
    '883_444': 32.005,
    '883_445': 32.0321,
    '883_446': 32.0589,
    '883_447': 32.0834,
    '883_448': 32.1046,
    '883_449': 32.121,
    '883_452': 32.1241,
    '883_453': 32.15,
    '883_454': 32.1777,
    '883_455': 32.2081,
    '883_456': 32.2415,
    '883_457': 32.2795,
    '883_458': 32.3249,
    '883_459': 32.3803,
    '883_460': 32.4416,
    '883_461': 32.4935,
    '883_462': 32.5489,
    '883_463': 32.6015,
    '883_464': 32.6441,
    '883_465': 32.6822,
    '883_466': 32.7206,
    '883_467': 32.7595,
    '883_468': 32.8041,
    '883_469': 32.846,
    '883_470': 32.8844,
    '883_471': 32.9228,
    '883_472': 32.9627,
    '883_473': 33.0073,
    '883_474': 33.0594,
    '883_475': 33.1232,
    '883_476': 33.1989,
    '883_477': 33.2759,
    '883_478': 33.359,
    '883_479': 33.4392,
    '883_480': 33.5225,
    '883_481': 33.613,
    '883_482': 33.7163,
    '883_483': 33.8393,
    '883_484': 33.9637,
    '883_485': 34.0659,
    '883_486': 34.1443,
    '883_487': 34.2087,
    '883_488': 34.2595,
    '883_489': 34.2893,
    '883_490': 34.3063,
    '883_491': 34.3245,
    '883_492': 34.3496,
    '883_493': 34.3796,
    '883_494': 34.4143,
    '883_495': 34.4451,
    '883_496': 34.464,
    '883_497': 34.4647,
    '883_498': 34.4609,
    '883_499': 34.4619,
    '883_500': 34.4675,
    '883_501': 34.4869,
    '883_502': 34.5115,
    '883_503': 34.5376,
    '883_504': 34.5608,
    '883_505': 34.5707,
    '883_506': 34.5795,
    '883_507': 34.59,
    '883_508': 34.6049,
    '883_509': 34.6237,
    '883_510': 34.6463,
    '883_511': 34.6749,
    '883_512': 34.7159,
    '883_513': 34.7596,
    '883_514': 34.8089,
    '883_515': 34.8524,
    '883_516': 34.8913,
    '883_517': 34.9451,
    '883_518': 34.997,
    '883_519': 35.0459,
    '883_520': 35.1058,
    '883_521': 35.1799,
    '883_522': 35.2465,
    '883_523': 35.3155,
    '883_524': 35.3876,
    '883_525': 35.4584,
    '883_526': 35.5329,
    '883_527': 35.6058,
    '883_528': 35.6702,
    '883_529': 35.725,
    '883_530': 35.7725,
    '883_531': 35.815,
    '883_532': 35.8542,
    '883_533': 35.8876,
    '883_534': 35.9128,
    '883_535': 35.9316,
    '883_536': 35.9417,
    '883_537': 35.9508,
    '883_538': 35.959,
    '883_539': 35.9756,
    '883_540': 36.0014,
    '883_541': 36.0339,
    '883_542': 36.0578,
    '883_543': 36.0675,
    '883_544': 36.0655,
    '883_545': 36.0602,
    '883_546': 36.0498,
    '883_547': 36.0508,
    '883_548': 36.054,
    '883_549': 36.0662,
    '883_550': 36.0862,
    '883_551': 36.0868,
    '883_552': 36.0912,
    '883_553': 36.1186,
    '883_554': 36.1418,
    '883_555': 36.1598,
    '883_556': 36.187,
    '883_557': 36.227,
    '883_558': 36.264,
    '883_559': 36.2922,
    '883_560': 36.3263,
    '883_561': 36.3508,
    '883_562': 36.3786,
    '883_563': 36.4135,
    '883_564': 36.4445,
    '883_565': 36.4792,
    '883_566': 36.5164,
    '883_567': 36.5363,
    '883_568': 36.5403,
    '883_569': 36.5436,
    '883_570': 36.5499,
    '883_571': 36.5515,
    '883_572': 36.5587,
    '883_573': 36.5568,
    '883_574': 36.553,
    '883_575': 36.5562,
    '883_576': 36.5614,
    '883_577': 36.568,
    '883_578': 36.5742,
    '883_579': 36.5809,
    '883_580': 36.5878,
    '883_581': 36.5949,
    '883_582': 36.6026,
    '883_583': 36.6119,
    '883_584': 36.6223,
    '883_585': 36.6334,
    '883_586': 36.6453,
    '883_587': 36.6575,
    '883_588': 36.67,
    '883_589': 36.6835,
    '883_590': 36.6967,
    '883_591': 36.7096,
    '883_592': 36.7231,
    '883_593': 36.7376,
    '883_594': 36.7531,
    '883_595': 36.7746,
    '883_596': 36.801,
    '883_597': 36.8314,
    '883_598': 36.8651,
    '883_599': 36.8986,
    '883_600': 36.9323,
    '883_601': 36.9719,
    '883_602': 37.0166,
    '883_603': 37.0695,
    '883_604': 37.1215,
    '883_605': 37.1694,
    '883_606': 37.2023,
    '883_607': 37.2192,
    '883_608': 37.2131,
    '883_609': 37.185,
    '883_610': 37.161,
    '883_611': 37.146,
    '883_612': 37.1378,
    '883_613': 37.1396,
    '883_614': 37.1527,
    '883_615': 37.1769,
    '883_616': 37.2104,
    '883_617': 37.2527,
    '883_618': 37.3032,
    '883_619': 37.3627,
    '883_620': 37.4339,
    '883_621': 37.5104,
    '883_622': 37.5717,
    '883_623': 37.6331,
    '883_624': 37.707,
    '883_625': 37.8046,
    '883_626': 37.9393,
    '883_627': 38.0758,
    '883_628': 38.1561,
    '883_629': 38.2217,
    '883_630': 38.2785,
    '883_631': 38.3306,
    '883_632': 38.3948,
    '883_633': 38.4775,
    '883_634': 38.5805,
    '883_635': 38.691,
    '883_636': 38.7959,
    '883_637': 38.8809,
    '883_638': 38.9535,
    '883_639': 39.0031,
    '883_640': 39.0384,
    '883_641': 39.0624,
    '883_642': 39.0821,
    '883_643': 39.0924,
    '883_644': 39.1012,
    '883_645': 39.1075,
    '883_646': 39.1087,
    '883_647': 39.1165,
    '883_648': 39.1315,
    '883_649': 39.1478,
    '883_650': 39.1548,
    '883_651': 39.1445,
    '883_652': 39.0958,
    '883_653': 38.9987,
    '883_654': 38.8731,
    '883_655': 38.7547,
    '883_656': 38.6475,
    '883_657': 38.5471,
    '883_658': 38.4466,
    '883_659': 38.3637,
    '883_660': 38.2988,
    '883_661': 38.2453,
    '883_662': 38.203,
    '883_663': 38.1724,
    '883_664': 38.1504,
    '883_665': 38.135,
    '883_666': 38.1262,
    '883_667': 38.1225,
    '883_668': 38.1236,
    '883_669': 38.1321,
    '883_670': 38.148,
    '883_671': 38.1702,
    '883_672': 38.1984,
    '883_673': 38.2262,
    '883_674': 38.2519,
    '883_675': 38.2788,
    '883_676': 38.3096,
    '883_677': 38.3506,
    '883_678': 38.4019,
    '883_679': 38.465,
    '883_680': 38.5372,
    '883_681': 38.6072,
    '883_682': 38.6776,
    '883_683': 38.7483,
    '883_684': 38.8143,
    '883_685': 38.8769,
    '883_686': 38.9364,
    '883_687': 38.9915,
    '883_688': 39.044,
    '883_689': 39.0917,
    '883_690': 39.1454,
    '883_691': 39.1951,
    '883_692': 39.2386,
    '883_693': 39.2807,
    '883_694': 39.3179,
    '883_695': 39.3484,
    '883_696': 39.3798,
    '883_697': 39.419,
    '883_698': 39.4596,
    '883_699': 39.4947,
    '883_700': 39.5221,
    '883_701': 39.5444,
    '883_702': 39.5649,
    '883_703': 39.5852,
    '883_704': 39.606,
    '883_705': 39.6262,
    '883_706': 39.6449,
    '883_707': 39.6593,
    '883_708': 39.6696,
    '883_709': 39.6747,
    '883_710': 39.6724,
    '883_711': 39.6667,
    '883_712': 39.6561,
    '883_713': 39.643,
    '883_714': 39.6348,
    '883_715': 39.6352,
    '883_716': 39.6431,
    '883_717': 39.6601,
    '883_718': 39.6836,
    '883_719': 39.7112,
    '883_720': 39.7353,
    '883_721': 39.7455,
    '883_722': 39.7588,
    '883_723': 39.7841,
    '883_724': 39.8124,
    '883_725': 39.8403,
    '883_726': 39.8586,
    '883_727': 39.855,
    '883_728': 39.8418,
    '883_729': 39.8148,
    '883_730': 39.7807,
    '883_731': 39.7385,
    '883_732': 39.6872,
    '883_733': 39.6273,
    '883_734': 39.5601,
    '883_735': 39.4946,
    '883_736': 39.443,
    '883_737': 39.3963,
    '883_738': 39.3816,
    '883_739': 39.3797,
    '883_740': 39.3706,
    '883_741': 39.3554,
    '883_742': 39.3213,
    '883_743': 39.298,
    '883_744': 39.3217,
    '883_745': 39.4371,
    '883_746': 39.6113,
    '883_747': 39.7706,
    '883_748': 39.9214,
    '883_749': 40.0715,
    '883_750': 40.2266,
    '883_751': 40.358,
    '883_752': 40.465,
    '883_753': 40.5549,
    '883_754': 40.6098,
    '883_755': 40.618,
    '883_756': 40.6086,
    '883_757': 40.5816,
    '883_758': 40.54,
    '883_759': 40.4726,
    '883_760': 40.3751,
    '883_761': 40.2684,
    '883_762': 40.1513,
    '883_763': 40.0272,
    '883_764': 39.9052,
    '883_765': 39.797,
    '883_766': 39.717,
    '883_767': 39.6559,
    '883_768': 39.614,
    '883_769': 39.603,
    '883_770': 39.6198,
    '883_772': 39.7716,
    '883_773': 39.7589,
    '883_774': 39.7425,
    '883_775': 39.7179,
    '883_776': 39.6478,
    '883_777': 39.5038,
    '883_778': 39.2714,
    '883_779': 39.0023,
    '883_780': 38.7458,
    '884_364': 29.2024,
    '884_365': 29.2075,
    '884_366': 29.2128,
    '884_367': 29.2182,
    '884_368': 29.2297,
    '884_369': 29.2539,
    '884_370': 29.297,
    '884_371': 29.3639,
    '884_372': 29.4537,
    '884_373': 29.5628,
    '884_374': 29.6877,
    '884_375': 29.8189,
    '884_376': 29.9476,
    '884_377': 30.0667,
    '884_378': 30.1718,
    '884_379': 30.2591,
    '884_380': 30.3315,
    '884_381': 30.3904,
    '884_382': 30.4341,
    '884_443': 31.95,
    '884_444': 31.9836,
    '884_445': 32.0149,
    '884_446': 32.0432,
    '884_447': 32.0675,
    '884_448': 32.0876,
    '884_449': 32.1021,
    '884_452': 32.1008,
    '884_453': 32.1241,
    '884_454': 32.1491,
    '884_455': 32.1767,
    '884_456': 32.2081,
    '884_457': 32.246,
    '884_458': 32.2933,
    '884_459': 32.3476,
    '884_460': 32.4043,
    '884_461': 32.4613,
    '884_462': 32.5151,
    '884_463': 32.5657,
    '884_464': 32.6059,
    '884_465': 32.6438,
    '884_466': 32.6839,
    '884_467': 32.7279,
    '884_468': 32.7782,
    '884_469': 32.8264,
    '884_470': 32.874,
    '884_471': 32.9193,
    '884_472': 32.9646,
    '884_473': 33.0107,
    '884_474': 33.0622,
    '884_475': 33.1253,
    '884_476': 33.2028,
    '884_477': 33.2739,
    '884_478': 33.3547,
    '884_479': 33.4315,
    '884_480': 33.5085,
    '884_481': 33.6041,
    '884_482': 33.7033,
    '884_483': 33.817,
    '884_484': 33.9368,
    '884_485': 34.0433,
    '884_486': 34.1282,
    '884_487': 34.1986,
    '884_488': 34.2562,
    '884_489': 34.2943,
    '884_490': 34.3208,
    '884_491': 34.347,
    '884_492': 34.3795,
    '884_493': 34.413,
    '884_494': 34.447,
    '884_495': 34.4784,
    '884_496': 34.4965,
    '884_497': 34.4935,
    '884_498': 34.4898,
    '884_499': 34.4926,
    '884_500': 34.5,
    '884_501': 34.518,
    '884_502': 34.5452,
    '884_503': 34.5722,
    '884_504': 34.5904,
    '884_505': 34.5985,
    '884_506': 34.607,
    '884_507': 34.6137,
    '884_508': 34.6249,
    '884_509': 34.6446,
    '884_510': 34.6678,
    '884_511': 34.6907,
    '884_512': 34.7224,
    '884_513': 34.7657,
    '884_514': 34.8098,
    '884_515': 34.8532,
    '884_516': 34.8878,
    '884_517': 34.9379,
    '884_518': 34.9909,
    '884_519': 35.0468,
    '884_520': 35.1145,
    '884_521': 35.1881,
    '884_522': 35.2603,
    '884_523': 35.3388,
    '884_524': 35.4161,
    '884_525': 35.4906,
    '884_526': 35.5658,
    '884_527': 35.6369,
    '884_528': 35.6992,
    '884_529': 35.7515,
    '884_530': 35.7976,
    '884_531': 35.8403,
    '884_532': 35.881,
    '884_533': 35.9153,
    '884_534': 35.9418,
    '884_535': 35.962,
    '884_536': 35.9723,
    '884_537': 35.9756,
    '884_538': 35.9785,
    '884_539': 35.9864,
    '884_540': 36.0082,
    '884_541': 36.042,
    '884_542': 36.0662,
    '884_543': 36.0807,
    '884_544': 36.0856,
    '884_545': 36.0816,
    '884_546': 36.0789,
    '884_547': 36.0899,
    '884_548': 36.0922,
    '884_549': 36.1058,
    '884_550': 36.1295,
    '884_551': 36.1295,
    '884_552': 36.1271,
    '884_553': 36.1547,
    '884_554': 36.1793,
    '884_555': 36.1924,
    '884_556': 36.2167,
    '884_557': 36.2605,
    '884_558': 36.2999,
    '884_559': 36.3218,
    '884_560': 36.3379,
    '884_561': 36.3588,
    '884_562': 36.3924,
    '884_563': 36.4311,
    '884_564': 36.4644,
    '884_565': 36.5014,
    '884_566': 36.5262,
    '884_567': 36.5373,
    '884_568': 36.5451,
    '884_569': 36.5515,
    '884_570': 36.561,
    '884_571': 36.5628,
    '884_572': 36.568,
    '884_573': 36.5715,
    '884_574': 36.561,
    '884_575': 36.5561,
    '884_576': 36.5619,
    '884_577': 36.5701,
    '884_578': 36.5744,
    '884_579': 36.5807,
    '884_580': 36.5873,
    '884_581': 36.594,
    '884_582': 36.6015,
    '884_583': 36.6109,
    '884_584': 36.6225,
    '884_585': 36.6353,
    '884_586': 36.6488,
    '884_587': 36.6621,
    '884_588': 36.6751,
    '884_589': 36.6897,
    '884_590': 36.7043,
    '884_591': 36.7162,
    '884_592': 36.7276,
    '884_593': 36.7395,
    '884_594': 36.754,
    '884_595': 36.7741,
    '884_596': 36.7984,
    '884_597': 36.826,
    '884_598': 36.8585,
    '884_599': 36.8952,
    '884_600': 36.9331,
    '884_601': 36.9749,
    '884_602': 37.0226,
    '884_603': 37.0755,
    '884_604': 37.1302,
    '884_605': 37.183,
    '884_606': 37.2239,
    '884_607': 37.2563,
    '884_608': 37.275,
    '884_609': 37.2679,
    '884_610': 37.2434,
    '884_611': 37.2191,
    '884_612': 37.1892,
    '884_613': 37.1695,
    '884_614': 37.1668,
    '884_615': 37.1794,
    '884_616': 37.2045,
    '884_617': 37.2402,
    '884_618': 37.2856,
    '884_619': 37.3412,
    '884_620': 37.4095,
    '884_621': 37.4804,
    '884_622': 37.5365,
    '884_623': 37.5923,
    '884_624': 37.659,
    '884_625': 37.747,
    '884_626': 37.8682,
    '884_627': 37.9962,
    '884_628': 38.0778,
    '884_629': 38.1441,
    '884_630': 38.201,
    '884_631': 38.2506,
    '884_632': 38.3103,
    '884_633': 38.391,
    '884_634': 38.4856,
    '884_635': 38.5855,
    '884_636': 38.6864,
    '884_637': 38.7684,
    '884_638': 38.8408,
    '884_639': 38.8917,
    '884_640': 38.9274,
    '884_641': 38.953,
    '884_642': 38.9769,
    '884_643': 38.99,
    '884_644': 38.994,
    '884_645': 38.9966,
    '884_646': 39.0006,
    '884_647': 39.0088,
    '884_648': 39.0235,
    '884_649': 39.0377,
    '884_650': 39.0456,
    '884_651': 39.0424,
    '884_652': 39.0106,
    '884_653': 38.9336,
    '884_654': 38.8199,
    '884_655': 38.7065,
    '884_656': 38.597,
    '884_657': 38.494,
    '884_658': 38.387,
    '884_659': 38.3009,
    '884_660': 38.2354,
    '884_661': 38.18,
    '884_662': 38.1368,
    '884_663': 38.1062,
    '884_664': 38.0858,
    '884_665': 38.0724,
    '884_666': 38.0639,
    '884_667': 38.0606,
    '884_668': 38.0633,
    '884_669': 38.0728,
    '884_670': 38.0904,
    '884_671': 38.1147,
    '884_672': 38.1429,
    '884_673': 38.1718,
    '884_674': 38.1998,
    '884_675': 38.2288,
    '884_676': 38.2644,
    '884_677': 38.311,
    '884_678': 38.3695,
    '884_679': 38.4401,
    '884_680': 38.5211,
    '884_681': 38.5994,
    '884_682': 38.6771,
    '884_683': 38.7535,
    '884_684': 38.826,
    '884_685': 38.8934,
    '884_686': 38.9572,
    '884_687': 39.0154,
    '884_688': 39.0692,
    '884_689': 39.118,
    '884_690': 39.1719,
    '884_691': 39.2232,
    '884_692': 39.2711,
    '884_693': 39.3172,
    '884_694': 39.36,
    '884_695': 39.3942,
    '884_696': 39.4311,
    '884_697': 39.4806,
    '884_698': 39.5338,
    '884_699': 39.5731,
    '884_700': 39.5975,
    '884_701': 39.6201,
    '884_702': 39.6427,
    '884_703': 39.6654,
    '884_704': 39.6888,
    '884_705': 39.7112,
    '884_706': 39.7323,
    '884_707': 39.7501,
    '884_708': 39.7634,
    '884_709': 39.7708,
    '884_710': 39.7705,
    '884_711': 39.7662,
    '884_712': 39.7568,
    '884_713': 39.7446,
    '884_714': 39.7392,
    '884_715': 39.74,
    '884_716': 39.7452,
    '884_717': 39.759,
    '884_718': 39.7811,
    '884_719': 39.8085,
    '884_720': 39.8318,
    '884_721': 39.8414,
    '884_722': 39.8539,
    '884_723': 39.8754,
    '884_724': 39.8984,
    '884_725': 39.9192,
    '884_726': 39.93,
    '884_727': 39.9214,
    '884_728': 39.9025,
    '884_729': 39.8708,
    '884_730': 39.835,
    '884_731': 39.7906,
    '884_732': 39.7363,
    '884_733': 39.6721,
    '884_734': 39.5967,
    '884_735': 39.5208,
    '884_736': 39.461,
    '884_737': 39.4149,
    '884_738': 39.4004,
    '884_739': 39.4025,
    '884_740': 39.4025,
    '884_741': 39.4016,
    '884_742': 39.3834,
    '884_743': 39.3465,
    '884_744': 39.3527,
    '884_745': 39.453,
    '884_746': 39.6207,
    '884_747': 39.7777,
    '884_748': 39.9286,
    '884_749': 40.0812,
    '884_750': 40.2353,
    '884_751': 40.3745,
    '884_752': 40.4877,
    '884_753': 40.5833,
    '884_754': 40.6454,
    '884_755': 40.6665,
    '884_756': 40.6618,
    '884_757': 40.6302,
    '884_758': 40.5888,
    '884_759': 40.5183,
    '884_760': 40.4162,
    '884_761': 40.2998,
    '884_762': 40.1747,
    '884_763': 40.042,
    '884_764': 39.9119,
    '884_765': 39.7962,
    '884_766': 39.6995,
    '884_767': 39.6324,
    '884_768': 39.5898,
    '884_769': 39.5705,
    '884_770': 39.5802,
    '884_772': 39.7299,
    '884_773': 39.7167,
    '884_774': 39.7022,
    '884_775': 39.6783,
    '884_776': 39.6031,
    '884_777': 39.4466,
    '884_778': 39.2019,
    '884_779': 38.9185,
    '884_780': 38.6574,
    '885_364': 29.2065,
    '885_365': 29.2101,
    '885_366': 29.2117,
    '885_367': 29.213,
    '885_368': 29.2172,
    '885_369': 29.2355,
    '885_370': 29.2753,
    '885_371': 29.3349,
    '885_372': 29.414,
    '885_373': 29.5138,
    '885_374': 29.6363,
    '885_375': 29.7681,
    '885_376': 29.8991,
    '885_377': 30.0195,
    '885_378': 30.1261,
    '885_379': 30.2132,
    '885_380': 30.2855,
    '885_381': 30.3443,
    '885_382': 30.392,
    '885_443': 31.9314,
    '885_444': 31.967,
    '885_445': 31.9999,
    '885_446': 32.0285,
    '885_447': 32.0501,
    '885_448': 32.0671,
    '885_449': 32.0771,
    '885_452': 32.075,
    '885_453': 32.0961,
    '885_454': 32.1186,
    '885_455': 32.144,
    '885_456': 32.1739,
    '885_457': 32.2127,
    '885_458': 32.2599,
    '885_459': 32.3137,
    '885_460': 32.3706,
    '885_461': 32.4239,
    '885_462': 32.4755,
    '885_463': 32.5245,
    '885_464': 32.5677,
    '885_465': 32.605,
    '885_466': 32.6465,
    '885_467': 32.6936,
    '885_468': 32.7516,
    '885_469': 32.8089,
    '885_470': 32.8645,
    '885_471': 32.9152,
    '885_472': 32.9666,
    '885_473': 33.017,
    '885_474': 33.0684,
    '885_475': 33.1291,
    '885_476': 33.2003,
    '885_477': 33.2713,
    '885_478': 33.3479,
    '885_479': 33.4211,
    '885_480': 33.5002,
    '885_481': 33.5961,
    '885_482': 33.6959,
    '885_483': 33.7999,
    '885_484': 33.907,
    '885_485': 34.0103,
    '885_486': 34.0999,
    '885_487': 34.1748,
    '885_488': 34.2382,
    '885_489': 34.2893,
    '885_490': 34.328,
    '885_491': 34.3632,
    '885_492': 34.4042,
    '885_493': 34.4435,
    '885_494': 34.4787,
    '885_495': 34.5082,
    '885_496': 34.5215,
    '885_497': 34.5151,
    '885_498': 34.5113,
    '885_499': 34.5154,
    '885_500': 34.5261,
    '885_501': 34.5504,
    '885_502': 34.5793,
    '885_503': 34.6051,
    '885_504': 34.6172,
    '885_505': 34.625,
    '885_506': 34.6335,
    '885_507': 34.6364,
    '885_508': 34.6468,
    '885_509': 34.6654,
    '885_510': 34.6897,
    '885_511': 34.7115,
    '885_512': 34.7384,
    '885_513': 34.7758,
    '885_514': 34.8163,
    '885_515': 34.8547,
    '885_516': 34.8891,
    '885_517': 34.9359,
    '885_518': 34.9918,
    '885_519': 35.0528,
    '885_520': 35.1263,
    '885_521': 35.2007,
    '885_522': 35.2788,
    '885_523': 35.3606,
    '885_524': 35.4418,
    '885_525': 35.5192,
    '885_526': 35.5959,
    '885_527': 35.6633,
    '885_528': 35.7201,
    '885_529': 35.7715,
    '885_530': 35.8159,
    '885_531': 35.8593,
    '885_532': 35.903,
    '885_533': 35.9376,
    '885_534': 35.967,
    '885_535': 35.9871,
    '885_536': 35.9949,
    '885_537': 35.9953,
    '885_538': 35.9992,
    '885_539': 36.0079,
    '885_540': 36.0199,
    '885_541': 36.0531,
    '885_542': 36.0708,
    '885_543': 36.0891,
    '885_544': 36.0956,
    '885_545': 36.1081,
    '885_546': 36.1201,
    '885_547': 36.1254,
    '885_548': 36.1292,
    '885_549': 36.1418,
    '885_550': 36.1614,
    '885_551': 36.1664,
    '885_552': 36.1632,
    '885_553': 36.1867,
    '885_554': 36.2147,
    '885_555': 36.2274,
    '885_556': 36.2478,
    '885_557': 36.2843,
    '885_558': 36.3211,
    '885_559': 36.344,
    '885_560': 36.3535,
    '885_561': 36.3679,
    '885_562': 36.4068,
    '885_563': 36.441,
    '885_564': 36.4829,
    '885_565': 36.5319,
    '885_566': 36.5457,
    '885_567': 36.5495,
    '885_568': 36.5594,
    '885_569': 36.564,
    '885_570': 36.5748,
    '885_571': 36.5848,
    '885_572': 36.5889,
    '885_573': 36.5898,
    '885_574': 36.5755,
    '885_575': 36.5655,
    '885_576': 36.5697,
    '885_577': 36.5787,
    '885_578': 36.5813,
    '885_579': 36.5868,
    '885_580': 36.5927,
    '885_581': 36.5984,
    '885_582': 36.605,
    '885_583': 36.614,
    '885_584': 36.6268,
    '885_585': 36.6412,
    '885_586': 36.6567,
    '885_587': 36.6722,
    '885_588': 36.6872,
    '885_589': 36.7024,
    '885_590': 36.7145,
    '885_591': 36.7232,
    '885_592': 36.7308,
    '885_593': 36.7409,
    '885_594': 36.7558,
    '885_595': 36.7756,
    '885_596': 36.7977,
    '885_597': 36.8238,
    '885_598': 36.8569,
    '885_599': 36.8958,
    '885_600': 36.9372,
    '885_601': 36.98,
    '885_602': 37.0263,
    '885_603': 37.0796,
    '885_604': 37.1345,
    '885_605': 37.1866,
    '885_606': 37.2293,
    '885_607': 37.2702,
    '885_608': 37.3068,
    '885_609': 37.325,
    '885_610': 37.3196,
    '885_611': 37.2953,
    '885_612': 37.2559,
    '885_613': 37.2136,
    '885_614': 37.1903,
    '885_615': 37.1899,
    '885_616': 37.2067,
    '885_617': 37.2362,
    '885_618': 37.2769,
    '885_619': 37.3289,
    '885_620': 37.3941,
    '885_621': 37.4571,
    '885_622': 37.5076,
    '885_623': 37.5569,
    '885_624': 37.6159,
    '885_625': 37.6933,
    '885_626': 37.7964,
    '885_627': 37.9095,
    '885_628': 38.0001,
    '885_629': 38.072,
    '885_630': 38.1265,
    '885_631': 38.1713,
    '885_632': 38.2286,
    '885_633': 38.31,
    '885_634': 38.399,
    '885_635': 38.4888,
    '885_636': 38.5837,
    '885_637': 38.665,
    '885_638': 38.7352,
    '885_639': 38.7885,
    '885_640': 38.8243,
    '885_641': 38.8483,
    '885_642': 38.8737,
    '885_643': 38.8894,
    '885_644': 38.8925,
    '885_645': 38.8918,
    '885_646': 38.8934,
    '885_647': 38.9027,
    '885_648': 38.9136,
    '885_649': 38.9228,
    '885_650': 38.9334,
    '885_651': 38.932,
    '885_652': 38.9134,
    '885_653': 38.8577,
    '885_654': 38.7668,
    '885_655': 38.6636,
    '885_656': 38.5445,
    '885_657': 38.4294,
    '885_658': 38.3204,
    '885_659': 38.237,
    '885_660': 38.1707,
    '885_661': 38.1149,
    '885_662': 38.0714,
    '885_663': 38.0406,
    '885_664': 38.0214,
    '885_665': 38.0083,
    '885_666': 37.9996,
    '885_667': 37.9962,
    '885_668': 37.9984,
    '885_669': 38.009,
    '885_670': 38.0288,
    '885_671': 38.0546,
    '885_672': 38.0848,
    '885_673': 38.1159,
    '885_674': 38.1485,
    '885_675': 38.1821,
    '885_676': 38.2227,
    '885_677': 38.2758,
    '885_678': 38.3425,
    '885_679': 38.4213,
    '885_680': 38.5093,
    '885_681': 38.5976,
    '885_682': 38.6819,
    '885_683': 38.7636,
    '885_684': 38.8426,
    '885_685': 38.9154,
    '885_686': 38.9836,
    '885_687': 39.0461,
    '885_688': 39.1002,
    '885_689': 39.1544,
    '885_690': 39.208,
    '885_691': 39.2598,
    '885_692': 39.3054,
    '885_693': 39.3548,
    '885_694': 39.4018,
    '885_695': 39.4395,
    '885_696': 39.4812,
    '885_697': 39.539,
    '885_698': 39.6019,
    '885_699': 39.6542,
    '885_700': 39.678,
    '885_701': 39.7,
    '885_702': 39.7242,
    '885_703': 39.7488,
    '885_704': 39.7751,
    '885_705': 39.8011,
    '885_706': 39.8237,
    '885_707': 39.8446,
    '885_708': 39.8609,
    '885_709': 39.8703,
    '885_710': 39.872,
    '885_711': 39.8691,
    '885_712': 39.8606,
    '885_713': 39.8495,
    '885_714': 39.8461,
    '885_715': 39.8451,
    '885_716': 39.8474,
    '885_717': 39.8596,
    '885_718': 39.8791,
    '885_719': 39.9022,
    '885_720': 39.9235,
    '885_721': 39.9377,
    '885_722': 39.9526,
    '885_723': 39.9704,
    '885_724': 39.988,
    '885_725': 40.0021,
    '885_726': 40.006,
    '885_727': 39.9909,
    '885_728': 39.9631,
    '885_729': 39.9292,
    '885_730': 39.8918,
    '885_731': 39.8464,
    '885_732': 39.7888,
    '885_733': 39.7198,
    '885_734': 39.6383,
    '885_735': 39.549,
    '885_736': 39.4805,
    '885_737': 39.432,
    '885_738': 39.4109,
    '885_739': 39.4076,
    '885_740': 39.41,
    '885_741': 39.4151,
    '885_742': 39.4174,
    '885_743': 39.3753,
    '885_744': 39.3753,
    '885_745': 39.4582,
    '885_746': 39.6172,
    '885_747': 39.7647,
    '885_748': 39.9206,
    '885_749': 40.0784,
    '885_750': 40.234,
    '885_751': 40.3821,
    '885_752': 40.5056,
    '885_753': 40.6093,
    '885_754': 40.682,
    '885_755': 40.7187,
    '885_756': 40.7213,
    '885_757': 40.6832,
    '885_758': 40.6332,
    '885_759': 40.5605,
    '885_760': 40.4593,
    '885_761': 40.3417,
    '885_762': 40.2068,
    '885_763': 40.0613,
    '885_764': 39.9179,
    '885_765': 39.793,
    '885_766': 39.6827,
    '885_767': 39.6103,
    '885_768': 39.5629,
    '885_769': 39.5337,
    '885_770': 39.5306,
    '885_772': 39.6654,
    '885_773': 39.6504,
    '885_774': 39.6334,
    '885_775': 39.6026,
    '885_776': 39.5206,
    '885_777': 39.355,
    '885_778': 39.1088,
    '885_779': 38.8235,
    '885_780': 38.5643,
    '885_788': 37.1062,
    '885_789': 36.9768,
    '885_790': 36.8393,
    '885_791': 36.6832,
    '885_792': 36.4957,
    '885_793': 36.2861,
    '885_794': 36.061,
    '885_795': 35.8285,
    '885_796': 35.5864,
    '885_797': 35.3388,
    '885_798': 35.0789,
    '885_799': 34.808,
    '885_800': 34.5115,
    '885_801': 34.1932,
    '885_802': 33.8638,
    '885_803': 33.53,
    '885_804': 33.1952,
    '885_805': 32.8619,
    '885_806': 32.5585,
    '885_807': 32.2724,
    '885_808': 31.9986,
    '885_809': 31.7392,
    '885_810': 31.4831,
    '885_811': 31.2186,
    '885_812': 30.9661,
    '885_813': 30.7224,
    '885_814': 30.4796,
    '885_815': 30.2368,
    '885_816': 29.9854,
    '885_817': 29.7312,
    '885_818': 29.4616,
    '885_819': 29.1752,
    '885_820': 28.8985,
    '885_821': 28.6112,
    '885_822': 28.3113,
    '885_823': 27.9993,
    '885_824': 27.6751,
    '885_825': 27.3403,
    '885_826': 26.9902,
    '885_827': 26.6196,
    '885_828': 26.243,
    '886_364': 29.2133,
    '886_365': 29.214,
    '886_366': 29.2136,
    '886_367': 29.2111,
    '886_368': 29.209,
    '886_369': 29.2243,
    '886_370': 29.2587,
    '886_371': 29.3117,
    '886_372': 29.3849,
    '886_373': 29.478,
    '886_374': 29.5917,
    '886_375': 29.721,
    '886_376': 29.8534,
    '886_377': 29.9717,
    '886_378': 30.0762,
    '886_379': 30.1653,
    '886_380': 30.2392,
    '886_381': 30.2999,
    '886_382': 30.3512,
    '886_443': 31.908,
    '886_444': 31.949,
    '886_445': 31.9849,
    '886_446': 32.0151,
    '886_447': 32.0325,
    '886_448': 32.0461,
    '886_449': 32.051,
    '886_452': 32.048,
    '886_453': 32.0672,
    '886_454': 32.0879,
    '886_455': 32.1116,
    '886_456': 32.1402,
    '886_457': 32.178,
    '886_458': 32.2236,
    '886_459': 32.2759,
    '886_460': 32.3282,
    '886_461': 32.3813,
    '886_462': 32.4325,
    '886_463': 32.4812,
    '886_464': 32.5265,
    '886_465': 32.5687,
    '886_466': 32.6138,
    '886_467': 32.6646,
    '886_468': 32.727,
    '886_469': 32.7905,
    '886_470': 32.8499,
    '886_471': 32.9063,
    '886_472': 32.9647,
    '886_473': 33.0223,
    '886_474': 33.0754,
    '886_475': 33.1325,
    '886_476': 33.1982,
    '886_477': 33.2654,
    '886_478': 33.338,
    '886_479': 33.4136,
    '886_480': 33.4994,
    '886_481': 33.5888,
    '886_482': 33.6901,
    '886_483': 33.7907,
    '886_484': 33.8876,
    '886_485': 33.9792,
    '886_486': 34.0662,
    '886_487': 34.1399,
    '886_488': 34.208,
    '886_489': 34.2739,
    '886_490': 34.3272,
    '886_491': 34.3706,
    '886_492': 34.419,
    '886_493': 34.4644,
    '886_494': 34.5046,
    '886_495': 34.5324,
    '886_496': 34.5425,
    '886_497': 34.5314,
    '886_498': 34.5264,
    '886_499': 34.5328,
    '886_500': 34.5502,
    '886_501': 34.5808,
    '886_502': 34.6131,
    '886_503': 34.634,
    '886_504': 34.6423,
    '886_505': 34.6495,
    '886_506': 34.6555,
    '886_507': 34.6589,
    '886_508': 34.668,
    '886_509': 34.6871,
    '886_510': 34.7116,
    '886_511': 34.7371,
    '886_512': 34.7632,
    '886_513': 34.7933,
    '886_514': 34.8293,
    '886_515': 34.8629,
    '886_516': 34.8979,
    '886_517': 34.945,
    '886_518': 35.0022,
    '886_519': 35.0676,
    '886_520': 35.1423,
    '886_521': 35.2202,
    '886_522': 35.3015,
    '886_523': 35.3819,
    '886_524': 35.4632,
    '886_525': 35.5433,
    '886_526': 35.6184,
    '886_527': 35.6831,
    '886_528': 35.7361,
    '886_529': 35.7847,
    '886_530': 35.8287,
    '886_531': 35.873,
    '886_532': 35.9171,
    '886_533': 35.9534,
    '886_534': 35.9866,
    '886_535': 36.0075,
    '886_536': 36.0122,
    '886_537': 36.0112,
    '886_538': 36.0174,
    '886_539': 36.0279,
    '886_540': 36.0361,
    '886_541': 36.0628,
    '886_542': 36.0796,
    '886_543': 36.0972,
    '886_544': 36.1057,
    '886_545': 36.1301,
    '886_546': 36.1511,
    '886_547': 36.1571,
    '886_548': 36.1659,
    '886_549': 36.1777,
    '886_550': 36.1927,
    '886_551': 36.1987,
    '886_552': 36.2038,
    '886_553': 36.2229,
    '886_554': 36.25,
    '886_555': 36.2659,
    '886_556': 36.279,
    '886_557': 36.2998,
    '886_558': 36.328,
    '886_559': 36.3553,
    '886_560': 36.3624,
    '886_561': 36.3801,
    '886_562': 36.4189,
    '886_563': 36.4545,
    '886_564': 36.4927,
    '886_565': 36.5407,
    '886_566': 36.5617,
    '886_567': 36.5667,
    '886_568': 36.5833,
    '886_569': 36.5839,
    '886_570': 36.5941,
    '886_571': 36.609,
    '886_572': 36.615,
    '886_573': 36.6112,
    '886_574': 36.5979,
    '886_575': 36.5852,
    '886_576': 36.5899,
    '886_577': 36.5898,
    '886_578': 36.5963,
    '886_579': 36.6009,
    '886_580': 36.6077,
    '886_581': 36.6106,
    '886_582': 36.6153,
    '886_583': 36.6233,
    '886_584': 36.6361,
    '886_585': 36.6514,
    '886_586': 36.6684,
    '886_587': 36.6858,
    '886_588': 36.7025,
    '886_589': 36.7186,
    '886_590': 36.7273,
    '886_591': 36.7307,
    '886_592': 36.734,
    '886_593': 36.7421,
    '886_594': 36.7571,
    '886_595': 36.7786,
    '886_596': 36.8,
    '886_597': 36.8247,
    '886_598': 36.8562,
    '886_599': 36.8934,
    '886_600': 36.9357,
    '886_601': 36.979,
    '886_602': 37.0245,
    '886_603': 37.076,
    '886_604': 37.1345,
    '886_605': 37.1848,
    '886_606': 37.2306,
    '886_607': 37.2708,
    '886_608': 37.3093,
    '886_609': 37.345,
    '886_610': 37.3581,
    '886_611': 37.3458,
    '886_612': 37.3084,
    '886_613': 37.2586,
    '886_614': 37.219,
    '886_615': 37.2067,
    '886_616': 37.2169,
    '886_617': 37.2414,
    '886_618': 37.2787,
    '886_619': 37.3274,
    '886_620': 37.389,
    '886_621': 37.4425,
    '886_622': 37.4846,
    '886_623': 37.5268,
    '886_624': 37.5784,
    '886_625': 37.6452,
    '886_626': 37.7292,
    '886_627': 37.8207,
    '886_628': 37.9221,
    '886_629': 38.0028,
    '886_630': 38.052,
    '886_631': 38.0932,
    '886_632': 38.1506,
    '886_633': 38.2354,
    '886_634': 38.3258,
    '886_635': 38.4072,
    '886_636': 38.4947,
    '886_637': 38.5785,
    '886_638': 38.6464,
    '886_639': 38.6949,
    '886_640': 38.7314,
    '886_641': 38.7588,
    '886_642': 38.78,
    '886_643': 38.7937,
    '886_644': 38.7963,
    '886_645': 38.7913,
    '886_646': 38.7912,
    '886_647': 38.7973,
    '886_648': 38.7999,
    '886_649': 38.8091,
    '886_650': 38.8293,
    '886_651': 38.8283,
    '886_652': 38.8166,
    '886_653': 38.7771,
    '886_654': 38.708,
    '886_655': 38.6147,
    '886_656': 38.4904,
    '886_657': 38.3585,
    '886_658': 38.2512,
    '886_659': 38.1708,
    '886_660': 38.1052,
    '886_661': 38.0495,
    '886_662': 38.0052,
    '886_663': 37.9739,
    '886_664': 37.9541,
    '886_665': 37.9404,
    '886_666': 37.9321,
    '886_667': 37.9293,
    '886_668': 37.9328,
    '886_669': 37.9444,
    '886_670': 37.9655,
    '886_671': 37.9938,
    '886_672': 38.0272,
    '886_673': 38.0628,
    '886_674': 38.1,
    '886_675': 38.1393,
    '886_676': 38.1872,
    '886_677': 38.2477,
    '886_678': 38.3218,
    '886_679': 38.4089,
    '886_680': 38.5005,
    '886_681': 38.5948,
    '886_682': 38.6876,
    '886_683': 38.7765,
    '886_684': 38.8621,
    '886_685': 38.9393,
    '886_686': 39.0121,
    '886_687': 39.0789,
    '886_688': 39.1337,
    '886_689': 39.1944,
    '886_690': 39.2477,
    '886_691': 39.2993,
    '886_692': 39.3443,
    '886_693': 39.3936,
    '886_694': 39.4414,
    '886_695': 39.4843,
    '886_696': 39.5326,
    '886_697': 39.5999,
    '886_698': 39.6758,
    '886_699': 39.7359,
    '886_700': 39.7643,
    '886_701': 39.7887,
    '886_702': 39.8115,
    '886_703': 39.8372,
    '886_704': 39.8678,
    '886_705': 39.8974,
    '886_706': 39.9217,
    '886_707': 39.9428,
    '886_708': 39.9614,
    '886_709': 39.9737,
    '886_710': 39.9771,
    '886_711': 39.975,
    '886_712': 39.9667,
    '886_713': 39.9575,
    '886_714': 39.9552,
    '886_715': 39.9529,
    '886_716': 39.9557,
    '886_717': 39.9685,
    '886_718': 39.9864,
    '886_719': 40.0046,
    '886_720': 40.0225,
    '886_721': 40.0393,
    '886_722': 40.0564,
    '886_723': 40.0703,
    '886_724': 40.0808,
    '886_725': 40.0876,
    '886_726': 40.0853,
    '886_727': 40.062,
    '886_728': 40.0301,
    '886_729': 39.9912,
    '886_730': 39.9514,
    '886_731': 39.9042,
    '886_732': 39.8445,
    '886_733': 39.7735,
    '886_734': 39.6902,
    '886_735': 39.5881,
    '886_736': 39.5035,
    '886_737': 39.4422,
    '886_738': 39.4188,
    '886_739': 39.4075,
    '886_740': 39.4077,
    '886_741': 39.4143,
    '886_742': 39.4256,
    '886_743': 39.3861,
    '886_744': 39.3852,
    '886_745': 39.4503,
    '886_746': 39.6023,
    '886_747': 39.7511,
    '886_748': 39.9104,
    '886_749': 40.0746,
    '886_750': 40.2379,
    '886_751': 40.396,
    '886_752': 40.5348,
    '886_753': 40.6462,
    '886_754': 40.7327,
    '886_755': 40.7771,
    '886_756': 40.7772,
    '886_757': 40.7417,
    '886_758': 40.6824,
    '886_759': 40.6134,
    '886_760': 40.5217,
    '886_761': 40.3994,
    '886_762': 40.25,
    '886_763': 40.0874,
    '886_764': 39.9276,
    '886_765': 39.7879,
    '886_766': 39.6701,
    '886_767': 39.5855,
    '886_768': 39.5253,
    '886_769': 39.4828,
    '886_770': 39.468,
    '886_772': 39.5842,
    '886_773': 39.5636,
    '886_774': 39.5396,
    '886_775': 39.4953,
    '886_776': 39.4006,
    '886_777': 39.2281,
    '886_778': 38.9812,
    '886_779': 38.7166,
    '886_780': 38.4662,
    '886_788': 37.1229,
    '886_789': 37.0073,
    '886_790': 36.8863,
    '886_791': 36.7391,
    '886_792': 36.558,
    '886_793': 36.348,
    '886_794': 36.1223,
    '886_795': 35.8864,
    '886_796': 35.6363,
    '886_797': 35.3801,
    '886_798': 35.1153,
    '886_799': 34.8322,
    '886_800': 34.5273,
    '886_801': 34.1976,
    '886_802': 33.851,
    '886_803': 33.511,
    '886_804': 33.1732,
    '886_805': 32.8526,
    '886_806': 32.5521,
    '886_807': 32.2704,
    '886_808': 32.0015,
    '886_809': 31.7375,
    '886_810': 31.48,
    '886_811': 31.2269,
    '886_812': 30.977,
    '886_813': 30.7403,
    '886_814': 30.5047,
    '886_815': 30.2702,
    '886_816': 30.0259,
    '886_817': 29.7723,
    '886_818': 29.5069,
    '886_819': 29.228,
    '886_820': 28.9439,
    '886_821': 28.6512,
    '886_822': 28.3472,
    '886_823': 28.0316,
    '886_824': 27.705,
    '886_825': 27.372,
    '886_826': 27.0271,
    '886_827': 26.6607,
    '886_828': 26.2852,
    '887_443': 31.8924,
    '887_444': 31.933,
    '887_445': 31.9683,
    '887_446': 31.9946,
    '887_447': 32.0101,
    '887_448': 32.0192,
    '887_449': 32.0217,
    '887_452': 32.0194,
    '887_453': 32.0371,
    '887_454': 32.0562,
    '887_455': 32.0787,
    '887_456': 32.1058,
    '887_457': 32.1417,
    '887_458': 32.184,
    '887_459': 32.2346,
    '887_460': 32.2865,
    '887_461': 32.3388,
    '887_462': 32.3898,
    '887_463': 32.4388,
    '887_464': 32.4867,
    '887_465': 32.5336,
    '887_466': 32.5834,
    '887_467': 32.6393,
    '887_468': 32.7049,
    '887_469': 32.7724,
    '887_470': 32.8339,
    '887_471': 32.8931,
    '887_472': 32.9588,
    '887_473': 33.0243,
    '887_474': 33.0796,
    '887_475': 33.1347,
    '887_476': 33.1948,
    '887_477': 33.2578,
    '887_478': 33.3278,
    '887_479': 33.4085,
    '887_480': 33.5009,
    '887_481': 33.5844,
    '887_482': 33.6814,
    '887_483': 33.7792,
    '887_484': 33.8696,
    '887_485': 33.9491,
    '887_486': 34.0269,
    '887_487': 34.1018,
    '887_488': 34.1716,
    '887_489': 34.2475,
    '887_490': 34.3126,
    '887_491': 34.3629,
    '887_492': 34.4133,
    '887_493': 34.4664,
    '887_494': 34.5126,
    '887_495': 34.5436,
    '887_496': 34.5519,
    '887_497': 34.5432,
    '887_498': 34.5412,
    '887_499': 34.5502,
    '887_500': 34.573,
    '887_501': 34.6069,
    '887_502': 34.6399,
    '887_503': 34.6565,
    '887_504': 34.6669,
    '887_505': 34.6734,
    '887_506': 34.6761,
    '887_507': 34.6812,
    '887_508': 34.6908,
    '887_509': 34.7102,
    '887_510': 34.7341,
    '887_511': 34.7634,
    '887_512': 34.7929,
    '887_513': 34.8202,
    '887_514': 34.8464,
    '887_515': 34.8769,
    '887_516': 34.9145,
    '887_517': 34.9638,
    '887_518': 35.0228,
    '887_519': 35.0875,
    '887_520': 35.1629,
    '887_521': 35.2416,
    '887_522': 35.3204,
    '887_523': 35.3992,
    '887_524': 35.4809,
    '887_525': 35.5646,
    '887_526': 35.6393,
    '887_527': 35.7018,
    '887_528': 35.7545,
    '887_529': 35.7997,
    '887_530': 35.8403,
    '887_531': 35.8834,
    '887_532': 35.9282,
    '887_533': 35.9678,
    '887_534': 36.0022,
    '887_535': 36.0254,
    '887_536': 36.0313,
    '887_537': 36.029,
    '887_538': 36.0387,
    '887_539': 36.0523,
    '887_540': 36.0658,
    '887_541': 36.0747,
    '887_542': 36.0916,
    '887_543': 36.1054,
    '887_544': 36.1129,
    '887_545': 36.1341,
    '887_546': 36.1681,
    '887_547': 36.1851,
    '887_548': 36.1987,
    '887_549': 36.215,
    '887_550': 36.2294,
    '887_551': 36.242,
    '887_552': 36.2468,
    '887_553': 36.2643,
    '887_554': 36.2828,
    '887_555': 36.2985,
    '887_556': 36.3041,
    '887_557': 36.3142,
    '887_558': 36.3438,
    '887_559': 36.3637,
    '887_560': 36.3718,
    '887_561': 36.3921,
    '887_562': 36.4279,
    '887_563': 36.4681,
    '887_564': 36.4968,
    '887_565': 36.5305,
    '887_566': 36.5576,
    '887_567': 36.5722,
    '887_568': 36.594,
    '887_569': 36.6026,
    '887_570': 36.6136,
    '887_571': 36.6231,
    '887_572': 36.6298,
    '887_573': 36.6336,
    '887_574': 36.6243,
    '887_575': 36.6113,
    '887_576': 36.6043,
    '887_577': 36.6031,
    '887_578': 36.6093,
    '887_579': 36.6144,
    '887_580': 36.6286,
    '887_581': 36.6272,
    '887_582': 36.6282,
    '887_583': 36.6336,
    '887_584': 36.6466,
    '887_585': 36.6627,
    '887_586': 36.6806,
    '887_587': 36.6995,
    '887_588': 36.7163,
    '887_589': 36.7315,
    '887_590': 36.7397,
    '887_591': 36.7393,
    '887_592': 36.7396,
    '887_593': 36.7463,
    '887_594': 36.7619,
    '887_595': 36.7806,
    '887_596': 36.8,
    '887_597': 36.8227,
    '887_598': 36.8523,
    '887_599': 36.8858,
    '887_600': 36.9247,
    '887_601': 36.9657,
    '887_602': 37.0117,
    '887_603': 37.0647,
    '887_604': 37.1302,
    '887_605': 37.184,
    '887_606': 37.2277,
    '887_607': 37.2635,
    '887_608': 37.2967,
    '887_609': 37.334,
    '887_610': 37.362,
    '887_611': 37.3612,
    '887_612': 37.3337,
    '887_613': 37.2859,
    '887_614': 37.2449,
    '887_615': 37.2267,
    '887_616': 37.233,
    '887_617': 37.2538,
    '887_618': 37.2883,
    '887_619': 37.3343,
    '887_620': 37.3898,
    '887_621': 37.4325,
    '887_622': 37.467,
    '887_623': 37.5018,
    '887_624': 37.546,
    '887_625': 37.6034,
    '887_626': 37.6719,
    '887_627': 37.7435,
    '887_628': 37.8368,
    '887_629': 37.9256,
    '887_630': 37.9735,
    '887_631': 38.0161,
    '887_632': 38.0759,
    '887_633': 38.1667,
    '887_634': 38.2636,
    '887_635': 38.3446,
    '887_636': 38.4189,
    '887_637': 38.5033,
    '887_638': 38.5769,
    '887_639': 38.6295,
    '887_640': 38.6667,
    '887_641': 38.6926,
    '887_642': 38.706,
    '887_643': 38.7107,
    '887_644': 38.7073,
    '887_645': 38.6987,
    '887_646': 38.6928,
    '887_647': 38.6926,
    '887_648': 38.6892,
    '887_649': 38.6999,
    '887_650': 38.7285,
    '887_651': 38.7387,
    '887_652': 38.7299,
    '887_653': 38.693,
    '887_654': 38.6255,
    '887_655': 38.5344,
    '887_656': 38.4132,
    '887_657': 38.2808,
    '887_658': 38.1761,
    '887_659': 38.0992,
    '887_660': 38.0366,
    '887_661': 37.982,
    '887_662': 37.938,
    '887_663': 37.9066,
    '887_664': 37.8855,
    '887_665': 37.8716,
    '887_666': 37.8638,
    '887_667': 37.8618,
    '887_668': 37.8665,
    '887_669': 37.8799,
    '887_670': 37.9028,
    '887_671': 37.9341,
    '887_672': 37.9718,
    '887_673': 38.0146,
    '887_674': 38.0582,
    '887_675': 38.1038,
    '887_676': 38.158,
    '887_677': 38.2238,
    '887_678': 38.3057,
    '887_679': 38.3989,
    '887_680': 38.4957,
    '887_681': 38.5944,
    '887_682': 38.6922,
    '887_683': 38.7903,
    '887_684': 38.8856,
    '887_685': 38.9688,
    '887_686': 39.0468,
    '887_687': 39.1154,
    '887_688': 39.1723,
    '887_689': 39.2288,
    '887_690': 39.2883,
    '887_691': 39.3462,
    '887_692': 39.3912,
    '887_693': 39.44,
    '887_694': 39.4894,
    '887_695': 39.5374,
    '887_696': 39.5916,
    '887_697': 39.6625,
    '887_698': 39.7466,
    '887_699': 39.8164,
    '887_700': 39.8571,
    '887_701': 39.8772,
    '887_702': 39.8997,
    '887_703': 39.9312,
    '887_704': 39.9665,
    '887_705': 39.9961,
    '887_706': 40.0209,
    '887_707': 40.0429,
    '887_708': 40.0634,
    '887_709': 40.0779,
    '887_710': 40.0841,
    '887_711': 40.0827,
    '887_712': 40.076,
    '887_713': 40.0678,
    '887_714': 40.0662,
    '887_715': 40.0652,
    '887_716': 40.0696,
    '887_717': 40.0844,
    '887_718': 40.1023,
    '887_719': 40.1173,
    '887_720': 40.1323,
    '887_721': 40.1476,
    '887_722': 40.1632,
    '887_723': 40.1744,
    '887_724': 40.1795,
    '887_725': 40.1775,
    '887_726': 40.166,
    '887_727': 40.1362,
    '887_728': 40.1009,
    '887_729': 40.0588,
    '887_730': 40.0136,
    '887_731': 39.9608,
    '887_732': 39.8977,
    '887_733': 39.8228,
    '887_734': 39.7337,
    '887_735': 39.6261,
    '887_736': 39.5297,
    '887_737': 39.4568,
    '887_738': 39.4225,
    '887_739': 39.4036,
    '887_740': 39.4045,
    '887_741': 39.4136,
    '887_742': 39.4192,
    '887_743': 39.3849,
    '887_744': 39.3843,
    '887_745': 39.4403,
    '887_746': 39.583,
    '887_747': 39.7329,
    '887_748': 39.8974,
    '887_749': 40.0693,
    '887_750': 40.2427,
    '887_751': 40.415,
    '887_752': 40.5649,
    '887_753': 40.6851,
    '887_754': 40.777,
    '887_755': 40.8277,
    '887_756': 40.8312,
    '887_757': 40.7978,
    '887_758': 40.7422,
    '887_759': 40.684,
    '887_760': 40.5963,
    '887_761': 40.4666,
    '887_762': 40.3042,
    '887_763': 40.1174,
    '887_764': 39.9393,
    '887_765': 39.7784,
    '887_766': 39.652,
    '887_767': 39.5518,
    '887_768': 39.4763,
    '887_769': 39.4207,
    '887_770': 39.3862,
    '887_772': 39.4819,
    '887_773': 39.4538,
    '887_774': 39.4204,
    '887_775': 39.3579,
    '887_776': 39.2468,
    '887_777': 39.0695,
    '887_778': 38.8377,
    '887_779': 38.5919,
    '887_780': 38.3579,
    '887_788': 37.1352,
    '887_789': 37.0299,
    '887_790': 36.9216,
    '887_791': 36.788,
    '887_792': 36.6132,
    '887_793': 36.4085,
    '887_794': 36.1884,
    '887_795': 35.9548,
    '887_796': 35.7034,
    '887_797': 35.442,
    '887_798': 35.1659,
    '887_799': 34.8741,
    '887_800': 34.5545,
    '887_801': 34.2055,
    '887_802': 33.858,
    '887_803': 33.5155,
    '887_804': 33.1817,
    '887_805': 32.8627,
    '887_806': 32.5599,
    '887_807': 32.2737,
    '887_808': 32.0046,
    '887_809': 31.7405,
    '887_810': 31.4876,
    '887_811': 31.2403,
    '887_812': 30.999,
    '887_813': 30.7672,
    '887_814': 30.5418,
    '887_815': 30.3155,
    '887_816': 30.0813,
    '887_817': 29.8299,
    '887_818': 29.5628,
    '887_819': 29.2822,
    '887_820': 28.9896,
    '887_821': 28.6923,
    '887_822': 28.3877,
    '887_823': 28.0764,
    '887_824': 27.7584,
    '887_825': 27.4285,
    '887_826': 27.0867,
    '887_827': 26.7211,
    '887_828': 26.3431,
    '888_443': 31.8817,
    '888_444': 31.92,
    '888_445': 31.952,
    '888_446': 31.9754,
    '888_447': 31.9884,
    '888_448': 31.9935,
    '888_449': 31.9931,
    '888_452': 31.9927,
    '888_453': 32.0092,
    '888_454': 32.0275,
    '888_455': 32.0492,
    '888_456': 32.0772,
    '888_457': 32.1106,
    '888_458': 32.1499,
    '888_459': 32.1981,
    '888_460': 32.2489,
    '888_461': 32.3006,
    '888_462': 32.3518,
    '888_463': 32.4012,
    '888_464': 32.4518,
    '888_465': 32.5024,
    '888_466': 32.554,
    '888_467': 32.6141,
    '888_468': 32.6837,
    '888_469': 32.7531,
    '888_470': 32.819,
    '888_471': 32.8817,
    '888_472': 32.9488,
    '888_473': 33.0153,
    '888_474': 33.0763,
    '888_475': 33.1353,
    '888_476': 33.194,
    '888_477': 33.2528,
    '888_478': 33.3187,
    '888_479': 33.3993,
    '888_480': 33.4958,
    '888_481': 33.586,
    '888_482': 33.6683,
    '888_483': 33.7592,
    '888_484': 33.8447,
    '888_485': 33.9155,
    '888_486': 33.9843,
    '888_487': 34.0601,
    '888_488': 34.1344,
    '888_489': 34.2135,
    '888_490': 34.2831,
    '888_491': 34.3367,
    '888_492': 34.3872,
    '888_493': 34.4453,
    '888_494': 34.4967,
    '888_495': 34.5322,
    '888_496': 34.5462,
    '888_497': 34.5446,
    '888_498': 34.5506,
    '888_499': 34.5627,
    '888_500': 34.5856,
    '888_501': 34.6203,
    '888_502': 34.6519,
    '888_503': 34.671,
    '888_504': 34.6874,
    '888_505': 34.6952,
    '888_506': 34.6915,
    '888_507': 34.6989,
    '888_508': 34.7099,
    '888_509': 34.734,
    '888_510': 34.7623,
    '888_511': 34.7927,
    '888_512': 34.8219,
    '888_513': 34.8484,
    '888_514': 34.8681,
    '888_515': 34.9012,
    '888_516': 34.9424,
    '888_517': 34.9914,
    '888_518': 35.0493,
    '888_519': 35.1143,
    '888_520': 35.1831,
    '888_521': 35.2595,
    '888_522': 35.3406,
    '888_523': 35.4254,
    '888_524': 35.5075,
    '888_525': 35.5895,
    '888_526': 35.6633,
    '888_527': 35.7241,
    '888_528': 35.7742,
    '888_529': 35.8156,
    '888_530': 35.8546,
    '888_531': 35.896,
    '888_532': 35.9412,
    '888_533': 35.9834,
    '888_534': 36.018,
    '888_535': 36.043,
    '888_536': 36.0537,
    '888_537': 36.0541,
    '888_538': 36.0707,
    '888_539': 36.0891,
    '888_540': 36.1006,
    '888_541': 36.1077,
    '888_542': 36.1192,
    '888_543': 36.1286,
    '888_544': 36.1315,
    '888_545': 36.1437,
    '888_546': 36.1811,
    '888_547': 36.2049,
    '888_548': 36.2259,
    '888_549': 36.2485,
    '888_550': 36.2614,
    '888_551': 36.2755,
    '888_552': 36.286,
    '888_553': 36.2989,
    '888_554': 36.3074,
    '888_555': 36.3182,
    '888_556': 36.3233,
    '888_557': 36.3338,
    '888_558': 36.3524,
    '888_559': 36.3742,
    '888_560': 36.3897,
    '888_561': 36.4099,
    '888_562': 36.4455,
    '888_563': 36.4766,
    '888_564': 36.5091,
    '888_565': 36.5327,
    '888_566': 36.5554,
    '888_567': 36.5782,
    '888_568': 36.6006,
    '888_569': 36.6185,
    '888_570': 36.6341,
    '888_571': 36.6395,
    '888_572': 36.6441,
    '888_573': 36.6458,
    '888_574': 36.6389,
    '888_575': 36.6298,
    '888_576': 36.6171,
    '888_577': 36.6205,
    '888_578': 36.6225,
    '888_579': 36.6292,
    '888_580': 36.6454,
    '888_581': 36.6425,
    '888_582': 36.6427,
    '888_583': 36.6467,
    '888_584': 36.6591,
    '888_585': 36.6763,
    '888_586': 36.6943,
    '888_587': 36.7139,
    '888_588': 36.7319,
    '888_589': 36.7438,
    '888_590': 36.7526,
    '888_591': 36.7528,
    '888_592': 36.7546,
    '888_593': 36.7588,
    '888_594': 36.7745,
    '888_595': 36.787,
    '888_596': 36.8024,
    '888_597': 36.8223,
    '888_598': 36.848,
    '888_599': 36.8776,
    '888_600': 36.9107,
    '888_601': 36.952,
    '888_602': 36.998,
    '888_603': 37.0491,
    '888_604': 37.108,
    '888_605': 37.1625,
    '888_606': 37.2052,
    '888_607': 37.2402,
    '888_608': 37.2719,
    '888_609': 37.3039,
    '888_610': 37.3344,
    '888_611': 37.3496,
    '888_612': 37.3358,
    '888_613': 37.3038,
    '888_614': 37.2647,
    '888_615': 37.2514,
    '888_616': 37.256,
    '888_617': 37.2746,
    '888_618': 37.3078,
    '888_619': 37.3518,
    '888_620': 37.3977,
    '888_621': 37.4306,
    '888_622': 37.4576,
    '888_623': 37.4849,
    '888_624': 37.5211,
    '888_625': 37.5695,
    '888_626': 37.6237,
    '888_627': 37.6803,
    '888_628': 37.7554,
    '888_629': 37.8396,
    '888_630': 37.8956,
    '888_631': 37.9421,
    '888_632': 38.0072,
    '888_633': 38.105,
    '888_634': 38.2042,
    '888_635': 38.2847,
    '888_636': 38.3486,
    '888_637': 38.4264,
    '888_638': 38.5017,
    '888_639': 38.5531,
    '888_640': 38.5944,
    '888_641': 38.6259,
    '888_642': 38.6433,
    '888_643': 38.6477,
    '888_644': 38.6367,
    '888_645': 38.6206,
    '888_646': 38.6037,
    '888_647': 38.5887,
    '888_648': 38.5813,
    '888_649': 38.5946,
    '888_650': 38.6297,
    '888_651': 38.6509,
    '888_652': 38.6417,
    '888_653': 38.6003,
    '888_654': 38.5265,
    '888_655': 38.4339,
    '888_656': 38.3189,
    '888_657': 38.1974,
    '888_658': 38.1011,
    '888_659': 38.0273,
    '888_660': 37.9656,
    '888_661': 37.9124,
    '888_662': 37.8688,
    '888_663': 37.8371,
    '888_664': 37.8147,
    '888_665': 37.802,
    '888_666': 37.7953,
    '888_667': 37.7954,
    '888_668': 37.8021,
    '888_669': 37.818,
    '888_670': 37.844,
    '888_671': 37.8799,
    '888_672': 37.9238,
    '888_673': 37.9748,
    '888_674': 38.0252,
    '888_675': 38.0764,
    '888_676': 38.136,
    '888_677': 38.2063,
    '888_678': 38.2925,
    '888_679': 38.3902,
    '888_680': 38.4948,
    '888_681': 38.5984,
    '888_682': 38.7005,
    '888_683': 38.8049,
    '888_684': 38.9103,
    '888_685': 39.0043,
    '888_686': 39.0922,
    '888_687': 39.1575,
    '888_688': 39.2145,
    '888_689': 39.2741,
    '888_690': 39.3377,
    '888_691': 39.3983,
    '888_692': 39.4498,
    '888_693': 39.4992,
    '888_694': 39.5504,
    '888_695': 39.6031,
    '888_696': 39.6626,
    '888_697': 39.7352,
    '888_698': 39.8191,
    '888_699': 39.895,
    '888_700': 39.9475,
    '888_701': 39.9681,
    '888_702': 39.9967,
    '888_703': 40.0369,
    '888_704': 40.0771,
    '888_705': 40.1033,
    '888_706': 40.1242,
    '888_707': 40.1461,
    '888_708': 40.1698,
    '888_709': 40.1863,
    '888_710': 40.1951,
    '888_711': 40.1946,
    '888_712': 40.1895,
    '888_713': 40.1814,
    '888_714': 40.18,
    '888_715': 40.1828,
    '888_716': 40.1907,
    '888_717': 40.2067,
    '888_718': 40.2243,
    '888_719': 40.239,
    '888_720': 40.2507,
    '888_721': 40.2632,
    '888_722': 40.2786,
    '888_723': 40.288,
    '888_724': 40.2878,
    '888_725': 40.2752,
    '888_726': 40.2529,
    '888_727': 40.218,
    '888_728': 40.1773,
    '888_729': 40.1304,
    '888_730': 40.0787,
    '888_731': 40.0206,
    '888_732': 39.9529,
    '888_733': 39.873,
    '888_734': 39.777,
    '888_735': 39.6635,
    '888_736': 39.5599,
    '888_737': 39.4774,
    '888_738': 39.4313,
    '888_739': 39.4041,
    '888_740': 39.4029,
    '888_741': 39.4076,
    '888_742': 39.406,
    '888_743': 39.3791,
    '888_744': 39.3821,
    '888_745': 39.4316,
    '888_746': 39.5652,
    '888_747': 39.7154,
    '888_748': 39.8856,
    '888_749': 40.0652,
    '888_750': 40.2485,
    '888_751': 40.4316,
    '888_752': 40.5954,
    '888_753': 40.7138,
    '888_754': 40.8127,
    '888_755': 40.8683,
    '888_756': 40.8774,
    '888_757': 40.8525,
    '888_758': 40.8144,
    '888_759': 40.7557,
    '888_760': 40.6639,
    '888_761': 40.5273,
    '888_762': 40.3534,
    '888_763': 40.1525,
    '888_764': 39.9543,
    '888_765': 39.7728,
    '888_766': 39.6268,
    '888_767': 39.5092,
    '888_768': 39.4201,
    '888_769': 39.3481,
    '888_770': 39.2954,
    '888_772': 39.363,
    '888_773': 39.3196,
    '888_774': 39.2707,
    '888_775': 39.1885,
    '888_776': 39.0605,
    '888_777': 38.8872,
    '888_778': 38.6774,
    '888_779': 38.4517,
    '888_780': 38.2404,
    '888_788': 37.1399,
    '888_789': 37.0402,
    '888_790': 36.941,
    '888_791': 36.8206,
    '888_792': 36.6579,
    '888_793': 36.4658,
    '888_794': 36.2569,
    '888_795': 36.0315,
    '888_796': 35.7835,
    '888_797': 35.5201,
    '888_798': 35.2315,
    '888_799': 34.9309,
    '888_800': 34.6012,
    '888_801': 34.255,
    '888_802': 33.8946,
    '888_803': 33.5394,
    '888_804': 33.2002,
    '888_805': 32.8755,
    '888_806': 32.5698,
    '888_807': 32.2823,
    '888_808': 32.0098,
    '888_809': 31.7442,
    '888_810': 31.4994,
    '888_811': 31.2594,
    '888_812': 31.0236,
    '888_813': 30.7981,
    '888_814': 30.5882,
    '888_815': 30.3736,
    '888_816': 30.1474,
    '888_817': 29.9036,
    '888_818': 29.6417,
    '888_819': 29.3552,
    '888_820': 29.0649,
    '888_821': 28.7671,
    '888_822': 28.4514,
    '888_823': 28.1408,
    '888_824': 27.8212,
    '888_825': 27.4911,
    '888_826': 27.1465,
    '888_827': 26.7809,
    '888_828': 26.4066,
    '889_443': 31.8696,
    '889_444': 31.906,
    '889_445': 31.9356,
    '889_446': 31.9575,
    '889_447': 31.9663,
    '889_448': 31.9683,
    '889_449': 31.9654,
    '889_452': 31.9681,
    '889_453': 31.9842,
    '889_454': 32.0029,
    '889_455': 32.0263,
    '889_456': 32.0535,
    '889_457': 32.0839,
    '889_458': 32.1188,
    '889_459': 32.1642,
    '889_460': 32.2141,
    '889_461': 32.2659,
    '889_462': 32.318,
    '889_463': 32.3685,
    '889_464': 32.4205,
    '889_465': 32.4732,
    '889_466': 32.5305,
    '889_467': 32.5962,
    '889_468': 32.6659,
    '889_469': 32.7339,
    '889_470': 32.8013,
    '889_471': 32.8695,
    '889_472': 32.9374,
    '889_473': 33.0035,
    '889_474': 33.0687,
    '889_475': 33.1312,
    '889_476': 33.1918,
    '889_477': 33.2452,
    '889_478': 33.3053,
    '889_479': 33.3819,
    '889_480': 33.4824,
    '889_481': 33.5745,
    '889_482': 33.647,
    '889_483': 33.7232,
    '889_484': 33.8068,
    '889_485': 33.8741,
    '889_486': 33.942,
    '889_487': 34.0186,
    '889_488': 34.0951,
    '889_489': 34.1724,
    '889_490': 34.2433,
    '889_491': 34.2961,
    '889_492': 34.3425,
    '889_493': 34.4016,
    '889_494': 34.4559,
    '889_495': 34.4972,
    '889_496': 34.5173,
    '889_497': 34.5305,
    '889_498': 34.5446,
    '889_499': 34.5579,
    '889_500': 34.5794,
    '889_501': 34.6131,
    '889_502': 34.6454,
    '889_503': 34.6695,
    '889_504': 34.6914,
    '889_505': 34.7049,
    '889_506': 34.7031,
    '889_507': 34.7091,
    '889_508': 34.7236,
    '889_509': 34.7532,
    '889_510': 34.7949,
    '889_511': 34.8331,
    '889_512': 34.8562,
    '889_513': 34.8723,
    '889_514': 34.8993,
    '889_515': 34.9393,
    '889_516': 34.9837,
    '889_517': 35.0274,
    '889_518': 35.0787,
    '889_519': 35.1417,
    '889_520': 35.2059,
    '889_521': 35.2788,
    '889_522': 35.3596,
    '889_523': 35.4496,
    '889_524': 35.5384,
    '889_525': 35.6195,
    '889_526': 35.6909,
    '889_527': 35.7482,
    '889_528': 35.7942,
    '889_529': 35.8343,
    '889_530': 35.8732,
    '889_531': 35.9152,
    '889_532': 35.9604,
    '889_533': 36.0004,
    '889_534': 36.0325,
    '889_535': 36.0588,
    '889_536': 36.0744,
    '889_537': 36.0858,
    '889_538': 36.1046,
    '889_539': 36.1257,
    '889_540': 36.1368,
    '889_541': 36.1462,
    '889_542': 36.152,
    '889_543': 36.1529,
    '889_544': 36.1469,
    '889_545': 36.1581,
    '889_546': 36.1917,
    '889_547': 36.2227,
    '889_548': 36.2498,
    '889_549': 36.2715,
    '889_550': 36.2856,
    '889_551': 36.2974,
    '889_552': 36.3102,
    '889_553': 36.3247,
    '889_554': 36.3277,
    '889_555': 36.3343,
    '889_556': 36.3392,
    '889_557': 36.3548,
    '889_558': 36.3695,
    '889_559': 36.3876,
    '889_560': 36.4063,
    '889_561': 36.4275,
    '889_562': 36.4617,
    '889_563': 36.4923,
    '889_564': 36.5255,
    '889_565': 36.5492,
    '889_566': 36.5709,
    '889_567': 36.5934,
    '889_568': 36.6128,
    '889_569': 36.6372,
    '889_570': 36.6568,
    '889_571': 36.6624,
    '889_572': 36.6605,
    '889_573': 36.6547,
    '889_574': 36.6446,
    '889_575': 36.6353,
    '889_576': 36.6366,
    '889_577': 36.6406,
    '889_578': 36.6425,
    '889_579': 36.6471,
    '889_580': 36.6557,
    '889_581': 36.6565,
    '889_582': 36.6564,
    '889_583': 36.6614,
    '889_584': 36.6745,
    '889_585': 36.6943,
    '889_586': 36.7101,
    '889_587': 36.73,
    '889_588': 36.7457,
    '889_589': 36.7568,
    '889_590': 36.7662,
    '889_591': 36.7736,
    '889_592': 36.7734,
    '889_593': 36.7738,
    '889_594': 36.7847,
    '889_595': 36.7978,
    '889_596': 36.8092,
    '889_597': 36.8242,
    '889_598': 36.8437,
    '889_599': 36.8718,
    '889_600': 36.9026,
    '889_601': 36.9403,
    '889_602': 36.9841,
    '889_603': 37.0303,
    '889_604': 37.0777,
    '889_605': 37.1261,
    '889_606': 37.168,
    '889_607': 37.2052,
    '889_608': 37.2378,
    '889_609': 37.2705,
    '889_610': 37.3054,
    '889_611': 37.3318,
    '889_612': 37.3301,
    '889_613': 37.3106,
    '889_614': 37.295,
    '889_615': 37.2852,
    '889_616': 37.2875,
    '889_617': 37.3043,
    '889_618': 37.3365,
    '889_619': 37.3757,
    '889_620': 37.4129,
    '889_621': 37.4367,
    '889_622': 37.4562,
    '889_623': 37.4764,
    '889_624': 37.5038,
    '889_625': 37.541,
    '889_626': 37.5831,
    '889_627': 37.6285,
    '889_628': 37.6866,
    '889_629': 37.7569,
    '889_630': 37.8174,
    '889_631': 37.8671,
    '889_632': 37.937,
    '889_633': 38.0371,
    '889_634': 38.1351,
    '889_635': 38.2141,
    '889_636': 38.2856,
    '889_637': 38.3522,
    '889_638': 38.4138,
    '889_639': 38.4624,
    '889_640': 38.5051,
    '889_641': 38.5415,
    '889_642': 38.5631,
    '889_643': 38.5723,
    '889_644': 38.5617,
    '889_645': 38.538,
    '889_646': 38.5176,
    '889_647': 38.4926,
    '889_648': 38.478,
    '889_649': 38.4875,
    '889_650': 38.52,
    '889_651': 38.5463,
    '889_652': 38.5355,
    '889_653': 38.4949,
    '889_654': 38.4275,
    '889_655': 38.3409,
    '889_656': 38.2303,
    '889_657': 38.1174,
    '889_658': 38.0274,
    '889_659': 37.9545,
    '889_660': 37.8923,
    '889_661': 37.8399,
    '889_662': 37.7965,
    '889_663': 37.7648,
    '889_664': 37.7439,
    '889_665': 37.7314,
    '889_666': 37.7267,
    '889_667': 37.7291,
    '889_668': 37.7397,
    '889_669': 37.7599,
    '889_670': 37.7906,
    '889_671': 37.8323,
    '889_672': 37.8853,
    '889_673': 37.9443,
    '889_674': 37.998,
    '889_675': 38.0535,
    '889_676': 38.1177,
    '889_677': 38.1926,
    '889_678': 38.2833,
    '889_679': 38.3844,
    '889_680': 38.4922,
    '889_681': 38.6027,
    '889_682': 38.7115,
    '889_683': 38.8203,
    '889_684': 38.9307,
    '889_685': 39.034,
    '889_686': 39.1279,
    '889_687': 39.1988,
    '889_688': 39.2616,
    '889_689': 39.3274,
    '889_690': 39.3978,
    '889_691': 39.468,
    '889_692': 39.5206,
    '889_693': 39.5705,
    '889_694': 39.6231,
    '889_695': 39.6793,
    '889_696': 39.7419,
    '889_697': 39.8158,
    '889_698': 39.897,
    '889_699': 39.9773,
    '889_700': 40.039,
    '889_701': 40.0647,
    '889_702': 40.1024,
    '889_703': 40.1534,
    '889_704': 40.1982,
    '889_705': 40.2236,
    '889_706': 40.2395,
    '889_707': 40.2561,
    '889_708': 40.2814,
    '889_709': 40.2976,
    '889_710': 40.3087,
    '889_711': 40.3095,
    '889_712': 40.3058,
    '889_713': 40.2976,
    '889_714': 40.2968,
    '889_715': 40.3041,
    '889_716': 40.3167,
    '889_717': 40.3318,
    '889_718': 40.3513,
    '889_719': 40.3665,
    '889_720': 40.3762,
    '889_721': 40.3853,
    '889_722': 40.3981,
    '889_723': 40.4052,
    '889_724': 40.3985,
    '889_725': 40.3725,
    '889_726': 40.3377,
    '889_727': 40.2989,
    '889_728': 40.2556,
    '889_729': 40.2038,
    '889_730': 40.1457,
    '889_731': 40.0818,
    '889_732': 40.0097,
    '889_733': 39.9252,
    '889_734': 39.8206,
    '889_735': 39.7047,
    '889_736': 39.597,
    '889_737': 39.5075,
    '889_738': 39.4493,
    '889_739': 39.4138,
    '889_740': 39.408,
    '889_741': 39.4026,
    '889_742': 39.3935,
    '889_743': 39.3767,
    '889_744': 39.3803,
    '889_745': 39.4216,
    '889_746': 39.534,
    '889_747': 39.6981,
    '889_748': 39.8734,
    '889_749': 40.0594,
    '889_750': 40.2517,
    '889_751': 40.4416,
    '889_752': 40.6123,
    '889_753': 40.7339,
    '889_754': 40.8295,
    '889_755': 40.8942,
    '889_756': 40.9145,
    '889_757': 40.9029,
    '889_758': 40.8756,
    '889_759': 40.8191,
    '889_760': 40.7214,
    '889_761': 40.5808,
    '889_762': 40.4001,
    '889_763': 40.1875,
    '889_764': 39.9673,
    '889_765': 39.7709,
    '889_766': 39.6006,
    '889_767': 39.4675,
    '889_768': 39.3599,
    '889_769': 39.2642,
    '889_770': 39.198,
    '889_772': 39.2272,
    '889_773': 39.1594,
    '889_774': 39.0877,
    '889_775': 38.9944,
    '889_776': 38.8672,
    '889_777': 38.7035,
    '889_778': 38.5131,
    '889_779': 38.3127,
    '889_780': 38.1224,
    '889_788': 37.1481,
    '889_789': 37.0509,
    '889_790': 36.9531,
    '889_791': 36.8424,
    '889_792': 36.6895,
    '889_793': 36.5125,
    '889_794': 36.3208,
    '889_795': 36.107,
    '889_796': 35.8644,
    '889_797': 35.5962,
    '889_798': 35.3094,
    '889_799': 35.006,
    '889_800': 34.6651,
    '889_801': 34.3036,
    '889_802': 33.9361,
    '889_803': 33.578,
    '889_804': 33.2328,
    '889_805': 32.9014,
    '889_806': 32.5885,
    '889_807': 32.2979,
    '889_808': 32.0252,
    '889_809': 31.7637,
    '889_810': 31.5197,
    '889_811': 31.2767,
    '889_812': 31.0545,
    '889_813': 30.8463,
    '889_814': 30.6487,
    '889_815': 30.4428,
    '889_816': 30.2239,
    '889_817': 29.9843,
    '889_818': 29.7258,
    '889_819': 29.4427,
    '889_820': 29.1539,
    '889_821': 28.8536,
    '889_822': 28.5365,
    '889_823': 28.2235,
    '889_824': 27.8978,
    '889_825': 27.5629,
    '889_826': 27.2144,
    '889_827': 26.8527,
    '889_828': 26.4828,
    '890_443': 31.8558,
    '890_444': 31.89,
    '890_445': 31.9157,
    '890_446': 31.934,
    '890_447': 31.9419,
    '890_448': 31.9424,
    '890_449': 31.9381,
    '890_452': 31.946,
    '890_453': 31.9625,
    '890_454': 31.983,
    '890_455': 32.0076,
    '890_456': 32.0337,
    '890_457': 32.0601,
    '890_458': 32.0906,
    '890_459': 32.1343,
    '890_460': 32.183,
    '890_461': 32.2338,
    '890_462': 32.2857,
    '890_463': 32.3373,
    '890_464': 32.3904,
    '890_465': 32.4471,
    '890_466': 32.5113,
    '890_467': 32.5808,
    '890_468': 32.6477,
    '890_469': 32.7145,
    '890_470': 32.784,
    '890_471': 32.8577,
    '890_472': 32.9273,
    '890_473': 32.9949,
    '890_474': 33.0596,
    '890_475': 33.1205,
    '890_476': 33.1774,
    '890_477': 33.23,
    '890_478': 33.2872,
    '890_479': 33.3561,
    '890_480': 33.4446,
    '890_481': 33.5371,
    '890_482': 33.61,
    '890_483': 33.6875,
    '890_484': 33.7621,
    '890_485': 33.8284,
    '890_486': 33.9005,
    '890_487': 33.9729,
    '890_488': 34.0509,
    '890_489': 34.1257,
    '890_490': 34.1953,
    '890_491': 34.2482,
    '890_492': 34.2947,
    '890_493': 34.3467,
    '890_494': 34.401,
    '890_495': 34.4478,
    '890_496': 34.4811,
    '890_497': 34.5098,
    '890_498': 34.5302,
    '890_499': 34.5455,
    '890_500': 34.5639,
    '890_501': 34.5939,
    '890_502': 34.6282,
    '890_503': 34.6598,
    '890_504': 34.6855,
    '890_505': 34.7041,
    '890_506': 34.7148,
    '890_507': 34.7295,
    '890_508': 34.7512,
    '890_509': 34.7846,
    '890_510': 34.837,
    '890_511': 34.8736,
    '890_512': 34.8898,
    '890_513': 34.907,
    '890_514': 34.9347,
    '890_515': 34.9781,
    '890_516': 35.0226,
    '890_517': 35.068,
    '890_518': 35.1176,
    '890_519': 35.1726,
    '890_520': 35.234,
    '890_521': 35.3031,
    '890_522': 35.3828,
    '890_523': 35.4737,
    '890_524': 35.5661,
    '890_525': 35.6508,
    '890_526': 35.7213,
    '890_527': 35.7731,
    '890_528': 35.8163,
    '890_529': 35.8579,
    '890_530': 35.9004,
    '890_531': 35.9437,
    '890_532': 35.9859,
    '890_533': 36.0255,
    '890_534': 36.0528,
    '890_535': 36.0741,
    '890_536': 36.0987,
    '890_537': 36.1192,
    '890_538': 36.1344,
    '890_539': 36.1596,
    '890_540': 36.172,
    '890_541': 36.1799,
    '890_542': 36.1747,
    '890_543': 36.164,
    '890_544': 36.154,
    '890_545': 36.1712,
    '890_546': 36.2034,
    '890_547': 36.2387,
    '890_548': 36.2687,
    '890_549': 36.2879,
    '890_550': 36.3048,
    '890_551': 36.3177,
    '890_552': 36.3292,
    '890_553': 36.3469,
    '890_554': 36.3508,
    '890_555': 36.3549,
    '890_556': 36.3532,
    '890_557': 36.3697,
    '890_558': 36.3935,
    '890_559': 36.4095,
    '890_560': 36.4279,
    '890_561': 36.4511,
    '890_562': 36.4819,
    '890_563': 36.5069,
    '890_564': 36.5409,
    '890_565': 36.5712,
    '890_566': 36.5961,
    '890_567': 36.6209,
    '890_568': 36.643,
    '890_569': 36.6579,
    '890_570': 36.6712,
    '890_571': 36.6821,
    '890_572': 36.673,
    '890_573': 36.6593,
    '890_574': 36.6481,
    '890_575': 36.6469,
    '890_576': 36.6527,
    '890_577': 36.6609,
    '890_578': 36.6685,
    '890_579': 36.6766,
    '890_580': 36.6741,
    '890_581': 36.6729,
    '890_582': 36.6724,
    '890_583': 36.6769,
    '890_584': 36.6895,
    '890_585': 36.7088,
    '890_586': 36.7255,
    '890_587': 36.7448,
    '890_588': 36.7602,
    '890_589': 36.7723,
    '890_590': 36.7828,
    '890_591': 36.7944,
    '890_592': 36.7961,
    '890_593': 36.7931,
    '890_594': 36.7981,
    '890_595': 36.8067,
    '890_596': 36.8132,
    '890_597': 36.8213,
    '890_598': 36.8397,
    '890_599': 36.8677,
    '890_600': 36.8991,
    '890_601': 36.9332,
    '890_602': 36.9725,
    '890_603': 37.0127,
    '890_604': 37.055,
    '890_605': 37.1006,
    '890_606': 37.1408,
    '890_607': 37.1777,
    '890_608': 37.212,
    '890_609': 37.2468,
    '890_610': 37.2853,
    '890_611': 37.3185,
    '890_612': 37.3284,
    '890_613': 37.3305,
    '890_614': 37.3354,
    '890_615': 37.3313,
    '890_616': 37.3316,
    '890_617': 37.3468,
    '890_618': 37.3844,
    '890_619': 37.4125,
    '890_620': 37.4349,
    '890_621': 37.4514,
    '890_622': 37.4631,
    '890_623': 37.4775,
    '890_624': 37.4966,
    '890_625': 37.5209,
    '890_626': 37.5534,
    '890_627': 37.5893,
    '890_628': 37.6325,
    '890_629': 37.6838,
    '890_630': 37.7369,
    '890_631': 37.7915,
    '890_632': 37.8658,
    '890_633': 37.9599,
    '890_634': 38.0492,
    '890_635': 38.1287,
    '890_636': 38.2081,
    '890_637': 38.2738,
    '890_638': 38.3205,
    '890_639': 38.3643,
    '890_640': 38.4044,
    '890_641': 38.4413,
    '890_642': 38.4642,
    '890_643': 38.4772,
    '890_644': 38.4692,
    '890_645': 38.4423,
    '890_646': 38.4196,
    '890_647': 38.3952,
    '890_648': 38.379,
    '890_649': 38.3835,
    '890_650': 38.4073,
    '890_651': 38.4318,
    '890_652': 38.4203,
    '890_653': 38.3813,
    '890_654': 38.3257,
    '890_655': 38.2447,
    '890_656': 38.1402,
    '890_657': 38.0393,
    '890_658': 37.9546,
    '890_659': 37.883,
    '890_660': 37.8208,
    '890_661': 37.7676,
    '890_662': 37.7247,
    '890_663': 37.6935,
    '890_664': 37.6733,
    '890_665': 37.6627,
    '890_666': 37.6609,
    '890_667': 37.6668,
    '890_668': 37.6805,
    '890_669': 37.705,
    '890_670': 37.7414,
    '890_671': 37.7899,
    '890_672': 37.8518,
    '890_673': 37.9206,
    '890_674': 37.975,
    '890_675': 38.032,
    '890_676': 38.1,
    '890_677': 38.1811,
    '890_678': 38.2764,
    '890_679': 38.3803,
    '890_680': 38.4904,
    '890_681': 38.6058,
    '890_682': 38.7201,
    '890_683': 38.8317,
    '890_684': 38.9429,
    '890_685': 39.0527,
    '890_686': 39.1474,
    '890_687': 39.2301,
    '890_688': 39.3121,
    '890_689': 39.388,
    '890_690': 39.4674,
    '890_691': 39.5457,
    '890_692': 39.5998,
    '890_693': 39.6495,
    '890_694': 39.7051,
    '890_695': 39.7645,
    '890_696': 39.8282,
    '890_697': 39.8989,
    '890_698': 39.977,
    '890_699': 40.0545,
    '890_700': 40.1227,
    '890_701': 40.1637,
    '890_702': 40.2133,
    '890_703': 40.2693,
    '890_704': 40.3151,
    '890_705': 40.3385,
    '890_706': 40.3563,
    '890_707': 40.376,
    '890_708': 40.4029,
    '890_709': 40.4147,
    '890_710': 40.4241,
    '890_711': 40.4285,
    '890_712': 40.4257,
    '890_713': 40.419,
    '890_714': 40.4211,
    '890_715': 40.4315,
    '890_716': 40.4466,
    '890_717': 40.4602,
    '890_718': 40.4789,
    '890_719': 40.496,
    '890_720': 40.5096,
    '890_721': 40.5163,
    '890_722': 40.5231,
    '890_723': 40.5252,
    '890_724': 40.5045,
    '890_725': 40.4669,
    '890_726': 40.4269,
    '890_727': 40.3852,
    '890_728': 40.3384,
    '890_729': 40.2815,
    '890_730': 40.2169,
    '890_731': 40.1473,
    '890_732': 40.0728,
    '890_733': 39.9852,
    '890_734': 39.8745,
    '890_735': 39.7543,
    '890_736': 39.6443,
    '890_737': 39.5487,
    '890_738': 39.479,
    '890_739': 39.4366,
    '890_740': 39.42,
    '890_741': 39.4074,
    '890_742': 39.3949,
    '890_743': 39.3799,
    '890_744': 39.3804,
    '890_745': 39.4198,
    '890_746': 39.521,
    '890_747': 39.6763,
    '890_748': 39.8558,
    '890_749': 40.0479,
    '890_750': 40.2464,
    '890_751': 40.4424,
    '890_752': 40.6126,
    '890_753': 40.7434,
    '890_754': 40.8442,
    '890_755': 40.9095,
    '890_756': 40.9335,
    '890_757': 40.9313,
    '890_758': 40.9106,
    '890_759': 40.8604,
    '890_760': 40.7679,
    '890_761': 40.6289,
    '890_762': 40.447,
    '890_763': 40.2205,
    '890_764': 39.9828,
    '890_765': 39.7703,
    '890_766': 39.5769,
    '890_767': 39.4252,
    '890_768': 39.2904,
    '890_769': 39.1753,
    '890_770': 39.0831,
    '890_772': 39.0771,
    '890_773': 38.9867,
    '890_774': 38.8977,
    '890_775': 38.7987,
    '890_776': 38.6777,
    '890_777': 38.5257,
    '890_778': 38.352,
    '890_779': 38.1722,
    '890_780': 38.0034,
    '890_788': 37.1503,
    '890_789': 37.055,
    '890_790': 36.9553,
    '890_791': 36.8474,
    '890_792': 36.7061,
    '890_793': 36.5437,
    '890_794': 36.3601,
    '890_795': 36.1541,
    '890_796': 35.9284,
    '890_797': 35.6678,
    '890_798': 35.3846,
    '890_799': 35.0805,
    '890_800': 34.7272,
    '890_801': 34.3551,
    '890_802': 33.9839,
    '890_803': 33.6194,
    '890_804': 33.2705,
    '890_805': 32.9379,
    '890_806': 32.6213,
    '890_807': 32.3222,
    '890_808': 32.0441,
    '890_809': 31.7843,
    '890_810': 31.5406,
    '890_811': 31.3077,
    '890_812': 31.0938,
    '890_813': 30.8946,
    '890_814': 30.7097,
    '890_815': 30.5153,
    '890_816': 30.3051,
    '890_817': 30.0678,
    '890_818': 29.8101,
    '890_819': 29.536,
    '890_820': 29.2541,
    '890_821': 28.9529,
    '890_822': 28.6281,
    '890_823': 28.3089,
    '890_824': 27.9798,
    '890_825': 27.644,
    '890_826': 27.2945,
    '890_827': 26.9342,
    '890_828': 26.5689,
    '891_452': 31.925,
    '891_453': 31.9441,
    '891_454': 31.9658,
    '891_455': 31.99,
    '891_456': 32.0145,
    '891_457': 32.0363,
    '891_458': 32.0683,
    '891_459': 32.1093,
    '891_460': 32.1555,
    '891_461': 32.2047,
    '891_462': 32.2568,
    '891_463': 32.3093,
    '891_464': 32.3651,
    '891_465': 32.4264,
    '891_466': 32.4952,
    '891_467': 32.5658,
    '891_468': 32.6275,
    '891_469': 32.6924,
    '891_470': 32.7655,
    '891_471': 32.8444,
    '891_472': 32.9161,
    '891_473': 32.9851,
    '891_474': 33.0487,
    '891_475': 33.1053,
    '891_476': 33.1575,
    '891_477': 33.2093,
    '891_478': 33.2649,
    '891_479': 33.3278,
    '891_480': 33.4025,
    '891_481': 33.4855,
    '891_482': 33.5635,
    '891_483': 33.6471,
    '891_484': 33.7183,
    '891_485': 33.7824,
    '891_486': 33.8511,
    '891_487': 33.9229,
    '891_488': 33.999,
    '891_489': 34.0731,
    '891_490': 34.1427,
    '891_491': 34.198,
    '891_492': 34.2472,
    '891_493': 34.297,
    '891_494': 34.3561,
    '891_495': 34.4139,
    '891_496': 34.4563,
    '891_497': 34.4917,
    '891_498': 34.5145,
    '891_499': 34.5314,
    '891_500': 34.5498,
    '891_501': 34.5746,
    '891_502': 34.6093,
    '891_503': 34.6478,
    '891_504': 34.6773,
    '891_505': 34.7042,
    '891_506': 34.7299,
    '891_507': 34.7539,
    '891_508': 34.7841,
    '891_509': 34.8263,
    '891_510': 34.8773,
    '891_511': 34.9079,
    '891_512': 34.9212,
    '891_513': 34.942,
    '891_514': 34.9723,
    '891_515': 35.0129,
    '891_516': 35.0595,
    '891_517': 35.109,
    '891_518': 35.1627,
    '891_519': 35.2119,
    '891_520': 35.2694,
    '891_521': 35.3345,
    '891_522': 35.4112,
    '891_523': 35.5002,
    '891_524': 35.5927,
    '891_525': 35.68,
    '891_526': 35.7492,
    '891_527': 35.7977,
    '891_528': 35.8424,
    '891_529': 35.8848,
    '891_530': 35.9274,
    '891_531': 35.9714,
    '891_532': 36.0129,
    '891_533': 36.0539,
    '891_534': 36.0809,
    '891_535': 36.0987,
    '891_536': 36.1239,
    '891_537': 36.147,
    '891_538': 36.1604,
    '891_539': 36.1886,
    '891_540': 36.2056,
    '891_541': 36.2088,
    '891_542': 36.1911,
    '891_543': 36.1701,
    '891_544': 36.1647,
    '891_545': 36.1813,
    '891_546': 36.2149,
    '891_547': 36.2516,
    '891_548': 36.2808,
    '891_549': 36.3008,
    '891_550': 36.3201,
    '891_551': 36.3373,
    '891_552': 36.3481,
    '891_553': 36.3635,
    '891_554': 36.3747,
    '891_555': 36.3739,
    '891_556': 36.3693,
    '891_557': 36.3901,
    '891_558': 36.4188,
    '891_559': 36.4402,
    '891_560': 36.4571,
    '891_561': 36.4782,
    '891_562': 36.5074,
    '891_563': 36.5286,
    '891_564': 36.5577,
    '891_565': 36.5942,
    '891_566': 36.6243,
    '891_567': 36.6461,
    '891_568': 36.6695,
    '891_569': 36.6813,
    '891_570': 36.6863,
    '891_571': 36.6931,
    '891_572': 36.6871,
    '891_573': 36.668,
    '891_574': 36.6601,
    '891_575': 36.6638,
    '891_576': 36.6794,
    '891_577': 36.6967,
    '891_578': 36.7018,
    '891_579': 36.7085,
    '891_580': 36.7021,
    '891_581': 36.696,
    '891_582': 36.6931,
    '891_583': 36.6965,
    '891_584': 36.7067,
    '891_585': 36.7232,
    '891_586': 36.7389,
    '891_587': 36.762,
    '891_588': 36.7786,
    '891_589': 36.793,
    '891_590': 36.8046,
    '891_591': 36.8101,
    '891_592': 36.8126,
    '891_593': 36.8112,
    '891_594': 36.8087,
    '891_595': 36.8113,
    '891_596': 36.8139,
    '891_597': 36.8188,
    '891_598': 36.8377,
    '891_599': 36.8663,
    '891_600': 36.8984,
    '891_601': 36.9305,
    '891_602': 36.9645,
    '891_603': 37.0023,
    '891_604': 37.0437,
    '891_605': 37.0866,
    '891_606': 37.1246,
    '891_607': 37.1607,
    '891_608': 37.1963,
    '891_609': 37.2323,
    '891_610': 37.2736,
    '891_611': 37.3098,
    '891_612': 37.3299,
    '891_613': 37.3488,
    '891_614': 37.3623,
    '891_615': 37.3619,
    '891_616': 37.3611,
    '891_617': 37.3803,
    '891_618': 37.4259,
    '891_619': 37.4654,
    '891_620': 37.4817,
    '891_621': 37.4844,
    '891_622': 37.4865,
    '891_623': 37.4928,
    '891_624': 37.5025,
    '891_625': 37.517,
    '891_626': 37.5373,
    '891_627': 37.5615,
    '891_628': 37.5893,
    '891_629': 37.6208,
    '891_630': 37.6637,
    '891_631': 37.7215,
    '891_632': 37.8,
    '891_633': 37.8878,
    '891_634': 37.9651,
    '891_635': 38.0349,
    '891_636': 38.1076,
    '891_637': 38.1739,
    '891_638': 38.2241,
    '891_639': 38.2651,
    '891_640': 38.3006,
    '891_641': 38.3297,
    '891_642': 38.35,
    '891_643': 38.3636,
    '891_644': 38.3593,
    '891_645': 38.3381,
    '891_646': 38.3158,
    '891_647': 38.2954,
    '891_648': 38.2823,
    '891_649': 38.2849,
    '891_650': 38.3011,
    '891_651': 38.3289,
    '891_652': 38.3246,
    '891_653': 38.2886,
    '891_654': 38.24,
    '891_655': 38.1609,
    '891_656': 38.0616,
    '891_657': 37.9684,
    '891_658': 37.8844,
    '891_659': 37.8111,
    '891_660': 37.7488,
    '891_661': 37.6966,
    '891_662': 37.6545,
    '891_663': 37.6235,
    '891_664': 37.6044,
    '891_665': 37.5967,
    '891_666': 37.598,
    '891_667': 37.6071,
    '891_668': 37.6241,
    '891_669': 37.6529,
    '891_670': 37.6944,
    '891_671': 37.7507,
    '891_672': 37.82,
    '891_673': 37.8889,
    '891_674': 37.9459,
    '891_675': 38.0093,
    '891_676': 38.0825,
    '891_677': 38.1675,
    '891_678': 38.2658,
    '891_679': 38.3743,
    '891_680': 38.4892,
    '891_681': 38.6087,
    '891_682': 38.7255,
    '891_683': 38.8392,
    '891_684': 38.9512,
    '891_685': 39.0631,
    '891_686': 39.1628,
    '891_687': 39.2584,
    '891_688': 39.3636,
    '891_689': 39.4533,
    '891_690': 39.5403,
    '891_691': 39.6202,
    '891_692': 39.6791,
    '891_693': 39.7331,
    '891_694': 39.7956,
    '891_695': 39.8592,
    '891_696': 39.922,
    '891_697': 39.988,
    '891_698': 40.0611,
    '891_699': 40.1362,
    '891_700': 40.1936,
    '891_701': 40.2475,
    '891_702': 40.3167,
    '891_703': 40.3836,
    '891_704': 40.4284,
    '891_705': 40.448,
    '891_706': 40.4762,
    '891_707': 40.5018,
    '891_708': 40.5301,
    '891_709': 40.5366,
    '891_710': 40.5417,
    '891_711': 40.5479,
    '891_712': 40.5472,
    '891_713': 40.5419,
    '891_714': 40.5502,
    '891_715': 40.5619,
    '891_716': 40.5771,
    '891_717': 40.5899,
    '891_718': 40.6075,
    '891_719': 40.6283,
    '891_720': 40.6436,
    '891_721': 40.6503,
    '891_722': 40.6542,
    '891_723': 40.6445,
    '891_724': 40.6094,
    '891_725': 40.5655,
    '891_726': 40.5254,
    '891_727': 40.48,
    '891_728': 40.4281,
    '891_729': 40.3652,
    '891_730': 40.2925,
    '891_731': 40.2185,
    '891_732': 40.1418,
    '891_733': 40.0535,
    '891_734': 39.9414,
    '891_735': 39.8198,
    '891_736': 39.7055,
    '891_737': 39.6039,
    '891_738': 39.5298,
    '891_739': 39.4798,
    '891_740': 39.4556,
    '891_741': 39.4268,
    '891_742': 39.4034,
    '891_743': 39.3863,
    '891_744': 39.3853,
    '891_745': 39.4219,
    '891_746': 39.5166,
    '891_747': 39.6605,
    '891_748': 39.837,
    '891_749': 40.03,
    '891_750': 40.229,
    '891_751': 40.4244,
    '891_752': 40.596,
    '891_753': 40.7411,
    '891_754': 40.8436,
    '891_755': 40.902,
    '891_756': 40.923,
    '891_757': 40.9246,
    '891_758': 40.9112,
    '891_759': 40.8719,
    '891_760': 40.7916,
    '891_761': 40.6607,
    '891_762': 40.4809,
    '891_763': 40.2517,
    '891_764': 40.0091,
    '891_765': 39.7884,
    '891_766': 39.5824,
    '891_767': 39.3994,
    '891_768': 39.2353,
    '891_769': 39.0909,
    '891_770': 38.9715,
    '891_788': 37.1301,
    '891_789': 37.0358,
    '891_790': 36.9433,
    '891_791': 36.833,
    '891_792': 36.7,
    '891_793': 36.5464,
    '891_794': 36.3711,
    '891_795': 36.1768,
    '891_796': 35.9631,
    '891_797': 35.7152,
    '891_798': 35.4371,
    '891_799': 35.1339,
    '891_800': 34.7908,
    '891_801': 34.4142,
    '891_802': 34.0479,
    '891_803': 33.6784,
    '891_804': 33.3245,
    '891_805': 32.9886,
    '891_806': 32.6689,
    '891_807': 32.3645,
    '891_808': 32.0812,
    '891_809': 31.8202,
    '891_810': 31.5763,
    '891_811': 31.3533,
    '891_812': 31.1433,
    '891_813': 30.9499,
    '891_814': 30.775,
    '891_815': 30.5907,
    '891_816': 30.3864,
    '891_817': 30.1574,
    '891_818': 29.9063,
    '891_819': 29.6399,
    '891_820': 29.3686,
    '891_821': 29.0695,
    '891_822': 28.7435,
    '891_823': 28.4122,
    '891_824': 28.074,
    '891_825': 27.7291,
    '891_826': 27.3754,
    '891_827': 27.0194,
    '891_828': 26.6608,
    '892_452': 31.9023,
    '892_453': 31.9237,
    '892_454': 31.947,
    '892_455': 31.97,
    '892_456': 31.9914,
    '892_457': 32.0168,
    '892_458': 32.0491,
    '892_459': 32.0882,
    '892_460': 32.1322,
    '892_461': 32.1806,
    '892_462': 32.2341,
    '892_463': 32.2873,
    '892_464': 32.343,
    '892_465': 32.4064,
    '892_466': 32.4741,
    '892_467': 32.5407,
    '892_468': 32.6,
    '892_469': 32.6666,
    '892_470': 32.7411,
    '892_471': 32.8217,
    '892_472': 32.8993,
    '892_473': 32.9721,
    '892_474': 33.0357,
    '892_475': 33.0861,
    '892_476': 33.1331,
    '892_477': 33.1822,
    '892_478': 33.2382,
    '892_479': 33.2995,
    '892_480': 33.3677,
    '892_481': 33.4421,
    '892_482': 33.5187,
    '892_483': 33.6019,
    '892_484': 33.6724,
    '892_485': 33.7391,
    '892_486': 33.8071,
    '892_487': 33.876,
    '892_488': 33.951,
    '892_489': 34.0235,
    '892_490': 34.0917,
    '892_491': 34.1493,
    '892_492': 34.2019,
    '892_493': 34.2545,
    '892_494': 34.3186,
    '892_495': 34.3819,
    '892_496': 34.4319,
    '892_497': 34.4659,
    '892_498': 34.4877,
    '892_499': 34.5126,
    '892_500': 34.5375,
    '892_501': 34.5646,
    '892_502': 34.5971,
    '892_503': 34.6342,
    '892_504': 34.6657,
    '892_505': 34.6986,
    '892_506': 34.7355,
    '892_507': 34.7708,
    '892_508': 34.8111,
    '892_509': 34.8596,
    '892_510': 34.9108,
    '892_511': 34.941,
    '892_512': 34.9601,
    '892_513': 34.988,
    '892_514': 35.0186,
    '892_515': 35.0611,
    '892_516': 35.1071,
    '892_517': 35.158,
    '892_518': 35.2099,
    '892_519': 35.2605,
    '892_520': 35.3158,
    '892_521': 35.3794,
    '892_522': 35.4493,
    '892_523': 35.5329,
    '892_524': 35.6274,
    '892_525': 35.7105,
    '892_526': 35.7775,
    '892_527': 35.8273,
    '892_528': 35.8737,
    '892_529': 35.9171,
    '892_530': 35.9575,
    '892_531': 35.9964,
    '892_532': 36.0373,
    '892_533': 36.0788,
    '892_534': 36.1083,
    '892_535': 36.1261,
    '892_536': 36.1471,
    '892_537': 36.1671,
    '892_538': 36.1815,
    '892_539': 36.2124,
    '892_540': 36.2327,
    '892_541': 36.2312,
    '892_542': 36.2127,
    '892_543': 36.1966,
    '892_544': 36.1928,
    '892_545': 36.1972,
    '892_546': 36.2232,
    '892_547': 36.2651,
    '892_548': 36.2939,
    '892_549': 36.3172,
    '892_550': 36.3357,
    '892_551': 36.353,
    '892_552': 36.3694,
    '892_553': 36.3866,
    '892_554': 36.3984,
    '892_555': 36.3956,
    '892_556': 36.3934,
    '892_557': 36.4171,
    '892_558': 36.4489,
    '892_559': 36.4727,
    '892_560': 36.4893,
    '892_561': 36.5089,
    '892_562': 36.5336,
    '892_563': 36.5593,
    '892_564': 36.5871,
    '892_565': 36.6246,
    '892_566': 36.6505,
    '892_567': 36.669,
    '892_568': 36.6887,
    '892_569': 36.7,
    '892_570': 36.7029,
    '892_571': 36.7101,
    '892_572': 36.7036,
    '892_573': 36.6838,
    '892_574': 36.6778,
    '892_575': 36.6903,
    '892_576': 36.7123,
    '892_577': 36.7376,
    '892_578': 36.7426,
    '892_579': 36.7477,
    '892_580': 36.7427,
    '892_581': 36.7293,
    '892_582': 36.7181,
    '892_583': 36.7171,
    '892_584': 36.7277,
    '892_585': 36.7408,
    '892_586': 36.7595,
    '892_587': 36.7847,
    '892_588': 36.8052,
    '892_589': 36.8167,
    '892_590': 36.8253,
    '892_591': 36.8307,
    '892_592': 36.8299,
    '892_593': 36.8251,
    '892_594': 36.8168,
    '892_595': 36.8152,
    '892_596': 36.8185,
    '892_597': 36.8241,
    '892_598': 36.8412,
    '892_599': 36.869,
    '892_600': 36.9006,
    '892_601': 36.9334,
    '892_602': 36.9682,
    '892_603': 37.0026,
    '892_604': 37.0413,
    '892_605': 37.0812,
    '892_606': 37.1169,
    '892_607': 37.1544,
    '892_608': 37.1887,
    '892_609': 37.2236,
    '892_610': 37.2635,
    '892_611': 37.3015,
    '892_612': 37.3311,
    '892_613': 37.356,
    '892_614': 37.3731,
    '892_615': 37.3764,
    '892_616': 37.3803,
    '892_617': 37.4084,
    '892_618': 37.4561,
    '892_619': 37.507,
    '892_620': 37.5286,
    '892_621': 37.5233,
    '892_622': 37.5196,
    '892_623': 37.5235,
    '892_624': 37.5236,
    '892_625': 37.5343,
    '892_626': 37.5421,
    '892_627': 37.5426,
    '892_628': 37.5509,
    '892_629': 37.5671,
    '892_630': 37.6021,
    '892_631': 37.6564,
    '892_632': 37.7329,
    '892_633': 37.8186,
    '892_634': 37.8932,
    '892_635': 37.9502,
    '892_636': 38.0071,
    '892_637': 38.0681,
    '892_638': 38.1255,
    '892_639': 38.1706,
    '892_640': 38.2044,
    '892_641': 38.2253,
    '892_642': 38.239,
    '892_643': 38.2527,
    '892_644': 38.25,
    '892_645': 38.2321,
    '892_646': 38.2112,
    '892_647': 38.1933,
    '892_648': 38.1836,
    '892_649': 38.1859,
    '892_650': 38.2025,
    '892_651': 38.2343,
    '892_652': 38.2422,
    '892_653': 38.2145,
    '892_654': 38.1646,
    '892_655': 38.0934,
    '892_656': 38.0024,
    '892_657': 37.91,
    '892_658': 37.8231,
    '892_659': 37.7465,
    '892_660': 37.6817,
    '892_661': 37.6288,
    '892_662': 37.5875,
    '892_663': 37.5576,
    '892_664': 37.5399,
    '892_665': 37.5342,
    '892_666': 37.5388,
    '892_667': 37.5521,
    '892_668': 37.5729,
    '892_669': 37.6048,
    '892_670': 37.651,
    '892_671': 37.7146,
    '892_672': 37.7883,
    '892_673': 37.8539,
    '892_674': 37.9166,
    '892_675': 37.9866,
    '892_676': 38.0645,
    '892_677': 38.1529,
    '892_678': 38.2554,
    '892_679': 38.3693,
    '892_680': 38.4892,
    '892_681': 38.6104,
    '892_682': 38.7298,
    '892_683': 38.8449,
    '892_684': 38.9576,
    '892_685': 39.0713,
    '892_686': 39.1755,
    '892_687': 39.2822,
    '892_688': 39.402,
    '892_689': 39.5093,
    '892_690': 39.6,
    '892_691': 39.6794,
    '892_692': 39.7511,
    '892_693': 39.8192,
    '892_694': 39.8936,
    '892_695': 39.9659,
    '892_696': 40.0324,
    '892_697': 40.0897,
    '892_698': 40.1502,
    '892_699': 40.2154,
    '892_700': 40.2701,
    '892_701': 40.3318,
    '892_702': 40.4018,
    '892_703': 40.4722,
    '892_704': 40.5238,
    '892_705': 40.5575,
    '892_706': 40.589,
    '892_707': 40.6213,
    '892_708': 40.6475,
    '892_709': 40.6584,
    '892_710': 40.6649,
    '892_711': 40.6705,
    '892_712': 40.6654,
    '892_713': 40.6654,
    '892_714': 40.6796,
    '892_715': 40.693,
    '892_716': 40.7132,
    '892_717': 40.7236,
    '892_718': 40.7352,
    '892_719': 40.7592,
    '892_720': 40.7773,
    '892_721': 40.7838,
    '892_722': 40.7845,
    '892_723': 40.7622,
    '892_724': 40.7194,
    '892_725': 40.6744,
    '892_726': 40.6333,
    '892_727': 40.5824,
    '892_728': 40.5236,
    '892_729': 40.4507,
    '892_730': 40.3734,
    '892_731': 40.2958,
    '892_732': 40.2177,
    '892_733': 40.1299,
    '892_734': 40.0213,
    '892_735': 39.902,
    '892_736': 39.7839,
    '892_737': 39.6786,
    '892_738': 39.5989,
    '892_739': 39.5393,
    '892_740': 39.4977,
    '892_741': 39.4553,
    '892_742': 39.4211,
    '892_743': 39.3975,
    '892_744': 39.3907,
    '892_745': 39.4205,
    '892_746': 39.5098,
    '892_747': 39.6457,
    '892_748': 39.8188,
    '892_749': 40.0107,
    '892_750': 40.2118,
    '892_751': 40.4142,
    '892_752': 40.5893,
    '892_753': 40.7307,
    '892_754': 40.8276,
    '892_755': 40.876,
    '892_756': 40.8866,
    '892_757': 40.8867,
    '892_758': 40.8869,
    '892_759': 40.8523,
    '892_760': 40.7785,
    '892_761': 40.6601,
    '892_762': 40.4935,
    '892_763': 40.2758,
    '892_764': 40.0378,
    '892_765': 39.8119,
    '892_766': 39.5934,
    '892_767': 39.3859,
    '892_768': 39.1895,
    '892_769': 39.0136,
    '892_770': 38.8616,
    '892_788': 37.0845,
    '892_789': 36.9987,
    '892_790': 36.9081,
    '892_791': 36.8077,
    '892_792': 36.676,
    '892_793': 36.537,
    '892_794': 36.3731,
    '892_795': 36.178,
    '892_796': 35.97,
    '892_797': 35.7359,
    '892_798': 35.475,
    '892_799': 35.1851,
    '892_800': 34.8502,
    '892_801': 34.4859,
    '892_802': 34.1195,
    '892_803': 33.7444,
    '892_804': 33.3904,
    '892_805': 33.0552,
    '892_806': 32.733,
    '892_807': 32.4284,
    '892_808': 32.1405,
    '892_809': 31.8708,
    '892_810': 31.6284,
    '892_811': 31.4057,
    '892_812': 31.2056,
    '892_813': 31.024,
    '892_814': 30.8578,
    '892_815': 30.6824,
    '892_816': 30.4887,
    '892_817': 30.2609,
    '892_818': 30.0116,
    '892_819': 29.751,
    '892_820': 29.4931,
    '892_821': 29.191,
    '892_822': 28.8662,
    '892_823': 28.5239,
    '892_824': 28.1752,
    '892_825': 27.8233,
    '892_826': 27.4685,
    '892_827': 27.1129,
    '892_828': 26.7502,
    '893_452': 31.8757,
    '893_453': 31.9,
    '893_454': 31.9254,
    '893_455': 31.9478,
    '893_456': 31.9704,
    '893_457': 31.9979,
    '893_458': 32.0309,
    '893_459': 32.0688,
    '893_460': 32.111,
    '893_461': 32.1583,
    '893_462': 32.2152,
    '893_463': 32.2689,
    '893_464': 32.3229,
    '893_465': 32.3854,
    '893_466': 32.4477,
    '893_467': 32.507,
    '893_468': 32.5687,
    '893_469': 32.6479,
    '893_470': 32.7197,
    '893_471': 32.7965,
    '893_472': 32.8766,
    '893_473': 32.9518,
    '893_474': 33.0114,
    '893_475': 33.0599,
    '893_476': 33.1041,
    '893_477': 33.151,
    '893_478': 33.21,
    '893_479': 33.2717,
    '893_480': 33.3367,
    '893_481': 33.4047,
    '893_482': 33.4762,
    '893_483': 33.5542,
    '893_484': 33.6271,
    '893_485': 33.6969,
    '893_486': 33.7693,
    '893_487': 33.8363,
    '893_488': 33.9113,
    '893_489': 33.9818,
    '893_490': 34.0495,
    '893_491': 34.11,
    '893_492': 34.1645,
    '893_493': 34.2211,
    '893_494': 34.2864,
    '893_495': 34.3517,
    '893_496': 34.4082,
    '893_497': 34.4377,
    '893_498': 34.4578,
    '893_499': 34.4917,
    '893_500': 34.5275,
    '893_501': 34.5626,
    '893_502': 34.5933,
    '893_503': 34.6264,
    '893_504': 34.658,
    '893_505': 34.6977,
    '893_506': 34.7413,
    '893_507': 34.7838,
    '893_508': 34.8387,
    '893_509': 34.8936,
    '893_510': 34.9435,
    '893_511': 34.9841,
    '893_512': 35.0178,
    '893_513': 35.0477,
    '893_514': 35.0779,
    '893_515': 35.1216,
    '893_516': 35.162,
    '893_517': 35.2108,
    '893_518': 35.2671,
    '893_519': 35.3215,
    '893_520': 35.375,
    '893_521': 35.4392,
    '893_522': 35.5046,
    '893_523': 35.5756,
    '893_524': 35.6655,
    '893_525': 35.7443,
    '893_526': 35.8116,
    '893_527': 35.8663,
    '893_528': 35.9124,
    '893_529': 35.9515,
    '893_530': 35.9866,
    '893_531': 36.0214,
    '893_532': 36.0618,
    '893_533': 36.0998,
    '893_534': 36.1315,
    '893_535': 36.1482,
    '893_536': 36.1637,
    '893_537': 36.183,
    '893_538': 36.1986,
    '893_539': 36.2279,
    '893_540': 36.2477,
    '893_541': 36.2476,
    '893_542': 36.2374,
    '893_543': 36.226,
    '893_544': 36.2237,
    '893_545': 36.2209,
    '893_546': 36.2359,
    '893_547': 36.2775,
    '893_548': 36.3147,
    '893_549': 36.3407,
    '893_550': 36.3578,
    '893_551': 36.3763,
    '893_552': 36.3973,
    '893_553': 36.4147,
    '893_554': 36.4243,
    '893_555': 36.4187,
    '893_556': 36.4182,
    '893_557': 36.442,
    '893_558': 36.4776,
    '893_559': 36.5023,
    '893_560': 36.5238,
    '893_561': 36.5396,
    '893_562': 36.556,
    '893_563': 36.5768,
    '893_564': 36.6029,
    '893_565': 36.6436,
    '893_566': 36.6685,
    '893_567': 36.6868,
    '893_568': 36.7049,
    '893_569': 36.7131,
    '893_570': 36.7202,
    '893_571': 36.7326,
    '893_572': 36.7318,
    '893_573': 36.7167,
    '893_574': 36.7075,
    '893_575': 36.7234,
    '893_576': 36.752,
    '893_577': 36.7791,
    '893_578': 36.7882,
    '893_579': 36.7859,
    '893_580': 36.7826,
    '893_581': 36.7623,
    '893_582': 36.7384,
    '893_583': 36.7336,
    '893_584': 36.746,
    '893_585': 36.765,
    '893_586': 36.7892,
    '893_587': 36.8113,
    '893_588': 36.8352,
    '893_589': 36.8389,
    '893_590': 36.8436,
    '893_591': 36.8558,
    '893_592': 36.8539,
    '893_593': 36.8425,
    '893_594': 36.8303,
    '893_595': 36.8276,
    '893_596': 36.8313,
    '893_597': 36.8361,
    '893_598': 36.8496,
    '893_599': 36.8744,
    '893_600': 36.9058,
    '893_601': 36.9405,
    '893_602': 36.9783,
    '893_603': 37.0125,
    '893_604': 37.0486,
    '893_605': 37.0858,
    '893_606': 37.1221,
    '893_607': 37.1589,
    '893_608': 37.1911,
    '893_609': 37.2244,
    '893_610': 37.2627,
    '893_611': 37.3002,
    '893_612': 37.3329,
    '893_613': 37.3599,
    '893_614': 37.373,
    '893_615': 37.3799,
    '893_616': 37.3947,
    '893_617': 37.429,
    '893_618': 37.4773,
    '893_619': 37.5297,
    '893_620': 37.5585,
    '893_621': 37.556,
    '893_622': 37.5519,
    '893_623': 37.5528,
    '893_624': 37.5491,
    '893_625': 37.554,
    '893_626': 37.5506,
    '893_627': 37.5284,
    '893_628': 37.5184,
    '893_629': 37.5215,
    '893_630': 37.5466,
    '893_631': 37.5922,
    '893_632': 37.66,
    '893_633': 37.7397,
    '893_634': 37.8117,
    '893_635': 37.8604,
    '893_636': 37.9042,
    '893_637': 37.9605,
    '893_638': 38.0202,
    '893_639': 38.0717,
    '893_640': 38.109,
    '893_641': 38.1255,
    '893_642': 38.1354,
    '893_643': 38.1459,
    '893_644': 38.1484,
    '893_645': 38.1339,
    '893_646': 38.1126,
    '893_647': 38.0939,
    '893_648': 38.0853,
    '893_649': 38.0886,
    '893_650': 38.1066,
    '893_651': 38.1373,
    '893_652': 38.1561,
    '893_653': 38.1443,
    '893_654': 38.1,
    '893_655': 38.0409,
    '893_656': 37.9621,
    '893_657': 37.8641,
    '893_658': 37.7713,
    '893_659': 37.6901,
    '893_660': 37.6236,
    '893_661': 37.5688,
    '893_662': 37.527,
    '893_663': 37.4981,
    '893_664': 37.4816,
    '893_665': 37.4775,
    '893_666': 37.4847,
    '893_667': 37.501,
    '893_668': 37.5257,
    '893_669': 37.5607,
    '893_670': 37.6112,
    '893_671': 37.6782,
    '893_672': 37.7544,
    '893_673': 37.8219,
    '893_674': 37.8893,
    '893_675': 37.9635,
    '893_676': 38.0464,
    '893_677': 38.1397,
    '893_678': 38.2463,
    '893_679': 38.3642,
    '893_680': 38.4865,
    '893_681': 38.6084,
    '893_682': 38.7296,
    '893_683': 38.8487,
    '893_684': 38.9639,
    '893_685': 39.0775,
    '893_686': 39.1883,
    '893_687': 39.3022,
    '893_688': 39.4247,
    '893_689': 39.5392,
    '893_690': 39.6418,
    '893_691': 39.7355,
    '893_692': 39.8282,
    '893_693': 39.9111,
    '893_694': 39.9951,
    '893_695': 40.0806,
    '893_696': 40.1577,
    '893_697': 40.2103,
    '893_698': 40.2558,
    '893_699': 40.303,
    '893_700': 40.3595,
    '893_701': 40.4245,
    '893_702': 40.4861,
    '893_703': 40.555,
    '893_704': 40.6113,
    '893_705': 40.6623,
    '893_706': 40.6962,
    '893_707': 40.7297,
    '893_708': 40.761,
    '893_709': 40.7761,
    '893_710': 40.7877,
    '893_711': 40.7898,
    '893_712': 40.7857,
    '893_713': 40.7878,
    '893_714': 40.802,
    '893_715': 40.8196,
    '893_716': 40.8454,
    '893_717': 40.8588,
    '893_718': 40.8642,
    '893_719': 40.8872,
    '893_720': 40.9083,
    '893_721': 40.9159,
    '893_722': 40.909,
    '893_723': 40.8777,
    '893_724': 40.8301,
    '893_725': 40.7885,
    '893_726': 40.7441,
    '893_727': 40.6915,
    '893_728': 40.6227,
    '893_729': 40.5406,
    '893_730': 40.4594,
    '893_731': 40.379,
    '893_732': 40.3022,
    '893_733': 40.2187,
    '893_734': 40.1129,
    '893_735': 39.9939,
    '893_736': 39.8717,
    '893_737': 39.7648,
    '893_738': 39.6795,
    '893_739': 39.611,
    '893_740': 39.5503,
    '893_741': 39.4947,
    '893_742': 39.4478,
    '893_743': 39.4145,
    '893_744': 39.3982,
    '893_745': 39.4181,
    '893_746': 39.5042,
    '893_747': 39.6365,
    '893_748': 39.8035,
    '893_749': 39.9903,
    '893_750': 40.187,
    '893_751': 40.3856,
    '893_752': 40.5679,
    '893_753': 40.7068,
    '893_754': 40.7988,
    '893_755': 40.835,
    '893_756': 40.8368,
    '893_757': 40.8312,
    '893_758': 40.8292,
    '893_759': 40.8034,
    '893_760': 40.7433,
    '893_761': 40.6353,
    '893_762': 40.4802,
    '893_763': 40.2788,
    '893_764': 40.0553,
    '893_765': 39.8305,
    '893_766': 39.5987,
    '893_767': 39.3689,
    '893_768': 39.1456,
    '893_769': 38.9407,
    '893_770': 38.7606,
    '893_788': 37.0403,
    '893_789': 36.958,
    '893_790': 36.8645,
    '893_791': 36.766,
    '893_792': 36.6448,
    '893_793': 36.5091,
    '893_794': 36.3523,
    '893_795': 36.1683,
    '893_796': 35.9628,
    '893_797': 35.729,
    '893_798': 35.4702,
    '893_799': 35.2207,
    '893_800': 34.9049,
    '893_801': 34.546,
    '893_802': 34.1918,
    '893_803': 33.8252,
    '893_804': 33.4723,
    '893_805': 33.1374,
    '893_806': 32.8135,
    '893_807': 32.5044,
    '893_808': 32.2123,
    '893_809': 31.9411,
    '893_810': 31.7012,
    '893_811': 31.4833,
    '893_812': 31.2867,
    '893_813': 31.1072,
    '893_814': 30.9498,
    '893_815': 30.7816,
    '893_816': 30.5957,
    '893_817': 30.3717,
    '893_818': 30.1267,
    '893_819': 29.8717,
    '893_820': 29.6069,
    '893_821': 29.3112,
    '893_822': 28.9878,
    '893_823': 28.6333,
    '893_824': 28.2738,
    '893_825': 27.9191,
    '893_826': 27.5629,
    '893_827': 27.2057,
    '893_828': 26.8414,
    '894_452': 31.8475,
    '894_453': 31.873,
    '894_454': 31.8986,
    '894_455': 31.9218,
    '894_456': 31.9474,
    '894_457': 31.9775,
    '894_458': 32.0121,
    '894_459': 32.0493,
    '894_460': 32.0913,
    '894_461': 32.1377,
    '894_462': 32.1932,
    '894_463': 32.2485,
    '894_464': 32.3048,
    '894_465': 32.3639,
    '894_466': 32.4168,
    '894_467': 32.4729,
    '894_468': 32.5392,
    '894_469': 32.6171,
    '894_470': 32.6804,
    '894_471': 32.7597,
    '894_472': 32.8451,
    '894_473': 32.9245,
    '894_474': 32.9814,
    '894_475': 33.0301,
    '894_476': 33.0744,
    '894_477': 33.1222,
    '894_478': 33.1844,
    '894_479': 33.2461,
    '894_480': 33.3081,
    '894_481': 33.3721,
    '894_482': 33.4386,
    '894_483': 33.5095,
    '894_484': 33.5817,
    '894_485': 33.6566,
    '894_486': 33.7327,
    '894_487': 33.8018,
    '894_488': 33.8778,
    '894_489': 33.9503,
    '894_490': 34.0188,
    '894_491': 34.0792,
    '894_492': 34.1385,
    '894_493': 34.2025,
    '894_494': 34.2707,
    '894_495': 34.3337,
    '894_496': 34.3893,
    '894_497': 34.4253,
    '894_498': 34.4437,
    '894_499': 34.4755,
    '894_500': 34.5282,
    '894_501': 34.5666,
    '894_502': 34.5944,
    '894_503': 34.6275,
    '894_504': 34.6625,
    '894_505': 34.7008,
    '894_506': 34.7557,
    '894_507': 34.8111,
    '894_508': 34.8758,
    '894_509': 34.9321,
    '894_510': 34.9865,
    '894_511': 35.0356,
    '894_512': 35.0839,
    '894_513': 35.114,
    '894_514': 35.1417,
    '894_515': 35.1831,
    '894_516': 35.2255,
    '894_517': 35.2766,
    '894_518': 35.3388,
    '894_519': 35.3932,
    '894_520': 35.4447,
    '894_521': 35.503,
    '894_522': 35.568,
    '894_523': 35.6286,
    '894_524': 35.7052,
    '894_525': 35.7823,
    '894_526': 35.8516,
    '894_527': 35.9106,
    '894_528': 35.9571,
    '894_529': 35.9912,
    '894_530': 36.0178,
    '894_531': 36.0509,
    '894_532': 36.0879,
    '894_533': 36.1225,
    '894_534': 36.152,
    '894_535': 36.1684,
    '894_536': 36.1792,
    '894_537': 36.1938,
    '894_538': 36.2078,
    '894_539': 36.2363,
    '894_540': 36.2551,
    '894_541': 36.2581,
    '894_542': 36.2566,
    '894_543': 36.2459,
    '894_544': 36.2449,
    '894_545': 36.2468,
    '894_546': 36.2572,
    '894_547': 36.288,
    '894_548': 36.3301,
    '894_549': 36.3598,
    '894_550': 36.3755,
    '894_551': 36.3941,
    '894_552': 36.4151,
    '894_553': 36.4385,
    '894_554': 36.4507,
    '894_555': 36.4471,
    '894_556': 36.4556,
    '894_557': 36.4728,
    '894_558': 36.4963,
    '894_559': 36.5225,
    '894_560': 36.5462,
    '894_561': 36.5617,
    '894_562': 36.5699,
    '894_563': 36.5811,
    '894_564': 36.6025,
    '894_565': 36.6431,
    '894_566': 36.6704,
    '894_567': 36.6958,
    '894_568': 36.7163,
    '894_569': 36.7269,
    '894_570': 36.7405,
    '894_571': 36.7549,
    '894_572': 36.7617,
    '894_573': 36.7525,
    '894_574': 36.747,
    '894_575': 36.761,
    '894_576': 36.7771,
    '894_577': 36.8051,
    '894_578': 36.8218,
    '894_579': 36.821,
    '894_580': 36.8111,
    '894_581': 36.7888,
    '894_582': 36.7607,
    '894_583': 36.7612,
    '894_584': 36.7717,
    '894_585': 36.7933,
    '894_586': 36.815,
    '894_587': 36.835,
    '894_588': 36.8591,
    '894_589': 36.8616,
    '894_590': 36.8648,
    '894_591': 36.8772,
    '894_592': 36.877,
    '894_593': 36.8654,
    '894_594': 36.854,
    '894_595': 36.8496,
    '894_596': 36.8507,
    '894_597': 36.8548,
    '894_598': 36.8646,
    '894_599': 36.8876,
    '894_600': 36.92,
    '894_601': 36.9569,
    '894_602': 36.9954,
    '894_603': 37.0305,
    '894_604': 37.067,
    '894_605': 37.1037,
    '894_606': 37.1388,
    '894_607': 37.1725,
    '894_608': 37.2015,
    '894_609': 37.2354,
    '894_610': 37.272,
    '894_611': 37.3069,
    '894_612': 37.3381,
    '894_613': 37.3648,
    '894_614': 37.3762,
    '894_615': 37.3871,
    '894_616': 37.4107,
    '894_617': 37.447,
    '894_618': 37.4934,
    '894_619': 37.542,
    '894_620': 37.5723,
    '894_621': 37.5791,
    '894_622': 37.5797,
    '894_623': 37.5808,
    '894_624': 37.5761,
    '894_625': 37.5757,
    '894_626': 37.5571,
    '894_627': 37.5205,
    '894_628': 37.4871,
    '894_629': 37.4811,
    '894_630': 37.4961,
    '894_631': 37.5317,
    '894_632': 37.59,
    '894_633': 37.6676,
    '894_634': 37.7306,
    '894_635': 37.7652,
    '894_636': 37.7997,
    '894_637': 37.8473,
    '894_638': 37.9077,
    '894_639': 37.9648,
    '894_640': 38.0052,
    '894_641': 38.0229,
    '894_642': 38.0353,
    '894_643': 38.0411,
    '894_644': 38.0486,
    '894_645': 38.0367,
    '894_646': 38.0152,
    '894_647': 37.9966,
    '894_648': 37.9909,
    '894_649': 37.9959,
    '894_650': 38.0143,
    '894_651': 38.0433,
    '894_652': 38.0651,
    '894_653': 38.0667,
    '894_654': 38.0431,
    '894_655': 37.9995,
    '894_656': 37.9247,
    '894_657': 37.8274,
    '894_658': 37.7306,
    '894_659': 37.6439,
    '894_660': 37.5747,
    '894_661': 37.5183,
    '894_662': 37.4757,
    '894_663': 37.4476,
    '894_664': 37.4308,
    '894_665': 37.427,
    '894_666': 37.4359,
    '894_667': 37.4542,
    '894_668': 37.4815,
    '894_669': 37.5199,
    '894_670': 37.5748,
    '894_671': 37.6454,
    '894_672': 37.7224,
    '894_673': 37.7932,
    '894_674': 37.8643,
    '894_675': 37.9424,
    '894_676': 38.0294,
    '894_677': 38.1267,
    '894_678': 38.2368,
    '894_679': 38.3565,
    '894_680': 38.48,
    '894_681': 38.603,
    '894_682': 38.7266,
    '894_683': 38.8491,
    '894_684': 38.9681,
    '894_685': 39.0851,
    '894_686': 39.2017,
    '894_687': 39.3213,
    '894_688': 39.4461,
    '894_689': 39.5653,
    '894_690': 39.6772,
    '894_691': 39.7853,
    '894_692': 39.8929,
    '894_693': 39.9939,
    '894_694': 40.0902,
    '894_695': 40.1863,
    '894_696': 40.2783,
    '894_697': 40.3447,
    '894_698': 40.3816,
    '894_699': 40.4104,
    '894_700': 40.453,
    '894_701': 40.5088,
    '894_702': 40.5744,
    '894_703': 40.6426,
    '894_704': 40.6974,
    '894_705': 40.7568,
    '894_706': 40.8037,
    '894_707': 40.8364,
    '894_708': 40.872,
    '894_709': 40.9021,
    '894_710': 40.9112,
    '894_711': 40.9092,
    '894_712': 40.9132,
    '894_713': 40.9144,
    '894_714': 40.9269,
    '894_715': 40.9411,
    '894_716': 40.9663,
    '894_717': 40.985,
    '894_718': 40.9916,
    '894_719': 41.0159,
    '894_720': 41.032,
    '894_721': 41.0408,
    '894_722': 41.0286,
    '894_723': 40.9877,
    '894_724': 40.9346,
    '894_725': 40.8974,
    '894_726': 40.8555,
    '894_727': 40.795,
    '894_728': 40.7171,
    '894_729': 40.6361,
    '894_730': 40.5491,
    '894_731': 40.4688,
    '894_732': 40.3988,
    '894_733': 40.3221,
    '894_734': 40.2108,
    '894_735': 40.0864,
    '894_736': 39.9568,
    '894_737': 39.8495,
    '894_738': 39.7593,
    '894_739': 39.6818,
    '894_740': 39.6073,
    '894_741': 39.538,
    '894_742': 39.4794,
    '894_743': 39.4364,
    '894_744': 39.4106,
    '894_745': 39.4236,
    '894_746': 39.5034,
    '894_747': 39.6297,
    '894_748': 39.7895,
    '894_749': 39.9692,
    '894_750': 40.1576,
    '894_751': 40.3467,
    '894_752': 40.5269,
    '894_753': 40.6734,
    '894_754': 40.7613,
    '894_755': 40.7893,
    '894_756': 40.7845,
    '894_757': 40.7719,
    '894_758': 40.7611,
    '894_759': 40.732,
    '894_760': 40.6758,
    '894_761': 40.5832,
    '894_762': 40.4433,
    '894_763': 40.2597,
    '894_764': 40.0565,
    '894_765': 39.8377,
    '894_766': 39.5972,
    '894_767': 39.3501,
    '894_768': 39.101,
    '894_769': 38.8684,
    '894_770': 38.6664,
    '894_788': 36.9921,
    '894_789': 36.9129,
    '894_790': 36.8192,
    '894_791': 36.7123,
    '894_792': 36.5978,
    '894_793': 36.4702,
    '894_794': 36.3227,
    '894_795': 36.1507,
    '894_796': 35.9557,
    '894_797': 35.7332,
    '894_798': 35.4884,
    '894_799': 35.2345,
    '894_800': 34.9426,
    '894_801': 34.6101,
    '894_802': 34.2841,
    '894_803': 33.9279,
    '894_804': 33.5643,
    '894_805': 33.2244,
    '894_806': 32.9064,
    '894_807': 32.599,
    '894_808': 32.2993,
    '894_809': 32.0221,
    '894_810': 31.7806,
    '894_811': 31.5686,
    '894_812': 31.3828,
    '894_813': 31.2092,
    '894_814': 31.0591,
    '894_815': 30.8925,
    '894_816': 30.6966,
    '894_817': 30.4846,
    '894_818': 30.2489,
    '894_819': 29.9982,
    '894_820': 29.7259,
    '894_821': 29.4276,
    '894_822': 29.097,
    '894_823': 28.7366,
    '894_824': 28.3749,
    '894_825': 28.0221,
    '894_826': 27.6639,
    '894_827': 27.3076,
    '894_828': 26.9431,
    '895_472': 32.8033,
    '895_473': 32.8815,
    '895_474': 32.9416,
    '895_475': 32.9955,
    '895_476': 33.0433,
    '895_477': 33.095,
    '895_478': 33.157,
    '895_479': 33.2186,
    '895_480': 33.2804,
    '895_481': 33.3417,
    '895_482': 33.4049,
    '895_483': 33.4724,
    '895_484': 33.5445,
    '895_485': 33.618,
    '895_486': 33.6921,
    '895_487': 33.7689,
    '895_488': 33.8499,
    '895_489': 33.9247,
    '895_490': 33.9998,
    '895_491': 34.0584,
    '895_492': 34.1137,
    '895_493': 34.1752,
    '895_494': 34.2475,
    '895_495': 34.3096,
    '895_496': 34.364,
    '895_497': 34.4088,
    '895_498': 34.4387,
    '895_499': 34.468,
    '895_500': 34.5288,
    '895_501': 34.5698,
    '895_502': 34.5996,
    '895_503': 34.6353,
    '895_504': 34.672,
    '895_505': 34.7114,
    '895_506': 34.7778,
    '895_507': 34.8469,
    '895_508': 34.9193,
    '895_509': 34.977,
    '895_510': 35.0337,
    '895_511': 35.0869,
    '895_512': 35.1385,
    '895_513': 35.1707,
    '895_514': 35.207,
    '895_515': 35.2489,
    '895_516': 35.2943,
    '895_517': 35.3496,
    '895_518': 35.4144,
    '895_519': 35.4698,
    '895_520': 35.5193,
    '895_521': 35.5749,
    '895_522': 35.6344,
    '895_523': 35.6874,
    '895_524': 35.7492,
    '895_525': 35.8215,
    '895_526': 35.8985,
    '895_527': 35.9581,
    '895_528': 36.0011,
    '895_529': 36.0309,
    '895_530': 36.0573,
    '895_531': 36.0907,
    '895_532': 36.1244,
    '895_533': 36.1487,
    '895_534': 36.1733,
    '895_535': 36.1889,
    '895_536': 36.1974,
    '895_537': 36.2056,
    '895_538': 36.2142,
    '895_539': 36.2422,
    '895_540': 36.2619,
    '895_541': 36.2699,
    '895_542': 36.2757,
    '895_543': 36.2678,
    '895_544': 36.2653,
    '895_545': 36.268,
    '895_546': 36.2829,
    '895_547': 36.3029,
    '895_548': 36.3402,
    '895_549': 36.372,
    '895_550': 36.3877,
    '895_551': 36.4077,
    '895_552': 36.425,
    '895_553': 36.4522,
    '895_554': 36.4702,
    '895_555': 36.4861,
    '895_556': 36.4996,
    '895_557': 36.5106,
    '895_558': 36.5181,
    '895_559': 36.5367,
    '895_560': 36.5576,
    '895_561': 36.5704,
    '895_562': 36.5748,
    '895_563': 36.5819,
    '895_564': 36.6003,
    '895_565': 36.6366,
    '895_566': 36.6676,
    '895_567': 36.7008,
    '895_568': 36.7269,
    '895_569': 36.7456,
    '895_570': 36.7647,
    '895_571': 36.7753,
    '895_572': 36.7851,
    '895_573': 36.7879,
    '895_574': 36.7842,
    '895_575': 36.7977,
    '895_576': 36.8093,
    '895_577': 36.8267,
    '895_578': 36.8429,
    '895_579': 36.842,
    '895_580': 36.8278,
    '895_581': 36.8047,
    '895_582': 36.7898,
    '895_583': 36.7982,
    '895_584': 36.8076,
    '895_585': 36.8229,
    '895_586': 36.8382,
    '895_587': 36.8634,
    '895_588': 36.8892,
    '895_589': 36.8877,
    '895_590': 36.889,
    '895_591': 36.8985,
    '895_592': 36.9027,
    '895_593': 36.895,
    '895_594': 36.8864,
    '895_595': 36.8804,
    '895_596': 36.8784,
    '895_597': 36.88,
    '895_598': 36.8854,
    '895_599': 36.9061,
    '895_600': 36.9392,
    '895_601': 36.9783,
    '895_602': 37.019,
    '895_603': 37.0552,
    '895_604': 37.0931,
    '895_605': 37.1306,
    '895_606': 37.1644,
    '895_607': 37.1932,
    '895_608': 37.2218,
    '895_609': 37.2565,
    '895_610': 37.2907,
    '895_611': 37.3225,
    '895_612': 37.3481,
    '895_613': 37.3694,
    '895_614': 37.3837,
    '895_615': 37.4039,
    '895_616': 37.4317,
    '895_617': 37.4656,
    '895_618': 37.5083,
    '895_619': 37.5501,
    '895_620': 37.574,
    '895_621': 37.591,
    '895_622': 37.5939,
    '895_623': 37.5945,
    '895_624': 37.5946,
    '895_625': 37.5897,
    '895_626': 37.5535,
    '895_627': 37.4991,
    '895_628': 37.4603,
    '895_629': 37.4467,
    '895_630': 37.4522,
    '895_631': 37.4775,
    '895_632': 37.5263,
    '895_633': 37.5985,
    '895_634': 37.6516,
    '895_635': 37.6754,
    '895_636': 37.6994,
    '895_637': 37.7347,
    '895_638': 37.7954,
    '895_639': 37.8531,
    '895_640': 37.8935,
    '895_641': 37.9169,
    '895_642': 37.9343,
    '895_643': 37.9378,
    '895_644': 37.9461,
    '895_645': 37.9383,
    '895_646': 37.9151,
    '895_647': 37.8998,
    '895_648': 37.8996,
    '895_649': 37.9081,
    '895_650': 37.9276,
    '895_651': 37.9585,
    '895_652': 37.9824,
    '895_653': 37.994,
    '895_654': 37.9889,
    '895_655': 37.9634,
    '895_656': 37.8949,
    '895_657': 37.809,
    '895_658': 37.7013,
    '895_659': 37.6065,
    '895_660': 37.533,
    '895_661': 37.4747,
    '895_662': 37.4315,
    '895_663': 37.4028,
    '895_664': 37.3861,
    '895_665': 37.3825,
    '895_666': 37.3927,
    '895_667': 37.4118,
    '895_668': 37.4404,
    '895_669': 37.4813,
    '895_670': 37.538,
    '895_671': 37.6108,
    '895_672': 37.6908,
    '895_673': 37.7655,
    '895_674': 37.8408,
    '895_675': 37.9239,
    '895_676': 38.0137,
    '895_677': 38.113,
    '895_678': 38.2257,
    '895_679': 38.3453,
    '895_680': 38.4702,
    '895_681': 38.5966,
    '895_682': 38.7223,
    '895_683': 38.8467,
    '895_684': 38.9697,
    '895_685': 39.0928,
    '895_686': 39.2153,
    '895_687': 39.3411,
    '895_688': 39.4684,
    '895_689': 39.5959,
    '895_690': 39.718,
    '895_691': 39.8349,
    '895_692': 39.9505,
    '895_693': 40.0652,
    '895_694': 40.1763,
    '895_695': 40.2824,
    '895_696': 40.386,
    '895_697': 40.4652,
    '895_698': 40.5115,
    '895_699': 40.5419,
    '895_700': 40.5658,
    '895_701': 40.5997,
    '895_702': 40.6525,
    '895_703': 40.7185,
    '895_704': 40.7829,
    '895_705': 40.8532,
    '895_706': 40.9147,
    '895_707': 40.9484,
    '895_708': 40.9839,
    '895_709': 41.0181,
    '895_710': 41.0293,
    '895_711': 41.036,
    '895_712': 41.0415,
    '895_713': 41.0402,
    '895_714': 41.052,
    '895_715': 41.0621,
    '895_716': 41.0799,
    '895_717': 41.1007,
    '895_718': 41.1146,
    '895_719': 41.1391,
    '895_720': 41.1572,
    '895_721': 41.1604,
    '895_722': 41.1391,
    '895_723': 41.0906,
    '895_724': 41.0439,
    '895_725': 41.0106,
    '895_726': 40.9666,
    '895_727': 40.9001,
    '895_728': 40.8174,
    '895_729': 40.7337,
    '895_730': 40.6441,
    '895_731': 40.5631,
    '895_732': 40.4925,
    '895_733': 40.4124,
    '895_734': 40.289,
    '895_735': 40.1553,
    '895_736': 40.0345,
    '895_737': 39.9284,
    '895_738': 39.8345,
    '895_739': 39.7493,
    '895_740': 39.6673,
    '895_741': 39.5883,
    '895_742': 39.5162,
    '895_743': 39.4614,
    '895_744': 39.4257,
    '895_745': 39.4327,
    '895_746': 39.5056,
    '895_747': 39.625,
    '895_748': 39.7771,
    '895_749': 39.9499,
    '895_750': 40.1331,
    '895_751': 40.3187,
    '895_752': 40.498,
    '895_753': 40.6452,
    '895_754': 40.7285,
    '895_755': 40.7481,
    '895_756': 40.7387,
    '895_757': 40.7163,
    '895_758': 40.6981,
    '895_759': 40.6585,
    '895_760': 40.596,
    '895_761': 40.5127,
    '895_762': 40.389,
    '895_763': 40.2242,
    '895_764': 40.0357,
    '895_765': 39.8246,
    '895_766': 39.5801,
    '895_767': 39.3175,
    '895_768': 39.0517,
    '895_769': 38.8001,
    '895_770': 38.5814,
    '895_788': 36.9439,
    '895_789': 36.8631,
    '895_790': 36.7665,
    '895_791': 36.6537,
    '895_792': 36.5444,
    '895_793': 36.4242,
    '895_794': 36.283,
    '895_795': 36.1204,
    '895_796': 35.9376,
    '895_797': 35.7328,
    '895_798': 35.4934,
    '895_799': 35.2341,
    '895_800': 34.9475,
    '895_801': 34.6356,
    '895_802': 34.3238,
    '895_803': 33.9985,
    '895_804': 33.6706,
    '895_805': 33.328,
    '895_806': 33.0026,
    '895_807': 32.6935,
    '895_808': 32.3933,
    '895_809': 32.1184,
    '895_810': 31.8738,
    '895_811': 31.6665,
    '895_812': 31.4893,
    '895_813': 31.3237,
    '895_814': 31.1745,
    '895_815': 31.0069,
    '895_816': 30.8146,
    '895_817': 30.6047,
    '895_818': 30.3708,
    '895_819': 30.124,
    '895_820': 29.8453,
    '895_821': 29.5431,
    '895_822': 29.2035,
    '895_823': 28.8461,
    '895_824': 28.4805,
    '895_825': 28.1314,
    '895_826': 27.7794,
    '895_827': 27.4252,
    '895_828': 27.0622,
    '896_472': 32.7608,
    '896_473': 32.836,
    '896_474': 32.9013,
    '896_475': 32.9552,
    '896_476': 33.0063,
    '896_477': 33.0615,
    '896_478': 33.1242,
    '896_479': 33.1883,
    '896_480': 33.2516,
    '896_481': 33.3131,
    '896_482': 33.3731,
    '896_483': 33.4364,
    '896_484': 33.506,
    '896_485': 33.5803,
    '896_486': 33.6552,
    '896_487': 33.7338,
    '896_488': 33.817,
    '896_489': 33.8973,
    '896_490': 33.979,
    '896_491': 34.0465,
    '896_492': 34.0941,
    '896_493': 34.1456,
    '896_494': 34.2187,
    '896_495': 34.2829,
    '896_496': 34.3351,
    '896_497': 34.3783,
    '896_498': 34.4162,
    '896_499': 34.453,
    '896_500': 34.5188,
    '896_501': 34.5682,
    '896_502': 34.6026,
    '896_503': 34.6369,
    '896_504': 34.6716,
    '896_505': 34.7193,
    '896_506': 34.7872,
    '896_507': 34.8784,
    '896_508': 34.9582,
    '896_509': 35.0199,
    '896_510': 35.0774,
    '896_511': 35.1335,
    '896_512': 35.1827,
    '896_513': 35.2168,
    '896_514': 35.264,
    '896_515': 35.3133,
    '896_516': 35.3679,
    '896_517': 35.4247,
    '896_518': 35.486,
    '896_519': 35.5404,
    '896_520': 35.593,
    '896_521': 35.65,
    '896_522': 35.7051,
    '896_523': 35.7527,
    '896_524': 35.8027,
    '896_525': 35.868,
    '896_526': 35.9473,
    '896_527': 36.0068,
    '896_528': 36.0451,
    '896_529': 36.0726,
    '896_530': 36.1033,
    '896_531': 36.1342,
    '896_532': 36.1645,
    '896_533': 36.1819,
    '896_534': 36.1996,
    '896_535': 36.2139,
    '896_536': 36.2185,
    '896_537': 36.221,
    '896_538': 36.2208,
    '896_539': 36.246,
    '896_540': 36.2639,
    '896_541': 36.2848,
    '896_542': 36.2986,
    '896_543': 36.2953,
    '896_544': 36.2931,
    '896_545': 36.2909,
    '896_546': 36.3075,
    '896_547': 36.3269,
    '896_548': 36.3555,
    '896_549': 36.3826,
    '896_550': 36.398,
    '896_551': 36.4162,
    '896_552': 36.4338,
    '896_553': 36.4587,
    '896_554': 36.4869,
    '896_555': 36.5191,
    '896_556': 36.5332,
    '896_557': 36.5413,
    '896_558': 36.5389,
    '896_559': 36.5455,
    '896_560': 36.5641,
    '896_561': 36.5774,
    '896_562': 36.5832,
    '896_563': 36.5873,
    '896_564': 36.6047,
    '896_565': 36.6328,
    '896_566': 36.6718,
    '896_567': 36.714,
    '896_568': 36.7409,
    '896_569': 36.7638,
    '896_570': 36.7817,
    '896_571': 36.7891,
    '896_572': 36.7996,
    '896_573': 36.8081,
    '896_574': 36.81,
    '896_575': 36.8364,
    '896_576': 36.8496,
    '896_577': 36.8554,
    '896_578': 36.8599,
    '896_579': 36.8567,
    '896_580': 36.847,
    '896_581': 36.8302,
    '896_582': 36.8216,
    '896_583': 36.8318,
    '896_584': 36.8475,
    '896_585': 36.8573,
    '896_586': 36.8699,
    '896_587': 36.8913,
    '896_588': 36.9129,
    '896_589': 36.913,
    '896_590': 36.9145,
    '896_591': 36.9222,
    '896_592': 36.9312,
    '896_593': 36.9271,
    '896_594': 36.9227,
    '896_595': 36.9163,
    '896_596': 36.912,
    '896_597': 36.9099,
    '896_598': 36.9124,
    '896_599': 36.9282,
    '896_600': 36.9627,
    '896_601': 37.007,
    '896_602': 37.052,
    '896_603': 37.0889,
    '896_604': 37.1253,
    '896_605': 37.1592,
    '896_606': 37.1949,
    '896_607': 37.2187,
    '896_608': 37.2473,
    '896_609': 37.2819,
    '896_610': 37.3161,
    '896_611': 37.3432,
    '896_612': 37.3682,
    '896_613': 37.3855,
    '896_614': 37.3999,
    '896_615': 37.4256,
    '896_616': 37.4507,
    '896_617': 37.4767,
    '896_618': 37.5124,
    '896_619': 37.549,
    '896_620': 37.5722,
    '896_621': 37.5875,
    '896_622': 37.589,
    '896_623': 37.5923,
    '896_624': 37.5924,
    '896_625': 37.5833,
    '896_626': 37.5359,
    '896_627': 37.4737,
    '896_628': 37.433,
    '896_629': 37.4139,
    '896_630': 37.4138,
    '896_631': 37.433,
    '896_632': 37.4685,
    '896_633': 37.5296,
    '896_634': 37.58,
    '896_635': 37.5968,
    '896_636': 37.6016,
    '896_637': 37.6246,
    '896_638': 37.6775,
    '896_639': 37.7357,
    '896_640': 37.7794,
    '896_641': 37.8076,
    '896_642': 37.83,
    '896_643': 37.8399,
    '896_644': 37.8473,
    '896_645': 37.8384,
    '896_646': 37.813,
    '896_647': 37.8055,
    '896_648': 37.8102,
    '896_649': 37.822,
    '896_650': 37.845,
    '896_651': 37.8806,
    '896_652': 37.9126,
    '896_653': 37.9356,
    '896_654': 37.9459,
    '896_655': 37.9376,
    '896_656': 37.8857,
    '896_657': 37.8023,
    '896_658': 37.6809,
    '896_659': 37.5767,
    '896_660': 37.498,
    '896_661': 37.4375,
    '896_662': 37.3936,
    '896_663': 37.3649,
    '896_664': 37.3479,
    '896_665': 37.3438,
    '896_666': 37.3538,
    '896_667': 37.3734,
    '896_668': 37.4033,
    '896_669': 37.4463,
    '896_670': 37.5054,
    '896_671': 37.5796,
    '896_672': 37.6606,
    '896_673': 37.7393,
    '896_674': 37.8205,
    '896_675': 37.9079,
    '896_676': 38.0008,
    '896_677': 38.1026,
    '896_678': 38.2165,
    '896_679': 38.3355,
    '896_680': 38.4611,
    '896_681': 38.59,
    '896_682': 38.717,
    '896_683': 38.8436,
    '896_684': 38.971,
    '896_685': 39.1001,
    '896_686': 39.2295,
    '896_687': 39.3617,
    '896_688': 39.4944,
    '896_689': 39.6316,
    '896_690': 39.7627,
    '896_691': 39.8922,
    '896_692': 40.0192,
    '896_693': 40.1412,
    '896_694': 40.2578,
    '896_695': 40.3714,
    '896_696': 40.4817,
    '896_697': 40.568,
    '896_698': 40.6244,
    '896_699': 40.665,
    '896_700': 40.6939,
    '896_701': 40.7096,
    '896_702': 40.7393,
    '896_703': 40.7947,
    '896_704': 40.862,
    '896_705': 40.9396,
    '896_706': 41.0131,
    '896_707': 41.0568,
    '896_708': 41.0969,
    '896_709': 41.1326,
    '896_710': 41.1543,
    '896_711': 41.1669,
    '896_712': 41.1699,
    '896_713': 41.1583,
    '896_714': 41.1657,
    '896_715': 41.1765,
    '896_716': 41.1895,
    '896_717': 41.2098,
    '896_718': 41.2319,
    '896_719': 41.2581,
    '896_720': 41.2785,
    '896_721': 41.2728,
    '896_722': 41.2389,
    '896_723': 41.1871,
    '896_724': 41.1519,
    '896_725': 41.1242,
    '896_726': 41.0787,
    '896_727': 41.0143,
    '896_728': 40.9288,
    '896_729': 40.8391,
    '896_730': 40.7466,
    '896_731': 40.6587,
    '896_732': 40.5789,
    '896_733': 40.4838,
    '896_734': 40.3582,
    '896_735': 40.2225,
    '896_736': 40.1073,
    '896_737': 40.0031,
    '896_738': 39.9085,
    '896_739': 39.8191,
    '896_740': 39.7328,
    '896_741': 39.6488,
    '896_742': 39.5626,
    '896_743': 39.4875,
    '896_744': 39.4392,
    '896_745': 39.4428,
    '896_746': 39.5085,
    '896_747': 39.6203,
    '896_748': 39.7653,
    '896_749': 39.9331,
    '896_750': 40.1143,
    '896_751': 40.3043,
    '896_752': 40.4902,
    '896_753': 40.6271,
    '896_754': 40.699,
    '896_755': 40.7114,
    '896_756': 40.6931,
    '896_757': 40.6653,
    '896_758': 40.6393,
    '896_759': 40.5955,
    '896_760': 40.5221,
    '896_761': 40.4434,
    '896_762': 40.3321,
    '896_763': 40.177,
    '896_764': 39.9967,
    '896_765': 39.793,
    '896_766': 39.5496,
    '896_767': 39.2765,
    '896_768': 39.0017,
    '896_769': 38.7391,
    '896_770': 38.5059,
    '896_788': 36.8924,
    '896_789': 36.8082,
    '896_790': 36.7125,
    '896_791': 36.6056,
    '896_792': 36.4929,
    '896_793': 36.37,
    '896_794': 36.2318,
    '896_795': 36.0763,
    '896_796': 35.9017,
    '896_797': 35.7069,
    '896_798': 35.482,
    '896_799': 35.2298,
    '896_800': 34.9615,
    '896_801': 34.6727,
    '896_802': 34.3496,
    '896_803': 34.0572,
    '896_804': 33.7427,
    '896_805': 33.4181,
    '896_806': 33.0972,
    '896_807': 32.7863,
    '896_808': 32.491,
    '896_809': 32.2225,
    '896_810': 31.9804,
    '896_811': 31.7806,
    '896_812': 31.6054,
    '896_813': 31.4427,
    '896_814': 31.2818,
    '896_815': 31.1167,
    '896_816': 30.9266,
    '896_817': 30.7187,
    '896_818': 30.4862,
    '896_819': 30.2343,
    '896_820': 29.9586,
    '896_821': 29.6539,
    '896_822': 29.3015,
    '896_823': 28.9565,
    '896_824': 28.5935,
    '896_825': 28.2486,
    '896_826': 27.8988,
    '896_827': 27.5443,
    '896_828': 27.1844,
    '897_472': 32.7166,
    '897_473': 32.7877,
    '897_474': 32.8533,
    '897_475': 32.911,
    '897_476': 32.9659,
    '897_477': 33.0251,
    '897_478': 33.091,
    '897_479': 33.1582,
    '897_480': 33.2238,
    '897_481': 33.2858,
    '897_482': 33.3437,
    '897_483': 33.4037,
    '897_484': 33.4689,
    '897_485': 33.5417,
    '897_486': 33.6187,
    '897_487': 33.7002,
    '897_488': 33.7861,
    '897_489': 33.8722,
    '897_490': 33.961,
    '897_491': 34.0375,
    '897_492': 34.0867,
    '897_493': 34.1281,
    '897_494': 34.1905,
    '897_495': 34.2529,
    '897_496': 34.3034,
    '897_497': 34.345,
    '897_498': 34.3859,
    '897_499': 34.4299,
    '897_500': 34.499,
    '897_501': 34.5567,
    '897_502': 34.5958,
    '897_503': 34.6346,
    '897_504': 34.6707,
    '897_505': 34.7227,
    '897_506': 34.7912,
    '897_507': 34.8964,
    '897_508': 34.9858,
    '897_509': 35.0558,
    '897_510': 35.1156,
    '897_511': 35.1717,
    '897_512': 35.2197,
    '897_513': 35.2606,
    '897_514': 35.3156,
    '897_515': 35.3729,
    '897_516': 35.432,
    '897_517': 35.4905,
    '897_518': 35.5451,
    '897_519': 35.5971,
    '897_520': 35.6544,
    '897_521': 35.7184,
    '897_522': 35.7751,
    '897_523': 35.8195,
    '897_524': 35.8657,
    '897_525': 35.9205,
    '897_526': 35.997,
    '897_527': 36.0573,
    '897_528': 36.0931,
    '897_529': 36.1214,
    '897_530': 36.1546,
    '897_531': 36.1859,
    '897_532': 36.2105,
    '897_533': 36.2217,
    '897_534': 36.2298,
    '897_535': 36.2387,
    '897_536': 36.2404,
    '897_537': 36.2371,
    '897_538': 36.2325,
    '897_539': 36.2519,
    '897_540': 36.2742,
    '897_541': 36.3063,
    '897_542': 36.3224,
    '897_543': 36.3238,
    '897_544': 36.3237,
    '897_545': 36.3171,
    '897_546': 36.3241,
    '897_547': 36.3446,
    '897_548': 36.3706,
    '897_549': 36.3938,
    '897_550': 36.4083,
    '897_551': 36.4227,
    '897_552': 36.4377,
    '897_553': 36.4654,
    '897_554': 36.5019,
    '897_555': 36.5403,
    '897_556': 36.5552,
    '897_557': 36.5601,
    '897_558': 36.5531,
    '897_559': 36.5532,
    '897_560': 36.564,
    '897_561': 36.5807,
    '897_562': 36.5872,
    '897_563': 36.5979,
    '897_564': 36.6182,
    '897_565': 36.6381,
    '897_566': 36.6776,
    '897_567': 36.7238,
    '897_568': 36.7534,
    '897_569': 36.7766,
    '897_570': 36.7911,
    '897_571': 36.7965,
    '897_572': 36.8048,
    '897_573': 36.8144,
    '897_574': 36.8234,
    '897_575': 36.8546,
    '897_576': 36.8753,
    '897_577': 36.8821,
    '897_578': 36.8798,
    '897_579': 36.8807,
    '897_580': 36.8808,
    '897_581': 36.867,
    '897_582': 36.8567,
    '897_583': 36.8699,
    '897_584': 36.8866,
    '897_585': 36.8998,
    '897_586': 36.9102,
    '897_587': 36.9209,
    '897_588': 36.9359,
    '897_589': 36.9405,
    '897_590': 36.9405,
    '897_591': 36.9489,
    '897_592': 36.9614,
    '897_593': 36.9656,
    '897_594': 36.9598,
    '897_595': 36.9541,
    '897_596': 36.9479,
    '897_597': 36.9441,
    '897_598': 36.944,
    '897_599': 36.9555,
    '897_600': 36.9927,
    '897_601': 37.037,
    '897_602': 37.0845,
    '897_603': 37.1236,
    '897_604': 37.1601,
    '897_605': 37.191,
    '897_606': 37.2229,
    '897_607': 37.2486,
    '897_608': 37.2797,
    '897_609': 37.3149,
    '897_610': 37.3476,
    '897_611': 37.3713,
    '897_612': 37.3962,
    '897_613': 37.4106,
    '897_614': 37.4302,
    '897_615': 37.4525,
    '897_616': 37.465,
    '897_617': 37.4858,
    '897_618': 37.5117,
    '897_619': 37.5453,
    '897_620': 37.5654,
    '897_621': 37.5728,
    '897_622': 37.572,
    '897_623': 37.5767,
    '897_624': 37.5743,
    '897_625': 37.5619,
    '897_626': 37.5113,
    '897_627': 37.4458,
    '897_628': 37.4046,
    '897_629': 37.3831,
    '897_630': 37.3799,
    '897_631': 37.3934,
    '897_632': 37.4204,
    '897_633': 37.4643,
    '897_634': 37.5146,
    '897_635': 37.5189,
    '897_636': 37.5082,
    '897_637': 37.5221,
    '897_638': 37.5604,
    '897_639': 37.6137,
    '897_640': 37.6647,
    '897_641': 37.7044,
    '897_642': 37.7314,
    '897_643': 37.7458,
    '897_644': 37.748,
    '897_645': 37.734,
    '897_646': 37.7107,
    '897_647': 37.7118,
    '897_648': 37.7212,
    '897_649': 37.7359,
    '897_650': 37.7675,
    '897_651': 37.8107,
    '897_652': 37.8521,
    '897_653': 37.8863,
    '897_654': 37.9082,
    '897_655': 37.915,
    '897_656': 37.8804,
    '897_657': 37.7986,
    '897_658': 37.6704,
    '897_659': 37.5556,
    '897_660': 37.4707,
    '897_661': 37.4076,
    '897_662': 37.3631,
    '897_663': 37.3334,
    '897_664': 37.3157,
    '897_665': 37.3115,
    '897_666': 37.3206,
    '897_667': 37.3399,
    '897_668': 37.3702,
    '897_669': 37.416,
    '897_670': 37.4786,
    '897_671': 37.5539,
    '897_672': 37.6344,
    '897_673': 37.7163,
    '897_674': 37.8034,
    '897_675': 37.8956,
    '897_676': 37.9923,
    '897_677': 38.0972,
    '897_678': 38.2105,
    '897_679': 38.3285,
    '897_680': 38.4525,
    '897_681': 38.5831,
    '897_682': 38.7121,
    '897_683': 38.8399,
    '897_684': 38.9719,
    '897_685': 39.1087,
    '897_686': 39.2457,
    '897_687': 39.3847,
    '897_688': 39.5286,
    '897_689': 39.6766,
    '897_690': 39.8161,
    '897_691': 39.9577,
    '897_692': 40.0939,
    '897_693': 40.223,
    '897_694': 40.348,
    '897_695': 40.4637,
    '897_696': 40.5751,
    '897_697': 40.6635,
    '897_698': 40.7245,
    '897_699': 40.7667,
    '897_700': 40.8012,
    '897_701': 40.8188,
    '897_702': 40.8392,
    '897_703': 40.8844,
    '897_704': 40.9415,
    '897_705': 41.0143,
    '897_706': 41.0961,
    '897_707': 41.1559,
    '897_708': 41.2066,
    '897_709': 41.2491,
    '897_710': 41.2806,
    '897_711': 41.297,
    '897_712': 41.2931,
    '897_713': 41.2862,
    '897_714': 41.2873,
    '897_715': 41.2891,
    '897_716': 41.2956,
    '897_717': 41.3151,
    '897_718': 41.3427,
    '897_719': 41.3743,
    '897_720': 41.3922,
    '897_721': 41.3808,
    '897_722': 41.3415,
    '897_723': 41.297,
    '897_724': 41.2628,
    '897_725': 41.2381,
    '897_726': 41.1937,
    '897_727': 41.132,
    '897_728': 41.0495,
    '897_729': 40.9504,
    '897_730': 40.8452,
    '897_731': 40.7548,
    '897_732': 40.6651,
    '897_733': 40.5547,
    '897_734': 40.4253,
    '897_735': 40.2925,
    '897_736': 40.1792,
    '897_737': 40.0772,
    '897_738': 39.9855,
    '897_739': 39.8912,
    '897_740': 39.7986,
    '897_741': 39.7075,
    '897_742': 39.6127,
    '897_743': 39.5275,
    '897_744': 39.4706,
    '897_745': 39.4605,
    '897_746': 39.5139,
    '897_747': 39.6147,
    '897_748': 39.7546,
    '897_749': 39.9199,
    '897_750': 40.1017,
    '897_751': 40.2972,
    '897_752': 40.4728,
    '897_753': 40.6013,
    '897_754': 40.6626,
    '897_755': 40.6683,
    '897_756': 40.6462,
    '897_757': 40.6202,
    '897_758': 40.5826,
    '897_759': 40.5358,
    '897_760': 40.4641,
    '897_761': 40.3919,
    '897_762': 40.2872,
    '897_763': 40.1375,
    '897_764': 39.9499,
    '897_765': 39.749,
    '897_766': 39.5092,
    '897_767': 39.2348,
    '897_768': 38.9546,
    '897_769': 38.6833,
    '897_770': 38.439,
    '897_788': 36.8339,
    '897_789': 36.753,
    '897_790': 36.6604,
    '897_791': 36.5574,
    '897_792': 36.4415,
    '897_793': 36.3146,
    '897_794': 36.1765,
    '897_795': 36.0246,
    '897_796': 35.855,
    '897_797': 35.6678,
    '897_798': 35.455,
    '897_799': 35.2165,
    '897_800': 34.9655,
    '897_801': 34.6972,
    '897_802': 34.4031,
    '897_803': 34.1111,
    '897_804': 33.7941,
    '897_805': 33.498,
    '897_806': 33.1897,
    '897_807': 32.8838,
    '897_808': 32.5908,
    '897_809': 32.3251,
    '897_810': 32.0973,
    '897_811': 31.9083,
    '897_812': 31.7412,
    '897_813': 31.5735,
    '897_814': 31.3923,
    '897_815': 31.2116,
    '897_816': 31.0309,
    '897_817': 30.8256,
    '897_818': 30.5956,
    '897_819': 30.3398,
    '897_820': 30.0645,
    '897_821': 29.7537,
    '897_822': 29.4045,
    '897_823': 29.0498,
    '897_824': 28.7008,
    '897_825': 28.3628,
    '897_826': 28.0193,
    '897_827': 27.6671,
    '897_828': 27.3111,
    '898_472': 32.6734,
    '898_473': 32.7415,
    '898_474': 32.807,
    '898_475': 32.8686,
    '898_476': 32.9283,
    '898_477': 32.9908,
    '898_478': 33.0584,
    '898_479': 33.1275,
    '898_480': 33.1947,
    '898_481': 33.257,
    '898_482': 33.3128,
    '898_483': 33.3701,
    '898_484': 33.433,
    '898_485': 33.5022,
    '898_486': 33.578,
    '898_487': 33.6613,
    '898_488': 33.7511,
    '898_489': 33.846,
    '898_490': 33.9392,
    '898_491': 34.0163,
    '898_492': 34.0724,
    '898_493': 34.1171,
    '898_494': 34.1771,
    '898_495': 34.2395,
    '898_496': 34.2925,
    '898_497': 34.329,
    '898_498': 34.3628,
    '898_499': 34.4033,
    '898_500': 34.465,
    '898_501': 34.5266,
    '898_502': 34.5779,
    '898_503': 34.6249,
    '898_504': 34.6635,
    '898_505': 34.7177,
    '898_506': 34.7931,
    '898_507': 34.8991,
    '898_508': 34.9958,
    '898_509': 35.0738,
    '898_510': 35.1417,
    '898_511': 35.2026,
    '898_512': 35.2518,
    '898_513': 35.3031,
    '898_514': 35.3613,
    '898_515': 35.4219,
    '898_516': 35.4855,
    '898_517': 35.5458,
    '898_518': 35.5954,
    '898_519': 35.6447,
    '898_520': 35.7034,
    '898_521': 35.7741,
    '898_522': 35.8396,
    '898_523': 35.888,
    '898_524': 35.9346,
    '898_525': 35.9808,
    '898_526': 36.0471,
    '898_527': 36.1081,
    '898_528': 36.1457,
    '898_529': 36.1723,
    '898_530': 36.2069,
    '898_531': 36.2388,
    '898_532': 36.2569,
    '898_533': 36.263,
    '898_534': 36.2603,
    '898_535': 36.2607,
    '898_536': 36.2589,
    '898_537': 36.2538,
    '898_538': 36.2517,
    '898_539': 36.2664,
    '898_540': 36.2961,
    '898_541': 36.3233,
    '898_542': 36.3448,
    '898_543': 36.3527,
    '898_544': 36.353,
    '898_545': 36.3444,
    '898_546': 36.3429,
    '898_547': 36.3542,
    '898_548': 36.3758,
    '898_549': 36.3944,
    '898_550': 36.4108,
    '898_551': 36.4232,
    '898_552': 36.4435,
    '898_553': 36.4751,
    '898_554': 36.5161,
    '898_555': 36.5525,
    '898_556': 36.5665,
    '898_557': 36.563,
    '898_558': 36.5575,
    '898_559': 36.5561,
    '898_560': 36.5652,
    '898_561': 36.5755,
    '898_562': 36.5898,
    '898_563': 36.6084,
    '898_564': 36.6248,
    '898_565': 36.6417,
    '898_566': 36.6799,
    '898_567': 36.7255,
    '898_568': 36.757,
    '898_569': 36.7792,
    '898_570': 36.7899,
    '898_571': 36.7983,
    '898_572': 36.8086,
    '898_573': 36.8141,
    '898_574': 36.8277,
    '898_575': 36.8567,
    '898_576': 36.8836,
    '898_577': 36.8977,
    '898_578': 36.9013,
    '898_579': 36.91,
    '898_580': 36.9176,
    '898_581': 36.9065,
    '898_582': 36.894,
    '898_583': 36.9099,
    '898_584': 36.927,
    '898_585': 36.9422,
    '898_586': 36.9487,
    '898_587': 36.9537,
    '898_588': 36.9655,
    '898_589': 36.9716,
    '898_590': 36.9707,
    '898_591': 36.9805,
    '898_592': 36.9978,
    '898_593': 37.0008,
    '898_594': 36.9964,
    '898_595': 36.9934,
    '898_596': 36.9839,
    '898_597': 36.9721,
    '898_598': 36.9688,
    '898_599': 36.9806,
    '898_600': 37.0194,
    '898_601': 37.0655,
    '898_602': 37.1118,
    '898_603': 37.1547,
    '898_604': 37.1895,
    '898_605': 37.2204,
    '898_606': 37.2517,
    '898_607': 37.2821,
    '898_608': 37.3156,
    '898_609': 37.3512,
    '898_610': 37.3767,
    '898_611': 37.3973,
    '898_612': 37.426,
    '898_613': 37.4399,
    '898_614': 37.4637,
    '898_615': 37.4773,
    '898_616': 37.4818,
    '898_617': 37.4951,
    '898_618': 37.5123,
    '898_619': 37.5363,
    '898_620': 37.549,
    '898_621': 37.5481,
    '898_622': 37.5437,
    '898_623': 37.5461,
    '898_624': 37.5449,
    '898_625': 37.5325,
    '898_626': 37.4902,
    '898_627': 37.4258,
    '898_628': 37.3799,
    '898_629': 37.3556,
    '898_630': 37.3486,
    '898_631': 37.3561,
    '898_632': 37.3743,
    '898_633': 37.404,
    '898_634': 37.4453,
    '898_635': 37.4374,
    '898_636': 37.4181,
    '898_637': 37.4258,
    '898_638': 37.4552,
    '898_639': 37.4991,
    '898_640': 37.5486,
    '898_641': 37.5936,
    '898_642': 37.6237,
    '898_643': 37.6395,
    '898_644': 37.6346,
    '898_645': 37.6234,
    '898_646': 37.609,
    '898_647': 37.617,
    '898_648': 37.6302,
    '898_649': 37.651,
    '898_650': 37.6903,
    '898_651': 37.7432,
    '898_652': 37.7959,
    '898_653': 37.8404,
    '898_654': 37.8694,
    '898_655': 37.8866,
    '898_656': 37.8641,
    '898_657': 37.7919,
    '898_658': 37.6699,
    '898_659': 37.54,
    '898_660': 37.4477,
    '898_661': 37.3811,
    '898_662': 37.3354,
    '898_663': 37.3056,
    '898_664': 37.2885,
    '898_665': 37.2846,
    '898_666': 37.2924,
    '898_667': 37.3098,
    '898_668': 37.3411,
    '898_669': 37.3895,
    '898_670': 37.4535,
    '898_671': 37.5288,
    '898_672': 37.6084,
    '898_673': 37.6939,
    '898_674': 37.7872,
    '898_675': 37.8848,
    '898_676': 37.9864,
    '898_677': 38.094,
    '898_678': 38.2063,
    '898_679': 38.32,
    '898_680': 38.442,
    '898_681': 38.573,
    '898_682': 38.7043,
    '898_683': 38.8365,
    '898_684': 38.9757,
    '898_685': 39.1193,
    '898_686': 39.2616,
    '898_687': 39.4084,
    '898_688': 39.562,
    '898_689': 39.7182,
    '898_690': 39.8685,
    '898_691': 40.0156,
    '898_692': 40.1611,
    '898_693': 40.3006,
    '898_694': 40.4353,
    '898_695': 40.5583,
    '898_696': 40.6675,
    '898_697': 40.7529,
    '898_698': 40.8146,
    '898_699': 40.8525,
    '898_700': 40.8853,
    '898_701': 40.9101,
    '898_702': 40.9359,
    '898_703': 40.9776,
    '898_704': 41.0275,
    '898_705': 41.0906,
    '898_706': 41.1722,
    '898_707': 41.2444,
    '898_708': 41.3088,
    '898_709': 41.361,
    '898_710': 41.3984,
    '898_711': 41.4151,
    '898_712': 41.4169,
    '898_713': 41.4174,
    '898_714': 41.4246,
    '898_715': 41.4222,
    '898_716': 41.4106,
    '898_717': 41.4284,
    '898_718': 41.4532,
    '898_719': 41.4837,
    '898_720': 41.5044,
    '898_721': 41.494,
    '898_722': 41.4548,
    '898_723': 41.4085,
    '898_724': 41.3719,
    '898_725': 41.3493,
    '898_726': 41.3079,
    '898_727': 41.249,
    '898_728': 41.1651,
    '898_729': 41.0605,
    '898_730': 40.9461,
    '898_731': 40.8464,
    '898_732': 40.7403,
    '898_733': 40.6254,
    '898_734': 40.4943,
    '898_735': 40.364,
    '898_736': 40.2508,
    '898_737': 40.1495,
    '898_738': 40.0616,
    '898_739': 39.9675,
    '898_740': 39.8621,
    '898_741': 39.7627,
    '898_742': 39.6646,
    '898_743': 39.5741,
    '898_744': 39.5076,
    '898_745': 39.4861,
    '898_746': 39.5247,
    '898_747': 39.6121,
    '898_748': 39.7465,
    '898_749': 39.9088,
    '898_750': 40.0876,
    '898_751': 40.2752,
    '898_752': 40.4406,
    '898_753': 40.5587,
    '898_754': 40.6149,
    '898_755': 40.6152,
    '898_756': 40.5947,
    '898_757': 40.5759,
    '898_758': 40.5398,
    '898_759': 40.4894,
    '898_760': 40.4277,
    '898_761': 40.3604,
    '898_762': 40.2578,
    '898_763': 40.1042,
    '898_764': 39.9046,
    '898_765': 39.7013,
    '898_766': 39.4628,
    '898_767': 39.1978,
    '898_768': 38.9135,
    '898_769': 38.6321,
    '898_770': 38.3801,
    '898_788': 36.7784,
    '898_789': 36.6957,
    '898_790': 36.6036,
    '898_791': 36.5005,
    '898_792': 36.3821,
    '898_793': 36.2557,
    '898_794': 36.1157,
    '898_795': 35.9639,
    '898_796': 35.8001,
    '898_797': 35.6181,
    '898_798': 35.417,
    '898_799': 35.1933,
    '898_800': 34.9581,
    '898_801': 34.709,
    '898_802': 34.4439,
    '898_803': 34.1649,
    '898_804': 33.8635,
    '898_805': 33.5651,
    '898_806': 33.2772,
    '898_807': 32.9839,
    '898_808': 32.7024,
    '898_809': 32.4421,
    '898_810': 32.2192,
    '898_811': 32.0431,
    '898_812': 31.8909,
    '898_813': 31.7168,
    '898_814': 31.5387,
    '898_815': 31.3539,
    '898_816': 31.1554,
    '898_817': 30.9406,
    '898_818': 30.7069,
    '898_819': 30.4513,
    '898_820': 30.1743,
    '898_821': 29.8624,
    '898_822': 29.5185,
    '898_823': 29.1694,
    '898_824': 28.8248,
    '898_825': 28.485,
    '898_826': 28.1414,
    '898_827': 27.7917,
    '898_828': 27.4399,
    '899_472': 32.631,
    '899_473': 32.6975,
    '899_474': 32.7633,
    '899_475': 32.8273,
    '899_476': 32.8907,
    '899_477': 32.956,
    '899_478': 33.0246,
    '899_479': 33.0943,
    '899_480': 33.1607,
    '899_481': 33.219,
    '899_482': 33.2745,
    '899_483': 33.333,
    '899_484': 33.3963,
    '899_485': 33.4639,
    '899_486': 33.538,
    '899_487': 33.6189,
    '899_488': 33.7108,
    '899_489': 33.8109,
    '899_490': 33.9054,
    '899_491': 33.9821,
    '899_492': 34.0501,
    '899_493': 34.1138,
    '899_494': 34.1699,
    '899_495': 34.2285,
    '899_496': 34.2818,
    '899_497': 34.3165,
    '899_498': 34.3474,
    '899_499': 34.3865,
    '899_500': 34.436,
    '899_501': 34.4989,
    '899_502': 34.5609,
    '899_503': 34.6099,
    '899_504': 34.6492,
    '899_505': 34.7075,
    '899_506': 34.7913,
    '899_507': 34.8947,
    '899_508': 34.9943,
    '899_509': 35.0794,
    '899_510': 35.1569,
    '899_511': 35.2258,
    '899_512': 35.2811,
    '899_513': 35.3417,
    '899_514': 35.4027,
    '899_515': 35.4639,
    '899_516': 35.5284,
    '899_517': 35.5917,
    '899_518': 35.6418,
    '899_519': 35.6902,
    '899_520': 35.7516,
    '899_521': 35.8277,
    '899_522': 35.9029,
    '899_523': 35.9564,
    '899_524': 35.9996,
    '899_525': 36.0395,
    '899_526': 36.0956,
    '899_527': 36.1553,
    '899_528': 36.1961,
    '899_529': 36.2247,
    '899_530': 36.2575,
    '899_531': 36.2869,
    '899_532': 36.3017,
    '899_533': 36.3065,
    '899_534': 36.2973,
    '899_535': 36.2854,
    '899_536': 36.2764,
    '899_537': 36.2733,
    '899_538': 36.2751,
    '899_539': 36.2933,
    '899_540': 36.3267,
    '899_541': 36.3498,
    '899_542': 36.3687,
    '899_543': 36.381,
    '899_544': 36.3841,
    '899_545': 36.3787,
    '899_546': 36.3779,
    '899_547': 36.371,
    '899_548': 36.3752,
    '899_549': 36.3886,
    '899_550': 36.4112,
    '899_551': 36.4294,
    '899_552': 36.4526,
    '899_553': 36.4889,
    '899_554': 36.5246,
    '899_555': 36.5578,
    '899_556': 36.5661,
    '899_557': 36.5548,
    '899_558': 36.5519,
    '899_559': 36.5551,
    '899_560': 36.5691,
    '899_561': 36.5774,
    '899_562': 36.5931,
    '899_563': 36.615,
    '899_564': 36.6308,
    '899_565': 36.6443,
    '899_566': 36.6791,
    '899_567': 36.7158,
    '899_568': 36.7515,
    '899_569': 36.7814,
    '899_570': 36.7931,
    '899_571': 36.8021,
    '899_572': 36.8119,
    '899_573': 36.8201,
    '899_574': 36.8357,
    '899_575': 36.859,
    '899_576': 36.8849,
    '899_577': 36.9082,
    '899_578': 36.9265,
    '899_579': 36.9467,
    '899_580': 36.9553,
    '899_581': 36.9433,
    '899_582': 36.9327,
    '899_583': 36.9518,
    '899_584': 36.9667,
    '899_585': 36.984,
    '899_586': 36.9939,
    '899_587': 36.994,
    '899_588': 37.0031,
    '899_589': 37.0069,
    '899_590': 37,
    '899_591': 37.009,
    '899_592': 37.0265,
    '899_593': 37.0371,
    '899_594': 37.0343,
    '899_595': 37.0298,
    '899_596': 37.0186,
    '899_597': 37.0006,
    '899_598': 36.9959,
    '899_599': 37.0031,
    '899_600': 37.0355,
    '899_601': 37.0842,
    '899_602': 37.1392,
    '899_603': 37.1866,
    '899_604': 37.2195,
    '899_605': 37.2522,
    '899_606': 37.2838,
    '899_607': 37.3188,
    '899_608': 37.3541,
    '899_609': 37.3883,
    '899_610': 37.4104,
    '899_611': 37.4277,
    '899_612': 37.4554,
    '899_613': 37.4703,
    '899_614': 37.4906,
    '899_615': 37.5033,
    '899_616': 37.5068,
    '899_617': 37.5126,
    '899_618': 37.5175,
    '899_619': 37.5255,
    '899_620': 37.5278,
    '899_621': 37.5197,
    '899_622': 37.5054,
    '899_623': 37.5089,
    '899_624': 37.5039,
    '899_625': 37.4965,
    '899_626': 37.4689,
    '899_627': 37.4149,
    '899_628': 37.3623,
    '899_629': 37.3341,
    '899_630': 37.3233,
    '899_631': 37.3263,
    '899_632': 37.3376,
    '899_633': 37.353,
    '899_634': 37.3758,
    '899_635': 37.3569,
    '899_636': 37.3314,
    '899_637': 37.3348,
    '899_638': 37.3576,
    '899_639': 37.3924,
    '899_640': 37.4327,
    '899_641': 37.4711,
    '899_642': 37.4999,
    '899_643': 37.5152,
    '899_644': 37.5153,
    '899_645': 37.5125,
    '899_646': 37.5105,
    '899_647': 37.5197,
    '899_648': 37.5354,
    '899_649': 37.5639,
    '899_650': 37.6076,
    '899_651': 37.6683,
    '899_652': 37.7326,
    '899_653': 37.7894,
    '899_654': 37.8331,
    '899_655': 37.8557,
    '899_656': 37.8438,
    '899_657': 37.7816,
    '899_658': 37.6629,
    '899_659': 37.5277,
    '899_660': 37.4263,
    '899_661': 37.3553,
    '899_662': 37.3101,
    '899_663': 37.2833,
    '899_664': 37.2684,
    '899_665': 37.2648,
    '899_666': 37.2708,
    '899_667': 37.287,
    '899_668': 37.3178,
    '899_669': 37.3662,
    '899_670': 37.4311,
    '899_671': 37.5051,
    '899_672': 37.585,
    '899_673': 37.674,
    '899_674': 37.7718,
    '899_675': 37.8748,
    '899_676': 37.9801,
    '899_677': 38.0897,
    '899_678': 38.2016,
    '899_679': 38.313,
    '899_680': 38.4331,
    '899_681': 38.564,
    '899_682': 38.6996,
    '899_683': 38.8382,
    '899_684': 38.9828,
    '899_685': 39.13,
    '899_686': 39.2783,
    '899_687': 39.4312,
    '899_688': 39.5924,
    '899_689': 39.7579,
    '899_690': 39.9192,
    '899_691': 40.0723,
    '899_692': 40.2203,
    '899_693': 40.3666,
    '899_694': 40.5111,
    '899_695': 40.6411,
    '899_696': 40.7502,
    '899_697': 40.8351,
    '899_698': 40.8987,
    '899_699': 40.9361,
    '899_700': 40.9611,
    '899_701': 40.9894,
    '899_702': 41.0259,
    '899_703': 41.0719,
    '899_704': 41.1195,
    '899_705': 41.1708,
    '899_706': 41.245,
    '899_707': 41.3192,
    '899_708': 41.3975,
    '899_709': 41.4607,
    '899_710': 41.5072,
    '899_711': 41.5351,
    '899_712': 41.5431,
    '899_713': 41.5522,
    '899_714': 41.5642,
    '899_715': 41.5597,
    '899_716': 41.5318,
    '899_717': 41.542,
    '899_718': 41.5631,
    '899_719': 41.5885,
    '899_720': 41.611,
    '899_721': 41.6078,
    '899_722': 41.573,
    '899_723': 41.517,
    '899_724': 41.4841,
    '899_725': 41.4564,
    '899_726': 41.418,
    '899_727': 41.3634,
    '899_728': 41.2809,
    '899_729': 41.172,
    '899_730': 41.0517,
    '899_731': 40.9411,
    '899_732': 40.8156,
    '899_733': 40.6981,
    '899_734': 40.5705,
    '899_735': 40.4416,
    '899_736': 40.3261,
    '899_737': 40.2213,
    '899_738': 40.1296,
    '899_739': 40.0314,
    '899_740': 39.9214,
    '899_741': 39.8185,
    '899_742': 39.714,
    '899_743': 39.6166,
    '899_744': 39.5466,
    '899_745': 39.5174,
    '899_746': 39.5444,
    '899_747': 39.6196,
    '899_748': 39.7463,
    '899_749': 39.9023,
    '899_750': 40.0742,
    '899_751': 40.2535,
    '899_752': 40.409,
    '899_753': 40.5142,
    '899_754': 40.5601,
    '899_755': 40.5641,
    '899_756': 40.555,
    '899_757': 40.5404,
    '899_758': 40.5093,
    '899_759': 40.4653,
    '899_760': 40.4101,
    '899_761': 40.3407,
    '899_762': 40.2347,
    '899_763': 40.0745,
    '899_764': 39.8666,
    '899_765': 39.6531,
    '899_766': 39.4225,
    '899_767': 39.1688,
    '899_768': 38.8836,
    '899_769': 38.5958,
    '899_770': 38.3293,
    '899_788': 36.7199,
    '899_789': 36.633,
    '899_790': 36.5386,
    '899_791': 36.4313,
    '899_792': 36.3134,
    '899_793': 36.1866,
    '899_794': 36.051,
    '899_795': 35.9035,
    '899_796': 35.7452,
    '899_797': 35.5702,
    '899_798': 35.3776,
    '899_799': 35.1685,
    '899_800': 34.9475,
    '899_801': 34.7177,
    '899_802': 34.4755,
    '899_803': 34.212,
    '899_804': 33.9289,
    '899_805': 33.649,
    '899_806': 33.3677,
    '899_807': 33.0911,
    '899_808': 32.8202,
    '899_809': 32.5744,
    '899_810': 32.3625,
    '899_811': 32.1846,
    '899_812': 32.0195,
    '899_813': 31.8564,
    '899_814': 31.6799,
    '899_815': 31.4871,
    '899_816': 31.2829,
    '899_817': 31.0659,
    '899_818': 30.8291,
    '899_819': 30.5713,
    '899_820': 30.2865,
    '899_821': 29.9828,
    '899_822': 29.6445,
    '899_823': 29.2996,
    '899_824': 28.9512,
    '899_825': 28.6116,
    '899_826': 28.266,
    '899_827': 27.9133,
    '899_828': 27.5633,
    '900_472': 32.5915,
    '900_473': 32.6565,
    '900_474': 32.7218,
    '900_475': 32.7864,
    '900_476': 32.8512,
    '900_477': 32.9171,
    '900_478': 32.9858,
    '900_479': 33.0541,
    '900_480': 33.1173,
    '900_481': 33.1732,
    '900_482': 33.229,
    '900_483': 33.2901,
    '900_484': 33.3559,
    '900_485': 33.4251,
    '900_486': 33.5001,
    '900_487': 33.5803,
    '900_488': 33.669,
    '900_489': 33.7646,
    '900_490': 33.8553,
    '900_491': 33.9364,
    '900_492': 34.0148,
    '900_493': 34.0895,
    '900_494': 34.1527,
    '900_495': 34.2141,
    '900_496': 34.2672,
    '900_497': 34.305,
    '900_498': 34.3351,
    '900_499': 34.3726,
    '900_500': 34.4161,
    '900_501': 34.4732,
    '900_502': 34.5376,
    '900_503': 34.5917,
    '900_504': 34.6393,
    '900_505': 34.7015,
    '900_506': 34.7901,
    '900_507': 34.8911,
    '900_508': 34.9885,
    '900_509': 35.0772,
    '900_510': 35.1617,
    '900_511': 35.2395,
    '900_512': 35.3034,
    '900_513': 35.3702,
    '900_514': 35.4344,
    '900_515': 35.4957,
    '900_516': 35.5598,
    '900_517': 35.6276,
    '900_518': 35.6839,
    '900_519': 35.7365,
    '900_520': 35.8019,
    '900_521': 35.8817,
    '900_522': 35.9581,
    '900_523': 36.0121,
    '900_524': 36.0529,
    '900_525': 36.0901,
    '900_526': 36.1396,
    '900_527': 36.1967,
    '900_528': 36.2426,
    '900_529': 36.2774,
    '900_530': 36.3086,
    '900_531': 36.3335,
    '900_532': 36.3448,
    '900_533': 36.3442,
    '900_534': 36.3309,
    '900_535': 36.3159,
    '900_536': 36.3035,
    '900_537': 36.3043,
    '900_538': 36.3084,
    '900_539': 36.3297,
    '900_540': 36.3567,
    '900_541': 36.3794,
    '900_542': 36.3982,
    '900_543': 36.4054,
    '900_544': 36.4142,
    '900_545': 36.4192,
    '900_546': 36.4136,
    '900_547': 36.397,
    '900_548': 36.3906,
    '900_549': 36.3902,
    '900_550': 36.408,
    '900_551': 36.4324,
    '900_552': 36.4649,
    '900_553': 36.5066,
    '900_554': 36.5358,
    '900_555': 36.5515,
    '900_556': 36.55,
    '900_557': 36.5412,
    '900_558': 36.5425,
    '900_559': 36.5554,
    '900_560': 36.5723,
    '900_561': 36.5824,
    '900_562': 36.5979,
    '900_563': 36.6182,
    '900_564': 36.6376,
    '900_565': 36.6504,
    '900_566': 36.6781,
    '900_567': 36.7113,
    '900_568': 36.7457,
    '900_569': 36.7786,
    '900_570': 36.7998,
    '900_571': 36.8202,
    '900_572': 36.8264,
    '900_573': 36.837,
    '900_574': 36.8539,
    '900_575': 36.8766,
    '900_576': 36.9033,
    '900_577': 36.9325,
    '900_578': 36.9586,
    '900_579': 36.9801,
    '900_580': 36.9894,
    '900_581': 36.9792,
    '900_582': 36.9734,
    '900_583': 36.9999,
    '900_584': 37.017,
    '900_585': 37.0352,
    '900_586': 37.0474,
    '900_587': 37.0454,
    '900_588': 37.0527,
    '900_589': 37.0458,
    '900_590': 37.0328,
    '900_591': 37.0408,
    '900_592': 37.06,
    '900_593': 37.0715,
    '900_594': 37.0717,
    '900_595': 37.059,
    '900_596': 37.0415,
    '900_597': 37.0289,
    '900_598': 37.0232,
    '900_599': 37.0293,
    '900_600': 37.0557,
    '900_601': 37.1063,
    '900_602': 37.1681,
    '900_603': 37.2153,
    '900_604': 37.2443,
    '900_605': 37.2757,
    '900_606': 37.3085,
    '900_607': 37.3533,
    '900_608': 37.3947,
    '900_609': 37.4268,
    '900_610': 37.4474,
    '900_611': 37.4622,
    '900_612': 37.4867,
    '900_613': 37.5037,
    '900_614': 37.5212,
    '900_615': 37.5315,
    '900_616': 37.5368,
    '900_617': 37.5324,
    '900_618': 37.5261,
    '900_619': 37.5149,
    '900_620': 37.5042,
    '900_621': 37.4885,
    '900_622': 37.4682,
    '900_623': 37.4683,
    '900_624': 37.4647,
    '900_625': 37.4701,
    '900_626': 37.4555,
    '900_627': 37.4073,
    '900_628': 37.3536,
    '900_629': 37.3228,
    '900_630': 37.3061,
    '900_631': 37.3034,
    '900_632': 37.3151,
    '900_633': 37.3205,
    '900_634': 37.3099,
    '900_635': 37.2754,
    '900_636': 37.2514,
    '900_637': 37.2515,
    '900_638': 37.2673,
    '900_639': 37.292,
    '900_640': 37.3228,
    '900_641': 37.3553,
    '900_642': 37.3813,
    '900_643': 37.3953,
    '900_644': 37.4068,
    '900_645': 37.4132,
    '900_646': 37.416,
    '900_647': 37.4259,
    '900_648': 37.4443,
    '900_649': 37.4751,
    '900_650': 37.5218,
    '900_651': 37.5857,
    '900_652': 37.6604,
    '900_653': 37.7407,
    '900_654': 37.8029,
    '900_655': 37.8315,
    '900_656': 37.8249,
    '900_657': 37.7677,
    '900_658': 37.6539,
    '900_659': 37.5124,
    '900_660': 37.4057,
    '900_661': 37.3341,
    '900_662': 37.2904,
    '900_663': 37.2667,
    '900_664': 37.2555,
    '900_665': 37.2521,
    '900_666': 37.2548,
    '900_667': 37.2692,
    '900_668': 37.2993,
    '900_669': 37.3464,
    '900_670': 37.4094,
    '900_671': 37.4828,
    '900_672': 37.5635,
    '900_673': 37.6556,
    '900_674': 37.7569,
    '900_675': 37.8639,
    '900_676': 37.9725,
    '900_677': 38.0828,
    '900_678': 38.1946,
    '900_679': 38.307,
    '900_680': 38.4267,
    '900_681': 38.5583,
    '900_682': 38.6987,
    '900_683': 38.8427,
    '900_684': 38.9904,
    '900_685': 39.1418,
    '900_686': 39.2957,
    '900_687': 39.4549,
    '900_688': 39.6221,
    '900_689': 39.7938,
    '900_690': 39.9634,
    '900_691': 40.1276,
    '900_692': 40.2834,
    '900_693': 40.4354,
    '900_694': 40.5809,
    '900_695': 40.7125,
    '900_696': 40.8234,
    '900_697': 40.9111,
    '900_698': 40.9788,
    '900_699': 41.0245,
    '900_700': 41.0539,
    '900_701': 41.0818,
    '900_702': 41.1215,
    '900_703': 41.1764,
    '900_704': 41.2203,
    '900_705': 41.2654,
    '900_706': 41.3257,
    '900_707': 41.3936,
    '900_708': 41.4739,
    '900_709': 41.5415,
    '900_710': 41.6026,
    '900_711': 41.6472,
    '900_712': 41.6668,
    '900_713': 41.6769,
    '900_714': 41.6871,
    '900_715': 41.6888,
    '900_716': 41.656,
    '900_717': 41.6576,
    '900_718': 41.6731,
    '900_719': 41.6968,
    '900_720': 41.7172,
    '900_721': 41.718,
    '900_722': 41.6858,
    '900_723': 41.6315,
    '900_724': 41.5983,
    '900_725': 41.5729,
    '900_726': 41.5272,
    '900_727': 41.476,
    '900_728': 41.3948,
    '900_729': 41.2859,
    '900_730': 41.1603,
    '900_731': 41.0368,
    '900_732': 40.912,
    '900_733': 40.7885,
    '900_734': 40.6595,
    '900_735': 40.5267,
    '900_736': 40.4058,
    '900_737': 40.2976,
    '900_738': 40.1952,
    '900_739': 40.0905,
    '900_740': 39.9815,
    '900_741': 39.8769,
    '900_742': 39.7664,
    '900_743': 39.6652,
    '900_744': 39.5922,
    '900_745': 39.5569,
    '900_746': 39.5769,
    '900_747': 39.6422,
    '900_748': 39.7565,
    '900_749': 39.9004,
    '900_750': 40.0606,
    '900_751': 40.2256,
    '900_752': 40.367,
    '900_753': 40.457,
    '900_754': 40.4969,
    '900_755': 40.5143,
    '900_756': 40.5256,
    '900_757': 40.5162,
    '900_758': 40.4855,
    '900_759': 40.4509,
    '900_760': 40.4004,
    '900_761': 40.326,
    '900_762': 40.2139,
    '900_763': 40.0463,
    '900_764': 39.8355,
    '900_765': 39.6115,
    '900_766': 39.3833,
    '900_767': 39.1377,
    '900_768': 38.8595,
    '900_769': 38.5659,
    '900_770': 38.2862,
    '900_771': 38.0404,
    '900_772': 37.829,
    '900_773': 37.6497,
    '900_774': 37.4951,
    '900_775': 37.37,
    '900_776': 37.2651,
    '900_777': 37.1819,
    '900_778': 37.1184,
    '900_779': 37.0705,
    '900_780': 37.0349,
    '900_781': 37.0191,
    '900_782': 37.0026,
    '900_783': 36.9677,
    '900_784': 36.9347,
    '900_785': 36.8612,
    '900_786': 36.7903,
    '900_787': 36.7253,
    '900_788': 36.6485,
    '900_789': 36.5609,
    '900_790': 36.4709,
    '900_791': 36.3602,
    '900_792': 36.2454,
    '900_793': 36.123,
    '900_794': 35.992,
    '900_795': 35.8505,
    '900_796': 35.6974,
    '900_797': 35.5302,
    '900_798': 35.3494,
    '900_799': 35.149,
    '900_800': 34.9385,
    '900_801': 34.7236,
    '900_802': 34.4955,
    '900_803': 34.2491,
    '900_804': 33.9899,
    '900_805': 33.7369,
    '900_806': 33.4675,
    '900_807': 33.1978,
    '900_808': 32.955,
    '900_809': 32.7222,
    '900_810': 32.5171,
    '900_811': 32.3425,
    '900_812': 32.1759,
    '900_813': 32.0029,
    '900_814': 31.8176,
    '900_815': 31.618,
    '900_816': 31.4148,
    '900_817': 31.191,
    '900_818': 30.9509,
    '900_819': 30.6957,
    '900_820': 30.4043,
    '900_821': 30.1017,
    '900_822': 29.7789,
    '900_823': 29.4433,
    '900_824': 29.0841,
    '900_825': 28.742,
    '900_826': 28.3934,
    '900_827': 28.0433,
    '900_828': 27.6924,
    '901_472': 32.5571,
    '901_473': 32.6195,
    '901_474': 32.6834,
    '901_475': 32.7468,
    '901_476': 32.8104,
    '901_477': 32.8745,
    '901_478': 32.9405,
    '901_479': 33.0036,
    '901_480': 33.0638,
    '901_481': 33.1205,
    '901_482': 33.178,
    '901_483': 33.2435,
    '901_484': 33.3129,
    '901_485': 33.3854,
    '901_486': 33.4618,
    '901_487': 33.5416,
    '901_488': 33.6272,
    '901_489': 33.718,
    '901_490': 33.8099,
    '901_491': 33.8895,
    '901_492': 33.9672,
    '901_493': 34.0415,
    '901_494': 34.114,
    '901_495': 34.1884,
    '901_496': 34.2468,
    '901_497': 34.2901,
    '901_498': 34.3248,
    '901_499': 34.3618,
    '901_500': 34.4018,
    '901_501': 34.4498,
    '901_502': 34.5073,
    '901_503': 34.5725,
    '901_504': 34.6326,
    '901_505': 34.6946,
    '901_506': 34.7817,
    '901_507': 34.8846,
    '901_508': 34.9808,
    '901_509': 35.071,
    '901_510': 35.1626,
    '901_511': 35.2511,
    '901_512': 35.3261,
    '901_513': 35.393,
    '901_514': 35.4579,
    '901_515': 35.5178,
    '901_516': 35.5824,
    '901_517': 35.6535,
    '901_518': 35.7176,
    '901_519': 35.7787,
    '901_520': 35.8474,
    '901_521': 35.9277,
    '901_522': 36.0019,
    '901_523': 36.056,
    '901_524': 36.0929,
    '901_525': 36.1318,
    '901_526': 36.1789,
    '901_527': 36.2338,
    '901_528': 36.2869,
    '901_529': 36.3285,
    '901_530': 36.3601,
    '901_531': 36.378,
    '901_532': 36.3815,
    '901_533': 36.3725,
    '901_534': 36.3552,
    '901_535': 36.3444,
    '901_536': 36.3402,
    '901_537': 36.3433,
    '901_538': 36.3532,
    '901_539': 36.3738,
    '901_540': 36.3907,
    '901_541': 36.4078,
    '901_542': 36.4265,
    '901_543': 36.4315,
    '901_544': 36.4378,
    '901_545': 36.4415,
    '901_546': 36.4324,
    '901_547': 36.4219,
    '901_548': 36.4118,
    '901_549': 36.3993,
    '901_550': 36.4042,
    '901_551': 36.4335,
    '901_552': 36.4752,
    '901_553': 36.5168,
    '901_554': 36.5455,
    '901_555': 36.549,
    '901_556': 36.5356,
    '901_557': 36.5281,
    '901_558': 36.5357,
    '901_559': 36.5551,
    '901_560': 36.5758,
    '901_561': 36.5874,
    '901_562': 36.6073,
    '901_563': 36.6239,
    '901_564': 36.6435,
    '901_565': 36.6583,
    '901_566': 36.6833,
    '901_567': 36.7171,
    '901_568': 36.7519,
    '901_569': 36.7827,
    '901_570': 36.8138,
    '901_571': 36.8319,
    '901_572': 36.8434,
    '901_573': 36.862,
    '901_574': 36.8816,
    '901_575': 36.9016,
    '901_576': 36.9344,
    '901_577': 36.9678,
    '901_578': 36.9994,
    '901_579': 37.019,
    '901_580': 37.0198,
    '901_581': 37.0161,
    '901_582': 37.019,
    '901_583': 37.0459,
    '901_584': 37.0705,
    '901_585': 37.0909,
    '901_586': 37.1019,
    '901_587': 37.1005,
    '901_588': 37.1039,
    '901_589': 37.0896,
    '901_590': 37.0711,
    '901_591': 37.0802,
    '901_592': 37.0993,
    '901_593': 37.1065,
    '901_594': 37.1058,
    '901_595': 37.0869,
    '901_596': 37.0664,
    '901_597': 37.0522,
    '901_598': 37.0452,
    '901_599': 37.0556,
    '901_600': 37.0829,
    '901_601': 37.1343,
    '901_602': 37.1898,
    '901_603': 37.2393,
    '901_604': 37.2711,
    '901_605': 37.2999,
    '901_606': 37.3293,
    '901_607': 37.371,
    '901_608': 37.4175,
    '901_609': 37.447,
    '901_610': 37.4673,
    '901_611': 37.4808,
    '901_612': 37.5029,
    '901_613': 37.5232,
    '901_614': 37.5404,
    '901_615': 37.5525,
    '901_616': 37.5515,
    '901_617': 37.5409,
    '901_618': 37.5305,
    '901_619': 37.5062,
    '901_620': 37.4807,
    '901_621': 37.459,
    '901_622': 37.4381,
    '901_623': 37.4395,
    '901_624': 37.4491,
    '901_625': 37.4597,
    '901_626': 37.4466,
    '901_627': 37.4011,
    '901_628': 37.3561,
    '901_629': 37.3234,
    '901_630': 37.2987,
    '901_631': 37.2876,
    '901_632': 37.2949,
    '901_633': 37.2951,
    '901_634': 37.2551,
    '901_635': 37.2056,
    '901_636': 37.1805,
    '901_637': 37.1747,
    '901_638': 37.1819,
    '901_639': 37.1998,
    '901_640': 37.2243,
    '901_641': 37.2519,
    '901_642': 37.276,
    '901_643': 37.2944,
    '901_644': 37.3155,
    '901_645': 37.3194,
    '901_646': 37.3237,
    '901_647': 37.3368,
    '901_648': 37.3581,
    '901_649': 37.392,
    '901_650': 37.4422,
    '901_651': 37.5093,
    '901_652': 37.5907,
    '901_653': 37.6925,
    '901_654': 37.7691,
    '901_655': 37.8067,
    '901_656': 37.8016,
    '901_657': 37.7476,
    '901_658': 37.6375,
    '901_659': 37.495,
    '901_660': 37.3866,
    '901_661': 37.317,
    '901_662': 37.2755,
    '901_663': 37.2555,
    '901_664': 37.2483,
    '901_665': 37.2444,
    '901_666': 37.2445,
    '901_667': 37.2566,
    '901_668': 37.2853,
    '901_669': 37.3309,
    '901_670': 37.391,
    '901_671': 37.4631,
    '901_672': 37.5452,
    '901_673': 37.6385,
    '901_674': 37.7427,
    '901_675': 37.8533,
    '901_676': 37.9643,
    '901_677': 38.0763,
    '901_678': 38.1907,
    '901_679': 38.3063,
    '901_680': 38.4256,
    '901_681': 38.5589,
    '901_682': 38.7024,
    '901_683': 38.8486,
    '901_684': 38.9998,
    '901_685': 39.1551,
    '901_686': 39.3127,
    '901_687': 39.4771,
    '901_688': 39.6495,
    '901_689': 39.8238,
    '901_690': 40.0026,
    '901_691': 40.177,
    '901_692': 40.3422,
    '901_693': 40.4997,
    '901_694': 40.6435,
    '901_695': 40.7746,
    '901_696': 40.89,
    '901_697': 40.9857,
    '901_698': 41.0622,
    '901_699': 41.1188,
    '901_700': 41.1541,
    '901_701': 41.1802,
    '901_702': 41.2193,
    '901_703': 41.276,
    '901_704': 41.3319,
    '901_705': 41.3697,
    '901_706': 41.4198,
    '901_707': 41.4808,
    '901_708': 41.5461,
    '901_709': 41.6108,
    '901_710': 41.6808,
    '901_711': 41.7386,
    '901_712': 41.7758,
    '901_713': 41.7927,
    '901_714': 41.7986,
    '901_715': 41.8108,
    '901_716': 41.7884,
    '901_717': 41.7774,
    '901_718': 41.7919,
    '901_719': 41.8125,
    '901_720': 41.8273,
    '901_721': 41.825,
    '901_722': 41.7942,
    '901_723': 41.7445,
    '901_724': 41.7106,
    '901_725': 41.6873,
    '901_726': 41.6394,
    '901_727': 41.586,
    '901_728': 41.5031,
    '901_729': 41.393,
    '901_730': 41.2642,
    '901_731': 41.1403,
    '901_732': 41.0204,
    '901_733': 40.891,
    '901_734': 40.7528,
    '901_735': 40.621,
    '901_736': 40.4978,
    '901_737': 40.3877,
    '901_738': 40.2731,
    '901_739': 40.1611,
    '901_740': 40.051,
    '901_741': 39.9441,
    '901_742': 39.8293,
    '901_743': 39.7252,
    '901_744': 39.6518,
    '901_745': 39.6141,
    '901_746': 39.6265,
    '901_747': 39.6795,
    '901_748': 39.7767,
    '901_749': 39.9029,
    '901_750': 40.0472,
    '901_751': 40.1939,
    '901_752': 40.3169,
    '901_753': 40.3941,
    '901_754': 40.4373,
    '901_755': 40.4677,
    '901_756': 40.4914,
    '901_757': 40.4889,
    '901_758': 40.4657,
    '901_759': 40.4422,
    '901_760': 40.3943,
    '901_761': 40.3173,
    '901_762': 40.2017,
    '901_763': 40.0297,
    '901_764': 39.8162,
    '901_765': 39.5775,
    '901_766': 39.347,
    '901_767': 39.1034,
    '901_768': 38.8355,
    '901_769': 38.5378,
    '901_770': 38.2468,
    '901_771': 37.997,
    '901_772': 37.7828,
    '901_773': 37.6005,
    '901_774': 37.4462,
    '901_775': 37.3166,
    '901_776': 37.2133,
    '901_777': 37.1335,
    '901_778': 37.0805,
    '901_779': 37.0419,
    '901_780': 37.0031,
    '901_781': 36.9725,
    '901_782': 36.9449,
    '901_783': 36.9032,
    '901_784': 36.8683,
    '901_785': 36.7902,
    '901_786': 36.7197,
    '901_787': 36.6477,
    '901_788': 36.5661,
    '901_789': 36.4764,
    '901_790': 36.3863,
    '901_791': 36.2881,
    '901_792': 36.1822,
    '901_793': 36.0674,
    '901_794': 35.944,
    '901_795': 35.8105,
    '901_796': 35.6641,
    '901_797': 35.5046,
    '901_798': 35.33,
    '901_799': 35.142,
    '901_800': 34.9453,
    '901_801': 34.7416,
    '901_802': 34.5265,
    '901_803': 34.2975,
    '901_804': 34.0584,
    '901_805': 33.8188,
    '901_806': 33.5737,
    '901_807': 33.3277,
    '901_808': 33.0964,
    '901_809': 32.8725,
    '901_810': 32.6763,
    '901_811': 32.4999,
    '901_812': 32.3304,
    '901_813': 32.1419,
    '901_814': 31.9524,
    '901_815': 31.7528,
    '901_816': 31.5415,
    '901_817': 31.3128,
    '901_818': 31.0693,
    '901_819': 30.8072,
    '901_820': 30.5158,
    '901_821': 30.1939,
    '901_822': 29.8799,
    '901_823': 29.5517,
    '901_824': 29.2042,
    '901_825': 28.861,
    '901_826': 28.523,
    '901_827': 28.1779,
    '901_828': 27.8246,
    '902_472': 32.5262,
    '902_473': 32.5863,
    '902_474': 32.6481,
    '902_475': 32.7087,
    '902_476': 32.7683,
    '902_477': 32.8267,
    '902_478': 32.888,
    '902_479': 32.9478,
    '902_480': 33.008,
    '902_481': 33.0675,
    '902_482': 33.1292,
    '902_483': 33.1975,
    '902_484': 33.2693,
    '902_485': 33.3435,
    '902_486': 33.421,
    '902_487': 33.5006,
    '902_488': 33.5843,
    '902_489': 33.6717,
    '902_490': 33.7612,
    '902_491': 33.8332,
    '902_492': 33.9058,
    '902_493': 33.9817,
    '902_494': 34.0633,
    '902_495': 34.1515,
    '902_496': 34.2202,
    '902_497': 34.2734,
    '902_498': 34.3144,
    '902_499': 34.3521,
    '902_500': 34.391,
    '902_501': 34.4373,
    '902_502': 34.484,
    '902_503': 34.5476,
    '902_504': 34.6112,
    '902_505': 34.681,
    '902_506': 34.7726,
    '902_507': 34.8713,
    '902_508': 34.9705,
    '902_509': 35.0638,
    '902_510': 35.1614,
    '902_511': 35.2614,
    '902_512': 35.3463,
    '902_513': 35.4194,
    '902_514': 35.4846,
    '902_515': 35.5415,
    '902_516': 35.6025,
    '902_517': 35.6706,
    '902_518': 35.7432,
    '902_519': 35.8145,
    '902_520': 35.8855,
    '902_521': 35.9637,
    '902_522': 36.0363,
    '902_523': 36.0873,
    '902_524': 36.1237,
    '902_525': 36.1616,
    '902_526': 36.2103,
    '902_527': 36.2673,
    '902_528': 36.3257,
    '902_529': 36.3731,
    '902_530': 36.4031,
    '902_531': 36.415,
    '902_532': 36.4072,
    '902_533': 36.39,
    '902_534': 36.3715,
    '902_535': 36.3668,
    '902_536': 36.3751,
    '902_537': 36.3844,
    '902_538': 36.3931,
    '902_539': 36.4148,
    '902_540': 36.4286,
    '902_541': 36.4402,
    '902_542': 36.451,
    '902_543': 36.4546,
    '902_544': 36.4541,
    '902_545': 36.4522,
    '902_546': 36.4407,
    '902_547': 36.4325,
    '902_548': 36.4275,
    '902_549': 36.4152,
    '902_550': 36.4103,
    '902_551': 36.4351,
    '902_552': 36.4769,
    '902_553': 36.521,
    '902_554': 36.5463,
    '902_555': 36.5467,
    '902_556': 36.5292,
    '902_557': 36.5232,
    '902_558': 36.5302,
    '902_559': 36.5523,
    '902_560': 36.5763,
    '902_561': 36.5936,
    '902_562': 36.6109,
    '902_563': 36.6301,
    '902_564': 36.6513,
    '902_565': 36.672,
    '902_566': 36.6997,
    '902_567': 36.7327,
    '902_568': 36.7711,
    '902_569': 36.8009,
    '902_570': 36.8305,
    '902_571': 36.8547,
    '902_572': 36.8666,
    '902_573': 36.8908,
    '902_574': 36.91,
    '902_575': 36.9345,
    '902_576': 36.9715,
    '902_577': 37.0061,
    '902_578': 37.0374,
    '902_579': 37.0566,
    '902_580': 37.0615,
    '902_581': 37.0645,
    '902_582': 37.0715,
    '902_583': 37.0952,
    '902_584': 37.1249,
    '902_585': 37.1501,
    '902_586': 37.162,
    '902_587': 37.1568,
    '902_588': 37.1578,
    '902_589': 37.1428,
    '902_590': 37.1191,
    '902_591': 37.1239,
    '902_592': 37.1455,
    '902_593': 37.1506,
    '902_594': 37.1414,
    '902_595': 37.1149,
    '902_596': 37.0908,
    '902_597': 37.0706,
    '902_598': 37.0665,
    '902_599': 37.0804,
    '902_600': 37.1069,
    '902_601': 37.158,
    '902_602': 37.2117,
    '902_603': 37.2542,
    '902_604': 37.2917,
    '902_605': 37.323,
    '902_606': 37.3475,
    '902_607': 37.3736,
    '902_608': 37.4154,
    '902_609': 37.4448,
    '902_610': 37.4667,
    '902_611': 37.4833,
    '902_612': 37.5081,
    '902_613': 37.5311,
    '902_614': 37.547,
    '902_615': 37.5569,
    '902_616': 37.5513,
    '902_617': 37.5378,
    '902_618': 37.5216,
    '902_619': 37.4901,
    '902_620': 37.464,
    '902_621': 37.4447,
    '902_622': 37.4228,
    '902_623': 37.4299,
    '902_624': 37.4611,
    '902_625': 37.4718,
    '902_626': 37.4572,
    '902_627': 37.4149,
    '902_628': 37.3746,
    '902_629': 37.3365,
    '902_630': 37.3014,
    '902_631': 37.2801,
    '902_632': 37.2777,
    '902_633': 37.2726,
    '902_634': 37.2206,
    '902_635': 37.1536,
    '902_636': 37.1199,
    '902_637': 37.1066,
    '902_638': 37.106,
    '902_639': 37.1171,
    '902_640': 37.1363,
    '902_641': 37.1603,
    '902_642': 37.187,
    '902_643': 37.2085,
    '902_644': 37.2255,
    '902_645': 37.2274,
    '902_646': 37.2381,
    '902_647': 37.255,
    '902_648': 37.2784,
    '902_649': 37.317,
    '902_650': 37.3708,
    '902_651': 37.441,
    '902_652': 37.5256,
    '902_653': 37.6298,
    '902_654': 37.7227,
    '902_655': 37.7688,
    '902_656': 37.7689,
    '902_657': 37.72,
    '902_658': 37.617,
    '902_659': 37.4827,
    '902_660': 37.3719,
    '902_661': 37.3039,
    '902_662': 37.265,
    '902_663': 37.2467,
    '902_664': 37.241,
    '902_665': 37.2409,
    '902_666': 37.2422,
    '902_667': 37.2498,
    '902_668': 37.2749,
    '902_669': 37.3183,
    '902_670': 37.3764,
    '902_671': 37.4477,
    '902_672': 37.5306,
    '902_673': 37.6252,
    '902_674': 37.7296,
    '902_675': 37.8421,
    '902_676': 37.9564,
    '902_677': 38.0711,
    '902_678': 38.1893,
    '902_679': 38.3094,
    '902_680': 38.4324,
    '902_681': 38.5673,
    '902_682': 38.7111,
    '902_683': 38.8579,
    '902_684': 39.0112,
    '902_685': 39.169,
    '902_686': 39.3297,
    '902_687': 39.495,
    '902_688': 39.6678,
    '902_689': 39.8485,
    '902_690': 40.0344,
    '902_691': 40.2182,
    '902_692': 40.3896,
    '902_693': 40.5489,
    '902_694': 40.6949,
    '902_695': 40.8283,
    '902_696': 40.9519,
    '902_697': 41.0612,
    '902_698': 41.1492,
    '902_699': 41.2137,
    '902_700': 41.2546,
    '902_701': 41.2778,
    '902_702': 41.3169,
    '902_703': 41.3729,
    '902_704': 41.4335,
    '902_705': 41.4751,
    '902_706': 41.5242,
    '902_707': 41.5783,
    '902_708': 41.6255,
    '902_709': 41.6745,
    '902_710': 41.744,
    '902_711': 41.8115,
    '902_712': 41.866,
    '902_713': 41.8977,
    '902_714': 41.9041,
    '902_715': 41.9282,
    '902_716': 41.9236,
    '902_717': 41.9104,
    '902_718': 41.9248,
    '902_719': 41.9396,
    '902_720': 41.9476,
    '902_721': 41.9434,
    '902_722': 41.9152,
    '902_723': 41.8619,
    '902_724': 41.8246,
    '902_725': 41.8004,
    '902_726': 41.7582,
    '902_727': 41.6966,
    '902_728': 41.6113,
    '902_729': 41.4952,
    '902_730': 41.3736,
    '902_731': 41.2642,
    '902_732': 41.139,
    '902_733': 41.0056,
    '902_734': 40.86,
    '902_735': 40.7258,
    '902_736': 40.6074,
    '902_737': 40.4938,
    '902_738': 40.3705,
    '902_739': 40.248,
    '902_740': 40.1336,
    '902_741': 40.0219,
    '902_742': 39.9056,
    '902_743': 39.8013,
    '902_744': 39.7287,
    '902_745': 39.6917,
    '902_746': 39.6944,
    '902_747': 39.7327,
    '902_748': 39.8072,
    '902_749': 39.9106,
    '902_750': 40.0376,
    '902_751': 40.1672,
    '902_752': 40.2737,
    '902_753': 40.3494,
    '902_754': 40.4016,
    '902_755': 40.4401,
    '902_756': 40.4669,
    '902_757': 40.4668,
    '902_758': 40.4505,
    '902_759': 40.439,
    '902_760': 40.3976,
    '902_761': 40.3157,
    '902_762': 40.1926,
    '902_763': 40.0139,
    '902_764': 39.798,
    '902_765': 39.5551,
    '902_766': 39.3129,
    '902_767': 39.0601,
    '902_768': 38.7892,
    '902_769': 38.501,
    '902_770': 38.2124,
    '902_771': 37.9608,
    '902_772': 37.7466,
    '902_773': 37.5628,
    '902_774': 37.4066,
    '902_775': 37.2758,
    '902_776': 37.172,
    '902_777': 37.0951,
    '902_778': 37.049,
    '902_779': 37.0152,
    '902_780': 36.9735,
    '902_781': 36.929,
    '902_782': 36.8933,
    '902_783': 36.8577,
    '902_784': 36.8017,
    '902_785': 36.7395,
    '902_786': 36.6609,
    '902_787': 36.5799,
    '902_788': 36.4925,
    '902_789': 36.4015,
    '902_790': 36.3125,
    '902_791': 36.2247,
    '902_792': 36.1282,
    '902_793': 36.0236,
    '902_794': 35.9108,
    '902_795': 35.7889,
    '902_796': 35.6492,
    '902_797': 35.4974,
    '902_798': 35.3311,
    '902_799': 35.1548,
    '902_800': 34.9706,
    '902_801': 34.7763,
    '902_802': 34.5729,
    '902_803': 34.3583,
    '902_804': 34.1339,
    '902_805': 33.9042,
    '902_806': 33.675,
    '902_807': 33.4532,
    '902_808': 33.2378,
    '902_809': 33.0165,
    '902_810': 32.8107,
    '902_811': 32.6383,
    '902_812': 32.4629,
    '902_813': 32.2669,
    '902_814': 32.0758,
    '902_815': 31.8737,
    '902_816': 31.6595,
    '902_817': 31.4249,
    '902_818': 31.1757,
    '902_819': 30.9124,
    '902_820': 30.6279,
    '902_821': 30.3169,
    '902_822': 29.9961,
    '902_823': 29.668,
    '902_824': 29.331,
    '902_825': 28.9915,
    '902_826': 28.6538,
    '902_827': 28.306,
    '902_828': 27.9493,
    '903_472': 32.4994,
    '903_473': 32.5576,
    '903_474': 32.6166,
    '903_475': 32.6734,
    '903_476': 32.7272,
    '903_477': 32.7762,
    '903_478': 32.8263,
    '903_479': 32.888,
    '903_480': 32.9525,
    '903_481': 33.0162,
    '903_482': 33.0824,
    '903_483': 33.1527,
    '903_484': 33.2256,
    '903_485': 33.3004,
    '903_486': 33.3779,
    '903_487': 33.4572,
    '903_488': 33.5375,
    '903_489': 33.6193,
    '903_490': 33.6994,
    '903_491': 33.7712,
    '903_492': 33.8431,
    '903_493': 33.9243,
    '903_494': 34.0126,
    '903_495': 34.107,
    '903_496': 34.1912,
    '903_497': 34.2577,
    '903_498': 34.3013,
    '903_499': 34.3383,
    '903_500': 34.3794,
    '903_501': 34.4299,
    '903_502': 34.4804,
    '903_503': 34.5313,
    '903_504': 34.5934,
    '903_505': 34.6721,
    '903_506': 34.761,
    '903_507': 34.8599,
    '903_508': 34.9615,
    '903_509': 35.0567,
    '903_510': 35.1569,
    '903_511': 35.2631,
    '903_512': 35.3571,
    '903_513': 35.4404,
    '903_514': 35.5115,
    '903_515': 35.569,
    '903_516': 35.6248,
    '903_517': 35.6912,
    '903_518': 35.7683,
    '903_519': 35.8435,
    '903_520': 35.9139,
    '903_521': 35.9859,
    '903_522': 36.0548,
    '903_523': 36.1047,
    '903_524': 36.1458,
    '903_525': 36.1849,
    '903_526': 36.2345,
    '903_527': 36.2909,
    '903_528': 36.352,
    '903_529': 36.4047,
    '903_530': 36.4354,
    '903_531': 36.4362,
    '903_532': 36.4219,
    '903_533': 36.3995,
    '903_534': 36.3854,
    '903_535': 36.3856,
    '903_536': 36.4033,
    '903_537': 36.4197,
    '903_538': 36.4286,
    '903_539': 36.4485,
    '903_540': 36.4655,
    '903_541': 36.4761,
    '903_542': 36.477,
    '903_543': 36.4725,
    '903_544': 36.4682,
    '903_545': 36.4577,
    '903_546': 36.4464,
    '903_547': 36.4391,
    '903_548': 36.4382,
    '903_549': 36.4254,
    '903_550': 36.4172,
    '903_551': 36.4423,
    '903_552': 36.4797,
    '903_553': 36.5159,
    '903_554': 36.5354,
    '903_555': 36.5362,
    '903_556': 36.5253,
    '903_557': 36.5222,
    '903_558': 36.5298,
    '903_559': 36.5482,
    '903_560': 36.5715,
    '903_561': 36.5931,
    '903_562': 36.6149,
    '903_563': 36.6394,
    '903_564': 36.666,
    '903_565': 36.6933,
    '903_566': 36.7241,
    '903_567': 36.7547,
    '903_568': 36.7886,
    '903_569': 36.8218,
    '903_570': 36.8532,
    '903_571': 36.8785,
    '903_572': 36.9021,
    '903_573': 36.9236,
    '903_574': 36.9473,
    '903_575': 36.975,
    '903_576': 37.0154,
    '903_577': 37.0491,
    '903_578': 37.0783,
    '903_579': 37.0994,
    '903_580': 37.1112,
    '903_581': 37.1228,
    '903_582': 37.1317,
    '903_583': 37.1438,
    '903_584': 37.1802,
    '903_585': 37.2113,
    '903_586': 37.2302,
    '903_587': 37.2226,
    '903_588': 37.2161,
    '903_589': 37.195,
    '903_590': 37.1653,
    '903_591': 37.1651,
    '903_592': 37.1939,
    '903_593': 37.2048,
    '903_594': 37.1888,
    '903_595': 37.1493,
    '903_596': 37.1174,
    '903_597': 37.0927,
    '903_598': 37.094,
    '903_599': 37.1057,
    '903_600': 37.1265,
    '903_601': 37.178,
    '903_602': 37.2242,
    '903_603': 37.2647,
    '903_604': 37.3003,
    '903_605': 37.3322,
    '903_606': 37.3543,
    '903_607': 37.3716,
    '903_608': 37.3999,
    '903_609': 37.4262,
    '903_610': 37.4507,
    '903_611': 37.4735,
    '903_612': 37.5005,
    '903_613': 37.5249,
    '903_614': 37.5366,
    '903_615': 37.5423,
    '903_616': 37.5328,
    '903_617': 37.5145,
    '903_618': 37.4936,
    '903_619': 37.466,
    '903_620': 37.4477,
    '903_621': 37.4295,
    '903_622': 37.4152,
    '903_623': 37.4336,
    '903_624': 37.4816,
    '903_625': 37.5003,
    '903_626': 37.4845,
    '903_627': 37.4396,
    '903_628': 37.4067,
    '903_629': 37.3598,
    '903_630': 37.3119,
    '903_631': 37.2824,
    '903_632': 37.2706,
    '903_633': 37.2645,
    '903_634': 37.2076,
    '903_635': 37.1216,
    '903_636': 37.0718,
    '903_637': 37.0474,
    '903_638': 37.0389,
    '903_639': 37.0439,
    '903_640': 37.0586,
    '903_641': 37.0798,
    '903_642': 37.1075,
    '903_643': 37.1286,
    '903_644': 37.1393,
    '903_645': 37.1434,
    '903_646': 37.1599,
    '903_647': 37.1791,
    '903_648': 37.2061,
    '903_649': 37.2465,
    '903_650': 37.3045,
    '903_651': 37.3739,
    '903_652': 37.4595,
    '903_653': 37.5656,
    '903_654': 37.6623,
    '903_655': 37.7161,
    '903_656': 37.7238,
    '903_657': 37.686,
    '903_658': 37.6017,
    '903_659': 37.4746,
    '903_660': 37.3631,
    '903_661': 37.2961,
    '903_662': 37.2592,
    '903_663': 37.2416,
    '903_664': 37.2362,
    '903_665': 37.2387,
    '903_666': 37.2432,
    '903_667': 37.2476,
    '903_668': 37.2686,
    '903_669': 37.3096,
    '903_670': 37.366,
    '903_671': 37.436,
    '903_672': 37.5193,
    '903_673': 37.614,
    '903_674': 37.7179,
    '903_675': 37.8303,
    '903_676': 37.9471,
    '903_677': 38.0666,
    '903_678': 38.1896,
    '903_679': 38.3153,
    '903_680': 38.444,
    '903_681': 38.5813,
    '903_682': 38.7235,
    '903_683': 38.8705,
    '903_684': 39.0243,
    '903_685': 39.1842,
    '903_686': 39.3464,
    '903_687': 39.5125,
    '903_688': 39.6863,
    '903_689': 39.8684,
    '903_690': 40.0582,
    '903_691': 40.2506,
    '903_692': 40.4279,
    '903_693': 40.5893,
    '903_694': 40.7387,
    '903_695': 40.8809,
    '903_696': 41.0142,
    '903_697': 41.1346,
    '903_698': 41.2319,
    '903_699': 41.3017,
    '903_700': 41.3482,
    '903_701': 41.3761,
    '903_702': 41.4155,
    '903_703': 41.4726,
    '903_704': 41.5314,
    '903_705': 41.5786,
    '903_706': 41.6283,
    '903_707': 41.6719,
    '903_708': 41.7092,
    '903_709': 41.7502,
    '903_710': 41.8034,
    '903_711': 41.8674,
    '903_712': 41.939,
    '903_713': 41.9845,
    '903_714': 42.0026,
    '903_715': 42.0406,
    '903_716': 42.0526,
    '903_717': 42.05,
    '903_718': 42.0728,
    '903_719': 42.0837,
    '903_720': 42.0917,
    '903_721': 42.0818,
    '903_722': 42.0488,
    '903_723': 41.9892,
    '903_724': 41.94,
    '903_725': 41.9165,
    '903_726': 41.8805,
    '903_727': 41.8167,
    '903_728': 41.7266,
    '903_729': 41.6115,
    '903_730': 41.4943,
    '903_731': 41.3961,
    '903_732': 41.2683,
    '903_733': 41.1279,
    '903_734': 40.975,
    '903_735': 40.8446,
    '903_736': 40.7388,
    '903_737': 40.6169,
    '903_738': 40.4873,
    '903_739': 40.3521,
    '903_740': 40.2354,
    '903_741': 40.1178,
    '903_742': 39.9972,
    '903_743': 39.8891,
    '903_744': 39.8168,
    '903_745': 39.7802,
    '903_746': 39.7747,
    '903_747': 39.7979,
    '903_748': 39.8501,
    '903_749': 39.9283,
    '903_750': 40.0399,
    '903_751': 40.1544,
    '903_752': 40.2527,
    '903_753': 40.3297,
    '903_754': 40.3878,
    '903_755': 40.4301,
    '903_756': 40.4567,
    '903_757': 40.4547,
    '903_758': 40.4423,
    '903_759': 40.4373,
    '903_760': 40.4059,
    '903_761': 40.3218,
    '903_762': 40.19,
    '903_763': 39.9931,
    '903_764': 39.7739,
    '903_765': 39.5315,
    '903_766': 39.2803,
    '903_767': 39.0192,
    '903_768': 38.7434,
    '903_769': 38.4587,
    '903_770': 38.1772,
    '903_771': 37.9286,
    '903_772': 37.7198,
    '903_773': 37.5324,
    '903_774': 37.3767,
    '903_775': 37.2455,
    '903_776': 37.1409,
    '903_777': 37.0642,
    '903_778': 37.0184,
    '903_779': 36.9864,
    '903_780': 36.9412,
    '903_781': 36.891,
    '903_782': 36.8558,
    '903_783': 36.8205,
    '903_784': 36.7708,
    '903_785': 36.7078,
    '903_786': 36.6216,
    '903_787': 36.5253,
    '903_788': 36.4362,
    '903_789': 36.3464,
    '903_790': 36.2589,
    '903_791': 36.1751,
    '903_792': 36.0855,
    '903_793': 35.9926,
    '903_794': 35.8935,
    '903_795': 35.7834,
    '903_796': 35.6552,
    '903_797': 35.5146,
    '903_798': 35.3557,
    '903_799': 35.1889,
    '903_800': 35.0149,
    '903_801': 34.8304,
    '903_802': 34.6346,
    '903_803': 34.4306,
    '903_804': 34.2184,
    '903_805': 34.0008,
    '903_806': 33.7837,
    '903_807': 33.5711,
    '903_808': 33.3637,
    '903_809': 33.165,
    '903_810': 32.9661,
    '903_811': 32.7804,
    '903_812': 32.5737,
    '903_813': 32.3983,
    '903_814': 32.2011,
    '903_815': 31.9828,
    '903_816': 31.7568,
    '903_817': 31.5235,
    '903_818': 31.2733,
    '903_819': 31.0078,
    '903_820': 30.7269,
    '903_821': 30.4238,
    '903_822': 30.1068,
    '903_823': 29.7832,
    '903_824': 29.4545,
    '903_825': 29.1178,
    '903_826': 28.7835,
    '903_827': 28.4398,
    '903_828': 28.0803,
    '904_472': 32.4774,
    '904_473': 32.5331,
    '904_474': 32.5884,
    '904_475': 32.6404,
    '904_476': 32.6877,
    '904_477': 32.7252,
    '904_478': 32.7723,
    '904_479': 32.8323,
    '904_480': 32.8991,
    '904_481': 32.9665,
    '904_482': 33.0358,
    '904_483': 33.1075,
    '904_484': 33.181,
    '904_485': 33.2555,
    '904_486': 33.3324,
    '904_487': 33.4103,
    '904_488': 33.4885,
    '904_489': 33.5659,
    '904_490': 33.6404,
    '904_491': 33.712,
    '904_492': 33.7847,
    '904_493': 33.8661,
    '904_494': 33.9591,
    '904_495': 34.0599,
    '904_496': 34.1548,
    '904_497': 34.231,
    '904_498': 34.279,
    '904_499': 34.3178,
    '904_500': 34.3642,
    '904_501': 34.4188,
    '904_502': 34.4743,
    '904_503': 34.53,
    '904_504': 34.5911,
    '904_505': 34.6786,
    '904_506': 34.7662,
    '904_507': 34.8674,
    '904_508': 34.9651,
    '904_509': 35.0547,
    '904_510': 35.153,
    '904_511': 35.2559,
    '904_512': 35.3565,
    '904_513': 35.4503,
    '904_514': 35.5296,
    '904_515': 35.5895,
    '904_516': 35.6424,
    '904_517': 35.7061,
    '904_518': 35.7834,
    '904_519': 35.8597,
    '904_520': 35.9263,
    '904_521': 35.9904,
    '904_522': 36.0509,
    '904_523': 36.1066,
    '904_524': 36.1498,
    '904_525': 36.1943,
    '904_526': 36.2431,
    '904_527': 36.2968,
    '904_528': 36.3571,
    '904_529': 36.4094,
    '904_530': 36.4384,
    '904_531': 36.4379,
    '904_532': 36.4204,
    '904_533': 36.4019,
    '904_534': 36.3936,
    '904_535': 36.3995,
    '904_536': 36.422,
    '904_537': 36.443,
    '904_538': 36.4579,
    '904_539': 36.4787,
    '904_540': 36.4996,
    '904_541': 36.5087,
    '904_542': 36.5037,
    '904_543': 36.4908,
    '904_544': 36.4856,
    '904_545': 36.4769,
    '904_546': 36.4642,
    '904_547': 36.4517,
    '904_548': 36.4503,
    '904_549': 36.441,
    '904_550': 36.4332,
    '904_551': 36.4561,
    '904_552': 36.4904,
    '904_553': 36.5179,
    '904_554': 36.5316,
    '904_555': 36.5293,
    '904_556': 36.5229,
    '904_557': 36.5233,
    '904_558': 36.5358,
    '904_559': 36.5545,
    '904_560': 36.5746,
    '904_561': 36.5975,
    '904_562': 36.6226,
    '904_563': 36.6519,
    '904_564': 36.68,
    '904_565': 36.7118,
    '904_566': 36.7475,
    '904_567': 36.782,
    '904_568': 36.8133,
    '904_569': 36.8476,
    '904_570': 36.8803,
    '904_571': 36.909,
    '904_572': 36.9368,
    '904_573': 36.9615,
    '904_574': 36.9893,
    '904_575': 37.0254,
    '904_576': 37.0653,
    '904_577': 37.0977,
    '904_578': 37.1292,
    '904_579': 37.1525,
    '904_580': 37.1684,
    '904_581': 37.1929,
    '904_582': 37.1961,
    '904_583': 37.1956,
    '904_584': 37.2304,
    '904_585': 37.2707,
    '904_586': 37.2967,
    '904_587': 37.2924,
    '904_588': 37.2741,
    '904_589': 37.2395,
    '904_590': 37.2141,
    '904_591': 37.2078,
    '904_592': 37.2309,
    '904_593': 37.2455,
    '904_594': 37.2304,
    '904_595': 37.1839,
    '904_596': 37.1422,
    '904_597': 37.1205,
    '904_598': 37.1252,
    '904_599': 37.1346,
    '904_600': 37.1431,
    '904_601': 37.1786,
    '904_602': 37.2189,
    '904_603': 37.2606,
    '904_604': 37.2943,
    '904_605': 37.3229,
    '904_606': 37.3432,
    '904_607': 37.3598,
    '904_608': 37.3833,
    '904_609': 37.4072,
    '904_610': 37.4325,
    '904_611': 37.4561,
    '904_612': 37.4804,
    '904_613': 37.5019,
    '904_614': 37.5131,
    '904_615': 37.5164,
    '904_616': 37.5052,
    '904_617': 37.4855,
    '904_618': 37.4647,
    '904_619': 37.4494,
    '904_620': 37.4348,
    '904_621': 37.4172,
    '904_622': 37.4163,
    '904_623': 37.4484,
    '904_624': 37.4987,
    '904_625': 37.5249,
    '904_626': 37.5114,
    '904_627': 37.4689,
    '904_628': 37.4337,
    '904_629': 37.3867,
    '904_630': 37.3317,
    '904_631': 37.2952,
    '904_632': 37.2743,
    '904_633': 37.2619,
    '904_634': 37.2042,
    '904_635': 37.1002,
    '904_636': 37.0329,
    '904_637': 36.9964,
    '904_638': 36.9787,
    '904_639': 36.9759,
    '904_640': 36.9843,
    '904_641': 37.0014,
    '904_642': 37.0267,
    '904_643': 37.0481,
    '904_644': 37.0552,
    '904_645': 37.0671,
    '904_646': 37.0903,
    '904_647': 37.1122,
    '904_648': 37.1419,
    '904_649': 37.182,
    '904_650': 37.2376,
    '904_651': 37.3086,
    '904_652': 37.3983,
    '904_653': 37.5037,
    '904_654': 37.5948,
    '904_655': 37.6537,
    '904_656': 37.6725,
    '904_657': 37.6506,
    '904_658': 37.5836,
    '904_659': 37.4674,
    '904_660': 37.36,
    '904_661': 37.2943,
    '904_662': 37.2592,
    '904_663': 37.2419,
    '904_664': 37.2359,
    '904_665': 37.2389,
    '904_666': 37.2451,
    '904_667': 37.2468,
    '904_668': 37.2633,
    '904_669': 37.303,
    '904_670': 37.3585,
    '904_671': 37.4276,
    '904_672': 37.5105,
    '904_673': 37.6045,
    '904_674': 37.7076,
    '904_675': 37.82,
    '904_676': 37.939,
    '904_677': 38.0623,
    '904_678': 38.1907,
    '904_679': 38.3212,
    '904_680': 38.4563,
    '904_681': 38.5962,
    '904_682': 38.7367,
    '904_683': 38.8835,
    '904_684': 39.0385,
    '904_685': 39.2004,
    '904_686': 39.3621,
    '904_687': 39.5273,
    '904_688': 39.7028,
    '904_689': 39.8837,
    '904_690': 40.0745,
    '904_691': 40.2677,
    '904_692': 40.4505,
    '904_693': 40.6188,
    '904_694': 40.7778,
    '904_695': 40.9328,
    '904_696': 41.0759,
    '904_697': 41.2052,
    '904_698': 41.3097,
    '904_699': 41.3862,
    '904_700': 41.4417,
    '904_701': 41.4776,
    '904_702': 41.5151,
    '904_703': 41.5721,
    '904_704': 41.6288,
    '904_705': 41.6756,
    '904_706': 41.7212,
    '904_707': 41.7604,
    '904_708': 41.7957,
    '904_709': 41.8379,
    '904_710': 41.883,
    '904_711': 41.9356,
    '904_712': 41.9969,
    '904_713': 42.0527,
    '904_714': 42.086,
    '904_715': 42.1417,
    '904_716': 42.1742,
    '904_717': 42.1889,
    '904_718': 42.2203,
    '904_719': 42.2379,
    '904_720': 42.2467,
    '904_721': 42.2344,
    '904_722': 42.1934,
    '904_723': 42.1278,
    '904_724': 42.0622,
    '904_725': 42.0286,
    '904_726': 41.996,
    '904_727': 41.9379,
    '904_728': 41.8493,
    '904_729': 41.7371,
    '904_730': 41.6229,
    '904_731': 41.5266,
    '904_732': 41.3961,
    '904_733': 41.243,
    '904_734': 41.0962,
    '904_735': 40.9693,
    '904_736': 40.8765,
    '904_737': 40.7489,
    '904_738': 40.6095,
    '904_739': 40.4654,
    '904_740': 40.347,
    '904_741': 40.2242,
    '904_742': 40.1091,
    '904_743': 40.0024,
    '904_744': 39.927,
    '904_745': 39.8832,
    '904_746': 39.8677,
    '904_747': 39.8778,
    '904_748': 39.9158,
    '904_749': 39.9776,
    '904_750': 40.0706,
    '904_751': 40.1685,
    '904_752': 40.257,
    '904_753': 40.3309,
    '904_754': 40.3899,
    '904_755': 40.4335,
    '904_756': 40.4569,
    '904_757': 40.4508,
    '904_758': 40.438,
    '904_759': 40.4332,
    '904_760': 40.4089,
    '904_761': 40.3302,
    '904_762': 40.192,
    '904_763': 39.987,
    '904_764': 39.7555,
    '904_765': 39.5086,
    '904_766': 39.2502,
    '904_767': 38.9849,
    '904_768': 38.704,
    '904_769': 38.4194,
    '904_770': 38.147,
    '904_771': 37.9037,
    '904_772': 37.7022,
    '904_773': 37.5094,
    '904_774': 37.3552,
    '904_775': 37.2255,
    '904_776': 37.1208,
    '904_777': 37.0419,
    '904_778': 36.9913,
    '904_779': 36.9531,
    '904_780': 36.9079,
    '904_781': 36.8625,
    '904_782': 36.8299,
    '904_783': 36.7956,
    '904_784': 36.7545,
    '904_785': 36.6933,
    '904_786': 36.6065,
    '904_787': 36.5021,
    '904_788': 36.4045,
    '904_789': 36.3114,
    '904_790': 36.2155,
    '904_791': 36.1255,
    '904_792': 36.0466,
    '904_793': 35.9678,
    '904_794': 35.8821,
    '904_795': 35.7836,
    '904_796': 35.6699,
    '904_797': 35.5474,
    '904_798': 35.4031,
    '904_799': 35.2416,
    '904_800': 35.0708,
    '904_801': 34.893,
    '904_802': 34.7048,
    '904_803': 34.5108,
    '904_804': 34.3066,
    '904_805': 34.0981,
    '904_806': 33.8896,
    '904_807': 33.6828,
    '904_808': 33.4802,
    '904_809': 33.2881,
    '904_810': 33.097,
    '904_811': 32.9072,
    '904_812': 32.705,
    '904_813': 32.5091,
    '904_814': 32.3052,
    '904_815': 32.0827,
    '904_816': 31.848,
    '904_817': 31.6086,
    '904_818': 31.3619,
    '904_819': 31.096,
    '904_820': 30.8102,
    '904_821': 30.5143,
    '904_822': 30.2039,
    '904_823': 29.8898,
    '904_824': 29.5707,
    '904_825': 29.2414,
    '904_826': 28.9085,
    '904_827': 28.5691,
    '904_828': 28.2089,
    '905_472': 32.4591,
    '905_473': 32.5121,
    '905_474': 32.5627,
    '905_475': 32.6077,
    '905_476': 32.6457,
    '905_477': 32.6785,
    '905_478': 32.7213,
    '905_479': 32.7804,
    '905_480': 32.8486,
    '905_481': 32.919,
    '905_482': 32.9904,
    '905_483': 33.063,
    '905_484': 33.1365,
    '905_485': 33.2101,
    '905_486': 33.2857,
    '905_487': 33.3625,
    '905_488': 33.439,
    '905_489': 33.5134,
    '905_490': 33.5858,
    '905_491': 33.657,
    '905_492': 33.7293,
    '905_493': 33.8099,
    '905_494': 33.9021,
    '905_495': 34.0037,
    '905_496': 34.1012,
    '905_497': 34.1846,
    '905_498': 34.2407,
    '905_499': 34.2875,
    '905_500': 34.3445,
    '905_501': 34.4061,
    '905_502': 34.4672,
    '905_503': 34.5299,
    '905_504': 34.6033,
    '905_505': 34.6953,
    '905_506': 34.7839,
    '905_507': 34.8762,
    '905_508': 34.9681,
    '905_509': 35.0552,
    '905_510': 35.1519,
    '905_511': 35.2525,
    '905_512': 35.3523,
    '905_513': 35.4503,
    '905_514': 35.5335,
    '905_515': 35.5978,
    '905_516': 35.6494,
    '905_517': 35.7079,
    '905_518': 35.7823,
    '905_519': 35.8551,
    '905_520': 35.9174,
    '905_521': 35.9723,
    '905_522': 36.0296,
    '905_523': 36.084,
    '905_524': 36.1334,
    '905_525': 36.1822,
    '905_526': 36.2335,
    '905_527': 36.2844,
    '905_528': 36.3407,
    '905_529': 36.3941,
    '905_530': 36.4205,
    '905_531': 36.4199,
    '905_532': 36.409,
    '905_533': 36.3949,
    '905_534': 36.3921,
    '905_535': 36.4073,
    '905_536': 36.4331,
    '905_537': 36.4577,
    '905_538': 36.4796,
    '905_539': 36.5089,
    '905_540': 36.5327,
    '905_541': 36.5383,
    '905_542': 36.5327,
    '905_543': 36.5174,
    '905_544': 36.5125,
    '905_545': 36.5058,
    '905_546': 36.4914,
    '905_547': 36.4765,
    '905_548': 36.4661,
    '905_549': 36.4629,
    '905_550': 36.4622,
    '905_551': 36.4825,
    '905_552': 36.5161,
    '905_553': 36.5426,
    '905_554': 36.5521,
    '905_555': 36.5448,
    '905_556': 36.534,
    '905_557': 36.5354,
    '905_558': 36.5497,
    '905_559': 36.5707,
    '905_560': 36.5911,
    '905_561': 36.6133,
    '905_562': 36.6382,
    '905_563': 36.6665,
    '905_564': 36.6978,
    '905_565': 36.7353,
    '905_566': 36.7757,
    '905_567': 36.8156,
    '905_568': 36.8508,
    '905_569': 36.8855,
    '905_570': 36.9183,
    '905_571': 36.9471,
    '905_572': 36.9738,
    '905_573': 37.0021,
    '905_574': 37.0387,
    '905_575': 37.0806,
    '905_576': 37.1231,
    '905_577': 37.1562,
    '905_578': 37.1847,
    '905_579': 37.2144,
    '905_580': 37.2321,
    '905_581': 37.2593,
    '905_582': 37.2635,
    '905_583': 37.2507,
    '905_584': 37.2766,
    '905_585': 37.3264,
    '905_586': 37.3561,
    '905_587': 37.3576,
    '905_588': 37.3351,
    '905_589': 37.2951,
    '905_590': 37.2708,
    '905_591': 37.2614,
    '905_592': 37.273,
    '905_593': 37.2786,
    '905_594': 37.265,
    '905_595': 37.2176,
    '905_596': 37.177,
    '905_597': 37.1582,
    '905_598': 37.1509,
    '905_599': 37.1458,
    '905_600': 37.1517,
    '905_601': 37.182,
    '905_602': 37.2134,
    '905_603': 37.2485,
    '905_604': 37.2807,
    '905_605': 37.3124,
    '905_606': 37.3328,
    '905_607': 37.3521,
    '905_608': 37.3768,
    '905_609': 37.4007,
    '905_610': 37.4259,
    '905_611': 37.4489,
    '905_612': 37.4672,
    '905_613': 37.4824,
    '905_614': 37.4963,
    '905_615': 37.4976,
    '905_616': 37.4862,
    '905_617': 37.4648,
    '905_618': 37.4442,
    '905_619': 37.4306,
    '905_620': 37.4199,
    '905_621': 37.4121,
    '905_622': 37.4224,
    '905_623': 37.4597,
    '905_624': 37.5034,
    '905_625': 37.5339,
    '905_626': 37.527,
    '905_627': 37.494,
    '905_628': 37.4579,
    '905_629': 37.4175,
    '905_630': 37.3627,
    '905_631': 37.3206,
    '905_632': 37.2878,
    '905_633': 37.2576,
    '905_634': 37.1976,
    '905_635': 37.0881,
    '905_636': 37.004,
    '905_637': 36.954,
    '905_638': 36.9256,
    '905_639': 36.9141,
    '905_640': 36.9159,
    '905_641': 36.9267,
    '905_642': 36.9471,
    '905_643': 36.9677,
    '905_644': 36.9791,
    '905_645': 36.9983,
    '905_646': 37.0237,
    '905_647': 37.0482,
    '905_648': 37.0776,
    '905_649': 37.1174,
    '905_650': 37.1762,
    '905_651': 37.2547,
    '905_652': 37.3523,
    '905_653': 37.4602,
    '905_654': 37.5403,
    '905_655': 37.5956,
    '905_656': 37.624,
    '905_657': 37.6185,
    '905_658': 37.5665,
    '905_659': 37.4663,
    '905_660': 37.3623,
    '905_661': 37.2976,
    '905_662': 37.2657,
    '905_663': 37.2511,
    '905_664': 37.2463,
    '905_665': 37.2456,
    '905_666': 37.2465,
    '905_667': 37.2435,
    '905_668': 37.2596,
    '905_669': 37.2987,
    '905_670': 37.3545,
    '905_671': 37.4243,
    '905_672': 37.5067,
    '905_673': 37.5994,
    '905_674': 37.7015,
    '905_675': 37.8134,
    '905_676': 37.9336,
    '905_677': 38.0602,
    '905_678': 38.1937,
    '905_679': 38.3296,
    '905_680': 38.4689,
    '905_681': 38.6118,
    '905_682': 38.7504,
    '905_683': 38.8965,
    '905_684': 39.0544,
    '905_685': 39.2166,
    '905_686': 39.3788,
    '905_687': 39.5427,
    '905_688': 39.7183,
    '905_689': 39.8996,
    '905_690': 40.0859,
    '905_691': 40.2767,
    '905_692': 40.4628,
    '905_693': 40.6384,
    '905_694': 40.8103,
    '905_695': 40.9773,
    '905_696': 41.1312,
    '905_697': 41.2692,
    '905_698': 41.3829,
    '905_699': 41.4673,
    '905_700': 41.5304,
    '905_701': 41.5754,
    '905_702': 41.6159,
    '905_703': 41.6704,
    '905_704': 41.7289,
    '905_705': 41.7772,
    '905_706': 41.8204,
    '905_707': 41.8599,
    '905_708': 41.8994,
    '905_709': 41.9432,
    '905_710': 41.9858,
    '905_711': 42.0295,
    '905_712': 42.0619,
    '905_713': 42.1144,
    '905_714': 42.1673,
    '905_715': 42.2402,
    '905_716': 42.2882,
    '905_717': 42.3212,
    '905_718': 42.3632,
    '905_719': 42.393,
    '905_720': 42.4018,
    '905_721': 42.3869,
    '905_722': 42.3434,
    '905_723': 42.2726,
    '905_724': 42.1936,
    '905_725': 42.1389,
    '905_726': 42.1045,
    '905_727': 42.0543,
    '905_728': 41.9727,
    '905_729': 41.861,
    '905_730': 41.7511,
    '905_731': 41.6531,
    '905_732': 41.5231,
    '905_733': 41.3698,
    '905_734': 41.2179,
    '905_735': 41.0943,
    '905_736': 41.0066,
    '905_737': 40.8772,
    '905_738': 40.7254,
    '905_739': 40.579,
    '905_740': 40.4599,
    '905_741': 40.3381,
    '905_742': 40.2279,
    '905_743': 40.1199,
    '905_744': 40.044,
    '905_745': 39.9961,
    '905_746': 39.9743,
    '905_747': 39.9765,
    '905_748': 40.0067,
    '905_749': 40.0587,
    '905_750': 40.1309,
    '905_751': 40.2098,
    '905_752': 40.2846,
    '905_753': 40.3498,
    '905_754': 40.4034,
    '905_755': 40.4433,
    '905_756': 40.4605,
    '905_757': 40.4525,
    '905_758': 40.4399,
    '905_759': 40.4299,
    '905_760': 40.4082,
    '905_761': 40.3363,
    '905_762': 40.2018,
    '905_763': 39.9939,
    '905_764': 39.7515,
    '905_765': 39.4952,
    '905_766': 39.2266,
    '905_767': 38.9565,
    '905_768': 38.6772,
    '905_769': 38.3955,
    '905_770': 38.128,
    '905_771': 37.8887,
    '905_772': 37.6932,
    '905_773': 37.5051,
    '905_774': 37.3482,
    '905_775': 37.2168,
    '905_776': 37.11,
    '905_777': 37.0307,
    '905_778': 36.9733,
    '905_779': 36.9251,
    '905_780': 36.8817,
    '905_781': 36.8432,
    '905_782': 36.8186,
    '905_783': 36.7913,
    '905_784': 36.7511,
    '905_785': 36.6849,
    '905_786': 36.5979,
    '905_787': 36.4919,
    '905_788': 36.3855,
    '905_789': 36.2874,
    '905_790': 36.1827,
    '905_791': 36.0938,
    '905_792': 36.02,
    '905_793': 35.951,
    '905_794': 35.8781,
    '905_795': 35.7942,
    '905_796': 35.6922,
    '905_797': 35.5812,
    '905_798': 35.4514,
    '905_799': 35.2961,
    '905_800': 35.1289,
    '905_801': 34.9559,
    '905_802': 34.7762,
    '905_803': 34.5886,
    '905_804': 34.393,
    '905_805': 34.1912,
    '905_806': 33.9902,
    '905_807': 33.7892,
    '905_808': 33.5913,
    '905_809': 33.3987,
    '905_810': 33.207,
    '905_811': 33.0137,
    '905_812': 32.8139,
    '905_813': 32.6102,
    '905_814': 32.3931,
    '905_815': 32.1675,
    '905_816': 31.9348,
    '905_817': 31.6914,
    '905_818': 31.437,
    '905_819': 31.1708,
    '905_820': 30.8891,
    '905_821': 30.5973,
    '905_822': 30.2938,
    '905_823': 29.9873,
    '905_824': 29.6744,
    '905_825': 29.3522,
    '905_826': 29.0239,
    '905_827': 28.6893,
    '905_828': 28.3396,
    '906_472': 32.4422,
    '906_473': 32.4921,
    '906_474': 32.538,
    '906_475': 32.5758,
    '906_476': 32.6036,
    '906_477': 32.6397,
    '906_478': 32.6752,
    '906_479': 32.7351,
    '906_480': 32.8038,
    '906_481': 32.8745,
    '906_482': 32.9458,
    '906_483': 33.0178,
    '906_484': 33.0903,
    '906_485': 33.1621,
    '906_486': 33.2365,
    '906_487': 33.3125,
    '906_488': 33.3884,
    '906_489': 33.4608,
    '906_490': 33.5315,
    '906_491': 33.6018,
    '906_492': 33.6734,
    '906_493': 33.7514,
    '906_494': 33.8392,
    '906_495': 33.9361,
    '906_496': 34.0371,
    '906_497': 34.1207,
    '906_498': 34.1862,
    '906_499': 34.2454,
    '906_500': 34.3139,
    '906_501': 34.3859,
    '906_502': 34.4599,
    '906_503': 34.5342,
    '906_504': 34.6201,
    '906_505': 34.7112,
    '906_506': 34.7955,
    '906_507': 34.88,
    '906_508': 34.9649,
    '906_509': 35.0557,
    '906_510': 35.1547,
    '906_511': 35.2555,
    '906_512': 35.3512,
    '906_513': 35.4406,
    '906_514': 35.5221,
    '906_515': 35.5851,
    '906_516': 35.6369,
    '906_517': 35.6908,
    '906_518': 35.7568,
    '906_519': 35.8285,
    '906_520': 35.8862,
    '906_521': 35.9325,
    '906_522': 35.9831,
    '906_523': 36.0434,
    '906_524': 36.0994,
    '906_525': 36.1536,
    '906_526': 36.2074,
    '906_527': 36.254,
    '906_528': 36.3044,
    '906_529': 36.3562,
    '906_530': 36.382,
    '906_531': 36.3865,
    '906_532': 36.382,
    '906_533': 36.3788,
    '906_534': 36.3885,
    '906_535': 36.4138,
    '906_536': 36.447,
    '906_537': 36.473,
    '906_538': 36.5004,
    '906_539': 36.5352,
    '906_540': 36.5596,
    '906_541': 36.5639,
    '906_542': 36.557,
    '906_543': 36.5521,
    '906_544': 36.548,
    '906_545': 36.5423,
    '906_546': 36.5309,
    '906_547': 36.521,
    '906_548': 36.5034,
    '906_549': 36.5017,
    '906_550': 36.5087,
    '906_551': 36.5189,
    '906_552': 36.5424,
    '906_553': 36.5651,
    '906_554': 36.5777,
    '906_555': 36.5719,
    '906_556': 36.5587,
    '906_557': 36.5588,
    '906_558': 36.5733,
    '906_559': 36.597,
    '906_560': 36.6157,
    '906_561': 36.6368,
    '906_562': 36.6615,
    '906_563': 36.6892,
    '906_564': 36.7224,
    '906_565': 36.7624,
    '906_566': 36.806,
    '906_567': 36.8492,
    '906_568': 36.891,
    '906_569': 36.9307,
    '906_570': 36.9691,
    '906_571': 36.9971,
    '906_572': 37.0203,
    '906_573': 37.0544,
    '906_574': 37.0999,
    '906_575': 37.1402,
    '906_576': 37.1873,
    '906_577': 37.2168,
    '906_578': 37.2441,
    '906_579': 37.2761,
    '906_580': 37.2995,
    '906_581': 37.3232,
    '906_582': 37.3232,
    '906_583': 37.3031,
    '906_584': 37.324,
    '906_585': 37.3716,
    '906_586': 37.4053,
    '906_587': 37.4129,
    '906_588': 37.3921,
    '906_589': 37.3564,
    '906_590': 37.3287,
    '906_591': 37.3154,
    '906_592': 37.32,
    '906_593': 37.3203,
    '906_594': 37.2996,
    '906_595': 37.2589,
    '906_596': 37.2144,
    '906_597': 37.1916,
    '906_598': 37.1761,
    '906_599': 37.1614,
    '906_600': 37.1623,
    '906_601': 37.1912,
    '906_602': 37.216,
    '906_603': 37.2424,
    '906_604': 37.2757,
    '906_605': 37.3076,
    '906_606': 37.3295,
    '906_607': 37.3511,
    '906_608': 37.3798,
    '906_609': 37.4051,
    '906_610': 37.4293,
    '906_611': 37.4492,
    '906_612': 37.4623,
    '906_613': 37.4696,
    '906_614': 37.4817,
    '906_615': 37.4819,
    '906_616': 37.4717,
    '906_617': 37.4497,
    '906_618': 37.4268,
    '906_619': 37.4155,
    '906_620': 37.4133,
    '906_621': 37.4164,
    '906_622': 37.4369,
    '906_623': 37.4701,
    '906_624': 37.504,
    '906_625': 37.5291,
    '906_626': 37.528,
    '906_627': 37.5056,
    '906_628': 37.4784,
    '906_629': 37.4467,
    '906_630': 37.401,
    '906_631': 37.3539,
    '906_632': 37.3069,
    '906_633': 37.2572,
    '906_634': 37.1897,
    '906_635': 37.0743,
    '906_636': 36.9815,
    '906_637': 36.9179,
    '906_638': 36.8796,
    '906_639': 36.8587,
    '906_640': 36.8532,
    '906_641': 36.8599,
    '906_642': 36.8758,
    '906_643': 36.8933,
    '906_644': 36.9104,
    '906_645': 36.9341,
    '906_646': 36.958,
    '906_647': 36.9854,
    '906_648': 37.0163,
    '906_649': 37.0571,
    '906_650': 37.1208,
    '906_651': 37.2119,
    '906_652': 37.3259,
    '906_653': 37.432,
    '906_654': 37.5063,
    '906_655': 37.5486,
    '906_656': 37.5829,
    '906_657': 37.5886,
    '906_658': 37.55,
    '906_659': 37.4621,
    '906_660': 37.3639,
    '906_661': 37.3023,
    '906_662': 37.2753,
    '906_663': 37.2676,
    '906_664': 37.2653,
    '906_665': 37.2539,
    '906_666': 37.2452,
    '906_667': 37.2372,
    '906_668': 37.2547,
    '906_669': 37.2946,
    '906_670': 37.352,
    '906_671': 37.4245,
    '906_672': 37.5076,
    '906_673': 37.5993,
    '906_674': 37.7005,
    '906_675': 37.8119,
    '906_676': 37.9325,
    '906_677': 38.0622,
    '906_678': 38.1994,
    '906_679': 38.3397,
    '906_680': 38.4818,
    '906_681': 38.6266,
    '906_682': 38.7642,
    '906_683': 38.91,
    '906_684': 39.0704,
    '906_685': 39.2337,
    '906_686': 39.3954,
    '906_687': 39.557,
    '906_688': 39.7297,
    '906_689': 39.9117,
    '906_690': 40.0944,
    '906_691': 40.2787,
    '906_692': 40.4683,
    '906_693': 40.6487,
    '906_694': 40.8311,
    '906_695': 41.0094,
    '906_696': 41.1748,
    '906_697': 41.3219,
    '906_698': 41.4459,
    '906_699': 41.5418,
    '906_700': 41.6141,
    '906_701': 41.6669,
    '906_702': 41.7112,
    '906_703': 41.7653,
    '906_704': 41.8268,
    '906_705': 41.8792,
    '906_706': 41.9234,
    '906_707': 41.9689,
    '906_708': 42.0131,
    '906_709': 42.0563,
    '906_710': 42.0988,
    '906_711': 42.1304,
    '906_712': 42.1531,
    '906_713': 42.2008,
    '906_714': 42.2611,
    '906_715': 42.3368,
    '906_716': 42.397,
    '906_717': 42.4437,
    '906_718': 42.4898,
    '906_719': 42.5293,
    '906_720': 42.5493,
    '906_721': 42.5363,
    '906_722': 42.4905,
    '906_723': 42.4182,
    '906_724': 42.3361,
    '906_725': 42.2627,
    '906_726': 42.2148,
    '906_727': 42.1724,
    '906_728': 42.0959,
    '906_729': 41.9871,
    '906_730': 41.8824,
    '906_731': 41.7748,
    '906_732': 41.6359,
    '906_733': 41.4802,
    '906_734': 41.3352,
    '906_735': 41.2154,
    '906_736': 41.1219,
    '906_737': 40.9847,
    '906_738': 40.8247,
    '906_739': 40.6822,
    '906_740': 40.5666,
    '906_741': 40.4562,
    '906_742': 40.345,
    '906_743': 40.2381,
    '906_744': 40.1632,
    '906_745': 40.1138,
    '906_746': 40.0902,
    '906_747': 40.0896,
    '906_748': 40.1122,
    '906_749': 40.1535,
    '906_750': 40.2092,
    '906_751': 40.2712,
    '906_752': 40.3313,
    '906_753': 40.3844,
    '906_754': 40.4271,
    '906_755': 40.4571,
    '906_756': 40.4684,
    '906_757': 40.4636,
    '906_758': 40.453,
    '906_759': 40.4363,
    '906_760': 40.4114,
    '906_761': 40.3457,
    '906_762': 40.2203,
    '906_763': 40.0124,
    '906_764': 39.7621,
    '906_765': 39.4995,
    '906_766': 39.2271,
    '906_767': 38.9524,
    '906_768': 38.6681,
    '906_769': 38.3839,
    '906_770': 38.1188,
    '906_771': 37.8816,
    '906_772': 37.6803,
    '906_773': 37.5038,
    '906_774': 37.3523,
    '906_775': 37.2202,
    '906_776': 37.1097,
    '906_777': 37.0268,
    '906_778': 36.9644,
    '906_779': 36.9058,
    '906_780': 36.8635,
    '906_781': 36.8282,
    '906_782': 36.8079,
    '906_783': 36.7852,
    '906_784': 36.7431,
    '906_785': 36.6698,
    '906_786': 36.5781,
    '906_787': 36.4759,
    '906_788': 36.3705,
    '906_789': 36.265,
    '906_790': 36.1578,
    '906_791': 36.0658,
    '906_792': 35.999,
    '906_793': 35.9413,
    '906_794': 35.8799,
    '906_795': 35.8088,
    '906_796': 35.7168,
    '906_797': 35.611,
    '906_798': 35.4886,
    '906_799': 35.3429,
    '906_800': 35.1833,
    '906_801': 35.0152,
    '906_802': 34.8405,
    '906_803': 34.6562,
    '906_804': 34.4658,
    '906_805': 34.2733,
    '906_806': 34.0798,
    '906_807': 33.8835,
    '906_808': 33.6889,
    '906_809': 33.4959,
    '906_810': 33.3013,
    '906_811': 33.1045,
    '906_812': 32.9036,
    '906_813': 32.6977,
    '906_814': 32.4816,
    '906_815': 32.2469,
    '906_816': 32.0119,
    '906_817': 31.7666,
    '906_818': 31.5061,
    '906_819': 31.2404,
    '906_820': 30.9582,
    '906_821': 30.6733,
    '906_822': 30.3768,
    '906_823': 30.0863,
    '906_824': 29.7749,
    '906_825': 29.4609,
    '906_826': 29.1391,
    '906_827': 28.8057,
    '906_828': 28.4643,
    '907_472': 32.4249,
    '907_473': 32.4712,
    '907_474': 32.5106,
    '907_475': 32.5426,
    '907_476': 32.5711,
    '907_477': 32.6087,
    '907_478': 32.6458,
    '907_479': 32.7021,
    '907_480': 32.7671,
    '907_481': 32.8345,
    '907_482': 32.9024,
    '907_483': 32.9704,
    '907_484': 33.0393,
    '907_485': 33.1101,
    '907_486': 33.1841,
    '907_487': 33.2594,
    '907_488': 33.3343,
    '907_489': 33.4068,
    '907_490': 33.477,
    '907_491': 33.5463,
    '907_492': 33.6169,
    '907_493': 33.6924,
    '907_494': 33.7745,
    '907_495': 33.8659,
    '907_496': 33.964,
    '907_497': 34.0454,
    '907_498': 34.1207,
    '907_499': 34.1942,
    '907_500': 34.2751,
    '907_501': 34.359,
    '907_502': 34.4484,
    '907_503': 34.535,
    '907_504': 34.6269,
    '907_505': 34.7201,
    '907_506': 34.7991,
    '907_507': 34.8775,
    '907_508': 34.9619,
    '907_509': 35.0579,
    '907_510': 35.1536,
    '907_511': 35.2501,
    '907_512': 35.3388,
    '907_513': 35.4199,
    '907_514': 35.4898,
    '907_515': 35.5486,
    '907_516': 35.6024,
    '907_517': 35.654,
    '907_518': 35.7114,
    '907_519': 35.7801,
    '907_520': 35.8354,
    '907_521': 35.8766,
    '907_522': 35.9265,
    '907_523': 35.9893,
    '907_524': 36.0511,
    '907_525': 36.1124,
    '907_526': 36.1721,
    '907_527': 36.2214,
    '907_528': 36.2648,
    '907_529': 36.3116,
    '907_530': 36.3381,
    '907_531': 36.3486,
    '907_532': 36.3495,
    '907_533': 36.3558,
    '907_534': 36.3834,
    '907_535': 36.4187,
    '907_536': 36.4591,
    '907_537': 36.4912,
    '907_538': 36.5224,
    '907_539': 36.5558,
    '907_540': 36.5753,
    '907_541': 36.5787,
    '907_542': 36.5749,
    '907_543': 36.5789,
    '907_544': 36.58,
    '907_545': 36.5731,
    '907_546': 36.5714,
    '907_547': 36.5683,
    '907_548': 36.5521,
    '907_549': 36.5457,
    '907_550': 36.5568,
    '907_551': 36.5625,
    '907_552': 36.5772,
    '907_553': 36.591,
    '907_554': 36.5987,
    '907_555': 36.5936,
    '907_556': 36.586,
    '907_557': 36.5936,
    '907_558': 36.6117,
    '907_559': 36.639,
    '907_560': 36.6591,
    '907_561': 36.6758,
    '907_562': 36.7033,
    '907_563': 36.7244,
    '907_564': 36.7607,
    '907_565': 36.7984,
    '907_566': 36.8403,
    '907_567': 36.8831,
    '907_568': 36.9258,
    '907_569': 36.9687,
    '907_570': 37.0085,
    '907_571': 37.0463,
    '907_572': 37.0769,
    '907_573': 37.1086,
    '907_574': 37.1585,
    '907_575': 37.204,
    '907_576': 37.2511,
    '907_577': 37.2801,
    '907_578': 37.3038,
    '907_579': 37.3364,
    '907_580': 37.3626,
    '907_581': 37.3762,
    '907_582': 37.3694,
    '907_583': 37.3492,
    '907_584': 37.3685,
    '907_585': 37.4097,
    '907_586': 37.4502,
    '907_587': 37.4636,
    '907_588': 37.4481,
    '907_589': 37.4107,
    '907_590': 37.3722,
    '907_591': 37.3578,
    '907_592': 37.354,
    '907_593': 37.3562,
    '907_594': 37.3373,
    '907_595': 37.3084,
    '907_596': 37.2589,
    '907_597': 37.2211,
    '907_598': 37.2073,
    '907_599': 37.1885,
    '907_600': 37.1754,
    '907_601': 37.1913,
    '907_602': 37.2105,
    '907_603': 37.2319,
    '907_604': 37.2662,
    '907_605': 37.3018,
    '907_606': 37.327,
    '907_607': 37.3477,
    '907_608': 37.3731,
    '907_609': 37.4033,
    '907_610': 37.4279,
    '907_611': 37.4437,
    '907_612': 37.4529,
    '907_613': 37.4549,
    '907_614': 37.4598,
    '907_615': 37.4614,
    '907_616': 37.4521,
    '907_617': 37.4354,
    '907_618': 37.4196,
    '907_619': 37.4088,
    '907_620': 37.4121,
    '907_621': 37.4268,
    '907_622': 37.4492,
    '907_623': 37.4746,
    '907_624': 37.497,
    '907_625': 37.5154,
    '907_626': 37.5207,
    '907_627': 37.5123,
    '907_628': 37.4989,
    '907_629': 37.4764,
    '907_630': 37.4431,
    '907_631': 37.3906,
    '907_632': 37.3334,
    '907_633': 37.2664,
    '907_634': 37.1862,
    '907_635': 37.065,
    '907_636': 36.9675,
    '907_637': 36.8912,
    '907_638': 36.8408,
    '907_639': 36.8106,
    '907_640': 36.799,
    '907_641': 36.8032,
    '907_642': 36.8166,
    '907_643': 36.8296,
    '907_644': 36.8465,
    '907_645': 36.8708,
    '907_646': 36.8992,
    '907_647': 36.9328,
    '907_648': 36.9617,
    '907_649': 37.0032,
    '907_650': 37.0698,
    '907_651': 37.1682,
    '907_652': 37.2962,
    '907_653': 37.3989,
    '907_654': 37.4719,
    '907_655': 37.5185,
    '907_656': 37.5579,
    '907_657': 37.571,
    '907_658': 37.541,
    '907_659': 37.4619,
    '907_660': 37.3612,
    '907_661': 37.3047,
    '907_662': 37.2876,
    '907_663': 37.2888,
    '907_664': 37.2872,
    '907_665': 37.2622,
    '907_666': 37.2371,
    '907_667': 37.2295,
    '907_668': 37.2496,
    '907_669': 37.2919,
    '907_670': 37.3525,
    '907_671': 37.4277,
    '907_672': 37.5128,
    '907_673': 37.6043,
    '907_674': 37.7051,
    '907_675': 37.818,
    '907_676': 37.9417,
    '907_677': 38.0737,
    '907_678': 38.2128,
    '907_679': 38.3544,
    '907_680': 38.4974,
    '907_681': 38.6404,
    '907_682': 38.7818,
    '907_683': 38.9297,
    '907_684': 39.0898,
    '907_685': 39.2516,
    '907_686': 39.4121,
    '907_687': 39.5719,
    '907_688': 39.7415,
    '907_689': 39.9204,
    '907_690': 40.0999,
    '907_691': 40.2785,
    '907_692': 40.4639,
    '907_693': 40.6513,
    '907_694': 40.8402,
    '907_695': 41.0258,
    '907_696': 41.201,
    '907_697': 41.3608,
    '907_698': 41.4945,
    '907_699': 41.603,
    '907_700': 41.688,
    '907_701': 41.753,
    '907_702': 41.8034,
    '907_703': 41.8574,
    '907_704': 41.919,
    '907_705': 41.9781,
    '907_706': 42.0274,
    '907_707': 42.0771,
    '907_708': 42.1254,
    '907_709': 42.1682,
    '907_710': 42.2052,
    '907_711': 42.2331,
    '907_712': 42.2528,
    '907_713': 42.2976,
    '907_714': 42.3535,
    '907_715': 42.4298,
    '907_716': 42.4981,
    '907_717': 42.5556,
    '907_718': 42.6105,
    '907_719': 42.6607,
    '907_720': 42.6844,
    '907_721': 42.6779,
    '907_722': 42.6344,
    '907_723': 42.5664,
    '907_724': 42.4843,
    '907_725': 42.4009,
    '907_726': 42.3391,
    '907_727': 42.2975,
    '907_728': 42.2262,
    '907_729': 42.1228,
    '907_730': 42.0177,
    '907_731': 41.895,
    '907_732': 41.7402,
    '907_733': 41.5884,
    '907_734': 41.4547,
    '907_735': 41.3397,
    '907_736': 41.2358,
    '907_737': 41.0824,
    '907_738': 40.923,
    '907_739': 40.7928,
    '907_740': 40.682,
    '907_741': 40.5785,
    '907_742': 40.4689,
    '907_743': 40.3663,
    '907_744': 40.2888,
    '907_745': 40.2338,
    '907_746': 40.209,
    '907_747': 40.2069,
    '907_748': 40.2241,
    '907_749': 40.2571,
    '907_750': 40.3008,
    '907_751': 40.3501,
    '907_752': 40.3977,
    '907_753': 40.4394,
    '907_754': 40.4707,
    '907_755': 40.4873,
    '907_756': 40.4917,
    '907_757': 40.4875,
    '907_758': 40.4812,
    '907_759': 40.462,
    '907_760': 40.4326,
    '907_761': 40.372,
    '907_762': 40.2507,
    '907_763': 40.0502,
    '907_764': 39.7908,
    '907_765': 39.5184,
    '907_766': 39.2436,
    '907_767': 38.9601,
    '907_768': 38.669,
    '907_769': 38.3798,
    '907_770': 38.1126,
    '907_771': 37.8749,
    '907_772': 37.6751,
    '907_773': 37.5091,
    '907_774': 37.3653,
    '907_775': 37.2332,
    '907_776': 37.1175,
    '907_777': 37.0241,
    '907_778': 36.956,
    '907_779': 36.8965,
    '907_780': 36.8524,
    '907_781': 36.8213,
    '907_782': 36.7965,
    '907_783': 36.7676,
    '907_784': 36.7233,
    '907_785': 36.652,
    '907_786': 36.5588,
    '907_787': 36.4551,
    '907_788': 36.3516,
    '907_789': 36.2437,
    '907_790': 36.1359,
    '907_791': 36.0421,
    '907_792': 35.9797,
    '907_793': 35.93,
    '907_794': 35.8767,
    '907_795': 35.807,
    '907_796': 35.722,
    '907_797': 35.6235,
    '907_798': 35.506,
    '907_799': 35.3665,
    '907_800': 35.2116,
    '907_801': 35.0435,
    '907_802': 34.8736,
    '907_803': 34.6981,
    '907_804': 34.5197,
    '907_805': 34.34,
    '907_806': 34.1554,
    '907_807': 33.9629,
    '907_808': 33.7721,
    '907_809': 33.5817,
    '907_810': 33.3811,
    '907_811': 33.1805,
    '907_812': 32.9767,
    '907_813': 32.768,
    '907_814': 32.5517,
    '907_815': 32.3224,
    '907_816': 32.0755,
    '907_817': 31.8274,
    '907_818': 31.5712,
    '907_819': 31.3042,
    '907_820': 31.0295,
    '907_821': 30.7499,
    '907_822': 30.46,
    '907_823': 30.1765,
    '907_824': 29.8768,
    '907_825': 29.5654,
    '907_826': 29.2495,
    '907_827': 28.922,
    '907_828': 28.5844,
    '908_472': 32.4068,
    '908_473': 32.451,
    '908_474': 32.4851,
    '908_475': 32.5151,
    '908_476': 32.5461,
    '908_477': 32.5836,
    '908_478': 32.6252,
    '908_479': 32.6781,
    '908_480': 32.738,
    '908_481': 32.8002,
    '908_482': 32.8617,
    '908_483': 32.9215,
    '908_484': 32.9876,
    '908_485': 33.0578,
    '908_486': 33.1319,
    '908_487': 33.2073,
    '908_488': 33.2823,
    '908_489': 33.3552,
    '908_490': 33.4247,
    '908_491': 33.4921,
    '908_492': 33.562,
    '908_493': 33.6355,
    '908_494': 33.7146,
    '908_495': 33.7997,
    '908_496': 33.8899,
    '908_497': 33.9724,
    '908_498': 34.0549,
    '908_499': 34.1375,
    '908_500': 34.2281,
    '908_501': 34.3223,
    '908_502': 34.4206,
    '908_503': 34.5174,
    '908_504': 34.6207,
    '908_505': 34.7175,
    '908_506': 34.796,
    '908_507': 34.869,
    '908_508': 34.9556,
    '908_509': 35.0516,
    '908_510': 35.1423,
    '908_511': 35.2307,
    '908_512': 35.312,
    '908_513': 35.3834,
    '908_514': 35.4454,
    '908_515': 35.4995,
    '908_516': 35.5496,
    '908_517': 35.6006,
    '908_518': 35.6516,
    '908_519': 35.7157,
    '908_520': 35.773,
    '908_521': 35.8193,
    '908_522': 35.871,
    '908_523': 35.9329,
    '908_524': 35.9966,
    '908_525': 36.0637,
    '908_526': 36.1309,
    '908_527': 36.188,
    '908_528': 36.231,
    '908_529': 36.2657,
    '908_530': 36.2887,
    '908_531': 36.3036,
    '908_532': 36.3122,
    '908_533': 36.3261,
    '908_534': 36.3676,
    '908_535': 36.4139,
    '908_536': 36.4643,
    '908_537': 36.5028,
    '908_538': 36.5365,
    '908_539': 36.5658,
    '908_540': 36.5852,
    '908_541': 36.5898,
    '908_542': 36.5943,
    '908_543': 36.5976,
    '908_544': 36.5974,
    '908_545': 36.5921,
    '908_546': 36.5982,
    '908_547': 36.6025,
    '908_548': 36.5915,
    '908_549': 36.5833,
    '908_550': 36.5933,
    '908_551': 36.6027,
    '908_552': 36.6132,
    '908_553': 36.6284,
    '908_554': 36.635,
    '908_555': 36.6271,
    '908_556': 36.6267,
    '908_557': 36.6438,
    '908_558': 36.6619,
    '908_559': 36.6938,
    '908_560': 36.714,
    '908_561': 36.7311,
    '908_562': 36.7573,
    '908_563': 36.7731,
    '908_564': 36.8161,
    '908_565': 36.8506,
    '908_566': 36.8864,
    '908_567': 36.9241,
    '908_568': 36.9621,
    '908_569': 37.0021,
    '908_570': 37.0415,
    '908_571': 37.0857,
    '908_572': 37.1222,
    '908_573': 37.1566,
    '908_574': 37.2078,
    '908_575': 37.2592,
    '908_576': 37.3061,
    '908_577': 37.3352,
    '908_578': 37.3548,
    '908_579': 37.3863,
    '908_580': 37.4097,
    '908_581': 37.4149,
    '908_582': 37.4025,
    '908_583': 37.3991,
    '908_584': 37.4134,
    '908_585': 37.4496,
    '908_586': 37.4877,
    '908_587': 37.5039,
    '908_588': 37.4938,
    '908_589': 37.4594,
    '908_590': 37.4141,
    '908_591': 37.3869,
    '908_592': 37.38,
    '908_593': 37.3821,
    '908_594': 37.3683,
    '908_595': 37.3485,
    '908_596': 37.3018,
    '908_597': 37.2526,
    '908_598': 37.2401,
    '908_599': 37.2223,
    '908_600': 37.1925,
    '908_601': 37.1828,
    '908_602': 37.1952,
    '908_603': 37.2183,
    '908_604': 37.2544,
    '908_605': 37.2762,
    '908_606': 37.3006,
    '908_607': 37.3225,
    '908_608': 37.3502,
    '908_609': 37.3805,
    '908_610': 37.4034,
    '908_611': 37.4164,
    '908_612': 37.4289,
    '908_613': 37.4345,
    '908_614': 37.4371,
    '908_615': 37.436,
    '908_616': 37.4284,
    '908_617': 37.4172,
    '908_618': 37.413,
    '908_619': 37.407,
    '908_620': 37.413,
    '908_621': 37.4307,
    '908_622': 37.4473,
    '908_623': 37.4686,
    '908_624': 37.4829,
    '908_625': 37.4974,
    '908_626': 37.5106,
    '908_627': 37.5185,
    '908_628': 37.5191,
    '908_629': 37.509,
    '908_630': 37.4806,
    '908_631': 37.4295,
    '908_632': 37.367,
    '908_633': 37.2879,
    '908_634': 37.1889,
    '908_635': 37.0629,
    '908_636': 36.9601,
    '908_637': 36.8743,
    '908_638': 36.8093,
    '908_639': 36.7698,
    '908_640': 36.7525,
    '908_641': 36.7554,
    '908_642': 36.768,
    '908_643': 36.7793,
    '908_644': 36.7914,
    '908_645': 36.8142,
    '908_646': 36.8456,
    '908_647': 36.8793,
    '908_648': 36.9092,
    '908_649': 36.9533,
    '908_650': 37.0227,
    '908_651': 37.1242,
    '908_652': 37.2562,
    '908_653': 37.3601,
    '908_654': 37.4355,
    '908_655': 37.498,
    '908_656': 37.5456,
    '908_657': 37.5612,
    '908_658': 37.5344,
    '908_659': 37.4569,
    '908_660': 37.3601,
    '908_661': 37.3112,
    '908_662': 37.3064,
    '908_663': 37.3158,
    '908_664': 37.3137,
    '908_665': 37.278,
    '908_666': 37.2308,
    '908_667': 37.2229,
    '908_668': 37.2468,
    '908_669': 37.2937,
    '908_670': 37.3578,
    '908_671': 37.4359,
    '908_672': 37.5228,
    '908_673': 37.6154,
    '908_674': 37.7165,
    '908_675': 37.8322,
    '908_676': 37.9607,
    '908_677': 38.0944,
    '908_678': 38.2335,
    '908_679': 38.3752,
    '908_680': 38.5176,
    '908_681': 38.6627,
    '908_682': 38.8043,
    '908_683': 38.9546,
    '908_684': 39.1138,
    '908_685': 39.2726,
    '908_686': 39.4295,
    '908_687': 39.5882,
    '908_688': 39.7522,
    '908_689': 39.924,
    '908_690': 40.1,
    '908_691': 40.2758,
    '908_692': 40.4588,
    '908_693': 40.6453,
    '908_694': 40.8387,
    '908_695': 41.0296,
    '908_696': 41.2146,
    '908_697': 41.3831,
    '908_698': 41.5276,
    '908_699': 41.6489,
    '908_700': 41.7457,
    '908_701': 41.8226,
    '908_702': 41.8836,
    '908_703': 41.9459,
    '908_704': 42.0097,
    '908_705': 42.0739,
    '908_706': 42.131,
    '908_707': 42.1834,
    '908_708': 42.2329,
    '908_709': 42.2745,
    '908_710': 42.308,
    '908_711': 42.336,
    '908_712': 42.3512,
    '908_713': 42.3901,
    '908_714': 42.4427,
    '908_715': 42.5154,
    '908_716': 42.5978,
    '908_717': 42.6627,
    '908_718': 42.7256,
    '908_719': 42.7804,
    '908_720': 42.8126,
    '908_721': 42.8124,
    '908_722': 42.7694,
    '908_723': 42.7083,
    '908_724': 42.6295,
    '908_725': 42.5386,
    '908_726': 42.468,
    '908_727': 42.4257,
    '908_728': 42.3616,
    '908_729': 42.2693,
    '908_730': 42.1558,
    '908_731': 42.0215,
    '908_732': 41.8595,
    '908_733': 41.7101,
    '908_734': 41.5861,
    '908_735': 41.4674,
    '908_736': 41.358,
    '908_737': 41.1944,
    '908_738': 41.0416,
    '908_739': 40.9122,
    '908_740': 40.7992,
    '908_741': 40.691,
    '908_742': 40.591,
    '908_743': 40.5001,
    '908_744': 40.423,
    '908_745': 40.3538,
    '908_746': 40.3265,
    '908_747': 40.3257,
    '908_748': 40.3418,
    '908_749': 40.3707,
    '908_750': 40.4075,
    '908_751': 40.4499,
    '908_752': 40.4892,
    '908_753': 40.5218,
    '908_754': 40.5428,
    '908_755': 40.5451,
    '908_756': 40.5358,
    '908_757': 40.5276,
    '908_758': 40.5269,
    '908_759': 40.511,
    '908_760': 40.4797,
    '908_761': 40.4165,
    '908_762': 40.2907,
    '908_763': 40.0846,
    '908_764': 39.8249,
    '908_765': 39.5444,
    '908_766': 39.2649,
    '908_767': 38.9664,
    '908_768': 38.6659,
    '908_769': 38.3756,
    '908_770': 38.107,
    '908_771': 37.8731,
    '908_772': 37.6782,
    '908_773': 37.5157,
    '908_774': 37.3768,
    '908_775': 37.2492,
    '908_776': 37.1296,
    '908_777': 37.0282,
    '908_778': 36.9525,
    '908_779': 36.8918,
    '908_780': 36.8468,
    '908_781': 36.8149,
    '908_782': 36.789,
    '908_783': 36.7567,
    '908_784': 36.7104,
    '908_785': 36.6388,
    '908_786': 36.5437,
    '908_787': 36.4368,
    '908_788': 36.3247,
    '908_789': 36.2177,
    '908_790': 36.1105,
    '908_791': 36.0184,
    '908_792': 35.9587,
    '908_793': 35.9107,
    '908_794': 35.8599,
    '908_795': 35.7932,
    '908_796': 35.7102,
    '908_797': 35.6146,
    '908_798': 35.4962,
    '908_799': 35.3601,
    '908_800': 35.2106,
    '908_801': 35.0516,
    '908_802': 34.889,
    '908_803': 34.7238,
    '908_804': 34.5579,
    '908_805': 34.3895,
    '908_806': 34.2115,
    '908_807': 34.0242,
    '908_808': 33.8356,
    '908_809': 33.6464,
    '908_810': 33.4458,
    '908_811': 33.2426,
    '908_812': 33.0358,
    '908_813': 32.8241,
    '908_814': 32.6053,
    '908_815': 32.3772,
    '908_816': 32.136,
    '908_817': 31.8863,
    '908_818': 31.6302,
    '908_819': 31.3687,
    '908_820': 31.0984,
    '908_821': 30.8232,
    '908_822': 30.542,
    '908_823': 30.2551,
    '908_824': 29.9685,
    '908_825': 29.6695,
    '908_826': 29.3532,
    '908_827': 29.0278,
    '908_828': 28.6951,
    '909_472': 32.3836,
    '909_473': 32.4257,
    '909_474': 32.4575,
    '909_475': 32.4872,
    '909_476': 32.5215,
    '909_477': 32.5609,
    '909_478': 32.6059,
    '909_479': 32.6575,
    '909_480': 32.7135,
    '909_481': 32.7709,
    '909_482': 32.8275,
    '909_483': 32.8811,
    '909_484': 32.9406,
    '909_485': 33.0081,
    '909_486': 33.0818,
    '909_487': 33.1575,
    '909_488': 33.2334,
    '909_489': 33.3074,
    '909_490': 33.3756,
    '909_491': 33.4395,
    '909_492': 33.5078,
    '909_493': 33.5803,
    '909_494': 33.6567,
    '909_495': 33.7375,
    '909_496': 33.8189,
    '909_497': 33.9066,
    '909_498': 33.9915,
    '909_499': 34.0779,
    '909_500': 34.175,
    '909_501': 34.277,
    '909_502': 34.3804,
    '909_503': 34.4841,
    '909_504': 34.5937,
    '909_505': 34.6999,
    '909_506': 34.7806,
    '909_507': 34.8531,
    '909_508': 34.9426,
    '909_509': 35.0337,
    '909_510': 35.1198,
    '909_511': 35.2,
    '909_512': 35.274,
    '909_513': 35.3387,
    '909_514': 35.3971,
    '909_515': 35.4496,
    '909_516': 35.4947,
    '909_517': 35.5449,
    '909_518': 35.5948,
    '909_519': 35.6482,
    '909_520': 35.7064,
    '909_521': 35.76,
    '909_522': 35.816,
    '909_523': 35.8771,
    '909_524': 35.9404,
    '909_525': 36.013,
    '909_526': 36.0868,
    '909_527': 36.1511,
    '909_528': 36.1973,
    '909_529': 36.2246,
    '909_530': 36.2401,
    '909_531': 36.2515,
    '909_532': 36.264,
    '909_533': 36.2868,
    '909_534': 36.336,
    '909_535': 36.393,
    '909_536': 36.4548,
    '909_537': 36.504,
    '909_538': 36.5426,
    '909_539': 36.5718,
    '909_540': 36.5899,
    '909_541': 36.6011,
    '909_542': 36.606,
    '909_543': 36.603,
    '909_544': 36.5997,
    '909_545': 36.597,
    '909_546': 36.6064,
    '909_547': 36.6141,
    '909_548': 36.6101,
    '909_549': 36.6032,
    '909_550': 36.6154,
    '909_551': 36.634,
    '909_552': 36.648,
    '909_553': 36.6661,
    '909_554': 36.6737,
    '909_555': 36.6724,
    '909_556': 36.6709,
    '909_557': 36.694,
    '909_558': 36.7104,
    '909_559': 36.7411,
    '909_560': 36.7604,
    '909_561': 36.7765,
    '909_562': 36.7936,
    '909_563': 36.8094,
    '909_564': 36.8588,
    '909_565': 36.9089,
    '909_566': 36.9496,
    '909_567': 36.9839,
    '909_568': 37.0123,
    '909_569': 37.0393,
    '909_570': 37.0708,
    '909_571': 37.1123,
    '909_572': 37.1521,
    '909_573': 37.1879,
    '909_574': 37.2448,
    '909_575': 37.3003,
    '909_576': 37.3535,
    '909_577': 37.3828,
    '909_578': 37.3983,
    '909_579': 37.4306,
    '909_580': 37.4493,
    '909_581': 37.4423,
    '909_582': 37.4336,
    '909_583': 37.4469,
    '909_584': 37.4561,
    '909_585': 37.4744,
    '909_586': 37.5106,
    '909_587': 37.53,
    '909_588': 37.5247,
    '909_589': 37.4961,
    '909_590': 37.4478,
    '909_591': 37.4087,
    '909_592': 37.3907,
    '909_593': 37.3903,
    '909_594': 37.383,
    '909_595': 37.3687,
    '909_596': 37.3324,
    '909_597': 37.2825,
    '909_598': 37.2624,
    '909_599': 37.234,
    '909_600': 37.1978,
    '909_601': 37.1793,
    '909_602': 37.184,
    '909_603': 37.2073,
    '909_604': 37.2314,
    '909_605': 37.2406,
    '909_606': 37.266,
    '909_607': 37.2935,
    '909_608': 37.3255,
    '909_609': 37.3505,
    '909_610': 37.3651,
    '909_611': 37.375,
    '909_612': 37.3901,
    '909_613': 37.3983,
    '909_614': 37.404,
    '909_615': 37.4017,
    '909_616': 37.3934,
    '909_617': 37.3912,
    '909_618': 37.3982,
    '909_619': 37.4007,
    '909_620': 37.4103,
    '909_621': 37.4267,
    '909_622': 37.4486,
    '909_623': 37.4651,
    '909_624': 37.4742,
    '909_625': 37.4834,
    '909_626': 37.4999,
    '909_627': 37.5215,
    '909_628': 37.5342,
    '909_629': 37.5338,
    '909_630': 37.5082,
    '909_631': 37.4614,
    '909_632': 37.3983,
    '909_633': 37.3123,
    '909_634': 37.2056,
    '909_635': 37.0759,
    '909_636': 36.9607,
    '909_637': 36.8602,
    '909_638': 36.7844,
    '909_639': 36.7365,
    '909_640': 36.7124,
    '909_641': 36.7127,
    '909_642': 36.7233,
    '909_643': 36.7362,
    '909_644': 36.7461,
    '909_645': 36.7627,
    '909_646': 36.7951,
    '909_647': 36.8263,
    '909_648': 36.8569,
    '909_649': 36.9062,
    '909_650': 36.9773,
    '909_651': 37.0807,
    '909_652': 37.2134,
    '909_653': 37.3208,
    '909_654': 37.4016,
    '909_655': 37.4802,
    '909_656': 37.5362,
    '909_657': 37.5536,
    '909_658': 37.5222,
    '909_659': 37.4433,
    '909_660': 37.355,
    '909_661': 37.3232,
    '909_662': 37.3351,
    '909_663': 37.3517,
    '909_664': 37.3445,
    '909_665': 37.2897,
    '909_666': 37.2258,
    '909_667': 37.2198,
    '909_668': 37.2486,
    '909_669': 37.3003,
    '909_670': 37.3676,
    '909_671': 37.4477,
    '909_672': 37.5368,
    '909_673': 37.6322,
    '909_674': 37.7353,
    '909_675': 37.8522,
    '909_676': 37.9829,
    '909_677': 38.1149,
    '909_678': 38.2515,
    '909_679': 38.3925,
    '909_680': 38.535,
    '909_681': 38.6823,
    '909_682': 38.8277,
    '909_683': 38.9793,
    '909_684': 39.1376,
    '909_685': 39.2958,
    '909_686': 39.4512,
    '909_687': 39.6052,
    '909_688': 39.7642,
    '909_689': 39.9272,
    '909_690': 40.0979,
    '909_691': 40.2732,
    '909_692': 40.4566,
    '909_693': 40.6469,
    '909_694': 40.8365,
    '909_695': 41.0283,
    '909_696': 41.2173,
    '909_697': 41.3923,
    '909_698': 41.5441,
    '909_699': 41.6752,
    '909_700': 41.7835,
    '909_701': 41.8729,
    '909_702': 41.9521,
    '909_703': 42.0287,
    '909_704': 42.0984,
    '909_705': 42.1647,
    '909_706': 42.2269,
    '909_707': 42.2855,
    '909_708': 42.3369,
    '909_709': 42.3788,
    '909_710': 42.4117,
    '909_711': 42.4374,
    '909_712': 42.4526,
    '909_713': 42.4772,
    '909_714': 42.5316,
    '909_715': 42.5979,
    '909_716': 42.6857,
    '909_717': 42.7581,
    '909_718': 42.8303,
    '909_719': 42.8998,
    '909_720': 42.9368,
    '909_721': 42.9473,
    '909_722': 42.9148,
    '909_723': 42.8494,
    '909_724': 42.7676,
    '909_725': 42.6783,
    '909_726': 42.6017,
    '909_727': 42.5541,
    '909_728': 42.4934,
    '909_729': 42.4047,
    '909_730': 42.2955,
    '909_731': 42.1529,
    '909_732': 41.9921,
    '909_733': 41.8411,
    '909_734': 41.7166,
    '909_735': 41.5944,
    '909_736': 41.4811,
    '909_737': 41.3216,
    '909_738': 41.1708,
    '909_739': 41.0379,
    '909_740': 40.9178,
    '909_741': 40.797,
    '909_742': 40.7005,
    '909_743': 40.6168,
    '909_744': 40.5448,
    '909_745': 40.4711,
    '909_746': 40.442,
    '909_747': 40.4447,
    '909_748': 40.4649,
    '909_749': 40.4951,
    '909_750': 40.533,
    '909_751': 40.5751,
    '909_752': 40.6122,
    '909_753': 40.634,
    '909_754': 40.6396,
    '909_755': 40.6245,
    '909_756': 40.6004,
    '909_757': 40.5849,
    '909_758': 40.5819,
    '909_759': 40.5728,
    '909_760': 40.5433,
    '909_761': 40.472,
    '909_762': 40.3406,
    '909_763': 40.1331,
    '909_764': 39.8698,
    '909_765': 39.5763,
    '909_766': 39.2813,
    '909_767': 38.9687,
    '909_768': 38.6603,
    '909_769': 38.3711,
    '909_770': 38.1036,
    '909_771': 37.8744,
    '909_772': 37.6813,
    '909_773': 37.5185,
    '909_774': 37.3796,
    '909_775': 37.2588,
    '909_776': 37.1416,
    '909_777': 37.0392,
    '909_778': 36.9584,
    '909_779': 36.8953,
    '909_780': 36.8486,
    '909_781': 36.8179,
    '909_782': 36.7896,
    '909_783': 36.7508,
    '909_784': 36.6976,
    '909_785': 36.6251,
    '909_786': 36.5293,
    '909_787': 36.4206,
    '909_788': 36.3083,
    '909_789': 36.1929,
    '909_790': 36.0877,
    '909_791': 36.0019,
    '909_792': 35.9403,
    '909_793': 35.8912,
    '909_794': 35.8371,
    '909_795': 35.7664,
    '909_796': 35.6825,
    '909_797': 35.5839,
    '909_798': 35.4693,
    '909_799': 35.3417,
    '909_800': 35.2012,
    '909_801': 35.0503,
    '909_802': 34.8895,
    '909_803': 34.7269,
    '909_804': 34.5716,
    '909_805': 34.4134,
    '909_806': 34.2466,
    '909_807': 34.0648,
    '909_808': 33.8788,
    '909_809': 33.6904,
    '909_810': 33.4932,
    '909_811': 33.2911,
    '909_812': 33.0839,
    '909_813': 32.8697,
    '909_814': 32.6481,
    '909_815': 32.4205,
    '909_816': 32.184,
    '909_817': 31.9395,
    '909_818': 31.6876,
    '909_819': 31.4319,
    '909_820': 31.1657,
    '909_821': 30.9,
    '909_822': 30.6245,
    '909_823': 30.3432,
    '909_824': 30.0544,
    '909_825': 29.7653,
    '909_826': 29.4522,
    '909_827': 29.1298,
    '909_828': 28.799,
    '910_472': 32.35,
    '910_473': 32.3928,
    '910_474': 32.4289,
    '910_475': 32.4636,
    '910_476': 32.4999,
    '910_477': 32.5412,
    '910_478': 32.5885,
    '910_479': 32.6391,
    '910_480': 32.6909,
    '910_481': 32.7426,
    '910_482': 32.7924,
    '910_483': 32.842,
    '910_484': 32.8965,
    '910_485': 32.9628,
    '910_486': 33.035,
    '910_487': 33.1099,
    '910_488': 33.1862,
    '910_489': 33.2616,
    '910_490': 33.3276,
    '910_491': 33.3906,
    '910_492': 33.4559,
    '910_493': 33.5258,
    '910_494': 33.5993,
    '910_495': 33.6766,
    '910_496': 33.757,
    '910_497': 33.8447,
    '910_498': 33.9322,
    '910_499': 34.0231,
    '910_500': 34.1229,
    '910_501': 34.2346,
    '910_502': 34.3398,
    '910_503': 34.4454,
    '910_504': 34.554,
    '910_505': 34.6613,
    '910_506': 34.7469,
    '910_507': 34.8257,
    '910_508': 34.9156,
    '910_509': 35.0044,
    '910_510': 35.0899,
    '910_511': 35.166,
    '910_512': 35.2332,
    '910_513': 35.2922,
    '910_514': 35.3517,
    '910_515': 35.4034,
    '910_516': 35.4453,
    '910_517': 35.4915,
    '910_518': 35.5422,
    '910_519': 35.5898,
    '910_520': 35.6468,
    '910_521': 35.7023,
    '910_522': 35.7599,
    '910_523': 35.8226,
    '910_524': 35.8851,
    '910_525': 35.9577,
    '910_526': 36.0346,
    '910_527': 36.1054,
    '910_528': 36.1531,
    '910_529': 36.1774,
    '910_530': 36.1906,
    '910_531': 36.202,
    '910_532': 36.2147,
    '910_533': 36.2418,
    '910_534': 36.2929,
    '910_535': 36.3557,
    '910_536': 36.4282,
    '910_537': 36.4832,
    '910_538': 36.5275,
    '910_539': 36.5642,
    '910_540': 36.5894,
    '910_541': 36.6038,
    '910_542': 36.6085,
    '910_543': 36.6018,
    '910_544': 36.5998,
    '910_545': 36.5985,
    '910_546': 36.5998,
    '910_547': 36.6042,
    '910_548': 36.6056,
    '910_549': 36.6071,
    '910_550': 36.6296,
    '910_551': 36.6616,
    '910_552': 36.6862,
    '910_553': 36.7064,
    '910_554': 36.716,
    '910_555': 36.7155,
    '910_556': 36.7134,
    '910_557': 36.7384,
    '910_558': 36.7607,
    '910_559': 36.7828,
    '910_560': 36.8017,
    '910_561': 36.8049,
    '910_562': 36.8086,
    '910_563': 36.8356,
    '910_564': 36.8934,
    '910_565': 36.9476,
    '910_566': 36.993,
    '910_567': 37.0264,
    '910_568': 37.0505,
    '910_569': 37.0691,
    '910_570': 37.0977,
    '910_571': 37.1394,
    '910_572': 37.1783,
    '910_573': 37.2197,
    '910_574': 37.2828,
    '910_575': 37.3532,
    '910_576': 37.4106,
    '910_577': 37.4302,
    '910_578': 37.4458,
    '910_579': 37.4777,
    '910_580': 37.4896,
    '910_581': 37.4756,
    '910_582': 37.4667,
    '910_583': 37.4869,
    '910_584': 37.4923,
    '910_585': 37.4888,
    '910_586': 37.5169,
    '910_587': 37.5434,
    '910_588': 37.5463,
    '910_589': 37.5237,
    '910_590': 37.4748,
    '910_591': 37.4223,
    '910_592': 37.3879,
    '910_593': 37.3839,
    '910_594': 37.3812,
    '910_595': 37.373,
    '910_596': 37.3464,
    '910_597': 37.3006,
    '910_598': 37.2738,
    '910_599': 37.2297,
    '910_600': 37.198,
    '910_601': 37.1831,
    '910_602': 37.1797,
    '910_603': 37.1895,
    '910_604': 37.2013,
    '910_605': 37.2137,
    '910_606': 37.2465,
    '910_607': 37.278,
    '910_608': 37.3034,
    '910_609': 37.3198,
    '910_610': 37.3268,
    '910_611': 37.3353,
    '910_612': 37.352,
    '910_613': 37.3601,
    '910_614': 37.3667,
    '910_615': 37.3654,
    '910_616': 37.3608,
    '910_617': 37.3635,
    '910_618': 37.3751,
    '910_619': 37.3867,
    '910_620': 37.3982,
    '910_621': 37.4184,
    '910_622': 37.4452,
    '910_623': 37.4612,
    '910_624': 37.4637,
    '910_625': 37.4708,
    '910_626': 37.488,
    '910_627': 37.5163,
    '910_628': 37.5365,
    '910_629': 37.5392,
    '910_630': 37.5204,
    '910_631': 37.4802,
    '910_632': 37.4215,
    '910_633': 37.3407,
    '910_634': 37.2357,
    '910_635': 37.1097,
    '910_636': 36.9746,
    '910_637': 36.8533,
    '910_638': 36.7652,
    '910_639': 36.7102,
    '910_640': 36.6814,
    '910_641': 36.6758,
    '910_642': 36.6858,
    '910_643': 36.7023,
    '910_644': 36.7047,
    '910_645': 36.7158,
    '910_646': 36.7423,
    '910_647': 36.776,
    '910_648': 36.8112,
    '910_649': 36.8635,
    '910_650': 36.9369,
    '910_651': 37.0405,
    '910_652': 37.1682,
    '910_653': 37.2779,
    '910_654': 37.3721,
    '910_655': 37.4647,
    '910_656': 37.5267,
    '910_657': 37.5411,
    '910_658': 37.5026,
    '910_659': 37.42,
    '910_660': 37.3495,
    '910_661': 37.3393,
    '910_662': 37.3646,
    '910_663': 37.3825,
    '910_664': 37.3576,
    '910_665': 37.2797,
    '910_666': 37.2219,
    '910_667': 37.2213,
    '910_668': 37.2552,
    '910_669': 37.311,
    '910_670': 37.3813,
    '910_671': 37.4636,
    '910_672': 37.5548,
    '910_673': 37.6525,
    '910_674': 37.7577,
    '910_675': 37.8753,
    '910_676': 38.0048,
    '910_677': 38.1352,
    '910_678': 38.2689,
    '910_679': 38.4074,
    '910_680': 38.5483,
    '910_681': 38.6946,
    '910_682': 38.8448,
    '910_683': 38.9995,
    '910_684': 39.1582,
    '910_685': 39.3187,
    '910_686': 39.4797,
    '910_687': 39.6298,
    '910_688': 39.7821,
    '910_689': 39.9395,
    '910_690': 40.1046,
    '910_691': 40.2755,
    '910_692': 40.458,
    '910_693': 40.6484,
    '910_694': 40.8381,
    '910_695': 41.0246,
    '910_696': 41.2104,
    '910_697': 41.3875,
    '910_698': 41.5451,
    '910_699': 41.686,
    '910_700': 41.8052,
    '910_701': 41.9079,
    '910_702': 42.0077,
    '910_703': 42.101,
    '910_704': 42.1819,
    '910_705': 42.2517,
    '910_706': 42.3195,
    '910_707': 42.3812,
    '910_708': 42.4348,
    '910_709': 42.4776,
    '910_710': 42.5092,
    '910_711': 42.5319,
    '910_712': 42.5417,
    '910_713': 42.5638,
    '910_714': 42.6194,
    '910_715': 42.6807,
    '910_716': 42.7683,
    '910_717': 42.8496,
    '910_718': 42.9275,
    '910_719': 43.0071,
    '910_720': 43.0496,
    '910_721': 43.0752,
    '910_722': 43.0438,
    '910_723': 42.989,
    '910_724': 42.9058,
    '910_725': 42.8164,
    '910_726': 42.7378,
    '910_727': 42.6774,
    '910_728': 42.6159,
    '910_729': 42.5331,
    '910_730': 42.4237,
    '910_731': 42.2843,
    '910_732': 42.1262,
    '910_733': 41.9741,
    '910_734': 41.8451,
    '910_735': 41.7215,
    '910_736': 41.6053,
    '910_737': 41.4499,
    '910_738': 41.3016,
    '910_739': 41.1629,
    '910_740': 41.0288,
    '910_741': 40.8963,
    '910_742': 40.7973,
    '910_743': 40.7185,
    '910_744': 40.6477,
    '910_745': 40.5873,
    '910_746': 40.5624,
    '910_747': 40.5717,
    '910_748': 40.6007,
    '910_749': 40.6379,
    '910_750': 40.6835,
    '910_751': 40.7293,
    '910_752': 40.7627,
    '910_753': 40.7701,
    '910_754': 40.7538,
    '910_755': 40.7168,
    '910_756': 40.6794,
    '910_757': 40.6556,
    '910_758': 40.6493,
    '910_759': 40.6436,
    '910_760': 40.6116,
    '910_761': 40.5317,
    '910_762': 40.3981,
    '910_763': 40.1884,
    '910_764': 39.9239,
    '910_765': 39.6199,
    '910_766': 39.2934,
    '910_767': 38.9704,
    '910_768': 38.656,
    '910_769': 38.3704,
    '910_770': 38.1097,
    '910_771': 37.8844,
    '910_772': 37.6914,
    '910_773': 37.5225,
    '910_774': 37.3772,
    '910_775': 37.2577,
    '910_776': 37.1507,
    '910_777': 37.0545,
    '910_778': 36.9741,
    '910_779': 36.9148,
    '910_780': 36.8681,
    '910_781': 36.8343,
    '910_782': 36.7998,
    '910_783': 36.7509,
    '910_784': 36.6896,
    '910_785': 36.6113,
    '910_786': 36.5145,
    '910_787': 36.4035,
    '910_788': 36.2897,
    '910_789': 36.1789,
    '910_790': 36.0768,
    '910_791': 35.9886,
    '910_792': 35.9193,
    '910_793': 35.8616,
    '910_794': 35.7987,
    '910_795': 35.7229,
    '910_796': 35.634,
    '910_797': 35.5348,
    '910_798': 35.4236,
    '910_799': 35.2996,
    '910_800': 35.1663,
    '910_801': 35.0234,
    '910_802': 34.8705,
    '910_803': 34.7162,
    '910_804': 34.5667,
    '910_805': 34.4147,
    '910_806': 34.2562,
    '910_807': 34.0834,
    '910_808': 33.9018,
    '910_809': 33.7157,
    '910_810': 33.5224,
    '910_811': 33.3219,
    '910_812': 33.118,
    '910_813': 32.9051,
    '910_814': 32.6818,
    '910_815': 32.4552,
    '910_816': 32.2234,
    '910_817': 31.9852,
    '910_818': 31.7357,
    '910_819': 31.4886,
    '910_820': 31.2295,
    '910_821': 30.9669,
    '910_822': 30.6959,
    '910_823': 30.4294,
    '910_824': 30.1442,
    '910_825': 29.8575,
    '910_826': 29.557,
    '910_827': 29.2381,
    '910_828': 28.9074,
    '911_472': 32.3091,
    '911_473': 32.3585,
    '911_474': 32.4039,
    '911_475': 32.4457,
    '911_476': 32.4849,
    '911_477': 32.5307,
    '911_478': 32.5765,
    '911_479': 32.6233,
    '911_480': 32.6697,
    '911_481': 32.7149,
    '911_482': 32.7601,
    '911_483': 32.8077,
    '911_484': 32.8609,
    '911_485': 32.9237,
    '911_486': 32.9924,
    '911_487': 33.0641,
    '911_488': 33.1379,
    '911_489': 33.2116,
    '911_490': 33.2793,
    '911_491': 33.3435,
    '911_492': 33.4077,
    '911_493': 33.4744,
    '911_494': 33.5458,
    '911_495': 33.6208,
    '911_496': 33.7001,
    '911_497': 33.7857,
    '911_498': 33.8762,
    '911_499': 33.9677,
    '911_500': 34.074,
    '911_501': 34.1888,
    '911_502': 34.2986,
    '911_503': 34.4057,
    '911_504': 34.5119,
    '911_505': 34.6166,
    '911_506': 34.7039,
    '911_507': 34.7846,
    '911_508': 34.874,
    '911_509': 34.9655,
    '911_510': 35.0546,
    '911_511': 35.1275,
    '911_512': 35.1893,
    '911_513': 35.2425,
    '911_514': 35.303,
    '911_515': 35.3577,
    '911_516': 35.4001,
    '911_517': 35.4408,
    '911_518': 35.4923,
    '911_519': 35.54,
    '911_520': 35.5935,
    '911_521': 35.6485,
    '911_522': 35.7083,
    '911_523': 35.7732,
    '911_524': 35.8395,
    '911_525': 35.9078,
    '911_526': 35.9804,
    '911_527': 36.0488,
    '911_528': 36.0973,
    '911_529': 36.121,
    '911_530': 36.1372,
    '911_531': 36.1537,
    '911_532': 36.1703,
    '911_533': 36.1971,
    '911_534': 36.2426,
    '911_535': 36.3036,
    '911_536': 36.3786,
    '911_537': 36.4397,
    '911_538': 36.4904,
    '911_539': 36.5352,
    '911_540': 36.5697,
    '911_541': 36.5921,
    '911_542': 36.6013,
    '911_543': 36.5987,
    '911_544': 36.6037,
    '911_545': 36.6006,
    '911_546': 36.5922,
    '911_547': 36.5875,
    '911_548': 36.5918,
    '911_549': 36.6053,
    '911_550': 36.6436,
    '911_551': 36.6883,
    '911_552': 36.7223,
    '911_553': 36.7516,
    '911_554': 36.7634,
    '911_555': 36.7583,
    '911_556': 36.7548,
    '911_557': 36.7773,
    '911_558': 36.8115,
    '911_559': 36.8281,
    '911_560': 36.8359,
    '911_561': 36.8294,
    '911_562': 36.8334,
    '911_563': 36.8625,
    '911_564': 36.9151,
    '911_565': 36.9632,
    '911_566': 37.0066,
    '911_567': 37.0367,
    '911_568': 37.0545,
    '911_569': 37.0742,
    '911_570': 37.1089,
    '911_571': 37.1593,
    '911_572': 37.2079,
    '911_573': 37.2564,
    '911_574': 37.3261,
    '911_575': 37.402,
    '911_576': 37.4544,
    '911_577': 37.473,
    '911_578': 37.4876,
    '911_579': 37.5148,
    '911_580': 37.5218,
    '911_581': 37.5052,
    '911_582': 37.5032,
    '911_583': 37.5173,
    '911_584': 37.5122,
    '911_585': 37.4973,
    '911_586': 37.519,
    '911_587': 37.5508,
    '911_588': 37.5578,
    '911_589': 37.5372,
    '911_590': 37.4868,
    '911_591': 37.4234,
    '911_592': 37.3742,
    '911_593': 37.3643,
    '911_594': 37.3653,
    '911_595': 37.3664,
    '911_596': 37.347,
    '911_597': 37.3063,
    '911_598': 37.2763,
    '911_599': 37.2339,
    '911_600': 37.2014,
    '911_601': 37.1876,
    '911_602': 37.178,
    '911_603': 37.1852,
    '911_604': 37.1854,
    '911_605': 37.1905,
    '911_606': 37.2185,
    '911_607': 37.2485,
    '911_608': 37.2715,
    '911_609': 37.2846,
    '911_610': 37.2908,
    '911_611': 37.301,
    '911_612': 37.3182,
    '911_613': 37.3307,
    '911_614': 37.3392,
    '911_615': 37.3406,
    '911_616': 37.3409,
    '911_617': 37.3445,
    '911_618': 37.3604,
    '911_619': 37.3767,
    '911_620': 37.3946,
    '911_621': 37.4136,
    '911_622': 37.4363,
    '911_623': 37.453,
    '911_624': 37.4572,
    '911_625': 37.463,
    '911_626': 37.4754,
    '911_627': 37.4958,
    '911_628': 37.5195,
    '911_629': 37.5232,
    '911_630': 37.5111,
    '911_631': 37.482,
    '911_632': 37.433,
    '911_633': 37.3629,
    '911_634': 37.2688,
    '911_635': 37.1548,
    '911_636': 37.0073,
    '911_637': 36.8581,
    '911_638': 36.7549,
    '911_639': 36.6883,
    '911_640': 36.6508,
    '911_641': 36.6387,
    '911_642': 36.6456,
    '911_643': 36.6591,
    '911_644': 36.6625,
    '911_645': 36.6676,
    '911_646': 36.6904,
    '911_647': 36.727,
    '911_648': 36.7691,
    '911_649': 36.8274,
    '911_650': 36.9033,
    '911_651': 37.0054,
    '911_652': 37.1247,
    '911_653': 37.2392,
    '911_654': 37.3497,
    '911_655': 37.4469,
    '911_656': 37.5079,
    '911_657': 37.5197,
    '911_658': 37.4716,
    '911_659': 37.39,
    '911_660': 37.3477,
    '911_661': 37.3545,
    '911_662': 37.3848,
    '911_663': 37.3924,
    '911_664': 37.3512,
    '911_665': 37.2641,
    '911_666': 37.2186,
    '911_667': 37.2261,
    '911_668': 37.2651,
    '911_669': 37.3237,
    '911_670': 37.3967,
    '911_671': 37.4812,
    '911_672': 37.5742,
    '911_673': 37.6745,
    '911_674': 37.7825,
    '911_675': 37.9,
    '911_676': 38.0264,
    '911_677': 38.1548,
    '911_678': 38.2853,
    '911_679': 38.4204,
    '911_680': 38.5599,
    '911_681': 38.7062,
    '911_682': 38.8578,
    '911_683': 39.0136,
    '911_684': 39.1753,
    '911_685': 39.343,
    '911_686': 39.5118,
    '911_687': 39.665,
    '911_688': 39.8061,
    '911_689': 39.9584,
    '911_690': 40.1199,
    '911_691': 40.2859,
    '911_692': 40.4654,
    '911_693': 40.6504,
    '911_694': 40.8377,
    '911_695': 41.0193,
    '911_696': 41.1991,
    '911_697': 41.3757,
    '911_698': 41.5366,
    '911_699': 41.6836,
    '911_700': 41.8127,
    '911_701': 41.9301,
    '911_702': 42.0504,
    '911_703': 42.1601,
    '911_704': 42.2534,
    '911_705': 42.3328,
    '911_706': 42.4069,
    '911_707': 42.4719,
    '911_708': 42.5253,
    '911_709': 42.5683,
    '911_710': 42.5993,
    '911_711': 42.6216,
    '911_712': 42.6269,
    '911_713': 42.6492,
    '911_714': 42.707,
    '911_715': 42.772,
    '911_716': 42.8485,
    '911_717': 42.9262,
    '911_718': 43.0158,
    '911_719': 43.0984,
    '911_720': 43.1609,
    '911_721': 43.1785,
    '911_722': 43.1639,
    '911_723': 43.1041,
    '911_724': 43.041,
    '911_725': 42.9629,
    '911_726': 42.887,
    '911_727': 42.8127,
    '911_728': 42.7378,
    '911_729': 42.6547,
    '911_730': 42.5476,
    '911_731': 42.4169,
    '911_732': 42.2623,
    '911_733': 42.111,
    '911_734': 41.9707,
    '911_735': 41.845,
    '911_736': 41.7227,
    '911_737': 41.5712,
    '911_738': 41.4113,
    '911_739': 41.2669,
    '911_740': 41.1224,
    '911_741': 40.9917,
    '911_742': 40.89,
    '911_743': 40.8119,
    '911_744': 40.748,
    '911_745': 40.7082,
    '911_746': 40.6947,
    '911_747': 40.7152,
    '911_748': 40.7553,
    '911_749': 40.8022,
    '911_750': 40.8549,
    '911_751': 40.9016,
    '911_752': 40.9271,
    '911_753': 40.9178,
    '911_754': 40.8761,
    '911_755': 40.817,
    '911_756': 40.7646,
    '911_757': 40.7355,
    '911_758': 40.7292,
    '911_759': 40.7216,
    '911_760': 40.6796,
    '911_761': 40.5905,
    '911_762': 40.446,
    '911_763': 40.2422,
    '911_764': 39.9762,
    '911_765': 39.6602,
    '911_766': 39.315,
    '911_767': 38.9877,
    '911_768': 38.6752,
    '911_769': 38.3863,
    '911_770': 38.1318,
    '911_771': 37.9071,
    '911_772': 37.7111,
    '911_773': 37.539,
    '911_774': 37.3908,
    '911_775': 37.2701,
    '911_776': 37.1641,
    '911_777': 37.0765,
    '911_778': 37.0005,
    '911_779': 36.9464,
    '911_780': 36.8973,
    '911_781': 36.8527,
    '911_782': 36.8085,
    '911_783': 36.7519,
    '911_784': 36.6833,
    '911_785': 36.601,
    '911_786': 36.5031,
    '911_787': 36.3949,
    '911_788': 36.2836,
    '911_789': 36.1719,
    '911_790': 36.0693,
    '911_791': 35.9722,
    '911_792': 35.8923,
    '911_793': 35.8235,
    '911_794': 35.7506,
    '911_795': 35.6661,
    '911_796': 35.5744,
    '911_797': 35.4742,
    '911_798': 35.3624,
    '911_799': 35.2443,
    '911_800': 35.1158,
    '911_801': 34.9762,
    '911_802': 34.8316,
    '911_803': 34.6843,
    '911_804': 34.5421,
    '911_805': 34.3949,
    '911_806': 34.2408,
    '911_807': 34.0788,
    '911_808': 33.9044,
    '911_809': 33.7221,
    '911_810': 33.5306,
    '911_811': 33.3341,
    '911_812': 33.1349,
    '911_813': 32.9265,
    '911_814': 32.7074,
    '911_815': 32.4827,
    '911_816': 32.253,
    '911_817': 32.0205,
    '911_818': 31.7813,
    '911_819': 31.529,
    '911_820': 31.2798,
    '911_821': 31.0245,
    '911_822': 30.7603,
    '911_823': 30.4918,
    '911_824': 30.2194,
    '911_825': 29.9405,
    '911_826': 29.6455,
    '911_827': 29.3324,
    '911_828': 29.0059,
    '912_472': 32.2648,
    '912_473': 32.3241,
    '912_474': 32.3818,
    '912_475': 32.4352,
    '912_476': 32.4808,
    '912_477': 32.5227,
    '912_478': 32.5671,
    '912_479': 32.6097,
    '912_480': 32.6492,
    '912_481': 32.6896,
    '912_482': 32.7316,
    '912_483': 32.7776,
    '912_484': 32.8304,
    '912_485': 32.8901,
    '912_486': 32.9551,
    '912_487': 33.0232,
    '912_488': 33.0957,
    '912_489': 33.1676,
    '912_490': 33.2359,
    '912_491': 33.301,
    '912_492': 33.3648,
    '912_493': 33.4296,
    '912_494': 33.498,
    '912_495': 33.5724,
    '912_496': 33.6504,
    '912_497': 33.7331,
    '912_498': 33.8208,
    '912_499': 33.9152,
    '912_500': 34.0208,
    '912_501': 34.1385,
    '912_502': 34.2522,
    '912_503': 34.3576,
    '912_504': 34.465,
    '912_505': 34.5697,
    '912_506': 34.6565,
    '912_507': 34.7356,
    '912_508': 34.8216,
    '912_509': 34.9086,
    '912_510': 34.9992,
    '912_511': 35.0777,
    '912_512': 35.1353,
    '912_513': 35.1877,
    '912_514': 35.2506,
    '912_515': 35.3059,
    '912_516': 35.3496,
    '912_517': 35.3914,
    '912_518': 35.445,
    '912_519': 35.4976,
    '912_520': 35.5477,
    '912_521': 35.6048,
    '912_522': 35.6655,
    '912_523': 35.7306,
    '912_524': 35.8009,
    '912_525': 35.8674,
    '912_526': 35.9318,
    '912_527': 35.9923,
    '912_528': 36.0365,
    '912_529': 36.0648,
    '912_530': 36.0863,
    '912_531': 36.1065,
    '912_532': 36.129,
    '912_533': 36.1577,
    '912_534': 36.1992,
    '912_535': 36.2506,
    '912_536': 36.3161,
    '912_537': 36.3761,
    '912_538': 36.4322,
    '912_539': 36.4889,
    '912_540': 36.5382,
    '912_541': 36.5705,
    '912_542': 36.587,
    '912_543': 36.5956,
    '912_544': 36.6047,
    '912_545': 36.5983,
    '912_546': 36.583,
    '912_547': 36.5719,
    '912_548': 36.578,
    '912_549': 36.6001,
    '912_550': 36.6481,
    '912_551': 36.7031,
    '912_552': 36.7482,
    '912_553': 36.7848,
    '912_554': 36.7998,
    '912_555': 36.7933,
    '912_556': 36.7882,
    '912_557': 36.8109,
    '912_558': 36.8494,
    '912_559': 36.8703,
    '912_560': 36.8709,
    '912_561': 36.862,
    '912_562': 36.8665,
    '912_563': 36.8884,
    '912_564': 36.9263,
    '912_565': 36.9632,
    '912_566': 36.9972,
    '912_567': 37.0172,
    '912_568': 37.0285,
    '912_569': 37.0562,
    '912_570': 37.1065,
    '912_571': 37.1663,
    '912_572': 37.2255,
    '912_573': 37.2875,
    '912_574': 37.3637,
    '912_575': 37.4401,
    '912_576': 37.4857,
    '912_577': 37.5084,
    '912_578': 37.5225,
    '912_579': 37.5439,
    '912_580': 37.5449,
    '912_581': 37.5314,
    '912_582': 37.5375,
    '912_583': 37.5406,
    '912_584': 37.5247,
    '912_585': 37.5023,
    '912_586': 37.5133,
    '912_587': 37.5419,
    '912_588': 37.5525,
    '912_589': 37.5291,
    '912_590': 37.4735,
    '912_591': 37.4047,
    '912_592': 37.3513,
    '912_593': 37.3454,
    '912_594': 37.3464,
    '912_595': 37.3515,
    '912_596': 37.3402,
    '912_597': 37.3078,
    '912_598': 37.2782,
    '912_599': 37.2458,
    '912_600': 37.217,
    '912_601': 37.1922,
    '912_602': 37.18,
    '912_603': 37.1891,
    '912_604': 37.1749,
    '912_605': 37.171,
    '912_606': 37.1866,
    '912_607': 37.2133,
    '912_608': 37.2336,
    '912_609': 37.2454,
    '912_610': 37.257,
    '912_611': 37.2766,
    '912_612': 37.2997,
    '912_613': 37.3174,
    '912_614': 37.3251,
    '912_615': 37.3275,
    '912_616': 37.3286,
    '912_617': 37.3337,
    '912_618': 37.3518,
    '912_619': 37.376,
    '912_620': 37.4023,
    '912_621': 37.4158,
    '912_622': 37.4306,
    '912_623': 37.4443,
    '912_624': 37.4521,
    '912_625': 37.4604,
    '912_626': 37.4694,
    '912_627': 37.4836,
    '912_628': 37.4957,
    '912_629': 37.4988,
    '912_630': 37.4907,
    '912_631': 37.469,
    '912_632': 37.4325,
    '912_633': 37.3757,
    '912_634': 37.2945,
    '912_635': 37.1911,
    '912_636': 37.0526,
    '912_637': 36.8809,
    '912_638': 36.7554,
    '912_639': 36.6725,
    '912_640': 36.6226,
    '912_641': 36.6019,
    '912_642': 36.6074,
    '912_643': 36.6138,
    '912_644': 36.6186,
    '912_645': 36.6242,
    '912_646': 36.647,
    '912_647': 36.6848,
    '912_648': 36.7328,
    '912_649': 36.7975,
    '912_650': 36.8775,
    '912_651': 36.9764,
    '912_652': 37.0902,
    '912_653': 37.2108,
    '912_654': 37.3286,
    '912_655': 37.4223,
    '912_656': 37.4796,
    '912_657': 37.4861,
    '912_658': 37.4325,
    '912_659': 37.3729,
    '912_660': 37.3542,
    '912_661': 37.3693,
    '912_662': 37.3884,
    '912_663': 37.3759,
    '912_664': 37.3203,
    '912_665': 37.2432,
    '912_666': 37.2167,
    '912_667': 37.2341,
    '912_668': 37.2778,
    '912_669': 37.3391,
    '912_670': 37.414,
    '912_671': 37.5,
    '912_672': 37.5947,
    '912_673': 37.6975,
    '912_674': 37.8069,
    '912_675': 37.9244,
    '912_676': 38.0481,
    '912_677': 38.1736,
    '912_678': 38.3021,
    '912_679': 38.4348,
    '912_680': 38.5744,
    '912_681': 38.7196,
    '912_682': 38.87,
    '912_683': 39.0264,
    '912_684': 39.1911,
    '912_685': 39.3647,
    '912_686': 39.5423,
    '912_687': 39.7013,
    '912_688': 39.8345,
    '912_689': 39.9817,
    '912_690': 40.1402,
    '912_691': 40.3061,
    '912_692': 40.4782,
    '912_693': 40.658,
    '912_694': 40.841,
    '912_695': 41.0156,
    '912_696': 41.1865,
    '912_697': 41.3581,
    '912_698': 41.522,
    '912_699': 41.6754,
    '912_700': 41.8116,
    '912_701': 41.942,
    '912_702': 42.0787,
    '912_703': 42.2042,
    '912_704': 42.3106,
    '912_705': 42.4009,
    '912_706': 42.4841,
    '912_707': 42.5529,
    '912_708': 42.6061,
    '912_709': 42.6491,
    '912_710': 42.6826,
    '912_711': 42.704,
    '912_712': 42.7121,
    '912_713': 42.7267,
    '912_714': 42.7893,
    '912_715': 42.8646,
    '912_716': 42.9345,
    '912_717': 43.0084,
    '912_718': 43.1013,
    '912_719': 43.1859,
    '912_720': 43.2674,
    '912_721': 43.2926,
    '912_722': 43.2904,
    '912_723': 43.2408,
    '912_724': 43.1758,
    '912_725': 43.1106,
    '912_726': 43.0432,
    '912_727': 42.9621,
    '912_728': 42.8689,
    '912_729': 42.7765,
    '912_730': 42.6695,
    '912_731': 42.5424,
    '912_732': 42.4006,
    '912_733': 42.2442,
    '912_734': 42.093,
    '912_735': 41.9672,
    '912_736': 41.8331,
    '912_737': 41.6759,
    '912_738': 41.5,
    '912_739': 41.3495,
    '912_740': 41.2048,
    '912_741': 41.0851,
    '912_742': 40.9882,
    '912_743': 40.9153,
    '912_744': 40.8619,
    '912_745': 40.8353,
    '912_746': 40.8376,
    '912_747': 40.8721,
    '912_748': 40.9233,
    '912_749': 40.9774,
    '912_750': 41.033,
    '912_751': 41.0809,
    '912_752': 41.0942,
    '912_753': 41.0646,
    '912_754': 40.9979,
    '912_755': 40.9199,
    '912_756': 40.8543,
    '912_757': 40.8195,
    '912_758': 40.812,
    '912_759': 40.7986,
    '912_760': 40.7477,
    '912_761': 40.6485,
    '912_762': 40.4948,
    '912_763': 40.2796,
    '912_764': 40.0152,
    '912_765': 39.6956,
    '912_766': 39.3508,
    '912_767': 39.0224,
    '912_768': 38.7145,
    '912_769': 38.4278,
    '912_770': 38.1721,
    '912_771': 37.9439,
    '912_772': 37.7447,
    '912_773': 37.5699,
    '912_774': 37.4183,
    '912_775': 37.294,
    '912_776': 37.1919,
    '912_777': 37.1081,
    '912_778': 37.0376,
    '912_779': 36.9805,
    '912_780': 36.9335,
    '912_781': 36.8879,
    '912_782': 36.8271,
    '912_783': 36.759,
    '912_784': 36.683,
    '912_785': 36.5966,
    '912_786': 36.5001,
    '912_787': 36.3941,
    '912_788': 36.283,
    '912_789': 36.1675,
    '912_790': 36.0568,
    '912_791': 35.9503,
    '912_792': 35.8579,
    '912_793': 35.7752,
    '912_794': 35.6932,
    '912_795': 35.6024,
    '912_796': 35.5053,
    '912_797': 35.4016,
    '912_798': 35.292,
    '912_799': 35.1783,
    '912_800': 35.0537,
    '912_801': 34.9171,
    '912_802': 34.777,
    '912_803': 34.6331,
    '912_804': 34.4946,
    '912_805': 34.353,
    '912_806': 34.2057,
    '912_807': 34.0505,
    '912_808': 33.8855,
    '912_809': 33.7093,
    '912_810': 33.5226,
    '912_811': 33.3333,
    '912_812': 33.1399,
    '912_813': 32.9357,
    '912_814': 32.7219,
    '912_815': 32.5037,
    '912_816': 32.2755,
    '912_817': 32.0447,
    '912_818': 31.8121,
    '912_819': 31.5714,
    '912_820': 31.3281,
    '912_821': 31.0767,
    '912_822': 30.8053,
    '912_823': 30.5476,
    '912_824': 30.2888,
    '912_825': 30.0227,
    '912_826': 29.7406,
    '912_827': 29.4272,
    '912_828': 29.1037,
    '913_472': 32.2225,
    '913_473': 32.2908,
    '913_474': 32.3589,
    '913_475': 32.4217,
    '913_476': 32.4734,
    '913_477': 32.5193,
    '913_478': 32.5614,
    '913_479': 32.5992,
    '913_480': 32.6336,
    '913_481': 32.6688,
    '913_482': 32.7076,
    '913_483': 32.7525,
    '913_484': 32.8035,
    '913_485': 32.861,
    '913_486': 32.9245,
    '913_487': 32.9908,
    '913_488': 33.0612,
    '913_489': 33.1316,
    '913_490': 33.1997,
    '913_491': 33.265,
    '913_492': 33.3287,
    '913_493': 33.3926,
    '913_494': 33.4588,
    '913_495': 33.5309,
    '913_496': 33.6093,
    '913_497': 33.6899,
    '913_498': 33.7749,
    '913_499': 33.8665,
    '913_500': 33.9666,
    '913_501': 34.0787,
    '913_502': 34.1948,
    '913_503': 34.3002,
    '913_504': 34.411,
    '913_505': 34.5219,
    '913_506': 34.6058,
    '913_507': 34.6808,
    '913_508': 34.7654,
    '913_509': 34.8432,
    '913_510': 34.9351,
    '913_511': 35.0194,
    '913_512': 35.0774,
    '913_513': 35.1313,
    '913_514': 35.1933,
    '913_515': 35.2482,
    '913_516': 35.2946,
    '913_517': 35.3437,
    '913_518': 35.3972,
    '913_519': 35.4549,
    '913_520': 35.5121,
    '913_521': 35.5729,
    '913_522': 35.6287,
    '913_523': 35.6895,
    '913_524': 35.757,
    '913_525': 35.8174,
    '913_526': 35.8777,
    '913_527': 35.9342,
    '913_528': 35.9756,
    '913_529': 36.0074,
    '913_530': 36.0326,
    '913_531': 36.0575,
    '913_532': 36.0854,
    '913_533': 36.1191,
    '913_534': 36.1635,
    '913_535': 36.2081,
    '913_536': 36.2539,
    '913_537': 36.3085,
    '913_538': 36.3715,
    '913_539': 36.4406,
    '913_540': 36.5011,
    '913_541': 36.5438,
    '913_542': 36.5705,
    '913_543': 36.5866,
    '913_544': 36.5939,
    '913_545': 36.5839,
    '913_546': 36.5632,
    '913_547': 36.5502,
    '913_548': 36.5574,
    '913_549': 36.5849,
    '913_550': 36.6386,
    '913_551': 36.7022,
    '913_552': 36.7562,
    '913_553': 36.7983,
    '913_554': 36.8186,
    '913_555': 36.8119,
    '913_556': 36.8096,
    '913_557': 36.8311,
    '913_558': 36.8724,
    '913_559': 36.8982,
    '913_560': 36.9037,
    '913_561': 36.8986,
    '913_562': 36.9011,
    '913_563': 36.9206,
    '913_564': 36.9418,
    '913_565': 36.9636,
    '913_566': 36.9865,
    '913_567': 36.995,
    '913_568': 37.0019,
    '913_569': 37.0292,
    '913_570': 37.0919,
    '913_571': 37.1586,
    '913_572': 37.2279,
    '913_573': 37.3052,
    '913_574': 37.391,
    '913_575': 37.4687,
    '913_576': 37.5107,
    '913_577': 37.5367,
    '913_578': 37.5541,
    '913_579': 37.5687,
    '913_580': 37.5655,
    '913_581': 37.553,
    '913_582': 37.5587,
    '913_583': 37.5585,
    '913_584': 37.5364,
    '913_585': 37.5127,
    '913_586': 37.5073,
    '913_587': 37.5249,
    '913_588': 37.5286,
    '913_589': 37.4974,
    '913_590': 37.4364,
    '913_591': 37.3685,
    '913_592': 37.322,
    '913_593': 37.3166,
    '913_594': 37.3176,
    '913_595': 37.3308,
    '913_596': 37.3272,
    '913_597': 37.3006,
    '913_598': 37.2723,
    '913_599': 37.2516,
    '913_600': 37.2219,
    '913_601': 37.1909,
    '913_602': 37.1836,
    '913_603': 37.1889,
    '913_604': 37.1673,
    '913_605': 37.1544,
    '913_606': 37.1642,
    '913_607': 37.1848,
    '913_608': 37.1987,
    '913_609': 37.2112,
    '913_610': 37.2341,
    '913_611': 37.2631,
    '913_612': 37.2865,
    '913_613': 37.3088,
    '913_614': 37.3163,
    '913_615': 37.3205,
    '913_616': 37.3211,
    '913_617': 37.33,
    '913_618': 37.3561,
    '913_619': 37.3821,
    '913_620': 37.4114,
    '913_621': 37.4237,
    '913_622': 37.4292,
    '913_623': 37.4405,
    '913_624': 37.4525,
    '913_625': 37.464,
    '913_626': 37.4712,
    '913_627': 37.4837,
    '913_628': 37.4875,
    '913_629': 37.4881,
    '913_630': 37.4784,
    '913_631': 37.4572,
    '913_632': 37.4269,
    '913_633': 37.3794,
    '913_634': 37.3062,
    '913_635': 37.212,
    '913_636': 37.0863,
    '913_637': 36.9156,
    '913_638': 36.7669,
    '913_639': 36.6661,
    '913_640': 36.6024,
    '913_641': 36.5733,
    '913_642': 36.5675,
    '913_643': 36.5688,
    '913_644': 36.5747,
    '913_645': 36.5869,
    '913_646': 36.6125,
    '913_647': 36.6538,
    '913_648': 36.7078,
    '913_649': 36.7758,
    '913_650': 36.8571,
    '913_651': 36.9553,
    '913_652': 37.069,
    '913_653': 37.1969,
    '913_654': 37.3171,
    '913_655': 37.3991,
    '913_656': 37.4465,
    '913_657': 37.451,
    '913_658': 37.4087,
    '913_659': 37.3676,
    '913_660': 37.3719,
    '913_661': 37.3913,
    '913_662': 37.3847,
    '913_663': 37.3403,
    '913_664': 37.276,
    '913_665': 37.2237,
    '913_666': 37.2176,
    '913_667': 37.2444,
    '913_668': 37.2925,
    '913_669': 37.3563,
    '913_670': 37.4334,
    '913_671': 37.5207,
    '913_672': 37.6172,
    '913_673': 37.7216,
    '913_674': 37.8316,
    '913_675': 37.9483,
    '913_676': 38.07,
    '913_677': 38.1929,
    '913_678': 38.3198,
    '913_679': 38.4521,
    '913_680': 38.5931,
    '913_681': 38.7365,
    '913_682': 38.886,
    '913_683': 39.0422,
    '913_684': 39.2077,
    '913_685': 39.3816,
    '913_686': 39.5624,
    '913_687': 39.7296,
    '913_688': 39.8669,
    '913_689': 40.011,
    '913_690': 40.1686,
    '913_691': 40.3336,
    '913_692': 40.4996,
    '913_693': 40.6665,
    '913_694': 40.8391,
    '913_695': 41.0059,
    '913_696': 41.1743,
    '913_697': 41.3383,
    '913_698': 41.5043,
    '913_699': 41.6613,
    '913_700': 41.8038,
    '913_701': 41.9453,
    '913_702': 42.0912,
    '913_703': 42.2283,
    '913_704': 42.3499,
    '913_705': 42.4543,
    '913_706': 42.5436,
    '913_707': 42.6204,
    '913_708': 42.6751,
    '913_709': 42.7173,
    '913_710': 42.7499,
    '913_711': 42.7705,
    '913_712': 42.7837,
    '913_713': 42.7964,
    '913_714': 42.8617,
    '913_715': 42.9472,
    '913_716': 43.0245,
    '913_717': 43.0996,
    '913_718': 43.1924,
    '913_719': 43.2878,
    '913_720': 43.3653,
    '913_721': 43.3983,
    '913_722': 43.3942,
    '913_723': 43.3617,
    '913_724': 43.3097,
    '913_725': 43.2587,
    '913_726': 43.2067,
    '913_727': 43.1187,
    '913_728': 43.0169,
    '913_729': 42.907,
    '913_730': 42.7952,
    '913_731': 42.6702,
    '913_732': 42.532,
    '913_733': 42.3687,
    '913_734': 42.2144,
    '913_735': 42.0867,
    '913_736': 41.9462,
    '913_737': 41.7723,
    '913_738': 41.5799,
    '913_739': 41.4189,
    '913_740': 41.2877,
    '913_741': 41.1804,
    '913_742': 41.0989,
    '913_743': 41.0325,
    '913_744': 40.989,
    '913_745': 40.9733,
    '913_746': 40.9918,
    '913_747': 41.0383,
    '913_748': 41.0985,
    '913_749': 41.1585,
    '913_750': 41.2123,
    '913_751': 41.2492,
    '913_752': 41.2482,
    '913_753': 41.1987,
    '913_754': 41.1137,
    '913_755': 41.0208,
    '913_756': 40.9458,
    '913_757': 40.9044,
    '913_758': 40.8923,
    '913_759': 40.8699,
    '913_760': 40.8133,
    '913_761': 40.7001,
    '913_762': 40.5324,
    '913_763': 40.3131,
    '913_764': 40.0417,
    '913_765': 39.7276,
    '913_766': 39.3928,
    '913_767': 39.073,
    '913_768': 38.7702,
    '913_769': 38.4862,
    '913_770': 38.2283,
    '913_771': 37.9952,
    '913_772': 37.7935,
    '913_773': 37.6174,
    '913_774': 37.4663,
    '913_775': 37.3334,
    '913_776': 37.2359,
    '913_777': 37.1524,
    '913_778': 37.0835,
    '913_779': 37.0231,
    '913_780': 36.9683,
    '913_781': 36.9107,
    '913_782': 36.8423,
    '913_783': 36.767,
    '913_784': 36.685,
    '913_785': 36.5973,
    '913_786': 36.5013,
    '913_787': 36.394,
    '913_788': 36.2772,
    '913_789': 36.1537,
    '913_790': 36.0343,
    '913_791': 35.9196,
    '913_792': 35.8156,
    '913_793': 35.7212,
    '913_794': 35.6297,
    '913_795': 35.5351,
    '913_796': 35.4353,
    '913_797': 35.3309,
    '913_798': 35.2229,
    '913_799': 35.1095,
    '913_800': 34.9833,
    '913_801': 34.8484,
    '913_802': 34.7118,
    '913_803': 34.5696,
    '913_804': 34.4313,
    '913_805': 34.2949,
    '913_806': 34.1549,
    '913_807': 34.0055,
    '913_808': 33.8486,
    '913_809': 33.6795,
    '913_810': 33.5038,
    '913_811': 33.3205,
    '913_812': 33.1337,
    '913_813': 32.9359,
    '913_814': 32.7277,
    '913_815': 32.5153,
    '913_816': 32.2918,
    '913_817': 32.0606,
    '913_818': 31.8374,
    '913_819': 31.6117,
    '913_820': 31.3729,
    '913_821': 31.1267,
    '913_822': 30.8679,
    '913_823': 30.6098,
    '913_824': 30.3567,
    '913_825': 30.0964,
    '913_826': 29.8208,
    '913_827': 29.5227,
    '913_828': 29.1979,
    '914_472': 32.184,
    '914_473': 32.2578,
    '914_474': 32.3318,
    '914_475': 32.3996,
    '914_476': 32.459,
    '914_477': 32.5105,
    '914_478': 32.5531,
    '914_479': 32.5872,
    '914_480': 32.6183,
    '914_481': 32.6499,
    '914_482': 32.6872,
    '914_483': 32.7297,
    '914_484': 32.778,
    '914_485': 32.8338,
    '914_486': 32.8989,
    '914_487': 32.9652,
    '914_488': 33.0336,
    '914_489': 33.1023,
    '914_490': 33.1697,
    '914_491': 33.2349,
    '914_492': 33.2984,
    '914_493': 33.3614,
    '914_494': 33.4257,
    '914_495': 33.4946,
    '914_496': 33.5735,
    '914_497': 33.653,
    '914_498': 33.7346,
    '914_499': 33.8212,
    '914_500': 33.9139,
    '914_501': 34.0168,
    '914_502': 34.1308,
    '914_503': 34.2411,
    '914_504': 34.3524,
    '914_505': 34.4633,
    '914_506': 34.5531,
    '914_507': 34.6243,
    '914_508': 34.7063,
    '914_509': 34.7796,
    '914_510': 34.8711,
    '914_511': 34.9615,
    '914_512': 35.023,
    '914_513': 35.0714,
    '914_514': 35.1287,
    '914_515': 35.1864,
    '914_516': 35.2383,
    '914_517': 35.2935,
    '914_518': 35.3503,
    '914_519': 35.4146,
    '914_520': 35.4785,
    '914_521': 35.5424,
    '914_522': 35.5988,
    '914_523': 35.6534,
    '914_524': 35.7063,
    '914_525': 35.7538,
    '914_526': 35.8087,
    '914_527': 35.866,
    '914_528': 35.9111,
    '914_529': 35.9446,
    '914_530': 35.9722,
    '914_531': 36.0011,
    '914_532': 36.0351,
    '914_533': 36.0748,
    '914_534': 36.1193,
    '914_535': 36.1621,
    '914_536': 36.1997,
    '914_537': 36.242,
    '914_538': 36.3055,
    '914_539': 36.3827,
    '914_540': 36.4486,
    '914_541': 36.4989,
    '914_542': 36.5352,
    '914_543': 36.5591,
    '914_544': 36.5667,
    '914_545': 36.5563,
    '914_546': 36.5364,
    '914_547': 36.5244,
    '914_548': 36.5328,
    '914_549': 36.563,
    '914_550': 36.6156,
    '914_551': 36.6816,
    '914_552': 36.7431,
    '914_553': 36.792,
    '914_554': 36.8157,
    '914_555': 36.8215,
    '914_556': 36.827,
    '914_557': 36.8435,
    '914_558': 36.8846,
    '914_559': 36.9207,
    '914_560': 36.9349,
    '914_561': 36.9333,
    '914_562': 36.9321,
    '914_563': 36.9476,
    '914_564': 36.9592,
    '914_565': 36.9697,
    '914_566': 36.9836,
    '914_567': 36.9775,
    '914_568': 36.9801,
    '914_569': 37.0007,
    '914_570': 37.0658,
    '914_571': 37.1423,
    '914_572': 37.2211,
    '914_573': 37.318,
    '914_574': 37.411,
    '914_575': 37.4843,
    '914_576': 37.5217,
    '914_577': 37.5496,
    '914_578': 37.5717,
    '914_579': 37.586,
    '914_580': 37.5846,
    '914_581': 37.5702,
    '914_582': 37.5649,
    '914_583': 37.5652,
    '914_584': 37.5422,
    '914_585': 37.5199,
    '914_586': 37.5034,
    '914_587': 37.5051,
    '914_588': 37.4954,
    '914_589': 37.453,
    '914_590': 37.3892,
    '914_591': 37.3227,
    '914_592': 37.2799,
    '914_593': 37.2724,
    '914_594': 37.2785,
    '914_595': 37.3037,
    '914_596': 37.3087,
    '914_597': 37.2843,
    '914_598': 37.2566,
    '914_599': 37.2423,
    '914_600': 37.2113,
    '914_601': 37.1846,
    '914_602': 37.182,
    '914_603': 37.1781,
    '914_604': 37.1517,
    '914_605': 37.1332,
    '914_606': 37.1343,
    '914_607': 37.1481,
    '914_608': 37.166,
    '914_609': 37.1862,
    '914_610': 37.2147,
    '914_611': 37.246,
    '914_612': 37.2767,
    '914_613': 37.3002,
    '914_614': 37.31,
    '914_615': 37.3174,
    '914_616': 37.3181,
    '914_617': 37.3299,
    '914_618': 37.3624,
    '914_619': 37.389,
    '914_620': 37.4117,
    '914_621': 37.4245,
    '914_622': 37.4263,
    '914_623': 37.4328,
    '914_624': 37.4484,
    '914_625': 37.4642,
    '914_626': 37.4742,
    '914_627': 37.4868,
    '914_628': 37.4918,
    '914_629': 37.4896,
    '914_630': 37.4757,
    '914_631': 37.45,
    '914_632': 37.419,
    '914_633': 37.3721,
    '914_634': 37.3041,
    '914_635': 37.2158,
    '914_636': 37.108,
    '914_637': 36.9557,
    '914_638': 36.794,
    '914_639': 36.6734,
    '914_640': 36.5952,
    '914_641': 36.5516,
    '914_642': 36.5345,
    '914_643': 36.5289,
    '914_644': 36.5348,
    '914_645': 36.552,
    '914_646': 36.5809,
    '914_647': 36.6249,
    '914_648': 36.6842,
    '914_649': 36.7519,
    '914_650': 36.8353,
    '914_651': 36.9362,
    '914_652': 37.0542,
    '914_653': 37.1864,
    '914_654': 37.303,
    '914_655': 37.3815,
    '914_656': 37.4207,
    '914_657': 37.4227,
    '914_658': 37.3871,
    '914_659': 37.3692,
    '914_660': 37.3943,
    '914_661': 37.4097,
    '914_662': 37.3751,
    '914_663': 37.2989,
    '914_664': 37.237,
    '914_665': 37.2119,
    '914_666': 37.2218,
    '914_667': 37.2562,
    '914_668': 37.3089,
    '914_669': 37.3753,
    '914_670': 37.4541,
    '914_671': 37.543,
    '914_672': 37.6405,
    '914_673': 37.7455,
    '914_674': 37.8556,
    '914_675': 37.9713,
    '914_676': 38.091,
    '914_677': 38.2119,
    '914_678': 38.3388,
    '914_679': 38.4711,
    '914_680': 38.6106,
    '914_681': 38.7548,
    '914_682': 38.9049,
    '914_683': 39.0607,
    '914_684': 39.2245,
    '914_685': 39.3978,
    '914_686': 39.5812,
    '914_687': 39.7551,
    '914_688': 39.9013,
    '914_689': 40.0465,
    '914_690': 40.2032,
    '914_691': 40.3628,
    '914_692': 40.5244,
    '914_693': 40.6822,
    '914_694': 40.8383,
    '914_695': 40.9938,
    '914_696': 41.1621,
    '914_697': 41.3272,
    '914_698': 41.4884,
    '914_699': 41.646,
    '914_700': 41.7947,
    '914_701': 41.9461,
    '914_702': 42.0977,
    '914_703': 42.2418,
    '914_704': 42.3721,
    '914_705': 42.4909,
    '914_706': 42.5908,
    '914_707': 42.6701,
    '914_708': 42.7257,
    '914_709': 42.7679,
    '914_710': 42.7997,
    '914_711': 42.8217,
    '914_712': 42.8345,
    '914_713': 42.857,
    '914_714': 42.9249,
    '914_715': 43.0203,
    '914_716': 43.1091,
    '914_717': 43.1894,
    '914_718': 43.281,
    '914_719': 43.377,
    '914_720': 43.4581,
    '914_721': 43.4921,
    '914_722': 43.4845,
    '914_723': 43.4777,
    '914_724': 43.4404,
    '914_725': 43.3951,
    '914_726': 43.3574,
    '914_727': 43.2648,
    '914_728': 43.1649,
    '914_729': 43.0348,
    '914_730': 42.9214,
    '914_731': 42.7989,
    '914_732': 42.6584,
    '914_733': 42.4907,
    '914_734': 42.3333,
    '914_735': 42.1991,
    '914_736': 42.0409,
    '914_737': 41.8609,
    '914_738': 41.6623,
    '914_739': 41.4921,
    '914_740': 41.378,
    '914_741': 41.2925,
    '914_742': 41.2198,
    '914_743': 41.1571,
    '914_744': 41.1222,
    '914_745': 41.1225,
    '914_746': 41.157,
    '914_747': 41.2143,
    '914_748': 41.2809,
    '914_749': 41.3423,
    '914_750': 41.3886,
    '914_751': 41.4082,
    '914_752': 41.3878,
    '914_753': 41.3244,
    '914_754': 41.2235,
    '914_755': 41.1195,
    '914_756': 41.0364,
    '914_757': 40.9849,
    '914_758': 40.9629,
    '914_759': 40.933,
    '914_760': 40.8665,
    '914_761': 40.743,
    '914_762': 40.5689,
    '914_763': 40.3365,
    '914_764': 40.0659,
    '914_765': 39.7565,
    '914_766': 39.4377,
    '914_767': 39.1301,
    '914_768': 38.8358,
    '914_769': 38.5568,
    '914_770': 38.3012,
    '914_771': 38.066,
    '914_772': 37.8589,
    '914_773': 37.6766,
    '914_774': 37.525,
    '914_775': 37.395,
    '914_776': 37.3012,
    '914_777': 37.2138,
    '914_778': 37.139,
    '914_779': 37.0723,
    '914_780': 37.0065,
    '914_781': 36.9378,
    '914_782': 36.8603,
    '914_783': 36.7782,
    '914_784': 36.6925,
    '914_785': 36.6006,
    '914_786': 36.4958,
    '914_787': 36.3776,
    '914_788': 36.2544,
    '914_789': 36.1255,
    '914_790': 35.9982,
    '914_791': 35.8777,
    '914_792': 35.7654,
    '914_793': 35.6647,
    '914_794': 35.5685,
    '914_795': 35.4704,
    '914_796': 35.3741,
    '914_797': 35.2692,
    '914_798': 35.1582,
    '914_799': 35.0392,
    '914_800': 34.9098,
    '914_801': 34.7792,
    '914_802': 34.6419,
    '914_803': 34.5023,
    '914_804': 34.3684,
    '914_805': 34.2349,
    '914_806': 34.0975,
    '914_807': 33.9532,
    '914_808': 33.8016,
    '914_809': 33.6383,
    '914_810': 33.4689,
    '914_811': 33.2972,
    '914_812': 33.1168,
    '914_813': 32.9259,
    '914_814': 32.725,
    '914_815': 32.5193,
    '914_816': 32.3,
    '914_817': 32.0672,
    '914_818': 31.8548,
    '914_819': 31.6408,
    '914_820': 31.4149,
    '914_821': 31.171,
    '914_822': 30.9258,
    '914_823': 30.6673,
    '914_824': 30.4188,
    '914_825': 30.162,
    '914_826': 29.8877,
    '914_827': 29.5945,
    '914_828': 29.2816,
    '915_472': 32.1527,
    '915_473': 32.2262,
    '915_474': 32.3025,
    '915_475': 32.3741,
    '915_476': 32.4389,
    '915_477': 32.4923,
    '915_478': 32.535,
    '915_479': 32.5709,
    '915_480': 32.6032,
    '915_481': 32.6344,
    '915_482': 32.6697,
    '915_483': 32.7094,
    '915_484': 32.7548,
    '915_485': 32.8081,
    '915_486': 32.8758,
    '915_487': 32.9431,
    '915_488': 33.0108,
    '915_489': 33.0788,
    '915_490': 33.1457,
    '915_491': 33.2107,
    '915_492': 33.2739,
    '915_493': 33.3364,
    '915_494': 33.3998,
    '915_495': 33.4676,
    '915_496': 33.5422,
    '915_497': 33.6195,
    '915_498': 33.6995,
    '915_499': 33.7821,
    '915_500': 33.8695,
    '915_501': 33.9649,
    '915_502': 34.072,
    '915_503': 34.1807,
    '915_504': 34.2901,
    '915_505': 34.3982,
    '915_506': 34.4902,
    '915_507': 34.57,
    '915_508': 34.6449,
    '915_509': 34.7236,
    '915_510': 34.8153,
    '915_511': 34.9008,
    '915_512': 34.9663,
    '915_513': 35.0124,
    '915_514': 35.0643,
    '915_515': 35.1193,
    '915_516': 35.1754,
    '915_517': 35.2378,
    '915_518': 35.299,
    '915_519': 35.3667,
    '915_520': 35.4373,
    '915_521': 35.5046,
    '915_522': 35.5644,
    '915_523': 35.6171,
    '915_524': 35.6596,
    '915_525': 35.6943,
    '915_526': 35.7355,
    '915_527': 35.7905,
    '915_528': 35.8405,
    '915_529': 35.8753,
    '915_530': 35.906,
    '915_531': 35.9408,
    '915_532': 35.9791,
    '915_533': 36.0199,
    '915_534': 36.0616,
    '915_535': 36.1036,
    '915_536': 36.139,
    '915_537': 36.184,
    '915_538': 36.2367,
    '915_539': 36.306,
    '915_540': 36.3734,
    '915_541': 36.433,
    '915_542': 36.4799,
    '915_543': 36.5113,
    '915_544': 36.5207,
    '915_545': 36.5129,
    '915_546': 36.4999,
    '915_547': 36.492,
    '915_548': 36.5032,
    '915_549': 36.531,
    '915_550': 36.5783,
    '915_551': 36.6404,
    '915_552': 36.7065,
    '915_553': 36.7637,
    '915_554': 36.8017,
    '915_555': 36.8224,
    '915_556': 36.8343,
    '915_557': 36.8499,
    '915_558': 36.8906,
    '915_559': 36.9322,
    '915_560': 36.9538,
    '915_561': 36.9577,
    '915_562': 36.955,
    '915_563': 36.9663,
    '915_564': 36.9709,
    '915_565': 36.9724,
    '915_566': 36.9742,
    '915_567': 36.9581,
    '915_568': 36.9537,
    '915_569': 36.973,
    '915_570': 37.038,
    '915_571': 37.1197,
    '915_572': 37.212,
    '915_573': 37.3195,
    '915_574': 37.4125,
    '915_575': 37.4818,
    '915_576': 37.5252,
    '915_577': 37.5531,
    '915_578': 37.5774,
    '915_579': 37.5957,
    '915_580': 37.5944,
    '915_581': 37.583,
    '915_582': 37.5679,
    '915_583': 37.5643,
    '915_584': 37.5445,
    '915_585': 37.5203,
    '915_586': 37.4924,
    '915_587': 37.4818,
    '915_588': 37.4631,
    '915_589': 37.4089,
    '915_590': 37.3466,
    '915_591': 37.2882,
    '915_592': 37.2408,
    '915_593': 37.2291,
    '915_594': 37.2433,
    '915_595': 37.2718,
    '915_596': 37.2831,
    '915_597': 37.2694,
    '915_598': 37.2417,
    '915_599': 37.2213,
    '915_600': 37.1959,
    '915_601': 37.181,
    '915_602': 37.174,
    '915_603': 37.1605,
    '915_604': 37.1407,
    '915_605': 37.1126,
    '915_606': 37.1033,
    '915_607': 37.1143,
    '915_608': 37.1374,
    '915_609': 37.1642,
    '915_610': 37.198,
    '915_611': 37.2262,
    '915_612': 37.2572,
    '915_613': 37.2828,
    '915_614': 37.2949,
    '915_615': 37.3065,
    '915_616': 37.3077,
    '915_617': 37.3216,
    '915_618': 37.3566,
    '915_619': 37.3841,
    '915_620': 37.3999,
    '915_621': 37.4138,
    '915_622': 37.4136,
    '915_623': 37.4216,
    '915_624': 37.4411,
    '915_625': 37.459,
    '915_626': 37.4737,
    '915_627': 37.486,
    '915_628': 37.4915,
    '915_629': 37.4899,
    '915_630': 37.4726,
    '915_631': 37.4446,
    '915_632': 37.4078,
    '915_633': 37.3546,
    '915_634': 37.2868,
    '915_635': 37.2054,
    '915_636': 37.115,
    '915_637': 36.9858,
    '915_638': 36.8335,
    '915_639': 36.6961,
    '915_640': 36.5979,
    '915_641': 36.5347,
    '915_642': 36.5054,
    '915_643': 36.4943,
    '915_644': 36.5004,
    '915_645': 36.5205,
    '915_646': 36.5514,
    '915_647': 36.5961,
    '915_648': 36.6564,
    '915_649': 36.7265,
    '915_650': 36.8151,
    '915_651': 36.9204,
    '915_652': 37.0449,
    '915_653': 37.1747,
    '915_654': 37.2864,
    '915_655': 37.3684,
    '915_656': 37.4073,
    '915_657': 37.4057,
    '915_658': 37.3701,
    '915_659': 37.3695,
    '915_660': 37.406,
    '915_661': 37.4114,
    '915_662': 37.3547,
    '915_663': 37.2622,
    '915_664': 37.2164,
    '915_665': 37.2108,
    '915_666': 37.2321,
    '915_667': 37.2728,
    '915_668': 37.3288,
    '915_669': 37.397,
    '915_670': 37.4766,
    '915_671': 37.5662,
    '915_672': 37.6643,
    '915_673': 37.7694,
    '915_674': 37.8793,
    '915_675': 37.9945,
    '915_676': 38.113,
    '915_677': 38.2346,
    '915_678': 38.3616,
    '915_679': 38.4933,
    '915_680': 38.6312,
    '915_681': 38.7753,
    '915_682': 38.9264,
    '915_683': 39.0813,
    '915_684': 39.2427,
    '915_685': 39.413,
    '915_686': 39.5955,
    '915_687': 39.7757,
    '915_688': 39.9302,
    '915_689': 40.0811,
    '915_690': 40.2365,
    '915_691': 40.3915,
    '915_692': 40.5447,
    '915_693': 40.6952,
    '915_694': 40.8421,
    '915_695': 40.9925,
    '915_696': 41.1604,
    '915_697': 41.33,
    '915_698': 41.4862,
    '915_699': 41.6338,
    '915_700': 41.7877,
    '915_701': 41.9469,
    '915_702': 42.1039,
    '915_703': 42.2547,
    '915_704': 42.3937,
    '915_705': 42.5224,
    '915_706': 42.6319,
    '915_707': 42.7091,
    '915_708': 42.7663,
    '915_709': 42.8042,
    '915_710': 42.8334,
    '915_711': 42.8526,
    '915_712': 42.8683,
    '915_713': 42.895,
    '915_714': 42.9708,
    '915_715': 43.0738,
    '915_716': 43.1702,
    '915_717': 43.2645,
    '915_718': 43.3658,
    '915_719': 43.4493,
    '915_720': 43.5422,
    '915_721': 43.5973,
    '915_722': 43.6077,
    '915_723': 43.5884,
    '915_724': 43.5688,
    '915_725': 43.5386,
    '915_726': 43.4728,
    '915_727': 43.397,
    '915_728': 43.2988,
    '915_729': 43.1644,
    '915_730': 43.0475,
    '915_731': 42.9229,
    '915_732': 42.7748,
    '915_733': 42.6091,
    '915_734': 42.4511,
    '915_735': 42.2964,
    '915_736': 42.1274,
    '915_737': 41.9323,
    '915_738': 41.7384,
    '915_739': 41.5818,
    '915_740': 41.4934,
    '915_741': 41.4197,
    '915_742': 41.3461,
    '915_743': 41.2908,
    '915_744': 41.2658,
    '915_745': 41.2812,
    '915_746': 41.3296,
    '915_747': 41.3979,
    '915_748': 41.4702,
    '915_749': 41.5301,
    '915_750': 41.566,
    '915_751': 41.5664,
    '915_752': 41.524,
    '915_753': 41.4416,
    '915_754': 41.3288,
    '915_755': 41.2174,
    '915_756': 41.1256,
    '915_757': 41.0623,
    '915_758': 41.027,
    '915_759': 40.9859,
    '915_760': 40.9101,
    '915_761': 40.7868,
    '915_762': 40.6049,
    '915_763': 40.3724,
    '915_764': 40.0977,
    '915_765': 39.7928,
    '915_766': 39.4876,
    '915_767': 39.1939,
    '915_768': 38.913,
    '915_769': 38.6451,
    '915_770': 38.3934,
    '915_771': 38.1585,
    '915_772': 37.9451,
    '915_773': 37.7572,
    '915_774': 37.6065,
    '915_775': 37.4812,
    '915_776': 37.3734,
    '915_777': 37.2774,
    '915_778': 37.1944,
    '915_779': 37.1182,
    '915_780': 37.0413,
    '915_781': 36.9601,
    '915_782': 36.8756,
    '915_783': 36.7896,
    '915_784': 36.7026,
    '915_785': 36.6019,
    '915_786': 36.479,
    '915_787': 36.3499,
    '915_788': 36.2183,
    '915_789': 36.0881,
    '915_790': 35.9581,
    '915_791': 35.834,
    '915_792': 35.7126,
    '915_793': 35.6081,
    '915_794': 35.5076,
    '915_795': 35.4074,
    '915_796': 35.3092,
    '915_797': 35.2078,
    '915_798': 35.0963,
    '915_799': 34.9736,
    '915_800': 34.841,
    '915_801': 34.7137,
    '915_802': 34.5747,
    '915_803': 34.4393,
    '915_804': 34.31,
    '915_805': 34.1765,
    '915_806': 34.0395,
    '915_807': 33.9002,
    '915_808': 33.7522,
    '915_809': 33.5936,
    '915_810': 33.4298,
    '915_811': 33.2636,
    '915_812': 33.0898,
    '915_813': 32.9071,
    '915_814': 32.7156,
    '915_815': 32.5182,
    '915_816': 32.3078,
    '915_817': 32.0873,
    '915_818': 31.8777,
    '915_819': 31.6681,
    '915_820': 31.4496,
    '915_821': 31.2171,
    '915_822': 30.977,
    '915_823': 30.7332,
    '915_824': 30.4858,
    '915_825': 30.2285,
    '915_826': 29.9503,
    '915_827': 29.6576,
    '915_828': 29.3507,
    '916_472': 32.1259,
    '916_473': 32.1952,
    '916_474': 32.2704,
    '916_475': 32.3458,
    '916_476': 32.416,
    '916_477': 32.4694,
    '916_478': 32.5133,
    '916_479': 32.5525,
    '916_480': 32.5884,
    '916_481': 32.6209,
    '916_482': 32.6547,
    '916_483': 32.6926,
    '916_484': 32.7375,
    '916_485': 32.7932,
    '916_486': 32.8579,
    '916_487': 32.9249,
    '916_488': 32.9926,
    '916_489': 33.0604,
    '916_490': 33.1272,
    '916_491': 33.1921,
    '916_492': 33.2549,
    '916_493': 33.316,
    '916_494': 33.3773,
    '916_495': 33.4423,
    '916_496': 33.513,
    '916_497': 33.5887,
    '916_498': 33.6681,
    '916_499': 33.7488,
    '916_500': 33.8317,
    '916_501': 33.9204,
    '916_502': 34.0165,
    '916_503': 34.1186,
    '916_504': 34.2228,
    '916_505': 34.3236,
    '916_506': 34.4185,
    '916_507': 34.5069,
    '916_508': 34.5875,
    '916_509': 34.6671,
    '916_510': 34.7544,
    '916_511': 34.834,
    '916_512': 34.9003,
    '916_513': 34.9525,
    '916_514': 35.0032,
    '916_515': 35.0556,
    '916_516': 35.1086,
    '916_517': 35.171,
    '916_518': 35.236,
    '916_519': 35.309,
    '916_520': 35.3835,
    '916_521': 35.4508,
    '916_522': 35.5111,
    '916_523': 35.5639,
    '916_524': 35.6046,
    '916_525': 35.6318,
    '916_526': 35.6665,
    '916_527': 35.7188,
    '916_528': 35.7715,
    '916_529': 35.8036,
    '916_530': 35.8301,
    '916_531': 35.8683,
    '916_532': 35.91,
    '916_533': 35.9481,
    '916_534': 35.9887,
    '916_535': 36.0321,
    '916_536': 36.0661,
    '916_537': 36.1168,
    '916_538': 36.1744,
    '916_539': 36.2226,
    '916_540': 36.2839,
    '916_541': 36.3486,
    '916_542': 36.4079,
    '916_543': 36.4484,
    '916_544': 36.4592,
    '916_545': 36.4555,
    '916_546': 36.4482,
    '916_547': 36.445,
    '916_548': 36.4631,
    '916_549': 36.4887,
    '916_550': 36.5316,
    '916_551': 36.5864,
    '916_552': 36.6508,
    '916_553': 36.7197,
    '916_554': 36.7729,
    '916_555': 36.8071,
    '916_556': 36.8263,
    '916_557': 36.8437,
    '916_558': 36.8838,
    '916_559': 36.9271,
    '916_560': 36.9558,
    '916_561': 36.9643,
    '916_562': 36.9651,
    '916_563': 36.9703,
    '916_564': 36.9694,
    '916_565': 36.9634,
    '916_566': 36.9542,
    '916_567': 36.936,
    '916_568': 36.9311,
    '916_569': 36.9478,
    '916_570': 37.0159,
    '916_571': 37.0938,
    '916_572': 37.19,
    '916_573': 37.3017,
    '916_574': 37.4013,
    '916_575': 37.4659,
    '916_576': 37.5021,
    '916_577': 37.5237,
    '916_578': 37.5521,
    '916_579': 37.582,
    '916_580': 37.5908,
    '916_581': 37.5859,
    '916_582': 37.5691,
    '916_583': 37.5573,
    '916_584': 37.538,
    '916_585': 37.509,
    '916_586': 37.4716,
    '916_587': 37.4488,
    '916_588': 37.4277,
    '916_589': 37.3698,
    '916_590': 37.3066,
    '916_591': 37.2546,
    '916_592': 37.211,
    '916_593': 37.1912,
    '916_594': 37.2107,
    '916_595': 37.2294,
    '916_596': 37.2516,
    '916_597': 37.2612,
    '916_598': 37.2427,
    '916_599': 37.215,
    '916_600': 37.1904,
    '916_601': 37.1736,
    '916_602': 37.1564,
    '916_603': 37.1374,
    '916_604': 37.12,
    '916_605': 37.0934,
    '916_606': 37.0822,
    '916_607': 37.0918,
    '916_608': 37.1197,
    '916_609': 37.1494,
    '916_610': 37.179,
    '916_611': 37.2084,
    '916_612': 37.2358,
    '916_613': 37.2598,
    '916_614': 37.2707,
    '916_615': 37.2857,
    '916_616': 37.2988,
    '916_617': 37.3243,
    '916_618': 37.3523,
    '916_619': 37.3745,
    '916_620': 37.3843,
    '916_621': 37.3938,
    '916_622': 37.3933,
    '916_623': 37.4044,
    '916_624': 37.4253,
    '916_625': 37.4492,
    '916_626': 37.4659,
    '916_627': 37.4771,
    '916_628': 37.4798,
    '916_629': 37.4807,
    '916_630': 37.4651,
    '916_631': 37.4375,
    '916_632': 37.3939,
    '916_633': 37.332,
    '916_634': 37.2631,
    '916_635': 37.1869,
    '916_636': 37.1059,
    '916_637': 36.99,
    '916_638': 36.8561,
    '916_639': 36.7286,
    '916_640': 36.6131,
    '916_641': 36.5322,
    '916_642': 36.4879,
    '916_643': 36.468,
    '916_644': 36.4723,
    '916_645': 36.4933,
    '916_646': 36.5259,
    '916_647': 36.5718,
    '916_648': 36.6314,
    '916_649': 36.7038,
    '916_650': 36.7961,
    '916_651': 36.9053,
    '916_652': 37.0324,
    '916_653': 37.1668,
    '916_654': 37.2742,
    '916_655': 37.361,
    '916_656': 37.4019,
    '916_657': 37.3951,
    '916_658': 37.3593,
    '916_659': 37.3672,
    '916_660': 37.4062,
    '916_661': 37.4039,
    '916_662': 37.3275,
    '916_663': 37.2433,
    '916_664': 37.2134,
    '916_665': 37.2194,
    '916_666': 37.2488,
    '916_667': 37.2949,
    '916_668': 37.3532,
    '916_669': 37.4221,
    '916_670': 37.5016,
    '916_671': 37.5908,
    '916_672': 37.689,
    '916_673': 37.7939,
    '916_674': 37.9041,
    '916_675': 38.0189,
    '916_676': 38.1371,
    '916_677': 38.2582,
    '916_678': 38.3861,
    '916_679': 38.5189,
    '916_680': 38.6565,
    '916_681': 38.7997,
    '916_682': 38.9507,
    '916_683': 39.105,
    '916_684': 39.2671,
    '916_685': 39.4301,
    '916_686': 39.6047,
    '916_687': 39.782,
    '916_688': 39.9426,
    '916_689': 40.0989,
    '916_690': 40.2579,
    '916_691': 40.4148,
    '916_692': 40.561,
    '916_693': 40.7035,
    '916_694': 40.8488,
    '916_695': 41.002,
    '916_696': 41.165,
    '916_697': 41.3337,
    '916_698': 41.4909,
    '916_699': 41.6345,
    '916_700': 41.7894,
    '916_701': 41.9541,
    '916_702': 42.1219,
    '916_703': 42.2799,
    '916_704': 42.4238,
    '916_705': 42.5514,
    '916_706': 42.661,
    '916_707': 42.7415,
    '916_708': 42.7958,
    '916_709': 42.8313,
    '916_710': 42.8566,
    '916_711': 42.8745,
    '916_712': 42.8921,
    '916_713': 42.9223,
    '916_714': 42.9941,
    '916_715': 43.1076,
    '916_716': 43.2104,
    '916_717': 43.317,
    '916_718': 43.4249,
    '916_719': 43.5388,
    '916_720': 43.6182,
    '916_721': 43.6891,
    '916_722': 43.715,
    '916_723': 43.7066,
    '916_724': 43.6936,
    '916_725': 43.6626,
    '916_726': 43.6053,
    '916_727': 43.5095,
    '916_728': 43.4047,
    '916_729': 43.2911,
    '916_730': 43.1719,
    '916_731': 43.0437,
    '916_732': 42.9027,
    '916_733': 42.7299,
    '916_734': 42.5637,
    '916_735': 42.3914,
    '916_736': 42.1996,
    '916_737': 41.9933,
    '916_738': 41.8074,
    '916_739': 41.6837,
    '916_740': 41.6177,
    '916_741': 41.5496,
    '916_742': 41.4839,
    '916_743': 41.4333,
    '916_744': 41.4201,
    '916_745': 41.4467,
    '916_746': 41.5063,
    '916_747': 41.5844,
    '916_748': 41.6624,
    '916_749': 41.7218,
    '916_750': 41.7478,
    '916_751': 41.7294,
    '916_752': 41.6624,
    '916_753': 41.5596,
    '916_754': 41.4381,
    '916_755': 41.3181,
    '916_756': 41.2143,
    '916_757': 41.1372,
    '916_758': 41.0851,
    '916_759': 41.0319,
    '916_760': 40.9517,
    '916_761': 40.8237,
    '916_762': 40.6417,
    '916_763': 40.4113,
    '916_764': 40.1355,
    '916_765': 39.8367,
    '916_766': 39.5435,
    '916_767': 39.2642,
    '916_768': 38.9987,
    '916_769': 38.7433,
    '916_770': 38.4977,
    '916_771': 38.2638,
    '916_772': 38.047,
    '916_773': 37.8541,
    '916_774': 37.6971,
    '916_775': 37.5639,
    '916_776': 37.4461,
    '916_777': 37.3409,
    '916_778': 37.2471,
    '916_779': 37.1595,
    '916_780': 37.0695,
    '916_781': 36.9777,
    '916_782': 36.8856,
    '916_783': 36.794,
    '916_784': 36.6979,
    '916_785': 36.5865,
    '916_786': 36.4532,
    '916_787': 36.3132,
    '916_788': 36.1741,
    '916_789': 36.0427,
    '916_790': 35.9146,
    '916_791': 35.7904,
    '916_792': 35.6715,
    '916_793': 35.5607,
    '916_794': 35.4548,
    '916_795': 35.3505,
    '916_796': 35.2459,
    '916_797': 35.1412,
    '916_798': 35.0291,
    '916_799': 34.9059,
    '916_800': 34.776,
    '916_801': 34.6536,
    '916_802': 34.5124,
    '916_803': 34.3774,
    '916_804': 34.2501,
    '916_805': 34.1196,
    '916_806': 33.9861,
    '916_807': 33.8515,
    '916_808': 33.7072,
    '916_809': 33.5522,
    '916_810': 33.3916,
    '916_811': 33.2299,
    '916_812': 33.0613,
    '916_813': 32.8865,
    '916_814': 32.7041,
    '916_815': 32.5158,
    '916_816': 32.3139,
    '916_817': 32.1034,
    '916_818': 31.9009,
    '916_819': 31.6966,
    '916_820': 31.4848,
    '916_821': 31.2618,
    '916_822': 31.0248,
    '916_823': 30.7904,
    '916_824': 30.5494,
    '916_825': 30.2936,
    '916_826': 30.0102,
    '916_827': 29.7191,
    '916_828': 29.4151,
    '917_472': 32.102,
    '917_473': 32.1689,
    '917_474': 32.2395,
    '917_475': 32.3124,
    '917_476': 32.3838,
    '917_477': 32.4391,
    '917_478': 32.4863,
    '917_479': 32.5301,
    '917_480': 32.5709,
    '917_481': 32.6056,
    '917_482': 32.6398,
    '917_483': 32.6767,
    '917_484': 32.7205,
    '917_485': 32.778,
    '917_486': 32.8428,
    '917_487': 32.9099,
    '917_488': 32.9777,
    '917_489': 33.0456,
    '917_490': 33.1124,
    '917_491': 33.1773,
    '917_492': 33.2393,
    '917_493': 33.2978,
    '917_494': 33.3554,
    '917_495': 33.4167,
    '917_496': 33.484,
    '917_497': 33.5595,
    '917_498': 33.6394,
    '917_499': 33.7178,
    '917_500': 33.7975,
    '917_501': 33.8796,
    '917_502': 33.966,
    '917_503': 34.0557,
    '917_504': 34.1477,
    '917_505': 34.2396,
    '917_506': 34.3341,
    '917_507': 34.4289,
    '917_508': 34.5196,
    '917_509': 34.6033,
    '917_510': 34.6897,
    '917_511': 34.7639,
    '917_512': 34.8307,
    '917_513': 34.8949,
    '917_514': 34.9487,
    '917_515': 34.9947,
    '917_516': 35.0463,
    '917_517': 35.1067,
    '917_518': 35.1719,
    '917_519': 35.2455,
    '917_520': 35.3238,
    '917_521': 35.3916,
    '917_522': 35.4534,
    '917_523': 35.5045,
    '917_524': 35.542,
    '917_525': 35.5677,
    '917_526': 35.5945,
    '917_527': 35.641,
    '917_528': 35.6897,
    '917_529': 35.7247,
    '917_530': 35.7462,
    '917_531': 35.7851,
    '917_532': 35.8267,
    '917_533': 35.8581,
    '917_534': 35.8994,
    '917_535': 35.9445,
    '917_536': 35.9847,
    '917_537': 36.0375,
    '917_538': 36.1029,
    '917_539': 36.1473,
    '917_540': 36.2051,
    '917_541': 36.2758,
    '917_542': 36.3384,
    '917_543': 36.3816,
    '917_544': 36.3954,
    '917_545': 36.3966,
    '917_546': 36.3957,
    '917_547': 36.3971,
    '917_548': 36.4126,
    '917_549': 36.4369,
    '917_550': 36.4769,
    '917_551': 36.5256,
    '917_552': 36.5808,
    '917_553': 36.6488,
    '917_554': 36.7172,
    '917_555': 36.7646,
    '917_556': 36.7947,
    '917_557': 36.8162,
    '917_558': 36.8569,
    '917_559': 36.9026,
    '917_560': 36.9356,
    '917_561': 36.9492,
    '917_562': 36.9528,
    '917_563': 36.9525,
    '917_564': 36.9457,
    '917_565': 36.9295,
    '917_566': 36.9128,
    '917_567': 36.8946,
    '917_568': 36.8979,
    '917_569': 36.9193,
    '917_570': 36.9912,
    '917_571': 37.0677,
    '917_572': 37.1563,
    '917_573': 37.268,
    '917_574': 37.3616,
    '917_575': 37.4255,
    '917_576': 37.459,
    '917_577': 37.4763,
    '917_578': 37.5115,
    '917_579': 37.5531,
    '917_580': 37.5817,
    '917_581': 37.5783,
    '917_582': 37.5609,
    '917_583': 37.54,
    '917_584': 37.513,
    '917_585': 37.4796,
    '917_586': 37.4346,
    '917_587': 37.4013,
    '917_588': 37.3813,
    '917_589': 37.3241,
    '917_590': 37.2605,
    '917_591': 37.2231,
    '917_592': 37.1896,
    '917_593': 37.1572,
    '917_594': 37.1652,
    '917_595': 37.1747,
    '917_596': 37.2033,
    '917_597': 37.2323,
    '917_598': 37.2306,
    '917_599': 37.2074,
    '917_600': 37.1895,
    '917_601': 37.1626,
    '917_602': 37.1357,
    '917_603': 37.1081,
    '917_604': 37.0872,
    '917_605': 37.0705,
    '917_606': 37.0664,
    '917_607': 37.0806,
    '917_608': 37.1089,
    '917_609': 37.1323,
    '917_610': 37.1545,
    '917_611': 37.1814,
    '917_612': 37.2081,
    '917_613': 37.2361,
    '917_614': 37.2629,
    '917_615': 37.288,
    '917_616': 37.3092,
    '917_617': 37.3375,
    '917_618': 37.3672,
    '917_619': 37.3906,
    '917_620': 37.3911,
    '917_621': 37.3905,
    '917_622': 37.3884,
    '917_623': 37.399,
    '917_624': 37.4118,
    '917_625': 37.4322,
    '917_626': 37.4474,
    '917_627': 37.4593,
    '917_628': 37.4595,
    '917_629': 37.462,
    '917_630': 37.4516,
    '917_631': 37.426,
    '917_632': 37.3786,
    '917_633': 37.3158,
    '917_634': 37.2423,
    '917_635': 37.1638,
    '917_636': 37.0812,
    '917_637': 36.9701,
    '917_638': 36.8475,
    '917_639': 36.7353,
    '917_640': 36.6231,
    '917_641': 36.5301,
    '917_642': 36.4786,
    '917_643': 36.4517,
    '917_644': 36.4501,
    '917_645': 36.4729,
    '917_646': 36.5057,
    '917_647': 36.5539,
    '917_648': 36.6141,
    '917_649': 36.6896,
    '917_650': 36.7827,
    '917_651': 36.8906,
    '917_652': 37.0152,
    '917_653': 37.1475,
    '917_654': 37.2533,
    '917_655': 37.3438,
    '917_656': 37.3893,
    '917_657': 37.3877,
    '917_658': 37.3588,
    '917_659': 37.3606,
    '917_660': 37.3983,
    '917_661': 37.3922,
    '917_662': 37.3064,
    '917_663': 37.2396,
    '917_664': 37.2217,
    '917_665': 37.2353,
    '917_666': 37.2698,
    '917_667': 37.3197,
    '917_668': 37.3801,
    '917_669': 37.4495,
    '917_670': 37.529,
    '917_671': 37.6178,
    '917_672': 37.7148,
    '917_673': 37.8195,
    '917_674': 37.9303,
    '917_675': 38.0449,
    '917_676': 38.163,
    '917_677': 38.2846,
    '917_678': 38.4126,
    '917_679': 38.5473,
    '917_680': 38.6854,
    '917_681': 38.8285,
    '917_682': 38.9791,
    '917_683': 39.1332,
    '917_684': 39.286,
    '917_685': 39.4424,
    '917_686': 39.6066,
    '917_687': 39.7751,
    '917_688': 39.937,
    '917_689': 40.0994,
    '917_690': 40.2612,
    '917_691': 40.4207,
    '917_692': 40.568,
    '917_693': 40.713,
    '917_694': 40.8636,
    '917_695': 41.0158,
    '917_696': 41.1732,
    '917_697': 41.3386,
    '917_698': 41.4984,
    '917_699': 41.6461,
    '917_700': 41.8041,
    '917_701': 41.9729,
    '917_702': 42.1468,
    '917_703': 42.309,
    '917_704': 42.4571,
    '917_705': 42.5833,
    '917_706': 42.6908,
    '917_707': 42.7701,
    '917_708': 42.8242,
    '917_709': 42.8559,
    '917_710': 42.8758,
    '917_711': 42.8924,
    '917_712': 42.9106,
    '917_713': 42.9434,
    '917_714': 43.0053,
    '917_715': 43.1182,
    '917_716': 43.2313,
    '917_717': 43.3445,
    '917_718': 43.4611,
    '917_719': 43.582,
    '917_720': 43.6946,
    '917_721': 43.7557,
    '917_722': 43.8001,
    '917_723': 43.8184,
    '917_724': 43.8059,
    '917_725': 43.774,
    '917_726': 43.7034,
    '917_727': 43.6372,
    '917_728': 43.5127,
    '917_729': 43.4041,
    '917_730': 43.2931,
    '917_731': 43.1698,
    '917_732': 43.0233,
    '917_733': 42.8509,
    '917_734': 42.6707,
    '917_735': 42.4756,
    '917_736': 42.2657,
    '917_737': 42.0457,
    '917_738': 41.8793,
    '917_739': 41.7973,
    '917_740': 41.7515,
    '917_741': 41.6902,
    '917_742': 41.6332,
    '917_743': 41.587,
    '917_744': 41.5808,
    '917_745': 41.6163,
    '917_746': 41.6864,
    '917_747': 41.7729,
    '917_748': 41.8558,
    '917_749': 41.9146,
    '917_750': 41.9314,
    '917_751': 41.8946,
    '917_752': 41.8092,
    '917_753': 41.6885,
    '917_754': 41.5536,
    '917_755': 41.4223,
    '917_756': 41.305,
    '917_757': 41.2096,
    '917_758': 41.1363,
    '917_759': 41.072,
    '917_760': 40.9822,
    '917_761': 40.8493,
    '917_762': 40.6659,
    '917_763': 40.44,
    '917_764': 40.1696,
    '917_765': 39.8813,
    '917_766': 39.6037,
    '917_767': 39.34,
    '917_768': 39.0903,
    '917_769': 38.8494,
    '917_770': 38.6086,
    '917_771': 38.3757,
    '917_772': 38.1572,
    '917_773': 37.9606,
    '917_774': 37.7921,
    '917_775': 37.6465,
    '917_776': 37.5173,
    '917_777': 37.4006,
    '917_778': 37.2945,
    '917_779': 37.1937,
    '917_780': 37.0921,
    '917_781': 36.9892,
    '917_782': 36.8861,
    '917_783': 36.7837,
    '917_784': 36.6739,
    '917_785': 36.5496,
    '917_786': 36.4102,
    '917_787': 36.2699,
    '917_788': 36.132,
    '917_789': 36,
    '917_790': 35.8733,
    '917_791': 35.7507,
    '917_792': 35.6327,
    '917_793': 35.5172,
    '917_794': 35.4054,
    '917_795': 35.2961,
    '917_796': 35.1866,
    '917_797': 35.0746,
    '917_798': 34.9585,
    '917_799': 34.837,
    '917_800': 34.7135,
    '917_801': 34.5919,
    '917_802': 34.4521,
    '917_803': 34.3165,
    '917_804': 34.1921,
    '917_805': 34.0645,
    '917_806': 33.9354,
    '917_807': 33.8061,
    '917_808': 33.6656,
    '917_809': 33.5128,
    '917_810': 33.3546,
    '917_811': 33.1962,
    '917_812': 33.0337,
    '917_813': 32.8661,
    '917_814': 32.693,
    '917_815': 32.5118,
    '917_816': 32.3204,
    '917_817': 32.1225,
    '917_818': 31.9257,
    '917_819': 31.7262,
    '917_820': 31.5206,
    '917_821': 31.3061,
    '917_822': 31.0795,
    '917_823': 30.8382,
    '917_824': 30.6007,
    '917_825': 30.3381,
    '917_826': 30.0655,
    '917_827': 29.7837,
    '917_828': 29.4854,
    '918_472': 32.078,
    '918_473': 32.1406,
    '918_474': 32.2055,
    '918_475': 32.2717,
    '918_476': 32.3397,
    '918_477': 32.3998,
    '918_478': 32.4539,
    '918_479': 32.5029,
    '918_480': 32.5482,
    '918_481': 32.5884,
    '918_482': 32.6261,
    '918_483': 32.6646,
    '918_484': 32.7098,
    '918_485': 32.7671,
    '918_486': 32.8313,
    '918_487': 32.8983,
    '918_488': 32.9659,
    '918_489': 33.0336,
    '918_490': 33.1001,
    '918_491': 33.1645,
    '918_492': 33.2254,
    '918_493': 33.2794,
    '918_494': 33.3332,
    '918_495': 33.3909,
    '918_496': 33.4552,
    '918_497': 33.5307,
    '918_498': 33.6115,
    '918_499': 33.6884,
    '918_500': 33.765,
    '918_501': 33.8432,
    '918_502': 33.9233,
    '918_503': 34.0042,
    '918_504': 34.0867,
    '918_505': 34.1699,
    '918_506': 34.2559,
    '918_507': 34.3461,
    '918_508': 34.4433,
    '918_509': 34.5378,
    '918_510': 34.6233,
    '918_511': 34.6983,
    '918_512': 34.7679,
    '918_513': 34.8374,
    '918_514': 34.8964,
    '918_515': 34.9397,
    '918_516': 34.9865,
    '918_517': 35.0461,
    '918_518': 35.1108,
    '918_519': 35.1816,
    '918_520': 35.2601,
    '918_521': 35.3301,
    '918_522': 35.3954,
    '918_523': 35.4438,
    '918_524': 35.4722,
    '918_525': 35.4994,
    '918_526': 35.5215,
    '918_527': 35.5602,
    '918_528': 35.6024,
    '918_529': 35.6402,
    '918_530': 35.6619,
    '918_531': 35.6978,
    '918_532': 35.7364,
    '918_533': 35.7669,
    '918_534': 35.8073,
    '918_535': 35.8571,
    '918_536': 35.9034,
    '918_537': 35.9577,
    '918_538': 36.0247,
    '918_539': 36.0762,
    '918_540': 36.1448,
    '918_541': 36.2147,
    '918_542': 36.2806,
    '918_543': 36.3247,
    '918_544': 36.3445,
    '918_545': 36.3485,
    '918_546': 36.3519,
    '918_547': 36.3577,
    '918_548': 36.3659,
    '918_549': 36.3819,
    '918_550': 36.4163,
    '918_551': 36.4566,
    '918_552': 36.5025,
    '918_553': 36.5616,
    '918_554': 36.6331,
    '918_555': 36.6933,
    '918_556': 36.7371,
    '918_557': 36.7744,
    '918_558': 36.815,
    '918_559': 36.8586,
    '918_560': 36.8934,
    '918_561': 36.9092,
    '918_562': 36.9127,
    '918_563': 36.9083,
    '918_564': 36.8953,
    '918_565': 36.8716,
    '918_566': 36.8476,
    '918_567': 36.8274,
    '918_568': 36.8373,
    '918_569': 36.8812,
    '918_570': 36.9502,
    '918_571': 37.0272,
    '918_572': 37.1119,
    '918_573': 37.2148,
    '918_574': 37.3038,
    '918_575': 37.3678,
    '918_576': 37.402,
    '918_577': 37.4246,
    '918_578': 37.4704,
    '918_579': 37.527,
    '918_580': 37.5647,
    '918_581': 37.5674,
    '918_582': 37.5488,
    '918_583': 37.5182,
    '918_584': 37.4797,
    '918_585': 37.4412,
    '918_586': 37.3923,
    '918_587': 37.3457,
    '918_588': 37.3247,
    '918_589': 37.2771,
    '918_590': 37.2196,
    '918_591': 37.2,
    '918_592': 37.1664,
    '918_593': 37.1274,
    '918_594': 37.1191,
    '918_595': 37.1273,
    '918_596': 37.1526,
    '918_597': 37.1898,
    '918_598': 37.2036,
    '918_599': 37.1878,
    '918_600': 37.1731,
    '918_601': 37.1514,
    '918_602': 37.128,
    '918_603': 37.096,
    '918_604': 37.0703,
    '918_605': 37.0591,
    '918_606': 37.0597,
    '918_607': 37.0729,
    '918_608': 37.0952,
    '918_609': 37.117,
    '918_610': 37.1393,
    '918_611': 37.1636,
    '918_612': 37.1938,
    '918_613': 37.2211,
    '918_614': 37.2569,
    '918_615': 37.2932,
    '918_616': 37.319,
    '918_617': 37.3439,
    '918_618': 37.3789,
    '918_619': 37.4053,
    '918_620': 37.4068,
    '918_621': 37.4015,
    '918_622': 37.3885,
    '918_623': 37.3925,
    '918_624': 37.4003,
    '918_625': 37.4065,
    '918_626': 37.4195,
    '918_627': 37.4323,
    '918_628': 37.4314,
    '918_629': 37.4335,
    '918_630': 37.429,
    '918_631': 37.4061,
    '918_632': 37.3589,
    '918_633': 37.2966,
    '918_634': 37.2232,
    '918_635': 37.1412,
    '918_636': 37.0489,
    '918_637': 36.9496,
    '918_638': 36.8259,
    '918_639': 36.7163,
    '918_640': 36.6089,
    '918_641': 36.525,
    '918_642': 36.4756,
    '918_643': 36.4438,
    '918_644': 36.4367,
    '918_645': 36.4598,
    '918_646': 36.4886,
    '918_647': 36.5409,
    '918_648': 36.6012,
    '918_649': 36.6787,
    '918_650': 36.7729,
    '918_651': 36.8769,
    '918_652': 36.9949,
    '918_653': 37.1144,
    '918_654': 37.2152,
    '918_655': 37.3112,
    '918_656': 37.3646,
    '918_657': 37.3735,
    '918_658': 37.3616,
    '918_659': 37.3554,
    '918_660': 37.382,
    '918_661': 37.3756,
    '918_662': 37.2943,
    '918_663': 37.2478,
    '918_664': 37.2396,
    '918_665': 37.2573,
    '918_666': 37.2948,
    '918_667': 37.3467,
    '918_668': 37.4091,
    '918_669': 37.4796,
    '918_670': 37.5599,
    '918_671': 37.6487,
    '918_672': 37.7452,
    '918_673': 37.8493,
    '918_674': 37.9598,
    '918_675': 38.0745,
    '918_676': 38.1929,
    '918_677': 38.3149,
    '918_678': 38.443,
    '918_679': 38.5784,
    '918_680': 38.7179,
    '918_681': 38.8608,
    '918_682': 39.0117,
    '918_683': 39.1661,
    '918_684': 39.3064,
    '918_685': 39.4507,
    '918_686': 39.6017,
    '918_687': 39.7569,
    '918_688': 39.9163,
    '918_689': 40.0849,
    '918_690': 40.249,
    '918_691': 40.4109,
    '918_692': 40.5651,
    '918_693': 40.7221,
    '918_694': 40.8755,
    '918_695': 41.0288,
    '918_696': 41.1856,
    '918_697': 41.3499,
    '918_698': 41.511,
    '918_699': 41.6649,
    '918_700': 41.8238,
    '918_701': 41.9967,
    '918_702': 42.176,
    '918_703': 42.3399,
    '918_704': 42.4845,
    '918_705': 42.6125,
    '918_706': 42.7205,
    '918_707': 42.8003,
    '918_708': 42.8547,
    '918_709': 42.8829,
    '918_710': 42.8955,
    '918_711': 42.9069,
    '918_712': 42.9246,
    '918_713': 42.957,
    '918_714': 43.0214,
    '918_715': 43.1288,
    '918_716': 43.245,
    '918_717': 43.3571,
    '918_718': 43.4898,
    '918_719': 43.6273,
    '918_720': 43.7393,
    '918_721': 43.8183,
    '918_722': 43.9005,
    '918_723': 43.9258,
    '918_724': 43.8871,
    '918_725': 43.8709,
    '918_726': 43.8058,
    '918_727': 43.7228,
    '918_728': 43.6266,
    '918_729': 43.5187,
    '918_730': 43.4027,
    '918_731': 43.2781,
    '918_732': 43.1263,
    '918_733': 42.9585,
    '918_734': 42.7727,
    '918_735': 42.5561,
    '918_736': 42.3396,
    '918_737': 42.1225,
    '918_738': 41.9714,
    '918_739': 41.9208,
    '918_740': 41.8868,
    '918_741': 41.8395,
    '918_742': 41.7893,
    '918_743': 41.7466,
    '918_744': 41.7433,
    '918_745': 41.7868,
    '918_746': 41.8662,
    '918_747': 41.9634,
    '918_748': 42.0529,
    '918_749': 42.1127,
    '918_750': 42.118,
    '918_751': 42.065,
    '918_752': 41.9632,
    '918_753': 41.8239,
    '918_754': 41.6734,
    '918_755': 41.5291,
    '918_756': 41.3979,
    '918_757': 41.283,
    '918_758': 41.1864,
    '918_759': 41.1031,
    '918_760': 41.0049,
    '918_761': 40.8693,
    '918_762': 40.6868,
    '918_763': 40.4607,
    '918_764': 40.2021,
    '918_765': 39.932,
    '918_766': 39.6732,
    '918_767': 39.4259,
    '918_768': 39.1919,
    '918_769': 38.9558,
    '918_770': 38.7208,
    '918_771': 38.4889,
    '918_772': 38.2671,
    '918_773': 38.0635,
    '918_774': 37.8843,
    '918_775': 37.7271,
    '918_776': 37.5862,
    '918_777': 37.4565,
    '918_778': 37.3377,
    '918_779': 37.2252,
    '918_780': 37.112,
    '918_781': 36.9963,
    '918_782': 36.879,
    '918_783': 36.7617,
    '918_784': 36.6393,
    '918_785': 36.5069,
    '918_786': 36.367,
    '918_787': 36.2292,
    '918_788': 36.0936,
    '918_789': 35.9613,
    '918_790': 35.8349,
    '918_791': 35.7124,
    '918_792': 35.5934,
    '918_793': 35.4763,
    '918_794': 35.361,
    '918_795': 35.2479,
    '918_796': 35.1356,
    '918_797': 35.0163,
    '918_798': 34.8967,
    '918_799': 34.7759,
    '918_800': 34.6563,
    '918_801': 34.5341,
    '918_802': 34.3958,
    '918_803': 34.2629,
    '918_804': 34.1409,
    '918_805': 34.0149,
    '918_806': 33.8867,
    '918_807': 33.7606,
    '918_808': 33.6248,
    '918_809': 33.4774,
    '918_810': 33.3227,
    '918_811': 33.1694,
    '918_812': 33.011,
    '918_813': 32.8487,
    '918_814': 32.6819,
    '918_815': 32.508,
    '918_816': 32.3265,
    '918_817': 32.1393,
    '918_818': 31.9494,
    '918_819': 31.7555,
    '918_820': 31.5556,
    '918_821': 31.3476,
    '918_822': 31.1278,
    '918_823': 30.8904,
    '918_824': 30.6497,
    '918_825': 30.3901,
    '918_826': 30.1189,
    '918_827': 29.8399,
    '918_828': 29.5495,
    '919_472': 32.0511,
    '919_473': 32.1098,
    '919_474': 32.17,
    '919_475': 32.2311,
    '919_476': 32.2964,
    '919_477': 32.3595,
    '919_478': 32.4202,
    '919_479': 32.4736,
    '919_480': 32.5237,
    '919_481': 32.57,
    '919_482': 32.6133,
    '919_483': 32.6563,
    '919_484': 32.7029,
    '919_485': 32.7582,
    '919_486': 32.8211,
    '919_487': 32.8875,
    '919_488': 32.9549,
    '919_489': 33.0219,
    '919_490': 33.0873,
    '919_491': 33.1498,
    '919_492': 33.2069,
    '919_493': 33.258,
    '919_494': 33.3096,
    '919_495': 33.3665,
    '919_496': 33.4312,
    '919_497': 33.5036,
    '919_498': 33.5827,
    '919_499': 33.6587,
    '919_500': 33.7334,
    '919_501': 33.8087,
    '919_502': 33.8846,
    '919_503': 33.9612,
    '919_504': 34.0383,
    '919_505': 34.1154,
    '919_506': 34.1944,
    '919_507': 34.2792,
    '919_508': 34.3712,
    '919_509': 34.4651,
    '919_510': 34.5521,
    '919_511': 34.6318,
    '919_512': 34.7072,
    '919_513': 34.7805,
    '919_514': 34.8393,
    '919_515': 34.8878,
    '919_516': 34.9353,
    '919_517': 34.9903,
    '919_518': 35.0499,
    '919_519': 35.1165,
    '919_520': 35.19,
    '919_521': 35.2598,
    '919_522': 35.3259,
    '919_523': 35.3762,
    '919_524': 35.399,
    '919_525': 35.4278,
    '919_526': 35.4535,
    '919_527': 35.4815,
    '919_528': 35.5206,
    '919_529': 35.5572,
    '919_530': 35.5761,
    '919_531': 35.6063,
    '919_532': 35.6444,
    '919_533': 35.6771,
    '919_534': 35.7176,
    '919_535': 35.7724,
    '919_536': 35.8262,
    '919_537': 35.8774,
    '919_538': 35.9446,
    '919_539': 36.0173,
    '919_540': 36.0984,
    '919_541': 36.1727,
    '919_542': 36.236,
    '919_543': 36.2797,
    '919_544': 36.301,
    '919_545': 36.3085,
    '919_546': 36.3117,
    '919_547': 36.3143,
    '919_548': 36.3161,
    '919_549': 36.3201,
    '919_550': 36.34,
    '919_551': 36.3756,
    '919_552': 36.4148,
    '919_553': 36.4637,
    '919_554': 36.5275,
    '919_555': 36.5959,
    '919_556': 36.6563,
    '919_557': 36.7098,
    '919_558': 36.76,
    '919_559': 36.8045,
    '919_560': 36.8381,
    '919_561': 36.8516,
    '919_562': 36.8493,
    '919_563': 36.8379,
    '919_564': 36.8181,
    '919_565': 36.7942,
    '919_566': 36.7696,
    '919_567': 36.7522,
    '919_568': 36.7613,
    '919_569': 36.8208,
    '919_570': 36.8871,
    '919_571': 36.9627,
    '919_572': 37.049,
    '919_573': 37.1408,
    '919_574': 37.2237,
    '919_575': 37.2869,
    '919_576': 37.3268,
    '919_577': 37.3689,
    '919_578': 37.4376,
    '919_579': 37.5043,
    '919_580': 37.5481,
    '919_581': 37.5538,
    '919_582': 37.5319,
    '919_583': 37.4938,
    '919_584': 37.4472,
    '919_585': 37.3985,
    '919_586': 37.3444,
    '919_587': 37.2957,
    '919_588': 37.2716,
    '919_589': 37.2279,
    '919_590': 37.1857,
    '919_591': 37.1735,
    '919_592': 37.14,
    '919_593': 37.1027,
    '919_594': 37.0817,
    '919_595': 37.0942,
    '919_596': 37.1203,
    '919_597': 37.1547,
    '919_598': 37.1697,
    '919_599': 37.1633,
    '919_600': 37.1483,
    '919_601': 37.131,
    '919_602': 37.1101,
    '919_603': 37.0913,
    '919_604': 37.0717,
    '919_605': 37.0652,
    '919_606': 37.0735,
    '919_607': 37.0799,
    '919_608': 37.0961,
    '919_609': 37.1155,
    '919_610': 37.1385,
    '919_611': 37.1594,
    '919_612': 37.1862,
    '919_613': 37.213,
    '919_614': 37.2449,
    '919_615': 37.2802,
    '919_616': 37.3172,
    '919_617': 37.3452,
    '919_618': 37.3747,
    '919_619': 37.4045,
    '919_620': 37.4131,
    '919_621': 37.4021,
    '919_622': 37.3827,
    '919_623': 37.3806,
    '919_624': 37.3865,
    '919_625': 37.3835,
    '919_626': 37.3843,
    '919_627': 37.3955,
    '919_628': 37.4006,
    '919_629': 37.4038,
    '919_630': 37.401,
    '919_631': 37.3765,
    '919_632': 37.3292,
    '919_633': 37.2652,
    '919_634': 37.1931,
    '919_635': 37.1114,
    '919_636': 37.0179,
    '919_637': 36.9303,
    '919_638': 36.8124,
    '919_639': 36.6966,
    '919_640': 36.5976,
    '919_641': 36.5228,
    '919_642': 36.4738,
    '919_643': 36.4434,
    '919_644': 36.4275,
    '919_645': 36.4391,
    '919_646': 36.4728,
    '919_647': 36.5268,
    '919_648': 36.5933,
    '919_649': 36.6733,
    '919_650': 36.7652,
    '919_651': 36.8646,
    '919_652': 36.9725,
    '919_653': 37.0787,
    '919_654': 37.1779,
    '919_655': 37.2722,
    '919_656': 37.3306,
    '919_657': 37.3554,
    '919_658': 37.3639,
    '919_659': 37.3561,
    '919_660': 37.3618,
    '919_661': 37.3511,
    '919_662': 37.2923,
    '919_663': 37.2649,
    '919_664': 37.2642,
    '919_665': 37.2841,
    '919_666': 37.3224,
    '919_667': 37.3754,
    '919_668': 37.439,
    '919_669': 37.5106,
    '919_670': 37.592,
    '919_671': 37.682,
    '919_672': 37.7787,
    '919_673': 37.8827,
    '919_674': 37.9921,
    '919_675': 38.1064,
    '919_676': 38.2235,
    '919_677': 38.3455,
    '919_678': 38.4754,
    '919_679': 38.6128,
    '919_680': 38.7535,
    '919_681': 38.8966,
    '919_682': 39.0437,
    '919_683': 39.1926,
    '919_684': 39.3229,
    '919_685': 39.4582,
    '919_686': 39.6009,
    '919_687': 39.7466,
    '919_688': 39.8958,
    '919_689': 40.0626,
    '919_690': 40.231,
    '919_691': 40.3948,
    '919_692': 40.5552,
    '919_693': 40.7215,
    '919_694': 40.8803,
    '919_695': 41.0369,
    '919_696': 41.1954,
    '919_697': 41.3627,
    '919_698': 41.5266,
    '919_699': 41.6879,
    '919_700': 41.8522,
    '919_701': 42.0276,
    '919_702': 42.2045,
    '919_703': 42.3719,
    '919_704': 42.5145,
    '919_705': 42.6409,
    '919_706': 42.7476,
    '919_707': 42.8269,
    '919_708': 42.8811,
    '919_709': 42.9062,
    '919_710': 42.919,
    '919_711': 42.93,
    '919_712': 42.9428,
    '919_713': 42.9709,
    '919_714': 43.0391,
    '919_715': 43.1514,
    '919_716': 43.2682,
    '919_717': 43.38,
    '919_718': 43.5065,
    '919_719': 43.6526,
    '919_720': 43.7895,
    '919_721': 43.8766,
    '919_722': 43.9581,
    '919_723': 43.9977,
    '919_724': 43.9908,
    '919_725': 43.9773,
    '919_726': 43.9107,
    '919_727': 43.8193,
    '919_728': 43.7235,
    '919_729': 43.6307,
    '919_730': 43.5001,
    '919_731': 43.3804,
    '919_732': 43.2137,
    '919_733': 43.0366,
    '919_734': 42.8511,
    '919_735': 42.637,
    '919_736': 42.4082,
    '919_737': 42.2026,
    '919_738': 42.068,
    '919_739': 42.0379,
    '919_740': 42.0181,
    '919_741': 41.9863,
    '919_742': 41.9431,
    '919_743': 41.8994,
    '919_744': 41.8992,
    '919_745': 41.9491,
    '919_746': 42.0382,
    '919_747': 42.1468,
    '919_748': 42.2456,
    '919_749': 42.3021,
    '919_750': 42.2988,
    '919_751': 42.2352,
    '919_752': 42.1165,
    '919_753': 41.9598,
    '919_754': 41.797,
    '919_755': 41.6409,
    '919_756': 41.4954,
    '919_757': 41.3613,
    '919_758': 41.2419,
    '919_759': 41.1374,
    '919_760': 41.0317,
    '919_761': 40.8932,
    '919_762': 40.7075,
    '919_763': 40.4843,
    '919_764': 40.241,
    '919_765': 39.995,
    '919_766': 39.7555,
    '919_767': 39.5263,
    '919_768': 39.302,
    '919_769': 39.0616,
    '919_770': 38.83,
    '919_771': 38.6,
    '919_772': 38.372,
    '919_773': 38.1593,
    '919_774': 37.9696,
    '919_775': 37.8025,
    '919_776': 37.6511,
    '919_777': 37.5071,
    '919_778': 37.3753,
    '919_779': 37.2498,
    '919_780': 37.1262,
    '919_781': 37.0001,
    '919_782': 36.8702,
    '919_783': 36.7418,
    '919_784': 36.6092,
    '919_785': 36.4689,
    '919_786': 36.3281,
    '919_787': 36.1898,
    '919_788': 36.057,
    '919_789': 35.9248,
    '919_790': 35.7996,
    '919_791': 35.6769,
    '919_792': 35.5574,
    '919_793': 35.4392,
    '919_794': 35.3217,
    '919_795': 35.2045,
    '919_796': 35.0881,
    '919_797': 34.9681,
    '919_798': 34.8488,
    '919_799': 34.7281,
    '919_800': 34.6056,
    '919_801': 34.4773,
    '919_802': 34.3406,
    '919_803': 34.2149,
    '919_804': 34.0943,
    '919_805': 33.9709,
    '919_806': 33.8417,
    '919_807': 33.7165,
    '919_808': 33.584,
    '919_809': 33.4433,
    '919_810': 33.2963,
    '919_811': 33.1467,
    '919_812': 32.993,
    '919_813': 32.8354,
    '919_814': 32.6748,
    '919_815': 32.5073,
    '919_816': 32.3336,
    '919_817': 32.1542,
    '919_818': 31.9711,
    '919_819': 31.783,
    '919_820': 31.5885,
    '919_821': 31.3859,
    '919_822': 31.1722,
    '919_823': 30.9434,
    '919_824': 30.698,
    '919_825': 30.4438,
    '919_826': 30.1719,
    '919_827': 29.9008,
    '919_828': 29.6253,
    '920_472': 32.0194,
    '920_473': 32.0763,
    '920_474': 32.1341,
    '920_475': 32.1925,
    '920_476': 32.254,
    '920_477': 32.316,
    '920_478': 32.3791,
    '920_479': 32.4394,
    '920_480': 32.4963,
    '920_481': 32.5491,
    '920_482': 32.5967,
    '920_483': 32.6426,
    '920_484': 32.6914,
    '920_485': 32.7466,
    '920_486': 32.8099,
    '920_487': 32.8767,
    '920_488': 32.9441,
    '920_489': 33.0104,
    '920_490': 33.0741,
    '920_491': 33.1341,
    '920_492': 33.1866,
    '920_493': 33.2354,
    '920_494': 33.2861,
    '920_495': 33.3443,
    '920_496': 33.4119,
    '920_497': 33.4844,
    '920_498': 33.5587,
    '920_499': 33.632,
    '920_500': 33.7043,
    '920_501': 33.777,
    '920_502': 33.8502,
    '920_503': 33.9241,
    '920_504': 33.9985,
    '920_505': 34.0729,
    '920_506': 34.1482,
    '920_507': 34.2277,
    '920_508': 34.3102,
    '920_509': 34.395,
    '920_510': 34.4812,
    '920_511': 34.5664,
    '920_512': 34.6445,
    '920_513': 34.717,
    '920_514': 34.7803,
    '920_515': 34.8334,
    '920_516': 34.8842,
    '920_517': 34.9378,
    '920_518': 34.9965,
    '920_519': 35.0627,
    '920_520': 35.1291,
    '920_521': 35.1888,
    '920_522': 35.253,
    '920_523': 35.3069,
    '920_524': 35.3325,
    '920_525': 35.3523,
    '920_526': 35.379,
    '920_527': 35.4062,
    '920_528': 35.4441,
    '920_529': 35.4742,
    '920_530': 35.4931,
    '920_531': 35.5189,
    '920_532': 35.5555,
    '920_533': 35.5882,
    '920_534': 35.634,
    '920_535': 35.6852,
    '920_536': 35.7439,
    '920_537': 35.8029,
    '920_538': 35.8751,
    '920_539': 35.9633,
    '920_540': 36.0548,
    '920_541': 36.1367,
    '920_542': 36.2016,
    '920_543': 36.2413,
    '920_544': 36.2568,
    '920_545': 36.2589,
    '920_546': 36.2562,
    '920_547': 36.2524,
    '920_548': 36.2451,
    '920_549': 36.2352,
    '920_550': 36.244,
    '920_551': 36.2809,
    '920_552': 36.323,
    '920_553': 36.3685,
    '920_554': 36.4164,
    '920_555': 36.4881,
    '920_556': 36.554,
    '920_557': 36.6205,
    '920_558': 36.6821,
    '920_559': 36.7343,
    '920_560': 36.7713,
    '920_561': 36.7855,
    '920_562': 36.7784,
    '920_563': 36.7605,
    '920_564': 36.7337,
    '920_565': 36.7066,
    '920_566': 36.6818,
    '920_567': 36.6691,
    '920_568': 36.6818,
    '920_569': 36.7404,
    '920_570': 36.8063,
    '920_571': 36.8785,
    '920_572': 36.9647,
    '920_573': 37.0459,
    '920_574': 37.1217,
    '920_575': 37.1898,
    '920_576': 37.241,
    '920_577': 37.3109,
    '920_578': 37.3982,
    '920_579': 37.472,
    '920_580': 37.5168,
    '920_581': 37.5272,
    '920_582': 37.5031,
    '920_583': 37.4608,
    '920_584': 37.4167,
    '920_585': 37.3668,
    '920_586': 37.3081,
    '920_587': 37.2558,
    '920_588': 37.225,
    '920_589': 37.1833,
    '920_590': 37.1433,
    '920_591': 37.1339,
    '920_592': 37.1001,
    '920_593': 37.0693,
    '920_594': 37.0537,
    '920_595': 37.0734,
    '920_596': 37.1002,
    '920_597': 37.1252,
    '920_598': 37.1425,
    '920_599': 37.1498,
    '920_600': 37.1396,
    '920_601': 37.123,
    '920_602': 37.0998,
    '920_603': 37.0806,
    '920_604': 37.0699,
    '920_605': 37.0748,
    '920_606': 37.0929,
    '920_607': 37.0942,
    '920_608': 37.1049,
    '920_609': 37.121,
    '920_610': 37.1458,
    '920_611': 37.1637,
    '920_612': 37.1828,
    '920_613': 37.2076,
    '920_614': 37.2306,
    '920_615': 37.2606,
    '920_616': 37.301,
    '920_617': 37.3373,
    '920_618': 37.3652,
    '920_619': 37.3912,
    '920_620': 37.4038,
    '920_621': 37.3905,
    '920_622': 37.3707,
    '920_623': 37.3603,
    '920_624': 37.3639,
    '920_625': 37.3616,
    '920_626': 37.3553,
    '920_627': 37.3587,
    '920_628': 37.364,
    '920_629': 37.3684,
    '920_630': 37.3644,
    '920_631': 37.3375,
    '920_632': 37.2904,
    '920_633': 37.2286,
    '920_634': 37.1574,
    '920_635': 37.0755,
    '920_636': 36.9857,
    '920_637': 36.9015,
    '920_638': 36.8016,
    '920_639': 36.6935,
    '920_640': 36.6005,
    '920_641': 36.5257,
    '920_642': 36.4732,
    '920_643': 36.4384,
    '920_644': 36.4197,
    '920_645': 36.4282,
    '920_646': 36.462,
    '920_647': 36.5188,
    '920_648': 36.5905,
    '920_649': 36.6733,
    '920_650': 36.7644,
    '920_651': 36.8603,
    '920_652': 36.9611,
    '920_653': 37.0622,
    '920_654': 37.1575,
    '920_655': 37.2431,
    '920_656': 37.3022,
    '920_657': 37.341,
    '920_658': 37.3597,
    '920_659': 37.3568,
    '920_660': 37.3522,
    '920_661': 37.3417,
    '920_662': 37.305,
    '920_663': 37.2903,
    '920_664': 37.2948,
    '920_665': 37.3158,
    '920_666': 37.3534,
    '920_667': 37.4063,
    '920_668': 37.4705,
    '920_669': 37.5439,
    '920_670': 37.6263,
    '920_671': 37.7177,
    '920_672': 37.815,
    '920_673': 37.9185,
    '920_674': 38.0276,
    '920_675': 38.1407,
    '920_676': 38.2568,
    '920_677': 38.3775,
    '920_678': 38.5069,
    '920_679': 38.6465,
    '920_680': 38.785,
    '920_681': 38.9283,
    '920_682': 39.0729,
    '920_683': 39.2114,
    '920_684': 39.3331,
    '920_685': 39.4632,
    '920_686': 39.6026,
    '920_687': 39.7414,
    '920_688': 39.8841,
    '920_689': 40.043,
    '920_690': 40.2069,
    '920_691': 40.3736,
    '920_692': 40.5472,
    '920_693': 40.7211,
    '920_694': 40.8797,
    '920_695': 41.0351,
    '920_696': 41.1956,
    '920_697': 41.3664,
    '920_698': 41.5374,
    '920_699': 41.7089,
    '920_700': 41.8825,
    '920_701': 42.0615,
    '920_702': 42.2395,
    '920_703': 42.4014,
    '920_704': 42.5512,
    '920_705': 42.6756,
    '920_706': 42.7796,
    '920_707': 42.8522,
    '920_708': 42.8989,
    '920_709': 42.93,
    '920_710': 42.9401,
    '920_711': 42.9469,
    '920_712': 42.9562,
    '920_713': 42.9848,
    '920_714': 43.0593,
    '920_715': 43.1777,
    '920_716': 43.3019,
    '920_717': 43.4145,
    '920_718': 43.531,
    '920_719': 43.6704,
    '920_720': 43.8131,
    '920_721': 43.9168,
    '920_722': 43.9858,
    '920_723': 44.0389,
    '920_724': 44.0439,
    '920_725': 44.0633,
    '920_726': 43.9931,
    '920_727': 43.9201,
    '920_728': 43.8083,
    '920_729': 43.7338,
    '920_730': 43.5936,
    '920_731': 43.4707,
    '920_732': 43.3034,
    '920_733': 43.1103,
    '920_734': 42.9222,
    '920_735': 42.7089,
    '920_736': 42.47,
    '920_737': 42.2751,
    '920_738': 42.1609,
    '920_739': 42.1488,
    '920_740': 42.1471,
    '920_741': 42.1263,
    '920_742': 42.0858,
    '920_743': 42.0441,
    '920_744': 42.045,
    '920_745': 42.0989,
    '920_746': 42.196,
    '920_747': 42.3148,
    '920_748': 42.4157,
    '920_749': 42.4486,
    '920_750': 42.4336,
    '920_751': 42.3838,
    '920_752': 42.2621,
    '920_753': 42.0967,
    '920_754': 41.9253,
    '920_755': 41.7606,
    '920_756': 41.6029,
    '920_757': 41.4515,
    '920_758': 41.3125,
    '920_759': 41.1901,
    '920_760': 41.075,
    '920_761': 40.9375,
    '920_762': 40.7547,
    '920_763': 40.5306,
    '920_764': 40.3022,
    '920_765': 40.0768,
    '920_766': 39.8554,
    '920_767': 39.638,
    '920_768': 39.408,
    '920_769': 39.1688,
    '920_770': 38.9362,
    '920_771': 38.7046,
    '920_772': 38.4692,
    '920_773': 38.2481,
    '920_774': 38.0493,
    '920_775': 37.8728,
    '920_776': 37.7106,
    '920_777': 37.555,
    '920_778': 37.4105,
    '920_779': 37.2718,
    '920_780': 37.1355,
    '920_781': 36.9979,
    '920_782': 36.8614,
    '920_783': 36.7246,
    '920_784': 36.5826,
    '920_785': 36.438,
    '920_786': 36.2967,
    '920_787': 36.1591,
    '920_788': 36.024,
    '920_789': 35.8967,
    '920_790': 35.7715,
    '920_791': 35.6493,
    '920_792': 35.5293,
    '920_793': 35.4097,
    '920_794': 35.2896,
    '920_795': 35.1696,
    '920_796': 35.0507,
    '920_797': 34.9304,
    '920_798': 34.8085,
    '920_799': 34.6842,
    '920_800': 34.558,
    '920_801': 34.4279,
    '920_802': 34.2945,
    '920_803': 34.1744,
    '920_804': 34.0529,
    '920_805': 33.9292,
    '920_806': 33.8026,
    '920_807': 33.6777,
    '920_808': 33.5473,
    '920_809': 33.4109,
    '920_810': 33.2694,
    '920_811': 33.1245,
    '920_812': 32.9768,
    '920_813': 32.8261,
    '920_814': 32.6714,
    '920_815': 32.51,
    '920_816': 32.3418,
    '920_817': 32.1695,
    '920_818': 31.993,
    '920_819': 31.8106,
    '920_820': 31.6214,
    '920_821': 31.4242,
    '920_822': 31.2165,
    '920_823': 30.9954,
    '920_824': 30.7586,
    '920_825': 30.5104,
    '920_826': 30.2497,
    '920_827': 29.9714,
    '920_828': 29.6991,
    '921_472': 31.9838,
    '921_473': 32.0387,
    '921_474': 32.0941,
    '921_475': 32.1515,
    '921_476': 32.2113,
    '921_477': 32.2717,
    '921_478': 32.3369,
    '921_479': 32.4022,
    '921_480': 32.4647,
    '921_481': 32.5222,
    '921_482': 32.5705,
    '921_483': 32.6212,
    '921_484': 32.6744,
    '921_485': 32.7314,
    '921_486': 32.797,
    '921_487': 32.8651,
    '921_488': 32.9331,
    '921_489': 32.999,
    '921_490': 33.0601,
    '921_491': 33.1165,
    '921_492': 33.1668,
    '921_493': 33.2146,
    '921_494': 33.2657,
    '921_495': 33.3272,
    '921_496': 33.3957,
    '921_497': 33.4661,
    '921_498': 33.5377,
    '921_499': 33.6081,
    '921_500': 33.6778,
    '921_501': 33.7481,
    '921_502': 33.8196,
    '921_503': 33.8921,
    '921_504': 33.966,
    '921_505': 34.0401,
    '921_506': 34.113,
    '921_507': 34.1883,
    '921_508': 34.265,
    '921_509': 34.3436,
    '921_510': 34.4237,
    '921_511': 34.5041,
    '921_512': 34.5822,
    '921_513': 34.6613,
    '921_514': 34.7301,
    '921_515': 34.7878,
    '921_516': 34.8364,
    '921_517': 34.8919,
    '921_518': 34.9512,
    '921_519': 35.0184,
    '921_520': 35.081,
    '921_521': 35.129,
    '921_522': 35.1808,
    '921_523': 35.228,
    '921_524': 35.2617,
    '921_525': 35.2823,
    '921_526': 35.3068,
    '921_527': 35.331,
    '921_528': 35.3669,
    '921_529': 35.3883,
    '921_530': 35.4098,
    '921_531': 35.4357,
    '921_532': 35.4718,
    '921_533': 35.5069,
    '921_534': 35.555,
    '921_535': 35.5999,
    '921_536': 35.6629,
    '921_537': 35.7274,
    '921_538': 35.8092,
    '921_539': 35.905,
    '921_540': 36.006,
    '921_541': 36.0975,
    '921_542': 36.1627,
    '921_543': 36.1919,
    '921_544': 36.2026,
    '921_545': 36.2004,
    '921_546': 36.1845,
    '921_547': 36.1631,
    '921_548': 36.1461,
    '921_549': 36.137,
    '921_550': 36.1421,
    '921_551': 36.1771,
    '921_552': 36.2251,
    '921_553': 36.2689,
    '921_554': 36.3043,
    '921_555': 36.3715,
    '921_556': 36.4341,
    '921_557': 36.5071,
    '921_558': 36.5811,
    '921_559': 36.6439,
    '921_560': 36.6891,
    '921_561': 36.7125,
    '921_562': 36.7109,
    '921_563': 36.6916,
    '921_564': 36.6611,
    '921_565': 36.6252,
    '921_566': 36.5963,
    '921_567': 36.5885,
    '921_568': 36.6046,
    '921_569': 36.654,
    '921_570': 36.7197,
    '921_571': 36.7955,
    '921_572': 36.8686,
    '921_573': 36.9419,
    '921_574': 37.0176,
    '921_575': 37.0935,
    '921_576': 37.1682,
    '921_577': 37.2592,
    '921_578': 37.3514,
    '921_579': 37.4269,
    '921_580': 37.4742,
    '921_581': 37.4772,
    '921_582': 37.455,
    '921_583': 37.4112,
    '921_584': 37.3705,
    '921_585': 37.3277,
    '921_586': 37.2774,
    '921_587': 37.2248,
    '921_588': 37.1745,
    '921_589': 37.139,
    '921_590': 37.0965,
    '921_591': 37.0757,
    '921_592': 37.054,
    '921_593': 37.0308,
    '921_594': 37.0314,
    '921_595': 37.0566,
    '921_596': 37.0862,
    '921_597': 37.1112,
    '921_598': 37.1273,
    '921_599': 37.1429,
    '921_600': 37.136,
    '921_601': 37.1241,
    '921_602': 37.0998,
    '921_603': 37.0819,
    '921_604': 37.069,
    '921_605': 37.0717,
    '921_606': 37.0877,
    '921_607': 37.0965,
    '921_608': 37.1049,
    '921_609': 37.1169,
    '921_610': 37.1411,
    '921_611': 37.1649,
    '921_612': 37.1845,
    '921_613': 37.2047,
    '921_614': 37.2299,
    '921_615': 37.2518,
    '921_616': 37.278,
    '921_617': 37.3106,
    '921_618': 37.338,
    '921_619': 37.3622,
    '921_620': 37.3797,
    '921_621': 37.3728,
    '921_622': 37.3528,
    '921_623': 37.3357,
    '921_624': 37.3313,
    '921_625': 37.3287,
    '921_626': 37.3194,
    '921_627': 37.3139,
    '921_628': 37.3127,
    '921_629': 37.3172,
    '921_630': 37.3127,
    '921_631': 37.2886,
    '921_632': 37.2464,
    '921_633': 37.1919,
    '921_634': 37.1245,
    '921_635': 37.0418,
    '921_636': 36.9547,
    '921_637': 36.867,
    '921_638': 36.7848,
    '921_639': 36.6904,
    '921_640': 36.6065,
    '921_641': 36.532,
    '921_642': 36.4719,
    '921_643': 36.4319,
    '921_644': 36.4138,
    '921_645': 36.4224,
    '921_646': 36.4568,
    '921_647': 36.5184,
    '921_648': 36.5947,
    '921_649': 36.6799,
    '921_650': 36.7717,
    '921_651': 36.8654,
    '921_652': 36.9634,
    '921_653': 37.0647,
    '921_654': 37.1561,
    '921_655': 37.2379,
    '921_656': 37.3004,
    '921_657': 37.346,
    '921_658': 37.3666,
    '921_659': 37.371,
    '921_660': 37.3744,
    '921_661': 37.3576,
    '921_662': 37.3301,
    '921_663': 37.324,
    '921_664': 37.332,
    '921_665': 37.3533,
    '921_666': 37.3896,
    '921_667': 37.4415,
    '921_668': 37.5064,
    '921_669': 37.5807,
    '921_670': 37.6643,
    '921_671': 37.7561,
    '921_672': 37.8539,
    '921_673': 37.9575,
    '921_674': 38.0661,
    '921_675': 38.1779,
    '921_676': 38.2927,
    '921_677': 38.4123,
    '921_678': 38.539,
    '921_679': 38.6742,
    '921_680': 38.813,
    '921_681': 38.9556,
    '921_682': 39.095,
    '921_683': 39.2263,
    '921_684': 39.3477,
    '921_685': 39.4789,
    '921_686': 39.6129,
    '921_687': 39.743,
    '921_688': 39.8781,
    '921_689': 40.0286,
    '921_690': 40.1877,
    '921_691': 40.3556,
    '921_692': 40.5362,
    '921_693': 40.7191,
    '921_694': 40.8779,
    '921_695': 41.0311,
    '921_696': 41.1893,
    '921_697': 41.3583,
    '921_698': 41.5358,
    '921_699': 41.7193,
    '921_700': 41.9064,
    '921_701': 42.0937,
    '921_702': 42.2745,
    '921_703': 42.4389,
    '921_704': 42.5863,
    '921_705': 42.7052,
    '921_706': 42.8052,
    '921_707': 42.8706,
    '921_708': 42.9132,
    '921_709': 42.9419,
    '921_710': 42.9509,
    '921_711': 42.9549,
    '921_712': 42.9614,
    '921_713': 43.0014,
    '921_714': 43.087,
    '921_715': 43.2049,
    '921_716': 43.3339,
    '921_717': 43.4518,
    '921_718': 43.5656,
    '921_719': 43.691,
    '921_720': 43.8206,
    '921_721': 43.9456,
    '921_722': 44.0294,
    '921_723': 44.086,
    '921_724': 44.1131,
    '921_725': 44.1149,
    '921_726': 44.0898,
    '921_727': 44.0211,
    '921_728': 43.917,
    '921_729': 43.8185,
    '921_730': 43.6798,
    '921_731': 43.5267,
    '921_732': 43.3574,
    '921_733': 43.1704,
    '921_734': 42.9642,
    '921_735': 42.7619,
    '921_736': 42.5367,
    '921_737': 42.3438,
    '921_738': 42.2434,
    '921_739': 42.247,
    '921_740': 42.2606,
    '921_741': 42.2552,
    '921_742': 42.2178,
    '921_743': 42.1766,
    '921_744': 42.1795,
    '921_745': 42.2356,
    '921_746': 42.3354,
    '921_747': 42.4581,
    '921_748': 42.5362,
    '921_749': 42.475,
    '921_750': 42.5005,
    '921_751': 42.5091,
    '921_752': 42.392,
    '921_753': 42.2281,
    '921_754': 42.0564,
    '921_755': 41.8905,
    '921_756': 41.7236,
    '921_757': 41.5588,
    '921_758': 41.4049,
    '921_759': 41.2671,
    '921_760': 41.1385,
    '921_761': 40.9961,
    '921_762': 40.8201,
    '921_763': 40.6049,
    '921_764': 40.3934,
    '921_765': 40.188,
    '921_766': 39.9812,
    '921_767': 39.7606,
    '921_768': 39.5209,
    '921_769': 39.2791,
    '921_770': 39.0384,
    '921_771': 38.7977,
    '921_772': 38.5601,
    '921_773': 38.3336,
    '921_774': 38.1289,
    '921_775': 37.9413,
    '921_776': 37.7677,
    '921_777': 37.6004,
    '921_778': 37.4424,
    '921_779': 37.2906,
    '921_780': 37.1408,
    '921_781': 36.9921,
    '921_782': 36.8474,
    '921_783': 36.7047,
    '921_784': 36.5598,
    '921_785': 36.4141,
    '921_786': 36.2722,
    '921_787': 36.1378,
    '921_788': 35.9986,
    '921_789': 35.8748,
    '921_790': 35.7491,
    '921_791': 35.6278,
    '921_792': 35.5089,
    '921_793': 35.3884,
    '921_794': 35.2664,
    '921_795': 35.144,
    '921_796': 35.022,
    '921_797': 34.8997,
    '921_798': 34.774,
    '921_799': 34.6468,
    '921_800': 34.5193,
    '921_801': 34.3878,
    '921_802': 34.2573,
    '921_803': 34.1387,
    '921_804': 34.0188,
    '921_805': 33.8944,
    '921_806': 33.77,
    '921_807': 33.6465,
    '921_808': 33.5191,
    '921_809': 33.3838,
    '921_810': 33.2454,
    '921_811': 33.1052,
    '921_812': 32.9631,
    '921_813': 32.8188,
    '921_814': 32.6706,
    '921_815': 32.5154,
    '921_816': 32.3528,
    '921_817': 32.187,
    '921_818': 32.0164,
    '921_819': 31.8395,
    '921_820': 31.6557,
    '921_821': 31.464,
    '921_822': 31.2627,
    '921_823': 31.0496,
    '921_824': 30.8228,
    '921_825': 30.5806,
    '921_826': 30.3251,
    '921_827': 30.0568,
    '921_828': 29.7825,
    '922_472': 31.944,
    '922_473': 31.9948,
    '922_474': 32.049,
    '922_475': 32.1075,
    '922_476': 32.1679,
    '922_477': 32.2267,
    '922_478': 32.2947,
    '922_479': 32.363,
    '922_480': 32.427,
    '922_481': 32.4844,
    '922_482': 32.5388,
    '922_483': 32.5956,
    '922_484': 32.6554,
    '922_485': 32.7182,
    '922_486': 32.7839,
    '922_487': 32.8521,
    '922_488': 32.9211,
    '922_489': 32.988,
    '922_490': 33.0492,
    '922_491': 33.1019,
    '922_492': 33.1511,
    '922_493': 33.2,
    '922_494': 33.2553,
    '922_495': 33.316,
    '922_496': 33.3815,
    '922_497': 33.4494,
    '922_498': 33.5192,
    '922_499': 33.5868,
    '922_500': 33.6539,
    '922_501': 33.722,
    '922_502': 33.7922,
    '922_503': 33.8647,
    '922_504': 33.94,
    '922_505': 34.0156,
    '922_506': 34.0885,
    '922_507': 34.1619,
    '922_508': 34.2373,
    '922_509': 34.3119,
    '922_510': 34.3846,
    '922_511': 34.4554,
    '922_512': 34.5256,
    '922_513': 34.5994,
    '922_514': 34.6706,
    '922_515': 34.7374,
    '922_516': 34.7905,
    '922_517': 34.8466,
    '922_518': 34.9098,
    '922_519': 34.9723,
    '922_520': 35.0298,
    '922_521': 35.075,
    '922_522': 35.1152,
    '922_523': 35.1458,
    '922_524': 35.1848,
    '922_525': 35.2153,
    '922_526': 35.2408,
    '922_527': 35.2564,
    '922_528': 35.2833,
    '922_529': 35.3084,
    '922_530': 35.3269,
    '922_531': 35.3546,
    '922_532': 35.3941,
    '922_533': 35.4332,
    '922_534': 35.471,
    '922_535': 35.5206,
    '922_536': 35.5853,
    '922_537': 35.653,
    '922_538': 35.7413,
    '922_539': 35.8411,
    '922_540': 35.9457,
    '922_541': 36.0406,
    '922_542': 36.0967,
    '922_543': 36.1349,
    '922_544': 36.1304,
    '922_545': 36.1129,
    '922_546': 36.0915,
    '922_547': 36.064,
    '922_548': 36.0457,
    '922_549': 36.0362,
    '922_550': 36.0408,
    '922_551': 36.0689,
    '922_552': 36.1124,
    '922_553': 36.1585,
    '922_554': 36.1921,
    '922_555': 36.2469,
    '922_556': 36.3064,
    '922_557': 36.3797,
    '922_558': 36.4598,
    '922_559': 36.5345,
    '922_560': 36.5906,
    '922_561': 36.6203,
    '922_562': 36.6276,
    '922_563': 36.6115,
    '922_564': 36.5738,
    '922_565': 36.5336,
    '922_566': 36.508,
    '922_567': 36.5042,
    '922_568': 36.5253,
    '922_569': 36.574,
    '922_570': 36.6341,
    '922_571': 36.7056,
    '922_572': 36.7761,
    '922_573': 36.8565,
    '922_574': 36.9402,
    '922_575': 37.0232,
    '922_576': 37.1188,
    '922_577': 37.2134,
    '922_578': 37.2996,
    '922_579': 37.3659,
    '922_580': 37.4069,
    '922_581': 37.4159,
    '922_582': 37.3891,
    '922_583': 37.3493,
    '922_584': 37.3147,
    '922_585': 37.2772,
    '922_586': 37.234,
    '922_587': 37.186,
    '922_588': 37.1271,
    '922_589': 37.086,
    '922_590': 37.0507,
    '922_591': 37.0219,
    '922_592': 37.0078,
    '922_593': 37.001,
    '922_594': 37.019,
    '922_595': 37.0488,
    '922_596': 37.0819,
    '922_597': 37.1095,
    '922_598': 37.1212,
    '922_599': 37.1333,
    '922_600': 37.1369,
    '922_601': 37.1238,
    '922_602': 37.1009,
    '922_603': 37.0856,
    '922_604': 37.066,
    '922_605': 37.0607,
    '922_606': 37.071,
    '922_607': 37.0834,
    '922_608': 37.096,
    '922_609': 37.1113,
    '922_610': 37.1318,
    '922_611': 37.1581,
    '922_612': 37.1786,
    '922_613': 37.1958,
    '922_614': 37.2262,
    '922_615': 37.2527,
    '922_616': 37.2639,
    '922_617': 37.2756,
    '922_618': 37.2964,
    '922_619': 37.3229,
    '922_620': 37.3434,
    '922_621': 37.3447,
    '922_622': 37.3257,
    '922_623': 37.3029,
    '922_624': 37.2898,
    '922_625': 37.2819,
    '922_626': 37.2663,
    '922_627': 37.2501,
    '922_628': 37.2489,
    '922_629': 37.2501,
    '922_630': 37.2462,
    '922_631': 37.2333,
    '922_632': 37.2051,
    '922_633': 37.1586,
    '922_634': 37.0964,
    '922_635': 37.0181,
    '922_636': 36.9358,
    '922_637': 36.8547,
    '922_638': 36.7746,
    '922_639': 36.6908,
    '922_640': 36.6172,
    '922_641': 36.5419,
    '922_642': 36.4755,
    '922_643': 36.4332,
    '922_644': 36.4165,
    '922_645': 36.427,
    '922_646': 36.4666,
    '922_647': 36.5299,
    '922_648': 36.6077,
    '922_649': 36.693,
    '922_650': 36.7843,
    '922_651': 36.8789,
    '922_652': 36.9764,
    '922_653': 37.0778,
    '922_654': 37.1709,
    '922_655': 37.2548,
    '922_656': 37.328,
    '922_657': 37.3834,
    '922_658': 37.4057,
    '922_659': 37.4108,
    '922_660': 37.4039,
    '922_661': 37.3879,
    '922_662': 37.372,
    '922_663': 37.3695,
    '922_664': 37.377,
    '922_665': 37.3966,
    '922_666': 37.432,
    '922_667': 37.4831,
    '922_668': 37.5476,
    '922_669': 37.6224,
    '922_670': 37.7059,
    '922_671': 37.7968,
    '922_672': 37.8953,
    '922_673': 37.9988,
    '922_674': 38.1068,
    '922_675': 38.2181,
    '922_676': 38.3318,
    '922_677': 38.4491,
    '922_678': 38.5723,
    '922_679': 38.7022,
    '922_680': 38.8374,
    '922_681': 38.9756,
    '922_682': 39.1073,
    '922_683': 39.2354,
    '922_684': 39.3646,
    '922_685': 39.4992,
    '922_686': 39.6324,
    '922_687': 39.7601,
    '922_688': 39.8911,
    '922_689': 40.0352,
    '922_690': 40.1843,
    '922_691': 40.3497,
    '922_692': 40.5294,
    '922_693': 40.7112,
    '922_694': 40.8723,
    '922_695': 41.0278,
    '922_696': 41.1843,
    '922_697': 41.3458,
    '922_698': 41.5184,
    '922_699': 41.7137,
    '922_700': 41.9161,
    '922_701': 42.1139,
    '922_702': 42.3027,
    '922_703': 42.4713,
    '922_704': 42.6181,
    '922_705': 42.7338,
    '922_706': 42.8239,
    '922_707': 42.8869,
    '922_708': 42.9227,
    '922_709': 42.9433,
    '922_710': 42.9496,
    '922_711': 42.9536,
    '922_712': 42.9637,
    '922_713': 43.0214,
    '922_714': 43.117,
    '922_715': 43.2346,
    '922_716': 43.3651,
    '922_717': 43.4882,
    '922_718': 43.6034,
    '922_719': 43.7163,
    '922_720': 43.8465,
    '922_721': 43.9667,
    '922_722': 44.0649,
    '922_723': 44.1581,
    '922_724': 44.1872,
    '922_725': 44.1716,
    '922_726': 44.1497,
    '922_727': 44.1085,
    '922_728': 44.03,
    '922_729': 43.8842,
    '922_730': 43.7642,
    '922_731': 43.6067,
    '922_732': 43.3996,
    '922_733': 43.2074,
    '922_734': 42.9965,
    '922_735': 42.8105,
    '922_736': 42.5971,
    '922_737': 42.4119,
    '922_738': 42.3178,
    '922_739': 42.3311,
    '922_740': 42.3651,
    '922_741': 42.369,
    '922_742': 42.3421,
    '922_743': 42.3016,
    '922_744': 42.3036,
    '922_745': 42.3576,
    '922_746': 42.4542,
    '922_747': 42.568,
    '922_748': 42.6231,
    '922_749': 42.5196,
    '922_750': 42.5731,
    '922_751': 42.5999,
    '922_752': 42.491,
    '922_753': 42.3431,
    '922_754': 42.1844,
    '922_755': 42.0241,
    '922_756': 41.8601,
    '922_757': 41.6883,
    '922_758': 41.522,
    '922_759': 41.3718,
    '922_760': 41.2294,
    '922_761': 41.0751,
    '922_762': 40.9075,
    '922_763': 40.7161,
    '922_764': 40.5241,
    '922_765': 40.3332,
    '922_766': 40.1199,
    '922_767': 39.8886,
    '922_768': 39.6447,
    '922_769': 39.3975,
    '922_770': 39.1501,
    '922_771': 38.8947,
    '922_772': 38.6493,
    '922_773': 38.4177,
    '922_774': 38.2058,
    '922_775': 38.0046,
    '922_776': 37.8165,
    '922_777': 37.6377,
    '922_778': 37.4669,
    '922_779': 37.3032,
    '922_780': 37.1429,
    '922_781': 36.9856,
    '922_782': 36.8331,
    '922_783': 36.6849,
    '922_784': 36.5372,
    '922_785': 36.3905,
    '922_786': 36.2512,
    '922_787': 36.1282,
    '922_788': 35.9844,
    '922_789': 35.8552,
    '922_790': 35.7327,
    '922_791': 35.6127,
    '922_792': 35.4964,
    '922_793': 35.3752,
    '922_794': 35.2518,
    '922_795': 35.1272,
    '922_796': 35.002,
    '922_797': 34.8761,
    '922_798': 34.7485,
    '922_799': 34.6195,
    '922_800': 34.4897,
    '922_801': 34.3579,
    '922_802': 34.2291,
    '922_803': 34.1101,
    '922_804': 33.992,
    '922_805': 33.8694,
    '922_806': 33.7465,
    '922_807': 33.6253,
    '922_808': 33.5012,
    '922_809': 33.3662,
    '922_810': 33.2293,
    '922_811': 33.0929,
    '922_812': 32.9561,
    '922_813': 32.817,
    '922_814': 32.6739,
    '922_815': 32.5241,
    '922_816': 32.368,
    '922_817': 32.208,
    '922_818': 32.0429,
    '922_819': 31.8712,
    '922_820': 31.6925,
    '922_821': 31.5063,
    '922_822': 31.3113,
    '922_823': 31.1057,
    '922_824': 30.888,
    '922_825': 30.6518,
    '922_826': 30.4012,
    '922_827': 30.1476,
    '922_828': 29.8748,
    '923_472': 31.8992,
    '923_473': 31.9454,
    '923_474': 31.9981,
    '923_475': 32.0591,
    '923_476': 32.1218,
    '923_477': 32.1861,
    '923_478': 32.2543,
    '923_479': 32.3226,
    '923_480': 32.386,
    '923_481': 32.4412,
    '923_482': 32.5007,
    '923_483': 32.5633,
    '923_484': 32.6284,
    '923_485': 32.6944,
    '923_486': 32.7625,
    '923_487': 32.8323,
    '923_488': 32.9025,
    '923_489': 32.9697,
    '923_490': 33.0289,
    '923_491': 33.0836,
    '923_492': 33.1369,
    '923_493': 33.1904,
    '923_494': 33.2508,
    '923_495': 33.3141,
    '923_496': 33.3742,
    '923_497': 33.4355,
    '923_498': 33.5014,
    '923_499': 33.5666,
    '923_500': 33.6311,
    '923_501': 33.6969,
    '923_502': 33.7671,
    '923_503': 33.8405,
    '923_504': 33.9196,
    '923_505': 33.9992,
    '923_506': 34.0756,
    '923_507': 34.1508,
    '923_508': 34.2283,
    '923_509': 34.2995,
    '923_510': 34.3639,
    '923_511': 34.4225,
    '923_512': 34.4816,
    '923_513': 34.5437,
    '923_514': 34.6084,
    '923_515': 34.6744,
    '923_516': 34.7325,
    '923_517': 34.79,
    '923_518': 34.8506,
    '923_519': 34.9101,
    '923_520': 34.9649,
    '923_521': 35.0073,
    '923_522': 35.0431,
    '923_523': 35.0678,
    '923_524': 35.1008,
    '923_525': 35.1389,
    '923_526': 35.1723,
    '923_527': 35.189,
    '923_528': 35.1991,
    '923_529': 35.2242,
    '923_530': 35.2475,
    '923_531': 35.2802,
    '923_532': 35.3205,
    '923_533': 35.3564,
    '923_534': 35.3921,
    '923_535': 35.4434,
    '923_536': 35.5059,
    '923_537': 35.5795,
    '923_538': 35.6687,
    '923_539': 35.7668,
    '923_540': 35.8687,
    '923_541': 35.9611,
    '923_542': 36.0207,
    '923_543': 36.0487,
    '923_544': 36.0434,
    '923_545': 36.0158,
    '923_546': 35.9917,
    '923_547': 35.9715,
    '923_548': 35.9534,
    '923_549': 35.9432,
    '923_550': 35.9441,
    '923_551': 35.9622,
    '923_552': 35.9985,
    '923_553': 36.0466,
    '923_554': 36.0808,
    '923_555': 36.1261,
    '923_556': 36.1852,
    '923_557': 36.2558,
    '923_558': 36.3399,
    '923_559': 36.4156,
    '923_560': 36.4714,
    '923_561': 36.5068,
    '923_562': 36.5233,
    '923_563': 36.5115,
    '923_564': 36.4752,
    '923_565': 36.4355,
    '923_566': 36.417,
    '923_567': 36.4223,
    '923_568': 36.4445,
    '923_569': 36.4904,
    '923_570': 36.5496,
    '923_571': 36.6211,
    '923_572': 36.7049,
    '923_573': 36.7984,
    '923_574': 36.8861,
    '923_575': 36.975,
    '923_576': 37.0724,
    '923_577': 37.1609,
    '923_578': 37.2401,
    '923_579': 37.2947,
    '923_580': 37.3282,
    '923_581': 37.3323,
    '923_582': 37.3172,
    '923_583': 37.2885,
    '923_584': 37.2609,
    '923_585': 37.2362,
    '923_586': 37.1986,
    '923_587': 37.1439,
    '923_588': 37.0852,
    '923_589': 37.0373,
    '923_590': 37.0019,
    '923_591': 36.976,
    '923_592': 36.9661,
    '923_593': 36.9757,
    '923_594': 37.0001,
    '923_595': 37.0309,
    '923_596': 37.0664,
    '923_597': 37.0985,
    '923_598': 37.1087,
    '923_599': 37.1175,
    '923_600': 37.1204,
    '923_601': 37.1159,
    '923_602': 37.1015,
    '923_603': 37.0889,
    '923_604': 37.0708,
    '923_605': 37.0605,
    '923_606': 37.0549,
    '923_607': 37.0655,
    '923_608': 37.0835,
    '923_609': 37.1034,
    '923_610': 37.1211,
    '923_611': 37.1471,
    '923_612': 37.1632,
    '923_613': 37.1772,
    '923_614': 37.2083,
    '923_615': 37.2416,
    '923_616': 37.2547,
    '923_617': 37.2579,
    '923_618': 37.2651,
    '923_619': 37.2771,
    '923_620': 37.2948,
    '923_621': 37.2994,
    '923_622': 37.2792,
    '923_623': 37.2485,
    '923_624': 37.227,
    '923_625': 37.2117,
    '923_626': 37.1913,
    '923_627': 37.17,
    '923_628': 37.1726,
    '923_629': 37.173,
    '923_630': 37.1729,
    '923_631': 37.1747,
    '923_632': 37.16,
    '923_633': 37.1214,
    '923_634': 37.0637,
    '923_635': 36.9976,
    '923_636': 36.9255,
    '923_637': 36.8518,
    '923_638': 36.7758,
    '923_639': 36.6977,
    '923_640': 36.6263,
    '923_641': 36.5489,
    '923_642': 36.4825,
    '923_643': 36.4408,
    '923_644': 36.4279,
    '923_645': 36.4435,
    '923_646': 36.4855,
    '923_647': 36.5496,
    '923_648': 36.6285,
    '923_649': 36.7131,
    '923_650': 36.8035,
    '923_651': 36.8994,
    '923_652': 36.999,
    '923_653': 37.1035,
    '923_654': 37.2022,
    '923_655': 37.2962,
    '923_656': 37.3893,
    '923_657': 37.4538,
    '923_658': 37.4848,
    '923_659': 37.4835,
    '923_660': 37.4579,
    '923_661': 37.4404,
    '923_662': 37.4291,
    '923_663': 37.4232,
    '923_664': 37.4248,
    '923_665': 37.4436,
    '923_666': 37.4795,
    '923_667': 37.5298,
    '923_668': 37.5936,
    '923_669': 37.6677,
    '923_670': 37.7505,
    '923_671': 37.8405,
    '923_672': 37.938,
    '923_673': 38.0404,
    '923_674': 38.1481,
    '923_675': 38.2588,
    '923_676': 38.3723,
    '923_677': 38.4889,
    '923_678': 38.6082,
    '923_679': 38.7335,
    '923_680': 38.8595,
    '923_681': 38.9863,
    '923_682': 39.1109,
    '923_683': 39.2402,
    '923_684': 39.3771,
    '923_685': 39.5193,
    '923_686': 39.6541,
    '923_687': 39.7853,
    '923_688': 39.918,
    '923_689': 40.0553,
    '923_690': 40.198,
    '923_691': 40.3522,
    '923_692': 40.5225,
    '923_693': 40.6958,
    '923_694': 40.8594,
    '923_695': 41.0195,
    '923_696': 41.1778,
    '923_697': 41.3337,
    '923_698': 41.4966,
    '923_699': 41.6927,
    '923_700': 41.9101,
    '923_701': 42.1232,
    '923_702': 42.3153,
    '923_703': 42.4884,
    '923_704': 42.6387,
    '923_705': 42.7511,
    '923_706': 42.8282,
    '923_707': 42.8898,
    '923_708': 42.915,
    '923_709': 42.9317,
    '923_710': 42.9403,
    '923_711': 42.9416,
    '923_712': 42.964,
    '923_713': 43.0349,
    '923_714': 43.1383,
    '923_715': 43.2587,
    '923_716': 43.3912,
    '923_717': 43.5224,
    '923_718': 43.6371,
    '923_719': 43.7521,
    '923_720': 43.8697,
    '923_721': 43.9898,
    '923_722': 44.0948,
    '923_723': 44.189,
    '923_724': 44.2677,
    '923_725': 44.2824,
    '923_726': 44.2009,
    '923_727': 44.1627,
    '923_728': 44.1003,
    '923_729': 43.984,
    '923_730': 43.8317,
    '923_731': 43.6648,
    '923_732': 43.4409,
    '923_733': 43.2322,
    '923_734': 43.0272,
    '923_735': 42.8383,
    '923_736': 42.6437,
    '923_737': 42.4796,
    '923_738': 42.3838,
    '923_739': 42.4029,
    '923_740': 42.4486,
    '923_741': 42.4702,
    '923_742': 42.4557,
    '923_743': 42.4189,
    '923_744': 42.4161,
    '923_745': 42.4612,
    '923_746': 42.5459,
    '923_747': 42.6423,
    '923_748': 42.6954,
    '923_749': 42.6864,
    '923_750': 42.7016,
    '923_751': 42.6706,
    '923_752': 42.5611,
    '923_753': 42.4297,
    '923_754': 42.2911,
    '923_755': 42.1438,
    '923_756': 41.999,
    '923_757': 41.8367,
    '923_758': 41.6655,
    '923_759': 41.5013,
    '923_760': 41.3484,
    '923_761': 41.1897,
    '923_762': 41.0246,
    '923_763': 40.8546,
    '923_764': 40.6824,
    '923_765': 40.4886,
    '923_766': 40.2703,
    '923_767': 40.0328,
    '923_768': 39.7856,
    '923_769': 39.5319,
    '923_770': 39.2732,
    '923_771': 39.0013,
    '923_772': 38.7413,
    '923_773': 38.5027,
    '923_774': 38.2817,
    '923_775': 38.0664,
    '923_776': 37.8632,
    '923_777': 37.6709,
    '923_778': 37.4889,
    '923_779': 37.3139,
    '923_780': 37.1454,
    '923_781': 36.9822,
    '923_782': 36.824,
    '923_783': 36.6707,
    '923_784': 36.5196,
    '923_785': 36.3718,
    '923_786': 36.2341,
    '923_787': 36.1075,
    '923_788': 35.9731,
    '923_789': 35.8391,
    '923_790': 35.7197,
    '923_791': 35.6058,
    '923_792': 35.4894,
    '923_793': 35.3683,
    '923_794': 35.2444,
    '923_795': 35.1185,
    '923_796': 34.9911,
    '923_797': 34.8621,
    '923_798': 34.7323,
    '923_799': 34.6013,
    '923_800': 34.47,
    '923_801': 34.3385,
    '923_802': 34.2116,
    '923_803': 34.0927,
    '923_804': 33.9741,
    '923_805': 33.854,
    '923_806': 33.7322,
    '923_807': 33.6136,
    '923_808': 33.4896,
    '923_809': 33.357,
    '923_810': 33.2237,
    '923_811': 33.0914,
    '923_812': 32.959,
    '923_813': 32.8228,
    '923_814': 32.6836,
    '923_815': 32.5388,
    '923_816': 32.3885,
    '923_817': 32.2334,
    '923_818': 32.073,
    '923_819': 31.9061,
    '923_820': 31.7322,
    '923_821': 31.5508,
    '923_822': 31.361,
    '923_823': 31.1612,
    '923_824': 30.9498,
    '923_825': 30.7236,
    '923_826': 30.4824,
    '923_827': 30.2336,
    '923_828': 29.977,
    '924_472': 31.8523,
    '924_473': 31.8929,
    '924_474': 31.9421,
    '924_475': 32.0047,
    '924_476': 32.0724,
    '924_477': 32.142,
    '924_478': 32.211,
    '924_479': 32.2779,
    '924_480': 32.3367,
    '924_481': 32.3962,
    '924_482': 32.4592,
    '924_483': 32.5252,
    '924_484': 32.5938,
    '924_485': 32.6628,
    '924_486': 32.7332,
    '924_487': 32.8042,
    '924_488': 32.8744,
    '924_489': 32.9392,
    '924_490': 32.9998,
    '924_491': 33.0596,
    '924_492': 33.1206,
    '924_493': 33.1814,
    '924_494': 33.2436,
    '924_495': 33.3058,
    '924_496': 33.3662,
    '924_497': 33.425,
    '924_498': 33.4864,
    '924_499': 33.5487,
    '924_500': 33.6109,
    '924_501': 33.6744,
    '924_502': 33.746,
    '924_503': 33.8225,
    '924_504': 33.9047,
    '924_505': 33.9899,
    '924_506': 34.0754,
    '924_507': 34.1624,
    '924_508': 34.2469,
    '924_509': 34.3188,
    '924_510': 34.3751,
    '924_511': 34.4126,
    '924_512': 34.452,
    '924_513': 34.5014,
    '924_514': 34.5549,
    '924_515': 34.6111,
    '924_516': 34.6665,
    '924_517': 34.7219,
    '924_518': 34.7774,
    '924_519': 34.8346,
    '924_520': 34.8871,
    '924_521': 34.9297,
    '924_522': 34.9637,
    '924_523': 34.9886,
    '924_524': 35.0127,
    '924_525': 35.0485,
    '924_526': 35.085,
    '924_527': 35.111,
    '924_528': 35.1218,
    '924_529': 35.1421,
    '924_530': 35.1709,
    '924_531': 35.2055,
    '924_532': 35.2418,
    '924_533': 35.2772,
    '924_534': 35.317,
    '924_535': 35.3685,
    '924_536': 35.4287,
    '924_537': 35.502,
    '924_538': 35.5888,
    '924_539': 35.6802,
    '924_540': 35.7726,
    '924_541': 35.8576,
    '924_542': 35.9191,
    '924_543': 35.9465,
    '924_544': 35.9413,
    '924_545': 35.9161,
    '924_546': 35.8916,
    '924_547': 35.8758,
    '924_548': 35.8622,
    '924_549': 35.854,
    '924_550': 35.8527,
    '924_551': 35.864,
    '924_552': 35.8911,
    '924_553': 35.9337,
    '924_554': 35.9708,
    '924_555': 36.0121,
    '924_556': 36.0727,
    '924_557': 36.1382,
    '924_558': 36.2202,
    '924_559': 36.2954,
    '924_560': 36.3458,
    '924_561': 36.3774,
    '924_562': 36.4045,
    '924_563': 36.3995,
    '924_564': 36.3724,
    '924_565': 36.3441,
    '924_566': 36.3371,
    '924_567': 36.3477,
    '924_568': 36.3656,
    '924_569': 36.4108,
    '924_570': 36.469,
    '924_571': 36.5469,
    '924_572': 36.6377,
    '924_573': 36.7274,
    '924_574': 36.824,
    '924_575': 36.9245,
    '924_576': 37.0242,
    '924_577': 37.1071,
    '924_578': 37.1766,
    '924_579': 37.2234,
    '924_580': 37.2435,
    '924_581': 37.2461,
    '924_582': 37.242,
    '924_583': 37.2255,
    '924_584': 37.2081,
    '924_585': 37.2035,
    '924_586': 37.1787,
    '924_587': 37.1217,
    '924_588': 37.0576,
    '924_589': 37.004,
    '924_590': 36.9577,
    '924_591': 36.9314,
    '924_592': 36.9326,
    '924_593': 36.9525,
    '924_594': 36.9756,
    '924_595': 37.0054,
    '924_596': 37.0454,
    '924_597': 37.0726,
    '924_598': 37.078,
    '924_599': 37.0843,
    '924_600': 37.0824,
    '924_601': 37.0899,
    '924_602': 37.0894,
    '924_603': 37.0857,
    '924_604': 37.0773,
    '924_605': 37.0657,
    '924_606': 37.0497,
    '924_607': 37.0549,
    '924_608': 37.0632,
    '924_609': 37.084,
    '924_610': 37.1035,
    '924_611': 37.1212,
    '924_612': 37.135,
    '924_613': 37.1423,
    '924_614': 37.1675,
    '924_615': 37.2065,
    '924_616': 37.2282,
    '924_617': 37.2379,
    '924_618': 37.2429,
    '924_619': 37.2353,
    '924_620': 37.2402,
    '924_621': 37.2398,
    '924_622': 37.2184,
    '924_623': 37.1868,
    '924_624': 37.156,
    '924_625': 37.1312,
    '924_626': 37.1079,
    '924_627': 37.0902,
    '924_628': 37.0923,
    '924_629': 37.1019,
    '924_630': 37.1088,
    '924_631': 37.1141,
    '924_632': 37.1044,
    '924_633': 37.0771,
    '924_634': 37.0322,
    '924_635': 36.9767,
    '924_636': 36.9134,
    '924_637': 36.8499,
    '924_638': 36.7805,
    '924_639': 36.7091,
    '924_640': 36.634,
    '924_641': 36.5567,
    '924_642': 36.493,
    '924_643': 36.4548,
    '924_644': 36.4458,
    '924_645': 36.4673,
    '924_646': 36.5111,
    '924_647': 36.5746,
    '924_648': 36.6523,
    '924_649': 36.7382,
    '924_650': 36.8303,
    '924_651': 36.9288,
    '924_652': 37.0312,
    '924_653': 37.1393,
    '924_654': 37.2501,
    '924_655': 37.3661,
    '924_656': 37.4783,
    '924_657': 37.5425,
    '924_658': 37.5681,
    '924_659': 37.5626,
    '924_660': 37.5357,
    '924_661': 37.515,
    '924_662': 37.4985,
    '924_663': 37.4816,
    '924_664': 37.4751,
    '924_665': 37.4947,
    '924_666': 37.5312,
    '924_667': 37.5804,
    '924_668': 37.6431,
    '924_669': 37.7167,
    '924_670': 37.7992,
    '924_671': 37.8889,
    '924_672': 37.9847,
    '924_673': 38.0856,
    '924_674': 38.191,
    '924_675': 38.3009,
    '924_676': 38.4149,
    '924_677': 38.5309,
    '924_678': 38.6493,
    '924_679': 38.7705,
    '924_680': 38.8848,
    '924_681': 38.9999,
    '924_682': 39.1216,
    '924_683': 39.25,
    '924_684': 39.3885,
    '924_685': 39.5342,
    '924_686': 39.6699,
    '924_687': 39.806,
    '924_688': 39.9446,
    '924_689': 40.0832,
    '924_690': 40.2212,
    '924_691': 40.3681,
    '924_692': 40.521,
    '924_693': 40.6829,
    '924_694': 40.8433,
    '924_695': 41.0056,
    '924_696': 41.1646,
    '924_697': 41.3176,
    '924_698': 41.4784,
    '924_699': 41.6692,
    '924_700': 41.8927,
    '924_701': 42.1116,
    '924_702': 42.3091,
    '924_703': 42.4895,
    '924_704': 42.651,
    '924_705': 42.7535,
    '924_706': 42.8261,
    '924_707': 42.8805,
    '924_708': 42.9007,
    '924_709': 42.9144,
    '924_710': 42.9206,
    '924_711': 42.9267,
    '924_712': 42.9596,
    '924_713': 43.038,
    '924_714': 43.1479,
    '924_715': 43.2745,
    '924_716': 43.4098,
    '924_717': 43.5474,
    '924_718': 43.6706,
    '924_719': 43.7937,
    '924_720': 43.8992,
    '924_721': 44.0278,
    '924_722': 44.1271,
    '924_723': 44.2295,
    '924_724': 44.2995,
    '924_725': 44.3539,
    '924_726': 44.2923,
    '924_727': 44.2707,
    '924_728': 44.1853,
    '924_729': 44.0566,
    '924_730': 43.8865,
    '924_731': 43.7238,
    '924_732': 43.5072,
    '924_733': 43.2607,
    '924_734': 43.05,
    '924_735': 42.849,
    '924_736': 42.6719,
    '924_737': 42.5297,
    '924_738': 42.4353,
    '924_739': 42.4577,
    '924_740': 42.5243,
    '924_741': 42.5608,
    '924_742': 42.5614,
    '924_743': 42.5298,
    '924_744': 42.5157,
    '924_745': 42.5478,
    '924_746': 42.6149,
    '924_747': 42.6908,
    '924_748': 42.7464,
    '924_749': 42.7752,
    '924_750': 42.7678,
    '924_751': 42.703,
    '924_752': 42.6034,
    '924_753': 42.4855,
    '924_754': 42.3637,
    '924_755': 42.2428,
    '924_756': 42.1161,
    '924_757': 41.9744,
    '924_758': 41.8128,
    '924_759': 41.6435,
    '924_760': 41.4834,
    '924_761': 41.3223,
    '924_762': 41.1564,
    '924_763': 41.0048,
    '924_764': 40.8444,
    '924_765': 40.6526,
    '924_766': 40.4325,
    '924_767': 40.1944,
    '924_768': 39.9472,
    '924_769': 39.6888,
    '924_770': 39.4139,
    '924_771': 39.1171,
    '924_772': 38.8364,
    '924_773': 38.5889,
    '924_774': 38.3582,
    '924_775': 38.1298,
    '924_776': 37.9128,
    '924_777': 37.7085,
    '924_778': 37.5149,
    '924_779': 37.33,
    '924_780': 37.154,
    '924_781': 36.9848,
    '924_782': 36.8215,
    '924_783': 36.665,
    '924_784': 36.5109,
    '924_785': 36.3629,
    '924_786': 36.2253,
    '924_787': 36.0976,
    '924_788': 35.9739,
    '924_789': 35.8342,
    '924_790': 35.7155,
    '924_791': 35.6031,
    '924_792': 35.4889,
    '924_793': 35.3679,
    '924_794': 35.2432,
    '924_795': 35.1164,
    '924_796': 34.9881,
    '924_797': 34.8579,
    '924_798': 34.7263,
    '924_799': 34.5941,
    '924_800': 34.4626,
    '924_801': 34.3324,
    '924_802': 34.2067,
    '924_803': 34.0871,
    '924_804': 33.9685,
    '924_805': 33.8479,
    '924_806': 33.7302,
    '924_807': 33.6129,
    '924_808': 33.4885,
    '924_809': 33.359,
    '924_810': 33.2279,
    '924_811': 33.0982,
    '924_812': 32.9691,
    '924_813': 32.8369,
    '924_814': 32.7016,
    '924_815': 32.5613,
    '924_816': 32.4153,
    '924_817': 32.2644,
    '924_818': 32.1083,
    '924_819': 31.9456,
    '924_820': 31.776,
    '924_821': 31.5991,
    '924_822': 31.4139,
    '924_823': 31.2192,
    '924_824': 31.0138,
    '924_825': 30.7962,
    '924_826': 30.5647,
    '924_827': 30.323,
    '924_828': 30.0725,
    '925_472': 31.8071,
    '925_473': 31.8436,
    '925_474': 31.8924,
    '925_475': 31.9552,
    '925_476': 32.0226,
    '925_477': 32.0907,
    '925_478': 32.1623,
    '925_479': 32.2266,
    '925_480': 32.2863,
    '925_481': 32.3491,
    '925_482': 32.4146,
    '925_483': 32.4828,
    '925_484': 32.554,
    '925_485': 32.6253,
    '925_486': 32.6967,
    '925_487': 32.7673,
    '925_488': 32.8363,
    '925_489': 32.8998,
    '925_490': 32.9618,
    '925_491': 33.0258,
    '925_492': 33.0925,
    '925_493': 33.1603,
    '925_494': 33.2278,
    '925_495': 33.2917,
    '925_496': 33.3526,
    '925_497': 33.4097,
    '925_498': 33.4693,
    '925_499': 33.5311,
    '925_500': 33.5937,
    '925_501': 33.657,
    '925_502': 33.7264,
    '925_503': 33.8026,
    '925_504': 33.8866,
    '925_505': 33.9797,
    '925_506': 34.0781,
    '925_507': 34.1714,
    '925_508': 34.2528,
    '925_509': 34.3193,
    '925_510': 34.3775,
    '925_511': 34.4156,
    '925_512': 34.435,
    '925_513': 34.4676,
    '925_514': 34.5118,
    '925_515': 34.5591,
    '925_516': 34.6071,
    '925_517': 34.6557,
    '925_518': 34.7049,
    '925_519': 34.7558,
    '925_520': 34.8041,
    '925_521': 34.8499,
    '925_522': 34.8841,
    '925_523': 34.9068,
    '925_524': 34.9259,
    '925_525': 34.9542,
    '925_526': 34.9892,
    '925_527': 35.0226,
    '925_528': 35.0417,
    '925_529': 35.064,
    '925_530': 35.0951,
    '925_531': 35.1283,
    '925_532': 35.1605,
    '925_533': 35.1987,
    '925_534': 35.2427,
    '925_535': 35.2925,
    '925_536': 35.3495,
    '925_537': 35.4189,
    '925_538': 35.5019,
    '925_539': 35.5888,
    '925_540': 35.6684,
    '925_541': 35.7396,
    '925_542': 35.7961,
    '925_543': 35.8272,
    '925_544': 35.8256,
    '925_545': 35.8072,
    '925_546': 35.7867,
    '925_547': 35.7747,
    '925_548': 35.767,
    '925_549': 35.762,
    '925_550': 35.7626,
    '925_551': 35.7711,
    '925_552': 35.7918,
    '925_553': 35.8274,
    '925_554': 35.864,
    '925_555': 35.9133,
    '925_556': 35.9751,
    '925_557': 36.0383,
    '925_558': 36.114,
    '925_559': 36.1803,
    '925_560': 36.2258,
    '925_561': 36.253,
    '925_562': 36.283,
    '925_563': 36.2817,
    '925_564': 36.2667,
    '925_565': 36.2591,
    '925_566': 36.26,
    '925_567': 36.2697,
    '925_568': 36.2891,
    '925_569': 36.3362,
    '925_570': 36.3929,
    '925_571': 36.4639,
    '925_572': 36.5501,
    '925_573': 36.6455,
    '925_574': 36.757,
    '925_575': 36.8635,
    '925_576': 36.9622,
    '925_577': 37.0452,
    '925_578': 37.1098,
    '925_579': 37.1455,
    '925_580': 37.1542,
    '925_581': 37.1504,
    '925_582': 37.1574,
    '925_583': 37.1565,
    '925_584': 37.1483,
    '925_585': 37.1605,
    '925_586': 37.1453,
    '925_587': 37.0868,
    '925_588': 37.019,
    '925_589': 36.965,
    '925_590': 36.9203,
    '925_591': 36.8908,
    '925_592': 36.8859,
    '925_593': 36.9105,
    '925_594': 36.9378,
    '925_595': 36.9634,
    '925_596': 37.0036,
    '925_597': 37.0261,
    '925_598': 37.0236,
    '925_599': 37.0304,
    '925_600': 37.0353,
    '925_601': 37.0501,
    '925_602': 37.0639,
    '925_603': 37.065,
    '925_604': 37.0662,
    '925_605': 37.0595,
    '925_606': 37.046,
    '925_607': 37.0428,
    '925_608': 37.0386,
    '925_609': 37.0469,
    '925_610': 37.0618,
    '925_611': 37.0762,
    '925_612': 37.0848,
    '925_613': 37.0889,
    '925_614': 37.1125,
    '925_615': 37.1492,
    '925_616': 37.1818,
    '925_617': 37.2009,
    '925_618': 37.2078,
    '925_619': 37.196,
    '925_620': 37.1897,
    '925_621': 37.1754,
    '925_622': 37.1534,
    '925_623': 37.1255,
    '925_624': 37.0952,
    '925_625': 37.0672,
    '925_626': 37.0407,
    '925_627': 37.0219,
    '925_628': 37.0245,
    '925_629': 37.0438,
    '925_630': 37.0492,
    '925_631': 37.0453,
    '925_632': 37.04,
    '925_633': 37.0235,
    '925_634': 36.9912,
    '925_635': 36.9433,
    '925_636': 36.8935,
    '925_637': 36.8451,
    '925_638': 36.7902,
    '925_639': 36.7322,
    '925_640': 36.6498,
    '925_641': 36.5701,
    '925_642': 36.5097,
    '925_643': 36.4786,
    '925_644': 36.4774,
    '925_645': 36.5032,
    '925_646': 36.5489,
    '925_647': 36.6084,
    '925_648': 36.6837,
    '925_649': 36.7696,
    '925_650': 36.8649,
    '925_651': 36.9669,
    '925_652': 37.0738,
    '925_653': 37.1893,
    '925_654': 37.3181,
    '925_655': 37.4441,
    '925_656': 37.5561,
    '925_657': 37.6215,
    '925_658': 37.6421,
    '925_659': 37.6436,
    '925_660': 37.6271,
    '925_661': 37.6054,
    '925_662': 37.5679,
    '925_663': 37.5314,
    '925_664': 37.5275,
    '925_665': 37.5485,
    '925_666': 37.5853,
    '925_667': 37.6346,
    '925_668': 37.6965,
    '925_669': 37.7692,
    '925_670': 37.8517,
    '925_671': 37.9421,
    '925_672': 38.0367,
    '925_673': 38.1351,
    '925_674': 38.2374,
    '925_675': 38.3459,
    '925_676': 38.4593,
    '925_677': 38.5778,
    '925_678': 38.692,
    '925_679': 38.8028,
    '925_680': 38.913,
    '925_681': 39.0205,
    '925_682': 39.1385,
    '925_683': 39.2661,
    '925_684': 39.4049,
    '925_685': 39.5466,
    '925_686': 39.6824,
    '925_687': 39.825,
    '925_688': 39.9701,
    '925_689': 40.113,
    '925_690': 40.2529,
    '925_691': 40.3985,
    '925_692': 40.5396,
    '925_693': 40.6811,
    '925_694': 40.8299,
    '925_695': 40.9841,
    '925_696': 41.1409,
    '925_697': 41.2944,
    '925_698': 41.4583,
    '925_699': 41.6487,
    '925_700': 41.8663,
    '925_701': 42.0846,
    '925_702': 42.303,
    '925_703': 42.4937,
    '925_704': 42.6412,
    '925_705': 42.7471,
    '925_706': 42.8151,
    '925_707': 42.8566,
    '925_708': 42.8788,
    '925_709': 42.8879,
    '925_710': 42.8933,
    '925_711': 42.9098,
    '925_712': 42.95,
    '925_713': 43.0292,
    '925_714': 43.1429,
    '925_715': 43.2767,
    '925_716': 43.4216,
    '925_717': 43.5632,
    '925_718': 43.691,
    '925_719': 43.8305,
    '925_720': 43.9429,
    '925_721': 44.0588,
    '925_722': 44.1813,
    '925_723': 44.2621,
    '925_724': 44.3394,
    '925_725': 44.3709,
    '925_726': 44.3357,
    '925_727': 44.3406,
    '925_728': 44.2582,
    '925_729': 44.1341,
    '925_730': 43.9446,
    '925_731': 43.7673,
    '925_732': 43.5547,
    '925_733': 43.3027,
    '925_734': 43.0671,
    '925_735': 42.8639,
    '925_736': 42.6928,
    '925_737': 42.5572,
    '925_738': 42.472,
    '925_739': 42.4972,
    '925_740': 42.5697,
    '925_741': 42.616,
    '925_742': 42.6239,
    '925_743': 42.6233,
    '925_744': 42.6033,
    '925_745': 42.619,
    '925_746': 42.6672,
    '925_747': 42.7205,
    '925_748': 42.7658,
    '925_749': 42.7894,
    '925_750': 42.7703,
    '925_751': 42.7066,
    '925_752': 42.6207,
    '925_753': 42.5199,
    '925_754': 42.4206,
    '925_755': 42.3219,
    '925_756': 42.2154,
    '925_757': 42.0915,
    '925_758': 41.9436,
    '925_759': 41.7789,
    '925_760': 41.6179,
    '925_761': 41.4573,
    '925_762': 41.2938,
    '925_763': 41.1514,
    '925_764': 41.0006,
    '925_765': 40.8166,
    '925_766': 40.6033,
    '925_767': 40.3695,
    '925_768': 40.1194,
    '925_769': 39.8491,
    '925_770': 39.5501,
    '925_771': 39.2285,
    '925_772': 38.93,
    '925_773': 38.6752,
    '925_774': 38.4333,
    '925_775': 38.1937,
    '925_776': 37.9636,
    '925_777': 37.7473,
    '925_778': 37.5443,
    '925_779': 37.3514,
    '925_780': 37.1686,
    '925_781': 36.9942,
    '925_782': 36.8262,
    '925_783': 36.6666,
    '925_784': 36.51,
    '925_785': 36.3625,
    '925_786': 36.2232,
    '925_787': 36.0917,
    '925_788': 35.9661,
    '925_789': 35.8383,
    '925_790': 35.7218,
    '925_791': 35.6068,
    '925_792': 35.4955,
    '925_793': 35.3731,
    '925_794': 35.2478,
    '925_795': 35.1208,
    '925_796': 34.9925,
    '925_797': 34.8622,
    '925_798': 34.7303,
    '925_799': 34.5987,
    '925_800': 34.4682,
    '925_801': 34.3389,
    '925_802': 34.2133,
    '925_803': 34.092,
    '925_804': 33.9727,
    '925_805': 33.8534,
    '925_806': 33.7416,
    '925_807': 33.625,
    '925_808': 33.4988,
    '925_809': 33.3706,
    '925_810': 33.2417,
    '925_811': 33.114,
    '925_812': 32.9877,
    '925_813': 32.8594,
    '925_814': 32.7274,
    '925_815': 32.5905,
    '925_816': 32.4483,
    '925_817': 32.3012,
    '925_818': 32.1487,
    '925_819': 31.9899,
    '925_820': 31.8244,
    '925_821': 31.6516,
    '925_822': 31.4709,
    '925_823': 31.2809,
    '925_824': 31.0813,
    '925_825': 30.8708,
    '925_826': 30.6482,
    '925_827': 30.4126,
    '925_828': 30.1669,
    '926_472': 31.7669,
    '926_473': 31.8011,
    '926_474': 31.8535,
    '926_475': 31.9159,
    '926_476': 31.9818,
    '926_477': 32.048,
    '926_478': 32.1173,
    '926_479': 32.174,
    '926_480': 32.2372,
    '926_481': 32.303,
    '926_482': 32.3697,
    '926_483': 32.4413,
    '926_484': 32.5139,
    '926_485': 32.5857,
    '926_486': 32.6566,
    '926_487': 32.7245,
    '926_488': 32.7908,
    '926_489': 32.8543,
    '926_490': 32.9178,
    '926_491': 32.9856,
    '926_492': 33.0561,
    '926_493': 33.1285,
    '926_494': 33.1996,
    '926_495': 33.2671,
    '926_496': 33.3267,
    '926_497': 33.3846,
    '926_498': 33.4457,
    '926_499': 33.5095,
    '926_500': 33.574,
    '926_501': 33.6357,
    '926_502': 33.7016,
    '926_503': 33.7745,
    '926_504': 33.8572,
    '926_505': 33.9491,
    '926_506': 34.0449,
    '926_507': 34.1375,
    '926_508': 34.2142,
    '926_509': 34.2822,
    '926_510': 34.3402,
    '926_511': 34.3844,
    '926_512': 34.4085,
    '926_513': 34.4384,
    '926_514': 34.4763,
    '926_515': 34.5176,
    '926_516': 34.5606,
    '926_517': 34.6038,
    '926_518': 34.6461,
    '926_519': 34.6877,
    '926_520': 34.7285,
    '926_521': 34.7684,
    '926_522': 34.8017,
    '926_523': 34.8267,
    '926_524': 34.8467,
    '926_525': 34.87,
    '926_526': 34.8993,
    '926_527': 34.933,
    '926_528': 34.9599,
    '926_529': 34.9882,
    '926_530': 35.0199,
    '926_531': 35.051,
    '926_532': 35.0819,
    '926_533': 35.1201,
    '926_534': 35.1643,
    '926_535': 35.2122,
    '926_536': 35.2677,
    '926_537': 35.3353,
    '926_538': 35.4169,
    '926_539': 35.4995,
    '926_540': 35.5618,
    '926_541': 35.6192,
    '926_542': 35.6703,
    '926_543': 35.7009,
    '926_544': 35.705,
    '926_545': 35.694,
    '926_546': 35.6806,
    '926_547': 35.672,
    '926_548': 35.671,
    '926_549': 35.6686,
    '926_550': 35.6722,
    '926_551': 35.6816,
    '926_552': 35.7,
    '926_553': 35.7288,
    '926_554': 35.7641,
    '926_555': 35.8192,
    '926_556': 35.8791,
    '926_557': 35.938,
    '926_558': 36.0116,
    '926_559': 36.0728,
    '926_560': 36.1129,
    '926_561': 36.1323,
    '926_562': 36.1571,
    '926_563': 36.1611,
    '926_564': 36.1646,
    '926_565': 36.1677,
    '926_566': 36.1803,
    '926_567': 36.1953,
    '926_568': 36.2104,
    '926_569': 36.2547,
    '926_570': 36.3109,
    '926_571': 36.3794,
    '926_572': 36.4646,
    '926_573': 36.5632,
    '926_574': 36.6761,
    '926_575': 36.7872,
    '926_576': 36.8883,
    '926_577': 36.9726,
    '926_578': 37.031,
    '926_579': 37.0635,
    '926_580': 37.069,
    '926_581': 37.0592,
    '926_582': 37.0714,
    '926_583': 37.0817,
    '926_584': 37.0849,
    '926_585': 37.1074,
    '926_586': 37.0987,
    '926_587': 37.0423,
    '926_588': 36.9699,
    '926_589': 36.9146,
    '926_590': 36.8753,
    '926_591': 36.8417,
    '926_592': 36.8366,
    '926_593': 36.8592,
    '926_594': 36.8842,
    '926_595': 36.9054,
    '926_596': 36.9386,
    '926_597': 36.9582,
    '926_598': 36.9586,
    '926_599': 36.9723,
    '926_600': 36.9899,
    '926_601': 37.0174,
    '926_602': 37.0366,
    '926_603': 37.0421,
    '926_604': 37.053,
    '926_605': 37.0544,
    '926_606': 37.0394,
    '926_607': 37.0279,
    '926_608': 37.0146,
    '926_609': 37.0093,
    '926_610': 37.0156,
    '926_611': 37.0227,
    '926_612': 37.0269,
    '926_613': 37.0318,
    '926_614': 37.0571,
    '926_615': 37.0902,
    '926_616': 37.1157,
    '926_617': 37.1413,
    '926_618': 37.1593,
    '926_619': 37.1534,
    '926_620': 37.1337,
    '926_621': 37.1,
    '926_622': 37.0761,
    '926_623': 37.0552,
    '926_624': 37.0276,
    '926_625': 37.0028,
    '926_626': 36.9816,
    '926_627': 36.9688,
    '926_628': 36.9609,
    '926_629': 36.9739,
    '926_630': 36.9815,
    '926_631': 36.9755,
    '926_632': 36.9691,
    '926_633': 36.9564,
    '926_634': 36.9375,
    '926_635': 36.9018,
    '926_636': 36.8682,
    '926_637': 36.8398,
    '926_638': 36.8103,
    '926_639': 36.7683,
    '926_640': 36.6931,
    '926_641': 36.6,
    '926_642': 36.5393,
    '926_643': 36.5154,
    '926_644': 36.5209,
    '926_645': 36.5497,
    '926_646': 36.594,
    '926_647': 36.6489,
    '926_648': 36.7232,
    '926_649': 36.8111,
    '926_650': 36.9113,
    '926_651': 37.0181,
    '926_652': 37.1299,
    '926_653': 37.2528,
    '926_654': 37.3873,
    '926_655': 37.5115,
    '926_656': 37.624,
    '926_657': 37.6872,
    '926_658': 37.7061,
    '926_659': 37.717,
    '926_660': 37.7089,
    '926_661': 37.6853,
    '926_662': 37.6266,
    '926_663': 37.5853,
    '926_664': 37.5853,
    '926_665': 37.6069,
    '926_666': 37.6446,
    '926_667': 37.6946,
    '926_668': 37.7549,
    '926_669': 37.8257,
    '926_670': 37.9064,
    '926_671': 37.9966,
    '926_672': 38.09,
    '926_673': 38.1871,
    '926_674': 38.287,
    '926_675': 38.3975,
    '926_676': 38.5085,
    '926_677': 38.6222,
    '926_678': 38.7302,
    '926_679': 38.8339,
    '926_680': 38.9381,
    '926_681': 39.0469,
    '926_682': 39.1661,
    '926_683': 39.2923,
    '926_684': 39.4261,
    '926_685': 39.5661,
    '926_686': 39.7089,
    '926_687': 39.853,
    '926_688': 40.001,
    '926_689': 40.1454,
    '926_690': 40.2867,
    '926_691': 40.4316,
    '926_692': 40.5667,
    '926_693': 40.6955,
    '926_694': 40.8261,
    '926_695': 40.9673,
    '926_696': 41.1118,
    '926_697': 41.2619,
    '926_698': 41.4313,
    '926_699': 41.6243,
    '926_700': 41.832,
    '926_701': 42.0482,
    '926_702': 42.2657,
    '926_703': 42.4548,
    '926_704': 42.5995,
    '926_705': 42.728,
    '926_706': 42.8029,
    '926_707': 42.832,
    '926_708': 42.852,
    '926_709': 42.8637,
    '926_710': 42.8763,
    '926_711': 42.9029,
    '926_712': 42.9448,
    '926_713': 43.0195,
    '926_714': 43.1323,
    '926_715': 43.2677,
    '926_716': 43.4149,
    '926_717': 43.5659,
    '926_718': 43.7085,
    '926_719': 43.8548,
    '926_720': 43.9941,
    '926_721': 44.1011,
    '926_722': 44.2324,
    '926_723': 44.3129,
    '926_724': 44.3795,
    '926_725': 44.4088,
    '926_726': 44.4325,
    '926_727': 44.4019,
    '926_728': 44.3208,
    '926_729': 44.1961,
    '926_730': 44.0093,
    '926_731': 43.8558,
    '926_732': 43.6279,
    '926_733': 43.3618,
    '926_734': 43.1152,
    '926_735': 42.9132,
    '926_736': 42.7293,
    '926_737': 42.5806,
    '926_738': 42.5016,
    '926_739': 42.5199,
    '926_740': 42.591,
    '926_741': 42.6535,
    '926_742': 42.6881,
    '926_743': 42.6857,
    '926_744': 42.6754,
    '926_745': 42.6797,
    '926_746': 42.7071,
    '926_747': 42.7439,
    '926_748': 42.77,
    '926_749': 42.7754,
    '926_750': 42.7506,
    '926_751': 42.6985,
    '926_752': 42.6294,
    '926_753': 42.5501,
    '926_754': 42.4736,
    '926_755': 42.3904,
    '926_756': 42.2984,
    '926_757': 42.1897,
    '926_758': 42.0588,
    '926_759': 41.9044,
    '926_760': 41.7462,
    '926_761': 41.5862,
    '926_762': 41.4233,
    '926_763': 41.2836,
    '926_764': 41.1406,
    '926_765': 40.9685,
    '926_766': 40.7656,
    '926_767': 40.5343,
    '926_768': 40.2713,
    '926_769': 39.9848,
    '926_770': 39.6697,
    '926_771': 39.3303,
    '926_772': 39.0174,
    '926_773': 38.7532,
    '926_774': 38.5014,
    '926_775': 38.2505,
    '926_776': 38.0122,
    '926_777': 37.7897,
    '926_778': 37.5796,
    '926_779': 37.3801,
    '926_780': 37.1917,
    '926_781': 37.0123,
    '926_782': 36.841,
    '926_783': 36.6771,
    '926_784': 36.5183,
    '926_785': 36.3687,
    '926_786': 36.2293,
    '926_787': 36.0981,
    '926_788': 35.977,
    '926_789': 35.8623,
    '926_790': 35.7372,
    '926_791': 35.6187,
    '926_792': 35.5051,
    '926_793': 35.3825,
    '926_794': 35.259,
    '926_795': 35.1331,
    '926_796': 35.0056,
    '926_797': 34.8764,
    '926_798': 34.745,
    '926_799': 34.6147,
    '926_800': 34.4849,
    '926_801': 34.3558,
    '926_802': 34.2298,
    '926_803': 34.108,
    '926_804': 33.9873,
    '926_805': 33.8706,
    '926_806': 33.761,
    '926_807': 33.6465,
    '926_808': 33.5227,
    '926_809': 33.3951,
    '926_810': 33.2681,
    '926_811': 33.1425,
    '926_812': 33.0179,
    '926_813': 32.892,
    '926_814': 32.7626,
    '926_815': 32.6282,
    '926_816': 32.4893,
    '926_817': 32.3453,
    '926_818': 32.1961,
    '926_819': 32.0409,
    '926_820': 31.8792,
    '926_821': 31.7104,
    '926_822': 31.5338,
    '926_823': 31.3484,
    '926_824': 31.1538,
    '926_825': 30.9494,
    '926_826': 30.734,
    '926_827': 30.5069,
    '926_828': 30.2671,
    '927_472': 31.7349,
    '927_473': 31.7716,
    '927_474': 31.8241,
    '927_475': 31.8842,
    '927_476': 31.9468,
    '927_477': 32.0068,
    '927_478': 32.0622,
    '927_479': 32.1239,
    '927_480': 32.1914,
    '927_481': 32.2612,
    '927_482': 32.3308,
    '927_483': 32.4024,
    '927_484': 32.4742,
    '927_485': 32.5447,
    '927_486': 32.6135,
    '927_487': 32.679,
    '927_488': 32.743,
    '927_489': 32.8067,
    '927_490': 32.8719,
    '927_491': 32.9411,
    '927_492': 33.0128,
    '927_493': 33.087,
    '927_494': 33.1659,
    '927_495': 33.2339,
    '927_496': 33.2906,
    '927_497': 33.3485,
    '927_498': 33.4119,
    '927_499': 33.478,
    '927_500': 33.5429,
    '927_501': 33.6039,
    '927_502': 33.667,
    '927_503': 33.7354,
    '927_504': 33.8146,
    '927_505': 33.9004,
    '927_506': 33.9875,
    '927_507': 34.0725,
    '927_508': 34.1482,
    '927_509': 34.2167,
    '927_510': 34.2807,
    '927_511': 34.338,
    '927_512': 34.3747,
    '927_513': 34.4072,
    '927_514': 34.4447,
    '927_515': 34.4831,
    '927_516': 34.5225,
    '927_517': 34.5617,
    '927_518': 34.5997,
    '927_519': 34.6359,
    '927_520': 34.6709,
    '927_521': 34.7044,
    '927_522': 34.7346,
    '927_523': 34.761,
    '927_524': 34.782,
    '927_525': 34.8017,
    '927_526': 34.8245,
    '927_527': 34.8528,
    '927_528': 34.8826,
    '927_529': 34.914,
    '927_530': 34.9441,
    '927_531': 34.9739,
    '927_532': 35.0065,
    '927_533': 35.0443,
    '927_534': 35.0843,
    '927_535': 35.1299,
    '927_536': 35.1836,
    '927_537': 35.2458,
    '927_538': 35.321,
    '927_539': 35.3973,
    '927_540': 35.4517,
    '927_541': 35.503,
    '927_542': 35.5503,
    '927_543': 35.5764,
    '927_544': 35.5846,
    '927_545': 35.5787,
    '927_546': 35.5707,
    '927_547': 35.5663,
    '927_548': 35.5698,
    '927_549': 35.5745,
    '927_550': 35.5806,
    '927_551': 35.5927,
    '927_552': 35.6124,
    '927_553': 35.6372,
    '927_554': 35.6676,
    '927_555': 35.7153,
    '927_556': 35.7705,
    '927_557': 35.83,
    '927_558': 35.9036,
    '927_559': 35.9566,
    '927_560': 35.9973,
    '927_561': 36.0107,
    '927_562': 36.0241,
    '927_563': 36.0386,
    '927_564': 36.0612,
    '927_565': 36.0783,
    '927_566': 36.0967,
    '927_567': 36.1154,
    '927_568': 36.1321,
    '927_569': 36.1717,
    '927_570': 36.2266,
    '927_571': 36.2981,
    '927_572': 36.3841,
    '927_573': 36.4867,
    '927_574': 36.5936,
    '927_575': 36.7038,
    '927_576': 36.8066,
    '927_577': 36.8882,
    '927_578': 36.9448,
    '927_579': 36.9755,
    '927_580': 36.9814,
    '927_581': 36.9721,
    '927_582': 36.9788,
    '927_583': 37.0006,
    '927_584': 37.0155,
    '927_585': 37.0463,
    '927_586': 37.0426,
    '927_587': 36.9858,
    '927_588': 36.9127,
    '927_589': 36.8548,
    '927_590': 36.8223,
    '927_591': 36.7913,
    '927_592': 36.7924,
    '927_593': 36.804,
    '927_594': 36.8211,
    '927_595': 36.839,
    '927_596': 36.8662,
    '927_597': 36.8864,
    '927_598': 36.9019,
    '927_599': 36.9231,
    '927_600': 36.9548,
    '927_601': 36.9823,
    '927_602': 36.9999,
    '927_603': 37.0068,
    '927_604': 37.0273,
    '927_605': 37.0397,
    '927_606': 37.0267,
    '927_607': 37.0088,
    '927_608': 36.9942,
    '927_609': 36.9821,
    '927_610': 36.9797,
    '927_611': 36.9738,
    '927_612': 36.9747,
    '927_613': 36.9754,
    '927_614': 36.9933,
    '927_615': 37.0161,
    '927_616': 37.032,
    '927_617': 37.0644,
    '927_618': 37.0915,
    '927_619': 37.0997,
    '927_620': 37.0763,
    '927_621': 37.0356,
    '927_622': 37.0014,
    '927_623': 36.9742,
    '927_624': 36.95,
    '927_625': 36.9406,
    '927_626': 36.9324,
    '927_627': 36.9167,
    '927_628': 36.8963,
    '927_629': 36.8965,
    '927_630': 36.9087,
    '927_631': 36.9087,
    '927_632': 36.8929,
    '927_633': 36.8843,
    '927_634': 36.875,
    '927_635': 36.8492,
    '927_636': 36.843,
    '927_637': 36.8467,
    '927_638': 36.8379,
    '927_639': 36.805,
    '927_640': 36.742,
    '927_641': 36.6451,
    '927_642': 36.5851,
    '927_643': 36.5701,
    '927_644': 36.5866,
    '927_645': 36.6089,
    '927_646': 36.6523,
    '927_647': 36.6958,
    '927_648': 36.7732,
    '927_649': 36.8626,
    '927_650': 36.9683,
    '927_651': 37.0834,
    '927_652': 37.1989,
    '927_653': 37.3276,
    '927_654': 37.4622,
    '927_655': 37.5872,
    '927_656': 37.6902,
    '927_657': 37.7467,
    '927_658': 37.7617,
    '927_659': 37.7738,
    '927_660': 37.7661,
    '927_661': 37.7411,
    '927_662': 37.6785,
    '927_663': 37.6456,
    '927_664': 37.6489,
    '927_665': 37.6714,
    '927_666': 37.7097,
    '927_667': 37.759,
    '927_668': 37.8177,
    '927_669': 37.8858,
    '927_670': 37.9642,
    '927_671': 38.0509,
    '927_672': 38.1464,
    '927_673': 38.2376,
    '927_674': 38.3339,
    '927_675': 38.4429,
    '927_676': 38.5517,
    '927_677': 38.6583,
    '927_678': 38.7615,
    '927_679': 38.8648,
    '927_680': 38.9713,
    '927_681': 39.0831,
    '927_682': 39.2023,
    '927_683': 39.3279,
    '927_684': 39.4597,
    '927_685': 39.5988,
    '927_686': 39.7411,
    '927_687': 39.886,
    '927_688': 40.0334,
    '927_689': 40.1777,
    '927_690': 40.319,
    '927_691': 40.4597,
    '927_692': 40.5908,
    '927_693': 40.7124,
    '927_694': 40.8372,
    '927_695': 40.9621,
    '927_696': 41.0903,
    '927_697': 41.2311,
    '927_698': 41.3943,
    '927_699': 41.5813,
    '927_700': 41.7827,
    '927_701': 41.9945,
    '927_702': 42.2145,
    '927_703': 42.4061,
    '927_704': 42.5603,
    '927_705': 42.7002,
    '927_706': 42.7758,
    '927_707': 42.8148,
    '927_708': 42.8347,
    '927_709': 42.8534,
    '927_710': 42.8739,
    '927_711': 42.9034,
    '927_712': 42.9447,
    '927_713': 43.013,
    '927_714': 43.1203,
    '927_715': 43.2527,
    '927_716': 43.3996,
    '927_717': 43.5575,
    '927_718': 43.7185,
    '927_719': 43.8661,
    '927_720': 44.0225,
    '927_721': 44.1324,
    '927_722': 44.2523,
    '927_723': 44.3752,
    '927_724': 44.439,
    '927_725': 44.4346,
    '927_726': 44.447,
    '927_727': 44.4427,
    '927_728': 44.3738,
    '927_729': 44.2661,
    '927_730': 44.0645,
    '927_731': 43.9017,
    '927_732': 43.6688,
    '927_733': 43.4208,
    '927_734': 43.1851,
    '927_735': 42.9846,
    '927_736': 42.79,
    '927_737': 42.623,
    '927_738': 42.5306,
    '927_739': 42.5319,
    '927_740': 42.5868,
    '927_741': 42.6575,
    '927_742': 42.7035,
    '927_743': 42.7225,
    '927_744': 42.7231,
    '927_745': 42.7272,
    '927_746': 42.7439,
    '927_747': 42.765,
    '927_748': 42.7749,
    '927_749': 42.7651,
    '927_750': 42.7374,
    '927_751': 42.6951,
    '927_752': 42.6446,
    '927_753': 42.5818,
    '927_754': 42.5196,
    '927_755': 42.4534,
    '927_756': 42.3688,
    '927_757': 42.2708,
    '927_758': 42.1509,
    '927_759': 42.0117,
    '927_760': 41.8635,
    '927_761': 41.7075,
    '927_762': 41.5477,
    '927_763': 41.4038,
    '927_764': 41.2615,
    '927_765': 41.0969,
    '927_766': 40.8978,
    '927_767': 40.6584,
    '927_768': 40.3855,
    '927_769': 40.0828,
    '927_770': 39.7586,
    '927_771': 39.4129,
    '927_772': 39.0957,
    '927_773': 38.8231,
    '927_774': 38.5616,
    '927_775': 38.304,
    '927_776': 38.0606,
    '927_777': 37.833,
    '927_778': 37.6166,
    '927_779': 37.4115,
    '927_780': 37.2184,
    '927_781': 37.0353,
    '927_782': 36.861,
    '927_783': 36.6936,
    '927_784': 36.533,
    '927_785': 36.3827,
    '927_786': 36.2427,
    '927_787': 36.1126,
    '927_788': 35.993,
    '927_789': 35.888,
    '927_790': 35.7647,
    '927_791': 35.6411,
    '927_792': 35.523,
    '927_793': 35.4003,
    '927_794': 35.2778,
    '927_795': 35.1528,
    '927_796': 35.0261,
    '927_797': 34.8979,
    '927_798': 34.7682,
    '927_799': 34.6386,
    '927_800': 34.5096,
    '927_801': 34.3818,
    '927_802': 34.2553,
    '927_803': 34.1341,
    '927_804': 34.0154,
    '927_805': 33.8989,
    '927_806': 33.7888,
    '927_807': 33.6764,
    '927_808': 33.5568,
    '927_809': 33.4332,
    '927_810': 33.3059,
    '927_811': 33.1822,
    '927_812': 33.0585,
    '927_813': 32.9338,
    '927_814': 32.8061,
    '927_815': 32.6738,
    '927_816': 32.5375,
    '927_817': 32.3962,
    '927_818': 32.2502,
    '927_819': 32.0984,
    '927_820': 31.9402,
    '927_821': 31.7752,
    '927_822': 31.6025,
    '927_823': 31.4214,
    '927_824': 31.2314,
    '927_825': 31.0321,
    '927_826': 30.8224,
    '927_827': 30.6019,
    '927_828': 30.3686,
    '928_472': 31.7087,
    '928_473': 31.7494,
    '928_474': 31.7999,
    '928_475': 31.8558,
    '928_476': 31.9128,
    '928_477': 31.9638,
    '928_478': 32.0124,
    '928_479': 32.0772,
    '928_480': 32.1481,
    '928_481': 32.2206,
    '928_482': 32.2925,
    '928_483': 32.3641,
    '928_484': 32.4345,
    '928_485': 32.5023,
    '928_486': 32.5681,
    '928_487': 32.6314,
    '928_488': 32.694,
    '928_489': 32.758,
    '928_490': 32.8251,
    '928_491': 32.8956,
    '928_492': 32.9699,
    '928_493': 33.0492,
    '928_494': 33.1273,
    '928_495': 33.1907,
    '928_496': 33.246,
    '928_497': 33.3024,
    '928_498': 33.3677,
    '928_499': 33.4346,
    '928_500': 33.4978,
    '928_501': 33.5608,
    '928_502': 33.625,
    '928_503': 33.6918,
    '928_504': 33.7652,
    '928_505': 33.8439,
    '928_506': 33.9232,
    '928_507': 34.0016,
    '928_508': 34.0765,
    '928_509': 34.149,
    '928_510': 34.2245,
    '928_511': 34.2956,
    '928_512': 34.3442,
    '928_513': 34.3747,
    '928_514': 34.4141,
    '928_515': 34.4517,
    '928_516': 34.4899,
    '928_517': 34.5263,
    '928_518': 34.5615,
    '928_519': 34.5945,
    '928_520': 34.626,
    '928_521': 34.6561,
    '928_522': 34.6846,
    '928_523': 34.7108,
    '928_524': 34.7338,
    '928_525': 34.7515,
    '928_526': 34.765,
    '928_527': 34.7866,
    '928_528': 34.8126,
    '928_529': 34.8426,
    '928_530': 34.8721,
    '928_531': 34.9011,
    '928_532': 34.9342,
    '928_533': 34.9707,
    '928_534': 35.0084,
    '928_535': 35.0513,
    '928_536': 35.1002,
    '928_537': 35.1552,
    '928_538': 35.2188,
    '928_539': 35.2856,
    '928_540': 35.3433,
    '928_541': 35.3931,
    '928_542': 35.4355,
    '928_543': 35.4589,
    '928_544': 35.4673,
    '928_545': 35.4649,
    '928_546': 35.4604,
    '928_547': 35.4593,
    '928_548': 35.465,
    '928_549': 35.4754,
    '928_550': 35.4867,
    '928_551': 35.503,
    '928_552': 35.5251,
    '928_553': 35.5451,
    '928_554': 35.5723,
    '928_555': 35.6123,
    '928_556': 35.6606,
    '928_557': 35.7256,
    '928_558': 35.7979,
    '928_559': 35.8415,
    '928_560': 35.8795,
    '928_561': 35.8933,
    '928_562': 35.9011,
    '928_563': 35.9248,
    '928_564': 35.9592,
    '928_565': 35.9838,
    '928_566': 36.009,
    '928_567': 36.0337,
    '928_568': 36.0557,
    '928_569': 36.0935,
    '928_570': 36.1496,
    '928_571': 36.2223,
    '928_572': 36.3117,
    '928_573': 36.4197,
    '928_574': 36.5184,
    '928_575': 36.6167,
    '928_576': 36.7151,
    '928_577': 36.7908,
    '928_578': 36.8426,
    '928_579': 36.8771,
    '928_580': 36.8907,
    '928_581': 36.8898,
    '928_582': 36.89,
    '928_583': 36.9174,
    '928_584': 36.9453,
    '928_585': 36.9837,
    '928_586': 36.982,
    '928_587': 36.927,
    '928_588': 36.8589,
    '928_589': 36.8048,
    '928_590': 36.7683,
    '928_591': 36.7381,
    '928_592': 36.7344,
    '928_593': 36.7453,
    '928_594': 36.7591,
    '928_595': 36.784,
    '928_596': 36.8205,
    '928_597': 36.8386,
    '928_598': 36.861,
    '928_599': 36.8873,
    '928_600': 36.9096,
    '928_601': 36.9305,
    '928_602': 36.9473,
    '928_603': 36.9531,
    '928_604': 36.9709,
    '928_605': 36.9999,
    '928_606': 37.0018,
    '928_607': 36.9858,
    '928_608': 36.9764,
    '928_609': 36.9587,
    '928_610': 36.9495,
    '928_611': 36.9393,
    '928_612': 36.9335,
    '928_613': 36.9252,
    '928_614': 36.9346,
    '928_615': 36.942,
    '928_616': 36.9539,
    '928_617': 36.9837,
    '928_618': 37.0137,
    '928_619': 37.0274,
    '928_620': 37.0095,
    '928_621': 36.9781,
    '928_622': 36.9389,
    '928_623': 36.9136,
    '928_624': 36.8881,
    '928_625': 36.8676,
    '928_626': 36.8621,
    '928_627': 36.8524,
    '928_628': 36.841,
    '928_629': 36.83,
    '928_630': 36.8312,
    '928_631': 36.8325,
    '928_632': 36.8208,
    '928_633': 36.8165,
    '928_634': 36.8127,
    '928_635': 36.8071,
    '928_636': 36.8239,
    '928_637': 36.8503,
    '928_638': 36.8613,
    '928_639': 36.8403,
    '928_640': 36.7865,
    '928_641': 36.6974,
    '928_642': 36.6425,
    '928_643': 36.6368,
    '928_644': 36.6548,
    '928_645': 36.6683,
    '928_646': 36.7072,
    '928_647': 36.7428,
    '928_648': 36.8238,
    '928_649': 36.9184,
    '928_650': 37.0336,
    '928_651': 37.1626,
    '928_652': 37.2862,
    '928_653': 37.4164,
    '928_654': 37.5502,
    '928_655': 37.6731,
    '928_656': 37.761,
    '928_657': 37.8046,
    '928_658': 37.814,
    '928_659': 37.8217,
    '928_660': 37.8109,
    '928_661': 37.7819,
    '928_662': 37.7297,
    '928_663': 37.7111,
    '928_664': 37.7197,
    '928_665': 37.7429,
    '928_666': 37.7809,
    '928_667': 37.8294,
    '928_668': 37.8862,
    '928_669': 37.9519,
    '928_670': 38.0283,
    '928_671': 38.1105,
    '928_672': 38.2042,
    '928_673': 38.2864,
    '928_674': 38.3793,
    '928_675': 38.4833,
    '928_676': 38.5883,
    '928_677': 38.692,
    '928_678': 38.7948,
    '928_679': 38.8999,
    '928_680': 39.0099,
    '928_681': 39.1242,
    '928_682': 39.2419,
    '928_683': 39.3634,
    '928_684': 39.4921,
    '928_685': 39.6262,
    '928_686': 39.7659,
    '928_687': 39.9125,
    '928_688': 40.0612,
    '928_689': 40.2082,
    '928_690': 40.353,
    '928_691': 40.4927,
    '928_692': 40.6197,
    '928_693': 40.7321,
    '928_694': 40.8513,
    '928_695': 40.9656,
    '928_696': 41.0839,
    '928_697': 41.213,
    '928_698': 41.3633,
    '928_699': 41.5337,
    '928_700': 41.7239,
    '928_701': 41.9329,
    '928_702': 42.1493,
    '928_703': 42.3559,
    '928_704': 42.5332,
    '928_705': 42.6557,
    '928_706': 42.7498,
    '928_707': 42.805,
    '928_708': 42.8337,
    '928_709': 42.8643,
    '928_710': 42.8899,
    '928_711': 42.9148,
    '928_712': 42.95,
    '928_713': 43.0097,
    '928_714': 43.1065,
    '928_715': 43.2327,
    '928_716': 43.3768,
    '928_717': 43.5375,
    '928_718': 43.7066,
    '928_719': 43.8599,
    '928_720': 44.0319,
    '928_721': 44.1559,
    '928_722': 44.2606,
    '928_723': 44.3769,
    '928_724': 44.4486,
    '928_725': 44.518,
    '928_726': 44.4963,
    '928_727': 44.4755,
    '928_728': 44.4455,
    '928_729': 44.3272,
    '928_730': 44.1309,
    '928_731': 43.9664,
    '928_732': 43.7393,
    '928_733': 43.4861,
    '928_734': 43.261,
    '928_735': 43.0609,
    '928_736': 42.8606,
    '928_737': 42.6719,
    '928_738': 42.5608,
    '928_739': 42.5425,
    '928_740': 42.5797,
    '928_741': 42.638,
    '928_742': 42.6972,
    '928_743': 42.7351,
    '928_744': 42.7548,
    '928_745': 42.7653,
    '928_746': 42.7776,
    '928_747': 42.7879,
    '928_748': 42.7861,
    '928_749': 42.7664,
    '928_750': 42.7369,
    '928_751': 42.6992,
    '928_752': 42.6568,
    '928_753': 42.6092,
    '928_754': 42.5597,
    '928_755': 42.5038,
    '928_756': 42.4292,
    '928_757': 42.3358,
    '928_758': 42.2258,
    '928_759': 42.0928,
    '928_760': 41.958,
    '928_761': 41.8105,
    '928_762': 41.6563,
    '928_763': 41.5097,
    '928_764': 41.3581,
    '928_765': 41.1962,
    '928_766': 40.9949,
    '928_767': 40.7484,
    '928_768': 40.4588,
    '928_769': 40.1413,
    '928_770': 39.817,
    '928_771': 39.4767,
    '928_772': 39.1665,
    '928_773': 38.8878,
    '928_774': 38.6179,
    '928_775': 38.3558,
    '928_776': 38.1073,
    '928_777': 37.8751,
    '928_778': 37.6534,
    '928_779': 37.4433,
    '928_780': 37.2465,
    '928_781': 37.0598,
    '928_782': 36.8825,
    '928_783': 36.7139,
    '928_784': 36.5545,
    '928_785': 36.4038,
    '928_786': 36.2654,
    '928_787': 36.1362,
    '928_788': 36.0173,
    '928_789': 35.9064,
    '928_790': 35.7877,
    '928_791': 35.6683,
    '928_792': 35.5513,
    '928_793': 35.4264,
    '928_794': 35.3022,
    '928_795': 35.1798,
    '928_796': 35.0544,
    '928_797': 34.9278,
    '928_798': 34.8001,
    '928_799': 34.6723,
    '928_800': 34.5439,
    '928_801': 34.4169,
    '928_802': 34.2912,
    '928_803': 34.1705,
    '928_804': 34.0527,
    '928_805': 33.9378,
    '928_806': 33.8273,
    '928_807': 33.7149,
    '928_808': 33.5981,
    '928_809': 33.4783,
    '928_810': 33.3554,
    '928_811': 33.233,
    '928_812': 33.1097,
    '928_813': 32.9849,
    '928_814': 32.8586,
    '928_815': 32.7285,
    '928_816': 32.5941,
    '928_817': 32.4552,
    '928_818': 32.3118,
    '928_819': 32.1632,
    '928_820': 32.0085,
    '928_821': 31.847,
    '928_822': 31.6781,
    '928_823': 31.501,
    '928_824': 31.3152,
    '928_825': 31.1202,
    '928_826': 30.9152,
    '928_827': 30.6999,
    '928_828': 30.4733,
    '929_472': 31.688,
    '929_473': 31.7295,
    '929_474': 31.7767,
    '929_475': 31.826,
    '929_476': 31.8737,
    '929_477': 31.9182,
    '929_478': 31.9723,
    '929_479': 32.0369,
    '929_480': 32.1083,
    '929_481': 32.1813,
    '929_482': 32.254,
    '929_483': 32.3255,
    '929_484': 32.3943,
    '929_485': 32.459,
    '929_486': 32.5214,
    '929_487': 32.5827,
    '929_488': 32.6441,
    '929_489': 32.7091,
    '929_490': 32.7771,
    '929_491': 32.8488,
    '929_492': 32.9261,
    '929_493': 33.0085,
    '929_494': 33.0792,
    '929_495': 33.1404,
    '929_496': 33.1969,
    '929_497': 33.2539,
    '929_498': 33.3144,
    '929_499': 33.3762,
    '929_500': 33.4408,
    '929_501': 33.5079,
    '929_502': 33.5743,
    '929_503': 33.6395,
    '929_504': 33.7079,
    '929_505': 33.7814,
    '929_506': 33.8554,
    '929_507': 33.929,
    '929_508': 34.0013,
    '929_509': 34.0744,
    '929_510': 34.1491,
    '929_511': 34.2198,
    '929_512': 34.2811,
    '929_513': 34.3336,
    '929_514': 34.3808,
    '929_515': 34.4192,
    '929_516': 34.4568,
    '929_517': 34.4943,
    '929_518': 34.5297,
    '929_519': 34.5615,
    '929_520': 34.5915,
    '929_521': 34.6215,
    '929_522': 34.6516,
    '929_523': 34.6788,
    '929_524': 34.7047,
    '929_525': 34.7159,
    '929_526': 34.7195,
    '929_527': 34.7346,
    '929_528': 34.7561,
    '929_529': 34.7823,
    '929_530': 34.8099,
    '929_531': 34.8377,
    '929_532': 34.8687,
    '929_533': 34.9033,
    '929_534': 34.9388,
    '929_535': 34.9763,
    '929_536': 35.0197,
    '929_537': 35.0662,
    '929_538': 35.1186,
    '929_539': 35.1769,
    '929_540': 35.2317,
    '929_541': 35.2812,
    '929_542': 35.3186,
    '929_543': 35.3405,
    '929_544': 35.3511,
    '929_545': 35.3512,
    '929_546': 35.3488,
    '929_547': 35.3507,
    '929_548': 35.3607,
    '929_549': 35.3769,
    '929_550': 35.3935,
    '929_551': 35.4123,
    '929_552': 35.4319,
    '929_553': 35.4523,
    '929_554': 35.4782,
    '929_555': 35.5135,
    '929_556': 35.5617,
    '929_557': 35.6212,
    '929_558': 35.6866,
    '929_559': 35.7284,
    '929_560': 35.7616,
    '929_561': 35.7818,
    '929_562': 35.7959,
    '929_563': 35.8226,
    '929_564': 35.859,
    '929_565': 35.8863,
    '929_566': 35.9185,
    '929_567': 35.9487,
    '929_568': 35.9793,
    '929_569': 36.0213,
    '929_570': 36.0809,
    '929_571': 36.157,
    '929_572': 36.2455,
    '929_573': 36.3468,
    '929_574': 36.4386,
    '929_575': 36.5285,
    '929_576': 36.6168,
    '929_577': 36.6876,
    '929_578': 36.7342,
    '929_579': 36.7687,
    '929_580': 36.7944,
    '929_581': 36.8018,
    '929_582': 36.808,
    '929_583': 36.8396,
    '929_584': 36.8846,
    '929_585': 36.922,
    '929_586': 36.9167,
    '929_587': 36.8672,
    '929_588': 36.809,
    '929_589': 36.765,
    '929_590': 36.7248,
    '929_591': 36.6905,
    '929_592': 36.6777,
    '929_593': 36.6842,
    '929_594': 36.6989,
    '929_595': 36.7329,
    '929_596': 36.7779,
    '929_597': 36.7995,
    '929_598': 36.8197,
    '929_599': 36.8421,
    '929_600': 36.8547,
    '929_601': 36.8768,
    '929_602': 36.8926,
    '929_603': 36.8907,
    '929_604': 36.8953,
    '929_605': 36.9343,
    '929_606': 36.9516,
    '929_607': 36.9471,
    '929_608': 36.9501,
    '929_609': 36.9347,
    '929_610': 36.9181,
    '929_611': 36.9035,
    '929_612': 36.8919,
    '929_613': 36.8788,
    '929_614': 36.8752,
    '929_615': 36.8787,
    '929_616': 36.8894,
    '929_617': 36.9128,
    '929_618': 36.9355,
    '929_619': 36.9501,
    '929_620': 36.9329,
    '929_621': 36.9058,
    '929_622': 36.8737,
    '929_623': 36.8502,
    '929_624': 36.8311,
    '929_625': 36.8119,
    '929_626': 36.7953,
    '929_627': 36.7838,
    '929_628': 36.7763,
    '929_629': 36.7681,
    '929_630': 36.7653,
    '929_631': 36.765,
    '929_632': 36.7658,
    '929_633': 36.7701,
    '929_634': 36.7761,
    '929_635': 36.7738,
    '929_636': 36.7978,
    '929_637': 36.8448,
    '929_638': 36.8712,
    '929_639': 36.8621,
    '929_640': 36.8194,
    '929_641': 36.7504,
    '929_642': 36.7025,
    '929_643': 36.7003,
    '929_644': 36.7115,
    '929_645': 36.7171,
    '929_646': 36.7569,
    '929_647': 36.8,
    '929_648': 36.8835,
    '929_649': 36.9819,
    '929_650': 37.106,
    '929_651': 37.2506,
    '929_652': 37.3844,
    '929_653': 37.5136,
    '929_654': 37.6389,
    '929_655': 37.7508,
    '929_656': 37.8292,
    '929_657': 37.862,
    '929_658': 37.8721,
    '929_659': 37.886,
    '929_660': 37.8784,
    '929_661': 37.8423,
    '929_662': 37.7928,
    '929_663': 37.785,
    '929_664': 37.8011,
    '929_665': 37.8246,
    '929_666': 37.8604,
    '929_667': 37.9067,
    '929_668': 37.9607,
    '929_669': 38.0247,
    '929_670': 38.0974,
    '929_671': 38.1696,
    '929_672': 38.2542,
    '929_673': 38.3357,
    '929_674': 38.4265,
    '929_675': 38.5258,
    '929_676': 38.6269,
    '929_677': 38.73,
    '929_678': 38.8357,
    '929_679': 38.9425,
    '929_680': 39.0543,
    '929_681': 39.171,
    '929_682': 39.2862,
    '929_683': 39.402,
    '929_684': 39.5335,
    '929_685': 39.6688,
    '929_686': 39.8044,
    '929_687': 39.9524,
    '929_688': 40.0989,
    '929_689': 40.2489,
    '929_690': 40.3949,
    '929_691': 40.5303,
    '929_692': 40.6545,
    '929_693': 40.7635,
    '929_694': 40.877,
    '929_695': 40.9836,
    '929_696': 41.0858,
    '929_697': 41.2055,
    '929_698': 41.3433,
    '929_699': 41.4987,
    '929_700': 41.671,
    '929_701': 41.8684,
    '929_702': 42.0796,
    '929_703': 42.2853,
    '929_704': 42.4769,
    '929_705': 42.6203,
    '929_706': 42.7315,
    '929_707': 42.7991,
    '929_708': 42.8472,
    '929_709': 42.8879,
    '929_710': 42.9204,
    '929_711': 42.938,
    '929_712': 42.9629,
    '929_713': 43.0124,
    '929_714': 43.0952,
    '929_715': 43.2121,
    '929_716': 43.3506,
    '929_717': 43.5099,
    '929_718': 43.6852,
    '929_719': 43.8538,
    '929_720': 44.0126,
    '929_721': 44.1724,
    '929_722': 44.2798,
    '929_723': 44.3505,
    '929_724': 44.4658,
    '929_725': 44.5578,
    '929_726': 44.5532,
    '929_727': 44.5079,
    '929_728': 44.4667,
    '929_729': 44.3662,
    '929_730': 44.209,
    '929_731': 44.0123,
    '929_732': 43.8028,
    '929_733': 43.5561,
    '929_734': 43.3378,
    '929_735': 43.1246,
    '929_736': 42.921,
    '929_737': 42.7192,
    '929_738': 42.59,
    '929_739': 42.5536,
    '929_740': 42.5792,
    '929_741': 42.6315,
    '929_742': 42.6916,
    '929_743': 42.7426,
    '929_744': 42.7765,
    '929_745': 42.7984,
    '929_746': 42.8114,
    '929_747': 42.8163,
    '929_748': 42.8053,
    '929_749': 42.781,
    '929_750': 42.7477,
    '929_751': 42.7126,
    '929_752': 42.671,
    '929_753': 42.6283,
    '929_754': 42.5856,
    '929_755': 42.5225,
    '929_756': 42.4631,
    '929_757': 42.3713,
    '929_758': 42.2733,
    '929_759': 42.1547,
    '929_760': 42.0232,
    '929_761': 41.8834,
    '929_762': 41.7399,
    '929_763': 41.5902,
    '929_764': 41.4297,
    '929_765': 41.2635,
    '929_766': 41.0501,
    '929_767': 40.7993,
    '929_768': 40.4971,
    '929_769': 40.1694,
    '929_770': 39.8521,
    '929_771': 39.5286,
    '929_772': 39.2309,
    '929_773': 38.9489,
    '929_774': 38.6736,
    '929_775': 38.4056,
    '929_776': 38.1522,
    '929_777': 37.9148,
    '929_778': 37.6888,
    '929_779': 37.4748,
    '929_780': 37.2746,
    '929_781': 37.085,
    '929_782': 36.907,
    '929_783': 36.7391,
    '929_784': 36.581,
    '929_785': 36.4332,
    '929_786': 36.2959,
    '929_787': 36.1686,
    '929_788': 36.0484,
    '929_789': 35.9314,
    '929_790': 35.8147,
    '929_791': 35.6996,
    '929_792': 35.5886,
    '929_793': 35.4616,
    '929_794': 35.3379,
    '929_795': 35.2166,
    '929_796': 35.0908,
    '929_797': 34.9658,
    '929_798': 34.8407,
    '929_799': 34.7152,
    '929_800': 34.5888,
    '929_801': 34.4625,
    '929_802': 34.3374,
    '929_803': 34.2168,
    '929_804': 34.1006,
    '929_805': 33.9882,
    '929_806': 33.8775,
    '929_807': 33.7637,
    '929_808': 33.6488,
    '929_809': 33.5319,
    '929_810': 33.4135,
    '929_811': 33.2932,
    '929_812': 33.1701,
    '929_813': 33.046,
    '929_814': 32.9209,
    '929_815': 32.7924,
    '929_816': 32.6597,
    '929_817': 32.5226,
    '929_818': 32.3815,
    '929_819': 32.2357,
    '929_820': 32.0843,
    '929_821': 31.9263,
    '929_822': 31.761,
    '929_823': 31.5877,
    '929_824': 31.4058,
    '929_825': 31.2149,
    '929_826': 31.0143,
    '929_827': 30.8036,
    '929_828': 30.5828,
    '930_472': 31.6704,
    '930_473': 31.7105,
    '930_474': 31.7536,
    '930_475': 31.7966,
    '930_476': 31.8378,
    '930_477': 31.8855,
    '930_478': 31.9388,
    '930_479': 32.0007,
    '930_480': 32.0708,
    '930_481': 32.1432,
    '930_482': 32.2155,
    '930_483': 32.2858,
    '930_484': 32.3526,
    '930_485': 32.4133,
    '930_486': 32.4729,
    '930_487': 32.5328,
    '930_488': 32.5939,
    '930_489': 32.659,
    '930_490': 32.7277,
    '930_491': 32.7992,
    '930_492': 32.8785,
    '930_493': 32.9564,
    '930_494': 33.0233,
    '930_495': 33.0838,
    '930_496': 33.1409,
    '930_497': 33.1974,
    '930_498': 33.2508,
    '930_499': 33.3098,
    '930_500': 33.3764,
    '930_501': 33.4479,
    '930_502': 33.5141,
    '930_503': 33.5782,
    '930_504': 33.6458,
    '930_505': 33.7155,
    '930_506': 33.7849,
    '930_507': 33.8536,
    '930_508': 33.922,
    '930_509': 33.9929,
    '930_510': 34.0647,
    '930_511': 34.1345,
    '930_512': 34.2007,
    '930_513': 34.2639,
    '930_514': 34.3204,
    '930_515': 34.3688,
    '930_516': 34.4127,
    '930_517': 34.4567,
    '930_518': 34.4975,
    '930_519': 34.5362,
    '930_520': 34.5667,
    '930_521': 34.6004,
    '930_522': 34.6399,
    '930_523': 34.6689,
    '930_524': 34.6902,
    '930_525': 34.6951,
    '930_526': 34.6927,
    '930_527': 34.6995,
    '930_528': 34.7149,
    '930_529': 34.7362,
    '930_530': 34.7606,
    '930_531': 34.7872,
    '930_532': 34.8156,
    '930_533': 34.8448,
    '930_534': 34.8755,
    '930_535': 34.9077,
    '930_536': 34.9443,
    '930_537': 34.9835,
    '930_538': 35.0261,
    '930_539': 35.0738,
    '930_540': 35.122,
    '930_541': 35.1651,
    '930_542': 35.1987,
    '930_543': 35.2217,
    '930_544': 35.2346,
    '930_545': 35.2378,
    '930_546': 35.2408,
    '930_547': 35.2472,
    '930_548': 35.26,
    '930_549': 35.2782,
    '930_550': 35.2988,
    '930_551': 35.3193,
    '930_552': 35.3377,
    '930_553': 35.359,
    '930_554': 35.3843,
    '930_555': 35.4179,
    '930_556': 35.4636,
    '930_557': 35.5122,
    '930_558': 35.568,
    '930_559': 35.613,
    '930_560': 35.6455,
    '930_561': 35.6747,
    '930_562': 35.6951,
    '930_563': 35.7262,
    '930_564': 35.7624,
    '930_565': 35.7959,
    '930_566': 35.8289,
    '930_567': 35.8647,
    '930_568': 35.9026,
    '930_569': 35.9512,
    '930_570': 36.0188,
    '930_571': 36.0911,
    '930_572': 36.1711,
    '930_573': 36.2554,
    '930_574': 36.3414,
    '930_575': 36.4307,
    '930_576': 36.5104,
    '930_577': 36.5778,
    '930_578': 36.6176,
    '930_579': 36.6527,
    '930_580': 36.6863,
    '930_581': 36.7079,
    '930_582': 36.7325,
    '930_583': 36.7687,
    '930_584': 36.8246,
    '930_585': 36.8566,
    '930_586': 36.8486,
    '930_587': 36.8076,
    '930_588': 36.7647,
    '930_589': 36.7283,
    '930_590': 36.6814,
    '930_591': 36.6498,
    '930_592': 36.6339,
    '930_593': 36.6308,
    '930_594': 36.6474,
    '930_595': 36.6847,
    '930_596': 36.7319,
    '930_597': 36.7701,
    '930_598': 36.7884,
    '930_599': 36.8001,
    '930_600': 36.8115,
    '930_601': 36.8304,
    '930_602': 36.8358,
    '930_603': 36.8257,
    '930_604': 36.8258,
    '930_605': 36.8572,
    '930_606': 36.8843,
    '930_607': 36.8819,
    '930_608': 36.8886,
    '930_609': 36.885,
    '930_610': 36.8723,
    '930_611': 36.8518,
    '930_612': 36.8361,
    '930_613': 36.8335,
    '930_614': 36.8234,
    '930_615': 36.8275,
    '930_616': 36.8351,
    '930_617': 36.8531,
    '930_618': 36.8721,
    '930_619': 36.8882,
    '930_620': 36.8691,
    '930_621': 36.8354,
    '930_622': 36.8113,
    '930_623': 36.7945,
    '930_624': 36.7912,
    '930_625': 36.7781,
    '930_626': 36.7479,
    '930_627': 36.7314,
    '930_628': 36.7208,
    '930_629': 36.7149,
    '930_630': 36.7144,
    '930_631': 36.7197,
    '930_632': 36.73,
    '930_633': 36.7362,
    '930_634': 36.7502,
    '930_635': 36.745,
    '930_636': 36.7634,
    '930_637': 36.8218,
    '930_638': 36.8627,
    '930_639': 36.8654,
    '930_640': 36.8423,
    '930_641': 36.8014,
    '930_642': 36.7635,
    '930_643': 36.7629,
    '930_644': 36.7634,
    '930_645': 36.7626,
    '930_646': 36.8048,
    '930_647': 36.8619,
    '930_648': 36.949,
    '930_649': 37.0533,
    '930_650': 37.1861,
    '930_651': 37.3405,
    '930_652': 37.48,
    '930_653': 37.6083,
    '930_654': 37.7234,
    '930_655': 37.8214,
    '930_656': 37.8915,
    '930_657': 37.9261,
    '930_658': 37.9379,
    '930_659': 37.9525,
    '930_660': 37.9453,
    '930_661': 37.9105,
    '930_662': 37.8689,
    '930_663': 37.8713,
    '930_664': 37.8904,
    '930_665': 37.919,
    '930_666': 37.9528,
    '930_667': 37.9926,
    '930_668': 38.0403,
    '930_669': 38.0991,
    '930_670': 38.1629,
    '930_671': 38.2323,
    '930_672': 38.3075,
    '930_673': 38.391,
    '930_674': 38.4802,
    '930_675': 38.5748,
    '930_676': 38.6729,
    '930_677': 38.7758,
    '930_678': 38.8828,
    '930_679': 38.9891,
    '930_680': 39.1017,
    '930_681': 39.2183,
    '930_682': 39.3333,
    '930_683': 39.4474,
    '930_684': 39.58,
    '930_685': 39.719,
    '930_686': 39.8542,
    '930_687': 39.998,
    '930_688': 40.1429,
    '930_689': 40.2889,
    '930_690': 40.4319,
    '930_691': 40.564,
    '930_692': 40.6842,
    '930_693': 40.8013,
    '930_694': 40.9142,
    '930_695': 41.0088,
    '930_696': 41.0985,
    '930_697': 41.2094,
    '930_698': 41.3352,
    '930_699': 41.479,
    '930_700': 41.6382,
    '930_701': 41.8203,
    '930_702': 42.0191,
    '930_703': 42.2232,
    '930_704': 42.4277,
    '930_705': 42.5898,
    '930_706': 42.718,
    '930_707': 42.8054,
    '930_708': 42.8713,
    '930_709': 42.924,
    '930_710': 42.9558,
    '930_711': 42.9716,
    '930_712': 42.9834,
    '930_713': 43.0225,
    '930_714': 43.0917,
    '930_715': 43.1955,
    '930_716': 43.3275,
    '930_717': 43.4815,
    '930_718': 43.6539,
    '930_719': 43.8279,
    '930_720': 43.9823,
    '930_721': 44.1491,
    '930_722': 44.243,
    '930_723': 44.3528,
    '930_724': 44.4763,
    '930_725': 44.5409,
    '930_726': 44.5497,
    '930_727': 44.5081,
    '930_728': 44.4613,
    '930_729': 44.3978,
    '930_730': 44.264,
    '930_731': 44.055,
    '930_732': 43.8491,
    '930_733': 43.6286,
    '930_734': 43.4164,
    '930_735': 43.2047,
    '930_736': 42.9823,
    '930_737': 42.7664,
    '930_738': 42.6251,
    '930_739': 42.5697,
    '930_740': 42.5868,
    '930_741': 42.6361,
    '930_742': 42.689,
    '930_743': 42.7396,
    '930_744': 42.7803,
    '930_745': 42.8125,
    '930_746': 42.8375,
    '930_747': 42.8476,
    '930_748': 42.8379,
    '930_749': 42.8095,
    '930_750': 42.7728,
    '930_751': 42.7388,
    '930_752': 42.6966,
    '930_753': 42.6417,
    '930_754': 42.5892,
    '930_755': 42.5327,
    '930_756': 42.4666,
    '930_757': 42.3855,
    '930_758': 42.2964,
    '930_759': 42.189,
    '930_760': 42.0662,
    '930_761': 41.9329,
    '930_762': 41.7887,
    '930_763': 41.6304,
    '930_764': 41.4709,
    '930_765': 41.2898,
    '930_766': 41.0765,
    '930_767': 40.8175,
    '930_768': 40.5064,
    '930_769': 40.1832,
    '930_770': 39.8794,
    '930_771': 39.581,
    '930_772': 39.2869,
    '930_773': 39.004,
    '930_774': 38.7239,
    '930_775': 38.4511,
    '930_776': 38.1941,
    '930_777': 37.9524,
    '930_778': 37.7218,
    '930_779': 37.505,
    '930_780': 37.3026,
    '930_781': 37.1119,
    '930_782': 36.9334,
    '930_783': 36.7663,
    '930_784': 36.6109,
    '930_785': 36.4674,
    '930_786': 36.3342,
    '930_787': 36.2089,
    '930_788': 36.088,
    '930_789': 35.9698,
    '930_790': 35.8539,
    '930_791': 35.7398,
    '930_792': 35.6269,
    '930_793': 35.5049,
    '930_794': 35.377,
    '930_795': 35.2573,
    '930_796': 35.1338,
    '930_797': 35.012,
    '930_798': 34.8904,
    '930_799': 34.768,
    '930_800': 34.644,
    '930_801': 34.5192,
    '930_802': 34.3953,
    '930_803': 34.2751,
    '930_804': 34.1598,
    '930_805': 34.0478,
    '930_806': 33.936,
    '930_807': 33.8229,
    '930_808': 33.7095,
    '930_809': 33.5954,
    '930_810': 33.4816,
    '930_811': 33.3638,
    '930_812': 33.2408,
    '930_813': 33.1178,
    '930_814': 32.9937,
    '930_815': 32.8663,
    '930_816': 32.7347,
    '930_817': 32.5988,
    '930_818': 32.4596,
    '930_819': 32.3164,
    '930_820': 32.1679,
    '930_821': 32.0132,
    '930_822': 31.8515,
    '930_823': 31.6818,
    '930_824': 31.5038,
    '930_825': 31.3171,
    '930_826': 31.1209,
    '930_827': 30.9151,
    '930_828': 30.7001,
    '930_829': 30.4772,
    '930_830': 30.2496,
    '930_831': 30.0198,
    '930_832': 29.7669,
    '930_833': 29.5212,
    '930_834': 29.2855,
    '930_835': 29.0447,
    '930_836': 28.7979,
    '930_837': 28.5464,
    '930_838': 28.293,
    '930_839': 28.032,
    '930_840': 27.7624,
    '930_841': 27.4817,
    '930_842': 27.195,
    '930_843': 26.8984,
    '930_844': 26.5888,
    '930_845': 26.2594,
    '930_846': 25.9193,
    '930_847': 25.5712,
    '930_848': 25.2154,
    '931_472': 31.6532,
    '931_473': 31.691,
    '931_474': 31.7286,
    '931_475': 31.7639,
    '931_476': 31.8044,
    '931_477': 31.8542,
    '931_478': 31.9067,
    '931_479': 31.9679,
    '931_480': 32.0356,
    '931_481': 32.106,
    '931_482': 32.1762,
    '931_483': 32.2428,
    '931_484': 32.3048,
    '931_485': 32.3632,
    '931_486': 32.4212,
    '931_487': 32.4797,
    '931_488': 32.5404,
    '931_489': 32.6059,
    '931_490': 32.6768,
    '931_491': 32.7498,
    '931_492': 32.8274,
    '931_493': 32.8981,
    '931_494': 32.9624,
    '931_495': 33.0222,
    '931_496': 33.0793,
    '931_497': 33.1357,
    '931_498': 33.1909,
    '931_499': 33.2489,
    '931_500': 33.3121,
    '931_501': 33.3868,
    '931_502': 33.4501,
    '931_503': 33.512,
    '931_504': 33.5804,
    '931_505': 33.6482,
    '931_506': 33.7146,
    '931_507': 33.7792,
    '931_508': 33.8423,
    '931_509': 33.9103,
    '931_510': 33.9795,
    '931_511': 34.0495,
    '931_512': 34.1173,
    '931_513': 34.1797,
    '931_514': 34.2367,
    '931_515': 34.2896,
    '931_516': 34.3386,
    '931_517': 34.3918,
    '931_518': 34.4426,
    '931_519': 34.4912,
    '931_520': 34.5349,
    '931_521': 34.5812,
    '931_522': 34.6334,
    '931_523': 34.6752,
    '931_524': 34.6969,
    '931_525': 34.6958,
    '931_526': 34.6873,
    '931_527': 34.6849,
    '931_528': 34.6918,
    '931_529': 34.7062,
    '931_530': 34.7257,
    '931_531': 34.7492,
    '931_532': 34.7727,
    '931_533': 34.797,
    '931_534': 34.8212,
    '931_535': 34.8462,
    '931_536': 34.8749,
    '931_537': 34.9096,
    '931_538': 34.9466,
    '931_539': 34.9856,
    '931_540': 35.0237,
    '931_541': 35.058,
    '931_542': 35.0866,
    '931_543': 35.1092,
    '931_544': 35.1247,
    '931_545': 35.1333,
    '931_546': 35.1422,
    '931_547': 35.1528,
    '931_548': 35.1672,
    '931_549': 35.1848,
    '931_550': 35.2042,
    '931_551': 35.2237,
    '931_552': 35.2427,
    '931_553': 35.2636,
    '931_554': 35.2883,
    '931_555': 35.3196,
    '931_556': 35.3591,
    '931_557': 35.4029,
    '931_558': 35.4518,
    '931_559': 35.4978,
    '931_560': 35.5343,
    '931_561': 35.5672,
    '931_562': 35.5948,
    '931_563': 35.629,
    '931_564': 35.6665,
    '931_565': 35.7045,
    '931_566': 35.7417,
    '931_567': 35.7827,
    '931_568': 35.8268,
    '931_569': 35.8821,
    '931_570': 35.9537,
    '931_571': 36.023,
    '931_572': 36.0883,
    '931_573': 36.1656,
    '931_574': 36.2426,
    '931_575': 36.3293,
    '931_576': 36.4066,
    '931_577': 36.4791,
    '931_578': 36.5113,
    '931_579': 36.5397,
    '931_580': 36.5776,
    '931_581': 36.6147,
    '931_582': 36.6541,
    '931_583': 36.6972,
    '931_584': 36.7569,
    '931_585': 36.7886,
    '931_586': 36.7832,
    '931_587': 36.7523,
    '931_588': 36.7149,
    '931_589': 36.6837,
    '931_590': 36.6374,
    '931_591': 36.6119,
    '931_592': 36.5945,
    '931_593': 36.5858,
    '931_594': 36.6033,
    '931_595': 36.6393,
    '931_596': 36.6874,
    '931_597': 36.7322,
    '931_598': 36.7576,
    '931_599': 36.7756,
    '931_600': 36.7831,
    '931_601': 36.7903,
    '931_602': 36.7918,
    '931_603': 36.775,
    '931_604': 36.7698,
    '931_605': 36.7884,
    '931_606': 36.815,
    '931_607': 36.8185,
    '931_608': 36.8127,
    '931_609': 36.8144,
    '931_610': 36.801,
    '931_611': 36.7739,
    '931_612': 36.7599,
    '931_613': 36.7661,
    '931_614': 36.7756,
    '931_615': 36.7911,
    '931_616': 36.7936,
    '931_617': 36.8102,
    '931_618': 36.8199,
    '931_619': 36.8188,
    '931_620': 36.804,
    '931_621': 36.7783,
    '931_622': 36.7592,
    '931_623': 36.7475,
    '931_624': 36.7445,
    '931_625': 36.7391,
    '931_626': 36.7138,
    '931_627': 36.694,
    '931_628': 36.6808,
    '931_629': 36.6762,
    '931_630': 36.6762,
    '931_631': 36.6857,
    '931_632': 36.6985,
    '931_633': 36.7097,
    '931_634': 36.7174,
    '931_635': 36.7127,
    '931_636': 36.7257,
    '931_637': 36.7875,
    '931_638': 36.8346,
    '931_639': 36.845,
    '931_640': 36.8471,
    '931_641': 36.8364,
    '931_642': 36.8155,
    '931_643': 36.8177,
    '931_644': 36.816,
    '931_645': 36.8135,
    '931_646': 36.8581,
    '931_647': 36.9282,
    '931_648': 37.0201,
    '931_649': 37.138,
    '931_650': 37.2731,
    '931_651': 37.4316,
    '931_652': 37.5763,
    '931_653': 37.7017,
    '931_654': 37.8053,
    '931_655': 37.8878,
    '931_656': 37.9491,
    '931_657': 37.9818,
    '931_658': 37.9994,
    '931_659': 38.011,
    '931_660': 38.0008,
    '931_661': 37.9758,
    '931_662': 37.9577,
    '931_663': 37.9657,
    '931_664': 37.9791,
    '931_665': 38.0057,
    '931_666': 38.0378,
    '931_667': 38.0825,
    '931_668': 38.1228,
    '931_669': 38.1709,
    '931_670': 38.2299,
    '931_671': 38.2985,
    '931_672': 38.372,
    '931_673': 38.4543,
    '931_674': 38.5403,
    '931_675': 38.6315,
    '931_676': 38.7261,
    '931_677': 38.8295,
    '931_678': 38.9341,
    '931_679': 39.0371,
    '931_680': 39.1485,
    '931_681': 39.2677,
    '931_682': 39.3835,
    '931_683': 39.4981,
    '931_684': 39.6189,
    '931_685': 39.7555,
    '931_686': 39.8987,
    '931_687': 40.0405,
    '931_688': 40.1828,
    '931_689': 40.3243,
    '931_690': 40.4623,
    '931_691': 40.591,
    '931_692': 40.7094,
    '931_693': 40.829,
    '931_694': 40.942,
    '931_695': 41.0302,
    '931_696': 41.1199,
    '931_697': 41.2251,
    '931_698': 41.3405,
    '931_699': 41.4762,
    '931_700': 41.6292,
    '931_701': 41.7974,
    '931_702': 41.9876,
    '931_703': 42.1893,
    '931_704': 42.3951,
    '931_705': 42.5641,
    '931_706': 42.7067,
    '931_707': 42.8153,
    '931_708': 42.8973,
    '931_709': 42.9614,
    '931_710': 42.9991,
    '931_711': 43.0151,
    '931_712': 43.022,
    '931_713': 43.0478,
    '931_714': 43.102,
    '931_715': 43.1878,
    '931_716': 43.3084,
    '931_717': 43.4561,
    '931_718': 43.6221,
    '931_719': 43.795,
    '931_720': 43.9461,
    '931_721': 44.1065,
    '931_722': 44.2206,
    '931_723': 44.3407,
    '931_724': 44.4482,
    '931_725': 44.5137,
    '931_726': 44.562,
    '931_727': 44.5865,
    '931_728': 44.5267,
    '931_729': 44.461,
    '931_730': 44.3005,
    '931_731': 44.0945,
    '931_732': 43.9014,
    '931_733': 43.7029,
    '931_734': 43.4824,
    '931_735': 43.279,
    '931_736': 43.045,
    '931_737': 42.8199,
    '931_738': 42.6577,
    '931_739': 42.5853,
    '931_740': 42.5882,
    '931_741': 42.6275,
    '931_742': 42.6698,
    '931_743': 42.7156,
    '931_744': 42.7638,
    '931_745': 42.8078,
    '931_746': 42.8345,
    '931_747': 42.8535,
    '931_748': 42.8595,
    '931_749': 42.8473,
    '931_750': 42.8146,
    '931_751': 42.7822,
    '931_752': 42.7324,
    '931_753': 42.6653,
    '931_754': 42.5895,
    '931_755': 42.5188,
    '931_756': 42.4462,
    '931_757': 42.3769,
    '931_758': 42.2963,
    '931_759': 42.1966,
    '931_760': 42.0838,
    '931_761': 41.9527,
    '931_762': 41.8062,
    '931_763': 41.6387,
    '931_764': 41.4617,
    '931_765': 41.2765,
    '931_766': 41.0625,
    '931_767': 40.799,
    '931_768': 40.4908,
    '931_769': 40.1855,
    '931_770': 39.905,
    '931_771': 39.6224,
    '931_772': 39.3315,
    '931_773': 39.0514,
    '931_774': 38.7688,
    '931_775': 38.4939,
    '931_776': 38.2354,
    '931_777': 37.9895,
    '931_778': 37.7541,
    '931_779': 37.5355,
    '931_780': 37.3319,
    '931_781': 37.1412,
    '931_782': 36.9643,
    '931_783': 36.8006,
    '931_784': 36.6482,
    '931_785': 36.5075,
    '931_786': 36.3776,
    '931_787': 36.2533,
    '931_788': 36.133,
    '931_789': 36.0157,
    '931_790': 35.9003,
    '931_791': 35.7866,
    '931_792': 35.6729,
    '931_793': 35.5559,
    '931_794': 35.4267,
    '931_795': 35.3024,
    '931_796': 35.1857,
    '931_797': 35.068,
    '931_798': 34.9505,
    '931_799': 34.8317,
    '931_800': 34.7104,
    '931_801': 34.5868,
    '931_802': 34.4638,
    '931_803': 34.3446,
    '931_804': 34.2296,
    '931_805': 34.1177,
    '931_806': 34.0061,
    '931_807': 33.8941,
    '931_808': 33.7825,
    '931_809': 33.6716,
    '931_810': 33.5608,
    '931_811': 33.446,
    '931_812': 33.3244,
    '931_813': 33.2021,
    '931_814': 33.0785,
    '931_815': 32.9518,
    '931_816': 32.821,
    '931_817': 32.686,
    '931_818': 32.5478,
    '931_819': 32.4064,
    '931_820': 32.2606,
    '931_821': 32.109,
    '931_822': 31.9506,
    '931_823': 31.7846,
    '931_824': 31.6105,
    '931_825': 31.4279,
    '931_826': 31.2362,
    '931_827': 31.0353,
    '931_828': 30.8253,
    '931_829': 30.6066,
    '931_830': 30.3819,
    '931_831': 30.1611,
    '931_832': 29.9159,
    '931_833': 29.6722,
    '931_834': 29.4337,
    '931_835': 29.1958,
    '931_836': 28.9494,
    '931_837': 28.6972,
    '931_838': 28.441,
    '931_839': 28.1788,
    '931_840': 27.9085,
    '931_841': 27.6267,
    '931_842': 27.3386,
    '931_843': 27.0405,
    '931_844': 26.7301,
    '931_845': 26.4041,
    '931_846': 26.0621,
    '931_847': 25.7124,
    '931_848': 25.3536,
    '932_472': 31.634,
    '932_473': 31.6686,
    '932_474': 31.6993,
    '932_475': 31.7299,
    '932_476': 31.7715,
    '932_477': 31.82,
    '932_478': 31.8714,
    '932_479': 31.933,
    '932_480': 31.9995,
    '932_481': 32.0679,
    '932_482': 32.1356,
    '932_483': 32.1969,
    '932_484': 32.2542,
    '932_485': 32.3098,
    '932_486': 32.3664,
    '932_487': 32.4232,
    '932_488': 32.4824,
    '932_489': 32.5465,
    '932_490': 32.6165,
    '932_491': 32.694,
    '932_492': 32.7674,
    '932_493': 32.835,
    '932_494': 32.8982,
    '932_495': 32.9581,
    '932_496': 33.0155,
    '932_497': 33.0737,
    '932_498': 33.1318,
    '932_499': 33.1916,
    '932_500': 33.2543,
    '932_501': 33.3155,
    '932_502': 33.3821,
    '932_503': 33.4461,
    '932_504': 33.5128,
    '932_505': 33.5799,
    '932_506': 33.6461,
    '932_507': 33.7104,
    '932_508': 33.7713,
    '932_509': 33.8279,
    '932_510': 33.8943,
    '932_511': 33.9649,
    '932_512': 34.0348,
    '932_513': 34.0954,
    '932_514': 34.1496,
    '932_515': 34.2008,
    '932_516': 34.2522,
    '932_517': 34.3113,
    '932_518': 34.374,
    '932_519': 34.438,
    '932_520': 34.4962,
    '932_521': 34.5519,
    '932_522': 34.6163,
    '932_523': 34.6726,
    '932_524': 34.7051,
    '932_525': 34.7059,
    '932_526': 34.6902,
    '932_527': 34.686,
    '932_528': 34.6882,
    '932_529': 34.6924,
    '932_530': 34.7051,
    '932_531': 34.7234,
    '932_532': 34.741,
    '932_533': 34.7588,
    '932_534': 34.7753,
    '932_535': 34.792,
    '932_536': 34.8126,
    '932_537': 34.8427,
    '932_538': 34.8763,
    '932_539': 34.911,
    '932_540': 34.9421,
    '932_541': 34.9698,
    '932_542': 34.9941,
    '932_543': 35.0146,
    '932_544': 35.0312,
    '932_545': 35.0443,
    '932_546': 35.0559,
    '932_547': 35.0664,
    '932_548': 35.0801,
    '932_549': 35.0956,
    '932_550': 35.1122,
    '932_551': 35.1297,
    '932_552': 35.148,
    '932_553': 35.1682,
    '932_554': 35.1914,
    '932_555': 35.2199,
    '932_556': 35.2568,
    '932_557': 35.2971,
    '932_558': 35.3405,
    '932_559': 35.3841,
    '932_560': 35.424,
    '932_561': 35.4593,
    '932_562': 35.493,
    '932_563': 35.5297,
    '932_564': 35.5691,
    '932_565': 35.6111,
    '932_566': 35.6559,
    '932_567': 35.7033,
    '932_568': 35.7526,
    '932_569': 35.8097,
    '932_570': 35.8758,
    '932_571': 35.9433,
    '932_572': 36.0065,
    '932_573': 36.0815,
    '932_574': 36.1527,
    '932_575': 36.2319,
    '932_576': 36.3134,
    '932_577': 36.3922,
    '932_578': 36.4189,
    '932_579': 36.4425,
    '932_580': 36.4793,
    '932_581': 36.5286,
    '932_582': 36.5747,
    '932_583': 36.6228,
    '932_584': 36.6797,
    '932_585': 36.7147,
    '932_586': 36.7184,
    '932_587': 36.6896,
    '932_588': 36.6587,
    '932_589': 36.6318,
    '932_590': 36.5973,
    '932_591': 36.5665,
    '932_592': 36.55,
    '932_593': 36.5452,
    '932_594': 36.5646,
    '932_595': 36.5989,
    '932_596': 36.6423,
    '932_597': 36.6762,
    '932_598': 36.701,
    '932_599': 36.7313,
    '932_600': 36.7509,
    '932_601': 36.7583,
    '932_602': 36.7559,
    '932_603': 36.7356,
    '932_604': 36.7285,
    '932_605': 36.7342,
    '932_606': 36.753,
    '932_607': 36.7578,
    '932_608': 36.74,
    '932_609': 36.7387,
    '932_610': 36.7184,
    '932_611': 36.6982,
    '932_612': 36.6878,
    '932_613': 36.6935,
    '932_614': 36.7175,
    '932_615': 36.7509,
    '932_616': 36.7555,
    '932_617': 36.7698,
    '932_618': 36.7724,
    '932_619': 36.7589,
    '932_620': 36.7448,
    '932_621': 36.7286,
    '932_622': 36.7097,
    '932_623': 36.7023,
    '932_624': 36.6973,
    '932_625': 36.6929,
    '932_626': 36.6815,
    '932_627': 36.6594,
    '932_628': 36.6476,
    '932_629': 36.647,
    '932_630': 36.6451,
    '932_631': 36.6487,
    '932_632': 36.6524,
    '932_633': 36.6655,
    '932_634': 36.6822,
    '932_635': 36.6847,
    '932_636': 36.6894,
    '932_637': 36.7436,
    '932_638': 36.7924,
    '932_639': 36.8095,
    '932_640': 36.8389,
    '932_641': 36.8521,
    '932_642': 36.8505,
    '932_643': 36.8605,
    '932_644': 36.8623,
    '932_645': 36.8686,
    '932_646': 36.917,
    '932_647': 37,
    '932_648': 37.0942,
    '932_649': 37.2202,
    '932_650': 37.355,
    '932_651': 37.5129,
    '932_652': 37.6593,
    '932_653': 37.7847,
    '932_654': 37.8789,
    '932_655': 37.9475,
    '932_656': 37.9956,
    '932_657': 38.0271,
    '932_658': 38.0439,
    '932_659': 38.0583,
    '932_660': 38.0534,
    '932_661': 38.042,
    '932_662': 38.0477,
    '932_663': 38.0643,
    '932_664': 38.08,
    '932_665': 38.0975,
    '932_666': 38.1267,
    '932_667': 38.1706,
    '932_668': 38.2087,
    '932_669': 38.2495,
    '932_670': 38.3073,
    '932_671': 38.3713,
    '932_672': 38.4442,
    '932_673': 38.5234,
    '932_674': 38.6078,
    '932_675': 38.6949,
    '932_676': 38.7858,
    '932_677': 38.8871,
    '932_678': 38.989,
    '932_679': 39.0894,
    '932_680': 39.1947,
    '932_681': 39.3142,
    '932_682': 39.4321,
    '932_683': 39.5474,
    '932_684': 39.6638,
    '932_685': 39.7867,
    '932_686': 39.9298,
    '932_687': 40.0685,
    '932_688': 40.2123,
    '932_689': 40.3483,
    '932_690': 40.4797,
    '932_691': 40.6091,
    '932_692': 40.7337,
    '932_693': 40.856,
    '932_694': 40.9695,
    '932_695': 41.0594,
    '932_696': 41.1574,
    '932_697': 41.2552,
    '932_698': 41.3582,
    '932_699': 41.491,
    '932_700': 41.6431,
    '932_701': 41.7986,
    '932_702': 41.9748,
    '932_703': 42.1726,
    '932_704': 42.3677,
    '932_705': 42.5462,
    '932_706': 42.6931,
    '932_707': 42.8194,
    '932_708': 42.9238,
    '932_709': 43,
    '932_710': 43.0459,
    '932_711': 43.0713,
    '932_712': 43.0815,
    '932_713': 43.0956,
    '932_714': 43.1279,
    '932_715': 43.191,
    '932_716': 43.2949,
    '932_717': 43.4329,
    '932_718': 43.591,
    '932_719': 43.7555,
    '932_720': 43.9057,
    '932_721': 44.0553,
    '932_722': 44.1865,
    '932_723': 44.318,
    '932_724': 44.4377,
    '932_725': 44.5406,
    '932_726': 44.5725,
    '932_727': 44.6209,
    '932_728': 44.593,
    '932_729': 44.4601,
    '932_730': 44.3325,
    '932_731': 44.1499,
    '932_732': 43.9646,
    '932_733': 43.7609,
    '932_734': 43.5545,
    '932_735': 43.3494,
    '932_736': 43.1086,
    '932_737': 42.868,
    '932_738': 42.6798,
    '932_739': 42.5838,
    '932_740': 42.572,
    '932_741': 42.597,
    '932_742': 42.633,
    '932_743': 42.6732,
    '932_744': 42.7258,
    '932_745': 42.7796,
    '932_746': 42.8268,
    '932_747': 42.8583,
    '932_748': 42.8739,
    '932_749': 42.8768,
    '932_750': 42.8619,
    '932_751': 42.8324,
    '932_752': 42.7723,
    '932_753': 42.6976,
    '932_754': 42.5967,
    '932_755': 42.5037,
    '932_756': 42.4205,
    '932_757': 42.3474,
    '932_758': 42.2757,
    '932_759': 42.1894,
    '932_760': 42.0787,
    '932_761': 41.9474,
    '932_762': 41.7965,
    '932_763': 41.6219,
    '932_764': 41.4292,
    '932_765': 41.2368,
    '932_766': 41.0243,
    '932_767': 40.7632,
    '932_768': 40.4688,
    '932_769': 40.1874,
    '932_770': 39.9249,
    '932_771': 39.6508,
    '932_772': 39.3729,
    '932_773': 39.0937,
    '932_774': 38.8092,
    '932_775': 38.5356,
    '932_776': 38.2734,
    '932_777': 38.0242,
    '932_778': 37.7891,
    '932_779': 37.57,
    '932_780': 37.3665,
    '932_781': 37.1785,
    '932_782': 37.0047,
    '932_783': 36.8447,
    '932_784': 36.6942,
    '932_785': 36.5545,
    '932_786': 36.4255,
    '932_787': 36.3036,
    '932_788': 36.185,
    '932_789': 36.0693,
    '932_790': 35.955,
    '932_791': 35.8423,
    '932_792': 35.7303,
    '932_793': 35.6219,
    '932_794': 35.488,
    '932_795': 35.3588,
    '932_796': 35.2439,
    '932_797': 35.1347,
    '932_798': 35.0205,
    '932_799': 34.9054,
    '932_800': 34.7876,
    '932_801': 34.6649,
    '932_802': 34.5422,
    '932_803': 34.4242,
    '932_804': 34.3101,
    '932_805': 34.1985,
    '932_806': 34.088,
    '932_807': 33.9788,
    '932_808': 33.8709,
    '932_809': 33.7618,
    '932_810': 33.6523,
    '932_811': 33.5398,
    '932_812': 33.4212,
    '932_813': 33.2992,
    '932_814': 33.1758,
    '932_815': 33.0493,
    '932_816': 32.9188,
    '932_817': 32.7842,
    '932_818': 32.6461,
    '932_819': 32.5062,
    '932_820': 32.3626,
    '932_821': 32.2139,
    '932_822': 32.0586,
    '932_823': 31.896,
    '932_824': 31.7256,
    '932_825': 31.5471,
    '932_826': 31.3601,
    '932_827': 31.1646,
    '932_828': 30.9607,
    '932_829': 30.7491,
    '932_830': 30.5313,
    '932_831': 30.3087,
    '932_832': 30.0709,
    '932_833': 29.8304,
    '932_834': 29.5921,
    '932_835': 29.3546,
    '932_836': 29.107,
    '932_837': 28.8545,
    '932_838': 28.5979,
    '932_839': 28.3307,
    '932_840': 28.0574,
    '932_841': 27.7723,
    '932_842': 27.4819,
    '932_843': 27.1814,
    '932_844': 26.869,
    '932_845': 26.5427,
    '932_846': 26.2019,
    '932_847': 25.8515,
    '932_848': 25.4895,
    '933_472': 31.6109,
    '933_473': 31.643,
    '933_474': 31.6701,
    '933_475': 31.7015,
    '933_476': 31.7401,
    '933_477': 31.7861,
    '933_478': 31.8371,
    '933_479': 31.898,
    '933_480': 31.9624,
    '933_481': 32.027,
    '933_482': 32.0913,
    '933_483': 32.1479,
    '933_484': 32.2012,
    '933_485': 32.2538,
    '933_486': 32.3091,
    '933_487': 32.3643,
    '933_488': 32.4225,
    '933_489': 32.4876,
    '933_490': 32.5626,
    '933_491': 32.6353,
    '933_492': 32.7044,
    '933_493': 32.7697,
    '933_494': 32.832,
    '933_495': 32.8927,
    '933_496': 32.9518,
    '933_497': 33.0111,
    '933_498': 33.0705,
    '933_499': 33.1306,
    '933_500': 33.1918,
    '933_501': 33.254,
    '933_502': 33.3204,
    '933_503': 33.3833,
    '933_504': 33.4464,
    '933_505': 33.5106,
    '933_506': 33.5772,
    '933_507': 33.6414,
    '933_508': 33.6992,
    '933_509': 33.7537,
    '933_510': 33.8138,
    '933_511': 33.8823,
    '933_512': 33.9552,
    '933_513': 34.0125,
    '933_514': 34.0635,
    '933_515': 34.1123,
    '933_516': 34.1627,
    '933_517': 34.2268,
    '933_518': 34.2978,
    '933_519': 34.3693,
    '933_520': 34.4338,
    '933_521': 34.4982,
    '933_522': 34.5665,
    '933_523': 34.6319,
    '933_524': 34.6752,
    '933_525': 34.6822,
    '933_526': 34.6756,
    '933_527': 34.6767,
    '933_528': 34.6858,
    '933_529': 34.69,
    '933_530': 34.6985,
    '933_531': 34.7088,
    '933_532': 34.7196,
    '933_533': 34.7297,
    '933_534': 34.738,
    '933_535': 34.7469,
    '933_536': 34.7585,
    '933_537': 34.7867,
    '933_538': 34.817,
    '933_539': 34.8458,
    '933_540': 34.8711,
    '933_541': 34.8942,
    '933_542': 34.9162,
    '933_543': 34.9346,
    '933_544': 34.9515,
    '933_545': 34.9652,
    '933_546': 34.9776,
    '933_547': 34.9886,
    '933_548': 35.0007,
    '933_549': 35.0135,
    '933_550': 35.0274,
    '933_551': 35.0426,
    '933_552': 35.0593,
    '933_553': 35.0786,
    '933_554': 35.0999,
    '933_555': 35.1248,
    '933_556': 35.1578,
    '933_557': 35.1943,
    '933_558': 35.2342,
    '933_559': 35.2767,
    '933_560': 35.3173,
    '933_561': 35.3556,
    '933_562': 35.3922,
    '933_563': 35.4318,
    '933_564': 35.4752,
    '933_565': 35.5221,
    '933_566': 35.5707,
    '933_567': 35.6208,
    '933_568': 35.6726,
    '933_569': 35.729,
    '933_570': 35.7919,
    '933_571': 35.8558,
    '933_572': 35.9265,
    '933_573': 35.992,
    '933_574': 36.0651,
    '933_575': 36.1431,
    '933_576': 36.2297,
    '933_577': 36.3104,
    '933_578': 36.3394,
    '933_579': 36.3604,
    '933_580': 36.3953,
    '933_581': 36.4533,
    '933_582': 36.4995,
    '933_583': 36.5417,
    '933_584': 36.5892,
    '933_585': 36.6292,
    '933_586': 36.6411,
    '933_587': 36.6183,
    '933_588': 36.5981,
    '933_589': 36.5805,
    '933_590': 36.5604,
    '933_591': 36.5249,
    '933_592': 36.5063,
    '933_593': 36.508,
    '933_594': 36.5279,
    '933_595': 36.5591,
    '933_596': 36.5943,
    '933_597': 36.6136,
    '933_598': 36.6393,
    '933_599': 36.6686,
    '933_600': 36.6957,
    '933_601': 36.7101,
    '933_602': 36.7139,
    '933_603': 36.6961,
    '933_604': 36.694,
    '933_605': 36.6962,
    '933_606': 36.6959,
    '933_607': 36.6967,
    '933_608': 36.6807,
    '933_609': 36.673,
    '933_610': 36.6524,
    '933_611': 36.6353,
    '933_612': 36.6217,
    '933_613': 36.6253,
    '933_614': 36.6423,
    '933_615': 36.6753,
    '933_616': 36.6898,
    '933_617': 36.717,
    '933_618': 36.7204,
    '933_619': 36.7083,
    '933_620': 36.6919,
    '933_621': 36.6807,
    '933_622': 36.6651,
    '933_623': 36.6564,
    '933_624': 36.6523,
    '933_625': 36.6454,
    '933_626': 36.6353,
    '933_627': 36.6214,
    '933_628': 36.6204,
    '933_629': 36.6308,
    '933_630': 36.621,
    '933_631': 36.6092,
    '933_632': 36.6123,
    '933_633': 36.6287,
    '933_634': 36.6448,
    '933_635': 36.6416,
    '933_636': 36.6489,
    '933_637': 36.7048,
    '933_638': 36.755,
    '933_639': 36.7758,
    '933_640': 36.8263,
    '933_641': 36.8521,
    '933_642': 36.8594,
    '933_643': 36.8821,
    '933_644': 36.8968,
    '933_645': 36.9211,
    '933_646': 36.9824,
    '933_647': 37.0784,
    '933_648': 37.1745,
    '933_649': 37.2991,
    '933_650': 37.4302,
    '933_651': 37.5787,
    '933_652': 37.7248,
    '933_653': 37.8486,
    '933_654': 37.9399,
    '933_655': 37.9993,
    '933_656': 38.0368,
    '933_657': 38.0609,
    '933_658': 38.0811,
    '933_659': 38.0987,
    '933_660': 38.107,
    '933_661': 38.126,
    '933_662': 38.1482,
    '933_663': 38.1701,
    '933_664': 38.1852,
    '933_665': 38.1965,
    '933_666': 38.2225,
    '933_667': 38.262,
    '933_668': 38.2997,
    '933_669': 38.3386,
    '933_670': 38.394,
    '933_671': 38.4532,
    '933_672': 38.5217,
    '933_673': 38.6005,
    '933_674': 38.6855,
    '933_675': 38.7689,
    '933_676': 38.8528,
    '933_677': 38.9455,
    '933_678': 39.0471,
    '933_679': 39.1451,
    '933_680': 39.2434,
    '933_681': 39.3582,
    '933_682': 39.4763,
    '933_683': 39.5905,
    '933_684': 39.7048,
    '933_685': 39.8227,
    '933_686': 39.9586,
    '933_687': 40.0886,
    '933_688': 40.2274,
    '933_689': 40.3582,
    '933_690': 40.4868,
    '933_691': 40.6241,
    '933_692': 40.7587,
    '933_693': 40.8867,
    '933_694': 41.0028,
    '933_695': 41.1039,
    '933_696': 41.2065,
    '933_697': 41.2956,
    '933_698': 41.3865,
    '933_699': 41.5175,
    '933_700': 41.6637,
    '933_701': 41.8069,
    '933_702': 41.9712,
    '933_703': 42.1611,
    '933_704': 42.3479,
    '933_705': 42.5258,
    '933_706': 42.6885,
    '933_707': 42.8337,
    '933_708': 42.9476,
    '933_709': 43.0427,
    '933_710': 43.1002,
    '933_711': 43.1331,
    '933_712': 43.1503,
    '933_713': 43.1625,
    '933_714': 43.1719,
    '933_715': 43.2099,
    '933_716': 43.2899,
    '933_717': 43.4116,
    '933_718': 43.5594,
    '933_719': 43.7167,
    '933_720': 43.8626,
    '933_721': 44.0022,
    '933_722': 44.1451,
    '933_723': 44.2764,
    '933_724': 44.4093,
    '933_725': 44.5117,
    '933_726': 44.563,
    '933_727': 44.6211,
    '933_728': 44.5844,
    '933_729': 44.4884,
    '933_730': 44.3666,
    '933_731': 44.2,
    '933_732': 44.0066,
    '933_733': 43.8096,
    '933_734': 43.6129,
    '933_735': 43.4035,
    '933_736': 43.1614,
    '933_737': 42.9088,
    '933_738': 42.6954,
    '933_739': 42.5709,
    '933_740': 42.5356,
    '933_741': 42.5446,
    '933_742': 42.5683,
    '933_743': 42.6102,
    '933_744': 42.6685,
    '933_745': 42.7301,
    '933_746': 42.7888,
    '933_747': 42.8415,
    '933_748': 42.8784,
    '933_749': 42.893,
    '933_750': 42.8842,
    '933_751': 42.8689,
    '933_752': 42.8079,
    '933_753': 42.7239,
    '933_754': 42.6139,
    '933_755': 42.5039,
    '933_756': 42.407,
    '933_757': 42.3225,
    '933_758': 42.2477,
    '933_759': 42.1625,
    '933_760': 42.0577,
    '933_761': 41.9244,
    '933_762': 41.7669,
    '933_763': 41.5879,
    '933_764': 41.3931,
    '933_765': 41.1876,
    '933_766': 40.9712,
    '933_767': 40.7235,
    '933_768': 40.456,
    '933_769': 40.2011,
    '933_770': 39.9497,
    '933_771': 39.686,
    '933_772': 39.4148,
    '933_773': 39.1318,
    '933_774': 38.8482,
    '933_775': 38.5742,
    '933_776': 38.3118,
    '933_777': 38.0624,
    '933_778': 37.8283,
    '933_779': 37.6104,
    '933_780': 37.4082,
    '933_781': 37.2225,
    '933_782': 37.0519,
    '933_783': 36.8941,
    '933_784': 36.7466,
    '933_785': 36.61,
    '933_786': 36.4818,
    '933_787': 36.3609,
    '933_788': 36.2444,
    '933_789': 36.1295,
    '933_790': 36.0162,
    '933_791': 35.9063,
    '933_792': 35.8,
    '933_793': 35.6868,
    '933_794': 35.5516,
    '933_795': 35.4244,
    '933_796': 35.3081,
    '933_797': 35.2017,
    '933_798': 35.095,
    '933_799': 34.9866,
    '933_800': 34.8745,
    '933_801': 34.7517,
    '933_802': 34.6302,
    '933_803': 34.5136,
    '933_804': 34.4013,
    '933_805': 34.2917,
    '933_806': 34.1834,
    '933_807': 34.077,
    '933_808': 33.9721,
    '933_809': 33.865,
    '933_810': 33.7564,
    '933_811': 33.6454,
    '933_812': 33.5295,
    '933_813': 33.4086,
    '933_814': 33.2857,
    '933_815': 33.1591,
    '933_816': 33.0284,
    '933_817': 32.894,
    '933_818': 32.756,
    '933_819': 32.6167,
    '933_820': 32.4746,
    '933_821': 32.328,
    '933_822': 32.1755,
    '933_823': 32.016,
    '933_824': 31.849,
    '933_825': 31.6743,
    '933_826': 31.4918,
    '933_827': 31.3013,
    '933_828': 31.1034,
    '933_829': 30.8986,
    '933_830': 30.6871,
    '933_831': 30.4663,
    '933_832': 30.2297,
    '933_833': 29.9955,
    '933_834': 29.7517,
    '933_835': 29.5085,
    '933_836': 29.2615,
    '933_837': 29.0082,
    '933_838': 28.7503,
    '933_839': 28.4847,
    '933_840': 28.2067,
    '933_841': 27.9172,
    '933_842': 27.625,
    '933_843': 27.3226,
    '933_844': 27.0081,
    '933_845': 26.6808,
    '933_846': 26.3404,
    '933_847': 25.9872,
    '933_848': 25.6221,
    '934_472': 31.5832,
    '934_473': 31.6098,
    '934_474': 31.6373,
    '934_475': 31.6684,
    '934_476': 31.7068,
    '934_477': 31.7537,
    '934_478': 31.8067,
    '934_479': 31.8657,
    '934_480': 31.9272,
    '934_481': 31.9873,
    '934_482': 32.0448,
    '934_483': 32.0981,
    '934_484': 32.1489,
    '934_485': 32.1997,
    '934_486': 32.2528,
    '934_487': 32.3068,
    '934_488': 32.3639,
    '934_489': 32.4295,
    '934_490': 32.5061,
    '934_491': 32.5758,
    '934_492': 32.6416,
    '934_493': 32.7047,
    '934_494': 32.7664,
    '934_495': 32.8281,
    '934_496': 32.8888,
    '934_497': 32.9489,
    '934_498': 33.0086,
    '934_499': 33.0678,
    '934_500': 33.1282,
    '934_501': 33.1915,
    '934_502': 33.2576,
    '934_503': 33.3244,
    '934_504': 33.3849,
    '934_505': 33.4446,
    '934_506': 33.5087,
    '934_507': 33.5737,
    '934_508': 33.6312,
    '934_509': 33.6857,
    '934_510': 33.7433,
    '934_511': 33.8052,
    '934_512': 33.8738,
    '934_513': 33.9308,
    '934_514': 33.9825,
    '934_515': 34.0329,
    '934_516': 34.0858,
    '934_517': 34.1478,
    '934_518': 34.2199,
    '934_519': 34.2919,
    '934_520': 34.36,
    '934_521': 34.4274,
    '934_522': 34.4961,
    '934_523': 34.5671,
    '934_524': 34.6147,
    '934_525': 34.6288,
    '934_526': 34.6307,
    '934_527': 34.6405,
    '934_528': 34.6536,
    '934_529': 34.6676,
    '934_530': 34.6815,
    '934_531': 34.6952,
    '934_532': 34.7019,
    '934_533': 34.7055,
    '934_534': 34.7101,
    '934_535': 34.7178,
    '934_536': 34.7275,
    '934_537': 34.747,
    '934_538': 34.7696,
    '934_539': 34.7915,
    '934_540': 34.8105,
    '934_541': 34.8291,
    '934_542': 34.847,
    '934_543': 34.8632,
    '934_544': 34.8805,
    '934_545': 34.8979,
    '934_546': 34.9103,
    '934_547': 34.9195,
    '934_548': 34.9297,
    '934_549': 34.9404,
    '934_550': 34.9516,
    '934_551': 34.9647,
    '934_552': 34.9797,
    '934_553': 34.9988,
    '934_554': 35.0188,
    '934_555': 35.0414,
    '934_556': 35.0704,
    '934_557': 35.1015,
    '934_558': 35.138,
    '934_559': 35.1774,
    '934_560': 35.2188,
    '934_561': 35.2593,
    '934_562': 35.2997,
    '934_563': 35.342,
    '934_564': 35.3862,
    '934_565': 35.4383,
    '934_566': 35.4886,
    '934_567': 35.5401,
    '934_568': 35.5939,
    '934_569': 35.6495,
    '934_570': 35.7091,
    '934_571': 35.7726,
    '934_572': 35.839,
    '934_573': 35.9056,
    '934_574': 35.9821,
    '934_575': 36.0662,
    '934_576': 36.1494,
    '934_577': 36.2205,
    '934_578': 36.2585,
    '934_579': 36.2837,
    '934_580': 36.3201,
    '934_581': 36.3786,
    '934_582': 36.4313,
    '934_583': 36.4708,
    '934_584': 36.4974,
    '934_585': 36.5344,
    '934_586': 36.5514,
    '934_587': 36.5439,
    '934_588': 36.5314,
    '934_589': 36.5194,
    '934_590': 36.509,
    '934_591': 36.4856,
    '934_592': 36.4708,
    '934_593': 36.4767,
    '934_594': 36.4993,
    '934_595': 36.5226,
    '934_596': 36.543,
    '934_597': 36.5516,
    '934_598': 36.5753,
    '934_599': 36.6039,
    '934_600': 36.6347,
    '934_601': 36.6486,
    '934_602': 36.6573,
    '934_603': 36.6522,
    '934_604': 36.6482,
    '934_605': 36.657,
    '934_606': 36.6556,
    '934_607': 36.6488,
    '934_608': 36.6358,
    '934_609': 36.6233,
    '934_610': 36.6054,
    '934_611': 36.5837,
    '934_612': 36.5664,
    '934_613': 36.566,
    '934_614': 36.5746,
    '934_615': 36.5945,
    '934_616': 36.6132,
    '934_617': 36.6407,
    '934_618': 36.6572,
    '934_619': 36.6492,
    '934_620': 36.6434,
    '934_621': 36.6375,
    '934_622': 36.6256,
    '934_623': 36.617,
    '934_624': 36.6091,
    '934_625': 36.6031,
    '934_626': 36.5939,
    '934_627': 36.5846,
    '934_628': 36.5821,
    '934_629': 36.5885,
    '934_630': 36.5808,
    '934_631': 36.5739,
    '934_632': 36.579,
    '934_633': 36.5932,
    '934_634': 36.6012,
    '934_635': 36.5983,
    '934_636': 36.6111,
    '934_637': 36.665,
    '934_638': 36.71,
    '934_639': 36.7431,
    '934_640': 36.8051,
    '934_641': 36.841,
    '934_642': 36.8546,
    '934_643': 36.8888,
    '934_644': 36.9174,
    '934_645': 36.9691,
    '934_646': 37.0572,
    '934_647': 37.1606,
    '934_648': 37.2627,
    '934_649': 37.3837,
    '934_650': 37.5112,
    '934_651': 37.6424,
    '934_652': 37.7793,
    '934_653': 37.9025,
    '934_654': 37.9882,
    '934_655': 38.0445,
    '934_656': 38.0786,
    '934_657': 38.1039,
    '934_658': 38.1321,
    '934_659': 38.1547,
    '934_660': 38.1822,
    '934_661': 38.2122,
    '934_662': 38.2495,
    '934_663': 38.2781,
    '934_664': 38.289,
    '934_665': 38.2987,
    '934_666': 38.3226,
    '934_667': 38.366,
    '934_668': 38.3997,
    '934_669': 38.4364,
    '934_670': 38.4834,
    '934_671': 38.5406,
    '934_672': 38.6089,
    '934_673': 38.6845,
    '934_674': 38.7593,
    '934_675': 38.8374,
    '934_676': 38.9169,
    '934_677': 39.0033,
    '934_678': 39.1015,
    '934_679': 39.1999,
    '934_680': 39.2919,
    '934_681': 39.3967,
    '934_682': 39.5109,
    '934_683': 39.6244,
    '934_684': 39.7392,
    '934_685': 39.8629,
    '934_686': 39.9942,
    '934_687': 40.1152,
    '934_688': 40.2374,
    '934_689': 40.3635,
    '934_690': 40.4987,
    '934_691': 40.6427,
    '934_692': 40.7806,
    '934_693': 40.912,
    '934_694': 41.0334,
    '934_695': 41.1466,
    '934_696': 41.2505,
    '934_697': 41.336,
    '934_698': 41.4195,
    '934_699': 41.5448,
    '934_700': 41.684,
    '934_701': 41.819,
    '934_702': 41.9791,
    '934_703': 42.158,
    '934_704': 42.3349,
    '934_705': 42.5163,
    '934_706': 42.6907,
    '934_707': 42.8515,
    '934_708': 42.9824,
    '934_709': 43.0901,
    '934_710': 43.1586,
    '934_711': 43.2028,
    '934_712': 43.2254,
    '934_713': 43.2339,
    '934_714': 43.2344,
    '934_715': 43.2431,
    '934_716': 43.2941,
    '934_717': 43.3954,
    '934_718': 43.5306,
    '934_719': 43.6813,
    '934_720': 43.8252,
    '934_721': 43.9611,
    '934_722': 44.1054,
    '934_723': 44.2457,
    '934_724': 44.3889,
    '934_725': 44.5203,
    '934_726': 44.5654,
    '934_727': 44.5918,
    '934_728': 44.5652,
    '934_729': 44.5402,
    '934_730': 44.3785,
    '934_731': 44.2426,
    '934_732': 44.0514,
    '934_733': 43.8593,
    '934_734': 43.6705,
    '934_735': 43.4697,
    '934_736': 43.2189,
    '934_737': 42.9453,
    '934_738': 42.712,
    '934_739': 42.5652,
    '934_740': 42.5005,
    '934_741': 42.4817,
    '934_742': 42.4921,
    '934_743': 42.5341,
    '934_744': 42.6004,
    '934_745': 42.6748,
    '934_746': 42.7385,
    '934_747': 42.8017,
    '934_748': 42.8577,
    '934_749': 42.8925,
    '934_750': 42.9084,
    '934_751': 42.8959,
    '934_752': 42.836,
    '934_753': 42.7571,
    '934_754': 42.6356,
    '934_755': 42.5172,
    '934_756': 42.413,
    '934_757': 42.3166,
    '934_758': 42.2312,
    '934_759': 42.1381,
    '934_760': 42.0292,
    '934_761': 41.8955,
    '934_762': 41.7344,
    '934_763': 41.5511,
    '934_764': 41.3533,
    '934_765': 41.1451,
    '934_766': 40.9262,
    '934_767': 40.6965,
    '934_768': 40.4559,
    '934_769': 40.221,
    '934_770': 39.9763,
    '934_771': 39.719,
    '934_772': 39.4528,
    '934_773': 39.1712,
    '934_774': 38.8902,
    '934_775': 38.6164,
    '934_776': 38.3541,
    '934_777': 38.1062,
    '934_778': 37.874,
    '934_779': 37.6587,
    '934_780': 37.4594,
    '934_781': 37.2767,
    '934_782': 37.1091,
    '934_783': 36.9529,
    '934_784': 36.808,
    '934_785': 36.6729,
    '934_786': 36.5468,
    '934_787': 36.4273,
    '934_788': 36.3119,
    '934_789': 36.198,
    '934_790': 36.0859,
    '934_791': 35.9776,
    '934_792': 35.8838,
    '934_793': 35.7625,
    '934_794': 35.6319,
    '934_795': 35.5028,
    '934_796': 35.3857,
    '934_797': 35.2775,
    '934_798': 35.1778,
    '934_799': 35.0763,
    '934_800': 34.9675,
    '934_801': 34.8455,
    '934_802': 34.7276,
    '934_803': 34.6149,
    '934_804': 34.5054,
    '934_805': 34.3987,
    '934_806': 34.2936,
    '934_807': 34.19,
    '934_808': 34.0866,
    '934_809': 33.9813,
    '934_810': 33.8737,
    '934_811': 33.7641,
    '934_812': 33.6507,
    '934_813': 33.5324,
    '934_814': 33.4112,
    '934_815': 33.2833,
    '934_816': 33.1519,
    '934_817': 33.0176,
    '934_818': 32.8802,
    '934_819': 32.7402,
    '934_820': 32.5987,
    '934_821': 32.4536,
    '934_822': 32.303,
    '934_823': 32.1459,
    '934_824': 31.9818,
    '934_825': 31.8103,
    '934_826': 31.6314,
    '934_827': 31.4451,
    '934_828': 31.2517,
    '934_829': 31.0519,
    '934_830': 30.8451,
    '934_831': 30.6274,
    '934_832': 30.3868,
    '934_833': 30.1654,
    '934_834': 29.9168,
    '934_835': 29.6677,
    '934_836': 29.4189,
    '934_837': 29.1603,
    '934_838': 28.8988,
    '934_839': 28.631,
    '934_840': 28.3505,
    '934_841': 28.0593,
    '934_842': 27.7696,
    '934_843': 27.4665,
    '934_844': 27.149,
    '934_845': 26.819,
    '934_846': 26.4767,
    '934_847': 26.1231,
    '934_848': 25.7574,
    '935_472': 31.549,
    '935_473': 31.5692,
    '935_474': 31.5955,
    '935_475': 31.6294,
    '935_476': 31.6702,
    '935_477': 31.7216,
    '935_478': 31.775,
    '935_479': 31.8319,
    '935_480': 31.8903,
    '935_481': 31.9449,
    '935_482': 31.9975,
    '935_483': 32.0482,
    '935_484': 32.0975,
    '935_485': 32.1477,
    '935_486': 32.1996,
    '935_487': 32.2531,
    '935_488': 32.3105,
    '935_489': 32.3808,
    '935_490': 32.4499,
    '935_491': 32.5156,
    '935_492': 32.5789,
    '935_493': 32.6403,
    '935_494': 32.7017,
    '935_495': 32.7642,
    '935_496': 32.826,
    '935_497': 32.8868,
    '935_498': 32.9462,
    '935_499': 33.0039,
    '935_500': 33.0634,
    '935_501': 33.1285,
    '935_502': 33.1929,
    '935_503': 33.2613,
    '935_504': 33.3256,
    '935_505': 33.3852,
    '935_506': 33.4483,
    '935_507': 33.5064,
    '935_508': 33.5676,
    '935_509': 33.6251,
    '935_510': 33.6828,
    '935_511': 33.7429,
    '935_512': 33.8025,
    '935_513': 33.8566,
    '935_514': 33.9082,
    '935_515': 33.9604,
    '935_516': 34.0151,
    '935_517': 34.0769,
    '935_518': 34.1455,
    '935_519': 34.2152,
    '935_520': 34.2829,
    '935_521': 34.3515,
    '935_522': 34.4186,
    '935_523': 34.4854,
    '935_524': 34.5331,
    '935_525': 34.5544,
    '935_526': 34.5669,
    '935_527': 34.5815,
    '935_528': 34.5982,
    '935_529': 34.6176,
    '935_530': 34.6342,
    '935_531': 34.6499,
    '935_532': 34.6623,
    '935_533': 34.6727,
    '935_534': 34.6826,
    '935_535': 34.6932,
    '935_536': 34.6995,
    '935_537': 34.7115,
    '935_538': 34.7254,
    '935_539': 34.7392,
    '935_540': 34.7533,
    '935_541': 34.767,
    '935_542': 34.7849,
    '935_543': 34.7991,
    '935_544': 34.8171,
    '935_545': 34.8389,
    '935_546': 34.8512,
    '935_547': 34.8579,
    '935_548': 34.8677,
    '935_549': 34.877,
    '935_550': 34.886,
    '935_551': 34.8982,
    '935_552': 34.9122,
    '935_553': 34.9284,
    '935_554': 34.9473,
    '935_555': 34.9689,
    '935_556': 34.9935,
    '935_557': 35.0172,
    '935_558': 35.0523,
    '935_559': 35.0914,
    '935_560': 35.1326,
    '935_561': 35.1743,
    '935_562': 35.2168,
    '935_563': 35.2616,
    '935_564': 35.3093,
    '935_565': 35.3611,
    '935_566': 35.4114,
    '935_567': 35.4636,
    '935_568': 35.5169,
    '935_569': 35.5717,
    '935_570': 35.6297,
    '935_571': 35.6911,
    '935_572': 35.7575,
    '935_573': 35.8249,
    '935_574': 35.9009,
    '935_575': 35.9813,
    '935_576': 36.0571,
    '935_577': 36.1225,
    '935_578': 36.1715,
    '935_579': 36.1996,
    '935_580': 36.2439,
    '935_581': 36.3029,
    '935_582': 36.3543,
    '935_583': 36.3956,
    '935_584': 36.4139,
    '935_585': 36.4367,
    '935_586': 36.4575,
    '935_587': 36.4674,
    '935_588': 36.4651,
    '935_589': 36.4574,
    '935_590': 36.4537,
    '935_591': 36.4449,
    '935_592': 36.4344,
    '935_593': 36.4439,
    '935_594': 36.4654,
    '935_595': 36.4745,
    '935_596': 36.4829,
    '935_597': 36.4945,
    '935_598': 36.5161,
    '935_599': 36.5479,
    '935_600': 36.5825,
    '935_601': 36.5902,
    '935_602': 36.5933,
    '935_603': 36.5971,
    '935_604': 36.6021,
    '935_605': 36.6222,
    '935_606': 36.6321,
    '935_607': 36.6273,
    '935_608': 36.6099,
    '935_609': 36.5834,
    '935_610': 36.5618,
    '935_611': 36.5368,
    '935_612': 36.5165,
    '935_613': 36.5093,
    '935_614': 36.5139,
    '935_615': 36.5318,
    '935_616': 36.5515,
    '935_617': 36.5743,
    '935_618': 36.5947,
    '935_619': 36.6005,
    '935_620': 36.6006,
    '935_621': 36.5975,
    '935_622': 36.5896,
    '935_623': 36.5805,
    '935_624': 36.5711,
    '935_625': 36.5619,
    '935_626': 36.5542,
    '935_627': 36.5495,
    '935_628': 36.5448,
    '935_629': 36.5443,
    '935_630': 36.5417,
    '935_631': 36.5412,
    '935_632': 36.5465,
    '935_633': 36.5556,
    '935_634': 36.5631,
    '935_635': 36.5667,
    '935_636': 36.5752,
    '935_637': 36.6136,
    '935_638': 36.6545,
    '935_639': 36.7146,
    '935_640': 36.7795,
    '935_641': 36.8173,
    '935_642': 36.8325,
    '935_643': 36.8832,
    '935_644': 36.928,
    '935_645': 37.0064,
    '935_646': 37.1171,
    '935_647': 37.2357,
    '935_648': 37.3529,
    '935_649': 37.4745,
    '935_650': 37.6042,
    '935_651': 37.7282,
    '935_652': 37.8416,
    '935_653': 37.9537,
    '935_654': 38.0334,
    '935_655': 38.0891,
    '935_656': 38.1258,
    '935_657': 38.1546,
    '935_658': 38.1935,
    '935_659': 38.2351,
    '935_660': 38.2797,
    '935_661': 38.3078,
    '935_662': 38.3529,
    '935_663': 38.3858,
    '935_664': 38.3926,
    '935_665': 38.3939,
    '935_666': 38.4273,
    '935_667': 38.4688,
    '935_668': 38.4959,
    '935_669': 38.5251,
    '935_670': 38.5664,
    '935_671': 38.6233,
    '935_672': 38.6906,
    '935_673': 38.7596,
    '935_674': 38.8296,
    '935_675': 38.9055,
    '935_676': 38.9869,
    '935_677': 39.0728,
    '935_678': 39.1577,
    '935_679': 39.2547,
    '935_680': 39.3437,
    '935_681': 39.4373,
    '935_682': 39.5486,
    '935_683': 39.6604,
    '935_684': 39.775,
    '935_685': 39.8991,
    '935_686': 40.0226,
    '935_687': 40.1332,
    '935_688': 40.2488,
    '935_689': 40.3789,
    '935_690': 40.516,
    '935_691': 40.6571,
    '935_692': 40.794,
    '935_693': 40.9285,
    '935_694': 41.0571,
    '935_695': 41.1808,
    '935_696': 41.2889,
    '935_697': 41.3736,
    '935_698': 41.457,
    '935_699': 41.5749,
    '935_700': 41.7117,
    '935_701': 41.8479,
    '935_702': 42.0019,
    '935_703': 42.1707,
    '935_704': 42.3371,
    '935_705': 42.5183,
    '935_706': 42.6914,
    '935_707': 42.8666,
    '935_708': 43.0068,
    '935_709': 43.1294,
    '935_710': 43.2232,
    '935_711': 43.2793,
    '935_712': 43.3044,
    '935_713': 43.3093,
    '935_714': 43.2979,
    '935_715': 43.2852,
    '935_716': 43.3088,
    '935_717': 43.3875,
    '935_718': 43.5077,
    '935_719': 43.648,
    '935_720': 43.7902,
    '935_721': 43.932,
    '935_722': 44.079,
    '935_723': 44.2415,
    '935_724': 44.3865,
    '935_725': 44.4816,
    '935_726': 44.5729,
    '935_727': 44.6021,
    '935_728': 44.5898,
    '935_729': 44.564,
    '935_730': 44.4045,
    '935_731': 44.2881,
    '935_732': 44.0988,
    '935_733': 43.9031,
    '935_734': 43.7111,
    '935_735': 43.495,
    '935_736': 43.2601,
    '935_737': 42.981,
    '935_738': 42.7348,
    '935_739': 42.5722,
    '935_740': 42.4852,
    '935_741': 42.4439,
    '935_742': 42.4381,
    '935_743': 42.4691,
    '935_744': 42.5292,
    '935_745': 42.6054,
    '935_746': 42.6798,
    '935_747': 42.7541,
    '935_748': 42.8215,
    '935_749': 42.8794,
    '935_750': 42.9092,
    '935_751': 42.9003,
    '935_752': 42.8561,
    '935_753': 42.774,
    '935_754': 42.6623,
    '935_755': 42.5394,
    '935_756': 42.4292,
    '935_757': 42.3237,
    '935_758': 42.228,
    '935_759': 42.1243,
    '935_760': 42.0039,
    '935_761': 41.8631,
    '935_762': 41.7008,
    '935_763': 41.5198,
    '935_764': 41.3211,
    '935_765': 41.1096,
    '935_766': 40.8994,
    '935_767': 40.6908,
    '935_768': 40.4771,
    '935_769': 40.247,
    '935_770': 40.0089,
    '935_771': 39.7551,
    '935_772': 39.489,
    '935_773': 39.2124,
    '935_774': 38.9334,
    '935_775': 38.6626,
    '935_776': 38.4033,
    '935_777': 38.1566,
    '935_778': 37.9272,
    '935_779': 37.7145,
    '935_780': 37.5188,
    '935_781': 37.3401,
    '935_782': 37.1742,
    '935_783': 37.019,
    '935_784': 36.8755,
    '935_785': 36.7424,
    '935_786': 36.6178,
    '935_787': 36.5002,
    '935_788': 36.3856,
    '935_789': 36.2729,
    '935_790': 36.1612,
    '935_791': 36.0519,
    '935_792': 35.9464,
    '935_793': 35.8349,
    '935_794': 35.7155,
    '935_795': 35.5876,
    '935_796': 35.4749,
    '935_797': 35.369,
    '935_798': 35.2675,
    '935_799': 35.1655,
    '935_800': 35.0631,
    '935_801': 34.9491,
    '935_802': 34.837,
    '935_803': 34.728,
    '935_804': 34.6217,
    '935_805': 34.5182,
    '935_806': 34.4162,
    '935_807': 34.3148,
    '935_808': 34.2129,
    '935_809': 34.109,
    '935_810': 34.0032,
    '935_811': 33.8954,
    '935_812': 33.7843,
    '935_813': 33.668,
    '935_814': 33.5471,
    '935_815': 33.42,
    '935_816': 33.2883,
    '935_817': 33.1543,
    '935_818': 33.017,
    '935_819': 32.8768,
    '935_820': 32.7343,
    '935_821': 32.5893,
    '935_822': 32.4396,
    '935_823': 32.2841,
    '935_824': 32.1219,
    '935_825': 31.9529,
    '935_826': 31.7767,
    '935_827': 31.5932,
    '935_828': 31.4026,
    '935_829': 31.2048,
    '935_830': 30.9985,
    '935_831': 30.7807,
    '935_832': 30.5488,
    '935_833': 30.3173,
    '935_834': 30.0761,
    '935_835': 29.8289,
    '935_836': 29.5781,
    '935_837': 29.3137,
    '935_838': 29.0485,
    '935_839': 28.7795,
    '935_840': 28.4985,
    '935_841': 28.2013,
    '935_842': 27.9165,
    '935_843': 27.612,
    '935_844': 27.2902,
    '935_845': 26.9561,
    '935_846': 26.6102,
    '935_847': 26.254,
    '935_848': 25.887,
    '936_472': 31.5073,
    '936_473': 31.5262,
    '936_474': 31.5552,
    '936_475': 31.5905,
    '936_476': 31.6331,
    '936_477': 31.688,
    '936_478': 31.7389,
    '936_479': 31.7917,
    '936_480': 31.8461,
    '936_481': 31.8977,
    '936_482': 31.948,
    '936_483': 31.9974,
    '936_484': 32.0459,
    '936_485': 32.0959,
    '936_486': 32.1482,
    '936_487': 32.2036,
    '936_488': 32.2637,
    '936_489': 32.3291,
    '936_490': 32.3922,
    '936_491': 32.4541,
    '936_492': 32.5154,
    '936_493': 32.5762,
    '936_494': 32.638,
    '936_495': 32.7007,
    '936_496': 32.7629,
    '936_497': 32.8241,
    '936_498': 32.8833,
    '936_499': 32.9408,
    '936_500': 33.0036,
    '936_501': 33.0729,
    '936_502': 33.1373,
    '936_503': 33.2057,
    '936_504': 33.2707,
    '936_505': 33.3314,
    '936_506': 33.3916,
    '936_507': 33.4459,
    '936_508': 33.5066,
    '936_509': 33.5675,
    '936_510': 33.6244,
    '936_511': 33.682,
    '936_512': 33.7393,
    '936_513': 33.7949,
    '936_514': 33.843,
    '936_515': 33.8951,
    '936_516': 33.9486,
    '936_517': 34.0096,
    '936_518': 34.0757,
    '936_519': 34.1433,
    '936_520': 34.2092,
    '936_521': 34.2755,
    '936_522': 34.3398,
    '936_523': 34.3995,
    '936_524': 34.4465,
    '936_525': 34.4726,
    '936_526': 34.4915,
    '936_527': 34.5091,
    '936_528': 34.5278,
    '936_529': 34.5494,
    '936_530': 34.5711,
    '936_531': 34.5917,
    '936_532': 34.6103,
    '936_533': 34.6288,
    '936_534': 34.6473,
    '936_535': 34.6639,
    '936_536': 34.6709,
    '936_537': 34.6764,
    '936_538': 34.6838,
    '936_539': 34.6932,
    '936_540': 34.704,
    '936_541': 34.7136,
    '936_542': 34.7288,
    '936_543': 34.7433,
    '936_544': 34.7602,
    '936_545': 34.7813,
    '936_546': 34.7966,
    '936_547': 34.807,
    '936_548': 34.8168,
    '936_549': 34.8255,
    '936_550': 34.8342,
    '936_551': 34.8451,
    '936_552': 34.8535,
    '936_553': 34.8643,
    '936_554': 34.881,
    '936_555': 34.901,
    '936_556': 34.9214,
    '936_557': 34.9442,
    '936_558': 34.9769,
    '936_559': 35.0155,
    '936_560': 35.056,
    '936_561': 35.0975,
    '936_562': 35.1406,
    '936_563': 35.1869,
    '936_564': 35.2373,
    '936_565': 35.291,
    '936_566': 35.3403,
    '936_567': 35.392,
    '936_568': 35.4437,
    '936_569': 35.4959,
    '936_570': 35.55,
    '936_571': 35.608,
    '936_572': 35.6706,
    '936_573': 35.738,
    '936_574': 35.8109,
    '936_575': 35.8857,
    '936_576': 35.9632,
    '936_577': 36.0184,
    '936_578': 36.0718,
    '936_579': 36.1055,
    '936_580': 36.1596,
    '936_581': 36.2218,
    '936_582': 36.2773,
    '936_583': 36.3196,
    '936_584': 36.3299,
    '936_585': 36.3418,
    '936_586': 36.3656,
    '936_587': 36.3835,
    '936_588': 36.3968,
    '936_589': 36.402,
    '936_590': 36.3953,
    '936_591': 36.3993,
    '936_592': 36.389,
    '936_593': 36.3915,
    '936_594': 36.4094,
    '936_595': 36.4201,
    '936_596': 36.4234,
    '936_597': 36.4383,
    '936_598': 36.4644,
    '936_599': 36.5009,
    '936_600': 36.5331,
    '936_601': 36.5415,
    '936_602': 36.5364,
    '936_603': 36.5435,
    '936_604': 36.5628,
    '936_605': 36.5926,
    '936_606': 36.6117,
    '936_607': 36.6212,
    '936_608': 36.6005,
    '936_609': 36.5537,
    '936_610': 36.5208,
    '936_611': 36.4904,
    '936_612': 36.4702,
    '936_613': 36.4603,
    '936_614': 36.4646,
    '936_615': 36.4784,
    '936_616': 36.5011,
    '936_617': 36.5242,
    '936_618': 36.5471,
    '936_619': 36.5632,
    '936_620': 36.5683,
    '936_621': 36.5644,
    '936_622': 36.5551,
    '936_623': 36.5448,
    '936_624': 36.5349,
    '936_625': 36.5255,
    '936_626': 36.5193,
    '936_627': 36.5137,
    '936_628': 36.5089,
    '936_629': 36.5069,
    '936_630': 36.5067,
    '936_631': 36.5089,
    '936_632': 36.5169,
    '936_633': 36.5266,
    '936_634': 36.5393,
    '936_635': 36.545,
    '936_636': 36.5429,
    '936_637': 36.5652,
    '936_638': 36.6072,
    '936_639': 36.6748,
    '936_640': 36.7367,
    '936_641': 36.7749,
    '936_642': 36.7943,
    '936_643': 36.8608,
    '936_644': 36.9296,
    '936_645': 37.0348,
    '936_646': 37.1641,
    '936_647': 37.3015,
    '936_648': 37.4382,
    '936_649': 37.5624,
    '936_650': 37.6974,
    '936_651': 37.8191,
    '936_652': 37.9243,
    '936_653': 38.02,
    '936_654': 38.0895,
    '936_655': 38.1427,
    '936_656': 38.184,
    '936_657': 38.2249,
    '936_658': 38.2692,
    '936_659': 38.3174,
    '936_660': 38.3708,
    '936_661': 38.4096,
    '936_662': 38.45,
    '936_663': 38.4786,
    '936_664': 38.4822,
    '936_665': 38.4852,
    '936_666': 38.5245,
    '936_667': 38.558,
    '936_668': 38.5827,
    '936_669': 38.6142,
    '936_670': 38.6545,
    '936_671': 38.7097,
    '936_672': 38.7716,
    '936_673': 38.8346,
    '936_674': 38.9118,
    '936_675': 38.9966,
    '936_676': 39.0729,
    '936_677': 39.1487,
    '936_678': 39.2187,
    '936_679': 39.3102,
    '936_680': 39.396,
    '936_681': 39.4825,
    '936_682': 39.5909,
    '936_683': 39.7005,
    '936_684': 39.8125,
    '936_685': 39.9314,
    '936_686': 40.0432,
    '936_687': 40.1474,
    '936_688': 40.2703,
    '936_689': 40.3991,
    '936_690': 40.5323,
    '936_691': 40.6718,
    '936_692': 40.8075,
    '936_693': 40.9435,
    '936_694': 41.0789,
    '936_695': 41.2067,
    '936_696': 41.3205,
    '936_697': 41.4106,
    '936_698': 41.4991,
    '936_699': 41.6164,
    '936_700': 41.7574,
    '936_701': 41.9047,
    '936_702': 42.0576,
    '936_703': 42.2142,
    '936_704': 42.3619,
    '936_705': 42.5275,
    '936_706': 42.6973,
    '936_707': 42.8751,
    '936_708': 43.0299,
    '936_709': 43.1542,
    '936_710': 43.2675,
    '936_711': 43.3462,
    '936_712': 43.3808,
    '936_713': 43.3769,
    '936_714': 43.3573,
    '936_715': 43.3285,
    '936_716': 43.3282,
    '936_717': 43.3863,
    '936_718': 43.4911,
    '936_719': 43.6234,
    '936_720': 43.7661,
    '936_721': 43.9111,
    '936_722': 44.0631,
    '936_723': 44.2177,
    '936_724': 44.3552,
    '936_725': 44.4718,
    '936_726': 44.5861,
    '936_727': 44.6245,
    '936_728': 44.6082,
    '936_729': 44.5646,
    '936_730': 44.4478,
    '936_731': 44.335,
    '936_732': 44.1372,
    '936_733': 43.949,
    '936_734': 43.759,
    '936_735': 43.5252,
    '936_736': 43.2932,
    '936_737': 43.0147,
    '936_738': 42.7655,
    '936_739': 42.5935,
    '936_740': 42.4928,
    '936_741': 42.438,
    '936_742': 42.418,
    '936_743': 42.4326,
    '936_744': 42.4776,
    '936_745': 42.5413,
    '936_746': 42.6164,
    '936_747': 42.697,
    '936_748': 42.7787,
    '936_749': 42.8472,
    '936_750': 42.8861,
    '936_751': 42.8939,
    '936_752': 42.8704,
    '936_753': 42.8048,
    '936_754': 42.7062,
    '936_755': 42.582,
    '936_756': 42.4579,
    '936_757': 42.3391,
    '936_758': 42.2306,
    '936_759': 42.1157,
    '936_760': 41.9846,
    '936_761': 41.8365,
    '936_762': 41.6702,
    '936_763': 41.4913,
    '936_764': 41.2964,
    '936_765': 41.0964,
    '936_766': 40.9003,
    '936_767': 40.708,
    '936_768': 40.5069,
    '936_769': 40.2878,
    '936_770': 40.0591,
    '936_771': 39.8062,
    '936_772': 39.535,
    '936_773': 39.255,
    '936_774': 38.9805,
    '936_775': 38.7137,
    '936_776': 38.459,
    '936_777': 38.2162,
    '936_778': 37.9896,
    '936_779': 37.7796,
    '936_780': 37.5865,
    '936_781': 37.4103,
    '936_782': 37.2457,
    '936_783': 37.0902,
    '936_784': 36.9466,
    '936_785': 36.8152,
    '936_786': 36.6935,
    '936_787': 36.5776,
    '936_788': 36.4646,
    '936_789': 36.3528,
    '936_790': 36.2417,
    '936_791': 36.132,
    '936_792': 36.0238,
    '936_793': 35.9151,
    '936_794': 35.8026,
    '936_795': 35.6864,
    '936_796': 35.5771,
    '936_797': 35.4724,
    '936_798': 35.371,
    '936_799': 35.273,
    '936_800': 35.1848,
    '936_801': 35.0703,
    '936_802': 34.9588,
    '936_803': 34.8522,
    '936_804': 34.7494,
    '936_805': 34.6492,
    '936_806': 34.5498,
    '936_807': 34.4507,
    '936_808': 34.351,
    '936_809': 34.2489,
    '936_810': 34.1446,
    '936_811': 34.038,
    '936_812': 33.9274,
    '936_813': 33.8124,
    '936_814': 33.6923,
    '936_815': 33.5669,
    '936_816': 33.436,
    '936_817': 33.3023,
    '936_818': 33.1649,
    '936_819': 33.0245,
    '936_820': 32.8811,
    '936_821': 32.7348,
    '936_822': 32.5846,
    '936_823': 32.4294,
    '936_824': 32.2684,
    '936_825': 32.1008,
    '936_826': 31.9263,
    '936_827': 31.7448,
    '936_828': 31.5558,
    '936_829': 31.3592,
    '936_830': 31.1538,
    '936_831': 30.9378,
    '936_832': 30.7113,
    '936_833': 30.4784,
    '936_834': 30.2385,
    '936_835': 29.9909,
    '936_836': 29.7372,
    '936_837': 29.483,
    '936_838': 29.2044,
    '936_839': 28.9283,
    '936_840': 28.646,
    '936_841': 28.3512,
    '936_842': 28.0587,
    '936_843': 27.7536,
    '936_844': 27.4282,
    '936_845': 27.0909,
    '936_846': 26.7424,
    '936_847': 26.3853,
    '936_848': 26.0185,
    '937_472': 31.4655,
    '937_473': 31.4886,
    '937_474': 31.5185,
    '937_475': 31.5537,
    '937_476': 31.5965,
    '937_477': 31.6463,
    '937_478': 31.695,
    '937_479': 31.7443,
    '937_480': 31.7954,
    '937_481': 31.8457,
    '937_482': 31.8955,
    '937_483': 31.9447,
    '937_484': 31.9927,
    '937_485': 32.0423,
    '937_486': 32.0947,
    '937_487': 32.1508,
    '937_488': 32.2097,
    '937_489': 32.2707,
    '937_490': 32.3308,
    '937_491': 32.3904,
    '937_492': 32.4506,
    '937_493': 32.5118,
    '937_494': 32.5739,
    '937_495': 32.6364,
    '937_496': 32.6983,
    '937_497': 32.7592,
    '937_498': 32.8166,
    '937_499': 32.8737,
    '937_500': 32.9374,
    '937_501': 33.0064,
    '937_502': 33.071,
    '937_503': 33.1364,
    '937_504': 33.2074,
    '937_505': 33.2692,
    '937_506': 33.329,
    '937_507': 33.3899,
    '937_508': 33.4469,
    '937_509': 33.5074,
    '937_510': 33.5658,
    '937_511': 33.6228,
    '937_512': 33.6787,
    '937_513': 33.7355,
    '937_514': 33.788,
    '937_515': 33.8406,
    '937_516': 33.8908,
    '937_517': 33.949,
    '937_518': 34.0119,
    '937_519': 34.0764,
    '937_520': 34.1378,
    '937_521': 34.1992,
    '937_522': 34.2603,
    '937_523': 34.3142,
    '937_524': 34.3568,
    '937_525': 34.3866,
    '937_526': 34.4083,
    '937_527': 34.4275,
    '937_528': 34.447,
    '937_529': 34.4712,
    '937_530': 34.4981,
    '937_531': 34.5232,
    '937_532': 34.548,
    '937_533': 34.5742,
    '937_534': 34.5986,
    '937_535': 34.6177,
    '937_536': 34.6276,
    '937_537': 34.6333,
    '937_538': 34.6387,
    '937_539': 34.6465,
    '937_540': 34.6566,
    '937_541': 34.6675,
    '937_542': 34.6816,
    '937_543': 34.695,
    '937_544': 34.7122,
    '937_545': 34.7322,
    '937_546': 34.7491,
    '937_547': 34.762,
    '937_548': 34.7724,
    '937_549': 34.7802,
    '937_550': 34.7864,
    '937_551': 34.7935,
    '937_552': 34.7991,
    '937_553': 34.8055,
    '937_554': 34.8209,
    '937_555': 34.8384,
    '937_556': 34.857,
    '937_557': 34.8794,
    '937_558': 34.9135,
    '937_559': 34.9497,
    '937_560': 34.9879,
    '937_561': 35.0276,
    '937_562': 35.0696,
    '937_563': 35.1156,
    '937_564': 35.1675,
    '937_565': 35.226,
    '937_566': 35.2778,
    '937_567': 35.3265,
    '937_568': 35.3741,
    '937_569': 35.4218,
    '937_570': 35.471,
    '937_571': 35.5232,
    '937_572': 35.5804,
    '937_573': 35.6455,
    '937_574': 35.7145,
    '937_575': 35.792,
    '937_576': 35.8572,
    '937_577': 35.9136,
    '937_578': 35.965,
    '937_579': 36.0111,
    '937_580': 36.073,
    '937_581': 36.1404,
    '937_582': 36.2006,
    '937_583': 36.2385,
    '937_584': 36.2448,
    '937_585': 36.2519,
    '937_586': 36.2799,
    '937_587': 36.2967,
    '937_588': 36.32,
    '937_589': 36.3363,
    '937_590': 36.3341,
    '937_591': 36.3442,
    '937_592': 36.3375,
    '937_593': 36.3315,
    '937_594': 36.3465,
    '937_595': 36.3624,
    '937_596': 36.3663,
    '937_597': 36.3814,
    '937_598': 36.4105,
    '937_599': 36.4497,
    '937_600': 36.4818,
    '937_601': 36.4896,
    '937_602': 36.486,
    '937_603': 36.4972,
    '937_604': 36.528,
    '937_605': 36.5658,
    '937_606': 36.5965,
    '937_607': 36.6122,
    '937_608': 36.5854,
    '937_609': 36.532,
    '937_610': 36.4874,
    '937_611': 36.451,
    '937_612': 36.4283,
    '937_613': 36.4162,
    '937_614': 36.4184,
    '937_615': 36.4315,
    '937_616': 36.4516,
    '937_617': 36.4779,
    '937_618': 36.5025,
    '937_619': 36.5248,
    '937_620': 36.5364,
    '937_621': 36.5288,
    '937_622': 36.5168,
    '937_623': 36.5066,
    '937_624': 36.499,
    '937_625': 36.4933,
    '937_626': 36.487,
    '937_627': 36.481,
    '937_628': 36.4767,
    '937_629': 36.4752,
    '937_630': 36.4765,
    '937_631': 36.4804,
    '937_632': 36.4888,
    '937_633': 36.4989,
    '937_634': 36.5117,
    '937_635': 36.5111,
    '937_636': 36.5147,
    '937_637': 36.5321,
    '937_638': 36.5735,
    '937_639': 36.6248,
    '937_640': 36.6802,
    '937_641': 36.72,
    '937_642': 36.7575,
    '937_643': 36.8358,
    '937_644': 36.9417,
    '937_645': 37.0731,
    '937_646': 37.216,
    '937_647': 37.3681,
    '937_648': 37.5127,
    '937_649': 37.6379,
    '937_650': 37.7725,
    '937_651': 37.8969,
    '937_652': 38.0013,
    '937_653': 38.0864,
    '937_654': 38.1531,
    '937_655': 38.2112,
    '937_656': 38.2627,
    '937_657': 38.3141,
    '937_658': 38.3553,
    '937_659': 38.3889,
    '937_660': 38.4452,
    '937_661': 38.4961,
    '937_662': 38.5354,
    '937_663': 38.5548,
    '937_664': 38.5543,
    '937_665': 38.5611,
    '937_666': 38.6053,
    '937_667': 38.6411,
    '937_668': 38.6731,
    '937_669': 38.7052,
    '937_670': 38.7524,
    '937_671': 38.8085,
    '937_672': 38.8636,
    '937_673': 38.9215,
    '937_674': 39.0084,
    '937_675': 39.0991,
    '937_676': 39.1682,
    '937_677': 39.2259,
    '937_678': 39.2808,
    '937_679': 39.3651,
    '937_680': 39.4477,
    '937_681': 39.5306,
    '937_682': 39.6303,
    '937_683': 39.7404,
    '937_684': 39.8496,
    '937_685': 39.9607,
    '937_686': 40.0651,
    '937_687': 40.1682,
    '937_688': 40.2972,
    '937_689': 40.4314,
    '937_690': 40.5583,
    '937_691': 40.6889,
    '937_692': 40.822,
    '937_693': 40.9605,
    '937_694': 41.0974,
    '937_695': 41.2302,
    '937_696': 41.3481,
    '937_697': 41.4471,
    '937_698': 41.5478,
    '937_699': 41.6732,
    '937_700': 41.8251,
    '937_701': 41.9845,
    '937_702': 42.1373,
    '937_703': 42.2713,
    '937_704': 42.3962,
    '937_705': 42.534,
    '937_706': 42.7008,
    '937_707': 42.8742,
    '937_708': 43.0423,
    '937_709': 43.1793,
    '937_710': 43.288,
    '937_711': 43.3802,
    '937_712': 43.4432,
    '937_713': 43.4444,
    '937_714': 43.4198,
    '937_715': 43.3714,
    '937_716': 43.3496,
    '937_717': 43.3889,
    '937_718': 43.4843,
    '937_719': 43.6123,
    '937_720': 43.7556,
    '937_721': 43.9021,
    '937_722': 44.0563,
    '937_723': 44.2079,
    '937_724': 44.3248,
    '937_725': 44.4618,
    '937_726': 44.5504,
    '937_727': 44.6134,
    '937_728': 44.6129,
    '937_729': 44.5485,
    '937_730': 44.4552,
    '937_731': 44.3275,
    '937_732': 44.1645,
    '937_733': 43.9833,
    '937_734': 43.7896,
    '937_735': 43.5551,
    '937_736': 43.3126,
    '937_737': 43.0416,
    '937_738': 42.7961,
    '937_739': 42.6251,
    '937_740': 42.5189,
    '937_741': 42.4574,
    '937_742': 42.4289,
    '937_743': 42.4314,
    '937_744': 42.46,
    '937_745': 42.5099,
    '937_746': 42.5771,
    '937_747': 42.653,
    '937_748': 42.7365,
    '937_749': 42.8173,
    '937_750': 42.8708,
    '937_751': 42.8933,
    '937_752': 42.8819,
    '937_753': 42.842,
    '937_754': 42.7681,
    '937_755': 42.6532,
    '937_756': 42.5166,
    '937_757': 42.3791,
    '937_758': 42.2505,
    '937_759': 42.127,
    '937_760': 41.9978,
    '937_761': 41.8468,
    '937_762': 41.6678,
    '937_763': 41.4809,
    '937_764': 41.2933,
    '937_765': 41.113,
    '937_766': 40.9331,
    '937_767': 40.7497,
    '937_768': 40.5554,
    '937_769': 40.3474,
    '937_770': 40.1156,
    '937_771': 39.8565,
    '937_772': 39.5831,
    '937_773': 39.3053,
    '937_774': 39.0352,
    '937_775': 38.7722,
    '937_776': 38.5202,
    '937_777': 38.2818,
    '937_778': 38.0582,
    '937_779': 37.851,
    '937_780': 37.6598,
    '937_781': 37.4827,
    '937_782': 37.3163,
    '937_783': 37.1626,
    '937_784': 37.0218,
    '937_785': 36.8929,
    '937_786': 36.7735,
    '937_787': 36.6591,
    '937_788': 36.5477,
    '937_789': 36.4372,
    '937_790': 36.3274,
    '937_791': 36.2191,
    '937_792': 36.1125,
    '937_793': 36.0064,
    '937_794': 35.8995,
    '937_795': 35.7917,
    '937_796': 35.687,
    '937_797': 35.5846,
    '937_798': 35.485,
    '937_799': 35.3882,
    '937_800': 35.2921,
    '937_801': 35.1866,
    '937_802': 35.0852,
    '937_803': 34.9861,
    '937_804': 34.8887,
    '937_805': 34.7922,
    '937_806': 34.6963,
    '937_807': 34.5992,
    '937_808': 34.5003,
    '937_809': 34.3992,
    '937_810': 34.2961,
    '937_811': 34.1898,
    '937_812': 34.0799,
    '937_813': 33.9658,
    '937_814': 33.8471,
    '937_815': 33.7229,
    '937_816': 33.5947,
    '937_817': 33.4613,
    '937_818': 33.3224,
    '937_819': 33.1812,
    '937_820': 33.0367,
    '937_821': 32.8889,
    '937_822': 32.7372,
    '937_823': 32.5815,
    '937_824': 32.4205,
    '937_825': 32.2537,
    '937_826': 32.0802,
    '937_827': 31.8999,
    '937_828': 31.7121,
    '937_829': 31.5167,
    '937_830': 31.3127,
    '937_831': 31.0991,
    '937_832': 30.8761,
    '937_833': 30.6449,
    '937_834': 30.4053,
    '937_835': 30.1567,
    '937_836': 29.9003,
    '937_837': 29.6432,
    '937_838': 29.3692,
    '937_839': 29.0848,
    '937_840': 28.7969,
    '937_841': 28.4991,
    '937_842': 28.2004,
    '937_843': 27.8854,
    '937_844': 27.5604,
    '937_845': 27.223,
    '937_846': 26.8724,
    '937_847': 26.5146,
    '937_848': 26.1464,
    '938_472': 31.4275,
    '938_473': 31.4521,
    '938_474': 31.4824,
    '938_475': 31.5166,
    '938_476': 31.5557,
    '938_477': 31.5992,
    '938_478': 31.6468,
    '938_479': 31.6941,
    '938_480': 31.742,
    '938_481': 31.7902,
    '938_482': 31.8393,
    '938_483': 31.8875,
    '938_484': 31.9356,
    '938_485': 31.9855,
    '938_486': 32.0382,
    '938_487': 32.0951,
    '938_488': 32.152,
    '938_489': 32.2103,
    '938_490': 32.2685,
    '938_491': 32.3265,
    '938_492': 32.3867,
    '938_493': 32.4477,
    '938_494': 32.5089,
    '938_495': 32.5704,
    '938_496': 32.631,
    '938_497': 32.6905,
    '938_498': 32.7497,
    '938_499': 32.8105,
    '938_500': 32.8755,
    '938_501': 32.9403,
    '938_502': 33.0062,
    '938_503': 33.0766,
    '938_504': 33.1471,
    '938_505': 33.2093,
    '938_506': 33.2681,
    '938_507': 33.3278,
    '938_508': 33.3877,
    '938_509': 33.4505,
    '938_510': 33.5136,
    '938_511': 33.5703,
    '938_512': 33.6264,
    '938_513': 33.6837,
    '938_514': 33.7373,
    '938_515': 33.7902,
    '938_516': 33.8437,
    '938_517': 33.8999,
    '938_518': 33.9587,
    '938_519': 34.0163,
    '938_520': 34.0707,
    '938_521': 34.1256,
    '938_522': 34.1828,
    '938_523': 34.2319,
    '938_524': 34.2703,
    '938_525': 34.3013,
    '938_526': 34.3266,
    '938_527': 34.3468,
    '938_528': 34.3657,
    '938_529': 34.3918,
    '938_530': 34.4215,
    '938_531': 34.4523,
    '938_532': 34.4843,
    '938_533': 34.5114,
    '938_534': 34.5389,
    '938_535': 34.5593,
    '938_536': 34.5735,
    '938_537': 34.5824,
    '938_538': 34.5882,
    '938_539': 34.5973,
    '938_540': 34.6103,
    '938_541': 34.6252,
    '938_542': 34.6408,
    '938_543': 34.6532,
    '938_544': 34.668,
    '938_545': 34.689,
    '938_546': 34.7069,
    '938_547': 34.7211,
    '938_548': 34.7326,
    '938_549': 34.7403,
    '938_550': 34.7439,
    '938_551': 34.7492,
    '938_552': 34.7547,
    '938_553': 34.7617,
    '938_554': 34.7735,
    '938_555': 34.7875,
    '938_556': 34.8045,
    '938_557': 34.8269,
    '938_558': 34.8573,
    '938_559': 34.891,
    '938_560': 34.9267,
    '938_561': 34.9644,
    '938_562': 35.0046,
    '938_563': 35.0493,
    '938_564': 35.1016,
    '938_565': 35.1694,
    '938_566': 35.2206,
    '938_567': 35.265,
    '938_568': 35.3074,
    '938_569': 35.35,
    '938_570': 35.3938,
    '938_571': 35.4401,
    '938_572': 35.4912,
    '938_573': 35.5535,
    '938_574': 35.6196,
    '938_575': 35.693,
    '938_576': 35.7548,
    '938_577': 35.8122,
    '938_578': 35.8675,
    '938_579': 35.9251,
    '938_580': 35.9913,
    '938_581': 36.0574,
    '938_582': 36.1054,
    '938_583': 36.1338,
    '938_584': 36.1489,
    '938_585': 36.1584,
    '938_586': 36.1899,
    '938_587': 36.2114,
    '938_588': 36.2362,
    '938_589': 36.2588,
    '938_590': 36.2668,
    '938_591': 36.2774,
    '938_592': 36.278,
    '938_593': 36.2719,
    '938_594': 36.2874,
    '938_595': 36.3071,
    '938_596': 36.3089,
    '938_597': 36.3215,
    '938_598': 36.3515,
    '938_599': 36.3902,
    '938_600': 36.4224,
    '938_601': 36.4314,
    '938_602': 36.4377,
    '938_603': 36.452,
    '938_604': 36.4897,
    '938_605': 36.5356,
    '938_606': 36.5755,
    '938_607': 36.5894,
    '938_608': 36.5641,
    '938_609': 36.5146,
    '938_610': 36.4614,
    '938_611': 36.4176,
    '938_612': 36.3925,
    '938_613': 36.3779,
    '938_614': 36.3763,
    '938_615': 36.3892,
    '938_616': 36.4074,
    '938_617': 36.4281,
    '938_618': 36.4506,
    '938_619': 36.4718,
    '938_620': 36.485,
    '938_621': 36.4793,
    '938_622': 36.4692,
    '938_623': 36.4623,
    '938_624': 36.4587,
    '938_625': 36.4588,
    '938_626': 36.453,
    '938_627': 36.4478,
    '938_628': 36.4448,
    '938_629': 36.4451,
    '938_630': 36.4485,
    '938_631': 36.4559,
    '938_632': 36.4632,
    '938_633': 36.4731,
    '938_634': 36.4792,
    '938_635': 36.4797,
    '938_636': 36.4879,
    '938_637': 36.5065,
    '938_638': 36.5408,
    '938_639': 36.5863,
    '938_640': 36.6427,
    '938_641': 36.6863,
    '938_642': 36.7384,
    '938_643': 36.8275,
    '938_644': 36.9606,
    '938_645': 37.1149,
    '938_646': 37.2699,
    '938_647': 37.43,
    '938_648': 37.5738,
    '938_649': 37.7065,
    '938_650': 37.8388,
    '938_651': 37.9638,
    '938_652': 38.0669,
    '938_653': 38.1485,
    '938_654': 38.2163,
    '938_655': 38.2807,
    '938_656': 38.3405,
    '938_657': 38.3946,
    '938_658': 38.4337,
    '938_659': 38.4594,
    '938_660': 38.5046,
    '938_661': 38.5642,
    '938_662': 38.5999,
    '938_663': 38.6136,
    '938_664': 38.6136,
    '938_665': 38.6336,
    '938_666': 38.6814,
    '938_667': 38.7274,
    '938_668': 38.7629,
    '938_669': 38.8038,
    '938_670': 38.858,
    '938_671': 38.9131,
    '938_672': 38.9633,
    '938_673': 39.0133,
    '938_674': 39.1008,
    '938_675': 39.1928,
    '938_676': 39.2561,
    '938_677': 39.2928,
    '938_678': 39.3345,
    '938_679': 39.4147,
    '938_680': 39.5004,
    '938_681': 39.5821,
    '938_682': 39.6756,
    '938_683': 39.7796,
    '938_684': 39.8847,
    '938_685': 39.9878,
    '938_686': 40.0905,
    '938_687': 40.1974,
    '938_688': 40.3252,
    '938_689': 40.4613,
    '938_690': 40.5923,
    '938_691': 40.7217,
    '938_692': 40.8451,
    '938_693': 40.9762,
    '938_694': 41.1133,
    '938_695': 41.2501,
    '938_696': 41.375,
    '938_697': 41.4847,
    '938_698': 41.6022,
    '938_699': 41.7486,
    '938_700': 41.914,
    '938_701': 42.0736,
    '938_702': 42.2104,
    '938_703': 42.334,
    '938_704': 42.4367,
    '938_705': 42.5497,
    '938_706': 42.6995,
    '938_707': 42.8639,
    '938_708': 43.0365,
    '938_709': 43.2006,
    '938_710': 43.3213,
    '938_711': 43.4109,
    '938_712': 43.4882,
    '938_713': 43.4958,
    '938_714': 43.4747,
    '938_715': 43.4123,
    '938_716': 43.3735,
    '938_717': 43.3965,
    '938_718': 43.4834,
    '938_719': 43.6102,
    '938_720': 43.7545,
    '938_721': 43.8982,
    '938_722': 44.0481,
    '938_723': 44.2006,
    '938_724': 44.3029,
    '938_725': 44.4376,
    '938_726': 44.5224,
    '938_727': 44.5828,
    '938_728': 44.584,
    '938_729': 44.5312,
    '938_730': 44.5021,
    '938_731': 44.3839,
    '938_732': 44.2014,
    '938_733': 44.0191,
    '938_734': 43.8121,
    '938_735': 43.5888,
    '938_736': 43.3279,
    '938_737': 43.0641,
    '938_738': 42.8288,
    '938_739': 42.6647,
    '938_740': 42.5585,
    '938_741': 42.496,
    '938_742': 42.4662,
    '938_743': 42.4637,
    '938_744': 42.4832,
    '938_745': 42.5196,
    '938_746': 42.5742,
    '938_747': 42.6382,
    '938_748': 42.7101,
    '938_749': 42.7943,
    '938_750': 42.8602,
    '938_751': 42.9003,
    '938_752': 42.9162,
    '938_753': 42.8991,
    '938_754': 42.8352,
    '938_755': 42.738,
    '938_756': 42.6007,
    '938_757': 42.4509,
    '938_758': 42.3066,
    '938_759': 42.1816,
    '938_760': 42.0545,
    '938_761': 41.9007,
    '938_762': 41.7146,
    '938_763': 41.5185,
    '938_764': 41.3345,
    '938_765': 41.1685,
    '938_766': 41.0003,
    '938_767': 40.8235,
    '938_768': 40.6238,
    '938_769': 40.4096,
    '938_770': 40.1669,
    '938_771': 39.9023,
    '938_772': 39.6303,
    '938_773': 39.3594,
    '938_774': 39.0944,
    '938_775': 38.836,
    '938_776': 38.5874,
    '938_777': 38.3507,
    '938_778': 38.1302,
    '938_779': 37.9261,
    '938_780': 37.734,
    '938_781': 37.5567,
    '938_782': 37.3921,
    '938_783': 37.241,
    '938_784': 37.1031,
    '938_785': 36.9764,
    '938_786': 36.8582,
    '938_787': 36.7454,
    '938_788': 36.6363,
    '938_789': 36.5284,
    '938_790': 36.4205,
    '938_791': 36.3149,
    '938_792': 36.2113,
    '938_793': 36.1086,
    '938_794': 36.006,
    '938_795': 35.9032,
    '938_796': 35.8021,
    '938_797': 35.7028,
    '938_798': 35.6057,
    '938_799': 35.51,
    '938_800': 35.413,
    '938_801': 35.3167,
    '938_802': 35.2227,
    '938_803': 35.1299,
    '938_804': 35.0384,
    '938_805': 34.9461,
    '938_806': 34.8537,
    '938_807': 34.7582,
    '938_808': 34.6599,
    '938_809': 34.5596,
    '938_810': 34.4576,
    '938_811': 34.352,
    '938_812': 34.2428,
    '938_813': 34.1301,
    '938_814': 34.0126,
    '938_815': 33.8906,
    '938_816': 33.763,
    '938_817': 33.6298,
    '938_818': 33.4902,
    '938_819': 33.3469,
    '938_820': 33.2011,
    '938_821': 33.0518,
    '938_822': 32.8981,
    '938_823': 32.7406,
    '938_824': 32.5788,
    '938_825': 32.4118,
    '938_826': 32.2387,
    '938_827': 32.0591,
    '938_828': 31.8724,
    '938_829': 31.6782,
    '938_830': 31.4756,
    '938_831': 31.264,
    '938_832': 31.0433,
    '938_833': 30.8136,
    '938_834': 30.5745,
    '938_835': 30.3249,
    '938_836': 30.0629,
    '938_837': 29.7982,
    '938_838': 29.5323,
    '938_839': 29.2422,
    '938_840': 28.9498,
    '938_841': 28.6477,
    '938_842': 28.3423,
    '938_843': 28.019,
    '938_844': 27.695,
    '938_845': 27.357,
    '938_846': 27.0023,
    '938_847': 26.6442,
    '938_848': 26.2744,
    '939_472': 31.3868,
    '939_473': 31.4123,
    '939_474': 31.4426,
    '939_475': 31.4764,
    '939_476': 31.5143,
    '939_477': 31.5556,
    '939_478': 31.5983,
    '939_479': 31.6421,
    '939_480': 31.6868,
    '939_481': 31.7324,
    '939_482': 31.7799,
    '939_483': 31.8274,
    '939_484': 31.8757,
    '939_485': 31.9259,
    '939_486': 31.9781,
    '939_487': 32.034,
    '939_488': 32.0908,
    '939_489': 32.1488,
    '939_490': 32.2071,
    '939_491': 32.2651,
    '939_492': 32.3245,
    '939_493': 32.3843,
    '939_494': 32.4439,
    '939_495': 32.5033,
    '939_496': 32.5616,
    '939_497': 32.6208,
    '939_498': 32.6814,
    '939_499': 32.7434,
    '939_500': 32.8062,
    '939_501': 32.8743,
    '939_502': 32.9456,
    '939_503': 33.0203,
    '939_504': 33.0902,
    '939_505': 33.1515,
    '939_506': 33.2094,
    '939_507': 33.2679,
    '939_508': 33.3292,
    '939_509': 33.3925,
    '939_510': 33.4553,
    '939_511': 33.5213,
    '939_512': 33.5788,
    '939_513': 33.6381,
    '939_514': 33.6903,
    '939_515': 33.7428,
    '939_516': 33.798,
    '939_517': 33.8545,
    '939_518': 33.9112,
    '939_519': 33.9626,
    '939_520': 34.0098,
    '939_521': 34.0575,
    '939_522': 34.1078,
    '939_523': 34.1553,
    '939_524': 34.1909,
    '939_525': 34.2236,
    '939_526': 34.2527,
    '939_527': 34.2748,
    '939_528': 34.2964,
    '939_529': 34.3218,
    '939_530': 34.3505,
    '939_531': 34.3803,
    '939_532': 34.4125,
    '939_533': 34.4406,
    '939_534': 34.4665,
    '939_535': 34.4868,
    '939_536': 34.5055,
    '939_537': 34.5241,
    '939_538': 34.5316,
    '939_539': 34.5438,
    '939_540': 34.5612,
    '939_541': 34.5804,
    '939_542': 34.5986,
    '939_543': 34.6074,
    '939_544': 34.6211,
    '939_545': 34.6469,
    '939_546': 34.6654,
    '939_547': 34.6804,
    '939_548': 34.6942,
    '939_549': 34.7037,
    '939_550': 34.7063,
    '939_551': 34.7101,
    '939_552': 34.7163,
    '939_553': 34.7246,
    '939_554': 34.7353,
    '939_555': 34.7467,
    '939_556': 34.7606,
    '939_557': 34.7806,
    '939_558': 34.8077,
    '939_559': 34.8391,
    '939_560': 34.8727,
    '939_561': 34.9084,
    '939_562': 34.9473,
    '939_563': 34.9927,
    '939_564': 35.0492,
    '939_565': 35.1171,
    '939_566': 35.1645,
    '939_567': 35.2044,
    '939_568': 35.2424,
    '939_569': 35.2805,
    '939_570': 35.3201,
    '939_571': 35.3628,
    '939_572': 35.4116,
    '939_573': 35.4685,
    '939_574': 35.5296,
    '939_575': 35.5997,
    '939_576': 35.6594,
    '939_577': 35.7155,
    '939_578': 35.7707,
    '939_579': 35.8287,
    '939_580': 35.8953,
    '939_581': 35.9608,
    '939_582': 35.9987,
    '939_583': 36.0225,
    '939_584': 36.0456,
    '939_585': 36.0626,
    '939_586': 36.0928,
    '939_587': 36.1187,
    '939_588': 36.1452,
    '939_589': 36.1723,
    '939_590': 36.1851,
    '939_591': 36.2006,
    '939_592': 36.2132,
    '939_593': 36.2119,
    '939_594': 36.2258,
    '939_595': 36.2419,
    '939_596': 36.2475,
    '939_597': 36.2615,
    '939_598': 36.2871,
    '939_599': 36.322,
    '939_600': 36.355,
    '939_601': 36.3707,
    '939_602': 36.3852,
    '939_603': 36.4042,
    '939_604': 36.4385,
    '939_605': 36.4906,
    '939_606': 36.5396,
    '939_607': 36.5571,
    '939_608': 36.5432,
    '939_609': 36.4999,
    '939_610': 36.4379,
    '939_611': 36.3907,
    '939_612': 36.364,
    '939_613': 36.3434,
    '939_614': 36.3402,
    '939_615': 36.3528,
    '939_616': 36.3672,
    '939_617': 36.3817,
    '939_618': 36.3988,
    '939_619': 36.4147,
    '939_620': 36.4233,
    '939_621': 36.4193,
    '939_622': 36.4124,
    '939_623': 36.411,
    '939_624': 36.4109,
    '939_625': 36.4113,
    '939_626': 36.4113,
    '939_627': 36.4108,
    '939_628': 36.4107,
    '939_629': 36.4136,
    '939_630': 36.4202,
    '939_631': 36.4293,
    '939_632': 36.436,
    '939_633': 36.4433,
    '939_634': 36.4487,
    '939_635': 36.4522,
    '939_636': 36.4634,
    '939_637': 36.4848,
    '939_638': 36.5175,
    '939_639': 36.5668,
    '939_640': 36.6311,
    '939_641': 36.6781,
    '939_642': 36.7365,
    '939_643': 36.8351,
    '939_644': 36.9776,
    '939_645': 37.1457,
    '939_646': 37.3158,
    '939_647': 37.481,
    '939_648': 37.6276,
    '939_649': 37.7655,
    '939_650': 37.8984,
    '939_651': 38.0191,
    '939_652': 38.1237,
    '939_653': 38.2052,
    '939_654': 38.2751,
    '939_655': 38.3397,
    '939_656': 38.4011,
    '939_657': 38.4577,
    '939_658': 38.5,
    '939_659': 38.5336,
    '939_660': 38.567,
    '939_661': 38.6183,
    '939_662': 38.6495,
    '939_663': 38.6576,
    '939_664': 38.6738,
    '939_665': 38.7116,
    '939_666': 38.7598,
    '939_667': 38.8144,
    '939_668': 38.8575,
    '939_669': 38.8993,
    '939_670': 38.9573,
    '939_671': 39.0157,
    '939_672': 39.0607,
    '939_673': 39.1039,
    '939_674': 39.1872,
    '939_675': 39.2723,
    '939_676': 39.3257,
    '939_677': 39.3507,
    '939_678': 39.3885,
    '939_679': 39.4663,
    '939_680': 39.5557,
    '939_681': 39.6356,
    '939_682': 39.7267,
    '939_683': 39.8239,
    '939_684': 39.9252,
    '939_685': 40.0203,
    '939_686': 40.118,
    '939_687': 40.2271,
    '939_688': 40.3544,
    '939_689': 40.4885,
    '939_690': 40.6225,
    '939_691': 40.7563,
    '939_692': 40.8799,
    '939_693': 41.0014,
    '939_694': 41.1345,
    '939_695': 41.275,
    '939_696': 41.4087,
    '939_697': 41.5303,
    '939_698': 41.6742,
    '939_699': 41.8424,
    '939_700': 42.0107,
    '939_701': 42.1672,
    '939_702': 42.2823,
    '939_703': 42.3934,
    '939_704': 42.4797,
    '939_705': 42.5747,
    '939_706': 42.705,
    '939_707': 42.8557,
    '939_708': 43.0223,
    '939_709': 43.1927,
    '939_710': 43.3532,
    '939_711': 43.4714,
    '939_712': 43.5151,
    '939_713': 43.5233,
    '939_714': 43.5021,
    '939_715': 43.447,
    '939_716': 43.3957,
    '939_717': 43.4031,
    '939_718': 43.4821,
    '939_719': 43.6084,
    '939_720': 43.7489,
    '939_721': 43.893,
    '939_722': 44.0383,
    '939_723': 44.1835,
    '939_724': 44.2796,
    '939_725': 44.4155,
    '939_726': 44.5003,
    '939_727': 44.5783,
    '939_728': 44.5973,
    '939_729': 44.5204,
    '939_730': 44.5016,
    '939_731': 44.4085,
    '939_732': 44.2425,
    '939_733': 44.0397,
    '939_734': 43.8317,
    '939_735': 43.6045,
    '939_736': 43.3484,
    '939_737': 43.0874,
    '939_738': 42.865,
    '939_739': 42.7103,
    '939_740': 42.6094,
    '939_741': 42.5512,
    '939_742': 42.5255,
    '939_743': 42.5248,
    '939_744': 42.5431,
    '939_745': 42.5697,
    '939_746': 42.605,
    '939_747': 42.6528,
    '939_748': 42.7107,
    '939_749': 42.7844,
    '939_750': 42.8619,
    '939_751': 42.9229,
    '939_752': 42.9626,
    '939_753': 42.9684,
    '939_754': 42.9157,
    '939_755': 42.8294,
    '939_756': 42.6944,
    '939_757': 42.5421,
    '939_758': 42.3877,
    '939_759': 42.2526,
    '939_760': 42.1262,
    '939_761': 41.9756,
    '939_762': 41.7924,
    '939_763': 41.5947,
    '939_764': 41.4094,
    '939_765': 41.2483,
    '939_766': 41.0831,
    '939_767': 40.9007,
    '939_768': 40.6916,
    '939_769': 40.464,
    '939_770': 40.2142,
    '939_771': 39.9493,
    '939_772': 39.684,
    '939_773': 39.4206,
    '939_774': 39.1593,
    '939_775': 38.9038,
    '939_776': 38.659,
    '939_777': 38.4247,
    '939_778': 38.2042,
    '939_779': 38.0009,
    '939_780': 37.8108,
    '939_781': 37.6354,
    '939_782': 37.4735,
    '939_783': 37.3253,
    '939_784': 37.1896,
    '939_785': 37.0641,
    '939_786': 36.9475,
    '939_787': 36.8367,
    '939_788': 36.7302,
    '939_789': 36.6254,
    '939_790': 36.5212,
    '939_791': 36.4193,
    '939_792': 36.3196,
    '939_793': 36.2209,
    '939_794': 36.1213,
    '939_795': 36.0214,
    '939_796': 35.9231,
    '939_797': 35.8272,
    '939_798': 35.7334,
    '939_799': 35.6411,
    '939_800': 35.5488,
    '939_801': 35.4582,
    '939_802': 35.3694,
    '939_803': 35.2819,
    '939_804': 35.1957,
    '939_805': 35.1081,
    '939_806': 35.019,
    '939_807': 34.9256,
    '939_808': 34.8294,
    '939_809': 34.7303,
    '939_810': 34.6302,
    '939_811': 34.5262,
    '939_812': 34.4181,
    '939_813': 34.3059,
    '939_814': 34.1894,
    '939_815': 34.0679,
    '939_816': 33.9404,
    '939_817': 33.807,
    '939_818': 33.6679,
    '939_819': 33.5218,
    '939_820': 33.3738,
    '939_821': 33.2222,
    '939_822': 33.066,
    '939_823': 32.9064,
    '939_824': 32.7429,
    '939_825': 32.5749,
    '939_826': 32.4017,
    '939_827': 32.2225,
    '939_828': 32.0365,
    '939_829': 31.8433,
    '939_830': 31.6418,
    '939_831': 31.4315,
    '939_832': 31.2122,
    '939_833': 30.9833,
    '939_834': 30.7443,
    '939_835': 30.4931,
    '939_836': 30.2231,
    '939_837': 29.9491,
    '939_838': 29.6855,
    '939_839': 29.4082,
    '939_840': 29.1036,
    '939_841': 28.7939,
    '939_842': 28.4846,
    '939_843': 28.166,
    '939_844': 27.8371,
    '939_845': 27.4946,
    '939_846': 27.1381,
    '939_847': 26.7731,
    '939_848': 26.3982,
    '940_472': 31.3433,
    '940_473': 31.3685,
    '940_474': 31.3986,
    '940_475': 31.4322,
    '940_476': 31.469,
    '940_477': 31.5076,
    '940_478': 31.5472,
    '940_479': 31.5884,
    '940_480': 31.6306,
    '940_481': 31.674,
    '940_482': 31.7189,
    '940_483': 31.7649,
    '940_484': 31.8129,
    '940_485': 31.8638,
    '940_486': 31.9157,
    '940_487': 31.9706,
    '940_488': 32.0276,
    '940_489': 32.0862,
    '940_490': 32.1457,
    '940_491': 32.2039,
    '940_492': 32.2627,
    '940_493': 32.3212,
    '940_494': 32.3786,
    '940_495': 32.435,
    '940_496': 32.4916,
    '940_497': 32.5512,
    '940_498': 32.614,
    '940_499': 32.6777,
    '940_500': 32.7422,
    '940_501': 32.8114,
    '940_502': 32.8872,
    '940_503': 32.963,
    '940_504': 33.0297,
    '940_505': 33.0959,
    '940_506': 33.1573,
    '940_507': 33.2173,
    '940_508': 33.2785,
    '940_509': 33.3405,
    '940_510': 33.4028,
    '940_511': 33.4663,
    '940_512': 33.528,
    '940_513': 33.5916,
    '940_514': 33.6472,
    '940_515': 33.6994,
    '940_516': 33.7545,
    '940_517': 33.8098,
    '940_518': 33.8649,
    '940_519': 33.9086,
    '940_520': 33.9508,
    '940_521': 33.9939,
    '940_522': 34.0384,
    '940_523': 34.0816,
    '940_524': 34.1198,
    '940_525': 34.1562,
    '940_526': 34.1866,
    '940_527': 34.2105,
    '940_528': 34.2315,
    '940_529': 34.2544,
    '940_530': 34.2793,
    '940_531': 34.3059,
    '940_532': 34.3352,
    '940_533': 34.3623,
    '940_534': 34.3917,
    '940_535': 34.4173,
    '940_536': 34.4451,
    '940_537': 34.4617,
    '940_538': 34.4713,
    '940_539': 34.486,
    '940_540': 34.5058,
    '940_541': 34.5264,
    '940_542': 34.5454,
    '940_543': 34.5576,
    '940_544': 34.5736,
    '940_545': 34.5965,
    '940_546': 34.6161,
    '940_547': 34.6313,
    '940_548': 34.6479,
    '940_549': 34.6581,
    '940_550': 34.6623,
    '940_551': 34.668,
    '940_552': 34.6772,
    '940_553': 34.6894,
    '940_554': 34.7027,
    '940_555': 34.7136,
    '940_556': 34.7253,
    '940_557': 34.7383,
    '940_558': 34.7626,
    '940_559': 34.7923,
    '940_560': 34.8245,
    '940_561': 34.8583,
    '940_562': 34.8951,
    '940_563': 34.9396,
    '940_564': 34.997,
    '940_565': 35.0576,
    '940_566': 35.1033,
    '940_567': 35.1415,
    '940_568': 35.1768,
    '940_569': 35.2117,
    '940_570': 35.2477,
    '940_571': 35.2871,
    '940_572': 35.3332,
    '940_573': 35.3855,
    '940_574': 35.4419,
    '940_575': 35.5092,
    '940_576': 35.5667,
    '940_577': 35.6206,
    '940_578': 35.6738,
    '940_579': 35.7287,
    '940_580': 35.7864,
    '940_581': 35.8423,
    '940_582': 35.883,
    '940_583': 35.9117,
    '940_584': 35.9394,
    '940_585': 35.9645,
    '940_586': 35.9931,
    '940_587': 36.0207,
    '940_588': 36.0483,
    '940_589': 36.0755,
    '940_590': 36.0944,
    '940_591': 36.1115,
    '940_592': 36.1275,
    '940_593': 36.1372,
    '940_594': 36.1525,
    '940_595': 36.1687,
    '940_596': 36.1823,
    '940_597': 36.1995,
    '940_598': 36.2239,
    '940_599': 36.2581,
    '940_600': 36.2921,
    '940_601': 36.3065,
    '940_602': 36.3273,
    '940_603': 36.3509,
    '940_604': 36.385,
    '940_605': 36.4402,
    '940_606': 36.4916,
    '940_607': 36.5138,
    '940_608': 36.5076,
    '940_609': 36.4696,
    '940_610': 36.4095,
    '940_611': 36.3623,
    '940_612': 36.3327,
    '940_613': 36.3064,
    '940_614': 36.3022,
    '940_615': 36.3168,
    '940_616': 36.3293,
    '940_617': 36.3387,
    '940_618': 36.3454,
    '940_619': 36.3551,
    '940_620': 36.3598,
    '940_621': 36.3539,
    '940_622': 36.3486,
    '940_623': 36.3553,
    '940_624': 36.3639,
    '940_625': 36.3643,
    '940_626': 36.3657,
    '940_627': 36.3686,
    '940_628': 36.3709,
    '940_629': 36.3765,
    '940_630': 36.3864,
    '940_631': 36.3967,
    '940_632': 36.4047,
    '940_633': 36.4122,
    '940_634': 36.4196,
    '940_635': 36.4283,
    '940_636': 36.4419,
    '940_637': 36.4641,
    '940_638': 36.5008,
    '940_639': 36.5587,
    '940_640': 36.6294,
    '940_641': 36.6885,
    '940_642': 36.7469,
    '940_643': 36.8518,
    '940_644': 36.9995,
    '940_645': 37.1738,
    '940_646': 37.3529,
    '940_647': 37.5203,
    '940_648': 37.6711,
    '940_649': 37.8116,
    '940_650': 37.9406,
    '940_651': 38.0605,
    '940_652': 38.1663,
    '940_653': 38.2509,
    '940_654': 38.3228,
    '940_655': 38.3845,
    '940_656': 38.4435,
    '940_657': 38.4993,
    '940_658': 38.5473,
    '940_659': 38.5921,
    '940_660': 38.63,
    '940_661': 38.6728,
    '940_662': 38.6958,
    '940_663': 38.7141,
    '940_664': 38.755,
    '940_665': 38.8044,
    '940_666': 38.845,
    '940_667': 38.8993,
    '940_668': 38.9472,
    '940_669': 38.9879,
    '940_670': 39.0443,
    '940_671': 39.1033,
    '940_672': 39.1459,
    '940_673': 39.1907,
    '940_674': 39.2672,
    '940_675': 39.3369,
    '940_676': 39.3811,
    '940_677': 39.4064,
    '940_678': 39.4481,
    '940_679': 39.5214,
    '940_680': 39.611,
    '940_681': 39.6927,
    '940_682': 39.7802,
    '940_683': 39.8712,
    '940_684': 39.9676,
    '940_685': 40.0586,
    '940_686': 40.1501,
    '940_687': 40.261,
    '940_688': 40.391,
    '940_689': 40.5228,
    '940_690': 40.6525,
    '940_691': 40.7879,
    '940_692': 40.9179,
    '940_693': 41.0455,
    '940_694': 41.1738,
    '940_695': 41.3099,
    '940_696': 41.4494,
    '940_697': 41.5891,
    '940_698': 41.7564,
    '940_699': 41.933,
    '940_700': 42.0962,
    '940_701': 42.2427,
    '940_702': 42.3624,
    '940_703': 42.4515,
    '940_704': 42.5247,
    '940_705': 42.6065,
    '940_706': 42.7168,
    '940_707': 42.8494,
    '940_708': 43.0071,
    '940_709': 43.1844,
    '940_710': 43.3489,
    '940_711': 43.473,
    '940_712': 43.5337,
    '940_713': 43.5811,
    '940_714': 43.5487,
    '940_715': 43.4793,
    '940_716': 43.414,
    '940_717': 43.4064,
    '940_718': 43.4736,
    '940_719': 43.5925,
    '940_720': 43.7287,
    '940_721': 43.8739,
    '940_722': 44.0108,
    '940_723': 44.1541,
    '940_724': 44.2624,
    '940_725': 44.3963,
    '940_726': 44.4791,
    '940_727': 44.5567,
    '940_728': 44.5808,
    '940_729': 44.5258,
    '940_730': 44.4447,
    '940_731': 44.3743,
    '940_732': 44.2596,
    '940_733': 44.0277,
    '940_734': 43.8431,
    '940_735': 43.6224,
    '940_736': 43.3689,
    '940_737': 43.1154,
    '940_738': 42.902,
    '940_739': 42.7585,
    '940_740': 42.668,
    '940_741': 42.619,
    '940_742': 42.6032,
    '940_743': 42.6107,
    '940_744': 42.6371,
    '940_745': 42.6614,
    '940_746': 42.6704,
    '940_747': 42.6974,
    '940_748': 42.7407,
    '940_749': 42.8031,
    '940_750': 42.8865,
    '940_751': 42.9641,
    '940_752': 43.0171,
    '940_753': 43.0335,
    '940_754': 42.9943,
    '940_755': 42.9138,
    '940_756': 42.7849,
    '940_757': 42.6325,
    '940_758': 42.474,
    '940_759': 42.3314,
    '940_760': 42.1992,
    '940_761': 42.0461,
    '940_762': 41.8693,
    '940_763': 41.678,
    '940_764': 41.4955,
    '940_765': 41.3351,
    '940_766': 41.1625,
    '940_767': 40.9668,
    '940_768': 40.7431,
    '940_769': 40.5062,
    '940_770': 40.2572,
    '940_771': 40.0009,
    '940_772': 39.7437,
    '940_773': 39.4855,
    '940_774': 39.2292,
    '940_775': 38.977,
    '940_776': 38.7327,
    '940_777': 38.5005,
    '940_778': 38.2822,
    '940_779': 38.0799,
    '940_780': 37.8921,
    '940_781': 37.719,
    '940_782': 37.56,
    '940_783': 37.4139,
    '940_784': 37.2792,
    '940_785': 37.1549,
    '940_786': 37.04,
    '940_787': 36.9319,
    '940_788': 36.8282,
    '940_789': 36.727,
    '940_790': 36.6274,
    '940_791': 36.5307,
    '940_792': 36.4353,
    '940_793': 36.3403,
    '940_794': 36.2436,
    '940_795': 36.1465,
    '940_796': 36.0507,
    '940_797': 35.9571,
    '940_798': 35.8668,
    '940_799': 35.7786,
    '940_800': 35.6916,
    '940_801': 35.6064,
    '940_802': 35.5226,
    '940_803': 35.4392,
    '940_804': 35.3567,
    '940_805': 35.2737,
    '940_806': 35.1883,
    '940_807': 35.0982,
    '940_808': 35.0046,
    '940_809': 34.9084,
    '940_810': 34.8107,
    '940_811': 34.7075,
    '940_812': 34.6011,
    '940_813': 34.4899,
    '940_814': 34.374,
    '940_815': 34.252,
    '940_816': 34.1243,
    '940_817': 33.9914,
    '940_818': 33.8536,
    '940_819': 33.7045,
    '940_820': 33.5532,
    '940_821': 33.3981,
    '940_822': 33.2389,
    '940_823': 33.0765,
    '940_824': 32.9108,
    '940_825': 32.7413,
    '940_826': 32.5674,
    '940_827': 32.3881,
    '940_828': 32.2026,
    '940_829': 32.01,
    '940_830': 31.8093,
    '940_831': 31.5996,
    '940_832': 31.3806,
    '940_833': 31.1514,
    '940_834': 30.9102,
    '940_835': 30.654,
    '940_836': 30.3821,
    '940_837': 30.1054,
    '940_838': 29.8323,
    '940_839': 29.5501,
    '940_840': 29.2415,
    '940_841': 28.9319,
    '940_842': 28.6215,
    '940_843': 28.302,
    '940_844': 27.9714,
    '940_845': 27.6284,
    '940_846': 27.2705,
    '940_847': 26.9005,
    '940_848': 26.5206,
    '941_472': 31.298,
    '941_473': 31.325,
    '941_474': 31.3543,
    '941_475': 31.3866,
    '941_476': 31.4222,
    '941_477': 31.4583,
    '941_478': 31.4958,
    '941_479': 31.5349,
    '941_480': 31.5751,
    '941_481': 31.6164,
    '941_482': 31.6586,
    '941_483': 31.7021,
    '941_484': 31.748,
    '941_485': 31.7995,
    '941_486': 31.852,
    '941_487': 31.9069,
    '941_488': 31.9639,
    '941_489': 32.023,
    '941_490': 32.0832,
    '941_491': 32.1429,
    '941_492': 32.2023,
    '941_493': 32.2606,
    '941_494': 32.3162,
    '941_495': 32.3704,
    '941_496': 32.4255,
    '941_497': 32.4869,
    '941_498': 32.5537,
    '941_499': 32.6178,
    '941_500': 32.6817,
    '941_501': 32.7498,
    '941_502': 32.8272,
    '941_503': 32.9016,
    '941_504': 32.9772,
    '941_505': 33.0459,
    '941_506': 33.1093,
    '941_507': 33.1696,
    '941_508': 33.2301,
    '941_509': 33.2912,
    '941_510': 33.3525,
    '941_511': 33.4148,
    '941_512': 33.4811,
    '941_513': 33.5488,
    '941_514': 33.6067,
    '941_515': 33.6628,
    '941_516': 33.717,
    '941_517': 33.7678,
    '941_518': 33.816,
    '941_519': 33.8549,
    '941_520': 33.8951,
    '941_521': 33.935,
    '941_522': 33.975,
    '941_523': 34.0136,
    '941_524': 34.0489,
    '941_525': 34.0871,
    '941_526': 34.1229,
    '941_527': 34.1498,
    '941_528': 34.1696,
    '941_529': 34.1909,
    '941_530': 34.2128,
    '941_531': 34.2365,
    '941_532': 34.2653,
    '941_533': 34.2915,
    '941_534': 34.3158,
    '941_535': 34.3389,
    '941_536': 34.3598,
    '941_537': 34.3851,
    '941_538': 34.4011,
    '941_539': 34.4208,
    '941_540': 34.4426,
    '941_541': 34.4641,
    '941_542': 34.4825,
    '941_543': 34.4983,
    '941_544': 34.516,
    '941_545': 34.5365,
    '941_546': 34.5554,
    '941_547': 34.5697,
    '941_548': 34.589,
    '941_549': 34.6003,
    '941_550': 34.6091,
    '941_551': 34.6198,
    '941_552': 34.6344,
    '941_553': 34.6532,
    '941_554': 34.6738,
    '941_555': 34.6883,
    '941_556': 34.6925,
    '941_557': 34.7011,
    '941_558': 34.7228,
    '941_559': 34.7517,
    '941_560': 34.7835,
    '941_561': 34.8161,
    '941_562': 34.8507,
    '941_563': 34.8952,
    '941_564': 34.9472,
    '941_565': 34.9991,
    '941_566': 35.0428,
    '941_567': 35.0795,
    '941_568': 35.1128,
    '941_569': 35.1451,
    '941_570': 35.1781,
    '941_571': 35.2141,
    '941_572': 35.2563,
    '941_573': 35.304,
    '941_574': 35.3558,
    '941_575': 35.4198,
    '941_576': 35.4746,
    '941_577': 35.5261,
    '941_578': 35.5783,
    '941_579': 35.6321,
    '941_580': 35.6861,
    '941_581': 35.7363,
    '941_582': 35.7777,
    '941_583': 35.811,
    '941_584': 35.8409,
    '941_585': 35.8689,
    '941_586': 35.8972,
    '941_587': 35.9251,
    '941_588': 35.953,
    '941_589': 35.9808,
    '941_590': 36.0055,
    '941_591': 36.0272,
    '941_592': 36.0469,
    '941_593': 36.0644,
    '941_594': 36.0817,
    '941_595': 36.0994,
    '941_596': 36.1171,
    '941_597': 36.1364,
    '941_598': 36.1616,
    '941_599': 36.1915,
    '941_600': 36.223,
    '941_601': 36.2372,
    '941_602': 36.2645,
    '941_603': 36.2979,
    '941_604': 36.3324,
    '941_605': 36.3844,
    '941_606': 36.4312,
    '941_607': 36.4584,
    '941_608': 36.4611,
    '941_609': 36.4274,
    '941_610': 36.3733,
    '941_611': 36.3322,
    '941_612': 36.297,
    '941_613': 36.2629,
    '941_614': 36.2542,
    '941_615': 36.2624,
    '941_616': 36.2772,
    '941_617': 36.2868,
    '941_618': 36.2887,
    '941_619': 36.2923,
    '941_620': 36.2912,
    '941_621': 36.2879,
    '941_622': 36.2887,
    '941_623': 36.2971,
    '941_624': 36.3062,
    '941_625': 36.3101,
    '941_626': 36.3138,
    '941_627': 36.319,
    '941_628': 36.3257,
    '941_629': 36.3356,
    '941_630': 36.3499,
    '941_631': 36.3645,
    '941_632': 36.3748,
    '941_633': 36.3841,
    '941_634': 36.3942,
    '941_635': 36.4067,
    '941_636': 36.4228,
    '941_637': 36.4477,
    '941_638': 36.4874,
    '941_639': 36.5473,
    '941_640': 36.6259,
    '941_641': 36.6909,
    '941_642': 36.7594,
    '941_643': 36.8719,
    '941_644': 37.0271,
    '941_645': 37.2009,
    '941_646': 37.3846,
    '941_647': 37.5503,
    '941_648': 37.7002,
    '941_649': 37.8354,
    '941_650': 37.9608,
    '941_651': 38.0807,
    '941_652': 38.1934,
    '941_653': 38.2908,
    '941_654': 38.3644,
    '941_655': 38.4246,
    '941_656': 38.4792,
    '941_657': 38.5294,
    '941_658': 38.5797,
    '941_659': 38.6362,
    '941_660': 38.6849,
    '941_661': 38.736,
    '941_662': 38.762,
    '941_663': 38.7886,
    '941_664': 38.8408,
    '941_665': 38.8945,
    '941_666': 38.938,
    '941_667': 38.9852,
    '941_668': 39.0322,
    '941_669': 39.0711,
    '941_670': 39.1234,
    '941_671': 39.1775,
    '941_672': 39.2175,
    '941_673': 39.2676,
    '941_674': 39.3347,
    '941_675': 39.3899,
    '941_676': 39.4299,
    '941_677': 39.4681,
    '941_678': 39.5103,
    '941_679': 39.5797,
    '941_680': 39.6699,
    '941_681': 39.7547,
    '941_682': 39.8392,
    '941_683': 39.9252,
    '941_684': 40.0116,
    '941_685': 40.0996,
    '941_686': 40.1913,
    '941_687': 40.2985,
    '941_688': 40.4255,
    '941_689': 40.5556,
    '941_690': 40.6855,
    '941_691': 40.8198,
    '941_692': 40.9576,
    '941_693': 41.0969,
    '941_694': 41.2298,
    '941_695': 41.3603,
    '941_696': 41.5032,
    '941_697': 41.6594,
    '941_698': 41.8397,
    '941_699': 42.024,
    '941_700': 42.1761,
    '941_701': 42.3079,
    '941_702': 42.4156,
    '941_703': 42.5023,
    '941_704': 42.5813,
    '941_705': 42.6533,
    '941_706': 42.7416,
    '941_707': 42.8528,
    '941_708': 43.0001,
    '941_709': 43.1783,
    '941_710': 43.3572,
    '941_711': 43.5043,
    '941_712': 43.5624,
    '941_713': 43.6029,
    '941_714': 43.5874,
    '941_715': 43.5018,
    '941_716': 43.4277,
    '941_717': 43.4059,
    '941_718': 43.4594,
    '941_719': 43.5697,
    '941_720': 43.7005,
    '941_721': 43.8448,
    '941_722': 43.9767,
    '941_723': 44.1096,
    '941_724': 44.2393,
    '941_725': 44.3637,
    '941_726': 44.4535,
    '941_727': 44.5282,
    '941_728': 44.5534,
    '941_729': 44.5315,
    '941_730': 44.4442,
    '941_731': 44.3878,
    '941_732': 44.2347,
    '941_733': 44.0199,
    '941_734': 43.8518,
    '941_735': 43.6129,
    '941_736': 43.3781,
    '941_737': 43.1362,
    '941_738': 42.9371,
    '941_739': 42.8094,
    '941_740': 42.7348,
    '941_741': 42.6998,
    '941_742': 42.7,
    '941_743': 42.7205,
    '941_744': 42.756,
    '941_745': 42.7754,
    '941_746': 42.7654,
    '941_747': 42.7705,
    '941_748': 42.7995,
    '941_749': 42.8473,
    '941_750': 42.9284,
    '941_751': 43.0158,
    '941_752': 43.0756,
    '941_753': 43.1016,
    '941_754': 43.0631,
    '941_755': 42.9799,
    '941_756': 42.858,
    '941_757': 42.7105,
    '941_758': 42.5535,
    '941_759': 42.4116,
    '941_760': 42.2702,
    '941_761': 42.1167,
    '941_762': 41.9431,
    '941_763': 41.7607,
    '941_764': 41.5826,
    '941_765': 41.411,
    '941_766': 41.2275,
    '941_767': 41.0229,
    '941_768': 40.7976,
    '941_769': 40.557,
    '941_770': 40.3072,
    '941_771': 40.0583,
    '941_772': 39.8069,
    '941_773': 39.5541,
    '941_774': 39.3018,
    '941_775': 39.0528,
    '941_776': 38.811,
    '941_777': 38.5817,
    '941_778': 38.3659,
    '941_779': 38.1649,
    '941_780': 37.9796,
    '941_781': 37.809,
    '941_782': 37.6519,
    '941_783': 37.5072,
    '941_784': 37.3736,
    '941_785': 37.2504,
    '941_786': 37.1375,
    '941_787': 37.0317,
    '941_788': 36.9314,
    '941_789': 36.8344,
    '941_790': 36.7402,
    '941_791': 36.6481,
    '941_792': 36.5575,
    '941_793': 36.4658,
    '941_794': 36.3724,
    '941_795': 36.2783,
    '941_796': 36.1852,
    '941_797': 36.0943,
    '941_798': 36.007,
    '941_799': 35.9227,
    '941_800': 35.8406,
    '941_801': 35.7603,
    '941_802': 35.6809,
    '941_803': 35.6018,
    '941_804': 35.5232,
    '941_805': 35.4441,
    '941_806': 35.3626,
    '941_807': 35.2763,
    '941_808': 35.1864,
    '941_809': 35.0948,
    '941_810': 34.999,
    '941_811': 34.8957,
    '941_812': 34.7926,
    '941_813': 34.6828,
    '941_814': 34.5667,
    '941_815': 34.444,
    '941_816': 34.3152,
    '941_817': 34.1825,
    '941_818': 34.0476,
    '941_819': 33.8948,
    '941_820': 33.7404,
    '941_821': 33.5811,
    '941_822': 33.418,
    '941_823': 33.2517,
    '941_824': 33.083,
    '941_825': 32.9114,
    '941_826': 32.7363,
    '941_827': 32.5565,
    '941_828': 32.371,
    '941_829': 32.1789,
    '941_830': 31.9786,
    '941_831': 31.7686,
    '941_832': 31.5492,
    '941_833': 31.3199,
    '941_834': 31.0775,
    '941_835': 30.8175,
    '941_836': 30.54,
    '941_837': 30.2575,
    '941_838': 29.9779,
    '941_839': 29.6985,
    '941_840': 29.3889,
    '941_841': 29.0733,
    '941_842': 28.7585,
    '941_843': 28.4356,
    '941_844': 28.1012,
    '941_845': 27.7546,
    '941_846': 27.3958,
    '941_847': 27.0257,
    '941_848': 26.6443,
    '942_472': 31.2551,
    '942_473': 31.2815,
    '942_474': 31.3092,
    '942_475': 31.3394,
    '942_476': 31.373,
    '942_477': 31.408,
    '942_478': 31.4443,
    '942_479': 31.4818,
    '942_480': 31.5203,
    '942_481': 31.5595,
    '942_482': 31.5995,
    '942_483': 31.6401,
    '942_484': 31.682,
    '942_485': 31.7334,
    '942_486': 31.7875,
    '942_487': 31.8434,
    '942_488': 31.9009,
    '942_489': 31.9606,
    '942_490': 32.0215,
    '942_491': 32.0824,
    '942_492': 32.1427,
    '942_493': 32.2016,
    '942_494': 32.2562,
    '942_495': 32.3107,
    '942_496': 32.368,
    '942_497': 32.4314,
    '942_498': 32.4983,
    '942_499': 32.5634,
    '942_500': 32.6227,
    '942_501': 32.6907,
    '942_502': 32.7653,
    '942_503': 32.8402,
    '942_504': 32.9153,
    '942_505': 32.9901,
    '942_506': 33.0587,
    '942_507': 33.1185,
    '942_508': 33.1766,
    '942_509': 33.238,
    '942_510': 33.3008,
    '942_511': 33.366,
    '942_512': 33.435,
    '942_513': 33.5047,
    '942_514': 33.5686,
    '942_515': 33.6291,
    '942_516': 33.6864,
    '942_517': 33.7344,
    '942_518': 33.7769,
    '942_519': 33.8128,
    '942_520': 33.8481,
    '942_521': 33.8843,
    '942_522': 33.9212,
    '942_523': 33.9568,
    '942_524': 33.9889,
    '942_525': 34.0216,
    '942_526': 34.0556,
    '942_527': 34.0898,
    '942_528': 34.1103,
    '942_529': 34.1327,
    '942_530': 34.1549,
    '942_531': 34.1814,
    '942_532': 34.2039,
    '942_533': 34.2228,
    '942_534': 34.2414,
    '942_535': 34.2614,
    '942_536': 34.2806,
    '942_537': 34.3063,
    '942_538': 34.332,
    '942_539': 34.3526,
    '942_540': 34.375,
    '942_541': 34.3976,
    '942_542': 34.4143,
    '942_543': 34.4318,
    '942_544': 34.4504,
    '942_545': 34.4695,
    '942_546': 34.487,
    '942_547': 34.502,
    '942_548': 34.5184,
    '942_549': 34.5332,
    '942_550': 34.5474,
    '942_551': 34.5635,
    '942_552': 34.5835,
    '942_553': 34.6079,
    '942_554': 34.6352,
    '942_555': 34.6468,
    '942_556': 34.6511,
    '942_557': 34.6595,
    '942_558': 34.6839,
    '942_559': 34.715,
    '942_560': 34.7483,
    '942_561': 34.7811,
    '942_562': 34.8128,
    '942_563': 34.8543,
    '942_564': 34.9015,
    '942_565': 34.949,
    '942_566': 34.9865,
    '942_567': 35.0195,
    '942_568': 35.0504,
    '942_569': 35.0804,
    '942_570': 35.1107,
    '942_571': 35.1433,
    '942_572': 35.1806,
    '942_573': 35.2231,
    '942_574': 35.2703,
    '942_575': 35.3306,
    '942_576': 35.3813,
    '942_577': 35.4309,
    '942_578': 35.483,
    '942_579': 35.5377,
    '942_580': 35.593,
    '942_581': 35.6438,
    '942_582': 35.6835,
    '942_583': 35.7164,
    '942_584': 35.7467,
    '942_585': 35.7758,
    '942_586': 35.8046,
    '942_587': 35.8322,
    '942_588': 35.8597,
    '942_589': 35.8895,
    '942_590': 35.9198,
    '942_591': 35.9469,
    '942_592': 35.9717,
    '942_593': 35.9941,
    '942_594': 36.0144,
    '942_595': 36.0342,
    '942_596': 36.0543,
    '942_597': 36.0747,
    '942_598': 36.0991,
    '942_599': 36.1235,
    '942_600': 36.1477,
    '942_601': 36.1673,
    '942_602': 36.1955,
    '942_603': 36.2343,
    '942_604': 36.2743,
    '942_605': 36.3244,
    '942_606': 36.3705,
    '942_607': 36.3977,
    '942_608': 36.4016,
    '942_609': 36.3657,
    '942_610': 36.3163,
    '942_611': 36.2824,
    '942_612': 36.2471,
    '942_613': 36.22,
    '942_614': 36.2149,
    '942_615': 36.209,
    '942_616': 36.2214,
    '942_617': 36.2308,
    '942_618': 36.23,
    '942_619': 36.2267,
    '942_620': 36.2217,
    '942_621': 36.2221,
    '942_622': 36.2307,
    '942_623': 36.241,
    '942_624': 36.2483,
    '942_625': 36.2525,
    '942_626': 36.2574,
    '942_627': 36.2645,
    '942_628': 36.2752,
    '942_629': 36.2906,
    '942_630': 36.3086,
    '942_631': 36.3245,
    '942_632': 36.3409,
    '942_633': 36.3541,
    '942_634': 36.3671,
    '942_635': 36.382,
    '942_636': 36.4027,
    '942_637': 36.4339,
    '942_638': 36.4779,
    '942_639': 36.5465,
    '942_640': 36.6413,
    '942_641': 36.7118,
    '942_642': 36.7729,
    '942_643': 36.89,
    '942_644': 37.0508,
    '942_645': 37.2261,
    '942_646': 37.4047,
    '942_647': 37.564,
    '942_648': 37.7055,
    '942_649': 37.8337,
    '942_650': 37.9556,
    '942_651': 38.0792,
    '942_652': 38.2081,
    '942_653': 38.3182,
    '942_654': 38.4015,
    '942_655': 38.4683,
    '942_656': 38.5223,
    '942_657': 38.5682,
    '942_658': 38.62,
    '942_659': 38.6839,
    '942_660': 38.7517,
    '942_661': 38.8103,
    '942_662': 38.84,
    '942_663': 38.8723,
    '942_664': 38.9193,
    '942_665': 38.9747,
    '942_666': 39.0181,
    '942_667': 39.0629,
    '942_668': 39.1131,
    '942_669': 39.1513,
    '942_670': 39.1944,
    '942_671': 39.2408,
    '942_672': 39.2833,
    '942_673': 39.3408,
    '942_674': 39.3991,
    '942_675': 39.4412,
    '942_676': 39.4817,
    '942_677': 39.5256,
    '942_678': 39.5684,
    '942_679': 39.641,
    '942_680': 39.7304,
    '942_681': 39.8156,
    '942_682': 39.8975,
    '942_683': 39.9779,
    '942_684': 40.0544,
    '942_685': 40.1381,
    '942_686': 40.2362,
    '942_687': 40.3395,
    '942_688': 40.4576,
    '942_689': 40.5841,
    '942_690': 40.718,
    '942_691': 40.8555,
    '942_692': 40.9989,
    '942_693': 41.1465,
    '942_694': 41.2839,
    '942_695': 41.4203,
    '942_696': 41.5749,
    '942_697': 41.7395,
    '942_698': 41.9206,
    '942_699': 42.0955,
    '942_700': 42.2468,
    '942_701': 42.3782,
    '942_702': 42.4784,
    '942_703': 42.5601,
    '942_704': 42.6323,
    '942_705': 42.7005,
    '942_706': 42.781,
    '942_707': 42.8733,
    '942_708': 43.0077,
    '942_709': 43.1776,
    '942_710': 43.3671,
    '942_711': 43.5196,
    '942_712': 43.5776,
    '942_713': 43.6027,
    '942_714': 43.6095,
    '942_715': 43.5272,
    '942_716': 43.4403,
    '942_717': 43.4033,
    '942_718': 43.4381,
    '942_719': 43.5338,
    '942_720': 43.6611,
    '942_721': 43.8001,
    '942_722': 43.9309,
    '942_723': 44.0633,
    '942_724': 44.2008,
    '942_725': 44.332,
    '942_726': 44.4285,
    '942_727': 44.4815,
    '942_728': 44.5149,
    '942_729': 44.5157,
    '942_730': 44.4335,
    '942_731': 44.3551,
    '942_732': 44.1525,
    '942_733': 44.0154,
    '942_734': 43.8421,
    '942_735': 43.5974,
    '942_736': 43.3697,
    '942_737': 43.1435,
    '942_738': 42.971,
    '942_739': 42.8672,
    '942_740': 42.8114,
    '942_741': 42.7952,
    '942_742': 42.8114,
    '942_743': 42.8472,
    '942_744': 42.8846,
    '942_745': 42.897,
    '942_746': 42.8801,
    '942_747': 42.87,
    '942_748': 42.8811,
    '942_749': 42.9137,
    '942_750': 42.9822,
    '942_751': 43.0669,
    '942_752': 43.134,
    '942_753': 43.1627,
    '942_754': 43.1278,
    '942_755': 43.0525,
    '942_756': 42.9288,
    '942_757': 42.7817,
    '942_758': 42.63,
    '942_759': 42.4864,
    '942_760': 42.3434,
    '942_761': 42.1875,
    '942_762': 42.0192,
    '942_763': 41.8412,
    '942_764': 41.6587,
    '942_765': 41.4735,
    '942_766': 41.2791,
    '942_767': 41.0745,
    '942_768': 40.8486,
    '942_769': 40.6093,
    '942_770': 40.3601,
    '942_771': 40.1159,
    '942_772': 39.8721,
    '942_773': 39.6252,
    '942_774': 39.3772,
    '942_775': 39.1333,
    '942_776': 38.8943,
    '942_777': 38.666,
    '942_778': 38.4524,
    '942_779': 38.255,
    '942_780': 38.0732,
    '942_781': 37.9046,
    '942_782': 37.7486,
    '942_783': 37.6054,
    '942_784': 37.4731,
    '942_785': 37.3514,
    '942_786': 37.2405,
    '942_787': 37.1376,
    '942_788': 37.0404,
    '942_789': 36.9477,
    '942_790': 36.8582,
    '942_791': 36.7707,
    '942_792': 36.6839,
    '942_793': 36.5953,
    '942_794': 36.505,
    '942_795': 36.4144,
    '942_796': 36.3248,
    '942_797': 36.2371,
    '942_798': 36.1529,
    '942_799': 36.0725,
    '942_800': 35.9941,
    '942_801': 35.9178,
    '942_802': 35.8421,
    '942_803': 35.7673,
    '942_804': 35.6922,
    '942_805': 35.6163,
    '942_806': 35.5388,
    '942_807': 35.4567,
    '942_808': 35.3711,
    '942_809': 35.2839,
    '942_810': 35.1913,
    '942_811': 35.0928,
    '942_812': 34.9906,
    '942_813': 34.8817,
    '942_814': 34.7649,
    '942_815': 34.6413,
    '942_816': 34.5114,
    '942_817': 34.3779,
    '942_818': 34.2423,
    '942_819': 34.0916,
    '942_820': 33.9341,
    '942_821': 33.7704,
    '942_822': 33.6027,
    '942_823': 33.4318,
    '942_824': 33.259,
    '942_825': 33.0843,
    '942_826': 32.9072,
    '942_827': 32.726,
    '942_828': 32.54,
    '942_829': 32.3482,
    '942_830': 32.1483,
    '942_831': 31.9371,
    '942_832': 31.7159,
    '942_833': 31.4862,
    '942_834': 31.2447,
    '942_835': 30.9815,
    '942_836': 30.6967,
    '942_837': 30.4053,
    '942_838': 30.1196,
    '942_839': 29.8328,
    '942_840': 29.526,
    '942_841': 29.2108,
    '942_842': 28.894,
    '942_843': 28.5688,
    '942_844': 28.2301,
    '942_845': 27.8743,
    '942_846': 27.5188,
    '942_847': 27.151,
    '942_848': 26.7686,
    '943_472': 31.2146,
    '943_473': 31.2398,
    '943_474': 31.2651,
    '943_475': 31.2929,
    '943_476': 31.325,
    '943_477': 31.3587,
    '943_478': 31.3924,
    '943_479': 31.4279,
    '943_480': 31.4647,
    '943_481': 31.5024,
    '943_482': 31.5413,
    '943_483': 31.5815,
    '943_484': 31.6217,
    '943_485': 31.6709,
    '943_486': 31.7247,
    '943_487': 31.7812,
    '943_488': 31.8406,
    '943_489': 31.9001,
    '943_490': 31.9606,
    '943_491': 32.0213,
    '943_492': 32.0816,
    '943_493': 32.1397,
    '943_494': 32.1953,
    '943_495': 32.2512,
    '943_496': 32.3108,
    '943_497': 32.3766,
    '943_498': 32.4436,
    '943_499': 32.5069,
    '943_500': 32.5677,
    '943_501': 32.635,
    '943_502': 32.7071,
    '943_503': 32.78,
    '943_504': 32.8485,
    '943_505': 32.9202,
    '943_506': 32.9959,
    '943_507': 33.0617,
    '943_508': 33.121,
    '943_509': 33.1857,
    '943_510': 33.2505,
    '943_511': 33.3174,
    '943_512': 33.3888,
    '943_513': 33.4618,
    '943_514': 33.5291,
    '943_515': 33.5916,
    '943_516': 33.6492,
    '943_517': 33.6941,
    '943_518': 33.7407,
    '943_519': 33.7775,
    '943_520': 33.8109,
    '943_521': 33.8445,
    '943_522': 33.8798,
    '943_523': 33.9133,
    '943_524': 33.9413,
    '943_525': 33.9673,
    '943_526': 33.9953,
    '943_527': 34.0271,
    '943_528': 34.0608,
    '943_529': 34.0825,
    '943_530': 34.103,
    '943_531': 34.1294,
    '943_532': 34.1442,
    '943_533': 34.1599,
    '943_534': 34.1769,
    '943_535': 34.195,
    '943_536': 34.2132,
    '943_537': 34.2357,
    '943_538': 34.26,
    '943_539': 34.2803,
    '943_540': 34.3013,
    '943_541': 34.3213,
    '943_542': 34.3398,
    '943_543': 34.3606,
    '943_544': 34.3807,
    '943_545': 34.3985,
    '943_546': 34.4135,
    '943_547': 34.4238,
    '943_548': 34.4422,
    '943_549': 34.461,
    '943_550': 34.4797,
    '943_551': 34.4997,
    '943_552': 34.5225,
    '943_553': 34.5488,
    '943_554': 34.5783,
    '943_555': 34.5936,
    '943_556': 34.6041,
    '943_557': 34.6196,
    '943_558': 34.6462,
    '943_559': 34.6795,
    '943_560': 34.7153,
    '943_561': 34.7501,
    '943_562': 34.7786,
    '943_563': 34.8125,
    '943_564': 34.8518,
    '943_565': 34.8934,
    '943_566': 34.9273,
    '943_567': 34.9586,
    '943_568': 34.9882,
    '943_569': 35.0162,
    '943_570': 35.0436,
    '943_571': 35.0723,
    '943_572': 35.1044,
    '943_573': 35.1419,
    '943_574': 35.1862,
    '943_575': 35.2433,
    '943_576': 35.2907,
    '943_577': 35.3382,
    '943_578': 35.3881,
    '943_579': 35.4429,
    '943_580': 35.5004,
    '943_581': 35.5544,
    '943_582': 35.5916,
    '943_583': 35.6232,
    '943_584': 35.6535,
    '943_585': 35.6844,
    '943_586': 35.7144,
    '943_587': 35.7416,
    '943_588': 35.7673,
    '943_589': 35.7983,
    '943_590': 35.8336,
    '943_591': 35.8669,
    '943_592': 35.8982,
    '943_593': 35.9254,
    '943_594': 35.9479,
    '943_595': 35.9698,
    '943_596': 35.9917,
    '943_597': 36.013,
    '943_598': 36.0353,
    '943_599': 36.0571,
    '943_600': 36.0793,
    '943_601': 36.1002,
    '943_602': 36.1267,
    '943_603': 36.1623,
    '943_604': 36.2074,
    '943_605': 36.256,
    '943_606': 36.3026,
    '943_607': 36.3257,
    '943_608': 36.3291,
    '943_609': 36.2884,
    '943_610': 36.2475,
    '943_611': 36.2204,
    '943_612': 36.1859,
    '943_613': 36.1614,
    '943_614': 36.1544,
    '943_615': 36.1517,
    '943_616': 36.1628,
    '943_617': 36.1735,
    '943_618': 36.1751,
    '943_619': 36.1635,
    '943_620': 36.1535,
    '943_621': 36.1608,
    '943_622': 36.1754,
    '943_623': 36.1867,
    '943_624': 36.1908,
    '943_625': 36.1935,
    '943_626': 36.1984,
    '943_627': 36.2071,
    '943_628': 36.2215,
    '943_629': 36.2428,
    '943_630': 36.2648,
    '943_631': 36.2852,
    '943_632': 36.3053,
    '943_633': 36.323,
    '943_634': 36.3391,
    '943_635': 36.3565,
    '943_636': 36.3823,
    '943_637': 36.4192,
    '943_638': 36.4702,
    '943_639': 36.5498,
    '943_640': 36.6476,
    '943_641': 36.7232,
    '943_642': 36.7828,
    '943_643': 36.9012,
    '943_644': 37.0639,
    '943_645': 37.2463,
    '943_646': 37.4105,
    '943_647': 37.5573,
    '943_648': 37.6873,
    '943_649': 37.8086,
    '943_650': 37.9308,
    '943_651': 38.0664,
    '943_652': 38.2114,
    '943_653': 38.3335,
    '943_654': 38.4299,
    '943_655': 38.5023,
    '943_656': 38.5621,
    '943_657': 38.6115,
    '943_658': 38.6689,
    '943_659': 38.7454,
    '943_660': 38.8267,
    '943_661': 38.891,
    '943_662': 38.9259,
    '943_663': 38.9536,
    '943_664': 38.9931,
    '943_665': 39.0446,
    '943_666': 39.09,
    '943_667': 39.1379,
    '943_668': 39.1908,
    '943_669': 39.2285,
    '943_670': 39.2617,
    '943_671': 39.2993,
    '943_672': 39.353,
    '943_673': 39.415,
    '943_674': 39.473,
    '943_675': 39.5142,
    '943_676': 39.547,
    '943_677': 39.5788,
    '943_678': 39.623,
    '943_679': 39.7017,
    '943_680': 39.7896,
    '943_681': 39.8712,
    '943_682': 39.9478,
    '943_683': 40.0264,
    '943_684': 40.1019,
    '943_685': 40.1832,
    '943_686': 40.2798,
    '943_687': 40.3834,
    '943_688': 40.4917,
    '943_689': 40.6168,
    '943_690': 40.7522,
    '943_691': 40.8922,
    '943_692': 41.0385,
    '943_693': 41.1909,
    '943_694': 41.3403,
    '943_695': 41.493,
    '943_696': 41.6568,
    '943_697': 41.8244,
    '943_698': 41.9973,
    '943_699': 42.1601,
    '943_700': 42.3002,
    '943_701': 42.4246,
    '943_702': 42.5181,
    '943_703': 42.6047,
    '943_704': 42.6707,
    '943_705': 42.746,
    '943_706': 42.8169,
    '943_707': 42.9047,
    '943_708': 43.0246,
    '943_709': 43.1907,
    '943_710': 43.3706,
    '943_711': 43.5402,
    '943_712': 43.6022,
    '943_713': 43.5987,
    '943_714': 43.6114,
    '943_715': 43.537,
    '943_716': 43.448,
    '943_717': 43.3981,
    '943_718': 43.4154,
    '943_719': 43.4931,
    '943_720': 43.6124,
    '943_721': 43.7532,
    '943_722': 43.8904,
    '943_723': 44.0174,
    '943_724': 44.1524,
    '943_725': 44.277,
    '943_726': 44.3744,
    '943_727': 44.3998,
    '943_728': 44.4435,
    '943_729': 44.475,
    '943_730': 44.3928,
    '943_731': 44.2639,
    '943_732': 44.0971,
    '943_733': 43.9609,
    '943_734': 43.7824,
    '943_735': 43.5634,
    '943_736': 43.3457,
    '943_737': 43.1465,
    '943_738': 43.0079,
    '943_739': 42.9316,
    '943_740': 42.8975,
    '943_741': 42.8977,
    '943_742': 42.9253,
    '943_743': 42.9734,
    '943_744': 43.0139,
    '943_745': 43.0265,
    '943_746': 43.0079,
    '943_747': 42.9869,
    '943_748': 42.9838,
    '943_749': 43.0022,
    '943_750': 43.0527,
    '943_751': 43.127,
    '943_752': 43.1943,
    '943_753': 43.2193,
    '943_754': 43.1881,
    '943_755': 43.1167,
    '943_756': 42.9915,
    '943_757': 42.8422,
    '943_758': 42.6954,
    '943_759': 42.555,
    '943_760': 42.4155,
    '943_761': 42.2617,
    '943_762': 42.0957,
    '943_763': 41.9141,
    '943_764': 41.7265,
    '943_765': 41.5345,
    '943_766': 41.3306,
    '943_767': 41.1203,
    '943_768': 40.8906,
    '943_769': 40.6497,
    '943_770': 40.4093,
    '943_771': 40.1702,
    '943_772': 39.9339,
    '943_773': 39.6956,
    '943_774': 39.4566,
    '943_775': 39.2163,
    '943_776': 38.9778,
    '943_777': 38.7494,
    '943_778': 38.5395,
    '943_779': 38.3477,
    '943_780': 38.1707,
    '943_781': 38.0045,
    '943_782': 37.8504,
    '943_783': 37.7086,
    '943_784': 37.578,
    '943_785': 37.4585,
    '943_786': 37.3497,
    '943_787': 37.2492,
    '943_788': 37.1553,
    '943_789': 37.0666,
    '943_790': 36.981,
    '943_791': 36.8966,
    '943_792': 36.8123,
    '943_793': 36.7267,
    '943_794': 36.6401,
    '943_795': 36.5537,
    '943_796': 36.4685,
    '943_797': 36.3849,
    '943_798': 36.3049,
    '943_799': 36.2277,
    '943_800': 36.1523,
    '943_801': 36.0793,
    '943_802': 36.0069,
    '943_803': 35.9347,
    '943_804': 35.8621,
    '943_805': 35.7889,
    '943_806': 35.7144,
    '943_807': 35.636,
    '943_808': 35.5548,
    '943_809': 35.4719,
    '943_810': 35.3848,
    '943_811': 35.2895,
    '943_812': 35.1888,
    '943_813': 35.0807,
    '943_814': 34.9645,
    '943_815': 34.8412,
    '943_816': 34.7111,
    '943_817': 34.5769,
    '943_818': 34.4435,
    '943_819': 34.2914,
    '943_820': 34.1317,
    '943_821': 33.9641,
    '943_822': 33.7907,
    '943_823': 33.6147,
    '943_824': 33.4365,
    '943_825': 33.2573,
    '943_826': 33.077,
    '943_827': 32.8935,
    '943_828': 32.7062,
    '943_829': 32.5124,
    '943_830': 32.3094,
    '943_831': 32.0979,
    '943_832': 31.8758,
    '943_833': 31.6435,
    '943_834': 31.3972,
    '943_835': 31.1329,
    '943_836': 30.8468,
    '943_837': 30.5526,
    '943_838': 30.2583,
    '943_839': 29.9694,
    '943_840': 29.6666,
    '943_841': 29.3455,
    '943_842': 29.0258,
    '943_843': 28.7002,
    '943_844': 28.3632,
    '943_845': 28.0041,
    '943_846': 27.6442,
    '943_847': 27.2775,
    '943_848': 26.89,
    '944_472': 31.1779,
    '944_473': 31.2035,
    '944_474': 31.2271,
    '944_475': 31.2527,
    '944_476': 31.2806,
    '944_477': 31.3094,
    '944_478': 31.3418,
    '944_479': 31.3759,
    '944_480': 31.411,
    '944_481': 31.4467,
    '944_482': 31.4834,
    '944_483': 31.5218,
    '944_484': 31.564,
    '944_485': 31.6124,
    '944_486': 31.6652,
    '944_487': 31.7212,
    '944_488': 31.7809,
    '944_489': 31.8412,
    '944_490': 31.902,
    '944_491': 31.9627,
    '944_492': 32.0227,
    '944_493': 32.0814,
    '944_494': 32.1384,
    '944_495': 32.1962,
    '944_496': 32.2595,
    '944_497': 32.3276,
    '944_498': 32.3957,
    '944_499': 32.4589,
    '944_500': 32.5228,
    '944_501': 32.591,
    '944_502': 32.6618,
    '944_503': 32.732,
    '944_504': 32.7996,
    '944_505': 32.8688,
    '944_506': 32.9415,
    '944_507': 33.0117,
    '944_508': 33.0785,
    '944_509': 33.1456,
    '944_510': 33.2099,
    '944_511': 33.275,
    '944_512': 33.3472,
    '944_513': 33.4219,
    '944_514': 33.49,
    '944_515': 33.554,
    '944_516': 33.6171,
    '944_517': 33.6626,
    '944_518': 33.7086,
    '944_519': 33.7553,
    '944_520': 33.7941,
    '944_521': 33.826,
    '944_522': 33.8583,
    '944_523': 33.8892,
    '944_524': 33.9173,
    '944_525': 33.9409,
    '944_526': 33.965,
    '944_527': 33.9912,
    '944_528': 34.0231,
    '944_529': 34.0517,
    '944_530': 34.0721,
    '944_531': 34.0898,
    '944_532': 34.1008,
    '944_533': 34.1152,
    '944_534': 34.1302,
    '944_535': 34.1464,
    '944_536': 34.1636,
    '944_537': 34.1827,
    '944_538': 34.2027,
    '944_539': 34.2178,
    '944_540': 34.2326,
    '944_541': 34.2506,
    '944_542': 34.2718,
    '944_543': 34.2946,
    '944_544': 34.3143,
    '944_545': 34.3299,
    '944_546': 34.3413,
    '944_547': 34.354,
    '944_548': 34.3717,
    '944_549': 34.3915,
    '944_550': 34.4122,
    '944_551': 34.4339,
    '944_552': 34.4572,
    '944_553': 34.4822,
    '944_554': 34.5107,
    '944_555': 34.5335,
    '944_556': 34.5524,
    '944_557': 34.5753,
    '944_558': 34.6069,
    '944_559': 34.6432,
    '944_560': 34.6797,
    '944_561': 34.7118,
    '944_562': 34.7387,
    '944_563': 34.7666,
    '944_564': 34.7977,
    '944_565': 34.8328,
    '944_566': 34.8663,
    '944_567': 34.8982,
    '944_568': 34.9286,
    '944_569': 34.955,
    '944_570': 34.9797,
    '944_571': 35.0043,
    '944_572': 35.0309,
    '944_573': 35.0631,
    '944_574': 35.1053,
    '944_575': 35.1609,
    '944_576': 35.2057,
    '944_577': 35.2508,
    '944_578': 35.2977,
    '944_579': 35.3489,
    '944_580': 35.4049,
    '944_581': 35.4565,
    '944_582': 35.4972,
    '944_583': 35.5316,
    '944_584': 35.5641,
    '944_585': 35.5984,
    '944_586': 35.6307,
    '944_587': 35.6588,
    '944_588': 35.6832,
    '944_589': 35.7077,
    '944_590': 35.7401,
    '944_591': 35.7866,
    '944_592': 35.8269,
    '944_593': 35.8583,
    '944_594': 35.8835,
    '944_595': 35.9078,
    '944_596': 35.9302,
    '944_597': 35.9531,
    '944_598': 35.9768,
    '944_599': 35.9984,
    '944_600': 36.0153,
    '944_601': 36.0343,
    '944_602': 36.0617,
    '944_603': 36.0939,
    '944_604': 36.1366,
    '944_605': 36.1883,
    '944_606': 36.2297,
    '944_607': 36.245,
    '944_608': 36.2527,
    '944_609': 36.2234,
    '944_610': 36.1838,
    '944_611': 36.1537,
    '944_612': 36.1263,
    '944_613': 36.1053,
    '944_614': 36.0985,
    '944_615': 36.1035,
    '944_616': 36.1144,
    '944_617': 36.1251,
    '944_618': 36.1268,
    '944_619': 36.1049,
    '944_620': 36.0902,
    '944_621': 36.1036,
    '944_622': 36.1222,
    '944_623': 36.13,
    '944_624': 36.1328,
    '944_625': 36.1351,
    '944_626': 36.1404,
    '944_627': 36.1509,
    '944_628': 36.1704,
    '944_629': 36.1974,
    '944_630': 36.2242,
    '944_631': 36.2508,
    '944_632': 36.2758,
    '944_633': 36.2953,
    '944_634': 36.3109,
    '944_635': 36.3315,
    '944_636': 36.3638,
    '944_637': 36.4063,
    '944_638': 36.4607,
    '944_639': 36.5386,
    '944_640': 36.6389,
    '944_641': 36.7168,
    '944_642': 36.7912,
    '944_643': 36.9113,
    '944_644': 37.0706,
    '944_645': 37.2559,
    '944_646': 37.4081,
    '944_647': 37.5398,
    '944_648': 37.6598,
    '944_649': 37.7854,
    '944_650': 37.9171,
    '944_651': 38.0592,
    '944_652': 38.2107,
    '944_653': 38.345,
    '944_654': 38.4504,
    '944_655': 38.5286,
    '944_656': 38.5946,
    '944_657': 38.6557,
    '944_658': 38.7291,
    '944_659': 38.8184,
    '944_660': 38.9105,
    '944_661': 38.977,
    '944_662': 39.0098,
    '944_663': 39.0303,
    '944_664': 39.0629,
    '944_665': 39.1097,
    '944_666': 39.158,
    '944_667': 39.2125,
    '944_668': 39.2661,
    '944_669': 39.3022,
    '944_670': 39.3285,
    '944_671': 39.3681,
    '944_672': 39.4302,
    '944_673': 39.4949,
    '944_674': 39.5548,
    '944_675': 39.595,
    '944_676': 39.6167,
    '944_677': 39.64,
    '944_678': 39.6797,
    '944_679': 39.7585,
    '944_680': 39.846,
    '944_681': 39.9253,
    '944_682': 39.9972,
    '944_683': 40.0721,
    '944_684': 40.1493,
    '944_685': 40.2302,
    '944_686': 40.3235,
    '944_687': 40.4297,
    '944_688': 40.5326,
    '944_689': 40.6528,
    '944_690': 40.7869,
    '944_691': 40.928,
    '944_692': 41.0779,
    '944_693': 41.2373,
    '944_694': 41.4029,
    '944_695': 41.577,
    '944_696': 41.7414,
    '944_697': 41.908,
    '944_698': 42.074,
    '944_699': 42.2157,
    '944_700': 42.3559,
    '944_701': 42.4709,
    '944_702': 42.5568,
    '944_703': 42.6379,
    '944_704': 42.7047,
    '944_705': 42.7788,
    '944_706': 42.8463,
    '944_707': 42.9297,
    '944_708': 43.0399,
    '944_709': 43.2019,
    '944_710': 43.3812,
    '944_711': 43.5361,
    '944_712': 43.6026,
    '944_713': 43.6703,
    '944_714': 43.6383,
    '944_715': 43.5504,
    '944_716': 43.4573,
    '944_717': 43.3936,
    '944_718': 43.3964,
    '944_719': 43.4605,
    '944_720': 43.5746,
    '944_721': 43.7101,
    '944_722': 43.834,
    '944_723': 43.9657,
    '944_724': 44.0981,
    '944_725': 44.2099,
    '944_726': 44.3193,
    '944_727': 44.3899,
    '944_728': 44.393,
    '944_729': 44.397,
    '944_730': 44.3063,
    '944_731': 44.2207,
    '944_732': 44.0664,
    '944_733': 43.892,
    '944_734': 43.7076,
    '944_735': 43.5108,
    '944_736': 43.3187,
    '944_737': 43.1577,
    '944_738': 43.0557,
    '944_739': 43.008,
    '944_740': 42.9975,
    '944_741': 43.0126,
    '944_742': 43.0495,
    '944_743': 43.1021,
    '944_744': 43.1402,
    '944_745': 43.1621,
    '944_746': 43.147,
    '944_747': 43.1248,
    '944_748': 43.1089,
    '944_749': 43.1142,
    '944_750': 43.1511,
    '944_751': 43.2067,
    '944_752': 43.2621,
    '944_753': 43.2851,
    '944_754': 43.2377,
    '944_755': 43.167,
    '944_756': 43.0412,
    '944_757': 42.8943,
    '944_758': 42.7518,
    '944_759': 42.6161,
    '944_760': 42.4737,
    '944_761': 42.3245,
    '944_762': 42.1658,
    '944_763': 41.9841,
    '944_764': 41.7942,
    '944_765': 41.5986,
    '944_766': 41.395,
    '944_767': 41.179,
    '944_768': 40.943,
    '944_769': 40.7009,
    '944_770': 40.4643,
    '944_771': 40.2318,
    '944_772': 40.0027,
    '944_773': 39.7715,
    '944_774': 39.5381,
    '944_775': 39.3005,
    '944_776': 39.063,
    '944_777': 38.8374,
    '944_778': 38.6329,
    '944_779': 38.4469,
    '944_780': 38.2745,
    '944_781': 38.1124,
    '944_782': 37.961,
    '944_783': 37.8207,
    '944_784': 37.6921,
    '944_785': 37.5748,
    '944_786': 37.4675,
    '944_787': 37.3691,
    '944_788': 37.2777,
    '944_789': 37.1918,
    '944_790': 37.1083,
    '944_791': 37.026,
    '944_792': 36.9448,
    '944_793': 36.8632,
    '944_794': 36.7813,
    '944_795': 36.7,
    '944_796': 36.6202,
    '944_797': 36.5417,
    '944_798': 36.4654,
    '944_799': 36.3912,
    '944_800': 36.3188,
    '944_801': 36.2485,
    '944_802': 36.1783,
    '944_803': 36.1079,
    '944_804': 36.0369,
    '944_805': 35.9656,
    '944_806': 35.8934,
    '944_807': 35.8178,
    '944_808': 35.7391,
    '944_809': 35.6591,
    '944_810': 35.5742,
    '944_811': 35.4838,
    '944_812': 35.3862,
    '944_813': 35.2798,
    '944_814': 35.1644,
    '944_815': 35.042,
    '944_816': 34.9124,
    '944_817': 34.7777,
    '944_818': 34.6408,
    '944_819': 34.4914,
    '944_820': 34.3325,
    '944_821': 34.1623,
    '944_822': 33.9849,
    '944_823': 33.8022,
    '944_824': 33.6171,
    '944_825': 33.432,
    '944_826': 33.247,
    '944_827': 33.0605,
    '944_828': 32.8704,
    '944_829': 32.6726,
    '944_830': 32.4656,
    '944_831': 32.25,
    '944_832': 32.025,
    '944_833': 31.7895,
    '944_834': 31.54,
    '944_835': 31.2739,
    '944_836': 30.9886,
    '944_837': 30.6942,
    '944_838': 30.3949,
    '944_839': 30.0919,
    '944_840': 29.7854,
    '944_841': 29.4675,
    '944_842': 29.1482,
    '944_843': 28.8197,
    '944_844': 28.4762,
    '944_845': 28.1152,
    '944_846': 27.7468,
    '944_847': 27.3795,
    '944_848': 26.9973,
    '945_472': 31.1467,
    '945_473': 31.172,
    '945_474': 31.1929,
    '945_475': 31.214,
    '945_476': 31.2368,
    '945_477': 31.2594,
    '945_478': 31.2916,
    '945_479': 31.3247,
    '945_480': 31.3578,
    '945_481': 31.391,
    '945_482': 31.4248,
    '945_483': 31.4615,
    '945_484': 31.5059,
    '945_485': 31.555,
    '945_486': 31.6078,
    '945_487': 31.6637,
    '945_488': 31.7233,
    '945_489': 31.7837,
    '945_490': 31.8445,
    '945_491': 31.9055,
    '945_492': 31.9662,
    '945_493': 32.0262,
    '945_494': 32.0853,
    '945_495': 32.1446,
    '945_496': 32.2102,
    '945_497': 32.2795,
    '945_498': 32.3449,
    '945_499': 32.4119,
    '945_500': 32.481,
    '945_501': 32.5527,
    '945_502': 32.6246,
    '945_503': 32.6943,
    '945_504': 32.7613,
    '945_505': 32.8274,
    '945_506': 32.8969,
    '945_507': 32.9671,
    '945_508': 33.0363,
    '945_509': 33.11,
    '945_510': 33.1748,
    '945_511': 33.2359,
    '945_512': 33.3078,
    '945_513': 33.3836,
    '945_514': 33.4447,
    '945_515': 33.5122,
    '945_516': 33.5839,
    '945_517': 33.6276,
    '945_518': 33.6721,
    '945_519': 33.7257,
    '945_520': 33.7715,
    '945_521': 33.8061,
    '945_522': 33.8406,
    '945_523': 33.8704,
    '945_524': 33.9002,
    '945_525': 33.9215,
    '945_526': 33.9425,
    '945_527': 33.9599,
    '945_528': 33.985,
    '945_529': 34.0123,
    '945_530': 34.0382,
    '945_531': 34.054,
    '945_532': 34.0636,
    '945_533': 34.0802,
    '945_534': 34.0932,
    '945_535': 34.1076,
    '945_536': 34.122,
    '945_537': 34.1355,
    '945_538': 34.1475,
    '945_539': 34.1607,
    '945_540': 34.1776,
    '945_541': 34.1894,
    '945_542': 34.2122,
    '945_543': 34.2345,
    '945_544': 34.2519,
    '945_545': 34.2655,
    '945_546': 34.2748,
    '945_547': 34.2865,
    '945_548': 34.3036,
    '945_549': 34.3236,
    '945_550': 34.3449,
    '945_551': 34.3672,
    '945_552': 34.3909,
    '945_553': 34.4155,
    '945_554': 34.4433,
    '945_555': 34.4703,
    '945_556': 34.4962,
    '945_557': 34.5256,
    '945_558': 34.5636,
    '945_559': 34.6029,
    '945_560': 34.6403,
    '945_561': 34.6693,
    '945_562': 34.6937,
    '945_563': 34.7174,
    '945_564': 34.7427,
    '945_565': 34.7718,
    '945_566': 34.8044,
    '945_567': 34.8374,
    '945_568': 34.8695,
    '945_569': 34.8945,
    '945_570': 34.917,
    '945_571': 34.9388,
    '945_572': 34.9613,
    '945_573': 34.9906,
    '945_574': 35.03,
    '945_575': 35.0833,
    '945_576': 35.1249,
    '945_577': 35.1666,
    '945_578': 35.2097,
    '945_579': 35.2571,
    '945_580': 35.3114,
    '945_581': 35.3613,
    '945_582': 35.4049,
    '945_583': 35.4436,
    '945_584': 35.4806,
    '945_585': 35.5168,
    '945_586': 35.55,
    '945_587': 35.5772,
    '945_588': 35.5976,
    '945_589': 35.6227,
    '945_590': 35.6649,
    '945_591': 35.7153,
    '945_592': 35.7592,
    '945_593': 35.7947,
    '945_594': 35.8237,
    '945_595': 35.8499,
    '945_596': 35.8708,
    '945_597': 35.8944,
    '945_598': 35.9194,
    '945_599': 35.9419,
    '945_600': 35.9531,
    '945_601': 35.9697,
    '945_602': 35.9953,
    '945_603': 36.0244,
    '945_604': 36.0594,
    '945_605': 36.0998,
    '945_606': 36.1377,
    '945_607': 36.1576,
    '945_608': 36.1755,
    '945_609': 36.1644,
    '945_610': 36.123,
    '945_611': 36.0904,
    '945_612': 36.0698,
    '945_613': 36.0515,
    '945_614': 36.0448,
    '945_615': 36.0479,
    '945_616': 36.0612,
    '945_617': 36.0689,
    '945_618': 36.0607,
    '945_619': 36.0423,
    '945_620': 36.0365,
    '945_621': 36.0501,
    '945_622': 36.063,
    '945_623': 36.0691,
    '945_624': 36.0717,
    '945_625': 36.0746,
    '945_626': 36.0812,
    '945_627': 36.0942,
    '945_628': 36.1164,
    '945_629': 36.1474,
    '945_630': 36.1795,
    '945_631': 36.2106,
    '945_632': 36.237,
    '945_633': 36.2579,
    '945_634': 36.2744,
    '945_635': 36.3033,
    '945_636': 36.3434,
    '945_637': 36.3907,
    '945_638': 36.4501,
    '945_639': 36.5246,
    '945_640': 36.6152,
    '945_641': 36.7025,
    '945_642': 36.7969,
    '945_643': 36.9214,
    '945_644': 37.0785,
    '945_645': 37.26,
    '945_646': 37.3992,
    '945_647': 37.5204,
    '945_648': 37.6371,
    '945_649': 37.7736,
    '945_650': 37.9106,
    '945_651': 38.0513,
    '945_652': 38.2024,
    '945_653': 38.3445,
    '945_654': 38.4558,
    '945_655': 38.5429,
    '945_656': 38.622,
    '945_657': 38.7033,
    '945_658': 38.793,
    '945_659': 38.89,
    '945_660': 38.9787,
    '945_661': 39.0417,
    '945_662': 39.0779,
    '945_663': 39.0966,
    '945_664': 39.1268,
    '945_665': 39.1698,
    '945_666': 39.2222,
    '945_667': 39.283,
    '945_668': 39.3325,
    '945_669': 39.37,
    '945_670': 39.4015,
    '945_671': 39.4472,
    '945_672': 39.5059,
    '945_673': 39.5702,
    '945_674': 39.6253,
    '945_675': 39.6622,
    '945_676': 39.68,
    '945_677': 39.6992,
    '945_678': 39.7336,
    '945_679': 39.8068,
    '945_680': 39.8965,
    '945_681': 39.978,
    '945_682': 40.0527,
    '945_683': 40.1228,
    '945_684': 40.1982,
    '945_685': 40.2821,
    '945_686': 40.3702,
    '945_687': 40.474,
    '945_688': 40.575,
    '945_689': 40.6886,
    '945_690': 40.8221,
    '945_691': 40.9633,
    '945_692': 41.1171,
    '945_693': 41.2875,
    '945_694': 41.4752,
    '945_695': 41.6556,
    '945_696': 41.8343,
    '945_697': 41.9901,
    '945_698': 42.131,
    '945_699': 42.2697,
    '945_700': 42.3996,
    '945_701': 42.4952,
    '945_702': 42.5843,
    '945_703': 42.6623,
    '945_704': 42.7308,
    '945_705': 42.7953,
    '945_706': 42.8579,
    '945_707': 42.9398,
    '945_708': 43.0483,
    '945_709': 43.205,
    '945_710': 43.3816,
    '945_711': 43.5259,
    '945_712': 43.6231,
    '945_713': 43.6597,
    '945_714': 43.6652,
    '945_715': 43.5742,
    '945_716': 43.4664,
    '945_717': 43.3912,
    '945_718': 43.3812,
    '945_719': 43.4375,
    '945_720': 43.5433,
    '945_721': 43.6689,
    '945_722': 43.7842,
    '945_723': 43.9085,
    '945_724': 44.0315,
    '945_725': 44.1519,
    '945_726': 44.2736,
    '945_727': 44.3301,
    '945_728': 44.366,
    '945_729': 44.3101,
    '945_730': 44.2364,
    '945_731': 44.1376,
    '945_732': 43.978,
    '945_733': 43.8018,
    '945_734': 43.6281,
    '945_735': 43.4591,
    '945_736': 43.2986,
    '945_737': 43.1789,
    '945_738': 43.112,
    '945_739': 43.0934,
    '945_740': 43.1043,
    '945_741': 43.1353,
    '945_742': 43.1775,
    '945_743': 43.2304,
    '945_744': 43.2676,
    '945_745': 43.2967,
    '945_746': 43.2922,
    '945_747': 43.2717,
    '945_748': 43.2475,
    '945_749': 43.2424,
    '945_750': 43.2668,
    '945_751': 43.3069,
    '945_752': 43.3396,
    '945_753': 43.3439,
    '945_754': 43.2898,
    '945_755': 43.2164,
    '945_756': 43.0919,
    '945_757': 42.9494,
    '945_758': 42.8097,
    '945_759': 42.667,
    '945_760': 42.5274,
    '945_761': 42.3842,
    '945_762': 42.2247,
    '945_763': 42.0518,
    '945_764': 41.8687,
    '945_765': 41.6751,
    '945_766': 41.4704,
    '945_767': 41.2448,
    '945_768': 41.0079,
    '945_769': 40.7678,
    '945_770': 40.5334,
    '945_771': 40.306,
    '945_772': 40.0779,
    '945_773': 39.849,
    '945_774': 39.6177,
    '945_775': 39.3821,
    '945_776': 39.1478,
    '945_777': 38.929,
    '945_778': 38.7316,
    '945_779': 38.5516,
    '945_780': 38.3842,
    '945_781': 38.2269,
    '945_782': 38.0785,
    '945_783': 37.9405,
    '945_784': 37.8129,
    '945_785': 37.6972,
    '945_786': 37.591,
    '945_787': 37.4938,
    '945_788': 37.4038,
    '945_789': 37.3188,
    '945_790': 37.2367,
    '945_791': 37.1572,
    '945_792': 37.0798,
    '945_793': 37.0033,
    '945_794': 36.9273,
    '945_795': 36.852,
    '945_796': 36.7782,
    '945_797': 36.7053,
    '945_798': 36.6329,
    '945_799': 36.5617,
    '945_800': 36.4923,
    '945_801': 36.4243,
    '945_802': 36.3554,
    '945_803': 36.2856,
    '945_804': 36.2157,
    '945_805': 36.1462,
    '945_806': 36.0743,
    '945_807': 36,
    '945_808': 35.9226,
    '945_809': 35.8429,
    '945_810': 35.7593,
    '945_811': 35.6712,
    '945_812': 35.577,
    '945_813': 35.4725,
    '945_814': 35.3597,
    '945_815': 35.2403,
    '945_816': 35.1123,
    '945_817': 34.9777,
    '945_818': 34.8383,
    '945_819': 34.6894,
    '945_820': 34.5312,
    '945_821': 34.359,
    '945_822': 34.1775,
    '945_823': 33.9899,
    '945_824': 33.7979,
    '945_825': 33.6057,
    '945_826': 33.4144,
    '945_827': 33.224,
    '945_828': 33.0294,
    '945_829': 32.8259,
    '945_830': 32.614,
    '945_831': 32.3929,
    '945_832': 32.1645,
    '945_833': 31.9254,
    '945_834': 31.672,
    '945_835': 31.4034,
    '945_836': 31.12,
    '945_837': 30.8272,
    '945_838': 30.5274,
    '945_839': 30.2172,
    '945_840': 29.9064,
    '945_841': 29.5826,
    '945_842': 29.2622,
    '945_843': 28.9311,
    '945_844': 28.5828,
    '945_845': 28.2206,
    '945_846': 27.851,
    '945_847': 27.4773,
    '945_848': 27.0957,
    '946_560': 34.5939,
    '946_561': 34.6231,
    '946_562': 34.6459,
    '946_563': 34.6659,
    '946_564': 34.6865,
    '946_565': 34.7108,
    '946_566': 34.7435,
    '946_567': 34.7771,
    '946_568': 34.8077,
    '946_569': 34.8323,
    '946_570': 34.8549,
    '946_571': 34.877,
    '946_572': 34.8955,
    '946_573': 34.9243,
    '946_574': 34.9604,
    '946_575': 35.0109,
    '946_576': 35.0483,
    '946_577': 35.086,
    '946_578': 35.1253,
    '946_579': 35.169,
    '946_580': 35.2205,
    '946_581': 35.2706,
    '946_582': 35.3174,
    '946_583': 35.3604,
    '946_584': 35.3999,
    '946_585': 35.4366,
    '946_586': 35.4692,
    '946_587': 35.4925,
    '946_588': 35.514,
    '946_589': 35.5445,
    '946_590': 35.5988,
    '946_591': 35.6536,
    '946_592': 35.6985,
    '946_593': 35.7368,
    '946_594': 35.7698,
    '946_595': 35.7981,
    '946_596': 35.8198,
    '946_597': 35.8412,
    '946_598': 35.8615,
    '946_599': 35.8789,
    '946_600': 35.8896,
    '946_601': 35.9039,
    '946_602': 35.9244,
    '946_603': 35.9532,
    '946_604': 35.9834,
    '946_605': 36.0154,
    '946_606': 36.0453,
    '946_607': 36.067,
    '946_608': 36.0831,
    '946_609': 36.0835,
    '946_610': 36.0553,
    '946_611': 36.0327,
    '946_612': 36.0135,
    '946_613': 36.0018,
    '946_614': 35.9955,
    '946_615': 35.9963,
    '946_616': 36.0065,
    '946_617': 36.0075,
    '946_618': 35.9956,
    '946_619': 35.9841,
    '946_620': 35.9869,
    '946_621': 36,
    '946_622': 36.0052,
    '946_623': 36.0078,
    '946_624': 36.0098,
    '946_625': 36.0134,
    '946_626': 36.0215,
    '946_627': 36.0376,
    '946_628': 36.0645,
    '946_629': 36.0976,
    '946_630': 36.1322,
    '946_631': 36.1655,
    '946_632': 36.1935,
    '946_633': 36.2139,
    '946_634': 36.2367,
    '946_635': 36.272,
    '946_636': 36.3183,
    '946_637': 36.373,
    '946_638': 36.4381,
    '946_639': 36.5159,
    '946_640': 36.6038,
    '946_641': 36.6992,
    '946_642': 36.8052,
    '946_643': 36.9339,
    '946_644': 37.0912,
    '946_645': 37.2627,
    '946_646': 37.3941,
    '946_647': 37.5108,
    '946_648': 37.6198,
    '946_649': 37.758,
    '946_650': 37.898,
    '946_651': 38.0333,
    '946_652': 38.1831,
    '946_653': 38.3311,
    '946_654': 38.4545,
    '946_655': 38.5548,
    '946_656': 38.6517,
    '946_657': 38.7513,
    '946_658': 38.8487,
    '946_659': 38.9458,
    '946_660': 39.0316,
    '946_661': 39.0939,
    '946_662': 39.1303,
    '946_663': 39.1527,
    '946_664': 39.1894,
    '946_665': 39.2334,
    '946_666': 39.2866,
    '946_667': 39.348,
    '946_668': 39.4027,
    '946_669': 39.4422,
    '946_670': 39.4889,
    '946_671': 39.5391,
    '946_672': 39.5889,
    '946_673': 39.6427,
    '946_674': 39.6896,
    '946_675': 39.7195,
    '946_676': 39.7313,
    '946_677': 39.7486,
    '946_678': 39.7902,
    '946_679': 39.8602,
    '946_680': 39.9422,
    '946_681': 40.0295,
    '946_682': 40.1123,
    '946_683': 40.1825,
    '946_684': 40.2568,
    '946_685': 40.3362,
    '946_686': 40.417,
    '946_687': 40.5184,
    '946_688': 40.6246,
    '946_689': 40.7322,
    '946_690': 40.859,
    '946_691': 41.0017,
    '946_692': 41.1658,
    '946_693': 41.3555,
    '946_694': 41.5561,
    '946_695': 41.7352,
    '946_696': 41.9064,
    '946_697': 42.0547,
    '946_698': 42.1781,
    '946_699': 42.3091,
    '946_700': 42.4266,
    '946_701': 42.5184,
    '946_702': 42.6089,
    '946_703': 42.6792,
    '946_704': 42.739,
    '946_705': 42.8021,
    '946_706': 42.8703,
    '946_707': 42.948,
    '946_708': 43.0478,
    '946_709': 43.2006,
    '946_710': 43.3799,
    '946_711': 43.5395,
    '946_712': 43.6028,
    '946_713': 43.6738,
    '946_714': 43.6677,
    '946_715': 43.5704,
    '946_716': 43.4708,
    '946_717': 43.3902,
    '946_718': 43.3671,
    '946_719': 43.4149,
    '946_720': 43.5094,
    '946_721': 43.6186,
    '946_722': 43.7333,
    '946_723': 43.8495,
    '946_724': 43.9759,
    '946_725': 44.1038,
    '946_726': 44.2014,
    '946_727': 44.2741,
    '946_728': 44.2987,
    '946_729': 44.216,
    '946_730': 44.1576,
    '946_731': 44.0389,
    '946_732': 43.8803,
    '946_733': 43.7104,
    '946_734': 43.5579,
    '946_735': 43.4154,
    '946_736': 43.2912,
    '946_737': 43.2085,
    '946_738': 43.1746,
    '946_739': 43.1833,
    '946_740': 43.2159,
    '946_741': 43.2579,
    '946_742': 43.3064,
    '946_743': 43.3612,
    '946_744': 43.4002,
    '946_745': 43.4319,
    '946_746': 43.4381,
    '946_747': 43.4202,
    '946_748': 43.395,
    '946_749': 43.3779,
    '946_750': 43.3879,
    '946_751': 43.4103,
    '946_752': 43.4171,
    '946_753': 43.4064,
    '946_754': 43.356,
    '946_755': 43.2594,
    '946_756': 43.1308,
    '946_757': 42.9975,
    '946_758': 42.8583,
    '946_759': 42.7185,
    '946_760': 42.5786,
    '946_761': 42.4328,
    '946_762': 42.2796,
    '946_763': 42.1125,
    '946_764': 41.9374,
    '946_765': 41.7454,
    '946_766': 41.5405,
    '946_767': 41.3116,
    '946_768': 41.0732,
    '946_769': 40.8362,
    '946_770': 40.6052,
    '946_771': 40.3783,
    '946_772': 40.1508,
    '946_773': 39.9241,
    '946_774': 39.6946,
    '946_775': 39.4627,
    '946_776': 39.2353,
    '946_777': 39.0253,
    '946_778': 38.8356,
    '946_779': 38.6617,
    '946_780': 38.5004,
    '946_781': 38.3473,
    '946_782': 38.202,
    '946_783': 38.0662,
    '946_784': 37.94,
    '946_785': 37.8241,
    '946_786': 37.7185,
    '946_787': 37.6214,
    '946_788': 37.5323,
    '946_789': 37.4484,
    '946_790': 37.3674,
    '946_791': 37.2912,
    '946_792': 37.2185,
    '946_793': 37.148,
    '946_794': 37.0788,
    '946_795': 37.0101,
    '946_796': 36.9427,
    '946_797': 36.8752,
    '946_798': 36.8079,
    '946_799': 36.7405,
    '946_800': 36.6739,
    '946_801': 36.6073,
    '946_802': 36.5393,
    '946_803': 36.4702,
    '946_804': 36.4007,
    '946_805': 36.3308,
    '946_806': 36.2592,
    '946_807': 36.185,
    '946_808': 36.1074,
    '946_809': 36.0272,
    '946_810': 35.9437,
    '946_811': 35.8561,
    '946_812': 35.763,
    '946_813': 35.6604,
    '946_814': 35.5513,
    '946_815': 35.4345,
    '946_816': 35.307,
    '946_817': 35.1727,
    '946_818': 35.0317,
    '946_819': 34.8812,
    '946_820': 34.7214,
    '946_821': 34.5479,
    '946_822': 34.3632,
    '946_823': 34.1719,
    '946_824': 33.9762,
    '946_825': 33.7778,
    '946_826': 33.5792,
    '946_827': 33.3806,
    '946_828': 33.181,
    '946_829': 32.9716,
    '946_830': 32.7547,
    '946_831': 32.5298,
    '946_832': 32.297,
    '946_833': 32.0526,
    '946_834': 31.7947,
    '946_835': 31.5246,
    '946_836': 31.2424,
    '946_837': 30.9495,
    '946_838': 30.6457,
    '946_839': 30.3325,
    '946_840': 30.0166,
    '946_841': 29.6859,
    '946_842': 29.3645,
    '946_843': 29.0305,
    '946_844': 28.6815,
    '946_845': 28.3202,
    '946_846': 27.9473,
    '946_847': 27.5628,
    '946_848': 27.1818,
    '947_560': 34.5514,
    '947_561': 34.5776,
    '947_562': 34.5974,
    '947_563': 34.6138,
    '947_564': 34.6313,
    '947_565': 34.6535,
    '947_566': 34.6854,
    '947_567': 34.7172,
    '947_568': 34.7438,
    '947_569': 34.7678,
    '947_570': 34.7899,
    '947_571': 34.8106,
    '947_572': 34.8328,
    '947_573': 34.8605,
    '947_574': 34.8932,
    '947_575': 34.9402,
    '947_576': 34.9746,
    '947_577': 35.0092,
    '947_578': 35.0451,
    '947_579': 35.0853,
    '947_580': 35.1341,
    '947_581': 35.1846,
    '947_582': 35.2345,
    '947_583': 35.2804,
    '947_584': 35.3205,
    '947_585': 35.3551,
    '947_586': 35.3852,
    '947_587': 35.4085,
    '947_588': 35.4369,
    '947_589': 35.482,
    '947_590': 35.5419,
    '947_591': 35.5946,
    '947_592': 35.6403,
    '947_593': 35.6805,
    '947_594': 35.716,
    '947_595': 35.7445,
    '947_596': 35.7684,
    '947_597': 35.7902,
    '947_598': 35.8095,
    '947_599': 35.8204,
    '947_600': 35.8254,
    '947_601': 35.8383,
    '947_602': 35.855,
    '947_603': 35.8788,
    '947_604': 35.9061,
    '947_605': 35.934,
    '947_606': 35.9594,
    '947_607': 35.9834,
    '947_608': 35.9976,
    '947_609': 36.001,
    '947_610': 35.9891,
    '947_611': 35.9728,
    '947_612': 35.9614,
    '947_613': 35.9523,
    '947_614': 35.9453,
    '947_615': 35.9409,
    '947_616': 35.9383,
    '947_617': 35.9379,
    '947_618': 35.929,
    '947_619': 35.9257,
    '947_620': 35.9302,
    '947_621': 35.9384,
    '947_622': 35.9427,
    '947_623': 35.9451,
    '947_624': 35.9479,
    '947_625': 35.953,
    '947_626': 35.9635,
    '947_627': 35.9846,
    '947_628': 36.014,
    '947_629': 36.0476,
    '947_630': 36.0832,
    '947_631': 36.1185,
    '947_632': 36.1486,
    '947_633': 36.1673,
    '947_634': 36.1965,
    '947_635': 36.2381,
    '947_636': 36.2909,
    '947_637': 36.3535,
    '947_638': 36.4263,
    '947_639': 36.5098,
    '947_640': 36.603,
    '947_641': 36.7041,
    '947_642': 36.8163,
    '947_643': 36.9483,
    '947_644': 37.1071,
    '947_645': 37.2664,
    '947_646': 37.3946,
    '947_647': 37.4961,
    '947_648': 37.5998,
    '947_649': 37.7329,
    '947_650': 37.8721,
    '947_651': 38.0048,
    '947_652': 38.1511,
    '947_653': 38.3059,
    '947_654': 38.4424,
    '947_655': 38.5593,
    '947_656': 38.6694,
    '947_657': 38.7805,
    '947_658': 38.8847,
    '947_659': 38.9801,
    '947_660': 39.0666,
    '947_661': 39.1219,
    '947_662': 39.1591,
    '947_663': 39.1946,
    '947_664': 39.2429,
    '947_665': 39.2941,
    '947_666': 39.3496,
    '947_667': 39.408,
    '947_668': 39.4675,
    '947_669': 39.522,
    '947_670': 39.5768,
    '947_671': 39.6268,
    '947_672': 39.6704,
    '947_673': 39.7113,
    '947_674': 39.7458,
    '947_675': 39.7675,
    '947_676': 39.7758,
    '947_677': 39.7984,
    '947_678': 39.8514,
    '947_679': 39.9192,
    '947_680': 39.9962,
    '947_681': 40.0848,
    '947_682': 40.1732,
    '947_683': 40.2457,
    '947_684': 40.3195,
    '947_685': 40.3906,
    '947_686': 40.4649,
    '947_687': 40.5618,
    '947_688': 40.6722,
    '947_689': 40.7795,
    '947_690': 40.8985,
    '947_691': 41.0495,
    '947_692': 41.2315,
    '947_693': 41.4331,
    '947_694': 41.6344,
    '947_695': 41.8196,
    '947_696': 41.9593,
    '947_697': 42.1138,
    '947_698': 42.232,
    '947_699': 42.3435,
    '947_700': 42.4533,
    '947_701': 42.5385,
    '947_702': 42.6155,
    '947_703': 42.6807,
    '947_704': 42.741,
    '947_705': 42.8024,
    '947_706': 42.8667,
    '947_707': 42.9427,
    '947_708': 43.0439,
    '947_709': 43.194,
    '947_710': 43.3745,
    '947_711': 43.5139,
    '947_712': 43.5675,
    '947_713': 43.626,
    '947_714': 43.638,
    '947_715': 43.5558,
    '947_716': 43.4675,
    '947_717': 43.3784,
    '947_718': 43.3431,
    '947_719': 43.3834,
    '947_720': 43.4657,
    '947_721': 43.5668,
    '947_722': 43.6736,
    '947_723': 43.7867,
    '947_724': 43.914,
    '947_725': 44.0363,
    '947_726': 44.1406,
    '947_727': 44.188,
    '947_728': 44.188,
    '947_729': 44.16,
    '947_730': 44.0941,
    '947_731': 43.9594,
    '947_732': 43.7971,
    '947_733': 43.6386,
    '947_734': 43.5058,
    '947_735': 43.3905,
    '947_736': 43.3021,
    '947_737': 43.2493,
    '947_738': 43.241,
    '947_739': 43.2729,
    '947_740': 43.3256,
    '947_741': 43.3787,
    '947_742': 43.4304,
    '947_743': 43.4831,
    '947_744': 43.5263,
    '947_745': 43.5626,
    '947_746': 43.5793,
    '947_747': 43.5683,
    '947_748': 43.544,
    '947_749': 43.5158,
    '947_750': 43.5072,
    '947_751': 43.5049,
    '947_752': 43.497,
    '947_753': 43.4675,
    '947_754': 43.4042,
    '947_755': 43.3079,
    '947_756': 43.1865,
    '947_757': 43.0593,
    '947_758': 42.9246,
    '947_759': 42.7876,
    '947_760': 42.6437,
    '947_761': 42.4958,
    '947_762': 42.337,
    '947_763': 42.17,
    '947_764': 41.9884,
    '947_765': 41.7974,
    '947_766': 41.5928,
    '947_767': 41.3673,
    '947_768': 41.1336,
    '947_769': 40.8963,
    '947_770': 40.6701,
    '947_771': 40.4462,
    '947_772': 40.2229,
    '947_773': 39.9989,
    '947_774': 39.7699,
    '947_775': 39.5422,
    '947_776': 39.3253,
    '947_777': 39.1256,
    '947_778': 38.9445,
    '947_779': 38.7778,
    '947_780': 38.6216,
    '947_781': 38.472,
    '947_782': 38.3298,
    '947_783': 38.1958,
    '947_784': 38.0704,
    '947_785': 37.9536,
    '947_786': 37.8471,
    '947_787': 37.7506,
    '947_788': 37.662,
    '947_789': 37.5793,
    '947_790': 37.5015,
    '947_791': 37.429,
    '947_792': 37.3615,
    '947_793': 37.2976,
    '947_794': 37.2357,
    '947_795': 37.1738,
    '947_796': 37.113,
    '947_797': 37.0522,
    '947_798': 36.9901,
    '947_799': 36.9271,
    '947_800': 36.8629,
    '947_801': 36.7976,
    '947_802': 36.7302,
    '947_803': 36.6618,
    '947_804': 36.5924,
    '947_805': 36.5218,
    '947_806': 36.4494,
    '947_807': 36.3745,
    '947_808': 36.2962,
    '947_809': 36.214,
    '947_810': 36.1297,
    '947_811': 36.0424,
    '947_812': 35.9491,
    '947_813': 35.847,
    '947_814': 35.7387,
    '947_815': 35.6217,
    '947_816': 35.4948,
    '947_817': 35.3585,
    '947_818': 35.2145,
    '947_819': 35.0616,
    '947_820': 34.8995,
    '947_821': 34.7247,
    '947_822': 34.5387,
    '947_823': 34.3448,
    '947_824': 34.1463,
    '947_825': 33.9443,
    '947_826': 33.7411,
    '947_827': 33.5354,
    '947_828': 33.3291,
    '947_829': 33.113,
    '947_830': 32.8892,
    '947_831': 32.6598,
    '947_832': 32.4196,
    '947_833': 32.1703,
    '947_834': 31.9106,
    '947_835': 31.6404,
    '947_836': 31.3597,
    '947_837': 31.0691,
    '947_838': 30.7668,
    '947_839': 30.4487,
    '947_840': 30.1331,
    '947_841': 29.7978,
    '947_842': 29.4692,
    '947_843': 29.128,
    '947_844': 28.7771,
    '947_845': 28.4168,
    '947_846': 28.0428,
    '947_847': 27.6565,
    '947_848': 27.271,
    '948_560': 34.5068,
    '948_561': 34.5302,
    '948_562': 34.5466,
    '948_563': 34.5616,
    '948_564': 34.5784,
    '948_565': 34.6002,
    '948_566': 34.6314,
    '948_567': 34.6594,
    '948_568': 34.6837,
    '948_569': 34.7065,
    '948_570': 34.7288,
    '948_571': 34.7508,
    '948_572': 34.7732,
    '948_573': 34.7992,
    '948_574': 34.8291,
    '948_575': 34.8739,
    '948_576': 34.906,
    '948_577': 34.9385,
    '948_578': 34.9725,
    '948_579': 35.0103,
    '948_580': 35.0564,
    '948_581': 35.106,
    '948_582': 35.158,
    '948_583': 35.2058,
    '948_584': 35.2457,
    '948_585': 35.2752,
    '948_586': 35.2991,
    '948_587': 35.3266,
    '948_588': 35.3614,
    '948_589': 35.4189,
    '948_590': 35.4824,
    '948_591': 35.538,
    '948_592': 35.585,
    '948_593': 35.6263,
    '948_594': 35.6617,
    '948_595': 35.6912,
    '948_596': 35.7163,
    '948_597': 35.7384,
    '948_598': 35.7569,
    '948_599': 35.7687,
    '948_600': 35.7728,
    '948_601': 35.7805,
    '948_602': 35.7895,
    '948_603': 35.8122,
    '948_604': 35.8383,
    '948_605': 35.8639,
    '948_606': 35.8865,
    '948_607': 35.906,
    '948_608': 35.9238,
    '948_609': 35.9299,
    '948_610': 35.9242,
    '948_611': 35.9183,
    '948_612': 35.91,
    '948_613': 35.8992,
    '948_614': 35.8952,
    '948_615': 35.8896,
    '948_616': 35.8816,
    '948_617': 35.8801,
    '948_618': 35.8707,
    '948_619': 35.8702,
    '948_620': 35.8743,
    '948_621': 35.8792,
    '948_622': 35.8827,
    '948_623': 35.8856,
    '948_624': 35.8897,
    '948_625': 35.8972,
    '948_626': 35.9116,
    '948_627': 35.9344,
    '948_628': 35.964,
    '948_629': 35.998,
    '948_630': 36.0334,
    '948_631': 36.0672,
    '948_632': 36.0947,
    '948_633': 36.1194,
    '948_634': 36.1548,
    '948_635': 36.2037,
    '948_636': 36.2635,
    '948_637': 36.3346,
    '948_638': 36.4167,
    '948_639': 36.5085,
    '948_640': 36.6079,
    '948_641': 36.7148,
    '948_642': 36.8325,
    '948_643': 36.9666,
    '948_644': 37.1219,
    '948_645': 37.2657,
    '948_646': 37.3865,
    '948_647': 37.4802,
    '948_648': 37.5829,
    '948_649': 37.7104,
    '948_650': 37.8497,
    '948_651': 37.9877,
    '948_652': 38.1241,
    '948_653': 38.2753,
    '948_654': 38.4216,
    '948_655': 38.5504,
    '948_656': 38.6715,
    '948_657': 38.7927,
    '948_658': 38.9004,
    '948_659': 39.0003,
    '948_660': 39.0801,
    '948_661': 39.136,
    '948_662': 39.1762,
    '948_663': 39.2239,
    '948_664': 39.2851,
    '948_665': 39.344,
    '948_666': 39.401,
    '948_667': 39.4626,
    '948_668': 39.5272,
    '948_669': 39.5862,
    '948_670': 39.6436,
    '948_671': 39.6947,
    '948_672': 39.7419,
    '948_673': 39.7755,
    '948_674': 39.8009,
    '948_675': 39.8156,
    '948_676': 39.8228,
    '948_677': 39.8595,
    '948_678': 39.9194,
    '948_679': 39.9875,
    '948_680': 40.0633,
    '948_681': 40.1453,
    '948_682': 40.2353,
    '948_683': 40.3117,
    '948_684': 40.3826,
    '948_685': 40.4485,
    '948_686': 40.5194,
    '948_687': 40.6098,
    '948_688': 40.7155,
    '948_689': 40.8227,
    '948_690': 40.9428,
    '948_691': 41.1114,
    '948_692': 41.3075,
    '948_693': 41.5025,
    '948_694': 41.6941,
    '948_695': 41.8707,
    '948_696': 42.0182,
    '948_697': 42.159,
    '948_698': 42.2835,
    '948_699': 42.3864,
    '948_700': 42.4814,
    '948_701': 42.5597,
    '948_702': 42.6292,
    '948_703': 42.6894,
    '948_704': 42.7448,
    '948_705': 42.8041,
    '948_706': 42.8666,
    '948_707': 42.9423,
    '948_708': 43.0423,
    '948_709': 43.183,
    '948_710': 43.3452,
    '948_711': 43.4708,
    '948_712': 43.5619,
    '948_713': 43.5889,
    '948_714': 43.5879,
    '948_715': 43.5348,
    '948_716': 43.4496,
    '948_717': 43.354,
    '948_718': 43.312,
    '948_719': 43.3412,
    '948_720': 43.4138,
    '948_721': 43.5083,
    '948_722': 43.6131,
    '948_723': 43.7281,
    '948_724': 43.8593,
    '948_725': 43.9767,
    '948_726': 44.0912,
    '948_727': 44.1223,
    '948_728': 44.1489,
    '948_729': 44.0973,
    '948_730': 44.0073,
    '948_731': 43.8861,
    '948_732': 43.7337,
    '948_733': 43.593,
    '948_734': 43.4831,
    '948_735': 43.3948,
    '948_736': 43.334,
    '948_737': 43.3045,
    '948_738': 43.3145,
    '948_739': 43.3612,
    '948_740': 43.4225,
    '948_741': 43.4819,
    '948_742': 43.5381,
    '948_743': 43.6031,
    '948_744': 43.6529,
    '948_745': 43.6857,
    '948_746': 43.7167,
    '948_747': 43.7183,
    '948_748': 43.6874,
    '948_749': 43.6492,
    '948_750': 43.6227,
    '948_751': 43.5986,
    '948_752': 43.5772,
    '948_753': 43.5393,
    '948_754': 43.4643,
    '948_755': 43.3679,
    '948_756': 43.2579,
    '948_757': 43.1314,
    '948_758': 43.0092,
    '948_759': 42.8723,
    '948_760': 42.7349,
    '948_761': 42.5799,
    '948_762': 42.4109,
    '948_763': 42.2259,
    '948_764': 42.0326,
    '948_765': 41.838,
    '948_766': 41.637,
    '948_767': 41.4148,
    '948_768': 41.1899,
    '948_769': 40.9622,
    '948_770': 40.741,
    '948_771': 40.519,
    '948_772': 40.2996,
    '948_773': 40.077,
    '948_774': 39.8487,
    '948_775': 39.6251,
    '948_776': 39.4175,
    '948_777': 39.2291,
    '948_778': 39.0578,
    '948_779': 38.8989,
    '948_780': 38.7476,
    '948_781': 38.601,
    '948_782': 38.4606,
    '948_783': 38.3281,
    '948_784': 38.2031,
    '948_785': 38.0863,
    '948_786': 37.9802,
    '948_787': 37.8844,
    '948_788': 37.7967,
    '948_789': 37.7159,
    '948_790': 37.6418,
    '948_791': 37.5735,
    '948_792': 37.5111,
    '948_793': 37.454,
    '948_794': 37.3998,
    '948_795': 37.3449,
    '948_796': 37.291,
    '948_797': 37.2366,
    '948_798': 37.1808,
    '948_799': 37.122,
    '948_800': 37.06,
    '948_801': 36.9968,
    '948_802': 36.9305,
    '948_803': 36.8622,
    '948_804': 36.7926,
    '948_805': 36.7208,
    '948_806': 36.6469,
    '948_807': 36.5706,
    '948_808': 36.4911,
    '948_809': 36.4079,
    '948_810': 36.3219,
    '948_811': 36.233,
    '948_812': 36.1389,
    '948_813': 36.0368,
    '948_814': 35.9289,
    '948_815': 35.8099,
    '948_816': 35.6802,
    '948_817': 35.5404,
    '948_818': 35.3916,
    '948_819': 35.2344,
    '948_820': 35.0677,
    '948_821': 34.8926,
    '948_822': 34.7062,
    '948_823': 34.5116,
    '948_824': 34.311,
    '948_825': 34.1071,
    '948_826': 33.9013,
    '948_827': 33.6912,
    '948_828': 33.4752,
    '948_829': 33.2504,
    '948_830': 33.0211,
    '948_831': 32.7851,
    '948_832': 32.54,
    '948_833': 32.2866,
    '948_834': 32.0244,
    '948_835': 31.7536,
    '948_836': 31.4741,
    '948_837': 31.185,
    '948_838': 30.8822,
    '948_839': 30.5626,
    '948_840': 30.2464,
    '948_841': 29.9065,
    '948_842': 29.564,
    '948_843': 29.2232,
    '948_844': 28.8714,
    '948_845': 28.511,
    '948_846': 28.1352,
    '948_847': 27.7432,
    '948_848': 27.3651,
    '949_560': 34.4529,
    '949_561': 34.4765,
    '949_562': 34.4925,
    '949_563': 34.5077,
    '949_564': 34.5241,
    '949_565': 34.5464,
    '949_566': 34.5782,
    '949_567': 34.6017,
    '949_568': 34.623,
    '949_569': 34.6446,
    '949_570': 34.6669,
    '949_571': 34.6892,
    '949_572': 34.712,
    '949_573': 34.7368,
    '949_574': 34.7644,
    '949_575': 34.8083,
    '949_576': 34.8395,
    '949_577': 34.8714,
    '949_578': 34.9049,
    '949_579': 34.9419,
    '949_580': 34.9849,
    '949_581': 35.0321,
    '949_582': 35.0831,
    '949_583': 35.1349,
    '949_584': 35.1767,
    '949_585': 35.2026,
    '949_586': 35.226,
    '949_587': 35.2573,
    '949_588': 35.3014,
    '949_589': 35.3644,
    '949_590': 35.4306,
    '949_591': 35.4871,
    '949_592': 35.5342,
    '949_593': 35.5742,
    '949_594': 35.6084,
    '949_595': 35.6373,
    '949_596': 35.6621,
    '949_597': 35.6838,
    '949_598': 35.7014,
    '949_599': 35.7122,
    '949_600': 35.717,
    '949_601': 35.7237,
    '949_602': 35.735,
    '949_603': 35.7543,
    '949_604': 35.7771,
    '949_605': 35.8,
    '949_606': 35.8202,
    '949_607': 35.8378,
    '949_608': 35.8521,
    '949_609': 35.8612,
    '949_610': 35.8648,
    '949_611': 35.8649,
    '949_612': 35.8586,
    '949_613': 35.8423,
    '949_614': 35.8435,
    '949_615': 35.8354,
    '949_616': 35.8262,
    '949_617': 35.8228,
    '949_618': 35.8187,
    '949_619': 35.8185,
    '949_620': 35.8208,
    '949_621': 35.823,
    '949_622': 35.8252,
    '949_623': 35.8283,
    '949_624': 35.8336,
    '949_625': 35.8429,
    '949_626': 35.8597,
    '949_627': 35.8833,
    '949_628': 35.9122,
    '949_629': 35.9451,
    '949_630': 35.9789,
    '949_631': 36.0092,
    '949_632': 36.0377,
    '949_633': 36.0683,
    '949_634': 36.1088,
    '949_635': 36.1649,
    '949_636': 36.2332,
    '949_637': 36.3136,
    '949_638': 36.4071,
    '949_639': 36.5094,
    '949_640': 36.6162,
    '949_641': 36.7287,
    '949_642': 36.8508,
    '949_643': 36.9871,
    '949_644': 37.1294,
    '949_645': 37.2598,
    '949_646': 37.3663,
    '949_647': 37.4613,
    '949_648': 37.5626,
    '949_649': 37.6928,
    '949_650': 37.8442,
    '949_651': 37.9851,
    '949_652': 38.1126,
    '949_653': 38.2526,
    '949_654': 38.3951,
    '949_655': 38.529,
    '949_656': 38.6609,
    '949_657': 38.792,
    '949_658': 38.904,
    '949_659': 39.0024,
    '949_660': 39.0795,
    '949_661': 39.1387,
    '949_662': 39.1822,
    '949_663': 39.2437,
    '949_664': 39.3153,
    '949_665': 39.382,
    '949_666': 39.4385,
    '949_667': 39.4997,
    '949_668': 39.5677,
    '949_669': 39.6318,
    '949_670': 39.6967,
    '949_671': 39.7501,
    '949_672': 39.7969,
    '949_673': 39.8328,
    '949_674': 39.8515,
    '949_675': 39.8607,
    '949_676': 39.8748,
    '949_677': 39.9262,
    '949_678': 39.9917,
    '949_679': 40.0639,
    '949_680': 40.1332,
    '949_681': 40.206,
    '949_682': 40.2955,
    '949_683': 40.3737,
    '949_684': 40.4439,
    '949_685': 40.5068,
    '949_686': 40.5729,
    '949_687': 40.6565,
    '949_688': 40.7546,
    '949_689': 40.8627,
    '949_690': 40.9968,
    '949_691': 41.1759,
    '949_692': 41.3685,
    '949_693': 41.5615,
    '949_694': 41.7486,
    '949_695': 41.923,
    '949_696': 42.0811,
    '949_697': 42.2178,
    '949_698': 42.3304,
    '949_699': 42.4307,
    '949_700': 42.5158,
    '949_701': 42.5919,
    '949_702': 42.649,
    '949_703': 42.7009,
    '949_704': 42.7543,
    '949_705': 42.8059,
    '949_706': 42.869,
    '949_707': 42.9404,
    '949_708': 43.0317,
    '949_709': 43.1556,
    '949_710': 43.3069,
    '949_711': 43.4446,
    '949_712': 43.5533,
    '949_713': 43.5598,
    '949_714': 43.5795,
    '949_715': 43.5079,
    '949_716': 43.4064,
    '949_717': 43.3188,
    '949_718': 43.2755,
    '949_719': 43.2976,
    '949_720': 43.3604,
    '949_721': 43.4497,
    '949_722': 43.5536,
    '949_723': 43.6725,
    '949_724': 43.8086,
    '949_725': 43.93,
    '949_726': 44.0369,
    '949_727': 44.0612,
    '949_728': 44.083,
    '949_729': 44.0281,
    '949_730': 43.9381,
    '949_731': 43.8247,
    '949_732': 43.684,
    '949_733': 43.5683,
    '949_734': 43.4843,
    '949_735': 43.4207,
    '949_736': 43.3813,
    '949_737': 43.3691,
    '949_738': 43.3924,
    '949_739': 43.4418,
    '949_740': 43.5082,
    '949_741': 43.5799,
    '949_742': 43.6381,
    '949_743': 43.7039,
    '949_744': 43.772,
    '949_745': 43.8174,
    '949_746': 43.8465,
    '949_747': 43.8427,
    '949_748': 43.8234,
    '949_749': 43.7681,
    '949_750': 43.7309,
    '949_751': 43.6895,
    '949_752': 43.652,
    '949_753': 43.6026,
    '949_754': 43.5268,
    '949_755': 43.4358,
    '949_756': 43.3286,
    '949_757': 43.2203,
    '949_758': 43.0975,
    '949_759': 42.9608,
    '949_760': 42.8185,
    '949_761': 42.655,
    '949_762': 42.4781,
    '949_763': 42.2916,
    '949_764': 42.0942,
    '949_765': 41.8937,
    '949_766': 41.6862,
    '949_767': 41.4741,
    '949_768': 41.257,
    '949_769': 41.0312,
    '949_770': 40.8104,
    '949_771': 40.5933,
    '949_772': 40.3767,
    '949_773': 40.1562,
    '949_774': 39.9287,
    '949_775': 39.7086,
    '949_776': 39.5102,
    '949_777': 39.3315,
    '949_778': 39.1696,
    '949_779': 39.0176,
    '949_780': 38.8715,
    '949_781': 38.7291,
    '949_782': 38.5911,
    '949_783': 38.46,
    '949_784': 38.3363,
    '949_785': 38.2203,
    '949_786': 38.1154,
    '949_787': 38.0205,
    '949_788': 37.9341,
    '949_789': 37.8559,
    '949_790': 37.7856,
    '949_791': 37.7223,
    '949_792': 37.6655,
    '949_793': 37.615,
    '949_794': 37.5676,
    '949_795': 37.5195,
    '949_796': 37.4734,
    '949_797': 37.4264,
    '949_798': 37.3763,
    '949_799': 37.3214,
    '949_800': 37.2621,
    '949_801': 37.2007,
    '949_802': 37.1354,
    '949_803': 37.0674,
    '949_804': 36.9968,
    '949_805': 36.9241,
    '949_806': 36.8487,
    '949_807': 36.771,
    '949_808': 36.6895,
    '949_809': 36.6047,
    '949_810': 36.5174,
    '949_811': 36.4271,
    '949_812': 36.3307,
    '949_813': 36.2275,
    '949_814': 36.1181,
    '949_815': 35.9979,
    '949_816': 35.8642,
    '949_817': 35.7197,
    '949_818': 35.5659,
    '949_819': 35.4029,
    '949_820': 35.2308,
    '949_821': 35.0529,
    '949_822': 34.8668,
    '949_823': 34.6719,
    '949_824': 34.4704,
    '949_825': 34.2654,
    '949_826': 34.0566,
    '949_827': 33.8404,
    '949_828': 33.6178,
    '949_829': 33.3862,
    '949_830': 33.1504,
    '949_831': 32.9081,
    '949_832': 32.6578,
    '949_833': 32.4,
    '949_834': 32.1348,
    '949_835': 31.8626,
    '949_836': 31.5838,
    '949_837': 31.2979,
    '949_838': 30.9989,
    '949_839': 30.6774,
    '949_840': 30.3581,
    '949_841': 30.0153,
    '949_842': 29.6724,
    '949_843': 29.3256,
    '949_844': 28.9669,
    '949_845': 28.6068,
    '949_846': 28.2312,
    '949_847': 27.8395,
    '949_848': 27.4507,
    '950_560': 34.4032,
    '950_561': 34.4217,
    '950_562': 34.4405,
    '950_563': 34.4531,
    '950_564': 34.4672,
    '950_565': 34.4861,
    '950_566': 34.5145,
    '950_567': 34.5387,
    '950_568': 34.5596,
    '950_569': 34.5803,
    '950_570': 34.6031,
    '950_571': 34.6266,
    '950_572': 34.6501,
    '950_573': 34.6747,
    '950_574': 34.7018,
    '950_575': 34.7442,
    '950_576': 34.7747,
    '950_577': 34.8066,
    '950_578': 34.8403,
    '950_579': 34.8766,
    '950_580': 34.9167,
    '950_581': 34.961,
    '950_582': 35.0116,
    '950_583': 35.0661,
    '950_584': 35.106,
    '950_585': 35.1297,
    '950_586': 35.1543,
    '950_587': 35.1917,
    '950_588': 35.247,
    '950_589': 35.3158,
    '950_590': 35.3854,
    '950_591': 35.441,
    '950_592': 35.4865,
    '950_593': 35.5242,
    '950_594': 35.5559,
    '950_595': 35.5827,
    '950_596': 35.6059,
    '950_597': 35.6266,
    '950_598': 35.6441,
    '950_599': 35.6541,
    '950_600': 35.6576,
    '950_601': 35.6646,
    '950_602': 35.6786,
    '950_603': 35.698,
    '950_604': 35.7195,
    '950_605': 35.7403,
    '950_606': 35.7583,
    '950_607': 35.7746,
    '950_608': 35.7887,
    '950_609': 35.7993,
    '950_610': 35.8063,
    '950_611': 35.8093,
    '950_612': 35.8034,
    '950_613': 35.7927,
    '950_614': 35.7875,
    '950_615': 35.7787,
    '950_616': 35.7699,
    '950_617': 35.7685,
    '950_618': 35.7691,
    '950_619': 35.7682,
    '950_620': 35.7681,
    '950_621': 35.7687,
    '950_622': 35.7701,
    '950_623': 35.7735,
    '950_624': 35.7805,
    '950_625': 35.7935,
    '950_626': 35.8091,
    '950_627': 35.8315,
    '950_628': 35.8587,
    '950_629': 35.8894,
    '950_630': 35.9204,
    '950_631': 35.9487,
    '950_632': 35.9799,
    '950_633': 36.0155,
    '950_634': 36.0633,
    '950_635': 36.1259,
    '950_636': 36.2019,
    '950_637': 36.2913,
    '950_638': 36.3976,
    '950_639': 36.5119,
    '950_640': 36.6264,
    '950_641': 36.7453,
    '950_642': 36.8726,
    '950_643': 37.0131,
    '950_644': 37.1384,
    '950_645': 37.2505,
    '950_646': 37.3433,
    '950_647': 37.4364,
    '950_648': 37.5424,
    '950_649': 37.6776,
    '950_650': 37.8275,
    '950_651': 37.9675,
    '950_652': 38.0927,
    '950_653': 38.2256,
    '950_654': 38.3674,
    '950_655': 38.5057,
    '950_656': 38.6493,
    '950_657': 38.7874,
    '950_658': 38.9021,
    '950_659': 38.9976,
    '950_660': 39.0766,
    '950_661': 39.1314,
    '950_662': 39.1866,
    '950_663': 39.2616,
    '950_664': 39.3424,
    '950_665': 39.4115,
    '950_666': 39.47,
    '950_667': 39.5293,
    '950_668': 39.5969,
    '950_669': 39.6658,
    '950_670': 39.7306,
    '950_671': 39.7925,
    '950_672': 39.8448,
    '950_673': 39.8795,
    '950_674': 39.8944,
    '950_675': 39.9024,
    '950_676': 39.9352,
    '950_677': 39.998,
    '950_678': 40.0718,
    '950_679': 40.1423,
    '950_680': 40.2018,
    '950_681': 40.2643,
    '950_682': 40.3462,
    '950_683': 40.4265,
    '950_684': 40.4967,
    '950_685': 40.5586,
    '950_686': 40.6222,
    '950_687': 40.6998,
    '950_688': 40.7909,
    '950_689': 40.9001,
    '950_690': 41.0533,
    '950_691': 41.2309,
    '950_692': 41.4204,
    '950_693': 41.6041,
    '950_694': 41.7847,
    '950_695': 41.9592,
    '950_696': 42.1171,
    '950_697': 42.2524,
    '950_698': 42.3749,
    '950_699': 42.4835,
    '950_700': 42.5642,
    '950_701': 42.6341,
    '950_702': 42.6802,
    '950_703': 42.7232,
    '950_704': 42.7652,
    '950_705': 42.812,
    '950_706': 42.8711,
    '950_707': 42.9409,
    '950_708': 43.0254,
    '950_709': 43.1339,
    '950_710': 43.2655,
    '950_711': 43.4035,
    '950_712': 43.492,
    '950_713': 43.5297,
    '950_714': 43.5251,
    '950_715': 43.463,
    '950_716': 43.3667,
    '950_717': 43.282,
    '950_718': 43.242,
    '950_719': 43.2578,
    '950_720': 43.3141,
    '950_721': 43.3987,
    '950_722': 43.5038,
    '950_723': 43.6212,
    '950_724': 43.7566,
    '950_725': 43.8859,
    '950_726': 43.9801,
    '950_727': 44.0093,
    '950_728': 44.0149,
    '950_729': 43.9565,
    '950_730': 43.8592,
    '950_731': 43.766,
    '950_732': 43.65,
    '950_733': 43.5633,
    '950_734': 43.5048,
    '950_735': 43.4621,
    '950_736': 43.4394,
    '950_737': 43.4409,
    '950_738': 43.4717,
    '950_739': 43.5234,
    '950_740': 43.5863,
    '950_741': 43.6556,
    '950_742': 43.7205,
    '950_743': 43.7893,
    '950_744': 43.8683,
    '950_745': 43.9288,
    '950_746': 43.9603,
    '950_747': 43.9468,
    '950_748': 43.9233,
    '950_749': 43.8693,
    '950_750': 43.8242,
    '950_751': 43.7756,
    '950_752': 43.7212,
    '950_753': 43.6716,
    '950_754': 43.6002,
    '950_755': 43.5027,
    '950_756': 43.3904,
    '950_757': 43.2971,
    '950_758': 43.1644,
    '950_759': 43.0269,
    '950_760': 42.8808,
    '950_761': 42.7223,
    '950_762': 42.5463,
    '950_763': 42.358,
    '950_764': 42.169,
    '950_765': 41.9694,
    '950_766': 41.7558,
    '950_767': 41.5409,
    '950_768': 41.3202,
    '950_769': 41.0965,
    '950_770': 40.8782,
    '950_771': 40.6628,
    '950_772': 40.4494,
    '950_773': 40.2304,
    '950_774': 40.0058,
    '950_775': 39.7914,
    '950_776': 39.6016,
    '950_777': 39.4322,
    '950_778': 39.2779,
    '950_779': 39.1322,
    '950_780': 38.9916,
    '950_781': 38.8545,
    '950_782': 38.7203,
    '950_783': 38.5915,
    '950_784': 38.4701,
    '950_785': 38.3564,
    '950_786': 38.2519,
    '950_787': 38.1577,
    '950_788': 38.0737,
    '950_789': 37.9993,
    '950_790': 37.9336,
    '950_791': 37.8755,
    '950_792': 37.8237,
    '950_793': 37.7786,
    '950_794': 37.7366,
    '950_795': 37.6959,
    '950_796': 37.657,
    '950_797': 37.6164,
    '950_798': 37.5724,
    '950_799': 37.5218,
    '950_800': 37.466,
    '950_801': 37.4061,
    '950_802': 37.3419,
    '950_803': 37.2744,
    '950_804': 37.2035,
    '950_805': 37.1307,
    '950_806': 37.0545,
    '950_807': 36.9758,
    '950_808': 36.8927,
    '950_809': 36.8064,
    '950_810': 36.7168,
    '950_811': 36.6241,
    '950_812': 36.5242,
    '950_813': 36.4173,
    '950_814': 36.3042,
    '950_815': 36.1807,
    '950_816': 36.0446,
    '950_817': 35.8958,
    '950_818': 35.7362,
    '950_819': 35.5678,
    '950_820': 35.3906,
    '950_821': 35.2089,
    '950_822': 35.0213,
    '950_823': 34.8262,
    '950_824': 34.6241,
    '950_825': 34.4171,
    '950_826': 34.2046,
    '950_827': 33.9838,
    '950_828': 33.7554,
    '950_829': 33.5197,
    '950_830': 33.2784,
    '950_831': 33.0303,
    '950_832': 32.7738,
    '950_833': 32.5109,
    '950_834': 32.2419,
    '950_835': 31.9669,
    '950_836': 31.6872,
    '950_837': 31.4031,
    '950_838': 31.1026,
    '950_839': 30.7835,
    '950_840': 30.4667,
    '950_841': 30.1235,
    '950_842': 29.7765,
    '950_843': 29.4244,
    '950_844': 29.0646,
    '950_845': 28.7009,
    '950_846': 28.326,
    '950_847': 27.9358,
    '950_848': 27.542,
    '951_560': 34.3486,
    '951_561': 34.3651,
    '951_562': 34.3821,
    '951_563': 34.3953,
    '951_564': 34.4114,
    '951_565': 34.4314,
    '951_566': 34.4556,
    '951_567': 34.4776,
    '951_568': 34.4969,
    '951_569': 34.5158,
    '951_570': 34.5395,
    '951_571': 34.5647,
    '951_572': 34.5881,
    '951_573': 34.6121,
    '951_574': 34.6382,
    '951_575': 34.6791,
    '951_576': 34.7105,
    '951_577': 34.7436,
    '951_578': 34.7782,
    '951_579': 34.8142,
    '951_580': 34.8522,
    '951_581': 34.8939,
    '951_582': 34.9451,
    '951_583': 34.9965,
    '951_584': 35.029,
    '951_585': 35.0546,
    '951_586': 35.081,
    '951_587': 35.1252,
    '951_588': 35.1918,
    '951_589': 35.2668,
    '951_590': 35.3385,
    '951_591': 35.396,
    '951_592': 35.4403,
    '951_593': 35.4754,
    '951_594': 35.5037,
    '951_595': 35.527,
    '951_596': 35.5469,
    '951_597': 35.5649,
    '951_598': 35.5809,
    '951_599': 35.5924,
    '951_600': 35.5939,
    '951_601': 35.6036,
    '951_602': 35.6204,
    '951_603': 35.642,
    '951_604': 35.6646,
    '951_605': 35.6837,
    '951_606': 35.6998,
    '951_607': 35.7154,
    '951_608': 35.7303,
    '951_609': 35.7417,
    '951_610': 35.7486,
    '951_611': 35.7492,
    '951_612': 35.7489,
    '951_613': 35.7435,
    '951_614': 35.7362,
    '951_615': 35.7272,
    '951_616': 35.7205,
    '951_617': 35.7275,
    '951_618': 35.7267,
    '951_619': 35.721,
    '951_620': 35.7181,
    '951_621': 35.7174,
    '951_622': 35.7181,
    '951_623': 35.7209,
    '951_624': 35.7274,
    '951_625': 35.7417,
    '951_626': 35.7587,
    '951_627': 35.7797,
    '951_628': 35.8049,
    '951_629': 35.8335,
    '951_630': 35.8637,
    '951_631': 35.8906,
    '951_632': 35.9264,
    '951_633': 35.9626,
    '951_634': 36.0177,
    '951_635': 36.0886,
    '951_636': 36.1741,
    '951_637': 36.2736,
    '951_638': 36.3908,
    '951_639': 36.5143,
    '951_640': 36.6391,
    '951_641': 36.7663,
    '951_642': 36.8992,
    '951_643': 37.0297,
    '951_644': 37.1411,
    '951_645': 37.2384,
    '951_646': 37.3209,
    '951_647': 37.4129,
    '951_648': 37.535,
    '951_649': 37.667,
    '951_650': 37.8021,
    '951_651': 37.9387,
    '951_652': 38.0614,
    '951_653': 38.1918,
    '951_654': 38.3353,
    '951_655': 38.4815,
    '951_656': 38.6371,
    '951_657': 38.7819,
    '951_658': 38.8959,
    '951_659': 38.9848,
    '951_660': 39.0574,
    '951_661': 39.1219,
    '951_662': 39.1947,
    '951_663': 39.2804,
    '951_664': 39.3614,
    '951_665': 39.4345,
    '951_666': 39.5007,
    '951_667': 39.5603,
    '951_668': 39.6243,
    '951_669': 39.6925,
    '951_670': 39.7632,
    '951_671': 39.8316,
    '951_672': 39.8863,
    '951_673': 39.9205,
    '951_674': 39.9334,
    '951_675': 39.9466,
    '951_676': 39.9985,
    '951_677': 40.0748,
    '951_678': 40.1558,
    '951_679': 40.2224,
    '951_680': 40.2732,
    '951_681': 40.3241,
    '951_682': 40.3977,
    '951_683': 40.4746,
    '951_684': 40.5445,
    '951_685': 40.6051,
    '951_686': 40.6644,
    '951_687': 40.7373,
    '951_688': 40.8236,
    '951_689': 40.9373,
    '951_690': 41.1022,
    '951_691': 41.2786,
    '951_692': 41.4561,
    '951_693': 41.6278,
    '951_694': 41.8126,
    '951_695': 41.9842,
    '951_696': 42.1513,
    '951_697': 42.295,
    '951_698': 42.4233,
    '951_699': 42.5428,
    '951_700': 42.6154,
    '951_701': 42.6731,
    '951_702': 42.71,
    '951_703': 42.7424,
    '951_704': 42.7815,
    '951_705': 42.8261,
    '951_706': 42.8802,
    '951_707': 42.9423,
    '951_708': 43.0174,
    '951_709': 43.1068,
    '951_710': 43.2179,
    '951_711': 43.3357,
    '951_712': 43.4064,
    '951_713': 43.4761,
    '951_714': 43.465,
    '951_715': 43.4132,
    '951_716': 43.3249,
    '951_717': 43.2501,
    '951_718': 43.215,
    '951_719': 43.2257,
    '951_720': 43.2763,
    '951_721': 43.3608,
    '951_722': 43.4661,
    '951_723': 43.5822,
    '951_724': 43.7096,
    '951_725': 43.8366,
    '951_726': 43.9235,
    '951_727': 43.9477,
    '951_728': 43.932,
    '951_729': 43.8905,
    '951_730': 43.7994,
    '951_731': 43.7173,
    '951_732': 43.6327,
    '951_733': 43.5764,
    '951_734': 43.5406,
    '951_735': 43.5158,
    '951_736': 43.5061,
    '951_737': 43.5168,
    '951_738': 43.5522,
    '951_739': 43.5999,
    '951_740': 43.6568,
    '951_741': 43.7225,
    '951_742': 43.7928,
    '951_743': 43.8779,
    '951_744': 43.9493,
    '951_745': 44.008,
    '951_746': 44.0504,
    '951_747': 44.0384,
    '951_748': 44.0308,
    '951_749': 43.9638,
    '951_750': 43.9083,
    '951_751': 43.8529,
    '951_752': 43.7916,
    '951_753': 43.7266,
    '951_754': 43.646,
    '951_755': 43.5457,
    '951_756': 43.4405,
    '951_757': 43.3295,
    '951_758': 43.2029,
    '951_759': 43.0855,
    '951_760': 42.9381,
    '951_761': 42.7748,
    '951_762': 42.5991,
    '951_763': 42.4222,
    '951_764': 42.237,
    '951_765': 42.0365,
    '951_766': 41.8207,
    '951_767': 41.6001,
    '951_768': 41.3732,
    '951_769': 41.1577,
    '951_770': 40.9452,
    '951_771': 40.7334,
    '951_772': 40.5219,
    '951_773': 40.3031,
    '951_774': 40.0772,
    '951_775': 39.8694,
    '951_776': 39.6887,
    '951_777': 39.5285,
    '951_778': 39.3813,
    '951_779': 39.2419,
    '951_780': 39.1075,
    '951_781': 38.9759,
    '951_782': 38.8469,
    '951_783': 38.722,
    '951_784': 38.6034,
    '951_785': 38.492,
    '951_786': 38.3892,
    '951_787': 38.2959,
    '951_788': 38.2158,
    '951_789': 38.1462,
    '951_790': 38.0858,
    '951_791': 38.0324,
    '951_792': 37.986,
    '951_793': 37.945,
    '951_794': 37.9075,
    '951_795': 37.8729,
    '951_796': 37.8397,
    '951_797': 37.8051,
    '951_798': 37.7667,
    '951_799': 37.7207,
    '951_800': 37.6677,
    '951_801': 37.6093,
    '951_802': 37.5467,
    '951_803': 37.4806,
    '951_804': 37.4118,
    '951_805': 37.3396,
    '951_806': 37.2621,
    '951_807': 37.1826,
    '951_808': 37.0995,
    '951_809': 37.0119,
    '951_810': 36.9196,
    '951_811': 36.8225,
    '951_812': 36.7182,
    '951_813': 36.6068,
    '951_814': 36.4892,
    '951_815': 36.3625,
    '951_816': 36.2227,
    '951_817': 36.0678,
    '951_818': 35.9028,
    '951_819': 35.7288,
    '951_820': 35.5456,
    '951_821': 35.3599,
    '951_822': 35.1693,
    '951_823': 34.9734,
    '951_824': 34.7707,
    '951_825': 34.5608,
    '951_826': 34.343,
    '951_827': 34.1183,
    '951_828': 33.8869,
    '951_829': 33.6478,
    '951_830': 33.4005,
    '951_831': 33.1463,
    '951_832': 32.8858,
    '951_833': 32.6194,
    '951_834': 32.3471,
    '951_835': 32.0691,
    '951_836': 31.7873,
    '951_837': 31.5083,
    '951_838': 31.2087,
    '951_839': 30.8909,
    '951_840': 30.5705,
    '951_841': 30.227,
    '951_842': 29.8767,
    '951_843': 29.5222,
    '951_844': 29.1615,
    '951_845': 28.797,
    '951_846': 28.4229,
    '951_847': 28.0375,
    '951_848': 27.6472,
    '952_560': 34.2959,
    '952_561': 34.3104,
    '952_562': 34.3247,
    '952_563': 34.3371,
    '952_564': 34.3563,
    '952_565': 34.3785,
    '952_566': 34.401,
    '952_567': 34.4198,
    '952_568': 34.4364,
    '952_569': 34.452,
    '952_570': 34.4756,
    '952_571': 34.5025,
    '952_572': 34.5251,
    '952_573': 34.5488,
    '952_574': 34.5746,
    '952_575': 34.615,
    '952_576': 34.6483,
    '952_577': 34.6834,
    '952_578': 34.7193,
    '952_579': 34.7559,
    '952_580': 34.7939,
    '952_581': 34.835,
    '952_582': 34.8824,
    '952_583': 34.9328,
    '952_584': 34.9696,
    '952_585': 34.9924,
    '952_586': 35.0168,
    '952_587': 35.0711,
    '952_588': 35.1425,
    '952_589': 35.2211,
    '952_590': 35.296,
    '952_591': 35.3541,
    '952_592': 35.3959,
    '952_593': 35.4271,
    '952_594': 35.4513,
    '952_595': 35.4703,
    '952_596': 35.4858,
    '952_597': 35.4996,
    '952_598': 35.5114,
    '952_599': 35.5202,
    '952_600': 35.5263,
    '952_601': 35.54,
    '952_602': 35.5603,
    '952_603': 35.5858,
    '952_604': 35.6113,
    '952_605': 35.6289,
    '952_606': 35.6438,
    '952_607': 35.6603,
    '952_608': 35.6784,
    '952_609': 35.6928,
    '952_610': 35.7026,
    '952_611': 35.7047,
    '952_612': 35.7041,
    '952_613': 35.7001,
    '952_614': 35.6907,
    '952_615': 35.682,
    '952_616': 35.6763,
    '952_617': 35.678,
    '952_618': 35.6825,
    '952_619': 35.6739,
    '952_620': 35.6708,
    '952_621': 35.6698,
    '952_622': 35.6705,
    '952_623': 35.6734,
    '952_624': 35.68,
    '952_625': 35.6941,
    '952_626': 35.7107,
    '952_627': 35.7295,
    '952_628': 35.7517,
    '952_629': 35.7773,
    '952_630': 35.8059,
    '952_631': 35.8403,
    '952_632': 35.8728,
    '952_633': 35.9118,
    '952_634': 35.9756,
    '952_635': 36.0559,
    '952_636': 36.1521,
    '952_637': 36.265,
    '952_638': 36.3914,
    '952_639': 36.5224,
    '952_640': 36.657,
    '952_641': 36.797,
    '952_642': 36.9188,
    '952_643': 37.034,
    '952_644': 37.1345,
    '952_645': 37.2213,
    '952_646': 37.2978,
    '952_647': 37.3896,
    '952_648': 37.5116,
    '952_649': 37.6347,
    '952_650': 37.7631,
    '952_651': 37.9044,
    '952_652': 38.0328,
    '952_653': 38.1629,
    '952_654': 38.3037,
    '952_655': 38.4532,
    '952_656': 38.6177,
    '952_657': 38.7717,
    '952_658': 38.8867,
    '952_659': 38.9719,
    '952_660': 39.0373,
    '952_661': 39.1096,
    '952_662': 39.1997,
    '952_663': 39.294,
    '952_664': 39.3781,
    '952_665': 39.451,
    '952_666': 39.5205,
    '952_667': 39.588,
    '952_668': 39.654,
    '952_669': 39.7267,
    '952_670': 39.8033,
    '952_671': 39.8755,
    '952_672': 39.935,
    '952_673': 39.966,
    '952_674': 39.9735,
    '952_675': 39.9987,
    '952_676': 40.0656,
    '952_677': 40.1524,
    '952_678': 40.2387,
    '952_679': 40.3056,
    '952_680': 40.352,
    '952_681': 40.3954,
    '952_682': 40.4557,
    '952_683': 40.525,
    '952_684': 40.5932,
    '952_685': 40.6504,
    '952_686': 40.706,
    '952_687': 40.7712,
    '952_688': 40.8522,
    '952_689': 40.9712,
    '952_690': 41.1361,
    '952_691': 41.3024,
    '952_692': 41.4734,
    '952_693': 41.6406,
    '952_694': 41.8264,
    '952_695': 42.0018,
    '952_696': 42.1804,
    '952_697': 42.3331,
    '952_698': 42.4683,
    '952_699': 42.5795,
    '952_700': 42.6443,
    '952_701': 42.7033,
    '952_702': 42.7463,
    '952_703': 42.7731,
    '952_704': 42.8007,
    '952_705': 42.8424,
    '952_706': 42.8946,
    '952_707': 42.9526,
    '952_708': 43.0162,
    '952_709': 43.0902,
    '952_710': 43.1809,
    '952_711': 43.2816,
    '952_712': 43.3595,
    '952_713': 43.4216,
    '952_714': 43.4204,
    '952_715': 43.3782,
    '952_716': 43.3006,
    '952_717': 43.2303,
    '952_718': 43.1958,
    '952_719': 43.2028,
    '952_720': 43.2505,
    '952_721': 43.3373,
    '952_722': 43.4394,
    '952_723': 43.5457,
    '952_724': 43.6614,
    '952_725': 43.7849,
    '952_726': 43.8673,
    '952_727': 43.8787,
    '952_728': 43.8659,
    '952_729': 43.8185,
    '952_730': 43.7444,
    '952_731': 43.6812,
    '952_732': 43.6299,
    '952_733': 43.6047,
    '952_734': 43.5909,
    '952_735': 43.5826,
    '952_736': 43.5816,
    '952_737': 43.5968,
    '952_738': 43.6302,
    '952_739': 43.6729,
    '952_740': 43.7293,
    '952_741': 43.7903,
    '952_742': 43.8652,
    '952_743': 43.9442,
    '952_744': 44.0188,
    '952_745': 44.0434,
    '952_746': 44.0915,
    '952_747': 44.0879,
    '952_748': 44.0987,
    '952_749': 44.054,
    '952_750': 43.9877,
    '952_751': 43.9141,
    '952_752': 43.8309,
    '952_753': 43.7572,
    '952_754': 43.6671,
    '952_755': 43.5761,
    '952_756': 43.4805,
    '952_757': 43.358,
    '952_758': 43.2209,
    '952_759': 43.0991,
    '952_760': 42.9561,
    '952_761': 42.8032,
    '952_762': 42.6391,
    '952_763': 42.4636,
    '952_764': 42.2839,
    '952_765': 42.0902,
    '952_766': 41.8713,
    '952_767': 41.6494,
    '952_768': 41.4307,
    '952_769': 41.2186,
    '952_770': 41.0091,
    '952_771': 40.8028,
    '952_772': 40.5946,
    '952_773': 40.3747,
    '952_774': 40.1462,
    '952_775': 39.9439,
    '952_776': 39.7708,
    '952_777': 39.6175,
    '952_778': 39.4773,
    '952_779': 39.3443,
    '952_780': 39.2158,
    '952_781': 39.0905,
    '952_782': 38.9674,
    '952_783': 38.8473,
    '952_784': 38.7324,
    '952_785': 38.6239,
    '952_786': 38.5245,
    '952_787': 38.435,
    '952_788': 38.3579,
    '952_789': 38.2936,
    '952_790': 38.2392,
    '952_791': 38.1916,
    '952_792': 38.1504,
    '952_793': 38.1133,
    '952_794': 38.0792,
    '952_795': 38.0492,
    '952_796': 38.0218,
    '952_797': 37.9919,
    '952_798': 37.9572,
    '952_799': 37.9156,
    '952_800': 37.865,
    '952_801': 37.8095,
    '952_802': 37.7498,
    '952_803': 37.6864,
    '952_804': 37.619,
    '952_805': 37.5468,
    '952_806': 37.4705,
    '952_807': 37.3912,
    '952_808': 37.3078,
    '952_809': 37.2191,
    '952_810': 37.1235,
    '952_811': 37.0216,
    '952_812': 36.9124,
    '952_813': 36.7962,
    '952_814': 36.6727,
    '952_815': 36.5409,
    '952_816': 36.3965,
    '952_817': 36.237,
    '952_818': 36.0664,
    '952_819': 35.8873,
    '952_820': 35.699,
    '952_821': 35.5073,
    '952_822': 35.3119,
    '952_823': 35.1124,
    '952_824': 34.907,
    '952_825': 34.6935,
    '952_826': 34.473,
    '952_827': 34.2462,
    '952_828': 34.0124,
    '952_829': 33.7683,
    '952_830': 33.5168,
    '952_831': 33.2584,
    '952_832': 32.9953,
    '952_833': 32.7271,
    '952_834': 32.4535,
    '952_835': 32.1746,
    '952_836': 31.8925,
    '952_837': 31.6077,
    '952_838': 31.3067,
    '952_839': 30.9905,
    '952_840': 30.6679,
    '952_841': 30.3261,
    '952_842': 29.9731,
    '952_843': 29.619,
    '952_844': 29.2621,
    '952_845': 28.8997,
    '952_846': 28.5282,
    '952_847': 28.1413,
    '952_848': 27.7505,
    '953_560': 34.2437,
    '953_561': 34.2583,
    '953_562': 34.2703,
    '953_563': 34.2831,
    '953_564': 34.3027,
    '953_565': 34.3256,
    '953_566': 34.3477,
    '953_567': 34.3621,
    '953_568': 34.3757,
    '953_569': 34.3891,
    '953_570': 34.4084,
    '953_571': 34.4325,
    '953_572': 34.4576,
    '953_573': 34.4843,
    '953_574': 34.5128,
    '953_575': 34.5552,
    '953_576': 34.5897,
    '953_577': 34.626,
    '953_578': 34.6631,
    '953_579': 34.7006,
    '953_580': 34.7386,
    '953_581': 34.7781,
    '953_582': 34.8208,
    '953_583': 34.8722,
    '953_584': 34.9142,
    '953_585': 34.9432,
    '953_586': 34.9738,
    '953_587': 35.0252,
    '953_588': 35.0951,
    '953_589': 35.1744,
    '953_590': 35.2499,
    '953_591': 35.3108,
    '953_592': 35.3499,
    '953_593': 35.378,
    '953_594': 35.3985,
    '953_595': 35.4134,
    '953_596': 35.4244,
    '953_597': 35.4346,
    '953_598': 35.4443,
    '953_599': 35.455,
    '953_600': 35.463,
    '953_601': 35.4767,
    '953_602': 35.498,
    '953_603': 35.527,
    '953_604': 35.5543,
    '953_605': 35.5734,
    '953_606': 35.5898,
    '953_607': 35.61,
    '953_608': 35.6354,
    '953_609': 35.6547,
    '953_610': 35.6658,
    '953_611': 35.6656,
    '953_612': 35.6605,
    '953_613': 35.6508,
    '953_614': 35.6451,
    '953_615': 35.6375,
    '953_616': 35.6299,
    '953_617': 35.6338,
    '953_618': 35.6357,
    '953_619': 35.6311,
    '953_620': 35.6279,
    '953_621': 35.6264,
    '953_622': 35.6273,
    '953_623': 35.6311,
    '953_624': 35.6382,
    '953_625': 35.6505,
    '953_626': 35.6677,
    '953_627': 35.6833,
    '953_628': 35.7027,
    '953_629': 35.7261,
    '953_630': 35.7543,
    '953_631': 35.7892,
    '953_632': 35.8258,
    '953_633': 35.8734,
    '953_634': 35.941,
    '953_635': 36.0269,
    '953_636': 36.1312,
    '953_637': 36.2564,
    '953_638': 36.3935,
    '953_639': 36.5312,
    '953_640': 36.677,
    '953_641': 36.821,
    '953_642': 36.9273,
    '953_643': 37.0293,
    '953_644': 37.1243,
    '953_645': 37.2032,
    '953_646': 37.2773,
    '953_647': 37.3669,
    '953_648': 37.4767,
    '953_649': 37.5936,
    '953_650': 37.7251,
    '953_651': 37.8661,
    '953_652': 38.0029,
    '953_653': 38.1362,
    '953_654': 38.2709,
    '953_655': 38.424,
    '953_656': 38.5928,
    '953_657': 38.7431,
    '953_658': 38.8569,
    '953_659': 38.9417,
    '953_660': 39.0104,
    '953_661': 39.0894,
    '953_662': 39.1928,
    '953_663': 39.2978,
    '953_664': 39.3833,
    '953_665': 39.4558,
    '953_666': 39.5333,
    '953_667': 39.6097,
    '953_668': 39.6897,
    '953_669': 39.7734,
    '953_670': 39.8547,
    '953_671': 39.9292,
    '953_672': 39.9857,
    '953_673': 40.0161,
    '953_674': 40.027,
    '953_675': 40.0616,
    '953_676': 40.1348,
    '953_677': 40.2267,
    '953_678': 40.3171,
    '953_679': 40.3864,
    '953_680': 40.4359,
    '953_681': 40.4751,
    '953_682': 40.524,
    '953_683': 40.5848,
    '953_684': 40.6449,
    '953_685': 40.6974,
    '953_686': 40.7472,
    '953_687': 40.8049,
    '953_688': 40.879,
    '953_689': 40.9994,
    '953_690': 41.1623,
    '953_691': 41.3154,
    '953_692': 41.4778,
    '953_693': 41.6418,
    '953_694': 41.8199,
    '953_695': 42.0051,
    '953_696': 42.195,
    '953_697': 42.3546,
    '953_698': 42.4733,
    '953_699': 42.5566,
    '953_700': 42.6615,
    '953_701': 42.7402,
    '953_702': 42.7644,
    '953_703': 42.7827,
    '953_704': 42.819,
    '953_705': 42.8604,
    '953_706': 42.9108,
    '953_707': 42.9648,
    '953_708': 43.0197,
    '953_709': 43.0829,
    '953_710': 43.1556,
    '953_711': 43.2396,
    '953_712': 43.3192,
    '953_713': 43.3755,
    '953_714': 43.3815,
    '953_715': 43.36,
    '953_716': 43.2896,
    '953_717': 43.2216,
    '953_718': 43.1802,
    '953_719': 43.181,
    '953_720': 43.2286,
    '953_721': 43.3158,
    '953_722': 43.4161,
    '953_723': 43.5141,
    '953_724': 43.6177,
    '953_725': 43.7288,
    '953_726': 43.8049,
    '953_727': 43.8187,
    '953_728': 43.8007,
    '953_729': 43.7483,
    '953_730': 43.6924,
    '953_731': 43.6555,
    '953_732': 43.6433,
    '953_733': 43.6461,
    '953_734': 43.6543,
    '953_735': 43.6599,
    '953_736': 43.6642,
    '953_737': 43.6791,
    '953_738': 43.7075,
    '953_739': 43.7495,
    '953_740': 43.7934,
    '953_741': 43.8543,
    '953_742': 43.9182,
    '953_743': 43.9943,
    '953_744': 44.0521,
    '953_745': 44.1094,
    '953_746': 44.1426,
    '953_747': 44.1521,
    '953_748': 44.1516,
    '953_749': 44.1022,
    '953_750': 44.0334,
    '953_751': 43.95,
    '953_752': 43.8586,
    '953_753': 43.7895,
    '953_754': 43.6951,
    '953_755': 43.5724,
    '953_756': 43.4651,
    '953_757': 43.3448,
    '953_758': 43.2107,
    '953_759': 43.0841,
    '953_760': 42.9474,
    '953_761': 42.8055,
    '953_762': 42.6446,
    '953_763': 42.4869,
    '953_764': 42.3141,
    '953_765': 42.1251,
    '953_766': 41.9154,
    '953_767': 41.6975,
    '953_768': 41.4909,
    '953_769': 41.2831,
    '953_770': 41.0789,
    '953_771': 40.8746,
    '953_772': 40.6664,
    '953_773': 40.4434,
    '953_774': 40.2131,
    '953_775': 40.0152,
    '953_776': 39.848,
    '953_777': 39.7002,
    '953_778': 39.5657,
    '953_779': 39.4374,
    '953_780': 39.3143,
    '953_781': 39.1946,
    '953_782': 39.0771,
    '953_783': 38.9617,
    '953_784': 38.8512,
    '953_785': 38.7474,
    '953_786': 38.6527,
    '953_787': 38.5684,
    '953_788': 38.4962,
    '953_789': 38.4375,
    '953_790': 38.3895,
    '953_791': 38.3493,
    '953_792': 38.314,
    '953_793': 38.2813,
    '953_794': 38.2507,
    '953_795': 38.2241,
    '953_796': 38.2005,
    '953_797': 38.1749,
    '953_798': 38.1436,
    '953_799': 38.1047,
    '953_800': 38.0579,
    '953_801': 38.0055,
    '953_802': 37.949,
    '953_803': 37.888,
    '953_804': 37.8213,
    '953_805': 37.7511,
    '953_806': 37.6769,
    '953_807': 37.5984,
    '953_808': 37.515,
    '953_809': 37.4254,
    '953_810': 37.3263,
    '953_811': 37.2201,
    '953_812': 37.1062,
    '953_813': 36.9841,
    '953_814': 36.854,
    '953_815': 36.716,
    '953_816': 36.5666,
    '953_817': 36.4034,
    '953_818': 36.2283,
    '953_819': 36.0441,
    '953_820': 35.8506,
    '953_821': 35.6523,
    '953_822': 35.4514,
    '953_823': 35.2452,
    '953_824': 35.0363,
    '953_825': 34.8202,
    '953_826': 34.5972,
    '953_827': 34.3675,
    '953_828': 34.1292,
    '953_829': 33.8818,
    '953_830': 33.6272,
    '953_831': 33.367,
    '953_832': 33.1035,
    '953_833': 32.8352,
    '953_834': 32.5611,
    '953_835': 32.2811,
    '953_836': 31.9971,
    '953_837': 31.7106,
    '953_838': 31.4067,
    '953_839': 31.0876,
    '953_840': 30.7629,
    '953_841': 30.4227,
    '953_842': 30.0673,
    '953_843': 29.7171,
    '953_844': 29.365,
    '953_845': 29.0039,
    '953_846': 28.633,
    '953_847': 28.2476,
    '953_848': 27.8599,
    '954_560': 34.187,
    '954_561': 34.1991,
    '954_562': 34.2127,
    '954_563': 34.2261,
    '954_564': 34.2456,
    '954_565': 34.2693,
    '954_566': 34.2901,
    '954_567': 34.3016,
    '954_568': 34.3126,
    '954_569': 34.3228,
    '954_570': 34.3387,
    '954_571': 34.362,
    '954_572': 34.3893,
    '954_573': 34.4195,
    '954_574': 34.452,
    '954_575': 34.4971,
    '954_576': 34.5331,
    '954_577': 34.5706,
    '954_578': 34.6089,
    '954_579': 34.6475,
    '954_580': 34.6861,
    '954_581': 34.7255,
    '954_582': 34.7668,
    '954_583': 34.8108,
    '954_584': 34.8531,
    '954_585': 34.8912,
    '954_586': 34.9245,
    '954_587': 34.9689,
    '954_588': 35.0427,
    '954_589': 35.1238,
    '954_590': 35.1986,
    '954_591': 35.2564,
    '954_592': 35.2975,
    '954_593': 35.3268,
    '954_594': 35.3457,
    '954_595': 35.3577,
    '954_596': 35.3649,
    '954_597': 35.3723,
    '954_598': 35.3784,
    '954_599': 35.3886,
    '954_600': 35.3992,
    '954_601': 35.4137,
    '954_602': 35.4361,
    '954_603': 35.4704,
    '954_604': 35.4993,
    '954_605': 35.5203,
    '954_606': 35.5421,
    '954_607': 35.5727,
    '954_608': 35.6018,
    '954_609': 35.6213,
    '954_610': 35.6297,
    '954_611': 35.6285,
    '954_612': 35.6217,
    '954_613': 35.6168,
    '954_614': 35.6108,
    '954_615': 35.6029,
    '954_616': 35.5926,
    '954_617': 35.592,
    '954_618': 35.5943,
    '954_619': 35.5911,
    '954_620': 35.5866,
    '954_621': 35.5858,
    '954_622': 35.5883,
    '954_623': 35.5937,
    '954_624': 35.6021,
    '954_625': 35.6164,
    '954_626': 35.6336,
    '954_627': 35.6448,
    '954_628': 35.6596,
    '954_629': 35.6807,
    '954_630': 35.7085,
    '954_631': 35.7418,
    '954_632': 35.7817,
    '954_633': 35.8363,
    '954_634': 35.9088,
    '954_635': 36,
    '954_636': 36.1121,
    '954_637': 36.244,
    '954_638': 36.3864,
    '954_639': 36.5336,
    '954_640': 36.6896,
    '954_641': 36.8318,
    '954_642': 36.9257,
    '954_643': 37.0192,
    '954_644': 37.116,
    '954_645': 37.1902,
    '954_646': 37.2626,
    '954_647': 37.3531,
    '954_648': 37.4557,
    '954_649': 37.5683,
    '954_650': 37.6978,
    '954_651': 37.8359,
    '954_652': 37.9793,
    '954_653': 38.1192,
    '954_654': 38.2463,
    '954_655': 38.3983,
    '954_656': 38.556,
    '954_657': 38.7015,
    '954_658': 38.8159,
    '954_659': 38.8979,
    '954_660': 38.9686,
    '954_661': 39.0536,
    '954_662': 39.1685,
    '954_663': 39.2908,
    '954_664': 39.3914,
    '954_665': 39.4688,
    '954_666': 39.5454,
    '954_667': 39.6323,
    '954_668': 39.7283,
    '954_669': 39.8231,
    '954_670': 39.9135,
    '954_671': 39.9877,
    '954_672': 40.0423,
    '954_673': 40.0679,
    '954_674': 40.086,
    '954_675': 40.1309,
    '954_676': 40.2053,
    '954_677': 40.2978,
    '954_678': 40.3907,
    '954_679': 40.4646,
    '954_680': 40.518,
    '954_681': 40.5502,
    '954_682': 40.5923,
    '954_683': 40.6436,
    '954_684': 40.6945,
    '954_685': 40.7422,
    '954_686': 40.7867,
    '954_687': 40.841,
    '954_688': 40.9084,
    '954_689': 41.0209,
    '954_690': 41.1719,
    '954_691': 41.3236,
    '954_692': 41.4729,
    '954_693': 41.6335,
    '954_694': 41.8205,
    '954_695': 42.0149,
    '954_696': 42.1905,
    '954_697': 42.3606,
    '954_698': 42.4934,
    '954_699': 42.5488,
    '954_700': 42.6681,
    '954_701': 42.7516,
    '954_702': 42.7768,
    '954_703': 42.7984,
    '954_704': 42.8336,
    '954_705': 42.8755,
    '954_706': 42.9274,
    '954_707': 42.9776,
    '954_708': 43.029,
    '954_709': 43.0826,
    '954_710': 43.1378,
    '954_711': 43.2038,
    '954_712': 43.273,
    '954_713': 43.336,
    '954_714': 43.3428,
    '954_715': 43.3261,
    '954_716': 43.2865,
    '954_717': 43.2165,
    '954_718': 43.1656,
    '954_719': 43.1582,
    '954_720': 43.2035,
    '954_721': 43.2893,
    '954_722': 43.3837,
    '954_723': 43.4766,
    '954_724': 43.5717,
    '954_725': 43.6687,
    '954_726': 43.7357,
    '954_727': 43.7484,
    '954_728': 43.7329,
    '954_729': 43.6834,
    '954_730': 43.6508,
    '954_731': 43.6491,
    '954_732': 43.6711,
    '954_733': 43.7032,
    '954_734': 43.7301,
    '954_735': 43.7449,
    '954_736': 43.7501,
    '954_737': 43.7585,
    '954_738': 43.7813,
    '954_739': 43.8195,
    '954_740': 43.855,
    '954_741': 43.907,
    '954_742': 43.9653,
    '954_743': 44.0311,
    '954_744': 44.0725,
    '954_745': 44.1375,
    '954_746': 44.1883,
    '954_747': 44.1806,
    '954_748': 44.1558,
    '954_749': 44.1037,
    '954_750': 44.0322,
    '954_751': 43.9444,
    '954_752': 43.865,
    '954_753': 43.7774,
    '954_754': 43.6753,
    '954_755': 43.535,
    '954_756': 43.4244,
    '954_757': 43.2996,
    '954_758': 43.1698,
    '954_759': 43.0519,
    '954_760': 42.921,
    '954_761': 42.7745,
    '954_762': 42.6305,
    '954_763': 42.4784,
    '954_764': 42.3223,
    '954_765': 42.1497,
    '954_766': 41.9516,
    '954_767': 41.7449,
    '954_768': 41.5494,
    '954_769': 41.3481,
    '954_770': 41.1498,
    '954_771': 40.9513,
    '954_772': 40.7417,
    '954_773': 40.5109,
    '954_774': 40.2786,
    '954_775': 40.084,
    '954_776': 39.9217,
    '954_777': 39.7781,
    '954_778': 39.6462,
    '954_779': 39.5214,
    '954_780': 39.4021,
    '954_781': 39.2864,
    '954_782': 39.1731,
    '954_783': 39.0633,
    '954_784': 38.9584,
    '954_785': 38.861,
    '954_786': 38.7725,
    '954_787': 38.694,
    '954_788': 38.6278,
    '954_789': 38.5755,
    '954_790': 38.5346,
    '954_791': 38.5021,
    '954_792': 38.4733,
    '954_793': 38.4459,
    '954_794': 38.42,
    '954_795': 38.3966,
    '954_796': 38.3767,
    '954_797': 38.3549,
    '954_798': 38.3274,
    '954_799': 38.291,
    '954_800': 38.2474,
    '954_801': 38.1984,
    '954_802': 38.1452,
    '954_803': 38.0861,
    '954_804': 38.0204,
    '954_805': 37.9532,
    '954_806': 37.8811,
    '954_807': 37.8033,
    '954_808': 37.7191,
    '954_809': 37.6272,
    '954_810': 37.5264,
    '954_811': 37.4172,
    '954_812': 37.2992,
    '954_813': 37.1717,
    '954_814': 37.0354,
    '954_815': 36.8901,
    '954_816': 36.7347,
    '954_817': 36.5677,
    '954_818': 36.3893,
    '954_819': 36.1989,
    '954_820': 35.9999,
    '954_821': 35.796,
    '954_822': 35.591,
    '954_823': 35.3814,
    '954_824': 35.1676,
    '954_825': 34.9475,
    '954_826': 34.7204,
    '954_827': 34.4865,
    '954_828': 34.2446,
    '954_829': 33.9947,
    '954_830': 33.7381,
    '954_831': 33.4759,
    '954_832': 33.2134,
    '954_833': 32.9461,
    '954_834': 32.6724,
    '954_835': 32.3915,
    '954_836': 32.1044,
    '954_837': 31.8186,
    '954_838': 31.5169,
    '954_839': 31.2018,
    '954_840': 30.8744,
    '954_841': 30.5295,
    '954_842': 30.1751,
    '954_843': 29.8247,
    '954_844': 29.4736,
    '954_845': 29.113,
    '954_846': 28.7463,
    '954_847': 28.3678,
    '954_848': 27.9868,
    '955_560': 34.1371,
    '955_561': 34.1466,
    '955_562': 34.1612,
    '955_563': 34.1774,
    '955_564': 34.1975,
    '955_565': 34.2167,
    '955_566': 34.233,
    '955_567': 34.2473,
    '955_568': 34.254,
    '955_569': 34.2582,
    '955_570': 34.2703,
    '955_571': 34.2935,
    '955_572': 34.3227,
    '955_573': 34.356,
    '955_574': 34.3918,
    '955_575': 34.4404,
    '955_576': 34.4786,
    '955_577': 34.5179,
    '955_578': 34.5576,
    '955_579': 34.5971,
    '955_580': 34.6357,
    '955_581': 34.6733,
    '955_582': 34.7091,
    '955_583': 34.7474,
    '955_584': 34.7881,
    '955_585': 34.8315,
    '955_586': 34.877,
    '955_587': 34.93,
    '955_588': 34.9995,
    '955_589': 35.0765,
    '955_590': 35.1456,
    '955_591': 35.2017,
    '955_592': 35.2449,
    '955_593': 35.2768,
    '955_594': 35.2957,
    '955_595': 35.3076,
    '955_596': 35.3148,
    '955_597': 35.3203,
    '955_598': 35.3254,
    '955_599': 35.3326,
    '955_600': 35.3434,
    '955_601': 35.3604,
    '955_602': 35.3886,
    '955_603': 35.421,
    '955_604': 35.439,
    '955_605': 35.471,
    '955_606': 35.5053,
    '955_607': 35.5425,
    '955_608': 35.5711,
    '955_609': 35.5896,
    '955_610': 35.5987,
    '955_611': 35.6004,
    '955_612': 35.5986,
    '955_613': 35.5952,
    '955_614': 35.5844,
    '955_615': 35.5735,
    '955_616': 35.5661,
    '955_617': 35.5665,
    '955_618': 35.5673,
    '955_619': 35.5606,
    '955_620': 35.552,
    '955_621': 35.5518,
    '955_622': 35.5555,
    '955_623': 35.5621,
    '955_624': 35.5706,
    '955_625': 35.5852,
    '955_626': 35.5982,
    '955_627': 35.6084,
    '955_628': 35.6209,
    '955_629': 35.6414,
    '955_630': 35.6694,
    '955_631': 35.7029,
    '955_632': 35.7436,
    '955_633': 35.8032,
    '955_634': 35.8805,
    '955_635': 35.9768,
    '955_636': 36.0972,
    '955_637': 36.2342,
    '955_638': 36.3805,
    '955_639': 36.533,
    '955_640': 36.6921,
    '955_641': 36.8322,
    '955_642': 36.9201,
    '955_643': 37.0074,
    '955_644': 37.0998,
    '955_645': 37.175,
    '955_646': 37.2491,
    '955_647': 37.3418,
    '955_648': 37.4452,
    '955_649': 37.559,
    '955_650': 37.6794,
    '955_651': 37.814,
    '955_652': 37.9614,
    '955_653': 38.1,
    '955_654': 38.2261,
    '955_655': 38.3702,
    '955_656': 38.5086,
    '955_657': 38.6424,
    '955_658': 38.7489,
    '955_659': 38.8318,
    '955_660': 38.9111,
    '955_661': 39.0066,
    '955_662': 39.1334,
    '955_663': 39.2777,
    '955_664': 39.4001,
    '955_665': 39.4891,
    '955_666': 39.5724,
    '955_667': 39.6682,
    '955_668': 39.7713,
    '955_669': 39.8768,
    '955_670': 39.968,
    '955_671': 40.0426,
    '955_672': 40.0931,
    '955_673': 40.1211,
    '955_674': 40.1464,
    '955_675': 40.1973,
    '955_676': 40.2713,
    '955_677': 40.3642,
    '955_678': 40.4569,
    '955_679': 40.528,
    '955_680': 40.5871,
    '955_681': 40.6165,
    '955_682': 40.6546,
    '955_683': 40.6964,
    '955_684': 40.7411,
    '955_685': 40.7872,
    '955_686': 40.8291,
    '955_687': 40.8812,
    '955_688': 40.9419,
    '955_689': 41.0416,
    '955_690': 41.1773,
    '955_691': 41.322,
    '955_692': 41.4712,
    '955_693': 41.6316,
    '955_694': 41.816,
    '955_695': 42.0087,
    '955_696': 42.1859,
    '955_697': 42.3601,
    '955_698': 42.5104,
    '955_699': 42.5861,
    '955_700': 42.6711,
    '955_701': 42.7544,
    '955_702': 42.7819,
    '955_703': 42.8071,
    '955_704': 42.8461,
    '955_705': 42.8928,
    '955_706': 42.9453,
    '955_707': 42.9968,
    '955_708': 43.0444,
    '955_709': 43.0859,
    '955_710': 43.1273,
    '955_711': 43.1791,
    '955_712': 43.2369,
    '955_713': 43.2984,
    '955_714': 43.304,
    '955_715': 43.2956,
    '955_716': 43.272,
    '955_717': 43.2117,
    '955_718': 43.1546,
    '955_719': 43.1371,
    '955_720': 43.1763,
    '955_721': 43.26,
    '955_722': 43.348,
    '955_723': 43.4355,
    '955_724': 43.5247,
    '955_725': 43.6052,
    '955_726': 43.6667,
    '955_727': 43.6758,
    '955_728': 43.6585,
    '955_729': 43.6282,
    '955_730': 43.6268,
    '955_731': 43.6573,
    '955_732': 43.7085,
    '955_733': 43.7657,
    '955_734': 43.8053,
    '955_735': 43.8301,
    '955_736': 43.8362,
    '955_737': 43.8369,
    '955_738': 43.8484,
    '955_739': 43.8692,
    '955_740': 43.9023,
    '955_741': 43.9393,
    '955_742': 43.9932,
    '955_743': 44.0463,
    '955_744': 44.0865,
    '955_745': 44.141,
    '955_746': 44.1885,
    '955_747': 44.1837,
    '955_748': 44.151,
    '955_749': 44.0998,
    '955_750': 44.056,
    '955_751': 43.9749,
    '955_752': 43.8561,
    '955_753': 43.7475,
    '955_754': 43.6281,
    '955_755': 43.5,
    '955_756': 43.3811,
    '955_757': 43.2391,
    '955_758': 43.1044,
    '955_759': 42.9824,
    '955_760': 42.8546,
    '955_761': 42.729,
    '955_762': 42.5931,
    '955_763': 42.4565,
    '955_764': 42.3232,
    '955_765': 42.1706,
    '955_766': 41.9892,
    '955_767': 41.7948,
    '955_768': 41.6005,
    '955_769': 41.4113,
    '955_770': 41.2265,
    '955_771': 41.0267,
    '955_772': 40.8098,
    '955_773': 40.5771,
    '955_774': 40.3449,
    '955_775': 40.1549,
    '955_776': 39.996,
    '955_777': 39.8543,
    '955_778': 39.7236,
    '955_779': 39.6013,
    '955_780': 39.4846,
    '955_781': 39.372,
    '955_782': 39.263,
    '955_783': 39.159,
    '955_784': 39.0607,
    '955_785': 38.9698,
    '955_786': 38.8874,
    '955_787': 38.8146,
    '955_788': 38.7542,
    '955_789': 38.7076,
    '955_790': 38.6723,
    '955_791': 38.646,
    '955_792': 38.6245,
    '955_793': 38.6047,
    '955_794': 38.5853,
    '955_795': 38.5668,
    '955_796': 38.5509,
    '955_797': 38.5336,
    '955_798': 38.5111,
    '955_799': 38.4779,
    '955_800': 38.4378,
    '955_801': 38.392,
    '955_802': 38.3416,
    '955_803': 38.2853,
    '955_804': 38.2237,
    '955_805': 38.1582,
    '955_806': 38.0869,
    '955_807': 38.0092,
    '955_808': 37.9238,
    '955_809': 37.8298,
    '955_810': 37.7269,
    '955_811': 37.615,
    '955_812': 37.4933,
    '955_813': 37.3612,
    '955_814': 37.2192,
    '955_815': 37.0666,
    '955_816': 36.9048,
    '955_817': 36.734,
    '955_818': 36.5512,
    '955_819': 36.3573,
    '955_820': 36.1553,
    '955_821': 35.9479,
    '955_822': 35.7378,
    '955_823': 35.5242,
    '955_824': 35.3057,
    '955_825': 35.0813,
    '955_826': 34.8503,
    '955_827': 34.6117,
    '955_828': 34.3659,
    '955_829': 34.1145,
    '955_830': 33.8573,
    '955_831': 33.5953,
    '955_832': 33.3316,
    '955_833': 33.0645,
    '955_834': 32.7933,
    '955_835': 32.5168,
    '955_836': 32.2355,
    '955_837': 31.9496,
    '955_838': 31.6451,
    '955_839': 31.3271,
    '955_840': 30.9889,
    '955_841': 30.6398,
    '955_842': 30.2915,
    '955_843': 29.939,
    '955_844': 29.5894,
    '955_845': 29.2285,
    '955_846': 28.8657,
    '955_847': 28.494,
    '955_848': 28.1216,
    '956_560': 34.0873,
    '956_561': 34.0974,
    '956_562': 34.1126,
    '956_563': 34.1296,
    '956_564': 34.1508,
    '956_565': 34.1664,
    '956_566': 34.1793,
    '956_567': 34.1892,
    '956_568': 34.1945,
    '956_569': 34.1986,
    '956_570': 34.2091,
    '956_571': 34.2291,
    '956_572': 34.258,
    '956_573': 34.2935,
    '956_574': 34.333,
    '956_575': 34.3834,
    '956_576': 34.4242,
    '956_577': 34.4658,
    '956_578': 34.5072,
    '956_579': 34.5473,
    '956_580': 34.5851,
    '956_581': 34.6194,
    '956_582': 34.6492,
    '956_583': 34.6821,
    '956_584': 34.7197,
    '956_585': 34.7662,
    '956_586': 34.8213,
    '956_587': 34.8855,
    '956_588': 34.9568,
    '956_589': 35.0309,
    '956_590': 35.0939,
    '956_591': 35.1479,
    '956_592': 35.1916,
    '956_593': 35.2239,
    '956_594': 35.2451,
    '956_595': 35.2591,
    '956_596': 35.2679,
    '956_597': 35.2721,
    '956_598': 35.2754,
    '956_599': 35.2815,
    '956_600': 35.2939,
    '956_601': 35.3158,
    '956_602': 35.3448,
    '956_603': 35.374,
    '956_604': 35.4002,
    '956_605': 35.4322,
    '956_606': 35.4685,
    '956_607': 35.5066,
    '956_608': 35.538,
    '956_609': 35.5569,
    '956_610': 35.5673,
    '956_611': 35.5724,
    '956_612': 35.5767,
    '956_613': 35.5777,
    '956_614': 35.5697,
    '956_615': 35.5594,
    '956_616': 35.5511,
    '956_617': 35.5459,
    '956_618': 35.5488,
    '956_619': 35.5389,
    '956_620': 35.5286,
    '956_621': 35.5255,
    '956_622': 35.5293,
    '956_623': 35.5381,
    '956_624': 35.548,
    '956_625': 35.5599,
    '956_626': 35.5679,
    '956_627': 35.5759,
    '956_628': 35.588,
    '956_629': 35.6088,
    '956_630': 35.6388,
    '956_631': 35.6757,
    '956_632': 35.7185,
    '956_633': 35.7777,
    '956_634': 35.8591,
    '956_635': 35.9614,
    '956_636': 36.0869,
    '956_637': 36.2278,
    '956_638': 36.3778,
    '956_639': 36.5331,
    '956_640': 36.6907,
    '956_641': 36.8279,
    '956_642': 36.9149,
    '956_643': 36.9981,
    '956_644': 37.0827,
    '956_645': 37.155,
    '956_646': 37.2328,
    '956_647': 37.329,
    '956_648': 37.4388,
    '956_649': 37.555,
    '956_650': 37.6691,
    '956_651': 37.7992,
    '956_652': 37.9398,
    '956_653': 38.082,
    '956_654': 38.2087,
    '956_655': 38.3326,
    '956_656': 38.4612,
    '956_657': 38.5816,
    '956_658': 38.684,
    '956_659': 38.7673,
    '956_660': 38.8442,
    '956_661': 38.9478,
    '956_662': 39.0865,
    '956_663': 39.256,
    '956_664': 39.3946,
    '956_665': 39.5075,
    '956_666': 39.6103,
    '956_667': 39.7173,
    '956_668': 39.8188,
    '956_669': 39.9211,
    '956_670': 40.0152,
    '956_671': 40.091,
    '956_672': 40.1432,
    '956_673': 40.1742,
    '956_674': 40.2078,
    '956_675': 40.2592,
    '956_676': 40.3316,
    '956_677': 40.422,
    '956_678': 40.5138,
    '956_679': 40.5873,
    '956_680': 40.6427,
    '956_681': 40.6821,
    '956_682': 40.7076,
    '956_683': 40.7407,
    '956_684': 40.784,
    '956_685': 40.8257,
    '956_686': 40.8747,
    '956_687': 40.927,
    '956_688': 40.9822,
    '956_689': 41.0709,
    '956_690': 41.1897,
    '956_691': 41.3284,
    '956_692': 41.4781,
    '956_693': 41.6389,
    '956_694': 41.8134,
    '956_695': 41.9999,
    '956_696': 42.1758,
    '956_697': 42.3393,
    '956_698': 42.4863,
    '956_699': 42.5903,
    '956_700': 42.6758,
    '956_701': 42.7433,
    '956_702': 42.7834,
    '956_703': 42.8179,
    '956_704': 42.8615,
    '956_705': 42.9138,
    '956_706': 42.9643,
    '956_707': 43.0197,
    '956_708': 43.0644,
    '956_709': 43.0925,
    '956_710': 43.1232,
    '956_711': 43.1573,
    '956_712': 43.2093,
    '956_713': 43.2544,
    '956_714': 43.2894,
    '956_715': 43.2815,
    '956_716': 43.2426,
    '956_717': 43.1971,
    '956_718': 43.1436,
    '956_719': 43.1168,
    '956_720': 43.148,
    '956_721': 43.2266,
    '956_722': 43.316,
    '956_723': 43.3965,
    '956_724': 43.4727,
    '956_725': 43.5397,
    '956_726': 43.5801,
    '956_727': 43.5991,
    '956_728': 43.5885,
    '956_729': 43.5844,
    '956_730': 43.6123,
    '956_731': 43.6702,
    '956_732': 43.7466,
    '956_733': 43.8155,
    '956_734': 43.8495,
    '956_735': 43.8914,
    '956_736': 43.9147,
    '956_737': 43.9055,
    '956_738': 43.9055,
    '956_739': 43.9164,
    '956_740': 43.9413,
    '956_741': 43.9679,
    '956_742': 44.0118,
    '956_743': 44.0492,
    '956_744': 44.1019,
    '956_745': 44.1344,
    '956_746': 44.1707,
    '956_747': 44.1716,
    '956_748': 44.1468,
    '956_749': 44.113,
    '956_750': 44.0383,
    '956_751': 43.959,
    '956_752': 43.8441,
    '956_753': 43.715,
    '956_754': 43.5862,
    '956_755': 43.4595,
    '956_756': 43.3194,
    '956_757': 43.1718,
    '956_758': 43.0326,
    '956_759': 42.9003,
    '956_760': 42.7759,
    '956_761': 42.6489,
    '956_762': 42.533,
    '956_763': 42.4277,
    '956_764': 42.3186,
    '956_765': 42.1833,
    '956_766': 42.0206,
    '956_767': 41.8419,
    '956_768': 41.6574,
    '956_769': 41.4807,
    '956_770': 41.2915,
    '956_771': 41.0865,
    '956_772': 40.8661,
    '956_773': 40.6353,
    '956_774': 40.4121,
    '956_775': 40.2288,
    '956_776': 40.0727,
    '956_777': 39.9317,
    '956_778': 39.8019,
    '956_779': 39.6813,
    '956_780': 39.5668,
    '956_781': 39.457,
    '956_782': 39.3528,
    '956_783': 39.2544,
    '956_784': 39.162,
    '956_785': 39.0766,
    '956_786': 38.9995,
    '956_787': 38.9321,
    '956_788': 38.8762,
    '956_789': 38.8332,
    '956_790': 38.8018,
    '956_791': 38.7808,
    '956_792': 38.7661,
    '956_793': 38.7539,
    '956_794': 38.7421,
    '956_795': 38.7313,
    '956_796': 38.721,
    '956_797': 38.7087,
    '956_798': 38.6911,
    '956_799': 38.664,
    '956_800': 38.6285,
    '956_801': 38.585,
    '956_802': 38.5376,
    '956_803': 38.4856,
    '956_804': 38.428,
    '956_805': 38.3641,
    '956_806': 38.2932,
    '956_807': 38.215,
    '956_808': 38.1279,
    '956_809': 38.0311,
    '956_810': 37.9251,
    '956_811': 37.8111,
    '956_812': 37.6862,
    '956_813': 37.5504,
    '956_814': 37.4047,
    '956_815': 37.2457,
    '956_816': 37.0785,
    '956_817': 36.9023,
    '956_818': 36.7156,
    '956_819': 36.5194,
    '956_820': 36.3153,
    '956_821': 36.105,
    '956_822': 35.8911,
    '956_823': 35.6734,
    '956_824': 35.451,
    '956_825': 35.223,
    '956_826': 34.9878,
    '956_827': 34.7452,
    '956_828': 34.496,
    '956_829': 34.2418,
    '956_830': 33.9825,
    '956_831': 33.7192,
    '956_832': 33.4543,
    '956_833': 33.1874,
    '956_834': 32.9186,
    '956_835': 32.6459,
    '956_836': 32.3665,
    '956_837': 32.0759,
    '956_838': 31.769,
    '956_839': 31.4473,
    '956_840': 31.1017,
    '956_841': 30.7529,
    '956_842': 30.4053,
    '956_843': 30.0531,
    '956_844': 29.6977,
    '956_845': 29.3465,
    '956_846': 28.9879,
    '956_847': 28.6235,
    '956_848': 28.2532,
    '957_516': 33.457,
    '957_517': 33.5364,
    '957_518': 33.6147,
    '957_519': 33.6864,
    '957_520': 33.7536,
    '957_521': 33.8189,
    '957_522': 33.8761,
    '957_523': 33.9165,
    '957_524': 33.956,
    '957_525': 33.9879,
    '957_526': 34.0116,
    '957_527': 34.0241,
    '957_528': 34.0369,
    '957_560': 34.0455,
    '957_561': 34.053,
    '957_562': 34.0656,
    '957_563': 34.08,
    '957_564': 34.0967,
    '957_565': 34.1122,
    '957_566': 34.1248,
    '957_567': 34.1301,
    '957_568': 34.1329,
    '957_569': 34.1375,
    '957_570': 34.1471,
    '957_571': 34.1641,
    '957_572': 34.1925,
    '957_573': 34.2285,
    '957_574': 34.27,
    '957_575': 34.3238,
    '957_576': 34.3681,
    '957_577': 34.4127,
    '957_578': 34.4562,
    '957_579': 34.4968,
    '957_580': 34.5327,
    '957_581': 34.5621,
    '957_582': 34.5883,
    '957_583': 34.6183,
    '957_584': 34.6513,
    '957_585': 34.6979,
    '957_586': 34.7542,
    '957_587': 34.8278,
    '957_588': 34.9023,
    '957_589': 34.9731,
    '957_590': 35.0372,
    '957_591': 35.0929,
    '957_592': 35.1385,
    '957_593': 35.1713,
    '957_594': 35.1936,
    '957_595': 35.2096,
    '957_596': 35.2207,
    '957_597': 35.2237,
    '957_598': 35.2254,
    '957_599': 35.2313,
    '957_600': 35.2461,
    '957_601': 35.273,
    '957_602': 35.304,
    '957_603': 35.3304,
    '957_604': 35.3593,
    '957_605': 35.3919,
    '957_606': 35.4277,
    '957_607': 35.4654,
    '957_608': 35.4986,
    '957_609': 35.5164,
    '957_610': 35.526,
    '957_611': 35.5343,
    '957_612': 35.5444,
    '957_613': 35.5548,
    '957_614': 35.5527,
    '957_615': 35.5435,
    '957_616': 35.5336,
    '957_617': 35.5255,
    '957_618': 35.5232,
    '957_619': 35.5139,
    '957_620': 35.5039,
    '957_621': 35.4997,
    '957_622': 35.5047,
    '957_623': 35.5167,
    '957_624': 35.525,
    '957_625': 35.5325,
    '957_626': 35.5384,
    '957_627': 35.5452,
    '957_628': 35.5572,
    '957_629': 35.5792,
    '957_630': 35.6131,
    '957_631': 35.6543,
    '957_632': 35.6975,
    '957_633': 35.7561,
    '957_634': 35.8423,
    '957_635': 35.9487,
    '957_636': 36.0796,
    '957_637': 36.2244,
    '957_638': 36.3792,
    '957_639': 36.5411,
    '957_640': 36.7017,
    '957_641': 36.8278,
    '957_642': 36.9147,
    '957_643': 36.993,
    '957_644': 37.0717,
    '957_645': 37.1424,
    '957_646': 37.2221,
    '957_647': 37.3208,
    '957_648': 37.4325,
    '957_649': 37.5457,
    '957_650': 37.6651,
    '957_651': 37.7916,
    '957_652': 37.93,
    '957_653': 38.0745,
    '957_654': 38.1936,
    '957_655': 38.3041,
    '957_656': 38.4216,
    '957_657': 38.5285,
    '957_658': 38.6315,
    '957_659': 38.7137,
    '957_660': 38.7883,
    '957_661': 38.8986,
    '957_662': 39.0444,
    '957_663': 39.2243,
    '957_664': 39.3845,
    '957_665': 39.517,
    '957_666': 39.6445,
    '957_667': 39.7641,
    '957_668': 39.8721,
    '957_669': 39.9709,
    '957_670': 40.0603,
    '957_671': 40.1327,
    '957_672': 40.1897,
    '957_673': 40.2367,
    '957_674': 40.2747,
    '957_675': 40.3217,
    '957_676': 40.3885,
    '957_677': 40.4691,
    '957_678': 40.5562,
    '957_679': 40.6367,
    '957_680': 40.6853,
    '957_681': 40.7212,
    '957_682': 40.7521,
    '957_683': 40.7827,
    '957_684': 40.8217,
    '957_685': 40.8659,
    '957_686': 40.9173,
    '957_687': 40.9695,
    '957_688': 41.0272,
    '957_689': 41.1097,
    '957_690': 41.2142,
    '957_691': 41.3429,
    '957_692': 41.4883,
    '957_693': 41.6528,
    '957_694': 41.8155,
    '957_695': 41.9785,
    '957_696': 42.1504,
    '957_697': 42.3104,
    '957_698': 42.449,
    '957_699': 42.5664,
    '957_700': 42.6584,
    '957_701': 42.7258,
    '957_702': 42.7821,
    '957_703': 42.8302,
    '957_704': 42.8803,
    '957_705': 42.9339,
    '957_706': 42.9893,
    '957_707': 43.0352,
    '957_708': 43.0712,
    '957_709': 43.0953,
    '957_710': 43.1174,
    '957_711': 43.1408,
    '957_712': 43.1814,
    '957_713': 43.2156,
    '957_714': 43.2473,
    '957_715': 43.2586,
    '957_716': 43.2165,
    '957_717': 43.1723,
    '957_718': 43.1238,
    '957_719': 43.0938,
    '957_720': 43.1153,
    '957_721': 43.1834,
    '957_722': 43.269,
    '957_723': 43.3474,
    '957_724': 43.4158,
    '957_725': 43.4682,
    '957_726': 43.5014,
    '957_727': 43.5189,
    '957_728': 43.5257,
    '957_729': 43.5506,
    '957_730': 43.6015,
    '957_731': 43.6783,
    '957_732': 43.7707,
    '957_733': 43.8478,
    '957_734': 43.8942,
    '957_735': 43.9531,
    '957_736': 43.9684,
    '957_737': 43.9566,
    '957_738': 43.9442,
    '957_739': 43.9434,
    '957_740': 43.9563,
    '957_741': 43.9797,
    '957_742': 44.0141,
    '957_743': 44.0541,
    '957_744': 44.097,
    '957_745': 44.1219,
    '957_746': 44.1475,
    '957_747': 44.1518,
    '957_748': 44.1313,
    '957_749': 44.0841,
    '957_750': 44.0104,
    '957_751': 43.9175,
    '957_752': 43.8022,
    '957_753': 43.6777,
    '957_754': 43.5452,
    '957_755': 43.4095,
    '957_756': 43.2654,
    '957_757': 43.1156,
    '957_758': 42.9678,
    '957_759': 42.8214,
    '957_760': 42.6856,
    '957_761': 42.5588,
    '957_762': 42.4639,
    '957_763': 42.3877,
    '957_764': 42.2954,
    '957_765': 42.1798,
    '957_766': 42.0401,
    '957_767': 41.8855,
    '957_768': 41.7185,
    '957_769': 41.5424,
    '957_770': 41.3424,
    '957_771': 41.1344,
    '957_772': 40.9168,
    '957_773': 40.6933,
    '957_774': 40.4816,
    '957_775': 40.3054,
    '957_776': 40.1524,
    '957_777': 40.0122,
    '957_778': 39.8833,
    '957_779': 39.7644,
    '957_780': 39.6516,
    '957_781': 39.5448,
    '957_782': 39.4449,
    '957_783': 39.3516,
    '957_784': 39.2639,
    '957_785': 39.1828,
    '957_786': 39.1104,
    '957_787': 39.0476,
    '957_788': 38.9959,
    '957_789': 38.9554,
    '957_790': 38.9271,
    '957_791': 38.91,
    '957_792': 38.9008,
    '957_793': 38.8952,
    '957_794': 38.8919,
    '957_795': 38.8895,
    '957_796': 38.886,
    '957_797': 38.8799,
    '957_798': 38.8687,
    '957_799': 38.8492,
    '957_800': 38.8193,
    '957_801': 38.78,
    '957_802': 38.7351,
    '957_803': 38.6872,
    '957_804': 38.6325,
    '957_805': 38.5692,
    '957_806': 38.4976,
    '957_807': 38.4169,
    '957_808': 38.3271,
    '957_809': 38.2273,
    '957_810': 38.1185,
    '957_811': 38.0022,
    '957_812': 37.8747,
    '957_813': 37.7364,
    '957_814': 37.5881,
    '957_815': 37.4267,
    '957_816': 37.2538,
    '957_817': 37.0725,
    '957_818': 36.8831,
    '957_819': 36.6854,
    '957_820': 36.4803,
    '957_821': 36.2687,
    '957_822': 36.0527,
    '957_823': 35.8315,
    '957_824': 35.6059,
    '957_825': 35.3744,
    '957_826': 35.1348,
    '957_827': 34.8863,
    '957_828': 34.6328,
    '957_829': 34.3747,
    '957_830': 34.1118,
    '957_831': 33.8456,
    '957_832': 33.5796,
    '957_833': 33.3123,
    '957_834': 33.0454,
    '957_835': 32.7736,
    '957_836': 32.4933,
    '957_837': 32.1979,
    '957_838': 31.8862,
    '957_839': 31.5627,
    '957_840': 31.2232,
    '957_841': 30.8699,
    '957_842': 30.5238,
    '957_843': 30.1688,
    '957_844': 29.8235,
    '957_845': 29.4723,
    '957_846': 29.1124,
    '957_847': 28.7558,
    '957_848': 28.3871,
    '958_516': 33.4531,
    '958_517': 33.5326,
    '958_518': 33.6092,
    '958_519': 33.6814,
    '958_520': 33.7511,
    '958_521': 33.8145,
    '958_522': 33.8678,
    '958_523': 33.9076,
    '958_524': 33.9409,
    '958_525': 33.9685,
    '958_526': 33.9885,
    '958_527': 34.0025,
    '958_528': 34.0157,
    '958_560': 34.0059,
    '958_561': 34.0089,
    '958_562': 34.0184,
    '958_563': 34.0291,
    '958_564': 34.0406,
    '958_565': 34.0541,
    '958_566': 34.0651,
    '958_567': 34.0685,
    '958_568': 34.0702,
    '958_569': 34.0759,
    '958_570': 34.0859,
    '958_571': 34.1016,
    '958_572': 34.1281,
    '958_573': 34.1633,
    '958_574': 34.2065,
    '958_575': 34.2635,
    '958_576': 34.3114,
    '958_577': 34.3593,
    '958_578': 34.4049,
    '958_579': 34.4458,
    '958_580': 34.4797,
    '958_581': 34.5075,
    '958_582': 34.5373,
    '958_583': 34.5674,
    '958_584': 34.6003,
    '958_585': 34.6416,
    '958_586': 34.6933,
    '958_587': 34.7594,
    '958_588': 34.843,
    '958_589': 34.9153,
    '958_590': 34.9802,
    '958_591': 35.0377,
    '958_592': 35.0864,
    '958_593': 35.119,
    '958_594': 35.1413,
    '958_595': 35.1573,
    '958_596': 35.1689,
    '958_597': 35.174,
    '958_598': 35.1758,
    '958_599': 35.1812,
    '958_600': 35.1982,
    '958_601': 35.2315,
    '958_602': 35.2673,
    '958_603': 35.2902,
    '958_604': 35.3181,
    '958_605': 35.3495,
    '958_606': 35.3828,
    '958_607': 35.4164,
    '958_608': 35.4445,
    '958_609': 35.4617,
    '958_610': 35.4737,
    '958_611': 35.4863,
    '958_612': 35.5042,
    '958_613': 35.5255,
    '958_614': 35.5258,
    '958_615': 35.52,
    '958_616': 35.5117,
    '958_617': 35.5019,
    '958_618': 35.4972,
    '958_619': 35.4868,
    '958_620': 35.4772,
    '958_621': 35.4737,
    '958_622': 35.4822,
    '958_623': 35.4946,
    '958_624': 35.5011,
    '958_625': 35.5062,
    '958_626': 35.5102,
    '958_627': 35.5153,
    '958_628': 35.5261,
    '958_629': 35.5515,
    '958_630': 35.5879,
    '958_631': 35.6291,
    '958_632': 35.6794,
    '958_633': 35.7459,
    '958_634': 35.8364,
    '958_635': 35.9474,
    '958_636': 36.0784,
    '958_637': 36.2242,
    '958_638': 36.3828,
    '958_639': 36.5579,
    '958_640': 36.7207,
    '958_641': 36.8384,
    '958_642': 36.9215,
    '958_643': 36.994,
    '958_644': 37.0675,
    '958_645': 37.1378,
    '958_646': 37.2169,
    '958_647': 37.3161,
    '958_648': 37.4275,
    '958_649': 37.5424,
    '958_650': 37.6641,
    '958_651': 37.7945,
    '958_652': 37.933,
    '958_653': 38.0737,
    '958_654': 38.1849,
    '958_655': 38.2801,
    '958_656': 38.3876,
    '958_657': 38.487,
    '958_658': 38.5887,
    '958_659': 38.6675,
    '958_660': 38.7497,
    '958_661': 38.8666,
    '958_662': 39.0132,
    '958_663': 39.1923,
    '958_664': 39.3651,
    '958_665': 39.5248,
    '958_666': 39.6733,
    '958_667': 39.8121,
    '958_668': 39.9283,
    '958_669': 40.028,
    '958_670': 40.109,
    '958_671': 40.1796,
    '958_672': 40.249,
    '958_673': 40.3062,
    '958_674': 40.3477,
    '958_675': 40.3855,
    '958_676': 40.4384,
    '958_677': 40.5122,
    '958_678': 40.5903,
    '958_679': 40.6655,
    '958_680': 40.7137,
    '958_681': 40.7534,
    '958_682': 40.7912,
    '958_683': 40.8196,
    '958_684': 40.857,
    '958_685': 40.9047,
    '958_686': 40.9616,
    '958_687': 41.0117,
    '958_688': 41.0717,
    '958_689': 41.1507,
    '958_690': 41.2461,
    '958_691': 41.362,
    '958_692': 41.5036,
    '958_693': 41.656,
    '958_694': 41.816,
    '958_695': 41.9667,
    '958_696': 42.1206,
    '958_697': 42.2738,
    '958_698': 42.416,
    '958_699': 42.5313,
    '958_700': 42.6317,
    '958_701': 42.7127,
    '958_702': 42.781,
    '958_703': 42.8442,
    '958_704': 42.9015,
    '958_705': 42.9544,
    '958_706': 43.0108,
    '958_707': 43.0534,
    '958_708': 43.0709,
    '958_709': 43.105,
    '958_710': 43.1157,
    '958_711': 43.1313,
    '958_712': 43.1594,
    '958_713': 43.1892,
    '958_714': 43.1925,
    '958_715': 43.2004,
    '958_716': 43.1813,
    '958_717': 43.1387,
    '958_718': 43.0948,
    '958_719': 43.0667,
    '958_720': 43.0792,
    '958_721': 43.1385,
    '958_722': 43.215,
    '958_723': 43.2875,
    '958_724': 43.3391,
    '958_725': 43.3898,
    '958_726': 43.4238,
    '958_727': 43.4452,
    '958_728': 43.4762,
    '958_729': 43.5236,
    '958_730': 43.5905,
    '958_731': 43.6797,
    '958_732': 43.7876,
    '958_733': 43.8882,
    '958_734': 43.9397,
    '958_735': 43.9666,
    '958_736': 43.9988,
    '958_737': 43.9843,
    '958_738': 43.9611,
    '958_739': 43.95,
    '958_740': 43.9554,
    '958_741': 43.9769,
    '958_742': 44.0135,
    '958_743': 44.05,
    '958_744': 44.0878,
    '958_745': 44.1139,
    '958_746': 44.127,
    '958_747': 44.1229,
    '958_748': 44.0974,
    '958_749': 44.0455,
    '958_750': 43.9772,
    '958_751': 43.8783,
    '958_752': 43.7689,
    '958_753': 43.6524,
    '958_754': 43.5217,
    '958_755': 43.3807,
    '958_756': 43.2326,
    '958_757': 43.0794,
    '958_758': 42.9225,
    '958_759': 42.762,
    '958_760': 42.6175,
    '958_761': 42.489,
    '958_762': 42.3912,
    '958_763': 42.3164,
    '958_764': 42.2438,
    '958_765': 42.1547,
    '958_766': 42.0514,
    '958_767': 41.9239,
    '958_768': 41.7673,
    '958_769': 41.5856,
    '958_770': 41.383,
    '958_771': 41.1771,
    '958_772': 40.9701,
    '958_773': 40.7549,
    '958_774': 40.5546,
    '958_775': 40.3857,
    '958_776': 40.2362,
    '958_777': 40.0969,
    '958_778': 39.9698,
    '958_779': 39.8522,
    '958_780': 39.741,
    '958_781': 39.6365,
    '958_782': 39.5401,
    '958_783': 39.4508,
    '958_784': 39.3675,
    '958_785': 39.2902,
    '958_786': 39.2217,
    '958_787': 39.1637,
    '958_788': 39.1164,
    '958_789': 39.0787,
    '958_790': 39.0517,
    '958_791': 39.0371,
    '958_792': 39.032,
    '958_793': 39.0335,
    '958_794': 39.038,
    '958_795': 39.0438,
    '958_796': 39.0479,
    '958_797': 39.0498,
    '958_798': 39.0466,
    '958_799': 39.0335,
    '958_800': 39.0092,
    '958_801': 38.9759,
    '958_802': 38.9359,
    '958_803': 38.891,
    '958_804': 38.8372,
    '958_805': 38.7734,
    '958_806': 38.6997,
    '958_807': 38.6154,
    '958_808': 38.5223,
    '958_809': 38.4195,
    '958_810': 38.3076,
    '958_811': 38.1877,
    '958_812': 38.0578,
    '958_813': 37.9175,
    '958_814': 37.7662,
    '958_815': 37.6031,
    '958_816': 37.4275,
    '958_817': 37.2433,
    '958_818': 37.0526,
    '958_819': 36.8548,
    '958_820': 36.6502,
    '958_821': 36.4388,
    '958_822': 36.2224,
    '958_823': 35.9998,
    '958_824': 35.7703,
    '958_825': 35.5353,
    '958_826': 35.2902,
    '958_827': 35.0351,
    '958_828': 34.7759,
    '958_829': 34.5131,
    '958_830': 34.2462,
    '958_831': 33.9767,
    '958_832': 33.7105,
    '958_833': 33.4426,
    '958_834': 33.1728,
    '958_835': 32.897,
    '958_836': 32.6091,
    '958_837': 32.31,
    '958_838': 32,
    '958_839': 31.6773,
    '958_840': 31.3416,
    '958_841': 30.9872,
    '958_842': 30.6409,
    '958_843': 30.2902,
    '958_844': 29.946,
    '958_845': 29.6005,
    '958_846': 29.2463,
    '958_847': 28.8926,
    '958_848': 28.5312,
    '959_516': 33.4482,
    '959_517': 33.5289,
    '959_518': 33.6079,
    '959_519': 33.6878,
    '959_520': 33.7561,
    '959_521': 33.8174,
    '959_522': 33.8674,
    '959_523': 33.9047,
    '959_524': 33.9319,
    '959_525': 33.953,
    '959_526': 33.9669,
    '959_527': 33.9804,
    '959_528': 33.9942,
    '959_560': 33.965,
    '959_561': 33.9652,
    '959_562': 33.9712,
    '959_563': 33.9783,
    '959_564': 33.9856,
    '959_565': 33.9935,
    '959_566': 34.0005,
    '959_567': 34.0041,
    '959_568': 34.0071,
    '959_569': 34.0134,
    '959_570': 34.0243,
    '959_571': 34.0394,
    '959_572': 34.0645,
    '959_573': 34.0981,
    '959_574': 34.1435,
    '959_575': 34.2024,
    '959_576': 34.253,
    '959_577': 34.3035,
    '959_578': 34.3511,
    '959_579': 34.3922,
    '959_580': 34.4252,
    '959_581': 34.4572,
    '959_582': 34.4912,
    '959_583': 34.5224,
    '959_584': 34.5564,
    '959_585': 34.5964,
    '959_586': 34.6454,
    '959_587': 34.7112,
    '959_588': 34.7867,
    '959_589': 34.8582,
    '959_590': 34.9223,
    '959_591': 34.9789,
    '959_592': 35.0267,
    '959_593': 35.0633,
    '959_594': 35.0883,
    '959_595': 35.1063,
    '959_596': 35.1242,
    '959_597': 35.1287,
    '959_598': 35.13,
    '959_599': 35.1361,
    '959_600': 35.1586,
    '959_601': 35.1901,
    '959_602': 35.2222,
    '959_603': 35.2474,
    '959_604': 35.2757,
    '959_605': 35.3052,
    '959_606': 35.3353,
    '959_607': 35.3651,
    '959_608': 35.3887,
    '959_609': 35.403,
    '959_610': 35.415,
    '959_611': 35.4281,
    '959_612': 35.4466,
    '959_613': 35.4703,
    '959_614': 35.4818,
    '959_615': 35.4848,
    '959_616': 35.4813,
    '959_617': 35.4695,
    '959_618': 35.462,
    '959_619': 35.4545,
    '959_620': 35.449,
    '959_621': 35.4509,
    '959_622': 35.4581,
    '959_623': 35.4673,
    '959_624': 35.474,
    '959_625': 35.479,
    '959_626': 35.482,
    '959_627': 35.4837,
    '959_628': 35.494,
    '959_629': 35.5253,
    '959_630': 35.5673,
    '959_631': 35.6104,
    '959_632': 35.6637,
    '959_633': 35.7355,
    '959_634': 35.8315,
    '959_635': 35.9463,
    '959_636': 36.0768,
    '959_637': 36.221,
    '959_638': 36.3805,
    '959_639': 36.5626,
    '959_640': 36.7284,
    '959_641': 36.8467,
    '959_642': 36.93,
    '959_643': 36.9979,
    '959_644': 37.0681,
    '959_645': 37.1421,
    '959_646': 37.2188,
    '959_647': 37.3137,
    '959_648': 37.4211,
    '959_649': 37.5391,
    '959_650': 37.6536,
    '959_651': 37.7801,
    '959_652': 37.9172,
    '959_653': 38.0562,
    '959_654': 38.1615,
    '959_655': 38.2521,
    '959_656': 38.355,
    '959_657': 38.4497,
    '959_658': 38.5454,
    '959_659': 38.6293,
    '959_660': 38.7253,
    '959_661': 38.8488,
    '959_662': 38.9993,
    '959_663': 39.1754,
    '959_664': 39.3655,
    '959_665': 39.5451,
    '959_666': 39.6985,
    '959_667': 39.8452,
    '959_668': 39.9712,
    '959_669': 40.0756,
    '959_670': 40.159,
    '959_671': 40.2309,
    '959_672': 40.3077,
    '959_673': 40.3711,
    '959_674': 40.4153,
    '959_675': 40.4464,
    '959_676': 40.485,
    '959_677': 40.5438,
    '959_678': 40.6094,
    '959_679': 40.6767,
    '959_680': 40.732,
    '959_681': 40.7737,
    '959_682': 40.8157,
    '959_683': 40.8502,
    '959_684': 40.8893,
    '959_685': 40.941,
    '959_686': 40.9979,
    '959_687': 41.0505,
    '959_688': 41.1118,
    '959_689': 41.1864,
    '959_690': 41.2742,
    '959_691': 41.3773,
    '959_692': 41.5106,
    '959_693': 41.6542,
    '959_694': 41.802,
    '959_695': 41.9505,
    '959_696': 42.0994,
    '959_697': 42.2444,
    '959_698': 42.3757,
    '959_699': 42.4959,
    '959_700': 42.603,
    '959_701': 42.6972,
    '959_702': 42.7757,
    '959_703': 42.8519,
    '959_704': 42.9266,
    '959_705': 42.9746,
    '959_706': 43.0287,
    '959_707': 43.0709,
    '959_708': 43.0818,
    '959_709': 43.1111,
    '959_710': 43.1166,
    '959_711': 43.1303,
    '959_712': 43.1449,
    '959_713': 43.1583,
    '959_714': 43.159,
    '959_715': 43.1399,
    '959_716': 43.1294,
    '959_717': 43.0958,
    '959_718': 43.0653,
    '959_719': 43.036,
    '959_720': 43.0402,
    '959_721': 43.0871,
    '959_722': 43.1553,
    '959_723': 43.219,
    '959_724': 43.2683,
    '959_725': 43.3151,
    '959_726': 43.3504,
    '959_727': 43.3872,
    '959_728': 43.4385,
    '959_729': 43.5018,
    '959_730': 43.5784,
    '959_731': 43.6772,
    '959_732': 43.7946,
    '959_733': 43.9069,
    '959_734': 43.9705,
    '959_735': 43.9722,
    '959_736': 44.0091,
    '959_737': 43.99,
    '959_738': 43.9584,
    '959_739': 43.938,
    '959_740': 43.9392,
    '959_741': 43.9649,
    '959_742': 43.99,
    '959_743': 44.0299,
    '959_744': 44.065,
    '959_745': 44.0874,
    '959_746': 44.091,
    '959_747': 44.0787,
    '959_748': 44.0475,
    '959_749': 43.9998,
    '959_750': 43.9382,
    '959_751': 43.8447,
    '959_752': 43.7417,
    '959_753': 43.6256,
    '959_754': 43.5012,
    '959_755': 43.3612,
    '959_756': 43.2116,
    '959_757': 43.0501,
    '959_758': 42.8843,
    '959_759': 42.72,
    '959_760': 42.5748,
    '959_761': 42.4426,
    '959_762': 42.3394,
    '959_763': 42.2536,
    '959_764': 42.188,
    '959_765': 42.1325,
    '959_766': 42.0604,
    '959_767': 41.9488,
    '959_768': 41.795,
    '959_769': 41.613,
    '959_770': 41.4201,
    '959_771': 41.2195,
    '959_772': 41.018,
    '959_773': 40.8115,
    '959_774': 40.6283,
    '959_775': 40.4689,
    '959_776': 40.3225,
    '959_777': 40.1852,
    '959_778': 40.0599,
    '959_779': 39.9438,
    '959_780': 39.8335,
    '959_781': 39.7304,
    '959_782': 39.636,
    '959_783': 39.5498,
    '959_784': 39.4697,
    '959_785': 39.3958,
    '959_786': 39.3312,
    '959_787': 39.278,
    '959_788': 39.2355,
    '959_789': 39.2016,
    '959_790': 39.1766,
    '959_791': 39.1635,
    '959_792': 39.1618,
    '959_793': 39.1692,
    '959_794': 39.1815,
    '959_795': 39.1955,
    '959_796': 39.208,
    '959_797': 39.2184,
    '959_798': 39.2228,
    '959_799': 39.2156,
    '959_800': 39.1972,
    '959_801': 39.1697,
    '959_802': 39.1341,
    '959_803': 39.0912,
    '959_804': 39.0381,
    '959_805': 38.9726,
    '959_806': 38.8961,
    '959_807': 38.8087,
    '959_808': 38.712,
    '959_809': 38.6057,
    '959_810': 38.4897,
    '959_811': 38.3656,
    '959_812': 38.2331,
    '959_813': 38.0908,
    '959_814': 37.9381,
    '959_815': 37.7734,
    '959_816': 37.5969,
    '959_817': 37.4124,
    '959_818': 37.2218,
    '959_819': 37.0252,
    '959_820': 36.8228,
    '959_821': 36.613,
    '959_822': 36.397,
    '959_823': 36.172,
    '959_824': 35.94,
    '959_825': 35.7001,
    '959_826': 35.4487,
    '959_827': 35.1867,
    '959_828': 34.9221,
    '959_829': 34.6545,
    '959_830': 34.3853,
    '959_831': 34.1164,
    '959_832': 33.8444,
    '959_833': 33.5712,
    '959_834': 33.2959,
    '959_835': 33.0156,
    '959_836': 32.7194,
    '959_837': 32.4182,
    '959_838': 32.1117,
    '959_839': 31.791,
    '959_840': 31.461,
    '959_841': 31.1058,
    '959_842': 30.7627,
    '959_843': 30.4102,
    '959_844': 30.0683,
    '959_845': 29.7322,
    '959_846': 29.3837,
    '959_847': 29.0323,
    '959_848': 28.672,
    '960_516': 33.4362,
    '960_517': 33.5214,
    '960_518': 33.6081,
    '960_519': 33.6891,
    '960_520': 33.7611,
    '960_521': 33.8241,
    '960_522': 33.8719,
    '960_523': 33.9045,
    '960_524': 33.9262,
    '960_525': 33.9416,
    '960_526': 33.9495,
    '960_527': 33.9575,
    '960_528': 33.9682,
    '960_560': 33.9246,
    '960_561': 33.9213,
    '960_562': 33.9231,
    '960_563': 33.9252,
    '960_564': 33.9264,
    '960_565': 33.9305,
    '960_566': 33.9354,
    '960_567': 33.9394,
    '960_568': 33.9433,
    '960_569': 33.9488,
    '960_570': 33.959,
    '960_571': 33.9776,
    '960_572': 34.0049,
    '960_573': 34.0402,
    '960_574': 34.084,
    '960_575': 34.1415,
    '960_576': 34.1923,
    '960_577': 34.2435,
    '960_578': 34.2909,
    '960_579': 34.3265,
    '960_580': 34.3632,
    '960_581': 34.401,
    '960_582': 34.439,
    '960_583': 34.4759,
    '960_584': 34.5149,
    '960_585': 34.5593,
    '960_586': 34.6117,
    '960_587': 34.6741,
    '960_588': 34.7414,
    '960_589': 34.8069,
    '960_590': 34.8681,
    '960_591': 34.9221,
    '960_592': 34.9674,
    '960_593': 35.0073,
    '960_594': 35.0382,
    '960_595': 35.058,
    '960_596': 35.0757,
    '960_597': 35.0848,
    '960_598': 35.0913,
    '960_599': 35.1037,
    '960_600': 35.1274,
    '960_601': 35.1551,
    '960_602': 35.1842,
    '960_603': 35.2103,
    '960_604': 35.2366,
    '960_605': 35.263,
    '960_606': 35.2888,
    '960_607': 35.316,
    '960_608': 35.3374,
    '960_609': 35.3495,
    '960_610': 35.3611,
    '960_611': 35.3783,
    '960_612': 35.3956,
    '960_613': 35.4178,
    '960_614': 35.4345,
    '960_615': 35.4418,
    '960_616': 35.4386,
    '960_617': 35.4292,
    '960_618': 35.4232,
    '960_619': 35.4194,
    '960_620': 35.4178,
    '960_621': 35.4215,
    '960_622': 35.4302,
    '960_623': 35.4384,
    '960_624': 35.4461,
    '960_625': 35.4525,
    '960_626': 35.4555,
    '960_627': 35.4587,
    '960_628': 35.4748,
    '960_629': 35.5057,
    '960_630': 35.5481,
    '960_631': 35.5944,
    '960_632': 35.651,
    '960_633': 35.7252,
    '960_634': 35.8271,
    '960_635': 35.9445,
    '960_636': 36.0731,
    '960_637': 36.2133,
    '960_638': 36.3668,
    '960_639': 36.5396,
    '960_640': 36.7117,
    '960_641': 36.84,
    '960_642': 36.9307,
    '960_643': 36.9984,
    '960_644': 37.0688,
    '960_645': 37.1441,
    '960_646': 37.2182,
    '960_647': 37.3074,
    '960_648': 37.4062,
    '960_649': 37.5158,
    '960_650': 37.6263,
    '960_651': 37.7517,
    '960_652': 37.8872,
    '960_653': 38.0198,
    '960_654': 38.1303,
    '960_655': 38.2269,
    '960_656': 38.3251,
    '960_657': 38.4245,
    '960_658': 38.524,
    '960_659': 38.6142,
    '960_660': 38.7162,
    '960_661': 38.8467,
    '960_662': 39.0076,
    '960_663': 39.1941,
    '960_664': 39.3947,
    '960_665': 39.579,
    '960_666': 39.7296,
    '960_667': 39.8746,
    '960_668': 40.011,
    '960_669': 40.1187,
    '960_670': 40.2091,
    '960_671': 40.2879,
    '960_672': 40.3671,
    '960_673': 40.4263,
    '960_674': 40.4664,
    '960_675': 40.4979,
    '960_676': 40.5262,
    '960_677': 40.5697,
    '960_678': 40.6224,
    '960_679': 40.6805,
    '960_680': 40.7351,
    '960_681': 40.7842,
    '960_682': 40.8319,
    '960_683': 40.8709,
    '960_684': 40.9158,
    '960_685': 40.9727,
    '960_686': 41.021,
    '960_687': 41.086,
    '960_688': 41.1447,
    '960_689': 41.216,
    '960_690': 41.2952,
    '960_691': 41.3882,
    '960_692': 41.5073,
    '960_693': 41.6477,
    '960_694': 41.7871,
    '960_695': 41.9307,
    '960_696': 42.0673,
    '960_697': 42.2044,
    '960_698': 42.3393,
    '960_699': 42.4666,
    '960_700': 42.5795,
    '960_701': 42.6874,
    '960_702': 42.7842,
    '960_703': 42.8659,
    '960_704': 42.9388,
    '960_705': 42.9906,
    '960_706': 43.0421,
    '960_707': 43.0636,
    '960_708': 43.0818,
    '960_709': 43.111,
    '960_710': 43.1179,
    '960_711': 43.1349,
    '960_712': 43.1341,
    '960_713': 43.131,
    '960_714': 43.1184,
    '960_715': 43.0936,
    '960_716': 43.0721,
    '960_717': 43.0482,
    '960_718': 43.0176,
    '960_719': 43.0021,
    '960_720': 43.0013,
    '960_721': 43.0345,
    '960_722': 43.0887,
    '960_723': 43.1402,
    '960_724': 43.1903,
    '960_725': 43.2377,
    '960_726': 43.2926,
    '960_727': 43.3519,
    '960_728': 43.4141,
    '960_729': 43.4845,
    '960_730': 43.5675,
    '960_731': 43.6722,
    '960_732': 43.7908,
    '960_733': 43.8999,
    '960_734': 43.961,
    '960_735': 43.9715,
    '960_736': 44.0013,
    '960_737': 43.9766,
    '960_738': 43.9397,
    '960_739': 43.9187,
    '960_740': 43.922,
    '960_741': 43.9467,
    '960_742': 43.9709,
    '960_743': 44.0174,
    '960_744': 44.0539,
    '960_745': 44.0752,
    '960_746': 44.0735,
    '960_747': 44.0436,
    '960_748': 44.0124,
    '960_749': 43.9616,
    '960_750': 43.8915,
    '960_751': 43.8096,
    '960_752': 43.7149,
    '960_753': 43.6115,
    '960_754': 43.4881,
    '960_755': 43.348,
    '960_756': 43.1866,
    '960_757': 43.0221,
    '960_758': 42.8534,
    '960_759': 42.6893,
    '960_760': 42.5455,
    '960_761': 42.4148,
    '960_762': 42.3085,
    '960_763': 42.219,
    '960_764': 42.1552,
    '960_765': 42.1111,
    '960_766': 42.0541,
    '960_767': 41.9531,
    '960_768': 41.8099,
    '960_769': 41.6331,
    '960_770': 41.4508,
    '960_771': 41.2589,
    '960_772': 41.0666,
    '960_773': 40.8723,
    '960_774': 40.7038,
    '960_775': 40.5531,
    '960_776': 40.4126,
    '960_777': 40.2776,
    '960_778': 40.1525,
    '960_779': 40.0367,
    '960_780': 39.9268,
    '960_781': 39.8237,
    '960_782': 39.7296,
    '960_783': 39.6449,
    '960_784': 39.5672,
    '960_785': 39.4968,
    '960_786': 39.4368,
    '960_787': 39.3884,
    '960_788': 39.3504,
    '960_789': 39.3214,
    '960_790': 39.3005,
    '960_791': 39.2914,
    '960_792': 39.2944,
    '960_793': 39.3075,
    '960_794': 39.3263,
    '960_795': 39.3491,
    '960_796': 39.3708,
    '960_797': 39.3887,
    '960_798': 39.398,
    '960_799': 39.3962,
    '960_800': 39.3824,
    '960_801': 39.3604,
    '960_802': 39.3295,
    '960_803': 39.2854,
    '960_804': 39.2311,
    '960_805': 39.1644,
    '960_806': 39.0856,
    '960_807': 38.995,
    '960_808': 38.8945,
    '960_809': 38.7847,
    '960_810': 38.6641,
    '960_811': 38.5359,
    '960_812': 38.4011,
    '960_813': 38.257,
    '960_814': 38.1037,
    '960_815': 37.9394,
    '960_816': 37.7618,
    '960_817': 37.5784,
    '960_818': 37.3897,
    '960_819': 37.1961,
    '960_820': 36.9969,
    '960_821': 36.7892,
    '960_822': 36.5727,
    '960_823': 36.3464,
    '960_824': 36.1122,
    '960_825': 35.8658,
    '960_826': 35.6066,
    '960_827': 35.3387,
    '960_828': 35.068,
    '960_829': 34.795,
    '960_830': 34.5207,
    '960_831': 34.2485,
    '960_832': 33.973,
    '960_833': 33.6937,
    '960_834': 33.4085,
    '960_835': 33.1177,
    '960_836': 32.8272,
    '960_837': 32.5284,
    '960_838': 32.2208,
    '960_839': 31.8996,
    '960_840': 31.5676,
    '960_841': 31.219,
    '960_842': 30.875,
    '960_843': 30.5316,
    '960_844': 30.1927,
    '960_845': 29.8556,
    '960_846': 29.5107,
    '960_847': 29.1608,
    '960_848': 28.8055,
    '961_516': 33.4277,
    '961_517': 33.5164,
    '961_518': 33.6031,
    '961_519': 33.6879,
    '961_520': 33.7662,
    '961_521': 33.8339,
    '961_522': 33.874,
    '961_523': 33.9029,
    '961_524': 33.9212,
    '961_525': 33.9318,
    '961_526': 33.9322,
    '961_527': 33.9363,
    '961_528': 33.9461,
    '961_560': 33.8858,
    '961_561': 33.8777,
    '961_562': 33.8727,
    '961_563': 33.8673,
    '961_564': 33.8629,
    '961_565': 33.8653,
    '961_566': 33.87,
    '961_567': 33.8738,
    '961_568': 33.8779,
    '961_569': 33.883,
    '961_570': 33.8938,
    '961_571': 33.9151,
    '961_572': 33.9455,
    '961_573': 33.9831,
    '961_574': 34.0238,
    '961_575': 34.0778,
    '961_576': 34.1265,
    '961_577': 34.1764,
    '961_578': 34.2224,
    '961_579': 34.2559,
    '961_580': 34.2972,
    '961_581': 34.3414,
    '961_582': 34.385,
    '961_583': 34.4271,
    '961_584': 34.4717,
    '961_585': 34.522,
    '961_586': 34.5784,
    '961_587': 34.64,
    '961_588': 34.7024,
    '961_589': 34.7635,
    '961_590': 34.8209,
    '961_591': 34.8717,
    '961_592': 34.9104,
    '961_593': 34.9545,
    '961_594': 34.9938,
    '961_595': 35.0168,
    '961_596': 35.0338,
    '961_597': 35.0456,
    '961_598': 35.0559,
    '961_599': 35.074,
    '961_600': 35.1008,
    '961_601': 35.1261,
    '961_602': 35.1519,
    '961_603': 35.1758,
    '961_604': 35.2005,
    '961_605': 35.2247,
    '961_606': 35.2487,
    '961_607': 35.2713,
    '961_608': 35.2873,
    '961_609': 35.2982,
    '961_610': 35.3103,
    '961_611': 35.3256,
    '961_612': 35.348,
    '961_613': 35.3693,
    '961_614': 35.3894,
    '961_615': 35.3989,
    '961_616': 35.3939,
    '961_617': 35.3849,
    '961_618': 35.3815,
    '961_619': 35.3814,
    '961_620': 35.3827,
    '961_621': 35.387,
    '961_622': 35.3968,
    '961_623': 35.4063,
    '961_624': 35.4158,
    '961_625': 35.4252,
    '961_626': 35.4292,
    '961_627': 35.4382,
    '961_628': 35.4599,
    '961_629': 35.4948,
    '961_630': 35.5349,
    '961_631': 35.5814,
    '961_632': 35.6429,
    '961_633': 35.725,
    '961_634': 35.8278,
    '961_635': 35.9433,
    '961_636': 36.0667,
    '961_637': 36.2006,
    '961_638': 36.3458,
    '961_639': 36.5034,
    '961_640': 36.6721,
    '961_641': 36.8192,
    '961_642': 36.9203,
    '961_643': 36.9977,
    '961_644': 37.067,
    '961_645': 37.1363,
    '961_646': 37.21,
    '961_647': 37.2953,
    '961_648': 37.3885,
    '961_649': 37.4875,
    '961_650': 37.5967,
    '961_651': 37.721,
    '961_652': 37.8559,
    '961_653': 37.9862,
    '961_654': 38.1087,
    '961_655': 38.2211,
    '961_656': 38.32,
    '961_657': 38.4234,
    '961_658': 38.5243,
    '961_659': 38.6196,
    '961_660': 38.7225,
    '961_661': 38.8563,
    '961_662': 39.0317,
    '961_663': 39.2273,
    '961_664': 39.437,
    '961_665': 39.621,
    '961_666': 39.7714,
    '961_667': 39.9058,
    '961_668': 40.043,
    '961_669': 40.159,
    '961_670': 40.2583,
    '961_671': 40.346,
    '961_672': 40.4207,
    '961_673': 40.4747,
    '961_674': 40.5145,
    '961_675': 40.5368,
    '961_676': 40.5615,
    '961_677': 40.5939,
    '961_678': 40.6323,
    '961_679': 40.6812,
    '961_680': 40.7344,
    '961_681': 40.7903,
    '961_682': 40.842,
    '961_683': 40.887,
    '961_684': 40.9345,
    '961_685': 40.9892,
    '961_686': 41.0462,
    '961_687': 41.1072,
    '961_688': 41.1664,
    '961_689': 41.229,
    '961_690': 41.3007,
    '961_691': 41.389,
    '961_692': 41.5016,
    '961_693': 41.6353,
    '961_694': 41.7689,
    '961_695': 41.9061,
    '961_696': 42.0398,
    '961_697': 42.1749,
    '961_698': 42.3154,
    '961_699': 42.4438,
    '961_700': 42.5688,
    '961_701': 42.6801,
    '961_702': 42.783,
    '961_703': 42.8698,
    '961_704': 42.9359,
    '961_705': 42.9908,
    '961_706': 43.0294,
    '961_707': 43.0541,
    '961_708': 43.0773,
    '961_709': 43.1063,
    '961_710': 43.1201,
    '961_711': 43.1365,
    '961_712': 43.1219,
    '961_713': 43.1091,
    '961_714': 43.0815,
    '961_715': 43.0489,
    '961_716': 43.0189,
    '961_717': 42.9934,
    '961_718': 42.9724,
    '961_719': 42.9602,
    '961_720': 42.9606,
    '961_721': 42.983,
    '961_722': 43.0217,
    '961_723': 43.067,
    '961_724': 43.1104,
    '961_725': 43.1774,
    '961_726': 43.2593,
    '961_727': 43.3324,
    '961_728': 43.3971,
    '961_729': 43.4702,
    '961_730': 43.557,
    '961_731': 43.6641,
    '961_732': 43.7817,
    '961_733': 43.8898,
    '961_734': 43.9355,
    '961_735': 43.9752,
    '961_736': 43.9815,
    '961_737': 43.9467,
    '961_738': 43.9088,
    '961_739': 43.89,
    '961_740': 43.8931,
    '961_741': 43.9119,
    '961_742': 43.9515,
    '961_743': 44.0001,
    '961_744': 44.034,
    '961_745': 44.0454,
    '961_746': 44.0336,
    '961_747': 44.0091,
    '961_748': 43.9708,
    '961_749': 43.9228,
    '961_750': 43.85,
    '961_751': 43.781,
    '961_752': 43.6922,
    '961_753': 43.5915,
    '961_754': 43.4688,
    '961_755': 43.3287,
    '961_756': 43.1645,
    '961_757': 42.9991,
    '961_758': 42.8356,
    '961_759': 42.677,
    '961_760': 42.5328,
    '961_761': 42.407,
    '961_762': 42.2975,
    '961_763': 42.2062,
    '961_764': 42.1354,
    '961_765': 42.0879,
    '961_766': 42.0342,
    '961_767': 41.9393,
    '961_768': 41.8081,
    '961_769': 41.6438,
    '961_770': 41.4794,
    '961_771': 41.2957,
    '961_772': 41.116,
    '961_773': 40.9339,
    '961_774': 40.776,
    '961_775': 40.6364,
    '961_776': 40.5016,
    '961_777': 40.3684,
    '961_778': 40.2444,
    '961_779': 40.1295,
    '961_780': 40.0168,
    '961_781': 39.912,
    '961_782': 39.8172,
    '961_783': 39.733,
    '961_784': 39.6582,
    '961_785': 39.5928,
    '961_786': 39.5387,
    '961_787': 39.4958,
    '961_788': 39.4635,
    '961_789': 39.4403,
    '961_790': 39.4256,
    '961_791': 39.4223,
    '961_792': 39.4303,
    '961_793': 39.4485,
    '961_794': 39.4741,
    '961_795': 39.5045,
    '961_796': 39.5342,
    '961_797': 39.5574,
    '961_798': 39.5692,
    '961_799': 39.5702,
    '961_800': 39.5605,
    '961_801': 39.5418,
    '961_802': 39.5127,
    '961_803': 39.468,
    '961_804': 39.4131,
    '961_805': 39.3447,
    '961_806': 39.2651,
    '961_807': 39.1712,
    '961_808': 39.0671,
    '961_809': 38.9537,
    '961_810': 38.8305,
    '961_811': 38.6989,
    '961_812': 38.5622,
    '961_813': 38.4173,
    '961_814': 38.2634,
    '961_815': 38.0968,
    '961_816': 37.9202,
    '961_817': 37.7401,
    '961_818': 37.5555,
    '961_819': 37.366,
    '961_820': 37.1698,
    '961_821': 36.9642,
    '961_822': 36.7485,
    '961_823': 36.5192,
    '961_824': 36.2812,
    '961_825': 36.0272,
    '961_826': 35.7603,
    '961_827': 35.4874,
    '961_828': 35.2116,
    '961_829': 34.9342,
    '961_830': 34.6558,
    '961_831': 34.3787,
    '961_832': 34.099,
    '961_833': 33.8145,
    '961_834': 33.5209,
    '961_835': 33.218,
    '961_836': 32.9366,
    '961_837': 32.6383,
    '961_838': 32.3299,
    '961_839': 32.0058,
    '961_840': 31.6738,
    '961_841': 31.3301,
    '961_842': 30.9863,
    '961_843': 30.6523,
    '961_844': 30.3156,
    '961_845': 29.9802,
    '961_846': 29.6396,
    '961_847': 29.2889,
    '961_848': 28.9361,
    '962_516': 33.4235,
    '962_517': 33.5121,
    '962_518': 33.6021,
    '962_519': 33.6896,
    '962_520': 33.7687,
    '962_521': 33.8308,
    '962_522': 33.8723,
    '962_523': 33.9015,
    '962_524': 33.9162,
    '962_525': 33.9222,
    '962_526': 33.9221,
    '962_527': 33.9265,
    '962_528': 33.9347,
    '962_560': 33.8491,
    '962_561': 33.8348,
    '962_562': 33.822,
    '962_563': 33.8068,
    '962_564': 33.8005,
    '962_565': 33.8007,
    '962_566': 33.8038,
    '962_567': 33.808,
    '962_568': 33.8128,
    '962_569': 33.8171,
    '962_570': 33.8278,
    '962_571': 33.8508,
    '962_572': 33.8823,
    '962_573': 33.9199,
    '962_574': 33.9585,
    '962_575': 34.0092,
    '962_576': 34.0549,
    '962_577': 34.1016,
    '962_578': 34.1441,
    '962_579': 34.183,
    '962_580': 34.2305,
    '962_581': 34.2811,
    '962_582': 34.3302,
    '962_583': 34.3754,
    '962_584': 34.4239,
    '962_585': 34.4801,
    '962_586': 34.541,
    '962_587': 34.6029,
    '962_588': 34.6645,
    '962_589': 34.7246,
    '962_590': 34.7815,
    '962_591': 34.8319,
    '962_592': 34.8585,
    '962_593': 34.906,
    '962_594': 34.9474,
    '962_595': 34.9764,
    '962_596': 34.9973,
    '962_597': 35.0134,
    '962_598': 35.0264,
    '962_599': 35.0493,
    '962_600': 35.075,
    '962_601': 35.0962,
    '962_602': 35.1185,
    '962_603': 35.1411,
    '962_604': 35.1648,
    '962_605': 35.188,
    '962_606': 35.2112,
    '962_607': 35.2314,
    '962_608': 35.2418,
    '962_609': 35.2513,
    '962_610': 35.2652,
    '962_611': 35.2808,
    '962_612': 35.3015,
    '962_613': 35.3224,
    '962_614': 35.3464,
    '962_615': 35.3564,
    '962_616': 35.3485,
    '962_617': 35.3396,
    '962_618': 35.3391,
    '962_619': 35.3431,
    '962_620': 35.3481,
    '962_621': 35.3558,
    '962_622': 35.3645,
    '962_623': 35.3725,
    '962_624': 35.3806,
    '962_625': 35.3895,
    '962_626': 35.3962,
    '962_627': 35.4109,
    '962_628': 35.4368,
    '962_629': 35.4733,
    '962_630': 35.5164,
    '962_631': 35.563,
    '962_632': 35.6298,
    '962_633': 35.7217,
    '962_634': 35.8262,
    '962_635': 35.9398,
    '962_636': 36.0559,
    '962_637': 36.1839,
    '962_638': 36.322,
    '962_639': 36.4689,
    '962_640': 36.6266,
    '962_641': 36.7896,
    '962_642': 36.9071,
    '962_643': 37.0018,
    '962_644': 37.0687,
    '962_645': 37.1304,
    '962_646': 37.1994,
    '962_647': 37.2811,
    '962_648': 37.3701,
    '962_649': 37.465,
    '962_650': 37.5731,
    '962_651': 37.6996,
    '962_652': 37.8432,
    '962_653': 37.9816,
    '962_654': 38.1116,
    '962_655': 38.2289,
    '962_656': 38.3245,
    '962_657': 38.4241,
    '962_658': 38.5183,
    '962_659': 38.6142,
    '962_660': 38.7226,
    '962_661': 38.8687,
    '962_662': 39.058,
    '962_663': 39.2641,
    '962_664': 39.4729,
    '962_665': 39.6579,
    '962_666': 39.8157,
    '962_667': 39.9455,
    '962_668': 40.0734,
    '962_669': 40.1938,
    '962_670': 40.3033,
    '962_671': 40.3977,
    '962_672': 40.4595,
    '962_673': 40.5184,
    '962_674': 40.5485,
    '962_675': 40.5681,
    '962_676': 40.5878,
    '962_677': 40.6111,
    '962_678': 40.6406,
    '962_679': 40.6817,
    '962_680': 40.7346,
    '962_681': 40.7918,
    '962_682': 40.8455,
    '962_683': 40.8987,
    '962_684': 40.9472,
    '962_685': 40.9975,
    '962_686': 41.0574,
    '962_687': 41.1097,
    '962_688': 41.1676,
    '962_689': 41.2349,
    '962_690': 41.2978,
    '962_691': 41.3799,
    '962_692': 41.4889,
    '962_693': 41.6223,
    '962_694': 41.7539,
    '962_695': 41.8809,
    '962_696': 42.0156,
    '962_697': 42.1503,
    '962_698': 42.298,
    '962_699': 42.436,
    '962_700': 42.5687,
    '962_701': 42.6917,
    '962_702': 42.8028,
    '962_703': 42.8824,
    '962_704': 42.9411,
    '962_705': 43.0029,
    '962_706': 43.0332,
    '962_707': 43.0548,
    '962_708': 43.0785,
    '962_709': 43.0974,
    '962_710': 43.1038,
    '962_711': 43.1307,
    '962_712': 43.1118,
    '962_713': 43.082,
    '962_714': 43.0432,
    '962_715': 43.0005,
    '962_716': 42.9648,
    '962_717': 42.938,
    '962_718': 42.922,
    '962_719': 42.9134,
    '962_720': 42.9152,
    '962_721': 42.9321,
    '962_722': 42.9607,
    '962_723': 43.0006,
    '962_724': 43.0517,
    '962_725': 43.1494,
    '962_726': 43.2462,
    '962_727': 43.3302,
    '962_728': 43.3974,
    '962_729': 43.471,
    '962_730': 43.557,
    '962_731': 43.6606,
    '962_732': 43.7702,
    '962_733': 43.8683,
    '962_734': 43.9082,
    '962_735': 43.9435,
    '962_736': 43.9506,
    '962_737': 43.9034,
    '962_738': 43.8699,
    '962_739': 43.8549,
    '962_740': 43.8536,
    '962_741': 43.8776,
    '962_742': 43.9196,
    '962_743': 43.9621,
    '962_744': 43.9887,
    '962_745': 44.0056,
    '962_746': 44.002,
    '962_747': 43.9673,
    '962_748': 43.9197,
    '962_749': 43.8681,
    '962_750': 43.8104,
    '962_751': 43.7429,
    '962_752': 43.663,
    '962_753': 43.567,
    '962_754': 43.4524,
    '962_755': 43.3079,
    '962_756': 43.1483,
    '962_757': 42.9926,
    '962_758': 42.8419,
    '962_759': 42.6887,
    '962_760': 42.5485,
    '962_761': 42.4209,
    '962_762': 42.3065,
    '962_763': 42.2094,
    '962_764': 42.1319,
    '962_765': 42.0802,
    '962_766': 42.0212,
    '962_767': 41.9236,
    '962_768': 41.7937,
    '962_769': 41.6396,
    '962_770': 41.4878,
    '962_771': 41.3267,
    '962_772': 41.159,
    '962_773': 40.9922,
    '962_774': 40.8474,
    '962_775': 40.7147,
    '962_776': 40.581,
    '962_777': 40.4533,
    '962_778': 40.3349,
    '962_779': 40.2179,
    '962_780': 40.0991,
    '962_781': 39.9917,
    '962_782': 39.8981,
    '962_783': 39.8172,
    '962_784': 39.747,
    '962_785': 39.687,
    '962_786': 39.64,
    '962_787': 39.6033,
    '962_788': 39.5777,
    '962_789': 39.561,
    '962_790': 39.5524,
    '962_791': 39.5537,
    '962_792': 39.5661,
    '962_793': 39.5884,
    '962_794': 39.6199,
    '962_795': 39.6573,
    '962_796': 39.6936,
    '962_797': 39.7208,
    '962_798': 39.7344,
    '962_799': 39.7366,
    '962_800': 39.7287,
    '962_801': 39.7108,
    '962_802': 39.6806,
    '962_803': 39.6341,
    '962_804': 39.5769,
    '962_805': 39.5078,
    '962_806': 39.4274,
    '962_807': 39.3347,
    '962_808': 39.23,
    '962_809': 39.1151,
    '962_810': 38.9906,
    '962_811': 38.8572,
    '962_812': 38.7187,
    '962_813': 38.5733,
    '962_814': 38.4202,
    '962_815': 38.2527,
    '962_816': 38.0758,
    '962_817': 37.9001,
    '962_818': 37.7201,
    '962_819': 37.5341,
    '962_820': 37.3391,
    '962_821': 37.1336,
    '962_822': 36.9157,
    '962_823': 36.6848,
    '962_824': 36.4429,
    '962_825': 36.1819,
    '962_826': 35.9078,
    '962_827': 35.6306,
    '962_828': 35.3514,
    '962_829': 35.0709,
    '962_830': 34.7894,
    '962_831': 34.5079,
    '962_832': 34.2238,
    '962_833': 33.9354,
    '962_834': 33.6417,
    '962_835': 33.3428,
    '962_836': 33.0504,
    '962_837': 32.7478,
    '962_838': 32.4347,
    '962_839': 32.1113,
    '962_840': 31.7832,
    '962_841': 31.4426,
    '962_842': 31.1023,
    '962_843': 30.7672,
    '962_844': 30.4362,
    '962_845': 30.1045,
    '962_846': 29.7679,
    '962_847': 29.4208,
    '962_848': 29.0693,
    '963_516': 33.4208,
    '963_517': 33.5116,
    '963_518': 33.6039,
    '963_519': 33.6949,
    '963_520': 33.7711,
    '963_521': 33.8285,
    '963_522': 33.8714,
    '963_523': 33.9012,
    '963_524': 33.9155,
    '963_525': 33.9176,
    '963_526': 33.9185,
    '963_527': 33.922,
    '963_528': 33.9246,
    '963_560': 33.8141,
    '963_561': 33.7932,
    '963_562': 33.7734,
    '963_563': 33.752,
    '963_564': 33.7422,
    '963_565': 33.7394,
    '963_566': 33.7405,
    '963_567': 33.7441,
    '963_568': 33.7482,
    '963_569': 33.7522,
    '963_570': 33.7645,
    '963_571': 33.7866,
    '963_572': 33.8161,
    '963_573': 33.851,
    '963_574': 33.8885,
    '963_575': 33.9371,
    '963_576': 33.9799,
    '963_577': 34.0237,
    '963_578': 34.0668,
    '963_579': 34.1108,
    '963_580': 34.1641,
    '963_581': 34.2196,
    '963_582': 34.2713,
    '963_583': 34.3179,
    '963_584': 34.37,
    '963_585': 34.4333,
    '963_586': 34.4998,
    '963_587': 34.563,
    '963_588': 34.6253,
    '963_589': 34.6855,
    '963_590': 34.7422,
    '963_591': 34.7935,
    '963_592': 34.8366,
    '963_593': 34.8777,
    '963_594': 34.914,
    '963_595': 34.9422,
    '963_596': 34.9653,
    '963_597': 34.9903,
    '963_598': 35.0129,
    '963_599': 35.0277,
    '963_600': 35.0446,
    '963_601': 35.0624,
    '963_602': 35.0849,
    '963_603': 35.1082,
    '963_604': 35.1317,
    '963_605': 35.1536,
    '963_606': 35.1736,
    '963_607': 35.1909,
    '963_608': 35.2001,
    '963_609': 35.2108,
    '963_610': 35.2243,
    '963_611': 35.2407,
    '963_612': 35.263,
    '963_613': 35.2864,
    '963_614': 35.3067,
    '963_615': 35.3077,
    '963_616': 35.2997,
    '963_617': 35.2947,
    '963_618': 35.2975,
    '963_619': 35.3038,
    '963_620': 35.3119,
    '963_621': 35.3234,
    '963_622': 35.331,
    '963_623': 35.3365,
    '963_624': 35.343,
    '963_625': 35.3474,
    '963_626': 35.3568,
    '963_627': 35.3776,
    '963_628': 35.407,
    '963_629': 35.4435,
    '963_630': 35.4844,
    '963_631': 35.5342,
    '963_632': 35.6093,
    '963_633': 35.7147,
    '963_634': 35.818,
    '963_635': 35.9259,
    '963_636': 36.0382,
    '963_637': 36.1643,
    '963_638': 36.2974,
    '963_639': 36.4379,
    '963_640': 36.589,
    '963_641': 36.7537,
    '963_642': 36.8995,
    '963_643': 37.0087,
    '963_644': 37.0798,
    '963_645': 37.1327,
    '963_646': 37.1919,
    '963_647': 37.2666,
    '963_648': 37.3532,
    '963_649': 37.4465,
    '963_650': 37.5541,
    '963_651': 37.6794,
    '963_652': 37.825,
    '963_653': 37.9698,
    '963_654': 38.0986,
    '963_655': 38.2191,
    '963_656': 38.3164,
    '963_657': 38.4119,
    '963_658': 38.5062,
    '963_659': 38.6031,
    '963_660': 38.7252,
    '963_661': 38.8878,
    '963_662': 39.0855,
    '963_663': 39.2951,
    '963_664': 39.5073,
    '963_665': 39.686,
    '963_666': 39.8489,
    '963_667': 39.9795,
    '963_668': 40.107,
    '963_669': 40.2301,
    '963_670': 40.3423,
    '963_671': 40.4363,
    '963_672': 40.5008,
    '963_673': 40.5529,
    '963_674': 40.5804,
    '963_675': 40.5919,
    '963_676': 40.6082,
    '963_677': 40.6244,
    '963_678': 40.6462,
    '963_679': 40.6841,
    '963_680': 40.7382,
    '963_681': 40.7951,
    '963_682': 40.8528,
    '963_683': 40.9026,
    '963_684': 40.9504,
    '963_685': 40.9951,
    '963_686': 41.0461,
    '963_687': 41.1037,
    '963_688': 41.1587,
    '963_689': 41.2213,
    '963_690': 41.2861,
    '963_691': 41.3653,
    '963_692': 41.4736,
    '963_693': 41.6013,
    '963_694': 41.7311,
    '963_695': 41.8612,
    '963_696': 41.9944,
    '963_697': 42.1365,
    '963_698': 42.2874,
    '963_699': 42.4363,
    '963_700': 42.5844,
    '963_701': 42.7122,
    '963_702': 42.8252,
    '963_703': 42.9115,
    '963_704': 42.9579,
    '963_705': 42.9968,
    '963_706': 43.015,
    '963_707': 43.0385,
    '963_708': 43.0697,
    '963_709': 43.0858,
    '963_710': 43.1004,
    '963_711': 43.1203,
    '963_712': 43.0838,
    '963_713': 43.0435,
    '963_714': 43.0078,
    '963_715': 42.9492,
    '963_716': 42.9099,
    '963_717': 42.8817,
    '963_718': 42.8682,
    '963_719': 42.8645,
    '963_720': 42.8678,
    '963_721': 42.8841,
    '963_722': 42.9116,
    '963_723': 42.9566,
    '963_724': 43.0308,
    '963_725': 43.142,
    '963_726': 43.2448,
    '963_727': 43.3384,
    '963_728': 43.4104,
    '963_729': 43.4833,
    '963_730': 43.5657,
    '963_731': 43.6622,
    '963_732': 43.7638,
    '963_733': 43.8437,
    '963_734': 43.8828,
    '963_735': 43.9026,
    '963_736': 43.895,
    '963_737': 43.8587,
    '963_738': 43.8244,
    '963_739': 43.8097,
    '963_740': 43.8105,
    '963_741': 43.8403,
    '963_742': 43.8858,
    '963_743': 43.9371,
    '963_744': 43.9691,
    '963_745': 43.9789,
    '963_746': 43.9604,
    '963_747': 43.9196,
    '963_748': 43.8676,
    '963_749': 43.82,
    '963_750': 43.7702,
    '963_751': 43.7073,
    '963_752': 43.6349,
    '963_753': 43.5432,
    '963_754': 43.4306,
    '963_755': 43.2931,
    '963_756': 43.15,
    '963_757': 43.0081,
    '963_758': 42.8625,
    '963_759': 42.7193,
    '963_760': 42.5892,
    '963_761': 42.4617,
    '963_762': 42.3357,
    '963_763': 42.2285,
    '963_764': 42.1461,
    '963_765': 42.0882,
    '963_766': 42.0156,
    '963_767': 41.91,
    '963_768': 41.7773,
    '963_769': 41.6348,
    '963_770': 41.4904,
    '963_771': 41.345,
    '963_772': 41.1952,
    '963_773': 41.0438,
    '963_774': 40.9078,
    '963_775': 40.7742,
    '963_776': 40.6462,
    '963_777': 40.5292,
    '963_778': 40.4104,
    '963_779': 40.2833,
    '963_780': 40.1658,
    '963_781': 40.0628,
    '963_782': 39.9752,
    '963_783': 39.9008,
    '963_784': 39.838,
    '963_785': 39.7858,
    '963_786': 39.7454,
    '963_787': 39.716,
    '963_788': 39.6968,
    '963_789': 39.6852,
    '963_790': 39.6805,
    '963_791': 39.6847,
    '963_792': 39.7011,
    '963_793': 39.7273,
    '963_794': 39.7626,
    '963_795': 39.8051,
    '963_796': 39.8455,
    '963_797': 39.8758,
    '963_798': 39.8906,
    '963_799': 39.8931,
    '963_800': 39.8843,
    '963_801': 39.8646,
    '963_802': 39.8329,
    '963_803': 39.7863,
    '963_804': 39.7285,
    '963_805': 39.6596,
    '963_806': 39.5797,
    '963_807': 39.488,
    '963_808': 39.3848,
    '963_809': 39.2707,
    '963_810': 39.1476,
    '963_811': 39.016,
    '963_812': 38.8773,
    '963_813': 38.7312,
    '963_814': 38.5775,
    '963_815': 38.4124,
    '963_816': 38.2384,
    '963_817': 38.0622,
    '963_818': 37.884,
    '963_819': 37.7001,
    '963_820': 37.5056,
    '963_821': 37.2983,
    '963_822': 37.0767,
    '963_823': 36.8415,
    '963_824': 36.5929,
    '963_825': 36.3241,
    '963_826': 36.0465,
    '963_827': 35.7669,
    '963_828': 35.4855,
    '963_829': 35.2033,
    '963_830': 34.9197,
    '963_831': 34.6344,
    '963_832': 34.347,
    '963_833': 34.0559,
    '963_834': 33.7645,
    '963_835': 33.4655,
    '963_836': 33.1671,
    '963_837': 32.8577,
    '963_838': 32.5408,
    '963_839': 32.2181,
    '963_840': 31.8975,
    '963_841': 31.5605,
    '963_842': 31.223,
    '963_843': 30.8906,
    '963_844': 30.5609,
    '963_845': 30.2306,
    '963_846': 29.8972,
    '963_847': 29.5538,
    '963_848': 29.2044,
    '964_516': 33.416,
    '964_517': 33.5069,
    '964_518': 33.6008,
    '964_519': 33.6917,
    '964_520': 33.7621,
    '964_521': 33.8291,
    '964_522': 33.8712,
    '964_523': 33.894,
    '964_524': 33.9113,
    '964_525': 33.912,
    '964_526': 33.9116,
    '964_527': 33.9118,
    '964_528': 33.92,
    '964_612': 35.227,
    '964_613': 35.2466,
    '964_614': 35.2551,
    '964_615': 35.2531,
    '964_616': 35.2478,
    '964_617': 35.2498,
    '964_618': 35.2555,
    '964_619': 35.2634,
    '964_620': 35.272,
    '964_621': 35.2825,
    '964_622': 35.2891,
    '964_623': 35.2922,
    '964_624': 35.2941,
    '964_625': 35.3007,
    '964_626': 35.317,
    '964_627': 35.3431,
    '964_628': 35.3769,
    '964_629': 35.4152,
    '964_630': 35.455,
    '964_631': 35.508,
    '964_632': 35.5986,
    '964_633': 35.7068,
    '964_634': 35.8055,
    '964_635': 35.9115,
    '964_636': 36.0244,
    '964_637': 36.1459,
    '964_638': 36.2711,
    '964_639': 36.4044,
    '964_640': 36.5505,
    '964_641': 36.7115,
    '964_642': 36.8729,
    '964_643': 36.996,
    '964_644': 37.076,
    '964_645': 37.1341,
    '964_646': 37.1892,
    '964_647': 37.2515,
    '964_648': 37.3302,
    '964_649': 37.425,
    '964_650': 37.5334,
    '964_651': 37.6575,
    '964_652': 37.7983,
    '964_653': 37.9394,
    '964_654': 38.0703,
    '964_655': 38.1859,
    '964_656': 38.2841,
    '964_657': 38.3859,
    '964_658': 38.4906,
    '964_659': 38.5976,
    '964_660': 38.7313,
    '964_661': 38.9033,
    '964_662': 39.1039,
    '964_663': 39.3099,
    '964_664': 39.5154,
    '964_665': 39.7116,
    '964_666': 39.8674,
    '964_667': 39.9997,
    '964_668': 40.1333,
    '964_669': 40.2691,
    '964_670': 40.3889,
    '964_671': 40.483,
    '964_672': 40.5554,
    '964_673': 40.5931,
    '964_674': 40.6092,
    '964_675': 40.6221,
    '964_676': 40.6274,
    '964_677': 40.6302,
    '964_678': 40.6478,
    '964_679': 40.69,
    '964_680': 40.7475,
    '964_681': 40.8062,
    '964_682': 40.8587,
    '964_683': 40.9073,
    '964_684': 40.9493,
    '964_685': 40.9854,
    '964_686': 41.0278,
    '964_687': 41.081,
    '964_688': 41.1401,
    '964_689': 41.1977,
    '964_690': 41.2638,
    '964_691': 41.3505,
    '964_692': 41.4596,
    '964_693': 41.5826,
    '964_694': 41.708,
    '964_695': 41.84,
    '964_696': 41.9784,
    '964_697': 42.1303,
    '964_698': 42.2866,
    '964_699': 42.442,
    '964_700': 42.6011,
    '964_701': 42.732,
    '964_702': 42.8476,
    '964_703': 42.9282,
    '964_704': 42.9609,
    '964_705': 42.9884,
    '964_706': 43.0101,
    '964_707': 43.0143,
    '964_708': 43.0502,
    '964_709': 43.0562,
    '964_710': 43.0791,
    '964_711': 43.0843,
    '964_712': 43.0458,
    '964_713': 43.0071,
    '964_714': 42.9481,
    '964_715': 42.8908,
    '964_716': 42.8423,
    '964_717': 42.819,
    '964_718': 42.8087,
    '964_719': 42.8109,
    '964_720': 42.8204,
    '964_721': 42.843,
    '964_722': 42.8797,
    '964_723': 42.9383,
    '964_724': 43.0338,
    '964_725': 43.1471,
    '964_726': 43.2511,
    '964_727': 43.3437,
    '964_728': 43.4202,
    '964_729': 43.4956,
    '964_730': 43.5739,
    '964_731': 43.6649,
    '964_732': 43.7564,
    '964_733': 43.8039,
    '964_734': 43.8466,
    '964_735': 43.8644,
    '964_736': 43.8348,
    '964_737': 43.7976,
    '964_738': 43.7688,
    '964_739': 43.7592,
    '964_740': 43.7673,
    '964_741': 43.8008,
    '964_742': 43.8507,
    '964_743': 43.8987,
    '964_744': 43.9384,
    '964_745': 43.9434,
    '964_746': 43.9145,
    '964_747': 43.872,
    '964_748': 43.8179,
    '964_749': 43.7679,
    '964_750': 43.7206,
    '964_751': 43.6602,
    '964_752': 43.5884,
    '964_753': 43.5071,
    '964_754': 43.4032,
    '964_755': 43.2855,
    '964_756': 43.1603,
    '964_757': 43.0304,
    '964_758': 42.8946,
    '964_759': 42.7631,
    '964_760': 42.6389,
    '964_761': 42.511,
    '964_762': 42.3763,
    '964_763': 42.2566,
    '964_764': 42.1621,
    '964_765': 42.0913,
    '964_766': 42.0142,
    '964_767': 41.903,
    '964_768': 41.7705,
    '964_769': 41.6315,
    '964_770': 41.4909,
    '964_771': 41.3528,
    '964_772': 41.2114,
    '964_773': 41.0736,
    '964_774': 40.9395,
    '964_775': 40.8091,
    '964_776': 40.685,
    '964_777': 40.5687,
    '964_778': 40.4505,
    '964_779': 40.329,
    '964_780': 40.2208,
    '964_781': 40.1286,
    '964_782': 40.0496,
    '964_783': 39.983,
    '964_784': 39.9288,
    '964_785': 39.885,
    '964_786': 39.8524,
    '964_787': 39.8309,
    '964_788': 39.8179,
    '964_789': 39.8099,
    '964_790': 39.808,
    '964_791': 39.8146,
    '964_792': 39.8322,
    '964_793': 39.861,
    '964_794': 39.8998,
    '964_795': 39.9444,
    '964_796': 39.9864,
    '964_797': 40.0163,
    '964_798': 40.0319,
    '964_799': 40.0346,
    '964_800': 40.0258,
    '964_801': 40.0054,
    '964_802': 39.9727,
    '964_803': 39.9268,
    '964_804': 39.8697,
    '964_805': 39.802,
    '964_806': 39.7235,
    '964_807': 39.6339,
    '964_808': 39.5336,
    '964_809': 39.422,
    '964_810': 39.3014,
    '964_811': 39.1718,
    '964_812': 39.0339,
    '964_813': 38.8876,
    '964_814': 38.7345,
    '964_815': 38.5708,
    '964_816': 38.3965,
    '964_817': 38.2193,
    '964_818': 38.0417,
    '964_819': 37.8574,
    '964_820': 37.6614,
    '964_821': 37.4502,
    '964_822': 37.2235,
    '964_823': 36.9838,
    '964_824': 36.7264,
    '964_825': 36.4528,
    '964_826': 36.174,
    '964_827': 35.8928,
    '964_828': 35.6102,
    '964_829': 35.3267,
    '964_830': 35.0413,
    '964_831': 34.7535,
    '964_832': 34.4624,
    '964_833': 34.1697,
    '964_834': 33.8782,
    '964_835': 33.5773,
    '964_836': 33.2725,
    '964_837': 32.9595,
    '964_838': 32.6439,
    '964_839': 32.329,
    '964_840': 32.011,
    '964_841': 31.6765,
    '964_842': 31.3427,
    '964_843': 31.0137,
    '964_844': 30.6865,
    '964_845': 30.3584,
    '964_846': 30.0252,
    '964_847': 29.6849,
    '964_848': 29.3391,
    '965_516': 33.4064,
    '965_517': 33.4949,
    '965_518': 33.5858,
    '965_519': 33.6744,
    '965_520': 33.7466,
    '965_521': 33.8207,
    '965_522': 33.8612,
    '965_523': 33.8804,
    '965_524': 33.8978,
    '965_525': 33.9027,
    '965_526': 33.9048,
    '965_527': 33.9104,
    '965_528': 33.9208,
    '965_612': 35.1968,
    '965_613': 35.211,
    '965_614': 35.2076,
    '965_615': 35.2045,
    '965_616': 35.2046,
    '965_617': 35.2088,
    '965_618': 35.2155,
    '965_619': 35.2235,
    '965_620': 35.2295,
    '965_621': 35.2407,
    '965_622': 35.247,
    '965_623': 35.2491,
    '965_624': 35.2487,
    '965_625': 35.2586,
    '965_626': 35.2794,
    '965_627': 35.31,
    '965_628': 35.3484,
    '965_629': 35.3926,
    '965_630': 35.4363,
    '965_631': 35.5013,
    '965_632': 35.5917,
    '965_633': 35.6907,
    '965_634': 35.7893,
    '965_635': 35.8956,
    '965_636': 36.0091,
    '965_637': 36.1278,
    '965_638': 36.2436,
    '965_639': 36.3677,
    '965_640': 36.5095,
    '965_641': 36.6657,
    '965_642': 36.8305,
    '965_643': 36.9675,
    '965_644': 37.0564,
    '965_645': 37.1139,
    '965_646': 37.1753,
    '965_647': 37.2363,
    '965_648': 37.3059,
    '965_649': 37.4013,
    '965_650': 37.5119,
    '965_651': 37.635,
    '965_652': 37.7712,
    '965_653': 37.9076,
    '965_654': 38.0388,
    '965_655': 38.1566,
    '965_656': 38.2701,
    '965_657': 38.3781,
    '965_658': 38.4956,
    '965_659': 38.6102,
    '965_660': 38.7488,
    '965_661': 38.9187,
    '965_662': 39.1127,
    '965_663': 39.3178,
    '965_664': 39.5257,
    '965_665': 39.7181,
    '965_666': 39.8755,
    '965_667': 40.022,
    '965_668': 40.1557,
    '965_669': 40.2945,
    '965_670': 40.4128,
    '965_671': 40.5251,
    '965_672': 40.587,
    '965_673': 40.6179,
    '965_674': 40.6334,
    '965_675': 40.6489,
    '965_676': 40.6444,
    '965_677': 40.6405,
    '965_678': 40.6538,
    '965_679': 40.7022,
    '965_680': 40.7582,
    '965_681': 40.8154,
    '965_682': 40.8616,
    '965_683': 40.9082,
    '965_684': 40.9445,
    '965_685': 40.9764,
    '965_686': 41.011,
    '965_687': 41.057,
    '965_688': 41.1142,
    '965_689': 41.1738,
    '965_690': 41.2464,
    '965_691': 41.3375,
    '965_692': 41.4468,
    '965_693': 41.5668,
    '965_694': 41.6925,
    '965_695': 41.8246,
    '965_696': 41.9687,
    '965_697': 42.1238,
    '965_698': 42.2886,
    '965_699': 42.4597,
    '965_700': 42.6193,
    '965_701': 42.7432,
    '965_702': 42.8439,
    '965_703': 42.9234,
    '965_704': 42.9643,
    '965_705': 42.977,
    '965_706': 42.9907,
    '965_707': 43.0026,
    '965_708': 43.0233,
    '965_709': 43.0297,
    '965_710': 43.0306,
    '965_711': 43.0384,
    '965_712': 43.0093,
    '965_713': 42.9584,
    '965_714': 42.8822,
    '965_715': 42.8214,
    '965_716': 42.767,
    '965_717': 42.7426,
    '965_718': 42.7407,
    '965_719': 42.7549,
    '965_720': 42.7815,
    '965_721': 42.8221,
    '965_722': 42.8761,
    '965_723': 42.9518,
    '965_724': 43.0507,
    '965_725': 43.1566,
    '965_726': 43.2563,
    '965_727': 43.3431,
    '965_728': 43.4214,
    '965_729': 43.4979,
    '965_730': 43.5642,
    '965_731': 43.6465,
    '965_732': 43.7268,
    '965_733': 43.7451,
    '965_734': 43.7933,
    '965_735': 43.7959,
    '965_736': 43.7663,
    '965_737': 43.7289,
    '965_738': 43.7051,
    '965_739': 43.7017,
    '965_740': 43.723,
    '965_741': 43.7659,
    '965_742': 43.8201,
    '965_743': 43.8604,
    '965_744': 43.8927,
    '965_745': 43.8945,
    '965_746': 43.8749,
    '965_747': 43.8292,
    '965_748': 43.7738,
    '965_749': 43.7261,
    '965_750': 43.68,
    '965_751': 43.6256,
    '965_752': 43.5597,
    '965_753': 43.486,
    '965_754': 43.3919,
    '965_755': 43.2821,
    '965_756': 43.1663,
    '965_757': 43.0505,
    '965_758': 42.9263,
    '965_759': 42.8004,
    '965_760': 42.6789,
    '965_761': 42.551,
    '965_762': 42.4119,
    '965_763': 42.2831,
    '965_764': 42.1766,
    '965_765': 42.0902,
    '965_766': 42.009,
    '965_767': 41.8888,
    '965_768': 41.7553,
    '965_769': 41.6233,
    '965_770': 41.488,
    '965_771': 41.3486,
    '965_772': 41.2132,
    '965_773': 41.0826,
    '965_774': 40.9509,
    '965_775': 40.824,
    '965_776': 40.7013,
    '965_777': 40.5858,
    '965_778': 40.4765,
    '965_779': 40.37,
    '965_780': 40.2758,
    '965_781': 40.1946,
    '965_782': 40.1247,
    '965_783': 40.066,
    '965_784': 40.0217,
    '965_785': 39.9873,
    '965_786': 39.9625,
    '965_787': 39.9475,
    '965_788': 39.9388,
    '965_789': 39.9341,
    '965_790': 39.9334,
    '965_791': 39.9404,
    '965_792': 39.9578,
    '965_793': 39.9877,
    '965_794': 40.0286,
    '965_795': 40.0739,
    '965_796': 40.1159,
    '965_797': 40.1451,
    '965_798': 40.1608,
    '965_799': 40.1633,
    '965_800': 40.1546,
    '965_801': 40.1346,
    '965_802': 40.1026,
    '965_803': 40.059,
    '965_804': 40.0046,
    '965_805': 39.9396,
    '965_806': 39.8631,
    '965_807': 39.7759,
    '965_808': 39.6785,
    '965_809': 39.5702,
    '965_810': 39.4518,
    '965_811': 39.3236,
    '965_812': 39.1864,
    '965_813': 39.0411,
    '965_814': 38.8887,
    '965_815': 38.7257,
    '965_816': 38.548,
    '965_817': 38.368,
    '965_818': 38.1898,
    '965_819': 38.005,
    '965_820': 37.8065,
    '965_821': 37.5905,
    '965_822': 37.3585,
    '965_823': 37.1118,
    '965_824': 36.8481,
    '965_825': 36.5721,
    '965_826': 36.2924,
    '965_827': 36.0097,
    '965_828': 35.7253,
    '965_829': 35.4406,
    '965_830': 35.1551,
    '965_831': 34.8669,
    '965_832': 34.5732,
    '965_833': 34.2775,
    '965_834': 33.9888,
    '965_835': 33.6826,
    '965_836': 33.3747,
    '965_837': 33.0571,
    '965_838': 32.7422,
    '965_839': 32.4324,
    '965_840': 32.1141,
    '965_841': 31.79,
    '965_842': 31.4616,
    '965_843': 31.1404,
    '965_844': 30.8126,
    '965_845': 30.4862,
    '965_846': 30.154,
    '965_847': 29.8164,
    '965_848': 29.4726,
    '966_516': 33.3873,
    '966_517': 33.4723,
    '966_518': 33.5567,
    '966_519': 33.6427,
    '966_520': 33.7187,
    '966_521': 33.7897,
    '966_522': 33.8278,
    '966_523': 33.8524,
    '966_524': 33.8688,
    '966_525': 33.8821,
    '966_526': 33.8912,
    '966_527': 33.9039,
    '966_528': 33.9131,
    '966_612': 35.165,
    '966_613': 35.1682,
    '966_614': 35.1649,
    '966_615': 35.164,
    '966_616': 35.166,
    '966_617': 35.1701,
    '966_618': 35.1762,
    '966_619': 35.1842,
    '966_620': 35.1902,
    '966_621': 35.1979,
    '966_622': 35.2034,
    '966_623': 35.2025,
    '966_624': 35.2022,
    '966_625': 35.2178,
    '966_626': 35.2434,
    '966_627': 35.276,
    '966_628': 35.3148,
    '966_629': 35.3584,
    '966_630': 35.4104,
    '966_631': 35.4888,
    '966_632': 35.5823,
    '966_633': 35.6733,
    '966_634': 35.769,
    '966_635': 35.8754,
    '966_636': 35.9875,
    '966_637': 36.1025,
    '966_638': 36.2169,
    '966_639': 36.3301,
    '966_640': 36.4673,
    '966_641': 36.6182,
    '966_642': 36.7795,
    '966_643': 36.921,
    '966_644': 37.0164,
    '966_645': 37.0757,
    '966_646': 37.1414,
    '966_647': 37.2061,
    '966_648': 37.2777,
    '966_649': 37.3752,
    '966_650': 37.488,
    '966_651': 37.6116,
    '966_652': 37.7471,
    '966_653': 37.8895,
    '966_654': 38.033,
    '966_655': 38.1676,
    '966_656': 38.2996,
    '966_657': 38.408,
    '966_658': 38.5311,
    '966_659': 38.6454,
    '966_660': 38.7858,
    '966_661': 38.9495,
    '966_662': 39.1228,
    '966_663': 39.319,
    '966_664': 39.5232,
    '966_665': 39.7076,
    '966_666': 39.868,
    '966_667': 40.02,
    '966_668': 40.1647,
    '966_669': 40.3142,
    '966_670': 40.4318,
    '966_671': 40.522,
    '966_672': 40.5931,
    '966_673': 40.6341,
    '966_674': 40.6454,
    '966_675': 40.6494,
    '966_676': 40.6398,
    '966_677': 40.6443,
    '966_678': 40.6648,
    '966_679': 40.7114,
    '966_680': 40.7707,
    '966_681': 40.8231,
    '966_682': 40.8638,
    '966_683': 40.9061,
    '966_684': 40.9399,
    '966_685': 40.9711,
    '966_686': 40.9988,
    '966_687': 41.0393,
    '966_688': 41.0919,
    '966_689': 41.1536,
    '966_690': 41.2274,
    '966_691': 41.3226,
    '966_692': 41.4314,
    '966_693': 41.5507,
    '966_694': 41.6804,
    '966_695': 41.8158,
    '966_696': 41.9647,
    '966_697': 42.1262,
    '966_698': 42.2938,
    '966_699': 42.4601,
    '966_700': 42.6115,
    '966_701': 42.7297,
    '966_702': 42.7918,
    '966_703': 42.9026,
    '966_704': 42.9695,
    '966_705': 42.9721,
    '966_706': 42.9564,
    '966_707': 42.9671,
    '966_708': 42.9874,
    '966_709': 42.9934,
    '966_710': 42.9907,
    '966_711': 42.9859,
    '966_712': 42.9552,
    '966_713': 42.8927,
    '966_714': 42.8105,
    '966_715': 42.7409,
    '966_716': 42.6841,
    '966_717': 42.6587,
    '966_718': 42.6626,
    '966_719': 42.6904,
    '966_720': 42.7427,
    '966_721': 42.8086,
    '966_722': 42.8884,
    '966_723': 42.9747,
    '966_724': 43.0721,
    '966_725': 43.1716,
    '966_726': 43.2582,
    '966_727': 43.3337,
    '966_728': 43.403,
    '966_729': 43.4807,
    '966_730': 43.5469,
    '966_731': 43.6151,
    '966_732': 43.6491,
    '966_733': 43.702,
    '966_734': 43.7409,
    '966_735': 43.7202,
    '966_736': 43.682,
    '966_737': 43.6491,
    '966_738': 43.6322,
    '966_739': 43.6411,
    '966_740': 43.6744,
    '966_741': 43.7226,
    '966_742': 43.7743,
    '966_743': 43.814,
    '966_744': 43.8437,
    '966_745': 43.8464,
    '966_746': 43.8276,
    '966_747': 43.787,
    '966_748': 43.739,
    '966_749': 43.6958,
    '966_750': 43.6574,
    '966_751': 43.6163,
    '966_752': 43.5579,
    '966_753': 43.4851,
    '966_754': 43.3893,
    '966_755': 43.2775,
    '966_756': 43.1634,
    '966_757': 43.0524,
    '966_758': 42.9327,
    '966_759': 42.8104,
    '966_760': 42.6889,
    '966_761': 42.563,
    '966_762': 42.4275,
    '966_763': 42.2931,
    '966_764': 42.1747,
    '966_765': 42.0751,
    '966_766': 41.9823,
    '966_767': 41.8584,
    '966_768': 41.729,
    '966_769': 41.6015,
    '966_770': 41.4689,
    '966_771': 41.3316,
    '966_772': 41.2018,
    '966_773': 41.0748,
    '966_774': 40.9492,
    '966_775': 40.8288,
    '966_776': 40.7149,
    '966_777': 40.6078,
    '966_778': 40.5077,
    '966_779': 40.4151,
    '966_780': 40.333,
    '966_781': 40.2625,
    '966_782': 40.2025,
    '966_783': 40.1538,
    '966_784': 40.1172,
    '966_785': 40.0906,
    '966_786': 40.0731,
    '966_787': 40.0636,
    '966_788': 40.0586,
    '966_789': 40.0565,
    '966_790': 40.0548,
    '966_791': 40.0617,
    '966_792': 40.0787,
    '966_793': 40.1086,
    '966_794': 40.1487,
    '966_795': 40.1924,
    '966_796': 40.2327,
    '966_797': 40.2613,
    '966_798': 40.2766,
    '966_799': 40.2794,
    '966_800': 40.2709,
    '966_801': 40.253,
    '966_802': 40.2237,
    '966_803': 40.1833,
    '966_804': 40.1331,
    '966_805': 40.0722,
    '966_806': 39.9985,
    '966_807': 39.9148,
    '966_808': 39.8197,
    '966_809': 39.7124,
    '966_810': 39.595,
    '966_811': 39.4675,
    '966_812': 39.3312,
    '966_813': 39.1868,
    '966_814': 39.0334,
    '966_815': 38.8683,
    '966_816': 38.6895,
    '966_817': 38.5084,
    '966_818': 38.3274,
    '966_819': 38.141,
    '966_820': 37.9396,
    '966_821': 37.7195,
    '966_822': 37.4837,
    '966_823': 37.2316,
    '966_824': 36.9628,
    '966_825': 36.685,
    '966_826': 36.4032,
    '966_827': 36.1178,
    '966_828': 35.8307,
    '966_829': 35.5443,
    '966_830': 35.2601,
    '966_831': 34.9745,
    '966_832': 34.6811,
    '966_833': 34.3862,
    '966_834': 34.0907,
    '966_835': 33.7852,
    '966_836': 33.4717,
    '966_837': 33.1569,
    '966_838': 32.8446,
    '966_839': 32.5283,
    '966_840': 32.2242,
    '966_841': 31.9029,
    '966_842': 31.5755,
    '966_843': 31.2551,
    '966_844': 30.9317,
    '966_845': 30.6065,
    '966_846': 30.2803,
    '966_847': 29.9452,
    '966_848': 29.6073,
    '967_516': 33.3645,
    '967_517': 33.4427,
    '967_518': 33.5192,
    '967_519': 33.5971,
    '967_520': 33.6731,
    '967_521': 33.7486,
    '967_522': 33.7892,
    '967_523': 33.8175,
    '967_524': 33.835,
    '967_525': 33.8539,
    '967_526': 33.8734,
    '967_527': 33.8929,
    '967_528': 33.9053,
    '967_529': 33.8128,
    '967_530': 33.8232,
    '967_531': 33.8269,
    '967_532': 33.8381,
    '967_533': 33.8405,
    '967_534': 33.8415,
    '967_535': 33.8329,
    '967_536': 33.8266,
    '967_537': 33.8194,
    '967_538': 33.8119,
    '967_612': 35.1355,
    '967_613': 35.13,
    '967_614': 35.1286,
    '967_615': 35.1303,
    '967_616': 35.1336,
    '967_617': 35.138,
    '967_618': 35.1437,
    '967_619': 35.1541,
    '967_620': 35.1617,
    '967_621': 35.1626,
    '967_622': 35.1652,
    '967_623': 35.1661,
    '967_624': 35.1704,
    '967_625': 35.1866,
    '967_626': 35.2141,
    '967_627': 35.2484,
    '967_628': 35.2884,
    '967_629': 35.3347,
    '967_630': 35.3969,
    '967_631': 35.476,
    '967_632': 35.5647,
    '967_633': 35.6549,
    '967_634': 35.7521,
    '967_635': 35.8578,
    '967_636': 35.9674,
    '967_637': 36.0785,
    '967_638': 36.1909,
    '967_639': 36.3049,
    '967_640': 36.4321,
    '967_641': 36.5722,
    '967_642': 36.7196,
    '967_643': 36.8555,
    '967_644': 36.9561,
    '967_645': 37.0285,
    '967_646': 37.0991,
    '967_647': 37.1679,
    '967_648': 37.2472,
    '967_649': 37.3491,
    '967_650': 37.4658,
    '967_651': 37.5932,
    '967_652': 37.7361,
    '967_653': 37.8946,
    '967_654': 38.0502,
    '967_655': 38.1949,
    '967_656': 38.3318,
    '967_657': 38.447,
    '967_658': 38.5708,
    '967_659': 38.6967,
    '967_660': 38.837,
    '967_661': 38.9911,
    '967_662': 39.1482,
    '967_663': 39.3275,
    '967_664': 39.5172,
    '967_665': 39.694,
    '967_666': 39.8555,
    '967_667': 40.0081,
    '967_668': 40.1658,
    '967_669': 40.331,
    '967_670': 40.4686,
    '967_671': 40.5462,
    '967_672': 40.6296,
    '967_673': 40.6385,
    '967_674': 40.6604,
    '967_675': 40.646,
    '967_676': 40.6506,
    '967_677': 40.6511,
    '967_678': 40.6784,
    '967_679': 40.7321,
    '967_680': 40.7885,
    '967_681': 40.831,
    '967_682': 40.8702,
    '967_683': 40.9048,
    '967_684': 40.9383,
    '967_685': 40.969,
    '967_686': 40.9938,
    '967_687': 41.0266,
    '967_688': 41.0744,
    '967_689': 41.1337,
    '967_690': 41.2068,
    '967_691': 41.3048,
    '967_692': 41.4167,
    '967_693': 41.5382,
    '967_694': 41.6692,
    '967_695': 41.8105,
    '967_696': 41.9601,
    '967_697': 42.1255,
    '967_698': 42.2929,
    '967_699': 42.4538,
    '967_700': 42.6077,
    '967_701': 42.7421,
    '967_702': 42.7798,
    '967_703': 42.8915,
    '967_704': 42.9552,
    '967_705': 42.9593,
    '967_706': 42.9463,
    '967_707': 42.9352,
    '967_708': 42.949,
    '967_709': 42.9615,
    '967_710': 42.9417,
    '967_711': 42.9328,
    '967_712': 42.8827,
    '967_713': 42.8263,
    '967_714': 42.7415,
    '967_715': 42.6594,
    '967_716': 42.6007,
    '967_717': 42.5754,
    '967_718': 42.5833,
    '967_719': 42.6263,
    '967_720': 42.7063,
    '967_721': 42.8,
    '967_722': 42.905,
    '967_723': 42.9947,
    '967_724': 43.0897,
    '967_725': 43.1761,
    '967_726': 43.2554,
    '967_727': 43.3182,
    '967_728': 43.3827,
    '967_729': 43.4553,
    '967_730': 43.5137,
    '967_731': 43.5683,
    '967_732': 43.5932,
    '967_733': 43.6316,
    '967_734': 43.648,
    '967_735': 43.6345,
    '967_736': 43.5969,
    '967_737': 43.5685,
    '967_738': 43.5605,
    '967_739': 43.5797,
    '967_740': 43.6174,
    '967_741': 43.6708,
    '967_742': 43.7235,
    '967_743': 43.7719,
    '967_744': 43.7988,
    '967_745': 43.7972,
    '967_746': 43.7746,
    '967_747': 43.7399,
    '967_748': 43.6993,
    '967_749': 43.6636,
    '967_750': 43.6347,
    '967_751': 43.6052,
    '967_752': 43.5614,
    '967_753': 43.4924,
    '967_754': 43.4005,
    '967_755': 43.2817,
    '967_756': 43.1597,
    '967_757': 43.0408,
    '967_758': 42.9208,
    '967_759': 42.7964,
    '967_760': 42.6711,
    '967_761': 42.5439,
    '967_762': 42.4126,
    '967_763': 42.2824,
    '967_764': 42.1624,
    '967_765': 42.054,
    '967_766': 41.9515,
    '967_767': 41.8266,
    '967_768': 41.6989,
    '967_769': 41.5685,
    '967_770': 41.4389,
    '967_771': 41.3099,
    '967_772': 41.1856,
    '967_773': 41.0655,
    '967_774': 40.9505,
    '967_775': 40.8407,
    '967_776': 40.7382,
    '967_777': 40.6408,
    '967_778': 40.5507,
    '967_779': 40.4692,
    '967_780': 40.3977,
    '967_781': 40.3366,
    '967_782': 40.2852,
    '967_783': 40.2455,
    '967_784': 40.2161,
    '967_785': 40.1962,
    '967_786': 40.1842,
    '967_787': 40.1789,
    '967_788': 40.178,
    '967_789': 40.1783,
    '967_790': 40.1771,
    '967_791': 40.183,
    '967_792': 40.1986,
    '967_793': 40.227,
    '967_794': 40.2643,
    '967_795': 40.3043,
    '967_796': 40.3413,
    '967_797': 40.3699,
    '967_798': 40.3846,
    '967_799': 40.3881,
    '967_800': 40.3808,
    '967_801': 40.3654,
    '967_802': 40.3403,
    '967_803': 40.3059,
    '967_804': 40.2612,
    '967_805': 40.2047,
    '967_806': 40.135,
    '967_807': 40.0537,
    '967_808': 39.9585,
    '967_809': 39.8513,
    '967_810': 39.7343,
    '967_811': 39.6078,
    '967_812': 39.4721,
    '967_813': 39.327,
    '967_814': 39.1716,
    '967_815': 39.0046,
    '967_816': 38.8266,
    '967_817': 38.6442,
    '967_818': 38.4597,
    '967_819': 38.2703,
    '967_820': 38.0673,
    '967_821': 37.8442,
    '967_822': 37.6055,
    '967_823': 37.3498,
    '967_824': 37.0773,
    '967_825': 36.7969,
    '967_826': 36.5117,
    '967_827': 36.2225,
    '967_828': 35.9319,
    '967_829': 35.6432,
    '967_830': 35.3571,
    '967_831': 35.0707,
    '967_832': 34.7808,
    '967_833': 34.489,
    '967_834': 34.1918,
    '967_835': 33.889,
    '967_836': 33.5717,
    '967_837': 33.262,
    '967_838': 32.9606,
    '967_839': 32.6453,
    '967_840': 32.3437,
    '967_841': 32.0111,
    '967_842': 31.6893,
    '967_843': 31.3713,
    '967_844': 31.0518,
    '967_845': 30.7296,
    '967_846': 30.4032,
    '967_847': 30.0709,
    '967_848': 29.7405,
    '968_516': 33.3234,
    '968_517': 33.3989,
    '968_518': 33.471,
    '968_519': 33.5435,
    '968_520': 33.6167,
    '968_521': 33.6899,
    '968_522': 33.7362,
    '968_523': 33.7738,
    '968_524': 33.7966,
    '968_525': 33.8223,
    '968_526': 33.8529,
    '968_527': 33.8819,
    '968_528': 33.9005,
    '968_529': 33.8243,
    '968_530': 33.8431,
    '968_531': 33.8498,
    '968_532': 33.8542,
    '968_533': 33.8576,
    '968_534': 33.8547,
    '968_535': 33.8457,
    '968_536': 33.8376,
    '968_537': 33.8266,
    '968_538': 33.8149,
    '968_612': 35.092,
    '968_613': 35.092,
    '968_614': 35.0945,
    '968_615': 35.0995,
    '968_616': 35.1054,
    '968_617': 35.1107,
    '968_618': 35.1157,
    '968_619': 35.1271,
    '968_620': 35.1328,
    '968_621': 35.1333,
    '968_622': 35.1345,
    '968_623': 35.1363,
    '968_624': 35.1437,
    '968_625': 35.1611,
    '968_626': 35.1914,
    '968_627': 35.2289,
    '968_628': 35.2726,
    '968_629': 35.3238,
    '968_630': 35.3896,
    '968_631': 35.4665,
    '968_632': 35.5504,
    '968_633': 35.6388,
    '968_634': 35.7348,
    '968_635': 35.841,
    '968_636': 35.9495,
    '968_637': 36.058,
    '968_638': 36.1689,
    '968_639': 36.2806,
    '968_640': 36.3981,
    '968_641': 36.5273,
    '968_642': 36.6579,
    '968_643': 36.7814,
    '968_644': 36.888,
    '968_645': 36.9722,
    '968_646': 37.0516,
    '968_647': 37.1293,
    '968_648': 37.2168,
    '968_649': 37.3224,
    '968_650': 37.4425,
    '968_651': 37.5762,
    '968_652': 37.7282,
    '968_653': 37.894,
    '968_654': 38.0512,
    '968_655': 38.2003,
    '968_656': 38.3413,
    '968_657': 38.4696,
    '968_658': 38.6025,
    '968_659': 38.7438,
    '968_660': 38.8865,
    '968_661': 39.0295,
    '968_662': 39.1769,
    '968_663': 39.3366,
    '968_664': 39.5028,
    '968_665': 39.6672,
    '968_666': 39.8271,
    '968_667': 39.991,
    '968_668': 40.1642,
    '968_669': 40.3378,
    '968_670': 40.4765,
    '968_671': 40.5491,
    '968_672': 40.6292,
    '968_673': 40.641,
    '968_674': 40.6435,
    '968_675': 40.6325,
    '968_676': 40.6408,
    '968_677': 40.6547,
    '968_678': 40.6955,
    '968_679': 40.7456,
    '968_680': 40.7926,
    '968_681': 40.8361,
    '968_682': 40.8721,
    '968_683': 40.906,
    '968_684': 40.9424,
    '968_685': 40.9725,
    '968_686': 40.9966,
    '968_687': 41.0196,
    '968_688': 41.0591,
    '968_689': 41.1135,
    '968_690': 41.1867,
    '968_691': 41.2845,
    '968_692': 41.3982,
    '968_693': 41.5214,
    '968_694': 41.6549,
    '968_695': 41.8018,
    '968_696': 41.9548,
    '968_697': 42.1093,
    '968_698': 42.2696,
    '968_699': 42.4282,
    '968_700': 42.6021,
    '968_701': 42.7262,
    '968_702': 42.7827,
    '968_703': 42.8816,
    '968_704': 42.9395,
    '968_705': 42.9301,
    '968_706': 42.9222,
    '968_707': 42.922,
    '968_708': 42.9187,
    '968_709': 42.9183,
    '968_710': 42.8994,
    '968_711': 42.8761,
    '968_712': 42.8128,
    '968_713': 42.7523,
    '968_714': 42.6659,
    '968_715': 42.5792,
    '968_716': 42.5187,
    '968_717': 42.4962,
    '968_718': 42.5091,
    '968_719': 42.5643,
    '968_720': 42.6655,
    '968_721': 42.7825,
    '968_722': 42.9036,
    '968_723': 43.0094,
    '968_724': 43.101,
    '968_725': 43.1775,
    '968_726': 43.2371,
    '968_727': 43.3039,
    '968_728': 43.3556,
    '968_729': 43.4096,
    '968_730': 43.452,
    '968_731': 43.4915,
    '968_732': 43.5251,
    '968_733': 43.5497,
    '968_734': 43.5386,
    '968_735': 43.523,
    '968_736': 43.5017,
    '968_737': 43.4834,
    '968_738': 43.4831,
    '968_739': 43.5087,
    '968_740': 43.5523,
    '968_741': 43.6108,
    '968_742': 43.6686,
    '968_743': 43.7132,
    '968_744': 43.7312,
    '968_745': 43.7312,
    '968_746': 43.7143,
    '968_747': 43.6822,
    '968_748': 43.6469,
    '968_749': 43.6173,
    '968_750': 43.598,
    '968_751': 43.5722,
    '968_752': 43.5381,
    '968_753': 43.4805,
    '968_754': 43.3928,
    '968_755': 43.2793,
    '968_756': 43.1574,
    '968_757': 43.0365,
    '968_758': 42.9113,
    '968_759': 42.7818,
    '968_760': 42.6439,
    '968_761': 42.507,
    '968_762': 42.3772,
    '968_763': 42.2559,
    '968_764': 42.1429,
    '968_765': 42.0299,
    '968_766': 41.9191,
    '968_767': 41.7966,
    '968_768': 41.664,
    '968_769': 41.5335,
    '968_770': 41.4104,
    '968_771': 41.288,
    '968_772': 41.1711,
    '968_773': 41.0628,
    '968_774': 40.96,
    '968_775': 40.8628,
    '968_776': 40.7697,
    '968_777': 40.6821,
    '968_778': 40.6014,
    '968_779': 40.5295,
    '968_780': 40.4681,
    '968_781': 40.4161,
    '968_782': 40.3731,
    '968_783': 40.3399,
    '968_784': 40.3163,
    '968_785': 40.3018,
    '968_786': 40.2943,
    '968_787': 40.2926,
    '968_788': 40.2952,
    '968_789': 40.2969,
    '968_790': 40.2976,
    '968_791': 40.3029,
    '968_792': 40.317,
    '968_793': 40.341,
    '968_794': 40.3733,
    '968_795': 40.4098,
    '968_796': 40.4443,
    '968_797': 40.4708,
    '968_798': 40.4852,
    '968_799': 40.4897,
    '968_800': 40.4846,
    '968_801': 40.4731,
    '968_802': 40.4532,
    '968_803': 40.4255,
    '968_804': 40.3876,
    '968_805': 40.3377,
    '968_806': 40.2727,
    '968_807': 40.1908,
    '968_808': 40.0941,
    '968_809': 39.9857,
    '968_810': 39.8682,
    '968_811': 39.7421,
    '968_812': 39.6068,
    '968_813': 39.4609,
    '968_814': 39.3044,
    '968_815': 39.1364,
    '968_816': 38.9591,
    '968_817': 38.7754,
    '968_818': 38.5888,
    '968_819': 38.396,
    '968_820': 38.1906,
    '968_821': 37.9656,
    '968_822': 37.7249,
    '968_823': 37.4659,
    '968_824': 37.1899,
    '968_825': 36.907,
    '968_826': 36.6179,
    '968_827': 36.3243,
    '968_828': 36.0287,
    '968_829': 35.7366,
    '968_830': 35.4474,
    '968_831': 35.1594,
    '968_832': 34.8704,
    '968_833': 34.5784,
    '968_834': 34.2855,
    '968_835': 33.9873,
    '968_836': 33.6795,
    '968_837': 33.3722,
    '968_838': 33.0631,
    '968_839': 32.7404,
    '968_840': 32.437,
    '968_841': 32.1167,
    '968_842': 31.8011,
    '968_843': 31.4862,
    '968_844': 31.1698,
    '968_845': 30.8482,
    '968_846': 30.5197,
    '968_847': 30.1932,
    '968_848': 29.8658,
    '969_516': 33.2718,
    '969_517': 33.3412,
    '969_518': 33.4135,
    '969_519': 33.4846,
    '969_520': 33.5549,
    '969_521': 33.6216,
    '969_522': 33.6755,
    '969_523': 33.7201,
    '969_524': 33.756,
    '969_525': 33.7911,
    '969_526': 33.8303,
    '969_527': 33.8669,
    '969_528': 33.897,
    '969_529': 33.8418,
    '969_530': 33.8645,
    '969_531': 33.8776,
    '969_532': 33.8841,
    '969_533': 33.8843,
    '969_534': 33.877,
    '969_535': 33.8684,
    '969_536': 33.8526,
    '969_537': 33.8372,
    '969_538': 33.8208,
    '969_612': 35.0494,
    '969_613': 35.0534,
    '969_614': 35.0608,
    '969_615': 35.0706,
    '969_616': 35.0806,
    '969_617': 35.0886,
    '969_618': 35.0926,
    '969_619': 35.1007,
    '969_620': 35.1074,
    '969_621': 35.1107,
    '969_622': 35.1106,
    '969_623': 35.1128,
    '969_624': 35.1231,
    '969_625': 35.1446,
    '969_626': 35.1767,
    '969_627': 35.2172,
    '969_628': 35.2656,
    '969_629': 35.322,
    '969_630': 35.3878,
    '969_631': 35.4616,
    '969_632': 35.5409,
    '969_633': 35.625,
    '969_634': 35.7163,
    '969_635': 35.8221,
    '969_636': 35.9318,
    '969_637': 36.0391,
    '969_638': 36.1505,
    '969_639': 36.2608,
    '969_640': 36.3736,
    '969_641': 36.4907,
    '969_642': 36.6076,
    '969_643': 36.7196,
    '969_644': 36.8237,
    '969_645': 36.9172,
    '969_646': 37.0069,
    '969_647': 37.094,
    '969_648': 37.1876,
    '969_649': 37.2963,
    '969_650': 37.4201,
    '969_651': 37.5581,
    '969_652': 37.7152,
    '969_653': 37.8768,
    '969_654': 38.0333,
    '969_655': 38.185,
    '969_656': 38.3275,
    '969_657': 38.4632,
    '969_658': 38.6065,
    '969_659': 38.7587,
    '969_660': 38.9052,
    '969_661': 39.0486,
    '969_662': 39.1925,
    '969_663': 39.3387,
    '969_664': 39.4829,
    '969_665': 39.6313,
    '969_666': 39.7952,
    '969_667': 39.9747,
    '969_668': 40.1528,
    '969_669': 40.322,
    '969_670': 40.4484,
    '969_671': 40.5119,
    '969_672': 40.6111,
    '969_673': 40.6327,
    '969_674': 40.6324,
    '969_675': 40.6263,
    '969_676': 40.6294,
    '969_677': 40.6592,
    '969_678': 40.7075,
    '969_679': 40.7587,
    '969_680': 40.8021,
    '969_681': 40.8408,
    '969_682': 40.8708,
    '969_683': 40.9074,
    '969_684': 40.947,
    '969_685': 40.9788,
    '969_686': 41.0005,
    '969_687': 41.0201,
    '969_688': 41.0538,
    '969_689': 41.1034,
    '969_690': 41.175,
    '969_691': 41.27,
    '969_692': 41.3808,
    '969_693': 41.5025,
    '969_694': 41.6364,
    '969_695': 41.7819,
    '969_696': 41.9327,
    '969_697': 42.0842,
    '969_698': 42.2439,
    '969_699': 42.4062,
    '969_700': 42.5692,
    '969_701': 42.6786,
    '969_702': 42.7454,
    '969_703': 42.8431,
    '969_704': 42.9014,
    '969_705': 42.9192,
    '969_706': 42.9119,
    '969_707': 42.9159,
    '969_708': 42.8955,
    '969_709': 42.8812,
    '969_710': 42.8594,
    '969_711': 42.8166,
    '969_712': 42.7578,
    '969_713': 42.6827,
    '969_714': 42.595,
    '969_715': 42.5039,
    '969_716': 42.4426,
    '969_717': 42.4216,
    '969_718': 42.4394,
    '969_719': 42.5027,
    '969_720': 42.6174,
    '969_721': 42.7592,
    '969_722': 42.8856,
    '969_723': 42.9967,
    '969_724': 43.091,
    '969_725': 43.1621,
    '969_726': 43.2185,
    '969_727': 43.2774,
    '969_728': 43.3091,
    '969_729': 43.3477,
    '969_730': 43.3826,
    '969_731': 43.4014,
    '969_732': 43.429,
    '969_733': 43.4407,
    '969_734': 43.4267,
    '969_735': 43.4115,
    '969_736': 43.3992,
    '969_737': 43.3934,
    '969_738': 43.4034,
    '969_739': 43.4352,
    '969_740': 43.4837,
    '969_741': 43.5427,
    '969_742': 43.6015,
    '969_743': 43.6466,
    '969_744': 43.6685,
    '969_745': 43.6661,
    '969_746': 43.6447,
    '969_747': 43.6122,
    '969_748': 43.5748,
    '969_749': 43.5454,
    '969_750': 43.5298,
    '969_751': 43.5165,
    '969_752': 43.489,
    '969_753': 43.4321,
    '969_754': 43.351,
    '969_755': 43.244,
    '969_756': 43.1271,
    '969_757': 43.0069,
    '969_758': 42.8839,
    '969_759': 42.7501,
    '969_760': 42.6098,
    '969_761': 42.4659,
    '969_762': 42.3286,
    '969_763': 42.2125,
    '969_764': 42.1022,
    '969_765': 41.9935,
    '969_766': 41.8782,
    '969_767': 41.7508,
    '969_768': 41.6235,
    '969_769': 41.5042,
    '969_770': 41.3858,
    '969_771': 41.2716,
    '969_772': 41.1677,
    '969_773': 41.0716,
    '969_774': 40.9806,
    '969_775': 40.8929,
    '969_776': 40.8093,
    '969_777': 40.7306,
    '969_778': 40.6586,
    '969_779': 40.5962,
    '969_780': 40.5437,
    '969_781': 40.4999,
    '969_782': 40.4638,
    '969_783': 40.4362,
    '969_784': 40.418,
    '969_785': 40.4079,
    '969_786': 40.4041,
    '969_787': 40.406,
    '969_788': 40.4113,
    '969_789': 40.4149,
    '969_790': 40.4164,
    '969_791': 40.4206,
    '969_792': 40.4311,
    '969_793': 40.4495,
    '969_794': 40.476,
    '969_795': 40.5094,
    '969_796': 40.5418,
    '969_797': 40.5649,
    '969_798': 40.5792,
    '969_799': 40.5856,
    '969_800': 40.5849,
    '969_801': 40.5784,
    '969_802': 40.5643,
    '969_803': 40.5432,
    '969_804': 40.5137,
    '969_805': 40.4742,
    '969_806': 40.4132,
    '969_807': 40.3274,
    '969_808': 40.2278,
    '969_809': 40.1157,
    '969_810': 39.9965,
    '969_811': 39.8701,
    '969_812': 39.7354,
    '969_813': 39.5907,
    '969_814': 39.435,
    '969_815': 39.2652,
    '969_816': 39.0865,
    '969_817': 38.9018,
    '969_818': 38.7134,
    '969_819': 38.5179,
    '969_820': 38.3108,
    '969_821': 38.0859,
    '969_822': 37.8428,
    '969_823': 37.5806,
    '969_824': 37.3017,
    '969_825': 37.016,
    '969_826': 36.7236,
    '969_827': 36.4261,
    '969_828': 36.126,
    '969_829': 35.828,
    '969_830': 35.5341,
    '969_831': 35.2438,
    '969_832': 34.9545,
    '969_833': 34.6611,
    '969_834': 34.3742,
    '969_835': 34.0821,
    '969_836': 33.7796,
    '969_837': 33.4755,
    '969_838': 33.1702,
    '969_839': 32.8469,
    '969_840': 32.5402,
    '969_841': 32.2257,
    '969_842': 31.9127,
    '969_843': 31.6013,
    '969_844': 31.2874,
    '969_845': 30.9684,
    '969_846': 30.6425,
    '969_847': 30.3154,
    '969_848': 29.9897,
    '970_516': 33.2183,
    '970_517': 33.2883,
    '970_518': 33.3585,
    '970_519': 33.4267,
    '970_520': 33.4955,
    '970_521': 33.5603,
    '970_522': 33.6202,
    '970_523': 33.6697,
    '970_524': 33.7161,
    '970_525': 33.762,
    '970_526': 33.8087,
    '970_527': 33.7901,
    '970_528': 33.8274,
    '970_529': 33.8606,
    '970_530': 33.8921,
    '970_531': 33.916,
    '970_532': 33.9262,
    '970_533': 33.9257,
    '970_534': 33.9124,
    '970_535': 33.8926,
    '970_536': 33.8734,
    '970_537': 33.8539,
    '970_538': 33.8281,
    '970_612': 35.0121,
    '970_613': 35.0171,
    '970_614': 35.0294,
    '970_615': 35.0434,
    '970_616': 35.0575,
    '970_617': 35.0687,
    '970_618': 35.069,
    '970_619': 35.0774,
    '970_620': 35.0852,
    '970_621': 35.089,
    '970_622': 35.0912,
    '970_623': 35.097,
    '970_624': 35.111,
    '970_625': 35.1346,
    '970_626': 35.1681,
    '970_627': 35.2112,
    '970_628': 35.2644,
    '970_629': 35.3248,
    '970_630': 35.3909,
    '970_631': 35.4623,
    '970_632': 35.5381,
    '970_633': 35.6189,
    '970_634': 35.7076,
    '970_635': 35.8071,
    '970_636': 35.9126,
    '970_637': 36.0216,
    '970_638': 36.1324,
    '970_639': 36.2426,
    '970_640': 36.352,
    '970_641': 36.4603,
    '970_642': 36.5675,
    '970_643': 36.6726,
    '970_644': 36.7749,
    '970_645': 36.8741,
    '970_646': 36.9705,
    '970_647': 37.0656,
    '970_648': 37.1623,
    '970_649': 37.2727,
    '970_650': 37.3981,
    '970_651': 37.5382,
    '970_652': 37.6968,
    '970_653': 37.8574,
    '970_654': 38.0143,
    '970_655': 38.1629,
    '970_656': 38.2994,
    '970_657': 38.4361,
    '970_658': 38.5837,
    '970_659': 38.7446,
    '970_660': 38.8977,
    '970_661': 39.0429,
    '970_662': 39.1878,
    '970_663': 39.3285,
    '970_664': 39.4585,
    '970_665': 39.5992,
    '970_666': 39.7691,
    '970_667': 39.9571,
    '970_668': 40.1477,
    '970_669': 40.3064,
    '970_670': 40.4111,
    '970_671': 40.4976,
    '970_672': 40.5715,
    '970_673': 40.6101,
    '970_674': 40.6049,
    '970_675': 40.6068,
    '970_676': 40.6174,
    '970_677': 40.6603,
    '970_678': 40.7098,
    '970_679': 40.7733,
    '970_680': 40.8106,
    '970_681': 40.8414,
    '970_682': 40.8721,
    '970_683': 40.9104,
    '970_684': 40.9509,
    '970_685': 40.9813,
    '970_686': 41.0028,
    '970_687': 41.0205,
    '970_688': 41.0522,
    '970_689': 41.1023,
    '970_690': 41.1738,
    '970_691': 41.2661,
    '970_692': 41.3711,
    '970_693': 41.4892,
    '970_694': 41.622,
    '970_695': 41.7646,
    '970_696': 41.9123,
    '970_697': 42.065,
    '970_698': 42.2194,
    '970_699': 42.372,
    '970_700': 42.5244,
    '970_701': 42.6411,
    '970_702': 42.7488,
    '970_703': 42.8236,
    '970_704': 42.8515,
    '970_705': 42.9085,
    '970_706': 42.9245,
    '970_707': 42.9156,
    '970_708': 42.8995,
    '970_709': 42.8774,
    '970_710': 42.8301,
    '970_711': 42.7704,
    '970_712': 42.7025,
    '970_713': 42.614,
    '970_714': 42.5249,
    '970_715': 42.432,
    '970_716': 42.3724,
    '970_717': 42.3527,
    '970_718': 42.3737,
    '970_719': 42.4427,
    '970_720': 42.5678,
    '970_721': 42.7244,
    '970_722': 42.8547,
    '970_723': 42.978,
    '970_724': 43.0778,
    '970_725': 43.1382,
    '970_726': 43.2007,
    '970_727': 43.2476,
    '970_728': 43.2649,
    '970_729': 43.2885,
    '970_730': 43.307,
    '970_731': 43.3085,
    '970_732': 43.3226,
    '970_733': 43.3224,
    '970_734': 43.312,
    '970_735': 43.3015,
    '970_736': 43.2969,
    '970_737': 43.303,
    '970_738': 43.3226,
    '970_739': 43.3605,
    '970_740': 43.4142,
    '970_741': 43.4778,
    '970_742': 43.5404,
    '970_743': 43.5877,
    '970_744': 43.614,
    '970_745': 43.6095,
    '970_746': 43.5857,
    '970_747': 43.5506,
    '970_748': 43.5077,
    '970_749': 43.4672,
    '970_750': 43.4439,
    '970_751': 43.4271,
    '970_752': 43.4041,
    '970_753': 43.3571,
    '970_754': 43.279,
    '970_755': 43.1711,
    '970_756': 43.059,
    '970_757': 42.9423,
    '970_758': 42.8206,
    '970_759': 42.6916,
    '970_760': 42.5598,
    '970_761': 42.4208,
    '970_762': 42.281,
    '970_763': 42.1599,
    '970_764': 42.0455,
    '970_765': 41.9336,
    '970_766': 41.8171,
    '970_767': 41.6996,
    '970_768': 41.5867,
    '970_769': 41.4774,
    '970_770': 41.3689,
    '970_771': 41.2681,
    '970_772': 41.1755,
    '970_773': 41.0894,
    '970_774': 41.0087,
    '970_775': 40.9312,
    '970_776': 40.8573,
    '970_777': 40.7876,
    '970_778': 40.7241,
    '970_779': 40.6705,
    '970_780': 40.6261,
    '970_781': 40.5889,
    '970_782': 40.5586,
    '970_783': 40.5361,
    '970_784': 40.5228,
    '970_785': 40.517,
    '970_786': 40.5164,
    '970_787': 40.5207,
    '970_788': 40.5284,
    '970_789': 40.5334,
    '970_790': 40.5341,
    '970_791': 40.5359,
    '970_792': 40.5429,
    '970_793': 40.5558,
    '970_794': 40.5757,
    '970_795': 40.6041,
    '970_796': 40.6329,
    '970_797': 40.6534,
    '970_798': 40.6683,
    '970_799': 40.6777,
    '970_800': 40.6825,
    '970_801': 40.6825,
    '970_802': 40.6752,
    '970_803': 40.6597,
    '970_804': 40.6393,
    '970_805': 40.6123,
    '970_806': 40.5533,
    '970_807': 40.4651,
    '970_808': 40.3587,
    '970_809': 40.2411,
    '970_810': 40.1204,
    '970_811': 39.994,
    '970_812': 39.861,
    '970_813': 39.7174,
    '970_814': 39.5611,
    '970_815': 39.3906,
    '970_816': 39.2116,
    '970_817': 39.0273,
    '970_818': 38.8369,
    '970_819': 38.6389,
    '970_820': 38.4298,
    '970_821': 38.2043,
    '970_822': 37.9597,
    '970_823': 37.6944,
    '970_824': 37.4138,
    '970_825': 37.1257,
    '970_826': 36.8304,
    '970_827': 36.5292,
    '970_828': 36.2239,
    '970_829': 35.918,
    '970_830': 35.6203,
    '970_831': 35.3262,
    '970_832': 35.0337,
    '970_833': 34.7458,
    '970_834': 34.4606,
    '970_835': 34.168,
    '970_836': 33.8623,
    '970_837': 33.5522,
    '970_838': 33.2492,
    '970_839': 32.9396,
    '970_840': 32.6352,
    '970_841': 32.326,
    '970_842': 32.0158,
    '970_843': 31.7063,
    '970_844': 31.3982,
    '970_845': 31.0845,
    '970_846': 30.762,
    '970_847': 30.4317,
    '970_848': 30.1128,
    '971_525': 33.6887,
    '971_526': 33.7393,
    '971_527': 33.7893,
    '971_528': 33.8378,
    '971_529': 33.8817,
    '971_530': 33.9261,
    '971_531': 33.9619,
    '971_532': 33.9757,
    '971_533': 33.9728,
    '971_534': 33.9533,
    '971_535': 33.9279,
    '971_536': 33.9005,
    '971_537': 33.8709,
    '971_538': 33.8418,
    '971_612': 34.9797,
    '971_613': 34.9885,
    '971_614': 35.001,
    '971_615': 35.0155,
    '971_616': 35.0293,
    '971_617': 35.0388,
    '971_618': 35.0461,
    '971_619': 35.0586,
    '971_620': 35.0664,
    '971_621': 35.0694,
    '971_622': 35.0741,
    '971_623': 35.0822,
    '971_624': 35.0998,
    '971_625': 35.1257,
    '971_626': 35.1611,
    '971_627': 35.2066,
    '971_628': 35.2652,
    '971_629': 35.3297,
    '971_630': 35.3963,
    '971_631': 35.4658,
    '971_632': 35.5383,
    '971_633': 35.6151,
    '971_634': 35.6997,
    '971_635': 35.7936,
    '971_636': 35.8963,
    '971_637': 36.0051,
    '971_638': 36.1155,
    '971_639': 36.2244,
    '971_640': 36.3294,
    '971_641': 36.4313,
    '971_642': 36.5317,
    '971_643': 36.6322,
    '971_644': 36.7349,
    '971_645': 36.839,
    '971_646': 36.9415,
    '971_647': 37.0421,
    '971_648': 37.1405,
    '971_649': 37.2496,
    '971_650': 37.3754,
    '971_651': 37.5158,
    '971_652': 37.6747,
    '971_653': 37.8413,
    '971_654': 37.989,
    '971_655': 38.1296,
    '971_656': 38.2559,
    '971_657': 38.3897,
    '971_658': 38.5402,
    '971_659': 38.7033,
    '971_660': 38.8587,
    '971_661': 39.0104,
    '971_662': 39.1592,
    '971_663': 39.3008,
    '971_664': 39.4276,
    '971_665': 39.5659,
    '971_666': 39.7409,
    '971_667': 39.927,
    '971_668': 40.1073,
    '971_669': 40.2616,
    '971_670': 40.3653,
    '971_671': 40.4792,
    '971_672': 40.5394,
    '971_673': 40.57,
    '971_674': 40.5649,
    '971_675': 40.576,
    '971_676': 40.6003,
    '971_677': 40.656,
    '971_678': 40.7149,
    '971_679': 40.7726,
    '971_680': 40.8064,
    '971_681': 40.8368,
    '971_682': 40.8708,
    '971_683': 40.9081,
    '971_684': 40.9475,
    '971_685': 40.9734,
    '971_686': 40.995,
    '971_687': 41.0105,
    '971_688': 41.0428,
    '971_689': 41.0973,
    '971_690': 41.1731,
    '971_691': 41.2674,
    '971_692': 41.3682,
    '971_693': 41.4784,
    '971_694': 41.6028,
    '971_695': 41.7413,
    '971_696': 41.8881,
    '971_697': 42.039,
    '971_698': 42.1898,
    '971_699': 42.3428,
    '971_700': 42.4822,
    '971_701': 42.5977,
    '971_702': 42.7277,
    '971_703': 42.7999,
    '971_704': 42.8367,
    '971_705': 42.9072,
    '971_706': 42.9191,
    '971_707': 42.9183,
    '971_708': 42.9131,
    '971_709': 42.8596,
    '971_710': 42.8028,
    '971_711': 42.7407,
    '971_712': 42.6569,
    '971_713': 42.5595,
    '971_714': 42.4545,
    '971_715': 42.3637,
    '971_716': 42.3061,
    '971_717': 42.2884,
    '971_718': 42.3128,
    '971_719': 42.3877,
    '971_720': 42.5202,
    '971_721': 42.6808,
    '971_722': 42.8253,
    '971_723': 42.962,
    '971_724': 43.0537,
    '971_725': 43.1178,
    '971_726': 43.1763,
    '971_727': 43.2063,
    '971_728': 43.2177,
    '971_729': 43.2195,
    '971_730': 43.2243,
    '971_731': 43.2166,
    '971_732': 43.2181,
    '971_733': 43.2098,
    '971_734': 43.2012,
    '971_735': 43.1939,
    '971_736': 43.1956,
    '971_737': 43.2104,
    '971_738': 43.2388,
    '971_739': 43.282,
    '971_740': 43.3441,
    '971_741': 43.4158,
    '971_742': 43.4818,
    '971_743': 43.5314,
    '971_744': 43.5544,
    '971_745': 43.5561,
    '971_746': 43.5322,
    '971_747': 43.4927,
    '971_748': 43.4397,
    '971_749': 43.3898,
    '971_750': 43.3504,
    '971_751': 43.3198,
    '971_752': 43.2941,
    '971_753': 43.2477,
    '971_754': 43.1684,
    '971_755': 43.0682,
    '971_756': 42.9668,
    '971_757': 42.856,
    '971_758': 42.7349,
    '971_759': 42.6117,
    '971_760': 42.4861,
    '971_761': 42.3516,
    '971_762': 42.2141,
    '971_763': 42.0901,
    '971_764': 41.9733,
    '971_765': 41.8628,
    '971_766': 41.7581,
    '971_767': 41.6554,
    '971_768': 41.5539,
    '971_769': 41.4556,
    '971_770': 41.3604,
    '971_771': 41.2712,
    '971_772': 41.1878,
    '971_773': 41.1121,
    '971_774': 41.0418,
    '971_775': 40.9748,
    '971_776': 40.9111,
    '971_777': 40.8514,
    '971_778': 40.7976,
    '971_779': 40.7518,
    '971_780': 40.7136,
    '971_781': 40.682,
    '971_782': 40.6571,
    '971_783': 40.6394,
    '971_784': 40.6304,
    '971_785': 40.6286,
    '971_786': 40.6308,
    '971_787': 40.6369,
    '971_788': 40.6455,
    '971_789': 40.6493,
    '971_790': 40.6492,
    '971_791': 40.6487,
    '971_792': 40.6514,
    '971_793': 40.6588,
    '971_794': 40.6725,
    '971_795': 40.6936,
    '971_796': 40.7152,
    '971_797': 40.733,
    '971_798': 40.7502,
    '971_799': 40.7638,
    '971_800': 40.7757,
    '971_801': 40.7837,
    '971_802': 40.7843,
    '971_803': 40.776,
    '971_804': 40.7618,
    '971_805': 40.7354,
    '971_806': 40.6708,
    '971_807': 40.5788,
    '971_808': 40.4798,
    '971_809': 40.3592,
    '971_810': 40.2374,
    '971_811': 40.1123,
    '971_812': 39.9804,
    '971_813': 39.8371,
    '971_814': 39.6807,
    '971_815': 39.5115,
    '971_816': 39.3327,
    '971_817': 39.1473,
    '971_818': 38.9551,
    '971_819': 38.7561,
    '971_820': 38.545,
    '971_821': 38.3181,
    '971_822': 38.0731,
    '971_823': 37.8059,
    '971_824': 37.5245,
    '971_825': 37.2349,
    '971_826': 36.938,
    '971_827': 36.6349,
    '971_828': 36.3279,
    '971_829': 36.02,
    '971_830': 35.7168,
    '971_831': 35.4183,
    '971_832': 35.1251,
    '971_833': 34.8377,
    '971_834': 34.5518,
    '971_835': 34.263,
    '971_836': 33.9673,
    '971_837': 33.6591,
    '971_838': 33.3513,
    '971_839': 33.0429,
    '971_840': 32.7439,
    '971_841': 32.4343,
    '971_842': 32.1272,
    '971_843': 31.8198,
    '971_844': 31.5092,
    '971_845': 31.1982,
    '971_846': 30.8784,
    '971_847': 30.5534,
    '971_848': 30.2372,
    '972_525': 33.6676,
    '972_526': 33.7284,
    '972_527': 33.7901,
    '972_528': 33.8517,
    '972_529': 33.9105,
    '972_530': 33.9619,
    '972_531': 34.0052,
    '972_532': 34.022,
    '972_533': 34.0179,
    '972_534': 33.9994,
    '972_535': 33.9676,
    '972_536': 33.9257,
    '972_537': 33.8858,
    '972_538': 33.8534,
    '972_612': 34.95,
    '972_613': 34.9612,
    '972_614': 34.9733,
    '972_615': 34.9883,
    '972_616': 35.0033,
    '972_617': 35.0121,
    '972_618': 35.0265,
    '972_619': 35.0429,
    '972_620': 35.0473,
    '972_621': 35.0506,
    '972_622': 35.0596,
    '972_623': 35.0683,
    '972_624': 35.0888,
    '972_625': 35.1176,
    '972_626': 35.1564,
    '972_627': 35.2068,
    '972_628': 35.2691,
    '972_629': 35.3371,
    '972_630': 35.4037,
    '972_631': 35.4721,
    '972_632': 35.5423,
    '972_633': 35.615,
    '972_634': 35.6932,
    '972_635': 35.7801,
    '972_636': 35.8807,
    '972_637': 35.9889,
    '972_638': 36.0996,
    '972_639': 36.2078,
    '972_640': 36.3085,
    '972_641': 36.4043,
    '972_642': 36.4984,
    '972_643': 36.5954,
    '972_644': 36.7001,
    '972_645': 36.8092,
    '972_646': 36.9178,
    '972_647': 37.0208,
    '972_648': 37.1197,
    '972_649': 37.2266,
    '972_650': 37.3518,
    '972_651': 37.4899,
    '972_652': 37.6466,
    '972_653': 37.8097,
    '972_654': 37.9451,
    '972_655': 38.0731,
    '972_656': 38.1965,
    '972_657': 38.3324,
    '972_658': 38.4815,
    '972_659': 38.6378,
    '972_660': 38.7953,
    '972_661': 38.9552,
    '972_662': 39.1129,
    '972_663': 39.2564,
    '972_664': 39.3862,
    '972_665': 39.5241,
    '972_666': 39.7038,
    '972_667': 39.8893,
    '972_668': 40.0628,
    '972_669': 40.2024,
    '972_670': 40.3189,
    '972_671': 40.4292,
    '972_672': 40.4838,
    '972_673': 40.5202,
    '972_674': 40.5429,
    '972_675': 40.5413,
    '972_676': 40.5762,
    '972_677': 40.6532,
    '972_678': 40.7152,
    '972_679': 40.772,
    '972_680': 40.8036,
    '972_681': 40.8338,
    '972_682': 40.8672,
    '972_683': 40.9044,
    '972_684': 40.937,
    '972_685': 40.9601,
    '972_686': 40.9753,
    '972_687': 40.9923,
    '972_688': 41.0276,
    '972_689': 41.0886,
    '972_690': 41.1709,
    '972_691': 41.2692,
    '972_692': 41.372,
    '972_693': 41.4746,
    '972_694': 41.5891,
    '972_695': 41.7197,
    '972_696': 41.8626,
    '972_697': 42.0133,
    '972_698': 42.1619,
    '972_699': 42.3064,
    '972_700': 42.4339,
    '972_701': 42.5566,
    '972_702': 42.6812,
    '972_703': 42.7509,
    '972_704': 42.8373,
    '972_705': 42.8902,
    '972_706': 42.9112,
    '972_707': 42.925,
    '972_708': 42.9099,
    '972_709': 42.864,
    '972_710': 42.8212,
    '972_711': 42.7322,
    '972_712': 42.6262,
    '972_713': 42.5158,
    '972_714': 42.3964,
    '972_715': 42.303,
    '972_716': 42.2477,
    '972_717': 42.2318,
    '972_718': 42.2596,
    '972_719': 42.3401,
    '972_720': 42.4774,
    '972_721': 42.6355,
    '972_722': 42.7909,
    '972_723': 42.9254,
    '972_724': 43.0249,
    '972_725': 43.0868,
    '972_726': 43.1464,
    '972_727': 43.1631,
    '972_728': 43.1606,
    '972_729': 43.1469,
    '972_730': 43.1383,
    '972_731': 43.128,
    '972_732': 43.1209,
    '972_733': 43.1078,
    '972_734': 43.0991,
    '972_735': 43.0937,
    '972_736': 43.1001,
    '972_737': 43.1225,
    '972_738': 43.1559,
    '972_739': 43.2033,
    '972_740': 43.2705,
    '972_741': 43.346,
    '972_742': 43.4137,
    '972_743': 43.469,
    '972_744': 43.4966,
    '972_745': 43.4949,
    '972_746': 43.472,
    '972_747': 43.4324,
    '972_748': 43.3773,
    '972_749': 43.3141,
    '972_750': 43.2586,
    '972_751': 43.2089,
    '972_752': 43.1692,
    '972_753': 43.1203,
    '972_754': 43.0445,
    '972_755': 42.953,
    '972_756': 42.8586,
    '972_757': 42.7573,
    '972_758': 42.6436,
    '972_759': 42.524,
    '972_760': 42.3939,
    '972_761': 42.2581,
    '972_762': 42.1259,
    '972_763': 42.0073,
    '972_764': 41.9022,
    '972_765': 41.8057,
    '972_766': 41.714,
    '972_767': 41.6212,
    '972_768': 41.5299,
    '972_769': 41.4423,
    '972_770': 41.3585,
    '972_771': 41.2793,
    '972_772': 41.2072,
    '972_773': 41.142,
    '972_774': 41.0815,
    '972_775': 41.0246,
    '972_776': 40.9711,
    '972_777': 40.921,
    '972_778': 40.876,
    '972_779': 40.8377,
    '972_780': 40.8057,
    '972_781': 40.7799,
    '972_782': 40.7608,
    '972_783': 40.7485,
    '972_784': 40.7436,
    '972_785': 40.7438,
    '972_786': 40.7467,
    '972_787': 40.7528,
    '972_788': 40.7617,
    '972_789': 40.7654,
    '972_790': 40.7639,
    '972_791': 40.7605,
    '972_792': 40.7585,
    '972_793': 40.7608,
    '972_794': 40.7693,
    '972_795': 40.7823,
    '972_796': 40.7958,
    '972_797': 40.8085,
    '972_798': 40.8256,
    '972_799': 40.8448,
    '972_800': 40.8665,
    '972_801': 40.8838,
    '972_802': 40.8906,
    '972_803': 40.8893,
    '972_804': 40.884,
    '972_805': 40.8537,
    '972_806': 40.7782,
    '972_807': 40.6805,
    '972_808': 40.5809,
    '972_809': 40.4716,
    '972_810': 40.3532,
    '972_811': 40.2282,
    '972_812': 40.0959,
    '972_813': 39.9522,
    '972_814': 39.7965,
    '972_815': 39.6277,
    '972_816': 39.4486,
    '972_817': 39.2623,
    '972_818': 39.0699,
    '972_819': 38.8698,
    '972_820': 38.658,
    '972_821': 38.4313,
    '972_822': 38.1853,
    '972_823': 37.9172,
    '972_824': 37.6359,
    '972_825': 37.3454,
    '972_826': 37.0476,
    '972_827': 36.7438,
    '972_828': 36.4363,
    '972_829': 36.126,
    '972_830': 35.8177,
    '972_831': 35.5136,
    '972_832': 35.2167,
    '972_833': 34.9273,
    '972_834': 34.637,
    '972_835': 34.3459,
    '972_836': 34.0496,
    '972_837': 33.7441,
    '972_838': 33.4362,
    '972_839': 33.1284,
    '972_840': 32.8354,
    '972_841': 32.5347,
    '972_842': 32.2305,
    '972_843': 31.9224,
    '972_844': 31.6134,
    '972_845': 31.3055,
    '972_846': 30.9903,
    '972_847': 30.6703,
    '972_848': 30.3524,
    '973_525': 33.6458,
    '973_526': 33.7146,
    '973_527': 33.7892,
    '973_528': 33.8717,
    '973_529': 33.9411,
    '973_530': 33.9951,
    '973_531': 34.0372,
    '973_532': 34.0582,
    '973_533': 34.0608,
    '973_534': 34.0441,
    '973_535': 34.0065,
    '973_536': 33.9492,
    '973_537': 33.8982,
    '973_538': 33.8697,
    '973_612': 34.9214,
    '973_613': 34.9315,
    '973_614': 34.9433,
    '973_615': 34.9597,
    '973_616': 34.9777,
    '973_617': 34.9947,
    '973_618': 35.0119,
    '973_619': 35.02,
    '973_620': 35.0252,
    '973_621': 35.0339,
    '973_622': 35.0487,
    '973_623': 35.0618,
    '973_624': 35.0808,
    '973_625': 35.1089,
    '973_626': 35.1493,
    '973_627': 35.2033,
    '973_628': 35.2691,
    '973_629': 35.3389,
    '973_630': 35.4092,
    '973_631': 35.4794,
    '973_632': 35.5486,
    '973_633': 35.6178,
    '973_634': 35.6906,
    '973_635': 35.7722,
    '973_636': 35.8673,
    '973_637': 35.9729,
    '973_638': 36.0825,
    '973_639': 36.188,
    '973_640': 36.2866,
    '973_641': 36.3775,
    '973_642': 36.4665,
    '973_643': 36.5591,
    '973_644': 36.6671,
    '973_645': 36.7807,
    '973_646': 36.8934,
    '973_647': 36.9956,
    '973_648': 37.0924,
    '973_649': 37.1977,
    '973_650': 37.3201,
    '973_651': 37.4584,
    '973_652': 37.6105,
    '973_653': 37.7618,
    '973_654': 37.8854,
    '973_655': 38.0057,
    '973_656': 38.1336,
    '973_657': 38.2696,
    '973_658': 38.4078,
    '973_659': 38.5547,
    '973_660': 38.7086,
    '973_661': 38.8789,
    '973_662': 39.0432,
    '973_663': 39.1956,
    '973_664': 39.3325,
    '973_665': 39.4713,
    '973_666': 39.6511,
    '973_667': 39.8286,
    '973_668': 39.9975,
    '973_669': 40.1368,
    '973_670': 40.2534,
    '973_671': 40.3541,
    '973_672': 40.4119,
    '973_673': 40.4578,
    '973_674': 40.4886,
    '973_675': 40.4994,
    '973_676': 40.541,
    '973_677': 40.6229,
    '973_678': 40.7031,
    '973_679': 40.7742,
    '973_680': 40.7991,
    '973_681': 40.8205,
    '973_682': 40.8499,
    '973_683': 40.8857,
    '973_684': 40.9128,
    '973_685': 40.9333,
    '973_686': 40.9489,
    '973_687': 40.9729,
    '973_688': 41.0152,
    '973_689': 41.0849,
    '973_690': 41.1716,
    '973_691': 41.2739,
    '973_692': 41.3766,
    '973_693': 41.476,
    '973_694': 41.5816,
    '973_695': 41.7019,
    '973_696': 41.8391,
    '973_697': 41.981,
    '973_698': 42.1286,
    '973_699': 42.2669,
    '973_700': 42.3979,
    '973_701': 42.532,
    '973_702': 42.6444,
    '973_703': 42.7195,
    '973_704': 42.8089,
    '973_705': 42.877,
    '973_706': 42.893,
    '973_707': 42.9261,
    '973_708': 42.9342,
    '973_709': 42.8905,
    '973_710': 42.8286,
    '973_711': 42.7255,
    '973_712': 42.6048,
    '973_713': 42.4842,
    '973_714': 42.3525,
    '973_715': 42.2525,
    '973_716': 42.1966,
    '973_717': 42.1825,
    '973_718': 42.2135,
    '973_719': 42.2997,
    '973_720': 42.438,
    '973_721': 42.5993,
    '973_722': 42.7602,
    '973_723': 42.8924,
    '973_724': 42.9851,
    '973_725': 43.0413,
    '973_726': 43.0952,
    '973_727': 43.1037,
    '973_728': 43.0982,
    '973_729': 43.0791,
    '973_730': 43.06,
    '973_731': 43.0451,
    '973_732': 43.0327,
    '973_733': 43.0168,
    '973_734': 43.0067,
    '973_735': 43.0067,
    '973_736': 43.0184,
    '973_737': 43.043,
    '973_738': 43.0772,
    '973_739': 43.1255,
    '973_740': 43.1973,
    '973_741': 43.2798,
    '973_742': 43.355,
    '973_743': 43.4126,
    '973_744': 43.4425,
    '973_745': 43.4386,
    '973_746': 43.4135,
    '973_747': 43.3662,
    '973_748': 43.3057,
    '973_749': 43.2362,
    '973_750': 43.1703,
    '973_751': 43.1051,
    '973_752': 43.0456,
    '973_753': 42.9866,
    '973_754': 42.9126,
    '973_755': 42.8257,
    '973_756': 42.7317,
    '973_757': 42.6381,
    '973_758': 42.5333,
    '973_759': 42.4198,
    '973_760': 42.293,
    '973_761': 42.1617,
    '973_762': 42.042,
    '973_763': 41.9357,
    '973_764': 41.8426,
    '973_765': 41.7598,
    '973_766': 41.6778,
    '973_767': 41.596,
    '973_768': 41.5152,
    '973_769': 41.438,
    '973_770': 41.3648,
    '973_771': 41.2967,
    '973_772': 41.2355,
    '973_773': 41.1801,
    '973_774': 41.1291,
    '973_775': 41.0815,
    '973_776': 41.0375,
    '973_777': 40.9962,
    '973_778': 40.9588,
    '973_779': 40.9275,
    '973_780': 40.9019,
    '973_781': 40.8819,
    '973_782': 40.8675,
    '973_783': 40.8593,
    '973_784': 40.8586,
    '973_785': 40.8607,
    '973_786': 40.8635,
    '973_787': 40.8701,
    '973_788': 40.8784,
    '973_789': 40.8828,
    '973_790': 40.8792,
    '973_791': 40.8718,
    '973_792': 40.8656,
    '973_793': 40.8626,
    '973_794': 40.8656,
    '973_795': 40.872,
    '973_796': 40.8792,
    '973_797': 40.8878,
    '973_798': 40.9018,
    '973_799': 40.9245,
    '973_800': 40.9559,
    '973_801': 40.9804,
    '973_802': 40.9941,
    '973_803': 41.0078,
    '973_804': 41.0164,
    '973_805': 40.9705,
    '973_806': 40.874,
    '973_807': 40.7769,
    '973_808': 40.6799,
    '973_809': 40.5784,
    '973_810': 40.4656,
    '973_811': 40.3408,
    '973_812': 40.2086,
    '973_813': 40.0656,
    '973_814': 39.9101,
    '973_815': 39.741,
    '973_816': 39.5613,
    '973_817': 39.3745,
    '973_818': 39.1816,
    '973_819': 38.9797,
    '973_820': 38.767,
    '973_821': 38.542,
    '973_822': 38.2979,
    '973_823': 38.0298,
    '973_824': 37.7486,
    '973_825': 37.4574,
    '973_826': 37.1586,
    '973_827': 36.8546,
    '973_828': 36.5474,
    '973_829': 36.235,
    '973_830': 35.9221,
    '973_831': 35.6106,
    '973_832': 35.3047,
    '973_833': 35.0078,
    '973_834': 34.7186,
    '973_835': 34.4265,
    '973_836': 34.1289,
    '973_837': 33.8245,
    '973_838': 33.5186,
    '973_839': 33.2072,
    '973_840': 32.9275,
    '973_841': 32.6356,
    '973_842': 32.3339,
    '973_843': 32.0251,
    '973_844': 31.7211,
    '973_845': 31.4154,
    '973_846': 31.102,
    '973_847': 30.787,
    '973_848': 30.4684,
    '974_525': 33.6167,
    '974_526': 33.6923,
    '974_527': 33.7771,
    '974_528': 33.8749,
    '974_529': 33.9593,
    '974_530': 34.0163,
    '974_531': 34.0577,
    '974_532': 34.0862,
    '974_533': 34.0984,
    '974_534': 34.0802,
    '974_535': 34.0351,
    '974_536': 33.9699,
    '974_537': 33.9143,
    '974_538': 33.8785,
    '974_612': 34.8939,
    '974_613': 34.9018,
    '974_614': 34.9119,
    '974_615': 34.9301,
    '974_616': 34.9515,
    '974_617': 34.9734,
    '974_618': 34.9894,
    '974_619': 34.9993,
    '974_620': 35.0017,
    '974_621': 35.0155,
    '974_622': 35.0385,
    '974_623': 35.0533,
    '974_624': 35.0701,
    '974_625': 35.0955,
    '974_626': 35.1361,
    '974_627': 35.1937,
    '974_628': 35.2634,
    '974_629': 35.337,
    '974_630': 35.4125,
    '974_631': 35.487,
    '974_632': 35.5562,
    '974_633': 35.6221,
    '974_634': 35.6895,
    '974_635': 35.7631,
    '974_636': 35.8525,
    '974_637': 35.9558,
    '974_638': 36.0625,
    '974_639': 36.1659,
    '974_640': 36.2616,
    '974_641': 36.3515,
    '974_642': 36.4393,
    '974_643': 36.5302,
    '974_644': 36.6374,
    '974_645': 36.7508,
    '974_646': 36.865,
    '974_647': 36.9626,
    '974_648': 37.0575,
    '974_649': 37.1606,
    '974_650': 37.2791,
    '974_651': 37.4134,
    '974_652': 37.5598,
    '974_653': 37.6989,
    '974_654': 37.8161,
    '974_655': 37.926,
    '974_656': 38.0541,
    '974_657': 38.1892,
    '974_658': 38.3233,
    '974_659': 38.4637,
    '974_660': 38.6108,
    '974_661': 38.7848,
    '974_662': 38.9615,
    '974_663': 39.1213,
    '974_664': 39.2665,
    '974_665': 39.4113,
    '974_666': 39.5829,
    '974_667': 39.7521,
    '974_668': 39.9142,
    '974_669': 40.0456,
    '974_670': 40.1648,
    '974_671': 40.262,
    '974_672': 40.3344,
    '974_673': 40.3965,
    '974_674': 40.4152,
    '974_675': 40.4454,
    '974_676': 40.4977,
    '974_677': 40.589,
    '974_678': 40.6779,
    '974_679': 40.7463,
    '974_680': 40.7805,
    '974_681': 40.8048,
    '974_682': 40.8385,
    '974_683': 40.8688,
    '974_684': 40.8885,
    '974_685': 40.9009,
    '974_686': 40.9218,
    '974_687': 40.9574,
    '974_688': 41.015,
    '974_689': 41.0949,
    '974_690': 41.1816,
    '974_691': 41.2792,
    '974_692': 41.3757,
    '974_693': 41.4693,
    '974_694': 41.5675,
    '974_695': 41.6764,
    '974_696': 41.8063,
    '974_697': 41.947,
    '974_698': 42.0914,
    '974_699': 42.2352,
    '974_700': 42.3712,
    '974_701': 42.495,
    '974_702': 42.6082,
    '974_703': 42.6975,
    '974_704': 42.7902,
    '974_705': 42.8947,
    '974_706': 42.9064,
    '974_707': 42.9516,
    '974_708': 42.9515,
    '974_709': 42.9165,
    '974_710': 42.8419,
    '974_711': 42.7288,
    '974_712': 42.5996,
    '974_713': 42.4649,
    '974_714': 42.3142,
    '974_715': 42.2064,
    '974_716': 42.1488,
    '974_717': 42.1373,
    '974_718': 42.1741,
    '974_719': 42.2654,
    '974_720': 42.4056,
    '974_721': 42.5706,
    '974_722': 42.7251,
    '974_723': 42.857,
    '974_724': 42.9398,
    '974_725': 43.0032,
    '974_726': 43.0359,
    '974_727': 43.0451,
    '974_728': 43.0288,
    '974_729': 43.0054,
    '974_730': 42.9835,
    '974_731': 42.9642,
    '974_732': 42.9475,
    '974_733': 42.9322,
    '974_734': 42.9232,
    '974_735': 42.9302,
    '974_736': 42.9478,
    '974_737': 42.9742,
    '974_738': 43.0095,
    '974_739': 43.0603,
    '974_740': 43.1313,
    '974_741': 43.2163,
    '974_742': 43.295,
    '974_743': 43.3545,
    '974_744': 43.3838,
    '974_745': 43.3852,
    '974_746': 43.3501,
    '974_747': 43.2925,
    '974_748': 43.2295,
    '974_749': 43.1568,
    '974_750': 43.0885,
    '974_751': 43.0192,
    '974_752': 42.9396,
    '974_753': 42.8611,
    '974_754': 42.7844,
    '974_755': 42.6961,
    '974_756': 42.6037,
    '974_757': 42.5123,
    '974_758': 42.4146,
    '974_759': 42.3102,
    '974_760': 42.1966,
    '974_761': 42.0804,
    '974_762': 41.9718,
    '974_763': 41.8789,
    '974_764': 41.797,
    '974_765': 41.7226,
    '974_766': 41.6501,
    '974_767': 41.5786,
    '974_768': 41.5087,
    '974_769': 41.4417,
    '974_770': 41.3789,
    '974_771': 41.322,
    '974_772': 41.2714,
    '974_773': 41.2258,
    '974_774': 41.1838,
    '974_775': 41.1454,
    '974_776': 41.1107,
    '974_777': 41.0772,
    '974_778': 41.0464,
    '974_779': 41.0206,
    '974_780': 41.0002,
    '974_781': 40.9853,
    '974_782': 40.9746,
    '974_783': 40.9693,
    '974_784': 40.9713,
    '974_785': 40.9751,
    '974_786': 40.9786,
    '974_787': 40.9858,
    '974_788': 40.9942,
    '974_789': 40.9991,
    '974_790': 40.9933,
    '974_791': 40.9821,
    '974_792': 40.9715,
    '974_793': 40.9636,
    '974_794': 40.9613,
    '974_795': 40.9622,
    '974_796': 40.9652,
    '974_797': 40.9717,
    '974_798': 40.9858,
    '974_799': 41.0103,
    '974_800': 41.044,
    '974_801': 41.073,
    '974_802': 41.0895,
    '974_803': 41.1081,
    '974_804': 41.1185,
    '974_805': 41.0706,
    '974_806': 40.9748,
    '974_807': 40.8743,
    '974_808': 40.778,
    '974_809': 40.6785,
    '974_810': 40.5694,
    '974_811': 40.447,
    '974_812': 40.3158,
    '974_813': 40.1744,
    '974_814': 40.0198,
    '974_815': 39.8498,
    '974_816': 39.6693,
    '974_817': 39.4822,
    '974_818': 39.2879,
    '974_819': 39.0847,
    '974_820': 38.8718,
    '974_821': 38.6494,
    '974_822': 38.4102,
    '974_823': 38.1429,
    '974_824': 37.862,
    '974_825': 37.57,
    '974_826': 37.2697,
    '974_827': 36.9646,
    '974_828': 36.6592,
    '974_829': 36.3498,
    '974_830': 36.0358,
    '974_831': 35.7229,
    '974_832': 35.4132,
    '974_833': 35.1105,
    '974_834': 34.8128,
    '974_835': 34.515,
    '974_836': 34.2153,
    '974_837': 33.9147,
    '974_838': 33.6124,
    '974_839': 33.3129,
    '974_840': 33.0295,
    '974_841': 32.7388,
    '974_842': 32.4379,
    '974_843': 32.1333,
    '974_844': 31.8316,
    '974_845': 31.5272,
    '974_846': 31.2162,
    '974_847': 30.9003,
    '974_848': 30.5805,
    '975_525': 33.5794,
    '975_526': 33.6601,
    '975_527': 33.7532,
    '975_528': 33.8575,
    '975_529': 33.9478,
    '975_530': 34.012,
    '975_531': 34.0655,
    '975_532': 34.1036,
    '975_533': 34.119,
    '975_534': 34.102,
    '975_535': 34.0488,
    '975_536': 33.9837,
    '975_537': 33.9283,
    '975_538': 33.8852,
    '975_612': 34.8677,
    '975_613': 34.8747,
    '975_614': 34.887,
    '975_615': 34.9052,
    '975_616': 34.9289,
    '975_617': 34.9511,
    '975_618': 34.9611,
    '975_619': 34.9682,
    '975_620': 34.975,
    '975_621': 34.9895,
    '975_622': 35.0187,
    '975_623': 35.0385,
    '975_624': 35.0547,
    '975_625': 35.0768,
    '975_626': 35.1182,
    '975_627': 35.1777,
    '975_628': 35.2527,
    '975_629': 35.3307,
    '975_630': 35.4125,
    '975_631': 35.4942,
    '975_632': 35.5645,
    '975_633': 35.6265,
    '975_634': 35.6858,
    '975_635': 35.7556,
    '975_636': 35.8469,
    '975_637': 35.9453,
    '975_638': 36.0465,
    '975_639': 36.145,
    '975_640': 36.2391,
    '975_641': 36.3301,
    '975_642': 36.42,
    '975_643': 36.5119,
    '975_644': 36.6129,
    '975_645': 36.721,
    '975_646': 36.8262,
    '975_647': 36.9207,
    '975_648': 37.0158,
    '975_649': 37.1146,
    '975_650': 37.2297,
    '975_651': 37.3603,
    '975_652': 37.4987,
    '975_653': 37.6246,
    '975_654': 37.7339,
    '975_655': 37.8381,
    '975_656': 37.9634,
    '975_657': 38.0987,
    '975_658': 38.2381,
    '975_659': 38.3761,
    '975_660': 38.5173,
    '975_661': 38.6887,
    '975_662': 38.8711,
    '975_663': 39.0367,
    '975_664': 39.193,
    '975_665': 39.3457,
    '975_666': 39.5037,
    '975_667': 39.6666,
    '975_668': 39.8166,
    '975_669': 39.9518,
    '975_670': 40.0801,
    '975_671': 40.182,
    '975_672': 40.2721,
    '975_673': 40.3305,
    '975_674': 40.3533,
    '975_675': 40.3917,
    '975_676': 40.4436,
    '975_677': 40.5529,
    '975_678': 40.6534,
    '975_679': 40.7251,
    '975_680': 40.7665,
    '975_681': 40.7921,
    '975_682': 40.8216,
    '975_683': 40.8495,
    '975_684': 40.8609,
    '975_685': 40.8736,
    '975_686': 40.9027,
    '975_687': 40.9542,
    '975_688': 41.0261,
    '975_689': 41.1054,
    '975_690': 41.1853,
    '975_691': 41.273,
    '975_692': 41.3579,
    '975_693': 41.4492,
    '975_694': 41.5402,
    '975_695': 41.6447,
    '975_696': 41.7721,
    '975_697': 41.9118,
    '975_698': 42.0638,
    '975_699': 42.2086,
    '975_700': 42.3456,
    '975_701': 42.479,
    '975_702': 42.604,
    '975_703': 42.7087,
    '975_704': 42.7898,
    '975_705': 42.8942,
    '975_706': 42.964,
    '975_707': 42.9841,
    '975_708': 42.944,
    '975_709': 42.896,
    '975_710': 42.837,
    '975_711': 42.7331,
    '975_712': 42.5821,
    '975_713': 42.4357,
    '975_714': 42.2746,
    '975_715': 42.1603,
    '975_716': 42.1023,
    '975_717': 42.0956,
    '975_718': 42.1423,
    '975_719': 42.2403,
    '975_720': 42.3806,
    '975_721': 42.5392,
    '975_722': 42.6885,
    '975_723': 42.8026,
    '975_724': 42.8944,
    '975_725': 42.9612,
    '975_726': 42.9741,
    '975_727': 42.967,
    '975_728': 42.9503,
    '975_729': 42.9255,
    '975_730': 42.9008,
    '975_731': 42.8836,
    '975_732': 42.8649,
    '975_733': 42.851,
    '975_734': 42.847,
    '975_735': 42.8604,
    '975_736': 42.8841,
    '975_737': 42.916,
    '975_738': 42.956,
    '975_739': 43.0102,
    '975_740': 43.0839,
    '975_741': 43.1695,
    '975_742': 43.2474,
    '975_743': 43.3063,
    '975_744': 43.3344,
    '975_745': 43.3302,
    '975_746': 43.2885,
    '975_747': 43.2279,
    '975_748': 43.1626,
    '975_749': 43.0938,
    '975_750': 43.0182,
    '975_751': 42.9418,
    '975_752': 42.8511,
    '975_753': 42.7566,
    '975_754': 42.6731,
    '975_755': 42.5841,
    '975_756': 42.4941,
    '975_757': 42.405,
    '975_758': 42.3128,
    '975_759': 42.2169,
    '975_760': 42.115,
    '975_761': 42.0124,
    '975_762': 41.9179,
    '975_763': 41.8357,
    '975_764': 41.7626,
    '975_765': 41.6958,
    '975_766': 41.632,
    '975_767': 41.5697,
    '975_768': 41.5099,
    '975_769': 41.4537,
    '975_770': 41.4014,
    '975_771': 41.3554,
    '975_772': 41.3153,
    '975_773': 41.2798,
    '975_774': 41.2475,
    '975_775': 41.2191,
    '975_776': 41.1919,
    '975_777': 41.1637,
    '975_778': 41.1376,
    '975_779': 41.1158,
    '975_780': 41.0995,
    '975_781': 41.0886,
    '975_782': 41.0816,
    '975_783': 41.0792,
    '975_784': 41.0823,
    '975_785': 41.0874,
    '975_786': 41.0923,
    '975_787': 41.0986,
    '975_788': 41.1062,
    '975_789': 41.1094,
    '975_790': 41.1039,
    '975_791': 41.0909,
    '975_792': 41.0769,
    '975_793': 41.0649,
    '975_794': 41.0577,
    '975_795': 41.054,
    '975_796': 41.0541,
    '975_797': 41.0604,
    '975_798': 41.0756,
    '975_799': 41.1032,
    '975_800': 41.1354,
    '975_801': 41.1628,
    '975_802': 41.1766,
    '975_803': 41.1853,
    '975_804': 41.1887,
    '975_805': 41.1564,
    '975_806': 41.0786,
    '975_807': 40.9742,
    '975_808': 40.879,
    '975_809': 40.7806,
    '975_810': 40.6723,
    '975_811': 40.5497,
    '975_812': 40.4183,
    '975_813': 40.2767,
    '975_814': 40.1226,
    '975_815': 39.9535,
    '975_816': 39.7726,
    '975_817': 39.5842,
    '975_818': 39.3887,
    '975_819': 39.1854,
    '975_820': 38.9741,
    '975_821': 38.7543,
    '975_822': 38.5193,
    '975_823': 38.2555,
    '975_824': 37.9765,
    '975_825': 37.6846,
    '975_826': 37.3829,
    '975_827': 37.0756,
    '975_828': 36.7729,
    '975_829': 36.4669,
    '975_830': 36.1511,
    '975_831': 35.8386,
    '975_832': 35.5262,
    '975_833': 35.22,
    '975_834': 34.9157,
    '975_835': 34.6128,
    '975_836': 34.3105,
    '975_837': 34.0136,
    '975_838': 33.7178,
    '975_839': 33.4295,
    '975_840': 33.1415,
    '975_841': 32.8483,
    '975_842': 32.5463,
    '975_843': 32.2441,
    '975_844': 31.9464,
    '975_845': 31.6434,
    '975_846': 31.334,
    '975_847': 31.0174,
    '975_848': 30.6961,
    '976_525': 33.5316,
    '976_526': 33.6152,
    '976_527': 33.7098,
    '976_528': 33.8165,
    '976_529': 33.908,
    '976_530': 33.9761,
    '976_531': 34.0487,
    '976_532': 34.0954,
    '976_533': 34.1095,
    '976_534': 34.0878,
    '976_535': 34.0416,
    '976_536': 33.9818,
    '976_537': 33.9279,
    '976_538': 33.8828,
    '976_612': 34.8409,
    '976_613': 34.848,
    '976_614': 34.8635,
    '976_615': 34.8831,
    '976_616': 34.9035,
    '976_617': 34.9196,
    '976_618': 34.9293,
    '976_619': 34.9393,
    '976_620': 34.9483,
    '976_621': 34.9638,
    '976_622': 34.9931,
    '976_623': 35.019,
    '976_624': 35.0355,
    '976_625': 35.0558,
    '976_626': 35.094,
    '976_627': 35.1519,
    '976_628': 35.2299,
    '976_629': 35.3144,
    '976_630': 35.4026,
    '976_631': 35.4909,
    '976_632': 35.5698,
    '976_633': 35.6334,
    '976_634': 35.6881,
    '976_635': 35.7643,
    '976_636': 35.853,
    '976_637': 35.9436,
    '976_638': 36.036,
    '976_639': 36.1279,
    '976_640': 36.2187,
    '976_641': 36.3095,
    '976_642': 36.4003,
    '976_643': 36.4895,
    '976_644': 36.5799,
    '976_645': 36.6772,
    '976_646': 36.7756,
    '976_647': 36.8713,
    '976_648': 36.9675,
    '976_649': 37.0658,
    '976_650': 37.1769,
    '976_651': 37.3008,
    '976_652': 37.4277,
    '976_653': 37.5414,
    '976_654': 37.6456,
    '976_655': 37.7532,
    '976_656': 37.8758,
    '976_657': 38.0153,
    '976_658': 38.1569,
    '976_659': 38.2948,
    '976_660': 38.4378,
    '976_661': 38.6022,
    '976_662': 38.7808,
    '976_663': 38.9469,
    '976_664': 39.1139,
    '976_665': 39.269,
    '976_666': 39.4148,
    '976_667': 39.5736,
    '976_668': 39.7192,
    '976_669': 39.8659,
    '976_670': 40.0014,
    '976_671': 40.1205,
    '976_672': 40.209,
    '976_673': 40.2604,
    '976_674': 40.3036,
    '976_675': 40.3363,
    '976_676': 40.389,
    '976_677': 40.5006,
    '976_678': 40.6149,
    '976_679': 40.7018,
    '976_680': 40.7566,
    '976_681': 40.783,
    '976_682': 40.8074,
    '976_683': 40.8254,
    '976_684': 40.843,
    '976_685': 40.863,
    '976_686': 40.901,
    '976_687': 40.9632,
    '976_688': 41.0365,
    '976_689': 41.1079,
    '976_690': 41.1786,
    '976_691': 41.2539,
    '976_692': 41.3335,
    '976_693': 41.4166,
    '976_694': 41.5067,
    '976_695': 41.6107,
    '976_696': 41.736,
    '976_697': 41.8834,
    '976_698': 42.0369,
    '976_699': 42.1909,
    '976_700': 42.3389,
    '976_701': 42.4587,
    '976_702': 42.5853,
    '976_703': 42.7155,
    '976_704': 42.7918,
    '976_705': 42.8625,
    '976_706': 42.9516,
    '976_707': 42.9774,
    '976_708': 42.9749,
    '976_709': 42.8952,
    '976_710': 42.8001,
    '976_711': 42.7014,
    '976_712': 42.5528,
    '976_713': 42.3968,
    '976_714': 42.2317,
    '976_715': 42.1111,
    '976_716': 42.0537,
    '976_717': 42.0545,
    '976_718': 42.113,
    '976_719': 42.2181,
    '976_720': 42.3556,
    '976_721': 42.5106,
    '976_722': 42.6537,
    '976_723': 42.7562,
    '976_724': 42.8303,
    '976_725': 42.8714,
    '976_726': 42.8871,
    '976_727': 42.8784,
    '976_728': 42.8613,
    '976_729': 42.8377,
    '976_730': 42.815,
    '976_731': 42.8001,
    '976_732': 42.7855,
    '976_733': 42.7746,
    '976_734': 42.7792,
    '976_735': 42.7999,
    '976_736': 42.8282,
    '976_737': 42.8652,
    '976_738': 42.9138,
    '976_739': 42.9763,
    '976_740': 43.0546,
    '976_741': 43.1393,
    '976_742': 43.2129,
    '976_743': 43.2711,
    '976_744': 43.2952,
    '976_745': 43.2837,
    '976_746': 43.2374,
    '976_747': 43.1769,
    '976_748': 43.1139,
    '976_749': 43.0408,
    '976_750': 42.9536,
    '976_751': 42.8612,
    '976_752': 42.7661,
    '976_753': 42.6677,
    '976_754': 42.5811,
    '976_755': 42.4942,
    '976_756': 42.4067,
    '976_757': 42.3198,
    '976_758': 42.231,
    '976_759': 42.141,
    '976_760': 42.0491,
    '976_761': 41.9584,
    '976_762': 41.8738,
    '976_763': 41.8009,
    '976_764': 41.7366,
    '976_765': 41.678,
    '976_766': 41.6223,
    '976_767': 41.5687,
    '976_768': 41.5185,
    '976_769': 41.4722,
    '976_770': 41.4305,
    '976_771': 41.3947,
    '976_772': 41.3655,
    '976_773': 41.3411,
    '976_774': 41.3197,
    '976_775': 41.2997,
    '976_776': 41.2783,
    '976_777': 41.2537,
    '976_778': 41.2307,
    '976_779': 41.2123,
    '976_780': 41.1991,
    '976_781': 41.191,
    '976_782': 41.1871,
    '976_783': 41.1872,
    '976_784': 41.1908,
    '976_785': 41.1967,
    '976_786': 41.2012,
    '976_787': 41.2067,
    '976_788': 41.2136,
    '976_789': 41.2155,
    '976_790': 41.207,
    '976_791': 41.1934,
    '976_792': 41.1775,
    '976_793': 41.1626,
    '976_794': 41.1522,
    '976_795': 41.1461,
    '976_796': 41.1446,
    '976_797': 41.1515,
    '976_798': 41.1696,
    '976_799': 41.1964,
    '976_800': 41.2255,
    '976_801': 41.2501,
    '976_802': 41.2607,
    '976_803': 41.2579,
    '976_804': 41.2499,
    '976_805': 41.2306,
    '976_806': 41.1755,
    '976_807': 41.0728,
    '976_808': 40.977,
    '976_809': 40.8846,
    '976_810': 40.7763,
    '976_811': 40.6493,
    '976_812': 40.5158,
    '976_813': 40.3744,
    '976_814': 40.2211,
    '976_815': 40.0519,
    '976_816': 39.8706,
    '976_817': 39.6809,
    '976_818': 39.4837,
    '976_819': 39.2806,
    '976_820': 39.072,
    '976_821': 38.8557,
    '976_822': 38.6254,
    '976_823': 38.3674,
    '976_824': 38.0921,
    '976_825': 37.8019,
    '976_826': 37.501,
    '976_827': 37.1937,
    '976_828': 36.8858,
    '976_829': 36.575,
    '976_830': 36.2597,
    '976_831': 35.9452,
    '976_832': 35.6326,
    '976_833': 35.3263,
    '976_834': 35.0218,
    '976_835': 34.7196,
    '976_836': 34.4189,
    '976_837': 34.1238,
    '976_838': 33.8335,
    '976_839': 33.5439,
    '976_840': 33.2531,
    '976_841': 32.9591,
    '976_842': 32.6607,
    '976_843': 32.3619,
    '976_844': 32.0621,
    '976_845': 31.7601,
    '976_846': 31.4536,
    '976_847': 31.1393,
    '976_848': 30.8192,
    '977_525': 33.4812,
    '977_526': 33.5635,
    '977_527': 33.6544,
    '977_528': 33.7525,
    '977_529': 33.8418,
    '977_530': 33.9228,
    '977_531': 34.0044,
    '977_532': 34.0552,
    '977_533': 34.0703,
    '977_534': 34.0476,
    '977_535': 34.0066,
    '977_536': 33.9586,
    '977_537': 33.9111,
    '977_538': 33.8691,
    '977_612': 34.8141,
    '977_613': 34.8243,
    '977_614': 34.84,
    '977_615': 34.86,
    '977_616': 34.8784,
    '977_617': 34.8906,
    '977_618': 34.8959,
    '977_619': 34.9092,
    '977_620': 34.9211,
    '977_621': 34.9401,
    '977_622': 34.9674,
    '977_623': 34.9928,
    '977_624': 35.0078,
    '977_625': 35.0271,
    '977_626': 35.066,
    '977_627': 35.1279,
    '977_628': 35.2068,
    '977_629': 35.2939,
    '977_630': 35.3859,
    '977_631': 35.4814,
    '977_632': 35.5692,
    '977_633': 35.6319,
    '977_634': 35.6949,
    '977_635': 35.7762,
    '977_636': 35.86,
    '977_637': 35.9436,
    '977_638': 36.028,
    '977_639': 36.1129,
    '977_640': 36.1991,
    '977_641': 36.2872,
    '977_642': 36.3754,
    '977_643': 36.4621,
    '977_644': 36.5405,
    '977_645': 36.6295,
    '977_646': 36.7242,
    '977_647': 36.8207,
    '977_648': 36.9161,
    '977_649': 37.0134,
    '977_650': 37.1215,
    '977_651': 37.2379,
    '977_652': 37.3532,
    '977_653': 37.4567,
    '977_654': 37.5588,
    '977_655': 37.6681,
    '977_656': 37.7904,
    '977_657': 37.9266,
    '977_658': 38.0676,
    '977_659': 38.2121,
    '977_660': 38.3605,
    '977_661': 38.5175,
    '977_662': 38.6903,
    '977_663': 38.8535,
    '977_664': 39.0239,
    '977_665': 39.1813,
    '977_666': 39.3221,
    '977_667': 39.4837,
    '977_668': 39.6385,
    '977_669': 39.7956,
    '977_670': 39.9462,
    '977_671': 40.0606,
    '977_672': 40.1409,
    '977_673': 40.2065,
    '977_674': 40.2473,
    '977_675': 40.2833,
    '977_676': 40.3415,
    '977_677': 40.4564,
    '977_678': 40.5774,
    '977_679': 40.6719,
    '977_680': 40.7319,
    '977_681': 40.7719,
    '977_682': 40.7938,
    '977_683': 40.8096,
    '977_684': 40.8267,
    '977_685': 40.8565,
    '977_686': 40.9045,
    '977_687': 40.9717,
    '977_688': 41.0388,
    '977_689': 41.1011,
    '977_690': 41.1575,
    '977_691': 41.2189,
    '977_692': 41.2882,
    '977_693': 41.3669,
    '977_694': 41.4618,
    '977_695': 41.5788,
    '977_696': 41.7136,
    '977_697': 41.8694,
    '977_698': 42.0336,
    '977_699': 42.1848,
    '977_700': 42.3502,
    '977_701': 42.4941,
    '977_702': 42.5967,
    '977_703': 42.728,
    '977_704': 42.8207,
    '977_705': 42.8661,
    '977_706': 42.8906,
    '977_707': 42.9643,
    '977_708': 42.9871,
    '977_709': 42.8724,
    '977_710': 42.7987,
    '977_711': 42.6796,
    '977_712': 42.5127,
    '977_713': 42.3496,
    '977_714': 42.181,
    '977_715': 42.0593,
    '977_716': 42.0043,
    '977_717': 42.0129,
    '977_718': 42.083,
    '977_719': 42.191,
    '977_720': 42.3261,
    '977_721': 42.4677,
    '977_722': 42.5967,
    '977_723': 42.6949,
    '977_724': 42.7598,
    '977_725': 42.7847,
    '977_726': 42.791,
    '977_727': 42.7833,
    '977_728': 42.7678,
    '977_729': 42.7482,
    '977_730': 42.7294,
    '977_731': 42.7174,
    '977_732': 42.7089,
    '977_733': 42.7076,
    '977_734': 42.7229,
    '977_735': 42.7509,
    '977_736': 42.786,
    '977_737': 42.8274,
    '977_738': 42.8829,
    '977_739': 42.9545,
    '977_740': 43.035,
    '977_741': 43.1126,
    '977_742': 43.1783,
    '977_743': 43.2258,
    '977_744': 43.2455,
    '977_745': 43.235,
    '977_746': 43.1911,
    '977_747': 43.1322,
    '977_748': 43.0748,
    '977_749': 42.9976,
    '977_750': 42.9016,
    '977_751': 42.7886,
    '977_752': 42.6885,
    '977_753': 42.5944,
    '977_754': 42.5081,
    '977_755': 42.4224,
    '977_756': 42.3372,
    '977_757': 42.2516,
    '977_758': 42.168,
    '977_759': 42.083,
    '977_760': 41.9977,
    '977_761': 41.9138,
    '977_762': 41.8381,
    '977_763': 41.7748,
    '977_764': 41.7196,
    '977_765': 41.6692,
    '977_766': 41.6217,
    '977_767': 41.5768,
    '977_768': 41.5353,
    '977_769': 41.4982,
    '977_770': 41.466,
    '977_771': 41.4395,
    '977_772': 41.4211,
    '977_773': 41.4077,
    '977_774': 41.397,
    '977_775': 41.3843,
    '977_776': 41.3667,
    '977_777': 41.3449,
    '977_778': 41.3244,
    '977_779': 41.3086,
    '977_780': 41.2976,
    '977_781': 41.2915,
    '977_782': 41.2892,
    '977_783': 41.2903,
    '977_784': 41.2946,
    '977_785': 41.3005,
    '977_786': 41.3054,
    '977_787': 41.3108,
    '977_788': 41.316,
    '977_789': 41.3151,
    '977_790': 41.3046,
    '977_791': 41.2898,
    '977_792': 41.2724,
    '977_793': 41.2564,
    '977_794': 41.2448,
    '977_795': 41.2382,
    '977_796': 41.236,
    '977_797': 41.2426,
    '977_798': 41.2622,
    '977_799': 41.288,
    '977_800': 41.3149,
    '977_801': 41.3367,
    '977_802': 41.3426,
    '977_803': 41.3321,
    '977_804': 41.3175,
    '977_805': 41.3026,
    '977_806': 41.2649,
    '977_807': 41.1658,
    '977_808': 41.0738,
    '977_809': 40.9892,
    '977_810': 40.8812,
    '977_811': 40.7451,
    '977_812': 40.609,
    '977_813': 40.4677,
    '977_814': 40.3149,
    '977_815': 40.1462,
    '977_816': 39.9646,
    '977_817': 39.7741,
    '977_818': 39.5774,
    '977_819': 39.3752,
    '977_820': 39.1686,
    '977_821': 38.9567,
    '977_822': 38.7338,
    '977_823': 38.4822,
    '977_824': 38.2104,
    '977_825': 37.922,
    '977_826': 37.6212,
    '977_827': 37.3123,
    '977_828': 36.999,
    '977_829': 36.6829,
    '977_830': 36.3663,
    '977_831': 36.0511,
    '977_832': 35.7379,
    '977_833': 35.4349,
    '977_834': 35.1313,
    '977_835': 34.8319,
    '977_836': 34.5316,
    '977_837': 34.2376,
    '977_838': 33.9511,
    '977_839': 33.6576,
    '977_840': 33.3628,
    '977_841': 33.0685,
    '977_842': 32.7759,
    '977_843': 32.4825,
    '977_844': 32.1837,
    '977_845': 31.8795,
    '977_846': 31.5759,
    '977_847': 31.263,
    '977_848': 30.9478,
    '978_525': 33.4305,
    '978_526': 33.5098,
    '978_527': 33.5968,
    '978_528': 33.6894,
    '978_529': 33.7814,
    '978_530': 33.8672,
    '978_531': 33.9421,
    '978_532': 33.9861,
    '978_533': 34.0014,
    '978_534': 33.9882,
    '978_535': 33.9577,
    '978_536': 33.9185,
    '978_537': 33.8781,
    '978_538': 33.8442,
    '978_612': 34.7826,
    '978_613': 34.7938,
    '978_614': 34.8102,
    '978_615': 34.8303,
    '978_616': 34.8491,
    '978_617': 34.8577,
    '978_618': 34.8644,
    '978_619': 34.878,
    '978_620': 34.8905,
    '978_621': 34.9123,
    '978_622': 34.9391,
    '978_623': 34.9627,
    '978_624': 34.978,
    '978_625': 34.9983,
    '978_626': 35.0376,
    '978_627': 35.1049,
    '978_628': 35.1863,
    '978_629': 35.2741,
    '978_630': 35.3649,
    '978_631': 35.4622,
    '978_632': 35.5491,
    '978_633': 35.616,
    '978_634': 35.6899,
    '978_635': 35.7847,
    '978_636': 35.8657,
    '978_637': 35.9435,
    '978_638': 36.0214,
    '978_639': 36.1003,
    '978_640': 36.182,
    '978_641': 36.2652,
    '978_642': 36.3473,
    '978_643': 36.4288,
    '978_644': 36.5056,
    '978_645': 36.5866,
    '978_646': 36.6787,
    '978_647': 36.7759,
    '978_648': 36.8711,
    '978_649': 36.9669,
    '978_650': 37.069,
    '978_651': 37.1772,
    '978_652': 37.2844,
    '978_653': 37.3819,
    '978_654': 37.4801,
    '978_655': 37.5863,
    '978_656': 37.7044,
    '978_657': 37.8356,
    '978_658': 37.9772,
    '978_659': 38.1257,
    '978_660': 38.2749,
    '978_661': 38.4303,
    '978_662': 38.6001,
    '978_663': 38.7664,
    '978_664': 38.9281,
    '978_665': 39.0874,
    '978_666': 39.2362,
    '978_667': 39.4046,
    '978_668': 39.5725,
    '978_669': 39.7369,
    '978_670': 39.8826,
    '978_671': 40.0064,
    '978_672': 40.0874,
    '978_673': 40.1458,
    '978_674': 40.1832,
    '978_675': 40.2287,
    '978_676': 40.2995,
    '978_677': 40.4158,
    '978_678': 40.5448,
    '978_679': 40.6485,
    '978_680': 40.7076,
    '978_681': 40.755,
    '978_682': 40.7842,
    '978_683': 40.7958,
    '978_684': 40.8108,
    '978_685': 40.8446,
    '978_686': 40.9005,
    '978_687': 40.9673,
    '978_688': 41.0342,
    '978_689': 41.0908,
    '978_690': 41.1363,
    '978_691': 41.1837,
    '978_692': 41.2422,
    '978_693': 41.3258,
    '978_694': 41.4356,
    '978_695': 41.5657,
    '978_696': 41.7103,
    '978_697': 41.8654,
    '978_698': 42.0394,
    '978_699': 42.2099,
    '978_700': 42.3602,
    '978_701': 42.4922,
    '978_702': 42.6008,
    '978_703': 42.7504,
    '978_704': 42.8478,
    '978_705': 42.9221,
    '978_706': 42.9071,
    '978_707': 42.9888,
    '978_708': 42.9903,
    '978_709': 42.8754,
    '978_710': 42.8176,
    '978_711': 42.6681,
    '978_712': 42.4857,
    '978_713': 42.2985,
    '978_714': 42.129,
    '978_715': 42.0111,
    '978_716': 41.9598,
    '978_717': 41.9758,
    '978_718': 42.0532,
    '978_719': 42.1594,
    '978_720': 42.2864,
    '978_721': 42.4242,
    '978_722': 42.549,
    '978_723': 42.6305,
    '978_724': 42.6784,
    '978_725': 42.6965,
    '978_726': 42.6997,
    '978_727': 42.6957,
    '978_728': 42.6859,
    '978_729': 42.667,
    '978_730': 42.6511,
    '978_731': 42.6455,
    '978_732': 42.647,
    '978_733': 42.657,
    '978_734': 42.6816,
    '978_735': 42.7187,
    '978_736': 42.7601,
    '978_737': 42.8074,
    '978_738': 42.869,
    '978_739': 42.9453,
    '978_740': 43.0237,
    '978_741': 43.0947,
    '978_742': 43.1501,
    '978_743': 43.1897,
    '978_744': 43.2031,
    '978_745': 43.1902,
    '978_746': 43.1544,
    '978_747': 43.1009,
    '978_748': 43.0382,
    '978_749': 42.9589,
    '978_750': 42.857,
    '978_751': 42.7379,
    '978_752': 42.6313,
    '978_753': 42.5371,
    '978_754': 42.4488,
    '978_755': 42.3634,
    '978_756': 42.2811,
    '978_757': 42.2003,
    '978_758': 42.1207,
    '978_759': 42.0381,
    '978_760': 41.9554,
    '978_761': 41.8784,
    '978_762': 41.8139,
    '978_763': 41.7597,
    '978_764': 41.7133,
    '978_765': 41.6709,
    '978_766': 41.6317,
    '978_767': 41.5952,
    '978_768': 41.5622,
    '978_769': 41.5342,
    '978_770': 41.5113,
    '978_771': 41.494,
    '978_772': 41.4833,
    '978_773': 41.4795,
    '978_774': 41.4781,
    '978_775': 41.4723,
    '978_776': 41.4553,
    '978_777': 41.4361,
    '978_778': 41.419,
    '978_779': 41.4059,
    '978_780': 41.397,
    '978_781': 41.3921,
    '978_782': 41.3903,
    '978_783': 41.3919,
    '978_784': 41.3968,
    '978_785': 41.404,
    '978_786': 41.4072,
    '978_787': 41.4119,
    '978_788': 41.414,
    '978_789': 41.4102,
    '978_790': 41.3987,
    '978_791': 41.3817,
    '978_792': 41.3632,
    '978_793': 41.3475,
    '978_794': 41.3361,
    '978_795': 41.3301,
    '978_796': 41.3282,
    '978_797': 41.3347,
    '978_798': 41.3545,
    '978_799': 41.38,
    '978_800': 41.4048,
    '978_801': 41.4218,
    '978_802': 41.4229,
    '978_803': 41.4107,
    '978_804': 41.395,
    '978_805': 41.3842,
    '978_806': 41.3528,
    '978_807': 41.2586,
    '978_808': 41.1758,
    '978_809': 41.092,
    '978_810': 40.9709,
    '978_811': 40.8362,
    '978_812': 40.6999,
    '978_813': 40.559,
    '978_814': 40.408,
    '978_815': 40.2397,
    '978_816': 40.0584,
    '978_817': 39.8682,
    '978_818': 39.6721,
    '978_819': 39.4705,
    '978_820': 39.2657,
    '978_821': 39.0574,
    '978_822': 38.8428,
    '978_823': 38.6003,
    '978_824': 38.3325,
    '978_825': 38.0456,
    '978_826': 37.7447,
    '978_827': 37.4332,
    '978_828': 37.1152,
    '978_829': 36.7943,
    '978_830': 36.4769,
    '978_831': 36.1647,
    '978_832': 35.8543,
    '978_833': 35.5516,
    '978_834': 35.248,
    '978_835': 34.9478,
    '978_836': 34.6485,
    '978_837': 34.3549,
    '978_838': 34.0646,
    '978_839': 33.7707,
    '978_840': 33.4765,
    '978_841': 33.1846,
    '978_842': 32.8948,
    '978_843': 32.6022,
    '978_844': 32.3031,
    '978_845': 31.999,
    '978_846': 31.697,
    '978_847': 31.3881,
    '978_848': 31.0751,
    '979_525': 33.3778,
    '979_526': 33.4523,
    '979_527': 33.5366,
    '979_528': 33.6239,
    '979_529': 33.7126,
    '979_530': 33.7961,
    '979_531': 33.861,
    '979_532': 33.9,
    '979_533': 33.915,
    '979_534': 33.9133,
    '979_535': 33.8961,
    '979_536': 33.8683,
    '979_537': 33.8354,
    '979_538': 33.8076,
    '979_612': 34.7445,
    '979_613': 34.755,
    '979_614': 34.7705,
    '979_615': 34.7892,
    '979_616': 34.805,
    '979_617': 34.8161,
    '979_618': 34.827,
    '979_619': 34.8418,
    '979_620': 34.8571,
    '979_621': 34.8786,
    '979_622': 34.9047,
    '979_623': 34.9296,
    '979_624': 34.9472,
    '979_625': 34.9706,
    '979_626': 35.0171,
    '979_627': 35.0864,
    '979_628': 35.1693,
    '979_629': 35.2583,
    '979_630': 35.3492,
    '979_631': 35.439,
    '979_632': 35.5153,
    '979_633': 35.5908,
    '979_634': 35.6818,
    '979_635': 35.7781,
    '979_636': 35.8611,
    '979_637': 35.9378,
    '979_638': 36.0123,
    '979_639': 36.0859,
    '979_640': 36.1637,
    '979_641': 36.2423,
    '979_642': 36.3184,
    '979_643': 36.3962,
    '979_644': 36.4721,
    '979_645': 36.5502,
    '979_646': 36.6375,
    '979_647': 36.7354,
    '979_648': 36.8273,
    '979_649': 36.9209,
    '979_650': 37.018,
    '979_651': 37.1191,
    '979_652': 37.2199,
    '979_653': 37.3148,
    '979_654': 37.409,
    '979_655': 37.5096,
    '979_656': 37.6213,
    '979_657': 37.7466,
    '979_658': 37.885,
    '979_659': 38.0341,
    '979_660': 38.1857,
    '979_661': 38.341,
    '979_662': 38.5085,
    '979_663': 38.6755,
    '979_664': 38.8362,
    '979_665': 38.9938,
    '979_666': 39.1503,
    '979_667': 39.3283,
    '979_668': 39.5015,
    '979_669': 39.669,
    '979_670': 39.823,
    '979_671': 39.937,
    '979_672': 40.0147,
    '979_673': 40.0731,
    '979_674': 40.1146,
    '979_675': 40.171,
    '979_676': 40.2548,
    '979_677': 40.3776,
    '979_678': 40.5014,
    '979_679': 40.6034,
    '979_680': 40.6825,
    '979_681': 40.7318,
    '979_682': 40.7697,
    '979_683': 40.7908,
    '979_684': 40.8058,
    '979_685': 40.8311,
    '979_686': 40.8792,
    '979_687': 40.944,
    '979_688': 41.0116,
    '979_689': 41.0704,
    '979_690': 41.1191,
    '979_691': 41.1596,
    '979_692': 41.2106,
    '979_693': 41.3051,
    '979_694': 41.4335,
    '979_695': 41.57,
    '979_696': 41.7191,
    '979_697': 41.8817,
    '979_698': 42.05,
    '979_699': 42.2228,
    '979_700': 42.3835,
    '979_701': 42.5095,
    '979_702': 42.593,
    '979_703': 42.723,
    '979_704': 42.8642,
    '979_705': 42.9178,
    '979_706': 42.9141,
    '979_707': 42.9756,
    '979_708': 42.9655,
    '979_709': 42.8473,
    '979_710': 42.7578,
    '979_711': 42.6225,
    '979_712': 42.4384,
    '979_713': 42.2459,
    '979_714': 42.0786,
    '979_715': 41.9668,
    '979_716': 41.9195,
    '979_717': 41.9432,
    '979_718': 42.022,
    '979_719': 42.1214,
    '979_720': 42.2425,
    '979_721': 42.3766,
    '979_722': 42.4928,
    '979_723': 42.5677,
    '979_724': 42.6082,
    '979_725': 42.6179,
    '979_726': 42.6168,
    '979_727': 42.6106,
    '979_728': 42.6026,
    '979_729': 42.5906,
    '979_730': 42.5828,
    '979_731': 42.5844,
    '979_732': 42.5977,
    '979_733': 42.6192,
    '979_734': 42.6532,
    '979_735': 42.6989,
    '979_736': 42.7488,
    '979_737': 42.8048,
    '979_738': 42.8719,
    '979_739': 42.9483,
    '979_740': 43.0216,
    '979_741': 43.0847,
    '979_742': 43.1283,
    '979_743': 43.1534,
    '979_744': 43.1644,
    '979_745': 43.1548,
    '979_746': 43.127,
    '979_747': 43.0728,
    '979_748': 43.0042,
    '979_749': 42.9175,
    '979_750': 42.8117,
    '979_751': 42.6963,
    '979_752': 42.5918,
    '979_753': 42.4943,
    '979_754': 42.4037,
    '979_755': 42.3171,
    '979_756': 42.2354,
    '979_757': 42.1587,
    '979_758': 42.082,
    '979_759': 42.0013,
    '979_760': 41.923,
    '979_761': 41.8558,
    '979_762': 41.8003,
    '979_763': 41.754,
    '979_764': 41.7149,
    '979_765': 41.6803,
    '979_766': 41.6489,
    '979_767': 41.6206,
    '979_768': 41.5963,
    '979_769': 41.5769,
    '979_770': 41.5629,
    '979_771': 41.5542,
    '979_772': 41.5498,
    '979_773': 41.5514,
    '979_774': 41.5546,
    '979_775': 41.555,
    '979_776': 41.5394,
    '979_777': 41.5244,
    '979_778': 41.5112,
    '979_779': 41.5011,
    '979_780': 41.4957,
    '979_781': 41.4923,
    '979_782': 41.488,
    '979_783': 41.4891,
    '979_784': 41.4943,
    '979_785': 41.5011,
    '979_786': 41.5028,
    '979_787': 41.5068,
    '979_788': 41.5069,
    '979_789': 41.5011,
    '979_790': 41.4883,
    '979_791': 41.4687,
    '979_792': 41.4479,
    '979_793': 41.4324,
    '979_794': 41.422,
    '979_795': 41.417,
    '979_796': 41.4164,
    '979_797': 41.4244,
    '979_798': 41.4434,
    '979_799': 41.4683,
    '979_800': 41.4919,
    '979_801': 41.5054,
    '979_802': 41.5037,
    '979_803': 41.4904,
    '979_804': 41.4723,
    '979_805': 41.4571,
    '979_806': 41.4199,
    '979_807': 41.3406,
    '979_808': 41.2707,
    '979_809': 41.1795,
    '979_810': 41.0605,
    '979_811': 40.9239,
    '979_812': 40.7867,
    '979_813': 40.647,
    '979_814': 40.4964,
    '979_815': 40.3292,
    '979_816': 40.1475,
    '979_817': 39.9574,
    '979_818': 39.7623,
    '979_819': 39.5619,
    '979_820': 39.3583,
    '979_821': 39.1531,
    '979_822': 38.945,
    '979_823': 38.7159,
    '979_824': 38.453,
    '979_825': 38.1687,
    '979_826': 37.8682,
    '979_827': 37.5548,
    '979_828': 37.2331,
    '979_829': 36.9082,
    '979_830': 36.5868,
    '979_831': 36.2776,
    '979_832': 35.9672,
    '979_833': 35.6685,
    '979_834': 35.3625,
    '979_835': 35.0629,
    '979_836': 34.7621,
    '979_837': 34.4699,
    '979_838': 34.1775,
    '979_839': 33.8835,
    '979_840': 33.5894,
    '979_841': 33.2996,
    '979_842': 33.0134,
    '979_843': 32.7223,
    '979_844': 32.4242,
    '979_845': 32.1223,
    '979_846': 31.8223,
    '979_847': 31.5175,
    '979_848': 31.2059,
    '980_525': 33.3261,
    '980_526': 33.4007,
    '980_527': 33.4796,
    '980_528': 33.56,
    '980_529': 33.6386,
    '980_530': 33.7115,
    '980_531': 33.7701,
    '980_532': 33.8088,
    '980_533': 33.8314,
    '980_534': 33.8396,
    '980_535': 33.8309,
    '980_536': 33.8144,
    '980_537': 33.7914,
    '980_538': 33.7744,
    '980_612': 34.7087,
    '980_613': 34.7129,
    '980_614': 34.7269,
    '980_615': 34.7457,
    '980_616': 34.7584,
    '980_617': 34.7711,
    '980_618': 34.7851,
    '980_619': 34.8012,
    '980_620': 34.8187,
    '980_621': 34.8407,
    '980_622': 34.8687,
    '980_623': 34.9004,
    '980_624': 34.9199,
    '980_625': 34.9492,
    '980_626': 34.9989,
    '980_627': 35.0707,
    '980_628': 35.1552,
    '980_629': 35.2448,
    '980_630': 35.333,
    '980_631': 35.4131,
    '980_632': 35.4856,
    '980_633': 35.5763,
    '980_634': 35.6765,
    '980_635': 35.7735,
    '980_636': 35.8545,
    '980_637': 35.9312,
    '980_638': 36.0058,
    '980_639': 36.0764,
    '980_640': 36.1469,
    '980_641': 36.218,
    '980_642': 36.2887,
    '980_643': 36.3644,
    '980_644': 36.4368,
    '980_645': 36.5145,
    '980_646': 36.6012,
    '980_647': 36.6929,
    '980_648': 36.783,
    '980_649': 36.8741,
    '980_650': 36.9685,
    '980_651': 37.0644,
    '980_652': 37.1609,
    '980_653': 37.2531,
    '980_654': 37.3452,
    '980_655': 37.4435,
    '980_656': 37.5495,
    '980_657': 37.6675,
    '980_658': 37.801,
    '980_659': 37.9529,
    '980_660': 38.105,
    '980_661': 38.2548,
    '980_662': 38.4208,
    '980_663': 38.5854,
    '980_664': 38.7424,
    '980_665': 38.9015,
    '980_666': 39.0705,
    '980_667': 39.2471,
    '980_668': 39.4254,
    '980_669': 39.5933,
    '980_670': 39.7423,
    '980_671': 39.8589,
    '980_672': 39.947,
    '980_673': 39.9987,
    '980_674': 40.0419,
    '980_675': 40.1104,
    '980_676': 40.2109,
    '980_677': 40.3369,
    '980_678': 40.4471,
    '980_679': 40.5569,
    '980_680': 40.6415,
    '980_681': 40.7041,
    '980_682': 40.7485,
    '980_683': 40.78,
    '980_684': 40.799,
    '980_685': 40.8181,
    '980_686': 40.85,
    '980_687': 40.9075,
    '980_688': 40.9768,
    '980_689': 41.0436,
    '980_690': 41.0951,
    '980_691': 41.1416,
    '980_692': 41.1977,
    '980_693': 41.3055,
    '980_694': 41.4392,
    '980_695': 41.5789,
    '980_696': 41.7309,
    '980_697': 41.8924,
    '980_698': 42.0541,
    '980_699': 42.2081,
    '980_700': 42.389,
    '980_701': 42.5355,
    '980_702': 42.6504,
    '980_703': 42.7246,
    '980_704': 42.8674,
    '980_705': 42.9152,
    '980_706': 42.8789,
    '980_707': 42.9398,
    '980_708': 42.9186,
    '980_709': 42.8107,
    '980_710': 42.7215,
    '980_711': 42.5736,
    '980_712': 42.3831,
    '980_713': 42.2003,
    '980_714': 42.033,
    '980_715': 41.9259,
    '980_716': 41.8839,
    '980_717': 41.912,
    '980_718': 41.9917,
    '980_719': 42.0845,
    '980_720': 42.1971,
    '980_721': 42.3266,
    '980_722': 42.4424,
    '980_723': 42.5163,
    '980_724': 42.5512,
    '980_725': 42.5505,
    '980_726': 42.5442,
    '980_727': 42.5345,
    '980_728': 42.5272,
    '980_729': 42.5202,
    '980_730': 42.5248,
    '980_731': 42.5362,
    '980_732': 42.5605,
    '980_733': 42.5946,
    '980_734': 42.639,
    '980_735': 42.6951,
    '980_736': 42.7585,
    '980_737': 42.8268,
    '980_738': 42.8981,
    '980_739': 42.9724,
    '980_740': 43.0392,
    '980_741': 43.0897,
    '980_742': 43.1207,
    '980_743': 43.1371,
    '980_744': 43.1434,
    '980_745': 43.1375,
    '980_746': 43.1125,
    '980_747': 43.0584,
    '980_748': 42.979,
    '980_749': 42.8823,
    '980_750': 42.775,
    '980_751': 42.6683,
    '980_752': 42.5664,
    '980_753': 42.4691,
    '980_754': 42.3775,
    '980_755': 42.2897,
    '980_756': 42.2066,
    '980_757': 42.1273,
    '980_758': 42.0493,
    '980_759': 41.9734,
    '980_760': 41.904,
    '980_761': 41.8451,
    '980_762': 41.797,
    '980_763': 41.7579,
    '980_764': 41.7257,
    '980_765': 41.6985,
    '980_766': 41.6748,
    '980_767': 41.6548,
    '980_768': 41.6394,
    '980_769': 41.6293,
    '980_770': 41.6245,
    '980_771': 41.6242,
    '980_772': 41.6259,
    '980_773': 41.6291,
    '980_774': 41.6318,
    '980_775': 41.6324,
    '980_776': 41.6236,
    '980_777': 41.6141,
    '980_778': 41.6049,
    '980_779': 41.5977,
    '980_780': 41.5979,
    '980_781': 41.5934,
    '980_782': 41.5841,
    '980_783': 41.5843,
    '980_784': 41.5907,
    '980_785': 41.5952,
    '980_786': 41.5985,
    '980_787': 41.6016,
    '980_788': 41.5982,
    '980_789': 41.5892,
    '980_790': 41.5726,
    '980_791': 41.5508,
    '980_792': 41.529,
    '980_793': 41.5132,
    '980_794': 41.5041,
    '980_795': 41.5003,
    '980_796': 41.5004,
    '980_797': 41.5082,
    '980_798': 41.5273,
    '980_799': 41.5523,
    '980_800': 41.5752,
    '980_801': 41.5876,
    '980_802': 41.585,
    '980_803': 41.5713,
    '980_804': 41.5541,
    '980_805': 41.5307,
    '980_806': 41.476,
    '980_807': 41.4111,
    '980_808': 41.3405,
    '980_809': 41.2479,
    '980_810': 41.1341,
    '980_811': 41.004,
    '980_812': 40.8711,
    '980_813': 40.7337,
    '980_814': 40.5849,
    '980_815': 40.4185,
    '980_816': 40.2356,
    '980_817': 40.0447,
    '980_818': 39.8504,
    '980_819': 39.6518,
    '980_820': 39.4499,
    '980_821': 39.2476,
    '980_822': 39.0424,
    '980_823': 38.8188,
    '980_824': 38.5688,
    '980_825': 38.2925,
    '980_826': 37.9939,
    '980_827': 37.6789,
    '980_828': 37.3541,
    '980_829': 37.0249,
    '980_830': 36.6978,
    '980_831': 36.38,
    '980_832': 36.0746,
    '980_833': 35.7772,
    '980_834': 35.4741,
    '980_835': 35.1735,
    '980_836': 34.8752,
    '980_837': 34.5833,
    '980_838': 34.2917,
    '980_839': 34.0016,
    '980_840': 33.7119,
    '980_841': 33.4205,
    '980_842': 33.1313,
    '980_843': 32.8417,
    '980_844': 32.5462,
    '980_845': 32.2471,
    '980_846': 31.9483,
    '980_847': 31.6431,
    '980_848': 31.3302,
    '981_525': 33.2778,
    '981_526': 33.3512,
    '981_527': 33.4255,
    '981_528': 33.4988,
    '981_529': 33.5692,
    '981_530': 33.6321,
    '981_531': 33.6845,
    '981_532': 33.7249,
    '981_533': 33.7541,
    '981_534': 33.7702,
    '981_535': 33.7689,
    '981_536': 33.7617,
    '981_537': 33.7525,
    '981_538': 33.7447,
    '981_612': 34.6586,
    '981_613': 34.6674,
    '981_614': 34.6817,
    '981_615': 34.7034,
    '981_616': 34.7105,
    '981_617': 34.7243,
    '981_618': 34.7431,
    '981_619': 34.7589,
    '981_620': 34.7766,
    '981_621': 34.7976,
    '981_622': 34.8262,
    '981_623': 34.864,
    '981_624': 34.8859,
    '981_625': 34.9239,
    '981_626': 34.9783,
    '981_627': 35.0549,
    '981_628': 35.1412,
    '981_629': 35.2309,
    '981_630': 35.3167,
    '981_631': 35.3915,
    '981_632': 35.4696,
    '981_633': 35.566,
    '981_634': 35.6681,
    '981_635': 35.7595,
    '981_636': 35.8411,
    '981_637': 35.9215,
    '981_638': 35.9998,
    '981_639': 36.0678,
    '981_640': 36.1314,
    '981_641': 36.1965,
    '981_642': 36.2623,
    '981_643': 36.3303,
    '981_644': 36.3984,
    '981_645': 36.4757,
    '981_646': 36.564,
    '981_647': 36.6513,
    '981_648': 36.7388,
    '981_649': 36.828,
    '981_650': 36.9201,
    '981_651': 37.0128,
    '981_652': 37.1056,
    '981_653': 37.1951,
    '981_654': 37.2845,
    '981_655': 37.3811,
    '981_656': 37.4851,
    '981_657': 37.5984,
    '981_658': 37.7301,
    '981_659': 37.8779,
    '981_660': 38.0278,
    '981_661': 38.1799,
    '981_662': 38.3404,
    '981_663': 38.501,
    '981_664': 38.655,
    '981_665': 38.8188,
    '981_666': 38.9937,
    '981_667': 39.1683,
    '981_668': 39.3471,
    '981_669': 39.5127,
    '981_670': 39.6572,
    '981_671': 39.7788,
    '981_672': 39.859,
    '981_673': 39.9211,
    '981_674': 39.9701,
    '981_675': 40.0429,
    '981_676': 40.1553,
    '981_677': 40.2707,
    '981_678': 40.3832,
    '981_679': 40.4973,
    '981_680': 40.5803,
    '981_681': 40.6522,
    '981_682': 40.7121,
    '981_683': 40.7505,
    '981_684': 40.778,
    '981_685': 40.8011,
    '981_686': 40.8215,
    '981_687': 40.8586,
    '981_688': 40.9245,
    '981_689': 41.0023,
    '981_690': 41.064,
    '981_691': 41.1174,
    '981_692': 41.1872,
    '981_693': 41.2999,
    '981_694': 41.4352,
    '981_695': 41.5791,
    '981_696': 41.7332,
    '981_697': 41.8884,
    '981_698': 42.0666,
    '981_699': 42.2258,
    '981_700': 42.402,
    '981_701': 42.5584,
    '981_702': 42.6805,
    '981_703': 42.7646,
    '981_704': 42.8389,
    '981_705': 42.8933,
    '981_706': 42.8945,
    '981_707': 42.9263,
    '981_708': 42.8519,
    '981_709': 42.7634,
    '981_710': 42.6743,
    '981_711': 42.5209,
    '981_712': 42.3386,
    '981_713': 42.1564,
    '981_714': 41.991,
    '981_715': 41.8879,
    '981_716': 41.8528,
    '981_717': 41.8851,
    '981_718': 41.9619,
    '981_719': 42.0523,
    '981_720': 42.1614,
    '981_721': 42.286,
    '981_722': 42.3995,
    '981_723': 42.4642,
    '981_724': 42.4976,
    '981_725': 42.4915,
    '981_726': 42.4803,
    '981_727': 42.4661,
    '981_728': 42.4604,
    '981_729': 42.4644,
    '981_730': 42.4824,
    '981_731': 42.5031,
    '981_732': 42.5359,
    '981_733': 42.5821,
    '981_734': 42.6405,
    '981_735': 42.7121,
    '981_736': 42.7913,
    '981_737': 42.8728,
    '981_738': 42.9489,
    '981_739': 43.0226,
    '981_740': 43.0809,
    '981_741': 43.1168,
    '981_742': 43.1333,
    '981_743': 43.1397,
    '981_744': 43.142,
    '981_745': 43.1364,
    '981_746': 43.1137,
    '981_747': 43.0598,
    '981_748': 42.9687,
    '981_749': 42.8642,
    '981_750': 42.7604,
    '981_751': 42.6573,
    '981_752': 42.56,
    '981_753': 42.4632,
    '981_754': 42.3702,
    '981_755': 42.2816,
    '981_756': 42.1975,
    '981_757': 42.1127,
    '981_758': 42.0324,
    '981_759': 41.96,
    '981_760': 41.8966,
    '981_761': 41.8437,
    '981_762': 41.8022,
    '981_763': 41.7698,
    '981_764': 41.7444,
    '981_765': 41.7242,
    '981_766': 41.7082,
    '981_767': 41.6973,
    '981_768': 41.6913,
    '981_769': 41.6911,
    '981_770': 41.6958,
    '981_771': 41.7026,
    '981_772': 41.7094,
    '981_773': 41.7162,
    '981_774': 41.7218,
    '981_775': 41.7193,
    '981_776': 41.7129,
    '981_777': 41.7096,
    '981_778': 41.7037,
    '981_779': 41.6926,
    '981_780': 41.6921,
    '981_781': 41.6858,
    '981_782': 41.6767,
    '981_783': 41.6765,
    '981_784': 41.6852,
    '981_785': 41.6899,
    '981_786': 41.6989,
    '981_787': 41.7014,
    '981_788': 41.6891,
    '981_789': 41.6724,
    '981_790': 41.6512,
    '981_791': 41.6269,
    '981_792': 41.6042,
    '981_793': 41.5887,
    '981_794': 41.5812,
    '981_795': 41.5791,
    '981_796': 41.5806,
    '981_797': 41.5886,
    '981_798': 41.6066,
    '981_799': 41.6327,
    '981_800': 41.6568,
    '981_801': 41.6684,
    '981_802': 41.6659,
    '981_803': 41.6497,
    '981_804': 41.6273,
    '981_805': 41.5944,
    '981_806': 41.5402,
    '981_807': 41.4816,
    '981_808': 41.4058,
    '981_809': 41.3121,
    '981_810': 41.202,
    '981_811': 41.0804,
    '981_812': 40.9532,
    '981_813': 40.8182,
    '981_814': 40.6711,
    '981_815': 40.5057,
    '981_816': 40.3239,
    '981_817': 40.1334,
    '981_818': 39.9385,
    '981_819': 39.7407,
    '981_820': 39.5406,
    '981_821': 39.3406,
    '981_822': 39.1381,
    '981_823': 38.9225,
    '981_824': 38.6864,
    '981_825': 38.4193,
    '981_826': 38.1213,
    '981_827': 37.8041,
    '981_828': 37.4766,
    '981_829': 37.1438,
    '981_830': 36.8104,
    '981_831': 36.4834,
    '981_832': 36.182,
    '981_833': 35.8893,
    '981_834': 35.5859,
    '981_835': 35.2865,
    '981_836': 34.9892,
    '981_837': 34.6999,
    '981_838': 34.4077,
    '981_839': 34.1215,
    '981_840': 33.8357,
    '981_841': 33.5406,
    '981_842': 33.2499,
    '981_843': 32.9634,
    '981_844': 32.6702,
    '981_845': 32.3725,
    '981_846': 32.0779,
    '981_847': 31.7758,
    '981_848': 31.4584,
    '982_525': 33.2397,
    '982_526': 33.3073,
    '982_527': 33.3755,
    '982_528': 33.4411,
    '982_529': 33.5054,
    '982_530': 33.5607,
    '982_531': 33.6074,
    '982_532': 33.6471,
    '982_533': 33.6786,
    '982_534': 33.7006,
    '982_535': 33.7089,
    '982_536': 33.7088,
    '982_537': 33.7066,
    '982_538': 33.7063,
    '982_612': 34.614,
    '982_613': 34.6256,
    '982_614': 34.6416,
    '982_615': 34.657,
    '982_616': 34.6635,
    '982_617': 34.6742,
    '982_618': 34.6927,
    '982_619': 34.712,
    '982_620': 34.7325,
    '982_621': 34.7517,
    '982_622': 34.7772,
    '982_623': 34.826,
    '982_624': 34.8507,
    '982_625': 34.9046,
    '982_626': 34.968,
    '982_627': 35.0433,
    '982_628': 35.1254,
    '982_629': 35.2101,
    '982_630': 35.2859,
    '982_631': 35.3647,
    '982_632': 35.4483,
    '982_633': 35.5508,
    '982_634': 35.6504,
    '982_635': 35.7407,
    '982_636': 35.8245,
    '982_637': 35.9103,
    '982_638': 35.9919,
    '982_639': 36.058,
    '982_640': 36.1177,
    '982_641': 36.1762,
    '982_642': 36.2353,
    '982_643': 36.2963,
    '982_644': 36.3619,
    '982_645': 36.439,
    '982_646': 36.5231,
    '982_647': 36.6082,
    '982_648': 36.6941,
    '982_649': 36.7818,
    '982_650': 36.8712,
    '982_651': 36.9628,
    '982_652': 37.0528,
    '982_653': 37.1407,
    '982_654': 37.2296,
    '982_655': 37.3252,
    '982_656': 37.4247,
    '982_657': 37.5356,
    '982_658': 37.6636,
    '982_659': 37.8023,
    '982_660': 37.951,
    '982_661': 38.1038,
    '982_662': 38.2624,
    '982_663': 38.422,
    '982_664': 38.5771,
    '982_665': 38.742,
    '982_666': 38.9159,
    '982_667': 39.0944,
    '982_668': 39.2671,
    '982_669': 39.4224,
    '982_670': 39.5638,
    '982_671': 39.6804,
    '982_672': 39.7727,
    '982_673': 39.8407,
    '982_674': 39.898,
    '982_675': 39.9714,
    '982_676': 40.081,
    '982_677': 40.2027,
    '982_678': 40.3103,
    '982_679': 40.4115,
    '982_680': 40.5049,
    '982_681': 40.5887,
    '982_682': 40.6511,
    '982_683': 40.7075,
    '982_684': 40.7413,
    '982_685': 40.7662,
    '982_686': 40.7846,
    '982_687': 40.8087,
    '982_688': 40.8639,
    '982_689': 40.9423,
    '982_690': 41.0134,
    '982_691': 41.0742,
    '982_692': 41.1593,
    '982_693': 41.276,
    '982_694': 41.4155,
    '982_695': 41.5642,
    '982_696': 41.7188,
    '982_697': 41.8772,
    '982_698': 42.0543,
    '982_699': 42.233,
    '982_700': 42.3897,
    '982_701': 42.5475,
    '982_702': 42.6984,
    '982_703': 42.7695,
    '982_704': 42.8011,
    '982_705': 42.8705,
    '982_706': 42.9019,
    '982_707': 42.8957,
    '982_708': 42.797,
    '982_709': 42.7561,
    '982_710': 42.6624,
    '982_711': 42.4983,
    '982_712': 42.3065,
    '982_713': 42.1152,
    '982_714': 41.955,
    '982_715': 41.8574,
    '982_716': 41.8293,
    '982_717': 41.862,
    '982_718': 41.9349,
    '982_719': 42.0258,
    '982_720': 42.1293,
    '982_721': 42.2438,
    '982_722': 42.3497,
    '982_723': 42.4175,
    '982_724': 42.4455,
    '982_725': 42.4397,
    '982_726': 42.4261,
    '982_727': 42.4143,
    '982_728': 42.4122,
    '982_729': 42.4265,
    '982_730': 42.4559,
    '982_731': 42.487,
    '982_732': 42.5289,
    '982_733': 42.5896,
    '982_734': 42.6654,
    '982_735': 42.7534,
    '982_736': 42.8473,
    '982_737': 42.9387,
    '982_738': 43.021,
    '982_739': 43.0882,
    '982_740': 43.1359,
    '982_741': 43.1614,
    '982_742': 43.1634,
    '982_743': 43.158,
    '982_744': 43.154,
    '982_745': 43.1487,
    '982_746': 43.1203,
    '982_747': 43.0637,
    '982_748': 42.9748,
    '982_749': 42.8687,
    '982_750': 42.7632,
    '982_751': 42.6628,
    '982_752': 42.5679,
    '982_753': 42.4724,
    '982_754': 42.3798,
    '982_755': 42.2893,
    '982_756': 42.2005,
    '982_757': 42.1143,
    '982_758': 42.0331,
    '982_759': 41.9616,
    '982_760': 41.9007,
    '982_761': 41.8517,
    '982_762': 41.8154,
    '982_763': 41.7893,
    '982_764': 41.7704,
    '982_765': 41.7574,
    '982_766': 41.7502,
    '982_767': 41.7488,
    '982_768': 41.7528,
    '982_769': 41.762,
    '982_770': 41.7747,
    '982_771': 41.7873,
    '982_772': 41.7982,
    '982_773': 41.8089,
    '982_774': 41.8123,
    '982_775': 41.8089,
    '982_776': 41.8084,
    '982_777': 41.8106,
    '982_778': 41.8031,
    '982_779': 41.7876,
    '982_780': 41.7843,
    '982_781': 41.7778,
    '982_782': 41.7671,
    '982_783': 41.766,
    '982_784': 41.7732,
    '982_785': 41.7821,
    '982_786': 41.7886,
    '982_787': 41.7883,
    '982_788': 41.7724,
    '982_789': 41.7486,
    '982_790': 41.7227,
    '982_791': 41.6962,
    '982_792': 41.6728,
    '982_793': 41.6586,
    '982_794': 41.6529,
    '982_795': 41.6524,
    '982_796': 41.6554,
    '982_797': 41.6637,
    '982_798': 41.6813,
    '982_799': 41.7072,
    '982_800': 41.7342,
    '982_801': 41.7484,
    '982_802': 41.7486,
    '982_803': 41.7298,
    '982_804': 41.7005,
    '982_805': 41.6623,
    '982_806': 41.6134,
    '982_807': 41.5588,
    '982_808': 41.4789,
    '982_809': 41.3815,
    '982_810': 41.2737,
    '982_811': 41.1589,
    '982_812': 41.0351,
    '982_813': 40.9021,
    '982_814': 40.7553,
    '982_815': 40.5909,
    '982_816': 40.4107,
    '982_817': 40.2212,
    '982_818': 40.0247,
    '982_819': 39.8262,
    '982_820': 39.6281,
    '982_821': 39.4323,
    '982_822': 39.2338,
    '982_823': 39.0261,
    '982_824': 38.803,
    '982_825': 38.5457,
    '982_826': 38.2452,
    '982_827': 37.9271,
    '982_828': 37.5996,
    '982_829': 37.2675,
    '982_830': 36.9357,
    '982_831': 36.6107,
    '982_832': 36.3001,
    '982_833': 36.0035,
    '982_834': 35.7042,
    '982_835': 35.4074,
    '982_836': 35.1136,
    '982_837': 34.8217,
    '982_838': 34.5298,
    '982_839': 34.2409,
    '982_840': 33.9533,
    '982_841': 33.6613,
    '982_842': 33.3728,
    '982_843': 33.0868,
    '982_844': 32.7964,
    '982_845': 32.5009,
    '982_846': 32.2089,
    '982_847': 31.9108,
    '982_848': 31.5997,
    '983_525': 33.2029,
    '983_526': 33.2657,
    '983_527': 33.3292,
    '983_528': 33.3893,
    '983_529': 33.4471,
    '983_530': 33.4927,
    '983_531': 33.5345,
    '983_532': 33.5776,
    '983_533': 33.6107,
    '983_534': 33.638,
    '983_535': 33.6518,
    '983_536': 33.6564,
    '983_537': 33.6595,
    '983_538': 33.6672,
    '983_612': 34.5776,
    '983_613': 34.5906,
    '983_614': 34.6144,
    '983_615': 34.6201,
    '983_616': 34.6233,
    '983_617': 34.631,
    '983_618': 34.6456,
    '983_619': 34.6663,
    '983_620': 34.6937,
    '983_621': 34.7144,
    '983_622': 34.7418,
    '983_623': 34.7904,
    '983_624': 34.8493,
    '983_625': 34.9024,
    '983_626': 34.9624,
    '983_627': 35.0318,
    '983_628': 35.1071,
    '983_629': 35.1834,
    '983_630': 35.2604,
    '983_631': 35.3454,
    '983_632': 35.4389,
    '983_633': 35.5407,
    '983_634': 35.6333,
    '983_635': 35.7216,
    '983_636': 35.8072,
    '983_637': 35.8926,
    '983_638': 35.9721,
    '983_639': 36.0416,
    '983_640': 36.1007,
    '983_641': 36.155,
    '983_642': 36.2086,
    '983_643': 36.2643,
    '983_644': 36.3298,
    '983_645': 36.4046,
    '983_646': 36.4842,
    '983_647': 36.566,
    '983_648': 36.6497,
    '983_649': 36.7364,
    '983_650': 36.8246,
    '983_651': 36.9143,
    '983_652': 37.0033,
    '983_653': 37.0911,
    '983_654': 37.1788,
    '983_655': 37.2712,
    '983_656': 37.3687,
    '983_657': 37.4775,
    '983_658': 37.5999,
    '983_659': 37.73,
    '983_660': 37.8704,
    '983_661': 38.02,
    '983_662': 38.1803,
    '983_663': 38.3444,
    '983_664': 38.501,
    '983_665': 38.6586,
    '983_666': 38.8336,
    '983_667': 39.0152,
    '983_668': 39.1829,
    '983_669': 39.3285,
    '983_670': 39.4579,
    '983_671': 39.5778,
    '983_672': 39.678,
    '983_673': 39.7579,
    '983_674': 39.8251,
    '983_675': 39.8924,
    '983_676': 39.9926,
    '983_677': 40.1023,
    '983_678': 40.2104,
    '983_679': 40.3116,
    '983_680': 40.4101,
    '983_681': 40.5044,
    '983_682': 40.577,
    '983_683': 40.6446,
    '983_684': 40.6887,
    '983_685': 40.721,
    '983_686': 40.7356,
    '983_687': 40.7484,
    '983_688': 40.8012,
    '983_689': 40.8825,
    '983_690': 40.9504,
    '983_691': 41.0182,
    '983_692': 41.1113,
    '983_693': 41.2334,
    '983_694': 41.3752,
    '983_695': 41.5289,
    '983_696': 41.6966,
    '983_697': 41.8693,
    '983_698': 42.0318,
    '983_699': 42.2178,
    '983_700': 42.3872,
    '983_701': 42.5208,
    '983_702': 42.6431,
    '983_703': 42.7462,
    '983_704': 42.8028,
    '983_705': 42.8714,
    '983_706': 42.8706,
    '983_707': 42.8628,
    '983_708': 42.7719,
    '983_709': 42.7129,
    '983_710': 42.6209,
    '983_711': 42.4347,
    '983_712': 42.2641,
    '983_713': 42.0784,
    '983_714': 41.9244,
    '983_715': 41.833,
    '983_716': 41.8101,
    '983_717': 41.8408,
    '983_718': 41.9106,
    '983_719': 41.9979,
    '983_720': 42.0957,
    '983_721': 42.2042,
    '983_722': 42.3064,
    '983_723': 42.3754,
    '983_724': 42.4038,
    '983_725': 42.4034,
    '983_726': 42.3919,
    '983_727': 42.3829,
    '983_728': 42.3848,
    '983_729': 42.406,
    '983_730': 42.4426,
    '983_731': 42.4859,
    '983_732': 42.5431,
    '983_733': 42.6201,
    '983_734': 42.7122,
    '983_735': 42.8148,
    '983_736': 42.9196,
    '983_737': 43.0116,
    '983_738': 43.0915,
    '983_739': 43.1543,
    '983_740': 43.1801,
    '983_741': 43.1974,
    '983_742': 43.1899,
    '983_743': 43.1795,
    '983_744': 43.1689,
    '983_745': 43.1594,
    '983_746': 43.1272,
    '983_747': 43.0771,
    '983_748': 42.9909,
    '983_749': 42.8836,
    '983_750': 42.7761,
    '983_751': 42.675,
    '983_752': 42.5795,
    '983_753': 42.4857,
    '983_754': 42.3965,
    '983_755': 42.3076,
    '983_756': 42.222,
    '983_757': 42.1374,
    '983_758': 42.0518,
    '983_759': 41.9775,
    '983_760': 41.9164,
    '983_761': 41.8701,
    '983_762': 41.8377,
    '983_763': 41.8174,
    '983_764': 41.8056,
    '983_765': 41.8007,
    '983_766': 41.8023,
    '983_767': 41.8095,
    '983_768': 41.8226,
    '983_769': 41.8402,
    '983_770': 41.8596,
    '983_771': 41.876,
    '983_772': 41.889,
    '983_773': 41.8984,
    '983_774': 41.9003,
    '983_775': 41.9038,
    '983_776': 41.9097,
    '983_777': 41.9072,
    '983_778': 41.9011,
    '983_779': 41.8834,
    '983_780': 41.8763,
    '983_781': 41.8685,
    '983_782': 41.8588,
    '983_783': 41.8549,
    '983_784': 41.8583,
    '983_785': 41.8689,
    '983_786': 41.8723,
    '983_787': 41.8612,
    '983_788': 41.8406,
    '983_789': 41.8148,
    '983_790': 41.7873,
    '983_791': 41.7601,
    '983_792': 41.7374,
    '983_793': 41.7249,
    '983_794': 41.7202,
    '983_795': 41.7203,
    '983_796': 41.7239,
    '983_797': 41.7317,
    '983_798': 41.7469,
    '983_799': 41.7724,
    '983_800': 41.8044,
    '983_801': 41.8225,
    '983_802': 41.8253,
    '983_803': 41.8088,
    '983_804': 41.7835,
    '983_805': 41.7415,
    '983_806': 41.6955,
    '983_807': 41.6397,
    '983_808': 41.5582,
    '983_809': 41.4551,
    '983_810': 41.3495,
    '983_811': 41.2402,
    '983_812': 41.1191,
    '983_813': 40.9868,
    '983_814': 40.8392,
    '983_815': 40.6744,
    '983_816': 40.496,
    '983_817': 40.307,
    '983_818': 40.11,
    '983_819': 39.9126,
    '983_820': 39.7169,
    '983_821': 39.5244,
    '983_822': 39.3275,
    '983_823': 39.1233,
    '983_824': 38.9032,
    '983_825': 38.6454,
    '983_826': 38.3543,
    '983_827': 38.0432,
    '983_828': 37.7207,
    '983_829': 37.392,
    '983_830': 37.0624,
    '983_831': 36.7362,
    '983_832': 36.4194,
    '983_833': 36.1242,
    '983_834': 35.8258,
    '983_835': 35.5311,
    '983_836': 35.2399,
    '983_837': 34.9451,
    '983_838': 34.6519,
    '983_839': 34.3601,
    '983_840': 34.073,
    '983_841': 33.786,
    '983_842': 33.4984,
    '983_843': 33.2139,
    '983_844': 32.9274,
    '983_845': 32.6336,
    '983_846': 32.3441,
    '983_847': 32.0506,
    '983_848': 31.7437,
    '984_612': 34.5426,
    '984_613': 34.5621,
    '984_614': 34.5747,
    '984_615': 34.5785,
    '984_616': 34.5827,
    '984_617': 34.5896,
    '984_618': 34.6013,
    '984_619': 34.6211,
    '984_620': 34.6574,
    '984_621': 34.6788,
    '984_622': 34.703,
    '984_623': 34.7481,
    '984_624': 34.8271,
    '984_625': 34.8884,
    '984_626': 34.9486,
    '984_627': 35.0141,
    '984_628': 35.0836,
    '984_629': 35.1509,
    '984_630': 35.2294,
    '984_631': 35.3212,
    '984_632': 35.4202,
    '984_633': 35.5174,
    '984_634': 35.6085,
    '984_635': 35.6965,
    '984_636': 35.7831,
    '984_637': 35.8686,
    '984_638': 35.9472,
    '984_639': 36.0226,
    '984_640': 36.0812,
    '984_641': 36.1337,
    '984_642': 36.1848,
    '984_643': 36.2373,
    '984_644': 36.3022,
    '984_645': 36.3734,
    '984_646': 36.4483,
    '984_647': 36.5256,
    '984_648': 36.6062,
    '984_649': 36.6922,
    '984_650': 36.7813,
    '984_651': 36.8697,
    '984_652': 36.9581,
    '984_653': 37.0428,
    '984_654': 37.13,
    '984_655': 37.2201,
    '984_656': 37.3155,
    '984_657': 37.4191,
    '984_658': 37.5337,
    '984_659': 37.6581,
    '984_660': 37.7904,
    '984_661': 37.9344,
    '984_662': 38.0929,
    '984_663': 38.2575,
    '984_664': 38.417,
    '984_665': 38.573,
    '984_666': 38.7555,
    '984_667': 38.9333,
    '984_668': 39.0903,
    '984_669': 39.2259,
    '984_670': 39.3455,
    '984_671': 39.4646,
    '984_672': 39.5725,
    '984_673': 39.6666,
    '984_674': 39.743,
    '984_675': 39.8116,
    '984_676': 39.8923,
    '984_677': 39.9874,
    '984_678': 40.0958,
    '984_679': 40.1994,
    '984_680': 40.2989,
    '984_681': 40.4,
    '984_682': 40.4871,
    '984_683': 40.5617,
    '984_684': 40.6139,
    '984_685': 40.6581,
    '984_686': 40.681,
    '984_687': 40.6889,
    '984_688': 40.7327,
    '984_689': 40.8101,
    '984_690': 40.8847,
    '984_691': 40.9566,
    '984_692': 41.0536,
    '984_693': 41.1844,
    '984_694': 41.3291,
    '984_695': 41.4919,
    '984_696': 41.67,
    '984_697': 41.8491,
    '984_698': 42.0148,
    '984_699': 42.2055,
    '984_700': 42.3683,
    '984_701': 42.4954,
    '984_702': 42.6258,
    '984_703': 42.7346,
    '984_704': 42.7597,
    '984_705': 42.8212,
    '984_706': 42.8293,
    '984_707': 42.8168,
    '984_708': 42.736,
    '984_709': 42.6421,
    '984_710': 42.5648,
    '984_711': 42.4019,
    '984_712': 42.2311,
    '984_713': 42.0474,
    '984_714': 41.8975,
    '984_715': 41.8124,
    '984_716': 41.7927,
    '984_717': 41.8215,
    '984_718': 41.8869,
    '984_719': 41.9679,
    '984_720': 42.0585,
    '984_721': 42.1637,
    '984_722': 42.2644,
    '984_723': 42.3307,
    '984_724': 42.3684,
    '984_725': 42.3785,
    '984_726': 42.3715,
    '984_727': 42.368,
    '984_728': 42.3763,
    '984_729': 42.4047,
    '984_730': 42.4511,
    '984_731': 42.5072,
    '984_732': 42.5781,
    '984_733': 42.6693,
    '984_734': 42.7733,
    '984_735': 42.8841,
    '984_736': 42.9929,
    '984_737': 43.0695,
    '984_738': 43.1379,
    '984_739': 43.1786,
    '984_740': 43.1877,
    '984_741': 43.1715,
    '984_742': 43.1933,
    '984_743': 43.1884,
    '984_744': 43.1764,
    '984_745': 43.1692,
    '984_746': 43.1407,
    '984_747': 43.0981,
    '984_748': 43.0086,
    '984_749': 42.9044,
    '984_750': 42.7955,
    '984_751': 42.6916,
    '984_752': 42.5972,
    '984_753': 42.5132,
    '984_754': 42.4347,
    '984_755': 42.3533,
    '984_756': 42.269,
    '984_757': 42.1797,
    '984_758': 42.0873,
    '984_759': 42.0079,
    '984_760': 41.9435,
    '984_761': 41.8988,
    '984_762': 41.871,
    '984_763': 41.8575,
    '984_764': 41.8541,
    '984_765': 41.8581,
    '984_766': 41.8681,
    '984_767': 41.8835,
    '984_768': 41.904,
    '984_769': 41.9281,
    '984_770': 41.9529,
    '984_771': 41.9722,
    '984_772': 41.9844,
    '984_773': 41.9919,
    '984_774': 41.9973,
    '984_775': 42.0083,
    '984_776': 42.016,
    '984_777': 42.0098,
    '984_778': 41.9991,
    '984_779': 41.9788,
    '984_780': 41.9645,
    '984_781': 41.9552,
    '984_782': 41.9495,
    '984_783': 41.9418,
    '984_784': 41.943,
    '984_785': 41.9492,
    '984_786': 41.9525,
    '984_787': 41.9347,
    '984_788': 41.9084,
    '984_789': 41.8801,
    '984_790': 41.8511,
    '984_791': 41.8237,
    '984_792': 41.8023,
    '984_793': 41.7909,
    '984_794': 41.786,
    '984_795': 41.7853,
    '984_796': 41.7875,
    '984_797': 41.794,
    '984_798': 41.8068,
    '984_799': 41.8305,
    '984_800': 41.8632,
    '984_801': 41.8863,
    '984_802': 41.8918,
    '984_803': 41.8812,
    '984_804': 41.8617,
    '984_805': 41.8225,
    '984_806': 41.7864,
    '984_807': 41.7301,
    '984_808': 41.6438,
    '984_809': 41.5371,
    '984_810': 41.4332,
    '984_811': 41.3243,
    '984_812': 41.2072,
    '984_813': 41.0741,
    '984_814': 40.9232,
    '984_815': 40.7568,
    '984_816': 40.5774,
    '984_817': 40.3886,
    '984_818': 40.1951,
    '984_819': 40.002,
    '984_820': 39.8108,
    '984_821': 39.6191,
    '984_822': 39.4227,
    '984_823': 39.2191,
    '984_824': 38.9995,
    '984_825': 38.7436,
    '984_826': 38.4613,
    '984_827': 38.1586,
    '984_828': 37.8424,
    '984_829': 37.5187,
    '984_830': 37.1924,
    '984_831': 36.8689,
    '984_832': 36.5532,
    '984_833': 36.2496,
    '984_834': 35.9505,
    '984_835': 35.6554,
    '984_836': 35.3605,
    '984_837': 35.0644,
    '984_838': 34.7717,
    '984_839': 34.4799,
    '984_840': 34.1923,
    '984_841': 33.9094,
    '984_842': 33.6271,
    '984_843': 33.342,
    '984_844': 33.0566,
    '984_845': 32.7677,
    '984_846': 32.4813,
    '984_847': 32.1911,
    '984_848': 31.8878,
    '985_612': 34.5094,
    '985_613': 34.5251,
    '985_614': 34.5335,
    '985_615': 34.5388,
    '985_616': 34.5441,
    '985_617': 34.551,
    '985_618': 34.5632,
    '985_619': 34.586,
    '985_620': 34.6226,
    '985_621': 34.6463,
    '985_622': 34.6743,
    '985_623': 34.7241,
    '985_624': 34.7985,
    '985_625': 34.8628,
    '985_626': 34.924,
    '985_627': 34.9856,
    '985_628': 35.0471,
    '985_629': 35.111,
    '985_630': 35.1904,
    '985_631': 35.2901,
    '985_632': 35.393,
    '985_633': 35.4882,
    '985_634': 35.5772,
    '985_635': 35.6636,
    '985_636': 35.7485,
    '985_637': 35.8317,
    '985_638': 35.9123,
    '985_639': 35.9902,
    '985_640': 36.0537,
    '985_641': 36.1089,
    '985_642': 36.1607,
    '985_643': 36.215,
    '985_644': 36.277,
    '985_645': 36.3435,
    '985_646': 36.4142,
    '985_647': 36.4866,
    '985_648': 36.5628,
    '985_649': 36.6473,
    '985_650': 36.7348,
    '985_651': 36.8223,
    '985_652': 36.9091,
    '985_653': 36.9941,
    '985_654': 37.0798,
    '985_655': 37.1694,
    '985_656': 37.2618,
    '985_657': 37.3602,
    '985_658': 37.4669,
    '985_659': 37.5856,
    '985_660': 37.7112,
    '985_661': 37.8458,
    '985_662': 37.9967,
    '985_663': 38.1562,
    '985_664': 38.3196,
    '985_665': 38.4808,
    '985_666': 38.666,
    '985_667': 38.8402,
    '985_668': 38.9899,
    '985_669': 39.1172,
    '985_670': 39.2306,
    '985_671': 39.3493,
    '985_672': 39.4594,
    '985_673': 39.5637,
    '985_674': 39.6499,
    '985_675': 39.7253,
    '985_676': 39.8009,
    '985_677': 39.8865,
    '985_678': 39.9806,
    '985_679': 40.0808,
    '985_680': 40.1824,
    '985_681': 40.2831,
    '985_682': 40.3759,
    '985_683': 40.4563,
    '985_684': 40.5288,
    '985_685': 40.5755,
    '985_686': 40.614,
    '985_687': 40.6253,
    '985_688': 40.6573,
    '985_689': 40.7265,
    '985_690': 40.8062,
    '985_691': 40.8867,
    '985_692': 40.9979,
    '985_693': 41.1363,
    '985_694': 41.2913,
    '985_695': 41.4592,
    '985_696': 41.6319,
    '985_697': 41.806,
    '985_698': 41.9864,
    '985_699': 42.1648,
    '985_700': 42.3236,
    '985_701': 42.4543,
    '985_702': 42.6172,
    '985_703': 42.6783,
    '985_704': 42.7101,
    '985_705': 42.7436,
    '985_706': 42.7449,
    '985_707': 42.763,
    '985_708': 42.6895,
    '985_709': 42.5893,
    '985_710': 42.4982,
    '985_711': 42.3687,
    '985_712': 42.1925,
    '985_713': 42.018,
    '985_714': 41.8751,
    '985_715': 41.7951,
    '985_716': 41.7769,
    '985_717': 41.8032,
    '985_718': 41.8616,
    '985_719': 41.9358,
    '985_720': 42.0187,
    '985_721': 42.1178,
    '985_722': 42.215,
    '985_723': 42.2911,
    '985_724': 42.3348,
    '985_725': 42.3521,
    '985_726': 42.351,
    '985_727': 42.3537,
    '985_728': 42.3742,
    '985_729': 42.4249,
    '985_730': 42.4882,
    '985_731': 42.5528,
    '985_732': 42.6265,
    '985_733': 42.7267,
    '985_734': 42.8371,
    '985_735': 42.9459,
    '985_736': 43.0436,
    '985_737': 43.1126,
    '985_738': 43.1558,
    '985_739': 43.1989,
    '985_740': 43.2028,
    '985_741': 43.2005,
    '985_742': 43.2042,
    '985_743': 43.1867,
    '985_744': 43.1731,
    '985_745': 43.167,
    '985_746': 43.1499,
    '985_747': 43.1107,
    '985_748': 43.0278,
    '985_749': 42.925,
    '985_750': 42.817,
    '985_751': 42.7137,
    '985_752': 42.6271,
    '985_753': 42.5603,
    '985_754': 42.4965,
    '985_755': 42.4242,
    '985_756': 42.3349,
    '985_757': 42.2356,
    '985_758': 42.1347,
    '985_759': 42.0487,
    '985_760': 41.9812,
    '985_761': 41.9375,
    '985_762': 41.9151,
    '985_763': 41.9111,
    '985_764': 41.9185,
    '985_765': 41.9325,
    '985_766': 41.9495,
    '985_767': 41.9717,
    '985_768': 41.998,
    '985_769': 42.0262,
    '985_770': 42.0543,
    '985_771': 42.074,
    '985_772': 42.0864,
    '985_773': 42.096,
    '985_774': 42.101,
    '985_775': 42.1139,
    '985_776': 42.1215,
    '985_777': 42.1123,
    '985_778': 42.0955,
    '985_779': 42.0765,
    '985_780': 42.0591,
    '985_781': 42.0473,
    '985_782': 42.0388,
    '985_783': 42.0287,
    '985_784': 42.0264,
    '985_785': 42.0289,
    '985_786': 42.0261,
    '985_787': 42.005,
    '985_788': 41.9783,
    '985_789': 41.9466,
    '985_790': 41.9157,
    '985_791': 41.8877,
    '985_792': 41.868,
    '985_793': 41.8559,
    '985_794': 41.8498,
    '985_795': 41.8474,
    '985_796': 41.8474,
    '985_797': 41.8519,
    '985_798': 41.8627,
    '985_799': 41.8843,
    '985_800': 41.9143,
    '985_801': 41.9392,
    '985_802': 41.9475,
    '985_803': 41.9423,
    '985_804': 41.9268,
    '985_805': 41.9001,
    '985_806': 41.8666,
    '985_807': 41.8049,
    '985_808': 41.7213,
    '985_809': 41.6259,
    '985_810': 41.5227,
    '985_811': 41.4115,
    '985_812': 41.2934,
    '985_813': 41.1596,
    '985_814': 41.0081,
    '985_815': 40.8387,
    '985_816': 40.6564,
    '985_817': 40.4681,
    '985_818': 40.2796,
    '985_819': 40.0928,
    '985_820': 39.9049,
    '985_821': 39.7154,
    '985_822': 39.5213,
    '985_823': 39.319,
    '985_824': 39.1005,
    '985_825': 38.849,
    '985_826': 38.5737,
    '985_827': 38.2778,
    '985_828': 37.9674,
    '985_829': 37.6479,
    '985_830': 37.325,
    '985_831': 37.0038,
    '985_832': 36.6893,
    '985_833': 36.3802,
    '985_834': 36.08,
    '985_835': 35.7858,
    '985_836': 35.4877,
    '985_837': 35.1885,
    '985_838': 34.8974,
    '985_839': 34.6043,
    '985_840': 34.3161,
    '985_841': 34.0385,
    '985_842': 33.7629,
    '985_843': 33.4764,
    '985_844': 33.1946,
    '985_845': 32.9099,
    '985_846': 32.6239,
    '985_847': 32.3362,
    '985_848': 32.0362,
    '986_612': 34.4796,
    '986_613': 34.4895,
    '986_614': 34.4947,
    '986_615': 34.5007,
    '986_616': 34.5075,
    '986_617': 34.5141,
    '986_618': 34.5257,
    '986_619': 34.5473,
    '986_620': 34.5867,
    '986_621': 34.6113,
    '986_622': 34.6391,
    '986_623': 34.6898,
    '986_624': 34.7637,
    '986_625': 34.8311,
    '986_626': 34.8941,
    '986_627': 34.9533,
    '986_628': 35.0069,
    '986_629': 35.0678,
    '986_630': 35.1509,
    '986_631': 35.2606,
    '986_632': 35.3667,
    '986_633': 35.4574,
    '986_634': 35.5421,
    '986_635': 35.6251,
    '986_636': 35.7076,
    '986_637': 35.7904,
    '986_638': 35.8751,
    '986_639': 35.9564,
    '986_640': 36.0223,
    '986_641': 36.0815,
    '986_642': 36.1363,
    '986_643': 36.1892,
    '986_644': 36.2474,
    '986_645': 36.3109,
    '986_646': 36.3804,
    '986_647': 36.4505,
    '986_648': 36.5255,
    '986_649': 36.6063,
    '986_650': 36.6905,
    '986_651': 36.7763,
    '986_652': 36.8621,
    '986_653': 36.9471,
    '986_654': 37.0319,
    '986_655': 37.1198,
    '986_656': 37.2092,
    '986_657': 37.3032,
    '986_658': 37.4033,
    '986_659': 37.513,
    '986_660': 37.6318,
    '986_661': 37.7574,
    '986_662': 37.898,
    '986_663': 38.0501,
    '986_664': 38.2104,
    '986_665': 38.3781,
    '986_666': 38.5625,
    '986_667': 38.7327,
    '986_668': 38.8777,
    '986_669': 39.0017,
    '986_670': 39.1149,
    '986_671': 39.2329,
    '986_672': 39.34,
    '986_673': 39.4473,
    '986_674': 39.5398,
    '986_675': 39.6165,
    '986_676': 39.7011,
    '986_677': 39.7874,
    '986_678': 39.8679,
    '986_679': 39.9632,
    '986_680': 40.0669,
    '986_681': 40.1641,
    '986_682': 40.2558,
    '986_683': 40.3412,
    '986_684': 40.4219,
    '986_685': 40.4842,
    '986_686': 40.5287,
    '986_687': 40.5481,
    '986_688': 40.5771,
    '986_689': 40.6327,
    '986_690': 40.709,
    '986_691': 40.8055,
    '986_692': 40.942,
    '986_693': 41.0919,
    '986_694': 41.2552,
    '986_695': 41.4306,
    '986_696': 41.5973,
    '986_697': 41.7677,
    '986_698': 41.9505,
    '986_699': 42.1205,
    '986_700': 42.28,
    '986_701': 42.4273,
    '986_702': 42.5771,
    '986_703': 42.6605,
    '986_704': 42.6726,
    '986_705': 42.7023,
    '986_706': 42.6834,
    '986_707': 42.7119,
    '986_708': 42.6483,
    '986_709': 42.5555,
    '986_710': 42.4658,
    '986_711': 42.3332,
    '986_712': 42.1601,
    '986_713': 41.9909,
    '986_714': 41.8589,
    '986_715': 41.7837,
    '986_716': 41.767,
    '986_717': 41.7883,
    '986_718': 41.8371,
    '986_719': 41.9044,
    '986_720': 41.983,
    '986_721': 42.0744,
    '986_722': 42.1675,
    '986_723': 42.2427,
    '986_724': 42.2916,
    '986_725': 42.3162,
    '986_726': 42.3229,
    '986_727': 42.3332,
    '986_728': 42.3747,
    '986_729': 42.4545,
    '986_730': 42.5355,
    '986_731': 42.6039,
    '986_732': 42.6793,
    '986_733': 42.7795,
    '986_734': 42.8919,
    '986_735': 42.992,
    '986_736': 43.0705,
    '986_737': 43.1442,
    '986_738': 43.1847,
    '986_739': 43.2108,
    '986_740': 43.2126,
    '986_741': 43.2028,
    '986_742': 43.188,
    '986_743': 43.1728,
    '986_744': 43.1624,
    '986_745': 43.1622,
    '986_746': 43.1498,
    '986_747': 43.1177,
    '986_748': 43.0359,
    '986_749': 42.9359,
    '986_750': 42.8327,
    '986_751': 42.7383,
    '986_752': 42.6709,
    '986_753': 42.6234,
    '986_754': 42.5738,
    '986_755': 42.5067,
    '986_756': 42.4141,
    '986_757': 42.3054,
    '986_758': 42.1948,
    '986_759': 42.0978,
    '986_760': 42.027,
    '986_761': 41.9853,
    '986_762': 41.971,
    '986_763': 41.9796,
    '986_764': 42.0001,
    '986_765': 42.0249,
    '986_766': 42.0499,
    '986_767': 42.0777,
    '986_768': 42.1079,
    '986_769': 42.1363,
    '986_770': 42.1622,
    '986_771': 42.1813,
    '986_772': 42.1945,
    '986_773': 42.2059,
    '986_774': 42.2134,
    '986_775': 42.2209,
    '986_776': 42.2287,
    '986_777': 42.217,
    '986_778': 42.1965,
    '986_779': 42.1725,
    '986_780': 42.1576,
    '986_781': 42.1441,
    '986_782': 42.1281,
    '986_783': 42.118,
    '986_784': 42.1128,
    '986_785': 42.113,
    '986_786': 42.0975,
    '986_787': 42.0743,
    '986_788': 42.0487,
    '986_789': 42.0139,
    '986_790': 41.982,
    '986_791': 41.954,
    '986_792': 41.9334,
    '986_793': 41.9196,
    '986_794': 41.9113,
    '986_795': 41.9069,
    '986_796': 41.9048,
    '986_797': 41.9075,
    '986_798': 41.9168,
    '986_799': 41.9362,
    '986_800': 41.9614,
    '986_801': 41.985,
    '986_802': 41.9978,
    '986_803': 41.9973,
    '986_804': 41.9863,
    '986_805': 41.9681,
    '986_806': 41.9339,
    '986_807': 41.8723,
    '986_808': 41.7961,
    '986_809': 41.7091,
    '986_810': 41.6094,
    '986_811': 41.497,
    '986_812': 41.3756,
    '986_813': 41.2416,
    '986_814': 41.0878,
    '986_815': 40.9161,
    '986_816': 40.7357,
    '986_817': 40.5506,
    '986_818': 40.3682,
    '986_819': 40.1847,
    '986_820': 40.0003,
    '986_821': 39.8154,
    '986_822': 39.6261,
    '986_823': 39.4286,
    '986_824': 39.2127,
    '986_825': 38.9668,
    '986_826': 38.6965,
    '986_827': 38.4051,
    '986_828': 38.0983,
    '986_829': 37.782,
    '986_830': 37.4616,
    '986_831': 37.1421,
    '986_832': 36.8279,
    '986_833': 36.5199,
    '986_834': 36.2206,
    '986_835': 35.9278,
    '986_836': 35.6311,
    '986_837': 35.3322,
    '986_838': 35.0364,
    '986_839': 34.741,
    '986_840': 34.4563,
    '986_841': 34.1811,
    '986_842': 33.9059,
    '986_843': 33.6267,
    '986_844': 33.3465,
    '986_845': 33.0617,
    '986_846': 32.7739,
    '986_847': 32.4902,
    '986_848': 32.1961,
    '987_612': 34.4444,
    '987_613': 34.4516,
    '987_614': 34.4549,
    '987_615': 34.4622,
    '987_616': 34.4694,
    '987_617': 34.4775,
    '987_618': 34.4913,
    '987_619': 34.5161,
    '987_620': 34.5513,
    '987_621': 34.5758,
    '987_622': 34.5996,
    '987_623': 34.6501,
    '987_624': 34.7342,
    '987_625': 34.8003,
    '987_626': 34.8631,
    '987_627': 34.9193,
    '987_628': 34.9704,
    '987_629': 35.0304,
    '987_630': 35.1251,
    '987_631': 35.2366,
    '987_632': 35.335,
    '987_633': 35.4216,
    '987_634': 35.5024,
    '987_635': 35.582,
    '987_636': 35.6629,
    '987_637': 35.7455,
    '987_638': 35.8296,
    '987_639': 35.9092,
    '987_640': 35.9796,
    '987_641': 36.0442,
    '987_642': 36.101,
    '987_643': 36.156,
    '987_644': 36.2113,
    '987_645': 36.2715,
    '987_646': 36.34,
    '987_647': 36.4113,
    '987_648': 36.4868,
    '987_649': 36.5651,
    '987_650': 36.647,
    '987_651': 36.7318,
    '987_652': 36.8171,
    '987_653': 36.9013,
    '987_654': 36.9849,
    '987_655': 37.0687,
    '987_656': 37.1556,
    '987_657': 37.2446,
    '987_658': 37.3382,
    '987_659': 37.4399,
    '987_660': 37.5506,
    '987_661': 37.6697,
    '987_662': 37.8009,
    '987_663': 37.9424,
    '987_664': 38.098,
    '987_665': 38.2688,
    '987_666': 38.4514,
    '987_667': 38.6124,
    '987_668': 38.7459,
    '987_669': 38.8706,
    '987_670': 38.9906,
    '987_671': 39.1076,
    '987_672': 39.2148,
    '987_673': 39.3201,
    '987_674': 39.4068,
    '987_675': 39.4895,
    '987_676': 39.5846,
    '987_677': 39.6829,
    '987_678': 39.7656,
    '987_679': 39.8515,
    '987_680': 39.951,
    '987_681': 40.0427,
    '987_682': 40.1297,
    '987_683': 40.2129,
    '987_684': 40.2977,
    '987_685': 40.3679,
    '987_686': 40.4158,
    '987_687': 40.4531,
    '987_688': 40.4843,
    '987_689': 40.5288,
    '987_690': 40.5992,
    '987_691': 40.7213,
    '987_692': 40.8783,
    '987_693': 41.0424,
    '987_694': 41.2084,
    '987_695': 41.3784,
    '987_696': 41.5495,
    '987_697': 41.7205,
    '987_698': 41.8921,
    '987_699': 42.0638,
    '987_700': 42.2328,
    '987_701': 42.3577,
    '987_702': 42.4706,
    '987_703': 42.6089,
    '987_704': 42.6119,
    '987_705': 42.6602,
    '987_706': 42.6088,
    '987_707': 42.6566,
    '987_708': 42.5948,
    '987_709': 42.4754,
    '987_710': 42.3972,
    '987_711': 42.2816,
    '987_712': 42.1267,
    '987_713': 41.9666,
    '987_714': 41.8459,
    '987_715': 41.7774,
    '987_716': 41.7589,
    '987_717': 41.7759,
    '987_718': 41.8149,
    '987_719': 41.8763,
    '987_720': 41.9554,
    '987_721': 42.0407,
    '987_722': 42.1214,
    '987_723': 42.1894,
    '987_724': 42.242,
    '987_725': 42.2721,
    '987_726': 42.2834,
    '987_727': 42.3108,
    '987_728': 42.3791,
    '987_729': 42.4799,
    '987_730': 42.5687,
    '987_731': 42.6478,
    '987_732': 42.7228,
    '987_733': 42.8247,
    '987_734': 42.9383,
    '987_735': 43.0381,
    '987_736': 43.0959,
    '987_737': 43.1476,
    '987_738': 43.1709,
    '987_739': 43.1787,
    '987_740': 43.1734,
    '987_741': 43.165,
    '987_742': 43.1547,
    '987_743': 43.1492,
    '987_744': 43.1495,
    '987_745': 43.1487,
    '987_746': 43.1316,
    '987_747': 43.1044,
    '987_748': 43.0379,
    '987_749': 42.943,
    '987_750': 42.8469,
    '987_751': 42.7667,
    '987_752': 42.718,
    '987_753': 42.6855,
    '987_754': 42.6476,
    '987_755': 42.585,
    '987_756': 42.4945,
    '987_757': 42.3798,
    '987_758': 42.2584,
    '987_759': 42.1505,
    '987_760': 42.0763,
    '987_761': 42.0389,
    '987_762': 42.0365,
    '987_763': 42.0594,
    '987_764': 42.0933,
    '987_765': 42.13,
    '987_766': 42.1644,
    '987_767': 42.1986,
    '987_768': 42.2301,
    '987_769': 42.2552,
    '987_770': 42.2742,
    '987_771': 42.2863,
    '987_772': 42.3018,
    '987_773': 42.3167,
    '987_774': 42.3311,
    '987_775': 42.3251,
    '987_776': 42.3266,
    '987_777': 42.3142,
    '987_778': 42.2909,
    '987_779': 42.2651,
    '987_780': 42.2551,
    '987_781': 42.2387,
    '987_782': 42.2227,
    '987_783': 42.2063,
    '987_784': 42.1992,
    '987_785': 42.1927,
    '987_786': 42.1706,
    '987_787': 42.1471,
    '987_788': 42.1166,
    '987_789': 42.0811,
    '987_790': 42.0476,
    '987_791': 42.0183,
    '987_792': 41.9964,
    '987_793': 41.9809,
    '987_794': 41.9701,
    '987_795': 41.9631,
    '987_796': 41.9589,
    '987_797': 41.9595,
    '987_798': 41.9664,
    '987_799': 41.9819,
    '987_800': 42.0012,
    '987_801': 42.0205,
    '987_802': 42.0368,
    '987_803': 42.0452,
    '987_804': 42.0399,
    '987_805': 42.0237,
    '987_806': 41.9896,
    '987_807': 41.9334,
    '987_808': 41.8667,
    '987_809': 41.7831,
    '987_810': 41.6839,
    '987_811': 41.5748,
    '987_812': 41.454,
    '987_813': 41.3176,
    '987_814': 41.1615,
    '987_815': 40.9915,
    '987_816': 40.8165,
    '987_817': 40.6375,
    '987_818': 40.4596,
    '987_819': 40.2783,
    '987_820': 40.0984,
    '987_821': 39.9213,
    '987_822': 39.7412,
    '987_823': 39.5519,
    '987_824': 39.3424,
    '987_825': 39.0994,
    '987_826': 38.8306,
    '987_827': 38.5407,
    '987_828': 38.2354,
    '987_829': 37.9211,
    '987_830': 37.6049,
    '987_831': 37.2924,
    '987_832': 36.9812,
    '987_833': 36.6752,
    '987_834': 36.3762,
    '987_835': 36.0795,
    '987_836': 35.781,
    '987_837': 35.4807,
    '987_838': 35.1824,
    '987_839': 34.8815,
    '987_840': 34.5996,
    '987_841': 34.3268,
    '987_842': 34.0522,
    '987_843': 33.7786,
    '987_844': 33.5012,
    '987_845': 33.2167,
    '987_846': 32.9279,
    '987_847': 32.6446,
    '987_848': 32.3561,
    '988_612': 34.4114,
    '988_613': 34.4164,
    '988_614': 34.4208,
    '988_615': 34.4274,
    '988_616': 34.433,
    '988_617': 34.4409,
    '988_618': 34.4553,
    '988_619': 34.4799,
    '988_620': 34.5143,
    '988_621': 34.5501,
    '988_622': 34.5811,
    '988_623': 34.6318,
    '988_624': 34.7115,
    '988_625': 34.7766,
    '988_626': 34.8332,
    '988_627': 34.8882,
    '988_628': 34.9447,
    '988_629': 35.0121,
    '988_630': 35.1027,
    '988_631': 35.2055,
    '988_632': 35.3009,
    '988_633': 35.3833,
    '988_634': 35.4604,
    '988_635': 35.5373,
    '988_636': 35.6158,
    '988_637': 35.6974,
    '988_638': 35.7805,
    '988_639': 35.8573,
    '988_640': 35.9274,
    '988_641': 35.9935,
    '988_642': 36.0543,
    '988_643': 36.1119,
    '988_644': 36.1698,
    '988_645': 36.2315,
    '988_646': 36.2988,
    '988_647': 36.3696,
    '988_648': 36.4448,
    '988_649': 36.5231,
    '988_650': 36.6048,
    '988_651': 36.6889,
    '988_652': 36.7736,
    '988_653': 36.8567,
    '988_654': 36.9392,
    '988_655': 37.0197,
    '988_656': 37.1016,
    '988_657': 37.1877,
    '988_658': 37.2762,
    '988_659': 37.3714,
    '988_660': 37.4745,
    '988_661': 37.5862,
    '988_662': 37.7088,
    '988_663': 37.8423,
    '988_664': 37.9904,
    '988_665': 38.159,
    '988_666': 38.3347,
    '988_667': 38.4768,
    '988_668': 38.6018,
    '988_669': 38.7231,
    '988_670': 38.8462,
    '988_671': 38.9703,
    '988_672': 39.0809,
    '988_673': 39.182,
    '988_674': 39.2633,
    '988_675': 39.3485,
    '988_676': 39.4525,
    '988_677': 39.5656,
    '988_678': 39.6623,
    '988_679': 39.742,
    '988_680': 39.8374,
    '988_681': 39.9218,
    '988_682': 40.0026,
    '988_683': 40.078,
    '988_684': 40.1587,
    '988_685': 40.233,
    '988_686': 40.2897,
    '988_687': 40.3345,
    '988_688': 40.3754,
    '988_689': 40.418,
    '988_690': 40.491,
    '988_691': 40.6337,
    '988_692': 40.7985,
    '988_693': 40.9728,
    '988_694': 41.1522,
    '988_695': 41.3231,
    '988_696': 41.4815,
    '988_697': 41.6493,
    '988_698': 41.8167,
    '988_699': 41.9881,
    '988_700': 42.1472,
    '988_701': 42.2708,
    '988_702': 42.3701,
    '988_703': 42.5342,
    '988_704': 42.5338,
    '988_705': 42.5986,
    '988_706': 42.5644,
    '988_707': 42.595,
    '988_708': 42.5416,
    '988_709': 42.4432,
    '988_710': 42.3651,
    '988_711': 42.2359,
    '988_712': 42.095,
    '988_713': 41.9473,
    '988_714': 41.8351,
    '988_715': 41.7739,
    '988_716': 41.7556,
    '988_717': 41.7668,
    '988_718': 41.7975,
    '988_719': 41.8569,
    '988_720': 41.9384,
    '988_721': 42.018,
    '988_722': 42.0858,
    '988_723': 42.1383,
    '988_724': 42.1818,
    '988_725': 42.215,
    '988_726': 42.2405,
    '988_727': 42.3012,
    '988_728': 42.3914,
    '988_729': 42.4976,
    '988_730': 42.596,
    '988_731': 42.6794,
    '988_732': 42.7637,
    '988_733': 42.8661,
    '988_734': 42.9698,
    '988_735': 43.0522,
    '988_736': 43.1077,
    '988_737': 43.1396,
    '988_738': 43.1503,
    '988_739': 43.1444,
    '988_740': 43.1336,
    '988_741': 43.1245,
    '988_742': 43.1196,
    '988_743': 43.1205,
    '988_744': 43.126,
    '988_745': 43.1293,
    '988_746': 43.1143,
    '988_747': 43.088,
    '988_748': 43.0261,
    '988_749': 42.9479,
    '988_750': 42.8618,
    '988_751': 42.7944,
    '988_752': 42.7568,
    '988_753': 42.7341,
    '988_754': 42.7022,
    '988_755': 42.6452,
    '988_756': 42.5547,
    '988_757': 42.4411,
    '988_758': 42.3165,
    '988_759': 42.2044,
    '988_760': 42.129,
    '988_761': 42.0978,
    '988_762': 42.109,
    '988_763': 42.1454,
    '988_764': 42.1934,
    '988_765': 42.2448,
    '988_766': 42.2923,
    '988_767': 42.3347,
    '988_768': 42.3655,
    '988_769': 42.3804,
    '988_770': 42.3881,
    '988_771': 42.3936,
    '988_772': 42.41,
    '988_773': 42.4343,
    '988_774': 42.451,
    '988_775': 42.444,
    '988_776': 42.4288,
    '988_777': 42.4123,
    '988_778': 42.3905,
    '988_779': 42.3656,
    '988_780': 42.3506,
    '988_781': 42.3333,
    '988_782': 42.3153,
    '988_783': 42.3001,
    '988_784': 42.2839,
    '988_785': 42.2676,
    '988_786': 42.2485,
    '988_787': 42.2199,
    '988_788': 42.1832,
    '988_789': 42.1482,
    '988_790': 42.1118,
    '988_791': 42.0812,
    '988_792': 42.0581,
    '988_793': 42.0409,
    '988_794': 42.0276,
    '988_795': 42.0175,
    '988_796': 42.011,
    '988_797': 42.0088,
    '988_798': 42.0116,
    '988_799': 42.0225,
    '988_800': 42.0371,
    '988_801': 42.0527,
    '988_802': 42.0704,
    '988_803': 42.0854,
    '988_804': 42.0852,
    '988_805': 42.0723,
    '988_806': 42.0407,
    '988_807': 41.9905,
    '988_808': 41.9326,
    '988_809': 41.8547,
    '988_810': 41.7556,
    '988_811': 41.6462,
    '988_812': 41.5267,
    '988_813': 41.3923,
    '988_814': 41.2369,
    '988_815': 41.0695,
    '988_816': 40.9005,
    '988_817': 40.7285,
    '988_818': 40.5556,
    '988_819': 40.3784,
    '988_820': 40.2043,
    '988_821': 40.0356,
    '988_822': 39.8662,
    '988_823': 39.6867,
    '988_824': 39.4841,
    '988_825': 39.2444,
    '988_826': 38.9755,
    '988_827': 38.6844,
    '988_828': 38.3778,
    '988_829': 38.0622,
    '988_830': 37.7451,
    '988_831': 37.4371,
    '988_832': 37.1305,
    '988_833': 36.825,
    '988_834': 36.5228,
    '988_835': 36.2213,
    '988_836': 35.9219,
    '988_837': 35.6242,
    '988_838': 35.3271,
    '988_839': 35.0309,
    '988_840': 34.7459,
    '988_841': 34.4729,
    '988_842': 34.1983,
    '988_843': 33.9223,
    '988_844': 33.6433,
    '988_845': 33.3603,
    '988_846': 33.0776,
    '988_847': 32.7945,
    '988_848': 32.512,
    '989_612': 34.3744,
    '989_613': 34.3801,
    '989_614': 34.3863,
    '989_615': 34.392,
    '989_616': 34.3967,
    '989_617': 34.4034,
    '989_618': 34.4159,
    '989_619': 34.4387,
    '989_620': 34.4748,
    '989_621': 34.5249,
    '989_622': 34.5651,
    '989_623': 34.6236,
    '989_624': 34.6968,
    '989_625': 34.7601,
    '989_626': 34.8104,
    '989_627': 34.8627,
    '989_628': 34.9232,
    '989_629': 34.9953,
    '989_630': 35.0808,
    '989_631': 35.1772,
    '989_632': 35.2695,
    '989_633': 35.3444,
    '989_634': 35.4174,
    '989_635': 35.4917,
    '989_636': 35.5686,
    '989_637': 35.6504,
    '989_638': 35.7317,
    '989_639': 35.8035,
    '989_640': 35.8722,
    '989_641': 35.9397,
    '989_642': 36.0026,
    '989_643': 36.0631,
    '989_644': 36.1243,
    '989_645': 36.1889,
    '989_646': 36.2567,
    '989_647': 36.3274,
    '989_648': 36.4031,
    '989_649': 36.4821,
    '989_650': 36.5632,
    '989_651': 36.6451,
    '989_652': 36.7275,
    '989_653': 36.8102,
    '989_654': 36.8928,
    '989_655': 36.9705,
    '989_656': 37.0496,
    '989_657': 37.1326,
    '989_658': 37.2175,
    '989_659': 37.3075,
    '989_660': 37.4047,
    '989_661': 37.5106,
    '989_662': 37.6247,
    '989_663': 37.7498,
    '989_664': 37.8877,
    '989_665': 38.0431,
    '989_666': 38.2002,
    '989_667': 38.3326,
    '989_668': 38.4526,
    '989_669': 38.5716,
    '989_670': 38.6982,
    '989_671': 38.831,
    '989_672': 38.95,
    '989_673': 39.0385,
    '989_674': 39.1173,
    '989_675': 39.2001,
    '989_676': 39.3015,
    '989_677': 39.4289,
    '989_678': 39.5445,
    '989_679': 39.6339,
    '989_680': 39.7191,
    '989_681': 39.8012,
    '989_682': 39.8754,
    '989_683': 39.938,
    '989_684': 40.0103,
    '989_685': 40.0811,
    '989_686': 40.1406,
    '989_687': 40.197,
    '989_688': 40.2461,
    '989_689': 40.296,
    '989_690': 40.3811,
    '989_691': 40.5326,
    '989_692': 40.706,
    '989_693': 40.8871,
    '989_694': 41.0656,
    '989_695': 41.2379,
    '989_696': 41.4076,
    '989_697': 41.5674,
    '989_698': 41.7338,
    '989_699': 41.9051,
    '989_700': 42.0753,
    '989_701': 42.2117,
    '989_702': 42.2869,
    '989_703': 42.4449,
    '989_704': 42.5022,
    '989_705': 42.5368,
    '989_706': 42.506,
    '989_707': 42.5285,
    '989_708': 42.5046,
    '989_709': 42.4333,
    '989_710': 42.3282,
    '989_711': 42.1977,
    '989_712': 42.0608,
    '989_713': 41.934,
    '989_714': 41.8289,
    '989_715': 41.7723,
    '989_716': 41.754,
    '989_717': 41.7593,
    '989_718': 41.7838,
    '989_719': 41.8378,
    '989_720': 41.9126,
    '989_721': 41.9858,
    '989_722': 42.0466,
    '989_723': 42.0851,
    '989_724': 42.122,
    '989_725': 42.1542,
    '989_726': 42.2035,
    '989_727': 42.2957,
    '989_728': 42.4004,
    '989_729': 42.5049,
    '989_730': 42.6075,
    '989_731': 42.6978,
    '989_732': 42.7974,
    '989_733': 42.8964,
    '989_734': 42.9942,
    '989_735': 43.0741,
    '989_736': 43.1198,
    '989_737': 43.1418,
    '989_738': 43.1418,
    '989_739': 43.1256,
    '989_740': 43.1093,
    '989_741': 43.0949,
    '989_742': 43.0912,
    '989_743': 43.0947,
    '989_744': 43.0999,
    '989_745': 43.1031,
    '989_746': 43.095,
    '989_747': 43.0705,
    '989_748': 43.0096,
    '989_749': 42.9384,
    '989_750': 42.868,
    '989_751': 42.8103,
    '989_752': 42.7796,
    '989_753': 42.7611,
    '989_754': 42.7296,
    '989_755': 42.676,
    '989_756': 42.5877,
    '989_757': 42.4792,
    '989_758': 42.3608,
    '989_759': 42.2561,
    '989_760': 42.1842,
    '989_761': 42.1611,
    '989_762': 42.184,
    '989_763': 42.2328,
    '989_764': 42.2968,
    '989_765': 42.369,
    '989_766': 42.4358,
    '989_767': 42.4824,
    '989_768': 42.5034,
    '989_769': 42.5048,
    '989_770': 42.5013,
    '989_771': 42.5036,
    '989_772': 42.5207,
    '989_773': 42.5473,
    '989_774': 42.5699,
    '989_775': 42.5676,
    '989_776': 42.5443,
    '989_777': 42.5254,
    '989_778': 42.5059,
    '989_779': 42.4753,
    '989_780': 42.4477,
    '989_781': 42.4278,
    '989_782': 42.4059,
    '989_783': 42.3879,
    '989_784': 42.3572,
    '989_785': 42.3358,
    '989_786': 42.3212,
    '989_787': 42.288,
    '989_788': 42.2475,
    '989_789': 42.2091,
    '989_790': 42.1736,
    '989_791': 42.1427,
    '989_792': 42.1191,
    '989_793': 42.1006,
    '989_794': 42.0846,
    '989_795': 42.0721,
    '989_796': 42.0635,
    '989_797': 42.0581,
    '989_798': 42.0564,
    '989_799': 42.0614,
    '989_800': 42.0716,
    '989_801': 42.0841,
    '989_802': 42.1002,
    '989_803': 42.1163,
    '989_804': 42.1249,
    '989_805': 42.1174,
    '989_806': 42.0901,
    '989_807': 42.0458,
    '989_808': 41.9909,
    '989_809': 41.9191,
    '989_810': 41.8247,
    '989_811': 41.7183,
    '989_812': 41.6014,
    '989_813': 41.4673,
    '989_814': 41.3124,
    '989_815': 41.1499,
    '989_816': 40.9883,
    '989_817': 40.8238,
    '989_818': 40.6545,
    '989_819': 40.4818,
    '989_820': 40.3155,
    '989_821': 40.1569,
    '989_822': 39.9975,
    '989_823': 39.8304,
    '989_824': 39.6409,
    '989_825': 39.4018,
    '989_826': 39.1295,
    '989_827': 38.8352,
    '989_828': 38.5264,
    '989_829': 38.2098,
    '989_830': 37.8928,
    '989_831': 37.5855,
    '989_832': 37.28,
    '989_833': 36.9724,
    '989_834': 36.6676,
    '989_835': 36.3613,
    '989_836': 36.062,
    '989_837': 35.768,
    '989_838': 35.4744,
    '989_839': 35.1811,
    '989_840': 34.8938,
    '989_841': 34.6228,
    '989_842': 34.3465,
    '989_843': 34.0665,
    '989_844': 33.787,
    '989_845': 33.5059,
    '989_846': 33.2315,
    '989_847': 32.953,
    '989_848': 32.6722,
    '990_612': 34.3335,
    '990_613': 34.3419,
    '990_614': 34.3516,
    '990_615': 34.3573,
    '990_616': 34.3614,
    '990_617': 34.366,
    '990_618': 34.3752,
    '990_619': 34.3951,
    '990_620': 34.4296,
    '990_621': 34.4972,
    '990_622': 34.5408,
    '990_623': 34.6102,
    '990_624': 34.6839,
    '990_625': 34.7401,
    '990_626': 34.7856,
    '990_627': 34.8369,
    '990_628': 34.9024,
    '990_629': 34.9782,
    '990_630': 35.0605,
    '990_631': 35.1479,
    '990_632': 35.2309,
    '990_633': 35.301,
    '990_634': 35.3729,
    '990_635': 35.4461,
    '990_636': 35.5234,
    '990_637': 35.6024,
    '990_638': 35.6794,
    '990_639': 35.7507,
    '990_640': 35.8248,
    '990_641': 35.8883,
    '990_642': 35.9501,
    '990_643': 36.012,
    '990_644': 36.0757,
    '990_645': 36.1442,
    '990_646': 36.2143,
    '990_647': 36.2876,
    '990_648': 36.362,
    '990_649': 36.4407,
    '990_650': 36.5203,
    '990_651': 36.5992,
    '990_652': 36.6778,
    '990_653': 36.7574,
    '990_654': 36.8375,
    '990_655': 36.9163,
    '990_656': 36.9956,
    '990_657': 37.0759,
    '990_658': 37.1595,
    '990_659': 37.246,
    '990_660': 37.338,
    '990_661': 37.4379,
    '990_662': 37.5448,
    '990_663': 37.661,
    '990_664': 37.786,
    '990_665': 37.924,
    '990_666': 38.0681,
    '990_667': 38.1925,
    '990_668': 38.3087,
    '990_669': 38.4332,
    '990_670': 38.5678,
    '990_671': 38.7092,
    '990_672': 38.8299,
    '990_673': 38.9024,
    '990_674': 38.9675,
    '990_675': 39.0457,
    '990_676': 39.139,
    '990_677': 39.2651,
    '990_678': 39.4059,
    '990_679': 39.5114,
    '990_680': 39.5911,
    '990_681': 39.6734,
    '990_682': 39.7428,
    '990_683': 39.7927,
    '990_684': 39.8504,
    '990_685': 39.9112,
    '990_686': 39.9758,
    '990_687': 40.0398,
    '990_688': 40.0992,
    '990_689': 40.1601,
    '990_690': 40.2587,
    '990_691': 40.4162,
    '990_692': 40.589,
    '990_693': 40.7701,
    '990_694': 40.9563,
    '990_695': 41.137,
    '990_696': 41.3089,
    '990_697': 41.4686,
    '990_698': 41.6424,
    '990_699': 41.8056,
    '990_700': 41.9754,
    '990_701': 42.1187,
    '990_702': 42.2377,
    '990_703': 42.3369,
    '990_704': 42.4105,
    '990_705': 42.4697,
    '990_706': 42.4597,
    '990_707': 42.4547,
    '990_708': 42.4307,
    '990_709': 42.3846,
    '990_710': 42.2831,
    '990_711': 42.1669,
    '990_712': 42.0357,
    '990_713': 41.9184,
    '990_714': 41.822,
    '990_715': 41.7694,
    '990_716': 41.7505,
    '990_717': 41.7517,
    '990_718': 41.7663,
    '990_719': 41.8108,
    '990_720': 41.8809,
    '990_721': 41.9507,
    '990_722': 42.0034,
    '990_723': 42.0298,
    '990_724': 42.0524,
    '990_725': 42.0888,
    '990_726': 42.1627,
    '990_727': 42.2737,
    '990_728': 42.381,
    '990_729': 42.4878,
    '990_730': 42.5931,
    '990_731': 42.6976,
    '990_732': 42.8092,
    '990_733': 42.9085,
    '990_734': 43.01,
    '990_735': 43.0893,
    '990_736': 43.1373,
    '990_737': 43.1514,
    '990_738': 43.1463,
    '990_739': 43.1221,
    '990_740': 43.0982,
    '990_741': 43.0785,
    '990_742': 43.0711,
    '990_743': 43.0726,
    '990_744': 43.0767,
    '990_745': 43.0764,
    '990_746': 43.0687,
    '990_747': 43.0445,
    '990_748': 42.9908,
    '990_749': 42.9292,
    '990_750': 42.8704,
    '990_751': 42.8205,
    '990_752': 42.7882,
    '990_753': 42.7649,
    '990_754': 42.7321,
    '990_755': 42.6757,
    '990_756': 42.5941,
    '990_757': 42.4938,
    '990_758': 42.3907,
    '990_759': 42.3014,
    '990_760': 42.2383,
    '990_761': 42.2245,
    '990_762': 42.2581,
    '990_763': 42.3205,
    '990_764': 42.4015,
    '990_765': 42.4927,
    '990_766': 42.5757,
    '990_767': 42.6287,
    '990_768': 42.6436,
    '990_769': 42.6334,
    '990_770': 42.6171,
    '990_771': 42.6116,
    '990_772': 42.6222,
    '990_773': 42.6497,
    '990_774': 42.6767,
    '990_775': 42.6829,
    '990_776': 42.6671,
    '990_777': 42.6477,
    '990_778': 42.6269,
    '990_779': 42.5898,
    '990_780': 42.5486,
    '990_781': 42.5178,
    '990_782': 42.492,
    '990_783': 42.4611,
    '990_784': 42.4245,
    '990_785': 42.3992,
    '990_786': 42.3769,
    '990_787': 42.3445,
    '990_788': 42.3041,
    '990_789': 42.2672,
    '990_790': 42.2334,
    '990_791': 42.2014,
    '990_792': 42.1787,
    '990_793': 42.1593,
    '990_794': 42.141,
    '990_795': 42.1279,
    '990_796': 42.1162,
    '990_797': 42.1061,
    '990_798': 42.0985,
    '990_799': 42.0971,
    '990_800': 42.103,
    '990_801': 42.1127,
    '990_802': 42.1271,
    '990_803': 42.1428,
    '990_804': 42.1575,
    '990_805': 42.1577,
    '990_806': 42.1361,
    '990_807': 42.0959,
    '990_808': 42.0432,
    '990_809': 41.9766,
    '990_810': 41.8914,
    '990_811': 41.7922,
    '990_812': 41.6783,
    '990_813': 41.5424,
    '990_814': 41.3899,
    '990_815': 41.2342,
    '990_816': 41.0786,
    '990_817': 40.9192,
    '990_818': 40.7539,
    '990_819': 40.5892,
    '990_820': 40.4321,
    '990_821': 40.2816,
    '990_822': 40.1334,
    '990_823': 39.9791,
    '990_824': 39.8,
    '990_825': 39.5627,
    '990_826': 39.2863,
    '990_827': 38.9883,
    '990_828': 38.6772,
    '990_829': 38.3598,
    '990_830': 38.0434,
    '990_831': 37.7345,
    '990_832': 37.4281,
    '990_833': 37.1202,
    '990_834': 36.8129,
    '990_835': 36.5066,
    '990_836': 36.2075,
    '990_837': 35.914,
    '990_838': 35.6222,
    '990_839': 35.3305,
    '990_840': 35.0411,
    '990_841': 34.7731,
    '990_842': 34.4987,
    '990_843': 34.2192,
    '990_844': 33.9419,
    '990_845': 33.6652,
    '990_846': 33.3899,
    '990_847': 33.111,
    '990_848': 32.8328,
    '991_612': 34.3028,
    '991_613': 34.3095,
    '991_614': 34.3191,
    '991_615': 34.3258,
    '991_616': 34.3302,
    '991_617': 34.3342,
    '991_618': 34.3409,
    '991_619': 34.3631,
    '991_620': 34.3986,
    '991_621': 34.4578,
    '991_622': 34.5253,
    '991_623': 34.5982,
    '991_624': 34.6666,
    '991_625': 34.7196,
    '991_626': 34.7621,
    '991_627': 34.8132,
    '991_628': 34.8904,
    '991_629': 34.9623,
    '991_630': 35.0388,
    '991_631': 35.1198,
    '991_632': 35.199,
    '991_633': 35.2662,
    '991_634': 35.3334,
    '991_635': 35.4033,
    '991_636': 35.4809,
    '991_637': 35.557,
    '991_638': 35.6311,
    '991_639': 35.702,
    '991_640': 35.7698,
    '991_641': 35.8329,
    '991_642': 35.8962,
    '991_643': 35.9606,
    '991_644': 36.0276,
    '991_645': 36.0985,
    '991_646': 36.1704,
    '991_647': 36.2458,
    '991_648': 36.3224,
    '991_649': 36.3996,
    '991_650': 36.4768,
    '991_651': 36.5511,
    '991_652': 36.6258,
    '991_653': 36.7025,
    '991_654': 36.7808,
    '991_655': 36.8616,
    '991_656': 36.9424,
    '991_657': 37.0217,
    '991_658': 37.1022,
    '991_659': 37.1875,
    '991_660': 37.2752,
    '991_661': 37.3689,
    '991_662': 37.4694,
    '991_663': 37.5771,
    '991_664': 37.6904,
    '991_665': 37.812,
    '991_666': 37.942,
    '991_667': 38.0637,
    '991_668': 38.1819,
    '991_669': 38.3133,
    '991_670': 38.4567,
    '991_671': 38.5965,
    '991_672': 38.713,
    '991_673': 38.7755,
    '991_674': 38.8246,
    '991_675': 38.8974,
    '991_676': 38.9843,
    '991_677': 39.0993,
    '991_678': 39.2443,
    '991_679': 39.3691,
    '991_680': 39.4552,
    '991_681': 39.5396,
    '991_682': 39.6051,
    '991_683': 39.645,
    '991_684': 39.6845,
    '991_685': 39.7352,
    '991_686': 39.8005,
    '991_687': 39.8709,
    '991_688': 39.9469,
    '991_689': 40.0205,
    '991_690': 40.1379,
    '991_691': 40.2916,
    '991_692': 40.4607,
    '991_693': 40.6382,
    '991_694': 40.8243,
    '991_695': 41.0076,
    '991_696': 41.1926,
    '991_697': 41.3526,
    '991_698': 41.5383,
    '991_699': 41.693,
    '991_700': 41.8689,
    '991_701': 42.023,
    '991_702': 42.1528,
    '991_703': 42.2365,
    '991_704': 42.32,
    '991_705': 42.3988,
    '991_706': 42.4239,
    '991_707': 42.4124,
    '991_708': 42.3789,
    '991_709': 42.3447,
    '991_710': 42.2639,
    '991_711': 42.1442,
    '991_712': 42.0202,
    '991_713': 41.9065,
    '991_714': 41.8176,
    '991_715': 41.766,
    '991_716': 41.7464,
    '991_717': 41.7416,
    '991_718': 41.7472,
    '991_719': 41.7776,
    '991_720': 41.8342,
    '991_721': 41.895,
    '991_722': 41.945,
    '991_723': 41.9686,
    '991_724': 41.9824,
    '991_725': 42.0273,
    '991_726': 42.1112,
    '991_727': 42.2229,
    '991_728': 42.335,
    '991_729': 42.4435,
    '991_730': 42.5596,
    '991_731': 42.6781,
    '991_732': 42.7944,
    '991_733': 42.9074,
    '991_734': 43.012,
    '991_735': 43.0976,
    '991_736': 43.1506,
    '991_737': 43.17,
    '991_738': 43.1664,
    '991_739': 43.1363,
    '991_740': 43.104,
    '991_741': 43.0779,
    '991_742': 43.0636,
    '991_743': 43.0568,
    '991_744': 43.0551,
    '991_745': 43.053,
    '991_746': 43.0394,
    '991_747': 43.0175,
    '991_748': 42.9754,
    '991_749': 42.926,
    '991_750': 42.8762,
    '991_751': 42.8272,
    '991_752': 42.7883,
    '991_753': 42.7552,
    '991_754': 42.7107,
    '991_755': 42.6499,
    '991_756': 42.575,
    '991_757': 42.4933,
    '991_758': 42.4143,
    '991_759': 42.344,
    '991_760': 42.2933,
    '991_761': 42.2876,
    '991_762': 42.3284,
    '991_763': 42.4021,
    '991_764': 42.5004,
    '991_765': 42.6093,
    '991_766': 42.7006,
    '991_767': 42.7555,
    '991_768': 42.776,
    '991_769': 42.7609,
    '991_770': 42.7346,
    '991_771': 42.7192,
    '991_772': 42.7214,
    '991_773': 42.7418,
    '991_774': 42.7668,
    '991_775': 42.7795,
    '991_776': 42.7787,
    '991_777': 42.7679,
    '991_778': 42.7471,
    '991_779': 42.708,
    '991_780': 42.6588,
    '991_781': 42.6135,
    '991_782': 42.5771,
    '991_783': 42.5353,
    '991_784': 42.4947,
    '991_785': 42.4658,
    '991_786': 42.4333,
    '991_787': 42.3949,
    '991_788': 42.3576,
    '991_789': 42.323,
    '991_790': 42.2884,
    '991_791': 42.2563,
    '991_792': 42.235,
    '991_793': 42.2143,
    '991_794': 42.1951,
    '991_795': 42.1829,
    '991_796': 42.1684,
    '991_797': 42.1539,
    '991_798': 42.1402,
    '991_799': 42.1329,
    '991_800': 42.1339,
    '991_801': 42.141,
    '991_802': 42.1544,
    '991_803': 42.171,
    '991_804': 42.1883,
    '991_805': 42.195,
    '991_806': 42.1779,
    '991_807': 42.1418,
    '991_808': 42.0943,
    '991_809': 42.0338,
    '991_810': 41.9565,
    '991_811': 41.8634,
    '991_812': 41.7556,
    '991_813': 41.6254,
    '991_814': 41.4715,
    '991_815': 41.321,
    '991_816': 41.1732,
    '991_817': 41.0173,
    '991_818': 40.8591,
    '991_819': 40.7039,
    '991_820': 40.557,
    '991_821': 40.4173,
    '991_822': 40.2784,
    '991_823': 40.1347,
    '991_824': 39.9653,
    '991_825': 39.7246,
    '991_826': 39.4423,
    '991_827': 39.1407,
    '991_828': 38.8269,
    '991_829': 38.5076,
    '991_830': 38.1895,
    '991_831': 37.8805,
    '991_832': 37.5744,
    '991_833': 37.2655,
    '991_834': 36.9578,
    '991_835': 36.6507,
    '991_836': 36.3533,
    '991_837': 36.0614,
    '991_838': 35.773,
    '991_839': 35.4869,
    '991_840': 35.2011,
    '991_841': 34.9315,
    '991_842': 34.6523,
    '991_843': 34.3737,
    '991_844': 34.1002,
    '991_845': 33.8275,
    '991_846': 33.5531,
    '991_847': 33.2748,
    '991_848': 32.9974,
    '992_612': 34.277,
    '992_613': 34.2863,
    '992_614': 34.2946,
    '992_615': 34.3007,
    '992_616': 34.3057,
    '992_617': 34.3122,
    '992_618': 34.32,
    '992_619': 34.3411,
    '992_620': 34.381,
    '992_621': 34.4536,
    '992_622': 34.5222,
    '992_623': 34.5879,
    '992_624': 34.645,
    '992_625': 34.6957,
    '992_626': 34.7392,
    '992_627': 34.7952,
    '992_628': 34.8663,
    '992_629': 34.9372,
    '992_630': 35.0104,
    '992_631': 35.0872,
    '992_632': 35.1625,
    '992_633': 35.2307,
    '992_634': 35.2985,
    '992_635': 35.3696,
    '992_636': 35.4442,
    '992_637': 35.5142,
    '992_638': 35.5832,
    '992_639': 35.65,
    '992_640': 35.7152,
    '992_641': 35.7794,
    '992_642': 35.844,
    '992_643': 35.9103,
    '992_644': 35.9798,
    '992_645': 36.0551,
    '992_646': 36.1296,
    '992_647': 36.2052,
    '992_648': 36.2826,
    '992_649': 36.3574,
    '992_650': 36.4309,
    '992_651': 36.5022,
    '992_652': 36.5743,
    '992_653': 36.6496,
    '992_654': 36.7273,
    '992_655': 36.8095,
    '992_656': 36.8912,
    '992_657': 36.9701,
    '992_658': 37.0495,
    '992_659': 37.133,
    '992_660': 37.2176,
    '992_661': 37.3058,
    '992_662': 37.4001,
    '992_663': 37.4997,
    '992_664': 37.6034,
    '992_665': 37.7131,
    '992_666': 37.8305,
    '992_667': 37.949,
    '992_668': 38.0697,
    '992_669': 38.2024,
    '992_670': 38.3446,
    '992_671': 38.4776,
    '992_672': 38.59,
    '992_673': 38.6525,
    '992_674': 38.691,
    '992_675': 38.7566,
    '992_676': 38.839,
    '992_677': 38.9434,
    '992_678': 39.0766,
    '992_679': 39.211,
    '992_680': 39.3117,
    '992_681': 39.4009,
    '992_682': 39.4656,
    '992_683': 39.4997,
    '992_684': 39.5244,
    '992_685': 39.5618,
    '992_686': 39.6227,
    '992_687': 39.6978,
    '992_688': 39.7878,
    '992_689': 39.8755,
    '992_690': 40.0063,
    '992_691': 40.1571,
    '992_692': 40.3199,
    '992_693': 40.4886,
    '992_694': 40.6711,
    '992_695': 40.857,
    '992_696': 41.0441,
    '992_697': 41.2296,
    '992_698': 41.4213,
    '992_699': 41.5708,
    '992_700': 41.7459,
    '992_701': 41.9217,
    '992_702': 42.0642,
    '992_703': 42.1562,
    '992_704': 42.2306,
    '992_705': 42.331,
    '992_706': 42.3709,
    '992_707': 42.3444,
    '992_708': 42.3038,
    '992_709': 42.277,
    '992_710': 42.2334,
    '992_711': 42.134,
    '992_712': 42.0153,
    '992_713': 41.9081,
    '992_714': 41.8198,
    '992_715': 41.7649,
    '992_716': 41.7453,
    '992_717': 41.7336,
    '992_718': 41.7288,
    '992_719': 41.7421,
    '992_720': 41.78,
    '992_721': 41.8306,
    '992_722': 41.8779,
    '992_723': 41.9043,
    '992_724': 41.9213,
    '992_725': 41.9669,
    '992_726': 42.0464,
    '992_727': 42.1529,
    '992_728': 42.2644,
    '992_729': 42.3751,
    '992_730': 42.505,
    '992_731': 42.6363,
    '992_732': 42.7638,
    '992_733': 42.8888,
    '992_734': 43.0031,
    '992_735': 43.0979,
    '992_736': 43.1468,
    '992_737': 43.1821,
    '992_738': 43.1923,
    '992_739': 43.1605,
    '992_740': 43.1218,
    '992_741': 43.0923,
    '992_742': 43.0707,
    '992_743': 43.054,
    '992_744': 43.0427,
    '992_745': 43.0358,
    '992_746': 43.0259,
    '992_747': 43.0058,
    '992_748': 42.9736,
    '992_749': 42.937,
    '992_750': 42.8909,
    '992_751': 42.8351,
    '992_752': 42.7853,
    '992_753': 42.7426,
    '992_754': 42.6846,
    '992_755': 42.6187,
    '992_756': 42.556,
    '992_757': 42.4963,
    '992_758': 42.4397,
    '992_759': 42.3886,
    '992_760': 42.357,
    '992_761': 42.3526,
    '992_762': 42.393,
    '992_763': 42.4735,
    '992_764': 42.5807,
    '992_765': 42.7011,
    '992_766': 42.8069,
    '992_767': 42.8642,
    '992_768': 42.8957,
    '992_769': 42.8876,
    '992_770': 42.8609,
    '992_771': 42.8403,
    '992_772': 42.8326,
    '992_773': 42.8362,
    '992_774': 42.849,
    '992_775': 42.8633,
    '992_776': 42.8769,
    '992_777': 42.8774,
    '992_778': 42.8614,
    '992_779': 42.8226,
    '992_780': 42.7678,
    '992_781': 42.7139,
    '992_782': 42.6637,
    '992_783': 42.6137,
    '992_784': 42.5682,
    '992_785': 42.53,
    '992_786': 42.4925,
    '992_787': 42.4522,
    '992_788': 42.4141,
    '992_789': 42.3773,
    '992_790': 42.3398,
    '992_791': 42.3082,
    '992_792': 42.2859,
    '992_793': 42.2647,
    '992_794': 42.2468,
    '992_795': 42.2345,
    '992_796': 42.2188,
    '992_797': 42.2006,
    '992_798': 42.1826,
    '992_799': 42.1704,
    '992_800': 42.1679,
    '992_801': 42.1719,
    '992_802': 42.1828,
    '992_803': 42.2023,
    '992_804': 42.2242,
    '992_805': 42.2334,
    '992_806': 42.2216,
    '992_807': 42.1915,
    '992_808': 42.1468,
    '992_809': 42.0902,
    '992_810': 42.0182,
    '992_811': 41.9296,
    '992_812': 41.8275,
    '992_813': 41.7032,
    '992_814': 41.5561,
    '992_815': 41.4132,
    '992_816': 41.2701,
    '992_817': 41.1225,
    '992_818': 40.9742,
    '992_819': 40.8297,
    '992_820': 40.6937,
    '992_821': 40.5599,
    '992_822': 40.4284,
    '992_823': 40.2982,
    '992_824': 40.1273,
    '992_825': 39.8799,
    '992_826': 39.5944,
    '992_827': 39.2906,
    '992_828': 38.975,
    '992_829': 38.6538,
    '992_830': 38.3332,
    '992_831': 38.021,
    '992_832': 37.713,
    '992_833': 37.4056,
    '992_834': 37.099,
    '992_835': 36.7944,
    '992_836': 36.4991,
    '992_837': 36.2078,
    '992_838': 35.9201,
    '992_839': 35.6318,
    '992_840': 35.3458,
    '992_841': 35.0772,
    '992_842': 34.8027,
    '992_843': 34.5283,
    '992_844': 34.2579,
    '992_845': 33.9858,
    '992_846': 33.7108,
    '992_847': 33.4342,
    '992_848': 33.1604,
    '993_612': 34.2525,
    '993_613': 34.2666,
    '993_614': 34.2748,
    '993_615': 34.2794,
    '993_616': 34.2832,
    '993_617': 34.2893,
    '993_618': 34.301,
    '993_619': 34.3258,
    '993_620': 34.3724,
    '993_621': 34.4468,
    '993_622': 34.5138,
    '993_623': 34.5684,
    '993_624': 34.6183,
    '993_625': 34.6686,
    '993_626': 34.7117,
    '993_627': 34.773,
    '993_628': 34.8415,
    '993_629': 34.9088,
    '993_630': 34.9785,
    '993_631': 35.053,
    '993_632': 35.1267,
    '993_633': 35.1942,
    '993_634': 35.263,
    '993_635': 35.3342,
    '993_636': 35.4033,
    '993_637': 35.4717,
    '993_638': 35.5393,
    '993_639': 35.6001,
    '993_640': 35.6642,
    '993_641': 35.7297,
    '993_642': 35.7959,
    '993_643': 35.8632,
    '993_644': 35.9333,
    '993_645': 36.0122,
    '993_646': 36.0894,
    '993_647': 36.1637,
    '993_648': 36.2401,
    '993_649': 36.3145,
    '993_650': 36.3861,
    '993_651': 36.4554,
    '993_652': 36.5265,
    '993_653': 36.6018,
    '993_654': 36.6799,
    '993_655': 36.7605,
    '993_656': 36.8417,
    '993_657': 36.9214,
    '993_658': 37.0012,
    '993_659': 37.0825,
    '993_660': 37.1657,
    '993_661': 37.2495,
    '993_662': 37.3371,
    '993_663': 37.4295,
    '993_664': 37.5256,
    '993_665': 37.6277,
    '993_666': 37.7374,
    '993_667': 37.8517,
    '993_668': 37.9715,
    '993_669': 38.0978,
    '993_670': 38.2272,
    '993_671': 38.3502,
    '993_672': 38.4618,
    '993_673': 38.5312,
    '993_674': 38.5692,
    '993_675': 38.627,
    '993_676': 38.7029,
    '993_677': 38.7975,
    '993_678': 38.9128,
    '993_679': 39.0429,
    '993_680': 39.1594,
    '993_681': 39.2574,
    '993_682': 39.3223,
    '993_683': 39.3515,
    '993_684': 39.3702,
    '993_685': 39.3977,
    '993_686': 39.4496,
    '993_687': 39.5243,
    '993_688': 39.6163,
    '993_689': 39.7205,
    '993_690': 39.8586,
    '993_691': 40.0092,
    '993_692': 40.1676,
    '993_693': 40.33,
    '993_694': 40.5083,
    '993_695': 40.6909,
    '993_696': 40.8827,
    '993_697': 41.0828,
    '993_698': 41.277,
    '993_699': 41.4606,
    '993_700': 41.6496,
    '993_701': 41.8205,
    '993_702': 41.9825,
    '993_703': 42.123,
    '993_704': 42.1809,
    '993_705': 42.2614,
    '993_706': 42.3219,
    '993_707': 42.3201,
    '993_708': 42.3095,
    '993_709': 42.3079,
    '993_710': 42.2461,
    '993_711': 42.1397,
    '993_712': 42.0267,
    '993_713': 41.9209,
    '993_714': 41.8305,
    '993_715': 41.7695,
    '993_716': 41.743,
    '993_717': 41.7259,
    '993_718': 41.7132,
    '993_719': 41.7145,
    '993_720': 41.734,
    '993_721': 41.7708,
    '993_722': 41.8099,
    '993_723': 41.8404,
    '993_724': 41.8639,
    '993_725': 41.9064,
    '993_726': 41.98,
    '993_727': 42.0772,
    '993_728': 42.1771,
    '993_729': 42.2905,
    '993_730': 42.433,
    '993_731': 42.5782,
    '993_732': 42.7178,
    '993_733': 42.8484,
    '993_734': 42.972,
    '993_735': 43.0732,
    '993_736': 43.1413,
    '993_737': 43.1882,
    '993_738': 43.2058,
    '993_739': 43.1834,
    '993_740': 43.1509,
    '993_741': 43.1201,
    '993_742': 43.0913,
    '993_743': 43.0686,
    '993_744': 43.0466,
    '993_745': 43.028,
    '993_746': 43.0187,
    '993_747': 43.0091,
    '993_748': 42.991,
    '993_749': 42.9577,
    '993_750': 42.9117,
    '993_751': 42.8526,
    '993_752': 42.7924,
    '993_753': 42.7307,
    '993_754': 42.6635,
    '993_755': 42.6009,
    '993_756': 42.5522,
    '993_757': 42.5163,
    '993_758': 42.4845,
    '993_759': 42.4542,
    '993_760': 42.4364,
    '993_761': 42.4292,
    '993_762': 42.4544,
    '993_763': 42.5333,
    '993_764': 42.6458,
    '993_765': 42.7754,
    '993_766': 42.887,
    '993_767': 42.9579,
    '993_768': 42.9898,
    '993_769': 42.9952,
    '993_770': 42.9764,
    '993_771': 42.9628,
    '993_772': 42.9544,
    '993_773': 42.9488,
    '993_774': 42.9491,
    '993_775': 42.9533,
    '993_776': 42.9663,
    '993_777': 42.9725,
    '993_778': 42.9635,
    '993_779': 42.9289,
    '993_780': 42.8719,
    '993_781': 42.8111,
    '993_782': 42.7516,
    '993_783': 42.6925,
    '993_784': 42.6437,
    '993_785': 42.595,
    '993_786': 42.5545,
    '993_787': 42.5125,
    '993_788': 42.4712,
    '993_789': 42.4308,
    '993_790': 42.3922,
    '993_791': 42.3609,
    '993_792': 42.3362,
    '993_793': 42.3146,
    '993_794': 42.2968,
    '993_795': 42.2841,
    '993_796': 42.2658,
    '993_797': 42.2454,
    '993_798': 42.2249,
    '993_799': 42.2092,
    '993_800': 42.2033,
    '993_801': 42.2049,
    '993_802': 42.214,
    '993_803': 42.2342,
    '993_804': 42.2595,
    '993_805': 42.2759,
    '993_806': 42.2726,
    '993_807': 42.2476,
    '993_808': 42.2042,
    '993_809': 42.1477,
    '993_810': 42.078,
    '993_811': 41.9943,
    '993_812': 41.8947,
    '993_813': 41.7757,
    '993_814': 41.6423,
    '993_815': 41.5089,
    '993_816': 41.3708,
    '993_817': 41.2328,
    '993_818': 41.097,
    '993_819': 40.9638,
    '993_820': 40.8388,
    '993_821': 40.7111,
    '993_822': 40.5848,
    '993_823': 40.4646,
    '993_824': 40.2835,
    '993_825': 40.0303,
    '993_826': 39.7434,
    '993_827': 39.4382,
    '993_828': 39.1216,
    '993_829': 38.799,
    '993_830': 38.4763,
    '993_831': 38.1594,
    '993_832': 37.8496,
    '993_833': 37.5434,
    '993_834': 37.2386,
    '993_835': 36.9351,
    '993_836': 36.6443,
    '993_837': 36.354,
    '993_838': 36.0699,
    '993_839': 35.7842,
    '993_840': 35.5064,
    '993_841': 35.2329,
    '993_842': 34.9562,
    '993_843': 34.6823,
    '993_844': 34.416,
    '993_845': 34.1441,
    '993_846': 33.8688,
    '993_847': 33.5954,
    '993_848': 33.3245,
    '994_612': 34.2297,
    '994_613': 34.25,
    '994_614': 34.2566,
    '994_615': 34.2593,
    '994_616': 34.2611,
    '994_617': 34.2657,
    '994_618': 34.2793,
    '994_619': 34.3089,
    '994_620': 34.3626,
    '994_621': 34.4353,
    '994_622': 34.4952,
    '994_623': 34.542,
    '994_624': 34.5898,
    '994_625': 34.6389,
    '994_626': 34.6906,
    '994_627': 34.7519,
    '994_628': 34.8164,
    '994_629': 34.8783,
    '994_630': 34.9461,
    '994_631': 35.0171,
    '994_632': 35.0867,
    '994_633': 35.1537,
    '994_634': 35.2252,
    '994_635': 35.2987,
    '994_636': 35.3628,
    '994_637': 35.4317,
    '994_638': 35.4931,
    '994_639': 35.5509,
    '994_640': 35.6177,
    '994_641': 35.6853,
    '994_642': 35.7539,
    '994_643': 35.823,
    '994_644': 35.8959,
    '994_645': 35.974,
    '994_646': 36.0502,
    '994_647': 36.1243,
    '994_648': 36.1985,
    '994_649': 36.2714,
    '994_650': 36.3405,
    '994_651': 36.4104,
    '994_652': 36.4839,
    '994_653': 36.5598,
    '994_654': 36.6368,
    '994_655': 36.7146,
    '994_656': 36.7961,
    '994_657': 36.8763,
    '994_658': 36.9568,
    '994_659': 37.038,
    '994_660': 37.1175,
    '994_661': 37.1982,
    '994_662': 37.2799,
    '994_663': 37.3662,
    '994_664': 37.4567,
    '994_665': 37.5531,
    '994_666': 37.6574,
    '994_667': 37.7688,
    '994_668': 37.8846,
    '994_669': 38.003,
    '994_670': 38.1193,
    '994_671': 38.2314,
    '994_672': 38.3362,
    '994_673': 38.4109,
    '994_674': 38.4571,
    '994_675': 38.5096,
    '994_676': 38.5777,
    '994_677': 38.6625,
    '994_678': 38.7647,
    '994_679': 38.8782,
    '994_680': 38.9987,
    '994_681': 39.1011,
    '994_682': 39.1648,
    '994_683': 39.1965,
    '994_684': 39.2152,
    '994_685': 39.2399,
    '994_686': 39.2854,
    '994_687': 39.3557,
    '994_688': 39.4433,
    '994_689': 39.5561,
    '994_690': 39.6938,
    '994_691': 39.8436,
    '994_692': 39.9993,
    '994_693': 40.1616,
    '994_694': 40.3373,
    '994_695': 40.5203,
    '994_696': 40.7173,
    '994_697': 40.9264,
    '994_698': 41.136,
    '994_699': 41.3409,
    '994_700': 41.5349,
    '994_701': 41.7172,
    '994_702': 41.8842,
    '994_703': 42.0292,
    '994_704': 42.0949,
    '994_705': 42.1633,
    '994_706': 42.2612,
    '994_707': 42.2791,
    '994_708': 42.2491,
    '994_709': 42.2787,
    '994_710': 42.2272,
    '994_711': 42.1348,
    '994_712': 42.0448,
    '994_713': 41.9399,
    '994_714': 41.8462,
    '994_715': 41.7764,
    '994_716': 41.7409,
    '994_717': 41.7198,
    '994_718': 41.7025,
    '994_719': 41.6966,
    '994_720': 41.701,
    '994_721': 41.721,
    '994_722': 41.749,
    '994_723': 41.7795,
    '994_724': 41.81,
    '994_725': 41.8506,
    '994_726': 41.9152,
    '994_727': 41.9963,
    '994_728': 42.0892,
    '994_729': 42.21,
    '994_730': 42.3499,
    '994_731': 42.4975,
    '994_732': 42.6428,
    '994_733': 42.7865,
    '994_734': 42.9235,
    '994_735': 43.0516,
    '994_736': 43.1451,
    '994_737': 43.1916,
    '994_738': 43.2115,
    '994_739': 43.2041,
    '994_740': 43.187,
    '994_741': 43.1585,
    '994_742': 43.1281,
    '994_743': 43.0928,
    '994_744': 43.0612,
    '994_745': 43.0383,
    '994_746': 43.033,
    '994_747': 43.0308,
    '994_748': 43.019,
    '994_749': 42.9853,
    '994_750': 42.9388,
    '994_751': 42.8787,
    '994_752': 42.8066,
    '994_753': 42.7274,
    '994_754': 42.658,
    '994_755': 42.606,
    '994_756': 42.5799,
    '994_757': 42.5649,
    '994_758': 42.5486,
    '994_759': 42.5293,
    '994_760': 42.5195,
    '994_761': 42.5082,
    '994_762': 42.5165,
    '994_763': 42.5847,
    '994_764': 42.696,
    '994_765': 42.8277,
    '994_766': 42.9495,
    '994_767': 43.028,
    '994_768': 43.0643,
    '994_769': 43.0829,
    '994_770': 43.0806,
    '994_771': 43.077,
    '994_772': 43.0756,
    '994_773': 43.0703,
    '994_774': 43.0639,
    '994_775': 43.0549,
    '994_776': 43.0561,
    '994_777': 43.0631,
    '994_778': 43.0568,
    '994_779': 43.022,
    '994_780': 42.9658,
    '994_781': 42.9012,
    '994_782': 42.8347,
    '994_783': 42.773,
    '994_784': 42.7155,
    '994_785': 42.6635,
    '994_786': 42.6161,
    '994_787': 42.5716,
    '994_788': 42.5278,
    '994_789': 42.485,
    '994_790': 42.4443,
    '994_791': 42.4127,
    '994_792': 42.386,
    '994_793': 42.3623,
    '994_794': 42.343,
    '994_795': 42.3265,
    '994_796': 42.3072,
    '994_797': 42.2844,
    '994_798': 42.2614,
    '994_799': 42.2427,
    '994_800': 42.2361,
    '994_801': 42.2363,
    '994_802': 42.2447,
    '994_803': 42.2678,
    '994_804': 42.2956,
    '994_805': 42.3181,
    '994_806': 42.3244,
    '994_807': 42.3045,
    '994_808': 42.2631,
    '994_809': 42.2101,
    '994_810': 42.1447,
    '994_811': 42.0652,
    '994_812': 41.9688,
    '994_813': 41.8564,
    '994_814': 41.7329,
    '994_815': 41.6054,
    '994_816': 41.4758,
    '994_817': 41.3474,
    '994_818': 41.2232,
    '994_819': 41.1027,
    '994_820': 40.9861,
    '994_821': 40.865,
    '994_822': 40.7415,
    '994_823': 40.6207,
    '994_824': 40.4354,
    '994_825': 40.1832,
    '994_826': 39.8918,
    '994_827': 39.5834,
    '994_828': 39.2652,
    '994_829': 38.9408,
    '994_830': 38.6151,
    '994_831': 38.2939,
    '994_832': 37.9838,
    '994_833': 37.6784,
    '994_834': 37.3745,
    '994_835': 37.0771,
    '994_836': 36.7865,
    '994_837': 36.499,
    '994_838': 36.2151,
    '994_839': 35.9358,
    '994_840': 35.6616,
    '994_841': 35.3884,
    '994_842': 35.1117,
    '994_843': 34.8377,
    '994_844': 34.5697,
    '994_845': 34.2996,
    '994_846': 34.0262,
    '994_847': 33.7531,
    '994_848': 33.4852,
    '995_612': 34.205,
    '995_613': 34.2238,
    '995_614': 34.2335,
    '995_615': 34.2384,
    '995_616': 34.2396,
    '995_617': 34.2421,
    '995_618': 34.257,
    '995_619': 34.2897,
    '995_620': 34.3525,
    '995_621': 34.4186,
    '995_622': 34.4677,
    '995_623': 34.5059,
    '995_624': 34.5556,
    '995_625': 34.6051,
    '995_626': 34.6626,
    '995_627': 34.7222,
    '995_628': 34.7785,
    '995_629': 34.8401,
    '995_630': 34.9108,
    '995_631': 34.9814,
    '995_632': 35.0485,
    '995_633': 35.1133,
    '995_634': 35.1886,
    '995_635': 35.2617,
    '995_636': 35.3243,
    '995_637': 35.3898,
    '995_638': 35.4524,
    '995_639': 35.5152,
    '995_640': 35.5821,
    '995_641': 35.6488,
    '995_642': 35.7181,
    '995_643': 35.7864,
    '995_644': 35.8583,
    '995_645': 35.9322,
    '995_646': 36.01,
    '995_647': 36.0839,
    '995_648': 36.1556,
    '995_649': 36.2259,
    '995_650': 36.2957,
    '995_651': 36.3691,
    '995_652': 36.4445,
    '995_653': 36.5222,
    '995_654': 36.6004,
    '995_655': 36.6783,
    '995_656': 36.7566,
    '995_657': 36.8347,
    '995_658': 36.9144,
    '995_659': 36.9965,
    '995_660': 37.0734,
    '995_661': 37.1509,
    '995_662': 37.2284,
    '995_663': 37.3096,
    '995_664': 37.3958,
    '995_665': 37.4874,
    '995_666': 37.5867,
    '995_667': 37.6934,
    '995_668': 37.8072,
    '995_669': 37.919,
    '995_670': 38.0274,
    '995_671': 38.1331,
    '995_672': 38.2264,
    '995_673': 38.2982,
    '995_674': 38.3534,
    '995_675': 38.4044,
    '995_676': 38.4654,
    '995_677': 38.542,
    '995_678': 38.6322,
    '995_679': 38.7324,
    '995_680': 38.8407,
    '995_681': 38.9397,
    '995_682': 39.0013,
    '995_683': 39.0358,
    '995_684': 39.0577,
    '995_685': 39.0858,
    '995_686': 39.1283,
    '995_687': 39.1955,
    '995_688': 39.282,
    '995_689': 39.3934,
    '995_690': 39.5247,
    '995_691': 39.6697,
    '995_692': 39.825,
    '995_693': 39.9892,
    '995_694': 40.1716,
    '995_695': 40.363,
    '995_696': 40.5649,
    '995_697': 40.783,
    '995_698': 41.0033,
    '995_699': 41.2181,
    '995_700': 41.4366,
    '995_701': 41.6315,
    '995_702': 41.7982,
    '995_703': 41.943,
    '995_704': 42.0375,
    '995_705': 42.0849,
    '995_706': 42.1949,
    '995_707': 42.2388,
    '995_708': 42.2242,
    '995_709': 42.2,
    '995_710': 42.1709,
    '995_711': 42.1393,
    '995_712': 42.058,
    '995_713': 41.9534,
    '995_714': 41.8606,
    '995_715': 41.7809,
    '995_716': 41.739,
    '995_717': 41.7116,
    '995_718': 41.6938,
    '995_719': 41.6847,
    '995_720': 41.6838,
    '995_721': 41.6906,
    '995_722': 41.7079,
    '995_723': 41.7357,
    '995_724': 41.7661,
    '995_725': 41.8037,
    '995_726': 41.8552,
    '995_727': 41.9227,
    '995_728': 42.0114,
    '995_729': 42.1262,
    '995_730': 42.258,
    '995_731': 42.403,
    '995_732': 42.5532,
    '995_733': 42.7012,
    '995_734': 42.8475,
    '995_735': 42.9869,
    '995_736': 43.1137,
    '995_737': 43.1774,
    '995_738': 43.2218,
    '995_739': 43.235,
    '995_740': 43.2216,
    '995_741': 43.2022,
    '995_742': 43.175,
    '995_743': 43.1336,
    '995_744': 43.0913,
    '995_745': 43.0676,
    '995_746': 43.0621,
    '995_747': 43.0644,
    '995_748': 43.0553,
    '995_749': 43.0193,
    '995_750': 42.9692,
    '995_751': 42.9043,
    '995_752': 42.8224,
    '995_753': 42.7387,
    '995_754': 42.6733,
    '995_755': 42.6363,
    '995_756': 42.6253,
    '995_757': 42.6171,
    '995_758': 42.6047,
    '995_759': 42.5927,
    '995_760': 42.5828,
    '995_761': 42.5694,
    '995_762': 42.5676,
    '995_763': 42.6223,
    '995_764': 42.725,
    '995_765': 42.854,
    '995_766': 42.9784,
    '995_767': 43.0725,
    '995_768': 43.1202,
    '995_769': 43.1481,
    '995_770': 43.1634,
    '995_771': 43.1768,
    '995_772': 43.1887,
    '995_773': 43.1938,
    '995_774': 43.1837,
    '995_775': 43.1649,
    '995_776': 43.1485,
    '995_777': 43.147,
    '995_778': 43.1405,
    '995_779': 43.1082,
    '995_780': 43.0547,
    '995_781': 42.9914,
    '995_782': 42.9228,
    '995_783': 42.8549,
    '995_784': 42.7923,
    '995_785': 42.7295,
    '995_786': 42.6735,
    '995_787': 42.6275,
    '995_788': 42.5813,
    '995_789': 42.5358,
    '995_790': 42.4952,
    '995_791': 42.4631,
    '995_792': 42.4341,
    '995_793': 42.4079,
    '995_794': 42.3858,
    '995_795': 42.365,
    '995_796': 42.3421,
    '995_797': 42.3179,
    '995_798': 42.2945,
    '995_799': 42.2753,
    '995_800': 42.2665,
    '995_801': 42.2657,
    '995_802': 42.2755,
    '995_803': 42.2996,
    '995_804': 42.3307,
    '995_805': 42.3594,
    '995_806': 42.3741,
    '995_807': 42.3599,
    '995_808': 42.328,
    '995_809': 42.2806,
    '995_810': 42.2191,
    '995_811': 42.1456,
    '995_812': 42.0523,
    '995_813': 41.9432,
    '995_814': 41.827,
    '995_815': 41.7067,
    '995_816': 41.5847,
    '995_817': 41.4659,
    '995_818': 41.3517,
    '995_819': 41.2394,
    '995_820': 41.1307,
    '995_821': 41.0181,
    '995_822': 40.8958,
    '995_823': 40.7723,
    '995_824': 40.583,
    '995_825': 40.3336,
    '995_826': 40.0366,
    '995_827': 39.7253,
    '995_828': 39.4053,
    '995_829': 39.0789,
    '995_830': 38.7514,
    '995_831': 38.4291,
    '995_832': 38.1183,
    '995_833': 37.8119,
    '995_834': 37.5079,
    '995_835': 37.2159,
    '995_836': 36.9258,
    '995_837': 36.6407,
    '995_838': 36.3568,
    '995_839': 36.0824,
    '995_840': 35.8116,
    '995_841': 35.5411,
    '995_842': 35.2656,
    '995_843': 34.9932,
    '995_844': 34.7229,
    '995_845': 34.4542,
    '995_846': 34.1827,
    '995_847': 33.9116,
    '995_848': 33.6459,
    '996_612': 34.1802,
    '996_613': 34.1983,
    '996_614': 34.2116,
    '996_615': 34.221,
    '996_616': 34.2244,
    '996_617': 34.2247,
    '996_618': 34.2439,
    '996_619': 34.2838,
    '996_620': 34.3449,
    '996_621': 34.3958,
    '996_622': 34.4363,
    '996_623': 34.4676,
    '996_624': 34.5146,
    '996_625': 34.5716,
    '996_626': 34.6289,
    '996_627': 34.683,
    '996_628': 34.7371,
    '996_629': 34.8081,
    '996_630': 34.8781,
    '996_631': 34.949,
    '996_632': 35.0177,
    '996_633': 35.0856,
    '996_634': 35.1583,
    '996_635': 35.2259,
    '996_636': 35.2906,
    '996_637': 35.3542,
    '996_638': 35.4172,
    '996_639': 35.4853,
    '996_640': 35.5564,
    '996_641': 35.6249,
    '996_642': 35.6909,
    '996_643': 35.7577,
    '996_644': 35.8278,
    '996_645': 35.9005,
    '996_646': 35.9747,
    '996_647': 36.0467,
    '996_648': 36.117,
    '996_649': 36.1864,
    '996_650': 36.2603,
    '996_651': 36.3342,
    '996_652': 36.4097,
    '996_653': 36.4912,
    '996_654': 36.5725,
    '996_655': 36.6513,
    '996_656': 36.7266,
    '996_657': 36.799,
    '996_658': 36.8746,
    '996_659': 36.9533,
    '996_660': 37.033,
    '996_661': 37.1089,
    '996_662': 37.1843,
    '996_663': 37.2623,
    '996_664': 37.3452,
    '996_665': 37.4332,
    '996_666': 37.5277,
    '996_667': 37.6293,
    '996_668': 37.7399,
    '996_669': 37.8494,
    '996_670': 37.9559,
    '996_671': 38.0556,
    '996_672': 38.137,
    '996_673': 38.2034,
    '996_674': 38.2587,
    '996_675': 38.309,
    '996_676': 38.3653,
    '996_677': 38.4361,
    '996_678': 38.5161,
    '996_679': 38.6026,
    '996_680': 38.694,
    '996_681': 38.7832,
    '996_682': 38.8406,
    '996_683': 38.8803,
    '996_684': 38.907,
    '996_685': 38.9417,
    '996_686': 38.9877,
    '996_687': 39.054,
    '996_688': 39.1396,
    '996_689': 39.2434,
    '996_690': 39.3649,
    '996_691': 39.5036,
    '996_692': 39.6567,
    '996_693': 39.8219,
    '996_694': 40.0099,
    '996_695': 40.2103,
    '996_696': 40.4256,
    '996_697': 40.6531,
    '996_698': 40.877,
    '996_699': 41.1015,
    '996_700': 41.3192,
    '996_701': 41.5141,
    '996_702': 41.6749,
    '996_703': 41.8415,
    '996_704': 41.972,
    '996_705': 42.0238,
    '996_706': 42.1152,
    '996_707': 42.1575,
    '996_708': 42.1848,
    '996_709': 42.2007,
    '996_710': 42.1713,
    '996_711': 42.1555,
    '996_712': 42.0721,
    '996_713': 41.9666,
    '996_714': 41.8716,
    '996_715': 41.7876,
    '996_716': 41.7411,
    '996_717': 41.7109,
    '996_718': 41.69,
    '996_719': 41.6793,
    '996_720': 41.6791,
    '996_721': 41.6822,
    '996_722': 41.6903,
    '996_723': 41.7113,
    '996_724': 41.7358,
    '996_725': 41.7668,
    '996_726': 41.8056,
    '996_727': 41.8625,
    '996_728': 41.9402,
    '996_729': 42.0401,
    '996_730': 42.1617,
    '996_731': 42.3049,
    '996_732': 42.4543,
    '996_733': 42.6017,
    '996_734': 42.753,
    '996_735': 42.9106,
    '996_736': 43.0507,
    '996_737': 43.1395,
    '996_738': 43.2001,
    '996_739': 43.2474,
    '996_740': 43.2506,
    '996_741': 43.2377,
    '996_742': 43.2212,
    '996_743': 43.1802,
    '996_744': 43.1334,
    '996_745': 43.11,
    '996_746': 43.1047,
    '996_747': 43.1066,
    '996_748': 43.0972,
    '996_749': 43.0585,
    '996_750': 43.0013,
    '996_751': 42.9299,
    '996_752': 42.8469,
    '996_753': 42.772,
    '996_754': 42.7122,
    '996_755': 42.6757,
    '996_756': 42.6637,
    '996_757': 42.6538,
    '996_758': 42.6433,
    '996_759': 42.6348,
    '996_760': 42.6268,
    '996_761': 42.6084,
    '996_762': 42.6032,
    '996_763': 42.6539,
    '996_764': 42.7487,
    '996_765': 42.8679,
    '996_766': 42.9904,
    '996_767': 43.0909,
    '996_768': 43.1544,
    '996_769': 43.2007,
    '996_770': 43.237,
    '996_771': 43.2695,
    '996_772': 43.2952,
    '996_773': 43.3117,
    '996_774': 43.2998,
    '996_775': 43.2752,
    '996_776': 43.2457,
    '996_777': 43.2306,
    '996_778': 43.2198,
    '996_779': 43.1936,
    '996_780': 43.1483,
    '996_781': 43.0866,
    '996_782': 43.0114,
    '996_783': 42.9336,
    '996_784': 42.8595,
    '996_785': 42.7905,
    '996_786': 42.7308,
    '996_787': 42.6783,
    '996_788': 42.6318,
    '996_789': 42.5837,
    '996_790': 42.5453,
    '996_791': 42.5144,
    '996_792': 42.4836,
    '996_793': 42.4528,
    '996_794': 42.4285,
    '996_795': 42.4035,
    '996_796': 42.3755,
    '996_797': 42.3492,
    '996_798': 42.3251,
    '996_799': 42.3042,
    '996_800': 42.2934,
    '996_801': 42.2917,
    '996_802': 42.3037,
    '996_803': 42.3289,
    '996_804': 42.3642,
    '996_805': 42.398,
    '996_806': 42.4184,
    '996_807': 42.4145,
    '996_808': 42.3963,
    '996_809': 42.3577,
    '996_810': 42.3017,
    '996_811': 42.2324,
    '996_812': 42.1426,
    '996_813': 42.0382,
    '996_814': 41.9273,
    '996_815': 41.8109,
    '996_816': 41.6933,
    '996_817': 41.5829,
    '996_818': 41.4782,
    '996_819': 41.3717,
    '996_820': 41.2678,
    '996_821': 41.1592,
    '996_822': 41.0356,
    '996_823': 40.9102,
    '996_824': 40.7234,
    '996_825': 40.4759,
    '996_826': 40.1828,
    '996_827': 39.8679,
    '996_828': 39.544,
    '996_829': 39.2155,
    '996_830': 38.8862,
    '996_831': 38.5631,
    '996_832': 38.2562,
    '996_833': 37.9514,
    '996_834': 37.6493,
    '996_835': 37.3544,
    '996_836': 37.0635,
    '996_837': 36.7797,
    '996_838': 36.5013,
    '996_839': 36.229,
    '996_840': 35.9602,
    '996_841': 35.6897,
    '996_842': 35.4162,
    '996_843': 35.1445,
    '996_844': 34.8724,
    '996_845': 34.6052,
    '996_846': 34.3357,
    '996_847': 34.0662,
    '996_848': 33.8022,
    '997_612': 34.1555,
    '997_613': 34.1733,
    '997_614': 34.1888,
    '997_615': 34.2039,
    '997_616': 34.2181,
    '997_617': 34.2232,
    '997_618': 34.239,
    '997_619': 34.2833,
    '997_620': 34.3279,
    '997_621': 34.3672,
    '997_622': 34.4019,
    '997_623': 34.43,
    '997_624': 34.4684,
    '997_625': 34.5225,
    '997_626': 34.5846,
    '997_627': 34.6398,
    '997_628': 34.6985,
    '997_629': 34.7656,
    '997_630': 34.8344,
    '997_631': 34.9071,
    '997_632': 34.9795,
    '997_633': 35.0547,
    '997_634': 35.1297,
    '997_635': 35.1942,
    '997_636': 35.26,
    '997_637': 35.3223,
    '997_638': 35.3857,
    '997_639': 35.4552,
    '997_640': 35.5279,
    '997_641': 35.5904,
    '997_642': 35.6503,
    '997_643': 35.7178,
    '997_644': 35.7928,
    '997_645': 35.8691,
    '997_646': 35.9422,
    '997_647': 36.0128,
    '997_648': 36.0819,
    '997_649': 36.1519,
    '997_650': 36.2253,
    '997_651': 36.3002,
    '997_652': 36.3815,
    '997_653': 36.468,
    '997_654': 36.5543,
    '997_655': 36.6332,
    '997_656': 36.7059,
    '997_657': 36.7734,
    '997_658': 36.8485,
    '997_659': 36.9289,
    '997_660': 37.0065,
    '997_661': 37.0784,
    '997_662': 37.1477,
    '997_663': 37.2233,
    '997_664': 37.3046,
    '997_665': 37.3905,
    '997_666': 37.4809,
    '997_667': 37.5774,
    '997_668': 37.6834,
    '997_669': 37.7952,
    '997_670': 37.8982,
    '997_671': 37.9918,
    '997_672': 38.0684,
    '997_673': 38.1231,
    '997_674': 38.1739,
    '997_675': 38.2229,
    '997_676': 38.276,
    '997_677': 38.3419,
    '997_678': 38.4126,
    '997_679': 38.486,
    '997_680': 38.5611,
    '997_681': 38.6363,
    '997_682': 38.6963,
    '997_683': 38.74,
    '997_684': 38.7749,
    '997_685': 38.8122,
    '997_686': 38.8627,
    '997_687': 38.9296,
    '997_688': 39.0118,
    '997_689': 39.1093,
    '997_690': 39.2216,
    '997_691': 39.3515,
    '997_692': 39.4969,
    '997_693': 39.6605,
    '997_694': 39.8489,
    '997_695': 40.0585,
    '997_696': 40.288,
    '997_697': 40.5201,
    '997_698': 40.7496,
    '997_699': 40.9755,
    '997_700': 41.2,
    '997_701': 41.4039,
    '997_702': 41.6029,
    '997_703': 41.7631,
    '997_704': 41.8908,
    '997_705': 41.9201,
    '997_706': 42.0357,
    '997_707': 42.0729,
    '997_708': 42.1251,
    '997_709': 42.1267,
    '997_710': 42.1161,
    '997_711': 42.1379,
    '997_712': 42.0749,
    '997_713': 41.9675,
    '997_714': 41.8735,
    '997_715': 41.7923,
    '997_716': 41.747,
    '997_717': 41.7175,
    '997_718': 41.697,
    '997_719': 41.6872,
    '997_720': 41.6913,
    '997_721': 41.6963,
    '997_722': 41.7005,
    '997_723': 41.7126,
    '997_724': 41.7232,
    '997_725': 41.7425,
    '997_726': 41.7707,
    '997_727': 41.8148,
    '997_728': 41.8787,
    '997_729': 41.9633,
    '997_730': 42.0726,
    '997_731': 42.2082,
    '997_732': 42.3525,
    '997_733': 42.4999,
    '997_734': 42.6586,
    '997_735': 42.8304,
    '997_736': 42.9827,
    '997_737': 43.0946,
    '997_738': 43.1784,
    '997_739': 43.238,
    '997_740': 43.2734,
    '997_741': 43.2574,
    '997_742': 43.2541,
    '997_743': 43.2264,
    '997_744': 43.1821,
    '997_745': 43.1551,
    '997_746': 43.1539,
    '997_747': 43.1533,
    '997_748': 43.141,
    '997_749': 43.1017,
    '997_750': 43.0397,
    '997_751': 42.965,
    '997_752': 42.8914,
    '997_753': 42.8263,
    '997_754': 42.7718,
    '997_755': 42.7316,
    '997_756': 42.7069,
    '997_757': 42.6865,
    '997_758': 42.6708,
    '997_759': 42.6635,
    '997_760': 42.656,
    '997_761': 42.6339,
    '997_762': 42.6314,
    '997_763': 42.6847,
    '997_764': 42.7733,
    '997_765': 42.8801,
    '997_766': 42.9938,
    '997_767': 43.094,
    '997_768': 43.1738,
    '997_769': 43.2413,
    '997_770': 43.2993,
    '997_771': 43.35,
    '997_772': 43.3908,
    '997_773': 43.4122,
    '997_774': 43.4049,
    '997_775': 43.3831,
    '997_776': 43.3451,
    '997_777': 43.3166,
    '997_778': 43.297,
    '997_779': 43.2735,
    '997_780': 43.2329,
    '997_781': 43.1683,
    '997_782': 43.0894,
    '997_783': 43.0014,
    '997_784': 42.9194,
    '997_785': 42.8443,
    '997_786': 42.7845,
    '997_787': 42.7295,
    '997_788': 42.6759,
    '997_789': 42.6295,
    '997_790': 42.5947,
    '997_791': 42.5697,
    '997_792': 42.5331,
    '997_793': 42.4963,
    '997_794': 42.4705,
    '997_795': 42.4411,
    '997_796': 42.4086,
    '997_797': 42.3779,
    '997_798': 42.3502,
    '997_799': 42.3279,
    '997_800': 42.3166,
    '997_801': 42.3158,
    '997_802': 42.3324,
    '997_803': 42.3616,
    '997_804': 42.3971,
    '997_805': 42.4313,
    '997_806': 42.4543,
    '997_807': 42.4641,
    '997_808': 42.4596,
    '997_809': 42.4302,
    '997_810': 42.382,
    '997_811': 42.3159,
    '997_812': 42.2312,
    '997_813': 42.1339,
    '997_814': 42.0302,
    '997_815': 41.9173,
    '997_816': 41.8037,
    '997_817': 41.7001,
    '997_818': 41.5989,
    '997_819': 41.493,
    '997_820': 41.3918,
    '997_821': 41.2833,
    '997_822': 41.1601,
    '997_823': 41.0327,
    '997_824': 40.8514,
    '997_825': 40.6052,
    '997_826': 40.3198,
    '997_827': 40.0049,
    '997_828': 39.6795,
    '997_829': 39.35,
    '997_830': 39.0205,
    '997_831': 38.6988,
    '997_832': 38.3967,
    '997_833': 38.0918,
    '997_834': 37.7892,
    '997_835': 37.4923,
    '997_836': 37.199,
    '997_837': 36.9169,
    '997_838': 36.6429,
    '997_839': 36.3718,
    '997_840': 36.1051,
    '997_841': 35.8345,
    '997_842': 35.5634,
    '997_843': 35.2958,
    '997_844': 35.0239,
    '997_845': 34.7586,
    '997_846': 34.4907,
    '997_847': 34.2264,
    '997_848': 33.9675,
    '998_612': 34.1292,
    '998_613': 34.1459,
    '998_614': 34.1612,
    '998_615': 34.1787,
    '998_616': 34.2027,
    '998_617': 34.219,
    '998_618': 34.2344,
    '998_619': 34.2742,
    '998_620': 34.3055,
    '998_621': 34.3352,
    '998_622': 34.3668,
    '998_623': 34.3933,
    '998_624': 34.4332,
    '998_625': 34.4864,
    '998_626': 34.5443,
    '998_627': 34.5991,
    '998_628': 34.6609,
    '998_629': 34.7249,
    '998_630': 34.791,
    '998_631': 34.8677,
    '998_632': 34.9446,
    '998_633': 35.0205,
    '998_634': 35.0929,
    '998_635': 35.1627,
    '998_636': 35.2287,
    '998_637': 35.2917,
    '998_638': 35.356,
    '998_639': 35.421,
    '998_640': 35.4901,
    '998_641': 35.5536,
    '998_642': 35.6146,
    '998_643': 35.6791,
    '998_644': 35.759,
    '998_645': 35.8397,
    '998_646': 35.9114,
    '998_647': 35.9803,
    '998_648': 36.0491,
    '998_649': 36.1193,
    '998_650': 36.1929,
    '998_651': 36.2684,
    '998_652': 36.3566,
    '998_653': 36.4484,
    '998_654': 36.5393,
    '998_655': 36.6215,
    '998_656': 36.6954,
    '998_657': 36.7572,
    '998_658': 36.8301,
    '998_659': 36.9152,
    '998_660': 36.9894,
    '998_661': 37.0567,
    '998_662': 37.1158,
    '998_663': 37.1922,
    '998_664': 37.2733,
    '998_665': 37.3577,
    '998_666': 37.445,
    '998_667': 37.5366,
    '998_668': 37.6371,
    '998_669': 37.7449,
    '998_670': 37.8444,
    '998_671': 37.9339,
    '998_672': 38.0119,
    '998_673': 38.0618,
    '998_674': 38.103,
    '998_675': 38.148,
    '998_676': 38.1985,
    '998_677': 38.2566,
    '998_678': 38.3178,
    '998_679': 38.38,
    '998_680': 38.4435,
    '998_681': 38.5089,
    '998_682': 38.5679,
    '998_683': 38.6159,
    '998_684': 38.6552,
    '998_685': 38.696,
    '998_686': 38.7467,
    '998_687': 38.8126,
    '998_688': 38.8914,
    '998_689': 38.9829,
    '998_690': 39.0892,
    '998_691': 39.2108,
    '998_692': 39.3487,
    '998_693': 39.5072,
    '998_694': 39.6912,
    '998_695': 39.9052,
    '998_696': 40.1425,
    '998_697': 40.3773,
    '998_698': 40.6058,
    '998_699': 40.8376,
    '998_700': 41.0604,
    '998_701': 41.2773,
    '998_702': 41.4802,
    '998_703': 41.6575,
    '998_704': 41.7704,
    '998_705': 41.8242,
    '998_706': 41.9216,
    '998_707': 41.9814,
    '998_708': 42.0481,
    '998_709': 42.094,
    '998_710': 42.1025,
    '998_711': 42.1206,
    '998_712': 42.059,
    '998_713': 41.9641,
    '998_714': 41.8702,
    '998_715': 41.7921,
    '998_716': 41.7547,
    '998_717': 41.7322,
    '998_718': 41.7162,
    '998_719': 41.7087,
    '998_720': 41.7183,
    '998_721': 41.7291,
    '998_722': 41.7347,
    '998_723': 41.7379,
    '998_724': 41.7372,
    '998_725': 41.7393,
    '998_726': 41.7545,
    '998_727': 41.778,
    '998_728': 41.8266,
    '998_729': 41.8972,
    '998_730': 41.992,
    '998_731': 42.1118,
    '998_732': 42.2494,
    '998_733': 42.4001,
    '998_734': 42.5708,
    '998_735': 42.7479,
    '998_736': 42.9048,
    '998_737': 43.0507,
    '998_738': 43.1363,
    '998_739': 43.2188,
    '998_740': 43.2809,
    '998_741': 43.2904,
    '998_742': 43.2874,
    '998_743': 43.2734,
    '998_744': 43.2323,
    '998_745': 43.2044,
    '998_746': 43.203,
    '998_747': 43.2001,
    '998_748': 43.1838,
    '998_749': 43.1491,
    '998_750': 43.0865,
    '998_751': 43.0201,
    '998_752': 42.9554,
    '998_753': 42.8984,
    '998_754': 42.844,
    '998_755': 42.8012,
    '998_756': 42.7674,
    '998_757': 42.7331,
    '998_758': 42.7084,
    '998_759': 42.6947,
    '998_760': 42.6818,
    '998_761': 42.662,
    '998_762': 42.6705,
    '998_763': 42.724,
    '998_764': 42.8049,
    '998_765': 42.897,
    '998_766': 42.9999,
    '998_767': 43.1002,
    '998_768': 43.1892,
    '998_769': 43.2743,
    '998_770': 43.3517,
    '998_771': 43.4213,
    '998_772': 43.4737,
    '998_773': 43.4995,
    '998_774': 43.5128,
    '998_775': 43.4848,
    '998_776': 43.4378,
    '998_777': 43.3993,
    '998_778': 43.373,
    '998_779': 43.3491,
    '998_780': 43.3048,
    '998_781': 43.2413,
    '998_782': 43.1524,
    '998_783': 43.0578,
    '998_784': 42.9695,
    '998_785': 42.8941,
    '998_786': 42.8354,
    '998_787': 42.772,
    '998_788': 42.7125,
    '998_789': 42.6652,
    '998_790': 42.6348,
    '998_791': 42.6147,
    '998_792': 42.5808,
    '998_793': 42.5428,
    '998_794': 42.5128,
    '998_795': 42.4794,
    '998_796': 42.441,
    '998_797': 42.4053,
    '998_798': 42.3731,
    '998_799': 42.3493,
    '998_800': 42.339,
    '998_801': 42.3418,
    '998_802': 42.3602,
    '998_803': 42.3914,
    '998_804': 42.4268,
    '998_805': 42.464,
    '998_806': 42.4933,
    '998_807': 42.5115,
    '998_808': 42.5177,
    '998_809': 42.4972,
    '998_810': 42.4568,
    '998_811': 42.3958,
    '998_812': 42.3189,
    '998_813': 42.2289,
    '998_814': 42.133,
    '998_815': 42.0265,
    '998_816': 41.9129,
    '998_817': 41.8127,
    '998_818': 41.7126,
    '998_819': 41.6067,
    '998_820': 41.5058,
    '998_821': 41.3987,
    '998_822': 41.2769,
    '998_823': 41.1404,
    '998_824': 40.9609,
    '998_825': 40.726,
    '998_826': 40.447,
    '998_827': 40.134,
    '998_828': 39.8096,
    '998_829': 39.4813,
    '998_830': 39.1527,
    '998_831': 38.831,
    '998_832': 38.5279,
    '998_833': 38.2241,
    '998_834': 37.9227,
    '998_835': 37.6261,
    '998_836': 37.3353,
    '998_837': 37.0539,
    '998_838': 36.7794,
    '998_839': 36.508,
    '998_840': 36.24,
    '998_841': 35.9747,
    '998_842': 35.7075,
    '998_843': 35.4421,
    '998_844': 35.1718,
    '998_845': 34.9071,
    '998_846': 34.6409,
    '998_847': 34.3781,
    '998_848': 34.1191,
    '999_612': 34.0989,
    '999_613': 34.1158,
    '999_614': 34.129,
    '999_615': 34.1442,
    '999_616': 34.1761,
    '999_617': 34.2062,
    '999_618': 34.2276,
    '999_619': 34.2557,
    '999_620': 34.2791,
    '999_621': 34.3031,
    '999_622': 34.3387,
    '999_623': 34.3566,
    '999_624': 34.4013,
    '999_625': 34.4587,
    '999_626': 34.517,
    '999_627': 34.5751,
    '999_628': 34.6323,
    '999_629': 34.6939,
    '999_630': 34.7606,
    '999_631': 34.8356,
    '999_632': 34.9142,
    '999_633': 34.9909,
    '999_634': 35.0639,
    '999_635': 35.135,
    '999_636': 35.2005,
    '999_637': 35.2626,
    '999_638': 35.3267,
    '999_639': 35.3936,
    '999_640': 35.4582,
    '999_641': 35.5218,
    '999_642': 35.5853,
    '999_643': 35.6534,
    '999_644': 35.7314,
    '999_645': 35.8079,
    '999_646': 35.8802,
    '999_647': 35.9497,
    '999_648': 36.0196,
    '999_649': 36.0908,
    '999_650': 36.1638,
    '999_651': 36.2473,
    '999_652': 36.3391,
    '999_653': 36.4332,
    '999_654': 36.5256,
    '999_655': 36.6092,
    '999_656': 36.6887,
    '999_657': 36.7443,
    '999_658': 36.8097,
    '999_659': 36.9032,
    '999_660': 36.9699,
    '999_661': 37.0322,
    '999_662': 37.0967,
    '999_663': 37.1724,
    '999_664': 37.2517,
    '999_665': 37.3334,
    '999_666': 37.4185,
    '999_667': 37.5066,
    '999_668': 37.602,
    '999_669': 37.704,
    '999_670': 37.8033,
    '999_671': 37.8905,
    '999_672': 37.9678,
    '999_673': 38.0203,
    '999_674': 38.0513,
    '999_675': 38.0884,
    '999_676': 38.1319,
    '999_677': 38.1813,
    '999_678': 38.2324,
    '999_679': 38.2849,
    '999_680': 38.3395,
    '999_681': 38.3972,
    '999_682': 38.4545,
    '999_683': 38.5036,
    '999_684': 38.5459,
    '999_685': 38.5878,
    '999_686': 38.6367,
    '999_687': 38.7,
    '999_688': 38.7757,
    '999_689': 38.8626,
    '999_690': 38.9628,
    '999_691': 39.0776,
    '999_692': 39.2093,
    '999_693': 39.3617,
    '999_694': 39.5406,
    '999_695': 39.7522,
    '999_696': 39.9889,
    '999_697': 40.2267,
    '999_698': 40.4591,
    '999_699': 40.6943,
    '999_700': 40.9234,
    '999_701': 41.1457,
    '999_702': 41.3575,
    '999_703': 41.5447,
    '999_704': 41.672,
    '999_705': 41.7341,
    '999_706': 41.8171,
    '999_707': 41.8951,
    '999_708': 41.9691,
    '999_709': 42.0423,
    '999_710': 42.0571,
    '999_711': 42.0826,
    '999_712': 42.03,
    '999_713': 41.9566,
    '999_714': 41.8593,
    '999_715': 41.7889,
    '999_716': 41.7596,
    '999_717': 41.7488,
    '999_718': 41.7391,
    '999_719': 41.7384,
    '999_720': 41.7458,
    '999_721': 41.7571,
    '999_722': 41.7641,
    '999_723': 41.76,
    '999_724': 41.7558,
    '999_725': 41.748,
    '999_726': 41.7469,
    '999_727': 41.7522,
    '999_728': 41.782,
    '999_729': 41.84,
    '999_730': 41.9195,
    '999_731': 42.024,
    '999_732': 42.1521,
    '999_733': 42.3063,
    '999_734': 42.4811,
    '999_735': 42.6605,
    '999_736': 42.832,
    '999_737': 42.9832,
    '999_738': 43.1097,
    '999_739': 43.2123,
    '999_740': 43.2781,
    '999_741': 43.301,
    '999_742': 43.3147,
    '999_743': 43.3129,
    '999_744': 43.2833,
    '999_745': 43.2602,
    '999_746': 43.256,
    '999_747': 43.2528,
    '999_748': 43.235,
    '999_749': 43.1988,
    '999_750': 43.1415,
    '999_751': 43.0813,
    '999_752': 43.0271,
    '999_753': 42.9759,
    '999_754': 42.9191,
    '999_755': 42.8699,
    '999_756': 42.8256,
    '999_757': 42.7845,
    '999_758': 42.7518,
    '999_759': 42.7324,
    '999_760': 42.7181,
    '999_761': 42.7024,
    '999_762': 42.7198,
    '999_763': 42.773,
    '999_764': 42.8479,
    '999_765': 42.929,
    '999_766': 43.0259,
    '999_767': 43.1227,
    '999_768': 43.2138,
    '999_769': 43.3051,
    '999_770': 43.3905,
    '999_771': 43.4724,
    '999_772': 43.5344,
    '999_773': 43.5731,
    '999_774': 43.5979,
    '999_775': 43.5753,
    '999_776': 43.5322,
    '999_777': 43.4819,
    '999_778': 43.4449,
    '999_779': 43.416,
    '999_780': 43.3717,
    '999_781': 43.3058,
    '999_782': 43.2095,
    '999_783': 43.1142,
    '999_784': 43.0257,
    '999_785': 42.944,
    '999_786': 42.8828,
    '999_787': 42.8141,
    '999_788': 42.7486,
    '999_789': 42.6956,
    '999_790': 42.6641,
    '999_791': 42.643,
    '999_792': 42.6195,
    '999_793': 42.591,
    '999_794': 42.5559,
    '999_795': 42.5175,
    '999_796': 42.4726,
    '999_797': 42.4318,
    '999_798': 42.3952,
    '999_799': 42.371,
    '999_800': 42.3608,
    '999_801': 42.3655,
    '999_802': 42.3852,
    '999_803': 42.4171,
    '999_804': 42.4536,
    '999_805': 42.4965,
    '999_806': 42.5334,
    '999_807': 42.5548,
    '999_808': 42.5693,
    '999_809': 42.5603,
    '999_810': 42.5277,
    '999_811': 42.4737,
    '999_812': 42.4038,
    '999_813': 42.3208,
    '999_814': 42.2294,
    '999_815': 42.1277,
    '999_816': 42.0165,
    '999_817': 41.9163,
    '999_818': 41.8182,
    '999_819': 41.7128,
    '999_820': 41.6138,
    '999_821': 41.5071,
    '999_822': 41.3876,
    '999_823': 41.2412,
    '999_824': 41.0577,
    '999_825': 40.8306,
    '999_826': 40.5617,
    '999_827': 40.2535,
    '999_828': 39.934,
    '999_829': 39.6098,
    '999_830': 39.2854,
    '999_831': 38.966,
    '999_832': 38.6611,
    '999_833': 38.3519,
    '999_834': 38.0489,
    '999_835': 37.7519,
    '999_836': 37.462,
    '999_837': 37.1811,
    '999_838': 36.9065,
    '999_839': 36.6344,
    '999_840': 36.3676,
    '999_841': 36.1099,
    '999_842': 35.8443,
    '999_843': 35.583,
    '999_844': 35.3161,
    '999_845': 35.0523,
    '999_846': 34.7864,
    '999_847': 34.5299,
    '999_848': 34.2702,
    '1000_612': 34.0709,
    '1000_613': 34.0876,
    '1000_614': 34.1009,
    '1000_615': 34.1187,
    '1000_616': 34.1516,
    '1000_617': 34.1869,
    '1000_618': 34.2138,
    '1000_619': 34.238,
    '1000_620': 34.2585,
    '1000_621': 34.2828,
    '1000_622': 34.3126,
    '1000_623': 34.3269,
    '1000_624': 34.3767,
    '1000_625': 34.437,
    '1000_626': 34.491,
    '1000_627': 34.5431,
    '1000_628': 34.6003,
    '1000_629': 34.6635,
    '1000_630': 34.7319,
    '1000_631': 34.8066,
    '1000_632': 34.8867,
    '1000_633': 34.9622,
    '1000_634': 35.0363,
    '1000_635': 35.1068,
    '1000_636': 35.1764,
    '1000_637': 35.2424,
    '1000_638': 35.3062,
    '1000_639': 35.3702,
    '1000_640': 35.4311,
    '1000_641': 35.4958,
    '1000_642': 35.5624,
    '1000_643': 35.6347,
    '1000_644': 35.7121,
    '1000_645': 35.7829,
    '1000_646': 35.855,
    '1000_647': 35.9252,
    '1000_648': 35.9968,
    '1000_649': 36.0708,
    '1000_650': 36.144,
    '1000_651': 36.233,
    '1000_652': 36.3251,
    '1000_653': 36.4154,
    '1000_654': 36.4985,
    '1000_655': 36.5786,
    '1000_656': 36.655,
    '1000_657': 36.7209,
    '1000_658': 36.79,
    '1000_659': 36.8677,
    '1000_660': 36.9395,
    '1000_661': 37.0071,
    '1000_662': 37.0819,
    '1000_663': 37.1606,
    '1000_664': 37.2405,
    '1000_665': 37.3204,
    '1000_666': 37.4033,
    '1000_667': 37.4891,
    '1000_668': 37.5818,
    '1000_669': 37.6793,
    '1000_670': 37.7755,
    '1000_671': 37.863,
    '1000_672': 37.9409,
    '1000_673': 37.996,
    '1000_674': 38.0217,
    '1000_675': 38.0471,
    '1000_676': 38.0805,
    '1000_677': 38.1193,
    '1000_678': 38.1608,
    '1000_679': 38.2045,
    '1000_680': 38.2509,
    '1000_681': 38.3021,
    '1000_682': 38.3551,
    '1000_683': 38.4012,
    '1000_684': 38.4446,
    '1000_685': 38.4872,
    '1000_686': 38.5352,
    '1000_687': 38.5949,
    '1000_688': 38.6665,
    '1000_689': 38.7493,
    '1000_690': 38.8436,
    '1000_691': 38.9527,
    '1000_692': 39.0787,
    '1000_693': 39.2257,
    '1000_694': 39.3981,
    '1000_695': 39.6017,
    '1000_696': 39.8332,
    '1000_697': 40.0758,
    '1000_698': 40.3185,
    '1000_699': 40.5627,
    '1000_700': 40.8032,
    '1000_701': 41.0346,
    '1000_702': 41.2421,
    '1000_703': 41.4149,
    '1000_704': 41.5554,
    '1000_705': 41.6491,
    '1000_706': 41.7178,
    '1000_707': 41.8016,
    '1000_708': 41.8969,
    '1000_709': 41.9841,
    '1000_710': 42.0279,
    '1000_711': 42.0664,
    '1000_712': 42.0343,
    '1000_713': 41.9414,
    '1000_714': 41.8464,
    '1000_715': 41.7854,
    '1000_716': 41.7663,
    '1000_717': 41.7645,
    '1000_718': 41.7611,
    '1000_719': 41.7638,
    '1000_720': 41.7759,
    '1000_721': 41.7889,
    '1000_722': 41.7949,
    '1000_723': 41.7863,
    '1000_724': 41.7788,
    '1000_725': 41.7666,
    '1000_726': 41.7545,
    '1000_727': 41.746,
    '1000_728': 41.7582,
    '1000_729': 41.7967,
    '1000_730': 41.8581,
    '1000_731': 41.9479,
    '1000_732': 42.0656,
    '1000_733': 42.2138,
    '1000_734': 42.3862,
    '1000_735': 42.5676,
    '1000_736': 42.7467,
    '1000_737': 42.9157,
    '1000_738': 43.0561,
    '1000_739': 43.1677,
    '1000_740': 43.2608,
    '1000_741': 43.296,
    '1000_742': 43.329,
    '1000_743': 43.3474,
    '1000_744': 43.3357,
    '1000_745': 43.3187,
    '1000_746': 43.3117,
    '1000_747': 43.3084,
    '1000_748': 43.2909,
    '1000_749': 43.2542,
    '1000_750': 43.1979,
    '1000_751': 43.1458,
    '1000_752': 43.1018,
    '1000_753': 43.0523,
    '1000_754': 42.9928,
    '1000_755': 42.9295,
    '1000_756': 42.8772,
    '1000_757': 42.8323,
    '1000_758': 42.7969,
    '1000_759': 42.7757,
    '1000_760': 42.7653,
    '1000_761': 42.7666,
    '1000_762': 42.7923,
    '1000_763': 42.8411,
    '1000_764': 42.9086,
    '1000_765': 42.9872,
    '1000_766': 43.0852,
    '1000_767': 43.1703,
    '1000_768': 43.2509,
    '1000_769': 43.3363,
    '1000_770': 43.4306,
    '1000_771': 43.5172,
    '1000_772': 43.5984,
    '1000_773': 43.6406,
    '1000_774': 43.6598,
    '1000_775': 43.6463,
    '1000_776': 43.6145,
    '1000_777': 43.5698,
    '1000_778': 43.5268,
    '1000_779': 43.4915,
    '1000_780': 43.4412,
    '1000_781': 43.3718,
    '1000_782': 43.2791,
    '1000_783': 43.179,
    '1000_784': 43.0858,
    '1000_785': 43.0016,
    '1000_786': 42.939,
    '1000_787': 42.863,
    '1000_788': 42.7905,
    '1000_789': 42.7353,
    '1000_790': 42.6974,
    '1000_791': 42.6693,
    '1000_792': 42.6483,
    '1000_793': 42.6305,
    '1000_794': 42.6003,
    '1000_795': 42.5558,
    '1000_796': 42.5063,
    '1000_797': 42.4601,
    '1000_798': 42.4193,
    '1000_799': 42.3944,
    '1000_800': 42.385,
    '1000_801': 42.3913,
    '1000_802': 42.4121,
    '1000_803': 42.4423,
    '1000_804': 42.4814,
    '1000_805': 42.5292,
    '1000_806': 42.5712,
    '1000_807': 42.599,
    '1000_808': 42.6187,
    '1000_809': 42.6195,
    '1000_810': 42.5958,
    '1000_811': 42.5489,
    '1000_812': 42.4832,
    '1000_813': 42.4058,
    '1000_814': 42.3199,
    '1000_815': 42.2185,
    '1000_816': 42.1101,
    '1000_817': 42.011,
    '1000_818': 41.916,
    '1000_819': 41.8144,
    '1000_820': 41.7207,
    '1000_821': 41.6104,
    '1000_822': 41.4903,
    '1000_823': 41.3381,
    '1000_824': 41.148,
    '1000_825': 40.9255,
    '1000_826': 40.6671,
    '1000_827': 40.3703,
    '1000_828': 40.0536,
    '1000_829': 39.7364,
    '1000_830': 39.4192,
    '1000_831': 39.1035,
    '1000_832': 38.7917,
    '1000_833': 38.478,
    '1000_834': 38.1673,
    '1000_835': 37.8677,
    '1000_836': 37.5772,
    '1000_837': 37.2947,
    '1000_838': 37.0183,
    '1000_839': 36.7476,
    '1000_840': 36.4887,
    '1000_841': 36.2368,
    '1000_842': 35.9697,
    '1000_843': 35.7015,
    '1000_844': 35.4411,
    '1000_845': 35.1849,
    '1000_846': 34.9202,
    '1000_847': 34.6617,
    '1000_848': 34.4056,
    '1001_612': 34.0341,
    '1001_613': 34.0532,
    '1001_614': 34.0675,
    '1001_615': 34.0859,
    '1001_616': 34.1213,
    '1001_617': 34.1603,
    '1001_618': 34.1947,
    '1001_619': 34.2186,
    '1001_620': 34.2344,
    '1001_621': 34.2555,
    '1001_622': 34.281,
    '1001_623': 34.3124,
    '1001_624': 34.3577,
    '1001_625': 34.4096,
    '1001_626': 34.4589,
    '1001_627': 34.5016,
    '1001_628': 34.5562,
    '1001_629': 34.6237,
    '1001_630': 34.6966,
    '1001_631': 34.7765,
    '1001_632': 34.8559,
    '1001_633': 34.9335,
    '1001_634': 35.0077,
    '1001_635': 35.0781,
    '1001_636': 35.1468,
    '1001_637': 35.2125,
    '1001_638': 35.2757,
    '1001_639': 35.3382,
    '1001_640': 35.4019,
    '1001_641': 35.4672,
    '1001_642': 35.5387,
    '1001_643': 35.6153,
    '1001_644': 35.6922,
    '1001_645': 35.7609,
    '1001_646': 35.8312,
    '1001_647': 35.9013,
    '1001_648': 35.9748,
    '1001_649': 36.0511,
    '1001_650': 36.1332,
    '1001_651': 36.2233,
    '1001_652': 36.3087,
    '1001_653': 36.399,
    '1001_654': 36.4713,
    '1001_655': 36.5483,
    '1001_656': 36.6243,
    '1001_657': 36.6978,
    '1001_658': 36.7727,
    '1001_659': 36.8447,
    '1001_660': 36.9173,
    '1001_661': 36.9883,
    '1001_662': 37.0746,
    '1001_663': 37.1569,
    '1001_664': 37.2355,
    '1001_665': 37.3144,
    '1001_666': 37.3956,
    '1001_667': 37.4809,
    '1001_668': 37.5709,
    '1001_669': 37.6666,
    '1001_670': 37.7624,
    '1001_671': 37.8488,
    '1001_672': 37.9265,
    '1001_673': 37.9831,
    '1001_674': 38.0085,
    '1001_675': 38.0235,
    '1001_676': 38.0416,
    '1001_677': 38.0676,
    '1001_678': 38.0991,
    '1001_679': 38.1337,
    '1001_680': 38.1723,
    '1001_681': 38.2166,
    '1001_682': 38.2617,
    '1001_683': 38.3033,
    '1001_684': 38.3452,
    '1001_685': 38.3879,
    '1001_686': 38.437,
    '1001_687': 38.4946,
    '1001_688': 38.5612,
    '1001_689': 38.6385,
    '1001_690': 38.7276,
    '1001_691': 38.8308,
    '1001_692': 38.9508,
    '1001_693': 39.0915,
    '1001_694': 39.2569,
    '1001_695': 39.4528,
    '1001_696': 39.6798,
    '1001_697': 39.928,
    '1001_698': 40.1815,
    '1001_699': 40.4379,
    '1001_700': 40.6854,
    '1001_701': 40.9252,
    '1001_702': 41.1501,
    '1001_703': 41.3157,
    '1001_704': 41.4328,
    '1001_705': 41.5496,
    '1001_706': 41.6117,
    '1001_707': 41.7071,
    '1001_708': 41.8202,
    '1001_709': 41.9407,
    '1001_710': 41.9861,
    '1001_711': 42.0104,
    '1001_712': 41.9985,
    '1001_713': 41.9192,
    '1001_714': 41.8256,
    '1001_715': 41.7718,
    '1001_716': 41.7634,
    '1001_717': 41.7726,
    '1001_718': 41.7823,
    '1001_719': 41.7891,
    '1001_720': 41.7992,
    '1001_721': 41.811,
    '1001_722': 41.8169,
    '1001_723': 41.8051,
    '1001_724': 41.7932,
    '1001_725': 41.784,
    '1001_726': 41.7682,
    '1001_727': 41.749,
    '1001_728': 41.7485,
    '1001_729': 41.7647,
    '1001_730': 41.8045,
    '1001_731': 41.8775,
    '1001_732': 41.9842,
    '1001_733': 42.1257,
    '1001_734': 42.2931,
    '1001_735': 42.4728,
    '1001_736': 42.6592,
    '1001_737': 42.8347,
    '1001_738': 42.9883,
    '1001_739': 43.1171,
    '1001_740': 43.2266,
    '1001_741': 43.2992,
    '1001_742': 43.3622,
    '1001_743': 43.3846,
    '1001_744': 43.378,
    '1001_745': 43.3727,
    '1001_746': 43.3632,
    '1001_747': 43.3625,
    '1001_748': 43.3396,
    '1001_749': 43.3061,
    '1001_750': 43.2584,
    '1001_751': 43.2035,
    '1001_752': 43.1578,
    '1001_753': 43.1136,
    '1001_754': 43.0503,
    '1001_755': 42.981,
    '1001_756': 42.9163,
    '1001_757': 42.8707,
    '1001_758': 42.8407,
    '1001_759': 42.8255,
    '1001_760': 42.8279,
    '1001_761': 42.8497,
    '1001_762': 42.8773,
    '1001_763': 42.9213,
    '1001_764': 42.9831,
    '1001_765': 43.0639,
    '1001_766': 43.1582,
    '1001_767': 43.2293,
    '1001_768': 43.2946,
    '1001_769': 43.3662,
    '1001_770': 43.4569,
    '1001_771': 43.5484,
    '1001_772': 43.6361,
    '1001_773': 43.6894,
    '1001_774': 43.7161,
    '1001_775': 43.7149,
    '1001_776': 43.69,
    '1001_777': 43.6566,
    '1001_778': 43.6093,
    '1001_779': 43.5617,
    '1001_780': 43.5154,
    '1001_781': 43.4366,
    '1001_782': 43.3485,
    '1001_783': 43.2463,
    '1001_784': 43.1454,
    '1001_785': 43.0607,
    '1001_786': 42.991,
    '1001_787': 42.9094,
    '1001_788': 42.8333,
    '1001_789': 42.7735,
    '1001_790': 42.7312,
    '1001_791': 42.6999,
    '1001_792': 42.6784,
    '1001_793': 42.6614,
    '1001_794': 42.6364,
    '1001_795': 42.5906,
    '1001_796': 42.5374,
    '1001_797': 42.4857,
    '1001_798': 42.442,
    '1001_799': 42.4145,
    '1001_800': 42.4057,
    '1001_801': 42.4129,
    '1001_802': 42.4323,
    '1001_803': 42.4634,
    '1001_804': 42.5087,
    '1001_805': 42.5592,
    '1001_806': 42.6038,
    '1001_807': 42.6389,
    '1001_808': 42.6653,
    '1001_809': 42.6731,
    '1001_810': 42.6573,
    '1001_811': 42.6154,
    '1001_812': 42.5567,
    '1001_813': 42.4847,
    '1001_814': 42.399,
    '1001_815': 42.2985,
    '1001_816': 42.1963,
    '1001_817': 42.1014,
    '1001_818': 42.009,
    '1001_819': 41.9119,
    '1001_820': 41.8225,
    '1001_821': 41.71,
    '1001_822': 41.5888,
    '1001_823': 41.4317,
    '1001_824': 41.236,
    '1001_825': 41.0154,
    '1001_826': 40.7636,
    '1001_827': 40.4714,
    '1001_828': 40.1667,
    '1001_829': 39.858,
    '1001_830': 39.549,
    '1001_831': 39.238,
    '1001_832': 38.9252,
    '1001_833': 38.6127,
    '1001_834': 38.3083,
    '1001_835': 38.0063,
    '1001_836': 37.7123,
    '1001_837': 37.4318,
    '1001_838': 37.1541,
    '1001_839': 36.8803,
    '1001_840': 36.6186,
    '1001_841': 36.3692,
    '1001_842': 36.1084,
    '1001_843': 35.8493,
    '1001_844': 35.5868,
    '1001_845': 35.3296,
    '1001_846': 35.0663,
    '1001_847': 34.8078,
    '1001_848': 34.5523,
    '1002_612': 33.9828,
    '1002_613': 34.0095,
    '1002_614': 34.0322,
    '1002_615': 34.0579,
    '1002_616': 34.0918,
    '1002_617': 34.1303,
    '1002_618': 34.1685,
    '1002_619': 34.1996,
    '1002_620': 34.2144,
    '1002_621': 34.2355,
    '1002_622': 34.2681,
    '1002_623': 34.3036,
    '1002_624': 34.3416,
    '1002_625': 34.383,
    '1002_626': 34.4239,
    '1002_627': 34.4656,
    '1002_628': 34.5223,
    '1002_629': 34.5916,
    '1002_630': 34.6681,
    '1002_631': 34.7509,
    '1002_632': 34.8277,
    '1002_633': 34.9044,
    '1002_634': 34.9778,
    '1002_635': 35.0485,
    '1002_636': 35.1163,
    '1002_637': 35.1808,
    '1002_638': 35.2423,
    '1002_639': 35.3034,
    '1002_640': 35.37,
    '1002_641': 35.4398,
    '1002_642': 35.5143,
    '1002_643': 35.5932,
    '1002_644': 35.6687,
    '1002_645': 35.7399,
    '1002_646': 35.8109,
    '1002_647': 35.8812,
    '1002_648': 35.9513,
    '1002_649': 36.0253,
    '1002_650': 36.1198,
    '1002_651': 36.2193,
    '1002_652': 36.3128,
    '1002_653': 36.394,
    '1002_654': 36.4684,
    '1002_655': 36.543,
    '1002_656': 36.6185,
    '1002_657': 36.6957,
    '1002_658': 36.7716,
    '1002_659': 36.837,
    '1002_660': 36.9126,
    '1002_661': 37.0014,
    '1002_662': 37.0895,
    '1002_663': 37.1653,
    '1002_664': 37.2388,
    '1002_665': 37.3158,
    '1002_666': 37.3964,
    '1002_667': 37.4818,
    '1002_668': 37.5716,
    '1002_669': 37.6655,
    '1002_670': 37.7596,
    '1002_671': 37.8475,
    '1002_672': 37.9231,
    '1002_673': 37.9783,
    '1002_674': 38.0104,
    '1002_675': 38.0249,
    '1002_676': 38.0222,
    '1002_677': 38.0294,
    '1002_678': 38.0489,
    '1002_679': 38.0745,
    '1002_680': 38.1048,
    '1002_681': 38.1403,
    '1002_682': 38.1772,
    '1002_683': 38.213,
    '1002_684': 38.2513,
    '1002_685': 38.2938,
    '1002_686': 38.3432,
    '1002_687': 38.4005,
    '1002_688': 38.4627,
    '1002_689': 38.5341,
    '1002_690': 38.6173,
    '1002_691': 38.7146,
    '1002_692': 38.8284,
    '1002_693': 38.9633,
    '1002_694': 39.1228,
    '1002_695': 39.3114,
    '1002_696': 39.5334,
    '1002_697': 39.7813,
    '1002_698': 40.0425,
    '1002_699': 40.304,
    '1002_700': 40.5628,
    '1002_701': 40.8085,
    '1002_702': 41.0263,
    '1002_703': 41.1905,
    '1002_704': 41.3183,
    '1002_705': 41.4374,
    '1002_706': 41.5174,
    '1002_707': 41.6297,
    '1002_708': 41.7736,
    '1002_709': 41.9021,
    '1002_710': 41.9452,
    '1002_711': 42.014,
    '1002_712': 41.9706,
    '1002_713': 41.8933,
    '1002_714': 41.8085,
    '1002_715': 41.7579,
    '1002_716': 41.7558,
    '1002_717': 41.7748,
    '1002_718': 41.8004,
    '1002_719': 41.8135,
    '1002_720': 41.8288,
    '1002_721': 41.8409,
    '1002_722': 41.8405,
    '1002_723': 41.8341,
    '1002_724': 41.8221,
    '1002_725': 41.8104,
    '1002_726': 41.7879,
    '1002_727': 41.7608,
    '1002_728': 41.7406,
    '1002_729': 41.7428,
    '1002_730': 41.7624,
    '1002_731': 41.8145,
    '1002_732': 41.9051,
    '1002_733': 42.035,
    '1002_734': 42.1995,
    '1002_735': 42.3795,
    '1002_736': 42.5704,
    '1002_737': 42.7502,
    '1002_738': 42.9212,
    '1002_739': 43.0669,
    '1002_740': 43.1855,
    '1002_741': 43.2781,
    '1002_742': 43.3386,
    '1002_743': 43.3752,
    '1002_744': 43.3891,
    '1002_745': 43.401,
    '1002_746': 43.4024,
    '1002_747': 43.396,
    '1002_748': 43.3936,
    '1002_749': 43.3515,
    '1002_750': 43.3096,
    '1002_751': 43.2582,
    '1002_752': 43.2114,
    '1002_753': 43.1573,
    '1002_754': 43.0965,
    '1002_755': 43.0247,
    '1002_756': 42.9613,
    '1002_757': 42.9219,
    '1002_758': 42.9009,
    '1002_759': 42.8866,
    '1002_760': 42.9008,
    '1002_761': 42.932,
    '1002_762': 42.9609,
    '1002_763': 43.0045,
    '1002_764': 43.0635,
    '1002_765': 43.1424,
    '1002_766': 43.2261,
    '1002_767': 43.2868,
    '1002_768': 43.3406,
    '1002_769': 43.3977,
    '1002_770': 43.4812,
    '1002_771': 43.5812,
    '1002_772': 43.6652,
    '1002_773': 43.7244,
    '1002_774': 43.7729,
    '1002_775': 43.7884,
    '1002_776': 43.7679,
    '1002_777': 43.7322,
    '1002_778': 43.6908,
    '1002_779': 43.6333,
    '1002_780': 43.5843,
    '1002_781': 43.506,
    '1002_782': 43.4159,
    '1002_783': 43.3118,
    '1002_784': 43.211,
    '1002_785': 43.119,
    '1002_786': 43.0371,
    '1002_787': 42.9528,
    '1002_788': 42.8757,
    '1002_789': 42.813,
    '1002_790': 42.7677,
    '1002_791': 42.7338,
    '1002_792': 42.7087,
    '1002_793': 42.6896,
    '1002_794': 42.6663,
    '1002_795': 42.6219,
    '1002_796': 42.5665,
    '1002_797': 42.5101,
    '1002_798': 42.463,
    '1002_799': 42.4355,
    '1002_800': 42.4261,
    '1002_801': 42.4328,
    '1002_802': 42.4521,
    '1002_803': 42.4878,
    '1002_804': 42.5347,
    '1002_805': 42.5892,
    '1002_806': 42.6379,
    '1002_807': 42.6775,
    '1002_808': 42.7092,
    '1002_809': 42.7235,
    '1002_810': 42.7141,
    '1002_811': 42.6784,
    '1002_812': 42.6243,
    '1002_813': 42.5542,
    '1002_814': 42.4691,
    '1002_815': 42.3709,
    '1002_816': 42.271,
    '1002_817': 42.1877,
    '1002_818': 42.1024,
    '1002_819': 42.006,
    '1002_820': 41.9171,
    '1002_821': 41.8125,
    '1002_822': 41.6916,
    '1002_823': 41.5307,
    '1002_824': 41.3339,
    '1002_825': 41.1101,
    '1002_826': 40.8585,
    '1002_827': 40.5709,
    '1002_828': 40.2756,
    '1002_829': 39.9772,
    '1002_830': 39.6777,
    '1002_831': 39.3738,
    '1002_832': 39.0635,
    '1002_833': 38.7521,
    '1002_834': 38.4456,
    '1002_835': 38.1425,
    '1002_836': 37.847,
    '1002_837': 37.5611,
    '1002_838': 37.2813,
    '1002_839': 37.0049,
    '1002_840': 36.7375,
    '1002_841': 36.4947,
    '1002_842': 36.2291,
    '1002_843': 35.9723,
    '1002_844': 35.7141,
    '1002_845': 35.4582,
    '1002_846': 35.1989,
    '1002_847': 34.9438,
    '1002_848': 34.6905,
    '1003_656': 36.6185,
    '1003_657': 36.6981,
    '1003_658': 36.7793,
    '1003_659': 36.8381,
    '1003_660': 36.9104,
    '1003_661': 37.0157,
    '1003_662': 37.106,
    '1003_663': 37.1725,
    '1003_664': 37.2425,
    '1003_665': 37.32,
    '1003_666': 37.4034,
    '1003_667': 37.4908,
    '1003_668': 37.5825,
    '1003_669': 37.6745,
    '1003_670': 37.7668,
    '1003_671': 37.8526,
    '1003_672': 37.9265,
    '1003_673': 37.9796,
    '1003_674': 38.0124,
    '1003_675': 38.0275,
    '1003_676': 38.017,
    '1003_677': 38.005,
    '1003_678': 38.0097,
    '1003_679': 38.0248,
    '1003_680': 38.0474,
    '1003_681': 38.0745,
    '1003_682': 38.1026,
    '1003_683': 38.1313,
    '1003_684': 38.165,
    '1003_685': 38.2067,
    '1003_686': 38.255,
    '1003_687': 38.3101,
    '1003_688': 38.369,
    '1003_689': 38.4356,
    '1003_690': 38.5132,
    '1003_691': 38.6044,
    '1003_692': 38.7122,
    '1003_693': 38.8413,
    '1003_694': 38.9943,
    '1003_695': 39.1758,
    '1003_696': 39.392,
    '1003_697': 39.6398,
    '1003_698': 39.9024,
    '1003_699': 40.1676,
    '1003_700': 40.4268,
    '1003_701': 40.6751,
    '1003_702': 40.9053,
    '1003_703': 41.0935,
    '1003_704': 41.2198,
    '1003_705': 41.3188,
    '1003_706': 41.4207,
    '1003_707': 41.5409,
    '1003_708': 41.7248,
    '1003_709': 41.8464,
    '1003_710': 41.8904,
    '1003_711': 41.9909,
    '1003_712': 41.9686,
    '1003_713': 41.8819,
    '1003_714': 41.7982,
    '1003_715': 41.7469,
    '1003_716': 41.7488,
    '1003_717': 41.7777,
    '1003_718': 41.8134,
    '1003_719': 41.8385,
    '1003_720': 41.8599,
    '1003_721': 41.8772,
    '1003_722': 41.8786,
    '1003_723': 41.8713,
    '1003_724': 41.8601,
    '1003_725': 41.8444,
    '1003_726': 41.8137,
    '1003_727': 41.7664,
    '1003_728': 41.7313,
    '1003_729': 41.7177,
    '1003_730': 41.727,
    '1003_731': 41.7632,
    '1003_732': 41.8324,
    '1003_733': 41.9439,
    '1003_734': 42.0976,
    '1003_735': 42.2768,
    '1003_736': 42.4724,
    '1003_737': 42.6627,
    '1003_738': 42.8462,
    '1003_739': 43.0057,
    '1003_740': 43.1474,
    '1003_741': 43.2435,
    '1003_742': 43.3181,
    '1003_743': 43.3774,
    '1003_744': 43.3844,
    '1003_745': 43.4061,
    '1003_746': 43.4295,
    '1003_747': 43.4258,
    '1003_748': 43.4329,
    '1003_749': 43.3958,
    '1003_750': 43.3516,
    '1003_751': 43.3023,
    '1003_752': 43.253,
    '1003_753': 43.1986,
    '1003_754': 43.1368,
    '1003_755': 43.0699,
    '1003_756': 43.0165,
    '1003_757': 42.989,
    '1003_758': 42.9678,
    '1003_759': 42.9536,
    '1003_760': 42.9743,
    '1003_761': 43.0116,
    '1003_762': 43.0484,
    '1003_763': 43.0969,
    '1003_764': 43.1589,
    '1003_765': 43.226,
    '1003_766': 43.2921,
    '1003_767': 43.3441,
    '1003_768': 43.3852,
    '1003_769': 43.436,
    '1003_770': 43.5183,
    '1003_771': 43.6184,
    '1003_772': 43.7146,
    '1003_773': 43.7852,
    '1003_774': 43.8223,
    '1003_775': 43.8243,
    '1003_776': 43.8112,
    '1003_777': 43.7778,
    '1003_778': 43.7486,
    '1003_779': 43.7055,
    '1003_780': 43.6437,
    '1003_781': 43.5712,
    '1003_782': 43.4743,
    '1003_783': 43.3726,
    '1003_784': 43.2731,
    '1003_785': 43.1743,
    '1003_786': 43.0803,
    '1003_787': 42.9924,
    '1003_788': 42.9146,
    '1003_789': 42.851,
    '1003_790': 42.8039,
    '1003_791': 42.7672,
    '1003_792': 42.739,
    '1003_793': 42.7168,
    '1003_794': 42.6903,
    '1003_795': 42.6466,
    '1003_796': 42.59,
    '1003_797': 42.5323,
    '1003_798': 42.4835,
    '1003_799': 42.4557,
    '1003_800': 42.4452,
    '1003_801': 42.4513,
    '1003_802': 42.4745,
    '1003_803': 42.512,
    '1003_804': 42.5596,
    '1003_805': 42.6144,
    '1003_806': 42.6651,
    '1003_807': 42.712,
    '1003_808': 42.7472,
    '1003_809': 42.7655,
    '1003_810': 42.7594,
    '1003_811': 42.7303,
    '1003_812': 42.6822,
    '1003_813': 42.6138,
    '1003_814': 42.5269,
    '1003_815': 42.4304,
    '1003_816': 42.3401,
    '1003_817': 42.2697,
    '1003_818': 42.1901,
    '1003_819': 42.1,
    '1003_820': 42.0123,
    '1003_821': 41.9161,
    '1003_822': 41.795,
    '1003_823': 41.6324,
    '1003_824': 41.4334,
    '1003_825': 41.2048,
    '1003_826': 40.9526,
    '1003_827': 40.6693,
    '1003_828': 40.3807,
    '1003_829': 40.0934,
    '1003_830': 39.8056,
    '1003_831': 39.5112,
    '1003_832': 39.2026,
    '1003_833': 38.89,
    '1003_834': 38.5815,
    '1003_835': 38.277,
    '1003_836': 37.9793,
    '1003_837': 37.6895,
    '1003_838': 37.4097,
    '1003_839': 37.134,
    '1003_840': 36.8677,
    '1003_841': 36.6186,
    '1003_842': 36.3575,
    '1003_843': 36.0969,
    '1003_844': 35.8394,
    '1003_845': 35.5893,
    '1003_846': 35.3342,
    '1003_847': 35.0801,
    '1003_848': 34.8289,
    '1004_656': 36.6106,
    '1004_657': 36.6856,
    '1004_658': 36.7575,
    '1004_659': 36.8288,
    '1004_660': 36.9065,
    '1004_661': 36.9946,
    '1004_662': 37.0826,
    '1004_663': 37.162,
    '1004_664': 37.2411,
    '1004_665': 37.3249,
    '1004_666': 37.4112,
    '1004_667': 37.4999,
    '1004_668': 37.5928,
    '1004_669': 37.6847,
    '1004_670': 37.7773,
    '1004_671': 37.8631,
    '1004_672': 37.9353,
    '1004_673': 37.9866,
    '1004_674': 38.012,
    '1004_675': 38.0237,
    '1004_676': 38.0142,
    '1004_677': 37.9919,
    '1004_678': 37.9805,
    '1004_679': 37.9848,
    '1004_680': 37.9985,
    '1004_681': 38.0176,
    '1004_682': 38.0378,
    '1004_683': 38.0591,
    '1004_684': 38.0874,
    '1004_685': 38.1255,
    '1004_686': 38.1707,
    '1004_687': 38.2222,
    '1004_688': 38.2785,
    '1004_689': 38.3409,
    '1004_690': 38.4139,
    '1004_691': 38.4995,
    '1004_692': 38.6016,
    '1004_693': 38.724,
    '1004_694': 38.8698,
    '1004_695': 39.0452,
    '1004_696': 39.2548,
    '1004_697': 39.497,
    '1004_698': 39.7574,
    '1004_699': 40.0221,
    '1004_700': 40.283,
    '1004_701': 40.5341,
    '1004_702': 40.7743,
    '1004_703': 40.9712,
    '1004_704': 41.0943,
    '1004_705': 41.2007,
    '1004_706': 41.3213,
    '1004_707': 41.484,
    '1004_708': 41.6674,
    '1004_709': 41.8182,
    '1004_710': 41.8572,
    '1004_711': 41.9495,
    '1004_712': 41.9406,
    '1004_713': 41.8749,
    '1004_714': 41.7948,
    '1004_715': 41.7404,
    '1004_716': 41.736,
    '1004_717': 41.7725,
    '1004_718': 41.8126,
    '1004_719': 41.859,
    '1004_720': 41.8836,
    '1004_721': 41.9108,
    '1004_722': 41.9157,
    '1004_723': 41.9092,
    '1004_724': 41.8953,
    '1004_725': 41.8629,
    '1004_726': 41.8269,
    '1004_727': 41.7681,
    '1004_728': 41.716,
    '1004_729': 41.6876,
    '1004_730': 41.688,
    '1004_731': 41.7154,
    '1004_732': 41.7704,
    '1004_733': 41.8627,
    '1004_734': 42.0011,
    '1004_735': 42.1751,
    '1004_736': 42.3704,
    '1004_737': 42.571,
    '1004_738': 42.7684,
    '1004_739': 42.9463,
    '1004_740': 43.0927,
    '1004_741': 43.1983,
    '1004_742': 43.2807,
    '1004_743': 43.3473,
    '1004_744': 43.392,
    '1004_745': 43.4022,
    '1004_746': 43.423,
    '1004_747': 43.4231,
    '1004_748': 43.4318,
    '1004_749': 43.4207,
    '1004_750': 43.3905,
    '1004_751': 43.3413,
    '1004_752': 43.285,
    '1004_753': 43.2356,
    '1004_754': 43.1753,
    '1004_755': 43.118,
    '1004_756': 43.0754,
    '1004_757': 43.0508,
    '1004_758': 43.0314,
    '1004_759': 43.0176,
    '1004_760': 43.0464,
    '1004_761': 43.0928,
    '1004_762': 43.1412,
    '1004_763': 43.194,
    '1004_764': 43.2546,
    '1004_765': 43.3078,
    '1004_766': 43.3597,
    '1004_767': 43.4,
    '1004_768': 43.4359,
    '1004_769': 43.4837,
    '1004_770': 43.5617,
    '1004_771': 43.6572,
    '1004_772': 43.7614,
    '1004_773': 43.8374,
    '1004_774': 43.8801,
    '1004_775': 43.8819,
    '1004_776': 43.8771,
    '1004_777': 43.8477,
    '1004_778': 43.8164,
    '1004_779': 43.7794,
    '1004_780': 43.7188,
    '1004_781': 43.6412,
    '1004_782': 43.5377,
    '1004_783': 43.4324,
    '1004_784': 43.3288,
    '1004_785': 43.2249,
    '1004_786': 43.1245,
    '1004_787': 43.0351,
    '1004_788': 42.9567,
    '1004_789': 42.8928,
    '1004_790': 42.8428,
    '1004_791': 42.8026,
    '1004_792': 42.7708,
    '1004_793': 42.7454,
    '1004_794': 42.7152,
    '1004_795': 42.6685,
    '1004_796': 42.612,
    '1004_797': 42.5546,
    '1004_798': 42.5045,
    '1004_799': 42.4762,
    '1004_800': 42.4657,
    '1004_801': 42.4738,
    '1004_802': 42.4977,
    '1004_803': 42.5348,
    '1004_804': 42.58,
    '1004_805': 42.6332,
    '1004_806': 42.6869,
    '1004_807': 42.7389,
    '1004_808': 42.7798,
    '1004_809': 42.7986,
    '1004_810': 42.7931,
    '1004_811': 42.7716,
    '1004_812': 42.7274,
    '1004_813': 42.6619,
    '1004_814': 42.5838,
    '1004_815': 42.4919,
    '1004_816': 42.407,
    '1004_817': 42.342,
    '1004_818': 42.2705,
    '1004_819': 42.1939,
    '1004_820': 42.1119,
    '1004_821': 42.0221,
    '1004_822': 41.8983,
    '1004_823': 41.734,
    '1004_824': 41.5352,
    '1004_825': 41.3059,
    '1004_826': 41.0515,
    '1004_827': 40.7706,
    '1004_828': 40.4859,
    '1004_829': 40.2073,
    '1004_830': 39.9288,
    '1004_831': 39.6455,
    '1004_832': 39.3374,
    '1004_833': 39.0256,
    '1004_834': 38.7168,
    '1004_835': 38.4123,
    '1004_836': 38.1142,
    '1004_837': 37.8228,
    '1004_838': 37.5388,
    '1004_839': 37.2588,
    '1004_840': 36.9865,
    '1004_841': 36.7452,
    '1004_842': 36.4864,
    '1004_843': 36.2257,
    '1004_844': 35.9757,
    '1004_845': 35.7275,
    '1004_846': 35.471,
    '1004_847': 35.2176,
    '1004_848': 34.9684,
    '1005_656': 36.6063,
    '1005_657': 36.6833,
    '1005_658': 36.7586,
    '1005_659': 36.8329,
    '1005_660': 36.9085,
    '1005_661': 36.9922,
    '1005_662': 37.0826,
    '1005_663': 37.1692,
    '1005_664': 37.2524,
    '1005_665': 37.3337,
    '1005_666': 37.4165,
    '1005_667': 37.5022,
    '1005_668': 37.5912,
    '1005_669': 37.6822,
    '1005_670': 37.773,
    '1005_671': 37.8637,
    '1005_672': 37.9431,
    '1005_673': 37.9916,
    '1005_674': 38.0135,
    '1005_675': 38.0186,
    '1005_676': 38.008,
    '1005_677': 37.9813,
    '1005_678': 37.9584,
    '1005_679': 37.951,
    '1005_680': 37.9553,
    '1005_681': 37.9663,
    '1005_682': 37.9786,
    '1005_683': 37.993,
    '1005_684': 38.0139,
    '1005_685': 38.0455,
    '1005_686': 38.0846,
    '1005_687': 38.1303,
    '1005_688': 38.1832,
    '1005_689': 38.2436,
    '1005_690': 38.3122,
    '1005_691': 38.3937,
    '1005_692': 38.4909,
    '1005_693': 38.6073,
    '1005_694': 38.746,
    '1005_695': 38.9145,
    '1005_696': 39.1148,
    '1005_697': 39.3474,
    '1005_698': 39.6047,
    '1005_699': 39.8664,
    '1005_700': 40.1273,
    '1005_701': 40.3904,
    '1005_702': 40.6248,
    '1005_703': 40.8135,
    '1005_704': 40.9572,
    '1005_705': 41.0778,
    '1005_706': 41.2183,
    '1005_707': 41.4081,
    '1005_708': 41.591,
    '1005_709': 41.7361,
    '1005_710': 41.8038,
    '1005_711': 41.9205,
    '1005_712': 41.9223,
    '1005_713': 41.8598,
    '1005_714': 41.7921,
    '1005_715': 41.7304,
    '1005_716': 41.7155,
    '1005_717': 41.7531,
    '1005_718': 41.8026,
    '1005_719': 41.864,
    '1005_720': 41.9019,
    '1005_721': 41.9174,
    '1005_722': 41.9462,
    '1005_723': 41.9357,
    '1005_724': 41.912,
    '1005_725': 41.8707,
    '1005_726': 41.8209,
    '1005_727': 41.7546,
    '1005_728': 41.6888,
    '1005_729': 41.6504,
    '1005_730': 41.6427,
    '1005_731': 41.6629,
    '1005_732': 41.7101,
    '1005_733': 41.7873,
    '1005_734': 41.9145,
    '1005_735': 42.0798,
    '1005_736': 42.2714,
    '1005_737': 42.4795,
    '1005_738': 42.6818,
    '1005_739': 42.8691,
    '1005_740': 43.0273,
    '1005_741': 43.1486,
    '1005_742': 43.2361,
    '1005_743': 43.2805,
    '1005_744': 43.3477,
    '1005_745': 43.3813,
    '1005_746': 43.4069,
    '1005_747': 43.4306,
    '1005_748': 43.4308,
    '1005_749': 43.4143,
    '1005_750': 43.3923,
    '1005_751': 43.3519,
    '1005_752': 43.3075,
    '1005_753': 43.2668,
    '1005_754': 43.2169,
    '1005_755': 43.1704,
    '1005_756': 43.1349,
    '1005_757': 43.1102,
    '1005_758': 43.0862,
    '1005_759': 43.0781,
    '1005_760': 43.1198,
    '1005_761': 43.1739,
    '1005_762': 43.229,
    '1005_763': 43.282,
    '1005_764': 43.337,
    '1005_765': 43.3858,
    '1005_766': 43.4304,
    '1005_767': 43.4598,
    '1005_768': 43.4908,
    '1005_769': 43.5363,
    '1005_770': 43.613,
    '1005_771': 43.7045,
    '1005_772': 43.7878,
    '1005_773': 43.8793,
    '1005_774': 43.9201,
    '1005_775': 43.9372,
    '1005_776': 43.9341,
    '1005_777': 43.8981,
    '1005_778': 43.8677,
    '1005_779': 43.8309,
    '1005_780': 43.7735,
    '1005_781': 43.7041,
    '1005_782': 43.6015,
    '1005_783': 43.4938,
    '1005_784': 43.3847,
    '1005_785': 43.2756,
    '1005_786': 43.1736,
    '1005_787': 43.0826,
    '1005_788': 43.0028,
    '1005_789': 42.9364,
    '1005_790': 42.8836,
    '1005_791': 42.8409,
    '1005_792': 42.8078,
    '1005_793': 42.7795,
    '1005_794': 42.7429,
    '1005_795': 42.6904,
    '1005_796': 42.6335,
    '1005_797': 42.5756,
    '1005_798': 42.5257,
    '1005_799': 42.4967,
    '1005_800': 42.4871,
    '1005_801': 42.4956,
    '1005_802': 42.5203,
    '1005_803': 42.5561,
    '1005_804': 42.6004,
    '1005_805': 42.6502,
    '1005_806': 42.7084,
    '1005_807': 42.7661,
    '1005_808': 42.8072,
    '1005_809': 42.8241,
    '1005_810': 42.8196,
    '1005_811': 42.8003,
    '1005_812': 42.7613,
    '1005_813': 42.7035,
    '1005_814': 42.6305,
    '1005_815': 42.5443,
    '1005_816': 42.466,
    '1005_817': 42.4043,
    '1005_818': 42.3434,
    '1005_819': 42.2845,
    '1005_820': 42.2118,
    '1005_821': 42.1295,
    '1005_822': 42.0036,
    '1005_823': 41.8357,
    '1005_824': 41.6377,
    '1005_825': 41.4132,
    '1005_826': 41.1566,
    '1005_827': 40.8761,
    '1005_828': 40.5903,
    '1005_829': 40.313,
    '1005_830': 40.0354,
    '1005_831': 39.7519,
    '1005_832': 39.4531,
    '1005_833': 39.1487,
    '1005_834': 38.844,
    '1005_835': 38.5421,
    '1005_836': 38.2453,
    '1005_837': 37.9545,
    '1005_838': 37.6714,
    '1005_839': 37.3943,
    '1005_840': 37.1248,
    '1005_841': 36.8758,
    '1005_842': 36.6165,
    '1005_843': 36.3561,
    '1005_844': 36.1079,
    '1005_845': 35.8545,
    '1005_846': 35.599,
    '1005_847': 35.3503,
    '1005_848': 35.1044,
    '1006_656': 36.5993,
    '1006_657': 36.683,
    '1006_658': 36.7655,
    '1006_659': 36.8418,
    '1006_660': 36.9109,
    '1006_661': 36.9946,
    '1006_662': 37.0938,
    '1006_663': 37.1844,
    '1006_664': 37.2689,
    '1006_665': 37.3447,
    '1006_666': 37.42,
    '1006_667': 37.4994,
    '1006_668': 37.5812,
    '1006_669': 37.6667,
    '1006_670': 37.755,
    '1006_671': 37.85,
    '1006_672': 37.9379,
    '1006_673': 37.9887,
    '1006_674': 38.0087,
    '1006_675': 38.0098,
    '1006_676': 37.9953,
    '1006_677': 37.9697,
    '1006_678': 37.9442,
    '1006_679': 37.9268,
    '1006_680': 37.9211,
    '1006_681': 37.9236,
    '1006_682': 37.926,
    '1006_683': 37.9315,
    '1006_684': 37.944,
    '1006_685': 37.9664,
    '1006_686': 37.9961,
    '1006_687': 38.0331,
    '1006_688': 38.0814,
    '1006_689': 38.1388,
    '1006_690': 38.2052,
    '1006_691': 38.2835,
    '1006_692': 38.3771,
    '1006_693': 38.4896,
    '1006_694': 38.6229,
    '1006_695': 38.783,
    '1006_696': 38.9704,
    '1006_697': 39.1927,
    '1006_698': 39.4397,
    '1006_699': 39.7008,
    '1006_700': 39.9632,
    '1006_701': 40.239,
    '1006_702': 40.4742,
    '1006_703': 40.6605,
    '1006_704': 40.8157,
    '1006_705': 40.9386,
    '1006_706': 41.1097,
    '1006_707': 41.3142,
    '1006_708': 41.5,
    '1006_709': 41.6599,
    '1006_710': 41.7645,
    '1006_711': 41.8202,
    '1006_712': 41.8535,
    '1006_713': 41.8255,
    '1006_714': 41.7711,
    '1006_715': 41.7118,
    '1006_716': 41.6839,
    '1006_717': 41.7171,
    '1006_718': 41.7808,
    '1006_719': 41.8453,
    '1006_720': 41.912,
    '1006_721': 41.9401,
    '1006_722': 41.9561,
    '1006_723': 41.9565,
    '1006_724': 41.9279,
    '1006_725': 41.8785,
    '1006_726': 41.8095,
    '1006_727': 41.728,
    '1006_728': 41.6567,
    '1006_729': 41.6151,
    '1006_730': 41.6023,
    '1006_731': 41.6147,
    '1006_732': 41.6539,
    '1006_733': 41.7223,
    '1006_734': 41.8356,
    '1006_735': 41.9919,
    '1006_736': 42.1804,
    '1006_737': 42.3877,
    '1006_738': 42.5974,
    '1006_739': 42.7886,
    '1006_740': 42.9354,
    '1006_741': 43.0781,
    '1006_742': 43.1747,
    '1006_743': 43.2185,
    '1006_744': 43.2691,
    '1006_745': 43.331,
    '1006_746': 43.3581,
    '1006_747': 43.3796,
    '1006_748': 43.4113,
    '1006_749': 43.3893,
    '1006_750': 43.3735,
    '1006_751': 43.3507,
    '1006_752': 43.3196,
    '1006_753': 43.2891,
    '1006_754': 43.2588,
    '1006_755': 43.2265,
    '1006_756': 43.1965,
    '1006_757': 43.1705,
    '1006_758': 43.1425,
    '1006_759': 43.1401,
    '1006_760': 43.1832,
    '1006_761': 43.2391,
    '1006_762': 43.2988,
    '1006_763': 43.3574,
    '1006_764': 43.4125,
    '1006_765': 43.461,
    '1006_766': 43.5019,
    '1006_767': 43.525,
    '1006_768': 43.5533,
    '1006_769': 43.5938,
    '1006_770': 43.664,
    '1006_771': 43.7522,
    '1006_772': 43.8368,
    '1006_773': 43.9069,
    '1006_774': 43.9509,
    '1006_775': 43.986,
    '1006_776': 43.978,
    '1006_777': 43.9428,
    '1006_778': 43.9112,
    '1006_779': 43.8664,
    '1006_780': 43.8033,
    '1006_781': 43.7712,
    '1006_782': 43.6784,
    '1006_783': 43.5658,
    '1006_784': 43.4509,
    '1006_785': 43.3398,
    '1006_786': 43.236,
    '1006_787': 43.1408,
    '1006_788': 43.059,
    '1006_789': 42.99,
    '1006_790': 42.9352,
    '1006_791': 42.89,
    '1006_792': 42.8542,
    '1006_793': 42.8207,
    '1006_794': 42.7745,
    '1006_795': 42.7163,
    '1006_796': 42.6559,
    '1006_797': 42.5961,
    '1006_798': 42.5477,
    '1006_799': 42.5198,
    '1006_800': 42.5106,
    '1006_801': 42.518,
    '1006_802': 42.5423,
    '1006_803': 42.5775,
    '1006_804': 42.6226,
    '1006_805': 42.675,
    '1006_806': 42.7369,
    '1006_807': 42.7983,
    '1006_808': 42.8389,
    '1006_809': 42.8509,
    '1006_810': 42.8429,
    '1006_811': 42.8203,
    '1006_812': 42.7778,
    '1006_813': 42.7233,
    '1006_814': 42.657,
    '1006_815': 42.5862,
    '1006_816': 42.5205,
    '1006_817': 42.4626,
    '1006_818': 42.4164,
    '1006_819': 42.3712,
    '1006_820': 42.3114,
    '1006_821': 42.2319,
    '1006_822': 42.1061,
    '1006_823': 41.9368,
    '1006_824': 41.7396,
    '1006_825': 41.5182,
    '1006_826': 41.2662,
    '1006_827': 40.9878,
    '1006_828': 40.6989,
    '1006_829': 40.4194,
    '1006_830': 40.1404,
    '1006_831': 39.8568,
    '1006_832': 39.5642,
    '1006_833': 39.2661,
    '1006_834': 38.9663,
    '1006_835': 38.6682,
    '1006_836': 38.3737,
    '1006_837': 38.085,
    '1006_838': 37.8048,
    '1006_839': 37.5303,
    '1006_840': 37.2512,
    '1006_841': 37.0084,
    '1006_842': 36.7493,
    '1006_843': 36.4877,
    '1006_844': 36.2348,
    '1006_845': 35.9815,
    '1006_846': 35.729,
    '1006_847': 35.4857,
    '1006_848': 35.2419,
    '1007_656': 36.5825,
    '1007_657': 36.67,
    '1007_658': 36.7605,
    '1007_659': 36.8421,
    '1007_660': 36.9033,
    '1007_661': 36.9945,
    '1007_662': 37.1024,
    '1007_663': 37.1914,
    '1007_664': 37.2787,
    '1007_665': 37.3501,
    '1007_666': 37.4211,
    '1007_667': 37.492,
    '1007_668': 37.5645,
    '1007_669': 37.6412,
    '1007_670': 37.724,
    '1007_671': 37.8183,
    '1007_672': 37.9106,
    '1007_673': 37.9675,
    '1007_674': 37.9879,
    '1007_675': 37.9888,
    '1007_676': 37.9725,
    '1007_677': 37.95,
    '1007_678': 37.9256,
    '1007_679': 37.9089,
    '1007_680': 37.8988,
    '1007_681': 37.8948,
    '1007_682': 37.8811,
    '1007_683': 37.8742,
    '1007_684': 37.8755,
    '1007_685': 37.8841,
    '1007_686': 37.9002,
    '1007_687': 37.9271,
    '1007_688': 37.9703,
    '1007_689': 38.0246,
    '1007_690': 38.0895,
    '1007_691': 38.1668,
    '1007_692': 38.2591,
    '1007_693': 38.3689,
    '1007_694': 38.4979,
    '1007_695': 38.6487,
    '1007_696': 38.826,
    '1007_697': 39.0352,
    '1007_698': 39.2737,
    '1007_699': 39.5272,
    '1007_700': 39.794,
    '1007_701': 40.0686,
    '1007_702': 40.3136,
    '1007_703': 40.5071,
    '1007_704': 40.6557,
    '1007_705': 40.801,
    '1007_706': 41.0029,
    '1007_707': 41.2265,
    '1007_708': 41.4231,
    '1007_709': 41.6087,
    '1007_710': 41.6924,
    '1007_711': 41.7701,
    '1007_712': 41.7541,
    '1007_713': 41.7568,
    '1007_714': 41.7217,
    '1007_715': 41.6737,
    '1007_716': 41.641,
    '1007_717': 41.6665,
    '1007_718': 41.7418,
    '1007_719': 41.817,
    '1007_720': 41.9005,
    '1007_721': 41.9571,
    '1007_722': 41.9753,
    '1007_723': 41.961,
    '1007_724': 41.9334,
    '1007_725': 41.8719,
    '1007_726': 41.7874,
    '1007_727': 41.695,
    '1007_728': 41.6236,
    '1007_729': 41.584,
    '1007_730': 41.5719,
    '1007_731': 41.5798,
    '1007_732': 41.6062,
    '1007_733': 41.6653,
    '1007_734': 41.7656,
    '1007_735': 41.9179,
    '1007_736': 42.101,
    '1007_737': 42.2942,
    '1007_738': 42.5043,
    '1007_739': 42.6962,
    '1007_740': 42.857,
    '1007_741': 43.0034,
    '1007_742': 43.0922,
    '1007_743': 43.1605,
    '1007_744': 43.2009,
    '1007_745': 43.2562,
    '1007_746': 43.3019,
    '1007_747': 43.331,
    '1007_748': 43.3689,
    '1007_749': 43.3628,
    '1007_750': 43.3432,
    '1007_751': 43.3327,
    '1007_752': 43.322,
    '1007_753': 43.3066,
    '1007_754': 43.2965,
    '1007_755': 43.2779,
    '1007_756': 43.2548,
    '1007_757': 43.2277,
    '1007_758': 43.203,
    '1007_759': 43.199,
    '1007_760': 43.2341,
    '1007_761': 43.2866,
    '1007_762': 43.3451,
    '1007_763': 43.4091,
    '1007_764': 43.4695,
    '1007_765': 43.5191,
    '1007_766': 43.5583,
    '1007_767': 43.5965,
    '1007_768': 43.6267,
    '1007_769': 43.6575,
    '1007_770': 43.7178,
    '1007_771': 43.8069,
    '1007_772': 43.8973,
    '1007_773': 43.9649,
    '1007_774': 43.9971,
    '1007_775': 43.9995,
    '1007_776': 44.0015,
    '1007_777': 43.9891,
    '1007_778': 43.9606,
    '1007_779': 43.9299,
    '1007_780': 43.8782,
    '1007_781': 43.8285,
    '1007_782': 43.7466,
    '1007_783': 43.6432,
    '1007_784': 43.5248,
    '1007_785': 43.4109,
    '1007_786': 43.3029,
    '1007_787': 43.207,
    '1007_788': 43.1269,
    '1007_789': 43.0604,
    '1007_790': 43.0035,
    '1007_791': 42.9545,
    '1007_792': 42.9166,
    '1007_793': 42.8742,
    '1007_794': 42.8138,
    '1007_795': 42.7467,
    '1007_796': 42.6802,
    '1007_797': 42.6179,
    '1007_798': 42.5714,
    '1007_799': 42.5442,
    '1007_800': 42.5337,
    '1007_801': 42.5408,
    '1007_802': 42.5644,
    '1007_803': 42.6007,
    '1007_804': 42.6473,
    '1007_805': 42.705,
    '1007_806': 42.7719,
    '1007_807': 42.8344,
    '1007_808': 42.8762,
    '1007_809': 42.885,
    '1007_810': 42.8771,
    '1007_811': 42.8499,
    '1007_812': 42.8021,
    '1007_813': 42.7455,
    '1007_814': 42.686,
    '1007_815': 42.6291,
    '1007_816': 42.5776,
    '1007_817': 42.5284,
    '1007_818': 42.4929,
    '1007_819': 42.4587,
    '1007_820': 42.4093,
    '1007_821': 42.331,
    '1007_822': 42.2066,
    '1007_823': 42.0369,
    '1007_824': 41.8399,
    '1007_825': 41.6219,
    '1007_826': 41.3765,
    '1007_827': 41.1053,
    '1007_828': 40.8129,
    '1007_829': 40.531,
    '1007_830': 40.2514,
    '1007_831': 39.9674,
    '1007_832': 39.6772,
    '1007_833': 39.3828,
    '1007_834': 39.0858,
    '1007_835': 38.7893,
    '1007_836': 38.5006,
    '1007_837': 38.2166,
    '1007_838': 37.9399,
    '1007_839': 37.6678,
    '1007_840': 37.3981,
    '1007_841': 37.1417,
    '1007_842': 36.8813,
    '1007_843': 36.6159,
    '1007_844': 36.3631,
    '1007_845': 36.113,
    '1007_846': 35.8616,
    '1007_847': 35.6232,
    '1007_848': 35.3797,
    '1008_656': 36.5386,
    '1008_657': 36.6269,
    '1008_658': 36.7144,
    '1008_659': 36.7987,
    '1008_660': 36.8811,
    '1008_661': 36.9709,
    '1008_662': 37.0695,
    '1008_663': 37.1644,
    '1008_664': 37.255,
    '1008_665': 37.3355,
    '1008_666': 37.4107,
    '1008_667': 37.4758,
    '1008_668': 37.5396,
    '1008_669': 37.6065,
    '1008_670': 37.6804,
    '1008_671': 37.7656,
    '1008_672': 37.8579,
    '1008_673': 37.9217,
    '1008_674': 37.9482,
    '1008_675': 37.9515,
    '1008_676': 37.939,
    '1008_677': 37.9183,
    '1008_678': 37.8955,
    '1008_679': 37.8836,
    '1008_680': 37.87,
    '1008_681': 37.8607,
    '1008_682': 37.8378,
    '1008_683': 37.8177,
    '1008_684': 37.8061,
    '1008_685': 37.8024,
    '1008_686': 37.8057,
    '1008_687': 37.821,
    '1008_688': 37.86,
    '1008_689': 37.9105,
    '1008_690': 37.9723,
    '1008_691': 38.0481,
    '1008_692': 38.1394,
    '1008_693': 38.2474,
    '1008_694': 38.3725,
    '1008_695': 38.5168,
    '1008_696': 38.685,
    '1008_697': 38.8831,
    '1008_698': 39.109,
    '1008_699': 39.3545,
    '1008_700': 39.6152,
    '1008_701': 39.8873,
    '1008_702': 40.1293,
    '1008_703': 40.3267,
    '1008_704': 40.4955,
    '1008_705': 40.6827,
    '1008_706': 40.8939,
    '1008_707': 41.1222,
    '1008_708': 41.3413,
    '1008_709': 41.5216,
    '1008_710': 41.594,
    '1008_711': 41.6877,
    '1008_712': 41.7014,
    '1008_713': 41.6853,
    '1008_714': 41.6618,
    '1008_715': 41.6185,
    '1008_716': 41.5834,
    '1008_717': 41.6034,
    '1008_718': 41.6881,
    '1008_719': 41.7814,
    '1008_720': 41.8722,
    '1008_721': 41.9327,
    '1008_722': 41.9454,
    '1008_723': 41.9543,
    '1008_724': 41.9152,
    '1008_725': 41.8433,
    '1008_726': 41.7587,
    '1008_727': 41.6598,
    '1008_728': 41.5883,
    '1008_729': 41.5553,
    '1008_730': 41.5503,
    '1008_731': 41.5572,
    '1008_732': 41.5727,
    '1008_733': 41.616,
    '1008_734': 41.7025,
    '1008_735': 41.8465,
    '1008_736': 42.0134,
    '1008_737': 42.1994,
    '1008_738': 42.4035,
    '1008_739': 42.6031,
    '1008_740': 42.7691,
    '1008_741': 42.9104,
    '1008_742': 42.9979,
    '1008_743': 43.0753,
    '1008_744': 43.1232,
    '1008_745': 43.1786,
    '1008_746': 43.2322,
    '1008_747': 43.2807,
    '1008_748': 43.2994,
    '1008_749': 43.3149,
    '1008_750': 43.2991,
    '1008_751': 43.294,
    '1008_752': 43.3033,
    '1008_753': 43.3088,
    '1008_754': 43.3195,
    '1008_755': 43.3157,
    '1008_756': 43.2993,
    '1008_757': 43.2894,
    '1008_758': 43.2638,
    '1008_759': 43.2513,
    '1008_760': 43.2752,
    '1008_761': 43.3208,
    '1008_762': 43.3834,
    '1008_763': 43.4474,
    '1008_764': 43.5146,
    '1008_765': 43.5675,
    '1008_766': 43.6116,
    '1008_767': 43.6687,
    '1008_768': 43.6985,
    '1008_769': 43.7269,
    '1008_770': 43.7762,
    '1008_771': 43.8515,
    '1008_772': 43.9385,
    '1008_773': 44.0055,
    '1008_774': 44.0347,
    '1008_775': 44.02,
    '1008_776': 44.0317,
    '1008_777': 44.0339,
    '1008_778': 43.999,
    '1008_779': 43.9737,
    '1008_780': 43.9193,
    '1008_781': 43.855,
    '1008_782': 43.7857,
    '1008_783': 43.7009,
    '1008_784': 43.5915,
    '1008_785': 43.4793,
    '1008_786': 43.3718,
    '1008_787': 43.2785,
    '1008_788': 43.2012,
    '1008_789': 43.1381,
    '1008_790': 43.0797,
    '1008_791': 43.0301,
    '1008_792': 42.9862,
    '1008_793': 42.9328,
    '1008_794': 42.8614,
    '1008_795': 42.7849,
    '1008_796': 42.7088,
    '1008_797': 42.643,
    '1008_798': 42.5967,
    '1008_799': 42.568,
    '1008_800': 42.5559,
    '1008_801': 42.5616,
    '1008_802': 42.5857,
    '1008_803': 42.6237,
    '1008_804': 42.6745,
    '1008_805': 42.7398,
    '1008_806': 42.8113,
    '1008_807': 42.8765,
    '1008_808': 42.9184,
    '1008_809': 42.9266,
    '1008_810': 42.9196,
    '1008_811': 42.8952,
    '1008_812': 42.8477,
    '1008_813': 42.7861,
    '1008_814': 42.7283,
    '1008_815': 42.6768,
    '1008_816': 42.6377,
    '1008_817': 42.5992,
    '1008_818': 42.5725,
    '1008_819': 42.5429,
    '1008_820': 42.4993,
    '1008_821': 42.4256,
    '1008_822': 42.3088,
    '1008_823': 42.1412,
    '1008_824': 41.9444,
    '1008_825': 41.7272,
    '1008_826': 41.4891,
    '1008_827': 41.2278,
    '1008_828': 40.933,
    '1008_829': 40.6485,
    '1008_830': 40.3711,
    '1008_831': 40.0841,
    '1008_832': 39.7943,
    '1008_833': 39.502,
    '1008_834': 39.2079,
    '1008_835': 38.9146,
    '1008_836': 38.6272,
    '1008_837': 38.3462,
    '1008_838': 38.0712,
    '1008_839': 37.8005,
    '1008_840': 37.5346,
    '1008_841': 37.2739,
    '1008_842': 37.0113,
    '1008_843': 36.7484,
    '1008_844': 36.4896,
    '1008_845': 36.2347,
    '1008_846': 35.9825,
    '1008_847': 35.7482,
    '1008_848': 35.5113,
    '1009_656': 36.5099,
    '1009_657': 36.5921,
    '1009_658': 36.6763,
    '1009_659': 36.7651,
    '1009_660': 36.8567,
    '1009_661': 36.9495,
    '1009_662': 37.0452,
    '1009_663': 37.1407,
    '1009_664': 37.2327,
    '1009_665': 37.3154,
    '1009_666': 37.3879,
    '1009_667': 37.4493,
    '1009_668': 37.5063,
    '1009_669': 37.5642,
    '1009_670': 37.6285,
    '1009_671': 37.7066,
    '1009_672': 37.7974,
    '1009_673': 37.8641,
    '1009_674': 37.898,
    '1009_675': 37.9065,
    '1009_676': 37.8987,
    '1009_677': 37.8827,
    '1009_678': 37.8609,
    '1009_679': 37.8449,
    '1009_680': 37.8304,
    '1009_681': 37.8137,
    '1009_682': 37.7888,
    '1009_683': 37.7606,
    '1009_684': 37.7384,
    '1009_685': 37.7255,
    '1009_686': 37.7214,
    '1009_687': 37.7311,
    '1009_688': 37.7602,
    '1009_689': 37.8014,
    '1009_690': 37.8567,
    '1009_691': 37.9296,
    '1009_692': 38.0195,
    '1009_693': 38.1261,
    '1009_694': 38.2491,
    '1009_695': 38.3895,
    '1009_696': 38.5514,
    '1009_697': 38.7378,
    '1009_698': 38.95,
    '1009_699': 39.1844,
    '1009_700': 39.4333,
    '1009_701': 39.6946,
    '1009_702': 39.9424,
    '1009_703': 40.145,
    '1009_704': 40.3493,
    '1009_705': 40.5647,
    '1009_706': 40.7811,
    '1009_707': 41.0071,
    '1009_708': 41.2029,
    '1009_709': 41.3661,
    '1009_710': 41.4949,
    '1009_711': 41.5808,
    '1009_712': 41.5956,
    '1009_713': 41.5747,
    '1009_714': 41.5595,
    '1009_715': 41.5342,
    '1009_716': 41.5076,
    '1009_717': 41.5367,
    '1009_718': 41.6241,
    '1009_719': 41.7337,
    '1009_720': 41.825,
    '1009_721': 41.897,
    '1009_722': 41.9236,
    '1009_723': 41.9308,
    '1009_724': 41.8761,
    '1009_725': 41.7879,
    '1009_726': 41.7179,
    '1009_727': 41.6175,
    '1009_728': 41.5458,
    '1009_729': 41.521,
    '1009_730': 41.5258,
    '1009_731': 41.5341,
    '1009_732': 41.5393,
    '1009_733': 41.5649,
    '1009_734': 41.6332,
    '1009_735': 41.7591,
    '1009_736': 41.9159,
    '1009_737': 42.0944,
    '1009_738': 42.3002,
    '1009_739': 42.5016,
    '1009_740': 42.6626,
    '1009_741': 42.8011,
    '1009_742': 42.9073,
    '1009_743': 42.9773,
    '1009_744': 43.0265,
    '1009_745': 43.0804,
    '1009_746': 43.1317,
    '1009_747': 43.1854,
    '1009_748': 43.2341,
    '1009_749': 43.251,
    '1009_750': 43.2394,
    '1009_751': 43.2429,
    '1009_752': 43.2584,
    '1009_753': 43.268,
    '1009_754': 43.2964,
    '1009_755': 43.3114,
    '1009_756': 43.3157,
    '1009_757': 43.3257,
    '1009_758': 43.3067,
    '1009_759': 43.3005,
    '1009_760': 43.3131,
    '1009_761': 43.3438,
    '1009_762': 43.3948,
    '1009_763': 43.4678,
    '1009_764': 43.5453,
    '1009_765': 43.6086,
    '1009_766': 43.6717,
    '1009_767': 43.7239,
    '1009_768': 43.7651,
    '1009_769': 43.7987,
    '1009_770': 43.8442,
    '1009_771': 43.899,
    '1009_772': 43.968,
    '1009_773': 44.031,
    '1009_774': 44.0732,
    '1009_775': 44.0792,
    '1009_776': 44.0651,
    '1009_777': 44.0519,
    '1009_778': 44.0127,
    '1009_779': 43.9709,
    '1009_780': 43.9365,
    '1009_781': 43.8731,
    '1009_782': 43.799,
    '1009_783': 43.7322,
    '1009_784': 43.6415,
    '1009_785': 43.5384,
    '1009_786': 43.4399,
    '1009_787': 43.3548,
    '1009_788': 43.2805,
    '1009_789': 43.217,
    '1009_790': 43.1631,
    '1009_791': 43.111,
    '1009_792': 43.0635,
    '1009_793': 43.0015,
    '1009_794': 42.9185,
    '1009_795': 42.8292,
    '1009_796': 42.7422,
    '1009_797': 42.6725,
    '1009_798': 42.6226,
    '1009_799': 42.5915,
    '1009_800': 42.5783,
    '1009_801': 42.5846,
    '1009_802': 42.6095,
    '1009_803': 42.6514,
    '1009_804': 42.7089,
    '1009_805': 42.7788,
    '1009_806': 42.8528,
    '1009_807': 42.9158,
    '1009_808': 42.9553,
    '1009_809': 42.969,
    '1009_810': 42.9653,
    '1009_811': 42.9435,
    '1009_812': 42.8999,
    '1009_813': 42.8382,
    '1009_814': 42.7793,
    '1009_815': 42.7319,
    '1009_816': 42.7009,
    '1009_817': 42.6687,
    '1009_818': 42.6443,
    '1009_819': 42.6227,
    '1009_820': 42.5844,
    '1009_821': 42.5151,
    '1009_822': 42.4033,
    '1009_823': 42.2484,
    '1009_824': 42.0571,
    '1009_825': 41.8413,
    '1009_826': 41.6071,
    '1009_827': 41.3456,
    '1009_828': 41.0548,
    '1009_829': 40.7711,
    '1009_830': 40.4932,
    '1009_831': 40.2029,
    '1009_832': 39.9134,
    '1009_833': 39.6225,
    '1009_834': 39.3304,
    '1009_835': 39.0395,
    '1009_836': 38.753,
    '1009_837': 38.4748,
    '1009_838': 38.2008,
    '1009_839': 37.9298,
    '1009_840': 37.6678,
    '1009_841': 37.4068,
    '1009_842': 37.142,
    '1009_843': 36.8804,
    '1009_844': 36.6204,
    '1009_845': 36.3633,
    '1009_846': 36.1119,
    '1009_847': 35.8791,
    '1009_848': 35.6442,
    '1010_656': 36.4904,
    '1010_657': 36.5661,
    '1010_658': 36.6518,
    '1010_659': 36.7469,
    '1010_660': 36.8383,
    '1010_661': 36.9297,
    '1010_662': 37.0229,
    '1010_663': 37.1176,
    '1010_664': 37.2112,
    '1010_665': 37.2939,
    '1010_666': 37.3626,
    '1010_667': 37.4184,
    '1010_668': 37.4706,
    '1010_669': 37.5228,
    '1010_670': 37.581,
    '1010_671': 37.6557,
    '1010_672': 37.7438,
    '1010_673': 37.8162,
    '1010_674': 37.8595,
    '1010_675': 37.8764,
    '1010_676': 37.8737,
    '1010_677': 37.8612,
    '1010_678': 37.8383,
    '1010_679': 37.8181,
    '1010_680': 37.7976,
    '1010_681': 37.7725,
    '1010_682': 37.741,
    '1010_683': 37.7051,
    '1010_684': 37.6735,
    '1010_685': 37.6537,
    '1010_686': 37.6433,
    '1010_687': 37.6458,
    '1010_688': 37.6648,
    '1010_689': 37.6969,
    '1010_690': 37.7447,
    '1010_691': 37.8134,
    '1010_692': 37.901,
    '1010_693': 38.0062,
    '1010_694': 38.1276,
    '1010_695': 38.2656,
    '1010_696': 38.4227,
    '1010_697': 38.5994,
    '1010_698': 38.7974,
    '1010_699': 39.0166,
    '1010_700': 39.255,
    '1010_701': 39.5079,
    '1010_702': 39.7543,
    '1010_703': 39.9747,
    '1010_704': 40.2057,
    '1010_705': 40.4372,
    '1010_706': 40.6566,
    '1010_707': 40.8821,
    '1010_708': 41.0793,
    '1010_709': 41.2243,
    '1010_710': 41.3424,
    '1010_711': 41.4323,
    '1010_712': 41.4527,
    '1010_713': 41.448,
    '1010_714': 41.4396,
    '1010_715': 41.4308,
    '1010_716': 41.4246,
    '1010_717': 41.4731,
    '1010_718': 41.5657,
    '1010_719': 41.6743,
    '1010_720': 41.7709,
    '1010_721': 41.8451,
    '1010_722': 41.875,
    '1010_723': 41.8764,
    '1010_724': 41.8395,
    '1010_725': 41.7583,
    '1010_726': 41.6665,
    '1010_727': 41.5629,
    '1010_728': 41.4953,
    '1010_729': 41.4771,
    '1010_730': 41.4877,
    '1010_731': 41.4882,
    '1010_732': 41.4911,
    '1010_733': 41.5014,
    '1010_734': 41.5526,
    '1010_735': 41.6552,
    '1010_736': 41.7994,
    '1010_737': 41.9829,
    '1010_738': 42.1954,
    '1010_739': 42.3927,
    '1010_740': 42.5603,
    '1010_741': 42.6949,
    '1010_742': 42.7942,
    '1010_743': 42.8515,
    '1010_744': 42.9118,
    '1010_745': 42.9739,
    '1010_746': 43.0389,
    '1010_747': 43.0819,
    '1010_748': 43.138,
    '1010_749': 43.1705,
    '1010_750': 43.1671,
    '1010_751': 43.1784,
    '1010_752': 43.2084,
    '1010_753': 43.2416,
    '1010_754': 43.273,
    '1010_755': 43.2996,
    '1010_756': 43.3292,
    '1010_757': 43.3284,
    '1010_758': 43.3429,
    '1010_759': 43.3466,
    '1010_760': 43.353,
    '1010_761': 43.3711,
    '1010_762': 43.4076,
    '1010_763': 43.4749,
    '1010_764': 43.5595,
    '1010_765': 43.6479,
    '1010_766': 43.7261,
    '1010_767': 43.778,
    '1010_768': 43.8184,
    '1010_769': 43.8684,
    '1010_770': 43.9105,
    '1010_771': 43.9524,
    '1010_772': 44,
    '1010_773': 44.0375,
    '1010_774': 44.0933,
    '1010_775': 44.0902,
    '1010_776': 44.0628,
    '1010_777': 44.0516,
    '1010_778': 44.0291,
    '1010_779': 43.9951,
    '1010_780': 43.9555,
    '1010_781': 43.9011,
    '1010_782': 43.8346,
    '1010_783': 43.7611,
    '1010_784': 43.6694,
    '1010_785': 43.5818,
    '1010_786': 43.503,
    '1010_787': 43.4279,
    '1010_788': 43.3588,
    '1010_789': 43.2959,
    '1010_790': 43.2459,
    '1010_791': 43.1975,
    '1010_792': 43.1465,
    '1010_793': 43.0759,
    '1010_794': 42.9829,
    '1010_795': 42.8787,
    '1010_796': 42.7826,
    '1010_797': 42.7075,
    '1010_798': 42.6519,
    '1010_799': 42.6183,
    '1010_800': 42.6045,
    '1010_801': 42.6104,
    '1010_802': 42.6353,
    '1010_803': 42.6794,
    '1010_804': 42.7417,
    '1010_805': 42.8181,
    '1010_806': 42.8972,
    '1010_807': 42.9566,
    '1010_808': 42.9943,
    '1010_809': 43.016,
    '1010_810': 43.0167,
    '1010_811': 42.9971,
    '1010_812': 42.9547,
    '1010_813': 42.8941,
    '1010_814': 42.8354,
    '1010_815': 42.7916,
    '1010_816': 42.7602,
    '1010_817': 42.7281,
    '1010_818': 42.7079,
    '1010_819': 42.6969,
    '1010_820': 42.6662,
    '1010_821': 42.6014,
    '1010_822': 42.4969,
    '1010_823': 42.3505,
    '1010_824': 42.1689,
    '1010_825': 41.957,
    '1010_826': 41.722,
    '1010_827': 41.4592,
    '1010_828': 41.1782,
    '1010_829': 40.8962,
    '1010_830': 40.6139,
    '1010_831': 40.3236,
    '1010_832': 40.0345,
    '1010_833': 39.7431,
    '1010_834': 39.4522,
    '1010_835': 39.164,
    '1010_836': 38.8832,
    '1010_837': 38.6075,
    '1010_838': 38.3328,
    '1010_839': 38.0583,
    '1010_840': 37.7981,
    '1010_841': 37.5381,
    '1010_842': 37.2705,
    '1010_843': 37.0037,
    '1010_844': 36.7462,
    '1010_845': 36.4918,
    '1010_846': 36.2435,
    '1010_847': 36.0099,
    '1010_848': 35.7778,
    '1011_656': 36.4727,
    '1011_657': 36.545,
    '1011_658': 36.6468,
    '1011_659': 36.7387,
    '1011_660': 36.8217,
    '1011_661': 36.9094,
    '1011_662': 36.9986,
    '1011_663': 37.0894,
    '1011_664': 37.1848,
    '1011_665': 37.2674,
    '1011_666': 37.3329,
    '1011_667': 37.3838,
    '1011_668': 37.4356,
    '1011_669': 37.4877,
    '1011_670': 37.5444,
    '1011_671': 37.6131,
    '1011_672': 37.6945,
    '1011_673': 37.7731,
    '1011_674': 37.8311,
    '1011_675': 37.8591,
    '1011_676': 37.8628,
    '1011_677': 37.8533,
    '1011_678': 37.8312,
    '1011_679': 37.807,
    '1011_680': 37.779,
    '1011_681': 37.7452,
    '1011_682': 37.7049,
    '1011_683': 37.6596,
    '1011_684': 37.6173,
    '1011_685': 37.5918,
    '1011_686': 37.5748,
    '1011_687': 37.57,
    '1011_688': 37.5784,
    '1011_689': 37.6,
    '1011_690': 37.6379,
    '1011_691': 37.7018,
    '1011_692': 37.7866,
    '1011_693': 37.8898,
    '1011_694': 38.0096,
    '1011_695': 38.1452,
    '1011_696': 38.2977,
    '1011_697': 38.4666,
    '1011_698': 38.6531,
    '1011_699': 38.8603,
    '1011_700': 39.0844,
    '1011_701': 39.3199,
    '1011_702': 39.5618,
    '1011_703': 39.8079,
    '1011_704': 40.0541,
    '1011_705': 40.2911,
    '1011_706': 40.5177,
    '1011_707': 40.7416,
    '1011_708': 40.9484,
    '1011_709': 41.0982,
    '1011_710': 41.1989,
    '1011_711': 41.2653,
    '1011_712': 41.2936,
    '1011_713': 41.298,
    '1011_714': 41.3026,
    '1011_715': 41.3132,
    '1011_716': 41.3382,
    '1011_717': 41.4109,
    '1011_718': 41.5009,
    '1011_719': 41.6134,
    '1011_720': 41.7114,
    '1011_721': 41.7826,
    '1011_722': 41.8244,
    '1011_723': 41.8218,
    '1011_724': 41.7847,
    '1011_725': 41.7107,
    '1011_726': 41.6072,
    '1011_727': 41.4989,
    '1011_728': 41.4295,
    '1011_729': 41.4082,
    '1011_730': 41.4185,
    '1011_731': 41.4217,
    '1011_732': 41.4194,
    '1011_733': 41.4232,
    '1011_734': 41.4612,
    '1011_735': 41.5452,
    '1011_736': 41.6831,
    '1011_737': 41.8729,
    '1011_738': 42.0915,
    '1011_739': 42.2864,
    '1011_740': 42.4415,
    '1011_741': 42.5733,
    '1011_742': 42.663,
    '1011_743': 42.7304,
    '1011_744': 42.7897,
    '1011_745': 42.8572,
    '1011_746': 42.9315,
    '1011_747': 43.0001,
    '1011_748': 43.0455,
    '1011_749': 43.078,
    '1011_750': 43.0856,
    '1011_751': 43.1009,
    '1011_752': 43.1293,
    '1011_753': 43.1686,
    '1011_754': 43.211,
    '1011_755': 43.2516,
    '1011_756': 43.2964,
    '1011_757': 43.3238,
    '1011_758': 43.3677,
    '1011_759': 43.3782,
    '1011_760': 43.3904,
    '1011_761': 43.4037,
    '1011_762': 43.4272,
    '1011_763': 43.4841,
    '1011_764': 43.5705,
    '1011_765': 43.6708,
    '1011_766': 43.7519,
    '1011_767': 43.8222,
    '1011_768': 43.8731,
    '1011_769': 43.933,
    '1011_770': 43.9685,
    '1011_771': 44.0089,
    '1011_772': 44.043,
    '1011_773': 44.0652,
    '1011_774': 44.0909,
    '1011_775': 44.1093,
    '1011_776': 44.0875,
    '1011_777': 44.0734,
    '1011_778': 44.0325,
    '1011_779': 43.992,
    '1011_780': 43.9452,
    '1011_781': 43.9008,
    '1011_782': 43.8404,
    '1011_783': 43.7662,
    '1011_784': 43.6849,
    '1011_785': 43.6095,
    '1011_786': 43.5472,
    '1011_787': 43.4895,
    '1011_788': 43.4261,
    '1011_789': 43.3728,
    '1011_790': 43.3356,
    '1011_791': 43.2931,
    '1011_792': 43.2345,
    '1011_793': 43.1542,
    '1011_794': 43.0506,
    '1011_795': 42.9347,
    '1011_796': 42.8295,
    '1011_797': 42.7484,
    '1011_798': 42.6858,
    '1011_799': 42.6485,
    '1011_800': 42.6329,
    '1011_801': 42.6378,
    '1011_802': 42.6633,
    '1011_803': 42.7081,
    '1011_804': 42.773,
    '1011_805': 42.8549,
    '1011_806': 42.9341,
    '1011_807': 42.9927,
    '1011_808': 43.0362,
    '1011_809': 43.0637,
    '1011_810': 43.0743,
    '1011_811': 43.0601,
    '1011_812': 43.0168,
    '1011_813': 42.9551,
    '1011_814': 42.8976,
    '1011_815': 42.8528,
    '1011_816': 42.812,
    '1011_817': 42.7823,
    '1011_818': 42.7703,
    '1011_819': 42.7664,
    '1011_820': 42.7407,
    '1011_821': 42.6836,
    '1011_822': 42.5857,
    '1011_823': 42.4476,
    '1011_824': 42.2735,
    '1011_825': 42.0643,
    '1011_826': 41.8277,
    '1011_827': 41.5684,
    '1011_828': 41.2998,
    '1011_829': 41.0179,
    '1011_830': 40.7331,
    '1011_831': 40.446,
    '1011_832': 40.156,
    '1011_833': 39.8642,
    '1011_834': 39.5742,
    '1011_835': 39.2881,
    '1011_836': 39.0139,
    '1011_837': 38.7428,
    '1011_838': 38.4713,
    '1011_839': 38.1968,
    '1011_840': 37.9339,
    '1011_841': 37.6693,
    '1011_842': 37.4023,
    '1011_843': 37.1372,
    '1011_844': 36.8752,
    '1011_845': 36.622,
    '1011_846': 36.3746,
    '1011_847': 36.1415,
    '1011_848': 35.9114,
    '1012_656': 36.4347,
    '1012_657': 36.5175,
    '1012_658': 36.6159,
    '1012_659': 36.7084,
    '1012_660': 36.7949,
    '1012_661': 36.8867,
    '1012_662': 36.9732,
    '1012_663': 37.0529,
    '1012_664': 37.1396,
    '1012_665': 37.2196,
    '1012_666': 37.2837,
    '1012_667': 37.3437,
    '1012_668': 37.4035,
    '1012_669': 37.4595,
    '1012_670': 37.5168,
    '1012_671': 37.5809,
    '1012_672': 37.655,
    '1012_673': 37.7368,
    '1012_674': 37.8044,
    '1012_675': 37.8457,
    '1012_676': 37.86,
    '1012_677': 37.8569,
    '1012_678': 37.8386,
    '1012_679': 37.8091,
    '1012_680': 37.7739,
    '1012_681': 37.7321,
    '1012_682': 37.6836,
    '1012_683': 37.6314,
    '1012_684': 37.5829,
    '1012_685': 37.5462,
    '1012_686': 37.5188,
    '1012_687': 37.5037,
    '1012_688': 37.5019,
    '1012_689': 37.5145,
    '1012_690': 37.545,
    '1012_691': 37.599,
    '1012_692': 37.6779,
    '1012_693': 37.7776,
    '1012_694': 37.8955,
    '1012_695': 38.0287,
    '1012_696': 38.177,
    '1012_697': 38.3384,
    '1012_698': 38.5134,
    '1012_699': 38.7054,
    '1012_700': 38.912,
    '1012_701': 39.1321,
    '1012_702': 39.3707,
    '1012_703': 39.6301,
    '1012_704': 39.8888,
    '1012_705': 40.1336,
    '1012_706': 40.3743,
    '1012_707': 40.5963,
    '1012_708': 40.8024,
    '1012_709': 40.9505,
    '1012_710': 41.0556,
    '1012_711': 41.0967,
    '1012_712': 41.1247,
    '1012_713': 41.1306,
    '1012_714': 41.1484,
    '1012_715': 41.1779,
    '1012_716': 41.2372,
    '1012_717': 41.3244,
    '1012_718': 41.4269,
    '1012_719': 41.5406,
    '1012_720': 41.6482,
    '1012_721': 41.7198,
    '1012_722': 41.7628,
    '1012_723': 41.7657,
    '1012_724': 41.7219,
    '1012_725': 41.6472,
    '1012_726': 41.5429,
    '1012_727': 41.4256,
    '1012_728': 41.3456,
    '1012_729': 41.3149,
    '1012_730': 41.3183,
    '1012_731': 41.3268,
    '1012_732': 41.3307,
    '1012_733': 41.3353,
    '1012_734': 41.3655,
    '1012_735': 41.4417,
    '1012_736': 41.5766,
    '1012_737': 41.7666,
    '1012_738': 41.9831,
    '1012_739': 42.1809,
    '1012_740': 42.3204,
    '1012_741': 42.4423,
    '1012_742': 42.5284,
    '1012_743': 42.6064,
    '1012_744': 42.6613,
    '1012_745': 42.7257,
    '1012_746': 42.8152,
    '1012_747': 42.9025,
    '1012_748': 42.945,
    '1012_749': 42.9855,
    '1012_750': 42.999,
    '1012_751': 43.009,
    '1012_752': 43.0413,
    '1012_753': 43.0768,
    '1012_754': 43.1236,
    '1012_755': 43.1844,
    '1012_756': 43.2567,
    '1012_757': 43.3124,
    '1012_758': 43.3714,
    '1012_759': 43.3874,
    '1012_760': 43.4174,
    '1012_761': 43.4262,
    '1012_762': 43.444,
    '1012_763': 43.4932,
    '1012_764': 43.5765,
    '1012_765': 43.6788,
    '1012_766': 43.7603,
    '1012_767': 43.8529,
    '1012_768': 43.9237,
    '1012_769': 43.972,
    '1012_770': 44.0063,
    '1012_771': 44.0356,
    '1012_772': 44.0729,
    '1012_773': 44.0985,
    '1012_774': 44.109,
    '1012_775': 44.1124,
    '1012_776': 44.1154,
    '1012_777': 44.0735,
    '1012_778': 44.0297,
    '1012_779': 43.9799,
    '1012_780': 43.9314,
    '1012_781': 43.8835,
    '1012_782': 43.8276,
    '1012_783': 43.7613,
    '1012_784': 43.6935,
    '1012_785': 43.634,
    '1012_786': 43.5826,
    '1012_787': 43.5321,
    '1012_788': 43.4822,
    '1012_789': 43.4472,
    '1012_790': 43.4274,
    '1012_791': 43.3918,
    '1012_792': 43.3278,
    '1012_793': 43.2353,
    '1012_794': 43.119,
    '1012_795': 42.9889,
    '1012_796': 42.8771,
    '1012_797': 42.7927,
    '1012_798': 42.7234,
    '1012_799': 42.6816,
    '1012_800': 42.6633,
    '1012_801': 42.6664,
    '1012_802': 42.6923,
    '1012_803': 42.7373,
    '1012_804': 42.8026,
    '1012_805': 42.8836,
    '1012_806': 42.9613,
    '1012_807': 43.0255,
    '1012_808': 43.0767,
    '1012_809': 43.1074,
    '1012_810': 43.1251,
    '1012_811': 43.1163,
    '1012_812': 43.0758,
    '1012_813': 43.0229,
    '1012_814': 42.9634,
    '1012_815': 42.911,
    '1012_816': 42.8676,
    '1012_817': 42.8352,
    '1012_818': 42.8263,
    '1012_819': 42.8274,
    '1012_820': 42.8031,
    '1012_821': 42.7518,
    '1012_822': 42.6622,
    '1012_823': 42.534,
    '1012_824': 42.3671,
    '1012_825': 42.1633,
    '1012_826': 41.9261,
    '1012_827': 41.6797,
    '1012_828': 41.4161,
    '1012_829': 41.1357,
    '1012_830': 40.8511,
    '1012_831': 40.565,
    '1012_832': 40.2734,
    '1012_833': 39.9841,
    '1012_834': 39.6977,
    '1012_835': 39.4157,
    '1012_836': 39.142,
    '1012_837': 38.8714,
    '1012_838': 38.6008,
    '1012_839': 38.3308,
    '1012_840': 38.0626,
    '1012_841': 37.797,
    '1012_842': 37.5281,
    '1012_843': 37.2594,
    '1012_844': 36.9943,
    '1012_845': 36.7454,
    '1012_846': 36.5026,
    '1012_847': 36.2662,
    '1012_848': 36.036,
    '1013_656': 36.4014,
    '1013_657': 36.4931,
    '1013_658': 36.5903,
    '1013_659': 36.6858,
    '1013_660': 36.7776,
    '1013_661': 36.8695,
    '1013_662': 36.9576,
    '1013_663': 37.0416,
    '1013_664': 37.1246,
    '1013_665': 37.1997,
    '1013_666': 37.2644,
    '1013_667': 37.3261,
    '1013_668': 37.3834,
    '1013_669': 37.4392,
    '1013_670': 37.4959,
    '1013_671': 37.5564,
    '1013_672': 37.6237,
    '1013_673': 37.7038,
    '1013_674': 37.783,
    '1013_675': 37.8327,
    '1013_676': 37.858,
    '1013_677': 37.8655,
    '1013_678': 37.8537,
    '1013_679': 37.8229,
    '1013_680': 37.7806,
    '1013_681': 37.7295,
    '1013_682': 37.6722,
    '1013_683': 37.6119,
    '1013_684': 37.5553,
    '1013_685': 37.5074,
    '1013_686': 37.4685,
    '1013_687': 37.4426,
    '1013_688': 37.4309,
    '1013_689': 37.4347,
    '1013_690': 37.4571,
    '1013_691': 37.5024,
    '1013_692': 37.5736,
    '1013_693': 37.669,
    '1013_694': 37.7845,
    '1013_695': 37.9152,
    '1013_696': 38.059,
    '1013_697': 38.2131,
    '1013_698': 38.3773,
    '1013_699': 38.5551,
    '1013_700': 38.7463,
    '1013_701': 38.953,
    '1013_702': 39.1837,
    '1013_703': 39.4424,
    '1013_704': 39.7073,
    '1013_705': 39.9658,
    '1013_706': 40.2212,
    '1013_707': 40.4452,
    '1013_708': 40.639,
    '1013_709': 40.7802,
    '1013_710': 40.8812,
    '1013_711': 40.9254,
    '1013_712': 40.943,
    '1013_713': 40.9552,
    '1013_714': 40.9754,
    '1013_715': 41.0216,
    '1013_716': 41.1094,
    '1013_717': 41.2185,
    '1013_718': 41.3398,
    '1013_719': 41.4561,
    '1013_720': 41.5596,
    '1013_721': 41.6369,
    '1013_722': 41.6938,
    '1013_723': 41.6904,
    '1013_724': 41.6276,
    '1013_725': 41.5587,
    '1013_726': 41.4706,
    '1013_727': 41.3431,
    '1013_728': 41.2471,
    '1013_729': 41.2035,
    '1013_730': 41.2009,
    '1013_731': 41.2142,
    '1013_732': 41.2278,
    '1013_733': 41.2417,
    '1013_734': 41.2726,
    '1013_735': 41.3436,
    '1013_736': 41.4739,
    '1013_737': 41.66,
    '1013_738': 41.865,
    '1013_739': 42.0461,
    '1013_740': 42.1806,
    '1013_741': 42.2942,
    '1013_742': 42.3815,
    '1013_743': 42.4495,
    '1013_744': 42.5114,
    '1013_745': 42.5827,
    '1013_746': 42.6797,
    '1013_747': 42.7577,
    '1013_748': 42.8305,
    '1013_749': 42.8724,
    '1013_750': 42.891,
    '1013_751': 42.8998,
    '1013_752': 42.9316,
    '1013_753': 42.9733,
    '1013_754': 43.0228,
    '1013_755': 43.0982,
    '1013_756': 43.1888,
    '1013_757': 43.2702,
    '1013_758': 43.342,
    '1013_759': 43.3776,
    '1013_760': 43.4155,
    '1013_761': 43.4316,
    '1013_762': 43.4551,
    '1013_763': 43.5028,
    '1013_764': 43.583,
    '1013_765': 43.6871,
    '1013_766': 43.7862,
    '1013_767': 43.8604,
    '1013_768': 43.9394,
    '1013_769': 43.9855,
    '1013_770': 44.0271,
    '1013_771': 44.0463,
    '1013_772': 44.0847,
    '1013_773': 44.1085,
    '1013_774': 44.1151,
    '1013_775': 44.1109,
    '1013_776': 44.1168,
    '1013_777': 44.08,
    '1013_778': 44.0297,
    '1013_779': 43.9789,
    '1013_780': 43.9255,
    '1013_781': 43.8747,
    '1013_782': 43.8199,
    '1013_783': 43.7644,
    '1013_784': 43.7049,
    '1013_785': 43.6545,
    '1013_786': 43.609,
    '1013_787': 43.5648,
    '1013_788': 43.5284,
    '1013_789': 43.5126,
    '1013_790': 43.5042,
    '1013_791': 43.4707,
    '1013_792': 43.4107,
    '1013_793': 43.3099,
    '1013_794': 43.1857,
    '1013_795': 43.0451,
    '1013_796': 42.9202,
    '1013_797': 42.828,
    '1013_798': 42.7596,
    '1013_799': 42.7156,
    '1013_800': 42.6964,
    '1013_801': 42.6994,
    '1013_802': 42.7244,
    '1013_803': 42.7692,
    '1013_804': 42.8336,
    '1013_805': 42.9114,
    '1013_806': 42.984,
    '1013_807': 43.0525,
    '1013_808': 43.1097,
    '1013_809': 43.1448,
    '1013_810': 43.1681,
    '1013_811': 43.162,
    '1013_812': 43.1342,
    '1013_813': 43.0922,
    '1013_814': 43.0305,
    '1013_815': 42.9688,
    '1013_816': 42.9224,
    '1013_817': 42.8909,
    '1013_818': 42.8868,
    '1013_819': 42.8828,
    '1013_820': 42.8597,
    '1013_821': 42.812,
    '1013_822': 42.7276,
    '1013_823': 42.6068,
    '1013_824': 42.4503,
    '1013_825': 42.2555,
    '1013_826': 42.0271,
    '1013_827': 41.7879,
    '1013_828': 41.5277,
    '1013_829': 41.2475,
    '1013_830': 40.9603,
    '1013_831': 40.6752,
    '1013_832': 40.3851,
    '1013_833': 40.0994,
    '1013_834': 39.8173,
    '1013_835': 39.539,
    '1013_836': 39.2662,
    '1013_837': 38.9957,
    '1013_838': 38.7259,
    '1013_839': 38.4595,
    '1013_840': 38.1876,
    '1013_841': 37.9213,
    '1013_842': 37.6502,
    '1013_843': 37.3788,
    '1013_844': 37.1133,
    '1013_845': 36.8654,
    '1013_846': 36.6189,
    '1013_847': 36.3829,
    '1013_848': 36.1547,
    '1014_656': 36.3866,
    '1014_657': 36.4794,
    '1014_658': 36.5752,
    '1014_659': 36.6704,
    '1014_660': 36.7634,
    '1014_661': 36.856,
    '1014_662': 36.9476,
    '1014_663': 37.0372,
    '1014_664': 37.1224,
    '1014_665': 37.1937,
    '1014_666': 37.2522,
    '1014_667': 37.3059,
    '1014_668': 37.3656,
    '1014_669': 37.4235,
    '1014_670': 37.4799,
    '1014_671': 37.5383,
    '1014_672': 37.5998,
    '1014_673': 37.6731,
    '1014_674': 37.7616,
    '1014_675': 37.8253,
    '1014_676': 37.8581,
    '1014_677': 37.8753,
    '1014_678': 37.871,
    '1014_679': 37.8419,
    '1014_680': 37.7951,
    '1014_681': 37.7331,
    '1014_682': 37.6659,
    '1014_683': 37.5964,
    '1014_684': 37.5296,
    '1014_685': 37.4702,
    '1014_686': 37.4199,
    '1014_687': 37.3836,
    '1014_688': 37.3627,
    '1014_689': 37.3577,
    '1014_690': 37.3714,
    '1014_691': 37.4082,
    '1014_692': 37.4731,
    '1014_693': 37.5656,
    '1014_694': 37.6778,
    '1014_695': 37.8049,
    '1014_696': 37.9443,
    '1014_697': 38.0908,
    '1014_698': 38.2452,
    '1014_699': 38.4118,
    '1014_700': 38.5903,
    '1014_701': 38.7832,
    '1014_702': 39.0008,
    '1014_703': 39.2499,
    '1014_704': 39.5233,
    '1014_705': 39.801,
    '1014_706': 40.0577,
    '1014_707': 40.2768,
    '1014_708': 40.4576,
    '1014_709': 40.5962,
    '1014_710': 40.6894,
    '1014_711': 40.7398,
    '1014_712': 40.7633,
    '1014_713': 40.7774,
    '1014_714': 40.8022,
    '1014_715': 40.8563,
    '1014_716': 40.9556,
    '1014_717': 41.0779,
    '1014_718': 41.2089,
    '1014_719': 41.3384,
    '1014_720': 41.4499,
    '1014_721': 41.5299,
    '1014_722': 41.6008,
    '1014_723': 41.5909,
    '1014_724': 41.5302,
    '1014_725': 41.477,
    '1014_726': 41.3814,
    '1014_727': 41.2462,
    '1014_728': 41.1381,
    '1014_729': 41.0783,
    '1014_730': 41.0681,
    '1014_731': 41.0853,
    '1014_732': 41.1168,
    '1014_733': 41.1491,
    '1014_734': 41.1882,
    '1014_735': 41.25,
    '1014_736': 41.3723,
    '1014_737': 41.5475,
    '1014_738': 41.7404,
    '1014_739': 41.9035,
    '1014_740': 42.03,
    '1014_741': 42.1329,
    '1014_742': 42.2068,
    '1014_743': 42.2728,
    '1014_744': 42.3411,
    '1014_745': 42.4222,
    '1014_746': 42.519,
    '1014_747': 42.6093,
    '1014_748': 42.6948,
    '1014_749': 42.7357,
    '1014_750': 42.7599,
    '1014_751': 42.7774,
    '1014_752': 42.8071,
    '1014_753': 42.8491,
    '1014_754': 42.9089,
    '1014_755': 43.0064,
    '1014_756': 43.1202,
    '1014_757': 43.2187,
    '1014_758': 43.307,
    '1014_759': 43.3681,
    '1014_760': 43.4109,
    '1014_761': 43.4318,
    '1014_762': 43.4565,
    '1014_763': 43.5078,
    '1014_764': 43.5936,
    '1014_765': 43.6986,
    '1014_766': 43.7995,
    '1014_767': 43.8601,
    '1014_768': 43.9477,
    '1014_769': 43.9832,
    '1014_770': 44.0206,
    '1014_771': 44.0546,
    '1014_772': 44.0863,
    '1014_773': 44.1072,
    '1014_774': 44.1227,
    '1014_775': 44.1163,
    '1014_776': 44.1024,
    '1014_777': 44.0878,
    '1014_778': 44.0349,
    '1014_779': 43.9819,
    '1014_780': 43.9358,
    '1014_781': 43.8854,
    '1014_782': 43.8305,
    '1014_783': 43.7806,
    '1014_784': 43.7296,
    '1014_785': 43.6809,
    '1014_786': 43.6337,
    '1014_787': 43.5937,
    '1014_788': 43.5677,
    '1014_789': 43.5677,
    '1014_790': 43.5623,
    '1014_791': 43.5269,
    '1014_792': 43.4694,
    '1014_793': 43.3741,
    '1014_794': 43.2495,
    '1014_795': 43.1013,
    '1014_796': 42.9664,
    '1014_797': 42.8677,
    '1014_798': 42.7978,
    '1014_799': 42.754,
    '1014_800': 42.7332,
    '1014_801': 42.734,
    '1014_802': 42.7579,
    '1014_803': 42.8033,
    '1014_804': 42.8647,
    '1014_805': 42.9352,
    '1014_806': 43.003,
    '1014_807': 43.0705,
    '1014_808': 43.133,
    '1014_809': 43.1768,
    '1014_810': 43.2061,
    '1014_811': 43.207,
    '1014_812': 43.19,
    '1014_813': 43.1533,
    '1014_814': 43.091,
    '1014_815': 43.0229,
    '1014_816': 42.9723,
    '1014_817': 42.944,
    '1014_818': 42.9421,
    '1014_819': 42.9343,
    '1014_820': 42.9103,
    '1014_821': 42.8613,
    '1014_822': 42.7822,
    '1014_823': 42.6688,
    '1014_824': 42.5213,
    '1014_825': 42.3358,
    '1014_826': 42.1216,
    '1014_827': 41.8877,
    '1014_828': 41.629,
    '1014_829': 41.3479,
    '1014_830': 41.0636,
    '1014_831': 40.7808,
    '1014_832': 40.4934,
    '1014_833': 40.2112,
    '1014_834': 39.9327,
    '1014_835': 39.6577,
    '1014_836': 39.3869,
    '1014_837': 39.1176,
    '1014_838': 38.8487,
    '1014_839': 38.5806,
    '1014_840': 38.31,
    '1014_841': 38.0411,
    '1014_842': 37.7654,
    '1014_843': 37.4906,
    '1014_844': 37.2258,
    '1014_845': 36.9794,
    '1014_846': 36.737,
    '1014_847': 36.5009,
    '1014_848': 36.2734,
    '1015_656': 36.3802,
    '1015_657': 36.4695,
    '1015_658': 36.5633,
    '1015_659': 36.657,
    '1015_660': 36.7474,
    '1015_661': 36.8402,
    '1015_662': 36.9348,
    '1015_663': 37.0284,
    '1015_664': 37.1199,
    '1015_665': 37.1906,
    '1015_666': 37.247,
    '1015_667': 37.301,
    '1015_668': 37.357,
    '1015_669': 37.4119,
    '1015_670': 37.469,
    '1015_671': 37.5284,
    '1015_672': 37.5851,
    '1015_673': 37.6472,
    '1015_674': 37.7266,
    '1015_675': 37.8062,
    '1015_676': 37.8573,
    '1015_677': 37.8843,
    '1015_678': 37.8863,
    '1015_679': 37.8622,
    '1015_680': 37.81,
    '1015_681': 37.7399,
    '1015_682': 37.6631,
    '1015_683': 37.5839,
    '1015_684': 37.5059,
    '1015_685': 37.4342,
    '1015_686': 37.3719,
    '1015_687': 37.3259,
    '1015_688': 37.2975,
    '1015_689': 37.2853,
    '1015_690': 37.2916,
    '1015_691': 37.322,
    '1015_692': 37.3834,
    '1015_693': 37.4697,
    '1015_694': 37.5766,
    '1015_695': 37.6983,
    '1015_696': 37.8311,
    '1015_697': 37.9712,
    '1015_698': 38.1177,
    '1015_699': 38.2744,
    '1015_700': 38.4413,
    '1015_701': 38.6215,
    '1015_702': 38.8252,
    '1015_703': 39.0648,
    '1015_704': 39.345,
    '1015_705': 39.6239,
    '1015_706': 39.8718,
    '1015_707': 40.0859,
    '1015_708': 40.2678,
    '1015_709': 40.4004,
    '1015_710': 40.4873,
    '1015_711': 40.5452,
    '1015_712': 40.576,
    '1015_713': 40.5934,
    '1015_714': 40.6226,
    '1015_715': 40.6841,
    '1015_716': 40.7832,
    '1015_717': 40.9157,
    '1015_718': 41.0707,
    '1015_719': 41.2121,
    '1015_720': 41.3319,
    '1015_721': 41.3983,
    '1015_722': 41.4496,
    '1015_723': 41.447,
    '1015_724': 41.4365,
    '1015_725': 41.3749,
    '1015_726': 41.2653,
    '1015_727': 41.1355,
    '1015_728': 41.023,
    '1015_729': 40.9548,
    '1015_730': 40.9366,
    '1015_731': 40.9534,
    '1015_732': 40.9968,
    '1015_733': 41.0515,
    '1015_734': 41.1072,
    '1015_735': 41.1692,
    '1015_736': 41.2761,
    '1015_737': 41.4334,
    '1015_738': 41.6115,
    '1015_739': 41.7602,
    '1015_740': 41.8756,
    '1015_741': 41.9606,
    '1015_742': 42.0207,
    '1015_743': 42.0819,
    '1015_744': 42.159,
    '1015_745': 42.2473,
    '1015_746': 42.3454,
    '1015_747': 42.4412,
    '1015_748': 42.5273,
    '1015_749': 42.583,
    '1015_750': 42.6227,
    '1015_751': 42.6488,
    '1015_752': 42.6767,
    '1015_753': 42.7198,
    '1015_754': 42.7953,
    '1015_755': 42.9078,
    '1015_756': 43.0362,
    '1015_757': 43.1518,
    '1015_758': 43.2619,
    '1015_759': 43.3317,
    '1015_760': 43.3818,
    '1015_761': 43.4106,
    '1015_762': 43.4506,
    '1015_763': 43.5116,
    '1015_764': 43.5974,
    '1015_765': 43.6998,
    '1015_766': 43.8074,
    '1015_767': 43.8797,
    '1015_768': 43.9533,
    '1015_769': 43.9959,
    '1015_770': 44.0307,
    '1015_771': 44.0552,
    '1015_772': 44.0729,
    '1015_773': 44.0908,
    '1015_774': 44.1025,
    '1015_775': 44.1109,
    '1015_776': 44.1,
    '1015_777': 44.0837,
    '1015_778': 44.0483,
    '1015_779': 44.007,
    '1015_780': 43.9508,
    '1015_781': 43.8985,
    '1015_782': 43.8459,
    '1015_783': 43.7959,
    '1015_784': 43.7501,
    '1015_785': 43.704,
    '1015_786': 43.6565,
    '1015_787': 43.6197,
    '1015_788': 43.6015,
    '1015_789': 43.6057,
    '1015_790': 43.6035,
    '1015_791': 43.574,
    '1015_792': 43.5191,
    '1015_793': 43.4268,
    '1015_794': 43.3074,
    '1015_795': 43.161,
    '1015_796': 43.0202,
    '1015_797': 42.9169,
    '1015_798': 42.8448,
    '1015_799': 42.7984,
    '1015_800': 42.775,
    '1015_801': 42.7732,
    '1015_802': 42.7953,
    '1015_803': 42.8392,
    '1015_804': 42.8965,
    '1015_805': 42.9597,
    '1015_806': 43.0216,
    '1015_807': 43.0868,
    '1015_808': 43.1522,
    '1015_809': 43.2036,
    '1015_810': 43.2385,
    '1015_811': 43.2508,
    '1015_812': 43.238,
    '1015_813': 43.2011,
    '1015_814': 43.1409,
    '1015_815': 43.075,
    '1015_816': 43.0218,
    '1015_817': 42.9945,
    '1015_818': 42.9909,
    '1015_819': 42.9835,
    '1015_820': 42.9635,
    '1015_821': 42.9148,
    '1015_822': 42.8367,
    '1015_823': 42.7278,
    '1015_824': 42.5864,
    '1015_825': 42.409,
    '1015_826': 42.2028,
    '1015_827': 41.9748,
    '1015_828': 41.7217,
    '1015_829': 41.4463,
    '1015_830': 41.1655,
    '1015_831': 40.8855,
    '1015_832': 40.6015,
    '1015_833': 40.3241,
    '1015_834': 40.0479,
    '1015_835': 39.7747,
    '1015_836': 39.5058,
    '1015_837': 39.2374,
    '1015_838': 38.9678,
    '1015_839': 38.6972,
    '1015_840': 38.4238,
    '1015_841': 38.1544,
    '1015_842': 37.8767,
    '1015_843': 37.6026,
    '1015_844': 37.3495,
    '1015_845': 37.0984,
    '1015_846': 36.855,
    '1015_847': 36.6193,
    '1015_848': 36.3906,
    '1016_656': 36.3606,
    '1016_657': 36.4483,
    '1016_658': 36.5425,
    '1016_659': 36.635,
    '1016_660': 36.7203,
    '1016_661': 36.8123,
    '1016_662': 36.9079,
    '1016_663': 36.9986,
    '1016_664': 37.0882,
    '1016_665': 37.1664,
    '1016_666': 37.2313,
    '1016_667': 37.2897,
    '1016_668': 37.3453,
    '1016_669': 37.3987,
    '1016_670': 37.4547,
    '1016_671': 37.5158,
    '1016_672': 37.5765,
    '1016_673': 37.6311,
    '1016_674': 37.6949,
    '1016_675': 37.7705,
    '1016_676': 37.8365,
    '1016_677': 37.8854,
    '1016_678': 37.8979,
    '1016_679': 37.8815,
    '1016_680': 37.8278,
    '1016_681': 37.7505,
    '1016_682': 37.6644,
    '1016_683': 37.5743,
    '1016_684': 37.4839,
    '1016_685': 37.3984,
    '1016_686': 37.3228,
    '1016_687': 37.2678,
    '1016_688': 37.2345,
    '1016_689': 37.2177,
    '1016_690': 37.2205,
    '1016_691': 37.2478,
    '1016_692': 37.3018,
    '1016_693': 37.3794,
    '1016_694': 37.4804,
    '1016_695': 37.5959,
    '1016_696': 37.7212,
    '1016_697': 37.854,
    '1016_698': 37.9928,
    '1016_699': 38.1401,
    '1016_700': 38.2962,
    '1016_701': 38.4648,
    '1016_702': 38.6548,
    '1016_703': 38.8846,
    '1016_704': 39.1569,
    '1016_705': 39.428,
    '1016_706': 39.6632,
    '1016_707': 39.865,
    '1016_708': 40.0452,
    '1016_709': 40.1841,
    '1016_710': 40.2794,
    '1016_711': 40.3434,
    '1016_712': 40.3888,
    '1016_713': 40.4133,
    '1016_714': 40.4344,
    '1016_715': 40.4965,
    '1016_716': 40.5922,
    '1016_717': 40.7328,
    '1016_718': 40.8936,
    '1016_719': 41.0445,
    '1016_720': 41.1683,
    '1016_721': 41.2512,
    '1016_722': 41.3198,
    '1016_723': 41.3176,
    '1016_724': 41.3077,
    '1016_725': 41.2493,
    '1016_726': 41.1359,
    '1016_727': 41.0103,
    '1016_728': 40.9015,
    '1016_729': 40.8305,
    '1016_730': 40.8057,
    '1016_731': 40.8227,
    '1016_732': 40.876,
    '1016_733': 40.9515,
    '1016_734': 41.0237,
    '1016_735': 41.0969,
    '1016_736': 41.1844,
    '1016_737': 41.3176,
    '1016_738': 41.4738,
    '1016_739': 41.6106,
    '1016_740': 41.7105,
    '1016_741': 41.7784,
    '1016_742': 41.8314,
    '1016_743': 41.8911,
    '1016_744': 41.9705,
    '1016_745': 42.0612,
    '1016_746': 42.1567,
    '1016_747': 42.2518,
    '1016_748': 42.3411,
    '1016_749': 42.4189,
    '1016_750': 42.4844,
    '1016_751': 42.5174,
    '1016_752': 42.548,
    '1016_753': 42.6022,
    '1016_754': 42.6964,
    '1016_755': 42.8174,
    '1016_756': 42.9468,
    '1016_757': 43.0788,
    '1016_758': 43.2007,
    '1016_759': 43.286,
    '1016_760': 43.3592,
    '1016_761': 43.4004,
    '1016_762': 43.449,
    '1016_763': 43.5176,
    '1016_764': 43.6075,
    '1016_765': 43.6986,
    '1016_766': 43.8003,
    '1016_767': 43.8754,
    '1016_768': 43.934,
    '1016_769': 43.9861,
    '1016_770': 44.0213,
    '1016_771': 44.0429,
    '1016_772': 44.0589,
    '1016_773': 44.0737,
    '1016_774': 44.0914,
    '1016_775': 44.1122,
    '1016_776': 44.094,
    '1016_777': 44.0766,
    '1016_778': 44.0548,
    '1016_779': 44.0124,
    '1016_780': 43.9557,
    '1016_781': 43.9063,
    '1016_782': 43.8528,
    '1016_783': 43.8054,
    '1016_784': 43.7554,
    '1016_785': 43.7177,
    '1016_786': 43.6778,
    '1016_787': 43.6436,
    '1016_788': 43.6278,
    '1016_789': 43.6333,
    '1016_790': 43.6344,
    '1016_791': 43.5992,
    '1016_792': 43.5444,
    '1016_793': 43.4642,
    '1016_794': 43.3531,
    '1016_795': 43.2206,
    '1016_796': 43.0803,
    '1016_797': 42.9738,
    '1016_798': 42.8973,
    '1016_799': 42.8474,
    '1016_800': 42.8216,
    '1016_801': 42.8185,
    '1016_802': 42.8395,
    '1016_803': 42.8803,
    '1016_804': 42.93,
    '1016_805': 42.9807,
    '1016_806': 43.0385,
    '1016_807': 43.1,
    '1016_808': 43.1651,
    '1016_809': 43.2234,
    '1016_810': 43.2633,
    '1016_811': 43.2815,
    '1016_812': 43.2714,
    '1016_813': 43.2337,
    '1016_814': 43.1791,
    '1016_815': 43.1171,
    '1016_816': 43.0609,
    '1016_817': 43.0363,
    '1016_818': 43.0325,
    '1016_819': 43.0323,
    '1016_820': 43.0132,
    '1016_821': 42.9665,
    '1016_822': 42.89,
    '1016_823': 42.7842,
    '1016_824': 42.6484,
    '1016_825': 42.478,
    '1016_826': 42.2757,
    '1016_827': 42.0544,
    '1016_828': 41.8105,
    '1016_829': 41.5434,
    '1016_830': 41.267,
    '1016_831': 40.9892,
    '1016_832': 40.7111,
    '1016_833': 40.4384,
    '1016_834': 40.164,
    '1016_835': 39.8919,
    '1016_836': 39.6235,
    '1016_837': 39.3555,
    '1016_838': 39.0834,
    '1016_839': 38.807,
    '1016_840': 38.5273,
    '1016_841': 38.2532,
    '1016_842': 37.9743,
    '1016_843': 37.7059,
    '1016_844': 37.4495,
    '1016_845': 37.2029,
    '1016_846': 36.961,
    '1016_847': 36.728,
    '1016_848': 36.4999,
    '1017_656': 36.3243,
    '1017_657': 36.4112,
    '1017_658': 36.5016,
    '1017_659': 36.5912,
    '1017_660': 36.6785,
    '1017_661': 36.7676,
    '1017_662': 36.8635,
    '1017_663': 36.9596,
    '1017_664': 37.0542,
    '1017_665': 37.1417,
    '1017_666': 37.2145,
    '1017_667': 37.2774,
    '1017_668': 37.3336,
    '1017_669': 37.3855,
    '1017_670': 37.4377,
    '1017_671': 37.4946,
    '1017_672': 37.5575,
    '1017_673': 37.6224,
    '1017_674': 37.6788,
    '1017_675': 37.7374,
    '1017_676': 37.8046,
    '1017_677': 37.8687,
    '1017_678': 37.9005,
    '1017_679': 37.893,
    '1017_680': 37.8431,
    '1017_681': 37.7643,
    '1017_682': 37.6695,
    '1017_683': 37.568,
    '1017_684': 37.4653,
    '1017_685': 37.3657,
    '1017_686': 37.275,
    '1017_687': 37.2102,
    '1017_688': 37.1716,
    '1017_689': 37.1522,
    '1017_690': 37.1515,
    '1017_691': 37.1732,
    '1017_692': 37.2234,
    '1017_693': 37.2968,
    '1017_694': 37.3906,
    '1017_695': 37.4989,
    '1017_696': 37.6157,
    '1017_697': 37.7403,
    '1017_698': 37.8715,
    '1017_699': 38.0096,
    '1017_700': 38.1547,
    '1017_701': 38.311,
    '1017_702': 38.4866,
    '1017_703': 38.6976,
    '1017_704': 38.946,
    '1017_705': 39.2013,
    '1017_706': 39.4245,
    '1017_707': 39.6247,
    '1017_708': 39.8072,
    '1017_709': 39.9476,
    '1017_710': 40.0456,
    '1017_711': 40.1265,
    '1017_712': 40.1896,
    '1017_713': 40.2254,
    '1017_714': 40.246,
    '1017_715': 40.2977,
    '1017_716': 40.3872,
    '1017_717': 40.5346,
    '1017_718': 40.7004,
    '1017_719': 40.8478,
    '1017_720': 40.9669,
    '1017_721': 41.0661,
    '1017_722': 41.1333,
    '1017_723': 41.1619,
    '1017_724': 41.1483,
    '1017_725': 41.0927,
    '1017_726': 40.9992,
    '1017_727': 40.8831,
    '1017_728': 40.7768,
    '1017_729': 40.7076,
    '1017_730': 40.6814,
    '1017_731': 40.6952,
    '1017_732': 40.756,
    '1017_733': 40.8452,
    '1017_734': 40.9371,
    '1017_735': 41.0213,
    '1017_736': 41.0994,
    '1017_737': 41.2044,
    '1017_738': 41.3332,
    '1017_739': 41.4526,
    '1017_740': 41.5397,
    '1017_741': 41.599,
    '1017_742': 41.6492,
    '1017_743': 41.7076,
    '1017_744': 41.7843,
    '1017_745': 41.8733,
    '1017_746': 41.9625,
    '1017_747': 42.0505,
    '1017_748': 42.1457,
    '1017_749': 42.2402,
    '1017_750': 42.3221,
    '1017_751': 42.3762,
    '1017_752': 42.4194,
    '1017_753': 42.4962,
    '1017_754': 42.6042,
    '1017_755': 42.7262,
    '1017_756': 42.8578,
    '1017_757': 43.0018,
    '1017_758': 43.1451,
    '1017_759': 43.2466,
    '1017_760': 43.3331,
    '1017_761': 43.3915,
    '1017_762': 43.4476,
    '1017_763': 43.5183,
    '1017_764': 43.6069,
    '1017_765': 43.6984,
    '1017_766': 43.7788,
    '1017_767': 43.8509,
    '1017_768': 43.9038,
    '1017_769': 43.9479,
    '1017_770': 43.9875,
    '1017_771': 44.0016,
    '1017_772': 44.0175,
    '1017_773': 44.0547,
    '1017_774': 44.0749,
    '1017_775': 44.0798,
    '1017_776': 44.0701,
    '1017_777': 44.0628,
    '1017_778': 44.0285,
    '1017_779': 44.0056,
    '1017_780': 43.9661,
    '1017_781': 43.9042,
    '1017_782': 43.8537,
    '1017_783': 43.7992,
    '1017_784': 43.7562,
    '1017_785': 43.7243,
    '1017_786': 43.6942,
    '1017_787': 43.6676,
    '1017_788': 43.6538,
    '1017_789': 43.659,
    '1017_790': 43.6508,
    '1017_791': 43.6183,
    '1017_792': 43.5659,
    '1017_793': 43.4982,
    '1017_794': 43.4068,
    '1017_795': 43.2875,
    '1017_796': 43.1487,
    '1017_797': 43.0387,
    '1017_798': 42.9571,
    '1017_799': 42.9016,
    '1017_800': 42.8722,
    '1017_801': 42.8661,
    '1017_802': 42.886,
    '1017_803': 42.9269,
    '1017_804': 42.972,
    '1017_805': 43.0097,
    '1017_806': 43.0571,
    '1017_807': 43.1133,
    '1017_808': 43.1742,
    '1017_809': 43.2334,
    '1017_810': 43.2784,
    '1017_811': 43.2986,
    '1017_812': 43.2865,
    '1017_813': 43.2482,
    '1017_814': 43.1986,
    '1017_815': 43.14,
    '1017_816': 43.089,
    '1017_817': 43.0689,
    '1017_818': 43.0694,
    '1017_819': 43.0766,
    '1017_820': 43.0604,
    '1017_821': 43.0172,
    '1017_822': 42.9433,
    '1017_823': 42.8431,
    '1017_824': 42.7127,
    '1017_825': 42.5501,
    '1017_826': 42.3554,
    '1017_827': 42.1391,
    '1017_828': 41.8996,
    '1017_829': 41.6421,
    '1017_830': 41.3707,
    '1017_831': 41.0984,
    '1017_832': 40.8252,
    '1017_833': 40.5513,
    '1017_834': 40.2799,
    '1017_835': 40.0091,
    '1017_836': 39.7415,
    '1017_837': 39.4753,
    '1017_838': 39.2011,
    '1017_839': 38.9188,
    '1017_840': 38.6331,
    '1017_841': 38.3527,
    '1017_842': 38.0736,
    '1017_843': 37.8119,
    '1017_844': 37.5608,
    '1017_845': 37.322,
    '1017_846': 37.0859,
    '1017_847': 36.854,
    '1017_848': 36.6323,
    '1018_656': 36.3038,
    '1018_657': 36.3923,
    '1018_658': 36.4825,
    '1018_659': 36.5727,
    '1018_660': 36.6616,
    '1018_661': 36.7514,
    '1018_662': 36.8463,
    '1018_663': 36.9432,
    '1018_664': 37.0376,
    '1018_665': 37.126,
    '1018_666': 37.2018,
    '1018_667': 37.2668,
    '1018_668': 37.3231,
    '1018_669': 37.3731,
    '1018_670': 37.4211,
    '1018_671': 37.4722,
    '1018_672': 37.5326,
    '1018_673': 37.5976,
    '1018_674': 37.6627,
    '1018_675': 37.7171,
    '1018_676': 37.769,
    '1018_677': 37.8304,
    '1018_678': 37.8806,
    '1018_679': 37.8864,
    '1018_680': 37.8443,
    '1018_681': 37.7683,
    '1018_682': 37.6699,
    '1018_683': 37.5603,
    '1018_684': 37.4496,
    '1018_685': 37.3401,
    '1018_686': 37.2371,
    '1018_687': 37.1636,
    '1018_688': 37.1153,
    '1018_689': 37.0907,
    '1018_690': 37.086,
    '1018_691': 37.1043,
    '1018_692': 37.1481,
    '1018_693': 37.2155,
    '1018_694': 37.3014,
    '1018_695': 37.401,
    '1018_696': 37.5107,
    '1018_697': 37.6289,
    '1018_698': 37.7532,
    '1018_699': 37.8828,
    '1018_700': 38.0164,
    '1018_701': 38.158,
    '1018_702': 38.3166,
    '1018_703': 38.5014,
    '1018_704': 38.7171,
    '1018_705': 38.9454,
    '1018_706': 39.1568,
    '1018_707': 39.3554,
    '1018_708': 39.5412,
    '1018_709': 39.6863,
    '1018_710': 39.7907,
    '1018_711': 39.883,
    '1018_712': 39.9621,
    '1018_713': 40.0083,
    '1018_714': 40.0342,
    '1018_715': 40.0798,
    '1018_716': 40.1704,
    '1018_717': 40.3141,
    '1018_718': 40.4796,
    '1018_719': 40.6198,
    '1018_720': 40.7415,
    '1018_721': 40.8408,
    '1018_722': 40.9183,
    '1018_723': 40.9588,
    '1018_724': 40.9634,
    '1018_725': 40.9366,
    '1018_726': 40.8631,
    '1018_727': 40.7556,
    '1018_728': 40.6535,
    '1018_729': 40.5862,
    '1018_730': 40.5633,
    '1018_731': 40.5715,
    '1018_732': 40.6334,
    '1018_733': 40.7283,
    '1018_734': 40.8341,
    '1018_735': 40.9296,
    '1018_736': 41.0139,
    '1018_737': 41.0974,
    '1018_738': 41.196,
    '1018_739': 41.2961,
    '1018_740': 41.3748,
    '1018_741': 41.4324,
    '1018_742': 41.4828,
    '1018_743': 41.5383,
    '1018_744': 41.6054,
    '1018_745': 41.6848,
    '1018_746': 41.767,
    '1018_747': 41.849,
    '1018_748': 41.9476,
    '1018_749': 42.0518,
    '1018_750': 42.1513,
    '1018_751': 42.2247,
    '1018_752': 42.2805,
    '1018_753': 42.3729,
    '1018_754': 42.4911,
    '1018_755': 42.6165,
    '1018_756': 42.7538,
    '1018_757': 42.9164,
    '1018_758': 43.0718,
    '1018_759': 43.1925,
    '1018_760': 43.2976,
    '1018_761': 43.3716,
    '1018_762': 43.4433,
    '1018_763': 43.5136,
    '1018_764': 43.5982,
    '1018_765': 43.686,
    '1018_766': 43.7672,
    '1018_767': 43.8318,
    '1018_768': 43.8803,
    '1018_769': 43.9039,
    '1018_770': 43.9457,
    '1018_771': 43.967,
    '1018_772': 43.9867,
    '1018_773': 44.0209,
    '1018_774': 44.0382,
    '1018_775': 44.0334,
    '1018_776': 44.0404,
    '1018_777': 44.0426,
    '1018_778': 44.0068,
    '1018_779': 43.9738,
    '1018_780': 43.9415,
    '1018_781': 43.8968,
    '1018_782': 43.8469,
    '1018_783': 43.7921,
    '1018_784': 43.7513,
    '1018_785': 43.7261,
    '1018_786': 43.7053,
    '1018_787': 43.6891,
    '1018_788': 43.678,
    '1018_789': 43.6795,
    '1018_790': 43.6683,
    '1018_791': 43.634,
    '1018_792': 43.5904,
    '1018_793': 43.5438,
    '1018_794': 43.474,
    '1018_795': 43.3617,
    '1018_796': 43.2263,
    '1018_797': 43.1081,
    '1018_798': 43.0193,
    '1018_799': 42.9585,
    '1018_800': 42.9252,
    '1018_801': 42.9169,
    '1018_802': 42.9347,
    '1018_803': 42.9735,
    '1018_804': 43.0109,
    '1018_805': 43.0396,
    '1018_806': 43.0773,
    '1018_807': 43.1293,
    '1018_808': 43.182,
    '1018_809': 43.2367,
    '1018_810': 43.2794,
    '1018_811': 43.2958,
    '1018_812': 43.2847,
    '1018_813': 43.2475,
    '1018_814': 43.1994,
    '1018_815': 43.1482,
    '1018_816': 43.1087,
    '1018_817': 43.0936,
    '1018_818': 43.1017,
    '1018_819': 43.1134,
    '1018_820': 43.1019,
    '1018_821': 43.0615,
    '1018_822': 42.9929,
    '1018_823': 42.8972,
    '1018_824': 42.7729,
    '1018_825': 42.6189,
    '1018_826': 42.4371,
    '1018_827': 42.2277,
    '1018_828': 41.9901,
    '1018_829': 41.7379,
    '1018_830': 41.4748,
    '1018_831': 41.2058,
    '1018_832': 40.9341,
    '1018_833': 40.6657,
    '1018_834': 40.3947,
    '1018_835': 40.1236,
    '1018_836': 39.8577,
    '1018_837': 39.5925,
    '1018_838': 39.3189,
    '1018_839': 39.0314,
    '1018_840': 38.7397,
    '1018_841': 38.455,
    '1018_842': 38.1789,
    '1018_843': 37.9166,
    '1018_844': 37.6635,
    '1018_845': 37.4231,
    '1018_846': 37.1906,
    '1018_847': 36.9633,
    '1018_848': 36.7497,
    '1019_656': 36.2915,
    '1019_657': 36.3836,
    '1019_658': 36.4765,
    '1019_659': 36.5685,
    '1019_660': 36.6585,
    '1019_661': 36.7473,
    '1019_662': 36.842,
    '1019_663': 36.9387,
    '1019_664': 37.0313,
    '1019_665': 37.1199,
    '1019_666': 37.1958,
    '1019_667': 37.2608,
    '1019_668': 37.3166,
    '1019_669': 37.3653,
    '1019_670': 37.41,
    '1019_671': 37.4566,
    '1019_672': 37.5117,
    '1019_673': 37.5787,
    '1019_674': 37.6448,
    '1019_675': 37.699,
    '1019_676': 37.7404,
    '1019_677': 37.7876,
    '1019_678': 37.8418,
    '1019_679': 37.8566,
    '1019_680': 37.8295,
    '1019_681': 37.7604,
    '1019_682': 37.6595,
    '1019_683': 37.5488,
    '1019_684': 37.4367,
    '1019_685': 37.324,
    '1019_686': 37.2129,
    '1019_687': 37.1269,
    '1019_688': 37.0668,
    '1019_689': 37.0317,
    '1019_690': 37.023,
    '1019_691': 37.0374,
    '1019_692': 37.0749,
    '1019_693': 37.1364,
    '1019_694': 37.2145,
    '1019_695': 37.3055,
    '1019_696': 37.4079,
    '1019_697': 37.5203,
    '1019_698': 37.6387,
    '1019_699': 37.7605,
    '1019_700': 37.8818,
    '1019_701': 38.0075,
    '1019_702': 38.1473,
    '1019_703': 38.3082,
    '1019_704': 38.491,
    '1019_705': 38.6862,
    '1019_706': 38.8812,
    '1019_707': 39.0731,
    '1019_708': 39.2498,
    '1019_709': 39.4007,
    '1019_710': 39.5219,
    '1019_711': 39.6229,
    '1019_712': 39.7104,
    '1019_713': 39.7622,
    '1019_714': 39.8051,
    '1019_715': 39.851,
    '1019_716': 39.9428,
    '1019_717': 40.0787,
    '1019_718': 40.2406,
    '1019_719': 40.3812,
    '1019_720': 40.4947,
    '1019_721': 40.6009,
    '1019_722': 40.6888,
    '1019_723': 40.7429,
    '1019_724': 40.7765,
    '1019_725': 40.7736,
    '1019_726': 40.7277,
    '1019_727': 40.6308,
    '1019_728': 40.5345,
    '1019_729': 40.471,
    '1019_730': 40.4448,
    '1019_731': 40.454,
    '1019_732': 40.5136,
    '1019_733': 40.6061,
    '1019_734': 40.7165,
    '1019_735': 40.8227,
    '1019_736': 40.9151,
    '1019_737': 40.9979,
    '1019_738': 41.0784,
    '1019_739': 41.1629,
    '1019_740': 41.2369,
    '1019_741': 41.2965,
    '1019_742': 41.3445,
    '1019_743': 41.3885,
    '1019_744': 41.4408,
    '1019_745': 41.507,
    '1019_746': 41.5824,
    '1019_747': 41.6633,
    '1019_748': 41.7631,
    '1019_749': 41.8687,
    '1019_750': 41.9792,
    '1019_751': 42.0708,
    '1019_752': 42.1375,
    '1019_753': 42.2328,
    '1019_754': 42.3554,
    '1019_755': 42.4835,
    '1019_756': 42.6327,
    '1019_757': 42.8185,
    '1019_758': 42.97,
    '1019_759': 43.1077,
    '1019_760': 43.2347,
    '1019_761': 43.3293,
    '1019_762': 43.4117,
    '1019_763': 43.5054,
    '1019_764': 43.5791,
    '1019_765': 43.6629,
    '1019_766': 43.7432,
    '1019_767': 43.8005,
    '1019_768': 43.8465,
    '1019_769': 43.8637,
    '1019_770': 43.8901,
    '1019_771': 43.9232,
    '1019_772': 43.9491,
    '1019_773': 43.9785,
    '1019_774': 43.9944,
    '1019_775': 43.9939,
    '1019_776': 43.9968,
    '1019_777': 43.9811,
    '1019_778': 43.9653,
    '1019_779': 43.9389,
    '1019_780': 43.9075,
    '1019_781': 43.8759,
    '1019_782': 43.8296,
    '1019_783': 43.7913,
    '1019_784': 43.7544,
    '1019_785': 43.7331,
    '1019_786': 43.7214,
    '1019_787': 43.705,
    '1019_788': 43.6967,
    '1019_789': 43.6968,
    '1019_790': 43.6913,
    '1019_791': 43.6652,
    '1019_792': 43.6349,
    '1019_793': 43.6037,
    '1019_794': 43.5435,
    '1019_795': 43.4461,
    '1019_796': 43.3101,
    '1019_797': 43.1831,
    '1019_798': 43.0885,
    '1019_799': 43.0217,
    '1019_800': 42.9825,
    '1019_801': 42.9702,
    '1019_802': 42.9845,
    '1019_803': 43.0157,
    '1019_804': 43.046,
    '1019_805': 43.0704,
    '1019_806': 43.102,
    '1019_807': 43.1461,
    '1019_808': 43.1921,
    '1019_809': 43.2387,
    '1019_810': 43.2771,
    '1019_811': 43.2866,
    '1019_812': 43.2724,
    '1019_813': 43.2357,
    '1019_814': 43.1909,
    '1019_815': 43.1505,
    '1019_816': 43.1242,
    '1019_817': 43.1214,
    '1019_818': 43.1375,
    '1019_819': 43.1477,
    '1019_820': 43.1329,
    '1019_821': 43.0947,
    '1019_822': 43.0312,
    '1019_823': 42.9411,
    '1019_824': 42.8245,
    '1019_825': 42.6796,
    '1019_826': 42.5106,
    '1019_827': 42.3106,
    '1019_828': 42.077,
    '1019_829': 41.8301,
    '1019_830': 41.5742,
    '1019_831': 41.3108,
    '1019_832': 41.0426,
    '1019_833': 40.7776,
    '1019_834': 40.5057,
    '1019_835': 40.2357,
    '1019_836': 39.9689,
    '1019_837': 39.7041,
    '1019_838': 39.4299,
    '1019_839': 39.1429,
    '1019_840': 38.8457,
    '1019_841': 38.555,
    '1019_842': 38.279,
    '1019_843': 38.011,
    '1019_844': 37.7615,
    '1019_845': 37.5201,
    '1019_846': 37.291,
    '1019_847': 37.067,
    '1019_848': 36.8477,
    '1020_656': 36.282,
    '1020_657': 36.3796,
    '1020_658': 36.4758,
    '1020_659': 36.5702,
    '1020_660': 36.6609,
    '1020_661': 36.7465,
    '1020_662': 36.8423,
    '1020_663': 36.9397,
    '1020_664': 37.0289,
    '1020_665': 37.1199,
    '1020_666': 37.1942,
    '1020_667': 37.2591,
    '1020_668': 37.3144,
    '1020_669': 37.3616,
    '1020_670': 37.406,
    '1020_671': 37.4507,
    '1020_672': 37.5015,
    '1020_673': 37.5661,
    '1020_674': 37.6327,
    '1020_675': 37.6768,
    '1020_676': 37.7119,
    '1020_677': 37.7466,
    '1020_678': 37.7857,
    '1020_679': 37.8081,
    '1020_680': 37.7949,
    '1020_681': 37.7351,
    '1020_682': 37.6415,
    '1020_683': 37.5329,
    '1020_684': 37.4212,
    '1020_685': 37.3088,
    '1020_686': 37.1888,
    '1020_687': 37.0921,
    '1020_688': 37.0241,
    '1020_689': 36.9825,
    '1020_690': 36.9672,
    '1020_691': 36.9773,
    '1020_692': 37.0088,
    '1020_693': 37.0623,
    '1020_694': 37.1313,
    '1020_695': 37.2133,
    '1020_696': 37.3078,
    '1020_697': 37.4127,
    '1020_698': 37.5239,
    '1020_699': 37.6387,
    '1020_700': 37.7462,
    '1020_701': 37.8533,
    '1020_702': 37.9734,
    '1020_703': 38.1125,
    '1020_704': 38.2692,
    '1020_705': 38.4385,
    '1020_706': 38.6131,
    '1020_707': 38.7855,
    '1020_708': 38.9498,
    '1020_709': 39.1037,
    '1020_710': 39.2393,
    '1020_711': 39.348,
    '1020_712': 39.4342,
    '1020_713': 39.4989,
    '1020_714': 39.5558,
    '1020_715': 39.6134,
    '1020_716': 39.7087,
    '1020_717': 39.8355,
    '1020_718': 39.9883,
    '1020_719': 40.1283,
    '1020_720': 40.2453,
    '1020_721': 40.3673,
    '1020_722': 40.4658,
    '1020_723': 40.5261,
    '1020_724': 40.5813,
    '1020_725': 40.6091,
    '1020_726': 40.5856,
    '1020_727': 40.5062,
    '1020_728': 40.4185,
    '1020_729': 40.3574,
    '1020_730': 40.3281,
    '1020_731': 40.3389,
    '1020_732': 40.3943,
    '1020_733': 40.4784,
    '1020_734': 40.5868,
    '1020_735': 40.6996,
    '1020_736': 40.7976,
    '1020_737': 40.8864,
    '1020_738': 40.9713,
    '1020_739': 41.0529,
    '1020_740': 41.1276,
    '1020_741': 41.188,
    '1020_742': 41.2347,
    '1020_743': 41.2698,
    '1020_744': 41.302,
    '1020_745': 41.3494,
    '1020_746': 41.4124,
    '1020_747': 41.4922,
    '1020_748': 41.5918,
    '1020_749': 41.7042,
    '1020_750': 41.8257,
    '1020_751': 41.9272,
    '1020_752': 42.0044,
    '1020_753': 42.0893,
    '1020_754': 42.2049,
    '1020_755': 42.3408,
    '1020_756': 42.5111,
    '1020_757': 42.7045,
    '1020_758': 42.8756,
    '1020_759': 43.03,
    '1020_760': 43.1587,
    '1020_761': 43.2784,
    '1020_762': 43.3711,
    '1020_763': 43.4651,
    '1020_764': 43.5533,
    '1020_765': 43.6367,
    '1020_766': 43.6969,
    '1020_767': 43.7374,
    '1020_768': 43.7972,
    '1020_769': 43.8115,
    '1020_770': 43.8263,
    '1020_771': 43.8601,
    '1020_772': 43.8878,
    '1020_773': 43.9191,
    '1020_774': 43.9485,
    '1020_775': 43.9492,
    '1020_776': 43.9379,
    '1020_777': 43.9194,
    '1020_778': 43.9087,
    '1020_779': 43.8895,
    '1020_780': 43.862,
    '1020_781': 43.8397,
    '1020_782': 43.8036,
    '1020_783': 43.7793,
    '1020_784': 43.7541,
    '1020_785': 43.7413,
    '1020_786': 43.7306,
    '1020_787': 43.7181,
    '1020_788': 43.7109,
    '1020_789': 43.7167,
    '1020_790': 43.7204,
    '1020_791': 43.7161,
    '1020_792': 43.7029,
    '1020_793': 43.6776,
    '1020_794': 43.6321,
    '1020_795': 43.5389,
    '1020_796': 43.4032,
    '1020_797': 43.266,
    '1020_798': 43.1644,
    '1020_799': 43.0906,
    '1020_800': 43.0439,
    '1020_801': 43.0258,
    '1020_802': 43.0324,
    '1020_803': 43.0536,
    '1020_804': 43.0766,
    '1020_805': 43.0965,
    '1020_806': 43.1224,
    '1020_807': 43.1592,
    '1020_808': 43.2031,
    '1020_809': 43.2406,
    '1020_810': 43.2727,
    '1020_811': 43.2764,
    '1020_812': 43.2587,
    '1020_813': 43.2233,
    '1020_814': 43.1859,
    '1020_815': 43.1554,
    '1020_816': 43.1408,
    '1020_817': 43.1473,
    '1020_818': 43.1693,
    '1020_819': 43.176,
    '1020_820': 43.1568,
    '1020_821': 43.1199,
    '1020_822': 43.06,
    '1020_823': 42.9766,
    '1020_824': 42.8703,
    '1020_825': 42.7334,
    '1020_826': 42.5707,
    '1020_827': 42.3788,
    '1020_828': 42.1551,
    '1020_829': 41.9173,
    '1020_830': 41.6698,
    '1020_831': 41.4183,
    '1020_832': 41.1602,
    '1020_833': 40.8931,
    '1020_834': 40.6178,
    '1020_835': 40.3472,
    '1020_836': 40.0791,
    '1020_837': 39.8121,
    '1020_838': 39.5377,
    '1020_839': 39.2507,
    '1020_840': 38.9533,
    '1020_841': 38.6632,
    '1020_842': 38.3802,
    '1020_843': 38.1107,
    '1020_844': 37.8584,
    '1020_845': 37.6216,
    '1020_846': 37.3907,
    '1020_847': 37.1643,
    '1020_848': 36.9364,
    '1021_656': 36.2769,
    '1021_657': 36.3765,
    '1021_658': 36.4736,
    '1021_659': 36.5684,
    '1021_660': 36.6598,
    '1021_661': 36.7491,
    '1021_662': 36.8435,
    '1021_663': 36.9386,
    '1021_664': 37.0283,
    '1021_665': 37.1141,
    '1021_666': 37.1888,
    '1021_667': 37.2583,
    '1021_668': 37.3138,
    '1021_669': 37.3608,
    '1021_670': 37.4063,
    '1021_671': 37.4505,
    '1021_672': 37.4995,
    '1021_673': 37.5592,
    '1021_674': 37.6195,
    '1021_675': 37.6553,
    '1021_676': 37.6829,
    '1021_677': 37.7071,
    '1021_678': 37.732,
    '1021_679': 37.7498,
    '1021_680': 37.7469,
    '1021_681': 37.7017,
    '1021_682': 37.6164,
    '1021_683': 37.5093,
    '1021_684': 37.3928,
    '1021_685': 37.2709,
    '1021_686': 37.148,
    '1021_687': 37.05,
    '1021_688': 36.981,
    '1021_689': 36.9366,
    '1021_690': 36.9175,
    '1021_691': 36.9227,
    '1021_692': 36.9469,
    '1021_693': 36.9916,
    '1021_694': 37.0509,
    '1021_695': 37.1217,
    '1021_696': 37.2059,
    '1021_697': 37.3001,
    '1021_698': 37.3985,
    '1021_699': 37.5029,
    '1021_700': 37.6009,
    '1021_701': 37.6892,
    '1021_702': 37.7921,
    '1021_703': 37.9131,
    '1021_704': 38.0496,
    '1021_705': 38.2005,
    '1021_706': 38.3583,
    '1021_707': 38.5125,
    '1021_708': 38.6623,
    '1021_709': 38.807,
    '1021_710': 38.9422,
    '1021_711': 39.0583,
    '1021_712': 39.1514,
    '1021_713': 39.2315,
    '1021_714': 39.3006,
    '1021_715': 39.3734,
    '1021_716': 39.4694,
    '1021_717': 39.5944,
    '1021_718': 39.7376,
    '1021_719': 39.8802,
    '1021_720': 40.0165,
    '1021_721': 40.1526,
    '1021_722': 40.2477,
    '1021_723': 40.3106,
    '1021_724': 40.3825,
    '1021_725': 40.4333,
    '1021_726': 40.4348,
    '1021_727': 40.3808,
    '1021_728': 40.3093,
    '1021_729': 40.2459,
    '1021_730': 40.2135,
    '1021_731': 40.222,
    '1021_732': 40.2685,
    '1021_733': 40.3456,
    '1021_734': 40.4507,
    '1021_735': 40.5651,
    '1021_736': 40.6717,
    '1021_737': 40.7639,
    '1021_738': 40.8539,
    '1021_739': 40.9367,
    '1021_740': 41.0141,
    '1021_741': 41.0747,
    '1021_742': 41.1244,
    '1021_743': 41.1595,
    '1021_744': 41.1853,
    '1021_745': 41.2151,
    '1021_746': 41.2594,
    '1021_747': 41.3344,
    '1021_748': 41.4333,
    '1021_749': 41.551,
    '1021_750': 41.6777,
    '1021_751': 41.7858,
    '1021_752': 41.8771,
    '1021_753': 41.9599,
    '1021_754': 42.0655,
    '1021_755': 42.2024,
    '1021_756': 42.3806,
    '1021_757': 42.5645,
    '1021_758': 42.7392,
    '1021_759': 42.8949,
    '1021_760': 43.0453,
    '1021_761': 43.1865,
    '1021_762': 43.3119,
    '1021_763': 43.4163,
    '1021_764': 43.503,
    '1021_765': 43.5919,
    '1021_766': 43.6485,
    '1021_767': 43.6874,
    '1021_768': 43.7168,
    '1021_769': 43.7336,
    '1021_770': 43.7559,
    '1021_771': 43.7862,
    '1021_772': 43.8336,
    '1021_773': 43.8663,
    '1021_774': 43.8897,
    '1021_775': 43.899,
    '1021_776': 43.8798,
    '1021_777': 43.8616,
    '1021_778': 43.8388,
    '1021_779': 43.8187,
    '1021_780': 43.7954,
    '1021_781': 43.7862,
    '1021_782': 43.7745,
    '1021_783': 43.7605,
    '1021_784': 43.747,
    '1021_785': 43.7436,
    '1021_786': 43.7393,
    '1021_787': 43.7259,
    '1021_788': 43.7201,
    '1021_789': 43.7271,
    '1021_790': 43.7432,
    '1021_791': 43.7598,
    '1021_792': 43.7653,
    '1021_793': 43.7491,
    '1021_794': 43.7069,
    '1021_795': 43.617,
    '1021_796': 43.4925,
    '1021_797': 43.3541,
    '1021_798': 43.2432,
    '1021_799': 43.162,
    '1021_800': 43.1086,
    '1021_801': 43.0842,
    '1021_802': 43.081,
    '1021_803': 43.0941,
    '1021_804': 43.108,
    '1021_805': 43.12,
    '1021_806': 43.1383,
    '1021_807': 43.1696,
    '1021_808': 43.2087,
    '1021_809': 43.2385,
    '1021_810': 43.262,
    '1021_811': 43.2646,
    '1021_812': 43.2504,
    '1021_813': 43.2198,
    '1021_814': 43.1853,
    '1021_815': 43.1643,
    '1021_816': 43.1527,
    '1021_817': 43.1684,
    '1021_818': 43.1903,
    '1021_819': 43.1936,
    '1021_820': 43.1725,
    '1021_821': 43.137,
    '1021_822': 43.0817,
    '1021_823': 43.0052,
    '1021_824': 42.9075,
    '1021_825': 42.7778,
    '1021_826': 42.622,
    '1021_827': 42.4394,
    '1021_828': 42.2282,
    '1021_829': 42.0032,
    '1021_830': 41.7705,
    '1021_831': 41.5388,
    '1021_832': 41.2937,
    '1021_833': 41.0095,
    '1021_834': 40.7305,
    '1021_835': 40.4572,
    '1021_836': 40.1848,
    '1021_837': 39.9142,
    '1021_838': 39.6372,
    '1021_839': 39.3508,
    '1021_840': 39.057,
    '1021_841': 38.7607,
    '1021_842': 38.4771,
    '1021_843': 38.2047,
    '1021_844': 37.9507,
    '1021_845': 37.7141,
    '1021_846': 37.4862,
    '1021_847': 37.2588,
    '1021_848': 37.0358,
    '1022_656': 36.2767,
    '1022_657': 36.3755,
    '1022_658': 36.47,
    '1022_659': 36.564,
    '1022_660': 36.6552,
    '1022_661': 36.746,
    '1022_662': 36.8411,
    '1022_663': 36.9352,
    '1022_664': 37.0247,
    '1022_665': 37.1086,
    '1022_666': 37.1792,
    '1022_667': 37.2565,
    '1022_668': 37.3109,
    '1022_669': 37.3589,
    '1022_670': 37.4066,
    '1022_671': 37.4507,
    '1022_672': 37.4968,
    '1022_673': 37.5527,
    '1022_674': 37.604,
    '1022_675': 37.6365,
    '1022_676': 37.6557,
    '1022_677': 37.6738,
    '1022_678': 37.6909,
    '1022_679': 37.7017,
    '1022_680': 37.6964,
    '1022_681': 37.6652,
    '1022_682': 37.5911,
    '1022_683': 37.4857,
    '1022_684': 37.3657,
    '1022_685': 37.237,
    '1022_686': 37.108,
    '1022_687': 37.0124,
    '1022_688': 36.9443,
    '1022_689': 36.8968,
    '1022_690': 36.8736,
    '1022_691': 36.8742,
    '1022_692': 36.8923,
    '1022_693': 36.9263,
    '1022_694': 36.9747,
    '1022_695': 37.0316,
    '1022_696': 37.1027,
    '1022_697': 37.1818,
    '1022_698': 37.2659,
    '1022_699': 37.3592,
    '1022_700': 37.4394,
    '1022_701': 37.5154,
    '1022_702': 37.6085,
    '1022_703': 37.7164,
    '1022_704': 37.8374,
    '1022_705': 37.9728,
    '1022_706': 38.1171,
    '1022_707': 38.2547,
    '1022_708': 38.3921,
    '1022_709': 38.5281,
    '1022_710': 38.6576,
    '1022_711': 38.7753,
    '1022_712': 38.8789,
    '1022_713': 38.9681,
    '1022_714': 39.0449,
    '1022_715': 39.1278,
    '1022_716': 39.2312,
    '1022_717': 39.3595,
    '1022_718': 39.5084,
    '1022_719': 39.6581,
    '1022_720': 39.8042,
    '1022_721': 39.9333,
    '1022_722': 40.0263,
    '1022_723': 40.0965,
    '1022_724': 40.18,
    '1022_725': 40.2478,
    '1022_726': 40.2736,
    '1022_727': 40.2531,
    '1022_728': 40.2005,
    '1022_729': 40.1365,
    '1022_730': 40.0997,
    '1022_731': 40.1037,
    '1022_732': 40.143,
    '1022_733': 40.2151,
    '1022_734': 40.3132,
    '1022_735': 40.4218,
    '1022_736': 40.5279,
    '1022_737': 40.631,
    '1022_738': 40.7298,
    '1022_739': 40.8169,
    '1022_740': 40.894,
    '1022_741': 40.9545,
    '1022_742': 41.0086,
    '1022_743': 41.0448,
    '1022_744': 41.0708,
    '1022_745': 41.096,
    '1022_746': 41.1293,
    '1022_747': 41.1893,
    '1022_748': 41.2816,
    '1022_749': 41.3991,
    '1022_750': 41.5265,
    '1022_751': 41.6423,
    '1022_752': 41.7442,
    '1022_753': 41.837,
    '1022_754': 41.9406,
    '1022_755': 42.0695,
    '1022_756': 42.2346,
    '1022_757': 42.4101,
    '1022_758': 42.5729,
    '1022_759': 42.7409,
    '1022_760': 42.9107,
    '1022_761': 43.0782,
    '1022_762': 43.2231,
    '1022_763': 43.3537,
    '1022_764': 43.4463,
    '1022_765': 43.5308,
    '1022_766': 43.5925,
    '1022_767': 43.6234,
    '1022_768': 43.6491,
    '1022_769': 43.6548,
    '1022_770': 43.6776,
    '1022_771': 43.7081,
    '1022_772': 43.7703,
    '1022_773': 43.8221,
    '1022_774': 43.8368,
    '1022_775': 43.8451,
    '1022_776': 43.823,
    '1022_777': 43.7902,
    '1022_778': 43.7649,
    '1022_779': 43.7419,
    '1022_780': 43.7235,
    '1022_781': 43.7248,
    '1022_782': 43.7247,
    '1022_783': 43.7318,
    '1022_784': 43.7303,
    '1022_785': 43.7327,
    '1022_786': 43.7319,
    '1022_787': 43.7251,
    '1022_788': 43.7203,
    '1022_789': 43.7287,
    '1022_790': 43.7547,
    '1022_791': 43.7833,
    '1022_792': 43.8042,
    '1022_793': 43.8057,
    '1022_794': 43.771,
    '1022_795': 43.6704,
    '1022_796': 43.5658,
    '1022_797': 43.4313,
    '1022_798': 43.3193,
    '1022_799': 43.2351,
    '1022_800': 43.1767,
    '1022_801': 43.1464,
    '1022_802': 43.1342,
    '1022_803': 43.1349,
    '1022_804': 43.1394,
    '1022_805': 43.1432,
    '1022_806': 43.1539,
    '1022_807': 43.1785,
    '1022_808': 43.2075,
    '1022_809': 43.2276,
    '1022_810': 43.2425,
    '1022_811': 43.2398,
    '1022_812': 43.2284,
    '1022_813': 43.209,
    '1022_814': 43.1856,
    '1022_815': 43.169,
    '1022_816': 43.1633,
    '1022_817': 43.1814,
    '1022_818': 43.2043,
    '1022_819': 43.2056,
    '1022_820': 43.184,
    '1022_821': 43.1507,
    '1022_822': 43.0988,
    '1022_823': 43.0244,
    '1022_824': 42.9329,
    '1022_825': 42.8104,
    '1022_826': 42.669,
    '1022_827': 42.5026,
    '1022_828': 42.2994,
    '1022_829': 42.0885,
    '1022_830': 41.8808,
    '1022_831': 41.661,
    '1022_832': 41.4032,
    '1022_833': 41.114,
    '1022_834': 40.8318,
    '1022_835': 40.5586,
    '1022_836': 40.2863,
    '1022_837': 40.0168,
    '1022_838': 39.7399,
    '1022_839': 39.453,
    '1022_840': 39.1599,
    '1022_841': 38.8624,
    '1022_842': 38.5844,
    '1022_843': 38.3165,
    '1022_844': 38.0638,
    '1022_845': 37.828,
    '1022_846': 37.6027,
    '1022_847': 37.3776,
    '1022_848': 37.158,
    '1023_656': 36.2773,
    '1023_657': 36.3685,
    '1023_658': 36.4567,
    '1023_659': 36.5496,
    '1023_660': 36.639,
    '1023_661': 36.7291,
    '1023_662': 36.8266,
    '1023_663': 36.9211,
    '1023_664': 37.0109,
    '1023_665': 37.1007,
    '1023_666': 37.1638,
    '1023_667': 37.2364,
    '1023_668': 37.2974,
    '1023_669': 37.3521,
    '1023_670': 37.4053,
    '1023_671': 37.449,
    '1023_672': 37.4915,
    '1023_673': 37.5392,
    '1023_674': 37.5857,
    '1023_675': 37.6152,
    '1023_676': 37.6305,
    '1023_677': 37.6435,
    '1023_678': 37.656,
    '1023_679': 37.664,
    '1023_680': 37.6549,
    '1023_681': 37.6282,
    '1023_682': 37.5704,
    '1023_683': 37.4661,
    '1023_684': 37.3477,
    '1023_685': 37.2231,
    '1023_686': 37.0984,
    '1023_687': 36.9949,
    '1023_688': 36.9246,
    '1023_689': 36.8728,
    '1023_690': 36.8425,
    '1023_691': 36.8346,
    '1023_692': 36.844,
    '1023_693': 36.8656,
    '1023_694': 36.9027,
    '1023_695': 36.9488,
    '1023_696': 37.0064,
    '1023_697': 37.0706,
    '1023_698': 37.1408,
    '1023_699': 37.2162,
    '1023_700': 37.2858,
    '1023_701': 37.3562,
    '1023_702': 37.4376,
    '1023_703': 37.532,
    '1023_704': 37.6374,
    '1023_705': 37.7589,
    '1023_706': 37.8867,
    '1023_707': 38.0104,
    '1023_708': 38.1381,
    '1023_709': 38.2676,
    '1023_710': 38.393,
    '1023_711': 38.5093,
    '1023_712': 38.615,
    '1023_713': 38.7094,
    '1023_714': 38.7957,
    '1023_715': 38.8852,
    '1023_716': 38.9962,
    '1023_717': 39.1336,
    '1023_718': 39.2927,
    '1023_719': 39.4403,
    '1023_720': 39.5784,
    '1023_721': 39.6976,
    '1023_722': 39.793,
    '1023_723': 39.88,
    '1023_724': 39.971,
    '1023_725': 40.0475,
    '1023_726': 40.0972,
    '1023_727': 40.104,
    '1023_728': 40.0711,
    '1023_729': 40.0153,
    '1023_730': 39.9821,
    '1023_731': 39.9879,
    '1023_732': 40.0224,
    '1023_733': 40.0873,
    '1023_734': 40.1769,
    '1023_735': 40.2797,
    '1023_736': 40.3833,
    '1023_737': 40.492,
    '1023_738': 40.5927,
    '1023_739': 40.6761,
    '1023_740': 40.7534,
    '1023_741': 40.8174,
    '1023_742': 40.8739,
    '1023_743': 40.9195,
    '1023_744': 40.949,
    '1023_745': 40.9724,
    '1023_746': 41.0057,
    '1023_747': 41.057,
    '1023_748': 41.135,
    '1023_749': 41.2446,
    '1023_750': 41.37,
    '1023_751': 41.4933,
    '1023_752': 41.6112,
    '1023_753': 41.7217,
    '1023_754': 41.8243,
    '1023_755': 41.9451,
    '1023_756': 42.0959,
    '1023_757': 42.2499,
    '1023_758': 42.4143,
    '1023_759': 42.595,
    '1023_760': 42.7799,
    '1023_761': 42.9654,
    '1023_762': 43.1313,
    '1023_763': 43.2782,
    '1023_764': 43.375,
    '1023_765': 43.4627,
    '1023_766': 43.5171,
    '1023_767': 43.5431,
    '1023_768': 43.5659,
    '1023_769': 43.5692,
    '1023_770': 43.5901,
    '1023_771': 43.6295,
    '1023_772': 43.6939,
    '1023_773': 43.7546,
    '1023_774': 43.7662,
    '1023_775': 43.7733,
    '1023_776': 43.7647,
    '1023_777': 43.7257,
    '1023_778': 43.6926,
    '1023_779': 43.6696,
    '1023_780': 43.6538,
    '1023_781': 43.6582,
    '1023_782': 43.6703,
    '1023_783': 43.6891,
    '1023_784': 43.6943,
    '1023_785': 43.7114,
    '1023_786': 43.7151,
    '1023_787': 43.711,
    '1023_788': 43.7079,
    '1023_789': 43.7185,
    '1023_790': 43.7484,
    '1023_791': 43.7911,
    '1023_792': 43.8152,
    '1023_793': 43.83,
    '1023_794': 43.8011,
    '1023_795': 43.7339,
    '1023_796': 43.6273,
    '1023_797': 43.4971,
    '1023_798': 43.3939,
    '1023_799': 43.3107,
    '1023_800': 43.2488,
    '1023_801': 43.2122,
    '1023_802': 43.1907,
    '1023_803': 43.1766,
    '1023_804': 43.1683,
    '1023_805': 43.1638,
    '1023_806': 43.1651,
    '1023_807': 43.179,
    '1023_808': 43.1962,
    '1023_809': 43.208,
    '1023_810': 43.2158,
    '1023_811': 43.2086,
    '1023_812': 43.2002,
    '1023_813': 43.1919,
    '1023_814': 43.1812,
    '1023_815': 43.169,
    '1023_816': 43.1714,
    '1023_817': 43.1915,
    '1023_818': 43.2115,
    '1023_819': 43.2126,
    '1023_820': 43.1925,
    '1023_821': 43.1606,
    '1023_822': 43.1124,
    '1023_823': 43.0381,
    '1023_824': 42.9526,
    '1023_825': 42.8483,
    '1023_826': 42.7199,
    '1023_827': 42.5603,
    '1023_828': 42.3689,
    '1023_829': 42.184,
    '1023_830': 41.9923,
    '1023_831': 41.7647,
    '1023_832': 41.4934,
    '1023_833': 41.2111,
    '1023_834': 40.9299,
    '1023_835': 40.6552,
    '1023_836': 40.3873,
    '1023_837': 40.1197,
    '1023_838': 39.8427,
    '1023_839': 39.5581,
    '1023_840': 39.271,
    '1023_841': 38.9808,
    '1023_842': 38.7016,
    '1023_843': 38.4294,
    '1023_844': 38.1782,
    '1023_845': 37.9456,
    '1023_846': 37.7248,
    '1023_847': 37.498,
    '1023_848': 37.274,
    '1024_656': 36.2492,
    '1024_657': 36.3386,
    '1024_658': 36.4264,
    '1024_659': 36.5137,
    '1024_660': 36.6041,
    '1024_661': 36.6969,
    '1024_662': 36.7918,
    '1024_663': 36.8854,
    '1024_664': 36.9773,
    '1024_665': 37.0711,
    '1024_666': 37.1479,
    '1024_667': 37.2202,
    '1024_668': 37.2851,
    '1024_669': 37.3459,
    '1024_670': 37.4032,
    '1024_671': 37.4481,
    '1024_672': 37.4946,
    '1024_673': 37.5344,
    '1024_674': 37.5682,
    '1024_675': 37.5941,
    '1024_676': 37.6074,
    '1024_677': 37.6172,
    '1024_678': 37.6271,
    '1024_679': 37.632,
    '1024_680': 37.6224,
    '1024_681': 37.5952,
    '1024_682': 37.5429,
    '1024_683': 37.4452,
    '1024_684': 37.3331,
    '1024_685': 37.2167,
    '1024_686': 37.1032,
    '1024_687': 37.0034,
    '1024_688': 36.9241,
    '1024_689': 36.8593,
    '1024_690': 36.8177,
    '1024_691': 36.8015,
    '1024_692': 36.8042,
    '1024_693': 36.815,
    '1024_694': 36.841,
    '1024_695': 36.8754,
    '1024_696': 36.9211,
    '1024_697': 36.9711,
    '1024_698': 37.0281,
    '1024_699': 37.0906,
    '1024_700': 37.1506,
    '1024_701': 37.2153,
    '1024_702': 37.2876,
    '1024_703': 37.3727,
    '1024_704': 37.4689,
    '1024_705': 37.5734,
    '1024_706': 37.6867,
    '1024_707': 37.7985,
    '1024_708': 37.9118,
    '1024_709': 38.0326,
    '1024_710': 38.1519,
    '1024_711': 38.2644,
    '1024_712': 38.3696,
    '1024_713': 38.4668,
    '1024_714': 38.56,
    '1024_715': 38.6575,
    '1024_716': 38.7712,
    '1024_717': 38.9067,
    '1024_718': 39.0638,
    '1024_719': 39.2066,
    '1024_720': 39.341,
    '1024_721': 39.4562,
    '1024_722': 39.5618,
    '1024_723': 39.6641,
    '1024_724': 39.7573,
    '1024_725': 39.8372,
    '1024_726': 39.9045,
    '1024_727': 39.936,
    '1024_728': 39.9251,
    '1024_729': 39.884,
    '1024_730': 39.8614,
    '1024_731': 39.8734,
    '1024_732': 39.9083,
    '1024_733': 39.9657,
    '1024_734': 40.0472,
    '1024_735': 40.1419,
    '1024_736': 40.2447,
    '1024_737': 40.3532,
    '1024_738': 40.4526,
    '1024_739': 40.5321,
    '1024_740': 40.6029,
    '1024_741': 40.6688,
    '1024_742': 40.7297,
    '1024_743': 40.7803,
    '1024_744': 40.8182,
    '1024_745': 40.8455,
    '1024_746': 40.8807,
    '1024_747': 40.9323,
    '1024_748': 40.9962,
    '1024_749': 41.0912,
    '1024_750': 41.2129,
    '1024_751': 41.3447,
    '1024_752': 41.478,
    '1024_753': 41.6029,
    '1024_754': 41.7155,
    '1024_755': 41.8283,
    '1024_756': 41.9683,
    '1024_757': 42.1177,
    '1024_758': 42.2982,
    '1024_759': 42.4881,
    '1024_760': 42.6809,
    '1024_761': 42.8706,
    '1024_762': 43.0433,
    '1024_763': 43.1931,
    '1024_764': 43.2949,
    '1024_765': 43.375,
    '1024_766': 43.427,
    '1024_767': 43.4557,
    '1024_768': 43.4688,
    '1024_769': 43.4779,
    '1024_770': 43.5021,
    '1024_771': 43.5504,
    '1024_772': 43.6187,
    '1024_773': 43.6813,
    '1024_774': 43.7192,
    '1024_775': 43.7199,
    '1024_776': 43.7105,
    '1024_777': 43.6669,
    '1024_778': 43.631,
    '1024_779': 43.6064,
    '1024_780': 43.5902,
    '1024_781': 43.5963,
    '1024_782': 43.62,
    '1024_783': 43.6384,
    '1024_784': 43.6585,
    '1024_785': 43.6754,
    '1024_786': 43.6832,
    '1024_787': 43.6836,
    '1024_788': 43.682,
    '1024_789': 43.6945,
    '1024_790': 43.7288,
    '1024_791': 43.7815,
    '1024_792': 43.8274,
    '1024_793': 43.8346,
    '1024_794': 43.8194,
    '1024_795': 43.7709,
    '1024_796': 43.6734,
    '1024_797': 43.5713,
    '1024_798': 43.477,
    '1024_799': 43.3947,
    '1024_800': 43.3274,
    '1024_801': 43.2783,
    '1024_802': 43.2402,
    '1024_803': 43.215,
    '1024_804': 43.1952,
    '1024_805': 43.1795,
    '1024_806': 43.1708,
    '1024_807': 43.1754,
    '1024_808': 43.1803,
    '1024_809': 43.1824,
    '1024_810': 43.1829,
    '1024_811': 43.1785,
    '1024_812': 43.1792,
    '1024_813': 43.1813,
    '1024_814': 43.1737,
    '1024_815': 43.1665,
    '1024_816': 43.1739,
    '1024_817': 43.1951,
    '1024_818': 43.2149,
    '1024_819': 43.2172,
    '1024_820': 43.198,
    '1024_821': 43.1657,
    '1024_822': 43.1202,
    '1024_823': 43.0526,
    '1024_824': 42.9819,
    '1024_825': 42.8918,
    '1024_826': 42.7701,
    '1024_827': 42.6186,
    '1024_828': 42.4494,
    '1024_829': 42.2773,
    '1024_830': 42.0812,
    '1024_831': 41.8454,
    '1024_832': 41.5792,
    '1024_833': 41.3047,
    '1024_834': 41.0263,
    '1024_835': 40.757,
    '1024_836': 40.4956,
    '1024_837': 40.2294,
    '1024_838': 39.9523,
    '1024_839': 39.6695,
    '1024_840': 39.3854,
    '1024_841': 39.0975,
    '1024_842': 38.8148,
    '1024_843': 38.5417,
    '1024_844': 38.288,
    '1024_845': 38.0577,
    '1024_846': 37.834,
    '1024_847': 37.6077,
    '1024_848': 37.3856,
    '1025_656': 36.2431,
    '1025_657': 36.3263,
    '1025_658': 36.4128,
    '1025_659': 36.5014,
    '1025_660': 36.594,
    '1025_661': 36.6877,
    '1025_662': 36.7808,
    '1025_663': 36.8731,
    '1025_664': 36.9642,
    '1025_665': 37.0536,
    '1025_666': 37.1345,
    '1025_667': 37.2082,
    '1025_668': 37.2752,
    '1025_669': 37.3396,
    '1025_670': 37.4006,
    '1025_671': 37.4479,
    '1025_672': 37.496,
    '1025_673': 37.5289,
    '1025_674': 37.5548,
    '1025_675': 37.5774,
    '1025_676': 37.5896,
    '1025_677': 37.5992,
    '1025_678': 37.6066,
    '1025_679': 37.6091,
    '1025_680': 37.5967,
    '1025_681': 37.5658,
    '1025_682': 37.5107,
    '1025_683': 37.4211,
    '1025_684': 37.3176,
    '1025_685': 37.2098,
    '1025_686': 37.1066,
    '1025_687': 37.0133,
    '1025_688': 36.9289,
    '1025_689': 36.8456,
    '1025_690': 36.7919,
    '1025_691': 36.769,
    '1025_692': 36.7611,
    '1025_693': 36.7666,
    '1025_694': 36.7838,
    '1025_695': 36.8112,
    '1025_696': 36.8448,
    '1025_697': 36.8852,
    '1025_698': 36.9308,
    '1025_699': 36.9822,
    '1025_700': 37.0404,
    '1025_701': 37.0994,
    '1025_702': 37.1652,
    '1025_703': 37.2403,
    '1025_704': 37.3259,
    '1025_705': 37.4161,
    '1025_706': 37.5143,
    '1025_707': 37.6164,
    '1025_708': 37.7227,
    '1025_709': 37.8289,
    '1025_710': 37.9354,
    '1025_711': 38.0399,
    '1025_712': 38.1425,
    '1025_713': 38.2397,
    '1025_714': 38.3366,
    '1025_715': 38.4394,
    '1025_716': 38.5558,
    '1025_717': 38.6855,
    '1025_718': 38.8282,
    '1025_719': 38.9694,
    '1025_720': 39.1032,
    '1025_721': 39.2299,
    '1025_722': 39.351,
    '1025_723': 39.4599,
    '1025_724': 39.5481,
    '1025_725': 39.6267,
    '1025_726': 39.7013,
    '1025_727': 39.752,
    '1025_728': 39.7618,
    '1025_729': 39.7416,
    '1025_730': 39.7351,
    '1025_731': 39.755,
    '1025_732': 39.7933,
    '1025_733': 39.8473,
    '1025_734': 39.9211,
    '1025_735': 40.0113,
    '1025_736': 40.1107,
    '1025_737': 40.2121,
    '1025_738': 40.308,
    '1025_739': 40.3898,
    '1025_740': 40.4592,
    '1025_741': 40.5236,
    '1025_742': 40.584,
    '1025_743': 40.6322,
    '1025_744': 40.6755,
    '1025_745': 40.7102,
    '1025_746': 40.7528,
    '1025_747': 40.8096,
    '1025_748': 40.8687,
    '1025_749': 40.9469,
    '1025_750': 41.0628,
    '1025_751': 41.1987,
    '1025_752': 41.342,
    '1025_753': 41.4785,
    '1025_754': 41.5998,
    '1025_755': 41.7139,
    '1025_756': 41.8454,
    '1025_757': 41.9953,
    '1025_758': 42.1793,
    '1025_759': 42.3796,
    '1025_760': 42.5842,
    '1025_761': 42.7762,
    '1025_762': 42.9518,
    '1025_763': 43.0792,
    '1025_764': 43.2045,
    '1025_765': 43.2789,
    '1025_766': 43.3205,
    '1025_767': 43.3591,
    '1025_768': 43.3733,
    '1025_769': 43.3841,
    '1025_770': 43.4161,
    '1025_771': 43.4719,
    '1025_772': 43.5473,
    '1025_773': 43.6175,
    '1025_774': 43.6672,
    '1025_775': 43.6619,
    '1025_776': 43.6507,
    '1025_777': 43.6158,
    '1025_778': 43.575,
    '1025_779': 43.5501,
    '1025_780': 43.5368,
    '1025_781': 43.5435,
    '1025_782': 43.5688,
    '1025_783': 43.5886,
    '1025_784': 43.6096,
    '1025_785': 43.6325,
    '1025_786': 43.645,
    '1025_787': 43.646,
    '1025_788': 43.6477,
    '1025_789': 43.6637,
    '1025_790': 43.7027,
    '1025_791': 43.756,
    '1025_792': 43.8091,
    '1025_793': 43.8263,
    '1025_794': 43.832,
    '1025_795': 43.7824,
    '1025_796': 43.7183,
    '1025_797': 43.6435,
    '1025_798': 43.5656,
    '1025_799': 43.4867,
    '1025_800': 43.4099,
    '1025_801': 43.3432,
    '1025_802': 43.2873,
    '1025_803': 43.2488,
    '1025_804': 43.2159,
    '1025_805': 43.1868,
    '1025_806': 43.1688,
    '1025_807': 43.1674,
    '1025_808': 43.1596,
    '1025_809': 43.1553,
    '1025_810': 43.1549,
    '1025_811': 43.1563,
    '1025_812': 43.1655,
    '1025_813': 43.1717,
    '1025_814': 43.1646,
    '1025_815': 43.1623,
    '1025_816': 43.1745,
    '1025_817': 43.1948,
    '1025_818': 43.2121,
    '1025_819': 43.2161,
    '1025_820': 43.199,
    '1025_821': 43.1654,
    '1025_822': 43.1226,
    '1025_823': 43.0674,
    '1025_824': 43.0095,
    '1025_825': 42.9279,
    '1025_826': 42.814,
    '1025_827': 42.6776,
    '1025_828': 42.5251,
    '1025_829': 42.354,
    '1025_830': 42.1506,
    '1025_831': 41.9167,
    '1025_832': 41.6682,
    '1025_833': 41.4008,
    '1025_834': 41.1255,
    '1025_835': 40.863,
    '1025_836': 40.6093,
    '1025_837': 40.3438,
    '1025_838': 40.0682,
    '1025_839': 39.7855,
    '1025_840': 39.4971,
    '1025_841': 39.2118,
    '1025_842': 38.9266,
    '1025_843': 38.6522,
    '1025_844': 38.3977,
    '1025_845': 38.1667,
    '1025_846': 37.9384,
    '1025_847': 37.7093,
    '1025_848': 37.4897,
    '1026_656': 36.2503,
    '1026_657': 36.3335,
    '1026_658': 36.4153,
    '1026_659': 36.5022,
    '1026_660': 36.597,
    '1026_661': 36.6913,
    '1026_662': 36.7823,
    '1026_663': 36.8726,
    '1026_664': 36.961,
    '1026_665': 37.0465,
    '1026_666': 37.1268,
    '1026_667': 37.2011,
    '1026_668': 37.2694,
    '1026_669': 37.3359,
    '1026_670': 37.3999,
    '1026_671': 37.4491,
    '1026_672': 37.4947,
    '1026_673': 37.5239,
    '1026_674': 37.5455,
    '1026_675': 37.5646,
    '1026_676': 37.578,
    '1026_677': 37.5893,
    '1026_678': 37.5984,
    '1026_679': 37.5975,
    '1026_680': 37.5767,
    '1026_681': 37.539,
    '1026_682': 37.4824,
    '1026_683': 37.3993,
    '1026_684': 37.3033,
    '1026_685': 37.2032,
    '1026_686': 37.1059,
    '1026_687': 37.0178,
    '1026_688': 36.9348,
    '1026_689': 36.8293,
    '1026_690': 36.7697,
    '1026_691': 36.738,
    '1026_692': 36.7232,
    '1026_693': 36.7213,
    '1026_694': 36.7296,
    '1026_695': 36.7474,
    '1026_696': 36.7743,
    '1026_697': 36.8078,
    '1026_698': 36.8476,
    '1026_699': 36.8925,
    '1026_700': 36.9436,
    '1026_701': 36.998,
    '1026_702': 37.0589,
    '1026_703': 37.1244,
    '1026_704': 37.1978,
    '1026_705': 37.2764,
    '1026_706': 37.3624,
    '1026_707': 37.4531,
    '1026_708': 37.5502,
    '1026_709': 37.6473,
    '1026_710': 37.7405,
    '1026_711': 37.8353,
    '1026_712': 37.932,
    '1026_713': 38.029,
    '1026_714': 38.1281,
    '1026_715': 38.2336,
    '1026_716': 38.3532,
    '1026_717': 38.4808,
    '1026_718': 38.6137,
    '1026_719': 38.7496,
    '1026_720': 38.8876,
    '1026_721': 39.0222,
    '1026_722': 39.1509,
    '1026_723': 39.2643,
    '1026_724': 39.3511,
    '1026_725': 39.4287,
    '1026_726': 39.5026,
    '1026_727': 39.561,
    '1026_728': 39.5911,
    '1026_729': 39.594,
    '1026_730': 39.6039,
    '1026_731': 39.6318,
    '1026_732': 39.6727,
    '1026_733': 39.7264,
    '1026_734': 39.7958,
    '1026_735': 39.883,
    '1026_736': 39.9785,
    '1026_737': 40.0741,
    '1026_738': 40.1656,
    '1026_739': 40.2504,
    '1026_740': 40.3256,
    '1026_741': 40.388,
    '1026_742': 40.4459,
    '1026_743': 40.4908,
    '1026_744': 40.5339,
    '1026_745': 40.5792,
    '1026_746': 40.6275,
    '1026_747': 40.6879,
    '1026_748': 40.745,
    '1026_749': 40.8142,
    '1026_750': 40.9231,
    '1026_751': 41.0591,
    '1026_752': 41.2091,
    '1026_753': 41.3555,
    '1026_754': 41.4863,
    '1026_755': 41.6065,
    '1026_756': 41.7328,
    '1026_757': 41.8806,
    '1026_758': 42.0623,
    '1026_759': 42.2634,
    '1026_760': 42.4764,
    '1026_761': 42.6569,
    '1026_762': 42.8377,
    '1026_763': 42.9716,
    '1026_764': 43.0926,
    '1026_765': 43.1629,
    '1026_766': 43.1997,
    '1026_767': 43.2427,
    '1026_768': 43.2704,
    '1026_769': 43.2934,
    '1026_770': 43.3336,
    '1026_771': 43.3991,
    '1026_772': 43.4769,
    '1026_773': 43.5519,
    '1026_774': 43.6069,
    '1026_775': 43.6157,
    '1026_776': 43.5936,
    '1026_777': 43.5653,
    '1026_778': 43.532,
    '1026_779': 43.5022,
    '1026_780': 43.4922,
    '1026_781': 43.4988,
    '1026_782': 43.5206,
    '1026_783': 43.542,
    '1026_784': 43.5647,
    '1026_785': 43.5934,
    '1026_786': 43.608,
    '1026_787': 43.6107,
    '1026_788': 43.6115,
    '1026_789': 43.6295,
    '1026_790': 43.6694,
    '1026_791': 43.7228,
    '1026_792': 43.7785,
    '1026_793': 43.8115,
    '1026_794': 43.8236,
    '1026_795': 43.7953,
    '1026_796': 43.751,
    '1026_797': 43.7016,
    '1026_798': 43.637,
    '1026_799': 43.5723,
    '1026_800': 43.4909,
    '1026_801': 43.4053,
    '1026_802': 43.3312,
    '1026_803': 43.276,
    '1026_804': 43.2299,
    '1026_805': 43.1931,
    '1026_806': 43.1699,
    '1026_807': 43.1551,
    '1026_808': 43.1398,
    '1026_809': 43.1329,
    '1026_810': 43.1325,
    '1026_811': 43.1405,
    '1026_812': 43.1551,
    '1026_813': 43.1581,
    '1026_814': 43.1508,
    '1026_815': 43.1557,
    '1026_816': 43.1698,
    '1026_817': 43.1894,
    '1026_818': 43.2063,
    '1026_819': 43.2105,
    '1026_820': 43.197,
    '1026_821': 43.1677,
    '1026_822': 43.1301,
    '1026_823': 43.0857,
    '1026_824': 43.0334,
    '1026_825': 42.9579,
    '1026_826': 42.8539,
    '1026_827': 42.7285,
    '1026_828': 42.5809,
    '1026_829': 42.4063,
    '1026_830': 42.2026,
    '1026_831': 41.9848,
    '1026_832': 41.7612,
    '1026_833': 41.4979,
    '1026_834': 41.2268,
    '1026_835': 40.9696,
    '1026_836': 40.723,
    '1026_837': 40.4618,
    '1026_838': 40.1897,
    '1026_839': 39.9052,
    '1026_840': 39.6129,
    '1026_841': 39.3269,
    '1026_842': 39.0425,
    '1026_843': 38.7673,
    '1026_844': 38.5049,
    '1026_845': 38.2649,
    '1026_846': 38.0326,
    '1026_847': 37.806,
    '1026_848': 37.5894,
    '1027_656': 36.2586,
    '1027_657': 36.3465,
    '1027_658': 36.428,
    '1027_659': 36.5051,
    '1027_660': 36.6008,
    '1027_661': 36.6952,
    '1027_662': 36.7846,
    '1027_663': 36.8744,
    '1027_664': 36.9611,
    '1027_665': 37.0439,
    '1027_666': 37.1229,
    '1027_667': 37.1972,
    '1027_668': 37.2664,
    '1027_669': 37.3346,
    '1027_670': 37.4007,
    '1027_671': 37.4571,
    '1027_672': 37.4982,
    '1027_673': 37.5212,
    '1027_674': 37.5427,
    '1027_675': 37.5613,
    '1027_676': 37.5719,
    '1027_677': 37.584,
    '1027_678': 37.5974,
    '1027_679': 37.5946,
    '1027_680': 37.5657,
    '1027_681': 37.5216,
    '1027_682': 37.4625,
    '1027_683': 37.3823,
    '1027_684': 37.2901,
    '1027_685': 37.1928,
    '1027_686': 37.095,
    '1027_687': 37.0026,
    '1027_688': 36.9143,
    '1027_689': 36.8226,
    '1027_690': 36.7565,
    '1027_691': 36.7152,
    '1027_692': 36.6924,
    '1027_693': 36.6832,
    '1027_694': 36.6846,
    '1027_695': 36.6969,
    '1027_696': 36.7177,
    '1027_697': 36.7453,
    '1027_698': 36.7788,
    '1027_699': 36.8163,
    '1027_700': 36.8597,
    '1027_701': 36.906,
    '1027_702': 36.9599,
    '1027_703': 37.0198,
    '1027_704': 37.085,
    '1027_705': 37.1544,
    '1027_706': 37.2312,
    '1027_707': 37.3114,
    '1027_708': 37.3971,
    '1027_709': 37.4821,
    '1027_710': 37.566,
    '1027_711': 37.6537,
    '1027_712': 37.7449,
    '1027_713': 37.8402,
    '1027_714': 37.9412,
    '1027_715': 38.0487,
    '1027_716': 38.1671,
    '1027_717': 38.2922,
    '1027_718': 38.4198,
    '1027_719': 38.5514,
    '1027_720': 38.6858,
    '1027_721': 38.82,
    '1027_722': 38.9518,
    '1027_723': 39.0701,
    '1027_724': 39.1634,
    '1027_725': 39.2424,
    '1027_726': 39.316,
    '1027_727': 39.382,
    '1027_728': 39.4251,
    '1027_729': 39.4462,
    '1027_730': 39.4704,
    '1027_731': 39.5077,
    '1027_732': 39.554,
    '1027_733': 39.6094,
    '1027_734': 39.676,
    '1027_735': 39.7574,
    '1027_736': 39.8494,
    '1027_737': 39.9426,
    '1027_738': 40.0354,
    '1027_739': 40.1224,
    '1027_740': 40.2003,
    '1027_741': 40.2639,
    '1027_742': 40.3192,
    '1027_743': 40.3641,
    '1027_744': 40.4104,
    '1027_745': 40.4589,
    '1027_746': 40.5081,
    '1027_747': 40.5671,
    '1027_748': 40.6255,
    '1027_749': 40.6914,
    '1027_750': 40.7925,
    '1027_751': 40.9258,
    '1027_752': 41.0772,
    '1027_753': 41.2292,
    '1027_754': 41.3716,
    '1027_755': 41.5017,
    '1027_756': 41.6268,
    '1027_757': 41.7686,
    '1027_758': 41.9477,
    '1027_759': 42.147,
    '1027_760': 42.3476,
    '1027_761': 42.5299,
    '1027_762': 42.7014,
    '1027_763': 42.8236,
    '1027_764': 42.953,
    '1027_765': 43.0412,
    '1027_766': 43.1039,
    '1027_767': 43.1497,
    '1027_768': 43.1787,
    '1027_769': 43.2108,
    '1027_770': 43.2607,
    '1027_771': 43.3306,
    '1027_772': 43.413,
    '1027_773': 43.4906,
    '1027_774': 43.5355,
    '1027_775': 43.5485,
    '1027_776': 43.5481,
    '1027_777': 43.5234,
    '1027_778': 43.4926,
    '1027_779': 43.4621,
    '1027_780': 43.4471,
    '1027_781': 43.4528,
    '1027_782': 43.4756,
    '1027_783': 43.5021,
    '1027_784': 43.5288,
    '1027_785': 43.5553,
    '1027_786': 43.563,
    '1027_787': 43.575,
    '1027_788': 43.5747,
    '1027_789': 43.5904,
    '1027_790': 43.6277,
    '1027_791': 43.6796,
    '1027_792': 43.734,
    '1027_793': 43.7754,
    '1027_794': 43.8024,
    '1027_795': 43.7962,
    '1027_796': 43.7782,
    '1027_797': 43.7477,
    '1027_798': 43.6898,
    '1027_799': 43.6377,
    '1027_800': 43.5572,
    '1027_801': 43.4599,
    '1027_802': 43.3713,
    '1027_803': 43.2995,
    '1027_804': 43.2447,
    '1027_805': 43.2034,
    '1027_806': 43.1746,
    '1027_807': 43.1535,
    '1027_808': 43.1341,
    '1027_809': 43.122,
    '1027_810': 43.1202,
    '1027_811': 43.1279,
    '1027_812': 43.1404,
    '1027_813': 43.1413,
    '1027_814': 43.1389,
    '1027_815': 43.1493,
    '1027_816': 43.1649,
    '1027_817': 43.1842,
    '1027_818': 43.1984,
    '1027_819': 43.2019,
    '1027_820': 43.1931,
    '1027_821': 43.1759,
    '1027_822': 43.1456,
    '1027_823': 43.1021,
    '1027_824': 43.0527,
    '1027_825': 42.9836,
    '1027_826': 42.884,
    '1027_827': 42.7646,
    '1027_828': 42.6168,
    '1027_829': 42.4397,
    '1027_830': 42.2466,
    '1027_831': 42.0466,
    '1027_832': 41.835,
    '1027_833': 41.583,
    '1027_834': 41.32,
    '1027_835': 41.074,
    '1027_836': 40.8367,
    '1027_837': 40.5846,
    '1027_838': 40.3125,
    '1027_839': 40.0251,
    '1027_840': 39.7262,
    '1027_841': 39.4382,
    '1027_842': 39.1559,
    '1027_843': 38.8825,
    '1027_844': 38.6151,
    '1027_845': 38.3674,
    '1027_846': 38.1303,
    '1027_847': 37.9032,
    '1027_848': 37.6889,
    '1028_656': 36.2559,
    '1028_657': 36.3421,
    '1028_658': 36.4239,
    '1028_659': 36.5035,
    '1028_660': 36.5899,
    '1028_661': 36.6815,
    '1028_662': 36.7798,
    '1028_663': 36.8757,
    '1028_664': 36.9624,
    '1028_665': 37.0432,
    '1028_666': 37.1208,
    '1028_667': 37.1952,
    '1028_668': 37.2651,
    '1028_669': 37.331,
    '1028_670': 37.3987,
    '1028_671': 37.4608,
    '1028_672': 37.5033,
    '1028_673': 37.5253,
    '1028_674': 37.5491,
    '1028_675': 37.5631,
    '1028_676': 37.5708,
    '1028_677': 37.5809,
    '1028_678': 37.5907,
    '1028_679': 37.5847,
    '1028_680': 37.5562,
    '1028_681': 37.5129,
    '1028_682': 37.4524,
    '1028_683': 37.3726,
    '1028_684': 37.2815,
    '1028_685': 37.1843,
    '1028_686': 37.084,
    '1028_687': 36.9871,
    '1028_688': 36.8987,
    '1028_689': 36.8147,
    '1028_690': 36.7467,
    '1028_691': 36.6993,
    '1028_692': 36.67,
    '1028_693': 36.6534,
    '1028_694': 36.6484,
    '1028_695': 36.6571,
    '1028_696': 36.6734,
    '1028_697': 36.6963,
    '1028_698': 36.7247,
    '1028_699': 36.756,
    '1028_700': 36.7914,
    '1028_701': 36.8331,
    '1028_702': 36.8811,
    '1028_703': 36.9355,
    '1028_704': 36.9944,
    '1028_705': 37.057,
    '1028_706': 37.1252,
    '1028_707': 37.1966,
    '1028_708': 37.2704,
    '1028_709': 37.3472,
    '1028_710': 37.4269,
    '1028_711': 37.5098,
    '1028_712': 37.5923,
    '1028_713': 37.6785,
    '1028_714': 37.7756,
    '1028_715': 37.8801,
    '1028_716': 37.9947,
    '1028_717': 38.1155,
    '1028_718': 38.2403,
    '1028_719': 38.3707,
    '1028_720': 38.5028,
    '1028_721': 38.6333,
    '1028_722': 38.7615,
    '1028_723': 38.8829,
    '1028_724': 38.9833,
    '1028_725': 39.0668,
    '1028_726': 39.1424,
    '1028_727': 39.219,
    '1028_728': 39.272,
    '1028_729': 39.304,
    '1028_730': 39.3406,
    '1028_731': 39.3872,
    '1028_732': 39.4401,
    '1028_733': 39.4997,
    '1028_734': 39.5658,
    '1028_735': 39.6428,
    '1028_736': 39.731,
    '1028_737': 39.8241,
    '1028_738': 39.9184,
    '1028_739': 40.0104,
    '1028_740': 40.0909,
    '1028_741': 40.1555,
    '1028_742': 40.2079,
    '1028_743': 40.2519,
    '1028_744': 40.3006,
    '1028_745': 40.3506,
    '1028_746': 40.3988,
    '1028_747': 40.4561,
    '1028_748': 40.5173,
    '1028_749': 40.5807,
    '1028_750': 40.6722,
    '1028_751': 40.8012,
    '1028_752': 40.9517,
    '1028_753': 41.1049,
    '1028_754': 41.2544,
    '1028_755': 41.3957,
    '1028_756': 41.5203,
    '1028_757': 41.654,
    '1028_758': 41.823,
    '1028_759': 42.0149,
    '1028_760': 42.2091,
    '1028_761': 42.3882,
    '1028_762': 42.549,
    '1028_763': 42.6893,
    '1028_764': 42.8083,
    '1028_765': 42.903,
    '1028_766': 42.9825,
    '1028_767': 43.0461,
    '1028_768': 43.0953,
    '1028_769': 43.1408,
    '1028_770': 43.1936,
    '1028_771': 43.2664,
    '1028_772': 43.3546,
    '1028_773': 43.431,
    '1028_774': 43.4773,
    '1028_775': 43.5034,
    '1028_776': 43.4961,
    '1028_777': 43.4777,
    '1028_778': 43.4565,
    '1028_779': 43.4243,
    '1028_780': 43.3997,
    '1028_781': 43.4017,
    '1028_782': 43.4267,
    '1028_783': 43.4571,
    '1028_784': 43.4879,
    '1028_785': 43.5084,
    '1028_786': 43.5184,
    '1028_787': 43.5316,
    '1028_788': 43.5372,
    '1028_789': 43.5462,
    '1028_790': 43.58,
    '1028_791': 43.6278,
    '1028_792': 43.6837,
    '1028_793': 43.7366,
    '1028_794': 43.7708,
    '1028_795': 43.7862,
    '1028_796': 43.7892,
    '1028_797': 43.7772,
    '1028_798': 43.7365,
    '1028_799': 43.6747,
    '1028_800': 43.5997,
    '1028_801': 43.5025,
    '1028_802': 43.4076,
    '1028_803': 43.3268,
    '1028_804': 43.2647,
    '1028_805': 43.2165,
    '1028_806': 43.1802,
    '1028_807': 43.1552,
    '1028_808': 43.1359,
    '1028_809': 43.1225,
    '1028_810': 43.1186,
    '1028_811': 43.1224,
    '1028_812': 43.1276,
    '1028_813': 43.1214,
    '1028_814': 43.1259,
    '1028_815': 43.1407,
    '1028_816': 43.158,
    '1028_817': 43.1748,
    '1028_818': 43.1878,
    '1028_819': 43.1924,
    '1028_820': 43.1897,
    '1028_821': 43.181,
    '1028_822': 43.1576,
    '1028_823': 43.1168,
    '1028_824': 43.0661,
    '1028_825': 43.0038,
    '1028_826': 42.9083,
    '1028_827': 42.7901,
    '1028_828': 42.6431,
    '1028_829': 42.4761,
    '1028_830': 42.2934,
    '1028_831': 42.0999,
    '1028_832': 41.8929,
    '1028_833': 41.6607,
    '1028_834': 41.4137,
    '1028_835': 41.1867,
    '1028_836': 40.9618,
    '1028_837': 40.7142,
    '1028_838': 40.4357,
    '1028_839': 40.1415,
    '1028_840': 39.833,
    '1028_841': 39.5439,
    '1028_842': 39.2631,
    '1028_843': 38.9901,
    '1028_844': 38.7252,
    '1028_845': 38.4801,
    '1028_846': 38.2449,
    '1028_847': 38.0116,
    '1028_848': 37.8006,
    '1029_656': 36.2511,
    '1029_657': 36.3364,
    '1029_658': 36.4186,
    '1029_659': 36.5004,
    '1029_660': 36.5847,
    '1029_661': 36.6738,
    '1029_662': 36.776,
    '1029_663': 36.8764,
    '1029_664': 36.9609,
    '1029_665': 37.0398,
    '1029_666': 37.1166,
    '1029_667': 37.1923,
    '1029_668': 37.2648,
    '1029_669': 37.3252,
    '1029_670': 37.389,
    '1029_671': 37.4511,
    '1029_672': 37.4986,
    '1029_673': 37.5301,
    '1029_674': 37.5569,
    '1029_675': 37.5658,
    '1029_676': 37.5699,
    '1029_677': 37.575,
    '1029_678': 37.5787,
    '1029_679': 37.5708,
    '1029_680': 37.5473,
    '1029_681': 37.5079,
    '1029_682': 37.4487,
    '1029_683': 37.3687,
    '1029_684': 37.2783,
    '1029_685': 37.1816,
    '1029_686': 37.0809,
    '1029_687': 36.9842,
    '1029_688': 36.8943,
    '1029_689': 36.8112,
    '1029_690': 36.742,
    '1029_691': 36.6926,
    '1029_692': 36.6576,
    '1029_693': 36.6334,
    '1029_694': 36.62,
    '1029_695': 36.6227,
    '1029_696': 36.6369,
    '1029_697': 36.656,
    '1029_698': 36.6796,
    '1029_699': 36.7058,
    '1029_700': 36.7355,
    '1029_701': 36.7742,
    '1029_702': 36.8173,
    '1029_703': 36.8662,
    '1029_704': 36.9206,
    '1029_705': 36.9783,
    '1029_706': 37.038,
    '1029_707': 37.1002,
    '1029_708': 37.1637,
    '1029_709': 37.2335,
    '1029_710': 37.3065,
    '1029_711': 37.3805,
    '1029_712': 37.4548,
    '1029_713': 37.536,
    '1029_714': 37.6256,
    '1029_715': 37.7255,
    '1029_716': 37.8348,
    '1029_717': 37.9526,
    '1029_718': 38.0743,
    '1029_719': 38.2051,
    '1029_720': 38.3365,
    '1029_721': 38.464,
    '1029_722': 38.5863,
    '1029_723': 38.7042,
    '1029_724': 38.8096,
    '1029_725': 38.8965,
    '1029_726': 38.9783,
    '1029_727': 39.0629,
    '1029_728': 39.1252,
    '1029_729': 39.1675,
    '1029_730': 39.2156,
    '1029_731': 39.2708,
    '1029_732': 39.3299,
    '1029_733': 39.3935,
    '1029_734': 39.4621,
    '1029_735': 39.54,
    '1029_736': 39.6257,
    '1029_737': 39.7182,
    '1029_738': 39.8148,
    '1029_739': 39.9104,
    '1029_740': 39.9868,
    '1029_741': 40.0537,
    '1029_742': 40.1082,
    '1029_743': 40.1497,
    '1029_744': 40.1964,
    '1029_745': 40.2489,
    '1029_746': 40.2977,
    '1029_747': 40.3518,
    '1029_748': 40.4182,
    '1029_749': 40.4802,
    '1029_750': 40.5614,
    '1029_751': 40.6836,
    '1029_752': 40.8325,
    '1029_753': 40.9817,
    '1029_754': 41.1342,
    '1029_755': 41.2816,
    '1029_756': 41.4105,
    '1029_757': 41.5416,
    '1029_758': 41.6991,
    '1029_759': 41.8771,
    '1029_760': 42.0656,
    '1029_761': 42.2422,
    '1029_762': 42.3973,
    '1029_763': 42.5384,
    '1029_764': 42.6629,
    '1029_765': 42.7728,
    '1029_766': 42.8679,
    '1029_767': 42.9513,
    '1029_768': 43.0204,
    '1029_769': 43.0765,
    '1029_770': 43.1349,
    '1029_771': 43.2093,
    '1029_772': 43.2987,
    '1029_773': 43.3672,
    '1029_774': 43.4151,
    '1029_775': 43.4433,
    '1029_776': 43.4371,
    '1029_777': 43.4314,
    '1029_778': 43.4072,
    '1029_779': 43.3819,
    '1029_780': 43.3533,
    '1029_781': 43.3497,
    '1029_782': 43.3739,
    '1029_783': 43.4042,
    '1029_784': 43.43,
    '1029_785': 43.4547,
    '1029_786': 43.4797,
    '1029_787': 43.4854,
    '1029_788': 43.4922,
    '1029_789': 43.4997,
    '1029_790': 43.5259,
    '1029_791': 43.569,
    '1029_792': 43.622,
    '1029_793': 43.6867,
    '1029_794': 43.7349,
    '1029_795': 43.7543,
    '1029_796': 43.7592,
    '1029_797': 43.7583,
    '1029_798': 43.7418,
    '1029_799': 43.6872,
    '1029_800': 43.6161,
    '1029_801': 43.5269,
    '1029_802': 43.4296,
    '1029_803': 43.3466,
    '1029_804': 43.2795,
    '1029_805': 43.2235,
    '1029_806': 43.1807,
    '1029_807': 43.1508,
    '1029_808': 43.1344,
    '1029_809': 43.1241,
    '1029_810': 43.1178,
    '1029_811': 43.1175,
    '1029_812': 43.1096,
    '1029_813': 43.0993,
    '1029_814': 43.1079,
    '1029_815': 43.1263,
    '1029_816': 43.1456,
    '1029_817': 43.1629,
    '1029_818': 43.1749,
    '1029_819': 43.1812,
    '1029_820': 43.1836,
    '1029_821': 43.1778,
    '1029_822': 43.1554,
    '1029_823': 43.1185,
    '1029_824': 43.0725,
    '1029_825': 43.0138,
    '1029_826': 42.9277,
    '1029_827': 42.8117,
    '1029_828': 42.6734,
    '1029_829': 42.5168,
    '1029_830': 42.3425,
    '1029_831': 42.158,
    '1029_832': 41.9591,
    '1029_833': 41.7399,
    '1029_834': 41.5228,
    '1029_835': 41.3173,
    '1029_836': 41.102,
    '1029_837': 40.8413,
    '1029_838': 40.5514,
    '1029_839': 40.2482,
    '1029_840': 39.9342,
    '1029_841': 39.6459,
    '1029_842': 39.3674,
    '1029_843': 39.0984,
    '1029_844': 38.8393,
    '1029_845': 38.5956,
    '1029_846': 38.3606,
    '1029_847': 38.1349,
    '1029_848': 37.921,
    '1030_656': 36.259,
    '1030_657': 36.3402,
    '1030_658': 36.4209,
    '1030_659': 36.5018,
    '1030_660': 36.5849,
    '1030_661': 36.6732,
    '1030_662': 36.7715,
    '1030_663': 36.8667,
    '1030_664': 36.9523,
    '1030_665': 37.0322,
    '1030_666': 37.1101,
    '1030_667': 37.1877,
    '1030_668': 37.2634,
    '1030_669': 37.3263,
    '1030_670': 37.3867,
    '1030_671': 37.4451,
    '1030_672': 37.5005,
    '1030_673': 37.5399,
    '1030_674': 37.5653,
    '1030_675': 37.5711,
    '1030_676': 37.5673,
    '1030_677': 37.5686,
    '1030_678': 37.5697,
    '1030_679': 37.5626,
    '1030_680': 37.544,
    '1030_681': 37.5095,
    '1030_682': 37.4505,
    '1030_683': 37.3714,
    '1030_684': 37.2837,
    '1030_685': 37.1894,
    '1030_686': 37.0901,
    '1030_687': 36.9932,
    '1030_688': 36.9032,
    '1030_689': 36.8206,
    '1030_690': 36.7522,
    '1030_691': 36.7,
    '1030_692': 36.66,
    '1030_693': 36.6305,
    '1030_694': 36.6135,
    '1030_695': 36.6032,
    '1030_696': 36.6124,
    '1030_697': 36.6292,
    '1030_698': 36.6494,
    '1030_699': 36.6722,
    '1030_700': 36.6974,
    '1030_701': 36.73,
    '1030_702': 36.7687,
    '1030_703': 36.8122,
    '1030_704': 36.8625,
    '1030_705': 36.915,
    '1030_706': 36.9696,
    '1030_707': 37.0251,
    '1030_708': 37.0813,
    '1030_709': 37.1409,
    '1030_710': 37.205,
    '1030_711': 37.2696,
    '1030_712': 37.3373,
    '1030_713': 37.4126,
    '1030_714': 37.4964,
    '1030_715': 37.589,
    '1030_716': 37.6919,
    '1030_717': 37.809,
    '1030_718': 37.9293,
    '1030_719': 38.0579,
    '1030_720': 38.188,
    '1030_721': 38.3132,
    '1030_722': 38.4312,
    '1030_723': 38.5441,
    '1030_724': 38.6503,
    '1030_725': 38.7405,
    '1030_726': 38.8256,
    '1030_727': 38.9103,
    '1030_728': 38.985,
    '1030_729': 39.0414,
    '1030_730': 39.0992,
    '1030_731': 39.1609,
    '1030_732': 39.2255,
    '1030_733': 39.2933,
    '1030_734': 39.3645,
    '1030_735': 39.4445,
    '1030_736': 39.532,
    '1030_737': 39.6253,
    '1030_738': 39.726,
    '1030_739': 39.8175,
    '1030_740': 39.8998,
    '1030_741': 39.966,
    '1030_742': 40.0195,
    '1030_743': 40.0593,
    '1030_744': 40.0999,
    '1030_745': 40.1499,
    '1030_746': 40.2022,
    '1030_747': 40.2535,
    '1030_748': 40.3198,
    '1030_749': 40.3864,
    '1030_750': 40.4617,
    '1030_751': 40.5753,
    '1030_752': 40.7191,
    '1030_753': 40.8664,
    '1030_754': 41.0183,
    '1030_755': 41.1676,
    '1030_756': 41.3062,
    '1030_757': 41.4384,
    '1030_758': 41.5845,
    '1030_759': 41.7515,
    '1030_760': 41.9359,
    '1030_761': 42.1099,
    '1030_762': 42.2505,
    '1030_763': 42.3856,
    '1030_764': 42.52,
    '1030_765': 42.6513,
    '1030_766': 42.7666,
    '1030_767': 42.8706,
    '1030_768': 42.9513,
    '1030_769': 43.0147,
    '1030_770': 43.0778,
    '1030_771': 43.1524,
    '1030_772': 43.2372,
    '1030_773': 43.3034,
    '1030_774': 43.3466,
    '1030_775': 43.3695,
    '1030_776': 43.3737,
    '1030_777': 43.372,
    '1030_778': 43.354,
    '1030_779': 43.3347,
    '1030_780': 43.3028,
    '1030_781': 43.2889,
    '1030_782': 43.3087,
    '1030_783': 43.3411,
    '1030_784': 43.3669,
    '1030_785': 43.3998,
    '1030_786': 43.4225,
    '1030_787': 43.4329,
    '1030_788': 43.4455,
    '1030_789': 43.4549,
    '1030_790': 43.475,
    '1030_791': 43.5107,
    '1030_792': 43.5593,
    '1030_793': 43.6244,
    '1030_794': 43.6758,
    '1030_795': 43.7191,
    '1030_796': 43.7206,
    '1030_797': 43.7393,
    '1030_798': 43.73,
    '1030_799': 43.6955,
    '1030_800': 43.6307,
    '1030_801': 43.5354,
    '1030_802': 43.4395,
    '1030_803': 43.358,
    '1030_804': 43.2876,
    '1030_805': 43.2242,
    '1030_806': 43.1718,
    '1030_807': 43.14,
    '1030_808': 43.1273,
    '1030_809': 43.1172,
    '1030_810': 43.1109,
    '1030_811': 43.1025,
    '1030_812': 43.084,
    '1030_813': 43.0755,
    '1030_814': 43.0888,
    '1030_815': 43.1101,
    '1030_816': 43.131,
    '1030_817': 43.1496,
    '1030_818': 43.1622,
    '1030_819': 43.1703,
    '1030_820': 43.1727,
    '1030_821': 43.1645,
    '1030_822': 43.1457,
    '1030_823': 43.1175,
    '1030_824': 43.0768,
    '1030_825': 43.0229,
    '1030_826': 42.9454,
    '1030_827': 42.8368,
    '1030_828': 42.708,
    '1030_829': 42.5607,
    '1030_830': 42.4003,
    '1030_831': 42.2242,
    '1030_832': 42.037,
    '1030_833': 41.8319,
    '1030_834': 41.6402,
    '1030_835': 41.4503,
    '1030_836': 41.2307,
    '1030_837': 40.9623,
    '1030_838': 40.6605,
    '1030_839': 40.3515,
    '1030_840': 40.0382,
    '1030_841': 39.748,
    '1030_842': 39.4708,
    '1030_843': 39.2055,
    '1030_844': 38.9504,
    '1030_845': 38.7084,
    '1030_846': 38.4734,
    '1030_847': 38.249,
    '1030_848': 38.0362,
    '1031_656': 36.2699,
    '1031_657': 36.347,
    '1031_658': 36.4259,
    '1031_659': 36.5045,
    '1031_660': 36.5856,
    '1031_661': 36.6715,
    '1031_662': 36.7617,
    '1031_663': 36.8537,
    '1031_664': 36.9389,
    '1031_665': 37.0196,
    '1031_666': 37.0992,
    '1031_667': 37.1799,
    '1031_668': 37.26,
    '1031_669': 37.3301,
    '1031_670': 37.3948,
    '1031_671': 37.451,
    '1031_672': 37.5066,
    '1031_673': 37.549,
    '1031_674': 37.5684,
    '1031_675': 37.5702,
    '1031_676': 37.5636,
    '1031_677': 37.5605,
    '1031_678': 37.5642,
    '1031_679': 37.5582,
    '1031_680': 37.5451,
    '1031_681': 37.5151,
    '1031_682': 37.4583,
    '1031_683': 37.3811,
    '1031_684': 37.2981,
    '1031_685': 37.2068,
    '1031_686': 37.1084,
    '1031_687': 37.0113,
    '1031_688': 36.924,
    '1031_689': 36.8469,
    '1031_690': 36.7761,
    '1031_691': 36.7222,
    '1031_692': 36.6759,
    '1031_693': 36.6381,
    '1031_694': 36.6122,
    '1031_695': 36.6026,
    '1031_696': 36.6078,
    '1031_697': 36.6212,
    '1031_698': 36.6365,
    '1031_699': 36.6544,
    '1031_700': 36.6741,
    '1031_701': 36.7021,
    '1031_702': 36.7379,
    '1031_703': 36.7772,
    '1031_704': 36.8206,
    '1031_705': 36.8665,
    '1031_706': 36.9157,
    '1031_707': 36.9659,
    '1031_708': 37.0153,
    '1031_709': 37.0665,
    '1031_710': 37.1203,
    '1031_711': 37.174,
    '1031_712': 37.234,
    '1031_713': 37.3035,
    '1031_714': 37.3824,
    '1031_715': 37.4702,
    '1031_716': 37.5694,
    '1031_717': 37.6791,
    '1031_718': 37.7958,
    '1031_719': 37.9235,
    '1031_720': 38.0532,
    '1031_721': 38.1767,
    '1031_722': 38.2933,
    '1031_723': 38.4077,
    '1031_724': 38.5171,
    '1031_725': 38.6126,
    '1031_726': 38.7007,
    '1031_727': 38.7843,
    '1031_728': 38.861,
    '1031_729': 38.9278,
    '1031_730': 38.9913,
    '1031_731': 39.0572,
    '1031_732': 39.1255,
    '1031_733': 39.1963,
    '1031_734': 39.2694,
    '1031_735': 39.3503,
    '1031_736': 39.4392,
    '1031_737': 39.533,
    '1031_738': 39.6345,
    '1031_739': 39.7309,
    '1031_740': 39.8183,
    '1031_741': 39.8894,
    '1031_742': 39.9385,
    '1031_743': 39.9729,
    '1031_744': 40.0079,
    '1031_745': 40.0484,
    '1031_746': 40.1015,
    '1031_747': 40.1535,
    '1031_748': 40.2183,
    '1031_749': 40.2932,
    '1031_750': 40.3683,
    '1031_751': 40.474,
    '1031_752': 40.6123,
    '1031_753': 40.7565,
    '1031_754': 40.9048,
    '1031_755': 41.0529,
    '1031_756': 41.1986,
    '1031_757': 41.3366,
    '1031_758': 41.4744,
    '1031_759': 41.6368,
    '1031_760': 41.8176,
    '1031_761': 41.986,
    '1031_762': 42.1205,
    '1031_763': 42.2558,
    '1031_764': 42.4005,
    '1031_765': 42.5446,
    '1031_766': 42.6738,
    '1031_767': 42.7873,
    '1031_768': 42.8752,
    '1031_769': 42.9492,
    '1031_770': 43.02,
    '1031_771': 43.0928,
    '1031_772': 43.1695,
    '1031_773': 43.2343,
    '1031_774': 43.272,
    '1031_775': 43.2894,
    '1031_776': 43.292,
    '1031_777': 43.293,
    '1031_778': 43.2885,
    '1031_779': 43.2698,
    '1031_780': 43.2458,
    '1031_781': 43.2279,
    '1031_782': 43.2427,
    '1031_783': 43.2743,
    '1031_784': 43.3081,
    '1031_785': 43.3357,
    '1031_786': 43.3542,
    '1031_787': 43.3707,
    '1031_788': 43.3903,
    '1031_789': 43.4055,
    '1031_790': 43.4236,
    '1031_791': 43.453,
    '1031_792': 43.4973,
    '1031_793': 43.5528,
    '1031_794': 43.5987,
    '1031_795': 43.6292,
    '1031_796': 43.6553,
    '1031_797': 43.6796,
    '1031_798': 43.6829,
    '1031_799': 43.6707,
    '1031_800': 43.6228,
    '1031_801': 43.5333,
    '1031_802': 43.4415,
    '1031_803': 43.3601,
    '1031_804': 43.2894,
    '1031_805': 43.2233,
    '1031_806': 43.1678,
    '1031_807': 43.1301,
    '1031_808': 43.1107,
    '1031_809': 43.1013,
    '1031_810': 43.0964,
    '1031_811': 43.0778,
    '1031_812': 43.0524,
    '1031_813': 43.0507,
    '1031_814': 43.0672,
    '1031_815': 43.0916,
    '1031_816': 43.1135,
    '1031_817': 43.1323,
    '1031_818': 43.1455,
    '1031_819': 43.1519,
    '1031_820': 43.1543,
    '1031_821': 43.1493,
    '1031_822': 43.1364,
    '1031_823': 43.1152,
    '1031_824': 43.081,
    '1031_825': 43.0304,
    '1031_826': 42.9556,
    '1031_827': 42.8566,
    '1031_828': 42.7376,
    '1031_829': 42.5997,
    '1031_830': 42.4508,
    '1031_831': 42.2892,
    '1031_832': 42.1141,
    '1031_833': 41.926,
    '1031_834': 41.7517,
    '1031_835': 41.5661,
    '1031_836': 41.3431,
    '1031_837': 41.0718,
    '1031_838': 40.7657,
    '1031_839': 40.4489,
    '1031_840': 40.1325,
    '1031_841': 39.8429,
    '1031_842': 39.5684,
    '1031_843': 39.3073,
    '1031_844': 39.0557,
    '1031_845': 38.8194,
    '1031_846': 38.5838,
    '1031_847': 38.3602,
    '1031_848': 38.1557,
    '1032_656': 36.2812,
    '1032_657': 36.354,
    '1032_658': 36.4327,
    '1032_659': 36.5083,
    '1032_660': 36.5872,
    '1032_661': 36.6732,
    '1032_662': 36.7524,
    '1032_663': 36.8416,
    '1032_664': 36.9247,
    '1032_665': 37.0047,
    '1032_666': 37.0841,
    '1032_667': 37.1655,
    '1032_668': 37.2509,
    '1032_669': 37.3326,
    '1032_670': 37.4055,
    '1032_671': 37.4652,
    '1032_672': 37.516,
    '1032_673': 37.5548,
    '1032_674': 37.5676,
    '1032_675': 37.5669,
    '1032_676': 37.5582,
    '1032_677': 37.5531,
    '1032_678': 37.5572,
    '1032_679': 37.5593,
    '1032_680': 37.553,
    '1032_681': 37.5292,
    '1032_682': 37.4798,
    '1032_683': 37.409,
    '1032_684': 37.3272,
    '1032_685': 37.2373,
    '1032_686': 37.1389,
    '1032_687': 37.0407,
    '1032_688': 36.9526,
    '1032_689': 36.8769,
    '1032_690': 36.8158,
    '1032_691': 36.7689,
    '1032_692': 36.7123,
    '1032_693': 36.666,
    '1032_694': 36.6401,
    '1032_695': 36.629,
    '1032_696': 36.63,
    '1032_697': 36.6424,
    '1032_698': 36.6463,
    '1032_699': 36.6609,
    '1032_700': 36.6737,
    '1032_701': 36.699,
    '1032_702': 36.7285,
    '1032_703': 36.7598,
    '1032_704': 36.7941,
    '1032_705': 36.8321,
    '1032_706': 36.8745,
    '1032_707': 36.9191,
    '1032_708': 36.9612,
    '1032_709': 37.0041,
    '1032_710': 37.0471,
    '1032_711': 37.0933,
    '1032_712': 37.1459,
    '1032_713': 37.2105,
    '1032_714': 37.2832,
    '1032_715': 37.3659,
    '1032_716': 37.461,
    '1032_717': 37.5656,
    '1032_718': 37.6791,
    '1032_719': 37.8018,
    '1032_720': 37.9282,
    '1032_721': 38.0462,
    '1032_722': 38.1661,
    '1032_723': 38.287,
    '1032_724': 38.4056,
    '1032_725': 38.5047,
    '1032_726': 38.5945,
    '1032_727': 38.6783,
    '1032_728': 38.7567,
    '1032_729': 38.8287,
    '1032_730': 38.8952,
    '1032_731': 38.9614,
    '1032_732': 39.0311,
    '1032_733': 39.1033,
    '1032_734': 39.1784,
    '1032_735': 39.2613,
    '1032_736': 39.3533,
    '1032_737': 39.4465,
    '1032_738': 39.5506,
    '1032_739': 39.6539,
    '1032_740': 39.747,
    '1032_741': 39.8186,
    '1032_742': 39.871,
    '1032_743': 39.9056,
    '1032_744': 39.9362,
    '1032_745': 39.9595,
    '1032_746': 40.0049,
    '1032_747': 40.0573,
    '1032_748': 40.1211,
    '1032_749': 40.2021,
    '1032_750': 40.28,
    '1032_751': 40.3803,
    '1032_752': 40.5124,
    '1032_753': 40.6522,
    '1032_754': 40.7933,
    '1032_755': 40.9391,
    '1032_756': 41.0848,
    '1032_757': 41.2291,
    '1032_758': 41.3691,
    '1032_759': 41.5249,
    '1032_760': 41.7009,
    '1032_761': 41.867,
    '1032_762': 42.006,
    '1032_763': 42.1462,
    '1032_764': 42.3009,
    '1032_765': 42.4535,
    '1032_766': 42.5892,
    '1032_767': 42.7104,
    '1032_768': 42.8031,
    '1032_769': 42.8805,
    '1032_770': 42.9548,
    '1032_771': 43.0279,
    '1032_772': 43.0966,
    '1032_773': 43.156,
    '1032_774': 43.1894,
    '1032_775': 43.1998,
    '1032_776': 43.2042,
    '1032_777': 43.2057,
    '1032_778': 43.2092,
    '1032_779': 43.1983,
    '1032_780': 43.1829,
    '1032_781': 43.169,
    '1032_782': 43.1821,
    '1032_783': 43.214,
    '1032_784': 43.2472,
    '1032_785': 43.2713,
    '1032_786': 43.2929,
    '1032_787': 43.3122,
    '1032_788': 43.3398,
    '1032_789': 43.359,
    '1032_790': 43.375,
    '1032_791': 43.3998,
    '1032_792': 43.4366,
    '1032_793': 43.4779,
    '1032_794': 43.5062,
    '1032_795': 43.5275,
    '1032_796': 43.5608,
    '1032_797': 43.6044,
    '1032_798': 43.6353,
    '1032_799': 43.6249,
    '1032_800': 43.5806,
    '1032_801': 43.5156,
    '1032_802': 43.4359,
    '1032_803': 43.3583,
    '1032_804': 43.2927,
    '1032_805': 43.2255,
    '1032_806': 43.1705,
    '1032_807': 43.1297,
    '1032_808': 43.1043,
    '1032_809': 43.093,
    '1032_810': 43.0786,
    '1032_811': 43.0463,
    '1032_812': 43.0226,
    '1032_813': 43.0254,
    '1032_814': 43.0449,
    '1032_815': 43.0707,
    '1032_816': 43.0938,
    '1032_817': 43.1155,
    '1032_818': 43.1315,
    '1032_819': 43.1394,
    '1032_820': 43.1429,
    '1032_821': 43.1411,
    '1032_822': 43.1317,
    '1032_823': 43.1157,
    '1032_824': 43.0862,
    '1032_825': 43.0369,
    '1032_826': 42.9654,
    '1032_827': 42.8729,
    '1032_828': 42.7631,
    '1032_829': 42.635,
    '1032_830': 42.496,
    '1032_831': 42.3484,
    '1032_832': 42.1931,
    '1032_833': 42.0249,
    '1032_834': 41.8544,
    '1032_835': 41.6708,
    '1032_836': 41.4467,
    '1032_837': 41.1748,
    '1032_838': 40.8682,
    '1032_839': 40.5461,
    '1032_840': 40.2247,
    '1032_841': 39.935,
    '1032_842': 39.662,
    '1032_843': 39.4034,
    '1032_844': 39.1632,
    '1032_845': 38.9308,
    '1032_846': 38.701,
    '1032_847': 38.4784,
    '1032_848': 38.2668,
    '1033_656': 36.2834,
    '1033_657': 36.3584,
    '1033_658': 36.4339,
    '1033_659': 36.5094,
    '1033_660': 36.5846,
    '1033_661': 36.6664,
    '1033_662': 36.7463,
    '1033_663': 36.8287,
    '1033_664': 36.9086,
    '1033_665': 36.9864,
    '1033_666': 37.0649,
    '1033_667': 37.1454,
    '1033_668': 37.2326,
    '1033_669': 37.3223,
    '1033_670': 37.4057,
    '1033_671': 37.4745,
    '1033_672': 37.5183,
    '1033_673': 37.5488,
    '1033_674': 37.5589,
    '1033_675': 37.5567,
    '1033_676': 37.5501,
    '1033_677': 37.5487,
    '1033_678': 37.556,
    '1033_679': 37.5646,
    '1033_680': 37.563,
    '1033_681': 37.5459,
    '1033_682': 37.5061,
    '1033_683': 37.4416,
    '1033_684': 37.3616,
    '1033_685': 37.2729,
    '1033_686': 37.1779,
    '1033_687': 37.0827,
    '1033_688': 36.9935,
    '1033_689': 36.914,
    '1033_690': 36.8516,
    '1033_691': 36.8054,
    '1033_692': 36.7609,
    '1033_693': 36.7032,
    '1033_694': 36.685,
    '1033_695': 36.687,
    '1033_696': 36.6769,
    '1033_697': 36.6835,
    '1033_698': 36.6707,
    '1033_699': 36.6931,
    '1033_700': 36.705,
    '1033_701': 36.7178,
    '1033_702': 36.7356,
    '1033_703': 36.7551,
    '1033_704': 36.7778,
    '1033_705': 36.8059,
    '1033_706': 36.8387,
    '1033_707': 36.874,
    '1033_708': 36.91,
    '1033_709': 36.9476,
    '1033_710': 36.9817,
    '1033_711': 37.0227,
    '1033_712': 37.0692,
    '1033_713': 37.127,
    '1033_714': 37.1944,
    '1033_715': 37.273,
    '1033_716': 37.3632,
    '1033_717': 37.4615,
    '1033_718': 37.5688,
    '1033_719': 37.683,
    '1033_720': 37.8011,
    '1033_721': 37.9191,
    '1033_722': 38.0474,
    '1033_723': 38.1766,
    '1033_724': 38.2961,
    '1033_725': 38.4017,
    '1033_726': 38.4951,
    '1033_727': 38.5811,
    '1033_728': 38.6617,
    '1033_729': 38.7376,
    '1033_730': 38.8054,
    '1033_731': 38.8722,
    '1033_732': 38.9418,
    '1033_733': 39.0151,
    '1033_734': 39.0913,
    '1033_735': 39.1745,
    '1033_736': 39.2682,
    '1033_737': 39.3671,
    '1033_738': 39.4754,
    '1033_739': 39.5876,
    '1033_740': 39.6924,
    '1033_741': 39.7609,
    '1033_742': 39.8069,
    '1033_743': 39.8482,
    '1033_744': 39.8777,
    '1033_745': 39.8847,
    '1033_746': 39.9157,
    '1033_747': 39.9696,
    '1033_748': 40.0342,
    '1033_749': 40.1153,
    '1033_750': 40.1967,
    '1033_751': 40.2942,
    '1033_752': 40.4193,
    '1033_753': 40.5547,
    '1033_754': 40.6901,
    '1033_755': 40.8296,
    '1033_756': 40.9684,
    '1033_757': 41.1157,
    '1033_758': 41.2621,
    '1033_759': 41.4168,
    '1033_760': 41.5899,
    '1033_761': 41.755,
    '1033_762': 41.9052,
    '1033_763': 42.0538,
    '1033_764': 42.2097,
    '1033_765': 42.3699,
    '1033_766': 42.5108,
    '1033_767': 42.6335,
    '1033_768': 42.7285,
    '1033_769': 42.8091,
    '1033_770': 42.8829,
    '1033_771': 42.9539,
    '1033_772': 43.0115,
    '1033_773': 43.056,
    '1033_774': 43.0859,
    '1033_775': 43.0997,
    '1033_776': 43.1044,
    '1033_777': 43.1114,
    '1033_778': 43.1183,
    '1033_779': 43.1176,
    '1033_780': 43.1104,
    '1033_781': 43.1044,
    '1033_782': 43.116,
    '1033_783': 43.1484,
    '1033_784': 43.1796,
    '1033_785': 43.2106,
    '1033_786': 43.237,
    '1033_787': 43.2664,
    '1033_788': 43.2968,
    '1033_789': 43.3179,
    '1033_790': 43.3359,
    '1033_791': 43.3619,
    '1033_792': 43.3898,
    '1033_793': 43.4085,
    '1033_794': 43.4186,
    '1033_795': 43.4392,
    '1033_796': 43.478,
    '1033_797': 43.532,
    '1033_798': 43.5721,
    '1033_799': 43.5768,
    '1033_800': 43.543,
    '1033_801': 43.4866,
    '1033_802': 43.423,
    '1033_803': 43.3552,
    '1033_804': 43.2937,
    '1033_805': 43.2322,
    '1033_806': 43.1755,
    '1033_807': 43.1313,
    '1033_808': 43.101,
    '1033_809': 43.0888,
    '1033_810': 43.061,
    '1033_811': 43.0154,
    '1033_812': 42.9946,
    '1033_813': 42.9998,
    '1033_814': 43.0206,
    '1033_815': 43.0482,
    '1033_816': 43.0735,
    '1033_817': 43.1002,
    '1033_818': 43.1205,
    '1033_819': 43.1346,
    '1033_820': 43.1398,
    '1033_821': 43.1409,
    '1033_822': 43.1341,
    '1033_823': 43.119,
    '1033_824': 43.0921,
    '1033_825': 43.044,
    '1033_826': 42.9756,
    '1033_827': 42.889,
    '1033_828': 42.7884,
    '1033_829': 42.6723,
    '1033_830': 42.5454,
    '1033_831': 42.411,
    '1033_832': 42.2703,
    '1033_833': 42.1133,
    '1033_834': 41.9438,
    '1033_835': 41.7576,
    '1033_836': 41.5336,
    '1033_837': 41.264,
    '1033_838': 40.9627,
    '1033_839': 40.6408,
    '1033_840': 40.3171,
    '1033_841': 40.0248,
    '1033_842': 39.7584,
    '1033_843': 39.5042,
    '1033_844': 39.2732,
    '1033_845': 39.0439,
    '1033_846': 38.8174,
    '1033_847': 38.5928,
    '1033_848': 38.3743,
    '1034_656': 36.2844,
    '1034_657': 36.3589,
    '1034_658': 36.4304,
    '1034_659': 36.5011,
    '1034_660': 36.5789,
    '1034_661': 36.6602,
    '1034_662': 36.7397,
    '1034_663': 36.8178,
    '1034_664': 36.8935,
    '1034_665': 36.9677,
    '1034_666': 37.0458,
    '1034_667': 37.126,
    '1034_668': 37.2121,
    '1034_669': 37.3042,
    '1034_670': 37.3924,
    '1034_671': 37.4639,
    '1034_672': 37.512,
    '1034_673': 37.539,
    '1034_674': 37.5485,
    '1034_675': 37.547,
    '1034_676': 37.5441,
    '1034_677': 37.55,
    '1034_678': 37.56,
    '1034_679': 37.573,
    '1034_680': 37.5781,
    '1034_681': 37.5663,
    '1034_682': 37.5347,
    '1034_683': 37.4765,
    '1034_684': 37.3996,
    '1034_685': 37.3139,
    '1034_686': 37.2224,
    '1034_687': 37.1335,
    '1034_688': 37.046,
    '1034_689': 36.9622,
    '1034_690': 36.8931,
    '1034_691': 36.8346,
    '1034_692': 36.7787,
    '1034_693': 36.7384,
    '1034_694': 36.7187,
    '1034_695': 36.7097,
    '1034_696': 36.694,
    '1034_697': 36.6902,
    '1034_698': 36.7126,
    '1034_699': 36.7274,
    '1034_700': 36.735,
    '1034_701': 36.7416,
    '1034_702': 36.7514,
    '1034_703': 36.7604,
    '1034_704': 36.7705,
    '1034_705': 36.7883,
    '1034_706': 36.8108,
    '1034_707': 36.8358,
    '1034_708': 36.8632,
    '1034_709': 36.893,
    '1034_710': 36.9227,
    '1034_711': 36.9566,
    '1034_712': 36.9995,
    '1034_713': 37.0536,
    '1034_714': 37.1173,
    '1034_715': 37.1915,
    '1034_716': 37.2764,
    '1034_717': 37.3667,
    '1034_718': 37.4676,
    '1034_719': 37.5757,
    '1034_720': 37.6901,
    '1034_721': 37.8126,
    '1034_722': 37.9423,
    '1034_723': 38.0742,
    '1034_724': 38.1942,
    '1034_725': 38.3026,
    '1034_726': 38.4001,
    '1034_727': 38.4879,
    '1034_728': 38.5691,
    '1034_729': 38.6455,
    '1034_730': 38.717,
    '1034_731': 38.7867,
    '1034_732': 38.8572,
    '1034_733': 38.9307,
    '1034_734': 39.0083,
    '1034_735': 39.0921,
    '1034_736': 39.188,
    '1034_737': 39.2951,
    '1034_738': 39.4068,
    '1034_739': 39.5213,
    '1034_740': 39.6281,
    '1034_741': 39.6979,
    '1034_742': 39.7358,
    '1034_743': 39.7744,
    '1034_744': 39.8064,
    '1034_745': 39.8079,
    '1034_746': 39.8287,
    '1034_747': 39.8831,
    '1034_748': 39.9481,
    '1034_749': 40.0301,
    '1034_750': 40.1189,
    '1034_751': 40.2156,
    '1034_752': 40.3344,
    '1034_753': 40.4659,
    '1034_754': 40.5939,
    '1034_755': 40.729,
    '1034_756': 40.8659,
    '1034_757': 41.009,
    '1034_758': 41.1623,
    '1034_759': 41.3229,
    '1034_760': 41.4945,
    '1034_761': 41.6653,
    '1034_762': 41.8235,
    '1034_763': 41.9802,
    '1034_764': 42.1383,
    '1034_765': 42.291,
    '1034_766': 42.439,
    '1034_767': 42.5645,
    '1034_768': 42.6574,
    '1034_769': 42.7368,
    '1034_770': 42.8117,
    '1034_771': 42.8745,
    '1034_772': 42.9205,
    '1034_773': 42.9616,
    '1034_774': 42.9873,
    '1034_775': 42.9947,
    '1034_776': 43.0017,
    '1034_777': 43.0185,
    '1034_778': 43.031,
    '1034_779': 43.0359,
    '1034_780': 43.0374,
    '1034_781': 43.0329,
    '1034_782': 43.0482,
    '1034_783': 43.0826,
    '1034_784': 43.1151,
    '1034_785': 43.1514,
    '1034_786': 43.1877,
    '1034_787': 43.2263,
    '1034_788': 43.2608,
    '1034_789': 43.2848,
    '1034_790': 43.3065,
    '1034_791': 43.3316,
    '1034_792': 43.3546,
    '1034_793': 43.3623,
    '1034_794': 43.3546,
    '1034_795': 43.3688,
    '1034_796': 43.4057,
    '1034_797': 43.4626,
    '1034_798': 43.5078,
    '1034_799': 43.5199,
    '1034_800': 43.4944,
    '1034_801': 43.4478,
    '1034_802': 43.4018,
    '1034_803': 43.3475,
    '1034_804': 43.2965,
    '1034_805': 43.2412,
    '1034_806': 43.1859,
    '1034_807': 43.1355,
    '1034_808': 43.1007,
    '1034_809': 43.0774,
    '1034_810': 43.039,
    '1034_811': 42.991,
    '1034_812': 42.9709,
    '1034_813': 42.9759,
    '1034_814': 42.997,
    '1034_815': 43.0258,
    '1034_816': 43.0542,
    '1034_817': 43.0834,
    '1034_818': 43.1086,
    '1034_819': 43.1319,
    '1034_820': 43.1439,
    '1034_821': 43.1479,
    '1034_822': 43.1416,
    '1034_823': 43.1271,
    '1034_824': 43.1008,
    '1034_825': 43.0519,
    '1034_826': 42.988,
    '1034_827': 42.9108,
    '1034_828': 42.8208,
    '1034_829': 42.7181,
    '1034_830': 42.6025,
    '1034_831': 42.4745,
    '1034_832': 42.341,
    '1034_833': 42.1894,
    '1034_834': 42.0165,
    '1034_835': 41.8297,
    '1034_836': 41.6077,
    '1034_837': 41.3438,
    '1034_838': 41.0508,
    '1034_839': 40.7328,
    '1034_840': 40.4123,
    '1034_841': 40.1237,
    '1034_842': 39.8635,
    '1034_843': 39.6155,
    '1034_844': 39.3823,
    '1034_845': 39.1548,
    '1034_846': 38.9311,
    '1034_847': 38.7037,
    '1034_848': 38.4822,
    '1035_656': 36.2823,
    '1035_657': 36.3559,
    '1035_658': 36.425,
    '1035_659': 36.4929,
    '1035_660': 36.5722,
    '1035_661': 36.6546,
    '1035_662': 36.7325,
    '1035_663': 36.806,
    '1035_664': 36.876,
    '1035_665': 36.9468,
    '1035_666': 37.0244,
    '1035_667': 37.1034,
    '1035_668': 37.1863,
    '1035_669': 37.2737,
    '1035_670': 37.3618,
    '1035_671': 37.4343,
    '1035_672': 37.4847,
    '1035_673': 37.5175,
    '1035_674': 37.5327,
    '1035_675': 37.5381,
    '1035_676': 37.544,
    '1035_677': 37.5561,
    '1035_678': 37.5706,
    '1035_679': 37.5887,
    '1035_680': 37.596,
    '1035_681': 37.5876,
    '1035_682': 37.5606,
    '1035_683': 37.5106,
    '1035_684': 37.4403,
    '1035_685': 37.3619,
    '1035_686': 37.2769,
    '1035_687': 37.1977,
    '1035_688': 37.1156,
    '1035_689': 37.0327,
    '1035_690': 36.9558,
    '1035_691': 36.8881,
    '1035_692': 36.8318,
    '1035_693': 36.7902,
    '1035_694': 36.7655,
    '1035_695': 36.7549,
    '1035_696': 36.7476,
    '1035_697': 36.7477,
    '1035_698': 36.7603,
    '1035_699': 36.7679,
    '1035_700': 36.7686,
    '1035_701': 36.7669,
    '1035_702': 36.7663,
    '1035_703': 36.774,
    '1035_704': 36.7749,
    '1035_705': 36.782,
    '1035_706': 36.7932,
    '1035_707': 36.8067,
    '1035_708': 36.8248,
    '1035_709': 36.8475,
    '1035_710': 36.8723,
    '1035_711': 36.9009,
    '1035_712': 36.9388,
    '1035_713': 36.9873,
    '1035_714': 37.0454,
    '1035_715': 37.1141,
    '1035_716': 37.1936,
    '1035_717': 37.2803,
    '1035_718': 37.3749,
    '1035_719': 37.4781,
    '1035_720': 37.5918,
    '1035_721': 37.7175,
    '1035_722': 37.8468,
    '1035_723': 37.9819,
    '1035_724': 38.1009,
    '1035_725': 38.2087,
    '1035_726': 38.3075,
    '1035_727': 38.3949,
    '1035_728': 38.4758,
    '1035_729': 38.5533,
    '1035_730': 38.6284,
    '1035_731': 38.7014,
    '1035_732': 38.7738,
    '1035_733': 38.847,
    '1035_734': 38.924,
    '1035_735': 39.0084,
    '1035_736': 39.1086,
    '1035_737': 39.2241,
    '1035_738': 39.3417,
    '1035_739': 39.4602,
    '1035_740': 39.563,
    '1035_741': 39.6302,
    '1035_742': 39.6643,
    '1035_743': 39.6882,
    '1035_744': 39.7177,
    '1035_745': 39.7219,
    '1035_746': 39.7433,
    '1035_747': 39.7971,
    '1035_748': 39.8619,
    '1035_749': 39.9455,
    '1035_750': 40.0394,
    '1035_751': 40.1368,
    '1035_752': 40.252,
    '1035_753': 40.3773,
    '1035_754': 40.5051,
    '1035_755': 40.639,
    '1035_756': 40.7773,
    '1035_757': 40.9224,
    '1035_758': 41.0847,
    '1035_759': 41.245,
    '1035_760': 41.418,
    '1035_761': 41.5973,
    '1035_762': 41.7629,
    '1035_763': 41.9234,
    '1035_764': 42.0753,
    '1035_765': 42.2211,
    '1035_766': 42.3715,
    '1035_767': 42.4952,
    '1035_768': 42.5884,
    '1035_769': 42.6619,
    '1035_770': 42.7339,
    '1035_771': 42.7843,
    '1035_772': 42.823,
    '1035_773': 42.8556,
    '1035_774': 42.8763,
    '1035_775': 42.8866,
    '1035_776': 42.8995,
    '1035_777': 42.9235,
    '1035_778': 42.9412,
    '1035_779': 42.9503,
    '1035_780': 42.956,
    '1035_781': 42.9576,
    '1035_782': 42.9734,
    '1035_783': 43.0059,
    '1035_784': 43.0511,
    '1035_785': 43.0938,
    '1035_786': 43.1393,
    '1035_787': 43.1793,
    '1035_788': 43.2122,
    '1035_789': 43.2485,
    '1035_790': 43.277,
    '1035_791': 43.3018,
    '1035_792': 43.3195,
    '1035_793': 43.3204,
    '1035_794': 43.3066,
    '1035_795': 43.3087,
    '1035_796': 43.3399,
    '1035_797': 43.3981,
    '1035_798': 43.4423,
    '1035_799': 43.4515,
    '1035_800': 43.436,
    '1035_801': 43.4035,
    '1035_802': 43.3739,
    '1035_803': 43.3371,
    '1035_804': 43.2918,
    '1035_805': 43.2475,
    '1035_806': 43.1895,
    '1035_807': 43.1348,
    '1035_808': 43.0992,
    '1035_809': 43.0652,
    '1035_810': 43.0105,
    '1035_811': 42.9683,
    '1035_812': 42.9497,
    '1035_813': 42.9523,
    '1035_814': 42.9735,
    '1035_815': 43.0037,
    '1035_816': 43.033,
    '1035_817': 43.0624,
    '1035_818': 43.0922,
    '1035_819': 43.1235,
    '1035_820': 43.1455,
    '1035_821': 43.1524,
    '1035_822': 43.147,
    '1035_823': 43.1346,
    '1035_824': 43.109,
    '1035_825': 43.063,
    '1035_826': 43.0048,
    '1035_827': 42.9388,
    '1035_828': 42.8629,
    '1035_829': 42.7675,
    '1035_830': 42.6565,
    '1035_831': 42.5324,
    '1035_832': 42.3985,
    '1035_833': 42.2448,
    '1035_834': 42.0699,
    '1035_835': 41.8837,
    '1035_836': 41.6677,
    '1035_837': 41.4183,
    '1035_838': 41.1352,
    '1035_839': 40.8268,
    '1035_840': 40.5079,
    '1035_841': 40.2244,
    '1035_842': 39.9688,
    '1035_843': 39.7232,
    '1035_844': 39.4875,
    '1035_845': 39.258,
    '1035_846': 39.0324,
    '1035_847': 38.8073,
    '1035_848': 38.5868,
    '1036_656': 36.2734,
    '1036_657': 36.3443,
    '1036_658': 36.4133,
    '1036_659': 36.4864,
    '1036_660': 36.5644,
    '1036_661': 36.6476,
    '1036_662': 36.7237,
    '1036_663': 36.794,
    '1036_664': 36.8596,
    '1036_665': 36.9302,
    '1036_666': 37.0027,
    '1036_667': 37.0775,
    '1036_668': 37.1554,
    '1036_669': 37.2373,
    '1036_670': 37.3231,
    '1036_671': 37.3931,
    '1036_672': 37.4449,
    '1036_673': 37.4823,
    '1036_674': 37.5075,
    '1036_675': 37.5272,
    '1036_676': 37.5444,
    '1036_677': 37.5643,
    '1036_678': 37.5846,
    '1036_679': 37.6032,
    '1036_680': 37.6118,
    '1036_681': 37.6065,
    '1036_682': 37.584,
    '1036_683': 37.5451,
    '1036_684': 37.487,
    '1036_685': 37.4205,
    '1036_686': 37.3479,
    '1036_687': 37.2759,
    '1036_688': 37.2003,
    '1036_689': 37.1194,
    '1036_690': 37.0368,
    '1036_691': 36.9579,
    '1036_692': 36.9079,
    '1036_693': 36.8645,
    '1036_694': 36.836,
    '1036_695': 36.8305,
    '1036_696': 36.8281,
    '1036_697': 36.8264,
    '1036_698': 36.8256,
    '1036_699': 36.8214,
    '1036_700': 36.8135,
    '1036_701': 36.8054,
    '1036_702': 36.7989,
    '1036_703': 36.8046,
    '1036_704': 36.7909,
    '1036_705': 36.79,
    '1036_706': 36.7899,
    '1036_707': 36.7915,
    '1036_708': 36.7999,
    '1036_709': 36.8138,
    '1036_710': 36.8304,
    '1036_711': 36.8534,
    '1036_712': 36.8846,
    '1036_713': 36.9257,
    '1036_714': 36.9767,
    '1036_715': 37.039,
    '1036_716': 37.1138,
    '1036_717': 37.1975,
    '1036_718': 37.2862,
    '1036_719': 37.3852,
    '1036_720': 37.5004,
    '1036_721': 37.6357,
    '1036_722': 37.7622,
    '1036_723': 37.892,
    '1036_724': 38.0093,
    '1036_725': 38.1153,
    '1036_726': 38.2126,
    '1036_727': 38.2996,
    '1036_728': 38.3797,
    '1036_729': 38.459,
    '1036_730': 38.5384,
    '1036_731': 38.6157,
    '1036_732': 38.6908,
    '1036_733': 38.7642,
    '1036_734': 38.8398,
    '1036_735': 38.9236,
    '1036_736': 39.0268,
    '1036_737': 39.1492,
    '1036_738': 39.2779,
    '1036_739': 39.4044,
    '1036_740': 39.5042,
    '1036_741': 39.5625,
    '1036_742': 39.5809,
    '1036_743': 39.596,
    '1036_744': 39.6206,
    '1036_745': 39.6332,
    '1036_746': 39.6643,
    '1036_747': 39.7171,
    '1036_748': 39.7777,
    '1036_749': 39.8615,
    '1036_750': 39.9591,
    '1036_751': 40.0571,
    '1036_752': 40.1726,
    '1036_753': 40.2989,
    '1036_754': 40.429,
    '1036_755': 40.5662,
    '1036_756': 40.706,
    '1036_757': 40.856,
    '1036_758': 41.0191,
    '1036_759': 41.1796,
    '1036_760': 41.3559,
    '1036_761': 41.5413,
    '1036_762': 41.7073,
    '1036_763': 41.8615,
    '1036_764': 42.0074,
    '1036_765': 42.1497,
    '1036_766': 42.3009,
    '1036_767': 42.4223,
    '1036_768': 42.5146,
    '1036_769': 42.5842,
    '1036_770': 42.6468,
    '1036_771': 42.6908,
    '1036_772': 42.7196,
    '1036_773': 42.7475,
    '1036_774': 42.7676,
    '1036_775': 42.7826,
    '1036_776': 42.8025,
    '1036_777': 42.8301,
    '1036_778': 42.849,
    '1036_779': 42.8597,
    '1036_780': 42.8685,
    '1036_781': 42.8757,
    '1036_782': 42.896,
    '1036_783': 42.9346,
    '1036_784': 42.978,
    '1036_785': 43.0271,
    '1036_786': 43.0838,
    '1036_787': 43.1303,
    '1036_788': 43.1715,
    '1036_789': 43.2111,
    '1036_790': 43.2477,
    '1036_791': 43.2753,
    '1036_792': 43.2891,
    '1036_793': 43.2876,
    '1036_794': 43.2698,
    '1036_795': 43.2645,
    '1036_796': 43.2851,
    '1036_797': 43.3343,
    '1036_798': 43.3783,
    '1036_799': 43.3951,
    '1036_800': 43.3833,
    '1036_801': 43.3708,
    '1036_802': 43.3552,
    '1036_803': 43.3286,
    '1036_804': 43.2939,
    '1036_805': 43.2545,
    '1036_806': 43.1938,
    '1036_807': 43.1344,
    '1036_808': 43.0932,
    '1036_809': 43.0502,
    '1036_810': 42.9954,
    '1036_811': 42.9517,
    '1036_812': 42.931,
    '1036_813': 42.9311,
    '1036_814': 42.9505,
    '1036_815': 42.9806,
    '1036_816': 43.012,
    '1036_817': 43.0401,
    '1036_818': 43.0703,
    '1036_819': 43.1056,
    '1036_820': 43.134,
    '1036_821': 43.1481,
    '1036_822': 43.1497,
    '1036_823': 43.1387,
    '1036_824': 43.1145,
    '1036_825': 43.0759,
    '1036_826': 43.0263,
    '1036_827': 42.9733,
    '1036_828': 42.9099,
    '1036_829': 42.8184,
    '1036_830': 42.7086,
    '1036_831': 42.5826,
    '1036_832': 42.4458,
    '1036_833': 42.2888,
    '1036_834': 42.1161,
    '1036_835': 41.9317,
    '1036_836': 41.7204,
    '1036_837': 41.4811,
    '1036_838': 41.2073,
    '1036_839': 40.9049,
    '1036_840': 40.601,
    '1036_841': 40.3247,
    '1036_842': 40.071,
    '1036_843': 39.8257,
    '1036_844': 39.5881,
    '1036_845': 39.3562,
    '1036_846': 39.1292,
    '1036_847': 38.9054,
    '1036_848': 38.6863,
    '1037_656': 36.2665,
    '1037_657': 36.3359,
    '1037_658': 36.4079,
    '1037_659': 36.4771,
    '1037_660': 36.5507,
    '1037_661': 36.6284,
    '1037_662': 36.7066,
    '1037_663': 36.7771,
    '1037_664': 36.8415,
    '1037_665': 36.9066,
    '1037_666': 36.9759,
    '1037_667': 37.0486,
    '1037_668': 37.1228,
    '1037_669': 37.2007,
    '1037_670': 37.2799,
    '1037_671': 37.3445,
    '1037_672': 37.3991,
    '1037_673': 37.4455,
    '1037_674': 37.4842,
    '1037_675': 37.5122,
    '1037_676': 37.537,
    '1037_677': 37.5685,
    '1037_678': 37.5967,
    '1037_679': 37.6181,
    '1037_680': 37.6268,
    '1037_681': 37.6226,
    '1037_682': 37.6076,
    '1037_683': 37.58,
    '1037_684': 37.5354,
    '1037_685': 37.4824,
    '1037_686': 37.4221,
    '1037_687': 37.3612,
    '1037_688': 37.297,
    '1037_689': 37.2253,
    '1037_690': 37.1471,
    '1037_691': 37.072,
    '1037_692': 37.0184,
    '1037_693': 36.9749,
    '1037_694': 36.9464,
    '1037_695': 36.9364,
    '1037_696': 36.93,
    '1037_697': 36.9206,
    '1037_698': 36.9057,
    '1037_699': 36.8826,
    '1037_700': 36.8698,
    '1037_701': 36.8561,
    '1037_702': 36.8398,
    '1037_703': 36.8324,
    '1037_704': 36.8249,
    '1037_705': 36.8158,
    '1037_706': 36.8041,
    '1037_707': 36.7997,
    '1037_708': 36.7968,
    '1037_709': 36.7936,
    '1037_710': 36.7959,
    '1037_711': 36.8111,
    '1037_712': 36.8336,
    '1037_713': 36.866,
    '1037_714': 36.9087,
    '1037_715': 36.964,
    '1037_716': 37.0332,
    '1037_717': 37.1125,
    '1037_718': 37.1993,
    '1037_719': 37.2973,
    '1037_720': 37.4177,
    '1037_721': 37.5704,
    '1037_722': 37.6816,
    '1037_723': 37.8063,
    '1037_724': 37.9172,
    '1037_725': 38.0212,
    '1037_726': 38.1163,
    '1037_727': 38.2003,
    '1037_728': 38.276,
    '1037_729': 38.3582,
    '1037_730': 38.442,
    '1037_731': 38.5258,
    '1037_732': 38.6063,
    '1037_733': 38.6813,
    '1037_734': 38.7566,
    '1037_735': 38.8396,
    '1037_736': 38.9418,
    '1037_737': 39.0691,
    '1037_738': 39.205,
    '1037_739': 39.3351,
    '1037_740': 39.4275,
    '1037_741': 39.4788,
    '1037_742': 39.4932,
    '1037_743': 39.5001,
    '1037_744': 39.52,
    '1037_745': 39.5461,
    '1037_746': 39.5921,
    '1037_747': 39.6399,
    '1037_748': 39.6964,
    '1037_749': 39.7803,
    '1037_750': 39.8801,
    '1037_751': 39.9761,
    '1037_752': 40.0931,
    '1037_753': 40.226,
    '1037_754': 40.3667,
    '1037_755': 40.509,
    '1037_756': 40.6489,
    '1037_757': 40.8023,
    '1037_758': 40.9615,
    '1037_759': 41.1179,
    '1037_760': 41.2892,
    '1037_761': 41.469,
    '1037_762': 41.6294,
    '1037_763': 41.7837,
    '1037_764': 41.9317,
    '1037_765': 42.0734,
    '1037_766': 42.2193,
    '1037_767': 42.3424,
    '1037_768': 42.435,
    '1037_769': 42.5045,
    '1037_770': 42.5585,
    '1037_771': 42.5944,
    '1037_772': 42.6206,
    '1037_773': 42.6477,
    '1037_774': 42.6676,
    '1037_775': 42.6868,
    '1037_776': 42.7121,
    '1037_777': 42.7399,
    '1037_778': 42.7572,
    '1037_779': 42.7676,
    '1037_780': 42.7793,
    '1037_781': 42.7986,
    '1037_782': 42.8288,
    '1037_783': 42.8642,
    '1037_784': 42.9071,
    '1037_785': 42.9571,
    '1037_786': 43.017,
    '1037_787': 43.0693,
    '1037_788': 43.1199,
    '1037_789': 43.1681,
    '1037_790': 43.2107,
    '1037_791': 43.242,
    '1037_792': 43.2597,
    '1037_793': 43.2552,
    '1037_794': 43.24,
    '1037_795': 43.2298,
    '1037_796': 43.2405,
    '1037_797': 43.2789,
    '1037_798': 43.3212,
    '1037_799': 43.3494,
    '1037_800': 43.3489,
    '1037_801': 43.3433,
    '1037_802': 43.3331,
    '1037_803': 43.3159,
    '1037_804': 43.2882,
    '1037_805': 43.256,
    '1037_806': 43.2028,
    '1037_807': 43.1406,
    '1037_808': 43.0893,
    '1037_809': 43.0399,
    '1037_810': 42.9827,
    '1037_811': 42.937,
    '1037_812': 42.914,
    '1037_813': 42.9116,
    '1037_814': 42.9269,
    '1037_815': 42.9552,
    '1037_816': 42.9871,
    '1037_817': 43.0176,
    '1037_818': 43.0488,
    '1037_819': 43.0823,
    '1037_820': 43.1094,
    '1037_821': 43.133,
    '1037_822': 43.1456,
    '1037_823': 43.1407,
    '1037_824': 43.1183,
    '1037_825': 43.0861,
    '1037_826': 43.0472,
    '1037_827': 43.0057,
    '1037_828': 42.9511,
    '1037_829': 42.8649,
    '1037_830': 42.7545,
    '1037_831': 42.6251,
    '1037_832': 42.4846,
    '1037_833': 42.3275,
    '1037_834': 42.1627,
    '1037_835': 41.9881,
    '1037_836': 41.785,
    '1037_837': 41.55,
    '1037_838': 41.2789,
    '1037_839': 40.9818,
    '1037_840': 40.6899,
    '1037_841': 40.4191,
    '1037_842': 40.1667,
    '1037_843': 39.9215,
    '1037_844': 39.6829,
    '1037_845': 39.4495,
    '1037_846': 39.2216,
    '1037_847': 38.9984,
    '1037_848': 38.7811,
    '1038_656': 36.2695,
    '1038_657': 36.3369,
    '1038_658': 36.4119,
    '1038_659': 36.4837,
    '1038_660': 36.555,
    '1038_661': 36.6277,
    '1038_662': 36.6971,
    '1038_663': 36.7617,
    '1038_664': 36.8212,
    '1038_665': 36.8818,
    '1038_666': 36.9473,
    '1038_667': 37.0168,
    '1038_668': 37.0893,
    '1038_669': 37.1695,
    '1038_670': 37.2495,
    '1038_671': 37.3112,
    '1038_672': 37.3658,
    '1038_673': 37.4217,
    '1038_674': 37.4633,
    '1038_675': 37.4969,
    '1038_676': 37.5268,
    '1038_677': 37.5676,
    '1038_678': 37.6031,
    '1038_679': 37.6284,
    '1038_680': 37.6386,
    '1038_681': 37.6377,
    '1038_682': 37.6286,
    '1038_683': 37.6091,
    '1038_684': 37.5799,
    '1038_685': 37.5415,
    '1038_686': 37.4916,
    '1038_687': 37.4453,
    '1038_688': 37.3944,
    '1038_689': 37.3329,
    '1038_690': 37.2587,
    '1038_691': 37.1803,
    '1038_692': 37.1166,
    '1038_693': 37.0867,
    '1038_694': 37.0643,
    '1038_695': 37.0532,
    '1038_696': 37.0426,
    '1038_697': 37.0268,
    '1038_698': 37.007,
    '1038_699': 36.9769,
    '1038_700': 36.95,
    '1038_701': 36.9236,
    '1038_702': 36.8977,
    '1038_703': 36.8786,
    '1038_704': 36.8609,
    '1038_705': 36.844,
    '1038_706': 36.8252,
    '1038_707': 36.8097,
    '1038_708': 36.7928,
    '1038_709': 36.7756,
    '1038_710': 36.7681,
    '1038_711': 36.7726,
    '1038_712': 36.7858,
    '1038_713': 36.8084,
    '1038_714': 36.8427,
    '1038_715': 36.8913,
    '1038_716': 36.9563,
    '1038_717': 37.0332,
    '1038_718': 37.1181,
    '1038_719': 37.2172,
    '1038_720': 37.3336,
    '1038_721': 37.4871,
    '1038_722': 37.6027,
    '1038_723': 37.7166,
    '1038_724': 37.8255,
    '1038_725': 37.9269,
    '1038_726': 38.018,
    '1038_727': 38.0944,
    '1038_728': 38.1659,
    '1038_729': 38.252,
    '1038_730': 38.3314,
    '1038_731': 38.4254,
    '1038_732': 38.5176,
    '1038_733': 38.5967,
    '1038_734': 38.674,
    '1038_735': 38.7581,
    '1038_736': 38.8573,
    '1038_737': 38.9787,
    '1038_738': 39.1074,
    '1038_739': 39.2319,
    '1038_740': 39.3209,
    '1038_741': 39.374,
    '1038_742': 39.3936,
    '1038_743': 39.4002,
    '1038_744': 39.4229,
    '1038_745': 39.4587,
    '1038_746': 39.5179,
    '1038_747': 39.5675,
    '1038_748': 39.6219,
    '1038_749': 39.7012,
    '1038_750': 39.8009,
    '1038_751': 39.8958,
    '1038_752': 40.0113,
    '1038_753': 40.1541,
    '1038_754': 40.308,
    '1038_755': 40.4552,
    '1038_756': 40.6,
    '1038_757': 40.7537,
    '1038_758': 40.9085,
    '1038_759': 41.0622,
    '1038_760': 41.2199,
    '1038_761': 41.3819,
    '1038_762': 41.5397,
    '1038_763': 41.7024,
    '1038_764': 41.854,
    '1038_765': 41.9946,
    '1038_766': 42.1338,
    '1038_767': 42.2566,
    '1038_768': 42.3581,
    '1038_769': 42.4309,
    '1038_770': 42.4747,
    '1038_771': 42.5024,
    '1038_772': 42.5283,
    '1038_773': 42.5548,
    '1038_774': 42.5749,
    '1038_775': 42.5973,
    '1038_776': 42.6279,
    '1038_777': 42.6527,
    '1038_778': 42.6662,
    '1038_779': 42.6744,
    '1038_780': 42.6915,
    '1038_781': 42.7268,
    '1038_782': 42.7646,
    '1038_783': 42.8005,
    '1038_784': 42.8383,
    '1038_785': 42.8884,
    '1038_786': 42.9485,
    '1038_787': 43.0067,
    '1038_788': 43.0615,
    '1038_789': 43.1155,
    '1038_790': 43.1621,
    '1038_791': 43.2007,
    '1038_792': 43.2173,
    '1038_793': 43.2233,
    '1038_794': 43.2102,
    '1038_795': 43.2022,
    '1038_796': 43.2059,
    '1038_797': 43.2386,
    '1038_798': 43.2838,
    '1038_799': 43.3096,
    '1038_800': 43.3209,
    '1038_801': 43.3166,
    '1038_802': 43.3076,
    '1038_803': 43.2947,
    '1038_804': 43.2796,
    '1038_805': 43.2578,
    '1038_806': 43.2145,
    '1038_807': 43.1556,
    '1038_808': 43.095,
    '1038_809': 43.0335,
    '1038_810': 42.9735,
    '1038_811': 42.9265,
    '1038_812': 42.9001,
    '1038_813': 42.8945,
    '1038_814': 42.9062,
    '1038_815': 42.9329,
    '1038_816': 42.9632,
    '1038_817': 42.9941,
    '1038_818': 43.0253,
    '1038_819': 43.058,
    '1038_820': 43.086,
    '1038_821': 43.1199,
    '1038_822': 43.1428,
    '1038_823': 43.1431,
    '1038_824': 43.1239,
    '1038_825': 43.096,
    '1038_826': 43.0654,
    '1038_827': 43.0316,
    '1038_828': 42.985,
    '1038_829': 42.9059,
    '1038_830': 42.7952,
    '1038_831': 42.6597,
    '1038_832': 42.5167,
    '1038_833': 42.3649,
    '1038_834': 42.2089,
    '1038_835': 42.0449,
    '1038_836': 41.8481,
    '1038_837': 41.6177,
    '1038_838': 41.352,
    '1038_839': 41.0557,
    '1038_840': 40.7727,
    '1038_841': 40.5059,
    '1038_842': 40.2556,
    '1038_843': 40.0111,
    '1038_844': 39.7722,
    '1038_845': 39.5381,
    '1038_846': 39.3098,
    '1038_847': 39.0867,
    '1038_848': 38.8706,
    '1039_656': 36.2734,
    '1039_657': 36.3385,
    '1039_658': 36.4174,
    '1039_659': 36.4881,
    '1039_660': 36.5557,
    '1039_661': 36.6243,
    '1039_662': 36.6829,
    '1039_663': 36.7406,
    '1039_664': 36.7934,
    '1039_665': 36.8511,
    '1039_666': 36.9164,
    '1039_667': 36.9808,
    '1039_668': 37.051,
    '1039_669': 37.1341,
    '1039_670': 37.2186,
    '1039_671': 37.2845,
    '1039_672': 37.3434,
    '1039_673': 37.4032,
    '1039_674': 37.4448,
    '1039_675': 37.481,
    '1039_676': 37.5168,
    '1039_677': 37.5615,
    '1039_678': 37.6039,
    '1039_679': 37.6331,
    '1039_680': 37.6469,
    '1039_681': 37.6495,
    '1039_682': 37.6439,
    '1039_683': 37.6293,
    '1039_684': 37.6094,
    '1039_685': 37.5868,
    '1039_686': 37.5488,
    '1039_687': 37.5126,
    '1039_688': 37.4676,
    '1039_689': 37.4111,
    '1039_690': 37.3469,
    '1039_691': 37.2789,
    '1039_692': 37.2235,
    '1039_693': 37.1896,
    '1039_694': 37.1692,
    '1039_695': 37.1602,
    '1039_696': 37.1476,
    '1039_697': 37.1245,
    '1039_698': 37.1014,
    '1039_699': 37.0767,
    '1039_700': 37.0436,
    '1039_701': 37.0045,
    '1039_702': 36.9652,
    '1039_703': 36.9336,
    '1039_704': 36.9072,
    '1039_705': 36.8794,
    '1039_706': 36.8509,
    '1039_707': 36.8232,
    '1039_708': 36.7965,
    '1039_709': 36.7654,
    '1039_710': 36.747,
    '1039_711': 36.7417,
    '1039_712': 36.7444,
    '1039_713': 36.7575,
    '1039_714': 36.7853,
    '1039_715': 36.8254,
    '1039_716': 36.8873,
    '1039_717': 36.9616,
    '1039_718': 37.0444,
    '1039_719': 37.1394,
    '1039_720': 37.2499,
    '1039_721': 37.3935,
    '1039_722': 37.5067,
    '1039_723': 37.617,
    '1039_724': 37.7264,
    '1039_725': 37.8265,
    '1039_726': 37.9155,
    '1039_727': 37.9953,
    '1039_728': 38.0748,
    '1039_729': 38.1576,
    '1039_730': 38.2393,
    '1039_731': 38.3274,
    '1039_732': 38.4185,
    '1039_733': 38.5061,
    '1039_734': 38.5873,
    '1039_735': 38.6736,
    '1039_736': 38.7699,
    '1039_737': 38.8796,
    '1039_738': 38.9966,
    '1039_739': 39.1082,
    '1039_740': 39.1948,
    '1039_741': 39.2587,
    '1039_742': 39.2878,
    '1039_743': 39.3048,
    '1039_744': 39.335,
    '1039_745': 39.3725,
    '1039_746': 39.4358,
    '1039_747': 39.4966,
    '1039_748': 39.5517,
    '1039_749': 39.6275,
    '1039_750': 39.7259,
    '1039_751': 39.8221,
    '1039_752': 39.9359,
    '1039_753': 40.0841,
    '1039_754': 40.2479,
    '1039_755': 40.4033,
    '1039_756': 40.5544,
    '1039_757': 40.7055,
    '1039_758': 40.8592,
    '1039_759': 41.0098,
    '1039_760': 41.1583,
    '1039_761': 41.298,
    '1039_762': 41.4546,
    '1039_763': 41.6202,
    '1039_764': 41.7716,
    '1039_765': 41.9142,
    '1039_766': 42.0518,
    '1039_767': 42.1808,
    '1039_768': 42.2902,
    '1039_769': 42.3615,
    '1039_770': 42.4065,
    '1039_771': 42.4252,
    '1039_772': 42.4438,
    '1039_773': 42.4696,
    '1039_774': 42.4951,
    '1039_775': 42.5244,
    '1039_776': 42.5556,
    '1039_777': 42.5763,
    '1039_778': 42.5836,
    '1039_779': 42.5903,
    '1039_780': 42.6154,
    '1039_781': 42.6551,
    '1039_782': 42.6984,
    '1039_783': 42.7337,
    '1039_784': 42.7704,
    '1039_785': 42.8203,
    '1039_786': 42.884,
    '1039_787': 42.9476,
    '1039_788': 43.004,
    '1039_789': 43.0554,
    '1039_790': 43.1002,
    '1039_791': 43.1362,
    '1039_792': 43.156,
    '1039_793': 43.1632,
    '1039_794': 43.1646,
    '1039_795': 43.1661,
    '1039_796': 43.1763,
    '1039_797': 43.2049,
    '1039_798': 43.2494,
    '1039_799': 43.2775,
    '1039_800': 43.2863,
    '1039_801': 43.2819,
    '1039_802': 43.2715,
    '1039_803': 43.2601,
    '1039_804': 43.2568,
    '1039_805': 43.2511,
    '1039_806': 43.2221,
    '1039_807': 43.1751,
    '1039_808': 43.1095,
    '1039_809': 43.0373,
    '1039_810': 42.9718,
    '1039_811': 42.9203,
    '1039_812': 42.8905,
    '1039_813': 42.881,
    '1039_814': 42.8899,
    '1039_815': 42.912,
    '1039_816': 42.9403,
    '1039_817': 42.9696,
    '1039_818': 42.9987,
    '1039_819': 43.0322,
    '1039_820': 43.0713,
    '1039_821': 43.1139,
    '1039_822': 43.1396,
    '1039_823': 43.1423,
    '1039_824': 43.1271,
    '1039_825': 43.1064,
    '1039_826': 43.0828,
    '1039_827': 43.0548,
    '1039_828': 43.0146,
    '1039_829': 42.9399,
    '1039_830': 42.8281,
    '1039_831': 42.6897,
    '1039_832': 42.5474,
    '1039_833': 42.4007,
    '1039_834': 42.2526,
    '1039_835': 42.093,
    '1039_836': 41.8989,
    '1039_837': 41.6719,
    '1039_838': 41.4146,
    '1039_839': 41.1213,
    '1039_840': 40.8446,
    '1039_841': 40.5838,
    '1039_842': 40.3368,
    '1039_843': 40.0944,
    '1039_844': 39.8563,
    '1039_845': 39.6225,
    '1039_846': 39.3941,
    '1039_847': 39.1709,
    '1039_848': 38.9556,
    '1040_656': 36.2619,
    '1040_657': 36.334,
    '1040_658': 36.4068,
    '1040_659': 36.4725,
    '1040_660': 36.536,
    '1040_661': 36.5961,
    '1040_662': 36.6496,
    '1040_663': 36.7001,
    '1040_664': 36.7504,
    '1040_665': 36.8071,
    '1040_666': 36.8696,
    '1040_667': 36.9357,
    '1040_668': 37.0072,
    '1040_669': 37.0893,
    '1040_670': 37.175,
    '1040_671': 37.2524,
    '1040_672': 37.3188,
    '1040_673': 37.379,
    '1040_674': 37.4244,
    '1040_675': 37.465,
    '1040_676': 37.5061,
    '1040_677': 37.5551,
    '1040_678': 37.6013,
    '1040_679': 37.6342,
    '1040_680': 37.6502,
    '1040_681': 37.6568,
    '1040_682': 37.6527,
    '1040_683': 37.6416,
    '1040_684': 37.6299,
    '1040_685': 37.6168,
    '1040_686': 37.5927,
    '1040_687': 37.5601,
    '1040_688': 37.5156,
    '1040_689': 37.461,
    '1040_690': 37.407,
    '1040_691': 37.3547,
    '1040_692': 37.309,
    '1040_693': 37.2726,
    '1040_694': 37.2487,
    '1040_695': 37.2354,
    '1040_696': 37.2213,
    '1040_697': 37.1992,
    '1040_698': 37.177,
    '1040_699': 37.1569,
    '1040_700': 37.1246,
    '1040_701': 37.083,
    '1040_702': 37.0317,
    '1040_703': 36.9927,
    '1040_704': 36.9558,
    '1040_705': 36.9163,
    '1040_706': 36.8771,
    '1040_707': 36.8398,
    '1040_708': 36.7997,
    '1040_709': 36.7564,
    '1040_710': 36.7256,
    '1040_711': 36.714,
    '1040_712': 36.7093,
    '1040_713': 36.7149,
    '1040_714': 36.7328,
    '1040_715': 36.7674,
    '1040_716': 36.8229,
    '1040_717': 36.8946,
    '1040_718': 36.9755,
    '1040_719': 37.0658,
    '1040_720': 37.1684,
    '1040_721': 37.2886,
    '1040_722': 37.3962,
    '1040_723': 37.5055,
    '1040_724': 37.6146,
    '1040_725': 37.7204,
    '1040_726': 37.8161,
    '1040_727': 37.8995,
    '1040_728': 37.9862,
    '1040_729': 38.0668,
    '1040_730': 38.1447,
    '1040_731': 38.2294,
    '1040_732': 38.3192,
    '1040_733': 38.415,
    '1040_734': 38.4973,
    '1040_735': 38.5852,
    '1040_736': 38.6784,
    '1040_737': 38.7777,
    '1040_738': 38.8813,
    '1040_739': 38.9841,
    '1040_740': 39.0732,
    '1040_741': 39.1407,
    '1040_742': 39.1812,
    '1040_743': 39.2107,
    '1040_744': 39.2459,
    '1040_745': 39.289,
    '1040_746': 39.3509,
    '1040_747': 39.423,
    '1040_748': 39.4863,
    '1040_749': 39.5593,
    '1040_750': 39.6542,
    '1040_751': 39.7543,
    '1040_752': 39.8688,
    '1040_753': 40.0155,
    '1040_754': 40.1872,
    '1040_755': 40.3539,
    '1040_756': 40.5103,
    '1040_757': 40.6603,
    '1040_758': 40.8093,
    '1040_759': 40.9559,
    '1040_760': 41.0956,
    '1040_761': 41.2251,
    '1040_762': 41.3782,
    '1040_763': 41.543,
    '1040_764': 41.6941,
    '1040_765': 41.8432,
    '1040_766': 41.9846,
    '1040_767': 42.1161,
    '1040_768': 42.2232,
    '1040_769': 42.2946,
    '1040_770': 42.3459,
    '1040_771': 42.361,
    '1040_772': 42.3695,
    '1040_773': 42.3942,
    '1040_774': 42.43,
    '1040_775': 42.4637,
    '1040_776': 42.4905,
    '1040_777': 42.505,
    '1040_778': 42.5088,
    '1040_779': 42.5243,
    '1040_780': 42.5539,
    '1040_781': 42.5925,
    '1040_782': 42.635,
    '1040_783': 42.6728,
    '1040_784': 42.7107,
    '1040_785': 42.7641,
    '1040_786': 42.828,
    '1040_787': 42.8902,
    '1040_788': 42.9462,
    '1040_789': 42.9923,
    '1040_790': 43.0314,
    '1040_791': 43.0557,
    '1040_792': 43.0756,
    '1040_793': 43.0897,
    '1040_794': 43.0992,
    '1040_795': 43.1088,
    '1040_796': 43.1272,
    '1040_797': 43.1591,
    '1040_798': 43.2035,
    '1040_799': 43.2307,
    '1040_800': 43.2356,
    '1040_801': 43.2315,
    '1040_802': 43.2263,
    '1040_803': 43.2239,
    '1040_804': 43.2291,
    '1040_805': 43.2351,
    '1040_806': 43.2244,
    '1040_807': 43.1935,
    '1040_808': 43.1344,
    '1040_809': 43.0537,
    '1040_810': 42.9792,
    '1040_811': 42.9217,
    '1040_812': 42.8869,
    '1040_813': 42.8737,
    '1040_814': 42.8793,
    '1040_815': 42.8973,
    '1040_816': 42.923,
    '1040_817': 42.9501,
    '1040_818': 42.9784,
    '1040_819': 43.0137,
    '1040_820': 43.0583,
    '1040_821': 43.1035,
    '1040_822': 43.1316,
    '1040_823': 43.1383,
    '1040_824': 43.1311,
    '1040_825': 43.1171,
    '1040_826': 43.1004,
    '1040_827': 43.0803,
    '1040_828': 43.0454,
    '1040_829': 42.9721,
    '1040_830': 42.8582,
    '1040_831': 42.7201,
    '1040_832': 42.5789,
    '1040_833': 42.4395,
    '1040_834': 42.2944,
    '1040_835': 42.1319,
    '1040_836': 41.9384,
    '1040_837': 41.7179,
    '1040_838': 41.4618,
    '1040_839': 41.174,
    '1040_840': 40.9058,
    '1040_841': 40.653,
    '1040_842': 40.4111,
    '1040_843': 40.1725,
    '1040_844': 39.9371,
    '1040_845': 39.7049,
    '1040_846': 39.4776,
    '1040_847': 39.2555,
    '1040_848': 39.0414,
    '1041_656': 36.242,
    '1041_657': 36.3178,
    '1041_658': 36.3865,
    '1041_659': 36.4449,
    '1041_660': 36.505,
    '1041_661': 36.5599,
    '1041_662': 36.6101,
    '1041_663': 36.6564,
    '1041_664': 36.7049,
    '1041_665': 36.7623,
    '1041_666': 36.8226,
    '1041_667': 36.8902,
    '1041_668': 36.9616,
    '1041_669': 37.0423,
    '1041_670': 37.1286,
    '1041_671': 37.2149,
    '1041_672': 37.2863,
    '1041_673': 37.3486,
    '1041_674': 37.3949,
    '1041_675': 37.4363,
    '1041_676': 37.4826,
    '1041_677': 37.5374,
    '1041_678': 37.5943,
    '1041_679': 37.6302,
    '1041_680': 37.6475,
    '1041_681': 37.6532,
    '1041_682': 37.6531,
    '1041_683': 37.6452,
    '1041_684': 37.6369,
    '1041_685': 37.6327,
    '1041_686': 37.6223,
    '1041_687': 37.595,
    '1041_688': 37.5507,
    '1041_689': 37.4963,
    '1041_690': 37.4456,
    '1041_691': 37.4023,
    '1041_692': 37.3644,
    '1041_693': 37.3307,
    '1041_694': 37.3047,
    '1041_695': 37.2884,
    '1041_696': 37.2738,
    '1041_697': 37.2534,
    '1041_698': 37.2348,
    '1041_699': 37.216,
    '1041_700': 37.1863,
    '1041_701': 37.1446,
    '1041_702': 37.0938,
    '1041_703': 37.046,
    '1041_704': 37.0018,
    '1041_705': 36.9526,
    '1041_706': 36.9014,
    '1041_707': 36.8498,
    '1041_708': 36.794,
    '1041_709': 36.7434,
    '1041_710': 36.7113,
    '1041_711': 36.6901,
    '1041_712': 36.6779,
    '1041_713': 36.6764,
    '1041_714': 36.6879,
    '1041_715': 36.7155,
    '1041_716': 36.7635,
    '1041_717': 36.8281,
    '1041_718': 36.9078,
    '1041_719': 36.9949,
    '1041_720': 37.0875,
    '1041_721': 37.1881,
    '1041_722': 37.2915,
    '1041_723': 37.3986,
    '1041_724': 37.5065,
    '1041_725': 37.613,
    '1041_726': 37.7134,
    '1041_727': 37.8026,
    '1041_728': 37.8874,
    '1041_729': 37.9715,
    '1041_730': 38.052,
    '1041_731': 38.1359,
    '1041_732': 38.2237,
    '1041_733': 38.3135,
    '1041_734': 38.4071,
    '1041_735': 38.4959,
    '1041_736': 38.5856,
    '1041_737': 38.6786,
    '1041_738': 38.7739,
    '1041_739': 38.8682,
    '1041_740': 38.956,
    '1041_741': 39.028,
    '1041_742': 39.0792,
    '1041_743': 39.1174,
    '1041_744': 39.159,
    '1041_745': 39.2121,
    '1041_746': 39.2746,
    '1041_747': 39.3489,
    '1041_748': 39.4235,
    '1041_749': 39.4975,
    '1041_750': 39.5877,
    '1041_751': 39.689,
    '1041_752': 39.8046,
    '1041_753': 39.9483,
    '1041_754': 40.1221,
    '1041_755': 40.2986,
    '1041_756': 40.46,
    '1041_757': 40.6112,
    '1041_758': 40.7544,
    '1041_759': 40.8935,
    '1041_760': 41.0254,
    '1041_761': 41.1549,
    '1041_762': 41.3033,
    '1041_763': 41.4673,
    '1041_764': 41.6316,
    '1041_765': 41.7863,
    '1041_766': 41.9292,
    '1041_767': 42.0542,
    '1041_768': 42.1535,
    '1041_769': 42.2263,
    '1041_770': 42.2705,
    '1041_771': 42.2974,
    '1041_772': 42.3049,
    '1041_773': 42.3263,
    '1041_774': 42.3671,
    '1041_775': 42.4043,
    '1041_776': 42.4286,
    '1041_777': 42.4414,
    '1041_778': 42.4506,
    '1041_779': 42.4701,
    '1041_780': 42.5024,
    '1041_781': 42.5385,
    '1041_782': 42.578,
    '1041_783': 42.6176,
    '1041_784': 42.6603,
    '1041_785': 42.7176,
    '1041_786': 42.7765,
    '1041_787': 42.8317,
    '1041_788': 42.8792,
    '1041_789': 42.9221,
    '1041_790': 42.9521,
    '1041_791': 42.9653,
    '1041_792': 42.9748,
    '1041_793': 42.9896,
    '1041_794': 43.0067,
    '1041_795': 43.0289,
    '1041_796': 43.0556,
    '1041_797': 43.0907,
    '1041_798': 43.1368,
    '1041_799': 43.1649,
    '1041_800': 43.1702,
    '1041_801': 43.1765,
    '1041_802': 43.1833,
    '1041_803': 43.1854,
    '1041_804': 43.1965,
    '1041_805': 43.2126,
    '1041_806': 43.2155,
    '1041_807': 43.2008,
    '1041_808': 43.1528,
    '1041_809': 43.0744,
    '1041_810': 42.9931,
    '1041_811': 42.9278,
    '1041_812': 42.8873,
    '1041_813': 42.8698,
    '1041_814': 42.8722,
    '1041_815': 42.888,
    '1041_816': 42.9097,
    '1041_817': 42.9356,
    '1041_818': 42.9637,
    '1041_819': 43,
    '1041_820': 43.0451,
    '1041_821': 43.0893,
    '1041_822': 43.1232,
    '1041_823': 43.1373,
    '1041_824': 43.1342,
    '1041_825': 43.1226,
    '1041_826': 43.1124,
    '1041_827': 43.1028,
    '1041_828': 43.0673,
    '1041_829': 42.9941,
    '1041_830': 42.8817,
    '1041_831': 42.7488,
    '1041_832': 42.6123,
    '1041_833': 42.4795,
    '1041_834': 42.3371,
    '1041_835': 42.1699,
    '1041_836': 41.9724,
    '1041_837': 41.7522,
    '1041_838': 41.4959,
    '1041_839': 41.2189,
    '1041_840': 40.9624,
    '1041_841': 40.7176,
    '1041_842': 40.4765,
    '1041_843': 40.246,
    '1041_844': 40.0147,
    '1041_845': 39.7854,
    '1041_846': 39.5609,
    '1041_847': 39.3422,
    '1041_848': 39.1332,
    '1042_656': 36.2172,
    '1042_657': 36.2877,
    '1042_658': 36.3522,
    '1042_659': 36.4105,
    '1042_660': 36.468,
    '1042_661': 36.5232,
    '1042_662': 36.5757,
    '1042_663': 36.6263,
    '1042_664': 36.678,
    '1042_665': 36.7337,
    '1042_666': 36.7921,
    '1042_667': 36.8549,
    '1042_668': 36.9223,
    '1042_669': 37.0005,
    '1042_670': 37.0835,
    '1042_671': 37.1679,
    '1042_672': 37.2425,
    '1042_673': 37.3107,
    '1042_674': 37.3586,
    '1042_675': 37.3937,
    '1042_676': 37.4451,
    '1042_677': 37.5038,
    '1042_678': 37.5709,
    '1042_679': 37.617,
    '1042_680': 37.6376,
    '1042_681': 37.6424,
    '1042_682': 37.6438,
    '1042_683': 37.6372,
    '1042_684': 37.6331,
    '1042_685': 37.6404,
    '1042_686': 37.6399,
    '1042_687': 37.6179,
    '1042_688': 37.5785,
    '1042_689': 37.5242,
    '1042_690': 37.4712,
    '1042_691': 37.4329,
    '1042_692': 37.4016,
    '1042_693': 37.3718,
    '1042_694': 37.3459,
    '1042_695': 37.3241,
    '1042_696': 37.3054,
    '1042_697': 37.2884,
    '1042_698': 37.2734,
    '1042_699': 37.2596,
    '1042_700': 37.2242,
    '1042_701': 37.1864,
    '1042_702': 37.1365,
    '1042_703': 37.0885,
    '1042_704': 37.0361,
    '1042_705': 36.9794,
    '1042_706': 36.9157,
    '1042_707': 36.85,
    '1042_708': 36.7829,
    '1042_709': 36.7265,
    '1042_710': 36.6889,
    '1042_711': 36.6656,
    '1042_712': 36.6496,
    '1042_713': 36.6426,
    '1042_714': 36.647,
    '1042_715': 36.6686,
    '1042_716': 36.7075,
    '1042_717': 36.7629,
    '1042_718': 36.8358,
    '1042_719': 36.9188,
    '1042_720': 37.006,
    '1042_721': 37.1,
    '1042_722': 37.1978,
    '1042_723': 37.301,
    '1042_724': 37.4043,
    '1042_725': 37.5089,
    '1042_726': 37.6111,
    '1042_727': 37.7073,
    '1042_728': 37.7939,
    '1042_729': 37.8814,
    '1042_730': 37.9637,
    '1042_731': 38.0464,
    '1042_732': 38.133,
    '1042_733': 38.2207,
    '1042_734': 38.3208,
    '1042_735': 38.4057,
    '1042_736': 38.4925,
    '1042_737': 38.5821,
    '1042_738': 38.6741,
    '1042_739': 38.764,
    '1042_740': 38.848,
    '1042_741': 38.9214,
    '1042_742': 38.9786,
    '1042_743': 39.0263,
    '1042_744': 39.077,
    '1042_745': 39.1383,
    '1042_746': 39.2076,
    '1042_747': 39.2827,
    '1042_748': 39.3635,
    '1042_749': 39.4429,
    '1042_750': 39.5254,
    '1042_751': 39.6249,
    '1042_752': 39.7391,
    '1042_753': 39.8814,
    '1042_754': 40.0489,
    '1042_755': 40.2267,
    '1042_756': 40.3958,
    '1042_757': 40.5518,
    '1042_758': 40.688,
    '1042_759': 40.8184,
    '1042_760': 40.9443,
    '1042_761': 41.0778,
    '1042_762': 41.2268,
    '1042_763': 41.3951,
    '1042_764': 41.5706,
    '1042_765': 41.7354,
    '1042_766': 41.8801,
    '1042_767': 41.9941,
    '1042_768': 42.0848,
    '1042_769': 42.1582,
    '1042_770': 42.2045,
    '1042_771': 42.2309,
    '1042_772': 42.247,
    '1042_773': 42.2671,
    '1042_774': 42.3074,
    '1042_775': 42.3437,
    '1042_776': 42.3658,
    '1042_777': 42.3778,
    '1042_778': 42.3983,
    '1042_779': 42.4292,
    '1042_780': 42.4637,
    '1042_781': 42.4984,
    '1042_782': 42.5327,
    '1042_783': 42.5696,
    '1042_784': 42.6123,
    '1042_785': 42.6637,
    '1042_786': 42.7151,
    '1042_787': 42.762,
    '1042_788': 42.7993,
    '1042_789': 42.8362,
    '1042_790': 42.8599,
    '1042_791': 42.8643,
    '1042_792': 42.8616,
    '1042_793': 42.8708,
    '1042_794': 42.8976,
    '1042_795': 42.9252,
    '1042_796': 42.9588,
    '1042_797': 42.9981,
    '1042_798': 43.0524,
    '1042_799': 43.0855,
    '1042_800': 43.1018,
    '1042_801': 43.1199,
    '1042_802': 43.1336,
    '1042_803': 43.1467,
    '1042_804': 43.1668,
    '1042_805': 43.1828,
    '1042_806': 43.1939,
    '1042_807': 43.1959,
    '1042_808': 43.1585,
    '1042_809': 43.0865,
    '1042_810': 43.0042,
    '1042_811': 42.9348,
    '1042_812': 42.8901,
    '1042_813': 42.8689,
    '1042_814': 42.868,
    '1042_815': 42.879,
    '1042_816': 42.8969,
    '1042_817': 42.9199,
    '1042_818': 42.9483,
    '1042_819': 42.986,
    '1042_820': 43.0302,
    '1042_821': 43.0752,
    '1042_822': 43.1152,
    '1042_823': 43.1309,
    '1042_824': 43.1288,
    '1042_825': 43.122,
    '1042_826': 43.1172,
    '1042_827': 43.1111,
    '1042_828': 43.0772,
    '1042_829': 43.0055,
    '1042_830': 42.8976,
    '1042_831': 42.7743,
    '1042_832': 42.6457,
    '1042_833': 42.5168,
    '1042_834': 42.3767,
    '1042_835': 42.2064,
    '1042_836': 42.0071,
    '1042_837': 41.7813,
    '1042_838': 41.5248,
    '1042_839': 41.2616,
    '1042_840': 41.0155,
    '1042_841': 40.7783,
    '1042_842': 40.5419,
    '1042_843': 40.3165,
    '1042_844': 40.0893,
    '1042_845': 39.8636,
    '1042_846': 39.642,
    '1042_847': 39.4262,
    '1042_848': 39.2205,
    '1043_656': 36.1954,
    '1043_657': 36.2574,
    '1043_658': 36.3182,
    '1043_659': 36.3759,
    '1043_660': 36.4325,
    '1043_661': 36.4905,
    '1043_662': 36.5481,
    '1043_663': 36.604,
    '1043_664': 36.6599,
    '1043_665': 36.7157,
    '1043_666': 36.7723,
    '1043_667': 36.8331,
    '1043_668': 36.8991,
    '1043_669': 36.9699,
    '1043_670': 37.0448,
    '1043_671': 37.1192,
    '1043_672': 37.1901,
    '1043_673': 37.2591,
    '1043_674': 37.3137,
    '1043_675': 37.3482,
    '1043_676': 37.3901,
    '1043_677': 37.4507,
    '1043_678': 37.5268,
    '1043_679': 37.5865,
    '1043_680': 37.617,
    '1043_681': 37.6283,
    '1043_682': 37.6241,
    '1043_683': 37.6189,
    '1043_684': 37.6225,
    '1043_685': 37.6371,
    '1043_686': 37.6398,
    '1043_687': 37.6211,
    '1043_688': 37.5859,
    '1043_689': 37.5313,
    '1043_690': 37.4794,
    '1043_691': 37.4475,
    '1043_692': 37.4241,
    '1043_693': 37.3981,
    '1043_694': 37.3736,
    '1043_695': 37.3486,
    '1043_696': 37.3234,
    '1043_697': 37.3048,
    '1043_698': 37.292,
    '1043_699': 37.2797,
    '1043_700': 37.2523,
    '1043_701': 37.2129,
    '1043_702': 37.1649,
    '1043_703': 37.1133,
    '1043_704': 37.0573,
    '1043_705': 36.9938,
    '1043_706': 36.9209,
    '1043_707': 36.8441,
    '1043_708': 36.7682,
    '1043_709': 36.7069,
    '1043_710': 36.6666,
    '1043_711': 36.6389,
    '1043_712': 36.6204,
    '1043_713': 36.6093,
    '1043_714': 36.6112,
    '1043_715': 36.6281,
    '1043_716': 36.6615,
    '1043_717': 36.7063,
    '1043_718': 36.771,
    '1043_719': 36.8463,
    '1043_720': 36.9289,
    '1043_721': 37.0176,
    '1043_722': 37.1121,
    '1043_723': 37.2109,
    '1043_724': 37.3119,
    '1043_725': 37.411,
    '1043_726': 37.5141,
    '1043_727': 37.6158,
    '1043_728': 37.7101,
    '1043_729': 37.7939,
    '1043_730': 37.8774,
    '1043_731': 37.9617,
    '1043_732': 38.0463,
    '1043_733': 38.1333,
    '1043_734': 38.2228,
    '1043_735': 38.3112,
    '1043_736': 38.4026,
    '1043_737': 38.4923,
    '1043_738': 38.5835,
    '1043_739': 38.6707,
    '1043_740': 38.7515,
    '1043_741': 38.8238,
    '1043_742': 38.8856,
    '1043_743': 38.9413,
    '1043_744': 39.0016,
    '1043_745': 39.069,
    '1043_746': 39.1473,
    '1043_747': 39.2259,
    '1043_748': 39.3075,
    '1043_749': 39.3892,
    '1043_750': 39.4667,
    '1043_751': 39.5611,
    '1043_752': 39.674,
    '1043_753': 39.8106,
    '1043_754': 39.9682,
    '1043_755': 40.1449,
    '1043_756': 40.3189,
    '1043_757': 40.4771,
    '1043_758': 40.6068,
    '1043_759': 40.7285,
    '1043_760': 40.8521,
    '1043_761': 40.9936,
    '1043_762': 41.1501,
    '1043_763': 41.3244,
    '1043_764': 41.508,
    '1043_765': 41.677,
    '1043_766': 41.8269,
    '1043_767': 41.9352,
    '1043_768': 42.017,
    '1043_769': 42.0876,
    '1043_770': 42.1415,
    '1043_771': 42.1753,
    '1043_772': 42.1989,
    '1043_773': 42.2199,
    '1043_774': 42.2535,
    '1043_775': 42.2872,
    '1043_776': 42.3084,
    '1043_777': 42.3235,
    '1043_778': 42.3475,
    '1043_779': 42.3828,
    '1043_780': 42.4203,
    '1043_781': 42.4542,
    '1043_782': 42.4856,
    '1043_783': 42.5206,
    '1043_784': 42.5575,
    '1043_785': 42.5985,
    '1043_786': 42.6391,
    '1043_787': 42.67,
    '1043_788': 42.7005,
    '1043_789': 42.7318,
    '1043_790': 42.7498,
    '1043_791': 42.7518,
    '1043_792': 42.74,
    '1043_793': 42.7431,
    '1043_794': 42.7744,
    '1043_795': 42.8098,
    '1043_796': 42.8489,
    '1043_797': 42.8937,
    '1043_798': 42.9592,
    '1043_799': 43.0056,
    '1043_800': 43.0362,
    '1043_801': 43.062,
    '1043_802': 43.0831,
    '1043_803': 43.1052,
    '1043_804': 43.1308,
    '1043_805': 43.1499,
    '1043_806': 43.1697,
    '1043_807': 43.1819,
    '1043_808': 43.1553,
    '1043_809': 43.0892,
    '1043_810': 43.0094,
    '1043_811': 42.9412,
    '1043_812': 42.8936,
    '1043_813': 42.8685,
    '1043_814': 42.8634,
    '1043_815': 42.8708,
    '1043_816': 42.8853,
    '1043_817': 42.9055,
    '1043_818': 42.9339,
    '1043_819': 42.9716,
    '1043_820': 43.0126,
    '1043_821': 43.0572,
    '1043_822': 43.0952,
    '1043_823': 43.1151,
    '1043_824': 43.1184,
    '1043_825': 43.1181,
    '1043_826': 43.117,
    '1043_827': 43.1079,
    '1043_828': 43.0746,
    '1043_829': 43.0086,
    '1043_830': 42.91,
    '1043_831': 42.7964,
    '1043_832': 42.6739,
    '1043_833': 42.5476,
    '1043_834': 42.4071,
    '1043_835': 42.239,
    '1043_836': 42.0421,
    '1043_837': 41.8151,
    '1043_838': 41.5615,
    '1043_839': 41.3054,
    '1043_840': 41.0655,
    '1043_841': 40.8346,
    '1043_842': 40.6049,
    '1043_843': 40.3849,
    '1043_844': 40.1624,
    '1043_845': 39.9407,
    '1043_846': 39.7224,
    '1043_847': 39.5093,
    '1043_848': 39.3052,
    '1044_656': 36.1746,
    '1044_657': 36.2304,
    '1044_658': 36.2902,
    '1044_659': 36.3472,
    '1044_660': 36.4032,
    '1044_661': 36.4646,
    '1044_662': 36.5268,
    '1044_663': 36.5861,
    '1044_664': 36.6455,
    '1044_665': 36.702,
    '1044_666': 36.7573,
    '1044_667': 36.8186,
    '1044_668': 36.8811,
    '1044_669': 36.9452,
    '1044_670': 37.0131,
    '1044_671': 37.0793,
    '1044_672': 37.1435,
    '1044_673': 37.2043,
    '1044_674': 37.2586,
    '1044_675': 37.3001,
    '1044_676': 37.3393,
    '1044_677': 37.3911,
    '1044_678': 37.4593,
    '1044_679': 37.5303,
    '1044_680': 37.5773,
    '1044_681': 37.595,
    '1044_682': 37.5894,
    '1044_683': 37.5913,
    '1044_684': 37.6047,
    '1044_685': 37.6273,
    '1044_686': 37.6355,
    '1044_687': 37.6185,
    '1044_688': 37.5802,
    '1044_689': 37.5208,
    '1044_690': 37.4718,
    '1044_691': 37.4465,
    '1044_692': 37.4279,
    '1044_693': 37.4068,
    '1044_694': 37.3834,
    '1044_695': 37.3569,
    '1044_696': 37.3278,
    '1044_697': 37.308,
    '1044_698': 37.2982,
    '1044_699': 37.2841,
    '1044_700': 37.2595,
    '1044_701': 37.2216,
    '1044_702': 37.1732,
    '1044_703': 37.12,
    '1044_704': 37.0597,
    '1044_705': 36.9918,
    '1044_706': 36.9146,
    '1044_707': 36.8304,
    '1044_708': 36.7526,
    '1044_709': 36.6822,
    '1044_710': 36.6365,
    '1044_711': 36.605,
    '1044_712': 36.585,
    '1044_713': 36.5764,
    '1044_714': 36.5776,
    '1044_715': 36.5884,
    '1044_716': 36.6164,
    '1044_717': 36.6574,
    '1044_718': 36.7139,
    '1044_719': 36.78,
    '1044_720': 36.8585,
    '1044_721': 36.9435,
    '1044_722': 37.0318,
    '1044_723': 37.1258,
    '1044_724': 37.2175,
    '1044_725': 37.3169,
    '1044_726': 37.4203,
    '1044_727': 37.5228,
    '1044_728': 37.6198,
    '1044_729': 37.7078,
    '1044_730': 37.7913,
    '1044_731': 37.8764,
    '1044_732': 37.964,
    '1044_733': 38.0525,
    '1044_734': 38.1364,
    '1044_735': 38.2334,
    '1044_736': 38.3278,
    '1044_737': 38.4184,
    '1044_738': 38.5045,
    '1044_739': 38.5877,
    '1044_740': 38.666,
    '1044_741': 38.7384,
    '1044_742': 38.805,
    '1044_743': 38.8688,
    '1044_744': 38.9356,
    '1044_745': 39.0049,
    '1044_746': 39.0849,
    '1044_747': 39.1706,
    '1044_748': 39.2497,
    '1044_749': 39.3309,
    '1044_750': 39.4096,
    '1044_751': 39.4977,
    '1044_752': 39.6071,
    '1044_753': 39.7369,
    '1044_754': 39.8812,
    '1044_755': 40.0463,
    '1044_756': 40.2197,
    '1044_757': 40.3831,
    '1044_758': 40.5178,
    '1044_759': 40.63,
    '1044_760': 40.7568,
    '1044_761': 40.9055,
    '1044_762': 41.0687,
    '1044_763': 41.2447,
    '1044_764': 41.4278,
    '1044_765': 41.5968,
    '1044_766': 41.7484,
    '1044_767': 41.8663,
    '1044_768': 41.951,
    '1044_769': 42.027,
    '1044_770': 42.0885,
    '1044_771': 42.1288,
    '1044_772': 42.1539,
    '1044_773': 42.1801,
    '1044_774': 42.2135,
    '1044_775': 42.2478,
    '1044_776': 42.2729,
    '1044_777': 42.289,
    '1044_778': 42.3076,
    '1044_779': 42.3339,
    '1044_780': 42.368,
    '1044_781': 42.3956,
    '1044_782': 42.4239,
    '1044_783': 42.4623,
    '1044_784': 42.4953,
    '1044_785': 42.5211,
    '1044_786': 42.5451,
    '1044_787': 42.5629,
    '1044_788': 42.5858,
    '1044_789': 42.6128,
    '1044_790': 42.6317,
    '1044_791': 42.6298,
    '1044_792': 42.614,
    '1044_793': 42.6177,
    '1044_794': 42.6465,
    '1044_795': 42.6838,
    '1044_796': 42.7317,
    '1044_797': 42.7869,
    '1044_798': 42.8657,
    '1044_799': 42.926,
    '1044_800': 42.973,
    '1044_801': 43.006,
    '1044_802': 43.0345,
    '1044_803': 43.063,
    '1044_804': 43.086,
    '1044_805': 43.1076,
    '1044_806': 43.1385,
    '1044_807': 43.1611,
    '1044_808': 43.1423,
    '1044_809': 43.0807,
    '1044_810': 43.0069,
    '1044_811': 42.941,
    '1044_812': 42.8915,
    '1044_813': 42.8655,
    '1044_814': 42.8575,
    '1044_815': 42.8615,
    '1044_816': 42.8725,
    '1044_817': 42.8897,
    '1044_818': 42.9171,
    '1044_819': 42.9535,
    '1044_820': 42.9942,
    '1044_821': 43.039,
    '1044_822': 43.0769,
    '1044_823': 43.0998,
    '1044_824': 43.1085,
    '1044_825': 43.1117,
    '1044_826': 43.1108,
    '1044_827': 43.0977,
    '1044_828': 43.0636,
    '1044_829': 43.0052,
    '1044_830': 42.9183,
    '1044_831': 42.8087,
    '1044_832': 42.6894,
    '1044_833': 42.5656,
    '1044_834': 42.4257,
    '1044_835': 42.2624,
    '1044_836': 42.0676,
    '1044_837': 41.8419,
    '1044_838': 41.5973,
    '1044_839': 41.3508,
    '1044_840': 41.1157,
    '1044_841': 40.8889,
    '1044_842': 40.6647,
    '1044_843': 40.4503,
    '1044_844': 40.2332,
    '1044_845': 40.0163,
    '1044_846': 39.8018,
    '1044_847': 39.5903,
    '1044_848': 39.388,
    '1045_656': 36.1544,
    '1045_657': 36.2147,
    '1045_658': 36.275,
    '1045_659': 36.3339,
    '1045_660': 36.3924,
    '1045_661': 36.4528,
    '1045_662': 36.5134,
    '1045_663': 36.5725,
    '1045_664': 36.6306,
    '1045_665': 36.6868,
    '1045_666': 36.743,
    '1045_667': 36.8015,
    '1045_668': 36.8602,
    '1045_669': 36.92,
    '1045_670': 36.9847,
    '1045_671': 37.0484,
    '1045_672': 37.1088,
    '1045_673': 37.1633,
    '1045_674': 37.2134,
    '1045_675': 37.2575,
    '1045_676': 37.299,
    '1045_677': 37.3446,
    '1045_678': 37.3973,
    '1045_679': 37.4551,
    '1045_680': 37.5091,
    '1045_681': 37.5285,
    '1045_682': 37.5329,
    '1045_683': 37.5494,
    '1045_684': 37.577,
    '1045_685': 37.6095,
    '1045_686': 37.6244,
    '1045_687': 37.6154,
    '1045_688': 37.5762,
    '1045_689': 37.5159,
    '1045_690': 37.4678,
    '1045_691': 37.4434,
    '1045_692': 37.4271,
    '1045_693': 37.4089,
    '1045_694': 37.3878,
    '1045_695': 37.3623,
    '1045_696': 37.334,
    '1045_697': 37.3093,
    '1045_698': 37.2916,
    '1045_699': 37.2734,
    '1045_700': 37.2492,
    '1045_701': 37.2126,
    '1045_702': 37.1652,
    '1045_703': 37.1094,
    '1045_704': 37.0459,
    '1045_705': 36.9711,
    '1045_706': 36.8905,
    '1045_707': 36.8052,
    '1045_708': 36.7236,
    '1045_709': 36.6522,
    '1045_710': 36.6021,
    '1045_711': 36.5687,
    '1045_712': 36.5503,
    '1045_713': 36.5409,
    '1045_714': 36.5411,
    '1045_715': 36.5513,
    '1045_716': 36.5742,
    '1045_717': 36.6111,
    '1045_718': 36.6604,
    '1045_719': 36.7237,
    '1045_720': 36.798,
    '1045_721': 36.8756,
    '1045_722': 36.9561,
    '1045_723': 37.0438,
    '1045_724': 37.1319,
    '1045_725': 37.2269,
    '1045_726': 37.3286,
    '1045_727': 37.4322,
    '1045_728': 37.5324,
    '1045_729': 37.621,
    '1045_730': 37.7048,
    '1045_731': 37.7919,
    '1045_732': 37.8848,
    '1045_733': 37.98,
    '1045_734': 38.0743,
    '1045_735': 38.1722,
    '1045_736': 38.2617,
    '1045_737': 38.3476,
    '1045_738': 38.4303,
    '1045_739': 38.5115,
    '1045_740': 38.589,
    '1045_741': 38.6616,
    '1045_742': 38.7342,
    '1045_743': 38.8043,
    '1045_744': 38.8771,
    '1045_745': 38.949,
    '1045_746': 39.028,
    '1045_747': 39.1147,
    '1045_748': 39.1912,
    '1045_749': 39.2673,
    '1045_750': 39.3495,
    '1045_751': 39.4334,
    '1045_752': 39.5358,
    '1045_753': 39.6558,
    '1045_754': 39.7891,
    '1045_755': 39.9389,
    '1045_756': 40.103,
    '1045_757': 40.2704,
    '1045_758': 40.4114,
    '1045_759': 40.5292,
    '1045_760': 40.6577,
    '1045_761': 40.8064,
    '1045_762': 40.9727,
    '1045_763': 41.1517,
    '1045_764': 41.3337,
    '1045_765': 41.5064,
    '1045_766': 41.6669,
    '1045_767': 41.7817,
    '1045_768': 41.8799,
    '1045_769': 41.9697,
    '1045_770': 42.0371,
    '1045_771': 42.0805,
    '1045_772': 42.1096,
    '1045_773': 42.1426,
    '1045_774': 42.1857,
    '1045_775': 42.2252,
    '1045_776': 42.2568,
    '1045_777': 42.2717,
    '1045_778': 42.2816,
    '1045_779': 42.2906,
    '1045_780': 42.3074,
    '1045_781': 42.3225,
    '1045_782': 42.3509,
    '1045_783': 42.3915,
    '1045_784': 42.4256,
    '1045_785': 42.4435,
    '1045_786': 42.4458,
    '1045_787': 42.4516,
    '1045_788': 42.4665,
    '1045_789': 42.494,
    '1045_790': 42.5167,
    '1045_791': 42.514,
    '1045_792': 42.492,
    '1045_793': 42.4929,
    '1045_794': 42.5187,
    '1045_795': 42.5608,
    '1045_796': 42.6173,
    '1045_797': 42.6899,
    '1045_798': 42.78,
    '1045_799': 42.8539,
    '1045_800': 42.9113,
    '1045_801': 42.9537,
    '1045_802': 42.9872,
    '1045_803': 43.0129,
    '1045_804': 43.0344,
    '1045_805': 43.0603,
    '1045_806': 43.101,
    '1045_807': 43.134,
    '1045_808': 43.1258,
    '1045_809': 43.0698,
    '1045_810': 43.0041,
    '1045_811': 42.9396,
    '1045_812': 42.8908,
    '1045_813': 42.8628,
    '1045_814': 42.8517,
    '1045_815': 42.8529,
    '1045_816': 42.8594,
    '1045_817': 42.874,
    '1045_818': 42.901,
    '1045_819': 42.9376,
    '1045_820': 42.9788,
    '1045_821': 43.0228,
    '1045_822': 43.0621,
    '1045_823': 43.089,
    '1045_824': 43.0987,
    '1045_825': 43.1043,
    '1045_826': 43.1001,
    '1045_827': 43.0833,
    '1045_828': 43.0516,
    '1045_829': 42.9988,
    '1045_830': 42.9168,
    '1045_831': 42.8131,
    '1045_832': 42.6976,
    '1045_833': 42.5755,
    '1045_834': 42.439,
    '1045_835': 42.2778,
    '1045_836': 42.0863,
    '1045_837': 41.866,
    '1045_838': 41.628,
    '1045_839': 41.3938,
    '1045_840': 41.1647,
    '1045_841': 40.9417,
    '1045_842': 40.7226,
    '1045_843': 40.5137,
    '1045_844': 40.3021,
    '1045_845': 40.0913,
    '1045_846': 39.8833,
    '1045_847': 39.6776,
    '1045_848': 39.4766,
    '1046_656': 36.1393,
    '1046_657': 36.2041,
    '1046_658': 36.2666,
    '1046_659': 36.3284,
    '1046_660': 36.389,
    '1046_661': 36.448,
    '1046_662': 36.5057,
    '1046_663': 36.5622,
    '1046_664': 36.6178,
    '1046_665': 36.673,
    '1046_666': 36.729,
    '1046_667': 36.7862,
    '1046_668': 36.8436,
    '1046_669': 36.9023,
    '1046_670': 36.9634,
    '1046_671': 37.0239,
    '1046_672': 37.0818,
    '1046_673': 37.1354,
    '1046_674': 37.1835,
    '1046_675': 37.2268,
    '1046_676': 37.268,
    '1046_677': 37.3094,
    '1046_678': 37.3505,
    '1046_679': 37.393,
    '1046_680': 37.4312,
    '1046_681': 37.4524,
    '1046_682': 37.4748,
    '1046_683': 37.5066,
    '1046_684': 37.5488,
    '1046_685': 37.585,
    '1046_686': 37.604,
    '1046_687': 37.6007,
    '1046_688': 37.5672,
    '1046_689': 37.5154,
    '1046_690': 37.4691,
    '1046_691': 37.4408,
    '1046_692': 37.4243,
    '1046_693': 37.4077,
    '1046_694': 37.3896,
    '1046_695': 37.3636,
    '1046_696': 37.333,
    '1046_697': 37.3033,
    '1046_698': 37.2802,
    '1046_699': 37.2605,
    '1046_700': 37.2298,
    '1046_701': 37.1883,
    '1046_702': 37.1397,
    '1046_703': 37.0822,
    '1046_704': 37.0134,
    '1046_705': 36.9351,
    '1046_706': 36.8528,
    '1046_707': 36.7671,
    '1046_708': 36.6858,
    '1046_709': 36.6145,
    '1046_710': 36.5645,
    '1046_711': 36.531,
    '1046_712': 36.5135,
    '1046_713': 36.5057,
    '1046_714': 36.506,
    '1046_715': 36.513,
    '1046_716': 36.536,
    '1046_717': 36.5699,
    '1046_718': 36.6165,
    '1046_719': 36.6744,
    '1046_720': 36.7442,
    '1046_721': 36.8175,
    '1046_722': 36.892,
    '1046_723': 36.9715,
    '1046_724': 37.0545,
    '1046_725': 37.1467,
    '1046_726': 37.2453,
    '1046_727': 37.3465,
    '1046_728': 37.45,
    '1046_729': 37.5412,
    '1046_730': 37.6246,
    '1046_731': 37.7121,
    '1046_732': 37.805,
    '1046_733': 37.9073,
    '1046_734': 38.0101,
    '1046_735': 38.1081,
    '1046_736': 38.1971,
    '1046_737': 38.2804,
    '1046_738': 38.3603,
    '1046_739': 38.4403,
    '1046_740': 38.5187,
    '1046_741': 38.5938,
    '1046_742': 38.6718,
    '1046_743': 38.7489,
    '1046_744': 38.8269,
    '1046_745': 38.9019,
    '1046_746': 38.9768,
    '1046_747': 39.0608,
    '1046_748': 39.1373,
    '1046_749': 39.2062,
    '1046_750': 39.2839,
    '1046_751': 39.3681,
    '1046_752': 39.4624,
    '1046_753': 39.5734,
    '1046_754': 39.6969,
    '1046_755': 39.8324,
    '1046_756': 39.9861,
    '1046_757': 40.1538,
    '1046_758': 40.2976,
    '1046_759': 40.4207,
    '1046_760': 40.553,
    '1046_761': 40.6997,
    '1046_762': 40.868,
    '1046_763': 41.0467,
    '1046_764': 41.2257,
    '1046_765': 41.4022,
    '1046_766': 41.5525,
    '1046_767': 41.684,
    '1046_768': 41.7997,
    '1046_769': 41.8981,
    '1046_770': 41.9779,
    '1046_771': 42.0302,
    '1046_772': 42.0667,
    '1046_773': 42.1046,
    '1046_774': 42.154,
    '1046_775': 42.2047,
    '1046_776': 42.2416,
    '1046_777': 42.2554,
    '1046_778': 42.2544,
    '1046_779': 42.2469,
    '1046_780': 42.2413,
    '1046_781': 42.2522,
    '1046_782': 42.2834,
    '1046_783': 42.3217,
    '1046_784': 42.3531,
    '1046_785': 42.3653,
    '1046_786': 42.3537,
    '1046_787': 42.3458,
    '1046_788': 42.3524,
    '1046_789': 42.3787,
    '1046_790': 42.4026,
    '1046_791': 42.4003,
    '1046_792': 42.3789,
    '1046_793': 42.3763,
    '1046_794': 42.3999,
    '1046_795': 42.4458,
    '1046_796': 42.511,
    '1046_797': 42.5946,
    '1046_798': 42.6982,
    '1046_799': 42.7855,
    '1046_800': 42.8499,
    '1046_801': 42.8984,
    '1046_802': 42.9359,
    '1046_803': 42.9623,
    '1046_804': 42.9844,
    '1046_805': 43.0149,
    '1046_806': 43.0599,
    '1046_807': 43.105,
    '1046_808': 43.1049,
    '1046_809': 43.064,
    '1046_810': 43.0029,
    '1046_811': 42.9399,
    '1046_812': 42.8909,
    '1046_813': 42.858,
    '1046_814': 42.845,
    '1046_815': 42.843,
    '1046_816': 42.846,
    '1046_817': 42.8585,
    '1046_818': 42.8858,
    '1046_819': 42.9218,
    '1046_820': 42.9644,
    '1046_821': 43.0092,
    '1046_822': 43.0489,
    '1046_823': 43.0776,
    '1046_824': 43.0904,
    '1046_825': 43.0964,
    '1046_826': 43.0922,
    '1046_827': 43.0763,
    '1046_828': 43.0458,
    '1046_829': 42.9914,
    '1046_830': 42.9112,
    '1046_831': 42.8112,
    '1046_832': 42.6995,
    '1046_833': 42.5809,
    '1046_834': 42.4479,
    '1046_835': 42.2864,
    '1046_836': 42.1024,
    '1046_837': 41.8885,
    '1046_838': 41.6586,
    '1046_839': 41.4336,
    '1046_840': 41.2123,
    '1046_841': 40.9936,
    '1046_842': 40.7798,
    '1046_843': 40.5756,
    '1046_844': 40.3686,
    '1046_845': 40.1633,
    '1046_846': 39.962,
    '1046_847': 39.7638,
    '1046_848': 39.5657,
    '1047_656': 36.1259,
    '1047_657': 36.1938,
    '1047_658': 36.2601,
    '1047_659': 36.3259,
    '1047_660': 36.3885,
    '1047_661': 36.4463,
    '1047_662': 36.5007,
    '1047_663': 36.5539,
    '1047_664': 36.6068,
    '1047_665': 36.6603,
    '1047_666': 36.716,
    '1047_667': 36.7732,
    '1047_668': 36.8309,
    '1047_669': 36.8889,
    '1047_670': 36.9473,
    '1047_671': 37.0051,
    '1047_672': 37.0612,
    '1047_673': 37.1145,
    '1047_674': 37.1603,
    '1047_675': 37.2016,
    '1047_676': 37.2408,
    '1047_677': 37.2789,
    '1047_678': 37.3156,
    '1047_679': 37.3482,
    '1047_680': 37.3775,
    '1047_681': 37.4014,
    '1047_682': 37.4292,
    '1047_683': 37.4741,
    '1047_684': 37.5305,
    '1047_685': 37.5684,
    '1047_686': 37.582,
    '1047_687': 37.5721,
    '1047_688': 37.5437,
    '1047_689': 37.5035,
    '1047_690': 37.4611,
    '1047_691': 37.4325,
    '1047_692': 37.4133,
    '1047_693': 37.4,
    '1047_694': 37.3884,
    '1047_695': 37.3606,
    '1047_696': 37.3276,
    '1047_697': 37.295,
    '1047_698': 37.2659,
    '1047_699': 37.2385,
    '1047_700': 37.1966,
    '1047_701': 37.1481,
    '1047_702': 37.0975,
    '1047_703': 37.0373,
    '1047_704': 36.9687,
    '1047_705': 36.8867,
    '1047_706': 36.8035,
    '1047_707': 36.7184,
    '1047_708': 36.6397,
    '1047_709': 36.5709,
    '1047_710': 36.5201,
    '1047_711': 36.4906,
    '1047_712': 36.477,
    '1047_713': 36.4682,
    '1047_714': 36.4665,
    '1047_715': 36.4749,
    '1047_716': 36.4972,
    '1047_717': 36.5293,
    '1047_718': 36.5733,
    '1047_719': 36.6298,
    '1047_720': 36.6974,
    '1047_721': 36.7665,
    '1047_722': 36.8375,
    '1047_723': 36.9127,
    '1047_724': 36.9909,
    '1047_725': 37.0762,
    '1047_726': 37.1689,
    '1047_727': 37.268,
    '1047_728': 37.3705,
    '1047_729': 37.4682,
    '1047_730': 37.5537,
    '1047_731': 37.639,
    '1047_732': 37.7308,
    '1047_733': 37.834,
    '1047_734': 37.9447,
    '1047_735': 38.0467,
    '1047_736': 38.1364,
    '1047_737': 38.2193,
    '1047_738': 38.2979,
    '1047_739': 38.3742,
    '1047_740': 38.452,
    '1047_741': 38.5303,
    '1047_742': 38.6129,
    '1047_743': 38.6986,
    '1047_744': 38.7827,
    '1047_745': 38.8612,
    '1047_746': 38.9319,
    '1047_747': 39.0087,
    '1047_748': 39.0871,
    '1047_749': 39.1528,
    '1047_750': 39.2198,
    '1047_751': 39.2987,
    '1047_752': 39.3875,
    '1047_753': 39.4902,
    '1047_754': 39.6034,
    '1047_755': 39.7274,
    '1047_756': 39.8693,
    '1047_757': 40.0335,
    '1047_758': 40.1817,
    '1047_759': 40.3103,
    '1047_760': 40.4454,
    '1047_761': 40.5933,
    '1047_762': 40.7631,
    '1047_763': 40.9349,
    '1047_764': 41.1056,
    '1047_765': 41.2764,
    '1047_766': 41.4343,
    '1047_767': 41.5772,
    '1047_768': 41.706,
    '1047_769': 41.816,
    '1047_770': 41.9085,
    '1047_771': 41.9734,
    '1047_772': 42.0223,
    '1047_773': 42.0656,
    '1047_774': 42.1161,
    '1047_775': 42.1756,
    '1047_776': 42.2147,
    '1047_777': 42.2281,
    '1047_778': 42.2197,
    '1047_779': 42.2053,
    '1047_780': 42.1895,
    '1047_781': 42.1915,
    '1047_782': 42.2168,
    '1047_783': 42.2459,
    '1047_784': 42.2712,
    '1047_785': 42.2788,
    '1047_786': 42.2609,
    '1047_787': 42.2495,
    '1047_788': 42.2503,
    '1047_789': 42.2714,
    '1047_790': 42.2922,
    '1047_791': 42.2915,
    '1047_792': 42.2771,
    '1047_793': 42.273,
    '1047_794': 42.2963,
    '1047_795': 42.3439,
    '1047_796': 42.413,
    '1047_797': 42.5024,
    '1047_798': 42.6145,
    '1047_799': 42.7131,
    '1047_800': 42.7912,
    '1047_801': 42.8441,
    '1047_802': 42.8845,
    '1047_803': 42.9119,
    '1047_804': 42.9374,
    '1047_805': 42.9717,
    '1047_806': 43.0207,
    '1047_807': 43.0718,
    '1047_808': 43.0852,
    '1047_809': 43.0579,
    '1047_810': 43.0012,
    '1047_811': 42.9377,
    '1047_812': 42.888,
    '1047_813': 42.8518,
    '1047_814': 42.8352,
    '1047_815': 42.832,
    '1047_816': 42.8327,
    '1047_817': 42.8432,
    '1047_818': 42.8702,
    '1047_819': 42.9075,
    '1047_820': 42.9505,
    '1047_821': 42.9965,
    '1047_822': 43.0352,
    '1047_823': 43.0633,
    '1047_824': 43.0841,
    '1047_825': 43.0957,
    '1047_826': 43.0951,
    '1047_827': 43.0797,
    '1047_828': 43.0476,
    '1047_829': 42.989,
    '1047_830': 42.9106,
    '1047_831': 42.8146,
    '1047_832': 42.7046,
    '1047_833': 42.5856,
    '1047_834': 42.4521,
    '1047_835': 42.2872,
    '1047_836': 42.1106,
    '1047_837': 41.9087,
    '1047_838': 41.6883,
    '1047_839': 41.4708,
    '1047_840': 41.2546,
    '1047_841': 41.0432,
    '1047_842': 40.8368,
    '1047_843': 40.6362,
    '1047_844': 40.4321,
    '1047_845': 40.2296,
    '1047_846': 40.0344,
    '1047_847': 39.8413,
    '1047_848': 39.6482,
    '1048_656': 36.1129,
    '1048_657': 36.1831,
    '1048_658': 36.2526,
    '1048_659': 36.3224,
    '1048_660': 36.3869,
    '1048_661': 36.4433,
    '1048_662': 36.4948,
    '1048_663': 36.5444,
    '1048_664': 36.595,
    '1048_665': 36.6468,
    '1048_666': 36.7025,
    '1048_667': 36.7608,
    '1048_668': 36.82,
    '1048_669': 36.8781,
    '1048_670': 36.934,
    '1048_671': 36.9898,
    '1048_672': 37.0444,
    '1048_673': 37.0981,
    '1048_674': 37.1406,
    '1048_675': 37.1791,
    '1048_676': 37.216,
    '1048_677': 37.2515,
    '1048_678': 37.2851,
    '1048_679': 37.3155,
    '1048_680': 37.341,
    '1048_681': 37.3671,
    '1048_682': 37.3976,
    '1048_683': 37.4404,
    '1048_684': 37.498,
    '1048_685': 37.5462,
    '1048_686': 37.5593,
    '1048_687': 37.5466,
    '1048_688': 37.5197,
    '1048_689': 37.4868,
    '1048_690': 37.4528,
    '1048_691': 37.4249,
    '1048_692': 37.4018,
    '1048_693': 37.3848,
    '1048_694': 37.3718,
    '1048_695': 37.3448,
    '1048_696': 37.3103,
    '1048_697': 37.2732,
    '1048_698': 37.2342,
    '1048_699': 37.197,
    '1048_700': 37.1451,
    '1048_701': 37.0889,
    '1048_702': 37.0367,
    '1048_703': 36.9752,
    '1048_704': 36.9054,
    '1048_705': 36.826,
    '1048_706': 36.745,
    '1048_707': 36.6638,
    '1048_708': 36.5891,
    '1048_709': 36.5226,
    '1048_710': 36.4732,
    '1048_711': 36.4452,
    '1048_712': 36.4304,
    '1048_713': 36.4266,
    '1048_714': 36.426,
    '1048_715': 36.4358,
    '1048_716': 36.4592,
    '1048_717': 36.4915,
    '1048_718': 36.534,
    '1048_719': 36.5874,
    '1048_720': 36.6526,
    '1048_721': 36.7195,
    '1048_722': 36.7876,
    '1048_723': 36.8613,
    '1048_724': 36.9367,
    '1048_725': 37.0183,
    '1048_726': 37.1058,
    '1048_727': 37.2017,
    '1048_728': 37.3004,
    '1048_729': 37.4005,
    '1048_730': 37.4897,
    '1048_731': 37.5736,
    '1048_732': 37.6646,
    '1048_733': 37.7664,
    '1048_734': 37.8793,
    '1048_735': 37.9883,
    '1048_736': 38.0784,
    '1048_737': 38.1611,
    '1048_738': 38.2379,
    '1048_739': 38.3077,
    '1048_740': 38.3792,
    '1048_741': 38.4669,
    '1048_742': 38.557,
    '1048_743': 38.6484,
    '1048_744': 38.7375,
    '1048_745': 38.8231,
    '1048_746': 38.8956,
    '1048_747': 38.9617,
    '1048_748': 39.0371,
    '1048_749': 39.1042,
    '1048_750': 39.164,
    '1048_751': 39.2306,
    '1048_752': 39.3124,
    '1048_753': 39.406,
    '1048_754': 39.5095,
    '1048_755': 39.6238,
    '1048_756': 39.754,
    '1048_757': 39.9086,
    '1048_758': 40.0592,
    '1048_759': 40.1925,
    '1048_760': 40.329,
    '1048_761': 40.4816,
    '1048_762': 40.6467,
    '1048_763': 40.8157,
    '1048_764': 40.9847,
    '1048_765': 41.1561,
    '1048_766': 41.3163,
    '1048_767': 41.4693,
    '1048_768': 41.6116,
    '1048_769': 41.7359,
    '1048_770': 41.8394,
    '1048_771': 41.9163,
    '1048_772': 41.9742,
    '1048_773': 42.0165,
    '1048_774': 42.0658,
    '1048_775': 42.1238,
    '1048_776': 42.1687,
    '1048_777': 42.1856,
    '1048_778': 42.1771,
    '1048_779': 42.1696,
    '1048_780': 42.1552,
    '1048_781': 42.1467,
    '1048_782': 42.1582,
    '1048_783': 42.1777,
    '1048_784': 42.2001,
    '1048_785': 42.199,
    '1048_786': 42.1741,
    '1048_787': 42.1612,
    '1048_788': 42.1597,
    '1048_789': 42.1738,
    '1048_790': 42.1879,
    '1048_791': 42.1883,
    '1048_792': 42.1826,
    '1048_793': 42.1789,
    '1048_794': 42.2026,
    '1048_795': 42.2507,
    '1048_796': 42.3211,
    '1048_797': 42.4131,
    '1048_798': 42.5311,
    '1048_799': 42.6437,
    '1048_800': 42.734,
    '1048_801': 42.7958,
    '1048_802': 42.8395,
    '1048_803': 42.8662,
    '1048_804': 42.8936,
    '1048_805': 42.9309,
    '1048_806': 42.9835,
    '1048_807': 43.0373,
    '1048_808': 43.0592,
    '1048_809': 43.0407,
    '1048_810': 42.9931,
    '1048_811': 42.9299,
    '1048_812': 42.8744,
    '1048_813': 42.8354,
    '1048_814': 42.8132,
    '1048_815': 42.8118,
    '1048_816': 42.8146,
    '1048_817': 42.8263,
    '1048_818': 42.8559,
    '1048_819': 42.8942,
    '1048_820': 42.9347,
    '1048_821': 42.9784,
    '1048_822': 43.0188,
    '1048_823': 43.0518,
    '1048_824': 43.0831,
    '1048_825': 43.1028,
    '1048_826': 43.1033,
    '1048_827': 43.0873,
    '1048_828': 43.0534,
    '1048_829': 42.994,
    '1048_830': 42.9181,
    '1048_831': 42.8242,
    '1048_832': 42.7132,
    '1048_833': 42.5916,
    '1048_834': 42.4576,
    '1048_835': 42.2949,
    '1048_836': 42.1217,
    '1048_837': 41.9255,
    '1048_838': 41.7146,
    '1048_839': 41.507,
    '1048_840': 41.2982,
    '1048_841': 41.093,
    '1048_842': 40.8909,
    '1048_843': 40.6945,
    '1048_844': 40.4943,
    '1048_845': 40.296,
    '1048_846': 40.1048,
    '1048_847': 39.9156,
    '1048_848': 39.7262,
    '1049_656': 36.102,
    '1049_657': 36.1739,
    '1049_658': 36.2447,
    '1049_659': 36.3142,
    '1049_660': 36.3788,
    '1049_661': 36.4364,
    '1049_662': 36.486,
    '1049_663': 36.5336,
    '1049_664': 36.5834,
    '1049_665': 36.6341,
    '1049_666': 36.6904,
    '1049_667': 36.75,
    '1049_668': 36.8105,
    '1049_669': 36.8682,
    '1049_670': 36.9247,
    '1049_671': 36.9798,
    '1049_672': 37.0325,
    '1049_673': 37.082,
    '1049_674': 37.1236,
    '1049_675': 37.1602,
    '1049_676': 37.1951,
    '1049_677': 37.2285,
    '1049_678': 37.2599,
    '1049_679': 37.2882,
    '1049_680': 37.3125,
    '1049_681': 37.339,
    '1049_682': 37.3691,
    '1049_683': 37.4053,
    '1049_684': 37.4493,
    '1049_685': 37.4947,
    '1049_686': 37.515,
    '1049_687': 37.51,
    '1049_688': 37.4957,
    '1049_689': 37.4729,
    '1049_690': 37.4445,
    '1049_691': 37.4172,
    '1049_692': 37.3936,
    '1049_693': 37.3712,
    '1049_694': 37.3491,
    '1049_695': 37.3192,
    '1049_696': 37.2819,
    '1049_697': 37.2397,
    '1049_698': 37.1876,
    '1049_699': 37.1329,
    '1049_700': 37.0731,
    '1049_701': 37.0112,
    '1049_702': 36.9553,
    '1049_703': 36.8953,
    '1049_704': 36.8299,
    '1049_705': 36.7571,
    '1049_706': 36.6834,
    '1049_707': 36.6081,
    '1049_708': 36.5372,
    '1049_709': 36.473,
    '1049_710': 36.4231,
    '1049_711': 36.3929,
    '1049_712': 36.3817,
    '1049_713': 36.3794,
    '1049_714': 36.3843,
    '1049_715': 36.3965,
    '1049_716': 36.423,
    '1049_717': 36.4538,
    '1049_718': 36.4961,
    '1049_719': 36.5489,
    '1049_720': 36.6113,
    '1049_721': 36.6764,
    '1049_722': 36.7427,
    '1049_723': 36.815,
    '1049_724': 36.8893,
    '1049_725': 36.9702,
    '1049_726': 37.0576,
    '1049_727': 37.1488,
    '1049_728': 37.2425,
    '1049_729': 37.34,
    '1049_730': 37.4313,
    '1049_731': 37.5174,
    '1049_732': 37.6076,
    '1049_733': 37.7083,
    '1049_734': 37.8211,
    '1049_735': 37.9303,
    '1049_736': 38.0226,
    '1049_737': 38.1058,
    '1049_738': 38.1802,
    '1049_739': 38.2502,
    '1049_740': 38.3266,
    '1049_741': 38.416,
    '1049_742': 38.5068,
    '1049_743': 38.5986,
    '1049_744': 38.6933,
    '1049_745': 38.7839,
    '1049_746': 38.858,
    '1049_747': 38.9209,
    '1049_748': 38.9879,
    '1049_749': 39.0576,
    '1049_750': 39.1135,
    '1049_751': 39.1719,
    '1049_752': 39.2421,
    '1049_753': 39.3259,
    '1049_754': 39.4206,
    '1049_755': 39.5264,
    '1049_756': 39.6469,
    '1049_757': 39.7873,
    '1049_758': 39.9342,
    '1049_759': 40.0702,
    '1049_760': 40.2119,
    '1049_761': 40.3669,
    '1049_762': 40.5298,
    '1049_763': 40.6949,
    '1049_764': 40.8638,
    '1049_765': 41.0419,
    '1049_766': 41.2077,
    '1049_767': 41.371,
    '1049_768': 41.5239,
    '1049_769': 41.6558,
    '1049_770': 41.7706,
    '1049_771': 41.8596,
    '1049_772': 41.9209,
    '1049_773': 41.9593,
    '1049_774': 42.0019,
    '1049_775': 42.0592,
    '1049_776': 42.1068,
    '1049_777': 42.1313,
    '1049_778': 42.1334,
    '1049_779': 42.1381,
    '1049_780': 42.1319,
    '1049_781': 42.1227,
    '1049_782': 42.121,
    '1049_783': 42.1388,
    '1049_784': 42.1522,
    '1049_785': 42.1345,
    '1049_786': 42.1027,
    '1049_787': 42.0812,
    '1049_788': 42.0765,
    '1049_789': 42.0802,
    '1049_790': 42.0872,
    '1049_791': 42.0918,
    '1049_792': 42.0914,
    '1049_793': 42.0909,
    '1049_794': 42.1174,
    '1049_795': 42.1685,
    '1049_796': 42.2403,
    '1049_797': 42.3336,
    '1049_798': 42.4531,
    '1049_799': 42.5808,
    '1049_800': 42.6798,
    '1049_801': 42.7486,
    '1049_802': 42.7935,
    '1049_803': 42.8236,
    '1049_804': 42.8552,
    '1049_805': 42.898,
    '1049_806': 42.9559,
    '1049_807': 43.0107,
    '1049_808': 43.0363,
    '1049_809': 43.0257,
    '1049_810': 42.9839,
    '1049_811': 42.9212,
    '1049_812': 42.8621,
    '1049_813': 42.8197,
    '1049_814': 42.7914,
    '1049_815': 42.7869,
    '1049_816': 42.7939,
    '1049_817': 42.8118,
    '1049_818': 42.843,
    '1049_819': 42.8803,
    '1049_820': 42.9184,
    '1049_821': 42.9568,
    '1049_822': 42.9951,
    '1049_823': 43.0347,
    '1049_824': 43.0737,
    '1049_825': 43.0986,
    '1049_826': 43.1011,
    '1049_827': 43.088,
    '1049_828': 43.057,
    '1049_829': 43.0018,
    '1049_830': 42.9289,
    '1049_831': 42.8336,
    '1049_832': 42.725,
    '1049_833': 42.6067,
    '1049_834': 42.4719,
    '1049_835': 42.3128,
    '1049_836': 42.1434,
    '1049_837': 41.952,
    '1049_838': 41.7462,
    '1049_839': 41.5442,
    '1049_840': 41.3439,
    '1049_841': 41.145,
    '1049_842': 40.9465,
    '1049_843': 40.753,
    '1049_844': 40.5568,
    '1049_845': 40.3642,
    '1049_846': 40.1763,
    '1049_847': 39.9897,
    '1049_848': 39.8034,
    '1050_656': 36.0907,
    '1050_657': 36.1635,
    '1050_658': 36.2351,
    '1050_659': 36.3041,
    '1050_660': 36.3675,
    '1050_661': 36.4234,
    '1050_662': 36.474,
    '1050_663': 36.5236,
    '1050_664': 36.5751,
    '1050_665': 36.6284,
    '1050_666': 36.6856,
    '1050_667': 36.7447,
    '1050_668': 36.8031,
    '1050_669': 36.86,
    '1050_670': 36.9187,
    '1050_671': 36.9741,
    '1050_672': 37.0252,
    '1050_673': 37.0717,
    '1050_674': 37.1123,
    '1050_675': 37.1485,
    '1050_676': 37.182,
    '1050_677': 37.2124,
    '1050_678': 37.2418,
    '1050_679': 37.2674,
    '1050_680': 37.2893,
    '1050_681': 37.3144,
    '1050_682': 37.3424,
    '1050_683': 37.3724,
    '1050_684': 37.4042,
    '1050_685': 37.4352,
    '1050_686': 37.4546,
    '1050_687': 37.4572,
    '1050_688': 37.4546,
    '1050_689': 37.4432,
    '1050_690': 37.4246,
    '1050_691': 37.4007,
    '1050_692': 37.3837,
    '1050_693': 37.3578,
    '1050_694': 37.326,
    '1050_695': 37.2872,
    '1050_696': 37.243,
    '1050_697': 37.1935,
    '1050_698': 37.1295,
    '1050_699': 37.0604,
    '1050_700': 36.9918,
    '1050_701': 36.9246,
    '1050_702': 36.868,
    '1050_703': 36.8071,
    '1050_704': 36.7467,
    '1050_705': 36.6862,
    '1050_706': 36.6208,
    '1050_707': 36.5532,
    '1050_708': 36.4851,
    '1050_709': 36.42,
    '1050_710': 36.3707,
    '1050_711': 36.3405,
    '1050_712': 36.3288,
    '1050_713': 36.3323,
    '1050_714': 36.3423,
    '1050_715': 36.3578,
    '1050_716': 36.3844,
    '1050_717': 36.418,
    '1050_718': 36.4618,
    '1050_719': 36.5128,
    '1050_720': 36.5724,
    '1050_721': 36.6357,
    '1050_722': 36.7012,
    '1050_723': 36.7754,
    '1050_724': 36.8506,
    '1050_725': 36.9324,
    '1050_726': 37.0179,
    '1050_727': 37.1059,
    '1050_728': 37.197,
    '1050_729': 37.2908,
    '1050_730': 37.382,
    '1050_731': 37.47,
    '1050_732': 37.5628,
    '1050_733': 37.6664,
    '1050_734': 37.7811,
    '1050_735': 37.8914,
    '1050_736': 37.9818,
    '1050_737': 38.0599,
    '1050_738': 38.1314,
    '1050_739': 38.1997,
    '1050_740': 38.2748,
    '1050_741': 38.3656,
    '1050_742': 38.4575,
    '1050_743': 38.55,
    '1050_744': 38.6468,
    '1050_745': 38.7402,
    '1050_746': 38.8228,
    '1050_747': 38.8877,
    '1050_748': 38.9466,
    '1050_749': 39.0094,
    '1050_750': 39.0638,
    '1050_751': 39.1152,
    '1050_752': 39.1762,
    '1050_753': 39.2515,
    '1050_754': 39.3382,
    '1050_755': 39.4364,
    '1050_756': 39.5479,
    '1050_757': 39.6777,
    '1050_758': 39.8178,
    '1050_759': 39.9548,
    '1050_760': 40.1009,
    '1050_761': 40.2562,
    '1050_762': 40.4191,
    '1050_763': 40.5832,
    '1050_764': 40.7526,
    '1050_765': 40.9295,
    '1050_766': 41.1081,
    '1050_767': 41.2842,
    '1050_768': 41.4439,
    '1050_769': 41.5833,
    '1050_770': 41.7079,
    '1050_771': 41.8044,
    '1050_772': 41.8656,
    '1050_773': 41.897,
    '1050_774': 41.928,
    '1050_775': 41.982,
    '1050_776': 42.0391,
    '1050_777': 42.0744,
    '1050_778': 42.0879,
    '1050_779': 42.0994,
    '1050_780': 42.1029,
    '1050_781': 42.0993,
    '1050_782': 42.0983,
    '1050_783': 42.1119,
    '1050_784': 42.1104,
    '1050_785': 42.0826,
    '1050_786': 42.0468,
    '1050_787': 42.0212,
    '1050_788': 42.005,
    '1050_789': 41.9983,
    '1050_790': 42.0017,
    '1050_791': 42.0085,
    '1050_792': 42.0068,
    '1050_793': 42.012,
    '1050_794': 42.0422,
    '1050_795': 42.0967,
    '1050_796': 42.1706,
    '1050_797': 42.2646,
    '1050_798': 42.3842,
    '1050_799': 42.5174,
    '1050_800': 42.625,
    '1050_801': 42.6996,
    '1050_802': 42.749,
    '1050_803': 42.7869,
    '1050_804': 42.8265,
    '1050_805': 42.8775,
    '1050_806': 42.9471,
    '1050_807': 43.0109,
    '1050_808': 43.0394,
    '1050_809': 43.0263,
    '1050_810': 42.9862,
    '1050_811': 42.9264,
    '1050_812': 42.8718,
    '1050_813': 42.8238,
    '1050_814': 42.7891,
    '1050_815': 42.7725,
    '1050_816': 42.7801,
    '1050_817': 42.8012,
    '1050_818': 42.8313,
    '1050_819': 42.8649,
    '1050_820': 42.8989,
    '1050_821': 42.9325,
    '1050_822': 42.9692,
    '1050_823': 43.0134,
    '1050_824': 43.0527,
    '1050_825': 43.0811,
    '1050_826': 43.0888,
    '1050_827': 43.0824,
    '1050_828': 43.0569,
    '1050_829': 43.0052,
    '1050_830': 42.9327,
    '1050_831': 42.841,
    '1050_832': 42.7392,
    '1050_833': 42.6245,
    '1050_834': 42.489,
    '1050_835': 42.3274,
    '1050_836': 42.1591,
    '1050_837': 41.9746,
    '1050_838': 41.7804,
    '1050_839': 41.5881,
    '1050_840': 41.3954,
    '1050_841': 41.2012,
    '1050_842': 41.004,
    '1050_843': 40.8128,
    '1050_844': 40.6199,
    '1050_845': 40.4339,
    '1050_846': 40.2499,
    '1050_847': 40.0656,
    '1050_848': 39.8819,
    '1051_680': 37.2693,
    '1051_681': 37.2914,
    '1051_682': 37.3161,
    '1051_683': 37.3402,
    '1051_684': 37.3628,
    '1051_685': 37.3826,
    '1051_686': 37.3953,
    '1051_687': 37.3999,
    '1051_688': 37.3994,
    '1051_689': 37.3948,
    '1051_690': 37.3881,
    '1051_691': 37.3703,
    '1051_692': 37.3513,
    '1051_693': 37.3262,
    '1051_694': 37.2908,
    '1051_695': 37.2452,
    '1051_696': 37.1927,
    '1051_697': 37.1347,
    '1051_698': 37.0674,
    '1051_699': 36.9922,
    '1051_700': 36.9148,
    '1051_701': 36.8412,
    '1051_702': 36.7788,
    '1051_703': 36.7197,
    '1051_704': 36.6646,
    '1051_736': 37.9535,
    '1051_737': 38.0226,
    '1051_738': 38.0881,
    '1051_739': 38.1522,
    '1051_740': 38.2212,
    '1051_741': 38.3159,
    '1051_742': 38.4061,
    '1051_743': 38.4974,
    '1051_744': 38.5952,
    '1051_745': 38.6921,
    '1051_746': 38.7812,
    '1051_747': 38.852,
    '1051_748': 38.9038,
    '1051_749': 38.9585,
    '1051_750': 39.0125,
    '1051_751': 39.0612,
    '1051_752': 39.1151,
    '1051_753': 39.1818,
    '1051_754': 39.2615,
    '1051_755': 39.3534,
    '1051_756': 39.4577,
    '1051_757': 39.5793,
    '1051_758': 39.7124,
    '1051_759': 39.85,
    '1051_760': 39.997,
    '1051_761': 40.1521,
    '1051_762': 40.3158,
    '1051_763': 40.4809,
    '1051_764': 40.6498,
    '1051_765': 40.8317,
    '1051_766': 41.0231,
    '1051_767': 41.2176,
    '1051_768': 41.3818,
    '1051_769': 41.5203,
    '1051_770': 41.6489,
    '1051_771': 41.745,
    '1051_772': 41.8054,
    '1051_773': 41.833,
    '1051_774': 41.8514,
    '1051_775': 41.901,
    '1051_776': 41.9668,
    '1051_777': 42.0022,
    '1051_778': 42.0272,
    '1051_779': 42.043,
    '1051_780': 42.0512,
    '1051_781': 42.0526,
    '1051_782': 42.0558,
    '1051_783': 42.0658,
    '1051_784': 42.0551,
    '1051_785': 42.027,
    '1051_786': 41.9972,
    '1051_787': 41.9705,
    '1051_788': 41.9486,
    '1051_789': 41.9269,
    '1051_790': 41.9278,
    '1051_791': 41.9328,
    '1051_792': 41.931,
    '1051_793': 41.9423,
    '1051_794': 41.9771,
    '1051_795': 42.0348,
    '1051_796': 42.111,
    '1051_797': 42.2078,
    '1051_798': 42.3279,
    '1051_799': 42.4617,
    '1051_800': 42.5736,
    '1051_801': 42.6545,
    '1051_802': 42.7122,
    '1051_803': 42.759,
    '1051_804': 42.8086,
    '1051_805': 42.8717,
    '1051_806': 42.9567,
    '1051_807': 43.0333,
    '1051_808': 43.0651,
    '1051_809': 43.0491,
    '1051_810': 43.0042,
    '1051_811': 42.9467,
    '1051_812': 42.8934,
    '1051_813': 42.8399,
    '1051_814': 42.7947,
    '1051_815': 42.769,
    '1051_816': 42.7747,
    '1051_817': 42.7922,
    '1051_818': 42.8188,
    '1051_819': 42.8473,
    '1051_820': 42.8742,
    '1051_821': 42.9052,
    '1051_822': 42.9426,
    '1051_823': 42.9891,
    '1051_824': 43.03,
    '1051_825': 43.0622,
    '1051_826': 43.0778,
    '1051_827': 43.0775,
    '1051_828': 43.0563,
    '1051_829': 43.0066,
    '1051_830': 42.9362,
    '1051_831': 42.8494,
    '1051_832': 42.7502,
    '1051_833': 42.6361,
    '1051_834': 42.4993,
    '1051_835': 42.3424,
    '1051_836': 42.1835,
    '1051_837': 42.0063,
    '1051_838': 41.8174,
    '1051_839': 41.6318,
    '1051_840': 41.4452,
    '1051_841': 41.2551,
    '1051_842': 41.0614,
    '1051_843': 40.8735,
    '1051_844': 40.6852,
    '1051_845': 40.5072,
    '1051_846': 40.3259,
    '1051_847': 40.1429,
    '1051_848': 39.9618,
    '1052_680': 37.2551,
    '1052_681': 37.2713,
    '1052_682': 37.292,
    '1052_683': 37.3093,
    '1052_684': 37.3233,
    '1052_685': 37.3348,
    '1052_686': 37.3418,
    '1052_687': 37.3445,
    '1052_688': 37.3432,
    '1052_689': 37.3385,
    '1052_690': 37.3321,
    '1052_691': 37.3198,
    '1052_692': 37.2988,
    '1052_693': 37.2741,
    '1052_694': 37.2413,
    '1052_695': 37.1959,
    '1052_696': 37.1412,
    '1052_697': 37.0844,
    '1052_698': 37.0177,
    '1052_699': 36.9333,
    '1052_700': 36.8485,
    '1052_701': 36.7722,
    '1052_702': 36.7035,
    '1052_703': 36.6417,
    '1052_704': 36.5836,
    '1052_736': 37.9342,
    '1052_737': 37.9945,
    '1052_738': 38.0517,
    '1052_739': 38.1112,
    '1052_740': 38.1725,
    '1052_741': 38.259,
    '1052_742': 38.3492,
    '1052_743': 38.4392,
    '1052_744': 38.5358,
    '1052_745': 38.6361,
    '1052_746': 38.729,
    '1052_747': 38.8092,
    '1052_748': 38.8629,
    '1052_749': 38.9119,
    '1052_750': 38.9604,
    '1052_751': 39.0095,
    '1052_752': 39.0597,
    '1052_753': 39.1173,
    '1052_754': 39.1902,
    '1052_755': 39.2765,
    '1052_756': 39.3754,
    '1052_757': 39.4916,
    '1052_758': 39.6231,
    '1052_759': 39.761,
    '1052_760': 39.9042,
    '1052_761': 40.0532,
    '1052_762': 40.2125,
    '1052_763': 40.3776,
    '1052_764': 40.5503,
    '1052_765': 40.7432,
    '1052_766': 40.9486,
    '1052_767': 41.1581,
    '1052_768': 41.3281,
    '1052_769': 41.4605,
    '1052_770': 41.5844,
    '1052_771': 41.6795,
    '1052_772': 41.7383,
    '1052_773': 41.7657,
    '1052_774': 41.7777,
    '1052_775': 41.81,
    '1052_776': 41.8738,
    '1052_777': 41.9171,
    '1052_778': 41.9406,
    '1052_779': 41.9575,
    '1052_780': 41.9684,
    '1052_781': 41.9747,
    '1052_782': 41.9862,
    '1052_783': 42.0023,
    '1052_784': 41.9879,
    '1052_785': 41.9598,
    '1052_786': 41.9393,
    '1052_787': 41.9172,
    '1052_788': 41.8969,
    '1052_789': 41.8676,
    '1052_790': 41.8628,
    '1052_791': 41.8667,
    '1052_792': 41.8672,
    '1052_793': 41.8838,
    '1052_794': 41.9224,
    '1052_795': 41.983,
    '1052_796': 42.0619,
    '1052_797': 42.16,
    '1052_798': 42.2799,
    '1052_799': 42.4115,
    '1052_800': 42.5278,
    '1052_801': 42.6182,
    '1052_802': 42.6878,
    '1052_803': 42.743,
    '1052_804': 42.8014,
    '1052_805': 42.8759,
    '1052_806': 42.9739,
    '1052_807': 43.0572,
    '1052_808': 43.0935,
    '1052_809': 43.0845,
    '1052_810': 43.035,
    '1052_811': 42.9762,
    '1052_812': 42.9224,
    '1052_813': 42.8611,
    '1052_814': 42.8061,
    '1052_815': 42.7764,
    '1052_816': 42.7776,
    '1052_817': 42.786,
    '1052_818': 42.8049,
    '1052_819': 42.8273,
    '1052_820': 42.8504,
    '1052_821': 42.879,
    '1052_822': 42.917,
    '1052_823': 42.9651,
    '1052_824': 43.0101,
    '1052_825': 43.0471,
    '1052_826': 43.07,
    '1052_827': 43.0745,
    '1052_828': 43.0527,
    '1052_829': 43.0039,
    '1052_830': 42.9376,
    '1052_831': 42.8531,
    '1052_832': 42.7542,
    '1052_833': 42.6422,
    '1052_834': 42.5068,
    '1052_835': 42.3613,
    '1052_836': 42.212,
    '1052_837': 42.0375,
    '1052_838': 41.8545,
    '1052_839': 41.6763,
    '1052_840': 41.4936,
    '1052_841': 41.307,
    '1052_842': 41.118,
    '1052_843': 40.9341,
    '1052_844': 40.7505,
    '1052_845': 40.5759,
    '1052_846': 40.4026,
    '1052_847': 40.226,
    '1052_848': 40.0447,
    '1053_680': 37.2431,
    '1053_681': 37.2578,
    '1053_682': 37.2726,
    '1053_683': 37.2819,
    '1053_684': 37.2868,
    '1053_685': 37.2905,
    '1053_686': 37.2928,
    '1053_687': 37.2931,
    '1053_688': 37.2894,
    '1053_689': 37.2814,
    '1053_690': 37.2741,
    '1053_691': 37.2623,
    '1053_692': 37.241,
    '1053_693': 37.219,
    '1053_694': 37.1947,
    '1053_695': 37.1522,
    '1053_696': 37.0976,
    '1053_697': 37.0441,
    '1053_698': 36.9803,
    '1053_699': 36.8848,
    '1053_700': 36.7972,
    '1053_701': 36.7172,
    '1053_702': 36.642,
    '1053_703': 36.5753,
    '1053_704': 36.5086,
    '1053_736': 37.9196,
    '1053_737': 37.9698,
    '1053_738': 38.016,
    '1053_739': 38.0658,
    '1053_740': 38.1307,
    '1053_741': 38.2082,
    '1053_742': 38.2951,
    '1053_743': 38.3827,
    '1053_744': 38.4766,
    '1053_745': 38.5747,
    '1053_746': 38.6701,
    '1053_747': 38.7536,
    '1053_748': 38.8205,
    '1053_749': 38.8724,
    '1053_750': 38.9166,
    '1053_751': 38.9615,
    '1053_752': 39.0093,
    '1053_753': 39.0601,
    '1053_754': 39.1261,
    '1053_755': 39.2062,
    '1053_756': 39.2997,
    '1053_757': 39.4107,
    '1053_758': 39.5415,
    '1053_759': 39.6808,
    '1053_760': 39.8177,
    '1053_761': 39.9571,
    '1053_762': 40.1102,
    '1053_763': 40.2763,
    '1053_764': 40.4606,
    '1053_765': 40.6667,
    '1053_766': 40.8789,
    '1053_767': 41.0926,
    '1053_768': 41.2623,
    '1053_769': 41.3957,
    '1053_770': 41.5116,
    '1053_771': 41.6036,
    '1053_772': 41.6638,
    '1053_773': 41.6962,
    '1053_774': 41.7087,
    '1053_775': 41.7189,
    '1053_776': 41.7665,
    '1053_777': 41.8092,
    '1053_778': 41.8315,
    '1053_779': 41.8501,
    '1053_780': 41.8643,
    '1053_781': 41.8768,
    '1053_782': 41.8995,
    '1053_783': 41.9234,
    '1053_784': 41.9087,
    '1053_785': 41.8788,
    '1053_786': 41.8664,
    '1053_787': 41.8533,
    '1053_788': 41.8452,
    '1053_789': 41.8186,
    '1053_790': 41.8048,
    '1053_791': 41.8095,
    '1053_792': 41.816,
    '1053_793': 41.8365,
    '1053_794': 41.8789,
    '1053_795': 41.942,
    '1053_796': 42.0243,
    '1053_797': 42.1262,
    '1053_798': 42.246,
    '1053_799': 42.3762,
    '1053_800': 42.4975,
    '1053_801': 42.5989,
    '1053_802': 42.6798,
    '1053_803': 42.745,
    '1053_804': 42.8067,
    '1053_805': 42.8837,
    '1053_806': 42.9885,
    '1053_807': 43.0771,
    '1053_808': 43.1185,
    '1053_809': 43.1173,
    '1053_810': 43.0765,
    '1053_811': 43.0224,
    '1053_812': 42.9634,
    '1053_813': 42.8968,
    '1053_814': 42.8341,
    '1053_815': 42.7969,
    '1053_816': 42.7874,
    '1053_817': 42.7843,
    '1053_818': 42.7921,
    '1053_819': 42.808,
    '1053_820': 42.8294,
    '1053_821': 42.8561,
    '1053_822': 42.8946,
    '1053_823': 42.9443,
    '1053_824': 42.9963,
    '1053_825': 43.0385,
    '1053_826': 43.0593,
    '1053_827': 43.066,
    '1053_828': 43.0455,
    '1053_829': 42.997,
    '1053_830': 42.9307,
    '1053_831': 42.8476,
    '1053_832': 42.7507,
    '1053_833': 42.6447,
    '1053_834': 42.52,
    '1053_835': 42.3841,
    '1053_836': 42.2389,
    '1053_837': 42.0634,
    '1053_838': 41.8864,
    '1053_839': 41.7162,
    '1053_840': 41.5397,
    '1053_841': 41.358,
    '1053_842': 41.1769,
    '1053_843': 40.9938,
    '1053_844': 40.8145,
    '1053_845': 40.6456,
    '1053_846': 40.4796,
    '1053_847': 40.3094,
    '1053_848': 40.1268,
    '1054_680': 37.2296,
    '1054_681': 37.2446,
    '1054_682': 37.2567,
    '1054_683': 37.2577,
    '1054_684': 37.2528,
    '1054_685': 37.2495,
    '1054_686': 37.2488,
    '1054_687': 37.248,
    '1054_688': 37.2419,
    '1054_689': 37.2289,
    '1054_690': 37.2216,
    '1054_691': 37.2104,
    '1054_692': 37.186,
    '1054_693': 37.1674,
    '1054_694': 37.1578,
    '1054_695': 37.1225,
    '1054_696': 37.0835,
    '1054_697': 37.0128,
    '1054_698': 36.934,
    '1054_699': 36.8465,
    '1054_700': 36.7602,
    '1054_701': 36.6749,
    '1054_702': 36.5947,
    '1054_703': 36.5207,
    '1054_704': 36.4492,
    '1054_736': 37.9168,
    '1054_737': 37.9515,
    '1054_738': 37.9871,
    '1054_739': 38.0304,
    '1054_740': 38.0893,
    '1054_741': 38.1547,
    '1054_742': 38.2323,
    '1054_743': 38.3213,
    '1054_744': 38.417,
    '1054_745': 38.5155,
    '1054_746': 38.6104,
    '1054_747': 38.6996,
    '1054_748': 38.775,
    '1054_749': 38.8363,
    '1054_750': 38.8824,
    '1054_751': 38.9223,
    '1054_752': 38.9658,
    '1054_753': 39.0106,
    '1054_754': 39.0687,
    '1054_755': 39.1418,
    '1054_756': 39.2287,
    '1054_757': 39.3325,
    '1054_758': 39.4587,
    '1054_759': 39.5983,
    '1054_760': 39.7338,
    '1054_761': 39.8675,
    '1054_762': 40.0151,
    '1054_763': 40.1829,
    '1054_764': 40.3725,
    '1054_765': 40.5841,
    '1054_766': 40.8004,
    '1054_767': 41.0129,
    '1054_768': 41.1821,
    '1054_769': 41.3124,
    '1054_770': 41.4243,
    '1054_771': 41.5174,
    '1054_772': 41.5827,
    '1054_773': 41.6212,
    '1054_774': 41.6445,
    '1054_775': 41.6446,
    '1054_776': 41.6625,
    '1054_777': 41.6926,
    '1054_778': 41.7165,
    '1054_779': 41.7385,
    '1054_780': 41.7582,
    '1054_781': 41.7831,
    '1054_782': 41.8147,
    '1054_783': 41.843,
    '1054_784': 41.8275,
    '1054_785': 41.7996,
    '1054_786': 41.7908,
    '1054_787': 41.794,
    '1054_788': 41.7973,
    '1054_789': 41.7805,
    '1054_790': 41.7648,
    '1054_791': 41.7655,
    '1054_792': 41.7765,
    '1054_793': 41.8004,
    '1054_794': 41.8463,
    '1054_795': 41.9124,
    '1054_796': 41.998,
    '1054_797': 42.1047,
    '1054_798': 42.2274,
    '1054_799': 42.3628,
    '1054_800': 42.4926,
    '1054_801': 42.6035,
    '1054_802': 42.6947,
    '1054_803': 42.7691,
    '1054_804': 42.8287,
    '1054_805': 42.897,
    '1054_806': 42.9992,
    '1054_807': 43.086,
    '1054_808': 43.1352,
    '1054_809': 43.1406,
    '1054_810': 43.119,
    '1054_811': 43.0752,
    '1054_812': 43.0185,
    '1054_813': 42.9487,
    '1054_814': 42.8784,
    '1054_815': 42.8295,
    '1054_816': 42.8021,
    '1054_817': 42.7861,
    '1054_818': 42.7815,
    '1054_819': 42.7895,
    '1054_820': 42.8071,
    '1054_821': 42.8355,
    '1054_822': 42.8748,
    '1054_823': 42.9268,
    '1054_824': 42.9836,
    '1054_825': 43.0314,
    '1054_826': 43.0504,
    '1054_827': 43.0535,
    '1054_828': 43.0351,
    '1054_829': 42.9922,
    '1054_830': 42.925,
    '1054_831': 42.844,
    '1054_832': 42.7529,
    '1054_833': 42.6537,
    '1054_834': 42.5355,
    '1054_835': 42.402,
    '1054_836': 42.2593,
    '1054_837': 42.0872,
    '1054_838': 41.9169,
    '1054_839': 41.7529,
    '1054_840': 41.5816,
    '1054_841': 41.4041,
    '1054_842': 41.2284,
    '1054_843': 41.0497,
    '1054_844': 40.8752,
    '1054_845': 40.7121,
    '1054_846': 40.551,
    '1054_847': 40.3821,
    '1054_848': 40.2018,
    '1055_680': 37.2119,
    '1055_681': 37.2266,
    '1055_682': 37.2398,
    '1055_683': 37.2343,
    '1055_684': 37.2218,
    '1055_685': 37.2141,
    '1055_686': 37.2132,
    '1055_687': 37.2135,
    '1055_688': 37.2071,
    '1055_689': 37.1939,
    '1055_690': 37.1803,
    '1055_691': 37.1652,
    '1055_692': 37.1454,
    '1055_693': 37.1273,
    '1055_694': 37.1109,
    '1055_695': 37.0826,
    '1055_696': 37.0541,
    '1055_697': 36.9976,
    '1055_698': 36.9184,
    '1055_699': 36.8296,
    '1055_700': 36.7396,
    '1055_701': 36.6499,
    '1055_702': 36.5638,
    '1055_703': 36.4822,
    '1055_704': 36.4045,
    '1055_736': 37.9184,
    '1055_737': 37.9407,
    '1055_738': 37.9596,
    '1055_739': 37.9891,
    '1055_740': 38.036,
    '1055_741': 38.0951,
    '1055_742': 38.167,
    '1055_743': 38.2592,
    '1055_744': 38.3585,
    '1055_745': 38.4596,
    '1055_746': 38.5571,
    '1055_747': 38.6492,
    '1055_748': 38.7351,
    '1055_749': 38.8061,
    '1055_750': 38.8581,
    '1055_751': 38.8923,
    '1055_752': 38.9281,
    '1055_753': 38.9666,
    '1055_754': 39.0166,
    '1055_755': 39.0825,
    '1055_756': 39.1626,
    '1055_757': 39.2598,
    '1055_758': 39.3767,
    '1055_759': 39.5108,
    '1055_760': 39.6452,
    '1055_761': 39.779,
    '1055_762': 39.9251,
    '1055_763': 40.0895,
    '1055_764': 40.2807,
    '1055_765': 40.4886,
    '1055_766': 40.7076,
    '1055_767': 40.9149,
    '1055_768': 41.0824,
    '1055_769': 41.2091,
    '1055_770': 41.3205,
    '1055_771': 41.4184,
    '1055_772': 41.4934,
    '1055_773': 41.5447,
    '1055_774': 41.5859,
    '1055_775': 41.5932,
    '1055_776': 41.5801,
    '1055_777': 41.5881,
    '1055_778': 41.6074,
    '1055_779': 41.6303,
    '1055_780': 41.6611,
    '1055_781': 41.7021,
    '1055_782': 41.7423,
    '1055_783': 41.7711,
    '1055_784': 41.7681,
    '1055_785': 41.7434,
    '1055_786': 41.7349,
    '1055_787': 41.7496,
    '1055_788': 41.7647,
    '1055_789': 41.7552,
    '1055_790': 41.7381,
    '1055_791': 41.7357,
    '1055_792': 41.7504,
    '1055_793': 41.7754,
    '1055_794': 41.823,
    '1055_795': 41.8915,
    '1055_796': 41.9805,
    '1055_797': 42.0911,
    '1055_798': 42.2234,
    '1055_799': 42.3728,
    '1055_800': 42.5151,
    '1055_801': 42.6336,
    '1055_802': 42.7306,
    '1055_803': 42.8074,
    '1055_804': 42.8619,
    '1055_805': 42.9149,
    '1055_806': 43.0033,
    '1055_807': 43.0837,
    '1055_808': 43.1337,
    '1055_809': 43.153,
    '1055_810': 43.1504,
    '1055_811': 43.124,
    '1055_812': 43.0716,
    '1055_813': 42.9994,
    '1055_814': 42.9258,
    '1055_815': 42.8678,
    '1055_816': 42.8183,
    '1055_817': 42.7876,
    '1055_818': 42.7713,
    '1055_819': 42.7712,
    '1055_820': 42.785,
    '1055_821': 42.8131,
    '1055_822': 42.8553,
    '1055_823': 42.908,
    '1055_824': 42.9657,
    '1055_825': 43.0122,
    '1055_826': 43.0351,
    '1055_827': 43.0386,
    '1055_828': 43.0234,
    '1055_829': 42.9841,
    '1055_830': 42.9238,
    '1055_831': 42.8462,
    '1055_832': 42.7625,
    '1055_833': 42.668,
    '1055_834': 42.5512,
    '1055_835': 42.4181,
    '1055_836': 42.2703,
    '1055_837': 42.105,
    '1055_838': 41.9437,
    '1055_839': 41.7841,
    '1055_840': 41.6174,
    '1055_841': 41.445,
    '1055_842': 41.2746,
    '1055_843': 41.1008,
    '1055_844': 40.9312,
    '1055_845': 40.7737,
    '1055_846': 40.618,
    '1055_847': 40.4511,
    '1055_848': 40.2713,
    '1056_680': 37.1901,
    '1056_681': 37.1999,
    '1056_682': 37.2058,
    '1056_683': 37.2063,
    '1056_684': 37.2033,
    '1056_685': 37.1989,
    '1056_686': 37.1961,
    '1056_687': 37.1938,
    '1056_688': 37.1853,
    '1056_689': 37.1696,
    '1056_690': 37.1499,
    '1056_691': 37.1316,
    '1056_692': 37.114,
    '1056_693': 37.0975,
    '1056_694': 37.0838,
    '1056_695': 37.0737,
    '1056_696': 37.041,
    '1056_697': 36.9841,
    '1056_698': 36.9109,
    '1056_699': 36.8283,
    '1056_700': 36.7368,
    '1056_701': 36.642,
    '1056_702': 36.5498,
    '1056_703': 36.4606,
    '1056_704': 36.3782,
    '1056_736': 37.9022,
    '1056_737': 37.9206,
    '1056_738': 37.9304,
    '1056_739': 37.9485,
    '1056_740': 37.9862,
    '1056_741': 38.0449,
    '1056_742': 38.1185,
    '1056_743': 38.2081,
    '1056_744': 38.3068,
    '1056_745': 38.4091,
    '1056_746': 38.509,
    '1056_747': 38.6059,
    '1056_748': 38.6997,
    '1056_749': 38.7801,
    '1056_750': 38.8417,
    '1056_751': 38.875,
    '1056_752': 38.8987,
    '1056_753': 38.9277,
    '1056_754': 38.9699,
    '1056_755': 39.0275,
    '1056_756': 39.1001,
    '1056_757': 39.1894,
    '1056_758': 39.2977,
    '1056_759': 39.4223,
    '1056_760': 39.5549,
    '1056_761': 39.6949,
    '1056_762': 39.8406,
    '1056_763': 40.0013,
    '1056_764': 40.1837,
    '1056_765': 40.3885,
    '1056_766': 40.605,
    '1056_767': 40.8113,
    '1056_768': 40.9748,
    '1056_769': 41.0943,
    '1056_770': 41.2066,
    '1056_771': 41.3085,
    '1056_772': 41.3976,
    '1056_773': 41.4656,
    '1056_774': 41.5244,
    '1056_775': 41.5433,
    '1056_776': 41.5192,
    '1056_777': 41.5008,
    '1056_778': 41.5113,
    '1056_779': 41.541,
    '1056_780': 41.5851,
    '1056_781': 41.6398,
    '1056_782': 41.6902,
    '1056_783': 41.7162,
    '1056_784': 41.7237,
    '1056_785': 41.7103,
    '1056_786': 41.7048,
    '1056_787': 41.7191,
    '1056_788': 41.7348,
    '1056_789': 41.7297,
    '1056_790': 41.7159,
    '1056_791': 41.7155,
    '1056_792': 41.7326,
    '1056_793': 41.7615,
    '1056_794': 41.8107,
    '1056_795': 41.8804,
    '1056_796': 41.9723,
    '1056_797': 42.0861,
    '1056_798': 42.2282,
    '1056_799': 42.3927,
    '1056_800': 42.5416,
    '1056_801': 42.6668,
    '1056_802': 42.7658,
    '1056_803': 42.8299,
    '1056_804': 42.8924,
    '1056_805': 42.9371,
    '1056_806': 43.0064,
    '1056_807': 43.0735,
    '1056_808': 43.1243,
    '1056_809': 43.1569,
    '1056_810': 43.1719,
    '1056_811': 43.1567,
    '1056_812': 43.1113,
    '1056_813': 43.0409,
    '1056_814': 42.9645,
    '1056_815': 42.8919,
    '1056_816': 42.8299,
    '1056_817': 42.7882,
    '1056_818': 42.76,
    '1056_819': 42.7514,
    '1056_820': 42.7621,
    '1056_821': 42.79,
    '1056_822': 42.8344,
    '1056_823': 42.8872,
    '1056_824': 42.9423,
    '1056_825': 42.9865,
    '1056_826': 43.0112,
    '1056_827': 43.0183,
    '1056_828': 43.0065,
    '1056_829': 42.9739,
    '1056_830': 42.9193,
    '1056_831': 42.8491,
    '1056_832': 42.7694,
    '1056_833': 42.6788,
    '1056_834': 42.5676,
    '1056_835': 42.4352,
    '1056_836': 42.2807,
    '1056_837': 42.1169,
    '1056_838': 41.9621,
    '1056_839': 41.8075,
    '1056_840': 41.6471,
    '1056_841': 41.4821,
    '1056_842': 41.3167,
    '1056_843': 41.1478,
    '1056_844': 40.9825,
    '1056_845': 40.8283,
    '1056_846': 40.675,
    '1056_847': 40.5128,
    '1056_848': 40.3382,
    '1057_680': 37.17,
    '1057_681': 37.1751,
    '1057_682': 37.1756,
    '1057_683': 37.1833,
    '1057_684': 37.1919,
    '1057_685': 37.1916,
    '1057_686': 37.1882,
    '1057_687': 37.1852,
    '1057_688': 37.174,
    '1057_689': 37.1555,
    '1057_690': 37.1333,
    '1057_691': 37.1117,
    '1057_692': 37.0924,
    '1057_693': 37.0764,
    '1057_694': 37.0656,
    '1057_695': 37.055,
    '1057_696': 37.0282,
    '1057_697': 36.9823,
    '1057_698': 36.9189,
    '1057_699': 36.8411,
    '1057_700': 36.7537,
    '1057_701': 36.6568,
    '1057_702': 36.5546,
    '1057_703': 36.4567,
    '1057_704': 36.3685,
    '1057_746': 38.4653,
    '1057_747': 38.5663,
    '1057_748': 38.6648,
    '1057_749': 38.7574,
    '1057_750': 38.8303,
    '1057_751': 38.8653,
    '1057_752': 38.8784,
    '1057_753': 38.8974,
    '1057_754': 38.9306,
    '1057_755': 38.9797,
    '1057_756': 39.0429,
    '1057_757': 39.1224,
    '1057_758': 39.2227,
    '1057_759': 39.3381,
    '1057_760': 39.4663,
    '1057_761': 39.6075,
    '1057_762': 39.7561,
    '1057_763': 39.9134,
    '1057_764': 40.0869,
    '1057_765': 40.2846,
    '1057_766': 40.4934,
    '1057_767': 40.6955,
    '1057_768': 40.8601,
    '1057_769': 40.9786,
    '1057_770': 41.0904,
    '1057_771': 41.2006,
    '1057_772': 41.2972,
    '1057_773': 41.3846,
    '1057_774': 41.4562,
    '1057_775': 41.4821,
    '1057_776': 41.4655,
    '1057_777': 41.4372,
    '1057_778': 41.4367,
    '1057_779': 41.4721,
    '1057_780': 41.531,
    '1057_781': 41.6029,
    '1057_782': 41.6625,
    '1057_783': 41.6859,
    '1057_784': 41.6944,
    '1057_785': 41.694,
    '1057_786': 41.6943,
    '1057_787': 41.7093,
    '1057_788': 41.7221,
    '1057_789': 41.7191,
    '1057_790': 41.7094,
    '1057_791': 41.7082,
    '1057_792': 41.7258,
    '1057_793': 41.7612,
    '1057_794': 41.8092,
    '1057_795': 41.8785,
    '1057_796': 41.971,
    '1057_797': 42.0884,
    '1057_798': 42.2362,
    '1057_799': 42.4081,
    '1057_800': 42.5632,
    '1057_801': 42.6888,
    '1057_802': 42.7868,
    '1057_803': 42.859,
    '1057_804': 42.9176,
    '1057_805': 42.9654,
    '1057_806': 43.0188,
    '1057_807': 43.0722,
    '1057_808': 43.1183,
    '1057_809': 43.1541,
    '1057_810': 43.1797,
    '1057_811': 43.1705,
    '1057_812': 43.131,
    '1057_813': 43.0632,
    '1057_814': 42.9799,
    '1057_815': 42.9029,
    '1057_816': 42.8308,
    '1057_817': 42.7799,
    '1057_818': 42.7434,
    '1057_819': 42.7282,
    '1057_820': 42.7369,
    '1057_821': 42.7638,
    '1057_822': 42.8073,
    '1057_823': 42.8597,
    '1057_824': 42.9063,
    '1057_825': 42.9501,
    '1057_826': 42.9804,
    '1057_827': 42.9957,
    '1057_828': 42.9879,
    '1057_829': 42.9583,
    '1057_830': 42.9103,
    '1057_831': 42.8464,
    '1057_832': 42.7689,
    '1057_833': 42.683,
    '1057_834': 42.5785,
    '1057_835': 42.4453,
    '1057_836': 42.2895,
    '1057_837': 42.1272,
    '1057_838': 41.976,
    '1057_839': 41.8268,
    '1057_840': 41.6734,
    '1057_841': 41.5136,
    '1057_842': 41.3549,
    '1057_843': 41.1918,
    '1057_844': 41.0311,
    '1057_845': 40.8798,
    '1057_846': 40.7298,
    '1057_847': 40.5733,
    '1057_848': 40.402,
    '1058_680': 37.1563,
    '1058_681': 37.1622,
    '1058_682': 37.1659,
    '1058_683': 37.1742,
    '1058_684': 37.1835,
    '1058_685': 37.1878,
    '1058_686': 37.1868,
    '1058_687': 37.1819,
    '1058_688': 37.1685,
    '1058_689': 37.1466,
    '1058_690': 37.1216,
    '1058_691': 37.0972,
    '1058_692': 37.0757,
    '1058_693': 37.0593,
    '1058_694': 37.0501,
    '1058_695': 37.0402,
    '1058_696': 37.0213,
    '1058_697': 36.9893,
    '1058_698': 36.9382,
    '1058_699': 36.869,
    '1058_700': 36.7808,
    '1058_701': 36.679,
    '1058_702': 36.5704,
    '1058_703': 36.4684,
    '1058_704': 36.3757,
    '1058_746': 38.4223,
    '1058_747': 38.5255,
    '1058_748': 38.6281,
    '1058_749': 38.7272,
    '1058_750': 38.8081,
    '1058_751': 38.8537,
    '1058_752': 38.867,
    '1058_753': 38.874,
    '1058_754': 38.8981,
    '1058_755': 38.9369,
    '1058_756': 38.9906,
    '1058_757': 39.0621,
    '1058_758': 39.1529,
    '1058_759': 39.2596,
    '1058_760': 39.3816,
    '1058_761': 39.5204,
    '1058_762': 39.6704,
    '1058_763': 39.8271,
    '1058_764': 39.9949,
    '1058_765': 40.1801,
    '1058_766': 40.3732,
    '1058_767': 40.5624,
    '1058_768': 40.7333,
    '1058_769': 40.8644,
    '1058_770': 40.9765,
    '1058_771': 41.0957,
    '1058_772': 41.1982,
    '1058_773': 41.2989,
    '1058_774': 41.3745,
    '1058_775': 41.4079,
    '1058_776': 41.4084,
    '1058_777': 41.3827,
    '1058_778': 41.3786,
    '1058_779': 41.4166,
    '1058_780': 41.4911,
    '1058_781': 41.5737,
    '1058_782': 41.6321,
    '1058_783': 41.6574,
    '1058_784': 41.6712,
    '1058_785': 41.6867,
    '1058_786': 41.6934,
    '1058_787': 41.7113,
    '1058_788': 41.7344,
    '1058_789': 41.7323,
    '1058_790': 41.7191,
    '1058_791': 41.716,
    '1058_792': 41.7352,
    '1058_793': 41.7728,
    '1058_794': 41.8188,
    '1058_795': 41.887,
    '1058_796': 41.9787,
    '1058_797': 42.0956,
    '1058_798': 42.2447,
    '1058_799': 42.415,
    '1058_800': 42.5715,
    '1058_801': 42.7035,
    '1058_802': 42.7985,
    '1058_803': 42.8715,
    '1058_804': 42.9345,
    '1058_805': 42.9906,
    '1058_806': 43.0434,
    '1058_807': 43.0882,
    '1058_808': 43.124,
    '1058_809': 43.1577,
    '1058_810': 43.1828,
    '1058_811': 43.1723,
    '1058_812': 43.1365,
    '1058_813': 43.0645,
    '1058_814': 42.9742,
    '1058_815': 42.8928,
    '1058_816': 42.8193,
    '1058_817': 42.7613,
    '1058_818': 42.7161,
    '1058_819': 42.6968,
    '1058_820': 42.7062,
    '1058_821': 42.7306,
    '1058_822': 42.77,
    '1058_823': 42.8157,
    '1058_824': 42.8546,
    '1058_825': 42.9008,
    '1058_826': 42.944,
    '1058_827': 42.9693,
    '1058_828': 42.964,
    '1058_829': 42.9369,
    '1058_830': 42.8941,
    '1058_831': 42.8362,
    '1058_832': 42.7633,
    '1058_833': 42.6791,
    '1058_834': 42.5761,
    '1058_835': 42.447,
    '1058_836': 42.2926,
    '1058_837': 42.1348,
    '1058_838': 41.9864,
    '1058_839': 41.8419,
    '1058_840': 41.6947,
    '1058_841': 41.5418,
    '1058_842': 41.3898,
    '1058_843': 41.2327,
    '1058_844': 41.0771,
    '1058_845': 40.9275,
    '1058_846': 40.7802,
    '1058_847': 40.628,
    '1058_848': 40.464,
    '1059_680': 37.1356,
    '1059_681': 37.1473,
    '1059_682': 37.1558,
    '1059_683': 37.166,
    '1059_684': 37.1768,
    '1059_685': 37.1843,
    '1059_686': 37.1852,
    '1059_687': 37.1796,
    '1059_688': 37.1629,
    '1059_689': 37.1387,
    '1059_690': 37.1116,
    '1059_691': 37.0855,
    '1059_692': 37.0641,
    '1059_693': 37.0526,
    '1059_694': 37.0399,
    '1059_695': 37.0286,
    '1059_696': 37.015,
    '1059_697': 36.9954,
    '1059_698': 36.954,
    '1059_699': 36.8899,
    '1059_700': 36.8073,
    '1059_701': 36.7066,
    '1059_702': 36.5977,
    '1059_703': 36.4895,
    '1059_704': 36.3946,
    '1059_746': 38.3789,
    '1059_747': 38.4818,
    '1059_748': 38.5856,
    '1059_749': 38.687,
    '1059_750': 38.7742,
    '1059_751': 38.8375,
    '1059_752': 38.8594,
    '1059_753': 38.859,
    '1059_754': 38.8719,
    '1059_755': 38.8978,
    '1059_756': 38.9406,
    '1059_757': 39.0034,
    '1059_758': 39.0863,
    '1059_759': 39.1863,
    '1059_760': 39.3027,
    '1059_761': 39.437,
    '1059_762': 39.585,
    '1059_763': 39.741,
    '1059_764': 39.9031,
    '1059_765': 40.0702,
    '1059_766': 40.2433,
    '1059_767': 40.4215,
    '1059_768': 40.5958,
    '1059_769': 40.7418,
    '1059_770': 40.8685,
    '1059_771': 40.9928,
    '1059_772': 41.1047,
    '1059_773': 41.2105,
    '1059_774': 41.2824,
    '1059_775': 41.323,
    '1059_776': 41.3387,
    '1059_777': 41.3282,
    '1059_778': 41.3295,
    '1059_779': 41.3711,
    '1059_780': 41.4551,
    '1059_781': 41.5456,
    '1059_782': 41.6088,
    '1059_783': 41.6419,
    '1059_784': 41.6633,
    '1059_785': 41.6896,
    '1059_786': 41.7065,
    '1059_787': 41.7261,
    '1059_788': 41.7623,
    '1059_789': 41.7648,
    '1059_790': 41.7495,
    '1059_791': 41.7413,
    '1059_792': 41.7592,
    '1059_793': 41.7937,
    '1059_794': 41.8382,
    '1059_795': 41.9039,
    '1059_796': 41.9953,
    '1059_797': 42.1141,
    '1059_798': 42.2606,
    '1059_799': 42.4217,
    '1059_800': 42.5764,
    '1059_801': 42.7056,
    '1059_802': 42.8046,
    '1059_803': 42.8818,
    '1059_804': 42.9522,
    '1059_805': 43.0192,
    '1059_806': 43.0721,
    '1059_807': 43.1184,
    '1059_808': 43.1505,
    '1059_809': 43.1762,
    '1059_810': 43.1921,
    '1059_811': 43.1714,
    '1059_812': 43.1267,
    '1059_813': 43.0435,
    '1059_814': 42.9508,
    '1059_815': 42.8684,
    '1059_816': 42.7972,
    '1059_817': 42.7333,
    '1059_818': 42.6858,
    '1059_819': 42.6682,
    '1059_820': 42.6754,
    '1059_821': 42.6948,
    '1059_822': 42.7261,
    '1059_823': 42.7642,
    '1059_824': 42.7984,
    '1059_825': 42.8484,
    '1059_826': 42.9036,
    '1059_827': 42.9373,
    '1059_828': 42.9331,
    '1059_829': 42.9076,
    '1059_830': 42.8711,
    '1059_831': 42.819,
    '1059_832': 42.7529,
    '1059_833': 42.673,
    '1059_834': 42.5665,
    '1059_835': 42.4372,
    '1059_836': 42.2885,
    '1059_837': 42.1401,
    '1059_838': 41.9954,
    '1059_839': 41.8552,
    '1059_840': 41.7136,
    '1059_841': 41.5676,
    '1059_842': 41.4213,
    '1059_843': 41.2691,
    '1059_844': 41.1166,
    '1059_845': 40.9692,
    '1059_846': 40.8269,
    '1059_847': 40.6804,
    '1059_848': 40.5249,
    '1060_680': 37.1147,
    '1060_681': 37.128,
    '1060_682': 37.14,
    '1060_683': 37.1535,
    '1060_684': 37.1667,
    '1060_685': 37.177,
    '1060_686': 37.1787,
    '1060_687': 37.1722,
    '1060_688': 37.1554,
    '1060_689': 37.1304,
    '1060_690': 37.1027,
    '1060_691': 37.0767,
    '1060_692': 37.0551,
    '1060_693': 37.0468,
    '1060_694': 37.0311,
    '1060_695': 37.0165,
    '1060_696': 36.9984,
    '1060_697': 36.981,
    '1060_698': 36.9536,
    '1060_699': 36.9035,
    '1060_700': 36.8275,
    '1060_701': 36.7356,
    '1060_702': 36.626,
    '1060_703': 36.5167,
    '1060_704': 36.4163,
    '1060_746': 38.3352,
    '1060_747': 38.4373,
    '1060_748': 38.5402,
    '1060_749': 38.6394,
    '1060_750': 38.7318,
    '1060_751': 38.8047,
    '1060_752': 38.8502,
    '1060_753': 38.8555,
    '1060_754': 38.8545,
    '1060_755': 38.8658,
    '1060_756': 38.8958,
    '1060_757': 38.9493,
    '1060_758': 39.0242,
    '1060_759': 39.1178,
    '1060_760': 39.229,
    '1060_761': 39.3596,
    '1060_762': 39.5069,
    '1060_763': 39.662,
    '1060_764': 39.8105,
    '1060_765': 39.9564,
    '1060_766': 40.1114,
    '1060_767': 40.2805,
    '1060_768': 40.4542,
    '1060_769': 40.6167,
    '1060_770': 40.7679,
    '1060_771': 40.9049,
    '1060_772': 41.0169,
    '1060_773': 41.1171,
    '1060_774': 41.1806,
    '1060_775': 41.2291,
    '1060_776': 41.2671,
    '1060_777': 41.2715,
    '1060_778': 41.2875,
    '1060_779': 41.3393,
    '1060_780': 41.4309,
    '1060_781': 41.5243,
    '1060_782': 41.5958,
    '1060_783': 41.6346,
    '1060_784': 41.668,
    '1060_785': 41.7008,
    '1060_786': 41.7271,
    '1060_787': 41.7562,
    '1060_788': 41.7989,
    '1060_789': 41.8091,
    '1060_790': 41.7939,
    '1060_791': 41.781,
    '1060_792': 41.793,
    '1060_793': 41.8235,
    '1060_794': 41.865,
    '1060_795': 41.9286,
    '1060_796': 42.0225,
    '1060_797': 42.146,
    '1060_798': 42.2909,
    '1060_799': 42.4422,
    '1060_800': 42.5909,
    '1060_801': 42.7186,
    '1060_802': 42.8169,
    '1060_803': 42.9018,
    '1060_804': 42.981,
    '1060_805': 43.0491,
    '1060_806': 43.1072,
    '1060_807': 43.1579,
    '1060_808': 43.1937,
    '1060_809': 43.2119,
    '1060_810': 43.2087,
    '1060_811': 43.1773,
    '1060_812': 43.1107,
    '1060_813': 43.0087,
    '1060_814': 42.9115,
    '1060_815': 42.8286,
    '1060_816': 42.7573,
    '1060_817': 42.6969,
    '1060_818': 42.6554,
    '1060_819': 42.6393,
    '1060_820': 42.6421,
    '1060_821': 42.6598,
    '1060_822': 42.6854,
    '1060_823': 42.7158,
    '1060_824': 42.75,
    '1060_825': 42.7997,
    '1060_826': 42.8611,
    '1060_827': 42.8988,
    '1060_828': 42.8985,
    '1060_829': 42.877,
    '1060_830': 42.8466,
    '1060_831': 42.8014,
    '1060_832': 42.7444,
    '1060_833': 42.6679,
    '1060_834': 42.5598,
    '1060_835': 42.4258,
    '1060_836': 42.285,
    '1060_837': 42.1439,
    '1060_838': 42.0035,
    '1060_839': 41.8679,
    '1060_840': 41.7318,
    '1060_841': 41.591,
    '1060_842': 41.4505,
    '1060_843': 41.303,
    '1060_844': 41.1536,
    '1060_845': 41.0071,
    '1060_846': 40.8701,
    '1060_847': 40.7259,
    '1060_848': 40.5813,
    '1061_680': 37.0769,
    '1061_681': 37.0947,
    '1061_682': 37.1132,
    '1061_683': 37.132,
    '1061_684': 37.1464,
    '1061_685': 37.1564,
    '1061_686': 37.162,
    '1061_687': 37.1591,
    '1061_688': 37.1437,
    '1061_689': 37.1182,
    '1061_690': 37.0916,
    '1061_691': 37.0708,
    '1061_692': 37.0542,
    '1061_693': 37.0389,
    '1061_694': 37.0156,
    '1061_695': 36.9947,
    '1061_696': 36.9721,
    '1061_697': 36.9515,
    '1061_698': 36.9296,
    '1061_699': 36.8933,
    '1061_700': 36.8385,
    '1061_701': 36.76,
    '1061_702': 36.6568,
    '1061_703': 36.5431,
    '1061_704': 36.4393,
    '1061_746': 38.2922,
    '1061_747': 38.3923,
    '1061_748': 38.4912,
    '1061_749': 38.5897,
    '1061_750': 38.6859,
    '1061_751': 38.769,
    '1061_752': 38.8332,
    '1061_753': 38.8492,
    '1061_754': 38.8392,
    '1061_755': 38.8367,
    '1061_756': 38.8513,
    '1061_757': 38.895,
    '1061_758': 38.9615,
    '1061_759': 39.0483,
    '1061_760': 39.1541,
    '1061_761': 39.2811,
    '1061_762': 39.4306,
    '1061_763': 39.5866,
    '1061_764': 39.7204,
    '1061_765': 39.8432,
    '1061_766': 39.9821,
    '1061_767': 40.1412,
    '1061_768': 40.3114,
    '1061_769': 40.4895,
    '1061_770': 40.6725,
    '1061_771': 40.8249,
    '1061_772': 40.9317,
    '1061_773': 41.0144,
    '1061_774': 41.0749,
    '1061_775': 41.1344,
    '1061_776': 41.1959,
    '1061_777': 41.2215,
    '1061_778': 41.2578,
    '1061_779': 41.3218,
    '1061_780': 41.4161,
    '1061_781': 41.5123,
    '1061_782': 41.5873,
    '1061_783': 41.6332,
    '1061_784': 41.6824,
    '1061_785': 41.722,
    '1061_786': 41.7546,
    '1061_787': 41.7937,
    '1061_788': 41.8406,
    '1061_789': 41.8603,
    '1061_790': 41.8493,
    '1061_791': 41.828,
    '1061_792': 41.8337,
    '1061_793': 41.8616,
    '1061_794': 41.8979,
    '1061_795': 41.9607,
    '1061_796': 42.0578,
    '1061_797': 42.1849,
    '1061_798': 42.3288,
    '1061_799': 42.4761,
    '1061_800': 42.6186,
    '1061_801': 42.7387,
    '1061_802': 42.8368,
    '1061_803': 42.9282,
    '1061_804': 43.0153,
    '1061_805': 43.0873,
    '1061_806': 43.1492,
    '1061_807': 43.2012,
    '1061_808': 43.2401,
    '1061_809': 43.2443,
    '1061_810': 43.2261,
    '1061_811': 43.1747,
    '1061_812': 43.078,
    '1061_813': 42.9611,
    '1061_814': 42.8566,
    '1061_815': 42.7739,
    '1061_816': 42.7064,
    '1061_817': 42.6477,
    '1061_818': 42.6116,
    '1061_819': 42.5988,
    '1061_820': 42.6035,
    '1061_821': 42.6246,
    '1061_822': 42.6494,
    '1061_823': 42.675,
    '1061_824': 42.7085,
    '1061_825': 42.7565,
    '1061_826': 42.8155,
    '1061_827': 42.8532,
    '1061_828': 42.8593,
    '1061_829': 42.8444,
    '1061_830': 42.8196,
    '1061_831': 42.7811,
    '1061_832': 42.7291,
    '1061_833': 42.6547,
    '1061_834': 42.5483,
    '1061_835': 42.422,
    '1061_836': 42.2837,
    '1061_837': 42.1454,
    '1061_838': 42.0087,
    '1061_839': 41.8774,
    '1061_840': 41.747,
    '1061_841': 41.6114,
    '1061_842': 41.4763,
    '1061_843': 41.3337,
    '1061_844': 41.1892,
    '1061_845': 41.047,
    '1061_846': 40.9111,
    '1061_847': 40.7759,
    '1061_848': 40.6394,
    '1062_680': 37.0269,
    '1062_681': 37.0565,
    '1062_682': 37.0857,
    '1062_683': 37.1083,
    '1062_684': 37.1262,
    '1062_685': 37.1396,
    '1062_686': 37.1465,
    '1062_687': 37.1439,
    '1062_688': 37.1303,
    '1062_689': 37.1041,
    '1062_690': 37.08,
    '1062_691': 37.0665,
    '1062_692': 37.0571,
    '1062_693': 37.0382,
    '1062_694': 37.0112,
    '1062_695': 36.9808,
    '1062_696': 36.9496,
    '1062_697': 36.9222,
    '1062_698': 36.8968,
    '1062_699': 36.865,
    '1062_700': 36.8192,
    '1062_701': 36.7531,
    '1062_702': 36.6653,
    '1062_703': 36.5587,
    '1062_704': 36.4545,
    '1062_746': 38.2542,
    '1062_747': 38.3481,
    '1062_748': 38.44,
    '1062_749': 38.5404,
    '1062_750': 38.6415,
    '1062_751': 38.7364,
    '1062_752': 38.8212,
    '1062_753': 38.8426,
    '1062_754': 38.8195,
    '1062_755': 38.8061,
    '1062_756': 38.8103,
    '1062_757': 38.8429,
    '1062_758': 38.9006,
    '1062_759': 38.9801,
    '1062_760': 39.0794,
    '1062_761': 39.2,
    '1062_762': 39.3431,
    '1062_763': 39.4932,
    '1062_764': 39.6247,
    '1062_765': 39.7376,
    '1062_766': 39.862,
    '1062_767': 40.0108,
    '1062_768': 40.176,
    '1062_769': 40.358,
    '1062_770': 40.5571,
    '1062_771': 40.7262,
    '1062_772': 40.8414,
    '1062_773': 40.9196,
    '1062_774': 40.9854,
    '1062_775': 41.0545,
    '1062_776': 41.1351,
    '1062_777': 41.1981,
    '1062_778': 41.2529,
    '1062_779': 41.3235,
    '1062_780': 41.409,
    '1062_781': 41.503,
    '1062_782': 41.581,
    '1062_783': 41.6446,
    '1062_784': 41.7116,
    '1062_785': 41.7605,
    '1062_786': 41.802,
    '1062_787': 41.8444,
    '1062_788': 41.8883,
    '1062_789': 41.9184,
    '1062_790': 41.9083,
    '1062_791': 41.8816,
    '1062_792': 41.8841,
    '1062_793': 41.9094,
    '1062_794': 41.9421,
    '1062_795': 42.005,
    '1062_796': 42.1052,
    '1062_797': 42.2303,
    '1062_798': 42.3734,
    '1062_799': 42.5192,
    '1062_800': 42.6542,
    '1062_801': 42.7638,
    '1062_802': 42.8583,
    '1062_803': 42.9552,
    '1062_804': 43.0456,
    '1062_805': 43.1263,
    '1062_806': 43.1927,
    '1062_807': 43.231,
    '1062_808': 43.2681,
    '1062_809': 43.252,
    '1062_810': 43.2191,
    '1062_811': 43.1555,
    '1062_812': 43.0457,
    '1062_813': 42.9159,
    '1062_814': 42.7995,
    '1062_815': 42.715,
    '1062_816': 42.6504,
    '1062_817': 42.5954,
    '1062_818': 42.5604,
    '1062_819': 42.5546,
    '1062_820': 42.5641,
    '1062_821': 42.5896,
    '1062_822': 42.6192,
    '1062_823': 42.6413,
    '1062_824': 42.6669,
    '1062_825': 42.7174,
    '1062_826': 42.7712,
    '1062_827': 42.8094,
    '1062_828': 42.823,
    '1062_829': 42.8156,
    '1062_830': 42.7934,
    '1062_831': 42.7583,
    '1062_832': 42.7087,
    '1062_833': 42.6389,
    '1062_834': 42.538,
    '1062_835': 42.4144,
    '1062_836': 42.2835,
    '1062_837': 42.1461,
    '1062_838': 42.0127,
    '1062_839': 41.8881,
    '1062_840': 41.7633,
    '1062_841': 41.6325,
    '1062_842': 41.502,
    '1062_843': 41.364,
    '1062_844': 41.2231,
    '1062_845': 41.0831,
    '1062_846': 40.9476,
    '1062_847': 40.8173,
    '1062_848': 40.6853,
    '1063_746': 38.2101,
    '1063_747': 38.3025,
    '1063_748': 38.3978,
    '1063_749': 38.4945,
    '1063_750': 38.5937,
    '1063_751': 38.6935,
    '1063_752': 38.7828,
    '1063_753': 38.8145,
    '1063_754': 38.7849,
    '1063_755': 38.7646,
    '1063_756': 38.7604,
    '1063_757': 38.7865,
    '1063_758': 38.8382,
    '1063_759': 38.9111,
    '1063_760': 39.0028,
    '1063_761': 39.114,
    '1063_762': 39.2469,
    '1063_763': 39.3883,
    '1063_764': 39.5213,
    '1063_765': 39.6308,
    '1063_766': 39.7473,
    '1063_767': 39.8868,
    '1063_768': 40.0474,
    '1063_769': 40.2249,
    '1063_770': 40.4217,
    '1063_771': 40.6058,
    '1063_772': 40.7344,
    '1063_773': 40.827,
    '1063_774': 40.9101,
    '1063_775': 40.9832,
    '1063_776': 41.0798,
    '1063_777': 41.1738,
    '1063_778': 41.2531,
    '1063_779': 41.3361,
    '1063_780': 41.4214,
    '1063_781': 41.5082,
    '1063_782': 41.5949,
    '1063_783': 41.6696,
    '1063_784': 41.7456,
    '1063_785': 41.808,
    '1063_786': 41.8647,
    '1063_787': 41.9035,
    '1063_788': 41.939,
    '1063_789': 41.9735,
    '1063_790': 41.9692,
    '1063_791': 41.9437,
    '1063_792': 41.9437,
    '1063_793': 41.9667,
    '1063_794': 42.0006,
    '1063_795': 42.0617,
    '1063_796': 42.1624,
    '1063_797': 42.2878,
    '1063_798': 42.4233,
    '1063_799': 42.5605,
    '1063_800': 42.6777,
    '1063_801': 42.7809,
    '1063_802': 42.8724,
    '1063_803': 42.9715,
    '1063_804': 43.0645,
    '1063_805': 43.144,
    '1063_806': 43.2089,
    '1063_807': 43.2467,
    '1063_808': 43.2661,
    '1063_809': 43.25,
    '1063_810': 43.2101,
    '1063_811': 43.1338,
    '1063_812': 43.0139,
    '1063_813': 42.8771,
    '1063_814': 42.7523,
    '1063_815': 42.6581,
    '1063_816': 42.5943,
    '1063_817': 42.5415,
    '1063_818': 42.509,
    '1063_819': 42.5074,
    '1063_820': 42.5238,
    '1063_821': 42.5528,
    '1063_822': 42.5822,
    '1063_823': 42.6045,
    '1063_824': 42.6232,
    '1063_825': 42.6713,
    '1063_826': 42.7256,
    '1063_827': 42.7702,
    '1063_828': 42.788,
    '1063_829': 42.7861,
    '1063_830': 42.7656,
    '1063_831': 42.7288,
    '1063_832': 42.6779,
    '1063_833': 42.6094,
    '1063_834': 42.5215,
    '1063_835': 42.4001,
    '1063_836': 42.2779,
    '1063_837': 42.1458,
    '1063_838': 42.0192,
    '1063_839': 41.9005,
    '1063_840': 41.7796,
    '1063_841': 41.6532,
    '1063_842': 41.5268,
    '1063_843': 41.3927,
    '1063_844': 41.2547,
    '1063_845': 41.1152,
    '1063_846': 40.9775,
    '1063_847': 40.8469,
    '1063_848': 40.7126,
    '1064_746': 38.1686,
    '1064_747': 38.2558,
    '1064_748': 38.3517,
    '1064_749': 38.4452,
    '1064_750': 38.5437,
    '1064_751': 38.6413,
    '1064_752': 38.7239,
    '1064_753': 38.7592,
    '1064_754': 38.7369,
    '1064_755': 38.7145,
    '1064_756': 38.7063,
    '1064_757': 38.7286,
    '1064_758': 38.7748,
    '1064_759': 38.8411,
    '1064_760': 38.9257,
    '1064_761': 39.0285,
    '1064_762': 39.1483,
    '1064_763': 39.2774,
    '1064_764': 39.407,
    '1064_765': 39.5264,
    '1064_766': 39.6399,
    '1064_767': 39.7736,
    '1064_768': 39.9258,
    '1064_769': 40.0942,
    '1064_770': 40.2828,
    '1064_771': 40.4725,
    '1064_772': 40.6235,
    '1064_773': 40.7421,
    '1064_774': 40.8502,
    '1064_775': 40.9291,
    '1064_776': 41.0246,
    '1064_777': 41.1322,
    '1064_778': 41.2374,
    '1064_779': 41.3487,
    '1064_780': 41.4546,
    '1064_781': 41.5407,
    '1064_782': 41.6301,
    '1064_783': 41.703,
    '1064_784': 41.7761,
    '1064_785': 41.8496,
    '1064_786': 41.9173,
    '1064_787': 41.96,
    '1064_788': 41.9882,
    '1064_789': 42.0219,
    '1064_790': 42.0294,
    '1064_791': 42.0175,
    '1064_792': 42.0155,
    '1064_793': 42.0384,
    '1064_794': 42.0741,
    '1064_795': 42.1365,
    '1064_796': 42.2326,
    '1064_797': 42.3499,
    '1064_798': 42.4724,
    '1064_799': 42.5942,
    '1064_800': 42.696,
    '1064_801': 42.7865,
    '1064_802': 42.8723,
    '1064_803': 42.9695,
    '1064_804': 43.0605,
    '1064_805': 43.1213,
    '1064_806': 43.1838,
    '1064_807': 43.2411,
    '1064_808': 43.2555,
    '1064_809': 43.2373,
    '1064_810': 43.1759,
    '1064_811': 43.1028,
    '1064_812': 42.9803,
    '1064_813': 42.8431,
    '1064_814': 42.7135,
    '1064_815': 42.6094,
    '1064_816': 42.5399,
    '1064_817': 42.4878,
    '1064_818': 42.4601,
    '1064_819': 42.4612,
    '1064_820': 42.4819,
    '1064_821': 42.5093,
    '1064_822': 42.5336,
    '1064_823': 42.5592,
    '1064_824': 42.5795,
    '1064_825': 42.622,
    '1064_826': 42.6798,
    '1064_827': 42.7313,
    '1064_828': 42.7543,
    '1064_829': 42.7544,
    '1064_830': 42.7371,
    '1064_831': 42.7009,
    '1064_832': 42.6473,
    '1064_833': 42.5867,
    '1064_834': 42.5066,
    '1064_835': 42.3927,
    '1064_836': 42.2704,
    '1064_837': 42.143,
    '1064_838': 42.0242,
    '1064_839': 41.9117,
    '1064_840': 41.7956,
    '1064_841': 41.6726,
    '1064_842': 41.5503,
    '1064_843': 41.4204,
    '1064_844': 41.2864,
    '1064_845': 41.1503,
    '1064_846': 41.0155,
    '1064_847': 40.8854,
    '1064_848': 40.7513,
    '1065_746': 38.1229,
    '1065_747': 38.2082,
    '1065_748': 38.3114,
    '1065_749': 38.403,
    '1065_750': 38.4956,
    '1065_751': 38.586,
    '1065_752': 38.6599,
    '1065_753': 38.6993,
    '1065_754': 38.6805,
    '1065_755': 38.6604,
    '1065_756': 38.6536,
    '1065_757': 38.6723,
    '1065_758': 38.7132,
    '1065_759': 38.7729,
    '1065_760': 38.8504,
    '1065_761': 38.9444,
    '1065_762': 39.0528,
    '1065_763': 39.1724,
    '1065_764': 39.2967,
    '1065_765': 39.4213,
    '1065_766': 39.5372,
    '1065_767': 39.6662,
    '1065_768': 39.814,
    '1065_769': 39.9789,
    '1065_770': 40.1638,
    '1065_771': 40.3649,
    '1065_772': 40.5485,
    '1065_773': 40.689,
    '1065_774': 40.7944,
    '1065_775': 40.8867,
    '1065_776': 40.9838,
    '1065_777': 41.0894,
    '1065_778': 41.2136,
    '1065_779': 41.3461,
    '1065_780': 41.4735,
    '1065_781': 41.5772,
    '1065_782': 41.6691,
    '1065_783': 41.7487,
    '1065_784': 41.8151,
    '1065_785': 41.8888,
    '1065_786': 41.9644,
    '1065_787': 42.0122,
    '1065_788': 42.0422,
    '1065_789': 42.0718,
    '1065_790': 42.0889,
    '1065_791': 42.0914,
    '1065_792': 42.0943,
    '1065_793': 42.1145,
    '1065_794': 42.1551,
    '1065_795': 42.2189,
    '1065_796': 42.3032,
    '1065_797': 42.4043,
    '1065_798': 42.5097,
    '1065_799': 42.6086,
    '1065_800': 42.696,
    '1065_801': 42.7772,
    '1065_802': 42.8573,
    '1065_803': 42.9402,
    '1065_804': 43.026,
    '1065_805': 43.0966,
    '1065_806': 43.1394,
    '1065_807': 43.1902,
    '1065_808': 43.2005,
    '1065_809': 43.1885,
    '1065_810': 43.1366,
    '1065_811': 43.0515,
    '1065_812': 42.9405,
    '1065_813': 42.8112,
    '1065_814': 42.6795,
    '1065_815': 42.5678,
    '1065_816': 42.491,
    '1065_817': 42.4356,
    '1065_818': 42.4115,
    '1065_819': 42.4132,
    '1065_820': 42.4331,
    '1065_821': 42.4586,
    '1065_822': 42.4823,
    '1065_823': 42.5072,
    '1065_824': 42.5343,
    '1065_825': 42.5766,
    '1065_826': 42.6346,
    '1065_827': 42.6911,
    '1065_828': 42.7218,
    '1065_829': 42.7268,
    '1065_830': 42.7123,
    '1065_831': 42.6784,
    '1065_832': 42.6286,
    '1065_833': 42.5693,
    '1065_834': 42.4897,
    '1065_835': 42.3809,
    '1065_836': 42.2602,
    '1065_837': 42.1394,
    '1065_838': 42.0285,
    '1065_839': 41.9218,
    '1065_840': 41.8094,
    '1065_841': 41.6907,
    '1065_842': 41.573,
    '1065_843': 41.4479,
    '1065_844': 41.3189,
    '1065_845': 41.1877,
    '1065_846': 41.0569,
    '1065_847': 40.9298,
    '1065_848': 40.7968,
    '1066_727': 37.5488,
    '1066_728': 37.7069,
    '1066_729': 37.8417,
    '1066_730': 37.9504,
    '1066_731': 38.0393,
    '1066_732': 38.1138,
    '1066_733': 38.1731,
    '1066_734': 38.2094,
    '1066_735': 38.218,
    '1066_736': 38.1964,
    '1066_737': 38.1544,
    '1066_738': 38.1184,
    '1066_739': 38.0893,
    '1066_740': 38.0745,
    '1066_741': 38.0842,
    '1066_742': 38.1158,
    '1066_743': 38.1601,
    '1066_744': 38.2083,
    '1066_746': 38.0868,
    '1066_747': 38.1778,
    '1066_748': 38.2708,
    '1066_749': 38.3586,
    '1066_750': 38.4478,
    '1066_751': 38.5338,
    '1066_752': 38.6059,
    '1066_753': 38.6552,
    '1066_754': 38.6425,
    '1066_755': 38.6103,
    '1066_756': 38.6018,
    '1066_757': 38.6157,
    '1066_758': 38.6523,
    '1066_759': 38.7065,
    '1066_760': 38.7775,
    '1066_761': 38.8638,
    '1066_762': 38.9634,
    '1066_763': 39.0749,
    '1066_764': 39.1927,
    '1066_765': 39.3144,
    '1066_766': 39.4352,
    '1066_767': 39.5616,
    '1066_768': 39.7067,
    '1066_769': 39.8686,
    '1066_770': 40.0522,
    '1066_771': 40.2632,
    '1066_772': 40.4735,
    '1066_773': 40.6411,
    '1066_774': 40.754,
    '1066_775': 40.8509,
    '1066_776': 40.9534,
    '1066_777': 41.0611,
    '1066_778': 41.1979,
    '1066_779': 41.3459,
    '1066_780': 41.4886,
    '1066_781': 41.6076,
    '1066_782': 41.7043,
    '1066_783': 41.7912,
    '1066_784': 41.8667,
    '1066_785': 41.9393,
    '1066_786': 42.014,
    '1066_787': 42.0683,
    '1066_788': 42.1046,
    '1066_789': 42.1294,
    '1066_790': 42.1466,
    '1066_791': 42.1526,
    '1066_792': 42.162,
    '1066_793': 42.1814,
    '1066_794': 42.2265,
    '1066_795': 42.2928,
    '1066_796': 42.3674,
    '1066_797': 42.4462,
    '1066_798': 42.5298,
    '1066_799': 42.6058,
    '1066_800': 42.681,
    '1066_801': 42.7509,
    '1066_802': 42.8189,
    '1066_803': 42.8907,
    '1066_804': 42.9648,
    '1066_805': 43.041,
    '1066_806': 43.0894,
    '1066_807': 43.1209,
    '1066_808': 43.1329,
    '1066_809': 43.129,
    '1066_810': 43.0736,
    '1066_811': 42.992,
    '1066_812': 42.8896,
    '1066_813': 42.7756,
    '1066_814': 42.6516,
    '1066_815': 42.539,
    '1066_816': 42.4532,
    '1066_817': 42.3939,
    '1066_818': 42.3657,
    '1066_819': 42.3669,
    '1066_820': 42.3826,
    '1066_821': 42.4086,
    '1066_822': 42.4335,
    '1066_823': 42.4566,
    '1066_824': 42.4853,
    '1066_825': 42.5299,
    '1066_826': 42.5917,
    '1066_827': 42.653,
    '1066_828': 42.689,
    '1066_829': 42.6959,
    '1066_830': 42.6829,
    '1066_831': 42.653,
    '1066_832': 42.6063,
    '1066_833': 42.5438,
    '1066_834': 42.4622,
    '1066_835': 42.3585,
    '1066_836': 42.2421,
    '1066_837': 42.13,
    '1066_838': 42.0274,
    '1066_839': 41.9257,
    '1066_840': 41.8182,
    '1066_841': 41.7056,
    '1066_842': 41.5927,
    '1066_843': 41.4728,
    '1066_844': 41.3493,
    '1066_845': 41.2239,
    '1066_846': 41.0981,
    '1066_847': 40.9722,
    '1066_848': 40.842,
    '1067_727': 37.5411,
    '1067_728': 37.7102,
    '1067_729': 37.8561,
    '1067_730': 37.973,
    '1067_731': 38.0653,
    '1067_732': 38.1397,
    '1067_733': 38.1964,
    '1067_734': 38.2302,
    '1067_735': 38.2373,
    '1067_736': 38.217,
    '1067_737': 38.1776,
    '1067_738': 38.136,
    '1067_739': 38.0989,
    '1067_740': 38.077,
    '1067_741': 38.0779,
    '1067_742': 38.099,
    '1067_743': 38.138,
    '1067_744': 38.1903,
    '1067_746': 38.0539,
    '1067_747': 38.145,
    '1067_748': 38.2347,
    '1067_749': 38.3206,
    '1067_750': 38.4038,
    '1067_751': 38.4835,
    '1067_752': 38.5527,
    '1067_753': 38.6059,
    '1067_754': 38.6022,
    '1067_755': 38.5622,
    '1067_756': 38.5522,
    '1067_757': 38.5634,
    '1067_758': 38.5946,
    '1067_759': 38.6432,
    '1067_760': 38.7082,
    '1067_761': 38.788,
    '1067_762': 38.8805,
    '1067_763': 38.9859,
    '1067_764': 39.0985,
    '1067_765': 39.2158,
    '1067_766': 39.3363,
    '1067_767': 39.4635,
    '1067_768': 39.6057,
    '1067_769': 39.7648,
    '1067_770': 39.9493,
    '1067_771': 40.1587,
    '1067_772': 40.3831,
    '1067_773': 40.5799,
    '1067_774': 40.7196,
    '1067_775': 40.8232,
    '1067_776': 40.9244,
    '1067_777': 41.0383,
    '1067_778': 41.1883,
    '1067_779': 41.3449,
    '1067_780': 41.4964,
    '1067_781': 41.6309,
    '1067_782': 41.7346,
    '1067_783': 41.8313,
    '1067_784': 41.919,
    '1067_785': 41.9975,
    '1067_786': 42.0745,
    '1067_787': 42.1345,
    '1067_788': 42.1717,
    '1067_789': 42.1976,
    '1067_790': 42.2071,
    '1067_791': 42.2078,
    '1067_792': 42.2168,
    '1067_793': 42.2403,
    '1067_794': 42.2837,
    '1067_795': 42.3506,
    '1067_796': 42.4136,
    '1067_797': 42.4745,
    '1067_798': 42.5373,
    '1067_799': 42.5896,
    '1067_800': 42.6475,
    '1067_801': 42.7043,
    '1067_802': 42.7598,
    '1067_803': 42.8182,
    '1067_804': 42.8785,
    '1067_805': 42.9392,
    '1067_806': 42.9905,
    '1067_807': 43.0264,
    '1067_808': 43.0372,
    '1067_809': 43.0307,
    '1067_810': 42.981,
    '1067_811': 42.9116,
    '1067_812': 42.8348,
    '1067_813': 42.7421,
    '1067_814': 42.6379,
    '1067_815': 42.5295,
    '1067_816': 42.4361,
    '1067_817': 42.3699,
    '1067_818': 42.3316,
    '1067_819': 42.323,
    '1067_820': 42.3373,
    '1067_821': 42.3611,
    '1067_822': 42.3832,
    '1067_823': 42.4081,
    '1067_824': 42.4396,
    '1067_825': 42.4863,
    '1067_826': 42.5504,
    '1067_827': 42.6161,
    '1067_828': 42.6546,
    '1067_829': 42.6619,
    '1067_830': 42.6513,
    '1067_831': 42.6251,
    '1067_832': 42.5803,
    '1067_833': 42.5175,
    '1067_834': 42.4432,
    '1067_835': 42.3389,
    '1067_836': 42.2227,
    '1067_837': 42.1193,
    '1067_838': 42.0234,
    '1067_839': 41.926,
    '1067_840': 41.8235,
    '1067_841': 41.7196,
    '1067_842': 41.609,
    '1067_843': 41.4942,
    '1067_844': 41.3764,
    '1067_845': 41.2564,
    '1067_846': 41.1352,
    '1067_847': 41.0121,
    '1067_848': 40.8847,
    '1068_727': 37.544,
    '1068_728': 37.7236,
    '1068_729': 37.8769,
    '1068_730': 38,
    '1068_731': 38.0933,
    '1068_732': 38.1701,
    '1068_733': 38.2286,
    '1068_734': 38.2633,
    '1068_735': 38.2708,
    '1068_736': 38.2517,
    '1068_737': 38.2118,
    '1068_738': 38.1671,
    '1068_739': 38.1191,
    '1068_740': 38.0886,
    '1068_741': 38.0801,
    '1068_742': 38.092,
    '1068_743': 38.1205,
    '1068_744': 38.1649,
    '1068_746': 38.0281,
    '1068_747': 38.1127,
    '1068_748': 38.2007,
    '1068_749': 38.2846,
    '1068_750': 38.3595,
    '1068_751': 38.4283,
    '1068_752': 38.4905,
    '1068_753': 38.5347,
    '1068_754': 38.5397,
    '1068_755': 38.5115,
    '1068_756': 38.5022,
    '1068_757': 38.5108,
    '1068_758': 38.538,
    '1068_759': 38.5824,
    '1068_760': 38.6428,
    '1068_761': 38.7177,
    '1068_762': 38.8058,
    '1068_763': 38.9052,
    '1068_764': 39.0127,
    '1068_765': 39.1253,
    '1068_766': 39.2429,
    '1068_767': 39.3705,
    '1068_768': 39.511,
    '1068_769': 39.6683,
    '1068_770': 39.8506,
    '1068_771': 40.0561,
    '1068_772': 40.2813,
    '1068_773': 40.5021,
    '1068_774': 40.6716,
    '1068_775': 40.7936,
    '1068_776': 40.892,
    '1068_777': 41.0099,
    '1068_778': 41.1644,
    '1068_779': 41.3285,
    '1068_780': 41.489,
    '1068_781': 41.6334,
    '1068_782': 41.7541,
    '1068_783': 41.8638,
    '1068_784': 41.9654,
    '1068_785': 42.061,
    '1068_786': 42.144,
    '1068_787': 42.2031,
    '1068_788': 42.2427,
    '1068_789': 42.2608,
    '1068_790': 42.2684,
    '1068_791': 42.2583,
    '1068_792': 42.2581,
    '1068_793': 42.2785,
    '1068_794': 42.32,
    '1068_795': 42.3737,
    '1068_796': 42.4317,
    '1068_797': 42.4804,
    '1068_798': 42.5288,
    '1068_799': 42.562,
    '1068_800': 42.5931,
    '1068_801': 42.6379,
    '1068_802': 42.6821,
    '1068_803': 42.7277,
    '1068_804': 42.7739,
    '1068_805': 42.8193,
    '1068_806': 42.8652,
    '1068_807': 42.9039,
    '1068_808': 42.9248,
    '1068_809': 42.9182,
    '1068_810': 42.8854,
    '1068_811': 42.8375,
    '1068_812': 42.7876,
    '1068_813': 42.7137,
    '1068_814': 42.6257,
    '1068_815': 42.5298,
    '1068_816': 42.4355,
    '1068_817': 42.362,
    '1068_818': 42.3166,
    '1068_819': 42.2986,
    '1068_820': 42.301,
    '1068_821': 42.3201,
    '1068_822': 42.3365,
    '1068_823': 42.3608,
    '1068_824': 42.3976,
    '1068_825': 42.4446,
    '1068_826': 42.5086,
    '1068_827': 42.5749,
    '1068_828': 42.6149,
    '1068_829': 42.6233,
    '1068_830': 42.6152,
    '1068_831': 42.5925,
    '1068_832': 42.5525,
    '1068_833': 42.4929,
    '1068_834': 42.4148,
    '1068_835': 42.3099,
    '1068_836': 42.1999,
    '1068_837': 42.1045,
    '1068_838': 42.0156,
    '1068_839': 41.9235,
    '1068_840': 41.8257,
    '1068_841': 41.7273,
    '1068_842': 41.6216,
    '1068_843': 41.5119,
    '1068_844': 41.3991,
    '1068_845': 41.2844,
    '1068_846': 41.168,
    '1068_847': 41.0483,
    '1068_848': 40.9231,
    '1069_675': 36.7969,
    '1069_676': 36.8266,
    '1069_677': 36.8571,
    '1069_678': 36.8887,
    '1069_679': 36.9213,
    '1069_727': 37.5456,
    '1069_728': 37.7358,
    '1069_729': 37.8979,
    '1069_730': 38.0227,
    '1069_731': 38.1177,
    '1069_732': 38.2001,
    '1069_733': 38.2674,
    '1069_734': 38.3086,
    '1069_735': 38.3198,
    '1069_736': 38.3053,
    '1069_737': 38.2692,
    '1069_738': 38.2223,
    '1069_739': 38.1669,
    '1069_740': 38.1136,
    '1069_741': 38.0918,
    '1069_742': 38.0929,
    '1069_743': 38.1104,
    '1069_744': 38.1481,
    '1069_748': 38.1664,
    '1069_749': 38.2475,
    '1069_750': 38.3117,
    '1069_751': 38.3688,
    '1069_752': 38.4193,
    '1069_753': 38.4538,
    '1069_754': 38.4658,
    '1069_755': 38.4549,
    '1069_756': 38.4498,
    '1069_757': 38.4574,
    '1069_758': 38.4819,
    '1069_759': 38.5228,
    '1069_760': 38.5792,
    '1069_761': 38.6502,
    '1069_762': 38.7343,
    '1069_763': 38.8286,
    '1069_764': 38.9314,
    '1069_765': 39.0403,
    '1069_766': 39.1562,
    '1069_767': 39.2818,
    '1069_768': 39.42,
    '1069_769': 39.575,
    '1069_770': 39.7533,
    '1069_771': 39.9549,
    '1069_772': 40.1776,
    '1069_773': 40.4077,
    '1069_774': 40.5982,
    '1069_775': 40.7379,
    '1069_776': 40.8384,
    '1069_777': 40.9632,
    '1069_778': 41.1241,
    '1069_779': 41.3,
    '1069_780': 41.4696,
    '1069_781': 41.624,
    '1069_782': 41.755,
    '1069_783': 41.8773,
    '1069_784': 41.9916,
    '1069_785': 42.1027,
    '1069_786': 42.1889,
    '1069_787': 42.2527,
    '1069_788': 42.3041,
    '1069_789': 42.3076,
    '1069_790': 42.3036,
    '1069_791': 42.2888,
    '1069_792': 42.2791,
    '1069_793': 42.2922,
    '1069_794': 42.3246,
    '1069_795': 42.3677,
    '1069_796': 42.4178,
    '1069_797': 42.4557,
    '1069_798': 42.4893,
    '1069_799': 42.5097,
    '1069_800': 42.525,
    '1069_801': 42.5538,
    '1069_802': 42.5869,
    '1069_803': 42.6222,
    '1069_804': 42.6608,
    '1069_805': 42.6967,
    '1069_806': 42.7367,
    '1069_807': 42.7779,
    '1069_808': 42.8038,
    '1069_809': 42.816,
    '1069_810': 42.8056,
    '1069_811': 42.7883,
    '1069_812': 42.7526,
    '1069_813': 42.6923,
    '1069_814': 42.6159,
    '1069_815': 42.5303,
    '1069_816': 42.4386,
    '1069_817': 42.3657,
    '1069_818': 42.3176,
    '1069_819': 42.2905,
    '1069_820': 42.2832,
    '1069_821': 42.2881,
    '1069_822': 42.2973,
    '1069_823': 42.3188,
    '1069_824': 42.3561,
    '1069_825': 42.4045,
    '1069_826': 42.468,
    '1069_827': 42.5286,
    '1069_828': 42.5674,
    '1069_829': 42.5758,
    '1069_830': 42.5697,
    '1069_831': 42.5482,
    '1069_832': 42.5074,
    '1069_833': 42.4465,
    '1069_834': 42.3712,
    '1069_835': 42.273,
    '1069_836': 42.1712,
    '1069_837': 42.0842,
    '1069_838': 42.002,
    '1069_839': 41.9156,
    '1069_840': 41.823,
    '1069_841': 41.7296,
    '1069_842': 41.629,
    '1069_843': 41.5242,
    '1069_844': 41.4163,
    '1069_845': 41.3064,
    '1069_846': 41.1952,
    '1069_847': 41.0806,
    '1069_848': 40.958,
    '1070_675': 36.7511,
    '1070_676': 36.7797,
    '1070_677': 36.8089,
    '1070_678': 36.838,
    '1070_679': 36.8669,
    '1070_727': 37.531,
    '1070_728': 37.7222,
    '1070_729': 37.8907,
    '1070_730': 38.0234,
    '1070_731': 38.1313,
    '1070_732': 38.2258,
    '1070_733': 38.312,
    '1070_734': 38.3684,
    '1070_735': 38.38,
    '1070_736': 38.3661,
    '1070_737': 38.3336,
    '1070_738': 38.2857,
    '1070_739': 38.2183,
    '1070_740': 38.1481,
    '1070_741': 38.1106,
    '1070_742': 38.099,
    '1070_743': 38.1064,
    '1070_744': 38.1395,
    '1070_748': 38.1291,
    '1070_749': 38.2011,
    '1070_750': 38.2611,
    '1070_751': 38.3079,
    '1070_752': 38.3466,
    '1070_753': 38.3765,
    '1070_754': 38.391,
    '1070_755': 38.394,
    '1070_756': 38.3968,
    '1070_757': 38.4062,
    '1070_758': 38.4287,
    '1070_759': 38.4665,
    '1070_760': 38.5187,
    '1070_761': 38.587,
    '1070_762': 38.6673,
    '1070_763': 38.7575,
    '1070_764': 38.8559,
    '1070_765': 38.9614,
    '1070_766': 39.075,
    '1070_767': 39.198,
    '1070_768': 39.3328,
    '1070_769': 39.4836,
    '1070_770': 39.6588,
    '1070_771': 39.8571,
    '1070_772': 40.0781,
    '1070_773': 40.308,
    '1070_774': 40.5065,
    '1070_775': 40.6587,
    '1070_776': 40.7695,
    '1070_777': 40.9061,
    '1070_778': 41.0829,
    '1070_779': 41.2744,
    '1070_780': 41.4525,
    '1070_781': 41.6098,
    '1070_782': 41.7454,
    '1070_783': 41.879,
    '1070_784': 42.0065,
    '1070_785': 42.1145,
    '1070_786': 42.2007,
    '1070_787': 42.2768,
    '1070_788': 42.3278,
    '1070_789': 42.3278,
    '1070_790': 42.3234,
    '1070_791': 42.2943,
    '1070_792': 42.2744,
    '1070_793': 42.2808,
    '1070_794': 42.3012,
    '1070_795': 42.3321,
    '1070_796': 42.3735,
    '1070_797': 42.4089,
    '1070_798': 42.4311,
    '1070_799': 42.438,
    '1070_800': 42.437,
    '1070_801': 42.4505,
    '1070_802': 42.4754,
    '1070_803': 42.5102,
    '1070_804': 42.5447,
    '1070_805': 42.5811,
    '1070_806': 42.6252,
    '1070_807': 42.668,
    '1070_808': 42.6977,
    '1070_809': 42.7313,
    '1070_810': 42.7519,
    '1070_811': 42.7581,
    '1070_812': 42.7329,
    '1070_813': 42.6811,
    '1070_814': 42.6104,
    '1070_815': 42.5273,
    '1070_816': 42.4423,
    '1070_817': 42.3754,
    '1070_818': 42.3293,
    '1070_819': 42.3004,
    '1070_820': 42.2823,
    '1070_821': 42.2704,
    '1070_822': 42.2689,
    '1070_823': 42.283,
    '1070_824': 42.3151,
    '1070_825': 42.3641,
    '1070_826': 42.4254,
    '1070_827': 42.4807,
    '1070_828': 42.5173,
    '1070_829': 42.5251,
    '1070_830': 42.5177,
    '1070_831': 42.4944,
    '1070_832': 42.4487,
    '1070_833': 42.3883,
    '1070_834': 42.3228,
    '1070_835': 42.2372,
    '1070_836': 42.1417,
    '1070_837': 42.0625,
    '1070_838': 41.9864,
    '1070_839': 41.9042,
    '1070_840': 41.8174,
    '1070_841': 41.7289,
    '1070_842': 41.6332,
    '1070_843': 41.5331,
    '1070_844': 41.4295,
    '1070_845': 41.3239,
    '1070_846': 41.2181,
    '1070_847': 41.1113,
    '1070_848': 40.9926,
    '1071_675': 36.7047,
    '1071_676': 36.7346,
    '1071_677': 36.7634,
    '1071_678': 36.789,
    '1071_679': 36.8144,
    '1071_727': 37.5028,
    '1071_728': 37.694,
    '1071_729': 37.8618,
    '1071_730': 38.0037,
    '1071_731': 38.1275,
    '1071_732': 38.2399,
    '1071_733': 38.3357,
    '1071_734': 38.4112,
    '1071_735': 38.4418,
    '1071_736': 38.4279,
    '1071_737': 38.3958,
    '1071_738': 38.3443,
    '1071_739': 38.2725,
    '1071_740': 38.1969,
    '1071_741': 38.1391,
    '1071_742': 38.1118,
    '1071_743': 38.1139,
    '1071_744': 38.1405,
    '1071_748': 38.0938,
    '1071_749': 38.1547,
    '1071_750': 38.2057,
    '1071_751': 38.2461,
    '1071_752': 38.2787,
    '1071_753': 38.3057,
    '1071_754': 38.3226,
    '1071_755': 38.3324,
    '1071_756': 38.3411,
    '1071_757': 38.3552,
    '1071_758': 38.378,
    '1071_759': 38.4138,
    '1071_760': 38.4636,
    '1071_761': 38.5277,
    '1071_762': 38.6038,
    '1071_763': 38.6902,
    '1071_764': 38.785,
    '1071_765': 38.8872,
    '1071_766': 38.9978,
    '1071_767': 39.1183,
    '1071_768': 39.2504,
    '1071_769': 39.3987,
    '1071_770': 39.569,
    '1071_771': 39.7655,
    '1071_772': 39.9822,
    '1071_773': 40.2,
    '1071_774': 40.4013,
    '1071_775': 40.5644,
    '1071_776': 40.691,
    '1071_777': 40.8461,
    '1071_778': 41.0422,
    '1071_779': 41.2455,
    '1071_780': 41.4333,
    '1071_781': 41.5941,
    '1071_782': 41.7362,
    '1071_783': 41.8659,
    '1071_784': 41.9955,
    '1071_785': 42.1237,
    '1071_786': 42.2231,
    '1071_787': 42.2674,
    '1071_788': 42.2904,
    '1071_789': 42.3051,
    '1071_790': 42.3039,
    '1071_791': 42.2725,
    '1071_792': 42.2431,
    '1071_793': 42.2366,
    '1071_794': 42.2463,
    '1071_795': 42.2645,
    '1071_796': 42.2962,
    '1071_797': 42.3299,
    '1071_798': 42.3446,
    '1071_799': 42.3402,
    '1071_800': 42.3303,
    '1071_801': 42.3361,
    '1071_802': 42.361,
    '1071_803': 42.3983,
    '1071_804': 42.4338,
    '1071_805': 42.474,
    '1071_806': 42.5232,
    '1071_807': 42.5668,
    '1071_808': 42.6055,
    '1071_809': 42.6603,
    '1071_810': 42.7138,
    '1071_811': 42.7351,
    '1071_812': 42.7211,
    '1071_813': 42.6773,
    '1071_814': 42.6132,
    '1071_815': 42.5343,
    '1071_816': 42.4535,
    '1071_817': 42.398,
    '1071_818': 42.3624,
    '1071_819': 42.3334,
    '1071_820': 42.3005,
    '1071_821': 42.2667,
    '1071_822': 42.2514,
    '1071_823': 42.2577,
    '1071_824': 42.2846,
    '1071_825': 42.3287,
    '1071_826': 42.3834,
    '1071_827': 42.4313,
    '1071_828': 42.4643,
    '1071_829': 42.4702,
    '1071_830': 42.4587,
    '1071_831': 42.431,
    '1071_832': 42.3877,
    '1071_833': 42.3311,
    '1071_834': 42.2762,
    '1071_835': 42.1995,
    '1071_836': 42.1124,
    '1071_837': 42.0386,
    '1071_838': 41.9665,
    '1071_839': 41.8902,
    '1071_840': 41.8113,
    '1071_841': 41.7256,
    '1071_842': 41.6347,
    '1071_843': 41.5393,
    '1071_844': 41.4397,
    '1071_845': 41.3369,
    '1071_846': 41.2351,
    '1071_847': 41.1378,
    '1071_848': 41.0227,
    '1072_675': 36.6542,
    '1072_676': 36.6856,
    '1072_677': 36.7156,
    '1072_678': 36.74,
    '1072_679': 36.7618,
    '1072_727': 37.4717,
    '1072_728': 37.6627,
    '1072_729': 37.8314,
    '1072_730': 37.9806,
    '1072_731': 38.1164,
    '1072_732': 38.2441,
    '1072_733': 38.3462,
    '1072_734': 38.4299,
    '1072_735': 38.4808,
    '1072_736': 38.485,
    '1072_737': 38.4573,
    '1072_738': 38.4101,
    '1072_739': 38.3389,
    '1072_740': 38.257,
    '1072_741': 38.1858,
    '1072_742': 38.1423,
    '1072_743': 38.1299,
    '1072_744': 38.1464,
    '1072_750': 38.1558,
    '1072_751': 38.1891,
    '1072_752': 38.2167,
    '1072_753': 38.241,
    '1072_754': 38.2615,
    '1072_755': 38.2757,
    '1072_756': 38.2882,
    '1072_757': 38.3045,
    '1072_758': 38.328,
    '1072_759': 38.3617,
    '1072_760': 38.4089,
    '1072_761': 38.4694,
    '1072_762': 38.5419,
    '1072_763': 38.6248,
    '1072_764': 38.7166,
    '1072_765': 38.8158,
    '1072_766': 38.9236,
    '1072_767': 39.0414,
    '1072_768': 39.171,
    '1072_769': 39.3162,
    '1072_770': 39.4828,
    '1072_771': 39.6722,
    '1072_772': 39.8812,
    '1072_773': 40.0895,
    '1072_774': 40.2833,
    '1072_775': 40.4548,
    '1072_776': 40.6016,
    '1072_777': 40.7853,
    '1072_778': 41.0045,
    '1072_779': 41.2203,
    '1072_780': 41.41,
    '1072_781': 41.5716,
    '1072_782': 41.7083,
    '1072_783': 41.8416,
    '1072_784': 41.9715,
    '1072_785': 42.0909,
    '1072_786': 42.1726,
    '1072_787': 42.2355,
    '1072_788': 42.2634,
    '1072_789': 42.2692,
    '1072_790': 42.2518,
    '1072_791': 42.2185,
    '1072_792': 42.1884,
    '1072_793': 42.1703,
    '1072_794': 42.1701,
    '1072_795': 42.1773,
    '1072_796': 42.2021,
    '1072_797': 42.2258,
    '1072_798': 42.2348,
    '1072_799': 42.2273,
    '1072_800': 42.2151,
    '1072_801': 42.2208,
    '1072_802': 42.2515,
    '1072_803': 42.2946,
    '1072_804': 42.3305,
    '1072_805': 42.3711,
    '1072_806': 42.4219,
    '1072_807': 42.4692,
    '1072_808': 42.5153,
    '1072_809': 42.5875,
    '1072_810': 42.667,
    '1072_811': 42.7092,
    '1072_812': 42.7083,
    '1072_813': 42.6732,
    '1072_814': 42.6187,
    '1072_815': 42.5486,
    '1072_816': 42.4705,
    '1072_817': 42.4153,
    '1072_818': 42.3861,
    '1072_819': 42.3657,
    '1072_820': 42.3271,
    '1072_821': 42.2723,
    '1072_822': 42.246,
    '1072_823': 42.2451,
    '1072_824': 42.2666,
    '1072_825': 42.3004,
    '1072_826': 42.3453,
    '1072_827': 42.3844,
    '1072_828': 42.4111,
    '1072_829': 42.4126,
    '1072_830': 42.3958,
    '1072_831': 42.3606,
    '1072_832': 42.3193,
    '1072_833': 42.2704,
    '1072_834': 42.2261,
    '1072_835': 42.1607,
    '1072_836': 42.0824,
    '1072_837': 42.0135,
    '1072_838': 41.9459,
    '1072_839': 41.8764,
    '1072_840': 41.8002,
    '1072_841': 41.7189,
    '1072_842': 41.6332,
    '1072_843': 41.5431,
    '1072_844': 41.4484,
    '1072_845': 41.3488,
    '1072_846': 41.2485,
    '1072_847': 41.1459,
    '1072_848': 41.0335,
    '1073_675': 36.6003,
    '1073_676': 36.633,
    '1073_677': 36.6671,
    '1073_678': 36.6938,
    '1073_679': 36.7127,
    '1073_727': 37.438,
    '1073_728': 37.6297,
    '1073_729': 37.8006,
    '1073_730': 37.9555,
    '1073_731': 38.0991,
    '1073_732': 38.2365,
    '1073_733': 38.3505,
    '1073_734': 38.4364,
    '1073_735': 38.5039,
    '1073_736': 38.5308,
    '1073_737': 38.5178,
    '1073_738': 38.4824,
    '1073_739': 38.4124,
    '1073_740': 38.321,
    '1073_741': 38.2341,
    '1073_742': 38.1759,
    '1073_743': 38.1501,
    '1073_744': 38.1557,
    '1073_750': 38.1167,
    '1073_751': 38.1417,
    '1073_752': 38.1646,
    '1073_753': 38.1885,
    '1073_754': 38.2084,
    '1073_755': 38.2258,
    '1073_756': 38.2397,
    '1073_757': 38.2563,
    '1073_758': 38.2792,
    '1073_759': 38.3117,
    '1073_760': 38.3556,
    '1073_761': 38.4124,
    '1073_762': 38.4819,
    '1073_763': 38.5619,
    '1073_764': 38.6506,
    '1073_765': 38.7473,
    '1073_766': 38.853,
    '1073_767': 38.9684,
    '1073_768': 39.0952,
    '1073_769': 39.2361,
    '1073_770': 39.3962,
    '1073_771': 39.5763,
    '1073_772': 39.7774,
    '1073_773': 39.981,
    '1073_774': 40.1682,
    '1073_775': 40.3397,
    '1073_776': 40.5039,
    '1073_777': 40.7121,
    '1073_778': 40.9469,
    '1073_779': 41.1781,
    '1073_780': 41.3686,
    '1073_781': 41.5275,
    '1073_782': 41.6646,
    '1073_783': 41.8015,
    '1073_784': 41.9297,
    '1073_785': 42.0363,
    '1073_786': 42.1181,
    '1073_787': 42.1673,
    '1073_788': 42.1877,
    '1073_789': 42.1914,
    '1073_790': 42.1731,
    '1073_791': 42.1472,
    '1073_792': 42.1191,
    '1073_793': 42.0959,
    '1073_794': 42.0842,
    '1073_795': 42.081,
    '1073_796': 42.1009,
    '1073_797': 42.1179,
    '1073_798': 42.1222,
    '1073_799': 42.1118,
    '1073_800': 42.1038,
    '1073_801': 42.1157,
    '1073_802': 42.1513,
    '1073_803': 42.1999,
    '1073_804': 42.2338,
    '1073_805': 42.2765,
    '1073_806': 42.329,
    '1073_807': 42.3801,
    '1073_808': 42.4345,
    '1073_809': 42.5217,
    '1073_810': 42.619,
    '1073_811': 42.6749,
    '1073_812': 42.6871,
    '1073_813': 42.6614,
    '1073_814': 42.6119,
    '1073_815': 42.5511,
    '1073_816': 42.4851,
    '1073_817': 42.4291,
    '1073_818': 42.4062,
    '1073_819': 42.397,
    '1073_820': 42.3598,
    '1073_821': 42.2945,
    '1073_822': 42.2601,
    '1073_823': 42.2509,
    '1073_824': 42.2616,
    '1073_825': 42.2818,
    '1073_826': 42.3102,
    '1073_827': 42.3397,
    '1073_828': 42.3621,
    '1073_829': 42.3576,
    '1073_830': 42.3322,
    '1073_831': 42.289,
    '1073_832': 42.2536,
    '1073_833': 42.2126,
    '1073_834': 42.1809,
    '1073_835': 42.1253,
    '1073_836': 42.0517,
    '1073_837': 41.9883,
    '1073_838': 41.9244,
    '1073_839': 41.859,
    '1073_840': 41.7867,
    '1073_841': 41.71,
    '1073_842': 41.6296,
    '1073_843': 41.5455,
    '1073_844': 41.4574,
    '1073_845': 41.3644,
    '1073_846': 41.2603,
    '1073_847': 41.1522,
    '1073_848': 41.0412,
    '1074_727': 37.4053,
    '1074_728': 37.5963,
    '1074_729': 37.7697,
    '1074_730': 37.9285,
    '1074_731': 38.0763,
    '1074_732': 38.2169,
    '1074_733': 38.3368,
    '1074_734': 38.4343,
    '1074_735': 38.5101,
    '1074_736': 38.5588,
    '1074_737': 38.571,
    '1074_738': 38.5475,
    '1074_739': 38.4853,
    '1074_740': 38.3876,
    '1074_741': 38.2888,
    '1074_742': 38.2108,
    '1074_743': 38.1716,
    '1074_744': 38.1707,
    '1074_750': 38.0908,
    '1074_751': 38.1093,
    '1074_752': 38.1312,
    '1074_753': 38.1519,
    '1074_754': 38.1686,
    '1074_755': 38.1833,
    '1074_756': 38.1956,
    '1074_757': 38.2119,
    '1074_758': 38.2333,
    '1074_759': 38.264,
    '1074_760': 38.3056,
    '1074_761': 38.3585,
    '1074_762': 38.4256,
    '1074_763': 38.5031,
    '1074_764': 38.5892,
    '1074_765': 38.6836,
    '1074_766': 38.7872,
    '1074_767': 38.9004,
    '1074_768': 39.0244,
    '1074_769': 39.1615,
    '1074_770': 39.3149,
    '1074_771': 39.4874,
    '1074_772': 39.6811,
    '1074_773': 39.8799,
    '1074_774': 40.06,
    '1074_775': 40.2259,
    '1074_776': 40.4054,
    '1074_777': 40.6273,
    '1074_778': 40.8731,
    '1074_779': 41.1129,
    '1074_780': 41.3057,
    '1074_781': 41.4657,
    '1074_782': 41.5989,
    '1074_783': 41.7379,
    '1074_784': 41.8765,
    '1074_785': 41.9692,
    '1074_786': 42.0366,
    '1074_787': 42.0699,
    '1074_788': 42.0818,
    '1074_789': 42.0881,
    '1074_790': 42.0808,
    '1074_791': 42.0596,
    '1074_792': 42.0388,
    '1074_793': 42.018,
    '1074_794': 41.9994,
    '1074_795': 41.9887,
    '1074_796': 42.0028,
    '1074_797': 42.0159,
    '1074_798': 42.0172,
    '1074_799': 42.0075,
    '1074_800': 42.0086,
    '1074_801': 42.0243,
    '1074_802': 42.0612,
    '1074_803': 42.1079,
    '1074_804': 42.1461,
    '1074_805': 42.1927,
    '1074_806': 42.2449,
    '1074_807': 42.301,
    '1074_808': 42.369,
    '1074_809': 42.4661,
    '1074_810': 42.569,
    '1074_811': 42.6346,
    '1074_812': 42.6521,
    '1074_813': 42.6349,
    '1074_814': 42.5928,
    '1074_815': 42.5402,
    '1074_816': 42.4863,
    '1074_817': 42.44,
    '1074_818': 42.4243,
    '1074_819': 42.4214,
    '1074_820': 42.3897,
    '1074_821': 42.3377,
    '1074_822': 42.302,
    '1074_823': 42.2792,
    '1074_824': 42.2727,
    '1074_825': 42.2774,
    '1074_826': 42.2856,
    '1074_827': 42.2987,
    '1074_828': 42.3157,
    '1074_829': 42.31,
    '1074_830': 42.2743,
    '1074_831': 42.2266,
    '1074_832': 42.1931,
    '1074_833': 42.16,
    '1074_834': 42.1347,
    '1074_835': 42.0878,
    '1074_836': 42.0225,
    '1074_837': 41.9641,
    '1074_838': 41.9057,
    '1074_839': 41.8417,
    '1074_840': 41.7729,
    '1074_841': 41.7,
    '1074_842': 41.6241,
    '1074_843': 41.5451,
    '1074_844': 41.46,
    '1074_845': 41.3688,
    '1074_846': 41.2688,
    '1074_847': 41.1647,
    '1074_848': 41.0575,
    '1075_727': 37.3691,
    '1075_728': 37.5605,
    '1075_729': 37.7374,
    '1075_730': 37.898,
    '1075_731': 38.0464,
    '1075_732': 38.1874,
    '1075_733': 38.3109,
    '1075_734': 38.4166,
    '1075_735': 38.506,
    '1075_736': 38.5685,
    '1075_737': 38.595,
    '1075_738': 38.5842,
    '1075_739': 38.5324,
    '1075_740': 38.4443,
    '1075_741': 38.3361,
    '1075_742': 38.2436,
    '1075_743': 38.202,
    '1075_744': 38.1931,
    '1075_750': 38.0685,
    '1075_751': 38.0908,
    '1075_752': 38.1093,
    '1075_753': 38.1255,
    '1075_754': 38.1365,
    '1075_755': 38.1483,
    '1075_756': 38.1563,
    '1075_757': 38.1674,
    '1075_758': 38.1876,
    '1075_759': 38.2168,
    '1075_760': 38.2572,
    '1075_761': 38.3091,
    '1075_762': 38.3728,
    '1075_763': 38.447,
    '1075_764': 38.5307,
    '1075_765': 38.6227,
    '1075_766': 38.7242,
    '1075_767': 38.8355,
    '1075_768': 38.9573,
    '1075_769': 39.0913,
    '1075_770': 39.2398,
    '1075_771': 39.406,
    '1075_772': 39.591,
    '1075_773': 39.7875,
    '1075_774': 39.9672,
    '1075_775': 40.1321,
    '1075_776': 40.316,
    '1075_777': 40.5382,
    '1075_778': 40.7855,
    '1075_779': 41.0276,
    '1075_780': 41.2275,
    '1075_781': 41.3846,
    '1075_782': 41.5188,
    '1075_783': 41.6653,
    '1075_784': 41.8022,
    '1075_785': 41.8818,
    '1075_786': 41.9408,
    '1075_787': 41.9515,
    '1075_788': 41.9653,
    '1075_789': 41.9804,
    '1075_790': 41.982,
    '1075_791': 41.97,
    '1075_792': 41.9576,
    '1075_793': 41.9419,
    '1075_794': 41.9212,
    '1075_795': 41.9111,
    '1075_796': 41.9158,
    '1075_797': 41.9226,
    '1075_798': 41.9222,
    '1075_799': 41.9182,
    '1075_800': 41.9268,
    '1075_801': 41.948,
    '1075_802': 41.9852,
    '1075_803': 42.0272,
    '1075_804': 42.0692,
    '1075_805': 42.1184,
    '1075_806': 42.1698,
    '1075_807': 42.2293,
    '1075_808': 42.3085,
    '1075_809': 42.4102,
    '1075_810': 42.5131,
    '1075_811': 42.5841,
    '1075_812': 42.6102,
    '1075_813': 42.5987,
    '1075_814': 42.5636,
    '1075_815': 42.5191,
    '1075_816': 42.4779,
    '1075_817': 42.4443,
    '1075_818': 42.4368,
    '1075_819': 42.4349,
    '1075_820': 42.4068,
    '1075_821': 42.3681,
    '1075_822': 42.338,
    '1075_823': 42.3103,
    '1075_824': 42.2804,
    '1075_825': 42.2721,
    '1075_826': 42.2653,
    '1075_827': 42.2661,
    '1075_828': 42.2711,
    '1075_829': 42.2619,
    '1075_830': 42.22,
    '1075_831': 42.1725,
    '1075_832': 42.139,
    '1075_833': 42.1143,
    '1075_834': 42.0971,
    '1075_835': 42.0516,
    '1075_836': 41.9917,
    '1075_837': 41.9379,
    '1075_838': 41.8838,
    '1075_839': 41.8233,
    '1075_840': 41.7575,
    '1075_841': 41.6874,
    '1075_842': 41.6146,
    '1075_843': 41.5409,
    '1075_844': 41.4586,
    '1075_845': 41.3712,
    '1075_846': 41.2756,
    '1075_847': 41.1775,
    '1075_848': 41.0749,
    '1076_727': 37.3359,
    '1076_728': 37.5341,
    '1076_729': 37.7111,
    '1076_730': 37.8668,
    '1076_731': 38.0059,
    '1076_732': 38.1417,
    '1076_733': 38.2685,
    '1076_734': 38.3795,
    '1076_735': 38.4811,
    '1076_736': 38.5518,
    '1076_737': 38.5884,
    '1076_738': 38.5962,
    '1076_739': 38.567,
    '1076_740': 38.4863,
    '1076_741': 38.3801,
    '1076_742': 38.2924,
    '1076_743': 38.2401,
    '1076_744': 38.2184,
    '1076_750': 38.0532,
    '1076_751': 38.0719,
    '1076_752': 38.0892,
    '1076_753': 38.1025,
    '1076_754': 38.1098,
    '1076_755': 38.1157,
    '1076_756': 38.1189,
    '1076_757': 38.1254,
    '1076_758': 38.1412,
    '1076_759': 38.1706,
    '1076_760': 38.2107,
    '1076_761': 38.2613,
    '1076_762': 38.3225,
    '1076_763': 38.3946,
    '1076_764': 38.4763,
    '1076_765': 38.5659,
    '1076_766': 38.6652,
    '1076_767': 38.7751,
    '1076_768': 38.895,
    '1076_769': 39.0265,
    '1076_770': 39.1708,
    '1076_771': 39.331,
    '1076_772': 39.5123,
    '1076_773': 39.7044,
    '1076_774': 39.89,
    '1076_775': 40.0577,
    '1076_776': 40.2416,
    '1076_777': 40.4614,
    '1076_778': 40.7004,
    '1076_779': 40.9344,
    '1076_780': 41.1356,
    '1076_781': 41.2938,
    '1076_782': 41.4343,
    '1076_783': 41.5814,
    '1076_784': 41.7063,
    '1076_785': 41.7934,
    '1076_786': 41.8295,
    '1076_787': 41.8337,
    '1076_788': 41.8514,
    '1076_789': 41.8739,
    '1076_790': 41.8869,
    '1076_791': 41.8859,
    '1076_792': 41.884,
    '1076_793': 41.8765,
    '1076_794': 41.8647,
    '1076_795': 41.8558,
    '1076_796': 41.856,
    '1076_797': 41.8557,
    '1076_798': 41.8476,
    '1076_799': 41.8447,
    '1076_800': 41.8556,
    '1076_801': 41.881,
    '1076_802': 41.9192,
    '1076_803': 41.9591,
    '1076_804': 42.0031,
    '1076_805': 42.0529,
    '1076_806': 42.1022,
    '1076_807': 42.1629,
    '1076_808': 42.2459,
    '1076_809': 42.3461,
    '1076_810': 42.4566,
    '1076_811': 42.5355,
    '1076_812': 42.5671,
    '1076_813': 42.5621,
    '1076_814': 42.5326,
    '1076_815': 42.4974,
    '1076_816': 42.4714,
    '1076_817': 42.4551,
    '1076_818': 42.449,
    '1076_819': 42.4429,
    '1076_820': 42.4125,
    '1076_821': 42.3774,
    '1076_822': 42.3519,
    '1076_823': 42.323,
    '1076_824': 42.2844,
    '1076_825': 42.2626,
    '1076_826': 42.2505,
    '1076_827': 42.243,
    '1076_828': 42.2296,
    '1076_829': 42.2101,
    '1076_830': 42.17,
    '1076_831': 42.1255,
    '1076_832': 42.0962,
    '1076_833': 42.0773,
    '1076_834': 42.0618,
    '1076_835': 42.0164,
    '1076_836': 41.9621,
    '1076_837': 41.9127,
    '1076_838': 41.863,
    '1076_839': 41.8065,
    '1076_840': 41.744,
    '1076_841': 41.6756,
    '1076_842': 41.6027,
    '1076_843': 41.5329,
    '1076_844': 41.4566,
    '1076_845': 41.3764,
    '1076_846': 41.288,
    '1076_847': 41.1932,
    '1076_848': 41.0934,
    '1077_727': 37.3062,
    '1077_728': 37.5108,
    '1077_729': 37.6902,
    '1077_730': 37.8384,
    '1077_731': 37.9574,
    '1077_732': 38.0851,
    '1077_733': 38.2066,
    '1077_734': 38.3193,
    '1077_735': 38.4273,
    '1077_736': 38.5083,
    '1077_737': 38.5575,
    '1077_738': 38.5863,
    '1077_739': 38.5814,
    '1077_740': 38.517,
    '1077_741': 38.4107,
    '1077_742': 38.327,
    '1077_743': 38.2698,
    '1077_744': 38.2391,
    '1077_750': 38.0396,
    '1077_751': 38.0542,
    '1077_752': 38.0684,
    '1077_753': 38.0789,
    '1077_754': 38.0818,
    '1077_755': 38.0819,
    '1077_756': 38.0809,
    '1077_757': 38.0837,
    '1077_758': 38.0966,
    '1077_759': 38.1236,
    '1077_760': 38.1648,
    '1077_761': 38.2144,
    '1077_762': 38.2737,
    '1077_763': 38.3434,
    '1077_764': 38.4233,
    '1077_765': 38.511,
    '1077_766': 38.6081,
    '1077_767': 38.7167,
    '1077_768': 38.835,
    '1077_769': 38.964,
    '1077_770': 39.1051,
    '1077_771': 39.2604,
    '1077_772': 39.4365,
    '1077_773': 39.6246,
    '1077_774': 39.8155,
    '1077_775': 39.9957,
    '1077_776': 40.1785,
    '1077_777': 40.3865,
    '1077_778': 40.6164,
    '1077_779': 40.8402,
    '1077_780': 41.0338,
    '1077_781': 41.1922,
    '1077_782': 41.3374,
    '1077_783': 41.4826,
    '1077_784': 41.6007,
    '1077_785': 41.681,
    '1077_786': 41.7115,
    '1077_787': 41.717,
    '1077_788': 41.741,
    '1077_789': 41.7739,
    '1077_790': 41.8011,
    '1077_791': 41.8082,
    '1077_792': 41.816,
    '1077_793': 41.8194,
    '1077_794': 41.8178,
    '1077_795': 41.8163,
    '1077_796': 41.814,
    '1077_797': 41.8036,
    '1077_798': 41.7899,
    '1077_799': 41.7856,
    '1077_800': 41.7937,
    '1077_801': 41.8198,
    '1077_802': 41.8586,
    '1077_803': 41.898,
    '1077_804': 41.9431,
    '1077_805': 41.9928,
    '1077_806': 42.0443,
    '1077_807': 42.1062,
    '1077_808': 42.1896,
    '1077_809': 42.2872,
    '1077_810': 42.4002,
    '1077_811': 42.487,
    '1077_812': 42.5266,
    '1077_813': 42.5282,
    '1077_814': 42.5116,
    '1077_815': 42.4949,
    '1077_816': 42.4811,
    '1077_817': 42.4684,
    '1077_818': 42.4537,
    '1077_819': 42.4411,
    '1077_820': 42.4129,
    '1077_821': 42.3799,
    '1077_822': 42.349,
    '1077_823': 42.3217,
    '1077_824': 42.2812,
    '1077_825': 42.2436,
    '1077_826': 42.2292,
    '1077_827': 42.2196,
    '1077_828': 42.1995,
    '1077_829': 42.17,
    '1077_830': 42.1252,
    '1077_831': 42.082,
    '1077_832': 42.0542,
    '1077_833': 42.0373,
    '1077_834': 42.0239,
    '1077_835': 41.9767,
    '1077_836': 41.9305,
    '1077_837': 41.8878,
    '1077_838': 41.8428,
    '1077_839': 41.791,
    '1077_840': 41.7335,
    '1077_841': 41.6707,
    '1077_842': 41.6036,
    '1077_843': 41.5326,
    '1077_844': 41.4584,
    '1077_845': 41.3817,
    '1077_846': 41.2966,
    '1077_847': 41.2037,
    '1077_848': 41.1064,
    '1078_727': 37.2745,
    '1078_728': 37.4902,
    '1078_729': 37.6786,
    '1078_730': 37.8237,
    '1078_731': 37.9088,
    '1078_732': 38.0315,
    '1078_733': 38.1487,
    '1078_734': 38.2536,
    '1078_735': 38.3566,
    '1078_736': 38.4461,
    '1078_737': 38.5137,
    '1078_738': 38.5648,
    '1078_739': 38.5773,
    '1078_740': 38.5301,
    '1078_741': 38.4283,
    '1078_742': 38.3436,
    '1078_743': 38.2864,
    '1078_744': 38.2523,
    '1078_750': 38.0275,
    '1078_751': 38.0378,
    '1078_752': 38.047,
    '1078_753': 38.0532,
    '1078_754': 38.0534,
    '1078_755': 38.0498,
    '1078_756': 38.0464,
    '1078_757': 38.0481,
    '1078_758': 38.06,
    '1078_759': 38.0845,
    '1078_760': 38.1216,
    '1078_761': 38.169,
    '1078_762': 38.226,
    '1078_763': 38.2927,
    '1078_764': 38.371,
    '1078_765': 38.4585,
    '1078_766': 38.5554,
    '1078_767': 38.6625,
    '1078_768': 38.7785,
    '1078_769': 38.9045,
    '1078_770': 39.0424,
    '1078_771': 39.1942,
    '1078_772': 39.3633,
    '1078_773': 39.5472,
    '1078_774': 39.74,
    '1078_775': 39.9348,
    '1078_776': 40.1254,
    '1078_777': 40.3107,
    '1078_778': 40.5236,
    '1078_779': 40.7408,
    '1078_780': 40.9277,
    '1078_781': 41.0863,
    '1078_782': 41.2303,
    '1078_783': 41.3673,
    '1078_784': 41.4837,
    '1078_785': 41.5617,
    '1078_786': 41.5988,
    '1078_787': 41.6089,
    '1078_788': 41.6415,
    '1078_789': 41.6813,
    '1078_790': 41.7186,
    '1078_791': 41.734,
    '1078_792': 41.7503,
    '1078_793': 41.7671,
    '1078_794': 41.78,
    '1078_795': 41.7829,
    '1078_796': 41.779,
    '1078_797': 41.7614,
    '1078_798': 41.7431,
    '1078_799': 41.7398,
    '1078_800': 41.7475,
    '1078_801': 41.7714,
    '1078_802': 41.8049,
    '1078_803': 41.8443,
    '1078_804': 41.8915,
    '1078_805': 41.9431,
    '1078_806': 41.9959,
    '1078_807': 42.0603,
    '1078_808': 42.1496,
    '1078_809': 42.2498,
    '1078_810': 42.3564,
    '1078_811': 42.4471,
    '1078_812': 42.4938,
    '1078_813': 42.5048,
    '1078_814': 42.5063,
    '1078_815': 42.5042,
    '1078_816': 42.4947,
    '1078_817': 42.4757,
    '1078_818': 42.4512,
    '1078_819': 42.4357,
    '1078_820': 42.4153,
    '1078_821': 42.3894,
    '1078_822': 42.3501,
    '1078_823': 42.3153,
    '1078_824': 42.2722,
    '1078_825': 42.2193,
    '1078_826': 42.2005,
    '1078_827': 42.1883,
    '1078_828': 42.1685,
    '1078_829': 42.134,
    '1078_830': 42.0858,
    '1078_831': 42.0428,
    '1078_832': 42.0143,
    '1078_833': 41.9966,
    '1078_834': 41.9807,
    '1078_835': 41.9369,
    '1078_836': 41.8998,
    '1078_837': 41.8627,
    '1078_838': 41.8231,
    '1078_839': 41.7767,
    '1078_840': 41.7242,
    '1078_841': 41.6658,
    '1078_842': 41.6012,
    '1078_843': 41.5296,
    '1078_844': 41.4536,
    '1078_845': 41.3745,
    '1078_846': 41.2911,
    '1078_847': 41.2022,
    '1078_848': 41.1065,
    '1079_727': 37.2458,
    '1079_728': 37.4724,
    '1079_729': 37.6715,
    '1079_730': 37.8163,
    '1079_731': 37.9099,
    '1079_732': 38.0139,
    '1079_733': 38.1175,
    '1079_734': 38.2096,
    '1079_735': 38.294,
    '1079_736': 38.3823,
    '1079_737': 38.4638,
    '1079_738': 38.5283,
    '1079_739': 38.5564,
    '1079_740': 38.5301,
    '1079_741': 38.4413,
    '1079_742': 38.3484,
    '1079_743': 38.2947,
    '1079_744': 38.257,
    '1079_750': 38.0134,
    '1079_751': 38.0205,
    '1079_752': 38.0255,
    '1079_753': 38.0276,
    '1079_754': 38.0258,
    '1079_755': 38.0192,
    '1079_756': 38.0132,
    '1079_757': 38.0135,
    '1079_758': 38.025,
    '1079_759': 38.0479,
    '1079_760': 38.0821,
    '1079_761': 38.1273,
    '1079_762': 38.1796,
    '1079_763': 38.2439,
    '1079_764': 38.3225,
    '1079_765': 38.4101,
    '1079_766': 38.5065,
    '1079_767': 38.6113,
    '1079_768': 38.7247,
    '1079_769': 38.848,
    '1079_770': 38.9821,
    '1079_771': 39.1289,
    '1079_772': 39.2907,
    '1079_773': 39.4693,
    '1079_774': 39.6608,
    '1079_775': 39.8628,
    '1079_776': 40.0593,
    '1079_777': 40.2355,
    '1079_778': 40.4273,
    '1079_779': 40.6335,
    '1079_780': 40.8177,
    '1079_781': 40.9769,
    '1079_782': 41.1183,
    '1079_783': 41.2469,
    '1079_784': 41.3568,
    '1079_785': 41.4435,
    '1079_786': 41.4951,
    '1079_787': 41.5096,
    '1079_788': 41.5482,
    '1079_789': 41.5884,
    '1079_790': 41.6334,
    '1079_791': 41.6597,
    '1079_792': 41.6896,
    '1079_793': 41.722,
    '1079_794': 41.7443,
    '1079_795': 41.7494,
    '1079_796': 41.7488,
    '1079_797': 41.7297,
    '1079_798': 41.7055,
    '1079_799': 41.7028,
    '1079_800': 41.711,
    '1079_801': 41.7331,
    '1079_802': 41.762,
    '1079_803': 41.7993,
    '1079_804': 41.8465,
    '1079_805': 41.8992,
    '1079_806': 41.9547,
    '1079_807': 42.0198,
    '1079_808': 42.1087,
    '1079_809': 42.2075,
    '1079_810': 42.3144,
    '1079_811': 42.4147,
    '1079_812': 42.4711,
    '1079_813': 42.4933,
    '1079_814': 42.5052,
    '1079_815': 42.5136,
    '1079_816': 42.5089,
    '1079_817': 42.4846,
    '1079_818': 42.4545,
    '1079_819': 42.4405,
    '1079_820': 42.4255,
    '1079_821': 42.3977,
    '1079_822': 42.3528,
    '1079_823': 42.3067,
    '1079_824': 42.2503,
    '1079_825': 42.189,
    '1079_826': 42.1657,
    '1079_827': 42.1552,
    '1079_828': 42.1358,
    '1079_829': 42.0984,
    '1079_830': 42.0483,
    '1079_831': 42.007,
    '1079_832': 41.9789,
    '1079_833': 41.9621,
    '1079_834': 41.9449,
    '1079_835': 41.9004,
    '1079_836': 41.8707,
    '1079_837': 41.8393,
    '1079_838': 41.805,
    '1079_839': 41.7634,
    '1079_840': 41.7156,
    '1079_841': 41.6609,
    '1079_842': 41.5976,
    '1079_843': 41.524,
    '1079_844': 41.4437,
    '1079_845': 41.3609,
    '1079_846': 41.2783,
    '1079_847': 41.1933,
    '1079_848': 41.0998,
    '1080_727': 37.2222,
    '1080_728': 37.4533,
    '1080_729': 37.6579,
    '1080_730': 37.8075,
    '1080_731': 37.9095,
    '1080_732': 38.0079,
    '1080_733': 38.1001,
    '1080_734': 38.1821,
    '1080_735': 38.2533,
    '1080_736': 38.3265,
    '1080_737': 38.4074,
    '1080_738': 38.4797,
    '1080_739': 38.5223,
    '1080_740': 38.5216,
    '1080_741': 38.4659,
    '1080_742': 38.379,
    '1080_743': 38.3147,
    '1080_744': 38.2694,
    '1080_750': 37.9988,
    '1080_751': 38.0031,
    '1080_752': 38.0041,
    '1080_753': 38.0023,
    '1080_754': 37.9973,
    '1080_755': 37.9889,
    '1080_756': 37.98,
    '1080_757': 37.978,
    '1080_758': 37.9907,
    '1080_759': 38.0128,
    '1080_760': 38.0445,
    '1080_761': 38.0888,
    '1080_762': 38.1399,
    '1080_763': 38.206,
    '1080_764': 38.2818,
    '1080_765': 38.3679,
    '1080_766': 38.4622,
    '1080_767': 38.5654,
    '1080_768': 38.6764,
    '1080_769': 38.7963,
    '1080_770': 38.9265,
    '1080_771': 39.0687,
    '1080_772': 39.2243,
    '1080_773': 39.3949,
    '1080_774': 39.58,
    '1080_775': 39.7792,
    '1080_776': 39.9811,
    '1080_777': 40.1681,
    '1080_778': 40.3359,
    '1080_779': 40.5208,
    '1080_780': 40.7011,
    '1080_781': 40.8612,
    '1080_782': 41.0067,
    '1080_783': 41.1311,
    '1080_784': 41.2405,
    '1080_785': 41.3352,
    '1080_786': 41.3984,
    '1080_787': 41.4216,
    '1080_788': 41.4648,
    '1080_789': 41.5061,
    '1080_790': 41.5545,
    '1080_791': 41.5948,
    '1080_792': 41.6388,
    '1080_793': 41.6812,
    '1080_794': 41.71,
    '1080_795': 41.7192,
    '1080_796': 41.7235,
    '1080_797': 41.7049,
    '1080_798': 41.6806,
    '1080_799': 41.6767,
    '1080_800': 41.6871,
    '1080_801': 41.7065,
    '1080_802': 41.7307,
    '1080_803': 41.7643,
    '1080_804': 41.8107,
    '1080_805': 41.862,
    '1080_806': 41.92,
    '1080_807': 41.9823,
    '1080_808': 42.0633,
    '1080_809': 42.1608,
    '1080_810': 42.2749,
    '1080_811': 42.3818,
    '1080_812': 42.4501,
    '1080_813': 42.4868,
    '1080_814': 42.5101,
    '1080_815': 42.5253,
    '1080_816': 42.5264,
    '1080_817': 42.5043,
    '1080_818': 42.4796,
    '1080_819': 42.4655,
    '1080_820': 42.4462,
    '1080_821': 42.4097,
    '1080_822': 42.3539,
    '1080_823': 42.29,
    '1080_824': 42.2183,
    '1080_825': 42.1602,
    '1080_826': 42.1371,
    '1080_827': 42.1263,
    '1080_828': 42.1053,
    '1080_829': 42.0634,
    '1080_830': 42.0145,
    '1080_831': 41.975,
    '1080_832': 41.9498,
    '1080_833': 41.9333,
    '1080_834': 41.9114,
    '1080_835': 41.8705,
    '1080_836': 41.8457,
    '1080_837': 41.8193,
    '1080_838': 41.7892,
    '1080_839': 41.752,
    '1080_840': 41.7085,
    '1080_841': 41.6577,
    '1080_842': 41.5965,
    '1080_843': 41.5192,
    '1080_844': 41.4329,
    '1080_845': 41.3444,
    '1080_846': 41.2613,
    '1080_847': 41.1791,
    '1080_848': 41.0891,
    '1081_727': 37.205,
    '1081_728': 37.4308,
    '1081_729': 37.6407,
    '1081_730': 37.8047,
    '1081_731': 37.9218,
    '1081_732': 38.0166,
    '1081_733': 38.0984,
    '1081_734': 38.17,
    '1081_735': 38.2314,
    '1081_736': 38.2914,
    '1081_737': 38.3575,
    '1081_738': 38.4275,
    '1081_739': 38.4788,
    '1081_740': 38.4959,
    '1081_741': 38.474,
    '1081_742': 38.3999,
    '1081_743': 38.3319,
    '1081_744': 38.2854,
    '1081_750': 37.9825,
    '1081_751': 37.9833,
    '1081_752': 37.9824,
    '1081_753': 37.9785,
    '1081_754': 37.971,
    '1081_755': 37.9602,
    '1081_756': 37.9478,
    '1081_757': 37.9419,
    '1081_758': 37.9589,
    '1081_759': 37.981,
    '1081_760': 38.0124,
    '1081_761': 38.0574,
    '1081_762': 38.1102,
    '1081_763': 38.1787,
    '1081_764': 38.2545,
    '1081_765': 38.3349,
    '1081_766': 38.4237,
    '1081_767': 38.524,
    '1081_768': 38.633,
    '1081_769': 38.7497,
    '1081_770': 38.8758,
    '1081_771': 39.0123,
    '1081_772': 39.1614,
    '1081_773': 39.3217,
    '1081_774': 39.4946,
    '1081_775': 39.6837,
    '1081_776': 39.8867,
    '1081_777': 40.0918,
    '1081_778': 40.258,
    '1081_779': 40.4191,
    '1081_780': 40.5869,
    '1081_781': 40.75,
    '1081_782': 40.8916,
    '1081_783': 41.0144,
    '1081_784': 41.1317,
    '1081_785': 41.2315,
    '1081_786': 41.3062,
    '1081_787': 41.3443,
    '1081_788': 41.3926,
    '1081_789': 41.441,
    '1081_790': 41.4928,
    '1081_791': 41.5418,
    '1081_792': 41.5957,
    '1081_793': 41.6464,
    '1081_794': 41.6841,
    '1081_795': 41.698,
    '1081_796': 41.7084,
    '1081_797': 41.6933,
    '1081_798': 41.6699,
    '1081_799': 41.6609,
    '1081_800': 41.6729,
    '1081_801': 41.6935,
    '1081_802': 41.7144,
    '1081_803': 41.744,
    '1081_804': 41.7887,
    '1081_805': 41.835,
    '1081_806': 41.8863,
    '1081_807': 41.9494,
    '1081_808': 42.03,
    '1081_809': 42.1272,
    '1081_810': 42.2376,
    '1081_811': 42.3478,
    '1081_812': 42.43,
    '1081_813': 42.4864,
    '1081_814': 42.5259,
    '1081_815': 42.5503,
    '1081_816': 42.558,
    '1081_817': 42.5477,
    '1081_818': 42.5306,
    '1081_819': 42.506,
    '1081_820': 42.476,
    '1081_821': 42.4307,
    '1081_822': 42.3629,
    '1081_823': 42.2825,
    '1081_824': 42.1998,
    '1081_825': 42.1417,
    '1081_826': 42.1192,
    '1081_827': 42.1015,
    '1081_828': 42.0758,
    '1081_829': 42.0333,
    '1081_830': 41.985,
    '1081_831': 41.9501,
    '1081_832': 41.93,
    '1081_833': 41.9152,
    '1081_834': 41.8876,
    '1081_835': 41.8478,
    '1081_836': 41.8239,
    '1081_837': 41.8019,
    '1081_838': 41.7749,
    '1081_839': 41.7413,
    '1081_840': 41.7016,
    '1081_841': 41.6544,
    '1081_842': 41.5957,
    '1081_843': 41.5169,
    '1081_844': 41.4267,
    '1081_845': 41.3336,
    '1081_846': 41.252,
    '1081_847': 41.1737,
    '1081_848': 41.0864,
    '1082_727': 37.1798,
    '1082_728': 37.4023,
    '1082_729': 37.6144,
    '1082_730': 37.8009,
    '1082_731': 37.9487,
    '1082_732': 38.0441,
    '1082_733': 38.1166,
    '1082_734': 38.1747,
    '1082_735': 38.2209,
    '1082_736': 38.2667,
    '1082_737': 38.3163,
    '1082_738': 38.3756,
    '1082_739': 38.4259,
    '1082_740': 38.4548,
    '1082_741': 38.4535,
    '1082_742': 38.4027,
    '1082_743': 38.3341,
    '1082_744': 38.2885,
    '1082_750': 37.966,
    '1082_751': 37.9642,
    '1082_752': 37.9625,
    '1082_753': 37.9564,
    '1082_754': 37.946,
    '1082_755': 37.9316,
    '1082_756': 37.9181,
    '1082_757': 37.9165,
    '1082_758': 37.9282,
    '1082_759': 37.948,
    '1082_760': 37.975,
    '1082_761': 38.0161,
    '1082_762': 38.0763,
    '1082_763': 38.1504,
    '1082_764': 38.2257,
    '1082_765': 38.3052,
    '1082_766': 38.3925,
    '1082_767': 38.4886,
    '1082_768': 38.5934,
    '1082_769': 38.7071,
    '1082_770': 38.8301,
    '1082_771': 38.9618,
    '1082_772': 39.1037,
    '1082_773': 39.2549,
    '1082_774': 39.417,
    '1082_775': 39.592,
    '1082_776': 39.7839,
    '1082_777': 39.9901,
    '1082_778': 40.1718,
    '1082_779': 40.3253,
    '1082_780': 40.4809,
    '1082_781': 40.6343,
    '1082_782': 40.7718,
    '1082_783': 40.8971,
    '1082_784': 41.023,
    '1082_785': 41.1318,
    '1082_786': 41.2222,
    '1082_787': 41.281,
    '1082_788': 41.335,
    '1082_789': 41.3936,
    '1082_790': 41.4451,
    '1082_791': 41.497,
    '1082_792': 41.5618,
    '1082_793': 41.6211,
    '1082_794': 41.667,
    '1082_795': 41.6918,
    '1082_796': 41.7056,
    '1082_797': 41.6946,
    '1082_798': 41.6723,
    '1082_799': 41.6608,
    '1082_800': 41.6656,
    '1082_801': 41.6848,
    '1082_802': 41.7094,
    '1082_803': 41.7404,
    '1082_804': 41.7774,
    '1082_805': 41.8177,
    '1082_806': 41.8618,
    '1082_807': 41.9248,
    '1082_808': 42.0065,
    '1082_809': 42.1042,
    '1082_810': 42.2135,
    '1082_811': 42.3214,
    '1082_812': 42.4117,
    '1082_813': 42.4857,
    '1082_814': 42.5437,
    '1082_815': 42.5836,
    '1082_816': 42.6034,
    '1082_817': 42.6081,
    '1082_818': 42.5936,
    '1082_819': 42.5589,
    '1082_820': 42.513,
    '1082_821': 42.4552,
    '1082_822': 42.3811,
    '1082_823': 42.2899,
    '1082_824': 42.1997,
    '1082_825': 42.1326,
    '1082_826': 42.0986,
    '1082_827': 42.0743,
    '1082_828': 42.0463,
    '1082_829': 42.0059,
    '1082_830': 41.9648,
    '1082_831': 41.936,
    '1082_832': 41.9174,
    '1082_833': 41.9032,
    '1082_834': 41.8705,
    '1082_835': 41.8292,
    '1082_836': 41.8037,
    '1082_837': 41.7836,
    '1082_838': 41.7606,
    '1082_839': 41.7306,
    '1082_840': 41.694,
    '1082_841': 41.6494,
    '1082_842': 41.5935,
    '1082_843': 41.5196,
    '1082_844': 41.4346,
    '1082_845': 41.3522,
    '1082_846': 41.2713,
    '1082_847': 41.1878,
    '1082_848': 41.0994,
    '1083_727': 37.1508,
    '1083_728': 37.371,
    '1083_729': 37.5873,
    '1083_730': 37.7897,
    '1083_731': 37.9603,
    '1083_732': 38.0776,
    '1083_733': 38.1486,
    '1083_734': 38.1931,
    '1083_735': 38.2201,
    '1083_736': 38.2461,
    '1083_737': 38.2798,
    '1083_738': 38.3258,
    '1083_739': 38.371,
    '1083_740': 38.3992,
    '1083_741': 38.4088,
    '1083_742': 38.3826,
    '1083_743': 38.3205,
    '1083_744': 38.2753,
    '1083_750': 37.9518,
    '1083_751': 37.9513,
    '1083_752': 37.9468,
    '1083_753': 37.9358,
    '1083_754': 37.9196,
    '1083_755': 37.8961,
    '1083_756': 37.8856,
    '1083_757': 37.8876,
    '1083_758': 37.8984,
    '1083_759': 37.9173,
    '1083_760': 37.9407,
    '1083_761': 37.9811,
    '1083_762': 38.0489,
    '1083_763': 38.1236,
    '1083_764': 38.2003,
    '1083_765': 38.2775,
    '1083_766': 38.3632,
    '1083_767': 38.4559,
    '1083_768': 38.5575,
    '1083_769': 38.6684,
    '1083_770': 38.7882,
    '1083_771': 38.9163,
    '1083_772': 39.052,
    '1083_773': 39.1963,
    '1083_774': 39.35,
    '1083_775': 39.5142,
    '1083_776': 39.6923,
    '1083_777': 39.8885,
    '1083_778': 40.0823,
    '1083_779': 40.2424,
    '1083_780': 40.3802,
    '1083_781': 40.5193,
    '1083_782': 40.656,
    '1083_783': 40.7876,
    '1083_784': 40.92,
    '1083_785': 41.0404,
    '1083_786': 41.1501,
    '1083_787': 41.2224,
    '1083_788': 41.2893,
    '1083_789': 41.3531,
    '1083_790': 41.4028,
    '1083_791': 41.4662,
    '1083_792': 41.5405,
    '1083_793': 41.6091,
    '1083_794': 41.6657,
    '1083_795': 41.7022,
    '1083_796': 41.7191,
    '1083_797': 41.7077,
    '1083_798': 41.6913,
    '1083_799': 41.6741,
    '1083_800': 41.6695,
    '1083_801': 41.6852,
    '1083_802': 41.7131,
    '1083_803': 41.7474,
    '1083_804': 41.7801,
    '1083_805': 41.8167,
    '1083_806': 41.8555,
    '1083_807': 41.9158,
    '1083_808': 41.9991,
    '1083_809': 42.0899,
    '1083_810': 42.1933,
    '1083_811': 42.2927,
    '1083_812': 42.3828,
    '1083_813': 42.4662,
    '1083_814': 42.5382,
    '1083_815': 42.596,
    '1083_816': 42.6399,
    '1083_817': 42.6609,
    '1083_818': 42.6586,
    '1083_819': 42.6264,
    '1083_820': 42.5675,
    '1083_821': 42.4916,
    '1083_822': 42.4034,
    '1083_823': 42.3039,
    '1083_824': 42.2135,
    '1083_825': 42.1375,
    '1083_826': 42.0871,
    '1083_827': 42.0583,
    '1083_828': 42.0257,
    '1083_829': 41.9871,
    '1083_830': 41.9573,
    '1083_831': 41.9309,
    '1083_832': 41.9076,
    '1083_833': 41.8924,
    '1083_834': 41.8595,
    '1083_835': 41.8163,
    '1083_836': 41.7887,
    '1083_837': 41.7692,
    '1083_838': 41.7486,
    '1083_839': 41.7216,
    '1083_840': 41.6873,
    '1083_841': 41.6439,
    '1083_842': 41.5893,
    '1083_843': 41.5253,
    '1083_844': 41.4444,
    '1083_845': 41.3753,
    '1083_846': 41.2969,
    '1083_847': 41.2096,
    '1083_848': 41.1222,
    '1084_727': 37.1157,
    '1084_728': 37.3316,
    '1084_729': 37.5475,
    '1084_730': 37.7607,
    '1084_731': 37.9539,
    '1084_732': 38.0998,
    '1084_733': 38.1841,
    '1084_734': 38.2252,
    '1084_735': 38.2368,
    '1084_736': 38.2356,
    '1084_737': 38.2493,
    '1084_738': 38.2791,
    '1084_739': 38.3121,
    '1084_740': 38.3287,
    '1084_741': 38.3392,
    '1084_742': 38.3317,
    '1084_743': 38.2875,
    '1084_744': 38.2448,
    '1084_750': 37.9378,
    '1084_751': 37.9407,
    '1084_752': 37.9338,
    '1084_753': 37.9151,
    '1084_754': 37.8905,
    '1084_755': 37.8665,
    '1084_756': 37.8565,
    '1084_757': 37.8605,
    '1084_758': 37.871,
    '1084_759': 37.886,
    '1084_760': 37.9087,
    '1084_761': 37.9494,
    '1084_762': 38.0255,
    '1084_763': 38.1,
    '1084_764': 38.176,
    '1084_765': 38.25,
    '1084_766': 38.3336,
    '1084_767': 38.4244,
    '1084_768': 38.5245,
    '1084_769': 38.634,
    '1084_770': 38.7517,
    '1084_771': 38.8771,
    '1084_772': 39.0086,
    '1084_773': 39.1471,
    '1084_774': 39.2939,
    '1084_775': 39.4502,
    '1084_776': 39.6183,
    '1084_777': 39.8001,
    '1084_778': 39.9932,
    '1084_779': 40.1654,
    '1084_780': 40.2999,
    '1084_781': 40.4242,
    '1084_782': 40.5623,
    '1084_783': 40.7053,
    '1084_784': 40.847,
    '1084_785': 40.9775,
    '1084_786': 41.0942,
    '1084_787': 41.1772,
    '1084_788': 41.2594,
    '1084_789': 41.3184,
    '1084_790': 41.3689,
    '1084_791': 41.4478,
    '1084_792': 41.5338,
    '1084_793': 41.613,
    '1084_794': 41.6837,
    '1084_795': 41.7309,
    '1084_796': 41.7496,
    '1084_797': 41.7392,
    '1084_798': 41.7233,
    '1084_799': 41.6959,
    '1084_800': 41.6818,
    '1084_801': 41.693,
    '1084_802': 41.7216,
    '1084_803': 41.7587,
    '1084_804': 41.7969,
    '1084_805': 41.8271,
    '1084_806': 41.8639,
    '1084_807': 41.9241,
    '1084_808': 41.9997,
    '1084_809': 42.0838,
    '1084_810': 42.1724,
    '1084_811': 42.2547,
    '1084_812': 42.3375,
    '1084_813': 42.4263,
    '1084_814': 42.5113,
    '1084_815': 42.5903,
    '1084_816': 42.6545,
    '1084_817': 42.6994,
    '1084_818': 42.7151,
    '1084_819': 42.6786,
    '1084_820': 42.6183,
    '1084_821': 42.527,
    '1084_822': 42.4217,
    '1084_823': 42.3215,
    '1084_824': 42.2354,
    '1084_825': 42.1478,
    '1084_826': 42.0822,
    '1084_827': 42.0481,
    '1084_828': 42.0147,
    '1084_829': 41.9803,
    '1084_830': 41.9595,
    '1084_831': 41.9298,
    '1084_832': 41.902,
    '1084_833': 41.8847,
    '1084_834': 41.8541,
    '1084_835': 41.8106,
    '1084_836': 41.7815,
    '1084_837': 41.762,
    '1084_838': 41.743,
    '1084_839': 41.7181,
    '1084_840': 41.6862,
    '1084_841': 41.6461,
    '1084_842': 41.5968,
    '1084_843': 41.5367,
    '1084_844': 41.4594,
    '1084_845': 41.3871,
    '1084_846': 41.3105,
    '1084_847': 41.2305,
    '1084_848': 41.1461,
    '1085_727': 37.0647,
    '1085_728': 37.2801,
    '1085_729': 37.4954,
    '1085_730': 37.711,
    '1085_731': 37.919,
    '1085_732': 38.0927,
    '1085_733': 38.1968,
    '1085_734': 38.2531,
    '1085_735': 38.2598,
    '1085_736': 38.232,
    '1085_737': 38.2217,
    '1085_738': 38.2295,
    '1085_739': 38.2434,
    '1085_740': 38.2503,
    '1085_741': 38.253,
    '1085_742': 38.2482,
    '1085_743': 38.223,
    '1085_744': 38.1916,
    '1085_750': 37.9176,
    '1085_751': 37.926,
    '1085_752': 37.9179,
    '1085_753': 37.8943,
    '1085_754': 37.8645,
    '1085_755': 37.8412,
    '1085_756': 37.8361,
    '1085_757': 37.8397,
    '1085_758': 37.848,
    '1085_759': 37.8625,
    '1085_760': 37.8858,
    '1085_761': 37.9302,
    '1085_762': 38.0062,
    '1085_763': 38.0784,
    '1085_764': 38.1548,
    '1085_765': 38.2281,
    '1085_766': 38.3073,
    '1085_767': 38.3941,
    '1085_768': 38.4932,
    '1085_769': 38.6021,
    '1085_770': 38.7191,
    '1085_771': 38.8425,
    '1085_772': 38.972,
    '1085_773': 39.1063,
    '1085_774': 39.2467,
    '1085_775': 39.3948,
    '1085_776': 39.5539,
    '1085_777': 39.7233,
    '1085_778': 39.902,
    '1085_779': 40.0804,
    '1085_780': 40.2274,
    '1085_781': 40.3489,
    '1085_782': 40.4915,
    '1085_783': 40.647,
    '1085_784': 40.8052,
    '1085_785': 40.9487,
    '1085_786': 41.0607,
    '1085_787': 41.1565,
    '1085_788': 41.2486,
    '1085_789': 41.2971,
    '1085_790': 41.3497,
    '1085_791': 41.4357,
    '1085_792': 41.528,
    '1085_793': 41.6177,
    '1085_794': 41.7026,
    '1085_795': 41.7603,
    '1085_796': 41.7843,
    '1085_797': 41.7754,
    '1085_798': 41.7538,
    '1085_799': 41.7229,
    '1085_800': 41.6982,
    '1085_801': 41.7036,
    '1085_802': 41.7319,
    '1085_803': 41.7729,
    '1085_804': 41.822,
    '1085_805': 41.8584,
    '1085_806': 41.885,
    '1085_807': 41.9354,
    '1085_808': 41.9943,
    '1085_809': 42.0675,
    '1085_810': 42.1392,
    '1085_811': 42.2049,
    '1085_812': 42.278,
    '1085_813': 42.3641,
    '1085_814': 42.4615,
    '1085_815': 42.5621,
    '1085_816': 42.6557,
    '1085_817': 42.7219,
    '1085_818': 42.7479,
    '1085_819': 42.7086,
    '1085_820': 42.655,
    '1085_821': 42.5512,
    '1085_822': 42.4325,
    '1085_823': 42.3297,
    '1085_824': 42.2465,
    '1085_825': 42.1582,
    '1085_826': 42.0828,
    '1085_827': 42.0424,
    '1085_828': 42.0095,
    '1085_829': 41.9803,
    '1085_830': 41.9617,
    '1085_831': 41.9321,
    '1085_832': 41.9029,
    '1085_833': 41.8839,
    '1085_834': 41.8548,
    '1085_835': 41.8128,
    '1085_836': 41.7821,
    '1085_837': 41.762,
    '1085_838': 41.7433,
    '1085_839': 41.7194,
    '1085_840': 41.6891,
    '1085_841': 41.6515,
    '1085_842': 41.6059,
    '1085_843': 41.5496,
    '1085_844': 41.4757,
    '1085_845': 41.403,
    '1085_846': 41.3307,
    '1085_847': 41.2565,
    '1085_848': 41.177,
    '1086_727': 37.0075,
    '1086_728': 37.2252,
    '1086_729': 37.4347,
    '1086_730': 37.6487,
    '1086_731': 37.861,
    '1086_732': 38.0494,
    '1086_733': 38.1836,
    '1086_734': 38.2548,
    '1086_735': 38.2729,
    '1086_736': 38.2345,
    '1086_737': 38.1996,
    '1086_738': 38.1849,
    '1086_739': 38.182,
    '1086_740': 38.1755,
    '1086_741': 38.1656,
    '1086_742': 38.1537,
    '1086_743': 38.1379,
    '1086_744': 38.1196,
    '1086_750': 37.8869,
    '1086_751': 37.8954,
    '1086_752': 37.89,
    '1086_753': 37.8726,
    '1086_754': 37.8462,
    '1086_755': 37.8326,
    '1086_756': 37.8262,
    '1086_757': 37.8256,
    '1086_758': 37.8279,
    '1086_759': 37.8394,
    '1086_760': 37.8667,
    '1086_761': 37.9201,
    '1086_762': 37.9865,
    '1086_763': 38.0597,
    '1086_764': 38.136,
    '1086_765': 38.2081,
    '1086_766': 38.2842,
    '1086_767': 38.3665,
    '1086_768': 38.4654,
    '1086_769': 38.5745,
    '1086_770': 38.6914,
    '1086_771': 38.8143,
    '1086_772': 38.942,
    '1086_773': 39.0739,
    '1086_774': 39.2088,
    '1086_775': 39.3496,
    '1086_776': 39.4994,
    '1086_777': 39.6579,
    '1086_778': 39.824,
    '1086_779': 39.9948,
    '1086_780': 40.1519,
    '1086_781': 40.2894,
    '1086_782': 40.4419,
    '1086_783': 40.6111,
    '1086_784': 40.7824,
    '1086_785': 40.9316,
    '1086_786': 41.0512,
    '1086_787': 41.1505,
    '1086_788': 41.2417,
    '1086_789': 41.2904,
    '1086_790': 41.3423,
    '1086_791': 41.4311,
    '1086_792': 41.5271,
    '1086_793': 41.6267,
    '1086_794': 41.7221,
    '1086_795': 41.7882,
    '1086_796': 41.8162,
    '1086_797': 41.8082,
    '1086_798': 41.7883,
    '1086_799': 41.7579,
    '1086_800': 41.7206,
    '1086_801': 41.7169,
    '1086_802': 41.7436,
    '1086_803': 41.7871,
    '1086_804': 41.8418,
    '1086_805': 41.8872,
    '1086_806': 41.9172,
    '1086_807': 41.9517,
    '1086_808': 41.9893,
    '1086_809': 42.0395,
    '1086_810': 42.0931,
    '1086_811': 42.1416,
    '1086_812': 42.2092,
    '1086_813': 42.3008,
    '1086_814': 42.4117,
    '1086_815': 42.5309,
    '1086_816': 42.6452,
    '1086_817': 42.7299,
    '1086_818': 42.759,
    '1086_819': 42.7297,
    '1086_820': 42.6734,
    '1086_821': 42.5595,
    '1086_822': 42.4289,
    '1086_823': 42.3203,
    '1086_824': 42.2385,
    '1086_825': 42.1571,
    '1086_826': 42.083,
    '1086_827': 42.0392,
    '1086_828': 42.0079,
    '1086_829': 41.9833,
    '1086_830': 41.9681,
    '1086_831': 41.9385,
    '1086_832': 41.9117,
    '1086_833': 41.8937,
    '1086_834': 41.8628,
    '1086_835': 41.823,
    '1086_836': 41.791,
    '1086_837': 41.7704,
    '1086_838': 41.7494,
    '1086_839': 41.7255,
    '1086_840': 41.695,
    '1086_841': 41.6579,
    '1086_842': 41.6132,
    '1086_843': 41.5581,
    '1086_844': 41.4861,
    '1086_845': 41.4173,
    '1086_846': 41.3501,
    '1086_847': 41.2827,
    '1086_848': 41.2079,
    '1086_849': 41.1333,
    '1086_850': 41.0588,
    '1086_851': 40.9826,
    '1086_852': 40.9024,
    '1086_853': 40.816,
    '1086_854': 40.7243,
    '1086_855': 40.6211,
    '1086_856': 40.5134,
    '1086_857': 40.4054,
    '1086_858': 40.2985,
    '1086_859': 40.1918,
    '1086_860': 40.0798,
    '1086_861': 39.9572,
    '1086_862': 39.8255,
    '1086_863': 39.6848,
    '1086_864': 39.5344,
    '1086_865': 39.3749,
    '1086_866': 39.2028,
    '1086_867': 39.018,
    '1086_868': 38.8199,
    '1086_869': 38.609,
    '1086_870': 38.3969,
    '1086_871': 38.1897,
    '1086_872': 37.9809,
    '1086_873': 37.7689,
    '1086_874': 37.5565,
    '1086_875': 37.3402,
    '1086_876': 37.1201,
    '1086_877': 36.8967,
    '1086_878': 36.6702,
    '1086_879': 36.439,
    '1086_880': 36.2013,
    '1086_881': 35.9527,
    '1086_882': 35.6953,
    '1086_883': 35.431,
    '1086_884': 35.1586,
    '1086_885': 34.8734,
    '1086_886': 34.5826,
    '1086_887': 34.2872,
    '1086_888': 33.9871,
    '1086_889': 33.6821,
    '1086_890': 33.372,
    '1086_891': 33.0582,
    '1086_892': 32.7421,
    '1086_893': 32.4268,
    '1086_894': 32.1128,
    '1086_895': 31.8068,
    '1086_896': 31.5086,
    '1086_897': 31.2152,
    '1086_898': 30.9322,
    '1086_899': 30.6489,
    '1086_900': 30.3695,
    '1087_727': 36.9392,
    '1087_728': 37.1563,
    '1087_729': 37.3669,
    '1087_730': 37.5795,
    '1087_731': 37.7914,
    '1087_732': 37.9869,
    '1087_733': 38.1371,
    '1087_734': 38.2355,
    '1087_735': 38.2694,
    '1087_736': 38.2405,
    '1087_737': 38.1881,
    '1087_738': 38.1493,
    '1087_739': 38.1279,
    '1087_740': 38.1058,
    '1087_741': 38.085,
    '1087_742': 38.065,
    '1087_743': 38.0522,
    '1087_744': 38.0415,
    '1087_768': 38.4437,
    '1087_769': 38.5514,
    '1087_770': 38.6678,
    '1087_771': 38.7906,
    '1087_772': 38.9183,
    '1087_773': 39.0484,
    '1087_774': 39.1784,
    '1087_775': 39.3115,
    '1087_776': 39.454,
    '1087_777': 39.6045,
    '1087_778': 39.7641,
    '1087_779': 39.9298,
    '1087_780': 40.0968,
    '1087_781': 40.2467,
    '1087_782': 40.4084,
    '1087_783': 40.5851,
    '1087_784': 40.7619,
    '1087_785': 40.913,
    '1087_786': 41.0375,
    '1087_787': 41.1451,
    '1087_788': 41.2364,
    '1087_789': 41.29,
    '1087_790': 41.3479,
    '1087_791': 41.4371,
    '1087_792': 41.5328,
    '1087_793': 41.6399,
    '1087_794': 41.7404,
    '1087_795': 41.8131,
    '1087_796': 41.8432,
    '1087_797': 41.8377,
    '1087_798': 41.8231,
    '1087_799': 41.7948,
    '1087_800': 41.7466,
    '1087_801': 41.7321,
    '1087_802': 41.752,
    '1087_803': 41.7979,
    '1087_804': 41.8545,
    '1087_805': 41.9049,
    '1087_806': 41.9341,
    '1087_807': 41.9553,
    '1087_808': 41.979,
    '1087_809': 42.0071,
    '1087_810': 42.0423,
    '1087_811': 42.0768,
    '1087_812': 42.1474,
    '1087_813': 42.249,
    '1087_814': 42.3722,
    '1087_815': 42.5062,
    '1087_816': 42.629,
    '1087_817': 42.716,
    '1087_818': 42.7548,
    '1087_819': 42.7413,
    '1087_820': 42.6654,
    '1087_821': 42.5488,
    '1087_822': 42.4136,
    '1087_823': 42.3054,
    '1087_824': 42.2228,
    '1087_825': 42.153,
    '1087_826': 42.0857,
    '1087_827': 42.0389,
    '1087_828': 42.009,
    '1087_829': 41.9887,
    '1087_830': 41.975,
    '1087_831': 41.953,
    '1087_832': 41.9295,
    '1087_833': 41.9076,
    '1087_834': 41.8743,
    '1087_835': 41.8391,
    '1087_836': 41.805,
    '1087_837': 41.7827,
    '1087_838': 41.7604,
    '1087_839': 41.7344,
    '1087_840': 41.7019,
    '1087_841': 41.6634,
    '1087_842': 41.6179,
    '1087_843': 41.5633,
    '1087_844': 41.4958,
    '1087_845': 41.4276,
    '1087_846': 41.3616,
    '1087_847': 41.2965,
    '1087_848': 41.2302,
    '1087_849': 41.1654,
    '1087_850': 41.1014,
    '1087_851': 41.0346,
    '1087_852': 40.9598,
    '1087_853': 40.8713,
    '1087_854': 40.7768,
    '1087_855': 40.6765,
    '1087_856': 40.5747,
    '1087_857': 40.4739,
    '1087_858': 40.3762,
    '1087_859': 40.2834,
    '1087_860': 40.1838,
    '1087_861': 40.0662,
    '1087_862': 39.9348,
    '1087_863': 39.7919,
    '1087_864': 39.6399,
    '1087_865': 39.478,
    '1087_866': 39.3038,
    '1087_867': 39.1177,
    '1087_868': 38.9201,
    '1087_869': 38.7111,
    '1087_870': 38.4967,
    '1087_871': 38.2835,
    '1087_872': 38.0686,
    '1087_873': 37.8512,
    '1087_874': 37.6335,
    '1087_875': 37.4106,
    '1087_876': 37.1853,
    '1087_877': 36.9564,
    '1087_878': 36.7247,
    '1087_879': 36.4889,
    '1087_880': 36.2465,
    '1087_881': 35.9962,
    '1087_882': 35.7359,
    '1087_883': 35.4686,
    '1087_884': 35.1929,
    '1087_885': 34.9104,
    '1087_886': 34.6209,
    '1087_887': 34.326,
    '1087_888': 34.0271,
    '1087_889': 33.7239,
    '1087_890': 33.4162,
    '1087_891': 33.1053,
    '1087_892': 32.7911,
    '1087_893': 32.477,
    '1087_894': 32.1657,
    '1087_895': 31.8599,
    '1087_896': 31.5599,
    '1087_897': 31.2655,
    '1087_898': 30.9786,
    '1087_899': 30.6962,
    '1087_900': 30.4178,
    '1088_727': 36.8638,
    '1088_728': 37.0829,
    '1088_729': 37.3009,
    '1088_730': 37.5081,
    '1088_731': 37.7135,
    '1088_732': 37.9109,
    '1088_733': 38.0725,
    '1088_734': 38.189,
    '1088_735': 38.2485,
    '1088_736': 38.2468,
    '1088_737': 38.1893,
    '1088_738': 38.1283,
    '1088_739': 38.0877,
    '1088_740': 38.0488,
    '1088_741': 38.0184,
    '1088_742': 37.9935,
    '1088_743': 37.9764,
    '1088_744': 37.9671,
    '1088_768': 38.4239,
    '1088_769': 38.5305,
    '1088_770': 38.647,
    '1088_771': 38.7705,
    '1088_772': 38.8987,
    '1088_773': 39.0291,
    '1088_774': 39.1552,
    '1088_775': 39.2826,
    '1088_776': 39.4172,
    '1088_777': 39.5622,
    '1088_778': 39.7189,
    '1088_779': 39.8866,
    '1088_780': 40.0598,
    '1088_781': 40.2161,
    '1088_782': 40.378,
    '1088_783': 40.5544,
    '1088_784': 40.7309,
    '1088_785': 40.8896,
    '1088_786': 41.0284,
    '1088_787': 41.1417,
    '1088_788': 41.2313,
    '1088_789': 41.291,
    '1088_790': 41.3549,
    '1088_791': 41.4445,
    '1088_792': 41.5417,
    '1088_793': 41.6513,
    '1088_794': 41.7536,
    '1088_795': 41.8274,
    '1088_796': 41.8626,
    '1088_797': 41.8634,
    '1088_798': 41.8613,
    '1088_799': 41.8395,
    '1088_800': 41.7856,
    '1088_801': 41.7501,
    '1088_802': 41.7563,
    '1088_803': 41.7962,
    '1088_804': 41.8507,
    '1088_805': 41.9013,
    '1088_806': 41.9306,
    '1088_807': 41.9498,
    '1088_808': 41.9698,
    '1088_809': 41.9855,
    '1088_810': 42.0046,
    '1088_811': 42.0282,
    '1088_812': 42.1025,
    '1088_813': 42.2064,
    '1088_814': 42.3331,
    '1088_815': 42.4766,
    '1088_816': 42.6076,
    '1088_817': 42.691,
    '1088_818': 42.7254,
    '1088_819': 42.7248,
    '1088_820': 42.6499,
    '1088_821': 42.5281,
    '1088_822': 42.395,
    '1088_823': 42.2916,
    '1088_824': 42.2101,
    '1088_825': 42.1458,
    '1088_826': 42.0857,
    '1088_827': 42.0406,
    '1088_828': 42.012,
    '1088_829': 41.9953,
    '1088_830': 41.9815,
    '1088_831': 41.964,
    '1088_832': 41.9447,
    '1088_833': 41.921,
    '1088_834': 41.8852,
    '1088_835': 41.8521,
    '1088_836': 41.8185,
    '1088_837': 41.7961,
    '1088_838': 41.773,
    '1088_839': 41.744,
    '1088_840': 41.7087,
    '1088_841': 41.6676,
    '1088_842': 41.6201,
    '1088_843': 41.565,
    '1088_844': 41.4995,
    '1088_845': 41.432,
    '1088_846': 41.3672,
    '1088_847': 41.3048,
    '1088_848': 41.246,
    '1088_849': 41.1914,
    '1088_850': 41.1387,
    '1088_851': 41.0825,
    '1088_852': 41.0153,
    '1088_853': 40.9215,
    '1088_854': 40.8256,
    '1088_855': 40.728,
    '1088_856': 40.6319,
    '1088_857': 40.5391,
    '1088_858': 40.4495,
    '1088_859': 40.371,
    '1088_860': 40.2836,
    '1088_861': 40.1667,
    '1088_862': 40.0343,
    '1088_863': 39.8895,
    '1088_864': 39.738,
    '1088_865': 39.5726,
    '1088_866': 39.3945,
    '1088_867': 39.2096,
    '1088_868': 39.013,
    '1088_869': 38.8095,
    '1088_870': 38.5936,
    '1088_871': 38.3771,
    '1088_872': 38.1577,
    '1088_873': 37.933,
    '1088_874': 37.71,
    '1088_875': 37.4801,
    '1088_876': 37.2514,
    '1088_877': 37.0167,
    '1088_878': 36.7796,
    '1088_879': 36.5398,
    '1088_880': 36.2915,
    '1088_881': 36.0371,
    '1088_882': 35.7756,
    '1088_883': 35.5051,
    '1088_884': 35.2284,
    '1088_885': 34.9497,
    '1088_886': 34.6608,
    '1088_887': 34.3646,
    '1088_888': 34.0638,
    '1088_889': 33.7629,
    '1088_890': 33.4586,
    '1088_891': 33.1506,
    '1088_892': 32.8391,
    '1088_893': 32.5271,
    '1088_894': 32.2198,
    '1088_895': 31.9164,
    '1088_896': 31.6164,
    '1088_897': 31.321,
    '1088_898': 31.0316,
    '1088_899': 30.75,
    '1088_900': 30.4712,
    '1089_727': 36.7998,
    '1089_728': 37.0159,
    '1089_729': 37.235,
    '1089_730': 37.4401,
    '1089_731': 37.6352,
    '1089_732': 37.8209,
    '1089_733': 37.9923,
    '1089_734': 38.1324,
    '1089_735': 38.2176,
    '1089_736': 38.243,
    '1089_737': 38.201,
    '1089_738': 38.1312,
    '1089_739': 38.0678,
    '1089_740': 38.0155,
    '1089_741': 37.9693,
    '1089_742': 37.9342,
    '1089_743': 37.9111,
    '1089_744': 37.9018,
    '1089_768': 38.4058,
    '1089_769': 38.5113,
    '1089_770': 38.6284,
    '1089_771': 38.7521,
    '1089_772': 38.8795,
    '1089_773': 39.0082,
    '1089_774': 39.1339,
    '1089_775': 39.2589,
    '1089_776': 39.3883,
    '1089_777': 39.5283,
    '1089_778': 39.6804,
    '1089_779': 39.8478,
    '1089_780': 40.0237,
    '1089_781': 40.1913,
    '1089_782': 40.3585,
    '1089_783': 40.5314,
    '1089_784': 40.7022,
    '1089_785': 40.8683,
    '1089_786': 41.0146,
    '1089_787': 41.1343,
    '1089_788': 41.2252,
    '1089_789': 41.2895,
    '1089_790': 41.3542,
    '1089_791': 41.4454,
    '1089_792': 41.5501,
    '1089_793': 41.6623,
    '1089_794': 41.763,
    '1089_795': 41.8372,
    '1089_796': 41.8757,
    '1089_797': 41.8906,
    '1089_798': 41.9029,
    '1089_799': 41.8916,
    '1089_800': 41.8412,
    '1089_801': 41.7801,
    '1089_802': 41.7611,
    '1089_803': 41.7832,
    '1089_804': 41.8314,
    '1089_805': 41.8833,
    '1089_806': 41.9157,
    '1089_807': 41.9359,
    '1089_808': 41.9549,
    '1089_809': 41.9637,
    '1089_810': 41.9708,
    '1089_811': 41.9872,
    '1089_812': 42.0512,
    '1089_813': 42.1532,
    '1089_814': 42.2858,
    '1089_815': 42.4347,
    '1089_816': 42.5684,
    '1089_817': 42.6508,
    '1089_818': 42.6885,
    '1089_819': 42.6885,
    '1089_820': 42.6209,
    '1089_821': 42.5091,
    '1089_822': 42.3819,
    '1089_823': 42.2765,
    '1089_824': 42.1949,
    '1089_825': 42.131,
    '1089_826': 42.0774,
    '1089_827': 42.0375,
    '1089_828': 42.0149,
    '1089_829': 42.0013,
    '1089_830': 41.984,
    '1089_831': 41.9655,
    '1089_832': 41.9508,
    '1089_833': 41.9266,
    '1089_834': 41.8901,
    '1089_835': 41.8574,
    '1089_836': 41.8291,
    '1089_837': 41.8076,
    '1089_838': 41.783,
    '1089_839': 41.7525,
    '1089_840': 41.715,
    '1089_841': 41.6713,
    '1089_842': 41.6211,
    '1089_843': 41.5641,
    '1089_844': 41.4996,
    '1089_845': 41.4334,
    '1089_846': 41.3716,
    '1089_847': 41.312,
    '1089_848': 41.2594,
    '1089_849': 41.2135,
    '1089_850': 41.171,
    '1089_851': 41.1215,
    '1089_852': 41.0564,
    '1089_853': 40.9695,
    '1089_854': 40.8736,
    '1089_855': 40.7782,
    '1089_856': 40.6876,
    '1089_857': 40.6074,
    '1089_858': 40.5292,
    '1089_859': 40.4499,
    '1089_860': 40.3563,
    '1089_861': 40.2397,
    '1089_862': 40.1066,
    '1089_863': 39.9632,
    '1089_864': 39.8118,
    '1089_865': 39.649,
    '1089_866': 39.4749,
    '1089_867': 39.2911,
    '1089_868': 39.0969,
    '1089_869': 38.8984,
    '1089_870': 38.6847,
    '1089_871': 38.4669,
    '1089_872': 38.2491,
    '1089_873': 38.0197,
    '1089_874': 37.7904,
    '1089_875': 37.5525,
    '1089_876': 37.32,
    '1089_877': 37.0817,
    '1089_878': 36.84,
    '1089_879': 36.597,
    '1089_880': 36.3461,
    '1089_881': 36.0913,
    '1089_882': 35.8256,
    '1089_883': 35.555,
    '1089_884': 35.2798,
    '1089_885': 34.9981,
    '1089_886': 34.707,
    '1089_887': 34.4081,
    '1089_888': 34.1041,
    '1089_889': 33.806,
    '1089_890': 33.5044,
    '1089_891': 33.1994,
    '1089_892': 32.8908,
    '1089_893': 32.58,
    '1089_894': 32.2773,
    '1089_895': 31.9764,
    '1089_896': 31.6766,
    '1089_897': 31.3809,
    '1089_898': 31.0894,
    '1089_899': 30.8092,
    '1089_900': 30.5292,
    '1090_727': 36.744,
    '1090_728': 36.9594,
    '1090_729': 37.1761,
    '1090_730': 37.3821,
    '1090_731': 37.5632,
    '1090_732': 37.7368,
    '1090_733': 37.9059,
    '1090_734': 38.0611,
    '1090_735': 38.1718,
    '1090_736': 38.2213,
    '1090_737': 38.2088,
    '1090_738': 38.1459,
    '1090_739': 38.0652,
    '1090_740': 37.9949,
    '1090_741': 37.9308,
    '1090_742': 37.8852,
    '1090_743': 37.8576,
    '1090_744': 37.8519,
    '1090_768': 38.3901,
    '1090_769': 38.494,
    '1090_770': 38.6122,
    '1090_771': 38.7361,
    '1090_772': 38.862,
    '1090_773': 38.9895,
    '1090_774': 39.1157,
    '1090_775': 39.2392,
    '1090_776': 39.3668,
    '1090_777': 39.5031,
    '1090_778': 39.6517,
    '1090_779': 39.8143,
    '1090_780': 39.9887,
    '1090_781': 40.1626,
    '1090_782': 40.3387,
    '1090_783': 40.5154,
    '1090_784': 40.6752,
    '1090_785': 40.8352,
    '1090_786': 40.988,
    '1090_787': 41.1159,
    '1090_788': 41.2118,
    '1090_789': 41.2819,
    '1090_790': 41.3554,
    '1090_791': 41.4506,
    '1090_792': 41.5626,
    '1090_793': 41.6776,
    '1090_794': 41.7744,
    '1090_795': 41.847,
    '1090_796': 41.8894,
    '1090_797': 41.9192,
    '1090_798': 41.9452,
    '1090_799': 41.9399,
    '1090_800': 41.8952,
    '1090_801': 41.8211,
    '1090_802': 41.7732,
    '1090_803': 41.7708,
    '1090_804': 41.8038,
    '1090_805': 41.8553,
    '1090_806': 41.8915,
    '1090_807': 41.914,
    '1090_808': 41.9289,
    '1090_809': 41.9316,
    '1090_810': 41.9307,
    '1090_811': 41.9436,
    '1090_812': 41.994,
    '1090_813': 42.0932,
    '1090_814': 42.2302,
    '1090_815': 42.3809,
    '1090_816': 42.5154,
    '1090_817': 42.601,
    '1090_818': 42.6478,
    '1090_819': 42.6446,
    '1090_820': 42.5904,
    '1090_821': 42.4949,
    '1090_822': 42.3794,
    '1090_823': 42.2659,
    '1090_824': 42.1783,
    '1090_825': 42.1085,
    '1090_826': 42.0555,
    '1090_827': 42.0243,
    '1090_828': 42.0111,
    '1090_829': 41.9982,
    '1090_830': 41.9805,
    '1090_831': 41.9578,
    '1090_832': 41.9453,
    '1090_833': 41.924,
    '1090_834': 41.8897,
    '1090_835': 41.8583,
    '1090_836': 41.8341,
    '1090_837': 41.814,
    '1090_838': 41.7911,
    '1090_839': 41.7597,
    '1090_840': 41.7214,
    '1090_841': 41.6762,
    '1090_842': 41.6246,
    '1090_843': 41.5668,
    '1090_844': 41.5034,
    '1090_845': 41.4382,
    '1090_846': 41.3827,
    '1090_847': 41.3289,
    '1090_848': 41.2784,
    '1090_849': 41.2382,
    '1090_850': 41.2049,
    '1090_851': 41.1622,
    '1090_852': 41.1002,
    '1090_853': 41.0175,
    '1090_854': 40.9222,
    '1090_855': 40.8306,
    '1090_856': 40.743,
    '1090_857': 40.675,
    '1090_858': 40.6034,
    '1090_859': 40.5178,
    '1090_860': 40.4169,
    '1090_861': 40.298,
    '1090_862': 40.166,
    '1090_863': 40.0255,
    '1090_864': 39.8759,
    '1090_865': 39.7174,
    '1090_866': 39.548,
    '1090_867': 39.3659,
    '1090_868': 39.1684,
    '1090_869': 38.9692,
    '1090_870': 38.7608,
    '1090_871': 38.5382,
    '1090_872': 38.313,
    '1090_873': 38.0857,
    '1090_874': 37.8507,
    '1090_875': 37.6099,
    '1090_876': 37.3692,
    '1090_877': 37.1314,
    '1090_878': 36.8927,
    '1090_879': 36.6486,
    '1090_880': 36.3961,
    '1090_881': 36.1396,
    '1090_882': 35.8746,
    '1090_883': 35.6064,
    '1090_884': 35.3344,
    '1090_885': 35.0512,
    '1090_886': 34.7594,
    '1090_887': 34.4616,
    '1090_888': 34.1587,
    '1090_889': 33.8589,
    '1090_890': 33.5566,
    '1090_891': 33.2534,
    '1090_892': 32.9497,
    '1090_893': 32.6442,
    '1090_894': 32.3409,
    '1090_895': 32.0399,
    '1090_896': 31.7398,
    '1090_897': 31.4471,
    '1090_898': 31.1589,
    '1090_899': 30.8749,
    '1090_900': 30.5905,
    '1091_727': 36.6905,
    '1091_728': 36.9019,
    '1091_729': 37.1151,
    '1091_730': 37.3156,
    '1091_731': 37.4922,
    '1091_732': 37.6586,
    '1091_733': 37.8192,
    '1091_734': 37.972,
    '1091_735': 38.1003,
    '1091_736': 38.1771,
    '1091_737': 38.1981,
    '1091_738': 38.1556,
    '1091_739': 38.0757,
    '1091_740': 37.9835,
    '1091_741': 37.9036,
    '1091_742': 37.8455,
    '1091_743': 37.8152,
    '1091_744': 37.8066,
    '1091_768': 38.3818,
    '1091_769': 38.4794,
    '1091_770': 38.598,
    '1091_771': 38.7204,
    '1091_772': 38.8418,
    '1091_773': 38.9657,
    '1091_774': 39.0906,
    '1091_775': 39.2171,
    '1091_776': 39.348,
    '1091_777': 39.4846,
    '1091_778': 39.6297,
    '1091_779': 39.7839,
    '1091_780': 39.9519,
    '1091_781': 40.1258,
    '1091_782': 40.3011,
    '1091_783': 40.4827,
    '1091_784': 40.6467,
    '1091_785': 40.8029,
    '1091_786': 40.9575,
    '1091_787': 41.0911,
    '1091_788': 41.1953,
    '1091_789': 41.2729,
    '1091_790': 41.36,
    '1091_791': 41.462,
    '1091_792': 41.5752,
    '1091_793': 41.6884,
    '1091_794': 41.7852,
    '1091_795': 41.861,
    '1091_796': 41.9115,
    '1091_797': 41.9537,
    '1091_798': 41.9839,
    '1091_799': 41.9776,
    '1091_800': 41.9385,
    '1091_801': 41.8639,
    '1091_802': 41.7915,
    '1091_803': 41.7631,
    '1091_804': 41.7754,
    '1091_805': 41.8174,
    '1091_806': 41.8574,
    '1091_807': 41.8802,
    '1091_808': 41.8868,
    '1091_809': 41.8823,
    '1091_810': 41.8736,
    '1091_811': 41.8795,
    '1091_812': 41.9256,
    '1091_813': 42.0223,
    '1091_814': 42.1609,
    '1091_815': 42.3099,
    '1091_816': 42.4375,
    '1091_817': 42.532,
    '1091_818': 42.5851,
    '1091_819': 42.5868,
    '1091_820': 42.5518,
    '1091_821': 42.4726,
    '1091_822': 42.3745,
    '1091_823': 42.266,
    '1091_824': 42.168,
    '1091_825': 42.091,
    '1091_826': 42.0393,
    '1091_827': 42.0117,
    '1091_828': 42.0026,
    '1091_829': 41.9879,
    '1091_830': 41.9702,
    '1091_831': 41.9501,
    '1091_832': 41.94,
    '1091_833': 41.9202,
    '1091_834': 41.8836,
    '1091_835': 41.8529,
    '1091_836': 41.8326,
    '1091_837': 41.8152,
    '1091_838': 41.7948,
    '1091_839': 41.7648,
    '1091_840': 41.7266,
    '1091_841': 41.6812,
    '1091_842': 41.6302,
    '1091_843': 41.5745,
    '1091_844': 41.5157,
    '1091_845': 41.4572,
    '1091_846': 41.4051,
    '1091_847': 41.3571,
    '1091_848': 41.3131,
    '1091_849': 41.2753,
    '1091_850': 41.2415,
    '1091_851': 41.2033,
    '1091_852': 41.1399,
    '1091_853': 41.0628,
    '1091_854': 40.9782,
    '1091_855': 40.8945,
    '1091_856': 40.8153,
    '1091_857': 40.7443,
    '1091_858': 40.6694,
    '1091_859': 40.5816,
    '1091_860': 40.4782,
    '1091_861': 40.3583,
    '1091_862': 40.2296,
    '1091_863': 40.0891,
    '1091_864': 39.9373,
    '1091_865': 39.7847,
    '1091_866': 39.6176,
    '1091_867': 39.4378,
    '1091_868': 39.2438,
    '1091_869': 39.0432,
    '1091_870': 38.8333,
    '1091_871': 38.6101,
    '1091_872': 38.3784,
    '1091_873': 38.1504,
    '1091_874': 37.9131,
    '1091_875': 37.6804,
    '1091_876': 37.439,
    '1091_877': 37.195,
    '1091_878': 36.9521,
    '1091_879': 36.7028,
    '1091_880': 36.4467,
    '1091_881': 36.1862,
    '1091_882': 35.9208,
    '1091_883': 35.6517,
    '1091_884': 35.3785,
    '1091_885': 35.0973,
    '1091_886': 34.8104,
    '1091_887': 34.5147,
    '1091_888': 34.2106,
    '1091_889': 33.9075,
    '1091_890': 33.6049,
    '1091_891': 33.3031,
    '1091_892': 33.0017,
    '1091_893': 32.6995,
    '1091_894': 32.3977,
    '1091_895': 32.0977,
    '1091_896': 31.7996,
    '1091_897': 31.5065,
    '1091_898': 31.2175,
    '1091_899': 30.9307,
    '1091_900': 30.6442,
    '1092_727': 36.6396,
    '1092_728': 36.8438,
    '1092_729': 37.0528,
    '1092_730': 37.2509,
    '1092_731': 37.4272,
    '1092_732': 37.5834,
    '1092_733': 37.7321,
    '1092_734': 37.8731,
    '1092_735': 38.0057,
    '1092_736': 38.1079,
    '1092_737': 38.1597,
    '1092_738': 38.1507,
    '1092_739': 38.0822,
    '1092_740': 37.9723,
    '1092_741': 37.8762,
    '1092_742': 37.8094,
    '1092_743': 37.7738,
    '1092_744': 37.7584,
    '1092_768': 38.3798,
    '1092_769': 38.4701,
    '1092_770': 38.5867,
    '1092_771': 38.7017,
    '1092_772': 38.8197,
    '1092_773': 38.9421,
    '1092_774': 39.0671,
    '1092_775': 39.1979,
    '1092_776': 39.3334,
    '1092_777': 39.4727,
    '1092_778': 39.6165,
    '1092_779': 39.7634,
    '1092_780': 39.9218,
    '1092_781': 40.0882,
    '1092_782': 40.2594,
    '1092_783': 40.4422,
    '1092_784': 40.6141,
    '1092_785': 40.7755,
    '1092_786': 40.9361,
    '1092_787': 41.0755,
    '1092_788': 41.1851,
    '1092_789': 41.2679,
    '1092_790': 41.3611,
    '1092_791': 41.4697,
    '1092_792': 41.5847,
    '1092_793': 41.6953,
    '1092_794': 41.7956,
    '1092_795': 41.8791,
    '1092_796': 41.9448,
    '1092_797': 41.9904,
    '1092_798': 42.0159,
    '1092_799': 42.0099,
    '1092_800': 41.9675,
    '1092_801': 41.8961,
    '1092_802': 41.8103,
    '1092_803': 41.7604,
    '1092_804': 41.7585,
    '1092_805': 41.7859,
    '1092_806': 41.8187,
    '1092_807': 41.8409,
    '1092_808': 41.8397,
    '1092_809': 41.8279,
    '1092_810': 41.8106,
    '1092_811': 41.8044,
    '1092_812': 41.8478,
    '1092_813': 41.9408,
    '1092_814': 42.0764,
    '1092_815': 42.2229,
    '1092_816': 42.353,
    '1092_817': 42.4457,
    '1092_818': 42.5087,
    '1092_819': 42.5268,
    '1092_820': 42.5096,
    '1092_821': 42.444,
    '1092_822': 42.3593,
    '1092_823': 42.2648,
    '1092_824': 42.1661,
    '1092_825': 42.0851,
    '1092_826': 42.0335,
    '1092_827': 42.0055,
    '1092_828': 41.9947,
    '1092_829': 41.9756,
    '1092_830': 41.9571,
    '1092_831': 41.9403,
    '1092_832': 41.9267,
    '1092_833': 41.9055,
    '1092_834': 41.8707,
    '1092_835': 41.8443,
    '1092_836': 41.8275,
    '1092_837': 41.8127,
    '1092_838': 41.7957,
    '1092_839': 41.7689,
    '1092_840': 41.7318,
    '1092_841': 41.6876,
    '1092_842': 41.6385,
    '1092_843': 41.5863,
    '1092_844': 41.5323,
    '1092_845': 41.4796,
    '1092_846': 41.432,
    '1092_847': 41.3898,
    '1092_848': 41.351,
    '1092_849': 41.3132,
    '1092_850': 41.2732,
    '1092_851': 41.229,
    '1092_852': 41.1736,
    '1092_853': 41.107,
    '1092_854': 41.0346,
    '1092_855': 40.9604,
    '1092_856': 40.8878,
    '1092_857': 40.8159,
    '1092_858': 40.7388,
    '1092_859': 40.6512,
    '1092_860': 40.5499,
    '1092_861': 40.4343,
    '1092_862': 40.3046,
    '1092_863': 40.1642,
    '1092_864': 40.0147,
    '1092_865': 39.8525,
    '1092_866': 39.6854,
    '1092_867': 39.5079,
    '1092_868': 39.3164,
    '1092_869': 39.117,
    '1092_870': 38.9095,
    '1092_871': 38.6917,
    '1092_872': 38.4642,
    '1092_873': 38.232,
    '1092_874': 37.9918,
    '1092_875': 37.7554,
    '1092_876': 37.5126,
    '1092_877': 37.2642,
    '1092_878': 37.0161,
    '1092_879': 36.7611,
    '1092_880': 36.4985,
    '1092_881': 36.2338,
    '1092_882': 35.9666,
    '1092_883': 35.6964,
    '1092_884': 35.4229,
    '1092_885': 35.1453,
    '1092_886': 34.8603,
    '1092_887': 34.5673,
    '1092_888': 34.2597,
    '1092_889': 33.9549,
    '1092_890': 33.6532,
    '1092_891': 33.3532,
    '1092_892': 33.0542,
    '1092_893': 32.7546,
    '1092_894': 32.4547,
    '1092_895': 32.1567,
    '1092_896': 31.8601,
    '1092_897': 31.5666,
    '1092_898': 31.2771,
    '1092_899': 30.9882,
    '1092_900': 30.7004,
    '1093_727': 36.5959,
    '1093_728': 36.7876,
    '1093_729': 36.9906,
    '1093_730': 37.1833,
    '1093_731': 37.3594,
    '1093_732': 37.5112,
    '1093_733': 37.6515,
    '1093_734': 37.7815,
    '1093_735': 37.9074,
    '1093_736': 38.0215,
    '1093_737': 38.0999,
    '1093_738': 38.1202,
    '1093_739': 38.0737,
    '1093_740': 37.9608,
    '1093_741': 37.8462,
    '1093_742': 37.7765,
    '1093_743': 37.7344,
    '1093_744': 37.7119,
    '1093_768': 38.3841,
    '1093_769': 38.4657,
    '1093_770': 38.5679,
    '1093_771': 38.6793,
    '1093_772': 38.7971,
    '1093_773': 38.9206,
    '1093_774': 39.0489,
    '1093_775': 39.1833,
    '1093_776': 39.3236,
    '1093_777': 39.4676,
    '1093_778': 39.6133,
    '1093_779': 39.7555,
    '1093_780': 39.9001,
    '1093_781': 40.0563,
    '1093_782': 40.2262,
    '1093_783': 40.4062,
    '1093_784': 40.5823,
    '1093_785': 40.7522,
    '1093_786': 40.9233,
    '1093_787': 41.0653,
    '1093_788': 41.1747,
    '1093_789': 41.2576,
    '1093_790': 41.361,
    '1093_791': 41.4748,
    '1093_792': 41.5963,
    '1093_793': 41.7084,
    '1093_794': 41.8117,
    '1093_795': 41.9079,
    '1093_796': 41.9799,
    '1093_797': 42.0305,
    '1093_798': 42.0427,
    '1093_799': 42.0314,
    '1093_800': 41.9857,
    '1093_801': 41.9186,
    '1093_802': 41.8313,
    '1093_803': 41.7645,
    '1093_804': 41.7453,
    '1093_805': 41.7554,
    '1093_806': 41.7777,
    '1093_807': 41.7961,
    '1093_808': 41.7889,
    '1093_809': 41.7691,
    '1093_810': 41.7424,
    '1093_811': 41.7273,
    '1093_812': 41.7644,
    '1093_813': 41.8518,
    '1093_814': 41.9808,
    '1093_815': 42.1251,
    '1093_816': 42.2576,
    '1093_817': 42.3566,
    '1093_818': 42.4378,
    '1093_819': 42.4765,
    '1093_820': 42.4699,
    '1093_821': 42.4128,
    '1093_822': 42.3412,
    '1093_823': 42.2626,
    '1093_824': 42.1636,
    '1093_825': 42.0809,
    '1093_826': 42.0313,
    '1093_827': 42.002,
    '1093_828': 41.9825,
    '1093_829': 41.9595,
    '1093_830': 41.9415,
    '1093_831': 41.9283,
    '1093_832': 41.9099,
    '1093_833': 41.8859,
    '1093_834': 41.8545,
    '1093_835': 41.8335,
    '1093_836': 41.8186,
    '1093_837': 41.8051,
    '1093_838': 41.7902,
    '1093_839': 41.7691,
    '1093_840': 41.7352,
    '1093_841': 41.6939,
    '1093_842': 41.6477,
    '1093_843': 41.5991,
    '1093_844': 41.5497,
    '1093_845': 41.5019,
    '1093_846': 41.4586,
    '1093_847': 41.4223,
    '1093_848': 41.3887,
    '1093_849': 41.3556,
    '1093_850': 41.3196,
    '1093_851': 41.2703,
    '1093_852': 41.2152,
    '1093_853': 41.1549,
    '1093_854': 41.091,
    '1093_855': 41.0247,
    '1093_856': 40.9579,
    '1093_857': 40.888,
    '1093_858': 40.8116,
    '1093_859': 40.7248,
    '1093_860': 40.6246,
    '1093_861': 40.5088,
    '1093_862': 40.3785,
    '1093_863': 40.2353,
    '1093_864': 40.0818,
    '1093_865': 39.9182,
    '1093_866': 39.7503,
    '1093_867': 39.5729,
    '1093_868': 39.3813,
    '1093_869': 39.1817,
    '1093_870': 38.977,
    '1093_871': 38.7654,
    '1093_872': 38.5362,
    '1093_873': 38.2988,
    '1093_874': 38.0557,
    '1093_875': 37.8116,
    '1093_876': 37.5687,
    '1093_877': 37.3216,
    '1093_878': 37.0704,
    '1093_879': 36.8118,
    '1093_880': 36.5473,
    '1093_881': 36.2797,
    '1093_882': 36.01,
    '1093_883': 35.7384,
    '1093_884': 35.4642,
    '1093_885': 35.1868,
    '1093_886': 34.9045,
    '1093_887': 34.6121,
    '1093_888': 34.3086,
    '1093_889': 34.0042,
    '1093_890': 33.7049,
    '1093_891': 33.408,
    '1093_892': 33.112,
    '1093_893': 32.8152,
    '1093_894': 32.5177,
    '1093_895': 32.2217,
    '1093_896': 31.9281,
    '1093_897': 31.6362,
    '1093_898': 31.3464,
    '1093_899': 31.0569,
    '1093_900': 30.7677,
    '1094_727': 36.5557,
    '1094_728': 36.7356,
    '1094_729': 36.9251,
    '1094_730': 37.1139,
    '1094_731': 37.2918,
    '1094_732': 37.4442,
    '1094_733': 37.577,
    '1094_734': 37.7003,
    '1094_735': 37.8158,
    '1094_736': 37.9291,
    '1094_737': 38.0224,
    '1094_738': 38.0678,
    '1094_739': 38.0505,
    '1094_740': 37.9579,
    '1094_741': 37.8383,
    '1094_742': 37.7516,
    '1094_743': 37.6974,
    '1094_744': 37.6661,
    '1094_768': 38.3815,
    '1094_769': 38.4612,
    '1094_770': 38.5511,
    '1094_771': 38.6583,
    '1094_772': 38.7758,
    '1094_773': 38.9008,
    '1094_774': 39.0319,
    '1094_775': 39.1698,
    '1094_776': 39.3146,
    '1094_777': 39.4653,
    '1094_778': 39.617,
    '1094_779': 39.7578,
    '1094_780': 39.8854,
    '1094_781': 40.033,
    '1094_782': 40.2008,
    '1094_783': 40.3773,
    '1094_784': 40.5488,
    '1094_785': 40.7203,
    '1094_786': 40.8946,
    '1094_787': 41.0385,
    '1094_788': 41.153,
    '1094_789': 41.2453,
    '1094_790': 41.3559,
    '1094_791': 41.4816,
    '1094_792': 41.6109,
    '1094_793': 41.7299,
    '1094_794': 41.8406,
    '1094_795': 41.9422,
    '1094_796': 42.0162,
    '1094_797': 42.0569,
    '1094_798': 42.0628,
    '1094_799': 42.0388,
    '1094_800': 41.992,
    '1094_801': 41.9272,
    '1094_802': 41.8465,
    '1094_803': 41.7691,
    '1094_804': 41.7254,
    '1094_805': 41.7164,
    '1094_806': 41.7274,
    '1094_807': 41.7394,
    '1094_808': 41.7264,
    '1094_809': 41.6987,
    '1094_810': 41.6634,
    '1094_811': 41.647,
    '1094_812': 41.6833,
    '1094_813': 41.7677,
    '1094_814': 41.8916,
    '1094_815': 42.0325,
    '1094_816': 42.165,
    '1094_817': 42.2791,
    '1094_818': 42.374,
    '1094_819': 42.427,
    '1094_820': 42.4311,
    '1094_821': 42.3894,
    '1094_822': 42.3289,
    '1094_823': 42.2555,
    '1094_824': 42.1618,
    '1094_825': 42.0725,
    '1094_826': 42.0243,
    '1094_827': 41.9918,
    '1094_828': 41.9696,
    '1094_829': 41.9444,
    '1094_830': 41.9303,
    '1094_831': 41.917,
    '1094_832': 41.893,
    '1094_833': 41.866,
    '1094_834': 41.8398,
    '1094_835': 41.8233,
    '1094_836': 41.8099,
    '1094_837': 41.798,
    '1094_838': 41.7833,
    '1094_839': 41.7658,
    '1094_840': 41.7363,
    '1094_841': 41.6982,
    '1094_842': 41.6551,
    '1094_843': 41.6098,
    '1094_844': 41.5647,
    '1094_845': 41.522,
    '1094_846': 41.4844,
    '1094_847': 41.452,
    '1094_848': 41.4215,
    '1094_849': 41.3898,
    '1094_850': 41.3533,
    '1094_851': 41.3075,
    '1094_852': 41.2561,
    '1094_853': 41.2017,
    '1094_854': 41.1445,
    '1094_855': 41.0853,
    '1094_856': 41.0242,
    '1094_857': 40.958,
    '1094_858': 40.8841,
    '1094_859': 40.7995,
    '1094_860': 40.6998,
    '1094_861': 40.5813,
    '1094_862': 40.4472,
    '1094_863': 40.3016,
    '1094_864': 40.1444,
    '1094_865': 39.9779,
    '1094_866': 39.8087,
    '1094_867': 39.6287,
    '1094_868': 39.4367,
    '1094_869': 39.2353,
    '1094_870': 39.0297,
    '1094_871': 38.819,
    '1094_872': 38.5929,
    '1094_873': 38.3551,
    '1094_874': 38.1116,
    '1094_875': 37.8665,
    '1094_876': 37.6202,
    '1094_877': 37.3719,
    '1094_878': 37.119,
    '1094_879': 36.8576,
    '1094_880': 36.5919,
    '1094_881': 36.3231,
    '1094_882': 36.0523,
    '1094_883': 35.78,
    '1094_884': 35.5063,
    '1094_885': 35.2296,
    '1094_886': 34.952,
    '1094_887': 34.6625,
    '1094_888': 34.3621,
    '1094_889': 34.0581,
    '1094_890': 33.7631,
    '1094_891': 33.4696,
    '1094_892': 33.1773,
    '1094_893': 32.8834,
    '1094_894': 32.5878,
    '1094_895': 32.294,
    '1094_896': 32.004,
    '1094_897': 31.7133,
    '1094_898': 31.4235,
    '1094_899': 31.1325,
    '1094_900': 30.8409,
    '1095_727': 36.5161,
    '1095_728': 36.6823,
    '1095_729': 36.8593,
    '1095_730': 37.0416,
    '1095_731': 37.2157,
    '1095_732': 37.3724,
    '1095_733': 37.5095,
    '1095_734': 37.6281,
    '1095_735': 37.7371,
    '1095_736': 37.8382,
    '1095_737': 37.9301,
    '1095_738': 38.0029,
    '1095_739': 38.0168,
    '1095_740': 37.9435,
    '1095_741': 37.8207,
    '1095_742': 37.7216,
    '1095_743': 37.66,
    '1095_744': 37.6216,
    '1095_768': 38.3824,
    '1095_769': 38.4587,
    '1095_770': 38.5416,
    '1095_771': 38.6429,
    '1095_772': 38.7582,
    '1095_773': 38.8834,
    '1095_774': 39.0157,
    '1095_775': 39.1561,
    '1095_776': 39.3046,
    '1095_777': 39.4605,
    '1095_778': 39.6208,
    '1095_779': 39.7684,
    '1095_780': 39.8828,
    '1095_781': 40.0159,
    '1095_782': 40.175,
    '1095_783': 40.3489,
    '1095_784': 40.5232,
    '1095_785': 40.7001,
    '1095_786': 40.8614,
    '1095_787': 41.0057,
    '1095_788': 41.1294,
    '1095_789': 41.2349,
    '1095_790': 41.3543,
    '1095_791': 41.485,
    '1095_792': 41.6217,
    '1095_793': 41.7496,
    '1095_794': 41.8663,
    '1095_795': 41.9686,
    '1095_796': 42.0505,
    '1095_797': 42.0747,
    '1095_798': 42.0832,
    '1095_799': 42.0452,
    '1095_800': 41.9889,
    '1095_801': 41.9231,
    '1095_802': 41.8498,
    '1095_803': 41.7738,
    '1095_804': 41.7054,
    '1095_805': 41.6764,
    '1095_806': 41.6704,
    '1095_807': 41.6691,
    '1095_808': 41.6561,
    '1095_809': 41.6233,
    '1095_810': 41.5816,
    '1095_811': 41.5692,
    '1095_812': 41.6077,
    '1095_813': 41.6923,
    '1095_814': 41.8131,
    '1095_815': 41.9527,
    '1095_816': 42.0897,
    '1095_817': 42.2105,
    '1095_818': 42.3119,
    '1095_819': 42.3704,
    '1095_820': 42.3925,
    '1095_821': 42.3664,
    '1095_822': 42.3142,
    '1095_823': 42.2425,
    '1095_824': 42.1514,
    '1095_825': 42.0686,
    '1095_826': 42.0207,
    '1095_827': 41.9825,
    '1095_828': 41.9543,
    '1095_829': 41.9284,
    '1095_830': 41.9125,
    '1095_831': 41.9004,
    '1095_832': 41.878,
    '1095_833': 41.8503,
    '1095_834': 41.8264,
    '1095_835': 41.8115,
    '1095_836': 41.8031,
    '1095_837': 41.7947,
    '1095_838': 41.7829,
    '1095_839': 41.7648,
    '1095_840': 41.7377,
    '1095_841': 41.7016,
    '1095_842': 41.6601,
    '1095_843': 41.6171,
    '1095_844': 41.575,
    '1095_845': 41.5366,
    '1095_846': 41.5041,
    '1095_847': 41.4764,
    '1095_848': 41.4498,
    '1095_849': 41.4198,
    '1095_850': 41.3842,
    '1095_851': 41.3422,
    '1095_852': 41.2952,
    '1095_853': 41.2465,
    '1095_854': 41.1951,
    '1095_855': 41.1425,
    '1095_856': 41.0871,
    '1095_857': 41.025,
    '1095_858': 40.9543,
    '1095_859': 40.8729,
    '1095_860': 40.7746,
    '1095_861': 40.653,
    '1095_862': 40.5158,
    '1095_863': 40.3668,
    '1095_864': 40.2075,
    '1095_865': 40.0397,
    '1095_866': 39.8631,
    '1095_867': 39.6849,
    '1095_868': 39.4911,
    '1095_869': 39.2853,
    '1095_870': 39.0772,
    '1095_871': 38.8698,
    '1095_872': 38.6452,
    '1095_873': 38.4064,
    '1095_874': 38.1625,
    '1095_875': 37.9167,
    '1095_876': 37.6669,
    '1095_877': 37.4176,
    '1095_878': 37.1622,
    '1095_879': 36.8994,
    '1095_880': 36.6326,
    '1095_881': 36.3659,
    '1095_882': 36.0972,
    '1095_883': 35.8268,
    '1095_884': 35.5567,
    '1095_885': 35.2839,
    '1095_886': 35.0079,
    '1095_887': 34.7249,
    '1095_888': 34.43,
    '1095_889': 34.1322,
    '1095_890': 33.837,
    '1095_891': 33.5432,
    '1095_892': 33.2546,
    '1095_893': 32.9637,
    '1095_894': 32.6701,
    '1095_895': 32.3806,
    '1095_896': 32.0918,
    '1095_897': 31.8009,
    '1095_898': 31.5087,
    '1095_899': 31.2151,
    '1095_900': 30.919,
    '1096_727': 36.4798,
    '1096_728': 36.6339,
    '1096_729': 36.8041,
    '1096_730': 36.9771,
    '1096_731': 37.1457,
    '1096_732': 37.3046,
    '1096_733': 37.4436,
    '1096_734': 37.5651,
    '1096_735': 37.6699,
    '1096_736': 37.7669,
    '1096_737': 37.855,
    '1096_738': 37.9372,
    '1096_739': 37.9705,
    '1096_740': 37.9141,
    '1096_741': 37.799,
    '1096_742': 37.7,
    '1096_743': 37.6286,
    '1096_744': 37.581,
    '1096_768': 38.3772,
    '1096_769': 38.4561,
    '1096_770': 38.5386,
    '1096_771': 38.6318,
    '1096_772': 38.7441,
    '1096_773': 38.8689,
    '1096_774': 39.0004,
    '1096_775': 39.1419,
    '1096_776': 39.292,
    '1096_777': 39.4498,
    '1096_778': 39.6135,
    '1096_779': 39.7692,
    '1096_780': 39.8881,
    '1096_781': 40.0016,
    '1096_782': 40.1483,
    '1096_783': 40.3236,
    '1096_784': 40.5064,
    '1096_785': 40.6909,
    '1096_786': 40.8512,
    '1096_787': 40.9866,
    '1096_788': 41.1127,
    '1096_789': 41.2289,
    '1096_790': 41.3572,
    '1096_791': 41.4948,
    '1096_792': 41.6337,
    '1096_793': 41.7684,
    '1096_794': 41.8919,
    '1096_795': 41.9912,
    '1096_796': 42.0558,
    '1096_797': 42.0777,
    '1096_798': 42.0763,
    '1096_799': 42.0353,
    '1096_800': 41.9783,
    '1096_801': 41.9079,
    '1096_802': 41.8354,
    '1096_803': 41.7619,
    '1096_804': 41.68,
    '1096_805': 41.6249,
    '1096_806': 41.6068,
    '1096_807': 41.5959,
    '1096_808': 41.5827,
    '1096_809': 41.5519,
    '1096_810': 41.5073,
    '1096_811': 41.4974,
    '1096_812': 41.5377,
    '1096_813': 41.6225,
    '1096_814': 41.7391,
    '1096_815': 41.879,
    '1096_816': 42.0186,
    '1096_817': 42.1382,
    '1096_818': 42.2467,
    '1096_819': 42.3199,
    '1096_820': 42.3586,
    '1096_821': 42.3427,
    '1096_822': 42.2974,
    '1096_823': 42.2335,
    '1096_824': 42.1509,
    '1096_825': 42.0682,
    '1096_826': 42.02,
    '1096_827': 41.9791,
    '1096_828': 41.9421,
    '1096_829': 41.9092,
    '1096_830': 41.8893,
    '1096_831': 41.8715,
    '1096_832': 41.8544,
    '1096_833': 41.832,
    '1096_834': 41.8105,
    '1096_835': 41.7984,
    '1096_836': 41.7941,
    '1096_837': 41.7892,
    '1096_838': 41.781,
    '1096_839': 41.7649,
    '1096_840': 41.7399,
    '1096_841': 41.7042,
    '1096_842': 41.6625,
    '1096_843': 41.6193,
    '1096_844': 41.5786,
    '1096_845': 41.543,
    '1096_846': 41.5149,
    '1096_847': 41.4928,
    '1096_848': 41.4729,
    '1096_849': 41.4466,
    '1096_850': 41.4143,
    '1096_851': 41.3772,
    '1096_852': 41.3346,
    '1096_853': 41.2917,
    '1096_854': 41.2444,
    '1096_855': 41.1978,
    '1096_856': 41.1469,
    '1096_857': 41.0872,
    '1096_858': 41.0184,
    '1096_859': 40.9381,
    '1096_860': 40.8377,
    '1096_861': 40.7192,
    '1096_862': 40.5806,
    '1096_863': 40.4303,
    '1096_864': 40.2661,
    '1096_865': 40.0962,
    '1096_866': 39.9214,
    '1096_867': 39.7448,
    '1096_868': 39.5506,
    '1096_869': 39.3438,
    '1096_870': 39.1322,
    '1096_871': 38.9208,
    '1096_872': 38.6974,
    '1096_873': 38.4579,
    '1096_874': 38.2118,
    '1096_875': 37.9628,
    '1096_876': 37.7099,
    '1096_877': 37.4607,
    '1096_878': 37.2059,
    '1096_879': 36.9437,
    '1096_880': 36.6767,
    '1096_881': 36.4147,
    '1096_882': 36.1495,
    '1096_883': 35.881,
    '1096_884': 35.6163,
    '1096_885': 35.3479,
    '1096_886': 35.0746,
    '1096_887': 34.8004,
    '1096_888': 34.509,
    '1096_889': 34.2175,
    '1096_890': 33.9249,
    '1096_891': 33.6343,
    '1096_892': 33.3464,
    '1096_893': 33.0575,
    '1096_894': 32.7675,
    '1096_895': 32.4762,
    '1096_896': 32.1857,
    '1096_897': 31.8921,
    '1096_898': 31.5968,
    '1096_899': 31.3003,
    '1096_900': 30.9996,
    '1097_727': 36.4579,
    '1097_728': 36.6094,
    '1097_729': 36.7657,
    '1097_730': 36.9242,
    '1097_731': 37.0843,
    '1097_732': 37.2385,
    '1097_733': 37.3784,
    '1097_734': 37.5003,
    '1097_735': 37.6063,
    '1097_736': 37.7037,
    '1097_737': 37.7924,
    '1097_738': 37.8779,
    '1097_739': 37.922,
    '1097_740': 37.8838,
    '1097_741': 37.777,
    '1097_742': 37.6761,
    '1097_743': 37.6006,
    '1097_744': 37.5483,
    '1097_768': 38.3741,
    '1097_769': 38.4567,
    '1097_770': 38.5399,
    '1097_771': 38.6241,
    '1097_772': 38.7328,
    '1097_773': 38.8583,
    '1097_774': 38.9877,
    '1097_775': 39.1285,
    '1097_776': 39.2784,
    '1097_777': 39.4358,
    '1097_778': 39.5998,
    '1097_779': 39.7669,
    '1097_780': 39.8968,
    '1097_781': 39.9981,
    '1097_782': 40.1308,
    '1097_783': 40.3061,
    '1097_784': 40.4913,
    '1097_785': 40.6783,
    '1097_786': 40.8422,
    '1097_787': 40.9843,
    '1097_788': 41.1135,
    '1097_789': 41.2256,
    '1097_790': 41.3597,
    '1097_791': 41.5051,
    '1097_792': 41.644,
    '1097_793': 41.7805,
    '1097_794': 41.9037,
    '1097_795': 42.0096,
    '1097_796': 42.0568,
    '1097_797': 42.0864,
    '1097_798': 42.0611,
    '1097_799': 42.0163,
    '1097_800': 41.9533,
    '1097_801': 41.8818,
    '1097_802': 41.8079,
    '1097_803': 41.7319,
    '1097_804': 41.6448,
    '1097_805': 41.5725,
    '1097_806': 41.5412,
    '1097_807': 41.5272,
    '1097_808': 41.5136,
    '1097_809': 41.4857,
    '1097_810': 41.4448,
    '1097_811': 41.4346,
    '1097_812': 41.4755,
    '1097_813': 41.5589,
    '1097_814': 41.6729,
    '1097_815': 41.8085,
    '1097_816': 41.9426,
    '1097_817': 42.0656,
    '1097_818': 42.1805,
    '1097_819': 42.2693,
    '1097_820': 42.3188,
    '1097_821': 42.3239,
    '1097_822': 42.2906,
    '1097_823': 42.2359,
    '1097_824': 42.1612,
    '1097_825': 42.0879,
    '1097_826': 42.0314,
    '1097_827': 41.9806,
    '1097_828': 41.9328,
    '1097_829': 41.8992,
    '1097_830': 41.8705,
    '1097_831': 41.8487,
    '1097_832': 41.8371,
    '1097_833': 41.8163,
    '1097_834': 41.7947,
    '1097_835': 41.7836,
    '1097_836': 41.7827,
    '1097_837': 41.7819,
    '1097_838': 41.7767,
    '1097_839': 41.7652,
    '1097_840': 41.7423,
    '1097_841': 41.7056,
    '1097_842': 41.6621,
    '1097_843': 41.6158,
    '1097_844': 41.5744,
    '1097_845': 41.5398,
    '1097_846': 41.5142,
    '1097_847': 41.4972,
    '1097_848': 41.4841,
    '1097_849': 41.4681,
    '1097_850': 41.4449,
    '1097_851': 41.4157,
    '1097_852': 41.3815,
    '1097_853': 41.3425,
    '1097_854': 41.299,
    '1097_855': 41.2535,
    '1097_856': 41.2036,
    '1097_857': 41.1434,
    '1097_858': 41.0725,
    '1097_859': 40.9914,
    '1097_860': 40.8949,
    '1097_861': 40.7833,
    '1097_862': 40.642,
    '1097_863': 40.4945,
    '1097_864': 40.3203,
    '1097_865': 40.1483,
    '1097_866': 39.9743,
    '1097_867': 39.803,
    '1097_868': 39.6104,
    '1097_869': 39.4048,
    '1097_870': 39.1924,
    '1097_871': 38.9774,
    '1097_872': 38.7559,
    '1097_873': 38.5231,
    '1097_874': 38.2743,
    '1097_875': 38.0211,
    '1097_876': 37.766,
    '1097_877': 37.5127,
    '1097_878': 37.2576,
    '1097_879': 36.9984,
    '1097_880': 36.7367,
    '1097_881': 36.4767,
    '1097_882': 36.2152,
    '1097_883': 35.9519,
    '1097_884': 35.6887,
    '1097_885': 35.4236,
    '1097_886': 35.155,
    '1097_887': 34.8821,
    '1097_888': 34.5984,
    '1097_889': 34.3106,
    '1097_890': 34.02,
    '1097_891': 33.7309,
    '1097_892': 33.4431,
    '1097_893': 33.1531,
    '1097_894': 32.8615,
    '1097_895': 32.5684,
    '1097_896': 32.2746,
    '1097_897': 31.9789,
    '1097_898': 31.6815,
    '1097_899': 31.382,
    '1097_900': 31.0793,
    '1098_727': 36.444,
    '1098_728': 36.5916,
    '1098_729': 36.736,
    '1098_730': 36.8818,
    '1098_731': 37.0294,
    '1098_732': 37.1758,
    '1098_733': 37.3121,
    '1098_734': 37.4287,
    '1098_735': 37.5341,
    '1098_736': 37.6343,
    '1098_737': 37.7254,
    '1098_738': 37.8114,
    '1098_739': 37.8667,
    '1098_740': 37.8511,
    '1098_741': 37.7614,
    '1098_742': 37.6634,
    '1098_743': 37.5778,
    '1098_744': 37.5165,
    '1098_768': 38.3731,
    '1098_769': 38.4606,
    '1098_770': 38.5493,
    '1098_771': 38.6256,
    '1098_772': 38.7247,
    '1098_773': 38.8468,
    '1098_774': 38.9772,
    '1098_775': 39.1169,
    '1098_776': 39.2664,
    '1098_777': 39.4253,
    '1098_778': 39.5929,
    '1098_779': 39.7651,
    '1098_780': 39.9053,
    '1098_781': 40.0103,
    '1098_782': 40.127,
    '1098_783': 40.2974,
    '1098_784': 40.4735,
    '1098_785': 40.6566,
    '1098_786': 40.8311,
    '1098_787': 40.9845,
    '1098_788': 41.113,
    '1098_789': 41.2228,
    '1098_790': 41.3622,
    '1098_791': 41.5128,
    '1098_792': 41.6576,
    '1098_793': 41.7967,
    '1098_794': 41.9103,
    '1098_795': 41.9935,
    '1098_796': 42.0431,
    '1098_797': 42.0504,
    '1098_798': 42.026,
    '1098_799': 41.9791,
    '1098_800': 41.9159,
    '1098_801': 41.8491,
    '1098_802': 41.7737,
    '1098_803': 41.6888,
    '1098_804': 41.6041,
    '1098_805': 41.5277,
    '1098_806': 41.4824,
    '1098_807': 41.4663,
    '1098_808': 41.4522,
    '1098_809': 41.4289,
    '1098_810': 41.3939,
    '1098_811': 41.3811,
    '1098_812': 41.4196,
    '1098_813': 41.5007,
    '1098_814': 41.6122,
    '1098_815': 41.7426,
    '1098_816': 41.8736,
    '1098_817': 41.9998,
    '1098_818': 42.1169,
    '1098_819': 42.2185,
    '1098_820': 42.2849,
    '1098_821': 42.3038,
    '1098_822': 42.2883,
    '1098_823': 42.2414,
    '1098_824': 42.1738,
    '1098_825': 42.0999,
    '1098_826': 42.0442,
    '1098_827': 41.992,
    '1098_828': 41.9422,
    '1098_829': 41.901,
    '1098_830': 41.8607,
    '1098_831': 41.8308,
    '1098_832': 41.8149,
    '1098_833': 41.7979,
    '1098_834': 41.7782,
    '1098_835': 41.7692,
    '1098_836': 41.7676,
    '1098_837': 41.7673,
    '1098_838': 41.7665,
    '1098_839': 41.7613,
    '1098_840': 41.7423,
    '1098_841': 41.7062,
    '1098_842': 41.6598,
    '1098_843': 41.6085,
    '1098_844': 41.5644,
    '1098_845': 41.5285,
    '1098_846': 41.5035,
    '1098_847': 41.4918,
    '1098_848': 41.4868,
    '1098_849': 41.4826,
    '1098_850': 41.4687,
    '1098_851': 41.4482,
    '1098_852': 41.424,
    '1098_853': 41.3893,
    '1098_854': 41.3492,
    '1098_855': 41.3036,
    '1098_856': 41.2524,
    '1098_857': 41.1925,
    '1098_858': 41.1211,
    '1098_859': 41.0408,
    '1098_860': 40.9471,
    '1098_861': 40.8354,
    '1098_862': 40.6935,
    '1098_863': 40.5447,
    '1098_864': 40.3702,
    '1098_865': 40.1996,
    '1098_866': 40.027,
    '1098_867': 39.8515,
    '1098_868': 39.6658,
    '1098_869': 39.4633,
    '1098_870': 39.2508,
    '1098_871': 39.0338,
    '1098_872': 38.8123,
    '1098_873': 38.5809,
    '1098_874': 38.3344,
    '1098_875': 38.0809,
    '1098_876': 37.8245,
    '1098_877': 37.5693,
    '1098_878': 37.3134,
    '1098_879': 37.057,
    '1098_880': 36.7996,
    '1098_881': 36.5427,
    '1098_882': 36.2847,
    '1098_883': 36.025,
    '1098_884': 35.7633,
    '1098_885': 35.5012,
    '1098_886': 35.2362,
    '1098_887': 34.966,
    '1098_888': 34.6864,
    '1098_889': 34.4015,
    '1098_890': 34.1112,
    '1098_891': 33.8235,
    '1098_892': 33.5374,
    '1098_893': 33.2458,
    '1098_894': 32.9524,
    '1098_895': 32.6572,
    '1098_896': 32.3608,
    '1098_897': 32.0636,
    '1098_898': 31.7645,
    '1098_899': 31.4631,
    '1098_900': 31.1584,
    '1099_727': 36.436,
    '1099_728': 36.575,
    '1099_729': 36.7093,
    '1099_730': 36.8432,
    '1099_731': 36.9774,
    '1099_732': 37.1139,
    '1099_733': 37.2376,
    '1099_734': 37.3514,
    '1099_735': 37.4559,
    '1099_736': 37.5581,
    '1099_737': 37.6488,
    '1099_738': 37.7294,
    '1099_739': 37.7878,
    '1099_740': 37.8027,
    '1099_741': 37.743,
    '1099_742': 37.6478,
    '1099_743': 37.559,
    '1099_744': 37.4945,
    '1099_774': 38.9706,
    '1099_775': 39.1077,
    '1099_776': 39.2559,
    '1099_777': 39.4165,
    '1099_778': 39.5879,
    '1099_779': 39.7648,
    '1099_780': 39.9148,
    '1099_781': 40.0284,
    '1099_782': 40.1278,
    '1099_783': 40.2849,
    '1099_784': 40.4514,
    '1099_785': 40.6333,
    '1099_786': 40.816,
    '1099_787': 40.9709,
    '1099_788': 41.1003,
    '1099_789': 41.2187,
    '1099_790': 41.3647,
    '1099_791': 41.516,
    '1099_792': 41.6614,
    '1099_793': 41.7961,
    '1099_794': 41.9114,
    '1099_795': 41.9747,
    '1099_796': 42.0162,
    '1099_797': 42.0056,
    '1099_798': 41.9744,
    '1099_799': 41.9269,
    '1099_800': 41.8707,
    '1099_801': 41.8125,
    '1099_802': 41.7352,
    '1099_803': 41.6456,
    '1099_804': 41.5666,
    '1099_805': 41.4903,
    '1099_806': 41.4381,
    '1099_807': 41.4122,
    '1099_808': 41.3961,
    '1099_809': 41.3772,
    '1099_810': 41.348,
    '1099_811': 41.3343,
    '1099_812': 41.3701,
    '1099_813': 41.4468,
    '1099_814': 41.5539,
    '1099_815': 41.6799,
    '1099_816': 41.8139,
    '1099_817': 41.9477,
    '1099_818': 42.0682,
    '1099_819': 42.1726,
    '1099_820': 42.2513,
    '1099_821': 42.2928,
    '1099_822': 42.2887,
    '1099_823': 42.252,
    '1099_824': 42.1875,
    '1099_825': 42.1182,
    '1099_826': 42.0614,
    '1099_827': 42.0082,
    '1099_828': 41.9603,
    '1099_829': 41.9112,
    '1099_830': 41.8578,
    '1099_831': 41.8176,
    '1099_832': 41.7938,
    '1099_833': 41.7774,
    '1099_834': 41.7645,
    '1099_835': 41.7574,
    '1099_836': 41.7556,
    '1099_837': 41.756,
    '1099_838': 41.7577,
    '1099_839': 41.7564,
    '1099_840': 41.7389,
    '1099_841': 41.7005,
    '1099_842': 41.6506,
    '1099_843': 41.5979,
    '1099_844': 41.5521,
    '1099_845': 41.5138,
    '1099_846': 41.4866,
    '1099_847': 41.4794,
    '1099_848': 41.4788,
    '1099_849': 41.4795,
    '1099_850': 41.4749,
    '1099_851': 41.4643,
    '1099_852': 41.4482,
    '1099_853': 41.4239,
    '1099_854': 41.3909,
    '1099_855': 41.3472,
    '1099_856': 41.2958,
    '1099_857': 41.2358,
    '1099_858': 41.1671,
    '1099_859': 41.0919,
    '1099_860': 41.0019,
    '1099_861': 40.884,
    '1099_862': 40.7336,
    '1099_863': 40.5796,
    '1099_864': 40.4156,
    '1099_865': 40.2494,
    '1099_866': 40.0794,
    '1099_867': 39.9039,
    '1099_868': 39.7197,
    '1099_869': 39.5205,
    '1099_870': 39.3097,
    '1099_871': 39.0917,
    '1099_872': 38.8675,
    '1099_873': 38.6344,
    '1099_874': 38.3912,
    '1099_875': 38.1396,
    '1099_876': 37.8837,
    '1099_877': 37.6276,
    '1099_878': 37.3707,
    '1099_879': 37.1116,
    '1099_880': 36.8586,
    '1099_881': 36.6038,
    '1099_882': 36.3473,
    '1099_883': 36.0899,
    '1099_884': 35.831,
    '1099_885': 35.571,
    '1099_886': 35.3078,
    '1099_887': 35.0392,
    '1099_888': 34.7622,
    '1099_889': 34.4764,
    '1099_890': 34.1898,
    '1099_891': 33.9051,
    '1099_892': 33.6201,
    '1099_893': 33.3309,
    '1099_894': 33.0389,
    '1099_895': 32.7428,
    '1099_896': 32.4453,
    '1099_897': 32.1476,
    '1099_898': 31.8476,
    '1099_899': 31.5445,
    '1099_900': 31.2378,
    '1100_727': 36.4271,
    '1100_728': 36.5605,
    '1100_729': 36.6884,
    '1100_730': 36.8113,
    '1100_731': 36.9358,
    '1100_732': 37.0597,
    '1100_733': 37.1666,
    '1100_734': 37.2795,
    '1100_735': 37.3856,
    '1100_736': 37.4824,
    '1100_737': 37.5682,
    '1100_738': 37.6419,
    '1100_739': 37.6972,
    '1100_740': 37.7223,
    '1100_741': 37.6919,
    '1100_742': 37.619,
    '1100_743': 37.5373,
    '1100_744': 37.4758,
    '1100_774': 38.9682,
    '1100_775': 39.1004,
    '1100_776': 39.2455,
    '1100_777': 39.4064,
    '1100_778': 39.5813,
    '1100_779': 39.7688,
    '1100_780': 39.9277,
    '1100_781': 40.0438,
    '1100_782': 40.1363,
    '1100_783': 40.2749,
    '1100_784': 40.4331,
    '1100_785': 40.6094,
    '1100_786': 40.7902,
    '1100_787': 40.9479,
    '1100_788': 41.0864,
    '1100_789': 41.2161,
    '1100_790': 41.3679,
    '1100_791': 41.5216,
    '1100_792': 41.6629,
    '1100_793': 41.7911,
    '1100_794': 41.8844,
    '1100_795': 41.9558,
    '1100_796': 41.9732,
    '1100_797': 41.9599,
    '1100_798': 41.923,
    '1100_799': 41.8752,
    '1100_800': 41.8245,
    '1100_801': 41.7715,
    '1100_802': 41.6892,
    '1100_803': 41.6007,
    '1100_804': 41.5274,
    '1100_805': 41.4572,
    '1100_806': 41.4023,
    '1100_807': 41.3675,
    '1100_808': 41.3492,
    '1100_809': 41.3324,
    '1100_810': 41.3074,
    '1100_811': 41.2957,
    '1100_812': 41.3274,
    '1100_813': 41.3974,
    '1100_814': 41.4995,
    '1100_815': 41.6256,
    '1100_816': 41.7634,
    '1100_817': 41.9054,
    '1100_818': 42.0269,
    '1100_819': 42.1234,
    '1100_820': 42.2126,
    '1100_821': 42.2741,
    '1100_822': 42.2846,
    '1100_823': 42.2567,
    '1100_824': 42.2032,
    '1100_825': 42.1447,
    '1100_826': 42.0812,
    '1100_827': 42.0239,
    '1100_828': 41.9743,
    '1100_829': 41.9239,
    '1100_830': 41.8638,
    '1100_831': 41.8141,
    '1100_832': 41.7838,
    '1100_833': 41.7691,
    '1100_834': 41.7565,
    '1100_835': 41.7511,
    '1100_836': 41.7497,
    '1100_837': 41.7474,
    '1100_838': 41.7474,
    '1100_839': 41.7489,
    '1100_840': 41.7328,
    '1100_841': 41.695,
    '1100_842': 41.6419,
    '1100_843': 41.5882,
    '1100_844': 41.5403,
    '1100_845': 41.5016,
    '1100_846': 41.4768,
    '1100_847': 41.4671,
    '1100_848': 41.4661,
    '1100_849': 41.4698,
    '1100_850': 41.472,
    '1100_851': 41.4698,
    '1100_852': 41.4623,
    '1100_853': 41.4479,
    '1100_854': 41.4238,
    '1100_855': 41.3824,
    '1100_856': 41.3329,
    '1100_857': 41.2742,
    '1100_858': 41.2076,
    '1100_859': 41.1362,
    '1100_860': 41.0509,
    '1100_861': 40.9228,
    '1100_862': 40.7685,
    '1100_863': 40.6153,
    '1100_864': 40.457,
    '1100_865': 40.2961,
    '1100_866': 40.1289,
    '1100_867': 39.9533,
    '1100_868': 39.7669,
    '1100_869': 39.5725,
    '1100_870': 39.3641,
    '1100_871': 39.1465,
    '1100_872': 38.9206,
    '1100_873': 38.6864,
    '1100_874': 38.4457,
    '1100_875': 38.1944,
    '1100_876': 37.9385,
    '1100_877': 37.6825,
    '1100_878': 37.4248,
    '1100_879': 37.1598,
    '1100_880': 36.9128,
    '1100_881': 36.6591,
    '1100_882': 36.4035,
    '1100_883': 36.1483,
    '1100_884': 35.8923,
    '1100_885': 35.6346,
    '1100_886': 35.3734,
    '1100_887': 35.1073,
    '1100_888': 34.8331,
    '1100_889': 34.545,
    '1100_890': 34.2619,
    '1100_891': 33.9809,
    '1100_892': 33.6974,
    '1100_893': 33.4099,
    '1100_894': 33.1188,
    '1100_895': 32.8245,
    '1100_896': 32.5286,
    '1100_897': 32.2316,
    '1100_898': 31.9323,
    '1100_899': 31.6296,
    '1100_900': 31.3229,
    '1101_727': 36.4176,
    '1101_728': 36.5463,
    '1101_729': 36.6672,
    '1101_730': 36.7813,
    '1101_731': 36.8919,
    '1101_732': 36.9998,
    '1101_733': 37.1025,
    '1101_734': 37.2111,
    '1101_735': 37.3134,
    '1101_736': 37.4043,
    '1101_737': 37.4875,
    '1101_738': 37.554,
    '1101_739': 37.6061,
    '1101_740': 37.6325,
    '1101_741': 37.623,
    '1101_742': 37.581,
    '1101_743': 37.5155,
    '1101_744': 37.4564,
    '1101_774': 38.9713,
    '1101_775': 39.0961,
    '1101_776': 39.2368,
    '1101_777': 39.3946,
    '1101_778': 39.5698,
    '1101_779': 39.7611,
    '1101_780': 39.9244,
    '1101_781': 40.0459,
    '1101_782': 40.141,
    '1101_783': 40.2726,
    '1101_784': 40.4232,
    '1101_785': 40.5857,
    '1101_786': 40.7526,
    '1101_787': 40.9188,
    '1101_788': 41.0683,
    '1101_789': 41.2111,
    '1101_790': 41.3685,
    '1101_791': 41.5176,
    '1101_792': 41.6512,
    '1101_793': 41.7704,
    '1101_794': 41.8563,
    '1101_795': 41.9152,
    '1101_796': 41.9346,
    '1101_797': 41.922,
    '1101_798': 41.8847,
    '1101_799': 41.8319,
    '1101_800': 41.7817,
    '1101_801': 41.7277,
    '1101_802': 41.6446,
    '1101_803': 41.5615,
    '1101_804': 41.4932,
    '1101_805': 41.4316,
    '1101_806': 41.3749,
    '1101_807': 41.3357,
    '1101_808': 41.3124,
    '1101_809': 41.2967,
    '1101_810': 41.2783,
    '1101_811': 41.2681,
    '1101_812': 41.2946,
    '1101_813': 41.3586,
    '1101_814': 41.4566,
    '1101_815': 41.5817,
    '1101_816': 41.7204,
    '1101_817': 41.8604,
    '1101_818': 41.9777,
    '1101_819': 42.0732,
    '1101_820': 42.1675,
    '1101_821': 42.2439,
    '1101_822': 42.2735,
    '1101_823': 42.2606,
    '1101_824': 42.2227,
    '1101_825': 42.173,
    '1101_826': 42.1104,
    '1101_827': 42.0479,
    '1101_828': 41.9961,
    '1101_829': 41.9383,
    '1101_830': 41.8754,
    '1101_831': 41.8215,
    '1101_832': 41.7874,
    '1101_833': 41.7687,
    '1101_834': 41.7511,
    '1101_835': 41.7413,
    '1101_836': 41.7366,
    '1101_837': 41.732,
    '1101_838': 41.7283,
    '1101_839': 41.7247,
    '1101_840': 41.7155,
    '1101_841': 41.6855,
    '1101_842': 41.6338,
    '1101_843': 41.5748,
    '1101_844': 41.5234,
    '1101_845': 41.4838,
    '1101_846': 41.4596,
    '1101_847': 41.4493,
    '1101_848': 41.4494,
    '1101_849': 41.4564,
    '1101_850': 41.4646,
    '1101_851': 41.4696,
    '1101_852': 41.4691,
    '1101_853': 41.4611,
    '1101_854': 41.4419,
    '1101_855': 41.4077,
    '1101_856': 41.3633,
    '1101_857': 41.3092,
    '1101_858': 41.2449,
    '1101_859': 41.1725,
    '1101_860': 41.0796,
    '1101_861': 40.9487,
    '1101_862': 40.8004,
    '1101_863': 40.6505,
    '1101_864': 40.4956,
    '1101_865': 40.3411,
    '1101_866': 40.1789,
    '1101_867': 40.0033,
    '1101_868': 39.8148,
    '1101_869': 39.618,
    '1101_870': 39.4124,
    '1101_871': 39.197,
    '1101_872': 38.9711,
    '1101_873': 38.737,
    '1101_874': 38.4955,
    '1101_875': 38.2433,
    '1101_876': 37.9868,
    '1101_877': 37.7305,
    '1101_878': 37.4712,
    '1101_879': 37.2097,
    '1101_880': 36.9576,
    '1101_881': 36.7056,
    '1101_882': 36.4534,
    '1101_883': 36.2015,
    '1101_884': 35.949,
    '1101_885': 35.6942,
    '1101_886': 35.435,
    '1101_887': 35.1708,
    '1101_888': 34.8996,
    '1101_889': 34.6195,
    '1101_890': 34.3385,
    '1101_891': 34.0569,
    '1101_892': 33.7727,
    '1101_893': 33.4854,
    '1101_894': 33.1951,
    '1101_895': 32.9024,
    '1101_896': 32.6085,
    '1101_897': 32.3134,
    '1101_898': 32.016,
    '1101_899': 31.7149,
    '1101_900': 31.4094,
    '1102_727': 36.4094,
    '1102_728': 36.5316,
    '1102_729': 36.6459,
    '1102_730': 36.7525,
    '1102_731': 36.8543,
    '1102_732': 36.953,
    '1102_733': 37.0508,
    '1102_734': 37.15,
    '1102_735': 37.24,
    '1102_736': 37.3261,
    '1102_737': 37.4073,
    '1102_738': 37.4708,
    '1102_739': 37.5246,
    '1102_740': 37.5542,
    '1102_741': 37.555,
    '1102_742': 37.5282,
    '1102_743': 37.4824,
    '1102_744': 37.4327,
    '1102_774': 38.9696,
    '1102_775': 39.0887,
    '1102_776': 39.2252,
    '1102_777': 39.3781,
    '1102_778': 39.5464,
    '1102_779': 39.7338,
    '1102_780': 39.905,
    '1102_781': 40.0374,
    '1102_782': 40.1391,
    '1102_783': 40.2699,
    '1102_784': 40.4158,
    '1102_785': 40.5635,
    '1102_786': 40.7181,
    '1102_787': 40.886,
    '1102_788': 41.0437,
    '1102_789': 41.1966,
    '1102_790': 41.3517,
    '1102_791': 41.4948,
    '1102_792': 41.6229,
    '1102_793': 41.7387,
    '1102_794': 41.8161,
    '1102_795': 41.8738,
    '1102_796': 41.8944,
    '1102_797': 41.8844,
    '1102_798': 41.8503,
    '1102_799': 41.7956,
    '1102_800': 41.7428,
    '1102_801': 41.6897,
    '1102_802': 41.6084,
    '1102_803': 41.5315,
    '1102_804': 41.4664,
    '1102_805': 41.414,
    '1102_806': 41.3571,
    '1102_807': 41.3119,
    '1102_808': 41.2846,
    '1102_809': 41.2686,
    '1102_810': 41.2542,
    '1102_811': 41.2492,
    '1102_812': 41.2737,
    '1102_813': 41.3319,
    '1102_814': 41.422,
    '1102_815': 41.5373,
    '1102_816': 41.6669,
    '1102_817': 41.8003,
    '1102_818': 41.921,
    '1102_819': 42.0262,
    '1102_820': 42.1321,
    '1102_821': 42.2187,
    '1102_822': 42.2613,
    '1102_823': 42.2662,
    '1102_824': 42.2456,
    '1102_825': 42.1996,
    '1102_826': 42.1387,
    '1102_827': 42.0793,
    '1102_828': 42.0245,
    '1102_829': 41.96,
    '1102_830': 41.8934,
    '1102_831': 41.8371,
    '1102_832': 41.7985,
    '1102_833': 41.7703,
    '1102_834': 41.7426,
    '1102_835': 41.7263,
    '1102_836': 41.7178,
    '1102_837': 41.7117,
    '1102_838': 41.7067,
    '1102_839': 41.7003,
    '1102_840': 41.6851,
    '1102_841': 41.6615,
    '1102_842': 41.6163,
    '1102_843': 41.5536,
    '1102_844': 41.4994,
    '1102_845': 41.4599,
    '1102_846': 41.4367,
    '1102_847': 41.4277,
    '1102_848': 41.4304,
    '1102_849': 41.4418,
    '1102_850': 41.4562,
    '1102_851': 41.4682,
    '1102_852': 41.4743,
    '1102_853': 41.4722,
    '1102_854': 41.4585,
    '1102_855': 41.4312,
    '1102_856': 41.3915,
    '1102_857': 41.3398,
    '1102_858': 41.2771,
    '1102_859': 41.198,
    '1102_860': 41.097,
    '1102_861': 40.9718,
    '1102_862': 40.8316,
    '1102_863': 40.6861,
    '1102_864': 40.5359,
    '1102_865': 40.3837,
    '1102_866': 40.2249,
    '1102_867': 40.0481,
    '1102_868': 39.8566,
    '1102_869': 39.6588,
    '1102_870': 39.4559,
    '1102_871': 39.2433,
    '1102_872': 39.017,
    '1102_873': 38.7819,
    '1102_874': 38.5385,
    '1102_875': 38.2867,
    '1102_876': 38.0299,
    '1102_877': 37.7757,
    '1102_878': 37.5155,
    '1102_879': 37.2544,
    '1102_880': 37.0014,
    '1102_881': 36.7508,
    '1102_882': 36.5021,
    '1102_883': 36.2537,
    '1102_884': 36.0051,
    '1102_885': 35.7535,
    '1102_886': 35.4962,
    '1102_887': 35.2347,
    '1102_888': 34.9682,
    '1102_889': 34.6946,
    '1102_890': 34.4157,
    '1102_891': 34.1331,
    '1102_892': 33.8475,
    '1102_893': 33.5596,
    '1102_894': 33.2693,
    '1102_895': 32.9768,
    '1102_896': 32.6841,
    '1102_897': 32.3909,
    '1102_898': 32.0952,
    '1102_899': 31.7964,
    '1102_900': 31.4933,
    '1103_727': 36.3955,
    '1103_728': 36.5109,
    '1103_729': 36.617,
    '1103_730': 36.7183,
    '1103_731': 36.8148,
    '1103_732': 36.9094,
    '1103_733': 37.0013,
    '1103_734': 37.0947,
    '1103_735': 37.1823,
    '1103_736': 37.2653,
    '1103_737': 37.3377,
    '1103_738': 37.4,
    '1103_739': 37.4538,
    '1103_740': 37.4909,
    '1103_741': 37.497,
    '1103_742': 37.4828,
    '1103_743': 37.451,
    '1103_744': 37.4117,
    '1103_774': 38.9625,
    '1103_775': 39.0787,
    '1103_776': 39.2094,
    '1103_777': 39.3559,
    '1103_778': 39.5163,
    '1103_779': 39.6985,
    '1103_780': 39.873,
    '1103_781': 40.0149,
    '1103_782': 40.1245,
    '1103_783': 40.2546,
    '1103_784': 40.3999,
    '1103_785': 40.539,
    '1103_786': 40.6872,
    '1103_787': 40.8507,
    '1103_788': 41.0134,
    '1103_789': 41.166,
    '1103_790': 41.3135,
    '1103_791': 41.4493,
    '1103_792': 41.5741,
    '1103_793': 41.6834,
    '1103_794': 41.7631,
    '1103_795': 41.8209,
    '1103_796': 41.8481,
    '1103_797': 41.8529,
    '1103_798': 41.8229,
    '1103_799': 41.7651,
    '1103_800': 41.7088,
    '1103_801': 41.6604,
    '1103_802': 41.5856,
    '1103_803': 41.5154,
    '1103_804': 41.4544,
    '1103_805': 41.4034,
    '1103_806': 41.349,
    '1103_807': 41.3034,
    '1103_808': 41.2727,
    '1103_809': 41.2546,
    '1103_810': 41.2378,
    '1103_811': 41.2372,
    '1103_812': 41.2615,
    '1103_813': 41.314,
    '1103_814': 41.3917,
    '1103_815': 41.4873,
    '1103_816': 41.6015,
    '1103_817': 41.7282,
    '1103_818': 41.8605,
    '1103_819': 41.9842,
    '1103_820': 42.0979,
    '1103_821': 42.191,
    '1103_822': 42.2469,
    '1103_823': 42.2708,
    '1103_824': 42.2648,
    '1103_825': 42.2272,
    '1103_826': 42.1771,
    '1103_827': 42.1265,
    '1103_828': 42.0675,
    '1103_829': 41.9943,
    '1103_830': 41.9189,
    '1103_831': 41.8544,
    '1103_832': 41.8061,
    '1103_833': 41.7675,
    '1103_834': 41.7289,
    '1103_835': 41.7046,
    '1103_836': 41.6929,
    '1103_837': 41.6859,
    '1103_838': 41.6811,
    '1103_839': 41.673,
    '1103_840': 41.6524,
    '1103_841': 41.6231,
    '1103_842': 41.5802,
    '1103_843': 41.5235,
    '1103_844': 41.4702,
    '1103_845': 41.4358,
    '1103_846': 41.4172,
    '1103_847': 41.4072,
    '1103_848': 41.412,
    '1103_849': 41.4283,
    '1103_850': 41.4492,
    '1103_851': 41.4689,
    '1103_852': 41.4816,
    '1103_853': 41.485,
    '1103_854': 41.4782,
    '1103_855': 41.4601,
    '1103_856': 41.4224,
    '1103_857': 41.3735,
    '1103_858': 41.3129,
    '1103_859': 41.225,
    '1103_860': 41.1179,
    '1103_861': 40.9959,
    '1103_862': 40.8604,
    '1103_863': 40.7171,
    '1103_864': 40.5677,
    '1103_865': 40.4124,
    '1103_866': 40.2491,
    '1103_867': 40.0721,
    '1103_868': 39.8841,
    '1103_869': 39.691,
    '1103_870': 39.4919,
    '1103_871': 39.2805,
    '1103_872': 39.0556,
    '1103_873': 38.8216,
    '1103_874': 38.5786,
    '1103_875': 38.3282,
    '1103_876': 38.0733,
    '1103_877': 37.8172,
    '1103_878': 37.558,
    '1103_879': 37.3006,
    '1103_880': 37.0489,
    '1103_881': 36.7997,
    '1103_882': 36.5531,
    '1103_883': 36.3095,
    '1103_884': 36.0625,
    '1103_885': 35.8133,
    '1103_886': 35.5576,
    '1103_887': 35.2989,
    '1103_888': 35.0373,
    '1103_889': 34.7697,
    '1103_890': 34.4906,
    '1103_891': 34.2073,
    '1103_892': 33.9208,
    '1103_893': 33.6318,
    '1103_894': 33.34,
    '1103_895': 33.0466,
    '1103_896': 32.7529,
    '1103_897': 32.4584,
    '1103_898': 32.1626,
    '1103_899': 31.8654,
    '1103_900': 31.5633,
    '1104_767': 38.735,
    '1104_768': 38.7293,
    '1104_769': 38.7194,
    '1104_770': 38.7136,
    '1104_771': 38.7147,
    '1104_772': 38.7208,
    '1104_774': 38.9496,
    '1104_775': 39.0625,
    '1104_776': 39.1879,
    '1104_777': 39.328,
    '1104_778': 39.4804,
    '1104_779': 39.6529,
    '1104_780': 39.8255,
    '1104_781': 39.9716,
    '1104_782': 40.0913,
    '1104_783': 40.2194,
    '1104_784': 40.3662,
    '1104_785': 40.5096,
    '1104_786': 40.6615,
    '1104_787': 40.8211,
    '1104_788': 40.9768,
    '1104_789': 41.12,
    '1104_790': 41.2524,
    '1104_791': 41.3799,
    '1104_792': 41.5049,
    '1104_793': 41.6155,
    '1104_794': 41.7013,
    '1104_795': 41.7631,
    '1104_796': 41.8048,
    '1104_797': 41.8157,
    '1104_798': 41.7913,
    '1104_799': 41.7385,
    '1104_800': 41.6833,
    '1104_801': 41.6391,
    '1104_802': 41.5739,
    '1104_803': 41.5083,
    '1104_804': 41.4551,
    '1104_805': 41.404,
    '1104_806': 41.3527,
    '1104_807': 41.3074,
    '1104_808': 41.2774,
    '1104_809': 41.2549,
    '1104_810': 41.2387,
    '1104_811': 41.2374,
    '1104_812': 41.256,
    '1104_813': 41.3023,
    '1104_814': 41.3693,
    '1104_815': 41.4515,
    '1104_816': 41.5559,
    '1104_817': 41.6768,
    '1104_818': 41.8109,
    '1104_819': 41.9492,
    '1104_820': 42.0747,
    '1104_821': 42.1735,
    '1104_822': 42.2411,
    '1104_823': 42.2786,
    '1104_824': 42.2822,
    '1104_825': 42.2577,
    '1104_826': 42.2175,
    '1104_827': 42.1716,
    '1104_828': 42.1125,
    '1104_829': 42.034,
    '1104_830': 41.9477,
    '1104_831': 41.8677,
    '1104_832': 41.8035,
    '1104_833': 41.7558,
    '1104_834': 41.7098,
    '1104_835': 41.6799,
    '1104_836': 41.6627,
    '1104_837': 41.6547,
    '1104_838': 41.6461,
    '1104_839': 41.633,
    '1104_840': 41.6097,
    '1104_841': 41.5785,
    '1104_842': 41.5402,
    '1104_843': 41.491,
    '1104_844': 41.4467,
    '1104_845': 41.4173,
    '1104_846': 41.4015,
    '1104_847': 41.3944,
    '1104_848': 41.3978,
    '1104_849': 41.4182,
    '1104_850': 41.4472,
    '1104_851': 41.4754,
    '1104_852': 41.4942,
    '1104_853': 41.5023,
    '1104_854': 41.4991,
    '1104_855': 41.4888,
    '1104_856': 41.4588,
    '1104_857': 41.4113,
    '1104_858': 41.3487,
    '1104_859': 41.2473,
    '1104_860': 41.1393,
    '1104_861': 41.0192,
    '1104_862': 40.8858,
    '1104_863': 40.7429,
    '1104_864': 40.5925,
    '1104_865': 40.434,
    '1104_866': 40.2672,
    '1104_867': 40.0891,
    '1104_868': 39.9029,
    '1104_869': 39.7155,
    '1104_870': 39.5227,
    '1104_871': 39.3125,
    '1104_872': 39.0873,
    '1104_873': 38.8555,
    '1104_874': 38.614,
    '1104_875': 38.3648,
    '1104_876': 38.1103,
    '1104_877': 37.854,
    '1104_878': 37.595,
    '1104_879': 37.3427,
    '1104_880': 37.0988,
    '1104_881': 36.8465,
    '1104_882': 36.5988,
    '1104_883': 36.36,
    '1104_884': 36.1137,
    '1104_885': 35.8687,
    '1104_886': 35.6143,
    '1104_887': 35.3586,
    '1104_888': 35.1008,
    '1104_889': 34.8347,
    '1104_890': 34.5588,
    '1104_891': 34.2781,
    '1104_892': 33.992,
    '1104_893': 33.7024,
    '1104_894': 33.4093,
    '1104_895': 33.1138,
    '1104_896': 32.8185,
    '1104_897': 32.5209,
    '1104_898': 32.2217,
    '1104_899': 31.9231,
    '1104_900': 31.6182,
    '1105_767': 38.7698,
    '1105_768': 38.7599,
    '1105_769': 38.7455,
    '1105_770': 38.7372,
    '1105_771': 38.7337,
    '1105_772': 38.7343,
    '1105_774': 38.9315,
    '1105_775': 39.0401,
    '1105_776': 39.1603,
    '1105_777': 39.2947,
    '1105_778': 39.4401,
    '1105_779': 39.6017,
    '1105_780': 39.7678,
    '1105_781': 39.9152,
    '1105_782': 40.0403,
    '1105_783': 40.168,
    '1105_784': 40.3152,
    '1105_785': 40.4698,
    '1105_786': 40.6263,
    '1105_787': 40.7792,
    '1105_788': 40.9264,
    '1105_789': 41.061,
    '1105_790': 41.1819,
    '1105_791': 41.3003,
    '1105_792': 41.4256,
    '1105_793': 41.5437,
    '1105_794': 41.6362,
    '1105_795': 41.7067,
    '1105_796': 41.7578,
    '1105_797': 41.7801,
    '1105_798': 41.7693,
    '1105_799': 41.7205,
    '1105_800': 41.6685,
    '1105_801': 41.629,
    '1105_802': 41.5728,
    '1105_803': 41.5106,
    '1105_804': 41.4642,
    '1105_805': 41.416,
    '1105_806': 41.3698,
    '1105_807': 41.3315,
    '1105_808': 41.3048,
    '1105_809': 41.2801,
    '1105_810': 41.2613,
    '1105_811': 41.2495,
    '1105_812': 41.2569,
    '1105_813': 41.2933,
    '1105_814': 41.352,
    '1105_815': 41.4268,
    '1105_816': 41.5272,
    '1105_817': 41.6477,
    '1105_818': 41.7808,
    '1105_819': 41.9258,
    '1105_820': 42.0632,
    '1105_821': 42.1709,
    '1105_822': 42.2463,
    '1105_823': 42.288,
    '1105_824': 42.2985,
    '1105_825': 42.2834,
    '1105_826': 42.2457,
    '1105_827': 42.2017,
    '1105_828': 42.1384,
    '1105_829': 42.0593,
    '1105_830': 41.9614,
    '1105_831': 41.8637,
    '1105_832': 41.7836,
    '1105_833': 41.731,
    '1105_834': 41.6886,
    '1105_835': 41.6541,
    '1105_836': 41.6314,
    '1105_837': 41.6205,
    '1105_838': 41.6073,
    '1105_839': 41.5893,
    '1105_840': 41.5627,
    '1105_841': 41.5321,
    '1105_842': 41.4975,
    '1105_843': 41.4581,
    '1105_844': 41.4231,
    '1105_845': 41.3992,
    '1105_846': 41.3891,
    '1105_847': 41.3889,
    '1105_848': 41.3942,
    '1105_849': 41.4161,
    '1105_850': 41.452,
    '1105_851': 41.4892,
    '1105_852': 41.5146,
    '1105_853': 41.527,
    '1105_854': 41.5214,
    '1105_855': 41.5066,
    '1105_856': 41.4794,
    '1105_857': 41.4389,
    '1105_858': 41.3753,
    '1105_859': 41.2713,
    '1105_860': 41.1605,
    '1105_861': 41.0408,
    '1105_862': 40.9083,
    '1105_863': 40.7651,
    '1105_864': 40.6136,
    '1105_865': 40.4542,
    '1105_866': 40.2875,
    '1105_867': 40.1087,
    '1105_868': 39.9221,
    '1105_869': 39.7415,
    '1105_870': 39.5488,
    '1105_871': 39.3366,
    '1105_872': 39.1121,
    '1105_873': 38.8805,
    '1105_874': 38.6404,
    '1105_875': 38.3951,
    '1105_876': 38.1364,
    '1105_877': 37.8798,
    '1105_878': 37.6252,
    '1105_879': 37.3751,
    '1105_880': 37.1308,
    '1105_881': 36.8827,
    '1105_882': 36.6326,
    '1105_883': 36.3924,
    '1105_884': 36.1514,
    '1105_885': 35.9107,
    '1105_886': 35.6644,
    '1105_887': 35.4148,
    '1105_888': 35.1626,
    '1105_889': 34.8982,
    '1105_890': 34.6243,
    '1105_891': 34.3477,
    '1105_892': 34.0624,
    '1105_893': 33.7742,
    '1105_894': 33.4805,
    '1105_895': 33.1832,
    '1105_896': 32.8871,
    '1105_897': 32.5864,
    '1105_898': 32.281,
    '1105_899': 31.9774,
    '1105_900': 31.673,
    '1106_767': 38.7916,
    '1106_768': 38.7902,
    '1106_769': 38.7833,
    '1106_770': 38.7706,
    '1106_771': 38.7502,
    '1106_772': 38.741,
    '1106_774': 38.9034,
    '1106_775': 39.007,
    '1106_776': 39.122,
    '1106_777': 39.2493,
    '1106_778': 39.3906,
    '1106_779': 39.5436,
    '1106_780': 39.7063,
    '1106_781': 39.8499,
    '1106_782': 39.9808,
    '1106_783': 40.1122,
    '1106_784': 40.2552,
    '1106_785': 40.416,
    '1106_786': 40.5712,
    '1106_787': 40.717,
    '1106_788': 40.859,
    '1106_789': 40.9908,
    '1106_790': 41.1033,
    '1106_791': 41.2185,
    '1106_792': 41.3459,
    '1106_793': 41.4691,
    '1106_794': 41.5681,
    '1106_795': 41.6431,
    '1106_796': 41.7059,
    '1106_797': 41.744,
    '1106_798': 41.7384,
    '1106_799': 41.6998,
    '1106_800': 41.6482,
    '1106_801': 41.6151,
    '1106_802': 41.5743,
    '1106_803': 41.5192,
    '1106_804': 41.476,
    '1106_805': 41.4359,
    '1106_806': 41.4007,
    '1106_807': 41.3737,
    '1106_808': 41.3457,
    '1106_809': 41.3176,
    '1106_810': 41.2949,
    '1106_811': 41.2731,
    '1106_812': 41.2634,
    '1106_813': 41.2863,
    '1106_814': 41.3358,
    '1106_815': 41.4072,
    '1106_816': 41.5105,
    '1106_817': 41.6389,
    '1106_818': 41.7769,
    '1106_819': 41.9187,
    '1106_820': 42.0544,
    '1106_821': 42.1674,
    '1106_822': 42.2445,
    '1106_823': 42.2847,
    '1106_824': 42.2982,
    '1106_825': 42.2888,
    '1106_826': 42.251,
    '1106_827': 42.2013,
    '1106_828': 42.1336,
    '1106_829': 42.0473,
    '1106_830': 41.9478,
    '1106_831': 41.8502,
    '1106_832': 41.7605,
    '1106_833': 41.7036,
    '1106_834': 41.6601,
    '1106_835': 41.6223,
    '1106_836': 41.5964,
    '1106_837': 41.5809,
    '1106_838': 41.5653,
    '1106_839': 41.5411,
    '1106_840': 41.5114,
    '1106_841': 41.4803,
    '1106_842': 41.45,
    '1106_843': 41.4203,
    '1106_844': 41.3966,
    '1106_845': 41.3808,
    '1106_846': 41.378,
    '1106_847': 41.383,
    '1106_848': 41.3933,
    '1106_849': 41.4189,
    '1106_850': 41.4611,
    '1106_851': 41.5044,
    '1106_852': 41.5341,
    '1106_853': 41.5519,
    '1106_854': 41.5516,
    '1106_855': 41.53,
    '1106_856': 41.5001,
    '1106_857': 41.4558,
    '1106_858': 41.3884,
    '1106_859': 41.2897,
    '1106_860': 41.1793,
    '1106_861': 41.0599,
    '1106_862': 40.9261,
    '1106_863': 40.7817,
    '1106_864': 40.6294,
    '1106_865': 40.4715,
    '1106_866': 40.3107,
    '1106_867': 40.1367,
    '1106_868': 39.9549,
    '1106_869': 39.7703,
    '1106_870': 39.5757,
    '1106_871': 39.3734,
    '1106_872': 39.1475,
    '1106_873': 38.9125,
    '1106_874': 38.6713,
    '1106_875': 38.4291,
    '1106_876': 38.1756,
    '1106_877': 37.9242,
    '1106_878': 37.6738,
    '1106_879': 37.4285,
    '1106_880': 37.1825,
    '1106_881': 36.9305,
    '1106_882': 36.6849,
    '1106_883': 36.448,
    '1106_884': 36.2074,
    '1106_885': 35.9678,
    '1106_886': 35.7227,
    '1106_887': 35.4731,
    '1106_888': 35.2192,
    '1106_889': 34.9575,
    '1106_890': 34.688,
    '1106_891': 34.4118,
    '1106_892': 34.13,
    '1106_893': 33.8441,
    '1106_894': 33.5543,
    '1106_895': 33.2585,
    '1106_896': 32.9608,
    '1106_897': 32.6619,
    '1106_898': 32.3582,
    '1106_899': 32.0511,
    '1106_900': 31.7457,
    '1107_767': 38.8,
    '1107_768': 38.8048,
    '1107_769': 38.803,
    '1107_770': 38.7821,
    '1107_771': 38.7534,
    '1107_772': 38.7391,
    '1107_774': 38.8688,
    '1107_775': 38.9671,
    '1107_776': 39.0769,
    '1107_777': 39.199,
    '1107_778': 39.3359,
    '1107_779': 39.4831,
    '1107_780': 39.6412,
    '1107_781': 39.7928,
    '1107_782': 39.9272,
    '1107_783': 40.0592,
    '1107_784': 40.1984,
    '1107_785': 40.3539,
    '1107_786': 40.5029,
    '1107_787': 40.6458,
    '1107_788': 40.7923,
    '1107_789': 40.9217,
    '1107_790': 41.0263,
    '1107_791': 41.14,
    '1107_792': 41.2675,
    '1107_793': 41.3901,
    '1107_794': 41.4923,
    '1107_795': 41.5754,
    '1107_796': 41.6489,
    '1107_797': 41.6957,
    '1107_798': 41.7037,
    '1107_799': 41.677,
    '1107_800': 41.6315,
    '1107_801': 41.6126,
    '1107_802': 41.5907,
    '1107_803': 41.5489,
    '1107_804': 41.5075,
    '1107_805': 41.4749,
    '1107_806': 41.4469,
    '1107_807': 41.4255,
    '1107_808': 41.3946,
    '1107_809': 41.3649,
    '1107_810': 41.3392,
    '1107_811': 41.3078,
    '1107_812': 41.2816,
    '1107_813': 41.2883,
    '1107_814': 41.3268,
    '1107_815': 41.3964,
    '1107_816': 41.504,
    '1107_817': 41.6422,
    '1107_818': 41.7812,
    '1107_819': 41.9123,
    '1107_820': 42.0418,
    '1107_821': 42.1521,
    '1107_822': 42.2307,
    '1107_823': 42.2705,
    '1107_824': 42.2847,
    '1107_825': 42.2668,
    '1107_826': 42.2335,
    '1107_827': 42.1758,
    '1107_828': 42.1001,
    '1107_829': 42.0092,
    '1107_830': 41.9123,
    '1107_831': 41.8234,
    '1107_832': 41.7316,
    '1107_833': 41.6692,
    '1107_834': 41.6252,
    '1107_835': 41.5893,
    '1107_836': 41.5629,
    '1107_837': 41.5442,
    '1107_838': 41.5239,
    '1107_839': 41.4969,
    '1107_840': 41.4651,
    '1107_841': 41.4364,
    '1107_842': 41.4123,
    '1107_843': 41.3914,
    '1107_844': 41.3754,
    '1107_845': 41.3669,
    '1107_846': 41.3703,
    '1107_847': 41.3827,
    '1107_848': 41.4,
    '1107_849': 41.4322,
    '1107_850': 41.4814,
    '1107_851': 41.5238,
    '1107_852': 41.5543,
    '1107_853': 41.5709,
    '1107_854': 41.5693,
    '1107_855': 41.5562,
    '1107_856': 41.5348,
    '1107_857': 41.4877,
    '1107_858': 41.41,
    '1107_859': 41.3172,
    '1107_860': 41.2017,
    '1107_861': 41.0796,
    '1107_862': 40.9438,
    '1107_863': 40.7979,
    '1107_864': 40.645,
    '1107_865': 40.4888,
    '1107_866': 40.3388,
    '1107_867': 40.1697,
    '1107_868': 39.9898,
    '1107_869': 39.8019,
    '1107_870': 39.6045,
    '1107_871': 39.3985,
    '1107_872': 39.179,
    '1107_873': 38.9463,
    '1107_874': 38.7053,
    '1107_875': 38.4571,
    '1107_876': 38.21,
    '1107_877': 37.9638,
    '1107_878': 37.7172,
    '1107_879': 37.4715,
    '1107_880': 37.2238,
    '1107_881': 36.9743,
    '1107_882': 36.7343,
    '1107_883': 36.4988,
    '1107_884': 36.2615,
    '1107_885': 36.0224,
    '1107_886': 35.7799,
    '1107_887': 35.5332,
    '1107_888': 35.2816,
    '1107_889': 35.0224,
    '1107_890': 34.7539,
    '1107_891': 34.477,
    '1107_892': 34.1956,
    '1107_893': 33.9102,
    '1107_894': 33.6207,
    '1107_895': 33.3258,
    '1107_896': 33.0269,
    '1107_897': 32.7255,
    '1107_898': 32.4217,
    '1107_899': 32.1146,
    '1107_900': 31.8045,
    '1108_767': 38.7947,
    '1108_768': 38.8013,
    '1108_769': 38.7994,
    '1108_770': 38.7797,
    '1108_771': 38.7471,
    '1108_772': 38.7286,
    '1108_774': 38.8263,
    '1108_775': 38.9194,
    '1108_776': 39.0239,
    '1108_777': 39.1409,
    '1108_778': 39.272,
    '1108_779': 39.4177,
    '1108_780': 39.5726,
    '1108_781': 39.7274,
    '1108_782': 39.8622,
    '1108_783': 40.001,
    '1108_784': 40.1378,
    '1108_785': 40.284,
    '1108_786': 40.4315,
    '1108_787': 40.583,
    '1108_788': 40.733,
    '1108_789': 40.8549,
    '1108_790': 40.952,
    '1108_791': 41.0574,
    '1108_792': 41.1851,
    '1108_793': 41.308,
    '1108_794': 41.4128,
    '1108_795': 41.5027,
    '1108_796': 41.5857,
    '1108_797': 41.6459,
    '1108_798': 41.6681,
    '1108_799': 41.6537,
    '1108_800': 41.6287,
    '1108_801': 41.6268,
    '1108_802': 41.6207,
    '1108_803': 41.5929,
    '1108_804': 41.5534,
    '1108_805': 41.5223,
    '1108_806': 41.4949,
    '1108_807': 41.4768,
    '1108_808': 41.4511,
    '1108_809': 41.4158,
    '1108_810': 41.3787,
    '1108_811': 41.3334,
    '1108_812': 41.2972,
    '1108_813': 41.2934,
    '1108_814': 41.3213,
    '1108_815': 41.3854,
    '1108_816': 41.4951,
    '1108_817': 41.64,
    '1108_818': 41.7793,
    '1108_819': 41.9025,
    '1108_820': 42.0194,
    '1108_821': 42.1209,
    '1108_822': 42.1973,
    '1108_823': 42.238,
    '1108_824': 42.2443,
    '1108_825': 42.2244,
    '1108_826': 42.1831,
    '1108_827': 42.1245,
    '1108_828': 42.0449,
    '1108_829': 41.9524,
    '1108_830': 41.8633,
    '1108_831': 41.7804,
    '1108_832': 41.6968,
    '1108_833': 41.6341,
    '1108_834': 41.5868,
    '1108_835': 41.5523,
    '1108_836': 41.5286,
    '1108_837': 41.5075,
    '1108_838': 41.4823,
    '1108_839': 41.4541,
    '1108_840': 41.4271,
    '1108_841': 41.4037,
    '1108_842': 41.3852,
    '1108_843': 41.37,
    '1108_844': 41.3596,
    '1108_845': 41.3579,
    '1108_846': 41.3684,
    '1108_847': 41.3887,
    '1108_848': 41.4128,
    '1108_849': 41.4533,
    '1108_850': 41.5062,
    '1108_851': 41.5445,
    '1108_852': 41.5765,
    '1108_853': 41.6006,
    '1108_854': 41.5986,
    '1108_855': 41.5765,
    '1108_856': 41.5601,
    '1108_857': 41.519,
    '1108_858': 41.4399,
    '1108_859': 41.3388,
    '1108_860': 41.221,
    '1108_861': 41.0973,
    '1108_862': 40.9617,
    '1108_863': 40.8156,
    '1108_864': 40.6632,
    '1108_865': 40.5085,
    '1108_866': 40.3575,
    '1108_867': 40.1954,
    '1108_868': 40.0176,
    '1108_869': 39.8285,
    '1108_870': 39.6267,
    '1108_871': 39.4174,
    '1108_872': 39.2043,
    '1108_873': 38.9756,
    '1108_874': 38.7387,
    '1108_875': 38.4883,
    '1108_876': 38.2437,
    '1108_877': 38.0009,
    '1108_878': 37.7566,
    '1108_879': 37.512,
    '1108_880': 37.2658,
    '1108_881': 37.02,
    '1108_882': 36.783,
    '1108_883': 36.5487,
    '1108_884': 36.3132,
    '1108_885': 36.0754,
    '1108_886': 35.8352,
    '1108_887': 35.5913,
    '1108_888': 35.3422,
    '1108_889': 35.0845,
    '1108_890': 34.8138,
    '1108_891': 34.5373,
    '1108_892': 34.2566,
    '1108_893': 33.9714,
    '1108_894': 33.6818,
    '1108_895': 33.3869,
    '1108_896': 33.086,
    '1108_897': 32.7814,
    '1108_898': 32.4766,
    '1108_899': 32.1686,
    '1108_900': 31.8549,
    '1109_767': 38.7774,
    '1109_768': 38.7821,
    '1109_769': 38.7797,
    '1109_770': 38.7672,
    '1109_771': 38.7375,
    '1109_772': 38.7113,
    '1109_774': 38.7774,
    '1109_775': 38.865,
    '1109_776': 38.9639,
    '1109_777': 39.0758,
    '1109_778': 39.2036,
    '1109_779': 39.3462,
    '1109_780': 39.4954,
    '1109_781': 39.6493,
    '1109_782': 39.795,
    '1109_783': 39.9351,
    '1109_784': 40.0752,
    '1109_785': 40.2206,
    '1109_786': 40.3662,
    '1109_787': 40.5178,
    '1109_788': 40.6684,
    '1109_789': 40.791,
    '1109_790': 40.8817,
    '1109_791': 40.9745,
    '1109_792': 41.0957,
    '1109_793': 41.2201,
    '1109_794': 41.327,
    '1109_795': 41.4254,
    '1109_796': 41.5178,
    '1109_797': 41.5841,
    '1109_798': 41.6239,
    '1109_799': 41.6302,
    '1109_800': 41.6304,
    '1109_801': 41.6463,
    '1109_802': 41.6509,
    '1109_803': 41.6314,
    '1109_804': 41.5964,
    '1109_805': 41.5659,
    '1109_806': 41.5387,
    '1109_807': 41.5193,
    '1109_808': 41.4966,
    '1109_809': 41.4611,
    '1109_810': 41.412,
    '1109_811': 41.3593,
    '1109_812': 41.31,
    '1109_813': 41.2937,
    '1109_814': 41.3132,
    '1109_815': 41.3669,
    '1109_816': 41.4706,
    '1109_817': 41.6147,
    '1109_818': 41.7569,
    '1109_819': 41.8778,
    '1109_820': 41.9835,
    '1109_821': 42.0733,
    '1109_822': 42.1404,
    '1109_823': 42.178,
    '1109_824': 42.1822,
    '1109_825': 42.1579,
    '1109_826': 42.1089,
    '1109_827': 42.0524,
    '1109_828': 41.9799,
    '1109_829': 41.8975,
    '1109_830': 41.8136,
    '1109_831': 41.7319,
    '1109_832': 41.6533,
    '1109_833': 41.5902,
    '1109_834': 41.5448,
    '1109_835': 41.5115,
    '1109_836': 41.4889,
    '1109_837': 41.4666,
    '1109_838': 41.4418,
    '1109_839': 41.4171,
    '1109_840': 41.3965,
    '1109_841': 41.3791,
    '1109_842': 41.3633,
    '1109_843': 41.3523,
    '1109_844': 41.3478,
    '1109_845': 41.353,
    '1109_846': 41.3718,
    '1109_847': 41.4001,
    '1109_848': 41.4307,
    '1109_849': 41.4757,
    '1109_850': 41.5244,
    '1109_851': 41.5588,
    '1109_852': 41.5888,
    '1109_853': 41.6177,
    '1109_854': 41.6274,
    '1109_855': 41.6018,
    '1109_856': 41.5808,
    '1109_857': 41.5432,
    '1109_858': 41.4656,
    '1109_859': 41.3653,
    '1109_860': 41.2412,
    '1109_861': 41.116,
    '1109_862': 40.98,
    '1109_863': 40.8338,
    '1109_864': 40.6813,
    '1109_865': 40.5259,
    '1109_866': 40.373,
    '1109_867': 40.2115,
    '1109_868': 40.036,
    '1109_869': 39.8461,
    '1109_870': 39.6439,
    '1109_871': 39.4334,
    '1109_872': 39.2154,
    '1109_873': 38.992,
    '1109_874': 38.7588,
    '1109_875': 38.5161,
    '1109_876': 38.2765,
    '1109_877': 38.0372,
    '1109_878': 37.7965,
    '1109_879': 37.5541,
    '1109_880': 37.3106,
    '1109_881': 37.0678,
    '1109_882': 36.8325,
    '1109_883': 36.5987,
    '1109_884': 36.3643,
    '1109_885': 36.1278,
    '1109_886': 35.8891,
    '1109_887': 35.646,
    '1109_888': 35.396,
    '1109_889': 35.1364,
    '1109_890': 34.8664,
    '1109_891': 34.5926,
    '1109_892': 34.3133,
    '1109_893': 34.0285,
    '1109_894': 33.7387,
    '1109_895': 33.4431,
    '1109_896': 33.1415,
    '1109_897': 32.8359,
    '1109_898': 32.5278,
    '1109_899': 32.2155,
    '1109_900': 31.8976,
    '1110_767': 38.7481,
    '1110_768': 38.7453,
    '1110_769': 38.7449,
    '1110_770': 38.7339,
    '1110_771': 38.7093,
    '1110_772': 38.6835,
    '1110_774': 38.7222,
    '1110_775': 38.8048,
    '1110_776': 38.8991,
    '1110_777': 39.0056,
    '1110_778': 39.1314,
    '1110_779': 39.2705,
    '1110_780': 39.4152,
    '1110_781': 39.5663,
    '1110_782': 39.7226,
    '1110_783': 39.8719,
    '1110_784': 40.0169,
    '1110_785': 40.1574,
    '1110_786': 40.2996,
    '1110_787': 40.4456,
    '1110_788': 40.5938,
    '1110_789': 40.7218,
    '1110_790': 40.8085,
    '1110_791': 40.8892,
    '1110_792': 41.0022,
    '1110_793': 41.1245,
    '1110_794': 41.236,
    '1110_795': 41.3394,
    '1110_796': 41.4364,
    '1110_797': 41.5159,
    '1110_798': 41.57,
    '1110_799': 41.6059,
    '1110_800': 41.6339,
    '1110_801': 41.6633,
    '1110_802': 41.6705,
    '1110_803': 41.654,
    '1110_804': 41.6216,
    '1110_805': 41.5902,
    '1110_806': 41.5635,
    '1110_807': 41.5437,
    '1110_808': 41.5252,
    '1110_809': 41.4934,
    '1110_810': 41.4376,
    '1110_811': 41.3742,
    '1110_812': 41.3156,
    '1110_813': 41.2825,
    '1110_814': 41.2919,
    '1110_815': 41.3389,
    '1110_816': 41.4313,
    '1110_817': 41.5654,
    '1110_818': 41.7065,
    '1110_819': 41.8247,
    '1110_820': 41.9227,
    '1110_821': 42.0027,
    '1110_822': 42.0626,
    '1110_823': 42.0998,
    '1110_824': 42.1034,
    '1110_825': 42.0743,
    '1110_826': 42.0262,
    '1110_827': 41.9707,
    '1110_828': 41.9092,
    '1110_829': 41.8398,
    '1110_830': 41.7637,
    '1110_831': 41.6834,
    '1110_832': 41.6042,
    '1110_833': 41.5416,
    '1110_834': 41.4989,
    '1110_835': 41.4674,
    '1110_836': 41.4444,
    '1110_837': 41.4242,
    '1110_838': 41.4043,
    '1110_839': 41.3837,
    '1110_840': 41.3664,
    '1110_841': 41.3522,
    '1110_842': 41.3421,
    '1110_843': 41.3356,
    '1110_844': 41.3376,
    '1110_845': 41.3502,
    '1110_846': 41.3768,
    '1110_847': 41.4126,
    '1110_848': 41.4493,
    '1110_849': 41.4941,
    '1110_850': 41.5391,
    '1110_851': 41.573,
    '1110_852': 41.5975,
    '1110_853': 41.6204,
    '1110_854': 41.6374,
    '1110_855': 41.6206,
    '1110_856': 41.5899,
    '1110_857': 41.547,
    '1110_858': 41.4674,
    '1110_859': 41.3708,
    '1110_860': 41.2545,
    '1110_861': 41.1369,
    '1110_862': 40.9977,
    '1110_863': 40.8507,
    '1110_864': 40.6987,
    '1110_865': 40.5432,
    '1110_866': 40.3886,
    '1110_867': 40.2252,
    '1110_868': 40.0496,
    '1110_869': 39.859,
    '1110_870': 39.657,
    '1110_871': 39.4483,
    '1110_872': 39.2354,
    '1110_873': 39.0094,
    '1110_874': 38.7799,
    '1110_875': 38.546,
    '1110_876': 38.3095,
    '1110_877': 38.0726,
    '1110_878': 37.8347,
    '1110_879': 37.5949,
    '1110_880': 37.3539,
    '1110_881': 37.1111,
    '1110_882': 36.8789,
    '1110_883': 36.6459,
    '1110_884': 36.4126,
    '1110_885': 36.1772,
    '1110_886': 35.9389,
    '1110_887': 35.6963,
    '1110_888': 35.4457,
    '1110_889': 35.1862,
    '1110_890': 34.9185,
    '1110_891': 34.6451,
    '1110_892': 34.3658,
    '1110_893': 34.0812,
    '1110_894': 33.7917,
    '1110_895': 33.4965,
    '1110_896': 33.1941,
    '1110_897': 32.8872,
    '1110_898': 32.5758,
    '1110_899': 32.2555,
    '1110_900': 31.9297,
    '1111_767': 38.7108,
    '1111_768': 38.7031,
    '1111_769': 38.6944,
    '1111_770': 38.6811,
    '1111_771': 38.6623,
    '1111_772': 38.646,
    '1111_774': 38.6641,
    '1111_775': 38.7436,
    '1111_776': 38.8369,
    '1111_777': 38.9425,
    '1111_778': 39.0625,
    '1111_779': 39.1944,
    '1111_780': 39.3374,
    '1111_781': 39.4856,
    '1111_782': 39.6435,
    '1111_783': 39.8017,
    '1111_784': 39.9541,
    '1111_785': 40.0922,
    '1111_786': 40.2298,
    '1111_787': 40.3696,
    '1111_788': 40.5124,
    '1111_789': 40.6443,
    '1111_790': 40.7312,
    '1111_791': 40.8072,
    '1111_792': 40.9063,
    '1111_793': 41.0281,
    '1111_794': 41.1416,
    '1111_795': 41.2416,
    '1111_796': 41.343,
    '1111_797': 41.4322,
    '1111_798': 41.5082,
    '1111_799': 41.5705,
    '1111_800': 41.6275,
    '1111_801': 41.6692,
    '1111_802': 41.6821,
    '1111_803': 41.663,
    '1111_804': 41.6279,
    '1111_805': 41.5916,
    '1111_806': 41.5621,
    '1111_807': 41.5472,
    '1111_808': 41.5325,
    '1111_809': 41.5001,
    '1111_810': 41.4512,
    '1111_811': 41.3788,
    '1111_812': 41.3131,
    '1111_813': 41.2635,
    '1111_814': 41.2633,
    '1111_815': 41.3048,
    '1111_816': 41.3858,
    '1111_817': 41.5082,
    '1111_818': 41.6354,
    '1111_819': 41.7436,
    '1111_820': 41.8387,
    '1111_821': 41.9163,
    '1111_822': 41.977,
    '1111_823': 42.0154,
    '1111_824': 42.0199,
    '1111_825': 41.993,
    '1111_826': 41.9483,
    '1111_827': 41.893,
    '1111_828': 41.8382,
    '1111_829': 41.775,
    '1111_830': 41.7055,
    '1111_831': 41.6266,
    '1111_832': 41.5505,
    '1111_833': 41.492,
    '1111_834': 41.4536,
    '1111_835': 41.4251,
    '1111_836': 41.4005,
    '1111_837': 41.3808,
    '1111_838': 41.3636,
    '1111_839': 41.3476,
    '1111_840': 41.3331,
    '1111_841': 41.3228,
    '1111_842': 41.3195,
    '1111_843': 41.3213,
    '1111_844': 41.3316,
    '1111_845': 41.3529,
    '1111_846': 41.3855,
    '1111_847': 41.4247,
    '1111_848': 41.4673,
    '1111_849': 41.5136,
    '1111_850': 41.5525,
    '1111_851': 41.5824,
    '1111_852': 41.6049,
    '1111_853': 41.6181,
    '1111_854': 41.623,
    '1111_855': 41.607,
    '1111_856': 41.574,
    '1111_857': 41.5296,
    '1111_858': 41.46,
    '1111_859': 41.366,
    '1111_860': 41.2663,
    '1111_861': 41.1514,
    '1111_862': 41.0111,
    '1111_863': 40.8645,
    '1111_864': 40.7143,
    '1111_865': 40.5608,
    '1111_866': 40.4039,
    '1111_867': 40.2362,
    '1111_868': 40.057,
    '1111_869': 39.8663,
    '1111_870': 39.6655,
    '1111_871': 39.4573,
    '1111_872': 39.2374,
    '1111_873': 39.0176,
    '1111_874': 38.7969,
    '1111_875': 38.5721,
    '1111_876': 38.3406,
    '1111_877': 38.1063,
    '1111_878': 37.8702,
    '1111_879': 37.6329,
    '1111_880': 37.3939,
    '1111_881': 37.1537,
    '1111_882': 36.9186,
    '1111_883': 36.6887,
    '1111_884': 36.4582,
    '1111_885': 36.2236,
    '1111_886': 35.9843,
    '1111_887': 35.7394,
    '1111_888': 35.489,
    '1111_889': 35.2315,
    '1111_890': 34.9657,
    '1111_891': 34.6917,
    '1111_892': 34.4126,
    '1111_893': 34.1293,
    '1111_894': 33.8422,
    '1111_895': 33.5501,
    '1111_896': 33.2466,
    '1111_897': 32.9398,
    '1111_898': 32.6299,
    '1111_899': 32.3034,
    '1111_900': 31.9762,
    '1112_767': 38.6686,
    '1112_768': 38.6574,
    '1112_769': 38.6449,
    '1112_770': 38.6302,
    '1112_771': 38.6141,
    '1112_772': 38.6042,
    '1112_774': 38.607,
    '1112_775': 38.6869,
    '1112_776': 38.7769,
    '1112_777': 38.8794,
    '1112_778': 38.9947,
    '1112_779': 39.1221,
    '1112_780': 39.2618,
    '1112_781': 39.4085,
    '1112_782': 39.5674,
    '1112_783': 39.7329,
    '1112_784': 39.8828,
    '1112_785': 40.0221,
    '1112_786': 40.1643,
    '1112_787': 40.2964,
    '1112_788': 40.4275,
    '1112_789': 40.5625,
    '1112_790': 40.6555,
    '1112_791': 40.7288,
    '1112_792': 40.8125,
    '1112_793': 40.9237,
    '1112_794': 41.0386,
    '1112_795': 41.1419,
    '1112_796': 41.2391,
    '1112_797': 41.3344,
    '1112_798': 41.4335,
    '1112_799': 41.5233,
    '1112_800': 41.5906,
    '1112_801': 41.6369,
    '1112_802': 41.6609,
    '1112_803': 41.6467,
    '1112_804': 41.6107,
    '1112_805': 41.5718,
    '1112_806': 41.54,
    '1112_807': 41.5266,
    '1112_808': 41.5123,
    '1112_809': 41.4897,
    '1112_810': 41.4393,
    '1112_811': 41.3601,
    '1112_812': 41.2946,
    '1112_813': 41.2445,
    '1112_814': 41.2298,
    '1112_815': 41.2613,
    '1112_816': 41.3345,
    '1112_817': 41.4439,
    '1112_818': 41.5548,
    '1112_819': 41.6531,
    '1112_820': 41.745,
    '1112_821': 41.8253,
    '1112_822': 41.8924,
    '1112_823': 41.931,
    '1112_824': 41.9384,
    '1112_825': 41.9206,
    '1112_826': 41.8839,
    '1112_827': 41.8276,
    '1112_828': 41.7643,
    '1112_829': 41.6996,
    '1112_830': 41.6356,
    '1112_831': 41.5622,
    '1112_832': 41.4959,
    '1112_833': 41.4462,
    '1112_834': 41.4123,
    '1112_835': 41.3847,
    '1112_836': 41.3576,
    '1112_837': 41.3365,
    '1112_838': 41.32,
    '1112_839': 41.3086,
    '1112_840': 41.2981,
    '1112_841': 41.2946,
    '1112_842': 41.3006,
    '1112_843': 41.3115,
    '1112_844': 41.3303,
    '1112_845': 41.3591,
    '1112_846': 41.3983,
    '1112_847': 41.442,
    '1112_848': 41.4868,
    '1112_849': 41.5293,
    '1112_850': 41.5631,
    '1112_851': 41.5918,
    '1112_852': 41.6147,
    '1112_853': 41.6243,
    '1112_854': 41.6128,
    '1112_855': 41.5909,
    '1112_856': 41.5555,
    '1112_857': 41.5066,
    '1112_858': 41.4475,
    '1112_859': 41.3634,
    '1112_860': 41.2604,
    '1112_861': 41.1484,
    '1112_862': 41.0132,
    '1112_863': 40.8713,
    '1112_864': 40.7232,
    '1112_865': 40.5703,
    '1112_866': 40.412,
    '1112_867': 40.2437,
    '1112_868': 40.0633,
    '1112_869': 39.8728,
    '1112_870': 39.6728,
    '1112_871': 39.4671,
    '1112_872': 39.2515,
    '1112_873': 39.0299,
    '1112_874': 38.8146,
    '1112_875': 38.5963,
    '1112_876': 38.3703,
    '1112_877': 38.1398,
    '1112_878': 37.9068,
    '1112_879': 37.6722,
    '1112_880': 37.4355,
    '1112_881': 37.196,
    '1112_882': 36.9574,
    '1112_883': 36.7321,
    '1112_884': 36.5044,
    '1112_885': 36.2698,
    '1112_886': 36.028,
    '1112_887': 35.7797,
    '1112_888': 35.5278,
    '1112_889': 35.2705,
    '1112_890': 35.0047,
    '1112_891': 34.7253,
    '1112_892': 34.4492,
    '1112_893': 34.1706,
    '1112_894': 33.8892,
    '1112_895': 33.6035,
    '1112_896': 33.3015,
    '1112_897': 32.9975,
    '1112_898': 32.6903,
    '1112_899': 32.3583,
    '1112_900': 32.0239,
    '1113_774': 38.5521,
    '1113_775': 38.6313,
    '1113_776': 38.7166,
    '1113_777': 38.8148,
    '1113_778': 38.9261,
    '1113_779': 39.0496,
    '1113_780': 39.184,
    '1113_781': 39.331,
    '1113_782': 39.4894,
    '1113_783': 39.6587,
    '1113_784': 39.8137,
    '1113_785': 39.9581,
    '1113_786': 40.0952,
    '1113_787': 40.2202,
    '1113_788': 40.3425,
    '1113_789': 40.4794,
    '1113_790': 40.583,
    '1113_791': 40.6555,
    '1113_792': 40.7254,
    '1113_793': 40.8176,
    '1113_794': 40.9296,
    '1113_795': 41.0344,
    '1113_796': 41.1315,
    '1113_797': 41.2292,
    '1113_798': 41.3412,
    '1113_799': 41.4499,
    '1113_800': 41.5368,
    '1113_801': 41.5861,
    '1113_802': 41.6156,
    '1113_803': 41.6104,
    '1113_804': 41.5736,
    '1113_805': 41.5343,
    '1113_806': 41.4984,
    '1113_807': 41.4858,
    '1113_808': 41.474,
    '1113_809': 41.444,
    '1113_810': 41.3964,
    '1113_811': 41.3231,
    '1113_812': 41.263,
    '1113_813': 41.2184,
    '1113_814': 41.195,
    '1113_815': 41.2152,
    '1113_816': 41.2798,
    '1113_817': 41.3794,
    '1113_818': 41.477,
    '1113_819': 41.5684,
    '1113_820': 41.6587,
    '1113_821': 41.7437,
    '1113_822': 41.8147,
    '1113_823': 41.8562,
    '1113_824': 41.8645,
    '1113_825': 41.8532,
    '1113_826': 41.8216,
    '1113_827': 41.7706,
    '1113_828': 41.7032,
    '1113_829': 41.6319,
    '1113_830': 41.5678,
    '1113_831': 41.5061,
    '1113_832': 41.4486,
    '1113_833': 41.4063,
    '1113_834': 41.377,
    '1113_835': 41.3513,
    '1113_836': 41.3228,
    '1113_837': 41.3009,
    '1113_838': 41.2853,
    '1113_839': 41.2784,
    '1113_840': 41.2745,
    '1113_841': 41.2774,
    '1113_842': 41.2899,
    '1113_843': 41.3088,
    '1113_844': 41.3336,
    '1113_845': 41.3687,
    '1113_846': 41.4114,
    '1113_847': 41.4611,
    '1113_848': 41.4999,
    '1113_849': 41.5369,
    '1113_850': 41.5695,
    '1113_851': 41.5966,
    '1113_852': 41.6218,
    '1113_853': 41.6387,
    '1113_854': 41.6237,
    '1113_855': 41.5988,
    '1113_856': 41.5502,
    '1113_857': 41.4925,
    '1113_858': 41.4292,
    '1113_859': 41.3491,
    '1113_860': 41.2475,
    '1113_861': 41.1366,
    '1113_862': 41.0099,
    '1113_863': 40.8727,
    '1113_864': 40.7276,
    '1113_865': 40.5759,
    '1113_866': 40.4189,
    '1113_867': 40.2525,
    '1113_868': 40.0745,
    '1113_869': 39.8856,
    '1113_870': 39.6875,
    '1113_871': 39.4833,
    '1113_872': 39.2696,
    '1113_873': 39.0508,
    '1113_874': 38.8383,
    '1113_875': 38.6233,
    '1113_876': 38.4015,
    '1113_877': 38.1756,
    '1113_878': 37.947,
    '1113_879': 37.7157,
    '1113_880': 37.4835,
    '1113_881': 37.2464,
    '1113_882': 37.0073,
    '1113_883': 36.7836,
    '1113_884': 36.5555,
    '1113_885': 36.3184,
    '1113_886': 36.0741,
    '1113_887': 35.8229,
    '1113_888': 35.5668,
    '1113_889': 35.3054,
    '1113_890': 35.0367,
    '1113_891': 34.76,
    '1113_892': 34.483,
    '1113_893': 34.204,
    '1113_894': 33.922,
    '1113_895': 33.6344,
    '1113_896': 33.3363,
    '1113_897': 33.0317,
    '1113_898': 32.7197,
    '1113_899': 32.3922,
    '1113_900': 32.0574,
    '1114_774': 38.5,
    '1114_775': 38.5776,
    '1114_776': 38.6572,
    '1114_777': 38.748,
    '1114_778': 38.8543,
    '1114_779': 38.9736,
    '1114_780': 39.1052,
    '1114_781': 39.2512,
    '1114_782': 39.4083,
    '1114_783': 39.5775,
    '1114_784': 39.7412,
    '1114_785': 39.8835,
    '1114_786': 40.0154,
    '1114_787': 40.1388,
    '1114_788': 40.2582,
    '1114_789': 40.3907,
    '1114_790': 40.5044,
    '1114_791': 40.5829,
    '1114_792': 40.6416,
    '1114_793': 40.7098,
    '1114_794': 40.8117,
    '1114_795': 40.916,
    '1114_796': 41.0143,
    '1114_797': 41.1202,
    '1114_798': 41.2374,
    '1114_799': 41.3569,
    '1114_800': 41.4585,
    '1114_801': 41.5164,
    '1114_802': 41.546,
    '1114_803': 41.5466,
    '1114_804': 41.5165,
    '1114_805': 41.4775,
    '1114_806': 41.4341,
    '1114_807': 41.414,
    '1114_808': 41.3991,
    '1114_809': 41.3755,
    '1114_810': 41.3305,
    '1114_811': 41.2672,
    '1114_812': 41.216,
    '1114_813': 41.1816,
    '1114_814': 41.1581,
    '1114_815': 41.17,
    '1114_816': 41.2298,
    '1114_817': 41.3209,
    '1114_818': 41.4091,
    '1114_819': 41.497,
    '1114_820': 41.5876,
    '1114_821': 41.6781,
    '1114_822': 41.7498,
    '1114_823': 41.7931,
    '1114_824': 41.8029,
    '1114_825': 41.7921,
    '1114_826': 41.7585,
    '1114_827': 41.7129,
    '1114_828': 41.6498,
    '1114_829': 41.5804,
    '1114_830': 41.5146,
    '1114_831': 41.4599,
    '1114_832': 41.4101,
    '1114_833': 41.3724,
    '1114_834': 41.3467,
    '1114_835': 41.3231,
    '1114_836': 41.2966,
    '1114_837': 41.2776,
    '1114_838': 41.2656,
    '1114_839': 41.2619,
    '1114_840': 41.2627,
    '1114_841': 41.2693,
    '1114_842': 41.287,
    '1114_843': 41.3138,
    '1114_844': 41.3439,
    '1114_845': 41.3797,
    '1114_846': 41.4215,
    '1114_847': 41.4653,
    '1114_848': 41.5024,
    '1114_849': 41.54,
    '1114_850': 41.5691,
    '1114_851': 41.5948,
    '1114_852': 41.6209,
    '1114_853': 41.6462,
    '1114_854': 41.6411,
    '1114_855': 41.616,
    '1114_856': 41.5599,
    '1114_857': 41.4937,
    '1114_858': 41.4203,
    '1114_859': 41.3378,
    '1114_860': 41.2399,
    '1114_861': 41.13,
    '1114_862': 41.0074,
    '1114_863': 40.8738,
    '1114_864': 40.7319,
    '1114_865': 40.5828,
    '1114_866': 40.4284,
    '1114_867': 40.2652,
    '1114_868': 40.0904,
    '1114_869': 39.9028,
    '1114_870': 39.7049,
    '1114_871': 39.5022,
    '1114_872': 39.2924,
    '1114_873': 39.0786,
    '1114_874': 38.8674,
    '1114_875': 38.6535,
    '1114_876': 38.4341,
    '1114_877': 38.2131,
    '1114_878': 37.9895,
    '1114_879': 37.7637,
    '1114_880': 37.5356,
    '1114_881': 37.3,
    '1114_882': 37.0527,
    '1114_883': 36.8353,
    '1114_884': 36.6053,
    '1114_885': 36.3648,
    '1114_886': 36.1181,
    '1114_887': 35.8636,
    '1114_888': 35.6034,
    '1114_889': 35.3382,
    '1114_890': 35.0672,
    '1114_891': 34.7906,
    '1114_892': 34.5121,
    '1114_893': 34.2307,
    '1114_894': 33.9456,
    '1114_895': 33.6558,
    '1114_896': 33.3579,
    '1114_897': 33.0517,
    '1114_898': 32.7371,
    '1114_899': 32.4123,
    '1114_900': 32.0782,
    '1115_774': 38.4531,
    '1115_775': 38.5248,
    '1115_776': 38.5962,
    '1115_777': 38.6773,
    '1115_778': 38.7786,
    '1115_779': 38.8943,
    '1115_780': 39.0229,
    '1115_781': 39.1668,
    '1115_782': 39.3203,
    '1115_783': 39.4873,
    '1115_784': 39.6546,
    '1115_785': 39.8042,
    '1115_786': 39.941,
    '1115_787': 40.0628,
    '1115_788': 40.1748,
    '1115_789': 40.2973,
    '1115_790': 40.4154,
    '1115_791': 40.4997,
    '1115_792': 40.5476,
    '1115_793': 40.5992,
    '1115_794': 40.6872,
    '1115_795': 40.7894,
    '1115_796': 40.8951,
    '1115_797': 41.0095,
    '1115_798': 41.1274,
    '1115_799': 41.2441,
    '1115_800': 41.3479,
    '1115_801': 41.4156,
    '1115_802': 41.4601,
    '1115_803': 41.4611,
    '1115_804': 41.44,
    '1115_805': 41.4015,
    '1115_806': 41.3512,
    '1115_807': 41.3228,
    '1115_808': 41.3061,
    '1115_809': 41.2832,
    '1115_810': 41.2471,
    '1115_811': 41.1991,
    '1115_812': 41.1613,
    '1115_813': 41.1325,
    '1115_814': 41.1136,
    '1115_815': 41.1264,
    '1115_816': 41.1846,
    '1115_817': 41.2704,
    '1115_818': 41.354,
    '1115_819': 41.441,
    '1115_820': 41.5332,
    '1115_821': 41.6299,
    '1115_822': 41.7013,
    '1115_823': 41.7426,
    '1115_824': 41.7519,
    '1115_825': 41.7349,
    '1115_826': 41.6979,
    '1115_827': 41.6537,
    '1115_828': 41.5993,
    '1115_829': 41.5334,
    '1115_830': 41.4724,
    '1115_831': 41.4238,
    '1115_832': 41.3811,
    '1115_833': 41.3474,
    '1115_834': 41.3226,
    '1115_835': 41.3013,
    '1115_836': 41.2798,
    '1115_837': 41.2647,
    '1115_838': 41.2555,
    '1115_839': 41.2528,
    '1115_840': 41.2553,
    '1115_841': 41.2646,
    '1115_842': 41.2862,
    '1115_843': 41.3191,
    '1115_844': 41.3533,
    '1115_845': 41.386,
    '1115_846': 41.4164,
    '1115_847': 41.4545,
    '1115_848': 41.4983,
    '1115_849': 41.5384,
    '1115_850': 41.5678,
    '1115_851': 41.5919,
    '1115_852': 41.6175,
    '1115_853': 41.6451,
    '1115_854': 41.6499,
    '1115_855': 41.6245,
    '1115_856': 41.5733,
    '1115_857': 41.5092,
    '1115_858': 41.4331,
    '1115_859': 41.3432,
    '1115_860': 41.2406,
    '1115_861': 41.1288,
    '1115_862': 41.0069,
    '1115_863': 40.8761,
    '1115_864': 40.7372,
    '1115_865': 40.5917,
    '1115_866': 40.4403,
    '1115_867': 40.2813,
    '1115_868': 40.111,
    '1115_869': 39.925,
    '1115_870': 39.726,
    '1115_871': 39.5258,
    '1115_872': 39.3162,
    '1115_873': 39.1053,
    '1115_874': 38.8957,
    '1115_875': 38.6855,
    '1115_876': 38.4662,
    '1115_877': 38.2508,
    '1115_878': 38.0294,
    '1115_879': 37.8033,
    '1115_880': 37.5825,
    '1115_881': 37.3464,
    '1115_882': 37.1028,
    '1115_883': 36.8713,
    '1115_884': 36.6406,
    '1115_885': 36.4008,
    '1115_886': 36.153,
    '1115_887': 35.898,
    '1115_888': 35.6358,
    '1115_889': 35.3686,
    '1115_890': 35.0965,
    '1115_891': 34.8194,
    '1115_892': 34.5373,
    '1115_893': 34.2525,
    '1115_894': 33.9645,
    '1115_895': 33.6722,
    '1115_896': 33.3725,
    '1115_897': 33.0634,
    '1115_898': 32.7463,
    '1115_899': 32.4219,
    '1115_900': 32.0869,
    '1116_774': 38.3906,
    '1116_775': 38.4627,
    '1116_776': 38.533,
    '1116_777': 38.6094,
    '1116_778': 38.7037,
    '1116_779': 38.8144,
    '1116_780': 38.9396,
    '1116_781': 39.08,
    '1116_782': 39.2304,
    '1116_783': 39.394,
    '1116_784': 39.5624,
    '1116_785': 39.7195,
    '1116_786': 39.8544,
    '1116_787': 39.983,
    '1116_788': 40.0925,
    '1116_789': 40.2015,
    '1116_790': 40.3187,
    '1116_791': 40.4033,
    '1116_792': 40.4499,
    '1116_793': 40.4881,
    '1116_794': 40.5658,
    '1116_795': 40.6698,
    '1116_796': 40.779,
    '1116_797': 40.8953,
    '1116_798': 41.0087,
    '1116_799': 41.1146,
    '1116_800': 41.2126,
    '1116_801': 41.2931,
    '1116_802': 41.343,
    '1116_803': 41.3588,
    '1116_804': 41.3518,
    '1116_805': 41.3175,
    '1116_806': 41.2662,
    '1116_807': 41.2322,
    '1116_808': 41.2124,
    '1116_809': 41.1903,
    '1116_810': 41.1624,
    '1116_811': 41.126,
    '1116_812': 41.0968,
    '1116_813': 41.0771,
    '1116_814': 41.065,
    '1116_815': 41.0859,
    '1116_816': 41.1456,
    '1116_817': 41.2294,
    '1116_818': 41.3132,
    '1116_819': 41.4015,
    '1116_820': 41.4981,
    '1116_821': 41.6024,
    '1116_822': 41.6743,
    '1116_823': 41.7116,
    '1116_824': 41.7199,
    '1116_825': 41.6987,
    '1116_826': 41.6586,
    '1116_827': 41.6051,
    '1116_828': 41.5532,
    '1116_829': 41.4986,
    '1116_830': 41.4479,
    '1116_831': 41.4049,
    '1116_832': 41.3684,
    '1116_833': 41.3374,
    '1116_834': 41.3123,
    '1116_835': 41.2921,
    '1116_836': 41.2752,
    '1116_837': 41.2626,
    '1116_838': 41.254,
    '1116_839': 41.2493,
    '1116_840': 41.2511,
    '1116_841': 41.2608,
    '1116_842': 41.2833,
    '1116_843': 41.3142,
    '1116_844': 41.3452,
    '1116_845': 41.372,
    '1116_846': 41.4036,
    '1116_847': 41.4441,
    '1116_848': 41.4912,
    '1116_849': 41.5349,
    '1116_850': 41.567,
    '1116_851': 41.5894,
    '1116_852': 41.6108,
    '1116_853': 41.6333,
    '1116_854': 41.6411,
    '1116_855': 41.6251,
    '1116_856': 41.5894,
    '1116_857': 41.5218,
    '1116_858': 41.4485,
    '1116_859': 41.3549,
    '1116_860': 41.2473,
    '1116_861': 41.1317,
    '1116_862': 41.0089,
    '1116_863': 40.8793,
    '1116_864': 40.7424,
    '1116_865': 40.5991,
    '1116_866': 40.4512,
    '1116_867': 40.2973,
    '1116_868': 40.134,
    '1116_869': 39.9527,
    '1116_870': 39.758,
    '1116_871': 39.5589,
    '1116_872': 39.3477,
    '1116_873': 39.1381,
    '1116_874': 38.9257,
    '1116_875': 38.7221,
    '1116_876': 38.5008,
    '1116_877': 38.2929,
    '1116_878': 38.0746,
    '1116_879': 37.8511,
    '1116_880': 37.6264,
    '1116_881': 37.3906,
    '1116_882': 37.146,
    '1116_883': 36.9034,
    '1116_884': 36.6733,
    '1116_885': 36.4323,
    '1116_886': 36.1828,
    '1116_887': 35.9261,
    '1116_888': 35.6639,
    '1116_889': 35.3964,
    '1116_890': 35.1231,
    '1116_891': 34.8443,
    '1116_892': 34.5568,
    '1116_893': 34.2693,
    '1116_894': 33.9787,
    '1116_895': 33.6839,
    '1116_896': 33.382,
    '1116_897': 33.0681,
    '1116_898': 32.7461,
    '1116_899': 32.4173,
    '1116_900': 32.0851,
    '1117_774': 38.3258,
    '1117_775': 38.3993,
    '1117_776': 38.4708,
    '1117_777': 38.5443,
    '1117_778': 38.6296,
    '1117_779': 38.7341,
    '1117_780': 38.8546,
    '1117_781': 38.9892,
    '1117_782': 39.1383,
    '1117_783': 39.298,
    '1117_784': 39.4704,
    '1117_785': 39.6359,
    '1117_786': 39.7702,
    '1117_787': 39.8957,
    '1117_788': 40.0029,
    '1117_789': 40.1034,
    '1117_790': 40.2123,
    '1117_791': 40.2945,
    '1117_792': 40.3406,
    '1117_793': 40.3738,
    '1117_794': 40.4396,
    '1117_795': 40.5454,
    '1117_796': 40.6598,
    '1117_797': 40.7715,
    '1117_798': 40.877,
    '1117_799': 40.9713,
    '1117_800': 41.0636,
    '1117_801': 41.1555,
    '1117_802': 41.2175,
    '1117_803': 41.2513,
    '1117_804': 41.2563,
    '1117_805': 41.2298,
    '1117_806': 41.1852,
    '1117_807': 41.1453,
    '1117_808': 41.1215,
    '1117_809': 41.1023,
    '1117_810': 41.0816,
    '1117_811': 41.0523,
    '1117_812': 41.0339,
    '1117_813': 41.0236,
    '1117_814': 41.0217,
    '1117_815': 41.0524,
    '1117_816': 41.1161,
    '1117_817': 41.2,
    '1117_818': 41.2865,
    '1117_819': 41.3794,
    '1117_820': 41.4835,
    '1117_821': 41.5906,
    '1117_822': 41.6631,
    '1117_823': 41.6978,
    '1117_824': 41.7044,
    '1117_825': 41.6767,
    '1117_826': 41.6312,
    '1117_827': 41.5813,
    '1117_828': 41.5326,
    '1117_829': 41.4872,
    '1117_830': 41.4461,
    '1117_831': 41.407,
    '1117_832': 41.3736,
    '1117_833': 41.3427,
    '1117_834': 41.3168,
    '1117_835': 41.2978,
    '1117_836': 41.2822,
    '1117_837': 41.2681,
    '1117_838': 41.2564,
    '1117_839': 41.2478,
    '1117_840': 41.2467,
    '1117_841': 41.2571,
    '1117_842': 41.279,
    '1117_843': 41.3037,
    '1117_844': 41.3263,
    '1117_845': 41.3504,
    '1117_846': 41.3872,
    '1117_847': 41.431,
    '1117_848': 41.4796,
    '1117_849': 41.527,
    '1117_850': 41.5658,
    '1117_851': 41.5895,
    '1117_852': 41.6041,
    '1117_853': 41.6197,
    '1117_854': 41.6256,
    '1117_855': 41.6182,
    '1117_856': 41.5847,
    '1117_857': 41.5171,
    '1117_858': 41.4384,
    '1117_859': 41.3568,
    '1117_860': 41.2557,
    '1117_861': 41.1377,
    '1117_862': 41.0123,
    '1117_863': 40.8837,
    '1117_864': 40.7479,
    '1117_865': 40.6056,
    '1117_866': 40.4591,
    '1117_867': 40.3065,
    '1117_868': 40.1441,
    '1117_869': 39.9677,
    '1117_870': 39.7798,
    '1117_871': 39.5824,
    '1117_872': 39.3714,
    '1117_873': 39.1617,
    '1117_874': 38.9526,
    '1117_875': 38.7502,
    '1117_876': 38.535,
    '1117_877': 38.3293,
    '1117_878': 38.1177,
    '1117_879': 37.8946,
    '1117_880': 37.6663,
    '1117_881': 37.4354,
    '1117_882': 37.1926,
    '1117_883': 36.9474,
    '1117_884': 36.7064,
    '1117_885': 36.4629,
    '1117_886': 36.2121,
    '1117_887': 35.9538,
    '1117_888': 35.6913,
    '1117_889': 35.4229,
    '1117_890': 35.1467,
    '1117_891': 34.864,
    '1117_892': 34.5758,
    '1117_893': 34.285,
    '1117_894': 33.9901,
    '1117_895': 33.6909,
    '1117_896': 33.3837,
    '1117_897': 33.0674,
    '1117_898': 32.7434,
    '1117_899': 32.4139,
    '1117_900': 32.0808,
    '1118_774': 38.2634,
    '1118_775': 38.337,
    '1118_776': 38.4083,
    '1118_777': 38.4803,
    '1118_778': 38.5596,
    '1118_779': 38.6554,
    '1118_780': 38.769,
    '1118_781': 38.8987,
    '1118_782': 39.0446,
    '1118_783': 39.2022,
    '1118_784': 39.3764,
    '1118_785': 39.5428,
    '1118_786': 39.6732,
    '1118_787': 39.7926,
    '1118_788': 39.9004,
    '1118_789': 39.9985,
    '1118_790': 40.0919,
    '1118_791': 40.175,
    '1118_792': 40.2273,
    '1118_793': 40.2512,
    '1118_794': 40.3104,
    '1118_795': 40.4115,
    '1118_796': 40.528,
    '1118_797': 40.6382,
    '1118_798': 40.7351,
    '1118_799': 40.8214,
    '1118_800': 40.9115,
    '1118_801': 41.0178,
    '1118_802': 41.0993,
    '1118_803': 41.1428,
    '1118_804': 41.1533,
    '1118_805': 41.1329,
    '1118_806': 41.099,
    '1118_807': 41.0641,
    '1118_808': 41.0397,
    '1118_809': 41.0217,
    '1118_810': 41.0043,
    '1118_811': 40.9849,
    '1118_812': 40.974,
    '1118_813': 40.9734,
    '1118_814': 40.9856,
    '1118_815': 41.027,
    '1118_816': 41.0988,
    '1118_817': 41.1892,
    '1118_818': 41.2806,
    '1118_819': 41.3767,
    '1118_820': 41.4806,
    '1118_821': 41.5892,
    '1118_822': 41.6635,
    '1118_823': 41.6947,
    '1118_824': 41.6975,
    '1118_825': 41.6642,
    '1118_826': 41.6218,
    '1118_827': 41.5814,
    '1118_828': 41.539,
    '1118_829': 41.5023,
    '1118_830': 41.4662,
    '1118_831': 41.4261,
    '1118_832': 41.3905,
    '1118_833': 41.3593,
    '1118_834': 41.3328,
    '1118_835': 41.3117,
    '1118_836': 41.2923,
    '1118_837': 41.2721,
    '1118_838': 41.2551,
    '1118_839': 41.2438,
    '1118_840': 41.2418,
    '1118_841': 41.2523,
    '1118_842': 41.2726,
    '1118_843': 41.2917,
    '1118_844': 41.3061,
    '1118_845': 41.3282,
    '1118_846': 41.3682,
    '1118_847': 41.4153,
    '1118_848': 41.4656,
    '1118_849': 41.5154,
    '1118_850': 41.556,
    '1118_851': 41.5844,
    '1118_852': 41.6035,
    '1118_853': 41.6189,
    '1118_854': 41.6179,
    '1118_855': 41.6031,
    '1118_856': 41.566,
    '1118_857': 41.4979,
    '1118_858': 41.42,
    '1118_859': 41.3418,
    '1118_860': 41.2466,
    '1118_861': 41.1348,
    '1118_862': 41.0148,
    '1118_863': 40.8889,
    '1118_864': 40.7539,
    '1118_865': 40.6113,
    '1118_866': 40.4654,
    '1118_867': 40.3134,
    '1118_868': 40.1509,
    '1118_869': 39.9771,
    '1118_870': 39.7952,
    '1118_871': 39.6051,
    '1118_872': 39.399,
    '1118_873': 39.1786,
    '1118_874': 38.9714,
    '1118_875': 38.7806,
    '1118_876': 38.5701,
    '1118_877': 38.3647,
    '1118_878': 38.1582,
    '1118_879': 37.9353,
    '1118_880': 37.71,
    '1118_881': 37.4832,
    '1118_882': 37.2391,
    '1118_883': 36.9904,
    '1118_884': 36.7414,
    '1118_885': 36.4956,
    '1118_886': 36.2432,
    '1118_887': 35.9816,
    '1118_888': 35.7185,
    '1118_889': 35.4497,
    '1118_890': 35.1693,
    '1118_891': 34.882,
    '1118_892': 34.5929,
    '1118_893': 34.3002,
    '1118_894': 34.0027,
    '1118_895': 33.6983,
    '1118_896': 33.3853,
    '1118_897': 33.0654,
    '1118_898': 32.7388,
    '1118_899': 32.4071,
    '1118_900': 32.0719,
    '1119_774': 38.2028,
    '1119_775': 38.2775,
    '1119_776': 38.3498,
    '1119_777': 38.4214,
    '1119_778': 38.4962,
    '1119_779': 38.581,
    '1119_780': 38.6846,
    '1119_781': 38.808,
    '1119_782': 38.9477,
    '1119_783': 39.1028,
    '1119_784': 39.2686,
    '1119_785': 39.4317,
    '1119_786': 39.5661,
    '1119_787': 39.6899,
    '1119_788': 39.7998,
    '1119_789': 39.8878,
    '1119_790': 39.9639,
    '1119_791': 40.0471,
    '1119_792': 40.1063,
    '1119_793': 40.1283,
    '1119_794': 40.1814,
    '1119_795': 40.2754,
    '1119_796': 40.3887,
    '1119_797': 40.4967,
    '1119_798': 40.5911,
    '1119_799': 40.6755,
    '1119_800': 40.7717,
    '1119_801': 40.8937,
    '1119_802': 40.987,
    '1119_803': 41.0361,
    '1119_804': 41.0463,
    '1119_805': 41.0307,
    '1119_806': 41.0068,
    '1119_807': 40.9838,
    '1119_808': 40.9668,
    '1119_809': 40.9489,
    '1119_810': 40.9314,
    '1119_811': 40.9169,
    '1119_812': 40.9189,
    '1119_813': 40.9302,
    '1119_814': 40.9569,
    '1119_815': 41.0127,
    '1119_816': 41.0941,
    '1119_817': 41.1982,
    '1119_818': 41.2975,
    '1119_819': 41.39,
    '1119_820': 41.4835,
    '1119_821': 41.5854,
    '1119_822': 41.6569,
    '1119_823': 41.6869,
    '1119_824': 41.6883,
    '1119_825': 41.6699,
    '1119_826': 41.634,
    '1119_827': 41.5994,
    '1119_828': 41.5633,
    '1119_829': 41.5325,
    '1119_830': 41.4969,
    '1119_831': 41.4561,
    '1119_832': 41.416,
    '1119_833': 41.3826,
    '1119_834': 41.3515,
    '1119_835': 41.3226,
    '1119_836': 41.2957,
    '1119_837': 41.2699,
    '1119_838': 41.2496,
    '1119_839': 41.238,
    '1119_840': 41.2369,
    '1119_841': 41.2476,
    '1119_842': 41.2668,
    '1119_843': 41.2831,
    '1119_844': 41.2896,
    '1119_845': 41.3098,
    '1119_846': 41.3507,
    '1119_847': 41.4008,
    '1119_848': 41.4515,
    '1119_849': 41.4999,
    '1119_850': 41.5416,
    '1119_851': 41.5715,
    '1119_852': 41.5978,
    '1119_853': 41.6187,
    '1119_854': 41.6213,
    '1119_855': 41.6007,
    '1119_856': 41.5559,
    '1119_857': 41.4859,
    '1119_858': 41.405,
    '1119_859': 41.3226,
    '1119_860': 41.2321,
    '1119_861': 41.13,
    '1119_862': 41.0179,
    '1119_863': 40.8965,
    '1119_864': 40.7629,
    '1119_865': 40.6164,
    '1119_866': 40.4725,
    '1119_867': 40.3225,
    '1119_868': 40.1583,
    '1119_869': 39.9821,
    '1119_870': 39.8009,
    '1119_871': 39.61,
    '1119_872': 39.4107,
    '1119_873': 39.1997,
    '1119_874': 38.9909,
    '1119_875': 38.7943,
    '1119_876': 38.5891,
    '1119_877': 38.386,
    '1119_878': 38.1796,
    '1119_879': 37.961,
    '1119_880': 37.7402,
    '1119_881': 37.5166,
    '1119_882': 37.2781,
    '1119_883': 37.0318,
    '1119_884': 36.7821,
    '1119_885': 36.5316,
    '1119_886': 36.2763,
    '1119_887': 36.0114,
    '1119_888': 35.7454,
    '1119_889': 35.4765,
    '1119_890': 35.1912,
    '1119_891': 34.8996,
    '1119_892': 34.6082,
    '1119_893': 34.3146,
    '1119_894': 34.0157,
    '1119_895': 33.708,
    '1119_896': 33.3903,
    '1119_897': 33.0654,
    '1119_898': 32.7333,
    '1119_899': 32.3943,
    '1119_900': 32.0541,
    '1120_774': 38.1458,
    '1120_775': 38.222,
    '1120_776': 38.2962,
    '1120_777': 38.3685,
    '1120_778': 38.4402,
    '1120_779': 38.5155,
    '1120_780': 38.6038,
    '1120_781': 38.7182,
    '1120_782': 38.8514,
    '1120_783': 39.0004,
    '1120_784': 39.1563,
    '1120_785': 39.314,
    '1120_786': 39.4569,
    '1120_787': 39.5801,
    '1120_788': 39.692,
    '1120_789': 39.7735,
    '1120_790': 39.8397,
    '1120_791': 39.917,
    '1120_792': 39.9791,
    '1120_793': 40.0028,
    '1120_794': 40.0569,
    '1120_795': 40.146,
    '1120_796': 40.2519,
    '1120_797': 40.3575,
    '1120_798': 40.4526,
    '1120_799': 40.5401,
    '1120_800': 40.6443,
    '1120_801': 40.7755,
    '1120_802': 40.8781,
    '1120_803': 40.931,
    '1120_804': 40.9479,
    '1120_805': 40.942,
    '1120_806': 40.9228,
    '1120_807': 40.9089,
    '1120_808': 40.9005,
    '1120_809': 40.8798,
    '1120_810': 40.8642,
    '1120_811': 40.8559,
    '1120_812': 40.872,
    '1120_813': 40.8966,
    '1120_814': 40.9405,
    '1120_815': 41.01,
    '1120_816': 41.1029,
    '1120_817': 41.217,
    '1120_818': 41.3227,
    '1120_819': 41.4107,
    '1120_820': 41.4894,
    '1120_821': 41.5728,
    '1120_822': 41.6378,
    '1120_823': 41.664,
    '1120_824': 41.6684,
    '1120_825': 41.6664,
    '1120_826': 41.6478,
    '1120_827': 41.6215,
    '1120_828': 41.5931,
    '1120_829': 41.5633,
    '1120_830': 41.5295,
    '1120_831': 41.4868,
    '1120_832': 41.4444,
    '1120_833': 41.4055,
    '1120_834': 41.3672,
    '1120_835': 41.3308,
    '1120_836': 41.2974,
    '1120_837': 41.2697,
    '1120_838': 41.2473,
    '1120_839': 41.2345,
    '1120_840': 41.2351,
    '1120_841': 41.2474,
    '1120_842': 41.2664,
    '1120_843': 41.2804,
    '1120_844': 41.2823,
    '1120_845': 41.3005,
    '1120_846': 41.3391,
    '1120_847': 41.3878,
    '1120_848': 41.4379,
    '1120_849': 41.4876,
    '1120_850': 41.5297,
    '1120_851': 41.5643,
    '1120_852': 41.5951,
    '1120_853': 41.618,
    '1120_854': 41.6213,
    '1120_855': 41.6015,
    '1120_856': 41.5517,
    '1120_857': 41.4792,
    '1120_858': 41.3988,
    '1120_859': 41.3156,
    '1120_860': 41.2266,
    '1120_861': 41.1293,
    '1120_862': 41.0232,
    '1120_863': 40.9076,
    '1120_864': 40.7788,
    '1120_865': 40.6277,
    '1120_866': 40.4854,
    '1120_867': 40.3374,
    '1120_868': 40.1702,
    '1120_869': 39.9883,
    '1120_870': 39.8084,
    '1120_871': 39.6144,
    '1120_872': 39.4241,
    '1120_873': 39.2214,
    '1120_874': 39.0126,
    '1120_875': 38.8128,
    '1120_876': 38.6049,
    '1120_877': 38.4048,
    '1120_878': 38.2023,
    '1120_879': 37.9843,
    '1120_880': 37.7665,
    '1120_881': 37.5447,
    '1120_882': 37.3143,
    '1120_883': 37.0707,
    '1120_884': 36.8177,
    '1120_885': 36.5607,
    '1120_886': 36.3042,
    '1120_887': 36.0337,
    '1120_888': 35.7634,
    '1120_889': 35.4917,
    '1120_890': 35.2082,
    '1120_891': 34.9155,
    '1120_892': 34.6238,
    '1120_893': 34.3296,
    '1120_894': 34.0295,
    '1120_895': 33.7192,
    '1120_896': 33.3969,
    '1120_897': 33.068,
    '1120_898': 32.7323,
    '1120_899': 32.3864,
    '1120_900': 32.0415,
    '1121_774': 38.0921,
    '1121_775': 38.1697,
    '1121_776': 38.2463,
    '1121_777': 38.3204,
    '1121_778': 38.3908,
    '1121_779': 38.4579,
    '1121_780': 38.5344,
    '1121_781': 38.6325,
    '1121_782': 38.7552,
    '1121_783': 38.8968,
    '1121_784': 39.0444,
    '1121_785': 39.1937,
    '1121_786': 39.3349,
    '1121_787': 39.4554,
    '1121_788': 39.5635,
    '1121_789': 39.6488,
    '1121_790': 39.7119,
    '1121_791': 39.7832,
    '1121_792': 39.8467,
    '1121_793': 39.877,
    '1121_794': 39.934,
    '1121_795': 40.0195,
    '1121_796': 40.1197,
    '1121_797': 40.2235,
    '1121_798': 40.3225,
    '1121_799': 40.4189,
    '1121_800': 40.528,
    '1121_801': 40.6634,
    '1121_802': 40.771,
    '1121_803': 40.8322,
    '1121_804': 40.8636,
    '1121_805': 40.8646,
    '1121_806': 40.85,
    '1121_807': 40.8432,
    '1121_808': 40.8371,
    '1121_809': 40.8154,
    '1121_810': 40.8035,
    '1121_811': 40.8074,
    '1121_812': 40.8352,
    '1121_813': 40.8756,
    '1121_814': 40.9348,
    '1121_815': 41.0171,
    '1121_816': 41.1184,
    '1121_817': 41.2355,
    '1121_818': 41.3416,
    '1121_819': 41.4269,
    '1121_820': 41.4924,
    '1121_821': 41.5509,
    '1121_822': 41.6025,
    '1121_823': 41.6259,
    '1121_824': 41.6406,
    '1121_825': 41.6547,
    '1121_826': 41.6519,
    '1121_827': 41.6402,
    '1121_828': 41.6204,
    '1121_829': 41.5926,
    '1121_830': 41.5586,
    '1121_831': 41.518,
    '1121_832': 41.4726,
    '1121_833': 41.4272,
    '1121_834': 41.3834,
    '1121_835': 41.3454,
    '1121_836': 41.3087,
    '1121_837': 41.2762,
    '1121_838': 41.2518,
    '1121_839': 41.2379,
    '1121_840': 41.2389,
    '1121_841': 41.2498,
    '1121_842': 41.2672,
    '1121_843': 41.2757,
    '1121_844': 41.2797,
    '1121_845': 41.2978,
    '1121_846': 41.3344,
    '1121_847': 41.3813,
    '1121_848': 41.43,
    '1121_849': 41.4795,
    '1121_850': 41.522,
    '1121_851': 41.5569,
    '1121_852': 41.5882,
    '1121_853': 41.6168,
    '1121_854': 41.623,
    '1121_855': 41.6005,
    '1121_856': 41.5479,
    '1121_857': 41.4757,
    '1121_858': 41.4016,
    '1121_859': 41.32,
    '1121_860': 41.2288,
    '1121_861': 41.1323,
    '1121_862': 41.0294,
    '1121_863': 40.917,
    '1121_864': 40.7916,
    '1121_865': 40.65,
    '1121_866': 40.5049,
    '1121_867': 40.3525,
    '1121_868': 40.1861,
    '1121_869': 40.0081,
    '1121_870': 39.8255,
    '1121_871': 39.6348,
    '1121_872': 39.4419,
    '1121_873': 39.2424,
    '1121_874': 39.0397,
    '1121_875': 38.8388,
    '1121_876': 38.6364,
    '1121_877': 38.4377,
    '1121_878': 38.2376,
    '1121_879': 38.0278,
    '1121_880': 37.815,
    '1121_881': 37.5981,
    '1121_882': 37.3631,
    '1121_883': 37.1133,
    '1121_884': 36.8465,
    '1121_885': 36.5692,
    '1121_886': 36.3114,
    '1121_887': 36.0491,
    '1121_888': 35.7836,
    '1121_889': 35.5123,
    '1121_890': 35.2316,
    '1121_891': 34.9428,
    '1121_892': 34.6468,
    '1121_893': 34.3465,
    '1121_894': 34.0419,
    '1121_895': 33.7271,
    '1121_896': 33.4029,
    '1121_897': 33.0721,
    '1121_898': 32.7373,
    '1121_899': 32.4003,
    '1121_900': 32.0532,
    '1122_774': 38.0398,
    '1122_775': 38.1182,
    '1122_776': 38.1964,
    '1122_777': 38.2723,
    '1122_778': 38.3424,
    '1122_779': 38.3996,
    '1122_780': 38.4636,
    '1122_781': 38.5457,
    '1122_782': 38.6595,
    '1122_783': 38.7931,
    '1122_784': 38.9358,
    '1122_785': 39.0756,
    '1122_786': 39.208,
    '1122_787': 39.3275,
    '1122_788': 39.4316,
    '1122_789': 39.5141,
    '1122_790': 39.5826,
    '1122_791': 39.65,
    '1122_792': 39.711,
    '1122_793': 39.7553,
    '1122_794': 39.8167,
    '1122_795': 39.8979,
    '1122_796': 39.9941,
    '1122_797': 40.0978,
    '1122_798': 40.2013,
    '1122_799': 40.3071,
    '1122_800': 40.4199,
    '1122_801': 40.5557,
    '1122_802': 40.6703,
    '1122_803': 40.7395,
    '1122_804': 40.7819,
    '1122_805': 40.7905,
    '1122_806': 40.7857,
    '1122_807': 40.7844,
    '1122_808': 40.7803,
    '1122_809': 40.7616,
    '1122_810': 40.7553,
    '1122_811': 40.7704,
    '1122_812': 40.8061,
    '1122_813': 40.8585,
    '1122_814': 40.93,
    '1122_815': 41.0277,
    '1122_816': 41.1387,
    '1122_817': 41.2565,
    '1122_818': 41.3561,
    '1122_819': 41.4326,
    '1122_820': 41.4811,
    '1122_821': 41.5184,
    '1122_822': 41.5586,
    '1122_823': 41.5921,
    '1122_824': 41.6294,
    '1122_825': 41.648,
    '1122_826': 41.6461,
    '1122_827': 41.641,
    '1122_828': 41.6339,
    '1122_829': 41.6138,
    '1122_830': 41.5787,
    '1122_831': 41.5414,
    '1122_832': 41.4966,
    '1122_833': 41.449,
    '1122_834': 41.4058,
    '1122_835': 41.3687,
    '1122_836': 41.3287,
    '1122_837': 41.292,
    '1122_838': 41.2644,
    '1122_839': 41.2478,
    '1122_840': 41.2463,
    '1122_841': 41.2534,
    '1122_842': 41.2648,
    '1122_843': 41.2753,
    '1122_844': 41.2849,
    '1122_845': 41.3023,
    '1122_846': 41.337,
    '1122_847': 41.3839,
    '1122_848': 41.4316,
    '1122_849': 41.4781,
    '1122_850': 41.5165,
    '1122_851': 41.55,
    '1122_852': 41.582,
    '1122_853': 41.6076,
    '1122_854': 41.6146,
    '1122_855': 41.5949,
    '1122_856': 41.5455,
    '1122_857': 41.4839,
    '1122_858': 41.4186,
    '1122_859': 41.3338,
    '1122_860': 41.2388,
    '1122_861': 41.139,
    '1122_862': 41.0366,
    '1122_863': 40.9275,
    '1122_864': 40.8073,
    '1122_865': 40.6716,
    '1122_866': 40.5268,
    '1122_867': 40.3729,
    '1122_868': 40.208,
    '1122_869': 40.0331,
    '1122_870': 39.8514,
    '1122_871': 39.6625,
    '1122_872': 39.4684,
    '1122_873': 39.2704,
    '1122_874': 39.071,
    '1122_875': 38.872,
    '1122_876': 38.672,
    '1122_877': 38.4748,
    '1122_878': 38.2802,
    '1122_879': 38.0742,
    '1122_880': 37.8626,
    '1122_881': 37.6453,
    '1122_882': 37.4138,
    '1122_883': 37.1654,
    '1122_884': 36.8946,
    '1122_885': 36.6141,
    '1122_886': 36.3353,
    '1122_887': 36.0693,
    '1122_888': 35.8071,
    '1122_889': 35.5358,
    '1122_890': 35.2545,
    '1122_891': 34.9656,
    '1122_892': 34.668,
    '1122_893': 34.3643,
    '1122_894': 34.0527,
    '1122_895': 33.7315,
    '1122_896': 33.4048,
    '1122_897': 33.0714,
    '1122_898': 32.7353,
    '1122_899': 32.3977,
    '1122_900': 32.056,
    '1123_774': 37.9875,
    '1123_775': 38.0651,
    '1123_776': 38.1424,
    '1123_777': 38.2152,
    '1123_778': 38.2779,
    '1123_779': 38.3343,
    '1123_780': 38.3949,
    '1123_781': 38.4699,
    '1123_782': 38.5719,
    '1123_783': 38.6998,
    '1123_784': 38.8365,
    '1123_785': 38.9714,
    '1123_786': 39.0963,
    '1123_787': 39.2112,
    '1123_788': 39.3131,
    '1123_789': 39.3944,
    '1123_790': 39.4615,
    '1123_791': 39.5254,
    '1123_792': 39.5862,
    '1123_793': 39.6418,
    '1123_794': 39.7049,
    '1123_795': 39.7834,
    '1123_796': 39.8775,
    '1123_797': 39.9822,
    '1123_798': 40.0892,
    '1123_799': 40.2003,
    '1123_800': 40.3186,
    '1123_801': 40.4577,
    '1123_802': 40.5839,
    '1123_803': 40.6613,
    '1123_804': 40.7099,
    '1123_805': 40.7267,
    '1123_806': 40.7321,
    '1123_807': 40.7306,
    '1123_808': 40.7285,
    '1123_809': 40.717,
    '1123_810': 40.7183,
    '1123_811': 40.7411,
    '1123_812': 40.7861,
    '1123_813': 40.8504,
    '1123_814': 40.9371,
    '1123_815': 41.0446,
    '1123_816': 41.161,
    '1123_817': 41.2775,
    '1123_818': 41.3689,
    '1123_819': 41.4258,
    '1123_820': 41.4491,
    '1123_821': 41.4765,
    '1123_822': 41.5199,
    '1123_823': 41.5684,
    '1123_824': 41.6143,
    '1123_825': 41.6301,
    '1123_826': 41.6308,
    '1123_827': 41.6306,
    '1123_828': 41.6303,
    '1123_829': 41.6116,
    '1123_830': 41.5804,
    '1123_831': 41.5548,
    '1123_832': 41.5152,
    '1123_833': 41.4751,
    '1123_834': 41.4387,
    '1123_835': 41.3999,
    '1123_836': 41.3565,
    '1123_837': 41.3151,
    '1123_838': 41.2816,
    '1123_839': 41.2614,
    '1123_840': 41.2556,
    '1123_841': 41.2606,
    '1123_842': 41.2707,
    '1123_843': 41.2843,
    '1123_844': 41.2962,
    '1123_845': 41.3138,
    '1123_846': 41.3478,
    '1123_847': 41.3939,
    '1123_848': 41.4409,
    '1123_849': 41.4816,
    '1123_850': 41.5156,
    '1123_851': 41.5472,
    '1123_852': 41.5767,
    '1123_853': 41.5998,
    '1123_854': 41.6079,
    '1123_855': 41.5949,
    '1123_856': 41.5571,
    '1123_857': 41.5028,
    '1123_858': 41.4436,
    '1123_859': 41.3555,
    '1123_860': 41.2534,
    '1123_861': 41.1504,
    '1123_862': 41.0472,
    '1123_863': 40.9379,
    '1123_864': 40.8206,
    '1123_865': 40.6914,
    '1123_866': 40.5501,
    '1123_867': 40.3978,
    '1123_868': 40.2352,
    '1123_869': 40.0634,
    '1123_870': 39.885,
    '1123_871': 39.6991,
    '1123_872': 39.5057,
    '1123_873': 39.3075,
    '1123_874': 39.1073,
    '1123_875': 38.908,
    '1123_876': 38.7107,
    '1123_877': 38.517,
    '1123_878': 38.3235,
    '1123_879': 38.1205,
    '1123_880': 37.9088,
    '1123_881': 37.6923,
    '1123_882': 37.4645,
    '1123_883': 37.2168,
    '1123_884': 36.9462,
    '1123_885': 36.6561,
    '1123_886': 36.3623,
    '1123_887': 36.0918,
    '1123_888': 35.8324,
    '1123_889': 35.5584,
    '1123_890': 35.274,
    '1123_891': 34.9834,
    '1123_892': 34.6829,
    '1123_893': 34.3726,
    '1123_894': 34.0509,
    '1123_895': 33.7273,
    '1123_896': 33.3983,
    '1123_897': 33.0635,
    '1123_898': 32.7253,
    '1123_899': 32.3851,
    '1123_900': 32.0423,
    '1124_774': 37.9313,
    '1124_775': 38.0059,
    '1124_776': 38.08,
    '1124_777': 38.1471,
    '1124_778': 38.2081,
    '1124_779': 38.2666,
    '1124_780': 38.3282,
    '1124_781': 38.3998,
    '1124_782': 38.4921,
    '1124_783': 38.6186,
    '1124_784': 38.7521,
    '1124_785': 38.8805,
    '1124_786': 38.9995,
    '1124_787': 39.108,
    '1124_788': 39.2088,
    '1124_789': 39.2952,
    '1124_790': 39.3593,
    '1124_791': 39.4193,
    '1124_792': 39.478,
    '1124_793': 39.5358,
    '1124_794': 39.5983,
    '1124_795': 39.676,
    '1124_796': 39.7687,
    '1124_797': 39.8715,
    '1124_798': 39.9818,
    '1124_799': 40.1031,
    '1124_800': 40.2318,
    '1124_801': 40.3729,
    '1124_802': 40.5101,
    '1124_803': 40.5955,
    '1124_804': 40.6496,
    '1124_805': 40.6747,
    '1124_806': 40.6849,
    '1124_807': 40.6863,
    '1124_808': 40.6865,
    '1124_809': 40.6857,
    '1124_810': 40.6906,
    '1124_811': 40.7193,
    '1124_812': 40.7701,
    '1124_813': 40.8475,
    '1124_814': 40.9481,
    '1124_815': 41.0727,
    '1124_816': 41.1956,
    '1124_817': 41.3002,
    '1124_818': 41.3713,
    '1124_819': 41.4004,
    '1124_820': 41.4148,
    '1124_821': 41.4437,
    '1124_822': 41.4987,
    '1124_823': 41.5589,
    '1124_824': 41.6019,
    '1124_825': 41.6295,
    '1124_826': 41.6458,
    '1124_827': 41.6481,
    '1124_828': 41.6359,
    '1124_829': 41.6132,
    '1124_830': 41.5811,
    '1124_831': 41.5628,
    '1124_832': 41.535,
    '1124_833': 41.5042,
    '1124_834': 41.469,
    '1124_835': 41.4274,
    '1124_836': 41.3806,
    '1124_837': 41.3367,
    '1124_838': 41.3,
    '1124_839': 41.2773,
    '1124_840': 41.2687,
    '1124_841': 41.2717,
    '1124_842': 41.2819,
    '1124_843': 41.2965,
    '1124_844': 41.3109,
    '1124_845': 41.33,
    '1124_846': 41.3658,
    '1124_847': 41.4093,
    '1124_848': 41.4517,
    '1124_849': 41.4891,
    '1124_850': 41.5201,
    '1124_851': 41.5501,
    '1124_852': 41.5773,
    '1124_853': 41.596,
    '1124_854': 41.6037,
    '1124_855': 41.5928,
    '1124_856': 41.5661,
    '1124_857': 41.5248,
    '1124_858': 41.4666,
    '1124_859': 41.3785,
    '1124_860': 41.2703,
    '1124_861': 41.1636,
    '1124_862': 41.0574,
    '1124_863': 40.9488,
    '1124_864': 40.834,
    '1124_865': 40.71,
    '1124_866': 40.5734,
    '1124_867': 40.4232,
    '1124_868': 40.2643,
    '1124_869': 40.0975,
    '1124_870': 39.9215,
    '1124_871': 39.739,
    '1124_872': 39.5466,
    '1124_873': 39.3498,
    '1124_874': 39.1512,
    '1124_875': 38.9525,
    '1124_876': 38.7528,
    '1124_877': 38.5623,
    '1124_878': 38.3713,
    '1124_879': 38.1711,
    '1124_880': 37.9563,
    '1124_881': 37.7403,
    '1124_882': 37.5138,
    '1124_883': 37.273,
    '1124_884': 37.0097,
    '1124_885': 36.7153,
    '1124_886': 36.42,
    '1124_887': 36.1211,
    '1124_888': 35.8474,
    '1124_889': 35.5679,
    '1124_890': 35.2847,
    '1124_891': 34.993,
    '1124_892': 34.6916,
    '1124_893': 34.3804,
    '1124_894': 34.0532,
    '1124_895': 33.7229,
    '1124_896': 33.3892,
    '1124_897': 33.0507,
    '1124_898': 32.7098,
    '1124_899': 32.3672,
    '1124_900': 32.0204,
    '1125_774': 37.868,
    '1125_775': 37.9375,
    '1125_776': 38.0072,
    '1125_777': 38.0737,
    '1125_778': 38.1374,
    '1125_779': 38.2005,
    '1125_780': 38.2666,
    '1125_781': 38.3401,
    '1125_782': 38.4295,
    '1125_783': 38.5552,
    '1125_784': 38.6817,
    '1125_785': 38.8022,
    '1125_786': 38.9105,
    '1125_787': 39.0135,
    '1125_788': 39.1109,
    '1125_789': 39.2018,
    '1125_790': 39.2687,
    '1125_791': 39.3215,
    '1125_792': 39.3762,
    '1125_793': 39.4337,
    '1125_794': 39.4986,
    '1125_795': 39.5773,
    '1125_796': 39.6693,
    '1125_797': 39.7718,
    '1125_798': 39.8849,
    '1125_799': 40.0138,
    '1125_800': 40.1522,
    '1125_801': 40.3028,
    '1125_802': 40.4437,
    '1125_803': 40.5366,
    '1125_804': 40.6036,
    '1125_805': 40.6429,
    '1125_806': 40.6551,
    '1125_807': 40.6542,
    '1125_808': 40.6548,
    '1125_809': 40.6582,
    '1125_810': 40.6702,
    '1125_811': 40.704,
    '1125_812': 40.7606,
    '1125_813': 40.8468,
    '1125_814': 40.9608,
    '1125_815': 41.0998,
    '1125_816': 41.2279,
    '1125_817': 41.3134,
    '1125_818': 41.3653,
    '1125_819': 41.3788,
    '1125_820': 41.3947,
    '1125_821': 41.4321,
    '1125_822': 41.4935,
    '1125_823': 41.5618,
    '1125_824': 41.6198,
    '1125_825': 41.6646,
    '1125_826': 41.6914,
    '1125_827': 41.6912,
    '1125_828': 41.6674,
    '1125_829': 41.6357,
    '1125_830': 41.6064,
    '1125_831': 41.5785,
    '1125_832': 41.5463,
    '1125_833': 41.5167,
    '1125_834': 41.4875,
    '1125_835': 41.4512,
    '1125_836': 41.4065,
    '1125_837': 41.3604,
    '1125_838': 41.3222,
    '1125_839': 41.2983,
    '1125_840': 41.2878,
    '1125_841': 41.2871,
    '1125_842': 41.2946,
    '1125_843': 41.306,
    '1125_844': 41.3221,
    '1125_845': 41.3471,
    '1125_846': 41.3857,
    '1125_847': 41.426,
    '1125_848': 41.4628,
    '1125_849': 41.4987,
    '1125_850': 41.5295,
    '1125_851': 41.5601,
    '1125_852': 41.5877,
    '1125_853': 41.5966,
    '1125_854': 41.5986,
    '1125_855': 41.5897,
    '1125_856': 41.5668,
    '1125_857': 41.5293,
    '1125_858': 41.4748,
    '1125_859': 41.3973,
    '1125_860': 41.2842,
    '1125_861': 41.1741,
    '1125_862': 41.0672,
    '1125_863': 40.9594,
    '1125_864': 40.8458,
    '1125_865': 40.7234,
    '1125_866': 40.5895,
    '1125_867': 40.4439,
    '1125_868': 40.2889,
    '1125_869': 40.1252,
    '1125_870': 39.9528,
    '1125_871': 39.7729,
    '1125_872': 39.5849,
    '1125_873': 39.3915,
    '1125_874': 39.1951,
    '1125_875': 38.9965,
    '1125_876': 38.7998,
    '1125_877': 38.6161,
    '1125_878': 38.4299,
    '1125_879': 38.2338,
    '1125_880': 38.017,
    '1125_881': 37.7953,
    '1125_882': 37.5683,
    '1125_883': 37.3295,
    '1125_884': 37.0785,
    '1125_885': 36.7804,
    '1125_886': 36.4734,
    '1125_887': 36.1707,
    '1125_888': 35.8804,
    '1125_889': 35.5916,
    '1125_890': 35.3002,
    '1125_891': 35.0017,
    '1125_892': 34.6959,
    '1125_893': 34.3835,
    '1125_894': 34.0617,
    '1125_895': 33.719,
    '1125_896': 33.3774,
    '1125_897': 33.0343,
    '1125_898': 32.6913,
    '1125_899': 32.3473,
    '1125_900': 32.001,
    '1126_774': 37.7939,
    '1126_775': 37.8618,
    '1126_776': 37.931,
    '1126_777': 37.9998,
    '1126_778': 38.0684,
    '1126_779': 38.1381,
    '1126_780': 38.2116,
    '1126_781': 38.2955,
    '1126_782': 38.3948,
    '1126_783': 38.5099,
    '1126_784': 38.6172,
    '1126_785': 38.7224,
    '1126_786': 38.8235,
    '1126_787': 38.9219,
    '1126_788': 39.0153,
    '1126_789': 39.1031,
    '1126_790': 39.1769,
    '1126_791': 39.228,
    '1126_792': 39.2791,
    '1126_793': 39.3359,
    '1126_794': 39.4019,
    '1126_795': 39.4834,
    '1126_796': 39.5773,
    '1126_797': 39.6824,
    '1126_798': 39.7988,
    '1126_799': 39.9322,
    '1126_800': 40.0803,
    '1126_801': 40.2375,
    '1126_802': 40.3884,
    '1126_803': 40.502,
    '1126_804': 40.5762,
    '1126_805': 40.6213,
    '1126_806': 40.63,
    '1126_807': 40.6293,
    '1126_808': 40.6303,
    '1126_809': 40.638,
    '1126_810': 40.6595,
    '1126_811': 40.6973,
    '1126_812': 40.7573,
    '1126_813': 40.8477,
    '1126_814': 40.973,
    '1126_815': 41.1271,
    '1126_816': 41.2595,
    '1126_817': 41.3371,
    '1126_818': 41.3672,
    '1126_819': 41.3841,
    '1126_820': 41.4093,
    '1126_821': 41.4514,
    '1126_822': 41.5111,
    '1126_823': 41.5886,
    '1126_824': 41.6618,
    '1126_825': 41.7027,
    '1126_826': 41.7161,
    '1126_827': 41.711,
    '1126_828': 41.6932,
    '1126_829': 41.6733,
    '1126_830': 41.6446,
    '1126_831': 41.6012,
    '1126_832': 41.5587,
    '1126_833': 41.5263,
    '1126_834': 41.504,
    '1126_835': 41.4749,
    '1126_836': 41.4347,
    '1126_837': 41.3883,
    '1126_838': 41.3487,
    '1126_839': 41.3215,
    '1126_840': 41.3089,
    '1126_841': 41.3054,
    '1126_842': 41.3089,
    '1126_843': 41.3169,
    '1126_844': 41.3329,
    '1126_845': 41.3604,
    '1126_846': 41.4003,
    '1126_847': 41.4403,
    '1126_848': 41.4755,
    '1126_849': 41.512,
    '1126_850': 41.5442,
    '1126_851': 41.5738,
    '1126_852': 41.6,
    '1126_853': 41.6067,
    '1126_854': 41.6041,
    '1126_855': 41.5934,
    '1126_856': 41.5677,
    '1126_857': 41.5263,
    '1126_858': 41.4698,
    '1126_859': 41.3946,
    '1126_860': 41.2893,
    '1126_861': 41.1847,
    '1126_862': 41.0802,
    '1126_863': 40.9729,
    '1126_864': 40.8595,
    '1126_865': 40.7379,
    '1126_866': 40.6062,
    '1126_867': 40.4641,
    '1126_868': 40.3126,
    '1126_869': 40.152,
    '1126_870': 39.983,
    '1126_871': 39.8067,
    '1126_872': 39.6233,
    '1126_873': 39.4339,
    '1126_874': 39.2405,
    '1126_875': 39.045,
    '1126_876': 38.8517,
    '1126_877': 38.675,
    '1126_878': 38.488,
    '1126_879': 38.2908,
    '1126_880': 38.0725,
    '1126_881': 37.8454,
    '1126_882': 37.6107,
    '1126_883': 37.3612,
    '1126_884': 37.0996,
    '1126_885': 36.825,
    '1126_886': 36.5247,
    '1126_887': 36.2188,
    '1126_888': 35.9178,
    '1126_889': 35.6195,
    '1126_890': 35.3189,
    '1126_891': 35.0121,
    '1126_892': 34.6994,
    '1126_893': 34.3823,
    '1126_894': 34.0616,
    '1126_895': 33.7128,
    '1126_896': 33.3709,
    '1126_897': 33.0232,
    '1126_898': 32.6779,
    '1126_899': 32.3332,
    '1126_900': 31.9859,
    '1127_774': 37.7157,
    '1127_775': 37.7827,
    '1127_776': 37.8531,
    '1127_777': 37.9251,
    '1127_778': 37.9991,
    '1127_779': 38.0742,
    '1127_780': 38.1516,
    '1127_781': 38.2426,
    '1127_782': 38.345,
    '1127_783': 38.4447,
    '1127_784': 38.5423,
    '1127_785': 38.6398,
    '1127_786': 38.736,
    '1127_787': 38.8294,
    '1127_788': 38.9198,
    '1127_789': 39.0036,
    '1127_790': 39.0783,
    '1127_791': 39.1332,
    '1127_792': 39.184,
    '1127_793': 39.2411,
    '1127_794': 39.3094,
    '1127_795': 39.394,
    '1127_796': 39.4913,
    '1127_797': 39.6005,
    '1127_798': 39.7218,
    '1127_799': 39.8589,
    '1127_800': 40.0137,
    '1127_801': 40.1837,
    '1127_802': 40.3552,
    '1127_803': 40.4835,
    '1127_804': 40.5602,
    '1127_805': 40.6017,
    '1127_806': 40.6101,
    '1127_807': 40.6129,
    '1127_808': 40.6162,
    '1127_809': 40.6222,
    '1127_810': 40.6497,
    '1127_811': 40.6928,
    '1127_812': 40.7578,
    '1127_813': 40.8515,
    '1127_814': 40.9815,
    '1127_815': 41.1406,
    '1127_816': 41.2835,
    '1127_817': 41.3677,
    '1127_818': 41.3941,
    '1127_819': 41.4174,
    '1127_820': 41.453,
    '1127_821': 41.4977,
    '1127_822': 41.5542,
    '1127_823': 41.63,
    '1127_824': 41.6948,
    '1127_825': 41.7126,
    '1127_826': 41.7078,
    '1127_827': 41.7033,
    '1127_828': 41.7012,
    '1127_829': 41.6971,
    '1127_830': 41.6758,
    '1127_831': 41.629,
    '1127_832': 41.5795,
    '1127_833': 41.5484,
    '1127_834': 41.5291,
    '1127_835': 41.5055,
    '1127_836': 41.4644,
    '1127_837': 41.4158,
    '1127_838': 41.3732,
    '1127_839': 41.3433,
    '1127_840': 41.3287,
    '1127_841': 41.3223,
    '1127_842': 41.3232,
    '1127_843': 41.3296,
    '1127_844': 41.3454,
    '1127_845': 41.3728,
    '1127_846': 41.4125,
    '1127_847': 41.4536,
    '1127_848': 41.4919,
    '1127_849': 41.5288,
    '1127_850': 41.5606,
    '1127_851': 41.5909,
    '1127_852': 41.6151,
    '1127_853': 41.6192,
    '1127_854': 41.6165,
    '1127_855': 41.5995,
    '1127_856': 41.5716,
    '1127_857': 41.5312,
    '1127_858': 41.4738,
    '1127_859': 41.3959,
    '1127_860': 41.3023,
    '1127_861': 41.2031,
    '1127_862': 41.0976,
    '1127_863': 40.9893,
    '1127_864': 40.8757,
    '1127_865': 40.7549,
    '1127_866': 40.625,
    '1127_867': 40.4854,
    '1127_868': 40.3366,
    '1127_869': 40.1789,
    '1127_870': 40.0133,
    '1127_871': 39.8408,
    '1127_872': 39.6619,
    '1127_873': 39.4772,
    '1127_874': 39.2883,
    '1127_875': 39.1014,
    '1127_876': 38.917,
    '1127_877': 38.7346,
    '1127_878': 38.5421,
    '1127_879': 38.3428,
    '1127_880': 38.1272,
    '1127_881': 37.8996,
    '1127_882': 37.6617,
    '1127_883': 37.4129,
    '1127_884': 37.1435,
    '1127_885': 36.8766,
    '1127_886': 36.5706,
    '1127_887': 36.2587,
    '1127_888': 35.949,
    '1127_889': 35.6419,
    '1127_890': 35.3344,
    '1127_891': 35.0236,
    '1127_892': 34.7089,
    '1127_893': 34.3908,
    '1127_894': 34.069,
    '1127_895': 33.7336,
    '1127_896': 33.3846,
    '1127_897': 33.0307,
    '1127_898': 32.6764,
    '1127_899': 32.3301,
    '1127_900': 31.9811,
    '1128_774': 37.6369,
    '1128_775': 37.7036,
    '1128_776': 37.7753,
    '1128_777': 37.8495,
    '1128_778': 37.928,
    '1128_779': 38.006,
    '1128_780': 38.0806,
    '1128_781': 38.1733,
    '1128_782': 38.2707,
    '1128_783': 38.3665,
    '1128_784': 38.4619,
    '1128_785': 38.5577,
    '1128_786': 38.6513,
    '1128_787': 38.7407,
    '1128_788': 38.8252,
    '1128_789': 38.904,
    '1128_790': 38.9735,
    '1128_791': 39.0335,
    '1128_792': 39.0905,
    '1128_793': 39.1504,
    '1128_794': 39.2221,
    '1128_795': 39.311,
    '1128_796': 39.4128,
    '1128_797': 39.527,
    '1128_798': 39.6541,
    '1128_799': 39.7965,
    '1128_800': 39.9616,
    '1128_801': 40.145,
    '1128_802': 40.3251,
    '1128_803': 40.462,
    '1128_804': 40.547,
    '1128_805': 40.586,
    '1128_806': 40.5958,
    '1128_807': 40.5986,
    '1128_808': 40.6035,
    '1128_809': 40.6128,
    '1128_810': 40.6465,
    '1128_811': 40.693,
    '1128_812': 40.7621,
    '1128_813': 40.8587,
    '1128_814': 40.9873,
    '1128_815': 41.1456,
    '1128_816': 41.2976,
    '1128_817': 41.3965,
    '1128_818': 41.4411,
    '1128_819': 41.4786,
    '1128_820': 41.5214,
    '1128_821': 41.5658,
    '1128_822': 41.6183,
    '1128_823': 41.6843,
    '1128_824': 41.725,
    '1128_825': 41.7124,
    '1128_826': 41.6885,
    '1128_827': 41.6972,
    '1128_828': 41.7145,
    '1128_829': 41.7216,
    '1128_830': 41.7011,
    '1128_831': 41.6572,
    '1128_832': 41.6133,
    '1128_833': 41.5842,
    '1128_834': 41.5681,
    '1128_835': 41.5416,
    '1128_836': 41.493,
    '1128_837': 41.4389,
    '1128_838': 41.3923,
    '1128_839': 41.3589,
    '1128_840': 41.3446,
    '1128_841': 41.3375,
    '1128_842': 41.3358,
    '1128_843': 41.3427,
    '1128_844': 41.3604,
    '1128_845': 41.3897,
    '1128_846': 41.4304,
    '1128_847': 41.4717,
    '1128_848': 41.5105,
    '1128_849': 41.546,
    '1128_850': 41.5785,
    '1128_851': 41.6087,
    '1128_852': 41.6299,
    '1128_853': 41.6388,
    '1128_854': 41.641,
    '1128_855': 41.6204,
    '1128_856': 41.5921,
    '1128_857': 41.5523,
    '1128_858': 41.4945,
    '1128_859': 41.4213,
    '1128_860': 41.3329,
    '1128_861': 41.2338,
    '1128_862': 41.1222,
    '1128_863': 41.0092,
    '1128_864': 40.8951,
    '1128_865': 40.7752,
    '1128_866': 40.6467,
    '1128_867': 40.5088,
    '1128_868': 40.3622,
    '1128_869': 40.2068,
    '1128_870': 40.044,
    '1128_871': 39.8749,
    '1128_872': 39.7005,
    '1128_873': 39.5205,
    '1128_874': 39.3341,
    '1128_875': 39.1572,
    '1128_876': 38.982,
    '1128_877': 38.7987,
    '1128_878': 38.6029,
    '1128_879': 38.4009,
    '1128_880': 38.1853,
    '1128_881': 37.9557,
    '1128_882': 37.7112,
    '1128_883': 37.4563,
    '1128_884': 37.1893,
    '1128_885': 36.9083,
    '1128_886': 36.6017,
    '1128_887': 36.2892,
    '1128_888': 35.9735,
    '1128_889': 35.66,
    '1128_890': 35.3469,
    '1128_891': 35.0324,
    '1128_892': 34.7151,
    '1128_893': 34.3938,
    '1128_894': 34.0687,
    '1128_895': 33.7326,
    '1128_896': 33.3895,
    '1128_897': 33.0406,
    '1128_898': 32.6854,
    '1128_899': 32.3346,
    '1128_900': 31.9801,
    '1129_774': 37.5593,
    '1129_775': 37.6265,
    '1129_776': 37.7001,
    '1129_777': 37.7731,
    '1129_778': 37.8529,
    '1129_779': 37.9252,
    '1129_780': 38.0089,
    '1129_781': 38.1013,
    '1129_782': 38.1958,
    '1129_783': 38.2903,
    '1129_784': 38.387,
    '1129_785': 38.4817,
    '1129_786': 38.5727,
    '1129_787': 38.6581,
    '1129_788': 38.7374,
    '1129_789': 38.8109,
    '1129_790': 38.8767,
    '1129_791': 38.9377,
    '1129_792': 39.0002,
    '1129_793': 39.065,
    '1129_794': 39.1415,
    '1129_795': 39.2351,
    '1129_796': 39.3418,
    '1129_797': 39.4615,
    '1129_798': 39.5952,
    '1129_799': 39.7465,
    '1129_800': 39.9234,
    '1129_801': 40.113,
    '1129_802': 40.2953,
    '1129_803': 40.4383,
    '1129_804': 40.5297,
    '1129_805': 40.5738,
    '1129_806': 40.5816,
    '1129_807': 40.5873,
    '1129_808': 40.5964,
    '1129_809': 40.6092,
    '1129_810': 40.6444,
    '1129_811': 40.6963,
    '1129_812': 40.769,
    '1129_813': 40.8671,
    '1129_814': 40.9908,
    '1129_815': 41.1387,
    '1129_816': 41.2946,
    '1129_817': 41.4198,
    '1129_818': 41.4988,
    '1129_819': 41.5501,
    '1129_820': 41.5934,
    '1129_821': 41.6337,
    '1129_822': 41.6849,
    '1129_823': 41.7356,
    '1129_824': 41.7559,
    '1129_825': 41.7306,
    '1129_826': 41.7022,
    '1129_827': 41.7197,
    '1129_828': 41.7393,
    '1129_829': 41.7428,
    '1129_830': 41.7298,
    '1129_831': 41.6954,
    '1129_832': 41.6599,
    '1129_833': 41.6355,
    '1129_834': 41.6133,
    '1129_835': 41.575,
    '1129_836': 41.517,
    '1129_837': 41.4574,
    '1129_838': 41.4068,
    '1129_839': 41.3755,
    '1129_840': 41.3593,
    '1129_841': 41.3501,
    '1129_842': 41.3493,
    '1129_843': 41.3583,
    '1129_844': 41.38,
    '1129_845': 41.4121,
    '1129_846': 41.4519,
    '1129_847': 41.4919,
    '1129_848': 41.528,
    '1129_849': 41.5635,
    '1129_850': 41.5941,
    '1129_851': 41.6225,
    '1129_852': 41.6422,
    '1129_853': 41.6526,
    '1129_854': 41.6536,
    '1129_855': 41.6403,
    '1129_856': 41.6208,
    '1129_857': 41.5801,
    '1129_858': 41.5224,
    '1129_859': 41.4569,
    '1129_860': 41.3733,
    '1129_861': 41.2759,
    '1129_862': 41.1545,
    '1129_863': 41.0326,
    '1129_864': 40.9166,
    '1129_865': 40.7984,
    '1129_866': 40.6709,
    '1129_867': 40.5341,
    '1129_868': 40.3886,
    '1129_869': 40.2348,
    '1129_870': 40.0744,
    '1129_871': 39.9081,
    '1129_872': 39.7368,
    '1129_873': 39.561,
    '1129_874': 39.3824,
    '1129_875': 39.2096,
    '1129_876': 39.0375,
    '1129_877': 38.8561,
    '1129_878': 38.6598,
    '1129_879': 38.4563,
    '1129_880': 38.25,
    '1129_881': 38.0168,
    '1129_882': 37.7675,
    '1129_883': 37.5084,
    '1129_884': 37.2419,
    '1129_885': 36.9645,
    '1129_886': 36.6466,
    '1129_887': 36.3222,
    '1129_888': 35.9982,
    '1129_889': 35.6759,
    '1129_890': 35.3553,
    '1129_891': 35.0343,
    '1129_892': 34.7115,
    '1129_893': 34.3861,
    '1129_894': 34.0575,
    '1129_895': 33.7229,
    '1129_896': 33.3826,
    '1129_897': 33.0343,
    '1129_898': 32.6799,
    '1129_899': 32.3264,
    '1129_900': 31.9724,
    '1130_774': 37.4876,
    '1130_775': 37.5574,
    '1130_776': 37.6356,
    '1130_777': 37.7065,
    '1130_778': 37.7839,
    '1130_779': 37.8619,
    '1130_780': 37.9472,
    '1130_781': 38.0391,
    '1130_782': 38.1333,
    '1130_783': 38.2284,
    '1130_784': 38.3235,
    '1130_785': 38.4158,
    '1130_786': 38.5038,
    '1130_787': 38.5853,
    '1130_788': 38.6599,
    '1130_789': 38.7281,
    '1130_790': 38.7916,
    '1130_791': 38.8523,
    '1130_792': 38.9173,
    '1130_793': 38.9874,
    '1130_794': 39.0696,
    '1130_795': 39.1669,
    '1130_796': 39.2784,
    '1130_797': 39.4038,
    '1130_798': 39.5466,
    '1130_799': 39.7104,
    '1130_800': 39.8909,
    '1130_801': 40.0789,
    '1130_802': 40.263,
    '1130_803': 40.4121,
    '1130_804': 40.5102,
    '1130_805': 40.5624,
    '1130_806': 40.5731,
    '1130_807': 40.5819,
    '1130_808': 40.5947,
    '1130_809': 40.6098,
    '1130_810': 40.646,
    '1130_811': 40.701,
    '1130_812': 40.7763,
    '1130_813': 40.8784,
    '1130_814': 40.9969,
    '1130_815': 41.1278,
    '1130_816': 41.2788,
    '1130_817': 41.427,
    '1130_818': 41.5341,
    '1130_819': 41.6047,
    '1130_820': 41.6517,
    '1130_821': 41.6905,
    '1130_822': 41.7327,
    '1130_823': 41.7724,
    '1130_824': 41.7829,
    '1130_825': 41.7604,
    '1130_826': 41.7355,
    '1130_827': 41.7519,
    '1130_828': 41.7632,
    '1130_829': 41.7644,
    '1130_830': 41.7614,
    '1130_831': 41.7391,
    '1130_832': 41.7126,
    '1130_833': 41.6908,
    '1130_834': 41.6559,
    '1130_835': 41.603,
    '1130_836': 41.5374,
    '1130_837': 41.4743,
    '1130_838': 41.4248,
    '1130_839': 41.396,
    '1130_840': 41.3776,
    '1130_841': 41.366,
    '1130_842': 41.3664,
    '1130_843': 41.3766,
    '1130_844': 41.3994,
    '1130_845': 41.4323,
    '1130_846': 41.4719,
    '1130_847': 41.5122,
    '1130_848': 41.5471,
    '1130_849': 41.5797,
    '1130_850': 41.6034,
    '1130_851': 41.6241,
    '1130_852': 41.6426,
    '1130_853': 41.6538,
    '1130_854': 41.657,
    '1130_855': 41.6553,
    '1130_856': 41.6437,
    '1130_857': 41.6075,
    '1130_858': 41.5612,
    '1130_859': 41.5034,
    '1130_860': 41.4188,
    '1130_861': 41.3114,
    '1130_862': 41.1853,
    '1130_863': 41.0573,
    '1130_864': 40.9411,
    '1130_865': 40.8252,
    '1130_866': 40.6976,
    '1130_867': 40.562,
    '1130_868': 40.4172,
    '1130_869': 40.2644,
    '1130_870': 40.1055,
    '1130_871': 39.9419,
    '1130_872': 39.7743,
    '1130_873': 39.6033,
    '1130_874': 39.4312,
    '1130_875': 39.2619,
    '1130_876': 39.0956,
    '1130_877': 38.9211,
    '1130_878': 38.7298,
    '1130_879': 38.5085,
    '1130_880': 38.3009,
    '1130_881': 38.0724,
    '1130_882': 37.8198,
    '1130_883': 37.5524,
    '1130_884': 37.274,
    '1130_885': 36.9847,
    '1130_886': 36.6703,
    '1130_887': 36.3443,
    '1130_888': 36.0145,
    '1130_889': 35.6869,
    '1130_890': 35.3602,
    '1130_891': 35.0327,
    '1130_892': 34.7037,
    '1130_893': 34.3735,
    '1130_894': 34.0418,
    '1130_895': 33.707,
    '1130_896': 33.3682,
    '1130_897': 33.0176,
    '1130_898': 32.664,
    '1130_899': 32.3114,
    '1130_900': 31.9597,
    '1131_774': 37.4284,
    '1131_775': 37.5062,
    '1131_776': 37.5771,
    '1131_777': 37.6489,
    '1131_778': 37.726,
    '1131_779': 37.8072,
    '1131_780': 37.8942,
    '1131_781': 37.9858,
    '1131_782': 38.08,
    '1131_783': 38.1753,
    '1131_784': 38.2694,
    '1131_785': 38.3599,
    '1131_786': 38.4452,
    '1131_787': 38.5234,
    '1131_788': 38.5939,
    '1131_789': 38.6579,
    '1131_790': 38.7179,
    '1131_791': 38.7765,
    '1131_792': 38.8408,
    '1131_793': 38.9153,
    '1131_794': 39.0015,
    '1131_795': 39.1033,
    '1131_796': 39.2201,
    '1131_797': 39.3519,
    '1131_798': 39.5015,
    '1131_799': 39.6721,
    '1131_800': 39.8491,
    '1131_801': 40.0406,
    '1131_802': 40.2324,
    '1131_803': 40.3856,
    '1131_804': 40.4917,
    '1131_805': 40.5505,
    '1131_806': 40.5652,
    '1131_807': 40.5809,
    '1131_808': 40.5967,
    '1131_809': 40.6182,
    '1131_810': 40.6543,
    '1131_811': 40.7089,
    '1131_812': 40.7852,
    '1131_813': 40.8868,
    '1131_814': 41.0013,
    '1131_815': 41.1269,
    '1131_816': 41.2709,
    '1131_817': 41.4289,
    '1131_818': 41.556,
    '1131_819': 41.6389,
    '1131_820': 41.6928,
    '1131_821': 41.7292,
    '1131_822': 41.7641,
    '1131_823': 41.7941,
    '1131_824': 41.803,
    '1131_825': 41.7891,
    '1131_826': 41.7694,
    '1131_827': 41.7752,
    '1131_828': 41.7797,
    '1131_829': 41.7859,
    '1131_830': 41.7872,
    '1131_831': 41.7695,
    '1131_832': 41.7532,
    '1131_833': 41.7321,
    '1131_834': 41.6906,
    '1131_835': 41.6303,
    '1131_836': 41.5605,
    '1131_837': 41.4969,
    '1131_838': 41.4492,
    '1131_839': 41.4223,
    '1131_840': 41.3995,
    '1131_841': 41.3851,
    '1131_842': 41.3842,
    '1131_843': 41.3948,
    '1131_844': 41.4189,
    '1131_845': 41.4535,
    '1131_846': 41.4938,
    '1131_847': 41.5337,
    '1131_848': 41.5677,
    '1131_849': 41.595,
    '1131_850': 41.6124,
    '1131_851': 41.6288,
    '1131_852': 41.6426,
    '1131_853': 41.6534,
    '1131_854': 41.6613,
    '1131_855': 41.6669,
    '1131_856': 41.6613,
    '1131_857': 41.6346,
    '1131_858': 41.6003,
    '1131_859': 41.5414,
    '1131_860': 41.445,
    '1131_861': 41.3258,
    '1131_862': 41.2053,
    '1131_863': 41.0785,
    '1131_864': 40.9641,
    '1131_865': 40.8501,
    '1131_866': 40.7248,
    '1131_867': 40.5905,
    '1131_868': 40.4463,
    '1131_869': 40.2941,
    '1131_870': 40.1368,
    '1131_871': 39.9757,
    '1131_872': 39.812,
    '1131_873': 39.6463,
    '1131_874': 39.4804,
    '1131_875': 39.3168,
    '1131_876': 39.1563,
    '1131_877': 38.985,
    '1131_878': 38.7944,
    '1131_879': 38.5822,
    '1131_880': 38.365,
    '1131_881': 38.1315,
    '1131_882': 37.8692,
    '1131_883': 37.592,
    '1131_884': 37.3027,
    '1131_885': 37.0023,
    '1131_886': 36.687,
    '1131_887': 36.362,
    '1131_888': 36.0294,
    '1131_889': 35.6957,
    '1131_890': 35.3639,
    '1131_891': 35.0307,
    '1131_892': 34.6953,
    '1131_893': 34.3599,
    '1131_894': 34.0244,
    '1131_895': 33.6858,
    '1131_896': 33.3442,
    '1131_897': 32.9945,
    '1131_898': 32.6431,
    '1131_899': 32.2918,
    '1131_900': 31.9429,
    '1132_774': 37.3827,
    '1132_775': 37.4509,
    '1132_776': 37.5193,
    '1132_777': 37.5948,
    '1132_778': 37.6743,
    '1132_779': 37.7581,
    '1132_780': 37.8462,
    '1132_781': 37.9381,
    '1132_782': 38.0328,
    '1132_783': 38.1289,
    '1132_784': 38.2234,
    '1132_785': 38.3133,
    '1132_786': 38.397,
    '1132_787': 38.4725,
    '1132_788': 38.5394,
    '1132_789': 38.5992,
    '1132_790': 38.6551,
    '1132_791': 38.7103,
    '1132_792': 38.7721,
    '1132_793': 38.8482,
    '1132_794': 38.9374,
    '1132_795': 39.0435,
    '1132_796': 39.1657,
    '1132_797': 39.3026,
    '1132_798': 39.4562,
    '1132_799': 39.6274,
    '1132_800': 39.8042,
    '1132_801': 39.9987,
    '1132_802': 40.1976,
    '1132_803': 40.356,
    '1132_804': 40.4711,
    '1132_805': 40.5391,
    '1132_806': 40.563,
    '1132_807': 40.5841,
    '1132_808': 40.6047,
    '1132_809': 40.6304,
    '1132_810': 40.6698,
    '1132_811': 40.7237,
    '1132_812': 40.7979,
    '1132_813': 40.8961,
    '1132_814': 41.0096,
    '1132_815': 41.1338,
    '1132_816': 41.2763,
    '1132_817': 41.4295,
    '1132_818': 41.5643,
    '1132_819': 41.6575,
    '1132_820': 41.7161,
    '1132_821': 41.7503,
    '1132_822': 41.7799,
    '1132_823': 41.8011,
    '1132_824': 41.8155,
    '1132_825': 41.8217,
    '1132_826': 41.816,
    '1132_827': 41.8023,
    '1132_828': 41.8041,
    '1132_829': 41.8082,
    '1132_830': 41.8042,
    '1132_831': 41.7893,
    '1132_832': 41.7732,
    '1132_833': 41.7525,
    '1132_834': 41.7153,
    '1132_835': 41.6543,
    '1132_836': 41.5867,
    '1132_837': 41.5256,
    '1132_838': 41.4793,
    '1132_839': 41.4545,
    '1132_840': 41.4291,
    '1132_841': 41.4072,
    '1132_842': 41.4016,
    '1132_843': 41.412,
    '1132_844': 41.4373,
    '1132_845': 41.4741,
    '1132_846': 41.5178,
    '1132_847': 41.5546,
    '1132_848': 41.5849,
    '1132_849': 41.6082,
    '1132_850': 41.6258,
    '1132_851': 41.6411,
    '1132_852': 41.6563,
    '1132_853': 41.6619,
    '1132_854': 41.6643,
    '1132_855': 41.6707,
    '1132_856': 41.6719,
    '1132_857': 41.6517,
    '1132_858': 41.6191,
    '1132_859': 41.5563,
    '1132_860': 41.4625,
    '1132_861': 41.3406,
    '1132_862': 41.2164,
    '1132_863': 41.0956,
    '1132_864': 40.9852,
    '1132_865': 40.876,
    '1132_866': 40.753,
    '1132_867': 40.6208,
    '1132_868': 40.4745,
    '1132_869': 40.323,
    '1132_870': 40.1677,
    '1132_871': 40.0092,
    '1132_872': 39.8493,
    '1132_873': 39.6893,
    '1132_874': 39.5302,
    '1132_875': 39.3743,
    '1132_876': 39.22,
    '1132_877': 39.0516,
    '1132_878': 38.8628,
    '1132_879': 38.652,
    '1132_880': 38.425,
    '1132_881': 38.1779,
    '1132_882': 37.9098,
    '1132_883': 37.6267,
    '1132_884': 37.3296,
    '1132_885': 37.0221,
    '1132_886': 36.704,
    '1132_887': 36.3798,
    '1132_888': 36.0431,
    '1132_889': 35.703,
    '1132_890': 35.3692,
    '1132_891': 35.0326,
    '1132_892': 34.6917,
    '1132_893': 34.3522,
    '1132_894': 34.0146,
    '1132_895': 33.671,
    '1132_896': 33.3276,
    '1132_897': 32.9744,
    '1132_898': 32.6224,
    '1132_899': 32.2687,
    '1132_900': 31.922,
    '1133_774': 37.3351,
    '1133_775': 37.4019,
    '1133_776': 37.4729,
    '1133_777': 37.5487,
    '1133_778': 37.6291,
    '1133_779': 37.7138,
    '1133_780': 37.8026,
    '1133_781': 37.8951,
    '1133_782': 37.9907,
    '1133_783': 38.0886,
    '1133_784': 38.185,
    '1133_785': 38.2762,
    '1133_786': 38.3592,
    '1133_787': 38.4328,
    '1133_788': 38.4969,
    '1133_789': 38.5523,
    '1133_790': 38.603,
    '1133_791': 38.6547,
    '1133_792': 38.7135,
    '1133_793': 38.7889,
    '1133_794': 38.8798,
    '1133_795': 38.9903,
    '1133_796': 39.1164,
    '1133_797': 39.2575,
    '1133_798': 39.4139,
    '1133_799': 39.5831,
    '1133_800': 39.7635,
    '1133_801': 39.9581,
    '1133_802': 40.1606,
    '1133_803': 40.3254,
    '1133_804': 40.4491,
    '1133_805': 40.5304,
    '1133_806': 40.5654,
    '1133_807': 40.592,
    '1133_808': 40.6159,
    '1133_809': 40.6475,
    '1133_810': 40.6944,
    '1133_811': 40.7481,
    '1133_812': 40.8204,
    '1133_813': 40.9155,
    '1133_814': 41.0222,
    '1133_815': 41.1428,
    '1133_816': 41.2806,
    '1133_817': 41.428,
    '1133_818': 41.5615,
    '1133_819': 41.6616,
    '1133_820': 41.7253,
    '1133_821': 41.7592,
    '1133_822': 41.7844,
    '1133_823': 41.8048,
    '1133_824': 41.8272,
    '1133_825': 41.8416,
    '1133_826': 41.8446,
    '1133_827': 41.8435,
    '1133_828': 41.8395,
    '1133_829': 41.8341,
    '1133_830': 41.8236,
    '1133_831': 41.812,
    '1133_832': 41.799,
    '1133_833': 41.7749,
    '1133_834': 41.7347,
    '1133_835': 41.6827,
    '1133_836': 41.6205,
    '1133_837': 41.5614,
    '1133_838': 41.5121,
    '1133_839': 41.4841,
    '1133_840': 41.4587,
    '1133_841': 41.4284,
    '1133_842': 41.4172,
    '1133_843': 41.4253,
    '1133_844': 41.4516,
    '1133_845': 41.4917,
    '1133_846': 41.5377,
    '1133_847': 41.5734,
    '1133_848': 41.599,
    '1133_849': 41.6179,
    '1133_850': 41.6328,
    '1133_851': 41.6496,
    '1133_852': 41.6651,
    '1133_853': 41.67,
    '1133_854': 41.6685,
    '1133_855': 41.6715,
    '1133_856': 41.6715,
    '1133_857': 41.6553,
    '1133_858': 41.6192,
    '1133_859': 41.5556,
    '1133_860': 41.4675,
    '1133_861': 41.3511,
    '1133_862': 41.2296,
    '1133_863': 41.1147,
    '1133_864': 41.0065,
    '1133_865': 40.8971,
    '1133_866': 40.7799,
    '1133_867': 40.6456,
    '1133_868': 40.5001,
    '1133_869': 40.3518,
    '1133_870': 40.1991,
    '1133_871': 40.0432,
    '1133_872': 39.8865,
    '1133_873': 39.7321,
    '1133_874': 39.5821,
    '1133_875': 39.4341,
    '1133_876': 39.2794,
    '1133_877': 39.1103,
    '1133_878': 38.921,
    '1133_879': 38.711,
    '1133_880': 38.4782,
    '1133_881': 38.2214,
    '1133_882': 37.9473,
    '1133_883': 37.6605,
    '1133_884': 37.3575,
    '1133_885': 37.0459,
    '1133_886': 36.7281,
    '1133_887': 36.3992,
    '1133_888': 36.0599,
    '1133_889': 35.715,
    '1133_890': 35.3795,
    '1133_891': 35.0441,
    '1133_892': 34.7034,
    '1133_893': 34.3634,
    '1133_894': 34.0248,
    '1133_895': 33.6803,
    '1133_896': 33.3306,
    '1133_897': 32.9701,
    '1133_898': 32.6098,
    '1133_899': 32.2517,
    '1133_900': 31.9009,
    '1134_774': 37.299,
    '1134_775': 37.3635,
    '1134_776': 37.4338,
    '1134_777': 37.5088,
    '1134_778': 37.5888,
    '1134_779': 37.6736,
    '1134_780': 37.7623,
    '1134_781': 37.855,
    '1134_782': 37.9515,
    '1134_783': 38.0519,
    '1134_784': 38.152,
    '1134_785': 38.2462,
    '1134_786': 38.3287,
    '1134_787': 38.4013,
    '1134_788': 38.464,
    '1134_789': 38.5145,
    '1134_790': 38.5603,
    '1134_791': 38.6086,
    '1134_792': 38.6649,
    '1134_793': 38.7381,
    '1134_794': 38.8312,
    '1134_795': 38.9437,
    '1134_796': 39.0724,
    '1134_797': 39.2175,
    '1134_798': 39.3802,
    '1134_799': 39.5499,
    '1134_800': 39.7289,
    '1134_801': 39.921,
    '1134_802': 40.1264,
    '1134_803': 40.306,
    '1134_804': 40.441,
    '1134_805': 40.5301,
    '1134_806': 40.5738,
    '1134_807': 40.6011,
    '1134_808': 40.6255,
    '1134_809': 40.6666,
    '1134_810': 40.7232,
    '1134_811': 40.7781,
    '1134_812': 40.8493,
    '1134_813': 40.9386,
    '1134_814': 41.0402,
    '1134_815': 41.1542,
    '1134_816': 41.2862,
    '1134_817': 41.426,
    '1134_818': 41.5535,
    '1134_819': 41.6522,
    '1134_820': 41.7101,
    '1134_821': 41.7475,
    '1134_822': 41.7721,
    '1134_823': 41.7996,
    '1134_824': 41.8285,
    '1134_825': 41.8461,
    '1134_826': 41.8611,
    '1134_827': 41.8611,
    '1134_828': 41.8604,
    '1134_829': 41.8536,
    '1134_830': 41.8521,
    '1134_831': 41.8505,
    '1134_832': 41.8374,
    '1134_833': 41.8058,
    '1134_834': 41.7614,
    '1134_835': 41.7181,
    '1134_836': 41.6573,
    '1134_837': 41.5997,
    '1134_838': 41.5507,
    '1134_839': 41.5103,
    '1134_840': 41.4748,
    '1134_841': 41.4392,
    '1134_842': 41.425,
    '1134_843': 41.4328,
    '1134_844': 41.4612,
    '1134_845': 41.5047,
    '1134_846': 41.5503,
    '1134_847': 41.5886,
    '1134_848': 41.6155,
    '1134_849': 41.6338,
    '1134_850': 41.6478,
    '1134_851': 41.6623,
    '1134_852': 41.6743,
    '1134_853': 41.6766,
    '1134_854': 41.6751,
    '1134_855': 41.6744,
    '1134_856': 41.6708,
    '1134_857': 41.6554,
    '1134_858': 41.6197,
    '1134_859': 41.5542,
    '1134_860': 41.4639,
    '1134_861': 41.3576,
    '1134_862': 41.2462,
    '1134_863': 41.1404,
    '1134_864': 41.0375,
    '1134_865': 40.9277,
    '1134_866': 40.8064,
    '1134_867': 40.6707,
    '1134_868': 40.53,
    '1134_869': 40.3838,
    '1134_870': 40.2325,
    '1134_871': 40.0774,
    '1134_872': 39.9218,
    '1134_873': 39.7716,
    '1134_874': 39.6271,
    '1134_875': 39.4848,
    '1134_876': 39.332,
    '1134_877': 39.1625,
    '1134_878': 38.9671,
    '1134_879': 38.749,
    '1134_880': 38.5138,
    '1134_881': 38.2574,
    '1134_882': 37.984,
    '1134_883': 37.6932,
    '1134_884': 37.3818,
    '1134_885': 37.067,
    '1134_886': 36.7521,
    '1134_887': 36.42,
    '1134_888': 36.0825,
    '1134_889': 35.7399,
    '1134_890': 35.3948,
    '1134_891': 35.059,
    '1134_892': 34.7158,
    '1134_893': 34.3746,
    '1134_894': 34.036,
    '1134_895': 33.688,
    '1134_896': 33.3293,
    '1134_897': 32.9647,
    '1134_898': 32.5986,
    '1134_899': 32.2363,
    '1134_900': 31.8842,
    '1135_774': 37.2723,
    '1135_775': 37.3343,
    '1135_776': 37.4025,
    '1135_777': 37.4756,
    '1135_778': 37.5548,
    '1135_779': 37.6389,
    '1135_780': 37.7268,
    '1135_781': 37.8192,
    '1135_782': 37.9155,
    '1135_783': 38.0182,
    '1135_784': 38.1218,
    '1135_785': 38.2201,
    '1135_786': 38.3008,
    '1135_787': 38.373,
    '1135_788': 38.4366,
    '1135_789': 38.4821,
    '1135_790': 38.5243,
    '1135_791': 38.5709,
    '1135_792': 38.6266,
    '1135_793': 38.6978,
    '1135_794': 38.791,
    '1135_795': 38.9047,
    '1135_796': 39.0357,
    '1135_797': 39.1843,
    '1135_798': 39.355,
    '1135_799': 39.5318,
    '1135_800': 39.7093,
    '1135_801': 39.8984,
    '1135_802': 40.1064,
    '1135_803': 40.2966,
    '1135_804': 40.4447,
    '1135_805': 40.541,
    '1135_806': 40.5851,
    '1135_807': 40.6098,
    '1135_808': 40.6396,
    '1135_809': 40.6874,
    '1135_810': 40.7444,
    '1135_811': 40.7991,
    '1135_812': 40.8715,
    '1135_813': 40.9589,
    '1135_814': 41.0577,
    '1135_815': 41.1697,
    '1135_816': 41.2943,
    '1135_817': 41.4245,
    '1135_818': 41.5377,
    '1135_819': 41.6243,
    '1135_820': 41.6839,
    '1135_821': 41.7177,
    '1135_822': 41.7421,
    '1135_823': 41.7771,
    '1135_824': 41.8123,
    '1135_825': 41.8426,
    '1135_826': 41.8563,
    '1135_827': 41.8623,
    '1135_828': 41.8664,
    '1135_829': 41.8717,
    '1135_830': 41.8798,
    '1135_831': 41.8897,
    '1135_832': 41.8805,
    '1135_833': 41.8449,
    '1135_834': 41.8013,
    '1135_835': 41.7633,
    '1135_836': 41.7013,
    '1135_837': 41.6395,
    '1135_838': 41.5842,
    '1135_839': 41.5276,
    '1135_840': 41.478,
    '1135_841': 41.444,
    '1135_842': 41.4311,
    '1135_843': 41.436,
    '1135_844': 41.4635,
    '1135_845': 41.5131,
    '1135_846': 41.5624,
    '1135_847': 41.6044,
    '1135_848': 41.636,
    '1135_849': 41.6564,
    '1135_850': 41.6715,
    '1135_851': 41.6892,
    '1135_852': 41.6984,
    '1135_853': 41.6909,
    '1135_854': 41.6855,
    '1135_855': 41.6846,
    '1135_856': 41.6792,
    '1135_857': 41.6642,
    '1135_858': 41.6271,
    '1135_859': 41.5593,
    '1135_860': 41.476,
    '1135_861': 41.3766,
    '1135_862': 41.2768,
    '1135_863': 41.1773,
    '1135_864': 41.0741,
    '1135_865': 40.9661,
    '1135_866': 40.8354,
    '1135_867': 40.7004,
    '1135_868': 40.564,
    '1135_869': 40.4211,
    '1135_870': 40.2716,
    '1135_871': 40.1167,
    '1135_872': 39.96,
    '1135_873': 39.8112,
    '1135_874': 39.6673,
    '1135_875': 39.524,
    '1135_876': 39.3781,
    '1135_877': 39.2091,
    '1135_878': 39.011,
    '1135_879': 38.7922,
    '1135_880': 38.55,
    '1135_881': 38.2894,
    '1135_882': 38.013,
    '1135_883': 37.7149,
    '1135_884': 37.4026,
    '1135_885': 37.0874,
    '1135_886': 36.7662,
    '1135_887': 36.4353,
    '1135_888': 36.0961,
    '1135_889': 35.75,
    '1135_890': 35.3981,
    '1135_891': 35.0635,
    '1135_892': 34.7211,
    '1135_893': 34.3753,
    '1135_894': 34.03,
    '1135_895': 33.6821,
    '1135_896': 33.3225,
    '1135_897': 32.9579,
    '1135_898': 32.5873,
    '1135_899': 32.2221,
    '1135_900': 31.8713,
    '1136_774': 37.248,
    '1136_775': 37.3081,
    '1136_776': 37.3748,
    '1136_777': 37.4468,
    '1136_778': 37.5243,
    '1136_779': 37.6071,
    '1136_780': 37.6945,
    '1136_781': 37.7864,
    '1136_782': 37.8826,
    '1136_783': 37.9833,
    '1136_784': 38.0841,
    '1136_785': 38.1791,
    '1136_786': 38.262,
    '1136_787': 38.3341,
    '1136_788': 38.395,
    '1136_789': 38.4434,
    '1136_790': 38.4877,
    '1136_791': 38.5352,
    '1136_792': 38.5913,
    '1136_793': 38.6621,
    '1136_794': 38.7558,
    '1136_795': 38.8709,
    '1136_796': 39.0044,
    '1136_797': 39.1576,
    '1136_798': 39.3349,
    '1136_799': 39.5205,
    '1136_800': 39.6998,
    '1136_801': 39.8869,
    '1136_802': 40.0923,
    '1136_803': 40.288,
    '1136_804': 40.4433,
    '1136_805': 40.5448,
    '1136_806': 40.5916,
    '1136_807': 40.6184,
    '1136_808': 40.6567,
    '1136_809': 40.7087,
    '1136_810': 40.7616,
    '1136_811': 40.8198,
    '1136_812': 40.8969,
    '1136_813': 40.9838,
    '1136_814': 41.0784,
    '1136_815': 41.1827,
    '1136_816': 41.3005,
    '1136_817': 41.4138,
    '1136_818': 41.5082,
    '1136_819': 41.5766,
    '1136_820': 41.6258,
    '1136_821': 41.6603,
    '1136_822': 41.693,
    '1136_823': 41.7425,
    '1136_824': 41.7864,
    '1136_825': 41.824,
    '1136_826': 41.8389,
    '1136_827': 41.8466,
    '1136_828': 41.8593,
    '1136_829': 41.8828,
    '1136_830': 41.911,
    '1136_831': 41.9342,
    '1136_832': 41.9302,
    '1136_833': 41.891,
    '1136_834': 41.8437,
    '1136_835': 41.7963,
    '1136_836': 41.7304,
    '1136_837': 41.6608,
    '1136_838': 41.6009,
    '1136_839': 41.5371,
    '1136_840': 41.4812,
    '1136_841': 41.4469,
    '1136_842': 41.4334,
    '1136_843': 41.4399,
    '1136_844': 41.4696,
    '1136_845': 41.5216,
    '1136_846': 41.5725,
    '1136_847': 41.619,
    '1136_848': 41.6559,
    '1136_849': 41.6784,
    '1136_850': 41.6925,
    '1136_851': 41.7122,
    '1136_852': 41.7212,
    '1136_853': 41.7076,
    '1136_854': 41.6969,
    '1136_855': 41.6955,
    '1136_856': 41.6902,
    '1136_857': 41.6759,
    '1136_858': 41.6407,
    '1136_859': 41.585,
    '1136_860': 41.5101,
    '1136_861': 41.4103,
    '1136_862': 41.315,
    '1136_863': 41.2141,
    '1136_864': 41.1139,
    '1136_865': 41.0027,
    '1136_866': 40.8627,
    '1136_867': 40.7312,
    '1136_868': 40.5993,
    '1136_869': 40.4614,
    '1136_870': 40.3155,
    '1136_871': 40.1645,
    '1136_872': 40.0121,
    '1136_873': 39.8636,
    '1136_874': 39.7166,
    '1136_875': 39.5692,
    '1136_876': 39.4223,
    '1136_877': 39.2455,
    '1136_878': 39.0416,
    '1136_879': 38.8218,
    '1136_880': 38.5768,
    '1136_881': 38.3197,
    '1136_882': 38.0459,
    '1136_883': 37.755,
    '1136_884': 37.4445,
    '1136_885': 37.1284,
    '1136_886': 36.8044,
    '1136_887': 36.4703,
    '1136_888': 36.1302,
    '1136_889': 35.7727,
    '1136_890': 35.4135,
    '1136_891': 35.0783,
    '1136_892': 34.7315,
    '1136_893': 34.3795,
    '1136_894': 34.0269,
    '1136_895': 33.6737,
    '1136_896': 33.3149,
    '1136_897': 32.9522,
    '1136_898': 32.5857,
    '1136_899': 32.2228,
    '1136_900': 31.8662,
    '1137_774': 37.2236,
    '1137_775': 37.2829,
    '1137_776': 37.3486,
    '1137_777': 37.4194,
    '1137_778': 37.4951,
    '1137_779': 37.5764,
    '1137_780': 37.6628,
    '1137_781': 37.7537,
    '1137_782': 37.8489,
    '1137_783': 37.9471,
    '1137_784': 38.045,
    '1137_785': 38.138,
    '1137_786': 38.2222,
    '1137_787': 38.2947,
    '1137_788': 38.3547,
    '1137_789': 38.4048,
    '1137_790': 38.4517,
    '1137_791': 38.5016,
    '1137_792': 38.5598,
    '1137_793': 38.6321,
    '1137_794': 38.7259,
    '1137_795': 38.842,
    '1137_796': 38.9776,
    '1137_797': 39.1339,
    '1137_798': 39.3145,
    '1137_799': 39.5018,
    '1137_800': 39.683,
    '1137_801': 39.8737,
    '1137_802': 40.073,
    '1137_803': 40.2757,
    '1137_804': 40.4361,
    '1137_805': 40.5415,
    '1137_806': 40.597,
    '1137_807': 40.6322,
    '1137_808': 40.6823,
    '1137_809': 40.7371,
    '1137_810': 40.791,
    '1137_811': 40.8477,
    '1137_812': 40.9202,
    '1137_813': 41.0036,
    '1137_814': 41.0925,
    '1137_815': 41.1876,
    '1137_816': 41.2913,
    '1137_817': 41.3873,
    '1137_818': 41.4656,
    '1137_819': 41.5136,
    '1137_820': 41.5494,
    '1137_821': 41.5917,
    '1137_822': 41.6414,
    '1137_823': 41.7043,
    '1137_824': 41.7552,
    '1137_825': 41.7932,
    '1137_826': 41.8084,
    '1137_827': 41.8241,
    '1137_828': 41.8436,
    '1137_829': 41.8844,
    '1137_830': 41.9295,
    '1137_831': 41.9565,
    '1137_832': 41.9598,
    '1137_833': 41.9311,
    '1137_834': 41.8803,
    '1137_835': 41.8194,
    '1137_836': 41.7459,
    '1137_837': 41.6715,
    '1137_838': 41.606,
    '1137_839': 41.5412,
    '1137_840': 41.4839,
    '1137_841': 41.4492,
    '1137_842': 41.4366,
    '1137_843': 41.446,
    '1137_844': 41.4797,
    '1137_845': 41.5339,
    '1137_846': 41.5854,
    '1137_847': 41.6346,
    '1137_848': 41.6747,
    '1137_849': 41.6989,
    '1137_850': 41.711,
    '1137_851': 41.7312,
    '1137_852': 41.7375,
    '1137_853': 41.7218,
    '1137_854': 41.7107,
    '1137_855': 41.7078,
    '1137_856': 41.7072,
    '1137_857': 41.7007,
    '1137_858': 41.6734,
    '1137_859': 41.6229,
    '1137_860': 41.5503,
    '1137_861': 41.4484,
    '1137_862': 41.3543,
    '1137_863': 41.2525,
    '1137_864': 41.1561,
    '1137_865': 41.0323,
    '1137_866': 40.8932,
    '1137_867': 40.7659,
    '1137_868': 40.6403,
    '1137_869': 40.5052,
    '1137_870': 40.3624,
    '1137_871': 40.2159,
    '1137_872': 40.0676,
    '1137_873': 39.9185,
    '1137_874': 39.7652,
    '1137_875': 39.6153,
    '1137_876': 39.4647,
    '1137_877': 39.2883,
    '1137_878': 39.081,
    '1137_879': 38.8551,
    '1137_880': 38.6121,
    '1137_881': 38.3542,
    '1137_882': 38.082,
    '1137_883': 37.7926,
    '1137_884': 37.4797,
    '1137_885': 37.1585,
    '1137_886': 36.8312,
    '1137_887': 36.4967,
    '1137_888': 36.1541,
    '1137_889': 35.7912,
    '1137_890': 35.4124,
    '1137_891': 35.0757,
    '1137_892': 34.7287,
    '1137_893': 34.3741,
    '1137_894': 34.02,
    '1137_895': 33.6656,
    '1137_896': 33.3084,
    '1137_897': 32.9482,
    '1137_898': 32.5846,
    '1137_899': 32.2236,
    '1137_900': 31.864,
    '1138_774': 37.1997,
    '1138_775': 37.259,
    '1138_776': 37.3241,
    '1138_777': 37.3935,
    '1138_778': 37.4674,
    '1138_779': 37.547,
    '1138_780': 37.6319,
    '1138_781': 37.7216,
    '1138_782': 37.8154,
    '1138_783': 37.912,
    '1138_784': 38.0085,
    '1138_785': 38.1018,
    '1138_786': 38.1883,
    '1138_787': 38.2624,
    '1138_788': 38.3209,
    '1138_789': 38.3702,
    '1138_790': 38.4183,
    '1138_791': 38.471,
    '1138_792': 38.5325,
    '1138_793': 38.6078,
    '1138_794': 38.7023,
    '1138_795': 38.8192,
    '1138_796': 38.9564,
    '1138_797': 39.1141,
    '1138_798': 39.2927,
    '1138_799': 39.4787,
    '1138_800': 39.6667,
    '1138_801': 39.8578,
    '1138_802': 40.0593,
    '1138_803': 40.266,
    '1138_804': 40.4269,
    '1138_805': 40.5356,
    '1138_806': 40.6022,
    '1138_807': 40.6576,
    '1138_808': 40.7187,
    '1138_809': 40.7766,
    '1138_810': 40.8357,
    '1138_811': 40.8865,
    '1138_812': 40.94,
    '1138_813': 41.011,
    '1138_814': 41.0902,
    '1138_815': 41.1779,
    '1138_816': 41.2676,
    '1138_817': 41.3508,
    '1138_818': 41.4173,
    '1138_819': 41.4479,
    '1138_820': 41.4769,
    '1138_821': 41.5303,
    '1138_822': 41.5971,
    '1138_823': 41.6628,
    '1138_824': 41.7114,
    '1138_825': 41.7501,
    '1138_826': 41.7723,
    '1138_827': 41.7909,
    '1138_828': 41.8204,
    '1138_829': 41.8747,
    '1138_830': 41.9288,
    '1138_831': 41.9667,
    '1138_832': 41.9673,
    '1138_833': 41.9459,
    '1138_834': 41.8981,
    '1138_835': 41.8309,
    '1138_836': 41.7543,
    '1138_837': 41.6789,
    '1138_838': 41.6085,
    '1138_839': 41.5444,
    '1138_840': 41.493,
    '1138_841': 41.4602,
    '1138_842': 41.4423,
    '1138_843': 41.4511,
    '1138_844': 41.4888,
    '1138_845': 41.5436,
    '1138_846': 41.5993,
    '1138_847': 41.6545,
    '1138_848': 41.6994,
    '1138_849': 41.7198,
    '1138_850': 41.7302,
    '1138_851': 41.7477,
    '1138_852': 41.7476,
    '1138_853': 41.7374,
    '1138_854': 41.7282,
    '1138_855': 41.7282,
    '1138_856': 41.7335,
    '1138_857': 41.7353,
    '1138_858': 41.7195,
    '1138_859': 41.665,
    '1138_860': 41.5882,
    '1138_861': 41.4885,
    '1138_862': 41.3944,
    '1138_863': 41.295,
    '1138_864': 41.1885,
    '1138_865': 41.0582,
    '1138_866': 40.9256,
    '1138_867': 40.8039,
    '1138_868': 40.6816,
    '1138_869': 40.5479,
    '1138_870': 40.4087,
    '1138_871': 40.2668,
    '1138_872': 40.1213,
    '1138_873': 39.9711,
    '1138_874': 39.8151,
    '1138_875': 39.655,
    '1138_876': 39.4978,
    '1138_877': 39.3172,
    '1138_878': 39.1134,
    '1138_879': 38.8855,
    '1138_880': 38.6435,
    '1138_881': 38.3848,
    '1138_882': 38.1096,
    '1138_883': 37.8165,
    '1138_884': 37.5038,
    '1138_885': 37.1814,
    '1138_886': 36.8535,
    '1138_887': 36.5224,
    '1138_888': 36.191,
    '1138_889': 35.8178,
    '1138_890': 35.428,
    '1138_891': 35.0522,
    '1138_892': 34.7043,
    '1138_893': 34.3588,
    '1138_894': 34.0108,
    '1138_895': 33.6588,
    '1138_896': 33.3024,
    '1138_897': 32.942,
    '1138_898': 32.5789,
    '1138_899': 32.2176,
    '1138_900': 31.8577,
    '1139_774': 37.1764,
    '1139_775': 37.2371,
    '1139_776': 37.3016,
    '1139_777': 37.3697,
    '1139_778': 37.4419,
    '1139_779': 37.5197,
    '1139_780': 37.6027,
    '1139_781': 37.6914,
    '1139_782': 37.7838,
    '1139_783': 37.879,
    '1139_784': 37.9754,
    '1139_785': 38.0704,
    '1139_786': 38.1617,
    '1139_787': 38.2395,
    '1139_788': 38.294,
    '1139_789': 38.3393,
    '1139_790': 38.3874,
    '1139_791': 38.4435,
    '1139_792': 38.5092,
    '1139_793': 38.5883,
    '1139_794': 38.6855,
    '1139_795': 38.8045,
    '1139_796': 38.9442,
    '1139_797': 39.1041,
    '1139_798': 39.2846,
    '1139_799': 39.4746,
    '1139_800': 39.6651,
    '1139_801': 39.8622,
    '1139_802': 40.0711,
    '1139_803': 40.2728,
    '1139_804': 40.429,
    '1139_805': 40.5406,
    '1139_806': 40.6138,
    '1139_807': 40.681,
    '1139_808': 40.7545,
    '1139_809': 40.8237,
    '1139_810': 40.8803,
    '1139_811': 40.9256,
    '1139_812': 40.9693,
    '1139_813': 41.0242,
    '1139_814': 41.0883,
    '1139_815': 41.1633,
    '1139_816': 41.2444,
    '1139_817': 41.3161,
    '1139_818': 41.3678,
    '1139_819': 41.3908,
    '1139_820': 41.4264,
    '1139_821': 41.4858,
    '1139_822': 41.5535,
    '1139_823': 41.6151,
    '1139_824': 41.6622,
    '1139_825': 41.6972,
    '1139_826': 41.7241,
    '1139_827': 41.7476,
    '1139_828': 41.7906,
    '1139_829': 41.8566,
    '1139_830': 41.9211,
    '1139_831': 41.9617,
    '1139_832': 41.9688,
    '1139_833': 41.9468,
    '1139_834': 41.8987,
    '1139_835': 41.835,
    '1139_836': 41.7633,
    '1139_837': 41.6938,
    '1139_838': 41.6221,
    '1139_839': 41.5585,
    '1139_840': 41.5107,
    '1139_841': 41.471,
    '1139_842': 41.4474,
    '1139_843': 41.4563,
    '1139_844': 41.4944,
    '1139_845': 41.5517,
    '1139_846': 41.616,
    '1139_847': 41.6817,
    '1139_848': 41.7271,
    '1139_849': 41.745,
    '1139_850': 41.7471,
    '1139_851': 41.7564,
    '1139_852': 41.7538,
    '1139_853': 41.7473,
    '1139_854': 41.7436,
    '1139_855': 41.7462,
    '1139_856': 41.7552,
    '1139_857': 41.764,
    '1139_858': 41.7535,
    '1139_859': 41.7011,
    '1139_860': 41.62,
    '1139_861': 41.5288,
    '1139_862': 41.4327,
    '1139_863': 41.3346,
    '1139_864': 41.2192,
    '1139_865': 41.085,
    '1139_866': 40.9628,
    '1139_867': 40.8499,
    '1139_868': 40.7285,
    '1139_869': 40.5944,
    '1139_870': 40.4578,
    '1139_871': 40.319,
    '1139_872': 40.1747,
    '1139_873': 40.0244,
    '1139_874': 39.8671,
    '1139_875': 39.6979,
    '1139_876': 39.5242,
    '1139_877': 39.3441,
    '1139_878': 39.1402,
    '1139_879': 38.9148,
    '1139_880': 38.6739,
    '1139_881': 38.4163,
    '1139_882': 38.1457,
    '1139_883': 37.8455,
    '1139_884': 37.5306,
    '1139_885': 37.2047,
    '1139_886': 36.8771,
    '1139_887': 36.5456,
    '1139_888': 36.2063,
    '1139_889': 35.8471,
    '1139_890': 35.4619,
    '1139_891': 35.0755,
    '1139_892': 34.7064,
    '1139_893': 34.3577,
    '1139_894': 34.0109,
    '1139_895': 33.6594,
    '1139_896': 33.3021,
    '1139_897': 32.9396,
    '1139_898': 32.5746,
    '1139_899': 32.2109,
    '1139_900': 31.8494,
    '1140_774': 37.1503,
    '1140_775': 37.2138,
    '1140_776': 37.2777,
    '1140_777': 37.3451,
    '1140_778': 37.4162,
    '1140_779': 37.4928,
    '1140_780': 37.5738,
    '1140_781': 37.6616,
    '1140_782': 37.7523,
    '1140_783': 37.8456,
    '1140_784': 37.9417,
    '1140_785': 38.0378,
    '1140_786': 38.134,
    '1140_787': 38.2179,
    '1140_788': 38.265,
    '1140_789': 38.3057,
    '1140_790': 38.3549,
    '1140_791': 38.4159,
    '1140_792': 38.4875,
    '1140_793': 38.5712,
    '1140_794': 38.6725,
    '1140_795': 38.7955,
    '1140_796': 38.9381,
    '1140_797': 39.102,
    '1140_798': 39.2877,
    '1140_799': 39.481,
    '1140_800': 39.681,
    '1140_801': 39.8813,
    '1140_802': 40.0858,
    '1140_803': 40.286,
    '1140_804': 40.4377,
    '1140_805': 40.5514,
    '1140_806': 40.6294,
    '1140_807': 40.7052,
    '1140_808': 40.7891,
    '1140_809': 40.8637,
    '1140_810': 40.9155,
    '1140_811': 40.9564,
    '1140_812': 40.996,
    '1140_813': 41.0384,
    '1140_814': 41.0836,
    '1140_815': 41.1421,
    '1140_816': 41.2091,
    '1140_817': 41.2658,
    '1140_818': 41.2992,
    '1140_819': 41.3291,
    '1140_820': 41.3844,
    '1140_821': 41.4458,
    '1140_822': 41.502,
    '1140_823': 41.5527,
    '1140_824': 41.5849,
    '1140_825': 41.6195,
    '1140_826': 41.6537,
    '1140_827': 41.7002,
    '1140_828': 41.7661,
    '1140_829': 41.8371,
    '1140_830': 41.9047,
    '1140_831': 41.9452,
    '1140_832': 41.9525,
    '1140_833': 41.9317,
    '1140_834': 41.8895,
    '1140_835': 41.8331,
    '1140_836': 41.7762,
    '1140_837': 41.7166,
    '1140_838': 41.6414,
    '1140_839': 41.5695,
    '1140_840': 41.5155,
    '1140_841': 41.4759,
    '1140_842': 41.4497,
    '1140_843': 41.4553,
    '1140_844': 41.4957,
    '1140_845': 41.558,
    '1140_846': 41.6323,
    '1140_847': 41.7041,
    '1140_848': 41.7529,
    '1140_849': 41.7663,
    '1140_850': 41.7694,
    '1140_851': 41.775,
    '1140_852': 41.7729,
    '1140_853': 41.7663,
    '1140_854': 41.7649,
    '1140_855': 41.7697,
    '1140_856': 41.7763,
    '1140_857': 41.7799,
    '1140_858': 41.7717,
    '1140_859': 41.724,
    '1140_860': 41.6458,
    '1140_861': 41.5602,
    '1140_862': 41.4597,
    '1140_863': 41.3597,
    '1140_864': 41.2429,
    '1140_865': 41.117,
    '1140_866': 41.0044,
    '1140_867': 40.8971,
    '1140_868': 40.7813,
    '1140_869': 40.6447,
    '1140_870': 40.5071,
    '1140_871': 40.3697,
    '1140_872': 40.2254,
    '1140_873': 40.0727,
    '1140_874': 39.9108,
    '1140_875': 39.7389,
    '1140_876': 39.5585,
    '1140_877': 39.3687,
    '1140_878': 39.1633,
    '1140_879': 38.9375,
    '1140_880': 38.6936,
    '1140_881': 38.4404,
    '1140_882': 38.1837,
    '1140_883': 37.8742,
    '1140_884': 37.5586,
    '1140_885': 37.2273,
    '1140_886': 36.9008,
    '1140_887': 36.5712,
    '1140_888': 36.2353,
    '1140_889': 35.8866,
    '1140_890': 35.5029,
    '1140_891': 35.1139,
    '1140_892': 34.7351,
    '1140_893': 34.3746,
    '1140_894': 34.0231,
    '1140_895': 33.6684,
    '1140_896': 33.3076,
    '1140_897': 32.941,
    '1140_898': 32.5716,
    '1140_899': 32.2046,
    '1140_900': 31.8401,
    '1141_774': 37.1238,
    '1141_775': 37.1846,
    '1141_776': 37.2495,
    '1141_777': 37.3185,
    '1141_778': 37.3914,
    '1141_779': 37.469,
    '1141_780': 37.5501,
    '1141_781': 37.6348,
    '1141_782': 37.722,
    '1141_783': 37.8127,
    '1141_784': 37.9072,
    '1141_785': 38.0027,
    '1141_786': 38.0923,
    '1141_787': 38.1676,
    '1141_788': 38.2204,
    '1141_789': 38.2682,
    '1141_790': 38.3228,
    '1141_791': 38.389,
    '1141_792': 38.4667,
    '1141_793': 38.5561,
    '1141_794': 38.6626,
    '1141_795': 38.7904,
    '1141_796': 38.9369,
    '1141_797': 39.1059,
    '1141_798': 39.2969,
    '1141_799': 39.4997,
    '1141_800': 39.7108,
    '1141_801': 39.9171,
    '1141_802': 40.1142,
    '1141_803': 40.3005,
    '1141_804': 40.4496,
    '1141_805': 40.5625,
    '1141_806': 40.6445,
    '1141_807': 40.7284,
    '1141_808': 40.8147,
    '1141_809': 40.8877,
    '1141_810': 40.9381,
    '1141_811': 40.9716,
    '1141_812': 41.0035,
    '1141_813': 41.0368,
    '1141_814': 41.0688,
    '1141_815': 41.1087,
    '1141_816': 41.1558,
    '1141_817': 41.1971,
    '1141_818': 41.2162,
    '1141_819': 41.2536,
    '1141_820': 41.322,
    '1141_821': 41.3892,
    '1141_822': 41.4422,
    '1141_823': 41.469,
    '1141_824': 41.4995,
    '1141_825': 41.5397,
    '1141_826': 41.5908,
    '1141_827': 41.6617,
    '1141_828': 41.7453,
    '1141_829': 41.8198,
    '1141_830': 41.8898,
    '1141_831': 41.9305,
    '1141_832': 41.9373,
    '1141_833': 41.9161,
    '1141_834': 41.881,
    '1141_835': 41.8339,
    '1141_836': 41.7938,
    '1141_837': 41.7398,
    '1141_838': 41.6646,
    '1141_839': 41.5851,
    '1141_840': 41.5199,
    '1141_841': 41.4713,
    '1141_842': 41.4466,
    '1141_843': 41.4523,
    '1141_844': 41.4931,
    '1141_845': 41.5535,
    '1141_846': 41.6314,
    '1141_847': 41.7106,
    '1141_848': 41.7605,
    '1141_849': 41.7823,
    '1141_850': 41.7969,
    '1141_851': 41.8067,
    '1141_852': 41.8033,
    '1141_853': 41.7958,
    '1141_854': 41.7937,
    '1141_855': 41.7961,
    '1141_856': 41.7986,
    '1141_857': 41.7988,
    '1141_858': 41.7906,
    '1141_859': 41.746,
    '1141_860': 41.6682,
    '1141_861': 41.582,
    '1141_862': 41.4786,
    '1141_863': 41.3759,
    '1141_864': 41.2685,
    '1141_865': 41.1582,
    '1141_866': 41.0595,
    '1141_867': 40.9598,
    '1141_868': 40.8413,
    '1141_869': 40.6944,
    '1141_870': 40.5598,
    '1141_871': 40.4209,
    '1141_872': 40.2759,
    '1141_873': 40.1202,
    '1141_874': 39.952,
    '1141_875': 39.7781,
    '1141_876': 39.595,
    '1141_877': 39.3945,
    '1141_878': 39.1855,
    '1141_879': 38.9604,
    '1141_880': 38.7163,
    '1141_881': 38.4632,
    '1141_882': 38.1979,
    '1141_883': 37.9031,
    '1141_884': 37.5888,
    '1141_885': 37.2575,
    '1141_886': 36.9263,
    '1141_887': 36.5932,
    '1141_888': 36.2537,
    '1141_889': 35.902,
    '1141_890': 35.5319,
    '1141_891': 35.1484,
    '1141_892': 34.7643,
    '1141_893': 34.3948,
    '1141_894': 34.0422,
    '1141_895': 33.6836,
    '1141_896': 33.3184,
    '1141_897': 32.9471,
    '1141_898': 32.5734,
    '1141_899': 32.2011,
    '1141_900': 31.8326,
    '1142_774': 37.0945,
    '1142_775': 37.1537,
    '1142_776': 37.2194,
    '1142_777': 37.2906,
    '1142_778': 37.3664,
    '1142_779': 37.446,
    '1142_780': 37.5274,
    '1142_781': 37.609,
    '1142_782': 37.6907,
    '1142_783': 37.7762,
    '1142_784': 37.8677,
    '1142_785': 37.963,
    '1142_786': 38.0465,
    '1142_787': 38.1163,
    '1142_788': 38.1713,
    '1142_789': 38.2255,
    '1142_790': 38.2873,
    '1142_791': 38.3613,
    '1142_792': 38.4462,
    '1142_793': 38.5424,
    '1142_794': 38.655,
    '1142_795': 38.7879,
    '1142_796': 38.9405,
    '1142_797': 39.1181,
    '1142_798': 39.3206,
    '1142_799': 39.5376,
    '1142_800': 39.7588,
    '1142_801': 39.9726,
    '1142_802': 40.1612,
    '1142_803': 40.3286,
    '1142_804': 40.4643,
    '1142_805': 40.5738,
    '1142_806': 40.6584,
    '1142_807': 40.7381,
    '1142_808': 40.8172,
    '1142_809': 40.8835,
    '1142_810': 40.9312,
    '1142_811': 40.96,
    '1142_812': 40.9884,
    '1142_813': 41.0182,
    '1142_814': 41.0413,
    '1142_815': 41.055,
    '1142_816': 41.0782,
    '1142_817': 41.1081,
    '1142_818': 41.1279,
    '1142_819': 41.1694,
    '1142_820': 41.2374,
    '1142_821': 41.3079,
    '1142_822': 41.3604,
    '1142_823': 41.3877,
    '1142_824': 41.4277,
    '1142_825': 41.4777,
    '1142_826': 41.5424,
    '1142_827': 41.6349,
    '1142_828': 41.7296,
    '1142_829': 41.8045,
    '1142_830': 41.8741,
    '1142_831': 41.9176,
    '1142_832': 41.9275,
    '1142_833': 41.9056,
    '1142_834': 41.8736,
    '1142_835': 41.8387,
    '1142_836': 41.8054,
    '1142_837': 41.7523,
    '1142_838': 41.6798,
    '1142_839': 41.5998,
    '1142_840': 41.5241,
    '1142_841': 41.4676,
    '1142_842': 41.4383,
    '1142_843': 41.444,
    '1142_844': 41.4799,
    '1142_845': 41.5376,
    '1142_846': 41.6091,
    '1142_847': 41.6911,
    '1142_848': 41.7562,
    '1142_849': 41.798,
    '1142_850': 41.8298,
    '1142_851': 41.8467,
    '1142_852': 41.8409,
    '1142_853': 41.8341,
    '1142_854': 41.8286,
    '1142_855': 41.8286,
    '1142_856': 41.8316,
    '1142_857': 41.8268,
    '1142_858': 41.815,
    '1142_859': 41.7722,
    '1142_860': 41.6969,
    '1142_861': 41.6061,
    '1142_862': 41.5141,
    '1142_863': 41.4091,
    '1142_864': 41.304,
    '1142_865': 41.2143,
    '1142_866': 41.1369,
    '1142_867': 41.0382,
    '1142_868': 40.9041,
    '1142_869': 40.7481,
    '1142_870': 40.6174,
    '1142_871': 40.4813,
    '1142_872': 40.3338,
    '1142_873': 40.1761,
    '1142_874': 39.9941,
    '1142_875': 39.8105,
    '1142_876': 39.6216,
    '1142_877': 39.4191,
    '1142_878': 39.2048,
    '1142_879': 38.9765,
    '1142_880': 38.7322,
    '1142_881': 38.4802,
    '1142_882': 38.212,
    '1142_883': 37.9301,
    '1142_884': 37.6175,
    '1142_885': 37.2808,
    '1142_886': 36.9472,
    '1142_887': 36.6125,
    '1142_888': 36.2729,
    '1142_889': 35.9278,
    '1142_890': 35.5631,
    '1142_891': 35.1828,
    '1142_892': 34.7986,
    '1142_893': 34.4105,
    '1142_894': 34.0618,
    '1142_895': 33.6976,
    '1142_896': 33.3287,
    '1142_897': 32.9535,
    '1142_898': 32.5765,
    '1142_899': 32.1997,
    '1142_900': 31.8264,
    '1143_774': 37.0642,
    '1143_775': 37.1225,
    '1143_776': 37.1885,
    '1143_777': 37.2622,
    '1143_778': 37.3418,
    '1143_779': 37.4239,
    '1143_780': 37.506,
    '1143_781': 37.5844,
    '1143_782': 37.6582,
    '1143_783': 37.7358,
    '1143_784': 37.8212,
    '1143_785': 37.9142,
    '1143_786': 37.9973,
    '1143_787': 38.0683,
    '1143_788': 38.1247,
    '1143_789': 38.1829,
    '1143_790': 38.2526,
    '1143_791': 38.3338,
    '1143_792': 38.4262,
    '1143_793': 38.5296,
    '1143_794': 38.649,
    '1143_795': 38.7883,
    '1143_796': 38.9494,
    '1143_797': 39.1396,
    '1143_798': 39.356,
    '1143_799': 39.5912,
    '1143_800': 39.8246,
    '1143_801': 40.04,
    '1143_802': 40.2179,
    '1143_803': 40.3668,
    '1143_804': 40.4805,
    '1143_805': 40.5766,
    '1143_806': 40.6566,
    '1143_807': 40.7293,
    '1143_808': 40.7947,
    '1143_809': 40.8452,
    '1143_810': 40.8849,
    '1143_811': 40.9159,
    '1143_812': 40.9437,
    '1143_813': 40.9776,
    '1143_814': 40.9985,
    '1143_815': 40.9975,
    '1143_816': 41.0013,
    '1143_817': 41.0229,
    '1143_818': 41.0457,
    '1143_819': 41.087,
    '1143_820': 41.1496,
    '1143_821': 41.217,
    '1143_822': 41.2784,
    '1143_823': 41.3188,
    '1143_824': 41.3661,
    '1143_825': 41.4274,
    '1143_826': 41.5127,
    '1143_827': 41.6164,
    '1143_828': 41.7119,
    '1143_829': 41.7889,
    '1143_830': 41.8582,
    '1143_831': 41.9059,
    '1143_832': 41.9194,
    '1143_833': 41.9007,
    '1143_834': 41.8667,
    '1143_835': 41.8347,
    '1143_836': 41.8034,
    '1143_837': 41.7509,
    '1143_838': 41.6772,
    '1143_839': 41.5965,
    '1143_840': 41.5145,
    '1143_841': 41.4575,
    '1143_842': 41.4315,
    '1143_843': 41.4331,
    '1143_844': 41.4583,
    '1143_845': 41.5107,
    '1143_846': 41.5773,
    '1143_847': 41.6602,
    '1143_848': 41.7443,
    '1143_849': 41.8136,
    '1143_850': 41.8665,
    '1143_851': 41.8896,
    '1143_852': 41.886,
    '1143_853': 41.8809,
    '1143_854': 41.8677,
    '1143_855': 41.8618,
    '1143_856': 41.8663,
    '1143_857': 41.8636,
    '1143_858': 41.8465,
    '1143_859': 41.8087,
    '1143_860': 41.7327,
    '1143_861': 41.6459,
    '1143_862': 41.5597,
    '1143_863': 41.453,
    '1143_864': 41.3509,
    '1143_865': 41.285,
    '1143_866': 41.2138,
    '1143_867': 41.1018,
    '1143_868': 40.9547,
    '1143_869': 40.797,
    '1143_870': 40.6737,
    '1143_871': 40.5457,
    '1143_872': 40.3844,
    '1143_873': 40.2148,
    '1143_874': 40.03,
    '1143_875': 39.8403,
    '1143_876': 39.6464,
    '1143_877': 39.4392,
    '1143_878': 39.2185,
    '1143_879': 38.9879,
    '1143_880': 38.7455,
    '1143_881': 38.4929,
    '1143_882': 38.2286,
    '1143_883': 37.9538,
    '1143_884': 37.6337,
    '1143_885': 37.2932,
    '1143_886': 36.9638,
    '1143_887': 36.6291,
    '1143_888': 36.289,
    '1143_889': 35.9436,
    '1143_890': 35.5846,
    '1143_891': 35.2009,
    '1143_892': 34.8094,
    '1143_893': 34.4192,
    '1143_894': 34.0608,
    '1143_895': 33.6967,
    '1143_896': 33.3283,
    '1143_897': 32.9558,
    '1143_898': 32.5808,
    '1143_899': 32.2049,
    '1143_900': 31.8288,
    '1144_786': 37.9489,
    '1144_787': 38.0157,
    '1144_788': 38.0761,
    '1144_789': 38.1411,
    '1144_790': 38.2176,
    '1144_791': 38.3062,
    '1144_792': 38.4058,
    '1144_793': 38.5173,
    '1144_794': 38.6432,
    '1144_795': 38.791,
    '1144_796': 38.9627,
    '1144_797': 39.1653,
    '1144_798': 39.3958,
    '1144_799': 39.6478,
    '1144_800': 39.8944,
    '1144_801': 40.101,
    '1144_802': 40.2683,
    '1144_803': 40.3929,
    '1144_804': 40.482,
    '1144_805': 40.5586,
    '1144_806': 40.6286,
    '1144_807': 40.6941,
    '1144_808': 40.7515,
    '1144_809': 40.7872,
    '1144_810': 40.8184,
    '1144_811': 40.8492,
    '1144_812': 40.8817,
    '1144_813': 40.9214,
    '1144_814': 40.9485,
    '1144_815': 40.9535,
    '1144_816': 40.9598,
    '1144_817': 40.9799,
    '1144_818': 40.9935,
    '1144_819': 41.0231,
    '1144_820': 41.0785,
    '1144_821': 41.1472,
    '1144_822': 41.2169,
    '1144_823': 41.2699,
    '1144_824': 41.3239,
    '1144_825': 41.4018,
    '1144_826': 41.5008,
    '1144_827': 41.5962,
    '1144_828': 41.6871,
    '1144_829': 41.7667,
    '1144_830': 41.8416,
    '1144_831': 41.8936,
    '1144_832': 41.9121,
    '1144_833': 41.8966,
    '1144_834': 41.859,
    '1144_835': 41.82,
    '1144_836': 41.7799,
    '1144_837': 41.724,
    '1144_838': 41.6487,
    '1144_839': 41.5703,
    '1144_840': 41.4947,
    '1144_841': 41.445,
    '1144_842': 41.4225,
    '1144_843': 41.4183,
    '1144_844': 41.4357,
    '1144_845': 41.4771,
    '1144_846': 41.5421,
    '1144_847': 41.6299,
    '1144_848': 41.7235,
    '1144_849': 41.8135,
    '1144_850': 41.8847,
    '1144_851': 41.9199,
    '1144_852': 41.9334,
    '1144_853': 41.9352,
    '1144_854': 41.9118,
    '1144_855': 41.9018,
    '1144_856': 41.9049,
    '1144_857': 41.9048,
    '1144_858': 41.8806,
    '1144_859': 41.8451,
    '1144_860': 41.7686,
    '1144_861': 41.6869,
    '1144_862': 41.6003,
    '1144_863': 41.4918,
    '1144_864': 41.4049,
    '1144_865': 41.3476,
    '1144_866': 41.2673,
    '1144_867': 41.146,
    '1144_868': 40.9955,
    '1144_869': 40.8416,
    '1144_870': 40.726,
    '1144_871': 40.6082,
    '1144_872': 40.4449,
    '1144_873': 40.2598,
    '1144_874': 40.0702,
    '1144_875': 39.8726,
    '1144_876': 39.6711,
    '1144_877': 39.4598,
    '1144_878': 39.2335,
    '1144_879': 39.0025,
    '1144_880': 38.7576,
    '1144_881': 38.5023,
    '1144_882': 38.2355,
    '1144_883': 37.9543,
    '1144_884': 37.6441,
    '1144_885': 37.3169,
    '1144_886': 36.9853,
    '1144_887': 36.6435,
    '1144_888': 36.2978,
    '1144_889': 35.9474,
    '1144_890': 35.5853,
    '1144_891': 35.2071,
    '1144_892': 34.8186,
    '1144_893': 34.4312,
    '1144_894': 34.061,
    '1144_895': 33.6911,
    '1144_896': 33.3239,
    '1144_897': 32.9553,
    '1144_898': 32.5832,
    '1144_899': 32.2092,
    '1144_900': 31.8294,
    '1145_786': 37.9038,
    '1145_787': 37.9659,
    '1145_788': 38.0281,
    '1145_789': 38.1003,
    '1145_790': 38.1834,
    '1145_791': 38.2784,
    '1145_792': 38.384,
    '1145_793': 38.5015,
    '1145_794': 38.634,
    '1145_795': 38.7913,
    '1145_796': 38.9763,
    '1145_797': 39.189,
    '1145_798': 39.4294,
    '1145_799': 39.692,
    '1145_800': 39.9351,
    '1145_801': 40.1261,
    '1145_802': 40.2672,
    '1145_803': 40.386,
    '1145_804': 40.4612,
    '1145_805': 40.518,
    '1145_806': 40.5749,
    '1145_807': 40.6316,
    '1145_808': 40.6857,
    '1145_809': 40.7187,
    '1145_810': 40.7429,
    '1145_811': 40.7712,
    '1145_812': 40.8089,
    '1145_813': 40.8613,
    '1145_814': 40.9005,
    '1145_815': 40.9172,
    '1145_816': 40.9331,
    '1145_817': 40.9536,
    '1145_818': 40.9642,
    '1145_819': 40.9827,
    '1145_820': 41.0374,
    '1145_821': 41.108,
    '1145_822': 41.1678,
    '1145_823': 41.2266,
    '1145_824': 41.2974,
    '1145_825': 41.3849,
    '1145_826': 41.4779,
    '1145_827': 41.5682,
    '1145_828': 41.6553,
    '1145_829': 41.7368,
    '1145_830': 41.8178,
    '1145_831': 41.8769,
    '1145_832': 41.9007,
    '1145_833': 41.8894,
    '1145_834': 41.8499,
    '1145_835': 41.8019,
    '1145_836': 41.7513,
    '1145_837': 41.6869,
    '1145_838': 41.606,
    '1145_839': 41.5298,
    '1145_840': 41.467,
    '1145_841': 41.4283,
    '1145_842': 41.4079,
    '1145_843': 41.4009,
    '1145_844': 41.415,
    '1145_845': 41.4503,
    '1145_846': 41.5094,
    '1145_847': 41.5987,
    '1145_848': 41.6998,
    '1145_849': 41.7981,
    '1145_850': 41.8833,
    '1145_851': 41.9378,
    '1145_852': 41.9719,
    '1145_853': 41.9796,
    '1145_854': 41.9589,
    '1145_855': 41.9505,
    '1145_856': 41.9522,
    '1145_857': 41.9502,
    '1145_858': 41.9216,
    '1145_859': 41.878,
    '1145_860': 41.8028,
    '1145_861': 41.7261,
    '1145_862': 41.6335,
    '1145_863': 41.5278,
    '1145_864': 41.4486,
    '1145_865': 41.3906,
    '1145_866': 41.2958,
    '1145_867': 41.1641,
    '1145_868': 41.0233,
    '1145_869': 40.8891,
    '1145_870': 40.7844,
    '1145_871': 40.6612,
    '1145_872': 40.4872,
    '1145_873': 40.2946,
    '1145_874': 40.1014,
    '1145_875': 39.9023,
    '1145_876': 39.6934,
    '1145_877': 39.4747,
    '1145_878': 39.2472,
    '1145_879': 39.0147,
    '1145_880': 38.7674,
    '1145_881': 38.5103,
    '1145_882': 38.2406,
    '1145_883': 37.9584,
    '1145_884': 37.6584,
    '1145_885': 37.3372,
    '1145_886': 36.9991,
    '1145_887': 36.6512,
    '1145_888': 36.3032,
    '1145_889': 35.9514,
    '1145_890': 35.5906,
    '1145_891': 35.2163,
    '1145_892': 34.8282,
    '1145_893': 34.4327,
    '1145_894': 34.0403,
    '1145_895': 33.6795,
    '1145_896': 33.3183,
    '1145_897': 32.9517,
    '1145_898': 32.5799,
    '1145_899': 32.2038,
    '1145_900': 31.8228,
    '1146_786': 37.8547,
    '1146_787': 37.919,
    '1146_788': 37.984,
    '1146_789': 38.0701,
    '1146_790': 38.1555,
    '1146_791': 38.2511,
    '1146_792': 38.3591,
    '1146_793': 38.4802,
    '1146_794': 38.6171,
    '1146_795': 38.7803,
    '1146_796': 38.976,
    '1146_797': 39.1966,
    '1146_798': 39.4394,
    '1146_799': 39.6964,
    '1146_800': 39.9188,
    '1146_801': 40.1037,
    '1146_802': 40.2216,
    '1146_803': 40.3399,
    '1146_804': 40.4114,
    '1146_805': 40.4535,
    '1146_806': 40.5014,
    '1146_807': 40.5494,
    '1146_808': 40.5954,
    '1146_809': 40.6299,
    '1146_810': 40.6556,
    '1146_811': 40.6866,
    '1146_812': 40.7319,
    '1146_813': 40.7921,
    '1146_814': 40.842,
    '1146_815': 40.8733,
    '1146_816': 40.9015,
    '1146_817': 40.9228,
    '1146_818': 40.9375,
    '1146_819': 40.9522,
    '1146_820': 41.0076,
    '1146_821': 41.0776,
    '1146_822': 41.135,
    '1146_823': 41.1906,
    '1146_824': 41.2772,
    '1146_825': 41.3632,
    '1146_826': 41.4458,
    '1146_827': 41.5307,
    '1146_828': 41.6158,
    '1146_829': 41.6994,
    '1146_830': 41.7908,
    '1146_831': 41.8578,
    '1146_832': 41.8901,
    '1146_833': 41.8848,
    '1146_834': 41.8486,
    '1146_835': 41.7928,
    '1146_836': 41.7304,
    '1146_837': 41.6568,
    '1146_838': 41.5717,
    '1146_839': 41.4966,
    '1146_840': 41.4414,
    '1146_841': 41.4086,
    '1146_842': 41.3911,
    '1146_843': 41.3851,
    '1146_844': 41.3981,
    '1146_845': 41.4332,
    '1146_846': 41.4899,
    '1146_847': 41.5735,
    '1146_848': 41.6748,
    '1146_849': 41.7728,
    '1146_850': 41.862,
    '1146_851': 41.9327,
    '1146_852': 41.988,
    '1146_853': 42.0057,
    '1146_854': 42.0014,
    '1146_855': 41.9995,
    '1146_856': 41.9996,
    '1146_857': 41.9938,
    '1146_858': 41.9613,
    '1146_859': 41.9095,
    '1146_860': 41.8366,
    '1146_861': 41.7605,
    '1146_862': 41.6682,
    '1146_863': 41.5651,
    '1146_864': 41.4793,
    '1146_865': 41.4159,
    '1146_866': 41.3117,
    '1146_867': 41.182,
    '1146_868': 41.0535,
    '1146_869': 40.9426,
    '1146_870': 40.848,
    '1146_871': 40.7099,
    '1146_872': 40.5262,
    '1146_873': 40.3317,
    '1146_874': 40.1402,
    '1146_875': 39.9402,
    '1146_876': 39.7256,
    '1146_877': 39.4893,
    '1146_878': 39.2614,
    '1146_879': 39.0294,
    '1146_880': 38.7833,
    '1146_881': 38.5229,
    '1146_882': 38.2506,
    '1146_883': 37.9672,
    '1146_884': 37.6753,
    '1146_885': 37.3566,
    '1146_886': 37.0118,
    '1146_887': 36.6574,
    '1146_888': 36.3105,
    '1146_889': 35.9604,
    '1146_890': 35.6048,
    '1146_891': 35.2376,
    '1146_892': 34.853,
    '1146_893': 34.4613,
    '1146_894': 34.0727,
    '1146_895': 33.6981,
    '1146_896': 33.3269,
    '1146_897': 32.9541,
    '1146_898': 32.5786,
    '1146_899': 32.2001,
    '1146_900': 31.817,
    '1147_786': 37.8067,
    '1147_787': 37.8725,
    '1147_788': 37.9447,
    '1147_789': 38.0305,
    '1147_790': 38.1195,
    '1147_791': 38.2175,
    '1147_792': 38.3276,
    '1147_793': 38.4514,
    '1147_794': 38.5911,
    '1147_795': 38.7578,
    '1147_796': 38.9552,
    '1147_797': 39.1744,
    '1147_798': 39.4104,
    '1147_799': 39.6526,
    '1147_800': 39.8697,
    '1147_801': 40.0505,
    '1147_802': 40.1745,
    '1147_803': 40.2637,
    '1147_804': 40.3273,
    '1147_805': 40.3665,
    '1147_806': 40.4061,
    '1147_807': 40.4485,
    '1147_808': 40.4942,
    '1147_809': 40.5354,
    '1147_810': 40.569,
    '1147_811': 40.6063,
    '1147_812': 40.656,
    '1147_813': 40.7128,
    '1147_814': 40.7697,
    '1147_815': 40.8169,
    '1147_816': 40.8502,
    '1147_817': 40.8685,
    '1147_818': 40.8864,
    '1147_819': 40.9055,
    '1147_820': 40.9577,
    '1147_821': 41.0232,
    '1147_822': 41.0791,
    '1147_823': 41.1407,
    '1147_824': 41.2395,
    '1147_825': 41.3267,
    '1147_826': 41.4027,
    '1147_827': 41.4892,
    '1147_828': 41.5752,
    '1147_829': 41.6606,
    '1147_830': 41.7606,
    '1147_831': 41.838,
    '1147_832': 41.8839,
    '1147_833': 41.8922,
    '1147_834': 41.8631,
    '1147_835': 41.8005,
    '1147_836': 41.72,
    '1147_837': 41.6327,
    '1147_838': 41.5453,
    '1147_839': 41.4717,
    '1147_840': 41.4204,
    '1147_841': 41.3905,
    '1147_842': 41.3771,
    '1147_843': 41.3725,
    '1147_844': 41.3853,
    '1147_845': 41.4189,
    '1147_846': 41.4723,
    '1147_847': 41.5499,
    '1147_848': 41.6522,
    '1147_849': 41.7488,
    '1147_850': 41.8377,
    '1147_851': 41.9208,
    '1147_852': 41.9906,
    '1147_853': 42.0212,
    '1147_854': 42.0357,
    '1147_855': 42.038,
    '1147_856': 42.036,
    '1147_857': 42.0248,
    '1147_858': 41.989,
    '1147_859': 41.9332,
    '1147_860': 41.8587,
    '1147_861': 41.7813,
    '1147_862': 41.6865,
    '1147_863': 41.5894,
    '1147_864': 41.5056,
    '1147_865': 41.4362,
    '1147_866': 41.334,
    '1147_867': 41.2141,
    '1147_868': 41.1042,
    '1147_869': 41.0003,
    '1147_870': 40.901,
    '1147_871': 40.7611,
    '1147_872': 40.5736,
    '1147_873': 40.3785,
    '1147_874': 40.1843,
    '1147_875': 39.9629,
    '1147_876': 39.739,
    '1147_877': 39.5073,
    '1147_878': 39.2796,
    '1147_879': 39.0425,
    '1147_880': 38.796,
    '1147_881': 38.5364,
    '1147_882': 38.2638,
    '1147_883': 37.9797,
    '1147_884': 37.6837,
    '1147_885': 37.3711,
    '1147_886': 37.0277,
    '1147_887': 36.6772,
    '1147_888': 36.328,
    '1147_889': 35.9767,
    '1147_890': 35.6269,
    '1147_891': 35.2699,
    '1147_892': 34.886,
    '1147_893': 34.4986,
    '1147_894': 34.1132,
    '1147_895': 33.727,
    '1147_896': 33.3438,
    '1147_897': 32.9618,
    '1147_898': 32.5801,
    '1147_899': 32.1988,
    '1147_900': 31.8111,
    '1148_778': 37.5354,
    '1148_779': 37.6254,
    '1148_780': 37.7093,
    '1148_781': 37.7861,
    '1148_782': 37.8552,
    '1148_783': 37.9151,
    '1148_784': 37.9682,
    '1148_786': 37.7631,
    '1148_787': 37.8268,
    '1148_788': 37.8999,
    '1148_789': 37.9836,
    '1148_790': 38.0755,
    '1148_791': 38.1761,
    '1148_792': 38.2882,
    '1148_793': 38.4131,
    '1148_794': 38.5526,
    '1148_795': 38.7161,
    '1148_796': 38.909,
    '1148_797': 39.1206,
    '1148_798': 39.3419,
    '1148_799': 39.5632,
    '1148_800': 39.7663,
    '1148_801': 39.9347,
    '1148_802': 40.0638,
    '1148_803': 40.1509,
    '1148_804': 40.2152,
    '1148_805': 40.2576,
    '1148_806': 40.2974,
    '1148_807': 40.3423,
    '1148_808': 40.3922,
    '1148_809': 40.4414,
    '1148_810': 40.4839,
    '1148_811': 40.5316,
    '1148_812': 40.5834,
    '1148_813': 40.6333,
    '1148_814': 40.688,
    '1148_815': 40.7409,
    '1148_816': 40.7776,
    '1148_817': 40.7947,
    '1148_818': 40.8156,
    '1148_819': 40.8406,
    '1148_820': 40.894,
    '1148_821': 40.9595,
    '1148_822': 41.0138,
    '1148_823': 41.0838,
    '1148_824': 41.1881,
    '1148_825': 41.2761,
    '1148_826': 41.3568,
    '1148_827': 41.4457,
    '1148_828': 41.5342,
    '1148_829': 41.6245,
    '1148_830': 41.7331,
    '1148_831': 41.8249,
    '1148_832': 41.8871,
    '1148_833': 41.9053,
    '1148_834': 41.8792,
    '1148_835': 41.8131,
    '1148_836': 41.7213,
    '1148_837': 41.6265,
    '1148_838': 41.5342,
    '1148_839': 41.4557,
    '1148_840': 41.4033,
    '1148_841': 41.3762,
    '1148_842': 41.3644,
    '1148_843': 41.3619,
    '1148_844': 41.3752,
    '1148_845': 41.4071,
    '1148_846': 41.46,
    '1148_847': 41.5364,
    '1148_848': 41.6358,
    '1148_849': 41.7307,
    '1148_850': 41.8214,
    '1148_851': 41.9087,
    '1148_852': 41.9854,
    '1148_853': 42.0279,
    '1148_854': 42.0552,
    '1148_855': 42.0642,
    '1148_856': 42.0636,
    '1148_857': 42.0446,
    '1148_858': 42.0019,
    '1148_859': 41.9399,
    '1148_860': 41.8646,
    '1148_861': 41.787,
    '1148_862': 41.693,
    '1148_863': 41.6042,
    '1148_864': 41.5287,
    '1148_865': 41.4611,
    '1148_866': 41.3678,
    '1148_867': 41.265,
    '1148_868': 41.1739,
    '1148_869': 41.0804,
    '1148_870': 40.9673,
    '1148_871': 40.8161,
    '1148_872': 40.6247,
    '1148_873': 40.417,
    '1148_874': 40.2047,
    '1148_875': 39.978,
    '1148_876': 39.7526,
    '1148_877': 39.525,
    '1148_878': 39.2962,
    '1148_879': 39.0586,
    '1148_880': 38.8117,
    '1148_881': 38.5526,
    '1148_882': 38.2798,
    '1148_883': 37.9936,
    '1148_884': 37.6931,
    '1148_885': 37.3913,
    '1148_886': 37.0461,
    '1148_887': 36.7002,
    '1148_888': 36.3536,
    '1148_889': 36.0014,
    '1148_890': 35.6512,
    '1148_891': 35.3018,
    '1148_892': 34.9157,
    '1148_893': 34.5302,
    '1148_894': 34.1414,
    '1148_895': 33.7526,
    '1148_896': 33.3633,
    '1148_897': 32.9729,
    '1148_898': 32.583,
    '1148_899': 32.1953,
    '1148_900': 31.8047,
    '1149_778': 37.576,
    '1149_779': 37.6718,
    '1149_780': 37.7578,
    '1149_781': 37.8362,
    '1149_782': 37.9051,
    '1149_783': 37.9575,
    '1149_784': 37.9968,
    '1149_786': 37.7253,
    '1149_787': 37.7824,
    '1149_788': 37.8525,
    '1149_789': 37.9341,
    '1149_790': 38.0261,
    '1149_791': 38.1269,
    '1149_792': 38.2396,
    '1149_793': 38.3643,
    '1149_794': 38.5017,
    '1149_795': 38.66,
    '1149_796': 38.8427,
    '1149_797': 39.0406,
    '1149_798': 39.2434,
    '1149_799': 39.4419,
    '1149_800': 39.6273,
    '1149_801': 39.7876,
    '1149_802': 39.9183,
    '1149_803': 40.0124,
    '1149_804': 40.086,
    '1149_805': 40.1332,
    '1149_806': 40.1793,
    '1149_807': 40.2295,
    '1149_808': 40.2892,
    '1149_809': 40.3509,
    '1149_810': 40.4055,
    '1149_811': 40.4646,
    '1149_812': 40.5125,
    '1149_813': 40.5568,
    '1149_814': 40.6064,
    '1149_815': 40.6581,
    '1149_816': 40.6942,
    '1149_817': 40.7162,
    '1149_818': 40.7407,
    '1149_819': 40.7729,
    '1149_820': 40.8266,
    '1149_821': 40.8891,
    '1149_822': 40.9455,
    '1149_823': 41.0245,
    '1149_824': 41.1297,
    '1149_825': 41.2215,
    '1149_826': 41.3103,
    '1149_827': 41.4009,
    '1149_828': 41.4894,
    '1149_829': 41.5855,
    '1149_830': 41.7026,
    '1149_831': 41.8047,
    '1149_832': 41.8756,
    '1149_833': 41.903,
    '1149_834': 41.8886,
    '1149_835': 41.8261,
    '1149_836': 41.7309,
    '1149_837': 41.6314,
    '1149_838': 41.5313,
    '1149_839': 41.4435,
    '1149_840': 41.3866,
    '1149_841': 41.3594,
    '1149_842': 41.3508,
    '1149_843': 41.3506,
    '1149_844': 41.3645,
    '1149_845': 41.396,
    '1149_846': 41.4488,
    '1149_847': 41.5234,
    '1149_848': 41.6132,
    '1149_849': 41.7073,
    '1149_850': 41.7986,
    '1149_851': 41.8853,
    '1149_852': 41.9562,
    '1149_853': 42.0087,
    '1149_854': 42.0528,
    '1149_855': 42.0758,
    '1149_856': 42.0796,
    '1149_857': 42.0567,
    '1149_858': 42.0087,
    '1149_859': 41.9457,
    '1149_860': 41.8685,
    '1149_861': 41.7897,
    '1149_862': 41.702,
    '1149_863': 41.6241,
    '1149_864': 41.558,
    '1149_865': 41.495,
    '1149_866': 41.4165,
    '1149_867': 41.3304,
    '1149_868': 41.2511,
    '1149_869': 41.1614,
    '1149_870': 41.0413,
    '1149_871': 40.8794,
    '1149_872': 40.6767,
    '1149_873': 40.4564,
    '1149_874': 40.2301,
    '1149_875': 40.0041,
    '1149_876': 39.7746,
    '1149_877': 39.5453,
    '1149_878': 39.3112,
    '1149_879': 39.0703,
    '1149_880': 38.8242,
    '1149_881': 38.5703,
    '1149_882': 38.2971,
    '1149_883': 38.0093,
    '1149_884': 37.7084,
    '1149_885': 37.3943,
    '1149_886': 37.0686,
    '1149_887': 36.7244,
    '1149_888': 36.3789,
    '1149_889': 36.0296,
    '1149_890': 35.6764,
    '1149_891': 35.3196,
    '1149_892': 34.951,
    '1149_893': 34.5622,
    '1149_894': 34.1687,
    '1149_895': 33.7802,
    '1149_896': 33.3869,
    '1149_897': 32.9923,
    '1149_898': 32.5952,
    '1149_899': 32.1962,
    '1149_900': 31.7988,
    '1150_778': 37.6104,
    '1150_779': 37.7122,
    '1150_780': 37.7976,
    '1150_781': 37.8767,
    '1150_782': 37.9475,
    '1150_783': 37.9921,
    '1150_784': 38.0282,
    '1150_786': 37.6894,
    '1150_787': 37.7378,
    '1150_788': 37.8041,
    '1150_789': 37.8811,
    '1150_790': 37.97,
    '1150_791': 38.0689,
    '1150_792': 38.1812,
    '1150_793': 38.304,
    '1150_794': 38.4382,
    '1150_795': 38.5894,
    '1150_796': 38.759,
    '1150_797': 38.9428,
    '1150_798': 39.1278,
    '1150_799': 39.3055,
    '1150_800': 39.4762,
    '1150_801': 39.6283,
    '1150_802': 39.7607,
    '1150_803': 39.8657,
    '1150_804': 39.9474,
    '1150_805': 40.0059,
    '1150_806': 40.0589,
    '1150_807': 40.1192,
    '1150_808': 40.1886,
    '1150_809': 40.2631,
    '1150_810': 40.3338,
    '1150_811': 40.3994,
    '1150_812': 40.4445,
    '1150_813': 40.4866,
    '1150_814': 40.534,
    '1150_815': 40.5759,
    '1150_816': 40.6073,
    '1150_817': 40.6338,
    '1150_818': 40.6639,
    '1150_819': 40.7032,
    '1150_820': 40.759,
    '1150_821': 40.8205,
    '1150_822': 40.8815,
    '1150_823': 40.9649,
    '1150_824': 41.0688,
    '1150_825': 41.1633,
    '1150_826': 41.2566,
    '1150_827': 41.3496,
    '1150_828': 41.4412,
    '1150_829': 41.5426,
    '1150_830': 41.6657,
    '1150_831': 41.7721,
    '1150_832': 41.8486,
    '1150_833': 41.8791,
    '1150_834': 41.8753,
    '1150_835': 41.8257,
    '1150_836': 41.7382,
    '1150_837': 41.6351,
    '1150_838': 41.5275,
    '1150_839': 41.4312,
    '1150_840': 41.3692,
    '1150_841': 41.3407,
    '1150_842': 41.3335,
    '1150_843': 41.3377,
    '1150_844': 41.3516,
    '1150_845': 41.3838,
    '1150_846': 41.4355,
    '1150_847': 41.5066,
    '1150_848': 41.593,
    '1150_849': 41.6823,
    '1150_850': 41.7681,
    '1150_851': 41.8494,
    '1150_852': 41.9171,
    '1150_853': 41.9792,
    '1150_854': 42.038,
    '1150_855': 42.0763,
    '1150_856': 42.0876,
    '1150_857': 42.0668,
    '1150_858': 42.0211,
    '1150_859': 41.9573,
    '1150_860': 41.8856,
    '1150_861': 41.8077,
    '1150_862': 41.7235,
    '1150_863': 41.6569,
    '1150_864': 41.5942,
    '1150_865': 41.5321,
    '1150_866': 41.4679,
    '1150_867': 41.3974,
    '1150_868': 41.3265,
    '1150_869': 41.2284,
    '1150_870': 41.098,
    '1150_871': 40.9268,
    '1150_872': 40.7264,
    '1150_873': 40.5098,
    '1150_874': 40.2813,
    '1150_875': 40.0499,
    '1150_876': 39.8069,
    '1150_877': 39.5641,
    '1150_878': 39.3275,
    '1150_879': 39.0863,
    '1150_880': 38.8409,
    '1150_881': 38.5888,
    '1150_882': 38.3152,
    '1150_883': 38.025,
    '1150_884': 37.7241,
    '1150_885': 37.4159,
    '1150_886': 37.0965,
    '1150_887': 36.7503,
    '1150_888': 36.4037,
    '1150_889': 36.054,
    '1150_890': 35.7021,
    '1150_891': 35.351,
    '1150_892': 34.9941,
    '1150_893': 34.6007,
    '1150_894': 34.2017,
    '1150_895': 33.806,
    '1150_896': 33.4088,
    '1150_897': 33.0109,
    '1150_898': 32.6066,
    '1150_899': 32.1947,
    '1150_900': 31.7893,
    '1151_778': 37.6357,
    '1151_779': 37.7329,
    '1151_780': 37.82,
    '1151_781': 37.8978,
    '1151_782': 37.9651,
    '1151_783': 38.0143,
    '1151_784': 38.0539,
    '1151_786': 37.6599,
    '1151_787': 37.7053,
    '1151_788': 37.7639,
    '1151_789': 37.8341,
    '1151_790': 37.9158,
    '1151_791': 38.0086,
    '1151_792': 38.1161,
    '1151_793': 38.2364,
    '1151_794': 38.3678,
    '1151_795': 38.5093,
    '1151_796': 38.6661,
    '1151_797': 38.8364,
    '1151_798': 39.0078,
    '1151_799': 39.1712,
    '1151_800': 39.3323,
    '1151_801': 39.4814,
    '1151_802': 39.6131,
    '1151_803': 39.7207,
    '1151_804': 39.8096,
    '1151_805': 39.8819,
    '1151_806': 39.9485,
    '1151_807': 40.0183,
    '1151_808': 40.0991,
    '1151_809': 40.1903,
    '1151_810': 40.2688,
    '1151_811': 40.3324,
    '1151_812': 40.3796,
    '1151_813': 40.4264,
    '1151_814': 40.4739,
    '1151_815': 40.5034,
    '1151_816': 40.5301,
    '1151_817': 40.5598,
    '1151_818': 40.5943,
    '1151_819': 40.6359,
    '1151_820': 40.6927,
    '1151_821': 40.7556,
    '1151_822': 40.8193,
    '1151_823': 40.909,
    '1151_824': 41.014,
    '1151_825': 41.108,
    '1151_826': 41.2056,
    '1151_827': 41.3061,
    '1151_828': 41.3984,
    '1151_829': 41.5029,
    '1151_830': 41.6202,
    '1151_831': 41.7268,
    '1151_832': 41.8027,
    '1151_833': 41.8461,
    '1151_834': 41.8516,
    '1151_835': 41.8112,
    '1151_836': 41.7294,
    '1151_837': 41.6299,
    '1151_838': 41.5169,
    '1151_839': 41.4164,
    '1151_840': 41.3503,
    '1151_841': 41.3214,
    '1151_842': 41.3171,
    '1151_843': 41.3244,
    '1151_844': 41.3399,
    '1151_845': 41.3728,
    '1151_846': 41.4259,
    '1151_847': 41.4953,
    '1151_848': 41.5759,
    '1151_849': 41.66,
    '1151_850': 41.7427,
    '1151_851': 41.8251,
    '1151_852': 41.8967,
    '1151_853': 41.966,
    '1151_854': 42.0293,
    '1151_855': 42.0759,
    '1151_856': 42.0953,
    '1151_857': 42.0811,
    '1151_858': 42.039,
    '1151_859': 41.9798,
    '1151_860': 41.9121,
    '1151_861': 41.8294,
    '1151_862': 41.7467,
    '1151_863': 41.6795,
    '1151_864': 41.6206,
    '1151_865': 41.5656,
    '1151_866': 41.5126,
    '1151_867': 41.4568,
    '1151_868': 41.3921,
    '1151_869': 41.2931,
    '1151_870': 41.1503,
    '1151_871': 40.9642,
    '1151_872': 40.7598,
    '1151_873': 40.5394,
    '1151_874': 40.3041,
    '1151_875': 40.066,
    '1151_876': 39.8248,
    '1151_877': 39.5837,
    '1151_878': 39.3449,
    '1151_879': 39.1022,
    '1151_880': 38.8545,
    '1151_881': 38.6018,
    '1151_882': 38.3325,
    '1151_883': 38.0411,
    '1151_884': 37.7385,
    '1151_885': 37.4346,
    '1151_886': 37.119,
    '1151_887': 36.7698,
    '1151_888': 36.4224,
    '1151_889': 36.0727,
    '1151_890': 35.7171,
    '1151_891': 35.3628,
    '1151_892': 35,
    '1151_893': 34.6181,
    '1151_894': 34.2229,
    '1151_895': 33.825,
    '1151_896': 33.4235,
    '1151_897': 33.0232,
    '1151_898': 32.6099,
    '1151_899': 32.1932,
    '1151_900': 31.7833,
    '1152_778': 37.656,
    '1152_779': 37.7493,
    '1152_780': 37.8365,
    '1152_781': 37.9142,
    '1152_782': 37.9812,
    '1152_783': 38.0329,
    '1152_784': 38.0768,
    '1152_786': 37.6305,
    '1152_787': 37.6727,
    '1152_788': 37.725,
    '1152_789': 37.7883,
    '1152_790': 37.863,
    '1152_791': 37.9497,
    '1152_792': 38.0503,
    '1152_793': 38.1655,
    '1152_794': 38.2914,
    '1152_795': 38.4249,
    '1152_796': 38.5713,
    '1152_797': 38.7295,
    '1152_798': 38.8886,
    '1152_799': 39.0452,
    '1152_800': 39.2039,
    '1152_801': 39.3467,
    '1152_802': 39.4751,
    '1152_803': 39.5857,
    '1152_804': 39.6794,
    '1152_805': 39.7622,
    '1152_806': 39.8432,
    '1152_807': 39.9305,
    '1152_808': 40.0284,
    '1152_809': 40.1319,
    '1152_810': 40.2051,
    '1152_811': 40.2634,
    '1152_812': 40.3153,
    '1152_813': 40.3668,
    '1152_814': 40.417,
    '1152_815': 40.4398,
    '1152_816': 40.4641,
    '1152_817': 40.4958,
    '1152_818': 40.5337,
    '1152_819': 40.5783,
    '1152_820': 40.6325,
    '1152_821': 40.6943,
    '1152_822': 40.7652,
    '1152_823': 40.8594,
    '1152_824': 40.9646,
    '1152_825': 41.064,
    '1152_826': 41.1651,
    '1152_827': 41.2646,
    '1152_828': 41.3534,
    '1152_829': 41.4591,
    '1152_830': 41.5693,
    '1152_831': 41.6672,
    '1152_832': 41.7412,
    '1152_833': 41.7889,
    '1152_834': 41.7978,
    '1152_835': 41.7742,
    '1152_836': 41.7035,
    '1152_837': 41.6044,
    '1152_838': 41.4945,
    '1152_839': 41.3952,
    '1152_840': 41.3294,
    '1152_841': 41.3026,
    '1152_842': 41.3005,
    '1152_843': 41.3124,
    '1152_844': 41.3304,
    '1152_845': 41.3651,
    '1152_846': 41.4197,
    '1152_847': 41.488,
    '1152_848': 41.5631,
    '1152_849': 41.6417,
    '1152_850': 41.7247,
    '1152_851': 41.8134,
    '1152_852': 41.8889,
    '1152_853': 41.9611,
    '1152_854': 42.0217,
    '1152_855': 42.0734,
    '1152_856': 42.0987,
    '1152_857': 42.0879,
    '1152_858': 42.0505,
    '1152_859': 42.001,
    '1152_860': 41.9383,
    '1152_861': 41.8614,
    '1152_862': 41.7814,
    '1152_863': 41.7094,
    '1152_864': 41.6524,
    '1152_865': 41.6068,
    '1152_866': 41.5495,
    '1152_867': 41.4985,
    '1152_868': 41.4381,
    '1152_869': 41.3298,
    '1152_870': 41.1878,
    '1152_871': 41.0002,
    '1152_872': 40.7833,
    '1152_873': 40.551,
    '1152_874': 40.3147,
    '1152_875': 40.0762,
    '1152_876': 39.84,
    '1152_877': 39.6001,
    '1152_878': 39.3618,
    '1152_879': 39.1184,
    '1152_880': 38.869,
    '1152_881': 38.6135,
    '1152_882': 38.3457,
    '1152_883': 38.0558,
    '1152_884': 37.7547,
    '1152_885': 37.4446,
    '1152_886': 37.1237,
    '1152_887': 36.7875,
    '1152_888': 36.4409,
    '1152_889': 36.092,
    '1152_890': 35.7286,
    '1152_891': 35.3727,
    '1152_892': 35.0097,
    '1152_893': 34.6325,
    '1152_894': 34.2356,
    '1152_895': 33.8356,
    '1152_896': 33.4272,
    '1152_897': 33.0229,
    '1152_898': 32.6065,
    '1152_899': 32.1866,
    '1152_900': 31.777,
    '1153_778': 37.6751,
    '1153_779': 37.7667,
    '1153_780': 37.8551,
    '1153_781': 37.9346,
    '1153_782': 38.0028,
    '1153_783': 38.0566,
    '1153_784': 38.1014,
    '1153_786': 37.5999,
    '1153_787': 37.6382,
    '1153_788': 37.6847,
    '1153_789': 37.7416,
    '1153_790': 37.8101,
    '1153_791': 37.8908,
    '1153_792': 37.9852,
    '1153_793': 38.0944,
    '1153_794': 38.2145,
    '1153_795': 38.3408,
    '1153_796': 38.4783,
    '1153_797': 38.6246,
    '1153_798': 38.7745,
    '1153_799': 38.926,
    '1153_800': 39.0844,
    '1153_801': 39.2237,
    '1153_802': 39.3481,
    '1153_803': 39.4603,
    '1153_804': 39.5624,
    '1153_805': 39.6538,
    '1153_806': 39.7463,
    '1153_807': 39.8516,
    '1153_808': 39.9716,
    '1153_809': 40.0781,
    '1153_810': 40.1437,
    '1153_811': 40.196,
    '1153_812': 40.2533,
    '1153_813': 40.3101,
    '1153_814': 40.3591,
    '1153_815': 40.3793,
    '1153_816': 40.4042,
    '1153_817': 40.4381,
    '1153_818': 40.4789,
    '1153_819': 40.5245,
    '1153_820': 40.5756,
    '1153_821': 40.637,
    '1153_822': 40.7167,
    '1153_823': 40.8176,
    '1153_824': 40.9239,
    '1153_825': 41.0212,
    '1153_826': 41.1213,
    '1153_827': 41.2143,
    '1153_828': 41.2941,
    '1153_829': 41.3977,
    '1153_830': 41.5056,
    '1153_831': 41.598,
    '1153_832': 41.6675,
    '1153_833': 41.7107,
    '1153_834': 41.7305,
    '1153_835': 41.7146,
    '1153_836': 41.6587,
    '1153_837': 41.5671,
    '1153_838': 41.4641,
    '1153_839': 41.37,
    '1153_840': 41.3078,
    '1153_841': 41.2839,
    '1153_842': 41.2868,
    '1153_843': 41.2993,
    '1153_844': 41.3223,
    '1153_845': 41.3624,
    '1153_846': 41.4177,
    '1153_847': 41.4868,
    '1153_848': 41.5631,
    '1153_849': 41.6372,
    '1153_850': 41.7158,
    '1153_851': 41.7995,
    '1153_852': 41.8747,
    '1153_853': 41.9499,
    '1153_854': 42.0126,
    '1153_855': 42.0682,
    '1153_856': 42.0949,
    '1153_857': 42.0851,
    '1153_858': 42.0534,
    '1153_859': 42.0186,
    '1153_860': 41.9659,
    '1153_861': 41.8949,
    '1153_862': 41.8176,
    '1153_863': 41.7519,
    '1153_864': 41.6909,
    '1153_865': 41.646,
    '1153_866': 41.5904,
    '1153_867': 41.5329,
    '1153_868': 41.4621,
    '1153_869': 41.3538,
    '1153_870': 41.2043,
    '1153_871': 41.0181,
    '1153_872': 40.8028,
    '1153_873': 40.5699,
    '1153_874': 40.3387,
    '1153_875': 40.1059,
    '1153_876': 39.8712,
    '1153_877': 39.6239,
    '1153_878': 39.3804,
    '1153_879': 39.1352,
    '1153_880': 38.8832,
    '1153_881': 38.6227,
    '1153_882': 38.3542,
    '1153_883': 38.0682,
    '1153_884': 37.7682,
    '1153_885': 37.4575,
    '1153_886': 37.1369,
    '1153_887': 36.8074,
    '1153_888': 36.4651,
    '1153_889': 36.1165,
    '1153_890': 35.7546,
    '1153_891': 35.3914,
    '1153_892': 35.0257,
    '1153_893': 34.651,
    '1153_894': 34.2464,
    '1153_895': 33.8508,
    '1153_896': 33.4414,
    '1153_897': 33.0276,
    '1153_898': 32.6087,
    '1153_899': 32.1891,
    '1153_900': 31.7735,
    '1154_778': 37.6934,
    '1154_779': 37.7856,
    '1154_780': 37.8776,
    '1154_781': 37.9628,
    '1154_782': 38.0341,
    '1154_783': 38.0879,
    '1154_784': 38.1291,
    '1154_786': 37.5681,
    '1154_787': 37.6012,
    '1154_788': 37.6421,
    '1154_789': 37.6931,
    '1154_790': 37.7559,
    '1154_791': 37.831,
    '1154_792': 37.9195,
    '1154_793': 38.024,
    '1154_794': 38.1389,
    '1154_795': 38.2588,
    '1154_796': 38.3884,
    '1154_797': 38.5249,
    '1154_798': 38.6661,
    '1154_799': 38.8135,
    '1154_800': 38.9726,
    '1154_801': 39.1125,
    '1154_802': 39.234,
    '1154_803': 39.3489,
    '1154_804': 39.4577,
    '1154_805': 39.5601,
    '1154_806': 39.6652,
    '1154_807': 39.7863,
    '1154_808': 39.9151,
    '1154_809': 40.0181,
    '1154_810': 40.0775,
    '1154_811': 40.1333,
    '1154_812': 40.1955,
    '1154_813': 40.2606,
    '1154_814': 40.3093,
    '1154_815': 40.3256,
    '1154_816': 40.3495,
    '1154_817': 40.3854,
    '1154_818': 40.4281,
    '1154_819': 40.4741,
    '1154_820': 40.5219,
    '1154_821': 40.5835,
    '1154_822': 40.6697,
    '1154_823': 40.7764,
    '1154_824': 40.8821,
    '1154_825': 40.9729,
    '1154_826': 41.0661,
    '1154_827': 41.1528,
    '1154_828': 41.2284,
    '1154_829': 41.3262,
    '1154_830': 41.4274,
    '1154_831': 41.5184,
    '1154_832': 41.585,
    '1154_833': 41.6283,
    '1154_834': 41.6525,
    '1154_835': 41.648,
    '1154_836': 41.6073,
    '1154_837': 41.5258,
    '1154_838': 41.43,
    '1154_839': 41.3439,
    '1154_840': 41.2892,
    '1154_841': 41.2705,
    '1154_842': 41.2775,
    '1154_843': 41.293,
    '1154_844': 41.32,
    '1154_845': 41.3633,
    '1154_846': 41.4221,
    '1154_847': 41.4914,
    '1154_848': 41.5659,
    '1154_849': 41.6391,
    '1154_850': 41.7133,
    '1154_851': 41.7908,
    '1154_852': 41.8657,
    '1154_853': 41.9379,
    '1154_854': 42.0025,
    '1154_855': 42.0579,
    '1154_856': 42.0825,
    '1154_857': 42.0749,
    '1154_858': 42.0518,
    '1154_859': 42.0293,
    '1154_860': 41.983,
    '1154_861': 41.9187,
    '1154_862': 41.8505,
    '1154_863': 41.7895,
    '1154_864': 41.7292,
    '1154_865': 41.68,
    '1154_866': 41.6239,
    '1154_867': 41.5554,
    '1154_868': 41.4709,
    '1154_869': 41.347,
    '1154_870': 41.1948,
    '1154_871': 41.012,
    '1154_872': 40.8028,
    '1154_873': 40.5846,
    '1154_874': 40.3607,
    '1154_875': 40.1254,
    '1154_876': 39.8847,
    '1154_877': 39.6406,
    '1154_878': 39.3984,
    '1154_879': 39.1519,
    '1154_880': 38.8987,
    '1154_881': 38.6298,
    '1154_882': 38.3598,
    '1154_883': 38.0769,
    '1154_884': 37.7777,
    '1154_885': 37.4641,
    '1154_886': 37.1473,
    '1154_887': 36.8243,
    '1154_888': 36.4825,
    '1154_889': 36.1332,
    '1154_890': 35.7745,
    '1154_891': 35.4058,
    '1154_892': 35.0351,
    '1154_893': 34.6535,
    '1154_894': 34.2632,
    '1154_895': 33.8655,
    '1154_896': 33.4551,
    '1154_897': 33.0344,
    '1154_898': 32.6123,
    '1154_899': 32.1905,
    '1154_900': 31.7713,
    '1155_778': 37.7099,
    '1155_779': 37.8036,
    '1155_780': 37.902,
    '1155_781': 37.9953,
    '1155_782': 38.0716,
    '1155_783': 38.1221,
    '1155_784': 38.158,
    '1155_786': 37.5343,
    '1155_787': 37.5616,
    '1155_788': 37.5969,
    '1155_789': 37.6429,
    '1155_790': 37.7013,
    '1155_791': 37.7725,
    '1155_792': 37.8549,
    '1155_793': 37.956,
    '1155_794': 38.0661,
    '1155_795': 38.1799,
    '1155_796': 38.3018,
    '1155_797': 38.4304,
    '1155_798': 38.5647,
    '1155_799': 38.7079,
    '1155_800': 38.8666,
    '1155_801': 39.0069,
    '1155_802': 39.1284,
    '1155_803': 39.2485,
    '1155_804': 39.365,
    '1155_805': 39.4764,
    '1155_806': 39.5977,
    '1155_807': 39.7222,
    '1155_808': 39.848,
    '1155_809': 39.9426,
    '1155_810': 40.0054,
    '1155_811': 40.0727,
    '1155_812': 40.1441,
    '1155_813': 40.2149,
    '1155_814': 40.2625,
    '1155_815': 40.2785,
    '1155_816': 40.3005,
    '1155_817': 40.3355,
    '1155_818': 40.3775,
    '1155_819': 40.4211,
    '1155_820': 40.4704,
    '1155_821': 40.5346,
    '1155_822': 40.6243,
    '1155_823': 40.7328,
    '1155_824': 40.8367,
    '1155_825': 40.922,
    '1155_826': 41.0069,
    '1155_827': 41.0882,
    '1155_828': 41.164,
    '1155_829': 41.2542,
    '1155_830': 41.3484,
    '1155_831': 41.4353,
    '1155_832': 41.5017,
    '1155_833': 41.5472,
    '1155_834': 41.5733,
    '1155_835': 41.5833,
    '1155_836': 41.5567,
    '1155_837': 41.4893,
    '1155_838': 41.4061,
    '1155_839': 41.3277,
    '1155_840': 41.2744,
    '1155_841': 41.2608,
    '1155_842': 41.2731,
    '1155_843': 41.2927,
    '1155_844': 41.3227,
    '1155_845': 41.3696,
    '1155_846': 41.4324,
    '1155_847': 41.502,
    '1155_848': 41.573,
    '1155_849': 41.6429,
    '1155_850': 41.7151,
    '1155_851': 41.7902,
    '1155_852': 41.8695,
    '1155_853': 41.9393,
    '1155_854': 41.9989,
    '1155_855': 42.0435,
    '1155_856': 42.0611,
    '1155_857': 42.0577,
    '1155_858': 42.0451,
    '1155_859': 42.028,
    '1155_860': 41.9884,
    '1155_861': 41.9302,
    '1155_862': 41.8696,
    '1155_863': 41.8119,
    '1155_864': 41.7553,
    '1155_865': 41.7047,
    '1155_866': 41.6494,
    '1155_867': 41.5773,
    '1155_868': 41.4804,
    '1155_869': 41.3395,
    '1155_870': 41.1766,
    '1155_871': 40.9956,
    '1155_872': 40.7888,
    '1155_873': 40.5746,
    '1155_874': 40.356,
    '1155_875': 40.1236,
    '1155_876': 39.8816,
    '1155_877': 39.643,
    '1155_878': 39.4056,
    '1155_879': 39.1626,
    '1155_880': 38.9127,
    '1155_881': 38.6413,
    '1155_882': 38.3678,
    '1155_883': 38.0799,
    '1155_884': 37.7857,
    '1155_885': 37.4678,
    '1155_886': 37.1544,
    '1155_887': 36.8364,
    '1155_888': 36.4951,
    '1155_889': 36.1501,
    '1155_890': 35.7874,
    '1155_891': 35.4167,
    '1155_892': 35.047,
    '1155_893': 34.6621,
    '1155_894': 34.277,
    '1155_895': 33.8748,
    '1155_896': 33.4641,
    '1155_897': 33.0389,
    '1155_898': 32.6132,
    '1155_899': 32.1883,
    '1155_900': 31.7717,
    '1156_786': 37.4983,
    '1156_787': 37.52,
    '1156_788': 37.5503,
    '1156_789': 37.5928,
    '1156_790': 37.6492,
    '1156_791': 37.7211,
    '1156_792': 37.8018,
    '1156_793': 37.8955,
    '1156_794': 37.9984,
    '1156_795': 38.1074,
    '1156_796': 38.2199,
    '1156_797': 38.3415,
    '1156_798': 38.4688,
    '1156_799': 38.6084,
    '1156_800': 38.7638,
    '1156_801': 38.9023,
    '1156_802': 39.0284,
    '1156_803': 39.1518,
    '1156_804': 39.274,
    '1156_805': 39.3976,
    '1156_806': 39.5302,
    '1156_807': 39.6566,
    '1156_808': 39.7758,
    '1156_809': 39.8628,
    '1156_810': 39.9367,
    '1156_811': 40.0138,
    '1156_812': 40.0963,
    '1156_813': 40.1698,
    '1156_814': 40.2136,
    '1156_815': 40.2297,
    '1156_816': 40.2517,
    '1156_817': 40.2857,
    '1156_818': 40.3251,
    '1156_819': 40.3689,
    '1156_820': 40.4211,
    '1156_821': 40.4896,
    '1156_822': 40.5807,
    '1156_823': 40.6889,
    '1156_824': 40.7922,
    '1156_825': 40.8764,
    '1156_826': 40.9582,
    '1156_827': 41.0411,
    '1156_828': 41.1125,
    '1156_829': 41.1884,
    '1156_830': 41.2779,
    '1156_831': 41.3575,
    '1156_832': 41.4229,
    '1156_833': 41.473,
    '1156_834': 41.5025,
    '1156_835': 41.5228,
    '1156_836': 41.5072,
    '1156_837': 41.4468,
    '1156_838': 41.3727,
    '1156_839': 41.302,
    '1156_840': 41.2571,
    '1156_841': 41.25,
    '1156_842': 41.2696,
    '1156_843': 41.2934,
    '1156_844': 41.327,
    '1156_845': 41.3783,
    '1156_846': 41.4469,
    '1156_847': 41.5174,
    '1156_848': 41.5882,
    '1156_849': 41.6525,
    '1156_850': 41.7224,
    '1156_851': 41.7934,
    '1156_852': 41.8737,
    '1156_853': 41.9386,
    '1156_854': 41.9931,
    '1156_855': 42.0242,
    '1156_856': 42.0339,
    '1156_857': 42.0343,
    '1156_858': 42.0306,
    '1156_859': 42.0166,
    '1156_860': 41.983,
    '1156_861': 41.9308,
    '1156_862': 41.8696,
    '1156_863': 41.812,
    '1156_864': 41.7623,
    '1156_865': 41.7218,
    '1156_866': 41.6737,
    '1156_867': 41.6004,
    '1156_868': 41.4945,
    '1156_869': 41.3512,
    '1156_870': 41.1994,
    '1156_871': 41.0191,
    '1156_872': 40.8065,
    '1156_873': 40.5769,
    '1156_874': 40.3485,
    '1156_875': 40.1169,
    '1156_876': 39.8806,
    '1156_877': 39.6467,
    '1156_878': 39.4086,
    '1156_879': 39.1678,
    '1156_880': 38.9199,
    '1156_881': 38.6555,
    '1156_882': 38.3825,
    '1156_883': 38.0993,
    '1156_884': 37.8021,
    '1156_885': 37.4899,
    '1156_886': 37.1766,
    '1156_887': 36.8606,
    '1156_888': 36.5331,
    '1156_889': 36.1788,
    '1156_890': 35.8144,
    '1156_891': 35.4437,
    '1156_892': 35.0663,
    '1156_893': 34.665,
    '1156_894': 34.2712,
    '1156_895': 33.8789,
    '1156_896': 33.4537,
    '1156_897': 33.0369,
    '1156_898': 32.6081,
    '1156_899': 32.1747,
    '1156_900': 31.7711,
    '1157_786': 37.4617,
    '1157_787': 37.4794,
    '1157_788': 37.5066,
    '1157_789': 37.5477,
    '1157_790': 37.6045,
    '1157_791': 37.6731,
    '1157_792': 37.7506,
    '1157_793': 37.838,
    '1157_794': 37.9337,
    '1157_795': 38.036,
    '1157_796': 38.1416,
    '1157_797': 38.2565,
    '1157_798': 38.3782,
    '1157_799': 38.5127,
    '1157_800': 38.6617,
    '1157_801': 38.7986,
    '1157_802': 38.932,
    '1157_803': 39.0593,
    '1157_804': 39.1852,
    '1157_805': 39.3231,
    '1157_806': 39.4657,
    '1157_807': 39.5932,
    '1157_808': 39.705,
    '1157_809': 39.7935,
    '1157_810': 39.8736,
    '1157_811': 39.9556,
    '1157_812': 40.0419,
    '1157_813': 40.1196,
    '1157_814': 40.1675,
    '1157_815': 40.187,
    '1157_816': 40.205,
    '1157_817': 40.2346,
    '1157_818': 40.2744,
    '1157_819': 40.3206,
    '1157_820': 40.3754,
    '1157_821': 40.4453,
    '1157_822': 40.5378,
    '1157_823': 40.6441,
    '1157_824': 40.7476,
    '1157_825': 40.8393,
    '1157_826': 40.925,
    '1157_827': 41.0081,
    '1157_828': 41.0767,
    '1157_829': 41.1366,
    '1157_830': 41.2124,
    '1157_831': 41.2879,
    '1157_832': 41.3549,
    '1157_833': 41.4071,
    '1157_834': 41.444,
    '1157_835': 41.4684,
    '1157_836': 41.4592,
    '1157_837': 41.4052,
    '1157_838': 41.3375,
    '1157_839': 41.2739,
    '1157_840': 41.244,
    '1157_841': 41.244,
    '1157_842': 41.2648,
    '1157_843': 41.2909,
    '1157_844': 41.3291,
    '1157_845': 41.384,
    '1157_846': 41.4514,
    '1157_847': 41.5278,
    '1157_848': 41.5987,
    '1157_849': 41.6633,
    '1157_850': 41.733,
    '1157_851': 41.7967,
    '1157_852': 41.8713,
    '1157_853': 41.9292,
    '1157_854': 41.9774,
    '1157_855': 42.0027,
    '1157_856': 42.0099,
    '1157_857': 42.0166,
    '1157_858': 42.0144,
    '1157_859': 42.0017,
    '1157_860': 41.9749,
    '1157_861': 41.925,
    '1157_862': 41.8584,
    '1157_863': 41.8015,
    '1157_864': 41.7624,
    '1157_865': 41.7347,
    '1157_866': 41.6952,
    '1157_867': 41.6226,
    '1157_868': 41.5141,
    '1157_869': 41.3758,
    '1157_870': 41.2272,
    '1157_871': 41.04,
    '1157_872': 40.8225,
    '1157_873': 40.5984,
    '1157_874': 40.3626,
    '1157_875': 40.1265,
    '1157_876': 39.8907,
    '1157_877': 39.6504,
    '1157_878': 39.4124,
    '1157_879': 39.1716,
    '1157_880': 38.9232,
    '1157_881': 38.6638,
    '1157_882': 38.392,
    '1157_883': 38.1097,
    '1157_884': 37.8152,
    '1157_885': 37.5071,
    '1157_886': 37.1937,
    '1157_887': 36.8755,
    '1157_888': 36.5493,
    '1157_889': 36.202,
    '1157_890': 35.8398,
    '1157_891': 35.4673,
    '1157_892': 35.0846,
    '1157_893': 34.6891,
    '1157_894': 34.2938,
    '1157_895': 33.8917,
    '1157_896': 33.4702,
    '1157_897': 33.0471,
    '1157_898': 32.6147,
    '1157_899': 32.1581,
    '1157_900': 31.7727,
    '1158_786': 37.4299,
    '1158_787': 37.4487,
    '1158_788': 37.4766,
    '1158_789': 37.5158,
    '1158_790': 37.5677,
    '1158_791': 37.6301,
    '1158_792': 37.7018,
    '1158_793': 37.7826,
    '1158_794': 37.8716,
    '1158_795': 37.9675,
    '1158_796': 38.0674,
    '1158_797': 38.1766,
    '1158_798': 38.2932,
    '1158_799': 38.4228,
    '1158_800': 38.5651,
    '1158_801': 38.7001,
    '1158_802': 38.8403,
    '1158_803': 38.9741,
    '1158_804': 39.1075,
    '1158_805': 39.254,
    '1158_806': 39.4055,
    '1158_807': 39.5365,
    '1158_808': 39.6454,
    '1158_809': 39.7335,
    '1158_810': 39.8174,
    '1158_811': 39.8996,
    '1158_812': 39.987,
    '1158_813': 40.0658,
    '1158_814': 40.1177,
    '1158_815': 40.1415,
    '1158_816': 40.1603,
    '1158_817': 40.1888,
    '1158_818': 40.2304,
    '1158_819': 40.2782,
    '1158_820': 40.3347,
    '1158_821': 40.4062,
    '1158_822': 40.4973,
    '1158_823': 40.6035,
    '1158_824': 40.7159,
    '1158_825': 40.8172,
    '1158_826': 40.9019,
    '1158_827': 40.9848,
    '1158_828': 41.0529,
    '1158_829': 41.0978,
    '1158_830': 41.1598,
    '1158_831': 41.2371,
    '1158_832': 41.3054,
    '1158_833': 41.3617,
    '1158_834': 41.4062,
    '1158_835': 41.4314,
    '1158_836': 41.4203,
    '1158_837': 41.3741,
    '1158_838': 41.3128,
    '1158_839': 41.2637,
    '1158_840': 41.2439,
    '1158_841': 41.2473,
    '1158_842': 41.264,
    '1158_843': 41.2912,
    '1158_844': 41.3321,
    '1158_845': 41.3859,
    '1158_846': 41.451,
    '1158_847': 41.5287,
    '1158_848': 41.6038,
    '1158_849': 41.6742,
    '1158_850': 41.7437,
    '1158_851': 41.8053,
    '1158_852': 41.8705,
    '1158_853': 41.9182,
    '1158_854': 41.9623,
    '1158_855': 41.9859,
    '1158_856': 41.9998,
    '1158_857': 42.0065,
    '1158_858': 42.0033,
    '1158_859': 41.9893,
    '1158_860': 41.9638,
    '1158_861': 41.9111,
    '1158_862': 41.8443,
    '1158_863': 41.7917,
    '1158_864': 41.7641,
    '1158_865': 41.7462,
    '1158_866': 41.7137,
    '1158_867': 41.6448,
    '1158_868': 41.5357,
    '1158_869': 41.4023,
    '1158_870': 41.2463,
    '1158_871': 41.0525,
    '1158_872': 40.8379,
    '1158_873': 40.6156,
    '1158_874': 40.3713,
    '1158_875': 40.1288,
    '1158_876': 39.8857,
    '1158_877': 39.6482,
    '1158_878': 39.411,
    '1158_879': 39.1705,
    '1158_880': 38.9218,
    '1158_881': 38.6655,
    '1158_882': 38.3954,
    '1158_883': 38.1137,
    '1158_884': 37.8206,
    '1158_885': 37.5165,
    '1158_886': 37.2046,
    '1158_887': 36.8863,
    '1158_888': 36.562,
    '1158_889': 36.2206,
    '1158_890': 35.8609,
    '1158_891': 35.4883,
    '1158_892': 35.1044,
    '1158_893': 34.71,
    '1158_894': 34.3105,
    '1158_895': 33.9039,
    '1158_896': 33.4857,
    '1158_897': 33.0595,
    '1158_898': 32.6291,
    '1158_899': 32.1903,
    '1158_900': 31.778,
    '1159_786': 37.4038,
    '1159_787': 37.4238,
    '1159_788': 37.4506,
    '1159_789': 37.4866,
    '1159_790': 37.5329,
    '1159_791': 37.5888,
    '1159_792': 37.6538,
    '1159_793': 37.7279,
    '1159_794': 37.8106,
    '1159_795': 37.9012,
    '1159_796': 37.9992,
    '1159_797': 38.1043,
    '1159_798': 38.2145,
    '1159_799': 38.3379,
    '1159_800': 38.4738,
    '1159_801': 38.6084,
    '1159_802': 38.7537,
    '1159_803': 38.8942,
    '1159_804': 39.036,
    '1159_805': 39.1931,
    '1159_806': 39.352,
    '1159_807': 39.4883,
    '1159_808': 39.601,
    '1159_809': 39.69,
    '1159_810': 39.7707,
    '1159_811': 39.8508,
    '1159_812': 39.9397,
    '1159_813': 40.0139,
    '1159_814': 40.0611,
    '1159_815': 40.0903,
    '1159_816': 40.1168,
    '1159_817': 40.1501,
    '1159_818': 40.1927,
    '1159_819': 40.2403,
    '1159_820': 40.2968,
    '1159_821': 40.3689,
    '1159_822': 40.4628,
    '1159_823': 40.5708,
    '1159_824': 40.694,
    '1159_825': 40.8029,
    '1159_826': 40.8872,
    '1159_827': 40.9675,
    '1159_828': 41.0342,
    '1159_829': 41.0694,
    '1159_830': 41.1212,
    '1159_831': 41.1977,
    '1159_832': 41.2753,
    '1159_833': 41.3456,
    '1159_834': 41.4001,
    '1159_835': 41.4271,
    '1159_836': 41.4154,
    '1159_837': 41.378,
    '1159_838': 41.3231,
    '1159_839': 41.2731,
    '1159_840': 41.2528,
    '1159_841': 41.2542,
    '1159_842': 41.2678,
    '1159_843': 41.295,
    '1159_844': 41.3353,
    '1159_845': 41.3866,
    '1159_846': 41.4502,
    '1159_847': 41.5281,
    '1159_848': 41.6064,
    '1159_849': 41.6776,
    '1159_850': 41.7472,
    '1159_851': 41.8153,
    '1159_852': 41.8735,
    '1159_853': 41.9166,
    '1159_854': 41.9606,
    '1159_855': 41.9867,
    '1159_856': 42.013,
    '1159_857': 42.0188,
    '1159_858': 41.9999,
    '1159_859': 41.9735,
    '1159_860': 41.9423,
    '1159_861': 41.8846,
    '1159_862': 41.8233,
    '1159_863': 41.7798,
    '1159_864': 41.7615,
    '1159_865': 41.7506,
    '1159_866': 41.7187,
    '1159_867': 41.6538,
    '1159_868': 41.5465,
    '1159_869': 41.411,
    '1159_870': 41.2472,
    '1159_871': 41.0581,
    '1159_872': 40.8465,
    '1159_873': 40.6127,
    '1159_874': 40.3674,
    '1159_875': 40.1265,
    '1159_876': 39.8844,
    '1159_877': 39.6508,
    '1159_878': 39.4163,
    '1159_879': 39.1751,
    '1159_880': 38.9222,
    '1159_881': 38.6658,
    '1159_882': 38.3962,
    '1159_883': 38.115,
    '1159_884': 37.823,
    '1159_885': 37.5214,
    '1159_886': 37.2113,
    '1159_887': 36.8931,
    '1159_888': 36.5682,
    '1159_889': 36.2289,
    '1159_890': 35.8753,
    '1159_891': 35.5048,
    '1159_892': 35.1199,
    '1159_893': 34.7238,
    '1159_894': 34.3213,
    '1159_895': 33.9132,
    '1159_896': 33.4983,
    '1159_897': 33.0667,
    '1159_898': 32.6371,
    '1159_899': 32.2048,
    '1159_900': 31.7772,
    '1160_786': 37.3787,
    '1160_787': 37.3991,
    '1160_788': 37.4245,
    '1160_789': 37.4572,
    '1160_790': 37.4984,
    '1160_791': 37.5482,
    '1160_792': 37.6068,
    '1160_793': 37.6744,
    '1160_794': 37.7513,
    '1160_795': 37.8373,
    '1160_796': 37.9323,
    '1160_797': 38.0348,
    '1160_798': 38.1419,
    '1160_799': 38.2605,
    '1160_800': 38.39,
    '1160_801': 38.5261,
    '1160_802': 38.6742,
    '1160_803': 38.8205,
    '1160_804': 38.9724,
    '1160_805': 39.1354,
    '1160_806': 39.3036,
    '1160_807': 39.4556,
    '1160_808': 39.5743,
    '1160_809': 39.6539,
    '1160_810': 39.7287,
    '1160_811': 39.8029,
    '1160_812': 39.8876,
    '1160_813': 39.9591,
    '1160_814': 40.0069,
    '1160_815': 40.0424,
    '1160_816': 40.0745,
    '1160_817': 40.1146,
    '1160_818': 40.1594,
    '1160_819': 40.2079,
    '1160_820': 40.2641,
    '1160_821': 40.3365,
    '1160_822': 40.4307,
    '1160_823': 40.5433,
    '1160_824': 40.674,
    '1160_825': 40.7927,
    '1160_826': 40.8777,
    '1160_827': 40.9515,
    '1160_828': 41.0123,
    '1160_829': 41.0435,
    '1160_830': 41.093,
    '1160_831': 41.1708,
    '1160_832': 41.2581,
    '1160_833': 41.3379,
    '1160_834': 41.4007,
    '1160_835': 41.4319,
    '1160_836': 41.4256,
    '1160_837': 41.3974,
    '1160_838': 41.3438,
    '1160_839': 41.2899,
    '1160_840': 41.2648,
    '1160_841': 41.2618,
    '1160_842': 41.272,
    '1160_843': 41.297,
    '1160_844': 41.336,
    '1160_845': 41.3877,
    '1160_846': 41.4506,
    '1160_847': 41.5284,
    '1160_848': 41.6063,
    '1160_849': 41.676,
    '1160_850': 41.7422,
    '1160_851': 41.8143,
    '1160_852': 41.872,
    '1160_853': 41.9158,
    '1160_854': 41.9564,
    '1160_855': 41.9953,
    '1160_856': 42.0345,
    '1160_857': 42.0463,
    '1160_858': 42.0179,
    '1160_859': 41.9763,
    '1160_860': 41.9302,
    '1160_861': 41.8647,
    '1160_862': 41.8078,
    '1160_863': 41.7708,
    '1160_864': 41.7564,
    '1160_865': 41.7459,
    '1160_866': 41.7177,
    '1160_867': 41.6519,
    '1160_868': 41.5441,
    '1160_869': 41.4002,
    '1160_870': 41.2285,
    '1160_871': 41.0426,
    '1160_872': 40.8326,
    '1160_873': 40.5927,
    '1160_874': 40.3576,
    '1160_875': 40.1218,
    '1160_876': 39.8808,
    '1160_877': 39.6452,
    '1160_878': 39.4141,
    '1160_879': 39.1767,
    '1160_880': 38.9247,
    '1160_881': 38.6677,
    '1160_882': 38.3978,
    '1160_883': 38.1168,
    '1160_884': 37.8257,
    '1160_885': 37.5256,
    '1160_886': 37.2174,
    '1160_887': 36.9008,
    '1160_888': 36.5747,
    '1160_889': 36.2363,
    '1160_890': 35.89,
    '1160_891': 35.5193,
    '1160_892': 35.131,
    '1160_893': 34.7319,
    '1160_894': 34.3271,
    '1160_895': 33.9194,
    '1160_896': 33.5103,
    '1160_897': 33.0671,
    '1160_898': 32.6354,
    '1160_899': 32.2045,
    '1160_900': 31.7694,
    '1161_786': 37.3498,
    '1161_787': 37.3703,
    '1161_788': 37.3947,
    '1161_789': 37.4249,
    '1161_790': 37.4618,
    '1161_791': 37.5061,
    '1161_792': 37.5585,
    '1161_793': 37.62,
    '1161_794': 37.6913,
    '1161_795': 37.7736,
    '1161_796': 37.8665,
    '1161_797': 37.9681,
    '1161_798': 38.0767,
    '1161_799': 38.1907,
    '1161_800': 38.3176,
    '1161_801': 38.4564,
    '1161_802': 38.6056,
    '1161_803': 38.7602,
    '1161_804': 38.9201,
    '1161_805': 39.0887,
    '1161_806': 39.2683,
    '1161_807': 39.4261,
    '1161_808': 39.5416,
    '1161_809': 39.6125,
    '1161_810': 39.6828,
    '1161_811': 39.7533,
    '1161_812': 39.8312,
    '1161_813': 39.9022,
    '1161_814': 39.954,
    '1161_815': 39.9991,
    '1161_816': 40.0378,
    '1161_817': 40.0851,
    '1161_818': 40.133,
    '1161_819': 40.1827,
    '1161_820': 40.2387,
    '1161_821': 40.3104,
    '1161_822': 40.4023,
    '1161_823': 40.516,
    '1161_824': 40.6536,
    '1161_825': 40.7823,
    '1161_826': 40.8695,
    '1161_827': 40.9375,
    '1161_828': 40.9898,
    '1161_829': 41.0184,
    '1161_830': 41.0726,
    '1161_831': 41.1552,
    '1161_832': 41.2498,
    '1161_833': 41.3344,
    '1161_834': 41.4003,
    '1161_835': 41.4343,
    '1161_836': 41.4353,
    '1161_837': 41.4137,
    '1161_838': 41.3625,
    '1161_839': 41.305,
    '1161_840': 41.2755,
    '1161_841': 41.2687,
    '1161_842': 41.2756,
    '1161_843': 41.2979,
    '1161_844': 41.3334,
    '1161_845': 41.3826,
    '1161_846': 41.4466,
    '1161_847': 41.5222,
    '1161_848': 41.5991,
    '1161_849': 41.6687,
    '1161_850': 41.7363,
    '1161_851': 41.8083,
    '1161_852': 41.8731,
    '1161_853': 41.9166,
    '1161_854': 41.9581,
    '1161_855': 42.0036,
    '1161_856': 42.0463,
    '1161_857': 42.0642,
    '1161_858': 42.0476,
    '1161_859': 41.9994,
    '1161_860': 41.9428,
    '1161_861': 41.8658,
    '1161_862': 41.8037,
    '1161_863': 41.7675,
    '1161_864': 41.7498,
    '1161_865': 41.7369,
    '1161_866': 41.7063,
    '1161_867': 41.6377,
    '1161_868': 41.5284,
    '1161_869': 41.3807,
    '1161_870': 41.2061,
    '1161_871': 41.0163,
    '1161_872': 40.8075,
    '1161_873': 40.5791,
    '1161_874': 40.3619,
    '1161_875': 40.1294,
    '1161_876': 39.8853,
    '1161_877': 39.6478,
    '1161_878': 39.4149,
    '1161_879': 39.1739,
    '1161_880': 38.9264,
    '1161_881': 38.6689,
    '1161_882': 38.3986,
    '1161_883': 38.1177,
    '1161_884': 37.8269,
    '1161_885': 37.5271,
    '1161_886': 37.2202,
    '1161_887': 36.9047,
    '1161_888': 36.5776,
    '1161_889': 36.2432,
    '1161_890': 35.9043,
    '1161_891': 35.5282,
    '1161_892': 35.1337,
    '1161_893': 34.7314,
    '1161_894': 34.3246,
    '1161_895': 33.9151,
    '1161_896': 33.4997,
    '1161_897': 33.0604,
    '1161_898': 32.6291,
    '1161_899': 32.1966,
    '1161_900': 31.7534,
    '1162_786': 37.3148,
    '1162_787': 37.3376,
    '1162_788': 37.3622,
    '1162_789': 37.3905,
    '1162_790': 37.4242,
    '1162_791': 37.4637,
    '1162_792': 37.5103,
    '1162_793': 37.5655,
    '1162_794': 37.6315,
    '1162_795': 37.7107,
    '1162_796': 37.8031,
    '1162_797': 37.9054,
    '1162_798': 38.015,
    '1162_799': 38.129,
    '1162_800': 38.2563,
    '1162_801': 38.3978,
    '1162_802': 38.5505,
    '1162_803': 38.7133,
    '1162_804': 38.8808,
    '1162_805': 39.0489,
    '1162_806': 39.2306,
    '1162_807': 39.3866,
    '1162_808': 39.4937,
    '1162_809': 39.5615,
    '1162_810': 39.6284,
    '1162_811': 39.6968,
    '1162_812': 39.7724,
    '1162_813': 39.8458,
    '1162_814': 39.9028,
    '1162_815': 39.9579,
    '1162_816': 40.0072,
    '1162_817': 40.0533,
    '1162_818': 40.1016,
    '1162_819': 40.1549,
    '1162_820': 40.2097,
    '1162_821': 40.2804,
    '1162_822': 40.3713,
    '1162_823': 40.486,
    '1162_824': 40.627,
    '1162_825': 40.7611,
    '1162_826': 40.8576,
    '1162_827': 40.9255,
    '1162_828': 40.9694,
    '1162_829': 40.9997,
    '1162_830': 41.0599,
    '1162_831': 41.1488,
    '1162_832': 41.2461,
    '1162_833': 41.3297,
    '1162_834': 41.3953,
    '1162_835': 41.4307,
    '1162_836': 41.4388,
    '1162_837': 41.4236,
    '1162_838': 41.3749,
    '1162_839': 41.3202,
    '1162_840': 41.2868,
    '1162_841': 41.2743,
    '1162_842': 41.2782,
    '1162_843': 41.2977,
    '1162_844': 41.3302,
    '1162_845': 41.3767,
    '1162_846': 41.4409,
    '1162_847': 41.5153,
    '1162_848': 41.591,
    '1162_849': 41.6597,
    '1162_850': 41.7359,
    '1162_851': 41.8207,
    '1162_852': 41.8913,
    '1162_853': 41.9348,
    '1162_854': 41.975,
    '1162_855': 42.0218,
    '1162_856': 42.0617,
    '1162_857': 42.0869,
    '1162_858': 42.0767,
    '1162_859': 42.026,
    '1162_860': 41.9609,
    '1162_861': 41.8809,
    '1162_862': 41.8134,
    '1162_863': 41.7761,
    '1162_864': 41.7509,
    '1162_865': 41.7314,
    '1162_866': 41.6993,
    '1162_867': 41.6314,
    '1162_868': 41.5216,
    '1162_869': 41.3755,
    '1162_870': 41.2031,
    '1162_871': 41.0147,
    '1162_872': 40.8083,
    '1162_873': 40.5842,
    '1162_874': 40.363,
    '1162_875': 40.1322,
    '1162_876': 39.895,
    '1162_877': 39.6626,
    '1162_878': 39.4173,
    '1162_879': 39.1729,
    '1162_880': 38.9249,
    '1162_881': 38.6685,
    '1162_882': 38.3984,
    '1162_883': 38.1176,
    '1162_884': 37.8274,
    '1162_885': 37.5255,
    '1162_886': 37.2189,
    '1162_887': 36.9037,
    '1162_888': 36.5754,
    '1162_889': 36.243,
    '1162_890': 35.8953,
    '1162_891': 35.523,
    '1162_892': 35.1278,
    '1162_893': 34.723,
    '1162_894': 34.3154,
    '1162_895': 33.9083,
    '1162_896': 33.5025,
    '1162_897': 33.0587,
    '1162_898': 32.6226,
    '1162_899': 32.1848,
    '1162_900': 31.7312,
    '1163_786': 37.2784,
    '1163_787': 37.3037,
    '1163_788': 37.3289,
    '1163_789': 37.3564,
    '1163_790': 37.3874,
    '1163_791': 37.423,
    '1163_792': 37.4645,
    '1163_793': 37.5134,
    '1163_794': 37.5733,
    '1163_795': 37.6504,
    '1163_796': 37.7443,
    '1163_797': 37.8487,
    '1163_798': 37.96,
    '1163_799': 38.0755,
    '1163_800': 38.2035,
    '1163_801': 38.3462,
    '1163_802': 38.5048,
    '1163_803': 38.6727,
    '1163_804': 38.8443,
    '1163_805': 39.0077,
    '1163_806': 39.1833,
    '1163_807': 39.3323,
    '1163_808': 39.4298,
    '1163_809': 39.4987,
    '1163_810': 39.5687,
    '1163_811': 39.6373,
    '1163_812': 39.7127,
    '1163_813': 39.7887,
    '1163_814': 39.8516,
    '1163_815': 39.9154,
    '1163_816': 39.9722,
    '1163_817': 40.0189,
    '1163_818': 40.0659,
    '1163_819': 40.117,
    '1163_820': 40.1714,
    '1163_821': 40.2435,
    '1163_822': 40.3353,
    '1163_823': 40.4494,
    '1163_824': 40.5925,
    '1163_825': 40.7295,
    '1163_826': 40.8383,
    '1163_827': 40.9136,
    '1163_828': 40.9548,
    '1163_829': 40.992,
    '1163_830': 41.0563,
    '1163_831': 41.149,
    '1163_832': 41.2468,
    '1163_833': 41.3286,
    '1163_834': 41.391,
    '1163_835': 41.4288,
    '1163_836': 41.442,
    '1163_837': 41.433,
    '1163_838': 41.3971,
    '1163_839': 41.3455,
    '1163_840': 41.3054,
    '1163_841': 41.2823,
    '1163_842': 41.2808,
    '1163_843': 41.2968,
    '1163_844': 41.3268,
    '1163_845': 41.374,
    '1163_846': 41.4392,
    '1163_847': 41.5116,
    '1163_848': 41.5854,
    '1163_849': 41.6569,
    '1163_850': 41.7427,
    '1163_851': 41.8397,
    '1163_852': 41.9169,
    '1163_853': 41.9623,
    '1163_854': 41.9973,
    '1163_855': 42.0487,
    '1163_856': 42.0911,
    '1163_857': 42.1139,
    '1163_858': 42.0973,
    '1163_859': 42.0418,
    '1163_860': 41.9688,
    '1163_861': 41.8916,
    '1163_862': 41.8293,
    '1163_863': 41.7957,
    '1163_864': 41.7657,
    '1163_865': 41.7343,
    '1163_866': 41.699,
    '1163_867': 41.6354,
    '1163_868': 41.5314,
    '1163_869': 41.3927,
    '1163_870': 41.2248,
    '1163_871': 41.0308,
    '1163_872': 40.8143,
    '1163_873': 40.5862,
    '1163_874': 40.3658,
    '1163_875': 40.14,
    '1163_876': 39.9154,
    '1163_877': 39.6799,
    '1163_878': 39.4241,
    '1163_879': 39.1762,
    '1163_880': 38.9291,
    '1163_881': 38.6705,
    '1163_882': 38.3979,
    '1163_883': 38.1146,
    '1163_884': 37.8228,
    '1163_885': 37.5215,
    '1163_886': 37.2144,
    '1163_887': 36.8997,
    '1163_888': 36.5701,
    '1163_889': 36.235,
    '1163_890': 35.8799,
    '1163_891': 35.5113,
    '1163_892': 35.1134,
    '1163_893': 34.7068,
    '1163_894': 34.2979,
    '1163_895': 33.8888,
    '1163_896': 33.4772,
    '1163_897': 33.0468,
    '1163_898': 32.6114,
    '1163_899': 32.1715,
    '1163_900': 31.7187,
    '1164_786': 37.2442,
    '1164_787': 37.2713,
    '1164_788': 37.2972,
    '1164_789': 37.324,
    '1164_790': 37.3533,
    '1164_791': 37.3863,
    '1164_792': 37.4241,
    '1164_793': 37.4683,
    '1164_794': 37.5221,
    '1164_795': 37.5977,
    '1164_796': 37.6942,
    '1164_797': 37.8003,
    '1164_798': 37.9131,
    '1164_799': 38.0321,
    '1164_800': 38.1583,
    '1164_801': 38.3027,
    '1164_802': 38.4662,
    '1164_803': 38.631,
    '1164_804': 38.798,
    '1164_805': 38.9614,
    '1164_806': 39.1309,
    '1164_807': 39.27,
    '1164_808': 39.356,
    '1164_809': 39.4283,
    '1164_810': 39.5083,
    '1164_811': 39.578,
    '1164_812': 39.6525,
    '1164_813': 39.7299,
    '1164_814': 39.7974,
    '1164_815': 39.8656,
    '1164_816': 39.9273,
    '1164_817': 39.9769,
    '1164_818': 40.0254,
    '1164_819': 40.0753,
    '1164_820': 40.1306,
    '1164_821': 40.2027,
    '1164_822': 40.2935,
    '1164_823': 40.4056,
    '1164_824': 40.5445,
    '1164_825': 40.6842,
    '1164_826': 40.8129,
    '1164_827': 40.9069,
    '1164_828': 40.9572,
    '1164_829': 40.9955,
    '1164_830': 41.0598,
    '1164_831': 41.1499,
    '1164_832': 41.2475,
    '1164_833': 41.3306,
    '1164_834': 41.391,
    '1164_835': 41.4303,
    '1164_836': 41.4466,
    '1164_837': 41.4406,
    '1164_838': 41.4158,
    '1164_839': 41.3716,
    '1164_840': 41.3293,
    '1164_841': 41.2922,
    '1164_842': 41.2808,
    '1164_843': 41.2903,
    '1164_844': 41.3223,
    '1164_845': 41.3745,
    '1164_846': 41.441,
    '1164_847': 41.5112,
    '1164_848': 41.5856,
    '1164_849': 41.6653,
    '1164_850': 41.7585,
    '1164_851': 41.8606,
    '1164_852': 41.9438,
    '1164_853': 41.9865,
    '1164_854': 42.0224,
    '1164_855': 42.0674,
    '1164_856': 42.1132,
    '1164_857': 42.134,
    '1164_858': 42.1133,
    '1164_859': 42.0515,
    '1164_860': 41.9709,
    '1164_861': 41.8999,
    '1164_862': 41.855,
    '1164_863': 41.8298,
    '1164_864': 41.7941,
    '1164_865': 41.7514,
    '1164_866': 41.7074,
    '1164_867': 41.6416,
    '1164_868': 41.5442,
    '1164_869': 41.4115,
    '1164_870': 41.2464,
    '1164_871': 41.0466,
    '1164_872': 40.8233,
    '1164_873': 40.5976,
    '1164_874': 40.3739,
    '1164_875': 40.1592,
    '1164_876': 39.9375,
    '1164_877': 39.6982,
    '1164_878': 39.4415,
    '1164_879': 39.1848,
    '1164_880': 38.9356,
    '1164_881': 38.6767,
    '1164_882': 38.3987,
    '1164_883': 38.1097,
    '1164_884': 37.8162,
    '1164_885': 37.5155,
    '1164_886': 37.2085,
    '1164_887': 36.8968,
    '1164_888': 36.5696,
    '1164_889': 36.2272,
    '1164_890': 35.8628,
    '1164_891': 35.4848,
    '1164_892': 35.0994,
    '1164_893': 34.6892,
    '1164_894': 34.2794,
    '1164_895': 33.8698,
    '1164_896': 33.453,
    '1164_897': 33.0279,
    '1164_898': 32.5951,
    '1164_899': 32.1558,
    '1164_900': 31.7078,
    '1165_786': 37.2139,
    '1165_787': 37.2418,
    '1165_788': 37.2679,
    '1165_789': 37.294,
    '1165_790': 37.3223,
    '1165_791': 37.3544,
    '1165_792': 37.3919,
    '1165_793': 37.437,
    '1165_794': 37.4934,
    '1165_795': 37.5674,
    '1165_796': 37.6591,
    '1165_797': 37.7621,
    '1165_798': 37.8734,
    '1165_799': 37.9922,
    '1165_800': 38.1179,
    '1165_801': 38.263,
    '1165_802': 38.428,
    '1165_803': 38.5867,
    '1165_804': 38.7465,
    '1165_805': 38.9096,
    '1165_806': 39.0704,
    '1165_807': 39.1992,
    '1165_808': 39.2798,
    '1165_809': 39.3541,
    '1165_810': 39.4377,
    '1165_811': 39.5144,
    '1165_812': 39.5922,
    '1165_813': 39.6702,
    '1165_814': 39.7412,
    '1165_815': 39.8095,
    '1165_816': 39.8745,
    '1165_817': 39.9291,
    '1165_818': 39.9812,
    '1165_819': 40.0358,
    '1165_820': 40.095,
    '1165_821': 40.1644,
    '1165_822': 40.253,
    '1165_823': 40.3594,
    '1165_824': 40.4894,
    '1165_825': 40.6379,
    '1165_826': 40.7846,
    '1165_827': 40.8977,
    '1165_828': 40.9682,
    '1165_829': 41.0175,
    '1165_830': 41.0718,
    '1165_831': 41.1521,
    '1165_832': 41.2452,
    '1165_833': 41.3275,
    '1165_834': 41.3886,
    '1165_835': 41.43,
    '1165_836': 41.4488,
    '1165_837': 41.449,
    '1165_838': 41.4292,
    '1165_839': 41.3853,
    '1165_840': 41.3394,
    '1165_841': 41.2999,
    '1165_842': 41.2811,
    '1165_843': 41.2842,
    '1165_844': 41.3206,
    '1165_845': 41.3775,
    '1165_846': 41.4436,
    '1165_847': 41.5152,
    '1165_848': 41.5947,
    '1165_849': 41.6807,
    '1165_850': 41.7753,
    '1165_851': 41.8695,
    '1165_852': 41.9478,
    '1165_853': 41.9992,
    '1165_854': 42.0469,
    '1165_855': 42.089,
    '1165_856': 42.1286,
    '1165_857': 42.1516,
    '1165_858': 42.1328,
    '1165_859': 42.0699,
    '1165_860': 41.9867,
    '1165_861': 41.9204,
    '1165_862': 41.8851,
    '1165_863': 41.863,
    '1165_864': 41.8256,
    '1165_865': 41.7749,
    '1165_866': 41.7204,
    '1165_867': 41.6523,
    '1165_868': 41.555,
    '1165_869': 41.4244,
    '1165_870': 41.2558,
    '1165_871': 41.0525,
    '1165_872': 40.8292,
    '1165_873': 40.608,
    '1165_874': 40.3896,
    '1165_875': 40.1758,
    '1165_876': 39.942,
    '1165_877': 39.6942,
    '1165_878': 39.4492,
    '1165_879': 39.1961,
    '1165_880': 38.9513,
    '1165_881': 38.6888,
    '1165_882': 38.4005,
    '1165_883': 38.1042,
    '1165_884': 37.8112,
    '1165_885': 37.5132,
    '1165_886': 37.2066,
    '1165_887': 36.8928,
    '1165_888': 36.5626,
    '1165_889': 36.2158,
    '1165_890': 35.8489,
    '1165_891': 35.4697,
    '1165_892': 35.0853,
    '1165_893': 34.6777,
    '1165_894': 34.2679,
    '1165_895': 33.8546,
    '1165_896': 33.4327,
    '1165_897': 33.0096,
    '1165_898': 32.5786,
    '1165_899': 32.1407,
    '1165_900': 31.7,
    '1166_786': 37.188,
    '1166_787': 37.2158,
    '1166_788': 37.2413,
    '1166_789': 37.2665,
    '1166_790': 37.294,
    '1166_791': 37.3259,
    '1166_792': 37.3644,
    '1166_793': 37.4115,
    '1166_794': 37.4702,
    '1166_795': 37.5432,
    '1166_796': 37.6308,
    '1166_797': 37.7301,
    '1166_798': 37.8389,
    '1166_799': 37.9566,
    '1166_800': 38.0816,
    '1166_801': 38.2262,
    '1166_802': 38.3917,
    '1166_803': 38.5477,
    '1166_804': 38.7039,
    '1166_805': 38.8606,
    '1166_806': 39.0066,
    '1166_807': 39.123,
    '1166_808': 39.2022,
    '1166_809': 39.2812,
    '1166_810': 39.3696,
    '1166_811': 39.4524,
    '1166_812': 39.5333,
    '1166_813': 39.6115,
    '1166_814': 39.6844,
    '1166_815': 39.7554,
    '1166_816': 39.825,
    '1166_817': 39.8873,
    '1166_818': 39.9401,
    '1166_819': 40.0004,
    '1166_820': 40.0627,
    '1166_821': 40.133,
    '1166_822': 40.2195,
    '1166_823': 40.3211,
    '1166_824': 40.4452,
    '1166_825': 40.593,
    '1166_826': 40.7511,
    '1166_827': 40.8862,
    '1166_828': 40.9781,
    '1166_829': 41.0375,
    '1166_830': 41.0854,
    '1166_831': 41.1522,
    '1166_832': 41.2361,
    '1166_833': 41.3178,
    '1166_834': 41.3827,
    '1166_835': 41.4274,
    '1166_836': 41.45,
    '1166_837': 41.4519,
    '1166_838': 41.437,
    '1166_839': 41.3923,
    '1166_840': 41.3367,
    '1166_841': 41.3023,
    '1166_842': 41.2845,
    '1166_843': 41.2829,
    '1166_844': 41.3192,
    '1166_845': 41.3796,
    '1166_846': 41.4463,
    '1166_847': 41.5213,
    '1166_848': 41.6074,
    '1166_849': 41.7009,
    '1166_850': 41.7934,
    '1166_851': 41.8761,
    '1166_852': 41.9441,
    '1166_853': 42.0067,
    '1166_854': 42.0658,
    '1166_855': 42.111,
    '1166_856': 42.1476,
    '1166_857': 42.1716,
    '1166_858': 42.1581,
    '1166_859': 42.101,
    '1166_860': 42.0202,
    '1166_861': 41.9536,
    '1166_862': 41.9175,
    '1166_863': 41.8935,
    '1166_864': 41.8567,
    '1166_865': 41.8019,
    '1166_866': 41.7368,
    '1166_867': 41.6582,
    '1166_868': 41.558,
    '1166_869': 41.4274,
    '1166_870': 41.2545,
    '1166_871': 41.0481,
    '1166_872': 40.83,
    '1166_873': 40.6191,
    '1166_874': 40.41,
    '1166_875': 40.1818,
    '1166_876': 39.9338,
    '1166_877': 39.681,
    '1166_878': 39.4393,
    '1166_879': 39.1978,
    '1166_880': 38.9571,
    '1166_881': 38.6977,
    '1166_882': 38.4045,
    '1166_883': 38.1041,
    '1166_884': 37.804,
    '1166_885': 37.5045,
    '1166_886': 37.1978,
    '1166_887': 36.8822,
    '1166_888': 36.5502,
    '1166_889': 36.202,
    '1166_890': 35.8374,
    '1166_891': 35.4583,
    '1166_892': 35.0703,
    '1166_893': 34.668,
    '1166_894': 34.2573,
    '1166_895': 33.8406,
    '1166_896': 33.4196,
    '1166_897': 32.996,
    '1166_898': 32.5664,
    '1166_899': 32.1321,
    '1166_900': 31.6917,
    '1167_786': 37.1667,
    '1167_787': 37.1934,
    '1167_788': 37.2175,
    '1167_789': 37.2409,
    '1167_790': 37.2671,
    '1167_791': 37.2987,
    '1167_792': 37.338,
    '1167_793': 37.387,
    '1167_794': 37.447,
    '1167_795': 37.5194,
    '1167_796': 37.6044,
    '1167_797': 37.7007,
    '1167_798': 37.8077,
    '1167_799': 37.9243,
    '1167_800': 38.0487,
    '1167_801': 38.1921,
    '1167_802': 38.3571,
    '1167_803': 38.5188,
    '1167_804': 38.6718,
    '1167_805': 38.8228,
    '1167_806': 38.9487,
    '1167_807': 39.0526,
    '1167_808': 39.1331,
    '1167_809': 39.2181,
    '1167_810': 39.3077,
    '1167_811': 39.3919,
    '1167_812': 39.4757,
    '1167_813': 39.5565,
    '1167_814': 39.6329,
    '1167_815': 39.7067,
    '1167_816': 39.787,
    '1167_817': 39.8609,
    '1167_818': 39.9105,
    '1167_819': 39.9696,
    '1167_820': 40.0364,
    '1167_821': 40.1107,
    '1167_822': 40.1961,
    '1167_823': 40.2954,
    '1167_824': 40.4156,
    '1167_825': 40.5573,
    '1167_826': 40.7227,
    '1167_827': 40.873,
    '1167_828': 40.9813,
    '1167_829': 41.0505,
    '1167_830': 41.0975,
    '1167_831': 41.1514,
    '1167_832': 41.2263,
    '1167_833': 41.3062,
    '1167_834': 41.3731,
    '1167_835': 41.422,
    '1167_836': 41.4483,
    '1167_837': 41.4517,
    '1167_838': 41.4368,
    '1167_839': 41.3965,
    '1167_840': 41.3407,
    '1167_841': 41.311,
    '1167_842': 41.298,
    '1167_843': 41.2941,
    '1167_844': 41.3257,
    '1167_845': 41.3845,
    '1167_846': 41.4519,
    '1167_847': 41.5289,
    '1167_848': 41.6168,
    '1167_849': 41.7143,
    '1167_850': 41.7993,
    '1167_851': 41.8739,
    '1167_852': 41.9419,
    '1167_853': 42.0129,
    '1167_854': 42.0776,
    '1167_855': 42.1289,
    '1167_856': 42.1742,
    '1167_857': 42.2004,
    '1167_858': 42.1886,
    '1167_859': 42.1311,
    '1167_860': 42.0569,
    '1167_861': 41.9947,
    '1167_862': 41.9564,
    '1167_863': 41.9256,
    '1167_864': 41.8831,
    '1167_865': 41.8233,
    '1167_866': 41.7474,
    '1167_867': 41.6574,
    '1167_868': 41.5525,
    '1167_869': 41.4177,
    '1167_870': 41.2438,
    '1167_871': 41.0373,
    '1167_872': 40.8315,
    '1167_873': 40.6395,
    '1167_874': 40.4283,
    '1167_875': 40.1867,
    '1167_876': 39.9343,
    '1167_877': 39.6753,
    '1167_878': 39.428,
    '1167_879': 39.1875,
    '1167_880': 38.9482,
    '1167_881': 38.6955,
    '1167_882': 38.4024,
    '1167_883': 38.0983,
    '1167_884': 37.7944,
    '1167_885': 37.4893,
    '1167_886': 37.1841,
    '1167_887': 36.8664,
    '1167_888': 36.5337,
    '1167_889': 36.1854,
    '1167_890': 35.8188,
    '1167_891': 35.4419,
    '1167_892': 35.0563,
    '1167_893': 34.6575,
    '1167_894': 34.2455,
    '1167_895': 33.8253,
    '1167_896': 33.4034,
    '1167_897': 32.9805,
    '1167_898': 32.553,
    '1167_899': 32.123,
    '1167_900': 31.6773,
    '1168_786': 37.1506,
    '1168_787': 37.1754,
    '1168_788': 37.1973,
    '1168_789': 37.2176,
    '1168_790': 37.2413,
    '1168_791': 37.2723,
    '1168_792': 37.3126,
    '1168_793': 37.3631,
    '1168_794': 37.4242,
    '1168_795': 37.4963,
    '1168_796': 37.5794,
    '1168_797': 37.6736,
    '1168_798': 37.7791,
    '1168_799': 37.8954,
    '1168_800': 38.0197,
    '1168_801': 38.1615,
    '1168_802': 38.3232,
    '1168_803': 38.4898,
    '1168_804': 38.6446,
    '1168_805': 38.7916,
    '1168_806': 38.9128,
    '1168_807': 39.0008,
    '1168_808': 39.08,
    '1168_809': 39.1658,
    '1168_810': 39.2542,
    '1168_811': 39.3384,
    '1168_812': 39.4223,
    '1168_813': 39.5059,
    '1168_814': 39.5871,
    '1168_815': 39.6631,
    '1168_816': 39.7493,
    '1168_817': 39.8328,
    '1168_818': 39.8876,
    '1168_819': 39.9462,
    '1168_820': 40.0172,
    '1168_821': 40.0955,
    '1168_822': 40.1814,
    '1168_823': 40.2788,
    '1168_824': 40.3947,
    '1168_825': 40.5329,
    '1168_826': 40.6963,
    '1168_827': 40.8591,
    '1168_828': 40.9801,
    '1168_829': 41.0604,
    '1168_830': 41.112,
    '1168_831': 41.1587,
    '1168_832': 41.221,
    '1168_833': 41.2957,
    '1168_834': 41.3609,
    '1168_835': 41.4115,
    '1168_836': 41.439,
    '1168_837': 41.4426,
    '1168_838': 41.4289,
    '1168_839': 41.3944,
    '1168_840': 41.3502,
    '1168_841': 41.3265,
    '1168_842': 41.3132,
    '1168_843': 41.3134,
    '1168_844': 41.3391,
    '1168_845': 41.393,
    '1168_846': 41.4605,
    '1168_847': 41.539,
    '1168_848': 41.6253,
    '1168_849': 41.715,
    '1168_850': 41.7976,
    '1168_851': 41.8736,
    '1168_852': 41.9491,
    '1168_853': 42.0278,
    '1168_854': 42.0967,
    '1168_855': 42.1565,
    '1168_856': 42.2073,
    '1168_857': 42.2348,
    '1168_858': 42.2251,
    '1168_859': 42.1692,
    '1168_860': 42.0967,
    '1168_861': 42.04,
    '1168_862': 42.0004,
    '1168_863': 41.9629,
    '1168_864': 41.9103,
    '1168_865': 41.8368,
    '1168_866': 41.7488,
    '1168_867': 41.6489,
    '1168_868': 41.5381,
    '1168_869': 41.4048,
    '1168_870': 41.2314,
    '1168_871': 41.0356,
    '1168_872': 40.8452,
    '1168_873': 40.6527,
    '1168_874': 40.4312,
    '1168_875': 40.1853,
    '1168_876': 39.9302,
    '1168_877': 39.6698,
    '1168_878': 39.4229,
    '1168_879': 39.1809,
    '1168_880': 38.9359,
    '1168_881': 38.6801,
    '1168_882': 38.3985,
    '1168_883': 38.0861,
    '1168_884': 37.7838,
    '1168_885': 37.4773,
    '1168_886': 37.1691,
    '1168_887': 36.8495,
    '1168_888': 36.5168,
    '1168_889': 36.1709,
    '1168_890': 35.7982,
    '1168_891': 35.4221,
    '1168_892': 35.0422,
    '1168_893': 34.6474,
    '1168_894': 34.2321,
    '1168_895': 33.807,
    '1168_896': 33.3818,
    '1168_897': 32.9579,
    '1168_898': 32.5375,
    '1168_899': 32.1167,
    '1168_900': 31.6655,
    '1169_786': 37.1372,
    '1169_787': 37.1597,
    '1169_788': 37.1792,
    '1169_789': 37.1942,
    '1169_790': 37.2145,
    '1169_791': 37.2458,
    '1169_792': 37.2878,
    '1169_793': 37.3398,
    '1169_794': 37.4022,
    '1169_795': 37.4743,
    '1169_796': 37.5557,
    '1169_797': 37.6473,
    '1169_798': 37.7522,
    '1169_799': 37.8685,
    '1169_800': 37.9946,
    '1169_801': 38.1308,
    '1169_802': 38.2873,
    '1169_803': 38.4553,
    '1169_804': 38.6134,
    '1169_805': 38.7605,
    '1169_806': 38.885,
    '1169_807': 38.9659,
    '1169_808': 39.0372,
    '1169_809': 39.1203,
    '1169_810': 39.2059,
    '1169_811': 39.29,
    '1169_812': 39.3735,
    '1169_813': 39.4583,
    '1169_814': 39.5423,
    '1169_815': 39.6227,
    '1169_816': 39.7071,
    '1169_817': 39.7895,
    '1169_818': 39.859,
    '1169_819': 39.9273,
    '1169_820': 40.0033,
    '1169_821': 40.0858,
    '1169_822': 40.172,
    '1169_823': 40.267,
    '1169_824': 40.3774,
    '1169_825': 40.5103,
    '1169_826': 40.6732,
    '1169_827': 40.8452,
    '1169_828': 40.9801,
    '1169_829': 41.0704,
    '1169_830': 41.1229,
    '1169_831': 41.1616,
    '1169_832': 41.2166,
    '1169_833': 41.2802,
    '1169_834': 41.3454,
    '1169_835': 41.396,
    '1169_836': 41.4204,
    '1169_837': 41.4179,
    '1169_838': 41.4045,
    '1169_839': 41.3885,
    '1169_840': 41.3662,
    '1169_841': 41.3499,
    '1169_842': 41.3402,
    '1169_843': 41.3391,
    '1169_844': 41.3555,
    '1169_845': 41.4032,
    '1169_846': 41.4682,
    '1169_847': 41.5469,
    '1169_848': 41.6347,
    '1169_849': 41.7197,
    '1169_850': 41.8024,
    '1169_851': 41.8907,
    '1169_852': 41.9691,
    '1169_853': 42.0421,
    '1169_854': 42.1172,
    '1169_855': 42.1842,
    '1169_856': 42.2369,
    '1169_857': 42.2697,
    '1169_858': 42.2638,
    '1169_859': 42.2146,
    '1169_860': 42.145,
    '1169_861': 42.0873,
    '1169_862': 42.0448,
    '1169_863': 41.9971,
    '1169_864': 41.9296,
    '1169_865': 41.8416,
    '1169_866': 41.7373,
    '1169_867': 41.6306,
    '1169_868': 41.5195,
    '1169_869': 41.389,
    '1169_870': 41.2253,
    '1169_871': 41.0468,
    '1169_872': 40.86,
    '1169_873': 40.6536,
    '1169_874': 40.4201,
    '1169_875': 40.1748,
    '1169_876': 39.9212,
    '1169_877': 39.6689,
    '1169_878': 39.4279,
    '1169_879': 39.1861,
    '1169_880': 38.9343,
    '1169_881': 38.6589,
    '1169_882': 38.3733,
    '1169_883': 38.0707,
    '1169_884': 37.7701,
    '1169_885': 37.4625,
    '1169_886': 37.1504,
    '1169_887': 36.8294,
    '1169_888': 36.497,
    '1169_889': 36.1493,
    '1169_890': 35.776,
    '1169_891': 35.395,
    '1169_892': 35.0128,
    '1169_893': 34.6303,
    '1169_894': 34.213,
    '1169_895': 33.7842,
    '1169_896': 33.3559,
    '1169_897': 32.9303,
    '1169_898': 32.5135,
    '1169_899': 32.0909,
    '1169_900': 31.6512,
    '1170_786': 37.1212,
    '1170_787': 37.1412,
    '1170_788': 37.1589,
    '1170_789': 37.1694,
    '1170_790': 37.1838,
    '1170_791': 37.2201,
    '1170_792': 37.2658,
    '1170_793': 37.319,
    '1170_794': 37.382,
    '1170_795': 37.4539,
    '1170_796': 37.5333,
    '1170_797': 37.6219,
    '1170_798': 37.7271,
    '1170_799': 37.843,
    '1170_800': 37.9679,
    '1170_801': 38.1006,
    '1170_802': 38.2526,
    '1170_803': 38.4207,
    '1170_804': 38.5799,
    '1170_805': 38.7277,
    '1170_806': 38.8554,
    '1170_807': 38.9373,
    '1170_808': 39.0023,
    '1170_809': 39.0773,
    '1170_810': 39.1597,
    '1170_811': 39.2451,
    '1170_812': 39.3306,
    '1170_813': 39.4168,
    '1170_814': 39.5034,
    '1170_815': 39.5879,
    '1170_816': 39.6738,
    '1170_817': 39.7601,
    '1170_818': 39.8384,
    '1170_819': 39.9127,
    '1170_820': 39.9919,
    '1170_821': 40.0766,
    '1170_822': 40.1634,
    '1170_823': 40.2556,
    '1170_824': 40.3604,
    '1170_825': 40.4863,
    '1170_826': 40.6457,
    '1170_827': 40.8265,
    '1170_828': 40.9747,
    '1170_829': 41.0759,
    '1170_830': 41.1315,
    '1170_831': 41.1664,
    '1170_832': 41.2125,
    '1170_833': 41.2648,
    '1170_834': 41.3294,
    '1170_835': 41.3805,
    '1170_836': 41.4025,
    '1170_837': 41.397,
    '1170_838': 41.3916,
    '1170_839': 41.3898,
    '1170_840': 41.3859,
    '1170_841': 41.3772,
    '1170_842': 41.3698,
    '1170_843': 41.3652,
    '1170_844': 41.3714,
    '1170_845': 41.412,
    '1170_846': 41.476,
    '1170_847': 41.5543,
    '1170_848': 41.6428,
    '1170_849': 41.7282,
    '1170_850': 41.8159,
    '1170_851': 41.9128,
    '1170_852': 41.9961,
    '1170_853': 42.0587,
    '1170_854': 42.1315,
    '1170_855': 42.2033,
    '1170_856': 42.2617,
    '1170_857': 42.3043,
    '1170_858': 42.3077,
    '1170_859': 42.2735,
    '1170_860': 42.2116,
    '1170_861': 42.1476,
    '1170_862': 42.0895,
    '1170_863': 42.0232,
    '1170_864': 41.9403,
    '1170_865': 41.8389,
    '1170_866': 41.7182,
    '1170_867': 41.6054,
    '1170_868': 41.5036,
    '1170_869': 41.3843,
    '1170_870': 41.2371,
    '1170_871': 41.0635,
    '1170_872': 40.8681,
    '1170_873': 40.6482,
    '1170_874': 40.4076,
    '1170_875': 40.1571,
    '1170_876': 39.9038,
    '1170_877': 39.6624,
    '1170_878': 39.432,
    '1170_879': 39.201,
    '1170_880': 38.9467,
    '1170_881': 38.6525,
    '1170_882': 38.3521,
    '1170_883': 38.0528,
    '1170_884': 37.7495,
    '1170_885': 37.441,
    '1170_886': 37.1274,
    '1170_887': 36.8049,
    '1170_888': 36.4706,
    '1170_889': 36.121,
    '1170_890': 35.7497,
    '1170_891': 35.3723,
    '1170_892': 34.9829,
    '1170_893': 34.5925,
    '1170_894': 34.1816,
    '1170_895': 33.7577,
    '1170_896': 33.3289,
    '1170_897': 32.9014,
    '1170_898': 32.4856,
    '1170_899': 32.0652,
    '1170_900': 31.6319,
    '1171_786': 37.1096,
    '1171_787': 37.1276,
    '1171_788': 37.1449,
    '1171_789': 37.1575,
    '1171_790': 37.172,
    '1171_791': 37.2107,
    '1171_792': 37.2552,
    '1171_793': 37.3055,
    '1171_794': 37.3671,
    '1171_795': 37.4367,
    '1171_796': 37.5141,
    '1171_797': 37.6049,
    '1171_798': 37.7083,
    '1171_799': 37.822,
    '1171_800': 37.9442,
    '1171_801': 38.0737,
    '1171_802': 38.2228,
    '1171_803': 38.3909,
    '1171_804': 38.5474,
    '1171_805': 38.6957,
    '1171_806': 38.8307,
    '1171_807': 38.9134,
    '1171_808': 38.973,
    '1171_809': 39.0406,
    '1171_810': 39.12,
    '1171_811': 39.205,
    '1171_812': 39.2925,
    '1171_813': 39.3815,
    '1171_814': 39.4724,
    '1171_815': 39.5615,
    '1171_816': 39.6537,
    '1171_817': 39.7453,
    '1171_818': 39.8283,
    '1171_819': 39.9079,
    '1171_820': 39.9886,
    '1171_821': 40.0717,
    '1171_822': 40.155,
    '1171_823': 40.2443,
    '1171_824': 40.3458,
    '1171_825': 40.4676,
    '1171_826': 40.622,
    '1171_827': 40.8066,
    '1171_828': 40.9644,
    '1171_829': 41.0765,
    '1171_830': 41.1417,
    '1171_831': 41.1776,
    '1171_832': 41.2112,
    '1171_833': 41.2533,
    '1171_834': 41.3123,
    '1171_835': 41.3638,
    '1171_836': 41.3882,
    '1171_837': 41.385,
    '1171_838': 41.387,
    '1171_839': 41.3943,
    '1171_840': 41.3961,
    '1171_841': 41.3913,
    '1171_842': 41.3871,
    '1171_843': 41.3904,
    '1171_844': 41.39,
    '1171_845': 41.418,
    '1171_846': 41.4821,
    '1171_847': 41.5608,
    '1171_848': 41.6478,
    '1171_849': 41.7407,
    '1171_850': 41.8363,
    '1171_851': 41.9393,
    '1171_852': 42.0228,
    '1171_853': 42.0842,
    '1171_854': 42.1552,
    '1171_855': 42.2243,
    '1171_856': 42.2882,
    '1171_857': 42.3362,
    '1171_858': 42.3509,
    '1171_859': 42.3312,
    '1171_860': 42.2776,
    '1171_861': 42.2048,
    '1171_862': 42.1245,
    '1171_863': 42.0393,
    '1171_864': 41.9422,
    '1171_865': 41.8256,
    '1171_866': 41.6957,
    '1171_867': 41.5827,
    '1171_868': 41.4871,
    '1171_869': 41.3783,
    '1171_870': 41.2364,
    '1171_871': 41.063,
    '1171_872': 40.8607,
    '1171_873': 40.6348,
    '1171_874': 40.3903,
    '1171_875': 40.1373,
    '1171_876': 39.8848,
    '1171_877': 39.6439,
    '1171_878': 39.4257,
    '1171_879': 39.2093,
    '1171_880': 38.9512,
    '1171_881': 38.6452,
    '1171_882': 38.3338,
    '1171_883': 38.0294,
    '1171_884': 37.725,
    '1171_885': 37.4152,
    '1171_886': 37.1015,
    '1171_887': 36.7781,
    '1171_888': 36.4409,
    '1171_889': 36.0883,
    '1171_890': 35.7217,
    '1171_891': 35.3464,
    '1171_892': 34.9558,
    '1171_893': 34.5605,
    '1171_894': 34.1525,
    '1171_895': 33.7336,
    '1171_896': 33.3071,
    '1171_897': 32.8802,
    '1171_898': 32.4643,
    '1171_899': 32.0458,
    '1171_900': 31.6124,
    '1172_786': 37.0998,
    '1172_787': 37.1192,
    '1172_788': 37.1437,
    '1172_789': 37.1661,
    '1172_790': 37.1909,
    '1172_791': 37.2231,
    '1172_792': 37.2602,
    '1172_793': 37.3055,
    '1172_794': 37.3618,
    '1172_795': 37.4285,
    '1172_796': 37.5057,
    '1172_797': 37.5941,
    '1172_798': 37.6944,
    '1172_799': 37.805,
    '1172_800': 37.9242,
    '1172_801': 38.0506,
    '1172_802': 38.1962,
    '1172_803': 38.3622,
    '1172_804': 38.5161,
    '1172_805': 38.6626,
    '1172_806': 38.7968,
    '1172_807': 38.8869,
    '1172_808': 38.9526,
    '1172_809': 39.0105,
    '1172_810': 39.0883,
    '1172_811': 39.1741,
    '1172_812': 39.264,
    '1172_813': 39.3568,
    '1172_814': 39.4514,
    '1172_815': 39.5457,
    '1172_816': 39.6428,
    '1172_817': 39.7378,
    '1172_818': 39.8254,
    '1172_819': 39.9115,
    '1172_820': 39.9959,
    '1172_821': 40.0741,
    '1172_822': 40.1522,
    '1172_823': 40.2394,
    '1172_824': 40.3385,
    '1172_825': 40.4583,
    '1172_826': 40.609,
    '1172_827': 40.7913,
    '1172_828': 40.9549,
    '1172_829': 41.0778,
    '1172_830': 41.1528,
    '1172_831': 41.1912,
    '1172_832': 41.2153,
    '1172_833': 41.2438,
    '1172_834': 41.295,
    '1172_835': 41.3453,
    '1172_836': 41.3709,
    '1172_837': 41.3699,
    '1172_838': 41.3727,
    '1172_839': 41.3871,
    '1172_840': 41.3918,
    '1172_841': 41.3865,
    '1172_842': 41.3872,
    '1172_843': 41.3941,
    '1172_844': 41.3949,
    '1172_845': 41.4197,
    '1172_846': 41.4833,
    '1172_847': 41.5651,
    '1172_848': 41.6556,
    '1172_849': 41.7522,
    '1172_850': 41.8551,
    '1172_851': 41.9634,
    '1172_852': 42.0514,
    '1172_853': 42.1165,
    '1172_854': 42.182,
    '1172_855': 42.25,
    '1172_856': 42.3158,
    '1172_857': 42.3721,
    '1172_858': 42.3981,
    '1172_859': 42.3825,
    '1172_860': 42.3303,
    '1172_861': 42.2522,
    '1172_862': 42.1551,
    '1172_863': 42.0495,
    '1172_864': 41.9363,
    '1172_865': 41.8121,
    '1172_866': 41.6768,
    '1172_867': 41.5651,
    '1172_868': 41.4665,
    '1172_869': 41.359,
    '1172_870': 41.2188,
    '1172_871': 41.0437,
    '1172_872': 40.8325,
    '1172_873': 40.6041,
    '1172_874': 40.3624,
    '1172_875': 40.1111,
    '1172_876': 39.8564,
    '1172_877': 39.6182,
    '1172_878': 39.4086,
    '1172_879': 39.1996,
    '1172_880': 38.9372,
    '1172_881': 38.632,
    '1172_882': 38.322,
    '1172_883': 38.0084,
    '1172_884': 37.6993,
    '1172_885': 37.3882,
    '1172_886': 37.0757,
    '1172_887': 36.7519,
    '1172_888': 36.4135,
    '1172_889': 36.0598,
    '1172_890': 35.6915,
    '1172_891': 35.3132,
    '1172_892': 34.9288,
    '1172_893': 34.5336,
    '1172_894': 34.1295,
    '1172_895': 33.7144,
    '1172_896': 33.29,
    '1172_897': 32.8646,
    '1172_898': 32.4459,
    '1172_899': 32.0263,
    '1172_900': 31.5956,
    '1173_786': 37.0916,
    '1173_787': 37.1119,
    '1173_788': 37.1466,
    '1173_789': 37.1751,
    '1173_790': 37.2013,
    '1173_791': 37.2307,
    '1173_792': 37.265,
    '1173_793': 37.3068,
    '1173_794': 37.3587,
    '1173_795': 37.4217,
    '1173_796': 37.4967,
    '1173_797': 37.5836,
    '1173_798': 37.6818,
    '1173_799': 37.79,
    '1173_800': 37.9066,
    '1173_801': 38.0299,
    '1173_802': 38.1712,
    '1173_803': 38.3316,
    '1173_804': 38.482,
    '1173_805': 38.6246,
    '1173_806': 38.7561,
    '1173_807': 38.8555,
    '1173_808': 38.9274,
    '1173_809': 38.9847,
    '1173_810': 39.0639,
    '1173_811': 39.1519,
    '1173_812': 39.2439,
    '1173_813': 39.3415,
    '1173_814': 39.4424,
    '1173_815': 39.5444,
    '1173_816': 39.6447,
    '1173_817': 39.7408,
    '1173_818': 39.8302,
    '1173_819': 39.9189,
    '1173_820': 40.0045,
    '1173_821': 40.0829,
    '1173_822': 40.1543,
    '1173_823': 40.2379,
    '1173_824': 40.3368,
    '1173_825': 40.4622,
    '1173_826': 40.6206,
    '1173_827': 40.7982,
    '1173_828': 40.9554,
    '1173_829': 41.0804,
    '1173_830': 41.1661,
    '1173_831': 41.2109,
    '1173_832': 41.2289,
    '1173_833': 41.2424,
    '1173_834': 41.2754,
    '1173_835': 41.3156,
    '1173_836': 41.3362,
    '1173_837': 41.3418,
    '1173_838': 41.3507,
    '1173_839': 41.3663,
    '1173_840': 41.3751,
    '1173_841': 41.3763,
    '1173_842': 41.3815,
    '1173_843': 41.383,
    '1173_844': 41.3834,
    '1173_845': 41.4139,
    '1173_846': 41.4779,
    '1173_847': 41.563,
    '1173_848': 41.6577,
    '1173_849': 41.7588,
    '1173_850': 41.8656,
    '1173_851': 41.9805,
    '1173_852': 42.0747,
    '1173_853': 42.1467,
    '1173_854': 42.2167,
    '1173_855': 42.2856,
    '1173_856': 42.3515,
    '1173_857': 42.4015,
    '1173_858': 42.4239,
    '1173_859': 42.4071,
    '1173_860': 42.3569,
    '1173_861': 42.2837,
    '1173_862': 42.1776,
    '1173_863': 42.0611,
    '1173_864': 41.9407,
    '1173_865': 41.8061,
    '1173_866': 41.6663,
    '1173_867': 41.5456,
    '1173_868': 41.4414,
    '1173_869': 41.3284,
    '1173_870': 41.1848,
    '1173_871': 41.0061,
    '1173_872': 40.7927,
    '1173_873': 40.5603,
    '1173_874': 40.3208,
    '1173_875': 40.0798,
    '1173_876': 39.8337,
    '1173_877': 39.5883,
    '1173_878': 39.3749,
    '1173_879': 39.1656,
    '1173_880': 38.9031,
    '1173_881': 38.6044,
    '1173_882': 38.3051,
    '1173_883': 37.9839,
    '1173_884': 37.6722,
    '1173_885': 37.3629,
    '1173_886': 37.0496,
    '1173_887': 36.7264,
    '1173_888': 36.3867,
    '1173_889': 36.0323,
    '1173_890': 35.6594,
    '1173_891': 35.2753,
    '1173_892': 34.8945,
    '1173_893': 34.5024,
    '1173_894': 34.103,
    '1173_895': 33.6935,
    '1173_896': 33.2687,
    '1173_897': 32.8419,
    '1173_898': 32.4232,
    '1173_899': 32.0054,
    '1173_900': 31.5776,
    '1174_786': 37.0863,
    '1174_787': 37.1124,
    '1174_788': 37.1459,
    '1174_789': 37.1773,
    '1174_790': 37.2061,
    '1174_791': 37.2357,
    '1174_792': 37.2686,
    '1174_793': 37.3077,
    '1174_794': 37.3554,
    '1174_795': 37.4142,
    '1174_796': 37.4862,
    '1174_797': 37.5729,
    '1174_798': 37.6701,
    '1174_799': 37.7766,
    '1174_800': 37.8909,
    '1174_801': 38.0114,
    '1174_802': 38.1484,
    '1174_803': 38.302,
    '1174_804': 38.4487,
    '1174_805': 38.5888,
    '1174_806': 38.7171,
    '1174_807': 38.82,
    '1174_808': 38.897,
    '1174_809': 38.9628,
    '1174_810': 39.046,
    '1174_811': 39.1364,
    '1174_812': 39.2358,
    '1174_813': 39.3407,
    '1174_814': 39.4546,
    '1174_815': 39.5626,
    '1174_816': 39.6591,
    '1174_817': 39.7529,
    '1174_818': 39.845,
    '1174_819': 39.9337,
    '1174_820': 40.017,
    '1174_821': 40.09,
    '1174_822': 40.1608,
    '1174_823': 40.2408,
    '1174_824': 40.3372,
    '1174_825': 40.4661,
    '1174_826': 40.6296,
    '1174_827': 40.8041,
    '1174_828': 40.9551,
    '1174_829': 41.0789,
    '1174_830': 41.1676,
    '1174_831': 41.2261,
    '1174_832': 41.2455,
    '1174_833': 41.2462,
    '1174_834': 41.2581,
    '1174_835': 41.2806,
    '1174_836': 41.2948,
    '1174_837': 41.299,
    '1174_838': 41.3172,
    '1174_839': 41.3428,
    '1174_840': 41.3584,
    '1174_841': 41.3688,
    '1174_842': 41.3781,
    '1174_843': 41.3685,
    '1174_844': 41.3663,
    '1174_845': 41.4012,
    '1174_846': 41.4671,
    '1174_847': 41.5533,
    '1174_848': 41.6526,
    '1174_849': 41.7602,
    '1174_850': 41.8739,
    '1174_851': 41.9935,
    '1174_852': 42.0938,
    '1174_853': 42.1743,
    '1174_854': 42.2501,
    '1174_855': 42.319,
    '1174_856': 42.3792,
    '1174_857': 42.4178,
    '1174_858': 42.4343,
    '1174_859': 42.413,
    '1174_860': 42.3644,
    '1174_861': 42.2852,
    '1174_862': 42.1814,
    '1174_863': 42.0626,
    '1174_864': 41.9355,
    '1174_865': 41.7961,
    '1174_866': 41.6532,
    '1174_867': 41.5255,
    '1174_868': 41.4158,
    '1174_869': 41.2883,
    '1174_870': 41.1347,
    '1174_871': 40.9578,
    '1174_872': 40.7482,
    '1174_873': 40.5173,
    '1174_874': 40.2815,
    '1174_875': 40.0458,
    '1174_876': 39.7968,
    '1174_877': 39.5515,
    '1174_878': 39.3292,
    '1174_879': 39.1158,
    '1174_880': 38.857,
    '1174_881': 38.5605,
    '1174_882': 38.2621,
    '1174_883': 37.9485,
    '1174_884': 37.6406,
    '1174_885': 37.3316,
    '1174_886': 37.0207,
    '1174_887': 36.6989,
    '1174_888': 36.3577,
    '1174_889': 36.0021,
    '1174_890': 35.6298,
    '1174_891': 35.2474,
    '1174_892': 34.8617,
    '1174_893': 34.4666,
    '1174_894': 34.0667,
    '1174_895': 33.663,
    '1174_896': 33.2394,
    '1174_897': 32.8122,
    '1174_898': 32.3937,
    '1174_899': 31.9774,
    '1174_900': 31.5576,
    '1175_786': 37.0728,
    '1175_787': 37.1114,
    '1175_788': 37.1486,
    '1175_789': 37.1824,
    '1175_790': 37.2129,
    '1175_791': 37.2425,
    '1175_792': 37.2739,
    '1175_793': 37.31,
    '1175_794': 37.3539,
    '1175_795': 37.4091,
    '1175_796': 37.4806,
    '1175_797': 37.5659,
    '1175_798': 37.6614,
    '1175_799': 37.7659,
    '1175_800': 37.8777,
    '1175_801': 37.9951,
    '1175_802': 38.1276,
    '1175_803': 38.2754,
    '1175_804': 38.4159,
    '1175_805': 38.5517,
    '1175_806': 38.6799,
    '1175_807': 38.7838,
    '1175_808': 38.8669,
    '1175_809': 38.9471,
    '1175_810': 39.0339,
    '1175_811': 39.1284,
    '1175_812': 39.2365,
    '1175_813': 39.3551,
    '1175_814': 39.4788,
    '1175_815': 39.59,
    '1175_816': 39.6845,
    '1175_817': 39.7763,
    '1175_818': 39.8725,
    '1175_819': 39.9583,
    '1175_820': 40.034,
    '1175_821': 40.1009,
    '1175_822': 40.1717,
    '1175_823': 40.2489,
    '1175_824': 40.3406,
    '1175_825': 40.4644,
    '1175_826': 40.6252,
    '1175_827': 40.7957,
    '1175_828': 40.9391,
    '1175_829': 41.0636,
    '1175_830': 41.1657,
    '1175_831': 41.2263,
    '1175_832': 41.2516,
    '1175_833': 41.2468,
    '1175_834': 41.2396,
    '1175_835': 41.2455,
    '1175_836': 41.2517,
    '1175_837': 41.2501,
    '1175_838': 41.2709,
    '1175_839': 41.3045,
    '1175_840': 41.3298,
    '1175_841': 41.3484,
    '1175_842': 41.3656,
    '1175_843': 41.3625,
    '1175_844': 41.3533,
    '1175_845': 41.3855,
    '1175_846': 41.4526,
    '1175_847': 41.542,
    '1175_848': 41.6471,
    '1175_849': 41.7593,
    '1175_850': 41.8813,
    '1175_851': 41.9983,
    '1175_852': 42.098,
    '1175_853': 42.1818,
    '1175_854': 42.2638,
    '1175_855': 42.3378,
    '1175_856': 42.3937,
    '1175_857': 42.4217,
    '1175_858': 42.4227,
    '1175_859': 42.4005,
    '1175_860': 42.3488,
    '1175_861': 42.2709,
    '1175_862': 42.1701,
    '1175_863': 42.0518,
    '1175_864': 41.9183,
    '1175_865': 41.7824,
    '1175_866': 41.6405,
    '1175_867': 41.5071,
    '1175_868': 41.3871,
    '1175_869': 41.2415,
    '1175_870': 41.0754,
    '1175_871': 40.9011,
    '1175_872': 40.697,
    '1175_873': 40.4778,
    '1175_874': 40.2402,
    '1175_875': 39.9977,
    '1175_876': 39.7545,
    '1175_877': 39.5162,
    '1175_878': 39.2794,
    '1175_879': 39.0559,
    '1175_880': 38.8051,
    '1175_881': 38.5138,
    '1175_882': 38.2155,
    '1175_883': 37.9144,
    '1175_884': 37.6119,
    '1175_885': 37.3044,
    '1175_886': 36.9925,
    '1175_887': 36.6698,
    '1175_888': 36.3288,
    '1175_889': 35.9713,
    '1175_890': 35.5997,
    '1175_891': 35.2172,
    '1175_892': 34.8283,
    '1175_893': 34.4331,
    '1175_894': 34.0326,
    '1175_895': 33.6262,
    '1175_896': 33.2054,
    '1175_897': 32.7799,
    '1175_898': 32.363,
    '1175_899': 31.9491,
    '1175_900': 31.5346,
    '1176_786': 37.0764,
    '1176_787': 37.1197,
    '1176_788': 37.1589,
    '1176_789': 37.1933,
    '1176_790': 37.2236,
    '1176_791': 37.252,
    '1176_792': 37.281,
    '1176_793': 37.3137,
    '1176_794': 37.354,
    '1176_795': 37.4063,
    '1176_796': 37.4767,
    '1176_797': 37.5604,
    '1176_798': 37.6541,
    '1176_799': 37.756,
    '1176_800': 37.8652,
    '1176_801': 37.9792,
    '1176_802': 38.1066,
    '1176_803': 38.2467,
    '1176_804': 38.3846,
    '1176_805': 38.5163,
    '1176_806': 38.6423,
    '1176_807': 38.7536,
    '1176_808': 38.847,
    '1176_809': 38.932,
    '1176_810': 39.0254,
    '1176_811': 39.1257,
    '1176_812': 39.236,
    '1176_813': 39.362,
    '1176_814': 39.4865,
    '1176_815': 39.6088,
    '1176_816': 39.7096,
    '1176_817': 39.8027,
    '1176_818': 39.8996,
    '1176_819': 39.9851,
    '1176_820': 40.0526,
    '1176_821': 40.1174,
    '1176_822': 40.1844,
    '1176_823': 40.2543,
    '1176_824': 40.34,
    '1176_825': 40.4549,
    '1176_826': 40.6025,
    '1176_827': 40.7648,
    '1176_828': 40.9066,
    '1176_829': 41.0272,
    '1176_830': 41.1374,
    '1176_831': 41.2077,
    '1176_832': 41.2388,
    '1176_833': 41.2354,
    '1176_834': 41.2171,
    '1176_835': 41.2086,
    '1176_836': 41.2102,
    '1176_837': 41.2025,
    '1176_838': 41.2148,
    '1176_839': 41.2475,
    '1176_840': 41.2799,
    '1176_841': 41.3096,
    '1176_842': 41.3357,
    '1176_843': 41.3421,
    '1176_844': 41.3378,
    '1176_845': 41.3659,
    '1176_846': 41.4333,
    '1176_847': 41.532,
    '1176_848': 41.6406,
    '1176_849': 41.7542,
    '1176_850': 41.8761,
    '1176_851': 41.992,
    '1176_852': 42.0905,
    '1176_853': 42.1779,
    '1176_854': 42.2654,
    '1176_855': 42.3414,
    '1176_856': 42.3871,
    '1176_857': 42.4058,
    '1176_858': 42.3951,
    '1176_859': 42.3586,
    '1176_860': 42.3047,
    '1176_861': 42.2347,
    '1176_862': 42.1425,
    '1176_863': 42.0267,
    '1176_864': 41.8949,
    '1176_865': 41.7658,
    '1176_866': 41.6316,
    '1176_867': 41.5052,
    '1176_868': 41.369,
    '1176_869': 41.2139,
    '1176_870': 41.0369,
    '1176_871': 40.845,
    '1176_872': 40.6477,
    '1176_873': 40.4396,
    '1176_874': 40.204,
    '1176_875': 39.9562,
    '1176_876': 39.7124,
    '1176_877': 39.4787,
    '1176_878': 39.237,
    '1176_879': 39.0002,
    '1176_880': 38.7555,
    '1176_881': 38.4707,
    '1176_882': 38.1779,
    '1176_883': 37.8831,
    '1176_884': 37.5831,
    '1176_885': 37.277,
    '1176_886': 36.9619,
    '1176_887': 36.6358,
    '1176_888': 36.2919,
    '1176_889': 35.9341,
    '1176_890': 35.5641,
    '1176_891': 35.1807,
    '1176_892': 34.792,
    '1176_893': 34.396,
    '1176_894': 33.9952,
    '1176_895': 33.5887,
    '1176_896': 33.171,
    '1176_897': 32.7455,
    '1176_898': 32.3328,
    '1176_899': 31.9226,
    '1176_900': 31.51,
    '1177_786': 37.0986,
    '1177_787': 37.1408,
    '1177_788': 37.1787,
    '1177_789': 37.2116,
    '1177_790': 37.24,
    '1177_791': 37.2658,
    '1177_792': 37.2915,
    '1177_793': 37.3204,
    '1177_794': 37.357,
    '1177_795': 37.4067,
    '1177_796': 37.4756,
    '1177_797': 37.5574,
    '1177_798': 37.6484,
    '1177_799': 37.7475,
    '1177_800': 37.854,
    '1177_801': 37.9652,
    '1177_802': 38.088,
    '1177_803': 38.2217,
    '1177_804': 38.357,
    '1177_805': 38.4856,
    '1177_806': 38.6094,
    '1177_807': 38.7272,
    '1177_808': 38.8277,
    '1177_809': 38.9223,
    '1177_810': 39.0215,
    '1177_811': 39.1268,
    '1177_812': 39.2415,
    '1177_813': 39.3662,
    '1177_814': 39.4995,
    '1177_815': 39.6266,
    '1177_816': 39.7345,
    '1177_817': 39.8344,
    '1177_818': 39.9288,
    '1177_819': 40.0113,
    '1177_820': 40.0786,
    '1177_821': 40.1372,
    '1177_822': 40.1891,
    '1177_823': 40.25,
    '1177_824': 40.3309,
    '1177_825': 40.4408,
    '1177_826': 40.5766,
    '1177_827': 40.7196,
    '1177_828': 40.851,
    '1177_829': 40.9674,
    '1177_830': 41.0825,
    '1177_831': 41.1671,
    '1177_832': 41.2125,
    '1177_833': 41.2167,
    '1177_834': 41.1959,
    '1177_835': 41.1786,
    '1177_836': 41.169,
    '1177_837': 41.1575,
    '1177_838': 41.1521,
    '1177_839': 41.1794,
    '1177_840': 41.216,
    '1177_841': 41.2586,
    '1177_842': 41.2952,
    '1177_843': 41.3108,
    '1177_844': 41.315,
    '1177_845': 41.35,
    '1177_846': 41.4228,
    '1177_847': 41.527,
    '1177_848': 41.6366,
    '1177_849': 41.7486,
    '1177_850': 41.8584,
    '1177_851': 41.97,
    '1177_852': 42.0716,
    '1177_853': 42.1703,
    '1177_854': 42.2621,
    '1177_855': 42.3318,
    '1177_856': 42.3697,
    '1177_857': 42.3792,
    '1177_858': 42.3637,
    '1177_859': 42.3261,
    '1177_860': 42.271,
    '1177_861': 42.2021,
    '1177_862': 42.1161,
    '1177_863': 42.0054,
    '1177_864': 41.8786,
    '1177_865': 41.754,
    '1177_866': 41.6402,
    '1177_867': 41.5238,
    '1177_868': 41.3814,
    '1177_869': 41.2165,
    '1177_870': 41.0261,
    '1177_871': 40.8178,
    '1177_872': 40.6064,
    '1177_873': 40.3961,
    '1177_874': 40.1643,
    '1177_875': 39.9235,
    '1177_876': 39.6804,
    '1177_877': 39.4443,
    '1177_878': 39.2016,
    '1177_879': 38.9547,
    '1177_880': 38.7101,
    '1177_881': 38.4302,
    '1177_882': 38.1422,
    '1177_883': 37.8514,
    '1177_884': 37.5532,
    '1177_885': 37.2462,
    '1177_886': 36.9274,
    '1177_887': 36.5996,
    '1177_888': 36.251,
    '1177_889': 35.893,
    '1177_890': 35.5194,
    '1177_891': 35.1352,
    '1177_892': 34.7486,
    '1177_893': 34.3532,
    '1177_894': 33.9524,
    '1177_895': 33.5488,
    '1177_896': 33.1384,
    '1177_897': 32.7148,
    '1177_898': 32.3052,
    '1177_899': 31.8979,
    '1177_900': 31.4825,
    '1178_786': 37.1306,
    '1178_787': 37.171,
    '1178_788': 37.2065,
    '1178_789': 37.2368,
    '1178_790': 37.2621,
    '1178_791': 37.2845,
    '1178_792': 37.3061,
    '1178_793': 37.3307,
    '1178_794': 37.3639,
    '1178_795': 37.4124,
    '1178_796': 37.479,
    '1178_797': 37.5577,
    '1178_798': 37.6453,
    '1178_799': 37.7409,
    '1178_800': 37.8445,
    '1178_801': 37.9553,
    '1178_802': 38.0726,
    '1178_803': 38.2027,
    '1178_804': 38.3347,
    '1178_805': 38.4597,
    '1178_806': 38.5841,
    '1178_807': 38.7044,
    '1178_808': 38.8108,
    '1178_809': 38.9128,
    '1178_810': 39.0193,
    '1178_811': 39.1309,
    '1178_812': 39.2485,
    '1178_813': 39.375,
    '1178_814': 39.5141,
    '1178_815': 39.6425,
    '1178_816': 39.7641,
    '1178_817': 39.8766,
    '1178_818': 39.9721,
    '1178_819': 40.0555,
    '1178_820': 40.1176,
    '1178_821': 40.1585,
    '1178_822': 40.1966,
    '1178_823': 40.2538,
    '1178_824': 40.3325,
    '1178_825': 40.435,
    '1178_826': 40.5526,
    '1178_827': 40.674,
    '1178_828': 40.7889,
    '1178_829': 40.8942,
    '1178_830': 41.0121,
    '1178_831': 41.1154,
    '1178_832': 41.1761,
    '1178_833': 41.1937,
    '1178_834': 41.1736,
    '1178_835': 41.1507,
    '1178_836': 41.1285,
    '1178_837': 41.1014,
    '1178_838': 41.0822,
    '1178_839': 41.1017,
    '1178_840': 41.1441,
    '1178_841': 41.1997,
    '1178_842': 41.2531,
    '1178_843': 41.2768,
    '1178_844': 41.2867,
    '1178_845': 41.334,
    '1178_846': 41.4162,
    '1178_847': 41.5168,
    '1178_848': 41.6276,
    '1178_849': 41.7397,
    '1178_850': 41.8427,
    '1178_851': 41.9483,
    '1178_852': 42.0499,
    '1178_853': 42.1557,
    '1178_854': 42.2469,
    '1178_855': 42.3134,
    '1178_856': 42.3445,
    '1178_857': 42.3526,
    '1178_858': 42.3424,
    '1178_859': 42.3127,
    '1178_860': 42.2612,
    '1178_861': 42.1861,
    '1178_862': 42.0967,
    '1178_863': 41.9898,
    '1178_864': 41.8713,
    '1178_865': 41.7644,
    '1178_866': 41.6591,
    '1178_867': 41.5411,
    '1178_868': 41.3914,
    '1178_869': 41.2118,
    '1178_870': 41.0123,
    '1178_871': 40.8035,
    '1178_872': 40.5995,
    '1178_873': 40.3785,
    '1178_874': 40.1462,
    '1178_875': 39.9072,
    '1178_876': 39.6634,
    '1178_877': 39.4209,
    '1178_878': 39.1737,
    '1178_879': 38.9187,
    '1178_880': 38.665,
    '1178_881': 38.3931,
    '1178_882': 38.1094,
    '1178_883': 37.8205,
    '1178_884': 37.5224,
    '1178_885': 37.2117,
    '1178_886': 36.8903,
    '1178_887': 36.5651,
    '1178_888': 36.2089,
    '1178_889': 35.8529,
    '1178_890': 35.4751,
    '1178_891': 35.0866,
    '1178_892': 34.704,
    '1178_893': 34.3069,
    '1178_894': 33.9041,
    '1178_895': 33.4984,
    '1178_896': 33.0856,
    '1178_897': 32.681,
    '1178_898': 32.2745,
    '1178_899': 31.8775,
    '1178_900': 31.4586,
    '1179_786': 37.1629,
    '1179_787': 37.2022,
    '1179_788': 37.2358,
    '1179_789': 37.2639,
    '1179_790': 37.2859,
    '1179_791': 37.3048,
    '1179_792': 37.322,
    '1179_793': 37.3419,
    '1179_794': 37.3711,
    '1179_795': 37.4196,
    '1179_796': 37.4836,
    '1179_797': 37.5589,
    '1179_798': 37.6418,
    '1179_799': 37.7332,
    '1179_800': 37.8338,
    '1179_801': 37.9423,
    '1179_802': 38.0564,
    '1179_803': 38.1824,
    '1179_804': 38.3118,
    '1179_805': 38.437,
    '1179_806': 38.5637,
    '1179_807': 38.6864,
    '1179_808': 38.7992,
    '1179_809': 38.906,
    '1179_810': 39.0169,
    '1179_811': 39.135,
    '1179_812': 39.2594,
    '1179_813': 39.3886,
    '1179_814': 39.5267,
    '1179_815': 39.6665,
    '1179_816': 39.8006,
    '1179_817': 39.9187,
    '1179_818': 40.0152,
    '1179_819': 40.1042,
    '1179_820': 40.1661,
    '1179_821': 40.1882,
    '1179_822': 40.2186,
    '1179_823': 40.2704,
    '1179_824': 40.3462,
    '1179_825': 40.4402,
    '1179_826': 40.5413,
    '1179_827': 40.6413,
    '1179_828': 40.7413,
    '1179_829': 40.833,
    '1179_830': 40.9441,
    '1179_831': 41.06,
    '1179_832': 41.1338,
    '1179_833': 41.1613,
    '1179_834': 41.1461,
    '1179_835': 41.115,
    '1179_836': 41.0835,
    '1179_837': 41.0463,
    '1179_838': 41.0208,
    '1179_839': 41.0337,
    '1179_840': 41.0793,
    '1179_841': 41.145,
    '1179_842': 41.207,
    '1179_843': 41.2358,
    '1179_844': 41.2569,
    '1179_845': 41.3165,
    '1179_846': 41.4007,
    '1179_847': 41.4945,
    '1179_848': 41.6026,
    '1179_849': 41.7119,
    '1179_850': 41.8171,
    '1179_851': 41.9211,
    '1179_852': 42.0263,
    '1179_853': 42.1313,
    '1179_854': 42.2217,
    '1179_855': 42.2873,
    '1179_856': 42.3239,
    '1179_857': 42.3382,
    '1179_858': 42.3354,
    '1179_859': 42.3117,
    '1179_860': 42.2624,
    '1179_861': 42.187,
    '1179_862': 42.0941,
    '1179_863': 41.9892,
    '1179_864': 41.8799,
    '1179_865': 41.7847,
    '1179_866': 41.6788,
    '1179_867': 41.5531,
    '1179_868': 41.3922,
    '1179_869': 41.1981,
    '1179_870': 40.9995,
    '1179_871': 40.8126,
    '1179_872': 40.6135,
    '1179_873': 40.392,
    '1179_874': 40.1632,
    '1179_875': 39.9158,
    '1179_876': 39.6638,
    '1179_877': 39.4142,
    '1179_878': 39.1612,
    '1179_879': 38.8956,
    '1179_880': 38.6287,
    '1179_881': 38.3578,
    '1179_882': 38.0774,
    '1179_883': 37.7899,
    '1179_884': 37.4922,
    '1179_885': 37.1831,
    '1179_886': 36.8618,
    '1179_887': 36.5304,
    '1179_888': 36.1762,
    '1179_889': 35.816,
    '1179_890': 35.4356,
    '1179_891': 35.0486,
    '1179_892': 34.66,
    '1179_893': 34.2599,
    '1179_894': 33.859,
    '1179_895': 33.4563,
    '1179_896': 33.0455,
    '1179_897': 32.6451,
    '1179_898': 32.2361,
    '1179_899': 31.8299,
    '1179_900': 31.4245,
    '1180_786': 37.1905,
    '1180_787': 37.23,
    '1180_788': 37.2623,
    '1180_789': 37.2898,
    '1180_790': 37.3087,
    '1180_791': 37.3252,
    '1180_792': 37.3393,
    '1180_793': 37.3559,
    '1180_794': 37.3829,
    '1180_795': 37.4286,
    '1180_796': 37.4891,
    '1180_797': 37.5609,
    '1180_798': 37.6385,
    '1180_799': 37.7254,
    '1180_800': 37.8235,
    '1180_801': 37.9303,
    '1180_802': 38.0429,
    '1180_803': 38.1675,
    '1180_804': 38.2939,
    '1180_805': 38.4222,
    '1180_806': 38.5504,
    '1180_807': 38.6761,
    '1180_808': 38.7941,
    '1180_809': 38.9071,
    '1180_810': 39.0228,
    '1180_811': 39.1464,
    '1180_812': 39.2765,
    '1180_813': 39.4124,
    '1180_814': 39.5543,
    '1180_815': 39.7017,
    '1180_816': 39.8402,
    '1180_817': 39.9598,
    '1180_818': 40.0635,
    '1180_819': 40.1531,
    '1180_820': 40.2158,
    '1180_821': 40.2389,
    '1180_822': 40.2538,
    '1180_823': 40.2962,
    '1180_824': 40.3639,
    '1180_825': 40.4473,
    '1180_826': 40.5365,
    '1180_827': 40.6187,
    '1180_828': 40.7025,
    '1180_829': 40.7921,
    '1180_830': 40.9006,
    '1180_831': 41.0168,
    '1180_832': 41.095,
    '1180_833': 41.1306,
    '1180_834': 41.1151,
    '1180_835': 41.0773,
    '1180_836': 41.0407,
    '1180_837': 41.0013,
    '1180_838': 40.9762,
    '1180_839': 40.9875,
    '1180_840': 41.0319,
    '1180_841': 41.0939,
    '1180_842': 41.156,
    '1180_843': 41.189,
    '1180_844': 41.2263,
    '1180_845': 41.2933,
    '1180_846': 41.374,
    '1180_847': 41.4689,
    '1180_848': 41.5728,
    '1180_849': 41.6773,
    '1180_850': 41.7819,
    '1180_851': 41.8895,
    '1180_852': 41.9982,
    '1180_853': 42.1018,
    '1180_854': 42.1937,
    '1180_855': 42.2616,
    '1180_856': 42.3102,
    '1180_857': 42.3338,
    '1180_858': 42.3361,
    '1180_859': 42.3155,
    '1180_860': 42.2706,
    '1180_861': 42.1999,
    '1180_862': 42.1097,
    '1180_863': 42.0093,
    '1180_864': 41.9072,
    '1180_865': 41.8105,
    '1180_866': 41.6954,
    '1180_867': 41.5568,
    '1180_868': 41.3836,
    '1180_869': 41.1907,
    '1180_870': 41.0137,
    '1180_871': 40.8388,
    '1180_872': 40.6409,
    '1180_873': 40.4141,
    '1180_874': 40.1821,
    '1180_875': 39.9316,
    '1180_876': 39.6734,
    '1180_877': 39.4171,
    '1180_878': 39.1533,
    '1180_879': 38.8774,
    '1180_880': 38.6009,
    '1180_881': 38.3264,
    '1180_882': 38.0472,
    '1180_883': 37.7599,
    '1180_884': 37.4617,
    '1180_885': 37.1461,
    '1180_886': 36.8278,
    '1180_887': 36.4913,
    '1180_888': 36.1357,
    '1180_889': 35.7744,
    '1180_890': 35.3935,
    '1180_891': 35.0087,
    '1180_892': 34.6176,
    '1180_893': 34.2166,
    '1180_894': 33.8133,
    '1180_895': 33.4107,
    '1180_896': 33.0068,
    '1180_897': 32.6114,
    '1180_898': 32.2174,
    '1180_899': 31.8001,
    '1180_900': 31.3903,
    '1181_786': 37.2055,
    '1181_787': 37.2472,
    '1181_788': 37.2801,
    '1181_789': 37.306,
    '1181_790': 37.3249,
    '1181_791': 37.3403,
    '1181_792': 37.3541,
    '1181_793': 37.3701,
    '1181_794': 37.3949,
    '1181_795': 37.438,
    '1181_796': 37.4941,
    '1181_797': 37.5604,
    '1181_798': 37.6349,
    '1181_799': 37.7192,
    '1181_800': 37.8153,
    '1181_801': 37.921,
    '1181_802': 38.0331,
    '1181_803': 38.1569,
    '1181_804': 38.2849,
    '1181_805': 38.415,
    '1181_806': 38.5458,
    '1181_807': 38.6747,
    '1181_808': 38.7982,
    '1181_809': 38.9189,
    '1181_810': 39.0417,
    '1181_811': 39.1694,
    '1181_812': 39.304,
    '1181_813': 39.4434,
    '1181_814': 39.5919,
    '1181_815': 39.7443,
    '1181_816': 39.8796,
    '1181_817': 40.0024,
    '1181_818': 40.1085,
    '1181_819': 40.1981,
    '1181_820': 40.2662,
    '1181_821': 40.2944,
    '1181_822': 40.3012,
    '1181_823': 40.3329,
    '1181_824': 40.39,
    '1181_825': 40.4605,
    '1181_826': 40.5348,
    '1181_827': 40.6052,
    '1181_828': 40.6799,
    '1181_829': 40.7647,
    '1181_830': 40.877,
    '1181_831': 40.9903,
    '1181_832': 41.07,
    '1181_833': 41.1065,
    '1181_834': 41.0883,
    '1181_835': 41.0432,
    '1181_836': 41.0044,
    '1181_837': 40.9708,
    '1181_838': 40.9493,
    '1181_839': 40.9601,
    '1181_840': 41.0018,
    '1181_841': 41.0564,
    '1181_842': 41.1109,
    '1181_843': 41.1461,
    '1181_844': 41.1966,
    '1181_845': 41.2656,
    '1181_846': 41.3453,
    '1181_847': 41.4399,
    '1181_848': 41.5466,
    '1181_849': 41.65,
    '1181_850': 41.7555,
    '1181_851': 41.8669,
    '1181_852': 41.9737,
    '1181_853': 42.0761,
    '1181_854': 42.1668,
    '1181_855': 42.243,
    '1181_856': 42.2976,
    '1181_857': 42.3271,
    '1181_858': 42.3342,
    '1181_859': 42.3187,
    '1181_860': 42.2814,
    '1181_861': 42.2167,
    '1181_862': 42.1315,
    '1181_863': 42.0353,
    '1181_864': 41.9381,
    '1181_865': 41.8298,
    '1181_866': 41.7028,
    '1181_867': 41.5503,
    '1181_868': 41.3718,
    '1181_869': 41.1945,
    '1181_870': 41.0374,
    '1181_871': 40.8697,
    '1181_872': 40.6701,
    '1181_873': 40.4369,
    '1181_874': 40.1895,
    '1181_875': 39.9344,
    '1181_876': 39.6745,
    '1181_877': 39.4109,
    '1181_878': 39.1358,
    '1181_879': 38.856,
    '1181_880': 38.5757,
    '1181_881': 38.2991,
    '1181_882': 38.0178,
    '1181_883': 37.7293,
    '1181_884': 37.431,
    '1181_885': 37.121,
    '1181_886': 36.7963,
    '1181_887': 36.4546,
    '1181_888': 36.0986,
    '1181_889': 35.7351,
    '1181_890': 35.356,
    '1181_891': 34.9696,
    '1181_892': 34.5769,
    '1181_893': 34.1757,
    '1181_894': 33.772,
    '1181_895': 33.364,
    '1181_896': 32.9608,
    '1181_897': 32.5668,
    '1181_898': 32.1861,
    '1181_899': 31.7813,
    '1181_900': 31.3584,
    '1182_786': 37.2142,
    '1182_787': 37.2584,
    '1182_788': 37.2923,
    '1182_789': 37.3183,
    '1182_790': 37.338,
    '1182_791': 37.3542,
    '1182_792': 37.369,
    '1182_793': 37.3862,
    '1182_794': 37.4106,
    '1182_795': 37.4492,
    '1182_796': 37.4994,
    '1182_797': 37.5602,
    '1182_798': 37.631,
    '1182_799': 37.7127,
    '1182_800': 37.8079,
    '1182_801': 37.913,
    '1182_802': 38.0255,
    '1182_803': 38.15,
    '1182_804': 38.2802,
    '1182_805': 38.4131,
    '1182_806': 38.5478,
    '1182_807': 38.6806,
    '1182_808': 38.8096,
    '1182_809': 38.9374,
    '1182_810': 39.0684,
    '1182_811': 39.2014,
    '1182_812': 39.3403,
    '1182_813': 39.4832,
    '1182_814': 39.6343,
    '1182_815': 39.7864,
    '1182_816': 39.9247,
    '1182_817': 40.0473,
    '1182_818': 40.1502,
    '1182_819': 40.2424,
    '1182_820': 40.3122,
    '1182_821': 40.3368,
    '1182_822': 40.3442,
    '1182_823': 40.368,
    '1182_824': 40.4132,
    '1182_825': 40.4716,
    '1182_826': 40.5206,
    '1182_827': 40.5815,
    '1182_828': 40.664,
    '1182_829': 40.7457,
    '1182_830': 40.8596,
    '1182_831': 40.9796,
    '1182_832': 41.0624,
    '1182_833': 41.0914,
    '1182_834': 41.0696,
    '1182_835': 41.0223,
    '1182_836': 40.9854,
    '1182_837': 40.9549,
    '1182_838': 40.9366,
    '1182_839': 40.9487,
    '1182_840': 40.9857,
    '1182_841': 41.0322,
    '1182_842': 41.0789,
    '1182_843': 41.1159,
    '1182_844': 41.1711,
    '1182_845': 41.2412,
    '1182_846': 41.3215,
    '1182_847': 41.4151,
    '1182_848': 41.5198,
    '1182_849': 41.6224,
    '1182_850': 41.7302,
    '1182_851': 41.8419,
    '1182_852': 41.9464,
    '1182_853': 42.0459,
    '1182_854': 42.1362,
    '1182_855': 42.2171,
    '1182_856': 42.2779,
    '1182_857': 42.3119,
    '1182_858': 42.3239,
    '1182_859': 42.3105,
    '1182_860': 42.2742,
    '1182_861': 42.2164,
    '1182_862': 42.1413,
    '1182_863': 42.0548,
    '1182_864': 41.9566,
    '1182_865': 41.8439,
    '1182_866': 41.7015,
    '1182_867': 41.5397,
    '1182_868': 41.3658,
    '1182_869': 41.209,
    '1182_870': 41.0606,
    '1182_871': 40.8938,
    '1182_872': 40.6906,
    '1182_873': 40.4512,
    '1182_874': 40.1908,
    '1182_875': 39.9245,
    '1182_876': 39.6596,
    '1182_877': 39.3932,
    '1182_878': 39.1159,
    '1182_879': 38.8331,
    '1182_880': 38.5493,
    '1182_881': 38.2699,
    '1182_882': 37.9865,
    '1182_883': 37.697,
    '1182_884': 37.3988,
    '1182_885': 37.088,
    '1182_886': 36.7578,
    '1182_887': 36.412,
    '1182_888': 36.056,
    '1182_889': 35.6929,
    '1182_890': 35.3142,
    '1182_891': 34.9256,
    '1182_892': 34.5295,
    '1182_893': 34.1265,
    '1182_894': 33.7204,
    '1182_895': 33.313,
    '1182_896': 32.9105,
    '1182_897': 32.5178,
    '1182_898': 32.1439,
    '1182_899': 31.7589,
    '1182_900': 31.3259,
    '1183_786': 37.2173,
    '1183_787': 37.2653,
    '1183_788': 37.3016,
    '1183_789': 37.3294,
    '1183_790': 37.3508,
    '1183_791': 37.3684,
    '1183_792': 37.3851,
    '1183_793': 37.404,
    '1183_794': 37.428,
    '1183_795': 37.4615,
    '1183_796': 37.5053,
    '1183_797': 37.5608,
    '1183_798': 37.6288,
    '1183_799': 37.7095,
    '1183_800': 37.803,
    '1183_801': 37.9054,
    '1183_802': 38.0204,
    '1183_803': 38.1468,
    '1183_804': 38.2799,
    '1183_805': 38.4171,
    '1183_806': 38.5561,
    '1183_807': 38.6933,
    '1183_808': 38.8273,
    '1183_809': 38.9625,
    '1183_810': 39.1014,
    '1183_811': 39.2432,
    '1183_812': 39.3854,
    '1183_813': 39.5325,
    '1183_814': 39.6841,
    '1183_815': 39.8345,
    '1183_816': 39.9683,
    '1183_817': 40.0834,
    '1183_818': 40.188,
    '1183_819': 40.2835,
    '1183_820': 40.3505,
    '1183_821': 40.3749,
    '1183_822': 40.3771,
    '1183_823': 40.3965,
    '1183_824': 40.4354,
    '1183_825': 40.4798,
    '1183_826': 40.5002,
    '1183_827': 40.5539,
    '1183_828': 40.6433,
    '1183_829': 40.7354,
    '1183_830': 40.8619,
    '1183_831': 40.9877,
    '1183_832': 41.0684,
    '1183_833': 41.0918,
    '1183_834': 41.0692,
    '1183_835': 41.0227,
    '1183_836': 40.9846,
    '1183_837': 40.9528,
    '1183_838': 40.9349,
    '1183_839': 40.9457,
    '1183_840': 40.9763,
    '1183_841': 41.0174,
    '1183_842': 41.0628,
    '1183_843': 41.0982,
    '1183_844': 41.1531,
    '1183_845': 41.223,
    '1183_846': 41.3021,
    '1183_847': 41.3913,
    '1183_848': 41.4926,
    '1183_849': 41.6023,
    '1183_850': 41.7121,
    '1183_851': 41.8178,
    '1183_852': 41.9192,
    '1183_853': 42.0148,
    '1183_854': 42.1073,
    '1183_855': 42.1911,
    '1183_856': 42.2532,
    '1183_857': 42.2897,
    '1183_858': 42.301,
    '1183_859': 42.2874,
    '1183_860': 42.2561,
    '1183_861': 42.2046,
    '1183_862': 42.1347,
    '1183_863': 42.0544,
    '1183_864': 41.9618,
    '1183_865': 41.8474,
    '1183_866': 41.6992,
    '1183_867': 41.5338,
    '1183_868': 41.3682,
    '1183_869': 41.2191,
    '1183_870': 41.0723,
    '1183_871': 40.9023,
    '1183_872': 40.6927,
    '1183_873': 40.4504,
    '1183_874': 40.1857,
    '1183_875': 39.9158,
    '1183_876': 39.6456,
    '1183_877': 39.3714,
    '1183_878': 39.0931,
    '1183_879': 38.8086,
    '1183_880': 38.521,
    '1183_881': 38.2384,
    '1183_882': 37.9525,
    '1183_883': 37.6602,
    '1183_884': 37.3594,
    '1183_885': 37.0478,
    '1183_886': 36.7161,
    '1183_887': 36.3696,
    '1183_888': 36.0147,
    '1183_889': 35.6501,
    '1183_890': 35.2691,
    '1183_891': 34.8772,
    '1183_892': 34.4777,
    '1183_893': 34.0724,
    '1183_894': 33.6652,
    '1183_895': 33.2599,
    '1183_896': 32.8586,
    '1183_897': 32.465,
    '1183_898': 32.0818,
    '1183_899': 31.6907,
    '1183_900': 31.2822,
    '1184_786': 37.2182,
    '1184_787': 37.2695,
    '1184_788': 37.3093,
    '1184_789': 37.3405,
    '1184_790': 37.3649,
    '1184_791': 37.3846,
    '1184_792': 37.4032,
    '1184_793': 37.4252,
    '1184_794': 37.4466,
    '1184_795': 37.4745,
    '1184_796': 37.5122,
    '1184_797': 37.562,
    '1184_798': 37.6278,
    '1184_799': 37.7072,
    '1184_800': 37.7999,
    '1184_801': 37.9019,
    '1184_802': 38.0175,
    '1184_803': 38.1454,
    '1184_804': 38.2836,
    '1184_805': 38.427,
    '1184_806': 38.5723,
    '1184_807': 38.7145,
    '1184_808': 38.8534,
    '1184_809': 38.9965,
    '1184_810': 39.1468,
    '1184_811': 39.3002,
    '1184_812': 39.4488,
    '1184_813': 39.5946,
    '1184_814': 39.7422,
    '1184_815': 39.886,
    '1184_816': 40.0091,
    '1184_817': 40.1173,
    '1184_818': 40.2198,
    '1184_819': 40.3118,
    '1184_820': 40.3754,
    '1184_821': 40.3982,
    '1184_822': 40.3915,
    '1184_823': 40.4053,
    '1184_824': 40.4468,
    '1184_825': 40.4899,
    '1184_826': 40.5039,
    '1184_827': 40.5505,
    '1184_828': 40.6338,
    '1184_829': 40.7341,
    '1184_830': 40.8708,
    '1184_831': 41.0053,
    '1184_832': 41.0873,
    '1184_833': 41.1118,
    '1184_834': 41.0912,
    '1184_835': 41.0491,
    '1184_836': 41.0031,
    '1184_837': 40.9625,
    '1184_838': 40.9416,
    '1184_839': 40.9492,
    '1184_840': 40.9751,
    '1184_841': 41.0138,
    '1184_842': 41.0577,
    '1184_843': 41.0902,
    '1184_844': 41.1384,
    '1184_845': 41.2055,
    '1184_846': 41.2826,
    '1184_847': 41.3702,
    '1184_848': 41.4676,
    '1184_849': 41.5801,
    '1184_850': 41.6888,
    '1184_851': 41.793,
    '1184_852': 41.8956,
    '1184_853': 41.9895,
    '1184_854': 42.0828,
    '1184_855': 42.1657,
    '1184_856': 42.226,
    '1184_857': 42.2604,
    '1184_858': 42.2683,
    '1184_859': 42.254,
    '1184_860': 42.2214,
    '1184_861': 42.1714,
    '1184_862': 42.1097,
    '1184_863': 42.0361,
    '1184_864': 41.9487,
    '1184_865': 41.8379,
    '1184_866': 41.6917,
    '1184_867': 41.5263,
    '1184_868': 41.3679,
    '1184_869': 41.2196,
    '1184_870': 41.0662,
    '1184_871': 40.8887,
    '1184_872': 40.6765,
    '1184_873': 40.4292,
    '1184_874': 40.1667,
    '1184_875': 39.9009,
    '1184_876': 39.6327,
    '1184_877': 39.3541,
    '1184_878': 39.0729,
    '1184_879': 38.7816,
    '1184_880': 38.4919,
    '1184_881': 38.2058,
    '1184_882': 37.9166,
    '1184_883': 37.6217,
    '1184_884': 37.3186,
    '1184_885': 37.0055,
    '1184_886': 36.6721,
    '1184_887': 36.3252,
    '1184_888': 35.9696,
    '1184_889': 35.603,
    '1184_890': 35.2202,
    '1184_891': 34.8263,
    '1184_892': 34.4244,
    '1184_893': 34.0166,
    '1184_894': 33.6074,
    '1184_895': 33.2034,
    '1184_896': 32.8042,
    '1184_897': 32.4109,
    '1184_898': 32.0227,
    '1184_899': 31.632,
    '1184_900': 31.2352,
    '1185_786': 37.2075,
    '1185_787': 37.2673,
    '1185_788': 37.3149,
    '1185_789': 37.3527,
    '1185_790': 37.3818,
    '1185_791': 37.4044,
    '1185_792': 37.424,
    '1185_793': 37.4502,
    '1185_794': 37.468,
    '1185_795': 37.491,
    '1185_796': 37.5234,
    '1185_797': 37.5668,
    '1185_798': 37.6307,
    '1185_799': 37.708,
    '1185_800': 37.8001,
    '1185_801': 37.9018,
    '1185_802': 38.0176,
    '1185_803': 38.1473,
    '1185_804': 38.2896,
    '1185_805': 38.4426,
    '1185_806': 38.5956,
    '1185_807': 38.7457,
    '1185_808': 38.8913,
    '1185_809': 39.0456,
    '1185_810': 39.2142,
    '1185_811': 39.3824,
    '1185_812': 39.5307,
    '1185_813': 39.6659,
    '1185_814': 39.8006,
    '1185_815': 39.9318,
    '1185_816': 40.0522,
    '1185_817': 40.1511,
    '1185_818': 40.2402,
    '1185_819': 40.3257,
    '1185_820': 40.3843,
    '1185_821': 40.4077,
    '1185_822': 40.4121,
    '1185_823': 40.426,
    '1185_824': 40.4574,
    '1185_825': 40.5043,
    '1185_826': 40.5402,
    '1185_827': 40.5857,
    '1185_828': 40.6575,
    '1185_829': 40.7559,
    '1185_830': 40.8891,
    '1185_831': 41.0193,
    '1185_832': 41.0949,
    '1185_833': 41.1372,
    '1185_834': 41.1299,
    '1185_835': 41.0907,
    '1185_836': 41.0382,
    '1185_837': 40.9862,
    '1185_838': 40.9564,
    '1185_839': 40.9587,
    '1185_840': 40.9805,
    '1185_841': 41.0172,
    '1185_842': 41.0564,
    '1185_843': 41.0873,
    '1185_844': 41.1286,
    '1185_845': 41.1879,
    '1185_846': 41.2604,
    '1185_847': 41.3457,
    '1185_848': 41.4433,
    '1185_849': 41.5564,
    '1185_850': 41.6706,
    '1185_851': 41.7758,
    '1185_852': 41.8786,
    '1185_853': 41.9708,
    '1185_854': 42.0591,
    '1185_855': 42.1376,
    '1185_856': 42.194,
    '1185_857': 42.2265,
    '1185_858': 42.2351,
    '1185_859': 42.2195,
    '1185_860': 42.1805,
    '1185_861': 42.1286,
    '1185_862': 42.0685,
    '1185_863': 41.9979,
    '1185_864': 41.9174,
    '1185_865': 41.8085,
    '1185_866': 41.6697,
    '1185_867': 41.5041,
    '1185_868': 41.3497,
    '1185_869': 41.2003,
    '1185_870': 41.0402,
    '1185_871': 40.8537,
    '1185_872': 40.6335,
    '1185_873': 40.3842,
    '1185_874': 40.13,
    '1185_875': 39.8743,
    '1185_876': 39.6157,
    '1185_877': 39.3413,
    '1185_878': 39.0581,
    '1185_879': 38.7604,
    '1185_880': 38.463,
    '1185_881': 38.1714,
    '1185_882': 37.8784,
    '1185_883': 37.581,
    '1185_884': 37.276,
    '1185_885': 36.9584,
    '1185_886': 36.6249,
    '1185_887': 36.2781,
    '1185_888': 35.9215,
    '1185_889': 35.5524,
    '1185_890': 35.1687,
    '1185_891': 34.7738,
    '1185_892': 34.3701,
    '1185_893': 33.961,
    '1185_894': 33.551,
    '1185_895': 33.1475,
    '1185_896': 32.7497,
    '1185_897': 32.3573,
    '1185_898': 31.969,
    '1185_899': 31.5803,
    '1185_900': 31.1904,
    '1186_786': 37.1957,
    '1186_787': 37.2636,
    '1186_788': 37.3205,
    '1186_789': 37.3669,
    '1186_790': 37.4033,
    '1186_791': 37.4318,
    '1186_792': 37.4564,
    '1186_793': 37.4807,
    '1186_794': 37.4976,
    '1186_795': 37.5172,
    '1186_796': 37.5453,
    '1186_797': 37.5859,
    '1186_798': 37.644,
    '1186_799': 37.7175,
    '1186_800': 37.8054,
    '1186_801': 37.9048,
    '1186_802': 38.0201,
    '1186_803': 38.1517,
    '1186_804': 38.2981,
    '1186_805': 38.4593,
    '1186_806': 38.6247,
    '1186_807': 38.7856,
    '1186_808': 38.9382,
    '1186_809': 39.1046,
    '1186_810': 39.2964,
    '1186_811': 39.474,
    '1186_812': 39.6198,
    '1186_813': 39.741,
    '1186_814': 39.8546,
    '1186_815': 39.9655,
    '1186_816': 40.0808,
    '1186_817': 40.1754,
    '1186_818': 40.2576,
    '1186_819': 40.3305,
    '1186_820': 40.3828,
    '1186_821': 40.409,
    '1186_822': 40.4271,
    '1186_823': 40.4487,
    '1186_824': 40.4801,
    '1186_825': 40.5298,
    '1186_826': 40.578,
    '1186_827': 40.6218,
    '1186_828': 40.6979,
    '1186_829': 40.7988,
    '1186_830': 40.9138,
    '1186_831': 41.0358,
    '1186_832': 41.1144,
    '1186_833': 41.1555,
    '1186_834': 41.1638,
    '1186_835': 41.1339,
    '1186_836': 41.0814,
    '1186_837': 41.0217,
    '1186_838': 40.9798,
    '1186_839': 40.9733,
    '1186_840': 40.9899,
    '1186_841': 41.0232,
    '1186_842': 41.0591,
    '1186_843': 41.0898,
    '1186_844': 41.1184,
    '1186_845': 41.1698,
    '1186_846': 41.237,
    '1186_847': 41.3204,
    '1186_848': 41.4224,
    '1186_849': 41.5395,
    '1186_850': 41.6587,
    '1186_851': 41.765,
    '1186_852': 41.8628,
    '1186_853': 41.9508,
    '1186_854': 42.033,
    '1186_855': 42.1028,
    '1186_856': 42.1587,
    '1186_857': 42.1901,
    '1186_858': 42.1982,
    '1186_859': 42.1819,
    '1186_860': 42.1439,
    '1186_861': 42.085,
    '1186_862': 42.0191,
    '1186_863': 41.9454,
    '1186_864': 41.8657,
    '1186_865': 41.7642,
    '1186_866': 41.6234,
    '1186_867': 41.4585,
    '1186_868': 41.3061,
    '1186_869': 41.1551,
    '1186_870': 40.9898,
    '1186_871': 40.7976,
    '1186_872': 40.5725,
    '1186_873': 40.3264,
    '1186_874': 40.0806,
    '1186_875': 39.8291,
    '1186_876': 39.5801,
    '1186_877': 39.3182,
    '1186_878': 39.0389,
    '1186_879': 38.7365,
    '1186_880': 38.4315,
    '1186_881': 38.1332,
    '1186_882': 37.8359,
    '1186_883': 37.5343,
    '1186_884': 37.226,
    '1186_885': 36.908,
    '1186_886': 36.576,
    '1186_887': 36.231,
    '1186_888': 35.8722,
    '1186_889': 35.5009,
    '1186_890': 35.1161,
    '1186_891': 34.7211,
    '1186_892': 34.3181,
    '1186_893': 33.9073,
    '1186_894': 33.4962,
    '1186_895': 33.0935,
    '1186_896': 32.6991,
    '1186_897': 32.3093,
    '1186_898': 31.9218,
    '1186_899': 31.5336,
    '1186_900': 31.1481,
    '1187_786': 37.1852,
    '1187_787': 37.2617,
    '1187_788': 37.3286,
    '1187_789': 37.3846,
    '1187_790': 37.429,
    '1187_791': 37.4628,
    '1187_792': 37.49,
    '1187_793': 37.5124,
    '1187_794': 37.5285,
    '1187_795': 37.5455,
    '1187_796': 37.5698,
    '1187_797': 37.6068,
    '1187_798': 37.6605,
    '1187_799': 37.7299,
    '1187_800': 37.8142,
    '1187_801': 37.9119,
    '1187_802': 38.0275,
    '1187_803': 38.1604,
    '1187_804': 38.3101,
    '1187_805': 38.477,
    '1187_806': 38.6554,
    '1187_807': 38.8287,
    '1187_808': 38.9923,
    '1187_809': 39.1658,
    '1187_810': 39.3585,
    '1187_811': 39.5354,
    '1187_812': 39.6844,
    '1187_813': 39.8044,
    '1187_814': 39.9027,
    '1187_815': 39.9933,
    '1187_816': 40.0941,
    '1187_817': 40.185,
    '1187_818': 40.2692,
    '1187_819': 40.3384,
    '1187_820': 40.3852,
    '1187_821': 40.4127,
    '1187_822': 40.4344,
    '1187_823': 40.4652,
    '1187_824': 40.5035,
    '1187_825': 40.56,
    '1187_826': 40.6117,
    '1187_827': 40.6659,
    '1187_828': 40.7525,
    '1187_829': 40.8514,
    '1187_830': 40.9474,
    '1187_831': 41.0491,
    '1187_832': 41.1323,
    '1187_833': 41.1781,
    '1187_834': 41.1853,
    '1187_835': 41.1645,
    '1187_836': 41.1156,
    '1187_837': 41.0606,
    '1187_838': 41.0119,
    '1187_839': 40.9967,
    '1187_840': 41.0066,
    '1187_841': 41.0326,
    '1187_842': 41.0621,
    '1187_843': 41.0842,
    '1187_844': 41.1067,
    '1187_845': 41.1533,
    '1187_846': 41.217,
    '1187_847': 41.2982,
    '1187_848': 41.4027,
    '1187_849': 41.5236,
    '1187_850': 41.648,
    '1187_851': 41.7551,
    '1187_852': 41.8466,
    '1187_853': 41.927,
    '1187_854': 42.0007,
    '1187_855': 42.0657,
    '1187_856': 42.1172,
    '1187_857': 42.1479,
    '1187_858': 42.1597,
    '1187_859': 42.1482,
    '1187_860': 42.114,
    '1187_861': 42.0533,
    '1187_862': 41.9764,
    '1187_863': 41.8962,
    '1187_864': 41.8069,
    '1187_865': 41.7016,
    '1187_866': 41.5639,
    '1187_867': 41.3987,
    '1187_868': 41.2448,
    '1187_869': 41.0904,
    '1187_870': 40.9237,
    '1187_871': 40.7306,
    '1187_872': 40.5079,
    '1187_873': 40.265,
    '1187_874': 40.0228,
    '1187_875': 39.7726,
    '1187_876': 39.5256,
    '1187_877': 39.2696,
    '1187_878': 38.9996,
    '1187_879': 38.6998,
    '1187_880': 38.3927,
    '1187_881': 38.0915,
    '1187_882': 37.7908,
    '1187_883': 37.4855,
    '1187_884': 37.1739,
    '1187_885': 36.8552,
    '1187_886': 36.5238,
    '1187_887': 36.1787,
    '1187_888': 35.82,
    '1187_889': 35.4486,
    '1187_890': 35.0627,
    '1187_891': 34.6673,
    '1187_892': 34.2653,
    '1187_893': 33.8553,
    '1187_894': 33.4444,
    '1187_895': 33.0448,
    '1187_896': 32.6524,
    '1187_897': 32.265,
    '1187_898': 31.8802,
    '1187_899': 31.4943,
    '1187_900': 31.1157,
    '1188_782': 36.7721,
    '1188_783': 36.8837,
    '1188_784': 36.9861,
    '1188_785': 37.0823,
    '1188_786': 37.1737,
    '1188_787': 37.2587,
    '1188_788': 37.3362,
    '1188_789': 37.4026,
    '1188_790': 37.455,
    '1188_791': 37.4924,
    '1188_792': 37.5217,
    '1188_793': 37.5432,
    '1188_794': 37.557,
    '1188_795': 37.5701,
    '1188_796': 37.5896,
    '1188_797': 37.6223,
    '1188_798': 37.673,
    '1188_799': 37.7403,
    '1188_800': 37.8213,
    '1188_801': 37.9197,
    '1188_802': 38.037,
    '1188_803': 38.1704,
    '1188_804': 38.3229,
    '1188_805': 38.4926,
    '1188_806': 38.6758,
    '1188_807': 38.8656,
    '1188_808': 39.0456,
    '1188_809': 39.2184,
    '1188_810': 39.4008,
    '1188_811': 39.5673,
    '1188_812': 39.7202,
    '1188_813': 39.8423,
    '1188_814': 39.9407,
    '1188_815': 40.0225,
    '1188_816': 40.1115,
    '1188_817': 40.1894,
    '1188_818': 40.2712,
    '1188_819': 40.3408,
    '1188_820': 40.3882,
    '1188_821': 40.4168,
    '1188_822': 40.4366,
    '1188_823': 40.4654,
    '1188_824': 40.5176,
    '1188_825': 40.5804,
    '1188_826': 40.6399,
    '1188_827': 40.7052,
    '1188_828': 40.7959,
    '1188_829': 40.8883,
    '1188_830': 40.9759,
    '1188_831': 41.0608,
    '1188_832': 41.139,
    '1188_833': 41.1872,
    '1188_834': 41.1969,
    '1188_835': 41.188,
    '1188_836': 41.1458,
    '1188_837': 41.0978,
    '1188_838': 41.0552,
    '1188_839': 41.039,
    '1188_840': 41.0389,
    '1188_841': 41.0465,
    '1188_842': 41.0554,
    '1188_843': 41.067,
    '1188_844': 41.0908,
    '1188_845': 41.1351,
    '1188_846': 41.1947,
    '1188_847': 41.2766,
    '1188_848': 41.3813,
    '1188_849': 41.505,
    '1188_850': 41.6335,
    '1188_851': 41.7392,
    '1188_852': 41.8236,
    '1188_853': 41.8971,
    '1188_854': 41.9638,
    '1188_855': 42.0225,
    '1188_856': 42.0669,
    '1188_857': 42.0992,
    '1188_858': 42.1119,
    '1188_859': 42.1072,
    '1188_860': 42.0793,
    '1188_861': 42.0259,
    '1188_862': 41.9463,
    '1188_863': 41.8575,
    '1188_864': 41.7565,
    '1188_865': 41.6418,
    '1188_866': 41.4993,
    '1188_867': 41.3378,
    '1188_868': 41.1768,
    '1188_869': 41.0125,
    '1188_870': 40.8429,
    '1188_871': 40.6494,
    '1188_872': 40.4299,
    '1188_873': 40.194,
    '1188_874': 39.951,
    '1188_875': 39.7073,
    '1188_876': 39.4689,
    '1188_877': 39.2117,
    '1188_878': 38.9405,
    '1188_879': 38.6487,
    '1188_880': 38.3452,
    '1188_881': 38.0451,
    '1188_882': 37.7429,
    '1188_883': 37.4355,
    '1188_884': 37.1212,
    '1188_885': 36.8,
    '1188_886': 36.4682,
    '1188_887': 36.1205,
    '1188_888': 35.7626,
    '1188_889': 35.3922,
    '1188_890': 35.0058,
    '1188_891': 34.61,
    '1188_892': 34.2095,
    '1188_893': 33.8056,
    '1188_894': 33.399,
    '1188_895': 32.9993,
    '1188_896': 32.6064,
    '1188_897': 32.219,
    '1188_898': 31.8358,
    '1188_899': 31.4512,
    '1188_900': 31.0728,
    '1189_782': 36.7495,
    '1189_783': 36.8634,
    '1189_784': 36.9637,
    '1189_785': 37.0607,
    '1189_786': 37.1582,
    '1189_787': 37.252,
    '1189_788': 37.3399,
    '1189_789': 37.4161,
    '1189_790': 37.4745,
    '1189_791': 37.5177,
    '1189_792': 37.5505,
    '1189_793': 37.5729,
    '1189_794': 37.5825,
    '1189_795': 37.5895,
    '1189_796': 37.6026,
    '1189_797': 37.6305,
    '1189_798': 37.6807,
    '1189_799': 37.7482,
    '1189_800': 37.8295,
    '1189_801': 37.9281,
    '1189_802': 38.0464,
    '1189_803': 38.1811,
    '1189_804': 38.3343,
    '1189_805': 38.505,
    '1189_806': 38.6908,
    '1189_807': 38.8934,
    '1189_808': 39.0859,
    '1189_809': 39.2626,
    '1189_810': 39.4328,
    '1189_811': 39.5924,
    '1189_812': 39.7409,
    '1189_813': 39.8606,
    '1189_814': 39.9614,
    '1189_815': 40.0492,
    '1189_816': 40.1288,
    '1189_817': 40.1941,
    '1189_818': 40.2648,
    '1189_819': 40.3337,
    '1189_820': 40.3824,
    '1189_821': 40.4128,
    '1189_822': 40.4313,
    '1189_823': 40.4551,
    '1189_824': 40.5154,
    '1189_825': 40.5854,
    '1189_826': 40.6484,
    '1189_827': 40.7238,
    '1189_828': 40.8146,
    '1189_829': 40.9081,
    '1189_830': 40.9908,
    '1189_831': 41.0661,
    '1189_832': 41.1328,
    '1189_833': 41.1761,
    '1189_834': 41.1968,
    '1189_835': 41.1973,
    '1189_836': 41.1695,
    '1189_837': 41.1382,
    '1189_838': 41.1121,
    '1189_839': 41.1006,
    '1189_840': 41.0896,
    '1189_841': 41.0744,
    '1189_842': 41.0557,
    '1189_843': 41.0524,
    '1189_844': 41.0725,
    '1189_845': 41.1099,
    '1189_846': 41.1665,
    '1189_847': 41.2484,
    '1189_848': 41.3545,
    '1189_849': 41.4815,
    '1189_850': 41.6098,
    '1189_851': 41.7123,
    '1189_852': 41.7933,
    '1189_853': 41.8619,
    '1189_854': 41.9235,
    '1189_855': 41.9771,
    '1189_856': 42.0181,
    '1189_857': 42.0465,
    '1189_858': 42.0612,
    '1189_859': 42.0606,
    '1189_860': 42.0387,
    '1189_861': 41.991,
    '1189_862': 41.9131,
    '1189_863': 41.8243,
    '1189_864': 41.7216,
    '1189_865': 41.6002,
    '1189_866': 41.4564,
    '1189_867': 41.291,
    '1189_868': 41.113,
    '1189_869': 40.9342,
    '1189_870': 40.7603,
    '1189_871': 40.5664,
    '1189_872': 40.3484,
    '1189_873': 40.1194,
    '1189_874': 39.8791,
    '1189_875': 39.638,
    '1189_876': 39.4046,
    '1189_877': 39.1494,
    '1189_878': 38.8808,
    '1189_879': 38.5941,
    '1189_880': 38.2954,
    '1189_881': 37.9962,
    '1189_882': 37.6935,
    '1189_883': 37.3845,
    '1189_884': 37.0683,
    '1189_885': 36.7433,
    '1189_886': 36.4075,
    '1189_887': 36.0596,
    '1189_888': 35.7022,
    '1189_889': 35.3324,
    '1189_890': 34.9474,
    '1189_891': 34.5527,
    '1189_892': 34.1564,
    '1189_893': 33.756,
    '1189_894': 33.3535,
    '1189_895': 32.9538,
    '1189_896': 32.56,
    '1189_897': 32.1716,
    '1189_898': 31.7913,
    '1189_899': 31.4049,
    '1189_900': 31.0245,
    '1190_782': 36.7237,
    '1190_783': 36.8407,
    '1190_784': 36.9359,
    '1190_785': 37.0345,
    '1190_786': 37.1396,
    '1190_787': 37.2409,
    '1190_788': 37.3357,
    '1190_789': 37.419,
    '1190_790': 37.4872,
    '1190_791': 37.5361,
    '1190_792': 37.5727,
    '1190_793': 37.5982,
    '1190_794': 37.6018,
    '1190_795': 37.6033,
    '1190_796': 37.6115,
    '1190_797': 37.6344,
    '1190_798': 37.6876,
    '1190_799': 37.7566,
    '1190_800': 37.8404,
    '1190_801': 37.94,
    '1190_802': 38.0582,
    '1190_803': 38.1923,
    '1190_804': 38.343,
    '1190_805': 38.5099,
    '1190_806': 38.6982,
    '1190_807': 38.9067,
    '1190_808': 39.1024,
    '1190_809': 39.2802,
    '1190_810': 39.445,
    '1190_811': 39.6035,
    '1190_812': 39.7449,
    '1190_813': 39.8632,
    '1190_814': 39.9633,
    '1190_815': 40.0547,
    '1190_816': 40.1285,
    '1190_817': 40.1844,
    '1190_818': 40.2469,
    '1190_819': 40.3129,
    '1190_820': 40.3609,
    '1190_821': 40.3921,
    '1190_822': 40.4106,
    '1190_823': 40.4429,
    '1190_824': 40.5086,
    '1190_825': 40.5829,
    '1190_826': 40.6478,
    '1190_827': 40.7248,
    '1190_828': 40.8125,
    '1190_829': 40.904,
    '1190_830': 40.9878,
    '1190_831': 41.0625,
    '1190_832': 41.1243,
    '1190_833': 41.1688,
    '1190_834': 41.1967,
    '1190_835': 41.2022,
    '1190_836': 41.1895,
    '1190_837': 41.171,
    '1190_838': 41.1568,
    '1190_839': 41.1444,
    '1190_840': 41.1216,
    '1190_841': 41.0978,
    '1190_842': 41.0594,
    '1190_843': 41.0399,
    '1190_844': 41.049,
    '1190_845': 41.0793,
    '1190_846': 41.1334,
    '1190_847': 41.2158,
    '1190_848': 41.3239,
    '1190_849': 41.4488,
    '1190_850': 41.5741,
    '1190_851': 41.6747,
    '1190_852': 41.7543,
    '1190_853': 41.8222,
    '1190_854': 41.8825,
    '1190_855': 41.9326,
    '1190_856': 41.9702,
    '1190_857': 41.997,
    '1190_858': 42.013,
    '1190_859': 42.0152,
    '1190_860': 41.9968,
    '1190_861': 41.9501,
    '1190_862': 41.874,
    '1190_863': 41.7835,
    '1190_864': 41.679,
    '1190_865': 41.5557,
    '1190_866': 41.4109,
    '1190_867': 41.2432,
    '1190_868': 41.0593,
    '1190_869': 40.8749,
    '1190_870': 40.6869,
    '1190_871': 40.4873,
    '1190_872': 40.2684,
    '1190_873': 40.0409,
    '1190_874': 39.8044,
    '1190_875': 39.5715,
    '1190_876': 39.3402,
    '1190_877': 39.0866,
    '1190_878': 38.8205,
    '1190_879': 38.5398,
    '1190_880': 38.2441,
    '1190_881': 37.9464,
    '1190_882': 37.6433,
    '1190_883': 37.3327,
    '1190_884': 37.0133,
    '1190_885': 36.6863,
    '1190_886': 36.3475,
    '1190_887': 35.9988,
    '1190_888': 35.6423,
    '1190_889': 35.275,
    '1190_890': 34.8926,
    '1190_891': 34.5006,
    '1190_892': 34.1068,
    '1190_893': 33.709,
    '1190_894': 33.3093,
    '1190_895': 32.9108,
    '1190_896': 32.5172,
    '1190_897': 32.1275,
    '1190_898': 31.7433,
    '1190_899': 31.3575,
    '1190_900': 30.9773,
    '1191_782': 36.6854,
    '1191_783': 36.7997,
    '1191_784': 36.9001,
    '1191_785': 37.0127,
    '1191_786': 37.1267,
    '1191_787': 37.2316,
    '1191_788': 37.33,
    '1191_789': 37.4188,
    '1191_790': 37.4929,
    '1191_791': 37.5451,
    '1191_792': 37.5799,
    '1191_793': 37.6003,
    '1191_794': 37.606,
    '1191_795': 37.6097,
    '1191_796': 37.6214,
    '1191_797': 37.6494,
    '1191_798': 37.7009,
    '1191_799': 37.7697,
    '1191_800': 37.8544,
    '1191_801': 37.9542,
    '1191_802': 38.0707,
    '1191_803': 38.2024,
    '1191_804': 38.3478,
    '1191_805': 38.5082,
    '1191_806': 38.6923,
    '1191_807': 38.9004,
    '1191_808': 39.0978,
    '1191_809': 39.2707,
    '1191_810': 39.4287,
    '1191_811': 39.5892,
    '1191_812': 39.7262,
    '1191_813': 39.8425,
    '1191_814': 39.945,
    '1191_815': 40.0399,
    '1191_816': 40.1118,
    '1191_817': 40.1596,
    '1191_818': 40.2182,
    '1191_819': 40.2814,
    '1191_820': 40.3302,
    '1191_821': 40.3622,
    '1191_822': 40.3861,
    '1191_823': 40.431,
    '1191_824': 40.5084,
    '1191_825': 40.5802,
    '1191_826': 40.6411,
    '1191_827': 40.709,
    '1191_828': 40.7893,
    '1191_829': 40.8794,
    '1191_830': 40.9734,
    '1191_831': 41.055,
    '1191_832': 41.1133,
    '1191_833': 41.1587,
    '1191_834': 41.1924,
    '1191_835': 41.2063,
    '1191_836': 41.2002,
    '1191_837': 41.1869,
    '1191_838': 41.1673,
    '1191_839': 41.1475,
    '1191_840': 41.1014,
    '1191_841': 41.0867,
    '1191_842': 41.049,
    '1191_843': 41.0192,
    '1191_844': 41.0203,
    '1191_845': 41.0459,
    '1191_846': 41.0966,
    '1191_847': 41.177,
    '1191_848': 41.2834,
    '1191_849': 41.4052,
    '1191_850': 41.5276,
    '1191_851': 41.6272,
    '1191_852': 41.709,
    '1191_853': 41.7794,
    '1191_854': 41.84,
    '1191_855': 41.8889,
    '1191_856': 41.926,
    '1191_857': 41.9542,
    '1191_858': 41.9728,
    '1191_859': 41.9764,
    '1191_860': 41.9555,
    '1191_861': 41.9043,
    '1191_862': 41.8253,
    '1191_863': 41.7327,
    '1191_864': 41.6266,
    '1191_865': 41.5018,
    '1191_866': 41.3573,
    '1191_867': 41.1926,
    '1191_868': 41.019,
    '1191_869': 40.8427,
    '1191_870': 40.6538,
    '1191_871': 40.439,
    '1191_872': 40.2094,
    '1191_873': 39.9806,
    '1191_874': 39.754,
    '1191_875': 39.5224,
    '1191_876': 39.2949,
    '1191_877': 39.0454,
    '1191_878': 38.7727,
    '1191_879': 38.4859,
    '1191_880': 38.1926,
    '1191_881': 37.8964,
    '1191_882': 37.5934,
    '1191_883': 37.2816,
    '1191_884': 36.9596,
    '1191_885': 36.6303,
    '1191_886': 36.2871,
    '1191_887': 35.9356,
    '1191_888': 35.5806,
    '1191_889': 35.2164,
    '1191_890': 34.8396,
    '1191_891': 34.4541,
    '1191_892': 34.0599,
    '1191_893': 33.6643,
    '1191_894': 33.2674,
    '1191_895': 32.871,
    '1191_896': 32.479,
    '1191_897': 32.0899,
    '1191_898': 31.7029,
    '1191_899': 31.3178,
    '1191_900': 30.9386,
    '1192_782': 36.6482,
    '1192_783': 36.768,
    '1192_784': 36.8797,
    '1192_785': 36.9952,
    '1192_786': 37.11,
    '1192_787': 37.2191,
    '1192_788': 37.322,
    '1192_789': 37.4191,
    '1192_790': 37.5066,
    '1192_791': 37.5592,
    '1192_792': 37.5858,
    '1192_793': 37.5992,
    '1192_794': 37.6017,
    '1192_795': 37.6078,
    '1192_796': 37.6246,
    '1192_797': 37.658,
    '1192_798': 37.7112,
    '1192_799': 37.7832,
    '1192_800': 37.8702,
    '1192_801': 37.9707,
    '1192_802': 38.0855,
    '1192_803': 38.2132,
    '1192_804': 38.3533,
    '1192_805': 38.5066,
    '1192_806': 38.6808,
    '1192_807': 38.8787,
    '1192_808': 39.0724,
    '1192_809': 39.2366,
    '1192_810': 39.3869,
    '1192_811': 39.5447,
    '1192_812': 39.6835,
    '1192_813': 39.8021,
    '1192_814': 39.909,
    '1192_815': 40.0073,
    '1192_816': 40.0775,
    '1192_817': 40.126,
    '1192_818': 40.1769,
    '1192_819': 40.2401,
    '1192_820': 40.2984,
    '1192_821': 40.3424,
    '1192_822': 40.3803,
    '1192_823': 40.4348,
    '1192_824': 40.5113,
    '1192_825': 40.5809,
    '1192_826': 40.6394,
    '1192_827': 40.6933,
    '1192_828': 40.7635,
    '1192_829': 40.8554,
    '1192_830': 40.9521,
    '1192_831': 41.0326,
    '1192_832': 41.0972,
    '1192_833': 41.1449,
    '1192_834': 41.179,
    '1192_835': 41.2029,
    '1192_836': 41.2064,
    '1192_837': 41.1874,
    '1192_838': 41.1511,
    '1192_839': 41.123,
    '1192_840': 41.0862,
    '1192_841': 41.0518,
    '1192_842': 41.013,
    '1192_843': 40.9865,
    '1192_844': 40.9853,
    '1192_845': 41.0119,
    '1192_846': 41.0608,
    '1192_847': 41.1363,
    '1192_848': 41.2374,
    '1192_849': 41.3533,
    '1192_850': 41.4714,
    '1192_851': 41.5737,
    '1192_852': 41.6591,
    '1192_853': 41.7331,
    '1192_854': 41.7968,
    '1192_855': 41.8479,
    '1192_856': 41.8891,
    '1192_857': 41.9216,
    '1192_858': 41.9423,
    '1192_859': 41.9453,
    '1192_860': 41.9226,
    '1192_861': 41.8643,
    '1192_862': 41.7793,
    '1192_863': 41.6822,
    '1192_864': 41.5727,
    '1192_865': 41.4489,
    '1192_866': 41.3106,
    '1192_867': 41.1598,
    '1192_868': 41.0022,
    '1192_869': 40.8287,
    '1192_870': 40.634,
    '1192_871': 40.4106,
    '1192_872': 40.1777,
    '1192_873': 39.952,
    '1192_874': 39.7275,
    '1192_875': 39.4902,
    '1192_876': 39.2555,
    '1192_877': 39.0011,
    '1192_878': 38.7268,
    '1192_879': 38.4383,
    '1192_880': 38.1431,
    '1192_881': 37.8477,
    '1192_882': 37.5446,
    '1192_883': 37.2314,
    '1192_884': 36.9061,
    '1192_885': 36.5743,
    '1192_886': 36.2266,
    '1192_887': 35.8715,
    '1192_888': 35.5185,
    '1192_889': 35.1557,
    '1192_890': 34.7837,
    '1192_891': 34.402,
    '1192_892': 34.0122,
    '1192_893': 33.6212,
    '1192_894': 33.2289,
    '1192_895': 32.8357,
    '1192_896': 32.4442,
    '1192_897': 32.0544,
    '1192_898': 31.6678,
    '1192_899': 31.2853,
    '1192_900': 30.9102,
    '1193_782': 36.6063,
    '1193_783': 36.7344,
    '1193_784': 36.8552,
    '1193_785': 36.9741,
    '1193_786': 37.0901,
    '1193_787': 37.2019,
    '1193_788': 37.309,
    '1193_789': 37.4151,
    '1193_790': 37.5202,
    '1193_791': 37.5723,
    '1193_792': 37.5875,
    '1193_793': 37.5898,
    '1193_794': 37.5881,
    '1193_795': 37.5948,
    '1193_796': 37.6162,
    '1193_797': 37.6542,
    '1193_798': 37.7138,
    '1193_799': 37.7926,
    '1193_800': 37.8849,
    '1193_801': 37.9871,
    '1193_802': 38.1007,
    '1193_803': 38.2234,
    '1193_804': 38.3546,
    '1193_805': 38.4973,
    '1193_806': 38.6565,
    '1193_807': 38.844,
    '1193_808': 39.0333,
    '1193_809': 39.1887,
    '1193_810': 39.3342,
    '1193_811': 39.4884,
    '1193_812': 39.6296,
    '1193_813': 39.7514,
    '1193_814': 39.8643,
    '1193_815': 39.9614,
    '1193_816': 40.0271,
    '1193_817': 40.0788,
    '1193_818': 40.1298,
    '1193_819': 40.1952,
    '1193_820': 40.2657,
    '1193_821': 40.3251,
    '1193_822': 40.3787,
    '1193_823': 40.4419,
    '1193_824': 40.5116,
    '1193_825': 40.5777,
    '1193_826': 40.6319,
    '1193_827': 40.6822,
    '1193_828': 40.7465,
    '1193_829': 40.8339,
    '1193_830': 40.9274,
    '1193_831': 41.0132,
    '1193_832': 41.0756,
    '1193_833': 41.1237,
    '1193_834': 41.1643,
    '1193_835': 41.1803,
    '1193_836': 41.1751,
    '1193_837': 41.1476,
    '1193_838': 41.0989,
    '1193_839': 41.0567,
    '1193_840': 41.0219,
    '1193_841': 40.9865,
    '1193_842': 40.9565,
    '1193_843': 40.9391,
    '1193_844': 40.9454,
    '1193_845': 40.9755,
    '1193_846': 41.0235,
    '1193_847': 41.0934,
    '1193_848': 41.1886,
    '1193_849': 41.2995,
    '1193_850': 41.4127,
    '1193_851': 41.5168,
    '1193_852': 41.608,
    '1193_853': 41.689,
    '1193_854': 41.7581,
    '1193_855': 41.8131,
    '1193_856': 41.8576,
    '1193_857': 41.8909,
    '1193_858': 41.9117,
    '1193_859': 41.9114,
    '1193_860': 41.8846,
    '1193_861': 41.8244,
    '1193_862': 41.7394,
    '1193_863': 41.6407,
    '1193_864': 41.5288,
    '1193_865': 41.4055,
    '1193_866': 41.2766,
    '1193_867': 41.1389,
    '1193_868': 40.9864,
    '1193_869': 40.8108,
    '1193_870': 40.6085,
    '1193_871': 40.3858,
    '1193_872': 40.1533,
    '1193_873': 39.9265,
    '1193_874': 39.6945,
    '1193_875': 39.4571,
    '1193_876': 39.2128,
    '1193_877': 38.9486,
    '1193_878': 38.6726,
    '1193_879': 38.3851,
    '1193_880': 38.0941,
    '1193_881': 37.799,
    '1193_882': 37.4938,
    '1193_883': 37.1774,
    '1193_884': 36.8492,
    '1193_885': 36.5143,
    '1193_886': 36.1668,
    '1193_887': 35.8123,
    '1193_888': 35.4583,
    '1193_889': 35.0955,
    '1193_890': 34.7295,
    '1193_891': 34.3515,
    '1193_892': 33.9642,
    '1193_893': 33.5788,
    '1193_894': 33.1914,
    '1193_895': 32.8019,
    '1193_896': 32.4104,
    '1193_897': 32.0181,
    '1193_898': 31.6334,
    '1193_899': 31.2524,
    '1193_900': 30.8788,
    '1194_782': 36.5567,
    '1194_783': 36.6951,
    '1194_784': 36.8244,
    '1194_785': 36.9484,
    '1194_786': 37.067,
    '1194_787': 37.1819,
    '1194_788': 37.2928,
    '1194_789': 37.4031,
    '1194_790': 37.5118,
    '1194_791': 37.5617,
    '1194_792': 37.572,
    '1194_793': 37.5699,
    '1194_794': 37.5675,
    '1194_795': 37.5767,
    '1194_796': 37.6001,
    '1194_797': 37.6422,
    '1194_798': 37.7089,
    '1194_799': 37.7959,
    '1194_800': 37.8952,
    '1194_801': 38.0025,
    '1194_802': 38.1165,
    '1194_803': 38.2308,
    '1194_804': 38.3526,
    '1194_805': 38.485,
    '1194_806': 38.6325,
    '1194_807': 38.8009,
    '1194_808': 38.9812,
    '1194_809': 39.1379,
    '1194_810': 39.2853,
    '1194_811': 39.4383,
    '1194_812': 39.5787,
    '1194_813': 39.7036,
    '1194_814': 39.8158,
    '1194_815': 39.9068,
    '1194_816': 39.965,
    '1194_817': 40.019,
    '1194_818': 40.0772,
    '1194_819': 40.1455,
    '1194_820': 40.2237,
    '1194_821': 40.2955,
    '1194_822': 40.3623,
    '1194_823': 40.4333,
    '1194_824': 40.507,
    '1194_825': 40.573,
    '1194_826': 40.6249,
    '1194_827': 40.6741,
    '1194_828': 40.7325,
    '1194_829': 40.8131,
    '1194_830': 40.8989,
    '1194_831': 40.9756,
    '1194_832': 41.0407,
    '1194_833': 41.0995,
    '1194_834': 41.1384,
    '1194_835': 41.1524,
    '1194_836': 41.1416,
    '1194_837': 41.0985,
    '1194_838': 41.0391,
    '1194_839': 40.9822,
    '1194_840': 40.9418,
    '1194_841': 40.9127,
    '1194_842': 40.8921,
    '1194_843': 40.8871,
    '1194_844': 40.9013,
    '1194_845': 40.9334,
    '1194_846': 40.9814,
    '1194_847': 41.0497,
    '1194_848': 41.1407,
    '1194_849': 41.2477,
    '1194_850': 41.3571,
    '1194_851': 41.4621,
    '1194_852': 41.5551,
    '1194_853': 41.6407,
    '1194_854': 41.7146,
    '1194_855': 41.7735,
    '1194_856': 41.8198,
    '1194_857': 41.8546,
    '1194_858': 41.8736,
    '1194_859': 41.8704,
    '1194_860': 41.8401,
    '1194_861': 41.7832,
    '1194_862': 41.7037,
    '1194_863': 41.6077,
    '1194_864': 41.4982,
    '1194_865': 41.3808,
    '1194_866': 41.2552,
    '1194_867': 41.1184,
    '1194_868': 40.9667,
    '1194_869': 40.7866,
    '1194_870': 40.5769,
    '1194_871': 40.3533,
    '1194_872': 40.1186,
    '1194_873': 39.8846,
    '1194_874': 39.6491,
    '1194_875': 39.4092,
    '1194_876': 39.1598,
    '1194_877': 38.8942,
    '1194_878': 38.6156,
    '1194_879': 38.3321,
    '1194_880': 38.0467,
    '1194_881': 37.7522,
    '1194_882': 37.4431,
    '1194_883': 37.1213,
    '1194_884': 36.7889,
    '1194_885': 36.4542,
    '1194_886': 36.1116,
    '1194_887': 35.7639,
    '1194_888': 35.4099,
    '1194_889': 35.0447,
    '1194_890': 34.6843,
    '1194_891': 34.3084,
    '1194_892': 33.9191,
    '1194_893': 33.5388,
    '1194_894': 33.154,
    '1194_895': 32.7668,
    '1194_896': 32.3784,
    '1194_897': 31.9812,
    '1194_898': 31.6036,
    '1194_899': 31.2242,
    '1194_900': 30.8509,
    '1195_782': 36.5085,
    '1195_783': 36.6538,
    '1195_784': 36.7897,
    '1195_785': 36.9192,
    '1195_786': 37.0408,
    '1195_787': 37.1605,
    '1195_788': 37.2756,
    '1195_789': 37.3895,
    '1195_790': 37.483,
    '1195_791': 37.5238,
    '1195_792': 37.535,
    '1195_793': 37.5372,
    '1195_794': 37.5421,
    '1195_795': 37.5591,
    '1195_796': 37.5819,
    '1195_797': 37.6294,
    '1195_798': 37.703,
    '1195_799': 37.7973,
    '1195_800': 37.9034,
    '1195_801': 38.0148,
    '1195_802': 38.1262,
    '1195_803': 38.2339,
    '1195_804': 38.3497,
    '1195_805': 38.474,
    '1195_806': 38.6088,
    '1195_807': 38.7652,
    '1195_808': 38.9359,
    '1195_809': 39.0864,
    '1195_810': 39.2416,
    '1195_811': 39.3985,
    '1195_812': 39.534,
    '1195_813': 39.6567,
    '1195_814': 39.7684,
    '1195_815': 39.8506,
    '1195_816': 39.9017,
    '1195_817': 39.9572,
    '1195_818': 40.0268,
    '1195_819': 40.1034,
    '1195_820': 40.1818,
    '1195_821': 40.2608,
    '1195_822': 40.338,
    '1195_823': 40.4168,
    '1195_824': 40.4954,
    '1195_825': 40.5622,
    '1195_826': 40.6161,
    '1195_827': 40.6619,
    '1195_828': 40.7161,
    '1195_829': 40.7878,
    '1195_830': 40.8618,
    '1195_831': 40.9356,
    '1195_832': 41.0006,
    '1195_833': 41.0599,
    '1195_834': 41.0936,
    '1195_835': 41.1124,
    '1195_836': 41.1055,
    '1195_837': 41.0574,
    '1195_838': 40.9909,
    '1195_839': 40.9249,
    '1195_840': 40.8758,
    '1195_841': 40.849,
    '1195_842': 40.8369,
    '1195_843': 40.8406,
    '1195_844': 40.8573,
    '1195_845': 40.8895,
    '1195_846': 40.9411,
    '1195_847': 41.01,
    '1195_848': 41.0972,
    '1195_849': 41.2005,
    '1195_850': 41.3073,
    '1195_851': 41.4098,
    '1195_852': 41.5018,
    '1195_853': 41.5847,
    '1195_854': 41.663,
    '1195_855': 41.7255,
    '1195_856': 41.7729,
    '1195_857': 41.807,
    '1195_858': 41.8248,
    '1195_859': 41.8196,
    '1195_860': 41.7896,
    '1195_861': 41.7371,
    '1195_862': 41.6688,
    '1195_863': 41.5823,
    '1195_864': 41.4828,
    '1195_865': 41.3727,
    '1195_866': 41.2403,
    '1195_867': 41.0947,
    '1195_868': 40.9384,
    '1195_869': 40.7529,
    '1195_870': 40.5369,
    '1195_871': 40.3107,
    '1195_872': 40.0714,
    '1195_873': 39.8316,
    '1195_874': 39.5991,
    '1195_875': 39.3609,
    '1195_876': 39.1067,
    '1195_877': 38.8393,
    '1195_878': 38.5596,
    '1195_879': 38.2808,
    '1195_880': 38.0004,
    '1195_881': 37.7072,
    '1195_882': 37.3922,
    '1195_883': 37.0683,
    '1195_884': 36.7269,
    '1195_885': 36.3906,
    '1195_886': 36.0588,
    '1195_887': 35.7166,
    '1195_888': 35.3682,
    '1195_889': 35.0019,
    '1195_890': 34.6402,
    '1195_891': 34.2691,
    '1195_892': 33.8761,
    '1195_893': 33.4992,
    '1195_894': 33.1114,
    '1195_895': 32.7223,
    '1195_896': 32.3496,
    '1195_897': 31.9505,
    '1195_898': 31.5819,
    '1195_899': 31.2043,
    '1195_900': 30.818,
    '1196_782': 36.4603,
    '1196_783': 36.6087,
    '1196_784': 36.7495,
    '1196_785': 36.8839,
    '1196_786': 37.0115,
    '1196_787': 37.1358,
    '1196_788': 37.2543,
    '1196_789': 37.3646,
    '1196_790': 37.4376,
    '1196_791': 37.4701,
    '1196_792': 37.4852,
    '1196_793': 37.4948,
    '1196_794': 37.5111,
    '1196_795': 37.5402,
    '1196_796': 37.5656,
    '1196_797': 37.6165,
    '1196_798': 37.6965,
    '1196_799': 37.7972,
    '1196_800': 37.9091,
    '1196_801': 38.0239,
    '1196_802': 38.1337,
    '1196_803': 38.2409,
    '1196_804': 38.3506,
    '1196_805': 38.4658,
    '1196_806': 38.5917,
    '1196_807': 38.7358,
    '1196_808': 38.8959,
    '1196_809': 39.046,
    '1196_810': 39.2039,
    '1196_811': 39.3565,
    '1196_812': 39.4874,
    '1196_813': 39.6086,
    '1196_814': 39.7172,
    '1196_815': 39.7917,
    '1196_816': 39.8407,
    '1196_817': 39.9086,
    '1196_818': 39.9889,
    '1196_819': 40.0743,
    '1196_820': 40.1603,
    '1196_821': 40.2444,
    '1196_822': 40.3266,
    '1196_823': 40.4035,
    '1196_824': 40.4791,
    '1196_825': 40.5425,
    '1196_826': 40.596,
    '1196_827': 40.6435,
    '1196_828': 40.6952,
    '1196_829': 40.7596,
    '1196_830': 40.8268,
    '1196_831': 40.8894,
    '1196_832': 40.9549,
    '1196_833': 41.0152,
    '1196_834': 41.0548,
    '1196_835': 41.077,
    '1196_836': 41.0637,
    '1196_837': 41.0197,
    '1196_838': 40.9572,
    '1196_839': 40.8878,
    '1196_840': 40.8305,
    '1196_841': 40.8017,
    '1196_842': 40.7927,
    '1196_843': 40.7996,
    '1196_844': 40.8184,
    '1196_845': 40.8543,
    '1196_846': 40.9033,
    '1196_847': 40.9718,
    '1196_848': 41.0561,
    '1196_849': 41.1554,
    '1196_850': 41.259,
    '1196_851': 41.3593,
    '1196_852': 41.4516,
    '1196_853': 41.5304,
    '1196_854': 41.6052,
    '1196_855': 41.6684,
    '1196_856': 41.7157,
    '1196_857': 41.7501,
    '1196_858': 41.7694,
    '1196_859': 41.7638,
    '1196_860': 41.7346,
    '1196_861': 41.6892,
    '1196_862': 41.6324,
    '1196_863': 41.5594,
    '1196_864': 41.4683,
    '1196_865': 41.3533,
    '1196_866': 41.2135,
    '1196_867': 41.06,
    '1196_868': 40.8972,
    '1196_869': 40.707,
    '1196_870': 40.4883,
    '1196_871': 40.26,
    '1196_872': 40.0205,
    '1196_873': 39.7746,
    '1196_874': 39.5413,
    '1196_875': 39.3049,
    '1196_876': 39.0495,
    '1196_877': 38.7819,
    '1196_878': 38.5041,
    '1196_879': 38.229,
    '1196_880': 37.9506,
    '1196_881': 37.6562,
    '1196_882': 37.329,
    '1196_883': 36.9951,
    '1196_884': 36.6607,
    '1196_885': 36.323,
    '1196_886': 35.9904,
    '1196_887': 35.6513,
    '1196_888': 35.3045,
    '1196_889': 34.9456,
    '1196_890': 34.582,
    '1196_891': 34.2089,
    '1196_892': 33.8226,
    '1196_893': 33.4421,
    '1196_894': 33.0566,
    '1196_895': 32.67,
    '1196_896': 32.2929,
    '1196_897': 31.9074,
    '1196_898': 31.5328,
    '1196_899': 31.1541,
    '1196_900': 30.7739,
    '1197_782': 36.4045,
    '1197_783': 36.5576,
    '1197_784': 36.7032,
    '1197_785': 36.8411,
    '1197_786': 36.9739,
    '1197_787': 37.1017,
    '1197_788': 37.2193,
    '1197_789': 37.3148,
    '1197_790': 37.3776,
    '1197_791': 37.4092,
    '1197_792': 37.4296,
    '1197_793': 37.4455,
    '1197_794': 37.4681,
    '1197_795': 37.5028,
    '1197_796': 37.5394,
    '1197_797': 37.5994,
    '1197_798': 37.6855,
    '1197_799': 37.7916,
    '1197_800': 37.9071,
    '1197_801': 38.0216,
    '1197_802': 38.1339,
    '1197_803': 38.2443,
    '1197_804': 38.3512,
    '1197_805': 38.46,
    '1197_806': 38.5746,
    '1197_807': 38.7082,
    '1197_808': 38.8608,
    '1197_809': 39.0091,
    '1197_810': 39.1618,
    '1197_811': 39.3087,
    '1197_812': 39.4313,
    '1197_813': 39.5477,
    '1197_814': 39.6502,
    '1197_815': 39.7222,
    '1197_816': 39.7778,
    '1197_817': 39.8566,
    '1197_818': 39.9521,
    '1197_819': 40.0487,
    '1197_820': 40.1439,
    '1197_821': 40.2357,
    '1197_822': 40.3199,
    '1197_823': 40.3897,
    '1197_824': 40.4516,
    '1197_825': 40.5048,
    '1197_826': 40.5596,
    '1197_827': 40.6142,
    '1197_828': 40.6701,
    '1197_829': 40.733,
    '1197_830': 40.7951,
    '1197_831': 40.8512,
    '1197_832': 40.9109,
    '1197_833': 40.9687,
    '1197_834': 41.0028,
    '1197_835': 41.0229,
    '1197_836': 41.0152,
    '1197_837': 40.99,
    '1197_838': 40.932,
    '1197_839': 40.8646,
    '1197_840': 40.8058,
    '1197_841': 40.7721,
    '1197_842': 40.7583,
    '1197_843': 40.7613,
    '1197_844': 40.7804,
    '1197_845': 40.818,
    '1197_846': 40.8649,
    '1197_847': 40.9306,
    '1197_848': 41.0143,
    '1197_849': 41.1089,
    '1197_850': 41.2089,
    '1197_851': 41.3098,
    '1197_852': 41.4036,
    '1197_853': 41.4807,
    '1197_854': 41.5475,
    '1197_855': 41.6073,
    '1197_856': 41.6521,
    '1197_857': 41.6876,
    '1197_858': 41.7086,
    '1197_859': 41.7058,
    '1197_860': 41.679,
    '1197_861': 41.6428,
    '1197_862': 41.5999,
    '1197_863': 41.5313,
    '1197_864': 41.4347,
    '1197_865': 41.3152,
    '1197_866': 41.168,
    '1197_867': 41.0055,
    '1197_868': 40.8381,
    '1197_869': 40.6445,
    '1197_870': 40.4276,
    '1197_871': 40.2039,
    '1197_872': 39.969,
    '1197_873': 39.7191,
    '1197_874': 39.4793,
    '1197_875': 39.2441,
    '1197_876': 38.9901,
    '1197_877': 38.7273,
    '1197_878': 38.4535,
    '1197_879': 38.1748,
    '1197_880': 37.8927,
    '1197_881': 37.5925,
    '1197_882': 37.256,
    '1197_883': 36.9229,
    '1197_884': 36.5918,
    '1197_885': 36.2552,
    '1197_886': 35.9206,
    '1197_887': 35.581,
    '1197_888': 35.2363,
    '1197_889': 34.8818,
    '1197_890': 34.5197,
    '1197_891': 34.1488,
    '1197_892': 33.7694,
    '1197_893': 33.385,
    '1197_894': 33.0017,
    '1197_895': 32.6193,
    '1197_896': 32.239,
    '1197_897': 31.8582,
    '1197_898': 31.4816,
    '1197_899': 31.1049,
    '1197_900': 30.731,
    '1198_782': 36.3457,
    '1198_783': 36.5018,
    '1198_784': 36.6521,
    '1198_785': 36.7916,
    '1198_786': 36.9275,
    '1198_787': 37.0542,
    '1198_788': 37.1669,
    '1198_789': 37.2575,
    '1198_790': 37.3118,
    '1198_791': 37.3461,
    '1198_792': 37.3708,
    '1198_793': 37.3929,
    '1198_794': 37.4199,
    '1198_795': 37.4582,
    '1198_796': 37.5078,
    '1198_797': 37.5758,
    '1198_798': 37.666,
    '1198_799': 37.7756,
    '1198_800': 37.893,
    '1198_801': 38.0083,
    '1198_802': 38.1225,
    '1198_803': 38.2362,
    '1198_804': 38.3443,
    '1198_805': 38.451,
    '1198_806': 38.5596,
    '1198_807': 38.684,
    '1198_808': 38.8299,
    '1198_809': 38.9751,
    '1198_810': 39.1155,
    '1198_811': 39.2503,
    '1198_812': 39.3674,
    '1198_813': 39.4787,
    '1198_814': 39.5778,
    '1198_815': 39.6514,
    '1198_816': 39.7165,
    '1198_817': 39.8043,
    '1198_818': 39.9081,
    '1198_819': 40.0128,
    '1198_820': 40.1199,
    '1198_821': 40.216,
    '1198_822': 40.296,
    '1198_823': 40.358,
    '1198_824': 40.409,
    '1198_825': 40.4528,
    '1198_826': 40.5076,
    '1198_827': 40.5709,
    '1198_828': 40.6382,
    '1198_829': 40.7035,
    '1198_830': 40.7591,
    '1198_831': 40.8061,
    '1198_832': 40.8565,
    '1198_833': 40.9051,
    '1198_834': 40.9389,
    '1198_835': 40.9636,
    '1198_836': 40.9579,
    '1198_837': 40.9325,
    '1198_838': 40.8916,
    '1198_839': 40.841,
    '1198_840': 40.7879,
    '1198_841': 40.7514,
    '1198_842': 40.7327,
    '1198_843': 40.7296,
    '1198_844': 40.7446,
    '1198_845': 40.7806,
    '1198_846': 40.8264,
    '1198_847': 40.8907,
    '1198_848': 40.9715,
    '1198_849': 41.0602,
    '1198_850': 41.1574,
    '1198_851': 41.2576,
    '1198_852': 41.3524,
    '1198_853': 41.4302,
    '1198_854': 41.4925,
    '1198_855': 41.5445,
    '1198_856': 41.5849,
    '1198_857': 41.6207,
    '1198_858': 41.6431,
    '1198_859': 41.6429,
    '1198_860': 41.6241,
    '1198_861': 41.6022,
    '1198_862': 41.567,
    '1198_863': 41.4976,
    '1198_864': 41.3918,
    '1198_865': 41.2649,
    '1198_866': 41.1144,
    '1198_867': 40.947,
    '1198_868': 40.769,
    '1198_869': 40.5702,
    '1198_870': 40.3576,
    '1198_871': 40.1414,
    '1198_872': 39.9096,
    '1198_873': 39.6605,
    '1198_874': 39.4166,
    '1198_875': 39.1807,
    '1198_876': 38.9366,
    '1198_877': 38.6811,
    '1198_878': 38.4043,
    '1198_879': 38.1175,
    '1198_880': 37.8289,
    '1198_881': 37.5203,
    '1198_882': 37.1838,
    '1198_883': 36.8559,
    '1198_884': 36.5236,
    '1198_885': 36.1891,
    '1198_886': 35.8548,
    '1198_887': 35.5167,
    '1198_888': 35.1711,
    '1198_889': 34.8176,
    '1198_890': 34.4562,
    '1198_891': 34.0876,
    '1198_892': 33.7111,
    '1198_893': 33.3258,
    '1198_894': 32.9461,
    '1198_895': 32.5679,
    '1198_896': 32.1879,
    '1198_897': 31.808,
    '1198_898': 31.433,
    '1198_899': 31.0604,
    '1198_900': 30.6924,
    '1199_782': 36.2856,
    '1199_783': 36.4438,
    '1199_784': 36.5972,
    '1199_785': 36.7415,
    '1199_786': 36.8801,
    '1199_787': 37.0026,
    '1199_788': 37.1071,
    '1199_789': 37.1879,
    '1199_790': 37.2425,
    '1199_791': 37.283,
    '1199_792': 37.3148,
    '1199_793': 37.3436,
    '1199_794': 37.3758,
    '1199_795': 37.418,
    '1199_796': 37.4752,
    '1199_797': 37.5474,
    '1199_798': 37.6391,
    '1199_799': 37.7509,
    '1199_800': 37.8693,
    '1199_801': 37.9848,
    '1199_802': 38.0978,
    '1199_803': 38.2135,
    '1199_804': 38.3231,
    '1199_805': 38.4317,
    '1199_806': 38.5386,
    '1199_807': 38.657,
    '1199_808': 38.798,
    '1199_809': 38.9414,
    '1199_810': 39.0703,
    '1199_811': 39.1882,
    '1199_812': 39.2964,
    '1199_813': 39.407,
    '1199_814': 39.5046,
    '1199_815': 39.5844,
    '1199_816': 39.6551,
    '1199_817': 39.7498,
    '1199_818': 39.8535,
    '1199_819': 39.9629,
    '1199_820': 40.0764,
    '1199_821': 40.1731,
    '1199_822': 40.2473,
    '1199_823': 40.3051,
    '1199_824': 40.3451,
    '1199_825': 40.3816,
    '1199_826': 40.438,
    '1199_827': 40.5104,
    '1199_828': 40.5887,
    '1199_829': 40.6572,
    '1199_830': 40.7133,
    '1199_831': 40.7526,
    '1199_832': 40.7866,
    '1199_833': 40.8278,
    '1199_834': 40.8619,
    '1199_835': 40.8846,
    '1199_836': 40.8825,
    '1199_837': 40.8638,
    '1199_838': 40.8345,
    '1199_839': 40.8031,
    '1199_840': 40.7647,
    '1199_841': 40.7355,
    '1199_842': 40.716,
    '1199_843': 40.7065,
    '1199_844': 40.7135,
    '1199_845': 40.7448,
    '1199_846': 40.7914,
    '1199_847': 40.8555,
    '1199_848': 40.9308,
    '1199_849': 41.015,
    '1199_850': 41.1097,
    '1199_851': 41.2065,
    '1199_852': 41.3002,
    '1199_853': 41.377,
    '1199_854': 41.4374,
    '1199_855': 41.4834,
    '1199_856': 41.5196,
    '1199_857': 41.5519,
    '1199_858': 41.5746,
    '1199_859': 41.5784,
    '1199_860': 41.5718,
    '1199_861': 41.565,
    '1199_862': 41.5325,
    '1199_863': 41.4562,
    '1199_864': 41.3477,
    '1199_865': 41.2208,
    '1199_866': 41.0713,
    '1199_867': 40.8952,
    '1199_868': 40.7017,
    '1199_869': 40.4988,
    '1199_870': 40.2866,
    '1199_871': 40.0703,
    '1199_872': 39.8424,
    '1199_873': 39.5964,
    '1199_874': 39.3511,
    '1199_875': 39.1108,
    '1199_876': 38.8727,
    '1199_877': 38.6237,
    '1199_878': 38.3444,
    '1199_879': 38.0558,
    '1199_880': 37.7651,
    '1199_881': 37.4474,
    '1199_882': 37.1143,
    '1199_883': 36.7877,
    '1199_884': 36.4561,
    '1199_885': 36.1224,
    '1199_886': 35.7887,
    '1199_887': 35.4514,
    '1199_888': 35.1064,
    '1199_889': 34.7535,
    '1199_890': 34.3922,
    '1199_891': 34.0256,
    '1199_892': 33.6525,
    '1199_893': 33.2729,
    '1199_894': 32.8943,
    '1199_895': 32.5171,
    '1199_896': 32.1391,
    '1199_897': 31.761,
    '1199_898': 31.3861,
    '1199_899': 31.0146,
    '1199_900': 30.6479,
    '1200_782': 36.2255,
    '1200_783': 36.3872,
    '1200_784': 36.5447,
    '1200_785': 36.6919,
    '1200_786': 36.8355,
    '1200_787': 36.9512,
    '1200_788': 37.0464,
    '1200_789': 37.121,
    '1200_790': 37.1783,
    '1200_791': 37.2232,
    '1200_792': 37.2613,
    '1200_793': 37.2967,
    '1200_794': 37.3342,
    '1200_795': 37.3799,
    '1200_796': 37.4397,
    '1200_797': 37.5141,
    '1200_798': 37.6062,
    '1200_799': 37.7186,
    '1200_800': 37.8366,
    '1200_801': 37.9511,
    '1200_802': 38.0637,
    '1200_803': 38.1779,
    '1200_804': 38.2908,
    '1200_805': 38.4006,
    '1200_806': 38.5103,
    '1200_807': 38.6264,
    '1200_808': 38.7626,
    '1200_809': 38.9015,
    '1200_810': 39.0203,
    '1200_811': 39.1237,
    '1200_812': 39.2247,
    '1200_813': 39.3258,
    '1200_814': 39.4245,
    '1200_815': 39.5081,
    '1200_816': 39.5827,
    '1200_817': 39.6846,
    '1200_818': 39.7843,
    '1200_819': 39.8931,
    '1200_820': 40.0069,
    '1200_821': 40.1075,
    '1200_822': 40.1837,
    '1200_823': 40.2352,
    '1200_824': 40.2658,
    '1200_825': 40.3008,
    '1200_826': 40.3603,
    '1200_827': 40.4385,
    '1200_828': 40.5196,
    '1200_829': 40.5907,
    '1200_830': 40.646,
    '1200_831': 40.6796,
    '1200_832': 40.7016,
    '1200_833': 40.7325,
    '1200_834': 40.7644,
    '1200_835': 40.7845,
    '1200_836': 40.7892,
    '1200_837': 40.7803,
    '1200_838': 40.7632,
    '1200_839': 40.7446,
    '1200_840': 40.7258,
    '1200_841': 40.7127,
    '1200_842': 40.6997,
    '1200_843': 40.6876,
    '1200_844': 40.6878,
    '1200_845': 40.7116,
    '1200_846': 40.758,
    '1200_847': 40.8219,
    '1200_848': 40.8934,
    '1200_849': 40.9736,
    '1200_850': 41.0612,
    '1200_851': 41.1541,
    '1200_852': 41.2452,
    '1200_853': 41.3224,
    '1200_854': 41.3833,
    '1200_855': 41.4273,
    '1200_856': 41.4589,
    '1200_857': 41.4853,
    '1200_858': 41.5053,
    '1200_859': 41.5146,
    '1200_860': 41.5255,
    '1200_861': 41.5275,
    '1200_862': 41.4903,
    '1200_863': 41.4088,
    '1200_864': 41.3015,
    '1200_865': 41.1772,
    '1200_866': 41.0283,
    '1200_867': 40.8505,
    '1200_868': 40.6505,
    '1200_869': 40.4416,
    '1200_870': 40.2227,
    '1200_871': 39.9971,
    '1200_872': 39.7708,
    '1200_873': 39.5295,
    '1200_874': 39.2874,
    '1200_875': 39.0413,
    '1200_876': 38.7983,
    '1200_877': 38.5512,
    '1200_878': 38.2767,
    '1200_879': 37.993,
    '1200_880': 37.6993,
    '1200_881': 37.3756,
    '1200_882': 37.0456,
    '1200_883': 36.717,
    '1200_884': 36.3857,
    '1200_885': 36.0536,
    '1200_886': 35.7209,
    '1200_887': 35.3844,
    '1200_888': 35.0403,
    '1200_889': 34.6882,
    '1200_890': 34.3265,
    '1200_891': 33.9626,
    '1200_892': 33.5933,
    '1200_893': 33.2168,
    '1200_894': 32.8393,
    '1200_895': 32.463,
    '1200_896': 32.0882,
    '1200_897': 31.7119,
    '1200_898': 31.3362,
    '1200_899': 30.963,
    '1200_900': 30.595,
    '1201_782': 36.1718,
    '1201_783': 36.3304,
    '1201_784': 36.4952,
    '1201_785': 36.6528,
    '1201_786': 36.7869,
    '1201_787': 36.8983,
    '1201_788': 36.9885,
    '1201_789': 37.0611,
    '1201_790': 37.1194,
    '1201_791': 37.1684,
    '1201_792': 37.2112,
    '1201_793': 37.2515,
    '1201_794': 37.294,
    '1201_795': 37.3425,
    '1201_796': 37.4025,
    '1201_797': 37.477,
    '1201_798': 37.5694,
    '1201_799': 37.682,
    '1201_800': 37.7995,
    '1201_801': 37.9127,
    '1201_802': 38.0236,
    '1201_803': 38.1393,
    '1201_804': 38.2537,
    '1201_805': 38.3616,
    '1201_806': 38.4729,
    '1201_807': 38.5875,
    '1201_808': 38.7176,
    '1201_809': 38.8468,
    '1201_810': 38.9579,
    '1201_811': 39.0544,
    '1201_812': 39.1529,
    '1201_813': 39.2469,
    '1201_814': 39.3418,
    '1201_815': 39.4269,
    '1201_816': 39.508,
    '1201_817': 39.608,
    '1201_818': 39.7039,
    '1201_819': 39.8108,
    '1201_820': 39.9176,
    '1201_821': 40.0173,
    '1201_822': 40.0991,
    '1201_823': 40.1531,
    '1201_824': 40.181,
    '1201_825': 40.2169,
    '1201_826': 40.2762,
    '1201_827': 40.3554,
    '1201_828': 40.438,
    '1201_829': 40.5142,
    '1201_830': 40.5695,
    '1201_831': 40.5953,
    '1201_832': 40.6056,
    '1201_833': 40.6271,
    '1201_834': 40.6585,
    '1201_835': 40.6811,
    '1201_836': 40.695,
    '1201_837': 40.6968,
    '1201_838': 40.6862,
    '1201_839': 40.6731,
    '1201_840': 40.6721,
    '1201_841': 40.6758,
    '1201_842': 40.6757,
    '1201_843': 40.6654,
    '1201_844': 40.6614,
    '1201_845': 40.6821,
    '1201_846': 40.7265,
    '1201_847': 40.7869,
    '1201_848': 40.856,
    '1201_849': 40.9327,
    '1201_850': 41.0141,
    '1201_851': 41.0999,
    '1201_852': 41.1857,
    '1201_853': 41.2636,
    '1201_854': 41.327,
    '1201_855': 41.3703,
    '1201_856': 41.3956,
    '1201_857': 41.4151,
    '1201_858': 41.4328,
    '1201_859': 41.4507,
    '1201_860': 41.4762,
    '1201_861': 41.4782,
    '1201_862': 41.4344,
    '1201_863': 41.3484,
    '1201_864': 41.2387,
    '1201_865': 41.1135,
    '1201_866': 40.9644,
    '1201_867': 40.787,
    '1201_868': 40.5895,
    '1201_869': 40.3811,
    '1201_870': 40.1641,
    '1201_871': 39.9348,
    '1201_872': 39.6979,
    '1201_873': 39.4637,
    '1201_874': 39.2231,
    '1201_875': 38.9753,
    '1201_876': 38.7243,
    '1201_877': 38.4715,
    '1201_878': 38.2069,
    '1201_879': 37.927,
    '1201_880': 37.6288,
    '1201_881': 37.3014,
    '1201_882': 36.9747,
    '1201_883': 36.6473,
    '1201_884': 36.3175,
    '1201_885': 35.986,
    '1201_886': 35.6525,
    '1201_887': 35.3162,
    '1201_888': 34.9725,
    '1201_889': 34.6194,
    '1201_890': 34.2607,
    '1201_891': 33.8979,
    '1201_892': 33.5297,
    '1201_893': 33.1555,
    '1201_894': 32.779,
    '1201_895': 32.4034,
    '1201_896': 32.0319,
    '1201_897': 31.66,
    '1201_898': 31.2825,
    '1201_899': 30.9065,
    '1201_900': 30.536,
    '1202_782': 36.1201,
    '1202_783': 36.2744,
    '1202_784': 36.4439,
    '1202_785': 36.6039,
    '1202_786': 36.7363,
    '1202_787': 36.8453,
    '1202_788': 36.9333,
    '1202_789': 37.0051,
    '1202_790': 37.0651,
    '1202_791': 37.1168,
    '1202_792': 37.1633,
    '1202_793': 37.2076,
    '1202_794': 37.2533,
    '1202_795': 37.3041,
    '1202_796': 37.3635,
    '1202_797': 37.4365,
    '1202_798': 37.5286,
    '1202_799': 37.6406,
    '1202_800': 37.7591,
    '1202_801': 37.871,
    '1202_802': 37.9796,
    '1202_803': 38.0951,
    '1202_804': 38.2072,
    '1202_805': 38.3155,
    '1202_806': 38.4256,
    '1202_807': 38.536,
    '1202_808': 38.6597,
    '1202_809': 38.7847,
    '1202_810': 38.89,
    '1202_811': 38.9794,
    '1202_812': 39.0747,
    '1202_813': 39.1669,
    '1202_814': 39.2603,
    '1202_815': 39.3471,
    '1202_816': 39.4326,
    '1202_817': 39.5276,
    '1202_818': 39.6231,
    '1202_819': 39.725,
    '1202_820': 39.8278,
    '1202_821': 39.9247,
    '1202_822': 40.0079,
    '1202_823': 40.0707,
    '1202_824': 40.1068,
    '1202_825': 40.1427,
    '1202_826': 40.1974,
    '1202_827': 40.2715,
    '1202_828': 40.349,
    '1202_829': 40.4244,
    '1202_830': 40.4795,
    '1202_831': 40.5018,
    '1202_832': 40.5056,
    '1202_833': 40.524,
    '1202_834': 40.5583,
    '1202_835': 40.585,
    '1202_836': 40.6044,
    '1202_837': 40.6141,
    '1202_838': 40.6094,
    '1202_839': 40.6042,
    '1202_840': 40.6123,
    '1202_841': 40.6256,
    '1202_842': 40.6319,
    '1202_843': 40.629,
    '1202_844': 40.633,
    '1202_845': 40.6547,
    '1202_846': 40.6976,
    '1202_847': 40.7533,
    '1202_848': 40.8192,
    '1202_849': 40.8914,
    '1202_850': 40.9653,
    '1202_851': 41.0429,
    '1202_852': 41.1224,
    '1202_853': 41.1999,
    '1202_854': 41.2645,
    '1202_855': 41.3047,
    '1202_856': 41.3236,
    '1202_857': 41.3388,
    '1202_858': 41.357,
    '1202_859': 41.3845,
    '1202_860': 41.4149,
    '1202_861': 41.4113,
    '1202_862': 41.3569,
    '1202_863': 41.2663,
    '1202_864': 41.1565,
    '1202_865': 41.029,
    '1202_866': 40.8776,
    '1202_867': 40.699,
    '1202_868': 40.5105,
    '1202_869': 40.3108,
    '1202_870': 40.0998,
    '1202_871': 39.8706,
    '1202_872': 39.6276,
    '1202_873': 39.3862,
    '1202_874': 39.1485,
    '1202_875': 38.9036,
    '1202_876': 38.6514,
    '1202_877': 38.3991,
    '1202_878': 38.1392,
    '1202_879': 37.8622,
    '1202_880': 37.5612,
    '1202_881': 37.2346,
    '1202_882': 36.9065,
    '1202_883': 36.579,
    '1202_884': 36.2497,
    '1202_885': 35.9172,
    '1202_886': 35.5822,
    '1202_887': 35.2463,
    '1202_888': 34.9047,
    '1202_889': 34.5519,
    '1202_890': 34.1932,
    '1202_891': 33.8301,
    '1202_892': 33.4622,
    '1202_893': 33.0891,
    '1202_894': 32.7135,
    '1202_895': 32.3382,
    '1202_896': 31.968,
    '1202_897': 31.5976,
    '1202_898': 31.2212,
    '1202_899': 30.8457,
    '1202_900': 30.4759,
    '1203_782': 36.0774,
    '1203_783': 36.2349,
    '1203_784': 36.4019,
    '1203_785': 36.5608,
    '1203_786': 36.6876,
    '1203_787': 36.793,
    '1203_788': 36.8803,
    '1203_789': 36.952,
    '1203_790': 37.0132,
    '1203_791': 37.0671,
    '1203_792': 37.1166,
    '1203_793': 37.1642,
    '1203_794': 37.2126,
    '1203_795': 37.2649,
    '1203_796': 37.3243,
    '1203_797': 37.3951,
    '1203_798': 37.4849,
    '1203_799': 37.5946,
    '1203_800': 37.7126,
    '1203_801': 37.8209,
    '1203_802': 37.9318,
    '1203_803': 38.0466,
    '1203_804': 38.1594,
    '1203_805': 38.2681,
    '1203_806': 38.3731,
    '1203_807': 38.4824,
    '1203_808': 38.6026,
    '1203_809': 38.7238,
    '1203_810': 38.8211,
    '1203_811': 38.9078,
    '1203_812': 38.994,
    '1203_813': 39.0879,
    '1203_814': 39.1816,
    '1203_815': 39.2735,
    '1203_816': 39.363,
    '1203_817': 39.4567,
    '1203_818': 39.558,
    '1203_819': 39.6581,
    '1203_820': 39.7514,
    '1203_821': 39.8464,
    '1203_822': 39.9321,
    '1203_823': 40.0025,
    '1203_824': 40.0482,
    '1203_825': 40.0843,
    '1203_826': 40.1369,
    '1203_827': 40.2021,
    '1203_828': 40.2704,
    '1203_829': 40.3363,
    '1203_830': 40.3838,
    '1203_831': 40.3991,
    '1203_832': 40.4055,
    '1203_833': 40.4319,
    '1203_834': 40.4721,
    '1203_835': 40.5008,
    '1203_836': 40.5255,
    '1203_837': 40.5403,
    '1203_838': 40.5395,
    '1203_839': 40.5403,
    '1203_840': 40.5484,
    '1203_841': 40.5592,
    '1203_842': 40.5717,
    '1203_843': 40.5841,
    '1203_844': 40.602,
    '1203_845': 40.628,
    '1203_846': 40.6705,
    '1203_847': 40.7242,
    '1203_848': 40.7827,
    '1203_849': 40.8462,
    '1203_850': 40.913,
    '1203_851': 40.9833,
    '1203_852': 41.056,
    '1203_853': 41.128,
    '1203_854': 41.1891,
    '1203_855': 41.2276,
    '1203_856': 41.2423,
    '1203_857': 41.2583,
    '1203_858': 41.2826,
    '1203_859': 41.3127,
    '1203_860': 41.3389,
    '1203_861': 41.3219,
    '1203_862': 41.2602,
    '1203_863': 41.1674,
    '1203_864': 41.0555,
    '1203_865': 40.9245,
    '1203_866': 40.7705,
    '1203_867': 40.5971,
    '1203_868': 40.4159,
    '1203_869': 40.2278,
    '1203_870': 40.0225,
    '1203_871': 39.7944,
    '1203_872': 39.5518,
    '1203_873': 39.307,
    '1203_874': 39.0667,
    '1203_875': 38.8247,
    '1203_876': 38.5819,
    '1203_877': 38.3339,
    '1203_878': 38.0767,
    '1203_879': 37.8009,
    '1203_880': 37.4959,
    '1203_881': 37.1706,
    '1203_882': 36.8383,
    '1203_883': 36.5105,
    '1203_884': 36.1813,
    '1203_885': 35.8478,
    '1203_886': 35.5116,
    '1203_887': 35.1736,
    '1203_888': 34.83,
    '1203_889': 34.4786,
    '1203_890': 34.12,
    '1203_891': 33.7573,
    '1203_892': 33.3905,
    '1203_893': 33.0171,
    '1203_894': 32.6423,
    '1203_895': 32.2676,
    '1203_896': 31.8967,
    '1203_897': 31.5262,
    '1203_898': 31.152,
    '1203_899': 30.7793,
    '1203_900': 30.4122,
    '1204_782': 36.0413,
    '1204_783': 36.1967,
    '1204_784': 36.3585,
    '1204_785': 36.5106,
    '1204_786': 36.6368,
    '1204_787': 36.7412,
    '1204_788': 36.8275,
    '1204_789': 36.9,
    '1204_790': 36.9628,
    '1204_791': 37.019,
    '1204_792': 37.0712,
    '1204_793': 37.1217,
    '1204_794': 37.1726,
    '1204_795': 37.2266,
    '1204_796': 37.2865,
    '1204_797': 37.3559,
    '1204_798': 37.4408,
    '1204_799': 37.5447,
    '1204_800': 37.66,
    '1204_801': 37.7727,
    '1204_802': 37.8849,
    '1204_803': 37.9985,
    '1204_804': 38.1125,
    '1204_805': 38.2193,
    '1204_806': 38.3219,
    '1204_807': 38.431,
    '1204_808': 38.5476,
    '1204_809': 38.6625,
    '1204_810': 38.7558,
    '1204_811': 38.8401,
    '1204_812': 38.9225,
    '1204_813': 39.0141,
    '1204_814': 39.1121,
    '1204_815': 39.2082,
    '1204_816': 39.298,
    '1204_817': 39.3949,
    '1204_818': 39.5011,
    '1204_819': 39.5988,
    '1204_820': 39.6898,
    '1204_821': 39.7827,
    '1204_822': 39.8714,
    '1204_823': 39.9503,
    '1204_824': 40.0063,
    '1204_825': 40.0427,
    '1204_826': 40.0863,
    '1204_827': 40.1387,
    '1204_828': 40.1954,
    '1204_829': 40.249,
    '1204_830': 40.2833,
    '1204_831': 40.2917,
    '1204_832': 40.3116,
    '1204_833': 40.3544,
    '1204_834': 40.3985,
    '1204_835': 40.4276,
    '1204_836': 40.4497,
    '1204_837': 40.4669,
    '1204_838': 40.473,
    '1204_839': 40.4771,
    '1204_840': 40.4826,
    '1204_841': 40.4896,
    '1204_842': 40.5041,
    '1204_843': 40.5261,
    '1204_844': 40.5596,
    '1204_845': 40.5966,
    '1204_846': 40.6418,
    '1204_847': 40.6926,
    '1204_848': 40.7434,
    '1204_849': 40.7964,
    '1204_850': 40.8552,
    '1204_851': 40.9164,
    '1204_852': 40.9824,
    '1204_853': 41.0479,
    '1204_854': 41.1043,
    '1204_855': 41.14,
    '1204_856': 41.156,
    '1204_857': 41.1758,
    '1204_858': 41.2018,
    '1204_859': 41.2317,
    '1204_860': 41.248,
    '1204_861': 41.2212,
    '1204_862': 41.1523,
    '1204_863': 41.0561,
    '1204_864': 40.9415,
    '1204_865': 40.8108,
    '1204_866': 40.6621,
    '1204_867': 40.4935,
    '1204_868': 40.3169,
    '1204_869': 40.1323,
    '1204_870': 39.9312,
    '1204_871': 39.7034,
    '1204_872': 39.4686,
    '1204_873': 39.2248,
    '1204_874': 38.9841,
    '1204_875': 38.7493,
    '1204_876': 38.512,
    '1204_877': 38.2693,
    '1204_878': 38.0137,
    '1204_879': 37.7377,
    '1204_880': 37.4296,
    '1204_881': 37.1031,
    '1204_882': 36.7694,
    '1204_883': 36.4412,
    '1204_884': 36.1112,
    '1204_885': 35.7746,
    '1204_886': 35.4349,
    '1204_887': 35.0933,
    '1204_888': 34.7487,
    '1204_889': 34.3982,
    '1204_890': 34.0381,
    '1204_891': 33.6743,
    '1204_892': 33.3071,
    '1204_893': 32.9342,
    '1204_894': 32.5607,
    '1204_895': 32.1878,
    '1204_896': 31.8175,
    '1204_897': 31.447,
    '1204_898': 31.0751,
    '1204_899': 30.7053,
    '1204_900': 30.341,
    '1205_782': 36.009,
    '1205_783': 36.1617,
    '1205_784': 36.3205,
    '1205_785': 36.4692,
    '1205_786': 36.5924,
    '1205_787': 36.6955,
    '1205_788': 36.7804,
    '1205_789': 36.8527,
    '1205_790': 36.9162,
    '1205_791': 36.974,
    '1205_792': 37.0288,
    '1205_793': 37.0819,
    '1205_794': 37.1353,
    '1205_795': 37.1908,
    '1205_796': 37.251,
    '1205_797': 37.3192,
    '1205_798': 37.3993,
    '1205_799': 37.4964,
    '1205_800': 37.6074,
    '1205_801': 37.7241,
    '1205_802': 37.8399,
    '1205_803': 37.9509,
    '1205_804': 38.0634,
    '1205_805': 38.1706,
    '1205_806': 38.2761,
    '1205_807': 38.3833,
    '1205_808': 38.4957,
    '1205_809': 38.6058,
    '1205_810': 38.6958,
    '1205_811': 38.7778,
    '1205_812': 38.8599,
    '1205_813': 38.9499,
    '1205_814': 39.0481,
    '1205_815': 39.1436,
    '1205_816': 39.231,
    '1205_817': 39.3275,
    '1205_818': 39.4309,
    '1205_819': 39.5278,
    '1205_820': 39.6239,
    '1205_821': 39.7177,
    '1205_822': 39.812,
    '1205_823': 39.8963,
    '1205_824': 39.9593,
    '1205_825': 39.998,
    '1205_826': 40.0327,
    '1205_827': 40.0695,
    '1205_828': 40.1173,
    '1205_829': 40.1611,
    '1205_830': 40.1804,
    '1205_831': 40.1828,
    '1205_832': 40.2218,
    '1205_833': 40.2831,
    '1205_834': 40.3244,
    '1205_835': 40.3497,
    '1205_836': 40.371,
    '1205_837': 40.393,
    '1205_838': 40.4043,
    '1205_839': 40.4141,
    '1205_840': 40.4186,
    '1205_841': 40.4225,
    '1205_842': 40.4334,
    '1205_843': 40.4601,
    '1205_844': 40.5024,
    '1205_845': 40.5491,
    '1205_846': 40.601,
    '1205_847': 40.6496,
    '1205_848': 40.6913,
    '1205_849': 40.736,
    '1205_850': 40.7882,
    '1205_851': 40.8439,
    '1205_852': 40.899,
    '1205_853': 40.9609,
    '1205_854': 41.0129,
    '1205_855': 41.0474,
    '1205_856': 41.0651,
    '1205_857': 41.0866,
    '1205_858': 41.1135,
    '1205_859': 41.1414,
    '1205_860': 41.149,
    '1205_861': 41.1151,
    '1205_862': 41.0396,
    '1205_863': 40.9394,
    '1205_864': 40.8235,
    '1205_865': 40.697,
    '1205_866': 40.5545,
    '1205_867': 40.389,
    '1205_868': 40.2133,
    '1205_869': 40.0305,
    '1205_870': 39.8299,
    '1205_871': 39.6027,
    '1205_872': 39.3724,
    '1205_873': 39.136,
    '1205_874': 38.9046,
    '1205_875': 38.6759,
    '1205_876': 38.4452,
    '1205_877': 38.2047,
    '1205_878': 37.9485,
    '1205_879': 37.6698,
    '1205_880': 37.3598,
    '1205_881': 37.0335,
    '1205_882': 36.6995,
    '1205_883': 36.3699,
    '1205_884': 36.0389,
    '1205_885': 35.6954,
    '1205_886': 35.3521,
    '1205_887': 35.0077,
    '1205_888': 34.66,
    '1205_889': 34.3084,
    '1205_890': 33.9463,
    '1205_891': 33.5814,
    '1205_892': 33.2113,
    '1205_893': 32.8416,
    '1205_894': 32.4723,
    '1205_895': 32.1036,
    '1205_896': 31.7338,
    '1205_897': 31.3638,
    '1205_898': 30.9921,
    '1205_899': 30.6234,
    '1205_900': 30.2606,
    '1206_782': 35.9865,
    '1206_783': 36.1365,
    '1206_784': 36.2897,
    '1206_785': 36.4333,
    '1206_786': 36.5527,
    '1206_787': 36.6527,
    '1206_788': 36.7368,
    '1206_789': 36.8093,
    '1206_790': 36.8728,
    '1206_791': 36.9322,
    '1206_792': 36.9895,
    '1206_793': 37.0454,
    '1206_794': 37.101,
    '1206_795': 37.1579,
    '1206_796': 37.2184,
    '1206_797': 37.2853,
    '1206_798': 37.3617,
    '1206_799': 37.4529,
    '1206_800': 37.5606,
    '1206_801': 37.6784,
    '1206_802': 37.7945,
    '1206_803': 37.9047,
    '1206_804': 38.019,
    '1206_805': 38.1257,
    '1206_806': 38.2305,
    '1206_807': 38.3379,
    '1206_808': 38.4462,
    '1206_809': 38.5556,
    '1206_810': 38.6455,
    '1206_811': 38.7216,
    '1206_812': 38.8018,
    '1206_813': 38.8907,
    '1206_814': 38.9854,
    '1206_815': 39.0745,
    '1206_816': 39.1599,
    '1206_817': 39.2536,
    '1206_818': 39.3521,
    '1206_819': 39.4498,
    '1206_820': 39.5485,
    '1206_821': 39.6459,
    '1206_822': 39.7427,
    '1206_823': 39.831,
    '1206_824': 39.8963,
    '1206_825': 39.9335,
    '1206_826': 39.9657,
    '1206_827': 39.9956,
    '1206_828': 40.0418,
    '1206_829': 40.0747,
    '1206_830': 40.0795,
    '1206_831': 40.0811,
    '1206_832': 40.1331,
    '1206_833': 40.2022,
    '1206_834': 40.2451,
    '1206_835': 40.2712,
    '1206_836': 40.296,
    '1206_837': 40.3237,
    '1206_838': 40.339,
    '1206_839': 40.3495,
    '1206_840': 40.3538,
    '1206_841': 40.3552,
    '1206_842': 40.3642,
    '1206_843': 40.3904,
    '1206_844': 40.4342,
    '1206_845': 40.4868,
    '1206_846': 40.5393,
    '1206_847': 40.5833,
    '1206_848': 40.6221,
    '1206_849': 40.6631,
    '1206_850': 40.7083,
    '1206_851': 40.7568,
    '1206_852': 40.8089,
    '1206_853': 40.865,
    '1206_854': 40.9154,
    '1206_855': 40.9507,
    '1206_856': 40.9732,
    '1206_857': 40.9957,
    '1206_858': 41.0161,
    '1206_859': 41.0374,
    '1206_860': 41.0394,
    '1206_861': 41.0025,
    '1206_862': 40.9259,
    '1206_863': 40.8271,
    '1206_864': 40.7116,
    '1206_865': 40.5933,
    '1206_866': 40.4538,
    '1206_867': 40.2867,
    '1206_868': 40.1084,
    '1206_869': 39.9265,
    '1206_870': 39.7225,
    '1206_871': 39.4993,
    '1206_872': 39.2711,
    '1206_873': 39.0472,
    '1206_874': 38.8267,
    '1206_875': 38.6068,
    '1206_876': 38.3797,
    '1206_877': 38.1401,
    '1206_878': 37.8826,
    '1206_879': 37.6002,
    '1206_880': 37.2855,
    '1206_881': 36.9578,
    '1206_882': 36.6201,
    '1206_883': 36.2879,
    '1206_884': 35.9525,
    '1206_885': 35.6089,
    '1206_886': 35.2616,
    '1206_887': 34.9121,
    '1206_888': 34.5607,
    '1206_889': 34.2066,
    '1206_890': 33.8475,
    '1206_891': 33.4844,
    '1206_892': 33.112,
    '1206_893': 32.7439,
    '1206_894': 32.3778,
    '1206_895': 32.0117,
    '1206_896': 31.6426,
    '1206_897': 31.2726,
    '1206_898': 30.9021,
    '1206_899': 30.5343,
    '1206_900': 30.1725,
    '1207_790': 36.8327,
    '1207_791': 36.8943,
    '1207_792': 36.9545,
    '1207_793': 37.0131,
    '1207_794': 37.0707,
    '1207_795': 37.1287,
    '1207_796': 37.1891,
    '1207_797': 37.2545,
    '1207_798': 37.3277,
    '1207_799': 37.4139,
    '1207_800': 37.5173,
    '1207_801': 37.6345,
    '1207_802': 37.7498,
    '1207_803': 37.8618,
    '1207_804': 37.9792,
    '1207_805': 38.0881,
    '1207_806': 38.1919,
    '1207_807': 38.2975,
    '1207_808': 38.405,
    '1207_809': 38.5143,
    '1207_810': 38.6069,
    '1207_811': 38.6754,
    '1207_812': 38.7498,
    '1207_813': 38.8357,
    '1207_814': 38.9209,
    '1207_815': 39.0029,
    '1207_816': 39.0899,
    '1207_817': 39.1822,
    '1207_818': 39.2787,
    '1207_819': 39.3815,
    '1207_820': 39.4776,
    '1207_821': 39.5736,
    '1207_822': 39.6707,
    '1207_823': 39.7585,
    '1207_824': 39.8189,
    '1207_825': 39.8493,
    '1207_826': 39.8818,
    '1207_827': 39.9134,
    '1207_828': 39.9598,
    '1207_829': 39.9898,
    '1207_830': 39.9895,
    '1207_831': 39.9916,
    '1207_832': 40.0427,
    '1207_833': 40.1163,
    '1207_834': 40.1608,
    '1207_835': 40.1945,
    '1207_836': 40.2285,
    '1207_837': 40.258,
    '1207_838': 40.2717,
    '1207_839': 40.2836,
    '1207_840': 40.291,
    '1207_841': 40.2944,
    '1207_842': 40.3019,
    '1207_843': 40.3232,
    '1207_844': 40.3588,
    '1207_845': 40.4059,
    '1207_846': 40.4546,
    '1207_847': 40.498,
    '1207_848': 40.5386,
    '1207_849': 40.5803,
    '1207_850': 40.6227,
    '1207_851': 40.665,
    '1207_852': 40.7133,
    '1207_853': 40.7645,
    '1207_854': 40.8157,
    '1207_855': 40.8526,
    '1207_856': 40.8818,
    '1207_857': 40.899,
    '1207_858': 40.9108,
    '1207_859': 40.9234,
    '1207_860': 40.9185,
    '1207_861': 40.8799,
    '1207_862': 40.8065,
    '1207_863': 40.7114,
    '1207_864': 40.6012,
    '1207_865': 40.4884,
    '1207_866': 40.349,
    '1207_867': 40.1831,
    '1207_868': 39.9994,
    '1207_869': 39.8167,
    '1207_870': 39.6106,
    '1207_871': 39.392,
    '1207_872': 39.176,
    '1207_873': 38.967,
    '1207_874': 38.7591,
    '1207_875': 38.5422,
    '1207_876': 38.3156,
    '1207_877': 38.0754,
    '1207_878': 37.8153,
    '1207_879': 37.5269,
    '1207_880': 37.2075,
    '1207_881': 36.8772,
    '1207_882': 36.5408,
    '1207_883': 36.2039,
    '1207_884': 35.864,
    '1207_885': 35.5183,
    '1207_886': 35.1674,
    '1207_887': 34.8123,
    '1207_888': 34.4571,
    '1207_889': 34.0994,
    '1207_890': 33.7429,
    '1207_891': 33.3808,
    '1207_892': 33.0119,
    '1207_893': 32.6435,
    '1207_894': 32.279,
    '1207_895': 31.9145,
    '1207_896': 31.5462,
    '1207_897': 31.1766,
    '1207_898': 30.8083,
    '1207_899': 30.4423,
    '1207_900': 30.0812,
    '1208_790': 36.7998,
    '1208_791': 36.8622,
    '1208_792': 36.925,
    '1208_793': 36.9858,
    '1208_794': 37.0448,
    '1208_795': 37.1033,
    '1208_796': 37.1633,
    '1208_797': 37.2269,
    '1208_798': 37.2974,
    '1208_799': 37.3791,
    '1208_800': 37.4784,
    '1208_801': 37.5908,
    '1208_802': 37.7046,
    '1208_803': 37.8174,
    '1208_804': 37.9373,
    '1208_805': 38.0565,
    '1208_806': 38.1592,
    '1208_807': 38.2617,
    '1208_808': 38.3659,
    '1208_809': 38.4733,
    '1208_810': 38.5688,
    '1208_811': 38.6351,
    '1208_812': 38.7022,
    '1208_813': 38.7799,
    '1208_814': 38.8569,
    '1208_815': 38.9365,
    '1208_816': 39.0237,
    '1208_817': 39.1167,
    '1208_818': 39.2122,
    '1208_819': 39.3121,
    '1208_820': 39.4037,
    '1208_821': 39.4969,
    '1208_822': 39.5927,
    '1208_823': 39.6729,
    '1208_824': 39.726,
    '1208_825': 39.758,
    '1208_826': 39.7862,
    '1208_827': 39.8304,
    '1208_828': 39.877,
    '1208_829': 39.9091,
    '1208_830': 39.9172,
    '1208_831': 39.9162,
    '1208_832': 39.9586,
    '1208_833': 40.0432,
    '1208_834': 40.1079,
    '1208_835': 40.1475,
    '1208_836': 40.1763,
    '1208_837': 40.195,
    '1208_838': 40.2072,
    '1208_839': 40.2227,
    '1208_840': 40.2381,
    '1208_841': 40.2462,
    '1208_842': 40.2554,
    '1208_843': 40.2672,
    '1208_844': 40.2859,
    '1208_845': 40.3172,
    '1208_846': 40.3587,
    '1208_847': 40.4011,
    '1208_848': 40.4431,
    '1208_849': 40.487,
    '1208_850': 40.5287,
    '1208_851': 40.57,
    '1208_852': 40.6142,
    '1208_853': 40.6669,
    '1208_854': 40.7177,
    '1208_855': 40.7588,
    '1208_856': 40.7868,
    '1208_857': 40.7961,
    '1208_858': 40.805,
    '1208_859': 40.8101,
    '1208_860': 40.7961,
    '1208_861': 40.7524,
    '1208_862': 40.6811,
    '1208_863': 40.5893,
    '1208_864': 40.4865,
    '1208_865': 40.3732,
    '1208_866': 40.234,
    '1208_867': 40.071,
    '1208_868': 39.8833,
    '1208_869': 39.6979,
    '1208_870': 39.4954,
    '1208_871': 39.2892,
    '1208_872': 39.0915,
    '1208_873': 38.9001,
    '1208_874': 38.7011,
    '1208_875': 38.4852,
    '1208_876': 38.2507,
    '1208_877': 38.0074,
    '1208_878': 37.7435,
    '1208_879': 37.446,
    '1208_880': 37.1242,
    '1208_881': 36.7918,
    '1208_882': 36.4559,
    '1208_883': 36.1161,
    '1208_884': 35.773,
    '1208_885': 35.4264,
    '1208_886': 35.0744,
    '1208_887': 34.7171,
    '1208_888': 34.3586,
    '1208_889': 33.9995,
    '1208_890': 33.6423,
    '1208_891': 33.2793,
    '1208_892': 32.9094,
    '1208_893': 32.5409,
    '1208_894': 32.1774,
    '1208_895': 31.8138,
    '1208_896': 31.4471,
    '1208_897': 31.0779,
    '1208_898': 30.7134,
    '1208_899': 30.35,
    '1208_900': 29.9903,
    '1209_790': 36.7764,
    '1209_791': 36.8394,
    '1209_792': 36.9032,
    '1209_793': 36.9646,
    '1209_794': 37.0237,
    '1209_795': 37.0819,
    '1209_796': 37.1412,
    '1209_797': 37.2034,
    '1209_798': 37.2713,
    '1209_799': 37.3493,
    '1209_800': 37.442,
    '1209_801': 37.5485,
    '1209_802': 37.6605,
    '1209_803': 37.7763,
    '1209_804': 37.9014,
    '1209_805': 38.0281,
    '1209_806': 38.1301,
    '1209_807': 38.2306,
    '1209_808': 38.3301,
    '1209_809': 38.4314,
    '1209_810': 38.5265,
    '1209_811': 38.5949,
    '1209_812': 38.6542,
    '1209_813': 38.7248,
    '1209_814': 38.8008,
    '1209_815': 38.8815,
    '1209_816': 38.9673,
    '1209_817': 39.0582,
    '1209_818': 39.1535,
    '1209_819': 39.2516,
    '1209_820': 39.3384,
    '1209_821': 39.42,
    '1209_822': 39.5009,
    '1209_823': 39.5685,
    '1209_824': 39.6189,
    '1209_825': 39.6593,
    '1209_826': 39.7029,
    '1209_827': 39.755,
    '1209_828': 39.798,
    '1209_829': 39.8266,
    '1209_830': 39.8441,
    '1209_831': 39.8476,
    '1209_832': 39.8852,
    '1209_833': 39.9785,
    '1209_834': 40.0588,
    '1209_835': 40.1075,
    '1209_836': 40.1318,
    '1209_837': 40.1423,
    '1209_838': 40.1565,
    '1209_839': 40.1777,
    '1209_840': 40.2004,
    '1209_841': 40.2137,
    '1209_842': 40.2183,
    '1209_843': 40.217,
    '1209_844': 40.2206,
    '1209_845': 40.2339,
    '1209_846': 40.2646,
    '1209_847': 40.301,
    '1209_848': 40.3422,
    '1209_849': 40.3859,
    '1209_850': 40.4279,
    '1209_851': 40.4714,
    '1209_852': 40.5136,
    '1209_853': 40.563,
    '1209_854': 40.6158,
    '1209_855': 40.6615,
    '1209_856': 40.6869,
    '1209_857': 40.6898,
    '1209_858': 40.6979,
    '1209_859': 40.699,
    '1209_860': 40.6771,
    '1209_861': 40.6301,
    '1209_862': 40.5613,
    '1209_863': 40.469,
    '1209_864': 40.3665,
    '1209_865': 40.2529,
    '1209_866': 40.1162,
    '1209_867': 39.9533,
    '1209_868': 39.7684,
    '1209_869': 39.5743,
    '1209_870': 39.3774,
    '1209_871': 39.1899,
    '1209_872': 39.0097,
    '1209_873': 38.8325,
    '1209_874': 38.6406,
    '1209_875': 38.4189,
    '1209_876': 38.1798,
    '1209_877': 37.9304,
    '1209_878': 37.6597,
    '1209_879': 37.3597,
    '1209_880': 37.0419,
    '1209_881': 36.7053,
    '1209_882': 36.3687,
    '1209_883': 36.0254,
    '1209_884': 35.6795,
    '1209_885': 35.3346,
    '1209_886': 34.9842,
    '1209_887': 34.6274,
    '1209_888': 34.264,
    '1209_889': 33.9028,
    '1209_890': 33.5473,
    '1209_891': 33.1837,
    '1209_892': 32.813,
    '1209_893': 32.4394,
    '1209_894': 32.0756,
    '1209_895': 31.7134,
    '1209_896': 31.3504,
    '1209_897': 30.9851,
    '1209_898': 30.6208,
    '1209_899': 30.2569,
    '1209_900': 29.8949,
    '1210_790': 36.7625,
    '1210_791': 36.8272,
    '1210_792': 36.8905,
    '1210_793': 36.9505,
    '1210_794': 37.0087,
    '1210_795': 37.0661,
    '1210_796': 37.1243,
    '1210_797': 37.1847,
    '1210_798': 37.2501,
    '1210_799': 37.3241,
    '1210_800': 37.411,
    '1210_801': 37.5128,
    '1210_802': 37.6222,
    '1210_803': 37.7413,
    '1210_804': 37.8714,
    '1210_805': 37.9958,
    '1210_806': 38.102,
    '1210_807': 38.2013,
    '1210_808': 38.2945,
    '1210_809': 38.391,
    '1210_810': 38.4859,
    '1210_811': 38.5549,
    '1210_812': 38.6127,
    '1210_813': 38.6814,
    '1210_814': 38.7582,
    '1210_815': 38.8395,
    '1210_816': 38.9255,
    '1210_817': 39.0144,
    '1210_818': 39.1064,
    '1210_819': 39.2004,
    '1210_820': 39.2797,
    '1210_821': 39.3456,
    '1210_822': 39.4076,
    '1210_823': 39.4614,
    '1210_824': 39.5117,
    '1210_825': 39.5657,
    '1210_826': 39.6218,
    '1210_827': 39.6718,
    '1210_828': 39.7116,
    '1210_829': 39.7419,
    '1210_830': 39.7661,
    '1210_831': 39.775,
    '1210_832': 39.8159,
    '1210_833': 39.9113,
    '1210_834': 40.0045,
    '1210_835': 40.0607,
    '1210_836': 40.0882,
    '1210_837': 40.1049,
    '1210_838': 40.1271,
    '1210_839': 40.1485,
    '1210_840': 40.1727,
    '1210_841': 40.1867,
    '1210_842': 40.1855,
    '1210_843': 40.174,
    '1210_844': 40.1605,
    '1210_845': 40.1577,
    '1210_846': 40.1754,
    '1210_847': 40.2048,
    '1210_848': 40.2428,
    '1210_849': 40.2857,
    '1210_850': 40.3271,
    '1210_851': 40.3716,
    '1210_852': 40.4141,
    '1210_853': 40.4587,
    '1210_854': 40.5112,
    '1210_855': 40.5571,
    '1210_856': 40.5814,
    '1210_857': 40.5814,
    '1210_858': 40.5883,
    '1210_859': 40.5885,
    '1210_860': 40.5648,
    '1210_861': 40.5136,
    '1210_862': 40.4447,
    '1210_863': 40.3539,
    '1210_864': 40.25,
    '1210_865': 40.1316,
    '1210_866': 39.995,
    '1210_867': 39.8304,
    '1210_868': 39.6465,
    '1210_869': 39.4529,
    '1210_870': 39.2638,
    '1210_871': 39.0891,
    '1210_872': 38.9253,
    '1210_873': 38.7535,
    '1210_874': 38.5612,
    '1210_875': 38.3419,
    '1210_876': 38.1029,
    '1210_877': 37.8459,
    '1210_878': 37.5656,
    '1210_879': 37.2649,
    '1210_880': 36.9495,
    '1210_881': 36.6233,
    '1210_882': 36.2822,
    '1210_883': 35.9348,
    '1210_884': 35.5963,
    '1210_885': 35.2452,
    '1210_886': 34.8926,
    '1210_887': 34.5359,
    '1210_888': 34.1679,
    '1210_889': 33.8148,
    '1210_890': 33.4555,
    '1210_891': 33.0879,
    '1210_892': 32.7182,
    '1210_893': 32.3454,
    '1210_894': 31.9745,
    '1210_895': 31.6094,
    '1210_896': 31.2494,
    '1210_897': 30.8865,
    '1210_898': 30.5234,
    '1210_899': 30.1616,
    '1210_900': 29.8014,
    '1211_790': 36.7555,
    '1211_791': 36.8204,
    '1211_792': 36.8828,
    '1211_793': 36.9418,
    '1211_794': 36.9995,
    '1211_795': 37.0563,
    '1211_796': 37.1133,
    '1211_797': 37.172,
    '1211_798': 37.2347,
    '1211_799': 37.3051,
    '1211_800': 37.3877,
    '1211_801': 37.4864,
    '1211_802': 37.5952,
    '1211_803': 37.7153,
    '1211_804': 37.8452,
    '1211_805': 37.9704,
    '1211_806': 38.0733,
    '1211_807': 38.1718,
    '1211_808': 38.263,
    '1211_809': 38.3529,
    '1211_810': 38.4477,
    '1211_811': 38.5203,
    '1211_812': 38.5826,
    '1211_813': 38.6513,
    '1211_814': 38.7266,
    '1211_815': 38.8069,
    '1211_816': 38.8895,
    '1211_817': 38.9791,
    '1211_818': 39.0737,
    '1211_819': 39.1614,
    '1211_820': 39.2264,
    '1211_821': 39.2774,
    '1211_822': 39.3197,
    '1211_823': 39.3649,
    '1211_824': 39.4172,
    '1211_825': 39.4785,
    '1211_826': 39.539,
    '1211_827': 39.588,
    '1211_828': 39.6231,
    '1211_829': 39.6573,
    '1211_830': 39.6852,
    '1211_831': 39.6997,
    '1211_832': 39.7439,
    '1211_833': 39.8405,
    '1211_834': 39.9429,
    '1211_835': 40.0086,
    '1211_836': 40.0454,
    '1211_837': 40.0784,
    '1211_838': 40.1067,
    '1211_839': 40.1281,
    '1211_840': 40.152,
    '1211_841': 40.1659,
    '1211_842': 40.1601,
    '1211_843': 40.1385,
    '1211_844': 40.1084,
    '1211_845': 40.091,
    '1211_846': 40.0924,
    '1211_847': 40.1104,
    '1211_848': 40.1458,
    '1211_849': 40.189,
    '1211_850': 40.2319,
    '1211_851': 40.2787,
    '1211_852': 40.3204,
    '1211_853': 40.3645,
    '1211_854': 40.4139,
    '1211_855': 40.4557,
    '1211_856': 40.4753,
    '1211_857': 40.4717,
    '1211_858': 40.4786,
    '1211_859': 40.4803,
    '1211_860': 40.4579,
    '1211_861': 40.4076,
    '1211_862': 40.335,
    '1211_863': 40.2444,
    '1211_864': 40.1399,
    '1211_865': 40.0193,
    '1211_866': 39.8799,
    '1211_867': 39.7136,
    '1211_868': 39.5276,
    '1211_869': 39.3375,
    '1211_870': 39.1604,
    '1211_871': 38.9915,
    '1211_872': 38.8307,
    '1211_873': 38.6585,
    '1211_874': 38.4683,
    '1211_875': 38.2566,
    '1211_876': 38.0203,
    '1211_877': 37.757,
    '1211_878': 37.4701,
    '1211_879': 37.1643,
    '1211_880': 36.8455,
    '1211_881': 36.5145,
    '1211_882': 36.1737,
    '1211_883': 35.8252,
    '1211_884': 35.4873,
    '1211_885': 35.1391,
    '1211_886': 34.7841,
    '1211_887': 34.4205,
    '1211_888': 34.0488,
    '1211_889': 33.696,
    '1211_890': 33.3367,
    '1211_891': 32.9678,
    '1211_892': 32.5965,
    '1211_893': 32.2266,
    '1211_894': 31.8558,
    '1211_895': 31.4898,
    '1211_896': 31.1293,
    '1211_897': 30.7688,
    '1211_898': 30.4062,
    '1211_899': 30.0455,
    '1211_900': 29.6864,
    '1212_790': 36.7503,
    '1212_791': 36.8149,
    '1212_792': 36.8764,
    '1212_793': 36.9352,
    '1212_794': 36.9935,
    '1212_795': 37.0502,
    '1212_796': 37.1062,
    '1212_797': 37.1633,
    '1212_798': 37.2237,
    '1212_799': 37.2914,
    '1212_800': 37.3705,
    '1212_801': 37.4673,
    '1212_802': 37.5767,
    '1212_803': 37.6991,
    '1212_804': 37.8304,
    '1212_805': 37.9485,
    '1212_806': 38.0453,
    '1212_807': 38.1404,
    '1212_808': 38.2298,
    '1212_809': 38.3162,
    '1212_810': 38.4075,
    '1212_811': 38.487,
    '1212_812': 38.5573,
    '1212_813': 38.6251,
    '1212_814': 38.6968,
    '1212_815': 38.7742,
    '1212_816': 38.8559,
    '1212_817': 38.9485,
    '1212_818': 39.0427,
    '1212_819': 39.1255,
    '1212_820': 39.1784,
    '1212_821': 39.2095,
    '1212_822': 39.2444,
    '1212_823': 39.2882,
    '1212_824': 39.3417,
    '1212_825': 39.402,
    '1212_826': 39.4579,
    '1212_827': 39.5052,
    '1212_828': 39.5404,
    '1212_829': 39.5766,
    '1212_830': 39.6043,
    '1212_831': 39.6242,
    '1212_832': 39.6712,
    '1212_833': 39.7684,
    '1212_834': 39.879,
    '1212_835': 39.9563,
    '1212_836': 40.002,
    '1212_837': 40.0457,
    '1212_838': 40.0802,
    '1212_839': 40.1036,
    '1212_840': 40.1258,
    '1212_841': 40.1368,
    '1212_842': 40.1275,
    '1212_843': 40.0978,
    '1212_844': 40.0576,
    '1212_845': 40.0287,
    '1212_846': 40.018,
    '1212_847': 40.0245,
    '1212_848': 40.0514,
    '1212_849': 40.0918,
    '1212_850': 40.1342,
    '1212_851': 40.1825,
    '1212_852': 40.2208,
    '1212_853': 40.2636,
    '1212_854': 40.3087,
    '1212_855': 40.35,
    '1212_856': 40.3694,
    '1212_857': 40.3647,
    '1212_858': 40.3696,
    '1212_859': 40.3705,
    '1212_860': 40.3522,
    '1212_861': 40.3049,
    '1212_862': 40.2321,
    '1212_863': 40.1406,
    '1212_864': 40.0328,
    '1212_865': 39.9113,
    '1212_866': 39.7681,
    '1212_867': 39.5998,
    '1212_868': 39.4136,
    '1212_869': 39.2334,
    '1212_870': 39.0629,
    '1212_871': 38.897,
    '1212_872': 38.7348,
    '1212_873': 38.5628,
    '1212_874': 38.3722,
    '1212_875': 38.1572,
    '1212_876': 37.9234,
    '1212_877': 37.6586,
    '1212_878': 37.3688,
    '1212_879': 37.0592,
    '1212_880': 36.7388,
    '1212_881': 36.4117,
    '1212_882': 36.0667,
    '1212_883': 35.7177,
    '1212_884': 35.3759,
    '1212_885': 35.025,
    '1212_886': 34.6698,
    '1212_887': 34.3071,
    '1212_888': 33.9395,
    '1212_889': 33.5801,
    '1212_890': 33.2154,
    '1212_891': 32.8467,
    '1212_892': 32.4746,
    '1212_893': 32.1024,
    '1212_894': 31.7307,
    '1212_895': 31.3613,
    '1212_896': 31.0003,
    '1212_897': 30.6399,
    '1212_898': 30.278,
    '1212_899': 29.9195,
    '1212_900': 29.5621,
    '1213_790': 36.7451,
    '1213_791': 36.8101,
    '1213_792': 36.8719,
    '1213_793': 36.9318,
    '1213_794': 36.9908,
    '1213_795': 37.0477,
    '1213_796': 37.1029,
    '1213_797': 37.1586,
    '1213_798': 37.2179,
    '1213_799': 37.2839,
    '1213_800': 37.3612,
    '1213_801': 37.4565,
    '1213_802': 37.5674,
    '1213_803': 37.691,
    '1213_804': 37.818,
    '1213_805': 37.9295,
    '1213_806': 38.0227,
    '1213_807': 38.1125,
    '1213_808': 38.2028,
    '1213_809': 38.2908,
    '1213_810': 38.3795,
    '1213_811': 38.4686,
    '1213_812': 38.5386,
    '1213_813': 38.6021,
    '1213_814': 38.6705,
    '1213_815': 38.7444,
    '1213_816': 38.826,
    '1213_817': 38.9185,
    '1213_818': 39.0119,
    '1213_819': 39.0883,
    '1213_820': 39.1201,
    '1213_821': 39.143,
    '1213_822': 39.1823,
    '1213_823': 39.2282,
    '1213_824': 39.2834,
    '1213_825': 39.3428,
    '1213_826': 39.3933,
    '1213_827': 39.4352,
    '1213_828': 39.4711,
    '1213_829': 39.5017,
    '1213_830': 39.5347,
    '1213_831': 39.5603,
    '1213_832': 39.6063,
    '1213_833': 39.6965,
    '1213_834': 39.8098,
    '1213_835': 39.8991,
    '1213_836': 39.9599,
    '1213_837': 40.0079,
    '1213_838': 40.0446,
    '1213_839': 40.0685,
    '1213_840': 40.0841,
    '1213_841': 40.0902,
    '1213_842': 40.0774,
    '1213_843': 40.0433,
    '1213_844': 40.0012,
    '1213_845': 39.9665,
    '1213_846': 39.9449,
    '1213_847': 39.9436,
    '1213_848': 39.9602,
    '1213_849': 39.9933,
    '1213_850': 40.0368,
    '1213_851': 40.0835,
    '1213_852': 40.1184,
    '1213_853': 40.1572,
    '1213_854': 40.1971,
    '1213_855': 40.2385,
    '1213_856': 40.2564,
    '1213_857': 40.2539,
    '1213_858': 40.2583,
    '1213_859': 40.2587,
    '1213_860': 40.2433,
    '1213_861': 40.1996,
    '1213_862': 40.1292,
    '1213_863': 40.0382,
    '1213_864': 39.9277,
    '1213_865': 39.8015,
    '1213_866': 39.6536,
    '1213_867': 39.4847,
    '1213_868': 39.3073,
    '1213_869': 39.1375,
    '1213_870': 38.9707,
    '1213_871': 38.8075,
    '1213_872': 38.6468,
    '1213_873': 38.4717,
    '1213_874': 38.2732,
    '1213_875': 38.0521,
    '1213_876': 37.8133,
    '1213_877': 37.548,
    '1213_878': 37.2566,
    '1213_879': 36.9465,
    '1213_880': 36.619,
    '1213_881': 36.2892,
    '1213_882': 35.9477,
    '1213_883': 35.5967,
    '1213_884': 35.2526,
    '1213_885': 34.9021,
    '1213_886': 34.5459,
    '1213_887': 34.1834,
    '1213_888': 33.8174,
    '1213_889': 33.4533,
    '1213_890': 33.0861,
    '1213_891': 32.7161,
    '1213_892': 32.3436,
    '1213_893': 31.9741,
    '1213_894': 31.6044,
    '1213_895': 31.2354,
    '1213_896': 30.873,
    '1213_897': 30.5127,
    '1213_898': 30.1527,
    '1213_899': 29.795,
    '1213_900': 29.4384,
    '1214_790': 36.7395,
    '1214_791': 36.805,
    '1214_792': 36.8672,
    '1214_793': 36.9281,
    '1214_794': 36.9885,
    '1214_795': 37.0465,
    '1214_796': 37.1012,
    '1214_797': 37.157,
    '1214_798': 37.2165,
    '1214_799': 37.2826,
    '1214_800': 37.3594,
    '1214_801': 37.4541,
    '1214_802': 37.5664,
    '1214_803': 37.6904,
    '1214_804': 37.8145,
    '1214_805': 37.9221,
    '1214_806': 38.0143,
    '1214_807': 38.1021,
    '1214_808': 38.1893,
    '1214_809': 38.2735,
    '1214_810': 38.3582,
    '1214_811': 38.4517,
    '1214_812': 38.5245,
    '1214_813': 38.5867,
    '1214_814': 38.6502,
    '1214_815': 38.7203,
    '1214_816': 38.7984,
    '1214_817': 38.8862,
    '1214_818': 38.9753,
    '1214_819': 39.0405,
    '1214_820': 39.0618,
    '1214_821': 39.0871,
    '1214_822': 39.1289,
    '1214_823': 39.179,
    '1214_824': 39.2454,
    '1214_825': 39.3128,
    '1214_826': 39.3656,
    '1214_827': 39.395,
    '1214_828': 39.4208,
    '1214_829': 39.4491,
    '1214_830': 39.4801,
    '1214_831': 39.5105,
    '1214_832': 39.5529,
    '1214_833': 39.6267,
    '1214_834': 39.7352,
    '1214_835': 39.8354,
    '1214_836': 39.912,
    '1214_837': 39.9675,
    '1214_838': 40.005,
    '1214_839': 40.0222,
    '1214_840': 40.0251,
    '1214_841': 40.0228,
    '1214_842': 40.0069,
    '1214_843': 39.9761,
    '1214_844': 39.9372,
    '1214_845': 39.8997,
    '1214_846': 39.8692,
    '1214_847': 39.8597,
    '1214_848': 39.8664,
    '1214_849': 39.8911,
    '1214_850': 39.932,
    '1214_851': 39.9725,
    '1214_852': 40.0099,
    '1214_853': 40.0495,
    '1214_854': 40.0926,
    '1214_855': 40.1315,
    '1214_856': 40.1437,
    '1214_857': 40.1424,
    '1214_858': 40.1457,
    '1214_859': 40.1434,
    '1214_860': 40.1293,
    '1214_861': 40.0877,
    '1214_862': 40.0189,
    '1214_863': 39.9265,
    '1214_864': 39.815,
    '1214_865': 39.6838,
    '1214_866': 39.533,
    '1214_867': 39.3671,
    '1214_868': 39.2017,
    '1214_869': 39.0411,
    '1214_870': 38.8836,
    '1214_871': 38.7249,
    '1214_872': 38.5643,
    '1214_873': 38.3773,
    '1214_874': 38.1695,
    '1214_875': 37.944,
    '1214_876': 37.7014,
    '1214_877': 37.4344,
    '1214_878': 37.1399,
    '1214_879': 36.8298,
    '1214_880': 36.5035,
    '1214_881': 36.1684,
    '1214_882': 35.8296,
    '1214_883': 35.4767,
    '1214_884': 35.131,
    '1214_885': 34.7786,
    '1214_886': 34.4211,
    '1214_887': 34.057,
    '1214_888': 33.6902,
    '1214_889': 33.3247,
    '1214_890': 32.9559,
    '1214_891': 32.5867,
    '1214_892': 32.2165,
    '1214_893': 31.8486,
    '1214_894': 31.481,
    '1214_895': 31.1135,
    '1214_896': 30.7486,
    '1214_897': 30.3871,
    '1214_898': 30.0282,
    '1214_899': 29.6714,
    '1214_900': 29.3155,
    '1215_790': 36.7332,
    '1215_791': 36.7986,
    '1215_792': 36.8606,
    '1215_793': 36.9212,
    '1215_794': 36.9812,
    '1215_795': 37.0401,
    '1215_796': 37.0979,
    '1215_797': 37.1571,
    '1215_798': 37.2197,
    '1215_799': 37.288,
    '1215_800': 37.3661,
    '1215_801': 37.4617,
    '1215_802': 37.575,
    '1215_803': 37.6989,
    '1215_804': 37.8229,
    '1215_805': 37.9309,
    '1215_806': 38.0204,
    '1215_807': 38.1047,
    '1215_808': 38.1866,
    '1215_809': 38.2651,
    '1215_810': 38.3432,
    '1215_811': 38.4333,
    '1215_812': 38.514,
    '1215_813': 38.5773,
    '1215_814': 38.6406,
    '1215_815': 38.7052,
    '1215_816': 38.7791,
    '1215_817': 38.8603,
    '1215_818': 38.9367,
    '1215_819': 38.9932,
    '1215_820': 39.0153,
    '1215_821': 39.0445,
    '1215_822': 39.0865,
    '1215_823': 39.1407,
    '1215_824': 39.2151,
    '1215_825': 39.2845,
    '1215_826': 39.3398,
    '1215_827': 39.3689,
    '1215_828': 39.3909,
    '1215_829': 39.4151,
    '1215_830': 39.4361,
    '1215_831': 39.4657,
    '1215_832': 39.5076,
    '1215_833': 39.5673,
    '1215_834': 39.6594,
    '1215_835': 39.7671,
    '1215_836': 39.854,
    '1215_837': 39.9167,
    '1215_838': 39.9543,
    '1215_839': 39.9665,
    '1215_840': 39.9576,
    '1215_841': 39.942,
    '1215_842': 39.9207,
    '1215_843': 39.8951,
    '1215_844': 39.8614,
    '1215_845': 39.825,
    '1215_846': 39.7911,
    '1215_847': 39.7708,
    '1215_848': 39.7704,
    '1215_849': 39.7937,
    '1215_850': 39.8315,
    '1215_851': 39.8612,
    '1215_852': 39.8921,
    '1215_853': 39.9382,
    '1215_854': 39.9932,
    '1215_855': 40.0341,
    '1215_856': 40.0382,
    '1215_857': 40.0329,
    '1215_858': 40.0336,
    '1215_859': 40.0267,
    '1215_860': 40.0098,
    '1215_861': 39.9682,
    '1215_862': 39.8971,
    '1215_863': 39.8045,
    '1215_864': 39.6925,
    '1215_865': 39.5607,
    '1215_866': 39.4115,
    '1215_867': 39.2521,
    '1215_868': 39.0984,
    '1215_869': 38.9461,
    '1215_870': 38.7954,
    '1215_871': 38.6385,
    '1215_872': 38.4696,
    '1215_873': 38.2752,
    '1215_874': 38.059,
    '1215_875': 37.8345,
    '1215_876': 37.5879,
    '1215_877': 37.3184,
    '1215_878': 37.0256,
    '1215_879': 36.7152,
    '1215_880': 36.3882,
    '1215_881': 36.0507,
    '1215_882': 35.7062,
    '1215_883': 35.3598,
    '1215_884': 35.0132,
    '1215_885': 34.6605,
    '1215_886': 34.301,
    '1215_887': 33.9364,
    '1215_888': 33.5694,
    '1215_889': 33.2017,
    '1215_890': 32.8314,
    '1215_891': 32.4605,
    '1215_892': 32.0904,
    '1215_893': 31.7236,
    '1215_894': 31.3562,
    '1215_895': 30.9886,
    '1215_896': 30.623,
    '1215_897': 30.2614,
    '1215_898': 29.9037,
    '1215_899': 29.5481,
    '1215_900': 29.1925,
    '1216_790': 36.7262,
    '1216_791': 36.7907,
    '1216_792': 36.8521,
    '1216_793': 36.9122,
    '1216_794': 36.9719,
    '1216_795': 37.0317,
    '1216_796': 37.095,
    '1216_797': 37.1601,
    '1216_798': 37.2281,
    '1216_799': 37.3013,
    '1216_800': 37.383,
    '1216_801': 37.4791,
    '1216_802': 37.5941,
    '1216_803': 37.7192,
    '1216_804': 37.8446,
    '1216_805': 37.9555,
    '1216_806': 38.041,
    '1216_807': 38.1202,
    '1216_808': 38.1968,
    '1216_809': 38.2687,
    '1216_810': 38.3416,
    '1216_811': 38.4259,
    '1216_812': 38.5088,
    '1216_813': 38.5772,
    '1216_814': 38.6371,
    '1216_815': 38.7008,
    '1216_816': 38.7687,
    '1216_817': 38.8374,
    '1216_818': 38.9015,
    '1216_819': 38.9512,
    '1216_820': 38.9855,
    '1216_821': 39.0185,
    '1216_822': 39.0581,
    '1216_823': 39.1099,
    '1216_824': 39.1838,
    '1216_825': 39.2538,
    '1216_826': 39.3063,
    '1216_827': 39.3335,
    '1216_828': 39.3552,
    '1216_829': 39.382,
    '1216_830': 39.4,
    '1216_831': 39.4268,
    '1216_832': 39.4659,
    '1216_833': 39.514,
    '1216_834': 39.5919,
    '1216_835': 39.6978,
    '1216_836': 39.7909,
    '1216_837': 39.8564,
    '1216_838': 39.8936,
    '1216_839': 39.903,
    '1216_840': 39.893,
    '1216_841': 39.8666,
    '1216_842': 39.8322,
    '1216_843': 39.8018,
    '1216_844': 39.7741,
    '1216_845': 39.7416,
    '1216_846': 39.7049,
    '1216_847': 39.6782,
    '1216_848': 39.6744,
    '1216_849': 39.6882,
    '1216_850': 39.7178,
    '1216_851': 39.7481,
    '1216_852': 39.7762,
    '1216_853': 39.8281,
    '1216_854': 39.8917,
    '1216_855': 39.9308,
    '1216_856': 39.929,
    '1216_857': 39.9252,
    '1216_858': 39.9237,
    '1216_859': 39.9097,
    '1216_860': 39.8834,
    '1216_861': 39.8372,
    '1216_862': 39.7689,
    '1216_863': 39.6769,
    '1216_864': 39.5646,
    '1216_865': 39.4342,
    '1216_866': 39.2844,
    '1216_867': 39.1369,
    '1216_868': 38.9946,
    '1216_869': 38.8506,
    '1216_870': 38.6996,
    '1216_871': 38.5373,
    '1216_872': 38.359,
    '1216_873': 38.1576,
    '1216_874': 37.9428,
    '1216_875': 37.72,
    '1216_876': 37.4693,
    '1216_877': 37.1988,
    '1216_878': 36.9063,
    '1216_879': 36.5979,
    '1216_880': 36.2699,
    '1216_881': 35.9333,
    '1216_882': 35.5879,
    '1216_883': 35.2424,
    '1216_884': 34.8954,
    '1216_885': 34.5424,
    '1216_886': 34.1803,
    '1216_887': 33.8151,
    '1216_888': 33.4478,
    '1216_889': 33.0798,
    '1216_890': 32.7076,
    '1216_891': 32.335,
    '1216_892': 31.9645,
    '1216_893': 31.5979,
    '1216_894': 31.2303,
    '1216_895': 30.8625,
    '1216_896': 30.4971,
    '1216_897': 30.1361,
    '1216_898': 29.7807,
    '1216_899': 29.4256,
    '1216_900': 29.0672,
    '1217_790': 36.7171,
    '1217_791': 36.7803,
    '1217_792': 36.8413,
    '1217_793': 36.9022,
    '1217_794': 36.9639,
    '1217_795': 37.0278,
    '1217_796': 37.0959,
    '1217_797': 37.1671,
    '1217_798': 37.2415,
    '1217_799': 37.321,
    '1217_800': 37.4079,
    '1217_801': 37.5078,
    '1217_802': 37.6256,
    '1217_803': 37.7546,
    '1217_804': 37.885,
    '1217_805': 37.9977,
    '1217_806': 38.0779,
    '1217_807': 38.1507,
    '1217_808': 38.2201,
    '1217_809': 38.2862,
    '1217_810': 38.3534,
    '1217_811': 38.4326,
    '1217_812': 38.5165,
    '1217_813': 38.588,
    '1217_814': 38.6426,
    '1217_815': 38.7028,
    '1217_816': 38.7655,
    '1217_817': 38.8238,
    '1217_818': 38.8797,
    '1217_819': 38.9302,
    '1217_820': 38.9743,
    '1217_821': 39.0088,
    '1217_822': 39.0406,
    '1217_823': 39.084,
    '1217_824': 39.145,
    '1217_825': 39.2145,
    '1217_826': 39.2636,
    '1217_827': 39.2916,
    '1217_828': 39.3206,
    '1217_829': 39.3518,
    '1217_830': 39.3717,
    '1217_831': 39.3986,
    '1217_832': 39.431,
    '1217_833': 39.4702,
    '1217_834': 39.5392,
    '1217_835': 39.6367,
    '1217_836': 39.731,
    '1217_837': 39.7955,
    '1217_838': 39.8303,
    '1217_839': 39.8368,
    '1217_840': 39.8248,
    '1217_841': 39.7936,
    '1217_842': 39.75,
    '1217_843': 39.71,
    '1217_844': 39.6775,
    '1217_845': 39.6456,
    '1217_846': 39.6085,
    '1217_847': 39.5821,
    '1217_848': 39.5754,
    '1217_849': 39.5847,
    '1217_850': 39.6021,
    '1217_851': 39.6308,
    '1217_852': 39.6655,
    '1217_853': 39.7209,
    '1217_854': 39.774,
    '1217_855': 39.8081,
    '1217_856': 39.8088,
    '1217_857': 39.8135,
    '1217_858': 39.8121,
    '1217_859': 39.7945,
    '1217_860': 39.759,
    '1217_861': 39.7114,
    '1217_862': 39.6458,
    '1217_863': 39.555,
    '1217_864': 39.4377,
    '1217_865': 39.3011,
    '1217_866': 39.1603,
    '1217_867': 39.022,
    '1217_868': 38.8882,
    '1217_869': 38.748,
    '1217_870': 38.5922,
    '1217_871': 38.4203,
    '1217_872': 38.2334,
    '1217_873': 38.0299,
    '1217_874': 37.8128,
    '1217_875': 37.5856,
    '1217_876': 37.3372,
    '1217_877': 37.0684,
    '1217_878': 36.7783,
    '1217_879': 36.4705,
    '1217_880': 36.145,
    '1217_881': 35.8075,
    '1217_882': 35.4619,
    '1217_883': 35.1185,
    '1217_884': 34.7701,
    '1217_885': 34.4145,
    '1217_886': 34.0523,
    '1217_887': 33.6872,
    '1217_888': 33.3208,
    '1217_889': 32.953,
    '1217_890': 32.5809,
    '1217_891': 32.2073,
    '1217_892': 31.8356,
    '1217_893': 31.469,
    '1217_894': 31.1014,
    '1217_895': 30.7341,
    '1217_896': 30.3706,
    '1217_897': 30.0127,
    '1217_898': 29.6608,
    '1217_899': 29.3092,
    '1217_900': 28.9511,
    '1218_790': 36.7025,
    '1218_791': 36.7654,
    '1218_792': 36.8273,
    '1218_793': 36.8902,
    '1218_794': 36.9552,
    '1218_795': 37.0242,
    '1218_796': 37.0988,
    '1218_797': 37.1774,
    '1218_798': 37.2593,
    '1218_799': 37.3462,
    '1218_800': 37.4398,
    '1218_801': 37.5459,
    '1218_802': 37.6708,
    '1218_803': 37.8057,
    '1218_804': 37.9398,
    '1218_805': 38.0541,
    '1218_806': 38.1348,
    '1218_807': 38.2002,
    '1218_808': 38.2605,
    '1218_809': 38.3182,
    '1218_810': 38.3808,
    '1218_811': 38.4543,
    '1218_812': 38.5378,
    '1218_813': 38.6123,
    '1218_814': 38.6622,
    '1218_815': 38.7118,
    '1218_816': 38.7722,
    '1218_817': 38.8264,
    '1218_818': 38.8797,
    '1218_819': 38.9252,
    '1218_820': 38.9717,
    '1218_821': 39.0089,
    '1218_822': 39.0316,
    '1218_823': 39.0657,
    '1218_824': 39.1188,
    '1218_825': 39.1844,
    '1218_826': 39.2304,
    '1218_827': 39.2639,
    '1218_828': 39.294,
    '1218_829': 39.3245,
    '1218_830': 39.3466,
    '1218_831': 39.3767,
    '1218_832': 39.4067,
    '1218_833': 39.4402,
    '1218_834': 39.498,
    '1218_835': 39.5836,
    '1218_836': 39.6751,
    '1218_837': 39.7396,
    '1218_838': 39.773,
    '1218_839': 39.7785,
    '1218_840': 39.7605,
    '1218_841': 39.7288,
    '1218_842': 39.686,
    '1218_843': 39.6323,
    '1218_844': 39.582,
    '1218_845': 39.5416,
    '1218_846': 39.5072,
    '1218_847': 39.4823,
    '1218_848': 39.4728,
    '1218_849': 39.4748,
    '1218_850': 39.4892,
    '1218_851': 39.5195,
    '1218_852': 39.5578,
    '1218_853': 39.6086,
    '1218_854': 39.6552,
    '1218_855': 39.6786,
    '1218_856': 39.6856,
    '1218_857': 39.6946,
    '1218_858': 39.693,
    '1218_859': 39.6731,
    '1218_860': 39.64,
    '1218_861': 39.5928,
    '1218_862': 39.5292,
    '1218_863': 39.4354,
    '1218_864': 39.3138,
    '1218_865': 39.1768,
    '1218_866': 39.0395,
    '1218_867': 38.9068,
    '1218_868': 38.7759,
    '1218_869': 38.6331,
    '1218_870': 38.4714,
    '1218_871': 38.2919,
    '1218_872': 38.0981,
    '1218_873': 37.896,
    '1218_874': 37.6776,
    '1218_875': 37.4472,
    '1218_876': 37.1994,
    '1218_877': 36.9314,
    '1218_878': 36.6443,
    '1218_879': 36.3388,
    '1218_880': 36.015,
    '1218_881': 35.6788,
    '1218_882': 35.3345,
    '1218_883': 34.9926,
    '1218_884': 34.6418,
    '1218_885': 34.2837,
    '1218_886': 33.9202,
    '1218_887': 33.5556,
    '1218_888': 33.1898,
    '1218_889': 32.8243,
    '1218_890': 32.4524,
    '1218_891': 32.0791,
    '1218_892': 31.7083,
    '1218_893': 31.3409,
    '1218_894': 30.9714,
    '1218_895': 30.6028,
    '1218_896': 30.2393,
    '1218_897': 29.8834,
    '1218_898': 29.5368,
    '1218_899': 29.1919,
    '1218_900': 28.8357,
    '1219_790': 36.6782,
    '1219_791': 36.7441,
    '1219_792': 36.8087,
    '1219_793': 36.8747,
    '1219_794': 36.9444,
    '1219_795': 37.02,
    '1219_796': 37.1028,
    '1219_797': 37.1899,
    '1219_798': 37.2807,
    '1219_799': 37.3761,
    '1219_800': 37.4776,
    '1219_801': 37.5919,
    '1219_802': 37.7261,
    '1219_803': 37.8681,
    '1219_804': 38.0041,
    '1219_805': 38.1155,
    '1219_806': 38.2038,
    '1219_807': 38.269,
    '1219_808': 38.3173,
    '1219_809': 38.3679,
    '1219_810': 38.4267,
    '1219_811': 38.4889,
    '1219_812': 38.5639,
    '1219_813': 38.6362,
    '1219_814': 38.687,
    '1219_815': 38.7309,
    '1219_816': 38.7878,
    '1219_817': 38.8473,
    '1219_818': 38.8911,
    '1219_819': 38.9294,
    '1219_820': 38.9719,
    '1219_821': 39.0059,
    '1219_822': 39.0234,
    '1219_823': 39.0535,
    '1219_824': 39.102,
    '1219_825': 39.1662,
    '1219_826': 39.2125,
    '1219_827': 39.2467,
    '1219_828': 39.2734,
    '1219_829': 39.3014,
    '1219_830': 39.3264,
    '1219_831': 39.3599,
    '1219_832': 39.3903,
    '1219_833': 39.4226,
    '1219_834': 39.4721,
    '1219_835': 39.5509,
    '1219_836': 39.6299,
    '1219_837': 39.6852,
    '1219_838': 39.7138,
    '1219_839': 39.7183,
    '1219_840': 39.7027,
    '1219_841': 39.6742,
    '1219_842': 39.6311,
    '1219_843': 39.5643,
    '1219_844': 39.5,
    '1219_845': 39.4481,
    '1219_846': 39.4053,
    '1219_847': 39.3819,
    '1219_848': 39.3692,
    '1219_849': 39.3671,
    '1219_850': 39.3792,
    '1219_851': 39.4089,
    '1219_852': 39.4507,
    '1219_853': 39.4956,
    '1219_854': 39.5344,
    '1219_855': 39.555,
    '1219_856': 39.564,
    '1219_857': 39.5698,
    '1219_858': 39.5656,
    '1219_859': 39.5497,
    '1219_860': 39.526,
    '1219_861': 39.4842,
    '1219_862': 39.4148,
    '1219_863': 39.3173,
    '1219_864': 39.1947,
    '1219_865': 39.0581,
    '1219_866': 38.9223,
    '1219_867': 38.7896,
    '1219_868': 38.6543,
    '1219_869': 38.5076,
    '1219_870': 38.34,
    '1219_871': 38.152,
    '1219_872': 37.9587,
    '1219_873': 37.7548,
    '1219_874': 37.5427,
    '1219_875': 37.3117,
    '1219_876': 37.0626,
    '1219_877': 36.7951,
    '1219_878': 36.5076,
    '1219_879': 36.2021,
    '1219_880': 35.8791,
    '1219_881': 35.5438,
    '1219_882': 35.2047,
    '1219_883': 34.8619,
    '1219_884': 34.5112,
    '1219_885': 34.1529,
    '1219_886': 33.7896,
    '1219_887': 33.4241,
    '1219_888': 33.0582,
    '1219_889': 32.6918,
    '1219_890': 32.3216,
    '1219_891': 31.9502,
    '1219_892': 31.5798,
    '1219_893': 31.2104,
    '1219_894': 30.8399,
    '1219_895': 30.4697,
    '1219_896': 30.1051,
    '1219_897': 29.7476,
    '1219_898': 29.4018,
    '1219_899': 29.0662,
    '1219_900': 28.705,
    '1220_790': 36.6454,
    '1220_791': 36.7167,
    '1220_792': 36.7849,
    '1220_793': 36.8552,
    '1220_794': 36.9306,
    '1220_795': 37.0151,
    '1220_796': 37.1072,
    '1220_797': 37.2041,
    '1220_798': 37.3048,
    '1220_799': 37.4097,
    '1220_800': 37.5201,
    '1220_801': 37.6435,
    '1220_802': 37.7888,
    '1220_803': 37.9369,
    '1220_804': 38.0685,
    '1220_805': 38.1811,
    '1220_806': 38.2759,
    '1220_807': 38.3471,
    '1220_808': 38.3938,
    '1220_809': 38.4345,
    '1220_810': 38.4802,
    '1220_811': 38.5316,
    '1220_812': 38.5935,
    '1220_813': 38.6564,
    '1220_814': 38.7137,
    '1220_815': 38.7546,
    '1220_816': 38.8028,
    '1220_817': 38.8588,
    '1220_818': 38.903,
    '1220_819': 38.9344,
    '1220_820': 38.977,
    '1220_821': 39.0033,
    '1220_822': 39.0162,
    '1220_823': 39.0425,
    '1220_824': 39.0863,
    '1220_825': 39.1506,
    '1220_826': 39.1992,
    '1220_827': 39.2313,
    '1220_828': 39.2547,
    '1220_829': 39.2803,
    '1220_830': 39.3076,
    '1220_831': 39.3453,
    '1220_832': 39.3817,
    '1220_833': 39.4139,
    '1220_834': 39.4577,
    '1220_835': 39.5233,
    '1220_836': 39.5896,
    '1220_837': 39.6336,
    '1220_838': 39.6556,
    '1220_839': 39.6592,
    '1220_840': 39.6455,
    '1220_841': 39.6189,
    '1220_842': 39.5723,
    '1220_843': 39.504,
    '1220_844': 39.4284,
    '1220_845': 39.3634,
    '1220_846': 39.3132,
    '1220_847': 39.2828,
    '1220_848': 39.267,
    '1220_849': 39.2629,
    '1220_850': 39.2757,
    '1220_851': 39.3019,
    '1220_852': 39.3449,
    '1220_853': 39.3899,
    '1220_854': 39.4182,
    '1220_855': 39.4334,
    '1220_856': 39.4441,
    '1220_857': 39.4472,
    '1220_858': 39.4458,
    '1220_859': 39.4375,
    '1220_860': 39.4207,
    '1220_861': 39.3785,
    '1220_862': 39.3024,
    '1220_863': 39.2047,
    '1220_864': 39.0869,
    '1220_865': 38.9503,
    '1220_866': 38.8082,
    '1220_867': 38.67,
    '1220_868': 38.5282,
    '1220_869': 38.3751,
    '1220_870': 38.2013,
    '1220_871': 38.0088,
    '1220_872': 37.817,
    '1220_873': 37.615,
    '1220_874': 37.4068,
    '1220_875': 37.1741,
    '1220_876': 36.9237,
    '1220_877': 36.6558,
    '1220_878': 36.3685,
    '1220_879': 36.0648,
    '1220_880': 35.7437,
    '1220_881': 35.4122,
    '1220_882': 35.0748,
    '1220_883': 34.7319,
    '1220_884': 34.3812,
    '1220_885': 34.0232,
    '1220_886': 33.6598,
    '1220_887': 33.2941,
    '1220_888': 32.9283,
    '1220_889': 32.5625,
    '1220_890': 32.193,
    '1220_891': 31.8226,
    '1220_892': 31.4523,
    '1220_893': 31.0825,
    '1220_894': 30.7126,
    '1220_895': 30.3415,
    '1220_896': 29.9794,
    '1220_897': 29.624,
    '1220_898': 29.2907,
    '1220_899': 28.9327,
    '1220_900': 28.5779,
    '1221_790': 36.612,
    '1221_791': 36.6836,
    '1221_792': 36.7562,
    '1221_793': 36.8325,
    '1221_794': 36.9158,
    '1221_795': 37.0094,
    '1221_796': 37.112,
    '1221_797': 37.22,
    '1221_798': 37.3318,
    '1221_799': 37.4476,
    '1221_800': 37.5682,
    '1221_801': 37.7017,
    '1221_802': 37.8558,
    '1221_803': 38.0085,
    '1221_804': 38.1356,
    '1221_805': 38.2511,
    '1221_806': 38.3487,
    '1221_807': 38.4226,
    '1221_808': 38.4696,
    '1221_809': 38.5024,
    '1221_810': 38.5342,
    '1221_811': 38.5852,
    '1221_812': 38.637,
    '1221_813': 38.6879,
    '1221_814': 38.7427,
    '1221_815': 38.775,
    '1221_816': 38.8107,
    '1221_817': 38.8561,
    '1221_818': 38.8985,
    '1221_819': 38.9318,
    '1221_820': 38.9796,
    '1221_821': 39.0024,
    '1221_822': 39.0123,
    '1221_823': 39.0342,
    '1221_824': 39.0692,
    '1221_825': 39.1281,
    '1221_826': 39.1753,
    '1221_827': 39.2087,
    '1221_828': 39.2334,
    '1221_829': 39.2581,
    '1221_830': 39.2923,
    '1221_831': 39.3357,
    '1221_832': 39.3778,
    '1221_833': 39.4129,
    '1221_834': 39.4451,
    '1221_835': 39.4947,
    '1221_836': 39.5448,
    '1221_837': 39.5778,
    '1221_838': 39.5959,
    '1221_839': 39.6005,
    '1221_840': 39.5893,
    '1221_841': 39.5622,
    '1221_842': 39.5122,
    '1221_843': 39.4365,
    '1221_844': 39.3572,
    '1221_845': 39.287,
    '1221_846': 39.2325,
    '1221_847': 39.1929,
    '1221_848': 39.1666,
    '1221_849': 39.1563,
    '1221_850': 39.1682,
    '1221_851': 39.1967,
    '1221_852': 39.2387,
    '1221_853': 39.2831,
    '1221_854': 39.3039,
    '1221_855': 39.3154,
    '1221_856': 39.3296,
    '1221_857': 39.334,
    '1221_858': 39.3322,
    '1221_859': 39.3275,
    '1221_860': 39.3124,
    '1221_861': 39.2672,
    '1221_862': 39.1912,
    '1221_863': 39.0992,
    '1221_864': 38.9881,
    '1221_865': 38.8472,
    '1221_866': 38.6918,
    '1221_867': 38.547,
    '1221_868': 38.3976,
    '1221_869': 38.2379,
    '1221_870': 38.0587,
    '1221_871': 37.8665,
    '1221_872': 37.6675,
    '1221_873': 37.4678,
    '1221_874': 37.2571,
    '1221_875': 37.0277,
    '1221_876': 36.7811,
    '1221_877': 36.5118,
    '1221_878': 36.2256,
    '1221_879': 35.9238,
    '1221_880': 35.6055,
    '1221_881': 35.2774,
    '1221_882': 34.9449,
    '1221_883': 34.6034,
    '1221_884': 34.2533,
    '1221_885': 33.8959,
    '1221_886': 33.5335,
    '1221_887': 33.1703,
    '1221_888': 32.807,
    '1221_889': 32.4419,
    '1221_890': 32.0726,
    '1221_891': 31.7004,
    '1221_892': 31.3286,
    '1221_893': 30.959,
    '1221_894': 30.5923,
    '1221_895': 30.2283,
    '1221_896': 29.8709,
    '1221_897': 29.5192,
    '1221_898': 29.1621,
    '1221_899': 28.8107,
    '1221_900': 28.4605,
    '1222_790': 36.5701,
    '1222_791': 36.6451,
    '1222_792': 36.723,
    '1222_793': 36.8051,
    '1222_794': 36.8953,
    '1222_795': 37.0008,
    '1222_796': 37.1158,
    '1222_797': 37.2362,
    '1222_798': 37.3603,
    '1222_799': 37.4877,
    '1222_800': 37.6214,
    '1222_801': 37.7657,
    '1222_802': 37.9222,
    '1222_803': 38.0747,
    '1222_804': 38.2015,
    '1222_805': 38.3165,
    '1222_806': 38.4148,
    '1222_807': 38.4816,
    '1222_808': 38.5259,
    '1222_809': 38.5534,
    '1222_810': 38.5872,
    '1222_811': 38.6429,
    '1222_812': 38.6872,
    '1222_813': 38.727,
    '1222_814': 38.7741,
    '1222_815': 38.8001,
    '1222_816': 38.8248,
    '1222_817': 38.8577,
    '1222_818': 38.8889,
    '1222_819': 38.922,
    '1222_820': 38.9679,
    '1222_821': 38.9976,
    '1222_822': 39.0099,
    '1222_823': 39.0242,
    '1222_824': 39.0548,
    '1222_825': 39.1035,
    '1222_826': 39.1462,
    '1222_827': 39.1832,
    '1222_828': 39.2067,
    '1222_829': 39.2308,
    '1222_830': 39.2738,
    '1222_831': 39.3252,
    '1222_832': 39.3709,
    '1222_833': 39.4078,
    '1222_834': 39.4326,
    '1222_835': 39.4613,
    '1222_836': 39.4941,
    '1222_837': 39.519,
    '1222_838': 39.5358,
    '1222_839': 39.5433,
    '1222_840': 39.5364,
    '1222_841': 39.5103,
    '1222_842': 39.4522,
    '1222_843': 39.3689,
    '1222_844': 39.2875,
    '1222_845': 39.2197,
    '1222_846': 39.161,
    '1222_847': 39.1125,
    '1222_848': 39.0779,
    '1222_849': 39.0587,
    '1222_850': 39.0633,
    '1222_851': 39.0905,
    '1222_852': 39.1342,
    '1222_853': 39.174,
    '1222_854': 39.1875,
    '1222_855': 39.2015,
    '1222_856': 39.2179,
    '1222_857': 39.2241,
    '1222_858': 39.2192,
    '1222_859': 39.2083,
    '1222_860': 39.1892,
    '1222_861': 39.1422,
    '1222_862': 39.0744,
    '1222_863': 38.9891,
    '1222_864': 38.8715,
    '1222_865': 38.7249,
    '1222_866': 38.5698,
    '1222_867': 38.4178,
    '1222_868': 38.2614,
    '1222_869': 38.096,
    '1222_870': 37.914,
    '1222_871': 37.722,
    '1222_872': 37.5203,
    '1222_873': 37.3184,
    '1222_874': 37.1046,
    '1222_875': 36.8733,
    '1222_876': 36.624,
    '1222_877': 36.361,
    '1222_878': 36.0801,
    '1222_879': 35.7811,
    '1222_880': 35.4667,
    '1222_881': 35.1441,
    '1222_882': 34.8138,
    '1222_883': 34.4765,
    '1222_884': 34.1287,
    '1222_885': 33.773,
    '1222_886': 33.4111,
    '1222_887': 33.0506,
    '1222_888': 32.6904,
    '1222_889': 32.3256,
    '1222_890': 31.9554,
    '1222_891': 31.5797,
    '1222_892': 31.2064,
    '1222_893': 30.8381,
    '1222_894': 30.4752,
    '1222_895': 30.1159,
    '1222_896': 29.7604,
    '1222_897': 29.4083,
    '1222_898': 29.0532,
    '1222_899': 28.6978,
    '1222_900': 28.3444,
    '1223_790': 36.519,
    '1223_791': 36.6023,
    '1223_792': 36.688,
    '1223_793': 36.7779,
    '1223_794': 36.8764,
    '1223_795': 36.993,
    '1223_796': 37.1198,
    '1223_797': 37.2524,
    '1223_798': 37.3889,
    '1223_799': 37.5328,
    '1223_800': 37.6829,
    '1223_801': 37.8267,
    '1223_802': 37.9807,
    '1223_803': 38.1285,
    '1223_804': 38.2579,
    '1223_805': 38.3656,
    '1223_806': 38.458,
    '1223_807': 38.5178,
    '1223_808': 38.5561,
    '1223_809': 38.5899,
    '1223_810': 38.6353,
    '1223_811': 38.6844,
    '1223_812': 38.7236,
    '1223_813': 38.7604,
    '1223_814': 38.8003,
    '1223_815': 38.8294,
    '1223_816': 38.8437,
    '1223_817': 38.8634,
    '1223_818': 38.8797,
    '1223_819': 38.9055,
    '1223_820': 38.9442,
    '1223_821': 38.9761,
    '1223_822': 39.0026,
    '1223_823': 39.0147,
    '1223_824': 39.0336,
    '1223_825': 39.073,
    '1223_826': 39.1142,
    '1223_827': 39.1523,
    '1223_828': 39.1806,
    '1223_829': 39.207,
    '1223_830': 39.2569,
    '1223_831': 39.3152,
    '1223_832': 39.3623,
    '1223_833': 39.3963,
    '1223_834': 39.4136,
    '1223_835': 39.4272,
    '1223_836': 39.4454,
    '1223_837': 39.4628,
    '1223_838': 39.4778,
    '1223_839': 39.4855,
    '1223_840': 39.4844,
    '1223_841': 39.4541,
    '1223_842': 39.3889,
    '1223_843': 39.3014,
    '1223_844': 39.2243,
    '1223_845': 39.162,
    '1223_846': 39.1,
    '1223_847': 39.0366,
    '1223_848': 38.9914,
    '1223_849': 38.963,
    '1223_850': 38.9648,
    '1223_851': 38.9872,
    '1223_852': 39.0292,
    '1223_853': 39.0537,
    '1223_854': 39.0632,
    '1223_855': 39.0805,
    '1223_856': 39.0984,
    '1223_857': 39.1052,
    '1223_858': 39.1004,
    '1223_859': 39.0837,
    '1223_860': 39.0582,
    '1223_861': 39.0113,
    '1223_862': 38.9525,
    '1223_863': 38.8739,
    '1223_864': 38.7508,
    '1223_865': 38.5984,
    '1223_866': 38.4461,
    '1223_867': 38.2893,
    '1223_868': 38.1255,
    '1223_869': 37.9537,
    '1223_870': 37.7687,
    '1223_871': 37.5715,
    '1223_872': 37.3738,
    '1223_873': 37.172,
    '1223_874': 36.9572,
    '1223_875': 36.7244,
    '1223_876': 36.4726,
    '1223_877': 36.2158,
    '1223_878': 35.941,
    '1223_879': 35.6425,
    '1223_880': 35.3282,
    '1223_881': 35.0112,
    '1223_882': 34.6869,
    '1223_883': 34.3553,
    '1223_884': 34.0114,
    '1223_885': 33.658,
    '1223_886': 33.2994,
    '1223_887': 32.9384,
    '1223_888': 32.5776,
    '1223_889': 32.21,
    '1223_890': 31.835,
    '1223_891': 31.4571,
    '1223_892': 31.0855,
    '1223_893': 30.722,
    '1223_894': 30.3608,
    '1223_895': 30.0025,
    '1223_896': 29.6478,
    '1223_897': 29.2972,
    '1223_898': 28.9424,
    '1223_899': 28.5857,
    '1223_900': 28.2305,
    '1224_790': 36.4656,
    '1224_791': 36.5593,
    '1224_792': 36.6538,
    '1224_793': 36.7529,
    '1224_794': 36.8613,
    '1224_795': 36.9867,
    '1224_796': 37.1231,
    '1224_797': 37.266,
    '1224_798': 37.4181,
    '1224_799': 37.5793,
    '1224_800': 37.7443,
    '1224_801': 37.8849,
    '1224_802': 38.026,
    '1224_803': 38.1699,
    '1224_804': 38.2976,
    '1224_805': 38.3989,
    '1224_806': 38.481,
    '1224_807': 38.5355,
    '1224_808': 38.574,
    '1224_809': 38.6211,
    '1224_810': 38.6741,
    '1224_811': 38.7136,
    '1224_812': 38.7415,
    '1224_813': 38.7823,
    '1224_814': 38.82,
    '1224_815': 38.8532,
    '1224_816': 38.871,
    '1224_817': 38.8785,
    '1224_818': 38.8832,
    '1224_819': 38.8964,
    '1224_820': 38.9198,
    '1224_821': 38.9474,
    '1224_822': 38.9783,
    '1224_823': 38.9959,
    '1224_824': 39.0034,
    '1224_825': 39.0306,
    '1224_826': 39.0725,
    '1224_827': 39.1134,
    '1224_828': 39.1538,
    '1224_829': 39.1955,
    '1224_830': 39.2497,
    '1224_831': 39.3062,
    '1224_832': 39.3503,
    '1224_833': 39.3759,
    '1224_834': 39.3835,
    '1224_835': 39.3879,
    '1224_836': 39.3993,
    '1224_837': 39.4116,
    '1224_838': 39.4245,
    '1224_839': 39.4323,
    '1224_840': 39.4253,
    '1224_841': 39.3867,
    '1224_842': 39.3165,
    '1224_843': 39.2355,
    '1224_844': 39.1637,
    '1224_845': 39.1035,
    '1224_846': 39.0379,
    '1224_847': 38.9621,
    '1224_848': 38.9034,
    '1224_849': 38.8697,
    '1224_850': 38.8667,
    '1224_851': 38.8798,
    '1224_852': 38.9049,
    '1224_853': 38.9205,
    '1224_854': 38.9348,
    '1224_855': 38.955,
    '1224_856': 38.9713,
    '1224_857': 38.9761,
    '1224_858': 38.9711,
    '1224_859': 38.9516,
    '1224_860': 38.9186,
    '1224_861': 38.8748,
    '1224_862': 38.821,
    '1224_863': 38.7494,
    '1224_864': 38.6302,
    '1224_865': 38.4752,
    '1224_866': 38.317,
    '1224_867': 38.1567,
    '1224_868': 37.988,
    '1224_869': 37.8103,
    '1224_870': 37.6261,
    '1224_871': 37.4295,
    '1224_872': 37.2321,
    '1224_873': 37.0322,
    '1224_874': 36.8185,
    '1224_875': 36.5901,
    '1224_876': 36.3382,
    '1224_877': 36.0809,
    '1224_878': 35.8051,
    '1224_879': 35.5089,
    '1224_880': 35.1925,
    '1224_881': 34.8804,
    '1224_882': 34.5597,
    '1224_883': 34.2345,
    '1224_884': 33.8915,
    '1224_885': 33.5388,
    '1224_886': 33.1827,
    '1224_887': 32.8195,
    '1224_888': 32.4621,
    '1224_889': 32.0936,
    '1224_890': 31.715,
    '1224_891': 31.3367,
    '1224_892': 30.9685,
    '1224_893': 30.6117,
    '1224_894': 30.2506,
    '1224_895': 29.8908,
    '1224_896': 29.5351,
    '1224_897': 29.1876,
    '1224_898': 28.8331,
    '1224_899': 28.4766,
    '1224_900': 28.1228,
    '1225_790': 36.424,
    '1225_791': 36.5198,
    '1225_792': 36.6206,
    '1225_793': 36.7289,
    '1225_794': 36.8485,
    '1225_795': 36.9794,
    '1225_796': 37.1224,
    '1225_797': 37.2764,
    '1225_798': 37.4486,
    '1225_799': 37.6256,
    '1225_800': 37.791,
    '1225_801': 37.9341,
    '1225_802': 38.0552,
    '1225_803': 38.1895,
    '1225_804': 38.3185,
    '1225_805': 38.4174,
    '1225_806': 38.4903,
    '1225_807': 38.5448,
    '1225_808': 38.5937,
    '1225_809': 38.6436,
    '1225_810': 38.6939,
    '1225_811': 38.7312,
    '1225_812': 38.7586,
    '1225_813': 38.7864,
    '1225_814': 38.8278,
    '1225_815': 38.8613,
    '1225_816': 38.8838,
    '1225_817': 38.8893,
    '1225_818': 38.8868,
    '1225_819': 38.8842,
    '1225_820': 38.8951,
    '1225_821': 38.9104,
    '1225_822': 38.9348,
    '1225_823': 38.9514,
    '1225_824': 38.9553,
    '1225_825': 38.9699,
    '1225_826': 39.0111,
    '1225_827': 39.061,
    '1225_828': 39.1182,
    '1225_829': 39.1781,
    '1225_830': 39.2379,
    '1225_831': 39.2937,
    '1225_832': 39.3329,
    '1225_833': 39.3488,
    '1225_834': 39.3449,
    '1225_835': 39.3349,
    '1225_836': 39.3433,
    '1225_837': 39.3581,
    '1225_838': 39.3724,
    '1225_839': 39.3794,
    '1225_840': 39.3692,
    '1225_841': 39.3204,
    '1225_842': 39.2462,
    '1225_843': 39.1655,
    '1225_844': 39.0966,
    '1225_845': 39.0282,
    '1225_846': 38.9524,
    '1225_847': 38.8737,
    '1225_848': 38.8099,
    '1225_849': 38.7716,
    '1225_850': 38.7628,
    '1225_851': 38.7658,
    '1225_852': 38.7718,
    '1225_853': 38.7813,
    '1225_854': 38.7987,
    '1225_855': 38.8227,
    '1225_856': 38.8366,
    '1225_857': 38.84,
    '1225_858': 38.8337,
    '1225_859': 38.8105,
    '1225_860': 38.7717,
    '1225_861': 38.7305,
    '1225_862': 38.6733,
    '1225_863': 38.6004,
    '1225_864': 38.4955,
    '1225_865': 38.3521,
    '1225_866': 38.1875,
    '1225_867': 38.0204,
    '1225_868': 37.8474,
    '1225_869': 37.6691,
    '1225_870': 37.4816,
    '1225_871': 37.2956,
    '1225_872': 37.1054,
    '1225_873': 36.8984,
    '1225_874': 36.6798,
    '1225_875': 36.4614,
    '1225_876': 36.2125,
    '1225_877': 35.949,
    '1225_878': 35.6688,
    '1225_879': 35.3786,
    '1225_880': 35.0638,
    '1225_881': 34.7492,
    '1225_882': 34.4259,
    '1225_883': 34.1021,
    '1225_884': 33.7534,
    '1225_885': 33.3988,
    '1225_886': 33.0474,
    '1225_887': 32.6785,
    '1225_888': 32.3328,
    '1225_889': 31.9663,
    '1225_890': 31.5909,
    '1225_891': 31.2324,
    '1225_892': 30.8677,
    '1225_893': 30.5082,
    '1225_894': 30.1479,
    '1225_895': 29.7841,
    '1225_896': 29.4308,
    '1225_897': 29.0807,
    '1225_898': 28.7282,
    '1225_899': 28.374,
    '1225_900': 28.02,
    '1226_790': 36.3828,
    '1226_791': 36.4817,
    '1226_792': 36.5871,
    '1226_793': 36.7013,
    '1226_794': 36.8283,
    '1226_795': 36.9657,
    '1226_796': 37.1143,
    '1226_797': 37.2768,
    '1226_798': 37.4648,
    '1226_799': 37.6471,
    '1226_800': 37.8156,
    '1226_801': 37.9613,
    '1226_802': 38.0787,
    '1226_803': 38.2018,
    '1226_804': 38.3271,
    '1226_805': 38.4217,
    '1226_806': 38.4913,
    '1226_807': 38.548,
    '1226_808': 38.6011,
    '1226_809': 38.6508,
    '1226_810': 38.6969,
    '1226_811': 38.7376,
    '1226_812': 38.7672,
    '1226_813': 38.7924,
    '1226_814': 38.8297,
    '1226_815': 38.8565,
    '1226_816': 38.8743,
    '1226_817': 38.8808,
    '1226_818': 38.8751,
    '1226_819': 38.8678,
    '1226_820': 38.867,
    '1226_821': 38.8657,
    '1226_822': 38.8734,
    '1226_823': 38.8878,
    '1226_824': 38.8973,
    '1226_825': 38.9002,
    '1226_826': 38.9341,
    '1226_827': 38.9928,
    '1226_828': 39.0661,
    '1226_829': 39.143,
    '1226_830': 39.2143,
    '1226_831': 39.27,
    '1226_832': 39.3044,
    '1226_833': 39.3087,
    '1226_834': 39.2904,
    '1226_835': 39.2734,
    '1226_836': 39.2771,
    '1226_837': 39.2972,
    '1226_838': 39.3187,
    '1226_839': 39.3293,
    '1226_840': 39.3161,
    '1226_841': 39.2614,
    '1226_842': 39.1809,
    '1226_843': 39.0903,
    '1226_844': 39.0091,
    '1226_845': 38.9346,
    '1226_846': 38.856,
    '1226_847': 38.781,
    '1226_848': 38.7125,
    '1226_849': 38.67,
    '1226_850': 38.653,
    '1226_851': 38.6475,
    '1226_852': 38.6439,
    '1226_853': 38.6481,
    '1226_854': 38.6633,
    '1226_855': 38.6862,
    '1226_856': 38.7006,
    '1226_857': 38.7027,
    '1226_858': 38.695,
    '1226_859': 38.67,
    '1226_860': 38.6286,
    '1226_861': 38.5846,
    '1226_862': 38.5232,
    '1226_863': 38.444,
    '1226_864': 38.3417,
    '1226_865': 38.21,
    '1226_866': 38.0528,
    '1226_867': 37.8856,
    '1226_868': 37.7121,
    '1226_869': 37.5345,
    '1226_870': 37.3456,
    '1226_871': 37.1581,
    '1226_872': 36.9649,
    '1226_873': 36.749,
    '1226_874': 36.521,
    '1226_875': 36.2978,
    '1226_876': 36.0566,
    '1226_877': 35.7901,
    '1226_878': 35.5126,
    '1226_879': 35.2159,
    '1226_880': 34.9061,
    '1226_881': 34.5837,
    '1226_882': 34.2558,
    '1226_883': 33.918,
    '1226_884': 33.5765,
    '1226_885': 33.2234,
    '1226_886': 32.8698,
    '1226_887': 32.5152,
    '1226_888': 32.1628,
    '1226_889': 31.7989,
    '1226_890': 31.4276,
    '1226_891': 31.0737,
    '1226_892': 30.7155,
    '1226_893': 30.3575,
    '1226_894': 30.0012,
    '1226_895': 29.6476,
    '1226_896': 29.3063,
    '1226_897': 28.957,
    '1226_898': 28.6064,
    '1226_899': 28.2558,
    '1226_900': 27.9062,
    '1227_790': 36.3339,
    '1227_791': 36.4389,
    '1227_792': 36.5501,
    '1227_793': 36.6696,
    '1227_794': 36.8018,
    '1227_795': 36.9429,
    '1227_796': 37.0941,
    '1227_797': 37.2583,
    '1227_798': 37.4496,
    '1227_799': 37.646,
    '1227_800': 37.821,
    '1227_801': 37.9712,
    '1227_802': 38.0924,
    '1227_803': 38.2069,
    '1227_804': 38.3195,
    '1227_805': 38.4068,
    '1227_806': 38.4717,
    '1227_807': 38.5271,
    '1227_808': 38.581,
    '1227_809': 38.6305,
    '1227_810': 38.676,
    '1227_811': 38.7195,
    '1227_812': 38.7555,
    '1227_813': 38.7832,
    '1227_814': 38.8132,
    '1227_815': 38.834,
    '1227_816': 38.8447,
    '1227_817': 38.8465,
    '1227_818': 38.8403,
    '1227_819': 38.8285,
    '1227_820': 38.8149,
    '1227_821': 38.8005,
    '1227_822': 38.7905,
    '1227_823': 38.8009,
    '1227_824': 38.8143,
    '1227_825': 38.8223,
    '1227_826': 38.8539,
    '1227_827': 38.9195,
    '1227_828': 39.0041,
    '1227_829': 39.0948,
    '1227_830': 39.175,
    '1227_831': 39.2283,
    '1227_832': 39.2591,
    '1227_833': 39.2558,
    '1227_834': 39.2278,
    '1227_835': 39.2119,
    '1227_836': 39.2123,
    '1227_837': 39.2321,
    '1227_838': 39.255,
    '1227_839': 39.2693,
    '1227_840': 39.2509,
    '1227_841': 39.1946,
    '1227_842': 39.1102,
    '1227_843': 39.0129,
    '1227_844': 38.921,
    '1227_845': 38.842,
    '1227_846': 38.7627,
    '1227_847': 38.684,
    '1227_848': 38.6131,
    '1227_849': 38.5655,
    '1227_850': 38.5388,
    '1227_851': 38.524,
    '1227_852': 38.5152,
    '1227_853': 38.5168,
    '1227_854': 38.5246,
    '1227_855': 38.542,
    '1227_856': 38.5552,
    '1227_857': 38.5599,
    '1227_858': 38.5523,
    '1227_859': 38.5231,
    '1227_860': 38.4855,
    '1227_861': 38.4427,
    '1227_862': 38.3789,
    '1227_863': 38.3005,
    '1227_864': 38.1977,
    '1227_865': 38.0693,
    '1227_866': 37.916,
    '1227_867': 37.7516,
    '1227_868': 37.5806,
    '1227_869': 37.3956,
    '1227_870': 37.21,
    '1227_871': 37.0205,
    '1227_872': 36.8166,
    '1227_873': 36.5988,
    '1227_874': 36.3727,
    '1227_875': 36.1434,
    '1227_876': 35.9016,
    '1227_877': 35.6405,
    '1227_878': 35.3695,
    '1227_879': 35.0599,
    '1227_880': 34.7473,
    '1227_881': 34.4242,
    '1227_882': 34.0928,
    '1227_883': 33.7532,
    '1227_884': 33.4098,
    '1227_885': 33.0593,
    '1227_886': 32.7062,
    '1227_887': 32.3508,
    '1227_888': 31.9926,
    '1227_889': 31.6337,
    '1227_890': 31.2704,
    '1227_891': 30.9105,
    '1227_892': 30.5483,
    '1227_893': 30.1891,
    '1227_894': 29.8337,
    '1227_895': 29.4795,
    '1227_896': 29.1372,
    '1227_897': 28.7852,
    '1227_898': 28.4345,
    '1227_899': 28.0901,
    '1227_900': 27.7397,
    '1228_790': 36.2768,
    '1228_791': 36.3902,
    '1228_792': 36.5089,
    '1228_793': 36.6354,
    '1228_794': 36.7704,
    '1228_795': 36.9126,
    '1228_796': 37.0627,
    '1228_797': 37.2242,
    '1228_798': 37.4142,
    '1228_799': 37.6215,
    '1228_800': 37.8104,
    '1228_801': 37.9658,
    '1228_802': 38.0914,
    '1228_803': 38.2004,
    '1228_804': 38.2951,
    '1228_805': 38.3692,
    '1228_806': 38.4303,
    '1228_807': 38.4795,
    '1228_808': 38.5266,
    '1228_809': 38.5762,
    '1228_810': 38.6272,
    '1228_811': 38.6745,
    '1228_812': 38.7147,
    '1228_813': 38.7476,
    '1228_814': 38.77,
    '1228_815': 38.783,
    '1228_816': 38.7871,
    '1228_817': 38.7881,
    '1228_818': 38.7798,
    '1228_819': 38.763,
    '1228_820': 38.7395,
    '1228_821': 38.7147,
    '1228_822': 38.6958,
    '1228_823': 38.7019,
    '1228_824': 38.7152,
    '1228_825': 38.7336,
    '1228_826': 38.7782,
    '1228_827': 38.8529,
    '1228_828': 38.943,
    '1228_829': 39.0355,
    '1228_830': 39.1107,
    '1228_831': 39.1696,
    '1228_832': 39.201,
    '1228_833': 39.1948,
    '1228_834': 39.1678,
    '1228_835': 39.1556,
    '1228_836': 39.1564,
    '1228_837': 39.1685,
    '1228_838': 39.1856,
    '1228_839': 39.195,
    '1228_840': 39.1741,
    '1228_841': 39.1165,
    '1228_842': 39.0314,
    '1228_843': 38.9334,
    '1228_844': 38.8374,
    '1228_845': 38.7512,
    '1228_846': 38.6665,
    '1228_847': 38.5823,
    '1228_848': 38.5101,
    '1228_849': 38.4581,
    '1228_850': 38.424,
    '1228_851': 38.3985,
    '1228_852': 38.3806,
    '1228_853': 38.377,
    '1228_854': 38.3845,
    '1228_855': 38.3936,
    '1228_856': 38.4038,
    '1228_857': 38.408,
    '1228_858': 38.4023,
    '1228_859': 38.3756,
    '1228_860': 38.3443,
    '1228_861': 38.3004,
    '1228_862': 38.2406,
    '1228_863': 38.164,
    '1228_864': 38.0616,
    '1228_865': 37.9325,
    '1228_866': 37.7809,
    '1228_867': 37.6202,
    '1228_868': 37.4497,
    '1228_869': 37.2611,
    '1228_870': 37.0675,
    '1228_871': 36.8683,
    '1228_872': 36.6613,
    '1228_873': 36.4451,
    '1228_874': 36.2217,
    '1228_875': 35.9902,
    '1228_876': 35.7462,
    '1228_877': 35.4791,
    '1228_878': 35.2075,
    '1228_879': 34.9048,
    '1228_880': 34.5931,
    '1228_881': 34.2689,
    '1228_882': 33.9352,
    '1228_883': 33.5947,
    '1228_884': 33.25,
    '1228_885': 32.9011,
    '1228_886': 32.549,
    '1228_887': 32.194,
    '1228_888': 31.8366,
    '1228_889': 31.4776,
    '1228_890': 31.1155,
    '1228_891': 30.755,
    '1228_892': 30.3933,
    '1228_893': 30.0331,
    '1228_894': 29.6757,
    '1228_895': 29.3273,
    '1228_896': 28.9828,
    '1228_897': 28.6332,
    '1228_898': 28.2837,
    '1228_899': 27.9374,
    '1228_900': 27.5905,
    '1229_790': 36.2166,
    '1229_791': 36.3379,
    '1229_792': 36.4637,
    '1229_793': 36.5955,
    '1229_794': 36.7353,
    '1229_795': 36.8775,
    '1229_796': 37.024,
    '1229_797': 37.1792,
    '1229_798': 37.3644,
    '1229_799': 37.5859,
    '1229_800': 37.7897,
    '1229_801': 37.9429,
    '1229_802': 38.07,
    '1229_803': 38.1726,
    '1229_804': 38.2523,
    '1229_805': 38.3163,
    '1229_806': 38.3733,
    '1229_807': 38.4153,
    '1229_808': 38.4477,
    '1229_809': 38.5006,
    '1229_810': 38.5562,
    '1229_811': 38.6062,
    '1229_812': 38.6493,
    '1229_813': 38.6811,
    '1229_814': 38.6989,
    '1229_815': 38.7015,
    '1229_816': 38.7029,
    '1229_817': 38.7045,
    '1229_818': 38.6951,
    '1229_819': 38.6787,
    '1229_820': 38.651,
    '1229_821': 38.6206,
    '1229_822': 38.6032,
    '1229_823': 38.6096,
    '1229_824': 38.6232,
    '1229_825': 38.6388,
    '1229_826': 38.6976,
    '1229_827': 38.7889,
    '1229_828': 38.8855,
    '1229_829': 38.9707,
    '1229_830': 39.0379,
    '1229_831': 39.0979,
    '1229_832': 39.1326,
    '1229_833': 39.1358,
    '1229_834': 39.121,
    '1229_835': 39.1129,
    '1229_836': 39.1081,
    '1229_837': 39.1074,
    '1229_838': 39.113,
    '1229_839': 39.1098,
    '1229_840': 39.0852,
    '1229_841': 39.0326,
    '1229_842': 38.9491,
    '1229_843': 38.8509,
    '1229_844': 38.7542,
    '1229_845': 38.6612,
    '1229_846': 38.5718,
    '1229_847': 38.4828,
    '1229_848': 38.4086,
    '1229_849': 38.3497,
    '1229_850': 38.3071,
    '1229_851': 38.2758,
    '1229_852': 38.2484,
    '1229_853': 38.2368,
    '1229_854': 38.2401,
    '1229_855': 38.2458,
    '1229_856': 38.2552,
    '1229_857': 38.2549,
    '1229_858': 38.2486,
    '1229_859': 38.2301,
    '1229_860': 38.2037,
    '1229_861': 38.1612,
    '1229_862': 38.1056,
    '1229_863': 38.0289,
    '1229_864': 37.9275,
    '1229_865': 37.7954,
    '1229_866': 37.646,
    '1229_867': 37.4875,
    '1229_868': 37.3119,
    '1229_869': 37.1289,
    '1229_870': 36.9286,
    '1229_871': 36.7156,
    '1229_872': 36.506,
    '1229_873': 36.2915,
    '1229_874': 36.0696,
    '1229_875': 35.8366,
    '1229_876': 35.5895,
    '1229_877': 35.3303,
    '1229_878': 35.0582,
    '1229_879': 34.7533,
    '1229_880': 34.4433,
    '1229_881': 34.1163,
    '1229_882': 33.7805,
    '1229_883': 33.4378,
    '1229_884': 33.0929,
    '1229_885': 32.7447,
    '1229_886': 32.3937,
    '1229_887': 32.04,
    '1229_888': 31.6844,
    '1229_889': 31.3261,
    '1229_890': 30.9623,
    '1229_891': 30.6046,
    '1229_892': 30.2453,
    '1229_893': 29.8879,
    '1229_894': 29.5345,
    '1229_895': 29.1877,
    '1229_896': 28.8433,
    '1229_897': 28.4933,
    '1229_898': 28.1433,
    '1229_899': 27.7958,
    '1229_900': 27.4504,
    '1230_790': 36.1544,
    '1230_791': 36.2836,
    '1230_792': 36.4172,
    '1230_793': 36.557,
    '1230_794': 36.7009,
    '1230_795': 36.8412,
    '1230_796': 36.9822,
    '1230_797': 37.1296,
    '1230_798': 37.3062,
    '1230_799': 37.5288,
    '1230_800': 37.7481,
    '1230_801': 37.9093,
    '1230_802': 38.036,
    '1230_803': 38.1258,
    '1230_804': 38.1949,
    '1230_805': 38.256,
    '1230_806': 38.3062,
    '1230_807': 38.3368,
    '1230_808': 38.3612,
    '1230_809': 38.413,
    '1230_810': 38.4697,
    '1230_811': 38.5158,
    '1230_812': 38.558,
    '1230_813': 38.5851,
    '1230_814': 38.5953,
    '1230_815': 38.593,
    '1230_816': 38.5902,
    '1230_817': 38.5938,
    '1230_818': 38.5903,
    '1230_819': 38.5791,
    '1230_820': 38.5483,
    '1230_821': 38.5163,
    '1230_822': 38.505,
    '1230_823': 38.5156,
    '1230_824': 38.5344,
    '1230_825': 38.5581,
    '1230_826': 38.624,
    '1230_827': 38.721,
    '1230_828': 38.8145,
    '1230_829': 38.8947,
    '1230_830': 38.9583,
    '1230_831': 39.0228,
    '1230_832': 39.0669,
    '1230_833': 39.0867,
    '1230_834': 39.0941,
    '1230_835': 39.0922,
    '1230_836': 39.0758,
    '1230_837': 39.054,
    '1230_838': 39.0396,
    '1230_839': 39.0181,
    '1230_840': 38.9896,
    '1230_841': 38.9418,
    '1230_842': 38.8663,
    '1230_843': 38.7693,
    '1230_844': 38.6717,
    '1230_845': 38.5733,
    '1230_846': 38.4742,
    '1230_847': 38.3836,
    '1230_848': 38.307,
    '1230_849': 38.2437,
    '1230_850': 38.1922,
    '1230_851': 38.1496,
    '1230_852': 38.1186,
    '1230_853': 38.1037,
    '1230_854': 38.0981,
    '1230_855': 38.0992,
    '1230_856': 38.1046,
    '1230_857': 38.1042,
    '1230_858': 38.0985,
    '1230_859': 38.0857,
    '1230_860': 38.0628,
    '1230_861': 38.0245,
    '1230_862': 37.969,
    '1230_863': 37.8923,
    '1230_864': 37.7882,
    '1230_865': 37.6539,
    '1230_866': 37.5069,
    '1230_867': 37.3437,
    '1230_868': 37.1741,
    '1230_869': 36.9892,
    '1230_870': 36.7822,
    '1230_871': 36.5657,
    '1230_872': 36.3548,
    '1230_873': 36.1407,
    '1230_874': 35.9221,
    '1230_875': 35.6882,
    '1230_876': 35.4412,
    '1230_877': 35.1781,
    '1230_878': 34.8971,
    '1230_879': 34.6007,
    '1230_880': 34.2902,
    '1230_881': 33.9642,
    '1230_882': 33.6272,
    '1230_883': 33.2828,
    '1230_884': 32.936,
    '1230_885': 32.587,
    '1230_886': 32.2363,
    '1230_887': 31.8841,
    '1230_888': 31.5304,
    '1230_889': 31.1735,
    '1230_890': 30.8115,
    '1230_891': 30.4532,
    '1230_892': 30.0972,
    '1230_893': 29.744,
    '1230_894': 29.3941,
    '1230_895': 29.0497,
    '1230_896': 28.7086,
    '1230_897': 28.3573,
    '1230_898': 28.0059,
    '1230_899': 27.6574,
    '1230_900': 27.313,
    '1231_790': 36.0864,
    '1231_791': 36.2252,
    '1231_792': 36.3698,
    '1231_793': 36.518,
    '1231_794': 36.6672,
    '1231_795': 36.8047,
    '1231_796': 36.941,
    '1231_797': 37.0831,
    '1231_798': 37.2547,
    '1231_799': 37.4723,
    '1231_800': 37.6968,
    '1231_801': 37.862,
    '1231_802': 37.9811,
    '1231_803': 38.0662,
    '1231_804': 38.1278,
    '1231_805': 38.1844,
    '1231_806': 38.2303,
    '1231_807': 38.2522,
    '1231_808': 38.2745,
    '1231_809': 38.3153,
    '1231_810': 38.3656,
    '1231_811': 38.4118,
    '1231_812': 38.4451,
    '1231_813': 38.4632,
    '1231_814': 38.469,
    '1231_815': 38.4627,
    '1231_816': 38.4603,
    '1231_817': 38.4649,
    '1231_818': 38.4648,
    '1231_819': 38.4582,
    '1231_820': 38.4368,
    '1231_821': 38.4098,
    '1231_822': 38.3991,
    '1231_823': 38.4164,
    '1231_824': 38.4462,
    '1231_825': 38.4893,
    '1231_826': 38.5599,
    '1231_827': 38.6477,
    '1231_828': 38.7313,
    '1231_829': 38.8031,
    '1231_830': 38.8721,
    '1231_831': 38.9498,
    '1231_832': 39.0148,
    '1231_833': 39.0547,
    '1231_834': 39.075,
    '1231_835': 39.0767,
    '1231_836': 39.0533,
    '1231_837': 39.0101,
    '1231_838': 38.9712,
    '1231_839': 38.9394,
    '1231_840': 38.9057,
    '1231_841': 38.8556,
    '1231_842': 38.7791,
    '1231_843': 38.6778,
    '1231_844': 38.5816,
    '1231_845': 38.4802,
    '1231_846': 38.3729,
    '1231_847': 38.282,
    '1231_848': 38.2061,
    '1231_849': 38.1388,
    '1231_850': 38.0802,
    '1231_851': 38.028,
    '1231_852': 37.9925,
    '1231_853': 37.9745,
    '1231_854': 37.9638,
    '1231_855': 37.9577,
    '1231_856': 37.9583,
    '1231_857': 37.9565,
    '1231_858': 37.9526,
    '1231_859': 37.9442,
    '1231_860': 37.9221,
    '1231_861': 37.8854,
    '1231_862': 37.8291,
    '1231_863': 37.7507,
    '1231_864': 37.6404,
    '1231_865': 37.5091,
    '1231_866': 37.3569,
    '1231_867': 37.2004,
    '1231_868': 37.0294,
    '1231_869': 36.8383,
    '1231_870': 36.6323,
    '1231_871': 36.4201,
    '1231_872': 36.2086,
    '1231_873': 35.994,
    '1231_874': 35.7729,
    '1231_875': 35.5373,
    '1231_876': 35.285,
    '1231_877': 35.0259,
    '1231_878': 34.7423,
    '1231_879': 34.4499,
    '1231_880': 34.1383,
    '1231_881': 33.8117,
    '1231_882': 33.4732,
    '1231_883': 33.1258,
    '1231_884': 32.7779,
    '1231_885': 32.4285,
    '1231_886': 32.0774,
    '1231_887': 31.7266,
    '1231_888': 31.3751,
    '1231_889': 31.0205,
    '1231_890': 30.6565,
    '1231_891': 30.3007,
    '1231_892': 29.9476,
    '1231_893': 29.5968,
    '1231_894': 29.2494,
    '1231_895': 28.9043,
    '1231_896': 28.5614,
    '1231_897': 28.2162,
    '1231_898': 27.8679,
    '1231_899': 27.5202,
    '1231_900': 27.1773,
    '1232_790': 36.0021,
    '1232_791': 36.1554,
    '1232_792': 36.3127,
    '1232_793': 36.4678,
    '1232_794': 36.6215,
    '1232_795': 36.762,
    '1232_796': 36.8973,
    '1232_797': 37.0374,
    '1232_798': 37.2062,
    '1232_799': 37.4146,
    '1232_800': 37.6301,
    '1232_801': 37.7948,
    '1232_802': 37.9106,
    '1232_803': 37.9968,
    '1232_804': 38.0546,
    '1232_805': 38.1031,
    '1232_806': 38.1529,
    '1232_807': 38.1776,
    '1232_808': 38.1999,
    '1232_809': 38.2267,
    '1232_810': 38.2565,
    '1232_811': 38.2931,
    '1232_812': 38.32,
    '1232_813': 38.3276,
    '1232_814': 38.3267,
    '1232_815': 38.3217,
    '1232_816': 38.3252,
    '1232_817': 38.3259,
    '1232_818': 38.3221,
    '1232_819': 38.3169,
    '1232_820': 38.3007,
    '1232_821': 38.2816,
    '1232_822': 38.2782,
    '1232_823': 38.3111,
    '1232_824': 38.3619,
    '1232_825': 38.4213,
    '1232_826': 38.493,
    '1232_827': 38.5727,
    '1232_828': 38.6439,
    '1232_829': 38.7205,
    '1232_830': 38.807,
    '1232_831': 38.8952,
    '1232_832': 38.9734,
    '1232_833': 39.0267,
    '1232_834': 39.0535,
    '1232_835': 39.0544,
    '1232_836': 39.0257,
    '1232_837': 38.968,
    '1232_838': 38.9129,
    '1232_839': 38.8706,
    '1232_840': 38.8312,
    '1232_841': 38.7717,
    '1232_842': 38.6877,
    '1232_843': 38.5834,
    '1232_844': 38.4786,
    '1232_845': 38.3774,
    '1232_846': 38.2721,
    '1232_847': 38.1797,
    '1232_848': 38.1016,
    '1232_849': 38.0312,
    '1232_850': 37.9677,
    '1232_851': 37.9153,
    '1232_852': 37.8761,
    '1232_853': 37.8499,
    '1232_854': 37.8336,
    '1232_855': 37.8247,
    '1232_856': 37.8202,
    '1232_857': 37.8152,
    '1232_858': 37.8086,
    '1232_859': 37.7991,
    '1232_860': 37.7786,
    '1232_861': 37.7436,
    '1232_862': 37.6843,
    '1232_863': 37.6035,
    '1232_864': 37.4922,
    '1232_865': 37.3607,
    '1232_866': 37.2066,
    '1232_867': 37.0501,
    '1232_868': 36.8731,
    '1232_869': 36.68,
    '1232_870': 36.477,
    '1232_871': 36.2702,
    '1232_872': 36.0608,
    '1232_873': 35.8466,
    '1232_874': 35.6257,
    '1232_875': 35.3919,
    '1232_876': 35.1372,
    '1232_877': 34.8742,
    '1232_878': 34.5935,
    '1232_879': 34.2965,
    '1232_880': 33.9828,
    '1232_881': 33.6556,
    '1232_882': 33.3139,
    '1232_883': 32.9652,
    '1232_884': 32.6181,
    '1232_885': 32.2694,
    '1232_886': 31.9184,
    '1232_887': 31.5682,
    '1232_888': 31.2169,
    '1232_889': 30.8634,
    '1232_890': 30.5064,
    '1232_891': 30.1515,
    '1232_892': 29.7994,
    '1232_893': 29.451,
    '1232_894': 29.106,
    '1232_895': 28.7642,
    '1232_896': 28.4246,
    '1232_897': 28.0793,
    '1232_898': 27.7334,
    '1232_899': 27.3881,
    '1232_900': 27.0431,
    '1233_790': 35.9268,
    '1233_791': 36.0861,
    '1233_792': 36.252,
    '1233_793': 36.4133,
    '1233_794': 36.5756,
    '1233_795': 36.7175,
    '1233_796': 36.849,
    '1233_797': 36.9893,
    '1233_798': 37.1548,
    '1233_799': 37.3512,
    '1233_800': 37.5559,
    '1233_801': 37.7122,
    '1233_802': 37.8268,
    '1233_803': 37.916,
    '1233_804': 37.9761,
    '1233_805': 38.0226,
    '1233_806': 38.0762,
    '1233_807': 38.108,
    '1233_808': 38.126,
    '1233_809': 38.1421,
    '1233_810': 38.1609,
    '1233_811': 38.1789,
    '1233_812': 38.192,
    '1233_813': 38.1943,
    '1233_814': 38.1841,
    '1233_815': 38.1761,
    '1233_816': 38.1799,
    '1233_817': 38.1801,
    '1233_818': 38.1752,
    '1233_819': 38.168,
    '1233_820': 38.1523,
    '1233_821': 38.138,
    '1233_822': 38.1451,
    '1233_823': 38.1864,
    '1233_824': 38.2525,
    '1233_825': 38.3317,
    '1233_826': 38.416,
    '1233_827': 38.4967,
    '1233_828': 38.5673,
    '1233_829': 38.6556,
    '1233_830': 38.7544,
    '1233_831': 38.8472,
    '1233_832': 38.9343,
    '1233_833': 38.996,
    '1233_834': 39.0251,
    '1233_835': 39.0258,
    '1233_836': 38.9942,
    '1233_837': 38.9313,
    '1233_838': 38.8677,
    '1233_839': 38.8133,
    '1233_840': 38.7565,
    '1233_841': 38.6778,
    '1233_842': 38.5847,
    '1233_843': 38.4789,
    '1233_844': 38.3734,
    '1233_845': 38.2705,
    '1233_846': 38.1697,
    '1233_847': 38.0761,
    '1233_848': 37.9944,
    '1233_849': 37.921,
    '1233_850': 37.8596,
    '1233_851': 37.8086,
    '1233_852': 37.7685,
    '1233_853': 37.7359,
    '1233_854': 37.7113,
    '1233_855': 37.6961,
    '1233_856': 37.6857,
    '1233_857': 37.6751,
    '1233_858': 37.6663,
    '1233_859': 37.6556,
    '1233_860': 37.6319,
    '1233_861': 37.5929,
    '1233_862': 37.5331,
    '1233_863': 37.451,
    '1233_864': 37.3428,
    '1233_865': 37.2043,
    '1233_866': 37.0514,
    '1233_867': 36.8877,
    '1233_868': 36.7067,
    '1233_869': 36.5177,
    '1233_870': 36.3196,
    '1233_871': 36.1185,
    '1233_872': 35.9118,
    '1233_873': 35.6982,
    '1233_874': 35.4758,
    '1233_875': 35.2405,
    '1233_876': 34.9891,
    '1233_877': 34.7246,
    '1233_878': 34.4422,
    '1233_879': 34.1426,
    '1233_880': 33.8256,
    '1233_881': 33.4954,
    '1233_882': 33.1537,
    '1233_883': 32.8068,
    '1233_884': 32.4601,
    '1233_885': 32.1125,
    '1233_886': 31.7621,
    '1233_887': 31.4117,
    '1233_888': 31.0608,
    '1233_889': 30.7094,
    '1233_890': 30.3581,
    '1233_891': 30.0045,
    '1233_892': 29.6527,
    '1233_893': 29.3058,
    '1233_894': 28.9614,
    '1233_895': 28.6207,
    '1233_896': 28.2816,
    '1233_897': 27.9382,
    '1233_898': 27.5946,
    '1233_899': 27.2519,
    '1233_900': 26.9048,
    '1234_790': 35.85,
    '1234_791': 36.0203,
    '1234_792': 36.1952,
    '1234_793': 36.3645,
    '1234_794': 36.5238,
    '1234_795': 36.6689,
    '1234_796': 36.801,
    '1234_797': 36.9378,
    '1234_798': 37.0976,
    '1234_799': 37.2774,
    '1234_800': 37.4669,
    '1234_801': 37.6215,
    '1234_802': 37.7366,
    '1234_803': 37.8264,
    '1234_804': 37.8935,
    '1234_805': 37.9422,
    '1234_806': 37.9977,
    '1234_807': 38.0348,
    '1234_808': 38.051,
    '1234_809': 38.0557,
    '1234_810': 38.0593,
    '1234_811': 38.0638,
    '1234_812': 38.0648,
    '1234_813': 38.0631,
    '1234_814': 38.0492,
    '1234_815': 38.0356,
    '1234_816': 38.0363,
    '1234_817': 38.0362,
    '1234_818': 38.0312,
    '1234_819': 38.0247,
    '1234_820': 38.0109,
    '1234_821': 38.0011,
    '1234_822': 38.016,
    '1234_823': 38.0575,
    '1234_824': 38.1277,
    '1234_825': 38.2185,
    '1234_826': 38.3184,
    '1234_827': 38.414,
    '1234_828': 38.4953,
    '1234_829': 38.5887,
    '1234_830': 38.6923,
    '1234_831': 38.793,
    '1234_832': 38.8915,
    '1234_833': 38.9623,
    '1234_834': 38.9965,
    '1234_835': 38.9999,
    '1234_836': 38.9658,
    '1234_837': 38.8995,
    '1234_838': 38.8263,
    '1234_839': 38.7511,
    '1234_840': 38.6775,
    '1234_841': 38.5833,
    '1234_842': 38.4834,
    '1234_843': 38.3759,
    '1234_844': 38.2698,
    '1234_845': 38.1669,
    '1234_846': 38.0654,
    '1234_847': 37.9704,
    '1234_848': 37.8864,
    '1234_849': 37.814,
    '1234_850': 37.7538,
    '1234_851': 37.7044,
    '1234_852': 37.6636,
    '1234_853': 37.6282,
    '1234_854': 37.5991,
    '1234_855': 37.5773,
    '1234_856': 37.559,
    '1234_857': 37.5442,
    '1234_858': 37.5326,
    '1234_859': 37.518,
    '1234_860': 37.4895,
    '1234_861': 37.4398,
    '1234_862': 37.3786,
    '1234_863': 37.297,
    '1234_864': 37.192,
    '1234_865': 37.0634,
    '1234_866': 36.9139,
    '1234_867': 36.737,
    '1234_868': 36.5528,
    '1234_869': 36.364,
    '1234_870': 36.1689,
    '1234_871': 35.9689,
    '1234_872': 35.7628,
    '1234_873': 35.55,
    '1234_874': 35.3256,
    '1234_875': 35.0896,
    '1234_876': 34.8402,
    '1234_877': 34.5768,
    '1234_878': 34.2917,
    '1234_879': 33.9888,
    '1234_880': 33.6685,
    '1234_881': 33.3357,
    '1234_882': 32.9933,
    '1234_883': 32.6465,
    '1234_884': 32.3,
    '1234_885': 31.9527,
    '1234_886': 31.604,
    '1234_887': 31.2551,
    '1234_888': 30.9064,
    '1234_889': 30.5595,
    '1234_890': 30.2118,
    '1234_891': 29.858,
    '1234_892': 29.5064,
    '1234_893': 29.1598,
    '1234_894': 28.8146,
    '1234_895': 28.4736,
    '1234_896': 28.1359,
    '1234_897': 27.7931,
    '1234_898': 27.4531,
    '1234_899': 27.1169,
    '1234_900': 26.765,
    '1235_790': 35.762,
    '1235_791': 35.9466,
    '1235_792': 36.1302,
    '1235_793': 36.3073,
    '1235_794': 36.4647,
    '1235_795': 36.6169,
    '1235_796': 36.7556,
    '1235_797': 36.8874,
    '1235_798': 37.0348,
    '1235_799': 37.2004,
    '1235_800': 37.3757,
    '1235_801': 37.5372,
    '1235_802': 37.6487,
    '1235_803': 37.7383,
    '1235_804': 37.811,
    '1235_805': 37.8639,
    '1235_806': 37.9253,
    '1235_807': 37.9586,
    '1235_808': 37.973,
    '1235_809': 37.9723,
    '1235_810': 37.9692,
    '1235_811': 37.9618,
    '1235_812': 37.9532,
    '1235_813': 37.9405,
    '1235_814': 37.9229,
    '1235_815': 37.9112,
    '1235_816': 37.9036,
    '1235_817': 37.9005,
    '1235_818': 37.8967,
    '1235_819': 37.8921,
    '1235_820': 37.884,
    '1235_821': 37.8817,
    '1235_822': 37.8974,
    '1235_823': 37.9356,
    '1235_824': 38.0027,
    '1235_825': 38.1003,
    '1235_826': 38.2082,
    '1235_827': 38.3172,
    '1235_828': 38.4149,
    '1235_829': 38.5111,
    '1235_830': 38.6172,
    '1235_831': 38.7333,
    '1235_832': 38.8422,
    '1235_833': 38.9228,
    '1235_834': 38.9684,
    '1235_835': 38.9739,
    '1235_836': 38.9397,
    '1235_837': 38.8731,
    '1235_838': 38.7851,
    '1235_839': 38.6922,
    '1235_840': 38.6014,
    '1235_841': 38.4962,
    '1235_842': 38.3875,
    '1235_843': 38.2783,
    '1235_844': 38.1719,
    '1235_845': 38.0672,
    '1235_846': 37.9635,
    '1235_847': 37.8678,
    '1235_848': 37.783,
    '1235_849': 37.7117,
    '1235_850': 37.6526,
    '1235_851': 37.6038,
    '1235_852': 37.5626,
    '1235_853': 37.5262,
    '1235_854': 37.4917,
    '1235_855': 37.4632,
    '1235_856': 37.4399,
    '1235_857': 37.4233,
    '1235_858': 37.4108,
    '1235_859': 37.3892,
    '1235_860': 37.3554,
    '1235_861': 37.302,
    '1235_862': 37.2399,
    '1235_863': 37.159,
    '1235_864': 37.0544,
    '1235_865': 36.9229,
    '1235_866': 36.7645,
    '1235_867': 36.583,
    '1235_868': 36.4011,
    '1235_869': 36.2146,
    '1235_870': 36.0215,
    '1235_871': 35.8212,
    '1235_872': 35.6119,
    '1235_873': 35.3944,
    '1235_874': 35.1744,
    '1235_875': 34.9411,
    '1235_876': 34.6932,
    '1235_877': 34.4285,
    '1235_878': 34.1421,
    '1235_879': 33.8378,
    '1235_880': 33.5143,
    '1235_881': 33.1797,
    '1235_882': 32.836,
    '1235_883': 32.4892,
    '1235_884': 32.1411,
    '1235_885': 31.7935,
    '1235_886': 31.4462,
    '1235_887': 31.099,
    '1235_888': 30.7516,
    '1235_889': 30.4073,
    '1235_890': 30.0585,
    '1235_891': 29.7058,
    '1235_892': 29.3549,
    '1235_893': 29.0078,
    '1235_894': 28.6627,
    '1235_895': 28.3213,
    '1235_896': 27.9843,
    '1235_897': 27.6433,
    '1235_898': 27.3131,
    '1235_899': 26.9926,
    '1235_900': 26.6249,
    '1236_790': 35.6602,
    '1236_791': 35.854,
    '1236_792': 36.047,
    '1236_793': 36.2282,
    '1236_794': 36.394,
    '1236_795': 36.5587,
    '1236_796': 36.7022,
    '1236_797': 36.8339,
    '1236_798': 36.9698,
    '1236_799': 37.1274,
    '1236_800': 37.2944,
    '1236_801': 37.4543,
    '1236_802': 37.5674,
    '1236_803': 37.6543,
    '1236_804': 37.7305,
    '1236_805': 37.7874,
    '1236_806': 37.8519,
    '1236_807': 37.8793,
    '1236_808': 37.8953,
    '1236_809': 37.8964,
    '1236_810': 37.892,
    '1236_811': 37.884,
    '1236_812': 37.8736,
    '1236_813': 37.8535,
    '1236_814': 37.8254,
    '1236_815': 37.8061,
    '1236_816': 37.7879,
    '1236_817': 37.7783,
    '1236_818': 37.774,
    '1236_819': 37.7707,
    '1236_820': 37.767,
    '1236_821': 37.7698,
    '1236_822': 37.7861,
    '1236_823': 37.8215,
    '1236_824': 37.8842,
    '1236_825': 37.9784,
    '1236_826': 38.089,
    '1236_827': 38.2108,
    '1236_828': 38.3253,
    '1236_829': 38.4313,
    '1236_830': 38.5364,
    '1236_831': 38.661,
    '1236_832': 38.7838,
    '1236_833': 38.8706,
    '1236_834': 38.9212,
    '1236_835': 38.9353,
    '1236_836': 38.9027,
    '1236_837': 38.8367,
    '1236_838': 38.7411,
    '1236_839': 38.6355,
    '1236_840': 38.5332,
    '1236_841': 38.4212,
    '1236_842': 38.3031,
    '1236_843': 38.1917,
    '1236_844': 38.0847,
    '1236_845': 37.976,
    '1236_846': 37.8672,
    '1236_847': 37.7708,
    '1236_848': 37.6886,
    '1236_849': 37.6181,
    '1236_850': 37.5587,
    '1236_851': 37.5087,
    '1236_852': 37.4653,
    '1236_853': 37.4262,
    '1236_854': 37.3896,
    '1236_855': 37.3571,
    '1236_856': 37.3292,
    '1236_857': 37.3096,
    '1236_858': 37.2917,
    '1236_859': 37.2655,
    '1236_860': 37.2219,
    '1236_861': 37.1728,
    '1236_862': 37.1109,
    '1236_863': 37.0313,
    '1236_864': 36.9262,
    '1236_865': 36.79,
    '1236_866': 36.6218,
    '1236_867': 36.4402,
    '1236_868': 36.2561,
    '1236_869': 36.0697,
    '1236_870': 35.8785,
    '1236_871': 35.6794,
    '1236_872': 35.4701,
    '1236_873': 35.2518,
    '1236_874': 35.0336,
    '1236_875': 34.7986,
    '1236_876': 34.5495,
    '1236_877': 34.2829,
    '1236_878': 33.9935,
    '1236_879': 33.6878,
    '1236_880': 33.3655,
    '1236_881': 33.0299,
    '1236_882': 32.6848,
    '1236_883': 32.335,
    '1236_884': 31.9837,
    '1236_885': 31.6367,
    '1236_886': 31.2908,
    '1236_887': 30.9456,
    '1236_888': 30.6004,
    '1236_889': 30.2571,
    '1236_890': 29.9058,
    '1236_891': 29.5542,
    '1236_892': 29.2024,
    '1236_893': 28.8555,
    '1236_894': 28.5097,
    '1236_895': 28.1669,
    '1236_896': 27.8332,
    '1236_897': 27.4872,
    '1236_898': 27.1616,
    '1236_899': 26.8757,
    '1236_900': 26.4746,
    '1237_790': 35.5633,
    '1237_791': 35.7536,
    '1237_792': 35.952,
    '1237_793': 36.1374,
    '1237_794': 36.3088,
    '1237_795': 36.4781,
    '1237_796': 36.6281,
    '1237_797': 36.7632,
    '1237_798': 36.9017,
    '1237_799': 37.0498,
    '1237_800': 37.2081,
    '1237_801': 37.3639,
    '1237_802': 37.481,
    '1237_803': 37.5693,
    '1237_804': 37.6471,
    '1237_805': 37.7025,
    '1237_806': 37.7654,
    '1237_807': 37.796,
    '1237_808': 37.8123,
    '1237_809': 37.8186,
    '1237_810': 37.8237,
    '1237_811': 37.828,
    '1237_812': 37.8238,
    '1237_813': 37.7968,
    '1237_814': 37.7512,
    '1237_815': 37.7122,
    '1237_816': 37.684,
    '1237_817': 37.6695,
    '1237_818': 37.6635,
    '1237_819': 37.6605,
    '1237_820': 37.6601,
    '1237_821': 37.6656,
    '1237_822': 37.6819,
    '1237_823': 37.7151,
    '1237_824': 37.7752,
    '1237_825': 37.8644,
    '1237_826': 37.9762,
    '1237_827': 38.1038,
    '1237_828': 38.2231,
    '1237_829': 38.3423,
    '1237_830': 38.4543,
    '1237_831': 38.5826,
    '1237_832': 38.7098,
    '1237_833': 38.8086,
    '1237_834': 38.8671,
    '1237_835': 38.8834,
    '1237_836': 38.8609,
    '1237_837': 38.7931,
    '1237_838': 38.6948,
    '1237_839': 38.585,
    '1237_840': 38.473,
    '1237_841': 38.3568,
    '1237_842': 38.2322,
    '1237_843': 38.1148,
    '1237_844': 38.0014,
    '1237_845': 37.8883,
    '1237_846': 37.7759,
    '1237_847': 37.6788,
    '1237_848': 37.598,
    '1237_849': 37.5291,
    '1237_850': 37.4694,
    '1237_851': 37.4186,
    '1237_852': 37.3739,
    '1237_853': 37.3326,
    '1237_854': 37.2943,
    '1237_855': 37.2591,
    '1237_856': 37.2281,
    '1237_857': 37.2039,
    '1237_858': 37.18,
    '1237_859': 37.1492,
    '1237_860': 37.1018,
    '1237_861': 37.0515,
    '1237_862': 36.9891,
    '1237_863': 36.9088,
    '1237_864': 36.7965,
    '1237_865': 36.6399,
    '1237_866': 36.4751,
    '1237_867': 36.298,
    '1237_868': 36.1148,
    '1237_869': 35.9299,
    '1237_870': 35.7408,
    '1237_871': 35.544,
    '1237_872': 35.3376,
    '1237_873': 35.1221,
    '1237_874': 34.9044,
    '1237_875': 34.6636,
    '1237_876': 34.4099,
    '1237_877': 34.1411,
    '1237_878': 33.8512,
    '1237_879': 33.5446,
    '1237_880': 33.2229,
    '1237_881': 32.8859,
    '1237_882': 32.5379,
    '1237_883': 32.1851,
    '1237_884': 31.83,
    '1237_885': 31.4821,
    '1237_886': 31.1358,
    '1237_887': 30.7914,
    '1237_888': 30.4452,
    '1237_889': 30.1013,
    '1237_890': 29.754,
    '1237_891': 29.4042,
    '1237_892': 29.0555,
    '1237_893': 28.7094,
    '1237_894': 28.3636,
    '1237_895': 28.0202,
    '1237_896': 27.6801,
    '1237_897': 27.3392,
    '1237_898': 27.0107,
    '1237_899': 26.6882,
    '1237_900': 26.3176,
    '1238_790': 35.4707,
    '1238_791': 35.6511,
    '1238_792': 35.8492,
    '1238_793': 36.0375,
    '1238_794': 36.211,
    '1238_795': 36.3794,
    '1238_796': 36.5345,
    '1238_797': 36.675,
    '1238_798': 36.8155,
    '1238_799': 36.9621,
    '1238_800': 37.115,
    '1238_801': 37.2648,
    '1238_802': 37.3824,
    '1238_803': 37.481,
    '1238_804': 37.5643,
    '1238_805': 37.6171,
    '1238_806': 37.6669,
    '1238_807': 37.6994,
    '1238_808': 37.7166,
    '1238_809': 37.7326,
    '1238_810': 37.7567,
    '1238_811': 37.7822,
    '1238_812': 37.7874,
    '1238_813': 37.7536,
    '1238_814': 37.6882,
    '1238_815': 37.629,
    '1238_816': 37.5909,
    '1238_817': 37.5722,
    '1238_818': 37.5649,
    '1238_819': 37.5623,
    '1238_820': 37.5634,
    '1238_821': 37.5697,
    '1238_822': 37.5859,
    '1238_823': 37.6182,
    '1238_824': 37.6756,
    '1238_825': 37.7611,
    '1238_826': 37.8742,
    '1238_827': 38.0032,
    '1238_828': 38.1308,
    '1238_829': 38.2528,
    '1238_830': 38.3709,
    '1238_831': 38.4912,
    '1238_832': 38.6171,
    '1238_833': 38.7215,
    '1238_834': 38.7953,
    '1238_835': 38.8214,
    '1238_836': 38.8037,
    '1238_837': 38.7404,
    '1238_838': 38.6455,
    '1238_839': 38.5353,
    '1238_840': 38.4182,
    '1238_841': 38.2946,
    '1238_842': 38.1654,
    '1238_843': 38.0395,
    '1238_844': 37.9191,
    '1238_845': 37.7999,
    '1238_846': 37.6857,
    '1238_847': 37.5879,
    '1238_848': 37.5071,
    '1238_849': 37.4395,
    '1238_850': 37.3812,
    '1238_851': 37.331,
    '1238_852': 37.2854,
    '1238_853': 37.2422,
    '1238_854': 37.2002,
    '1238_855': 37.1626,
    '1238_856': 37.128,
    '1238_857': 37.1006,
    '1238_858': 37.0723,
    '1238_859': 37.0375,
    '1238_860': 36.9879,
    '1238_861': 36.9353,
    '1238_862': 36.8698,
    '1238_863': 36.7878,
    '1238_864': 36.6643,
    '1238_865': 36.5066,
    '1238_866': 36.3405,
    '1238_867': 36.164,
    '1238_868': 35.9832,
    '1238_869': 35.798,
    '1238_870': 35.6069,
    '1238_871': 35.4074,
    '1238_872': 35.1987,
    '1238_873': 34.9841,
    '1238_874': 34.7633,
    '1238_875': 34.526,
    '1238_876': 34.2695,
    '1238_877': 33.9991,
    '1238_878': 33.7108,
    '1238_879': 33.4071,
    '1238_880': 33.0847,
    '1238_881': 32.7443,
    '1238_882': 32.3942,
    '1238_883': 32.0378,
    '1238_884': 31.675,
    '1238_885': 31.3261,
    '1238_886': 30.9786,
    '1238_887': 30.6338,
    '1238_888': 30.2888,
    '1238_889': 29.9453,
    '1238_890': 29.6014,
    '1238_891': 29.2539,
    '1238_892': 28.9084,
    '1238_893': 28.5652,
    '1238_894': 28.2202,
    '1238_895': 27.8768,
    '1238_896': 27.5323,
    '1238_897': 27.1907,
    '1238_898': 26.8556,
    '1238_899': 26.5144,
    '1238_900': 26.1557,
    '1239_790': 35.3776,
    '1239_791': 35.5449,
    '1239_792': 35.7428,
    '1239_793': 35.9318,
    '1239_794': 36.1069,
    '1239_795': 36.2747,
    '1239_796': 36.4304,
    '1239_797': 36.5728,
    '1239_798': 36.7166,
    '1239_799': 36.8606,
    '1239_800': 37.008,
    '1239_801': 37.1566,
    '1239_802': 37.2824,
    '1239_803': 37.3833,
    '1239_804': 37.4638,
    '1239_805': 37.5154,
    '1239_806': 37.5527,
    '1239_807': 37.5874,
    '1239_808': 37.6128,
    '1239_809': 37.6396,
    '1239_810': 37.6809,
    '1239_811': 37.7196,
    '1239_812': 37.7233,
    '1239_813': 37.698,
    '1239_814': 37.6215,
    '1239_815': 37.5481,
    '1239_816': 37.5036,
    '1239_817': 37.4826,
    '1239_818': 37.4751,
    '1239_819': 37.4726,
    '1239_820': 37.4739,
    '1239_821': 37.4798,
    '1239_822': 37.4959,
    '1239_823': 37.5282,
    '1239_824': 37.583,
    '1239_825': 37.6636,
    '1239_826': 37.7733,
    '1239_827': 37.9017,
    '1239_828': 38.0347,
    '1239_829': 38.1594,
    '1239_830': 38.2773,
    '1239_831': 38.3887,
    '1239_832': 38.508,
    '1239_833': 38.6186,
    '1239_834': 38.6983,
    '1239_835': 38.726,
    '1239_836': 38.7129,
    '1239_837': 38.6643,
    '1239_838': 38.5808,
    '1239_839': 38.4778,
    '1239_840': 38.3652,
    '1239_841': 38.2406,
    '1239_842': 38.1027,
    '1239_843': 37.9662,
    '1239_844': 37.836,
    '1239_845': 37.7091,
    '1239_846': 37.5929,
    '1239_847': 37.4945,
    '1239_848': 37.4138,
    '1239_849': 37.3494,
    '1239_850': 37.2932,
    '1239_851': 37.2433,
    '1239_852': 37.1951,
    '1239_853': 37.1489,
    '1239_854': 37.1047,
    '1239_855': 37.0665,
    '1239_856': 37.0316,
    '1239_857': 37.0016,
    '1239_858': 36.9693,
    '1239_859': 36.9241,
    '1239_860': 36.878,
    '1239_861': 36.8237,
    '1239_862': 36.7556,
    '1239_863': 36.666,
    '1239_864': 36.5397,
    '1239_865': 36.3866,
    '1239_866': 36.2178,
    '1239_867': 36.0407,
    '1239_868': 35.8558,
    '1239_869': 35.6678,
    '1239_870': 35.4746,
    '1239_871': 35.2732,
    '1239_872': 35.0665,
    '1239_873': 34.8599,
    '1239_874': 34.6351,
    '1239_875': 34.3908,
    '1239_876': 34.1279,
    '1239_877': 33.8536,
    '1239_878': 33.5641,
    '1239_879': 33.2633,
    '1239_880': 32.9402,
    '1239_881': 32.5952,
    '1239_882': 32.2446,
    '1239_883': 31.8918,
    '1239_884': 31.5274,
    '1239_885': 31.1766,
    '1239_886': 30.8274,
    '1239_887': 30.4767,
    '1239_888': 30.1321,
    '1239_889': 29.7866,
    '1239_890': 29.4401,
    '1239_891': 29.0969,
    '1239_892': 28.7561,
    '1239_893': 28.4183,
    '1239_894': 28.0769,
    '1239_895': 27.7376,
    '1239_896': 27.3933,
    '1239_897': 27.0525,
    '1239_898': 26.7101,
    '1239_899': 26.3609,
    '1239_900': 26.0095,
    '1240_790': 35.2731,
    '1240_791': 35.4351,
    '1240_792': 35.62,
    '1240_793': 35.8223,
    '1240_794': 36.0051,
    '1240_795': 36.1712,
    '1240_796': 36.3265,
    '1240_797': 36.4708,
    '1240_798': 36.6147,
    '1240_799': 36.7601,
    '1240_800': 36.9062,
    '1240_801': 37.0594,
    '1240_802': 37.1896,
    '1240_803': 37.2768,
    '1240_804': 37.3401,
    '1240_805': 37.385,
    '1240_806': 37.4229,
    '1240_807': 37.4697,
    '1240_808': 37.5006,
    '1240_809': 37.5363,
    '1240_810': 37.5817,
    '1240_811': 37.6239,
    '1240_812': 37.6348,
    '1240_813': 37.6087,
    '1240_814': 37.5382,
    '1240_815': 37.4667,
    '1240_816': 37.423,
    '1240_817': 37.4022,
    '1240_818': 37.3947,
    '1240_819': 37.3927,
    '1240_820': 37.3919,
    '1240_821': 37.3962,
    '1240_822': 37.4115,
    '1240_823': 37.4429,
    '1240_824': 37.4951,
    '1240_825': 37.5724,
    '1240_826': 37.6793,
    '1240_827': 37.8026,
    '1240_828': 37.933,
    '1240_829': 38.0518,
    '1240_830': 38.1646,
    '1240_831': 38.2777,
    '1240_832': 38.3897,
    '1240_833': 38.5005,
    '1240_834': 38.5851,
    '1240_835': 38.6194,
    '1240_836': 38.6227,
    '1240_837': 38.5772,
    '1240_838': 38.5075,
    '1240_839': 38.4202,
    '1240_840': 38.3136,
    '1240_841': 38.1834,
    '1240_842': 38.0386,
    '1240_843': 37.893,
    '1240_844': 37.7499,
    '1240_845': 37.6149,
    '1240_846': 37.4994,
    '1240_847': 37.4024,
    '1240_848': 37.324,
    '1240_849': 37.2613,
    '1240_850': 37.2074,
    '1240_851': 37.158,
    '1240_852': 37.1079,
    '1240_853': 37.0579,
    '1240_854': 37.0118,
    '1240_855': 36.9739,
    '1240_856': 36.9399,
    '1240_857': 36.9104,
    '1240_858': 36.874,
    '1240_859': 36.8232,
    '1240_860': 36.773,
    '1240_861': 36.7132,
    '1240_862': 36.6371,
    '1240_863': 36.5337,
    '1240_864': 36.4111,
    '1240_865': 36.2637,
    '1240_866': 36.0939,
    '1240_867': 35.9173,
    '1240_868': 35.7261,
    '1240_869': 35.5353,
    '1240_870': 35.3404,
    '1240_871': 35.1367,
    '1240_872': 34.9328,
    '1240_873': 34.7188,
    '1240_874': 34.4873,
    '1240_875': 34.2363,
    '1240_876': 33.9676,
    '1240_877': 33.6949,
    '1240_878': 33.4039,
    '1240_879': 33.0975,
    '1240_880': 32.7714,
    '1240_881': 32.4285,
    '1240_882': 32.0769,
    '1240_883': 31.7219,
    '1240_884': 31.3686,
    '1240_885': 31.0222,
    '1240_886': 30.6741,
    '1240_887': 30.3212,
    '1240_888': 29.9826,
    '1240_889': 29.6375,
    '1240_890': 29.2843,
    '1240_891': 28.9399,
    '1240_892': 28.6021,
    '1240_893': 28.2702,
    '1240_894': 27.9294,
    '1240_895': 27.5977,
    '1240_896': 27.2499,
    '1240_897': 26.9117,
    '1240_898': 26.5657,
    '1240_899': 26.2122,
    '1240_900': 25.8698,
    '1241_790': 35.175,
    '1241_791': 35.3361,
    '1241_792': 35.5161,
    '1241_793': 35.7149,
    '1241_794': 35.9001,
    '1241_795': 36.0749,
    '1241_796': 36.2308,
    '1241_797': 36.3716,
    '1241_798': 36.5137,
    '1241_799': 36.6591,
    '1241_800': 36.8065,
    '1241_801': 36.9516,
    '1241_802': 37.0792,
    '1241_803': 37.1602,
    '1241_804': 37.2053,
    '1241_805': 37.2469,
    '1241_806': 37.2906,
    '1241_807': 37.3384,
    '1241_808': 37.3789,
    '1241_809': 37.4212,
    '1241_810': 37.4591,
    '1241_811': 37.4964,
    '1241_812': 37.5126,
    '1241_813': 37.4894,
    '1241_814': 37.4342,
    '1241_815': 37.3798,
    '1241_816': 37.3474,
    '1241_817': 37.3303,
    '1241_818': 37.3247,
    '1241_819': 37.3227,
    '1241_820': 37.3206,
    '1241_821': 37.323,
    '1241_822': 37.3364,
    '1241_823': 37.3666,
    '1241_824': 37.4169,
    '1241_825': 37.4922,
    '1241_826': 37.5943,
    '1241_827': 37.7102,
    '1241_828': 37.8314,
    '1241_829': 37.9452,
    '1241_830': 38.0524,
    '1241_831': 38.1631,
    '1241_832': 38.2738,
    '1241_833': 38.3841,
    '1241_834': 38.4723,
    '1241_835': 38.5155,
    '1241_836': 38.5183,
    '1241_837': 38.4772,
    '1241_838': 38.4254,
    '1241_839': 38.3614,
    '1241_840': 38.2584,
    '1241_841': 38.1298,
    '1241_842': 37.9782,
    '1241_843': 37.8212,
    '1241_844': 37.6675,
    '1241_845': 37.5276,
    '1241_846': 37.4104,
    '1241_847': 37.3142,
    '1241_848': 37.2395,
    '1241_849': 37.178,
    '1241_850': 37.1245,
    '1241_851': 37.0748,
    '1241_852': 37.0227,
    '1241_853': 36.9713,
    '1241_854': 36.9255,
    '1241_855': 36.8869,
    '1241_856': 36.8535,
    '1241_857': 36.8225,
    '1241_858': 36.7839,
    '1241_859': 36.7296,
    '1241_860': 36.6739,
    '1241_861': 36.6049,
    '1241_862': 36.5154,
    '1241_863': 36.4009,
    '1241_864': 36.2716,
    '1241_865': 36.1277,
    '1241_866': 35.9628,
    '1241_867': 35.7845,
    '1241_868': 35.5934,
    '1241_869': 35.3968,
    '1241_870': 35.1943,
    '1241_871': 34.9846,
    '1241_872': 34.7732,
    '1241_873': 34.5639,
    '1241_874': 34.3343,
    '1241_875': 34.0784,
    '1241_876': 33.8117,
    '1241_877': 33.5358,
    '1241_878': 33.2427,
    '1241_879': 32.9305,
    '1241_880': 32.6012,
    '1241_881': 32.258,
    '1241_882': 31.9054,
    '1241_883': 31.5494,
    '1241_884': 31.1976,
    '1241_885': 30.8467,
    '1241_886': 30.4919,
    '1241_887': 30.1347,
    '1241_888': 29.7968,
    '1241_889': 29.4554,
    '1241_890': 29.1168,
    '1241_891': 28.7792,
    '1241_892': 28.4444,
    '1241_893': 28.1114,
    '1241_894': 27.7777,
    '1241_895': 27.4458,
    '1241_896': 27.1033,
    '1241_897': 26.7511,
    '1241_898': 26.3991,
    '1241_899': 26.0472,
    '1241_900': 25.701,
    '1242_790': 35.0758,
    '1242_791': 35.237,
    '1242_792': 35.4115,
    '1242_793': 35.5955,
    '1242_794': 35.7897,
    '1242_795': 35.9667,
    '1242_796': 36.1273,
    '1242_797': 36.2712,
    '1242_798': 36.4121,
    '1242_799': 36.5533,
    '1242_800': 36.6971,
    '1242_801': 36.839,
    '1242_802': 36.9561,
    '1242_803': 37.0268,
    '1242_804': 37.0603,
    '1242_805': 37.1071,
    '1242_806': 37.161,
    '1242_807': 37.2047,
    '1242_808': 37.253,
    '1242_809': 37.2949,
    '1242_810': 37.3208,
    '1242_811': 37.3501,
    '1242_812': 37.366,
    '1242_813': 37.3552,
    '1242_814': 37.3241,
    '1242_815': 37.291,
    '1242_816': 37.2727,
    '1242_817': 37.2641,
    '1242_818': 37.2613,
    '1242_819': 37.2594,
    '1242_820': 37.2578,
    '1242_821': 37.2605,
    '1242_822': 37.2725,
    '1242_823': 37.299,
    '1242_824': 37.3465,
    '1242_825': 37.4199,
    '1242_826': 37.5166,
    '1242_827': 37.6252,
    '1242_828': 37.7382,
    '1242_829': 37.8391,
    '1242_830': 37.9382,
    '1242_831': 38.0455,
    '1242_832': 38.157,
    '1242_833': 38.2627,
    '1242_834': 38.3513,
    '1242_835': 38.4039,
    '1242_836': 38.4096,
    '1242_837': 38.3829,
    '1242_838': 38.3491,
    '1242_839': 38.2935,
    '1242_840': 38.196,
    '1242_841': 38.0677,
    '1242_842': 37.9111,
    '1242_843': 37.7456,
    '1242_844': 37.5851,
    '1242_845': 37.4442,
    '1242_846': 37.3278,
    '1242_847': 37.234,
    '1242_848': 37.1589,
    '1242_849': 37.0962,
    '1242_850': 37.0413,
    '1242_851': 36.9892,
    '1242_852': 36.9364,
    '1242_853': 36.8852,
    '1242_854': 36.8409,
    '1242_855': 36.8015,
    '1242_856': 36.7656,
    '1242_857': 36.7323,
    '1242_858': 36.6932,
    '1242_859': 36.6393,
    '1242_860': 36.581,
    '1242_861': 36.505,
    '1242_862': 36.4062,
    '1242_863': 36.2854,
    '1242_864': 36.1492,
    '1242_865': 35.9987,
    '1242_866': 35.831,
    '1242_867': 35.6497,
    '1242_868': 35.4564,
    '1242_869': 35.2552,
    '1242_870': 35.0476,
    '1242_871': 34.8354,
    '1242_872': 34.6278,
    '1242_873': 34.4174,
    '1242_874': 34.1881,
    '1242_875': 33.9247,
    '1242_876': 33.6565,
    '1242_877': 33.3794,
    '1242_878': 33.0838,
    '1242_879': 32.7645,
    '1242_880': 32.4317,
    '1242_881': 32.0872,
    '1242_882': 31.7329,
    '1242_883': 31.373,
    '1242_884': 31.0218,
    '1242_885': 30.6686,
    '1242_886': 30.3137,
    '1242_887': 29.9615,
    '1242_888': 29.6212,
    '1242_889': 29.2837,
    '1242_890': 28.9487,
    '1242_891': 28.6135,
    '1242_892': 28.2805,
    '1242_893': 27.9476,
    '1242_894': 27.6211,
    '1242_895': 27.2997,
    '1242_896': 26.9843,
    '1242_897': 26.5909,
    '1242_898': 26.2258,
    '1242_899': 25.8701,
    '1242_900': 25.5198,
    '1243_790': 34.9756,
    '1243_791': 35.1385,
    '1243_792': 35.3093,
    '1243_793': 35.4845,
    '1243_794': 35.6794,
    '1243_795': 35.8573,
    '1243_796': 36.0225,
    '1243_797': 36.1736,
    '1243_798': 36.309,
    '1243_799': 36.4426,
    '1243_800': 36.5773,
    '1243_801': 36.7086,
    '1243_802': 36.8181,
    '1243_803': 36.8798,
    '1243_804': 36.9118,
    '1243_805': 36.9645,
    '1243_806': 37.0273,
    '1243_807': 37.078,
    '1243_808': 37.1291,
    '1243_809': 37.1689,
    '1243_810': 37.189,
    '1243_811': 37.2119,
    '1243_812': 37.2285,
    '1243_813': 37.232,
    '1243_814': 37.2221,
    '1243_815': 37.2073,
    '1243_816': 37.2013,
    '1243_817': 37.2012,
    '1243_818': 37.2026,
    '1243_819': 37.2021,
    '1243_820': 37.2019,
    '1243_821': 37.2053,
    '1243_822': 37.2167,
    '1243_823': 37.2399,
    '1243_824': 37.2822,
    '1243_825': 37.3512,
    '1243_826': 37.4416,
    '1243_827': 37.5376,
    '1243_828': 37.6386,
    '1243_829': 37.7297,
    '1243_830': 37.8236,
    '1243_831': 37.9292,
    '1243_832': 38.0405,
    '1243_833': 38.1405,
    '1243_834': 38.2242,
    '1243_835': 38.2858,
    '1243_836': 38.3137,
    '1243_837': 38.3041,
    '1243_838': 38.2699,
    '1243_839': 38.2153,
    '1243_840': 38.1235,
    '1243_841': 37.9969,
    '1243_842': 37.838,
    '1243_843': 37.6697,
    '1243_844': 37.5055,
    '1243_845': 37.3663,
    '1243_846': 37.2511,
    '1243_847': 37.158,
    '1243_848': 37.0806,
    '1243_849': 37.0149,
    '1243_850': 36.9576,
    '1243_851': 36.9026,
    '1243_852': 36.849,
    '1243_853': 36.7987,
    '1243_854': 36.7539,
    '1243_855': 36.7144,
    '1243_856': 36.6766,
    '1243_857': 36.6403,
    '1243_858': 36.5958,
    '1243_859': 36.5513,
    '1243_860': 36.4941,
    '1243_861': 36.4126,
    '1243_862': 36.3052,
    '1243_863': 36.1767,
    '1243_864': 36.0328,
    '1243_865': 35.8742,
    '1243_866': 35.7001,
    '1243_867': 35.5144,
    '1243_868': 35.3177,
    '1243_869': 35.1137,
    '1243_870': 34.905,
    '1243_871': 34.6922,
    '1243_872': 34.4838,
    '1244_790': 34.8876,
    '1244_791': 35.0516,
    '1244_792': 35.2265,
    '1244_793': 35.411,
    '1244_794': 35.5901,
    '1244_795': 35.7539,
    '1244_796': 35.9187,
    '1244_797': 36.0716,
    '1244_798': 36.208,
    '1244_799': 36.3302,
    '1244_800': 36.4533,
    '1244_801': 36.5684,
    '1244_802': 36.6709,
    '1244_803': 36.7341,
    '1244_804': 36.764,
    '1244_805': 36.8201,
    '1244_806': 36.8884,
    '1244_807': 36.9513,
    '1244_808': 37.0047,
    '1244_809': 37.0428,
    '1244_810': 37.0648,
    '1244_811': 37.0887,
    '1244_812': 37.1066,
    '1244_813': 37.121,
    '1244_814': 37.1262,
    '1244_815': 37.1267,
    '1244_816': 37.1314,
    '1244_817': 37.1398,
    '1244_818': 37.1459,
    '1244_819': 37.1488,
    '1244_820': 37.1512,
    '1244_821': 37.1564,
    '1244_822': 37.1682,
    '1244_823': 37.1894,
    '1244_824': 37.2244,
    '1244_825': 37.2851,
    '1244_826': 37.3672,
    '1244_827': 37.4536,
    '1244_828': 37.5395,
    '1244_829': 37.6134,
    '1244_830': 37.7021,
    '1244_831': 37.8072,
    '1244_832': 37.914,
    '1244_833': 38.0126,
    '1244_834': 38.1003,
    '1244_835': 38.1729,
    '1244_836': 38.2128,
    '1244_837': 38.2137,
    '1244_838': 38.177,
    '1244_839': 38.1187,
    '1244_840': 38.0318,
    '1244_841': 37.9096,
    '1244_842': 37.7573,
    '1244_843': 37.5937,
    '1244_844': 37.4307,
    '1244_845': 37.292,
    '1244_846': 37.1775,
    '1244_847': 37.0843,
    '1244_848': 37.0057,
    '1244_849': 36.9374,
    '1244_850': 36.8763,
    '1244_851': 36.8182,
    '1244_852': 36.7629,
    '1244_853': 36.7112,
    '1244_854': 36.6644,
    '1244_855': 36.6239,
    '1244_856': 36.586,
    '1244_857': 36.5506,
    '1244_858': 36.5059,
    '1244_859': 36.4635,
    '1244_860': 36.4082,
    '1244_861': 36.32,
    '1244_862': 36.2052,
    '1244_863': 36.0689,
    '1244_864': 35.9165,
    '1244_865': 35.749,
    '1244_866': 35.5673,
    '1244_867': 35.3757,
    '1244_868': 35.1783,
    '1244_869': 34.9741,
    '1244_870': 34.7664,
    '1244_871': 34.5495,
    '1244_872': 34.3389,
    '1245_790': 34.7968,
    '1245_791': 34.9577,
    '1245_792': 35.1298,
    '1245_793': 35.308,
    '1245_794': 35.4797,
    '1245_795': 35.6462,
    '1245_796': 35.8091,
    '1245_797': 35.9647,
    '1245_798': 36.0979,
    '1245_799': 36.214,
    '1245_800': 36.3275,
    '1245_801': 36.4312,
    '1245_802': 36.5262,
    '1245_803': 36.5909,
    '1245_804': 36.6281,
    '1245_805': 36.6844,
    '1245_806': 36.7538,
    '1245_807': 36.8251,
    '1245_808': 36.8837,
    '1245_809': 36.9217,
    '1245_810': 36.9488,
    '1245_811': 36.9736,
    '1245_812': 36.9977,
    '1245_813': 37.0214,
    '1245_814': 37.0379,
    '1245_815': 37.051,
    '1245_816': 37.0657,
    '1245_817': 37.0821,
    '1245_818': 37.0938,
    '1245_819': 37.101,
    '1245_820': 37.1064,
    '1245_821': 37.1137,
    '1245_822': 37.1273,
    '1245_823': 37.1468,
    '1245_824': 37.1748,
    '1245_825': 37.223,
    '1245_826': 37.2944,
    '1245_827': 37.3679,
    '1245_828': 37.4361,
    '1245_829': 37.4968,
    '1245_830': 37.578,
    '1245_831': 37.6757,
    '1245_832': 37.7813,
    '1245_833': 37.8848,
    '1245_834': 37.9806,
    '1245_835': 38.0604,
    '1245_836': 38.109,
    '1245_837': 38.1176,
    '1245_838': 38.0763,
    '1245_839': 38.0112,
    '1245_840': 37.9292,
    '1245_841': 37.8149,
    '1245_842': 37.6769,
    '1245_843': 37.5244,
    '1245_844': 37.3643,
    '1245_845': 37.222,
    '1245_846': 37.1068,
    '1245_847': 37.0135,
    '1245_848': 36.9354,
    '1245_849': 36.8653,
    '1245_850': 36.8001,
    '1245_851': 36.7385,
    '1245_852': 36.6816,
    '1245_853': 36.6262,
    '1245_854': 36.576,
    '1245_855': 36.5331,
    '1245_856': 36.4958,
    '1245_857': 36.4613,
    '1245_858': 36.4151,
    '1245_859': 36.3687,
    '1245_860': 36.3075,
    '1245_861': 36.2195,
    '1245_862': 36.1011,
    '1245_863': 35.9592,
    '1245_864': 35.8007,
    '1245_865': 35.6259,
    '1245_866': 35.4377,
    '1245_867': 35.2404,
    '1245_868': 35.0458,
    '1245_869': 34.8427,
    '1245_870': 34.6348,
    '1245_871': 34.4114,
    '1245_872': 34.1995,
    '1246_790': 34.7025,
    '1246_791': 34.8596,
    '1246_792': 35.026,
    '1246_793': 35.1988,
    '1246_794': 35.3673,
    '1246_795': 35.5284,
    '1246_796': 35.6887,
    '1246_797': 35.8423,
    '1246_798': 35.9789,
    '1246_799': 36.0953,
    '1246_800': 36.2043,
    '1246_801': 36.3017,
    '1246_802': 36.3896,
    '1246_803': 36.4534,
    '1246_804': 36.5059,
    '1246_805': 36.5676,
    '1246_806': 36.6377,
    '1246_807': 36.7079,
    '1246_808': 36.7681,
    '1246_809': 36.8078,
    '1246_810': 36.8413,
    '1246_811': 36.8703,
    '1246_812': 36.9013,
    '1246_813': 36.9306,
    '1246_814': 36.9568,
    '1246_815': 36.9807,
    '1246_816': 37.0038,
    '1246_817': 37.0278,
    '1246_818': 37.0467,
    '1246_819': 37.0581,
    '1246_820': 37.0667,
    '1246_821': 37.0768,
    '1246_822': 37.0933,
    '1246_823': 37.1123,
    '1246_824': 37.1311,
    '1246_825': 37.1644,
    '1246_826': 37.2199,
    '1246_827': 37.278,
    '1246_828': 37.3345,
    '1246_829': 37.3898,
    '1246_830': 37.4642,
    '1246_831': 37.5549,
    '1246_832': 37.6572,
    '1246_833': 37.7654,
    '1246_834': 37.8641,
    '1246_835': 37.9454,
    '1246_836': 37.9978,
    '1246_837': 38.0076,
    '1246_838': 37.9694,
    '1246_839': 37.9056,
    '1246_840': 37.8236,
    '1246_841': 37.7174,
    '1246_842': 37.5925,
    '1246_843': 37.4516,
    '1246_844': 37.2993,
    '1246_845': 37.153,
    '1246_846': 37.0374,
    '1246_847': 36.9461,
    '1246_848': 36.8685,
    '1246_849': 36.7966,
    '1246_850': 36.7287,
    '1246_851': 36.6639,
    '1246_852': 36.6038,
    '1246_853': 36.545,
    '1246_854': 36.4924,
    '1246_855': 36.4474,
    '1246_856': 36.4101,
    '1246_857': 36.3755,
    '1246_858': 36.3263,
    '1246_859': 36.2743,
    '1246_860': 36.2051,
    '1246_861': 36.1104,
    '1246_862': 35.989,
    '1246_863': 35.8453,
    '1246_864': 35.6843,
    '1246_865': 35.5079,
    '1246_866': 35.3169,
    '1246_867': 35.1166,
    '1246_868': 34.9138,
    '1246_869': 34.7035,
    '1246_870': 34.4851,
    '1246_871': 34.2713,
    '1246_872': 34.0528,
    '1247_790': 34.6074,
    '1247_791': 34.7608,
    '1247_792': 34.9251,
    '1247_793': 35.0951,
    '1247_794': 35.2643,
    '1247_795': 35.4299,
    '1247_796': 35.5744,
    '1247_797': 35.7242,
    '1247_798': 35.8625,
    '1247_799': 35.984,
    '1247_800': 36.0893,
    '1247_801': 36.1749,
    '1247_802': 36.2526,
    '1247_803': 36.3184,
    '1247_804': 36.3863,
    '1247_805': 36.4554,
    '1247_806': 36.5263,
    '1247_807': 36.5995,
    '1247_808': 36.6613,
    '1247_809': 36.7039,
    '1247_810': 36.7402,
    '1247_811': 36.776,
    '1247_812': 36.8113,
    '1247_813': 36.8464,
    '1247_814': 36.8803,
    '1247_815': 36.913,
    '1247_816': 36.9456,
    '1247_817': 36.9774,
    '1247_818': 37.0031,
    '1247_819': 37.0176,
    '1247_820': 37.0297,
    '1247_821': 37.0445,
    '1247_822': 37.0665,
    '1247_823': 37.0861,
    '1247_824': 37.0951,
    '1247_825': 37.1113,
    '1247_826': 37.149,
    '1247_827': 37.1922,
    '1247_828': 37.2397,
    '1247_829': 37.2917,
    '1247_830': 37.3621,
    '1247_831': 37.4474,
    '1247_832': 37.5448,
    '1247_833': 37.6509,
    '1247_834': 37.749,
    '1247_835': 37.8255,
    '1247_836': 37.8747,
    '1247_837': 37.8876,
    '1247_838': 37.8594,
    '1247_839': 37.8082,
    '1247_840': 37.7282,
    '1247_841': 37.623,
    '1247_842': 37.5009,
    '1247_843': 37.3642,
    '1247_844': 37.2189,
    '1247_845': 37.079,
    '1247_846': 36.9664,
    '1247_847': 36.8765,
    '1247_848': 36.8003,
    '1247_849': 36.7291,
    '1247_850': 36.6592,
    '1247_851': 36.5939,
    '1247_852': 36.5294,
    '1247_853': 36.4676,
    '1247_854': 36.4136,
    '1247_855': 36.3668,
    '1247_856': 36.3303,
    '1247_857': 36.294,
    '1247_858': 36.242,
    '1247_859': 36.1853,
    '1247_860': 36.1085,
    '1247_861': 36.0047,
    '1247_862': 35.8771,
    '1247_863': 35.7302,
    '1247_864': 35.5674,
    '1247_865': 35.3897,
    '1247_866': 35.1957,
    '1247_867': 34.9909,
    '1247_868': 34.7815,
    '1247_869': 34.5673,
    '1247_870': 34.3454,
    '1247_871': 34.1309,
    '1247_872': 33.9058,
    '1248_790': 34.5134,
    '1248_791': 34.6649,
    '1248_792': 34.8264,
    '1248_793': 34.9917,
    '1248_794': 35.1534,
    '1248_795': 35.309,
    '1248_796': 35.4627,
    '1248_797': 35.6146,
    '1248_798': 35.7533,
    '1248_799': 35.8736,
    '1248_800': 35.9754,
    '1248_801': 36.0574,
    '1248_802': 36.1271,
    '1248_803': 36.1897,
    '1248_804': 36.2574,
    '1248_805': 36.3335,
    '1248_806': 36.4203,
    '1248_807': 36.4996,
    '1248_808': 36.5621,
    '1248_809': 36.6087,
    '1248_810': 36.6504,
    '1248_811': 36.6916,
    '1248_812': 36.7306,
    '1248_813': 36.7696,
    '1248_814': 36.8091,
    '1248_815': 36.8483,
    '1248_816': 36.8878,
    '1248_817': 36.9259,
    '1248_818': 36.955,
    '1248_819': 36.9738,
    '1248_820': 36.991,
    '1248_821': 37.0123,
    '1248_822': 37.039,
    '1248_823': 37.0594,
    '1248_824': 37.0564,
    '1248_825': 37.0567,
    '1248_826': 37.074,
    '1248_827': 37.1084,
    '1248_828': 37.1493,
    '1248_829': 37.2003,
    '1248_830': 37.2681,
    '1248_831': 37.3493,
    '1248_832': 37.4422,
    '1248_833': 37.5407,
    '1248_834': 37.6326,
    '1248_835': 37.7022,
    '1248_836': 37.7463,
    '1248_837': 37.7677,
    '1248_838': 37.7571,
    '1248_839': 37.7176,
    '1248_840': 37.6443,
    '1248_841': 37.5447,
    '1248_842': 37.4219,
    '1248_843': 37.2837,
    '1248_844': 37.1362,
    '1248_845': 36.9993,
    '1248_846': 36.8899,
    '1248_847': 36.8023,
    '1248_848': 36.7276,
    '1248_849': 36.6593,
    '1248_850': 36.5913,
    '1248_851': 36.5248,
    '1248_852': 36.457,
    '1248_853': 36.3932,
    '1248_854': 36.3386,
    '1248_855': 36.2925,
    '1248_856': 36.2538,
    '1248_857': 36.2146,
    '1248_858': 36.1616,
    '1248_859': 36.1023,
    '1248_860': 36.0185,
    '1248_861': 35.903,
    '1248_862': 35.7671,
    '1248_863': 35.6158,
    '1248_864': 35.4489,
    '1248_865': 35.266,
    '1248_866': 35.0665,
    '1248_867': 34.8582,
    '1248_868': 34.6426,
    '1248_869': 34.4196,
    '1248_870': 34.1912,
    '1248_871': 33.9731,
    '1248_872': 33.7467,
    '1249_790': 34.4321,
    '1249_791': 34.5774,
    '1249_792': 34.7331,
    '1249_793': 34.8928,
    '1249_794': 35.0544,
    '1249_795': 35.2142,
    '1249_796': 35.3715,
    '1249_797': 35.5113,
    '1249_798': 35.6489,
    '1249_799': 35.7653,
    '1249_800': 35.8636,
    '1249_801': 35.9378,
    '1249_802': 36.0006,
    '1249_803': 36.0573,
    '1249_804': 36.1268,
    '1249_805': 36.2061,
    '1249_806': 36.3078,
    '1249_807': 36.4069,
    '1249_808': 36.4698,
    '1249_809': 36.5156,
    '1249_810': 36.5633,
    '1249_811': 36.6112,
    '1249_812': 36.6558,
    '1249_813': 36.6991,
    '1249_814': 36.7437,
    '1249_815': 36.7875,
    '1249_816': 36.8291,
    '1249_817': 36.8691,
    '1249_818': 36.9025,
    '1249_819': 36.9256,
    '1249_820': 36.9479,
    '1249_821': 36.974,
    '1249_822': 37.0031,
    '1249_823': 37.0222,
    '1249_824': 37.0095,
    '1249_825': 36.996,
    '1249_826': 36.998,
    '1249_827': 37.02,
    '1249_828': 37.056,
    '1249_829': 37.1077,
    '1249_830': 37.1755,
    '1249_831': 37.2542,
    '1249_832': 37.3418,
    '1249_833': 37.4308,
    '1249_834': 37.5142,
    '1249_835': 37.5817,
    '1249_836': 37.6325,
    '1249_837': 37.6585,
    '1249_838': 37.6538,
    '1249_839': 37.6163,
    '1249_840': 37.5503,
    '1249_841': 37.4605,
    '1249_842': 37.3419,
    '1249_843': 37.2005,
    '1249_844': 37.0467,
    '1249_845': 36.9142,
    '1249_846': 36.8112,
    '1249_847': 36.7263,
    '1249_848': 36.6534,
    '1249_849': 36.5884,
    '1249_850': 36.5243,
    '1249_851': 36.4561,
    '1249_852': 36.384,
    '1249_853': 36.3174,
    '1249_854': 36.2605,
    '1249_855': 36.2134,
    '1249_856': 36.1737,
    '1249_857': 36.1258,
    '1249_858': 36.0794,
    '1249_859': 36.0182,
    '1249_860': 35.9228,
    '1249_861': 35.7998,
    '1249_862': 35.6576,
    '1249_863': 35.503,
    '1249_864': 35.3323,
    '1249_865': 35.1438,
    '1249_866': 34.9369,
    '1249_867': 34.7251,
    '1249_868': 34.5053,
    '1249_869': 34.2774,
    '1249_870': 34.0448,
    '1249_871': 33.8206,
    '1249_872': 33.5903,
    '1250_790': 34.3518,
    '1250_791': 34.4942,
    '1250_792': 34.6461,
    '1250_793': 34.8023,
    '1250_794': 34.9614,
    '1250_795': 35.1201,
    '1250_796': 35.272,
    '1250_797': 35.4074,
    '1250_798': 35.5406,
    '1250_799': 35.6521,
    '1250_800': 35.7426,
    '1250_801': 35.8146,
    '1250_802': 35.8771,
    '1250_803': 35.9386,
    '1250_804': 36.0098,
    '1250_805': 36.0926,
    '1250_806': 36.1883,
    '1250_807': 36.2825,
    '1250_808': 36.3592,
    '1250_809': 36.4185,
    '1250_810': 36.4768,
    '1250_811': 36.5336,
    '1250_812': 36.5867,
    '1250_813': 36.636,
    '1250_814': 36.6841,
    '1250_815': 36.7306,
    '1250_816': 36.7731,
    '1250_817': 36.8126,
    '1250_818': 36.8463,
    '1250_819': 36.8737,
    '1250_820': 36.9006,
    '1250_821': 36.9299,
    '1250_822': 36.958,
    '1250_823': 36.9735,
    '1250_824': 36.9542,
    '1250_825': 36.9295,
    '1250_826': 36.9198,
    '1250_827': 36.9316,
    '1250_828': 36.9635,
    '1250_829': 37.0149,
    '1250_830': 37.0829,
    '1250_831': 37.1597,
    '1250_832': 37.2412,
    '1250_833': 37.323,
    '1250_834': 37.4049,
    '1250_835': 37.481,
    '1250_836': 37.5342,
    '1250_837': 37.5483,
    '1250_838': 37.5364,
    '1250_839': 37.4981,
    '1250_840': 37.4421,
    '1250_841': 37.3653,
    '1250_842': 37.2549,
    '1250_843': 37.1108,
    '1250_844': 36.956,
    '1250_845': 36.8259,
    '1250_846': 36.7278,
    '1250_847': 36.6468,
    '1250_848': 36.5766,
    '1250_849': 36.5141,
    '1250_850': 36.4521,
    '1250_851': 36.3832,
    '1250_852': 36.3097,
    '1250_853': 36.2393,
    '1250_854': 36.1791,
    '1250_855': 36.1295,
    '1250_856': 36.0906,
    '1250_857': 36.0408,
    '1250_858': 35.992,
    '1250_859': 35.9248,
    '1250_860': 35.8251,
    '1250_861': 35.6993,
    '1250_862': 35.5543,
    '1250_863': 35.3943,
    '1250_864': 35.2178,
    '1250_865': 35.0254,
    '1250_866': 34.8155,
    '1250_867': 34.5976,
    '1250_868': 34.3723,
    '1250_869': 34.1416,
    '1250_870': 33.9056,
    '1250_871': 33.6765,
    '1250_872': 33.4409,
    '1251_790': 34.2725,
    '1251_791': 34.413,
    '1251_792': 34.5627,
    '1251_793': 34.715,
    '1251_794': 34.8707,
    '1251_795': 35.0279,
    '1251_796': 35.1782,
    '1251_797': 35.3066,
    '1251_798': 35.4406,
    '1251_799': 35.5446,
    '1251_800': 35.6268,
    '1251_801': 35.6957,
    '1251_802': 35.7581,
    '1251_803': 35.8239,
    '1251_804': 35.8975,
    '1251_805': 35.9814,
    '1251_806': 36.0719,
    '1251_807': 36.1631,
    '1251_808': 36.2513,
    '1251_809': 36.3288,
    '1251_810': 36.3943,
    '1251_811': 36.4604,
    '1251_812': 36.5217,
    '1251_813': 36.5782,
    '1251_814': 36.6301,
    '1251_815': 36.6781,
    '1251_816': 36.721,
    '1251_817': 36.7603,
    '1251_818': 36.7942,
    '1251_819': 36.8226,
    '1251_820': 36.8508,
    '1251_821': 36.8775,
    '1251_822': 36.9007,
    '1251_823': 36.9112,
    '1251_824': 36.8874,
    '1251_825': 36.8549,
    '1251_826': 36.8377,
    '1251_827': 36.8443,
    '1251_828': 36.8742,
    '1251_829': 36.9272,
    '1251_830': 36.994,
    '1251_831': 37.0671,
    '1251_832': 37.1423,
    '1251_833': 37.2172,
    '1251_834': 37.3022,
    '1251_835': 37.3819,
    '1251_836': 37.4254,
    '1251_837': 37.4294,
    '1251_838': 37.4096,
    '1251_839': 37.3714,
    '1251_840': 37.3167,
    '1251_841': 37.25,
    '1251_842': 37.1493,
    '1251_843': 37.0115,
    '1251_844': 36.8598,
    '1251_845': 36.7341,
    '1251_846': 36.6399,
    '1251_847': 36.564,
    '1251_848': 36.4985,
    '1251_849': 36.439,
    '1251_850': 36.3792,
    '1251_851': 36.3088,
    '1251_852': 36.2343,
    '1251_853': 36.1632,
    '1251_854': 36.1021,
    '1251_855': 36.0533,
    '1251_856': 36.0116,
    '1251_857': 35.9584,
    '1251_858': 35.9056,
    '1251_859': 35.8348,
    '1251_860': 35.7288,
    '1251_861': 35.599,
    '1251_862': 35.4505,
    '1251_863': 35.285,
    '1251_864': 35.1022,
    '1251_865': 34.9074,
    '1251_866': 34.6935,
    '1251_867': 34.4709,
    '1251_868': 34.2399,
    '1251_869': 34.0048,
    '1251_870': 33.7734,
    '1251_871': 33.5366,
    '1251_872': 33.291,
    '1252_790': 34.1949,
    '1252_791': 34.3333,
    '1252_792': 34.4807,
    '1252_793': 34.6275,
    '1252_794': 34.7801,
    '1252_795': 34.934,
    '1252_796': 35.0808,
    '1252_797': 35.2187,
    '1252_798': 35.3435,
    '1252_799': 35.4419,
    '1252_800': 35.517,
    '1252_801': 35.584,
    '1252_802': 35.6459,
    '1252_803': 35.7149,
    '1252_804': 35.7918,
    '1252_805': 35.8777,
    '1252_806': 35.9684,
    '1252_807': 36.0622,
    '1252_808': 36.1575,
    '1252_809': 36.2449,
    '1252_810': 36.3185,
    '1252_811': 36.3909,
    '1252_812': 36.4592,
    '1252_813': 36.523,
    '1252_814': 36.5786,
    '1252_815': 36.6289,
    '1252_816': 36.6732,
    '1252_817': 36.7124,
    '1252_818': 36.7457,
    '1252_819': 36.7734,
    '1252_820': 36.8013,
    '1252_821': 36.8226,
    '1252_822': 36.8376,
    '1252_823': 36.839,
    '1252_824': 36.8154,
    '1252_825': 36.7797,
    '1252_826': 36.7599,
    '1252_827': 36.7637,
    '1252_828': 36.7933,
    '1252_829': 36.8459,
    '1252_830': 36.9115,
    '1252_831': 36.98,
    '1252_832': 37.0483,
    '1252_833': 37.1188,
    '1252_834': 37.1967,
    '1252_835': 37.2674,
    '1252_836': 37.3027,
    '1252_837': 37.3022,
    '1252_838': 37.274,
    '1252_839': 37.2352,
    '1252_840': 37.182,
    '1252_841': 37.1141,
    '1252_842': 37.0191,
    '1252_843': 36.8926,
    '1252_844': 36.7565,
    '1252_845': 36.6385,
    '1252_846': 36.5486,
    '1252_847': 36.4776,
    '1252_848': 36.4191,
    '1252_849': 36.3637,
    '1252_850': 36.3033,
    '1252_851': 36.2356,
    '1252_852': 36.1617,
    '1252_853': 36.0914,
    '1252_854': 36.0284,
    '1252_855': 35.9788,
    '1252_856': 35.9348,
    '1252_857': 35.877,
    '1252_858': 35.8167,
    '1252_859': 35.7365,
    '1252_860': 35.628,
    '1252_861': 35.4956,
    '1252_862': 35.3411,
    '1252_863': 35.1662,
    '1252_864': 34.9771,
    '1252_865': 34.7792,
    '1252_866': 34.5651,
    '1252_867': 34.3414,
    '1252_868': 34.1089,
    '1252_869': 33.8719,
    '1252_870': 33.6361,
    '1252_871': 33.3948,
    '1252_872': 33.1412,
    '1253_790': 34.1278,
    '1253_791': 34.2566,
    '1253_792': 34.3902,
    '1253_793': 34.5365,
    '1253_794': 34.6853,
    '1253_795': 34.8439,
    '1253_796': 34.9882,
    '1253_797': 35.1198,
    '1253_798': 35.2464,
    '1253_799': 35.3428,
    '1253_800': 35.4131,
    '1253_801': 35.479,
    '1253_802': 35.5411,
    '1253_803': 35.6156,
    '1253_804': 35.6958,
    '1253_805': 35.7854,
    '1253_806': 35.8787,
    '1253_807': 35.9776,
    '1253_808': 36.0788,
    '1253_809': 36.1736,
    '1253_810': 36.2537,
    '1253_811': 36.33,
    '1253_812': 36.4013,
    '1253_813': 36.4701,
    '1253_814': 36.5295,
    '1253_815': 36.5819,
    '1253_816': 36.6269,
    '1253_817': 36.6659,
    '1253_818': 36.6983,
    '1253_819': 36.725,
    '1253_820': 36.7525,
    '1253_821': 36.7722,
    '1253_822': 36.7798,
    '1253_823': 36.7727,
    '1253_824': 36.7425,
    '1253_825': 36.7065,
    '1253_826': 36.6854,
    '1253_827': 36.6875,
    '1253_828': 36.719,
    '1253_829': 36.7714,
    '1253_830': 36.8325,
    '1253_831': 36.8972,
    '1253_832': 36.9614,
    '1253_833': 37.0279,
    '1253_834': 37.0958,
    '1253_835': 37.155,
    '1253_836': 37.1821,
    '1253_837': 37.177,
    '1253_838': 37.1441,
    '1253_839': 37.0966,
    '1253_840': 37.0388,
    '1253_841': 36.9682,
    '1253_842': 36.8747,
    '1253_843': 36.7647,
    '1253_844': 36.6507,
    '1253_845': 36.5458,
    '1253_846': 36.4582,
    '1253_847': 36.3917,
    '1253_848': 36.339,
    '1253_849': 36.2892,
    '1253_850': 36.2317,
    '1253_851': 36.1657,
    '1253_852': 36.0923,
    '1253_853': 36.0227,
    '1253_854': 35.9605,
    '1253_855': 35.9071,
    '1253_856': 35.8609,
    '1253_857': 35.7994,
    '1253_858': 35.7325,
    '1253_859': 35.643,
    '1253_860': 35.5296,
    '1253_861': 35.394,
    '1253_862': 35.2342,
    '1253_863': 35.0507,
    '1253_864': 34.8542,
    '1253_865': 34.6557,
    '1253_866': 34.4393,
    '1253_867': 34.2147,
    '1253_868': 33.978,
    '1253_869': 33.7352,
    '1253_870': 33.4949,
    '1253_871': 33.2472,
    '1253_872': 32.9907,
    '1254_790': 34.0706,
    '1254_791': 34.198,
    '1254_792': 34.3293,
    '1254_793': 34.4695,
    '1254_794': 34.6161,
    '1254_795': 34.768,
    '1254_796': 34.9124,
    '1254_797': 35.0426,
    '1254_798': 35.1632,
    '1254_799': 35.2631,
    '1254_800': 35.3359,
    '1254_801': 35.3988,
    '1254_802': 35.4637,
    '1254_803': 35.539,
    '1254_804': 35.6219,
    '1254_805': 35.7114,
    '1254_806': 35.8054,
    '1254_807': 35.9072,
    '1254_808': 36.0153,
    '1254_809': 36.1173,
    '1254_810': 36.2072,
    '1254_811': 36.2828,
    '1254_812': 36.3569,
    '1254_813': 36.4254,
    '1254_814': 36.4845,
    '1254_815': 36.5366,
    '1254_816': 36.5822,
    '1254_817': 36.6218,
    '1254_818': 36.655,
    '1254_819': 36.6819,
    '1254_820': 36.7069,
    '1254_821': 36.7271,
    '1254_822': 36.7294,
    '1254_823': 36.712,
    '1254_824': 36.6733,
    '1254_825': 36.6356,
    '1254_826': 36.6134,
    '1254_827': 36.6157,
    '1254_828': 36.6495,
    '1254_829': 36.6987,
    '1254_830': 36.7563,
    '1254_831': 36.8172,
    '1254_832': 36.8784,
    '1254_833': 36.9377,
    '1254_834': 36.9962,
    '1254_835': 37.0457,
    '1254_836': 37.0662,
    '1254_837': 37.053,
    '1254_838': 37.0184,
    '1254_839': 36.9644,
    '1254_840': 36.8978,
    '1254_841': 36.823,
    '1254_842': 36.7356,
    '1254_843': 36.6414,
    '1254_844': 36.5465,
    '1254_845': 36.4558,
    '1254_846': 36.3753,
    '1254_847': 36.3088,
    '1254_848': 36.2609,
    '1254_849': 36.217,
    '1254_850': 36.1624,
    '1254_851': 36.0989,
    '1254_852': 36.027,
    '1254_853': 35.9569,
    '1254_854': 35.896,
    '1254_855': 35.8415,
    '1254_856': 35.7908,
    '1254_857': 35.7254,
    '1254_858': 35.6534,
    '1254_859': 35.5516,
    '1254_860': 35.4331,
    '1254_861': 35.291,
    '1254_862': 35.1282,
    '1254_863': 34.9494,
    '1254_864': 34.7496,
    '1254_865': 34.5454,
    '1254_866': 34.3276,
    '1254_867': 34.0953,
    '1254_868': 33.8488,
    '1254_869': 33.5989,
    '1254_870': 33.3559,
    '1254_871': 33.1002,
    '1254_872': 32.8411,
    '1255_790': 34.0189,
    '1255_791': 34.1506,
    '1255_792': 34.2813,
    '1255_793': 34.4169,
    '1255_794': 34.5597,
    '1255_795': 34.7068,
    '1255_796': 34.8515,
    '1255_797': 34.9768,
    '1255_798': 35.0921,
    '1255_799': 35.1901,
    '1255_800': 35.272,
    '1255_801': 35.3337,
    '1255_802': 35.4018,
    '1255_803': 35.4812,
    '1255_804': 35.5682,
    '1255_805': 35.6594,
    '1255_806': 35.7539,
    '1255_807': 35.8582,
    '1255_808': 35.9752,
    '1255_809': 36.0825,
    '1255_810': 36.1746,
    '1255_811': 36.2502,
    '1255_812': 36.3242,
    '1255_813': 36.3907,
    '1255_814': 36.4481,
    '1255_815': 36.4981,
    '1255_816': 36.5432,
    '1255_817': 36.5838,
    '1255_818': 36.6184,
    '1255_819': 36.646,
    '1255_820': 36.6675,
    '1255_821': 36.685,
    '1255_822': 36.6879,
    '1255_823': 36.6632,
    '1255_824': 36.6163,
    '1255_825': 36.573,
    '1255_826': 36.5486,
    '1255_827': 36.5523,
    '1255_828': 36.5859,
    '1255_829': 36.6326,
    '1255_830': 36.6866,
    '1255_831': 36.7434,
    '1255_832': 36.7996,
    '1255_833': 36.8519,
    '1255_834': 36.9002,
    '1255_835': 36.9388,
    '1255_836': 36.9563,
    '1255_837': 36.941,
    '1255_838': 36.902,
    '1255_839': 36.8454,
    '1255_840': 36.775,
    '1255_841': 36.6986,
    '1255_842': 36.6191,
    '1255_843': 36.5381,
    '1255_844': 36.4533,
    '1255_845': 36.3725,
    '1255_846': 36.302,
    '1255_847': 36.2383,
    '1255_848': 36.1881,
    '1255_849': 36.1478,
    '1255_850': 36.096,
    '1255_851': 36.0365,
    '1255_852': 35.9685,
    '1255_853': 35.9001,
    '1255_854': 35.8372,
    '1255_855': 35.7776,
    '1255_856': 35.7205,
    '1255_857': 35.6487,
    '1255_858': 35.5721,
    '1255_859': 35.4495,
    '1255_860': 35.3228,
    '1255_861': 35.1557,
    '1255_862': 34.9844,
    '1255_863': 34.8029,
    '1255_864': 34.6032,
    '1255_865': 34.4093,
    '1255_866': 34.2013,
    '1255_867': 33.9704,
    '1255_868': 33.7171,
    '1255_869': 33.4665,
    '1255_870': 33.2216,
    '1255_871': 32.9574,
    '1255_872': 32.6947,
    '1256_790': 33.962,
    '1256_791': 34.0957,
    '1256_792': 34.2351,
    '1256_793': 34.3703,
    '1256_794': 34.5095,
    '1256_795': 34.6499,
    '1256_796': 34.7898,
    '1256_797': 34.9154,
    '1256_798': 35.0316,
    '1256_799': 35.1235,
    '1256_800': 35.2204,
    '1256_801': 35.2791,
    '1256_802': 35.352,
    '1256_803': 35.4398,
    '1256_804': 35.534,
    '1256_805': 35.6319,
    '1256_806': 35.7344,
    '1256_807': 35.8472,
    '1256_808': 35.965,
    '1256_809': 36.0692,
    '1256_810': 36.1564,
    '1256_811': 36.2272,
    '1256_812': 36.2968,
    '1256_813': 36.3605,
    '1256_814': 36.4154,
    '1256_815': 36.4632,
    '1256_816': 36.5053,
    '1256_817': 36.5448,
    '1256_818': 36.5823,
    '1256_819': 36.6152,
    '1256_820': 36.6416,
    '1256_821': 36.6578,
    '1256_822': 36.6565,
    '1256_823': 36.6219,
    '1256_824': 36.5651,
    '1256_825': 36.5169,
    '1256_826': 36.4911,
    '1256_827': 36.4957,
    '1256_828': 36.5283,
    '1256_829': 36.5721,
    '1256_830': 36.6224,
    '1256_831': 36.6749,
    '1256_832': 36.7265,
    '1256_833': 36.7734,
    '1256_834': 36.8157,
    '1256_835': 36.849,
    '1256_836': 36.8654,
    '1256_837': 36.8503,
    '1256_838': 36.8083,
    '1256_839': 36.7492,
    '1256_840': 36.6778,
    '1256_841': 36.6019,
    '1256_842': 36.5274,
    '1256_843': 36.4523,
    '1256_844': 36.3739,
    '1256_845': 36.2993,
    '1256_846': 36.2338,
    '1256_847': 36.1729,
    '1256_848': 36.121,
    '1256_849': 36.0804,
    '1256_850': 36.0318,
    '1256_851': 35.9764,
    '1256_852': 35.9124,
    '1256_853': 35.8456,
    '1256_854': 35.7806,
    '1256_855': 35.716,
    '1256_856': 35.6455,
    '1256_857': 35.5532,
    '1256_858': 35.4503,
    '1256_859': 35.3322,
    '1256_860': 35.1935,
    '1256_861': 35.0294,
    '1256_862': 34.8544,
    '1256_863': 34.6689,
    '1256_864': 34.4723,
    '1256_865': 34.2714,
    '1256_866': 34.0563,
    '1256_867': 33.8195,
    '1256_868': 33.5647,
    '1256_869': 33.3083,
    '1256_870': 33.0496,
    '1256_871': 32.7892,
    '1256_872': 32.5228,
    '1257_790': 33.923,
    '1257_791': 34.0521,
    '1257_792': 34.2006,
    '1257_793': 34.3355,
    '1257_794': 34.4705,
    '1257_795': 34.6087,
    '1257_796': 34.7452,
    '1257_797': 34.8719,
    '1257_798': 34.986,
    '1257_799': 35.086,
    '1257_800': 35.1777,
    '1257_801': 35.2528,
    '1257_802': 35.3241,
    '1257_803': 35.4163,
    '1257_804': 35.5162,
    '1257_805': 35.6189,
    '1257_806': 35.7263,
    '1257_807': 35.8461,
    '1257_808': 35.9664,
    '1257_809': 36.0645,
    '1257_810': 36.1436,
    '1257_811': 36.208,
    '1257_812': 36.2723,
    '1257_813': 36.3331,
    '1257_814': 36.3869,
    '1257_815': 36.4319,
    '1257_816': 36.4701,
    '1257_817': 36.5072,
    '1257_818': 36.547,
    '1257_819': 36.5864,
    '1257_820': 36.6241,
    '1257_821': 36.6398,
    '1257_822': 36.63,
    '1257_823': 36.5798,
    '1257_824': 36.5141,
    '1257_825': 36.4633,
    '1257_826': 36.4367,
    '1257_827': 36.4426,
    '1257_828': 36.4731,
    '1257_829': 36.5135,
    '1257_830': 36.5599,
    '1257_831': 36.6085,
    '1257_832': 36.6564,
    '1257_833': 36.7001,
    '1257_834': 36.7413,
    '1257_835': 36.773,
    '1257_836': 36.7881,
    '1257_837': 36.7749,
    '1257_838': 36.7314,
    '1257_839': 36.6736,
    '1257_840': 36.5994,
    '1257_841': 36.5238,
    '1257_842': 36.4482,
    '1257_843': 36.3742,
    '1257_844': 36.3005,
    '1257_845': 36.2291,
    '1257_846': 36.1638,
    '1257_847': 36.1079,
    '1257_848': 36.0558,
    '1257_849': 36.0118,
    '1257_850': 35.9669,
    '1257_851': 35.9137,
    '1257_852': 35.8559,
    '1257_853': 35.791,
    '1257_854': 35.7242,
    '1257_855': 35.6538,
    '1257_856': 35.5741,
    '1257_857': 35.4726,
    '1257_858': 35.3649,
    '1257_859': 35.231,
    '1257_860': 35.0757,
    '1257_861': 34.909,
    '1257_862': 34.7312,
    '1257_863': 34.5418,
    '1257_864': 34.3424,
    '1257_865': 34.1329,
    '1257_866': 33.9099,
    '1257_867': 33.6675,
    '1257_868': 33.412,
    '1257_869': 33.1504,
    '1257_870': 32.8872,
    '1257_871': 32.6229,
    '1257_872': 32.3521,
    '1258_790': 33.8839,
    '1258_791': 34.0014,
    '1258_792': 34.1498,
    '1258_793': 34.297,
    '1258_794': 34.4368,
    '1258_795': 34.5784,
    '1258_796': 34.7105,
    '1258_797': 34.8315,
    '1258_798': 34.9458,
    '1258_799': 35.0469,
    '1258_800': 35.142,
    '1258_801': 35.2294,
    '1258_802': 35.3132,
    '1258_803': 35.4081,
    '1258_804': 35.512,
    '1258_805': 35.6176,
    '1258_806': 35.7281,
    '1258_807': 35.8427,
    '1258_808': 35.954,
    '1258_809': 36.0491,
    '1258_810': 36.1278,
    '1258_811': 36.1907,
    '1258_812': 36.2512,
    '1258_813': 36.3088,
    '1258_814': 36.3624,
    '1258_815': 36.4061,
    '1258_816': 36.4399,
    '1258_817': 36.4737,
    '1258_818': 36.5155,
    '1258_819': 36.5614,
    '1258_820': 36.602,
    '1258_821': 36.6155,
    '1258_822': 36.5931,
    '1258_823': 36.5303,
    '1258_824': 36.4621,
    '1258_825': 36.4119,
    '1258_826': 36.3857,
    '1258_827': 36.3913,
    '1258_828': 36.4199,
    '1258_829': 36.457,
    '1258_830': 36.4995,
    '1258_831': 36.5439,
    '1258_832': 36.588,
    '1258_833': 36.6289,
    '1258_834': 36.6677,
    '1258_835': 36.6976,
    '1258_836': 36.7071,
    '1258_837': 36.6957,
    '1258_838': 36.6576,
    '1258_839': 36.5978,
    '1258_840': 36.5244,
    '1258_841': 36.4497,
    '1258_842': 36.3753,
    '1258_843': 36.3024,
    '1258_844': 36.2305,
    '1258_845': 36.1611,
    '1258_846': 36.0972,
    '1258_847': 36.0426,
    '1258_848': 35.991,
    '1258_849': 35.945,
    '1258_850': 35.9023,
    '1258_851': 35.854,
    '1258_852': 35.7989,
    '1258_853': 35.7362,
    '1258_854': 35.6697,
    '1258_855': 35.5933,
    '1258_856': 35.505,
    '1258_857': 35.3948,
    '1258_858': 35.2812,
    '1258_859': 35.1408,
    '1258_860': 34.9754,
    '1258_861': 34.7998,
    '1258_862': 34.6155,
    '1258_863': 34.4203,
    '1258_864': 34.2129,
    '1258_865': 33.9939,
    '1258_866': 33.7627,
    '1258_867': 33.518,
    '1258_868': 33.262,
    '1258_869': 32.9984,
    '1258_870': 32.7324,
    '1258_871': 32.4633,
    '1258_872': 32.1873,
    '1259_790': 33.8546,
    '1259_791': 33.9672,
    '1259_792': 34.1066,
    '1259_793': 34.2623,
    '1259_794': 34.4183,
    '1259_795': 34.5571,
    '1259_796': 34.68,
    '1259_797': 34.7918,
    '1259_798': 34.9086,
    '1259_799': 35.0078,
    '1259_800': 35.1092,
    '1259_801': 35.2066,
    '1259_802': 35.3004,
    '1259_803': 35.4015,
    '1259_804': 35.5128,
    '1259_805': 35.6205,
    '1259_806': 35.733,
    '1259_807': 35.8411,
    '1259_808': 35.9448,
    '1259_809': 36.0353,
    '1259_810': 36.1136,
    '1259_811': 36.1804,
    '1259_812': 36.234,
    '1259_813': 36.2878,
    '1259_814': 36.3387,
    '1259_815': 36.38,
    '1259_816': 36.4103,
    '1259_817': 36.4413,
    '1259_818': 36.4836,
    '1259_819': 36.5328,
    '1259_820': 36.5697,
    '1259_821': 36.5773,
    '1259_822': 36.5396,
    '1259_823': 36.47,
    '1259_824': 36.4046,
    '1259_825': 36.3588,
    '1259_826': 36.3356,
    '1259_827': 36.3414,
    '1259_828': 36.3678,
    '1259_829': 36.4015,
    '1259_830': 36.4399,
    '1259_831': 36.4796,
    '1259_832': 36.5188,
    '1259_833': 36.5551,
    '1259_834': 36.5841,
    '1259_835': 36.6056,
    '1259_836': 36.6135,
    '1259_837': 36.6036,
    '1259_838': 36.568,
    '1259_839': 36.5112,
    '1259_840': 36.4449,
    '1259_841': 36.3743,
    '1259_842': 36.3025,
    '1259_843': 36.2312,
    '1259_844': 36.161,
    '1259_845': 36.093,
    '1259_846': 36.0298,
    '1259_847': 35.9724,
    '1259_848': 35.9244,
    '1259_849': 35.8786,
    '1259_850': 35.8386,
    '1259_851': 35.7953,
    '1259_852': 35.7436,
    '1259_853': 35.6852,
    '1259_854': 35.6186,
    '1259_855': 35.5351,
    '1259_856': 35.4377,
    '1259_857': 35.3175,
    '1259_858': 35.1928,
    '1259_859': 35.0537,
    '1259_860': 34.8767,
    '1259_861': 34.693,
    '1259_862': 34.5024,
    '1259_863': 34.3016,
    '1259_864': 34.086,
    '1259_865': 33.8585,
    '1259_866': 33.6206,
    '1259_867': 33.3735,
    '1259_868': 33.1158,
    '1259_869': 32.8491,
    '1259_870': 32.5808,
    '1259_871': 32.3073,
    '1259_872': 32.029,
    '1260_790': 33.8307,
    '1260_791': 33.9247,
    '1260_792': 34.0504,
    '1260_793': 34.2133,
    '1260_794': 34.378,
    '1260_795': 34.5232,
    '1260_796': 34.6377,
    '1260_797': 34.7524,
    '1260_798': 34.8652,
    '1260_799': 34.9696,
    '1260_800': 35.0768,
    '1260_801': 35.1764,
    '1260_802': 35.2822,
    '1260_803': 35.3932,
    '1260_804': 35.5089,
    '1260_805': 35.6294,
    '1260_806': 35.7432,
    '1260_807': 35.8468,
    '1260_808': 35.9428,
    '1260_809': 36.0267,
    '1260_810': 36.1042,
    '1260_811': 36.1712,
    '1260_812': 36.221,
    '1260_813': 36.27,
    '1260_814': 36.3151,
    '1260_815': 36.3524,
    '1260_816': 36.3818,
    '1260_817': 36.4116,
    '1260_818': 36.4504,
    '1260_819': 36.498,
    '1260_820': 36.5293,
    '1260_821': 36.5251,
    '1260_822': 36.4743,
    '1260_823': 36.4024,
    '1260_824': 36.3436,
    '1260_825': 36.3046,
    '1260_826': 36.2855,
    '1260_827': 36.2954,
    '1260_828': 36.3177,
    '1260_829': 36.3478,
    '1260_830': 36.3819,
    '1260_831': 36.4165,
    '1260_832': 36.4499,
    '1260_833': 36.4798,
    '1260_834': 36.5015,
    '1260_835': 36.5171,
    '1260_836': 36.5199,
    '1260_837': 36.5031,
    '1260_838': 36.47,
    '1260_839': 36.4212,
    '1260_840': 36.3618,
    '1260_841': 36.2962,
    '1260_842': 36.228,
    '1260_843': 36.1592,
    '1260_844': 36.0908,
    '1260_845': 36.0239,
    '1260_846': 35.9613,
    '1260_847': 35.9039,
    '1260_848': 35.8566,
    '1260_849': 35.8119,
    '1260_850': 35.7751,
    '1260_851': 35.737,
    '1260_852': 35.6936,
    '1260_853': 35.6418,
    '1260_854': 35.5738,
    '1260_855': 35.4791,
    '1260_856': 35.3724,
    '1260_857': 35.2449,
    '1260_858': 35.1143,
    '1260_859': 34.9631,
    '1260_860': 34.7773,
    '1260_861': 34.5858,
    '1260_862': 34.3889,
    '1260_863': 34.1823,
    '1260_864': 33.9629,
    '1260_865': 33.7315,
    '1260_866': 33.4887,
    '1260_867': 33.2361,
    '1260_868': 32.9743,
    '1260_869': 32.7057,
    '1260_870': 32.432,
    '1260_871': 32.151,
    '1260_872': 31.865,
    '1261_790': 33.8207,
    '1261_791': 33.9153,
    '1261_792': 34.0326,
    '1261_793': 34.1845,
    '1261_794': 34.3501,
    '1261_795': 34.5099,
    '1261_796': 34.6251,
    '1261_797': 34.7308,
    '1261_798': 34.8378,
    '1261_799': 34.9402,
    '1261_800': 35.0486,
    '1261_801': 35.1513,
    '1261_802': 35.2698,
    '1261_803': 35.3855,
    '1261_804': 35.5023,
    '1261_805': 35.6258,
    '1261_806': 35.7369,
    '1261_807': 35.8397,
    '1261_808': 35.9378,
    '1261_809': 36.0204,
    '1261_810': 36.0994,
    '1261_811': 36.1661,
    '1261_812': 36.2103,
    '1261_813': 36.2533,
    '1261_814': 36.2944,
    '1261_815': 36.3298,
    '1261_816': 36.3577,
    '1261_817': 36.3849,
    '1261_818': 36.419,
    '1261_819': 36.4552,
    '1261_820': 36.4732,
    '1261_821': 36.4557,
    '1261_822': 36.3981,
    '1261_823': 36.3335,
    '1261_824': 36.2849,
    '1261_825': 36.2529,
    '1261_826': 36.2379,
    '1261_827': 36.2492,
    '1261_828': 36.2696,
    '1261_829': 36.2962,
    '1261_830': 36.3257,
    '1261_831': 36.355,
    '1261_832': 36.3822,
    '1261_833': 36.4053,
    '1261_834': 36.4196,
    '1261_835': 36.4272,
    '1261_836': 36.4245,
    '1261_837': 36.4066,
    '1261_838': 36.3751,
    '1261_839': 36.3315,
    '1261_840': 36.2775,
    '1261_841': 36.2166,
    '1261_842': 36.1519,
    '1261_843': 36.0857,
    '1261_844': 36.0188,
    '1261_845': 35.9529,
    '1261_846': 35.8905,
    '1261_847': 35.8328,
    '1261_848': 35.7853,
    '1261_849': 35.7423,
    '1261_850': 35.7077,
    '1261_851': 35.6779,
    '1261_852': 35.6456,
    '1261_853': 35.6038,
    '1261_854': 35.5361,
    '1261_855': 35.4239,
    '1261_856': 35.3053,
    '1261_857': 35.1671,
    '1261_858': 35.0221,
    '1261_859': 34.8562,
    '1261_860': 34.6667,
    '1261_861': 34.4755,
    '1261_862': 34.2762,
    '1261_863': 34.0664,
    '1261_864': 33.8443,
    '1261_865': 33.6097,
    '1261_866': 33.362,
    '1261_867': 33.1029,
    '1261_868': 32.8326,
    '1261_869': 32.5543,
    '1261_870': 32.2769,
    '1261_871': 31.9937,
    '1261_872': 31.7035,
    '1262_790': 33.7936,
    '1262_791': 33.8959,
    '1262_792': 34.0146,
    '1262_793': 34.1619,
    '1262_794': 34.3207,
    '1262_795': 34.483,
    '1262_796': 34.6151,
    '1262_797': 34.723,
    '1262_798': 34.8382,
    '1262_799': 34.9538,
    '1262_800': 35.06,
    '1262_801': 35.1701,
    '1262_802': 35.2895,
    '1262_803': 35.407,
    '1262_804': 35.5271,
    '1262_805': 35.648,
    '1262_806': 35.7602,
    '1262_807': 35.8639,
    '1262_808': 35.956,
    '1262_809': 36.0337,
    '1262_810': 36.1026,
    '1262_811': 36.1599,
    '1262_812': 36.1993,
    '1262_813': 36.2381,
    '1262_814': 36.2793,
    '1262_815': 36.3148,
    '1262_816': 36.3416,
    '1262_817': 36.3656,
    '1262_818': 36.3864,
    '1262_819': 36.4058,
    '1262_820': 36.411,
    '1262_821': 36.3816,
    '1262_822': 36.3229,
    '1262_823': 36.2703,
    '1262_824': 36.2337,
    '1262_825': 36.2079,
    '1262_826': 36.1956,
    '1262_827': 36.2063,
    '1262_828': 36.2238,
    '1262_829': 36.2465,
    '1262_830': 36.2713,
    '1262_831': 36.2952,
    '1262_832': 36.3164,
    '1262_833': 36.3331,
    '1262_834': 36.3408,
    '1262_835': 36.3423,
    '1262_836': 36.3365,
    '1262_837': 36.3171,
    '1262_838': 36.2854,
    '1262_839': 36.2438,
    '1262_840': 36.1935,
    '1262_841': 36.1363,
    '1262_842': 36.0745,
    '1262_843': 36.0102,
    '1262_844': 35.9447,
    '1262_845': 35.8791,
    '1262_846': 35.8162,
    '1262_847': 35.7571,
    '1262_848': 35.7047,
    '1262_849': 35.6646,
    '1262_850': 35.6313,
    '1262_851': 35.6096,
    '1262_852': 35.5906,
    '1262_853': 35.5572,
    '1262_854': 35.4843,
    '1262_855': 35.3637,
    '1262_856': 35.2354,
    '1262_857': 35.0909,
    '1262_858': 34.9401,
    '1262_859': 34.7609,
    '1262_860': 34.5693,
    '1262_861': 34.3728,
    '1262_862': 34.1687,
    '1262_863': 33.9553,
    '1262_864': 33.73,
    '1262_865': 33.4915,
    '1262_866': 33.2388,
    '1262_867': 32.9738,
    '1262_868': 32.6962,
    '1262_869': 32.4115,
    '1262_870': 32.1255,
    '1262_871': 31.8359,
    '1262_872': 31.5426,
    '1263_790': 33.8079,
    '1263_791': 33.9052,
    '1263_792': 34.021,
    '1263_793': 34.1605,
    '1263_794': 34.3175,
    '1263_795': 34.4801,
    '1263_796': 34.6276,
    '1263_797': 34.7416,
    '1263_798': 34.8522,
    '1263_799': 34.9655,
    '1263_800': 35.0812,
    '1263_801': 35.1992,
    '1263_802': 35.3184,
    '1263_803': 35.438,
    '1263_804': 35.5634,
    '1263_805': 35.6828,
    '1263_806': 35.7942,
    '1263_807': 35.8969,
    '1263_808': 35.9813,
    '1263_809': 36.0501,
    '1263_810': 36.109,
    '1263_811': 36.1577,
    '1263_812': 36.1928,
    '1263_813': 36.2304,
    '1263_814': 36.2726,
    '1263_815': 36.3087,
    '1263_816': 36.3348,
    '1263_817': 36.3523,
    '1263_818': 36.357,
    '1263_819': 36.3538,
    '1263_820': 36.3421,
    '1263_821': 36.3084,
    '1263_822': 36.2597,
    '1263_823': 36.2182,
    '1263_824': 36.19,
    '1263_825': 36.1704,
    '1263_826': 36.1583,
    '1263_827': 36.1673,
    '1263_828': 36.1814,
    '1263_829': 36.1998,
    '1263_830': 36.2198,
    '1263_831': 36.2384,
    '1263_832': 36.2542,
    '1263_833': 36.2655,
    '1263_834': 36.2697,
    '1263_835': 36.2659,
    '1263_836': 36.2547,
    '1263_837': 36.2331,
    '1263_838': 36.201,
    '1263_839': 36.1606,
    '1263_840': 36.1124,
    '1263_841': 36.0573,
    '1263_842': 35.9975,
    '1263_843': 35.9348,
    '1263_844': 35.8698,
    '1263_845': 35.8042,
    '1263_846': 35.7402,
    '1263_847': 35.6791,
    '1263_848': 35.6237,
    '1263_849': 35.581,
    '1263_850': 35.5466,
    '1263_851': 35.53,
    '1263_852': 35.5206,
    '1263_853': 35.4918,
    '1263_854': 35.4184,
    '1263_855': 35.2961,
    '1263_856': 35.1602,
    '1263_857': 35.0087,
    '1263_858': 34.8484,
    '1263_859': 34.6656,
    '1263_860': 34.4753,
    '1263_861': 34.2762,
    '1263_862': 34.0691,
    '1263_863': 33.8497,
    '1263_864': 33.6196,
    '1263_865': 33.3763,
    '1263_866': 33.1183,
    '1263_867': 32.8479,
    '1263_868': 32.5629,
    '1263_869': 32.2717,
    '1263_870': 31.98,
    '1263_871': 31.6856,
    '1263_872': 31.388,
    '1264_790': 33.8295,
    '1264_791': 33.9246,
    '1264_792': 34.0338,
    '1264_793': 34.1673,
    '1264_794': 34.3222,
    '1264_795': 34.4819,
    '1264_796': 34.6314,
    '1264_797': 34.7607,
    '1264_798': 34.879,
    '1264_799': 34.9959,
    '1264_800': 35.1151,
    '1264_801': 35.236,
    '1264_802': 35.3534,
    '1264_803': 35.4705,
    '1264_804': 35.5964,
    '1264_805': 35.714,
    '1264_806': 35.8209,
    '1264_807': 35.9143,
    '1264_808': 35.9901,
    '1264_809': 36.0575,
    '1264_810': 36.1116,
    '1264_811': 36.1552,
    '1264_812': 36.1879,
    '1264_813': 36.2257,
    '1264_814': 36.2678,
    '1264_815': 36.3058,
    '1264_816': 36.335,
    '1264_817': 36.3476,
    '1264_818': 36.337,
    '1264_819': 36.3113,
    '1264_820': 36.2828,
    '1264_821': 36.2478,
    '1264_822': 36.2094,
    '1264_823': 36.1811,
    '1264_824': 36.1611,
    '1264_825': 36.1434,
    '1264_826': 36.1283,
    '1264_827': 36.1326,
    '1264_828': 36.1418,
    '1264_829': 36.1556,
    '1264_830': 36.1713,
    '1264_831': 36.1854,
    '1264_832': 36.1963,
    '1264_833': 36.2026,
    '1264_834': 36.2023,
    '1264_835': 36.1941,
    '1264_836': 36.1792,
    '1264_837': 36.1552,
    '1264_838': 36.1222,
    '1264_839': 36.082,
    '1264_840': 36.0348,
    '1264_841': 35.981,
    '1264_842': 35.9223,
    '1264_843': 35.8603,
    '1264_844': 35.7955,
    '1264_845': 35.7295,
    '1264_846': 35.6641,
    '1264_847': 35.6005,
    '1264_848': 35.5418,
    '1264_849': 35.4954,
    '1264_850': 35.4583,
    '1264_851': 35.4438,
    '1264_852': 35.4363,
    '1264_853': 35.4106,
    '1264_854': 35.3416,
    '1264_855': 35.2204,
    '1264_856': 35.0819,
    '1264_857': 34.9295,
    '1264_858': 34.7736,
    '1264_859': 34.5859,
    '1264_860': 34.3893,
    '1264_861': 34.1842,
    '1264_862': 33.9697,
    '1264_863': 33.7442,
    '1264_864': 33.508,
    '1264_865': 33.2594,
    '1264_866': 32.9958,
    '1264_867': 32.7186,
    '1264_868': 32.4298,
    '1264_869': 32.1354,
    '1264_870': 31.8383,
    '1264_871': 31.5394,
    '1264_872': 31.2375,
    '1265_790': 33.8453,
    '1265_791': 33.938,
    '1265_792': 34.0494,
    '1265_793': 34.1835,
    '1265_794': 34.3353,
    '1265_795': 34.4913,
    '1265_796': 34.6416,
    '1265_797': 34.7819,
    '1265_798': 34.9088,
    '1265_799': 35.0308,
    '1265_800': 35.1521,
    '1265_801': 35.2766,
    '1265_802': 35.3929,
    '1265_803': 35.5093,
    '1265_804': 35.6281,
    '1265_805': 35.7362,
    '1265_806': 35.8377,
    '1265_807': 35.9221,
    '1265_808': 35.986,
    '1265_809': 36.056,
    '1265_810': 36.1063,
    '1265_811': 36.1468,
    '1265_812': 36.1786,
    '1265_813': 36.2172,
    '1265_814': 36.2609,
    '1265_815': 36.3053,
    '1265_816': 36.3388,
    '1265_817': 36.3445,
    '1265_818': 36.3177,
    '1265_819': 36.2778,
    '1265_820': 36.2397,
    '1265_821': 36.2029,
    '1265_822': 36.1764,
    '1265_823': 36.1597,
    '1265_824': 36.1457,
    '1265_825': 36.1256,
    '1265_826': 36.1014,
    '1265_827': 36.0989,
    '1265_828': 36.1027,
    '1265_829': 36.1126,
    '1265_830': 36.1254,
    '1265_831': 36.1363,
    '1265_832': 36.1433,
    '1265_833': 36.1451,
    '1265_834': 36.1403,
    '1265_835': 36.1281,
    '1265_836': 36.1098,
    '1265_837': 36.0835,
    '1265_838': 36.0493,
    '1265_839': 36.0085,
    '1265_840': 35.9614,
    '1265_841': 35.908,
    '1265_842': 35.8496,
    '1265_843': 35.7877,
    '1265_844': 35.7228,
    '1265_845': 35.656,
    '1265_846': 35.5891,
    '1265_847': 35.5231,
    '1265_848': 35.4615,
    '1265_849': 35.4117,
    '1265_850': 35.3719,
    '1265_851': 35.3574,
    '1265_852': 35.3516,
    '1265_853': 35.3287,
    '1265_854': 35.2612,
    '1265_855': 35.1409,
    '1265_856': 34.9974,
    '1265_857': 34.8352,
    '1265_858': 34.668,
    '1265_859': 34.4931,
    '1265_860': 34.3008,
    '1265_861': 34.0923,
    '1265_862': 33.8709,
    '1265_863': 33.6394,
    '1265_864': 33.3983,
    '1265_865': 33.1453,
    '1265_866': 32.8772,
    '1265_867': 32.5943,
    '1265_868': 32.3008,
    '1265_869': 32.0005,
    '1265_870': 31.696,
    '1265_871': 31.3943,
    '1265_872': 31.0889,
    '1266_790': 33.8571,
    '1266_791': 33.9504,
    '1266_792': 34.0607,
    '1266_793': 34.2007,
    '1266_794': 34.3526,
    '1266_795': 34.5064,
    '1266_796': 34.654,
    '1266_797': 34.7956,
    '1266_798': 34.9372,
    '1266_799': 35.0638,
    '1266_800': 35.1859,
    '1266_801': 35.306,
    '1266_802': 35.4205,
    '1266_803': 35.5309,
    '1266_804': 35.6365,
    '1266_805': 35.7393,
    '1266_806': 35.8323,
    '1266_807': 35.9099,
    '1266_808': 35.9702,
    '1266_809': 36.0326,
    '1266_810': 36.0835,
    '1266_811': 36.1242,
    '1266_812': 36.1603,
    '1266_813': 36.2012,
    '1266_814': 36.2485,
    '1266_815': 36.3038,
    '1266_816': 36.3371,
    '1266_817': 36.331,
    '1266_818': 36.2896,
    '1266_819': 36.2451,
    '1266_820': 36.2017,
    '1266_821': 36.1691,
    '1266_822': 36.153,
    '1266_823': 36.148,
    '1266_824': 36.1341,
    '1266_825': 36.1029,
    '1266_826': 36.0703,
    '1266_827': 36.0588,
    '1266_828': 36.0586,
    '1266_829': 36.0667,
    '1266_830': 36.0816,
    '1266_831': 36.091,
    '1266_832': 36.0952,
    '1266_833': 36.0929,
    '1266_834': 36.0837,
    '1266_835': 36.0677,
    '1266_836': 36.0462,
    '1266_837': 36.0176,
    '1266_838': 35.9817,
    '1266_839': 35.94,
    '1266_840': 35.8923,
    '1266_841': 35.8386,
    '1266_842': 35.78,
    '1266_843': 35.7177,
    '1266_844': 35.6523,
    '1266_845': 35.5845,
    '1266_846': 35.5161,
    '1266_847': 35.4483,
    '1266_848': 35.3842,
    '1266_849': 35.3315,
    '1266_850': 35.2888,
    '1266_851': 35.2722,
    '1266_852': 35.2633,
    '1266_853': 35.238,
    '1266_854': 35.1711,
    '1266_855': 35.0565,
    '1266_856': 34.9098,
    '1266_857': 34.7465,
    '1266_858': 34.5856,
    '1266_859': 34.4099,
    '1266_860': 34.2174,
    '1266_861': 34.0033,
    '1266_862': 33.773,
    '1266_863': 33.5368,
    '1266_864': 33.2913,
    '1266_865': 33.0348,
    '1266_866': 32.7629,
    '1266_867': 32.4725,
    '1266_868': 32.1717,
    '1266_869': 31.8663,
    '1266_870': 31.5583,
    '1266_871': 31.2531,
    '1266_872': 30.9429,
    '1267_790': 33.8915,
    '1267_791': 33.9836,
    '1267_792': 34.0962,
    '1267_793': 34.231,
    '1267_794': 34.3781,
    '1267_795': 34.5286,
    '1267_796': 34.6725,
    '1267_797': 34.8136,
    '1267_798': 34.9543,
    '1267_799': 35.0875,
    '1267_800': 35.2116,
    '1267_801': 35.3279,
    '1267_802': 35.4393,
    '1267_803': 35.5447,
    '1267_804': 35.6411,
    '1267_805': 35.7354,
    '1267_806': 35.8201,
    '1267_807': 35.8903,
    '1267_808': 35.9494,
    '1267_809': 36.0076,
    '1267_810': 36.0589,
    '1267_811': 36.0986,
    '1267_812': 36.1299,
    '1267_813': 36.169,
    '1267_814': 36.2283,
    '1267_815': 36.2871,
    '1267_816': 36.316,
    '1267_817': 36.2981,
    '1267_818': 36.2548,
    '1267_819': 36.2073,
    '1267_820': 36.1626,
    '1267_821': 36.1381,
    '1267_822': 36.1373,
    '1267_823': 36.1454,
    '1267_824': 36.1271,
    '1267_825': 36.0766,
    '1267_826': 36.0347,
    '1267_827': 36.018,
    '1267_828': 36.0157,
    '1267_829': 36.0234,
    '1267_830': 36.0389,
    '1267_831': 36.0488,
    '1267_832': 36.052,
    '1267_833': 36.0451,
    '1267_834': 36.0317,
    '1267_835': 36.0121,
    '1267_836': 35.9876,
    '1267_837': 35.9565,
    '1267_838': 35.9187,
    '1267_839': 35.8757,
    '1267_840': 35.827,
    '1267_841': 35.7723,
    '1267_842': 35.7128,
    '1267_843': 35.6497,
    '1267_844': 35.5833,
    '1267_845': 35.5147,
    '1267_846': 35.445,
    '1267_847': 35.3756,
    '1267_848': 35.3096,
    '1267_849': 35.2544,
    '1267_850': 35.2077,
    '1267_851': 35.1838,
    '1267_852': 35.1711,
    '1267_853': 35.1447,
    '1267_854': 35.0707,
    '1267_855': 34.9584,
    '1267_856': 34.8089,
    '1267_857': 34.6451,
    '1267_858': 34.4873,
    '1267_859': 34.3205,
    '1267_860': 34.1365,
    '1267_861': 33.9184,
    '1267_862': 33.6782,
    '1267_863': 33.4396,
    '1267_864': 33.188,
    '1267_865': 32.9263,
    '1267_866': 32.6487,
    '1267_867': 32.3507,
    '1267_868': 32.0441,
    '1267_869': 31.7334,
    '1267_870': 31.4213,
    '1267_871': 31.1063,
    '1267_872': 30.7922,
    '1268_790': 33.9365,
    '1268_791': 34.0263,
    '1268_792': 34.1371,
    '1268_793': 34.2656,
    '1268_794': 34.4065,
    '1268_795': 34.5519,
    '1268_796': 34.6937,
    '1268_797': 34.8332,
    '1268_798': 34.9743,
    '1268_799': 35.1094,
    '1268_800': 35.2334,
    '1268_801': 35.3433,
    '1268_802': 35.4492,
    '1268_803': 35.5496,
    '1268_804': 35.6369,
    '1268_805': 35.7254,
    '1268_806': 35.8111,
    '1268_807': 35.867,
    '1268_808': 35.9227,
    '1268_809': 35.9775,
    '1268_810': 36.026,
    '1268_811': 36.0629,
    '1268_812': 36.0865,
    '1268_813': 36.1249,
    '1268_814': 36.1942,
    '1268_815': 36.2494,
    '1268_816': 36.2689,
    '1268_817': 36.2474,
    '1268_818': 36.207,
    '1268_819': 36.1605,
    '1268_820': 36.1223,
    '1268_821': 36.1059,
    '1268_822': 36.1245,
    '1268_823': 36.1383,
    '1268_824': 36.116,
    '1268_825': 36.0451,
    '1268_826': 35.9936,
    '1268_827': 35.9742,
    '1268_828': 35.9723,
    '1268_829': 35.9815,
    '1268_830': 35.9961,
    '1268_831': 36.0108,
    '1268_832': 36.0176,
    '1268_833': 36.0022,
    '1268_834': 35.986,
    '1268_835': 35.9633,
    '1268_836': 35.9357,
    '1268_837': 35.902,
    '1268_838': 35.8622,
    '1268_839': 35.8174,
    '1268_840': 35.767,
    '1268_841': 35.7108,
    '1268_842': 35.6497,
    '1268_843': 35.5851,
    '1268_844': 35.5175,
    '1268_845': 35.4478,
    '1268_846': 35.3768,
    '1268_847': 35.3058,
    '1268_848': 35.2379,
    '1268_849': 35.1799,
    '1268_850': 35.1292,
    '1268_851': 35.0951,
    '1268_852': 35.0795,
    '1268_853': 35.0453,
    '1268_854': 34.962,
    '1268_855': 34.8479,
    '1268_856': 34.6955,
    '1268_857': 34.5322,
    '1268_858': 34.3758,
    '1268_859': 34.2171,
    '1268_860': 34.0517,
    '1268_861': 33.838,
    '1268_862': 33.6037,
    '1268_863': 33.3581,
    '1268_864': 33.097,
    '1268_865': 32.8201,
    '1268_866': 32.5302,
    '1268_867': 32.2264,
    '1268_868': 31.9158,
    '1268_869': 31.6004,
    '1268_870': 31.2854,
    '1268_871': 30.9586,
    '1268_872': 30.6307,
    '1269_790': 33.9824,
    '1269_791': 34.0707,
    '1269_792': 34.1773,
    '1269_793': 34.2998,
    '1269_794': 34.4358,
    '1269_795': 34.5784,
    '1269_796': 34.7191,
    '1269_797': 34.8589,
    '1269_798': 34.996,
    '1269_799': 35.1252,
    '1269_800': 35.2507,
    '1269_801': 35.3521,
    '1269_802': 35.442,
    '1269_803': 35.5259,
    '1269_804': 35.6083,
    '1269_805': 35.6922,
    '1269_806': 35.7786,
    '1269_807': 35.843,
    '1269_808': 35.8946,
    '1269_809': 35.942,
    '1269_810': 35.9809,
    '1269_811': 36.0099,
    '1269_812': 36.0338,
    '1269_813': 36.0783,
    '1269_814': 36.1513,
    '1269_815': 36.196,
    '1269_816': 36.1992,
    '1269_817': 36.1687,
    '1269_818': 36.1326,
    '1269_819': 36.091,
    '1269_820': 36.0611,
    '1269_821': 36.0608,
    '1269_822': 36.0944,
    '1269_823': 36.1077,
    '1269_824': 36.0818,
    '1269_825': 36.014,
    '1269_826': 35.9489,
    '1269_827': 35.9281,
    '1269_828': 35.9288,
    '1269_829': 35.9411,
    '1269_830': 35.9602,
    '1269_831': 35.9832,
    '1269_832': 35.9904,
    '1269_833': 35.9739,
    '1269_834': 35.9489,
    '1269_835': 35.9239,
    '1269_836': 35.892,
    '1269_837': 35.8547,
    '1269_838': 35.8125,
    '1269_839': 35.7657,
    '1269_840': 35.7132,
    '1269_841': 35.6548,
    '1269_842': 35.5912,
    '1269_843': 35.5242,
    '1269_844': 35.4548,
    '1269_845': 35.3837,
    '1269_846': 35.3112,
    '1269_847': 35.2384,
    '1269_848': 35.1673,
    '1269_849': 35.1023,
    '1269_850': 35.0502,
    '1269_851': 35.0083,
    '1269_852': 34.9845,
    '1269_853': 34.9416,
    '1269_854': 34.8501,
    '1269_855': 34.7277,
    '1269_856': 34.5757,
    '1269_857': 34.4102,
    '1269_858': 34.2493,
    '1269_859': 34.0868,
    '1269_860': 33.915,
    '1269_861': 33.7209,
    '1269_862': 33.5086,
    '1269_863': 33.2694,
    '1269_864': 33.0048,
    '1269_865': 32.711,
    '1269_866': 32.4126,
    '1269_867': 32.1066,
    '1269_868': 31.7887,
    '1269_869': 31.4643,
    '1269_870': 31.1503,
    '1269_871': 30.8301,
    '1269_872': 30.5087,
    '1270_790': 34.0274,
    '1270_791': 34.1146,
    '1270_792': 34.2162,
    '1270_793': 34.3321,
    '1270_794': 34.4638,
    '1270_795': 34.6039,
    '1270_796': 34.7453,
    '1270_797': 34.8864,
    '1270_798': 35.0216,
    '1270_799': 35.1414,
    '1270_800': 35.2493,
    '1270_801': 35.3394,
    '1270_802': 35.4191,
    '1270_803': 35.4879,
    '1270_804': 35.5703,
    '1270_805': 35.6581,
    '1270_806': 35.7465,
    '1270_807': 35.8101,
    '1270_808': 35.8571,
    '1270_809': 35.9022,
    '1270_810': 35.9365,
    '1270_811': 35.9629,
    '1270_812': 35.9898,
    '1270_813': 36.0331,
    '1270_814': 36.1006,
    '1270_815': 36.1342,
    '1270_816': 36.1184,
    '1270_817': 36.079,
    '1270_818': 36.0396,
    '1270_819': 36.0013,
    '1270_820': 35.9809,
    '1270_821': 35.9962,
    '1270_822': 36.0393,
    '1270_823': 36.0622,
    '1270_824': 36.0426,
    '1270_825': 35.9748,
    '1270_826': 35.9018,
    '1270_827': 35.8824,
    '1270_828': 35.8869,
    '1270_829': 35.9032,
    '1270_830': 35.9267,
    '1270_831': 35.9542,
    '1270_832': 35.9656,
    '1270_833': 35.9626,
    '1270_834': 35.9351,
    '1270_835': 35.8997,
    '1270_836': 35.8594,
    '1270_837': 35.8161,
    '1270_838': 35.7707,
    '1270_839': 35.7223,
    '1270_840': 35.667,
    '1270_841': 35.6057,
    '1270_842': 35.5389,
    '1270_843': 35.4677,
    '1270_844': 35.3958,
    '1270_845': 35.3233,
    '1270_846': 35.2491,
    '1270_847': 35.1736,
    '1270_848': 35.0987,
    '1270_849': 35.0287,
    '1270_850': 34.9713,
    '1270_851': 34.9223,
    '1270_852': 34.8887,
    '1270_853': 34.8411,
    '1270_854': 34.744,
    '1270_855': 34.6124,
    '1270_856': 34.4589,
    '1270_857': 34.2876,
    '1270_858': 34.1181,
    '1270_859': 33.9474,
    '1270_860': 33.7639,
    '1270_861': 33.5696,
    '1270_862': 33.3745,
    '1270_863': 33.1562,
    '1270_864': 32.9029,
    '1270_865': 32.5947,
    '1270_866': 32.3069,
    '1270_867': 31.9898,
    '1270_868': 31.6625,
    '1270_869': 31.3307,
    '1270_870': 31.0167,
    '1270_871': 30.6999,
    '1270_872': 30.3759,
    '1271_790': 34.0705,
    '1271_791': 34.1565,
    '1271_792': 34.2548,
    '1271_793': 34.3669,
    '1271_794': 34.494,
    '1271_795': 34.6311,
    '1271_796': 34.7718,
    '1271_797': 34.9144,
    '1271_798': 35.0486,
    '1271_799': 35.1564,
    '1271_800': 35.2489,
    '1271_801': 35.327,
    '1271_802': 35.3914,
    '1271_803': 35.4448,
    '1271_804': 35.5306,
    '1271_805': 35.62,
    '1271_806': 35.7048,
    '1271_807': 35.7709,
    '1271_808': 35.8139,
    '1271_809': 35.8581,
    '1271_810': 35.8915,
    '1271_811': 35.9174,
    '1271_812': 35.9453,
    '1271_813': 35.9855,
    '1271_814': 36.0427,
    '1271_815': 36.0645,
    '1271_816': 36.0353,
    '1271_817': 35.9845,
    '1271_818': 35.9391,
    '1271_819': 35.9101,
    '1271_820': 35.902,
    '1271_821': 35.9236,
    '1271_822': 35.9699,
    '1271_823': 35.9979,
    '1271_824': 35.9824,
    '1271_825': 35.9166,
    '1271_826': 35.8547,
    '1271_827': 35.841,
    '1271_828': 35.8488,
    '1271_829': 35.8683,
    '1271_830': 35.8966,
    '1271_831': 35.9319,
    '1271_832': 35.9518,
    '1271_833': 35.9578,
    '1271_834': 35.9341,
    '1271_835': 35.8958,
    '1271_836': 35.8483,
    '1271_837': 35.7966,
    '1271_838': 35.7447,
    '1271_839': 35.6888,
    '1271_840': 35.6297,
    '1271_841': 35.5667,
    '1271_842': 35.4958,
    '1271_843': 35.4164,
    '1271_844': 35.3409,
    '1271_845': 35.2675,
    '1271_846': 35.1907,
    '1271_847': 35.112,
    '1271_848': 35.0323,
    '1271_849': 34.957,
    '1271_850': 34.8932,
    '1271_851': 34.8377,
    '1271_852': 34.7963,
    '1271_853': 34.7426,
    '1271_854': 34.6461,
    '1271_855': 34.5112,
    '1271_856': 34.3512,
    '1271_857': 34.1754,
    '1271_858': 34.0023,
    '1271_859': 33.8256,
    '1271_860': 33.639,
    '1271_861': 33.4403,
    '1271_862': 33.2322,
    '1271_863': 33.0027,
    '1271_864': 32.7598,
    '1271_865': 32.4638,
    '1271_866': 32.1615,
    '1271_867': 31.8425,
    '1271_868': 31.5103,
    '1271_869': 31.1771,
    '1271_870': 30.8589,
    '1271_871': 30.5393,
    '1271_872': 30.2149,
    '1272_790': 34.1103,
    '1272_791': 34.1938,
    '1272_792': 34.2872,
    '1272_793': 34.3946,
    '1272_794': 34.5192,
    '1272_795': 34.6557,
    '1272_796': 34.7965,
    '1272_797': 34.9339,
    '1272_798': 35.0597,
    '1272_799': 35.1628,
    '1272_800': 35.2482,
    '1272_801': 35.3202,
    '1272_802': 35.3825,
    '1272_803': 35.443,
    '1272_804': 35.5149,
    '1272_805': 35.5977,
    '1272_806': 35.6801,
    '1272_807': 35.7446,
    '1272_808': 35.7893,
    '1272_809': 35.8137,
    '1272_810': 35.8397,
    '1272_811': 35.8656,
    '1272_812': 35.8938,
    '1272_813': 35.932,
    '1272_814': 35.9815,
    '1272_815': 35.99,
    '1272_816': 35.9467,
    '1272_817': 35.8877,
    '1272_818': 35.8484,
    '1272_819': 35.8292,
    '1272_820': 35.8279,
    '1272_821': 35.8493,
    '1272_822': 35.8882,
    '1272_823': 35.9127,
    '1272_824': 35.9,
    '1272_825': 35.8471,
    '1272_826': 35.8108,
    '1272_827': 35.8016,
    '1272_828': 35.8103,
    '1272_829': 35.8311,
    '1272_830': 35.8623,
    '1272_831': 35.9048,
    '1272_832': 35.9392,
    '1272_833': 35.9499,
    '1272_834': 35.9314,
    '1272_835': 35.8969,
    '1272_836': 35.8466,
    '1272_837': 35.7888,
    '1272_838': 35.73,
    '1272_839': 35.6677,
    '1272_840': 35.6024,
    '1272_841': 35.5418,
    '1272_842': 35.4629,
    '1272_843': 35.3684,
    '1272_844': 35.2878,
    '1272_845': 35.2125,
    '1272_846': 35.1314,
    '1272_847': 35.0495,
    '1272_848': 34.964,
    '1272_849': 34.8864,
    '1272_850': 34.8144,
    '1272_851': 34.7544,
    '1272_852': 34.7084,
    '1272_853': 34.6499,
    '1272_854': 34.5534,
    '1272_855': 34.419,
    '1272_856': 34.2507,
    '1272_857': 34.0687,
    '1272_858': 33.8918,
    '1272_859': 33.7104,
    '1272_860': 33.5188,
    '1272_861': 33.3131,
    '1272_862': 33.0907,
    '1272_863': 32.8492,
    '1272_864': 32.591,
    '1272_865': 32.3033,
    '1272_866': 32.0011,
    '1272_867': 31.6858,
    '1272_868': 31.3534,
    '1272_869': 31.0288,
    '1272_870': 30.7042,
    '1272_871': 30.3817,
    '1272_872': 30.0603,
    '1273_790': 34.1482,
    '1273_791': 34.2267,
    '1273_792': 34.3123,
    '1273_793': 34.413,
    '1273_794': 34.5361,
    '1273_795': 34.6721,
    '1273_796': 34.8137,
    '1273_797': 34.9477,
    '1273_798': 35.0699,
    '1273_799': 35.1682,
    '1273_800': 35.2484,
    '1273_801': 35.3164,
    '1273_802': 35.3769,
    '1273_803': 35.435,
    '1273_804': 35.4983,
    '1273_805': 35.5732,
    '1273_806': 35.6487,
    '1273_807': 35.708,
    '1273_808': 35.7463,
    '1273_809': 35.7672,
    '1273_810': 35.7897,
    '1273_811': 35.8108,
    '1273_812': 35.8359,
    '1273_813': 35.8696,
    '1273_814': 35.9099,
    '1273_815': 35.9076,
    '1273_816': 35.8534,
    '1273_817': 35.7968,
    '1273_818': 35.7658,
    '1273_819': 35.7514,
    '1273_820': 35.7513,
    '1273_821': 35.7669,
    '1273_822': 35.793,
    '1273_823': 35.8093,
    '1273_824': 35.7984,
    '1273_825': 35.7728,
    '1273_826': 35.7569,
    '1273_827': 35.7552,
    '1273_828': 35.7663,
    '1273_829': 35.7881,
    '1273_830': 35.8191,
    '1273_831': 35.8624,
    '1273_832': 35.9124,
    '1273_833': 35.9289,
    '1273_834': 35.9228,
    '1273_835': 35.8995,
    '1273_836': 35.8537,
    '1273_837': 35.7935,
    '1273_838': 35.7291,
    '1273_839': 35.6585,
    '1273_840': 35.5811,
    '1273_841': 35.5186,
    '1273_842': 35.4327,
    '1273_843': 35.3334,
    '1273_844': 35.2403,
    '1273_845': 35.1525,
    '1273_846': 35.0679,
    '1273_847': 34.9793,
    '1273_848': 34.8915,
    '1273_849': 34.8112,
    '1273_850': 34.7374,
    '1273_851': 34.679,
    '1273_852': 34.6282,
    '1273_853': 34.565,
    '1273_854': 34.4671,
    '1273_855': 34.3322,
    '1273_856': 34.1575,
    '1273_857': 33.9669,
    '1273_858': 33.7854,
    '1273_859': 33.6,
    '1273_860': 33.4029,
    '1273_861': 33.1893,
    '1273_862': 32.9532,
    '1273_863': 32.6979,
    '1273_864': 32.4278,
    '1273_865': 32.1433,
    '1273_866': 31.8447,
    '1273_867': 31.5312,
    '1273_868': 31.1984,
    '1273_869': 30.8831,
    '1273_870': 30.5572,
    '1273_871': 30.229,
    '1273_872': 29.9091,
    '1274_790': 34.1793,
    '1274_791': 34.2502,
    '1274_792': 34.3322,
    '1274_793': 34.431,
    '1274_794': 34.5481,
    '1274_795': 34.6769,
    '1274_796': 34.8137,
    '1274_797': 34.9523,
    '1274_798': 35.0797,
    '1274_799': 35.1725,
    '1274_800': 35.2486,
    '1274_801': 35.3145,
    '1274_802': 35.3754,
    '1274_803': 35.433,
    '1274_804': 35.4895,
    '1274_805': 35.5507,
    '1274_806': 35.6189,
    '1274_807': 35.6707,
    '1274_808': 35.7073,
    '1274_809': 35.7242,
    '1274_810': 35.7386,
    '1274_811': 35.7522,
    '1274_812': 35.7735,
    '1274_813': 35.7998,
    '1274_814': 35.825,
    '1274_815': 35.8116,
    '1274_816': 35.759,
    '1274_817': 35.7154,
    '1274_818': 35.6874,
    '1274_819': 35.6727,
    '1274_820': 35.67,
    '1274_821': 35.678,
    '1274_822': 35.6924,
    '1274_823': 35.7027,
    '1274_824': 35.7013,
    '1274_825': 35.695,
    '1274_826': 35.6937,
    '1274_827': 35.7004,
    '1274_828': 35.7162,
    '1274_829': 35.7404,
    '1274_830': 35.7726,
    '1274_831': 35.8164,
    '1274_832': 35.8743,
    '1274_833': 35.9023,
    '1274_834': 35.898,
    '1274_835': 35.885,
    '1274_836': 35.8503,
    '1274_837': 35.7895,
    '1274_838': 35.716,
    '1274_839': 35.6377,
    '1274_840': 35.5603,
    '1274_841': 35.4954,
    '1274_842': 35.4139,
    '1274_843': 35.3181,
    '1274_844': 35.2182,
    '1274_845': 35.1168,
    '1274_846': 35.0159,
    '1274_847': 34.9159,
    '1274_848': 34.824,
    '1274_849': 34.7384,
    '1274_850': 34.6661,
    '1274_851': 34.6097,
    '1274_852': 34.552,
    '1274_853': 34.4818,
    '1274_854': 34.3823,
    '1274_855': 34.2465,
    '1274_856': 34.0737,
    '1274_857': 33.8791,
    '1274_858': 33.6888,
    '1274_859': 33.4984,
    '1274_860': 33.2936,
    '1274_861': 33.0694,
    '1274_862': 32.823,
    '1274_863': 32.5557,
    '1274_864': 32.2731,
    '1274_865': 31.9868,
    '1274_866': 31.6878,
    '1274_867': 31.3715,
    '1274_868': 31.0454,
    '1274_869': 30.7234,
    '1274_870': 30.3979,
    '1274_871': 30.0775,
    '1274_872': 29.7611,
    '1275_790': 34.2054,
    '1275_791': 34.273,
    '1275_792': 34.3533,
    '1275_793': 34.4497,
    '1275_794': 34.5615,
    '1275_795': 34.6817,
    '1275_796': 34.8126,
    '1275_797': 34.9507,
    '1275_798': 35.0757,
    '1275_799': 35.1671,
    '1275_800': 35.2409,
    '1275_801': 35.3093,
    '1275_802': 35.3748,
    '1275_803': 35.4327,
    '1275_804': 35.481,
    '1275_805': 35.5287,
    '1275_806': 35.578,
    '1275_807': 35.6236,
    '1275_808': 35.6552,
    '1275_809': 35.6688,
    '1275_810': 35.6757,
    '1275_811': 35.6894,
    '1275_812': 35.707,
    '1275_813': 35.7241,
    '1275_814': 35.7338,
    '1275_815': 35.7128,
    '1275_816': 35.6723,
    '1275_817': 35.6345,
    '1275_818': 35.6061,
    '1275_819': 35.5888,
    '1275_820': 35.5821,
    '1275_821': 35.5844,
    '1275_822': 35.5925,
    '1275_823': 35.6014,
    '1275_824': 35.6075,
    '1275_825': 35.6128,
    '1275_826': 35.6224,
    '1275_827': 35.6373,
    '1275_828': 35.6588,
    '1275_829': 35.6872,
    '1275_830': 35.7224,
    '1275_831': 35.7676,
    '1275_832': 35.8249,
    '1275_833': 35.857,
    '1275_834': 35.8537,
    '1275_835': 35.8448,
    '1275_836': 35.8247,
    '1275_837': 35.7748,
    '1275_838': 35.7038,
    '1275_839': 35.6218,
    '1275_840': 35.5506,
    '1275_841': 35.485,
    '1275_842': 35.4111,
    '1275_843': 35.3209,
    '1275_844': 35.2171,
    '1275_845': 35.1023,
    '1275_846': 34.9757,
    '1275_847': 34.8607,
    '1275_848': 34.7575,
    '1275_849': 34.6667,
    '1275_850': 34.5951,
    '1275_851': 34.539,
    '1275_852': 34.4779,
    '1275_853': 34.4002,
    '1275_854': 34.2981,
    '1275_855': 34.1659,
    '1275_856': 34.0009,
    '1275_857': 33.8124,
    '1275_858': 33.6113,
    '1275_859': 33.41,
    '1275_860': 33.1947,
    '1275_861': 32.9597,
    '1275_862': 32.7029,
    '1275_863': 32.4249,
    '1275_864': 32.1311,
    '1275_865': 31.8366,
    '1275_866': 31.5323,
    '1275_867': 31.2123,
    '1275_868': 30.8899,
    '1275_869': 30.5686,
    '1275_870': 30.2468,
    '1275_871': 29.9305,
    '1275_872': 29.6157,
    '1276_790': 34.2431,
    '1276_791': 34.3144,
    '1276_792': 34.3941,
    '1276_793': 34.4833,
    '1276_794': 34.5854,
    '1276_795': 34.7048,
    '1276_796': 34.8408,
    '1276_797': 34.9619,
    '1276_798': 35.0739,
    '1276_799': 35.162,
    '1276_800': 35.2341,
    '1276_801': 35.2999,
    '1276_802': 35.3629,
    '1276_803': 35.4189,
    '1276_804': 35.4644,
    '1276_805': 35.5044,
    '1276_806': 35.5444,
    '1276_807': 35.5775,
    '1276_808': 35.5977,
    '1276_809': 35.6116,
    '1276_810': 35.6151,
    '1276_811': 35.6267,
    '1276_812': 35.6393,
    '1276_813': 35.6475,
    '1276_814': 35.6448,
    '1276_815': 35.6201,
    '1276_816': 35.5843,
    '1276_817': 35.5478,
    '1276_818': 35.5175,
    '1276_819': 35.4972,
    '1276_820': 35.4871,
    '1276_821': 35.4858,
    '1276_822': 35.4914,
    '1276_823': 35.5011,
    '1276_824': 35.5126,
    '1276_825': 35.526,
    '1276_826': 35.5441,
    '1276_827': 35.5666,
    '1276_828': 35.5941,
    '1276_829': 35.6275,
    '1276_830': 35.6664,
    '1276_831': 35.7116,
    '1276_832': 35.7646,
    '1276_833': 35.7971,
    '1276_834': 35.8031,
    '1276_835': 35.8004,
    '1276_836': 35.7837,
    '1276_837': 35.7365,
    '1276_838': 35.6737,
    '1276_839': 35.6047,
    '1276_840': 35.5404,
    '1276_841': 35.4805,
    '1276_842': 35.4109,
    '1276_843': 35.3277,
    '1276_844': 35.2254,
    '1276_845': 35.0982,
    '1276_846': 34.943,
    '1276_847': 34.8073,
    '1276_848': 34.694,
    '1276_849': 34.5983,
    '1276_850': 34.5217,
    '1276_851': 34.4615,
    '1276_852': 34.3985,
    '1276_853': 34.3163,
    '1276_854': 34.212,
    '1276_855': 34.0845,
    '1276_856': 33.9274,
    '1276_857': 33.7422,
    '1276_858': 33.5357,
    '1276_859': 33.3262,
    '1276_860': 33.1011,
    '1276_861': 32.8559,
    '1276_862': 32.5879,
    '1276_863': 32.2966,
    '1276_864': 31.9932,
    '1276_865': 31.69,
    '1276_866': 31.3764,
    '1276_867': 31.0557,
    '1276_868': 30.7441,
    '1276_869': 30.4223,
    '1276_870': 30.1035,
    '1276_871': 29.7888,
    '1276_872': 29.4732,
    '1277_790': 34.2844,
    '1277_791': 34.3519,
    '1277_792': 34.4277,
    '1277_793': 34.5111,
    '1277_794': 34.6054,
    '1277_795': 34.7201,
    '1277_796': 34.8518,
    '1277_797': 34.9748,
    '1277_798': 35.081,
    '1277_799': 35.1702,
    '1277_800': 35.2371,
    '1277_801': 35.3045,
    '1277_802': 35.3575,
    '1277_803': 35.4072,
    '1277_804': 35.4446,
    '1277_805': 35.4815,
    '1277_806': 35.5184,
    '1277_807': 35.5553,
    '1277_808': 35.5558,
    '1277_809': 35.5658,
    '1277_810': 35.5685,
    '1277_811': 35.5711,
    '1277_812': 35.5761,
    '1277_813': 35.5746,
    '1277_814': 35.5609,
    '1277_815': 35.5328,
    '1277_816': 35.4965,
    '1277_817': 35.4572,
    '1277_818': 35.4234,
    '1277_819': 35.4001,
    '1277_820': 35.3876,
    '1277_821': 35.3845,
    '1277_822': 35.3896,
    '1277_823': 35.4011,
    '1277_824': 35.4172,
    '1277_825': 35.4371,
    '1277_826': 35.4623,
    '1277_827': 35.4914,
    '1277_828': 35.5247,
    '1277_829': 35.5629,
    '1277_830': 35.6088,
    '1277_831': 35.6487,
    '1277_832': 35.6997,
    '1277_833': 35.7445,
    '1277_834': 35.7672,
    '1277_835': 35.7751,
    '1277_836': 35.7481,
    '1277_837': 35.6822,
    '1277_838': 35.6201,
    '1277_839': 35.5681,
    '1277_840': 35.5188,
    '1277_841': 35.4675,
    '1277_842': 35.4067,
    '1277_843': 35.3206,
    '1277_844': 35.2155,
    '1277_845': 35.0818,
    '1277_846': 34.9132,
    '1277_847': 34.7592,
    '1277_848': 34.6363,
    '1277_849': 34.5337,
    '1277_850': 34.4509,
    '1277_851': 34.3839,
    '1277_852': 34.3139,
    '1277_853': 34.2298,
    '1277_854': 34.1268,
    '1277_855': 34.0059,
    '1277_856': 33.8479,
    '1277_857': 33.6634,
    '1277_858': 33.4542,
    '1277_859': 33.2391,
    '1277_860': 33.0041,
    '1277_861': 32.7518,
    '1277_862': 32.4759,
    '1277_863': 32.1724,
    '1277_864': 31.8645,
    '1277_865': 31.5542,
    '1277_866': 31.2342,
    '1277_867': 30.9126,
    '1277_868': 30.6083,
    '1277_869': 30.2806,
    '1277_870': 29.9662,
    '1277_871': 29.6558,
    '1277_872': 29.3373,
    '1278_790': 34.3158,
    '1278_791': 34.3821,
    '1278_792': 34.4585,
    '1278_793': 34.5425,
    '1278_794': 34.6389,
    '1278_795': 34.7443,
    '1278_796': 34.8687,
    '1278_797': 34.9937,
    '1278_798': 35.0992,
    '1278_799': 35.1897,
    '1278_800': 35.2483,
    '1278_801': 35.3264,
    '1278_802': 35.3679,
    '1278_803': 35.4049,
    '1278_804': 35.4391,
    '1278_805': 35.4788,
    '1278_806': 35.5081,
    '1278_807': 35.5292,
    '1278_808': 35.5324,
    '1278_809': 35.5283,
    '1278_810': 35.5283,
    '1278_811': 35.5199,
    '1278_812': 35.5212,
    '1278_813': 35.5118,
    '1278_814': 35.4883,
    '1278_815': 35.4533,
    '1278_816': 35.414,
    '1278_817': 35.3669,
    '1278_818': 35.3276,
    '1278_819': 35.3017,
    '1278_820': 35.287,
    '1278_821': 35.2829,
    '1278_822': 35.2885,
    '1278_823': 35.3026,
    '1278_824': 35.323,
    '1278_825': 35.3485,
    '1278_826': 35.3798,
    '1278_827': 35.4149,
    '1278_828': 35.4536,
    '1278_829': 35.4964,
    '1278_830': 35.5455,
    '1278_831': 35.5854,
    '1278_832': 35.6374,
    '1278_833': 35.6985,
    '1278_834': 35.7349,
    '1278_835': 35.7446,
    '1278_836': 35.7069,
    '1278_837': 35.6307,
    '1278_838': 35.569,
    '1278_839': 35.5248,
    '1278_840': 35.4865,
    '1278_841': 35.4427,
    '1278_842': 35.3776,
    '1278_843': 35.2781,
    '1278_844': 35.1704,
    '1278_845': 35.0404,
    '1278_846': 34.8739,
    '1278_847': 34.7157,
    '1278_848': 34.5832,
    '1278_849': 34.4719,
    '1278_850': 34.3797,
    '1278_851': 34.3017,
    '1278_852': 34.2251,
    '1278_853': 34.1382,
    '1278_854': 34.0328,
    '1278_855': 33.9144,
    '1278_856': 33.7613,
    '1278_857': 33.5766,
    '1278_858': 33.3635,
    '1278_859': 33.1387,
    '1278_860': 32.8957,
    '1278_861': 32.6344,
    '1278_862': 32.3499,
    '1278_863': 32.0417,
    '1278_864': 31.7364,
    '1278_865': 31.4271,
    '1278_866': 31.1045,
    '1278_867': 30.7843,
    '1278_868': 30.472,
    '1278_869': 30.141,
    '1278_870': 29.826,
    '1278_871': 29.5117,
    '1278_872': 29.188,
    '1279_790': 34.3299,
    '1279_791': 34.3977,
    '1279_792': 34.4818,
    '1279_793': 34.5753,
    '1279_794': 34.676,
    '1279_795': 34.7763,
    '1279_796': 34.8823,
    '1279_797': 34.993,
    '1279_798': 35.0941,
    '1279_799': 35.1765,
    '1279_800': 35.2402,
    '1279_801': 35.3063,
    '1279_802': 35.3599,
    '1279_803': 35.4024,
    '1279_804': 35.4384,
    '1279_805': 35.4723,
    '1279_806': 35.5003,
    '1279_807': 35.5202,
    '1279_808': 35.5269,
    '1279_809': 35.5071,
    '1279_810': 35.5001,
    '1279_811': 35.4894,
    '1279_812': 35.4765,
    '1279_813': 35.4593,
    '1279_814': 35.4265,
    '1279_815': 35.3814,
    '1279_816': 35.3396,
    '1279_817': 35.2797,
    '1279_818': 35.2345,
    '1279_819': 35.2066,
    '1279_820': 35.1889,
    '1279_821': 35.1837,
    '1279_822': 35.1897,
    '1279_823': 35.2063,
    '1279_824': 35.2307,
    '1279_825': 35.2612,
    '1279_826': 35.2979,
    '1279_827': 35.3383,
    '1279_828': 35.3821,
    '1279_829': 35.4296,
    '1279_830': 35.483,
    '1279_831': 35.5262,
    '1279_832': 35.5814,
    '1279_833': 35.6494,
    '1279_834': 35.6983,
    '1279_835': 35.7065,
    '1279_836': 35.6628,
    '1279_837': 35.5911,
    '1279_838': 35.5292,
    '1279_839': 35.4764,
    '1279_840': 35.4455,
    '1279_841': 35.4055,
    '1279_842': 35.3296,
    '1279_843': 35.2163,
    '1279_844': 35.1084,
    '1279_845': 34.9822,
    '1279_846': 34.8256,
    '1279_847': 34.6705,
    '1279_848': 34.5314,
    '1279_849': 34.4107,
    '1279_850': 34.3078,
    '1279_851': 34.2171,
    '1279_852': 34.1335,
    '1279_853': 34.0441,
    '1279_854': 33.9337,
    '1279_855': 33.8155,
    '1279_856': 33.6744,
    '1279_857': 33.4867,
    '1279_858': 33.2765,
    '1279_859': 33.0372,
    '1279_860': 32.7864,
    '1279_861': 32.5198,
    '1279_862': 32.2328,
    '1279_863': 31.9234,
    '1279_864': 31.6109,
    '1279_865': 31.2973,
    '1279_866': 30.9687,
    '1279_867': 30.6423,
    '1279_868': 30.3202,
    '1279_869': 29.9851,
    '1279_870': 29.681,
    '1279_871': 29.3671,
    '1279_872': 29.043,
    '1280_790': 34.3433,
    '1280_791': 34.4104,
    '1280_792': 34.4936,
    '1280_793': 34.5877,
    '1280_794': 34.6827,
    '1280_795': 34.7781,
    '1280_796': 34.8765,
    '1280_797': 34.9806,
    '1280_798': 35.0796,
    '1280_799': 35.1613,
    '1280_800': 35.2354,
    '1280_801': 35.3005,
    '1280_802': 35.3592,
    '1280_803': 35.4055,
    '1280_804': 35.4422,
    '1280_805': 35.4755,
    '1280_806': 35.5068,
    '1280_807': 35.5326,
    '1280_808': 35.5397,
    '1280_809': 35.5235,
    '1280_810': 35.4985,
    '1280_811': 35.4737,
    '1280_812': 35.439,
    '1280_813': 35.4087,
    '1280_814': 35.3738,
    '1280_815': 35.3196,
    '1280_816': 35.2616,
    '1280_817': 35.2034,
    '1280_818': 35.1568,
    '1280_819': 35.1233,
    '1280_820': 35.0988,
    '1280_821': 35.0909,
    '1280_822': 35.0952,
    '1280_823': 35.1135,
    '1280_824': 35.1412,
    '1280_825': 35.1757,
    '1280_826': 35.2167,
    '1280_827': 35.2619,
    '1280_828': 35.3103,
    '1280_829': 35.3626,
    '1280_830': 35.4208,
    '1280_831': 35.4687,
    '1280_832': 35.5269,
    '1280_833': 35.5947,
    '1280_834': 35.6449,
    '1280_835': 35.6586,
    '1280_836': 35.6218,
    '1280_837': 35.5524,
    '1280_838': 35.4884,
    '1280_839': 35.4311,
    '1280_840': 35.392,
    '1280_841': 35.3458,
    '1280_842': 35.2645,
    '1280_843': 35.156,
    '1280_844': 35.0411,
    '1280_845': 34.9138,
    '1280_846': 34.7654,
    '1280_847': 34.6166,
    '1280_848': 34.4732,
    '1280_849': 34.344,
    '1280_850': 34.2302,
    '1280_851': 34.1308,
    '1280_852': 34.0389,
    '1280_853': 33.9416,
    '1280_854': 33.8303,
    '1280_855': 33.7103,
    '1280_856': 33.5772,
    '1280_857': 33.3938,
    '1280_858': 33.1833,
    '1280_859': 32.9393,
    '1280_860': 32.6832,
    '1280_861': 32.4098,
    '1280_862': 32.1156,
    '1280_863': 31.8012,
    '1280_864': 31.4827,
    '1280_865': 31.1634,
    '1280_866': 30.8332,
    '1280_867': 30.5088,
    '1280_868': 30.1797,
    '1280_869': 29.854,
    '1280_870': 29.5358,
    '1280_871': 29.2141,
    '1280_872': 28.8957,
    '1281_790': 34.3227,
    '1281_791': 34.3951,
    '1281_792': 34.4806,
    '1281_793': 34.5718,
    '1281_794': 34.6695,
    '1281_795': 34.7649,
    '1281_796': 34.8599,
    '1281_797': 34.9631,
    '1281_798': 35.0579,
    '1281_799': 35.1469,
    '1281_800': 35.2361,
    '1281_801': 35.3096,
    '1281_802': 35.3729,
    '1281_803': 35.4233,
    '1281_804': 35.4635,
    '1281_805': 35.5056,
    '1281_806': 35.5371,
    '1281_807': 35.5571,
    '1281_808': 35.5785,
    '1281_809': 35.5586,
    '1281_810': 35.5184,
    '1281_811': 35.4765,
    '1281_812': 35.4244,
    '1281_813': 35.3709,
    '1281_814': 35.3231,
    '1281_815': 35.2586,
    '1281_816': 35.1882,
    '1281_817': 35.1315,
    '1281_818': 35.0876,
    '1281_819': 35.0539,
    '1281_820': 35.028,
    '1281_821': 35.0131,
    '1281_822': 35.0114,
    '1281_823': 35.029,
    '1281_824': 35.0575,
    '1281_825': 35.0938,
    '1281_826': 35.1376,
    '1281_827': 35.1862,
    '1281_828': 35.2383,
    '1281_829': 35.2943,
    '1281_830': 35.3564,
    '1281_831': 35.4072,
    '1281_832': 35.4645,
    '1281_833': 35.5299,
    '1281_834': 35.579,
    '1281_835': 35.5966,
    '1281_836': 35.5739,
    '1281_837': 35.5176,
    '1281_838': 35.4508,
    '1281_839': 35.3847,
    '1281_840': 35.3302,
    '1281_841': 35.2697,
    '1281_842': 35.185,
    '1281_843': 35.0815,
    '1281_844': 34.9624,
    '1281_845': 34.8357,
    '1281_846': 34.689,
    '1281_847': 34.5425,
    '1281_848': 34.4004,
    '1281_849': 34.2704,
    '1281_850': 34.1496,
    '1281_851': 34.0402,
    '1281_852': 33.9373,
    '1281_853': 33.834,
    '1281_854': 33.7234,
    '1281_855': 33.6047,
    '1281_856': 33.4742,
    '1281_857': 33.2997,
    '1281_858': 33.0919,
    '1281_859': 32.8461,
    '1281_860': 32.5868,
    '1281_861': 32.3098,
    '1281_862': 32.013,
    '1281_863': 31.6974,
    '1281_864': 31.3749,
    '1281_865': 31.0481,
    '1281_866': 30.7148,
    '1281_867': 30.3871,
    '1281_868': 30.0476,
    '1281_869': 29.738,
    '1281_870': 29.4018,
    '1281_871': 29.0715,
    '1281_872': 28.7736,
    '1282_790': 34.2958,
    '1282_791': 34.3669,
    '1282_792': 34.4516,
    '1282_793': 34.5438,
    '1282_794': 34.6471,
    '1282_795': 34.751,
    '1282_796': 34.8555,
    '1282_797': 34.9632,
    '1282_798': 35.0646,
    '1282_799': 35.158,
    '1282_800': 35.2456,
    '1282_801': 35.3251,
    '1282_802': 35.3937,
    '1282_803': 35.4562,
    '1282_804': 35.5089,
    '1282_805': 35.5563,
    '1282_806': 35.5781,
    '1282_807': 35.6151,
    '1282_808': 35.6492,
    '1282_809': 35.6301,
    '1282_810': 35.5701,
    '1282_811': 35.5045,
    '1282_812': 35.4238,
    '1282_813': 35.3474,
    '1282_814': 35.2732,
    '1282_815': 35.1955,
    '1282_816': 35.1235,
    '1282_817': 35.0665,
    '1282_818': 35.0261,
    '1282_819': 34.9955,
    '1282_820': 34.9702,
    '1282_821': 34.9548,
    '1282_822': 34.9515,
    '1282_823': 34.9593,
    '1282_824': 34.9821,
    '1282_825': 35.0164,
    '1282_826': 35.0604,
    '1282_827': 35.1105,
    '1282_828': 35.1649,
    '1282_829': 35.2233,
    '1282_830': 35.2878,
    '1282_831': 35.3438,
    '1282_832': 35.394,
    '1282_833': 35.451,
    '1282_834': 35.499,
    '1282_835': 35.5174,
    '1282_836': 35.5071,
    '1282_837': 35.4669,
    '1282_838': 35.3988,
    '1282_839': 35.3259,
    '1282_840': 35.2655,
    '1282_841': 35.1929,
    '1282_842': 35.1036,
    '1282_843': 34.9997,
    '1282_844': 34.879,
    '1282_845': 34.7514,
    '1282_846': 34.6072,
    '1282_847': 34.4596,
    '1282_848': 34.3198,
    '1282_849': 34.1894,
    '1282_850': 34.0675,
    '1282_851': 33.9532,
    '1282_852': 33.8428,
    '1282_853': 33.7327,
    '1282_854': 33.6175,
    '1282_855': 33.4947,
    '1282_856': 33.3669,
    '1282_857': 33.2119,
    '1282_858': 33.0059,
    '1282_859': 32.7568,
    '1282_860': 32.4953,
    '1282_861': 32.2169,
    '1282_862': 31.9182,
    '1282_863': 31.5995,
    '1282_864': 31.2736,
    '1282_865': 30.937,
    '1282_866': 30.5932,
    '1282_867': 30.2492,
    '1282_868': 29.9028,
    '1282_869': 29.5826,
    '1282_870': 29.2544,
    '1282_871': 28.9305,
    '1282_872': 28.6256,
    '1283_790': 34.259,
    '1283_791': 34.3277,
    '1283_792': 34.4111,
    '1283_793': 34.5056,
    '1283_794': 34.615,
    '1283_795': 34.7302,
    '1283_796': 34.8461,
    '1283_797': 34.9643,
    '1283_798': 35.0737,
    '1283_799': 35.1678,
    '1283_800': 35.2649,
    '1283_801': 35.3512,
    '1283_802': 35.4227,
    '1283_803': 35.4916,
    '1283_804': 35.5596,
    '1283_805': 35.6208,
    '1283_806': 35.6309,
    '1283_807': 35.647,
    '1283_808': 35.6579,
    '1283_809': 35.6385,
    '1283_810': 35.5904,
    '1283_811': 35.5335,
    '1283_812': 35.4485,
    '1283_813': 35.3455,
    '1283_814': 35.2479,
    '1283_815': 35.1517,
    '1283_816': 35.0709,
    '1283_817': 35.0131,
    '1283_818': 34.9737,
    '1283_819': 34.9471,
    '1283_820': 34.9203,
    '1283_821': 34.9052,
    '1283_822': 34.9034,
    '1283_823': 34.9047,
    '1283_824': 34.9182,
    '1283_825': 34.9465,
    '1283_826': 34.9881,
    '1283_827': 35.0379,
    '1283_828': 35.093,
    '1283_829': 35.1519,
    '1283_830': 35.2168,
    '1283_831': 35.2725,
    '1283_832': 35.3193,
    '1283_833': 35.3705,
    '1283_834': 35.4136,
    '1283_835': 35.4304,
    '1283_836': 35.4243,
    '1283_837': 35.3928,
    '1283_838': 35.3338,
    '1283_839': 35.2624,
    '1283_840': 35.1939,
    '1283_841': 35.1174,
    '1283_842': 35.0269,
    '1283_843': 34.9244,
    '1283_844': 34.8038,
    '1283_845': 34.6681,
    '1283_846': 34.524,
    '1283_847': 34.3785,
    '1283_848': 34.2412,
    '1283_849': 34.1121,
    '1283_850': 33.9887,
    '1283_851': 33.866,
    '1283_852': 33.7483,
    '1283_853': 33.635,
    '1283_854': 33.5153,
    '1283_855': 33.3916,
    '1283_856': 33.2625,
    '1283_857': 33.1166,
    '1283_858': 32.9217,
    '1283_859': 32.6743,
    '1283_860': 32.4073,
    '1283_861': 32.1305,
    '1283_862': 31.8336,
    '1283_863': 31.5122,
    '1283_864': 31.18,
    '1283_865': 30.833,
    '1283_866': 30.4846,
    '1283_867': 30.1283,
    '1283_868': 29.7738,
    '1283_869': 29.4397,
    '1283_870': 29.1067,
    '1283_871': 28.7756,
    '1283_872': 28.454,
    '1284_790': 34.2066,
    '1284_791': 34.2773,
    '1284_792': 34.3612,
    '1284_793': 34.4559,
    '1284_794': 34.5649,
    '1284_795': 34.6882,
    '1284_796': 34.8167,
    '1284_797': 34.9449,
    '1284_798': 35.0648,
    '1284_799': 35.1791,
    '1284_800': 35.3103,
    '1284_801': 35.3842,
    '1284_802': 35.4463,
    '1284_803': 35.5068,
    '1284_804': 35.5698,
    '1284_805': 35.6175,
    '1284_806': 35.6323,
    '1284_807': 35.6464,
    '1284_808': 35.6549,
    '1284_809': 35.6254,
    '1284_810': 35.5726,
    '1284_811': 35.5089,
    '1284_812': 35.4614,
    '1284_813': 35.3586,
    '1284_814': 35.2481,
    '1284_815': 35.1408,
    '1284_816': 35.05,
    '1284_817': 34.9707,
    '1284_818': 34.921,
    '1284_819': 34.8997,
    '1284_820': 34.8715,
    '1284_821': 34.8533,
    '1284_822': 34.8476,
    '1284_823': 34.8512,
    '1284_824': 34.8641,
    '1284_825': 34.8829,
    '1284_826': 34.9203,
    '1284_827': 34.9675,
    '1284_828': 35.0223,
    '1284_829': 35.08,
    '1284_830': 35.1429,
    '1284_831': 35.1961,
    '1284_832': 35.2393,
    '1284_833': 35.2847,
    '1284_834': 35.3218,
    '1284_835': 35.3379,
    '1284_836': 35.3342,
    '1284_837': 35.3108,
    '1284_838': 35.2654,
    '1284_839': 35.1993,
    '1284_840': 35.1286,
    '1284_841': 35.0517,
    '1284_842': 34.9633,
    '1284_843': 34.8595,
    '1284_844': 34.7354,
    '1284_845': 34.5941,
    '1284_846': 34.4471,
    '1284_847': 34.3003,
    '1284_848': 34.1647,
    '1284_849': 34.0329,
    '1284_850': 33.9063,
    '1284_851': 33.7815,
    '1284_852': 33.6565,
    '1284_853': 33.5315,
    '1284_854': 33.4055,
    '1284_855': 33.2828,
    '1284_856': 33.1485,
    '1284_857': 32.9981,
    '1284_858': 32.8127,
    '1284_859': 32.5794,
    '1284_860': 32.3167,
    '1284_861': 32.0431,
    '1284_862': 31.7453,
    '1284_863': 31.4181,
    '1284_864': 31.0777,
    '1284_865': 30.7233,
    '1284_866': 30.377,
    '1284_867': 30.0201,
    '1284_868': 29.6639,
    '1284_869': 29.3157,
    '1284_870': 28.9716,
    '1284_871': 28.6305,
    '1284_872': 28.2945,
    '1285_790': 34.1377,
    '1285_791': 34.2092,
    '1285_792': 34.295,
    '1285_793': 34.3887,
    '1285_794': 34.4989,
    '1285_795': 34.6325,
    '1285_796': 34.7761,
    '1285_797': 34.9192,
    '1285_798': 35.049,
    '1285_799': 35.1681,
    '1285_800': 35.2843,
    '1285_801': 35.3792,
    '1285_802': 35.4521,
    '1285_803': 35.5176,
    '1285_804': 35.5715,
    '1285_805': 35.6073,
    '1285_806': 35.6284,
    '1285_807': 35.6558,
    '1285_808': 35.6655,
    '1285_809': 35.6232,
    '1285_810': 35.5638,
    '1285_811': 35.502,
    '1285_812': 35.4422,
    '1285_813': 35.3561,
    '1285_814': 35.2573,
    '1285_815': 35.1536,
    '1285_816': 35.0479,
    '1285_817': 34.9618,
    '1285_818': 34.8957,
    '1285_819': 34.8646,
    '1285_820': 34.8429,
    '1285_821': 34.8151,
    '1285_822': 34.8016,
    '1285_823': 34.8015,
    '1285_824': 34.8082,
    '1285_825': 34.8254,
    '1285_826': 34.8573,
    '1285_827': 34.8988,
    '1285_828': 34.9528,
    '1285_829': 35.0075,
    '1285_830': 35.0669,
    '1285_831': 35.1162,
    '1285_832': 35.1546,
    '1285_833': 35.1932,
    '1285_834': 35.2264,
    '1285_835': 35.2406,
    '1285_836': 35.2451,
    '1285_837': 35.232,
    '1285_838': 35.2036,
    '1285_839': 35.1492,
    '1285_840': 35.0818,
    '1285_841': 35.0084,
    '1285_842': 34.9191,
    '1285_843': 34.8057,
    '1285_844': 34.6702,
    '1285_845': 34.5218,
    '1285_846': 34.3705,
    '1285_847': 34.2246,
    '1285_848': 34.0883,
    '1285_849': 33.9543,
    '1285_850': 33.8236,
    '1285_851': 33.6946,
    '1285_852': 33.5626,
    '1285_853': 33.4304,
    '1285_854': 33.2978,
    '1285_855': 33.1653,
    '1285_856': 33.0307,
    '1285_857': 32.8759,
    '1285_858': 32.6944,
    '1285_859': 32.4777,
    '1285_860': 32.2212,
    '1285_861': 31.9517,
    '1285_862': 31.6519,
    '1285_863': 31.3243,
    '1285_864': 30.9782,
    '1285_865': 30.6237,
    '1285_866': 30.2771,
    '1285_867': 29.9219,
    '1285_868': 29.568,
    '1285_869': 29.2024,
    '1285_870': 28.8469,
    '1285_871': 28.494,
    '1285_872': 28.1467,
    '1286_790': 34.0575,
    '1286_791': 34.1308,
    '1286_792': 34.2162,
    '1286_793': 34.3138,
    '1286_794': 34.4284,
    '1286_795': 34.567,
    '1286_796': 34.7333,
    '1286_797': 34.8977,
    '1286_798': 35.0365,
    '1286_799': 35.1634,
    '1286_800': 35.2768,
    '1286_801': 35.3756,
    '1286_802': 35.4647,
    '1286_803': 35.5417,
    '1286_804': 35.5962,
    '1286_805': 35.6248,
    '1286_806': 35.6494,
    '1286_807': 35.6815,
    '1286_808': 35.6773,
    '1286_809': 35.624,
    '1286_810': 35.5587,
    '1286_811': 35.4947,
    '1286_812': 35.4292,
    '1286_813': 35.3537,
    '1286_814': 35.2709,
    '1286_815': 35.1827,
    '1286_816': 35.0738,
    '1286_817': 34.9842,
    '1286_818': 34.9005,
    '1286_819': 34.8433,
    '1286_820': 34.8265,
    '1286_821': 34.8111,
    '1286_822': 34.7884,
    '1286_823': 34.7701,
    '1286_824': 34.7648,
    '1286_825': 34.7759,
    '1286_826': 34.7999,
    '1286_827': 34.8342,
    '1286_828': 34.8818,
    '1286_829': 34.9353,
    '1286_830': 34.9913,
    '1286_831': 35.0366,
    '1286_832': 35.0745,
    '1286_833': 35.1025,
    '1286_834': 35.1286,
    '1286_835': 35.1444,
    '1286_836': 35.1514,
    '1286_837': 35.1464,
    '1286_838': 35.1341,
    '1286_839': 35.0909,
    '1286_840': 35.0292,
    '1286_841': 34.956,
    '1286_842': 34.8694,
    '1286_843': 34.7547,
    '1286_844': 34.6042,
    '1286_845': 34.4454,
    '1286_846': 34.2904,
    '1286_847': 34.1461,
    '1286_848': 34.0067,
    '1286_849': 33.8714,
    '1286_850': 33.7347,
    '1286_851': 33.5998,
    '1286_852': 33.4642,
    '1286_853': 33.3296,
    '1286_854': 33.1943,
    '1286_855': 33.0549,
    '1286_856': 32.9103,
    '1286_857': 32.7592,
    '1286_858': 32.5803,
    '1286_859': 32.3718,
    '1286_860': 32.1214,
    '1286_861': 31.853,
    '1286_862': 31.5596,
    '1286_863': 31.2352,
    '1286_864': 30.8886,
    '1286_865': 30.5383,
    '1286_866': 30.1866,
    '1286_867': 29.8358,
    '1286_868': 29.47,
    '1286_869': 29.0858,
    '1286_870': 28.7247,
    '1286_871': 28.3652,
    '1286_872': 28.0098,
    '1287_790': 33.9751,
    '1287_791': 34.0523,
    '1287_792': 34.1398,
    '1287_793': 34.2439,
    '1287_794': 34.3675,
    '1287_795': 34.5218,
    '1287_796': 34.6989,
    '1287_797': 34.873,
    '1287_798': 35.0129,
    '1287_799': 35.1462,
    '1287_800': 35.2658,
    '1287_801': 35.3805,
    '1287_802': 35.4872,
    '1287_803': 35.5788,
    '1287_804': 35.6345,
    '1287_805': 35.663,
    '1287_806': 35.6833,
    '1287_807': 35.7075,
    '1287_808': 35.6909,
    '1287_809': 35.6276,
    '1287_810': 35.5539,
    '1287_811': 35.4852,
    '1287_812': 35.4186,
    '1287_813': 35.347,
    '1287_814': 35.2691,
    '1287_815': 35.1866,
    '1287_816': 35.1021,
    '1287_817': 35.0207,
    '1287_818': 34.9202,
    '1287_819': 34.8612,
    '1287_820': 34.8318,
    '1287_821': 34.8176,
    '1287_822': 34.795,
    '1287_823': 34.7674,
    '1287_824': 34.7466,
    '1287_825': 34.7412,
    '1287_826': 34.7519,
    '1287_827': 34.776,
    '1287_828': 34.8136,
    '1287_829': 34.8642,
    '1287_830': 34.9144,
    '1287_831': 34.9548,
    '1287_832': 34.989,
    '1287_833': 35.0125,
    '1287_834': 35.0333,
    '1287_835': 35.0483,
    '1287_836': 35.0598,
    '1287_837': 35.0623,
    '1287_838': 35.0493,
    '1287_839': 35.0076,
    '1287_840': 34.9478,
    '1287_841': 34.8782,
    '1287_842': 34.7916,
    '1287_843': 34.6737,
    '1287_844': 34.5211,
    '1287_845': 34.3572,
    '1287_846': 34.2067,
    '1287_847': 34.0609,
    '1287_848': 33.9176,
    '1287_849': 33.7737,
    '1287_850': 33.6347,
    '1287_851': 33.497,
    '1287_852': 33.358,
    '1287_853': 33.2227,
    '1287_854': 33.0873,
    '1287_855': 32.9425,
    '1287_856': 32.7946,
    '1287_857': 32.6419,
    '1287_858': 32.4633,
    '1287_859': 32.2577,
    '1287_860': 32.0158,
    '1287_861': 31.7507,
    '1287_862': 31.4678,
    '1287_863': 31.144,
    '1287_864': 30.7995,
    '1287_865': 30.4593,
    '1287_866': 30.1124,
    '1287_867': 29.7542,
    '1287_868': 29.362,
    '1287_869': 28.9681,
    '1287_870': 28.593,
    '1287_871': 28.2296,
    '1287_872': 27.8716,
    '1288_790': 33.9079,
    '1288_791': 33.9882,
    '1288_792': 34.0822,
    '1288_793': 34.1939,
    '1288_794': 34.3286,
    '1288_795': 34.4937,
    '1288_796': 34.6782,
    '1288_797': 34.8543,
    '1288_798': 35.0003,
    '1288_799': 35.1346,
    '1288_800': 35.2626,
    '1288_801': 35.3915,
    '1288_802': 35.5147,
    '1288_803': 35.6171,
    '1288_804': 35.6729,
    '1288_805': 35.6974,
    '1288_806': 35.7161,
    '1288_807': 35.7369,
    '1288_808': 35.705,
    '1288_809': 35.6332,
    '1288_810': 35.5528,
    '1288_811': 35.4794,
    '1288_812': 35.414,
    '1288_813': 35.3429,
    '1288_814': 35.2619,
    '1288_815': 35.1714,
    '1288_816': 35.0783,
    '1288_817': 35.0097,
    '1288_818': 34.9505,
    '1288_819': 34.9165,
    '1288_820': 34.8618,
    '1288_821': 34.8455,
    '1288_822': 34.8193,
    '1288_823': 34.7837,
    '1288_824': 34.7441,
    '1288_825': 34.7209,
    '1288_826': 34.7141,
    '1288_827': 34.7242,
    '1288_828': 34.7488,
    '1288_829': 34.79,
    '1288_830': 34.8343,
    '1288_831': 34.8712,
    '1288_832': 34.9035,
    '1288_833': 34.9292,
    '1288_834': 34.9442,
    '1288_835': 34.9583,
    '1288_836': 34.9709,
    '1288_837': 34.9723,
    '1288_838': 34.9503,
    '1288_839': 34.9041,
    '1288_840': 34.8436,
    '1288_841': 34.7757,
    '1288_842': 34.6871,
    '1288_843': 34.5672,
    '1288_844': 34.4208,
    '1288_845': 34.2704,
    '1288_846': 34.124,
    '1288_847': 33.9767,
    '1288_848': 33.8283,
    '1288_849': 33.6735,
    '1288_850': 33.533,
    '1288_851': 33.3909,
    '1288_852': 33.2469,
    '1288_853': 33.1098,
    '1288_854': 32.9716,
    '1288_855': 32.826,
    '1288_856': 32.6799,
    '1288_857': 32.5199,
    '1288_858': 32.3402,
    '1288_859': 32.1353,
    '1288_860': 31.9053,
    '1288_861': 31.6508,
    '1288_862': 31.3752,
    '1288_863': 31.06,
    '1288_864': 30.7226,
    '1288_865': 30.3819,
    '1288_866': 30.0306,
    '1288_867': 29.6643,
    '1288_868': 29.2464,
    '1288_869': 28.8494,
    '1288_870': 28.4663,
    '1288_871': 28.0938,
    '1288_872': 27.728,
    '1289_790': 33.8561,
    '1289_791': 33.9347,
    '1289_792': 34.0307,
    '1289_793': 34.1467,
    '1289_794': 34.2882,
    '1289_795': 34.4639,
    '1289_796': 34.6591,
    '1289_797': 34.8339,
    '1289_798': 34.9868,
    '1289_799': 35.1249,
    '1289_800': 35.2628,
    '1289_801': 35.4036,
    '1289_802': 35.5359,
    '1289_803': 35.6442,
    '1289_804': 35.705,
    '1289_805': 35.726,
    '1289_806': 35.7417,
    '1289_807': 35.7587,
    '1289_808': 35.7164,
    '1289_809': 35.6402,
    '1289_810': 35.559,
    '1289_811': 35.491,
    '1289_812': 35.4277,
    '1289_813': 35.3554,
    '1289_814': 35.2722,
    '1289_815': 35.1794,
    '1289_816': 35.085,
    '1289_817': 35.0023,
    '1289_818': 34.9433,
    '1289_819': 34.8911,
    '1289_820': 34.8427,
    '1289_821': 34.8645,
    '1289_822': 34.8429,
    '1289_823': 34.8105,
    '1289_824': 34.7615,
    '1289_825': 34.7177,
    '1289_826': 34.6923,
    '1289_827': 34.6836,
    '1289_828': 34.6924,
    '1289_829': 34.7169,
    '1289_830': 34.7548,
    '1289_831': 34.7892,
    '1289_832': 34.8207,
    '1289_833': 34.8466,
    '1289_834': 34.8617,
    '1289_835': 34.8746,
    '1289_836': 34.8823,
    '1289_837': 34.8764,
    '1289_838': 34.8491,
    '1289_839': 34.7961,
    '1289_840': 34.7294,
    '1289_841': 34.6592,
    '1289_842': 34.5641,
    '1289_843': 34.4452,
    '1289_844': 34.3178,
    '1289_845': 34.1865,
    '1289_846': 34.0494,
    '1289_847': 33.8997,
    '1289_848': 33.7459,
    '1289_849': 33.5925,
    '1289_850': 33.4456,
    '1289_851': 33.2961,
    '1289_852': 33.1481,
    '1289_853': 33.0055,
    '1289_854': 32.8646,
    '1289_855': 32.722,
    '1289_856': 32.5728,
    '1289_857': 32.4108,
    '1289_858': 32.2346,
    '1289_859': 32.0341,
    '1289_860': 31.8092,
    '1289_861': 31.5644,
    '1289_862': 31.3055,
    '1289_863': 30.9983,
    '1289_864': 30.6665,
    '1289_865': 30.3067,
    '1289_866': 29.9258,
    '1289_867': 29.5291,
    '1289_868': 29.1318,
    '1289_869': 28.7338,
    '1289_870': 28.3423,
    '1289_871': 27.9602,
    '1289_872': 27.5845,
    '1290_790': 33.7966,
    '1290_791': 33.8766,
    '1290_792': 33.9723,
    '1290_793': 34.0893,
    '1290_794': 34.2423,
    '1290_795': 34.4325,
    '1290_796': 34.6318,
    '1290_797': 34.81,
    '1290_798': 34.9697,
    '1290_799': 35.1159,
    '1290_800': 35.2662,
    '1290_801': 35.4167,
    '1290_802': 35.5531,
    '1290_803': 35.6601,
    '1290_804': 35.728,
    '1290_805': 35.757,
    '1290_806': 35.7719,
    '1290_807': 35.7771,
    '1290_808': 35.7239,
    '1290_809': 35.6477,
    '1290_810': 35.5751,
    '1290_811': 35.5243,
    '1290_812': 35.4673,
    '1290_813': 35.3937,
    '1290_814': 35.3045,
    '1290_815': 35.2117,
    '1290_816': 35.1196,
    '1290_817': 35.0328,
    '1290_818': 34.9567,
    '1290_819': 34.8888,
    '1290_820': 34.8454,
    '1290_821': 34.8382,
    '1290_822': 34.83,
    '1290_823': 34.8155,
    '1290_824': 34.7735,
    '1290_825': 34.7267,
    '1290_826': 34.6813,
    '1290_827': 34.6513,
    '1290_828': 34.644,
    '1290_829': 34.6546,
    '1290_830': 34.6821,
    '1290_831': 34.7127,
    '1290_832': 34.7427,
    '1290_833': 34.7687,
    '1290_834': 34.7877,
    '1290_835': 34.7957,
    '1290_836': 34.799,
    '1290_837': 34.79,
    '1290_838': 34.7594,
    '1290_839': 34.6977,
    '1290_840': 34.6266,
    '1290_841': 34.5498,
    '1290_842': 34.4529,
    '1290_843': 34.3383,
    '1290_844': 34.2298,
    '1290_845': 34.1141,
    '1290_846': 33.9978,
    '1290_847': 33.8529,
    '1290_848': 33.6819,
    '1290_849': 33.5191,
    '1290_850': 33.3608,
    '1290_851': 33.2049,
    '1290_852': 33.0528,
    '1290_853': 32.9028,
    '1290_854': 32.7538,
    '1290_855': 32.6012,
    '1290_856': 32.4527,
    '1290_857': 32.3008,
    '1290_858': 32.1373,
    '1290_859': 31.9454,
    '1290_860': 31.7321,
    '1290_861': 31.4917,
    '1290_862': 31.2543,
    '1290_863': 30.9603,
    '1290_864': 30.634,
    '1290_865': 30.2374,
    '1290_866': 29.8318,
    '1290_867': 29.4265,
    '1290_868': 29.0247,
    '1290_869': 28.6211,
    '1290_870': 28.2206,
    '1290_871': 27.83,
    '1290_872': 27.4461,
    '1291_790': 33.7391,
    '1291_791': 33.819,
    '1291_792': 33.9157,
    '1291_793': 34.0193,
    '1291_794': 34.1727,
    '1291_795': 34.3745,
    '1291_796': 34.5865,
    '1291_797': 34.7762,
    '1291_798': 34.948,
    '1291_799': 35.1066,
    '1291_800': 35.2684,
    '1291_801': 35.4292,
    '1291_802': 35.5664,
    '1291_803': 35.6694,
    '1291_804': 35.744,
    '1291_805': 35.7841,
    '1291_806': 35.7992,
    '1291_807': 35.7941,
    '1291_808': 35.7359,
    '1291_809': 35.6573,
    '1291_810': 35.5878,
    '1291_811': 35.5458,
    '1291_812': 35.5014,
    '1291_813': 35.435,
    '1291_814': 35.3562,
    '1291_815': 35.267,
    '1291_816': 35.1728,
    '1291_817': 35.078,
    '1291_818': 34.9917,
    '1291_819': 34.9168,
    '1291_820': 34.8529,
    '1291_821': 34.8092,
    '1291_822': 34.7966,
    '1291_823': 34.7877,
    '1291_824': 34.7653,
    '1291_825': 34.7311,
    '1291_826': 34.6746,
    '1291_827': 34.6241,
    '1291_828': 34.5969,
    '1291_829': 34.5974,
    '1291_830': 34.6191,
    '1291_831': 34.6444,
    '1291_832': 34.6716,
    '1291_833': 34.6963,
    '1291_834': 34.7163,
    '1291_835': 34.7247,
    '1291_836': 34.7283,
    '1291_837': 34.7171,
    '1291_838': 34.6811,
    '1291_839': 34.6184,
    '1291_840': 34.5473,
    '1291_841': 34.4734,
    '1291_842': 34.3842,
    '1291_843': 34.2731,
    '1291_844': 34.1767,
    '1291_845': 34.0526,
    '1291_846': 33.9713,
    '1291_847': 33.8358,
    '1291_848': 33.6458,
    '1291_849': 33.4559,
    '1291_850': 33.2804,
    '1291_851': 33.1155,
    '1291_852': 32.9566,
    '1291_853': 32.803,
    '1291_854': 32.6509,
    '1291_855': 32.5087,
    '1291_856': 32.3632,
    '1291_857': 32.2016,
    '1291_858': 32.0393,
    '1291_859': 31.8508,
    '1291_860': 31.6448,
    '1291_861': 31.4246,
    '1291_862': 31.1961,
    '1291_863': 30.8672,
    '1291_864': 30.4988,
    '1291_865': 30.1169,
    '1291_866': 29.7216,
    '1291_867': 29.3217,
    '1291_868': 28.9215,
    '1291_869': 28.5129,
    '1291_870': 28.106,
    '1291_871': 27.7181,
    '1291_872': 27.3398,
    '1292_790': 33.7009,
    '1292_791': 33.7789,
    '1292_792': 33.8663,
    '1292_793': 33.9711,
    '1292_794': 34.118,
    '1292_795': 34.3161,
    '1292_796': 34.5428,
    '1292_797': 34.7377,
    '1292_798': 34.9182,
    '1292_799': 35.0904,
    '1292_800': 35.263,
    '1292_801': 35.4278,
    '1292_802': 35.5666,
    '1292_803': 35.6712,
    '1292_804': 35.7486,
    '1292_805': 35.7945,
    '1292_806': 35.812,
    '1292_807': 35.8051,
    '1292_808': 35.7489,
    '1292_809': 35.6679,
    '1292_810': 35.5923,
    '1292_811': 35.544,
    '1292_812': 35.502,
    '1292_813': 35.449,
    '1292_814': 35.3872,
    '1292_815': 35.3155,
    '1292_816': 35.2236,
    '1292_817': 35.1149,
    '1292_818': 35.0201,
    '1292_819': 34.944,
    '1292_820': 34.8637,
    '1292_821': 34.7962,
    '1292_822': 34.7675,
    '1292_823': 34.7549,
    '1292_824': 34.7391,
    '1292_825': 34.7105,
    '1292_826': 34.6557,
    '1292_827': 34.5954,
    '1292_828': 34.553,
    '1292_829': 34.5483,
    '1292_830': 34.568,
    '1292_831': 34.5873,
    '1292_832': 34.607,
    '1292_833': 34.6301,
    '1292_834': 34.6506,
    '1292_835': 34.6593,
    '1292_836': 34.6624,
    '1292_837': 34.649,
    '1292_838': 34.6099,
    '1292_839': 34.5533,
    '1292_840': 34.4815,
    '1292_841': 34.4176,
    '1292_842': 34.3651,
    '1292_843': 34.3194,
    '1292_844': 34.1869,
    '1292_845': 33.9782,
    '1292_846': 33.9415,
    '1292_847': 33.8019,
    '1292_848': 33.584,
    '1292_849': 33.3789,
    '1292_850': 33.1965,
    '1292_851': 33.0291,
    '1292_852': 32.867,
    '1292_853': 32.7154,
    '1292_854': 32.571,
    '1292_855': 32.4307,
    '1292_856': 32.2876,
    '1292_857': 32.1212,
    '1292_858': 31.9487,
    '1292_859': 31.7595,
    '1292_860': 31.5479,
    '1292_861': 31.3346,
    '1292_862': 31.05,
    '1292_863': 30.7345,
    '1292_864': 30.3839,
    '1292_865': 30.0094,
    '1292_866': 29.6147,
    '1292_867': 29.2222,
    '1292_868': 28.8182,
    '1292_869': 28.4075,
    '1292_870': 28.0007,
    '1292_871': 27.6056,
    '1292_872': 27.2204,
    '1293_790': 33.6623,
    '1293_791': 33.7363,
    '1293_792': 33.8228,
    '1293_793': 33.9265,
    '1293_794': 34.0613,
    '1293_795': 34.2481,
    '1293_796': 34.4855,
    '1293_797': 34.6891,
    '1293_798': 34.8799,
    '1293_799': 35.0705,
    '1293_800': 35.2564,
    '1293_801': 35.4217,
    '1293_802': 35.5534,
    '1293_803': 35.6638,
    '1293_804': 35.7474,
    '1293_805': 35.8,
    '1293_806': 35.8238,
    '1293_807': 35.816,
    '1293_808': 35.7578,
    '1293_809': 35.675,
    '1293_810': 35.593,
    '1293_811': 35.5303,
    '1293_812': 35.4823,
    '1293_813': 35.4303,
    '1293_814': 35.3789,
    '1293_815': 35.3202,
    '1293_816': 35.2338,
    '1293_817': 35.124,
    '1293_818': 35.0236,
    '1293_819': 34.9429,
    '1293_820': 34.8637,
    '1293_821': 34.7939,
    '1293_822': 34.7464,
    '1293_823': 34.7254,
    '1293_824': 34.7056,
    '1293_825': 34.6748,
    '1293_826': 34.6345,
    '1293_827': 34.5921,
    '1293_828': 34.5153,
    '1293_829': 34.5087,
    '1293_830': 34.5337,
    '1293_831': 34.5582,
    '1293_832': 34.5624,
    '1293_833': 34.5823,
    '1293_834': 34.5991,
    '1293_835': 34.6034,
    '1293_836': 34.5982,
    '1293_837': 34.5795,
    '1293_838': 34.5437,
    '1293_839': 34.51,
    '1293_840': 34.418,
    '1293_841': 34.3414,
    '1293_842': 34.2635,
    '1293_843': 34.1694,
    '1293_844': 34.0412,
    '1293_845': 33.9156,
    '1293_846': 33.7904,
    '1293_847': 33.6271,
    '1293_848': 33.4565,
    '1293_849': 33.2931,
    '1293_850': 33.1201,
    '1293_851': 32.9546,
    '1293_852': 32.7947,
    '1293_853': 32.6418,
    '1293_854': 32.4951,
    '1293_855': 32.3432,
    '1293_856': 32.1882,
    '1293_857': 32.022,
    '1293_858': 31.8419,
    '1293_859': 31.6446,
    '1293_860': 31.4273,
    '1293_861': 31.1968,
    '1293_862': 30.9154,
    '1293_863': 30.6006,
    '1293_864': 30.2568,
    '1293_865': 29.8941,
    '1293_866': 29.5093,
    '1293_867': 29.1077,
    '1293_868': 28.7044,
    '1293_869': 28.2912,
    '1293_870': 27.8808,
    '1293_871': 27.4804,
    '1293_872': 27.0908,
    '1294_790': 33.6281,
    '1294_791': 33.6999,
    '1294_792': 33.7875,
    '1294_793': 33.895,
    '1294_794': 34.0317,
    '1294_795': 34.2092,
    '1294_796': 34.4211,
    '1294_797': 34.6338,
    '1294_798': 34.8355,
    '1294_799': 35.042,
    '1294_800': 35.2402,
    '1294_801': 35.4107,
    '1294_802': 35.5407,
    '1294_803': 35.6485,
    '1294_804': 35.7371,
    '1294_805': 35.7929,
    '1294_806': 35.8218,
    '1294_807': 35.8158,
    '1294_808': 35.761,
    '1294_809': 35.6804,
    '1294_810': 35.5947,
    '1294_811': 35.5176,
    '1294_812': 35.4561,
    '1294_813': 35.3987,
    '1294_814': 35.3448,
    '1294_815': 35.2882,
    '1294_816': 35.2009,
    '1294_817': 35.0942,
    '1294_818': 35.007,
    '1294_819': 34.9311,
    '1294_820': 34.8542,
    '1294_821': 34.7816,
    '1294_822': 34.725,
    '1294_823': 34.6941,
    '1294_824': 34.6628,
    '1294_825': 34.6236,
    '1294_826': 34.5739,
    '1294_827': 34.5201,
    '1294_828': 34.4809,
    '1294_829': 34.4764,
    '1294_830': 34.4978,
    '1294_831': 34.5201,
    '1294_832': 34.5405,
    '1294_833': 34.5549,
    '1294_834': 34.5639,
    '1294_835': 34.5628,
    '1294_836': 34.5394,
    '1294_837': 34.5086,
    '1294_838': 34.4839,
    '1294_839': 34.5084,
    '1294_840': 34.4011,
    '1294_841': 34.2925,
    '1294_842': 34.1992,
    '1294_843': 34.1071,
    '1294_844': 33.9901,
    '1294_845': 33.8609,
    '1294_846': 33.7105,
    '1294_847': 33.5427,
    '1294_848': 33.3766,
    '1294_849': 33.2138,
    '1294_850': 33.0456,
    '1294_851': 32.8779,
    '1294_852': 32.7147,
    '1294_853': 32.5551,
    '1294_854': 32.4003,
    '1294_855': 32.2431,
    '1294_856': 32.078,
    '1294_857': 31.9035,
    '1294_858': 31.7153,
    '1294_859': 31.515,
    '1294_860': 31.2926,
    '1294_861': 31.0639,
    '1294_862': 30.786,
    '1294_863': 30.4745,
    '1294_864': 30.1365,
    '1294_865': 29.78,
    '1294_866': 29.4034,
    '1294_867': 28.9994,
    '1294_868': 28.5947,
    '1294_869': 28.1797,
    '1294_870': 27.7603,
    '1294_871': 27.3594,
    '1294_872': 26.9686,
    '1295_790': 33.5957,
    '1295_791': 33.6658,
    '1295_792': 33.7546,
    '1295_793': 33.8645,
    '1295_794': 34.001,
    '1295_795': 34.1704,
    '1295_796': 34.3698,
    '1295_797': 34.5827,
    '1295_798': 34.7922,
    '1295_799': 35.0079,
    '1295_800': 35.216,
    '1295_801': 35.3901,
    '1295_802': 35.5244,
    '1295_803': 35.6282,
    '1295_804': 35.7116,
    '1295_805': 35.7676,
    '1295_806': 35.802,
    '1295_807': 35.8023,
    '1295_808': 35.7555,
    '1295_809': 35.6812,
    '1295_810': 35.597,
    '1295_811': 35.5118,
    '1295_812': 35.4405,
    '1295_813': 35.3764,
    '1295_814': 35.3134,
    '1295_815': 35.2449,
    '1295_816': 35.1559,
    '1295_817': 35.0639,
    '1295_818': 34.9911,
    '1295_819': 34.9229,
    '1295_820': 34.8449,
    '1295_821': 34.7653,
    '1295_822': 34.6969,
    '1295_823': 34.6551,
    '1295_824': 34.6202,
    '1295_825': 34.5786,
    '1295_826': 34.5263,
    '1295_827': 34.4817,
    '1295_828': 34.454,
    '1295_829': 34.4483,
    '1295_830': 34.4618,
    '1295_831': 34.4779,
    '1295_832': 34.5045,
    '1295_833': 34.533,
    '1295_834': 34.5504,
    '1295_835': 34.5371,
    '1295_836': 34.502,
    '1295_837': 34.4722,
    '1295_838': 34.4621,
    '1295_839': 34.4661,
    '1295_840': 34.3512,
    '1295_841': 34.2462,
    '1295_842': 34.1586,
    '1295_843': 34.056,
    '1295_844': 33.9385,
    '1295_845': 33.8064,
    '1295_846': 33.6538,
    '1295_847': 33.4827,
    '1295_848': 33.3112,
    '1295_849': 33.1393,
    '1295_850': 32.9656,
    '1295_851': 32.791,
    '1295_852': 32.6222,
    '1295_853': 32.4537,
    '1295_854': 32.293,
    '1295_855': 32.1336,
    '1295_856': 31.9614,
    '1295_857': 31.7817,
    '1295_858': 31.5824,
    '1295_859': 31.3795,
    '1295_860': 31.1785,
    '1295_861': 30.959,
    '1295_862': 30.6724,
    '1295_863': 30.362,
    '1295_864': 30.0231,
    '1295_865': 29.6637,
    '1295_866': 29.2781,
    '1295_867': 28.8754,
    '1295_868': 28.4692,
    '1295_869': 28.0542,
    '1295_870': 27.6334,
    '1295_871': 27.2418,
    '1295_872': 26.8526,
    '1296_790': 33.5618,
    '1296_791': 33.6302,
    '1296_792': 33.7195,
    '1296_793': 33.8285,
    '1296_794': 33.9606,
    '1296_795': 34.1212,
    '1296_796': 34.3158,
    '1296_797': 34.5294,
    '1296_798': 34.7441,
    '1296_799': 34.9671,
    '1296_800': 35.1823,
    '1296_801': 35.3581,
    '1296_802': 35.4911,
    '1296_803': 35.5894,
    '1296_804': 35.6685,
    '1296_805': 35.7242,
    '1296_806': 35.7648,
    '1296_807': 35.7713,
    '1296_808': 35.74,
    '1296_809': 35.6771,
    '1296_810': 35.5978,
    '1296_811': 35.5114,
    '1296_812': 35.4349,
    '1296_813': 35.3645,
    '1296_814': 35.2944,
    '1296_815': 35.2161,
    '1296_816': 35.1247,
    '1296_817': 35.0415,
    '1296_818': 34.9796,
    '1296_819': 34.9207,
    '1296_820': 34.8389,
    '1296_821': 34.7526,
    '1296_822': 34.6755,
    '1296_823': 34.6208,
    '1296_824': 34.5819,
    '1296_825': 34.5372,
    '1296_826': 34.4909,
    '1296_827': 34.4537,
    '1296_828': 34.431,
    '1296_829': 34.4254,
    '1296_830': 34.436,
    '1296_831': 34.4513,
    '1296_832': 34.4733,
    '1296_833': 34.5065,
    '1296_834': 34.5149,
    '1296_835': 34.4942,
    '1296_836': 34.4584,
    '1296_837': 34.4343,
    '1296_838': 34.4011,
    '1296_839': 34.3647,
    '1296_840': 34.3054,
    '1296_841': 34.2138,
    '1296_842': 34.1157,
    '1296_843': 34.0053,
    '1296_844': 33.8814,
    '1296_845': 33.7455,
    '1296_846': 33.5877,
    '1296_847': 33.4142,
    '1296_848': 33.2374,
    '1296_849': 33.0489,
    '1296_850': 32.8644,
    '1296_851': 32.6834,
    '1296_852': 32.5087,
    '1296_853': 32.3356,
    '1296_854': 32.1719,
    '1296_855': 32.0098,
    '1296_856': 31.8369,
    '1296_857': 31.6604,
    '1296_858': 31.467,
    '1296_859': 31.2798,
    '1296_860': 31.0748,
    '1296_861': 30.8491,
    '1296_862': 30.5762,
    '1296_863': 30.2689,
    '1296_864': 29.931,
    '1296_865': 29.5694,
    '1296_866': 29.1822,
    '1296_867': 28.7729,
    '1296_868': 28.3569,
    '1296_869': 27.9443,
    '1296_870': 27.5347,
    '1296_871': 27.1352,
    '1296_872': 26.7403,
    '1297_790': 33.53,
    '1297_791': 33.5972,
    '1297_792': 33.6848,
    '1297_793': 33.7904,
    '1297_794': 33.9158,
    '1297_795': 34.0666,
    '1297_796': 34.2575,
    '1297_797': 34.4719,
    '1297_798': 34.6897,
    '1297_799': 34.9148,
    '1297_800': 35.1339,
    '1297_801': 35.3144,
    '1297_802': 35.4426,
    '1297_803': 35.5351,
    '1297_804': 35.6112,
    '1297_805': 35.6718,
    '1297_806': 35.7156,
    '1297_807': 35.7282,
    '1297_808': 35.7141,
    '1297_809': 35.6688,
    '1297_810': 35.5968,
    '1297_811': 35.5114,
    '1297_812': 35.4379,
    '1297_813': 35.367,
    '1297_814': 35.296,
    '1297_815': 35.213,
    '1297_816': 35.1174,
    '1297_817': 35.0295,
    '1297_818': 34.9684,
    '1297_819': 34.909,
    '1297_820': 34.8298,
    '1297_821': 34.7421,
    '1297_822': 34.6593,
    '1297_823': 34.5937,
    '1297_824': 34.5462,
    '1297_825': 34.5021,
    '1297_826': 34.4616,
    '1297_827': 34.4297,
    '1297_828': 34.4109,
    '1297_829': 34.4096,
    '1297_830': 34.4206,
    '1297_831': 34.4418,
    '1297_832': 34.4672,
    '1297_833': 34.4953,
    '1297_834': 34.4891,
    '1297_835': 34.4638,
    '1297_836': 34.4335,
    '1297_837': 34.4068,
    '1297_838': 34.3765,
    '1297_839': 34.3243,
    '1297_840': 34.2536,
    '1297_841': 34.1606,
    '1297_842': 34.0574,
    '1297_843': 33.9417,
    '1297_844': 33.8155,
    '1297_845': 33.6708,
    '1297_846': 33.5158,
    '1297_847': 33.3417,
    '1297_848': 33.154,
    '1297_849': 32.9484,
    '1297_850': 32.7565,
    '1297_851': 32.5694,
    '1297_852': 32.3947,
    '1297_853': 32.2244,
    '1297_854': 32.0548,
    '1297_855': 31.894,
    '1297_856': 31.7267,
    '1297_857': 31.551,
    '1297_858': 31.3801,
    '1297_859': 31.1949,
    '1297_860': 30.9912,
    '1297_861': 30.7536,
    '1297_862': 30.4827,
    '1297_863': 30.1758,
    '1297_864': 29.8439,
    '1297_865': 29.4849,
    '1297_866': 29.0991,
    '1297_867': 28.6805,
    '1297_868': 28.2549,
    '1297_869': 27.8422,
    '1297_870': 27.439,
    '1297_871': 27.0328,
    '1297_872': 26.6316,
    '1298_790': 33.4999,
    '1298_791': 33.5659,
    '1298_792': 33.6498,
    '1298_793': 33.7515,
    '1298_794': 33.8724,
    '1298_795': 34.0167,
    '1298_796': 34.1987,
    '1298_797': 34.4106,
    '1298_798': 34.6344,
    '1298_799': 34.8494,
    '1298_800': 35.0683,
    '1298_801': 35.2526,
    '1298_802': 35.3801,
    '1298_803': 35.47,
    '1298_804': 35.5535,
    '1298_805': 35.6213,
    '1298_806': 35.6645,
    '1298_807': 35.6796,
    '1298_808': 35.6717,
    '1298_809': 35.6398,
    '1298_810': 35.5803,
    '1298_811': 35.5091,
    '1298_812': 35.4416,
    '1298_813': 35.3697,
    '1298_814': 35.2983,
    '1298_815': 35.2153,
    '1298_816': 35.1179,
    '1298_817': 35.0276,
    '1298_818': 34.9647,
    '1298_819': 34.906,
    '1298_820': 34.8244,
    '1298_821': 34.7358,
    '1298_822': 34.6506,
    '1298_823': 34.5772,
    '1298_824': 34.5212,
    '1298_825': 34.4748,
    '1298_826': 34.4364,
    '1298_827': 34.4079,
    '1298_828': 34.3936,
    '1298_829': 34.3982,
    '1298_830': 34.4137,
    '1298_831': 34.4346,
    '1298_832': 34.4484,
    '1298_833': 34.4576,
    '1298_834': 34.4598,
    '1298_835': 34.4442,
    '1298_836': 34.4219,
    '1298_837': 34.4,
    '1298_838': 34.3497,
    '1298_839': 34.2732,
    '1298_840': 34.2035,
    '1298_841': 34.1136,
    '1298_842': 34.0044,
    '1298_843': 33.8872,
    '1298_844': 33.7565,
    '1298_845': 33.6051,
    '1298_846': 33.4431,
    '1298_847': 33.2645,
    '1298_848': 33.0656,
    '1298_849': 32.8577,
    '1298_850': 32.6556,
    '1298_851': 32.4635,
    '1298_852': 32.2852,
    '1298_853': 32.1158,
    '1298_854': 31.947,
    '1298_855': 31.7764,
    '1298_856': 31.6054,
    '1298_857': 31.4524,
    '1298_858': 31.2871,
    '1298_859': 31.0987,
    '1298_860': 30.8861,
    '1298_861': 30.64,
    '1298_862': 30.366,
    '1298_863': 30.0694,
    '1298_864': 29.7518,
    '1298_865': 29.393,
    '1298_866': 29.0018,
    '1298_867': 28.5854,
    '1298_868': 28.1589,
    '1298_869': 27.7366,
    '1298_870': 27.3271,
    '1298_871': 26.9232,
    '1298_872': 26.5207,
    '1299_790': 33.4677,
    '1299_791': 33.5314,
    '1299_792': 33.6115,
    '1299_793': 33.7086,
    '1299_794': 33.8241,
    '1299_795': 33.966,
    '1299_796': 34.1425,
    '1299_797': 34.3472,
    '1299_798': 34.5712,
    '1299_799': 34.7789,
    '1299_800': 34.9901,
    '1299_801': 35.1783,
    '1299_802': 35.3075,
    '1299_803': 35.4039,
    '1299_804': 35.4941,
    '1299_805': 35.5627,
    '1299_806': 35.6055,
    '1299_807': 35.6265,
    '1299_808': 35.6248,
    '1299_809': 35.5957,
    '1299_810': 35.5479,
    '1299_811': 35.5009,
    '1299_812': 35.437,
    '1299_813': 35.365,
    '1299_814': 35.2954,
    '1299_815': 35.2167,
    '1299_816': 35.1206,
    '1299_817': 35.0327,
    '1299_818': 34.9641,
    '1299_819': 34.8978,
    '1299_820': 34.8191,
    '1299_821': 34.7335,
    '1299_822': 34.6488,
    '1299_823': 34.572,
    '1299_824': 34.5085,
    '1299_825': 34.457,
    '1299_826': 34.4171,
    '1299_827': 34.3868,
    '1299_828': 34.3676,
    '1299_829': 34.3626,
    '1299_830': 34.3937,
    '1299_831': 34.4243,
    '1299_832': 34.4302,
    '1299_833': 34.4365,
    '1299_834': 34.4366,
    '1299_835': 34.4315,
    '1299_836': 34.4271,
    '1299_837': 34.3827,
    '1299_838': 34.3081,
    '1299_839': 34.2306,
    '1299_840': 34.1575,
    '1299_841': 34.0659,
    '1299_842': 33.9569,
    '1299_843': 33.8292,
    '1299_844': 33.6845,
    '1299_845': 33.5281,
    '1299_846': 33.3644,
    '1299_847': 33.1834,
    '1299_848': 32.971,
    '1299_849': 32.7592,
    '1299_850': 32.5498,
    '1299_851': 32.3527,
    '1299_852': 32.1712,
    '1299_853': 32.0041,
    '1299_854': 31.8384,
    '1299_855': 31.6697,
    '1299_856': 31.512,
    '1299_857': 31.3644,
    '1299_858': 31.192,
    '1299_859': 30.9938,
    '1299_860': 30.7746,
    '1299_861': 30.521,
    '1299_862': 30.2436,
    '1299_863': 29.9552,
    '1299_864': 29.6572,
    '1299_865': 29.2956,
    '1299_866': 28.9034,
    '1299_867': 28.4919,
    '1299_868': 28.0679,
    '1299_869': 27.6405,
    '1299_870': 27.221,
    '1299_871': 26.8181,
    '1299_872': 26.4119,
    '1299_873': 26.0131,
    '1299_874': 25.6246,
    '1299_875': 25.2483,
    '1299_876': 24.8836,
    '1299_877': 24.5329,
    '1299_878': 24.1977,
    '1299_879': 23.8745,
    '1299_880': 23.5574,
    '1299_881': 23.2426,
    '1299_882': 22.9411,
    '1299_883': 22.6503,
    '1299_884': 22.3676,
    '1299_885': 22.0752,
    '1299_886': 21.7965,
    '1299_887': 21.5217,
    '1299_888': 21.2567,
    '1299_889': 21.0071,
    '1299_890': 20.7764,
    '1299_891': 20.5565,
    '1299_892': 20.347,
    '1299_893': 20.1516,
    '1299_894': 19.9683,
    '1299_895': 19.7953,
    '1299_896': 19.6353,
    '1299_897': 19.4802,
    '1299_898': 19.3338,
    '1299_899': 19.1941,
    '1299_900': 19.0616,
    '1299_901': 18.9354,
    '1299_902': 18.8177,
    '1299_903': 18.7068,
    '1299_904': 18.6037,
    '1299_905': 18.5047,
    '1299_906': 18.4095,
    '1299_907': 18.3196,
    '1299_908': 18.2383,
    '1299_909': 18.1648,
    '1299_910': 18.0992,
    '1299_911': 18.0445,
    '1299_912': 17.9984,
    '1299_913': 17.9594,
    '1299_914': 17.925,
    '1299_915': 17.8962,
    '1299_916': 17.8746,
    '1299_917': 17.8571,
    '1299_918': 17.8374,
    '1299_919': 17.8101,
    '1299_920': 17.7781,
    '1299_921': 17.7409,
    '1299_922': 17.7048,
    '1299_923': 17.6655,
    '1299_924': 17.6163,
    '1299_925': 17.5644,
    '1299_926': 17.4988,
    '1299_927': 17.4348,
    '1299_928': 17.375,
    '1299_929': 17.3178,
    '1299_930': 17.2601,
    '1299_931': 17.1925,
    '1299_932': 17.1257,
    '1299_933': 17.0547,
    '1299_934': 16.985,
    '1299_935': 16.9133,
    '1299_936': 16.8316,
    '1299_937': 16.7502,
    '1299_938': 16.6596,
    '1299_939': 16.5658,
    '1299_940': 16.4795,
    '1300_790': 33.4326,
    '1300_791': 33.494,
    '1300_792': 33.5689,
    '1300_793': 33.6597,
    '1300_794': 33.7703,
    '1300_795': 33.9087,
    '1300_796': 34.0835,
    '1300_797': 34.283,
    '1300_798': 34.4974,
    '1300_799': 34.7024,
    '1300_800': 34.911,
    '1300_801': 35.0989,
    '1300_802': 35.2289,
    '1300_803': 35.3334,
    '1300_804': 35.4259,
    '1300_805': 35.4949,
    '1300_806': 35.543,
    '1300_807': 35.5734,
    '1300_808': 35.5758,
    '1300_809': 35.5486,
    '1300_810': 35.5082,
    '1300_811': 35.4751,
    '1300_812': 35.4203,
    '1300_813': 35.3524,
    '1300_814': 35.286,
    '1300_815': 35.2106,
    '1300_816': 35.1259,
    '1300_817': 35.0458,
    '1300_818': 34.9745,
    '1300_819': 34.903,
    '1300_820': 34.8243,
    '1300_821': 34.7403,
    '1300_822': 34.6562,
    '1300_823': 34.5776,
    '1300_824': 34.5076,
    '1300_825': 34.4502,
    '1300_826': 34.4098,
    '1300_827': 34.3809,
    '1300_828': 34.3626,
    '1300_829': 34.3586,
    '1300_830': 34.3778,
    '1300_831': 34.3961,
    '1300_832': 34.4027,
    '1300_833': 34.4182,
    '1300_834': 34.4142,
    '1300_835': 34.4071,
    '1300_836': 34.4258,
    '1300_837': 34.3489,
    '1300_838': 34.2573,
    '1300_839': 34.1732,
    '1300_840': 34.0944,
    '1300_841': 34.0153,
    '1300_842': 33.9164,
    '1300_843': 33.7797,
    '1300_844': 33.6217,
    '1300_845': 33.4578,
    '1300_846': 33.2863,
    '1300_847': 33.0869,
    '1300_848': 32.8623,
    '1300_849': 32.6426,
    '1300_850': 32.4364,
    '1300_851': 32.2379,
    '1300_852': 32.0513,
    '1300_853': 31.8785,
    '1300_854': 31.7131,
    '1300_855': 31.554,
    '1300_856': 31.4041,
    '1300_857': 31.2459,
    '1300_858': 31.0692,
    '1300_859': 30.8733,
    '1300_860': 30.6491,
    '1300_861': 30.4067,
    '1300_862': 30.1376,
    '1300_863': 29.846,
    '1300_864': 29.5304,
    '1300_865': 29.1787,
    '1300_866': 28.8026,
    '1300_867': 28.4046,
    '1300_868': 27.9875,
    '1300_869': 27.56,
    '1300_870': 27.1367,
    '1300_871': 26.721,
    '1300_872': 26.3104,
    '1300_873': 25.9089,
    '1300_874': 25.5198,
    '1300_875': 25.143,
    '1300_876': 24.7765,
    '1300_877': 24.4237,
    '1300_878': 24.0864,
    '1300_879': 23.7603,
    '1300_880': 23.441,
    '1300_881': 23.1298,
    '1300_882': 22.8441,
    '1300_883': 22.5769,
    '1300_884': 22.2741,
    '1300_885': 21.9768,
    '1300_886': 21.6955,
    '1300_887': 21.4246,
    '1300_888': 21.1654,
    '1300_889': 20.92,
    '1300_890': 20.6886,
    '1300_891': 20.4713,
    '1300_892': 20.2688,
    '1300_893': 20.0807,
    '1300_894': 19.9012,
    '1300_895': 19.7324,
    '1300_896': 19.5811,
    '1300_897': 19.4343,
    '1300_898': 19.296,
    '1300_899': 19.1612,
    '1300_900': 19.0319,
    '1300_901': 18.9134,
    '1300_902': 18.8034,
    '1300_903': 18.7,
    '1300_904': 18.6064,
    '1300_905': 18.5151,
    '1300_906': 18.4284,
    '1300_907': 18.3459,
    '1300_908': 18.2728,
    '1300_909': 18.2078,
    '1300_910': 18.1513,
    '1300_911': 18.1028,
    '1300_912': 18.0628,
    '1300_913': 18.0352,
    '1300_914': 18.0119,
    '1300_915': 17.994,
    '1300_916': 17.9858,
    '1300_917': 17.9786,
    '1300_918': 17.9691,
    '1300_919': 17.9577,
    '1300_920': 17.9354,
    '1300_921': 17.9055,
    '1300_922': 17.8713,
    '1300_923': 17.8311,
    '1300_924': 17.7845,
    '1300_925': 17.7311,
    '1300_926': 17.6724,
    '1300_927': 17.6121,
    '1300_928': 17.5518,
    '1300_929': 17.488,
    '1300_930': 17.4208,
    '1300_931': 17.3511,
    '1300_932': 17.2816,
    '1300_933': 17.2133,
    '1300_934': 17.1427,
    '1300_935': 17.0765,
    '1300_936': 16.9933,
    '1300_937': 16.9151,
    '1300_938': 16.8345,
    '1300_939': 16.7455,
    '1300_940': 16.6555,
    '1301_790': 33.393,
    '1301_791': 33.4507,
    '1301_792': 33.5212,
    '1301_793': 33.607,
    '1301_794': 33.7138,
    '1301_795': 33.8531,
    '1301_796': 34.0215,
    '1301_797': 34.2121,
    '1301_798': 34.4189,
    '1301_799': 34.6196,
    '1301_800': 34.825,
    '1301_801': 35.0151,
    '1301_802': 35.151,
    '1301_803': 35.2698,
    '1301_804': 35.3681,
    '1301_805': 35.4339,
    '1301_806': 35.4897,
    '1301_807': 35.5224,
    '1301_808': 35.5276,
    '1301_809': 35.5024,
    '1301_810': 35.4616,
    '1301_811': 35.4327,
    '1301_812': 35.3901,
    '1301_813': 35.3333,
    '1301_814': 35.272,
    '1301_815': 35.2053,
    '1301_816': 35.1364,
    '1301_817': 35.0668,
    '1301_818': 34.9952,
    '1301_819': 34.9202,
    '1301_820': 34.8393,
    '1301_821': 34.7545,
    '1301_822': 34.6697,
    '1301_823': 34.5898,
    '1301_824': 34.517,
    '1301_825': 34.4515,
    '1301_826': 34.4115,
    '1301_827': 34.3842,
    '1301_828': 34.3667,
    '1301_829': 34.361,
    '1301_830': 34.3701,
    '1301_831': 34.3822,
    '1301_832': 34.3907,
    '1301_833': 34.3983,
    '1301_834': 34.3925,
    '1301_835': 34.3726,
    '1301_836': 34.3447,
    '1301_837': 34.2728,
    '1301_838': 34.1974,
    '1301_839': 34.13,
    '1301_840': 34.0554,
    '1301_841': 33.9708,
    '1301_842': 33.8679,
    '1301_843': 33.7391,
    '1301_844': 33.5929,
    '1301_845': 33.4325,
    '1301_846': 33.2386,
    '1301_847': 33.0248,
    '1301_848': 32.7854,
    '1301_849': 32.5498,
    '1301_850': 32.338,
    '1301_851': 32.1395,
    '1301_852': 31.9539,
    '1301_853': 31.7819,
    '1301_854': 31.6178,
    '1301_855': 31.4593,
    '1301_856': 31.3083,
    '1301_857': 31.1395,
    '1301_858': 30.9681,
    '1301_859': 30.7724,
    '1301_860': 30.5534,
    '1301_861': 30.3136,
    '1301_862': 30.0459,
    '1301_863': 29.7489,
    '1301_864': 29.4215,
    '1301_865': 29.0662,
    '1301_866': 28.7086,
    '1301_867': 28.3259,
    '1301_868': 27.9181,
    '1301_869': 27.4972,
    '1301_870': 27.0732,
    '1301_871': 26.6502,
    '1301_872': 26.2323,
    '1301_873': 25.826,
    '1301_874': 25.4333,
    '1301_875': 25.0564,
    '1301_876': 24.6894,
    '1301_877': 24.3326,
    '1301_878': 23.9911,
    '1301_879': 23.661,
    '1301_880': 23.3459,
    '1301_881': 23.0467,
    '1301_882': 22.7649,
    '1301_883': 22.4888,
    '1301_884': 22.1744,
    '1301_885': 21.8794,
    '1301_886': 21.5999,
    '1301_887': 21.3329,
    '1301_888': 21.0794,
    '1301_889': 20.8404,
    '1301_890': 20.6144,
    '1301_891': 20.4032,
    '1301_892': 20.2044,
    '1301_893': 20.0186,
    '1301_894': 19.845,
    '1301_895': 19.6801,
    '1301_896': 19.531,
    '1301_897': 19.3915,
    '1301_898': 19.2574,
    '1301_899': 19.1233,
    '1301_900': 19.0014,
    '1301_901': 18.8922,
    '1301_902': 18.7895,
    '1301_903': 18.6901,
    '1301_904': 18.6046,
    '1301_905': 18.5186,
    '1301_906': 18.4378,
    '1301_907': 18.3675,
    '1301_908': 18.306,
    '1301_909': 18.2509,
    '1301_910': 18.2047,
    '1301_911': 18.1642,
    '1301_912': 18.1287,
    '1301_913': 18.1159,
    '1301_914': 18.1031,
    '1301_915': 18.0949,
    '1301_916': 18.1032,
    '1301_917': 18.11,
    '1301_918': 18.1101,
    '1301_919': 18.1064,
    '1301_920': 18.0892,
    '1301_921': 18.0645,
    '1301_922': 18.0346,
    '1301_923': 17.9998,
    '1301_924': 17.9566,
    '1301_925': 17.9055,
    '1301_926': 17.8515,
    '1301_927': 17.7924,
    '1301_928': 17.7293,
    '1301_929': 17.6663,
    '1301_930': 17.597,
    '1301_931': 17.5253,
    '1301_932': 17.453,
    '1301_933': 17.3834,
    '1301_934': 17.3155,
    '1301_935': 17.2489,
    '1301_936': 17.1697,
    '1301_937': 17.0897,
    '1301_938': 17.0069,
    '1301_939': 16.9183,
    '1301_940': 16.8249,
    '1302_790': 33.3553,
    '1302_791': 33.4077,
    '1302_792': 33.4736,
    '1302_793': 33.5548,
    '1302_794': 33.6579,
    '1302_795': 33.795,
    '1302_796': 33.9575,
    '1302_797': 34.141,
    '1302_798': 34.3394,
    '1302_799': 34.534,
    '1302_800': 34.7314,
    '1302_801': 34.9209,
    '1302_802': 35.0833,
    '1302_803': 35.2136,
    '1302_804': 35.3139,
    '1302_805': 35.3828,
    '1302_806': 35.4355,
    '1302_807': 35.4646,
    '1302_808': 35.4698,
    '1302_809': 35.4516,
    '1302_810': 35.4152,
    '1302_811': 35.3918,
    '1302_812': 35.3636,
    '1302_813': 35.3205,
    '1302_814': 35.2702,
    '1302_815': 35.2145,
    '1302_816': 35.1563,
    '1302_817': 35.0965,
    '1302_818': 35.0297,
    '1302_819': 34.952,
    '1302_820': 34.8675,
    '1302_821': 34.7794,
    '1302_822': 34.6896,
    '1302_823': 34.6072,
    '1302_824': 34.5338,
    '1302_825': 34.4713,
    '1302_826': 34.4263,
    '1302_827': 34.3962,
    '1302_828': 34.3778,
    '1302_829': 34.3701,
    '1302_830': 34.3744,
    '1302_831': 34.3802,
    '1302_832': 34.3849,
    '1302_833': 34.3851,
    '1302_834': 34.373,
    '1302_835': 34.3427,
    '1302_836': 34.2939,
    '1302_837': 34.2415,
    '1302_838': 34.1824,
    '1302_839': 34.1164,
    '1302_840': 34.0347,
    '1302_841': 33.9374,
    '1302_842': 33.8256,
    '1302_843': 33.6968,
    '1302_844': 33.5524,
    '1302_845': 33.38,
    '1302_846': 33.1781,
    '1302_847': 32.9554,
    '1302_848': 32.7162,
    '1302_849': 32.4791,
    '1302_850': 32.2592,
    '1302_851': 32.056,
    '1302_852': 31.8716,
    '1302_853': 31.7049,
    '1302_854': 31.5417,
    '1302_855': 31.3827,
    '1302_856': 31.225,
    '1302_857': 31.0554,
    '1302_858': 30.8782,
    '1302_859': 30.6837,
    '1302_860': 30.4702,
    '1302_861': 30.235,
    '1302_862': 29.968,
    '1302_863': 29.6711,
    '1302_864': 29.3439,
    '1302_865': 28.9965,
    '1302_866': 28.6331,
    '1302_867': 28.2518,
    '1302_868': 27.8528,
    '1302_869': 27.4386,
    '1302_870': 27.0173,
    '1302_871': 26.5906,
    '1302_872': 26.1681,
    '1302_873': 25.7568,
    '1302_874': 25.3595,
    '1302_875': 24.9762,
    '1302_876': 24.6041,
    '1302_877': 24.2457,
    '1302_878': 23.9016,
    '1302_879': 23.5711,
    '1302_880': 23.2557,
    '1302_881': 22.9587,
    '1302_882': 22.6731,
    '1302_883': 22.3888,
    '1302_884': 22.079,
    '1302_885': 21.7837,
    '1302_886': 21.5048,
    '1302_887': 21.2414,
    '1302_888': 20.9918,
    '1302_889': 20.7574,
    '1302_890': 20.5367,
    '1302_891': 20.329,
    '1302_892': 20.1339,
    '1302_893': 19.9516,
    '1302_894': 19.7833,
    '1302_895': 19.626,
    '1302_896': 19.4841,
    '1302_897': 19.3538,
    '1302_898': 19.2298,
    '1302_899': 19.1017,
    '1302_900': 18.9838,
    '1302_901': 18.8781,
    '1302_902': 18.7801,
    '1302_903': 18.6888,
    '1302_904': 18.608,
    '1302_905': 18.5286,
    '1302_906': 18.4577,
    '1302_907': 18.393,
    '1302_908': 18.3392,
    '1302_909': 18.2936,
    '1302_910': 18.257,
    '1302_911': 18.2324,
    '1302_912': 18.2089,
    '1302_913': 18.2045,
    '1302_914': 18.2049,
    '1302_915': 18.2148,
    '1302_916': 18.2317,
    '1302_917': 18.2431,
    '1302_918': 18.2462,
    '1302_919': 18.2431,
    '1302_920': 18.2332,
    '1302_921': 18.2167,
    '1302_922': 18.1927,
    '1302_923': 18.1614,
    '1302_924': 18.1209,
    '1302_925': 18.0742,
    '1302_926': 18.0241,
    '1302_927': 17.9652,
    '1302_928': 17.9016,
    '1302_929': 17.8452,
    '1302_930': 17.7736,
    '1302_931': 17.7004,
    '1302_932': 17.6251,
    '1302_933': 17.5535,
    '1302_934': 17.4854,
    '1302_935': 17.4177,
    '1302_936': 17.3426,
    '1302_937': 17.2625,
    '1302_938': 17.175,
    '1302_939': 17.0815,
    '1302_940': 16.9817,
    '1303_790': 33.3145,
    '1303_791': 33.3637,
    '1303_792': 33.4266,
    '1303_793': 33.505,
    '1303_794': 33.6053,
    '1303_795': 33.7358,
    '1303_796': 33.889,
    '1303_797': 34.0636,
    '1303_798': 34.2571,
    '1303_799': 34.4531,
    '1303_800': 34.637,
    '1303_801': 34.8209,
    '1303_802': 34.9956,
    '1303_803': 35.1401,
    '1303_804': 35.2467,
    '1303_805': 35.3194,
    '1303_806': 35.369,
    '1303_807': 35.3978,
    '1303_808': 35.4072,
    '1303_809': 35.3966,
    '1303_810': 35.3731,
    '1303_811': 35.361,
    '1303_812': 35.346,
    '1303_813': 35.3181,
    '1303_814': 35.276,
    '1303_815': 35.228,
    '1303_816': 35.1795,
    '1303_817': 35.1295,
    '1303_818': 35.0693,
    '1303_819': 34.9962,
    '1303_820': 34.9121,
    '1303_821': 34.8185,
    '1303_822': 34.7174,
    '1303_823': 34.6283,
    '1303_824': 34.5542,
    '1303_825': 34.4901,
    '1303_826': 34.4436,
    '1303_827': 34.4122,
    '1303_828': 34.3928,
    '1303_829': 34.3831,
    '1303_830': 34.3811,
    '1303_831': 34.3825,
    '1303_832': 34.3835,
    '1303_833': 34.3805,
    '1303_834': 34.368,
    '1303_835': 34.34,
    '1303_836': 34.2916,
    '1303_837': 34.2406,
    '1303_838': 34.1929,
    '1303_839': 34.1261,
    '1303_840': 34.0276,
    '1303_841': 33.9129,
    '1303_842': 33.7891,
    '1303_843': 33.6495,
    '1303_844': 33.4977,
    '1303_845': 33.3183,
    '1303_846': 33.1113,
    '1303_847': 32.8857,
    '1303_848': 32.6485,
    '1303_849': 32.416,
    '1303_850': 32.1978,
    '1303_851': 31.983,
    '1303_852': 31.8004,
    '1303_853': 31.6318,
    '1303_854': 31.4679,
    '1303_855': 31.3071,
    '1303_856': 31.1448,
    '1303_857': 30.9749,
    '1303_858': 30.7946,
    '1303_859': 30.5989,
    '1303_860': 30.3878,
    '1303_861': 30.1584,
    '1303_862': 29.8902,
    '1303_863': 29.5952,
    '1303_864': 29.2666,
    '1303_865': 28.9265,
    '1303_866': 28.557,
    '1303_867': 28.1749,
    '1303_868': 27.7824,
    '1303_869': 27.3723,
    '1303_870': 26.9562,
    '1303_871': 26.5266,
    '1303_872': 26.1019,
    '1303_873': 25.6885,
    '1303_874': 25.2885,
    '1303_875': 24.9023,
    '1303_876': 24.5285,
    '1303_877': 24.1684,
    '1303_878': 23.8216,
    '1303_879': 23.4877,
    '1303_880': 23.168,
    '1303_881': 22.8703,
    '1303_882': 22.5741,
    '1303_883': 22.2768,
    '1303_884': 21.9732,
    '1303_885': 21.6839,
    '1303_886': 21.4064,
    '1303_887': 21.1517,
    '1303_888': 20.9083,
    '1303_889': 20.6795,
    '1303_890': 20.4635,
    '1303_891': 20.2586,
    '1303_892': 20.0674,
    '1303_893': 19.8887,
    '1303_894': 19.7273,
    '1303_895': 19.5782,
    '1303_896': 19.4414,
    '1303_897': 19.3167,
    '1303_898': 19.1987,
    '1303_899': 19.0828,
    '1303_900': 18.9747,
    '1303_901': 18.8791,
    '1303_902': 18.7915,
    '1303_903': 18.7101,
    '1303_904': 18.6354,
    '1303_905': 18.5649,
    '1303_906': 18.5004,
    '1303_907': 18.4412,
    '1303_908': 18.3889,
    '1303_909': 18.3478,
    '1303_910': 18.3131,
    '1303_911': 18.2962,
    '1303_912': 18.2809,
    '1303_913': 18.2781,
    '1303_914': 18.302,
    '1303_915': 18.3328,
    '1303_916': 18.3606,
    '1303_917': 18.3753,
    '1303_918': 18.38,
    '1303_919': 18.3783,
    '1303_920': 18.376,
    '1303_921': 18.3682,
    '1303_922': 18.3489,
    '1303_923': 18.3206,
    '1303_924': 18.2808,
    '1303_925': 18.2375,
    '1303_926': 18.1918,
    '1303_927': 18.1327,
    '1303_928': 18.0699,
    '1303_929': 18.006,
    '1303_930': 17.937,
    '1303_931': 17.8623,
    '1303_932': 17.789,
    '1303_933': 17.7184,
    '1303_934': 17.6497,
    '1303_935': 17.5833,
    '1303_936': 17.5091,
    '1303_937': 17.4295,
    '1303_938': 17.3361,
    '1303_939': 17.2369,
    '1303_940': 17.1306,
    '1304_790': 33.2663,
    '1304_791': 33.3159,
    '1304_792': 33.3789,
    '1304_793': 33.4558,
    '1304_794': 33.5546,
    '1304_795': 33.6763,
    '1304_796': 33.8169,
    '1304_797': 33.9802,
    '1304_798': 34.1712,
    '1304_799': 34.3659,
    '1304_800': 34.5437,
    '1304_801': 34.7196,
    '1304_802': 34.8916,
    '1304_803': 35.0474,
    '1304_804': 35.1622,
    '1304_805': 35.2405,
    '1304_806': 35.2939,
    '1304_807': 35.325,
    '1304_808': 35.3416,
    '1304_809': 35.3422,
    '1304_810': 35.3403,
    '1304_811': 35.3389,
    '1304_812': 35.3329,
    '1304_813': 35.3169,
    '1304_814': 35.2852,
    '1304_815': 35.2405,
    '1304_816': 35.2004,
    '1304_817': 35.1604,
    '1304_818': 35.1063,
    '1304_819': 35.04,
    '1304_820': 34.9567,
    '1304_821': 34.8609,
    '1304_822': 34.7557,
    '1304_823': 34.6541,
    '1304_824': 34.5691,
    '1304_825': 34.5035,
    '1304_826': 34.4542,
    '1304_827': 34.4192,
    '1304_828': 34.4001,
    '1304_829': 34.3877,
    '1304_830': 34.3856,
    '1304_831': 34.3846,
    '1304_832': 34.3827,
    '1304_833': 34.3778,
    '1304_834': 34.3669,
    '1304_835': 34.3451,
    '1304_836': 34.2922,
    '1304_837': 34.24,
    '1304_838': 34.1922,
    '1304_839': 34.1185,
    '1304_840': 34.0192,
    '1304_841': 33.8992,
    '1304_842': 33.7648,
    '1304_843': 33.6096,
    '1304_844': 33.4414,
    '1304_845': 33.2544,
    '1304_846': 33.039,
    '1304_847': 32.8099,
    '1304_848': 32.5749,
    '1304_849': 32.3433,
    '1304_850': 32.1253,
    '1304_851': 31.9104,
    '1304_852': 31.712,
    '1304_853': 31.5496,
    '1304_854': 31.3873,
    '1304_855': 31.2275,
    '1304_856': 31.0643,
    '1304_857': 30.8939,
    '1304_858': 30.7112,
    '1304_859': 30.5101,
    '1304_860': 30.2923,
    '1304_861': 30.0567,
    '1304_862': 29.79,
    '1304_863': 29.492,
    '1304_864': 29.1617,
    '1304_865': 28.8208,
    '1304_866': 28.4585,
    '1304_867': 28.0793,
    '1304_868': 27.6869,
    '1304_869': 27.2805,
    '1304_870': 26.866,
    '1304_871': 26.4442,
    '1304_872': 26.0249,
    '1304_873': 25.6145,
    '1304_874': 25.2158,
    '1304_875': 24.8298,
    '1304_876': 24.4566,
    '1304_877': 24.0969,
    '1304_878': 23.7504,
    '1304_879': 23.4168,
    '1304_880': 23.0972,
    '1304_881': 22.7991,
    '1304_882': 22.4995,
    '1304_883': 22.1892,
    '1304_884': 21.8902,
    '1304_885': 21.605,
    '1304_886': 21.3353,
    '1304_887': 21.0822,
    '1304_888': 20.8434,
    '1304_889': 20.6185,
    '1304_890': 20.4042,
    '1304_891': 20.2018,
    '1304_892': 20.0141,
    '1304_893': 19.8409,
    '1304_894': 19.6834,
    '1304_895': 19.5394,
    '1304_896': 19.4075,
    '1304_897': 19.2874,
    '1304_898': 19.1759,
    '1304_899': 19.0716,
    '1304_900': 18.9764,
    '1304_901': 18.8921,
    '1304_902': 18.8161,
    '1304_903': 18.7441,
    '1304_904': 18.6776,
    '1304_905': 18.6158,
    '1304_906': 18.5592,
    '1304_907': 18.5092,
    '1304_908': 18.4652,
    '1304_909': 18.4299,
    '1304_910': 18.4044,
    '1304_911': 18.3893,
    '1304_912': 18.3827,
    '1304_913': 18.3895,
    '1304_914': 18.412,
    '1304_915': 18.4423,
    '1304_916': 18.4726,
    '1304_917': 18.4952,
    '1304_918': 18.511,
    '1304_919': 18.5204,
    '1304_920': 18.5244,
    '1304_921': 18.5219,
    '1304_922': 18.5036,
    '1304_923': 18.4777,
    '1304_924': 18.4371,
    '1304_925': 18.4,
    '1304_926': 18.3429,
    '1304_927': 18.2808,
    '1304_928': 18.2182,
    '1304_929': 18.1577,
    '1304_930': 18.0868,
    '1304_931': 18.0171,
    '1304_932': 17.9525,
    '1304_933': 17.882,
    '1304_934': 17.8099,
    '1304_935': 17.7423,
    '1304_936': 17.6667,
    '1304_937': 17.5825,
    '1304_938': 17.4869,
    '1304_939': 17.3832,
    '1304_940': 17.2719,
    '1305_790': 33.2041,
    '1305_791': 33.2632,
    '1305_792': 33.3305,
    '1305_793': 33.4054,
    '1305_794': 33.5023,
    '1305_795': 33.6161,
    '1305_796': 33.7466,
    '1305_797': 33.9003,
    '1305_798': 34.0814,
    '1305_799': 34.2756,
    '1305_800': 34.4558,
    '1305_801': 34.6208,
    '1305_802': 34.787,
    '1305_803': 34.9437,
    '1305_804': 35.0659,
    '1305_805': 35.1531,
    '1305_806': 35.2157,
    '1305_807': 35.2555,
    '1305_808': 35.2867,
    '1305_809': 35.3078,
    '1305_810': 35.3175,
    '1305_811': 35.3245,
    '1305_812': 35.3246,
    '1305_813': 35.3204,
    '1305_814': 35.299,
    '1305_815': 35.2563,
    '1305_816': 35.2177,
    '1305_817': 35.1853,
    '1305_818': 35.1428,
    '1305_819': 35.0809,
    '1305_820': 35.0004,
    '1305_821': 34.8959,
    '1305_822': 34.7883,
    '1305_823': 34.6794,
    '1305_824': 34.5847,
    '1305_825': 34.5127,
    '1305_826': 34.4615,
    '1305_827': 34.425,
    '1305_828': 34.4089,
    '1305_829': 34.3902,
    '1305_830': 34.3852,
    '1305_831': 34.384,
    '1305_832': 34.3778,
    '1305_833': 34.3683,
    '1305_834': 34.3515,
    '1305_835': 34.3247,
    '1305_836': 34.2839,
    '1305_837': 34.2398,
    '1305_838': 34.1845,
    '1305_839': 34.1047,
    '1305_840': 34.0025,
    '1305_841': 33.8814,
    '1305_842': 33.7418,
    '1305_843': 33.5726,
    '1305_844': 33.3875,
    '1305_845': 33.1864,
    '1305_846': 32.968,
    '1305_847': 32.7383,
    '1305_848': 32.5065,
    '1305_849': 32.2784,
    '1305_850': 32.056,
    '1305_851': 31.8368,
    '1305_852': 31.6284,
    '1305_853': 31.4538,
    '1305_854': 31.2931,
    '1305_855': 31.142,
    '1305_856': 30.9803,
    '1305_857': 30.808,
    '1305_858': 30.6224,
    '1305_859': 30.4198,
    '1305_860': 30.1988,
    '1305_861': 29.9565,
    '1305_862': 29.6894,
    '1305_863': 29.3958,
    '1305_864': 29.0734,
    '1305_865': 28.7243,
    '1305_866': 28.3579,
    '1305_867': 27.9774,
    '1305_868': 27.5846,
    '1305_869': 27.1793,
    '1305_870': 26.7674,
    '1305_871': 26.3518,
    '1305_872': 25.9394,
    '1305_873': 25.5343,
    '1305_874': 25.139,
    '1305_875': 24.7555,
    '1305_876': 24.3845,
    '1305_877': 24.0275,
    '1305_878': 23.685,
    '1305_879': 23.3573,
    '1305_880': 23.0445,
    '1305_881': 22.7426,
    '1305_882': 22.4329,
    '1305_883': 22.1145,
    '1305_884': 21.8141,
    '1305_885': 21.5331,
    '1305_886': 21.2713,
    '1305_887': 21.0203,
    '1305_888': 20.7854,
    '1305_889': 20.5654,
    '1305_890': 20.3521,
    '1305_891': 20.1527,
    '1305_892': 19.9689,
    '1305_893': 19.801,
    '1305_894': 19.648,
    '1305_895': 19.5085,
    '1305_896': 19.3811,
    '1305_897': 19.2663,
    '1305_898': 19.1619,
    '1305_899': 19.0671,
    '1305_900': 18.9828,
    '1305_901': 18.9094,
    '1305_902': 18.8451,
    '1305_903': 18.7812,
    '1305_904': 18.7239,
    '1305_905': 18.6707,
    '1305_906': 18.622,
    '1305_907': 18.582,
    '1305_908': 18.5469,
    '1305_909': 18.5184,
    '1305_910': 18.5026,
    '1305_911': 18.4914,
    '1305_912': 18.4919,
    '1305_913': 18.5064,
    '1305_914': 18.5263,
    '1305_915': 18.5538,
    '1305_916': 18.5866,
    '1305_917': 18.6157,
    '1305_918': 18.6423,
    '1305_919': 18.6612,
    '1305_920': 18.668,
    '1305_921': 18.6664,
    '1305_922': 18.6523,
    '1305_923': 18.6374,
    '1305_924': 18.598,
    '1305_925': 18.5535,
    '1305_926': 18.4992,
    '1305_927': 18.4414,
    '1305_928': 18.3857,
    '1305_929': 18.3351,
    '1305_930': 18.2515,
    '1305_931': 18.1808,
    '1305_932': 18.1161,
    '1305_933': 18.0495,
    '1305_934': 17.9804,
    '1305_935': 17.9095,
    '1305_936': 17.831,
    '1305_937': 17.7419,
    '1305_938': 17.6413,
    '1305_939': 17.5312,
    '1305_940': 17.4121,
    '1306_790': 33.1588,
    '1306_791': 33.2151,
    '1306_792': 33.2848,
    '1306_793': 33.3641,
    '1306_794': 33.4533,
    '1306_795': 33.5557,
    '1306_796': 33.6724,
    '1306_797': 33.8117,
    '1306_798': 33.9804,
    '1306_799': 34.164,
    '1306_800': 34.3498,
    '1306_801': 34.5215,
    '1306_802': 34.6883,
    '1306_803': 34.8417,
    '1306_804': 34.9687,
    '1306_805': 35.0666,
    '1306_806': 35.138,
    '1306_807': 35.1978,
    '1306_808': 35.2528,
    '1306_809': 35.2872,
    '1306_810': 35.3052,
    '1306_811': 35.3153,
    '1306_812': 35.3196,
    '1306_813': 35.3221,
    '1306_814': 35.3099,
    '1306_815': 35.2731,
    '1306_816': 35.229,
    '1306_817': 35.2015,
    '1306_818': 35.1734,
    '1306_819': 35.1212,
    '1306_820': 35.0396,
    '1306_821': 34.9239,
    '1306_822': 34.8119,
    '1306_823': 34.7,
    '1306_824': 34.5958,
    '1306_825': 34.5113,
    '1306_826': 34.4576,
    '1306_827': 34.4178,
    '1306_828': 34.3957,
    '1306_829': 34.3775,
    '1306_830': 34.3776,
    '1306_831': 34.3812,
    '1306_832': 34.3768,
    '1306_833': 34.3632,
    '1306_834': 34.342,
    '1306_835': 34.3145,
    '1306_836': 34.2824,
    '1306_837': 34.235,
    '1306_838': 34.1678,
    '1306_839': 34.0837,
    '1306_840': 33.9811,
    '1306_841': 33.8608,
    '1306_842': 33.7139,
    '1306_843': 33.5338,
    '1306_844': 33.3387,
    '1306_845': 33.1288,
    '1306_846': 32.9091,
    '1306_847': 32.6819,
    '1306_848': 32.451,
    '1306_849': 32.2224,
    '1306_850': 31.9984,
    '1306_851': 31.78,
    '1306_852': 31.5725,
    '1306_853': 31.3814,
    '1306_854': 31.2054,
    '1306_855': 31.0582,
    '1306_856': 30.8968,
    '1306_857': 30.7229,
    '1306_858': 30.5354,
    '1306_859': 30.3309,
    '1306_860': 30.1064,
    '1306_861': 29.8597,
    '1306_862': 29.5888,
    '1306_863': 29.2925,
    '1306_864': 28.9694,
    '1306_865': 28.6215,
    '1306_866': 28.2555,
    '1306_867': 27.8762,
    '1306_868': 27.4848,
    '1306_869': 27.0802,
    '1306_870': 26.669,
    '1306_871': 26.2567,
    '1306_872': 25.8504,
    '1306_873': 25.4503,
    '1306_874': 25.059,
    '1306_875': 24.6784,
    '1306_876': 24.3103,
    '1306_877': 23.9564,
    '1306_878': 23.618,
    '1306_879': 23.2955,
    '1306_880': 22.9877,
    '1306_881': 22.6829,
    '1306_882': 22.3457,
    '1306_883': 22.0313,
    '1306_884': 21.7372,
    '1306_885': 21.4595,
    '1306_886': 21.2016,
    '1306_887': 20.956,
    '1306_888': 20.7253,
    '1306_889': 20.5076,
    '1306_890': 20.3004,
    '1306_891': 20.1075,
    '1306_892': 19.9296,
    '1306_893': 19.768,
    '1306_894': 19.6208,
    '1306_895': 19.4864,
    '1306_896': 19.3634,
    '1306_897': 19.2543,
    '1306_898': 19.1563,
    '1306_899': 19.0686,
    '1306_900': 18.9919,
    '1306_901': 18.9247,
    '1306_902': 18.8661,
    '1306_903': 18.8122,
    '1306_904': 18.763,
    '1306_905': 18.7181,
    '1306_906': 18.6777,
    '1306_907': 18.644,
    '1306_908': 18.6152,
    '1306_909': 18.5939,
    '1306_910': 18.5844,
    '1306_911': 18.5822,
    '1306_912': 18.5922,
    '1306_913': 18.6124,
    '1306_914': 18.6392,
    '1306_915': 18.6732,
    '1306_916': 18.7107,
    '1306_917': 18.7462,
    '1306_918': 18.7773,
    '1306_919': 18.7992,
    '1306_920': 18.8094,
    '1306_921': 18.8091,
    '1306_922': 18.7979,
    '1306_923': 18.7794,
    '1306_924': 18.7462,
    '1306_925': 18.7038,
    '1306_926': 18.6543,
    '1306_927': 18.5994,
    '1306_928': 18.5422,
    '1306_929': 18.4827,
    '1306_930': 18.4123,
    '1306_931': 18.3453,
    '1306_932': 18.2819,
    '1306_933': 18.219,
    '1306_934': 18.1524,
    '1306_935': 18.0789,
    '1306_936': 17.9968,
    '1306_937': 17.9023,
    '1306_938': 17.7954,
    '1306_939': 17.6783,
    '1306_940': 17.5518,
    '1307_790': 33.1117,
    '1307_791': 33.1657,
    '1307_792': 33.2358,
    '1307_793': 33.3171,
    '1307_794': 33.3987,
    '1307_795': 33.4912,
    '1307_796': 33.5956,
    '1307_797': 33.7227,
    '1307_798': 33.8767,
    '1307_799': 34.0533,
    '1307_800': 34.245,
    '1307_801': 34.4339,
    '1307_802': 34.6,
    '1307_803': 34.7559,
    '1307_804': 34.8865,
    '1307_805': 34.985,
    '1307_806': 35.067,
    '1307_807': 35.158,
    '1307_808': 35.2277,
    '1307_809': 35.2695,
    '1307_810': 35.2954,
    '1307_811': 35.3078,
    '1307_812': 35.3074,
    '1307_813': 35.3079,
    '1307_814': 35.2994,
    '1307_815': 35.2688,
    '1307_816': 35.2281,
    '1307_817': 35.2088,
    '1307_818': 35.1892,
    '1307_819': 35.1413,
    '1307_820': 35.0537,
    '1307_821': 34.9406,
    '1307_822': 34.8282,
    '1307_823': 34.7159,
    '1307_824': 34.6053,
    '1307_825': 34.5063,
    '1307_826': 34.4478,
    '1307_827': 34.4065,
    '1307_828': 34.3828,
    '1307_829': 34.3714,
    '1307_830': 34.379,
    '1307_831': 34.3851,
    '1307_832': 34.3751,
    '1307_833': 34.3551,
    '1307_834': 34.3328,
    '1307_835': 34.3072,
    '1307_836': 34.2726,
    '1307_837': 34.2158,
    '1307_838': 34.1407,
    '1307_839': 34.0545,
    '1307_840': 33.9545,
    '1307_841': 33.835,
    '1307_842': 33.6836,
    '1307_843': 33.4988,
    '1307_844': 33.2985,
    '1307_845': 33.0887,
    '1307_846': 32.8681,
    '1307_847': 32.6403,
    '1307_848': 32.4013,
    '1307_849': 32.1683,
    '1307_850': 31.943,
    '1307_851': 31.7251,
    '1307_852': 31.5204,
    '1307_853': 31.3257,
    '1307_854': 31.1412,
    '1307_855': 30.9801,
    '1307_856': 30.8166,
    '1307_857': 30.6413,
    '1307_858': 30.4519,
    '1307_859': 30.246,
    '1307_860': 30.0178,
    '1307_861': 29.7661,
    '1307_862': 29.4907,
    '1307_863': 29.1899,
    '1307_864': 28.8635,
    '1307_865': 28.5151,
    '1307_866': 28.1495,
    '1307_867': 27.7721,
    '1307_868': 27.3841,
    '1307_869': 26.9799,
    '1307_870': 26.5712,
    '1307_871': 26.1636,
    '1307_872': 25.7607,
    '1307_873': 25.3641,
    '1307_874': 24.9758,
    '1307_875': 24.5977,
    '1307_876': 24.232,
    '1307_877': 23.8805,
    '1307_878': 23.5447,
    '1307_879': 23.2243,
    '1307_880': 22.9173,
    '1307_881': 22.6162,
    '1307_882': 22.2827,
    '1307_883': 21.9664,
    '1307_884': 21.6734,
    '1307_885': 21.3991,
    '1307_886': 21.1415,
    '1307_887': 20.8976,
    '1307_888': 20.6715,
    '1307_889': 20.4568,
    '1307_890': 20.2554,
    '1307_891': 20.0698,
    '1307_892': 19.8973,
    '1307_893': 19.743,
    '1307_894': 19.6025,
    '1307_895': 19.4749,
    '1307_896': 19.358,
    '1307_897': 19.253,
    '1307_898': 19.1603,
    '1307_899': 19.0781,
    '1307_900': 19.0082,
    '1307_901': 18.9454,
    '1307_902': 18.8923,
    '1307_903': 18.8469,
    '1307_904': 18.805,
    '1307_905': 18.7681,
    '1307_906': 18.7344,
    '1307_907': 18.7078,
    '1307_908': 18.6848,
    '1307_909': 18.6694,
    '1307_910': 18.6687,
    '1307_911': 18.6745,
    '1307_912': 18.6946,
    '1307_913': 18.7213,
    '1307_914': 18.7558,
    '1307_915': 18.798,
    '1307_916': 18.8414,
    '1307_917': 18.8817,
    '1307_918': 18.9164,
    '1307_919': 18.9402,
    '1307_920': 18.9521,
    '1307_921': 18.9532,
    '1307_922': 18.9418,
    '1307_923': 18.921,
    '1307_924': 18.8919,
    '1307_925': 18.8535,
    '1307_926': 18.8085,
    '1307_927': 18.7549,
    '1307_928': 18.694,
    '1307_929': 18.6295,
    '1307_930': 18.5683,
    '1307_931': 18.5068,
    '1307_932': 18.4458,
    '1307_933': 18.3866,
    '1307_934': 18.3209,
    '1307_935': 18.2423,
    '1307_936': 18.153,
    '1307_937': 18.0525,
    '1307_938': 17.9411,
    '1307_939': 17.8192,
    '1307_940': 17.6865,
    '1308_790': 33.0617,
    '1308_791': 33.1115,
    '1308_792': 33.1772,
    '1308_793': 33.2585,
    '1308_794': 33.3309,
    '1308_795': 33.4139,
    '1308_796': 33.5104,
    '1308_797': 33.6278,
    '1308_798': 33.7693,
    '1308_799': 33.9401,
    '1308_800': 34.1428,
    '1308_801': 34.3479,
    '1308_802': 34.5195,
    '1308_803': 34.6786,
    '1308_804': 34.8095,
    '1308_805': 34.9093,
    '1308_806': 35.0096,
    '1308_807': 35.1132,
    '1308_808': 35.1931,
    '1308_809': 35.2453,
    '1308_810': 35.2765,
    '1308_811': 35.2886,
    '1308_812': 35.2833,
    '1308_813': 35.2742,
    '1308_814': 35.2635,
    '1308_815': 35.2406,
    '1308_816': 35.2149,
    '1308_817': 35.2018,
    '1308_818': 35.1847,
    '1308_819': 35.136,
    '1308_820': 35.0521,
    '1308_821': 34.9502,
    '1308_822': 34.8407,
    '1308_823': 34.7275,
    '1308_824': 34.6059,
    '1308_825': 34.4988,
    '1308_826': 34.438,
    '1308_827': 34.3994,
    '1308_828': 34.38,
    '1308_829': 34.3758,
    '1308_830': 34.3869,
    '1308_831': 34.3892,
    '1308_832': 34.3764,
    '1308_833': 34.3519,
    '1308_834': 34.327,
    '1308_835': 34.2929,
    '1308_836': 34.2503,
    '1308_837': 34.1911,
    '1308_838': 34.1136,
    '1308_839': 34.0241,
    '1308_840': 33.9271,
    '1308_841': 33.8054,
    '1308_842': 33.6492,
    '1308_843': 33.4678,
    '1308_844': 33.2689,
    '1308_845': 33.0652,
    '1308_846': 32.8482,
    '1308_847': 32.6124,
    '1308_848': 32.3563,
    '1308_849': 32.1103,
    '1308_850': 31.8785,
    '1308_851': 31.6563,
    '1308_852': 31.4454,
    '1308_853': 31.2474,
    '1308_854': 31.0688,
    '1308_855': 30.9096,
    '1308_856': 30.7454,
    '1308_857': 30.5671,
    '1308_858': 30.3742,
    '1308_859': 30.1668,
    '1308_860': 29.9338,
    '1308_861': 29.6762,
    '1308_862': 29.3965,
    '1308_863': 29.0914,
    '1308_864': 28.7593,
    '1308_865': 28.4079,
    '1308_866': 28.0405,
    '1308_867': 27.6619,
    '1308_868': 27.2737,
    '1308_869': 26.8746,
    '1308_870': 26.4722,
    '1308_871': 26.0702,
    '1308_872': 25.6715,
    '1308_873': 25.278,
    '1308_874': 24.892,
    '1308_875': 24.516,
    '1308_876': 24.1524,
    '1308_877': 23.8033,
    '1308_878': 23.4702,
    '1308_879': 23.1529,
    '1308_880': 22.8444,
    '1308_881': 22.5527,
    '1308_882': 22.2181,
    '1308_883': 21.906,
    '1308_884': 21.6176,
    '1308_885': 21.3482,
    '1308_886': 21.0946,
    '1308_887': 20.8561,
    '1308_888': 20.6341,
    '1308_889': 20.4245,
    '1308_890': 20.2281,
    '1308_891': 20.0474,
    '1308_892': 19.8813,
    '1308_893': 19.7309,
    '1308_894': 19.5963,
    '1308_895': 19.4749,
    '1308_896': 19.3637,
    '1308_897': 19.2646,
    '1308_898': 19.1777,
    '1308_899': 19.1032,
    '1308_900': 19.04,
    '1308_901': 18.9839,
    '1308_902': 18.9366,
    '1308_903': 18.8958,
    '1308_904': 18.8602,
    '1308_905': 18.8303,
    '1308_906': 18.8045,
    '1308_907': 18.7853,
    '1308_908': 18.7712,
    '1308_909': 18.7658,
    '1308_910': 18.7734,
    '1308_911': 18.7907,
    '1308_912': 18.8138,
    '1308_913': 18.8471,
    '1308_914': 18.8891,
    '1308_915': 18.9336,
    '1308_916': 18.979,
    '1308_917': 19.0218,
    '1308_918': 19.059,
    '1308_919': 19.0862,
    '1308_920': 19.1001,
    '1308_921': 19.1022,
    '1308_922': 19.0894,
    '1308_923': 19.0712,
    '1308_924': 19.0444,
    '1308_925': 19.0091,
    '1308_926': 18.9667,
    '1308_927': 18.9137,
    '1308_928': 18.854,
    '1308_929': 18.7916,
    '1308_930': 18.7312,
    '1308_931': 18.6713,
    '1308_932': 18.6125,
    '1308_933': 18.5525,
    '1308_934': 18.4836,
    '1308_935': 18.3997,
    '1308_936': 18.3039,
    '1308_937': 18.1979,
    '1308_938': 18.0825,
    '1308_939': 17.9564,
    '1308_940': 17.8177,
    '1309_790': 33.0179,
    '1309_791': 33.0587,
    '1309_792': 33.107,
    '1309_793': 33.1699,
    '1309_794': 33.2505,
    '1309_795': 33.3356,
    '1309_796': 33.4285,
    '1309_797': 33.5401,
    '1309_798': 33.6703,
    '1309_799': 33.8325,
    '1309_800': 34.0478,
    '1309_801': 34.2637,
    '1309_802': 34.4434,
    '1309_803': 34.6011,
    '1309_804': 34.7312,
    '1309_805': 34.843,
    '1309_806': 34.9572,
    '1309_807': 35.0658,
    '1309_808': 35.1538,
    '1309_809': 35.2133,
    '1309_810': 35.2484,
    '1309_811': 35.2573,
    '1309_812': 35.2447,
    '1309_813': 35.2224,
    '1309_814': 35.2057,
    '1309_815': 35.1898,
    '1309_816': 35.1817,
    '1309_817': 35.1761,
    '1309_818': 35.1584,
    '1309_819': 35.1148,
    '1309_820': 35.0428,
    '1309_821': 34.9497,
    '1309_822': 34.8509,
    '1309_823': 34.731,
    '1309_824': 34.6,
    '1309_825': 34.4946,
    '1309_826': 34.4315,
    '1309_827': 34.4004,
    '1309_828': 34.3876,
    '1309_829': 34.3911,
    '1309_830': 34.4017,
    '1309_831': 34.4002,
    '1309_832': 34.3842,
    '1309_833': 34.3582,
    '1309_834': 34.3281,
    '1309_835': 34.2895,
    '1309_836': 34.239,
    '1309_837': 34.1768,
    '1309_838': 34.0952,
    '1309_839': 33.9998,
    '1309_840': 33.9018,
    '1309_841': 33.7738,
    '1309_842': 33.6167,
    '1309_843': 33.4383,
    '1309_844': 33.238,
    '1309_845': 33.0239,
    '1309_846': 32.8017,
    '1309_847': 32.5533,
    '1309_848': 32.2979,
    '1309_849': 32.0479,
    '1309_850': 31.8123,
    '1309_851': 31.5879,
    '1309_852': 31.3776,
    '1309_853': 31.1908,
    '1309_854': 31.0254,
    '1309_855': 30.8586,
    '1309_856': 30.6863,
    '1309_857': 30.5022,
    '1309_858': 30.3037,
    '1309_859': 30.0863,
    '1309_860': 29.8479,
    '1309_861': 29.5827,
    '1309_862': 29.2995,
    '1309_863': 28.9914,
    '1309_864': 28.6526,
    '1309_865': 28.2987,
    '1309_866': 27.9296,
    '1309_867': 27.5511,
    '1309_868': 27.1654,
    '1309_869': 26.7708,
    '1309_870': 26.3754,
    '1309_871': 25.9794,
    '1309_872': 25.5849,
    '1309_873': 25.1943,
    '1309_874': 24.8102,
    '1309_875': 24.4357,
    '1309_876': 24.0744,
    '1309_877': 23.7297,
    '1309_878': 23.4028,
    '1309_879': 23.1003,
    '1309_880': 22.805,
    '1309_881': 22.491,
    '1309_882': 22.1641,
    '1309_883': 21.8543,
    '1309_884': 21.5679,
    '1309_885': 21.3025,
    '1309_886': 21.0535,
    '1309_887': 20.8202,
    '1309_888': 20.604,
    '1309_889': 20.3995,
    '1309_890': 20.2072,
    '1309_891': 20.0314,
    '1309_892': 19.8703,
    '1309_893': 19.7235,
    '1309_894': 19.5957,
    '1309_895': 19.4816,
    '1309_896': 19.3744,
    '1309_897': 19.2816,
    '1309_898': 19.2005,
    '1309_899': 19.1336,
    '1309_900': 19.0785,
    '1309_901': 19.0281,
    '1309_902': 18.9875,
    '1309_903': 18.9516,
    '1309_904': 18.9216,
    '1309_905': 18.8993,
    '1309_906': 18.881,
    '1309_907': 18.8704,
    '1309_908': 18.865,
    '1309_909': 18.8667,
    '1309_910': 18.8798,
    '1309_911': 18.9025,
    '1309_912': 18.933,
    '1309_913': 18.9746,
    '1309_914': 19.0258,
    '1309_915': 19.0721,
    '1309_916': 19.1197,
    '1309_917': 19.1646,
    '1309_918': 19.2045,
    '1309_919': 19.235,
    '1309_920': 19.2485,
    '1309_921': 19.2504,
    '1309_922': 19.2406,
    '1309_923': 19.2267,
    '1309_924': 19.2006,
    '1309_925': 19.1664,
    '1309_926': 19.1244,
    '1309_927': 19.0737,
    '1309_928': 19.0169,
    '1309_929': 18.9569,
    '1309_930': 18.8968,
    '1309_931': 18.8359,
    '1309_932': 18.7763,
    '1309_933': 18.7121,
    '1309_934': 18.6379,
    '1309_935': 18.5508,
    '1309_936': 18.4495,
    '1309_937': 18.3388,
    '1309_938': 18.2201,
    '1309_939': 18.0912,
    '1309_940': 17.9483,
    '1310_790': 33.001,
    '1310_791': 33.0353,
    '1310_792': 33.0771,
    '1310_793': 33.1312,
    '1310_794': 33.201,
    '1310_795': 33.2809,
    '1310_796': 33.3723,
    '1310_797': 33.4819,
    '1310_798': 33.6148,
    '1310_799': 33.7811,
    '1310_800': 33.9875,
    '1310_801': 34.2048,
    '1310_802': 34.3931,
    '1310_803': 34.549,
    '1310_804': 34.6724,
    '1310_805': 34.7902,
    '1310_806': 34.9137,
    '1310_807': 35.0257,
    '1310_808': 35.1147,
    '1310_809': 35.1773,
    '1310_810': 35.2084,
    '1310_811': 35.2118,
    '1310_812': 35.1918,
    '1310_813': 35.1587,
    '1310_814': 35.1318,
    '1310_815': 35.1246,
    '1310_816': 35.1286,
    '1310_817': 35.1325,
    '1310_818': 35.1248,
    '1310_819': 35.0973,
    '1310_820': 35.0407,
    '1310_821': 34.9556,
    '1310_822': 34.8636,
    '1310_823': 34.7375,
    '1310_824': 34.6017,
    '1310_825': 34.4992,
    '1310_826': 34.4386,
    '1310_827': 34.4132,
    '1310_828': 34.4071,
    '1310_829': 34.4145,
    '1310_830': 34.4218,
    '1310_831': 34.4187,
    '1310_832': 34.4044,
    '1310_833': 34.3836,
    '1310_834': 34.3419,
    '1310_835': 34.2912,
    '1310_836': 34.2295,
    '1310_837': 34.16,
    '1310_838': 34.0762,
    '1310_839': 33.9784,
    '1310_840': 33.8716,
    '1310_841': 33.7367,
    '1310_842': 33.5817,
    '1310_843': 33.4061,
    '1310_844': 33.2047,
    '1310_845': 32.9731,
    '1310_846': 32.7296,
    '1310_847': 32.4811,
    '1310_848': 32.2328,
    '1310_849': 31.9842,
    '1310_850': 31.7492,
    '1310_851': 31.5286,
    '1310_852': 31.3279,
    '1310_853': 31.1485,
    '1310_854': 30.9812,
    '1310_855': 30.8116,
    '1310_856': 30.6331,
    '1310_857': 30.44,
    '1310_858': 30.2304,
    '1310_859': 29.9998,
    '1310_860': 29.7478,
    '1310_861': 29.4739,
    '1310_862': 29.1828,
    '1310_863': 28.8704,
    '1310_864': 28.5336,
    '1310_865': 28.1816,
    '1310_866': 27.8162,
    '1310_867': 27.4425,
    '1310_868': 27.0631,
    '1310_869': 26.6765,
    '1310_870': 26.2863,
    '1310_871': 25.8945,
    '1310_872': 25.5033,
    '1310_873': 25.1151,
    '1310_874': 24.7325,
    '1310_875': 24.359,
    '1310_876': 24.0002,
    '1310_877': 23.6635,
    '1310_878': 23.3458,
    '1310_879': 23.0453,
    '1310_880': 22.75,
    '1310_881': 22.4259,
    '1310_882': 22.1036,
    '1310_883': 21.8001,
    '1310_884': 21.5171,
    '1310_885': 21.2542,
    '1310_886': 21.0102,
    '1310_887': 20.782,
    '1310_888': 20.5692,
    '1310_889': 20.3707,
    '1310_890': 20.1845,
    '1310_891': 20.0123,
    '1310_892': 19.8538,
    '1310_893': 19.7137,
    '1310_894': 19.5942,
    '1310_895': 19.4879,
    '1310_896': 19.3833,
    '1310_897': 19.2961,
    '1310_898': 19.2217,
    '1310_899': 19.1606,
    '1310_900': 19.1101,
    '1310_901': 19.0671,
    '1310_902': 19.0328,
    '1310_903': 19.0046,
    '1310_904': 18.9816,
    '1310_905': 18.9652,
    '1310_906': 18.9566,
    '1310_907': 18.9548,
    '1310_908': 18.958,
    '1310_909': 18.9683,
    '1310_910': 18.9878,
    '1310_911': 19.0155,
    '1310_912': 19.051,
    '1310_913': 19.096,
    '1310_914': 19.1507,
    '1310_915': 19.204,
    '1310_916': 19.2573,
    '1310_917': 19.3074,
    '1310_918': 19.3469,
    '1310_919': 19.3774,
    '1310_920': 19.3932,
    '1310_921': 19.4,
    '1310_922': 19.3982,
    '1310_923': 19.3834,
    '1310_924': 19.3575,
    '1310_925': 19.3243,
    '1310_926': 19.2835,
    '1310_927': 19.2346,
    '1310_928': 19.1804,
    '1310_929': 19.1235,
    '1310_930': 19.0643,
    '1310_931': 19.003,
    '1310_932': 18.9396,
    '1310_933': 18.8697,
    '1310_934': 18.7883,
    '1310_935': 18.6947,
    '1310_936': 18.589,
    '1310_937': 18.4734,
    '1310_938': 18.35,
    '1310_939': 18.2177,
    '1310_940': 18.0741,
    '1311_790': 33.0037,
    '1311_791': 33.0343,
    '1311_792': 33.071,
    '1311_793': 33.1171,
    '1311_794': 33.1756,
    '1311_795': 33.247,
    '1311_796': 33.334,
    '1311_797': 33.4407,
    '1311_798': 33.5727,
    '1311_799': 33.7358,
    '1311_800': 33.9324,
    '1311_801': 34.1459,
    '1311_802': 34.3499,
    '1311_803': 34.5083,
    '1311_804': 34.6301,
    '1311_805': 34.7488,
    '1311_806': 34.8708,
    '1311_807': 34.9828,
    '1311_808': 35.0734,
    '1311_809': 35.1339,
    '1311_810': 35.1548,
    '1311_811': 35.1523,
    '1311_812': 35.1269,
    '1311_813': 35.0874,
    '1311_814': 35.0573,
    '1311_815': 35.0545,
    '1311_816': 35.0691,
    '1311_817': 35.082,
    '1311_818': 35.0879,
    '1311_819': 35.0783,
    '1311_820': 35.0321,
    '1311_821': 34.9631,
    '1311_822': 34.8765,
    '1311_823': 34.7518,
    '1311_824': 34.611,
    '1311_825': 34.511,
    '1311_826': 34.4536,
    '1311_827': 34.4316,
    '1311_828': 34.4297,
    '1311_829': 34.4394,
    '1311_830': 34.4477,
    '1311_831': 34.4461,
    '1311_832': 34.4375,
    '1311_833': 34.4166,
    '1311_834': 34.3634,
    '1311_835': 34.2963,
    '1311_836': 34.2211,
    '1311_837': 34.1378,
    '1311_838': 34.0516,
    '1311_839': 33.9486,
    '1311_840': 33.8221,
    '1311_841': 33.678,
    '1311_842': 33.5269,
    '1311_843': 33.3533,
    '1311_844': 33.1505,
    '1311_845': 32.9119,
    '1311_846': 32.6596,
    '1311_847': 32.4054,
    '1311_848': 32.1618,
    '1311_849': 31.9234,
    '1311_850': 31.6922,
    '1311_851': 31.4776,
    '1311_852': 31.2888,
    '1311_853': 31.1128,
    '1311_854': 30.9425,
    '1311_855': 30.7691,
    '1311_856': 30.5847,
    '1311_857': 30.3812,
    '1311_858': 30.1609,
    '1311_859': 29.9172,
    '1311_860': 29.653,
    '1311_861': 29.3695,
    '1311_862': 29.0734,
    '1311_863': 28.7602,
    '1311_864': 28.4253,
    '1311_865': 28.0783,
    '1311_866': 27.7191,
    '1311_867': 27.3481,
    '1311_868': 26.9751,
    '1311_869': 26.594,
    '1311_870': 26.2074,
    '1311_871': 25.8185,
    '1311_872': 25.4302,
    '1311_873': 25.0446,
    '1311_874': 24.6639,
    '1311_875': 24.2913,
    '1311_876': 23.9333,
    '1311_877': 23.6049,
    '1311_878': 23.2913,
    '1311_879': 22.9815,
    '1311_880': 22.6614,
    '1311_881': 22.3511,
    '1311_882': 22.0417,
    '1311_883': 21.7501,
    '1311_884': 21.4733,
    '1311_885': 21.2138,
    '1311_886': 20.9759,
    '1311_887': 20.7521,
    '1311_888': 20.5427,
    '1311_889': 20.3504,
    '1311_890': 20.1702,
    '1311_891': 20.0036,
    '1311_892': 19.8506,
    '1311_893': 19.7146,
    '1311_894': 19.5971,
    '1311_895': 19.4928,
    '1311_896': 19.3996,
    '1311_897': 19.3185,
    '1311_898': 19.2496,
    '1311_899': 19.1941,
    '1311_900': 19.1487,
    '1311_901': 19.1119,
    '1311_902': 19.0854,
    '1311_903': 19.0656,
    '1311_904': 19.0506,
    '1311_905': 19.0401,
    '1311_906': 19.0416,
    '1311_907': 19.0489,
    '1311_908': 19.0606,
    '1311_909': 19.0787,
    '1311_910': 19.1059,
    '1311_911': 19.1392,
    '1311_912': 19.1798,
    '1311_913': 19.2298,
    '1311_914': 19.2844,
    '1311_915': 19.3389,
    '1311_916': 19.3956,
    '1311_917': 19.4507,
    '1311_918': 19.4857,
    '1311_919': 19.5139,
    '1311_920': 19.5319,
    '1311_921': 19.5407,
    '1311_922': 19.542,
    '1311_923': 19.5347,
    '1311_924': 19.5158,
    '1311_925': 19.4835,
    '1311_926': 19.4437,
    '1311_927': 19.396,
    '1311_928': 19.3439,
    '1311_929': 19.2899,
    '1311_930': 19.2312,
    '1311_931': 19.1687,
    '1311_932': 19.102,
    '1311_933': 19.0273,
    '1311_934': 18.9382,
    '1311_935': 18.838,
    '1311_936': 18.728,
    '1311_937': 18.6088,
    '1311_938': 18.4811,
    '1311_939': 18.3443,
    '1311_940': 18.1984,
    '1312_790': 33.0202,
    '1312_791': 33.0476,
    '1312_792': 33.0789,
    '1312_793': 33.1172,
    '1312_794': 33.1658,
    '1312_795': 33.2277,
    '1312_796': 33.3089,
    '1312_797': 33.4097,
    '1312_798': 33.5357,
    '1312_799': 33.6905,
    '1312_800': 33.882,
    '1312_801': 34.0957,
    '1312_802': 34.319,
    '1312_803': 34.4776,
    '1312_804': 34.5952,
    '1312_805': 34.7097,
    '1312_806': 34.8292,
    '1312_807': 34.9322,
    '1312_808': 35.0185,
    '1312_809': 35.0707,
    '1312_810': 35.0869,
    '1312_811': 35.0832,
    '1312_812': 35.0534,
    '1312_813': 35.0109,
    '1312_814': 34.984,
    '1312_815': 34.9864,
    '1312_816': 35.0125,
    '1312_817': 35.0367,
    '1312_818': 35.0533,
    '1312_819': 35.0572,
    '1312_820': 35.0192,
    '1312_821': 34.9593,
    '1312_822': 34.8781,
    '1312_823': 34.759,
    '1312_824': 34.6213,
    '1312_825': 34.5249,
    '1312_826': 34.4696,
    '1312_827': 34.4488,
    '1312_828': 34.4509,
    '1312_829': 34.4653,
    '1312_830': 34.4776,
    '1312_831': 34.4787,
    '1312_832': 34.4701,
    '1312_833': 34.4427,
    '1312_834': 34.3811,
    '1312_835': 34.3054,
    '1312_836': 34.2182,
    '1312_837': 34.1179,
    '1312_838': 34.013,
    '1312_839': 33.8933,
    '1312_840': 33.7484,
    '1312_841': 33.5941,
    '1312_842': 33.4383,
    '1312_843': 33.26,
    '1312_844': 33.0532,
    '1312_845': 32.8175,
    '1312_846': 32.5739,
    '1312_847': 32.3294,
    '1312_848': 32.0963,
    '1312_849': 31.8708,
    '1312_850': 31.6478,
    '1312_851': 31.4464,
    '1312_852': 31.2599,
    '1312_853': 31.0811,
    '1312_854': 30.9059,
    '1312_855': 30.7264,
    '1312_856': 30.532,
    '1312_857': 30.3203,
    '1312_858': 30.091,
    '1312_859': 29.8415,
    '1312_860': 29.5732,
    '1312_861': 29.2887,
    '1312_862': 28.9908,
    '1312_863': 28.6783,
    '1312_864': 28.3498,
    '1312_865': 28.0075,
    '1312_866': 27.6495,
    '1312_867': 27.2809,
    '1312_868': 26.9068,
    '1312_869': 26.5251,
    '1312_870': 26.1388,
    '1312_871': 25.7514,
    '1312_872': 25.3657,
    '1312_873': 24.9841,
    '1312_874': 24.6076,
    '1312_875': 24.2405,
    '1312_876': 23.8903,
    '1312_877': 23.5653,
    '1312_878': 23.2583,
    '1312_879': 22.964,
    '1312_880': 22.6328,
    '1312_881': 22.3146,
    '1312_882': 22.0097,
    '1312_883': 21.7217,
    '1312_884': 21.4505,
    '1312_885': 21.1964,
    '1312_886': 20.9609,
    '1312_887': 20.741,
    '1312_888': 20.5369,
    '1312_889': 20.3472,
    '1312_890': 20.171,
    '1312_891': 20.0093,
    '1312_892': 19.8604,
    '1312_893': 19.7277,
    '1312_894': 19.6149,
    '1312_895': 19.5172,
    '1312_896': 19.4315,
    '1312_897': 19.355,
    '1312_898': 19.2921,
    '1312_899': 19.2426,
    '1312_900': 19.2021,
    '1312_901': 19.1734,
    '1312_902': 19.1529,
    '1312_903': 19.1425,
    '1312_904': 19.1387,
    '1312_905': 19.1376,
    '1312_906': 19.1453,
    '1312_907': 19.16,
    '1312_908': 19.181,
    '1312_909': 19.2082,
    '1312_910': 19.2421,
    '1312_911': 19.2819,
    '1312_912': 19.3282,
    '1312_913': 19.3797,
    '1312_914': 19.4334,
    '1312_915': 19.484,
    '1312_916': 19.5362,
    '1312_917': 19.5879,
    '1312_918': 19.6234,
    '1312_919': 19.6545,
    '1312_920': 19.6735,
    '1312_921': 19.6813,
    '1312_922': 19.6839,
    '1312_923': 19.6843,
    '1312_924': 19.6734,
    '1312_925': 19.6392,
    '1312_926': 19.5998,
    '1312_927': 19.5554,
    '1312_928': 19.5065,
    '1312_929': 19.4536,
    '1312_930': 19.3952,
    '1312_931': 19.3311,
    '1312_932': 19.2601,
    '1312_933': 19.179,
    '1312_934': 19.0845,
    '1312_935': 18.9794,
    '1312_936': 18.8655,
    '1312_937': 18.7434,
    '1312_938': 18.6114,
    '1312_939': 18.4704,
    '1312_940': 18.3211,
    '1313_790': 33.0493,
    '1313_791': 33.0726,
    '1313_792': 33.0978,
    '1313_793': 33.1299,
    '1313_794': 33.1717,
    '1313_795': 33.2255,
    '1313_796': 33.303,
    '1313_797': 33.3971,
    '1313_798': 33.5169,
    '1313_799': 33.6616,
    '1313_800': 33.8572,
    '1313_801': 34.0809,
    '1313_802': 34.2976,
    '1313_803': 34.451,
    '1313_804': 34.5627,
    '1313_805': 34.6698,
    '1313_806': 34.7798,
    '1313_807': 34.8775,
    '1313_808': 34.953,
    '1313_809': 35.0019,
    '1313_810': 35.0178,
    '1313_811': 35.0081,
    '1313_812': 34.9726,
    '1313_813': 34.9361,
    '1313_814': 34.9215,
    '1313_815': 34.933,
    '1313_816': 34.966,
    '1313_817': 34.9977,
    '1313_818': 35.0248,
    '1313_819': 35.0374,
    '1313_820': 35.008,
    '1313_821': 34.9488,
    '1313_822': 34.8677,
    '1313_823': 34.7543,
    '1313_824': 34.6282,
    '1313_825': 34.5334,
    '1313_826': 34.4792,
    '1313_827': 34.4617,
    '1313_828': 34.47,
    '1313_829': 34.4903,
    '1313_830': 34.5071,
    '1313_831': 34.5089,
    '1313_832': 34.4904,
    '1313_833': 34.4496,
    '1313_834': 34.3839,
    '1313_835': 34.3038,
    '1313_836': 34.2051,
    '1313_837': 34.0886,
    '1313_838': 33.9566,
    '1313_839': 33.8128,
    '1313_840': 33.6531,
    '1313_841': 33.4886,
    '1313_842': 33.326,
    '1313_843': 33.1422,
    '1313_844': 32.9341,
    '1313_845': 32.7108,
    '1313_846': 32.4778,
    '1313_847': 32.2526,
    '1313_848': 32.0352,
    '1313_849': 31.8309,
    '1313_850': 31.6203,
    '1313_851': 31.4222,
    '1313_852': 31.2323,
    '1313_853': 31.0476,
    '1313_854': 30.8681,
    '1313_855': 30.6847,
    '1313_856': 30.4818,
    '1313_857': 30.2637,
    '1313_858': 30.0291,
    '1313_859': 29.7765,
    '1313_860': 29.5073,
    '1313_861': 29.2234,
    '1313_862': 28.9264,
    '1313_863': 28.6164,
    '1313_864': 28.293,
    '1313_865': 27.9553,
    '1313_866': 27.5962,
    '1313_867': 27.2268,
    '1313_868': 26.8514,
    '1313_869': 26.4671,
    '1313_870': 26.0798,
    '1313_871': 25.6934,
    '1313_872': 25.3106,
    '1313_873': 24.9341,
    '1313_874': 24.5627,
    '1313_875': 24.2017,
    '1313_876': 23.8576,
    '1313_877': 23.5362,
    '1313_878': 23.2267,
    '1313_879': 22.9265,
    '1313_880': 22.6038,
    '1313_881': 22.2931,
    '1313_882': 21.9942,
    '1313_883': 21.7094,
    '1313_884': 21.4444,
    '1313_885': 21.1954,
    '1313_886': 20.9633,
    '1313_887': 20.7468,
    '1313_888': 20.5484,
    '1313_889': 20.3621,
    '1313_890': 20.1896,
    '1313_891': 20.0331,
    '1313_892': 19.8882,
    '1313_893': 19.7619,
    '1313_894': 19.653,
    '1313_895': 19.5629,
    '1313_896': 19.4843,
    '1313_897': 19.4107,
    '1313_898': 19.3539,
    '1313_899': 19.3121,
    '1313_900': 19.2752,
    '1313_901': 19.2558,
    '1313_902': 19.2394,
    '1313_903': 19.239,
    '1313_904': 19.2486,
    '1313_905': 19.2525,
    '1313_906': 19.2675,
    '1313_907': 19.2896,
    '1313_908': 19.3209,
    '1313_909': 19.3574,
    '1313_910': 19.3987,
    '1313_911': 19.4452,
    '1313_912': 19.4976,
    '1313_913': 19.5511,
    '1313_914': 19.6086,
    '1313_915': 19.6613,
    '1313_916': 19.7109,
    '1313_917': 19.756,
    '1313_918': 19.7905,
    '1313_919': 19.8159,
    '1313_920': 19.8306,
    '1313_921': 19.8346,
    '1313_922': 19.8322,
    '1313_923': 19.8354,
    '1313_924': 19.8253,
    '1313_925': 19.7904,
    '1313_926': 19.7552,
    '1313_927': 19.7151,
    '1313_928': 19.669,
    '1313_929': 19.6169,
    '1313_930': 19.5582,
    '1313_931': 19.4922,
    '1313_932': 19.4177,
    '1313_933': 19.3315,
    '1313_934': 19.2305,
    '1313_935': 19.1203,
    '1313_936': 19.0023,
    '1313_937': 18.8774,
    '1313_938': 18.7408,
    '1313_939': 18.5953,
    '1313_940': 18.4421,
    '1314_790': 33.0865,
    '1314_791': 33.1041,
    '1314_792': 33.1228,
    '1314_793': 33.1534,
    '1314_794': 33.1971,
    '1314_795': 33.2548,
    '1314_796': 33.3285,
    '1314_797': 33.4216,
    '1314_798': 33.5386,
    '1314_799': 33.6847,
    '1314_800': 33.8721,
    '1314_801': 34.0823,
    '1314_802': 34.2814,
    '1314_803': 34.4259,
    '1314_804': 34.5303,
    '1314_805': 34.6266,
    '1314_806': 34.722,
    '1314_807': 34.8138,
    '1314_808': 34.8843,
    '1314_809': 34.9341,
    '1314_810': 34.9489,
    '1314_811': 34.9377,
    '1314_812': 34.9022,
    '1314_813': 34.8786,
    '1314_814': 34.8763,
    '1314_815': 34.8942,
    '1314_816': 34.931,
    '1314_817': 34.9681,
    '1314_818': 35.0001,
    '1314_819': 35.0155,
    '1314_820': 34.9894,
    '1314_821': 34.9328,
    '1314_822': 34.856,
    '1314_823': 34.7469,
    '1314_824': 34.6269,
    '1314_825': 34.5385,
    '1314_826': 34.4814,
    '1314_827': 34.4667,
    '1314_828': 34.4829,
    '1314_829': 34.5117,
    '1314_830': 34.529,
    '1314_831': 34.5292,
    '1314_832': 34.4989,
    '1314_833': 34.4389,
    '1314_834': 34.3615,
    '1314_835': 34.2701,
    '1314_836': 34.1635,
    '1314_837': 34.034,
    '1314_838': 33.8826,
    '1314_839': 33.7169,
    '1314_840': 33.549,
    '1314_841': 33.3804,
    '1314_842': 33.212,
    '1314_843': 33.0284,
    '1314_844': 32.8267,
    '1314_845': 32.6081,
    '1314_846': 32.3893,
    '1314_847': 32.1832,
    '1314_848': 31.9823,
    '1314_849': 31.7838,
    '1314_850': 31.5816,
    '1314_851': 31.3831,
    '1314_852': 31.1901,
    '1314_853': 31.0036,
    '1314_854': 30.8217,
    '1314_855': 30.6325,
    '1314_856': 30.4305,
    '1314_857': 30.2127,
    '1314_858': 29.9762,
    '1314_859': 29.7221,
    '1314_860': 29.4527,
    '1314_861': 29.1696,
    '1314_862': 28.8739,
    '1314_863': 28.5653,
    '1314_864': 28.243,
    '1314_865': 27.9049,
    '1314_866': 27.5465,
    '1314_867': 27.1765,
    '1314_868': 26.7985,
    '1314_869': 26.4138,
    '1314_870': 26.0275,
    '1314_871': 25.6435,
    '1314_872': 25.2638,
    '1314_873': 24.8912,
    '1314_874': 24.5271,
    '1314_875': 24.1763,
    '1314_876': 23.8419,
    '1314_877': 23.5269,
    '1314_878': 23.2261,
    '1314_879': 22.918,
    '1314_880': 22.5968,
    '1314_881': 22.2881,
    '1314_882': 21.993,
    '1314_883': 21.7102,
    '1314_884': 21.4534,
    '1314_885': 21.2115,
    '1314_886': 20.9808,
    '1314_887': 20.7691,
    '1314_888': 20.5776,
    '1314_889': 20.3967,
    '1314_890': 20.231,
    '1314_891': 20.0769,
    '1314_892': 19.9322,
    '1314_893': 19.8074,
    '1314_894': 19.7112,
    '1314_895': 19.6335,
    '1314_896': 19.5589,
    '1314_897': 19.4913,
    '1314_898': 19.4423,
    '1314_899': 19.404,
    '1314_900': 19.3758,
    '1314_901': 19.3611,
    '1314_902': 19.3488,
    '1314_903': 19.3588,
    '1314_904': 19.3806,
    '1314_905': 19.3829,
    '1314_906': 19.4109,
    '1314_907': 19.4412,
    '1314_908': 19.4858,
    '1314_909': 19.5301,
    '1314_910': 19.5811,
    '1314_911': 19.6368,
    '1314_912': 19.6916,
    '1314_913': 19.748,
    '1314_914': 19.8065,
    '1314_915': 19.8579,
    '1314_916': 19.9036,
    '1314_917': 19.9426,
    '1314_918': 19.973,
    '1314_919': 19.9919,
    '1314_920': 20.0027,
    '1314_921': 20.006,
    '1314_922': 20.0037,
    '1314_923': 20.0007,
    '1314_924': 19.9868,
    '1314_925': 19.9539,
    '1314_926': 19.9176,
    '1314_927': 19.8781,
    '1314_928': 19.8327,
    '1314_929': 19.78,
    '1314_930': 19.7199,
    '1314_931': 19.6518,
    '1314_932': 19.5742,
    '1314_933': 19.4846,
    '1314_934': 19.3754,
    '1314_935': 19.2607,
    '1314_936': 19.1383,
    '1314_937': 19.0076,
    '1314_938': 18.8669,
    '1314_939': 18.717,
    '1314_940': 18.5602,
    '1315_790': 33.118,
    '1315_791': 33.1336,
    '1315_792': 33.1473,
    '1315_793': 33.1806,
    '1315_794': 33.2302,
    '1315_795': 33.2946,
    '1315_796': 33.3674,
    '1315_797': 33.4612,
    '1315_798': 33.5761,
    '1315_799': 33.7169,
    '1315_800': 33.8946,
    '1315_801': 34.0879,
    '1315_802': 34.2701,
    '1315_803': 34.4047,
    '1315_804': 34.5065,
    '1315_805': 34.5862,
    '1315_806': 34.6658,
    '1315_807': 34.7457,
    '1315_808': 34.8148,
    '1315_809': 34.8697,
    '1315_810': 34.8931,
    '1315_811': 34.8868,
    '1315_812': 34.8594,
    '1315_813': 34.8454,
    '1315_814': 34.8476,
    '1315_815': 34.87,
    '1315_816': 34.9103,
    '1315_817': 34.9513,
    '1315_818': 34.9796,
    '1315_819': 34.9941,
    '1315_820': 34.9702,
    '1315_821': 34.9148,
    '1315_822': 34.838,
    '1315_823': 34.7309,
    '1315_824': 34.6308,
    '1315_825': 34.551,
    '1315_826': 34.4853,
    '1315_827': 34.4711,
    '1315_828': 34.4928,
    '1315_829': 34.5199,
    '1315_830': 34.5358,
    '1315_831': 34.5291,
    '1315_832': 34.4877,
    '1315_833': 34.4108,
    '1315_834': 34.3173,
    '1315_835': 34.2151,
    '1315_836': 34.0967,
    '1315_837': 33.9566,
    '1315_838': 33.7925,
    '1315_839': 33.6227,
    '1315_840': 33.4591,
    '1315_841': 33.2935,
    '1315_842': 33.1222,
    '1315_843': 32.9354,
    '1315_844': 32.7377,
    '1315_845': 32.5284,
    '1315_846': 32.3173,
    '1315_847': 32.1307,
    '1315_848': 31.9393,
    '1315_849': 31.7437,
    '1315_850': 31.5441,
    '1315_851': 31.3448,
    '1315_852': 31.1489,
    '1315_853': 30.9611,
    '1315_854': 30.7789,
    '1315_855': 30.587,
    '1315_856': 30.3883,
    '1315_857': 30.1732,
    '1315_858': 29.9356,
    '1315_859': 29.68,
    '1315_860': 29.4113,
    '1315_861': 29.1293,
    '1315_862': 28.8342,
    '1315_863': 28.526,
    '1315_864': 28.2024,
    '1315_865': 27.8622,
    '1315_866': 27.5046,
    '1315_867': 27.135,
    '1315_868': 26.756,
    '1315_869': 26.3707,
    '1315_870': 25.9855,
    '1315_871': 25.6039,
    '1315_872': 25.2265,
    '1315_873': 24.8576,
    '1315_874': 24.4992,
    '1315_875': 24.1571,
    '1315_876': 23.8282,
    '1315_877': 23.5134,
    '1315_878': 23.2092,
    '1315_879': 22.8996,
    '1315_880': 22.5874,
    '1315_881': 22.2887,
    '1315_882': 22.005,
    '1315_883': 21.7324,
    '1315_884': 21.4753,
    '1315_885': 21.2374,
    '1315_886': 21.0045,
    '1315_887': 20.797,
    '1315_888': 20.6156,
    '1315_889': 20.4372,
    '1315_890': 20.2802,
    '1315_891': 20.1312,
    '1315_892': 19.9873,
    '1315_893': 19.8696,
    '1315_894': 19.7724,
    '1315_895': 19.6909,
    '1315_896': 19.6147,
    '1315_897': 19.5455,
    '1315_898': 19.5021,
    '1315_899': 19.4668,
    '1315_900': 19.4459,
    '1315_901': 19.4409,
    '1315_902': 19.4452,
    '1315_903': 19.4706,
    '1315_904': 19.5044,
    '1315_905': 19.5368,
    '1315_906': 19.584,
    '1315_907': 19.6287,
    '1315_908': 19.6768,
    '1315_909': 19.7261,
    '1315_910': 19.7769,
    '1315_911': 19.8393,
    '1315_912': 19.8934,
    '1315_913': 19.951,
    '1315_914': 20.0136,
    '1315_915': 20.0639,
    '1315_916': 20.1035,
    '1315_917': 20.1354,
    '1315_918': 20.1584,
    '1315_919': 20.1729,
    '1315_920': 20.1817,
    '1315_921': 20.1853,
    '1315_922': 20.1818,
    '1315_923': 20.1746,
    '1315_924': 20.1597,
    '1315_925': 20.1229,
    '1315_926': 20.0853,
    '1315_927': 20.046,
    '1315_928': 19.9997,
    '1315_929': 19.9427,
    '1315_930': 19.8802,
    '1315_931': 19.8101,
    '1315_932': 19.7271,
    '1315_933': 19.6316,
    '1315_934': 19.5216,
    '1315_935': 19.4027,
    '1315_936': 19.2753,
    '1315_937': 19.1394,
    '1315_938': 18.9946,
    '1315_939': 18.8419,
    '1315_940': 18.6822,
    '1316_790': 33.1369,
    '1316_791': 33.1508,
    '1316_792': 33.1707,
    '1316_793': 33.2061,
    '1316_794': 33.2598,
    '1316_795': 33.3276,
    '1316_796': 33.4059,
    '1316_797': 33.5026,
    '1316_798': 33.6197,
    '1316_799': 33.7583,
    '1316_800': 33.9195,
    '1316_801': 34.0917,
    '1316_802': 34.2575,
    '1316_803': 34.3852,
    '1316_804': 34.4814,
    '1316_805': 34.5552,
    '1316_806': 34.6271,
    '1316_807': 34.6976,
    '1316_808': 34.7631,
    '1316_809': 34.8187,
    '1316_810': 34.8494,
    '1316_811': 34.8505,
    '1316_812': 34.8356,
    '1316_813': 34.8279,
    '1316_814': 34.8327,
    '1316_815': 34.8573,
    '1316_816': 34.896,
    '1316_817': 34.9344,
    '1316_818': 34.9577,
    '1316_819': 34.9688,
    '1316_820': 34.9469,
    '1316_821': 34.8921,
    '1316_822': 34.8121,
    '1316_823': 34.7213,
    '1316_824': 34.6438,
    '1316_825': 34.5624,
    '1316_826': 34.4921,
    '1316_827': 34.4697,
    '1316_828': 34.4816,
    '1316_829': 34.5018,
    '1316_830': 34.514,
    '1316_831': 34.4957,
    '1316_832': 34.4487,
    '1316_833': 34.363,
    '1316_834': 34.2595,
    '1316_835': 34.1501,
    '1316_836': 34.0179,
    '1316_837': 33.8647,
    '1316_838': 33.6982,
    '1316_839': 33.5393,
    '1316_840': 33.3799,
    '1316_841': 33.2178,
    '1316_842': 33.048,
    '1316_843': 32.8672,
    '1316_844': 32.6763,
    '1316_845': 32.4782,
    '1316_846': 32.28,
    '1316_847': 32.0925,
    '1316_848': 31.9047,
    '1316_849': 31.7132,
    '1316_850': 31.5186,
    '1316_851': 31.3234,
    '1316_852': 31.13,
    '1316_853': 30.9407,
    '1316_854': 30.7538,
    '1316_855': 30.5617,
    '1316_856': 30.3614,
    '1316_857': 30.1458,
    '1316_858': 29.91,
    '1316_859': 29.6526,
    '1316_860': 29.3861,
    '1316_861': 29.107,
    '1316_862': 28.8118,
    '1316_863': 28.5011,
    '1316_864': 28.1746,
    '1316_865': 27.8326,
    '1316_866': 27.4763,
    '1316_867': 27.1076,
    '1316_868': 26.7298,
    '1316_869': 26.3448,
    '1316_870': 25.959,
    '1316_871': 25.5774,
    '1316_872': 25.2016,
    '1316_873': 24.8354,
    '1316_874': 24.4822,
    '1316_875': 24.1445,
    '1316_876': 23.8207,
    '1316_877': 23.5103,
    '1316_878': 23.2186,
    '1316_879': 22.8977,
    '1316_880': 22.5848,
    '1316_881': 22.2877,
    '1316_882': 22.006,
    '1316_883': 21.7381,
    '1316_884': 21.4861,
    '1316_885': 21.2508,
    '1316_886': 21.0285,
    '1316_887': 20.827,
    '1316_888': 20.645,
    '1316_889': 20.473,
    '1316_890': 20.3166,
    '1316_891': 20.1725,
    '1316_892': 20.0409,
    '1316_893': 19.9298,
    '1316_894': 19.836,
    '1316_895': 19.755,
    '1316_896': 19.6845,
    '1316_897': 19.6258,
    '1316_898': 19.5818,
    '1316_899': 19.5503,
    '1316_900': 19.5344,
    '1316_901': 19.5349,
    '1316_902': 19.5544,
    '1316_903': 19.59,
    '1316_904': 19.6343,
    '1316_905': 19.6829,
    '1316_906': 19.7368,
    '1316_907': 19.7881,
    '1316_908': 19.8449,
    '1316_909': 19.9036,
    '1316_910': 19.9617,
    '1316_911': 20.0245,
    '1316_912': 20.0845,
    '1316_913': 20.1444,
    '1316_914': 20.2088,
    '1316_915': 20.2705,
    '1316_916': 20.2992,
    '1316_917': 20.3275,
    '1316_918': 20.3444,
    '1316_919': 20.3538,
    '1316_920': 20.3606,
    '1316_921': 20.3635,
    '1316_922': 20.3596,
    '1316_923': 20.3491,
    '1316_924': 20.3294,
    '1316_925': 20.2972,
    '1316_926': 20.2614,
    '1316_927': 20.2207,
    '1316_928': 20.1696,
    '1316_929': 20.1124,
    '1316_930': 20.0452,
    '1316_931': 19.9694,
    '1316_932': 19.8805,
    '1316_933': 19.7801,
    '1316_934': 19.6678,
    '1316_935': 19.545,
    '1316_936': 19.4129,
    '1316_937': 19.2722,
    '1316_938': 19.1229,
    '1316_939': 18.9672,
    '1316_940': 18.8048,
    '1317_790': 33.1617,
    '1317_791': 33.1723,
    '1317_792': 33.1939,
    '1317_793': 33.2311,
    '1317_794': 33.2902,
    '1317_795': 33.3621,
    '1317_796': 33.4435,
    '1317_797': 33.5431,
    '1317_798': 33.6617,
    '1317_799': 33.7961,
    '1317_800': 33.9422,
    '1317_801': 34.0945,
    '1317_802': 34.2474,
    '1317_803': 34.3681,
    '1317_804': 34.4567,
    '1317_805': 34.5363,
    '1317_806': 34.6083,
    '1317_807': 34.674,
    '1317_808': 34.7344,
    '1317_809': 34.7871,
    '1317_810': 34.8226,
    '1317_811': 34.832,
    '1317_812': 34.8289,
    '1317_813': 34.8289,
    '1317_814': 34.8331,
    '1317_815': 34.8589,
    '1317_816': 34.8945,
    '1317_817': 34.9222,
    '1317_818': 34.9383,
    '1317_819': 34.9467,
    '1317_820': 34.9223,
    '1317_821': 34.8653,
    '1317_822': 34.7865,
    '1317_823': 34.7137,
    '1317_824': 34.6396,
    '1317_825': 34.5566,
    '1317_826': 34.4844,
    '1317_827': 34.451,
    '1317_828': 34.444,
    '1317_829': 34.4512,
    '1317_830': 34.4614,
    '1317_831': 34.4422,
    '1317_832': 34.3857,
    '1317_833': 34.2932,
    '1317_834': 34.1857,
    '1317_835': 34.0685,
    '1317_836': 33.9306,
    '1317_837': 33.7701,
    '1317_838': 33.6137,
    '1317_839': 33.464,
    '1317_840': 33.3137,
    '1317_841': 33.1571,
    '1317_842': 32.9915,
    '1317_843': 32.8159,
    '1317_844': 32.6317,
    '1317_845': 32.4424,
    '1317_846': 32.2519,
    '1317_847': 32.0651,
    '1317_848': 31.879,
    '1317_849': 31.6913,
    '1317_850': 31.5016,
    '1317_851': 31.3105,
    '1317_852': 31.1193,
    '1317_853': 30.9289,
    '1317_854': 30.7385,
    '1317_855': 30.545,
    '1317_856': 30.344,
    '1317_857': 30.129,
    '1317_858': 29.8981,
    '1317_859': 29.6449,
    '1317_860': 29.3776,
    '1317_861': 29.1009,
    '1317_862': 28.8003,
    '1317_863': 28.4872,
    '1317_864': 28.1575,
    '1317_865': 27.8133,
    '1317_866': 27.4571,
    '1317_867': 27.088,
    '1317_868': 26.7115,
    '1317_869': 26.3261,
    '1317_870': 25.9393,
    '1317_871': 25.558,
    '1317_872': 25.1834,
    '1317_873': 24.8203,
    '1317_874': 24.4716,
    '1317_875': 24.1405,
    '1317_876': 23.8257,
    '1317_877': 23.5239,
    '1317_878': 23.2121,
    '1317_879': 22.8878,
    '1317_880': 22.5804,
    '1317_881': 22.2865,
    '1317_882': 22.0059,
    '1317_883': 21.7405,
    '1317_884': 21.493,
    '1317_885': 21.2623,
    '1317_886': 21.0483,
    '1317_887': 20.8531,
    '1317_888': 20.673,
    '1317_889': 20.505,
    '1317_890': 20.3507,
    '1317_891': 20.2118,
    '1317_892': 20.0903,
    '1317_893': 19.987,
    '1317_894': 19.9008,
    '1317_895': 19.8243,
    '1317_896': 19.7623,
    '1317_897': 19.7153,
    '1317_898': 19.674,
    '1317_899': 19.6477,
    '1317_900': 19.639,
    '1317_901': 19.6486,
    '1317_902': 19.6773,
    '1317_903': 19.7217,
    '1317_904': 19.7756,
    '1317_905': 19.8369,
    '1317_906': 19.8991,
    '1317_907': 19.9627,
    '1317_908': 20.0248,
    '1317_909': 20.0847,
    '1317_910': 20.145,
    '1317_911': 20.2089,
    '1317_912': 20.2728,
    '1317_913': 20.3288,
    '1317_914': 20.3849,
    '1317_915': 20.4379,
    '1317_916': 20.469,
    '1317_917': 20.5116,
    '1317_918': 20.5264,
    '1317_919': 20.5324,
    '1317_920': 20.5395,
    '1317_921': 20.5433,
    '1317_922': 20.5402,
    '1317_923': 20.5282,
    '1317_924': 20.5067,
    '1317_925': 20.4768,
    '1317_926': 20.4436,
    '1317_927': 20.4002,
    '1317_928': 20.3506,
    '1317_929': 20.2898,
    '1317_930': 20.2132,
    '1317_931': 20.1311,
    '1317_932': 20.0365,
    '1317_933': 19.931,
    '1317_934': 19.8148,
    '1317_935': 19.6873,
    '1317_936': 19.5502,
    '1317_937': 19.405,
    '1317_938': 19.2502,
    '1317_939': 19.091,
    '1317_940': 18.9255,
    '1318_790': 33.1822,
    '1318_791': 33.1949,
    '1318_792': 33.2215,
    '1318_793': 33.2662,
    '1318_794': 33.3269,
    '1318_795': 33.4002,
    '1318_796': 33.4854,
    '1318_797': 33.586,
    '1318_798': 33.7023,
    '1318_799': 33.8311,
    '1318_800': 33.9688,
    '1318_801': 34.1109,
    '1318_802': 34.2494,
    '1318_803': 34.3616,
    '1318_804': 34.4479,
    '1318_805': 34.5289,
    '1318_806': 34.5997,
    '1318_807': 34.661,
    '1318_808': 34.7213,
    '1318_809': 34.7768,
    '1318_810': 34.8173,
    '1318_811': 34.832,
    '1318_812': 34.8348,
    '1318_813': 34.8387,
    '1318_814': 34.8449,
    '1318_815': 34.8723,
    '1318_816': 34.9072,
    '1318_817': 34.9273,
    '1318_818': 34.9286,
    '1318_819': 34.9294,
    '1318_820': 34.901,
    '1318_821': 34.8424,
    '1318_822': 34.7677,
    '1318_823': 34.6882,
    '1318_824': 34.6073,
    '1318_825': 34.519,
    '1318_826': 34.4426,
    '1318_827': 34.3976,
    '1318_828': 34.3792,
    '1318_829': 34.3763,
    '1318_830': 34.3794,
    '1318_831': 34.3558,
    '1318_832': 34.2936,
    '1318_833': 34.2014,
    '1318_834': 34.0937,
    '1318_835': 33.9672,
    '1318_836': 33.8277,
    '1318_837': 33.6761,
    '1318_838': 33.5356,
    '1318_839': 33.3993,
    '1318_840': 33.258,
    '1318_841': 33.108,
    '1318_842': 32.9473,
    '1318_843': 32.7762,
    '1318_844': 32.5973,
    '1318_845': 32.4136,
    '1318_846': 32.2277,
    '1318_847': 32.043,
    '1318_848': 31.8595,
    '1318_849': 31.6758,
    '1318_850': 31.4901,
    '1318_851': 31.3019,
    '1318_852': 31.1116,
    '1318_853': 30.9195,
    '1318_854': 30.726,
    '1318_855': 30.5286,
    '1318_856': 30.3238,
    '1318_857': 30.1086,
    '1318_858': 29.8798,
    '1318_859': 29.6243,
    '1318_860': 29.3554,
    '1318_861': 29.0746,
    '1318_862': 28.7773,
    '1318_863': 28.4661,
    '1318_864': 28.139,
    '1318_865': 27.7957,
    '1318_866': 27.4383,
    '1318_867': 27.0684,
    '1318_868': 26.6896,
    '1318_869': 26.3056,
    '1318_870': 25.9211,
    '1318_871': 25.541,
    '1318_872': 25.1701,
    '1318_873': 24.8114,
    '1318_874': 24.4681,
    '1318_875': 24.1429,
    '1318_876': 23.8389,
    '1318_877': 23.5466,
    '1318_878': 23.2179,
    '1318_879': 22.8935,
    '1318_880': 22.5865,
    '1318_881': 22.294,
    '1318_882': 22.0146,
    '1318_883': 21.751,
    '1318_884': 21.5048,
    '1318_885': 21.2763,
    '1318_886': 21.0664,
    '1318_887': 20.874,
    '1318_888': 20.6975,
    '1318_889': 20.5354,
    '1318_890': 20.3876,
    '1318_891': 20.256,
    '1318_892': 20.1418,
    '1318_893': 20.0442,
    '1318_894': 19.9627,
    '1318_895': 19.8945,
    '1318_896': 19.8402,
    '1318_897': 19.8003,
    '1318_898': 19.7696,
    '1318_899': 19.7533,
    '1318_900': 19.7547,
    '1318_901': 19.7738,
    '1318_902': 19.8126,
    '1318_903': 19.8662,
    '1318_904': 19.9294,
    '1318_905': 19.9991,
    '1318_906': 20.0655,
    '1318_907': 20.1414,
    '1318_908': 20.209,
    '1318_909': 20.2705,
    '1318_910': 20.3396,
    '1318_911': 20.3991,
    '1318_912': 20.4681,
    '1318_913': 20.5249,
    '1318_914': 20.5687,
    '1318_915': 20.6159,
    '1318_916': 20.6471,
    '1318_917': 20.6834,
    '1318_918': 20.7015,
    '1318_919': 20.7119,
    '1318_920': 20.7216,
    '1318_921': 20.7272,
    '1318_922': 20.726,
    '1318_923': 20.7164,
    '1318_924': 20.6972,
    '1318_925': 20.6697,
    '1318_926': 20.6332,
    '1318_927': 20.5942,
    '1318_928': 20.5445,
    '1318_929': 20.4756,
    '1318_930': 20.3872,
    '1318_931': 20.298,
    '1318_932': 20.1967,
    '1318_933': 20.0843,
    '1318_934': 19.9613,
    '1318_935': 19.8277,
    '1318_936': 19.6859,
    '1318_937': 19.5358,
    '1318_938': 19.3768,
    '1318_939': 19.2131,
    '1318_940': 19.0426,
    '1319_790': 33.198,
    '1319_791': 33.2181,
    '1319_792': 33.2526,
    '1319_793': 33.3041,
    '1319_794': 33.3663,
    '1319_795': 33.4406,
    '1319_796': 33.5274,
    '1319_797': 33.6283,
    '1319_798': 33.7417,
    '1319_799': 33.8653,
    '1319_800': 33.9958,
    '1319_801': 34.1282,
    '1319_802': 34.2542,
    '1319_803': 34.362,
    '1319_804': 34.4494,
    '1319_805': 34.5325,
    '1319_806': 34.6036,
    '1319_807': 34.664,
    '1319_808': 34.7259,
    '1319_809': 34.7842,
    '1319_810': 34.8299,
    '1319_811': 34.8509,
    '1319_812': 34.8469,
    '1319_813': 34.8451,
    '1319_814': 34.8578,
    '1319_815': 34.8872,
    '1319_816': 34.9156,
    '1319_817': 34.9289,
    '1319_818': 34.9219,
    '1319_819': 34.909,
    '1319_820': 34.8739,
    '1319_821': 34.8137,
    '1319_822': 34.7339,
    '1319_823': 34.639,
    '1319_824': 34.5444,
    '1319_825': 34.4521,
    '1319_826': 34.3784,
    '1319_827': 34.3294,
    '1319_828': 34.3029,
    '1319_829': 34.289,
    '1319_830': 34.2774,
    '1319_831': 34.2467,
    '1319_832': 34.1863,
    '1319_833': 34.0968,
    '1319_834': 33.9854,
    '1319_835': 33.8562,
    '1319_836': 33.7211,
    '1319_837': 33.592,
    '1319_838': 33.4686,
    '1319_839': 33.3446,
    '1319_840': 33.2128,
    '1319_841': 33.0697,
    '1319_842': 32.9134,
    '1319_843': 32.7449,
    '1319_844': 32.5689,
    '1319_845': 32.3887,
    '1319_846': 32.2054,
    '1319_847': 32.0236,
    '1319_848': 31.8434,
    '1319_849': 31.6643,
    '1319_850': 31.4823,
    '1319_851': 31.2961,
    '1319_852': 31.1058,
    '1319_853': 30.9112,
    '1319_854': 30.7145,
    '1319_855': 30.5118,
    '1319_856': 30.3008,
    '1319_857': 30.0784,
    '1319_858': 29.84,
    '1319_859': 29.5915,
    '1319_860': 29.3276,
    '1319_861': 29.0477,
    '1319_862': 28.755,
    '1319_863': 28.4492,
    '1319_864': 28.1268,
    '1319_865': 27.7854,
    '1319_866': 27.4283,
    '1319_867': 27.0578,
    '1319_868': 26.6775,
    '1319_869': 26.2945,
    '1319_870': 25.9125,
    '1319_871': 25.5334,
    '1319_872': 25.1659,
    '1319_873': 24.8115,
    '1319_874': 24.4725,
    '1319_875': 24.1473,
    '1319_876': 23.8402,
    '1319_877': 23.5369,
    '1319_878': 23.214,
    '1319_879': 22.9022,
    '1319_880': 22.6023,
    '1319_881': 22.3125,
    '1319_882': 22.034,
    '1319_883': 21.7708,
    '1319_884': 21.525,
    '1319_885': 21.298,
    '1319_886': 21.0914,
    '1319_887': 20.902,
    '1319_888': 20.7304,
    '1319_889': 20.5752,
    '1319_890': 20.4341,
    '1319_891': 20.3099,
    '1319_892': 20.2032,
    '1319_893': 20.1112,
    '1319_894': 20.0355,
    '1319_895': 19.9758,
    '1319_896': 19.9294,
    '1319_897': 19.8993,
    '1319_898': 19.8792,
    '1319_899': 19.8739,
    '1319_900': 19.8858,
    '1319_901': 19.9124,
    '1319_902': 19.9613,
    '1319_903': 20.0219,
    '1319_904': 20.0885,
    '1319_905': 20.1568,
    '1319_906': 20.2239,
    '1319_907': 20.306,
    '1319_908': 20.3801,
    '1319_909': 20.4443,
    '1319_910': 20.5161,
    '1319_911': 20.574,
    '1319_912': 20.6434,
    '1319_913': 20.6967,
    '1319_914': 20.7343,
    '1319_915': 20.7762,
    '1319_916': 20.8112,
    '1319_917': 20.848,
    '1319_918': 20.877,
    '1319_919': 20.8959,
    '1319_920': 20.9122,
    '1319_921': 20.921,
    '1319_922': 20.9227,
    '1319_923': 20.9155,
    '1319_924': 20.8983,
    '1319_925': 20.8716,
    '1319_926': 20.8356,
    '1319_927': 20.7989,
    '1319_928': 20.7454,
    '1319_929': 20.6659,
    '1319_930': 20.5705,
    '1319_931': 20.473,
    '1319_932': 20.3636,
    '1319_933': 20.2433,
    '1319_934': 20.1127,
    '1319_935': 19.9725,
    '1319_936': 19.8238,
    '1319_937': 19.667,
    '1319_938': 19.5025,
    '1319_939': 19.3343,
    '1319_940': 19.1588,
    '1320_790': 33.2188,
    '1320_791': 33.2427,
    '1320_792': 33.2806,
    '1320_793': 33.3367,
    '1320_794': 33.4026,
    '1320_795': 33.4793,
    '1320_796': 33.5682,
    '1320_797': 33.6696,
    '1320_798': 33.7809,
    '1320_799': 33.8991,
    '1320_800': 34.0235,
    '1320_801': 34.1488,
    '1320_802': 34.2664,
    '1320_803': 34.3653,
    '1320_804': 34.4605,
    '1320_805': 34.5468,
    '1320_806': 34.6195,
    '1320_807': 34.6846,
    '1320_808': 34.7451,
    '1320_809': 34.8047,
    '1320_810': 34.8532,
    '1320_811': 34.8776,
    '1320_812': 34.8672,
    '1320_813': 34.854,
    '1320_814': 34.8614,
    '1320_815': 34.8839,
    '1320_816': 34.9069,
    '1320_817': 34.9134,
    '1320_818': 34.8982,
    '1320_819': 34.8701,
    '1320_820': 34.8287,
    '1320_821': 34.7653,
    '1320_822': 34.6796,
    '1320_823': 34.5747,
    '1320_824': 34.4681,
    '1320_825': 34.3733,
    '1320_826': 34.3058,
    '1320_827': 34.2602,
    '1320_828': 34.2274,
    '1320_829': 34.2011,
    '1320_830': 34.1723,
    '1320_831': 34.1321,
    '1320_832': 34.0723,
    '1320_833': 33.9841,
    '1320_834': 33.8743,
    '1320_835': 33.7509,
    '1320_836': 33.6307,
    '1320_837': 33.5215,
    '1320_838': 33.4117,
    '1320_839': 33.2987,
    '1320_840': 33.1759,
    '1320_841': 33.0392,
    '1320_842': 32.8865,
    '1320_843': 32.717,
    '1320_844': 32.5418,
    '1320_845': 32.363,
    '1320_846': 32.1802,
    '1320_847': 32.003,
    '1320_848': 31.8284,
    '1320_849': 31.6537,
    '1320_850': 31.4739,
    '1320_851': 31.2894,
    '1320_852': 31.099,
    '1320_853': 30.9026,
    '1320_854': 30.7017,
    '1320_855': 30.4943,
    '1320_856': 30.2793,
    '1320_857': 30.0534,
    '1320_858': 29.8134,
    '1320_859': 29.5663,
    '1320_860': 29.3073,
    '1320_861': 29.0332,
    '1320_862': 28.7451,
    '1320_863': 28.4417,
    '1320_864': 28.1213,
    '1320_865': 27.7841,
    '1320_866': 27.4295,
    '1320_867': 27.0611,
    '1320_868': 26.6831,
    '1320_869': 26.3018,
    '1320_870': 25.9208,
    '1320_871': 25.5436,
    '1320_872': 25.1772,
    '1320_873': 24.828,
    '1320_874': 24.4956,
    '1320_875': 24.1811,
    '1320_876': 23.8842,
    '1320_877': 23.5624,
    '1320_878': 23.2413,
    '1320_879': 22.932,
    '1320_880': 22.6339,
    '1320_881': 22.3454,
    '1320_882': 22.0671,
    '1320_883': 21.8024,
    '1320_884': 21.556,
    '1320_885': 21.333,
    '1320_886': 21.1302,
    '1320_887': 20.9452,
    '1320_888': 20.7795,
    '1320_889': 20.631,
    '1320_890': 20.498,
    '1320_891': 20.3811,
    '1320_892': 20.281,
    '1320_893': 20.1964,
    '1320_894': 20.1288,
    '1320_895': 20.0777,
    '1320_896': 20.0419,
    '1320_897': 20.023,
    '1320_898': 20.0156,
    '1320_899': 20.0214,
    '1320_900': 20.0416,
    '1320_901': 20.0761,
    '1320_902': 20.1292,
    '1320_903': 20.1939,
    '1320_904': 20.2643,
    '1320_905': 20.3382,
    '1320_906': 20.4148,
    '1320_907': 20.4974,
    '1320_908': 20.5788,
    '1320_909': 20.6524,
    '1320_910': 20.7284,
    '1320_911': 20.7905,
    '1320_912': 20.8435,
    '1320_913': 20.8882,
    '1320_914': 20.9247,
    '1320_915': 20.9602,
    '1320_916': 21,
    '1320_917': 21.0399,
    '1320_918': 21.0737,
    '1320_919': 21.0998,
    '1320_920': 21.1189,
    '1320_921': 21.1295,
    '1320_922': 21.1315,
    '1320_923': 21.1246,
    '1320_924': 21.1076,
    '1320_925': 21.0777,
    '1320_926': 21.0459,
    '1320_927': 21.0065,
    '1320_928': 20.949,
    '1320_929': 20.8675,
    '1320_930': 20.7674,
    '1320_931': 20.6547,
    '1320_932': 20.5368,
    '1320_933': 20.407,
    '1320_934': 20.2675,
    '1320_935': 20.1194,
    '1320_936': 19.9632,
    '1320_937': 19.7998,
    '1320_938': 19.6296,
    '1320_939': 19.4538,
    '1320_940': 19.2706,
    '1321_774': 32.4477,
    '1321_775': 32.6407,
    '1321_776': 32.8193,
    '1321_777': 32.969,
    '1321_778': 33.0687,
    '1321_779': 33.1199,
    '1321_780': 33.1415,
    '1321_781': 33.1471,
    '1321_782': 33.1449,
    '1321_783': 33.1343,
    '1321_784': 33.1033,
    '1321_785': 33.0717,
    '1321_790': 33.2395,
    '1321_791': 33.2691,
    '1321_792': 33.3137,
    '1321_793': 33.3723,
    '1321_794': 33.4409,
    '1321_795': 33.5219,
    '1321_796': 33.6145,
    '1321_797': 33.7178,
    '1321_798': 33.8269,
    '1321_799': 33.9391,
    '1321_800': 34.0572,
    '1321_801': 34.1764,
    '1321_802': 34.2867,
    '1321_803': 34.3837,
    '1321_804': 34.4835,
    '1321_805': 34.5755,
    '1321_806': 34.6528,
    '1321_807': 34.719,
    '1321_808': 34.7769,
    '1321_809': 34.8356,
    '1321_810': 34.8815,
    '1321_811': 34.9043,
    '1321_812': 34.886,
    '1321_813': 34.8631,
    '1321_814': 34.8587,
    '1321_815': 34.8671,
    '1321_816': 34.8828,
    '1321_817': 34.8824,
    '1321_818': 34.8574,
    '1321_819': 34.8154,
    '1321_820': 34.7671,
    '1321_821': 34.7,
    '1321_822': 34.6065,
    '1321_823': 34.4971,
    '1321_824': 34.3873,
    '1321_825': 34.2955,
    '1321_826': 34.2375,
    '1321_827': 34.1992,
    '1321_828': 34.1607,
    '1321_829': 34.1173,
    '1321_830': 34.0731,
    '1321_831': 34.0188,
    '1321_832': 33.9538,
    '1321_833': 33.8701,
    '1321_834': 33.768,
    '1321_835': 33.6618,
    '1321_836': 33.5599,
    '1321_837': 33.4606,
    '1321_838': 33.3626,
    '1321_839': 33.2593,
    '1321_840': 33.1448,
    '1321_841': 33.0138,
    '1321_842': 32.8645,
    '1321_843': 32.6901,
    '1321_844': 32.5137,
    '1321_845': 32.3327,
    '1321_846': 32.15,
    '1321_847': 31.978,
    '1321_848': 31.8093,
    '1321_849': 31.6396,
    '1321_850': 31.461,
    '1321_851': 31.2783,
    '1321_852': 31.0885,
    '1321_853': 30.8911,
    '1321_854': 30.6886,
    '1321_855': 30.4779,
    '1321_856': 30.2616,
    '1321_857': 30.0335,
    '1321_858': 29.7945,
    '1321_859': 29.5504,
    '1321_860': 29.2977,
    '1321_861': 29.0285,
    '1321_862': 28.7458,
    '1321_863': 28.4455,
    '1321_864': 28.1272,
    '1321_865': 27.7942,
    '1321_866': 27.4424,
    '1321_867': 27.0768,
    '1321_868': 26.7012,
    '1321_869': 26.3223,
    '1321_870': 25.9433,
    '1321_871': 25.5674,
    '1321_872': 25.2009,
    '1321_873': 24.8569,
    '1321_874': 24.5282,
    '1321_875': 24.2164,
    '1321_876': 23.9303,
    '1321_877': 23.5982,
    '1321_878': 23.2819,
    '1321_879': 22.9763,
    '1321_880': 22.6802,
    '1321_881': 22.3937,
    '1321_882': 22.1166,
    '1321_883': 21.8505,
    '1321_884': 21.6031,
    '1321_885': 21.3848,
    '1321_886': 21.1857,
    '1321_887': 21.0039,
    '1321_888': 20.844,
    '1321_889': 20.7023,
    '1321_890': 20.577,
    '1321_891': 20.4676,
    '1321_892': 20.3754,
    '1321_893': 20.2988,
    '1321_894': 20.2403,
    '1321_895': 20.1986,
    '1321_896': 20.1726,
    '1321_897': 20.1667,
    '1321_898': 20.1706,
    '1321_899': 20.1865,
    '1321_900': 20.2147,
    '1321_901': 20.2547,
    '1321_902': 20.3139,
    '1321_903': 20.3835,
    '1321_904': 20.4587,
    '1321_905': 20.5401,
    '1321_906': 20.6259,
    '1321_907': 20.7153,
    '1321_908': 20.8023,
    '1321_909': 20.8809,
    '1321_910': 20.9488,
    '1321_911': 21.0062,
    '1321_912': 21.0544,
    '1321_913': 21.0964,
    '1321_914': 21.1343,
    '1321_915': 21.1725,
    '1321_916': 21.2141,
    '1321_917': 21.2547,
    '1321_918': 21.2896,
    '1321_919': 21.3192,
    '1321_920': 21.3398,
    '1321_921': 21.351,
    '1321_922': 21.3523,
    '1321_923': 21.3442,
    '1321_924': 21.3277,
    '1321_925': 21.2997,
    '1321_926': 21.2717,
    '1321_927': 21.2344,
    '1321_928': 21.1748,
    '1321_929': 21.092,
    '1321_930': 20.9833,
    '1321_931': 20.8528,
    '1321_932': 20.7161,
    '1321_933': 20.576,
    '1321_934': 20.4256,
    '1321_935': 20.2679,
    '1321_936': 20.1032,
    '1321_937': 19.9323,
    '1321_938': 19.7545,
    '1321_939': 19.57,
    '1321_940': 19.3786,
    '1322_774': 32.4784,
    '1322_775': 32.671,
    '1322_776': 32.8463,
    '1322_777': 32.9925,
    '1322_778': 33.099,
    '1322_779': 33.1603,
    '1322_780': 33.1865,
    '1322_781': 33.1896,
    '1322_782': 33.1776,
    '1322_783': 33.1545,
    '1322_784': 33.1188,
    '1322_785': 33.0822,
    '1322_790': 33.2598,
    '1322_791': 33.2924,
    '1322_792': 33.3414,
    '1322_793': 33.4035,
    '1322_794': 33.483,
    '1322_795': 33.5777,
    '1322_796': 33.6824,
    '1322_797': 33.7846,
    '1322_798': 33.8912,
    '1322_799': 33.9966,
    '1322_800': 34.1058,
    '1322_801': 34.2117,
    '1322_802': 34.3074,
    '1322_803': 34.4089,
    '1322_804': 34.5135,
    '1322_805': 34.6125,
    '1322_806': 34.6912,
    '1322_807': 34.7592,
    '1322_808': 34.8139,
    '1322_809': 34.861,
    '1322_810': 34.9005,
    '1322_811': 34.9196,
    '1322_812': 34.8982,
    '1322_813': 34.8707,
    '1322_814': 34.8495,
    '1322_815': 34.8382,
    '1322_816': 34.8425,
    '1322_817': 34.8321,
    '1322_818': 34.7985,
    '1322_819': 34.7433,
    '1322_820': 34.6854,
    '1322_821': 34.6164,
    '1322_822': 34.5205,
    '1322_823': 34.406,
    '1322_824': 34.2992,
    '1322_825': 34.2183,
    '1322_826': 34.1745,
    '1322_827': 34.1482,
    '1322_828': 34.1067,
    '1322_829': 34.0444,
    '1322_830': 33.9795,
    '1322_831': 33.9104,
    '1322_832': 33.8379,
    '1322_833': 33.7612,
    '1322_834': 33.6736,
    '1322_835': 33.5834,
    '1322_836': 33.493,
    '1322_837': 33.4048,
    '1322_838': 33.3152,
    '1322_839': 33.2204,
    '1322_840': 33.1131,
    '1322_841': 32.9848,
    '1322_842': 32.8371,
    '1322_843': 32.6676,
    '1322_844': 32.4915,
    '1322_845': 32.3118,
    '1322_846': 32.1303,
    '1322_847': 31.9546,
    '1322_848': 31.7844,
    '1322_849': 31.6137,
    '1322_850': 31.4374,
    '1322_851': 31.2561,
    '1322_852': 31.0683,
    '1322_853': 30.8737,
    '1322_854': 30.6739,
    '1322_855': 30.4675,
    '1322_856': 30.25,
    '1322_857': 30.0202,
    '1322_858': 29.7835,
    '1322_859': 29.5435,
    '1322_860': 29.2966,
    '1322_861': 29.032,
    '1322_862': 28.7548,
    '1322_863': 28.4593,
    '1322_864': 28.1467,
    '1322_865': 27.8125,
    '1322_866': 27.4629,
    '1322_867': 27.1023,
    '1322_868': 26.7297,
    '1322_869': 26.3533,
    '1322_870': 25.9782,
    '1322_871': 25.6076,
    '1322_872': 25.2475,
    '1322_873': 24.9041,
    '1322_874': 24.5779,
    '1322_875': 24.2711,
    '1322_876': 23.9801,
    '1322_877': 23.6526,
    '1322_878': 23.3378,
    '1322_879': 23.0336,
    '1322_880': 22.7397,
    '1322_881': 22.4573,
    '1322_882': 22.1867,
    '1322_883': 21.9275,
    '1322_884': 21.6834,
    '1322_885': 21.4624,
    '1322_886': 21.2628,
    '1322_887': 21.0834,
    '1322_888': 20.926,
    '1322_889': 20.7892,
    '1322_890': 20.671,
    '1322_891': 20.5706,
    '1322_892': 20.4884,
    '1322_893': 20.4224,
    '1322_894': 20.3735,
    '1322_895': 20.3409,
    '1322_896': 20.3251,
    '1322_897': 20.3262,
    '1322_898': 20.3398,
    '1322_899': 20.3644,
    '1322_900': 20.401,
    '1322_901': 20.4508,
    '1322_902': 20.5157,
    '1322_903': 20.5911,
    '1322_904': 20.6728,
    '1322_905': 20.7585,
    '1322_906': 20.8488,
    '1322_907': 20.9445,
    '1322_908': 21.0328,
    '1322_909': 21.1077,
    '1322_910': 21.1714,
    '1322_911': 21.2258,
    '1322_912': 21.2729,
    '1322_913': 21.3148,
    '1322_914': 21.3546,
    '1322_915': 21.3968,
    '1322_916': 21.4421,
    '1322_917': 21.4852,
    '1322_918': 21.5211,
    '1322_919': 21.551,
    '1322_920': 21.573,
    '1322_921': 21.585,
    '1322_922': 21.5855,
    '1322_923': 21.5729,
    '1322_924': 21.5594,
    '1322_925': 21.5422,
    '1322_926': 21.5229,
    '1322_927': 21.4898,
    '1322_928': 21.435,
    '1322_929': 21.3487,
    '1322_930': 21.2284,
    '1322_931': 21.0763,
    '1322_932': 20.9109,
    '1322_933': 20.7511,
    '1322_934': 20.5867,
    '1322_935': 20.4176,
    '1322_936': 20.2432,
    '1322_937': 20.0631,
    '1322_938': 19.8754,
    '1322_939': 19.6805,
    '1322_940': 19.4798,
    '1323_774': 32.4995,
    '1323_775': 32.6941,
    '1323_776': 32.8692,
    '1323_777': 33.0094,
    '1323_778': 33.1255,
    '1323_779': 33.2009,
    '1323_780': 33.2298,
    '1323_781': 33.2315,
    '1323_782': 33.2117,
    '1323_783': 33.1768,
    '1323_784': 33.1328,
    '1323_785': 33.0945,
    '1323_790': 33.283,
    '1323_791': 33.3175,
    '1323_792': 33.3699,
    '1323_793': 33.4343,
    '1323_794': 33.5224,
    '1323_795': 33.6256,
    '1323_796': 33.7346,
    '1323_797': 33.8417,
    '1323_798': 33.947,
    '1323_799': 34.0481,
    '1323_800': 34.1486,
    '1323_801': 34.2468,
    '1323_802': 34.3374,
    '1323_803': 34.4402,
    '1323_804': 34.5486,
    '1323_805': 34.651,
    '1323_806': 34.7299,
    '1323_807': 34.7926,
    '1323_808': 34.8407,
    '1323_809': 34.8777,
    '1323_810': 34.9055,
    '1323_811': 34.9112,
    '1323_812': 34.8884,
    '1323_813': 34.8506,
    '1323_814': 34.8126,
    '1323_815': 34.7893,
    '1323_816': 34.7791,
    '1323_817': 34.7645,
    '1323_818': 34.7276,
    '1323_819': 34.6637,
    '1323_820': 34.5843,
    '1323_821': 34.5096,
    '1323_822': 34.4143,
    '1323_823': 34.3006,
    '1323_824': 34.2026,
    '1323_825': 34.1353,
    '1323_826': 34.1087,
    '1323_827': 34.0962,
    '1323_828': 34.0518,
    '1323_829': 33.9738,
    '1323_830': 33.8877,
    '1323_831': 33.8069,
    '1323_832': 33.7317,
    '1323_833': 33.6605,
    '1323_834': 33.586,
    '1323_835': 33.5066,
    '1323_836': 33.4275,
    '1323_837': 33.347,
    '1323_838': 33.2633,
    '1323_839': 33.1733,
    '1323_840': 33.0673,
    '1323_841': 32.9452,
    '1323_842': 32.8039,
    '1323_843': 32.6387,
    '1323_844': 32.4665,
    '1323_845': 32.2909,
    '1323_846': 32.1093,
    '1323_847': 31.9299,
    '1323_848': 31.7582,
    '1323_849': 31.5861,
    '1323_850': 31.4104,
    '1323_851': 31.2303,
    '1323_852': 31.0444,
    '1323_853': 30.8523,
    '1323_854': 30.6545,
    '1323_855': 30.4488,
    '1323_856': 30.2316,
    '1323_857': 30.0046,
    '1323_858': 29.7705,
    '1323_859': 29.5305,
    '1323_860': 29.2836,
    '1323_861': 29.0261,
    '1323_862': 28.7576,
    '1323_863': 28.4668,
    '1323_864': 28.163,
    '1323_865': 27.827,
    '1323_866': 27.481,
    '1323_867': 27.1288,
    '1323_868': 26.7594,
    '1323_869': 26.3869,
    '1323_870': 26.0176,
    '1323_871': 25.6529,
    '1323_872': 25.2996,
    '1323_873': 24.9578,
    '1323_874': 24.6314,
    '1323_875': 24.3204,
    '1323_876': 24.0243,
    '1323_877': 23.7,
    '1323_878': 23.3975,
    '1323_879': 23.0976,
    '1323_880': 22.8072,
    '1323_881': 22.5299,
    '1323_882': 22.2676,
    '1323_883': 22.017,
    '1323_884': 21.7755,
    '1323_885': 21.5543,
    '1323_886': 21.355,
    '1323_887': 21.1769,
    '1323_888': 21.0221,
    '1323_889': 20.8904,
    '1323_890': 20.7794,
    '1323_891': 20.6878,
    '1323_892': 20.6165,
    '1323_893': 20.5613,
    '1323_894': 20.5216,
    '1323_895': 20.4976,
    '1323_896': 20.4899,
    '1323_897': 20.4978,
    '1323_898': 20.5197,
    '1323_899': 20.5518,
    '1323_900': 20.597,
    '1323_901': 20.6562,
    '1323_902': 20.7288,
    '1323_903': 20.8118,
    '1323_904': 20.9001,
    '1323_905': 20.9892,
    '1323_906': 21.0792,
    '1323_907': 21.1772,
    '1323_908': 21.2589,
    '1323_909': 21.3286,
    '1323_910': 21.3944,
    '1323_911': 21.4498,
    '1323_912': 21.4984,
    '1323_913': 21.5412,
    '1323_914': 21.5821,
    '1323_915': 21.6283,
    '1323_916': 21.6777,
    '1323_917': 21.7245,
    '1323_918': 21.761,
    '1323_919': 21.7922,
    '1323_920': 21.8169,
    '1323_921': 21.8325,
    '1323_922': 21.8313,
    '1323_923': 21.826,
    '1323_924': 21.8198,
    '1323_925': 21.812,
    '1323_926': 21.8013,
    '1323_927': 21.779,
    '1323_928': 21.7266,
    '1323_929': 21.6295,
    '1323_930': 21.4924,
    '1323_931': 21.3166,
    '1323_932': 21.1203,
    '1323_933': 20.9277,
    '1323_934': 20.7486,
    '1323_935': 20.5662,
    '1323_936': 20.3802,
    '1323_937': 20.1895,
    '1323_938': 19.9895,
    '1323_939': 19.7809,
    '1323_940': 19.5671,
    '1324_774': 32.5158,
    '1324_775': 32.7124,
    '1324_776': 32.8805,
    '1324_777': 33.0349,
    '1324_778': 33.1559,
    '1324_779': 33.2278,
    '1324_780': 33.2613,
    '1324_781': 33.2729,
    '1324_782': 33.2485,
    '1324_783': 33.208,
    '1324_784': 33.1621,
    '1324_785': 33.1209,
    '1324_790': 33.3103,
    '1324_791': 33.3477,
    '1324_792': 33.3986,
    '1324_793': 33.4641,
    '1324_794': 33.5628,
    '1324_795': 33.6731,
    '1324_796': 33.7849,
    '1324_797': 33.8941,
    '1324_798': 33.9988,
    '1324_799': 34.0946,
    '1324_800': 34.1876,
    '1324_801': 34.2794,
    '1324_802': 34.3683,
    '1324_803': 34.472,
    '1324_804': 34.5787,
    '1324_805': 34.6748,
    '1324_806': 34.7507,
    '1324_807': 34.8107,
    '1324_808': 34.8554,
    '1324_809': 34.8836,
    '1324_810': 34.8935,
    '1324_811': 34.8804,
    '1324_812': 34.8505,
    '1324_813': 34.8013,
    '1324_814': 34.7507,
    '1324_815': 34.7203,
    '1324_816': 34.6986,
    '1324_817': 34.6774,
    '1324_818': 34.6346,
    '1324_819': 34.5664,
    '1324_820': 34.4749,
    '1324_821': 34.3848,
    '1324_822': 34.2897,
    '1324_823': 34.1819,
    '1324_824': 34.0937,
    '1324_825': 34.0404,
    '1324_826': 34.0269,
    '1324_827': 34.0188,
    '1324_828': 33.9718,
    '1324_829': 33.8878,
    '1324_830': 33.7952,
    '1324_831': 33.7121,
    '1324_832': 33.6355,
    '1324_833': 33.5703,
    '1324_834': 33.5038,
    '1324_835': 33.4345,
    '1324_836': 33.3627,
    '1324_837': 33.2866,
    '1324_838': 33.2046,
    '1324_839': 33.1139,
    '1324_840': 33.0088,
    '1324_841': 32.8876,
    '1324_842': 32.7494,
    '1324_843': 32.5957,
    '1324_844': 32.433,
    '1324_845': 32.2636,
    '1324_846': 32.0875,
    '1324_847': 31.9099,
    '1324_848': 31.7363,
    '1324_849': 31.5621,
    '1324_850': 31.386,
    '1324_851': 31.2067,
    '1324_852': 31.0217,
    '1324_853': 30.8314,
    '1324_854': 30.6357,
    '1324_855': 30.4312,
    '1324_856': 30.2141,
    '1324_857': 29.9898,
    '1324_858': 29.7561,
    '1324_859': 29.5148,
    '1324_860': 29.2648,
    '1324_861': 29.0061,
    '1324_862': 28.7347,
    '1324_863': 28.4471,
    '1324_864': 28.1459,
    '1324_865': 27.8213,
    '1324_866': 27.4842,
    '1324_867': 27.1396,
    '1324_868': 26.7818,
    '1324_869': 26.4171,
    '1324_870': 26.0531,
    '1324_871': 25.6966,
    '1324_872': 25.3509,
    '1324_873': 25.0167,
    '1324_874': 24.6991,
    '1324_875': 24.3927,
    '1324_876': 24.0824,
    '1324_877': 23.7664,
    '1324_878': 23.4626,
    '1324_879': 23.1661,
    '1324_880': 22.8793,
    '1324_881': 22.6052,
    '1324_882': 22.3471,
    '1324_883': 22.1029,
    '1324_884': 21.8701,
    '1324_885': 21.655,
    '1324_886': 21.4599,
    '1324_887': 21.2844,
    '1324_888': 21.1329,
    '1324_889': 21.0073,
    '1324_890': 20.904,
    '1324_891': 20.8203,
    '1324_892': 20.7603,
    '1324_893': 20.7139,
    '1324_894': 20.682,
    '1324_895': 20.6652,
    '1324_896': 20.6638,
    '1324_897': 20.6769,
    '1324_898': 20.704,
    '1324_899': 20.7462,
    '1324_900': 20.8012,
    '1324_901': 20.869,
    '1324_902': 20.95,
    '1324_903': 21.042,
    '1324_904': 21.1376,
    '1324_905': 21.2333,
    '1324_906': 21.3279,
    '1324_907': 21.4052,
    '1324_908': 21.481,
    '1324_909': 21.5522,
    '1324_910': 21.6182,
    '1324_911': 21.6779,
    '1324_912': 21.7301,
    '1324_913': 21.7769,
    '1324_914': 21.8216,
    '1324_915': 21.8683,
    '1324_916': 21.9167,
    '1324_917': 21.9625,
    '1324_918': 22.0023,
    '1324_919': 22.0381,
    '1324_920': 22.0665,
    '1324_921': 22.0928,
    '1324_922': 22.1063,
    '1324_923': 22.1105,
    '1324_924': 22.1093,
    '1324_925': 22.1056,
    '1324_926': 22.0988,
    '1324_927': 22.0729,
    '1324_928': 22.0081,
    '1324_929': 21.8978,
    '1324_930': 21.7428,
    '1324_931': 21.5448,
    '1324_932': 21.3252,
    '1324_933': 21.1061,
    '1324_934': 20.9096,
    '1324_935': 20.7122,
    '1324_936': 20.5119,
    '1324_937': 20.3065,
    '1324_938': 20.0937,
    '1324_939': 19.8736,
    '1324_940': 19.6489,
    '1325_774': 32.5256,
    '1325_775': 32.7259,
    '1325_776': 32.8991,
    '1325_777': 33.062,
    '1325_778': 33.1882,
    '1325_779': 33.2693,
    '1325_780': 33.3067,
    '1325_781': 33.3116,
    '1325_782': 33.282,
    '1325_783': 33.2369,
    '1325_784': 33.1887,
    '1325_785': 33.1466,
    '1325_790': 33.3459,
    '1325_791': 33.3868,
    '1325_792': 33.4429,
    '1325_793': 33.5178,
    '1325_794': 33.6178,
    '1325_795': 33.7287,
    '1325_796': 33.839,
    '1325_797': 33.9455,
    '1325_798': 34.0453,
    '1325_799': 34.1365,
    '1325_800': 34.2222,
    '1325_801': 34.3034,
    '1325_802': 34.399,
    '1325_803': 34.5009,
    '1325_804': 34.5888,
    '1325_805': 34.6713,
    '1325_806': 34.7491,
    '1325_807': 34.815,
    '1325_808': 34.8585,
    '1325_809': 34.8736,
    '1325_810': 34.8635,
    '1325_811': 34.829,
    '1325_812': 34.7912,
    '1325_813': 34.735,
    '1325_814': 34.6761,
    '1325_815': 34.6354,
    '1325_816': 34.6043,
    '1325_817': 34.5747,
    '1325_818': 34.523,
    '1325_819': 34.4586,
    '1325_820': 34.3652,
    '1325_821': 34.2622,
    '1325_822': 34.1548,
    '1325_823': 34.0555,
    '1325_824': 33.9769,
    '1325_825': 33.9327,
    '1325_826': 33.9199,
    '1325_827': 33.9114,
    '1325_828': 33.8637,
    '1325_829': 33.782,
    '1325_830': 33.6962,
    '1325_831': 33.6178,
    '1325_832': 33.5503,
    '1325_833': 33.4905,
    '1325_834': 33.4313,
    '1325_835': 33.3684,
    '1325_836': 33.3013,
    '1325_837': 33.2279,
    '1325_838': 33.1469,
    '1325_839': 33.0561,
    '1325_840': 32.9516,
    '1325_841': 32.831,
    '1325_842': 32.6954,
    '1325_843': 32.5518,
    '1325_844': 32.3994,
    '1325_845': 32.2384,
    '1325_846': 32.0688,
    '1325_847': 31.8936,
    '1325_848': 31.7208,
    '1325_849': 31.5452,
    '1325_850': 31.3686,
    '1325_851': 31.1897,
    '1325_852': 31.0044,
    '1325_853': 30.8147,
    '1325_854': 30.6215,
    '1325_855': 30.4195,
    '1325_856': 30.2028,
    '1325_857': 29.9815,
    '1325_858': 29.7462,
    '1325_859': 29.503,
    '1325_860': 29.2504,
    '1325_861': 28.9909,
    '1325_862': 28.7175,
    '1325_863': 28.4324,
    '1325_864': 28.1388,
    '1325_865': 27.823,
    '1325_866': 27.4945,
    '1325_867': 27.1597,
    '1325_868': 26.8113,
    '1325_869': 26.4539,
    '1325_870': 26.0948,
    '1325_871': 25.7466,
    '1325_872': 25.409,
    '1325_873': 25.0802,
    '1325_874': 24.7693,
    '1325_875': 24.4606,
    '1325_876': 24.1492,
    '1325_877': 23.8374,
    '1325_878': 23.5351,
    '1325_879': 23.2424,
    '1325_880': 22.9597,
    '1325_881': 22.6888,
    '1325_882': 22.436,
    '1325_883': 22.1997,
    '1325_884': 21.9752,
    '1325_885': 21.7684,
    '1325_886': 21.5814,
    '1325_887': 21.413,
    '1325_888': 21.2663,
    '1325_889': 21.1441,
    '1325_890': 21.0495,
    '1325_891': 20.9756,
    '1325_892': 20.9214,
    '1325_893': 20.8786,
    '1325_894': 20.8521,
    '1325_895': 20.842,
    '1325_896': 20.847,
    '1325_897': 20.8655,
    '1325_898': 20.8976,
    '1325_899': 20.9501,
    '1325_900': 21.0144,
    '1325_901': 21.0899,
    '1325_902': 21.1791,
    '1325_903': 21.2802,
    '1325_904': 21.3786,
    '1325_905': 21.4701,
    '1325_906': 21.5543,
    '1325_907': 21.6261,
    '1325_908': 21.7071,
    '1325_909': 21.7831,
    '1325_910': 21.8503,
    '1325_911': 21.9144,
    '1325_912': 21.969,
    '1325_913': 22.0183,
    '1325_914': 22.0652,
    '1325_915': 22.1116,
    '1325_916': 22.1583,
    '1325_917': 22.2027,
    '1325_918': 22.2438,
    '1325_919': 22.2819,
    '1325_920': 22.3311,
    '1325_921': 22.3781,
    '1325_922': 22.4002,
    '1325_923': 22.4112,
    '1325_924': 22.3982,
    '1325_925': 22.3971,
    '1325_926': 22.3856,
    '1325_927': 22.346,
    '1325_928': 22.2618,
    '1325_929': 22.1314,
    '1325_930': 21.9566,
    '1325_931': 21.7446,
    '1325_932': 21.5137,
    '1325_933': 21.2802,
    '1325_934': 21.0679,
    '1325_935': 20.8559,
    '1325_936': 20.6413,
    '1325_937': 20.4221,
    '1325_938': 20.1968,
    '1325_939': 19.9656,
    '1325_940': 19.7322,
    '1326_774': 32.5241,
    '1326_775': 32.73,
    '1326_776': 32.9105,
    '1326_777': 33.0841,
    '1326_778': 33.219,
    '1326_779': 33.3035,
    '1326_780': 33.3386,
    '1326_781': 33.3339,
    '1326_782': 33.3036,
    '1326_783': 33.2548,
    '1326_784': 33.2031,
    '1326_785': 33.163,
    '1326_788': 33.3403,
    '1326_789': 33.3523,
    '1326_790': 33.3833,
    '1326_791': 33.4303,
    '1326_792': 33.4913,
    '1326_793': 33.5722,
    '1326_794': 33.6766,
    '1326_795': 33.7884,
    '1326_796': 33.896,
    '1326_797': 33.9991,
    '1326_798': 34.0941,
    '1326_799': 34.1782,
    '1326_800': 34.251,
    '1326_801': 34.3354,
    '1326_802': 34.4352,
    '1326_803': 34.5314,
    '1326_804': 34.6096,
    '1326_805': 34.6795,
    '1326_806': 34.7501,
    '1326_807': 34.8117,
    '1326_808': 34.8407,
    '1326_809': 34.8366,
    '1326_810': 34.808,
    '1326_811': 34.7607,
    '1326_812': 34.7205,
    '1326_813': 34.6615,
    '1326_814': 34.5951,
    '1326_815': 34.5425,
    '1326_816': 34.5035,
    '1326_817': 34.4608,
    '1326_818': 34.4035,
    '1326_819': 34.3396,
    '1326_820': 34.2477,
    '1326_821': 34.1357,
    '1326_822': 34.0251,
    '1326_823': 33.9303,
    '1326_824': 33.8592,
    '1326_825': 33.8147,
    '1326_826': 33.7907,
    '1326_827': 33.7712,
    '1326_828': 33.7284,
    '1326_829': 33.6629,
    '1326_830': 33.5928,
    '1326_831': 33.5279,
    '1326_832': 33.4724,
    '1326_833': 33.4198,
    '1326_834': 33.3664,
    '1326_835': 33.3083,
    '1326_836': 33.2446,
    '1326_837': 33.1734,
    '1326_838': 33.0939,
    '1326_839': 33.0053,
    '1326_840': 32.9036,
    '1326_841': 32.7857,
    '1326_842': 32.6541,
    '1326_843': 32.5161,
    '1326_844': 32.372,
    '1326_845': 32.2193,
    '1326_846': 32.057,
    '1326_847': 31.8873,
    '1326_848': 31.7148,
    '1326_849': 31.5403,
    '1326_850': 31.3627,
    '1326_851': 31.1803,
    '1326_852': 30.9957,
    '1326_853': 30.8035,
    '1326_854': 30.6082,
    '1326_855': 30.4087,
    '1326_856': 30.2063,
    '1326_857': 29.9841,
    '1326_858': 29.7439,
    '1326_859': 29.4994,
    '1326_860': 29.25,
    '1326_861': 28.9946,
    '1326_862': 28.7296,
    '1326_863': 28.4549,
    '1326_864': 28.1669,
    '1326_865': 27.862,
    '1326_866': 27.539,
    '1326_867': 27.2028,
    '1326_868': 26.8568,
    '1326_869': 26.5062,
    '1326_870': 26.1564,
    '1326_871': 25.8148,
    '1326_872': 25.4837,
    '1326_873': 25.1635,
    '1326_874': 24.8549,
    '1326_875': 24.5453,
    '1326_876': 24.2258,
    '1326_877': 23.912,
    '1326_878': 23.6132,
    '1326_879': 23.3269,
    '1326_880': 23.0522,
    '1326_881': 22.7905,
    '1326_882': 22.5449,
    '1326_883': 22.3153,
    '1326_884': 22.0999,
    '1326_885': 21.9005,
    '1326_886': 21.7186,
    '1326_887': 21.5551,
    '1326_888': 21.4141,
    '1326_889': 21.2995,
    '1326_890': 21.2083,
    '1326_891': 21.1382,
    '1326_892': 21.0846,
    '1326_893': 21.0465,
    '1326_894': 21.0279,
    '1326_895': 21.0262,
    '1326_896': 21.0392,
    '1326_897': 21.0661,
    '1326_898': 21.1082,
    '1326_899': 21.1678,
    '1326_900': 21.2395,
    '1326_901': 21.3227,
    '1326_902': 21.424,
    '1326_903': 21.5277,
    '1326_904': 21.6236,
    '1326_905': 21.7107,
    '1326_906': 21.7947,
    '1326_907': 21.8748,
    '1326_908': 21.9544,
    '1326_909': 22.03,
    '1326_910': 22.0976,
    '1326_911': 22.1606,
    '1326_912': 22.2128,
    '1326_913': 22.2617,
    '1326_914': 22.3094,
    '1326_915': 22.3555,
    '1326_916': 22.4003,
    '1326_917': 22.4431,
    '1326_918': 22.4804,
    '1326_919': 22.5301,
    '1326_920': 22.5977,
    '1326_921': 22.6632,
    '1326_922': 22.6802,
    '1326_923': 22.6878,
    '1326_924': 22.6745,
    '1326_925': 22.6615,
    '1326_926': 22.6325,
    '1326_927': 22.5779,
    '1326_928': 22.4786,
    '1326_929': 22.3276,
    '1326_930': 22.1318,
    '1326_931': 21.9118,
    '1326_932': 21.6864,
    '1326_933': 21.4492,
    '1326_934': 21.2219,
    '1326_935': 20.9967,
    '1326_936': 20.7694,
    '1326_937': 20.5382,
    '1326_938': 20.3026,
    '1326_939': 20.063,
    '1326_940': 19.8213,
    '1327_774': 32.5116,
    '1327_775': 32.7227,
    '1327_776': 32.9092,
    '1327_777': 33.0907,
    '1327_778': 33.237,
    '1327_779': 33.3206,
    '1327_780': 33.3483,
    '1327_781': 33.33,
    '1327_782': 33.3132,
    '1327_783': 33.2632,
    '1327_784': 33.2082,
    '1327_785': 33.1753,
    '1327_788': 33.3676,
    '1327_789': 33.394,
    '1327_790': 33.4327,
    '1327_791': 33.4842,
    '1327_792': 33.5459,
    '1327_793': 33.6275,
    '1327_794': 33.7374,
    '1327_795': 33.8462,
    '1327_796': 33.9539,
    '1327_797': 34.0576,
    '1327_798': 34.1507,
    '1327_799': 34.2239,
    '1327_800': 34.2993,
    '1327_801': 34.3836,
    '1327_802': 34.4876,
    '1327_803': 34.5689,
    '1327_804': 34.6292,
    '1327_805': 34.6891,
    '1327_806': 34.7471,
    '1327_807': 34.7921,
    '1327_808': 34.8046,
    '1327_809': 34.7839,
    '1327_810': 34.743,
    '1327_811': 34.6876,
    '1327_812': 34.6425,
    '1327_813': 34.5831,
    '1327_814': 34.5162,
    '1327_815': 34.4495,
    '1327_816': 34.3995,
    '1327_817': 34.3456,
    '1327_818': 34.2821,
    '1327_819': 34.2157,
    '1327_820': 34.1193,
    '1327_821': 34.0075,
    '1327_822': 33.9023,
    '1327_823': 33.8176,
    '1327_824': 33.7504,
    '1327_825': 33.7022,
    '1327_826': 33.668,
    '1327_827': 33.637,
    '1327_828': 33.599,
    '1327_829': 33.5494,
    '1327_830': 33.4982,
    '1327_831': 33.4493,
    '1327_832': 33.4027,
    '1327_833': 33.3578,
    '1327_834': 33.3104,
    '1327_835': 33.2574,
    '1327_836': 33.1967,
    '1327_837': 33.1274,
    '1327_838': 33.0498,
    '1327_839': 32.9657,
    '1327_840': 32.8695,
    '1327_841': 32.7557,
    '1327_842': 32.6267,
    '1327_843': 32.4929,
    '1327_844': 32.3554,
    '1327_845': 32.2089,
    '1327_846': 32.0528,
    '1327_847': 31.8856,
    '1327_848': 31.7122,
    '1327_849': 31.537,
    '1327_850': 31.3562,
    '1327_851': 31.1629,
    '1327_852': 30.9655,
    '1327_853': 30.7758,
    '1327_854': 30.5828,
    '1327_855': 30.3848,
    '1327_856': 30.1924,
    '1327_857': 29.9843,
    '1327_858': 29.7395,
    '1327_859': 29.4991,
    '1327_860': 29.2555,
    '1327_861': 29.0066,
    '1327_862': 28.751,
    '1327_863': 28.4879,
    '1327_864': 28.2069,
    '1327_865': 27.9132,
    '1327_866': 27.5945,
    '1327_867': 27.2574,
    '1327_868': 26.9126,
    '1327_869': 26.568,
    '1327_870': 26.2253,
    '1327_871': 25.8889,
    '1327_872': 25.5623,
    '1327_873': 25.2474,
    '1327_874': 24.9409,
    '1327_875': 24.6239,
    '1327_876': 24.3023,
    '1327_877': 23.989,
    '1327_878': 23.6978,
    '1327_879': 23.4198,
    '1327_880': 23.1541,
    '1327_881': 22.9018,
    '1327_882': 22.6646,
    '1327_883': 22.4434,
    '1327_884': 22.2366,
    '1327_885': 22.0442,
    '1327_886': 21.8665,
    '1327_887': 21.7053,
    '1327_888': 21.5665,
    '1327_889': 21.4594,
    '1327_890': 21.3713,
    '1327_891': 21.3062,
    '1327_892': 21.2541,
    '1327_893': 21.2207,
    '1327_894': 21.2117,
    '1327_895': 21.2193,
    '1327_896': 21.2407,
    '1327_897': 21.2753,
    '1327_898': 21.3262,
    '1327_899': 21.3958,
    '1327_900': 21.4778,
    '1327_901': 21.5718,
    '1327_902': 21.6752,
    '1327_903': 21.7683,
    '1327_904': 21.8577,
    '1327_905': 21.9487,
    '1327_906': 22.0388,
    '1327_907': 22.1275,
    '1327_908': 22.2059,
    '1327_909': 22.2806,
    '1327_910': 22.3433,
    '1327_911': 22.4012,
    '1327_912': 22.4536,
    '1327_913': 22.5044,
    '1327_914': 22.5543,
    '1327_915': 22.6015,
    '1327_916': 22.6411,
    '1327_917': 22.6833,
    '1327_918': 22.7269,
    '1327_919': 22.7847,
    '1327_920': 22.8585,
    '1327_921': 22.9272,
    '1327_922': 22.9447,
    '1327_923': 22.9383,
    '1327_924': 22.9264,
    '1327_925': 22.9055,
    '1327_926': 22.8609,
    '1327_927': 22.7886,
    '1327_928': 22.6716,
    '1327_929': 22.5027,
    '1327_930': 22.2966,
    '1327_931': 22.072,
    '1327_932': 21.8474,
    '1327_933': 21.6091,
    '1327_934': 21.373,
    '1327_935': 21.1365,
    '1327_936': 20.8978,
    '1327_937': 20.6557,
    '1327_938': 20.4106,
    '1327_939': 20.1627,
    '1327_940': 19.9133,
    '1328_774': 32.4883,
    '1328_775': 32.7044,
    '1328_776': 32.9003,
    '1328_777': 33.0917,
    '1328_778': 33.2392,
    '1328_779': 33.3157,
    '1328_780': 33.336,
    '1328_781': 33.3209,
    '1328_782': 33.3069,
    '1328_783': 33.2672,
    '1328_784': 33.2233,
    '1328_785': 33.1912,
    '1328_788': 33.3991,
    '1328_789': 33.4445,
    '1328_790': 33.496,
    '1328_791': 33.5535,
    '1328_792': 33.6207,
    '1328_793': 33.7036,
    '1328_794': 33.8047,
    '1328_795': 33.9093,
    '1328_796': 34.009,
    '1328_797': 34.1176,
    '1328_798': 34.2171,
    '1328_799': 34.2986,
    '1328_800': 34.372,
    '1328_801': 34.4436,
    '1328_802': 34.5258,
    '1328_803': 34.5909,
    '1328_804': 34.6394,
    '1328_805': 34.6879,
    '1328_806': 34.7297,
    '1328_807': 34.753,
    '1328_808': 34.7491,
    '1328_809': 34.7242,
    '1328_810': 34.6826,
    '1328_811': 34.6232,
    '1328_812': 34.5607,
    '1328_813': 34.5002,
    '1328_814': 34.4296,
    '1328_815': 34.3547,
    '1328_816': 34.296,
    '1328_817': 34.2366,
    '1328_818': 34.1684,
    '1328_819': 34.0873,
    '1328_820': 33.9855,
    '1328_821': 33.8838,
    '1328_822': 33.7942,
    '1328_823': 33.7191,
    '1328_824': 33.6571,
    '1328_825': 33.6075,
    '1328_826': 33.5675,
    '1328_827': 33.5311,
    '1328_828': 33.4949,
    '1328_829': 33.4562,
    '1328_830': 33.4193,
    '1328_831': 33.3819,
    '1328_832': 33.3429,
    '1328_833': 33.3041,
    '1328_834': 33.2627,
    '1328_835': 33.2168,
    '1328_836': 33.1601,
    '1328_837': 33.0926,
    '1328_838': 33.0159,
    '1328_839': 32.938,
    '1328_840': 32.8478,
    '1328_841': 32.7432,
    '1328_842': 32.6228,
    '1328_843': 32.4902,
    '1328_844': 32.3513,
    '1328_845': 32.2052,
    '1328_846': 32.0518,
    '1328_847': 31.8831,
    '1328_848': 31.7083,
    '1328_849': 31.5273,
    '1328_850': 31.3398,
    '1328_851': 31.1448,
    '1328_852': 30.9479,
    '1328_853': 30.7532,
    '1328_854': 30.5609,
    '1328_855': 30.3683,
    '1328_856': 30.171,
    '1328_857': 29.9612,
    '1328_858': 29.734,
    '1328_859': 29.4994,
    '1328_860': 29.2599,
    '1328_861': 29.015,
    '1328_862': 28.7632,
    '1328_863': 28.5023,
    '1328_864': 28.2302,
    '1328_865': 27.9429,
    '1328_866': 27.635,
    '1328_867': 27.3086,
    '1328_868': 26.972,
    '1328_869': 26.6325,
    '1328_870': 26.2944,
    '1328_871': 25.9609,
    '1328_872': 25.6354,
    '1328_873': 25.3215,
    '1328_874': 25.0188,
    '1328_875': 24.7016,
    '1328_876': 24.3835,
    '1328_877': 24.0787,
    '1328_878': 23.7921,
    '1328_879': 23.5198,
    '1328_880': 23.2612,
    '1328_881': 23.0171,
    '1328_882': 22.7888,
    '1328_883': 22.5768,
    '1328_884': 22.3796,
    '1328_885': 22.1949,
    '1328_886': 22.0224,
    '1328_887': 21.8612,
    '1328_888': 21.7188,
    '1328_889': 21.6106,
    '1328_890': 21.53,
    '1328_891': 21.4739,
    '1328_892': 21.4308,
    '1328_893': 21.4082,
    '1328_894': 21.4076,
    '1328_895': 21.423,
    '1328_896': 21.4512,
    '1328_897': 21.4905,
    '1328_898': 21.5459,
    '1328_899': 21.6252,
    '1328_900': 21.718,
    '1328_901': 21.8219,
    '1328_902': 21.9152,
    '1328_903': 22.0046,
    '1328_904': 22.0953,
    '1328_905': 22.1873,
    '1328_906': 22.2785,
    '1328_907': 22.366,
    '1328_908': 22.4453,
    '1328_909': 22.5177,
    '1328_910': 22.581,
    '1328_911': 22.6392,
    '1328_912': 22.6894,
    '1328_913': 22.7443,
    '1328_914': 22.7984,
    '1328_915': 22.8507,
    '1328_916': 22.8961,
    '1328_917': 22.9378,
    '1328_918': 22.9794,
    '1328_919': 23.0406,
    '1328_920': 23.1119,
    '1328_921': 23.1734,
    '1328_922': 23.191,
    '1328_923': 23.1696,
    '1328_924': 23.1515,
    '1328_925': 23.126,
    '1328_926': 23.0734,
    '1328_927': 22.9874,
    '1328_928': 22.8587,
    '1328_929': 22.6847,
    '1328_930': 22.4728,
    '1328_931': 22.2455,
    '1328_932': 22.012,
    '1328_933': 21.7681,
    '1328_934': 21.5227,
    '1328_935': 21.2756,
    '1328_936': 21.0261,
    '1328_937': 20.7737,
    '1328_938': 20.5188,
    '1328_939': 20.2617,
    '1328_940': 20.0034,
    '1329_774': 32.4513,
    '1329_775': 32.6659,
    '1329_776': 32.8825,
    '1329_777': 33.0876,
    '1329_778': 33.2223,
    '1329_779': 33.2863,
    '1329_780': 33.3032,
    '1329_781': 33.2953,
    '1329_782': 33.2887,
    '1329_783': 33.2608,
    '1329_784': 33.2279,
    '1329_785': 33.2016,
    '1329_788': 33.4345,
    '1329_789': 33.4903,
    '1329_790': 33.5582,
    '1329_791': 33.6236,
    '1329_792': 33.6962,
    '1329_793': 33.7802,
    '1329_794': 33.8736,
    '1329_795': 33.9659,
    '1329_796': 34.0758,
    '1329_797': 34.1925,
    '1329_798': 34.2937,
    '1329_799': 34.3866,
    '1329_800': 34.4571,
    '1329_801': 34.5084,
    '1329_802': 34.5522,
    '1329_803': 34.5988,
    '1329_804': 34.6338,
    '1329_805': 34.668,
    '1329_806': 34.6882,
    '1329_807': 34.693,
    '1329_808': 34.6736,
    '1329_809': 34.6478,
    '1329_810': 34.6109,
    '1329_811': 34.5512,
    '1329_812': 34.4799,
    '1329_813': 34.4109,
    '1329_814': 34.3319,
    '1329_815': 34.2552,
    '1329_816': 34.1866,
    '1329_817': 34.1189,
    '1329_818': 34.0412,
    '1329_819': 33.9489,
    '1329_820': 33.8553,
    '1329_821': 33.769,
    '1329_822': 33.693,
    '1329_823': 33.6272,
    '1329_824': 33.5714,
    '1329_825': 33.5244,
    '1329_826': 33.4854,
    '1329_827': 33.4504,
    '1329_828': 33.4176,
    '1329_829': 33.3863,
    '1329_830': 33.3557,
    '1329_831': 33.3246,
    '1329_832': 33.2922,
    '1329_833': 33.2585,
    '1329_834': 33.2218,
    '1329_835': 33.1857,
    '1329_836': 33.1362,
    '1329_837': 33.0742,
    '1329_838': 33.0007,
    '1329_839': 32.9248,
    '1329_840': 32.8354,
    '1329_841': 32.7368,
    '1329_842': 32.6188,
    '1329_843': 32.488,
    '1329_844': 32.348,
    '1329_845': 32.1973,
    '1329_846': 32.0379,
    '1329_847': 31.8682,
    '1329_848': 31.6913,
    '1329_849': 31.5079,
    '1329_850': 31.3187,
    '1329_851': 31.1233,
    '1329_852': 30.9262,
    '1329_853': 30.7299,
    '1329_854': 30.5366,
    '1329_855': 30.3451,
    '1329_856': 30.1506,
    '1329_857': 29.9485,
    '1329_858': 29.7279,
    '1329_859': 29.4965,
    '1329_860': 29.257,
    '1329_861': 29.0184,
    '1329_862': 28.7708,
    '1329_863': 28.5115,
    '1329_864': 28.2483,
    '1329_865': 27.968,
    '1329_866': 27.6715,
    '1329_867': 27.3553,
    '1329_868': 27.0262,
    '1329_869': 26.6931,
    '1329_870': 26.3604,
    '1329_871': 26.0302,
    '1329_872': 25.7044,
    '1329_873': 25.3886,
    '1329_874': 25.0925,
    '1329_875': 24.7785,
    '1329_876': 24.4656,
    '1329_877': 24.1712,
    '1329_878': 23.8905,
    '1329_879': 23.6221,
    '1329_880': 23.3694,
    '1329_881': 23.1315,
    '1329_882': 22.9118,
    '1329_883': 22.709,
    '1329_884': 22.5232,
    '1329_885': 22.3476,
    '1329_886': 22.1854,
    '1329_887': 22.0266,
    '1329_888': 21.8779,
    '1329_889': 21.7764,
    '1329_890': 21.7066,
    '1329_891': 21.6623,
    '1329_892': 21.6222,
    '1329_893': 21.6059,
    '1329_894': 21.6141,
    '1329_895': 21.6369,
    '1329_896': 21.6727,
    '1329_897': 21.7157,
    '1329_898': 21.7722,
    '1329_899': 21.8626,
    '1329_900': 21.9567,
    '1329_901': 22.0615,
    '1329_902': 22.1429,
    '1329_903': 22.2385,
    '1329_904': 22.3347,
    '1329_905': 22.4286,
    '1329_906': 22.5193,
    '1329_907': 22.605,
    '1329_908': 22.6833,
    '1329_909': 22.7553,
    '1329_910': 22.8156,
    '1329_911': 22.8775,
    '1329_912': 22.9403,
    '1329_913': 23.003,
    '1329_914': 23.0587,
    '1329_915': 23.1172,
    '1329_916': 23.1643,
    '1329_917': 23.199,
    '1329_918': 23.2362,
    '1329_919': 23.2964,
    '1329_920': 23.3568,
    '1329_921': 23.4059,
    '1329_922': 23.4224,
    '1329_923': 23.4019,
    '1329_924': 23.3758,
    '1329_925': 23.3449,
    '1329_926': 23.2838,
    '1329_927': 23.1918,
    '1329_928': 23.0549,
    '1329_929': 22.8761,
    '1329_930': 22.6607,
    '1329_931': 22.4337,
    '1329_932': 22.1903,
    '1329_933': 21.931,
    '1329_934': 21.6727,
    '1329_935': 21.4149,
    '1329_936': 21.1549,
    '1329_937': 20.8924,
    '1329_938': 20.6278,
    '1329_939': 20.3612,
    '1329_940': 20.0932,
    '1330_774': 32.393,
    '1330_775': 32.613,
    '1330_776': 32.8397,
    '1330_777': 33.0459,
    '1330_778': 33.1671,
    '1330_779': 33.2327,
    '1330_780': 33.2539,
    '1330_781': 33.2525,
    '1330_782': 33.2566,
    '1330_783': 33.2406,
    '1330_784': 33.2179,
    '1330_785': 33.1981,
    '1330_788': 33.4672,
    '1330_789': 33.532,
    '1330_790': 33.6071,
    '1330_791': 33.6821,
    '1330_792': 33.7595,
    '1330_793': 33.8425,
    '1330_794': 33.9329,
    '1330_795': 34.0251,
    '1330_796': 34.1423,
    '1330_797': 34.2689,
    '1330_798': 34.3718,
    '1330_799': 34.4698,
    '1330_800': 34.5391,
    '1330_801': 34.5753,
    '1330_802': 34.5834,
    '1330_803': 34.5964,
    '1330_804': 34.6115,
    '1330_805': 34.6268,
    '1330_806': 34.6316,
    '1330_807': 34.6176,
    '1330_808': 34.5846,
    '1330_809': 34.5488,
    '1330_810': 34.5133,
    '1330_811': 34.46,
    '1330_812': 34.3976,
    '1330_813': 34.3161,
    '1330_814': 34.2235,
    '1330_815': 34.1438,
    '1330_816': 34.0686,
    '1330_817': 33.9903,
    '1330_818': 33.9018,
    '1330_819': 33.8133,
    '1330_820': 33.7323,
    '1330_821': 33.6588,
    '1330_822': 33.5927,
    '1330_823': 33.5358,
    '1330_824': 33.487,
    '1330_825': 33.4457,
    '1330_826': 33.4104,
    '1330_827': 33.3796,
    '1330_828': 33.3513,
    '1330_829': 33.3256,
    '1330_830': 33.3016,
    '1330_831': 33.2753,
    '1330_832': 33.2484,
    '1330_833': 33.2205,
    '1330_834': 33.1872,
    '1330_835': 33.1669,
    '1330_836': 33.125,
    '1330_837': 33.0679,
    '1330_838': 32.9975,
    '1330_839': 32.9155,
    '1330_840': 32.8234,
    '1330_841': 32.7214,
    '1330_842': 32.6048,
    '1330_843': 32.4753,
    '1330_844': 32.335,
    '1330_845': 32.183,
    '1330_846': 32.0204,
    '1330_847': 31.8486,
    '1330_848': 31.6697,
    '1330_849': 31.4845,
    '1330_850': 31.294,
    '1330_851': 31.0988,
    '1330_852': 30.9013,
    '1330_853': 30.7036,
    '1330_854': 30.5089,
    '1330_855': 30.3163,
    '1330_856': 30.123,
    '1330_857': 29.9238,
    '1330_858': 29.71,
    '1330_859': 29.4862,
    '1330_860': 29.2541,
    '1330_861': 29.0173,
    '1330_862': 28.7738,
    '1330_863': 28.5207,
    '1330_864': 28.2629,
    '1330_865': 27.9876,
    '1330_866': 27.6988,
    '1330_867': 27.388,
    '1330_868': 27.063,
    '1330_869': 26.7416,
    '1330_870': 26.4178,
    '1330_871': 26.0968,
    '1330_872': 25.7789,
    '1330_873': 25.4706,
    '1330_874': 25.1662,
    '1330_875': 24.8548,
    '1330_876': 24.5547,
    '1330_877': 24.2662,
    '1330_878': 23.9885,
    '1330_879': 23.7187,
    '1330_880': 23.4705,
    '1330_881': 23.2359,
    '1330_882': 23.0224,
    '1330_883': 22.8266,
    '1330_884': 22.6537,
    '1330_885': 22.4861,
    '1330_886': 22.3443,
    '1330_887': 22.2,
    '1330_888': 22.0622,
    '1330_889': 21.983,
    '1330_890': 21.9344,
    '1330_891': 21.8831,
    '1330_892': 21.84,
    '1330_893': 21.8123,
    '1330_894': 21.8268,
    '1330_895': 21.8547,
    '1330_896': 21.902,
    '1330_897': 21.955,
    '1330_898': 22.0261,
    '1330_899': 22.1221,
    '1330_900': 22.2168,
    '1330_901': 22.3069,
    '1330_902': 22.3926,
    '1330_903': 22.4863,
    '1330_904': 22.582,
    '1330_905': 22.6752,
    '1330_906': 22.7639,
    '1330_907': 22.8479,
    '1330_908': 22.9212,
    '1330_909': 22.9953,
    '1330_910': 23.0657,
    '1330_911': 23.1351,
    '1330_912': 23.2062,
    '1330_913': 23.2731,
    '1330_914': 23.328,
    '1330_915': 23.3868,
    '1330_916': 23.4346,
    '1330_917': 23.4667,
    '1330_918': 23.5035,
    '1330_919': 23.5493,
    '1330_920': 23.5954,
    '1330_921': 23.63,
    '1330_922': 23.6493,
    '1330_923': 23.6395,
    '1330_924': 23.6077,
    '1330_925': 23.5635,
    '1330_926': 23.501,
    '1330_927': 23.4054,
    '1330_928': 23.2647,
    '1330_929': 23.0854,
    '1330_930': 22.8678,
    '1330_931': 22.628,
    '1330_932': 22.371,
    '1330_933': 22.0959,
    '1330_934': 21.8203,
    '1330_935': 21.5534,
    '1330_936': 21.2834,
    '1330_937': 21.0112,
    '1330_938': 20.7375,
    '1330_939': 20.4628,
    '1330_940': 20.1877,
    '1331_774': 32.3105,
    '1331_775': 32.5334,
    '1331_776': 32.7678,
    '1331_777': 32.971,
    '1331_778': 33.0994,
    '1331_779': 33.1736,
    '1331_780': 33.2054,
    '1331_781': 33.2088,
    '1331_782': 33.2177,
    '1331_783': 33.2116,
    '1331_784': 33.1983,
    '1331_785': 33.186,
    '1331_788': 33.4994,
    '1331_789': 33.5743,
    '1331_790': 33.6564,
    '1331_791': 33.7374,
    '1331_792': 33.8173,
    '1331_793': 33.8974,
    '1331_794': 33.9778,
    '1331_795': 34.0784,
    '1331_796': 34.1977,
    '1331_797': 34.3255,
    '1331_798': 34.4351,
    '1331_799': 34.5341,
    '1331_800': 34.599,
    '1331_801': 34.6221,
    '1331_802': 34.6124,
    '1331_803': 34.5951,
    '1331_804': 34.5833,
    '1331_805': 34.5696,
    '1331_806': 34.5554,
    '1331_807': 34.5306,
    '1331_808': 34.4931,
    '1331_809': 34.4476,
    '1331_810': 34.4096,
    '1331_811': 34.3675,
    '1331_812': 34.3121,
    '1331_813': 34.2269,
    '1331_814': 34.1229,
    '1331_815': 34.0283,
    '1331_816': 33.946,
    '1331_817': 33.8587,
    '1331_818': 33.7713,
    '1331_819': 33.6915,
    '1331_820': 33.6199,
    '1331_821': 33.5541,
    '1331_822': 33.4956,
    '1331_823': 33.4463,
    '1331_824': 33.4039,
    '1331_825': 33.3701,
    '1331_826': 33.3398,
    '1331_827': 33.3145,
    '1331_828': 33.2915,
    '1331_829': 33.271,
    '1331_830': 33.2537,
    '1331_831': 33.2321,
    '1331_832': 33.2116,
    '1331_833': 33.1931,
    '1331_834': 33.1736,
    '1331_835': 33.1537,
    '1331_836': 33.1152,
    '1331_837': 33.0613,
    '1331_838': 32.9924,
    '1331_839': 32.9092,
    '1331_840': 32.8158,
    '1331_841': 32.7125,
    '1331_842': 32.5962,
    '1331_843': 32.4675,
    '1331_844': 32.3277,
    '1331_845': 32.1748,
    '1331_846': 32.0097,
    '1331_847': 31.8352,
    '1331_848': 31.6539,
    '1331_849': 31.4674,
    '1331_850': 31.2764,
    '1331_851': 31.081,
    '1331_852': 30.8827,
    '1331_853': 30.6834,
    '1331_854': 30.4876,
    '1331_855': 30.2923,
    '1331_856': 30.0973,
    '1331_857': 29.8926,
    '1331_858': 29.6731,
    '1331_859': 29.4491,
    '1331_860': 29.2207,
    '1331_861': 28.9913,
    '1331_862': 28.749,
    '1331_863': 28.5008,
    '1331_864': 28.2461,
    '1331_865': 27.9773,
    '1331_866': 27.6949,
    '1331_867': 27.4035,
    '1331_868': 27.0948,
    '1331_869': 26.7798,
    '1331_870': 26.4664,
    '1331_871': 26.157,
    '1331_872': 25.8534,
    '1331_873': 25.56,
    '1331_874': 25.2445,
    '1331_875': 24.9233,
    '1331_876': 24.6348,
    '1331_877': 24.3579,
    '1331_878': 24.0864,
    '1331_879': 23.8039,
    '1331_880': 23.561,
    '1331_881': 23.3302,
    '1331_882': 23.1114,
    '1331_883': 22.9245,
    '1331_884': 22.7494,
    '1331_885': 22.5922,
    '1331_886': 22.4617,
    '1331_887': 22.3319,
    '1331_888': 22.2241,
    '1331_889': 22.1502,
    '1331_890': 22.0922,
    '1331_891': 22.0602,
    '1331_892': 22.0309,
    '1331_893': 22.0122,
    '1331_894': 22.0355,
    '1331_895': 22.0694,
    '1331_896': 22.1195,
    '1331_897': 22.1785,
    '1331_898': 22.2562,
    '1331_899': 22.3489,
    '1331_900': 22.451,
    '1331_901': 22.5496,
    '1331_902': 22.6456,
    '1331_903': 22.7429,
    '1331_904': 22.8394,
    '1331_905': 22.9314,
    '1331_906': 23.0141,
    '1331_907': 23.098,
    '1331_908': 23.1788,
    '1331_909': 23.2574,
    '1331_910': 23.3352,
    '1331_911': 23.4076,
    '1331_912': 23.4844,
    '1331_913': 23.5503,
    '1331_914': 23.6031,
    '1331_915': 23.6575,
    '1331_916': 23.7107,
    '1331_917': 23.7541,
    '1331_918': 23.7925,
    '1331_919': 23.8195,
    '1331_920': 23.8443,
    '1331_921': 23.8694,
    '1331_922': 23.8902,
    '1331_923': 23.8837,
    '1331_924': 23.8477,
    '1331_925': 23.7955,
    '1331_926': 23.7273,
    '1331_927': 23.6238,
    '1331_928': 23.4771,
    '1331_929': 23.2884,
    '1331_930': 23.0609,
    '1331_931': 22.8049,
    '1331_932': 22.5387,
    '1331_933': 22.2607,
    '1331_934': 21.971,
    '1331_935': 21.6937,
    '1331_936': 21.4142,
    '1331_937': 21.1332,
    '1331_938': 20.8515,
    '1331_939': 20.57,
    '1331_940': 20.2897,
    '1332_774': 32.1958,
    '1332_775': 32.4121,
    '1332_776': 32.6439,
    '1332_777': 32.8554,
    '1332_778': 33.0073,
    '1332_779': 33.0873,
    '1332_780': 33.1379,
    '1332_781': 33.1603,
    '1332_782': 33.1617,
    '1332_783': 33.1705,
    '1332_784': 33.1686,
    '1332_785': 33.168,
    '1332_788': 33.5244,
    '1332_789': 33.6104,
    '1332_790': 33.7018,
    '1332_791': 33.7882,
    '1332_792': 33.8705,
    '1332_793': 33.9508,
    '1332_794': 34.0257,
    '1332_795': 34.1227,
    '1332_796': 34.2423,
    '1332_797': 34.3643,
    '1332_798': 34.4742,
    '1332_799': 34.5685,
    '1332_800': 34.63,
    '1332_801': 34.6473,
    '1332_802': 34.6244,
    '1332_803': 34.5922,
    '1332_804': 34.5582,
    '1332_805': 34.5151,
    '1332_806': 34.4757,
    '1332_807': 34.4402,
    '1332_808': 34.3987,
    '1332_809': 34.349,
    '1332_810': 34.3045,
    '1332_811': 34.2659,
    '1332_812': 34.2104,
    '1332_813': 34.1257,
    '1332_814': 34.018,
    '1332_815': 33.9137,
    '1332_816': 33.8242,
    '1332_817': 33.7364,
    '1332_818': 33.6537,
    '1332_819': 33.5794,
    '1332_820': 33.5128,
    '1332_821': 33.4529,
    '1332_822': 33.3988,
    '1332_823': 33.3523,
    '1332_824': 33.3162,
    '1332_825': 33.2874,
    '1332_826': 33.2662,
    '1332_827': 33.2466,
    '1332_828': 33.2274,
    '1332_829': 33.2129,
    '1332_830': 33.1998,
    '1332_831': 33.1871,
    '1332_832': 33.1749,
    '1332_833': 33.1623,
    '1332_834': 33.1505,
    '1332_835': 33.1396,
    '1332_836': 33.1094,
    '1332_837': 33.0579,
    '1332_838': 32.9926,
    '1332_839': 32.9085,
    '1332_840': 32.8157,
    '1332_841': 32.7143,
    '1332_842': 32.5992,
    '1332_843': 32.4727,
    '1332_844': 32.3327,
    '1332_845': 32.1773,
    '1332_846': 32.0087,
    '1332_847': 31.8308,
    '1332_848': 31.6462,
    '1332_849': 31.4593,
    '1332_850': 31.2688,
    '1332_851': 31.0731,
    '1332_852': 30.875,
    '1332_853': 30.6774,
    '1332_854': 30.4816,
    '1332_855': 30.2884,
    '1332_856': 30.0912,
    '1332_857': 29.885,
    '1332_858': 29.6682,
    '1332_859': 29.4465,
    '1332_860': 29.2203,
    '1332_861': 28.9913,
    '1332_862': 28.7547,
    '1332_863': 28.5111,
    '1332_864': 28.2622,
    '1332_865': 28.0036,
    '1332_866': 27.7305,
    '1332_867': 27.4448,
    '1332_868': 27.1421,
    '1332_869': 26.8285,
    '1332_870': 26.5233,
    '1332_871': 26.2249,
    '1332_872': 25.9299,
    '1332_873': 25.6556,
    '1332_874': 25.3433,
    '1332_875': 25.0272,
    '1332_876': 24.7317,
    '1332_877': 24.453,
    '1332_878': 24.1851,
    '1332_879': 23.9216,
    '1332_880': 23.6815,
    '1332_881': 23.4572,
    '1332_882': 23.2483,
    '1332_883': 23.0625,
    '1332_884': 22.8937,
    '1332_885': 22.7451,
    '1332_886': 22.6183,
    '1332_887': 22.5056,
    '1332_888': 22.415,
    '1332_889': 22.3451,
    '1332_890': 22.2927,
    '1332_891': 22.2619,
    '1332_892': 22.2429,
    '1332_893': 22.2388,
    '1332_894': 22.2602,
    '1332_895': 22.2966,
    '1332_896': 22.3465,
    '1332_897': 22.4087,
    '1332_898': 22.4875,
    '1332_899': 22.5831,
    '1332_900': 22.6886,
    '1332_901': 22.795,
    '1332_902': 22.9,
    '1332_903': 23.0034,
    '1332_904': 23.1027,
    '1332_905': 23.192,
    '1332_906': 23.2809,
    '1332_907': 23.3665,
    '1332_908': 23.4496,
    '1332_909': 23.5319,
    '1332_910': 23.6097,
    '1332_911': 23.6852,
    '1332_912': 23.7611,
    '1332_913': 23.8285,
    '1332_914': 23.8822,
    '1332_915': 23.9354,
    '1332_916': 23.9953,
    '1332_917': 24.0541,
    '1332_918': 24.0907,
    '1332_919': 24.099,
    '1332_920': 24.1056,
    '1332_921': 24.1154,
    '1332_922': 24.1357,
    '1332_923': 24.1303,
    '1332_924': 24.0947,
    '1332_925': 24.0363,
    '1332_926': 23.9554,
    '1332_927': 23.8373,
    '1332_928': 23.6747,
    '1332_929': 23.4675,
    '1332_930': 23.2231,
    '1332_931': 22.9548,
    '1332_932': 22.685,
    '1332_933': 22.4059,
    '1332_934': 22.1145,
    '1332_935': 21.8302,
    '1332_936': 21.5429,
    '1332_937': 21.2545,
    '1332_938': 20.9658,
    '1332_939': 20.6782,
    '1332_940': 20.3936,
    '1333_774': 32.0629,
    '1333_775': 32.2736,
    '1333_776': 32.4963,
    '1333_777': 32.7093,
    '1333_778': 32.8676,
    '1333_779': 32.9633,
    '1333_780': 33.0329,
    '1333_781': 33.0813,
    '1333_782': 33.1035,
    '1333_783': 33.1074,
    '1333_784': 33.123,
    '1333_785': 33.134,
    '1333_788': 33.5342,
    '1333_789': 33.6331,
    '1333_790': 33.7375,
    '1333_791': 33.8297,
    '1333_792': 33.9093,
    '1333_793': 33.992,
    '1333_794': 34.0706,
    '1333_795': 34.1584,
    '1333_796': 34.2676,
    '1333_797': 34.3816,
    '1333_798': 34.484,
    '1333_799': 34.5783,
    '1333_800': 34.6342,
    '1333_801': 34.6434,
    '1333_802': 34.6197,
    '1333_803': 34.5735,
    '1333_804': 34.5277,
    '1333_805': 34.4658,
    '1333_806': 34.4094,
    '1333_807': 34.3598,
    '1333_808': 34.3143,
    '1333_809': 34.2646,
    '1333_810': 34.2162,
    '1333_811': 34.1702,
    '1333_812': 34.1081,
    '1333_813': 34.0184,
    '1333_814': 33.9098,
    '1333_815': 33.8071,
    '1333_816': 33.7148,
    '1333_817': 33.6285,
    '1333_818': 33.5484,
    '1333_819': 33.4756,
    '1333_820': 33.415,
    '1333_821': 33.3622,
    '1333_822': 33.3138,
    '1333_823': 33.2696,
    '1333_824': 33.2416,
    '1333_825': 33.2175,
    '1333_826': 33.2067,
    '1333_827': 33.1936,
    '1333_828': 33.1773,
    '1333_829': 33.1703,
    '1333_830': 33.1612,
    '1333_831': 33.1571,
    '1333_832': 33.1526,
    '1333_833': 33.1414,
    '1333_834': 33.1279,
    '1333_835': 33.1174,
    '1333_836': 33.09,
    '1333_837': 33.0556,
    '1333_838': 33.0066,
    '1333_839': 32.9246,
    '1333_840': 32.8349,
    '1333_841': 32.7376,
    '1333_842': 32.6217,
    '1333_843': 32.4972,
    '1333_844': 32.357,
    '1333_845': 32.1984,
    '1333_846': 32.0259,
    '1333_847': 31.845,
    '1333_848': 31.6558,
    '1333_849': 31.4699,
    '1333_850': 31.2802,
    '1333_851': 31.0829,
    '1333_852': 30.8838,
    '1333_853': 30.689,
    '1333_854': 30.493,
    '1333_855': 30.3032,
    '1333_856': 30.1068,
    '1333_857': 29.9015,
    '1333_858': 29.6879,
    '1333_859': 29.4703,
    '1333_860': 29.2461,
    '1333_861': 29.0194,
    '1333_862': 28.7882,
    '1333_863': 28.5496,
    '1333_864': 28.3096,
    '1333_865': 28.0625,
    '1333_866': 27.7987,
    '1333_867': 27.5198,
    '1333_868': 27.224,
    '1333_869': 26.9167,
    '1333_870': 26.6115,
    '1333_871': 26.3276,
    '1333_872': 26.0473,
    '1333_873': 25.7559,
    '1333_874': 25.4471,
    '1333_875': 25.1368,
    '1333_876': 24.8391,
    '1333_877': 24.5612,
    '1333_878': 24.3023,
    '1333_879': 24.0544,
    '1333_880': 23.8238,
    '1333_881': 23.6085,
    '1333_882': 23.409,
    '1333_883': 23.2282,
    '1333_884': 23.0657,
    '1333_885': 22.9242,
    '1333_886': 22.8031,
    '1333_887': 22.701,
    '1333_888': 22.6235,
    '1333_889': 22.5581,
    '1333_890': 22.5106,
    '1333_891': 22.4823,
    '1333_892': 22.4698,
    '1333_893': 22.4735,
    '1333_894': 22.4957,
    '1333_895': 22.5328,
    '1333_896': 22.5827,
    '1333_897': 22.6454,
    '1333_898': 22.7245,
    '1333_899': 22.8249,
    '1333_900': 22.9347,
    '1333_901': 23.0474,
    '1333_902': 23.1594,
    '1333_903': 23.2697,
    '1333_904': 23.371,
    '1333_905': 23.4706,
    '1333_906': 23.5634,
    '1333_907': 23.6506,
    '1333_908': 23.7331,
    '1333_909': 23.8157,
    '1333_910': 23.8953,
    '1333_911': 23.9717,
    '1333_912': 24.0434,
    '1333_913': 24.1113,
    '1333_914': 24.1726,
    '1333_915': 24.2277,
    '1333_916': 24.2868,
    '1333_917': 24.3391,
    '1333_918': 24.3621,
    '1333_919': 24.3669,
    '1333_920': 24.3677,
    '1333_921': 24.3733,
    '1333_922': 24.3896,
    '1333_923': 24.383,
    '1333_924': 24.3418,
    '1333_925': 24.2789,
    '1333_926': 24.184,
    '1333_927': 24.0429,
    '1333_928': 23.8573,
    '1333_929': 23.6269,
    '1333_930': 23.3701,
    '1333_931': 23.0972,
    '1333_932': 22.8218,
    '1333_933': 22.5394,
    '1333_934': 22.2512,
    '1333_935': 21.9631,
    '1333_936': 21.67,
    '1333_937': 21.3755,
    '1333_938': 21.0808,
    '1333_939': 20.7879,
    '1333_940': 20.499,
    '1334_774': 31.9436,
    '1334_775': 32.1416,
    '1334_776': 32.3412,
    '1334_777': 32.5361,
    '1334_778': 32.6917,
    '1334_779': 32.8041,
    '1334_780': 32.8923,
    '1334_781': 32.9647,
    '1334_782': 33.0191,
    '1334_783': 33.0315,
    '1334_784': 33.0556,
    '1334_785': 33.0778,
    '1334_788': 33.5251,
    '1334_789': 33.6318,
    '1334_790': 33.7447,
    '1334_791': 33.8468,
    '1334_792': 33.9527,
    '1334_793': 34.0421,
    '1334_794': 34.1145,
    '1334_795': 34.1845,
    '1334_796': 34.2755,
    '1334_797': 34.3763,
    '1334_798': 34.4728,
    '1334_799': 34.5555,
    '1334_800': 34.6042,
    '1334_801': 34.6146,
    '1334_802': 34.5844,
    '1334_803': 34.5317,
    '1334_804': 34.4726,
    '1334_805': 34.4054,
    '1334_806': 34.3473,
    '1334_807': 34.297,
    '1334_808': 34.2453,
    '1334_809': 34.1943,
    '1334_810': 34.1419,
    '1334_811': 34.0836,
    '1334_812': 34.0083,
    '1334_813': 33.9125,
    '1334_814': 33.8104,
    '1334_815': 33.7128,
    '1334_816': 33.6209,
    '1334_817': 33.5357,
    '1334_818': 33.4549,
    '1334_819': 33.3829,
    '1334_820': 33.3299,
    '1334_821': 33.288,
    '1334_822': 33.2524,
    '1334_823': 33.2209,
    '1334_824': 33.2,
    '1334_825': 33.1837,
    '1334_826': 33.1769,
    '1334_827': 33.1705,
    '1334_828': 33.1639,
    '1334_829': 33.163,
    '1334_830': 33.1623,
    '1334_831': 33.163,
    '1334_832': 33.163,
    '1334_833': 33.158,
    '1334_834': 33.1477,
    '1334_835': 33.1316,
    '1334_836': 33.1074,
    '1334_837': 33.0774,
    '1334_838': 33.0376,
    '1334_839': 32.9795,
    '1334_840': 32.8938,
    '1334_841': 32.7937,
    '1334_842': 32.676,
    '1334_843': 32.5465,
    '1334_844': 32.4039,
    '1334_845': 32.2449,
    '1334_846': 32.0708,
    '1334_847': 31.8877,
    '1334_848': 31.7005,
    '1334_849': 31.511,
    '1334_850': 31.3181,
    '1334_851': 31.1211,
    '1334_852': 30.9239,
    '1334_853': 30.7269,
    '1334_854': 30.5306,
    '1334_855': 30.338,
    '1334_856': 30.1405,
    '1334_857': 29.9373,
    '1334_858': 29.7299,
    '1334_859': 29.517,
    '1334_860': 29.2975,
    '1334_861': 29.0746,
    '1334_862': 28.8479,
    '1334_863': 28.618,
    '1334_864': 28.3872,
    '1334_865': 28.1494,
    '1334_866': 27.8952,
    '1334_867': 27.6221,
    '1334_868': 27.3334,
    '1334_869': 27.0343,
    '1334_870': 26.7352,
    '1334_871': 26.448,
    '1334_872': 26.164,
    '1334_873': 25.8645,
    '1334_874': 25.5577,
    '1334_875': 25.2529,
    '1334_876': 24.9591,
    '1334_877': 24.6906,
    '1334_878': 24.4402,
    '1334_879': 24.2033,
    '1334_880': 23.9812,
    '1334_881': 23.7743,
    '1334_882': 23.5831,
    '1334_883': 23.4092,
    '1334_884': 23.2533,
    '1334_885': 23.1175,
    '1334_886': 23.0029,
    '1334_887': 22.9078,
    '1334_888': 22.8327,
    '1334_889': 22.773,
    '1334_890': 22.7316,
    '1334_891': 22.7081,
    '1334_892': 22.7003,
    '1334_893': 22.7092,
    '1334_894': 22.7338,
    '1334_895': 22.7731,
    '1334_896': 22.8248,
    '1334_897': 22.8914,
    '1334_898': 22.9748,
    '1334_899': 23.0762,
    '1334_900': 23.188,
    '1334_901': 23.3054,
    '1334_902': 23.4205,
    '1334_903': 23.5391,
    '1334_904': 23.6552,
    '1334_905': 23.7631,
    '1334_906': 23.8559,
    '1334_907': 23.9437,
    '1334_908': 24.0275,
    '1334_909': 24.107,
    '1334_910': 24.186,
    '1334_911': 24.2659,
    '1334_912': 24.3409,
    '1334_913': 24.408,
    '1334_914': 24.4763,
    '1334_915': 24.5386,
    '1334_916': 24.5859,
    '1334_917': 24.6111,
    '1334_918': 24.6276,
    '1334_919': 24.6364,
    '1334_920': 24.6396,
    '1334_921': 24.6424,
    '1334_922': 24.6483,
    '1334_923': 24.6352,
    '1334_924': 24.5905,
    '1334_925': 24.5159,
    '1334_926': 24.3993,
    '1334_927': 24.234,
    '1334_928': 24.0246,
    '1334_929': 23.7783,
    '1334_930': 23.5153,
    '1334_931': 23.2406,
    '1334_932': 22.963,
    '1334_933': 22.6751,
    '1334_934': 22.387,
    '1334_935': 22.0936,
    '1334_936': 21.7961,
    '1334_937': 21.4967,
    '1334_938': 21.1973,
    '1334_939': 20.9003,
    '1334_940': 20.6087,
    '1335_774': 31.8214,
    '1335_775': 32.0052,
    '1335_776': 32.1919,
    '1335_777': 32.3745,
    '1335_778': 32.5269,
    '1335_779': 32.6416,
    '1335_780': 32.7404,
    '1335_781': 32.8202,
    '1335_782': 32.8821,
    '1335_783': 32.9209,
    '1335_784': 32.9641,
    '1335_785': 33.0009,
    '1335_788': 33.5014,
    '1335_789': 33.6145,
    '1335_790': 33.7319,
    '1335_791': 33.8624,
    '1335_792': 33.9888,
    '1335_793': 34.0834,
    '1335_794': 34.149,
    '1335_795': 34.2022,
    '1335_796': 34.2696,
    '1335_797': 34.3552,
    '1335_798': 34.436,
    '1335_799': 34.5039,
    '1335_800': 34.5443,
    '1335_801': 34.548,
    '1335_802': 34.5176,
    '1335_803': 34.4678,
    '1335_804': 34.4063,
    '1335_805': 34.3442,
    '1335_806': 34.2905,
    '1335_807': 34.2473,
    '1335_808': 34.1959,
    '1335_809': 34.1401,
    '1335_810': 34.0742,
    '1335_811': 34.002,
    '1335_812': 33.9179,
    '1335_813': 33.8234,
    '1335_814': 33.7256,
    '1335_815': 33.6319,
    '1335_816': 33.5412,
    '1335_817': 33.4568,
    '1335_818': 33.3798,
    '1335_819': 33.3143,
    '1335_820': 33.2638,
    '1335_821': 33.2288,
    '1335_822': 33.2022,
    '1335_823': 33.1828,
    '1335_824': 33.1722,
    '1335_825': 33.1617,
    '1335_826': 33.1613,
    '1335_827': 33.1616,
    '1335_828': 33.1633,
    '1335_829': 33.1716,
    '1335_830': 33.1789,
    '1335_831': 33.1854,
    '1335_832': 33.1914,
    '1335_833': 33.191,
    '1335_834': 33.1829,
    '1335_835': 33.1615,
    '1335_836': 33.1351,
    '1335_837': 33.1029,
    '1335_838': 33.0633,
    '1335_839': 33.0093,
    '1335_840': 32.9411,
    '1335_841': 32.8484,
    '1335_842': 32.7304,
    '1335_843': 32.598,
    '1335_844': 32.4557,
    '1335_845': 32.2978,
    '1335_846': 32.1232,
    '1335_847': 31.9393,
    '1335_848': 31.7547,
    '1335_849': 31.5619,
    '1335_850': 31.3651,
    '1335_851': 31.1664,
    '1335_852': 30.97,
    '1335_853': 30.7699,
    '1335_854': 30.5717,
    '1335_855': 30.3776,
    '1335_856': 30.1794,
    '1335_857': 29.9778,
    '1335_858': 29.7766,
    '1335_859': 29.5696,
    '1335_860': 29.3547,
    '1335_861': 29.1369,
    '1335_862': 28.9151,
    '1335_863': 28.6931,
    '1335_864': 28.4724,
    '1335_865': 28.2446,
    '1335_866': 28.0011,
    '1335_867': 27.7338,
    '1335_868': 27.452,
    '1335_869': 27.1588,
    '1335_870': 26.8616,
    '1335_871': 26.5695,
    '1335_872': 26.2788,
    '1335_873': 25.9778,
    '1335_874': 25.6768,
    '1335_875': 25.3821,
    '1335_876': 25.0997,
    '1335_877': 24.8374,
    '1335_878': 24.593,
    '1335_879': 24.3636,
    '1335_880': 24.1489,
    '1335_881': 23.9494,
    '1335_882': 23.7656,
    '1335_883': 23.5988,
    '1335_884': 23.4489,
    '1335_885': 23.3177,
    '1335_886': 23.2089,
    '1335_887': 23.1175,
    '1335_888': 23.0441,
    '1335_889': 22.9873,
    '1335_890': 22.9506,
    '1335_891': 22.932,
    '1335_892': 22.9274,
    '1335_893': 22.9405,
    '1335_894': 22.9682,
    '1335_895': 23.0117,
    '1335_896': 23.0658,
    '1335_897': 23.137,
    '1335_898': 23.2251,
    '1335_899': 23.3296,
    '1335_900': 23.4418,
    '1335_901': 23.5658,
    '1335_902': 23.6943,
    '1335_903': 23.8225,
    '1335_904': 23.9487,
    '1335_905': 24.0626,
    '1335_906': 24.1565,
    '1335_907': 24.2453,
    '1335_908': 24.3285,
    '1335_909': 24.407,
    '1335_910': 24.4804,
    '1335_911': 24.5635,
    '1335_912': 24.6466,
    '1335_913': 24.7162,
    '1335_914': 24.7901,
    '1335_915': 24.8538,
    '1335_916': 24.8841,
    '1335_917': 24.8986,
    '1335_918': 24.9143,
    '1335_919': 24.9227,
    '1335_920': 24.9254,
    '1335_921': 24.9173,
    '1335_922': 24.9115,
    '1335_923': 24.8891,
    '1335_924': 24.8357,
    '1335_925': 24.7394,
    '1335_926': 24.5941,
    '1335_927': 24.4055,
    '1335_928': 24.1782,
    '1335_929': 23.9263,
    '1335_930': 23.658,
    '1335_931': 23.3834,
    '1335_932': 23.1032,
    '1335_933': 22.8121,
    '1335_934': 22.5218,
    '1335_935': 22.2249,
    '1335_936': 21.9236,
    '1335_937': 21.6204,
    '1335_938': 21.3171,
    '1335_939': 21.0163,
    '1335_940': 20.7226,
    '1336_774': 31.7005,
    '1336_775': 31.8673,
    '1336_776': 32.04,
    '1336_777': 32.2085,
    '1336_778': 32.3608,
    '1336_779': 32.4903,
    '1336_780': 32.5969,
    '1336_781': 32.6738,
    '1336_782': 32.7428,
    '1336_783': 32.8003,
    '1336_784': 32.864,
    '1336_785': 32.9241,
    '1336_788': 33.4757,
    '1336_789': 33.6008,
    '1336_790': 33.7232,
    '1336_791': 33.8637,
    '1336_792': 34.0014,
    '1336_793': 34.0979,
    '1336_794': 34.1623,
    '1336_795': 34.2083,
    '1336_796': 34.2509,
    '1336_797': 34.3128,
    '1336_798': 34.3783,
    '1336_799': 34.4281,
    '1336_800': 34.4568,
    '1336_801': 34.4637,
    '1336_802': 34.441,
    '1336_803': 34.3989,
    '1336_804': 34.3421,
    '1336_805': 34.288,
    '1336_806': 34.2439,
    '1336_807': 34.207,
    '1336_808': 34.159,
    '1336_809': 34.0962,
    '1336_810': 34.0142,
    '1336_811': 33.9286,
    '1336_812': 33.8407,
    '1336_813': 33.7494,
    '1336_814': 33.6536,
    '1336_815': 33.5602,
    '1336_816': 33.4716,
    '1336_817': 33.3905,
    '1336_818': 33.3191,
    '1336_819': 33.2621,
    '1336_820': 33.218,
    '1336_821': 33.1855,
    '1336_822': 33.1627,
    '1336_823': 33.1477,
    '1336_824': 33.1412,
    '1336_825': 33.1371,
    '1336_826': 33.1429,
    '1336_827': 33.1503,
    '1336_828': 33.1595,
    '1336_829': 33.1761,
    '1336_830': 33.1948,
    '1336_831': 33.2095,
    '1336_832': 33.2231,
    '1336_833': 33.2215,
    '1336_834': 33.2113,
    '1336_835': 33.187,
    '1336_836': 33.1588,
    '1336_837': 33.1263,
    '1336_838': 33.086,
    '1336_839': 33.0299,
    '1336_840': 32.9595,
    '1336_841': 32.869,
    '1336_842': 32.7595,
    '1336_843': 32.6299,
    '1336_844': 32.4887,
    '1336_845': 32.3324,
    '1336_846': 32.1644,
    '1336_847': 31.9866,
    '1336_848': 31.8011,
    '1336_849': 31.6046,
    '1336_850': 31.4031,
    '1336_851': 31.2007,
    '1336_852': 30.9998,
    '1336_853': 30.7978,
    '1336_854': 30.5999,
    '1336_855': 30.4042,
    '1336_856': 30.2063,
    '1336_857': 30.0085,
    '1336_858': 29.8099,
    '1336_859': 29.6076,
    '1336_860': 29.3995,
    '1336_861': 29.1884,
    '1336_862': 28.9749,
    '1336_863': 28.7595,
    '1336_864': 28.5442,
    '1336_865': 28.3223,
    '1336_866': 28.0866,
    '1336_867': 27.8326,
    '1336_868': 27.5579,
    '1336_869': 27.2722,
    '1336_870': 26.9824,
    '1336_871': 26.6954,
    '1336_872': 26.3963,
    '1336_873': 26.0947,
    '1336_874': 25.8024,
    '1336_875': 25.5195,
    '1336_876': 25.2479,
    '1336_877': 24.993,
    '1336_878': 24.755,
    '1336_879': 24.5316,
    '1336_880': 24.3233,
    '1336_881': 24.1305,
    '1336_882': 23.9535,
    '1336_883': 23.7934,
    '1336_884': 23.6504,
    '1336_885': 23.5255,
    '1336_886': 23.4172,
    '1336_887': 23.327,
    '1336_888': 23.2547,
    '1336_889': 23.2003,
    '1336_890': 23.1645,
    '1336_891': 23.1461,
    '1336_892': 23.1441,
    '1336_893': 23.1598,
    '1336_894': 23.1917,
    '1336_895': 23.2391,
    '1336_896': 23.3001,
    '1336_897': 23.3773,
    '1336_898': 23.4688,
    '1336_899': 23.58,
    '1336_900': 23.7036,
    '1336_901': 23.8366,
    '1336_902': 23.974,
    '1336_903': 24.111,
    '1336_904': 24.2364,
    '1336_905': 24.353,
    '1336_906': 24.4552,
    '1336_907': 24.5483,
    '1336_908': 24.636,
    '1336_909': 24.71,
    '1336_910': 24.7795,
    '1336_911': 24.8575,
    '1336_912': 24.9467,
    '1336_913': 25.0301,
    '1336_914': 25.1067,
    '1336_915': 25.1612,
    '1336_916': 25.1826,
    '1336_917': 25.1985,
    '1336_918': 25.2107,
    '1336_919': 25.2173,
    '1336_920': 25.2103,
    '1336_921': 25.2011,
    '1336_922': 25.1902,
    '1336_923': 25.152,
    '1336_924': 25.0743,
    '1336_925': 24.9475,
    '1336_926': 24.7764,
    '1336_927': 24.5673,
    '1336_928': 24.3309,
    '1336_929': 24.0718,
    '1336_930': 23.8039,
    '1336_931': 23.5283,
    '1336_932': 23.2452,
    '1336_933': 22.9542,
    '1336_934': 22.6594,
    '1336_935': 22.3586,
    '1336_936': 22.0542,
    '1336_937': 21.7486,
    '1336_938': 21.4433,
    '1336_939': 21.141,
    '1336_940': 20.8432,
    '1337_774': 31.5914,
    '1337_775': 31.7389,
    '1337_776': 31.8996,
    '1337_777': 32.0561,
    '1337_778': 32.2065,
    '1337_779': 32.3406,
    '1337_780': 32.4529,
    '1337_781': 32.5397,
    '1337_782': 32.6157,
    '1337_783': 32.6834,
    '1337_784': 32.7502,
    '1337_785': 32.8307,
    '1337_788': 33.441,
    '1337_789': 33.5754,
    '1337_790': 33.7051,
    '1337_791': 33.8539,
    '1337_792': 34.0012,
    '1337_793': 34.1034,
    '1337_794': 34.1601,
    '1337_795': 34.19,
    '1337_796': 34.217,
    '1337_797': 34.2581,
    '1337_798': 34.3046,
    '1337_799': 34.3447,
    '1337_800': 34.3694,
    '1337_801': 34.3796,
    '1337_802': 34.369,
    '1337_803': 34.3362,
    '1337_804': 34.2891,
    '1337_805': 34.2401,
    '1337_806': 34.204,
    '1337_807': 34.176,
    '1337_808': 34.1325,
    '1337_809': 34.0627,
    '1337_810': 33.9649,
    '1337_811': 33.8676,
    '1337_812': 33.7804,
    '1337_813': 33.6883,
    '1337_814': 33.5922,
    '1337_815': 33.4995,
    '1337_816': 33.413,
    '1337_817': 33.3365,
    '1337_818': 33.2714,
    '1337_819': 33.222,
    '1337_820': 33.1847,
    '1337_821': 33.1553,
    '1337_822': 33.1363,
    '1337_823': 33.1252,
    '1337_824': 33.1232,
    '1337_825': 33.1253,
    '1337_826': 33.1337,
    '1337_827': 33.1452,
    '1337_828': 33.1567,
    '1337_829': 33.1756,
    '1337_830': 33.2013,
    '1337_831': 33.2203,
    '1337_832': 33.2336,
    '1337_833': 33.234,
    '1337_834': 33.2221,
    '1337_835': 33.1983,
    '1337_836': 33.1702,
    '1337_837': 33.1381,
    '1337_838': 33.0989,
    '1337_839': 33.0374,
    '1337_840': 32.9615,
    '1337_841': 32.8666,
    '1337_842': 32.7568,
    '1337_843': 32.6402,
    '1337_844': 32.5095,
    '1337_845': 32.3584,
    '1337_846': 32.1998,
    '1337_847': 32.0279,
    '1337_848': 31.8425,
    '1337_849': 31.6409,
    '1337_850': 31.4354,
    '1337_851': 31.2305,
    '1337_852': 31.0279,
    '1337_853': 30.8233,
    '1337_854': 30.6268,
    '1337_855': 30.4306,
    '1337_856': 30.2329,
    '1337_857': 30.0384,
    '1337_858': 29.8425,
    '1337_859': 29.6447,
    '1337_860': 29.4427,
    '1337_861': 29.2388,
    '1337_862': 29.034,
    '1337_863': 28.8238,
    '1337_864': 28.6136,
    '1337_865': 28.3959,
    '1337_866': 28.166,
    '1337_867': 27.9204,
    '1337_868': 27.6575,
    '1337_869': 27.382,
    '1337_870': 27.0965,
    '1337_871': 26.8118,
    '1337_872': 26.5156,
    '1337_873': 26.2167,
    '1337_874': 25.936,
    '1337_875': 25.6647,
    '1337_876': 25.4034,
    '1337_877': 25.1558,
    '1337_878': 24.9231,
    '1337_879': 24.7044,
    '1337_880': 24.5014,
    '1337_881': 24.3143,
    '1337_882': 24.1427,
    '1337_883': 23.9879,
    '1337_884': 23.8498,
    '1337_885': 23.7279,
    '1337_886': 23.6221,
    '1337_887': 23.5336,
    '1337_888': 23.4629,
    '1337_889': 23.4099,
    '1337_890': 23.3743,
    '1337_891': 23.3556,
    '1337_892': 23.3541,
    '1337_893': 23.3728,
    '1337_894': 23.4087,
    '1337_895': 23.4605,
    '1337_896': 23.5279,
    '1337_897': 23.6095,
    '1337_898': 23.7123,
    '1337_899': 23.8353,
    '1337_900': 23.9667,
    '1337_901': 24.1021,
    '1337_902': 24.247,
    '1337_903': 24.3919,
    '1337_904': 24.523,
    '1337_905': 24.6456,
    '1337_906': 24.756,
    '1337_907': 24.853,
    '1337_908': 24.9431,
    '1337_909': 25.0115,
    '1337_910': 25.0821,
    '1337_911': 25.16,
    '1337_912': 25.2554,
    '1337_913': 25.3515,
    '1337_914': 25.4271,
    '1337_915': 25.469,
    '1337_916': 25.483,
    '1337_917': 25.501,
    '1337_918': 25.5128,
    '1337_919': 25.515,
    '1337_920': 25.5104,
    '1337_921': 25.4996,
    '1337_922': 25.4725,
    '1337_923': 25.4068,
    '1337_924': 25.2992,
    '1337_925': 25.145,
    '1337_926': 24.9446,
    '1337_927': 24.7234,
    '1337_928': 24.4809,
    '1337_929': 24.2225,
    '1337_930': 23.9531,
    '1337_931': 23.6769,
    '1337_932': 23.392,
    '1337_933': 23.0977,
    '1337_934': 22.7984,
    '1337_935': 22.4943,
    '1337_936': 22.1878,
    '1337_937': 21.8807,
    '1337_938': 21.5748,
    '1337_939': 21.2717,
    '1337_940': 20.9728,
    '1338_788': 33.384,
    '1338_789': 33.5313,
    '1338_790': 33.6735,
    '1338_791': 33.8392,
    '1338_792': 33.9877,
    '1338_793': 34.0843,
    '1338_794': 34.133,
    '1338_795': 34.1568,
    '1338_796': 34.1725,
    '1338_797': 34.2007,
    '1338_798': 34.2344,
    '1338_799': 34.2652,
    '1338_800': 34.2882,
    '1338_801': 34.3021,
    '1338_802': 34.3007,
    '1338_803': 34.2824,
    '1338_804': 34.2469,
    '1338_805': 34.2056,
    '1338_806': 34.1737,
    '1338_807': 34.1521,
    '1338_808': 34.1124,
    '1338_809': 34.0361,
    '1338_810': 33.9286,
    '1338_811': 33.8216,
    '1338_812': 33.739,
    '1338_813': 33.6477,
    '1338_814': 33.548,
    '1338_815': 33.4544,
    '1338_816': 33.3685,
    '1338_817': 33.2954,
    '1338_818': 33.234,
    '1338_819': 33.1918,
    '1338_820': 33.1612,
    '1338_821': 33.1397,
    '1338_822': 33.1246,
    '1338_823': 33.1184,
    '1338_824': 33.1196,
    '1338_825': 33.1259,
    '1338_826': 33.1376,
    '1338_827': 33.1562,
    '1338_828': 33.1757,
    '1338_829': 33.1975,
    '1338_830': 33.2195,
    '1338_831': 33.2364,
    '1338_832': 33.2449,
    '1338_833': 33.2415,
    '1338_834': 33.226,
    '1338_835': 33.1994,
    '1338_836': 33.1677,
    '1338_837': 33.1308,
    '1338_838': 33.0892,
    '1338_839': 33.0293,
    '1338_840': 32.9523,
    '1338_841': 32.8592,
    '1338_842': 32.7517,
    '1338_843': 32.642,
    '1338_844': 32.5217,
    '1338_845': 32.3865,
    '1338_846': 32.2343,
    '1338_847': 32.0633,
    '1338_848': 31.8726,
    '1338_849': 31.6685,
    '1338_850': 31.4646,
    '1338_851': 31.2648,
    '1338_852': 31.0658,
    '1338_853': 30.8632,
    '1338_854': 30.6642,
    '1338_855': 30.4673,
    '1338_856': 30.2707,
    '1338_857': 30.077,
    '1338_858': 29.8841,
    '1338_859': 29.6885,
    '1338_860': 29.4915,
    '1338_861': 29.2951,
    '1338_862': 29.1007,
    '1338_863': 28.8988,
    '1338_864': 28.6897,
    '1338_865': 28.4754,
    '1338_866': 28.2508,
    '1338_867': 28.0145,
    '1338_868': 27.7655,
    '1338_869': 27.5038,
    '1338_870': 27.2315,
    '1338_871': 26.9447,
    '1338_872': 26.652,
    '1338_873': 26.3615,
    '1338_874': 26.0837,
    '1338_875': 25.8172,
    '1338_876': 25.5622,
    '1338_877': 25.3207,
    '1338_878': 25.0936,
    '1338_879': 24.88,
    '1338_880': 24.6803,
    '1338_881': 24.4976,
    '1338_882': 24.3292,
    '1338_883': 24.1784,
    '1338_884': 24.0448,
    '1338_885': 23.9256,
    '1338_886': 23.8223,
    '1338_887': 23.7361,
    '1338_888': 23.6673,
    '1338_889': 23.6159,
    '1338_890': 23.5815,
    '1338_891': 23.563,
    '1338_892': 23.561,
    '1338_893': 23.5829,
    '1338_894': 23.6224,
    '1338_895': 23.6765,
    '1338_896': 23.7496,
    '1338_897': 23.8407,
    '1338_898': 23.9518,
    '1338_899': 24.0835,
    '1338_900': 24.2267,
    '1338_901': 24.3723,
    '1338_902': 24.5237,
    '1338_903': 24.6764,
    '1338_904': 24.8185,
    '1338_905': 24.9474,
    '1338_906': 25.0643,
    '1338_907': 25.1676,
    '1338_908': 25.2553,
    '1338_909': 25.3158,
    '1338_910': 25.3918,
    '1338_911': 25.4757,
    '1338_912': 25.5795,
    '1338_913': 25.673,
    '1338_914': 25.7423,
    '1338_915': 25.7742,
    '1338_916': 25.7826,
    '1338_917': 25.792,
    '1338_918': 25.8022,
    '1338_919': 25.8166,
    '1338_920': 25.8183,
    '1338_921': 25.7976,
    '1338_922': 25.7444,
    '1338_923': 25.6473,
    '1338_924': 25.5112,
    '1338_925': 25.3303,
    '1338_926': 25.1162,
    '1338_927': 24.881,
    '1338_928': 24.6289,
    '1338_929': 24.3695,
    '1338_930': 24.1046,
    '1338_931': 23.8282,
    '1338_932': 23.5397,
    '1338_933': 23.2385,
    '1338_934': 22.9358,
    '1338_935': 22.6301,
    '1338_936': 22.323,
    '1338_937': 22.0158,
    '1338_938': 21.71,
    '1338_939': 21.4069,
    '1338_940': 21.1078,
    '1339_788': 33.3118,
    '1339_789': 33.4697,
    '1339_790': 33.6213,
    '1339_791': 33.7932,
    '1339_792': 33.9415,
    '1339_793': 34.034,
    '1339_794': 34.0829,
    '1339_795': 34.1079,
    '1339_796': 34.1247,
    '1339_797': 34.1438,
    '1339_798': 34.1721,
    '1339_799': 34.1993,
    '1339_800': 34.2197,
    '1339_801': 34.2335,
    '1339_802': 34.2401,
    '1339_803': 34.2324,
    '1339_804': 34.2078,
    '1339_805': 34.1753,
    '1339_806': 34.1467,
    '1339_807': 34.1273,
    '1339_808': 34.0906,
    '1339_809': 34.0153,
    '1339_810': 33.9069,
    '1339_811': 33.7991,
    '1339_812': 33.729,
    '1339_813': 33.6372,
    '1339_814': 33.5338,
    '1339_815': 33.4349,
    '1339_816': 33.3466,
    '1339_817': 33.2735,
    '1339_818': 33.2155,
    '1339_819': 33.1741,
    '1339_820': 33.1454,
    '1339_821': 33.1267,
    '1339_822': 33.1188,
    '1339_823': 33.1197,
    '1339_824': 33.1265,
    '1339_825': 33.1382,
    '1339_826': 33.1557,
    '1339_827': 33.1775,
    '1339_828': 33.2011,
    '1339_829': 33.2227,
    '1339_830': 33.2389,
    '1339_831': 33.2514,
    '1339_832': 33.2546,
    '1339_833': 33.2458,
    '1339_834': 33.2266,
    '1339_835': 33.1949,
    '1339_836': 33.1575,
    '1339_837': 33.1179,
    '1339_838': 33.071,
    '1339_839': 33.0122,
    '1339_840': 32.9343,
    '1339_841': 32.8456,
    '1339_842': 32.7443,
    '1339_843': 32.6403,
    '1339_844': 32.5275,
    '1339_845': 32.4025,
    '1339_846': 32.261,
    '1339_847': 32.0938,
    '1339_848': 31.8992,
    '1339_849': 31.6923,
    '1339_850': 31.4917,
    '1339_851': 31.2993,
    '1339_852': 31.1065,
    '1339_853': 30.9047,
    '1339_854': 30.7051,
    '1339_855': 30.5087,
    '1339_856': 30.3132,
    '1339_857': 30.1203,
    '1339_858': 29.9294,
    '1339_859': 29.7371,
    '1339_860': 29.5445,
    '1339_861': 29.3516,
    '1339_862': 29.1665,
    '1339_863': 28.9651,
    '1339_864': 28.7633,
    '1339_865': 28.5565,
    '1339_866': 28.3389,
    '1339_867': 28.1123,
    '1339_868': 27.8754,
    '1339_869': 27.6235,
    '1339_870': 27.359,
    '1339_871': 27.0783,
    '1339_872': 26.7924,
    '1339_873': 26.5093,
    '1339_874': 26.2353,
    '1339_875': 25.9722,
    '1339_876': 25.7212,
    '1339_877': 25.4847,
    '1339_878': 25.263,
    '1339_879': 25.0548,
    '1339_880': 24.8584,
    '1339_881': 24.6783,
    '1339_882': 24.5092,
    '1339_883': 24.3629,
    '1339_884': 24.2333,
    '1339_885': 24.1183,
    '1339_886': 24.0185,
    '1339_887': 23.9348,
    '1339_888': 23.8686,
    '1339_889': 23.8196,
    '1339_890': 23.7878,
    '1339_891': 23.773,
    '1339_892': 23.7737,
    '1339_893': 23.7957,
    '1339_894': 23.8358,
    '1339_895': 23.8965,
    '1339_896': 23.977,
    '1339_897': 24.0753,
    '1339_898': 24.1914,
    '1339_899': 24.3288,
    '1339_900': 24.4838,
    '1339_901': 24.6435,
    '1339_902': 24.8038,
    '1339_903': 24.9711,
    '1339_904': 25.1284,
    '1339_905': 25.2612,
    '1339_906': 25.3716,
    '1339_907': 25.4767,
    '1339_908': 25.5566,
    '1339_909': 25.6194,
    '1339_910': 25.7027,
    '1339_911': 25.7998,
    '1339_912': 25.8999,
    '1339_913': 25.9895,
    '1339_914': 26.0529,
    '1339_915': 26.0773,
    '1339_916': 26.0799,
    '1339_917': 26.0823,
    '1339_918': 26.0976,
    '1339_919': 26.1144,
    '1339_920': 26.1088,
    '1339_921': 26.074,
    '1339_922': 25.9948,
    '1339_923': 25.8733,
    '1339_924': 25.7082,
    '1339_925': 25.513,
    '1339_926': 25.2927,
    '1339_927': 25.0446,
    '1339_928': 24.7856,
    '1339_929': 24.5206,
    '1339_930': 24.2537,
    '1339_931': 23.9732,
    '1339_932': 23.6816,
    '1339_933': 23.3781,
    '1339_934': 23.0726,
    '1339_935': 22.7673,
    '1339_936': 22.4609,
    '1339_937': 22.1552,
    '1339_938': 21.8513,
    '1339_939': 21.55,
    '1339_940': 21.2529,
    '1340_788': 33.2263,
    '1340_789': 33.393,
    '1340_790': 33.5497,
    '1340_791': 33.7184,
    '1340_792': 33.8649,
    '1340_793': 33.9594,
    '1340_794': 34.0165,
    '1340_795': 34.0503,
    '1340_796': 34.0689,
    '1340_797': 34.0883,
    '1340_798': 34.1178,
    '1340_799': 34.1421,
    '1340_800': 34.1633,
    '1340_801': 34.1764,
    '1340_802': 34.1855,
    '1340_803': 34.1848,
    '1340_804': 34.1735,
    '1340_805': 34.152,
    '1340_806': 34.1266,
    '1340_807': 34.1061,
    '1340_808': 34.0742,
    '1340_809': 34.0038,
    '1340_810': 33.9015,
    '1340_811': 33.8028,
    '1340_812': 33.7449,
    '1340_813': 33.6418,
    '1340_814': 33.5292,
    '1340_815': 33.4242,
    '1340_816': 33.3326,
    '1340_817': 33.26,
    '1340_818': 33.2043,
    '1340_819': 33.1643,
    '1340_820': 33.1375,
    '1340_821': 33.122,
    '1340_822': 33.1183,
    '1340_823': 33.1245,
    '1340_824': 33.1373,
    '1340_825': 33.1539,
    '1340_826': 33.1745,
    '1340_827': 33.1957,
    '1340_828': 33.2161,
    '1340_829': 33.2336,
    '1340_830': 33.2448,
    '1340_831': 33.2503,
    '1340_832': 33.2493,
    '1340_833': 33.2404,
    '1340_834': 33.2223,
    '1340_835': 33.1861,
    '1340_836': 33.1464,
    '1340_837': 33.0998,
    '1340_838': 33.0517,
    '1340_839': 32.9914,
    '1340_840': 32.9185,
    '1340_841': 32.8359,
    '1340_842': 32.7441,
    '1340_843': 32.6391,
    '1340_844': 32.5294,
    '1340_845': 32.4073,
    '1340_846': 32.2677,
    '1340_847': 32.1075,
    '1340_848': 31.9221,
    '1340_849': 31.7227,
    '1340_850': 31.5259,
    '1340_851': 31.3325,
    '1340_852': 31.1371,
    '1340_853': 30.94,
    '1340_854': 30.7443,
    '1340_855': 30.5522,
    '1340_856': 30.3604,
    '1340_857': 30.1695,
    '1340_858': 29.9815,
    '1340_859': 29.7928,
    '1340_860': 29.6072,
    '1340_861': 29.4138,
    '1340_862': 29.2279,
    '1340_863': 29.0348,
    '1340_864': 28.8386,
    '1340_865': 28.6374,
    '1340_866': 28.4274,
    '1340_867': 28.209,
    '1340_868': 27.9807,
    '1340_869': 27.7382,
    '1340_870': 27.4809,
    '1340_871': 27.2084,
    '1340_872': 26.9306,
    '1340_873': 26.6545,
    '1340_874': 26.3855,
    '1340_875': 26.1262,
    '1340_876': 25.8784,
    '1340_877': 25.6463,
    '1340_878': 25.4278,
    '1340_879': 25.2217,
    '1340_880': 25.0282,
    '1340_881': 24.8502,
    '1340_882': 24.689,
    '1340_883': 24.5465,
    '1340_884': 24.42,
    '1340_885': 24.3087,
    '1340_886': 24.2123,
    '1340_887': 24.1314,
    '1340_888': 24.0672,
    '1340_889': 24.0203,
    '1340_890': 23.9908,
    '1340_891': 23.9787,
    '1340_892': 23.9838,
    '1340_893': 24.0079,
    '1340_894': 24.0532,
    '1340_895': 24.1198,
    '1340_896': 24.2093,
    '1340_897': 24.3141,
    '1340_898': 24.4335,
    '1340_899': 24.5803,
    '1340_900': 24.7394,
    '1340_901': 24.9126,
    '1340_902': 25.0928,
    '1340_903': 25.2773,
    '1340_904': 25.436,
    '1340_905': 25.562,
    '1340_906': 25.6727,
    '1340_907': 25.7752,
    '1340_908': 25.8534,
    '1340_909': 25.9249,
    '1340_910': 26.0176,
    '1340_911': 26.1197,
    '1340_912': 26.2116,
    '1340_913': 26.2991,
    '1340_914': 26.3533,
    '1340_915': 26.3744,
    '1340_916': 26.3786,
    '1340_917': 26.3874,
    '1340_918': 26.3987,
    '1340_919': 26.404,
    '1340_920': 26.3869,
    '1340_921': 26.3309,
    '1340_922': 26.2277,
    '1340_923': 26.085,
    '1340_924': 25.9014,
    '1340_925': 25.6929,
    '1340_926': 25.4635,
    '1340_927': 25.2131,
    '1340_928': 24.9529,
    '1340_929': 24.6813,
    '1340_930': 24.4044,
    '1340_931': 24.118,
    '1340_932': 23.8225,
    '1340_933': 23.5202,
    '1340_934': 23.2132,
    '1340_935': 22.9076,
    '1340_936': 22.6032,
    '1340_937': 22.3009,
    '1340_938': 22.0014,
    '1340_939': 21.7052,
    '1340_940': 21.4142,
    '1341_788': 33.1304,
    '1341_789': 33.3028,
    '1341_790': 33.4629,
    '1341_791': 33.6272,
    '1341_792': 33.7689,
    '1341_793': 33.8668,
    '1341_794': 33.9372,
    '1341_795': 33.9826,
    '1341_796': 34.0102,
    '1341_797': 34.0371,
    '1341_798': 34.0684,
    '1341_799': 34.0942,
    '1341_800': 34.117,
    '1341_801': 34.1303,
    '1341_802': 34.1403,
    '1341_803': 34.146,
    '1341_804': 34.1435,
    '1341_805': 34.1319,
    '1341_806': 34.1161,
    '1341_807': 34.0941,
    '1341_808': 34.0665,
    '1341_809': 34.003,
    '1341_810': 33.9099,
    '1341_811': 33.8118,
    '1341_812': 33.7252,
    '1341_813': 33.625,
    '1341_814': 33.5162,
    '1341_815': 33.4119,
    '1341_816': 33.3196,
    '1341_817': 33.2498,
    '1341_818': 33.1967,
    '1341_819': 33.1588,
    '1341_820': 33.1348,
    '1341_821': 33.1211,
    '1341_822': 33.1201,
    '1341_823': 33.1299,
    '1341_824': 33.1479,
    '1341_825': 33.1677,
    '1341_826': 33.19,
    '1341_827': 33.2089,
    '1341_828': 33.2251,
    '1341_829': 33.2379,
    '1341_830': 33.2433,
    '1341_831': 33.2422,
    '1341_832': 33.2374,
    '1341_833': 33.2259,
    '1341_834': 33.2074,
    '1341_835': 33.1708,
    '1341_836': 33.1308,
    '1341_837': 33.0851,
    '1341_838': 33.0376,
    '1341_839': 32.9826,
    '1341_840': 32.9174,
    '1341_841': 32.8386,
    '1341_842': 32.7478,
    '1341_843': 32.643,
    '1341_844': 32.5324,
    '1341_845': 32.4091,
    '1341_846': 32.2658,
    '1341_847': 32.1061,
    '1341_848': 31.9374,
    '1341_849': 31.7473,
    '1341_850': 31.5578,
    '1341_851': 31.3651,
    '1341_852': 31.1689,
    '1341_853': 30.9776,
    '1341_854': 30.7862,
    '1341_855': 30.6007,
    '1341_856': 30.414,
    '1341_857': 30.2273,
    '1341_858': 30.0419,
    '1341_859': 29.856,
    '1341_860': 29.6707,
    '1341_861': 29.4827,
    '1341_862': 29.2965,
    '1341_863': 29.1076,
    '1341_864': 28.916,
    '1341_865': 28.7201,
    '1341_866': 28.5166,
    '1341_867': 28.3056,
    '1341_868': 28.0856,
    '1341_869': 27.8514,
    '1341_870': 27.5992,
    '1341_871': 27.3342,
    '1341_872': 27.0639,
    '1341_873': 26.7948,
    '1341_874': 26.5318,
    '1341_875': 26.2778,
    '1341_876': 26.0349,
    '1341_877': 25.8056,
    '1341_878': 25.5896,
    '1341_879': 25.3859,
    '1341_880': 25.1959,
    '1341_881': 25.024,
    '1341_882': 24.8674,
    '1341_883': 24.7278,
    '1341_884': 24.6042,
    '1341_885': 24.4961,
    '1341_886': 24.4031,
    '1341_887': 24.3246,
    '1341_888': 24.2624,
    '1341_889': 24.2177,
    '1341_890': 24.1911,
    '1341_891': 24.1824,
    '1341_892': 24.1924,
    '1341_893': 24.2228,
    '1341_894': 24.2755,
    '1341_895': 24.3426,
    '1341_896': 24.4334,
    '1341_897': 24.5472,
    '1341_898': 24.676,
    '1341_899': 24.8288,
    '1341_900': 24.9949,
    '1341_901': 25.1817,
    '1341_902': 25.3783,
    '1341_903': 25.5683,
    '1341_904': 25.7247,
    '1341_905': 25.8603,
    '1341_906': 25.9792,
    '1341_907': 26.0736,
    '1341_908': 26.1546,
    '1341_909': 26.2292,
    '1341_910': 26.3275,
    '1341_911': 26.4315,
    '1341_912': 26.5134,
    '1341_913': 26.5913,
    '1341_914': 26.6433,
    '1341_915': 26.6739,
    '1341_916': 26.6878,
    '1341_917': 26.6994,
    '1341_918': 26.7074,
    '1341_919': 26.6989,
    '1341_920': 26.6602,
    '1341_921': 26.5777,
    '1341_922': 26.4528,
    '1341_923': 26.2878,
    '1341_924': 26.0925,
    '1341_925': 25.875,
    '1341_926': 25.6368,
    '1341_927': 25.3794,
    '1341_928': 25.1165,
    '1341_929': 24.8438,
    '1341_930': 24.5607,
    '1341_931': 24.2672,
    '1341_932': 23.9654,
    '1341_933': 23.6629,
    '1341_934': 23.3565,
    '1341_935': 23.0501,
    '1341_936': 22.7489,
    '1341_937': 22.4523,
    '1341_938': 22.1593,
    '1341_939': 21.8702,
    '1341_940': 21.5863,
    '1342_788': 33.0233,
    '1342_789': 33.1999,
    '1342_790': 33.3637,
    '1342_791': 33.5241,
    '1342_792': 33.6655,
    '1342_793': 33.7754,
    '1342_794': 33.8573,
    '1342_795': 33.9167,
    '1342_796': 33.9559,
    '1342_797': 33.9921,
    '1342_798': 34.0271,
    '1342_799': 34.0555,
    '1342_800': 34.0797,
    '1342_801': 34.0959,
    '1342_802': 34.1075,
    '1342_803': 34.1171,
    '1342_804': 34.1202,
    '1342_805': 34.1118,
    '1342_806': 34.1054,
    '1342_807': 34.0892,
    '1342_808': 34.0633,
    '1342_809': 34.0075,
    '1342_810': 33.9194,
    '1342_811': 33.8166,
    '1342_812': 33.7094,
    '1342_813': 33.6058,
    '1342_814': 33.5013,
    '1342_815': 33.4025,
    '1342_816': 33.3152,
    '1342_817': 33.2463,
    '1342_818': 33.1943,
    '1342_819': 33.1587,
    '1342_820': 33.138,
    '1342_821': 33.1255,
    '1342_822': 33.1233,
    '1342_823': 33.1331,
    '1342_824': 33.1536,
    '1342_825': 33.1754,
    '1342_826': 33.1975,
    '1342_827': 33.2151,
    '1342_828': 33.2283,
    '1342_829': 33.2366,
    '1342_830': 33.2389,
    '1342_831': 33.2352,
    '1342_832': 33.2269,
    '1342_833': 33.2129,
    '1342_834': 33.1879,
    '1342_835': 33.154,
    '1342_836': 33.1144,
    '1342_837': 33.0753,
    '1342_838': 33.038,
    '1342_839': 32.9938,
    '1342_840': 32.9362,
    '1342_841': 32.8619,
    '1342_842': 32.7683,
    '1342_843': 32.6621,
    '1342_844': 32.5421,
    '1342_845': 32.4134,
    '1342_846': 32.2692,
    '1342_847': 32.1125,
    '1342_848': 31.9475,
    '1342_849': 31.7711,
    '1342_850': 31.591,
    '1342_851': 31.4046,
    '1342_852': 31.2161,
    '1342_853': 31.0283,
    '1342_854': 30.8413,
    '1342_855': 30.658,
    '1342_856': 30.4734,
    '1342_857': 30.289,
    '1342_858': 30.106,
    '1342_859': 29.9223,
    '1342_860': 29.7381,
    '1342_861': 29.5535,
    '1342_862': 29.3689,
    '1342_863': 29.1836,
    '1342_864': 28.9958,
    '1342_865': 28.805,
    '1342_866': 28.6066,
    '1342_867': 28.4014,
    '1342_868': 28.1892,
    '1342_869': 27.9635,
    '1342_870': 27.7152,
    '1342_871': 27.4553,
    '1342_872': 27.1914,
    '1342_873': 26.929,
    '1342_874': 26.6723,
    '1342_875': 26.4241,
    '1342_876': 26.1863,
    '1342_877': 25.9609,
    '1342_878': 25.7484,
    '1342_879': 25.5487,
    '1342_880': 25.3633,
    '1342_881': 25.1942,
    '1342_882': 25.0411,
    '1342_883': 24.9044,
    '1342_884': 24.7838,
    '1342_885': 24.679,
    '1342_886': 24.5898,
    '1342_887': 24.5136,
    '1342_888': 24.454,
    '1342_889': 24.4124,
    '1342_890': 24.39,
    '1342_891': 24.3871,
    '1342_892': 24.4049,
    '1342_893': 24.4396,
    '1342_894': 24.4981,
    '1342_895': 24.5713,
    '1342_896': 24.659,
    '1342_897': 24.7712,
    '1342_898': 24.9079,
    '1342_899': 25.0679,
    '1342_900': 25.2488,
    '1342_901': 25.4533,
    '1342_902': 25.6602,
    '1342_903': 25.8488,
    '1342_904': 26.0101,
    '1342_905': 26.1595,
    '1342_906': 26.2832,
    '1342_907': 26.3717,
    '1342_908': 26.4524,
    '1342_909': 26.5317,
    '1342_910': 26.6269,
    '1342_911': 26.7307,
    '1342_912': 26.8139,
    '1342_913': 26.8761,
    '1342_914': 26.9313,
    '1342_915': 26.9719,
    '1342_916': 26.9997,
    '1342_917': 27.0164,
    '1342_918': 27.0152,
    '1342_919': 26.9902,
    '1342_920': 26.9187,
    '1342_921': 26.8144,
    '1342_922': 26.6638,
    '1342_923': 26.483,
    '1342_924': 26.2745,
    '1342_925': 26.0485,
    '1342_926': 25.8053,
    '1342_927': 25.5441,
    '1342_928': 25.2807,
    '1342_929': 25.0042,
    '1342_930': 24.7136,
    '1342_931': 24.4121,
    '1342_932': 24.1077,
    '1342_933': 23.8043,
    '1342_934': 23.4995,
    '1342_935': 23.1931,
    '1342_936': 22.8979,
    '1342_937': 22.6095,
    '1342_938': 22.3252,
    '1342_939': 22.0447,
    '1342_940': 21.7692,
    '1343_788': 32.9124,
    '1343_789': 33.0927,
    '1343_790': 33.2691,
    '1343_791': 33.4247,
    '1343_792': 33.5663,
    '1343_793': 33.6856,
    '1343_794': 33.778,
    '1343_795': 33.8499,
    '1343_796': 33.903,
    '1343_797': 33.9486,
    '1343_798': 33.9896,
    '1343_799': 34.0234,
    '1343_800': 34.0518,
    '1343_801': 34.0711,
    '1343_802': 34.0862,
    '1343_803': 34.096,
    '1343_804': 34.0998,
    '1343_805': 34.0921,
    '1343_806': 34.0858,
    '1343_807': 34.0841,
    '1343_808': 34.0654,
    '1343_809': 34.0153,
    '1343_810': 33.9286,
    '1343_811': 33.8186,
    '1343_812': 33.6972,
    '1343_813': 33.5898,
    '1343_814': 33.4889,
    '1343_815': 33.3961,
    '1343_816': 33.3141,
    '1343_817': 33.249,
    '1343_818': 33.2,
    '1343_819': 33.164,
    '1343_820': 33.1453,
    '1343_821': 33.1275,
    '1343_822': 33.1272,
    '1343_823': 33.1388,
    '1343_824': 33.1591,
    '1343_825': 33.1799,
    '1343_826': 33.2012,
    '1343_827': 33.217,
    '1343_828': 33.2276,
    '1343_829': 33.2321,
    '1343_830': 33.2312,
    '1343_831': 33.2252,
    '1343_832': 33.2146,
    '1343_833': 33.1999,
    '1343_834': 33.1704,
    '1343_835': 33.1372,
    '1343_836': 33.103,
    '1343_837': 33.0765,
    '1343_838': 33.0523,
    '1343_839': 33.0198,
    '1343_840': 32.9719,
    '1343_841': 32.9006,
    '1343_842': 32.8049,
    '1343_843': 32.6904,
    '1343_844': 32.5685,
    '1343_845': 32.4293,
    '1343_846': 32.2815,
    '1343_847': 32.1241,
    '1343_848': 31.9607,
    '1343_849': 31.7905,
    '1343_850': 31.6165,
    '1343_851': 31.4397,
    '1343_852': 31.2611,
    '1343_853': 31.0786,
    '1343_854': 30.8953,
    '1343_855': 30.7158,
    '1343_856': 30.5335,
    '1343_857': 30.3511,
    '1343_858': 30.171,
    '1343_859': 29.9901,
    '1343_860': 29.8088,
    '1343_861': 29.627,
    '1343_862': 29.4449,
    '1343_863': 29.264,
    '1343_864': 29.0794,
    '1343_865': 28.8933,
    '1343_866': 28.6971,
    '1343_867': 28.4945,
    '1343_868': 28.2865,
    '1343_869': 28.0621,
    '1343_870': 27.8219,
    '1343_871': 27.5686,
    '1343_872': 27.3115,
    '1343_873': 27.0558,
    '1343_874': 26.8056,
    '1343_875': 26.5635,
    '1343_876': 26.3311,
    '1343_877': 26.1105,
    '1343_878': 25.9024,
    '1343_879': 25.7071,
    '1343_880': 25.5259,
    '1343_881': 25.3601,
    '1343_882': 25.2098,
    '1343_883': 25.076,
    '1343_884': 24.9579,
    '1343_885': 24.8558,
    '1343_886': 24.7692,
    '1343_887': 24.6975,
    '1343_888': 24.6424,
    '1343_889': 24.6054,
    '1343_890': 24.5891,
    '1343_891': 24.5952,
    '1343_892': 24.6217,
    '1343_893': 24.6599,
    '1343_894': 24.7183,
    '1343_895': 24.7969,
    '1343_896': 24.8924,
    '1343_897': 25.0036,
    '1343_898': 25.1414,
    '1343_899': 25.3077,
    '1343_900': 25.5043,
    '1343_901': 25.72,
    '1343_902': 25.9346,
    '1343_903': 26.1215,
    '1343_904': 26.295,
    '1343_905': 26.4513,
    '1343_906': 26.5771,
    '1343_907': 26.6642,
    '1343_908': 26.7431,
    '1343_909': 26.8316,
    '1343_910': 26.9322,
    '1343_911': 27.0357,
    '1343_912': 27.1178,
    '1343_913': 27.1742,
    '1343_914': 27.2308,
    '1343_915': 27.2768,
    '1343_916': 27.3098,
    '1343_917': 27.326,
    '1343_918': 27.314,
    '1343_919': 27.2649,
    '1343_920': 27.1722,
    '1343_921': 27.0381,
    '1343_922': 26.8663,
    '1343_923': 26.668,
    '1343_924': 26.4486,
    '1343_925': 26.2146,
    '1343_926': 25.9705,
    '1343_927': 25.704,
    '1343_928': 25.4339,
    '1343_929': 25.1536,
    '1343_930': 24.8581,
    '1343_931': 24.5535,
    '1343_932': 24.2482,
    '1343_933': 23.9453,
    '1343_934': 23.6396,
    '1343_935': 23.3379,
    '1343_936': 23.0525,
    '1343_937': 22.7745,
    '1343_938': 22.5002,
    '1343_939': 22.2291,
    '1343_940': 21.9621,
    '1344_788': 32.8009,
    '1344_789': 32.9908,
    '1344_790': 33.1752,
    '1344_791': 33.3397,
    '1344_792': 33.4765,
    '1344_793': 33.5997,
    '1344_794': 33.7035,
    '1344_795': 33.7846,
    '1344_796': 33.8486,
    '1344_797': 33.9051,
    '1344_798': 33.9551,
    '1344_799': 34.0004,
    '1344_800': 34.0362,
    '1344_801': 34.0629,
    '1344_802': 34.0792,
    '1344_803': 34.0882,
    '1344_804': 34.0916,
    '1344_805': 34.0821,
    '1344_806': 34.0719,
    '1344_807': 34.0736,
    '1344_808': 34.0641,
    '1344_809': 34.0217,
    '1344_810': 33.9381,
    '1344_811': 33.8198,
    '1344_812': 33.6904,
    '1344_813': 33.577,
    '1344_814': 33.4809,
    '1344_815': 33.395,
    '1344_816': 33.3207,
    '1344_817': 33.2615,
    '1344_818': 33.2159,
    '1344_819': 33.1808,
    '1344_820': 33.1566,
    '1344_821': 33.1396,
    '1344_822': 33.1348,
    '1344_823': 33.1417,
    '1344_824': 33.1573,
    '1344_825': 33.1775,
    '1344_826': 33.1969,
    '1344_827': 33.2116,
    '1344_828': 33.2193,
    '1344_829': 33.2214,
    '1344_830': 33.2166,
    '1344_831': 33.2069,
    '1344_832': 33.1935,
    '1344_833': 33.1761,
    '1344_834': 33.1507,
    '1344_835': 33.1243,
    '1344_836': 33.1003,
    '1344_837': 33.0876,
    '1344_838': 33.0796,
    '1344_839': 33.065,
    '1344_840': 33.0243,
    '1344_841': 32.95,
    '1344_842': 32.8505,
    '1344_843': 32.7304,
    '1344_844': 32.601,
    '1344_845': 32.4619,
    '1344_846': 32.3053,
    '1344_847': 32.1438,
    '1344_848': 31.9798,
    '1344_849': 31.8099,
    '1344_850': 31.6367,
    '1344_851': 31.4635,
    '1344_852': 31.2903,
    '1344_853': 31.1162,
    '1344_854': 30.9364,
    '1344_855': 30.7642,
    '1344_856': 30.5889,
    '1344_857': 30.4122,
    '1344_858': 30.2347,
    '1344_859': 30.0577,
    '1344_860': 29.8803,
    '1344_861': 29.7044,
    '1344_862': 29.5286,
    '1344_863': 29.352,
    '1344_864': 29.1723,
    '1344_865': 28.9871,
    '1344_866': 28.7873,
    '1344_867': 28.5888,
    '1344_868': 28.3828,
    '1344_869': 28.1573,
    '1344_870': 27.9196,
    '1344_871': 27.6733,
    '1344_872': 27.4235,
    '1344_873': 27.1749,
    '1344_874': 26.9314,
    '1344_875': 26.6956,
    '1344_876': 26.4691,
    '1344_877': 26.2538,
    '1344_878': 26.0506,
    '1344_879': 25.86,
    '1344_880': 25.683,
    '1344_881': 25.5207,
    '1344_882': 25.3738,
    '1344_883': 25.2428,
    '1344_884': 25.1276,
    '1344_885': 25.0284,
    '1344_886': 24.9454,
    '1344_887': 24.879,
    '1344_888': 24.83,
    '1344_889': 24.801,
    '1344_890': 24.7932,
    '1344_891': 24.8112,
    '1344_892': 24.8462,
    '1344_893': 24.893,
    '1344_894': 24.9501,
    '1344_895': 25.0269,
    '1344_896': 25.1257,
    '1344_897': 25.2478,
    '1344_898': 25.3911,
    '1344_899': 25.5627,
    '1344_900': 25.7604,
    '1344_901': 25.9761,
    '1344_902': 26.1918,
    '1344_903': 26.3804,
    '1344_904': 26.5665,
    '1344_905': 26.7312,
    '1344_906': 26.8624,
    '1344_907': 26.9551,
    '1344_908': 27.0369,
    '1344_909': 27.135,
    '1344_910': 27.2448,
    '1344_911': 27.3524,
    '1344_912': 27.4317,
    '1344_913': 27.4917,
    '1344_914': 27.5452,
    '1344_915': 27.5907,
    '1344_916': 27.6178,
    '1344_917': 27.6254,
    '1344_918': 27.5957,
    '1344_919': 27.5156,
    '1344_920': 27.4034,
    '1344_921': 27.2457,
    '1344_922': 27.0599,
    '1344_923': 26.85,
    '1344_924': 26.6205,
    '1344_925': 26.3779,
    '1344_926': 26.1251,
    '1344_927': 25.8577,
    '1344_928': 25.5813,
    '1344_929': 25.2924,
    '1344_930': 24.9958,
    '1344_931': 24.6957,
    '1344_932': 24.392,
    '1344_933': 24.0898,
    '1344_934': 23.792,
    '1344_935': 23.4988,
    '1344_936': 23.2207,
    '1344_937': 22.9512,
    '1344_938': 22.6865,
    '1344_939': 22.4245,
    '1344_940': 22.1663,
    '1344_941': 21.9143,
    '1344_942': 21.6726,
    '1344_943': 21.4404,
    '1344_944': 21.217,
    '1344_945': 20.9986,
    '1344_946': 20.7872,
    '1344_947': 20.6026,
    '1344_948': 20.4215,
    '1344_949': 20.24,
    '1344_950': 20.062,
    '1344_951': 19.8917,
    '1344_952': 19.7284,
    '1344_953': 19.5696,
    '1344_954': 19.4163,
    '1344_955': 19.2698,
    '1344_956': 19.1315,
    '1344_957': 18.9975,
    '1344_958': 18.8664,
    '1344_959': 18.7347,
    '1344_960': 18.6036,
    '1345_788': 32.6942,
    '1345_789': 32.8932,
    '1345_790': 33.0876,
    '1345_791': 33.2575,
    '1345_792': 33.399,
    '1345_793': 33.5273,
    '1345_794': 33.6389,
    '1345_795': 33.731,
    '1345_796': 33.8046,
    '1345_797': 33.8691,
    '1345_798': 33.9291,
    '1345_799': 33.9874,
    '1345_800': 34.0348,
    '1345_801': 34.0692,
    '1345_802': 34.0867,
    '1345_803': 34.0967,
    '1345_804': 34.0995,
    '1345_805': 34.0888,
    '1345_806': 34.0696,
    '1345_807': 34.0655,
    '1345_808': 34.0602,
    '1345_809': 34.0212,
    '1345_810': 33.9385,
    '1345_811': 33.8217,
    '1345_812': 33.6897,
    '1345_813': 33.5702,
    '1345_814': 33.4767,
    '1345_815': 33.3968,
    '1345_816': 33.3292,
    '1345_817': 33.275,
    '1345_818': 33.2332,
    '1345_819': 33.1977,
    '1345_820': 33.1698,
    '1345_821': 33.1514,
    '1345_822': 33.1415,
    '1345_823': 33.142,
    '1345_824': 33.1513,
    '1345_825': 33.1705,
    '1345_826': 33.186,
    '1345_827': 33.1992,
    '1345_828': 33.2036,
    '1345_829': 33.2051,
    '1345_830': 33.1964,
    '1345_831': 33.1845,
    '1345_832': 33.1701,
    '1345_833': 33.1535,
    '1345_834': 33.1339,
    '1345_835': 33.1172,
    '1345_836': 33.1068,
    '1345_837': 33.112,
    '1345_838': 33.1244,
    '1345_839': 33.1227,
    '1345_840': 33.0872,
    '1345_841': 33.0107,
    '1345_842': 32.9004,
    '1345_843': 32.7705,
    '1345_844': 32.6396,
    '1345_845': 32.4986,
    '1345_846': 32.3464,
    '1345_847': 32.1784,
    '1345_848': 32.0084,
    '1345_849': 31.8335,
    '1345_850': 31.6561,
    '1345_851': 31.4815,
    '1345_852': 31.3065,
    '1345_853': 31.1299,
    '1345_854': 30.9597,
    '1345_855': 30.8028,
    '1345_856': 30.635,
    '1345_857': 30.4645,
    '1345_858': 30.2892,
    '1345_859': 30.1182,
    '1345_860': 29.9449,
    '1345_861': 29.7755,
    '1345_862': 29.6053,
    '1345_863': 29.4333,
    '1345_864': 29.2579,
    '1345_865': 29.0768,
    '1345_866': 28.8665,
    '1345_867': 28.6726,
    '1345_868': 28.4652,
    '1345_869': 28.2437,
    '1345_870': 28.0117,
    '1345_871': 27.772,
    '1345_872': 27.529,
    '1345_873': 27.2872,
    '1345_874': 27.0503,
    '1345_875': 26.821,
    '1345_876': 26.6005,
    '1345_877': 26.391,
    '1345_878': 26.1931,
    '1345_879': 26.0075,
    '1345_880': 25.8351,
    '1345_881': 25.6768,
    '1345_882': 25.5334,
    '1345_883': 25.4058,
    '1345_884': 25.2939,
    '1345_885': 25.1985,
    '1345_886': 25.1204,
    '1345_887': 25.0604,
    '1345_888': 25.0205,
    '1345_889': 25.0003,
    '1345_890': 25.0044,
    '1345_891': 25.0307,
    '1345_892': 25.0727,
    '1345_893': 25.1287,
    '1345_894': 25.1947,
    '1345_895': 25.2729,
    '1345_896': 25.3708,
    '1345_897': 25.503,
    '1345_898': 25.6584,
    '1345_899': 25.8339,
    '1345_900': 26.0304,
    '1345_901': 26.2372,
    '1345_902': 26.4406,
    '1345_903': 26.6333,
    '1345_904': 26.8212,
    '1345_905': 26.9953,
    '1345_906': 27.1378,
    '1345_907': 27.2402,
    '1345_908': 27.3319,
    '1345_909': 27.4447,
    '1345_910': 27.563,
    '1345_911': 27.6739,
    '1345_912': 27.7575,
    '1345_913': 27.8144,
    '1345_914': 27.8625,
    '1345_915': 27.9052,
    '1345_916': 27.9216,
    '1345_917': 27.9108,
    '1345_918': 27.8496,
    '1345_919': 27.7544,
    '1345_920': 27.6121,
    '1345_921': 27.438,
    '1345_922': 27.2435,
    '1345_923': 27.0291,
    '1345_924': 26.7956,
    '1345_925': 26.5436,
    '1345_926': 26.2789,
    '1345_927': 26.0087,
    '1345_928': 25.7317,
    '1345_929': 25.4406,
    '1345_930': 25.1405,
    '1345_931': 24.8419,
    '1345_932': 24.5393,
    '1345_933': 24.244,
    '1345_934': 23.953,
    '1345_935': 23.6672,
    '1345_936': 23.4003,
    '1345_937': 23.1409,
    '1345_938': 22.8852,
    '1345_939': 22.632,
    '1345_940': 22.3822,
    '1345_941': 22.138,
    '1345_942': 21.9057,
    '1345_943': 21.6818,
    '1345_944': 21.4655,
    '1345_945': 21.2537,
    '1345_946': 21.0487,
    '1345_947': 20.8611,
    '1345_948': 20.6814,
    '1345_949': 20.5005,
    '1345_950': 20.3238,
    '1345_951': 20.1532,
    '1345_952': 19.9872,
    '1345_953': 19.8272,
    '1345_954': 19.6726,
    '1345_955': 19.5243,
    '1345_956': 19.3865,
    '1345_957': 19.2531,
    '1345_958': 19.1223,
    '1345_959': 18.9911,
    '1345_960': 18.8578,
    '1346_788': 32.597,
    '1346_789': 32.8002,
    '1346_790': 32.9981,
    '1346_791': 33.1802,
    '1346_792': 33.3373,
    '1346_793': 33.4677,
    '1346_794': 33.588,
    '1346_795': 33.6906,
    '1346_796': 33.7735,
    '1346_797': 33.8464,
    '1346_798': 33.9153,
    '1346_799': 33.9866,
    '1346_800': 34.0488,
    '1346_801': 34.093,
    '1346_802': 34.1165,
    '1346_803': 34.1257,
    '1346_804': 34.1274,
    '1346_805': 34.1136,
    '1346_806': 34.0855,
    '1346_807': 34.068,
    '1346_808': 34.0551,
    '1346_809': 34.0152,
    '1346_810': 33.9324,
    '1346_811': 33.8194,
    '1346_812': 33.6922,
    '1346_813': 33.5713,
    '1346_814': 33.478,
    '1346_815': 33.4012,
    '1346_816': 33.337,
    '1346_817': 33.2846,
    '1346_818': 33.2425,
    '1346_819': 33.2078,
    '1346_820': 33.18,
    '1346_821': 33.1613,
    '1346_822': 33.1492,
    '1346_823': 33.1451,
    '1346_824': 33.1482,
    '1346_825': 33.1561,
    '1346_826': 33.1663,
    '1346_827': 33.1735,
    '1346_828': 33.1776,
    '1346_829': 33.1787,
    '1346_830': 33.1728,
    '1346_831': 33.1626,
    '1346_832': 33.1499,
    '1346_833': 33.1366,
    '1346_834': 33.1237,
    '1346_835': 33.1178,
    '1346_836': 33.1243,
    '1346_837': 33.15,
    '1346_838': 33.1781,
    '1346_839': 33.1808,
    '1346_840': 33.1444,
    '1346_841': 33.0686,
    '1346_842': 32.9544,
    '1346_843': 32.8143,
    '1346_844': 32.6796,
    '1346_845': 32.5428,
    '1346_846': 32.3927,
    '1346_847': 32.2285,
    '1346_848': 32.0472,
    '1346_849': 31.8657,
    '1346_850': 31.6834,
    '1346_851': 31.5048,
    '1346_852': 31.3291,
    '1346_853': 31.1567,
    '1346_854': 30.99,
    '1346_855': 30.8303,
    '1346_856': 30.6656,
    '1346_857': 30.4975,
    '1346_858': 30.327,
    '1346_859': 30.1626,
    '1346_860': 29.997,
    '1346_861': 29.8311,
    '1346_862': 29.663,
    '1346_863': 29.4917,
    '1346_864': 29.3192,
    '1346_865': 29.1376,
    '1346_866': 28.9389,
    '1346_867': 28.7427,
    '1346_868': 28.5391,
    '1346_869': 28.3247,
    '1346_870': 28.1002,
    '1346_871': 27.8663,
    '1346_872': 27.629,
    '1346_873': 27.3934,
    '1346_874': 27.163,
    '1346_875': 26.9403,
    '1346_876': 26.7264,
    '1346_877': 26.5228,
    '1346_878': 26.3308,
    '1346_879': 26.1506,
    '1346_880': 25.9833,
    '1346_881': 25.8296,
    '1346_882': 25.6905,
    '1346_883': 25.5667,
    '1346_884': 25.4586,
    '1346_885': 25.3677,
    '1346_886': 25.2955,
    '1346_887': 25.2442,
    '1346_888': 25.2119,
    '1346_889': 25.2035,
    '1346_890': 25.2156,
    '1346_891': 25.2456,
    '1346_892': 25.2945,
    '1346_893': 25.3573,
    '1346_894': 25.4342,
    '1346_895': 25.5183,
    '1346_896': 25.6223,
    '1346_897': 25.758,
    '1346_898': 25.9292,
    '1346_899': 26.1195,
    '1346_900': 26.3224,
    '1346_901': 26.5249,
    '1346_902': 26.7098,
    '1346_903': 26.9004,
    '1346_904': 27.0737,
    '1346_905': 27.2485,
    '1346_906': 27.3988,
    '1346_907': 27.5119,
    '1346_908': 27.6258,
    '1346_909': 27.7544,
    '1346_910': 27.8792,
    '1346_911': 27.9921,
    '1346_912': 28.08,
    '1346_913': 28.1385,
    '1346_914': 28.1798,
    '1346_915': 28.213,
    '1346_916': 28.2126,
    '1346_917': 28.17,
    '1346_918': 28.0891,
    '1346_919': 27.9688,
    '1346_920': 27.8081,
    '1346_921': 27.6199,
    '1346_922': 27.418,
    '1346_923': 27.2009,
    '1346_924': 26.9632,
    '1346_925': 26.7071,
    '1346_926': 26.4398,
    '1346_927': 26.1669,
    '1346_928': 25.8853,
    '1346_929': 25.5902,
    '1346_930': 25.2907,
    '1346_931': 24.9918,
    '1346_932': 24.6941,
    '1346_933': 24.4066,
    '1346_934': 24.1233,
    '1346_935': 23.8522,
    '1346_936': 23.5967,
    '1346_937': 23.3466,
    '1346_938': 23.0973,
    '1346_939': 22.8521,
    '1346_940': 22.6111,
    '1346_941': 22.3765,
    '1346_942': 22.1518,
    '1346_943': 21.9357,
    '1346_944': 21.7255,
    '1346_945': 21.5175,
    '1346_946': 21.315,
    '1346_947': 21.1262,
    '1346_948': 20.9432,
    '1346_949': 20.7629,
    '1346_950': 20.5864,
    '1346_951': 20.4155,
    '1346_952': 20.2494,
    '1346_953': 20.0897,
    '1346_954': 19.9363,
    '1346_955': 19.7897,
    '1346_956': 19.6504,
    '1346_957': 19.5158,
    '1346_958': 19.3834,
    '1346_959': 19.2505,
    '1346_960': 19.1143,
    '1347_788': 32.5196,
    '1347_789': 32.7223,
    '1347_790': 32.9211,
    '1347_791': 33.1115,
    '1347_792': 33.2778,
    '1347_793': 33.417,
    '1347_794': 33.5467,
    '1347_795': 33.6583,
    '1347_796': 33.7501,
    '1347_797': 33.833,
    '1347_798': 33.912,
    '1347_799': 33.9933,
    '1347_800': 34.0686,
    '1347_801': 34.125,
    '1347_802': 34.155,
    '1347_803': 34.1656,
    '1347_804': 34.1682,
    '1347_805': 34.1509,
    '1347_806': 34.1181,
    '1347_807': 34.0838,
    '1347_808': 34.0624,
    '1347_809': 34.0137,
    '1347_810': 33.9307,
    '1347_811': 33.8234,
    '1347_812': 33.7076,
    '1347_813': 33.5849,
    '1347_814': 33.4843,
    '1347_815': 33.4086,
    '1347_816': 33.3464,
    '1347_817': 33.2956,
    '1347_818': 33.2541,
    '1347_819': 33.222,
    '1347_820': 33.1948,
    '1347_821': 33.1777,
    '1347_822': 33.1639,
    '1347_823': 33.1559,
    '1347_824': 33.1543,
    '1347_825': 33.1522,
    '1347_826': 33.1561,
    '1347_827': 33.1549,
    '1347_828': 33.157,
    '1347_829': 33.1582,
    '1347_830': 33.1539,
    '1347_831': 33.1464,
    '1347_832': 33.1362,
    '1347_833': 33.1265,
    '1347_834': 33.1215,
    '1347_835': 33.1248,
    '1347_836': 33.1498,
    '1347_837': 33.1932,
    '1347_838': 33.2305,
    '1347_839': 33.2302,
    '1347_840': 33.1867,
    '1347_841': 33.1071,
    '1347_842': 32.9939,
    '1347_843': 32.8649,
    '1347_844': 32.733,
    '1347_845': 32.5979,
    '1347_846': 32.4484,
    '1347_847': 32.2804,
    '1347_848': 32.0973,
    '1347_849': 31.9083,
    '1347_850': 31.7142,
    '1347_851': 31.5309,
    '1347_852': 31.3546,
    '1347_853': 31.185,
    '1347_854': 31.0188,
    '1347_855': 30.8569,
    '1347_856': 30.6938,
    '1347_857': 30.53,
    '1347_858': 30.3668,
    '1347_859': 30.2057,
    '1347_860': 30.044,
    '1347_861': 29.8806,
    '1347_862': 29.7143,
    '1347_863': 29.5454,
    '1347_864': 29.374,
    '1347_865': 29.1945,
    '1347_866': 29.0014,
    '1347_867': 28.8083,
    '1347_868': 28.6094,
    '1347_869': 28.4014,
    '1347_870': 28.1844,
    '1347_871': 27.9542,
    '1347_872': 27.7219,
    '1347_873': 27.4926,
    '1347_874': 27.2691,
    '1347_875': 27.0533,
    '1347_876': 26.8463,
    '1347_877': 26.6494,
    '1347_878': 26.4637,
    '1347_879': 26.2896,
    '1347_880': 26.128,
    '1347_881': 25.9798,
    '1347_882': 25.8461,
    '1347_883': 25.7266,
    '1347_884': 25.6229,
    '1347_885': 25.5367,
    '1347_886': 25.4727,
    '1347_887': 25.4263,
    '1347_888': 25.4033,
    '1347_889': 25.4066,
    '1347_890': 25.4299,
    '1347_891': 25.4635,
    '1347_892': 25.5118,
    '1347_893': 25.5812,
    '1347_894': 25.6634,
    '1347_895': 25.7615,
    '1347_896': 25.8752,
    '1347_897': 26.0173,
    '1347_898': 26.2038,
    '1347_899': 26.4121,
    '1347_900': 26.6262,
    '1347_901': 26.8191,
    '1347_902': 26.989,
    '1347_903': 27.1712,
    '1347_904': 27.3417,
    '1347_905': 27.5039,
    '1347_906': 27.6532,
    '1347_907': 27.7758,
    '1347_908': 27.9101,
    '1347_909': 28.0515,
    '1347_910': 28.1797,
    '1347_911': 28.2958,
    '1347_912': 28.3869,
    '1347_913': 28.4472,
    '1347_914': 28.4892,
    '1347_915': 28.5086,
    '1347_916': 28.4848,
    '1347_917': 28.4193,
    '1347_918': 28.3081,
    '1347_919': 28.165,
    '1347_920': 27.9916,
    '1347_921': 27.8001,
    '1347_922': 27.5915,
    '1347_923': 27.3639,
    '1347_924': 27.1193,
    '1347_925': 26.8657,
    '1347_926': 26.6008,
    '1347_927': 26.3241,
    '1347_928': 26.0351,
    '1347_929': 25.738,
    '1347_930': 25.4408,
    '1347_931': 25.1452,
    '1347_932': 24.8561,
    '1347_933': 24.5762,
    '1347_934': 24.3063,
    '1347_935': 24.052,
    '1347_936': 23.8078,
    '1347_937': 23.5641,
    '1347_938': 23.3201,
    '1347_939': 23.0806,
    '1347_940': 22.8468,
    '1347_941': 22.6203,
    '1347_942': 22.4028,
    '1347_943': 22.1944,
    '1347_944': 21.9918,
    '1347_945': 21.7889,
    '1347_946': 21.5917,
    '1347_947': 21.399,
    '1347_948': 21.212,
    '1347_949': 21.0306,
    '1347_950': 20.8537,
    '1347_951': 20.6839,
    '1347_952': 20.5183,
    '1347_953': 20.3591,
    '1347_954': 20.2075,
    '1347_955': 20.0615,
    '1347_956': 19.9202,
    '1347_957': 19.7831,
    '1347_958': 19.6489,
    '1347_959': 19.5161,
    '1347_960': 19.3782,
    '1348_788': 32.4443,
    '1348_789': 32.6526,
    '1348_790': 32.8558,
    '1348_791': 33.0498,
    '1348_792': 33.2235,
    '1348_793': 33.3726,
    '1348_794': 33.5138,
    '1348_795': 33.633,
    '1348_796': 33.7319,
    '1348_797': 33.8235,
    '1348_798': 33.914,
    '1348_799': 34.005,
    '1348_800': 34.093,
    '1348_801': 34.1637,
    '1348_802': 34.1989,
    '1348_803': 34.2102,
    '1348_804': 34.2129,
    '1348_805': 34.197,
    '1348_806': 34.1622,
    '1348_807': 34.1135,
    '1348_808': 34.0696,
    '1348_809': 34.0109,
    '1348_810': 33.9303,
    '1348_811': 33.8364,
    '1348_812': 33.7291,
    '1348_813': 33.6055,
    '1348_814': 33.4964,
    '1348_815': 33.4196,
    '1348_816': 33.3605,
    '1348_817': 33.3146,
    '1348_818': 33.2788,
    '1348_819': 33.2517,
    '1348_820': 33.2294,
    '1348_821': 33.2109,
    '1348_822': 33.196,
    '1348_823': 33.1864,
    '1348_824': 33.1828,
    '1348_825': 33.1819,
    '1348_826': 33.1735,
    '1348_827': 33.1647,
    '1348_828': 33.1583,
    '1348_829': 33.1543,
    '1348_830': 33.1492,
    '1348_831': 33.1419,
    '1348_832': 33.1346,
    '1348_833': 33.1276,
    '1348_834': 33.1256,
    '1348_835': 33.1379,
    '1348_836': 33.176,
    '1348_837': 33.2339,
    '1348_838': 33.2738,
    '1348_839': 33.2699,
    '1348_840': 33.2181,
    '1348_841': 33.1339,
    '1348_842': 33.0342,
    '1348_843': 32.922,
    '1348_844': 32.7956,
    '1348_845': 32.6568,
    '1348_846': 32.4993,
    '1348_847': 32.3287,
    '1348_848': 32.1426,
    '1348_849': 31.9469,
    '1348_850': 31.7518,
    '1348_851': 31.5599,
    '1348_852': 31.3816,
    '1348_853': 31.2106,
    '1348_854': 31.0446,
    '1348_855': 30.8828,
    '1348_856': 30.7235,
    '1348_857': 30.5652,
    '1348_858': 30.4074,
    '1348_859': 30.2492,
    '1348_860': 30.0899,
    '1348_861': 29.9287,
    '1348_862': 29.765,
    '1348_863': 29.5978,
    '1348_864': 29.4274,
    '1348_865': 29.2505,
    '1348_866': 29.0645,
    '1348_867': 28.874,
    '1348_868': 28.6771,
    '1348_869': 28.4711,
    '1348_870': 28.2556,
    '1348_871': 28.0316,
    '1348_872': 27.8063,
    '1348_873': 27.5844,
    '1348_874': 27.3687,
    '1348_875': 27.1605,
    '1348_876': 26.9608,
    '1348_877': 26.7711,
    '1348_878': 26.5923,
    '1348_879': 26.4253,
    '1348_880': 26.2708,
    '1348_881': 26.1291,
    '1348_882': 26.005,
    '1348_883': 25.8906,
    '1348_884': 25.7919,
    '1348_885': 25.7102,
    '1348_886': 25.65,
    '1348_887': 25.6092,
    '1348_888': 25.5913,
    '1348_889': 25.6,
    '1348_890': 25.6318,
    '1348_891': 25.6775,
    '1348_892': 25.7304,
    '1348_893': 25.8035,
    '1348_894': 25.8922,
    '1348_895': 26.0009,
    '1348_896': 26.1266,
    '1348_897': 26.275,
    '1348_898': 26.4742,
    '1348_899': 26.6971,
    '1348_900': 26.921,
    '1348_901': 27.1076,
    '1348_902': 27.2736,
    '1348_903': 27.4393,
    '1348_904': 27.6192,
    '1348_905': 27.7858,
    '1348_906': 27.9235,
    '1348_907': 28.0582,
    '1348_908': 28.2067,
    '1348_909': 28.3512,
    '1348_910': 28.4715,
    '1348_911': 28.5849,
    '1348_912': 28.678,
    '1348_913': 28.7426,
    '1348_914': 28.7828,
    '1348_915': 28.7852,
    '1348_916': 28.7397,
    '1348_917': 28.6488,
    '1348_918': 28.5147,
    '1348_919': 28.3515,
    '1348_920': 28.168,
    '1348_921': 27.9728,
    '1348_922': 27.761,
    '1348_923': 27.5258,
    '1348_924': 27.2731,
    '1348_925': 27.0154,
    '1348_926': 26.752,
    '1348_927': 26.4735,
    '1348_928': 26.1814,
    '1348_929': 25.8881,
    '1348_930': 25.5923,
    '1348_931': 25.3023,
    '1348_932': 25.0228,
    '1348_933': 24.7555,
    '1348_934': 24.5052,
    '1348_935': 24.2675,
    '1348_936': 24.0307,
    '1348_937': 23.7911,
    '1348_938': 23.5515,
    '1348_939': 23.314,
    '1348_940': 23.085,
    '1348_941': 22.8644,
    '1348_942': 22.6529,
    '1348_943': 22.45,
    '1348_944': 22.2523,
    '1348_945': 22.0565,
    '1348_946': 21.864,
    '1348_947': 21.6732,
    '1348_948': 21.4866,
    '1348_949': 21.3058,
    '1348_950': 21.1315,
    '1348_951': 20.9631,
    '1348_952': 20.7994,
    '1348_953': 20.6399,
    '1348_954': 20.4862,
    '1348_955': 20.3379,
    '1348_956': 20.1935,
    '1348_957': 20.0519,
    '1348_958': 19.9128,
    '1348_959': 19.7749,
    '1348_960': 19.6407,
    '1349_788': 32.3579,
    '1349_789': 32.5816,
    '1349_790': 32.7937,
    '1349_791': 32.9905,
    '1349_792': 33.1708,
    '1349_793': 33.3298,
    '1349_794': 33.4831,
    '1349_795': 33.611,
    '1349_796': 33.7216,
    '1349_797': 33.8208,
    '1349_798': 33.9206,
    '1349_799': 34.0181,
    '1349_800': 34.1139,
    '1349_801': 34.1944,
    '1349_802': 34.2371,
    '1349_803': 34.2521,
    '1349_804': 34.2542,
    '1349_805': 34.2418,
    '1349_806': 34.2069,
    '1349_807': 34.151,
    '1349_808': 34.0844,
    '1349_809': 34.0142,
    '1349_810': 33.9346,
    '1349_811': 33.8488,
    '1349_812': 33.7488,
    '1349_813': 33.6282,
    '1349_814': 33.5121,
    '1349_815': 33.4323,
    '1349_816': 33.3774,
    '1349_817': 33.3372,
    '1349_818': 33.3083,
    '1349_819': 33.2876,
    '1349_820': 33.2707,
    '1349_821': 33.2512,
    '1349_822': 33.2337,
    '1349_823': 33.2199,
    '1349_824': 33.2101,
    '1349_825': 33.202,
    '1349_826': 33.1921,
    '1349_827': 33.1816,
    '1349_828': 33.1694,
    '1349_829': 33.1624,
    '1349_830': 33.1565,
    '1349_831': 33.1506,
    '1349_832': 33.1435,
    '1349_833': 33.1396,
    '1349_834': 33.1418,
    '1349_835': 33.1591,
    '1349_836': 33.2005,
    '1349_837': 33.2619,
    '1349_838': 33.3011,
    '1349_839': 33.2976,
    '1349_840': 33.2466,
    '1349_841': 33.1725,
    '1349_842': 33.0874,
    '1349_843': 32.9866,
    '1349_844': 32.863,
    '1349_845': 32.717,
    '1349_846': 32.5572,
    '1349_847': 32.386,
    '1349_848': 32.1976,
    '1349_849': 31.9894,
    '1349_850': 31.7847,
    '1349_851': 31.596,
    '1349_852': 31.4109,
    '1349_853': 31.2379,
    '1349_854': 31.0716,
    '1349_855': 30.9104,
    '1349_856': 30.7556,
    '1349_857': 30.6023,
    '1349_858': 30.448,
    '1349_859': 30.2919,
    '1349_860': 30.1345,
    '1349_861': 29.9752,
    '1349_862': 29.8134,
    '1349_863': 29.6482,
    '1349_864': 29.4799,
    '1349_865': 29.3066,
    '1349_866': 29.1257,
    '1349_867': 28.9385,
    '1349_868': 28.7441,
    '1349_869': 28.5392,
    '1349_870': 28.3236,
    '1349_871': 28.1027,
    '1349_872': 27.8838,
    '1349_873': 27.67,
    '1349_874': 27.4625,
    '1349_875': 27.2623,
    '1349_876': 27.0704,
    '1349_877': 26.8884,
    '1349_878': 26.7176,
    '1349_879': 26.559,
    '1349_880': 26.4165,
    '1349_881': 26.2812,
    '1349_882': 26.1599,
    '1349_883': 26.0514,
    '1349_884': 25.9595,
    '1349_885': 25.8839,
    '1349_886': 25.8275,
    '1349_887': 25.7931,
    '1349_888': 25.7799,
    '1349_889': 25.7941,
    '1349_890': 25.8341,
    '1349_891': 25.889,
    '1349_892': 25.9481,
    '1349_893': 26.0238,
    '1349_894': 26.1182,
    '1349_895': 26.2328,
    '1349_896': 26.3726,
    '1349_897': 26.5326,
    '1349_898': 26.734,
    '1349_899': 26.9645,
    '1349_900': 27.1914,
    '1349_901': 27.3815,
    '1349_902': 27.5527,
    '1349_903': 27.7104,
    '1349_904': 27.8912,
    '1349_905': 28.0624,
    '1349_906': 28.2063,
    '1349_907': 28.3544,
    '1349_908': 28.5113,
    '1349_909': 28.6511,
    '1349_910': 28.7643,
    '1349_911': 28.873,
    '1349_912': 28.9657,
    '1349_913': 29.0304,
    '1349_914': 29.0635,
    '1349_915': 29.0534,
    '1349_916': 28.9853,
    '1349_917': 28.8678,
    '1349_918': 28.7159,
    '1349_919': 28.5401,
    '1349_920': 28.3453,
    '1349_921': 28.1365,
    '1349_922': 27.9159,
    '1349_923': 27.6751,
    '1349_924': 27.4209,
    '1349_925': 27.1594,
    '1349_926': 26.892,
    '1349_927': 26.6094,
    '1349_928': 26.3224,
    '1349_929': 26.0338,
    '1349_930': 25.7425,
    '1349_931': 25.4611,
    '1349_932': 25.1945,
    '1349_933': 24.9463,
    '1349_934': 24.7188,
    '1349_935': 24.4946,
    '1349_936': 24.2607,
    '1349_937': 24.0229,
    '1349_938': 23.7841,
    '1349_939': 23.5478,
    '1349_940': 23.3217,
    '1349_941': 23.1056,
    '1349_942': 22.8996,
    '1349_943': 22.7022,
    '1349_944': 22.5104,
    '1349_945': 22.321,
    '1349_946': 22.1332,
    '1349_947': 21.9464,
    '1349_948': 21.762,
    '1349_949': 21.5834,
    '1349_950': 21.4124,
    '1349_951': 21.2467,
    '1349_952': 21.0854,
    '1349_953': 20.9248,
    '1349_954': 20.7688,
    '1349_955': 20.6187,
    '1349_956': 20.4713,
    '1349_957': 20.3264,
    '1349_958': 20.1836,
    '1349_959': 20.043,
    '1349_960': 19.9146,
    '1350_788': 32.284,
    '1350_789': 32.5119,
    '1350_790': 32.7296,
    '1350_791': 32.9277,
    '1350_792': 33.1155,
    '1350_793': 33.2872,
    '1350_794': 33.4562,
    '1350_795': 33.5977,
    '1350_796': 33.7177,
    '1350_797': 33.8272,
    '1350_798': 33.9374,
    '1350_799': 34.0413,
    '1350_800': 34.1407,
    '1350_801': 34.2214,
    '1350_802': 34.2662,
    '1350_803': 34.2846,
    '1350_804': 34.2854,
    '1350_805': 34.2749,
    '1350_806': 34.2452,
    '1350_807': 34.1903,
    '1350_808': 34.1121,
    '1350_809': 34.035,
    '1350_810': 33.9539,
    '1350_811': 33.8689,
    '1350_812': 33.7657,
    '1350_813': 33.6481,
    '1350_814': 33.5313,
    '1350_815': 33.4493,
    '1350_816': 33.3963,
    '1350_817': 33.361,
    '1350_818': 33.338,
    '1350_819': 33.3223,
    '1350_820': 33.3077,
    '1350_821': 33.2899,
    '1350_822': 33.2717,
    '1350_823': 33.2536,
    '1350_824': 33.2373,
    '1350_825': 33.2232,
    '1350_826': 33.2107,
    '1350_827': 33.198,
    '1350_828': 33.1863,
    '1350_829': 33.1798,
    '1350_830': 33.1755,
    '1350_831': 33.1702,
    '1350_832': 33.1664,
    '1350_833': 33.1678,
    '1350_834': 33.1698,
    '1350_835': 33.1858,
    '1350_836': 33.2229,
    '1350_837': 33.2768,
    '1350_838': 33.3171,
    '1350_839': 33.3193,
    '1350_840': 33.281,
    '1350_841': 33.2235,
    '1350_842': 33.1534,
    '1350_843': 33.0595,
    '1350_844': 32.9293,
    '1350_845': 32.7741,
    '1350_846': 32.6069,
    '1350_847': 32.4343,
    '1350_848': 32.2415,
    '1350_849': 32.0291,
    '1350_850': 31.8222,
    '1350_851': 31.6303,
    '1350_852': 31.4509,
    '1350_853': 31.2721,
    '1350_854': 31.106,
    '1350_855': 30.9474,
    '1350_856': 30.7941,
    '1350_857': 30.6429,
    '1350_858': 30.4899,
    '1350_859': 30.3343,
    '1350_860': 30.1779,
    '1350_861': 30.0199,
    '1350_862': 29.8599,
    '1350_863': 29.6968,
    '1350_864': 29.5314,
    '1350_865': 29.3623,
    '1350_866': 29.1858,
    '1350_867': 29.0026,
    '1350_868': 28.8123,
    '1350_869': 28.6101,
    '1350_870': 28.3922,
    '1350_871': 28.1706,
    '1350_872': 27.958,
    '1350_873': 27.7526,
    '1350_874': 27.5535,
    '1350_875': 27.3616,
    '1350_876': 27.1778,
    '1350_877': 27.004,
    '1350_878': 26.8421,
    '1350_879': 26.6963,
    '1350_880': 26.5564,
    '1350_881': 26.4272,
    '1350_882': 26.313,
    '1350_883': 26.2127,
    '1350_884': 26.1245,
    '1350_885': 26.0552,
    '1350_886': 26.0055,
    '1350_887': 25.9744,
    '1350_888': 25.9671,
    '1350_889': 25.9883,
    '1350_890': 26.0351,
    '1350_891': 26.0985,
    '1350_892': 26.1651,
    '1350_893': 26.238,
    '1350_894': 26.3356,
    '1350_895': 26.4598,
    '1350_896': 26.6115,
    '1350_897': 26.7879,
    '1350_898': 26.9912,
    '1350_899': 27.221,
    '1350_900': 27.4428,
    '1350_901': 27.638,
    '1350_902': 27.818,
    '1350_903': 27.9799,
    '1350_904': 28.1537,
    '1350_905': 28.3282,
    '1350_906': 28.4855,
    '1350_907': 28.651,
    '1350_908': 28.8113,
    '1350_909': 28.9445,
    '1350_910': 29.0542,
    '1350_911': 29.1646,
    '1350_912': 29.2531,
    '1350_913': 29.3071,
    '1350_914': 29.3213,
    '1350_915': 29.2792,
    '1350_916': 29.1983,
    '1350_917': 29.0699,
    '1350_918': 28.9102,
    '1350_919': 28.7209,
    '1350_920': 28.5176,
    '1350_921': 28.2967,
    '1350_922': 28.0609,
    '1350_923': 27.8131,
    '1350_924': 27.5596,
    '1350_925': 27.2967,
    '1350_926': 27.0242,
    '1350_927': 26.743,
    '1350_928': 26.4569,
    '1350_929': 26.1736,
    '1350_930': 25.8918,
    '1350_931': 25.6231,
    '1350_932': 25.3753,
    '1350_933': 25.1499,
    '1350_934': 24.938,
    '1350_935': 24.7185,
    '1350_936': 24.4908,
    '1350_937': 24.2547,
    '1350_938': 24.0155,
    '1350_939': 23.7809,
    '1350_940': 23.5547,
    '1350_941': 23.3426,
    '1350_942': 23.1421,
    '1350_943': 22.951,
    '1350_944': 22.7665,
    '1350_945': 22.5843,
    '1350_946': 22.4011,
    '1350_947': 22.218,
    '1350_948': 22.0373,
    '1350_949': 21.8617,
    '1350_950': 21.6929,
    '1350_951': 21.5291,
    '1350_952': 21.3687,
    '1350_953': 21.2096,
    '1350_954': 21.0534,
    '1350_955': 20.902,
    '1350_956': 20.7541,
    '1350_957': 20.6092,
    '1350_958': 20.4667,
    '1350_959': 20.3238,
    '1350_960': 20.1969,
    '1351_788': 32.1579,
    '1351_789': 32.3964,
    '1351_790': 32.6256,
    '1351_791': 32.8445,
    '1351_792': 33.0525,
    '1351_793': 33.2435,
    '1351_794': 33.4309,
    '1351_795': 33.5922,
    '1351_796': 33.7237,
    '1351_797': 33.8428,
    '1351_798': 33.9598,
    '1351_799': 34.0663,
    '1351_800': 34.1625,
    '1351_801': 34.2407,
    '1351_802': 34.2836,
    '1351_803': 34.3014,
    '1351_804': 34.3058,
    '1351_805': 34.3011,
    '1351_806': 34.2728,
    '1351_807': 34.2201,
    '1351_808': 34.1401,
    '1351_809': 34.0599,
    '1351_810': 33.9789,
    '1351_811': 33.8913,
    '1351_812': 33.7858,
    '1351_813': 33.6704,
    '1351_814': 33.5538,
    '1351_815': 33.4717,
    '1351_816': 33.4174,
    '1351_817': 33.3859,
    '1351_818': 33.3674,
    '1351_819': 33.3567,
    '1351_820': 33.3447,
    '1351_821': 33.3284,
    '1351_822': 33.3108,
    '1351_823': 33.2898,
    '1351_824': 33.2687,
    '1351_825': 33.2512,
    '1351_826': 33.2369,
    '1351_827': 33.223,
    '1351_828': 33.2138,
    '1351_829': 33.2081,
    '1351_830': 33.2048,
    '1351_831': 33.2023,
    '1351_832': 33.2043,
    '1351_833': 33.2112,
    '1351_834': 33.2111,
    '1351_835': 33.216,
    '1351_836': 33.2442,
    '1351_837': 33.2892,
    '1351_838': 33.3255,
    '1351_839': 33.332,
    '1351_840': 33.3079,
    '1351_841': 33.2674,
    '1351_842': 33.2111,
    '1351_843': 33.1204,
    '1351_844': 32.9854,
    '1351_845': 32.8264,
    '1351_846': 32.6584,
    '1351_847': 32.4812,
    '1351_848': 32.2789,
    '1351_849': 32.0647,
    '1351_850': 31.8605,
    '1351_851': 31.6697,
    '1351_852': 31.4913,
    '1351_853': 31.3188,
    '1351_854': 31.1477,
    '1351_855': 30.9889,
    '1351_856': 30.8358,
    '1351_857': 30.6855,
    '1351_858': 30.5315,
    '1351_859': 30.3749,
    '1351_860': 30.2195,
    '1351_861': 30.0625,
    '1351_862': 29.9038,
    '1351_863': 29.7423,
    '1351_864': 29.5793,
    '1351_865': 29.4146,
    '1351_866': 29.2421,
    '1351_867': 29.0632,
    '1351_868': 28.879,
    '1351_869': 28.6836,
    '1351_870': 28.4623,
    '1351_871': 28.2379,
    '1351_872': 28.0332,
    '1351_873': 27.8362,
    '1351_874': 27.6451,
    '1351_875': 27.4609,
    '1351_876': 27.2851,
    '1351_877': 27.1203,
    '1351_878': 26.9713,
    '1351_879': 26.8284,
    '1351_880': 26.6964,
    '1351_881': 26.5739,
    '1351_882': 26.463,
    '1351_883': 26.3696,
    '1351_884': 26.291,
    '1351_885': 26.2269,
    '1351_886': 26.1814,
    '1351_887': 26.1573,
    '1351_888': 26.1529,
    '1351_889': 26.1792,
    '1351_890': 26.2312,
    '1351_891': 26.3005,
    '1351_892': 26.3759,
    '1351_893': 26.4496,
    '1351_894': 26.555,
    '1351_895': 26.6895,
    '1351_896': 26.8541,
    '1351_897': 27.0482,
    '1351_898': 27.2659,
    '1351_899': 27.4961,
    '1351_900': 27.7064,
    '1351_901': 27.8961,
    '1351_902': 28.0778,
    '1351_903': 28.2494,
    '1351_904': 28.4118,
    '1351_905': 28.5853,
    '1351_906': 28.7656,
    '1351_907': 28.9431,
    '1351_908': 29.1015,
    '1351_909': 29.2311,
    '1351_910': 29.3432,
    '1351_911': 29.4543,
    '1351_912': 29.5339,
    '1351_913': 29.5754,
    '1351_914': 29.5596,
    '1351_915': 29.504,
    '1351_916': 29.3994,
    '1351_917': 29.2535,
    '1351_918': 29.0824,
    '1351_919': 28.8899,
    '1351_920': 28.6782,
    '1351_921': 28.4511,
    '1351_922': 28.2086,
    '1351_923': 27.9526,
    '1351_924': 27.6927,
    '1351_925': 27.4278,
    '1351_926': 27.1522,
    '1351_927': 26.8695,
    '1351_928': 26.5855,
    '1351_929': 26.3087,
    '1351_930': 26.0409,
    '1351_931': 25.7906,
    '1351_932': 25.5636,
    '1351_933': 25.3592,
    '1351_934': 25.1573,
    '1351_935': 24.943,
    '1351_936': 24.7188,
    '1351_937': 24.4848,
    '1351_938': 24.2429,
    '1351_939': 24.0069,
    '1351_940': 23.7828,
    '1351_941': 23.5749,
    '1351_942': 23.3794,
    '1351_943': 23.1946,
    '1351_944': 23.0177,
    '1351_945': 22.8444,
    '1351_946': 22.6656,
    '1351_947': 22.4872,
    '1351_948': 22.3108,
    '1351_949': 22.1385,
    '1351_950': 21.9728,
    '1351_951': 21.8113,
    '1351_952': 21.6532,
    '1351_953': 21.4963,
    '1351_954': 21.3423,
    '1351_955': 21.19,
    '1351_956': 21.0428,
    '1351_957': 20.9007,
    '1351_958': 20.7614,
    '1351_959': 20.6169,
    '1351_960': 20.4907,
    '1352_788': 32.0371,
    '1352_789': 32.2768,
    '1352_790': 32.5151,
    '1352_791': 32.7564,
    '1352_792': 32.9885,
    '1352_793': 33.2039,
    '1352_794': 33.4182,
    '1352_795': 33.6,
    '1352_796': 33.7388,
    '1352_797': 33.8567,
    '1352_798': 33.9721,
    '1352_799': 34.0802,
    '1352_800': 34.1708,
    '1352_801': 34.2411,
    '1352_802': 34.2819,
    '1352_803': 34.2993,
    '1352_804': 34.3079,
    '1352_805': 34.3088,
    '1352_806': 34.2853,
    '1352_807': 34.235,
    '1352_808': 34.1601,
    '1352_809': 34.0822,
    '1352_810': 33.9995,
    '1352_811': 33.9086,
    '1352_812': 33.8029,
    '1352_813': 33.6888,
    '1352_814': 33.5794,
    '1352_815': 33.4999,
    '1352_816': 33.4454,
    '1352_817': 33.4118,
    '1352_818': 33.3953,
    '1352_819': 33.3857,
    '1352_820': 33.3763,
    '1352_821': 33.364,
    '1352_822': 33.3488,
    '1352_823': 33.3302,
    '1352_824': 33.3102,
    '1352_825': 33.2912,
    '1352_826': 33.2753,
    '1352_827': 33.2616,
    '1352_828': 33.2521,
    '1352_829': 33.2465,
    '1352_830': 33.2434,
    '1352_831': 33.2418,
    '1352_832': 33.2509,
    '1352_833': 33.2624,
    '1352_834': 33.2585,
    '1352_835': 33.2449,
    '1352_836': 33.2597,
    '1352_837': 33.2947,
    '1352_838': 33.3263,
    '1352_839': 33.331,
    '1352_840': 33.3143,
    '1352_841': 33.2907,
    '1352_842': 33.2495,
    '1352_843': 33.17,
    '1352_844': 33.0435,
    '1352_845': 32.886,
    '1352_846': 32.7103,
    '1352_847': 32.5234,
    '1352_848': 32.3192,
    '1352_849': 32.1072,
    '1352_850': 31.9058,
    '1352_851': 31.7164,
    '1352_852': 31.5391,
    '1352_853': 31.3669,
    '1352_854': 31.2011,
    '1352_855': 31.0348,
    '1352_856': 30.8781,
    '1352_857': 30.7232,
    '1352_858': 30.5672,
    '1352_859': 30.41,
    '1352_860': 30.2557,
    '1352_861': 30.101,
    '1352_862': 29.9437,
    '1352_863': 29.7814,
    '1352_864': 29.6192,
    '1352_865': 29.4559,
    '1352_866': 29.2873,
    '1352_867': 29.1132,
    '1352_868': 28.9336,
    '1352_869': 28.7435,
    '1352_870': 28.5321,
    '1352_871': 28.3188,
    '1352_872': 28.1171,
    '1352_873': 27.9242,
    '1352_874': 27.7388,
    '1352_875': 27.5615,
    '1352_876': 27.3938,
    '1352_877': 27.2411,
    '1352_878': 27.0938,
    '1352_879': 26.9583,
    '1352_880': 26.8347,
    '1352_881': 26.7197,
    '1352_882': 26.6124,
    '1352_883': 26.5232,
    '1352_884': 26.4524,
    '1352_885': 26.3976,
    '1352_886': 26.3589,
    '1352_887': 26.3396,
    '1352_888': 26.3384,
    '1352_889': 26.3657,
    '1352_890': 26.4201,
    '1352_891': 26.4954,
    '1352_892': 26.578,
    '1352_893': 26.66,
    '1352_894': 26.7726,
    '1352_895': 26.9249,
    '1352_896': 27.1059,
    '1352_897': 27.3141,
    '1352_898': 27.5507,
    '1352_899': 27.7823,
    '1352_900': 27.9867,
    '1352_901': 28.1697,
    '1352_902': 28.3413,
    '1352_903': 28.5113,
    '1352_904': 28.6684,
    '1352_905': 28.8467,
    '1352_906': 29.0372,
    '1352_907': 29.2193,
    '1352_908': 29.3764,
    '1352_909': 29.5069,
    '1352_910': 29.621,
    '1352_911': 29.7217,
    '1352_912': 29.7834,
    '1352_913': 29.8109,
    '1352_914': 29.7813,
    '1352_915': 29.6938,
    '1352_916': 29.5718,
    '1352_917': 29.4171,
    '1352_918': 29.2404,
    '1352_919': 29.0418,
    '1352_920': 28.8223,
    '1352_921': 28.5894,
    '1352_922': 28.344,
    '1352_923': 28.0895,
    '1352_924': 27.8286,
    '1352_925': 27.5593,
    '1352_926': 27.2836,
    '1352_927': 27.0008,
    '1352_928': 26.7191,
    '1352_929': 26.4497,
    '1352_930': 26.1976,
    '1352_931': 25.9634,
    '1352_932': 25.7556,
    '1352_933': 25.5684,
    '1352_934': 25.3762,
    '1352_935': 25.1663,
    '1352_936': 24.9461,
    '1352_937': 24.7146,
    '1352_938': 24.4688,
    '1352_939': 24.2284,
    '1352_940': 24.0095,
    '1352_941': 23.8027,
    '1352_942': 23.6107,
    '1352_943': 23.4299,
    '1352_944': 23.2573,
    '1352_945': 23.0884,
    '1352_946': 22.9195,
    '1352_947': 22.7505,
    '1352_948': 22.581,
    '1352_949': 22.4142,
    '1352_950': 22.2512,
    '1352_951': 22.0918,
    '1352_952': 21.9353,
    '1352_953': 21.7812,
    '1352_954': 21.629,
    '1352_955': 21.4782,
    '1352_956': 21.3324,
    '1352_957': 21.1901,
    '1352_958': 21.0481,
    '1352_959': 20.912,
    '1352_960': 20.799,
    '1353_788': 31.9253,
    '1353_789': 32.1692,
    '1353_790': 32.4201,
    '1353_791': 32.6758,
    '1353_792': 32.9268,
    '1353_793': 33.1657,
    '1353_794': 33.4021,
    '1353_795': 33.6036,
    '1353_796': 33.7544,
    '1353_797': 33.8678,
    '1353_798': 33.971,
    '1353_799': 34.0711,
    '1353_800': 34.1536,
    '1353_801': 34.2199,
    '1353_802': 34.2588,
    '1353_803': 34.2761,
    '1353_804': 34.2855,
    '1353_805': 34.2928,
    '1353_806': 34.2785,
    '1353_807': 34.2362,
    '1353_808': 34.1694,
    '1353_809': 34.0952,
    '1353_810': 34.0086,
    '1353_811': 33.9131,
    '1353_812': 33.813,
    '1353_813': 33.7003,
    '1353_814': 33.6048,
    '1353_815': 33.5378,
    '1353_816': 33.4861,
    '1353_817': 33.4462,
    '1353_818': 33.4284,
    '1353_819': 33.4199,
    '1353_820': 33.4129,
    '1353_821': 33.4039,
    '1353_822': 33.3927,
    '1353_823': 33.3779,
    '1353_824': 33.3591,
    '1353_825': 33.3391,
    '1353_826': 33.3216,
    '1353_827': 33.3077,
    '1353_828': 33.2986,
    '1353_829': 33.2956,
    '1353_830': 33.2958,
    '1353_831': 33.2962,
    '1353_832': 33.2985,
    '1353_833': 33.3003,
    '1353_834': 33.2879,
    '1353_835': 33.2696,
    '1353_836': 33.2739,
    '1353_837': 33.3012,
    '1353_838': 33.3262,
    '1353_839': 33.325,
    '1353_840': 33.3119,
    '1353_841': 33.3018,
    '1353_842': 33.2725,
    '1353_843': 33.2071,
    '1353_844': 33.095,
    '1353_845': 32.9442,
    '1353_846': 32.7677,
    '1353_847': 32.574,
    '1353_848': 32.3717,
    '1353_849': 32.1649,
    '1353_850': 31.9645,
    '1353_851': 31.7743,
    '1353_852': 31.5956,
    '1353_853': 31.4237,
    '1353_854': 31.2562,
    '1353_855': 31.0929,
    '1353_856': 30.9326,
    '1353_857': 30.7669,
    '1353_858': 30.6073,
    '1353_859': 30.4491,
    '1353_860': 30.2927,
    '1353_861': 30.1375,
    '1353_862': 29.982,
    '1353_863': 29.8172,
    '1353_864': 29.6556,
    '1353_865': 29.4935,
    '1353_866': 29.328,
    '1353_867': 29.1566,
    '1353_868': 28.9794,
    '1353_869': 28.7933,
    '1353_870': 28.5958,
    '1353_871': 28.396,
    '1353_872': 28.2016,
    '1353_873': 28.0143,
    '1353_874': 27.8347,
    '1353_875': 27.6645,
    '1353_876': 27.5082,
    '1353_877': 27.356,
    '1353_878': 27.2141,
    '1353_879': 27.0861,
    '1353_880': 26.9727,
    '1353_881': 26.8661,
    '1353_882': 26.7657,
    '1353_883': 26.6777,
    '1353_884': 26.6096,
    '1353_885': 26.5644,
    '1353_886': 26.5353,
    '1353_887': 26.5235,
    '1353_888': 26.5238,
    '1353_889': 26.5557,
    '1353_890': 26.608,
    '1353_891': 26.6846,
    '1353_892': 26.7704,
    '1353_893': 26.8659,
    '1353_894': 26.9968,
    '1353_895': 27.1652,
    '1353_896': 27.3623,
    '1353_897': 27.5837,
    '1353_898': 27.8303,
    '1353_899': 28.061,
    '1353_900': 28.2616,
    '1353_901': 28.4369,
    '1353_902': 28.6034,
    '1353_903': 28.7707,
    '1353_904': 28.931,
    '1353_905': 29.1126,
    '1353_906': 29.3019,
    '1353_907': 29.4806,
    '1353_908': 29.6336,
    '1353_909': 29.7708,
    '1353_910': 29.884,
    '1353_911': 29.9811,
    '1353_912': 30.0329,
    '1353_913': 30.0249,
    '1353_914': 29.9768,
    '1353_915': 29.8763,
    '1353_916': 29.7359,
    '1353_917': 29.571,
    '1353_918': 29.3871,
    '1353_919': 29.1788,
    '1353_920': 28.952,
    '1353_921': 28.7134,
    '1353_922': 28.4696,
    '1353_923': 28.2164,
    '1353_924': 27.9589,
    '1353_925': 27.6924,
    '1353_926': 27.4194,
    '1353_927': 27.1422,
    '1353_928': 26.8642,
    '1353_929': 26.6014,
    '1353_930': 26.3635,
    '1353_931': 26.1427,
    '1353_932': 25.9506,
    '1353_933': 25.7755,
    '1353_934': 25.5912,
    '1353_935': 25.3859,
    '1353_936': 25.1697,
    '1353_937': 24.9393,
    '1353_938': 24.6955,
    '1353_939': 24.4566,
    '1353_940': 24.2352,
    '1353_941': 24.0314,
    '1353_942': 23.8379,
    '1353_943': 23.6597,
    '1353_944': 23.4907,
    '1353_945': 23.3274,
    '1353_946': 23.1682,
    '1353_947': 23.0095,
    '1353_948': 22.8477,
    '1353_949': 22.6873,
    '1353_950': 22.5281,
    '1353_951': 22.3721,
    '1353_952': 22.2181,
    '1353_953': 22.0665,
    '1353_954': 21.9159,
    '1353_955': 21.7669,
    '1353_956': 21.6231,
    '1353_957': 21.4815,
    '1353_958': 21.3362,
    '1353_959': 21.2051,
    '1353_960': 21.1039,
    '1354_788': 31.8179,
    '1354_789': 32.0667,
    '1354_790': 32.3273,
    '1354_791': 32.5962,
    '1354_792': 32.8585,
    '1354_793': 33.1225,
    '1354_794': 33.3764,
    '1354_795': 33.5861,
    '1354_796': 33.744,
    '1354_797': 33.862,
    '1354_798': 33.9593,
    '1354_799': 34.0463,
    '1354_800': 34.1176,
    '1354_801': 34.1759,
    '1354_802': 34.2113,
    '1354_803': 34.2282,
    '1354_804': 34.2395,
    '1354_805': 34.2574,
    '1354_806': 34.2584,
    '1354_807': 34.2254,
    '1354_808': 34.1638,
    '1354_809': 34.0847,
    '1354_810': 33.9918,
    '1354_811': 33.8929,
    '1354_812': 33.799,
    '1354_813': 33.7056,
    '1354_814': 33.6404,
    '1354_815': 33.5878,
    '1354_816': 33.5418,
    '1354_817': 33.4961,
    '1354_818': 33.4716,
    '1354_819': 33.4641,
    '1354_820': 33.459,
    '1354_821': 33.4521,
    '1354_822': 33.4458,
    '1354_823': 33.4378,
    '1354_824': 33.4154,
    '1354_825': 33.3927,
    '1354_826': 33.3724,
    '1354_827': 33.3571,
    '1354_828': 33.3497,
    '1354_829': 33.3521,
    '1354_830': 33.362,
    '1354_831': 33.3616,
    '1354_832': 33.341,
    '1354_833': 33.3165,
    '1354_834': 33.2998,
    '1354_835': 33.2894,
    '1354_836': 33.2975,
    '1354_837': 33.3223,
    '1354_838': 33.3384,
    '1354_839': 33.3299,
    '1354_840': 33.3142,
    '1354_841': 33.3056,
    '1354_842': 33.2871,
    '1354_843': 33.2355,
    '1354_844': 33.139,
    '1354_845': 33,
    '1354_846': 32.8289,
    '1354_847': 32.643,
    '1354_848': 32.4441,
    '1354_849': 32.2369,
    '1354_850': 32.0371,
    '1354_851': 31.8454,
    '1354_852': 31.6646,
    '1354_853': 31.4903,
    '1354_854': 31.321,
    '1354_855': 31.1529,
    '1354_856': 30.9883,
    '1354_857': 30.8245,
    '1354_858': 30.6553,
    '1354_859': 30.4933,
    '1354_860': 30.3328,
    '1354_861': 30.1746,
    '1354_862': 30.0164,
    '1354_863': 29.8544,
    '1354_864': 29.6932,
    '1354_865': 29.5319,
    '1354_866': 29.3675,
    '1354_867': 29.199,
    '1354_868': 29.0254,
    '1354_869': 28.8452,
    '1354_870': 28.6587,
    '1354_871': 28.4706,
    '1354_872': 28.2854,
    '1354_873': 28.1057,
    '1354_874': 27.9328,
    '1354_875': 27.7735,
    '1354_876': 27.6168,
    '1354_877': 27.4691,
    '1354_878': 27.3331,
    '1354_879': 27.2117,
    '1354_880': 27.1045,
    '1354_881': 27.0082,
    '1354_882': 26.9158,
    '1354_883': 26.8346,
    '1354_884': 26.7681,
    '1354_885': 26.7253,
    '1354_886': 26.7061,
    '1354_887': 26.7039,
    '1354_888': 26.713,
    '1354_889': 26.7469,
    '1354_890': 26.8009,
    '1354_891': 26.8791,
    '1354_892': 26.9724,
    '1354_893': 27.0818,
    '1354_894': 27.2272,
    '1354_895': 27.4122,
    '1354_896': 27.6253,
    '1354_897': 27.8534,
    '1354_898': 28.1023,
    '1354_899': 28.3297,
    '1354_900': 28.5291,
    '1354_901': 28.6995,
    '1354_902': 28.8637,
    '1354_903': 29.036,
    '1354_904': 29.2064,
    '1354_905': 29.385,
    '1354_906': 29.5631,
    '1354_907': 29.7302,
    '1354_908': 29.8877,
    '1354_909': 30.0246,
    '1354_910': 30.1401,
    '1354_911': 30.2152,
    '1354_912': 30.2559,
    '1354_913': 30.2198,
    '1354_914': 30.1566,
    '1354_915': 30.0379,
    '1354_916': 29.8873,
    '1354_917': 29.7161,
    '1354_918': 29.5241,
    '1354_919': 29.3038,
    '1354_920': 29.0749,
    '1354_921': 28.8393,
    '1354_922': 28.5945,
    '1354_923': 28.3435,
    '1354_924': 28.0877,
    '1354_925': 27.8243,
    '1354_926': 27.5556,
    '1354_927': 27.284,
    '1354_928': 27.0107,
    '1354_929': 26.7579,
    '1354_930': 26.5289,
    '1354_931': 26.3206,
    '1354_932': 26.1396,
    '1354_933': 25.9733,
    '1354_934': 25.797,
    '1354_935': 25.599,
    '1354_936': 25.3845,
    '1354_937': 25.1542,
    '1354_938': 24.911,
    '1354_939': 24.6755,
    '1354_940': 24.4551,
    '1354_941': 24.2529,
    '1354_942': 24.0609,
    '1354_943': 23.8849,
    '1354_944': 23.7196,
    '1354_945': 23.5622,
    '1354_946': 23.4111,
    '1354_947': 23.2611,
    '1354_948': 23.1077,
    '1354_949': 22.9548,
    '1354_950': 22.8033,
    '1354_951': 22.6532,
    '1354_952': 22.5036,
    '1354_953': 22.3547,
    '1354_954': 22.2058,
    '1354_955': 22.0584,
    '1354_956': 21.9136,
    '1354_957': 21.7695,
    '1354_958': 21.6285,
    '1354_959': 21.5091,
    '1354_960': 21.4146,
    '1355_788': 31.7155,
    '1355_789': 31.9681,
    '1355_790': 32.2339,
    '1355_791': 32.5148,
    '1355_792': 32.7917,
    '1355_793': 33.0735,
    '1355_794': 33.3318,
    '1355_795': 33.5426,
    '1355_796': 33.7106,
    '1355_797': 33.8363,
    '1355_798': 33.9374,
    '1355_799': 34.0117,
    '1355_800': 34.0678,
    '1355_801': 34.1125,
    '1355_802': 34.1434,
    '1355_803': 34.1625,
    '1355_804': 34.1826,
    '1355_805': 34.2131,
    '1355_806': 34.2283,
    '1355_807': 34.203,
    '1355_808': 34.143,
    '1355_809': 34.0536,
    '1355_810': 33.9608,
    '1355_811': 33.8683,
    '1355_812': 33.7774,
    '1355_813': 33.7118,
    '1355_814': 33.6739,
    '1355_815': 33.6345,
    '1355_816': 33.5943,
    '1355_817': 33.5542,
    '1355_818': 33.5254,
    '1355_819': 33.5202,
    '1355_820': 33.52,
    '1355_821': 33.5146,
    '1355_822': 33.5134,
    '1355_823': 33.5018,
    '1355_824': 33.478,
    '1355_825': 33.4528,
    '1355_826': 33.4261,
    '1355_827': 33.4097,
    '1355_828': 33.4018,
    '1355_829': 33.4082,
    '1355_830': 33.42,
    '1355_831': 33.41,
    '1355_832': 33.3683,
    '1355_833': 33.3327,
    '1355_834': 33.316,
    '1355_835': 33.3067,
    '1355_836': 33.3201,
    '1355_837': 33.3555,
    '1355_838': 33.3769,
    '1355_839': 33.3626,
    '1355_840': 33.3382,
    '1355_841': 33.3149,
    '1355_842': 33.2955,
    '1355_843': 33.2548,
    '1355_844': 33.1775,
    '1355_845': 33.0573,
    '1355_846': 32.9022,
    '1355_847': 32.7262,
    '1355_848': 32.5299,
    '1355_849': 32.3186,
    '1355_850': 32.1163,
    '1355_851': 31.9246,
    '1355_852': 31.7418,
    '1355_853': 31.5648,
    '1355_854': 31.3953,
    '1355_855': 31.2249,
    '1355_856': 31.0544,
    '1355_857': 30.8849,
    '1355_858': 30.7168,
    '1355_859': 30.5498,
    '1355_860': 30.3782,
    '1355_861': 30.2158,
    '1355_862': 30.0546,
    '1355_863': 29.8933,
    '1355_864': 29.733,
    '1355_865': 29.5727,
    '1355_866': 29.4098,
    '1355_867': 29.2444,
    '1355_868': 29.0756,
    '1355_869': 28.902,
    '1355_870': 28.7246,
    '1355_871': 28.5467,
    '1355_872': 28.3705,
    '1355_873': 28.2038,
    '1355_874': 28.0367,
    '1355_875': 27.8767,
    '1355_876': 27.7244,
    '1355_877': 27.5826,
    '1355_878': 27.4537,
    '1355_879': 27.3388,
    '1355_880': 27.2374,
    '1355_881': 27.1449,
    '1355_882': 27.0581,
    '1355_883': 26.9871,
    '1355_884': 26.931,
    '1355_885': 26.8902,
    '1355_886': 26.8736,
    '1355_887': 26.8797,
    '1355_888': 26.899,
    '1355_889': 26.9349,
    '1355_890': 26.9919,
    '1355_891': 27.0749,
    '1355_892': 27.1783,
    '1355_893': 27.3043,
    '1355_894': 27.4639,
    '1355_895': 27.6599,
    '1355_896': 27.881,
    '1355_897': 28.1143,
    '1355_898': 28.3622,
    '1355_899': 28.5903,
    '1355_900': 28.7926,
    '1355_901': 28.9628,
    '1355_902': 29.1226,
    '1355_903': 29.2988,
    '1355_904': 29.4824,
    '1355_905': 29.6589,
    '1355_906': 29.825,
    '1355_907': 29.9824,
    '1355_908': 30.1325,
    '1355_909': 30.2697,
    '1355_910': 30.3777,
    '1355_911': 30.4471,
    '1355_912': 30.4529,
    '1355_913': 30.4135,
    '1355_914': 30.3226,
    '1355_915': 30.1885,
    '1355_916': 30.0293,
    '1355_917': 29.8444,
    '1355_918': 29.6385,
    '1355_919': 29.4169,
    '1355_920': 29.1885,
    '1355_921': 28.9551,
    '1355_922': 28.7112,
    '1355_923': 28.4606,
    '1355_924': 28.2078,
    '1355_925': 27.948,
    '1355_926': 27.6834,
    '1355_927': 27.4169,
    '1355_928': 27.1534,
    '1355_929': 26.9088,
    '1355_930': 26.6872,
    '1355_931': 26.491,
    '1355_932': 26.3224,
    '1355_933': 26.1671,
    '1355_934': 25.9948,
    '1355_935': 25.8025,
    '1355_936': 25.5907,
    '1355_937': 25.3604,
    '1355_938': 25.1185,
    '1355_939': 24.888,
    '1355_940': 24.6728,
    '1355_941': 24.4708,
    '1355_942': 24.2832,
    '1355_943': 24.106,
    '1355_944': 23.9436,
    '1355_945': 23.7916,
    '1355_946': 23.6482,
    '1355_947': 23.5078,
    '1355_948': 23.3623,
    '1355_949': 23.2179,
    '1355_950': 23.0759,
    '1355_951': 22.9339,
    '1355_952': 22.7901,
    '1355_953': 22.6456,
    '1355_954': 22.4994,
    '1355_955': 22.3547,
    '1355_956': 22.2107,
    '1355_957': 22.0676,
    '1355_958': 21.934,
    '1355_959': 21.8173,
    '1355_960': 21.7278,
    '1356_788': 31.627,
    '1356_789': 31.8789,
    '1356_790': 32.1483,
    '1356_791': 32.4312,
    '1356_792': 32.7137,
    '1356_793': 33.0024,
    '1356_794': 33.263,
    '1356_795': 33.487,
    '1356_796': 33.6648,
    '1356_797': 33.7957,
    '1356_798': 33.8961,
    '1356_799': 33.9651,
    '1356_800': 33.9996,
    '1356_801': 34.0297,
    '1356_802': 34.0643,
    '1356_803': 34.0979,
    '1356_804': 34.1293,
    '1356_805': 34.1709,
    '1356_806': 34.193,
    '1356_807': 34.1708,
    '1356_808': 34.1086,
    '1356_809': 34.0235,
    '1356_810': 33.9367,
    '1356_811': 33.8448,
    '1356_812': 33.7588,
    '1356_813': 33.6994,
    '1356_814': 33.6721,
    '1356_815': 33.6463,
    '1356_816': 33.6136,
    '1356_817': 33.586,
    '1356_818': 33.5778,
    '1356_819': 33.5835,
    '1356_820': 33.5875,
    '1356_821': 33.5888,
    '1356_822': 33.581,
    '1356_823': 33.5555,
    '1356_824': 33.5319,
    '1356_825': 33.5062,
    '1356_826': 33.4817,
    '1356_827': 33.4647,
    '1356_828': 33.4542,
    '1356_829': 33.4527,
    '1356_830': 33.4526,
    '1356_831': 33.4358,
    '1356_832': 33.3908,
    '1356_833': 33.3553,
    '1356_834': 33.343,
    '1356_835': 33.3333,
    '1356_836': 33.3492,
    '1356_837': 33.3928,
    '1356_838': 33.4269,
    '1356_839': 33.419,
    '1356_840': 33.3861,
    '1356_841': 33.3455,
    '1356_842': 33.3096,
    '1356_843': 33.2728,
    '1356_844': 33.2122,
    '1356_845': 33.1165,
    '1356_846': 32.9826,
    '1356_847': 32.8161,
    '1356_848': 32.6216,
    '1356_849': 32.4098,
    '1356_850': 32.2052,
    '1356_851': 32.0128,
    '1356_852': 31.828,
    '1356_853': 31.6498,
    '1356_854': 31.4782,
    '1356_855': 31.3072,
    '1356_856': 31.1312,
    '1356_857': 30.9554,
    '1356_858': 30.7803,
    '1356_859': 30.6069,
    '1356_860': 30.4357,
    '1356_861': 30.2695,
    '1356_862': 30.0997,
    '1356_863': 29.9379,
    '1356_864': 29.7784,
    '1356_865': 29.6191,
    '1356_866': 29.4576,
    '1356_867': 29.2959,
    '1356_868': 29.133,
    '1356_869': 28.9659,
    '1356_870': 28.7964,
    '1356_871': 28.6318,
    '1356_872': 28.4622,
    '1356_873': 28.296,
    '1356_874': 28.1346,
    '1356_875': 27.98,
    '1356_876': 27.8342,
    '1356_877': 27.6997,
    '1356_878': 27.5793,
    '1356_879': 27.4724,
    '1356_880': 27.3757,
    '1356_881': 27.2892,
    '1356_882': 27.2046,
    '1356_883': 27.1358,
    '1356_884': 27.0893,
    '1356_885': 27.0595,
    '1356_886': 27.0418,
    '1356_887': 27.0507,
    '1356_888': 27.0772,
    '1356_889': 27.1212,
    '1356_890': 27.1825,
    '1356_891': 27.2702,
    '1356_892': 27.3852,
    '1356_893': 27.5261,
    '1356_894': 27.6947,
    '1356_895': 27.8961,
    '1356_896': 28.1207,
    '1356_897': 28.3606,
    '1356_898': 28.6112,
    '1356_899': 28.8465,
    '1356_900': 29.0486,
    '1356_901': 29.2174,
    '1356_902': 29.3721,
    '1356_903': 29.5479,
    '1356_904': 29.7379,
    '1356_905': 29.9216,
    '1356_906': 30.0856,
    '1356_907': 30.2326,
    '1356_908': 30.376,
    '1356_909': 30.5077,
    '1356_910': 30.6022,
    '1356_911': 30.6647,
    '1356_912': 30.641,
    '1356_913': 30.5837,
    '1356_914': 30.4708,
    '1356_915': 30.3287,
    '1356_916': 30.1583,
    '1356_917': 29.9633,
    '1356_918': 29.7452,
    '1356_919': 29.5171,
    '1356_920': 29.2855,
    '1356_921': 29.0521,
    '1356_922': 28.8102,
    '1356_923': 28.5626,
    '1356_924': 28.3133,
    '1356_925': 28.0615,
    '1356_926': 27.8015,
    '1356_927': 27.5415,
    '1356_928': 27.2865,
    '1356_929': 27.0489,
    '1356_930': 26.8359,
    '1356_931': 26.65,
    '1356_932': 26.4916,
    '1356_933': 26.3488,
    '1356_934': 26.1838,
    '1356_935': 25.9953,
    '1356_936': 25.7868,
    '1356_937': 25.5605,
    '1356_938': 25.3242,
    '1356_939': 25.0969,
    '1356_940': 24.8842,
    '1356_941': 24.6849,
    '1356_942': 24.4989,
    '1356_943': 24.3265,
    '1356_944': 24.1631,
    '1356_945': 24.0154,
    '1356_946': 23.8779,
    '1356_947': 23.7444,
    '1356_948': 23.6105,
    '1356_949': 23.4779,
    '1356_950': 23.3464,
    '1356_951': 23.2125,
    '1356_952': 23.0761,
    '1356_953': 22.9378,
    '1356_954': 22.7978,
    '1356_955': 22.6576,
    '1356_956': 22.5149,
    '1356_957': 22.369,
    '1356_958': 22.2307,
    '1356_959': 22.1277,
    '1356_960': 22.0461,
    '1357_788': 31.5297,
    '1357_789': 31.782,
    '1357_790': 32.0526,
    '1357_791': 32.3338,
    '1357_792': 32.6152,
    '1357_793': 32.9059,
    '1357_794': 33.1769,
    '1357_795': 33.4097,
    '1357_796': 33.5907,
    '1357_797': 33.7298,
    '1357_798': 33.8289,
    '1357_799': 33.8939,
    '1357_800': 33.9201,
    '1357_801': 33.9377,
    '1357_802': 33.9777,
    '1357_803': 34.0283,
    '1357_804': 34.0743,
    '1357_805': 34.1228,
    '1357_806': 34.1486,
    '1357_807': 34.1255,
    '1357_808': 34.0608,
    '1357_809': 33.9833,
    '1357_810': 33.9047,
    '1357_811': 33.813,
    '1357_812': 33.7314,
    '1357_813': 33.6714,
    '1357_814': 33.6423,
    '1357_815': 33.6259,
    '1357_816': 33.6075,
    '1357_817': 33.5981,
    '1357_818': 33.6102,
    '1357_819': 33.6245,
    '1357_820': 33.6376,
    '1357_821': 33.6435,
    '1357_822': 33.6337,
    '1357_823': 33.6085,
    '1357_824': 33.5842,
    '1357_825': 33.5583,
    '1357_826': 33.537,
    '1357_827': 33.5141,
    '1357_828': 33.4931,
    '1357_829': 33.4793,
    '1357_830': 33.4689,
    '1357_831': 33.4539,
    '1357_832': 33.4218,
    '1357_833': 33.3803,
    '1357_834': 33.3647,
    '1357_835': 33.3666,
    '1357_836': 33.3918,
    '1357_837': 33.4378,
    '1357_838': 33.4763,
    '1357_839': 33.4752,
    '1357_840': 33.4389,
    '1357_841': 33.3847,
    '1357_842': 33.3324,
    '1357_843': 33.2904,
    '1357_844': 33.2439,
    '1357_845': 33.1705,
    '1357_846': 33.0535,
    '1357_847': 32.8965,
    '1357_848': 32.7059,
    '1357_849': 32.4989,
    '1357_850': 32.2974,
    '1357_851': 32.1076,
    '1357_852': 31.9248,
    '1357_853': 31.7442,
    '1357_854': 31.572,
    '1357_855': 31.3993,
    '1357_856': 31.2193,
    '1357_857': 31.0351,
    '1357_858': 30.8517,
    '1357_859': 30.6714,
    '1357_860': 30.4931,
    '1357_861': 30.322,
    '1357_862': 30.1558,
    '1357_863': 29.9937,
    '1357_864': 29.8345,
    '1357_865': 29.6764,
    '1357_866': 29.5113,
    '1357_867': 29.3535,
    '1357_868': 29.2017,
    '1357_869': 29.0405,
    '1357_870': 28.8761,
    '1357_871': 28.7106,
    '1357_872': 28.5464,
    '1357_873': 28.3855,
    '1357_874': 28.2306,
    '1357_875': 28.0836,
    '1357_876': 27.9451,
    '1357_877': 27.8191,
    '1357_878': 27.7079,
    '1357_879': 27.6084,
    '1357_880': 27.5171,
    '1357_881': 27.4342,
    '1357_882': 27.3551,
    '1357_883': 27.2883,
    '1357_884': 27.2444,
    '1357_885': 27.2216,
    '1357_886': 27.207,
    '1357_887': 27.2222,
    '1357_888': 27.2526,
    '1357_889': 27.3034,
    '1357_890': 27.373,
    '1357_891': 27.4647,
    '1357_892': 27.5866,
    '1357_893': 27.7355,
    '1357_894': 27.9085,
    '1357_895': 28.1088,
    '1357_896': 28.3333,
    '1357_897': 28.588,
    '1357_898': 28.845,
    '1357_899': 29.085,
    '1357_900': 29.2904,
    '1357_901': 29.4573,
    '1357_902': 29.6079,
    '1357_903': 29.7794,
    '1357_904': 29.9775,
    '1357_905': 30.1685,
    '1357_906': 30.3351,
    '1357_907': 30.4908,
    '1357_908': 30.6209,
    '1357_909': 30.7425,
    '1357_910': 30.8224,
    '1357_911': 30.8457,
    '1357_912': 30.8229,
    '1357_913': 30.743,
    '1357_914': 30.6134,
    '1357_915': 30.4622,
    '1357_916': 30.2782,
    '1357_917': 30.0736,
    '1357_918': 29.8474,
    '1357_919': 29.6143,
    '1357_920': 29.3795,
    '1357_921': 29.1427,
    '1357_922': 28.9013,
    '1357_923': 28.6543,
    '1357_924': 28.4087,
    '1357_925': 28.1643,
    '1357_926': 27.9092,
    '1357_927': 27.6543,
    '1357_928': 27.4086,
    '1357_929': 27.1797,
    '1357_930': 26.9745,
    '1357_931': 26.7965,
    '1357_932': 26.6473,
    '1357_933': 26.5169,
    '1357_934': 26.3641,
    '1357_935': 26.1807,
    '1357_936': 25.9777,
    '1357_937': 25.7572,
    '1357_938': 25.5267,
    '1357_939': 25.3007,
    '1357_940': 25.0907,
    '1357_941': 24.8941,
    '1357_942': 24.711,
    '1357_943': 24.5394,
    '1357_944': 24.3792,
    '1357_945': 24.2358,
    '1357_946': 24.1039,
    '1357_947': 23.9779,
    '1357_948': 23.8547,
    '1357_949': 23.7341,
    '1357_950': 23.614,
    '1357_951': 23.4888,
    '1357_952': 23.3615,
    '1357_953': 23.2306,
    '1357_954': 23.0986,
    '1357_955': 22.9671,
    '1357_956': 22.8312,
    '1357_957': 22.6901,
    '1357_958': 22.5574,
    '1357_959': 22.4506,
    '1357_960': 22.3688,
    '1358_788': 31.4267,
    '1358_789': 31.6794,
    '1358_790': 31.9477,
    '1358_791': 32.2228,
    '1358_792': 32.4981,
    '1358_793': 32.7802,
    '1358_794': 33.0569,
    '1358_795': 33.3012,
    '1358_796': 33.4915,
    '1358_797': 33.6383,
    '1358_798': 33.7427,
    '1358_799': 33.8022,
    '1358_800': 33.8261,
    '1358_801': 33.8425,
    '1358_802': 33.8832,
    '1358_803': 33.9436,
    '1358_804': 34.0034,
    '1358_805': 34.0618,
    '1358_806': 34.0853,
    '1358_807': 34.061,
    '1358_808': 33.9913,
    '1358_809': 33.9187,
    '1358_810': 33.8479,
    '1358_811': 33.7661,
    '1358_812': 33.6896,
    '1358_813': 33.6383,
    '1358_814': 33.6084,
    '1358_815': 33.5974,
    '1358_816': 33.5959,
    '1358_817': 33.6046,
    '1358_818': 33.6283,
    '1358_819': 33.6553,
    '1358_820': 33.6763,
    '1358_821': 33.6831,
    '1358_822': 33.674,
    '1358_823': 33.6506,
    '1358_824': 33.6291,
    '1358_825': 33.6106,
    '1358_826': 33.5845,
    '1358_827': 33.5538,
    '1358_828': 33.525,
    '1358_829': 33.5054,
    '1358_830': 33.4874,
    '1358_831': 33.4699,
    '1358_832': 33.4461,
    '1358_833': 33.4138,
    '1358_834': 33.4023,
    '1358_835': 33.4154,
    '1358_836': 33.454,
    '1358_837': 33.5,
    '1358_838': 33.5284,
    '1358_839': 33.525,
    '1358_840': 33.4867,
    '1358_841': 33.4251,
    '1358_842': 33.3596,
    '1358_843': 33.3112,
    '1358_844': 33.2706,
    '1358_845': 33.2083,
    '1358_846': 33.1052,
    '1358_847': 32.9655,
    '1358_848': 32.777,
    '1358_849': 32.5751,
    '1358_850': 32.383,
    '1358_851': 32.2029,
    '1358_852': 32.0255,
    '1358_853': 31.8503,
    '1358_854': 31.6815,
    '1358_855': 31.5077,
    '1358_856': 31.3208,
    '1358_857': 31.1271,
    '1358_858': 30.9323,
    '1358_859': 30.7409,
    '1358_860': 30.5558,
    '1358_861': 30.3803,
    '1358_862': 30.2113,
    '1358_863': 30.0476,
    '1358_864': 29.8881,
    '1358_865': 29.7321,
    '1358_866': 29.5763,
    '1358_867': 29.4222,
    '1358_868': 29.2683,
    '1358_869': 29.1105,
    '1358_870': 28.949,
    '1358_871': 28.7875,
    '1358_872': 28.6291,
    '1358_873': 28.4754,
    '1358_874': 28.3277,
    '1358_875': 28.1886,
    '1358_876': 28.0584,
    '1358_877': 27.9413,
    '1358_878': 27.8395,
    '1358_879': 27.7476,
    '1358_880': 27.6604,
    '1358_881': 27.579,
    '1358_882': 27.5018,
    '1358_883': 27.4364,
    '1358_884': 27.3995,
    '1358_885': 27.383,
    '1358_886': 27.3744,
    '1358_887': 27.3914,
    '1358_888': 27.4285,
    '1358_889': 27.4802,
    '1358_890': 27.5536,
    '1358_891': 27.6513,
    '1358_892': 27.7787,
    '1358_893': 27.9304,
    '1358_894': 28.1051,
    '1358_895': 28.308,
    '1358_896': 28.5375,
    '1358_897': 28.7956,
    '1358_898': 29.0585,
    '1358_899': 29.3053,
    '1358_900': 29.5087,
    '1358_901': 29.6787,
    '1358_902': 29.8315,
    '1358_903': 30.0008,
    '1358_904': 30.2024,
    '1358_905': 30.4009,
    '1358_906': 30.5729,
    '1358_907': 30.7237,
    '1358_908': 30.8569,
    '1358_909': 30.9614,
    '1358_910': 31.0266,
    '1358_911': 31.03,
    '1358_912': 30.9977,
    '1358_913': 30.897,
    '1358_914': 30.7583,
    '1358_915': 30.5893,
    '1358_916': 30.3917,
    '1358_917': 30.1702,
    '1358_918': 29.9359,
    '1358_919': 29.7001,
    '1358_920': 29.4654,
    '1358_921': 29.2293,
    '1358_922': 28.9884,
    '1358_923': 28.7424,
    '1358_924': 28.5007,
    '1358_925': 28.2621,
    '1358_926': 28.012,
    '1358_927': 27.762,
    '1358_928': 27.5231,
    '1358_929': 27.302,
    '1358_930': 27.105,
    '1358_931': 26.9343,
    '1358_932': 26.793,
    '1358_933': 26.6749,
    '1358_934': 26.5356,
    '1358_935': 26.3647,
    '1358_936': 26.1703,
    '1358_937': 25.9524,
    '1358_938': 25.7273,
    '1358_939': 25.503,
    '1358_940': 25.2945,
    '1358_941': 25.1021,
    '1358_942': 24.9242,
    '1358_943': 24.7557,
    '1358_944': 24.6018,
    '1358_945': 24.4587,
    '1358_946': 24.332,
    '1358_947': 24.2135,
    '1358_948': 24.0993,
    '1358_949': 23.9879,
    '1358_950': 23.8768,
    '1358_951': 23.7626,
    '1358_952': 23.6456,
    '1358_953': 23.5248,
    '1358_954': 23.4019,
    '1358_955': 23.2776,
    '1358_956': 23.1508,
    '1358_957': 23.0189,
    '1358_958': 22.8889,
    '1358_959': 22.7738,
    '1358_960': 22.6927,
    '1359_788': 31.3228,
    '1359_789': 31.5803,
    '1359_790': 31.8374,
    '1359_791': 32.1022,
    '1359_792': 32.3756,
    '1359_793': 32.642,
    '1359_794': 32.9083,
    '1359_795': 33.1548,
    '1359_796': 33.353,
    '1359_797': 33.5064,
    '1359_798': 33.6162,
    '1359_799': 33.6804,
    '1359_800': 33.7037,
    '1359_801': 33.7256,
    '1359_802': 33.7777,
    '1359_803': 33.8428,
    '1359_804': 33.9119,
    '1359_805': 33.9763,
    '1359_806': 33.998,
    '1359_807': 33.9719,
    '1359_808': 33.9069,
    '1359_809': 33.8393,
    '1359_810': 33.7747,
    '1359_811': 33.7017,
    '1359_812': 33.6364,
    '1359_813': 33.5991,
    '1359_814': 33.5817,
    '1359_815': 33.579,
    '1359_816': 33.5911,
    '1359_817': 33.6149,
    '1359_818': 33.6536,
    '1359_819': 33.6861,
    '1359_820': 33.7095,
    '1359_821': 33.7166,
    '1359_822': 33.699,
    '1359_823': 33.6763,
    '1359_824': 33.6615,
    '1359_825': 33.6505,
    '1359_826': 33.6257,
    '1359_827': 33.5921,
    '1359_828': 33.5564,
    '1359_829': 33.5306,
    '1359_830': 33.5114,
    '1359_831': 33.4906,
    '1359_832': 33.4689,
    '1359_833': 33.451,
    '1359_834': 33.4537,
    '1359_835': 33.4759,
    '1359_836': 33.5143,
    '1359_837': 33.5509,
    '1359_838': 33.5731,
    '1359_839': 33.5656,
    '1359_840': 33.5275,
    '1359_841': 33.4683,
    '1359_842': 33.3979,
    '1359_843': 33.3365,
    '1359_844': 33.2924,
    '1359_845': 33.2371,
    '1359_846': 33.1453,
    '1359_847': 33.0136,
    '1359_848': 32.8386,
    '1359_849': 32.6527,
    '1359_850': 32.4683,
    '1359_851': 32.297,
    '1359_852': 32.1305,
    '1359_853': 31.9651,
    '1359_854': 31.7976,
    '1359_855': 31.6228,
    '1359_856': 31.4311,
    '1359_857': 31.2253,
    '1359_858': 31.017,
    '1359_859': 30.8135,
    '1359_860': 30.6218,
    '1359_861': 30.4407,
    '1359_862': 30.2683,
    '1359_863': 30.1027,
    '1359_864': 29.9432,
    '1359_865': 29.7887,
    '1359_866': 29.6363,
    '1359_867': 29.485,
    '1359_868': 29.3334,
    '1359_869': 29.1777,
    '1359_870': 29.0201,
    '1359_871': 28.8635,
    '1359_872': 28.7121,
    '1359_873': 28.566,
    '1359_874': 28.4263,
    '1359_875': 28.2952,
    '1359_876': 28.1733,
    '1359_877': 28.0649,
    '1359_878': 27.9707,
    '1359_879': 27.8848,
    '1359_880': 27.8003,
    '1359_881': 27.7189,
    '1359_882': 27.6447,
    '1359_883': 27.5851,
    '1359_884': 27.5578,
    '1359_885': 27.5463,
    '1359_886': 27.5426,
    '1359_887': 27.5565,
    '1359_888': 27.5961,
    '1359_889': 27.6488,
    '1359_890': 27.7257,
    '1359_891': 27.8285,
    '1359_892': 27.9578,
    '1359_893': 28.1122,
    '1359_894': 28.292,
    '1359_895': 28.5002,
    '1359_896': 28.7317,
    '1359_897': 28.9844,
    '1359_898': 29.25,
    '1359_899': 29.4996,
    '1359_900': 29.711,
    '1359_901': 29.8837,
    '1359_902': 30.0425,
    '1359_903': 30.2139,
    '1359_904': 30.4128,
    '1359_905': 30.6183,
    '1359_906': 30.794,
    '1359_907': 30.9383,
    '1359_908': 31.0714,
    '1359_909': 31.164,
    '1359_910': 31.2154,
    '1359_911': 31.2145,
    '1359_912': 31.1629,
    '1359_913': 31.045,
    '1359_914': 30.9003,
    '1359_915': 30.7162,
    '1359_916': 30.5002,
    '1359_917': 30.2632,
    '1359_918': 30.0212,
    '1359_919': 29.7798,
    '1359_920': 29.5402,
    '1359_921': 29.3045,
    '1359_922': 29.0671,
    '1359_923': 28.8245,
    '1359_924': 28.5879,
    '1359_925': 28.3527,
    '1359_926': 28.1089,
    '1359_927': 27.8629,
    '1359_928': 27.6307,
    '1359_929': 27.4179,
    '1359_930': 27.2274,
    '1359_931': 27.0621,
    '1359_932': 26.9235,
    '1359_933': 26.813,
    '1359_934': 26.6885,
    '1359_935': 26.5336,
    '1359_936': 26.3468,
    '1359_937': 26.1377,
    '1359_938': 25.9198,
    '1359_939': 25.6973,
    '1359_940': 25.4929,
    '1359_941': 25.3089,
    '1359_942': 25.1364,
    '1359_943': 24.9739,
    '1359_944': 24.8235,
    '1359_945': 24.6881,
    '1359_946': 24.5638,
    '1359_947': 24.452,
    '1359_948': 24.3456,
    '1359_949': 24.2425,
    '1359_950': 24.1401,
    '1359_951': 24.0363,
    '1359_952': 23.9302,
    '1359_953': 23.8197,
    '1359_954': 23.7061,
    '1359_955': 23.5904,
    '1359_956': 23.4745,
    '1359_957': 23.3533,
    '1359_958': 23.2295,
    '1359_959': 23.1156,
    '1359_960': 23.0181,
    '1360_788': 31.2314,
    '1360_789': 31.4723,
    '1360_790': 31.7235,
    '1360_791': 31.9815,
    '1360_792': 32.2438,
    '1360_793': 32.497,
    '1360_794': 32.7474,
    '1360_795': 32.9836,
    '1360_796': 33.1834,
    '1360_797': 33.3439,
    '1360_798': 33.4596,
    '1360_799': 33.5244,
    '1360_800': 33.5581,
    '1360_801': 33.5951,
    '1360_802': 33.6575,
    '1360_803': 33.7233,
    '1360_804': 33.8034,
    '1360_805': 33.8695,
    '1360_806': 33.8848,
    '1360_807': 33.8655,
    '1360_808': 33.8185,
    '1360_809': 33.7601,
    '1360_810': 33.7013,
    '1360_811': 33.6385,
    '1360_812': 33.59,
    '1360_813': 33.5674,
    '1360_814': 33.5617,
    '1360_815': 33.5727,
    '1360_816': 33.5969,
    '1360_817': 33.6342,
    '1360_818': 33.6808,
    '1360_819': 33.7178,
    '1360_820': 33.7421,
    '1360_821': 33.7456,
    '1360_822': 33.7187,
    '1360_823': 33.6962,
    '1360_824': 33.6895,
    '1360_825': 33.6878,
    '1360_826': 33.6733,
    '1360_827': 33.6378,
    '1360_828': 33.5962,
    '1360_829': 33.5599,
    '1360_830': 33.5346,
    '1360_831': 33.5128,
    '1360_832': 33.495,
    '1360_833': 33.488,
    '1360_834': 33.4986,
    '1360_835': 33.5235,
    '1360_836': 33.5519,
    '1360_837': 33.5872,
    '1360_838': 33.6063,
    '1360_839': 33.5953,
    '1360_840': 33.5585,
    '1360_841': 33.5065,
    '1360_842': 33.4439,
    '1360_843': 33.3767,
    '1360_844': 33.32,
    '1360_845': 33.2613,
    '1360_846': 33.179,
    '1360_847': 33.0616,
    '1360_848': 32.9057,
    '1360_849': 32.7344,
    '1360_850': 32.557,
    '1360_851': 32.3928,
    '1360_852': 32.2331,
    '1360_853': 32.0757,
    '1360_854': 31.9161,
    '1360_855': 31.7417,
    '1360_856': 31.541,
    '1360_857': 31.3224,
    '1360_858': 31.0999,
    '1360_859': 30.8857,
    '1360_860': 30.687,
    '1360_861': 30.5012,
    '1360_862': 30.3257,
    '1360_863': 30.159,
    '1360_864': 30.0003,
    '1360_865': 29.8469,
    '1360_866': 29.6962,
    '1360_867': 29.5471,
    '1360_868': 29.3982,
    '1360_869': 29.246,
    '1360_870': 29.0937,
    '1360_871': 28.9431,
    '1360_872': 28.798,
    '1360_873': 28.6598,
    '1360_874': 28.5279,
    '1360_875': 28.4044,
    '1360_876': 28.2899,
    '1360_877': 28.1889,
    '1360_878': 28.0999,
    '1360_879': 28.0173,
    '1360_880': 27.937,
    '1360_881': 27.8582,
    '1360_882': 27.7885,
    '1360_883': 27.7384,
    '1360_884': 27.7121,
    '1360_885': 27.7048,
    '1360_886': 27.7084,
    '1360_887': 27.7213,
    '1360_888': 27.7565,
    '1360_889': 27.8097,
    '1360_890': 27.8907,
    '1360_891': 27.9966,
    '1360_892': 28.1322,
    '1360_893': 28.2918,
    '1360_894': 28.476,
    '1360_895': 28.6837,
    '1360_896': 28.9137,
    '1360_897': 29.1643,
    '1360_898': 29.4306,
    '1360_899': 29.6824,
    '1360_900': 29.8981,
    '1360_901': 30.079,
    '1360_902': 30.2474,
    '1360_903': 30.4189,
    '1360_904': 30.6172,
    '1360_905': 30.8214,
    '1360_906': 31.0064,
    '1360_907': 31.1553,
    '1360_908': 31.2698,
    '1360_909': 31.3493,
    '1360_910': 31.3922,
    '1360_911': 31.3931,
    '1360_912': 31.3224,
    '1360_913': 31.199,
    '1360_914': 31.0444,
    '1360_915': 30.8445,
    '1360_916': 30.611,
    '1360_917': 30.3594,
    '1360_918': 30.1062,
    '1360_919': 29.8568,
    '1360_920': 29.6141,
    '1360_921': 29.3745,
    '1360_922': 29.138,
    '1360_923': 28.8996,
    '1360_924': 28.6687,
    '1360_925': 28.4379,
    '1360_926': 28.2011,
    '1360_927': 27.9633,
    '1360_928': 27.7368,
    '1360_929': 27.5298,
    '1360_930': 27.3455,
    '1360_931': 27.1843,
    '1360_932': 27.0471,
    '1360_933': 26.9376,
    '1360_934': 26.8225,
    '1360_935': 26.6824,
    '1360_936': 26.5104,
    '1360_937': 26.3156,
    '1360_938': 26.1032,
    '1360_939': 25.8845,
    '1360_940': 25.6914,
    '1360_941': 25.5145,
    '1360_942': 25.3483,
    '1360_943': 25.1935,
    '1360_944': 25.0496,
    '1360_945': 24.919,
    '1360_946': 24.8053,
    '1360_947': 24.6964,
    '1360_948': 24.5964,
    '1360_949': 24.5002,
    '1360_950': 24.4055,
    '1360_951': 24.3104,
    '1360_952': 24.2139,
    '1360_953': 24.1144,
    '1360_954': 24.011,
    '1360_955': 23.9047,
    '1360_956': 23.7935,
    '1360_957': 23.6806,
    '1360_958': 23.5652,
    '1360_959': 23.4509,
    '1360_960': 23.3524,
    '1361_788': 31.133,
    '1361_789': 31.3632,
    '1361_790': 31.6057,
    '1361_791': 31.8549,
    '1361_792': 32.1063,
    '1361_793': 32.3521,
    '1361_794': 32.5808,
    '1361_795': 32.7972,
    '1361_796': 32.9893,
    '1361_797': 33.1467,
    '1361_798': 33.2646,
    '1361_799': 33.3412,
    '1361_800': 33.3955,
    '1361_801': 33.4511,
    '1361_802': 33.5201,
    '1361_803': 33.5945,
    '1361_804': 33.6786,
    '1361_805': 33.7412,
    '1361_806': 33.7621,
    '1361_807': 33.7596,
    '1361_808': 33.7331,
    '1361_809': 33.6903,
    '1361_810': 33.637,
    '1361_811': 33.5858,
    '1361_812': 33.5558,
    '1361_813': 33.5487,
    '1361_814': 33.5604,
    '1361_815': 33.5859,
    '1361_816': 33.6183,
    '1361_817': 33.6576,
    '1361_818': 33.7053,
    '1361_819': 33.7429,
    '1361_820': 33.7641,
    '1361_821': 33.7631,
    '1361_822': 33.7349,
    '1361_823': 33.7149,
    '1361_824': 33.7183,
    '1361_825': 33.7282,
    '1361_826': 33.7209,
    '1361_827': 33.6879,
    '1361_828': 33.639,
    '1361_829': 33.5954,
    '1361_830': 33.5605,
    '1361_831': 33.5339,
    '1361_832': 33.5192,
    '1361_833': 33.5159,
    '1361_834': 33.5266,
    '1361_835': 33.5518,
    '1361_836': 33.5819,
    '1361_837': 33.6156,
    '1361_838': 33.6262,
    '1361_839': 33.6106,
    '1361_840': 33.5749,
    '1361_841': 33.5296,
    '1361_842': 33.4771,
    '1361_843': 33.4173,
    '1361_844': 33.3561,
    '1361_845': 33.2887,
    '1361_846': 33.2074,
    '1361_847': 33.1036,
    '1361_848': 32.9628,
    '1361_849': 32.8043,
    '1361_850': 32.6395,
    '1361_851': 32.4783,
    '1361_852': 32.3177,
    '1361_853': 32.1617,
    '1361_854': 32.0099,
    '1361_855': 31.8382,
    '1361_856': 31.6343,
    '1361_857': 31.406,
    '1361_858': 31.1741,
    '1361_859': 30.9526,
    '1361_860': 30.7487,
    '1361_861': 30.559,
    '1361_862': 30.3814,
    '1361_863': 30.2142,
    '1361_864': 30.0557,
    '1361_865': 29.9036,
    '1361_866': 29.7545,
    '1361_867': 29.6078,
    '1361_868': 29.4617,
    '1361_869': 29.3145,
    '1361_870': 29.1685,
    '1361_871': 29.0253,
    '1361_872': 28.8867,
    '1361_873': 28.7552,
    '1361_874': 28.6312,
    '1361_875': 28.5149,
    '1361_876': 28.4064,
    '1361_877': 28.3121,
    '1361_878': 28.2277,
    '1361_879': 28.148,
    '1361_880': 28.0727,
    '1361_881': 28.0001,
    '1361_882': 27.9386,
    '1361_883': 27.895,
    '1361_884': 27.865,
    '1361_885': 27.8575,
    '1361_886': 27.8687,
    '1361_887': 27.889,
    '1361_888': 27.9213,
    '1361_889': 27.9788,
    '1361_890': 28.0636,
    '1361_891': 28.1707,
    '1361_892': 28.3053,
    '1361_893': 28.4676,
    '1361_894': 28.6555,
    '1361_895': 28.8648,
    '1361_896': 29.0922,
    '1361_897': 29.3441,
    '1361_898': 29.6112,
    '1361_899': 29.8593,
    '1361_900': 30.0785,
    '1361_901': 30.2698,
    '1361_902': 30.4477,
    '1361_903': 30.6231,
    '1361_904': 30.8197,
    '1361_905': 31.0248,
    '1361_906': 31.2096,
    '1361_907': 31.355,
    '1361_908': 31.4687,
    '1361_909': 31.5416,
    '1361_910': 31.5628,
    '1361_911': 31.5335,
    '1361_912': 31.4569,
    '1361_913': 31.3312,
    '1361_914': 31.1599,
    '1361_915': 30.9472,
    '1361_916': 30.7089,
    '1361_917': 30.4417,
    '1361_918': 30.1778,
    '1361_919': 29.9228,
    '1361_920': 29.6783,
    '1361_921': 29.4403,
    '1361_922': 29.2025,
    '1361_923': 28.9681,
    '1361_924': 28.7413,
    '1361_925': 28.5183,
    '1361_926': 28.2876,
    '1361_927': 28.0568,
    '1361_928': 27.8361,
    '1361_929': 27.6367,
    '1361_930': 27.4583,
    '1361_931': 27.3014,
    '1361_932': 27.168,
    '1361_933': 27.0617,
    '1361_934': 26.9513,
    '1361_935': 26.8196,
    '1361_936': 26.6583,
    '1361_937': 26.4755,
    '1361_938': 26.2702,
    '1361_939': 26.0665,
    '1361_940': 25.8878,
    '1361_941': 25.7212,
    '1361_942': 25.5629,
    '1361_943': 25.4154,
    '1361_944': 25.2801,
    '1361_945': 25.1554,
    '1361_946': 25.0485,
    '1361_947': 24.9503,
    '1361_948': 24.8519,
    '1361_949': 24.7609,
    '1361_950': 24.6723,
    '1361_951': 24.5842,
    '1361_952': 24.4958,
    '1361_953': 24.4057,
    '1361_954': 24.3093,
    '1361_955': 24.2084,
    '1361_956': 24.1048,
    '1361_957': 24.0037,
    '1361_958': 23.9001,
    '1361_959': 23.7918,
    '1361_960': 23.6981,
    '1362_788': 31.0407,
    '1362_789': 31.2566,
    '1362_790': 31.487,
    '1362_791': 31.725,
    '1362_792': 31.9662,
    '1362_793': 32.2009,
    '1362_794': 32.422,
    '1362_795': 32.6181,
    '1362_796': 32.7971,
    '1362_797': 32.9468,
    '1362_798': 33.0668,
    '1362_799': 33.1604,
    '1362_800': 33.2373,
    '1362_801': 33.3074,
    '1362_802': 33.3814,
    '1362_803': 33.4657,
    '1362_804': 33.5494,
    '1362_805': 33.61,
    '1362_806': 33.6415,
    '1362_807': 33.655,
    '1362_808': 33.6511,
    '1362_809': 33.6255,
    '1362_810': 33.584,
    '1362_811': 33.5515,
    '1362_812': 33.54,
    '1362_813': 33.5511,
    '1362_814': 33.5822,
    '1362_815': 33.6125,
    '1362_816': 33.6378,
    '1362_817': 33.6684,
    '1362_818': 33.7099,
    '1362_819': 33.7477,
    '1362_820': 33.771,
    '1362_821': 33.7691,
    '1362_822': 33.7428,
    '1362_823': 33.7292,
    '1362_824': 33.7414,
    '1362_825': 33.7566,
    '1362_826': 33.753,
    '1362_827': 33.7239,
    '1362_828': 33.6739,
    '1362_829': 33.6226,
    '1362_830': 33.5827,
    '1362_831': 33.5525,
    '1362_832': 33.535,
    '1362_833': 33.5317,
    '1362_834': 33.5429,
    '1362_835': 33.5692,
    '1362_836': 33.6045,
    '1362_837': 33.6312,
    '1362_838': 33.6321,
    '1362_839': 33.6091,
    '1362_840': 33.5737,
    '1362_841': 33.5322,
    '1362_842': 33.4885,
    '1362_843': 33.4377,
    '1362_844': 33.3808,
    '1362_845': 33.3131,
    '1362_846': 33.2301,
    '1362_847': 33.1325,
    '1362_848': 33.0092,
    '1362_849': 32.8596,
    '1362_850': 32.699,
    '1362_851': 32.5353,
    '1362_852': 32.369,
    '1362_853': 32.215,
    '1362_854': 32.0727,
    '1362_855': 31.9048,
    '1362_856': 31.7007,
    '1362_857': 31.4722,
    '1362_858': 31.239,
    '1362_859': 31.0153,
    '1362_860': 30.8076,
    '1362_861': 30.6154,
    '1362_862': 30.4373,
    '1362_863': 30.2695,
    '1362_864': 30.1107,
    '1362_865': 29.9597,
    '1362_866': 29.8131,
    '1362_867': 29.6692,
    '1362_868': 29.527,
    '1362_869': 29.3853,
    '1362_870': 29.2461,
    '1362_871': 29.11,
    '1362_872': 28.9786,
    '1362_873': 28.854,
    '1362_874': 28.7362,
    '1362_875': 28.6261,
    '1362_876': 28.5238,
    '1362_877': 28.4348,
    '1362_878': 28.3544,
    '1362_879': 28.2803,
    '1362_880': 28.2115,
    '1362_881': 28.1469,
    '1362_882': 28.0914,
    '1362_883': 28.0512,
    '1362_884': 28.0213,
    '1362_885': 28.0095,
    '1362_886': 28.0262,
    '1362_887': 28.0541,
    '1362_888': 28.09,
    '1362_889': 28.1445,
    '1362_890': 28.2293,
    '1362_891': 28.3407,
    '1362_892': 28.4797,
    '1362_893': 28.6458,
    '1362_894': 28.8357,
    '1362_895': 29.0449,
    '1362_896': 29.2717,
    '1362_897': 29.523,
    '1362_898': 29.7903,
    '1362_899': 30.0362,
    '1362_900': 30.2566,
    '1362_901': 30.4554,
    '1362_902': 30.6381,
    '1362_903': 30.8211,
    '1362_904': 31.0245,
    '1362_905': 31.2332,
    '1362_906': 31.4019,
    '1362_907': 31.5524,
    '1362_908': 31.6511,
    '1362_909': 31.7162,
    '1362_910': 31.7219,
    '1362_911': 31.6848,
    '1362_912': 31.5795,
    '1362_913': 31.4472,
    '1362_914': 31.2552,
    '1362_915': 31.0288,
    '1362_916': 30.7779,
    '1362_917': 30.5009,
    '1362_918': 30.2296,
    '1362_919': 29.9736,
    '1362_920': 29.7293,
    '1362_921': 29.4929,
    '1362_922': 29.2586,
    '1362_923': 29.0282,
    '1362_924': 28.809,
    '1362_925': 28.5915,
    '1362_926': 28.368,
    '1362_927': 28.1447,
    '1362_928': 27.9321,
    '1362_929': 27.7389,
    '1362_930': 27.5653,
    '1362_931': 27.412,
    '1362_932': 27.2809,
    '1362_933': 27.1736,
    '1362_934': 27.072,
    '1362_935': 26.9456,
    '1362_936': 26.7922,
    '1362_937': 26.6182,
    '1362_938': 26.4277,
    '1362_939': 26.2463,
    '1362_940': 26.0833,
    '1362_941': 25.9269,
    '1362_942': 25.776,
    '1362_943': 25.6373,
    '1362_944': 25.5106,
    '1362_945': 25.3957,
    '1362_946': 25.296,
    '1362_947': 25.2046,
    '1362_948': 25.1169,
    '1362_949': 25.0266,
    '1362_950': 24.9418,
    '1362_951': 24.8583,
    '1362_952': 24.775,
    '1362_953': 24.6909,
    '1362_954': 24.6027,
    '1362_955': 24.5083,
    '1362_956': 24.4126,
    '1362_957': 24.3184,
    '1362_958': 24.2225,
    '1362_959': 24.1247,
    '1362_960': 24.0344,
    '1363_788': 30.9491,
    '1363_789': 31.1523,
    '1363_790': 31.3699,
    '1363_791': 31.595,
    '1363_792': 31.8262,
    '1363_793': 32.0506,
    '1363_794': 32.2641,
    '1363_795': 32.4571,
    '1363_796': 32.6291,
    '1363_797': 32.7764,
    '1363_798': 32.9016,
    '1363_799': 33.0076,
    '1363_800': 33.0991,
    '1363_801': 33.1741,
    '1363_802': 33.2513,
    '1363_803': 33.3349,
    '1363_804': 33.4173,
    '1363_805': 33.4826,
    '1363_806': 33.5222,
    '1363_807': 33.5568,
    '1363_808': 33.5727,
    '1363_809': 33.5666,
    '1363_810': 33.5477,
    '1363_811': 33.5336,
    '1363_812': 33.538,
    '1363_813': 33.5655,
    '1363_814': 33.6115,
    '1363_815': 33.639,
    '1363_816': 33.6488,
    '1363_817': 33.6562,
    '1363_818': 33.6847,
    '1363_819': 33.7241,
    '1363_820': 33.7527,
    '1363_821': 33.7584,
    '1363_822': 33.738,
    '1363_823': 33.7233,
    '1363_824': 33.7379,
    '1363_825': 33.7547,
    '1363_826': 33.7544,
    '1363_827': 33.7299,
    '1363_828': 33.6859,
    '1363_829': 33.6364,
    '1363_830': 33.5961,
    '1363_831': 33.5647,
    '1363_832': 33.5432,
    '1363_833': 33.5413,
    '1363_834': 33.5534,
    '1363_835': 33.5798,
    '1363_836': 33.6127,
    '1363_837': 33.6307,
    '1363_838': 33.623,
    '1363_839': 33.5971,
    '1363_840': 33.5633,
    '1363_841': 33.5257,
    '1363_842': 33.4873,
    '1363_843': 33.4436,
    '1363_844': 33.395,
    '1363_845': 33.3349,
    '1363_846': 33.2595,
    '1363_847': 33.1676,
    '1363_848': 33.0509,
    '1363_849': 32.904,
    '1363_850': 32.7386,
    '1363_851': 32.5581,
    '1363_852': 32.3817,
    '1363_853': 32.2366,
    '1363_854': 32.1026,
    '1363_855': 31.9402,
    '1363_856': 31.743,
    '1363_857': 31.5245,
    '1363_858': 31.2964,
    '1363_859': 31.0733,
    '1363_860': 30.8645,
    '1363_861': 30.6695,
    '1363_862': 30.4902,
    '1363_863': 30.3223,
    '1363_864': 30.1641,
    '1363_865': 30.0155,
    '1363_866': 29.8718,
    '1363_867': 29.7317,
    '1363_868': 29.5944,
    '1363_869': 29.4587,
    '1363_870': 29.3261,
    '1363_871': 29.1972,
    '1363_872': 29.0737,
    '1363_873': 28.9567,
    '1363_874': 28.8453,
    '1363_875': 28.7378,
    '1363_876': 28.6412,
    '1363_877': 28.5572,
    '1363_878': 28.4818,
    '1363_879': 28.4131,
    '1363_880': 28.3498,
    '1363_881': 28.2934,
    '1363_882': 28.2427,
    '1363_883': 28.2027,
    '1363_884': 28.1721,
    '1363_885': 28.1617,
    '1363_886': 28.1817,
    '1363_887': 28.2161,
    '1363_888': 28.26,
    '1363_889': 28.3188,
    '1363_890': 28.3976,
    '1363_891': 28.5066,
    '1363_892': 28.647,
    '1363_893': 28.8166,
    '1363_894': 29.0079,
    '1363_895': 29.2181,
    '1363_896': 29.4505,
    '1363_897': 29.6991,
    '1363_898': 29.9614,
    '1363_899': 30.2056,
    '1363_900': 30.4271,
    '1363_901': 30.6264,
    '1363_902': 30.812,
    '1363_903': 31.003,
    '1363_904': 31.212,
    '1363_905': 31.4135,
    '1363_906': 31.5908,
    '1363_907': 31.712,
    '1363_908': 31.8101,
    '1363_909': 31.8662,
    '1363_910': 31.8498,
    '1363_911': 31.8012,
    '1363_912': 31.6852,
    '1363_913': 31.5203,
    '1363_914': 31.3229,
    '1363_915': 31.0854,
    '1363_916': 30.8227,
    '1363_917': 30.5426,
    '1363_918': 30.2737,
    '1363_919': 30.0169,
    '1363_920': 29.7715,
    '1363_921': 29.5362,
    '1363_922': 29.3069,
    '1363_923': 29.0815,
    '1363_924': 28.8645,
    '1363_925': 28.6555,
    '1363_926': 28.441,
    '1363_927': 28.2255,
    '1363_928': 28.0216,
    '1363_929': 27.8357,
    '1363_930': 27.6673,
    '1363_931': 27.5174,
    '1363_932': 27.386,
    '1363_933': 27.2728,
    '1363_934': 27.1744,
    '1363_935': 27.0563,
    '1363_936': 26.9093,
    '1363_937': 26.7478,
    '1363_938': 26.5793,
    '1363_939': 26.4222,
    '1363_940': 26.2767,
    '1363_941': 26.1315,
    '1363_942': 25.9882,
    '1363_943': 25.8582,
    '1363_944': 25.7415,
    '1363_945': 25.6371,
    '1363_946': 25.5456,
    '1363_947': 25.4616,
    '1363_948': 25.3815,
    '1363_949': 25.3007,
    '1363_950': 25.2147,
    '1363_951': 25.1332,
    '1363_952': 25.0528,
    '1363_953': 24.9722,
    '1363_954': 24.8889,
    '1363_955': 24.8026,
    '1363_956': 24.7153,
    '1363_957': 24.6289,
    '1363_958': 24.5425,
    '1363_959': 24.4549,
    '1363_960': 24.3736,
    '1364_788': 30.8633,
    '1364_789': 31.0563,
    '1364_790': 31.2617,
    '1364_791': 31.475,
    '1364_792': 31.6916,
    '1364_793': 31.906,
    '1364_794': 32.1147,
    '1364_795': 32.3042,
    '1364_796': 32.4713,
    '1364_797': 32.617,
    '1364_798': 32.7473,
    '1364_799': 32.8621,
    '1364_800': 32.9656,
    '1364_801': 33.0525,
    '1364_802': 33.1259,
    '1364_803': 33.2045,
    '1364_804': 33.2826,
    '1364_805': 33.3516,
    '1364_806': 33.4059,
    '1364_807': 33.4575,
    '1364_808': 33.4949,
    '1364_809': 33.5127,
    '1364_810': 33.5119,
    '1364_811': 33.5114,
    '1364_812': 33.5298,
    '1364_813': 33.5797,
    '1364_814': 33.6341,
    '1364_815': 33.6543,
    '1364_816': 33.6442,
    '1364_817': 33.6323,
    '1364_818': 33.6459,
    '1364_819': 33.681,
    '1364_820': 33.7159,
    '1364_821': 33.7346,
    '1364_822': 33.7214,
    '1364_823': 33.7006,
    '1364_824': 33.7077,
    '1364_825': 33.7237,
    '1364_826': 33.7256,
    '1364_827': 33.7061,
    '1364_828': 33.6723,
    '1364_829': 33.6318,
    '1364_830': 33.5941,
    '1364_831': 33.5644,
    '1364_832': 33.5466,
    '1364_833': 33.5466,
    '1364_834': 33.5585,
    '1364_835': 33.5832,
    '1364_836': 33.6073,
    '1364_837': 33.6164,
    '1364_838': 33.6053,
    '1364_839': 33.5826,
    '1364_840': 33.5533,
    '1364_841': 33.5226,
    '1364_842': 33.4902,
    '1364_843': 33.4537,
    '1364_844': 33.4162,
    '1364_845': 33.365,
    '1364_846': 33.2973,
    '1364_847': 33.2133,
    '1364_848': 33.1028,
    '1364_849': 32.9601,
    '1364_850': 32.781,
    '1364_851': 32.5787,
    '1364_852': 32.3973,
    '1364_853': 32.2447,
    '1364_854': 32.108,
    '1364_855': 31.952,
    '1364_856': 31.7708,
    '1364_857': 31.5696,
    '1364_858': 31.3496,
    '1364_859': 31.1287,
    '1364_860': 30.9189,
    '1364_861': 30.7231,
    '1364_862': 30.5426,
    '1364_863': 30.376,
    '1364_864': 30.2201,
    '1364_865': 30.0727,
    '1364_866': 29.9319,
    '1364_867': 29.7963,
    '1364_868': 29.6649,
    '1364_869': 29.5356,
    '1364_870': 29.4096,
    '1364_871': 29.2883,
    '1364_872': 29.173,
    '1364_873': 29.0652,
    '1364_874': 28.9585,
    '1364_875': 28.854,
    '1364_876': 28.7618,
    '1364_877': 28.6805,
    '1364_878': 28.6103,
    '1364_879': 28.5449,
    '1364_880': 28.4864,
    '1364_881': 28.4364,
    '1364_882': 28.3946,
    '1364_883': 28.3556,
    '1364_884': 28.3241,
    '1364_885': 28.3185,
    '1364_886': 28.3396,
    '1364_887': 28.3749,
    '1364_888': 28.4257,
    '1364_889': 28.4916,
    '1364_890': 28.5759,
    '1364_891': 28.6878,
    '1364_892': 28.8309,
    '1364_893': 29,
    '1364_894': 29.1853,
    '1364_895': 29.3986,
    '1364_896': 29.6351,
    '1364_897': 29.8837,
    '1364_898': 30.1346,
    '1364_899': 30.3667,
    '1364_900': 30.5798,
    '1364_901': 30.7812,
    '1364_902': 30.9669,
    '1364_903': 31.1655,
    '1364_904': 31.3752,
    '1364_905': 31.5775,
    '1364_906': 31.7564,
    '1364_907': 31.8867,
    '1364_908': 31.9582,
    '1364_909': 31.9947,
    '1364_910': 31.975,
    '1364_911': 31.8936,
    '1364_912': 31.7582,
    '1364_913': 31.5766,
    '1364_914': 31.3609,
    '1364_915': 31.1175,
    '1364_916': 30.8542,
    '1364_917': 30.5798,
    '1364_918': 30.31,
    '1364_919': 30.0519,
    '1364_920': 29.8075,
    '1364_921': 29.5746,
    '1364_922': 29.3493,
    '1364_923': 29.1293,
    '1364_924': 28.9156,
    '1364_925': 28.7117,
    '1364_926': 28.5058,
    '1364_927': 28.3015,
    '1364_928': 28.1064,
    '1364_929': 27.928,
    '1364_930': 27.7659,
    '1364_931': 27.6192,
    '1364_932': 27.4909,
    '1364_933': 27.3808,
    '1364_934': 27.2856,
    '1364_935': 27.17,
    '1364_936': 27.0254,
    '1364_937': 26.8789,
    '1364_938': 26.7324,
    '1364_939': 26.5958,
    '1364_940': 26.4648,
    '1364_941': 26.3313,
    '1364_942': 26.2004,
    '1364_943': 26.082,
    '1364_944': 25.9762,
    '1364_945': 25.8827,
    '1364_946': 25.8009,
    '1364_947': 25.7275,
    '1364_948': 25.6526,
    '1364_949': 25.576,
    '1364_950': 25.4964,
    '1364_951': 25.4117,
    '1364_952': 25.3328,
    '1364_953': 25.2545,
    '1364_954': 25.1752,
    '1364_955': 25.0952,
    '1364_956': 25.0154,
    '1364_957': 24.9364,
    '1364_958': 24.8585,
    '1364_959': 24.7826,
    '1364_960': 24.713,
    '1365_788': 30.7792,
    '1365_789': 30.9628,
    '1365_790': 31.1575,
    '1365_791': 31.3607,
    '1365_792': 31.5651,
    '1365_793': 31.7713,
    '1365_794': 31.977,
    '1365_795': 32.166,
    '1365_796': 32.3247,
    '1365_797': 32.4679,
    '1365_798': 32.6002,
    '1365_799': 32.7188,
    '1365_800': 32.8339,
    '1365_801': 32.926,
    '1365_802': 33.0019,
    '1365_803': 33.0796,
    '1365_804': 33.154,
    '1365_805': 33.2239,
    '1365_806': 33.2915,
    '1365_807': 33.3585,
    '1365_808': 33.4203,
    '1365_809': 33.4541,
    '1365_810': 33.463,
    '1365_811': 33.4745,
    '1365_812': 33.511,
    '1365_813': 33.5773,
    '1365_814': 33.6363,
    '1365_815': 33.6525,
    '1365_816': 33.6309,
    '1365_817': 33.6048,
    '1365_818': 33.6044,
    '1365_819': 33.6299,
    '1365_820': 33.6702,
    '1365_821': 33.6992,
    '1365_822': 33.6943,
    '1365_823': 33.6729,
    '1365_824': 33.6693,
    '1365_825': 33.6771,
    '1365_826': 33.6771,
    '1365_827': 33.6635,
    '1365_828': 33.6418,
    '1365_829': 33.6154,
    '1365_830': 33.582,
    '1365_831': 33.5565,
    '1365_832': 33.5428,
    '1365_833': 33.5439,
    '1365_834': 33.5578,
    '1365_835': 33.5854,
    '1365_836': 33.6037,
    '1365_837': 33.6003,
    '1365_838': 33.5886,
    '1365_839': 33.5702,
    '1365_840': 33.5501,
    '1365_841': 33.5294,
    '1365_842': 33.5095,
    '1365_843': 33.485,
    '1365_844': 33.4533,
    '1365_845': 33.4102,
    '1365_846': 33.3477,
    '1365_847': 33.2668,
    '1365_848': 33.1649,
    '1365_849': 33.0226,
    '1365_850': 32.836,
    '1365_851': 32.62,
    '1365_852': 32.4346,
    '1365_853': 32.2678,
    '1365_854': 32.114,
    '1365_855': 31.9603,
    '1365_856': 31.7971,
    '1365_857': 31.6122,
    '1365_858': 31.3975,
    '1365_859': 31.1782,
    '1365_860': 30.9686,
    '1365_861': 30.7732,
    '1365_862': 30.5938,
    '1365_863': 30.429,
    '1365_864': 30.2753,
    '1365_865': 30.1304,
    '1365_866': 29.9927,
    '1365_867': 29.8618,
    '1365_868': 29.7364,
    '1365_869': 29.6134,
    '1365_870': 29.4941,
    '1365_871': 29.3804,
    '1365_872': 29.273,
    '1365_873': 29.1719,
    '1365_874': 29.0697,
    '1365_875': 28.9718,
    '1365_876': 28.8853,
    '1365_877': 28.8037,
    '1365_878': 28.7351,
    '1365_879': 28.6733,
    '1365_880': 28.6222,
    '1365_881': 28.5791,
    '1365_882': 28.5443,
    '1365_883': 28.5114,
    '1365_884': 28.4837,
    '1365_885': 28.4749,
    '1365_886': 28.4881,
    '1365_887': 28.5256,
    '1365_888': 28.5824,
    '1365_889': 28.6582,
    '1365_890': 28.7512,
    '1365_891': 28.8756,
    '1365_892': 29.0336,
    '1365_893': 29.2053,
    '1365_894': 29.3803,
    '1365_895': 29.5848,
    '1365_896': 29.8173,
    '1365_897': 30.0631,
    '1365_898': 30.3072,
    '1365_899': 30.532,
    '1365_900': 30.7352,
    '1365_901': 30.9267,
    '1365_902': 31.1052,
    '1365_903': 31.3029,
    '1365_904': 31.5175,
    '1365_905': 31.7178,
    '1365_906': 31.8783,
    '1365_907': 32.0082,
    '1365_908': 32.0912,
    '1365_909': 32.1249,
    '1365_910': 32.0776,
    '1365_911': 31.9694,
    '1365_912': 31.8144,
    '1365_913': 31.6164,
    '1365_914': 31.3894,
    '1365_915': 31.1446,
    '1365_916': 30.8848,
    '1365_917': 30.6109,
    '1365_918': 30.3384,
    '1365_919': 30.0801,
    '1365_920': 29.8373,
    '1365_921': 29.6067,
    '1365_922': 29.3848,
    '1365_923': 29.1702,
    '1365_924': 28.9618,
    '1365_925': 28.7606,
    '1365_926': 28.5631,
    '1365_927': 28.3678,
    '1365_928': 28.1822,
    '1365_929': 28.0137,
    '1365_930': 27.86,
    '1365_931': 27.718,
    '1365_932': 27.5911,
    '1365_933': 27.487,
    '1365_934': 27.3969,
    '1365_935': 27.2903,
    '1365_936': 27.152,
    '1365_937': 27.0142,
    '1365_938': 26.885,
    '1365_939': 26.7647,
    '1365_940': 26.6481,
    '1365_941': 26.5273,
    '1365_942': 26.4105,
    '1365_943': 26.3039,
    '1365_944': 26.2105,
    '1365_945': 26.1284,
    '1365_946': 26.0579,
    '1365_947': 25.9915,
    '1365_948': 25.9241,
    '1365_949': 25.8533,
    '1365_950': 25.7767,
    '1365_951': 25.6927,
    '1365_952': 25.6143,
    '1365_953': 25.537,
    '1365_954': 25.4603,
    '1365_955': 25.3848,
    '1365_956': 25.3117,
    '1365_957': 25.2397,
    '1365_958': 25.1702,
    '1365_959': 25.105,
    '1365_960': 25.0454,
    '1366_788': 30.6933,
    '1366_789': 30.8721,
    '1366_790': 31.0596,
    '1366_791': 31.2525,
    '1366_792': 31.4473,
    '1366_793': 31.6431,
    '1366_794': 31.8359,
    '1366_795': 32.0143,
    '1366_796': 32.1722,
    '1366_797': 32.3165,
    '1366_798': 32.4507,
    '1366_799': 32.5765,
    '1366_800': 32.6934,
    '1366_801': 32.7939,
    '1366_802': 32.8822,
    '1366_803': 32.9642,
    '1366_804': 33.0363,
    '1366_805': 33.1109,
    '1366_806': 33.1885,
    '1366_807': 33.2695,
    '1366_808': 33.3425,
    '1366_809': 33.3818,
    '1366_810': 33.3974,
    '1366_811': 33.4254,
    '1366_812': 33.4761,
    '1366_813': 33.5522,
    '1366_814': 33.6148,
    '1366_815': 33.6335,
    '1366_816': 33.6105,
    '1366_817': 33.5784,
    '1366_818': 33.5713,
    '1366_819': 33.5898,
    '1366_820': 33.6231,
    '1366_821': 33.6573,
    '1366_822': 33.6656,
    '1366_823': 33.6495,
    '1366_824': 33.6305,
    '1366_825': 33.6308,
    '1366_826': 33.6297,
    '1366_827': 33.6213,
    '1366_828': 33.61,
    '1366_829': 33.5965,
    '1366_830': 33.5728,
    '1366_831': 33.5571,
    '1366_832': 33.5493,
    '1366_833': 33.5497,
    '1366_834': 33.5607,
    '1366_835': 33.5864,
    '1366_836': 33.6014,
    '1366_837': 33.5974,
    '1366_838': 33.5792,
    '1366_839': 33.568,
    '1366_840': 33.563,
    '1366_841': 33.5595,
    '1366_842': 33.552,
    '1366_843': 33.5318,
    '1366_844': 33.5021,
    '1366_845': 33.4614,
    '1366_846': 33.4013,
    '1366_847': 33.3228,
    '1366_848': 33.2239,
    '1366_849': 33.0878,
    '1366_850': 32.9053,
    '1366_851': 32.6941,
    '1366_852': 32.5052,
    '1366_853': 32.3176,
    '1366_854': 32.152,
    '1366_855': 31.9981,
    '1366_856': 31.8417,
    '1366_857': 31.658,
    '1366_858': 31.4413,
    '1366_859': 31.2217,
    '1366_860': 31.0131,
    '1366_861': 30.8192,
    '1366_862': 30.6417,
    '1366_863': 30.4796,
    '1366_864': 30.3297,
    '1366_865': 30.189,
    '1366_866': 30.0555,
    '1366_867': 29.929,
    '1366_868': 29.809,
    '1366_869': 29.6917,
    '1366_870': 29.579,
    '1366_871': 29.4734,
    '1366_872': 29.3741,
    '1366_873': 29.2744,
    '1366_874': 29.1775,
    '1366_875': 29.0883,
    '1366_876': 29.0098,
    '1366_877': 28.9316,
    '1366_878': 28.8669,
    '1366_879': 28.8103,
    '1366_880': 28.7609,
    '1366_881': 28.7231,
    '1366_882': 28.6934,
    '1366_883': 28.6663,
    '1366_884': 28.6442,
    '1366_885': 28.6327,
    '1366_886': 28.6405,
    '1366_887': 28.6803,
    '1366_888': 28.7387,
    '1366_889': 28.8182,
    '1366_890': 28.9196,
    '1366_891': 29.0572,
    '1366_892': 29.2295,
    '1366_893': 29.4095,
    '1366_894': 29.5784,
    '1366_895': 29.7652,
    '1366_896': 29.9833,
    '1366_897': 30.2216,
    '1366_898': 30.4606,
    '1366_899': 30.6822,
    '1366_900': 30.8854,
    '1366_901': 31.0684,
    '1366_902': 31.2406,
    '1366_903': 31.4268,
    '1366_904': 31.6373,
    '1366_905': 31.8277,
    '1366_906': 31.9977,
    '1366_907': 32.1294,
    '1366_908': 32.1838,
    '1366_909': 32.1982,
    '1366_910': 32.1417,
    '1366_911': 32.0154,
    '1366_912': 31.8492,
    '1366_913': 31.6398,
    '1366_914': 31.4059,
    '1366_915': 31.1576,
    '1366_916': 30.8986,
    '1366_917': 30.6272,
    '1366_918': 30.3586,
    '1366_919': 30.1042,
    '1366_920': 29.8633,
    '1366_921': 29.6344,
    '1366_922': 29.4159,
    '1366_923': 29.2058,
    '1366_924': 29.0019,
    '1366_925': 28.8049,
    '1366_926': 28.6144,
    '1366_927': 28.4285,
    '1366_928': 28.2532,
    '1366_929': 28.095,
    '1366_930': 27.9488,
    '1366_931': 27.8125,
    '1366_932': 27.6865,
    '1366_933': 27.5828,
    '1366_934': 27.4976,
    '1366_935': 27.3965,
    '1366_936': 27.2747,
    '1366_937': 27.1535,
    '1366_938': 27.0357,
    '1366_939': 26.9296,
    '1366_940': 26.8279,
    '1366_941': 26.7208,
    '1366_942': 26.6178,
    '1366_943': 26.5241,
    '1366_944': 26.4433,
    '1366_945': 26.3742,
    '1366_946': 26.3154,
    '1366_947': 26.2587,
    '1366_948': 26.1963,
    '1366_949': 26.1308,
    '1366_950': 26.059,
    '1366_951': 25.9821,
    '1366_952': 25.8985,
    '1366_953': 25.8196,
    '1366_954': 25.7429,
    '1366_955': 25.6699,
    '1366_956': 25.6014,
    '1366_957': 25.5371,
    '1366_958': 25.4764,
    '1366_959': 25.4218,
    '1366_960': 25.3718,
    '1367_788': 30.6127,
    '1367_789': 30.7856,
    '1367_790': 30.9665,
    '1367_791': 31.1505,
    '1367_792': 31.3355,
    '1367_793': 31.52,
    '1367_794': 31.7016,
    '1367_795': 31.8707,
    '1367_796': 32.0243,
    '1367_797': 32.1689,
    '1367_798': 32.3045,
    '1367_799': 32.4361,
    '1367_800': 32.556,
    '1367_801': 32.6625,
    '1367_802': 32.757,
    '1367_803': 32.8449,
    '1367_804': 32.9279,
    '1367_805': 33.0042,
    '1367_806': 33.0904,
    '1367_807': 33.1834,
    '1367_808': 33.2585,
    '1367_809': 33.2999,
    '1367_810': 33.3246,
    '1367_811': 33.3644,
    '1367_812': 33.423,
    '1367_813': 33.5041,
    '1367_814': 33.5674,
    '1367_815': 33.5933,
    '1367_816': 33.5831,
    '1367_817': 33.5544,
    '1367_818': 33.5492,
    '1367_819': 33.5643,
    '1367_820': 33.5815,
    '1367_821': 33.6128,
    '1367_822': 33.6321,
    '1367_823': 33.6192,
    '1367_824': 33.595,
    '1367_825': 33.5943,
    '1367_826': 33.5942,
    '1367_827': 33.586,
    '1367_828': 33.5777,
    '1367_829': 33.5739,
    '1367_830': 33.571,
    '1367_831': 33.5736,
    '1367_832': 33.575,
    '1367_833': 33.5723,
    '1367_834': 33.568,
    '1367_835': 33.5801,
    '1367_836': 33.5913,
    '1367_837': 33.5879,
    '1367_838': 33.5761,
    '1367_839': 33.5767,
    '1367_840': 33.5846,
    '1367_841': 33.5948,
    '1367_842': 33.5955,
    '1367_843': 33.5782,
    '1367_844': 33.5474,
    '1367_845': 33.5067,
    '1367_846': 33.4466,
    '1367_847': 33.369,
    '1367_848': 33.2735,
    '1367_849': 33.1461,
    '1367_850': 32.9792,
    '1367_851': 32.7879,
    '1367_852': 32.5945,
    '1367_853': 32.3915,
    '1367_854': 32.2183,
    '1367_855': 32.0708,
    '1367_856': 31.9102,
    '1367_857': 31.703,
    '1367_858': 31.4801,
    '1367_859': 31.2605,
    '1367_860': 31.0536,
    '1367_861': 30.8621,
    '1367_862': 30.6875,
    '1367_863': 30.5287,
    '1367_864': 30.3832,
    '1367_865': 30.2477,
    '1367_866': 30.1192,
    '1367_867': 29.9973,
    '1367_868': 29.8825,
    '1367_869': 29.7706,
    '1367_870': 29.6646,
    '1367_871': 29.5672,
    '1367_872': 29.4742,
    '1367_873': 29.3749,
    '1367_874': 29.2822,
    '1367_875': 29.2029,
    '1367_876': 29.1342,
    '1367_877': 29.0628,
    '1367_878': 29.0007,
    '1367_879': 28.9508,
    '1367_880': 28.9057,
    '1367_881': 28.867,
    '1367_882': 28.8412,
    '1367_883': 28.8189,
    '1367_884': 28.8019,
    '1367_885': 28.792,
    '1367_886': 28.8027,
    '1367_887': 28.8464,
    '1367_888': 28.9075,
    '1367_889': 28.9815,
    '1367_890': 29.0889,
    '1367_891': 29.2409,
    '1367_892': 29.4233,
    '1367_893': 29.5996,
    '1367_894': 29.7566,
    '1367_895': 29.9345,
    '1367_896': 30.1332,
    '1367_897': 30.3612,
    '1367_898': 30.5942,
    '1367_899': 30.8132,
    '1367_900': 31.0138,
    '1367_901': 31.1959,
    '1367_902': 31.3656,
    '1367_903': 31.5477,
    '1367_904': 31.7475,
    '1367_905': 31.9333,
    '1367_906': 32.096,
    '1367_907': 32.215,
    '1367_908': 32.2737,
    '1367_909': 32.2721,
    '1367_910': 32.2052,
    '1367_911': 32.0629,
    '1367_912': 31.8789,
    '1367_913': 31.6551,
    '1367_914': 31.4148,
    '1367_915': 31.1616,
    '1367_916': 30.8995,
    '1367_917': 30.6345,
    '1367_918': 30.3748,
    '1367_919': 30.1254,
    '1367_920': 29.8882,
    '1367_921': 29.6621,
    '1367_922': 29.4457,
    '1367_923': 29.2386,
    '1367_924': 29.0375,
    '1367_925': 28.8446,
    '1367_926': 28.6593,
    '1367_927': 28.4831,
    '1367_928': 28.3192,
    '1367_929': 28.1715,
    '1367_930': 28.0322,
    '1367_931': 27.9018,
    '1367_932': 27.7824,
    '1367_933': 27.6773,
    '1367_934': 27.5897,
    '1367_935': 27.4965,
    '1367_936': 27.3927,
    '1367_937': 27.29,
    '1367_938': 27.1847,
    '1367_939': 27.0914,
    '1367_940': 27.0005,
    '1367_941': 26.9092,
    '1367_942': 26.8202,
    '1367_943': 26.7411,
    '1367_944': 26.674,
    '1367_945': 26.6188,
    '1367_946': 26.5703,
    '1367_947': 26.5249,
    '1367_948': 26.4681,
    '1367_949': 26.4061,
    '1367_950': 26.3401,
    '1367_951': 26.2678,
    '1367_952': 26.1891,
    '1367_953': 26.1025,
    '1367_954': 26.0233,
    '1367_955': 25.9506,
    '1367_956': 25.8854,
    '1367_957': 25.827,
    '1367_958': 25.7751,
    '1367_959': 25.7312,
    '1367_960': 25.6924,
    '1368_788': 30.5369,
    '1368_789': 30.7044,
    '1368_790': 30.8783,
    '1368_791': 31.0539,
    '1368_792': 31.2303,
    '1368_793': 31.4063,
    '1368_794': 31.5789,
    '1368_795': 31.7407,
    '1368_796': 31.8902,
    '1368_797': 32.032,
    '1368_798': 32.1684,
    '1368_799': 32.2998,
    '1368_800': 32.4221,
    '1368_801': 32.5324,
    '1368_802': 32.6302,
    '1368_803': 32.7231,
    '1368_804': 32.814,
    '1368_805': 32.8975,
    '1368_806': 32.9888,
    '1368_807': 33.0851,
    '1368_808': 33.1638,
    '1368_809': 33.2086,
    '1368_810': 33.2475,
    '1368_811': 33.2938,
    '1368_812': 33.3548,
    '1368_813': 33.4368,
    '1368_814': 33.498,
    '1368_815': 33.5354,
    '1368_816': 33.5353,
    '1368_817': 33.5207,
    '1368_818': 33.5187,
    '1368_819': 33.5284,
    '1368_820': 33.538,
    '1368_821': 33.5595,
    '1368_822': 33.5785,
    '1368_823': 33.5762,
    '1368_824': 33.5662,
    '1368_825': 33.5714,
    '1368_826': 33.5709,
    '1368_827': 33.5615,
    '1368_828': 33.5564,
    '1368_829': 33.5613,
    '1368_830': 33.5795,
    '1368_831': 33.6065,
    '1368_832': 33.6257,
    '1368_833': 33.6143,
    '1368_834': 33.5824,
    '1368_835': 33.5696,
    '1368_836': 33.5731,
    '1368_837': 33.5764,
    '1368_838': 33.5776,
    '1368_839': 33.5846,
    '1368_840': 33.5989,
    '1368_841': 33.6174,
    '1368_842': 33.6258,
    '1368_843': 33.6138,
    '1368_844': 33.5835,
    '1368_845': 33.5372,
    '1368_846': 33.4753,
    '1368_847': 33.3981,
    '1368_848': 33.3056,
    '1368_849': 33.1873,
    '1368_850': 33.0375,
    '1368_851': 32.8612,
    '1368_852': 32.6728,
    '1368_853': 32.476,
    '1368_854': 32.3047,
    '1368_855': 32.1432,
    '1368_856': 31.9608,
    '1368_857': 31.7411,
    '1368_858': 31.5147,
    '1368_859': 31.2954,
    '1368_860': 31.0909,
    '1368_861': 30.903,
    '1368_862': 30.7319,
    '1368_863': 30.5761,
    '1368_864': 30.4347,
    '1368_865': 30.3038,
    '1368_866': 30.1805,
    '1368_867': 30.0636,
    '1368_868': 29.9531,
    '1368_869': 29.8476,
    '1368_870': 29.7489,
    '1368_871': 29.6584,
    '1368_872': 29.5697,
    '1368_873': 29.4728,
    '1368_874': 29.3853,
    '1368_875': 29.3142,
    '1368_876': 29.2543,
    '1368_877': 29.1915,
    '1368_878': 29.1345,
    '1368_879': 29.0915,
    '1368_880': 29.0518,
    '1368_881': 29.0191,
    '1368_882': 28.9903,
    '1368_883': 28.972,
    '1368_884': 28.961,
    '1368_885': 28.9541,
    '1368_886': 28.9655,
    '1368_887': 29.0143,
    '1368_888': 29.0793,
    '1368_889': 29.1559,
    '1368_890': 29.2629,
    '1368_891': 29.4182,
    '1368_892': 29.6032,
    '1368_893': 29.7732,
    '1368_894': 29.9243,
    '1368_895': 30.0907,
    '1368_896': 30.2722,
    '1368_897': 30.4826,
    '1368_898': 30.708,
    '1368_899': 30.9227,
    '1368_900': 31.1207,
    '1368_901': 31.3007,
    '1368_902': 31.4729,
    '1368_903': 31.6545,
    '1368_904': 31.8505,
    '1368_905': 32.0266,
    '1368_906': 32.18,
    '1368_907': 32.2981,
    '1368_908': 32.3524,
    '1368_909': 32.3268,
    '1368_910': 32.25,
    '1368_911': 32.0897,
    '1368_912': 31.8951,
    '1368_913': 31.6684,
    '1368_914': 31.4262,
    '1368_915': 31.1746,
    '1368_916': 30.9105,
    '1368_917': 30.6455,
    '1368_918': 30.3894,
    '1368_919': 30.1449,
    '1368_920': 29.9123,
    '1368_921': 29.6897,
    '1368_922': 29.4759,
    '1368_923': 29.2704,
    '1368_924': 29.0731,
    '1368_925': 28.8821,
    '1368_926': 28.7011,
    '1368_927': 28.5319,
    '1368_928': 28.3793,
    '1368_929': 28.2417,
    '1368_930': 28.1113,
    '1368_931': 27.9881,
    '1368_932': 27.875,
    '1368_933': 27.7731,
    '1368_934': 27.6861,
    '1368_935': 27.6012,
    '1368_936': 27.5085,
    '1368_937': 27.4171,
    '1368_938': 27.3288,
    '1368_939': 27.2489,
    '1368_940': 27.1765,
    '1368_941': 27.0962,
    '1368_942': 27.0202,
    '1368_943': 26.9561,
    '1368_944': 26.9039,
    '1368_945': 26.859,
    '1368_946': 26.8202,
    '1368_947': 26.7825,
    '1368_948': 26.7342,
    '1368_949': 26.6804,
    '1368_950': 26.6195,
    '1368_951': 26.5514,
    '1368_952': 26.4721,
    '1368_953': 26.3862,
    '1368_954': 26.2993,
    '1368_955': 26.2259,
    '1368_956': 26.1635,
    '1368_957': 26.1106,
    '1368_958': 26.0669,
    '1368_959': 26.0349,
    '1368_960': 26.0097,
    '1368_961': 25.9846,
    '1368_962': 25.9685,
    '1368_963': 25.9645,
    '1368_964': 25.9744,
    '1368_965': 25.9958,
    '1368_966': 26.0253,
    '1368_967': 26.0613,
    '1368_968': 26.102,
    '1368_969': 26.1422,
    '1368_970': 26.1799,
    '1368_971': 26.2212,
    '1368_972': 26.2564,
    '1368_973': 26.2927,
    '1368_974': 26.3303,
    '1368_975': 26.368,
    '1368_976': 26.4009,
    '1368_977': 26.4307,
    '1368_978': 26.4562,
    '1368_979': 26.4763,
    '1368_980': 26.4903,
    '1368_981': 26.4972,
    '1368_982': 26.4944,
    '1368_983': 26.4755,
    '1368_984': 26.4466,
    '1368_985': 26.4097,
    '1368_986': 26.3655,
    '1368_987': 26.3148,
    '1368_988': 26.2597,
    '1368_989': 26.1941,
    '1368_990': 26.117,
    '1368_991': 26.0533,
    '1368_992': 25.9764,
    '1368_993': 25.8892,
    '1368_994': 25.7926,
    '1368_995': 25.6793,
    '1368_996': 25.5577,
    '1368_997': 25.4352,
    '1368_998': 25.312,
    '1368_999': 25.1845,
    '1368_1000': 25.0514,
    '1368_1001': 24.9123,
    '1368_1002': 24.7668,
    '1368_1003': 24.613,
    '1368_1004': 24.4503,
    '1368_1005': 24.2834,
    '1368_1006': 24.1201,
    '1368_1007': 23.9574,
    '1368_1008': 23.7948,
    '1368_1009': 23.6292,
    '1368_1010': 23.4589,
    '1368_1011': 23.2816,
    '1368_1012': 23.0934,
    '1368_1013': 22.8882,
    '1368_1014': 22.67,
    '1368_1015': 22.4474,
    '1368_1016': 22.2206,
    '1368_1017': 21.9933,
    '1368_1018': 21.7673,
    '1368_1019': 21.5436,
    '1368_1020': 21.3181,
    '1368_1021': 21.086,
    '1368_1022': 20.8401,
    '1368_1023': 20.597,
    '1368_1024': 20.3595,
    '1368_1025': 20.1201,
    '1368_1026': 19.8717,
    '1368_1027': 19.6271,
    '1368_1028': 19.3929,
    '1368_1029': 19.1648,
    '1368_1030': 18.9408,
    '1368_1031': 18.7204,
    '1368_1032': 18.505,
    '1368_1033': 18.2933,
    '1369_788': 30.4633,
    '1369_789': 30.625,
    '1369_790': 30.7928,
    '1369_791': 30.9601,
    '1369_792': 31.128,
    '1369_793': 31.2949,
    '1369_794': 31.4584,
    '1369_795': 31.6146,
    '1369_796': 31.7609,
    '1369_797': 31.9,
    '1369_798': 32.0364,
    '1369_799': 32.1666,
    '1369_800': 32.2894,
    '1369_801': 32.3997,
    '1369_802': 32.5025,
    '1369_803': 32.6006,
    '1369_804': 32.7001,
    '1369_805': 32.7959,
    '1369_806': 32.8853,
    '1369_807': 32.9781,
    '1369_808': 33.0574,
    '1369_809': 33.1155,
    '1369_810': 33.1669,
    '1369_811': 33.218,
    '1369_812': 33.2789,
    '1369_813': 33.3568,
    '1369_814': 33.4191,
    '1369_815': 33.4627,
    '1369_816': 33.4688,
    '1369_817': 33.4716,
    '1369_818': 33.4779,
    '1369_819': 33.4891,
    '1369_820': 33.5029,
    '1369_821': 33.5211,
    '1369_822': 33.539,
    '1369_823': 33.551,
    '1369_824': 33.5598,
    '1369_825': 33.5661,
    '1369_826': 33.5632,
    '1369_827': 33.552,
    '1369_828': 33.5461,
    '1369_829': 33.557,
    '1369_830': 33.5875,
    '1369_831': 33.6343,
    '1369_832': 33.6488,
    '1369_833': 33.6448,
    '1369_834': 33.6018,
    '1369_835': 33.565,
    '1369_836': 33.5626,
    '1369_837': 33.5731,
    '1369_838': 33.5834,
    '1369_839': 33.596,
    '1369_840': 33.6129,
    '1369_841': 33.6311,
    '1369_842': 33.6411,
    '1369_843': 33.6298,
    '1369_844': 33.5984,
    '1369_845': 33.5493,
    '1369_846': 33.4834,
    '1369_847': 33.4063,
    '1369_848': 33.3193,
    '1369_849': 33.2109,
    '1369_850': 33.0752,
    '1369_851': 32.9107,
    '1369_852': 32.728,
    '1369_853': 32.5437,
    '1369_854': 32.3653,
    '1369_855': 32.1807,
    '1369_856': 31.9833,
    '1369_857': 31.7667,
    '1369_858': 31.545,
    '1369_859': 31.3277,
    '1369_860': 31.1274,
    '1369_861': 30.9437,
    '1369_862': 30.7755,
    '1369_863': 30.6228,
    '1369_864': 30.4845,
    '1369_865': 30.3575,
    '1369_866': 30.239,
    '1369_867': 30.1267,
    '1369_868': 30.021,
    '1369_869': 29.9218,
    '1369_870': 29.8303,
    '1369_871': 29.7463,
    '1369_872': 29.6612,
    '1369_873': 29.5685,
    '1369_874': 29.4878,
    '1369_875': 29.4221,
    '1369_876': 29.3686,
    '1369_877': 29.3147,
    '1369_878': 29.2647,
    '1369_879': 29.2301,
    '1369_880': 29.1976,
    '1369_881': 29.1708,
    '1369_882': 29.143,
    '1369_883': 29.1237,
    '1369_884': 29.1141,
    '1369_885': 29.1124,
    '1369_886': 29.1258,
    '1369_887': 29.1712,
    '1369_888': 29.2432,
    '1369_889': 29.3344,
    '1369_890': 29.4433,
    '1369_891': 29.5964,
    '1369_892': 29.7732,
    '1369_893': 29.9365,
    '1369_894': 30.0812,
    '1369_895': 30.2405,
    '1369_896': 30.4091,
    '1369_897': 30.5936,
    '1369_898': 30.8062,
    '1369_899': 31.0173,
    '1369_900': 31.2131,
    '1369_901': 31.3891,
    '1369_902': 31.5601,
    '1369_903': 31.7452,
    '1369_904': 31.9341,
    '1369_905': 32.1052,
    '1369_906': 32.2431,
    '1369_907': 32.3385,
    '1369_908': 32.3929,
    '1369_909': 32.369,
    '1369_910': 32.2815,
    '1369_911': 32.1223,
    '1369_912': 31.9145,
    '1369_913': 31.6847,
    '1369_914': 31.4451,
    '1369_915': 31.1964,
    '1369_916': 30.9297,
    '1369_917': 30.6617,
    '1369_918': 30.4058,
    '1369_919': 30.1645,
    '1369_920': 29.9356,
    '1369_921': 29.7158,
    '1369_922': 29.5043,
    '1369_923': 29.3012,
    '1369_924': 29.1074,
    '1369_925': 28.9195,
    '1369_926': 28.7424,
    '1369_927': 28.5795,
    '1369_928': 28.4345,
    '1369_929': 28.3049,
    '1369_930': 28.185,
    '1369_931': 28.0692,
    '1369_932': 27.9623,
    '1369_933': 27.8652,
    '1369_934': 27.7804,
    '1369_935': 27.7053,
    '1369_936': 27.6277,
    '1369_937': 27.5469,
    '1369_938': 27.471,
    '1369_939': 27.401,
    '1369_940': 27.3364,
    '1369_941': 27.2772,
    '1369_942': 27.2187,
    '1369_943': 27.1697,
    '1369_944': 27.1268,
    '1369_945': 27.0932,
    '1369_946': 27.0644,
    '1369_947': 27.0333,
    '1369_948': 26.9946,
    '1369_949': 26.9489,
    '1369_950': 26.8971,
    '1369_951': 26.8311,
    '1369_952': 26.7503,
    '1369_953': 26.6647,
    '1369_954': 26.5748,
    '1369_955': 26.4967,
    '1369_956': 26.4367,
    '1369_957': 26.3897,
    '1369_958': 26.3541,
    '1369_959': 26.3296,
    '1369_960': 26.3126,
    '1369_961': 26.2991,
    '1369_962': 26.2895,
    '1369_963': 26.2894,
    '1369_964': 26.3031,
    '1369_965': 26.3263,
    '1369_966': 26.3553,
    '1369_967': 26.3915,
    '1369_968': 26.4345,
    '1369_969': 26.4807,
    '1369_970': 26.5253,
    '1369_971': 26.5693,
    '1369_972': 26.6094,
    '1369_973': 26.6478,
    '1369_974': 26.6859,
    '1369_975': 26.7237,
    '1369_976': 26.7624,
    '1369_977': 26.797,
    '1369_978': 26.8254,
    '1369_979': 26.8468,
    '1369_980': 26.861,
    '1369_981': 26.8675,
    '1369_982': 26.865,
    '1369_983': 26.8498,
    '1369_984': 26.8247,
    '1369_985': 26.7916,
    '1369_986': 26.7507,
    '1369_987': 26.7006,
    '1369_988': 26.6517,
    '1369_989': 26.5932,
    '1369_990': 26.5151,
    '1369_991': 26.4405,
    '1369_992': 26.3602,
    '1369_993': 26.2715,
    '1369_994': 26.1744,
    '1369_995': 26.0659,
    '1369_996': 25.9512,
    '1369_997': 25.8346,
    '1369_998': 25.7137,
    '1369_999': 25.5891,
    '1369_1000': 25.4579,
    '1369_1001': 25.3209,
    '1369_1002': 25.1782,
    '1369_1003': 25.0283,
    '1369_1004': 24.8706,
    '1369_1005': 24.709,
    '1369_1006': 24.5486,
    '1369_1007': 24.3902,
    '1369_1008': 24.234,
    '1369_1009': 24.0748,
    '1369_1010': 23.9101,
    '1369_1011': 23.736,
    '1369_1012': 23.5489,
    '1369_1013': 23.3463,
    '1369_1014': 23.1327,
    '1369_1015': 22.9134,
    '1369_1016': 22.6872,
    '1369_1017': 22.4596,
    '1369_1018': 22.2362,
    '1369_1019': 22.0109,
    '1369_1020': 21.7808,
    '1369_1021': 21.5426,
    '1369_1022': 21.2939,
    '1369_1023': 21.0435,
    '1369_1024': 20.7994,
    '1369_1025': 20.5525,
    '1369_1026': 20.2874,
    '1369_1027': 20.0349,
    '1369_1028': 19.7902,
    '1369_1029': 19.5498,
    '1369_1030': 19.3145,
    '1369_1031': 19.0841,
    '1369_1032': 18.8614,
    '1369_1033': 18.6437,
    '1370_788': 30.3855,
    '1370_789': 30.5426,
    '1370_790': 30.704,
    '1370_791': 30.8654,
    '1370_792': 31.0262,
    '1370_793': 31.1849,
    '1370_794': 31.3399,
    '1370_795': 31.4893,
    '1370_796': 31.6338,
    '1370_797': 31.7724,
    '1370_798': 31.9063,
    '1370_799': 32.0354,
    '1370_800': 32.1581,
    '1370_801': 32.2731,
    '1370_802': 32.3823,
    '1370_803': 32.4874,
    '1370_804': 32.5909,
    '1370_805': 32.6912,
    '1370_806': 32.7845,
    '1370_807': 32.8773,
    '1370_808': 32.9601,
    '1370_809': 33.0331,
    '1370_810': 33.0912,
    '1370_811': 33.141,
    '1370_812': 33.2001,
    '1370_813': 33.2694,
    '1370_814': 33.3337,
    '1370_815': 33.3815,
    '1370_816': 33.397,
    '1370_817': 33.4219,
    '1370_818': 33.4486,
    '1370_819': 33.4709,
    '1370_820': 33.4912,
    '1370_821': 33.5132,
    '1370_822': 33.5354,
    '1370_823': 33.5551,
    '1370_824': 33.5729,
    '1370_825': 33.5815,
    '1370_826': 33.5787,
    '1370_827': 33.5632,
    '1370_828': 33.5493,
    '1370_829': 33.5571,
    '1370_830': 33.5877,
    '1370_831': 33.6353,
    '1370_832': 33.646,
    '1370_833': 33.6484,
    '1370_834': 33.6098,
    '1370_835': 33.5696,
    '1370_836': 33.5657,
    '1370_837': 33.5771,
    '1370_838': 33.59,
    '1370_839': 33.6097,
    '1370_840': 33.6291,
    '1370_841': 33.6395,
    '1370_842': 33.6444,
    '1370_843': 33.6312,
    '1370_844': 33.5959,
    '1370_845': 33.5419,
    '1370_846': 33.474,
    '1370_847': 33.3966,
    '1370_848': 33.3179,
    '1370_849': 33.2189,
    '1370_850': 33.0924,
    '1370_851': 32.9367,
    '1370_852': 32.7586,
    '1370_853': 32.5739,
    '1370_854': 32.3913,
    '1370_855': 32.1975,
    '1370_856': 31.9957,
    '1370_857': 31.7852,
    '1370_858': 31.5701,
    '1370_859': 31.3582,
    '1370_860': 31.1625,
    '1370_861': 30.9823,
    '1370_862': 30.8169,
    '1370_863': 30.6666,
    '1370_864': 30.5315,
    '1370_865': 30.4079,
    '1370_866': 30.2933,
    '1370_867': 30.186,
    '1370_868': 30.0859,
    '1370_869': 29.9924,
    '1370_870': 29.9074,
    '1370_871': 29.8286,
    '1370_872': 29.7481,
    '1370_873': 29.6624,
    '1370_874': 29.5891,
    '1370_875': 29.5287,
    '1370_876': 29.4794,
    '1370_877': 29.4344,
    '1370_878': 29.3961,
    '1370_879': 29.3707,
    '1370_880': 29.3456,
    '1370_881': 29.3201,
    '1370_882': 29.2966,
    '1370_883': 29.2792,
    '1370_884': 29.2684,
    '1370_885': 29.2675,
    '1370_886': 29.2858,
    '1370_887': 29.3267,
    '1370_888': 29.4083,
    '1370_889': 29.5111,
    '1370_890': 29.6193,
    '1370_891': 29.7629,
    '1370_892': 29.9333,
    '1370_893': 30.0919,
    '1370_894': 30.233,
    '1370_895': 30.3836,
    '1370_896': 30.5386,
    '1370_897': 30.699,
    '1370_898': 30.8946,
    '1370_899': 31.1041,
    '1370_900': 31.298,
    '1370_901': 31.4682,
    '1370_902': 31.6349,
    '1370_903': 31.822,
    '1370_904': 32.0068,
    '1370_905': 32.1637,
    '1370_906': 32.304,
    '1370_907': 32.3911,
    '1370_908': 32.4346,
    '1370_909': 32.4031,
    '1370_910': 32.3109,
    '1370_911': 32.1505,
    '1370_912': 31.944,
    '1370_913': 31.711,
    '1370_914': 31.4697,
    '1370_915': 31.2192,
    '1370_916': 30.9539,
    '1370_917': 30.6777,
    '1370_918': 30.4173,
    '1370_919': 30.1806,
    '1370_920': 29.9551,
    '1370_921': 29.7383,
    '1370_922': 29.5304,
    '1370_923': 29.3311,
    '1370_924': 29.1411,
    '1370_925': 28.9578,
    '1370_926': 28.7847,
    '1370_927': 28.6259,
    '1370_928': 28.4859,
    '1370_929': 28.3628,
    '1370_930': 28.2524,
    '1370_931': 28.1455,
    '1370_932': 28.0451,
    '1370_933': 27.9578,
    '1370_934': 27.8803,
    '1370_935': 27.8129,
    '1370_936': 27.7433,
    '1370_937': 27.6734,
    '1370_938': 27.6073,
    '1370_939': 27.5464,
    '1370_940': 27.4943,
    '1370_941': 27.4492,
    '1370_942': 27.4086,
    '1370_943': 27.3734,
    '1370_944': 27.3416,
    '1370_945': 27.319,
    '1370_946': 27.2973,
    '1370_947': 27.2748,
    '1370_948': 27.2455,
    '1370_949': 27.207,
    '1370_950': 27.1647,
    '1370_951': 27.1007,
    '1370_952': 27.0251,
    '1370_953': 26.9347,
    '1370_954': 26.8432,
    '1370_955': 26.7674,
    '1370_956': 26.7065,
    '1370_957': 26.6643,
    '1370_958': 26.6353,
    '1370_959': 26.6171,
    '1370_960': 26.6068,
    '1370_961': 26.6016,
    '1370_962': 26.6011,
    '1370_963': 26.6079,
    '1370_964': 26.6242,
    '1370_965': 26.6478,
    '1370_966': 26.6767,
    '1370_967': 26.7127,
    '1370_968': 26.7584,
    '1370_969': 26.8089,
    '1370_970': 26.8604,
    '1370_971': 26.9082,
    '1370_972': 26.9528,
    '1370_973': 26.9935,
    '1370_974': 27.0341,
    '1370_975': 27.0757,
    '1370_976': 27.1196,
    '1370_977': 27.1584,
    '1370_978': 27.189,
    '1370_979': 27.211,
    '1370_980': 27.2253,
    '1370_981': 27.2313,
    '1370_982': 27.2303,
    '1370_983': 27.2178,
    '1370_984': 27.1976,
    '1370_985': 27.1692,
    '1370_986': 27.1333,
    '1370_987': 27.0811,
    '1370_988': 27.026,
    '1370_989': 26.9656,
    '1370_990': 26.8964,
    '1370_991': 26.8216,
    '1370_992': 26.7415,
    '1370_993': 26.6515,
    '1370_994': 26.5578,
    '1370_995': 26.4531,
    '1370_996': 26.3446,
    '1370_997': 26.2324,
    '1370_998': 26.1141,
    '1370_999': 25.9898,
    '1370_1000': 25.8577,
    '1370_1001': 25.7219,
    '1370_1002': 25.5817,
    '1370_1003': 25.4352,
    '1370_1004': 25.2843,
    '1370_1005': 25.1269,
    '1370_1006': 24.9691,
    '1370_1007': 24.8135,
    '1370_1008': 24.6645,
    '1370_1009': 24.5106,
    '1370_1010': 24.3531,
    '1370_1011': 24.1836,
    '1370_1012': 23.9983,
    '1370_1013': 23.7996,
    '1370_1014': 23.5924,
    '1370_1015': 23.3795,
    '1370_1016': 23.1573,
    '1370_1017': 22.9337,
    '1370_1018': 22.71,
    '1370_1019': 22.4821,
    '1370_1020': 22.2493,
    '1370_1021': 22.0077,
    '1370_1022': 21.7549,
    '1370_1023': 21.4989,
    '1370_1024': 21.2435,
    '1370_1025': 20.9847,
    '1370_1026': 20.7183,
    '1370_1027': 20.4601,
    '1370_1028': 20.2048,
    '1370_1029': 19.9517,
    '1370_1030': 19.7025,
    '1370_1031': 19.4591,
    '1370_1032': 19.2281,
    '1370_1033': 19.003,
    '1371_788': 30.3088,
    '1371_789': 30.461,
    '1371_790': 30.617,
    '1371_791': 30.773,
    '1371_792': 30.9275,
    '1371_793': 31.0789,
    '1371_794': 31.2262,
    '1371_795': 31.3694,
    '1371_796': 31.5117,
    '1371_797': 31.6498,
    '1371_798': 31.7818,
    '1371_799': 31.9105,
    '1371_800': 32.0337,
    '1371_801': 32.1534,
    '1371_802': 32.268,
    '1371_803': 32.3783,
    '1371_804': 32.4851,
    '1371_805': 32.5889,
    '1371_806': 32.6934,
    '1371_807': 32.7896,
    '1371_808': 32.8761,
    '1371_809': 32.9494,
    '1371_810': 33.0106,
    '1371_811': 33.0625,
    '1371_812': 33.1209,
    '1371_813': 33.1854,
    '1371_814': 33.252,
    '1371_815': 33.3057,
    '1371_816': 33.3453,
    '1371_817': 33.3981,
    '1371_818': 33.4489,
    '1371_819': 33.4867,
    '1371_820': 33.5153,
    '1371_821': 33.5373,
    '1371_822': 33.5587,
    '1371_823': 33.5835,
    '1371_824': 33.6056,
    '1371_825': 33.619,
    '1371_826': 33.6183,
    '1371_827': 33.5972,
    '1371_828': 33.5625,
    '1371_829': 33.5528,
    '1371_830': 33.5692,
    '1371_831': 33.6044,
    '1371_832': 33.6312,
    '1371_833': 33.6275,
    '1371_834': 33.595,
    '1371_835': 33.5683,
    '1371_836': 33.566,
    '1371_837': 33.5794,
    '1371_838': 33.5976,
    '1371_839': 33.6223,
    '1371_840': 33.6427,
    '1371_841': 33.6506,
    '1371_842': 33.6444,
    '1371_843': 33.6223,
    '1371_844': 33.5826,
    '1371_845': 33.5248,
    '1371_846': 33.4519,
    '1371_847': 33.3773,
    '1371_848': 33.3026,
    '1371_849': 33.2171,
    '1371_850': 33.0933,
    '1371_851': 32.9423,
    '1371_852': 32.7673,
    '1371_853': 32.5833,
    '1371_854': 32.3947,
    '1371_855': 32.2018,
    '1371_856': 32.0011,
    '1371_857': 31.7947,
    '1371_858': 31.5879,
    '1371_859': 31.3836,
    '1371_860': 31.1937,
    '1371_861': 31.018,
    '1371_862': 30.8555,
    '1371_863': 30.707,
    '1371_864': 30.5743,
    '1371_865': 30.4545,
    '1371_866': 30.3442,
    '1371_867': 30.2411,
    '1371_868': 30.1459,
    '1371_869': 30.0587,
    '1371_870': 29.9801,
    '1371_871': 29.9047,
    '1371_872': 29.8303,
    '1371_873': 29.7543,
    '1371_874': 29.689,
    '1371_875': 29.6358,
    '1371_876': 29.5924,
    '1371_877': 29.5545,
    '1371_878': 29.5278,
    '1371_879': 29.5061,
    '1371_880': 29.4854,
    '1371_881': 29.4637,
    '1371_882': 29.4477,
    '1371_883': 29.4319,
    '1371_884': 29.4221,
    '1371_885': 29.4206,
    '1371_886': 29.4385,
    '1371_887': 29.4811,
    '1371_888': 29.571,
    '1371_889': 29.6785,
    '1371_890': 29.7825,
    '1371_891': 29.9157,
    '1371_892': 30.0843,
    '1371_893': 30.241,
    '1371_894': 30.3761,
    '1371_895': 30.5181,
    '1371_896': 30.6575,
    '1371_897': 30.7999,
    '1371_898': 30.9793,
    '1371_899': 31.1857,
    '1371_900': 31.373,
    '1371_901': 31.5384,
    '1371_902': 31.6999,
    '1371_903': 31.8874,
    '1371_904': 32.0664,
    '1371_905': 32.2198,
    '1371_906': 32.3479,
    '1371_907': 32.445,
    '1371_908': 32.479,
    '1371_909': 32.4367,
    '1371_910': 32.3312,
    '1371_911': 32.1744,
    '1371_912': 31.9757,
    '1371_913': 31.7362,
    '1371_914': 31.4826,
    '1371_915': 31.2227,
    '1371_916': 30.958,
    '1371_917': 30.6808,
    '1371_918': 30.4202,
    '1371_919': 30.1873,
    '1371_920': 29.9684,
    '1371_921': 29.7564,
    '1371_922': 29.5531,
    '1371_923': 29.3579,
    '1371_924': 29.1722,
    '1371_925': 28.995,
    '1371_926': 28.8271,
    '1371_927': 28.6715,
    '1371_928': 28.5349,
    '1371_929': 28.4176,
    '1371_930': 28.3154,
    '1371_931': 28.2172,
    '1371_932': 28.1256,
    '1371_933': 28.0481,
    '1371_934': 27.9767,
    '1371_935': 27.919,
    '1371_936': 27.8578,
    '1371_937': 27.7975,
    '1371_938': 27.7404,
    '1371_939': 27.6916,
    '1371_940': 27.6521,
    '1371_941': 27.6188,
    '1371_942': 27.5912,
    '1371_943': 27.5701,
    '1371_944': 27.5529,
    '1371_945': 27.5375,
    '1371_946': 27.5247,
    '1371_947': 27.5077,
    '1371_948': 27.4891,
    '1371_949': 27.4601,
    '1371_950': 27.421,
    '1371_951': 27.362,
    '1371_952': 27.2878,
    '1371_953': 27.1952,
    '1371_954': 27.1026,
    '1371_955': 27.0297,
    '1371_956': 26.977,
    '1371_957': 26.9361,
    '1371_958': 26.9121,
    '1371_959': 26.8998,
    '1371_960': 26.8958,
    '1371_961': 26.8978,
    '1371_962': 26.9049,
    '1371_963': 26.9179,
    '1371_964': 26.9376,
    '1371_965': 26.9628,
    '1371_966': 26.9915,
    '1371_967': 27.0266,
    '1371_968': 27.0757,
    '1371_969': 27.1297,
    '1371_970': 27.1872,
    '1371_971': 27.2372,
    '1371_972': 27.2857,
    '1371_973': 27.3275,
    '1371_974': 27.3715,
    '1371_975': 27.4228,
    '1371_976': 27.4724,
    '1371_977': 27.5149,
    '1371_978': 27.5473,
    '1371_979': 27.5695,
    '1371_980': 27.5847,
    '1371_981': 27.5924,
    '1371_982': 27.5907,
    '1371_983': 27.5806,
    '1371_984': 27.5689,
    '1371_985': 27.5454,
    '1371_986': 27.5057,
    '1371_987': 27.4615,
    '1371_988': 27.4083,
    '1371_989': 27.3453,
    '1371_990': 27.2799,
    '1371_991': 27.1999,
    '1371_992': 27.1248,
    '1371_993': 27.0276,
    '1371_994': 26.9409,
    '1371_995': 26.8451,
    '1371_996': 26.7358,
    '1371_997': 26.6218,
    '1371_998': 26.5033,
    '1371_999': 26.3797,
    '1371_1000': 26.2455,
    '1371_1001': 26.1112,
    '1371_1002': 25.9742,
    '1371_1003': 25.8307,
    '1371_1004': 25.6895,
    '1371_1005': 25.5338,
    '1371_1006': 25.3774,
    '1371_1007': 25.2234,
    '1371_1008': 25.0803,
    '1371_1009': 24.9267,
    '1371_1010': 24.7794,
    '1371_1011': 24.6173,
    '1371_1012': 24.4365,
    '1371_1013': 24.2405,
    '1371_1014': 24.0395,
    '1371_1015': 23.8363,
    '1371_1016': 23.628,
    '1371_1017': 23.4051,
    '1371_1018': 23.1812,
    '1371_1019': 22.9516,
    '1371_1020': 22.7211,
    '1371_1021': 22.4805,
    '1371_1022': 22.222,
    '1371_1023': 21.96,
    '1371_1024': 21.6968,
    '1371_1025': 21.4332,
    '1371_1026': 21.1689,
    '1371_1027': 20.9017,
    '1371_1028': 20.6342,
    '1371_1029': 20.3676,
    '1371_1030': 20.1051,
    '1371_1031': 19.8499,
    '1371_1032': 19.607,
    '1371_1033': 19.3719,
    '1372_788': 30.2355,
    '1372_789': 30.3834,
    '1372_790': 30.534,
    '1372_791': 30.6849,
    '1372_792': 30.8346,
    '1372_793': 30.9807,
    '1372_794': 31.1196,
    '1372_795': 31.2559,
    '1372_796': 31.3965,
    '1372_797': 31.5354,
    '1372_798': 31.6675,
    '1372_799': 31.7962,
    '1372_800': 31.9213,
    '1372_801': 32.0428,
    '1372_802': 32.1602,
    '1372_803': 32.2741,
    '1372_804': 32.3846,
    '1372_805': 32.4931,
    '1372_806': 32.6007,
    '1372_807': 32.6964,
    '1372_808': 32.7844,
    '1372_809': 32.8622,
    '1372_810': 32.9259,
    '1372_811': 32.9853,
    '1372_812': 33.0421,
    '1372_813': 33.107,
    '1372_814': 33.1774,
    '1372_815': 33.2496,
    '1372_816': 33.3283,
    '1372_817': 33.4131,
    '1372_818': 33.482,
    '1372_819': 33.5343,
    '1372_820': 33.5675,
    '1372_821': 33.5869,
    '1372_822': 33.6015,
    '1372_823': 33.6251,
    '1372_824': 33.6469,
    '1372_825': 33.6572,
    '1372_826': 33.6489,
    '1372_827': 33.6195,
    '1372_828': 33.5765,
    '1372_829': 33.5458,
    '1372_830': 33.5365,
    '1372_831': 33.5521,
    '1372_832': 33.5726,
    '1372_833': 33.5776,
    '1372_834': 33.5652,
    '1372_835': 33.559,
    '1372_836': 33.5667,
    '1372_837': 33.5846,
    '1372_838': 33.6073,
    '1372_839': 33.6351,
    '1372_840': 33.6597,
    '1372_841': 33.6638,
    '1372_842': 33.6482,
    '1372_843': 33.6136,
    '1372_844': 33.5596,
    '1372_845': 33.4934,
    '1372_846': 33.4188,
    '1372_847': 33.348,
    '1372_848': 33.2804,
    '1372_849': 33.1937,
    '1372_850': 33.0705,
    '1372_851': 32.9267,
    '1372_852': 32.7558,
    '1372_853': 32.5771,
    '1372_854': 32.391,
    '1372_855': 32.2011,
    '1372_856': 32.0068,
    '1372_857': 31.81,
    '1372_858': 31.6078,
    '1372_859': 31.4058,
    '1372_860': 31.2205,
    '1372_861': 31.0501,
    '1372_862': 30.8905,
    '1372_863': 30.7449,
    '1372_864': 30.6151,
    '1372_865': 30.4987,
    '1372_866': 30.3926,
    '1372_867': 30.2945,
    '1372_868': 30.2042,
    '1372_869': 30.1229,
    '1372_870': 30.0484,
    '1372_871': 29.9772,
    '1372_872': 29.91,
    '1372_873': 29.8438,
    '1372_874': 29.7873,
    '1372_875': 29.7404,
    '1372_876': 29.703,
    '1372_877': 29.6745,
    '1372_878': 29.6538,
    '1372_879': 29.6344,
    '1372_880': 29.6147,
    '1372_881': 29.5997,
    '1372_882': 29.5901,
    '1372_883': 29.5784,
    '1372_884': 29.566,
    '1372_885': 29.5654,
    '1372_886': 29.5854,
    '1372_887': 29.6311,
    '1372_888': 29.7197,
    '1372_889': 29.8257,
    '1372_890': 29.9292,
    '1372_891': 30.0593,
    '1372_892': 30.2257,
    '1372_893': 30.3818,
    '1372_894': 30.5143,
    '1372_895': 30.6452,
    '1372_896': 30.771,
    '1372_897': 30.9027,
    '1372_898': 31.0653,
    '1372_899': 31.2585,
    '1372_900': 31.4377,
    '1372_901': 31.5964,
    '1372_902': 31.7555,
    '1372_903': 31.9428,
    '1372_904': 32.1204,
    '1372_905': 32.2697,
    '1372_906': 32.388,
    '1372_907': 32.4694,
    '1372_908': 32.5009,
    '1372_909': 32.4625,
    '1372_910': 32.352,
    '1372_911': 32.18,
    '1372_912': 31.9772,
    '1372_913': 31.7375,
    '1372_914': 31.4767,
    '1372_915': 31.2047,
    '1372_916': 30.9363,
    '1372_917': 30.6666,
    '1372_918': 30.4144,
    '1372_919': 30.187,
    '1372_920': 29.9745,
    '1372_921': 29.7703,
    '1372_922': 29.5724,
    '1372_923': 29.3818,
    '1372_924': 29.2017,
    '1372_925': 29.0302,
    '1372_926': 28.8674,
    '1372_927': 28.7159,
    '1372_928': 28.5824,
    '1372_929': 28.4707,
    '1372_930': 28.3751,
    '1372_931': 28.2859,
    '1372_932': 28.2062,
    '1372_933': 28.1367,
    '1372_934': 28.0748,
    '1372_935': 28.0264,
    '1372_936': 27.9766,
    '1372_937': 27.9235,
    '1372_938': 27.8756,
    '1372_939': 27.8387,
    '1372_940': 27.8112,
    '1372_941': 27.7879,
    '1372_942': 27.7691,
    '1372_943': 27.7599,
    '1372_944': 27.7563,
    '1372_945': 27.7525,
    '1372_946': 27.7492,
    '1372_947': 27.7367,
    '1372_948': 27.7264,
    '1372_949': 27.7028,
    '1372_950': 27.6706,
    '1372_951': 27.6161,
    '1372_952': 27.5448,
    '1372_953': 27.4565,
    '1372_954': 27.3722,
    '1372_955': 27.2999,
    '1372_956': 27.2471,
    '1372_957': 27.2121,
    '1372_958': 27.1884,
    '1372_959': 27.1804,
    '1372_960': 27.1817,
    '1372_961': 27.1895,
    '1372_962': 27.2027,
    '1372_963': 27.221,
    '1372_964': 27.2444,
    '1372_965': 27.2724,
    '1372_966': 27.3047,
    '1372_967': 27.344,
    '1372_968': 27.3929,
    '1372_969': 27.4474,
    '1372_970': 27.503,
    '1372_971': 27.555,
    '1372_972': 27.6037,
    '1372_973': 27.647,
    '1372_974': 27.6929,
    '1372_975': 27.7591,
    '1372_976': 27.817,
    '1372_977': 27.8638,
    '1372_978': 27.8983,
    '1372_979': 27.9216,
    '1372_980': 27.9355,
    '1372_981': 27.9443,
    '1372_982': 27.9446,
    '1372_983': 27.9415,
    '1372_984': 27.9334,
    '1372_985': 27.9009,
    '1372_986': 27.8582,
    '1372_987': 27.8105,
    '1372_988': 27.7565,
    '1372_989': 27.6954,
    '1372_990': 27.6291,
    '1372_991': 27.5542,
    '1372_992': 27.4761,
    '1372_993': 27.3876,
    '1372_994': 27.2991,
    '1372_995': 27.204,
    '1372_996': 27.098,
    '1372_997': 26.985,
    '1372_998': 26.8661,
    '1372_999': 26.7459,
    '1372_1000': 26.6178,
    '1372_1001': 26.4849,
    '1372_1002': 26.349,
    '1372_1003': 26.2092,
    '1372_1004': 26.068,
    '1372_1005': 25.9178,
    '1372_1006': 25.7664,
    '1372_1007': 25.6143,
    '1372_1008': 25.4644,
    '1372_1009': 25.3088,
    '1372_1010': 25.1703,
    '1372_1011': 25.0186,
    '1372_1012': 24.848,
    '1372_1013': 24.6587,
    '1372_1014': 24.4631,
    '1372_1015': 24.2691,
    '1372_1016': 24.0697,
    '1372_1017': 23.855,
    '1372_1018': 23.6359,
    '1372_1019': 23.4114,
    '1372_1020': 23.191,
    '1372_1021': 22.9587,
    '1372_1022': 22.6967,
    '1372_1023': 22.4277,
    '1372_1024': 22.1598,
    '1372_1025': 21.8958,
    '1372_1026': 21.6264,
    '1372_1027': 21.3519,
    '1372_1028': 21.0742,
    '1372_1029': 20.7956,
    '1372_1030': 20.5187,
    '1372_1031': 20.249,
    '1372_1032': 19.9955,
    '1372_1033': 19.7501,
    '1373_788': 30.1656,
    '1373_789': 30.3095,
    '1373_790': 30.4538,
    '1373_791': 30.5997,
    '1373_792': 30.746,
    '1373_793': 30.8889,
    '1373_794': 31.0195,
    '1373_795': 31.1483,
    '1373_796': 31.2881,
    '1373_797': 31.4267,
    '1373_798': 31.5602,
    '1373_799': 31.6903,
    '1373_800': 31.8168,
    '1373_801': 31.9394,
    '1373_802': 32.0583,
    '1373_803': 32.1736,
    '1373_804': 32.2853,
    '1373_805': 32.3936,
    '1373_806': 32.4985,
    '1373_807': 32.5954,
    '1373_808': 32.6851,
    '1373_809': 32.7648,
    '1373_810': 32.8341,
    '1373_811': 32.9001,
    '1373_812': 32.9658,
    '1373_813': 33.0323,
    '1373_814': 33.1136,
    '1373_815': 33.2089,
    '1373_816': 33.3208,
    '1373_817': 33.4279,
    '1373_818': 33.5146,
    '1373_819': 33.5769,
    '1373_820': 33.6119,
    '1373_821': 33.6251,
    '1373_822': 33.6323,
    '1373_823': 33.6459,
    '1373_824': 33.6625,
    '1373_825': 33.6652,
    '1373_826': 33.6475,
    '1373_827': 33.6104,
    '1373_828': 33.563,
    '1373_829': 33.5281,
    '1373_830': 33.5032,
    '1373_831': 33.504,
    '1373_832': 33.5175,
    '1373_833': 33.5333,
    '1373_834': 33.5439,
    '1373_835': 33.555,
    '1373_836': 33.5705,
    '1373_837': 33.593,
    '1373_838': 33.6226,
    '1373_839': 33.6511,
    '1373_840': 33.673,
    '1373_841': 33.6728,
    '1373_842': 33.6451,
    '1373_843': 33.5974,
    '1373_844': 33.5304,
    '1373_845': 33.4542,
    '1373_846': 33.376,
    '1373_847': 33.3111,
    '1373_848': 33.2446,
    '1373_849': 33.1556,
    '1373_850': 33.0398,
    '1373_851': 32.8955,
    '1373_852': 32.727,
    '1373_853': 32.5518,
    '1373_854': 32.3696,
    '1373_855': 32.1888,
    '1373_856': 32.0032,
    '1373_857': 31.817,
    '1373_858': 31.6191,
    '1373_859': 31.4234,
    '1373_860': 31.2436,
    '1373_861': 31.0784,
    '1373_862': 30.9243,
    '1373_863': 30.7827,
    '1373_864': 30.6566,
    '1373_865': 30.5433,
    '1373_866': 30.4405,
    '1373_867': 30.3468,
    '1373_868': 30.262,
    '1373_869': 30.1866,
    '1373_870': 30.1164,
    '1373_871': 30.0494,
    '1373_872': 29.9868,
    '1373_873': 29.9285,
    '1373_874': 29.8785,
    '1373_875': 29.8377,
    '1373_876': 29.808,
    '1373_877': 29.7866,
    '1373_878': 29.7697,
    '1373_879': 29.7537,
    '1373_880': 29.7345,
    '1373_881': 29.7242,
    '1373_882': 29.7175,
    '1373_883': 29.7099,
    '1373_884': 29.7043,
    '1373_885': 29.7115,
    '1373_886': 29.738,
    '1373_887': 29.7806,
    '1373_888': 29.8606,
    '1373_889': 29.9611,
    '1373_890': 30.0659,
    '1373_891': 30.1972,
    '1373_892': 30.3575,
    '1373_893': 30.51,
    '1373_894': 30.6406,
    '1373_895': 30.7636,
    '1373_896': 30.8837,
    '1373_897': 31.0074,
    '1373_898': 31.1547,
    '1373_899': 31.3296,
    '1373_900': 31.4961,
    '1373_901': 31.6477,
    '1373_902': 31.8069,
    '1373_903': 31.9854,
    '1373_904': 32.1583,
    '1373_905': 32.3044,
    '1373_906': 32.4218,
    '1373_907': 32.4839,
    '1373_908': 32.507,
    '1373_909': 32.4582,
    '1373_910': 32.3471,
    '1373_911': 32.181,
    '1373_912': 31.9667,
    '1373_913': 31.7135,
    '1373_914': 31.4459,
    '1373_915': 31.1676,
    '1373_916': 30.9006,
    '1373_917': 30.6446,
    '1373_918': 30.4036,
    '1373_919': 30.1828,
    '1373_920': 29.9765,
    '1373_921': 29.7802,
    '1373_922': 29.5897,
    '1373_923': 29.4054,
    '1373_924': 29.2307,
    '1373_925': 29.0646,
    '1373_926': 28.9072,
    '1373_927': 28.7607,
    '1373_928': 28.6307,
    '1373_929': 28.5227,
    '1373_930': 28.4323,
    '1373_931': 28.3525,
    '1373_932': 28.284,
    '1373_933': 28.2276,
    '1373_934': 28.1774,
    '1373_935': 28.138,
    '1373_936': 28.0962,
    '1373_937': 28.0505,
    '1373_938': 28.0132,
    '1373_939': 27.9859,
    '1373_940': 27.9671,
    '1373_941': 27.9535,
    '1373_942': 27.9453,
    '1373_943': 27.9442,
    '1373_944': 27.9538,
    '1373_945': 27.9651,
    '1373_946': 27.9713,
    '1373_947': 27.963,
    '1373_948': 27.9593,
    '1373_949': 27.9401,
    '1373_950': 27.9125,
    '1373_951': 27.8691,
    '1373_952': 27.8072,
    '1373_953': 27.7299,
    '1373_954': 27.6482,
    '1373_955': 27.579,
    '1373_956': 27.5263,
    '1373_957': 27.4885,
    '1373_958': 27.4694,
    '1373_959': 27.4612,
    '1373_960': 27.466,
    '1373_961': 27.4778,
    '1373_962': 27.4955,
    '1373_963': 27.5178,
    '1373_964': 27.5446,
    '1373_965': 27.5753,
    '1373_966': 27.6106,
    '1373_967': 27.6523,
    '1373_968': 27.7013,
    '1373_969': 27.7556,
    '1373_970': 27.8091,
    '1373_971': 27.8625,
    '1373_972': 27.9143,
    '1373_973': 27.9613,
    '1373_974': 28.006,
    '1373_975': 28.0867,
    '1373_976': 28.1472,
    '1373_977': 28.1964,
    '1373_978': 28.2311,
    '1373_979': 28.2538,
    '1373_980': 28.2631,
    '1373_981': 28.2675,
    '1373_982': 28.2668,
    '1373_983': 28.2616,
    '1373_984': 28.2483,
    '1373_985': 28.2188,
    '1373_986': 28.1787,
    '1373_987': 28.1321,
    '1373_988': 28.0795,
    '1373_989': 28.0209,
    '1373_990': 27.9564,
    '1373_991': 27.8854,
    '1373_992': 27.8088,
    '1373_993': 27.7258,
    '1373_994': 27.6384,
    '1373_995': 27.5447,
    '1373_996': 27.4425,
    '1373_997': 27.333,
    '1373_998': 27.218,
    '1373_999': 27.0991,
    '1373_1000': 26.9734,
    '1373_1001': 26.8428,
    '1373_1002': 26.7095,
    '1373_1003': 26.5739,
    '1373_1004': 26.4379,
    '1373_1005': 26.2919,
    '1373_1006': 26.1472,
    '1373_1007': 26.0005,
    '1373_1008': 25.8514,
    '1373_1009': 25.6989,
    '1373_1010': 25.5614,
    '1373_1011': 25.4121,
    '1373_1012': 25.248,
    '1373_1013': 25.0618,
    '1373_1014': 24.869,
    '1373_1015': 24.6855,
    '1373_1016': 24.4972,
    '1373_1017': 24.2981,
    '1373_1018': 24.0857,
    '1373_1019': 23.8696,
    '1373_1020': 23.6513,
    '1373_1021': 23.416,
    '1373_1022': 23.1595,
    '1373_1023': 22.8936,
    '1373_1024': 22.6298,
    '1373_1025': 22.3639,
    '1373_1026': 22.088,
    '1373_1027': 21.8067,
    '1373_1028': 21.5211,
    '1373_1029': 21.2328,
    '1373_1030': 20.9434,
    '1373_1031': 20.6597,
    '1373_1032': 20.3958,
    '1373_1033': 20.1392,
    '1374_788': 30.0986,
    '1374_789': 30.236,
    '1374_790': 30.3729,
    '1374_791': 30.5136,
    '1374_792': 30.6559,
    '1374_793': 30.7946,
    '1374_794': 30.927,
    '1374_795': 31.0577,
    '1374_796': 31.1937,
    '1374_797': 31.3297,
    '1374_798': 31.4624,
    '1374_799': 31.5928,
    '1374_800': 31.7199,
    '1374_801': 31.8432,
    '1374_802': 31.9626,
    '1374_803': 32.0783,
    '1374_804': 32.1894,
    '1374_805': 32.2961,
    '1374_806': 32.3985,
    '1374_807': 32.4955,
    '1374_808': 32.5885,
    '1374_809': 32.6706,
    '1374_810': 32.744,
    '1374_811': 32.8143,
    '1374_812': 32.8852,
    '1374_813': 32.9572,
    '1374_814': 33.052,
    '1374_815': 33.1678,
    '1374_816': 33.299,
    '1374_817': 33.4141,
    '1374_818': 33.5059,
    '1374_819': 33.5695,
    '1374_820': 33.6034,
    '1374_821': 33.6132,
    '1374_822': 33.6161,
    '1374_823': 33.6239,
    '1374_824': 33.6372,
    '1374_825': 33.6368,
    '1374_826': 33.6122,
    '1374_827': 33.567,
    '1374_828': 33.5258,
    '1374_829': 33.5028,
    '1374_830': 33.4765,
    '1374_831': 33.4734,
    '1374_832': 33.4868,
    '1374_833': 33.5095,
    '1374_834': 33.5362,
    '1374_835': 33.5604,
    '1374_836': 33.5857,
    '1374_837': 33.6078,
    '1374_838': 33.6348,
    '1374_839': 33.6619,
    '1374_840': 33.6779,
    '1374_841': 33.6713,
    '1374_842': 33.6388,
    '1374_843': 33.5783,
    '1374_844': 33.4987,
    '1374_845': 33.4112,
    '1374_846': 33.3269,
    '1374_847': 33.2592,
    '1374_848': 33.1901,
    '1374_849': 33.1078,
    '1374_850': 32.9934,
    '1374_851': 32.8498,
    '1374_852': 32.6847,
    '1374_853': 32.5122,
    '1374_854': 32.336,
    '1374_855': 32.1628,
    '1374_856': 31.9864,
    '1374_857': 31.8106,
    '1374_858': 31.6229,
    '1374_859': 31.4358,
    '1374_860': 31.264,
    '1374_861': 31.1057,
    '1374_862': 30.9575,
    '1374_863': 30.821,
    '1374_864': 30.6989,
    '1374_865': 30.5893,
    '1374_866': 30.4901,
    '1374_867': 30.4005,
    '1374_868': 30.3211,
    '1374_869': 30.252,
    '1374_870': 30.1877,
    '1374_871': 30.1228,
    '1374_872': 30.0617,
    '1374_873': 30.0084,
    '1374_874': 29.9636,
    '1374_875': 29.9294,
    '1374_876': 29.9057,
    '1374_877': 29.8895,
    '1374_878': 29.8785,
    '1374_879': 29.8647,
    '1374_880': 29.8477,
    '1374_881': 29.8391,
    '1374_882': 29.8383,
    '1374_883': 29.8342,
    '1374_884': 29.8414,
    '1374_885': 29.8588,
    '1374_886': 29.892,
    '1374_887': 29.9343,
    '1374_888': 30.0029,
    '1374_889': 30.103,
    '1374_890': 30.2083,
    '1374_891': 30.3312,
    '1374_892': 30.4795,
    '1374_893': 30.6277,
    '1374_894': 30.7538,
    '1374_895': 30.8687,
    '1374_896': 30.9859,
    '1374_897': 31.1061,
    '1374_898': 31.2488,
    '1374_899': 31.4059,
    '1374_900': 31.5588,
    '1374_901': 31.7091,
    '1374_902': 31.8646,
    '1374_903': 32.0246,
    '1374_904': 32.1824,
    '1374_905': 32.328,
    '1374_906': 32.4382,
    '1374_907': 32.5005,
    '1374_908': 32.5027,
    '1374_909': 32.4462,
    '1374_910': 32.3214,
    '1374_911': 32.1553,
    '1374_912': 31.9317,
    '1374_913': 31.6721,
    '1374_914': 31.4008,
    '1374_915': 31.13,
    '1374_916': 30.8705,
    '1374_917': 30.6249,
    '1374_918': 30.3943,
    '1374_919': 30.1812,
    '1374_920': 29.9812,
    '1374_921': 29.7918,
    '1374_922': 29.6085,
    '1374_923': 29.4298,
    '1374_924': 29.2592,
    '1374_925': 29.0975,
    '1374_926': 28.9453,
    '1374_927': 28.8035,
    '1374_928': 28.6771,
    '1374_929': 28.5733,
    '1374_930': 28.4876,
    '1374_931': 28.4165,
    '1374_932': 28.3581,
    '1374_933': 28.3163,
    '1374_934': 28.2829,
    '1374_935': 28.2521,
    '1374_936': 28.2168,
    '1374_937': 28.1798,
    '1374_938': 28.1517,
    '1374_939': 28.1346,
    '1374_940': 28.123,
    '1374_941': 28.1145,
    '1374_942': 28.1148,
    '1374_943': 28.1258,
    '1374_944': 28.1456,
    '1374_945': 28.1689,
    '1374_946': 28.1816,
    '1374_947': 28.1823,
    '1374_948': 28.1891,
    '1374_949': 28.1742,
    '1374_950': 28.1478,
    '1374_951': 28.1134,
    '1374_952': 28.0623,
    '1374_953': 27.9947,
    '1374_954': 27.9208,
    '1374_955': 27.8565,
    '1374_956': 27.8058,
    '1374_957': 27.7681,
    '1374_958': 27.7492,
    '1374_959': 27.746,
    '1374_960': 27.7524,
    '1374_961': 27.7637,
    '1374_962': 27.784,
    '1374_963': 27.8094,
    '1374_964': 27.8384,
    '1374_965': 27.8704,
    '1374_966': 27.9063,
    '1374_967': 27.9478,
    '1374_968': 27.9953,
    '1374_969': 28.0472,
    '1374_970': 28.101,
    '1374_971': 28.1568,
    '1374_972': 28.2144,
    '1374_973': 28.2711,
    '1374_974': 28.3132,
    '1374_975': 28.3812,
    '1374_976': 28.4459,
    '1374_977': 28.4962,
    '1374_978': 28.5291,
    '1374_979': 28.5499,
    '1374_980': 28.5603,
    '1374_981': 28.564,
    '1374_982': 28.5623,
    '1374_983': 28.5549,
    '1374_984': 28.5392,
    '1374_985': 28.5126,
    '1374_986': 28.4766,
    '1374_987': 28.4333,
    '1374_988': 28.384,
    '1374_989': 28.3289,
    '1374_990': 28.2677,
    '1374_991': 28.2,
    '1374_992': 28.1268,
    '1374_993': 28.048,
    '1374_994': 27.9639,
    '1374_995': 27.8732,
    '1374_996': 27.7747,
    '1374_997': 27.6691,
    '1374_998': 27.5573,
    '1374_999': 27.44,
    '1374_1000': 27.317,
    '1374_1001': 27.1896,
    '1374_1002': 27.0606,
    '1374_1003': 26.9334,
    '1374_1004': 26.8054,
    '1374_1005': 26.664,
    '1374_1006': 26.5226,
    '1374_1007': 26.3801,
    '1374_1008': 26.2345,
    '1374_1009': 26.0873,
    '1374_1010': 25.9445,
    '1374_1011': 25.7943,
    '1374_1012': 25.6321,
    '1374_1013': 25.4562,
    '1374_1014': 25.2749,
    '1374_1015': 25.0952,
    '1374_1016': 24.9119,
    '1374_1017': 24.7194,
    '1374_1018': 24.5168,
    '1374_1019': 24.3102,
    '1374_1020': 24.1,
    '1374_1021': 23.865,
    '1374_1022': 23.6147,
    '1374_1023': 23.3561,
    '1374_1024': 23.094,
    '1374_1025': 22.8231,
    '1374_1026': 22.5445,
    '1374_1027': 22.2608,
    '1374_1028': 21.9706,
    '1374_1029': 21.6771,
    '1374_1030': 21.3811,
    '1374_1031': 21.0893,
    '1374_1032': 20.812,
    '1374_1033': 20.5414,
    '1375_788': 30.0187,
    '1375_789': 30.1486,
    '1375_790': 30.2856,
    '1375_791': 30.4241,
    '1375_792': 30.565,
    '1375_793': 30.7048,
    '1375_794': 30.8405,
    '1375_795': 30.9736,
    '1375_796': 31.1084,
    '1375_797': 31.2426,
    '1375_798': 31.3744,
    '1375_799': 31.5041,
    '1375_800': 31.631,
    '1375_801': 31.7546,
    '1375_802': 31.8745,
    '1375_803': 31.9903,
    '1375_804': 32.1007,
    '1375_805': 32.2063,
    '1375_806': 32.3067,
    '1375_807': 32.4022,
    '1375_808': 32.5007,
    '1375_809': 32.584,
    '1375_810': 32.6593,
    '1375_811': 32.7319,
    '1375_812': 32.8076,
    '1375_813': 32.8846,
    '1375_814': 32.9851,
    '1375_815': 33.1054,
    '1375_816': 33.2382,
    '1375_817': 33.3622,
    '1375_818': 33.4546,
    '1375_819': 33.5197,
    '1375_820': 33.5463,
    '1375_821': 33.5557,
    '1375_822': 33.5501,
    '1375_823': 33.5545,
    '1375_824': 33.5726,
    '1375_825': 33.5767,
    '1375_826': 33.5537,
    '1375_827': 33.5148,
    '1375_828': 33.4846,
    '1375_829': 33.4697,
    '1375_830': 33.4535,
    '1375_831': 33.4567,
    '1375_832': 33.4741,
    '1375_833': 33.5039,
    '1375_834': 33.5384,
    '1375_835': 33.5696,
    '1375_836': 33.6046,
    '1375_837': 33.6278,
    '1375_838': 33.6457,
    '1375_839': 33.6619,
    '1375_840': 33.6708,
    '1375_841': 33.6549,
    '1375_842': 33.6201,
    '1375_843': 33.5538,
    '1375_844': 33.4639,
    '1375_845': 33.3687,
    '1375_846': 33.2807,
    '1375_847': 33.2053,
    '1375_848': 33.127,
    '1375_849': 33.0357,
    '1375_850': 32.9219,
    '1375_851': 32.7886,
    '1375_852': 32.6354,
    '1375_853': 32.4682,
    '1375_854': 32.2968,
    '1375_855': 32.1307,
    '1375_856': 31.9623,
    '1375_857': 31.7938,
    '1375_858': 31.6219,
    '1375_859': 31.4474,
    '1375_860': 31.2822,
    '1375_861': 31.1317,
    '1375_862': 30.9911,
    '1375_863': 30.8595,
    '1375_864': 30.7419,
    '1375_865': 30.6371,
    '1375_866': 30.5423,
    '1375_867': 30.4574,
    '1375_868': 30.384,
    '1375_869': 30.3218,
    '1375_870': 30.2603,
    '1375_871': 30.1952,
    '1375_872': 30.1365,
    '1375_873': 30.0863,
    '1375_874': 30.047,
    '1375_875': 30.0191,
    '1375_876': 30.0026,
    '1375_877': 29.9971,
    '1375_878': 29.9913,
    '1375_879': 29.9789,
    '1375_880': 29.9704,
    '1375_881': 29.9571,
    '1375_882': 29.957,
    '1375_883': 29.9579,
    '1375_884': 29.9768,
    '1375_885': 30.0055,
    '1375_886': 30.0399,
    '1375_887': 30.081,
    '1375_888': 30.1491,
    '1375_889': 30.2515,
    '1375_890': 30.3634,
    '1375_891': 30.4737,
    '1375_892': 30.5999,
    '1375_893': 30.7394,
    '1375_894': 30.8613,
    '1375_895': 30.9672,
    '1375_896': 31.0822,
    '1375_897': 31.2029,
    '1375_898': 31.3383,
    '1375_899': 31.486,
    '1375_900': 31.6341,
    '1375_901': 31.783,
    '1375_902': 31.9326,
    '1375_903': 32.0774,
    '1375_904': 32.2154,
    '1375_905': 32.3427,
    '1375_906': 32.4441,
    '1375_907': 32.4971,
    '1375_908': 32.4926,
    '1375_909': 32.428,
    '1375_910': 32.3013,
    '1375_911': 32.1138,
    '1375_912': 31.888,
    '1375_913': 31.6258,
    '1375_914': 31.3595,
    '1375_915': 31.0992,
    '1375_916': 30.8486,
    '1375_917': 30.6108,
    '1375_918': 30.3884,
    '1375_919': 30.1821,
    '1375_920': 29.9896,
    '1375_921': 29.8077,
    '1375_922': 29.6305,
    '1375_923': 29.4558,
    '1375_924': 29.2897,
    '1375_925': 29.1336,
    '1375_926': 28.986,
    '1375_927': 28.8485,
    '1375_928': 28.7266,
    '1375_929': 28.6261,
    '1375_930': 28.5447,
    '1375_931': 28.4812,
    '1375_932': 28.4357,
    '1375_933': 28.4077,
    '1375_934': 28.3886,
    '1375_935': 28.3673,
    '1375_936': 28.3383,
    '1375_937': 28.3085,
    '1375_938': 28.2892,
    '1375_939': 28.2817,
    '1375_940': 28.2744,
    '1375_941': 28.2699,
    '1375_942': 28.2764,
    '1375_943': 28.2984,
    '1375_944': 28.3274,
    '1375_945': 28.3577,
    '1375_946': 28.3771,
    '1375_947': 28.3946,
    '1375_948': 28.4079,
    '1375_949': 28.3989,
    '1375_950': 28.3788,
    '1375_951': 28.35,
    '1375_952': 28.3082,
    '1375_953': 28.2479,
    '1375_954': 28.1856,
    '1375_955': 28.1287,
    '1375_956': 28.0789,
    '1375_957': 28.0419,
    '1375_958': 28.0252,
    '1375_959': 28.0246,
    '1375_960': 28.0319,
    '1375_961': 28.0493,
    '1375_962': 28.0688,
    '1375_963': 28.0959,
    '1375_964': 28.1259,
    '1375_965': 28.1569,
    '1375_966': 28.191,
    '1375_967': 28.2303,
    '1375_968': 28.2752,
    '1375_969': 28.3247,
    '1375_970': 28.377,
    '1375_971': 28.4312,
    '1375_972': 28.4853,
    '1375_973': 28.5353,
    '1375_974': 28.5801,
    '1375_975': 28.653,
    '1375_976': 28.7226,
    '1375_977': 28.7705,
    '1375_978': 28.8025,
    '1375_979': 28.8233,
    '1375_980': 28.8353,
    '1375_981': 28.8401,
    '1375_982': 28.8388,
    '1375_983': 28.831,
    '1375_984': 28.8157,
    '1375_985': 28.7916,
    '1375_986': 28.7594,
    '1375_987': 28.7202,
    '1375_988': 28.6752,
    '1375_989': 28.6244,
    '1375_990': 28.5672,
    '1375_991': 28.5022,
    '1375_992': 28.4322,
    '1375_993': 28.3579,
    '1375_994': 28.2781,
    '1375_995': 28.1915,
    '1375_996': 28.0969,
    '1375_997': 27.9951,
    '1375_998': 27.8867,
    '1375_999': 27.772,
    '1375_1000': 27.6518,
    '1375_1001': 27.5275,
    '1375_1002': 27.4016,
    '1375_1003': 27.2753,
    '1375_1004': 27.1469,
    '1375_1005': 27.0113,
    '1375_1006': 26.8821,
    '1375_1007': 26.7475,
    '1375_1008': 26.6072,
    '1375_1009': 26.4634,
    '1375_1010': 26.3208,
    '1375_1011': 26.1698,
    '1375_1012': 26.0096,
    '1375_1013': 25.8409,
    '1375_1014': 25.6678,
    '1375_1015': 25.4925,
    '1375_1016': 25.313,
    '1375_1017': 25.1259,
    '1375_1018': 24.9285,
    '1375_1019': 24.7262,
    '1375_1020': 24.5227,
    '1375_1021': 24.2966,
    '1375_1022': 24.0548,
    '1375_1023': 23.8035,
    '1375_1024': 23.5432,
    '1375_1025': 23.2709,
    '1375_1026': 22.9914,
    '1375_1027': 22.7068,
    '1375_1028': 22.4173,
    '1375_1029': 22.1237,
    '1375_1030': 21.8244,
    '1375_1031': 21.524,
    '1375_1032': 21.2381,
    '1375_1033': 20.9545,
    '1376_788': 29.9432,
    '1376_789': 30.0726,
    '1376_790': 30.2077,
    '1376_791': 30.3452,
    '1376_792': 30.4842,
    '1376_793': 30.6232,
    '1376_794': 30.7599,
    '1376_795': 30.8946,
    '1376_796': 31.0294,
    '1376_797': 31.1626,
    '1376_798': 31.2936,
    '1376_799': 31.4224,
    '1376_800': 31.5489,
    '1376_801': 31.6734,
    '1376_802': 31.7945,
    '1376_803': 31.9111,
    '1376_804': 32.0221,
    '1376_805': 32.1278,
    '1376_806': 32.2288,
    '1376_807': 32.3254,
    '1376_808': 32.4192,
    '1376_809': 32.5039,
    '1376_810': 32.582,
    '1376_811': 32.6593,
    '1376_812': 32.7399,
    '1376_813': 32.8261,
    '1376_814': 32.9216,
    '1376_815': 33.0261,
    '1376_816': 33.1456,
    '1376_817': 33.2676,
    '1376_818': 33.3573,
    '1376_819': 33.4146,
    '1376_820': 33.4417,
    '1376_821': 33.4488,
    '1376_822': 33.4429,
    '1376_823': 33.4538,
    '1376_824': 33.4806,
    '1376_825': 33.4949,
    '1376_826': 33.4841,
    '1376_827': 33.4617,
    '1376_828': 33.4453,
    '1376_829': 33.4395,
    '1376_830': 33.435,
    '1376_831': 33.445,
    '1376_832': 33.4684,
    '1376_833': 33.5,
    '1376_834': 33.5365,
    '1376_835': 33.5754,
    '1376_836': 33.6164,
    '1376_837': 33.6418,
    '1376_838': 33.6512,
    '1376_839': 33.6519,
    '1376_840': 33.6494,
    '1376_841': 33.6296,
    '1376_842': 33.5846,
    '1376_843': 33.5193,
    '1376_844': 33.4299,
    '1376_845': 33.3294,
    '1376_846': 33.2367,
    '1376_847': 33.1525,
    '1376_848': 33.06,
    '1376_849': 32.9551,
    '1376_850': 32.846,
    '1376_851': 32.7242,
    '1376_852': 32.5813,
    '1376_853': 32.4213,
    '1376_854': 32.2576,
    '1376_855': 32.0982,
    '1376_856': 31.9363,
    '1376_857': 31.7718,
    '1376_858': 31.6094,
    '1376_859': 31.4501,
    '1376_860': 31.2975,
    '1376_861': 31.1568,
    '1376_862': 31.0238,
    '1376_863': 30.8974,
    '1376_864': 30.7852,
    '1376_865': 30.6853,
    '1376_866': 30.5955,
    '1376_867': 30.5156,
    '1376_868': 30.4469,
    '1376_869': 30.388,
    '1376_870': 30.3286,
    '1376_871': 30.2656,
    '1376_872': 30.209,
    '1376_873': 30.1631,
    '1376_874': 30.1298,
    '1376_875': 30.1083,
    '1376_876': 30.1021,
    '1376_877': 30.1075,
    '1376_878': 30.1076,
    '1376_879': 30.0966,
    '1376_880': 30.0921,
    '1376_881': 30.0763,
    '1376_882': 30.0707,
    '1376_883': 30.0833,
    '1376_884': 30.1092,
    '1376_885': 30.1437,
    '1376_886': 30.1812,
    '1376_887': 30.2254,
    '1376_888': 30.2957,
    '1376_889': 30.3966,
    '1376_890': 30.5112,
    '1376_891': 30.617,
    '1376_892': 30.7216,
    '1376_893': 30.8472,
    '1376_894': 30.9668,
    '1376_895': 31.0708,
    '1376_896': 31.1809,
    '1376_897': 31.2945,
    '1376_898': 31.42,
    '1376_899': 31.5615,
    '1376_900': 31.7066,
    '1376_901': 31.8541,
    '1376_902': 32.0003,
    '1376_903': 32.1318,
    '1376_904': 32.2475,
    '1376_905': 32.3512,
    '1376_906': 32.431,
    '1376_907': 32.4694,
    '1376_908': 32.4578,
    '1376_909': 32.3856,
    '1376_910': 32.2525,
    '1376_911': 32.0661,
    '1376_912': 31.8421,
    '1376_913': 31.5893,
    '1376_914': 31.3293,
    '1376_915': 31.0725,
    '1376_916': 30.829,
    '1376_917': 30.5987,
    '1376_918': 30.3828,
    '1376_919': 30.1842,
    '1376_920': 29.9995,
    '1376_921': 29.8245,
    '1376_922': 29.6547,
    '1376_923': 29.4868,
    '1376_924': 29.3267,
    '1376_925': 29.1751,
    '1376_926': 29.0315,
    '1376_927': 28.8973,
    '1376_928': 28.7789,
    '1376_929': 28.6819,
    '1376_930': 28.6055,
    '1376_931': 28.5492,
    '1376_932': 28.5158,
    '1376_933': 28.5003,
    '1376_934': 28.4907,
    '1376_935': 28.4812,
    '1376_936': 28.4604,
    '1376_937': 28.4361,
    '1376_938': 28.4226,
    '1376_939': 28.4229,
    '1376_940': 28.4188,
    '1376_941': 28.4193,
    '1376_942': 28.4337,
    '1376_943': 28.4594,
    '1376_944': 28.4932,
    '1376_945': 28.5277,
    '1376_946': 28.5584,
    '1376_947': 28.5904,
    '1376_948': 28.6106,
    '1376_949': 28.6129,
    '1376_950': 28.5984,
    '1376_951': 28.5727,
    '1376_952': 28.5341,
    '1376_953': 28.4875,
    '1376_954': 28.4364,
    '1376_955': 28.3873,
    '1376_956': 28.3409,
    '1376_957': 28.3038,
    '1376_958': 28.291,
    '1376_959': 28.2925,
    '1376_960': 28.3046,
    '1376_961': 28.3269,
    '1376_962': 28.3498,
    '1376_963': 28.376,
    '1376_964': 28.4062,
    '1376_965': 28.4332,
    '1376_966': 28.4626,
    '1376_967': 28.4983,
    '1376_968': 28.5406,
    '1376_969': 28.5877,
    '1376_970': 28.6383,
    '1376_971': 28.6909,
    '1376_972': 28.7439,
    '1376_973': 28.7963,
    '1376_974': 28.8508,
    '1376_975': 28.9174,
    '1376_976': 28.9811,
    '1376_977': 29.0264,
    '1376_978': 29.0581,
    '1376_979': 29.0801,
    '1376_980': 29.0942,
    '1376_981': 29.1003,
    '1376_982': 29.1008,
    '1376_983': 29.0941,
    '1376_984': 29.0807,
    '1376_985': 29.0595,
    '1376_986': 29.0304,
    '1376_987': 28.9961,
    '1376_988': 28.9568,
    '1376_989': 28.9114,
    '1376_990': 28.8586,
    '1376_991': 28.7934,
    '1376_992': 28.724,
    '1376_993': 28.6555,
    '1376_994': 28.5808,
    '1376_995': 28.4993,
    '1376_996': 28.4088,
    '1376_997': 28.3106,
    '1376_998': 28.2059,
    '1376_999': 28.0943,
    '1376_1000': 27.9767,
    '1376_1001': 27.8543,
    '1376_1002': 27.7294,
    '1376_1003': 27.6031,
    '1376_1004': 27.4767,
    '1376_1005': 27.3494,
    '1376_1006': 27.223,
    '1376_1007': 27.0952,
    '1376_1008': 26.9646,
    '1376_1009': 26.8287,
    '1376_1010': 26.6884,
    '1376_1011': 26.5367,
    '1376_1012': 26.379,
    '1376_1013': 26.2143,
    '1376_1014': 26.0446,
    '1376_1015': 25.8748,
    '1376_1016': 25.699,
    '1376_1017': 25.5148,
    '1376_1018': 25.3243,
    '1376_1019': 25.1304,
    '1376_1020': 24.9343,
    '1376_1021': 24.7123,
    '1376_1022': 24.4768,
    '1376_1023': 24.2323,
    '1376_1024': 23.9742,
    '1376_1025': 23.7045,
    '1376_1026': 23.4268,
    '1376_1027': 23.1437,
    '1376_1028': 22.8563,
    '1376_1029': 22.5645,
    '1376_1030': 22.269,
    '1376_1031': 21.9661,
    '1376_1032': 21.6735,
    '1376_1033': 21.3764,
    '1377_788': 29.8767,
    '1377_789': 30.0049,
    '1377_790': 30.1386,
    '1377_791': 30.2748,
    '1377_792': 30.4119,
    '1377_793': 30.5496,
    '1377_794': 30.6864,
    '1377_795': 30.8218,
    '1377_796': 30.9567,
    '1377_797': 31.0897,
    '1377_798': 31.2204,
    '1377_799': 31.3489,
    '1377_800': 31.4749,
    '1377_801': 31.6013,
    '1377_802': 31.7239,
    '1377_803': 31.8417,
    '1377_804': 31.9534,
    '1377_805': 32.0596,
    '1377_806': 32.1615,
    '1377_807': 32.2584,
    '1377_808': 32.3497,
    '1377_809': 32.4335,
    '1377_810': 32.5113,
    '1377_811': 32.5898,
    '1377_812': 32.67,
    '1377_813': 32.7542,
    '1377_814': 32.8438,
    '1377_815': 32.9369,
    '1377_816': 33.0389,
    '1377_817': 33.1415,
    '1377_818': 33.223,
    '1377_819': 33.2742,
    '1377_820': 33.3044,
    '1377_821': 33.3188,
    '1377_822': 33.3304,
    '1377_823': 33.3532,
    '1377_824': 33.3848,
    '1377_825': 33.4109,
    '1377_826': 33.4178,
    '1377_827': 33.4137,
    '1377_828': 33.4113,
    '1377_829': 33.4193,
    '1377_830': 33.4253,
    '1377_831': 33.4376,
    '1377_832': 33.466,
    '1377_833': 33.4996,
    '1377_834': 33.537,
    '1377_835': 33.5771,
    '1377_836': 33.6165,
    '1377_837': 33.6379,
    '1377_838': 33.6414,
    '1377_839': 33.6331,
    '1377_840': 33.6203,
    '1377_841': 33.5958,
    '1377_842': 33.545,
    '1377_843': 33.4791,
    '1377_844': 33.3935,
    '1377_845': 33.2954,
    '1377_846': 33.196,
    '1377_847': 33.1078,
    '1377_848': 33.0095,
    '1377_849': 32.897,
    '1377_850': 32.7866,
    '1377_851': 32.6699,
    '1377_852': 32.5334,
    '1377_853': 32.3804,
    '1377_854': 32.2233,
    '1377_855': 32.0686,
    '1377_856': 31.9125,
    '1377_857': 31.7549,
    '1377_858': 31.5957,
    '1377_859': 31.4492,
    '1377_860': 31.3103,
    '1377_861': 31.1799,
    '1377_862': 31.0545,
    '1377_863': 30.9344,
    '1377_864': 30.8282,
    '1377_865': 30.7336,
    '1377_866': 30.6484,
    '1377_867': 30.5728,
    '1377_868': 30.5076,
    '1377_869': 30.4522,
    '1377_870': 30.3966,
    '1377_871': 30.3351,
    '1377_872': 30.2802,
    '1377_873': 30.2391,
    '1377_874': 30.2114,
    '1377_875': 30.197,
    '1377_876': 30.1997,
    '1377_877': 30.2108,
    '1377_878': 30.2157,
    '1377_879': 30.2092,
    '1377_880': 30.2017,
    '1377_881': 30.1848,
    '1377_882': 30.1894,
    '1377_883': 30.2135,
    '1377_884': 30.2481,
    '1377_885': 30.2843,
    '1377_886': 30.3217,
    '1377_887': 30.3726,
    '1377_888': 30.4464,
    '1377_889': 30.5405,
    '1377_890': 30.6517,
    '1377_891': 30.7589,
    '1377_892': 30.8515,
    '1377_893': 30.9551,
    '1377_894': 31.0707,
    '1377_895': 31.1724,
    '1377_896': 31.2742,
    '1377_897': 31.3786,
    '1377_898': 31.497,
    '1377_899': 31.631,
    '1377_900': 31.7736,
    '1377_901': 31.9179,
    '1377_902': 32.0598,
    '1377_903': 32.1798,
    '1377_904': 32.2773,
    '1377_905': 32.3542,
    '1377_906': 32.4127,
    '1377_907': 32.4364,
    '1377_908': 32.41,
    '1377_909': 32.3315,
    '1377_910': 32.1995,
    '1377_911': 32.0155,
    '1377_912': 31.7891,
    '1377_913': 31.5444,
    '1377_914': 31.2948,
    '1377_915': 31.0448,
    '1377_916': 30.8095,
    '1377_917': 30.5871,
    '1377_918': 30.3779,
    '1377_919': 30.1874,
    '1377_920': 30.0114,
    '1377_921': 29.8452,
    '1377_922': 29.6833,
    '1377_923': 29.5232,
    '1377_924': 29.3697,
    '1377_925': 29.2212,
    '1377_926': 29.081,
    '1377_927': 28.9515,
    '1377_928': 28.8374,
    '1377_929': 28.744,
    '1377_930': 28.672,
    '1377_931': 28.6239,
    '1377_932': 28.6019,
    '1377_933': 28.597,
    '1377_934': 28.5961,
    '1377_935': 28.5952,
    '1377_936': 28.5851,
    '1377_937': 28.5638,
    '1377_938': 28.5519,
    '1377_939': 28.5574,
    '1377_940': 28.5587,
    '1377_941': 28.5636,
    '1377_942': 28.5832,
    '1377_943': 28.6111,
    '1377_944': 28.6518,
    '1377_945': 28.6942,
    '1377_946': 28.7357,
    '1377_947': 28.7793,
    '1377_948': 28.804,
    '1377_949': 28.8182,
    '1377_950': 28.8097,
    '1377_951': 28.7859,
    '1377_952': 28.7487,
    '1377_953': 28.7109,
    '1377_954': 28.6723,
    '1377_955': 28.6316,
    '1377_956': 28.591,
    '1377_957': 28.559,
    '1377_958': 28.5475,
    '1377_959': 28.5496,
    '1377_960': 28.5672,
    '1377_961': 28.5909,
    '1377_962': 28.6174,
    '1377_963': 28.6493,
    '1377_964': 28.6798,
    '1377_965': 28.6983,
    '1377_966': 28.7192,
    '1377_967': 28.7511,
    '1377_968': 28.7912,
    '1377_969': 28.8367,
    '1377_970': 28.8859,
    '1377_971': 28.9378,
    '1377_972': 28.9913,
    '1377_973': 29.0466,
    '1377_974': 29.1047,
    '1377_975': 29.1666,
    '1377_976': 29.2218,
    '1377_977': 29.2651,
    '1377_978': 29.2993,
    '1377_979': 29.3252,
    '1377_980': 29.342,
    '1377_981': 29.351,
    '1377_982': 29.3553,
    '1377_983': 29.3511,
    '1377_984': 29.3409,
    '1377_985': 29.3222,
    '1377_986': 29.2974,
    '1377_987': 29.2673,
    '1377_988': 29.23,
    '1377_989': 29.189,
    '1377_990': 29.1339,
    '1377_991': 29.0695,
    '1377_992': 29.0057,
    '1377_993': 28.9415,
    '1377_994': 28.8707,
    '1377_995': 28.7956,
    '1377_996': 28.7097,
    '1377_997': 28.616,
    '1377_998': 28.5155,
    '1377_999': 28.4077,
    '1377_1000': 28.2926,
    '1377_1001': 28.1717,
    '1377_1002': 28.0465,
    '1377_1003': 27.9195,
    '1377_1004': 27.7945,
    '1377_1005': 27.6703,
    '1377_1006': 27.548,
    '1377_1007': 27.4246,
    '1377_1008': 27.2986,
    '1377_1009': 27.1665,
    '1377_1010': 27.034,
    '1377_1011': 26.8887,
    '1377_1012': 26.7372,
    '1377_1013': 26.5799,
    '1377_1014': 26.4146,
    '1377_1015': 26.2471,
    '1377_1016': 26.0722,
    '1377_1017': 25.8925,
    '1377_1018': 25.7044,
    '1377_1019': 25.5106,
    '1377_1020': 25.3181,
    '1377_1021': 25.105,
    '1377_1022': 24.8771,
    '1377_1023': 24.6399,
    '1377_1024': 24.3896,
    '1377_1025': 24.125,
    '1377_1026': 23.8509,
    '1377_1027': 23.571,
    '1377_1028': 23.2862,
    '1377_1029': 22.9964,
    '1377_1030': 22.6967,
    '1377_1031': 22.3945,
    '1377_1032': 22.1077,
    '1377_1033': 21.8008,
    '1378_806': 32.1032,
    '1378_807': 32.2011,
    '1378_808': 32.2922,
    '1378_809': 32.3748,
    '1378_810': 32.4506,
    '1378_811': 32.5248,
    '1378_812': 32.5993,
    '1378_813': 32.6772,
    '1378_814': 32.7596,
    '1378_815': 32.8484,
    '1378_816': 32.9348,
    '1378_817': 33.02,
    '1378_818': 33.0913,
    '1378_819': 33.1446,
    '1378_820': 33.1823,
    '1378_821': 33.2099,
    '1378_822': 33.2388,
    '1378_823': 33.271,
    '1378_824': 33.3073,
    '1378_825': 33.3431,
    '1378_826': 33.366,
    '1378_827': 33.3765,
    '1378_828': 33.3889,
    '1378_829': 33.4075,
    '1378_830': 33.4226,
    '1378_831': 33.4374,
    '1378_832': 33.467,
    '1378_833': 33.5015,
    '1378_834': 33.5348,
    '1378_835': 33.5677,
    '1378_836': 33.5983,
    '1378_837': 33.6135,
    '1378_838': 33.6146,
    '1378_839': 33.6061,
    '1378_840': 33.5867,
    '1378_841': 33.5549,
    '1378_842': 33.5083,
    '1378_843': 33.4397,
    '1378_844': 33.3573,
    '1378_845': 33.2633,
    '1378_846': 33.1675,
    '1378_847': 33.0759,
    '1378_848': 32.9802,
    '1378_849': 32.8721,
    '1378_850': 32.7633,
    '1378_851': 32.6388,
    '1378_852': 32.5021,
    '1378_853': 32.3495,
    '1378_854': 32.19,
    '1378_855': 32.039,
    '1378_856': 31.8867,
    '1378_857': 31.7307,
    '1378_858': 31.5799,
    '1378_859': 31.4446,
    '1378_860': 31.3177,
    '1378_861': 31.1973,
    '1378_862': 31.0809,
    '1378_863': 30.9687,
    '1378_864': 30.8686,
    '1378_865': 30.7788,
    '1378_866': 30.6978,
    '1378_867': 30.6261,
    '1378_868': 30.5644,
    '1378_869': 30.5124,
    '1378_870': 30.4627,
    '1378_871': 30.4018,
    '1378_872': 30.35,
    '1378_873': 30.313,
    '1378_874': 30.2903,
    '1378_875': 30.2826,
    '1378_876': 30.2923,
    '1378_877': 30.3083,
    '1378_878': 30.3164,
    '1378_879': 30.3116,
    '1378_880': 30.301,
    '1378_881': 30.2963,
    '1378_882': 30.3113,
    '1378_883': 30.3401,
    '1378_884': 30.379,
    '1378_885': 30.4181,
    '1378_886': 30.4569,
    '1378_887': 30.514,
    '1378_888': 30.5945,
    '1378_889': 30.6905,
    '1378_890': 30.7978,
    '1378_891': 30.9002,
    '1378_892': 30.9857,
    '1378_893': 31.0705,
    '1378_894': 31.1736,
    '1378_895': 31.2682,
    '1378_896': 31.3593,
    '1378_897': 31.4597,
    '1378_898': 31.5784,
    '1378_899': 31.7051,
    '1378_900': 31.841,
    '1378_901': 31.9793,
    '1378_902': 32.1104,
    '1378_903': 32.2192,
    '1378_904': 32.2977,
    '1378_905': 32.3574,
    '1378_906': 32.3969,
    '1378_907': 32.4087,
    '1378_908': 32.3739,
    '1378_909': 32.2862,
    '1378_910': 32.1443,
    '1378_911': 31.96,
    '1378_912': 31.7318,
    '1378_913': 31.4932,
    '1378_914': 31.2543,
    '1378_915': 31.0166,
    '1378_916': 30.7906,
    '1378_917': 30.5774,
    '1378_918': 30.3767,
    '1378_919': 30.1941,
    '1378_920': 30.0277,
    '1378_921': 29.8699,
    '1378_922': 29.7167,
    '1378_923': 29.5654,
    '1378_924': 29.4168,
    '1378_925': 29.273,
    '1378_926': 29.1352,
    '1378_927': 29.0104,
    '1378_928': 28.8992,
    '1378_929': 28.8081,
    '1378_930': 28.7385,
    '1378_931': 28.6956,
    '1378_932': 28.6799,
    '1378_933': 28.6838,
    '1378_934': 28.6937,
    '1378_935': 28.7021,
    '1378_936': 28.7021,
    '1378_937': 28.684,
    '1378_938': 28.6736,
    '1378_939': 28.6843,
    '1378_940': 28.6937,
    '1378_941': 28.7025,
    '1378_942': 28.7244,
    '1378_943': 28.7568,
    '1378_944': 28.8002,
    '1378_945': 28.8518,
    '1378_946': 28.9066,
    '1378_947': 28.9578,
    '1378_948': 28.9907,
    '1378_949': 29.0146,
    '1378_950': 29.0118,
    '1378_951': 28.9892,
    '1378_952': 28.9607,
    '1378_953': 28.9307,
    '1378_954': 28.8932,
    '1378_955': 28.8581,
    '1378_956': 28.8275,
    '1378_957': 28.8006,
    '1378_958': 28.7874,
    '1378_959': 28.7943,
    '1378_960': 28.8157,
    '1378_961': 28.8464,
    '1378_962': 28.8751,
    '1378_963': 28.9024,
    '1378_964': 28.9287,
    '1378_965': 28.9399,
    '1378_966': 28.9589,
    '1378_967': 28.9873,
    '1378_968': 29.0265,
    '1378_969': 29.071,
    '1378_970': 29.1189,
    '1378_971': 29.1698,
    '1378_972': 29.2234,
    '1378_973': 29.2798,
    '1378_974': 29.3396,
    '1378_975': 29.3976,
    '1378_976': 29.4476,
    '1378_977': 29.4891,
    '1378_978': 29.5246,
    '1378_979': 29.5538,
    '1378_980': 29.5746,
    '1378_981': 29.589,
    '1378_982': 29.5957,
    '1378_983': 29.5962,
    '1378_984': 29.5894,
    '1378_985': 29.5717,
    '1378_986': 29.5462,
    '1378_987': 29.5166,
    '1378_988': 29.4822,
    '1378_989': 29.4426,
    '1378_990': 29.3896,
    '1378_991': 29.3324,
    '1378_992': 29.2752,
    '1378_993': 29.214,
    '1378_994': 29.1467,
    '1378_995': 29.0749,
    '1378_996': 28.9962,
    '1378_997': 28.9087,
    '1378_998': 28.8144,
    '1378_999': 28.7117,
    '1378_1000': 28.5991,
    '1378_1001': 28.4792,
    '1378_1002': 28.3547,
    '1378_1003': 28.2278,
    '1378_1004': 28.1034,
    '1378_1005': 27.9813,
    '1378_1006': 27.8616,
    '1378_1007': 27.743,
    '1378_1008': 27.623,
    '1378_1009': 27.4965,
    '1378_1010': 27.362,
    '1378_1011': 27.2218,
    '1378_1012': 27.0748,
    '1378_1013': 26.9295,
    '1378_1014': 26.7679,
    '1378_1015': 26.6063,
    '1378_1016': 26.4314,
    '1378_1017': 26.2561,
    '1378_1018': 26.0726,
    '1378_1019': 25.8825,
    '1378_1020': 25.6931,
    '1378_1021': 25.4829,
    '1378_1022': 25.2611,
    '1378_1023': 25.0323,
    '1378_1024': 24.7915,
    '1378_1025': 24.532,
    '1378_1026': 24.2625,
    '1378_1027': 23.9863,
    '1378_1028': 23.7041,
    '1378_1029': 23.4149,
    '1378_1030': 23.1179,
    '1378_1031': 22.818,
    '1378_1032': 22.5219,
    '1378_1033': 22.2155,
    '1379_806': 32.0528,
    '1379_807': 32.1525,
    '1379_808': 32.2444,
    '1379_809': 32.3241,
    '1379_810': 32.3941,
    '1379_811': 32.4626,
    '1379_812': 32.5299,
    '1379_813': 32.6022,
    '1379_814': 32.6775,
    '1379_815': 32.7633,
    '1379_816': 32.8471,
    '1379_817': 32.9271,
    '1379_818': 32.994,
    '1379_819': 33.0509,
    '1379_820': 33.0964,
    '1379_821': 33.1347,
    '1379_822': 33.174,
    '1379_823': 33.214,
    '1379_824': 33.2572,
    '1379_825': 33.3016,
    '1379_826': 33.3346,
    '1379_827': 33.3592,
    '1379_828': 33.3819,
    '1379_829': 33.4054,
    '1379_830': 33.4265,
    '1379_831': 33.4398,
    '1379_832': 33.4633,
    '1379_833': 33.4931,
    '1379_834': 33.5171,
    '1379_835': 33.5383,
    '1379_836': 33.5609,
    '1379_837': 33.5724,
    '1379_838': 33.5752,
    '1379_839': 33.5719,
    '1379_840': 33.5541,
    '1379_841': 33.5238,
    '1379_842': 33.473,
    '1379_843': 33.4016,
    '1379_844': 33.3242,
    '1379_845': 33.2372,
    '1379_846': 33.1536,
    '1379_847': 33.0713,
    '1379_848': 32.981,
    '1379_849': 32.8756,
    '1379_850': 32.7653,
    '1379_851': 32.6307,
    '1379_852': 32.479,
    '1379_853': 32.3177,
    '1379_854': 32.1578,
    '1379_855': 32.0093,
    '1379_856': 31.8608,
    '1379_857': 31.7059,
    '1379_858': 31.5629,
    '1379_859': 31.4369,
    '1379_860': 31.3203,
    '1379_861': 31.2094,
    '1379_862': 31.1014,
    '1379_863': 30.9976,
    '1379_864': 30.9041,
    '1379_865': 30.8189,
    '1379_866': 30.742,
    '1379_867': 30.6746,
    '1379_868': 30.618,
    '1379_869': 30.5724,
    '1379_870': 30.5294,
    '1379_871': 30.4705,
    '1379_872': 30.4184,
    '1379_873': 30.3832,
    '1379_874': 30.3645,
    '1379_875': 30.3638,
    '1379_876': 30.3803,
    '1379_877': 30.3999,
    '1379_878': 30.4123,
    '1379_879': 30.4101,
    '1379_880': 30.4051,
    '1379_881': 30.4109,
    '1379_882': 30.4319,
    '1379_883': 30.4644,
    '1379_884': 30.504,
    '1379_885': 30.5422,
    '1379_886': 30.5851,
    '1379_887': 30.6505,
    '1379_888': 30.7387,
    '1379_889': 30.84,
    '1379_890': 30.9441,
    '1379_891': 31.0376,
    '1379_892': 31.1143,
    '1379_893': 31.1875,
    '1379_894': 31.2799,
    '1379_895': 31.3678,
    '1379_896': 31.447,
    '1379_897': 31.5459,
    '1379_898': 31.6648,
    '1379_899': 31.7882,
    '1379_900': 31.9126,
    '1379_901': 32.0379,
    '1379_902': 32.1532,
    '1379_903': 32.2459,
    '1379_904': 32.3123,
    '1379_905': 32.3527,
    '1379_906': 32.3729,
    '1379_907': 32.3704,
    '1379_908': 32.3285,
    '1379_909': 32.2391,
    '1379_910': 32.096,
    '1379_911': 31.9082,
    '1379_912': 31.686,
    '1379_913': 31.4498,
    '1379_914': 31.2179,
    '1379_915': 30.9921,
    '1379_916': 30.7759,
    '1379_917': 30.5705,
    '1379_918': 30.3794,
    '1379_919': 30.2067,
    '1379_920': 30.0493,
    '1379_921': 29.9004,
    '1379_922': 29.7533,
    '1379_923': 29.6085,
    '1379_924': 29.4669,
    '1379_925': 29.3278,
    '1379_926': 29.1933,
    '1379_927': 29.072,
    '1379_928': 28.9636,
    '1379_929': 28.8749,
    '1379_930': 28.808,
    '1379_931': 28.7683,
    '1379_932': 28.7551,
    '1379_933': 28.7612,
    '1379_934': 28.7761,
    '1379_935': 28.7906,
    '1379_936': 28.7957,
    '1379_937': 28.7883,
    '1379_938': 28.7843,
    '1379_939': 28.7984,
    '1379_940': 28.815,
    '1379_941': 28.8311,
    '1379_942': 28.8553,
    '1379_943': 28.8929,
    '1379_944': 28.939,
    '1379_945': 28.9972,
    '1379_946': 29.0632,
    '1379_947': 29.1254,
    '1379_948': 29.1696,
    '1379_949': 29.1996,
    '1379_950': 29.2017,
    '1379_951': 29.1842,
    '1379_952': 29.1656,
    '1379_953': 29.1404,
    '1379_954': 29.1075,
    '1379_955': 29.0755,
    '1379_956': 29.0499,
    '1379_957': 29.0267,
    '1379_958': 29.0118,
    '1379_959': 29.0227,
    '1379_960': 29.0513,
    '1379_961': 29.0884,
    '1379_962': 29.1147,
    '1379_963': 29.1398,
    '1379_964': 29.1628,
    '1379_965': 29.1688,
    '1379_966': 29.1806,
    '1379_967': 29.2098,
    '1379_968': 29.2482,
    '1379_969': 29.2912,
    '1379_970': 29.3358,
    '1379_971': 29.3853,
    '1379_972': 29.4379,
    '1379_973': 29.4933,
    '1379_974': 29.5509,
    '1379_975': 29.6046,
    '1379_976': 29.6517,
    '1379_977': 29.6933,
    '1379_978': 29.7311,
    '1379_979': 29.764,
    '1379_980': 29.7891,
    '1379_981': 29.8073,
    '1379_982': 29.8174,
    '1379_983': 29.821,
    '1379_984': 29.8149,
    '1379_985': 29.7983,
    '1379_986': 29.7766,
    '1379_987': 29.7494,
    '1379_988': 29.7164,
    '1379_989': 29.6765,
    '1379_990': 29.6307,
    '1379_991': 29.5823,
    '1379_992': 29.5296,
    '1379_993': 29.4725,
    '1379_994': 29.4097,
    '1379_995': 29.3431,
    '1379_996': 29.27,
    '1379_997': 29.1896,
    '1379_998': 29.1028,
    '1379_999': 29.0063,
    '1379_1000': 28.8948,
    '1379_1001': 28.7762,
    '1379_1002': 28.654,
    '1379_1003': 28.5267,
    '1379_1004': 28.4035,
    '1379_1005': 28.282,
    '1379_1006': 28.1646,
    '1379_1007': 28.0493,
    '1379_1008': 27.9343,
    '1379_1009': 27.8093,
    '1379_1010': 27.6801,
    '1379_1011': 27.5458,
    '1379_1012': 27.4022,
    '1379_1013': 27.2547,
    '1379_1014': 27.1016,
    '1379_1015': 26.9472,
    '1379_1016': 26.7824,
    '1379_1017': 26.6085,
    '1379_1018': 26.4241,
    '1379_1019': 26.2331,
    '1379_1020': 26.0459,
    '1379_1021': 25.8436,
    '1379_1022': 25.6322,
    '1379_1023': 25.4091,
    '1379_1024': 25.1731,
    '1379_1025': 24.9224,
    '1379_1026': 24.6609,
    '1379_1027': 24.3908,
    '1379_1028': 24.1133,
    '1379_1029': 23.828,
    '1379_1030': 23.5347,
    '1379_1031': 23.2353,
    '1379_1032': 22.9356,
    '1379_1033': 22.6284,
    '1380_806': 32.0107,
    '1380_807': 32.1114,
    '1380_808': 32.2038,
    '1380_809': 32.2754,
    '1380_810': 32.3395,
    '1380_811': 32.4017,
    '1380_812': 32.4656,
    '1380_813': 32.5368,
    '1380_814': 32.6151,
    '1380_815': 32.6968,
    '1380_816': 32.7751,
    '1380_817': 32.8569,
    '1380_818': 32.931,
    '1380_819': 32.9906,
    '1380_820': 33.043,
    '1380_821': 33.0891,
    '1380_822': 33.1352,
    '1380_823': 33.184,
    '1380_824': 33.236,
    '1380_825': 33.2853,
    '1380_826': 33.3253,
    '1380_827': 33.3546,
    '1380_828': 33.3801,
    '1380_829': 33.4039,
    '1380_830': 33.4193,
    '1380_831': 33.4291,
    '1380_832': 33.4468,
    '1380_833': 33.4685,
    '1380_834': 33.4807,
    '1380_835': 33.4888,
    '1380_836': 33.5113,
    '1380_837': 33.5252,
    '1380_838': 33.5355,
    '1380_839': 33.5416,
    '1380_840': 33.5306,
    '1380_841': 33.5003,
    '1380_842': 33.4469,
    '1380_843': 33.3786,
    '1380_844': 33.3068,
    '1380_845': 33.2286,
    '1380_846': 33.1501,
    '1380_847': 33.0772,
    '1380_848': 32.9906,
    '1380_849': 32.8812,
    '1380_850': 32.7592,
    '1380_851': 32.6173,
    '1380_852': 32.4567,
    '1380_853': 32.2925,
    '1380_854': 32.1297,
    '1380_855': 31.9787,
    '1380_856': 31.8309,
    '1380_857': 31.6828,
    '1380_858': 31.5477,
    '1380_859': 31.4281,
    '1380_860': 31.3194,
    '1380_861': 31.2176,
    '1380_862': 31.1172,
    '1380_863': 31.0211,
    '1380_864': 30.9341,
    '1380_865': 30.8542,
    '1380_866': 30.7817,
    '1380_867': 30.7186,
    '1380_868': 30.6672,
    '1380_869': 30.6268,
    '1380_870': 30.5873,
    '1380_871': 30.5316,
    '1380_872': 30.4818,
    '1380_873': 30.4498,
    '1380_874': 30.4358,
    '1380_875': 30.4404,
    '1380_876': 30.4626,
    '1380_877': 30.4846,
    '1380_878': 30.5016,
    '1380_879': 30.5092,
    '1380_880': 30.5143,
    '1380_881': 30.5246,
    '1380_882': 30.5458,
    '1380_883': 30.5783,
    '1380_884': 30.6174,
    '1380_885': 30.6557,
    '1380_886': 30.7094,
    '1380_887': 30.7857,
    '1380_888': 30.8816,
    '1380_889': 30.9884,
    '1380_890': 31.0881,
    '1380_891': 31.1711,
    '1380_892': 31.2399,
    '1380_893': 31.3101,
    '1380_894': 31.3912,
    '1380_895': 31.4709,
    '1380_896': 31.541,
    '1380_897': 31.6329,
    '1380_898': 31.7469,
    '1380_899': 31.8651,
    '1380_900': 31.9801,
    '1380_901': 32.0914,
    '1380_902': 32.1906,
    '1380_903': 32.2653,
    '1380_904': 32.3166,
    '1380_905': 32.3407,
    '1380_906': 32.3439,
    '1380_907': 32.327,
    '1380_908': 32.2795,
    '1380_909': 32.1849,
    '1380_910': 32.046,
    '1380_911': 31.8642,
    '1380_912': 31.6473,
    '1380_913': 31.4181,
    '1380_914': 31.1886,
    '1380_915': 30.9717,
    '1380_916': 30.7656,
    '1380_917': 30.5707,
    '1380_918': 30.3902,
    '1380_919': 30.2277,
    '1380_920': 30.0789,
    '1380_921': 29.9356,
    '1380_922': 29.7939,
    '1380_923': 29.6544,
    '1380_924': 29.5191,
    '1380_925': 29.3839,
    '1380_926': 29.254,
    '1380_927': 29.1356,
    '1380_928': 29.0302,
    '1380_929': 28.9445,
    '1380_930': 28.8802,
    '1380_931': 28.8423,
    '1380_932': 28.8303,
    '1380_933': 28.8364,
    '1380_934': 28.8531,
    '1380_935': 28.8701,
    '1380_936': 28.8782,
    '1380_937': 28.8796,
    '1380_938': 28.8848,
    '1380_939': 28.9038,
    '1380_940': 28.9286,
    '1380_941': 28.9525,
    '1380_942': 28.9813,
    '1380_943': 29.0258,
    '1380_944': 29.0779,
    '1380_945': 29.1381,
    '1380_946': 29.2099,
    '1380_947': 29.2848,
    '1380_948': 29.3389,
    '1380_949': 29.372,
    '1380_950': 29.3813,
    '1380_951': 29.3724,
    '1380_952': 29.356,
    '1380_953': 29.3308,
    '1380_954': 29.3039,
    '1380_955': 29.2795,
    '1380_956': 29.2622,
    '1380_957': 29.2462,
    '1380_958': 29.2351,
    '1380_959': 29.2453,
    '1380_960': 29.2752,
    '1380_961': 29.3124,
    '1380_962': 29.344,
    '1380_963': 29.3677,
    '1380_964': 29.3822,
    '1380_965': 29.3861,
    '1380_966': 29.3902,
    '1380_967': 29.4156,
    '1380_968': 29.4519,
    '1380_969': 29.4928,
    '1380_970': 29.5371,
    '1380_971': 29.5845,
    '1380_972': 29.6353,
    '1380_973': 29.6886,
    '1380_974': 29.7422,
    '1380_975': 29.7919,
    '1380_976': 29.8368,
    '1380_977': 29.8789,
    '1380_978': 29.9206,
    '1380_979': 29.9567,
    '1380_980': 29.9848,
    '1380_981': 30.0056,
    '1380_982': 30.0187,
    '1380_983': 30.0233,
    '1380_984': 30.0193,
    '1380_985': 30.0081,
    '1380_986': 29.9913,
    '1380_987': 29.9674,
    '1380_988': 29.9366,
    '1380_989': 29.9009,
    '1380_990': 29.8613,
    '1380_991': 29.8187,
    '1380_992': 29.7708,
    '1380_993': 29.7175,
    '1380_994': 29.6617,
    '1380_995': 29.6015,
    '1380_996': 29.533,
    '1380_997': 29.4581,
    '1380_998': 29.3763,
    '1380_999': 29.285,
    '1380_1000': 29.1821,
    '1380_1001': 29.0684,
    '1380_1002': 28.9482,
    '1380_1003': 28.8243,
    '1380_1004': 28.701,
    '1380_1005': 28.5789,
    '1380_1006': 28.4617,
    '1380_1007': 28.3485,
    '1380_1008': 28.2335,
    '1380_1009': 28.1101,
    '1380_1010': 27.9872,
    '1380_1011': 27.8591,
    '1380_1012': 27.7223,
    '1380_1013': 27.5707,
    '1380_1014': 27.4253,
    '1380_1015': 27.2714,
    '1380_1016': 27.1076,
    '1380_1017': 26.9422,
    '1380_1018': 26.7675,
    '1380_1019': 26.5861,
    '1380_1020': 26.396,
    '1380_1021': 26.1935,
    '1380_1022': 25.9903,
    '1380_1023': 25.7735,
    '1380_1024': 25.544,
    '1380_1025': 25.3016,
    '1380_1026': 25.0489,
    '1380_1027': 24.787,
    '1380_1028': 24.5166,
    '1380_1029': 24.2382,
    '1380_1030': 23.9525,
    '1380_1031': 23.6588,
    '1380_1032': 23.3553,
    '1380_1033': 23.0447,
    '1381_806': 31.9838,
    '1381_807': 32.08,
    '1381_808': 32.1684,
    '1381_809': 32.2402,
    '1381_810': 32.3018,
    '1381_811': 32.3629,
    '1381_812': 32.4262,
    '1381_813': 32.4948,
    '1381_814': 32.5706,
    '1381_815': 32.6481,
    '1381_816': 32.729,
    '1381_817': 32.8142,
    '1381_818': 32.8929,
    '1381_819': 32.961,
    '1381_820': 33.0179,
    '1381_821': 33.0716,
    '1381_822': 33.1225,
    '1381_823': 33.1767,
    '1381_824': 33.2335,
    '1381_825': 33.2891,
    '1381_826': 33.3315,
    '1381_827': 33.3588,
    '1381_828': 33.3749,
    '1381_829': 33.3809,
    '1381_830': 33.3812,
    '1381_831': 33.3833,
    '1381_832': 33.3946,
    '1381_833': 33.4126,
    '1381_834': 33.4276,
    '1381_835': 33.4383,
    '1381_836': 33.4638,
    '1381_837': 33.4841,
    '1381_838': 33.5,
    '1381_839': 33.5107,
    '1381_840': 33.5047,
    '1381_841': 33.4804,
    '1381_842': 33.4332,
    '1381_843': 33.3686,
    '1381_844': 33.2976,
    '1381_845': 33.2214,
    '1381_846': 33.1462,
    '1381_847': 33.0738,
    '1381_848': 32.9828,
    '1381_849': 32.8653,
    '1381_850': 32.7282,
    '1381_851': 32.5837,
    '1381_852': 32.4308,
    '1381_853': 32.2731,
    '1381_854': 32.1055,
    '1381_855': 31.9491,
    '1381_856': 31.8011,
    '1381_857': 31.6593,
    '1381_858': 31.531,
    '1381_859': 31.4189,
    '1381_860': 31.3181,
    '1381_861': 31.225,
    '1381_862': 31.1313,
    '1381_863': 31.0419,
    '1381_864': 30.9607,
    '1381_865': 30.8862,
    '1381_866': 30.8186,
    '1381_867': 30.7601,
    '1381_868': 30.7125,
    '1381_869': 30.6751,
    '1381_870': 30.6364,
    '1381_871': 30.5854,
    '1381_872': 30.541,
    '1381_873': 30.5148,
    '1381_874': 30.5055,
    '1381_875': 30.5118,
    '1381_876': 30.5342,
    '1381_877': 30.5582,
    '1381_878': 30.5812,
    '1381_879': 30.6053,
    '1381_880': 30.6255,
    '1381_881': 30.6438,
    '1381_882': 30.6582,
    '1381_883': 30.6835,
    '1381_884': 30.7208,
    '1381_885': 30.765,
    '1381_886': 30.8299,
    '1381_887': 30.9129,
    '1381_888': 31.015,
    '1381_889': 31.127,
    '1381_890': 31.2285,
    '1381_891': 31.3076,
    '1381_892': 31.3744,
    '1381_893': 31.4406,
    '1381_894': 31.5072,
    '1381_895': 31.5735,
    '1381_896': 31.6367,
    '1381_897': 31.7204,
    '1381_898': 31.8237,
    '1381_899': 31.9323,
    '1381_900': 32.0363,
    '1381_901': 32.1323,
    '1381_902': 32.2139,
    '1381_903': 32.2755,
    '1381_904': 32.314,
    '1381_905': 32.3243,
    '1381_906': 32.3116,
    '1381_907': 32.282,
    '1381_908': 32.2277,
    '1381_909': 32.1352,
    '1381_910': 31.9962,
    '1381_911': 31.8137,
    '1381_912': 31.6037,
    '1381_913': 31.3834,
    '1381_914': 31.1642,
    '1381_915': 30.959,
    '1381_916': 30.7649,
    '1381_917': 30.5821,
    '1381_918': 30.4118,
    '1381_919': 30.2587,
    '1381_920': 30.116,
    '1381_921': 29.977,
    '1381_922': 29.8407,
    '1381_923': 29.708,
    '1381_924': 29.5786,
    '1381_925': 29.4471,
    '1381_926': 29.3193,
    '1381_927': 29.2031,
    '1381_928': 29.1009,
    '1381_929': 29.0173,
    '1381_930': 28.9543,
    '1381_931': 28.9167,
    '1381_932': 28.9063,
    '1381_933': 28.9101,
    '1381_934': 28.9243,
    '1381_935': 28.9425,
    '1381_936': 28.9561,
    '1381_937': 28.9676,
    '1381_938': 28.9829,
    '1381_939': 29.0082,
    '1381_940': 29.0392,
    '1381_941': 29.0689,
    '1381_942': 29.1011,
    '1381_943': 29.1498,
    '1381_944': 29.2063,
    '1381_945': 29.2704,
    '1381_946': 29.35,
    '1381_947': 29.4316,
    '1381_948': 29.4941,
    '1381_949': 29.5341,
    '1381_950': 29.5498,
    '1381_951': 29.5459,
    '1381_952': 29.5301,
    '1381_953': 29.5109,
    '1381_954': 29.493,
    '1381_955': 29.4761,
    '1381_956': 29.4681,
    '1381_957': 29.458,
    '1381_958': 29.4495,
    '1381_959': 29.4569,
    '1381_960': 29.4871,
    '1381_961': 29.5226,
    '1381_962': 29.5591,
    '1381_963': 29.5791,
    '1381_964': 29.5812,
    '1381_965': 29.5768,
    '1381_966': 29.5844,
    '1381_967': 29.6045,
    '1381_968': 29.6355,
    '1381_969': 29.6744,
    '1381_970': 29.7169,
    '1381_971': 29.7623,
    '1381_972': 29.8119,
    '1381_973': 29.8631,
    '1381_974': 29.9132,
    '1381_975': 29.9604,
    '1381_976': 30.0058,
    '1381_977': 30.0513,
    '1381_978': 30.0956,
    '1381_979': 30.1351,
    '1381_980': 30.1658,
    '1381_981': 30.1877,
    '1381_982': 30.205,
    '1381_983': 30.2124,
    '1381_984': 30.211,
    '1381_985': 30.2056,
    '1381_986': 30.1926,
    '1381_987': 30.1725,
    '1381_988': 30.1454,
    '1381_989': 30.1158,
    '1381_990': 30.0821,
    '1381_991': 30.0439,
    '1381_992': 29.9995,
    '1381_993': 29.9522,
    '1381_994': 29.903,
    '1381_995': 29.8433,
    '1381_996': 29.776,
    '1381_997': 29.7068,
    '1381_998': 29.633,
    '1381_999': 29.5483,
    '1381_1000': 29.4518,
    '1381_1001': 29.3466,
    '1381_1002': 29.2333,
    '1381_1003': 29.1148,
    '1381_1004': 28.9945,
    '1381_1005': 28.8739,
    '1381_1006': 28.7553,
    '1381_1007': 28.6446,
    '1381_1008': 28.5324,
    '1381_1009': 28.412,
    '1381_1010': 28.2891,
    '1381_1011': 28.161,
    '1381_1012': 28.0271,
    '1381_1013': 27.8869,
    '1381_1014': 27.748,
    '1381_1015': 27.594,
    '1381_1016': 27.4341,
    '1381_1017': 27.2705,
    '1381_1018': 27.0962,
    '1381_1019': 26.9118,
    '1381_1020': 26.7293,
    '1381_1021': 26.5371,
    '1381_1022': 26.3413,
    '1381_1023': 26.1282,
    '1381_1024': 25.9062,
    '1381_1025': 25.6721,
    '1381_1026': 25.4286,
    '1381_1027': 25.1764,
    '1381_1028': 24.9143,
    '1381_1029': 24.6434,
    '1381_1030': 24.3664,
    '1381_1031': 24.0807,
    '1381_1032': 23.7757,
    '1381_1033': 23.4638,
    '1382_806': 31.9728,
    '1382_807': 32.0673,
    '1382_808': 32.1519,
    '1382_809': 32.2216,
    '1382_810': 32.2793,
    '1382_811': 32.3412,
    '1382_812': 32.4051,
    '1382_813': 32.4724,
    '1382_814': 32.5504,
    '1382_815': 32.6309,
    '1382_816': 32.7142,
    '1382_817': 32.7983,
    '1382_818': 32.8778,
    '1382_819': 32.9472,
    '1382_820': 33.015,
    '1382_821': 33.0789,
    '1382_822': 33.1335,
    '1382_823': 33.1912,
    '1382_824': 33.2503,
    '1382_825': 33.3054,
    '1382_826': 33.3444,
    '1382_827': 33.3621,
    '1382_828': 33.3554,
    '1382_829': 33.3306,
    '1382_830': 33.3147,
    '1382_831': 33.3112,
    '1382_832': 33.3208,
    '1382_833': 33.3415,
    '1382_834': 33.3633,
    '1382_835': 33.3834,
    '1382_836': 33.4144,
    '1382_837': 33.4399,
    '1382_838': 33.4582,
    '1382_839': 33.4702,
    '1382_840': 33.4676,
    '1382_841': 33.4476,
    '1382_842': 33.4149,
    '1382_843': 33.3546,
    '1382_844': 33.2826,
    '1382_845': 33.2052,
    '1382_846': 33.1331,
    '1382_847': 33.055,
    '1382_848': 32.9537,
    '1382_849': 32.8206,
    '1382_850': 32.6742,
    '1382_851': 32.5291,
    '1382_852': 32.3828,
    '1382_853': 32.2268,
    '1382_854': 32.0642,
    '1382_855': 31.9126,
    '1382_856': 31.7686,
    '1382_857': 31.6332,
    '1382_858': 31.5122,
    '1382_859': 31.408,
    '1382_860': 31.3158,
    '1382_861': 31.23,
    '1382_862': 31.1429,
    '1382_863': 31.0605,
    '1382_864': 30.985,
    '1382_865': 30.916,
    '1382_866': 30.8534,
    '1382_867': 30.7987,
    '1382_868': 30.7537,
    '1382_869': 30.7169,
    '1382_870': 30.6799,
    '1382_871': 30.6339,
    '1382_872': 30.5977,
    '1382_873': 30.5783,
    '1382_874': 30.5745,
    '1382_875': 30.5833,
    '1382_876': 30.602,
    '1382_877': 30.6295,
    '1382_878': 30.6655,
    '1382_879': 30.7058,
    '1382_880': 30.7397,
    '1382_881': 30.761,
    '1382_882': 30.778,
    '1382_883': 30.8023,
    '1382_884': 30.8307,
    '1382_885': 30.8744,
    '1382_886': 30.9427,
    '1382_887': 31.031,
    '1382_888': 31.1393,
    '1382_889': 31.2522,
    '1382_890': 31.3583,
    '1382_891': 31.4434,
    '1382_892': 31.507,
    '1382_893': 31.5635,
    '1382_894': 31.6168,
    '1382_895': 31.6713,
    '1382_896': 31.73,
    '1382_897': 31.809,
    '1382_898': 31.899,
    '1382_899': 31.9923,
    '1382_900': 32.0831,
    '1382_901': 32.165,
    '1382_902': 32.2297,
    '1382_903': 32.28,
    '1382_904': 32.3071,
    '1382_905': 32.3059,
    '1382_906': 32.2795,
    '1382_907': 32.239,
    '1382_908': 32.1802,
    '1382_909': 32.0846,
    '1382_910': 31.943,
    '1382_911': 31.7606,
    '1382_912': 31.5581,
    '1382_913': 31.3542,
    '1382_914': 31.1511,
    '1382_915': 30.9565,
    '1382_916': 30.7748,
    '1382_917': 30.6019,
    '1382_918': 30.4419,
    '1382_919': 30.2981,
    '1382_920': 30.1596,
    '1382_921': 30.0241,
    '1382_922': 29.8925,
    '1382_923': 29.7654,
    '1382_924': 29.6391,
    '1382_925': 29.5126,
    '1382_926': 29.3892,
    '1382_927': 29.276,
    '1382_928': 29.1753,
    '1382_929': 29.0931,
    '1382_930': 29.0309,
    '1382_931': 28.9931,
    '1382_932': 28.9816,
    '1382_933': 28.9842,
    '1382_934': 28.9951,
    '1382_935': 29.0139,
    '1382_936': 29.0321,
    '1382_937': 29.0503,
    '1382_938': 29.0729,
    '1382_939': 29.1024,
    '1382_940': 29.1397,
    '1382_941': 29.1784,
    '1382_942': 29.2175,
    '1382_943': 29.2676,
    '1382_944': 29.3246,
    '1382_945': 29.395,
    '1382_946': 29.481,
    '1382_947': 29.565,
    '1382_948': 29.6339,
    '1382_949': 29.6835,
    '1382_950': 29.7103,
    '1382_951': 29.7128,
    '1382_952': 29.6988,
    '1382_953': 29.6841,
    '1382_954': 29.6744,
    '1382_955': 29.6659,
    '1382_956': 29.6624,
    '1382_957': 29.6625,
    '1382_958': 29.6582,
    '1382_959': 29.6676,
    '1382_960': 29.6904,
    '1382_961': 29.723,
    '1382_962': 29.7552,
    '1382_963': 29.7661,
    '1382_964': 29.7611,
    '1382_965': 29.7502,
    '1382_966': 29.7564,
    '1382_967': 29.7742,
    '1382_968': 29.8014,
    '1382_969': 29.8349,
    '1382_970': 29.8753,
    '1382_971': 29.9195,
    '1382_972': 29.9679,
    '1382_973': 30.0178,
    '1382_974': 30.0652,
    '1382_975': 30.1108,
    '1382_976': 30.1575,
    '1382_977': 30.2037,
    '1382_978': 30.2494,
    '1382_979': 30.2921,
    '1382_980': 30.3274,
    '1382_981': 30.356,
    '1382_982': 30.376,
    '1382_983': 30.3861,
    '1382_984': 30.3885,
    '1382_985': 30.3864,
    '1382_986': 30.3786,
    '1382_987': 30.3634,
    '1382_988': 30.3425,
    '1382_989': 30.3182,
    '1382_990': 30.291,
    '1382_991': 30.2563,
    '1382_992': 30.2169,
    '1382_993': 30.1743,
    '1382_994': 30.1277,
    '1382_995': 30.0703,
    '1382_996': 30.0076,
    '1382_997': 29.9431,
    '1382_998': 29.8753,
    '1382_999': 29.7975,
    '1382_1000': 29.7083,
    '1382_1001': 29.6096,
    '1382_1002': 29.505,
    '1382_1003': 29.3936,
    '1382_1004': 29.2805,
    '1382_1005': 29.1647,
    '1382_1006': 29.0495,
    '1382_1007': 28.9372,
    '1382_1008': 28.8237,
    '1382_1009': 28.7045,
    '1382_1010': 28.5814,
    '1382_1011': 28.4531,
    '1382_1012': 28.3213,
    '1382_1013': 28.1858,
    '1382_1014': 28.0513,
    '1382_1015': 27.9068,
    '1382_1016': 27.7506,
    '1382_1017': 27.592,
    '1382_1018': 27.4211,
    '1382_1019': 27.2362,
    '1382_1020': 27.0557,
    '1382_1021': 26.8647,
    '1382_1022': 26.6696,
    '1382_1023': 26.4649,
    '1382_1024': 26.2556,
    '1382_1025': 26.0304,
    '1382_1026': 25.798,
    '1382_1027': 25.5573,
    '1382_1028': 25.304,
    '1382_1029': 25.0396,
    '1382_1030': 24.7664,
    '1382_1031': 24.4833,
    '1382_1032': 24.1872,
    '1382_1033': 23.8824,
    '1383_806': 31.9715,
    '1383_807': 32.066,
    '1383_808': 32.1493,
    '1383_809': 32.2164,
    '1383_810': 32.2762,
    '1383_811': 32.3373,
    '1383_812': 32.4034,
    '1383_813': 32.4748,
    '1383_814': 32.5513,
    '1383_815': 32.6322,
    '1383_816': 32.7162,
    '1383_817': 32.7996,
    '1383_818': 32.8794,
    '1383_819': 32.9523,
    '1383_820': 33.0278,
    '1383_821': 33.1028,
    '1383_822': 33.1642,
    '1383_823': 33.2235,
    '1383_824': 33.2847,
    '1383_825': 33.3323,
    '1383_826': 33.3546,
    '1383_827': 33.3526,
    '1383_828': 33.3166,
    '1383_829': 33.2744,
    '1383_830': 33.2608,
    '1383_831': 33.2602,
    '1383_832': 33.2584,
    '1383_833': 33.268,
    '1383_834': 33.2933,
    '1383_835': 33.3196,
    '1383_836': 33.35,
    '1383_837': 33.3786,
    '1383_838': 33.398,
    '1383_839': 33.4096,
    '1383_840': 33.4163,
    '1383_841': 33.4077,
    '1383_842': 33.3746,
    '1383_843': 33.3221,
    '1383_844': 33.2512,
    '1383_845': 33.1765,
    '1383_846': 33.1065,
    '1383_847': 33.0224,
    '1383_848': 32.9095,
    '1383_849': 32.7658,
    '1383_850': 32.6112,
    '1383_851': 32.4613,
    '1383_852': 32.316,
    '1383_853': 32.1645,
    '1383_854': 32.0136,
    '1383_855': 31.8698,
    '1383_856': 31.7336,
    '1383_857': 31.6081,
    '1383_858': 31.4959,
    '1383_859': 31.3983,
    '1383_860': 31.3138,
    '1383_861': 31.2339,
    '1383_862': 31.1529,
    '1383_863': 31.077,
    '1383_864': 31.0073,
    '1383_865': 30.9434,
    '1383_866': 30.8854,
    '1383_867': 30.8341,
    '1383_868': 30.791,
    '1383_869': 30.7542,
    '1383_870': 30.7199,
    '1383_871': 30.6841,
    '1383_872': 30.6567,
    '1383_873': 30.6422,
    '1383_874': 30.6438,
    '1383_875': 30.6582,
    '1383_876': 30.6805,
    '1383_877': 30.7119,
    '1383_878': 30.7584,
    '1383_879': 30.8052,
    '1383_880': 30.8465,
    '1383_881': 30.8725,
    '1383_882': 30.8922,
    '1383_883': 30.9165,
    '1383_884': 30.9385,
    '1383_885': 30.9789,
    '1383_886': 31.0503,
    '1383_887': 31.1466,
    '1383_888': 31.259,
    '1383_889': 31.3725,
    '1383_890': 31.4756,
    '1383_891': 31.5604,
    '1383_892': 31.6184,
    '1383_893': 31.6684,
    '1383_894': 31.713,
    '1383_895': 31.7592,
    '1383_896': 31.8133,
    '1383_897': 31.888,
    '1383_898': 31.9685,
    '1383_899': 32.0468,
    '1383_900': 32.1246,
    '1383_901': 32.193,
    '1383_902': 32.2464,
    '1383_903': 32.2858,
    '1383_904': 32.3039,
    '1383_905': 32.2886,
    '1383_906': 32.2523,
    '1383_907': 32.2011,
    '1383_908': 32.1351,
    '1383_909': 32.0376,
    '1383_910': 31.8954,
    '1383_911': 31.7164,
    '1383_912': 31.5188,
    '1383_913': 31.3289,
    '1383_914': 31.1418,
    '1383_915': 30.9625,
    '1383_916': 30.7927,
    '1383_917': 30.6307,
    '1383_918': 30.4816,
    '1383_919': 30.3437,
    '1383_920': 30.2091,
    '1383_921': 30.077,
    '1383_922': 29.949,
    '1383_923': 29.825,
    '1383_924': 29.7032,
    '1383_925': 29.582,
    '1383_926': 29.4642,
    '1383_927': 29.3523,
    '1383_928': 29.2521,
    '1383_929': 29.1708,
    '1383_930': 29.1093,
    '1383_931': 29.0699,
    '1383_932': 29.0571,
    '1383_933': 29.0609,
    '1383_934': 29.0672,
    '1383_935': 29.0835,
    '1383_936': 29.1054,
    '1383_937': 29.1305,
    '1383_938': 29.158,
    '1383_939': 29.189,
    '1383_940': 29.2329,
    '1383_941': 29.2805,
    '1383_942': 29.3291,
    '1383_943': 29.3846,
    '1383_944': 29.4432,
    '1383_945': 29.5166,
    '1383_946': 29.6058,
    '1383_947': 29.693,
    '1383_948': 29.7659,
    '1383_949': 29.8256,
    '1383_950': 29.8634,
    '1383_951': 29.8735,
    '1383_952': 29.8623,
    '1383_953': 29.848,
    '1383_954': 29.8416,
    '1383_955': 29.8406,
    '1383_956': 29.8481,
    '1383_957': 29.8554,
    '1383_958': 29.8568,
    '1383_959': 29.8647,
    '1383_960': 29.8808,
    '1383_961': 29.91,
    '1383_962': 29.9309,
    '1383_963': 29.9347,
    '1383_964': 29.9294,
    '1383_965': 29.9187,
    '1383_966': 29.9152,
    '1383_967': 29.924,
    '1383_968': 29.9444,
    '1383_969': 29.9748,
    '1383_970': 30.0142,
    '1383_971': 30.058,
    '1383_972': 30.1052,
    '1383_973': 30.1536,
    '1383_974': 30.1986,
    '1383_975': 30.2435,
    '1383_976': 30.2907,
    '1383_977': 30.3387,
    '1383_978': 30.3855,
    '1383_979': 30.4306,
    '1383_980': 30.4706,
    '1383_981': 30.5037,
    '1383_982': 30.5265,
    '1383_983': 30.5399,
    '1383_984': 30.5483,
    '1383_985': 30.5527,
    '1383_986': 30.5489,
    '1383_987': 30.5382,
    '1383_988': 30.5232,
    '1383_989': 30.5057,
    '1383_990': 30.4822,
    '1383_991': 30.4543,
    '1383_992': 30.4194,
    '1383_993': 30.3804,
    '1383_994': 30.3362,
    '1383_995': 30.2834,
    '1383_996': 30.2256,
    '1383_997': 30.1652,
    '1383_998': 30.1011,
    '1383_999': 30.0281,
    '1383_1000': 29.9466,
    '1383_1001': 29.8572,
    '1383_1002': 29.7622,
    '1383_1003': 29.6605,
    '1383_1004': 29.5526,
    '1383_1005': 29.4421,
    '1383_1006': 29.332,
    '1383_1007': 29.2199,
    '1383_1008': 29.1056,
    '1383_1009': 28.9869,
    '1383_1010': 28.8638,
    '1383_1011': 28.7364,
    '1383_1012': 28.6083,
    '1383_1013': 28.477,
    '1383_1014': 28.343,
    '1383_1015': 28.2007,
    '1383_1016': 28.0488,
    '1383_1017': 27.89,
    '1383_1018': 27.7348,
    '1383_1019': 27.5511,
    '1383_1020': 27.3716,
    '1383_1021': 27.1797,
    '1383_1022': 26.9884,
    '1383_1023': 26.7902,
    '1383_1024': 26.5846,
    '1383_1025': 26.3695,
    '1383_1026': 26.151,
    '1383_1027': 25.9251,
    '1383_1028': 25.6817,
    '1383_1029': 25.4235,
    '1383_1030': 25.1561,
    '1383_1031': 24.8792,
    '1383_1032': 24.5954,
    '1383_1033': 24.3047,
    '1384_806': 31.9774,
    '1384_807': 32.0702,
    '1384_808': 32.1499,
    '1384_809': 32.2169,
    '1384_810': 32.2783,
    '1384_811': 32.3403,
    '1384_812': 32.4082,
    '1384_813': 32.4816,
    '1384_814': 32.5594,
    '1384_815': 32.6415,
    '1384_816': 32.7265,
    '1384_817': 32.8107,
    '1384_818': 32.8897,
    '1384_819': 32.9714,
    '1384_820': 33.0537,
    '1384_821': 33.1342,
    '1384_822': 33.2038,
    '1384_823': 33.2619,
    '1384_824': 33.3144,
    '1384_825': 33.3501,
    '1384_826': 33.3553,
    '1384_827': 33.3296,
    '1384_828': 33.2714,
    '1384_829': 33.23,
    '1384_830': 33.2216,
    '1384_831': 33.2134,
    '1384_832': 33.1998,
    '1384_833': 33.2073,
    '1384_834': 33.2339,
    '1384_835': 33.2596,
    '1384_836': 33.2822,
    '1384_837': 33.3054,
    '1384_838': 33.322,
    '1384_839': 33.335,
    '1384_840': 33.3464,
    '1384_841': 33.3442,
    '1384_842': 33.319,
    '1384_843': 33.2687,
    '1384_844': 33.1984,
    '1384_845': 33.1297,
    '1384_846': 33.0616,
    '1384_847': 32.98,
    '1384_848': 32.8595,
    '1384_849': 32.7126,
    '1384_850': 32.5491,
    '1384_851': 32.3888,
    '1384_852': 32.2422,
    '1384_853': 32.1006,
    '1384_854': 31.9614,
    '1384_855': 31.8274,
    '1384_856': 31.7012,
    '1384_857': 31.5844,
    '1384_858': 31.4799,
    '1384_859': 31.3889,
    '1384_860': 31.3114,
    '1384_861': 31.2373,
    '1384_862': 31.1628,
    '1384_863': 31.093,
    '1384_864': 31.0284,
    '1384_865': 30.9694,
    '1384_866': 30.9155,
    '1384_867': 30.8675,
    '1384_868': 30.8263,
    '1384_869': 30.7919,
    '1384_870': 30.7633,
    '1384_871': 30.7395,
    '1384_872': 30.7184,
    '1384_873': 30.7066,
    '1384_874': 30.7144,
    '1384_875': 30.7344,
    '1384_876': 30.7618,
    '1384_877': 30.7987,
    '1384_878': 30.8529,
    '1384_879': 30.9,
    '1384_880': 30.9412,
    '1384_881': 30.9735,
    '1384_882': 30.998,
    '1384_883': 31.0196,
    '1384_884': 31.0381,
    '1384_885': 31.0793,
    '1384_886': 31.1525,
    '1384_887': 31.2558,
    '1384_888': 31.3742,
    '1384_889': 31.4896,
    '1384_890': 31.5874,
    '1384_891': 31.6584,
    '1384_892': 31.7144,
    '1384_893': 31.7556,
    '1384_894': 31.7971,
    '1384_895': 31.8388,
    '1384_896': 31.8869,
    '1384_897': 31.9557,
    '1384_898': 32.0307,
    '1384_899': 32.1016,
    '1384_900': 32.1688,
    '1384_901': 32.2225,
    '1384_902': 32.2654,
    '1384_903': 32.2982,
    '1384_904': 32.3084,
    '1384_905': 32.2857,
    '1384_906': 32.2378,
    '1384_907': 32.1737,
    '1384_908': 32.1005,
    '1384_909': 32.0027,
    '1384_910': 31.863,
    '1384_911': 31.6835,
    '1384_912': 31.4948,
    '1384_913': 31.3164,
    '1384_914': 31.1435,
    '1384_915': 30.9782,
    '1384_916': 30.8183,
    '1384_917': 30.6672,
    '1384_918': 30.529,
    '1384_919': 30.3954,
    '1384_920': 30.2647,
    '1384_921': 30.1358,
    '1384_922': 30.0115,
    '1384_923': 29.8925,
    '1384_924': 29.775,
    '1384_925': 29.657,
    '1384_926': 29.5425,
    '1384_927': 29.4328,
    '1384_928': 29.3336,
    '1384_929': 29.252,
    '1384_930': 29.1901,
    '1384_931': 29.1483,
    '1384_932': 29.1311,
    '1384_933': 29.1326,
    '1384_934': 29.1386,
    '1384_935': 29.1543,
    '1384_936': 29.1779,
    '1384_937': 29.2051,
    '1384_938': 29.2337,
    '1384_939': 29.2705,
    '1384_940': 29.3198,
    '1384_941': 29.376,
    '1384_942': 29.4348,
    '1384_943': 29.4946,
    '1384_944': 29.5558,
    '1384_945': 29.6312,
    '1384_946': 29.7228,
    '1384_947': 29.8122,
    '1384_948': 29.8923,
    '1384_949': 29.9595,
    '1384_950': 30.0071,
    '1384_951': 30.0245,
    '1384_952': 30.0174,
    '1384_953': 30.0039,
    '1384_954': 30.0033,
    '1384_955': 30.0111,
    '1384_956': 30.0218,
    '1384_957': 30.0294,
    '1384_958': 30.0324,
    '1384_959': 30.0436,
    '1384_960': 30.062,
    '1384_961': 30.0814,
    '1384_962': 30.0896,
    '1384_963': 30.0868,
    '1384_964': 30.0802,
    '1384_965': 30.0641,
    '1384_966': 30.0509,
    '1384_967': 30.0528,
    '1384_968': 30.0719,
    '1384_969': 30.1027,
    '1384_970': 30.1405,
    '1384_971': 30.1822,
    '1384_972': 30.2269,
    '1384_973': 30.2701,
    '1384_974': 30.3124,
    '1384_975': 30.3572,
    '1384_976': 30.4047,
    '1384_977': 30.4553,
    '1384_978': 30.5064,
    '1384_979': 30.5531,
    '1384_980': 30.5946,
    '1384_981': 30.6307,
    '1384_982': 30.6582,
    '1384_983': 30.6753,
    '1384_984': 30.6878,
    '1384_985': 30.6954,
    '1384_986': 30.6972,
    '1384_987': 30.6922,
    '1384_988': 30.6835,
    '1384_989': 30.6728,
    '1384_990': 30.6581,
    '1384_991': 30.6363,
    '1384_992': 30.6076,
    '1384_993': 30.5711,
    '1384_994': 30.5303,
    '1384_995': 30.4821,
    '1384_996': 30.4296,
    '1384_997': 30.3753,
    '1384_998': 30.3169,
    '1384_999': 30.2483,
    '1384_1000': 30.1746,
    '1384_1001': 30.0947,
    '1384_1002': 30.0066,
    '1384_1003': 29.9116,
    '1384_1004': 29.8116,
    '1384_1005': 29.7076,
    '1384_1006': 29.6005,
    '1384_1007': 29.4896,
    '1384_1008': 29.3781,
    '1384_1009': 29.261,
    '1384_1010': 29.1404,
    '1384_1011': 29.0166,
    '1384_1012': 28.8905,
    '1384_1013': 28.7608,
    '1384_1014': 28.6279,
    '1384_1015': 28.4879,
    '1384_1016': 28.3399,
    '1384_1017': 28.1847,
    '1384_1018': 28.0242,
    '1384_1019': 27.858,
    '1384_1020': 27.688,
    '1384_1021': 27.498,
    '1384_1022': 27.3052,
    '1384_1023': 27.1082,
    '1384_1024': 26.9044,
    '1384_1025': 26.6944,
    '1384_1026': 26.4864,
    '1384_1027': 26.2712,
    '1384_1028': 26.0422,
    '1384_1029': 25.7929,
    '1384_1030': 25.5366,
    '1384_1031': 25.2698,
    '1384_1032': 24.9973,
    '1384_1033': 24.7195,
    '1385_806': 31.9862,
    '1385_807': 32.0779,
    '1385_808': 32.1558,
    '1385_809': 32.2212,
    '1385_810': 32.2831,
    '1385_811': 32.3451,
    '1385_812': 32.4154,
    '1385_813': 32.4907,
    '1385_814': 32.57,
    '1385_815': 32.6541,
    '1385_816': 32.7404,
    '1385_817': 32.8268,
    '1385_818': 32.9119,
    '1385_819': 33.0059,
    '1385_820': 33.0926,
    '1385_821': 33.1704,
    '1385_822': 33.238,
    '1385_823': 33.2893,
    '1385_824': 33.3304,
    '1385_825': 33.3505,
    '1385_826': 33.3398,
    '1385_827': 33.2954,
    '1385_828': 33.2297,
    '1385_829': 33.1846,
    '1385_830': 33.1787,
    '1385_831': 33.1691,
    '1385_832': 33.1508,
    '1385_833': 33.1561,
    '1385_834': 33.186,
    '1385_835': 33.2111,
    '1385_836': 33.2232,
    '1385_837': 33.2353,
    '1385_838': 33.2434,
    '1385_839': 33.2561,
    '1385_840': 33.2679,
    '1385_841': 33.2669,
    '1385_842': 33.2441,
    '1385_843': 33.1966,
    '1385_844': 33.135,
    '1385_845': 33.0661,
    '1385_846': 32.9944,
    '1385_847': 32.9085,
    '1385_848': 32.7878,
    '1385_849': 32.6449,
    '1385_850': 32.4812,
    '1385_851': 32.3204,
    '1385_852': 32.1793,
    '1385_853': 32.0457,
    '1385_854': 31.916,
    '1385_855': 31.792,
    '1385_856': 31.6741,
    '1385_857': 31.5643,
    '1385_858': 31.4669,
    '1385_859': 31.3832,
    '1385_860': 31.3103,
    '1385_861': 31.2419,
    '1385_862': 31.1736,
    '1385_863': 31.1089,
    '1385_864': 31.0494,
    '1385_865': 30.9947,
    '1385_866': 30.9446,
    '1385_867': 30.8995,
    '1385_868': 30.8607,
    '1385_869': 30.8296,
    '1385_870': 30.8074,
    '1385_871': 30.7921,
    '1385_872': 30.7772,
    '1385_873': 30.7706,
    '1385_874': 30.7843,
    '1385_875': 30.8078,
    '1385_876': 30.8393,
    '1385_877': 30.8767,
    '1385_878': 30.934,
    '1385_879': 30.9857,
    '1385_880': 31.0271,
    '1385_881': 31.0626,
    '1385_882': 31.0911,
    '1385_883': 31.1106,
    '1385_884': 31.1271,
    '1385_885': 31.1716,
    '1385_886': 31.2466,
    '1385_887': 31.3514,
    '1385_888': 31.4766,
    '1385_889': 31.5934,
    '1385_890': 31.6874,
    '1385_891': 31.7525,
    '1385_892': 31.7925,
    '1385_893': 31.826,
    '1385_894': 31.8672,
    '1385_895': 31.9079,
    '1385_896': 31.9485,
    '1385_897': 32.0113,
    '1385_898': 32.0818,
    '1385_899': 32.1503,
    '1385_900': 32.2105,
    '1385_901': 32.2545,
    '1385_902': 32.288,
    '1385_903': 32.3132,
    '1385_904': 32.3168,
    '1385_905': 32.2899,
    '1385_906': 32.2312,
    '1385_907': 32.1564,
    '1385_908': 32.0786,
    '1385_909': 31.9817,
    '1385_910': 31.8446,
    '1385_911': 31.6691,
    '1385_912': 31.4907,
    '1385_913': 31.3233,
    '1385_914': 31.1605,
    '1385_915': 31.0036,
    '1385_916': 30.8529,
    '1385_917': 30.7123,
    '1385_918': 30.583,
    '1385_919': 30.4533,
    '1385_920': 30.3268,
    '1385_921': 30.2041,
    '1385_922': 30.0836,
    '1385_923': 29.9664,
    '1385_924': 29.8522,
    '1385_925': 29.7376,
    '1385_926': 29.6258,
    '1385_927': 29.5177,
    '1385_928': 29.4202,
    '1385_929': 29.3381,
    '1385_930': 29.274,
    '1385_931': 29.2292,
    '1385_932': 29.2071,
    '1385_933': 29.2048,
    '1385_934': 29.2068,
    '1385_935': 29.221,
    '1385_936': 29.2446,
    '1385_937': 29.2732,
    '1385_938': 29.3097,
    '1385_939': 29.3523,
    '1385_940': 29.4026,
    '1385_941': 29.4637,
    '1385_942': 29.5313,
    '1385_943': 29.597,
    '1385_944': 29.6633,
    '1385_945': 29.7404,
    '1385_946': 29.8342,
    '1385_947': 29.9288,
    '1385_948': 30.0127,
    '1385_949': 30.0859,
    '1385_950': 30.1407,
    '1385_951': 30.1674,
    '1385_952': 30.1676,
    '1385_953': 30.1608,
    '1385_954': 30.1615,
    '1385_955': 30.1706,
    '1385_956': 30.1827,
    '1385_957': 30.1934,
    '1385_958': 30.205,
    '1385_959': 30.2164,
    '1385_960': 30.2324,
    '1385_961': 30.2404,
    '1385_962': 30.2364,
    '1385_963': 30.2296,
    '1385_964': 30.2159,
    '1385_965': 30.192,
    '1385_966': 30.172,
    '1385_967': 30.1748,
    '1385_968': 30.1938,
    '1385_969': 30.221,
    '1385_970': 30.2561,
    '1385_971': 30.2968,
    '1385_972': 30.3365,
    '1385_973': 30.3734,
    '1385_974': 30.412,
    '1385_975': 30.4564,
    '1385_976': 30.5045,
    '1385_977': 30.556,
    '1385_978': 30.6095,
    '1385_979': 30.6595,
    '1385_980': 30.7008,
    '1385_981': 30.7382,
    '1385_982': 30.7705,
    '1385_983': 30.7914,
    '1385_984': 30.8062,
    '1385_985': 30.8174,
    '1385_986': 30.8249,
    '1385_987': 30.8262,
    '1385_988': 30.8255,
    '1385_989': 30.8232,
    '1385_990': 30.8158,
    '1385_991': 30.801,
    '1385_992': 30.7781,
    '1385_993': 30.7464,
    '1385_994': 30.7077,
    '1385_995': 30.6646,
    '1385_996': 30.6184,
    '1385_997': 30.5691,
    '1385_998': 30.5158,
    '1385_999': 30.454,
    '1385_1000': 30.3908,
    '1385_1001': 30.3205,
    '1385_1002': 30.2401,
    '1385_1003': 30.1514,
    '1385_1004': 30.0582,
    '1385_1005': 29.9604,
    '1385_1006': 29.8587,
    '1385_1007': 29.7508,
    '1385_1008': 29.6413,
    '1385_1009': 29.5268,
    '1385_1010': 29.4105,
    '1385_1011': 29.2911,
    '1385_1012': 29.168,
    '1385_1013': 29.0406,
    '1385_1014': 28.9093,
    '1385_1015': 28.7715,
    '1385_1016': 28.6265,
    '1385_1017': 28.4743,
    '1385_1018': 28.3155,
    '1385_1019': 28.151,
    '1385_1020': 27.9797,
    '1385_1021': 27.7997,
    '1385_1022': 27.613,
    '1385_1023': 27.4204,
    '1385_1024': 27.2219,
    '1385_1025': 27.0185,
    '1385_1026': 26.8137,
    '1385_1027': 26.6024,
    '1385_1028': 26.3804,
    '1385_1029': 26.1458,
    '1385_1030': 25.9034,
    '1385_1031': 25.6468,
    '1385_1032': 25.3889,
    '1385_1033': 25.1192,
    '1386_806': 31.9886,
    '1386_807': 32.0825,
    '1386_808': 32.1636,
    '1386_809': 32.2296,
    '1386_810': 32.2918,
    '1386_811': 32.356,
    '1386_812': 32.4266,
    '1386_813': 32.5029,
    '1386_814': 32.5845,
    '1386_815': 32.6689,
    '1386_816': 32.755,
    '1386_817': 32.8397,
    '1386_818': 32.9353,
    '1386_819': 33.0388,
    '1386_820': 33.1302,
    '1386_821': 33.2007,
    '1386_822': 33.2581,
    '1386_823': 33.2964,
    '1386_824': 33.3235,
    '1386_825': 33.3342,
    '1386_826': 33.3156,
    '1386_827': 33.2616,
    '1386_828': 33.1962,
    '1386_829': 33.1455,
    '1386_830': 33.1364,
    '1386_831': 33.1335,
    '1386_832': 33.1174,
    '1386_833': 33.1156,
    '1386_834': 33.1438,
    '1386_835': 33.1684,
    '1386_836': 33.174,
    '1386_837': 33.1753,
    '1386_838': 33.1772,
    '1386_839': 33.1836,
    '1386_840': 33.1898,
    '1386_841': 33.1783,
    '1386_842': 33.1559,
    '1386_843': 33.1152,
    '1386_844': 33.0568,
    '1386_845': 32.9853,
    '1386_846': 32.9084,
    '1386_847': 32.8192,
    '1386_848': 32.6966,
    '1386_849': 32.5573,
    '1386_850': 32.402,
    '1386_851': 32.2558,
    '1386_852': 32.1246,
    '1386_853': 32.0001,
    '1386_854': 31.8799,
    '1386_855': 31.7644,
    '1386_856': 31.654,
    '1386_857': 31.5519,
    '1386_858': 31.4614,
    '1386_859': 31.3834,
    '1386_860': 31.3145,
    '1386_861': 31.2508,
    '1386_862': 31.1875,
    '1386_863': 31.1271,
    '1386_864': 31.0716,
    '1386_865': 31.0205,
    '1386_866': 30.9735,
    '1386_867': 30.9312,
    '1386_868': 30.8946,
    '1386_869': 30.8661,
    '1386_870': 30.8481,
    '1386_871': 30.8383,
    '1386_872': 30.8298,
    '1386_873': 30.8311,
    '1386_874': 30.8504,
    '1386_875': 30.8779,
    '1386_876': 30.9098,
    '1386_877': 30.9472,
    '1386_878': 31.0004,
    '1386_879': 31.0537,
    '1386_880': 31.0967,
    '1386_881': 31.1353,
    '1386_882': 31.1674,
    '1386_883': 31.187,
    '1386_884': 31.2064,
    '1386_885': 31.2566,
    '1386_886': 31.3324,
    '1386_887': 31.4344,
    '1386_888': 31.563,
    '1386_889': 31.6753,
    '1386_890': 31.7635,
    '1386_891': 31.8297,
    '1386_892': 31.8618,
    '1386_893': 31.8868,
    '1386_894': 31.9219,
    '1386_895': 31.9573,
    '1386_896': 31.9943,
    '1386_897': 32.0509,
    '1386_898': 32.1172,
    '1386_899': 32.1845,
    '1386_900': 32.242,
    '1386_901': 32.282,
    '1386_902': 32.3066,
    '1386_903': 32.321,
    '1386_904': 32.3179,
    '1386_905': 32.288,
    '1386_906': 32.2279,
    '1386_907': 32.1502,
    '1386_908': 32.0685,
    '1386_909': 31.9743,
    '1386_910': 31.8421,
    '1386_911': 31.6709,
    '1386_912': 31.5039,
    '1386_913': 31.3427,
    '1386_914': 31.1867,
    '1386_915': 31.0398,
    '1386_916': 30.898,
    '1386_917': 30.7671,
    '1386_918': 30.6431,
    '1386_919': 30.5187,
    '1386_920': 30.3986,
    '1386_921': 30.281,
    '1386_922': 30.1645,
    '1386_923': 30.0496,
    '1386_924': 29.9372,
    '1386_925': 29.8264,
    '1386_926': 29.7158,
    '1386_927': 29.6092,
    '1386_928': 29.5118,
    '1386_929': 29.4285,
    '1386_930': 29.3622,
    '1386_931': 29.314,
    '1386_932': 29.2864,
    '1386_933': 29.2785,
    '1386_934': 29.2756,
    '1386_935': 29.2866,
    '1386_936': 29.3113,
    '1386_937': 29.3468,
    '1386_938': 29.3862,
    '1386_939': 29.4329,
    '1386_940': 29.4879,
    '1386_941': 29.5511,
    '1386_942': 29.62,
    '1386_943': 29.6883,
    '1386_944': 29.7606,
    '1386_945': 29.8444,
    '1386_946': 29.939,
    '1386_947': 30.0381,
    '1386_948': 30.1284,
    '1386_949': 30.2064,
    '1386_950': 30.2671,
    '1386_951': 30.3003,
    '1386_952': 30.3097,
    '1386_953': 30.3071,
    '1386_954': 30.3065,
    '1386_955': 30.3166,
    '1386_956': 30.3341,
    '1386_957': 30.3588,
    '1386_958': 30.3731,
    '1386_959': 30.3801,
    '1386_960': 30.3896,
    '1386_961': 30.3871,
    '1386_962': 30.3762,
    '1386_963': 30.3626,
    '1386_964': 30.3357,
    '1386_965': 30.3071,
    '1386_966': 30.2941,
    '1386_967': 30.2948,
    '1386_968': 30.3083,
    '1386_969': 30.3289,
    '1386_970': 30.3576,
    '1386_971': 30.3964,
    '1386_972': 30.4314,
    '1386_973': 30.4633,
    '1386_974': 30.4983,
    '1386_975': 30.5417,
    '1386_976': 30.5894,
    '1386_977': 30.6395,
    '1386_978': 30.6915,
    '1386_979': 30.7443,
    '1386_980': 30.7904,
    '1386_981': 30.8312,
    '1386_982': 30.8649,
    '1386_983': 30.888,
    '1386_984': 30.9052,
    '1386_985': 30.9197,
    '1386_986': 30.9318,
    '1386_987': 30.941,
    '1386_988': 30.9471,
    '1386_989': 30.9508,
    '1386_990': 30.9496,
    '1386_991': 30.9419,
    '1386_992': 30.9259,
    '1386_993': 30.9005,
    '1386_994': 30.866,
    '1386_995': 30.8293,
    '1386_996': 30.7903,
    '1386_997': 30.7473,
    '1386_998': 30.6968,
    '1386_999': 30.6446,
    '1386_1000': 30.5908,
    '1386_1001': 30.5313,
    '1386_1002': 30.4601,
    '1386_1003': 30.3785,
    '1386_1004': 30.2917,
    '1386_1005': 30.2,
    '1386_1006': 30.1035,
    '1386_1007': 30.0015,
    '1386_1008': 29.896,
    '1386_1009': 29.7848,
    '1386_1010': 29.674,
    '1386_1011': 29.5594,
    '1386_1012': 29.44,
    '1386_1013': 29.3156,
    '1386_1014': 29.1862,
    '1386_1015': 29.0508,
    '1386_1016': 28.9088,
    '1386_1017': 28.7597,
    '1386_1018': 28.604,
    '1386_1019': 28.4417,
    '1386_1020': 28.2725,
    '1386_1021': 28.0968,
    '1386_1022': 27.9152,
    '1386_1023': 27.7275,
    '1386_1024': 27.5343,
    '1386_1025': 27.3358,
    '1386_1026': 27.1332,
    '1386_1027': 26.9241,
    '1386_1028': 26.7058,
    '1386_1029': 26.4777,
    '1386_1030': 26.2415,
    '1386_1031': 25.9962,
    '1386_1032': 25.7495,
    '1386_1033': 25.5058,
    '1387_806': 31.981,
    '1387_807': 32.0756,
    '1387_808': 32.1635,
    '1387_809': 32.2299,
    '1387_810': 32.2951,
    '1387_811': 32.3638,
    '1387_812': 32.4373,
    '1387_813': 32.5154,
    '1387_814': 32.6002,
    '1387_815': 32.6843,
    '1387_816': 32.7686,
    '1387_817': 32.8534,
    '1387_818': 32.9537,
    '1387_819': 33.0572,
    '1387_820': 33.1478,
    '1387_821': 33.2144,
    '1387_822': 33.2595,
    '1387_823': 33.2842,
    '1387_824': 33.2962,
    '1387_825': 33.3011,
    '1387_826': 33.2808,
    '1387_827': 33.2275,
    '1387_828': 33.1624,
    '1387_829': 33.1062,
    '1387_830': 33.0843,
    '1387_831': 33.0784,
    '1387_832': 33.0672,
    '1387_833': 33.0674,
    '1387_834': 33.0919,
    '1387_835': 33.1204,
    '1387_836': 33.1312,
    '1387_837': 33.1316,
    '1387_838': 33.1265,
    '1387_839': 33.121,
    '1387_840': 33.1119,
    '1387_841': 33.0882,
    '1387_842': 33.0568,
    '1387_843': 33.0201,
    '1387_844': 32.9615,
    '1387_845': 32.884,
    '1387_846': 32.7932,
    '1387_847': 32.7015,
    '1387_848': 32.5859,
    '1387_849': 32.4568,
    '1387_850': 32.3212,
    '1387_851': 32.194,
    '1387_852': 32.075,
    '1387_853': 31.9609,
    '1387_854': 31.8505,
    '1387_855': 31.7439,
    '1387_856': 31.6416,
    '1387_857': 31.5476,
    '1387_858': 31.4638,
    '1387_859': 31.3898,
    '1387_860': 31.3238,
    '1387_861': 31.2635,
    '1387_862': 31.2049,
    '1387_863': 31.1489,
    '1387_864': 31.0966,
    '1387_865': 31.0484,
    '1387_866': 31.0039,
    '1387_867': 30.9638,
    '1387_868': 30.9292,
    '1387_869': 30.903,
    '1387_870': 30.888,
    '1387_871': 30.8819,
    '1387_872': 30.8784,
    '1387_873': 30.8855,
    '1387_874': 30.9094,
    '1387_875': 30.9385,
    '1387_876': 30.9687,
    '1387_877': 31.0031,
    '1387_878': 31.0504,
    '1387_879': 31.1017,
    '1387_880': 31.1471,
    '1387_881': 31.1928,
    '1387_882': 31.2318,
    '1387_883': 31.2567,
    '1387_884': 31.2824,
    '1387_885': 31.3359,
    '1387_886': 31.4111,
    '1387_887': 31.5083,
    '1387_888': 31.6302,
    '1387_889': 31.7476,
    '1387_890': 31.8385,
    '1387_891': 31.899,
    '1387_892': 31.9253,
    '1387_893': 31.9411,
    '1387_894': 31.9647,
    '1387_895': 31.9921,
    '1387_896': 32.024,
    '1387_897': 32.0744,
    '1387_898': 32.1367,
    '1387_899': 32.2018,
    '1387_900': 32.2583,
    '1387_901': 32.2976,
    '1387_902': 32.3133,
    '1387_903': 32.3142,
    '1387_904': 32.3043,
    '1387_905': 32.2727,
    '1387_906': 32.2169,
    '1387_907': 32.1446,
    '1387_908': 32.0671,
    '1387_909': 31.9769,
    '1387_910': 31.8537,
    '1387_911': 31.6954,
    '1387_912': 31.5291,
    '1387_913': 31.3743,
    '1387_914': 31.2263,
    '1387_915': 31.088,
    '1387_916': 30.9545,
    '1387_917': 30.831,
    '1387_918': 30.7128,
    '1387_919': 30.595,
    '1387_920': 30.4795,
    '1387_921': 30.3664,
    '1387_922': 30.2541,
    '1387_923': 30.1419,
    '1387_924': 30.0313,
    '1387_925': 29.9235,
    '1387_926': 29.8139,
    '1387_927': 29.7077,
    '1387_928': 29.6096,
    '1387_929': 29.5251,
    '1387_930': 29.456,
    '1387_931': 29.4042,
    '1387_932': 29.372,
    '1387_933': 29.3593,
    '1387_934': 29.3537,
    '1387_935': 29.3615,
    '1387_936': 29.3904,
    '1387_937': 29.4259,
    '1387_938': 29.4662,
    '1387_939': 29.5163,
    '1387_940': 29.5738,
    '1387_941': 29.6383,
    '1387_942': 29.7047,
    '1387_943': 29.7721,
    '1387_944': 29.8474,
    '1387_945': 29.9382,
    '1387_946': 30.0343,
    '1387_947': 30.1365,
    '1387_948': 30.234,
    '1387_949': 30.3187,
    '1387_950': 30.385,
    '1387_951': 30.4258,
    '1387_952': 30.4433,
    '1387_953': 30.4439,
    '1387_954': 30.4421,
    '1387_955': 30.4552,
    '1387_956': 30.4808,
    '1387_957': 30.5146,
    '1387_958': 30.5321,
    '1387_959': 30.5372,
    '1387_960': 30.5387,
    '1387_961': 30.5272,
    '1387_962': 30.5111,
    '1387_963': 30.4873,
    '1387_964': 30.4491,
    '1387_965': 30.4149,
    '1387_966': 30.4031,
    '1387_967': 30.4068,
    '1387_968': 30.4132,
    '1387_969': 30.424,
    '1387_970': 30.4478,
    '1387_971': 30.4785,
    '1387_972': 30.5085,
    '1387_973': 30.5372,
    '1387_974': 30.5728,
    '1387_975': 30.6154,
    '1387_976': 30.6624,
    '1387_977': 30.7108,
    '1387_978': 30.7605,
    '1387_979': 30.8129,
    '1387_980': 30.8644,
    '1387_981': 30.9078,
    '1387_982': 30.945,
    '1387_983': 30.9704,
    '1387_984': 30.9895,
    '1387_985': 31.007,
    '1387_986': 31.0231,
    '1387_987': 31.0379,
    '1387_988': 31.0496,
    '1387_989': 31.0582,
    '1387_990': 31.0614,
    '1387_991': 31.0583,
    '1387_992': 31.0473,
    '1387_993': 31.0289,
    '1387_994': 31.0045,
    '1387_995': 30.9757,
    '1387_996': 30.9445,
    '1387_997': 30.9066,
    '1387_998': 30.8638,
    '1387_999': 30.8201,
    '1387_1000': 30.7744,
    '1387_1001': 30.7254,
    '1387_1002': 30.6644,
    '1387_1003': 30.5925,
    '1387_1004': 30.5134,
    '1387_1005': 30.4282,
    '1387_1006': 30.3393,
    '1387_1007': 30.2447,
    '1387_1008': 30.1452,
    '1387_1009': 30.0405,
    '1387_1010': 29.933,
    '1387_1011': 29.8217,
    '1387_1012': 29.7054,
    '1387_1013': 29.584,
    '1387_1014': 29.4573,
    '1387_1015': 29.3247,
    '1387_1016': 29.1861,
    '1387_1017': 29.0406,
    '1387_1018': 28.8882,
    '1387_1019': 28.7291,
    '1387_1020': 28.5634,
    '1387_1021': 28.3911,
    '1387_1022': 28.2143,
    '1387_1023': 28.0311,
    '1387_1024': 27.8425,
    '1387_1025': 27.6476,
    '1387_1026': 27.4473,
    '1387_1027': 27.2398,
    '1387_1028': 27.024,
    '1387_1029': 26.7996,
    '1387_1030': 26.5689,
    '1387_1031': 26.3334,
    '1387_1032': 26.0999,
    '1387_1033': 25.8662,
    '1388_806': 31.9413,
    '1388_807': 32.0393,
    '1388_808': 32.1298,
    '1388_809': 32.2091,
    '1388_810': 32.2874,
    '1388_811': 32.3672,
    '1388_812': 32.4488,
    '1388_813': 32.5325,
    '1388_814': 32.6181,
    '1388_815': 32.7018,
    '1388_816': 32.7817,
    '1388_817': 32.8695,
    '1388_818': 32.9704,
    '1388_819': 33.0688,
    '1388_820': 33.1521,
    '1388_821': 33.2085,
    '1388_822': 33.2436,
    '1388_823': 33.2577,
    '1388_824': 33.2621,
    '1388_825': 33.2642,
    '1388_826': 33.2408,
    '1388_827': 33.189,
    '1388_828': 33.126,
    '1388_829': 33.064,
    '1388_830': 33.0214,
    '1388_831': 33.0043,
    '1388_832': 32.9947,
    '1388_833': 33.0001,
    '1388_834': 33.0272,
    '1388_835': 33.0568,
    '1388_836': 33.0767,
    '1388_837': 33.0796,
    '1388_838': 33.0658,
    '1388_839': 33.0457,
    '1388_840': 33.0228,
    '1388_841': 32.9858,
    '1388_842': 32.9435,
    '1388_843': 32.9066,
    '1388_844': 32.8436,
    '1388_845': 32.7611,
    '1388_846': 32.6645,
    '1388_847': 32.5726,
    '1388_848': 32.4717,
    '1388_849': 32.3609,
    '1388_850': 32.2467,
    '1388_851': 32.1357,
    '1388_852': 32.0293,
    '1388_853': 31.9265,
    '1388_854': 31.8268,
    '1388_855': 31.7296,
    '1388_856': 31.6362,
    '1388_857': 31.5497,
    '1388_858': 31.471,
    '1388_859': 31.4001,
    '1388_860': 31.337,
    '1388_861': 31.2801,
    '1388_862': 31.2263,
    '1388_863': 31.1749,
    '1388_864': 31.1258,
    '1388_865': 31.0797,
    '1388_866': 31.0368,
    '1388_867': 30.9984,
    '1388_868': 30.9659,
    '1388_869': 30.9418,
    '1388_870': 30.9284,
    '1388_871': 30.9245,
    '1388_872': 30.9245,
    '1388_873': 30.9338,
    '1388_874': 30.9586,
    '1388_875': 30.9866,
    '1388_876': 31.0121,
    '1388_877': 31.0458,
    '1388_878': 31.0888,
    '1388_879': 31.139,
    '1388_880': 31.1903,
    '1388_881': 31.2456,
    '1388_882': 31.2892,
    '1388_883': 31.319,
    '1388_884': 31.351,
    '1388_885': 31.4081,
    '1388_886': 31.4829,
    '1388_887': 31.5728,
    '1388_888': 31.6852,
    '1388_889': 31.8045,
    '1388_890': 31.898,
    '1388_891': 31.9564,
    '1388_892': 31.9835,
    '1388_893': 31.9919,
    '1388_894': 32.0026,
    '1388_895': 32.0231,
    '1388_896': 32.0493,
    '1388_897': 32.0896,
    '1388_898': 32.1465,
    '1388_899': 32.2044,
    '1388_900': 32.2586,
    '1388_901': 32.2952,
    '1388_902': 32.3077,
    '1388_903': 32.299,
    '1388_904': 32.2844,
    '1388_905': 32.2551,
    '1388_906': 32.2085,
    '1388_907': 32.1462,
    '1388_908': 32.0742,
    '1388_909': 31.99,
    '1388_910': 31.8788,
    '1388_911': 31.7351,
    '1388_912': 31.5738,
    '1388_913': 31.4211,
    '1388_914': 31.278,
    '1388_915': 31.1442,
    '1388_916': 31.0181,
    '1388_917': 30.9027,
    '1388_918': 30.7924,
    '1388_919': 30.681,
    '1388_920': 30.5685,
    '1388_921': 30.4603,
    '1388_922': 30.3519,
    '1388_923': 30.241,
    '1388_924': 30.1325,
    '1388_925': 30.0272,
    '1388_926': 29.9196,
    '1388_927': 29.8132,
    '1388_928': 29.714,
    '1388_929': 29.627,
    '1388_930': 29.5559,
    '1388_931': 29.5008,
    '1388_932': 29.4645,
    '1388_933': 29.4477,
    '1388_934': 29.4397,
    '1388_935': 29.4449,
    '1388_936': 29.474,
    '1388_937': 29.5095,
    '1388_938': 29.5518,
    '1388_939': 29.6023,
    '1388_940': 29.6626,
    '1388_941': 29.7283,
    '1388_942': 29.7926,
    '1388_943': 29.8586,
    '1388_944': 29.9358,
    '1388_945': 30.0355,
    '1388_946': 30.1353,
    '1388_947': 30.2359,
    '1388_948': 30.3335,
    '1388_949': 30.4214,
    '1388_950': 30.4912,
    '1388_951': 30.54,
    '1388_952': 30.5639,
    '1388_953': 30.5705,
    '1388_954': 30.5711,
    '1388_955': 30.587,
    '1388_956': 30.6198,
    '1388_957': 30.6576,
    '1388_958': 30.6791,
    '1388_959': 30.6849,
    '1388_960': 30.6755,
    '1388_961': 30.6585,
    '1388_962': 30.6362,
    '1388_963': 30.6004,
    '1388_964': 30.5593,
    '1388_965': 30.5206,
    '1388_966': 30.5028,
    '1388_967': 30.5061,
    '1388_968': 30.5066,
    '1388_969': 30.5097,
    '1388_970': 30.5275,
    '1388_971': 30.5492,
    '1388_972': 30.5731,
    '1388_973': 30.6007,
    '1388_974': 30.6362,
    '1388_975': 30.6789,
    '1388_976': 30.7267,
    '1388_977': 30.7724,
    '1388_978': 30.8186,
    '1388_979': 30.8682,
    '1388_980': 30.9206,
    '1388_981': 30.9672,
    '1388_982': 31.0071,
    '1388_983': 31.0356,
    '1388_984': 31.0587,
    '1388_985': 31.0798,
    '1388_986': 31.0997,
    '1388_987': 31.1175,
    '1388_988': 31.1318,
    '1388_989': 31.1452,
    '1388_990': 31.1518,
    '1388_991': 31.152,
    '1388_992': 31.1459,
    '1388_993': 31.1362,
    '1388_994': 31.1207,
    '1388_995': 31.1016,
    '1388_996': 31.0761,
    '1388_997': 31.0447,
    '1388_998': 31.0142,
    '1388_999': 30.9818,
    '1388_1000': 30.9435,
    '1388_1001': 30.902,
    '1388_1002': 30.8528,
    '1388_1003': 30.7922,
    '1388_1004': 30.7222,
    '1388_1005': 30.6441,
    '1388_1006': 30.5597,
    '1388_1007': 30.4739,
    '1388_1008': 30.3834,
    '1388_1009': 30.2849,
    '1388_1010': 30.1821,
    '1388_1011': 30.0739,
    '1388_1012': 29.9609,
    '1388_1013': 29.8429,
    '1388_1014': 29.7195,
    '1388_1015': 29.5905,
    '1388_1016': 29.4557,
    '1388_1017': 29.3142,
    '1388_1018': 29.1659,
    '1388_1019': 29.011,
    '1388_1020': 28.85,
    '1388_1021': 28.6832,
    '1388_1022': 28.5105,
    '1388_1023': 28.3306,
    '1388_1024': 28.146,
    '1388_1025': 27.9541,
    '1388_1026': 27.7557,
    '1388_1027': 27.5502,
    '1388_1028': 27.3374,
    '1388_1029': 27.1174,
    '1388_1030': 26.8914,
    '1388_1031': 26.6637,
    '1388_1032': 26.4407,
    '1388_1033': 26.2148,
    '1389_806': 31.9098,
    '1389_807': 32.0021,
    '1389_808': 32.0922,
    '1389_809': 32.1808,
    '1389_810': 32.2739,
    '1389_811': 32.3675,
    '1389_812': 32.4592,
    '1389_813': 32.5497,
    '1389_814': 32.6384,
    '1389_815': 32.7227,
    '1389_816': 32.8114,
    '1389_817': 32.9014,
    '1389_818': 32.9839,
    '1389_819': 33.0657,
    '1389_820': 33.1371,
    '1389_821': 33.1878,
    '1389_822': 33.2114,
    '1389_823': 33.2142,
    '1389_824': 33.2171,
    '1389_825': 33.2231,
    '1389_826': 33.2034,
    '1389_827': 33.1498,
    '1389_828': 33.0801,
    '1389_829': 33.015,
    '1389_830': 32.9608,
    '1389_831': 32.9296,
    '1389_832': 32.9194,
    '1389_833': 32.9277,
    '1389_834': 32.9506,
    '1389_835': 32.9789,
    '1389_836': 33.0024,
    '1389_837': 33.0079,
    '1389_838': 32.9848,
    '1389_839': 32.9474,
    '1389_840': 32.9131,
    '1389_841': 32.8698,
    '1389_842': 32.8224,
    '1389_843': 32.7844,
    '1389_844': 32.7165,
    '1389_845': 32.6342,
    '1389_846': 32.5477,
    '1389_847': 32.4643,
    '1389_848': 32.3757,
    '1389_849': 32.279,
    '1389_850': 32.1787,
    '1389_851': 32.0809,
    '1389_852': 31.9864,
    '1389_853': 31.8951,
    '1389_854': 31.8063,
    '1389_855': 31.7189,
    '1389_856': 31.6346,
    '1389_857': 31.5555,
    '1389_858': 31.4808,
    '1389_859': 31.4127,
    '1389_860': 31.3531,
    '1389_861': 31.3008,
    '1389_862': 31.252,
    '1389_863': 31.2046,
    '1389_864': 31.1584,
    '1389_865': 31.114,
    '1389_866': 31.0722,
    '1389_867': 31.0349,
    '1389_868': 31.0041,
    '1389_869': 30.9814,
    '1389_870': 30.9683,
    '1389_871': 30.9643,
    '1389_872': 30.9657,
    '1389_873': 30.9764,
    '1389_874': 31,
    '1389_875': 31.0259,
    '1389_876': 31.0496,
    '1389_877': 31.083,
    '1389_878': 31.1246,
    '1389_879': 31.1757,
    '1389_880': 31.2318,
    '1389_881': 31.2887,
    '1389_882': 31.3341,
    '1389_883': 31.3702,
    '1389_884': 31.4114,
    '1389_885': 31.4731,
    '1389_886': 31.5491,
    '1389_887': 31.6329,
    '1389_888': 31.7357,
    '1389_889': 31.8512,
    '1389_890': 31.9467,
    '1389_891': 32.0119,
    '1389_892': 32.0361,
    '1389_893': 32.0371,
    '1389_894': 32.0423,
    '1389_895': 32.0577,
    '1389_896': 32.0761,
    '1389_897': 32.1112,
    '1389_898': 32.1649,
    '1389_899': 32.2133,
    '1389_900': 32.252,
    '1389_901': 32.2852,
    '1389_902': 32.2972,
    '1389_903': 32.2879,
    '1389_904': 32.2748,
    '1389_905': 32.2555,
    '1389_906': 32.2167,
    '1389_907': 32.1597,
    '1389_908': 32.0918,
    '1389_909': 32.0139,
    '1389_910': 31.9152,
    '1389_911': 31.7861,
    '1389_912': 31.6291,
    '1389_913': 31.4755,
    '1389_914': 31.3367,
    '1389_915': 31.2104,
    '1389_916': 31.092,
    '1389_917': 30.9837,
    '1389_918': 30.8794,
    '1389_919': 30.7735,
    '1389_920': 30.6645,
    '1389_921': 30.5587,
    '1389_922': 30.454,
    '1389_923': 30.347,
    '1389_924': 30.2405,
    '1389_925': 30.1371,
    '1389_926': 30.0313,
    '1389_927': 29.9241,
    '1389_928': 29.8233,
    '1389_929': 29.7343,
    '1389_930': 29.6607,
    '1389_931': 29.603,
    '1389_932': 29.5629,
    '1389_933': 29.5408,
    '1389_934': 29.5308,
    '1389_935': 29.5365,
    '1389_936': 29.5642,
    '1389_937': 29.5986,
    '1389_938': 29.6434,
    '1389_939': 29.6951,
    '1389_940': 29.75,
    '1389_941': 29.8114,
    '1389_942': 29.8756,
    '1389_943': 29.9433,
    '1389_944': 30.0203,
    '1389_945': 30.1214,
    '1389_946': 30.2265,
    '1389_947': 30.3256,
    '1389_948': 30.4259,
    '1389_949': 30.5155,
    '1389_950': 30.5896,
    '1389_951': 30.6437,
    '1389_952': 30.6745,
    '1389_953': 30.6865,
    '1389_954': 30.6957,
    '1389_955': 30.714,
    '1389_956': 30.7461,
    '1389_957': 30.7887,
    '1389_958': 30.8136,
    '1389_959': 30.8205,
    '1389_960': 30.8069,
    '1389_961': 30.7815,
    '1389_962': 30.7488,
    '1389_963': 30.7028,
    '1389_964': 30.6597,
    '1389_965': 30.6212,
    '1389_966': 30.5967,
    '1389_967': 30.5923,
    '1389_968': 30.5851,
    '1389_969': 30.5838,
    '1389_970': 30.5955,
    '1389_971': 30.6129,
    '1389_972': 30.633,
    '1389_973': 30.6571,
    '1389_974': 30.6922,
    '1389_975': 30.7337,
    '1389_976': 30.7807,
    '1389_977': 30.8248,
    '1389_978': 30.8666,
    '1389_979': 30.9133,
    '1389_980': 30.9642,
    '1389_981': 31.0129,
    '1389_982': 31.0527,
    '1389_983': 31.0849,
    '1389_984': 31.1113,
    '1389_985': 31.1367,
    '1389_986': 31.161,
    '1389_987': 31.1804,
    '1389_988': 31.195,
    '1389_989': 31.2084,
    '1389_990': 31.216,
    '1389_991': 31.2185,
    '1389_992': 31.2183,
    '1389_993': 31.2155,
    '1389_994': 31.2102,
    '1389_995': 31.2031,
    '1389_996': 31.1874,
    '1389_997': 31.1671,
    '1389_998': 31.1464,
    '1389_999': 31.1231,
    '1389_1000': 31.0933,
    '1389_1001': 31.0584,
    '1389_1002': 31.0187,
    '1389_1003': 30.9694,
    '1389_1004': 30.9101,
    '1389_1005': 30.8401,
    '1389_1006': 30.7641,
    '1389_1007': 30.6895,
    '1389_1008': 30.6083,
    '1389_1009': 30.5163,
    '1389_1010': 30.4156,
    '1389_1011': 30.312,
    '1389_1012': 30.2029,
    '1389_1013': 30.089,
    '1389_1014': 29.9699,
    '1389_1015': 29.8452,
    '1389_1016': 29.715,
    '1389_1017': 29.578,
    '1389_1018': 29.434,
    '1389_1019': 29.2823,
    '1389_1020': 29.1269,
    '1389_1021': 28.968,
    '1389_1022': 28.8013,
    '1389_1023': 28.6273,
    '1389_1024': 28.4445,
    '1389_1025': 28.2536,
    '1389_1026': 28.0565,
    '1389_1027': 27.8531,
    '1389_1028': 27.6437,
    '1389_1029': 27.4282,
    '1389_1030': 27.205,
    '1389_1031': 26.9811,
    '1389_1032': 26.7665,
    '1389_1033': 26.5503,
    '1390_806': 31.8805,
    '1390_807': 31.9647,
    '1390_808': 32.057,
    '1390_809': 32.1571,
    '1390_810': 32.2613,
    '1390_811': 32.368,
    '1390_812': 32.4689,
    '1390_813': 32.5655,
    '1390_814': 32.6585,
    '1390_815': 32.7611,
    '1390_816': 32.8667,
    '1390_817': 32.9496,
    '1390_818': 33.0034,
    '1390_819': 33.0631,
    '1390_820': 33.1195,
    '1390_821': 33.1601,
    '1390_822': 33.1745,
    '1390_823': 33.1677,
    '1390_824': 33.1664,
    '1390_825': 33.1743,
    '1390_826': 33.1616,
    '1390_827': 33.1124,
    '1390_828': 33.0387,
    '1390_829': 32.9653,
    '1390_830': 32.9039,
    '1390_831': 32.8624,
    '1390_832': 32.8436,
    '1390_833': 32.8462,
    '1390_834': 32.8643,
    '1390_835': 32.8947,
    '1390_836': 32.9199,
    '1390_837': 32.9218,
    '1390_838': 32.8934,
    '1390_839': 32.8495,
    '1390_840': 32.7979,
    '1390_841': 32.7509,
    '1390_842': 32.6979,
    '1390_843': 32.6558,
    '1390_844': 32.5976,
    '1390_845': 32.5279,
    '1390_846': 32.4571,
    '1390_847': 32.382,
    '1390_848': 32.3001,
    '1390_849': 32.2111,
    '1390_850': 32.1189,
    '1390_851': 32.0307,
    '1390_852': 31.9464,
    '1390_853': 31.8654,
    '1390_854': 31.7865,
    '1390_855': 31.7094,
    '1390_856': 31.6347,
    '1390_857': 31.5628,
    '1390_858': 31.4932,
    '1390_859': 31.4293,
    '1390_860': 31.3742,
    '1390_861': 31.3268,
    '1390_862': 31.283,
    '1390_863': 31.2392,
    '1390_864': 31.1952,
    '1390_865': 31.1517,
    '1390_866': 31.1103,
    '1390_867': 31.073,
    '1390_868': 31.0426,
    '1390_869': 31.0202,
    '1390_870': 31.0066,
    '1390_871': 31.0031,
    '1390_872': 31.0079,
    '1390_873': 31.0174,
    '1390_874': 31.0406,
    '1390_875': 31.0687,
    '1390_876': 31.0928,
    '1390_877': 31.127,
    '1390_878': 31.172,
    '1390_879': 31.224,
    '1390_880': 31.2763,
    '1390_881': 31.3237,
    '1390_882': 31.3664,
    '1390_883': 31.41,
    '1390_884': 31.4645,
    '1390_885': 31.5336,
    '1390_886': 31.6117,
    '1390_887': 31.6916,
    '1390_888': 31.7868,
    '1390_889': 31.8972,
    '1390_890': 31.9959,
    '1390_891': 32.0611,
    '1390_892': 32.0838,
    '1390_893': 32.0765,
    '1390_894': 32.0746,
    '1390_895': 32.0887,
    '1390_896': 32.1036,
    '1390_897': 32.1298,
    '1390_898': 32.1807,
    '1390_899': 32.2255,
    '1390_900': 32.2582,
    '1390_901': 32.2816,
    '1390_902': 32.2929,
    '1390_903': 32.2875,
    '1390_904': 32.2786,
    '1390_905': 32.2624,
    '1390_906': 32.227,
    '1390_907': 32.1736,
    '1390_908': 32.1125,
    '1390_909': 32.0417,
    '1390_910': 31.9537,
    '1390_911': 31.8373,
    '1390_912': 31.6894,
    '1390_913': 31.5383,
    '1390_914': 31.4044,
    '1390_915': 31.2874,
    '1390_916': 31.1776,
    '1390_917': 31.0701,
    '1390_918': 30.9702,
    '1390_919': 30.8713,
    '1390_920': 30.7678,
    '1390_921': 30.6651,
    '1390_922': 30.5616,
    '1390_923': 30.4594,
    '1390_924': 30.3553,
    '1390_925': 30.2527,
    '1390_926': 30.1491,
    '1390_927': 30.0415,
    '1390_928': 29.9378,
    '1390_929': 29.8459,
    '1390_930': 29.7702,
    '1390_931': 29.7101,
    '1390_932': 29.6669,
    '1390_933': 29.6399,
    '1390_934': 29.6275,
    '1390_935': 29.6323,
    '1390_936': 29.6578,
    '1390_937': 29.6916,
    '1390_938': 29.7379,
    '1390_939': 29.7857,
    '1390_940': 29.835,
    '1390_941': 29.8947,
    '1390_942': 29.9585,
    '1390_943': 30.025,
    '1390_944': 30.1018,
    '1390_945': 30.2007,
    '1390_946': 30.3062,
    '1390_947': 30.4106,
    '1390_948': 30.5114,
    '1390_949': 30.6014,
    '1390_950': 30.6796,
    '1390_951': 30.7398,
    '1390_952': 30.7755,
    '1390_953': 30.7932,
    '1390_954': 30.8075,
    '1390_955': 30.8271,
    '1390_956': 30.8588,
    '1390_957': 30.901,
    '1390_958': 30.9296,
    '1390_959': 30.9395,
    '1390_960': 30.9342,
    '1390_961': 30.906,
    '1390_962': 30.8608,
    '1390_963': 30.8077,
    '1390_964': 30.7591,
    '1390_965': 30.72,
    '1390_966': 30.6868,
    '1390_967': 30.6713,
    '1390_968': 30.6572,
    '1390_969': 30.6503,
    '1390_970': 30.6579,
    '1390_971': 30.6667,
    '1390_972': 30.6826,
    '1390_973': 30.7052,
    '1390_974': 30.7392,
    '1390_975': 30.7762,
    '1390_976': 30.8188,
    '1390_977': 30.8636,
    '1390_978': 30.904,
    '1390_979': 30.9496,
    '1390_980': 30.9988,
    '1390_981': 31.0446,
    '1390_982': 31.0827,
    '1390_983': 31.1154,
    '1390_984': 31.1459,
    '1390_985': 31.1749,
    '1390_986': 31.2031,
    '1390_987': 31.2248,
    '1390_988': 31.2393,
    '1390_989': 31.2505,
    '1390_990': 31.2556,
    '1390_991': 31.2608,
    '1390_992': 31.2709,
    '1390_993': 31.2809,
    '1390_994': 31.2885,
    '1390_995': 31.2899,
    '1390_996': 31.2847,
    '1390_997': 31.2739,
    '1390_998': 31.2612,
    '1390_999': 31.2445,
    '1390_1000': 31.2213,
    '1390_1001': 31.1922,
    '1390_1002': 31.1594,
    '1390_1003': 31.1191,
    '1390_1004': 31.0701,
    '1390_1005': 31.0117,
    '1390_1006': 30.9485,
    '1390_1007': 30.8824,
    '1390_1008': 30.8095,
    '1390_1009': 30.7254,
    '1390_1010': 30.6321,
    '1390_1011': 30.5347,
    '1390_1012': 30.4307,
    '1390_1013': 30.3222,
    '1390_1014': 30.2075,
    '1390_1015': 30.0886,
    '1390_1016': 29.9634,
    '1390_1017': 29.8314,
    '1390_1018': 29.6925,
    '1390_1019': 29.5461,
    '1390_1020': 29.3964,
    '1390_1021': 29.2416,
    '1390_1022': 29.0818,
    '1390_1023': 28.9148,
    '1390_1024': 28.7336,
    '1390_1025': 28.5442,
    '1390_1026': 28.3488,
    '1390_1027': 28.1473,
    '1390_1028': 27.9405,
    '1390_1029': 27.7288,
    '1390_1030': 27.5133,
    '1390_1031': 27.2982,
    '1390_1032': 27.0878,
    '1390_1033': 26.8776,
    '1391_806': 31.8424,
    '1391_807': 31.9236,
    '1391_808': 32.0243,
    '1391_809': 32.1386,
    '1391_810': 32.2521,
    '1391_811': 32.3723,
    '1391_812': 32.4784,
    '1391_813': 32.5761,
    '1391_814': 32.6859,
    '1391_815': 32.8081,
    '1391_816': 32.9167,
    '1391_817': 32.988,
    '1391_818': 33.0197,
    '1391_819': 33.0641,
    '1391_820': 33.1026,
    '1391_821': 33.1341,
    '1391_822': 33.1401,
    '1391_823': 33.1245,
    '1391_824': 33.1142,
    '1391_825': 33.1163,
    '1391_826': 33.1045,
    '1391_827': 33.0632,
    '1391_828': 33.0006,
    '1391_829': 32.9238,
    '1391_830': 32.8533,
    '1391_831': 32.8021,
    '1391_832': 32.776,
    '1391_833': 32.77,
    '1391_834': 32.7824,
    '1391_835': 32.8041,
    '1391_836': 32.8207,
    '1391_837': 32.8194,
    '1391_838': 32.7931,
    '1391_839': 32.753,
    '1391_840': 32.6989,
    '1391_841': 32.6454,
    '1391_842': 32.5936,
    '1391_843': 32.5518,
    '1391_844': 32.5056,
    '1391_845': 32.4507,
    '1391_846': 32.3903,
    '1391_847': 32.3207,
    '1391_848': 32.2418,
    '1391_849': 32.1576,
    '1391_850': 32.0711,
    '1391_851': 31.9874,
    '1391_852': 31.91,
    '1391_853': 31.8371,
    '1391_854': 31.768,
    '1391_855': 31.7015,
    '1391_856': 31.6362,
    '1391_857': 31.5722,
    '1391_858': 31.5098,
    '1391_859': 31.4517,
    '1391_860': 31.4014,
    '1391_861': 31.3591,
    '1391_862': 31.32,
    '1391_863': 31.2796,
    '1391_864': 31.2374,
    '1391_865': 31.1944,
    '1391_866': 31.1524,
    '1391_867': 31.1145,
    '1391_868': 31.0826,
    '1391_869': 31.0592,
    '1391_870': 31.0445,
    '1391_871': 31.0402,
    '1391_872': 31.0448,
    '1391_873': 31.0553,
    '1391_874': 31.0798,
    '1391_875': 31.1099,
    '1391_876': 31.1387,
    '1391_877': 31.1801,
    '1391_878': 31.2249,
    '1391_879': 31.2718,
    '1391_880': 31.3176,
    '1391_881': 31.3548,
    '1391_882': 31.3968,
    '1391_883': 31.4439,
    '1391_884': 31.5089,
    '1391_885': 31.5883,
    '1391_886': 31.6707,
    '1391_887': 31.7484,
    '1391_888': 31.8378,
    '1391_889': 31.9442,
    '1391_890': 32.0455,
    '1391_891': 32.1102,
    '1391_892': 32.1312,
    '1391_893': 32.1142,
    '1391_894': 32.0979,
    '1391_895': 32.1082,
    '1391_896': 32.1235,
    '1391_897': 32.1419,
    '1391_898': 32.1875,
    '1391_899': 32.232,
    '1391_900': 32.2714,
    '1391_901': 32.3001,
    '1391_902': 32.3106,
    '1391_903': 32.3057,
    '1391_904': 32.2963,
    '1391_905': 32.2786,
    '1391_906': 32.244,
    '1391_907': 32.195,
    '1391_908': 32.1385,
    '1391_909': 32.0715,
    '1391_910': 31.9887,
    '1391_911': 31.8783,
    '1391_912': 31.7403,
    '1391_913': 31.5993,
    '1391_914': 31.4768,
    '1391_915': 31.3683,
    '1391_916': 31.2645,
    '1391_917': 31.161,
    '1391_918': 31.0644,
    '1391_919': 30.9759,
    '1391_920': 30.8792,
    '1391_921': 30.7791,
    '1391_922': 30.6784,
    '1391_923': 30.5788,
    '1391_924': 30.4778,
    '1391_925': 30.3762,
    '1391_926': 30.2734,
    '1391_927': 30.1651,
    '1391_928': 30.059,
    '1391_929': 29.9643,
    '1391_930': 29.8861,
    '1391_931': 29.8239,
    '1391_932': 29.7769,
    '1391_933': 29.745,
    '1391_934': 29.729,
    '1391_935': 29.7322,
    '1391_936': 29.7574,
    '1391_937': 29.7899,
    '1391_938': 29.8332,
    '1391_939': 29.8768,
    '1391_940': 29.9234,
    '1391_941': 29.9813,
    '1391_942': 30.0425,
    '1391_943': 30.1026,
    '1391_944': 30.1785,
    '1391_945': 30.2733,
    '1391_946': 30.3759,
    '1391_947': 30.485,
    '1391_948': 30.5903,
    '1391_949': 30.6821,
    '1391_950': 30.761,
    '1391_951': 30.8285,
    '1391_952': 30.8726,
    '1391_953': 30.893,
    '1391_954': 30.9083,
    '1391_955': 30.9312,
    '1391_956': 30.965,
    '1391_957': 31.0026,
    '1391_958': 31.0321,
    '1391_959': 31.0479,
    '1391_960': 31.0535,
    '1391_961': 31.0297,
    '1391_962': 30.9758,
    '1391_963': 30.9124,
    '1391_964': 30.8586,
    '1391_965': 30.8145,
    '1391_966': 30.7695,
    '1391_967': 30.7459,
    '1391_968': 30.7263,
    '1391_969': 30.716,
    '1391_970': 30.715,
    '1391_971': 30.7167,
    '1391_972': 30.7293,
    '1391_973': 30.7478,
    '1391_974': 30.7831,
    '1391_975': 30.8139,
    '1391_976': 30.8521,
    '1391_977': 30.8922,
    '1391_978': 30.9311,
    '1391_979': 30.9736,
    '1391_980': 31.0181,
    '1391_981': 31.0607,
    '1391_982': 31.0956,
    '1391_983': 31.1292,
    '1391_984': 31.1639,
    '1391_985': 31.1966,
    '1391_986': 31.2258,
    '1391_987': 31.2499,
    '1391_988': 31.2639,
    '1391_989': 31.2737,
    '1391_990': 31.2811,
    '1391_991': 31.2922,
    '1391_992': 31.3124,
    '1391_993': 31.3338,
    '1391_994': 31.3532,
    '1391_995': 31.3629,
    '1391_996': 31.3665,
    '1391_997': 31.3636,
    '1391_998': 31.357,
    '1391_999': 31.344,
    '1391_1000': 31.3255,
    '1391_1001': 31.3028,
    '1391_1002': 31.276,
    '1391_1003': 31.2419,
    '1391_1004': 31.2029,
    '1391_1005': 31.1563,
    '1391_1006': 31.1045,
    '1391_1007': 31.0477,
    '1391_1008': 30.983,
    '1391_1009': 30.9101,
    '1391_1010': 30.8286,
    '1391_1011': 30.7412,
    '1391_1012': 30.6449,
    '1391_1013': 30.5429,
    '1391_1014': 30.4347,
    '1391_1015': 30.3208,
    '1391_1016': 30.2002,
    '1391_1017': 30.073,
    '1391_1018': 29.94,
    '1391_1019': 29.7996,
    '1391_1020': 29.653,
    '1391_1021': 29.5015,
    '1391_1022': 29.344,
    '1391_1023': 29.1826,
    '1391_1024': 29.0082,
    '1391_1025': 28.8233,
    '1391_1026': 28.6313,
    '1391_1027': 28.4327,
    '1391_1028': 28.2292,
    '1391_1029': 28.0218,
    '1391_1030': 27.8132,
    '1391_1031': 27.6059,
    '1391_1032': 27.4011,
    '1391_1033': 27.1962,
    '1392_806': 31.8067,
    '1392_807': 31.9013,
    '1392_808': 32.0081,
    '1392_809': 32.1343,
    '1392_810': 32.2562,
    '1392_811': 32.3779,
    '1392_812': 32.4817,
    '1392_813': 32.5828,
    '1392_814': 32.7027,
    '1392_815': 32.8273,
    '1392_816': 32.9349,
    '1392_817': 33.0052,
    '1392_818': 33.0341,
    '1392_819': 33.0712,
    '1392_820': 33.0995,
    '1392_821': 33.1147,
    '1392_822': 33.1082,
    '1392_823': 33.0808,
    '1392_824': 33.0547,
    '1392_825': 33.0443,
    '1392_826': 33.0309,
    '1392_827': 32.9976,
    '1392_828': 32.9502,
    '1392_829': 32.8815,
    '1392_830': 32.8041,
    '1392_831': 32.7485,
    '1392_832': 32.7175,
    '1392_833': 32.7044,
    '1392_834': 32.7054,
    '1392_835': 32.7125,
    '1392_836': 32.7171,
    '1392_837': 32.7149,
    '1392_838': 32.6995,
    '1392_839': 32.667,
    '1392_840': 32.6171,
    '1392_841': 32.5683,
    '1392_842': 32.5236,
    '1392_843': 32.4835,
    '1392_844': 32.4436,
    '1392_845': 32.3941,
    '1392_846': 32.3363,
    '1392_847': 32.2701,
    '1392_848': 32.1964,
    '1392_849': 32.114,
    '1392_850': 32.0314,
    '1392_851': 31.9519,
    '1392_852': 31.8783,
    '1392_853': 31.8117,
    '1392_854': 31.7511,
    '1392_855': 31.6943,
    '1392_856': 31.638,
    '1392_857': 31.5834,
    '1392_858': 31.5304,
    '1392_859': 31.4796,
    '1392_860': 31.4347,
    '1392_861': 31.3971,
    '1392_862': 31.3624,
    '1392_863': 31.3249,
    '1392_864': 31.2842,
    '1392_865': 31.2417,
    '1392_866': 31.1987,
    '1392_867': 31.1586,
    '1392_868': 31.1247,
    '1392_869': 31.0986,
    '1392_870': 31.0812,
    '1392_871': 31.074,
    '1392_872': 31.0769,
    '1392_873': 31.088,
    '1392_874': 31.1125,
    '1392_875': 31.1433,
    '1392_876': 31.1764,
    '1392_877': 31.2222,
    '1392_878': 31.2686,
    '1392_879': 31.3083,
    '1392_880': 31.3482,
    '1392_881': 31.3838,
    '1392_882': 31.4277,
    '1392_883': 31.4809,
    '1392_884': 31.5518,
    '1392_885': 31.6365,
    '1392_886': 31.7228,
    '1392_887': 31.8024,
    '1392_888': 31.8896,
    '1392_889': 31.9934,
    '1392_890': 32.0952,
    '1392_891': 32.1595,
    '1392_892': 32.1767,
    '1392_893': 32.1483,
    '1392_894': 32.1129,
    '1392_895': 32.1133,
    '1392_896': 32.1336,
    '1392_897': 32.1533,
    '1392_898': 32.1907,
    '1392_899': 32.2344,
    '1392_900': 32.2794,
    '1392_901': 32.3161,
    '1392_902': 32.3285,
    '1392_903': 32.3267,
    '1392_904': 32.3207,
    '1392_905': 32.305,
    '1392_906': 32.2722,
    '1392_907': 32.2246,
    '1392_908': 32.1692,
    '1392_909': 32.1038,
    '1392_910': 32.0218,
    '1392_911': 31.916,
    '1392_912': 31.7889,
    '1392_913': 31.6642,
    '1392_914': 31.5531,
    '1392_915': 31.4527,
    '1392_916': 31.3547,
    '1392_917': 31.2567,
    '1392_918': 31.1664,
    '1392_919': 31.0872,
    '1392_920': 30.9989,
    '1392_921': 30.9002,
    '1392_922': 30.8037,
    '1392_923': 30.7082,
    '1392_924': 30.6094,
    '1392_925': 30.5078,
    '1392_926': 30.4048,
    '1392_927': 30.2951,
    '1392_928': 30.1871,
    '1392_929': 30.0905,
    '1392_930': 30.0087,
    '1392_931': 29.9434,
    '1392_932': 29.8928,
    '1392_933': 29.8568,
    '1392_934': 29.8373,
    '1392_935': 29.837,
    '1392_936': 29.8579,
    '1392_937': 29.8884,
    '1392_938': 29.9267,
    '1392_939': 29.9661,
    '1392_940': 30.013,
    '1392_941': 30.067,
    '1392_942': 30.123,
    '1392_943': 30.1804,
    '1392_944': 30.2561,
    '1392_945': 30.3472,
    '1392_946': 30.4476,
    '1392_947': 30.5535,
    '1392_948': 30.6621,
    '1392_949': 30.7588,
    '1392_950': 30.8372,
    '1392_951': 30.9064,
    '1392_952': 30.9596,
    '1392_953': 30.9878,
    '1392_954': 31.0069,
    '1392_955': 31.0318,
    '1392_956': 31.0692,
    '1392_957': 31.1061,
    '1392_958': 31.1342,
    '1392_959': 31.1567,
    '1392_960': 31.1686,
    '1392_961': 31.1529,
    '1392_962': 31.0967,
    '1392_963': 31.0235,
    '1392_964': 30.963,
    '1392_965': 30.905,
    '1392_966': 30.8533,
    '1392_967': 30.8222,
    '1392_968': 30.7963,
    '1392_969': 30.7793,
    '1392_970': 30.7687,
    '1392_971': 30.7663,
    '1392_972': 30.7737,
    '1392_973': 30.7867,
    '1392_974': 30.8125,
    '1392_975': 30.8435,
    '1392_976': 30.8797,
    '1392_977': 30.9122,
    '1392_978': 30.9464,
    '1392_979': 30.9836,
    '1392_980': 31.0218,
    '1392_981': 31.0592,
    '1392_982': 31.0939,
    '1392_983': 31.1305,
    '1392_984': 31.1674,
    '1392_985': 31.2025,
    '1392_986': 31.2338,
    '1392_987': 31.2573,
    '1392_988': 31.2738,
    '1392_989': 31.2866,
    '1392_990': 31.2996,
    '1392_991': 31.3189,
    '1392_992': 31.3463,
    '1392_993': 31.3755,
    '1392_994': 31.3995,
    '1392_995': 31.4201,
    '1392_996': 31.4323,
    '1392_997': 31.434,
    '1392_998': 31.4303,
    '1392_999': 31.4214,
    '1392_1000': 31.4066,
    '1392_1001': 31.3885,
    '1392_1002': 31.366,
    '1392_1003': 31.3389,
    '1392_1004': 31.3076,
    '1392_1005': 31.2707,
    '1392_1006': 31.2298,
    '1392_1007': 31.1841,
    '1392_1008': 31.1307,
    '1392_1009': 31.0686,
    '1392_1010': 30.9985,
    '1392_1011': 30.9215,
    '1392_1012': 30.8358,
    '1392_1013': 30.7428,
    '1392_1014': 30.6435,
    '1392_1015': 30.5357,
    '1392_1016': 30.4221,
    '1392_1017': 30.3019,
    '1392_1018': 30.1772,
    '1392_1019': 30.0412,
    '1392_1020': 29.897,
    '1392_1021': 29.7484,
    '1392_1022': 29.5947,
    '1392_1023': 29.4375,
    '1392_1024': 29.2703,
    '1392_1025': 29.0894,
    '1392_1026': 28.9035,
    '1392_1027': 28.7089,
    '1392_1028': 28.5093,
    '1392_1029': 28.3066,
    '1392_1030': 28.1031,
    '1392_1031': 27.903,
    '1392_1032': 27.7049,
    '1392_1033': 27.5062,
    '1393_806': 31.779,
    '1393_807': 31.882,
    '1393_808': 31.9965,
    '1393_809': 32.1211,
    '1393_810': 32.2448,
    '1393_811': 32.3632,
    '1393_812': 32.4652,
    '1393_813': 32.5781,
    '1393_814': 32.7094,
    '1393_815': 32.8283,
    '1393_816': 32.932,
    '1393_817': 33.0055,
    '1393_818': 33.0434,
    '1393_819': 33.0759,
    '1393_820': 33.0933,
    '1393_821': 33.0941,
    '1393_822': 33.0702,
    '1393_823': 33.0303,
    '1393_824': 32.9938,
    '1393_825': 32.9668,
    '1393_826': 32.9512,
    '1393_827': 32.9224,
    '1393_828': 32.8808,
    '1393_829': 32.8222,
    '1393_830': 32.7527,
    '1393_831': 32.7,
    '1393_832': 32.6664,
    '1393_833': 32.6481,
    '1393_834': 32.6393,
    '1393_835': 32.6342,
    '1393_836': 32.6291,
    '1393_837': 32.6273,
    '1393_838': 32.6204,
    '1393_839': 32.5972,
    '1393_840': 32.5571,
    '1393_841': 32.5091,
    '1393_842': 32.4692,
    '1393_843': 32.4313,
    '1393_844': 32.3929,
    '1393_845': 32.344,
    '1393_846': 32.2881,
    '1393_847': 32.2234,
    '1393_848': 32.1543,
    '1393_849': 32.0758,
    '1393_850': 31.996,
    '1393_851': 31.9216,
    '1393_852': 31.8531,
    '1393_853': 31.7915,
    '1393_854': 31.737,
    '1393_855': 31.6879,
    '1393_856': 31.6417,
    '1393_857': 31.598,
    '1393_858': 31.5568,
    '1393_859': 31.5138,
    '1393_860': 31.4745,
    '1393_861': 31.4416,
    '1393_862': 31.4103,
    '1393_863': 31.3755,
    '1393_864': 31.3361,
    '1393_865': 31.2942,
    '1393_866': 31.2503,
    '1393_867': 31.2077,
    '1393_868': 31.1704,
    '1393_869': 31.1405,
    '1393_870': 31.1198,
    '1393_871': 31.1095,
    '1393_872': 31.1094,
    '1393_873': 31.1197,
    '1393_874': 31.1439,
    '1393_875': 31.1744,
    '1393_876': 31.2069,
    '1393_877': 31.2532,
    '1393_878': 31.3047,
    '1393_879': 31.3422,
    '1393_880': 31.376,
    '1393_881': 31.4145,
    '1393_882': 31.4617,
    '1393_883': 31.5221,
    '1393_884': 31.5956,
    '1393_885': 31.6802,
    '1393_886': 31.7676,
    '1393_887': 31.8493,
    '1393_888': 31.9364,
    '1393_889': 32.0398,
    '1393_890': 32.137,
    '1393_891': 32.2043,
    '1393_892': 32.2223,
    '1393_893': 32.1774,
    '1393_894': 32.1235,
    '1393_895': 32.1161,
    '1393_896': 32.1422,
    '1393_897': 32.1705,
    '1393_898': 32.2017,
    '1393_899': 32.2453,
    '1393_900': 32.2917,
    '1393_901': 32.3313,
    '1393_902': 32.3509,
    '1393_903': 32.3522,
    '1393_904': 32.3469,
    '1393_905': 32.3354,
    '1393_906': 32.3076,
    '1393_907': 32.2615,
    '1393_908': 32.204,
    '1393_909': 32.1387,
    '1393_910': 32.0577,
    '1393_911': 31.9553,
    '1393_912': 31.842,
    '1393_913': 31.7346,
    '1393_914': 31.634,
    '1393_915': 31.5402,
    '1393_916': 31.4459,
    '1393_917': 31.3527,
    '1393_918': 31.2681,
    '1393_919': 31.1972,
    '1393_920': 31.1192,
    '1393_921': 31.0274,
    '1393_922': 30.9373,
    '1393_923': 30.8483,
    '1393_924': 30.7549,
    '1393_925': 30.6526,
    '1393_926': 30.5467,
    '1393_927': 30.4354,
    '1393_928': 30.3256,
    '1393_929': 30.2263,
    '1393_930': 30.1396,
    '1393_931': 30.0696,
    '1393_932': 30.0144,
    '1393_933': 29.9742,
    '1393_934': 29.9502,
    '1393_935': 29.9455,
    '1393_936': 29.9624,
    '1393_937': 29.9871,
    '1393_938': 30.0208,
    '1393_939': 30.0591,
    '1393_940': 30.1043,
    '1393_941': 30.1551,
    '1393_942': 30.2029,
    '1393_943': 30.2613,
    '1393_944': 30.3374,
    '1393_945': 30.4228,
    '1393_946': 30.518,
    '1393_947': 30.6205,
    '1393_948': 30.7267,
    '1393_949': 30.8229,
    '1393_950': 30.9053,
    '1393_951': 30.9744,
    '1393_952': 31.0338,
    '1393_953': 31.0735,
    '1393_954': 31.1008,
    '1393_955': 31.1297,
    '1393_956': 31.167,
    '1393_957': 31.2024,
    '1393_958': 31.2366,
    '1393_959': 31.264,
    '1393_960': 31.2765,
    '1393_961': 31.2619,
    '1393_962': 31.2091,
    '1393_963': 31.1322,
    '1393_964': 31.0623,
    '1393_965': 30.9936,
    '1393_966': 30.942,
    '1393_967': 30.9031,
    '1393_968': 30.8685,
    '1393_969': 30.8422,
    '1393_970': 30.8229,
    '1393_971': 30.8145,
    '1393_972': 30.816,
    '1393_973': 30.8225,
    '1393_974': 30.8417,
    '1393_975': 30.8696,
    '1393_976': 30.8998,
    '1393_977': 30.9268,
    '1393_978': 30.955,
    '1393_979': 30.9874,
    '1393_980': 31.0217,
    '1393_981': 31.0573,
    '1393_982': 31.0936,
    '1393_983': 31.1283,
    '1393_984': 31.1649,
    '1393_985': 31.2009,
    '1393_986': 31.2306,
    '1393_987': 31.2545,
    '1393_988': 31.2727,
    '1393_989': 31.2903,
    '1393_990': 31.3109,
    '1393_991': 31.3399,
    '1393_992': 31.3745,
    '1393_993': 31.4078,
    '1393_994': 31.4343,
    '1393_995': 31.4572,
    '1393_996': 31.4746,
    '1393_997': 31.4786,
    '1393_998': 31.4779,
    '1393_999': 31.473,
    '1393_1000': 31.4607,
    '1393_1001': 31.4461,
    '1393_1002': 31.4276,
    '1393_1003': 31.4064,
    '1393_1004': 31.3825,
    '1393_1005': 31.3559,
    '1393_1006': 31.3257,
    '1393_1007': 31.2915,
    '1393_1008': 31.2504,
    '1393_1009': 31.2004,
    '1393_1010': 31.1412,
    '1393_1011': 31.0734,
    '1393_1012': 30.998,
    '1393_1013': 30.9151,
    '1393_1014': 30.8245,
    '1393_1015': 30.7265,
    '1393_1016': 30.622,
    '1393_1017': 30.5108,
    '1393_1018': 30.3948,
    '1393_1019': 30.268,
    '1393_1020': 30.1254,
    '1393_1021': 29.9809,
    '1393_1022': 29.8344,
    '1393_1023': 29.683,
    '1393_1024': 29.5239,
    '1393_1025': 29.3467,
    '1393_1026': 29.1647,
    '1393_1027': 28.9744,
    '1393_1028': 28.7795,
    '1393_1029': 28.5819,
    '1393_1030': 28.3823,
    '1393_1031': 28.1902,
    '1393_1032': 27.9998,
    '1393_1033': 27.8083,
    '1394_806': 31.7467,
    '1394_807': 31.857,
    '1394_808': 31.9773,
    '1394_809': 32.1001,
    '1394_810': 32.2213,
    '1394_811': 32.3369,
    '1394_812': 32.441,
    '1394_813': 32.5597,
    '1394_814': 32.6983,
    '1394_815': 32.8169,
    '1394_816': 32.9113,
    '1394_817': 32.985,
    '1394_818': 33.0336,
    '1394_819': 33.059,
    '1394_820': 33.0608,
    '1394_821': 33.0476,
    '1394_822': 33.0191,
    '1394_823': 32.9729,
    '1394_824': 32.9273,
    '1394_825': 32.8876,
    '1394_826': 32.8652,
    '1394_827': 32.8402,
    '1394_828': 32.7986,
    '1394_829': 32.7476,
    '1394_830': 32.6906,
    '1394_831': 32.6476,
    '1394_832': 32.6201,
    '1394_833': 32.5963,
    '1394_834': 32.5811,
    '1394_835': 32.5695,
    '1394_836': 32.5595,
    '1394_837': 32.5519,
    '1394_838': 32.5434,
    '1394_839': 32.5268,
    '1394_840': 32.4932,
    '1394_841': 32.4493,
    '1394_842': 32.4137,
    '1394_843': 32.3769,
    '1394_844': 32.3379,
    '1394_845': 32.2905,
    '1394_846': 32.2343,
    '1394_847': 32.1708,
    '1394_848': 32.1034,
    '1394_849': 32.0328,
    '1394_850': 31.9626,
    '1394_851': 31.8944,
    '1394_852': 31.8324,
    '1394_853': 31.7764,
    '1394_854': 31.7273,
    '1394_855': 31.6856,
    '1394_856': 31.6485,
    '1394_857': 31.6148,
    '1394_858': 31.5847,
    '1394_859': 31.5538,
    '1394_860': 31.5208,
    '1394_861': 31.4916,
    '1394_862': 31.4652,
    '1394_863': 31.4317,
    '1394_864': 31.3931,
    '1394_865': 31.351,
    '1394_866': 31.3063,
    '1394_867': 31.2617,
    '1394_868': 31.2212,
    '1394_869': 31.1878,
    '1394_870': 31.1636,
    '1394_871': 31.1495,
    '1394_872': 31.1459,
    '1394_873': 31.1546,
    '1394_874': 31.1755,
    '1394_875': 31.2044,
    '1394_876': 31.2388,
    '1394_877': 31.2828,
    '1394_878': 31.3338,
    '1394_879': 31.3785,
    '1394_880': 31.4129,
    '1394_881': 31.4526,
    '1394_882': 31.5011,
    '1394_883': 31.562,
    '1394_884': 31.636,
    '1394_885': 31.7174,
    '1394_886': 31.8024,
    '1394_887': 31.8859,
    '1394_888': 31.9717,
    '1394_889': 32.073,
    '1394_890': 32.1748,
    '1394_891': 32.2329,
    '1394_892': 32.2473,
    '1394_893': 32.2002,
    '1394_894': 32.1366,
    '1394_895': 32.1213,
    '1394_896': 32.1541,
    '1394_897': 32.1924,
    '1394_898': 32.2224,
    '1394_899': 32.2612,
    '1394_900': 32.311,
    '1394_901': 32.3558,
    '1394_902': 32.3844,
    '1394_903': 32.3853,
    '1394_904': 32.3741,
    '1394_905': 32.3644,
    '1394_906': 32.3423,
    '1394_907': 32.3007,
    '1394_908': 32.2427,
    '1394_909': 32.1769,
    '1394_910': 32.0995,
    '1394_911': 32.0035,
    '1394_912': 31.9006,
    '1394_913': 31.8046,
    '1394_914': 31.7119,
    '1394_915': 31.6225,
    '1394_916': 31.5344,
    '1394_917': 31.447,
    '1394_918': 31.3687,
    '1394_919': 31.3041,
    '1394_920': 31.2353,
    '1394_921': 31.1568,
    '1394_922': 31.0767,
    '1394_923': 30.9991,
    '1394_924': 30.9143,
    '1394_925': 30.8137,
    '1394_926': 30.7048,
    '1394_927': 30.5914,
    '1394_928': 30.4769,
    '1394_929': 30.3725,
    '1394_930': 30.2795,
    '1394_931': 30.2023,
    '1394_932': 30.141,
    '1394_933': 30.0959,
    '1394_934': 30.0668,
    '1394_935': 30.0565,
    '1394_936': 30.0668,
    '1394_937': 30.0878,
    '1394_938': 30.1172,
    '1394_939': 30.1546,
    '1394_940': 30.1964,
    '1394_941': 30.2395,
    '1394_942': 30.2809,
    '1394_943': 30.3397,
    '1394_944': 30.4149,
    '1394_945': 30.4987,
    '1394_946': 30.59,
    '1394_947': 30.6864,
    '1394_948': 30.7852,
    '1394_949': 30.8795,
    '1394_950': 30.9636,
    '1394_951': 31.0355,
    '1394_952': 31.0996,
    '1394_953': 31.1491,
    '1394_954': 31.1875,
    '1394_955': 31.2249,
    '1394_956': 31.2608,
    '1394_957': 31.2946,
    '1394_958': 31.3322,
    '1394_959': 31.3628,
    '1394_960': 31.3733,
    '1394_961': 31.3596,
    '1394_962': 31.3114,
    '1394_963': 31.2328,
    '1394_964': 31.1552,
    '1394_965': 31.0817,
    '1394_966': 31.029,
    '1394_967': 30.984,
    '1394_968': 30.9406,
    '1394_969': 30.9059,
    '1394_970': 30.8812,
    '1394_971': 30.8658,
    '1394_972': 30.8608,
    '1394_973': 30.8611,
    '1394_974': 30.8748,
    '1394_975': 30.8953,
    '1394_976': 30.9177,
    '1394_977': 30.9384,
    '1394_978': 30.9607,
    '1394_979': 30.9892,
    '1394_980': 31.022,
    '1394_981': 31.0552,
    '1394_982': 31.0911,
    '1394_983': 31.1269,
    '1394_984': 31.162,
    '1394_985': 31.1953,
    '1394_986': 31.2217,
    '1394_987': 31.2457,
    '1394_988': 31.2655,
    '1394_989': 31.2872,
    '1394_990': 31.3129,
    '1394_991': 31.3469,
    '1394_992': 31.3854,
    '1394_993': 31.4251,
    '1394_994': 31.4512,
    '1394_995': 31.4714,
    '1394_996': 31.489,
    '1394_997': 31.495,
    '1394_998': 31.494,
    '1394_999': 31.4908,
    '1394_1000': 31.4857,
    '1394_1001': 31.4782,
    '1394_1002': 31.4624,
    '1394_1003': 31.4465,
    '1394_1004': 31.4294,
    '1394_1005': 31.4118,
    '1394_1006': 31.3921,
    '1394_1007': 31.3681,
    '1394_1008': 31.3384,
    '1394_1009': 31.3023,
    '1394_1010': 31.2578,
    '1394_1011': 31.202,
    '1394_1012': 31.1359,
    '1394_1013': 31.0617,
    '1394_1014': 30.979,
    '1394_1015': 30.8901,
    '1394_1016': 30.7949,
    '1394_1017': 30.6938,
    '1394_1018': 30.588,
    '1394_1019': 30.4833,
    '1394_1020': 30.3373,
    '1394_1021': 30.1994,
    '1394_1022': 30.061,
    '1394_1023': 29.914,
    '1394_1024': 29.7582,
    '1394_1025': 29.5892,
    '1394_1026': 29.4138,
    '1394_1027': 29.2294,
    '1394_1028': 29.04,
    '1394_1029': 28.8488,
    '1394_1030': 28.6577,
    '1394_1031': 28.4714,
    '1394_1032': 28.2873,
    '1394_1033': 28.1023,
    '1395_806': 31.7037,
    '1395_807': 31.8173,
    '1395_808': 31.9447,
    '1395_809': 32.067,
    '1395_810': 32.1872,
    '1395_811': 32.3034,
    '1395_812': 32.4071,
    '1395_813': 32.5236,
    '1395_814': 32.6586,
    '1395_815': 32.7705,
    '1395_816': 32.8559,
    '1395_817': 32.9353,
    '1395_818': 32.9915,
    '1395_819': 33.0152,
    '1395_820': 33.0059,
    '1395_821': 32.9771,
    '1395_822': 32.9418,
    '1395_823': 32.8967,
    '1395_824': 32.8576,
    '1395_825': 32.8143,
    '1395_826': 32.7794,
    '1395_827': 32.7517,
    '1395_828': 32.7118,
    '1395_829': 32.6674,
    '1395_830': 32.6268,
    '1395_831': 32.5964,
    '1395_832': 32.5705,
    '1395_833': 32.5467,
    '1395_834': 32.528,
    '1395_835': 32.5125,
    '1395_836': 32.4989,
    '1395_837': 32.4844,
    '1395_838': 32.4706,
    '1395_839': 32.4514,
    '1395_840': 32.4234,
    '1395_841': 32.3901,
    '1395_842': 32.36,
    '1395_843': 32.3253,
    '1395_844': 32.2877,
    '1395_845': 32.2411,
    '1395_846': 32.1844,
    '1395_847': 32.1206,
    '1395_848': 32.0553,
    '1395_849': 31.9916,
    '1395_850': 31.9299,
    '1395_851': 31.8716,
    '1395_852': 31.816,
    '1395_853': 31.7664,
    '1395_854': 31.7232,
    '1395_855': 31.6887,
    '1395_856': 31.6596,
    '1395_857': 31.6349,
    '1395_858': 31.6147,
    '1395_859': 31.5961,
    '1395_860': 31.5716,
    '1395_861': 31.5447,
    '1395_862': 31.5237,
    '1395_863': 31.4946,
    '1395_864': 31.4543,
    '1395_865': 31.4109,
    '1395_866': 31.365,
    '1395_867': 31.3188,
    '1395_868': 31.2761,
    '1395_869': 31.2397,
    '1395_870': 31.2121,
    '1395_871': 31.1943,
    '1395_872': 31.1883,
    '1395_873': 31.1963,
    '1395_874': 31.2167,
    '1395_875': 31.2457,
    '1395_876': 31.2778,
    '1395_877': 31.3166,
    '1395_878': 31.3612,
    '1395_879': 31.4095,
    '1395_880': 31.4588,
    '1395_881': 31.5034,
    '1395_882': 31.5504,
    '1395_883': 31.6067,
    '1395_884': 31.6736,
    '1395_885': 31.7466,
    '1395_886': 31.8283,
    '1395_887': 31.9116,
    '1395_888': 31.9966,
    '1395_889': 32.0918,
    '1395_890': 32.1879,
    '1395_891': 32.252,
    '1395_892': 32.2656,
    '1395_893': 32.2101,
    '1395_894': 32.1469,
    '1395_895': 32.127,
    '1395_896': 32.1571,
    '1395_897': 32.2054,
    '1395_898': 32.2431,
    '1395_899': 32.2807,
    '1395_900': 32.3329,
    '1395_901': 32.3802,
    '1395_902': 32.4134,
    '1395_903': 32.418,
    '1395_904': 32.4057,
    '1395_905': 32.3936,
    '1395_906': 32.3735,
    '1395_907': 32.3378,
    '1395_908': 32.2825,
    '1395_909': 32.218,
    '1395_910': 32.1442,
    '1395_911': 32.0567,
    '1395_912': 31.9609,
    '1395_913': 31.8693,
    '1395_914': 31.7839,
    '1395_915': 31.6993,
    '1395_916': 31.6161,
    '1395_917': 31.5355,
    '1395_918': 31.4677,
    '1395_919': 31.4095,
    '1395_920': 31.3471,
    '1395_921': 31.2825,
    '1395_922': 31.2177,
    '1395_923': 31.1484,
    '1395_924': 31.0685,
    '1395_925': 30.9757,
    '1395_926': 30.8681,
    '1395_927': 30.7521,
    '1395_928': 30.6325,
    '1395_929': 30.5224,
    '1395_930': 30.4248,
    '1395_931': 30.3412,
    '1395_932': 30.2724,
    '1395_933': 30.2214,
    '1395_934': 30.1873,
    '1395_935': 30.1695,
    '1395_936': 30.1705,
    '1395_937': 30.1877,
    '1395_938': 30.2175,
    '1395_939': 30.252,
    '1395_940': 30.29,
    '1395_941': 30.3248,
    '1395_942': 30.3634,
    '1395_943': 30.4194,
    '1395_944': 30.4913,
    '1395_945': 30.573,
    '1395_946': 30.6603,
    '1395_947': 30.7511,
    '1395_948': 30.8441,
    '1395_949': 30.9361,
    '1395_950': 31.0217,
    '1395_951': 31.0934,
    '1395_952': 31.1622,
    '1395_953': 31.2209,
    '1395_954': 31.2679,
    '1395_955': 31.3125,
    '1395_956': 31.3495,
    '1395_957': 31.3826,
    '1395_958': 31.4195,
    '1395_959': 31.4533,
    '1395_960': 31.4616,
    '1395_961': 31.4467,
    '1395_962': 31.3985,
    '1395_963': 31.3278,
    '1395_964': 31.251,
    '1395_965': 31.1795,
    '1395_966': 31.1225,
    '1395_967': 31.0671,
    '1395_968': 31.0163,
    '1395_969': 30.9734,
    '1395_970': 30.9409,
    '1395_971': 30.918,
    '1395_972': 30.9051,
    '1395_973': 30.9012,
    '1395_974': 30.9088,
    '1395_975': 30.9203,
    '1395_976': 30.9337,
    '1395_977': 30.9497,
    '1395_978': 30.9684,
    '1395_979': 30.9939,
    '1395_980': 31.0227,
    '1395_981': 31.0542,
    '1395_982': 31.0869,
    '1395_983': 31.1229,
    '1395_984': 31.1571,
    '1395_985': 31.1879,
    '1395_986': 31.2134,
    '1395_987': 31.2375,
    '1395_988': 31.26,
    '1395_989': 31.2829,
    '1395_990': 31.3103,
    '1395_991': 31.3437,
    '1395_992': 31.3807,
    '1395_993': 31.4184,
    '1395_994': 31.4472,
    '1395_995': 31.4678,
    '1395_996': 31.4825,
    '1395_997': 31.489,
    '1395_998': 31.4905,
    '1395_999': 31.4902,
    '1395_1000': 31.4886,
    '1395_1001': 31.4839,
    '1395_1002': 31.4744,
    '1395_1003': 31.4643,
    '1395_1004': 31.4558,
    '1395_1005': 31.4481,
    '1395_1006': 31.4383,
    '1395_1007': 31.4249,
    '1395_1008': 31.406,
    '1395_1009': 31.3812,
    '1395_1010': 31.3493,
    '1395_1011': 31.3054,
    '1395_1012': 31.2491,
    '1395_1013': 31.1824,
    '1395_1014': 31.1086,
    '1395_1015': 31.0292,
    '1395_1016': 30.9443,
    '1395_1017': 30.853,
    '1395_1018': 30.7552,
    '1395_1019': 30.6531,
    '1395_1020': 30.5309,
    '1395_1021': 30.4068,
    '1395_1022': 30.2774,
    '1395_1023': 30.1351,
    '1395_1024': 29.9842,
    '1395_1025': 29.8231,
    '1395_1026': 29.655,
    '1395_1027': 29.4774,
    '1395_1028': 29.2938,
    '1395_1029': 29.1096,
    '1395_1030': 28.9252,
    '1395_1031': 28.7448,
    '1395_1032': 28.5668,
    '1395_1033': 28.3881,
    '1396_806': 31.6509,
    '1396_807': 31.7615,
    '1396_808': 31.8861,
    '1396_809': 32.0149,
    '1396_810': 32.1384,
    '1396_811': 32.2598,
    '1396_812': 32.3611,
    '1396_813': 32.4663,
    '1396_814': 32.5856,
    '1396_815': 32.6897,
    '1396_816': 32.7717,
    '1396_817': 32.8557,
    '1396_818': 32.9176,
    '1396_819': 32.9403,
    '1396_820': 32.9304,
    '1396_821': 32.8975,
    '1396_822': 32.8496,
    '1396_823': 32.8077,
    '1396_824': 32.7773,
    '1396_825': 32.7397,
    '1396_826': 32.6975,
    '1396_827': 32.666,
    '1396_828': 32.6312,
    '1396_829': 32.5962,
    '1396_830': 32.5672,
    '1396_831': 32.5398,
    '1396_832': 32.5141,
    '1396_833': 32.4904,
    '1396_834': 32.4701,
    '1396_835': 32.4528,
    '1396_836': 32.438,
    '1396_837': 32.4242,
    '1396_838': 32.4087,
    '1396_839': 32.3901,
    '1396_840': 32.3659,
    '1396_841': 32.3384,
    '1396_842': 32.3131,
    '1396_843': 32.2832,
    '1396_844': 32.2466,
    '1396_845': 32.1999,
    '1396_846': 32.1431,
    '1396_847': 32.0808,
    '1396_848': 32.018,
    '1396_849': 31.9575,
    '1396_850': 31.9024,
    '1396_851': 31.8522,
    '1396_852': 31.8056,
    '1396_853': 31.7622,
    '1396_854': 31.726,
    '1396_855': 31.6974,
    '1396_856': 31.6755,
    '1396_857': 31.6591,
    '1396_858': 31.6471,
    '1396_859': 31.6385,
    '1396_860': 31.6227,
    '1396_861': 31.6003,
    '1396_862': 31.5835,
    '1396_863': 31.5605,
    '1396_864': 31.5178,
    '1396_865': 31.4723,
    '1396_866': 31.4244,
    '1396_867': 31.3765,
    '1396_868': 31.3325,
    '1396_869': 31.2945,
    '1396_870': 31.2646,
    '1396_871': 31.244,
    '1396_872': 31.2349,
    '1396_873': 31.2404,
    '1396_874': 31.2652,
    '1396_875': 31.3024,
    '1396_876': 31.338,
    '1396_877': 31.3741,
    '1396_878': 31.4107,
    '1396_879': 31.4552,
    '1396_880': 31.5112,
    '1396_881': 31.5602,
    '1396_882': 31.6031,
    '1396_883': 31.6533,
    '1396_884': 31.7092,
    '1396_885': 31.773,
    '1396_886': 31.8478,
    '1396_887': 31.9285,
    '1396_888': 32.0086,
    '1396_889': 32.0991,
    '1396_890': 32.1921,
    '1396_891': 32.2527,
    '1396_892': 32.2617,
    '1396_893': 32.2144,
    '1396_894': 32.1541,
    '1396_895': 32.1298,
    '1396_896': 32.1588,
    '1396_897': 32.2118,
    '1396_898': 32.2546,
    '1396_899': 32.2941,
    '1396_900': 32.3439,
    '1396_901': 32.3929,
    '1396_902': 32.4288,
    '1396_903': 32.4418,
    '1396_904': 32.4381,
    '1396_905': 32.4257,
    '1396_906': 32.406,
    '1396_907': 32.3735,
    '1396_908': 32.322,
    '1396_909': 32.2587,
    '1396_910': 32.1893,
    '1396_911': 32.1073,
    '1396_912': 32.0172,
    '1396_913': 31.9302,
    '1396_914': 31.8522,
    '1396_915': 31.7735,
    '1396_916': 31.694,
    '1396_917': 31.6191,
    '1396_918': 31.5672,
    '1396_919': 31.5175,
    '1396_920': 31.4632,
    '1396_921': 31.4046,
    '1396_922': 31.349,
    '1396_923': 31.2886,
    '1396_924': 31.2148,
    '1396_925': 31.1244,
    '1396_926': 31.0245,
    '1396_927': 30.9091,
    '1396_928': 30.7871,
    '1396_929': 30.6741,
    '1396_930': 30.5748,
    '1396_931': 30.4844,
    '1396_932': 30.4073,
    '1396_933': 30.3498,
    '1396_934': 30.3087,
    '1396_935': 30.2823,
    '1396_936': 30.273,
    '1396_937': 30.2809,
    '1396_938': 30.3087,
    '1396_939': 30.3446,
    '1396_940': 30.3789,
    '1396_941': 30.4113,
    '1396_942': 30.451,
    '1396_943': 30.506,
    '1396_944': 30.5688,
    '1396_945': 30.6446,
    '1396_946': 30.7295,
    '1396_947': 30.8159,
    '1396_948': 30.9079,
    '1396_949': 30.9989,
    '1396_950': 31.0796,
    '1396_951': 31.1466,
    '1396_952': 31.2194,
    '1396_953': 31.2877,
    '1396_954': 31.3416,
    '1396_955': 31.3904,
    '1396_956': 31.4307,
    '1396_957': 31.4648,
    '1396_958': 31.5007,
    '1396_959': 31.535,
    '1396_960': 31.5437,
    '1396_961': 31.5279,
    '1396_962': 31.4829,
    '1396_963': 31.4191,
    '1396_964': 31.3478,
    '1396_965': 31.2801,
    '1396_966': 31.2173,
    '1396_967': 31.1518,
    '1396_968': 31.0919,
    '1396_969': 31.0445,
    '1396_970': 31.0059,
    '1396_971': 30.9745,
    '1396_972': 30.9553,
    '1396_973': 30.9433,
    '1396_974': 30.9431,
    '1396_975': 30.9456,
    '1396_976': 30.9524,
    '1396_977': 30.9641,
    '1396_978': 30.9783,
    '1396_979': 30.9992,
    '1396_980': 31.0239,
    '1396_981': 31.0531,
    '1396_982': 31.0835,
    '1396_983': 31.117,
    '1396_984': 31.1503,
    '1396_985': 31.1823,
    '1396_986': 31.209,
    '1396_987': 31.233,
    '1396_988': 31.2566,
    '1396_989': 31.2808,
    '1396_990': 31.3086,
    '1396_991': 31.3411,
    '1396_992': 31.3753,
    '1396_993': 31.4086,
    '1396_994': 31.4351,
    '1396_995': 31.4556,
    '1396_996': 31.467,
    '1396_997': 31.4744,
    '1396_998': 31.4798,
    '1396_999': 31.4842,
    '1396_1000': 31.4845,
    '1396_1001': 31.4804,
    '1396_1002': 31.4745,
    '1396_1003': 31.4706,
    '1396_1004': 31.4697,
    '1396_1005': 31.4713,
    '1396_1006': 31.4708,
    '1396_1007': 31.4675,
    '1396_1008': 31.457,
    '1396_1009': 31.4399,
    '1396_1010': 31.4161,
    '1396_1011': 31.3823,
    '1396_1012': 31.3335,
    '1396_1013': 31.2761,
    '1396_1014': 31.2123,
    '1396_1015': 31.143,
    '1396_1016': 31.0694,
    '1396_1017': 30.9883,
    '1396_1018': 30.9005,
    '1396_1019': 30.8095,
    '1396_1020': 30.7087,
    '1396_1021': 30.6007,
    '1396_1022': 30.4799,
    '1396_1023': 30.3449,
    '1396_1024': 30.2022,
    '1396_1025': 30.05,
    '1396_1026': 29.8897,
    '1396_1027': 29.7212,
    '1396_1028': 29.5462,
    '1396_1029': 29.3673,
    '1396_1030': 29.1866,
    '1396_1031': 29.0111,
    '1396_1032': 28.8383,
    '1396_1033': 28.6665,
    '1397_806': 31.5936,
    '1397_807': 31.6977,
    '1397_808': 31.8189,
    '1397_809': 31.95,
    '1397_810': 32.0792,
    '1397_811': 32.2129,
    '1397_812': 32.3082,
    '1397_813': 32.3982,
    '1397_814': 32.495,
    '1397_815': 32.5869,
    '1397_816': 32.6687,
    '1397_817': 32.7486,
    '1397_818': 32.8097,
    '1397_819': 32.8385,
    '1397_820': 32.8343,
    '1397_821': 32.8025,
    '1397_822': 32.7615,
    '1397_823': 32.7223,
    '1397_824': 32.6926,
    '1397_825': 32.6662,
    '1397_826': 32.6225,
    '1397_827': 32.5937,
    '1397_828': 32.5627,
    '1397_829': 32.5324,
    '1397_830': 32.5051,
    '1397_831': 32.4798,
    '1397_832': 32.4541,
    '1397_833': 32.4305,
    '1397_834': 32.4107,
    '1397_835': 32.3925,
    '1397_836': 32.3785,
    '1397_837': 32.3664,
    '1397_838': 32.3509,
    '1397_839': 32.3369,
    '1397_840': 32.3176,
    '1397_841': 32.295,
    '1397_842': 32.2722,
    '1397_843': 32.2451,
    '1397_844': 32.21,
    '1397_845': 32.1636,
    '1397_846': 32.1071,
    '1397_847': 32.0473,
    '1397_848': 31.9881,
    '1397_849': 31.9306,
    '1397_850': 31.8817,
    '1397_851': 31.8391,
    '1397_852': 31.7989,
    '1397_853': 31.763,
    '1397_854': 31.7319,
    '1397_855': 31.7091,
    '1397_856': 31.6942,
    '1397_857': 31.6863,
    '1397_858': 31.6813,
    '1397_859': 31.6805,
    '1397_860': 31.6746,
    '1397_861': 31.6583,
    '1397_862': 31.6435,
    '1397_863': 31.6234,
    '1397_864': 31.5794,
    '1397_865': 31.5329,
    '1397_866': 31.4839,
    '1397_867': 31.4352,
    '1397_868': 31.3901,
    '1397_869': 31.3512,
    '1397_870': 31.3203,
    '1397_871': 31.2987,
    '1397_872': 31.2883,
    '1397_873': 31.2913,
    '1397_874': 31.3137,
    '1397_875': 31.3574,
    '1397_876': 31.4034,
    '1397_877': 31.4438,
    '1397_878': 31.4758,
    '1397_879': 31.5093,
    '1397_880': 31.5586,
    '1397_881': 31.6125,
    '1397_882': 31.6562,
    '1397_883': 31.698,
    '1397_884': 31.7427,
    '1397_885': 31.7972,
    '1397_886': 31.8654,
    '1397_887': 31.9412,
    '1397_888': 32.0163,
    '1397_889': 32.099,
    '1397_890': 32.182,
    '1397_891': 32.2409,
    '1397_892': 32.2558,
    '1397_893': 32.2197,
    '1397_894': 32.1614,
    '1397_895': 32.1286,
    '1397_896': 32.1494,
    '1397_897': 32.2039,
    '1397_898': 32.2525,
    '1397_899': 32.2965,
    '1397_900': 32.3451,
    '1397_901': 32.3933,
    '1397_902': 32.4346,
    '1397_903': 32.4582,
    '1397_904': 32.4641,
    '1397_905': 32.4585,
    '1397_906': 32.441,
    '1397_907': 32.41,
    '1397_908': 32.3599,
    '1397_909': 32.3005,
    '1397_910': 32.2356,
    '1397_911': 32.1586,
    '1397_912': 32.0725,
    '1397_913': 31.9913,
    '1397_914': 31.9181,
    '1397_915': 31.8461,
    '1397_916': 31.7705,
    '1397_917': 31.7029,
    '1397_918': 31.6588,
    '1397_919': 31.6202,
    '1397_920': 31.5748,
    '1397_921': 31.5197,
    '1397_922': 31.4672,
    '1397_923': 31.4092,
    '1397_924': 31.3397,
    '1397_925': 31.2564,
    '1397_926': 31.1612,
    '1397_927': 31.0538,
    '1397_928': 30.934,
    '1397_929': 30.823,
    '1397_930': 30.7244,
    '1397_931': 30.6289,
    '1397_932': 30.5442,
    '1397_933': 30.4809,
    '1397_934': 30.4328,
    '1397_935': 30.3979,
    '1397_936': 30.3788,
    '1397_937': 30.3789,
    '1397_938': 30.4028,
    '1397_939': 30.4342,
    '1397_940': 30.4677,
    '1397_941': 30.4991,
    '1397_942': 30.5339,
    '1397_943': 30.5889,
    '1397_944': 30.6533,
    '1397_945': 30.7247,
    '1397_946': 30.8028,
    '1397_947': 30.8859,
    '1397_948': 30.9738,
    '1397_949': 31.0607,
    '1397_950': 31.1366,
    '1397_951': 31.2017,
    '1397_952': 31.2732,
    '1397_953': 31.3443,
    '1397_954': 31.404,
    '1397_955': 31.4579,
    '1397_956': 31.5031,
    '1397_957': 31.541,
    '1397_958': 31.5762,
    '1397_959': 31.6085,
    '1397_960': 31.6207,
    '1397_961': 31.6055,
    '1397_962': 31.5633,
    '1397_963': 31.505,
    '1397_964': 31.4391,
    '1397_965': 31.3769,
    '1397_966': 31.3086,
    '1397_967': 31.2344,
    '1397_968': 31.1678,
    '1397_969': 31.1165,
    '1397_970': 31.0704,
    '1397_971': 31.0317,
    '1397_972': 31.0061,
    '1397_973': 30.9876,
    '1397_974': 30.979,
    '1397_975': 30.9733,
    '1397_976': 30.9757,
    '1397_977': 30.9814,
    '1397_978': 30.9919,
    '1397_979': 31.0076,
    '1397_980': 31.0295,
    '1397_981': 31.0556,
    '1397_982': 31.083,
    '1397_983': 31.1145,
    '1397_984': 31.1445,
    '1397_985': 31.177,
    '1397_986': 31.206,
    '1397_987': 31.2309,
    '1397_988': 31.2547,
    '1397_989': 31.2803,
    '1397_990': 31.3081,
    '1397_991': 31.3377,
    '1397_992': 31.3693,
    '1397_993': 31.3998,
    '1397_994': 31.4247,
    '1397_995': 31.4431,
    '1397_996': 31.4542,
    '1397_997': 31.4629,
    '1397_998': 31.4718,
    '1397_999': 31.4789,
    '1397_1000': 31.481,
    '1397_1001': 31.4796,
    '1397_1002': 31.4774,
    '1397_1003': 31.4776,
    '1397_1004': 31.4816,
    '1397_1005': 31.4876,
    '1397_1006': 31.4928,
    '1397_1007': 31.4967,
    '1397_1008': 31.4925,
    '1397_1009': 31.4817,
    '1397_1010': 31.4618,
    '1397_1011': 31.4342,
    '1397_1012': 31.395,
    '1397_1013': 31.3475,
    '1397_1014': 31.2936,
    '1397_1015': 31.2345,
    '1397_1016': 31.1718,
    '1397_1017': 31.103,
    '1397_1018': 31.0279,
    '1397_1019': 30.9494,
    '1397_1020': 30.8653,
    '1397_1021': 30.7718,
    '1397_1022': 30.663,
    '1397_1023': 30.5407,
    '1397_1024': 30.4084,
    '1397_1025': 30.2656,
    '1397_1026': 30.116,
    '1397_1027': 29.9589,
    '1397_1028': 29.7945,
    '1397_1029': 29.6247,
    '1397_1030': 29.4512,
    '1397_1031': 29.2773,
    '1397_1032': 29.1072,
    '1397_1033': 28.9417,
    '1398_806': 31.5488,
    '1398_807': 31.6517,
    '1398_808': 31.7701,
    '1398_809': 31.8994,
    '1398_810': 32.0328,
    '1398_811': 32.1561,
    '1398_812': 32.2492,
    '1398_813': 32.3234,
    '1398_814': 32.398,
    '1398_815': 32.471,
    '1398_816': 32.5474,
    '1398_817': 32.6196,
    '1398_818': 32.6751,
    '1398_819': 32.7054,
    '1398_820': 32.7066,
    '1398_821': 32.6905,
    '1398_822': 32.6675,
    '1398_823': 32.6389,
    '1398_824': 32.608,
    '1398_825': 32.5833,
    '1398_826': 32.552,
    '1398_827': 32.527,
    '1398_828': 32.4993,
    '1398_829': 32.4705,
    '1398_830': 32.4425,
    '1398_831': 32.4164,
    '1398_832': 32.3921,
    '1398_833': 32.3702,
    '1398_834': 32.354,
    '1398_835': 32.3362,
    '1398_836': 32.3233,
    '1398_837': 32.3128,
    '1398_838': 32.2975,
    '1398_839': 32.29,
    '1398_840': 32.2776,
    '1398_841': 32.2508,
    '1398_842': 32.2287,
    '1398_843': 32.2024,
    '1398_844': 32.1675,
    '1398_845': 32.1231,
    '1398_846': 32.0726,
    '1398_847': 32.0201,
    '1398_848': 31.9689,
    '1398_849': 31.9203,
    '1398_850': 31.8761,
    '1398_851': 31.8366,
    '1398_852': 31.7993,
    '1398_853': 31.7671,
    '1398_854': 31.7419,
    '1398_855': 31.7254,
    '1398_856': 31.7171,
    '1398_857': 31.7149,
    '1398_858': 31.7163,
    '1398_859': 31.7221,
    '1398_860': 31.7271,
    '1398_861': 31.7187,
    '1398_862': 31.6986,
    '1398_863': 31.6776,
    '1398_864': 31.6352,
    '1398_865': 31.5901,
    '1398_866': 31.5439,
    '1398_867': 31.4961,
    '1398_868': 31.4506,
    '1398_869': 31.4104,
    '1398_870': 31.3788,
    '1398_871': 31.357,
    '1398_872': 31.3455,
    '1398_873': 31.3451,
    '1398_874': 31.3603,
    '1398_875': 31.3981,
    '1398_876': 31.4478,
    '1398_877': 31.4918,
    '1398_878': 31.5266,
    '1398_879': 31.5618,
    '1398_880': 31.6025,
    '1398_881': 31.6535,
    '1398_882': 31.7019,
    '1398_883': 31.7374,
    '1398_884': 31.7715,
    '1398_885': 31.814,
    '1398_886': 31.8731,
    '1398_887': 31.9463,
    '1398_888': 32.0191,
    '1398_889': 32.0928,
    '1398_890': 32.1651,
    '1398_891': 32.2236,
    '1398_892': 32.2412,
    '1398_893': 32.2223,
    '1398_894': 32.1669,
    '1398_895': 32.1243,
    '1398_896': 32.133,
    '1398_897': 32.1865,
    '1398_898': 32.2423,
    '1398_899': 32.2936,
    '1398_900': 32.3408,
    '1398_901': 32.3916,
    '1398_902': 32.4391,
    '1398_903': 32.4743,
    '1398_904': 32.4907,
    '1398_905': 32.4941,
    '1398_906': 32.4795,
    '1398_907': 32.4473,
    '1398_908': 32.3992,
    '1398_909': 32.3425,
    '1398_910': 32.2815,
    '1398_911': 32.2141,
    '1398_912': 32.1343,
    '1398_913': 32.0559,
    '1398_914': 31.9828,
    '1398_915': 31.9142,
    '1398_916': 31.8443,
    '1398_917': 31.7779,
    '1398_918': 31.7352,
    '1398_919': 31.7069,
    '1398_920': 31.6702,
    '1398_921': 31.6254,
    '1398_922': 31.5741,
    '1398_923': 31.5213,
    '1398_924': 31.4549,
    '1398_925': 31.3756,
    '1398_926': 31.2809,
    '1398_927': 31.1844,
    '1398_928': 31.0727,
    '1398_929': 30.9705,
    '1398_930': 30.8709,
    '1398_931': 30.7728,
    '1398_932': 30.6823,
    '1398_933': 30.6146,
    '1398_934': 30.5607,
    '1398_935': 30.5171,
    '1398_936': 30.4898,
    '1398_937': 30.4844,
    '1398_938': 30.5001,
    '1398_939': 30.5257,
    '1398_940': 30.5558,
    '1398_941': 30.5893,
    '1398_942': 30.6218,
    '1398_943': 30.6685,
    '1398_944': 30.7364,
    '1398_945': 30.809,
    '1398_946': 30.8838,
    '1398_947': 30.9622,
    '1398_948': 31.0407,
    '1398_949': 31.121,
    '1398_950': 31.1954,
    '1398_951': 31.2588,
    '1398_952': 31.3237,
    '1398_953': 31.3911,
    '1398_954': 31.4553,
    '1398_955': 31.5176,
    '1398_956': 31.5699,
    '1398_957': 31.6126,
    '1398_958': 31.6495,
    '1398_959': 31.6779,
    '1398_960': 31.6923,
    '1398_961': 31.6803,
    '1398_962': 31.6435,
    '1398_963': 31.5896,
    '1398_964': 31.5272,
    '1398_965': 31.4677,
    '1398_966': 31.3957,
    '1398_967': 31.3166,
    '1398_968': 31.2475,
    '1398_969': 31.1898,
    '1398_970': 31.1375,
    '1398_971': 31.0924,
    '1398_972': 31.0586,
    '1398_973': 31.0345,
    '1398_974': 31.0194,
    '1398_975': 31.0078,
    '1398_976': 31.0031,
    '1398_977': 31.0024,
    '1398_978': 31.0077,
    '1398_979': 31.0202,
    '1398_980': 31.0379,
    '1398_981': 31.0599,
    '1398_982': 31.0875,
    '1398_983': 31.1174,
    '1398_984': 31.1436,
    '1398_985': 31.1733,
    '1398_986': 31.2036,
    '1398_987': 31.2295,
    '1398_988': 31.2542,
    '1398_989': 31.2792,
    '1398_990': 31.3056,
    '1398_991': 31.3334,
    '1398_992': 31.362,
    '1398_993': 31.3884,
    '1398_994': 31.4124,
    '1398_995': 31.4304,
    '1398_996': 31.4431,
    '1398_997': 31.4536,
    '1398_998': 31.464,
    '1398_999': 31.4728,
    '1398_1000': 31.4785,
    '1398_1001': 31.4803,
    '1398_1002': 31.4813,
    '1398_1003': 31.4835,
    '1398_1004': 31.4884,
    '1398_1005': 31.4946,
    '1398_1006': 31.501,
    '1398_1007': 31.5081,
    '1398_1008': 31.5096,
    '1398_1009': 31.5046,
    '1398_1010': 31.489,
    '1398_1011': 31.4679,
    '1398_1012': 31.4386,
    '1398_1013': 31.4002,
    '1398_1014': 31.3557,
    '1398_1015': 31.3065,
    '1398_1016': 31.2542,
    '1398_1017': 31.1969,
    '1398_1018': 31.1347,
    '1398_1019': 31.0692,
    '1398_1020': 30.9987,
    '1398_1021': 30.9191,
    '1398_1022': 30.8247,
    '1398_1023': 30.7179,
    '1398_1024': 30.5986,
    '1398_1025': 30.4668,
    '1398_1026': 30.3308,
    '1398_1027': 30.1874,
    '1398_1028': 30.0352,
    '1398_1029': 29.8774,
    '1398_1030': 29.7148,
    '1398_1031': 29.5484,
    '1398_1032': 29.3821,
    '1398_1033': 29.2168,
    '1399_806': 31.5098,
    '1399_807': 31.6106,
    '1399_808': 31.7277,
    '1399_809': 31.8532,
    '1399_810': 31.9861,
    '1399_811': 32.0994,
    '1399_812': 32.1863,
    '1399_813': 32.2503,
    '1399_814': 32.3086,
    '1399_815': 32.3684,
    '1399_816': 32.428,
    '1399_817': 32.4809,
    '1399_818': 32.5297,
    '1399_819': 32.5666,
    '1399_820': 32.5853,
    '1399_821': 32.585,
    '1399_822': 32.5723,
    '1399_823': 32.5491,
    '1399_824': 32.5233,
    '1399_825': 32.5017,
    '1399_826': 32.482,
    '1399_827': 32.4606,
    '1399_828': 32.4368,
    '1399_829': 32.4102,
    '1399_830': 32.3823,
    '1399_831': 32.356,
    '1399_832': 32.3326,
    '1399_833': 32.3097,
    '1399_834': 32.2971,
    '1399_835': 32.2733,
    '1399_836': 32.2551,
    '1399_837': 32.2476,
    '1399_838': 32.2381,
    '1399_839': 32.2316,
    '1399_840': 32.2203,
    '1399_841': 32.2028,
    '1399_842': 32.1852,
    '1399_843': 32.1629,
    '1399_844': 32.1261,
    '1399_845': 32.0832,
    '1399_846': 32.039,
    '1399_847': 31.995,
    '1399_848': 31.9528,
    '1399_849': 31.9134,
    '1399_850': 31.8744,
    '1399_851': 31.8389,
    '1399_852': 31.8036,
    '1399_853': 31.7743,
    '1399_854': 31.7567,
    '1399_855': 31.7451,
    '1399_856': 31.7415,
    '1399_857': 31.7444,
    '1399_858': 31.7526,
    '1399_859': 31.7636,
    '1399_860': 31.7723,
    '1399_861': 31.7673,
    '1399_862': 31.7469,
    '1399_863': 31.7219,
    '1399_864': 31.6853,
    '1399_865': 31.6455,
    '1399_866': 31.6045,
    '1399_867': 31.5592,
    '1399_868': 31.5142,
    '1399_869': 31.4729,
    '1399_870': 31.4402,
    '1399_871': 31.4178,
    '1399_872': 31.4057,
    '1399_873': 31.404,
    '1399_874': 31.4138,
    '1399_875': 31.4384,
    '1399_876': 31.4792,
    '1399_877': 31.5199,
    '1399_878': 31.5562,
    '1399_879': 31.598,
    '1399_880': 31.6439,
    '1399_881': 31.6892,
    '1399_882': 31.7379,
    '1399_883': 31.7709,
    '1399_884': 31.7986,
    '1399_885': 31.8312,
    '1399_886': 31.8739,
    '1399_887': 31.9405,
    '1399_888': 32.0115,
    '1399_889': 32.0771,
    '1399_890': 32.1391,
    '1399_891': 32.1939,
    '1399_892': 32.2237,
    '1399_893': 32.213,
    '1399_894': 32.1645,
    '1399_895': 32.119,
    '1399_896': 32.1171,
    '1399_897': 32.1621,
    '1399_898': 32.2297,
    '1399_899': 32.2863,
    '1399_900': 32.3369,
    '1399_901': 32.3927,
    '1399_902': 32.4482,
    '1399_903': 32.494,
    '1399_904': 32.5226,
    '1399_905': 32.534,
    '1399_906': 32.521,
    '1399_907': 32.4886,
    '1399_908': 32.4404,
    '1399_909': 32.3864,
    '1399_910': 32.3281,
    '1399_911': 32.2646,
    '1399_912': 32.1924,
    '1399_913': 32.1157,
    '1399_914': 32.0385,
    '1399_915': 31.973,
    '1399_916': 31.9111,
    '1399_917': 31.8482,
    '1399_918': 31.7971,
    '1399_919': 31.7782,
    '1399_920': 31.7551,
    '1399_921': 31.7191,
    '1399_922': 31.6736,
    '1399_923': 31.6202,
    '1399_924': 31.5568,
    '1399_925': 31.4822,
    '1399_926': 31.3951,
    '1399_927': 31.3003,
    '1399_928': 31.2015,
    '1399_929': 31.1009,
    '1399_930': 31.0009,
    '1399_931': 30.9027,
    '1399_932': 30.8146,
    '1399_933': 30.7486,
    '1399_934': 30.694,
    '1399_935': 30.6426,
    '1399_936': 30.6065,
    '1399_937': 30.5945,
    '1399_938': 30.6058,
    '1399_939': 30.6256,
    '1399_940': 30.652,
    '1399_941': 30.6817,
    '1399_942': 30.7126,
    '1399_943': 30.7553,
    '1399_944': 30.8216,
    '1399_945': 30.8929,
    '1399_946': 30.9648,
    '1399_947': 31.0413,
    '1399_948': 31.1153,
    '1399_949': 31.1855,
    '1399_950': 31.2576,
    '1399_951': 31.3181,
    '1399_952': 31.3724,
    '1399_953': 31.4341,
    '1399_954': 31.4997,
    '1399_955': 31.5671,
    '1399_956': 31.6286,
    '1399_957': 31.679,
    '1399_958': 31.7187,
    '1399_959': 31.7447,
    '1399_960': 31.7603,
    '1399_961': 31.7535,
    '1399_962': 31.7219,
    '1399_963': 31.671,
    '1399_964': 31.6115,
    '1399_965': 31.5546,
    '1399_966': 31.4837,
    '1399_967': 31.4038,
    '1399_968': 31.3298,
    '1399_969': 31.2655,
    '1399_970': 31.2067,
    '1399_971': 31.1554,
    '1399_972': 31.1147,
    '1399_973': 31.088,
    '1399_974': 31.0656,
    '1399_975': 31.0481,
    '1399_976': 31.0361,
    '1399_977': 31.0295,
    '1399_978': 31.032,
    '1399_979': 31.0389,
    '1399_980': 31.0511,
    '1399_981': 31.0713,
    '1399_982': 31.097,
    '1399_983': 31.1236,
    '1399_984': 31.1463,
    '1399_985': 31.1731,
    '1399_986': 31.2026,
    '1399_987': 31.2301,
    '1399_988': 31.2552,
    '1399_989': 31.2787,
    '1399_990': 31.3032,
    '1399_991': 31.3288,
    '1399_992': 31.3542,
    '1399_993': 31.3775,
    '1399_994': 31.3989,
    '1399_995': 31.4174,
    '1399_996': 31.4318,
    '1399_997': 31.444,
    '1399_998': 31.456,
    '1399_999': 31.4657,
    '1399_1000': 31.4729,
    '1399_1001': 31.478,
    '1399_1002': 31.4815,
    '1399_1003': 31.4845,
    '1399_1004': 31.4891,
    '1399_1005': 31.4958,
    '1399_1006': 31.5018,
    '1399_1007': 31.5072,
    '1399_1008': 31.5096,
    '1399_1009': 31.5068,
    '1399_1010': 31.4967,
    '1399_1011': 31.4833,
    '1399_1012': 31.4651,
    '1399_1013': 31.4353,
    '1399_1014': 31.4003,
    '1399_1015': 31.3612,
    '1399_1016': 31.3192,
    '1399_1017': 31.2712,
    '1399_1018': 31.2211,
    '1399_1019': 31.168,
    '1399_1020': 31.1117,
    '1399_1021': 31.0448,
    '1399_1022': 30.965,
    '1399_1023': 30.8737,
    '1399_1024': 30.7704,
    '1399_1025': 30.6537,
    '1399_1026': 30.5319,
    '1399_1027': 30.4035,
    '1399_1028': 30.2628,
    '1399_1029': 30.1192,
    '1399_1030': 29.9691,
    '1399_1031': 29.8103,
    '1399_1032': 29.6482,
    '1399_1033': 29.4843,
    '1400_806': 31.4746,
    '1400_807': 31.5768,
    '1400_808': 31.6921,
    '1400_809': 31.8187,
    '1400_810': 31.9494,
    '1400_811': 32.0475,
    '1400_812': 32.1276,
    '1400_813': 32.1847,
    '1400_814': 32.2311,
    '1400_815': 32.2745,
    '1400_816': 32.3192,
    '1400_817': 32.362,
    '1400_818': 32.4123,
    '1400_819': 32.4612,
    '1400_820': 32.4938,
    '1400_821': 32.5026,
    '1400_822': 32.4852,
    '1400_823': 32.4625,
    '1400_824': 32.4443,
    '1400_825': 32.4238,
    '1400_826': 32.4049,
    '1400_827': 32.3871,
    '1400_828': 32.3692,
    '1400_829': 32.3493,
    '1400_830': 32.3277,
    '1400_831': 32.3059,
    '1400_832': 32.28,
    '1400_833': 32.2563,
    '1400_834': 32.2289,
    '1400_835': 32.2096,
    '1400_836': 32.1968,
    '1400_837': 32.1906,
    '1400_838': 32.1844,
    '1400_839': 32.1775,
    '1400_840': 32.1675,
    '1400_841': 32.152,
    '1400_842': 32.1356,
    '1400_843': 32.1134,
    '1400_844': 32.0801,
    '1400_845': 32.0412,
    '1400_846': 32.0044,
    '1400_847': 31.9678,
    '1400_848': 31.9328,
    '1400_849': 31.8993,
    '1400_850': 31.8666,
    '1400_851': 31.8358,
    '1400_852': 31.8076,
    '1400_853': 31.7861,
    '1400_854': 31.7738,
    '1400_855': 31.7675,
    '1400_856': 31.769,
    '1400_857': 31.7777,
    '1400_858': 31.7928,
    '1400_859': 31.8061,
    '1400_860': 31.8139,
    '1400_861': 31.8112,
    '1400_862': 31.7957,
    '1400_863': 31.768,
    '1400_864': 31.7363,
    '1400_865': 31.7034,
    '1400_866': 31.6677,
    '1400_867': 31.6255,
    '1400_868': 31.5817,
    '1400_869': 31.5394,
    '1400_870': 31.5045,
    '1400_871': 31.4795,
    '1400_872': 31.4652,
    '1400_873': 31.4608,
    '1400_874': 31.4639,
    '1400_875': 31.4783,
    '1400_876': 31.5084,
    '1400_877': 31.5481,
    '1400_878': 31.5889,
    '1400_879': 31.632,
    '1400_880': 31.6819,
    '1400_881': 31.7252,
    '1400_882': 31.7664,
    '1400_883': 31.7954,
    '1400_884': 31.8161,
    '1400_885': 31.8421,
    '1400_886': 31.8758,
    '1400_887': 31.9309,
    '1400_888': 31.9958,
    '1400_889': 32.0556,
    '1400_890': 32.1107,
    '1400_891': 32.1611,
    '1400_892': 32.1911,
    '1400_893': 32.1905,
    '1400_894': 32.1528,
    '1400_895': 32.1108,
    '1400_896': 32.1052,
    '1400_897': 32.1395,
    '1400_898': 32.2072,
    '1400_899': 32.2727,
    '1400_900': 32.3308,
    '1400_901': 32.3916,
    '1400_902': 32.4576,
    '1400_903': 32.5163,
    '1400_904': 32.5585,
    '1400_905': 32.5714,
    '1400_906': 32.5632,
    '1400_907': 32.5338,
    '1400_908': 32.4888,
    '1400_909': 32.4357,
    '1400_910': 32.3744,
    '1400_911': 32.3116,
    '1400_912': 32.2417,
    '1400_913': 32.1639,
    '1400_914': 32.083,
    '1400_915': 32.0166,
    '1400_916': 31.9614,
    '1400_917': 31.9061,
    '1400_918': 31.8558,
    '1400_919': 31.8394,
    '1400_920': 31.8319,
    '1400_921': 31.8063,
    '1400_922': 31.767,
    '1400_923': 31.7181,
    '1400_924': 31.6539,
    '1400_925': 31.5729,
    '1400_926': 31.4872,
    '1400_927': 31.3952,
    '1400_928': 31.303,
    '1400_929': 31.2062,
    '1400_930': 31.1078,
    '1400_931': 31.0117,
    '1400_932': 30.9325,
    '1400_933': 30.8706,
    '1400_934': 30.8227,
    '1400_935': 30.7689,
    '1400_936': 30.7276,
    '1400_937': 30.7107,
    '1400_938': 30.7163,
    '1400_939': 30.7329,
    '1400_940': 30.7562,
    '1400_941': 30.7806,
    '1400_942': 30.8098,
    '1400_943': 30.8508,
    '1400_944': 30.9073,
    '1400_945': 30.9687,
    '1400_946': 31.0365,
    '1400_947': 31.1102,
    '1400_948': 31.1846,
    '1400_949': 31.2531,
    '1400_950': 31.3201,
    '1400_951': 31.3767,
    '1400_952': 31.4289,
    '1400_953': 31.4833,
    '1400_954': 31.5414,
    '1400_955': 31.607,
    '1400_956': 31.6712,
    '1400_957': 31.7288,
    '1400_958': 31.7741,
    '1400_959': 31.806,
    '1400_960': 31.8239,
    '1400_961': 31.8218,
    '1400_962': 31.796,
    '1400_963': 31.7542,
    '1400_964': 31.6953,
    '1400_965': 31.6377,
    '1400_966': 31.5726,
    '1400_967': 31.4932,
    '1400_968': 31.4151,
    '1400_969': 31.3447,
    '1400_970': 31.2805,
    '1400_971': 31.2252,
    '1400_972': 31.1783,
    '1400_973': 31.1441,
    '1400_974': 31.1162,
    '1400_975': 31.0935,
    '1400_976': 31.0746,
    '1400_977': 31.0634,
    '1400_978': 31.0591,
    '1400_979': 31.0609,
    '1400_980': 31.0709,
    '1400_981': 31.0893,
    '1400_982': 31.111,
    '1400_983': 31.1322,
    '1400_984': 31.1519,
    '1400_985': 31.176,
    '1400_986': 31.2037,
    '1400_987': 31.231,
    '1400_988': 31.256,
    '1400_989': 31.2784,
    '1400_990': 31.3011,
    '1400_991': 31.3243,
    '1400_992': 31.3472,
    '1400_993': 31.3684,
    '1400_994': 31.3884,
    '1400_995': 31.4061,
    '1400_996': 31.4206,
    '1400_997': 31.4334,
    '1400_998': 31.4463,
    '1400_999': 31.4575,
    '1400_1000': 31.4667,
    '1400_1001': 31.4733,
    '1400_1002': 31.4779,
    '1400_1003': 31.4814,
    '1400_1004': 31.485,
    '1400_1005': 31.49,
    '1400_1006': 31.4951,
    '1400_1007': 31.4972,
    '1400_1008': 31.496,
    '1400_1009': 31.4926,
    '1400_1010': 31.4868,
    '1400_1011': 31.479,
    '1400_1012': 31.4681,
    '1400_1013': 31.4509,
    '1400_1014': 31.4279,
    '1400_1015': 31.3999,
    '1400_1016': 31.367,
    '1400_1017': 31.3321,
    '1400_1018': 31.2934,
    '1400_1019': 31.2515,
    '1400_1020': 31.2064,
    '1400_1021': 31.1509,
    '1400_1022': 31.0856,
    '1400_1023': 31.0108,
    '1400_1024': 30.9251,
    '1400_1025': 30.8253,
    '1400_1026': 30.7187,
    '1400_1027': 30.6031,
    '1400_1028': 30.475,
    '1400_1029': 30.3422,
    '1400_1030': 30.2051,
    '1400_1031': 30.0522,
    '1400_1032': 29.8978,
    '1400_1033': 29.7377,
    '1401_806': 31.4338,
    '1401_807': 31.5338,
    '1401_808': 31.6426,
    '1401_809': 31.7586,
    '1401_810': 31.8743,
    '1401_811': 31.9763,
    '1401_812': 32.0676,
    '1401_813': 32.1269,
    '1401_814': 32.1684,
    '1401_815': 32.1988,
    '1401_816': 32.2346,
    '1401_817': 32.2749,
    '1401_818': 32.3238,
    '1401_819': 32.3777,
    '1401_820': 32.413,
    '1401_821': 32.4177,
    '1401_822': 32.4014,
    '1401_823': 32.3862,
    '1401_824': 32.3657,
    '1401_825': 32.3429,
    '1401_826': 32.3243,
    '1401_827': 32.3084,
    '1401_828': 32.2952,
    '1401_829': 32.2812,
    '1401_830': 32.2634,
    '1401_831': 32.2393,
    '1401_832': 32.2126,
    '1401_833': 32.188,
    '1401_834': 32.1635,
    '1401_835': 32.1489,
    '1401_836': 32.141,
    '1401_837': 32.1381,
    '1401_838': 32.1342,
    '1401_839': 32.1295,
    '1401_840': 32.1192,
    '1401_841': 32.0998,
    '1401_842': 32.0835,
    '1401_843': 32.0635,
    '1401_844': 32.0351,
    '1401_845': 32.0044,
    '1401_846': 31.9723,
    '1401_847': 31.9401,
    '1401_848': 31.9109,
    '1401_849': 31.8835,
    '1401_850': 31.8565,
    '1401_851': 31.8311,
    '1401_852': 31.8098,
    '1401_853': 31.7965,
    '1401_854': 31.7912,
    '1401_855': 31.7927,
    '1401_856': 31.8001,
    '1401_857': 31.8159,
    '1401_858': 31.8377,
    '1401_859': 31.8553,
    '1401_860': 31.8595,
    '1401_861': 31.8558,
    '1401_862': 31.8392,
    '1401_863': 31.8144,
    '1401_864': 31.7906,
    '1401_865': 31.7658,
    '1401_866': 31.7391,
    '1401_867': 31.7013,
    '1401_868': 31.658,
    '1401_869': 31.6136,
    '1401_870': 31.5738,
    '1401_871': 31.5432,
    '1401_872': 31.5232,
    '1401_873': 31.5126,
    '1401_874': 31.5091,
    '1401_875': 31.5167,
    '1401_876': 31.541,
    '1401_877': 31.5799,
    '1401_878': 31.6184,
    '1401_879': 31.6612,
    '1401_880': 31.7106,
    '1401_881': 31.7565,
    '1401_882': 31.792,
    '1401_883': 31.8153,
    '1401_884': 31.8315,
    '1401_885': 31.852,
    '1401_886': 31.8769,
    '1401_887': 31.9214,
    '1401_888': 31.9766,
    '1401_889': 32.0326,
    '1401_890': 32.0878,
    '1401_891': 32.1363,
    '1401_892': 32.1607,
    '1401_893': 32.1642,
    '1401_894': 32.1357,
    '1401_895': 32.1049,
    '1401_896': 32.0961,
    '1401_897': 32.1203,
    '1401_898': 32.1815,
    '1401_899': 32.2546,
    '1401_900': 32.3201,
    '1401_901': 32.3888,
    '1401_902': 32.4637,
    '1401_903': 32.5349,
    '1401_904': 32.5885,
    '1401_905': 32.6102,
    '1401_906': 32.6092,
    '1401_907': 32.5841,
    '1401_908': 32.542,
    '1401_909': 32.482,
    '1401_910': 32.4174,
    '1401_911': 32.3484,
    '1401_912': 32.2809,
    '1401_913': 32.2062,
    '1401_914': 32.1238,
    '1401_915': 32.0543,
    '1401_916': 32.0039,
    '1401_917': 31.958,
    '1401_918': 31.9208,
    '1401_919': 31.9077,
    '1401_920': 31.9068,
    '1401_921': 31.8946,
    '1401_922': 31.8601,
    '1401_923': 31.8132,
    '1401_924': 31.7457,
    '1401_925': 31.6601,
    '1401_926': 31.5657,
    '1401_927': 31.4717,
    '1401_928': 31.3787,
    '1401_929': 31.2893,
    '1401_930': 31.1977,
    '1401_931': 31.1089,
    '1401_932': 31.0384,
    '1401_933': 30.9853,
    '1401_934': 30.943,
    '1401_935': 30.8937,
    '1401_936': 30.8534,
    '1401_937': 30.8378,
    '1401_938': 30.839,
    '1401_939': 30.8522,
    '1401_940': 30.8701,
    '1401_941': 30.8884,
    '1401_942': 30.911,
    '1401_943': 30.9481,
    '1401_944': 30.9982,
    '1401_945': 31.0446,
    '1401_946': 31.1036,
    '1401_947': 31.173,
    '1401_948': 31.2453,
    '1401_949': 31.32,
    '1401_950': 31.3849,
    '1401_951': 31.4331,
    '1401_952': 31.4775,
    '1401_953': 31.5263,
    '1401_954': 31.5783,
    '1401_955': 31.637,
    '1401_956': 31.699,
    '1401_957': 31.7599,
    '1401_958': 31.8129,
    '1401_959': 31.8543,
    '1401_960': 31.8809,
    '1401_961': 31.8847,
    '1401_962': 31.8649,
    '1401_963': 31.8275,
    '1401_964': 31.7726,
    '1401_965': 31.7138,
    '1401_966': 31.6504,
    '1401_967': 31.5773,
    '1401_968': 31.4979,
    '1401_969': 31.4234,
    '1401_970': 31.3593,
    '1401_971': 31.3,
    '1401_972': 31.2497,
    '1401_973': 31.2084,
    '1401_974': 31.1733,
    '1401_975': 31.1447,
    '1401_976': 31.1207,
    '1401_977': 31.102,
    '1401_978': 31.0929,
    '1401_979': 31.0928,
    '1401_980': 31.0988,
    '1401_981': 31.113,
    '1401_982': 31.129,
    '1401_983': 31.1448,
    '1401_984': 31.1637,
    '1401_985': 31.1849,
    '1401_986': 31.2089,
    '1401_987': 31.2352,
    '1401_988': 31.2581,
    '1401_989': 31.2796,
    '1401_990': 31.3004,
    '1401_991': 31.3213,
    '1401_992': 31.3417,
    '1401_993': 31.3609,
    '1401_994': 31.3789,
    '1401_995': 31.3955,
    '1401_996': 31.4094,
    '1401_997': 31.4227,
    '1401_998': 31.4362,
    '1401_999': 31.4489,
    '1401_1000': 31.4598,
    '1401_1001': 31.4677,
    '1401_1002': 31.4728,
    '1401_1003': 31.4763,
    '1401_1004': 31.4785,
    '1401_1005': 31.4804,
    '1401_1006': 31.4815,
    '1401_1007': 31.4812,
    '1401_1008': 31.4776,
    '1401_1009': 31.4729,
    '1401_1010': 31.4689,
    '1401_1011': 31.4648,
    '1401_1012': 31.4612,
    '1401_1013': 31.455,
    '1401_1014': 31.4435,
    '1401_1015': 31.4246,
    '1401_1016': 31.4054,
    '1401_1017': 31.382,
    '1401_1018': 31.3539,
    '1401_1019': 31.3222,
    '1401_1020': 31.2851,
    '1401_1021': 31.2408,
    '1401_1022': 31.1906,
    '1401_1023': 31.1302,
    '1401_1024': 31.0604,
    '1401_1025': 30.9777,
    '1401_1026': 30.8853,
    '1401_1027': 30.7818,
    '1401_1028': 30.6675,
    '1401_1029': 30.5452,
    '1401_1030': 30.4143,
    '1401_1031': 30.2735,
    '1401_1032': 30.1237,
    '1401_1033': 29.9732,
    '1402_806': 31.385,
    '1402_807': 31.48,
    '1402_808': 31.5797,
    '1402_809': 31.6828,
    '1402_810': 31.786,
    '1402_811': 31.8884,
    '1402_812': 32.0003,
    '1402_813': 32.0683,
    '1402_814': 32.1135,
    '1402_815': 32.1315,
    '1402_816': 32.1639,
    '1402_817': 32.1992,
    '1402_818': 32.2479,
    '1402_819': 32.2982,
    '1402_820': 32.3275,
    '1402_821': 32.33,
    '1402_822': 32.3214,
    '1402_823': 32.309,
    '1402_824': 32.2886,
    '1402_825': 32.2664,
    '1402_826': 32.2439,
    '1402_827': 32.2238,
    '1402_828': 32.2106,
    '1402_829': 32.1999,
    '1402_830': 32.1904,
    '1402_831': 32.1735,
    '1402_832': 32.146,
    '1402_833': 32.1232,
    '1402_834': 32.1067,
    '1402_835': 32.0951,
    '1402_836': 32.0861,
    '1402_837': 32.082,
    '1402_838': 32.0815,
    '1402_839': 32.0798,
    '1402_840': 32.0699,
    '1402_841': 32.0485,
    '1402_842': 32.025,
    '1402_843': 32.0052,
    '1402_844': 31.9865,
    '1402_845': 31.9671,
    '1402_846': 31.9406,
    '1402_847': 31.9117,
    '1402_848': 31.8881,
    '1402_849': 31.867,
    '1402_850': 31.8453,
    '1402_851': 31.8254,
    '1402_852': 31.8098,
    '1402_853': 31.8043,
    '1402_854': 31.807,
    '1402_855': 31.8166,
    '1402_856': 31.8318,
    '1402_857': 31.8545,
    '1402_858': 31.8812,
    '1402_859': 31.9,
    '1402_860': 31.9075,
    '1402_861': 31.9028,
    '1402_862': 31.885,
    '1402_863': 31.8618,
    '1402_864': 31.8449,
    '1402_865': 31.8306,
    '1402_866': 31.8149,
    '1402_867': 31.7875,
    '1402_868': 31.7465,
    '1402_869': 31.6974,
    '1402_870': 31.6508,
    '1402_871': 31.6116,
    '1402_872': 31.5828,
    '1402_873': 31.564,
    '1402_874': 31.5526,
    '1402_875': 31.5524,
    '1402_876': 31.57,
    '1402_877': 31.6009,
    '1402_878': 31.6401,
    '1402_879': 31.6834,
    '1402_880': 31.7298,
    '1402_881': 31.7779,
    '1402_882': 31.8138,
    '1402_883': 31.835,
    '1402_884': 31.8497,
    '1402_885': 31.8632,
    '1402_886': 31.8757,
    '1402_887': 31.903,
    '1402_888': 31.9499,
    '1402_889': 32.0064,
    '1402_890': 32.0685,
    '1402_891': 32.12,
    '1402_892': 32.1449,
    '1402_893': 32.1427,
    '1402_894': 32.1228,
    '1402_895': 32.1062,
    '1402_896': 32.095,
    '1402_897': 32.1093,
    '1402_898': 32.1594,
    '1402_899': 32.2336,
    '1402_900': 32.3056,
    '1402_901': 32.3807,
    '1402_902': 32.4641,
    '1402_903': 32.5453,
    '1402_904': 32.6061,
    '1402_905': 32.6419,
    '1402_906': 32.6477,
    '1402_907': 32.6315,
    '1402_908': 32.5883,
    '1402_909': 32.5254,
    '1402_910': 32.4543,
    '1402_911': 32.3814,
    '1402_912': 32.3173,
    '1402_913': 32.2466,
    '1402_914': 32.1676,
    '1402_915': 32.0922,
    '1402_916': 32.0455,
    '1402_917': 32.0154,
    '1402_918': 31.9924,
    '1402_919': 31.982,
    '1402_920': 31.9844,
    '1402_921': 31.9775,
    '1402_922': 31.95,
    '1402_923': 31.9,
    '1402_924': 31.8251,
    '1402_925': 31.7362,
    '1402_926': 31.6322,
    '1402_927': 31.5261,
    '1402_928': 31.4333,
    '1402_929': 31.3588,
    '1402_930': 31.2843,
    '1402_931': 31.2059,
    '1402_932': 31.1405,
    '1402_933': 31.0922,
    '1402_934': 31.0563,
    '1402_935': 31.0168,
    '1402_936': 30.9879,
    '1402_937': 30.9788,
    '1402_938': 30.9794,
    '1402_939': 30.9846,
    '1402_940': 30.992,
    '1402_941': 30.9983,
    '1402_942': 31.015,
    '1402_943': 31.0493,
    '1402_944': 31.0902,
    '1402_945': 31.1228,
    '1402_946': 31.1693,
    '1402_947': 31.2312,
    '1402_948': 31.3032,
    '1402_949': 31.3807,
    '1402_950': 31.4452,
    '1402_951': 31.488,
    '1402_952': 31.5239,
    '1402_953': 31.5688,
    '1402_954': 31.6148,
    '1402_955': 31.664,
    '1402_956': 31.7194,
    '1402_957': 31.7781,
    '1402_958': 31.8355,
    '1402_959': 31.8851,
    '1402_960': 31.9164,
    '1402_961': 31.928,
    '1402_962': 31.9151,
    '1402_963': 31.8797,
    '1402_964': 31.8235,
    '1402_965': 31.7663,
    '1402_966': 31.7098,
    '1402_967': 31.6485,
    '1402_968': 31.5753,
    '1402_969': 31.5044,
    '1402_970': 31.4393,
    '1402_971': 31.3788,
    '1402_972': 31.323,
    '1402_973': 31.2769,
    '1402_974': 31.2368,
    '1402_975': 31.2015,
    '1402_976': 31.1725,
    '1402_977': 31.1483,
    '1402_978': 31.1372,
    '1402_979': 31.1331,
    '1402_980': 31.1351,
    '1402_981': 31.1426,
    '1402_982': 31.1517,
    '1402_983': 31.1659,
    '1402_984': 31.1826,
    '1402_985': 31.1991,
    '1402_986': 31.2195,
    '1402_987': 31.243,
    '1402_988': 31.2637,
    '1402_989': 31.2829,
    '1402_990': 31.3013,
    '1402_991': 31.3192,
    '1402_992': 31.3371,
    '1402_993': 31.3538,
    '1402_994': 31.37,
    '1402_995': 31.3854,
    '1402_996': 31.3986,
    '1402_997': 31.4113,
    '1402_998': 31.4248,
    '1402_999': 31.4376,
    '1402_1000': 31.4492,
    '1402_1001': 31.4586,
    '1402_1002': 31.4657,
    '1402_1003': 31.4693,
    '1402_1004': 31.4699,
    '1402_1005': 31.4683,
    '1402_1006': 31.4661,
    '1402_1007': 31.4631,
    '1402_1008': 31.4582,
    '1402_1009': 31.4523,
    '1402_1010': 31.4497,
    '1402_1011': 31.4474,
    '1402_1012': 31.4505,
    '1402_1013': 31.4535,
    '1402_1014': 31.4499,
    '1402_1015': 31.4443,
    '1402_1016': 31.4363,
    '1402_1017': 31.4233,
    '1402_1018': 31.4052,
    '1402_1019': 31.3827,
    '1402_1020': 31.3548,
    '1402_1021': 31.3203,
    '1402_1022': 31.2814,
    '1402_1023': 31.2328,
    '1402_1024': 31.1757,
    '1402_1025': 31.1086,
    '1402_1026': 31.0306,
    '1402_1027': 30.9383,
    '1402_1028': 30.8356,
    '1402_1029': 30.7241,
    '1402_1030': 30.603,
    '1402_1031': 30.4734,
    '1402_1032': 30.3337,
    '1402_1033': 30.1863,
    '1403_806': 31.3304,
    '1403_807': 31.4195,
    '1403_808': 31.51,
    '1403_809': 31.6009,
    '1403_810': 31.6921,
    '1403_811': 31.7857,
    '1403_812': 31.8844,
    '1403_813': 31.9747,
    '1403_814': 32.0475,
    '1403_815': 32.0653,
    '1403_816': 32.0965,
    '1403_817': 32.129,
    '1403_818': 32.1752,
    '1403_819': 32.2173,
    '1403_820': 32.2393,
    '1403_821': 32.2458,
    '1403_822': 32.2406,
    '1403_823': 32.2251,
    '1403_824': 32.2028,
    '1403_825': 32.1815,
    '1403_826': 32.1596,
    '1403_827': 32.1425,
    '1403_828': 32.1324,
    '1403_829': 32.1226,
    '1403_830': 32.113,
    '1403_831': 32.0975,
    '1403_832': 32.0761,
    '1403_833': 32.0589,
    '1403_834': 32.0482,
    '1403_835': 32.0427,
    '1403_836': 32.0351,
    '1403_837': 32.0288,
    '1403_838': 32.0243,
    '1403_839': 32.0178,
    '1403_840': 32.0063,
    '1403_841': 31.988,
    '1403_842': 31.9706,
    '1403_843': 31.9555,
    '1403_844': 31.9412,
    '1403_845': 31.9279,
    '1403_846': 31.9095,
    '1403_847': 31.8862,
    '1403_848': 31.8655,
    '1403_849': 31.8488,
    '1403_850': 31.8332,
    '1403_851': 31.8195,
    '1403_852': 31.8108,
    '1403_853': 31.8112,
    '1403_854': 31.8215,
    '1403_855': 31.8397,
    '1403_856': 31.8636,
    '1403_857': 31.8941,
    '1403_858': 31.9271,
    '1403_859': 31.9494,
    '1403_860': 31.9571,
    '1403_861': 31.9508,
    '1403_862': 31.9314,
    '1403_863': 31.9126,
    '1403_864': 31.9046,
    '1403_865': 31.9008,
    '1403_866': 31.8935,
    '1403_867': 31.8775,
    '1403_868': 31.8436,
    '1403_869': 31.7907,
    '1403_870': 31.7357,
    '1403_871': 31.6862,
    '1403_872': 31.6456,
    '1403_873': 31.615,
    '1403_874': 31.5938,
    '1403_875': 31.5851,
    '1403_876': 31.5956,
    '1403_877': 31.6211,
    '1403_878': 31.6543,
    '1403_879': 31.6922,
    '1403_880': 31.74,
    '1403_881': 31.7911,
    '1403_882': 31.8298,
    '1403_883': 31.8492,
    '1403_884': 31.8593,
    '1403_885': 31.8643,
    '1403_886': 31.8679,
    '1403_887': 31.8857,
    '1403_888': 31.9294,
    '1403_889': 31.9854,
    '1403_890': 32.0481,
    '1403_891': 32.1008,
    '1403_892': 32.1292,
    '1403_893': 32.1321,
    '1403_894': 32.1215,
    '1403_895': 32.1181,
    '1403_896': 32.1027,
    '1403_897': 32.1081,
    '1403_898': 32.1443,
    '1403_899': 32.2107,
    '1403_900': 32.2869,
    '1403_901': 32.3649,
    '1403_902': 32.4532,
    '1403_903': 32.5435,
    '1403_904': 32.607,
    '1403_905': 32.644,
    '1403_906': 32.667,
    '1403_907': 32.6634,
    '1403_908': 32.6175,
    '1403_909': 32.5666,
    '1403_910': 32.4862,
    '1403_911': 32.4114,
    '1403_912': 32.3477,
    '1403_913': 32.2794,
    '1403_914': 32.208,
    '1403_915': 32.1392,
    '1403_916': 32.1033,
    '1403_917': 32.0862,
    '1403_918': 32.0689,
    '1403_919': 32.0581,
    '1403_920': 32.0597,
    '1403_921': 32.0511,
    '1403_922': 32.0224,
    '1403_923': 31.9603,
    '1403_924': 31.8837,
    '1403_925': 31.7962,
    '1403_926': 31.6809,
    '1403_927': 31.5713,
    '1403_928': 31.486,
    '1403_929': 31.4271,
    '1403_930': 31.367,
    '1403_931': 31.3001,
    '1403_932': 31.2395,
    '1403_933': 31.1982,
    '1403_934': 31.1712,
    '1403_935': 31.1453,
    '1403_936': 31.1329,
    '1403_937': 31.1317,
    '1403_938': 31.135,
    '1403_939': 31.1346,
    '1403_940': 31.1285,
    '1403_941': 31.1197,
    '1403_942': 31.1229,
    '1403_943': 31.1474,
    '1403_944': 31.1744,
    '1403_945': 31.2013,
    '1403_946': 31.2376,
    '1403_947': 31.2867,
    '1403_948': 31.3555,
    '1403_949': 31.435,
    '1403_950': 31.4965,
    '1403_951': 31.5375,
    '1403_952': 31.5699,
    '1403_953': 31.6082,
    '1403_954': 31.6533,
    '1403_955': 31.6945,
    '1403_956': 31.7374,
    '1403_957': 31.7877,
    '1403_958': 31.8435,
    '1403_959': 31.8987,
    '1403_960': 31.9332,
    '1403_961': 31.9452,
    '1403_962': 31.9388,
    '1403_963': 31.9053,
    '1403_964': 31.8561,
    '1403_965': 31.8031,
    '1403_966': 31.7544,
    '1403_967': 31.7086,
    '1403_968': 31.6487,
    '1403_969': 31.5861,
    '1403_970': 31.5239,
    '1403_971': 31.4646,
    '1403_972': 31.4055,
    '1403_973': 31.3531,
    '1403_974': 31.305,
    '1403_975': 31.2643,
    '1403_976': 31.2299,
    '1403_977': 31.2017,
    '1403_978': 31.186,
    '1403_979': 31.1787,
    '1403_980': 31.177,
    '1403_981': 31.1775,
    '1403_982': 31.1833,
    '1403_983': 31.1956,
    '1403_984': 31.2075,
    '1403_985': 31.2186,
    '1403_986': 31.2362,
    '1403_987': 31.2555,
    '1403_988': 31.2731,
    '1403_989': 31.2889,
    '1403_990': 31.304,
    '1403_991': 31.319,
    '1403_992': 31.3336,
    '1403_993': 31.3478,
    '1403_994': 31.3621,
    '1403_995': 31.3763,
    '1403_996': 31.3888,
    '1403_997': 31.4013,
    '1403_998': 31.4146,
    '1403_999': 31.427,
    '1403_1000': 31.4389,
    '1403_1001': 31.4485,
    '1403_1002': 31.4555,
    '1403_1003': 31.4591,
    '1403_1004': 31.459,
    '1403_1005': 31.4564,
    '1403_1006': 31.453,
    '1403_1007': 31.4485,
    '1403_1008': 31.4441,
    '1403_1009': 31.4396,
    '1403_1010': 31.4373,
    '1403_1011': 31.437,
    '1403_1012': 31.4426,
    '1403_1013': 31.449,
    '1403_1014': 31.4563,
    '1403_1015': 31.4602,
    '1403_1016': 31.4616,
    '1403_1017': 31.4578,
    '1403_1018': 31.4484,
    '1403_1019': 31.4345,
    '1403_1020': 31.415,
    '1403_1021': 31.3887,
    '1403_1022': 31.3561,
    '1403_1023': 31.3175,
    '1403_1024': 31.2729,
    '1403_1025': 31.2176,
    '1403_1026': 31.1526,
    '1403_1027': 31.0715,
    '1403_1028': 30.9789,
    '1403_1029': 30.8776,
    '1403_1030': 30.7675,
    '1403_1031': 30.6484,
    '1403_1032': 30.52,
    '1403_1033': 30.3826,
    '1404_806': 31.2755,
    '1404_807': 31.3596,
    '1404_808': 31.4422,
    '1404_809': 31.5218,
    '1404_810': 31.5986,
    '1404_811': 31.6752,
    '1404_812': 31.7527,
    '1404_813': 31.8431,
    '1404_814': 31.9158,
    '1404_815': 31.959,
    '1404_816': 32.0255,
    '1404_817': 32.0719,
    '1404_818': 32.1165,
    '1404_819': 32.1502,
    '1404_820': 32.1588,
    '1404_821': 32.1543,
    '1404_822': 32.145,
    '1404_823': 32.127,
    '1404_824': 32.1027,
    '1404_825': 32.0825,
    '1404_826': 32.0649,
    '1404_827': 32.0525,
    '1404_828': 32.0464,
    '1404_829': 32.0404,
    '1404_830': 32.0333,
    '1404_831': 32.0217,
    '1404_832': 32.0063,
    '1404_833': 31.9932,
    '1404_834': 31.9872,
    '1404_835': 31.9879,
    '1404_836': 31.9816,
    '1404_837': 31.9744,
    '1404_838': 31.9675,
    '1404_839': 31.9571,
    '1404_840': 31.9447,
    '1404_841': 31.9307,
    '1404_842': 31.9179,
    '1404_843': 31.9072,
    '1404_844': 31.8977,
    '1404_845': 31.8888,
    '1404_846': 31.8759,
    '1404_847': 31.8606,
    '1404_848': 31.8461,
    '1404_849': 31.8341,
    '1404_850': 31.8237,
    '1404_851': 31.8156,
    '1404_852': 31.8124,
    '1404_853': 31.8178,
    '1404_854': 31.8363,
    '1404_855': 31.8635,
    '1404_856': 31.8969,
    '1404_857': 31.936,
    '1404_858': 31.9743,
    '1404_859': 31.9995,
    '1404_860': 32.009,
    '1404_861': 32.0033,
    '1404_862': 31.9853,
    '1404_863': 31.9696,
    '1404_864': 31.9675,
    '1404_865': 31.9728,
    '1404_866': 31.9736,
    '1404_867': 31.9652,
    '1404_868': 31.9442,
    '1404_869': 31.8946,
    '1404_870': 31.8271,
    '1404_871': 31.7635,
    '1404_872': 31.7084,
    '1404_873': 31.6648,
    '1404_874': 31.6332,
    '1404_875': 31.6166,
    '1404_876': 31.6187,
    '1404_877': 31.639,
    '1404_878': 31.6643,
    '1404_879': 31.7011,
    '1404_880': 31.744,
    '1404_881': 31.7963,
    '1404_882': 31.8366,
    '1404_883': 31.8554,
    '1404_884': 31.8613,
    '1404_885': 31.8609,
    '1404_886': 31.8604,
    '1404_887': 31.8707,
    '1404_888': 31.915,
    '1404_889': 31.9638,
    '1404_890': 32.0174,
    '1404_891': 32.0703,
    '1404_892': 32.1021,
    '1404_893': 32.1121,
    '1404_894': 32.1193,
    '1404_895': 32.1301,
    '1404_896': 32.1166,
    '1404_897': 32.1151,
    '1404_898': 32.1385,
    '1404_899': 32.1934,
    '1404_900': 32.2661,
    '1404_901': 32.3429,
    '1404_902': 32.4267,
    '1404_903': 32.5166,
    '1404_904': 32.5925,
    '1404_905': 32.6346,
    '1404_906': 32.6655,
    '1404_907': 32.6666,
    '1404_908': 32.6466,
    '1404_909': 32.5944,
    '1404_910': 32.5103,
    '1404_911': 32.4328,
    '1404_912': 32.369,
    '1404_913': 32.3081,
    '1404_914': 32.2454,
    '1404_915': 32.1894,
    '1404_916': 32.1673,
    '1404_917': 32.1564,
    '1404_918': 32.1453,
    '1404_919': 32.135,
    '1404_920': 32.1326,
    '1404_921': 32.121,
    '1404_922': 32.0846,
    '1404_923': 32.0285,
    '1404_924': 31.94,
    '1404_925': 31.8419,
    '1404_926': 31.7271,
    '1404_927': 31.621,
    '1404_928': 31.5467,
    '1404_929': 31.4988,
    '1404_930': 31.448,
    '1404_931': 31.3897,
    '1404_932': 31.3377,
    '1404_933': 31.3029,
    '1404_934': 31.285,
    '1404_935': 31.278,
    '1404_936': 31.28,
    '1404_937': 31.2883,
    '1404_938': 31.2918,
    '1404_939': 31.2863,
    '1404_940': 31.2704,
    '1404_941': 31.2493,
    '1404_942': 31.2353,
    '1404_943': 31.2404,
    '1404_944': 31.2558,
    '1404_945': 31.2769,
    '1404_946': 31.3069,
    '1404_947': 31.3494,
    '1404_948': 31.4097,
    '1404_949': 31.4804,
    '1404_950': 31.5387,
    '1404_951': 31.5803,
    '1404_952': 31.616,
    '1404_953': 31.6484,
    '1404_954': 31.6862,
    '1404_955': 31.723,
    '1404_956': 31.761,
    '1404_957': 31.8015,
    '1404_958': 31.8463,
    '1404_959': 31.8957,
    '1404_960': 31.9384,
    '1404_961': 31.954,
    '1404_962': 31.9448,
    '1404_963': 31.9148,
    '1404_964': 31.8744,
    '1404_965': 31.8304,
    '1404_966': 31.7907,
    '1404_967': 31.7613,
    '1404_968': 31.7204,
    '1404_969': 31.6707,
    '1404_970': 31.615,
    '1404_971': 31.558,
    '1404_972': 31.4965,
    '1404_973': 31.4372,
    '1404_974': 31.3817,
    '1404_975': 31.3342,
    '1404_976': 31.2957,
    '1404_977': 31.2648,
    '1404_978': 31.2449,
    '1404_979': 31.2311,
    '1404_980': 31.2225,
    '1404_981': 31.2187,
    '1404_982': 31.2242,
    '1404_983': 31.2329,
    '1404_984': 31.2416,
    '1404_985': 31.2495,
    '1404_986': 31.263,
    '1404_987': 31.2767,
    '1404_988': 31.2892,
    '1404_989': 31.3003,
    '1404_990': 31.3113,
    '1404_991': 31.3226,
    '1404_992': 31.3343,
    '1404_993': 31.3461,
    '1404_994': 31.3584,
    '1404_995': 31.3708,
    '1404_996': 31.3821,
    '1404_997': 31.3938,
    '1404_998': 31.4062,
    '1404_999': 31.4183,
    '1404_1000': 31.4294,
    '1404_1001': 31.4387,
    '1404_1002': 31.4454,
    '1404_1003': 31.4492,
    '1404_1004': 31.4498,
    '1404_1005': 31.4474,
    '1404_1006': 31.4439,
    '1404_1007': 31.4397,
    '1404_1008': 31.4357,
    '1404_1009': 31.4326,
    '1404_1010': 31.432,
    '1404_1011': 31.4342,
    '1404_1012': 31.4427,
    '1404_1013': 31.4523,
    '1404_1014': 31.4651,
    '1404_1015': 31.4758,
    '1404_1016': 31.4847,
    '1404_1017': 31.4887,
    '1404_1018': 31.4871,
    '1404_1019': 31.4808,
    '1404_1020': 31.4685,
    '1404_1021': 31.4492,
    '1404_1022': 31.4236,
    '1404_1023': 31.3921,
    '1404_1024': 31.3532,
    '1404_1025': 31.3076,
    '1404_1026': 31.25,
    '1404_1027': 31.1794,
    '1404_1028': 31.0979,
    '1404_1029': 31.0075,
    '1404_1030': 30.9083,
    '1404_1031': 30.7993,
    '1404_1032': 30.6821,
    '1404_1033': 30.5565,
    '1405_806': 31.2247,
    '1405_807': 31.3055,
    '1405_808': 31.3829,
    '1405_809': 31.4563,
    '1405_810': 31.5207,
    '1405_811': 31.5843,
    '1405_812': 31.6515,
    '1405_813': 31.7303,
    '1405_814': 31.8036,
    '1405_815': 31.8678,
    '1405_816': 31.9367,
    '1405_817': 31.9943,
    '1405_818': 32.0429,
    '1405_819': 32.0762,
    '1405_820': 32.0687,
    '1405_821': 32.0541,
    '1405_822': 32.035,
    '1405_823': 32.015,
    '1405_824': 31.9928,
    '1405_825': 31.9743,
    '1405_826': 31.9606,
    '1405_827': 31.9529,
    '1405_828': 31.9532,
    '1405_829': 31.9513,
    '1405_830': 31.9486,
    '1405_831': 31.9421,
    '1405_832': 31.9328,
    '1405_833': 31.9261,
    '1405_834': 31.9253,
    '1405_835': 31.9253,
    '1405_836': 31.9201,
    '1405_837': 31.9133,
    '1405_838': 31.9041,
    '1405_839': 31.8937,
    '1405_840': 31.8828,
    '1405_841': 31.8726,
    '1405_842': 31.8639,
    '1405_843': 31.857,
    '1405_844': 31.8519,
    '1405_845': 31.8468,
    '1405_846': 31.8407,
    '1405_847': 31.8336,
    '1405_848': 31.8264,
    '1405_849': 31.8201,
    '1405_850': 31.8152,
    '1405_851': 31.8126,
    '1405_852': 31.8148,
    '1405_853': 31.8256,
    '1405_854': 31.8513,
    '1405_855': 31.8868,
    '1405_856': 31.9301,
    '1405_857': 31.9815,
    '1405_858': 32.0321,
    '1405_859': 32.0574,
    '1405_860': 32.0587,
    '1405_861': 32.0571,
    '1405_862': 32.0466,
    '1405_863': 32.0319,
    '1405_864': 32.0306,
    '1405_865': 32.0405,
    '1405_866': 32.05,
    '1405_867': 32.0513,
    '1405_868': 32.0394,
    '1405_869': 31.9959,
    '1405_870': 31.9185,
    '1405_871': 31.8385,
    '1405_872': 31.767,
    '1405_873': 31.7096,
    '1405_874': 31.668,
    '1405_875': 31.6432,
    '1405_876': 31.6367,
    '1405_877': 31.6489,
    '1405_878': 31.6695,
    '1405_879': 31.7025,
    '1405_880': 31.7413,
    '1405_881': 31.7897,
    '1405_882': 31.8299,
    '1405_883': 31.847,
    '1405_884': 31.8497,
    '1405_885': 31.8462,
    '1405_886': 31.8407,
    '1405_887': 31.8527,
    '1405_888': 31.8952,
    '1405_889': 31.9336,
    '1405_890': 31.9752,
    '1405_891': 32.0269,
    '1405_892': 32.0635,
    '1405_893': 32.0867,
    '1405_894': 32.1117,
    '1405_895': 32.1332,
    '1405_896': 32.1317,
    '1405_897': 32.1258,
    '1405_898': 32.1398,
    '1405_899': 32.1864,
    '1405_900': 32.2522,
    '1405_901': 32.3227,
    '1405_902': 32.3975,
    '1405_903': 32.4773,
    '1405_904': 32.5543,
    '1405_905': 32.6159,
    '1405_906': 32.6568,
    '1405_907': 32.6582,
    '1405_908': 32.6351,
    '1405_909': 32.6071,
    '1405_910': 32.5348,
    '1405_911': 32.455,
    '1405_912': 32.387,
    '1405_913': 32.3305,
    '1405_914': 32.2785,
    '1405_915': 32.2383,
    '1405_916': 32.2247,
    '1405_917': 32.2213,
    '1405_918': 32.2132,
    '1405_919': 32.2079,
    '1405_920': 32.2023,
    '1405_921': 32.1788,
    '1405_922': 32.14,
    '1405_923': 32.0726,
    '1405_924': 31.9793,
    '1405_925': 31.8756,
    '1405_926': 31.7699,
    '1405_927': 31.6772,
    '1405_928': 31.6157,
    '1405_929': 31.5717,
    '1405_930': 31.5244,
    '1405_931': 31.4741,
    '1405_932': 31.4328,
    '1405_933': 31.4076,
    '1405_934': 31.3924,
    '1405_935': 31.3935,
    '1405_936': 31.4074,
    '1405_937': 31.4238,
    '1405_938': 31.4329,
    '1405_939': 31.4232,
    '1405_940': 31.4,
    '1405_941': 31.3698,
    '1405_942': 31.3435,
    '1405_943': 31.3325,
    '1405_944': 31.3366,
    '1405_945': 31.3501,
    '1405_946': 31.3734,
    '1405_947': 31.4106,
    '1405_948': 31.4657,
    '1405_949': 31.5247,
    '1405_950': 31.5792,
    '1405_951': 31.6221,
    '1405_952': 31.6587,
    '1405_953': 31.6855,
    '1405_954': 31.7125,
    '1405_955': 31.745,
    '1405_956': 31.7796,
    '1405_957': 31.8158,
    '1405_958': 31.8526,
    '1405_959': 31.8913,
    '1405_960': 31.9289,
    '1405_961': 31.9468,
    '1405_962': 31.9374,
    '1405_963': 31.9103,
    '1405_964': 31.879,
    '1405_965': 31.8475,
    '1405_966': 31.8229,
    '1405_967': 31.8104,
    '1405_968': 31.7909,
    '1405_969': 31.7531,
    '1405_970': 31.7038,
    '1405_971': 31.6483,
    '1405_972': 31.5868,
    '1405_973': 31.5264,
    '1405_974': 31.469,
    '1405_975': 31.4137,
    '1405_976': 31.3681,
    '1405_977': 31.3319,
    '1405_978': 31.3075,
    '1405_979': 31.2867,
    '1405_980': 31.2726,
    '1405_981': 31.2676,
    '1405_982': 31.2735,
    '1405_983': 31.2789,
    '1405_984': 31.2844,
    '1405_985': 31.289,
    '1405_986': 31.2973,
    '1405_987': 31.3044,
    '1405_988': 31.311,
    '1405_989': 31.3167,
    '1405_990': 31.323,
    '1405_991': 31.3299,
    '1405_992': 31.3388,
    '1405_993': 31.3476,
    '1405_994': 31.3573,
    '1405_995': 31.3676,
    '1405_996': 31.3771,
    '1405_997': 31.3878,
    '1405_998': 31.3991,
    '1405_999': 31.4099,
    '1405_1000': 31.4196,
    '1405_1001': 31.428,
    '1405_1002': 31.4343,
    '1405_1003': 31.4382,
    '1405_1004': 31.4402,
    '1405_1005': 31.4391,
    '1405_1006': 31.4369,
    '1405_1007': 31.4342,
    '1405_1008': 31.4315,
    '1405_1009': 31.4301,
    '1405_1010': 31.431,
    '1405_1011': 31.4349,
    '1405_1012': 31.4421,
    '1405_1013': 31.4576,
    '1405_1014': 31.4747,
    '1405_1015': 31.4904,
    '1405_1016': 31.5055,
    '1405_1017': 31.5163,
    '1405_1018': 31.5217,
    '1405_1019': 31.5221,
    '1405_1020': 31.5162,
    '1405_1021': 31.5029,
    '1405_1022': 31.4831,
    '1405_1023': 31.4573,
    '1405_1024': 31.424,
    '1405_1025': 31.3825,
    '1405_1026': 31.332,
    '1405_1027': 31.2708,
    '1405_1028': 31.1999,
    '1405_1029': 31.1199,
    '1405_1030': 31.0307,
    '1405_1031': 30.933,
    '1405_1032': 30.8299,
    '1405_1033': 30.717,
    '1406_806': 31.184,
    '1406_807': 31.2601,
    '1406_808': 31.331,
    '1406_809': 31.3952,
    '1406_810': 31.4509,
    '1406_811': 31.506,
    '1406_812': 31.565,
    '1406_813': 31.6347,
    '1406_814': 31.706,
    '1406_815': 31.7763,
    '1406_816': 31.8432,
    '1406_817': 31.8981,
    '1406_818': 31.937,
    '1406_819': 31.9576,
    '1406_820': 31.9546,
    '1406_821': 31.9394,
    '1406_822': 31.9143,
    '1406_823': 31.8918,
    '1406_824': 31.872,
    '1406_825': 31.8572,
    '1406_826': 31.8488,
    '1406_827': 31.8471,
    '1406_828': 31.8528,
    '1406_829': 31.8507,
    '1406_830': 31.8494,
    '1406_831': 31.8486,
    '1406_832': 31.8487,
    '1406_833': 31.8502,
    '1406_834': 31.8497,
    '1406_835': 31.8503,
    '1406_836': 31.8497,
    '1406_837': 31.8468,
    '1406_838': 31.8387,
    '1406_839': 31.8292,
    '1406_840': 31.8202,
    '1406_841': 31.8136,
    '1406_842': 31.8083,
    '1406_843': 31.8054,
    '1406_844': 31.8042,
    '1406_845': 31.8037,
    '1406_846': 31.8046,
    '1406_847': 31.8055,
    '1406_848': 31.8054,
    '1406_849': 31.8054,
    '1406_850': 31.8061,
    '1406_851': 31.8094,
    '1406_852': 31.8173,
    '1406_853': 31.8333,
    '1406_854': 31.8656,
    '1406_855': 31.9095,
    '1406_856': 31.9652,
    '1406_857': 32.0353,
    '1406_858': 32.0946,
    '1406_859': 32.1171,
    '1406_860': 32.1104,
    '1406_861': 32.1102,
    '1406_862': 32.1103,
    '1406_863': 32.097,
    '1406_864': 32.0921,
    '1406_865': 32.1041,
    '1406_866': 32.1221,
    '1406_867': 32.1316,
    '1406_868': 32.1278,
    '1406_869': 32.0899,
    '1406_870': 32.0103,
    '1406_871': 31.9088,
    '1406_872': 31.8182,
    '1406_873': 31.7463,
    '1406_874': 31.6955,
    '1406_875': 31.6635,
    '1406_876': 31.6497,
    '1406_877': 31.6537,
    '1406_878': 31.6676,
    '1406_879': 31.6917,
    '1406_880': 31.7251,
    '1406_881': 31.7715,
    '1406_882': 31.8096,
    '1406_883': 31.8276,
    '1406_884': 31.8289,
    '1406_885': 31.8214,
    '1406_886': 31.8134,
    '1406_887': 31.831,
    '1406_888': 31.8668,
    '1406_889': 31.8962,
    '1406_890': 31.9328,
    '1406_891': 31.9797,
    '1406_892': 32.0183,
    '1406_893': 32.0568,
    '1406_894': 32.1022,
    '1406_895': 32.1339,
    '1406_896': 32.1416,
    '1406_897': 32.135,
    '1406_898': 32.1456,
    '1406_899': 32.1852,
    '1406_900': 32.2448,
    '1406_901': 32.3075,
    '1406_902': 32.3722,
    '1406_903': 32.4407,
    '1406_904': 32.5096,
    '1406_905': 32.5711,
    '1406_906': 32.621,
    '1406_907': 32.6417,
    '1406_908': 32.6296,
    '1406_909': 32.603,
    '1406_910': 32.5534,
    '1406_911': 32.4798,
    '1406_912': 32.4114,
    '1406_913': 32.359,
    '1406_914': 32.3168,
    '1406_915': 32.2876,
    '1406_916': 32.2758,
    '1406_917': 32.2735,
    '1406_918': 32.2748,
    '1406_919': 32.2721,
    '1406_920': 32.2673,
    '1406_921': 32.2435,
    '1406_922': 32.1931,
    '1406_923': 32.111,
    '1406_924': 32.0116,
    '1406_925': 31.9133,
    '1406_926': 31.8225,
    '1406_927': 31.7491,
    '1406_928': 31.695,
    '1406_929': 31.6469,
    '1406_930': 31.5958,
    '1406_931': 31.5484,
    '1406_932': 31.5223,
    '1406_933': 31.502,
    '1406_934': 31.4849,
    '1406_935': 31.4871,
    '1406_936': 31.5064,
    '1406_937': 31.5295,
    '1406_938': 31.542,
    '1406_939': 31.5368,
    '1406_940': 31.5087,
    '1406_941': 31.4718,
    '1406_942': 31.4372,
    '1406_943': 31.4172,
    '1406_944': 31.4133,
    '1406_945': 31.4199,
    '1406_946': 31.435,
    '1406_947': 31.4706,
    '1406_948': 31.5188,
    '1406_949': 31.5683,
    '1406_950': 31.6198,
    '1406_951': 31.6632,
    '1406_952': 31.6989,
    '1406_953': 31.7233,
    '1406_954': 31.7381,
    '1406_955': 31.7595,
    '1406_956': 31.7888,
    '1406_957': 31.8274,
    '1406_958': 31.8623,
    '1406_959': 31.8952,
    '1406_960': 31.9208,
    '1406_961': 31.9339,
    '1406_962': 31.9261,
    '1406_963': 31.9026,
    '1406_964': 31.8836,
    '1406_965': 31.8711,
    '1406_966': 31.8672,
    '1406_967': 31.864,
    '1406_968': 31.846,
    '1406_969': 31.818,
    '1406_970': 31.7769,
    '1406_971': 31.7244,
    '1406_972': 31.6651,
    '1406_973': 31.6059,
    '1406_974': 31.5473,
    '1406_975': 31.4902,
    '1406_976': 31.4357,
    '1406_977': 31.3927,
    '1406_978': 31.3651,
    '1406_979': 31.3397,
    '1406_980': 31.3238,
    '1406_981': 31.3227,
    '1406_982': 31.3288,
    '1406_983': 31.3317,
    '1406_984': 31.334,
    '1406_985': 31.3337,
    '1406_986': 31.3352,
    '1406_987': 31.3361,
    '1406_988': 31.3366,
    '1406_989': 31.3367,
    '1406_990': 31.3383,
    '1406_991': 31.3415,
    '1406_992': 31.3465,
    '1406_993': 31.3517,
    '1406_994': 31.3582,
    '1406_995': 31.3659,
    '1406_996': 31.3731,
    '1406_997': 31.3822,
    '1406_998': 31.3922,
    '1406_999': 31.4015,
    '1406_1000': 31.4101,
    '1406_1001': 31.4176,
    '1406_1002': 31.423,
    '1406_1003': 31.4265,
    '1406_1004': 31.4288,
    '1406_1005': 31.4298,
    '1406_1006': 31.4301,
    '1406_1007': 31.4295,
    '1406_1008': 31.4285,
    '1406_1009': 31.4286,
    '1406_1010': 31.4314,
    '1406_1011': 31.4375,
    '1406_1012': 31.4452,
    '1406_1013': 31.4628,
    '1406_1014': 31.4829,
    '1406_1015': 31.5028,
    '1406_1016': 31.5232,
    '1406_1017': 31.54,
    '1406_1018': 31.5519,
    '1406_1019': 31.5584,
    '1406_1020': 31.5581,
    '1406_1021': 31.5498,
    '1406_1022': 31.5348,
    '1406_1023': 31.514,
    '1406_1024': 31.4856,
    '1406_1025': 31.4491,
    '1406_1026': 31.4048,
    '1406_1027': 31.3515,
    '1406_1028': 31.2891,
    '1406_1029': 31.2184,
    '1406_1030': 31.1396,
    '1406_1031': 31.0527,
    '1406_1032': 30.9585,
    '1406_1033': 30.8562,
    '1407_806': 31.1493,
    '1407_807': 31.2221,
    '1407_808': 31.2873,
    '1407_809': 31.3445,
    '1407_810': 31.3924,
    '1407_811': 31.4393,
    '1407_812': 31.4882,
    '1407_813': 31.5525,
    '1407_814': 31.6193,
    '1407_815': 31.6891,
    '1407_816': 31.7507,
    '1407_817': 31.7975,
    '1407_818': 31.8236,
    '1407_819': 31.8337,
    '1407_820': 31.8275,
    '1407_821': 31.8098,
    '1407_822': 31.7824,
    '1407_823': 31.7626,
    '1407_824': 31.7462,
    '1407_825': 31.7358,
    '1407_826': 31.7321,
    '1407_827': 31.7314,
    '1407_828': 31.738,
    '1407_829': 31.7401,
    '1407_830': 31.7437,
    '1407_831': 31.7484,
    '1407_832': 31.7554,
    '1407_833': 31.7616,
    '1407_834': 31.7658,
    '1407_835': 31.7709,
    '1407_836': 31.7757,
    '1407_837': 31.7732,
    '1407_838': 31.7703,
    '1407_839': 31.7656,
    '1407_840': 31.7599,
    '1407_841': 31.7578,
    '1407_842': 31.755,
    '1407_843': 31.756,
    '1407_844': 31.7584,
    '1407_845': 31.7617,
    '1407_846': 31.7692,
    '1407_847': 31.7772,
    '1407_848': 31.7829,
    '1407_849': 31.7892,
    '1407_850': 31.7951,
    '1407_851': 31.8047,
    '1407_852': 31.8197,
    '1407_853': 31.8406,
    '1407_854': 31.8797,
    '1407_855': 31.9316,
    '1407_856': 32.0008,
    '1407_857': 32.0855,
    '1407_858': 32.1463,
    '1407_859': 32.167,
    '1407_860': 32.1594,
    '1407_861': 32.1607,
    '1407_862': 32.1667,
    '1407_863': 32.1546,
    '1407_864': 32.1413,
    '1407_865': 32.1534,
    '1407_866': 32.1775,
    '1407_867': 32.1969,
    '1407_868': 32.2006,
    '1407_869': 32.1681,
    '1407_870': 32.0888,
    '1407_871': 31.971,
    '1407_872': 31.8611,
    '1407_873': 31.7769,
    '1407_874': 31.7163,
    '1407_875': 31.6781,
    '1407_876': 31.6578,
    '1407_877': 31.6532,
    '1407_878': 31.6626,
    '1407_879': 31.6776,
    '1407_880': 31.7075,
    '1407_881': 31.7483,
    '1407_882': 31.7795,
    '1407_883': 31.8001,
    '1407_884': 31.8022,
    '1407_885': 31.7902,
    '1407_886': 31.7831,
    '1407_887': 31.799,
    '1407_888': 31.8297,
    '1407_889': 31.8556,
    '1407_890': 31.8906,
    '1407_891': 31.9359,
    '1407_892': 31.9857,
    '1407_893': 32.0414,
    '1407_894': 32.0996,
    '1407_895': 32.1336,
    '1407_896': 32.1418,
    '1407_897': 32.141,
    '1407_898': 32.1526,
    '1407_899': 32.1885,
    '1407_900': 32.2415,
    '1407_901': 32.2939,
    '1407_902': 32.3488,
    '1407_903': 32.4079,
    '1407_904': 32.4676,
    '1407_905': 32.5241,
    '1407_906': 32.5721,
    '1407_907': 32.6102,
    '1407_908': 32.6149,
    '1407_909': 32.5929,
    '1407_910': 32.5704,
    '1407_911': 32.5155,
    '1407_912': 32.4571,
    '1407_913': 32.4063,
    '1407_914': 32.3678,
    '1407_915': 32.3388,
    '1407_916': 32.324,
    '1407_917': 32.3235,
    '1407_918': 32.3246,
    '1407_919': 32.3287,
    '1407_920': 32.3233,
    '1407_921': 32.2869,
    '1407_922': 32.2425,
    '1407_923': 32.1607,
    '1407_924': 32.0583,
    '1407_925': 31.9592,
    '1407_926': 31.8811,
    '1407_927': 31.821,
    '1407_928': 31.774,
    '1407_929': 31.7254,
    '1407_930': 31.6729,
    '1407_931': 31.6294,
    '1407_932': 31.605,
    '1407_933': 31.5842,
    '1407_934': 31.5678,
    '1407_935': 31.5693,
    '1407_936': 31.5899,
    '1407_937': 31.6151,
    '1407_938': 31.6238,
    '1407_939': 31.6197,
    '1407_940': 31.5955,
    '1407_941': 31.5559,
    '1407_942': 31.5169,
    '1407_943': 31.491,
    '1407_944': 31.4828,
    '1407_945': 31.4883,
    '1407_946': 31.4967,
    '1407_947': 31.5272,
    '1407_948': 31.5659,
    '1407_949': 31.6101,
    '1407_950': 31.652,
    '1407_951': 31.6928,
    '1407_952': 31.728,
    '1407_953': 31.7534,
    '1407_954': 31.7666,
    '1407_955': 31.7792,
    '1407_956': 31.8011,
    '1407_957': 31.8352,
    '1407_958': 31.8741,
    '1407_959': 31.9067,
    '1407_960': 31.9223,
    '1407_961': 31.9283,
    '1407_962': 31.9256,
    '1407_963': 31.9181,
    '1407_964': 31.9093,
    '1407_965': 31.9107,
    '1407_966': 31.9163,
    '1407_967': 31.9101,
    '1407_968': 31.8905,
    '1407_969': 31.8653,
    '1407_970': 31.8299,
    '1407_971': 31.7798,
    '1407_972': 31.7188,
    '1407_973': 31.6622,
    '1407_974': 31.6042,
    '1407_975': 31.5418,
    '1407_976': 31.4853,
    '1407_977': 31.4418,
    '1407_978': 31.4156,
    '1407_979': 31.3907,
    '1407_980': 31.3784,
    '1407_981': 31.3837,
    '1407_982': 31.3918,
    '1407_983': 31.393,
    '1407_984': 31.3917,
    '1407_985': 31.386,
    '1407_986': 31.3785,
    '1407_987': 31.3727,
    '1407_988': 31.3676,
    '1407_989': 31.3631,
    '1407_990': 31.3597,
    '1407_991': 31.3578,
    '1407_992': 31.3578,
    '1407_993': 31.3589,
    '1407_994': 31.3621,
    '1407_995': 31.3672,
    '1407_996': 31.3722,
    '1407_997': 31.3786,
    '1407_998': 31.3868,
    '1407_999': 31.3945,
    '1407_1000': 31.4015,
    '1407_1001': 31.4079,
    '1407_1002': 31.412,
    '1407_1003': 31.4152,
    '1407_1004': 31.4183,
    '1407_1005': 31.421,
    '1407_1006': 31.4231,
    '1407_1007': 31.4245,
    '1407_1008': 31.4257,
    '1407_1009': 31.4277,
    '1407_1010': 31.4321,
    '1407_1011': 31.4394,
    '1407_1012': 31.4475,
    '1407_1013': 31.4665,
    '1407_1014': 31.4889,
    '1407_1015': 31.5124,
    '1407_1016': 31.5376,
    '1407_1017': 31.5603,
    '1407_1018': 31.5783,
    '1407_1019': 31.5911,
    '1407_1020': 31.5952,
    '1407_1021': 31.591,
    '1407_1022': 31.5802,
    '1407_1023': 31.5638,
    '1407_1024': 31.54,
    '1407_1025': 31.5086,
    '1407_1026': 31.4701,
    '1407_1027': 31.4233,
    '1407_1028': 31.3683,
    '1407_1029': 31.3057,
    '1407_1030': 31.2355,
    '1407_1031': 31.1576,
    '1407_1032': 31.0723,
    '1407_1033': 30.9782,
    '1408_806': 31.1182,
    '1408_807': 31.1892,
    '1408_808': 31.249,
    '1408_809': 31.2996,
    '1408_810': 31.344,
    '1408_811': 31.3882,
    '1408_812': 31.4334,
    '1408_813': 31.4902,
    '1408_814': 31.5527,
    '1408_815': 31.6127,
    '1408_816': 31.6607,
    '1408_817': 31.6914,
    '1408_818': 31.7049,
    '1408_819': 31.7055,
    '1408_820': 31.6946,
    '1408_821': 31.6758,
    '1408_822': 31.6547,
    '1408_823': 31.6372,
    '1408_824': 31.6236,
    '1408_825': 31.6216,
    '1408_826': 31.6225,
    '1408_827': 31.6244,
    '1408_828': 31.6308,
    '1408_829': 31.6365,
    '1408_830': 31.6433,
    '1408_831': 31.6518,
    '1408_832': 31.6633,
    '1408_833': 31.6749,
    '1408_834': 31.6861,
    '1408_835': 31.7005,
    '1408_836': 31.7141,
    '1408_837': 31.7147,
    '1408_838': 31.7138,
    '1408_839': 31.7119,
    '1408_840': 31.7104,
    '1408_841': 31.7106,
    '1408_842': 31.7116,
    '1408_843': 31.7134,
    '1408_844': 31.7172,
    '1408_845': 31.7253,
    '1408_846': 31.7357,
    '1408_847': 31.7465,
    '1408_848': 31.7569,
    '1408_849': 31.7676,
    '1408_850': 31.7793,
    '1408_851': 31.7953,
    '1408_852': 31.8175,
    '1408_853': 31.8488,
    '1408_854': 31.8941,
    '1408_855': 31.9542,
    '1408_856': 32.0358,
    '1408_857': 32.122,
    '1408_858': 32.1785,
    '1408_859': 32.1978,
    '1408_860': 32.2039,
    '1408_861': 32.2092,
    '1408_862': 32.2161,
    '1408_863': 32.2012,
    '1408_864': 32.1823,
    '1408_865': 32.1952,
    '1408_866': 32.222,
    '1408_867': 32.2449,
    '1408_868': 32.2489,
    '1408_869': 32.2184,
    '1408_870': 32.1398,
    '1408_871': 32.0218,
    '1408_872': 31.8963,
    '1408_873': 31.7973,
    '1408_874': 31.728,
    '1408_875': 31.6854,
    '1408_876': 31.6598,
    '1408_877': 31.65,
    '1408_878': 31.6539,
    '1408_879': 31.664,
    '1408_880': 31.6852,
    '1408_881': 31.718,
    '1408_882': 31.7476,
    '1408_883': 31.7661,
    '1408_884': 31.7669,
    '1408_885': 31.7559,
    '1408_886': 31.7508,
    '1408_887': 31.762,
    '1408_888': 31.79,
    '1408_889': 31.8175,
    '1408_890': 31.8554,
    '1408_891': 31.9086,
    '1408_892': 31.9728,
    '1408_893': 32.0409,
    '1408_894': 32.1035,
    '1408_895': 32.133,
    '1408_896': 32.1395,
    '1408_897': 32.1411,
    '1408_898': 32.1552,
    '1408_899': 32.1889,
    '1408_900': 32.2378,
    '1408_901': 32.2828,
    '1408_902': 32.3305,
    '1408_903': 32.3802,
    '1408_904': 32.4297,
    '1408_905': 32.48,
    '1408_906': 32.5258,
    '1408_907': 32.5665,
    '1408_908': 32.592,
    '1408_909': 32.5959,
    '1408_910': 32.5664,
    '1408_911': 32.5533,
    '1408_912': 32.5122,
    '1408_913': 32.4626,
    '1408_914': 32.4303,
    '1408_915': 32.3984,
    '1408_916': 32.3732,
    '1408_917': 32.3684,
    '1408_918': 32.3714,
    '1408_919': 32.3723,
    '1408_920': 32.371,
    '1408_921': 32.3407,
    '1408_922': 32.2753,
    '1408_923': 32.2021,
    '1408_924': 32.1042,
    '1408_925': 32.0095,
    '1408_926': 31.9353,
    '1408_927': 31.8868,
    '1408_928': 31.8494,
    '1408_929': 31.8056,
    '1408_930': 31.7562,
    '1408_931': 31.7146,
    '1408_932': 31.6853,
    '1408_933': 31.6601,
    '1408_934': 31.6416,
    '1408_935': 31.6415,
    '1408_936': 31.6611,
    '1408_937': 31.6865,
    '1408_938': 31.6983,
    '1408_939': 31.6911,
    '1408_940': 31.666,
    '1408_941': 31.627,
    '1408_942': 31.5867,
    '1408_943': 31.5548,
    '1408_944': 31.5444,
    '1408_945': 31.5484,
    '1408_946': 31.5575,
    '1408_947': 31.5776,
    '1408_948': 31.6112,
    '1408_949': 31.6487,
    '1408_950': 31.6795,
    '1408_951': 31.7135,
    '1408_952': 31.7494,
    '1408_953': 31.7765,
    '1408_954': 31.7861,
    '1408_955': 31.7924,
    '1408_956': 31.8078,
    '1408_957': 31.8419,
    '1408_958': 31.8821,
    '1408_959': 31.9085,
    '1408_960': 31.9194,
    '1408_961': 31.927,
    '1408_962': 31.941,
    '1408_963': 31.9527,
    '1408_964': 31.9457,
    '1408_965': 31.9433,
    '1408_966': 31.9482,
    '1408_967': 31.938,
    '1408_968': 31.9157,
    '1408_969': 31.8937,
    '1408_970': 31.861,
    '1408_971': 31.8124,
    '1408_972': 31.752,
    '1408_973': 31.6947,
    '1408_974': 31.6389,
    '1408_975': 31.5793,
    '1408_976': 31.524,
    '1408_977': 31.4842,
    '1408_978': 31.4596,
    '1408_979': 31.4403,
    '1408_980': 31.4367,
    '1408_981': 31.4491,
    '1408_982': 31.4617,
    '1408_983': 31.4635,
    '1408_984': 31.4578,
    '1408_985': 31.4453,
    '1408_986': 31.4305,
    '1408_987': 31.4168,
    '1408_988': 31.4055,
    '1408_989': 31.3956,
    '1408_990': 31.387,
    '1408_991': 31.3791,
    '1408_992': 31.3732,
    '1408_993': 31.3693,
    '1408_994': 31.3689,
    '1408_995': 31.3713,
    '1408_996': 31.3736,
    '1408_997': 31.3771,
    '1408_998': 31.383,
    '1408_999': 31.3886,
    '1408_1000': 31.3935,
    '1408_1001': 31.398,
    '1408_1002': 31.4017,
    '1408_1003': 31.4052,
    '1408_1004': 31.409,
    '1408_1005': 31.4128,
    '1408_1006': 31.4168,
    '1408_1007': 31.4201,
    '1408_1008': 31.422,
    '1408_1009': 31.425,
    '1408_1010': 31.4302,
    '1408_1011': 31.4385,
    '1408_1012': 31.447,
    '1408_1013': 31.4672,
    '1408_1014': 31.492,
    '1408_1015': 31.5187,
    '1408_1016': 31.5484,
    '1409_806': 31.0884,
    '1409_807': 31.1588,
    '1409_808': 31.2147,
    '1409_809': 31.2591,
    '1409_810': 31.2978,
    '1409_811': 31.341,
    '1409_812': 31.3808,
    '1409_813': 31.4318,
    '1409_814': 31.4901,
    '1409_815': 31.5396,
    '1409_816': 31.5732,
    '1409_817': 31.5861,
    '1409_818': 31.5846,
    '1409_819': 31.5745,
    '1409_820': 31.5582,
    '1409_821': 31.5385,
    '1409_822': 31.5242,
    '1409_823': 31.5142,
    '1409_824': 31.5079,
    '1409_825': 31.5118,
    '1409_826': 31.5154,
    '1409_827': 31.518,
    '1409_828': 31.5272,
    '1409_829': 31.5359,
    '1409_830': 31.5459,
    '1409_831': 31.5571,
    '1409_832': 31.5729,
    '1409_833': 31.5896,
    '1409_834': 31.6057,
    '1409_835': 31.6285,
    '1409_836': 31.6502,
    '1409_837': 31.6571,
    '1409_838': 31.6613,
    '1409_839': 31.6624,
    '1409_840': 31.6651,
    '1409_841': 31.6682,
    '1409_842': 31.673,
    '1409_843': 31.6767,
    '1409_844': 31.6827,
    '1409_845': 31.694,
    '1409_846': 31.7058,
    '1409_847': 31.7184,
    '1409_848': 31.7321,
    '1409_849': 31.7464,
    '1409_850': 31.7625,
    '1409_851': 31.7843,
    '1409_852': 31.8135,
    '1409_853': 31.8539,
    '1409_854': 31.9066,
    '1409_855': 31.9752,
    '1409_856': 32.0593,
    '1409_857': 32.1397,
    '1409_858': 32.1999,
    '1409_859': 32.2298,
    '1409_860': 32.2452,
    '1409_861': 32.251,
    '1409_862': 32.2577,
    '1409_863': 32.2396,
    '1409_864': 32.2191,
    '1409_865': 32.2317,
    '1409_866': 32.2645,
    '1409_867': 32.2881,
    '1409_868': 32.2846,
    '1409_869': 32.2477,
    '1409_870': 32.1652,
    '1409_871': 32.0473,
    '1409_872': 31.9192,
    '1409_873': 31.8081,
    '1409_874': 31.7322,
    '1409_875': 31.6851,
    '1409_876': 31.6558,
    '1409_877': 31.6413,
    '1409_878': 31.64,
    '1409_879': 31.6467,
    '1409_880': 31.6634,
    '1409_881': 31.6902,
    '1409_882': 31.7153,
    '1409_883': 31.7312,
    '1409_884': 31.7339,
    '1409_885': 31.7281,
    '1409_886': 31.7259,
    '1409_887': 31.7322,
    '1409_888': 31.7558,
    '1409_889': 31.789,
    '1409_890': 31.8367,
    '1409_891': 31.9002,
    '1409_892': 31.9691,
    '1409_893': 32.0373,
    '1409_894': 32.0985,
    '1409_895': 32.1308,
    '1409_896': 32.138,
    '1409_897': 32.1429,
    '1409_898': 32.1581,
    '1409_899': 32.1899,
    '1409_900': 32.2326,
    '1409_901': 32.2713,
    '1409_902': 32.3124,
    '1409_903': 32.3544,
    '1409_904': 32.3965,
    '1409_905': 32.4403,
    '1409_906': 32.4838,
    '1409_907': 32.5269,
    '1409_908': 32.5642,
    '1409_909': 32.5952,
    '1409_910': 32.5978,
    '1409_911': 32.5823,
    '1409_912': 32.564,
    '1409_913': 32.5349,
    '1409_914': 32.4997,
    '1409_915': 32.4578,
    '1409_916': 32.4285,
    '1409_917': 32.4182,
    '1409_918': 32.4169,
    '1409_919': 32.4182,
    '1409_920': 32.4167,
    '1409_921': 32.3868,
    '1409_922': 32.3173,
    '1409_923': 32.2398,
    '1409_924': 32.1428,
    '1409_925': 32.0492,
    '1409_926': 31.9843,
    '1409_927': 31.9464,
    '1409_928': 31.9174,
    '1409_929': 31.88,
    '1409_930': 31.8387,
    '1409_931': 31.8001,
    '1409_932': 31.7642,
    '1409_933': 31.7324,
    '1409_934': 31.7088,
    '1409_935': 31.7087,
    '1409_936': 31.7255,
    '1409_937': 31.7429,
    '1409_938': 31.7523,
    '1409_939': 31.7493,
    '1409_940': 31.7235,
    '1409_941': 31.6848,
    '1409_942': 31.646,
    '1409_943': 31.6124,
    '1409_944': 31.5955,
    '1409_945': 31.5955,
    '1409_946': 31.6063,
    '1409_947': 31.6279,
    '1409_948': 31.658,
    '1409_949': 31.6893,
    '1409_950': 31.7104,
    '1409_951': 31.7318,
    '1409_952': 31.763,
    '1409_953': 31.7886,
    '1409_954': 31.7978,
    '1409_955': 31.7967,
    '1409_956': 31.8077,
    '1409_957': 31.8387,
    '1409_958': 31.8703,
    '1409_959': 31.8923,
    '1409_960': 31.9072,
    '1409_961': 31.9256,
    '1409_962': 31.9535,
    '1409_963': 31.9831,
    '1409_964': 31.9733,
    '1409_965': 31.9625,
    '1409_966': 31.9615,
    '1409_967': 31.9485,
    '1409_968': 31.9295,
    '1409_969': 31.9101,
    '1409_970': 31.877,
    '1409_971': 31.8296,
    '1409_972': 31.7774,
    '1409_973': 31.7247,
    '1409_974': 31.6735,
    '1409_975': 31.6137,
    '1409_976': 31.5654,
    '1409_977': 31.5256,
    '1409_978': 31.5015,
    '1409_979': 31.4887,
    '1409_980': 31.4933,
    '1409_981': 31.5155,
    '1409_982': 31.5351,
    '1409_983': 31.5392,
    '1409_984': 31.5301,
    '1409_985': 31.511,
    '1409_986': 31.4896,
    '1409_987': 31.4697,
    '1409_988': 31.452,
    '1409_989': 31.4356,
    '1409_990': 31.4201,
    '1409_991': 31.4057,
    '1409_992': 31.3933,
    '1409_993': 31.3844,
    '1409_994': 31.3806,
    '1409_995': 31.3798,
    '1409_996': 31.3793,
    '1409_997': 31.3802,
    '1409_998': 31.3831,
    '1409_999': 31.3856,
    '1409_1000': 31.3879,
    '1409_1001': 31.3903,
    '1409_1002': 31.3931,
    '1409_1003': 31.3963,
    '1409_1004': 31.4003,
    '1409_1005': 31.4055,
    '1409_1006': 31.411,
    '1409_1007': 31.4155,
    '1409_1008': 31.4187,
    '1409_1009': 31.4219,
    '1409_1010': 31.4262,
    '1409_1011': 31.434,
    '1409_1012': 31.4431,
    '1409_1013': 31.4648,
    '1409_1014': 31.4917,
    '1409_1015': 31.5216,
    '1409_1016': 31.5554,
    '1410_806': 31.0436,
    '1410_807': 31.1227,
    '1410_808': 31.1816,
    '1410_809': 31.221,
    '1410_810': 31.2484,
    '1410_811': 31.2848,
    '1410_812': 31.3283,
    '1410_813': 31.3736,
    '1410_814': 31.418,
    '1410_815': 31.4541,
    '1410_816': 31.477,
    '1410_817': 31.4789,
    '1410_818': 31.4614,
    '1410_819': 31.4373,
    '1410_820': 31.4142,
    '1410_821': 31.3967,
    '1410_822': 31.3855,
    '1410_823': 31.382,
    '1410_824': 31.3856,
    '1410_825': 31.3928,
    '1410_826': 31.3994,
    '1410_827': 31.4078,
    '1410_828': 31.421,
    '1410_829': 31.4373,
    '1410_830': 31.4494,
    '1410_831': 31.4656,
    '1410_832': 31.4845,
    '1410_833': 31.5045,
    '1410_834': 31.526,
    '1410_835': 31.5582,
    '1410_836': 31.5865,
    '1410_837': 31.6018,
    '1410_838': 31.6081,
    '1410_839': 31.6128,
    '1410_840': 31.6193,
    '1410_841': 31.6258,
    '1410_842': 31.6333,
    '1410_843': 31.6421,
    '1410_844': 31.6508,
    '1410_845': 31.664,
    '1410_846': 31.6794,
    '1410_847': 31.6946,
    '1410_848': 31.7111,
    '1410_849': 31.7264,
    '1410_850': 31.7472,
    '1410_851': 31.7748,
    '1410_852': 31.8094,
    '1410_853': 31.8575,
    '1410_854': 31.9193,
    '1410_855': 32.0009,
    '1410_856': 32.0901,
    '1410_857': 32.1621,
    '1410_858': 32.2224,
    '1410_859': 32.26,
    '1410_860': 32.2813,
    '1410_861': 32.2884,
    '1410_862': 32.287,
    '1410_863': 32.2625,
    '1410_864': 32.2441,
    '1410_865': 32.2599,
    '1410_866': 32.2931,
    '1410_867': 32.3102,
    '1410_868': 32.2994,
    '1410_869': 32.257,
    '1410_870': 32.1748,
    '1410_871': 32.0601,
    '1410_872': 31.9311,
    '1410_873': 31.8137,
    '1410_874': 31.732,
    '1410_875': 31.6796,
    '1410_876': 31.6469,
    '1410_877': 31.6306,
    '1410_878': 31.6289,
    '1410_879': 31.6356,
    '1410_880': 31.6463,
    '1410_881': 31.6627,
    '1410_882': 31.6799,
    '1410_883': 31.6942,
    '1410_884': 31.6993,
    '1410_885': 31.6977,
    '1410_886': 31.7016,
    '1410_887': 31.7134,
    '1410_888': 31.7371,
    '1410_889': 31.7797,
    '1410_890': 31.8356,
    '1410_891': 31.9049,
    '1410_892': 31.9798,
    '1410_893': 32.0514,
    '1410_894': 32.1071,
    '1410_895': 32.1318,
    '1410_896': 32.1392,
    '1410_897': 32.1485,
    '1410_898': 32.1618,
    '1410_899': 32.1911,
    '1410_900': 32.2266,
    '1410_901': 32.2582,
    '1410_902': 32.2922,
    '1410_903': 32.3288,
    '1410_904': 32.3652,
    '1410_905': 32.4049,
    '1410_906': 32.4467,
    '1410_907': 32.4892,
    '1410_908': 32.5323,
    '1410_909': 32.5793,
    '1410_910': 32.6141,
    '1410_911': 32.6187,
    '1410_912': 32.6097,
    '1410_913': 32.5991,
    '1410_914': 32.5556,
    '1410_915': 32.5209,
    '1410_916': 32.4933,
    '1410_917': 32.4734,
    '1410_918': 32.4628,
    '1410_919': 32.465,
    '1410_920': 32.458,
    '1410_921': 32.421,
    '1410_922': 32.3644,
    '1410_923': 32.2753,
    '1410_924': 32.1766,
    '1410_925': 32.086,
    '1410_926': 32.025,
    '1410_927': 31.9954,
    '1410_928': 31.9788,
    '1410_929': 31.9547,
    '1410_930': 31.9168,
    '1410_931': 31.8777,
    '1410_932': 31.8305,
    '1410_933': 31.7951,
    '1410_934': 31.7663,
    '1410_935': 31.7625,
    '1410_936': 31.7757,
    '1410_937': 31.7876,
    '1410_938': 31.7934,
    '1410_939': 31.7863,
    '1410_940': 31.7662,
    '1410_941': 31.7338,
    '1410_942': 31.693,
    '1410_943': 31.6583,
    '1410_944': 31.6417,
    '1410_945': 31.6418,
    '1410_946': 31.6531,
    '1410_947': 31.6751,
    '1410_948': 31.7009,
    '1410_949': 31.7246,
    '1410_950': 31.7408,
    '1410_951': 31.75,
    '1410_952': 31.7694,
    '1410_953': 31.7906,
    '1410_954': 31.7982,
    '1410_955': 31.7956,
    '1410_956': 31.806,
    '1410_957': 31.8294,
    '1410_958': 31.8549,
    '1410_959': 31.872,
    '1410_960': 31.8901,
    '1410_961': 31.9177,
    '1410_962': 31.9522,
    '1410_963': 31.9806,
    '1410_964': 31.9821,
    '1410_965': 31.9725,
    '1410_966': 31.9661,
    '1410_967': 31.9578,
    '1410_968': 31.9395,
    '1410_969': 31.9176,
    '1410_970': 31.8834,
    '1410_971': 31.8353,
    '1410_972': 31.7884,
    '1410_973': 31.7417,
    '1410_974': 31.6896,
    '1410_975': 31.6349,
    '1410_976': 31.5959,
    '1410_977': 31.5614,
    '1410_978': 31.5345,
    '1410_979': 31.5344,
    '1410_980': 31.55,
    '1410_981': 31.5779,
    '1410_982': 31.6023,
    '1410_983': 31.6073,
    '1410_984': 31.598,
    '1410_985': 31.5758,
    '1410_986': 31.5506,
    '1410_987': 31.5261,
    '1410_988': 31.503,
    '1410_989': 31.4803,
    '1410_990': 31.4586,
    '1410_991': 31.4384,
    '1410_992': 31.4203,
    '1410_993': 31.4058,
    '1410_994': 31.3989,
    '1410_995': 31.3944,
    '1410_996': 31.3901,
    '1410_997': 31.3874,
    '1410_998': 31.3865,
    '1410_999': 31.3855,
    '1410_1000': 31.3849,
    '1410_1001': 31.3846,
    '1410_1002': 31.386,
    '1410_1003': 31.3886,
    '1410_1004': 31.3928,
    '1410_1005': 31.3982,
    '1410_1006': 31.4043,
    '1410_1007': 31.409,
    '1410_1008': 31.4118,
    '1410_1009': 31.4147,
    '1410_1010': 31.4186,
    '1410_1011': 31.4209,
    '1410_1012': 31.4345,
    '1410_1013': 31.4577,
    '1410_1014': 31.4868,
    '1410_1015': 31.52,
    '1410_1016': 31.5582,
    '1411_806': 30.999,
    '1411_807': 31.0832,
    '1411_808': 31.1472,
    '1411_809': 31.1904,
    '1411_810': 31.2211,
    '1411_811': 31.2526,
    '1411_812': 31.2835,
    '1411_813': 31.3144,
    '1411_814': 31.3413,
    '1411_815': 31.3591,
    '1411_816': 31.3654,
    '1411_817': 31.3579,
    '1411_818': 31.3401,
    '1411_819': 31.32,
    '1411_820': 31.2969,
    '1411_821': 31.2821,
    '1411_822': 31.2743,
    '1411_823': 31.2755,
    '1411_824': 31.2825,
    '1411_825': 31.2946,
    '1411_826': 31.3067,
    '1411_827': 31.3189,
    '1411_828': 31.3371,
    '1411_829': 31.357,
    '1411_830': 31.3783,
    '1411_831': 31.4003,
    '1411_832': 31.4221,
    '1411_833': 31.4466,
    '1411_834': 31.469,
    '1411_835': 31.4977,
    '1411_836': 31.5218,
    '1411_837': 31.5443,
    '1411_838': 31.5591,
    '1411_839': 31.57,
    '1411_840': 31.581,
    '1411_841': 31.5887,
    '1411_842': 31.5984,
    '1411_843': 31.6114,
    '1411_844': 31.6261,
    '1411_845': 31.6426,
    '1411_846': 31.66,
    '1411_847': 31.6776,
    '1411_848': 31.6958,
    '1411_849': 31.7152,
    '1411_850': 31.7394,
    '1411_851': 31.7701,
    '1411_852': 31.8089,
    '1411_853': 31.8596,
    '1411_854': 31.9279,
    '1411_855': 32.0209,
    '1411_856': 32.11,
    '1411_857': 32.1808,
    '1411_858': 32.235,
    '1411_859': 32.2775,
    '1411_860': 32.3021,
    '1411_861': 32.3173,
    '1411_862': 32.3054,
    '1411_863': 32.2793,
    '1411_864': 32.2672,
    '1411_865': 32.2781,
    '1411_866': 32.2974,
    '1411_867': 32.3039,
    '1411_868': 32.2877,
    '1411_869': 32.2478,
    '1411_870': 32.1743,
    '1411_871': 32.0638,
    '1411_872': 31.9362,
    '1411_873': 31.8166,
    '1411_874': 31.7294,
    '1411_875': 31.6715,
    '1411_876': 31.6357,
    '1411_877': 31.618,
    '1411_878': 31.6157,
    '1411_879': 31.6218,
    '1411_880': 31.634,
    '1411_881': 31.64,
    '1411_882': 31.6465,
    '1411_883': 31.6554,
    '1411_884': 31.6598,
    '1411_885': 31.6653,
    '1411_886': 31.6764,
    '1411_887': 31.6969,
    '1411_888': 31.7286,
    '1411_889': 31.7792,
    '1411_890': 31.8401,
    '1411_891': 31.9125,
    '1411_892': 31.9942,
    '1411_893': 32.0695,
    '1411_894': 32.1184,
    '1411_895': 32.1331,
    '1411_896': 32.1374,
    '1411_897': 32.1471,
    '1411_898': 32.1613,
    '1411_899': 32.1892,
    '1411_900': 32.2187,
    '1411_901': 32.241,
    '1411_902': 32.2679,
    '1411_903': 32.2998,
    '1411_904': 32.3345,
    '1411_905': 32.372,
    '1411_906': 32.4137,
    '1411_907': 32.4554,
    '1411_908': 32.4994,
    '1411_909': 32.5554,
    '1411_910': 32.5996,
    '1411_911': 32.6319,
    '1411_912': 32.6434,
    '1411_913': 32.6348,
    '1411_914': 32.5936,
    '1411_915': 32.5798,
    '1411_916': 32.5542,
    '1411_917': 32.5256,
    '1411_918': 32.507,
    '1411_919': 32.5066,
    '1411_920': 32.497,
    '1411_921': 32.465,
    '1411_922': 32.4096,
    '1411_923': 32.3173,
    '1411_924': 32.2197,
    '1411_925': 32.1308,
    '1411_926': 32.0705,
    '1411_927': 32.0424,
    '1411_928': 32.034,
    '1411_929': 32.0102,
    '1411_930': 31.9739,
    '1411_931': 31.9325,
    '1411_932': 31.8867,
    '1411_933': 31.8414,
    '1411_934': 31.811,
    '1411_935': 31.8046,
    '1411_936': 31.8083,
    '1411_937': 31.8104,
    '1411_938': 31.8091,
    '1411_939': 31.8021,
    '1411_940': 31.7862,
    '1411_941': 31.7609,
    '1411_942': 31.7196,
    '1411_943': 31.688,
    '1411_944': 31.6735,
    '1411_945': 31.6769,
    '1411_946': 31.6902,
    '1411_947': 31.7086,
    '1411_948': 31.7355,
    '1411_949': 31.7568,
    '1411_950': 31.7692,
    '1411_951': 31.7686,
    '1411_952': 31.7721,
    '1411_953': 31.7845,
    '1411_954': 31.7933,
    '1411_955': 31.7946,
    '1411_956': 31.7992,
    '1411_957': 31.8119,
    '1411_958': 31.8279,
    '1411_959': 31.8426,
    '1411_960': 31.8634,
    '1411_961': 31.8953,
    '1411_962': 31.9331,
    '1411_963': 31.9593,
    '1411_964': 31.9667,
    '1411_965': 31.9617,
    '1411_966': 31.9554,
    '1411_967': 31.9474,
    '1411_968': 31.931,
    '1411_969': 31.9073,
    '1411_970': 31.8725,
    '1411_971': 31.8243,
    '1411_972': 31.7803,
    '1411_973': 31.7345,
    '1411_974': 31.6805,
    '1411_975': 31.626,
    '1411_976': 31.586,
    '1411_977': 31.5614,
    '1411_978': 31.5572,
    '1411_979': 31.5697,
    '1411_980': 31.5946,
    '1411_981': 31.6286,
    '1411_982': 31.6579,
    '1411_983': 31.6655,
    '1411_984': 31.6559,
    '1411_985': 31.635,
    '1411_986': 31.6085,
    '1411_987': 31.5814,
    '1411_988': 31.5545,
    '1411_989': 31.5279,
    '1411_990': 31.5027,
    '1411_991': 31.478,
    '1411_992': 31.4545,
    '1411_993': 31.4359,
    '1411_994': 31.4217,
    '1411_995': 31.4118,
    '1411_996': 31.4034,
    '1411_997': 31.3973,
    '1411_998': 31.3925,
    '1411_999': 31.3878,
    '1411_1000': 31.3831,
    '1411_1001': 31.3797,
    '1411_1002': 31.3782,
    '1411_1003': 31.3794,
    '1411_1004': 31.3832,
    '1411_1005': 31.3882,
    '1411_1006': 31.3939,
    '1411_1007': 31.3975,
    '1411_1008': 31.399,
    '1411_1009': 31.4014,
    '1411_1010': 31.4049,
    '1411_1011': 31.406,
    '1411_1012': 31.4199,
    '1411_1013': 31.4439,
    '1411_1014': 31.4751,
    '1411_1015': 31.5118,
    '1411_1016': 31.5547,
    '1412_806': 30.9594,
    '1412_807': 31.047,
    '1412_808': 31.1202,
    '1412_809': 31.1659,
    '1412_810': 31.1951,
    '1412_811': 31.2184,
    '1412_812': 31.2385,
    '1412_813': 31.2558,
    '1412_814': 31.2671,
    '1412_815': 31.2667,
    '1412_816': 31.2559,
    '1412_817': 31.2377,
    '1412_818': 31.2181,
    '1412_819': 31.1997,
    '1412_820': 31.1827,
    '1412_821': 31.1732,
    '1412_822': 31.1709,
    '1412_823': 31.1761,
    '1412_824': 31.1867,
    '1412_825': 31.2013,
    '1412_826': 31.2168,
    '1412_827': 31.2341,
    '1412_828': 31.2567,
    '1412_829': 31.2813,
    '1412_830': 31.3079,
    '1412_831': 31.3356,
    '1412_832': 31.3616,
    '1412_833': 31.3873,
    '1412_834': 31.4122,
    '1412_835': 31.4393,
    '1412_836': 31.4677,
    '1412_837': 31.4924,
    '1412_838': 31.5116,
    '1412_839': 31.5276,
    '1412_840': 31.5415,
    '1412_841': 31.5538,
    '1412_842': 31.5686,
    '1412_843': 31.59,
    '1412_844': 31.6074,
    '1412_845': 31.6259,
    '1412_846': 31.6469,
    '1412_847': 31.667,
    '1412_848': 31.6872,
    '1412_849': 31.7095,
    '1412_850': 31.7361,
    '1412_851': 31.7688,
    '1412_852': 31.8091,
    '1412_853': 31.8598,
    '1412_854': 31.928,
    '1412_855': 32.0211,
    '1412_856': 32.1059,
    '1412_857': 32.1726,
    '1412_858': 32.2308,
    '1412_859': 32.2804,
    '1412_860': 32.3149,
    '1412_861': 32.3348,
    '1412_862': 32.3225,
    '1412_863': 32.3003,
    '1412_864': 32.2881,
    '1412_865': 32.2872,
    '1412_866': 32.2865,
    '1412_867': 32.2787,
    '1412_868': 32.2606,
    '1412_869': 32.2232,
    '1412_870': 32.1559,
    '1412_871': 32.0556,
    '1412_872': 31.9325,
    '1412_873': 31.8137,
    '1412_874': 31.7259,
    '1412_875': 31.6649,
    '1412_876': 31.6267,
    '1412_877': 31.6079,
    '1412_878': 31.6039,
    '1412_879': 31.6065,
    '1412_880': 31.6153,
    '1412_881': 31.6203,
    '1412_882': 31.6218,
    '1412_883': 31.6212,
    '1412_884': 31.627,
    '1412_885': 31.6402,
    '1412_886': 31.6595,
    '1412_887': 31.6909,
    '1412_888': 31.736,
    '1412_889': 31.7905,
    '1412_890': 31.8501,
    '1412_891': 31.927,
    '1412_892': 32.0145,
    '1412_893': 32.0871,
    '1412_894': 32.1265,
    '1412_895': 32.1332,
    '1412_896': 32.1325,
    '1412_897': 32.1424,
    '1412_898': 32.1557,
    '1412_899': 32.1808,
    '1412_900': 32.2052,
    '1412_901': 32.2215,
    '1412_902': 32.2459,
    '1412_903': 32.2766,
    '1412_904': 32.3078,
    '1412_905': 32.3439,
    '1412_906': 32.3816,
    '1412_907': 32.4221,
    '1412_908': 32.4742,
    '1412_909': 32.5289,
    '1412_910': 32.585,
    '1412_911': 32.6344,
    '1412_912': 32.6562,
    '1412_913': 32.6568,
    '1412_914': 32.6294,
    '1412_915': 32.6348,
    '1412_916': 32.6079,
    '1412_917': 32.5709,
    '1412_918': 32.5402,
    '1412_919': 32.5451,
    '1412_920': 32.5357,
    '1412_921': 32.5009,
    '1412_922': 32.464,
    '1412_923': 32.3782,
    '1412_924': 32.2857,
    '1412_925': 32.1953,
    '1412_926': 32.1303,
    '1412_927': 32.0973,
    '1412_928': 32.0862,
    '1412_929': 32.0636,
    '1412_930': 32.0313,
    '1412_931': 31.9844,
    '1412_932': 31.9305,
    '1412_933': 31.8813,
    '1412_934': 31.8481,
    '1412_935': 31.839,
    '1412_936': 31.8319,
    '1412_937': 31.8215,
    '1412_938': 31.8085,
    '1412_939': 31.7964,
    '1412_940': 31.7793,
    '1412_941': 31.7604,
    '1412_942': 31.7342,
    '1412_943': 31.7111,
    '1412_944': 31.701,
    '1412_945': 31.7068,
    '1412_946': 31.7219,
    '1412_947': 31.7417,
    '1412_948': 31.7699,
    '1412_949': 31.7869,
    '1412_950': 31.795,
    '1412_951': 31.789,
    '1412_952': 31.7808,
    '1412_953': 31.7821,
    '1412_954': 31.7861,
    '1412_955': 31.7872,
    '1412_956': 31.7871,
    '1412_957': 31.7887,
    '1412_958': 31.7975,
    '1412_959': 31.8085,
    '1412_960': 31.8263,
    '1412_961': 31.8571,
    '1412_962': 31.8907,
    '1412_963': 31.9167,
    '1412_964': 31.9286,
    '1412_965': 31.9282,
    '1412_966': 31.9238,
    '1412_967': 31.9155,
    '1412_968': 31.8998,
    '1412_969': 31.8781,
    '1412_970': 31.8466,
    '1412_971': 31.8002,
    '1412_972': 31.7489,
    '1412_973': 31.6949,
    '1412_974': 31.6435,
    '1412_975': 31.5998,
    '1412_976': 31.5657,
    '1412_977': 31.5568,
    '1412_978': 31.5686,
    '1412_979': 31.5919,
    '1412_980': 31.6218,
    '1412_981': 31.6649,
    '1412_982': 31.6994,
    '1412_983': 31.7158,
    '1412_984': 31.7085,
    '1412_985': 31.6888,
    '1412_986': 31.665,
    '1412_987': 31.6386,
    '1412_988': 31.6089,
    '1412_989': 31.582,
    '1412_990': 31.5558,
    '1412_991': 31.5292,
    '1412_992': 31.5018,
    '1412_993': 31.4761,
    '1412_994': 31.4552,
    '1412_995': 31.4387,
    '1412_996': 31.4245,
    '1412_997': 31.4134,
    '1412_998': 31.4046,
    '1412_999': 31.3956,
    '1412_1000': 31.3867,
    '1412_1001': 31.3785,
    '1412_1002': 31.3736,
    '1412_1003': 31.3723,
    '1412_1004': 31.3736,
    '1412_1005': 31.3769,
    '1412_1006': 31.3807,
    '1412_1007': 31.3832,
    '1412_1008': 31.3842,
    '1412_1009': 31.3864,
    '1412_1010': 31.3898,
    '1412_1011': 31.3911,
    '1412_1012': 31.4048,
    '1412_1013': 31.4289,
    '1412_1014': 31.4619,
    '1412_1015': 31.5014,
    '1412_1016': 31.5488,
    '1413_806': 30.915,
    '1413_807': 31.0112,
    '1413_808': 31.0966,
    '1413_809': 31.1399,
    '1413_810': 31.1633,
    '1413_811': 31.1783,
    '1413_812': 31.1882,
    '1413_813': 31.1946,
    '1413_814': 31.1946,
    '1413_815': 31.1787,
    '1413_816': 31.1519,
    '1413_817': 31.1229,
    '1413_818': 31.1009,
    '1413_819': 31.085,
    '1413_820': 31.0724,
    '1413_821': 31.069,
    '1413_822': 31.0726,
    '1413_823': 31.0821,
    '1413_824': 31.0966,
    '1413_825': 31.1146,
    '1413_826': 31.1332,
    '1413_827': 31.1552,
    '1413_828': 31.1809,
    '1413_829': 31.2086,
    '1413_830': 31.2388,
    '1413_831': 31.272,
    '1413_832': 31.3016,
    '1413_833': 31.3301,
    '1413_834': 31.3587,
    '1413_835': 31.3909,
    '1413_836': 31.4228,
    '1413_837': 31.4475,
    '1413_838': 31.468,
    '1413_839': 31.488,
    '1413_840': 31.5046,
    '1413_841': 31.5214,
    '1413_842': 31.5416,
    '1413_843': 31.5656,
    '1413_844': 31.588,
    '1413_845': 31.6103,
    '1413_846': 31.637,
    '1413_847': 31.6589,
    '1413_848': 31.6806,
    '1413_849': 31.7046,
    '1413_850': 31.7329,
    '1413_851': 31.7667,
    '1413_852': 31.8075,
    '1413_853': 31.8567,
    '1413_854': 31.918,
    '1413_855': 31.9954,
    '1413_856': 32.075,
    '1413_857': 32.1454,
    '1413_858': 32.213,
    '1413_859': 32.2694,
    '1413_860': 32.3152,
    '1413_861': 32.3376,
    '1413_862': 32.3297,
    '1413_863': 32.3189,
    '1413_864': 32.3114,
    '1413_865': 32.2958,
    '1413_866': 32.2766,
    '1413_867': 32.2513,
    '1413_868': 32.2253,
    '1413_869': 32.187,
    '1413_870': 32.1186,
    '1413_871': 32.0262,
    '1413_872': 31.9203,
    '1413_873': 31.8088,
    '1413_874': 31.7201,
    '1413_875': 31.6579,
    '1413_876': 31.6174,
    '1413_877': 31.5947,
    '1413_878': 31.5858,
    '1413_879': 31.5856,
    '1413_880': 31.5914,
    '1413_881': 31.597,
    '1413_882': 31.5971,
    '1413_883': 31.6022,
    '1413_884': 31.6149,
    '1413_885': 31.6325,
    '1413_886': 31.6566,
    '1413_887': 31.6942,
    '1413_888': 31.7451,
    '1413_889': 31.8025,
    '1413_890': 31.8673,
    '1413_891': 31.9503,
    '1413_892': 32.0342,
    '1413_893': 32.0969,
    '1413_894': 32.1268,
    '1413_895': 32.1282,
    '1413_896': 32.1235,
    '1413_897': 32.1319,
    '1413_898': 32.1441,
    '1413_899': 32.166,
    '1413_900': 32.1859,
    '1413_901': 32.2023,
    '1413_902': 32.2256,
    '1413_903': 32.2533,
    '1413_904': 32.2837,
    '1413_905': 32.3173,
    '1413_906': 32.3552,
    '1413_907': 32.3946,
    '1413_908': 32.44,
    '1413_909': 32.4996,
    '1413_910': 32.5758,
    '1413_911': 32.6378,
    '1413_912': 32.6746,
    '1413_913': 32.6912,
    '1413_914': 32.6708,
    '1413_915': 32.6554,
    '1413_916': 32.6341,
    '1413_917': 32.5949,
    '1413_918': 32.5783,
    '1413_919': 32.5814,
    '1413_920': 32.5731,
    '1413_921': 32.5399,
    '1413_922': 32.5087,
    '1413_923': 32.4417,
    '1413_924': 32.3636,
    '1413_925': 32.2763,
    '1413_926': 32.2025,
    '1413_927': 32.1633,
    '1413_928': 32.1448,
    '1413_929': 32.113,
    '1413_930': 32.0696,
    '1413_931': 32.0197,
    '1413_932': 31.9678,
    '1413_933': 31.9178,
    '1413_934': 31.8787,
    '1413_935': 31.86,
    '1413_936': 31.8458,
    '1413_937': 31.8234,
    '1413_938': 31.7977,
    '1413_939': 31.7776,
    '1413_940': 31.7623,
    '1413_941': 31.758,
    '1413_942': 31.7467,
    '1413_943': 31.7315,
    '1413_944': 31.7237,
    '1413_945': 31.7299,
    '1413_946': 31.749,
    '1413_947': 31.774,
    '1413_948': 31.7975,
    '1413_949': 31.8091,
    '1413_950': 31.815,
    '1413_951': 31.8074,
    '1413_952': 31.7934,
    '1413_953': 31.7842,
    '1413_954': 31.7773,
    '1413_955': 31.7726,
    '1413_956': 31.7656,
    '1413_957': 31.7601,
    '1413_958': 31.7643,
    '1413_959': 31.7718,
    '1413_960': 31.7849,
    '1413_961': 31.8065,
    '1413_962': 31.8344,
    '1413_963': 31.8596,
    '1413_964': 31.8707,
    '1413_965': 31.8751,
    '1413_966': 31.8745,
    '1413_967': 31.8671,
    '1413_968': 31.8537,
    '1413_969': 31.8381,
    '1413_970': 31.8063,
    '1413_971': 31.7535,
    '1413_972': 31.6897,
    '1413_973': 31.6281,
    '1413_974': 31.5819,
    '1413_975': 31.5552,
    '1413_976': 31.5343,
    '1413_977': 31.5369,
    '1413_978': 31.5595,
    '1413_979': 31.595,
    '1413_980': 31.6383,
    '1413_981': 31.6832,
    '1413_982': 31.7283,
    '1413_983': 31.757,
    '1413_984': 31.7595,
    '1413_985': 31.7428,
    '1413_986': 31.7233,
    '1413_987': 31.6999,
    '1413_988': 31.6708,
    '1413_989': 31.6429,
    '1413_990': 31.6167,
    '1413_991': 31.5891,
    '1413_992': 31.5568,
    '1413_993': 31.5237,
    '1413_994': 31.4964,
    '1413_995': 31.4728,
    '1413_996': 31.4534,
    '1413_997': 31.4373,
    '1413_998': 31.4221,
    '1413_999': 31.4076,
    '1413_1000': 31.3936,
    '1413_1001': 31.3811,
    '1413_1002': 31.371,
    '1413_1003': 31.3654,
    '1413_1004': 31.3628,
    '1413_1005': 31.3625,
    '1413_1006': 31.3639,
    '1413_1007': 31.3653,
    '1413_1008': 31.3664,
    '1413_1009': 31.3692,
    '1413_1010': 31.3744,
    '1413_1011': 31.3824,
    '1413_1012': 31.3968,
    '1413_1013': 31.4202,
    '1413_1014': 31.4547,
    '1413_1015': 31.4902,
    '1413_1016': 31.5412,
    '1414_844': 31.5716,
    '1414_845': 31.5998,
    '1414_846': 31.6273,
    '1414_847': 31.6505,
    '1414_848': 31.6732,
    '1414_849': 31.6985,
    '1414_850': 31.7281,
    '1414_851': 31.763,
    '1414_852': 31.8041,
    '1414_853': 31.8523,
    '1414_854': 31.9084,
    '1414_855': 31.9731,
    '1414_856': 32.0439,
    '1414_857': 32.1102,
    '1414_858': 32.1837,
    '1414_859': 32.2487,
    '1414_860': 32.3032,
    '1414_861': 32.3288,
    '1414_862': 32.3313,
    '1414_863': 32.3353,
    '1414_864': 32.3324,
    '1414_865': 32.3073,
    '1414_866': 32.2724,
    '1414_867': 32.2317,
    '1414_868': 32.1905,
    '1414_869': 32.1409,
    '1414_870': 32.0736,
    '1414_871': 31.9912,
    '1414_872': 31.9016,
    '1414_873': 31.8024,
    '1414_874': 31.7151,
    '1414_875': 31.6515,
    '1414_876': 31.6097,
    '1414_877': 31.5847,
    '1414_878': 31.5713,
    '1414_879': 31.5665,
    '1414_880': 31.5682,
    '1414_881': 31.5765,
    '1414_882': 31.5859,
    '1414_883': 31.6027,
    '1414_884': 31.6202,
    '1414_885': 31.6417,
    '1414_886': 31.6674,
    '1414_887': 31.7044,
    '1414_888': 31.7498,
    '1414_889': 31.8125,
    '1414_890': 31.8905,
    '1414_891': 31.9794,
    '1414_892': 32.0516,
    '1414_893': 32.0992,
    '1414_894': 32.1197,
    '1414_895': 32.1191,
    '1414_896': 32.1144,
    '1414_897': 32.1205,
    '1414_898': 32.1309,
    '1414_899': 32.1512,
    '1414_900': 32.1684,
    '1414_901': 32.1841,
    '1414_902': 32.2057,
    '1414_903': 32.2311,
    '1414_904': 32.2618,
    '1414_905': 32.294,
    '1414_906': 32.3314,
    '1414_907': 32.3663,
    '1414_908': 32.4058,
    '1414_909': 32.4714,
    '1414_910': 32.5482,
    '1414_911': 32.6154,
    '1414_912': 32.6749,
    '1414_913': 32.6853,
    '1414_914': 32.6862,
    '1414_915': 32.678,
    '1414_916': 32.6571,
    '1414_917': 32.6283,
    '1414_918': 32.6099,
    '1414_919': 32.5994,
    '1414_920': 32.5971,
    '1414_921': 32.5715,
    '1414_922': 32.5398,
    '1414_923': 32.4872,
    '1414_924': 32.424,
    '1414_925': 32.3606,
    '1414_926': 32.291,
    '1414_927': 32.2398,
    '1414_928': 32.2007,
    '1414_929': 32.1515,
    '1414_930': 32.1017,
    '1414_931': 32.048,
    '1414_932': 31.9942,
    '1414_933': 31.9455,
    '1414_934': 31.9025,
    '1414_935': 31.8758,
    '1414_936': 31.8523,
    '1414_937': 31.82,
    '1414_938': 31.7881,
    '1414_939': 31.7634,
    '1414_940': 31.7529,
    '1414_941': 31.7588,
    '1414_942': 31.76,
    '1414_943': 31.7508,
    '1414_944': 31.742,
    '1414_945': 31.7492,
    '1414_946': 31.7685,
    '1414_947': 31.791,
    '1414_948': 31.8112,
    '1414_949': 31.8242,
    '1414_950': 31.8281,
    '1414_951': 31.8206,
    '1414_952': 31.8048,
    '1414_953': 31.7892,
    '1414_954': 31.7675,
    '1414_955': 31.7499,
    '1414_956': 31.7339,
    '1414_957': 31.729,
    '1414_958': 31.7293,
    '1414_959': 31.7312,
    '1414_960': 31.7418,
    '1414_961': 31.7547,
    '1414_962': 31.7747,
    '1414_963': 31.7927,
    '1414_964': 31.7987,
    '1414_965': 31.8058,
    '1414_966': 31.8135,
    '1414_967': 31.8148,
    '1414_968': 31.8064,
    '1414_969': 31.7887,
    '1414_970': 31.7524,
    '1414_971': 31.6875,
    '1414_972': 31.6163,
    '1414_973': 31.5547,
    '1414_974': 31.5124,
    '1414_975': 31.5017,
    '1414_976': 31.4961,
    '1414_977': 31.506,
    '1414_978': 31.5344,
    '1414_979': 31.5826,
    '1414_980': 31.6373,
    '1414_981': 31.6829,
    '1414_982': 31.7408,
    '1414_983': 31.7829,
    '1414_984': 31.7978,
    '1414_985': 31.7941,
    '1414_986': 31.7864,
    '1414_987': 31.7691,
    '1414_988': 31.743,
    '1414_989': 31.714,
    '1414_990': 31.6892,
    '1414_991': 31.6579,
    '1414_992': 31.6204,
    '1414_993': 31.5818,
    '1414_994': 31.5476,
    '1414_995': 31.5169,
    '1414_996': 31.491,
    '1414_997': 31.4689,
    '1414_998': 31.4478,
    '1414_999': 31.4258,
    '1414_1000': 31.4043,
    '1414_1001': 31.3864,
    '1414_1002': 31.3718,
    '1414_1003': 31.3608,
    '1414_1004': 31.3528,
    '1414_1005': 31.3481,
    '1414_1006': 31.3469,
    '1414_1007': 31.3475,
    '1414_1008': 31.3487,
    '1414_1009': 31.3523,
    '1414_1010': 31.3593,
    '1414_1011': 31.3697,
    '1414_1012': 31.3855,
    '1414_1013': 31.4107,
    '1414_1014': 31.4436,
    '1414_1015': 31.4809,
    '1414_1016': 31.535,
    '1415_844': 31.561,
    '1415_845': 31.5908,
    '1415_846': 31.6178,
    '1415_847': 31.6401,
    '1415_848': 31.663,
    '1415_849': 31.6894,
    '1415_850': 31.7204,
    '1415_851': 31.7568,
    '1415_852': 31.7991,
    '1415_853': 31.8481,
    '1415_854': 31.9037,
    '1415_855': 31.9649,
    '1415_856': 32.03,
    '1415_857': 32.0967,
    '1415_858': 32.1657,
    '1415_859': 32.2326,
    '1415_860': 32.2845,
    '1415_861': 32.3175,
    '1415_862': 32.3365,
    '1415_863': 32.3517,
    '1415_864': 32.3507,
    '1415_865': 32.3179,
    '1415_866': 32.2705,
    '1415_867': 32.2174,
    '1415_868': 32.161,
    '1415_869': 32.1001,
    '1415_870': 32.0326,
    '1415_871': 31.9585,
    '1415_872': 31.8791,
    '1415_873': 31.7913,
    '1415_874': 31.7073,
    '1415_875': 31.6409,
    '1415_876': 31.5985,
    '1415_877': 31.5735,
    '1415_878': 31.5593,
    '1415_879': 31.554,
    '1415_880': 31.5556,
    '1415_881': 31.5684,
    '1415_882': 31.5889,
    '1415_883': 31.6146,
    '1415_884': 31.6363,
    '1415_885': 31.6594,
    '1415_886': 31.6848,
    '1415_887': 31.7169,
    '1415_888': 31.7586,
    '1415_889': 31.8272,
    '1415_890': 31.9174,
    '1415_891': 32.0072,
    '1415_892': 32.0682,
    '1415_893': 32.0983,
    '1415_894': 32.1096,
    '1415_895': 32.11,
    '1415_896': 32.1041,
    '1415_897': 32.1061,
    '1415_898': 32.1181,
    '1415_899': 32.139,
    '1415_900': 32.1572,
    '1415_901': 32.1697,
    '1415_902': 32.1874,
    '1415_903': 32.2132,
    '1415_904': 32.2438,
    '1415_905': 32.2773,
    '1415_906': 32.3139,
    '1415_907': 32.3477,
    '1415_908': 32.3826,
    '1415_909': 32.445,
    '1415_910': 32.5242,
    '1415_911': 32.5992,
    '1415_912': 32.6538,
    '1415_913': 32.678,
    '1415_914': 32.6957,
    '1415_915': 32.7145,
    '1415_916': 32.6801,
    '1415_917': 32.6495,
    '1415_918': 32.6219,
    '1415_919': 32.5993,
    '1415_920': 32.5948,
    '1415_921': 32.5829,
    '1415_922': 32.5687,
    '1415_923': 32.5319,
    '1415_924': 32.4803,
    '1415_925': 32.4205,
    '1415_926': 32.36,
    '1415_927': 32.2996,
    '1415_928': 32.2436,
    '1415_929': 32.183,
    '1415_930': 32.1185,
    '1415_931': 32.0588,
    '1415_932': 32.0063,
    '1415_933': 31.9556,
    '1415_934': 31.9108,
    '1415_935': 31.8781,
    '1415_936': 31.8506,
    '1415_937': 31.8169,
    '1415_938': 31.7878,
    '1415_939': 31.7626,
    '1415_940': 31.7467,
    '1415_941': 31.7573,
    '1415_942': 31.7656,
    '1415_943': 31.7585,
    '1415_944': 31.751,
    '1415_945': 31.7601,
    '1415_946': 31.7789,
    '1415_947': 31.7991,
    '1415_948': 31.82,
    '1415_949': 31.8357,
    '1415_950': 31.8355,
    '1415_951': 31.8264,
    '1415_952': 31.8095,
    '1415_953': 31.7881,
    '1415_954': 31.7582,
    '1415_955': 31.7289,
    '1415_956': 31.7055,
    '1415_957': 31.6944,
    '1415_958': 31.6931,
    '1415_959': 31.6884,
    '1415_960': 31.692,
    '1415_961': 31.6994,
    '1415_962': 31.7122,
    '1415_963': 31.7235,
    '1415_964': 31.729,
    '1415_965': 31.7342,
    '1415_966': 31.7456,
    '1415_967': 31.755,
    '1415_968': 31.7563,
    '1415_969': 31.7397,
    '1415_970': 31.6961,
    '1415_971': 31.6218,
    '1415_972': 31.5483,
    '1415_973': 31.4859,
    '1415_974': 31.4461,
    '1415_975': 31.4475,
    '1415_976': 31.4603,
    '1415_977': 31.4801,
    '1415_978': 31.5106,
    '1415_979': 31.5639,
    '1415_980': 31.6253,
    '1415_981': 31.6734,
    '1415_982': 31.7312,
    '1415_983': 31.7903,
    '1415_984': 31.8234,
    '1415_985': 31.8394,
    '1415_986': 31.8434,
    '1415_987': 31.8405,
    '1415_988': 31.8188,
    '1415_989': 31.7926,
    '1415_990': 31.7614,
    '1415_991': 31.725,
    '1415_992': 31.6865,
    '1415_993': 31.6455,
    '1415_994': 31.6075,
    '1415_995': 31.5708,
    '1415_996': 31.5364,
    '1415_997': 31.5063,
    '1415_998': 31.4781,
    '1415_999': 31.4493,
    '1415_1000': 31.4206,
    '1415_1001': 31.3941,
    '1415_1002': 31.3731,
    '1415_1003': 31.3567,
    '1415_1004': 31.3439,
    '1415_1005': 31.3352,
    '1415_1006': 31.3311,
    '1415_1007': 31.3301,
    '1415_1008': 31.3317,
    '1415_1009': 31.3363,
    '1415_1010': 31.3452,
    '1415_1011': 31.3578,
    '1415_1012': 31.3767,
    '1415_1013': 31.4045,
    '1415_1014': 31.441,
    '1415_1015': 31.4775,
    '1415_1016': 31.5319,
    '1416_844': 31.545,
    '1416_845': 31.5735,
    '1416_846': 31.5991,
    '1416_847': 31.623,
    '1416_848': 31.6478,
    '1416_849': 31.6761,
    '1416_850': 31.7092,
    '1416_851': 31.7476,
    '1416_852': 31.7922,
    '1416_853': 31.8439,
    '1416_854': 31.9035,
    '1416_855': 31.9714,
    '1416_856': 32.0443,
    '1416_857': 32.1106,
    '1416_858': 32.1758,
    '1416_859': 32.2366,
    '1416_860': 32.284,
    '1416_861': 32.3225,
    '1416_862': 32.3511,
    '1416_863': 32.3712,
    '1416_864': 32.3635,
    '1416_865': 32.3281,
    '1416_866': 32.2783,
    '1416_867': 32.2112,
    '1416_868': 32.1422,
    '1416_869': 32.0764,
    '1416_870': 32.0117,
    '1416_871': 31.939,
    '1416_872': 31.8602,
    '1416_873': 31.7778,
    '1416_874': 31.6939,
    '1416_875': 31.6264,
    '1416_876': 31.5825,
    '1416_877': 31.5564,
    '1416_878': 31.5433,
    '1416_879': 31.5407,
    '1416_880': 31.547,
    '1416_881': 31.5671,
    '1416_882': 31.5978,
    '1416_883': 31.6299,
    '1416_884': 31.656,
    '1416_885': 31.6803,
    '1416_886': 31.702,
    '1416_887': 31.7263,
    '1416_888': 31.7672,
    '1416_889': 31.841,
    '1416_890': 31.9415,
    '1416_891': 32.0267,
    '1416_892': 32.0786,
    '1416_893': 32.0965,
    '1416_894': 32.1022,
    '1416_895': 32.1013,
    '1416_896': 32.0944,
    '1416_897': 32.0937,
    '1416_898': 32.1072,
    '1416_899': 32.1308,
    '1416_900': 32.1476,
    '1416_901': 32.1592,
    '1416_902': 32.1754,
    '1416_903': 32.2016,
    '1416_904': 32.233,
    '1416_905': 32.2672,
    '1416_906': 32.3004,
    '1416_907': 32.3326,
    '1416_908': 32.3694,
    '1416_909': 32.428,
    '1416_910': 32.5043,
    '1416_911': 32.5806,
    '1416_912': 32.6422,
    '1416_913': 32.6766,
    '1416_914': 32.7109,
    '1416_915': 32.7128,
    '1416_916': 32.6859,
    '1416_917': 32.6636,
    '1416_918': 32.6307,
    '1416_919': 32.5983,
    '1416_920': 32.5782,
    '1416_921': 32.5701,
    '1416_922': 32.5661,
    '1416_923': 32.5426,
    '1416_924': 32.512,
    '1416_925': 32.4628,
    '1416_926': 32.4039,
    '1416_927': 32.3397,
    '1416_928': 32.2686,
    '1416_929': 32.1938,
    '1416_930': 32.1228,
    '1416_931': 32.0582,
    '1416_932': 32.0087,
    '1416_933': 31.9614,
    '1416_934': 31.9098,
    '1416_935': 31.8763,
    '1416_936': 31.8476,
    '1416_937': 31.8145,
    '1416_938': 31.7869,
    '1416_939': 31.7597,
    '1416_940': 31.7388,
    '1416_941': 31.7473,
    '1416_942': 31.757,
    '1416_943': 31.7544,
    '1416_944': 31.748,
    '1416_945': 31.7561,
    '1416_946': 31.7787,
    '1416_947': 31.8003,
    '1416_948': 31.8209,
    '1416_949': 31.8378,
    '1416_950': 31.8383,
    '1416_951': 31.8271,
    '1416_952': 31.8073,
    '1416_953': 31.7817,
    '1416_954': 31.7539,
    '1416_955': 31.7261,
    '1416_956': 31.6952,
    '1416_957': 31.6662,
    '1416_958': 31.6532,
    '1416_959': 31.6457,
    '1416_960': 31.6435,
    '1416_961': 31.6461,
    '1416_962': 31.6516,
    '1416_963': 31.6585,
    '1416_964': 31.6605,
    '1416_965': 31.6602,
    '1416_966': 31.6724,
    '1416_967': 31.6855,
    '1416_968': 31.6958,
    '1416_969': 31.6831,
    '1416_970': 31.6323,
    '1416_971': 31.5623,
    '1416_972': 31.4946,
    '1416_973': 31.4352,
    '1416_974': 31.3972,
    '1416_975': 31.3998,
    '1416_976': 31.4201,
    '1416_977': 31.452,
    '1416_978': 31.4896,
    '1416_979': 31.5428,
    '1416_980': 31.6068,
    '1416_981': 31.6698,
    '1416_982': 31.7255,
    '1416_983': 31.7865,
    '1416_984': 31.8362,
    '1416_985': 31.874,
    '1416_986': 31.893,
    '1416_987': 31.894,
    '1416_988': 31.8818,
    '1416_989': 31.8606,
    '1416_990': 31.8298,
    '1416_991': 31.7932,
    '1416_992': 31.7547,
    '1416_993': 31.7146,
    '1416_994': 31.6774,
    '1416_995': 31.635,
    '1416_996': 31.5909,
    '1416_997': 31.552,
    '1416_998': 31.515,
    '1416_999': 31.4778,
    '1416_1000': 31.4409,
    '1416_1001': 31.4056,
    '1416_1002': 31.3761,
    '1416_1003': 31.3531,
    '1416_1004': 31.3353,
    '1416_1005': 31.3233,
    '1416_1006': 31.3169,
    '1416_1007': 31.3142,
    '1416_1008': 31.3154,
    '1416_1009': 31.3213,
    '1416_1010': 31.3326,
    '1416_1011': 31.3475,
    '1416_1012': 31.3691,
    '1416_1013': 31.3979,
    '1416_1014': 31.4375,
    '1416_1015': 31.4752,
    '1416_1016': 31.5297,
    '1417_844': 31.5235,
    '1417_845': 31.5523,
    '1417_846': 31.5782,
    '1417_847': 31.6024,
    '1417_848': 31.6285,
    '1417_849': 31.659,
    '1417_850': 31.6946,
    '1417_851': 31.7359,
    '1417_852': 31.7838,
    '1417_853': 31.8394,
    '1417_854': 31.9052,
    '1417_855': 31.9839,
    '1417_856': 32.0705,
    '1417_857': 32.1466,
    '1417_858': 32.2145,
    '1417_859': 32.2764,
    '1417_860': 32.3182,
    '1417_861': 32.3531,
    '1417_862': 32.3828,
    '1417_863': 32.3952,
    '1417_864': 32.3779,
    '1417_865': 32.3404,
    '1417_866': 32.2873,
    '1417_867': 32.2109,
    '1417_868': 32.1352,
    '1417_869': 32.0703,
    '1417_870': 32.0023,
    '1417_871': 31.9257,
    '1417_872': 31.8479,
    '1417_873': 31.7685,
    '1417_874': 31.6836,
    '1417_875': 31.6107,
    '1417_876': 31.5631,
    '1417_877': 31.5346,
    '1417_878': 31.5213,
    '1417_879': 31.5204,
    '1417_880': 31.5323,
    '1417_881': 31.5611,
    '1417_882': 31.602,
    '1417_883': 31.6426,
    '1417_884': 31.6746,
    '1417_885': 31.6991,
    '1417_886': 31.7136,
    '1417_887': 31.7324,
    '1417_888': 31.7745,
    '1417_889': 31.8516,
    '1417_890': 31.9524,
    '1417_891': 32.0337,
    '1417_892': 32.0783,
    '1417_893': 32.0925,
    '1417_894': 32.0979,
    '1417_895': 32.0948,
    '1417_896': 32.0835,
    '1417_897': 32.0833,
    '1417_898': 32.1,
    '1417_899': 32.1249,
    '1417_900': 32.1448,
    '1417_901': 32.1566,
    '1417_902': 32.171,
    '1417_903': 32.1951,
    '1417_904': 32.2256,
    '1417_905': 32.2617,
    '1417_906': 32.2963,
    '1417_907': 32.3277,
    '1417_908': 32.3661,
    '1417_909': 32.4246,
    '1417_910': 32.4978,
    '1417_911': 32.5705,
    '1417_912': 32.6331,
    '1417_913': 32.6799,
    '1417_914': 32.7158,
    '1417_915': 32.7196,
    '1417_916': 32.7046,
    '1417_917': 32.6867,
    '1417_918': 32.6401,
    '1417_919': 32.5991,
    '1417_920': 32.5694,
    '1417_921': 32.5565,
    '1417_922': 32.5555,
    '1417_923': 32.5462,
    '1417_924': 32.5247,
    '1417_925': 32.485,
    '1417_926': 32.4344,
    '1417_927': 32.3671,
    '1417_928': 32.2807,
    '1417_929': 32.1963,
    '1417_930': 32.1168,
    '1417_931': 32.0531,
    '1417_932': 32.0031,
    '1417_933': 31.9597,
    '1417_934': 31.9123,
    '1417_935': 31.8783,
    '1417_936': 31.8477,
    '1417_937': 31.8131,
    '1417_938': 31.7787,
    '1417_939': 31.7472,
    '1417_940': 31.7267,
    '1417_941': 31.7285,
    '1417_942': 31.7363,
    '1417_943': 31.7371,
    '1417_944': 31.7367,
    '1417_945': 31.7483,
    '1417_946': 31.769,
    '1417_947': 31.7943,
    '1417_948': 31.8147,
    '1417_949': 31.8297,
    '1417_950': 31.8329,
    '1417_951': 31.8231,
    '1417_952': 31.8015,
    '1417_953': 31.7762,
    '1417_954': 31.7499,
    '1417_955': 31.7201,
    '1417_956': 31.6829,
    '1417_957': 31.6449,
    '1417_958': 31.6212,
    '1417_959': 31.6035,
    '1417_960': 31.5956,
    '1417_961': 31.596,
    '1417_962': 31.5961,
    '1417_963': 31.5994,
    '1417_964': 31.5933,
    '1417_965': 31.5877,
    '1417_966': 31.5955,
    '1417_967': 31.6093,
    '1417_968': 31.6195,
    '1417_969': 31.6109,
    '1417_970': 31.57,
    '1417_971': 31.5095,
    '1417_972': 31.4552,
    '1417_973': 31.4038,
    '1417_974': 31.3674,
    '1417_975': 31.3674,
    '1417_976': 31.3921,
    '1417_977': 31.4299,
    '1417_978': 31.4665,
    '1417_979': 31.5177,
    '1417_980': 31.5825,
    '1417_981': 31.6502,
    '1417_982': 31.7142,
    '1417_983': 31.7802,
    '1417_984': 31.8425,
    '1417_985': 31.8963,
    '1417_986': 31.9287,
    '1417_987': 31.9377,
    '1417_988': 31.9353,
    '1417_989': 31.9216,
    '1417_990': 31.8987,
    '1417_991': 31.8624,
    '1417_992': 31.825,
    '1417_993': 31.7879,
    '1417_994': 31.7515,
    '1417_995': 31.7066,
    '1417_996': 31.6549,
    '1417_997': 31.6061,
    '1417_998': 31.5586,
    '1417_999': 31.5118,
    '1417_1000': 31.4641,
    '1417_1001': 31.4194,
    '1417_1002': 31.3813,
    '1417_1003': 31.351,
    '1417_1004': 31.3277,
    '1417_1005': 31.3127,
    '1417_1006': 31.3041,
    '1417_1007': 31.3002,
    '1417_1008': 31.3013,
    '1417_1009': 31.3084,
    '1417_1010': 31.3231,
    '1417_1011': 31.3418,
    '1417_1012': 31.3653,
    '1417_1013': 31.3968,
    '1417_1014': 31.4283,
    '1417_1015': 31.4733,
    '1417_1016': 31.5272,
    '1418_844': 31.4998,
    '1418_845': 31.5271,
    '1418_846': 31.5519,
    '1418_847': 31.5757,
    '1418_848': 31.6036,
    '1418_849': 31.6369,
    '1418_850': 31.6758,
    '1418_851': 31.7208,
    '1418_852': 31.7728,
    '1418_853': 31.8341,
    '1418_854': 31.908,
    '1418_855': 32.0013,
    '1418_856': 32.1024,
    '1418_857': 32.1919,
    '1418_858': 32.2635,
    '1418_859': 32.3255,
    '1418_860': 32.3684,
    '1418_861': 32.4024,
    '1418_862': 32.4185,
    '1418_863': 32.4137,
    '1418_864': 32.3881,
    '1418_865': 32.3501,
    '1418_866': 32.29,
    '1418_867': 32.2098,
    '1418_868': 32.1309,
    '1418_869': 32.0609,
    '1418_870': 31.9832,
    '1418_871': 31.9052,
    '1418_872': 31.8294,
    '1418_873': 31.7512,
    '1418_874': 31.6689,
    '1418_875': 31.591,
    '1418_876': 31.5384,
    '1418_877': 31.5063,
    '1418_878': 31.4909,
    '1418_879': 31.4889,
    '1418_880': 31.504,
    '1418_881': 31.5378,
    '1418_882': 31.5867,
    '1418_883': 31.6405,
    '1418_884': 31.6815,
    '1418_885': 31.7054,
    '1418_886': 31.7137,
    '1418_887': 31.7323,
    '1418_888': 31.7814,
    '1418_889': 31.8668,
    '1418_890': 31.9611,
    '1418_891': 32.0338,
    '1418_892': 32.071,
    '1418_893': 32.0829,
    '1418_894': 32.0889,
    '1418_895': 32.0841,
    '1418_896': 32.0749,
    '1418_897': 32.0767,
    '1418_898': 32.0949,
    '1418_899': 32.1226,
    '1418_900': 32.1426,
    '1418_901': 32.1547,
    '1418_902': 32.1683,
    '1418_903': 32.1893,
    '1418_904': 32.2196,
    '1418_905': 32.2584,
    '1418_906': 32.2984,
    '1418_907': 32.3346,
    '1418_908': 32.375,
    '1418_909': 32.4304,
    '1418_910': 32.4969,
    '1418_911': 32.5659,
    '1418_912': 32.629,
    '1418_913': 32.683,
    '1418_914': 32.7227,
    '1418_915': 32.7383,
    '1418_916': 32.7205,
    '1418_917': 32.6961,
    '1418_918': 32.6503,
    '1418_919': 32.6024,
    '1418_920': 32.5651,
    '1418_921': 32.5488,
    '1418_922': 32.547,
    '1418_923': 32.5456,
    '1418_924': 32.532,
    '1418_925': 32.5011,
    '1418_926': 32.4546,
    '1418_927': 32.384,
    '1418_928': 32.2982,
    '1418_929': 32.2059,
    '1418_930': 32.1237,
    '1418_931': 32.0545,
    '1418_932': 32.0044,
    '1418_933': 31.9696,
    '1418_934': 31.926,
    '1418_935': 31.8854,
    '1418_936': 31.8505,
    '1418_937': 31.813,
    '1418_938': 31.7741,
    '1418_939': 31.739,
    '1418_940': 31.7121,
    '1418_941': 31.7024,
    '1418_942': 31.7063,
    '1418_943': 31.712,
    '1418_944': 31.7225,
    '1418_945': 31.738,
    '1418_946': 31.7542,
    '1418_947': 31.7764,
    '1418_948': 31.7985,
    '1418_949': 31.8117,
    '1418_950': 31.8143,
    '1418_951': 31.8085,
    '1418_952': 31.791,
    '1418_953': 31.7682,
    '1418_954': 31.7407,
    '1418_955': 31.7062,
    '1418_956': 31.6651,
    '1418_957': 31.6251,
    '1418_958': 31.5927,
    '1418_959': 31.5677,
    '1418_960': 31.5502,
    '1418_961': 31.5442,
    '1418_962': 31.5408,
    '1418_963': 31.5407,
    '1418_964': 31.5312,
    '1418_965': 31.5254,
    '1418_966': 31.5216,
    '1418_967': 31.5246,
    '1418_968': 31.5299,
    '1418_969': 31.5251,
    '1418_970': 31.5034,
    '1418_971': 31.4656,
    '1418_972': 31.427,
    '1418_973': 31.3876,
    '1418_974': 31.357,
    '1418_975': 31.352,
    '1418_976': 31.3768,
    '1418_977': 31.4094,
    '1418_978': 31.4438,
    '1418_979': 31.494,
    '1418_980': 31.5524,
    '1418_981': 31.6178,
    '1418_982': 31.6884,
    '1418_983': 31.7663,
    '1418_984': 31.8404,
    '1418_985': 31.9034,
    '1418_986': 31.9473,
    '1418_987': 31.9693,
    '1418_988': 31.9782,
    '1418_989': 31.9742,
    '1418_990': 31.9604,
    '1418_991': 31.9294,
    '1418_992': 31.8947,
    '1418_993': 31.8595,
    '1418_994': 31.8242,
    '1418_995': 31.7777,
    '1418_996': 31.7215,
    '1418_997': 31.6621,
    '1418_998': 31.6037,
    '1418_999': 31.5477,
    '1418_1000': 31.4898,
    '1418_1001': 31.4352,
    '1418_1002': 31.3888,
    '1418_1003': 31.3513,
    '1418_1004': 31.3233,
    '1418_1005': 31.3036,
    '1418_1006': 31.2921,
    '1418_1007': 31.2872,
    '1418_1008': 31.2894,
    '1418_1009': 31.2981,
    '1418_1010': 31.3141,
    '1418_1011': 31.3343,
    '1418_1012': 31.3526,
    '1418_1013': 31.3856,
    '1418_1014': 31.4252,
    '1418_1015': 31.4706,
    '1418_1016': 31.5232,
    '1419_844': 31.4699,
    '1419_845': 31.4957,
    '1419_846': 31.5198,
    '1419_847': 31.5427,
    '1419_848': 31.5733,
    '1419_849': 31.6103,
    '1419_850': 31.6534,
    '1419_851': 31.7028,
    '1419_852': 31.7602,
    '1419_853': 31.8286,
    '1419_854': 31.9116,
    '1419_855': 32.0149,
    '1419_856': 32.1279,
    '1419_857': 32.2325,
    '1419_858': 32.3156,
    '1419_859': 32.3773,
    '1419_860': 32.4203,
    '1419_861': 32.444,
    '1419_862': 32.4462,
    '1419_863': 32.428,
    '1419_864': 32.3944,
    '1419_865': 32.3483,
    '1419_866': 32.2834,
    '1419_867': 32.2054,
    '1419_868': 32.1254,
    '1419_869': 32.0487,
    '1419_870': 31.9617,
    '1419_871': 31.8811,
    '1419_872': 31.8018,
    '1419_873': 31.7193,
    '1419_874': 31.6351,
    '1419_875': 31.5587,
    '1419_876': 31.5048,
    '1419_877': 31.4707,
    '1419_878': 31.4526,
    '1419_879': 31.4486,
    '1419_880': 31.4637,
    '1419_881': 31.501,
    '1419_882': 31.554,
    '1419_883': 31.615,
    '1419_884': 31.6638,
    '1419_885': 31.6895,
    '1419_886': 31.6986,
    '1419_887': 31.7232,
    '1419_888': 31.7835,
    '1419_889': 31.8801,
    '1419_890': 31.9707,
    '1419_891': 32.0324,
    '1419_892': 32.0584,
    '1419_893': 32.0664,
    '1419_894': 32.0771,
    '1419_895': 32.0735,
    '1419_896': 32.0653,
    '1419_897': 32.0679,
    '1419_898': 32.0858,
    '1419_899': 32.1122,
    '1419_900': 32.135,
    '1419_901': 32.1491,
    '1419_902': 32.1601,
    '1419_903': 32.1791,
    '1419_904': 32.2114,
    '1419_905': 32.2528,
    '1419_906': 32.2992,
    '1419_907': 32.3473,
    '1419_908': 32.3966,
    '1419_909': 32.4471,
    '1419_910': 32.5057,
    '1419_911': 32.5703,
    '1419_912': 32.6348,
    '1419_913': 32.6895,
    '1419_914': 32.7217,
    '1419_915': 32.7413,
    '1419_916': 32.7283,
    '1419_917': 32.6943,
    '1419_918': 32.6625,
    '1419_919': 32.6108,
    '1419_920': 32.5687,
    '1419_921': 32.5508,
    '1419_922': 32.5525,
    '1419_923': 32.5512,
    '1419_924': 32.542,
    '1419_925': 32.519,
    '1419_926': 32.4748,
    '1419_927': 32.407,
    '1419_928': 32.3187,
    '1419_929': 32.2249,
    '1419_930': 32.1436,
    '1419_931': 32.0736,
    '1419_932': 32.0203,
    '1419_933': 31.9796,
    '1419_934': 31.9321,
    '1419_935': 31.8885,
    '1419_936': 31.8464,
    '1419_937': 31.8077,
    '1419_938': 31.7702,
    '1419_939': 31.7346,
    '1419_940': 31.7036,
    '1419_941': 31.6841,
    '1419_942': 31.6805,
    '1419_943': 31.6867,
    '1419_944': 31.701,
    '1419_945': 31.7143,
    '1419_946': 31.7261,
    '1419_947': 31.7455,
    '1419_948': 31.7668,
    '1419_949': 31.7786,
    '1419_950': 31.7817,
    '1419_951': 31.7788,
    '1419_952': 31.7674,
    '1419_953': 31.7477,
    '1419_954': 31.7221,
    '1419_955': 31.6902,
    '1419_956': 31.6519,
    '1419_957': 31.6071,
    '1419_958': 31.5675,
    '1419_959': 31.5348,
    '1419_960': 31.5075,
    '1419_961': 31.4932,
    '1419_962': 31.4843,
    '1419_963': 31.4775,
    '1419_964': 31.4703,
    '1419_965': 31.4586,
    '1419_966': 31.4432,
    '1419_967': 31.4364,
    '1419_968': 31.4374,
    '1419_969': 31.4397,
    '1419_970': 31.4359,
    '1419_971': 31.4218,
    '1419_972': 31.3965,
    '1419_973': 31.3758,
    '1419_974': 31.3591,
    '1419_975': 31.3547,
    '1419_976': 31.3695,
    '1419_977': 31.3935,
    '1419_978': 31.4287,
    '1419_979': 31.4766,
    '1419_980': 31.5279,
    '1419_981': 31.5859,
    '1419_982': 31.6584,
    '1419_983': 31.74,
    '1419_984': 31.8222,
    '1419_985': 31.8897,
    '1419_986': 31.9439,
    '1419_987': 31.9839,
    '1419_988': 32.0118,
    '1419_989': 32.0191,
    '1419_990': 32.0089,
    '1419_991': 31.9868,
    '1419_992': 31.9599,
    '1419_993': 31.9257,
    '1419_994': 31.8909,
    '1419_995': 31.8444,
    '1419_996': 31.7871,
    '1419_997': 31.7171,
    '1419_998': 31.6459,
    '1419_999': 31.5801,
    '1419_1000': 31.5135,
    '1419_1001': 31.451,
    '1419_1002': 31.3962,
    '1419_1003': 31.351,
    '1419_1004': 31.3178,
    '1419_1005': 31.2942,
    '1419_1006': 31.2803,
    '1419_1007': 31.2752,
    '1419_1008': 31.2772,
    '1419_1009': 31.2803,
    '1419_1010': 31.2963,
    '1419_1011': 31.318,
    '1419_1012': 31.3451,
    '1419_1013': 31.3805,
    '1419_1014': 31.4213,
    '1419_1015': 31.4664,
    '1419_1016': 31.5176,
    '1420_844': 31.4289,
    '1420_845': 31.4527,
    '1420_846': 31.4768,
    '1420_847': 31.5049,
    '1420_848': 31.5397,
    '1420_849': 31.581,
    '1420_850': 31.6285,
    '1420_851': 31.6831,
    '1420_852': 31.7471,
    '1420_853': 31.825,
    '1420_854': 31.9221,
    '1420_855': 32.0369,
    '1420_856': 32.1527,
    '1420_857': 32.26,
    '1420_858': 32.3504,
    '1420_859': 32.4166,
    '1420_860': 32.4577,
    '1420_861': 32.4721,
    '1420_862': 32.4629,
    '1420_863': 32.4346,
    '1420_864': 32.3902,
    '1420_865': 32.3345,
    '1420_866': 32.2627,
    '1420_867': 32.1826,
    '1420_868': 32.105,
    '1420_869': 32.023,
    '1420_870': 31.9311,
    '1420_871': 31.8443,
    '1420_872': 31.7612,
    '1420_873': 31.6748,
    '1420_874': 31.5889,
    '1420_875': 31.5167,
    '1420_876': 31.4657,
    '1420_877': 31.4312,
    '1420_878': 31.4112,
    '1420_879': 31.4054,
    '1420_880': 31.4165,
    '1420_881': 31.4482,
    '1420_882': 31.4991,
    '1420_883': 31.5619,
    '1420_884': 31.6157,
    '1420_885': 31.6481,
    '1420_886': 31.6684,
    '1420_887': 31.7095,
    '1420_888': 31.7871,
    '1420_889': 31.8884,
    '1420_890': 31.9723,
    '1420_891': 32.0242,
    '1420_892': 32.0424,
    '1420_893': 32.0513,
    '1420_894': 32.0642,
    '1420_895': 32.061,
    '1420_896': 32.053,
    '1420_897': 32.0569,
    '1420_898': 32.0753,
    '1420_899': 32.1026,
    '1420_900': 32.1297,
    '1420_901': 32.1427,
    '1420_902': 32.1512,
    '1420_903': 32.169,
    '1420_904': 32.2021,
    '1420_905': 32.2446,
    '1420_906': 32.2968,
    '1420_907': 32.353,
    '1420_908': 32.4106,
    '1420_909': 32.4633,
    '1420_910': 32.5197,
    '1420_911': 32.5774,
    '1420_912': 32.6373,
    '1420_913': 32.6878,
    '1420_914': 32.7108,
    '1420_915': 32.7245,
    '1420_916': 32.72,
    '1420_917': 32.6824,
    '1420_918': 32.6586,
    '1420_919': 32.615,
    '1420_920': 32.5777,
    '1420_921': 32.5649,
    '1420_922': 32.5649,
    '1420_923': 32.5637,
    '1420_924': 32.5554,
    '1420_925': 32.536,
    '1420_926': 32.4967,
    '1420_927': 32.4387,
    '1420_928': 32.3531,
    '1420_929': 32.258,
    '1420_930': 32.1738,
    '1420_931': 32.0999,
    '1420_932': 32.0392,
    '1420_933': 31.9869,
    '1420_934': 31.9354,
    '1420_935': 31.885,
    '1420_936': 31.8363,
    '1420_937': 31.7919,
    '1420_938': 31.7552,
    '1420_939': 31.7224,
    '1420_940': 31.6943,
    '1420_941': 31.673,
    '1420_942': 31.6607,
    '1420_943': 31.6604,
    '1420_944': 31.6681,
    '1420_945': 31.6772,
    '1420_946': 31.6874,
    '1420_947': 31.702,
    '1420_948': 31.717,
    '1420_949': 31.7269,
    '1420_950': 31.7314,
    '1420_951': 31.7303,
    '1420_952': 31.7261,
    '1420_953': 31.7128,
    '1420_954': 31.6911,
    '1420_955': 31.6616,
    '1420_956': 31.6229,
    '1420_957': 31.5774,
    '1420_958': 31.5379,
    '1420_959': 31.5024,
    '1420_960': 31.4688,
    '1420_961': 31.4432,
    '1420_962': 31.4287,
    '1420_963': 31.4168,
    '1420_964': 31.4059,
    '1420_965': 31.3868,
    '1420_966': 31.3652,
    '1420_967': 31.3495,
    '1420_968': 31.3466,
    '1420_969': 31.357,
    '1420_970': 31.3727,
    '1420_971': 31.3834,
    '1420_972': 31.3816,
    '1420_973': 31.3763,
    '1420_974': 31.3684,
    '1420_975': 31.3625,
    '1420_976': 31.3706,
    '1420_977': 31.3878,
    '1420_978': 31.4181,
    '1420_979': 31.456,
    '1420_980': 31.4992,
    '1420_981': 31.5499,
    '1420_982': 31.6222,
    '1420_983': 31.704,
    '1420_984': 31.7883,
    '1420_985': 31.8626,
    '1420_986': 31.9255,
    '1420_987': 31.9822,
    '1420_988': 32.0257,
    '1420_989': 32.048,
    '1420_990': 32.0441,
    '1420_991': 32.0285,
    '1420_992': 32.0108,
    '1420_993': 31.9848,
    '1420_994': 31.9517,
    '1420_995': 31.9079,
    '1420_996': 31.8442,
    '1420_997': 31.7636,
    '1420_998': 31.6805,
    '1420_999': 31.6046,
    '1420_1000': 31.5291,
    '1420_1001': 31.4606,
    '1420_1002': 31.4011,
    '1420_1003': 31.3512,
    '1420_1004': 31.3126,
    '1420_1005': 31.2852,
    '1420_1006': 31.2703,
    '1420_1007': 31.2581,
    '1420_1008': 31.2589,
    '1420_1009': 31.2681,
    '1420_1010': 31.2852,
    '1420_1011': 31.3083,
    '1420_1012': 31.3378,
    '1420_1013': 31.3743,
    '1420_1014': 31.4154,
    '1420_1015': 31.4599,
    '1420_1016': 31.5099,
    '1421_844': 31.3835,
    '1421_845': 31.4058,
    '1421_846': 31.43,
    '1421_847': 31.4634,
    '1421_848': 31.5032,
    '1421_849': 31.549,
    '1421_850': 31.6011,
    '1421_851': 31.6611,
    '1421_852': 31.7322,
    '1421_853': 31.8216,
    '1421_854': 31.9371,
    '1421_855': 32.0597,
    '1421_856': 32.1799,
    '1421_857': 32.2854,
    '1421_858': 32.3696,
    '1421_859': 32.4324,
    '1421_860': 32.4679,
    '1421_861': 32.481,
    '1421_862': 32.4624,
    '1421_863': 32.4252,
    '1421_864': 32.3744,
    '1421_865': 32.3119,
    '1421_866': 32.2369,
    '1421_867': 32.1479,
    '1421_868': 32.0609,
    '1421_869': 31.9731,
    '1421_870': 31.8837,
    '1421_871': 31.7982,
    '1421_872': 31.7101,
    '1421_873': 31.6237,
    '1421_874': 31.5424,
    '1421_875': 31.472,
    '1421_876': 31.4223,
    '1421_877': 31.3884,
    '1421_878': 31.3693,
    '1421_879': 31.3649,
    '1421_880': 31.3758,
    '1421_881': 31.4026,
    '1421_882': 31.4449,
    '1421_883': 31.499,
    '1421_884': 31.5542,
    '1421_885': 31.5986,
    '1421_886': 31.6354,
    '1421_887': 31.6953,
    '1421_888': 31.7922,
    '1421_889': 31.8917,
    '1421_890': 31.9625,
    '1421_891': 32.0083,
    '1421_892': 32.0279,
    '1421_893': 32.0404,
    '1421_894': 32.0496,
    '1421_895': 32.0457,
    '1421_896': 32.039,
    '1421_897': 32.0446,
    '1421_898': 32.065,
    '1421_899': 32.0939,
    '1421_900': 32.1208,
    '1421_901': 32.1359,
    '1421_902': 32.1444,
    '1421_903': 32.1613,
    '1421_904': 32.192,
    '1421_905': 32.2344,
    '1421_906': 32.2878,
    '1421_907': 32.3479,
    '1421_908': 32.407,
    '1421_909': 32.4615,
    '1421_910': 32.5159,
    '1421_911': 32.5753,
    '1421_912': 32.6333,
    '1421_913': 32.6821,
    '1421_914': 32.7132,
    '1421_915': 32.7199,
    '1421_916': 32.715,
    '1421_917': 32.6857,
    '1421_918': 32.65,
    '1421_919': 32.6115,
    '1421_920': 32.5836,
    '1421_921': 32.5796,
    '1421_922': 32.579,
    '1421_923': 32.5788,
    '1421_924': 32.5715,
    '1421_925': 32.5549,
    '1421_926': 32.5196,
    '1421_927': 32.4655,
    '1421_928': 32.3847,
    '1421_929': 32.2953,
    '1421_930': 32.2057,
    '1421_931': 32.1246,
    '1421_932': 32.0487,
    '1421_933': 31.9785,
    '1421_934': 31.9215,
    '1421_935': 31.8655,
    '1421_936': 31.8147,
    '1421_937': 31.7645,
    '1421_938': 31.7258,
    '1421_939': 31.6969,
    '1421_940': 31.6752,
    '1421_941': 31.6591,
    '1421_942': 31.647,
    '1421_943': 31.6408,
    '1421_944': 31.6342,
    '1421_945': 31.6359,
    '1421_946': 31.6449,
    '1421_947': 31.6544,
    '1421_948': 31.657,
    '1421_949': 31.6617,
    '1421_950': 31.663,
    '1421_951': 31.6674,
    '1421_952': 31.671,
    '1421_953': 31.6658,
    '1421_954': 31.6476,
    '1421_955': 31.6219,
    '1421_956': 31.5869,
    '1421_957': 31.5476,
    '1421_958': 31.506,
    '1421_959': 31.4649,
    '1421_960': 31.4296,
    '1421_961': 31.3956,
    '1421_962': 31.3751,
    '1421_963': 31.3571,
    '1421_964': 31.3394,
    '1421_965': 31.3131,
    '1421_966': 31.2882,
    '1421_967': 31.2676,
    '1421_968': 31.2628,
    '1421_969': 31.2782,
    '1421_970': 31.3056,
    '1421_971': 31.3358,
    '1421_972': 31.361,
    '1421_973': 31.3756,
    '1421_974': 31.3736,
    '1421_975': 31.3699,
    '1421_976': 31.3748,
    '1421_977': 31.3856,
    '1421_978': 31.4021,
    '1421_979': 31.4271,
    '1421_980': 31.4623,
    '1421_981': 31.507,
    '1421_982': 31.5763,
    '1421_983': 31.6591,
    '1421_984': 31.7465,
    '1421_985': 31.8264,
    '1421_986': 31.8993,
    '1421_987': 31.9647,
    '1421_988': 32.0203,
    '1421_989': 32.0515,
    '1421_990': 32.0578,
    '1421_991': 32.051,
    '1421_992': 32.0464,
    '1421_993': 32.0301,
    '1421_994': 31.9996,
    '1421_995': 31.9508,
    '1421_996': 31.8781,
    '1421_997': 31.7887,
    '1421_998': 31.6965,
    '1421_999': 31.6096,
    '1421_1000': 31.5308,
    '1421_1001': 31.4614,
    '1421_1002': 31.3993,
    '1421_1003': 31.3458,
    '1421_1004': 31.3045,
    '1421_1005': 31.2765,
    '1421_1006': 31.2593,
    '1421_1007': 31.2453,
    '1421_1008': 31.2457,
    '1421_1009': 31.2561,
    '1421_1010': 31.274,
    '1421_1011': 31.2979,
    '1421_1012': 31.3282,
    '1421_1013': 31.3652,
    '1421_1014': 31.4064,
    '1421_1015': 31.4507,
    '1421_1016': 31.5002,
    '1422_844': 31.3407,
    '1422_845': 31.3626,
    '1422_846': 31.3887,
    '1422_847': 31.4244,
    '1422_848': 31.4675,
    '1422_849': 31.5169,
    '1422_850': 31.573,
    '1422_851': 31.6378,
    '1422_852': 31.7162,
    '1422_853': 31.8163,
    '1422_854': 31.9482,
    '1422_855': 32.0769,
    '1422_856': 32.1962,
    '1422_857': 32.2939,
    '1422_858': 32.366,
    '1422_859': 32.4224,
    '1422_860': 32.4565,
    '1422_861': 32.4612,
    '1422_862': 32.4409,
    '1422_863': 32.396,
    '1422_864': 32.3435,
    '1422_865': 32.2821,
    '1422_866': 32.2063,
    '1422_867': 32.1118,
    '1422_868': 32.0132,
    '1422_869': 31.9183,
    '1422_870': 31.8283,
    '1422_871': 31.7411,
    '1422_872': 31.6505,
    '1422_873': 31.5654,
    '1422_874': 31.4932,
    '1422_875': 31.4293,
    '1422_876': 31.3806,
    '1422_877': 31.3483,
    '1422_878': 31.3317,
    '1422_879': 31.3304,
    '1422_880': 31.3439,
    '1422_881': 31.3704,
    '1422_882': 31.4078,
    '1422_883': 31.4549,
    '1422_884': 31.5084,
    '1422_885': 31.5605,
    '1422_886': 31.6143,
    '1422_887': 31.6825,
    '1422_888': 31.7828,
    '1422_889': 31.8765,
    '1422_890': 31.9409,
    '1422_891': 31.9908,
    '1422_892': 32.0206,
    '1422_893': 32.0354,
    '1422_894': 32.0351,
    '1422_895': 32.0295,
    '1422_896': 32.0248,
    '1422_897': 32.0319,
    '1422_898': 32.053,
    '1422_899': 32.0838,
    '1422_900': 32.1128,
    '1422_901': 32.1317,
    '1422_902': 32.1421,
    '1422_903': 32.1569,
    '1422_904': 32.1855,
    '1422_905': 32.2259,
    '1422_906': 32.2768,
    '1422_907': 32.3352,
    '1422_908': 32.3952,
    '1422_909': 32.4508,
    '1422_910': 32.5024,
    '1422_911': 32.5569,
    '1422_912': 32.6116,
    '1422_913': 32.663,
    '1422_914': 32.6816,
    '1422_915': 32.6953,
    '1422_916': 32.6957,
    '1422_917': 32.6794,
    '1422_918': 32.6473,
    '1422_919': 32.6124,
    '1422_920': 32.5988,
    '1422_921': 32.5975,
    '1422_922': 32.6021,
    '1422_923': 32.6019,
    '1422_924': 32.5919,
    '1422_925': 32.5748,
    '1422_926': 32.5385,
    '1422_927': 32.4794,
    '1422_928': 32.4075,
    '1422_929': 32.3105,
    '1422_930': 32.2227,
    '1422_931': 32.1353,
    '1422_932': 32.0473,
    '1422_933': 31.9641,
    '1422_934': 31.8906,
    '1422_935': 31.8285,
    '1422_936': 31.7728,
    '1422_937': 31.7273,
    '1422_938': 31.6873,
    '1422_939': 31.6576,
    '1422_940': 31.644,
    '1422_941': 31.6328,
    '1422_942': 31.621,
    '1422_943': 31.6127,
    '1422_944': 31.6034,
    '1422_945': 31.602,
    '1422_946': 31.6082,
    '1422_947': 31.6121,
    '1422_948': 31.6053,
    '1422_949': 31.5974,
    '1422_950': 31.5919,
    '1422_951': 31.5979,
    '1422_952': 31.6074,
    '1422_953': 31.6132,
    '1422_954': 31.6035,
    '1422_955': 31.5799,
    '1422_956': 31.5487,
    '1422_957': 31.5121,
    '1422_958': 31.4726,
    '1422_959': 31.4296,
    '1422_960': 31.3877,
    '1422_961': 31.3516,
    '1422_962': 31.3255,
    '1422_963': 31.302,
    '1422_964': 31.2776,
    '1422_965': 31.2439,
    '1422_966': 31.2151,
    '1422_967': 31.1927,
    '1422_968': 31.187,
    '1422_969': 31.2036,
    '1422_970': 31.2361,
    '1422_971': 31.2768,
    '1422_972': 31.3207,
    '1422_973': 31.3501,
    '1422_974': 31.3573,
    '1422_975': 31.3497,
    '1422_976': 31.3485,
    '1422_977': 31.3579,
    '1422_978': 31.3729,
    '1422_979': 31.3924,
    '1422_980': 31.4201,
    '1422_981': 31.4608,
    '1422_982': 31.5305,
    '1422_983': 31.6157,
    '1422_984': 31.7029,
    '1422_985': 31.7834,
    '1422_986': 31.8623,
    '1422_987': 31.9339,
    '1422_988': 31.995,
    '1422_989': 32.0344,
    '1422_990': 32.0479,
    '1422_991': 32.0503,
    '1422_992': 32.0531,
    '1422_993': 32.0432,
    '1422_994': 32.0152,
    '1422_995': 31.9645,
    '1422_996': 31.8851,
    '1422_997': 31.7908,
    '1422_998': 31.6946,
    '1422_999': 31.6032,
    '1422_1000': 31.5243,
    '1422_1001': 31.4554,
    '1422_1002': 31.3905,
    '1422_1003': 31.3335,
    '1422_1004': 31.2901,
    '1422_1005': 31.2637,
    '1422_1006': 31.2406,
    '1422_1007': 31.2315,
    '1422_1008': 31.2327,
    '1422_1009': 31.2436,
    '1422_1010': 31.2621,
    '1422_1011': 31.2865,
    '1422_1012': 31.317,
    '1422_1013': 31.3543,
    '1422_1014': 31.3958,
    '1422_1015': 31.4412,
    '1422_1016': 31.4926,
    '1423_844': 31.2988,
    '1423_845': 31.3206,
    '1423_846': 31.3471,
    '1423_847': 31.3858,
    '1423_848': 31.4316,
    '1423_849': 31.4835,
    '1423_850': 31.5422,
    '1423_851': 31.6103,
    '1423_852': 31.6937,
    '1423_853': 31.8038,
    '1423_854': 31.946,
    '1423_855': 32.0748,
    '1423_856': 32.1897,
    '1423_857': 32.2765,
    '1423_858': 32.338,
    '1423_859': 32.384,
    '1423_860': 32.4114,
    '1423_861': 32.408,
    '1423_862': 32.3906,
    '1423_863': 32.348,
    '1423_864': 32.2968,
    '1423_865': 32.2384,
    '1423_866': 32.1638,
    '1423_867': 32.0703,
    '1423_868': 31.9682,
    '1423_869': 31.8635,
    '1423_870': 31.7675,
    '1423_871': 31.6777,
    '1423_872': 31.5899,
    '1423_873': 31.5097,
    '1423_874': 31.4421,
    '1423_875': 31.3859,
    '1423_876': 31.3415,
    '1423_877': 31.3131,
    '1423_878': 31.3015,
    '1423_879': 31.3045,
    '1423_880': 31.3209,
    '1423_881': 31.3485,
    '1423_882': 31.3852,
    '1423_883': 31.4309,
    '1423_884': 31.4831,
    '1423_885': 31.5408,
    '1423_886': 31.6006,
    '1423_887': 31.6734,
    '1423_888': 31.7661,
    '1423_889': 31.8497,
    '1423_890': 31.9171,
    '1423_891': 31.9767,
    '1423_892': 32.0172,
    '1423_893': 32.0306,
    '1423_894': 32.0265,
    '1423_895': 32.0195,
    '1423_896': 32.0147,
    '1423_897': 32.0219,
    '1423_898': 32.0445,
    '1423_899': 32.0735,
    '1423_900': 32.1056,
    '1423_901': 32.1274,
    '1423_902': 32.1419,
    '1423_903': 32.158,
    '1423_904': 32.1859,
    '1423_905': 32.2198,
    '1423_906': 32.265,
    '1423_907': 32.3199,
    '1423_908': 32.3753,
    '1423_909': 32.4245,
    '1423_910': 32.4719,
    '1423_911': 32.5219,
    '1423_912': 32.5763,
    '1423_913': 32.627,
    '1423_914': 32.6558,
    '1423_915': 32.6749,
    '1423_916': 32.6697,
    '1423_917': 32.6506,
    '1423_918': 32.6262,
    '1423_919': 32.6131,
    '1423_920': 32.6112,
    '1423_921': 32.6138,
    '1423_922': 32.612,
    '1423_923': 32.6139,
    '1423_924': 32.6083,
    '1423_925': 32.5894,
    '1423_926': 32.5574,
    '1423_927': 32.4985,
    '1423_928': 32.4271,
    '1423_929': 32.3259,
    '1423_930': 32.224,
    '1423_931': 32.1269,
    '1423_932': 32.032,
    '1423_933': 31.942,
    '1423_934': 31.8617,
    '1423_935': 31.7932,
    '1423_936': 31.7308,
    '1423_937': 31.6785,
    '1423_938': 31.6409,
    '1423_939': 31.6127,
    '1423_940': 31.6018,
    '1423_941': 31.5989,
    '1423_942': 31.588,
    '1423_943': 31.5756,
    '1423_944': 31.5691,
    '1423_945': 31.5721,
    '1423_946': 31.5807,
    '1423_947': 31.5798,
    '1423_948': 31.5695,
    '1423_949': 31.5533,
    '1423_950': 31.5387,
    '1423_951': 31.5347,
    '1423_952': 31.5437,
    '1423_953': 31.5507,
    '1423_954': 31.5545,
    '1423_955': 31.5387,
    '1423_956': 31.5162,
    '1423_957': 31.4864,
    '1423_958': 31.4484,
    '1423_959': 31.4014,
    '1423_960': 31.3535,
    '1423_961': 31.3122,
    '1423_962': 31.2809,
    '1423_963': 31.2512,
    '1423_964': 31.2155,
    '1423_965': 31.1769,
    '1423_966': 31.1442,
    '1423_967': 31.1216,
    '1423_968': 31.1166,
    '1423_969': 31.1333,
    '1423_970': 31.1659,
    '1423_971': 31.2097,
    '1423_972': 31.2577,
    '1423_973': 31.2917,
    '1423_974': 31.3018,
    '1423_975': 31.2935,
    '1423_976': 31.2929,
    '1423_977': 31.3033,
    '1423_978': 31.3225,
    '1423_979': 31.343,
    '1423_980': 31.3683,
    '1423_981': 31.412,
    '1423_982': 31.4829,
    '1423_983': 31.565,
    '1423_984': 31.6531,
    '1423_985': 31.7369,
    '1423_986': 31.8167,
    '1423_987': 31.8959,
    '1423_988': 31.9639,
    '1423_989': 32.0073,
    '1423_990': 32.0266,
    '1423_991': 32.0294,
    '1423_992': 32.0286,
    '1423_993': 32.0206,
    '1423_994': 31.9953,
    '1423_995': 31.9453,
    '1423_996': 31.8684,
    '1423_997': 31.7744,
    '1423_998': 31.6753,
    '1423_999': 31.5836,
    '1423_1000': 31.5091,
    '1423_1001': 31.443,
    '1423_1002': 31.3753,
    '1423_1003': 31.3152,
    '1423_1004': 31.2704,
    '1423_1005': 31.2428,
    '1423_1006': 31.2212,
    '1423_1007': 31.2143,
    '1423_1008': 31.2168,
    '1423_1009': 31.2285,
    '1423_1010': 31.2474,
    '1423_1011': 31.2721,
    '1423_1012': 31.3028,
    '1423_1013': 31.3401,
    '1423_1014': 31.3824,
    '1423_1015': 31.4304,
    '1423_1016': 31.4959,
    '1424_844': 31.2546,
    '1424_845': 31.2794,
    '1424_846': 31.3084,
    '1424_847': 31.3478,
    '1424_848': 31.3945,
    '1424_849': 31.4473,
    '1424_850': 31.507,
    '1424_851': 31.576,
    '1424_852': 31.6595,
    '1424_853': 31.7655,
    '1424_854': 31.9057,
    '1424_855': 32.0416,
    '1424_856': 32.1511,
    '1424_857': 32.2309,
    '1424_858': 32.2806,
    '1424_859': 32.3167,
    '1424_860': 32.3373,
    '1424_861': 32.3361,
    '1424_862': 32.3124,
    '1424_863': 32.2752,
    '1424_864': 32.2309,
    '1424_865': 32.1795,
    '1424_866': 32.1096,
    '1424_867': 32.0153,
    '1424_868': 31.9151,
    '1424_869': 31.8114,
    '1424_870': 31.7136,
    '1424_871': 31.6212,
    '1424_872': 31.5366,
    '1424_873': 31.4602,
    '1424_874': 31.3974,
    '1424_875': 31.3456,
    '1424_876': 31.307,
    '1424_877': 31.2838,
    '1424_878': 31.277,
    '1424_879': 31.2842,
    '1424_880': 31.304,
    '1424_881': 31.3343,
    '1424_882': 31.3733,
    '1424_883': 31.4202,
    '1424_884': 31.4741,
    '1424_885': 31.5342,
    '1424_886': 31.6,
    '1424_887': 31.6771,
    '1424_888': 31.7644,
    '1424_889': 31.836,
    '1424_890': 31.8979,
    '1424_891': 31.9585,
    '1424_892': 32.003,
    '1424_893': 32.0192,
    '1424_894': 32.0185,
    '1424_895': 32.0109,
    '1424_896': 32.0055,
    '1424_897': 32.0135,
    '1424_898': 32.0361,
    '1424_899': 32.0652,
    '1424_900': 32.0963,
    '1424_901': 32.119,
    '1424_902': 32.1345,
    '1424_903': 32.1513,
    '1424_904': 32.1808,
    '1424_905': 32.2153,
    '1424_906': 32.2581,
    '1424_907': 32.3102,
    '1424_908': 32.3572,
    '1424_909': 32.3957,
    '1424_910': 32.4308,
    '1424_911': 32.4699,
    '1424_912': 32.5213,
    '1424_913': 32.5651,
    '1424_914': 32.5972,
    '1424_915': 32.6119,
    '1424_916': 32.6098,
    '1424_917': 32.6011,
    '1424_918': 32.5981,
    '1424_919': 32.6044,
    '1424_920': 32.6205,
    '1424_921': 32.6251,
    '1424_922': 32.623,
    '1424_923': 32.6071,
    '1424_924': 32.603,
    '1424_925': 32.5797,
    '1424_926': 32.5572,
    '1424_927': 32.4899,
    '1424_928': 32.4189,
    '1424_929': 32.3221,
    '1424_930': 32.2109,
    '1424_931': 32.1064,
    '1424_932': 32.0077,
    '1424_933': 31.9166,
    '1424_934': 31.8392,
    '1424_935': 31.7696,
    '1424_936': 31.7052,
    '1424_937': 31.6413,
    '1424_938': 31.5957,
    '1424_939': 31.5668,
    '1424_940': 31.5613,
    '1424_941': 31.5625,
    '1424_942': 31.552,
    '1424_943': 31.5391,
    '1424_944': 31.5363,
    '1424_945': 31.543,
    '1424_946': 31.5537,
    '1424_947': 31.5553,
    '1424_948': 31.5462,
    '1424_949': 31.5273,
    '1424_950': 31.5074,
    '1424_951': 31.4925,
    '1424_952': 31.4914,
    '1424_953': 31.4964,
    '1424_954': 31.5006,
    '1424_955': 31.4962,
    '1424_956': 31.4826,
    '1424_957': 31.463,
    '1424_958': 31.429,
    '1424_959': 31.3807,
    '1424_960': 31.3271,
    '1424_961': 31.2799,
    '1424_962': 31.2394,
    '1424_963': 31.2024,
    '1424_964': 31.1592,
    '1424_965': 31.1156,
    '1424_966': 31.0796,
    '1424_967': 31.0548,
    '1424_968': 31.05,
    '1424_969': 31.065,
    '1424_970': 31.0935,
    '1424_971': 31.1323,
    '1424_972': 31.173,
    '1424_973': 31.2011,
    '1424_974': 31.2113,
    '1424_975': 31.2086,
    '1424_976': 31.2125,
    '1424_977': 31.2266,
    '1424_978': 31.2514,
    '1424_979': 31.2789,
    '1424_980': 31.3089,
    '1424_981': 31.3531,
    '1424_982': 31.4209,
    '1424_983': 31.5027,
    '1424_984': 31.5901,
    '1424_985': 31.6741,
    '1424_986': 31.7586,
    '1424_987': 31.8452,
    '1424_988': 31.9262,
    '1424_989': 31.979,
    '1424_990': 31.9993,
    '1424_991': 31.9963,
    '1424_992': 31.985,
    '1424_993': 31.9717,
    '1424_994': 31.9496,
    '1424_995': 31.9032,
    '1424_996': 31.8375,
    '1424_997': 31.7483,
    '1424_998': 31.6463,
    '1424_999': 31.5587,
    '1424_1000': 31.4858,
    '1424_1001': 31.4205,
    '1424_1002': 31.3519,
    '1424_1003': 31.2904,
    '1424_1004': 31.2471,
    '1424_1005': 31.2143,
    '1424_1006': 31.1984,
    '1424_1007': 31.1932,
    '1424_1008': 31.1969,
    '1424_1009': 31.2092,
    '1424_1010': 31.2286,
    '1424_1011': 31.2533,
    '1424_1012': 31.2837,
    '1424_1013': 31.3208,
    '1424_1014': 31.3631,
    '1424_1015': 31.4128,
    '1424_1016': 31.4823,
    '1425_844': 31.209,
    '1425_845': 31.2371,
    '1425_846': 31.2666,
    '1425_847': 31.3081,
    '1425_848': 31.3549,
    '1425_849': 31.4079,
    '1425_850': 31.4674,
    '1425_851': 31.5353,
    '1425_852': 31.6161,
    '1425_853': 31.7132,
    '1425_854': 31.8362,
    '1425_855': 31.9731,
    '1425_856': 32.0857,
    '1425_857': 32.1592,
    '1425_858': 32.1992,
    '1425_859': 32.2286,
    '1425_860': 32.2427,
    '1425_861': 32.2358,
    '1425_862': 32.2174,
    '1425_863': 32.1876,
    '1425_864': 32.1539,
    '1425_865': 32.1132,
    '1425_866': 32.0527,
    '1425_867': 31.9663,
    '1425_868': 31.8657,
    '1425_869': 31.7639,
    '1425_870': 31.6656,
    '1425_871': 31.5731,
    '1425_872': 31.4893,
    '1425_873': 31.4172,
    '1425_874': 31.3599,
    '1425_875': 31.3113,
    '1425_876': 31.2782,
    '1425_877': 31.261,
    '1425_878': 31.2593,
    '1425_879': 31.2711,
    '1425_880': 31.2957,
    '1425_881': 31.3298,
    '1425_882': 31.371,
    '1425_883': 31.4208,
    '1425_884': 31.4792,
    '1425_885': 31.5415,
    '1425_886': 31.6125,
    '1425_887': 31.6972,
    '1425_888': 31.7838,
    '1425_889': 31.8438,
    '1425_890': 31.8868,
    '1425_891': 31.9356,
    '1425_892': 31.9797,
    '1425_893': 31.9996,
    '1425_894': 32.0019,
    '1425_895': 31.9988,
    '1425_896': 31.9957,
    '1425_897': 32.0039,
    '1425_898': 32.0259,
    '1425_899': 32.0558,
    '1425_900': 32.0869,
    '1425_901': 32.1061,
    '1425_902': 32.1228,
    '1425_903': 32.1452,
    '1425_904': 32.1789,
    '1425_905': 32.2151,
    '1425_906': 32.2529,
    '1425_907': 32.2975,
    '1425_908': 32.3358,
    '1425_909': 32.3635,
    '1425_910': 32.3864,
    '1425_911': 32.4177,
    '1425_912': 32.4559,
    '1425_913': 32.4945,
    '1425_914': 32.5235,
    '1425_915': 32.5398,
    '1425_916': 32.5467,
    '1425_917': 32.5554,
    '1425_918': 32.5766,
    '1425_919': 32.6012,
    '1425_920': 32.6186,
    '1425_921': 32.613,
    '1425_922': 32.615,
    '1425_923': 32.5947,
    '1425_924': 32.5739,
    '1425_925': 32.5508,
    '1425_926': 32.5248,
    '1425_927': 32.4759,
    '1425_928': 32.3964,
    '1425_929': 32.2998,
    '1425_930': 32.1878,
    '1425_931': 32.0787,
    '1425_932': 31.9841,
    '1425_933': 31.9002,
    '1425_934': 31.828,
    '1425_935': 31.7585,
    '1425_936': 31.692,
    '1425_937': 31.6327,
    '1425_938': 31.5812,
    '1425_939': 31.549,
    '1425_940': 31.5407,
    '1425_941': 31.5358,
    '1425_942': 31.5206,
    '1425_943': 31.5094,
    '1425_944': 31.5121,
    '1425_945': 31.5225,
    '1425_946': 31.5344,
    '1425_947': 31.5367,
    '1425_948': 31.5285,
    '1425_949': 31.5106,
    '1425_950': 31.4923,
    '1425_951': 31.4739,
    '1425_952': 31.4613,
    '1425_953': 31.4556,
    '1425_954': 31.4508,
    '1425_955': 31.4535,
    '1425_956': 31.4484,
    '1425_957': 31.4325,
    '1425_958': 31.4039,
    '1425_959': 31.3598,
    '1425_960': 31.3038,
    '1425_961': 31.2523,
    '1425_962': 31.2034,
    '1425_963': 31.157,
    '1425_964': 31.1085,
    '1425_965': 31.0609,
    '1425_966': 31.0209,
    '1425_967': 30.9938,
    '1425_968': 30.9883,
    '1425_969': 30.9995,
    '1425_970': 31.023,
    '1425_971': 31.0512,
    '1425_972': 31.078,
    '1425_973': 31.0965,
    '1425_974': 31.1068,
    '1425_975': 31.1134,
    '1425_976': 31.1239,
    '1425_977': 31.1426,
    '1425_978': 31.1702,
    '1425_979': 31.2029,
    '1425_980': 31.2387,
    '1425_981': 31.2777,
    '1425_982': 31.3344,
    '1425_983': 31.4103,
    '1425_984': 31.4977,
    '1425_985': 31.5862,
    '1425_986': 31.6789,
    '1425_987': 31.7781,
    '1425_988': 31.868,
    '1425_989': 31.9245,
    '1425_990': 31.9524,
    '1425_991': 31.9471,
    '1425_992': 31.9329,
    '1425_993': 31.9208,
    '1425_994': 31.9036,
    '1425_995': 31.8675,
    '1425_996': 31.81,
    '1425_997': 31.7274,
    '1425_998': 31.6255,
    '1425_999': 31.5333,
    '1425_1000': 31.4573,
    '1425_1001': 31.3889,
    '1425_1002': 31.3231,
    '1425_1003': 31.2651,
    '1425_1004': 31.222,
    '1425_1005': 31.1883,
    '1425_1006': 31.1731,
    '1425_1007': 31.1688,
    '1425_1008': 31.173,
    '1425_1009': 31.1859,
    '1425_1010': 31.2056,
    '1425_1011': 31.23,
    '1425_1012': 31.2597,
    '1425_1013': 31.2955,
    '1425_1014': 31.3366,
    '1425_1015': 31.385,
    '1425_1016': 31.454,
    '1426_844': 31.1614,
    '1426_845': 31.1906,
    '1426_846': 31.2233,
    '1426_847': 31.2641,
    '1426_848': 31.3109,
    '1426_849': 31.3634,
    '1426_850': 31.4224,
    '1426_851': 31.4879,
    '1426_852': 31.5626,
    '1426_853': 31.6516,
    '1426_854': 31.756,
    '1426_855': 31.8805,
    '1426_856': 31.9979,
    '1426_857': 32.0732,
    '1426_858': 32.1067,
    '1426_859': 32.1264,
    '1426_860': 32.1309,
    '1426_861': 32.1215,
    '1426_862': 32.1078,
    '1426_863': 32.0857,
    '1426_864': 32.0627,
    '1426_865': 32.0369,
    '1426_866': 31.9876,
    '1426_867': 31.9089,
    '1426_868': 31.8127,
    '1426_869': 31.7161,
    '1426_870': 31.6212,
    '1426_871': 31.5311,
    '1426_872': 31.4488,
    '1426_873': 31.38,
    '1426_874': 31.3242,
    '1426_875': 31.281,
    '1426_876': 31.2548,
    '1426_877': 31.2438,
    '1426_878': 31.2471,
    '1426_879': 31.2652,
    '1426_880': 31.2957,
    '1426_881': 31.3313,
    '1426_882': 31.3741,
    '1426_883': 31.4259,
    '1426_884': 31.4876,
    '1426_885': 31.5542,
    '1426_886': 31.6285,
    '1426_887': 31.714,
    '1426_888': 31.7971,
    '1426_889': 31.8529,
    '1426_890': 31.8857,
    '1426_891': 31.9193,
    '1426_892': 31.9539,
    '1426_893': 31.9782,
    '1426_894': 31.9835,
    '1426_895': 31.9839,
    '1426_896': 31.985,
    '1426_897': 31.9946,
    '1426_898': 32.0149,
    '1426_899': 32.0415,
    '1426_900': 32.0691,
    '1426_901': 32.0934,
    '1426_902': 32.1154,
    '1426_903': 32.1417,
    '1426_904': 32.1753,
    '1426_905': 32.2064,
    '1426_906': 32.2366,
    '1426_907': 32.2751,
    '1426_908': 32.3068,
    '1426_909': 32.3263,
    '1426_910': 32.3448,
    '1426_911': 32.3719,
    '1426_912': 32.4026,
    '1426_913': 32.4319,
    '1426_914': 32.4557,
    '1426_915': 32.4736,
    '1426_916': 32.4892,
    '1426_917': 32.5154,
    '1426_918': 32.5546,
    '1426_919': 32.5817,
    '1426_920': 32.5964,
    '1426_921': 32.6002,
    '1426_922': 32.5886,
    '1426_923': 32.5521,
    '1426_924': 32.5227,
    '1426_925': 32.5028,
    '1426_926': 32.4712,
    '1426_927': 32.426,
    '1426_928': 32.3663,
    '1426_929': 32.2658,
    '1426_930': 32.1537,
    '1426_931': 32.05,
    '1426_932': 31.9604,
    '1426_933': 31.8835,
    '1426_934': 31.8164,
    '1426_935': 31.7542,
    '1426_936': 31.6902,
    '1426_937': 31.6386,
    '1426_938': 31.5921,
    '1426_939': 31.5571,
    '1426_940': 31.5397,
    '1426_941': 31.5249,
    '1426_942': 31.5004,
    '1426_943': 31.4866,
    '1426_944': 31.4903,
    '1426_945': 31.5022,
    '1426_946': 31.5199,
    '1426_947': 31.5241,
    '1426_948': 31.5151,
    '1426_949': 31.4959,
    '1426_950': 31.4777,
    '1426_951': 31.4637,
    '1426_952': 31.4477,
    '1426_953': 31.4262,
    '1426_954': 31.4062,
    '1426_955': 31.4051,
    '1426_956': 31.4065,
    '1426_957': 31.3954,
    '1426_958': 31.3748,
    '1426_959': 31.3368,
    '1426_960': 31.2848,
    '1426_961': 31.2308,
    '1426_962': 31.1736,
    '1426_963': 31.1172,
    '1426_964': 31.0632,
    '1426_965': 31.0125,
    '1426_966': 30.9695,
    '1426_967': 30.9402,
    '1426_968': 30.9321,
    '1426_969': 30.9372,
    '1426_970': 30.9552,
    '1426_971': 30.9738,
    '1426_972': 30.9891,
    '1426_973': 30.9998,
    '1426_974': 31.0091,
    '1426_975': 31.0214,
    '1426_976': 31.0346,
    '1426_977': 31.0545,
    '1426_978': 31.085,
    '1426_979': 31.1214,
    '1426_980': 31.1557,
    '1426_981': 31.1869,
    '1426_982': 31.2335,
    '1426_983': 31.2948,
    '1426_984': 31.3734,
    '1426_985': 31.4641,
    '1426_986': 31.5678,
    '1426_987': 31.6783,
    '1426_988': 31.7787,
    '1426_989': 31.8412,
    '1426_990': 31.8754,
    '1426_991': 31.8775,
    '1426_992': 31.874,
    '1426_993': 31.8725,
    '1426_994': 31.8678,
    '1426_995': 31.8445,
    '1426_996': 31.7939,
    '1426_997': 31.7157,
    '1426_998': 31.6152,
    '1426_999': 31.517,
    '1426_1000': 31.4328,
    '1426_1001': 31.3585,
    '1426_1002': 31.2929,
    '1426_1003': 31.2355,
    '1426_1004': 31.1881,
    '1426_1005': 31.159,
    '1426_1006': 31.1442,
    '1426_1007': 31.1401,
    '1426_1008': 31.1445,
    '1426_1009': 31.1578,
    '1426_1010': 31.1777,
    '1426_1011': 31.2019,
    '1426_1012': 31.2303,
    '1426_1013': 31.2642,
    '1426_1014': 31.3031,
    '1426_1015': 31.3498,
    '1426_1016': 31.4178,
    '1427_844': 31.1131,
    '1427_845': 31.1436,
    '1427_846': 31.1781,
    '1427_847': 31.2194,
    '1427_848': 31.2659,
    '1427_849': 31.317,
    '1427_850': 31.3754,
    '1427_851': 31.4376,
    '1427_852': 31.5063,
    '1427_853': 31.5852,
    '1427_854': 31.6775,
    '1427_855': 31.7883,
    '1427_856': 31.9026,
    '1427_857': 31.9762,
    '1427_858': 32.0085,
    '1427_859': 32.0164,
    '1427_860': 32.008,
    '1427_861': 31.9978,
    '1427_862': 31.9861,
    '1427_863': 31.9744,
    '1427_864': 31.9619,
    '1427_865': 31.9481,
    '1427_866': 31.9099,
    '1427_867': 31.8398,
    '1427_868': 31.758,
    '1427_869': 31.6703,
    '1427_870': 31.5794,
    '1427_871': 31.4926,
    '1427_872': 31.4156,
    '1427_873': 31.3489,
    '1427_874': 31.2957,
    '1427_875': 31.2611,
    '1427_876': 31.2393,
    '1427_877': 31.2321,
    '1427_878': 31.2396,
    '1427_879': 31.2627,
    '1427_880': 31.2968,
    '1427_881': 31.3348,
    '1427_882': 31.3788,
    '1427_883': 31.4319,
    '1427_884': 31.4929,
    '1427_885': 31.5631,
    '1427_886': 31.6374,
    '1427_887': 31.7205,
    '1427_888': 31.7987,
    '1427_889': 31.855,
    '1427_890': 31.8865,
    '1427_891': 31.9113,
    '1427_892': 31.9359,
    '1427_893': 31.9556,
    '1427_894': 31.966,
    '1427_895': 31.9707,
    '1427_896': 31.9731,
    '1427_897': 31.9832,
    '1427_898': 32.0021,
    '1427_899': 32.0259,
    '1427_900': 32.0522,
    '1427_901': 32.079,
    '1427_902': 32.1014,
    '1427_903': 32.1247,
    '1427_904': 32.1571,
    '1427_905': 32.1881,
    '1427_906': 32.2123,
    '1427_907': 32.2439,
    '1427_908': 32.2694,
    '1427_909': 32.2849,
    '1427_910': 32.3017,
    '1427_911': 32.3266,
    '1427_912': 32.353,
    '1427_913': 32.3778,
    '1427_914': 32.3999,
    '1427_915': 32.4209,
    '1427_916': 32.4456,
    '1427_917': 32.4822,
    '1427_918': 32.5217,
    '1427_919': 32.5477,
    '1427_920': 32.5665,
    '1427_921': 32.5606,
    '1427_922': 32.5408,
    '1427_923': 32.4933,
    '1427_924': 32.4477,
    '1427_925': 32.4232,
    '1427_926': 32.4071,
    '1427_927': 32.3682,
    '1427_928': 32.3061,
    '1427_929': 32.2227,
    '1427_930': 32.1179,
    '1427_931': 32.0181,
    '1427_932': 31.9314,
    '1427_933': 31.8598,
    '1427_934': 31.7954,
    '1427_935': 31.7421,
    '1427_936': 31.6916,
    '1427_937': 31.6442,
    '1427_938': 31.6027,
    '1427_939': 31.5655,
    '1427_940': 31.5414,
    '1427_941': 31.5204,
    '1427_942': 31.4966,
    '1427_943': 31.4789,
    '1427_944': 31.4775,
    '1427_945': 31.4884,
    '1427_946': 31.5026,
    '1427_947': 31.5062,
    '1427_948': 31.4978,
    '1427_949': 31.4806,
    '1427_950': 31.4631,
    '1427_951': 31.4515,
    '1427_952': 31.4407,
    '1427_953': 31.4118,
    '1427_954': 31.3813,
    '1427_955': 31.3622,
    '1427_956': 31.3613,
    '1427_957': 31.3553,
    '1427_958': 31.3399,
    '1427_959': 31.3093,
    '1427_960': 31.2635,
    '1427_961': 31.2095,
    '1427_962': 31.1501,
    '1427_963': 31.0828,
    '1427_964': 31.0224,
    '1427_965': 30.968,
    '1427_966': 30.924,
    '1427_967': 30.8954,
    '1427_968': 30.8854,
    '1427_969': 30.8848,
    '1427_970': 30.893,
    '1427_971': 30.9032,
    '1427_972': 30.9115,
    '1427_973': 30.9169,
    '1427_974': 30.9249,
    '1427_975': 30.9339,
    '1427_976': 30.949,
    '1427_977': 30.9684,
    '1427_978': 30.9974,
    '1427_979': 31.0288,
    '1427_980': 31.06,
    '1427_981': 31.0902,
    '1427_982': 31.1289,
    '1427_983': 31.1782,
    '1427_984': 31.243,
    '1427_985': 31.3303,
    '1427_986': 31.4353,
    '1427_987': 31.5487,
    '1427_988': 31.6505,
    '1427_989': 31.7256,
    '1427_990': 31.767,
    '1427_991': 31.7861,
    '1427_992': 31.8058,
    '1427_993': 31.8247,
    '1427_994': 31.8336,
    '1427_995': 31.8213,
    '1427_996': 31.7773,
    '1427_997': 31.708,
    '1427_998': 31.6142,
    '1427_999': 31.5118,
    '1427_1000': 31.4172,
    '1427_1001': 31.3351,
    '1427_1002': 31.2648,
    '1427_1003': 31.2053,
    '1427_1004': 31.1565,
    '1427_1005': 31.1272,
    '1427_1006': 31.1123,
    '1427_1007': 31.1078,
    '1427_1008': 31.1121,
    '1427_1009': 31.1254,
    '1427_1010': 31.1456,
    '1427_1011': 31.1696,
    '1427_1012': 31.1959,
    '1427_1013': 31.2277,
    '1427_1014': 31.2644,
    '1427_1015': 31.3095,
    '1427_1016': 31.3768,
    '1428_844': 31.0645,
    '1428_845': 31.0979,
    '1428_846': 31.1351,
    '1428_847': 31.1775,
    '1428_848': 31.2234,
    '1428_849': 31.273,
    '1428_850': 31.3278,
    '1428_851': 31.387,
    '1428_852': 31.4505,
    '1428_853': 31.5202,
    '1428_854': 31.5987,
    '1428_855': 31.6871,
    '1428_856': 31.7854,
    '1428_857': 31.8582,
    '1428_858': 31.8923,
    '1428_859': 31.8975,
    '1428_860': 31.8867,
    '1428_861': 31.8739,
    '1428_862': 31.8689,
    '1428_863': 31.8654,
    '1428_864': 31.8602,
    '1428_865': 31.8543,
    '1428_866': 31.8264,
    '1428_867': 31.769,
    '1428_868': 31.7028,
    '1428_869': 31.623,
    '1428_870': 31.5376,
    '1428_871': 31.455,
    '1428_872': 31.3858,
    '1428_873': 31.3224,
    '1428_874': 31.277,
    '1428_875': 31.2511,
    '1428_876': 31.2311,
    '1428_877': 31.2273,
    '1428_878': 31.2388,
    '1428_879': 31.2647,
    '1428_880': 31.2992,
    '1428_881': 31.3384,
    '1428_882': 31.3846,
    '1428_883': 31.4391,
    '1428_884': 31.5033,
    '1428_885': 31.5754,
    '1428_886': 31.6467,
    '1428_887': 31.7206,
    '1428_888': 31.7929,
    '1428_889': 31.8505,
    '1428_890': 31.8842,
    '1428_891': 31.9034,
    '1428_892': 31.9271,
    '1428_893': 31.9423,
    '1428_894': 31.9533,
    '1428_895': 31.9591,
    '1428_896': 31.9635,
    '1428_897': 31.9726,
    '1428_898': 31.9916,
    '1428_899': 32.0136,
    '1428_900': 32.0367,
    '1428_901': 32.0601,
    '1428_902': 32.0803,
    '1428_903': 32.1031,
    '1428_904': 32.1338,
    '1428_905': 32.1632,
    '1428_906': 32.1865,
    '1428_907': 32.2121,
    '1428_908': 32.2313,
    '1428_909': 32.243,
    '1428_910': 32.2598,
    '1428_911': 32.2817,
    '1428_912': 32.3047,
    '1428_913': 32.3292,
    '1428_914': 32.3536,
    '1428_915': 32.3803,
    '1428_916': 32.4112,
    '1428_917': 32.4488,
    '1428_918': 32.4867,
    '1428_919': 32.5181,
    '1428_920': 32.5311,
    '1428_921': 32.5203,
    '1428_922': 32.4863,
    '1428_923': 32.4285,
    '1428_924': 32.3681,
    '1428_925': 32.3291,
    '1428_926': 32.3116,
    '1428_927': 32.2934,
    '1428_928': 32.2444,
    '1428_929': 32.1719,
    '1428_930': 32.0767,
    '1428_931': 31.9797,
    '1428_932': 31.8976,
    '1428_933': 31.8281,
    '1428_934': 31.7705,
    '1428_935': 31.7231,
    '1428_936': 31.6771,
    '1428_937': 31.6378,
    '1428_938': 31.5996,
    '1428_939': 31.5605,
    '1428_940': 31.5306,
    '1428_941': 31.5062,
    '1428_942': 31.4862,
    '1428_943': 31.4734,
    '1428_944': 31.4732,
    '1428_945': 31.4826,
    '1428_946': 31.4977,
    '1428_947': 31.5015,
    '1428_948': 31.4951,
    '1428_949': 31.4785,
    '1428_950': 31.4587,
    '1428_951': 31.44,
    '1428_952': 31.4203,
    '1428_953': 31.3982,
    '1428_954': 31.3724,
    '1428_955': 31.3421,
    '1428_956': 31.3207,
    '1428_957': 31.3121,
    '1428_958': 31.3006,
    '1428_959': 31.28,
    '1428_960': 31.242,
    '1428_961': 31.1892,
    '1428_962': 31.1267,
    '1428_963': 31.0558,
    '1428_964': 30.9906,
    '1428_965': 30.9322,
    '1428_966': 30.8869,
    '1428_967': 30.8595,
    '1428_968': 30.8476,
    '1428_969': 30.8428,
    '1428_970': 30.8419,
    '1428_971': 30.8436,
    '1428_972': 30.8453,
    '1428_973': 30.8474,
    '1428_974': 30.8504,
    '1428_975': 30.856,
    '1428_976': 30.8677,
    '1428_977': 30.8847,
    '1428_978': 30.9092,
    '1428_979': 30.9381,
    '1428_980': 30.9659,
    '1428_981': 30.9939,
    '1428_982': 31.0252,
    '1428_983': 31.0675,
    '1428_984': 31.1251,
    '1428_985': 31.2052,
    '1428_986': 31.3023,
    '1428_987': 31.4078,
    '1428_988': 31.5059,
    '1428_989': 31.5827,
    '1428_990': 31.6385,
    '1428_991': 31.6798,
    '1428_992': 31.7221,
    '1428_993': 31.7622,
    '1428_994': 31.7892,
    '1428_995': 31.7946,
    '1428_996': 31.7632,
    '1428_997': 31.7037,
    '1428_998': 31.6192,
    '1428_999': 31.5179,
    '1428_1000': 31.4118,
    '1428_1001': 31.3205,
    '1428_1002': 31.2421,
    '1428_1003': 31.1774,
    '1428_1004': 31.1262,
    '1428_1005': 31.0947,
    '1428_1006': 31.0782,
    '1428_1007': 31.0727,
    '1428_1008': 31.076,
    '1428_1009': 31.0886,
    '1428_1010': 31.1083,
    '1428_1011': 31.1322,
    '1428_1012': 31.1557,
    '1428_1013': 31.1853,
    '1428_1014': 31.2208,
    '1428_1015': 31.2661,
    '1428_1016': 31.3323,
    '1429_844': 31.0193,
    '1429_845': 31.0552,
    '1429_846': 31.0939,
    '1429_847': 31.1378,
    '1429_848': 31.182,
    '1429_849': 31.2294,
    '1429_850': 31.2805,
    '1429_851': 31.3365,
    '1429_852': 31.3947,
    '1429_853': 31.4562,
    '1429_854': 31.5221,
    '1429_855': 31.5882,
    '1429_856': 31.6558,
    '1429_857': 31.7192,
    '1429_858': 31.7582,
    '1429_859': 31.7651,
    '1429_860': 31.76,
    '1429_861': 31.7579,
    '1429_862': 31.7574,
    '1429_863': 31.7604,
    '1429_864': 31.759,
    '1429_865': 31.7598,
    '1429_866': 31.7447,
    '1429_867': 31.7007,
    '1429_868': 31.6423,
    '1429_869': 31.5673,
    '1429_870': 31.4921,
    '1429_871': 31.4211,
    '1429_872': 31.3632,
    '1429_873': 31.3066,
    '1429_874': 31.2659,
    '1429_875': 31.2439,
    '1429_876': 31.2284,
    '1429_877': 31.2303,
    '1429_878': 31.2461,
    '1429_879': 31.2725,
    '1429_880': 31.307,
    '1429_881': 31.3461,
    '1429_882': 31.3945,
    '1429_883': 31.4507,
    '1429_884': 31.5161,
    '1429_885': 31.5878,
    '1429_886': 31.6551,
    '1429_887': 31.7245,
    '1429_888': 31.7927,
    '1429_889': 31.8454,
    '1429_890': 31.872,
    '1429_891': 31.8945,
    '1429_892': 31.9223,
    '1429_893': 31.9337,
    '1429_894': 31.9436,
    '1429_895': 31.9499,
    '1429_896': 31.9548,
    '1429_897': 31.9659,
    '1429_898': 31.9848,
    '1429_899': 32.0053,
    '1429_900': 32.0235,
    '1429_901': 32.041,
    '1429_902': 32.0594,
    '1429_903': 32.0823,
    '1429_904': 32.1096,
    '1429_905': 32.1355,
    '1429_906': 32.1611,
    '1429_907': 32.1824,
    '1429_908': 32.1925,
    '1429_909': 32.2001,
    '1429_910': 32.2171,
    '1429_911': 32.2374,
    '1429_912': 32.26,
    '1429_913': 32.2873,
    '1429_914': 32.3192,
    '1429_915': 32.3522,
    '1429_916': 32.382,
    '1429_917': 32.4117,
    '1429_918': 32.4422,
    '1429_919': 32.4675,
    '1429_920': 32.4768,
    '1429_921': 32.4607,
    '1429_922': 32.4121,
    '1429_923': 32.3552,
    '1429_924': 32.2884,
    '1429_925': 32.2329,
    '1429_926': 32.2145,
    '1429_927': 32.2056,
    '1429_928': 32.1738,
    '1429_929': 32.1161,
    '1429_930': 32.0339,
    '1429_931': 31.9413,
    '1429_932': 31.8585,
    '1429_933': 31.7872,
    '1429_934': 31.7352,
    '1429_935': 31.6984,
    '1429_936': 31.6625,
    '1429_937': 31.6258,
    '1429_938': 31.586,
    '1429_939': 31.5426,
    '1429_940': 31.5086,
    '1429_941': 31.4801,
    '1429_942': 31.4689,
    '1429_943': 31.465,
    '1429_944': 31.4662,
    '1429_945': 31.4725,
    '1429_946': 31.4882,
    '1429_947': 31.5023,
    '1429_948': 31.5036,
    '1429_949': 31.4829,
    '1429_950': 31.4597,
    '1429_951': 31.4398,
    '1429_952': 31.4177,
    '1429_953': 31.3891,
    '1429_954': 31.3664,
    '1429_955': 31.3396,
    '1429_956': 31.2996,
    '1429_957': 31.2717,
    '1429_958': 31.2536,
    '1429_959': 31.2404,
    '1429_960': 31.2145,
    '1429_961': 31.1668,
    '1429_962': 31.1048,
    '1429_963': 31.033,
    '1429_964': 30.9668,
    '1429_965': 30.9062,
    '1429_966': 30.8603,
    '1429_967': 30.8328,
    '1429_968': 30.817,
    '1429_969': 30.8106,
    '1429_970': 30.8045,
    '1429_971': 30.7964,
    '1429_972': 30.7878,
    '1429_973': 30.7835,
    '1429_974': 30.7816,
    '1429_975': 30.7828,
    '1429_976': 30.7914,
    '1429_977': 30.8055,
    '1429_978': 30.8258,
    '1429_979': 30.8548,
    '1429_980': 30.8794,
    '1429_981': 30.9029,
    '1429_982': 30.9302,
    '1429_983': 30.967,
    '1429_984': 31.0179,
    '1429_985': 31.0872,
    '1429_986': 31.1704,
    '1429_987': 31.2621,
    '1429_988': 31.3528,
    '1429_989': 31.4325,
    '1429_990': 31.5024,
    '1429_991': 31.5644,
    '1429_992': 31.6263,
    '1429_993': 31.6853,
    '1429_994': 31.7335,
    '1429_995': 31.7597,
    '1429_996': 31.7461,
    '1429_997': 31.6991,
    '1429_998': 31.6258,
    '1429_999': 31.5257,
    '1429_1000': 31.418,
    '1429_1001': 31.3176,
    '1429_1002': 31.229,
    '1429_1003': 31.1551,
    '1429_1004': 31.1033,
    '1429_1005': 31.0626,
    '1429_1006': 31.0429,
    '1429_1007': 31.0352,
    '1429_1008': 31.0362,
    '1429_1009': 31.0461,
    '1429_1010': 31.0626,
    '1429_1011': 31.0828,
    '1429_1012': 31.1053,
    '1429_1013': 31.134,
    '1429_1014': 31.1705,
    '1429_1015': 31.2284,
    '1429_1016': 31.2823,
    '1430_844': 30.9827,
    '1430_845': 31.0195,
    '1430_846': 31.0586,
    '1430_847': 31.0999,
    '1430_848': 31.1423,
    '1430_849': 31.187,
    '1430_850': 31.2348,
    '1430_851': 31.286,
    '1430_852': 31.3391,
    '1430_853': 31.394,
    '1430_854': 31.4487,
    '1430_855': 31.5022,
    '1430_856': 31.5494,
    '1430_857': 31.5917,
    '1430_858': 31.6224,
    '1430_859': 31.6357,
    '1430_860': 31.6403,
    '1430_861': 31.6457,
    '1430_862': 31.6535,
    '1430_863': 31.6598,
    '1430_864': 31.664,
    '1430_865': 31.6692,
    '1430_866': 31.664,
    '1430_867': 31.6343,
    '1430_868': 31.5814,
    '1430_869': 31.5139,
    '1430_870': 31.446,
    '1430_871': 31.3837,
    '1430_872': 31.3368,
    '1430_873': 31.2914,
    '1430_874': 31.2563,
    '1430_875': 31.2374,
    '1430_876': 31.2277,
    '1430_877': 31.2364,
    '1430_878': 31.2561,
    '1430_879': 31.2819,
    '1430_880': 31.3145,
    '1430_881': 31.356,
    '1430_882': 31.4056,
    '1430_883': 31.4617,
    '1430_884': 31.5254,
    '1430_885': 31.595,
    '1430_886': 31.6604,
    '1430_887': 31.729,
    '1430_888': 31.7947,
    '1430_889': 31.8404,
    '1430_890': 31.8605,
    '1430_891': 31.8852,
    '1430_892': 31.9141,
    '1430_893': 31.9234,
    '1430_894': 31.9317,
    '1430_895': 31.9392,
    '1430_896': 31.9452,
    '1430_897': 31.9578,
    '1430_898': 31.9789,
    '1430_899': 31.9994,
    '1430_900': 32.0136,
    '1430_901': 32.0271,
    '1430_902': 32.0424,
    '1430_903': 32.0619,
    '1430_904': 32.087,
    '1430_905': 32.1106,
    '1430_906': 32.1325,
    '1430_907': 32.1503,
    '1430_908': 32.1553,
    '1430_909': 32.1618,
    '1430_910': 32.1763,
    '1430_911': 32.1954,
    '1430_912': 32.2223,
    '1430_913': 32.2574,
    '1430_914': 32.2934,
    '1430_915': 32.3249,
    '1430_916': 32.3504,
    '1430_917': 32.3717,
    '1430_918': 32.3923,
    '1430_919': 32.4104,
    '1430_920': 32.4155,
    '1430_921': 32.3951,
    '1430_922': 32.3479,
    '1430_923': 32.2835,
    '1430_924': 32.2089,
    '1430_925': 32.1464,
    '1430_926': 32.1185,
    '1430_927': 32.1153,
    '1430_928': 32.0983,
    '1430_929': 32.053,
    '1430_930': 31.9821,
    '1430_931': 31.8991,
    '1430_932': 31.8176,
    '1430_933': 31.7501,
    '1430_934': 31.706,
    '1430_935': 31.6729,
    '1430_936': 31.6446,
    '1430_937': 31.6091,
    '1430_938': 31.5655,
    '1430_939': 31.5172,
    '1430_940': 31.4784,
    '1430_941': 31.4484,
    '1430_942': 31.443,
    '1430_943': 31.4498,
    '1430_944': 31.4504,
    '1430_945': 31.4522,
    '1430_946': 31.4632,
    '1430_947': 31.4855,
    '1430_948': 31.4981,
    '1430_949': 31.4847,
    '1430_950': 31.4534,
    '1430_951': 31.4218,
    '1430_952': 31.4008,
    '1430_953': 31.3851,
    '1430_954': 31.3642,
    '1430_955': 31.3385,
    '1430_956': 31.2931,
    '1430_957': 31.2433,
    '1430_958': 31.2079,
    '1430_959': 31.1957,
    '1430_960': 31.1766,
    '1430_961': 31.1403,
    '1430_962': 31.0811,
    '1430_963': 31.0125,
    '1430_964': 30.9447,
    '1430_965': 30.8878,
    '1430_966': 30.8437,
    '1430_967': 30.8115,
    '1430_968': 30.7912,
    '1430_969': 30.7839,
    '1430_970': 30.7714,
    '1430_971': 30.7562,
    '1430_972': 30.7352,
    '1430_973': 30.7247,
    '1430_974': 30.718,
    '1430_975': 30.7156,
    '1430_976': 30.7223,
    '1430_977': 30.7341,
    '1430_978': 30.7507,
    '1430_979': 30.7788,
    '1430_980': 30.8037,
    '1430_981': 30.8259,
    '1430_982': 30.8488,
    '1430_983': 30.8775,
    '1430_984': 30.9198,
    '1430_985': 30.9777,
    '1430_986': 31.0453,
    '1430_987': 31.124,
    '1430_988': 31.2079,
    '1430_989': 31.2891,
    '1430_990': 31.3685,
    '1430_991': 31.4474,
    '1430_992': 31.5262,
    '1430_993': 31.6063,
    '1430_994': 31.6732,
    '1430_995': 31.7205,
    '1430_996': 31.7272,
    '1430_997': 31.6922,
    '1430_998': 31.6244,
    '1430_999': 31.533,
    '1430_1000': 31.4335,
    '1430_1001': 31.3258,
    '1430_1002': 31.2247,
    '1430_1003': 31.1411,
    '1430_1004': 31.0795,
    '1430_1005': 31.0317,
    '1430_1006': 31.0074,
    '1430_1007': 30.9959,
    '1430_1008': 30.9935,
    '1430_1009': 30.9996,
    '1430_1010': 31.0119,
    '1430_1011': 31.0283,
    '1430_1012': 31.0489,
    '1430_1013': 31.076,
    '1430_1014': 31.1118,
    '1430_1015': 31.1698,
    '1430_1016': 31.2214,
    '1431_844': 30.9512,
    '1431_845': 30.9878,
    '1431_846': 31.0261,
    '1431_847': 31.064,
    '1431_848': 31.1035,
    '1431_849': 31.145,
    '1431_850': 31.1891,
    '1431_851': 31.2357,
    '1431_852': 31.284,
    '1431_853': 31.3334,
    '1431_854': 31.3818,
    '1431_855': 31.4235,
    '1431_856': 31.4589,
    '1431_857': 31.4906,
    '1431_858': 31.5129,
    '1431_859': 31.5268,
    '1431_860': 31.5347,
    '1431_861': 31.5418,
    '1431_862': 31.5538,
    '1431_863': 31.5658,
    '1431_864': 31.5766,
    '1431_865': 31.588,
    '1431_866': 31.595,
    '1431_867': 31.5753,
    '1431_868': 31.5275,
    '1431_869': 31.4643,
    '1431_870': 31.3992,
    '1431_871': 31.3442,
    '1431_872': 31.3039,
    '1431_873': 31.2667,
    '1431_874': 31.2415,
    '1431_875': 31.2286,
    '1431_876': 31.226,
    '1431_877': 31.2377,
    '1431_878': 31.2574,
    '1431_879': 31.2852,
    '1431_880': 31.3218,
    '1431_881': 31.3691,
    '1431_882': 31.4235,
    '1431_883': 31.4768,
    '1431_884': 31.533,
    '1431_885': 31.5983,
    '1431_886': 31.6701,
    '1431_887': 31.7353,
    '1431_888': 31.7904,
    '1431_889': 31.8311,
    '1431_890': 31.8486,
    '1431_891': 31.8751,
    '1431_892': 31.9022,
    '1431_893': 31.9134,
    '1431_894': 31.92,
    '1431_895': 31.9282,
    '1431_896': 31.9365,
    '1431_897': 31.9516,
    '1431_898': 31.9739,
    '1431_899': 31.9909,
    '1431_900': 32.0026,
    '1431_901': 32.014,
    '1431_902': 32.0291,
    '1431_903': 32.049,
    '1431_904': 32.0695,
    '1431_905': 32.0892,
    '1431_906': 32.106,
    '1431_907': 32.1158,
    '1431_908': 32.1193,
    '1431_909': 32.1297,
    '1431_910': 32.1455,
    '1431_911': 32.1681,
    '1431_912': 32.2001,
    '1431_913': 32.235,
    '1431_914': 32.2689,
    '1431_915': 32.2972,
    '1431_916': 32.3173,
    '1431_917': 32.3324,
    '1431_918': 32.3453,
    '1431_919': 32.3548,
    '1431_920': 32.3515,
    '1431_921': 32.3269,
    '1431_922': 32.2769,
    '1431_923': 32.2067,
    '1431_924': 32.1314,
    '1431_925': 32.0681,
    '1431_926': 32.033,
    '1431_927': 32.0262,
    '1431_928': 32.0158,
    '1431_929': 31.9822,
    '1431_930': 31.9241,
    '1431_931': 31.8412,
    '1431_932': 31.767,
    '1431_933': 31.7056,
    '1431_934': 31.668,
    '1431_935': 31.6425,
    '1431_936': 31.6217,
    '1431_937': 31.5871,
    '1431_938': 31.5464,
    '1431_939': 31.4915,
    '1431_940': 31.4449,
    '1431_941': 31.4159,
    '1431_942': 31.4137,
    '1431_943': 31.4235,
    '1431_944': 31.4223,
    '1431_945': 31.4183,
    '1431_946': 31.4275,
    '1431_947': 31.4534,
    '1431_948': 31.4816,
    '1431_949': 31.4821,
    '1431_950': 31.4524,
    '1431_951': 31.4101,
    '1431_952': 31.3767,
    '1431_953': 31.3598,
    '1431_954': 31.3529,
    '1431_955': 31.3301,
    '1431_956': 31.2897,
    '1431_957': 31.2356,
    '1431_958': 31.1894,
    '1431_959': 31.1553,
    '1431_960': 31.1347,
    '1431_961': 31.1067,
    '1431_962': 31.0522,
    '1431_963': 30.9881,
    '1431_964': 30.9239,
    '1431_965': 30.8689,
    '1431_966': 30.8268,
    '1431_967': 30.7916,
    '1431_968': 30.766,
    '1431_969': 30.7496,
    '1431_970': 30.7318,
    '1431_971': 30.7112,
    '1431_972': 30.6863,
    '1431_973': 30.6709,
    '1431_974': 30.6587,
    '1431_975': 30.6539,
    '1431_976': 30.657,
    '1431_977': 30.665,
    '1431_978': 30.6796,
    '1431_979': 30.703,
    '1431_980': 30.7306,
    '1431_981': 30.7514,
    '1431_982': 30.7714,
    '1431_983': 30.7944,
    '1431_984': 30.8278,
    '1431_985': 30.8746,
    '1431_986': 30.9312,
    '1431_987': 30.9983,
    '1431_988': 31.0747,
    '1431_989': 31.1547,
    '1431_990': 31.239,
    '1431_991': 31.3301,
    '1431_992': 31.4315,
    '1431_993': 31.5309,
    '1431_994': 31.6201,
    '1431_995': 31.6844,
    '1431_996': 31.7026,
    '1431_997': 31.6751,
    '1431_998': 31.6114,
    '1431_999': 31.5318,
    '1431_1000': 31.4387,
    '1431_1001': 31.3336,
    '1431_1002': 31.2255,
    '1431_1003': 31.1318,
    '1431_1004': 31.0591,
    '1431_1005': 31.0029,
    '1431_1006': 30.9728,
    '1431_1007': 30.9564,
    '1431_1008': 30.9493,
    '1431_1009': 30.9508,
    '1431_1010': 30.9586,
    '1431_1011': 30.971,
    '1431_1012': 30.9885,
    '1431_1013': 31.013,
    '1431_1014': 31.045,
    '1431_1015': 31.0988,
    '1431_1016': 31.1479,
    '1432_844': 30.9245,
    '1432_845': 30.9585,
    '1432_846': 30.9934,
    '1432_847': 31.0286,
    '1432_848': 31.0654,
    '1432_849': 31.1034,
    '1432_850': 31.1436,
    '1432_851': 31.1854,
    '1432_852': 31.2282,
    '1432_853': 31.2724,
    '1432_854': 31.3148,
    '1432_855': 31.3514,
    '1432_856': 31.3798,
    '1432_857': 31.4031,
    '1432_858': 31.4217,
    '1432_859': 31.4373,
    '1432_860': 31.4474,
    '1432_861': 31.4564,
    '1432_862': 31.4675,
    '1432_863': 31.481,
    '1432_864': 31.4971,
    '1432_865': 31.5185,
    '1432_866': 31.5311,
    '1432_867': 31.5165,
    '1432_868': 31.4769,
    '1432_869': 31.4125,
    '1432_870': 31.3524,
    '1432_871': 31.3091,
    '1432_872': 31.2773,
    '1432_873': 31.2453,
    '1432_874': 31.2249,
    '1432_875': 31.2193,
    '1432_876': 31.2221,
    '1432_877': 31.2363,
    '1432_878': 31.2552,
    '1432_879': 31.2849,
    '1432_880': 31.3263,
    '1432_881': 31.3794,
    '1432_882': 31.4386,
    '1432_883': 31.4915,
    '1432_884': 31.5418,
    '1432_885': 31.6042,
    '1432_886': 31.6789,
    '1432_887': 31.7409,
    '1432_888': 31.7832,
    '1432_889': 31.8176,
    '1432_890': 31.8357,
    '1432_891': 31.8637,
    '1432_892': 31.8894,
    '1432_893': 31.8993,
    '1432_894': 31.9079,
    '1432_895': 31.9195,
    '1432_896': 31.9315,
    '1432_897': 31.9492,
    '1432_898': 31.9704,
    '1432_899': 31.9826,
    '1432_900': 31.99,
    '1432_901': 32.0001,
    '1432_902': 32.0158,
    '1432_903': 32.0343,
    '1432_904': 32.0512,
    '1432_905': 32.0681,
    '1432_906': 32.0798,
    '1432_907': 32.084,
    '1432_908': 32.0935,
    '1432_909': 32.1084,
    '1432_910': 32.1303,
    '1432_911': 32.16,
    '1432_912': 32.1933,
    '1432_913': 32.2242,
    '1432_914': 32.2518,
    '1432_915': 32.2779,
    '1432_916': 32.294,
    '1432_917': 32.3016,
    '1432_918': 32.3067,
    '1432_919': 32.3064,
    '1432_920': 32.2947,
    '1432_921': 32.2675,
    '1432_922': 32.217,
    '1432_923': 32.1466,
    '1432_924': 32.0743,
    '1432_925': 32.0109,
    '1432_926': 31.9704,
    '1432_927': 31.954,
    '1432_928': 31.9377,
    '1432_929': 31.9057,
    '1432_930': 31.8505,
    '1432_931': 31.7724,
    '1432_932': 31.7066,
    '1432_933': 31.6534,
    '1432_934': 31.6228,
    '1432_935': 31.6014,
    '1432_936': 31.5863,
    '1432_937': 31.5598,
    '1432_938': 31.52,
    '1432_939': 31.4629,
    '1432_940': 31.4128,
    '1432_941': 31.3813,
    '1432_942': 31.3793,
    '1432_943': 31.3911,
    '1432_944': 31.3895,
    '1432_945': 31.3824,
    '1432_946': 31.3904,
    '1432_947': 31.4159,
    '1432_948': 31.4538,
    '1432_949': 31.467,
    '1432_950': 31.4481,
    '1432_951': 31.411,
    '1432_952': 31.3668,
    '1432_953': 31.3355,
    '1432_954': 31.3205,
    '1432_955': 31.3051,
    '1432_956': 31.2766,
    '1432_957': 31.2291,
    '1432_958': 31.1785,
    '1432_959': 31.1322,
    '1432_960': 31.1003,
    '1432_961': 31.0721,
    '1432_962': 31.0281,
    '1432_963': 30.9688,
    '1432_964': 30.9086,
    '1432_965': 30.8502,
    '1432_966': 30.8059,
    '1432_967': 30.7706,
    '1432_968': 30.7425,
    '1432_969': 30.7179,
    '1432_970': 30.6924,
    '1432_971': 30.6673,
    '1432_972': 30.6422,
    '1432_973': 30.6228,
    '1432_974': 30.6055,
    '1432_975': 30.5963,
    '1432_976': 30.5939,
    '1432_977': 30.5955,
    '1432_978': 30.6061,
    '1432_979': 30.6247,
    '1432_980': 30.6496,
    '1432_981': 30.6715,
    '1432_982': 30.6912,
    '1432_983': 30.7112,
    '1432_984': 30.7385,
    '1432_985': 30.7765,
    '1432_986': 30.8237,
    '1432_987': 30.881,
    '1432_988': 30.9486,
    '1432_989': 31.0244,
    '1432_990': 31.1092,
    '1432_991': 31.2119,
    '1432_992': 31.3285,
    '1432_993': 31.4505,
    '1432_994': 31.5571,
    '1432_995': 31.6287,
    '1432_996': 31.6554,
    '1432_997': 31.6415,
    '1432_998': 31.589,
    '1432_999': 31.5136,
    '1432_1000': 31.4253,
    '1432_1001': 31.3297,
    '1432_1002': 31.2275,
    '1432_1003': 31.1262,
    '1432_1004': 31.0429,
    '1432_1005': 30.9776,
    '1432_1006': 30.9404,
    '1432_1007': 30.9178,
    '1432_1008': 30.905,
    '1432_1009': 30.9011,
    '1432_1010': 30.9039,
    '1432_1011': 30.9117,
    '1432_1012': 30.9253,
    '1432_1013': 30.9459,
    '1432_1014': 30.9713,
    '1432_1015': 31.0062,
    '1432_1016': 31.0638,
    '1433_844': 30.9012,
    '1433_845': 30.9327,
    '1433_846': 30.9645,
    '1433_847': 30.9964,
    '1433_848': 31.0308,
    '1433_849': 31.0648,
    '1433_850': 31.1011,
    '1433_851': 31.1381,
    '1433_852': 31.1749,
    '1433_853': 31.2139,
    '1433_854': 31.2508,
    '1433_855': 31.2828,
    '1433_856': 31.3078,
    '1433_857': 31.3276,
    '1433_858': 31.3447,
    '1433_859': 31.3583,
    '1433_860': 31.37,
    '1433_861': 31.3825,
    '1433_862': 31.3937,
    '1433_863': 31.4075,
    '1433_864': 31.4263,
    '1433_865': 31.4501,
    '1433_866': 31.4628,
    '1433_867': 31.4455,
    '1433_868': 31.4065,
    '1433_869': 31.3547,
    '1433_870': 31.311,
    '1433_871': 31.2765,
    '1433_872': 31.2512,
    '1433_873': 31.2281,
    '1433_874': 31.2133,
    '1433_875': 31.212,
    '1433_876': 31.2192,
    '1433_877': 31.2298,
    '1433_878': 31.2523,
    '1433_879': 31.2868,
    '1433_880': 31.3309,
    '1433_881': 31.3878,
    '1433_882': 31.4474,
    '1433_883': 31.5016,
    '1433_884': 31.5479,
    '1433_885': 31.6147,
    '1433_886': 31.6876,
    '1433_887': 31.7382,
    '1433_888': 31.7708,
    '1433_889': 31.8015,
    '1433_890': 31.8267,
    '1433_891': 31.8562,
    '1433_892': 31.8733,
    '1433_893': 31.8839,
    '1433_894': 31.8957,
    '1433_895': 31.9093,
    '1433_896': 31.9243,
    '1433_897': 31.9446,
    '1433_898': 31.9634,
    '1433_899': 31.9677,
    '1433_900': 31.9749,
    '1433_901': 31.9854,
    '1433_902': 32.0032,
    '1433_903': 32.0196,
    '1433_904': 32.032,
    '1433_905': 32.0443,
    '1433_906': 32.0529,
    '1433_907': 32.0602,
    '1433_908': 32.0791,
    '1433_909': 32.0999,
    '1433_910': 32.1296,
    '1433_911': 32.165,
    '1433_912': 32.1996,
    '1433_913': 32.2264,
    '1433_914': 32.2504,
    '1433_915': 32.2716,
    '1433_916': 32.2847,
    '1433_917': 32.2827,
    '1433_918': 32.275,
    '1433_919': 32.2662,
    '1433_920': 32.2519,
    '1433_921': 32.2252,
    '1433_922': 32.1785,
    '1433_923': 32.114,
    '1433_924': 32.0488,
    '1433_925': 31.9899,
    '1433_926': 31.9448,
    '1433_927': 31.9073,
    '1433_928': 31.8708,
    '1433_929': 31.831,
    '1433_930': 31.7754,
    '1433_931': 31.7056,
    '1433_932': 31.6459,
    '1433_933': 31.5992,
    '1433_934': 31.5753,
    '1433_935': 31.5616,
    '1433_936': 31.5508,
    '1433_937': 31.5251,
    '1433_938': 31.4828,
    '1433_939': 31.4298,
    '1433_940': 31.3782,
    '1433_941': 31.3409,
    '1433_942': 31.3372,
    '1433_943': 31.3517,
    '1433_944': 31.3523,
    '1433_945': 31.3426,
    '1433_946': 31.3504,
    '1433_947': 31.3776,
    '1433_948': 31.4176,
    '1433_949': 31.4434,
    '1433_950': 31.4398,
    '1433_951': 31.412,
    '1433_952': 31.3634,
    '1433_953': 31.3208,
    '1433_954': 31.2892,
    '1433_955': 31.2682,
    '1433_956': 31.2468,
    '1433_957': 31.2177,
    '1433_958': 31.1722,
    '1433_959': 31.1238,
    '1433_960': 31.0868,
    '1433_961': 31.0537,
    '1433_962': 31.015,
    '1433_963': 30.9581,
    '1433_964': 30.895,
    '1433_965': 30.8332,
    '1433_966': 30.7851,
    '1433_967': 30.7488,
    '1433_968': 30.7213,
    '1433_969': 30.695,
    '1433_970': 30.665,
    '1433_971': 30.6342,
    '1433_972': 30.6048,
    '1433_973': 30.578,
    '1433_974': 30.5556,
    '1433_975': 30.5403,
    '1433_976': 30.532,
    '1433_977': 30.5285,
    '1433_978': 30.535,
    '1433_979': 30.5477,
    '1433_980': 30.5668,
    '1433_981': 30.5875,
    '1433_982': 30.6065,
    '1433_983': 30.626,
    '1433_984': 30.6509,
    '1433_985': 30.6835,
    '1433_986': 30.7234,
    '1433_987': 30.7735,
    '1433_988': 30.8338,
    '1433_989': 30.904,
    '1433_990': 30.9861,
    '1433_991': 31.0869,
    '1433_992': 31.2104,
    '1433_993': 31.3431,
    '1433_994': 31.4643,
    '1433_995': 31.5482,
    '1433_996': 31.5915,
    '1433_997': 31.5858,
    '1433_998': 31.5499,
    '1433_999': 31.4919,
    '1433_1000': 31.4132,
    '1433_1001': 31.3258,
    '1433_1002': 31.2296,
    '1433_1003': 31.1277,
    '1433_1004': 31.0326,
    '1433_1005': 30.9571,
    '1433_1006': 30.9114,
    '1433_1007': 30.8814,
    '1433_1008': 30.8619,
    '1433_1009': 30.8519,
    '1433_1010': 30.8492,
    '1433_1011': 30.8516,
    '1433_1012': 30.8599,
    '1433_1013': 30.8751,
    '1433_1014': 30.8948,
    '1433_1015': 30.9214,
    '1433_1016': 30.9613,
    '1434_844': 30.8814,
    '1434_845': 30.9101,
    '1434_846': 30.9394,
    '1434_847': 30.9699,
    '1434_848': 31.0018,
    '1434_849': 31.0326,
    '1434_850': 31.0639,
    '1434_851': 31.096,
    '1434_852': 31.1277,
    '1434_853': 31.1597,
    '1434_854': 31.1905,
    '1434_855': 31.2186,
    '1434_856': 31.2417,
    '1434_857': 31.2606,
    '1434_858': 31.2764,
    '1434_859': 31.2895,
    '1434_860': 31.301,
    '1434_861': 31.3117,
    '1434_862': 31.3224,
    '1434_863': 31.3366,
    '1434_864': 31.3524,
    '1434_865': 31.3728,
    '1434_866': 31.3829,
    '1434_867': 31.3693,
    '1434_868': 31.3398,
    '1434_869': 31.3048,
    '1434_870': 31.2703,
    '1434_871': 31.2437,
    '1434_872': 31.2232,
    '1434_873': 31.2102,
    '1434_874': 31.2023,
    '1434_875': 31.2024,
    '1434_876': 31.2094,
    '1434_877': 31.2209,
    '1434_878': 31.2467,
    '1434_879': 31.288,
    '1434_880': 31.3375,
    '1434_881': 31.4,
    '1434_882': 31.4581,
    '1434_883': 31.5036,
    '1434_884': 31.553,
    '1434_885': 31.6272,
    '1434_886': 31.7026,
    '1434_887': 31.7449,
    '1434_888': 31.7708,
    '1434_889': 31.7963,
    '1434_890': 31.8247,
    '1434_891': 31.852,
    '1434_892': 31.8596,
    '1434_893': 31.8689,
    '1434_894': 31.8828,
    '1434_895': 31.897,
    '1434_896': 31.9141,
    '1434_897': 31.9375,
    '1434_898': 31.9572,
    '1434_899': 31.9571,
    '1434_900': 31.9641,
    '1434_901': 31.9765,
    '1434_902': 31.9911,
    '1434_903': 32.0007,
    '1434_904': 32.0094,
    '1434_905': 32.0174,
    '1434_906': 32.0255,
    '1434_907': 32.0428,
    '1434_908': 32.0697,
    '1434_909': 32.099,
    '1434_910': 32.138,
    '1434_911': 32.1777,
    '1434_912': 32.2105,
    '1434_913': 32.2354,
    '1434_914': 32.254,
    '1434_915': 32.27,
    '1434_916': 32.2766,
    '1434_917': 32.2684,
    '1434_918': 32.2489,
    '1434_919': 32.2319,
    '1434_920': 32.2145,
    '1434_921': 32.1913,
    '1434_922': 32.1499,
    '1434_923': 32.0996,
    '1434_924': 32.0395,
    '1434_925': 31.9816,
    '1434_926': 31.9278,
    '1434_927': 31.8753,
    '1434_928': 31.8199,
    '1434_929': 31.7639,
    '1434_930': 31.7052,
    '1434_931': 31.6447,
    '1434_932': 31.5887,
    '1434_933': 31.5456,
    '1434_934': 31.5297,
    '1434_935': 31.522,
    '1434_936': 31.5142,
    '1434_937': 31.4883,
    '1434_938': 31.4418,
    '1434_939': 31.3857,
    '1434_940': 31.3357,
    '1434_941': 31.297,
    '1434_942': 31.2916,
    '1434_943': 31.309,
    '1434_944': 31.3147,
    '1434_945': 31.303,
    '1434_946': 31.3095,
    '1434_947': 31.3333,
    '1434_948': 31.3668,
    '1434_949': 31.403,
    '1434_950': 31.4192,
    '1434_951': 31.4047,
    '1434_952': 31.3618,
    '1434_953': 31.3172,
    '1434_954': 31.2738,
    '1434_955': 31.2365,
    '1434_956': 31.2067,
    '1434_957': 31.1916,
    '1434_958': 31.1687,
    '1434_959': 31.1288,
    '1434_960': 31.0941,
    '1434_961': 31.0593,
    '1434_962': 31.0149,
    '1434_963': 30.9538,
    '1434_964': 30.8827,
    '1434_965': 30.8159,
    '1434_966': 30.7627,
    '1434_967': 30.7236,
    '1434_968': 30.6958,
    '1434_969': 30.6713,
    '1434_970': 30.641,
    '1434_971': 30.6096,
    '1434_972': 30.5708,
    '1434_973': 30.5363,
    '1434_974': 30.5076,
    '1434_975': 30.4851,
    '1434_976': 30.47,
    '1434_977': 30.4619,
    '1434_978': 30.4635,
    '1434_979': 30.4715,
    '1434_980': 30.4866,
    '1434_981': 30.5061,
    '1434_982': 30.5245,
    '1434_983': 30.5437,
    '1434_984': 30.5667,
    '1434_985': 30.5958,
    '1434_986': 30.6305,
    '1434_987': 30.6754,
    '1434_988': 30.7293,
    '1434_989': 30.7936,
    '1434_990': 30.8697,
    '1434_991': 30.9627,
    '1434_992': 31.0784,
    '1434_993': 31.212,
    '1434_994': 31.3415,
    '1434_995': 31.4419,
    '1434_996': 31.4977,
    '1434_997': 31.5125,
    '1434_998': 31.4967,
    '1434_999': 31.4622,
    '1434_1000': 31.4046,
    '1434_1001': 31.3251,
    '1434_1002': 31.2325,
    '1434_1003': 31.1377,
    '1434_1004': 31.0315,
    '1434_1005': 30.9425,
    '1434_1006': 30.8854,
    '1434_1007': 30.8462,
    '1434_1008': 30.8191,
    '1434_1009': 30.8025,
    '1434_1010': 30.7935,
    '1434_1011': 30.7901,
    '1434_1012': 30.793,
    '1434_1013': 30.8024,
    '1434_1014': 30.8167,
    '1434_1015': 30.8365,
    '1434_1016': 30.8688,
    '1435_844': 30.8585,
    '1435_845': 30.8857,
    '1435_846': 30.913,
    '1435_847': 30.9422,
    '1435_848': 30.9732,
    '1435_849': 31.0004,
    '1435_850': 31.0277,
    '1435_851': 31.0559,
    '1435_852': 31.0827,
    '1435_853': 31.1089,
    '1435_854': 31.134,
    '1435_855': 31.1592,
    '1435_856': 31.18,
    '1435_857': 31.1977,
    '1435_858': 31.2124,
    '1435_859': 31.2248,
    '1435_860': 31.2358,
    '1435_861': 31.2452,
    '1435_862': 31.254,
    '1435_863': 31.2649,
    '1435_864': 31.275,
    '1435_865': 31.2869,
    '1435_866': 31.2961,
    '1435_867': 31.289,
    '1435_868': 31.2754,
    '1435_869': 31.2509,
    '1435_870': 31.2252,
    '1435_871': 31.2072,
    '1435_872': 31.1983,
    '1435_873': 31.1939,
    '1435_874': 31.1907,
    '1435_875': 31.191,
    '1435_876': 31.1954,
    '1435_877': 31.2101,
    '1435_878': 31.2429,
    '1435_879': 31.2881,
    '1435_880': 31.3427,
    '1435_881': 31.4062,
    '1435_882': 31.4582,
    '1435_883': 31.498,
    '1435_884': 31.5526,
    '1435_885': 31.6298,
    '1435_886': 31.7011,
    '1435_887': 31.7482,
    '1435_888': 31.7748,
    '1435_889': 31.7985,
    '1435_890': 31.8265,
    '1435_891': 31.8446,
    '1435_892': 31.8477,
    '1435_893': 31.8539,
    '1435_894': 31.8655,
    '1435_895': 31.8825,
    '1435_896': 31.9068,
    '1435_897': 31.9295,
    '1435_898': 31.9453,
    '1435_899': 31.9461,
    '1435_900': 31.9579,
    '1435_901': 31.9731,
    '1435_902': 31.9798,
    '1435_903': 31.9831,
    '1435_904': 31.9898,
    '1435_905': 31.9997,
    '1435_906': 32.0108,
    '1435_907': 32.0299,
    '1435_908': 32.0574,
    '1435_909': 32.0968,
    '1435_910': 32.1434,
    '1435_911': 32.1842,
    '1435_912': 32.2151,
    '1435_913': 32.238,
    '1435_914': 32.2547,
    '1435_915': 32.2647,
    '1435_916': 32.2627,
    '1435_917': 32.2477,
    '1435_918': 32.2189,
    '1435_919': 32.195,
    '1435_920': 32.1737,
    '1435_921': 32.1498,
    '1435_922': 32.115,
    '1435_923': 32.0727,
    '1435_924': 32.0229,
    '1435_925': 31.9665,
    '1435_926': 31.9087,
    '1435_927': 31.8489,
    '1435_928': 31.7836,
    '1435_929': 31.7146,
    '1435_930': 31.6476,
    '1435_931': 31.588,
    '1435_932': 31.5347,
    '1435_933': 31.4968,
    '1435_934': 31.4859,
    '1435_935': 31.4847,
    '1435_936': 31.4774,
    '1435_937': 31.4489,
    '1435_938': 31.4012,
    '1435_939': 31.3472,
    '1435_940': 31.2948,
    '1435_941': 31.2579,
    '1435_942': 31.254,
    '1435_943': 31.2705,
    '1435_944': 31.2743,
    '1435_945': 31.2624,
    '1435_946': 31.265,
    '1435_947': 31.2866,
    '1435_948': 31.3134,
    '1435_949': 31.3493,
    '1435_950': 31.381,
    '1435_951': 31.3809,
    '1435_952': 31.3491,
    '1435_953': 31.311,
    '1435_954': 31.2669,
    '1435_955': 31.2234,
    '1435_956': 31.1859,
    '1435_957': 31.1676,
    '1435_958': 31.1568,
    '1435_959': 31.134,
    '1435_960': 31.1059,
    '1435_961': 31.0694,
    '1435_962': 31.0182,
    '1435_963': 30.9516,
    '1435_964': 30.8739,
    '1435_965': 30.7981,
    '1435_966': 30.7383,
    '1435_967': 30.6928,
    '1435_968': 30.6625,
    '1435_969': 30.6403,
    '1435_970': 30.6127,
    '1435_971': 30.5779,
    '1435_972': 30.5344,
    '1435_973': 30.4925,
    '1435_974': 30.4563,
    '1435_975': 30.4273,
    '1435_976': 30.4078,
    '1435_977': 30.3969,
    '1435_978': 30.3947,
    '1435_979': 30.4006,
    '1435_980': 30.4145,
    '1435_981': 30.4318,
    '1435_982': 30.4488,
    '1435_983': 30.4671,
    '1435_984': 30.4885,
    '1435_985': 30.516,
    '1435_986': 30.5476,
    '1435_987': 30.588,
    '1435_988': 30.6351,
    '1435_989': 30.6935,
    '1435_990': 30.7606,
    '1435_991': 30.843,
    '1435_992': 30.9463,
    '1435_993': 31.0727,
    '1435_994': 31.2021,
    '1435_995': 31.3155,
    '1435_996': 31.3921,
    '1435_997': 31.4322,
    '1435_998': 31.4414,
    '1435_999': 31.4289,
    '1435_1000': 31.3845,
    '1435_1001': 31.3136,
    '1435_1002': 31.2323,
    '1435_1003': 31.1433,
    '1435_1004': 31.0363,
    '1435_1005': 30.9388,
    '1435_1006': 30.8619,
    '1435_1007': 30.8121,
    '1435_1008': 30.7769,
    '1435_1009': 30.753,
    '1435_1010': 30.7376,
    '1435_1011': 30.7282,
    '1435_1012': 30.7252,
    '1435_1013': 30.7291,
    '1435_1014': 30.7376,
    '1435_1015': 30.7513,
    '1435_1016': 30.775,
    '1436_844': 30.8276,
    '1436_845': 30.8533,
    '1436_846': 30.8804,
    '1436_847': 30.9088,
    '1436_848': 30.9376,
    '1436_849': 30.9645,
    '1436_850': 30.9901,
    '1436_851': 31.015,
    '1436_852': 31.0389,
    '1436_853': 31.0619,
    '1436_854': 31.0839,
    '1436_855': 31.104,
    '1436_856': 31.121,
    '1436_857': 31.136,
    '1436_858': 31.1498,
    '1436_859': 31.1619,
    '1436_860': 31.1724,
    '1436_861': 31.1806,
    '1436_862': 31.1869,
    '1436_863': 31.1917,
    '1436_864': 31.1976,
    '1436_865': 31.2018,
    '1436_866': 31.2062,
    '1436_867': 31.2035,
    '1436_868': 31.1983,
    '1436_869': 31.188,
    '1436_870': 31.1719,
    '1436_871': 31.1664,
    '1436_872': 31.1756,
    '1436_873': 31.1825,
    '1436_874': 31.1789,
    '1436_875': 31.1792,
    '1436_876': 31.1838,
    '1436_877': 31.199,
    '1436_878': 31.2369,
    '1436_879': 31.2842,
    '1436_880': 31.3405,
    '1436_881': 31.4013,
    '1436_882': 31.4478,
    '1436_883': 31.4914,
    '1436_884': 31.5542,
    '1436_885': 31.6275,
    '1436_886': 31.6832,
    '1436_887': 31.7361,
    '1436_888': 31.7764,
    '1436_889': 31.8049,
    '1436_890': 31.83,
    '1436_891': 31.8382,
    '1436_892': 31.8378,
    '1436_893': 31.8414,
    '1436_894': 31.85,
    '1436_895': 31.8671,
    '1436_896': 31.8954,
    '1436_897': 31.9152,
    '1436_898': 31.929,
    '1436_899': 31.9393,
    '1436_900': 31.9602,
    '1436_901': 31.9755,
    '1436_902': 31.9767,
    '1436_903': 31.9704,
    '1436_904': 31.9698,
    '1436_905': 31.9802,
    '1436_906': 31.9917,
    '1436_907': 32.0071,
    '1436_908': 32.0351,
    '1436_909': 32.0826,
    '1436_910': 32.1337,
    '1436_911': 32.1748,
    '1436_912': 32.2077,
    '1436_913': 32.2303,
    '1436_914': 32.2462,
    '1436_915': 32.2554,
    '1436_916': 32.2439,
    '1436_917': 32.216,
    '1436_918': 32.1805,
    '1436_919': 32.1472,
    '1436_920': 32.1223,
    '1436_921': 32.0956,
    '1436_922': 32.0681,
    '1436_923': 32.0315,
    '1436_924': 31.9948,
    '1436_925': 31.9459,
    '1436_926': 31.8839,
    '1436_927': 31.8238,
    '1436_928': 31.755,
    '1436_929': 31.6796,
    '1436_930': 31.6015,
    '1436_931': 31.5388,
    '1436_932': 31.488,
    '1436_933': 31.4554,
    '1436_934': 31.446,
    '1436_935': 31.4481,
    '1436_936': 31.4372,
    '1436_937': 31.4039,
    '1436_938': 31.3567,
    '1436_939': 31.3047,
    '1436_940': 31.2557,
    '1436_941': 31.2246,
    '1436_942': 31.2242,
    '1436_943': 31.2367,
    '1436_944': 31.2388,
    '1436_945': 31.2238,
    '1436_946': 31.2188,
    '1436_947': 31.2358,
    '1436_948': 31.2609,
    '1436_949': 31.2928,
    '1436_950': 31.3338,
    '1436_951': 31.3441,
    '1436_952': 31.323,
    '1436_953': 31.2937,
    '1436_954': 31.2557,
    '1436_955': 31.209,
    '1436_956': 31.1721,
    '1436_957': 31.1569,
    '1436_958': 31.1496,
    '1436_959': 31.1396,
    '1436_960': 31.1162,
    '1436_961': 31.0777,
    '1436_962': 31.0185,
    '1436_963': 30.9456,
    '1436_964': 30.8667,
    '1436_965': 30.7855,
    '1436_966': 30.7168,
    '1436_967': 30.665,
    '1436_968': 30.6304,
    '1436_969': 30.6085,
    '1436_970': 30.5831,
    '1436_971': 30.5426,
    '1436_972': 30.4934,
    '1436_973': 30.447,
    '1436_974': 30.4058,
    '1436_975': 30.3733,
    '1436_976': 30.3505,
    '1436_977': 30.3374,
    '1436_978': 30.3338,
    '1436_979': 30.34,
    '1436_980': 30.3517,
    '1436_981': 30.3655,
    '1436_982': 30.3801,
    '1436_983': 30.3974,
    '1436_984': 30.4186,
    '1436_985': 30.4453,
    '1436_986': 30.4759,
    '1436_987': 30.5137,
    '1436_988': 30.5583,
    '1436_989': 30.6079,
    '1436_990': 30.6655,
    '1436_991': 30.7356,
    '1436_992': 30.8239,
    '1436_993': 30.9382,
    '1436_994': 31.0647,
    '1436_995': 31.1841,
    '1436_996': 31.2753,
    '1436_997': 31.337,
    '1436_998': 31.368,
    '1436_999': 31.3767,
    '1436_1000': 31.3495,
    '1436_1001': 31.2934,
    '1436_1002': 31.2198,
    '1436_1003': 31.1337,
    '1436_1004': 31.0345,
    '1436_1005': 30.9286,
    '1436_1006': 30.8411,
    '1436_1007': 30.7808,
    '1436_1008': 30.7365,
    '1436_1009': 30.7053,
    '1436_1010': 30.6833,
    '1436_1011': 30.6678,
    '1436_1012': 30.6587,
    '1436_1013': 30.657,
    '1436_1014': 30.6597,
    '1436_1015': 30.6671,
    '1436_1016': 30.6832,
    '1437_844': 30.7906,
    '1437_845': 30.8158,
    '1437_846': 30.8424,
    '1437_847': 30.8698,
    '1437_848': 30.8973,
    '1437_849': 30.9243,
    '1437_850': 30.949,
    '1437_851': 30.9718,
    '1437_852': 30.9934,
    '1437_853': 31.0143,
    '1437_854': 31.0346,
    '1437_855': 31.0504,
    '1437_856': 31.0636,
    '1437_857': 31.0756,
    '1437_858': 31.0882,
    '1437_859': 31.0987,
    '1437_860': 31.107,
    '1437_861': 31.1136,
    '1437_862': 31.119,
    '1437_863': 31.1195,
    '1437_864': 31.1201,
    '1437_865': 31.1187,
    '1437_866': 31.1201,
    '1437_867': 31.1215,
    '1437_868': 31.121,
    '1437_869': 31.1168,
    '1437_870': 31.1119,
    '1437_871': 31.1215,
    '1437_872': 31.1471,
    '1437_873': 31.1674,
    '1437_874': 31.1658,
    '1437_875': 31.1629,
    '1437_876': 31.1685,
    '1437_877': 31.1862,
    '1437_878': 31.222,
    '1437_879': 31.272,
    '1437_880': 31.331,
    '1437_881': 31.3956,
    '1437_882': 31.4466,
    '1437_883': 31.4902,
    '1437_884': 31.5457,
    '1437_885': 31.6074,
    '1437_886': 31.6554,
    '1437_887': 31.706,
    '1437_888': 31.7588,
    '1437_889': 31.7966,
    '1437_890': 31.8192,
    '1437_891': 31.8228,
    '1437_892': 31.824,
    '1437_893': 31.8279,
    '1437_894': 31.8365,
    '1437_895': 31.8478,
    '1437_896': 31.8727,
    '1437_897': 31.8932,
    '1437_898': 31.9111,
    '1437_899': 31.9362,
    '1437_900': 31.9642,
    '1437_901': 31.9799,
    '1437_902': 31.9786,
    '1437_903': 31.9635,
    '1437_904': 31.9503,
    '1437_905': 31.9495,
    '1437_906': 31.9552,
    '1437_907': 31.9694,
    '1437_908': 32.0041,
    '1437_909': 32.0556,
    '1437_910': 32.1075,
    '1437_911': 32.1503,
    '1437_912': 32.1825,
    '1437_913': 32.2057,
    '1437_914': 32.2253,
    '1437_915': 32.2298,
    '1437_916': 32.2162,
    '1437_917': 32.1795,
    '1437_918': 32.1307,
    '1437_919': 32.0899,
    '1437_920': 32.0594,
    '1437_921': 32.0292,
    '1437_922': 31.9998,
    '1437_923': 31.9804,
    '1437_924': 31.9532,
    '1437_925': 31.9117,
    '1437_926': 31.8581,
    '1437_927': 31.795,
    '1437_928': 31.7261,
    '1437_929': 31.648,
    '1437_930': 31.5678,
    '1437_931': 31.4978,
    '1437_932': 31.4454,
    '1437_933': 31.4172,
    '1437_934': 31.4097,
    '1437_935': 31.4102,
    '1437_936': 31.3902,
    '1437_937': 31.3498,
    '1437_938': 31.3034,
    '1437_939': 31.2602,
    '1437_940': 31.2177,
    '1437_941': 31.1894,
    '1437_942': 31.1884,
    '1437_943': 31.1988,
    '1437_944': 31.1986,
    '1437_945': 31.1822,
    '1437_946': 31.1725,
    '1437_947': 31.1821,
    '1437_948': 31.2037,
    '1437_949': 31.2303,
    '1437_950': 31.2742,
    '1437_951': 31.2948,
    '1437_952': 31.2895,
    '1437_953': 31.2662,
    '1437_954': 31.2326,
    '1437_955': 31.1909,
    '1437_956': 31.1592,
    '1437_957': 31.1394,
    '1437_958': 31.1361,
    '1437_959': 31.1343,
    '1437_960': 31.1186,
    '1437_961': 31.0814,
    '1437_962': 31.0162,
    '1437_963': 30.9349,
    '1437_964': 30.851,
    '1437_965': 30.7662,
    '1437_966': 30.6907,
    '1437_967': 30.6325,
    '1437_968': 30.5928,
    '1437_969': 30.5706,
    '1437_970': 30.5468,
    '1437_971': 30.5038,
    '1437_972': 30.4533,
    '1437_973': 30.4041,
    '1437_974': 30.36,
    '1437_975': 30.3234,
    '1437_976': 30.2995,
    '1437_977': 30.2867,
    '1437_978': 30.2838,
    '1437_979': 30.2897,
    '1437_980': 30.2991,
    '1437_981': 30.3048,
    '1437_982': 30.3142,
    '1437_983': 30.3287,
    '1437_984': 30.3484,
    '1437_985': 30.3747,
    '1437_986': 30.4033,
    '1437_987': 30.4397,
    '1437_988': 30.4831,
    '1437_989': 30.5248,
    '1437_990': 30.5745,
    '1437_991': 30.6351,
    '1437_992': 30.7101,
    '1437_993': 30.8055,
    '1437_994': 30.9232,
    '1437_995': 31.0416,
    '1437_996': 31.1424,
    '1437_997': 31.2223,
    '1437_998': 31.2734,
    '1437_999': 31.3045,
    '1437_1000': 31.3027,
    '1437_1001': 31.2638,
    '1437_1002': 31.2001,
    '1437_1003': 31.1189,
    '1437_1004': 31.0285,
    '1437_1005': 30.9256,
    '1437_1006': 30.8333,
    '1437_1007': 30.7527,
    '1437_1008': 30.6984,
    '1437_1009': 30.6594,
    '1437_1010': 30.6305,
    '1437_1011': 30.6085,
    '1437_1012': 30.5935,
    '1437_1013': 30.5857,
    '1437_1014': 30.5825,
    '1437_1015': 30.5836,
    '1437_1016': 30.5929,
    '1438_844': 30.7528,
    '1438_845': 30.7738,
    '1438_846': 30.7986,
    '1438_847': 30.8242,
    '1438_848': 30.8502,
    '1438_849': 30.8769,
    '1438_850': 30.902,
    '1438_851': 30.9247,
    '1438_852': 30.9444,
    '1438_853': 30.9631,
    '1438_854': 30.9807,
    '1438_855': 30.9961,
    '1438_856': 31.0077,
    '1438_857': 31.0195,
    '1438_858': 31.0293,
    '1438_859': 31.0365,
    '1438_860': 31.0419,
    '1438_861': 31.0463,
    '1438_862': 31.0489,
    '1438_863': 31.0465,
    '1438_864': 31.0426,
    '1438_865': 31.0369,
    '1438_866': 31.0362,
    '1438_867': 31.0419,
    '1438_868': 31.0447,
    '1438_869': 31.0469,
    '1438_870': 31.0549,
    '1438_871': 31.0783,
    '1438_872': 31.1115,
    '1438_873': 31.1402,
    '1438_874': 31.1423,
    '1438_875': 31.1367,
    '1438_876': 31.1441,
    '1438_877': 31.1661,
    '1438_878': 31.2063,
    '1438_879': 31.2599,
    '1438_880': 31.3225,
    '1438_881': 31.3903,
    '1438_882': 31.4448,
    '1438_883': 31.4873,
    '1438_884': 31.5306,
    '1438_885': 31.5776,
    '1438_886': 31.6216,
    '1438_887': 31.6708,
    '1438_888': 31.7226,
    '1438_889': 31.7634,
    '1438_890': 31.7891,
    '1438_891': 31.7931,
    '1438_892': 31.7946,
    '1438_893': 31.8016,
    '1438_894': 31.8104,
    '1438_895': 31.8145,
    '1438_896': 31.8323,
    '1438_897': 31.8582,
    '1438_898': 31.8894,
    '1438_899': 31.928,
    '1438_900': 31.9621,
    '1438_901': 31.9768,
    '1438_902': 31.9715,
    '1438_903': 31.9516,
    '1438_904': 31.9308,
    '1438_905': 31.9219,
    '1438_906': 31.9222,
    '1438_907': 31.9356,
    '1438_908': 31.9717,
    '1438_909': 32.0233,
    '1438_910': 32.0747,
    '1438_911': 32.1166,
    '1438_912': 32.1459,
    '1438_913': 32.1727,
    '1438_914': 32.1926,
    '1438_915': 32.1923,
    '1438_916': 32.1769,
    '1438_917': 32.1325,
    '1438_918': 32.0756,
    '1438_919': 32.0278,
    '1438_920': 31.9915,
    '1438_921': 31.9558,
    '1438_922': 31.9247,
    '1438_923': 31.9164,
    '1438_924': 31.9041,
    '1438_925': 31.8715,
    '1438_926': 31.8172,
    '1438_927': 31.7532,
    '1438_928': 31.6869,
    '1438_929': 31.6131,
    '1438_930': 31.5345,
    '1438_931': 31.4681,
    '1438_932': 31.4145,
    '1438_933': 31.3881,
    '1438_934': 31.378,
    '1438_935': 31.3703,
    '1438_936': 31.339,
    '1438_937': 31.2904,
    '1438_938': 31.248,
    '1438_939': 31.2134,
    '1438_940': 31.1783,
    '1438_941': 31.1552,
    '1438_942': 31.152,
    '1438_943': 31.1593,
    '1438_944': 31.1551,
    '1438_945': 31.1401,
    '1438_946': 31.1288,
    '1438_947': 31.1293,
    '1438_948': 31.1416,
    '1438_949': 31.1624,
    '1438_950': 31.2019,
    '1438_951': 31.2305,
    '1438_952': 31.2422,
    '1438_953': 31.2285,
    '1438_954': 31.204,
    '1438_955': 31.1685,
    '1438_956': 31.1423,
    '1438_957': 31.122,
    '1438_958': 31.1118,
    '1438_959': 31.1129,
    '1438_960': 31.0993,
    '1438_961': 31.0608,
    '1438_962': 30.9953,
    '1438_963': 30.9115,
    '1438_964': 30.8247,
    '1438_965': 30.738,
    '1438_966': 30.6587,
    '1438_967': 30.596,
    '1438_968': 30.5526,
    '1438_969': 30.5312,
    '1438_970': 30.5071,
    '1438_971': 30.4639,
    '1438_972': 30.4136,
    '1438_973': 30.3629,
    '1438_974': 30.3159,
    '1438_975': 30.2789,
    '1438_976': 30.2532,
    '1438_977': 30.2371,
    '1438_978': 30.2332,
    '1438_979': 30.2385,
    '1438_980': 30.247,
    '1438_981': 30.2437,
    '1438_982': 30.2471,
    '1438_983': 30.2571,
    '1438_984': 30.2722,
    '1438_985': 30.2947,
    '1438_986': 30.3221,
    '1438_987': 30.3557,
    '1438_988': 30.3946,
    '1438_989': 30.4348,
    '1438_990': 30.4803,
    '1438_991': 30.5343,
    '1438_992': 30.6008,
    '1438_993': 30.6808,
    '1438_994': 30.7796,
    '1438_995': 30.8942,
    '1438_996': 31.0032,
    '1438_997': 31.0982,
    '1438_998': 31.1681,
    '1438_999': 31.2239,
    '1438_1000': 31.2543,
    '1438_1001': 31.2412,
    '1438_1002': 31.189,
    '1438_1003': 31.1109,
    '1438_1004': 31.0245,
    '1438_1005': 30.9241,
    '1438_1006': 30.8212,
    '1438_1007': 30.7289,
    '1438_1008': 30.6645,
    '1438_1009': 30.6171,
    '1438_1010': 30.581,
    '1438_1011': 30.5525,
    '1438_1012': 30.5311,
    '1438_1013': 30.5171,
    '1438_1014': 30.5077,
    '1438_1015': 30.5026,
    '1438_1016': 30.5056,
    '1439_844': 30.7074,
    '1439_845': 30.7247,
    '1439_846': 30.7481,
    '1439_847': 30.772,
    '1439_848': 30.7968,
    '1439_849': 30.8249,
    '1439_850': 30.8511,
    '1439_851': 30.8747,
    '1439_852': 30.8926,
    '1439_853': 30.9096,
    '1439_854': 30.9248,
    '1439_855': 30.9384,
    '1439_856': 30.9497,
    '1439_857': 30.9628,
    '1439_858': 30.9702,
    '1439_859': 30.9739,
    '1439_860': 30.9753,
    '1439_861': 30.9774,
    '1439_862': 30.9785,
    '1439_863': 30.9735,
    '1439_864': 30.9644,
    '1439_865': 30.9589,
    '1439_866': 30.9585,
    '1439_867': 30.9669,
    '1439_868': 30.975,
    '1439_869': 30.9824,
    '1439_870': 30.9991,
    '1439_871': 31.0291,
    '1439_872': 31.0631,
    '1439_873': 31.0925,
    '1439_874': 31.0987,
    '1439_875': 31.0973,
    '1439_876': 31.1123,
    '1439_877': 31.1451,
    '1439_878': 31.1874,
    '1439_879': 31.242,
    '1439_880': 31.308,
    '1439_881': 31.3742,
    '1439_882': 31.434,
    '1439_883': 31.4848,
    '1439_884': 31.5244,
    '1439_885': 31.5619,
    '1439_886': 31.6008,
    '1439_887': 31.6455,
    '1439_888': 31.6941,
    '1439_889': 31.7359,
    '1439_890': 31.7572,
    '1439_891': 31.7549,
    '1439_892': 31.7555,
    '1439_893': 31.7645,
    '1439_894': 31.7695,
    '1439_895': 31.769,
    '1439_896': 31.7863,
    '1439_897': 31.8188,
    '1439_898': 31.8628,
    '1439_899': 31.9111,
    '1439_900': 31.9451,
    '1439_901': 31.9597,
    '1439_902': 31.9551,
    '1439_903': 31.9359,
    '1439_904': 31.9128,
    '1439_905': 31.8998,
    '1439_906': 31.8967,
    '1439_907': 31.9108,
    '1439_908': 31.9431,
    '1439_909': 31.9902,
    '1439_910': 32.0408,
    '1439_911': 32.0812,
    '1439_912': 32.1096,
    '1439_913': 32.1336,
    '1439_914': 32.1538,
    '1439_915': 32.1596,
    '1439_916': 32.1326,
    '1439_917': 32.0847,
    '1439_918': 32.02,
    '1439_919': 31.9642,
    '1439_920': 31.9189,
    '1439_921': 31.8785,
    '1439_922': 31.8507,
    '1439_923': 31.8492,
    '1439_924': 31.8431,
    '1439_925': 31.8169,
    '1439_926': 31.7647,
    '1439_927': 31.6996,
    '1439_928': 31.6365,
    '1439_929': 31.571,
    '1439_930': 31.5002,
    '1439_931': 31.4425,
    '1439_932': 31.3931,
    '1439_933': 31.3595,
    '1439_934': 31.3422,
    '1439_935': 31.3248,
    '1439_936': 31.2861,
    '1439_937': 31.2323,
    '1439_938': 31.1915,
    '1439_939': 31.1634,
    '1439_940': 31.1368,
    '1439_941': 31.1218,
    '1439_942': 31.116,
    '1439_943': 31.119,
    '1439_944': 31.1134,
    '1439_945': 31.1013,
    '1439_946': 31.091,
    '1439_947': 31.0836,
    '1439_948': 31.0837,
    '1439_949': 31.0962,
    '1439_950': 31.1227,
    '1439_951': 31.1582,
    '1439_952': 31.182,
    '1439_953': 31.1812,
    '1439_954': 31.1651,
    '1439_955': 31.1409,
    '1439_956': 31.1188,
    '1439_957': 31.1006,
    '1439_958': 31.0805,
    '1439_959': 31.0721,
    '1439_960': 31.0554,
    '1439_961': 31.0173,
    '1439_962': 30.9536,
    '1439_963': 30.8732,
    '1439_964': 30.7865,
    '1439_965': 30.7005,
    '1439_966': 30.6203,
    '1439_967': 30.5592,
    '1439_968': 30.5173,
    '1439_969': 30.4918,
    '1439_970': 30.4668,
    '1439_971': 30.4221,
    '1439_972': 30.371,
    '1439_973': 30.3198,
    '1439_974': 30.2726,
    '1439_975': 30.2317,
    '1439_976': 30.2033,
    '1439_977': 30.1855,
    '1439_978': 30.1774,
    '1439_979': 30.1769,
    '1439_980': 30.178,
    '1439_981': 30.1745,
    '1439_982': 30.1741,
    '1439_983': 30.179,
    '1439_984': 30.1898,
    '1439_985': 30.2101,
    '1439_986': 30.2358,
    '1439_987': 30.2666,
    '1439_988': 30.3023,
    '1439_989': 30.341,
    '1439_990': 30.3838,
    '1439_991': 30.433,
    '1439_992': 30.4922,
    '1439_993': 30.5651,
    '1439_994': 30.6506,
    '1439_995': 30.7509,
    '1439_996': 30.8649,
    '1439_997': 30.9746,
    '1439_998': 31.0644,
    '1439_999': 31.1477,
    '1439_1000': 31.2035,
    '1439_1001': 31.2155,
    '1439_1002': 31.1829,
    '1439_1003': 31.115,
    '1439_1004': 31.0283,
    '1439_1005': 30.9268,
    '1439_1006': 30.8169,
    '1439_1007': 30.7226,
    '1439_1008': 30.6388,
    '1439_1009': 30.5806,
    '1439_1010': 30.5361,
    '1439_1011': 30.5003,
    '1439_1012': 30.472,
    '1439_1013': 30.4514,
    '1439_1014': 30.4355,
    '1439_1015': 30.4241,
    '1439_1016': 30.4208,
    '1440_844': 30.6515,
    '1440_845': 30.6656,
    '1440_846': 30.6861,
    '1440_847': 30.7103,
    '1440_848': 30.739,
    '1440_849': 30.7686,
    '1440_850': 30.7964,
    '1440_851': 30.821,
    '1440_852': 30.841,
    '1440_853': 30.8581,
    '1440_854': 30.8725,
    '1440_855': 30.8838,
    '1440_856': 30.8931,
    '1440_857': 30.9018,
    '1440_858': 30.9074,
    '1440_859': 30.9092,
    '1440_860': 30.9057,
    '1440_861': 30.9047,
    '1440_862': 30.9016,
    '1440_863': 30.8952,
    '1440_864': 30.8874,
    '1440_865': 30.8844,
    '1440_866': 30.8865,
    '1440_867': 30.8989,
    '1440_868': 30.9098,
    '1440_869': 30.9235,
    '1440_870': 30.9424,
    '1440_871': 30.9712,
    '1440_872': 31.0025,
    '1440_873': 31.0309,
    '1440_874': 31.0476,
    '1440_875': 31.055,
    '1440_876': 31.0779,
    '1440_877': 31.1165,
    '1440_878': 31.1633,
    '1440_879': 31.2168,
    '1440_880': 31.2824,
    '1440_881': 31.3485,
    '1440_882': 31.4156,
    '1440_883': 31.4751,
    '1440_884': 31.5236,
    '1440_885': 31.5589,
    '1440_886': 31.5901,
    '1440_887': 31.63,
    '1440_888': 31.6781,
    '1440_889': 31.7123,
    '1440_890': 31.7237,
    '1440_891': 31.7137,
    '1440_892': 31.7103,
    '1440_893': 31.7196,
    '1440_894': 31.72,
    '1440_895': 31.7228,
    '1440_896': 31.7456,
    '1440_897': 31.7838,
    '1440_898': 31.8314,
    '1440_899': 31.8822,
    '1440_900': 31.9146,
    '1440_901': 31.9306,
    '1440_902': 31.9303,
    '1440_903': 31.9144,
    '1440_904': 31.8936,
    '1440_905': 31.8795,
    '1440_906': 31.8734,
    '1440_907': 31.8882,
    '1440_908': 31.9183,
    '1440_909': 31.9593,
    '1440_910': 32.0066,
    '1440_911': 32.0451,
    '1440_912': 32.0731,
    '1440_913': 32.0952,
    '1440_914': 32.1104,
    '1440_915': 32.1143,
    '1440_916': 32.0829,
    '1440_917': 32.03,
    '1440_918': 31.9621,
    '1440_919': 31.9018,
    '1440_920': 31.8476,
    '1440_921': 31.8025,
    '1440_922': 31.7749,
    '1440_923': 31.7756,
    '1440_924': 31.7742,
    '1440_925': 31.7507,
    '1440_926': 31.7085,
    '1440_927': 31.6481,
    '1440_928': 31.5872,
    '1440_929': 31.5284,
    '1440_930': 31.4735,
    '1440_931': 31.4208,
    '1440_932': 31.3737,
    '1440_933': 31.3296,
    '1440_934': 31.2999,
    '1440_935': 31.2717,
    '1440_936': 31.228,
    '1440_937': 31.1799,
    '1440_938': 31.1384,
    '1440_939': 31.1123,
    '1440_940': 31.0915,
    '1440_941': 31.078,
    '1440_942': 31.0755,
    '1440_943': 31.078,
    '1440_944': 31.0675,
    '1440_945': 31.0564,
    '1440_946': 31.0475,
    '1440_947': 31.0406,
    '1440_948': 31.0385,
    '1440_949': 31.0418,
    '1440_950': 31.0514,
    '1440_951': 31.0781,
    '1440_952': 31.1027,
    '1440_953': 31.1108,
    '1440_954': 31.1043,
    '1440_955': 31.0916,
    '1440_956': 31.0792,
    '1440_957': 31.0685,
    '1440_958': 31.0471,
    '1440_959': 31.0202,
    '1440_960': 30.9944,
    '1440_961': 30.9534,
    '1440_962': 30.8989,
    '1440_963': 30.8257,
    '1440_964': 30.7436,
    '1440_965': 30.66,
    '1440_966': 30.5826,
    '1440_967': 30.521,
    '1440_968': 30.4791,
    '1440_969': 30.4507,
    '1440_970': 30.424,
    '1440_971': 30.38,
    '1440_972': 30.328,
    '1440_973': 30.2732,
    '1440_974': 30.2246,
    '1440_975': 30.1834,
    '1440_976': 30.1525,
    '1440_977': 30.1305,
    '1440_978': 30.117,
    '1440_979': 30.111,
    '1440_980': 30.1055,
    '1440_981': 30.0999,
    '1440_982': 30.0969,
    '1440_983': 30.0986,
    '1440_984': 30.1069,
    '1440_985': 30.1243,
    '1440_986': 30.148,
    '1440_987': 30.1765,
    '1440_988': 30.2087,
    '1440_989': 30.2441,
    '1440_990': 30.2833,
    '1440_991': 30.3287,
    '1440_992': 30.3834,
    '1440_993': 30.4511,
    '1440_994': 30.5333,
    '1440_995': 30.6254,
    '1440_996': 30.7314,
    '1440_997': 30.8492,
    '1440_998': 30.9583,
    '1440_999': 31.0576,
    '1440_1000': 31.1312,
    '1440_1001': 31.1649,
    '1440_1002': 31.1567,
    '1440_1003': 31.107,
    '1440_1004': 31.024,
    '1440_1005': 30.9277,
    '1440_1006': 30.828,
    '1440_1007': 30.7239,
    '1440_1008': 30.6327,
    '1440_1009': 30.5519,
    '1440_1010': 30.4965,
    '1440_1011': 30.4518,
    '1440_1012': 30.4157,
    '1440_1013': 30.388,
    '1440_1014': 30.3655,
    '1440_1015': 30.3477,
    '1440_1016': 30.3381,
    '1441_844': 30.5931,
    '1441_845': 30.6039,
    '1441_846': 30.6225,
    '1441_847': 30.6464,
    '1441_848': 30.6798,
    '1441_849': 30.7115,
    '1441_850': 30.7416,
    '1441_851': 30.7679,
    '1441_852': 30.7901,
    '1441_853': 30.808,
    '1441_854': 30.822,
    '1441_855': 30.8309,
    '1441_856': 30.8375,
    '1441_857': 30.8422,
    '1441_858': 30.8449,
    '1441_859': 30.843,
    '1441_860': 30.8386,
    '1441_861': 30.8337,
    '1441_862': 30.8276,
    '1441_863': 30.8203,
    '1441_864': 30.8147,
    '1441_865': 30.8161,
    '1441_866': 30.8227,
    '1441_867': 30.8381,
    '1441_868': 30.859,
    '1441_869': 30.879,
    '1441_870': 30.8966,
    '1441_871': 30.9158,
    '1441_872': 30.9409,
    '1441_873': 30.9701,
    '1441_874': 30.9964,
    '1441_875': 31.0132,
    '1441_876': 31.0425,
    '1441_877': 31.0859,
    '1441_878': 31.1351,
    '1441_879': 31.1874,
    '1441_880': 31.249,
    '1441_881': 31.3175,
    '1441_882': 31.3947,
    '1441_883': 31.4685,
    '1441_884': 31.5279,
    '1441_885': 31.564,
    '1441_886': 31.5896,
    '1441_887': 31.6268,
    '1441_888': 31.6727,
    '1441_889': 31.6957,
    '1441_890': 31.692,
    '1441_891': 31.6727,
    '1441_892': 31.6642,
    '1441_893': 31.6679,
    '1441_894': 31.6685,
    '1441_895': 31.6805,
    '1441_896': 31.7111,
    '1441_897': 31.7542,
    '1441_898': 31.8041,
    '1441_899': 31.8494,
    '1441_900': 31.8814,
    '1441_901': 31.9005,
    '1441_902': 31.9055,
    '1441_903': 31.8949,
    '1441_904': 31.8749,
    '1441_905': 31.8555,
    '1441_906': 31.8465,
    '1441_907': 31.8594,
    '1441_908': 31.8886,
    '1441_909': 31.9254,
    '1441_910': 31.9682,
    '1441_911': 32.0033,
    '1441_912': 32.0299,
    '1441_913': 32.0507,
    '1441_914': 32.0657,
    '1441_915': 32.062,
    '1441_916': 32.0341,
    '1441_917': 31.9764,
    '1441_918': 31.904,
    '1441_919': 31.8384,
    '1441_920': 31.7767,
    '1441_921': 31.7281,
    '1441_922': 31.7,
    '1441_923': 31.6953,
    '1441_924': 31.6959,
    '1441_925': 31.6803,
    '1441_926': 31.643,
    '1441_927': 31.5892,
    '1441_928': 31.5348,
    '1441_929': 31.4876,
    '1441_930': 31.4451,
    '1441_931': 31.3981,
    '1441_932': 31.3506,
    '1441_933': 31.303,
    '1441_934': 31.2594,
    '1441_935': 31.2231,
    '1441_936': 31.1819,
    '1441_937': 31.1358,
    '1441_938': 31.0872,
    '1441_939': 31.0544,
    '1441_940': 31.0369,
    '1441_941': 31.0288,
    '1441_942': 31.0313,
    '1441_943': 31.0317,
    '1441_944': 31.0175,
    '1441_945': 31.0048,
    '1441_946': 30.9965,
    '1441_947': 30.9931,
    '1441_948': 30.9919,
    '1441_949': 30.9873,
    '1441_950': 30.9895,
    '1441_951': 31.0041,
    '1441_952': 31.0227,
    '1441_953': 31.0319,
    '1441_954': 31.0339,
    '1441_955': 31.028,
    '1441_956': 31.0232,
    '1441_957': 31.0233,
    '1441_958': 31.0097,
    '1441_959': 30.9727,
    '1441_960': 30.9235,
    '1441_961': 30.8833,
    '1441_962': 30.8362,
    '1441_963': 30.7687,
    '1441_964': 30.6918,
    '1441_965': 30.6121,
    '1441_966': 30.5372,
    '1441_967': 30.4759,
    '1441_968': 30.4408,
    '1441_969': 30.4128,
    '1441_970': 30.3778,
    '1441_971': 30.3338,
    '1441_972': 30.2826,
    '1441_973': 30.2277,
    '1441_974': 30.1777,
    '1441_975': 30.1367,
    '1441_976': 30.1036,
    '1441_977': 30.0771,
    '1441_978': 30.0563,
    '1441_979': 30.046,
    '1441_980': 30.0337,
    '1441_981': 30.0243,
    '1441_982': 30.0185,
    '1441_983': 30.0173,
    '1441_984': 30.0227,
    '1441_985': 30.038,
    '1441_986': 30.06,
    '1441_987': 30.086,
    '1441_988': 30.1141,
    '1441_989': 30.1452,
    '1441_990': 30.1806,
    '1441_991': 30.2221,
    '1441_992': 30.2724,
    '1441_993': 30.3375,
    '1441_994': 30.4148,
    '1441_995': 30.5031,
    '1441_996': 30.6006,
    '1441_997': 30.7121,
    '1441_998': 30.8303,
    '1441_999': 30.9377,
    '1441_1000': 31.0265,
    '1441_1001': 31.0854,
    '1441_1002': 31.0999,
    '1441_1003': 31.0762,
    '1441_1004': 31.0118,
    '1441_1005': 30.9308,
    '1441_1006': 30.8375,
    '1441_1007': 30.7348,
    '1441_1008': 30.6286,
    '1441_1009': 30.5337,
    '1441_1010': 30.4643,
    '1441_1011': 30.4081,
    '1441_1012': 30.3629,
    '1441_1013': 30.3276,
    '1441_1014': 30.2985,
    '1441_1015': 30.2747,
    '1441_1016': 30.2594,
    '1442_844': 30.5393,
    '1442_845': 30.5486,
    '1442_846': 30.5651,
    '1442_847': 30.589,
    '1442_848': 30.6202,
    '1442_849': 30.6535,
    '1442_850': 30.6858,
    '1442_851': 30.7147,
    '1442_852': 30.7389,
    '1442_853': 30.757,
    '1442_854': 30.7695,
    '1442_855': 30.776,
    '1442_856': 30.7843,
    '1442_857': 30.7882,
    '1442_858': 30.7879,
    '1442_859': 30.7826,
    '1442_860': 30.7754,
    '1442_861': 30.7672,
    '1442_862': 30.7586,
    '1442_863': 30.7512,
    '1442_864': 30.7485,
    '1442_865': 30.7522,
    '1442_866': 30.7654,
    '1442_867': 30.7863,
    '1442_868': 30.8121,
    '1442_869': 30.8385,
    '1442_870': 30.8562,
    '1442_871': 30.8689,
    '1442_872': 30.8874,
    '1442_873': 30.9122,
    '1442_874': 30.943,
    '1442_875': 30.9726,
    '1442_876': 31.0085,
    '1442_877': 31.0534,
    '1442_878': 31.1052,
    '1442_879': 31.1605,
    '1442_880': 31.2171,
    '1442_881': 31.2906,
    '1442_882': 31.3772,
    '1442_883': 31.462,
    '1442_884': 31.5276,
    '1442_885': 31.5616,
    '1442_886': 31.5861,
    '1442_887': 31.6268,
    '1442_888': 31.6655,
    '1442_889': 31.6731,
    '1442_890': 31.6578,
    '1442_891': 31.6386,
    '1442_892': 31.6236,
    '1442_893': 31.618,
    '1442_894': 31.6229,
    '1442_895': 31.6423,
    '1442_896': 31.679,
    '1442_897': 31.7285,
    '1442_898': 31.7781,
    '1442_899': 31.815,
    '1442_900': 31.844,
    '1442_901': 31.8665,
    '1442_902': 31.8784,
    '1442_903': 31.871,
    '1442_904': 31.8462,
    '1442_905': 31.8221,
    '1442_906': 31.8101,
    '1442_907': 31.8214,
    '1442_908': 31.8509,
    '1442_909': 31.8856,
    '1442_910': 31.9209,
    '1442_911': 31.9489,
    '1442_912': 31.9725,
    '1442_913': 31.9953,
    '1442_914': 32.0119,
    '1442_915': 32.0098,
    '1442_916': 31.9814,
    '1442_917': 31.924,
    '1442_918': 31.8495,
    '1442_919': 31.7752,
    '1442_920': 31.7096,
    '1442_921': 31.6594,
    '1442_922': 31.6266,
    '1442_923': 31.6154,
    '1442_924': 31.6083,
    '1442_925': 31.5953,
    '1442_926': 31.5626,
    '1442_927': 31.5142,
    '1442_928': 31.4694,
    '1442_929': 31.4361,
    '1442_930': 31.4032,
    '1442_931': 31.3633,
    '1442_932': 31.3176,
    '1442_933': 31.2728,
    '1442_934': 31.2302,
    '1442_935': 31.1921,
    '1442_936': 31.1464,
    '1442_937': 31.0891,
    '1442_938': 31.0352,
    '1442_939': 30.9999,
    '1442_940': 30.9823,
    '1442_941': 30.9791,
    '1442_942': 30.9819,
    '1442_943': 30.982,
    '1442_944': 30.967,
    '1442_945': 30.9516,
    '1442_946': 30.9405,
    '1442_947': 30.9362,
    '1442_948': 30.9327,
    '1442_949': 30.9247,
    '1442_950': 30.9293,
    '1442_951': 30.9422,
    '1442_952': 30.9616,
    '1442_953': 30.9657,
    '1442_954': 30.9632,
    '1442_955': 30.9537,
    '1442_956': 30.9482,
    '1442_957': 30.9554,
    '1442_958': 30.9541,
    '1442_959': 30.9244,
    '1442_960': 30.8702,
    '1442_961': 30.8159,
    '1442_962': 30.772,
    '1442_963': 30.7084,
    '1442_964': 30.6341,
    '1442_965': 30.5572,
    '1442_966': 30.487,
    '1442_967': 30.4319,
    '1442_968': 30.398,
    '1442_969': 30.3698,
    '1442_970': 30.3292,
    '1442_971': 30.2806,
    '1442_972': 30.2315,
    '1442_973': 30.1784,
    '1442_974': 30.126,
    '1442_975': 30.0881,
    '1442_976': 30.0577,
    '1442_977': 30.0263,
    '1442_978': 29.9993,
    '1442_979': 29.9791,
    '1442_980': 29.9615,
    '1442_981': 29.948,
    '1442_982': 29.9393,
    '1442_983': 29.9361,
    '1442_984': 29.9398,
    '1442_985': 29.9524,
    '1442_986': 29.9713,
    '1442_987': 29.9937,
    '1442_988': 30.0173,
    '1442_989': 30.0437,
    '1442_990': 30.075,
    '1442_991': 30.1138,
    '1442_992': 30.1607,
    '1442_993': 30.219,
    '1442_994': 30.2896,
    '1442_995': 30.3708,
    '1442_996': 30.4658,
    '1442_997': 30.5692,
    '1442_998': 30.683,
    '1442_999': 30.7906,
    '1442_1000': 30.89,
    '1442_1001': 30.9699,
    '1442_1002': 31.015,
    '1442_1003': 31.0215,
    '1442_1004': 30.9834,
    '1442_1005': 30.9214,
    '1442_1006': 30.8407,
    '1442_1007': 30.7463,
    '1442_1008': 30.6403,
    '1442_1009': 30.539,
    '1442_1010': 30.4396,
    '1442_1011': 30.3675,
    '1442_1012': 30.3118,
    '1442_1013': 30.2687,
    '1442_1014': 30.233,
    '1442_1015': 30.2032,
    '1442_1016': 30.1821,
    '1443_844': 30.4891,
    '1443_845': 30.4975,
    '1443_846': 30.5122,
    '1443_847': 30.5354,
    '1443_848': 30.5647,
    '1443_849': 30.599,
    '1443_850': 30.633,
    '1443_851': 30.6638,
    '1443_852': 30.69,
    '1443_853': 30.7094,
    '1443_854': 30.7238,
    '1443_855': 30.7328,
    '1443_856': 30.7397,
    '1443_857': 30.7419,
    '1443_858': 30.738,
    '1443_859': 30.7282,
    '1443_860': 30.7176,
    '1443_861': 30.7069,
    '1443_862': 30.6965,
    '1443_863': 30.6889,
    '1443_864': 30.6891,
    '1443_865': 30.6962,
    '1443_866': 30.7144,
    '1443_867': 30.7416,
    '1443_868': 30.7705,
    '1443_869': 30.7969,
    '1443_870': 30.8172,
    '1443_871': 30.8333,
    '1443_872': 30.8541,
    '1443_873': 30.8778,
    '1443_874': 30.9052,
    '1443_875': 30.9384,
    '1443_876': 30.9775,
    '1443_877': 31.0222,
    '1443_878': 31.0742,
    '1443_879': 31.1298,
    '1443_880': 31.1909,
    '1443_881': 31.2721,
    '1443_882': 31.3637,
    '1443_883': 31.4521,
    '1443_884': 31.5155,
    '1443_885': 31.5478,
    '1443_886': 31.5775,
    '1443_887': 31.6222,
    '1443_888': 31.6454,
    '1443_889': 31.638,
    '1443_890': 31.6172,
    '1443_891': 31.6005,
    '1443_892': 31.5856,
    '1443_893': 31.5774,
    '1443_894': 31.5827,
    '1443_895': 31.6059,
    '1443_896': 31.6458,
    '1443_897': 31.6973,
    '1443_898': 31.7425,
    '1443_899': 31.7765,
    '1443_900': 31.8022,
    '1443_901': 31.8254,
    '1443_902': 31.8417,
    '1443_903': 31.8365,
    '1443_904': 31.8103,
    '1443_905': 31.7827,
    '1443_906': 31.7717,
    '1443_907': 31.7832,
    '1443_908': 31.811,
    '1443_909': 31.8404,
    '1443_910': 31.8674,
    '1443_911': 31.8867,
    '1443_912': 31.9073,
    '1443_913': 31.9317,
    '1443_914': 31.9503,
    '1443_915': 31.9458,
    '1443_916': 31.9203,
    '1443_917': 31.8648,
    '1443_918': 31.792,
    '1443_919': 31.7126,
    '1443_920': 31.6503,
    '1443_921': 31.5967,
    '1443_922': 31.5557,
    '1443_923': 31.5356,
    '1443_924': 31.5239,
    '1443_925': 31.5075,
    '1443_926': 31.4761,
    '1443_927': 31.4315,
    '1443_928': 31.3917,
    '1443_929': 31.3667,
    '1443_930': 31.342,
    '1443_931': 31.3103,
    '1443_932': 31.27,
    '1443_933': 31.2338,
    '1443_934': 31.1967,
    '1443_935': 31.1571,
    '1443_936': 31.1077,
    '1443_937': 31.0405,
    '1443_938': 30.9873,
    '1443_939': 30.9517,
    '1443_940': 30.9361,
    '1443_941': 30.9299,
    '1443_942': 30.9317,
    '1443_943': 30.9324,
    '1443_944': 30.9175,
    '1443_945': 30.8991,
    '1443_946': 30.8846,
    '1443_947': 30.8794,
    '1443_948': 30.8698,
    '1443_949': 30.86,
    '1443_950': 30.8644,
    '1443_951': 30.8811,
    '1443_952': 30.8968,
    '1443_953': 30.9055,
    '1443_954': 30.904,
    '1443_955': 30.8956,
    '1443_956': 30.8782,
    '1443_957': 30.8732,
    '1443_958': 30.8745,
    '1443_959': 30.8542,
    '1443_960': 30.8094,
    '1443_961': 30.755,
    '1443_962': 30.7103,
    '1443_963': 30.6493,
    '1443_964': 30.5746,
    '1443_965': 30.4958,
    '1443_966': 30.4287,
    '1443_967': 30.3775,
    '1443_968': 30.3462,
    '1443_969': 30.3187,
    '1443_970': 30.276,
    '1443_971': 30.2248,
    '1443_972': 30.171,
    '1443_973': 30.1207,
    '1443_974': 30.0717,
    '1443_975': 30.0315,
    '1443_976': 29.9958,
    '1443_977': 29.9657,
    '1443_978': 29.9353,
    '1443_979': 29.9094,
    '1443_980': 29.8871,
    '1443_981': 29.8711,
    '1443_982': 29.8601,
    '1443_983': 29.8553,
    '1443_984': 29.8563,
    '1443_985': 29.8665,
    '1443_986': 29.883,
    '1443_987': 29.9015,
    '1443_988': 29.9197,
    '1443_989': 29.9408,
    '1443_990': 29.9674,
    '1443_991': 30.0038,
    '1443_992': 30.0456,
    '1443_993': 30.0966,
    '1443_994': 30.1605,
    '1443_995': 30.2361,
    '1443_996': 30.3257,
    '1443_997': 30.4213,
    '1443_998': 30.5241,
    '1443_999': 30.6302,
    '1443_1000': 30.7358,
    '1443_1001': 30.8312,
    '1443_1002': 30.9011,
    '1443_1003': 30.9359,
    '1443_1004': 30.9372,
    '1443_1005': 30.9081,
    '1443_1006': 30.8442,
    '1443_1007': 30.7522,
    '1443_1008': 30.6443,
    '1443_1009': 30.5346,
    '1443_1010': 30.4268,
    '1443_1011': 30.3284,
    '1443_1012': 30.262,
    '1443_1013': 30.2117,
    '1443_1014': 30.1699,
    '1443_1015': 30.1342,
    '1443_1016': 30.1073,
    '1443_1017': 30.0854,
    '1443_1018': 30.0691,
    '1443_1019': 30.0611,
    '1443_1020': 30.0617,
    '1443_1021': 30.0692,
    '1443_1022': 30.0841,
    '1444_844': 30.4442,
    '1444_845': 30.453,
    '1444_846': 30.4678,
    '1444_847': 30.4905,
    '1444_848': 30.519,
    '1444_849': 30.5525,
    '1444_850': 30.5851,
    '1444_851': 30.6151,
    '1444_852': 30.6409,
    '1444_853': 30.6626,
    '1444_854': 30.6785,
    '1444_855': 30.6896,
    '1444_856': 30.6954,
    '1444_857': 30.6961,
    '1444_858': 30.6898,
    '1444_859': 30.6778,
    '1444_860': 30.6659,
    '1444_861': 30.6539,
    '1444_862': 30.6429,
    '1444_863': 30.6363,
    '1444_864': 30.6381,
    '1444_865': 30.6476,
    '1444_866': 30.6689,
    '1444_867': 30.6999,
    '1444_868': 30.7329,
    '1444_869': 30.7617,
    '1444_870': 30.788,
    '1444_871': 30.8087,
    '1444_872': 30.8362,
    '1444_873': 30.8633,
    '1444_874': 30.8869,
    '1444_875': 30.9167,
    '1444_876': 30.9501,
    '1444_877': 30.9891,
    '1444_878': 31.0385,
    '1444_879': 31.0966,
    '1444_880': 31.1678,
    '1444_881': 31.2524,
    '1444_882': 31.3484,
    '1444_883': 31.4367,
    '1444_884': 31.4942,
    '1444_885': 31.5236,
    '1444_886': 31.5572,
    '1444_887': 31.6037,
    '1444_888': 31.619,
    '1444_889': 31.6007,
    '1444_890': 31.5784,
    '1444_891': 31.5623,
    '1444_892': 31.5465,
    '1444_893': 31.5395,
    '1444_894': 31.5473,
    '1444_895': 31.5729,
    '1444_896': 31.6128,
    '1444_897': 31.6608,
    '1444_898': 31.7038,
    '1444_899': 31.7349,
    '1444_900': 31.758,
    '1444_901': 31.7777,
    '1444_902': 31.792,
    '1444_903': 31.7889,
    '1444_904': 31.7651,
    '1444_905': 31.7411,
    '1444_906': 31.7352,
    '1444_907': 31.7512,
    '1444_908': 31.7777,
    '1444_909': 31.7978,
    '1444_910': 31.813,
    '1444_911': 31.826,
    '1444_912': 31.8428,
    '1444_913': 31.8654,
    '1444_914': 31.881,
    '1444_915': 31.8771,
    '1444_916': 31.8511,
    '1444_917': 31.7983,
    '1444_918': 31.7272,
    '1444_919': 31.6485,
    '1444_920': 31.5872,
    '1444_921': 31.535,
    '1444_922': 31.4872,
    '1444_923': 31.4566,
    '1444_924': 31.4412,
    '1444_925': 31.4214,
    '1444_926': 31.3914,
    '1444_927': 31.3495,
    '1444_928': 31.3136,
    '1444_929': 31.2926,
    '1444_930': 31.2719,
    '1444_931': 31.2485,
    '1444_932': 31.2162,
    '1444_933': 31.1847,
    '1444_934': 31.1546,
    '1444_935': 31.1146,
    '1444_936': 31.0628,
    '1444_937': 30.9945,
    '1444_938': 30.9415,
    '1444_939': 30.9063,
    '1444_940': 30.8876,
    '1444_941': 30.8825,
    '1444_942': 30.8866,
    '1444_943': 30.8865,
    '1444_944': 30.8683,
    '1444_945': 30.8465,
    '1444_946': 30.8295,
    '1444_947': 30.8199,
    '1444_948': 30.8074,
    '1444_949': 30.7953,
    '1444_950': 30.7962,
    '1444_951': 30.8104,
    '1444_952': 30.8214,
    '1444_953': 30.8355,
    '1444_954': 30.8409,
    '1444_955': 30.8327,
    '1444_956': 30.811,
    '1444_957': 30.792,
    '1444_958': 30.786,
    '1444_959': 30.7634,
    '1444_960': 30.7265,
    '1444_961': 30.6832,
    '1444_962': 30.6418,
    '1444_963': 30.5867,
    '1444_964': 30.5135,
    '1444_965': 30.4292,
    '1444_966': 30.3634,
    '1444_967': 30.3127,
    '1444_968': 30.2839,
    '1444_969': 30.2618,
    '1444_970': 30.2216,
    '1444_971': 30.1674,
    '1444_972': 30.1126,
    '1444_973': 30.0627,
    '1444_974': 30.0137,
    '1444_975': 29.9711,
    '1444_976': 29.9311,
    '1444_977': 29.8953,
    '1444_978': 29.8619,
    '1444_979': 29.833,
    '1444_980': 29.8096,
    '1444_981': 29.7953,
    '1444_982': 29.783,
    '1444_983': 29.7763,
    '1444_984': 29.7751,
    '1444_985': 29.7813,
    '1444_986': 29.7945,
    '1444_987': 29.8069,
    '1444_988': 29.8205,
    '1444_989': 29.8376,
    '1444_990': 29.8606,
    '1444_991': 29.8913,
    '1444_992': 29.9285,
    '1444_993': 29.9738,
    '1444_994': 30.0305,
    '1444_995': 30.0992,
    '1444_996': 30.1785,
    '1444_997': 30.2713,
    '1444_998': 30.3667,
    '1444_999': 30.4662,
    '1444_1000': 30.5707,
    '1444_1001': 30.6739,
    '1444_1002': 30.7603,
    '1444_1003': 30.8263,
    '1444_1004': 30.8705,
    '1444_1005': 30.8725,
    '1444_1006': 30.835,
    '1444_1007': 30.7463,
    '1444_1008': 30.6312,
    '1444_1009': 30.5149,
    '1444_1010': 30.3973,
    '1444_1011': 30.2871,
    '1444_1012': 30.2133,
    '1444_1013': 30.1572,
    '1444_1014': 30.1099,
    '1444_1015': 30.0685,
    '1444_1016': 30.0356,
    '1444_1017': 30.0077,
    '1444_1018': 29.9851,
    '1444_1019': 29.9712,
    '1444_1020': 29.9675,
    '1444_1021': 29.9709,
    '1444_1022': 29.9822,
    '1445_844': 30.4034,
    '1445_845': 30.4142,
    '1445_846': 30.4295,
    '1445_847': 30.453,
    '1445_848': 30.4808,
    '1445_849': 30.5147,
    '1445_850': 30.5466,
    '1445_851': 30.5759,
    '1445_852': 30.6028,
    '1445_853': 30.6247,
    '1445_854': 30.6404,
    '1445_855': 30.6527,
    '1445_856': 30.6572,
    '1445_857': 30.6569,
    '1445_858': 30.6484,
    '1445_859': 30.6355,
    '1445_860': 30.6201,
    '1445_861': 30.6064,
    '1445_862': 30.5949,
    '1445_863': 30.5894,
    '1445_864': 30.593,
    '1445_865': 30.6055,
    '1445_866': 30.6307,
    '1445_867': 30.6703,
    '1445_868': 30.7058,
    '1445_869': 30.7396,
    '1445_870': 30.7689,
    '1445_871': 30.7914,
    '1445_872': 30.8222,
    '1445_873': 30.8519,
    '1445_874': 30.8712,
    '1445_875': 30.8995,
    '1445_876': 30.9321,
    '1445_877': 30.9667,
    '1445_878': 31.0067,
    '1445_879': 31.0672,
    '1445_880': 31.1439,
    '1445_881': 31.2294,
    '1445_882': 31.3323,
    '1445_883': 31.4198,
    '1445_884': 31.4738,
    '1445_885': 31.4984,
    '1445_886': 31.5232,
    '1445_887': 31.5644,
    '1445_888': 31.5768,
    '1445_889': 31.5586,
    '1445_890': 31.5395,
    '1445_891': 31.5231,
    '1445_892': 31.5065,
    '1445_893': 31.5022,
    '1445_894': 31.5144,
    '1445_895': 31.5424,
    '1445_896': 31.582,
    '1445_897': 31.6239,
    '1445_898': 31.6633,
    '1445_899': 31.6911,
    '1445_900': 31.7101,
    '1445_901': 31.7257,
    '1445_902': 31.7359,
    '1445_903': 31.7355,
    '1445_904': 31.7161,
    '1445_905': 31.6986,
    '1445_906': 31.7009,
    '1445_907': 31.7202,
    '1445_908': 31.743,
    '1445_909': 31.7581,
    '1445_910': 31.7676,
    '1445_911': 31.7759,
    '1445_912': 31.7864,
    '1445_913': 31.8028,
    '1445_914': 31.8105,
    '1445_915': 31.801,
    '1445_916': 31.7712,
    '1445_917': 31.723,
    '1445_918': 31.6547,
    '1445_919': 31.5785,
    '1445_920': 31.5148,
    '1445_921': 31.4674,
    '1445_922': 31.4189,
    '1445_923': 31.3806,
    '1445_924': 31.3604,
    '1445_925': 31.3385,
    '1445_926': 31.308,
    '1445_927': 31.2702,
    '1445_928': 31.2428,
    '1445_929': 31.2248,
    '1445_930': 31.2059,
    '1445_931': 31.1876,
    '1445_932': 31.1606,
    '1445_933': 31.1282,
    '1445_934': 31.0989,
    '1445_935': 31.0621,
    '1445_936': 31.0121,
    '1445_937': 30.9516,
    '1445_938': 30.9023,
    '1445_939': 30.8694,
    '1445_940': 30.8425,
    '1445_941': 30.8344,
    '1445_942': 30.8407,
    '1445_943': 30.8423,
    '1445_944': 30.8233,
    '1445_945': 30.7961,
    '1445_946': 30.7717,
    '1445_947': 30.7588,
    '1445_948': 30.7443,
    '1445_949': 30.7307,
    '1445_950': 30.7263,
    '1445_951': 30.7311,
    '1445_952': 30.7409,
    '1445_953': 30.7568,
    '1445_954': 30.756,
    '1445_955': 30.742,
    '1445_956': 30.7221,
    '1445_957': 30.7037,
    '1445_958': 30.6909,
    '1445_959': 30.6702,
    '1445_960': 30.6357,
    '1445_961': 30.5987,
    '1445_962': 30.5636,
    '1445_963': 30.5173,
    '1445_964': 30.4489,
    '1445_965': 30.3695,
    '1445_966': 30.2995,
    '1445_967': 30.244,
    '1445_968': 30.2112,
    '1445_969': 30.193,
    '1445_970': 30.1588,
    '1445_971': 30.1006,
    '1445_972': 30.0523,
    '1445_973': 30.0064,
    '1445_974': 29.9588,
    '1445_975': 29.9108,
    '1445_976': 29.8632,
    '1445_977': 29.82,
    '1445_978': 29.7822,
    '1445_979': 29.7517,
    '1445_980': 29.7282,
    '1445_981': 29.7103,
    '1445_982': 29.6983,
    '1445_983': 29.6927,
    '1445_984': 29.6897,
    '1445_985': 29.6913,
    '1445_986': 29.7037,
    '1445_987': 29.7087,
    '1445_988': 29.7175,
    '1445_989': 29.7309,
    '1445_990': 29.75,
    '1445_991': 29.7763,
    '1445_992': 29.8092,
    '1445_993': 29.8494,
    '1445_994': 29.8994,
    '1445_995': 29.9619,
    '1445_996': 30.0331,
    '1445_997': 30.1154,
    '1445_998': 30.2095,
    '1445_999': 30.3039,
    '1445_1000': 30.4024,
    '1445_1001': 30.5038,
    '1445_1002': 30.602,
    '1445_1003': 30.6908,
    '1445_1004': 30.7701,
    '1445_1005': 30.8018,
    '1445_1006': 30.7923,
    '1445_1007': 30.7265,
    '1445_1008': 30.6127,
    '1445_1009': 30.4861,
    '1445_1010': 30.3587,
    '1445_1011': 30.2425,
    '1445_1012': 30.1646,
    '1445_1013': 30.104,
    '1445_1014': 30.0517,
    '1445_1015': 30.0046,
    '1445_1016': 29.9657,
    '1445_1017': 29.9317,
    '1445_1018': 29.9034,
    '1445_1019': 29.8843,
    '1445_1020': 29.8752,
    '1445_1021': 29.8741,
    '1445_1022': 29.8815,
    '1446_844': 30.3702,
    '1446_845': 30.3839,
    '1446_846': 30.403,
    '1446_847': 30.4265,
    '1446_848': 30.4552,
    '1446_849': 30.4857,
    '1446_850': 30.5171,
    '1446_851': 30.5474,
    '1446_852': 30.5757,
    '1446_853': 30.5995,
    '1446_854': 30.6172,
    '1446_855': 30.6291,
    '1446_856': 30.6333,
    '1446_857': 30.6261,
    '1446_858': 30.6106,
    '1446_859': 30.5926,
    '1446_860': 30.575,
    '1446_861': 30.5603,
    '1446_862': 30.5496,
    '1446_863': 30.5454,
    '1446_864': 30.5525,
    '1446_865': 30.5709,
    '1446_866': 30.598,
    '1446_867': 30.6372,
    '1446_868': 30.6753,
    '1446_869': 30.7154,
    '1446_870': 30.7474,
    '1446_871': 30.7716,
    '1446_872': 30.8002,
    '1446_873': 30.8324,
    '1446_874': 30.8547,
    '1446_875': 30.8852,
    '1446_876': 30.9183,
    '1446_877': 30.9494,
    '1446_878': 30.9843,
    '1446_879': 31.0357,
    '1446_880': 31.1117,
    '1446_881': 31.2041,
    '1446_882': 31.3102,
    '1446_883': 31.393,
    '1446_884': 31.4391,
    '1446_885': 31.458,
    '1446_886': 31.4782,
    '1446_887': 31.5132,
    '1446_888': 31.5263,
    '1446_889': 31.5122,
    '1446_890': 31.4936,
    '1446_891': 31.4814,
    '1446_892': 31.4689,
    '1446_893': 31.4681,
    '1446_894': 31.4824,
    '1446_895': 31.5091,
    '1446_896': 31.5451,
    '1446_897': 31.5844,
    '1446_898': 31.6213,
    '1446_899': 31.6448,
    '1446_900': 31.6624,
    '1446_901': 31.6755,
    '1446_902': 31.6839,
    '1446_903': 31.6834,
    '1446_904': 31.6688,
    '1446_905': 31.66,
    '1446_906': 31.6684,
    '1446_907': 31.6887,
    '1446_908': 31.7077,
    '1446_909': 31.7252,
    '1446_910': 31.738,
    '1446_911': 31.7388,
    '1446_912': 31.7411,
    '1446_913': 31.7462,
    '1446_914': 31.7436,
    '1446_915': 31.7279,
    '1446_916': 31.6957,
    '1446_917': 31.6471,
    '1446_918': 31.5807,
    '1446_919': 31.5072,
    '1446_920': 31.4434,
    '1446_921': 31.3957,
    '1446_922': 31.3504,
    '1446_923': 31.3082,
    '1446_924': 31.2824,
    '1446_925': 31.2598,
    '1446_926': 31.2318,
    '1446_927': 31.1965,
    '1446_928': 31.1718,
    '1446_929': 31.1585,
    '1446_930': 31.1469,
    '1446_931': 31.1346,
    '1446_932': 31.1092,
    '1446_933': 31.0742,
    '1446_934': 31.0419,
    '1446_935': 31.0055,
    '1446_936': 30.9587,
    '1446_937': 30.9112,
    '1446_938': 30.8639,
    '1446_939': 30.8317,
    '1446_940': 30.8077,
    '1446_941': 30.7923,
    '1446_942': 30.7867,
    '1446_943': 30.7871,
    '1446_944': 30.7686,
    '1446_945': 30.7443,
    '1446_946': 30.715,
    '1446_947': 30.6972,
    '1446_948': 30.6795,
    '1446_949': 30.6636,
    '1446_950': 30.6535,
    '1446_951': 30.6501,
    '1446_952': 30.6525,
    '1446_953': 30.6594,
    '1446_954': 30.658,
    '1446_955': 30.6417,
    '1446_956': 30.6269,
    '1446_957': 30.6098,
    '1446_958': 30.5926,
    '1446_959': 30.5675,
    '1446_960': 30.5385,
    '1446_961': 30.5059,
    '1446_962': 30.4798,
    '1446_963': 30.439,
    '1446_964': 30.3795,
    '1446_965': 30.306,
    '1446_966': 30.2341,
    '1446_967': 30.1712,
    '1446_968': 30.1324,
    '1446_969': 30.111,
    '1446_970': 30.0825,
    '1446_971': 30.0308,
    '1446_972': 29.9874,
    '1446_973': 29.9469,
    '1446_974': 29.9021,
    '1446_975': 29.848,
    '1446_976': 29.7922,
    '1446_977': 29.7414,
    '1446_978': 29.6974,
    '1446_979': 29.6638,
    '1446_980': 29.6374,
    '1446_981': 29.6168,
    '1446_982': 29.6028,
    '1446_983': 29.6033,
    '1446_984': 29.6003,
    '1446_985': 29.5982,
    '1446_986': 29.6015,
    '1446_987': 29.6043,
    '1446_988': 29.6102,
    '1446_989': 29.6205,
    '1446_990': 29.6362,
    '1446_991': 29.6591,
    '1446_992': 29.6899,
    '1446_993': 29.7281,
    '1446_994': 29.7745,
    '1446_995': 29.8297,
    '1446_996': 29.8933,
    '1446_997': 29.9664,
    '1446_998': 30.0482,
    '1446_999': 30.1425,
    '1446_1000': 30.2355,
    '1446_1001': 30.3327,
    '1446_1002': 30.4364,
    '1446_1003': 30.538,
    '1446_1004': 30.642,
    '1446_1005': 30.7101,
    '1446_1006': 30.7205,
    '1446_1007': 30.6859,
    '1446_1008': 30.589,
    '1446_1009': 30.4601,
    '1446_1010': 30.3195,
    '1446_1011': 30.1994,
    '1446_1012': 30.1189,
    '1446_1013': 30.0543,
    '1446_1014': 29.9965,
    '1446_1015': 29.9434,
    '1446_1016': 29.8979,
    '1446_1017': 29.8572,
    '1446_1018': 29.8226,
    '1446_1019': 29.7976,
    '1446_1020': 29.7833,
    '1446_1021': 29.7778,
    '1446_1022': 29.7815,
    '1447_844': 30.3399,
    '1447_845': 30.3549,
    '1447_846': 30.3752,
    '1447_847': 30.3998,
    '1447_848': 30.4278,
    '1447_849': 30.4597,
    '1447_850': 30.4926,
    '1447_851': 30.5246,
    '1447_852': 30.5549,
    '1447_853': 30.5804,
    '1447_854': 30.598,
    '1447_855': 30.6061,
    '1447_856': 30.6031,
    '1447_857': 30.5916,
    '1447_858': 30.5727,
    '1447_859': 30.5512,
    '1447_860': 30.5324,
    '1447_861': 30.5185,
    '1447_862': 30.5092,
    '1447_863': 30.5068,
    '1447_864': 30.5156,
    '1447_865': 30.5362,
    '1447_866': 30.5649,
    '1447_867': 30.6039,
    '1447_868': 30.6411,
    '1447_869': 30.6798,
    '1447_870': 30.7138,
    '1447_871': 30.7385,
    '1447_872': 30.7649,
    '1447_873': 30.8014,
    '1447_874': 30.8315,
    '1447_875': 30.8619,
    '1447_876': 30.8956,
    '1447_877': 30.9308,
    '1447_878': 30.967,
    '1447_879': 31.0109,
    '1447_880': 31.0814,
    '1447_881': 31.1731,
    '1447_882': 31.2759,
    '1447_883': 31.3522,
    '1447_884': 31.387,
    '1447_885': 31.3993,
    '1447_886': 31.4205,
    '1447_887': 31.4539,
    '1447_888': 31.475,
    '1447_889': 31.4631,
    '1447_890': 31.4474,
    '1447_891': 31.4418,
    '1447_892': 31.4345,
    '1447_893': 31.436,
    '1447_894': 31.45,
    '1447_895': 31.4733,
    '1447_896': 31.506,
    '1447_897': 31.5412,
    '1447_898': 31.5741,
    '1447_899': 31.5968,
    '1447_900': 31.6167,
    '1447_901': 31.6313,
    '1447_902': 31.6375,
    '1447_903': 31.6356,
    '1447_904': 31.6282,
    '1447_905': 31.6233,
    '1447_906': 31.6331,
    '1447_907': 31.6531,
    '1447_908': 31.6755,
    '1447_909': 31.7022,
    '1447_910': 31.7184,
    '1447_911': 31.7146,
    '1447_912': 31.7056,
    '1447_913': 31.6992,
    '1447_914': 31.6895,
    '1447_915': 31.6664,
    '1447_916': 31.6297,
    '1447_917': 31.5793,
    '1447_918': 31.5121,
    '1447_919': 31.4431,
    '1447_920': 31.3791,
    '1447_921': 31.327,
    '1447_922': 31.2821,
    '1447_923': 31.2384,
    '1447_924': 31.2079,
    '1447_925': 31.1857,
    '1447_926': 31.1616,
    '1447_927': 31.1286,
    '1447_928': 31.1038,
    '1447_929': 31.0934,
    '1447_930': 31.0891,
    '1447_931': 31.0794,
    '1447_932': 31.0578,
    '1447_933': 31.0232,
    '1447_934': 30.9876,
    '1447_935': 30.9513,
    '1447_936': 30.9075,
    '1447_937': 30.8667,
    '1447_938': 30.8205,
    '1447_939': 30.789,
    '1447_940': 30.7695,
    '1447_941': 30.7523,
    '1447_942': 30.7282,
    '1447_943': 30.7205,
    '1447_944': 30.7084,
    '1447_945': 30.6835,
    '1447_946': 30.6547,
    '1447_947': 30.6339,
    '1447_948': 30.614,
    '1447_949': 30.5937,
    '1447_950': 30.5803,
    '1447_951': 30.5695,
    '1447_952': 30.5635,
    '1447_953': 30.5589,
    '1447_954': 30.5518,
    '1447_955': 30.5389,
    '1447_956': 30.5255,
    '1447_957': 30.5098,
    '1447_958': 30.4907,
    '1447_959': 30.4665,
    '1447_960': 30.4431,
    '1447_961': 30.4195,
    '1447_962': 30.3947,
    '1447_963': 30.3588,
    '1447_964': 30.306,
    '1447_965': 30.237,
    '1447_966': 30.1674,
    '1447_967': 30.1055,
    '1447_968': 30.0606,
    '1447_969': 30.0299,
    '1447_970': 30,
    '1447_971': 29.9555,
    '1447_972': 29.9178,
    '1447_973': 29.885,
    '1447_974': 29.846,
    '1447_975': 29.7855,
    '1447_976': 29.7235,
    '1447_977': 29.6642,
    '1447_978': 29.6112,
    '1447_979': 29.5744,
    '1447_980': 29.5454,
    '1447_981': 29.5242,
    '1447_982': 29.5108,
    '1447_983': 29.5064,
    '1447_984': 29.505,
    '1447_985': 29.4969,
    '1447_986': 29.4954,
    '1447_987': 29.4958,
    '1447_988': 29.4996,
    '1447_989': 29.5074,
    '1447_990': 29.5203,
    '1447_991': 29.5408,
    '1447_992': 29.5706,
    '1447_993': 29.6074,
    '1447_994': 29.6511,
    '1447_995': 29.7001,
    '1447_996': 29.7566,
    '1447_997': 29.8224,
    '1447_998': 29.8963,
    '1447_999': 29.981,
    '1447_1000': 30.0758,
    '1447_1001': 30.1678,
    '1447_1002': 30.2658,
    '1447_1003': 30.3718,
    '1447_1004': 30.4826,
    '1447_1005': 30.5785,
    '1447_1006': 30.6206,
    '1447_1007': 30.6056,
    '1447_1008': 30.5424,
    '1447_1009': 30.4266,
    '1447_1010': 30.2905,
    '1447_1011': 30.1668,
    '1447_1012': 30.0815,
    '1447_1013': 30.0107,
    '1447_1014': 29.9461,
    '1447_1015': 29.8858,
    '1447_1016': 29.8331,
    '1447_1017': 29.785,
    '1447_1018': 29.7427,
    '1447_1019': 29.7109,
    '1447_1020': 29.6917,
    '1447_1021': 29.6822,
    '1447_1022': 29.6824,
    '1448_844': 30.3102,
    '1448_845': 30.326,
    '1448_846': 30.3491,
    '1448_847': 30.3757,
    '1448_848': 30.4052,
    '1448_849': 30.4375,
    '1448_850': 30.4715,
    '1448_851': 30.5048,
    '1448_852': 30.5359,
    '1448_853': 30.5608,
    '1448_854': 30.5756,
    '1448_855': 30.5809,
    '1448_856': 30.5757,
    '1448_857': 30.5604,
    '1448_858': 30.5383,
    '1448_859': 30.5144,
    '1448_860': 30.4958,
    '1448_861': 30.4816,
    '1448_862': 30.472,
    '1448_863': 30.4694,
    '1448_864': 30.4787,
    '1448_865': 30.5,
    '1448_866': 30.5283,
    '1448_867': 30.5653,
    '1448_868': 30.6008,
    '1448_869': 30.6357,
    '1448_870': 30.6686,
    '1448_871': 30.6963,
    '1448_872': 30.7227,
    '1448_873': 30.7608,
    '1448_874': 30.7901,
    '1448_875': 30.8196,
    '1448_876': 30.8571,
    '1448_877': 30.8992,
    '1448_878': 30.9385,
    '1448_879': 30.981,
    '1448_880': 31.0478,
    '1448_881': 31.1382,
    '1448_882': 31.2338,
    '1448_883': 31.3042,
    '1448_884': 31.3289,
    '1448_885': 31.3402,
    '1448_886': 31.3634,
    '1448_887': 31.3999,
    '1448_888': 31.4246,
    '1448_889': 31.4135,
    '1448_890': 31.4026,
    '1448_891': 31.4029,
    '1448_892': 31.401,
    '1448_893': 31.4024,
    '1448_894': 31.4144,
    '1448_895': 31.4343,
    '1448_896': 31.4607,
    '1448_897': 31.4904,
    '1448_898': 31.5221,
    '1448_899': 31.5522,
    '1448_900': 31.581,
    '1448_901': 31.5995,
    '1448_902': 31.6065,
    '1448_903': 31.6043,
    '1448_904': 31.6033,
    '1448_905': 31.6062,
    '1448_906': 31.6138,
    '1448_907': 31.6301,
    '1448_908': 31.6557,
    '1448_909': 31.683,
    '1448_910': 31.6975,
    '1448_911': 31.6923,
    '1448_912': 31.6779,
    '1448_913': 31.662,
    '1448_914': 31.644,
    '1448_915': 31.6127,
    '1448_916': 31.5696,
    '1448_917': 31.5162,
    '1448_918': 31.4496,
    '1448_919': 31.3868,
    '1448_920': 31.3232,
    '1448_921': 31.2623,
    '1448_922': 31.2165,
    '1448_923': 31.173,
    '1448_924': 31.1419,
    '1448_925': 31.1199,
    '1448_926': 31.0979,
    '1448_927': 31.0676,
    '1448_928': 31.0438,
    '1448_929': 31.0368,
    '1448_930': 31.0331,
    '1448_931': 31.0186,
    '1448_932': 30.9978,
    '1448_933': 30.9672,
    '1448_934': 30.9292,
    '1448_935': 30.8921,
    '1448_936': 30.853,
    '1448_937': 30.8164,
    '1448_938': 30.7749,
    '1448_939': 30.7393,
    '1448_940': 30.7181,
    '1448_941': 30.6942,
    '1448_942': 30.6681,
    '1448_943': 30.6536,
    '1448_944': 30.6436,
    '1448_945': 30.6173,
    '1448_946': 30.5883,
    '1448_947': 30.5636,
    '1448_948': 30.5421,
    '1448_949': 30.5206,
    '1448_950': 30.5028,
    '1448_951': 30.4889,
    '1448_952': 30.4775,
    '1448_953': 30.4664,
    '1448_954': 30.4546,
    '1448_955': 30.4395,
    '1448_956': 30.4272,
    '1448_957': 30.4139,
    '1448_958': 30.3971,
    '1448_959': 30.3785,
    '1448_960': 30.3602,
    '1448_961': 30.3395,
    '1448_962': 30.3132,
    '1448_963': 30.2751,
    '1448_964': 30.2237,
    '1448_965': 30.1608,
    '1448_966': 30.0941,
    '1448_967': 30.0267,
    '1448_968': 29.9774,
    '1448_969': 29.9455,
    '1448_970': 29.9131,
    '1448_971': 29.8761,
    '1448_972': 29.8471,
    '1448_973': 29.8171,
    '1448_974': 29.7773,
    '1448_975': 29.7219,
    '1448_976': 29.6591,
    '1448_977': 29.5934,
    '1448_978': 29.5328,
    '1448_979': 29.4872,
    '1448_980': 29.453,
    '1448_981': 29.4286,
    '1448_982': 29.4145,
    '1448_983': 29.4082,
    '1448_984': 29.41,
    '1448_985': 29.3929,
    '1448_986': 29.3869,
    '1448_987': 29.3859,
    '1448_988': 29.3876,
    '1448_989': 29.3919,
    '1448_990': 29.4041,
    '1448_991': 29.4246,
    '1448_992': 29.4528,
    '1448_993': 29.4873,
    '1448_994': 29.5274,
    '1448_995': 29.5727,
    '1448_996': 29.625,
    '1448_997': 29.6858,
    '1448_998': 29.7557,
    '1448_999': 29.8353,
    '1448_1000': 29.9187,
    '1448_1001': 30.0113,
    '1448_1002': 30.1009,
    '1448_1003': 30.1982,
    '1448_1004': 30.3088,
    '1448_1005': 30.4168,
    '1448_1006': 30.4859,
    '1448_1007': 30.4941,
    '1448_1008': 30.4612,
    '1448_1009': 30.3741,
    '1448_1010': 30.2625,
    '1448_1011': 30.1584,
    '1448_1012': 30.0563,
    '1448_1013': 29.9755,
    '1448_1014': 29.902,
    '1448_1015': 29.8332,
    '1448_1016': 29.7725,
    '1448_1017': 29.7166,
    '1448_1018': 29.6664,
    '1448_1019': 29.6264,
    '1448_1020': 29.6029,
    '1448_1021': 29.5892,
    '1448_1022': 29.5858,
    '1449_844': 30.2832,
    '1449_845': 30.3018,
    '1449_846': 30.3263,
    '1449_847': 30.3543,
    '1449_848': 30.3853,
    '1449_849': 30.4182,
    '1449_850': 30.4535,
    '1449_851': 30.487,
    '1449_852': 30.5179,
    '1449_853': 30.5391,
    '1449_854': 30.5522,
    '1449_855': 30.5563,
    '1449_856': 30.5508,
    '1449_857': 30.5321,
    '1449_858': 30.5083,
    '1449_859': 30.4839,
    '1449_860': 30.4641,
    '1449_861': 30.4488,
    '1449_862': 30.4385,
    '1449_863': 30.4352,
    '1449_864': 30.4424,
    '1449_865': 30.4623,
    '1449_866': 30.488,
    '1449_867': 30.52,
    '1449_868': 30.5526,
    '1449_869': 30.5864,
    '1449_870': 30.6198,
    '1449_871': 30.6478,
    '1449_872': 30.6742,
    '1449_873': 30.7114,
    '1449_874': 30.7398,
    '1449_875': 30.7746,
    '1449_876': 30.817,
    '1449_877': 30.8602,
    '1449_878': 30.9006,
    '1449_879': 30.9433,
    '1449_880': 31.01,
    '1449_881': 31.0966,
    '1449_882': 31.1878,
    '1449_883': 31.2545,
    '1449_884': 31.2766,
    '1449_885': 31.2863,
    '1449_886': 31.3079,
    '1449_887': 31.3463,
    '1449_888': 31.3705,
    '1449_889': 31.3629,
    '1449_890': 31.3565,
    '1449_891': 31.3609,
    '1449_892': 31.366,
    '1449_893': 31.367,
    '1449_894': 31.3786,
    '1449_895': 31.3951,
    '1449_896': 31.4151,
    '1449_897': 31.4401,
    '1449_898': 31.4718,
    '1449_899': 31.5129,
    '1449_900': 31.5544,
    '1449_901': 31.5734,
    '1449_902': 31.58,
    '1449_903': 31.5804,
    '1449_904': 31.5846,
    '1449_905': 31.5955,
    '1449_906': 31.6036,
    '1449_907': 31.617,
    '1449_908': 31.6394,
    '1449_909': 31.6566,
    '1449_910': 31.6679,
    '1449_911': 31.6651,
    '1449_912': 31.6505,
    '1449_913': 31.6303,
    '1449_914': 31.6067,
    '1449_915': 31.5706,
    '1449_916': 31.5197,
    '1449_917': 31.4572,
    '1449_918': 31.3896,
    '1449_919': 31.3317,
    '1449_920': 31.2698,
    '1449_921': 31.2036,
    '1449_922': 31.1546,
    '1449_923': 31.1132,
    '1449_924': 31.0828,
    '1449_925': 31.0569,
    '1449_926': 31.0361,
    '1449_927': 31.0092,
    '1449_928': 30.9878,
    '1449_929': 30.9816,
    '1449_930': 30.9777,
    '1449_931': 30.9604,
    '1449_932': 30.9362,
    '1449_933': 30.9054,
    '1449_934': 30.8699,
    '1449_935': 30.8316,
    '1449_936': 30.7963,
    '1449_937': 30.7641,
    '1449_938': 30.7275,
    '1449_939': 30.6933,
    '1449_940': 30.6678,
    '1449_941': 30.6371,
    '1449_942': 30.607,
    '1449_943': 30.5871,
    '1449_944': 30.5726,
    '1449_945': 30.5471,
    '1449_946': 30.5175,
    '1449_947': 30.488,
    '1449_948': 30.4637,
    '1449_949': 30.4417,
    '1449_950': 30.4235,
    '1449_951': 30.4091,
    '1449_952': 30.3984,
    '1449_953': 30.3865,
    '1449_954': 30.3714,
    '1449_955': 30.3525,
    '1449_956': 30.3382,
    '1449_957': 30.327,
    '1449_958': 30.3131,
    '1449_959': 30.2987,
    '1449_960': 30.2831,
    '1449_961': 30.2635,
    '1449_962': 30.2355,
    '1449_963': 30.1897,
    '1449_964': 30.138,
    '1449_965': 30.0754,
    '1449_966': 30.0088,
    '1449_967': 29.946,
    '1449_968': 29.8931,
    '1449_969': 29.8508,
    '1449_970': 29.8181,
    '1449_971': 29.7953,
    '1449_972': 29.7765,
    '1449_973': 29.751,
    '1449_974': 29.7121,
    '1449_975': 29.6598,
    '1449_976': 29.5977,
    '1449_977': 29.5258,
    '1449_978': 29.4559,
    '1449_979': 29.4024,
    '1449_980': 29.3625,
    '1449_981': 29.3339,
    '1449_982': 29.3192,
    '1449_983': 29.3105,
    '1449_984': 29.3019,
    '1449_985': 29.2873,
    '1449_986': 29.2799,
    '1449_987': 29.2778,
    '1449_988': 29.278,
    '1449_989': 29.2773,
    '1449_990': 29.2895,
    '1449_991': 29.3101,
    '1449_992': 29.337,
    '1449_993': 29.3692,
    '1449_994': 29.4063,
    '1449_995': 29.448,
    '1449_996': 29.4965,
    '1449_997': 29.5538,
    '1449_998': 29.6201,
    '1449_999': 29.6982,
    '1449_1000': 29.7753,
    '1449_1001': 29.8544,
    '1449_1002': 29.9424,
    '1449_1003': 30.0277,
    '1449_1004': 30.1222,
    '1449_1005': 30.2304,
    '1449_1006': 30.3213,
    '1449_1007': 30.3616,
    '1449_1008': 30.3495,
    '1449_1009': 30.2948,
    '1449_1010': 30.2197,
    '1449_1011': 30.1456,
    '1449_1012': 30.0526,
    '1449_1013': 29.9496,
    '1449_1014': 29.8634,
    '1449_1015': 29.7847,
    '1449_1016': 29.7158,
    '1449_1017': 29.6529,
    '1449_1018': 29.5969,
    '1449_1019': 29.5521,
    '1449_1020': 29.521,
    '1449_1021': 29.501,
    '1449_1022': 29.4923,
    '1450_844': 30.2567,
    '1450_845': 30.2772,
    '1450_846': 30.3035,
    '1450_847': 30.3339,
    '1450_848': 30.3682,
    '1450_849': 30.4039,
    '1450_850': 30.4409,
    '1450_851': 30.4749,
    '1450_852': 30.5043,
    '1450_853': 30.5214,
    '1450_854': 30.5306,
    '1450_855': 30.5315,
    '1450_856': 30.5225,
    '1450_857': 30.5029,
    '1450_858': 30.4778,
    '1450_859': 30.4545,
    '1450_860': 30.4348,
    '1450_861': 30.4188,
    '1450_862': 30.4072,
    '1450_863': 30.4022,
    '1450_864': 30.4065,
    '1450_865': 30.4243,
    '1450_866': 30.4476,
    '1450_867': 30.4769,
    '1450_868': 30.5059,
    '1450_869': 30.5378,
    '1450_870': 30.568,
    '1450_871': 30.5932,
    '1450_872': 30.6198,
    '1450_873': 30.657,
    '1450_874': 30.6921,
    '1450_875': 30.7331,
    '1450_876': 30.7741,
    '1450_877': 30.811,
    '1450_878': 30.8528,
    '1450_879': 30.8998,
    '1450_880': 30.9658,
    '1450_881': 31.049,
    '1450_882': 31.1371,
    '1450_883': 31.2026,
    '1450_884': 31.2278,
    '1450_885': 31.236,
    '1450_886': 31.2583,
    '1450_887': 31.2928,
    '1450_888': 31.3131,
    '1450_889': 31.3103,
    '1450_890': 31.3086,
    '1450_891': 31.3167,
    '1450_892': 31.3263,
    '1450_893': 31.331,
    '1450_894': 31.343,
    '1450_895': 31.3552,
    '1450_896': 31.3722,
    '1450_897': 31.3948,
    '1450_898': 31.4278,
    '1450_899': 31.4756,
    '1450_900': 31.5204,
    '1450_901': 31.5422,
    '1450_902': 31.5519,
    '1450_903': 31.5603,
    '1450_904': 31.5676,
    '1450_905': 31.5801,
    '1450_906': 31.5884,
    '1450_907': 31.5988,
    '1450_908': 31.6164,
    '1450_909': 31.6264,
    '1450_910': 31.6337,
    '1450_911': 31.6313,
    '1450_912': 31.6138,
    '1450_913': 31.5897,
    '1450_914': 31.5679,
    '1450_915': 31.5323,
    '1450_916': 31.4751,
    '1450_917': 31.404,
    '1450_918': 31.3334,
    '1450_919': 31.2725,
    '1450_920': 31.2126,
    '1450_921': 31.1493,
    '1450_922': 31.0979,
    '1450_923': 31.0608,
    '1450_924': 31.0323,
    '1450_925': 31.0025,
    '1450_926': 30.9793,
    '1450_927': 30.9556,
    '1450_928': 30.9319,
    '1450_929': 30.9234,
    '1450_930': 30.9171,
    '1450_931': 30.9021,
    '1450_932': 30.8759,
    '1450_933': 30.8443,
    '1450_934': 30.8099,
    '1450_935': 30.774,
    '1450_936': 30.7413,
    '1450_937': 30.7093,
    '1450_938': 30.6716,
    '1450_939': 30.6379,
    '1450_940': 30.6151,
    '1450_941': 30.583,
    '1450_942': 30.5482,
    '1450_943': 30.5168,
    '1450_944': 30.495,
    '1450_945': 30.4698,
    '1450_946': 30.4381,
    '1450_947': 30.4073,
    '1450_948': 30.3806,
    '1450_949': 30.3592,
    '1450_950': 30.3422,
    '1450_951': 30.3275,
    '1450_952': 30.3194,
    '1450_953': 30.3091,
    '1450_954': 30.2936,
    '1450_955': 30.2717,
    '1450_956': 30.2551,
    '1450_957': 30.2443,
    '1450_958': 30.2338,
    '1450_959': 30.2154,
    '1450_960': 30.2017,
    '1450_961': 30.1828,
    '1450_962': 30.155,
    '1450_963': 30.1163,
    '1450_964': 30.065,
    '1450_965': 29.9972,
    '1450_966': 29.9267,
    '1450_967': 29.8624,
    '1450_968': 29.8078,
    '1450_969': 29.7625,
    '1450_970': 29.7315,
    '1450_971': 29.7205,
    '1450_972': 29.7106,
    '1450_973': 29.6891,
    '1450_974': 29.6497,
    '1450_975': 29.5981,
    '1450_976': 29.5351,
    '1450_977': 29.4632,
    '1450_978': 29.3904,
    '1450_979': 29.3248,
    '1450_980': 29.2792,
    '1450_981': 29.2482,
    '1450_982': 29.2297,
    '1450_983': 29.2155,
    '1450_984': 29.1988,
    '1450_985': 29.1825,
    '1450_986': 29.1753,
    '1450_987': 29.1723,
    '1450_988': 29.1707,
    '1450_989': 29.1714,
    '1450_990': 29.1806,
    '1450_991': 29.1993,
    '1450_992': 29.2243,
    '1450_993': 29.254,
    '1450_994': 29.2881,
    '1450_995': 29.3268,
    '1450_996': 29.3721,
    '1450_997': 29.4274,
    '1450_998': 29.491,
    '1450_999': 29.563,
    '1450_1000': 29.636,
    '1450_1001': 29.7091,
    '1450_1002': 29.7806,
    '1450_1003': 29.8641,
    '1450_1004': 29.9444,
    '1450_1005': 30.0327,
    '1450_1006': 30.1266,
    '1450_1007': 30.1874,
    '1450_1008': 30.2053,
    '1450_1009': 30.1898,
    '1450_1010': 30.164,
    '1450_1011': 30.1239,
    '1450_1012': 30.042,
    '1450_1013': 29.9412,
    '1450_1014': 29.8281,
    '1450_1015': 29.7376,
    '1450_1016': 29.6601,
    '1450_1017': 29.5902,
    '1450_1018': 29.5283,
    '1450_1019': 29.478,
    '1450_1020': 29.4398,
    '1450_1021': 29.4133,
    '1450_1022': 29.3994,
    '1451_844': 30.2307,
    '1451_845': 30.2524,
    '1451_846': 30.2807,
    '1451_847': 30.3135,
    '1451_848': 30.352,
    '1451_849': 30.3899,
    '1451_850': 30.4289,
    '1451_851': 30.4592,
    '1451_852': 30.4818,
    '1451_853': 30.5011,
    '1451_854': 30.5099,
    '1451_855': 30.5091,
    '1451_856': 30.498,
    '1451_857': 30.4773,
    '1451_858': 30.4521,
    '1451_859': 30.4274,
    '1451_860': 30.4074,
    '1451_861': 30.3904,
    '1451_862': 30.3771,
    '1451_863': 30.3704,
    '1451_864': 30.3726,
    '1451_865': 30.3862,
    '1451_866': 30.4052,
    '1451_867': 30.4311,
    '1451_868': 30.4539,
    '1451_869': 30.4823,
    '1451_870': 30.5084,
    '1451_871': 30.5338,
    '1451_872': 30.5673,
    '1451_873': 30.6084,
    '1451_874': 30.6484,
    '1451_875': 30.6867,
    '1451_876': 30.7216,
    '1451_877': 30.7551,
    '1451_878': 30.7991,
    '1451_879': 30.8514,
    '1451_880': 30.9178,
    '1451_881': 30.9953,
    '1451_882': 31.077,
    '1451_883': 31.1444,
    '1451_884': 31.1774,
    '1451_885': 31.1876,
    '1451_886': 31.2111,
    '1451_887': 31.2411,
    '1451_888': 31.2581,
    '1451_889': 31.258,
    '1451_890': 31.2623,
    '1451_891': 31.2727,
    '1451_892': 31.2842,
    '1451_893': 31.2934,
    '1451_894': 31.3028,
    '1451_895': 31.3115,
    '1451_896': 31.3289,
    '1451_897': 31.3523,
    '1451_898': 31.3856,
    '1451_899': 31.4326,
    '1451_900': 31.4755,
    '1451_901': 31.4998,
    '1451_902': 31.509,
    '1451_903': 31.5186,
    '1451_904': 31.5287,
    '1451_905': 31.5437,
    '1451_906': 31.5551,
    '1451_907': 31.5653,
    '1451_908': 31.5831,
    '1451_909': 31.5926,
    '1451_910': 31.596,
    '1451_911': 31.5866,
    '1451_912': 31.5613,
    '1451_913': 31.5393,
    '1451_914': 31.5251,
    '1451_915': 31.4927,
    '1451_916': 31.4328,
    '1451_917': 31.3556,
    '1451_918': 31.281,
    '1451_919': 31.2127,
    '1451_920': 31.1558,
    '1451_921': 31.0994,
    '1451_922': 31.0494,
    '1451_923': 31.0136,
    '1451_924': 30.9842,
    '1451_925': 30.9518,
    '1451_926': 30.9244,
    '1451_927': 30.9022,
    '1451_928': 30.8786,
    '1451_929': 30.8626,
    '1451_930': 30.8545,
    '1451_931': 30.8431,
    '1451_932': 30.8208,
    '1451_933': 30.7855,
    '1451_934': 30.7481,
    '1451_935': 30.7159,
    '1451_936': 30.6856,
    '1451_937': 30.6582,
    '1451_938': 30.6193,
    '1451_939': 30.5781,
    '1451_940': 30.553,
    '1451_941': 30.5182,
    '1451_942': 30.4821,
    '1451_943': 30.4444,
    '1451_944': 30.414,
    '1451_945': 30.3843,
    '1451_946': 30.351,
    '1451_947': 30.3182,
    '1451_948': 30.2918,
    '1451_949': 30.2723,
    '1451_950': 30.2586,
    '1451_951': 30.2437,
    '1451_952': 30.2306,
    '1451_953': 30.2161,
    '1451_954': 30.2005,
    '1451_955': 30.1827,
    '1451_956': 30.1608,
    '1451_957': 30.1512,
    '1451_958': 30.1447,
    '1451_959': 30.1359,
    '1451_960': 30.1258,
    '1451_961': 30.1078,
    '1451_962': 30.0802,
    '1451_963': 30.0434,
    '1451_964': 29.9939,
    '1451_965': 29.9161,
    '1451_966': 29.8409,
    '1451_967': 29.7752,
    '1451_968': 29.7242,
    '1451_969': 29.6822,
    '1451_970': 29.662,
    '1451_971': 29.6532,
    '1451_972': 29.6448,
    '1451_973': 29.6194,
    '1451_974': 29.5816,
    '1451_975': 29.5343,
    '1451_976': 29.4724,
    '1451_977': 29.3999,
    '1451_978': 29.3249,
    '1451_979': 29.2462,
    '1451_980': 29.1992,
    '1451_981': 29.1659,
    '1451_982': 29.1453,
    '1451_983': 29.1287,
    '1451_984': 29.1061,
    '1451_985': 29.0906,
    '1451_986': 29.0778,
    '1451_987': 29.0718,
    '1451_988': 29.0678,
    '1451_989': 29.0681,
    '1451_990': 29.0746,
    '1451_991': 29.0922,
    '1451_992': 29.1158,
    '1451_993': 29.1431,
    '1451_994': 29.1752,
    '1451_995': 29.2118,
    '1451_996': 29.2537,
    '1451_997': 29.3079,
    '1451_998': 29.3675,
    '1451_999': 29.4337,
    '1451_1000': 29.5004,
    '1451_1001': 29.5655,
    '1451_1002': 29.6291,
    '1451_1003': 29.6965,
    '1451_1004': 29.7765,
    '1451_1005': 29.8509,
    '1451_1006': 29.9278,
    '1451_1007': 29.9974,
    '1451_1008': 30.0453,
    '1451_1009': 30.0751,
    '1451_1010': 30.0981,
    '1451_1011': 30.0895,
    '1451_1012': 30.026,
    '1451_1013': 29.9182,
    '1451_1014': 29.7916,
    '1451_1015': 29.6889,
    '1451_1016': 29.6021,
    '1451_1017': 29.5252,
    '1451_1018': 29.4574,
    '1451_1019': 29.4011,
    '1451_1020': 29.3563,
    '1451_1021': 29.3235,
    '1451_1022': 29.3054,
    '1452_844': 30.2072,
    '1452_845': 30.2295,
    '1452_846': 30.258,
    '1452_847': 30.2949,
    '1452_848': 30.3357,
    '1452_849': 30.3778,
    '1452_850': 30.4169,
    '1452_851': 30.4451,
    '1452_852': 30.4693,
    '1452_853': 30.4855,
    '1452_854': 30.4922,
    '1452_855': 30.4906,
    '1452_856': 30.4773,
    '1452_857': 30.455,
    '1452_858': 30.4295,
    '1452_859': 30.4038,
    '1452_860': 30.3827,
    '1452_861': 30.3634,
    '1452_862': 30.3477,
    '1452_863': 30.3382,
    '1452_864': 30.3379,
    '1452_865': 30.3473,
    '1452_866': 30.3616,
    '1452_867': 30.3831,
    '1452_868': 30.4022,
    '1452_869': 30.4233,
    '1452_870': 30.4478,
    '1452_871': 30.4756,
    '1452_872': 30.5112,
    '1452_873': 30.5517,
    '1452_874': 30.5902,
    '1452_875': 30.6257,
    '1452_876': 30.6601,
    '1452_877': 30.702,
    '1452_878': 30.7538,
    '1452_879': 30.8116,
    '1452_880': 30.8745,
    '1452_881': 30.9444,
    '1452_882': 31.0191,
    '1452_883': 31.0838,
    '1452_884': 31.1221,
    '1452_885': 31.1362,
    '1452_886': 31.1602,
    '1452_887': 31.1856,
    '1452_888': 31.202,
    '1452_889': 31.2088,
    '1452_890': 31.2212,
    '1452_891': 31.234,
    '1452_892': 31.2475,
    '1452_893': 31.2572,
    '1452_894': 31.2622,
    '1452_895': 31.2684,
    '1452_896': 31.2858,
    '1452_897': 31.3092,
    '1452_898': 31.3406,
    '1452_899': 31.3812,
    '1452_900': 31.415,
    '1452_901': 31.4318,
    '1452_902': 31.4362,
    '1452_903': 31.4454,
    '1452_904': 31.461,
    '1452_905': 31.4833,
    '1452_906': 31.5025,
    '1452_907': 31.5194,
    '1452_908': 31.5374,
    '1452_909': 31.5491,
    '1452_910': 31.5512,
    '1452_911': 31.5373,
    '1452_912': 31.5114,
    '1452_913': 31.4923,
    '1452_914': 31.4824,
    '1452_915': 31.4499,
    '1452_916': 31.3864,
    '1452_917': 31.3034,
    '1452_918': 31.2238,
    '1452_919': 31.155,
    '1452_920': 31.0988,
    '1452_921': 31.0488,
    '1452_922': 31.0083,
    '1452_923': 30.9803,
    '1452_924': 30.9488,
    '1452_925': 30.9132,
    '1452_926': 30.8784,
    '1452_927': 30.8496,
    '1452_928': 30.8257,
    '1452_929': 30.8041,
    '1452_930': 30.7931,
    '1452_931': 30.7818,
    '1452_932': 30.7613,
    '1452_933': 30.7273,
    '1452_934': 30.6859,
    '1452_935': 30.6586,
    '1452_936': 30.6319,
    '1452_937': 30.6017,
    '1452_938': 30.5642,
    '1452_939': 30.5159,
    '1452_940': 30.4754,
    '1452_941': 30.4425,
    '1452_942': 30.4033,
    '1452_943': 30.3642,
    '1452_944': 30.3287,
    '1452_945': 30.2931,
    '1452_946': 30.2565,
    '1452_947': 30.2233,
    '1452_948': 30.1972,
    '1452_949': 30.1789,
    '1452_950': 30.1638,
    '1452_951': 30.1481,
    '1452_952': 30.1321,
    '1452_953': 30.1089,
    '1452_954': 30.0933,
    '1452_955': 30.0793,
    '1452_956': 30.0666,
    '1452_957': 30.0593,
    '1452_958': 30.0549,
    '1452_959': 30.049,
    '1452_960': 30.04,
    '1452_961': 30.0239,
    '1452_962': 29.9966,
    '1452_963': 29.9572,
    '1452_964': 29.8997,
    '1452_965': 29.8255,
    '1452_966': 29.7503,
    '1452_967': 29.6844,
    '1452_968': 29.641,
    '1452_969': 29.6093,
    '1452_970': 29.5911,
    '1452_971': 29.5801,
    '1452_972': 29.5669,
    '1452_973': 29.5414,
    '1452_974': 29.5015,
    '1452_975': 29.4629,
    '1452_976': 29.4064,
    '1452_977': 29.3286,
    '1452_978': 29.2488,
    '1452_979': 29.1778,
    '1452_980': 29.1275,
    '1452_981': 29.0911,
    '1452_982': 29.064,
    '1452_983': 29.0431,
    '1452_984': 29.0194,
    '1452_985': 28.9994,
    '1452_986': 28.9811,
    '1452_987': 28.972,
    '1452_988': 28.9675,
    '1452_989': 28.9679,
    '1452_990': 28.9747,
    '1452_991': 28.991,
    '1452_992': 29.013,
    '1452_993': 29.0376,
    '1452_994': 29.0699,
    '1452_995': 29.1081,
    '1452_996': 29.1521,
    '1452_997': 29.2032,
    '1452_998': 29.2579,
    '1452_999': 29.3155,
    '1452_1000': 29.373,
    '1452_1001': 29.4292,
    '1452_1002': 29.485,
    '1452_1003': 29.5434,
    '1452_1004': 29.6069,
    '1452_1005': 29.6837,
    '1452_1006': 29.7538,
    '1452_1007': 29.8242,
    '1452_1008': 29.8927,
    '1452_1009': 29.963,
    '1452_1010': 30.0207,
    '1452_1011': 30.0352,
    '1452_1012': 29.9865,
    '1452_1013': 29.8843,
    '1452_1014': 29.7638,
    '1452_1015': 29.6362,
    '1452_1016': 29.5398,
    '1452_1017': 29.4563,
    '1452_1018': 29.3832,
    '1452_1019': 29.3217,
    '1452_1020': 29.2711,
    '1452_1021': 29.2322,
    '1452_1022': 29.2116,
    '1453_844': 30.184,
    '1453_845': 30.208,
    '1453_846': 30.2359,
    '1453_847': 30.278,
    '1453_848': 30.3194,
    '1453_849': 30.3638,
    '1453_850': 30.4027,
    '1453_851': 30.429,
    '1453_852': 30.4582,
    '1453_853': 30.4717,
    '1453_854': 30.4761,
    '1453_855': 30.4759,
    '1453_856': 30.4604,
    '1453_857': 30.4349,
    '1453_858': 30.4084,
    '1453_859': 30.3803,
    '1453_860': 30.3587,
    '1453_861': 30.337,
    '1453_862': 30.3192,
    '1453_863': 30.3078,
    '1453_864': 30.3037,
    '1453_865': 30.3084,
    '1453_866': 30.3176,
    '1453_867': 30.3339,
    '1453_868': 30.3486,
    '1453_869': 30.3649,
    '1453_870': 30.3887,
    '1453_871': 30.4203,
    '1453_872': 30.4521,
    '1453_873': 30.4875,
    '1453_874': 30.5252,
    '1453_875': 30.5635,
    '1453_876': 30.6045,
    '1453_877': 30.6551,
    '1453_878': 30.7104,
    '1453_879': 30.7691,
    '1453_880': 30.8294,
    '1453_881': 30.8975,
    '1453_882': 30.9677,
    '1453_883': 31.0282,
    '1453_884': 31.0689,
    '1453_885': 31.0851,
    '1453_886': 31.1039,
    '1453_887': 31.1285,
    '1453_888': 31.1513,
    '1453_889': 31.1672,
    '1453_890': 31.1829,
    '1453_891': 31.1997,
    '1453_892': 31.2159,
    '1453_893': 31.2247,
    '1453_894': 31.2266,
    '1453_895': 31.2277,
    '1453_896': 31.2433,
    '1453_897': 31.2651,
    '1453_898': 31.2935,
    '1453_899': 31.3263,
    '1453_900': 31.3482,
    '1453_901': 31.35,
    '1453_902': 31.3477,
    '1453_903': 31.3564,
    '1453_904': 31.3755,
    '1453_905': 31.4023,
    '1453_906': 31.4278,
    '1453_907': 31.4578,
    '1453_908': 31.4799,
    '1453_909': 31.4958,
    '1453_910': 31.4995,
    '1453_911': 31.4846,
    '1453_912': 31.4596,
    '1453_913': 31.4453,
    '1453_914': 31.4366,
    '1453_915': 31.4029,
    '1453_916': 31.3359,
    '1453_917': 31.2528,
    '1453_918': 31.1749,
    '1453_919': 31.1102,
    '1453_920': 31.0566,
    '1453_921': 31.0119,
    '1453_922': 30.9827,
    '1453_923': 30.9592,
    '1453_924': 30.9272,
    '1453_925': 30.8882,
    '1453_926': 30.8487,
    '1453_927': 30.8138,
    '1453_928': 30.7821,
    '1453_929': 30.7529,
    '1453_930': 30.7324,
    '1453_931': 30.7159,
    '1453_932': 30.6941,
    '1453_933': 30.6613,
    '1453_934': 30.6228,
    '1453_935': 30.5989,
    '1453_936': 30.5751,
    '1453_937': 30.5428,
    '1453_938': 30.5009,
    '1453_939': 30.4502,
    '1453_940': 30.3969,
    '1453_941': 30.357,
    '1453_942': 30.3169,
    '1453_943': 30.2777,
    '1453_944': 30.2372,
    '1453_945': 30.1949,
    '1453_946': 30.1584,
    '1453_947': 30.1258,
    '1453_948': 30.1019,
    '1453_949': 30.0825,
    '1453_950': 30.0657,
    '1453_951': 30.042,
    '1453_952': 30.0248,
    '1453_953': 30.0083,
    '1453_954': 29.9942,
    '1453_955': 29.9818,
    '1453_956': 29.9713,
    '1453_957': 29.9657,
    '1453_958': 29.9634,
    '1453_959': 29.9586,
    '1453_960': 29.9497,
    '1453_961': 29.9344,
    '1453_962': 29.9055,
    '1453_963': 29.865,
    '1453_964': 29.8019,
    '1453_965': 29.7318,
    '1453_966': 29.663,
    '1453_967': 29.605,
    '1453_968': 29.5641,
    '1453_969': 29.5359,
    '1453_970': 29.5176,
    '1453_971': 29.5044,
    '1453_972': 29.4888,
    '1453_973': 29.4694,
    '1453_974': 29.4378,
    '1453_975': 29.3985,
    '1453_976': 29.3438,
    '1453_977': 29.2554,
    '1453_978': 29.1715,
    '1453_979': 29.1073,
    '1453_980': 29.0575,
    '1453_981': 29.019,
    '1453_982': 28.986,
    '1453_983': 28.9574,
    '1453_984': 28.9352,
    '1453_985': 28.9157,
    '1453_986': 28.8953,
    '1453_987': 28.8815,
    '1453_988': 28.8743,
    '1453_989': 28.8746,
    '1453_990': 28.8809,
    '1453_991': 28.8965,
    '1453_992': 28.9166,
    '1453_993': 28.9393,
    '1453_994': 28.9738,
    '1453_995': 29.0148,
    '1453_996': 29.0608,
    '1453_997': 29.11,
    '1453_998': 29.1593,
    '1453_999': 29.2083,
    '1453_1000': 29.256,
    '1453_1001': 29.3027,
    '1453_1002': 29.35,
    '1453_1003': 29.4005,
    '1453_1004': 29.4561,
    '1453_1005': 29.5175,
    '1453_1006': 29.5862,
    '1453_1007': 29.6729,
    '1453_1008': 29.7559,
    '1453_1009': 29.8559,
    '1453_1010': 29.9377,
    '1453_1011': 29.9697,
    '1453_1012': 29.9381,
    '1453_1013': 29.8481,
    '1453_1014': 29.7197,
    '1453_1015': 29.5783,
    '1453_1016': 29.4735,
    '1453_1017': 29.3846,
    '1453_1018': 29.3072,
    '1453_1019': 29.2425,
    '1453_1020': 29.1896,
    '1453_1021': 29.1492,
    '1453_1022': 29.1238,
    '1454_844': 30.1584,
    '1454_845': 30.1853,
    '1454_846': 30.2205,
    '1454_847': 30.2638,
    '1454_848': 30.3028,
    '1454_849': 30.3433,
    '1454_850': 30.3809,
    '1454_851': 30.4116,
    '1454_852': 30.4382,
    '1454_853': 30.4522,
    '1454_854': 30.4587,
    '1454_855': 30.457,
    '1454_856': 30.4431,
    '1454_857': 30.4149,
    '1454_858': 30.3835,
    '1454_859': 30.3498,
    '1454_860': 30.3263,
    '1454_861': 30.306,
    '1454_862': 30.2891,
    '1454_863': 30.2759,
    '1454_864': 30.2679,
    '1454_865': 30.2681,
    '1454_866': 30.2726,
    '1454_867': 30.2835,
    '1454_868': 30.2935,
    '1454_869': 30.3069,
    '1454_870': 30.3317,
    '1454_871': 30.3618,
    '1454_872': 30.3956,
    '1454_873': 30.4346,
    '1454_874': 30.4727,
    '1454_875': 30.5134,
    '1454_876': 30.5581,
    '1454_877': 30.6065,
    '1454_878': 30.6598,
    '1454_879': 30.7159,
    '1454_880': 30.7774,
    '1454_881': 30.8482,
    '1454_882': 30.9167,
    '1454_883': 30.975,
    '1454_884': 31.0158,
    '1454_885': 31.0374,
    '1454_886': 31.0539,
    '1454_887': 31.0787,
    '1454_888': 31.1068,
    '1454_889': 31.129,
    '1454_890': 31.1454,
    '1454_891': 31.1685,
    '1454_892': 31.1856,
    '1454_893': 31.1925,
    '1454_894': 31.1894,
    '1454_895': 31.1867,
    '1454_896': 31.1994,
    '1454_897': 31.2182,
    '1454_898': 31.2403,
    '1454_899': 31.2627,
    '1454_900': 31.2738,
    '1454_901': 31.2696,
    '1454_902': 31.2641,
    '1454_903': 31.2688,
    '1454_904': 31.2855,
    '1454_905': 31.3105,
    '1454_906': 31.3438,
    '1454_907': 31.3814,
    '1454_908': 31.4133,
    '1454_909': 31.4356,
    '1454_910': 31.4438,
    '1454_911': 31.4309,
    '1454_912': 31.4078,
    '1454_913': 31.3918,
    '1454_914': 31.3819,
    '1454_915': 31.3465,
    '1454_916': 31.2791,
    '1454_917': 31.198,
    '1454_918': 31.1258,
    '1454_919': 31.0657,
    '1454_920': 31.0207,
    '1454_921': 30.9859,
    '1454_922': 30.9666,
    '1454_923': 30.9443,
    '1454_924': 30.9082,
    '1454_925': 30.8677,
    '1454_926': 30.823,
    '1454_927': 30.7812,
    '1454_928': 30.7445,
    '1454_929': 30.7108,
    '1454_930': 30.6751,
    '1454_931': 30.6504,
    '1454_932': 30.6284,
    '1454_933': 30.5954,
    '1454_934': 30.5586,
    '1454_935': 30.5339,
    '1454_936': 30.5105,
    '1454_937': 30.4806,
    '1454_938': 30.4343,
    '1454_939': 30.3818,
    '1454_940': 30.3242,
    '1454_941': 30.2752,
    '1454_942': 30.231,
    '1454_943': 30.1899,
    '1454_944': 30.1478,
    '1454_945': 30.1041,
    '1454_946': 30.066,
    '1454_947': 30.0341,
    '1454_948': 30.0084,
    '1454_949': 29.9799,
    '1454_950': 29.963,
    '1454_951': 29.9466,
    '1454_952': 29.928,
    '1454_953': 29.9132,
    '1454_954': 29.9001,
    '1454_955': 29.8869,
    '1454_956': 29.8754,
    '1454_957': 29.8715,
    '1454_958': 29.8718,
    '1454_959': 29.8666,
    '1454_960': 29.8551,
    '1454_961': 29.8363,
    '1454_962': 29.802,
    '1454_963': 29.7531,
    '1454_964': 29.6984,
    '1454_965': 29.6361,
    '1454_966': 29.5731,
    '1454_967': 29.5228,
    '1454_968': 29.4854,
    '1454_969': 29.4581,
    '1454_970': 29.4383,
    '1454_971': 29.4221,
    '1454_972': 29.3968,
    '1454_973': 29.3704,
    '1454_974': 29.3357,
    '1454_975': 29.3067,
    '1454_976': 29.2554,
    '1454_977': 29.1809,
    '1454_978': 29.1044,
    '1454_979': 29.0394,
    '1454_980': 28.9864,
    '1454_981': 28.9439,
    '1454_982': 28.9085,
    '1454_983': 28.8824,
    '1454_984': 28.8556,
    '1454_985': 28.8322,
    '1454_986': 28.8115,
    '1454_987': 28.7967,
    '1454_988': 28.7887,
    '1454_989': 28.7886,
    '1454_990': 28.7971,
    '1454_991': 28.8113,
    '1454_992': 28.8307,
    '1454_993': 28.855,
    '1454_994': 28.8884,
    '1454_995': 28.9295,
    '1454_996': 28.9766,
    '1454_997': 29.0245,
    '1454_998': 29.068,
    '1454_999': 29.1089,
    '1454_1000': 29.1467,
    '1454_1001': 29.1836,
    '1454_1002': 29.222,
    '1454_1003': 29.2648,
    '1454_1004': 29.3146,
    '1454_1005': 29.3714,
    '1454_1006': 29.4389,
    '1454_1007': 29.5283,
    '1454_1008': 29.6177,
    '1454_1009': 29.7279,
    '1454_1010': 29.8285,
    '1454_1011': 29.8739,
    '1454_1012': 29.8653,
    '1454_1013': 29.7919,
    '1454_1014': 29.6625,
    '1454_1015': 29.5123,
    '1454_1016': 29.4004,
    '1454_1017': 29.3068,
    '1454_1018': 29.2267,
    '1454_1019': 29.1601,
    '1454_1020': 29.1061,
    '1454_1021': 29.0644,
    '1454_1022': 29.0359,
    '1455_844': 30.1314,
    '1455_845': 30.1601,
    '1455_846': 30.1975,
    '1455_847': 30.2363,
    '1455_848': 30.2783,
    '1455_849': 30.3193,
    '1455_850': 30.358,
    '1455_851': 30.392,
    '1455_852': 30.4216,
    '1455_853': 30.4386,
    '1455_854': 30.444,
    '1455_855': 30.4397,
    '1455_856': 30.4238,
    '1455_857': 30.3948,
    '1455_858': 30.364,
    '1455_859': 30.3263,
    '1455_860': 30.2973,
    '1455_861': 30.2741,
    '1455_862': 30.2564,
    '1455_863': 30.2408,
    '1455_864': 30.2296,
    '1455_865': 30.2254,
    '1455_866': 30.2244,
    '1455_867': 30.2303,
    '1455_868': 30.2386,
    '1455_869': 30.2501,
    '1455_870': 30.2725,
    '1455_871': 30.3053,
    '1455_872': 30.3442,
    '1455_873': 30.382,
    '1455_874': 30.4232,
    '1455_875': 30.4705,
    '1455_876': 30.5151,
    '1455_877': 30.5584,
    '1455_878': 30.6065,
    '1455_879': 30.6644,
    '1455_880': 30.7305,
    '1455_881': 30.8001,
    '1455_882': 30.8679,
    '1455_883': 30.9244,
    '1455_884': 30.9641,
    '1455_885': 30.9876,
    '1455_886': 31.0083,
    '1455_887': 31.0366,
    '1455_888': 31.066,
    '1455_889': 31.0893,
    '1455_890': 31.1074,
    '1455_891': 31.1317,
    '1455_892': 31.1515,
    '1455_893': 31.1569,
    '1455_894': 31.148,
    '1455_895': 31.1442,
    '1455_896': 31.154,
    '1455_897': 31.169,
    '1455_898': 31.1836,
    '1455_899': 31.1971,
    '1455_900': 31.2028,
    '1455_901': 31.1991,
    '1455_902': 31.1927,
    '1455_903': 31.1942,
    '1455_904': 31.2035,
    '1455_905': 31.2188,
    '1455_906': 31.2507,
    '1455_907': 31.2941,
    '1455_908': 31.3375,
    '1455_909': 31.3728,
    '1455_910': 31.3884,
    '1455_911': 31.378,
    '1455_912': 31.3526,
    '1455_913': 31.3352,
    '1455_914': 31.3193,
    '1455_915': 31.2784,
    '1455_916': 31.2117,
    '1455_917': 31.1388,
    '1455_918': 31.0704,
    '1455_919': 31.0174,
    '1455_920': 30.9851,
    '1455_921': 30.963,
    '1455_922': 30.9507,
    '1455_923': 30.9291,
    '1455_924': 30.8887,
    '1455_925': 30.8426,
    '1455_926': 30.7911,
    '1455_927': 30.7459,
    '1455_928': 30.7084,
    '1455_929': 30.6678,
    '1455_930': 30.6251,
    '1455_931': 30.5918,
    '1455_932': 30.5695,
    '1455_933': 30.5366,
    '1455_934': 30.5005,
    '1455_935': 30.4721,
    '1455_936': 30.4476,
    '1455_937': 30.4174,
    '1455_938': 30.3679,
    '1455_939': 30.3117,
    '1455_940': 30.2588,
    '1455_941': 30.2095,
    '1455_942': 30.1593,
    '1455_943': 30.1088,
    '1455_944': 30.0633,
    '1455_945': 30.0198,
    '1455_946': 29.9806,
    '1455_947': 29.9406,
    '1455_948': 29.9132,
    '1455_949': 29.8904,
    '1455_950': 29.8763,
    '1455_951': 29.8624,
    '1455_952': 29.8398,
    '1455_953': 29.8269,
    '1455_954': 29.8147,
    '1455_955': 29.7991,
    '1455_956': 29.7837,
    '1455_957': 29.7806,
    '1455_958': 29.7848,
    '1455_959': 29.7788,
    '1455_960': 29.7659,
    '1455_961': 29.7482,
    '1455_962': 29.7133,
    '1455_963': 29.6643,
    '1455_964': 29.6111,
    '1455_965': 29.5505,
    '1455_966': 29.4868,
    '1455_967': 29.4421,
    '1455_968': 29.4068,
    '1455_969': 29.3778,
    '1455_970': 29.3525,
    '1455_971': 29.3273,
    '1455_972': 29.3035,
    '1455_973': 29.286,
    '1455_974': 29.2627,
    '1455_975': 29.2333,
    '1455_976': 29.1779,
    '1455_977': 29.1093,
    '1455_978': 29.0367,
    '1455_979': 28.9708,
    '1455_980': 28.9155,
    '1455_981': 28.8726,
    '1455_982': 28.8392,
    '1455_983': 28.8157,
    '1455_984': 28.7804,
    '1455_985': 28.7532,
    '1455_986': 28.7314,
    '1455_987': 28.7164,
    '1455_988': 28.7079,
    '1455_989': 28.7063,
    '1455_990': 28.717,
    '1455_991': 28.7302,
    '1455_992': 28.75,
    '1455_993': 28.7756,
    '1455_994': 28.8087,
    '1455_995': 28.849,
    '1455_996': 28.8956,
    '1455_997': 28.9424,
    '1455_998': 28.9785,
    '1455_999': 29.0122,
    '1455_1000': 29.041,
    '1455_1001': 29.0695,
    '1455_1002': 29.0996,
    '1455_1003': 29.1343,
    '1455_1004': 29.1793,
    '1455_1005': 29.2325,
    '1455_1006': 29.296,
    '1455_1007': 29.3711,
    '1455_1008': 29.4698,
    '1455_1009': 29.5724,
    '1455_1010': 29.6803,
    '1455_1011': 29.7469,
    '1455_1012': 29.7559,
    '1455_1013': 29.7047,
    '1455_1014': 29.5883,
    '1455_1015': 29.4494,
    '1455_1016': 29.3178,
    '1455_1017': 29.2202,
    '1455_1018': 29.1386,
    '1455_1019': 29.0713,
    '1455_1020': 29.0166,
    '1455_1021': 28.9738,
    '1455_1022': 28.9437,
    '1456_844': 30.1091,
    '1456_845': 30.1389,
    '1456_846': 30.1757,
    '1456_847': 30.2151,
    '1456_848': 30.2566,
    '1456_849': 30.2972,
    '1456_850': 30.3358,
    '1456_851': 30.3719,
    '1456_852': 30.4017,
    '1456_853': 30.4225,
    '1456_854': 30.4323,
    '1456_855': 30.4297,
    '1456_856': 30.4109,
    '1456_857': 30.3825,
    '1456_858': 30.3485,
    '1456_859': 30.3094,
    '1456_860': 30.2728,
    '1456_861': 30.2406,
    '1456_862': 30.2161,
    '1456_863': 30.202,
    '1456_864': 30.1895,
    '1456_865': 30.182,
    '1456_866': 30.1763,
    '1456_867': 30.1761,
    '1456_868': 30.1811,
    '1456_869': 30.1925,
    '1456_870': 30.2154,
    '1456_871': 30.2493,
    '1456_872': 30.2897,
    '1456_873': 30.3308,
    '1456_874': 30.3747,
    '1456_875': 30.4234,
    '1456_876': 30.4646,
    '1456_877': 30.5045,
    '1456_878': 30.5529,
    '1456_879': 30.6137,
    '1456_880': 30.6841,
    '1456_881': 30.7582,
    '1456_882': 30.8235,
    '1456_883': 30.8769,
    '1456_884': 30.9168,
    '1456_885': 30.9423,
    '1456_886': 30.9661,
    '1456_887': 30.9938,
    '1456_888': 31.0237,
    '1456_889': 31.0505,
    '1456_890': 31.0719,
    '1456_891': 31.0947,
    '1456_892': 31.112,
    '1456_893': 31.1144,
    '1456_894': 31.1043,
    '1456_895': 31.1019,
    '1456_896': 31.1094,
    '1456_897': 31.1213,
    '1456_898': 31.1311,
    '1456_899': 31.1383,
    '1456_900': 31.1424,
    '1456_901': 31.1378,
    '1456_902': 31.1304,
    '1456_903': 31.1287,
    '1456_904': 31.132,
    '1456_905': 31.1396,
    '1456_906': 31.1674,
    '1456_907': 31.2095,
    '1456_908': 31.2611,
    '1456_909': 31.3069,
    '1456_910': 31.3295,
    '1456_911': 31.322,
    '1456_912': 31.2946,
    '1456_913': 31.2759,
    '1456_914': 31.2518,
    '1456_915': 31.2019,
    '1456_916': 31.1378,
    '1456_917': 31.0722,
    '1456_918': 31.0124,
    '1456_919': 30.9668,
    '1456_920': 30.9454,
    '1456_921': 30.9334,
    '1456_922': 30.9227,
    '1456_923': 30.9036,
    '1456_924': 30.8659,
    '1456_925': 30.8123,
    '1456_926': 30.7547,
    '1456_927': 30.7056,
    '1456_928': 30.6684,
    '1456_929': 30.6294,
    '1456_930': 30.5898,
    '1456_931': 30.5453,
    '1456_932': 30.515,
    '1456_933': 30.4826,
    '1456_934': 30.4476,
    '1456_935': 30.416,
    '1456_936': 30.3861,
    '1456_937': 30.3457,
    '1456_938': 30.293,
    '1456_939': 30.2366,
    '1456_940': 30.1845,
    '1456_941': 30.1375,
    '1456_942': 30.084,
    '1456_943': 30.0301,
    '1456_944': 29.9813,
    '1456_945': 29.931,
    '1456_946': 29.8924,
    '1456_947': 29.8605,
    '1456_948': 29.8355,
    '1456_949': 29.8157,
    '1456_950': 29.801,
    '1456_951': 29.7869,
    '1456_952': 29.7699,
    '1456_953': 29.7552,
    '1456_954': 29.7409,
    '1456_955': 29.725,
    '1456_956': 29.7102,
    '1456_957': 29.7022,
    '1456_958': 29.6999,
    '1456_959': 29.698,
    '1456_960': 29.691,
    '1456_961': 29.6729,
    '1456_962': 29.6417,
    '1456_963': 29.5982,
    '1456_964': 29.5432,
    '1456_965': 29.4831,
    '1456_966': 29.4229,
    '1456_967': 29.3748,
    '1456_968': 29.3358,
    '1456_969': 29.3048,
    '1456_970': 29.2792,
    '1456_971': 29.2574,
    '1456_972': 29.2389,
    '1456_973': 29.2227,
    '1456_974': 29.2013,
    '1456_975': 29.1689,
    '1456_976': 29.1147,
    '1456_977': 29.0446,
    '1456_978': 28.9699,
    '1456_979': 28.9022,
    '1456_980': 28.8465,
    '1456_981': 28.8041,
    '1456_982': 28.7711,
    '1456_983': 28.7426,
    '1456_984': 28.71,
    '1456_985': 28.6807,
    '1456_986': 28.6587,
    '1456_987': 28.6438,
    '1456_988': 28.6356,
    '1456_989': 28.6308,
    '1456_990': 28.6333,
    '1456_991': 28.6489,
    '1456_992': 28.6708,
    '1456_993': 28.6998,
    '1456_994': 28.7368,
    '1456_995': 28.7752,
    '1456_996': 28.8145,
    '1456_997': 28.8523,
    '1456_998': 28.8832,
    '1456_999': 28.9104,
    '1456_1000': 28.9364,
    '1456_1001': 28.9611,
    '1456_1002': 28.9868,
    '1456_1003': 29.0159,
    '1456_1004': 29.0525,
    '1456_1005': 29.0999,
    '1456_1006': 29.1576,
    '1456_1007': 29.225,
    '1456_1008': 29.3156,
    '1456_1009': 29.404,
    '1456_1010': 29.4981,
    '1456_1011': 29.5798,
    '1456_1012': 29.6138,
    '1456_1013': 29.588,
    '1456_1014': 29.492,
    '1456_1015': 29.3542,
    '1456_1016': 29.2212,
    '1456_1017': 29.1236,
    '1456_1018': 29.0422,
    '1456_1019': 28.9751,
    '1456_1020': 28.9202,
    '1456_1021': 28.8771,
    '1456_1022': 28.8464,
    '1457_844': 30.0897,
    '1457_845': 30.1199,
    '1457_846': 30.1567,
    '1457_847': 30.1969,
    '1457_848': 30.2381,
    '1457_849': 30.2785,
    '1457_850': 30.3188,
    '1457_851': 30.3558,
    '1457_852': 30.3843,
    '1457_853': 30.4062,
    '1457_854': 30.4162,
    '1457_855': 30.4101,
    '1457_856': 30.3946,
    '1457_857': 30.3697,
    '1457_858': 30.3349,
    '1457_859': 30.2936,
    '1457_860': 30.2512,
    '1457_861': 30.2116,
    '1457_862': 30.1801,
    '1457_863': 30.1646,
    '1457_864': 30.1495,
    '1457_865': 30.1387,
    '1457_866': 30.129,
    '1457_867': 30.1231,
    '1457_868': 30.125,
    '1457_869': 30.1372,
    '1457_870': 30.1616,
    '1457_871': 30.1973,
    '1457_872': 30.2355,
    '1457_873': 30.2781,
    '1457_874': 30.3247,
    '1457_875': 30.3706,
    '1457_876': 30.4093,
    '1457_877': 30.4515,
    '1457_878': 30.5064,
    '1457_879': 30.5694,
    '1457_880': 30.6361,
    '1457_881': 30.7117,
    '1457_882': 30.779,
    '1457_883': 30.8331,
    '1457_884': 30.8735,
    '1457_885': 30.9035,
    '1457_886': 30.9287,
    '1457_887': 30.9561,
    '1457_888': 30.9861,
    '1457_889': 31.0151,
    '1457_890': 31.0411,
    '1457_891': 31.0591,
    '1457_892': 31.0696,
    '1457_893': 31.0695,
    '1457_894': 31.0635,
    '1457_895': 31.0621,
    '1457_896': 31.0682,
    '1457_897': 31.0772,
    '1457_898': 31.0838,
    '1457_899': 31.0884,
    '1457_900': 31.0902,
    '1457_901': 31.0836,
    '1457_902': 31.0747,
    '1457_903': 31.0701,
    '1457_904': 31.0687,
    '1457_905': 31.0728,
    '1457_906': 31.0966,
    '1457_907': 31.1358,
    '1457_908': 31.1837,
    '1457_909': 31.2332,
    '1457_910': 31.2603,
    '1457_911': 31.2556,
    '1457_912': 31.2289,
    '1457_913': 31.2076,
    '1457_914': 31.1786,
    '1457_915': 31.1231,
    '1457_916': 31.0639,
    '1457_917': 31.0061,
    '1457_918': 30.9543,
    '1457_919': 30.9152,
    '1457_920': 30.8962,
    '1457_921': 30.8878,
    '1457_922': 30.8815,
    '1457_923': 30.8624,
    '1457_924': 30.8267,
    '1457_925': 30.7722,
    '1457_926': 30.7114,
    '1457_927': 30.6591,
    '1457_928': 30.6204,
    '1457_929': 30.5867,
    '1457_930': 30.5479,
    '1457_931': 30.4998,
    '1457_932': 30.462,
    '1457_933': 30.4309,
    '1457_934': 30.3946,
    '1457_935': 30.3602,
    '1457_936': 30.3202,
    '1457_937': 30.2718,
    '1457_938': 30.2186,
    '1457_939': 30.164,
    '1457_940': 30.1103,
    '1457_941': 30.0568,
    '1457_942': 30.0015,
    '1457_943': 29.9413,
    '1457_944': 29.8927,
    '1457_945': 29.8505,
    '1457_946': 29.8148,
    '1457_947': 29.787,
    '1457_948': 29.7654,
    '1457_949': 29.7482,
    '1457_950': 29.7343,
    '1457_951': 29.7217,
    '1457_952': 29.7078,
    '1457_953': 29.6935,
    '1457_954': 29.6777,
    '1457_955': 29.6605,
    '1457_956': 29.6443,
    '1457_957': 29.6307,
    '1457_958': 29.6204,
    '1457_959': 29.622,
    '1457_960': 29.6211,
    '1457_961': 29.6023,
    '1457_962': 29.5733,
    '1457_963': 29.5325,
    '1457_964': 29.4804,
    '1457_965': 29.4238,
    '1457_966': 29.3653,
    '1457_967': 29.3156,
    '1457_968': 29.2723,
    '1457_969': 29.2398,
    '1457_970': 29.2148,
    '1457_971': 29.198,
    '1457_972': 29.1833,
    '1457_973': 29.1678,
    '1457_974': 29.1458,
    '1457_975': 29.1137,
    '1457_976': 29.0594,
    '1457_977': 28.9847,
    '1457_978': 28.905,
    '1457_979': 28.8353,
    '1457_980': 28.7788,
    '1457_981': 28.7373,
    '1457_982': 28.7048,
    '1457_983': 28.6748,
    '1457_984': 28.6435,
    '1457_985': 28.6126,
    '1457_986': 28.5917,
    '1457_987': 28.5779,
    '1457_988': 28.5729,
    '1457_989': 28.5676,
    '1457_990': 28.5687,
    '1457_991': 28.5779,
    '1457_992': 28.5968,
    '1457_993': 28.627,
    '1457_994': 28.6698,
    '1457_995': 28.7057,
    '1457_996': 28.7375,
    '1457_997': 28.7678,
    '1457_998': 28.793,
    '1457_999': 28.8141,
    '1457_1000': 28.8392,
    '1457_1001': 28.8613,
    '1457_1002': 28.8836,
    '1457_1003': 28.9064,
    '1457_1004': 28.9332,
    '1457_1005': 28.9747,
    '1457_1006': 29.0244,
    '1457_1007': 29.0806,
    '1457_1008': 29.1469,
    '1457_1009': 29.2349,
    '1457_1010': 29.3143,
    '1457_1011': 29.3912,
    '1457_1012': 29.4472,
    '1457_1013': 29.4422,
    '1457_1014': 29.3589,
    '1457_1015': 29.2335,
    '1457_1016': 29.1098,
    '1457_1017': 29.0167,
    '1457_1018': 28.9376,
    '1457_1019': 28.871,
    '1457_1020': 28.8163,
    '1457_1021': 28.7734,
    '1457_1022': 28.743,
    '1458_844': 30.0728,
    '1458_845': 30.1029,
    '1458_846': 30.1406,
    '1458_847': 30.1818,
    '1458_848': 30.2235,
    '1458_849': 30.2631,
    '1458_850': 30.3061,
    '1458_851': 30.3468,
    '1458_852': 30.3764,
    '1458_853': 30.3918,
    '1458_854': 30.3974,
    '1458_855': 30.3917,
    '1458_856': 30.3771,
    '1458_857': 30.3533,
    '1458_858': 30.3204,
    '1458_859': 30.2775,
    '1458_860': 30.232,
    '1458_861': 30.1877,
    '1458_862': 30.1498,
    '1458_863': 30.1288,
    '1458_864': 30.1108,
    '1458_865': 30.0963,
    '1458_866': 30.0826,
    '1458_867': 30.0727,
    '1458_868': 30.0709,
    '1458_869': 30.081,
    '1458_870': 30.1041,
    '1458_871': 30.1388,
    '1458_872': 30.176,
    '1458_873': 30.2188,
    '1458_874': 30.2644,
    '1458_875': 30.3095,
    '1458_876': 30.3529,
    '1458_877': 30.4019,
    '1458_878': 30.461,
    '1458_879': 30.5264,
    '1458_880': 30.5943,
    '1458_881': 30.6697,
    '1458_882': 30.7407,
    '1458_883': 30.7961,
    '1458_884': 30.8358,
    '1458_885': 30.8664,
    '1458_886': 30.8925,
    '1458_887': 30.9207,
    '1458_888': 30.9513,
    '1458_889': 30.9801,
    '1458_890': 31.0055,
    '1458_891': 31.0222,
    '1458_892': 31.0319,
    '1458_893': 31.0338,
    '1458_894': 31.028,
    '1458_895': 31.0267,
    '1458_896': 31.0322,
    '1458_897': 31.0398,
    '1458_898': 31.0444,
    '1458_899': 31.0474,
    '1458_900': 31.0467,
    '1458_901': 31.0373,
    '1458_902': 31.0247,
    '1458_903': 31.0178,
    '1458_904': 31.013,
    '1458_905': 31.0177,
    '1458_906': 31.0366,
    '1458_907': 31.0697,
    '1458_908': 31.1079,
    '1458_909': 31.1519,
    '1458_910': 31.181,
    '1458_911': 31.179,
    '1458_912': 31.1589,
    '1458_913': 31.1399,
    '1458_914': 31.1035,
    '1458_915': 31.0501,
    '1458_916': 30.998,
    '1458_917': 30.9462,
    '1458_918': 30.9002,
    '1458_919': 30.8641,
    '1458_920': 30.8445,
    '1458_921': 30.8314,
    '1458_922': 30.8214,
    '1458_923': 30.8008,
    '1458_924': 30.7669,
    '1458_925': 30.7177,
    '1458_926': 30.658,
    '1458_927': 30.605,
    '1458_928': 30.5639,
    '1458_929': 30.5274,
    '1458_930': 30.492,
    '1458_931': 30.4469,
    '1458_932': 30.4073,
    '1458_933': 30.3754,
    '1458_934': 30.3366,
    '1458_935': 30.2959,
    '1458_936': 30.2506,
    '1458_937': 30.2007,
    '1458_938': 30.1454,
    '1458_939': 30.0891,
    '1458_940': 30.0322,
    '1458_941': 29.9686,
    '1458_942': 29.9121,
    '1458_943': 29.8604,
    '1458_944': 29.8146,
    '1458_945': 29.775,
    '1458_946': 29.7424,
    '1458_947': 29.7188,
    '1458_948': 29.7011,
    '1458_949': 29.6867,
    '1458_950': 29.6751,
    '1458_951': 29.6643,
    '1458_952': 29.6524,
    '1458_953': 29.6391,
    '1458_954': 29.6235,
    '1458_955': 29.6057,
    '1458_956': 29.586,
    '1458_957': 29.5685,
    '1458_958': 29.5548,
    '1458_959': 29.5483,
    '1458_960': 29.5413,
    '1458_961': 29.5254,
    '1458_962': 29.4994,
    '1458_963': 29.463,
    '1458_964': 29.4179,
    '1458_965': 29.368,
    '1458_966': 29.3159,
    '1458_967': 29.2669,
    '1458_968': 29.2225,
    '1458_969': 29.1857,
    '1458_970': 29.1636,
    '1458_971': 29.1464,
    '1458_972': 29.1297,
    '1458_973': 29.1113,
    '1458_974': 29.0876,
    '1458_975': 29.0533,
    '1458_976': 28.9999,
    '1458_977': 28.9276,
    '1458_978': 28.8507,
    '1458_979': 28.7819,
    '1458_980': 28.7239,
    '1458_981': 28.6758,
    '1458_982': 28.6431,
    '1458_983': 28.613,
    '1458_984': 28.5837,
    '1458_985': 28.5549,
    '1458_986': 28.5337,
    '1458_987': 28.5213,
    '1458_988': 28.5158,
    '1458_989': 28.5124,
    '1458_990': 28.5103,
    '1458_991': 28.5125,
    '1458_992': 28.5292,
    '1458_993': 28.5639,
    '1458_994': 28.6049,
    '1458_995': 28.6419,
    '1458_996': 28.6717,
    '1458_997': 28.6972,
    '1458_998': 28.7185,
    '1458_999': 28.7374,
    '1458_1000': 28.7573,
    '1458_1001': 28.7759,
    '1458_1002': 28.794,
    '1458_1003': 28.8133,
    '1458_1004': 28.8353,
    '1458_1005': 28.8647,
    '1458_1006': 28.9015,
    '1458_1007': 28.9441,
    '1458_1008': 28.995,
    '1458_1009': 29.0605,
    '1458_1010': 29.1434,
    '1458_1011': 29.2078,
    '1458_1012': 29.2635,
    '1458_1013': 29.2721,
    '1458_1014': 29.2006,
    '1458_1015': 29.0978,
    '1458_1016': 28.9894,
    '1458_1017': 28.9047,
    '1458_1018': 28.8287,
    '1458_1019': 28.7625,
    '1458_1020': 28.7074,
    '1458_1021': 28.6651,
    '1458_1022': 28.6361,
    '1459_860': 30.2142,
    '1459_861': 30.17,
    '1459_862': 30.1299,
    '1459_863': 30.1007,
    '1459_864': 30.0756,
    '1459_865': 30.0554,
    '1459_866': 30.0365,
    '1459_867': 30.0218,
    '1459_868': 30.0167,
    '1459_869': 30.0236,
    '1459_870': 30.0444,
    '1459_871': 30.0791,
    '1459_872': 30.1203,
    '1459_873': 30.1613,
    '1459_874': 30.2038,
    '1459_875': 30.2491,
    '1459_876': 30.2999,
    '1459_877': 30.3563,
    '1459_878': 30.4192,
    '1459_879': 30.4905,
    '1459_880': 30.5663,
    '1459_881': 30.6447,
    '1459_882': 30.715,
    '1459_883': 30.7697,
    '1459_884': 30.8106,
    '1459_885': 30.8381,
    '1459_886': 30.8627,
    '1459_887': 30.8915,
    '1459_888': 30.9203,
    '1459_889': 30.947,
    '1459_890': 30.9712,
    '1459_891': 30.9905,
    '1459_892': 31.002,
    '1459_893': 31.0039,
    '1459_894': 30.9978,
    '1459_895': 30.9967,
    '1459_896': 31.0008,
    '1459_897': 31.0062,
    '1459_898': 31.0102,
    '1459_899': 31.0128,
    '1459_900': 31.0101,
    '1459_901': 30.9998,
    '1459_902': 30.9889,
    '1459_903': 30.9852,
    '1459_904': 30.9798,
    '1459_905': 30.9832,
    '1459_906': 30.9954,
    '1459_907': 31.0207,
    '1459_908': 31.0499,
    '1459_909': 31.079,
    '1459_910': 31.1036,
    '1459_911': 31.1073,
    '1459_912': 31.0979,
    '1459_913': 31.0781,
    '1459_914': 31.0371,
    '1459_915': 30.9862,
    '1459_916': 30.9398,
    '1459_917': 30.8942,
    '1459_918': 30.849,
    '1459_919': 30.8104,
    '1459_920': 30.7853,
    '1459_921': 30.7671,
    '1459_922': 30.752,
    '1459_923': 30.7291,
    '1459_924': 30.6943,
    '1459_925': 30.6505,
    '1459_926': 30.5983,
    '1459_927': 30.5498,
    '1459_928': 30.5055,
    '1459_929': 30.4623,
    '1459_930': 30.4292,
    '1459_931': 30.3923,
    '1459_932': 30.3521,
    '1459_933': 30.3189,
    '1459_934': 30.2733,
    '1459_935': 30.2272,
    '1459_936': 30.1791,
    '1459_937': 30.1281,
    '1459_938': 30.072,
    '1459_939': 30.0087,
    '1459_940': 29.9504,
    '1459_941': 29.8918,
    '1459_942': 29.8355,
    '1459_943': 29.7881,
    '1459_944': 29.7447,
    '1459_945': 29.7058,
    '1459_946': 29.6756,
    '1459_947': 29.6564,
    '1459_948': 29.643,
    '1459_949': 29.6314,
    '1459_950': 29.6228,
    '1459_951': 29.6135,
    '1459_952': 29.6022,
    '1459_953': 29.5897,
    '1459_954': 29.5725,
    '1459_955': 29.552,
    '1459_956': 29.5274,
    '1459_957': 29.5058,
    '1459_958': 29.4868,
    '1459_959': 29.4745,
    '1459_960': 29.4638,
    '1459_961': 29.4504,
    '1459_962': 29.4269,
    '1459_963': 29.3946,
    '1459_964': 29.3558,
    '1459_965': 29.3129,
    '1459_966': 29.2673,
    '1459_967': 29.2206,
    '1459_968': 29.1758,
    '1459_969': 29.1324,
    '1459_970': 29.1156,
    '1459_971': 29.0964,
    '1459_972': 29.0762,
    '1459_973': 29.0541,
    '1459_974': 29.0294,
    '1459_975': 28.9948,
    '1459_976': 28.9427,
    '1459_977': 28.8694,
    '1459_978': 28.7927,
    '1459_979': 28.7258,
    '1459_980': 28.6674,
    '1459_981': 28.6088,
    '1459_982': 28.5793,
    '1459_983': 28.5486,
    '1459_984': 28.5224,
    '1459_985': 28.4939,
    '1459_986': 28.4727,
    '1459_987': 28.4628,
    '1459_988': 28.4585,
    '1459_989': 28.4591,
    '1459_990': 28.4578,
    '1459_991': 28.461,
    '1459_992': 28.4649,
    '1459_993': 28.5022,
    '1459_994': 28.5412,
    '1459_995': 28.5804,
    '1459_996': 28.6088,
    '1459_997': 28.6325,
    '1459_998': 28.6512,
    '1459_999': 28.6677,
    '1459_1000': 28.6837,
    '1459_1001': 28.6985,
    '1459_1002': 28.7128,
    '1459_1003': 28.7284,
    '1459_1004': 28.7452,
    '1459_1005': 28.7637,
    '1459_1006': 28.7875,
    '1459_1007': 28.8178,
    '1459_1008': 28.8548,
    '1459_1009': 28.9109,
    '1459_1010': 28.9852,
    '1459_1011': 29.0393,
    '1459_1012': 29.0808,
    '1459_1013': 29.0967,
    '1459_1014': 29.0424,
    '1459_1015': 28.9457,
    '1459_1016': 28.8671,
    '1459_1017': 28.792,
    '1459_1018': 28.7186,
    '1459_1019': 28.6507,
    '1459_1020': 28.5938,
    '1459_1021': 28.5524,
    '1459_1022': 28.526,
    '1460_860': 30.1966,
    '1460_861': 30.1537,
    '1460_862': 30.1118,
    '1460_863': 30.0712,
    '1460_864': 30.0408,
    '1460_865': 30.0138,
    '1460_866': 29.9883,
    '1460_867': 29.9691,
    '1460_868': 29.9617,
    '1460_869': 29.9649,
    '1460_870': 29.9845,
    '1460_871': 30.0216,
    '1460_872': 30.0665,
    '1460_873': 30.1103,
    '1460_874': 30.1549,
    '1460_875': 30.201,
    '1460_876': 30.2554,
    '1460_877': 30.3167,
    '1460_878': 30.384,
    '1460_879': 30.4607,
    '1460_880': 30.5465,
    '1460_881': 30.6304,
    '1460_882': 30.6982,
    '1460_883': 30.7495,
    '1460_884': 30.7874,
    '1460_885': 30.8123,
    '1460_886': 30.8363,
    '1460_887': 30.865,
    '1460_888': 30.8926,
    '1460_889': 30.9174,
    '1460_890': 30.9393,
    '1460_891': 30.9591,
    '1460_892': 30.9765,
    '1460_893': 30.9835,
    '1460_894': 30.9755,
    '1460_895': 30.9709,
    '1460_896': 30.9757,
    '1460_897': 30.979,
    '1460_898': 30.9824,
    '1460_899': 30.9852,
    '1460_900': 30.9849,
    '1460_901': 30.981,
    '1460_902': 30.9809,
    '1460_903': 30.9803,
    '1460_904': 30.9699,
    '1460_905': 30.9674,
    '1460_906': 30.965,
    '1460_907': 30.979,
    '1460_908': 31.005,
    '1460_909': 31.0275,
    '1460_910': 31.0471,
    '1460_911': 31.0544,
    '1460_912': 31.0485,
    '1460_913': 31.0249,
    '1460_914': 30.9828,
    '1460_915': 30.9349,
    '1460_916': 30.8923,
    '1460_917': 30.8481,
    '1460_918': 30.7994,
    '1460_919': 30.7556,
    '1460_920': 30.7209,
    '1460_921': 30.6955,
    '1460_922': 30.675,
    '1460_923': 30.6529,
    '1460_924': 30.62,
    '1460_925': 30.579,
    '1460_926': 30.5348,
    '1460_927': 30.4922,
    '1460_928': 30.4494,
    '1460_929': 30.4066,
    '1460_930': 30.3661,
    '1460_931': 30.329,
    '1460_932': 30.2903,
    '1460_933': 30.2549,
    '1460_934': 30.2049,
    '1460_935': 30.1559,
    '1460_936': 30.1049,
    '1460_937': 30.0533,
    '1460_938': 29.9935,
    '1460_939': 29.9376,
    '1460_940': 29.8826,
    '1460_941': 29.8286,
    '1460_942': 29.777,
    '1460_943': 29.7306,
    '1460_944': 29.6884,
    '1460_945': 29.6512,
    '1460_946': 29.6215,
    '1460_947': 29.6039,
    '1460_948': 29.5933,
    '1460_949': 29.5861,
    '1460_950': 29.5795,
    '1460_951': 29.5705,
    '1460_952': 29.5594,
    '1460_953': 29.5433,
    '1460_954': 29.5177,
    '1460_955': 29.4895,
    '1460_956': 29.4607,
    '1460_957': 29.4341,
    '1460_958': 29.4128,
    '1460_959': 29.4016,
    '1460_960': 29.3937,
    '1460_961': 29.3824,
    '1460_962': 29.358,
    '1460_963': 29.3274,
    '1460_964': 29.2926,
    '1460_965': 29.2552,
    '1460_966': 29.2145,
    '1460_967': 29.1707,
    '1460_968': 29.1272,
    '1460_969': 29.0874,
    '1460_970': 29.064,
    '1460_971': 29.0438,
    '1460_972': 29.0212,
    '1460_973': 28.9966,
    '1460_974': 28.9712,
    '1460_975': 28.9353,
    '1460_976': 28.8782,
    '1460_977': 28.8034,
    '1460_978': 28.7258,
    '1460_979': 28.6578,
    '1460_980': 28.5972,
    '1460_981': 28.5414,
    '1460_982': 28.4992,
    '1460_983': 28.4697,
    '1460_984': 28.445,
    '1460_985': 28.4209,
    '1460_986': 28.4018,
    '1460_987': 28.3925,
    '1460_988': 28.3894,
    '1460_989': 28.3906,
    '1460_990': 28.3928,
    '1460_991': 28.3974,
    '1460_992': 28.4053,
    '1460_993': 28.4283,
    '1460_994': 28.4665,
    '1460_995': 28.5061,
    '1460_996': 28.5385,
    '1460_997': 28.5651,
    '1460_998': 28.5834,
    '1460_999': 28.5982,
    '1460_1000': 28.6112,
    '1460_1001': 28.6227,
    '1460_1002': 28.6329,
    '1460_1003': 28.6449,
    '1460_1004': 28.6575,
    '1460_1005': 28.6675,
    '1460_1006': 28.6821,
    '1460_1007': 28.7062,
    '1460_1008': 28.7367,
    '1460_1009': 28.7788,
    '1460_1010': 28.8273,
    '1460_1011': 28.8845,
    '1460_1012': 28.9179,
    '1460_1013': 28.9286,
    '1460_1014': 28.8927,
    '1460_1015': 28.8155,
    '1460_1016': 28.751,
    '1460_1017': 28.6838,
    '1460_1018': 28.6111,
    '1460_1019': 28.5379,
    '1460_1020': 28.4755,
    '1460_1021': 28.4363,
    '1460_1022': 28.4145,
    '1461_860': 30.1767,
    '1461_861': 30.1344,
    '1461_862': 30.0924,
    '1461_863': 30.0433,
    '1461_864': 30.0063,
    '1461_865': 29.969,
    '1461_866': 29.9388,
    '1461_867': 29.9152,
    '1461_868': 29.9041,
    '1461_869': 29.9059,
    '1461_870': 29.9256,
    '1461_871': 29.9642,
    '1461_872': 30.0095,
    '1461_873': 30.0543,
    '1461_874': 30.1012,
    '1461_875': 30.1558,
    '1461_876': 30.2172,
    '1461_877': 30.2804,
    '1461_878': 30.3509,
    '1461_879': 30.4346,
    '1461_880': 30.5278,
    '1461_881': 30.6133,
    '1461_882': 30.6753,
    '1461_883': 30.7206,
    '1461_884': 30.7544,
    '1461_885': 30.7814,
    '1461_886': 30.8083,
    '1461_887': 30.8379,
    '1461_888': 30.866,
    '1461_889': 30.8905,
    '1461_890': 30.9095,
    '1461_891': 30.9297,
    '1461_892': 30.9527,
    '1461_893': 30.9627,
    '1461_894': 30.9516,
    '1461_895': 30.946,
    '1461_896': 30.9516,
    '1461_897': 30.9561,
    '1461_898': 30.9597,
    '1461_899': 30.9657,
    '1461_900': 30.9762,
    '1461_901': 30.984,
    '1461_902': 30.99,
    '1461_903': 30.9899,
    '1461_904': 30.9736,
    '1461_905': 30.9624,
    '1461_906': 30.9497,
    '1461_907': 30.9474,
    '1461_908': 30.968,
    '1461_909': 30.9912,
    '1461_910': 31.0041,
    '1461_911': 31.0113,
    '1461_912': 31.0048,
    '1461_913': 30.9833,
    '1461_914': 30.9442,
    '1461_915': 30.9001,
    '1461_916': 30.8584,
    '1461_917': 30.8132,
    '1461_918': 30.7595,
    '1461_919': 30.7028,
    '1461_920': 30.6582,
    '1461_921': 30.6223,
    '1461_922': 30.5949,
    '1461_923': 30.5741,
    '1461_924': 30.5434,
    '1461_925': 30.5061,
    '1461_926': 30.4661,
    '1461_927': 30.4263,
    '1461_928': 30.3858,
    '1461_929': 30.3457,
    '1461_930': 30.3018,
    '1461_931': 30.2581,
    '1461_932': 30.2186,
    '1461_933': 30.1765,
    '1461_934': 30.1274,
    '1461_935': 30.0783,
    '1461_936': 30.0232,
    '1461_937': 29.9732,
    '1461_938': 29.9229,
    '1461_939': 29.8723,
    '1461_940': 29.8222,
    '1461_941': 29.7733,
    '1461_942': 29.7264,
    '1461_943': 29.6827,
    '1461_944': 29.6424,
    '1461_945': 29.6064,
    '1461_946': 29.576,
    '1461_947': 29.561,
    '1461_948': 29.552,
    '1461_949': 29.548,
    '1461_950': 29.5425,
    '1461_951': 29.5305,
    '1461_952': 29.5157,
    '1461_953': 29.4905,
    '1461_954': 29.4586,
    '1461_955': 29.4248,
    '1461_956': 29.3933,
    '1461_957': 29.3633,
    '1461_958': 29.3397,
    '1461_959': 29.3288,
    '1461_960': 29.3225,
    '1461_961': 29.3152,
    '1461_962': 29.2879,
    '1461_963': 29.2572,
    '1461_964': 29.2256,
    '1461_965': 29.1929,
    '1461_966': 29.1577,
    '1461_967': 29.1163,
    '1461_968': 29.0751,
    '1461_969': 29.0352,
    '1461_970': 29.0099,
    '1461_971': 28.9887,
    '1461_972': 28.9638,
    '1461_973': 28.9397,
    '1461_974': 28.9101,
    '1461_975': 28.8739,
    '1461_976': 28.8113,
    '1461_977': 28.7321,
    '1461_978': 28.6516,
    '1461_979': 28.5851,
    '1461_980': 28.5263,
    '1461_981': 28.4753,
    '1461_982': 28.433,
    '1461_983': 28.3945,
    '1461_984': 28.3672,
    '1461_985': 28.3438,
    '1461_986': 28.325,
    '1461_987': 28.317,
    '1461_988': 28.3149,
    '1461_989': 28.3175,
    '1461_990': 28.3227,
    '1461_991': 28.3318,
    '1461_992': 28.3467,
    '1461_993': 28.3693,
    '1461_994': 28.3934,
    '1461_995': 28.4281,
    '1461_996': 28.4605,
    '1461_997': 28.4887,
    '1461_998': 28.5094,
    '1461_999': 28.5256,
    '1461_1000': 28.5352,
    '1461_1001': 28.5423,
    '1461_1002': 28.5491,
    '1461_1003': 28.5568,
    '1461_1004': 28.5634,
    '1461_1005': 28.572,
    '1461_1006': 28.587,
    '1461_1007': 28.6028,
    '1461_1008': 28.626,
    '1461_1009': 28.6574,
    '1461_1010': 28.6932,
    '1461_1011': 28.7299,
    '1461_1012': 28.7723,
    '1461_1013': 28.7813,
    '1461_1014': 28.759,
    '1461_1015': 28.6975,
    '1461_1016': 28.6444,
    '1461_1017': 28.5842,
    '1461_1018': 28.5117,
    '1461_1019': 28.4299,
    '1461_1020': 28.3578,
    '1461_1021': 28.3233,
    '1461_1022': 28.3067,
    '1462_860': 30.1513,
    '1462_861': 30.1055,
    '1462_862': 30.0588,
    '1462_863': 30.0053,
    '1462_864': 29.9604,
    '1462_865': 29.9199,
    '1462_866': 29.8874,
    '1462_867': 29.8619,
    '1462_868': 29.8479,
    '1462_869': 29.8482,
    '1462_870': 29.8666,
    '1462_871': 29.9031,
    '1462_872': 29.9492,
    '1462_873': 29.9964,
    '1462_874': 30.0499,
    '1462_875': 30.1094,
    '1462_876': 30.1767,
    '1462_877': 30.2457,
    '1462_878': 30.3206,
    '1462_879': 30.4079,
    '1462_880': 30.5051,
    '1462_881': 30.5881,
    '1462_882': 30.6446,
    '1462_883': 30.6848,
    '1462_884': 30.7155,
    '1462_885': 30.7466,
    '1462_886': 30.7789,
    '1462_887': 30.8111,
    '1462_888': 30.84,
    '1462_889': 30.8638,
    '1462_890': 30.8833,
    '1462_891': 30.9049,
    '1462_892': 30.9287,
    '1462_893': 30.9378,
    '1462_894': 30.9241,
    '1462_895': 30.9194,
    '1462_896': 30.925,
    '1462_897': 30.933,
    '1462_898': 30.9403,
    '1462_899': 30.9537,
    '1462_900': 30.9756,
    '1462_901': 30.9989,
    '1462_902': 31.0114,
    '1462_903': 31.0093,
    '1462_904': 30.9872,
    '1462_905': 30.9656,
    '1462_906': 30.9413,
    '1462_907': 30.9229,
    '1462_908': 30.9303,
    '1462_909': 30.9503,
    '1462_910': 30.9608,
    '1462_911': 30.9713,
    '1462_912': 30.9647,
    '1462_913': 30.9493,
    '1462_914': 30.9194,
    '1462_915': 30.8761,
    '1462_916': 30.8309,
    '1462_917': 30.7791,
    '1462_918': 30.7183,
    '1462_919': 30.6566,
    '1462_920': 30.6044,
    '1462_921': 30.5549,
    '1462_922': 30.5185,
    '1462_923': 30.4926,
    '1462_924': 30.4655,
    '1462_925': 30.4323,
    '1462_926': 30.3969,
    '1462_927': 30.3579,
    '1462_928': 30.3152,
    '1462_929': 30.2745,
    '1462_930': 30.2303,
    '1462_931': 30.1847,
    '1462_932': 30.1379,
    '1462_933': 30.0913,
    '1462_934': 30.0456,
    '1462_935': 30.0005,
    '1462_936': 29.9489,
    '1462_937': 29.9033,
    '1462_938': 29.8585,
    '1462_939': 29.8133,
    '1462_940': 29.7683,
    '1462_941': 29.7245,
    '1462_942': 29.6825,
    '1462_943': 29.6429,
    '1462_944': 29.6067,
    '1462_945': 29.5751,
    '1462_946': 29.5492,
    '1462_947': 29.5335,
    '1462_948': 29.5243,
    '1462_949': 29.518,
    '1462_950': 29.5097,
    '1462_951': 29.4953,
    '1462_952': 29.472,
    '1462_953': 29.4388,
    '1462_954': 29.4027,
    '1462_955': 29.3675,
    '1462_956': 29.334,
    '1462_957': 29.3023,
    '1462_958': 29.2757,
    '1462_959': 29.2549,
    '1462_960': 29.2393,
    '1462_961': 29.2255,
    '1462_962': 29.2035,
    '1462_963': 29.1777,
    '1462_964': 29.1517,
    '1462_965': 29.1237,
    '1462_966': 29.092,
    '1462_967': 29.0557,
    '1462_968': 29.0174,
    '1462_969': 28.9832,
    '1462_970': 28.9543,
    '1462_971': 28.9264,
    '1462_972': 28.8944,
    '1462_973': 28.8593,
    '1462_974': 28.8301,
    '1462_975': 28.7907,
    '1462_976': 28.7315,
    '1462_977': 28.6588,
    '1462_978': 28.5835,
    '1462_979': 28.5161,
    '1462_980': 28.4567,
    '1462_981': 28.4058,
    '1462_982': 28.362,
    '1462_983': 28.3227,
    '1462_984': 28.2911,
    '1462_985': 28.2695,
    '1462_986': 28.2531,
    '1462_987': 28.2447,
    '1462_988': 28.2427,
    '1462_989': 28.2455,
    '1462_990': 28.2514,
    '1462_991': 28.2617,
    '1462_992': 28.2777,
    '1462_993': 28.295,
    '1462_994': 28.3084,
    '1462_995': 28.3373,
    '1462_996': 28.3753,
    '1462_997': 28.4058,
    '1462_998': 28.4282,
    '1462_999': 28.4443,
    '1462_1000': 28.4535,
    '1462_1001': 28.4582,
    '1462_1002': 28.4622,
    '1462_1003': 28.4671,
    '1462_1004': 28.4679,
    '1462_1005': 28.4703,
    '1462_1006': 28.4779,
    '1462_1007': 28.4957,
    '1462_1008': 28.5168,
    '1462_1009': 28.5428,
    '1462_1010': 28.5713,
    '1462_1011': 28.6007,
    '1462_1012': 28.6399,
    '1462_1013': 28.6506,
    '1462_1014': 28.6378,
    '1462_1015': 28.59,
    '1462_1016': 28.5474,
    '1462_1017': 28.4943,
    '1462_1018': 28.4213,
    '1462_1019': 28.3391,
    '1462_1020': 28.2673,
    '1462_1021': 28.2292,
    '1462_1022': 28.2113,
    '1463_850': 30.3739,
    '1463_851': 30.4018,
    '1463_852': 30.4193,
    '1463_853': 30.4242,
    '1463_854': 30.4204,
    '1463_860': 30.1219,
    '1463_861': 30.0739,
    '1463_862': 30.0247,
    '1463_863': 29.9671,
    '1463_864': 29.9119,
    '1463_865': 29.8671,
    '1463_866': 29.8318,
    '1463_867': 29.8066,
    '1463_868': 29.791,
    '1463_869': 29.7905,
    '1463_870': 29.8065,
    '1463_871': 29.8429,
    '1463_872': 29.8937,
    '1463_873': 29.9462,
    '1463_874': 30.0032,
    '1463_875': 30.0618,
    '1463_876': 30.1301,
    '1463_877': 30.2054,
    '1463_878': 30.2842,
    '1463_879': 30.377,
    '1463_880': 30.4739,
    '1463_881': 30.5536,
    '1463_882': 30.6054,
    '1463_883': 30.6437,
    '1463_884': 30.6762,
    '1463_885': 30.7118,
    '1463_886': 30.75,
    '1463_887': 30.7833,
    '1463_888': 30.8147,
    '1463_889': 30.8378,
    '1463_890': 30.8587,
    '1463_891': 30.8806,
    '1463_892': 30.9011,
    '1463_893': 30.9078,
    '1463_894': 30.8938,
    '1463_895': 30.8911,
    '1463_896': 30.8996,
    '1463_897': 30.9117,
    '1463_898': 30.9246,
    '1463_899': 30.946,
    '1463_900': 30.9777,
    '1463_901': 31.0124,
    '1463_902': 31.0305,
    '1463_903': 31.028,
    '1463_904': 31.0038,
    '1463_905': 30.976,
    '1463_906': 30.941,
    '1463_907': 30.9106,
    '1463_908': 30.8992,
    '1463_909': 30.9148,
    '1463_910': 30.9272,
    '1463_911': 30.9359,
    '1463_912': 30.9325,
    '1463_913': 30.9201,
    '1463_914': 30.8925,
    '1463_915': 30.8565,
    '1463_916': 30.809,
    '1463_917': 30.7483,
    '1463_918': 30.6795,
    '1463_919': 30.6102,
    '1463_920': 30.5477,
    '1463_921': 30.4929,
    '1463_922': 30.4457,
    '1463_923': 30.4107,
    '1463_924': 30.3855,
    '1463_925': 30.3578,
    '1463_926': 30.324,
    '1463_927': 30.287,
    '1463_928': 30.2444,
    '1463_929': 30.2003,
    '1463_930': 30.1503,
    '1463_931': 30.1012,
    '1463_932': 30.0534,
    '1463_933': 30.0085,
    '1463_934': 29.9663,
    '1463_935': 29.9199,
    '1463_936': 29.878,
    '1463_937': 29.8375,
    '1463_938': 29.7976,
    '1463_939': 29.7573,
    '1463_940': 29.7173,
    '1463_941': 29.6786,
    '1463_942': 29.6414,
    '1463_943': 29.6063,
    '1463_944': 29.5746,
    '1463_945': 29.5475,
    '1463_946': 29.5251,
    '1463_947': 29.5097,
    '1463_948': 29.4991,
    '1463_949': 29.49,
    '1463_950': 29.4783,
    '1463_951': 29.4606,
    '1463_952': 29.4293,
    '1463_953': 29.3867,
    '1463_954': 29.3459,
    '1463_955': 29.3089,
    '1463_956': 29.274,
    '1463_957': 29.2388,
    '1463_958': 29.2075,
    '1463_959': 29.1755,
    '1463_960': 29.1499,
    '1463_961': 29.1333,
    '1463_962': 29.1171,
    '1463_963': 29.0986,
    '1463_964': 29.0776,
    '1463_965': 29.0537,
    '1463_966': 29.0269,
    '1463_967': 28.9977,
    '1463_968': 28.9658,
    '1463_969': 28.9322,
    '1463_970': 28.9001,
    '1463_971': 28.8677,
    '1463_972': 28.8324,
    '1463_973': 28.7949,
    '1463_974': 28.7576,
    '1463_975': 28.7123,
    '1463_976': 28.6534,
    '1463_977': 28.5847,
    '1463_978': 28.5126,
    '1463_979': 28.4449,
    '1463_980': 28.3843,
    '1463_981': 28.3331,
    '1463_982': 28.289,
    '1463_983': 28.2496,
    '1463_984': 28.213,
    '1463_985': 28.1945,
    '1463_986': 28.1795,
    '1463_987': 28.1717,
    '1463_988': 28.1704,
    '1463_989': 28.1732,
    '1463_990': 28.1788,
    '1463_991': 28.1889,
    '1463_992': 28.2077,
    '1463_993': 28.228,
    '1463_994': 28.2409,
    '1463_995': 28.2504,
    '1463_996': 28.2909,
    '1463_997': 28.3202,
    '1463_998': 28.3435,
    '1463_999': 28.3597,
    '1463_1000': 28.3688,
    '1463_1001': 28.3708,
    '1463_1002': 28.3707,
    '1463_1003': 28.3729,
    '1463_1004': 28.3725,
    '1463_1005': 28.3741,
    '1463_1006': 28.3813,
    '1463_1007': 28.395,
    '1463_1008': 28.4118,
    '1463_1009': 28.4326,
    '1463_1010': 28.4563,
    '1463_1011': 28.4802,
    '1463_1012': 28.5022,
    '1463_1013': 28.5144,
    '1463_1014': 28.5111,
    '1463_1015': 28.4898,
    '1463_1016': 28.4561,
    '1463_1017': 28.4072,
    '1463_1018': 28.341,
    '1463_1019': 28.2606,
    '1463_1020': 28.1852,
    '1463_1021': 28.148,
    '1463_1022': 28.1278,
    '1464_850': 30.362,
    '1464_851': 30.3883,
    '1464_852': 30.4045,
    '1464_853': 30.406,
    '1464_854': 30.3975,
    '1464_855': 30.4268,
    '1464_856': 30.3986,
    '1464_857': 30.3615,
    '1464_858': 30.315,
    '1464_860': 30.0795,
    '1464_861': 30.027,
    '1464_862': 29.9712,
    '1464_863': 29.9128,
    '1464_864': 29.855,
    '1464_865': 29.8109,
    '1464_866': 29.7758,
    '1464_867': 29.7504,
    '1464_868': 29.7341,
    '1464_869': 29.7321,
    '1464_870': 29.7477,
    '1464_871': 29.7853,
    '1464_872': 29.8396,
    '1464_873': 29.8964,
    '1464_874': 29.9581,
    '1464_875': 30.0223,
    '1464_876': 30.0886,
    '1464_877': 30.1665,
    '1464_878': 30.2486,
    '1464_879': 30.3399,
    '1464_880': 30.433,
    '1464_881': 30.5089,
    '1464_882': 30.5612,
    '1464_883': 30.6037,
    '1464_884': 30.641,
    '1464_885': 30.6821,
    '1464_886': 30.7247,
    '1464_887': 30.754,
    '1464_888': 30.7801,
    '1464_889': 30.8058,
    '1464_890': 30.8294,
    '1464_891': 30.8486,
    '1464_892': 30.866,
    '1464_893': 30.8722,
    '1464_894': 30.8602,
    '1464_895': 30.8602,
    '1464_896': 30.8717,
    '1464_897': 30.89,
    '1464_898': 30.9119,
    '1464_899': 30.941,
    '1464_900': 30.9825,
    '1464_901': 31.0175,
    '1464_902': 31.0398,
    '1464_903': 31.04,
    '1464_904': 31.0168,
    '1464_905': 30.9848,
    '1464_906': 30.9442,
    '1464_907': 30.9052,
    '1464_908': 30.8841,
    '1464_909': 30.8914,
    '1464_910': 30.9018,
    '1464_911': 30.9076,
    '1464_912': 30.9043,
    '1464_913': 30.8915,
    '1464_914': 30.8621,
    '1464_915': 30.8274,
    '1464_916': 30.7795,
    '1464_917': 30.7109,
    '1464_918': 30.6343,
    '1464_919': 30.5615,
    '1464_920': 30.4895,
    '1464_921': 30.4252,
    '1464_922': 30.3733,
    '1464_923': 30.3342,
    '1464_924': 30.3085,
    '1464_925': 30.2813,
    '1464_926': 30.2486,
    '1464_927': 30.2128,
    '1464_928': 30.1703,
    '1464_929': 30.123,
    '1464_930': 30.0701,
    '1464_931': 30.0175,
    '1464_932': 29.972,
    '1464_933': 29.9304,
    '1464_934': 29.8861,
    '1464_935': 29.8478,
    '1464_936': 29.8109,
    '1464_937': 29.775,
    '1464_938': 29.7395,
    '1464_939': 29.7037,
    '1464_940': 29.6682,
    '1464_941': 29.6341,
    '1464_942': 29.6013,
    '1464_943': 29.5703,
    '1464_944': 29.5426,
    '1464_945': 29.5187,
    '1464_946': 29.4985,
    '1464_947': 29.4831,
    '1464_948': 29.4713,
    '1464_949': 29.4588,
    '1464_950': 29.4411,
    '1464_951': 29.4164,
    '1464_952': 29.3796,
    '1464_953': 29.3335,
    '1464_954': 29.2874,
    '1464_955': 29.2443,
    '1464_956': 29.2039,
    '1464_957': 29.1651,
    '1464_958': 29.1281,
    '1464_959': 29.0907,
    '1464_960': 29.0595,
    '1464_961': 29.0464,
    '1464_962': 29.0365,
    '1464_963': 29.025,
    '1464_964': 29.0083,
    '1464_965': 28.9871,
    '1464_966': 28.9647,
    '1464_967': 28.9424,
    '1464_968': 28.9163,
    '1464_969': 28.8812,
    '1464_970': 28.8473,
    '1464_971': 28.8129,
    '1464_972': 28.7755,
    '1464_973': 28.7352,
    '1464_974': 28.6929,
    '1464_975': 28.6419,
    '1464_976': 28.5803,
    '1464_977': 28.5112,
    '1464_978': 28.4396,
    '1464_979': 28.3712,
    '1464_980': 28.3094,
    '1464_981': 28.258,
    '1464_982': 28.2139,
    '1464_983': 28.1747,
    '1464_984': 28.1388,
    '1464_985': 28.1121,
    '1464_986': 28.1018,
    '1464_987': 28.0967,
    '1464_988': 28.0963,
    '1464_989': 28.0991,
    '1464_990': 28.1053,
    '1464_991': 28.1163,
    '1464_992': 28.1332,
    '1464_993': 28.1511,
    '1464_994': 28.1641,
    '1464_995': 28.1741,
    '1464_996': 28.1934,
    '1464_997': 28.2274,
    '1464_998': 28.2538,
    '1464_999': 28.2723,
    '1464_1000': 28.2814,
    '1464_1001': 28.2829,
    '1464_1002': 28.2805,
    '1464_1003': 28.2841,
    '1464_1004': 28.2847,
    '1464_1005': 28.2843,
    '1464_1006': 28.2881,
    '1464_1007': 28.2966,
    '1464_1008': 28.3087,
    '1464_1009': 28.3247,
    '1464_1010': 28.3449,
    '1464_1011': 28.3657,
    '1464_1012': 28.3863,
    '1464_1013': 28.4026,
    '1464_1014': 28.4088,
    '1464_1015': 28.3996,
    '1464_1016': 28.3722,
    '1464_1017': 28.3275,
    '1464_1018': 28.2688,
    '1464_1019': 28.2024,
    '1464_1020': 28.1385,
    '1464_1021': 28.0881,
    '1464_1022': 28.0565,
    '1465_850': 30.3519,
    '1465_851': 30.3808,
    '1465_852': 30.398,
    '1465_853': 30.3928,
    '1465_854': 30.3744,
    '1465_855': 30.3886,
    '1465_856': 30.3585,
    '1465_857': 30.3173,
    '1465_858': 30.2646,
    '1465_860': 30.0318,
    '1465_861': 29.9752,
    '1465_862': 29.9168,
    '1465_863': 29.8585,
    '1465_864': 29.802,
    '1465_865': 29.7562,
    '1465_866': 29.7198,
    '1465_867': 29.6928,
    '1465_868': 29.6773,
    '1465_869': 29.6744,
    '1465_870': 29.69,
    '1465_871': 29.7274,
    '1465_872': 29.7855,
    '1465_873': 29.8492,
    '1465_874': 29.9165,
    '1465_875': 29.9856,
    '1465_876': 30.0543,
    '1465_877': 30.1312,
    '1465_878': 30.2134,
    '1465_879': 30.2992,
    '1465_880': 30.3847,
    '1465_881': 30.455,
    '1465_882': 30.5121,
    '1465_883': 30.5662,
    '1465_884': 30.613,
    '1465_885': 30.6579,
    '1465_886': 30.6989,
    '1465_887': 30.7243,
    '1465_888': 30.7467,
    '1465_889': 30.7681,
    '1465_890': 30.7855,
    '1465_891': 30.8005,
    '1465_892': 30.8223,
    '1465_893': 30.8288,
    '1465_894': 30.8222,
    '1465_895': 30.8276,
    '1465_896': 30.841,
    '1465_897': 30.8627,
    '1465_898': 30.892,
    '1465_899': 30.9335,
    '1465_900': 30.9844,
    '1465_901': 31.0174,
    '1465_902': 31.0379,
    '1465_903': 31.0442,
    '1465_904': 31.0269,
    '1465_905': 30.9913,
    '1465_906': 30.9458,
    '1465_907': 30.9023,
    '1465_908': 30.8757,
    '1465_909': 30.8722,
    '1465_910': 30.8739,
    '1465_911': 30.873,
    '1465_912': 30.8663,
    '1465_913': 30.8535,
    '1465_914': 30.8254,
    '1465_915': 30.7876,
    '1465_916': 30.7325,
    '1465_917': 30.6619,
    '1465_918': 30.5864,
    '1465_919': 30.5058,
    '1465_920': 30.4267,
    '1465_921': 30.3579,
    '1465_922': 30.3084,
    '1465_923': 30.2621,
    '1465_924': 30.2263,
    '1465_925': 30.1992,
    '1465_926': 30.1678,
    '1465_927': 30.1322,
    '1465_928': 30.0909,
    '1465_929': 30.0418,
    '1465_930': 29.9894,
    '1465_931': 29.94,
    '1465_932': 29.896,
    '1465_933': 29.8513,
    '1465_934': 29.8139,
    '1465_935': 29.7797,
    '1465_936': 29.7466,
    '1465_937': 29.7145,
    '1465_938': 29.6829,
    '1465_939': 29.6512,
    '1465_940': 29.6198,
    '1465_941': 29.5896,
    '1465_942': 29.5606,
    '1465_943': 29.5331,
    '1465_944': 29.5081,
    '1465_945': 29.4862,
    '1465_946': 29.4668,
    '1465_947': 29.4507,
    '1465_948': 29.4367,
    '1465_949': 29.4211,
    '1465_950': 29.3968,
    '1465_951': 29.3673,
    '1465_952': 29.3255,
    '1465_953': 29.2739,
    '1465_954': 29.2226,
    '1465_955': 29.1741,
    '1465_956': 29.1301,
    '1465_957': 29.0896,
    '1465_958': 29.0508,
    '1465_959': 29.014,
    '1465_960': 28.9858,
    '1465_961': 28.9743,
    '1465_962': 28.9683,
    '1465_963': 28.9626,
    '1465_964': 28.949,
    '1465_965': 28.9285,
    '1465_966': 28.9063,
    '1465_967': 28.8885,
    '1465_968': 28.8648,
    '1465_969': 28.8278,
    '1465_970': 28.7953,
    '1465_971': 28.7607,
    '1465_972': 28.7212,
    '1465_973': 28.6774,
    '1465_974': 28.6326,
    '1465_975': 28.5765,
    '1465_976': 28.5104,
    '1465_977': 28.4379,
    '1465_978': 28.364,
    '1465_979': 28.2945,
    '1465_980': 28.2324,
    '1465_981': 28.1802,
    '1465_982': 28.1361,
    '1465_983': 28.0994,
    '1465_984': 28.0677,
    '1465_985': 28.0412,
    '1465_986': 28.027,
    '1465_987': 28.0197,
    '1465_988': 28.0189,
    '1465_989': 28.0208,
    '1465_990': 28.0266,
    '1465_991': 28.0371,
    '1465_992': 28.0531,
    '1465_993': 28.0709,
    '1465_994': 28.0875,
    '1465_995': 28.1031,
    '1465_996': 28.1176,
    '1465_997': 28.1422,
    '1465_998': 28.1621,
    '1465_999': 28.1809,
    '1465_1000': 28.1915,
    '1465_1001': 28.1977,
    '1465_1002': 28.2024,
    '1465_1003': 28.2006,
    '1465_1004': 28.1976,
    '1465_1005': 28.1922,
    '1465_1006': 28.1909,
    '1465_1007': 28.1953,
    '1465_1008': 28.2039,
    '1465_1009': 28.2165,
    '1465_1010': 28.2329,
    '1465_1011': 28.2521,
    '1465_1012': 28.2731,
    '1465_1013': 28.2945,
    '1465_1014': 28.3114,
    '1465_1015': 28.3149,
    '1465_1016': 28.2909,
    '1465_1017': 28.2492,
    '1465_1018': 28.198,
    '1465_1019': 28.1441,
    '1465_1020': 28.09,
    '1465_1021': 28.0264,
    '1465_1022': 27.986,
    '1466_850': 30.338,
    '1466_851': 30.36,
    '1466_852': 30.3697,
    '1466_853': 30.365,
    '1466_854': 30.3482,
    '1466_855': 30.3494,
    '1466_856': 30.3209,
    '1466_857': 30.2751,
    '1466_858': 30.2106,
    '1466_860': 29.981,
    '1466_861': 29.9204,
    '1466_862': 29.8598,
    '1466_863': 29.8016,
    '1466_864': 29.7438,
    '1466_865': 29.6989,
    '1466_866': 29.6628,
    '1466_867': 29.6361,
    '1466_868': 29.6211,
    '1466_869': 29.6183,
    '1466_870': 29.633,
    '1466_871': 29.6699,
    '1466_872': 29.7325,
    '1466_873': 29.8015,
    '1466_874': 29.8732,
    '1466_875': 29.9466,
    '1466_876': 30.0222,
    '1466_877': 30.0997,
    '1466_878': 30.1776,
    '1466_879': 30.2568,
    '1466_880': 30.3309,
    '1466_881': 30.397,
    '1466_882': 30.4607,
    '1466_883': 30.5273,
    '1466_884': 30.5859,
    '1466_885': 30.6328,
    '1466_886': 30.6701,
    '1466_887': 30.6963,
    '1466_888': 30.715,
    '1466_889': 30.7284,
    '1466_890': 30.7325,
    '1466_891': 30.7462,
    '1466_892': 30.773,
    '1466_893': 30.78,
    '1466_894': 30.7796,
    '1466_895': 30.7902,
    '1466_896': 30.8075,
    '1466_897': 30.8344,
    '1466_898': 30.8721,
    '1466_899': 30.9195,
    '1466_900': 30.9713,
    '1466_901': 31.0083,
    '1466_902': 31.0295,
    '1466_903': 31.0398,
    '1466_904': 31.027,
    '1466_905': 30.992,
    '1466_906': 30.9458,
    '1466_907': 30.8986,
    '1466_908': 30.8666,
    '1466_909': 30.8505,
    '1466_910': 30.8403,
    '1466_911': 30.8326,
    '1466_912': 30.822,
    '1466_913': 30.8089,
    '1466_914': 30.7812,
    '1466_915': 30.7381,
    '1466_916': 30.6811,
    '1466_917': 30.6125,
    '1466_918': 30.5347,
    '1466_919': 30.4498,
    '1466_920': 30.3654,
    '1466_921': 30.2954,
    '1466_922': 30.238,
    '1466_923': 30.1861,
    '1466_924': 30.1491,
    '1466_925': 30.1152,
    '1466_926': 30.0801,
    '1466_927': 30.0446,
    '1466_928': 30.0077,
    '1466_929': 29.9606,
    '1466_930': 29.9102,
    '1466_931': 29.8592,
    '1466_932': 29.817,
    '1466_933': 29.7793,
    '1466_934': 29.7455,
    '1466_935': 29.7147,
    '1466_936': 29.685,
    '1466_937': 29.6562,
    '1466_938': 29.6283,
    '1466_939': 29.6002,
    '1466_940': 29.5723,
    '1466_941': 29.5455,
    '1466_942': 29.5196,
    '1466_943': 29.4946,
    '1466_944': 29.4713,
    '1466_945': 29.4502,
    '1466_946': 29.43,
    '1466_947': 29.4114,
    '1466_948': 29.3924,
    '1466_949': 29.3689,
    '1466_950': 29.3395,
    '1466_951': 29.3056,
    '1466_952': 29.2592,
    '1466_953': 29.2045,
    '1466_954': 29.15,
    '1466_955': 29.1011,
    '1466_956': 29.0566,
    '1466_957': 29.0169,
    '1466_958': 28.98,
    '1466_959': 28.9457,
    '1466_960': 28.9204,
    '1466_961': 28.9162,
    '1466_962': 28.9171,
    '1466_963': 28.9142,
    '1466_964': 28.9035,
    '1466_965': 28.8827,
    '1466_966': 28.8577,
    '1466_967': 28.842,
    '1466_968': 28.8171,
    '1466_969': 28.7833,
    '1466_970': 28.7494,
    '1466_971': 28.7131,
    '1466_972': 28.6708,
    '1466_973': 28.624,
    '1466_974': 28.5724,
    '1466_975': 28.5125,
    '1466_976': 28.442,
    '1466_977': 28.364,
    '1466_978': 28.2853,
    '1466_979': 28.2138,
    '1466_980': 28.151,
    '1466_981': 28.0981,
    '1466_982': 28.0527,
    '1466_983': 28.0146,
    '1466_984': 27.9856,
    '1466_985': 27.9612,
    '1466_986': 27.9414,
    '1466_987': 27.9355,
    '1466_988': 27.9348,
    '1466_989': 27.9369,
    '1466_990': 27.9416,
    '1466_991': 27.9502,
    '1466_992': 27.9654,
    '1466_993': 27.984,
    '1466_994': 28.0038,
    '1466_995': 28.0186,
    '1466_996': 28.0286,
    '1466_997': 28.0376,
    '1466_998': 28.0571,
    '1466_999': 28.0732,
    '1466_1000': 28.0881,
    '1466_1001': 28.0996,
    '1466_1002': 28.1052,
    '1466_1003': 28.1046,
    '1466_1004': 28.0999,
    '1466_1005': 28.0922,
    '1466_1006': 28.087,
    '1466_1007': 28.0882,
    '1466_1008': 28.0936,
    '1466_1009': 28.1018,
    '1466_1010': 28.1136,
    '1466_1011': 28.1311,
    '1466_1012': 28.1516,
    '1466_1013': 28.1765,
    '1466_1014': 28.2033,
    '1466_1015': 28.2234,
    '1466_1016': 28.1994,
    '1466_1017': 28.161,
    '1466_1018': 28.116,
    '1466_1019': 28.0672,
    '1466_1020': 28.0142,
    '1466_1021': 27.9571,
    '1466_1022': 27.9118,
    '1467_850': 30.3145,
    '1467_851': 30.329,
    '1467_852': 30.3317,
    '1467_853': 30.3248,
    '1467_854': 30.3093,
    '1467_855': 30.2968,
    '1467_856': 30.2631,
    '1467_857': 30.2133,
    '1467_858': 30.1479,
    '1467_860': 29.9266,
    '1467_861': 29.8615,
    '1467_862': 29.7967,
    '1467_863': 29.7345,
    '1467_864': 29.6798,
    '1467_865': 29.6358,
    '1467_866': 29.6035,
    '1467_867': 29.5787,
    '1467_868': 29.5643,
    '1467_869': 29.5631,
    '1467_870': 29.5771,
    '1467_871': 29.6124,
    '1467_872': 29.6739,
    '1467_873': 29.7501,
    '1467_874': 29.83,
    '1467_875': 29.9091,
    '1467_876': 29.9905,
    '1467_877': 30.0678,
    '1467_878': 30.1396,
    '1467_879': 30.2078,
    '1467_880': 30.2725,
    '1467_881': 30.3388,
    '1467_882': 30.4085,
    '1467_883': 30.482,
    '1467_884': 30.5501,
    '1467_885': 30.6026,
    '1467_886': 30.6389,
    '1467_887': 30.666,
    '1467_888': 30.6838,
    '1467_889': 30.689,
    '1467_890': 30.6831,
    '1467_891': 30.6945,
    '1467_892': 30.7198,
    '1467_893': 30.7277,
    '1467_894': 30.7365,
    '1467_895': 30.7553,
    '1467_896': 30.7802,
    '1467_897': 30.8148,
    '1467_898': 30.8577,
    '1467_899': 30.9071,
    '1467_900': 30.9566,
    '1467_901': 30.9951,
    '1467_902': 31.0182,
    '1467_903': 31.0295,
    '1467_904': 31.0175,
    '1467_905': 30.9799,
    '1467_906': 30.9329,
    '1467_907': 30.8886,
    '1467_908': 30.8541,
    '1467_909': 30.8323,
    '1467_910': 30.8121,
    '1467_911': 30.7964,
    '1467_912': 30.7783,
    '1467_913': 30.7553,
    '1467_914': 30.7212,
    '1467_915': 30.6703,
    '1467_916': 30.6145,
    '1467_917': 30.5529,
    '1467_918': 30.4764,
    '1467_919': 30.3881,
    '1467_920': 30.3033,
    '1467_921': 30.2333,
    '1467_922': 30.1688,
    '1467_923': 30.1079,
    '1467_924': 30.0642,
    '1467_925': 30.0283,
    '1467_926': 29.9892,
    '1467_927': 29.9539,
    '1467_928': 29.9191,
    '1467_929': 29.8746,
    '1467_930': 29.8246,
    '1467_931': 29.7811,
    '1467_932': 29.7427,
    '1467_933': 29.7089,
    '1467_934': 29.6788,
    '1467_935': 29.6515,
    '1467_936': 29.6252,
    '1467_937': 29.5998,
    '1467_938': 29.575,
    '1467_939': 29.5501,
    '1467_940': 29.5252,
    '1467_941': 29.5012,
    '1467_942': 29.4778,
    '1467_943': 29.4548,
    '1467_944': 29.4325,
    '1467_945': 29.411,
    '1467_946': 29.3892,
    '1467_947': 29.3673,
    '1467_948': 29.3437,
    '1467_949': 29.3124,
    '1467_950': 29.2766,
    '1467_951': 29.2404,
    '1467_952': 29.1915,
    '1467_953': 29.1351,
    '1467_954': 29.0785,
    '1467_955': 29.0321,
    '1467_956': 28.989,
    '1467_957': 28.9524,
    '1467_958': 28.9197,
    '1467_959': 28.891,
    '1467_960': 28.8741,
    '1467_961': 28.8717,
    '1467_962': 28.8755,
    '1467_963': 28.8738,
    '1467_964': 28.8673,
    '1467_965': 28.8495,
    '1467_966': 28.8294,
    '1467_967': 28.8076,
    '1467_968': 28.7757,
    '1467_969': 28.7395,
    '1467_970': 28.7033,
    '1467_971': 28.6639,
    '1467_972': 28.6165,
    '1467_973': 28.5653,
    '1467_974': 28.5079,
    '1467_975': 28.4453,
    '1467_976': 28.3716,
    '1467_977': 28.288,
    '1467_978': 28.2033,
    '1467_979': 28.1307,
    '1467_980': 28.0668,
    '1467_981': 28.0148,
    '1467_982': 27.9685,
    '1467_983': 27.9276,
    '1467_984': 27.9027,
    '1467_985': 27.8832,
    '1467_986': 27.8641,
    '1467_987': 27.854,
    '1467_988': 27.8501,
    '1467_989': 27.8515,
    '1467_990': 27.8544,
    '1467_991': 27.86,
    '1467_992': 27.8751,
    '1467_993': 27.894,
    '1467_994': 27.9181,
    '1467_995': 27.9339,
    '1467_996': 27.9483,
    '1467_997': 27.9595,
    '1467_998': 27.9687,
    '1467_999': 27.9765,
    '1467_1000': 27.9859,
    '1467_1001': 27.9992,
    '1467_1002': 28.0039,
    '1467_1003': 28.0029,
    '1467_1004': 27.9971,
    '1467_1005': 27.9881,
    '1467_1006': 27.9799,
    '1467_1007': 27.9741,
    '1467_1008': 27.9719,
    '1467_1009': 27.9744,
    '1467_1010': 27.9812,
    '1467_1011': 27.9946,
    '1467_1012': 28.0126,
    '1467_1013': 28.0373,
    '1467_1014': 28.0633,
    '1467_1015': 28.0805,
    '1467_1016': 28.0735,
    '1467_1017': 28.0519,
    '1467_1018': 28.0199,
    '1467_1019': 27.9779,
    '1467_1020': 27.9272,
    '1467_1021': 27.8737,
    '1467_1022': 27.8269,
    '1468_850': 30.2804,
    '1468_851': 30.2865,
    '1468_852': 30.284,
    '1468_853': 30.2743,
    '1468_854': 30.2575,
    '1468_855': 30.2302,
    '1468_856': 30.1947,
    '1468_857': 30.1435,
    '1468_858': 30.0787,
    '1468_860': 29.8691,
    '1468_861': 29.801,
    '1468_862': 29.7353,
    '1468_863': 29.676,
    '1468_864': 29.6259,
    '1468_865': 29.5792,
    '1468_866': 29.5456,
    '1468_867': 29.5219,
    '1468_868': 29.5092,
    '1468_869': 29.5095,
    '1468_870': 29.5243,
    '1468_871': 29.56,
    '1468_872': 29.6197,
    '1468_873': 29.7,
    '1468_874': 29.7835,
    '1468_875': 29.869,
    '1468_876': 29.9524,
    '1468_877': 30.026,
    '1468_878': 30.0874,
    '1468_879': 30.1487,
    '1468_880': 30.2134,
    '1468_881': 30.2864,
    '1468_882': 30.3569,
    '1468_883': 30.4314,
    '1468_884': 30.505,
    '1468_885': 30.5637,
    '1468_886': 30.6035,
    '1468_887': 30.6323,
    '1468_888': 30.648,
    '1468_889': 30.6491,
    '1468_890': 30.6407,
    '1468_891': 30.6476,
    '1468_892': 30.6673,
    '1468_893': 30.6791,
    '1468_894': 30.6992,
    '1468_895': 30.7274,
    '1468_896': 30.761,
    '1468_897': 30.8021,
    '1468_898': 30.8452,
    '1468_899': 30.8936,
    '1468_900': 30.9418,
    '1468_901': 30.9809,
    '1468_902': 31.0064,
    '1468_903': 31.0147,
    '1468_904': 30.9996,
    '1468_905': 30.9617,
    '1468_906': 30.9118,
    '1468_907': 30.8676,
    '1468_908': 30.8305,
    '1468_909': 30.8037,
    '1468_910': 30.7784,
    '1468_911': 30.7533,
    '1468_912': 30.7219,
    '1468_913': 30.686,
    '1468_914': 30.6446,
    '1468_915': 30.5882,
    '1468_916': 30.5343,
    '1468_917': 30.4763,
    '1468_918': 30.402,
    '1468_919': 30.3158,
    '1468_920': 30.234,
    '1468_921': 30.1618,
    '1468_922': 30.0953,
    '1468_923': 30.0335,
    '1468_924': 29.983,
    '1468_925': 29.9429,
    '1468_926': 29.9044,
    '1468_927': 29.8653,
    '1468_928': 29.8275,
    '1468_929': 29.7825,
    '1468_930': 29.7405,
    '1468_931': 29.7022,
    '1468_932': 29.6683,
    '1468_933': 29.6385,
    '1468_934': 29.6125,
    '1468_935': 29.5892,
    '1468_936': 29.5665,
    '1468_937': 29.5441,
    '1468_938': 29.5222,
    '1468_939': 29.5001,
    '1468_940': 29.4779,
    '1468_941': 29.4563,
    '1468_942': 29.435,
    '1468_943': 29.4135,
    '1468_944': 29.3919,
    '1468_945': 29.3693,
    '1468_946': 29.345,
    '1468_947': 29.3193,
    '1468_948': 29.2909,
    '1468_949': 29.2567,
    '1468_950': 29.2182,
    '1468_951': 29.1774,
    '1468_952': 29.1296,
    '1468_953': 29.0765,
    '1468_954': 29.0238,
    '1468_955': 28.9772,
    '1468_956': 28.9349,
    '1468_957': 28.8989,
    '1468_958': 28.8694,
    '1468_959': 28.8452,
    '1468_960': 28.8303,
    '1468_961': 28.8254,
    '1468_962': 28.8258,
    '1468_963': 28.825,
    '1468_964': 28.8198,
    '1468_965': 28.8058,
    '1468_966': 28.7851,
    '1468_967': 28.7563,
    '1468_968': 28.7292,
    '1468_969': 28.6944,
    '1468_970': 28.651,
    '1468_971': 28.6024,
    '1468_972': 28.5476,
    '1468_973': 28.4892,
    '1468_974': 28.4276,
    '1468_975': 28.3617,
    '1468_976': 28.2891,
    '1468_977': 28.2089,
    '1468_978': 28.1249,
    '1468_979': 28.0497,
    '1468_980': 27.9855,
    '1468_981': 27.9339,
    '1468_982': 27.8896,
    '1468_983': 27.8495,
    '1468_984': 27.8207,
    '1468_985': 27.7983,
    '1468_986': 27.7807,
    '1468_987': 27.77,
    '1468_988': 27.7632,
    '1468_989': 27.7657,
    '1468_990': 27.7695,
    '1468_991': 27.7756,
    '1468_992': 27.7881,
    '1468_993': 27.8048,
    '1468_994': 27.8249,
    '1468_995': 27.8431,
    '1468_996': 27.8632,
    '1468_997': 27.8782,
    '1468_998': 27.8868,
    '1468_999': 27.8859,
    '1468_1000': 27.8848,
    '1468_1001': 27.9001,
    '1468_1002': 27.9049,
    '1468_1003': 27.8994,
    '1468_1004': 27.8895,
    '1468_1005': 27.8801,
    '1468_1006': 27.8685,
    '1468_1007': 27.8555,
    '1468_1008': 27.8468,
    '1468_1009': 27.8443,
    '1468_1010': 27.8454,
    '1468_1011': 27.8546,
    '1468_1012': 27.867,
    '1468_1013': 27.8882,
    '1468_1014': 27.9101,
    '1468_1015': 27.9247,
    '1468_1016': 27.9316,
    '1468_1017': 27.9287,
    '1468_1018': 27.9137,
    '1468_1019': 27.8794,
    '1468_1020': 27.8293,
    '1468_1021': 27.7765,
    '1468_1022': 27.7293,
    '1469_860': 29.807,
    '1469_861': 29.7376,
    '1469_862': 29.6704,
    '1469_863': 29.6117,
    '1469_864': 29.5626,
    '1469_865': 29.5208,
    '1469_866': 29.4892,
    '1469_867': 29.4671,
    '1469_868': 29.4557,
    '1469_869': 29.4571,
    '1469_870': 29.473,
    '1469_871': 29.5099,
    '1469_872': 29.573,
    '1469_873': 29.6517,
    '1469_874': 29.7377,
    '1469_875': 29.8265,
    '1469_876': 29.9071,
    '1469_877': 29.9749,
    '1469_878': 30.0293,
    '1469_879': 30.0846,
    '1469_880': 30.151,
    '1469_881': 30.226,
    '1469_882': 30.2977,
    '1469_883': 30.3742,
    '1469_884': 30.4508,
    '1469_885': 30.5129,
    '1469_886': 30.5568,
    '1469_887': 30.5895,
    '1469_888': 30.6098,
    '1469_889': 30.614,
    '1469_890': 30.6034,
    '1469_891': 30.6094,
    '1469_892': 30.6228,
    '1469_893': 30.6404,
    '1469_894': 30.6696,
    '1469_895': 30.7076,
    '1469_896': 30.746,
    '1469_897': 30.7853,
    '1469_898': 30.8283,
    '1469_899': 30.8727,
    '1469_900': 30.9182,
    '1469_901': 30.9571,
    '1469_902': 30.9829,
    '1469_903': 30.9889,
    '1469_904': 30.973,
    '1469_905': 30.9366,
    '1469_906': 30.8878,
    '1469_907': 30.8388,
    '1469_908': 30.7923,
    '1469_909': 30.7562,
    '1469_910': 30.7256,
    '1469_911': 30.6939,
    '1469_912': 30.6521,
    '1469_913': 30.6051,
    '1469_914': 30.5554,
    '1469_915': 30.4973,
    '1469_916': 30.4412,
    '1469_917': 30.3833,
    '1469_918': 30.3133,
    '1469_919': 30.2339,
    '1469_920': 30.1556,
    '1469_921': 30.0841,
    '1469_922': 30.0167,
    '1469_923': 29.9561,
    '1469_924': 29.9057,
    '1469_925': 29.8634,
    '1469_926': 29.8237,
    '1469_927': 29.7825,
    '1469_928': 29.7379,
    '1469_929': 29.6961,
    '1469_930': 29.6575,
    '1469_931': 29.6235,
    '1469_932': 29.5935,
    '1469_933': 29.5677,
    '1469_934': 29.5461,
    '1469_935': 29.5276,
    '1469_936': 29.5088,
    '1469_937': 29.4891,
    '1469_938': 29.4694,
    '1469_939': 29.45,
    '1469_940': 29.43,
    '1469_941': 29.4101,
    '1469_942': 29.3906,
    '1469_943': 29.371,
    '1469_944': 29.3501,
    '1469_945': 29.3262,
    '1469_946': 29.2994,
    '1469_947': 29.2709,
    '1469_948': 29.2397,
    '1469_949': 29.2038,
    '1469_950': 29.1633,
    '1469_951': 29.1212,
    '1469_952': 29.0743,
    '1469_953': 29.0243,
    '1469_954': 28.9751,
    '1469_955': 28.9295,
    '1469_956': 28.8881,
    '1469_957': 28.8522,
    '1469_958': 28.8244,
    '1469_959': 28.8012,
    '1469_960': 28.7851,
    '1469_961': 28.7771,
    '1469_962': 28.774,
    '1469_963': 28.7724,
    '1469_964': 28.7688,
    '1469_965': 28.7579,
    '1469_966': 28.7435,
    '1469_967': 28.7198,
    '1469_968': 28.6868,
    '1469_969': 28.6463,
    '1469_970': 28.5893,
    '1469_971': 28.5295,
    '1469_972': 28.4649,
    '1469_973': 28.3986,
    '1469_974': 28.3331,
    '1469_975': 28.2644,
    '1469_976': 28.1944,
    '1469_977': 28.1181,
    '1469_978': 28.033,
    '1469_979': 27.9565,
    '1469_980': 27.8921,
    '1469_981': 27.8427,
    '1469_982': 27.8017,
    '1469_983': 27.7599,
    '1469_984': 27.7289,
    '1469_985': 27.7034,
    '1469_986': 27.687,
    '1469_987': 27.6802,
    '1469_988': 27.6741,
    '1469_989': 27.6768,
    '1469_990': 27.6804,
    '1469_991': 27.6859,
    '1469_992': 27.6969,
    '1469_993': 27.7108,
    '1469_994': 27.7296,
    '1469_995': 27.7513,
    '1469_996': 27.7742,
    '1469_997': 27.7914,
    '1469_998': 27.8044,
    '1469_999': 27.8004,
    '1469_1000': 27.7987,
    '1469_1001': 27.8081,
    '1469_1002': 27.8063,
    '1469_1003': 27.7925,
    '1469_1004': 27.7769,
    '1469_1005': 27.7684,
    '1469_1006': 27.7518,
    '1469_1007': 27.7351,
    '1469_1008': 27.7257,
    '1469_1009': 27.7213,
    '1469_1010': 27.7223,
    '1469_1011': 27.7242,
    '1469_1012': 27.7314,
    '1469_1013': 27.7467,
    '1469_1014': 27.7595,
    '1469_1015': 27.7681,
    '1469_1016': 27.7823,
    '1469_1017': 27.7929,
    '1469_1018': 27.7942,
    '1469_1019': 27.7658,
    '1469_1020': 27.7138,
    '1469_1021': 27.6616,
    '1469_1022': 27.6165,
    '1470_860': 29.7467,
    '1470_861': 29.6762,
    '1470_862': 29.6063,
    '1470_863': 29.546,
    '1470_864': 29.5002,
    '1470_865': 29.4634,
    '1470_866': 29.4348,
    '1470_867': 29.4144,
    '1470_868': 29.4042,
    '1470_869': 29.4063,
    '1470_870': 29.4243,
    '1470_871': 29.4635,
    '1470_872': 29.5269,
    '1470_873': 29.6039,
    '1470_874': 29.6899,
    '1470_875': 29.7762,
    '1470_876': 29.8545,
    '1470_877': 29.9177,
    '1470_878': 29.9699,
    '1470_879': 30.02,
    '1470_880': 30.087,
    '1470_881': 30.1624,
    '1470_882': 30.2368,
    '1470_883': 30.3129,
    '1470_884': 30.3878,
    '1470_885': 30.4506,
    '1470_886': 30.4977,
    '1470_887': 30.5367,
    '1470_888': 30.5666,
    '1470_889': 30.5783,
    '1470_890': 30.5704,
    '1470_891': 30.5765,
    '1470_892': 30.5903,
    '1470_893': 30.6131,
    '1470_894': 30.6487,
    '1470_895': 30.695,
    '1470_896': 30.7413,
    '1470_897': 30.7816,
    '1470_898': 30.8237,
    '1470_899': 30.8606,
    '1470_900': 30.8919,
    '1470_901': 30.9225,
    '1470_902': 30.9449,
    '1470_903': 30.9489,
    '1470_904': 30.9335,
    '1470_905': 30.9026,
    '1470_906': 30.8588,
    '1470_907': 30.8074,
    '1470_908': 30.7525,
    '1470_909': 30.6996,
    '1470_910': 30.6588,
    '1470_911': 30.6238,
    '1470_912': 30.5711,
    '1470_913': 30.5118,
    '1470_914': 30.4573,
    '1470_915': 30.4014,
    '1470_916': 30.3442,
    '1470_917': 30.2862,
    '1470_918': 30.2196,
    '1470_919': 30.1469,
    '1470_920': 30.073,
    '1470_921': 30.0024,
    '1470_922': 29.9379,
    '1470_923': 29.8823,
    '1470_924': 29.8347,
    '1470_925': 29.793,
    '1470_926': 29.7517,
    '1470_927': 29.7041,
    '1470_928': 29.6587,
    '1470_929': 29.6157,
    '1470_930': 29.5787,
    '1470_931': 29.5477,
    '1470_932': 29.5211,
    '1470_933': 29.4987,
    '1470_934': 29.4811,
    '1470_935': 29.4687,
    '1470_936': 29.4542,
    '1470_937': 29.4355,
    '1470_938': 29.417,
    '1470_939': 29.4012,
    '1470_940': 29.3827,
    '1470_941': 29.3635,
    '1470_942': 29.3454,
    '1470_943': 29.3283,
    '1470_944': 29.3094,
    '1470_945': 29.2834,
    '1470_946': 29.2545,
    '1470_947': 29.2245,
    '1470_948': 29.1926,
    '1470_949': 29.157,
    '1470_950': 29.1165,
    '1470_951': 29.0723,
    '1470_952': 29.0249,
    '1470_953': 28.9766,
    '1470_954': 28.9301,
    '1470_955': 28.8873,
    '1470_956': 28.8486,
    '1470_957': 28.8148,
    '1470_958': 28.7858,
    '1470_959': 28.7605,
    '1470_960': 28.7411,
    '1470_961': 28.7305,
    '1470_962': 28.7237,
    '1470_963': 28.7185,
    '1470_964': 28.7117,
    '1470_965': 28.7016,
    '1470_966': 28.6914,
    '1470_967': 28.671,
    '1470_968': 28.6343,
    '1470_969': 28.5815,
    '1470_970': 28.5116,
    '1470_971': 28.4399,
    '1470_972': 28.3658,
    '1470_973': 28.2935,
    '1470_974': 28.2219,
    '1470_975': 28.1507,
    '1470_976': 28.0772,
    '1470_977': 27.9991,
    '1470_978': 27.9176,
    '1470_979': 27.8417,
    '1470_980': 27.7783,
    '1470_981': 27.7267,
    '1470_982': 27.6839,
    '1470_983': 27.6452,
    '1470_984': 27.6153,
    '1470_985': 27.5918,
    '1470_986': 27.5761,
    '1470_987': 27.5695,
    '1470_988': 27.5667,
    '1470_989': 27.5695,
    '1470_990': 27.5761,
    '1470_991': 27.5846,
    '1470_992': 27.595,
    '1470_993': 27.6075,
    '1470_994': 27.6251,
    '1470_995': 27.6457,
    '1470_996': 27.6673,
    '1470_997': 27.6855,
    '1470_998': 27.697,
    '1470_999': 27.6966,
    '1470_1000': 27.691,
    '1470_1001': 27.6847,
    '1470_1002': 27.6855,
    '1470_1003': 27.678,
    '1470_1004': 27.6642,
    '1470_1005': 27.65,
    '1470_1006': 27.6327,
    '1470_1007': 27.6167,
    '1470_1008': 27.6043,
    '1470_1009': 27.5962,
    '1470_1010': 27.5933,
    '1470_1011': 27.5919,
    '1470_1012': 27.5946,
    '1470_1013': 27.6047,
    '1470_1014': 27.614,
    '1470_1015': 27.6208,
    '1470_1016': 27.6298,
    '1470_1017': 27.6368,
    '1470_1018': 27.635,
    '1470_1019': 27.6122,
    '1470_1020': 27.5691,
    '1470_1021': 27.5232,
    '1470_1022': 27.4888,
    '1471_860': 29.6859,
    '1471_861': 29.621,
    '1471_862': 29.5511,
    '1471_863': 29.4889,
    '1471_864': 29.444,
    '1471_865': 29.4078,
    '1471_866': 29.3797,
    '1471_867': 29.36,
    '1471_868': 29.3505,
    '1471_869': 29.3533,
    '1471_870': 29.3718,
    '1471_871': 29.4109,
    '1471_872': 29.4743,
    '1471_873': 29.5514,
    '1471_874': 29.6335,
    '1471_875': 29.7155,
    '1471_876': 29.7899,
    '1471_877': 29.8524,
    '1471_878': 29.9016,
    '1471_879': 29.953,
    '1471_880': 30.0226,
    '1471_881': 30.0968,
    '1471_882': 30.1701,
    '1471_883': 30.2411,
    '1471_884': 30.3151,
    '1471_885': 30.3791,
    '1471_886': 30.4311,
    '1471_887': 30.4792,
    '1471_888': 30.5182,
    '1471_889': 30.5359,
    '1471_890': 30.5322,
    '1471_891': 30.5386,
    '1471_892': 30.5597,
    '1471_893': 30.5861,
    '1471_894': 30.629,
    '1471_895': 30.6817,
    '1471_896': 30.7357,
    '1471_897': 30.7869,
    '1471_898': 30.8291,
    '1471_899': 30.8582,
    '1471_900': 30.8724,
    '1471_901': 30.8845,
    '1471_902': 30.8934,
    '1471_903': 30.8945,
    '1471_904': 30.8826,
    '1471_905': 30.8605,
    '1471_906': 30.8239,
    '1471_907': 30.7742,
    '1471_908': 30.714,
    '1471_909': 30.6506,
    '1471_910': 30.5965,
    '1471_911': 30.5532,
    '1471_912': 30.4927,
    '1471_913': 30.4246,
    '1471_914': 30.3635,
    '1471_915': 30.3049,
    '1471_916': 30.2494,
    '1471_917': 30.1931,
    '1471_918': 30.1282,
    '1471_919': 30.0596,
    '1471_920': 29.9905,
    '1471_921': 29.9248,
    '1471_922': 29.8643,
    '1471_923': 29.8133,
    '1471_924': 29.7708,
    '1471_925': 29.7254,
    '1471_926': 29.6814,
    '1471_927': 29.6328,
    '1471_928': 29.5846,
    '1471_929': 29.539,
    '1471_930': 29.5027,
    '1471_931': 29.4738,
    '1471_932': 29.4496,
    '1471_933': 29.4291,
    '1471_934': 29.4139,
    '1471_935': 29.4079,
    '1471_936': 29.3976,
    '1471_937': 29.3782,
    '1471_938': 29.3599,
    '1471_939': 29.3494,
    '1471_940': 29.3311,
    '1471_941': 29.3112,
    '1471_942': 29.2933,
    '1471_943': 29.2789,
    '1471_944': 29.2641,
    '1471_945': 29.2351,
    '1471_946': 29.2054,
    '1471_947': 29.1752,
    '1471_948': 29.1433,
    '1471_949': 29.1076,
    '1471_950': 29.0651,
    '1471_951': 29.0213,
    '1471_952': 28.9744,
    '1471_953': 28.9277,
    '1471_954': 28.8839,
    '1471_955': 28.8443,
    '1471_956': 28.809,
    '1471_957': 28.7779,
    '1471_958': 28.7484,
    '1471_959': 28.7212,
    '1471_960': 28.6987,
    '1471_961': 28.6878,
    '1471_962': 28.6773,
    '1471_963': 28.6678,
    '1471_964': 28.6575,
    '1471_965': 28.6443,
    '1471_966': 28.6395,
    '1471_967': 28.6216,
    '1471_968': 28.5812,
    '1471_969': 28.519,
    '1471_970': 28.4326,
    '1471_971': 28.3513,
    '1471_972': 28.2662,
    '1471_973': 28.1874,
    '1471_974': 28.1084,
    '1471_975': 28.0335,
    '1471_976': 27.9561,
    '1471_977': 27.8754,
    '1471_978': 27.7952,
    '1471_979': 27.7185,
    '1471_980': 27.6566,
    '1471_981': 27.6029,
    '1471_982': 27.5594,
    '1471_983': 27.5209,
    '1471_984': 27.4936,
    '1471_985': 27.4715,
    '1471_986': 27.4564,
    '1471_987': 27.4519,
    '1471_988': 27.4521,
    '1471_989': 27.4575,
    '1471_990': 27.4666,
    '1471_991': 27.4775,
    '1471_992': 27.4874,
    '1471_993': 27.4985,
    '1471_994': 27.5157,
    '1471_995': 27.5341,
    '1471_996': 27.5542,
    '1471_997': 27.5727,
    '1471_998': 27.5846,
    '1471_999': 27.5877,
    '1471_1000': 27.5847,
    '1471_1001': 27.5778,
    '1471_1002': 27.5735,
    '1471_1003': 27.5662,
    '1471_1004': 27.5517,
    '1471_1005': 27.5343,
    '1471_1006': 27.5154,
    '1471_1007': 27.5004,
    '1471_1008': 27.4858,
    '1471_1009': 27.473,
    '1471_1010': 27.4674,
    '1471_1011': 27.4624,
    '1471_1012': 27.4596,
    '1471_1013': 27.4672,
    '1471_1014': 27.4733,
    '1471_1015': 27.475,
    '1471_1016': 27.4759,
    '1471_1017': 27.4756,
    '1471_1018': 27.4714,
    '1471_1019': 27.4529,
    '1471_1020': 27.4153,
    '1471_1021': 27.374,
    '1471_1022': 27.3539,
    '1472_860': 29.6265,
    '1472_861': 29.5616,
    '1472_862': 29.4951,
    '1472_863': 29.4345,
    '1472_864': 29.3859,
    '1472_865': 29.3505,
    '1472_866': 29.3221,
    '1472_867': 29.3024,
    '1472_868': 29.2935,
    '1472_869': 29.297,
    '1472_870': 29.3162,
    '1472_871': 29.355,
    '1472_872': 29.414,
    '1472_873': 29.488,
    '1472_874': 29.5661,
    '1472_875': 29.6428,
    '1472_876': 29.7174,
    '1472_877': 29.782,
    '1472_878': 29.8296,
    '1472_879': 29.8818,
    '1472_880': 29.9525,
    '1472_881': 30.025,
    '1472_882': 30.0949,
    '1472_883': 30.1622,
    '1472_884': 30.2373,
    '1472_885': 30.3062,
    '1472_886': 30.3663,
    '1472_887': 30.426,
    '1472_888': 30.4716,
    '1472_889': 30.4897,
    '1472_890': 30.4902,
    '1472_891': 30.5035,
    '1472_892': 30.5298,
    '1472_893': 30.5617,
    '1472_894': 30.6103,
    '1472_895': 30.6684,
    '1472_896': 30.7297,
    '1472_897': 30.7887,
    '1472_898': 30.8345,
    '1472_899': 30.8579,
    '1472_900': 30.8622,
    '1472_901': 30.8547,
    '1472_902': 30.845,
    '1472_903': 30.8382,
    '1472_904': 30.8321,
    '1472_905': 30.8209,
    '1472_906': 30.7918,
    '1472_907': 30.7442,
    '1472_908': 30.6794,
    '1472_909': 30.6116,
    '1472_910': 30.5453,
    '1472_911': 30.4877,
    '1472_912': 30.4264,
    '1472_913': 30.3556,
    '1472_914': 30.286,
    '1472_915': 30.2201,
    '1472_916': 30.1624,
    '1472_917': 30.104,
    '1472_918': 30.0395,
    '1472_919': 29.9751,
    '1472_920': 29.9104,
    '1472_921': 29.8482,
    '1472_922': 29.7939,
    '1472_923': 29.7499,
    '1472_924': 29.708,
    '1472_925': 29.6597,
    '1472_926': 29.6114,
    '1472_927': 29.5633,
    '1472_928': 29.5168,
    '1472_929': 29.4731,
    '1472_930': 29.4355,
    '1472_931': 29.405,
    '1472_932': 29.3811,
    '1472_933': 29.362,
    '1472_934': 29.3481,
    '1472_935': 29.3389,
    '1472_936': 29.3281,
    '1472_937': 29.3152,
    '1472_938': 29.3009,
    '1472_939': 29.288,
    '1472_940': 29.273,
    '1472_941': 29.2521,
    '1472_942': 29.2311,
    '1472_943': 29.2164,
    '1472_944': 29.201,
    '1472_945': 29.1778,
    '1472_946': 29.1511,
    '1472_947': 29.1222,
    '1472_948': 29.089,
    '1472_949': 29.0498,
    '1472_950': 29.0051,
    '1472_951': 28.9625,
    '1472_952': 28.9194,
    '1472_953': 28.8761,
    '1472_954': 28.8351,
    '1472_955': 28.799,
    '1472_956': 28.766,
    '1472_957': 28.7362,
    '1472_958': 28.7102,
    '1472_959': 28.6869,
    '1472_960': 28.6687,
    '1472_961': 28.6564,
    '1472_962': 28.6415,
    '1472_963': 28.6284,
    '1472_964': 28.6174,
    '1472_965': 28.6059,
    '1472_966': 28.5967,
    '1472_967': 28.5736,
    '1472_968': 28.5316,
    '1472_969': 28.4681,
    '1472_970': 28.38,
    '1472_971': 28.2864,
    '1472_972': 28.1906,
    '1472_973': 28.0985,
    '1472_974': 28.0104,
    '1472_975': 27.9273,
    '1472_976': 27.8455,
    '1472_977': 27.7641,
    '1472_978': 27.685,
    '1472_979': 27.611,
    '1472_980': 27.5478,
    '1472_981': 27.4931,
    '1472_982': 27.4468,
    '1472_983': 27.4073,
    '1472_984': 27.3767,
    '1472_985': 27.3542,
    '1472_986': 27.3419,
    '1472_987': 27.3392,
    '1472_988': 27.3424,
    '1472_989': 27.3498,
    '1472_990': 27.3601,
    '1472_991': 27.3718,
    '1472_992': 27.3833,
    '1472_993': 27.3963,
    '1472_994': 27.4122,
    '1472_995': 27.4293,
    '1472_996': 27.447,
    '1472_997': 27.4628,
    '1472_998': 27.4737,
    '1472_999': 27.4783,
    '1472_1000': 27.4773,
    '1472_1001': 27.4724,
    '1472_1002': 27.4664,
    '1472_1003': 27.4582,
    '1472_1004': 27.4448,
    '1472_1005': 27.4279,
    '1472_1006': 27.4104,
    '1472_1007': 27.394,
    '1472_1008': 27.3781,
    '1472_1009': 27.3645,
    '1472_1010': 27.3546,
    '1472_1011': 27.3466,
    '1472_1012': 27.3415,
    '1472_1013': 27.342,
    '1472_1014': 27.342,
    '1472_1015': 27.3363,
    '1472_1016': 27.3295,
    '1472_1017': 27.3257,
    '1472_1018': 27.3176,
    '1472_1019': 27.3004,
    '1472_1020': 27.2726,
    '1472_1021': 27.2434,
    '1472_1022': 27.2243,
    '1473_860': 29.5687,
    '1473_861': 29.5027,
    '1473_862': 29.4378,
    '1473_863': 29.378,
    '1473_864': 29.3278,
    '1473_865': 29.2891,
    '1473_866': 29.2604,
    '1473_867': 29.241,
    '1473_868': 29.2322,
    '1473_869': 29.2359,
    '1473_870': 29.2548,
    '1473_871': 29.2919,
    '1473_872': 29.3449,
    '1473_873': 29.4139,
    '1473_874': 29.4903,
    '1473_875': 29.5676,
    '1473_876': 29.6431,
    '1473_877': 29.7091,
    '1473_878': 29.7596,
    '1473_879': 29.8106,
    '1473_880': 29.8795,
    '1473_881': 29.9484,
    '1473_882': 30.0166,
    '1473_883': 30.0835,
    '1473_884': 30.1598,
    '1473_885': 30.236,
    '1473_886': 30.3099,
    '1473_887': 30.3805,
    '1473_888': 30.4294,
    '1473_889': 30.4476,
    '1473_890': 30.4534,
    '1473_891': 30.4705,
    '1473_892': 30.4971,
    '1473_893': 30.5347,
    '1473_894': 30.5937,
    '1473_895': 30.6555,
    '1473_896': 30.7162,
    '1473_897': 30.7769,
    '1473_898': 30.824,
    '1473_899': 30.8481,
    '1473_900': 30.848,
    '1473_901': 30.8299,
    '1473_902': 30.8072,
    '1473_903': 30.7913,
    '1473_904': 30.7883,
    '1473_905': 30.7842,
    '1473_906': 30.7617,
    '1473_907': 30.7156,
    '1473_908': 30.649,
    '1473_909': 30.5793,
    '1473_910': 30.5056,
    '1473_911': 30.429,
    '1473_912': 30.3613,
    '1473_913': 30.2873,
    '1473_914': 30.2165,
    '1473_915': 30.1444,
    '1473_916': 30.0807,
    '1473_917': 30.0186,
    '1473_918': 29.9539,
    '1473_919': 29.893,
    '1473_920': 29.8322,
    '1473_921': 29.7754,
    '1473_922': 29.7261,
    '1473_923': 29.6821,
    '1473_924': 29.6361,
    '1473_925': 29.5881,
    '1473_926': 29.5398,
    '1473_927': 29.4942,
    '1473_928': 29.4505,
    '1473_929': 29.4092,
    '1473_930': 29.3706,
    '1473_931': 29.3384,
    '1473_932': 29.3153,
    '1473_933': 29.2968,
    '1473_934': 29.2835,
    '1473_935': 29.2723,
    '1473_936': 29.2604,
    '1473_937': 29.2538,
    '1473_938': 29.2423,
    '1473_939': 29.2283,
    '1473_940': 29.218,
    '1473_941': 29.1933,
    '1473_942': 29.1663,
    '1473_943': 29.152,
    '1473_944': 29.1375,
    '1473_945': 29.1172,
    '1473_946': 29.0941,
    '1473_947': 29.0674,
    '1473_948': 29.0334,
    '1473_949': 28.991,
    '1473_950': 28.9421,
    '1473_951': 28.9023,
    '1473_952': 28.8642,
    '1473_953': 28.8244,
    '1473_954': 28.7862,
    '1473_955': 28.7542,
    '1473_956': 28.7234,
    '1473_957': 28.6944,
    '1473_958': 28.671,
    '1473_959': 28.6504,
    '1473_960': 28.6348,
    '1473_961': 28.6236,
    '1473_962': 28.6016,
    '1473_963': 28.5841,
    '1473_964': 28.5725,
    '1473_965': 28.5593,
    '1473_966': 28.5509,
    '1473_967': 28.5234,
    '1473_968': 28.481,
    '1473_969': 28.4206,
    '1473_970': 28.328,
    '1473_971': 28.2269,
    '1473_972': 28.1201,
    '1473_973': 28.0159,
    '1473_974': 27.9186,
    '1473_975': 27.8293,
    '1473_976': 27.7447,
    '1473_977': 27.663,
    '1473_978': 27.585,
    '1473_979': 27.512,
    '1473_980': 27.4484,
    '1473_981': 27.3924,
    '1473_982': 27.3435,
    '1473_983': 27.3013,
    '1473_984': 27.2667,
    '1473_985': 27.2418,
    '1473_986': 27.232,
    '1473_987': 27.2319,
    '1473_988': 27.2376,
    '1473_989': 27.2467,
    '1473_990': 27.258,
    '1473_991': 27.2704,
    '1473_992': 27.2834,
    '1473_993': 27.2975,
    '1473_994': 27.3126,
    '1473_995': 27.3283,
    '1473_996': 27.3436,
    '1473_997': 27.3571,
    '1473_998': 27.3668,
    '1473_999': 27.3718,
    '1473_1000': 27.372,
    '1473_1001': 27.3685,
    '1473_1002': 27.3628,
    '1473_1003': 27.3552,
    '1473_1004': 27.3432,
    '1473_1005': 27.3276,
    '1473_1006': 27.3111,
    '1473_1007': 27.2942,
    '1473_1008': 27.2776,
    '1473_1009': 27.2627,
    '1473_1010': 27.2499,
    '1473_1011': 27.2388,
    '1473_1012': 27.2301,
    '1473_1013': 27.2243,
    '1473_1014': 27.2176,
    '1473_1015': 27.2081,
    '1473_1016': 27.1984,
    '1473_1017': 27.189,
    '1473_1018': 27.1769,
    '1473_1019': 27.1601,
    '1473_1020': 27.1384,
    '1473_1021': 27.1162,
    '1473_1022': 27.0984,
    '1474_860': 29.5111,
    '1474_861': 29.4441,
    '1474_862': 29.379,
    '1474_863': 29.3188,
    '1474_864': 29.2667,
    '1474_865': 29.2259,
    '1474_866': 29.1962,
    '1474_867': 29.1764,
    '1474_868': 29.1672,
    '1474_869': 29.17,
    '1474_870': 29.1867,
    '1474_871': 29.2197,
    '1474_872': 29.2708,
    '1474_873': 29.3376,
    '1474_874': 29.413,
    '1474_875': 29.4912,
    '1474_876': 29.5687,
    '1474_877': 29.6344,
    '1474_878': 29.6836,
    '1474_879': 29.7361,
    '1474_880': 29.8036,
    '1474_881': 29.8719,
    '1474_882': 29.9414,
    '1474_883': 30.0103,
    '1474_884': 30.091,
    '1474_885': 30.1729,
    '1474_886': 30.2623,
    '1474_887': 30.3399,
    '1474_888': 30.3888,
    '1474_889': 30.4087,
    '1474_890': 30.4191,
    '1474_891': 30.436,
    '1474_892': 30.4621,
    '1474_893': 30.5089,
    '1474_894': 30.5781,
    '1474_895': 30.6402,
    '1474_896': 30.6991,
    '1474_897': 30.7537,
    '1474_898': 30.7965,
    '1474_899': 30.8175,
    '1474_900': 30.8162,
    '1474_901': 30.7935,
    '1474_902': 30.766,
    '1474_903': 30.7475,
    '1474_904': 30.7462,
    '1474_905': 30.7461,
    '1474_906': 30.727,
    '1474_907': 30.6817,
    '1474_908': 30.6159,
    '1474_909': 30.5409,
    '1474_910': 30.4613,
    '1474_911': 30.3767,
    '1474_912': 30.3032,
    '1474_913': 30.2193,
    '1474_914': 30.1379,
    '1474_915': 30.0636,
    '1474_916': 30.0002,
    '1474_917': 29.9402,
    '1474_918': 29.8765,
    '1474_919': 29.817,
    '1474_920': 29.7594,
    '1474_921': 29.7066,
    '1474_922': 29.657,
    '1474_923': 29.6115,
    '1474_924': 29.5649,
    '1474_925': 29.5172,
    '1474_926': 29.4703,
    '1474_927': 29.4262,
    '1474_928': 29.3846,
    '1474_929': 29.3441,
    '1474_930': 29.3073,
    '1474_931': 29.277,
    '1474_932': 29.2547,
    '1474_933': 29.2374,
    '1474_934': 29.2249,
    '1474_935': 29.2159,
    '1474_936': 29.2079,
    '1475_860': 29.4527,
    '1475_861': 29.3844,
    '1475_862': 29.3186,
    '1475_863': 29.2578,
    '1475_864': 29.2044,
    '1475_865': 29.1611,
    '1475_866': 29.1301,
    '1475_867': 29.1094,
    '1475_868': 29.0992,
    '1475_869': 29.1003,
    '1475_870': 29.1142,
    '1475_871': 29.1434,
    '1475_872': 29.1932,
    '1475_873': 29.2599,
    '1475_874': 29.337,
    '1475_875': 29.4132,
    '1475_876': 29.4894,
    '1475_877': 29.5561,
    '1475_878': 29.6063,
    '1475_879': 29.6667,
    '1475_880': 29.7339,
    '1475_881': 29.8016,
    '1475_882': 29.8698,
    '1475_883': 29.9426,
    '1475_884': 30.0286,
    '1475_885': 30.1187,
    '1475_886': 30.2185,
    '1475_887': 30.2998,
    '1475_888': 30.3453,
    '1475_889': 30.3663,
    '1475_890': 30.3827,
    '1475_891': 30.4013,
    '1475_892': 30.4289,
    '1475_893': 30.4835,
    '1475_894': 30.5583,
    '1475_895': 30.6265,
    '1475_896': 30.6862,
    '1475_897': 30.7314,
    '1475_898': 30.7642,
    '1475_899': 30.7795,
    '1475_900': 30.7742,
    '1475_901': 30.7505,
    '1475_902': 30.7236,
    '1475_903': 30.7051,
    '1475_904': 30.7004,
    '1475_905': 30.6984,
    '1475_906': 30.6783,
    '1475_907': 30.6328,
    '1475_908': 30.5705,
    '1475_909': 30.4968,
    '1475_910': 30.4101,
    '1475_911': 30.3191,
    '1475_912': 30.2385,
    '1475_913': 30.1515,
    '1475_914': 30.0625,
    '1475_915': 29.9843,
    '1475_916': 29.9201,
    '1475_917': 29.8621,
    '1475_918': 29.8021,
    '1475_919': 29.7429,
    '1475_920': 29.6883,
    '1475_921': 29.6362,
    '1475_922': 29.5891,
    '1475_923': 29.5438,
    '1475_924': 29.4951,
    '1475_925': 29.4471,
    '1475_926': 29.4011,
    '1475_927': 29.3588,
    '1475_928': 29.3196,
    '1475_929': 29.2802,
    '1475_930': 29.2453,
    '1475_931': 29.2166,
    '1475_932': 29.1969,
    '1475_933': 29.1809,
    '1475_934': 29.1702,
    '1475_935': 29.1648,
    '1475_936': 29.1608,
    '1476_860': 29.3918,
    '1476_861': 29.3221,
    '1476_862': 29.2555,
    '1476_863': 29.194,
    '1476_864': 29.1396,
    '1476_865': 29.0945,
    '1476_866': 29.062,
    '1476_867': 29.0401,
    '1476_868': 29.0287,
    '1476_869': 29.0283,
    '1476_870': 29.04,
    '1476_871': 29.0662,
    '1476_872': 29.1119,
    '1476_873': 29.178,
    '1476_874': 29.2539,
    '1476_875': 29.3302,
    '1476_876': 29.4061,
    '1476_877': 29.4747,
    '1476_878': 29.529,
    '1476_879': 29.5932,
    '1476_880': 29.6621,
    '1476_881': 29.7301,
    '1476_882': 29.7996,
    '1476_883': 29.8837,
    '1476_884': 29.9747,
    '1476_885': 30.0694,
    '1476_886': 30.1714,
    '1476_887': 30.2565,
    '1476_888': 30.3086,
    '1476_889': 30.3291,
    '1476_890': 30.3449,
    '1476_891': 30.3634,
    '1476_892': 30.395,
    '1476_893': 30.4582,
    '1476_894': 30.5388,
    '1476_895': 30.6147,
    '1476_896': 30.6768,
    '1476_897': 30.7149,
    '1476_898': 30.7361,
    '1476_899': 30.7416,
    '1476_900': 30.7294,
    '1476_901': 30.7059,
    '1476_902': 30.6812,
    '1476_903': 30.6606,
    '1476_904': 30.6452,
    '1476_905': 30.6354,
    '1476_906': 30.6119,
    '1476_907': 30.5678,
    '1476_908': 30.5128,
    '1476_909': 30.4399,
    '1476_910': 30.3503,
    '1476_911': 30.256,
    '1476_912': 30.1725,
    '1476_913': 30.0833,
    '1476_914': 29.9923,
    '1476_915': 29.9111,
    '1476_916': 29.844,
    '1476_917': 29.7856,
    '1476_918': 29.7254,
    '1476_919': 29.6689,
    '1476_920': 29.6149,
    '1476_921': 29.5652,
    '1476_922': 29.5179,
    '1476_923': 29.4711,
    '1476_924': 29.4232,
    '1476_925': 29.376,
    '1476_926': 29.3307,
    '1476_927': 29.2893,
    '1476_928': 29.2528,
    '1476_929': 29.2182,
    '1476_930': 29.1852,
    '1476_931': 29.1579,
    '1476_932': 29.1387,
    '1476_933': 29.1248,
    '1476_934': 29.1176,
    '1476_935': 29.1138,
    '1476_936': 29.1109,
    '1477_860': 29.327,
    '1477_861': 29.2568,
    '1477_862': 29.1899,
    '1477_863': 29.1281,
    '1477_864': 29.0732,
    '1477_865': 29.0271,
    '1477_866': 28.9929,
    '1477_867': 28.9698,
    '1477_868': 28.9575,
    '1477_869': 28.956,
    '1477_870': 28.9661,
    '1477_871': 28.9899,
    '1477_872': 29.0324,
    '1477_873': 29.0972,
    '1477_874': 29.1743,
    '1477_875': 29.2503,
    '1477_876': 29.3245,
    '1477_877': 29.3922,
    '1477_878': 29.4525,
    '1477_879': 29.5229,
    '1477_880': 29.5938,
    '1477_881': 29.6619,
    '1477_882': 29.7354,
    '1477_883': 29.8262,
    '1477_884': 29.9258,
    '1477_885': 30.0309,
    '1477_886': 30.1317,
    '1477_887': 30.2177,
    '1477_888': 30.2734,
    '1477_889': 30.2996,
    '1477_890': 30.3153,
    '1477_891': 30.3332,
    '1477_892': 30.3692,
    '1477_893': 30.437,
    '1477_894': 30.5199,
    '1477_895': 30.5997,
    '1477_896': 30.6617,
    '1477_897': 30.6963,
    '1477_898': 30.7071,
    '1477_899': 30.6999,
    '1477_900': 30.6813,
    '1477_901': 30.6578,
    '1477_902': 30.6345,
    '1477_903': 30.6085,
    '1477_904': 30.5819,
    '1477_905': 30.5601,
    '1477_906': 30.5309,
    '1477_907': 30.4893,
    '1477_908': 30.4406,
    '1477_909': 30.3705,
    '1477_910': 30.284,
    '1477_911': 30.1878,
    '1477_912': 30.098,
    '1477_913': 30.0103,
    '1477_914': 29.9211,
    '1477_915': 29.8396,
    '1477_916': 29.7695,
    '1477_917': 29.7099,
    '1477_918': 29.6506,
    '1477_919': 29.5943,
    '1477_920': 29.5427,
    '1477_921': 29.4938,
    '1477_922': 29.4462,
    '1477_923': 29.3984,
    '1477_924': 29.3509,
    '1477_925': 29.3046,
    '1477_926': 29.2594,
    '1477_927': 29.2185,
    '1477_928': 29.1854,
    '1477_929': 29.1556,
    '1477_930': 29.124,
    '1477_931': 29.0985,
    '1477_932': 29.0812,
    '1477_933': 29.0704,
    '1477_934': 29.0681,
    '1477_935': 29.066,
    '1477_936': 29.0626,
    '1478_860': 29.2585,
    '1478_861': 29.1888,
    '1478_862': 29.1222,
    '1478_863': 29.0603,
    '1478_864': 29.0051,
    '1478_865': 28.9581,
    '1478_866': 28.9223,
    '1478_867': 28.8987,
    '1478_868': 28.8861,
    '1478_869': 28.8844,
    '1478_870': 28.894,
    '1478_871': 28.9169,
    '1478_872': 28.9577,
    '1478_873': 29.0195,
    '1478_874': 29.0944,
    '1478_875': 29.1685,
    '1478_876': 29.2412,
    '1478_877': 29.3076,
    '1478_878': 29.3763,
    '1478_879': 29.4532,
    '1478_880': 29.5268,
    '1478_881': 29.5953,
    '1478_882': 29.6743,
    '1478_883': 29.7739,
    '1478_884': 29.8882,
    '1478_885': 30.0039,
    '1478_886': 30.1041,
    '1478_887': 30.1842,
    '1478_888': 30.235,
    '1478_889': 30.2687,
    '1478_890': 30.2851,
    '1478_891': 30.3021,
    '1478_892': 30.3459,
    '1478_893': 30.415,
    '1478_894': 30.4968,
    '1478_895': 30.5767,
    '1478_896': 30.6379,
    '1478_897': 30.6677,
    '1478_898': 30.6705,
    '1478_899': 30.6521,
    '1478_900': 30.6237,
    '1478_901': 30.5961,
    '1478_902': 30.5693,
    '1478_903': 30.5388,
    '1478_904': 30.5096,
    '1478_905': 30.4787,
    '1478_906': 30.4429,
    '1478_907': 30.4017,
    '1478_908': 30.3568,
    '1478_909': 30.2896,
    '1478_910': 30.2086,
    '1478_911': 30.1136,
    '1478_912': 30.0165,
    '1478_913': 29.9279,
    '1478_914': 29.8463,
    '1478_915': 29.766,
    '1478_916': 29.6938,
    '1478_917': 29.632,
    '1478_918': 29.5742,
    '1478_919': 29.5191,
    '1478_920': 29.4693,
    '1478_921': 29.4212,
    '1478_922': 29.3738,
    '1478_923': 29.3252,
    '1478_924': 29.2774,
    '1478_925': 29.2313,
    '1478_926': 29.1872,
    '1478_927': 29.1478,
    '1478_928': 29.1144,
    '1478_929': 29.0848,
    '1478_930': 29.0578,
    '1478_931': 29.0376,
    '1478_932': 29.0255,
    '1478_933': 29.0206,
    '1478_934': 29.024,
    '1478_935': 29.0249,
    '1478_936': 29.0219,
    '1479_860': 29.1881,
    '1479_861': 29.119,
    '1479_862': 29.0527,
    '1479_863': 28.9911,
    '1479_864': 28.9356,
    '1479_865': 28.8878,
    '1479_866': 28.8506,
    '1479_867': 28.8273,
    '1479_868': 28.8151,
    '1479_869': 28.8134,
    '1479_870': 28.8229,
    '1479_871': 28.8452,
    '1479_872': 28.8843,
    '1479_873': 28.9435,
    '1479_874': 29.0149,
    '1479_875': 29.0863,
    '1479_876': 29.156,
    '1479_877': 29.22,
    '1479_878': 29.2975,
    '1479_879': 29.3803,
    '1479_880': 29.4586,
    '1479_881': 29.5311,
    '1479_882': 29.617,
    '1479_883': 29.7278,
    '1479_884': 29.8517,
    '1479_885': 29.9745,
    '1479_886': 30.078,
    '1479_887': 30.1491,
    '1479_888': 30.1955,
    '1479_889': 30.2311,
    '1479_890': 30.2465,
    '1479_891': 30.2666,
    '1479_892': 30.3116,
    '1479_893': 30.3796,
    '1479_894': 30.4649,
    '1479_895': 30.5451,
    '1479_896': 30.602,
    '1479_897': 30.6281,
    '1479_898': 30.6234,
    '1479_899': 30.5979,
    '1479_900': 30.5603,
    '1479_901': 30.5227,
    '1479_902': 30.4895,
    '1479_903': 30.4568,
    '1479_904': 30.4278,
    '1479_905': 30.3934,
    '1479_906': 30.3552,
    '1479_907': 30.3098,
    '1479_908': 30.2632,
    '1479_909': 30.1994,
    '1479_910': 30.1236,
    '1479_911': 30.0295,
    '1479_912': 29.933,
    '1479_913': 29.8455,
    '1479_914': 29.7657,
    '1479_915': 29.6899,
    '1479_916': 29.6184,
    '1479_917': 29.5537,
    '1479_918': 29.4936,
    '1479_919': 29.4405,
    '1479_920': 29.3928,
    '1479_921': 29.3457,
    '1479_922': 29.2999,
    '1479_923': 29.2509,
    '1479_924': 29.2028,
    '1479_925': 29.1569,
    '1479_926': 29.114,
    '1479_927': 29.0758,
    '1479_928': 29.0426,
    '1479_929': 29.0131,
    '1479_930': 28.9889,
    '1479_931': 28.9748,
    '1479_932': 28.9683,
    '1479_933': 28.9667,
    '1479_934': 28.9747,
    '1479_935': 28.9813,
    '1479_936': 28.9777,
    '1480_860': 29.1172,
    '1480_861': 29.0485,
    '1480_862': 28.9827,
    '1480_863': 28.9215,
    '1480_864': 28.8662,
    '1480_865': 28.8187,
    '1480_866': 28.7817,
    '1480_867': 28.7578,
    '1480_868': 28.7453,
    '1480_869': 28.7435,
    '1480_870': 28.7528,
    '1480_871': 28.7744,
    '1480_872': 28.8125,
    '1480_873': 28.8682,
    '1480_874': 28.935,
    '1480_875': 29.0021,
    '1480_876': 29.0638,
    '1480_877': 29.1275,
    '1480_878': 29.2141,
    '1480_879': 29.3046,
    '1480_880': 29.3882,
    '1480_881': 29.4683,
    '1480_882': 29.5672,
    '1480_883': 29.6871,
    '1480_884': 29.8146,
    '1480_885': 29.9424,
    '1480_886': 30.0459,
    '1480_887': 30.1121,
    '1480_888': 30.1554,
    '1480_889': 30.1909,
    '1480_890': 30.2081,
    '1480_891': 30.2262,
    '1480_892': 30.2703,
    '1480_893': 30.3363,
    '1480_894': 30.4194,
    '1480_895': 30.4955,
    '1480_896': 30.5457,
    '1480_897': 30.5688,
    '1480_898': 30.5627,
    '1480_899': 30.5361,
    '1480_900': 30.4968,
    '1480_901': 30.4507,
    '1480_902': 30.4103,
    '1480_903': 30.3694,
    '1480_904': 30.3392,
    '1480_905': 30.3072,
    '1480_906': 30.2703,
    '1480_907': 30.2166,
    '1480_908': 30.1648,
    '1480_909': 30.1058,
    '1480_910': 30.033,
    '1480_911': 29.9434,
    '1480_912': 29.8465,
    '1480_913': 29.7625,
    '1480_914': 29.685,
    '1480_915': 29.6121,
    '1480_916': 29.5409,
    '1480_917': 29.4728,
    '1480_918': 29.412,
    '1480_919': 29.3576,
    '1480_920': 29.309,
    '1480_921': 29.2653,
    '1480_922': 29.2228,
    '1480_923': 29.1772,
    '1480_924': 29.1291,
    '1480_925': 29.0824,
    '1480_926': 29.0423,
    '1480_927': 29.0072,
    '1480_928': 28.9752,
    '1480_929': 28.9453,
    '1480_930': 28.9227,
    '1480_931': 28.9143,
    '1480_932': 28.9144,
    '1480_933': 28.9159,
    '1480_934': 28.9223,
    '1480_935': 28.928,
    '1480_936': 28.9264,
    '1481_860': 29.0473,
    '1481_861': 28.9785,
    '1481_862': 28.9131,
    '1481_863': 28.8525,
    '1481_864': 28.7979,
    '1481_865': 28.7507,
    '1481_866': 28.7133,
    '1481_867': 28.6896,
    '1481_868': 28.6768,
    '1481_869': 28.6747,
    '1481_870': 28.6835,
    '1481_871': 28.704,
    '1481_872': 28.74,
    '1481_873': 28.7921,
    '1481_874': 28.8534,
    '1481_875': 28.9138,
    '1481_876': 28.9694,
    '1481_877': 29.0377,
    '1481_878': 29.1273,
    '1481_879': 29.2265,
    '1481_880': 29.3202,
    '1481_881': 29.4118,
    '1481_882': 29.5209,
    '1481_883': 29.6493,
    '1481_884': 29.7769,
    '1481_885': 29.9046,
    '1481_886': 30.0101,
    '1481_887': 30.0809,
    '1481_888': 30.12,
    '1481_889': 30.1502,
    '1481_890': 30.1722,
    '1481_891': 30.1896,
    '1481_892': 30.2309,
    '1481_893': 30.2895,
    '1481_894': 30.3614,
    '1481_895': 30.4242,
    '1481_896': 30.4683,
    '1481_897': 30.4865,
    '1481_898': 30.4812,
    '1481_899': 30.4577,
    '1481_900': 30.4183,
    '1481_901': 30.373,
    '1481_902': 30.3275,
    '1481_903': 30.2811,
    '1481_904': 30.2469,
    '1481_905': 30.2153,
    '1481_906': 30.177,
    '1481_907': 30.1278,
    '1481_908': 30.0733,
    '1481_909': 30.0157,
    '1481_910': 29.9429,
    '1481_911': 29.8572,
    '1481_912': 29.7628,
    '1481_913': 29.6811,
    '1481_914': 29.6058,
    '1481_915': 29.5331,
    '1481_916': 29.4612,
    '1481_917': 29.3939,
    '1481_918': 29.3318,
    '1481_919': 29.2764,
    '1481_920': 29.2267,
    '1481_921': 29.1874,
    '1481_922': 29.1495,
    '1481_923': 29.1087,
    '1481_924': 29.0612,
    '1481_925': 29.0126,
    '1481_926': 28.9766,
    '1481_927': 28.9446,
    '1481_928': 28.9142,
    '1481_929': 28.883,
    '1481_930': 28.8582,
    '1481_931': 28.8547,
    '1481_932': 28.8599,
    '1481_933': 28.8591,
    '1481_934': 28.8622,
    '1481_935': 28.8664,
    '1481_936': 28.8644,
    '1482_860': 28.9789,
    '1482_861': 28.9098,
    '1482_862': 28.8445,
    '1482_863': 28.7846,
    '1482_864': 28.731,
    '1482_865': 28.6853,
    '1482_866': 28.6489,
    '1482_867': 28.6238,
    '1482_868': 28.6098,
    '1482_869': 28.6067,
    '1482_870': 28.6145,
    '1482_871': 28.6338,
    '1482_872': 28.668,
    '1482_873': 28.7165,
    '1482_874': 28.7704,
    '1482_875': 28.8267,
    '1482_876': 28.8842,
    '1482_877': 28.955,
    '1482_878': 29.0459,
    '1482_879': 29.1514,
    '1482_880': 29.2541,
    '1482_881': 29.3524,
    '1482_882': 29.4681,
    '1482_883': 29.5955,
    '1482_884': 29.7275,
    '1482_885': 29.8579,
    '1482_886': 29.9641,
    '1482_887': 30.0358,
    '1482_888': 30.0766,
    '1482_889': 30.1037,
    '1482_890': 30.1282,
    '1482_891': 30.1485,
    '1482_892': 30.1924,
    '1482_893': 30.2406,
    '1482_894': 30.2907,
    '1482_895': 30.3346,
    '1482_896': 30.3663,
    '1482_897': 30.3821,
    '1482_898': 30.3811,
    '1482_899': 30.3609,
    '1482_900': 30.3165,
    '1482_901': 30.2664,
    '1482_902': 30.2201,
    '1482_903': 30.1773,
    '1482_904': 30.1427,
    '1482_905': 30.117,
    '1482_906': 30.0799,
    '1482_907': 30.037,
    '1482_908': 29.9864,
    '1482_909': 29.9282,
    '1482_910': 29.8572,
    '1482_911': 29.7739,
    '1482_912': 29.6799,
    '1482_913': 29.6006,
    '1482_914': 29.5274,
    '1482_915': 29.455,
    '1482_916': 29.3851,
    '1482_917': 29.3187,
    '1482_918': 29.258,
    '1482_919': 29.2041,
    '1482_920': 29.1575,
    '1482_921': 29.1184,
    '1482_922': 29.0821,
    '1482_923': 29.0449,
    '1482_924': 29.0046,
    '1482_925': 28.9617,
    '1482_926': 28.9239,
    '1482_927': 28.8904,
    '1482_928': 28.86,
    '1482_929': 28.8319,
    '1482_930': 28.8098,
    '1482_931': 28.799,
    '1482_932': 28.7968,
    '1482_933': 28.7897,
    '1482_934': 28.7858,
    '1482_935': 28.7838,
    '1482_936': 28.7824,
    '1483_860': 28.9136,
    '1483_861': 28.844,
    '1483_862': 28.7785,
    '1483_863': 28.7191,
    '1483_864': 28.6665,
    '1483_865': 28.622,
    '1483_866': 28.586,
    '1483_867': 28.5594,
    '1483_868': 28.5442,
    '1483_869': 28.5402,
    '1483_870': 28.5465,
    '1483_871': 28.5641,
    '1483_872': 28.5951,
    '1483_873': 28.6393,
    '1483_874': 28.692,
    '1483_875': 28.747,
    '1483_876': 28.804,
    '1483_877': 28.8755,
    '1483_878': 28.9697,
    '1483_879': 29.0819,
    '1483_880': 29.1902,
    '1483_881': 29.2907,
    '1483_882': 29.4086,
    '1483_883': 29.5363,
    '1483_884': 29.6705,
    '1483_885': 29.7992,
    '1483_886': 29.9025,
    '1483_887': 29.9736,
    '1483_888': 30.0164,
    '1483_889': 30.0462,
    '1483_890': 30.0728,
    '1483_891': 30.1057,
    '1483_892': 30.1551,
    '1483_893': 30.1878,
    '1483_894': 30.2105,
    '1483_895': 30.2337,
    '1483_896': 30.2528,
    '1483_897': 30.2662,
    '1483_898': 30.2702,
    '1483_899': 30.252,
    '1483_900': 30.2072,
    '1483_901': 30.1535,
    '1483_902': 30.1053,
    '1483_903': 30.067,
    '1483_904': 30.0392,
    '1483_905': 30.0241,
    '1483_906': 30.0002,
    '1483_907': 29.9613,
    '1483_908': 29.9107,
    '1483_909': 29.8499,
    '1483_910': 29.7777,
    '1483_911': 29.694,
    '1483_912': 29.6038,
    '1483_913': 29.5265,
    '1483_914': 29.4531,
    '1483_915': 29.3827,
    '1483_916': 29.3136,
    '1483_917': 29.2475,
    '1483_918': 29.1889,
    '1483_919': 29.1365,
    '1483_920': 29.0925,
    '1483_921': 29.054,
    '1483_922': 29.0185,
    '1483_923': 28.9837,
    '1483_924': 28.9483,
    '1483_925': 28.9122,
    '1483_926': 28.8758,
    '1483_927': 28.8418,
    '1483_928': 28.812,
    '1483_929': 28.7871,
    '1483_930': 28.7679,
    '1483_931': 28.7544,
    '1483_932': 28.7415,
    '1483_933': 28.7207,
    '1483_934': 28.7087,
    '1483_935': 28.6988,
    '1483_936': 28.6967,
    '1484_860': 28.8527,
    '1484_861': 28.7826,
    '1484_862': 28.7164,
    '1484_863': 28.6571,
    '1484_864': 28.6048,
    '1484_865': 28.561,
    '1484_866': 28.5247,
    '1484_867': 28.4954,
    '1484_868': 28.4795,
    '1484_869': 28.4747,
    '1484_870': 28.4797,
    '1484_871': 28.4953,
    '1484_872': 28.5234,
    '1484_873': 28.5661,
    '1484_874': 28.6157,
    '1484_875': 28.6656,
    '1484_876': 28.7241,
    '1484_877': 28.7981,
    '1484_878': 28.8941,
    '1484_879': 29.0094,
    '1484_880': 29.1193,
    '1484_881': 29.2258,
    '1484_882': 29.3433,
    '1484_883': 29.4736,
    '1484_884': 29.607,
    '1484_885': 29.7319,
    '1484_886': 29.8351,
    '1484_887': 29.9091,
    '1484_888': 29.9502,
    '1484_889': 29.991,
    '1484_890': 30.0171,
    '1484_891': 30.0574,
    '1484_892': 30.1077,
    '1484_893': 30.1314,
    '1484_894': 30.1343,
    '1484_895': 30.1404,
    '1484_896': 30.1501,
    '1484_897': 30.1634,
    '1484_898': 30.1655,
    '1484_899': 30.1416,
    '1484_900': 30.1007,
    '1484_901': 30.0499,
    '1484_902': 29.9995,
    '1484_903': 29.9632,
    '1484_904': 29.9416,
    '1484_905': 29.9332,
    '1484_906': 29.918,
    '1484_907': 29.8862,
    '1484_908': 29.8353,
    '1484_909': 29.7731,
    '1484_910': 29.6997,
    '1484_911': 29.6191,
    '1484_912': 29.5359,
    '1484_913': 29.4579,
    '1484_914': 29.3852,
    '1484_915': 29.3152,
    '1484_916': 29.2473,
    '1484_917': 29.1832,
    '1484_918': 29.1252,
    '1484_919': 29.0739,
    '1484_920': 29.0299,
    '1484_921': 28.9913,
    '1484_922': 28.9564,
    '1484_923': 28.9236,
    '1484_924': 28.8921,
    '1484_925': 28.8612,
    '1484_926': 28.8292,
    '1484_927': 28.7977,
    '1484_928': 28.7682,
    '1484_929': 28.7431,
    '1484_930': 28.724,
    '1484_931': 28.706,
    '1484_932': 28.6868,
    '1484_933': 28.6647,
    '1484_934': 28.6465,
    '1484_935': 28.6318,
    '1484_936': 28.621,
    '1485_860': 28.8009,
    '1485_861': 28.7301,
    '1485_862': 28.6638,
    '1485_863': 28.6038,
    '1485_864': 28.5507,
    '1485_865': 28.5058,
    '1485_866': 28.4686,
    '1485_867': 28.4358,
    '1485_868': 28.4185,
    '1485_869': 28.4125,
    '1485_870': 28.4161,
    '1485_871': 28.43,
    '1485_872': 28.4559,
    '1485_873': 28.4947,
    '1485_874': 28.5401,
    '1485_875': 28.5894,
    '1485_876': 28.6496,
    '1485_877': 28.7242,
    '1485_878': 28.8195,
    '1485_879': 28.9361,
    '1485_880': 29.0489,
    '1485_881': 29.1608,
    '1485_882': 29.2796,
    '1485_883': 29.4124,
    '1485_884': 29.5483,
    '1485_885': 29.6694,
    '1485_886': 29.7772,
    '1485_887': 29.8548,
    '1485_888': 29.8975,
    '1485_889': 29.9392,
    '1485_890': 29.962,
    '1485_891': 30.0041,
    '1485_892': 30.0511,
    '1485_893': 30.068,
    '1485_894': 30.0626,
    '1485_895': 30.0584,
    '1485_896': 30.058,
    '1485_897': 30.0615,
    '1485_898': 30.0591,
    '1485_899': 30.0359,
    '1485_900': 30.002,
    '1485_901': 29.9584,
    '1485_902': 29.9094,
    '1485_903': 29.8747,
    '1485_904': 29.8544,
    '1485_905': 29.8473,
    '1485_906': 29.8336,
    '1485_907': 29.8042,
    '1485_908': 29.7564,
    '1485_909': 29.695,
    '1485_910': 29.6232,
    '1485_911': 29.5466,
    '1485_912': 29.4677,
    '1485_913': 29.3928,
    '1485_914': 29.3208,
    '1485_915': 29.2515,
    '1485_916': 29.1845,
    '1485_917': 29.1214,
    '1485_918': 29.0637,
    '1485_919': 29.0127,
    '1485_920': 28.9684,
    '1485_921': 28.9297,
    '1485_922': 28.8957,
    '1485_923': 28.8649,
    '1485_924': 28.8372,
    '1485_925': 28.8116,
    '1485_926': 28.7859,
    '1485_927': 28.7588,
    '1485_928': 28.7276,
    '1485_929': 28.6998,
    '1485_930': 28.6806,
    '1485_931': 28.6586,
    '1485_932': 28.6365,
    '1485_933': 28.6135,
    '1485_934': 28.5915,
    '1485_935': 28.5715,
    '1485_936': 28.5529,
    '1486_860': 28.7544,
    '1486_861': 28.6829,
    '1486_862': 28.616,
    '1486_863': 28.5546,
    '1486_864': 28.4993,
    '1486_865': 28.4514,
    '1486_866': 28.4115,
    '1486_867': 28.3805,
    '1486_868': 28.3622,
    '1486_869': 28.3545,
    '1486_870': 28.356,
    '1486_871': 28.3684,
    '1486_872': 28.3929,
    '1486_873': 28.4286,
    '1486_874': 28.4718,
    '1486_875': 28.5215,
    '1486_876': 28.5821,
    '1486_877': 28.6568,
    '1486_878': 28.7505,
    '1486_879': 28.8655,
    '1486_880': 28.9815,
    '1486_881': 29.0929,
    '1486_882': 29.2104,
    '1486_883': 29.3437,
    '1486_884': 29.4858,
    '1486_885': 29.6146,
    '1486_886': 29.7247,
    '1486_887': 29.8006,
    '1486_888': 29.8433,
    '1486_889': 29.8781,
    '1486_890': 29.9013,
    '1486_891': 29.9438,
    '1486_892': 29.9859,
    '1486_893': 30.0004,
    '1486_894': 29.994,
    '1486_895': 29.9854,
    '1486_896': 29.9778,
    '1486_897': 29.9702,
    '1486_898': 29.9626,
    '1486_899': 29.9395,
    '1486_900': 29.9085,
    '1486_901': 29.873,
    '1486_902': 29.8328,
    '1486_903': 29.7991,
    '1486_904': 29.7787,
    '1486_905': 29.7689,
    '1486_906': 29.7521,
    '1486_907': 29.7186,
    '1486_908': 29.6731,
    '1486_909': 29.6143,
    '1486_910': 29.5463,
    '1486_911': 29.4748,
    '1486_912': 29.4002,
    '1486_913': 29.328,
    '1486_914': 29.2576,
    '1486_915': 29.1891,
    '1486_916': 29.1226,
    '1486_917': 29.0595,
    '1486_918': 29.0016,
    '1486_919': 28.9505,
    '1486_920': 28.9061,
    '1486_921': 28.8677,
    '1486_922': 28.8345,
    '1486_923': 28.8056,
    '1486_924': 28.7811,
    '1486_925': 28.7606,
    '1486_926': 28.7415,
    '1486_927': 28.7214,
    '1486_928': 28.6897,
    '1486_929': 28.659,
    '1486_930': 28.6338,
    '1486_931': 28.6101,
    '1486_932': 28.5873,
    '1486_933': 28.564,
    '1486_934': 28.5389,
    '1486_935': 28.5141,
    '1486_936': 28.4893,
    '1487_860': 28.711,
    '1487_861': 28.6391,
    '1487_862': 28.5713,
    '1487_863': 28.5083,
    '1487_864': 28.4504,
    '1487_865': 28.3993,
    '1487_866': 28.3576,
    '1487_867': 28.3289,
    '1487_868': 28.3098,
    '1487_869': 28.2999,
    '1487_870': 28.2993,
    '1487_871': 28.3098,
    '1487_872': 28.3331,
    '1487_873': 28.3664,
    '1487_874': 28.4068,
    '1487_875': 28.454,
    '1487_876': 28.5137,
    '1487_877': 28.5861,
    '1487_878': 28.6795,
    '1487_879': 28.793,
    '1487_880': 28.9105,
    '1487_881': 29.0197,
    '1487_882': 29.1378,
    '1487_883': 29.273,
    '1487_884': 29.4179,
    '1487_885': 29.552,
    '1487_886': 29.6639,
    '1487_887': 29.7416,
    '1487_888': 29.7871,
    '1487_889': 29.8133,
    '1487_890': 29.8418,
    '1487_891': 29.882,
    '1487_892': 29.9191,
    '1487_893': 29.9337,
    '1487_894': 29.9285,
    '1487_895': 29.9207,
    '1487_896': 29.9058,
    '1487_897': 29.8883,
    '1487_898': 29.8746,
    '1487_899': 29.8525,
    '1487_900': 29.8243,
    '1487_901': 29.7938,
    '1487_902': 29.766,
    '1487_903': 29.7361,
    '1487_904': 29.7105,
    '1487_905': 29.6872,
    '1487_906': 29.665,
    '1487_907': 29.6281,
    '1487_908': 29.5833,
    '1487_909': 29.5297,
    '1487_910': 29.4682,
    '1487_911': 29.4008,
    '1487_912': 29.3322,
    '1487_913': 29.2629,
    '1487_914': 29.1945,
    '1487_915': 29.1274,
    '1487_916': 29.0609,
    '1487_917': 28.9971,
    '1487_918': 28.9382,
    '1487_919': 28.8869,
    '1487_920': 28.8428,
    '1487_921': 28.8048,
    '1487_922': 28.7721,
    '1487_923': 28.7441,
    '1487_924': 28.7214,
    '1487_925': 28.7036,
    '1487_926': 28.6866,
    '1487_927': 28.6671,
    '1487_928': 28.64,
    '1487_929': 28.6099,
    '1487_930': 28.5827,
    '1487_931': 28.5586,
    '1487_932': 28.5365,
    '1487_933': 28.5143,
    '1487_934': 28.4868,
    '1487_935': 28.4586,
    '1487_936': 28.4299,
    '1488_860': 28.6697,
    '1488_861': 28.5972,
    '1488_862': 28.5288,
    '1488_863': 28.4645,
    '1488_864': 28.4038,
    '1488_865': 28.3505,
    '1488_866': 28.3081,
    '1488_867': 28.2804,
    '1488_868': 28.26,
    '1488_869': 28.2482,
    '1488_870': 28.2454,
    '1488_871': 28.2539,
    '1488_872': 28.2753,
    '1488_873': 28.3065,
    '1488_874': 28.3451,
    '1488_875': 28.3896,
    '1488_876': 28.4475,
    '1488_877': 28.5158,
    '1488_878': 28.6055,
    '1488_879': 28.713,
    '1488_880': 28.8279,
    '1488_881': 28.9405,
    '1488_882': 29.0575,
    '1488_883': 29.1981,
    '1488_884': 29.3473,
    '1488_885': 29.4889,
    '1488_886': 29.604,
    '1488_887': 29.6814,
    '1488_888': 29.7263,
    '1488_889': 29.7515,
    '1488_890': 29.7839,
    '1488_891': 29.8237,
    '1488_892': 29.855,
    '1488_893': 29.8676,
    '1488_894': 29.8667,
    '1488_895': 29.8674,
    '1488_896': 29.8456,
    '1488_897': 29.8178,
    '1488_898': 29.7944,
    '1488_899': 29.7723,
    '1488_900': 29.75,
    '1488_901': 29.7313,
    '1488_902': 29.7135,
    '1488_903': 29.6872,
    '1488_904': 29.6524,
    '1488_905': 29.6064,
    '1488_906': 29.5762,
    '1488_907': 29.5394,
    '1488_908': 29.4948,
    '1488_909': 29.4443,
    '1488_910': 29.387,
    '1488_911': 29.327,
    '1488_912': 29.2637,
    '1488_913': 29.1964,
    '1488_914': 29.1307,
    '1488_915': 29.0658,
    '1488_916': 28.9993,
    '1488_917': 28.9337,
    '1488_918': 28.873,
    '1488_919': 28.8218,
    '1488_920': 28.7782,
    '1488_921': 28.7408,
    '1488_922': 28.7082,
    '1488_923': 28.68,
    '1488_924': 28.6573,
    '1488_925': 28.6414,
    '1488_926': 28.6261,
    '1488_927': 28.6065,
    '1488_928': 28.5798,
    '1488_929': 28.5509,
    '1488_930': 28.5248,
    '1488_931': 28.5013,
    '1488_932': 28.4795,
    '1488_933': 28.4574,
    '1488_934': 28.4313,
    '1488_935': 28.4032,
    '1488_936': 28.3735,
    '1489_860': 28.6308,
    '1489_861': 28.5576,
    '1489_862': 28.4881,
    '1489_863': 28.4224,
    '1489_864': 28.3584,
    '1489_865': 28.3035,
    '1489_866': 28.2623,
    '1489_867': 28.2329,
    '1489_868': 28.2116,
    '1489_869': 28.1984,
    '1489_870': 28.1945,
    '1489_871': 28.2015,
    '1489_872': 28.2202,
    '1489_873': 28.2499,
    '1489_874': 28.2869,
    '1489_875': 28.3292,
    '1489_876': 28.3852,
    '1489_877': 28.45,
    '1489_878': 28.5337,
    '1489_879': 28.6333,
    '1489_880': 28.7441,
    '1489_881': 28.8607,
    '1489_882': 28.9783,
    '1489_883': 29.122,
    '1489_884': 29.2755,
    '1489_885': 29.4205,
    '1489_886': 29.5366,
    '1489_887': 29.6135,
    '1489_888': 29.6604,
    '1489_889': 29.6947,
    '1489_890': 29.7306,
    '1489_891': 29.7691,
    '1489_892': 29.7994,
    '1489_893': 29.8111,
    '1489_894': 29.8135,
    '1489_895': 29.8228,
    '1489_896': 29.8017,
    '1489_897': 29.7649,
    '1489_898': 29.7308,
    '1489_899': 29.7034,
    '1489_900': 29.6839,
    '1489_901': 29.6763,
    '1489_902': 29.6588,
    '1489_903': 29.6285,
    '1489_904': 29.5834,
    '1489_905': 29.5221,
    '1489_906': 29.4833,
    '1489_907': 29.4485,
    '1489_908': 29.4071,
    '1489_909': 29.3582,
    '1489_910': 29.3068,
    '1489_911': 29.2527,
    '1489_912': 29.1951,
    '1489_913': 29.1281,
    '1489_914': 29.0664,
    '1489_915': 29.0052,
    '1489_916': 28.9392,
    '1489_917': 28.8721,
    '1489_918': 28.8088,
    '1489_919': 28.7588,
    '1489_920': 28.7155,
    '1489_921': 28.6787,
    '1489_922': 28.6455,
    '1489_923': 28.6162,
    '1489_924': 28.5915,
    '1489_925': 28.5772,
    '1489_926': 28.563,
    '1489_927': 28.5431,
    '1489_928': 28.5141,
    '1489_929': 28.4859,
    '1489_930': 28.4627,
    '1489_931': 28.4414,
    '1489_932': 28.4207,
    '1489_933': 28.4011,
    '1489_934': 28.3764,
    '1489_935': 28.3489,
    '1489_936': 28.3198,
    '1490_860': 28.5956,
    '1490_861': 28.5206,
    '1490_862': 28.447,
    '1490_863': 28.3767,
    '1490_864': 28.3107,
    '1490_865': 28.2548,
    '1490_866': 28.216,
    '1490_867': 28.1866,
    '1490_868': 28.1645,
    '1490_869': 28.1503,
    '1490_870': 28.1454,
    '1490_871': 28.1508,
    '1490_872': 28.1675,
    '1490_873': 28.1959,
    '1490_874': 28.2305,
    '1490_875': 28.2705,
    '1490_876': 28.3241,
    '1490_877': 28.3884,
    '1490_878': 28.4677,
    '1490_879': 28.5626,
    '1490_880': 28.6671,
    '1490_881': 28.7858,
    '1490_882': 28.9053,
    '1490_883': 29.0512,
    '1490_884': 29.2074,
    '1490_885': 29.3514,
    '1490_886': 29.4628,
    '1490_887': 29.5379,
    '1490_888': 29.588,
    '1490_889': 29.6317,
    '1490_890': 29.6738,
    '1490_891': 29.7135,
    '1490_892': 29.7454,
    '1490_893': 29.7652,
    '1490_894': 29.7737,
    '1490_895': 29.7845,
    '1490_896': 29.7657,
    '1490_897': 29.7268,
    '1490_898': 29.6837,
    '1490_899': 29.6456,
    '1490_900': 29.6213,
    '1490_901': 29.6099,
    '1490_902': 29.5869,
    '1490_903': 29.5484,
    '1490_904': 29.499,
    '1490_905': 29.4403,
    '1490_906': 29.3882,
    '1490_907': 29.3529,
    '1490_908': 29.3165,
    '1490_909': 29.2727,
    '1490_910': 29.2251,
    '1490_911': 29.1741,
    '1490_912': 29.1207,
    '1490_913': 29.0609,
    '1490_914': 29.0016,
    '1490_915': 28.9428,
    '1490_916': 28.881,
    '1490_917': 28.8166,
    '1490_918': 28.7557,
    '1490_919': 28.7042,
    '1490_920': 28.6602,
    '1490_921': 28.6222,
    '1490_922': 28.5884,
    '1490_923': 28.5585,
    '1490_924': 28.5334,
    '1490_925': 28.514,
    '1490_926': 28.4934,
    '1490_927': 28.4692,
    '1490_928': 28.4419,
    '1490_929': 28.4191,
    '1490_930': 28.4003,
    '1490_931': 28.3822,
    '1490_932': 28.365,
    '1490_933': 28.3477,
    '1490_934': 28.3227,
    '1490_935': 28.2957,
    '1490_936': 28.2677,
    '1491_860': 28.5604,
    '1491_861': 28.4842,
    '1491_862': 28.4062,
    '1491_863': 28.3327,
    '1491_864': 28.2658,
    '1491_865': 28.2111,
    '1491_866': 28.1733,
    '1491_867': 28.1427,
    '1491_868': 28.1192,
    '1491_869': 28.1039,
    '1491_870': 28.0979,
    '1491_871': 28.1016,
    '1491_872': 28.1164,
    '1491_873': 28.1427,
    '1491_874': 28.1761,
    '1491_875': 28.2163,
    '1491_876': 28.2689,
    '1491_877': 28.3332,
    '1491_878': 28.4099,
    '1491_879': 28.4997,
    '1491_880': 28.5968,
    '1491_881': 28.7112,
    '1491_882': 28.84,
    '1491_883': 28.9894,
    '1491_884': 29.1456,
    '1491_885': 29.2873,
    '1491_886': 29.3981,
    '1491_887': 29.4672,
    '1491_888': 29.517,
    '1491_889': 29.5676,
    '1491_890': 29.6165,
    '1491_891': 29.6607,
    '1491_892': 29.6993,
    '1491_893': 29.7287,
    '1491_894': 29.7474,
    '1491_895': 29.7554,
    '1491_896': 29.7353,
    '1491_897': 29.6955,
    '1491_898': 29.6513,
    '1491_899': 29.6005,
    '1491_900': 29.5606,
    '1491_901': 29.5311,
    '1491_902': 29.497,
    '1491_903': 29.4539,
    '1491_904': 29.4024,
    '1491_905': 29.3505,
    '1491_906': 29.3,
    '1491_907': 29.2651,
    '1491_908': 29.2302,
    '1491_909': 29.1918,
    '1491_910': 29.1454,
    '1491_911': 29.096,
    '1491_912': 29.048,
    '1491_913': 28.9931,
    '1491_914': 28.9364,
    '1491_915': 28.8816,
    '1491_916': 28.8243,
    '1491_917': 28.7615,
    '1491_918': 28.7021,
    '1491_919': 28.6509,
    '1491_920': 28.6065,
    '1491_921': 28.5678,
    '1491_922': 28.5332,
    '1491_923': 28.5022,
    '1491_924': 28.4752,
    '1491_925': 28.4511,
    '1491_926': 28.4253,
    '1491_927': 28.3975,
    '1491_928': 28.3698,
    '1491_929': 28.3525,
    '1491_930': 28.3374,
    '1491_931': 28.3215,
    '1491_932': 28.3054,
    '1491_933': 28.2878,
    '1491_934': 28.2663,
    '1491_935': 28.2427,
    '1491_936': 28.2173,
    '1492_860': 28.5203,
    '1492_861': 28.4409,
    '1492_862': 28.3629,
    '1492_863': 28.2892,
    '1492_864': 28.2224,
    '1492_865': 28.1665,
    '1492_866': 28.1273,
    '1492_867': 28.0967,
    '1492_868': 28.0734,
    '1492_869': 28.0579,
    '1492_870': 28.0518,
    '1492_871': 28.0549,
    '1492_872': 28.0695,
    '1492_873': 28.0942,
    '1492_874': 28.1259,
    '1492_875': 28.1658,
    '1492_876': 28.2188,
    '1492_877': 28.2815,
    '1492_878': 28.356,
    '1492_879': 28.4418,
    '1492_880': 28.534,
    '1492_881': 28.6449,
    '1492_882': 28.7828,
    '1492_883': 28.9403,
    '1492_884': 29.0918,
    '1492_885': 29.2256,
    '1492_886': 29.3322,
    '1492_887': 29.3997,
    '1492_888': 29.4526,
    '1492_889': 29.5096,
    '1492_890': 29.5673,
    '1492_891': 29.6164,
    '1492_892': 29.6592,
    '1492_893': 29.6943,
    '1492_894': 29.7233,
    '1492_895': 29.7324,
    '1492_896': 29.7154,
    '1492_897': 29.6763,
    '1492_898': 29.6276,
    '1492_899': 29.569,
    '1492_900': 29.5154,
    '1492_901': 29.4649,
    '1492_902': 29.409,
    '1492_903': 29.3583,
    '1492_904': 29.3076,
    '1492_905': 29.2639,
    '1492_906': 29.2213,
    '1492_907': 29.185,
    '1492_908': 29.1525,
    '1492_909': 29.1165,
    '1492_910': 29.0688,
    '1492_911': 29.0214,
    '1492_912': 28.9746,
    '1492_913': 28.9247,
    '1492_914': 28.8726,
    '1492_915': 28.8191,
    '1492_916': 28.7617,
    '1492_917': 28.7008,
    '1492_918': 28.643,
    '1492_919': 28.5935,
    '1492_920': 28.5499,
    '1492_921': 28.5108,
    '1492_922': 28.4757,
    '1492_923': 28.444,
    '1492_924': 28.4146,
    '1492_925': 28.3882,
    '1492_926': 28.3608,
    '1492_927': 28.3348,
    '1492_928': 28.3112,
    '1492_929': 28.293,
    '1492_930': 28.2772,
    '1492_931': 28.2607,
    '1492_932': 28.2448,
    '1492_933': 28.2278,
    '1492_934': 28.2091,
    '1492_935': 28.1897,
    '1492_936': 28.1693,
    '1493_860': 28.4777,
    '1493_861': 28.3968,
    '1493_862': 28.319,
    '1493_863': 28.2466,
    '1493_864': 28.1816,
    '1493_865': 28.1268,
    '1493_866': 28.0848,
    '1493_867': 28.0527,
    '1493_868': 28.0287,
    '1493_869': 28.013,
    '1493_870': 28.0072,
    '1493_871': 28.0113,
    '1493_872': 28.0285,
    '1493_873': 28.0514,
    '1493_874': 28.0819,
    '1493_875': 28.1211,
    '1493_876': 28.1733,
    '1493_877': 28.2364,
    '1493_878': 28.311,
    '1493_879': 28.3935,
    '1493_880': 28.4855,
    '1493_881': 28.5957,
    '1493_882': 28.7354,
    '1493_883': 28.8958,
    '1493_884': 29.0494,
    '1493_885': 29.1773,
    '1493_886': 29.2737,
    '1493_887': 29.3372,
    '1493_888': 29.396,
    '1493_889': 29.4674,
    '1493_890': 29.537,
    '1493_891': 29.5862,
    '1493_892': 29.6229,
    '1493_893': 29.6597,
    '1493_894': 29.6963,
    '1493_895': 29.7116,
    '1493_896': 29.7015,
    '1493_897': 29.6657,
    '1493_898': 29.6121,
    '1493_899': 29.546,
    '1493_900': 29.4829,
    '1493_901': 29.4165,
    '1493_902': 29.3467,
    '1493_903': 29.2856,
    '1493_904': 29.2321,
    '1493_905': 29.1901,
    '1493_906': 29.1515,
    '1493_907': 29.1137,
    '1493_908': 29.0781,
    '1493_909': 29.0382,
    '1493_910': 28.9913,
    '1493_911': 28.9485,
    '1493_912': 28.9045,
    '1493_913': 28.8578,
    '1493_914': 28.809,
    '1493_915': 28.7575,
    '1493_916': 28.6996,
    '1493_917': 28.6395,
    '1493_918': 28.582,
    '1493_919': 28.5354,
    '1493_920': 28.4923,
    '1493_921': 28.4521,
    '1493_922': 28.4159,
    '1493_923': 28.383,
    '1493_924': 28.3498,
    '1493_925': 28.3191,
    '1493_926': 28.2951,
    '1493_927': 28.2736,
    '1493_928': 28.2531,
    '1493_929': 28.2348,
    '1493_930': 28.2186,
    '1493_931': 28.2003,
    '1493_932': 28.1858,
    '1493_933': 28.1694,
    '1493_934': 28.1519,
    '1493_935': 28.1339,
    '1493_936': 28.1156,
    '1494_860': 28.4339,
    '1494_861': 28.3525,
    '1494_862': 28.2754,
    '1494_863': 28.2041,
    '1494_864': 28.1404,
    '1494_865': 28.0867,
    '1494_866': 28.0437,
    '1494_867': 28.0102,
    '1494_868': 27.9853,
    '1494_869': 27.9697,
    '1494_870': 27.9651,
    '1494_871': 27.9731,
    '1494_872': 27.9917,
    '1494_873': 28.0127,
    '1494_874': 28.0435,
    '1494_875': 28.0827,
    '1494_876': 28.1341,
    '1494_877': 28.1976,
    '1494_878': 28.2706,
    '1494_879': 28.3526,
    '1494_880': 28.4466,
    '1494_881': 28.5584,
    '1494_882': 28.6952,
    '1494_883': 28.8519,
    '1494_884': 29.0071,
    '1494_885': 29.135,
    '1494_886': 29.2261,
    '1494_887': 29.2869,
    '1494_888': 29.3465,
    '1494_889': 29.4206,
    '1494_890': 29.4954,
    '1494_891': 29.5516,
    '1494_892': 29.5839,
    '1494_893': 29.6226,
    '1494_894': 29.6665,
    '1494_895': 29.6911,
    '1494_896': 29.691,
    '1494_897': 29.6591,
    '1494_898': 29.5995,
    '1494_899': 29.5272,
    '1494_900': 29.4493,
    '1494_901': 29.3754,
    '1494_902': 29.3001,
    '1494_903': 29.2328,
    '1494_904': 29.1725,
    '1494_905': 29.1278,
    '1494_906': 29.0893,
    '1494_907': 29.0504,
    '1494_908': 29.0129,
    '1494_909': 28.9719,
    '1494_910': 28.9278,
    '1494_911': 28.8844,
    '1494_912': 28.8396,
    '1494_913': 28.7932,
    '1494_914': 28.7446,
    '1494_915': 28.6932,
    '1494_916': 28.6385,
    '1494_917': 28.5832,
    '1494_918': 28.5306,
    '1494_919': 28.4833,
    '1494_920': 28.4364,
    '1494_921': 28.392,
    '1494_922': 28.3532,
    '1494_923': 28.3183,
    '1494_924': 28.2856,
    '1494_925': 28.2552,
    '1494_926': 28.2311,
    '1494_927': 28.2108,
    '1494_928': 28.1914,
    '1494_929': 28.1728,
    '1494_930': 28.1562,
    '1494_931': 28.1416,
    '1494_932': 28.1296,
    '1494_933': 28.1143,
    '1494_934': 28.096,
    '1494_935': 28.077,
    '1494_936': 28.0603,
    '1495_860': 28.3892,
    '1495_861': 28.3084,
    '1495_862': 28.232,
    '1495_863': 28.1617,
    '1495_864': 28.0986,
    '1495_865': 28.0459,
    '1495_866': 28.0028,
    '1495_867': 27.9687,
    '1495_868': 27.9433,
    '1495_869': 27.9281,
    '1495_870': 27.9247,
    '1495_871': 27.9346,
    '1495_872': 27.9536,
    '1495_873': 27.9748,
    '1495_874': 28.0048,
    '1495_875': 28.0455,
    '1495_876': 28.0991,
    '1495_877': 28.1668,
    '1495_878': 28.2477,
    '1495_879': 28.3292,
    '1495_880': 28.4165,
    '1495_881': 28.5221,
    '1495_882': 28.6528,
    '1495_883': 28.8072,
    '1495_884': 28.9631,
    '1495_885': 29.0934,
    '1495_886': 29.1834,
    '1495_887': 29.2443,
    '1495_888': 29.2981,
    '1495_889': 29.363,
    '1495_890': 29.4361,
    '1495_891': 29.4949,
    '1495_892': 29.5353,
    '1495_893': 29.5826,
    '1495_894': 29.6386,
    '1495_895': 29.6714,
    '1495_896': 29.6809,
    '1495_897': 29.6498,
    '1495_898': 29.5887,
    '1495_899': 29.5096,
    '1495_900': 29.4231,
    '1495_901': 29.3382,
    '1495_902': 29.2537,
    '1495_903': 29.181,
    '1495_904': 29.1203,
    '1495_905': 29.0731,
    '1495_906': 29.0309,
    '1495_907': 28.9934,
    '1495_908': 28.9554,
    '1495_909': 28.9131,
    '1495_910': 28.8691,
    '1495_911': 28.8249,
    '1495_912': 28.7786,
    '1495_913': 28.731,
    '1495_914': 28.6818,
    '1495_915': 28.6308,
    '1495_916': 28.5786,
    '1495_917': 28.5269,
    '1495_918': 28.4781,
    '1495_919': 28.4319,
    '1495_920': 28.3805,
    '1495_921': 28.3314,
    '1495_922': 28.2906,
    '1495_923': 28.2542,
    '1495_924': 28.2224,
    '1495_925': 28.1902,
    '1495_926': 28.1672,
    '1495_927': 28.1484,
    '1495_928': 28.1293,
    '1495_929': 28.1105,
    '1495_930': 28.0936,
    '1495_931': 28.0807,
    '1495_932': 28.0677,
    '1495_933': 28.0585,
    '1495_934': 28.0408,
    '1495_935': 28.0203,
    '1495_936': 28.0062,
    '1496_860': 28.3436,
    '1496_861': 28.264,
    '1496_862': 28.1891,
    '1496_863': 28.12,
    '1496_864': 28.0579,
    '1496_865': 28.0056,
    '1496_866': 27.9624,
    '1496_867': 27.9278,
    '1496_868': 27.9022,
    '1496_869': 27.8871,
    '1496_870': 27.8845,
    '1496_871': 27.8951,
    '1496_872': 27.9133,
    '1496_873': 27.9359,
    '1496_874': 27.9673,
    '1496_875': 28.0109,
    '1496_876': 28.0674,
    '1496_877': 28.1393,
    '1496_878': 28.2245,
    '1496_879': 28.3081,
    '1496_880': 28.3913,
    '1496_881': 28.4907,
    '1496_882': 28.6134,
    '1496_883': 28.7613,
    '1496_884': 28.9155,
    '1496_885': 29.0489,
    '1496_886': 29.1453,
    '1496_887': 29.2054,
    '1496_888': 29.2531,
    '1496_889': 29.3078,
    '1496_890': 29.3706,
    '1496_891': 29.4281,
    '1496_892': 29.4825,
    '1496_893': 29.5408,
    '1496_894': 29.6052,
    '1496_895': 29.6509,
    '1496_896': 29.6656,
    '1496_897': 29.6363,
    '1496_898': 29.5715,
    '1496_899': 29.4897,
    '1496_900': 29.3966,
    '1496_901': 29.3042,
    '1496_902': 29.2143,
    '1496_903': 29.134,
    '1496_904': 29.0709,
    '1496_905': 29.0196,
    '1496_906': 28.9786,
    '1496_907': 28.9405,
    '1496_908': 28.9002,
    '1496_909': 28.8568,
    '1496_910': 28.8112,
    '1496_911': 28.7648,
    '1496_912': 28.7188,
    '1496_913': 28.6707,
    '1496_914': 28.6209,
    '1496_915': 28.5698,
    '1496_916': 28.5185,
    '1496_917': 28.4677,
    '1496_918': 28.4183,
    '1496_919': 28.3701,
    '1496_920': 28.3217,
    '1496_921': 28.2741,
    '1496_922': 28.2314,
    '1496_923': 28.193,
    '1496_924': 28.1591,
    '1496_925': 28.128,
    '1496_926': 28.1041,
    '1496_927': 28.0848,
    '1496_928': 28.0676,
    '1496_929': 28.0513,
    '1496_930': 28.0377,
    '1496_931': 28.028,
    '1496_932': 28.0156,
    '1496_933': 28.0028,
    '1496_934': 27.9862,
    '1496_935': 27.9678,
    '1496_936': 27.9526,
    '1497_860': 28.2967,
    '1497_861': 28.2188,
    '1497_862': 28.146,
    '1497_863': 28.0788,
    '1497_864': 28.0179,
    '1497_865': 27.9656,
    '1497_866': 27.922,
    '1497_867': 27.8868,
    '1497_868': 27.8614,
    '1497_869': 27.8468,
    '1497_870': 27.8439,
    '1497_871': 27.8543,
    '1497_872': 27.8713,
    '1497_873': 27.8965,
    '1497_874': 27.9318,
    '1497_875': 27.9788,
    '1497_876': 28.0393,
    '1497_877': 28.1155,
    '1497_878': 28.2012,
    '1497_879': 28.287,
    '1497_880': 28.3683,
    '1497_881': 28.4634,
    '1497_882': 28.5793,
    '1497_883': 28.719,
    '1497_884': 28.869,
    '1497_885': 29.0057,
    '1497_886': 29.1099,
    '1497_887': 29.1717,
    '1497_888': 29.2127,
    '1497_889': 29.261,
    '1497_890': 29.3156,
    '1497_891': 29.3706,
    '1497_892': 29.4299,
    '1497_893': 29.4986,
    '1497_894': 29.5706,
    '1497_895': 29.6224,
    '1497_896': 29.6408,
    '1497_897': 29.6145,
    '1497_898': 29.5472,
    '1497_899': 29.4641,
    '1497_900': 29.3673,
    '1497_901': 29.2694,
    '1497_902': 29.1767,
    '1497_903': 29.0892,
    '1497_904': 29.0233,
    '1497_905': 28.9709,
    '1497_906': 28.9296,
    '1497_907': 28.8912,
    '1497_908': 28.8488,
    '1497_909': 28.8038,
    '1497_910': 28.7562,
    '1497_911': 28.7071,
    '1497_912': 28.6616,
    '1497_913': 28.6125,
    '1497_914': 28.5617,
    '1497_915': 28.51,
    '1497_916': 28.4588,
    '1497_917': 28.408,
    '1497_918': 28.3575,
    '1497_919': 28.3078,
    '1497_920': 28.2628,
    '1497_921': 28.2164,
    '1497_922': 28.1726,
    '1497_923': 28.1322,
    '1497_924': 28.0978,
    '1497_925': 28.0663,
    '1497_926': 28.0426,
    '1497_927': 28.0234,
    '1497_928': 28.008,
    '1497_929': 27.9937,
    '1497_930': 27.9827,
    '1497_931': 27.9787,
    '1497_932': 27.9652,
    '1497_933': 27.9499,
    '1497_934': 27.9334,
    '1497_935': 27.918,
    '1497_936': 27.9019,
    '1498_860': 28.2489,
    '1498_861': 28.173,
    '1498_862': 28.1025,
    '1498_863': 28.0376,
    '1498_864': 27.9776,
    '1498_865': 27.9253,
    '1498_866': 27.8814,
    '1498_867': 27.8459,
    '1498_868': 27.8206,
    '1498_869': 27.8064,
    '1498_870': 27.8029,
    '1498_871': 27.8105,
    '1498_872': 27.8294,
    '1498_873': 27.859,
    '1498_874': 27.8986,
    '1498_875': 27.9498,
    '1498_876': 28.0136,
    '1498_877': 28.0931,
    '1498_878': 28.1807,
    '1498_879': 28.2679,
    '1498_880': 28.3456,
    '1498_881': 28.4399,
    '1498_882': 28.5513,
    '1498_883': 28.6817,
    '1498_884': 28.8259,
    '1498_885': 28.9621,
    '1498_886': 29.0693,
    '1498_887': 29.1374,
    '1498_888': 29.1796,
    '1498_889': 29.2266,
    '1498_890': 29.2763,
    '1498_891': 29.3256,
    '1498_892': 29.3831,
    '1498_893': 29.4557,
    '1498_894': 29.5318,
    '1498_895': 29.5888,
    '1498_896': 29.6086,
    '1498_897': 29.585,
    '1498_898': 29.5194,
    '1498_899': 29.4331,
    '1498_900': 29.3266,
    '1498_901': 29.2186,
    '1498_902': 29.1251,
    '1498_903': 29.041,
    '1498_904': 28.978,
    '1498_905': 28.9251,
    '1498_906': 28.8822,
    '1498_907': 28.8421,
    '1498_908': 28.8004,
    '1498_909': 28.7565,
    '1498_910': 28.7092,
    '1498_911': 28.6591,
    '1498_912': 28.6089,
    '1498_913': 28.5568,
    '1498_914': 28.5047,
    '1498_915': 28.4526,
    '1498_916': 28.402,
    '1498_917': 28.3517,
    '1498_918': 28.3016,
    '1498_919': 28.2526,
    '1498_920': 28.206,
    '1498_921': 28.1595,
    '1498_922': 28.1142,
    '1498_923': 28.0723,
    '1498_924': 28.0391,
    '1498_925': 28.0102,
    '1498_926': 27.9864,
    '1498_927': 27.9671,
    '1498_928': 27.9517,
    '1498_929': 27.9387,
    '1498_930': 27.9288,
    '1498_931': 27.922,
    '1498_932': 27.9115,
    '1498_933': 27.8995,
    '1498_934': 27.8878,
    '1498_935': 27.8773,
    '1498_936': 27.8636,
    '1499_860': 28.2004,
    '1499_861': 28.1261,
    '1499_862': 28.058,
    '1499_863': 27.9959,
    '1499_864': 27.9366,
    '1499_865': 27.8841,
    '1499_866': 27.8402,
    '1499_867': 27.805,
    '1499_868': 27.78,
    '1499_869': 27.7663,
    '1499_870': 27.7623,
    '1499_871': 27.7685,
    '1499_872': 27.788,
    '1499_873': 27.8231,
    '1499_874': 27.8677,
    '1499_875': 27.9233,
    '1499_876': 27.9904,
    '1499_877': 28.072,
    '1499_878': 28.1638,
    '1499_879': 28.2508,
    '1499_880': 28.3271,
    '1499_881': 28.4188,
    '1499_882': 28.5284,
    '1499_883': 28.6497,
    '1499_884': 28.7844,
    '1499_885': 28.9198,
    '1499_886': 29.031,
    '1499_887': 29.1022,
    '1499_888': 29.1458,
    '1499_889': 29.1933,
    '1499_890': 29.2389,
    '1499_891': 29.2865,
    '1499_892': 29.3398,
    '1499_893': 29.4105,
    '1499_894': 29.4863,
    '1499_895': 29.5435,
    '1499_896': 29.5651,
    '1499_897': 29.5431,
    '1499_898': 29.4835,
    '1499_899': 29.3943,
    '1499_900': 29.2794,
    '1499_901': 29.1644,
    '1499_902': 29.0713,
    '1499_903': 28.9964,
    '1499_904': 28.9366,
    '1499_905': 28.8829,
    '1499_906': 28.8384,
    '1499_907': 28.7974,
    '1499_908': 28.7571,
    '1499_909': 28.7154,
    '1499_910': 28.6688,
    '1499_911': 28.617,
    '1499_912': 28.5622,
    '1499_913': 28.5058,
    '1499_914': 28.452,
    '1499_915': 28.399,
    '1499_916': 28.349,
    '1499_917': 28.2992,
    '1499_918': 28.2496,
    '1499_919': 28.201,
    '1499_920': 28.1534,
    '1499_921': 28.1065,
    '1499_922': 28.0612,
    '1499_923': 28.0201,
    '1499_924': 27.9866,
    '1499_925': 27.9579,
    '1499_926': 27.9335,
    '1499_927': 27.9133,
    '1499_928': 27.8974,
    '1499_929': 27.8846,
    '1499_930': 27.8747,
    '1499_931': 27.8668,
    '1499_932': 27.8583,
    '1499_933': 27.8497,
    '1499_934': 27.8424,
    '1499_935': 27.8364,
    '1499_936': 27.8288,
    '1500_860': 28.1526,
    '1500_861': 28.0787,
    '1500_862': 28.0111,
    '1500_863': 27.9497,
    '1500_864': 27.8924,
    '1500_865': 27.8412,
    '1500_866': 27.7981,
    '1500_867': 27.7641,
    '1500_868': 27.7408,
    '1500_869': 27.728,
    '1500_870': 27.7251,
    '1500_871': 27.7325,
    '1500_872': 27.7556,
    '1500_873': 27.7921,
    '1500_874': 27.8405,
    '1500_875': 27.9003,
    '1500_876': 27.9695,
    '1500_877': 28.0532,
    '1500_878': 28.1479,
    '1500_879': 28.2358,
    '1500_880': 28.3127,
    '1500_881': 28.3991,
    '1500_882': 28.5078,
    '1500_883': 28.6219,
    '1500_884': 28.7467,
    '1500_885': 28.8808,
    '1500_886': 28.9928,
    '1500_887': 29.0646,
    '1500_888': 29.107,
    '1500_889': 29.151,
    '1500_890': 29.1985,
    '1500_891': 29.246,
    '1500_892': 29.2938,
    '1500_893': 29.3583,
    '1500_894': 29.4296,
    '1500_895': 29.4858,
    '1500_896': 29.5062,
    '1500_897': 29.4838,
    '1500_898': 29.4306,
    '1500_899': 29.3495,
    '1500_900': 29.2427,
    '1500_901': 29.1234,
    '1500_902': 29.0278,
    '1500_903': 28.9572,
    '1500_904': 28.8956,
    '1500_905': 28.8438,
    '1500_906': 28.7978,
    '1500_907': 28.7561,
    '1500_908': 28.7184,
    '1500_909': 28.6798,
    '1500_910': 28.6338,
    '1500_911': 28.5795,
    '1500_912': 28.5213,
    '1500_913': 28.4624,
    '1500_914': 28.4057,
    '1500_915': 28.3516,
    '1500_916': 28.3002,
    '1500_917': 28.2494,
    '1500_918': 28.1997,
    '1500_919': 28.1515,
    '1500_920': 28.1037,
    '1500_921': 28.0568,
    '1500_922': 28.0118,
    '1500_923': 27.9705,
    '1500_924': 27.9374,
    '1500_925': 27.9081,
    '1500_926': 27.8823,
    '1500_927': 27.8606,
    '1500_928': 27.844,
    '1500_929': 27.8308,
    '1500_930': 27.821,
    '1500_931': 27.8133,
    '1500_932': 27.806,
    '1500_933': 27.7998,
    '1500_934': 27.7965,
    '1500_935': 27.7964,
    '1500_936': 27.7965,
    '1501_860': 28.1094,
    '1501_861': 28.0356,
    '1501_862': 27.9675,
    '1501_863': 27.9054,
    '1501_864': 27.8489,
    '1501_865': 27.7987,
    '1501_866': 27.7569,
    '1501_867': 27.7244,
    '1501_868': 27.7032,
    '1501_869': 27.6932,
    '1501_870': 27.6942,
    '1501_871': 27.7056,
    '1501_872': 27.7322,
    '1501_873': 27.771,
    '1501_874': 27.8205,
    '1501_875': 27.8821,
    '1501_876': 27.955,
    '1501_877': 28.0355,
    '1501_878': 28.1294,
    '1501_879': 28.2171,
    '1501_880': 28.2952,
    '1501_881': 28.3809,
    '1501_882': 28.4845,
    '1501_883': 28.5944,
    '1501_884': 28.7134,
    '1501_885': 28.8413,
    '1501_886': 28.9524,
    '1501_887': 29.0241,
    '1501_888': 29.0662,
    '1501_889': 29.1069,
    '1501_890': 29.1512,
    '1501_891': 29.1985,
    '1501_892': 29.2435,
    '1501_893': 29.2992,
    '1501_894': 29.3572,
    '1501_895': 29.4092,
    '1501_896': 29.4301,
    '1501_897': 29.4084,
    '1501_898': 29.3583,
    '1501_899': 29.2906,
    '1501_900': 29.1947,
    '1501_901': 29.0851,
    '1501_902': 28.9951,
    '1501_903': 28.9225,
    '1501_904': 28.8628,
    '1501_905': 28.8096,
    '1501_906': 28.7614,
    '1501_907': 28.7177,
    '1501_908': 28.6829,
    '1501_909': 28.6475,
    '1501_910': 28.6014,
    '1501_911': 28.5439,
    '1501_912': 28.4831,
    '1501_913': 28.422,
    '1501_914': 28.3629,
    '1501_915': 28.3079,
    '1501_916': 28.2552,
    '1501_917': 28.203,
    '1501_918': 28.1521,
    '1501_919': 28.1045,
    '1501_920': 28.0567,
    '1501_921': 28.0106,
    '1501_922': 27.9669,
    '1501_923': 27.927,
    '1501_924': 27.8926,
    '1501_925': 27.8614,
    '1501_926': 27.8339,
    '1501_927': 27.8106,
    '1501_928': 27.7932,
    '1501_929': 27.779,
    '1501_930': 27.7689,
    '1501_931': 27.7619,
    '1501_932': 27.7551,
    '1501_933': 27.7507,
    '1501_934': 27.7506,
    '1501_935': 27.757,
    '1501_936': 27.7653,
    '1502_860': 28.0688,
    '1502_861': 27.9943,
    '1502_862': 27.9255,
    '1502_863': 27.8628,
    '1502_864': 27.8064,
    '1502_865': 27.7571,
    '1502_866': 27.7169,
    '1502_867': 27.6867,
    '1502_868': 27.6676,
    '1502_869': 27.6606,
    '1502_870': 27.6662,
    '1502_871': 27.6847,
    '1502_872': 27.7141,
    '1502_873': 27.7563,
    '1502_874': 27.8056,
    '1502_875': 27.8662,
    '1502_876': 27.9409,
    '1502_877': 28.0197,
    '1502_878': 28.1092,
    '1502_879': 28.1991,
    '1502_880': 28.2808,
    '1502_881': 28.3669,
    '1502_882': 28.4667,
    '1502_883': 28.5701,
    '1502_884': 28.6829,
    '1502_885': 28.801,
    '1502_886': 28.9054,
    '1502_887': 28.9746,
    '1502_888': 29.0226,
    '1502_889': 29.0635,
    '1502_890': 29.105,
    '1502_891': 29.1441,
    '1502_892': 29.1819,
    '1502_893': 29.2244,
    '1502_894': 29.272,
    '1502_895': 29.3139,
    '1502_896': 29.3354,
    '1502_897': 29.3211,
    '1502_898': 29.2818,
    '1502_899': 29.2296,
    '1502_900': 29.1431,
    '1502_901': 29.0434,
    '1502_902': 28.9645,
    '1502_903': 28.8944,
    '1502_904': 28.8348,
    '1502_905': 28.7802,
    '1502_906': 28.7305,
    '1502_907': 28.6867,
    '1502_908': 28.6476,
    '1502_909': 28.6073,
    '1502_910': 28.5596,
    '1502_911': 28.5038,
    '1502_912': 28.4435,
    '1502_913': 28.3822,
    '1502_914': 28.3232,
    '1502_915': 28.267,
    '1502_916': 28.2127,
    '1502_917': 28.1594,
    '1502_918': 28.107,
    '1502_919': 28.0569,
    '1502_920': 28.009,
    '1502_921': 27.9649,
    '1502_922': 27.923,
    '1502_923': 27.8828,
    '1502_924': 27.8467,
    '1502_925': 27.8156,
    '1502_926': 27.7886,
    '1502_927': 27.7657,
    '1502_928': 27.7472,
    '1502_929': 27.7305,
    '1502_930': 27.7177,
    '1502_931': 27.7099,
    '1502_932': 27.7049,
    '1502_933': 27.7033,
    '1502_934': 27.7062,
    '1502_935': 27.7142,
    '1502_936': 27.7259,
    '1503_860': 28.0287,
    '1503_861': 27.9535,
    '1503_862': 27.8837,
    '1503_863': 27.8205,
    '1503_864': 27.7641,
    '1503_865': 27.7157,
    '1503_866': 27.6774,
    '1503_867': 27.6497,
    '1503_868': 27.6326,
    '1503_869': 27.6287,
    '1503_870': 27.6391,
    '1503_871': 27.664,
    '1503_872': 27.696,
    '1503_873': 27.7386,
    '1503_874': 27.7891,
    '1503_875': 27.8507,
    '1503_876': 27.9243,
    '1503_877': 28.0055,
    '1503_878': 28.0947,
    '1503_879': 28.1824,
    '1503_880': 28.2668,
    '1503_881': 28.3554,
    '1503_882': 28.4519,
    '1503_883': 28.5514,
    '1503_884': 28.659,
    '1503_885': 28.768,
    '1503_886': 28.8623,
    '1503_887': 28.9291,
    '1503_888': 28.9771,
    '1503_889': 29.0157,
    '1503_890': 29.0518,
    '1503_891': 29.0845,
    '1503_892': 29.1138,
    '1503_893': 29.1435,
    '1503_894': 29.1837,
    '1503_895': 29.22,
    '1503_896': 29.2376,
    '1503_897': 29.2293,
    '1503_898': 29.2057,
    '1503_899': 29.1636,
    '1503_900': 29.0892,
    '1503_901': 29.0066,
    '1503_902': 28.9323,
    '1503_903': 28.8683,
    '1503_904': 28.8096,
    '1503_905': 28.7537,
    '1503_906': 28.7018,
    '1503_907': 28.6569,
    '1503_908': 28.6137,
    '1503_909': 28.5684,
    '1503_910': 28.5183,
    '1503_911': 28.463,
    '1503_912': 28.4035,
    '1503_913': 28.3416,
    '1503_914': 28.2828,
    '1503_915': 28.226,
    '1503_916': 28.1704,
    '1503_917': 28.1163,
    '1503_918': 28.0616,
    '1503_919': 28.0088,
    '1503_920': 27.9594,
    '1503_921': 27.9162,
    '1503_922': 27.8774,
    '1503_923': 27.8368,
    '1503_924': 27.7999,
    '1503_925': 27.7701,
    '1503_926': 27.7441,
    '1503_927': 27.7218,
    '1503_928': 27.7031,
    '1503_929': 27.6837,
    '1503_930': 27.6684,
    '1503_931': 27.6609,
    '1503_932': 27.6574,
    '1503_933': 27.6583,
    '1503_934': 27.663,
    '1503_935': 27.6712,
    '1503_936': 27.6852,
    '1504_844': 29.5044,
    '1504_845': 29.496,
    '1504_846': 29.4728,
    '1504_847': 29.437,
    '1504_848': 29.3897,
    '1504_849': 29.3313,
    '1504_850': 29.2618,
    '1504_851': 29.1824,
    '1504_852': 29.0955,
    '1504_853': 29.0034,
    '1504_854': 28.9086,
    '1504_855': 28.8126,
    '1504_860': 27.988,
    '1504_861': 27.9118,
    '1504_862': 27.8413,
    '1504_863': 27.7773,
    '1504_864': 27.721,
    '1504_865': 27.6734,
    '1504_866': 27.6371,
    '1504_867': 27.6112,
    '1504_868': 27.5966,
    '1504_869': 27.5954,
    '1504_870': 27.6087,
    '1504_871': 27.6365,
    '1504_872': 27.6708,
    '1504_873': 27.7158,
    '1504_874': 27.7718,
    '1504_875': 27.8331,
    '1504_876': 27.9067,
    '1504_877': 27.9911,
    '1504_878': 28.0801,
    '1504_879': 28.1669,
    '1504_880': 28.2525,
    '1504_881': 28.3412,
    '1504_882': 28.4367,
    '1504_883': 28.5361,
    '1504_884': 28.6372,
    '1504_885': 28.7345,
    '1504_886': 28.8168,
    '1504_887': 28.8794,
    '1504_888': 28.9273,
    '1504_889': 28.964,
    '1504_890': 28.9963,
    '1504_891': 29.0241,
    '1504_892': 29.0473,
    '1504_893': 29.0686,
    '1504_894': 29.0967,
    '1504_895': 29.1283,
    '1504_896': 29.1484,
    '1504_897': 29.1413,
    '1504_898': 29.1212,
    '1504_899': 29.0784,
    '1504_900': 29.0262,
    '1504_901': 28.9638,
    '1504_902': 28.8986,
    '1504_903': 28.84,
    '1504_904': 28.7843,
    '1504_905': 28.7289,
    '1504_906': 28.6768,
    '1504_907': 28.6289,
    '1504_908': 28.5814,
    '1504_909': 28.531,
    '1504_910': 28.4776,
    '1504_911': 28.4221,
    '1504_912': 28.3623,
    '1504_913': 28.3006,
    '1504_914': 28.2402,
    '1504_915': 28.1811,
    '1504_916': 28.1239,
    '1504_917': 28.0684,
    '1504_918': 28.0134,
    '1504_919': 27.9597,
    '1504_920': 27.9093,
    '1504_921': 27.864,
    '1504_922': 27.8253,
    '1504_923': 27.7881,
    '1504_924': 27.7533,
    '1504_925': 27.7234,
    '1504_926': 27.6978,
    '1504_927': 27.6748,
    '1504_928': 27.6545,
    '1504_929': 27.6371,
    '1504_930': 27.6242,
    '1504_931': 27.6175,
    '1504_932': 27.6157,
    '1504_933': 27.6181,
    '1504_934': 27.6242,
    '1504_935': 27.6337,
    '1504_936': 27.6472,
    '1505_844': 29.4744,
    '1505_845': 29.4668,
    '1505_846': 29.4469,
    '1505_847': 29.4146,
    '1505_848': 29.3706,
    '1505_849': 29.3135,
    '1505_850': 29.2427,
    '1505_851': 29.1593,
    '1505_852': 29.0676,
    '1505_853': 28.9714,
    '1505_854': 28.8734,
    '1505_855': 28.7756,
    '1505_860': 27.9474,
    '1505_861': 27.8702,
    '1505_862': 27.7988,
    '1505_863': 27.7343,
    '1505_864': 27.6777,
    '1505_865': 27.6308,
    '1505_866': 27.5967,
    '1505_867': 27.5732,
    '1505_868': 27.5611,
    '1505_869': 27.5622,
    '1505_870': 27.5774,
    '1505_871': 27.6065,
    '1505_872': 27.6457,
    '1505_873': 27.6947,
    '1505_874': 27.755,
    '1505_875': 27.8152,
    '1505_876': 27.8889,
    '1505_877': 27.9752,
    '1505_878': 28.0612,
    '1505_879': 28.1474,
    '1505_880': 28.2358,
    '1505_881': 28.3244,
    '1505_882': 28.4179,
    '1505_883': 28.5146,
    '1505_884': 28.6127,
    '1505_885': 28.7009,
    '1505_886': 28.7736,
    '1505_887': 28.8352,
    '1505_888': 28.8818,
    '1505_889': 28.9108,
    '1505_890': 28.9359,
    '1505_891': 28.9582,
    '1505_892': 28.9761,
    '1505_893': 28.9938,
    '1505_894': 29.0147,
    '1505_895': 29.0379,
    '1505_896': 29.0526,
    '1505_897': 29.0517,
    '1505_898': 29.0347,
    '1505_899': 29.0025,
    '1505_900': 28.9626,
    '1505_901': 28.9107,
    '1505_902': 28.8581,
    '1505_903': 28.806,
    '1505_904': 28.7542,
    '1505_905': 28.6992,
    '1505_906': 28.6469,
    '1505_907': 28.5973,
    '1505_908': 28.5473,
    '1505_909': 28.4922,
    '1505_910': 28.4351,
    '1505_911': 28.3798,
    '1505_912': 28.318,
    '1505_913': 28.2554,
    '1505_914': 28.1934,
    '1505_915': 28.1326,
    '1505_916': 28.0746,
    '1505_917': 28.0183,
    '1505_918': 27.9631,
    '1505_919': 27.9091,
    '1505_920': 27.8588,
    '1505_921': 27.8139,
    '1505_922': 27.7747,
    '1505_923': 27.7393,
    '1505_924': 27.7061,
    '1505_925': 27.6761,
    '1505_926': 27.6514,
    '1505_927': 27.6277,
    '1505_928': 27.6067,
    '1505_929': 27.5914,
    '1505_930': 27.5808,
    '1505_931': 27.5757,
    '1505_932': 27.5757,
    '1505_933': 27.58,
    '1505_934': 27.5875,
    '1505_935': 27.5977,
    '1505_936': 27.6107,
    '1506_844': 29.4446,
    '1506_845': 29.44,
    '1506_846': 29.4246,
    '1506_847': 29.3975,
    '1506_848': 29.3602,
    '1506_849': 29.3064,
    '1506_850': 29.2347,
    '1506_851': 29.1443,
    '1506_852': 29.0451,
    '1506_853': 28.9411,
    '1506_854': 28.8392,
    '1506_855': 28.7387,
    '1506_860': 27.9064,
    '1506_861': 27.8284,
    '1506_862': 27.7564,
    '1506_863': 27.6916,
    '1506_864': 27.6348,
    '1506_865': 27.5889,
    '1506_866': 27.5584,
    '1506_867': 27.5361,
    '1506_868': 27.526,
    '1506_869': 27.5295,
    '1506_870': 27.5479,
    '1506_871': 27.5797,
    '1506_872': 27.6226,
    '1506_873': 27.6761,
    '1506_874': 27.7365,
    '1506_875': 27.7976,
    '1506_876': 27.8702,
    '1506_877': 27.9551,
    '1506_878': 28.0396,
    '1506_879': 28.125,
    '1506_880': 28.2115,
    '1506_881': 28.2981,
    '1506_882': 28.3899,
    '1506_883': 28.4881,
    '1506_884': 28.5825,
    '1506_885': 28.6634,
    '1506_886': 28.7339,
    '1506_887': 28.7937,
    '1506_888': 28.8369,
    '1506_889': 28.858,
    '1506_890': 28.874,
    '1506_891': 28.887,
    '1506_892': 28.9004,
    '1506_893': 28.9135,
    '1506_894': 28.9285,
    '1506_895': 28.9455,
    '1506_896': 28.959,
    '1506_897': 28.9647,
    '1506_898': 28.9534,
    '1506_899': 28.9316,
    '1506_900': 28.8975,
    '1506_901': 28.857,
    '1506_902': 28.8111,
    '1506_903': 28.7623,
    '1506_904': 28.7118,
    '1506_905': 28.6594,
    '1506_906': 28.6081,
    '1506_907': 28.5572,
    '1506_908': 28.5051,
    '1506_909': 28.4486,
    '1506_910': 28.3899,
    '1506_911': 28.3297,
    '1506_912': 28.2665,
    '1506_913': 28.203,
    '1506_914': 28.1399,
    '1506_915': 28.0784,
    '1506_916': 28.0222,
    '1506_917': 27.9654,
    '1506_918': 27.9093,
    '1506_919': 27.8555,
    '1506_920': 27.8047,
    '1506_921': 27.7596,
    '1506_922': 27.7232,
    '1506_923': 27.6914,
    '1506_924': 27.6595,
    '1506_925': 27.6295,
    '1506_926': 27.6037,
    '1506_927': 27.5821,
    '1506_928': 27.5638,
    '1506_929': 27.5482,
    '1506_930': 27.5377,
    '1506_931': 27.5333,
    '1506_932': 27.5351,
    '1506_933': 27.5415,
    '1506_934': 27.5511,
    '1506_935': 27.5624,
    '1506_936': 27.5754,
    '1507_844': 29.4165,
    '1507_845': 29.4168,
    '1507_846': 29.4099,
    '1507_847': 29.3887,
    '1507_848': 29.3553,
    '1507_849': 29.3064,
    '1507_850': 29.2347,
    '1507_851': 29.1359,
    '1507_852': 29.0244,
    '1507_853': 28.9124,
    '1507_854': 28.8056,
    '1507_855': 28.7021,
    '1507_860': 27.8656,
    '1507_861': 27.7871,
    '1507_862': 27.7149,
    '1507_863': 27.6503,
    '1507_864': 27.5943,
    '1507_865': 27.5499,
    '1507_866': 27.5201,
    '1507_867': 27.4996,
    '1507_868': 27.4917,
    '1507_869': 27.4979,
    '1507_870': 27.5189,
    '1507_871': 27.5535,
    '1507_872': 27.5979,
    '1507_873': 27.6548,
    '1507_874': 27.7178,
    '1507_875': 27.7799,
    '1507_876': 27.8506,
    '1507_877': 27.9342,
    '1507_878': 28.019,
    '1507_879': 28.1026,
    '1507_880': 28.1856,
    '1507_881': 28.2691,
    '1507_882': 28.36,
    '1507_883': 28.4537,
    '1507_884': 28.5428,
    '1507_885': 28.624,
    '1507_886': 28.6967,
    '1507_887': 28.7574,
    '1507_888': 28.794,
    '1507_889': 28.8075,
    '1507_890': 28.8147,
    '1507_891': 28.8186,
    '1507_892': 28.8243,
    '1507_893': 28.8305,
    '1507_894': 28.8389,
    '1507_895': 28.8517,
    '1507_896': 28.8653,
    '1507_897': 28.8749,
    '1507_898': 28.8734,
    '1507_899': 28.8586,
    '1507_900': 28.8363,
    '1507_901': 28.8018,
    '1507_902': 28.7593,
    '1507_903': 28.7119,
    '1507_904': 28.662,
    '1507_905': 28.611,
    '1507_906': 28.5606,
    '1507_907': 28.5094,
    '1507_908': 28.4562,
    '1507_909': 28.3981,
    '1507_910': 28.3384,
    '1507_911': 28.2747,
    '1507_912': 28.21,
    '1507_913': 28.1467,
    '1507_914': 28.0834,
    '1507_915': 28.0206,
    '1507_916': 27.9687,
    '1507_917': 27.9116,
    '1507_918': 27.8547,
    '1507_919': 27.8015,
    '1507_920': 27.7501,
    '1507_921': 27.7039,
    '1507_922': 27.6714,
    '1507_923': 27.6442,
    '1507_924': 27.6125,
    '1507_925': 27.582,
    '1507_926': 27.555,
    '1507_927': 27.5357,
    '1507_928': 27.5209,
    '1507_929': 27.5041,
    '1507_930': 27.4937,
    '1507_931': 27.4897,
    '1507_932': 27.4935,
    '1507_933': 27.5025,
    '1507_934': 27.5151,
    '1507_935': 27.528,
    '1507_936': 27.5416,
    '1508_844': 29.392,
    '1508_845': 29.3973,
    '1508_846': 29.397,
    '1508_847': 29.3857,
    '1508_848': 29.3629,
    '1508_849': 29.3222,
    '1508_850': 29.2507,
    '1508_851': 29.1421,
    '1508_852': 29.0109,
    '1508_853': 28.8878,
    '1508_854': 28.7727,
    '1508_855': 28.6654,
    '1508_860': 27.8244,
    '1508_861': 27.7457,
    '1508_862': 27.6737,
    '1508_863': 27.61,
    '1508_864': 27.556,
    '1508_865': 27.5141,
    '1508_866': 27.4835,
    '1508_867': 27.4644,
    '1508_868': 27.458,
    '1508_869': 27.4665,
    '1508_870': 27.4902,
    '1508_871': 27.5274,
    '1508_872': 27.5716,
    '1508_873': 27.632,
    '1508_874': 27.6976,
    '1508_875': 27.7584,
    '1508_876': 27.8292,
    '1508_877': 27.9119,
    '1508_878': 27.9973,
    '1508_879': 28.079,
    '1508_880': 28.162,
    '1508_881': 28.2437,
    '1508_882': 28.3278,
    '1508_883': 28.4173,
    '1508_884': 28.5019,
    '1508_885': 28.5825,
    '1508_886': 28.6595,
    '1508_887': 28.72,
    '1508_888': 28.7464,
    '1508_889': 28.7545,
    '1508_890': 28.7541,
    '1508_891': 28.7514,
    '1508_892': 28.7504,
    '1508_893': 28.7492,
    '1508_894': 28.7518,
    '1508_895': 28.7624,
    '1508_896': 28.7764,
    '1508_897': 28.7901,
    '1508_898': 28.7928,
    '1508_899': 28.7888,
    '1508_900': 28.7739,
    '1508_901': 28.7437,
    '1508_902': 28.7034,
    '1508_903': 28.656,
    '1508_904': 28.6057,
    '1508_905': 28.5551,
    '1508_906': 28.5059,
    '1508_907': 28.4525,
    '1508_908': 28.396,
    '1508_909': 28.3367,
    '1508_910': 28.2762,
    '1508_911': 28.2139,
    '1508_912': 28.1512,
    '1508_913': 28.0892,
    '1508_914': 28.0278,
    '1508_915': 27.9678,
    '1508_916': 27.9126,
    '1508_917': 27.857,
    '1508_918': 27.8025,
    '1508_919': 27.7501,
    '1508_920': 27.7007,
    '1508_921': 27.6568,
    '1508_922': 27.6216,
    '1508_923': 27.5922,
    '1508_924': 27.5627,
    '1508_925': 27.5341,
    '1508_926': 27.5069,
    '1508_927': 27.4855,
    '1508_928': 27.4707,
    '1508_929': 27.4583,
    '1508_930': 27.4494,
    '1508_931': 27.4465,
    '1508_932': 27.4515,
    '1508_933': 27.4625,
    '1508_934': 27.4778,
    '1508_935': 27.4942,
    '1508_936': 27.5095,
    '1509_844': 29.368,
    '1509_845': 29.3803,
    '1509_846': 29.3848,
    '1509_847': 29.3842,
    '1509_848': 29.3737,
    '1509_849': 29.3441,
    '1509_850': 29.273,
    '1509_851': 29.1534,
    '1509_852': 29.0043,
    '1509_853': 28.8638,
    '1509_854': 28.7391,
    '1509_855': 28.6276,
    '1509_860': 27.7823,
    '1509_861': 27.7034,
    '1509_862': 27.6318,
    '1509_863': 27.569,
    '1509_864': 27.5171,
    '1509_865': 27.4773,
    '1509_866': 27.4482,
    '1509_867': 27.431,
    '1509_868': 27.4253,
    '1509_869': 27.4349,
    '1509_870': 27.4607,
    '1509_871': 27.4991,
    '1509_872': 27.5427,
    '1509_873': 27.6052,
    '1509_874': 27.6708,
    '1509_875': 27.7317,
    '1509_876': 27.8023,
    '1509_877': 27.8857,
    '1509_878': 27.9704,
    '1509_879': 28.052,
    '1509_880': 28.1375,
    '1509_881': 28.2195,
    '1509_882': 28.2938,
    '1509_883': 28.3746,
    '1509_884': 28.455,
    '1509_885': 28.534,
    '1509_886': 28.6111,
    '1509_887': 28.6699,
    '1509_888': 28.6913,
    '1509_889': 28.6925,
    '1509_890': 28.6873,
    '1509_891': 28.6822,
    '1509_892': 28.6791,
    '1509_893': 28.6766,
    '1509_894': 28.6794,
    '1509_895': 28.6902,
    '1509_896': 28.7027,
    '1509_897': 28.7117,
    '1509_898': 28.7191,
    '1509_899': 28.7197,
    '1509_900': 28.7102,
    '1509_901': 28.6808,
    '1509_902': 28.6416,
    '1509_903': 28.5936,
    '1509_904': 28.5423,
    '1509_905': 28.4911,
    '1509_906': 28.4445,
    '1509_907': 28.3884,
    '1509_908': 28.3288,
    '1509_909': 28.2683,
    '1509_910': 28.2083,
    '1509_911': 28.1484,
    '1509_912': 28.0886,
    '1509_913': 28.0294,
    '1509_914': 27.9701,
    '1509_915': 27.912,
    '1509_916': 27.8553,
    '1509_917': 27.8005,
    '1509_918': 27.7484,
    '1509_919': 27.6974,
    '1509_920': 27.6524,
    '1509_921': 27.608,
    '1509_922': 27.57,
    '1509_923': 27.5388,
    '1509_924': 27.5107,
    '1509_925': 27.4844,
    '1509_926': 27.4559,
    '1509_927': 27.4323,
    '1509_928': 27.4193,
    '1509_929': 27.4107,
    '1509_930': 27.4035,
    '1509_931': 27.4016,
    '1509_932': 27.4084,
    '1509_933': 27.4213,
    '1509_934': 27.4402,
    '1509_935': 27.4611,
    '1509_936': 27.4787,
    '1510_844': 29.3416,
    '1510_845': 29.3569,
    '1510_846': 29.367,
    '1510_847': 29.3731,
    '1510_848': 29.3716,
    '1510_849': 29.3444,
    '1510_850': 29.2683,
    '1510_851': 29.1443,
    '1510_852': 28.9815,
    '1510_853': 28.8321,
    '1510_854': 28.7025,
    '1510_855': 28.5884,
    '1510_860': 27.7407,
    '1510_861': 27.6621,
    '1510_862': 27.5911,
    '1510_863': 27.5294,
    '1510_864': 27.48,
    '1510_865': 27.4418,
    '1510_866': 27.4153,
    '1510_867': 27.4004,
    '1510_868': 27.3954,
    '1510_869': 27.4057,
    '1510_870': 27.4327,
    '1510_871': 27.4707,
    '1510_872': 27.5133,
    '1510_873': 27.5755,
    '1510_874': 27.6409,
    '1510_875': 27.7034,
    '1510_876': 27.7753,
    '1510_877': 27.8568,
    '1510_878': 27.9397,
    '1510_879': 28.0244,
    '1510_880': 28.1106,
    '1510_881': 28.187,
    '1510_882': 28.2546,
    '1510_883': 28.3271,
    '1510_884': 28.4042,
    '1510_885': 28.4804,
    '1510_886': 28.5508,
    '1510_887': 28.6029,
    '1510_888': 28.6244,
    '1510_889': 28.6252,
    '1510_890': 28.6191,
    '1510_891': 28.6136,
    '1510_892': 28.6115,
    '1510_893': 28.6132,
    '1510_894': 28.6187,
    '1510_895': 28.6277,
    '1510_896': 28.6342,
    '1510_897': 28.6425,
    '1510_898': 28.6476,
    '1510_899': 28.6458,
    '1510_900': 28.6336,
    '1510_901': 28.607,
    '1510_902': 28.5696,
    '1510_903': 28.524,
    '1510_904': 28.4743,
    '1510_905': 28.4234,
    '1510_906': 28.3725,
    '1510_907': 28.3163,
    '1510_908': 28.2566,
    '1510_909': 28.196,
    '1510_910': 28.1376,
    '1510_911': 28.08,
    '1510_912': 28.0232,
    '1510_913': 27.9671,
    '1510_914': 27.9085,
    '1510_915': 27.851,
    '1510_916': 27.7951,
    '1510_917': 27.7409,
    '1510_918': 27.6883,
    '1510_919': 27.637,
    '1510_920': 27.5939,
    '1510_921': 27.5527,
    '1510_922': 27.5154,
    '1510_923': 27.4835,
    '1510_924': 27.455,
    '1510_925': 27.4285,
    '1510_926': 27.4032,
    '1510_927': 27.3824,
    '1510_928': 27.369,
    '1510_929': 27.3611,
    '1510_930': 27.357,
    '1510_931': 27.3582,
    '1510_932': 27.3667,
    '1510_933': 27.3811,
    '1510_934': 27.4013,
    '1510_935': 27.4242,
    '1510_936': 27.4473,
    '1511_844': 29.312,
    '1511_845': 29.3235,
    '1511_846': 29.3306,
    '1511_847': 29.3383,
    '1511_848': 29.3358,
    '1511_849': 29.3004,
    '1511_850': 29.2187,
    '1511_851': 29.0967,
    '1511_852': 28.9347,
    '1511_853': 28.7893,
    '1511_854': 28.6607,
    '1511_855': 28.546,
    '1511_860': 27.6994,
    '1511_861': 27.6214,
    '1511_862': 27.5512,
    '1511_863': 27.4909,
    '1511_864': 27.4434,
    '1511_865': 27.4078,
    '1511_866': 27.3838,
    '1511_867': 27.3698,
    '1511_868': 27.3659,
    '1511_869': 27.3779,
    '1511_870': 27.4059,
    '1511_871': 27.4425,
    '1511_872': 27.4848,
    '1511_873': 27.5455,
    '1511_874': 27.6156,
    '1511_875': 27.6764,
    '1511_876': 27.7473,
    '1511_877': 27.8284,
    '1511_878': 27.9118,
    '1511_879': 27.9965,
    '1511_880': 28.0805,
    '1511_881': 28.1491,
    '1511_882': 28.2149,
    '1511_883': 28.2854,
    '1511_884': 28.3566,
    '1511_885': 28.4271,
    '1511_886': 28.4903,
    '1511_887': 28.5374,
    '1511_888': 28.5577,
    '1511_889': 28.557,
    '1511_890': 28.5513,
    '1511_891': 28.5479,
    '1511_892': 28.5498,
    '1511_893': 28.5542,
    '1511_894': 28.5611,
    '1511_895': 28.5688,
    '1511_896': 28.5724,
    '1511_897': 28.5775,
    '1511_898': 28.5788,
    '1511_899': 28.5731,
    '1511_900': 28.5577,
    '1511_901': 28.5312,
    '1511_902': 28.495,
    '1511_903': 28.4511,
    '1511_904': 28.4026,
    '1511_905': 28.3515,
    '1511_906': 28.2985,
    '1511_907': 28.2423,
    '1511_908': 28.1837,
    '1511_909': 28.1245,
    '1511_910': 28.0663,
    '1511_911': 28.0091,
    '1511_912': 27.9529,
    '1511_913': 27.8975,
    '1511_914': 27.8416,
    '1511_915': 27.7866,
    '1511_916': 27.7333,
    '1511_917': 27.6815,
    '1511_918': 27.6311,
    '1511_919': 27.5821,
    '1511_920': 27.5376,
    '1511_921': 27.4965,
    '1511_922': 27.4592,
    '1511_923': 27.427,
    '1511_924': 27.3982,
    '1511_925': 27.3724,
    '1511_926': 27.3496,
    '1511_927': 27.3313,
    '1511_928': 27.3187,
    '1511_929': 27.3118,
    '1511_930': 27.3099,
    '1511_931': 27.3137,
    '1511_932': 27.3239,
    '1511_933': 27.3399,
    '1511_934': 27.3614,
    '1511_935': 27.3871,
    '1511_936': 27.4155,
    '1512_844': 29.2726,
    '1512_845': 29.2778,
    '1512_846': 29.2777,
    '1512_847': 29.2751,
    '1512_848': 29.2636,
    '1512_849': 29.2255,
    '1512_850': 29.1414,
    '1512_851': 29.0117,
    '1512_852': 28.8655,
    '1512_853': 28.7317,
    '1512_854': 28.6102,
    '1512_855': 28.4984,
    '1512_860': 27.6577,
    '1512_861': 27.5806,
    '1512_862': 27.5117,
    '1512_863': 27.4531,
    '1512_864': 27.4073,
    '1512_865': 27.3736,
    '1512_866': 27.3513,
    '1512_867': 27.3385,
    '1512_868': 27.3363,
    '1512_869': 27.3504,
    '1512_870': 27.3792,
    '1512_871': 27.413,
    '1512_872': 27.4554,
    '1512_873': 27.5157,
    '1512_874': 27.584,
    '1512_875': 27.645,
    '1512_876': 27.7172,
    '1512_877': 27.7991,
    '1512_878': 27.8822,
    '1512_879': 27.966,
    '1512_880': 28.0435,
    '1512_881': 28.1106,
    '1512_882': 28.1743,
    '1512_883': 28.2424,
    '1512_884': 28.3111,
    '1512_885': 28.3766,
    '1512_886': 28.4313,
    '1512_887': 28.4726,
    '1512_888': 28.4869,
    '1512_889': 28.484,
    '1512_890': 28.4817,
    '1512_891': 28.484,
    '1512_892': 28.4898,
    '1512_893': 28.4963,
    '1512_894': 28.5036,
    '1512_895': 28.5082,
    '1512_896': 28.5129,
    '1512_897': 28.5155,
    '1512_898': 28.5136,
    '1512_899': 28.5042,
    '1512_900': 28.4858,
    '1512_901': 28.4578,
    '1512_902': 28.4211,
    '1512_903': 28.3774,
    '1512_904': 28.3291,
    '1512_905': 28.2778,
    '1512_906': 28.2241,
    '1512_907': 28.1682,
    '1512_908': 28.1105,
    '1512_909': 28.0522,
    '1512_910': 27.9939,
    '1512_911': 27.9366,
    '1512_912': 27.8805,
    '1512_913': 27.826,
    '1512_914': 27.7726,
    '1512_915': 27.7207,
    '1512_916': 27.6708,
    '1512_917': 27.6227,
    '1512_918': 27.5756,
    '1512_919': 27.5279,
    '1512_920': 27.4825,
    '1512_921': 27.4406,
    '1512_922': 27.4029,
    '1512_923': 27.3706,
    '1512_924': 27.342,
    '1512_925': 27.317,
    '1512_926': 27.296,
    '1512_927': 27.2795,
    '1512_928': 27.2682,
    '1512_929': 27.2624,
    '1512_930': 27.2624,
    '1512_931': 27.2681,
    '1512_932': 27.2798,
    '1512_933': 27.2969,
    '1512_934': 27.3201,
    '1512_935': 27.3489,
    '1512_936': 27.3824,
    '1513_844': 29.2308,
    '1513_845': 29.2266,
    '1513_846': 29.2136,
    '1513_847': 29.1934,
    '1513_848': 29.1652,
    '1513_849': 29.1176,
    '1513_850': 29.0253,
    '1513_851': 28.9048,
    '1513_852': 28.7824,
    '1513_853': 28.6658,
    '1513_854': 28.5536,
    '1513_855': 28.4469,
    '1513_860': 27.6154,
    '1513_861': 27.5396,
    '1513_862': 27.472,
    '1513_863': 27.415,
    '1513_864': 27.3704,
    '1513_865': 27.3383,
    '1513_866': 27.318,
    '1513_867': 27.3064,
    '1513_868': 27.3068,
    '1513_869': 27.3231,
    '1513_870': 27.3519,
    '1513_871': 27.3867,
    '1513_872': 27.4306,
    '1513_873': 27.4873,
    '1513_874': 27.5506,
    '1513_875': 27.615,
    '1513_876': 27.6907,
    '1513_877': 27.7734,
    '1513_878': 27.8557,
    '1513_879': 27.9376,
    '1513_880': 28.0142,
    '1513_881': 28.0796,
    '1513_882': 28.1403,
    '1513_883': 28.2019,
    '1513_884': 28.2642,
    '1513_885': 28.3233,
    '1513_886': 28.3706,
    '1513_887': 28.4048,
    '1513_888': 28.4126,
    '1513_889': 28.4094,
    '1513_890': 28.4131,
    '1513_891': 28.4211,
    '1513_892': 28.4302,
    '1513_893': 28.4383,
    '1513_894': 28.444,
    '1513_895': 28.4511,
    '1513_896': 28.4554,
    '1513_897': 28.4564,
    '1513_898': 28.4519,
    '1513_899': 28.4397,
    '1513_900': 28.4185,
    '1513_901': 28.3881,
    '1513_902': 28.3494,
    '1513_903': 28.3046,
    '1513_904': 28.2561,
    '1513_905': 28.2049,
    '1513_906': 28.1509,
    '1513_907': 28.0954,
    '1513_908': 28.0385,
    '1514_836': 28.4587,
    '1514_837': 28.5289,
    '1514_838': 28.605,
    '1514_839': 28.6785,
    '1514_840': 28.7488,
    '1514_841': 28.7999,
    '1514_842': 28.8295,
    '1514_843': 28.8367,
    '1514_844': 29.1901,
    '1514_845': 29.1748,
    '1514_846': 29.147,
    '1514_847': 29.1102,
    '1514_848': 29.061,
    '1514_849': 29.0001,
    '1514_850': 28.9103,
    '1514_851': 28.809,
    '1514_852': 28.705,
    '1514_853': 28.5996,
    '1514_854': 28.4963,
    '1514_855': 28.3943,
    '1514_860': 27.573,
    '1514_861': 27.4987,
    '1514_862': 27.4323,
    '1514_863': 27.3761,
    '1514_864': 27.3326,
    '1514_865': 27.3025,
    '1514_866': 27.2838,
    '1514_867': 27.2743,
    '1514_868': 27.2784,
    '1514_869': 27.2964,
    '1514_870': 27.3263,
    '1514_871': 27.3652,
    '1514_872': 27.4073,
    '1514_873': 27.4585,
    '1514_874': 27.5189,
    '1514_875': 27.5888,
    '1514_876': 27.6663,
    '1514_877': 27.7484,
    '1514_878': 27.83,
    '1514_879': 27.9127,
    '1514_880': 27.9919,
    '1514_881': 28.0557,
    '1514_882': 28.1111,
    '1514_883': 28.1676,
    '1514_884': 28.2218,
    '1514_885': 28.2696,
    '1514_886': 28.3086,
    '1514_887': 28.3337,
    '1514_888': 28.3405,
    '1514_889': 28.3408,
    '1514_890': 28.3491,
    '1514_891': 28.361,
    '1514_892': 28.3722,
    '1514_893': 28.3793,
    '1514_894': 28.388,
    '1514_895': 28.3958,
    '1514_896': 28.3998,
    '1514_897': 28.3997,
    '1514_898': 28.3937,
    '1514_899': 28.3793,
    '1514_900': 28.3556,
    '1514_901': 28.3222,
    '1514_902': 28.2806,
    '1514_903': 28.2339,
    '1514_904': 28.1855,
    '1514_905': 28.1352,
    '1514_906': 28.0811,
    '1514_907': 28.0264,
    '1514_908': 27.9701,
    '1515_836': 28.4161,
    '1515_837': 28.4886,
    '1515_838': 28.5729,
    '1515_839': 28.6508,
    '1515_840': 28.7351,
    '1515_841': 28.7885,
    '1515_842': 28.8132,
    '1515_843': 28.8138,
    '1515_844': 29.1508,
    '1515_845': 29.1274,
    '1515_846': 29.0882,
    '1515_847': 29.0374,
    '1515_848': 28.9769,
    '1515_849': 28.909,
    '1515_850': 28.8252,
    '1515_851': 28.7335,
    '1515_852': 28.6366,
    '1515_853': 28.5391,
    '1515_854': 28.4406,
    '1515_855': 28.3421,
    '1515_860': 27.5303,
    '1515_861': 27.458,
    '1515_862': 27.3937,
    '1515_863': 27.339,
    '1515_864': 27.2965,
    '1515_865': 27.2662,
    '1515_866': 27.2485,
    '1515_867': 27.2404,
    '1515_868': 27.247,
    '1515_869': 27.2688,
    '1515_870': 27.302,
    '1515_871': 27.343,
    '1515_872': 27.3809,
    '1515_873': 27.4301,
    '1515_874': 27.4921,
    '1515_875': 27.5652,
    '1515_876': 27.6453,
    '1515_877': 27.729,
    '1515_878': 27.8101,
    '1515_879': 27.891,
    '1515_880': 27.9681,
    '1515_881': 28.0274,
    '1515_882': 28.0802,
    '1515_883': 28.1345,
    '1515_884': 28.1858,
    '1515_885': 28.2254,
    '1515_886': 28.2534,
    '1515_887': 28.273,
    '1515_888': 28.2811,
    '1515_889': 28.2836,
    '1515_890': 28.2926,
    '1515_891': 28.305,
    '1515_892': 28.3168,
    '1515_893': 28.3244,
    '1515_894': 28.3336,
    '1515_895': 28.3416,
    '1515_896': 28.3448,
    '1515_897': 28.3436,
    '1515_898': 28.3364,
    '1515_899': 28.3197,
    '1515_900': 28.2942,
    '1515_901': 28.2582,
    '1515_902': 28.2137,
    '1515_903': 28.1654,
    '1515_904': 28.1182,
    '1515_905': 28.071,
    '1515_906': 28.0185,
    '1515_907': 27.9636,
    '1515_908': 27.9071,
    '1516_836': 28.3795,
    '1516_837': 28.4545,
    '1516_838': 28.5441,
    '1516_839': 28.6342,
    '1516_840': 28.7221,
    '1516_841': 28.782,
    '1516_842': 28.8042,
    '1516_843': 28.7954,
    '1516_844': 29.1176,
    '1516_845': 29.0834,
    '1516_846': 29.0371,
    '1516_847': 28.979,
    '1516_848': 28.9124,
    '1516_849': 28.839,
    '1516_850': 28.7552,
    '1516_851': 28.6675,
    '1516_852': 28.5759,
    '1516_853': 28.4821,
    '1516_854': 28.3865,
    '1516_855': 28.29,
    '1516_860': 27.486,
    '1516_861': 27.4161,
    '1516_862': 27.3543,
    '1516_863': 27.3016,
    '1516_864': 27.2599,
    '1516_865': 27.2314,
    '1516_866': 27.2154,
    '1516_867': 27.2066,
    '1516_868': 27.2147,
    '1516_869': 27.2393,
    '1516_870': 27.2759,
    '1516_871': 27.3173,
    '1516_872': 27.3534,
    '1516_873': 27.4042,
    '1516_874': 27.4704,
    '1516_875': 27.5452,
    '1516_876': 27.6268,
    '1516_877': 27.7115,
    '1516_878': 27.7916,
    '1516_879': 27.87,
    '1516_880': 27.9431,
    '1516_881': 27.9996,
    '1516_882': 28.0526,
    '1516_883': 28.1067,
    '1516_884': 28.1545,
    '1516_885': 28.1885,
    '1516_886': 28.2085,
    '1516_887': 28.2254,
    '1516_888': 28.2336,
    '1516_889': 28.234,
    '1516_890': 28.2413,
    '1516_891': 28.252,
    '1516_892': 28.2606,
    '1516_893': 28.27,
    '1516_894': 28.2791,
    '1516_895': 28.2864,
    '1516_896': 28.2882,
    '1516_897': 28.284,
    '1516_898': 28.2735,
    '1516_899': 28.2538,
    '1516_900': 28.2261,
    '1516_901': 28.1905,
    '1516_902': 28.1481,
    '1516_903': 28.1022,
    '1516_904': 28.0558,
    '1516_905': 28.0084,
    '1516_906': 27.9576,
    '1516_907': 27.9044,
    '1516_908': 27.8498,
    '1517_836': 28.3539,
    '1517_837': 28.4285,
    '1517_838': 28.5255,
    '1517_839': 28.6227,
    '1517_840': 28.713,
    '1517_841': 28.7781,
    '1517_842': 28.7972,
    '1517_843': 28.7786,
    '1517_844': 29.0873,
    '1517_845': 29.045,
    '1517_846': 28.9896,
    '1517_847': 28.9268,
    '1517_848': 28.856,
    '1517_849': 28.7784,
    '1517_850': 28.6956,
    '1517_851': 28.609,
    '1517_852': 28.5198,
    '1517_853': 28.428,
    '1517_854': 28.3343,
    '1517_855': 28.2388,
    '1517_860': 27.4401,
    '1517_861': 27.373,
    '1517_862': 27.3137,
    '1517_863': 27.2634,
    '1517_864': 27.2232,
    '1517_865': 27.1959,
    '1517_866': 27.1818,
    '1517_867': 27.1735,
    '1517_868': 27.1836,
    '1517_869': 27.2093,
    '1517_870': 27.2478,
    '1517_871': 27.2885,
    '1517_872': 27.3264,
    '1517_873': 27.3809,
    '1517_874': 27.4507,
    '1517_875': 27.5266,
    '1517_876': 27.6116,
    '1517_877': 27.6971,
    '1517_878': 27.7767,
    '1517_879': 27.8512,
    '1517_880': 27.9191,
    '1517_881': 27.9725,
    '1517_882': 28.0261,
    '1517_883': 28.08,
    '1517_884': 28.1288,
    '1517_885': 28.1606,
    '1517_886': 28.1773,
    '1517_887': 28.1914,
    '1517_888': 28.1947,
    '1517_889': 28.1899,
    '1517_890': 28.1941,
    '1517_891': 28.1998,
    '1517_892': 28.2079,
    '1517_893': 28.2159,
    '1517_894': 28.2235,
    '1517_895': 28.2293,
    '1517_896': 28.229,
    '1517_897': 28.2205,
    '1517_898': 28.206,
    '1517_899': 28.1846,
    '1517_900': 28.1566,
    '1517_901': 28.1225,
    '1517_902': 28.083,
    '1517_903': 28.0397,
    '1517_904': 27.9948,
    '1517_905': 27.9482,
    '1517_906': 27.8991,
    '1517_907': 27.8481,
    '1517_908': 27.7958,
    '1518_836': 28.3531,
    '1518_837': 28.4334,
    '1518_838': 28.5271,
    '1518_839': 28.6223,
    '1518_840': 28.7098,
    '1518_841': 28.7726,
    '1518_842': 28.7903,
    '1518_843': 28.7647,
    '1518_860': 27.3935,
    '1518_861': 27.3295,
    '1518_862': 27.2731,
    '1518_863': 27.2256,
    '1518_864': 27.1874,
    '1518_865': 27.161,
    '1518_866': 27.146,
    '1518_867': 27.1409,
    '1518_868': 27.1531,
    '1518_869': 27.1784,
    '1518_870': 27.2177,
    '1518_871': 27.2569,
    '1518_872': 27.2984,
    '1518_873': 27.3587,
    '1518_874': 27.4336,
    '1518_875': 27.5098,
    '1518_876': 27.5979,
    '1518_877': 27.683,
    '1518_878': 27.7608,
    '1518_879': 27.8334,
    '1518_880': 27.8943,
    '1518_881': 27.9467,
    '1518_882': 28.0017,
    '1518_883': 28.0566,
    '1518_884': 28.1046,
    '1518_885': 28.1391,
    '1518_886': 28.1589,
    '1518_887': 28.1678,
    '1518_888': 28.163,
    '1518_889': 28.1564,
    '1518_890': 28.1517,
    '1518_891': 28.1525,
    '1518_892': 28.156,
    '1518_893': 28.1607,
    '1518_894': 28.1656,
    '1518_895': 28.1682,
    '1518_896': 28.1637,
    '1518_897': 28.1531,
    '1518_898': 28.1371,
    '1518_899': 28.1153,
    '1518_900': 28.0879,
    '1518_901': 28.0556,
    '1518_902': 28.0187,
    '1518_903': 27.9779,
    '1518_904': 27.9349,
    '1518_905': 27.89,
    '1518_906': 27.8427,
    '1518_907': 27.7941,
    '1518_908': 27.7443,
    '1519_836': 28.3595,
    '1519_837': 28.4435,
    '1519_838': 28.5359,
    '1519_839': 28.6309,
    '1519_840': 28.7148,
    '1519_841': 28.7776,
    '1519_842': 28.7884,
    '1519_843': 28.7534,
    '1519_860': 27.3466,
    '1519_861': 27.2857,
    '1519_862': 27.2325,
    '1519_863': 27.1878,
    '1519_864': 27.1521,
    '1519_865': 27.1261,
    '1519_866': 27.1118,
    '1519_867': 27.1095,
    '1519_868': 27.1214,
    '1519_869': 27.1461,
    '1519_870': 27.183,
    '1519_871': 27.2209,
    '1519_872': 27.2692,
    '1519_873': 27.3382,
    '1519_874': 27.4158,
    '1519_875': 27.4913,
    '1519_876': 27.5788,
    '1519_877': 27.6649,
    '1519_878': 27.7441,
    '1519_879': 27.8155,
    '1519_880': 27.8715,
    '1519_881': 27.9244,
    '1519_882': 27.9789,
    '1519_883': 28.0337,
    '1519_884': 28.0828,
    '1519_885': 28.1198,
    '1519_886': 28.1429,
    '1519_887': 28.1509,
    '1519_888': 28.1397,
    '1519_889': 28.1251,
    '1519_890': 28.1117,
    '1519_891': 28.1048,
    '1519_892': 28.1024,
    '1519_893': 28.1027,
    '1519_894': 28.1048,
    '1519_895': 28.1035,
    '1519_896': 28.0965,
    '1519_897': 28.0849,
    '1519_898': 28.0686,
    '1519_899': 28.0472,
    '1519_900': 28.0209,
    '1519_901': 27.9904,
    '1519_902': 27.9556,
    '1519_903': 27.9171,
    '1519_904': 27.8761,
    '1519_905': 27.8332,
    '1519_906': 27.7881,
    '1519_907': 27.7417,
    '1519_908': 27.6944,
    '1520_836': 28.3626,
    '1520_837': 28.4497,
    '1520_838': 28.5424,
    '1520_839': 28.6374,
    '1520_840': 28.7212,
    '1520_841': 28.7776,
    '1520_842': 28.7789,
    '1520_843': 28.7415,
    '1520_860': 27.299,
    '1520_861': 27.241,
    '1520_862': 27.191,
    '1520_863': 27.1498,
    '1520_864': 27.117,
    '1520_865': 27.0933,
    '1520_866': 27.0811,
    '1520_867': 27.0796,
    '1520_868': 27.0897,
    '1520_869': 27.1109,
    '1520_870': 27.145,
    '1520_871': 27.1858,
    '1520_872': 27.2402,
    '1520_873': 27.3151,
    '1520_874': 27.389,
    '1520_875': 27.4679,
    '1520_876': 27.558,
    '1520_877': 27.6455,
    '1520_878': 27.7248,
    '1520_879': 27.7914,
    '1520_880': 27.8464,
    '1520_881': 27.9011,
    '1520_882': 27.9561,
    '1520_883': 28.0096,
    '1520_884': 28.0566,
    '1520_885': 28.0948,
    '1520_886': 28.1174,
    '1520_887': 28.1218,
    '1520_888': 28.107,
    '1520_889': 28.0854,
    '1520_890': 28.0669,
    '1520_891': 28.0535,
    '1520_892': 28.0452,
    '1520_893': 28.0415,
    '1520_894': 28.0405,
    '1520_895': 28.0376,
    '1520_896': 28.0296,
    '1520_897': 28.0177,
    '1520_898': 28.0018,
    '1520_899': 27.9812,
    '1520_900': 27.9561,
    '1520_901': 27.9271,
    '1520_902': 27.8942,
    '1520_903': 27.8577,
    '1520_904': 27.8186,
    '1520_905': 27.7776,
    '1520_906': 27.7345,
    '1520_907': 27.6904,
    '1520_908': 27.6452,
    '1521_836': 28.3589,
    '1521_837': 28.4487,
    '1521_838': 28.5428,
    '1521_839': 28.6396,
    '1521_840': 28.7266,
    '1521_841': 28.7776,
    '1521_842': 28.774,
    '1521_843': 28.7291,
    '1521_860': 27.2517,
    '1521_861': 27.1964,
    '1521_862': 27.1495,
    '1521_863': 27.1116,
    '1521_864': 27.0822,
    '1521_865': 27.0616,
    '1521_866': 27.0517,
    '1521_867': 27.052,
    '1521_868': 27.0593,
    '1521_869': 27.0769,
    '1521_870': 27.1079,
    '1521_871': 27.1514,
    '1521_872': 27.2087,
    '1521_873': 27.2834,
    '1521_874': 27.3576,
    '1521_875': 27.4459,
    '1521_876': 27.5376,
    '1521_877': 27.6236,
    '1521_878': 27.7017,
    '1521_879': 27.7668,
    '1521_880': 27.8221,
    '1521_881': 27.8762,
    '1521_882': 27.9308,
    '1521_883': 27.9834,
    '1521_884': 28.0279,
    '1521_885': 28.0652,
    '1521_886': 28.0834,
    '1521_887': 28.0808,
    '1521_888': 28.0628,
    '1521_889': 28.04,
    '1521_890': 28.0175,
    '1521_891': 27.9993,
    '1521_892': 27.9871,
    '1521_893': 27.9818,
    '1521_894': 27.9784,
    '1521_895': 27.974,
    '1521_896': 27.9653,
    '1521_897': 27.9534,
    '1521_898': 27.9381,
    '1521_899': 27.9183,
    '1521_900': 27.8943,
    '1521_901': 27.8668,
    '1521_902': 27.8355,
    '1521_903': 27.8006,
    '1521_904': 27.7632,
    '1521_905': 27.7238,
    '1521_906': 27.6825,
    '1521_907': 27.6402,
    '1521_908': 27.5972,
    '1522_836': 28.3468,
    '1522_837': 28.4388,
    '1522_838': 28.5337,
    '1522_839': 28.6315,
    '1522_840': 28.7217,
    '1522_841': 28.7666,
    '1522_842': 28.7576,
    '1522_843': 28.7087,
    '1522_860': 27.2057,
    '1522_861': 27.1522,
    '1522_862': 27.1078,
    '1522_863': 27.0729,
    '1522_864': 27.047,
    '1522_865': 27.0301,
    '1522_866': 27.0233,
    '1522_867': 27.0238,
    '1522_868': 27.0273,
    '1522_869': 27.0439,
    '1522_870': 27.073,
    '1522_871': 27.1161,
    '1522_872': 27.1735,
    '1522_873': 27.2451,
    '1522_874': 27.3261,
    '1522_875': 27.4228,
    '1522_876': 27.5133,
    '1522_877': 27.5978,
    '1522_878': 27.6746,
    '1522_879': 27.7393,
    '1522_880': 27.7974,
    '1522_881': 27.8517,
    '1522_882': 27.9051,
    '1522_883': 27.9552,
    '1522_884': 27.9962,
    '1522_885': 28.0255,
    '1522_886': 28.0382,
    '1522_887': 28.0341,
    '1522_888': 28.0159,
    '1522_889': 27.9916,
    '1522_890': 27.9663,
    '1522_891': 27.9457,
    '1522_892': 27.9332,
    '1522_893': 27.9255,
    '1522_894': 27.9199,
    '1522_895': 27.914,
    '1522_896': 27.9046,
    '1522_897': 27.8928,
    '1522_898': 27.8779,
    '1522_899': 27.8589,
    '1522_900': 27.8361,
    '1522_901': 27.8098,
    '1522_902': 27.7797,
    '1522_903': 27.7461,
    '1522_904': 27.71,
    '1522_905': 27.6716,
    '1522_906': 27.6316,
    '1522_907': 27.5903,
    '1522_908': 27.5483,
    '1523_836': 28.3251,
    '1523_837': 28.4191,
    '1523_838': 28.5163,
    '1523_839': 28.6096,
    '1523_840': 28.6942,
    '1523_841': 28.7322,
    '1523_842': 28.7243,
    '1523_843': 28.6801,
    '1523_860': 27.1594,
    '1523_861': 27.1072,
    '1523_862': 27.0646,
    '1523_863': 27.0323,
    '1523_864': 27.0098,
    '1523_865': 26.9979,
    '1523_866': 26.9969,
    '1523_867': 26.9947,
    '1523_868': 26.9946,
    '1523_869': 27.0111,
    '1523_870': 27.0388,
    '1523_871': 27.0796,
    '1523_872': 27.1344,
    '1523_873': 27.2057,
    '1523_874': 27.2935,
    '1523_875': 27.3948,
    '1523_876': 27.4884,
    '1523_877': 27.5706,
    '1523_878': 27.6447,
    '1523_879': 27.7088,
    '1523_880': 27.7697,
    '1523_881': 27.8268,
    '1523_882': 27.8805,
    '1523_883': 27.9271,
    '1523_884': 27.9638,
    '1523_885': 27.9869,
    '1523_886': 27.9937,
    '1523_887': 27.9878,
    '1523_888': 27.9701,
    '1523_889': 27.944,
    '1523_890': 27.9163,
    '1523_891': 27.8951,
    '1523_892': 27.8816,
    '1523_893': 27.8716,
    '1523_894': 27.8638,
    '1523_895': 27.8563,
    '1523_896': 27.8462,
    '1523_897': 27.8345,
    '1523_898': 27.8201,
    '1523_899': 27.8019,
    '1523_900': 27.78,
    '1523_901': 27.7546,
    '1523_902': 27.7256,
    '1523_903': 27.693,
    '1523_904': 27.6577,
    '1523_905': 27.6201,
    '1523_906': 27.5806,
    '1523_907': 27.5398,
    '1523_908': 27.4982,
    '1524_836': 28.2948,
    '1524_837': 28.3906,
    '1524_838': 28.4878,
    '1524_839': 28.5772,
    '1524_840': 28.653,
    '1524_841': 28.6868,
    '1524_842': 28.6829,
    '1524_843': 28.6472,
    '1524_860': 27.114,
    '1524_861': 27.0619,
    '1524_862': 27.0201,
    '1524_863': 26.99,
    '1524_864': 26.9704,
    '1524_865': 26.9628,
    '1524_866': 26.967,
    '1524_867': 26.9632,
    '1524_868': 26.9618,
    '1524_869': 26.9779,
    '1524_870': 27.0062,
    '1524_871': 27.0456,
    '1524_872': 27.0986,
    '1524_873': 27.1723,
    '1524_874': 27.2638,
    '1524_875': 27.365,
    '1524_876': 27.4587,
    '1524_877': 27.5451,
    '1524_878': 27.6189,
    '1524_879': 27.6842,
    '1524_880': 27.748,
    '1524_881': 27.8068,
    '1524_882': 27.8575,
    '1524_883': 27.8994,
    '1524_884': 27.9322,
    '1524_885': 27.9515,
    '1524_886': 27.9574,
    '1524_887': 27.9504,
    '1524_888': 27.9318,
    '1524_889': 27.9036,
    '1524_890': 27.8737,
    '1524_891': 27.8518,
    '1524_892': 27.8349,
    '1524_893': 27.8214,
    '1524_894': 27.8108,
    '1524_895': 27.8018,
    '1524_896': 27.7911,
    '1524_897': 27.7794,
    '1524_898': 27.7656,
    '1524_899': 27.7482,
    '1524_900': 27.7271,
    '1524_901': 27.7026,
    '1524_902': 27.6744,
    '1524_903': 27.6425,
    '1524_904': 27.6077,
    '1524_905': 27.5704,
    '1524_906': 27.5309,
    '1524_907': 27.4901,
    '1524_908': 27.4486,
    '1525_836': 28.2559,
    '1525_837': 28.3539,
    '1525_838': 28.4499,
    '1525_839': 28.5378,
    '1525_840': 28.6043,
    '1525_841': 28.6369,
    '1525_842': 28.6395,
    '1525_843': 28.6111,
    '1525_860': 27.0684,
    '1525_861': 27.0147,
    '1525_862': 26.9734,
    '1525_863': 26.9448,
    '1525_864': 26.9278,
    '1525_865': 26.9232,
    '1525_866': 26.9298,
    '1525_867': 26.928,
    '1525_868': 26.9298,
    '1525_869': 26.9469,
    '1525_870': 26.9762,
    '1525_871': 27.0166,
    '1525_872': 27.0702,
    '1525_873': 27.141,
    '1525_874': 27.2339,
    '1525_875': 27.3358,
    '1525_876': 27.4299,
    '1525_877': 27.5179,
    '1525_878': 27.5933,
    '1525_879': 27.6625,
    '1525_880': 27.7303,
    '1525_881': 27.7899,
    '1525_882': 27.8354,
    '1525_883': 27.875,
    '1525_884': 27.9045,
    '1525_885': 27.9239,
    '1525_886': 27.93,
    '1525_887': 27.9235,
    '1525_888': 27.9049,
    '1525_889': 27.875,
    '1525_890': 27.8441,
    '1525_891': 27.8163,
    '1525_892': 27.7928,
    '1525_893': 27.7742,
    '1525_894': 27.7608,
    '1525_895': 27.7503,
    '1525_896': 27.7392,
    '1525_897': 27.7279,
    '1525_898': 27.7149,
    '1525_899': 27.6984,
    '1525_900': 27.6781,
    '1525_901': 27.6542,
    '1525_902': 27.6265,
    '1525_903': 27.595,
    '1525_904': 27.5603,
    '1525_905': 27.523,
    '1525_906': 27.4831,
    '1525_907': 27.4419,
    '1525_908': 27.4,
    '1526_836': 28.2092,
    '1526_837': 28.3104,
    '1526_838': 28.4103,
    '1526_839': 28.4959,
    '1526_840': 28.5534,
    '1526_841': 28.5851,
    '1526_842': 28.5915,
    '1526_843': 28.5642,
    '1526_860': 27.0196,
    '1526_861': 26.9654,
    '1526_862': 26.9243,
    '1526_863': 26.8969,
    '1526_864': 26.8819,
    '1526_865': 26.879,
    '1526_866': 26.8852,
    '1526_867': 26.8868,
    '1526_868': 26.8941,
    '1526_869': 26.9148,
    '1526_870': 26.9465,
    '1526_871': 26.9892,
    '1526_872': 27.0463,
    '1526_873': 27.1184,
    '1526_874': 27.2063,
    '1526_875': 27.3035,
    '1526_876': 27.3984,
    '1526_877': 27.4859,
    '1526_878': 27.5631,
    '1526_879': 27.6366,
    '1526_880': 27.7067,
    '1526_881': 27.7675,
    '1526_882': 27.8137,
    '1526_883': 27.8522,
    '1526_884': 27.8825,
    '1526_885': 27.9028,
    '1526_886': 27.9103,
    '1526_887': 27.9062,
    '1526_888': 27.8897,
    '1526_889': 27.8589,
    '1526_890': 27.8225,
    '1526_891': 27.7856,
    '1526_892': 27.7527,
    '1526_893': 27.729,
    '1526_894': 27.7129,
    '1526_895': 27.7014,
    '1526_896': 27.6904,
    '1526_897': 27.6798,
    '1526_898': 27.668,
    '1526_899': 27.6525,
    '1526_900': 27.6328,
    '1526_901': 27.6092,
    '1526_902': 27.5819,
    '1526_903': 27.5506,
    '1526_904': 27.516,
    '1526_905': 27.4782,
    '1526_906': 27.4376,
    '1526_907': 27.3953,
    '1526_908': 27.3525,
    '1527_836': 28.1558,
    '1527_837': 28.2594,
    '1527_838': 28.3608,
    '1527_839': 28.4456,
    '1527_840': 28.5006,
    '1527_841': 28.5316,
    '1527_842': 28.5363,
    '1527_843': 28.5118,
    '1527_860': 26.9788,
    '1527_861': 26.9193,
    '1527_862': 26.8755,
    '1527_863': 26.8473,
    '1527_864': 26.8334,
    '1527_865': 26.8308,
    '1527_866': 26.8354,
    '1527_867': 26.8422,
    '1527_868': 26.8546,
    '1527_869': 26.8783,
    '1527_870': 26.913,
    '1527_871': 26.9593,
    '1527_872': 27.0185,
    '1527_873': 27.0921,
    '1527_874': 27.1772,
    '1527_875': 27.2711,
    '1527_876': 27.3671,
    '1527_877': 27.4546,
    '1527_878': 27.5316,
    '1527_879': 27.6062,
    '1527_880': 27.6771,
    '1527_881': 27.74,
    '1527_882': 27.7911,
    '1527_883': 27.8316,
    '1527_884': 27.8634,
    '1527_885': 27.8849,
    '1527_886': 27.8945,
    '1527_887': 27.8929,
    '1527_888': 27.8789,
    '1527_889': 27.8464,
    '1527_890': 27.8047,
    '1527_891': 27.7589,
    '1527_892': 27.715,
    '1527_893': 27.6864,
    '1527_894': 27.6676,
    '1527_895': 27.6551,
    '1527_896': 27.6444,
    '1527_897': 27.6347,
    '1527_898': 27.6244,
    '1527_899': 27.6102,
    '1527_900': 27.5908,
    '1527_901': 27.5671,
    '1527_902': 27.5397,
    '1527_903': 27.5087,
    '1527_904': 27.4741,
    '1527_905': 27.4359,
    '1527_906': 27.3943,
    '1527_907': 27.351,
    '1527_908': 27.3069,
    '1528_836': 28.0973,
    '1528_837': 28.2009,
    '1528_838': 28.3021,
    '1528_839': 28.387,
    '1528_840': 28.4402,
    '1528_841': 28.4702,
    '1528_842': 28.4734,
    '1528_843': 28.4526,
    '1528_860': 26.942,
    '1528_861': 26.8798,
    '1528_862': 26.8312,
    '1528_863': 26.8001,
    '1528_864': 26.7866,
    '1528_865': 26.7839,
    '1528_866': 26.7881,
    '1528_867': 26.7962,
    '1528_868': 26.8122,
    '1528_869': 26.8384,
    '1528_870': 26.8757,
    '1528_871': 26.9246,
    '1528_872': 26.9856,
    '1528_873': 27.0591,
    '1528_874': 27.1439,
    '1528_875': 27.2365,
    '1528_876': 27.3328,
    '1528_877': 27.4195,
    '1528_878': 27.4973,
    '1528_879': 27.5734,
    '1528_880': 27.6463,
    '1528_881': 27.7126,
    '1528_882': 27.7673,
    '1528_883': 27.8113,
    '1528_884': 27.8439,
    '1528_885': 27.8656,
    '1528_886': 27.8766,
    '1528_887': 27.8759,
    '1528_888': 27.861,
    '1528_889': 27.8304,
    '1528_890': 27.7893,
    '1528_891': 27.74,
    '1528_892': 27.6911,
    '1528_893': 27.6516,
    '1528_894': 27.6266,
    '1528_895': 27.6112,
    '1528_896': 27.5998,
    '1528_897': 27.591,
    '1528_898': 27.5824,
    '1528_899': 27.5698,
    '1528_900': 27.55,
    '1528_901': 27.5257,
    '1528_902': 27.4981,
    '1528_903': 27.4674,
    '1528_904': 27.4333,
    '1528_905': 27.3946,
    '1528_906': 27.3526,
    '1528_907': 27.309,
    '1528_908': 27.2655,
    '1529_836': 28.0355,
    '1529_837': 28.1362,
    '1529_838': 28.2331,
    '1529_839': 28.313,
    '1529_840': 28.3677,
    '1529_841': 28.3988,
    '1529_842': 28.4062,
    '1529_843': 28.3917,
    '1529_860': 26.9044,
    '1529_861': 26.8448,
    '1529_862': 26.7974,
    '1529_863': 26.7645,
    '1529_864': 26.7469,
    '1529_865': 26.7403,
    '1529_866': 26.7428,
    '1529_867': 26.7514,
    '1529_868': 26.7679,
    '1529_869': 26.7951,
    '1529_870': 26.8341,
    '1529_871': 26.8848,
    '1529_872': 26.9476,
    '1529_873': 27.022,
    '1529_874': 27.1072,
    '1529_875': 27.1986,
    '1529_876': 27.2949,
    '1529_877': 27.3828,
    '1529_878': 27.4656,
    '1529_879': 27.5434,
    '1529_880': 27.6183,
    '1529_881': 27.6865,
    '1529_882': 27.7454,
    '1529_883': 27.7895,
    '1529_884': 27.8205,
    '1529_885': 27.8402,
    '1529_886': 27.8522,
    '1529_887': 27.8502,
    '1529_888': 27.8371,
    '1529_889': 27.8093,
    '1529_890': 27.7717,
    '1529_891': 27.721,
    '1529_892': 27.67,
    '1529_893': 27.6249,
    '1529_894': 27.5908,
    '1529_895': 27.5706,
    '1529_896': 27.5571,
    '1529_897': 27.5477,
    '1529_898': 27.5401,
    '1529_899': 27.5297,
    '1529_900': 27.5083,
    '1529_901': 27.4831,
    '1529_902': 27.4556,
    '1529_903': 27.4255,
    '1529_904': 27.3926,
    '1529_905': 27.3532,
    '1529_906': 27.311,
    '1529_907': 27.2681,
    '1529_908': 27.2254,
    '1530_836': 27.9746,
    '1530_837': 28.0709,
    '1530_838': 28.164,
    '1530_839': 28.2409,
    '1530_840': 28.2948,
    '1530_841': 28.3272,
    '1530_842': 28.3382,
    '1530_843': 28.3303,
    '1530_860': 26.8677,
    '1530_861': 26.8114,
    '1530_862': 26.7657,
    '1530_863': 26.7302,
    '1530_864': 26.7095,
    '1530_865': 26.6996,
    '1530_866': 26.6997,
    '1530_867': 26.7059,
    '1530_868': 26.7208,
    '1530_869': 26.7477,
    '1530_870': 26.7875,
    '1530_871': 26.84,
    '1530_872': 26.9048,
    '1530_873': 26.981,
    '1530_874': 27.0674,
    '1530_875': 27.1583,
    '1530_876': 27.2542,
    '1530_877': 27.3468,
    '1530_878': 27.4333,
    '1530_879': 27.5125,
    '1530_880': 27.5888,
    '1530_881': 27.661,
    '1530_882': 27.7217,
    '1530_883': 27.7606,
    '1530_884': 27.786,
    '1530_885': 27.8042,
    '1530_886': 27.8167,
    '1530_887': 27.8135,
    '1530_888': 27.8015,
    '1530_889': 27.7754,
    '1530_890': 27.7388,
    '1530_891': 27.6931,
    '1530_892': 27.6452,
    '1530_893': 27.6001,
    '1530_894': 27.5625,
    '1530_895': 27.535,
    '1530_896': 27.5173,
    '1530_897': 27.5043,
    '1530_898': 27.4935,
    '1530_899': 27.4809,
    '1530_900': 27.4617,
    '1530_901': 27.4381,
    '1530_902': 27.411,
    '1530_903': 27.3806,
    '1530_904': 27.347,
    '1530_905': 27.3093,
    '1530_906': 27.2686,
    '1530_907': 27.2272,
    '1530_908': 27.186,
    '1531_836': 27.9188,
    '1531_837': 28.0093,
    '1531_838': 28.0996,
    '1531_839': 28.1767,
    '1531_840': 28.2318,
    '1531_841': 28.2633,
    '1531_842': 28.2748,
    '1531_843': 28.2694,
    '1531_860': 26.836,
    '1531_861': 26.7793,
    '1531_862': 26.7329,
    '1531_863': 26.6972,
    '1531_864': 26.673,
    '1531_865': 26.659,
    '1531_866': 26.6551,
    '1531_867': 26.6588,
    '1531_868': 26.6713,
    '1531_869': 26.6967,
    '1531_870': 26.7369,
    '1531_871': 26.7911,
    '1531_872': 26.8583,
    '1531_873': 26.937,
    '1531_874': 27.0256,
    '1531_875': 27.1175,
    '1531_876': 27.2126,
    '1531_877': 27.3077,
    '1531_878': 27.3976,
    '1531_879': 27.4806,
    '1531_880': 27.5581,
    '1531_881': 27.6308,
    '1531_882': 27.6911,
    '1531_883': 27.7279,
    '1531_884': 27.7515,
    '1531_885': 27.7742,
    '1531_886': 27.7799,
    '1531_887': 27.7761,
    '1531_888': 27.7626,
    '1531_889': 27.7373,
    '1531_890': 27.7026,
    '1531_891': 27.6611,
    '1531_892': 27.617,
    '1531_893': 27.5739,
    '1531_894': 27.5363,
    '1531_895': 27.5062,
    '1531_896': 27.4826,
    '1531_897': 27.4639,
    '1531_898': 27.4487,
    '1531_899': 27.4343,
    '1531_900': 27.4162,
    '1531_901': 27.3937,
    '1531_902': 27.3666,
    '1531_903': 27.3357,
    '1531_904': 27.3017,
    '1531_905': 27.2648,
    '1531_906': 27.2261,
    '1531_907': 27.1868,
    '1531_908': 27.1478,
    '1532_836': 27.8677,
    '1532_837': 27.9543,
    '1532_838': 28.0476,
    '1532_839': 28.1268,
    '1532_840': 28.1797,
    '1532_841': 28.2065,
    '1532_842': 28.2151,
    '1532_843': 28.2111,
    '1532_860': 26.8048,
    '1532_861': 26.7473,
    '1532_862': 26.7,
    '1532_863': 26.6632,
    '1532_864': 26.6362,
    '1532_865': 26.6187,
    '1532_866': 26.6116,
    '1532_867': 26.6126,
    '1532_868': 26.6226,
    '1532_869': 26.6452,
    '1532_870': 26.6853,
    '1532_871': 26.7406,
    '1532_872': 26.8104,
    '1532_873': 26.892,
    '1532_874': 26.9816,
    '1532_875': 27.0771,
    '1532_876': 27.173,
    '1532_877': 27.2682,
    '1532_878': 27.3601,
    '1532_879': 27.4464,
    '1532_880': 27.5248,
    '1532_881': 27.597,
    '1532_882': 27.6564,
    '1532_883': 27.697,
    '1532_884': 27.7228,
    '1532_885': 27.7414,
    '1532_886': 27.7464,
    '1532_887': 27.741,
    '1532_888': 27.7259,
    '1532_889': 27.7005,
    '1532_890': 27.6667,
    '1532_891': 27.6276,
    '1532_892': 27.5862,
    '1532_893': 27.5452,
    '1532_894': 27.5083,
    '1532_895': 27.4773,
    '1532_896': 27.451,
    '1532_897': 27.4295,
    '1532_898': 27.4116,
    '1532_899': 27.3944,
    '1532_900': 27.3757,
    '1532_901': 27.3526,
    '1532_902': 27.3247,
    '1532_903': 27.2927,
    '1532_904': 27.258,
    '1532_905': 27.2216,
    '1532_906': 27.1841,
    '1532_907': 27.1466,
    '1532_908': 27.109,
    '1533_860': 26.7722,
    '1533_861': 26.7143,
    '1533_862': 26.6664,
    '1533_863': 26.6282,
    '1533_864': 26.5989,
    '1533_865': 26.5791,
    '1533_866': 26.5701,
    '1533_867': 26.5702,
    '1533_868': 26.58,
    '1533_869': 26.602,
    '1533_870': 26.6386,
    '1533_871': 26.6928,
    '1533_872': 26.764,
    '1533_873': 26.8488,
    '1533_874': 26.9406,
    '1533_875': 27.0358,
    '1533_876': 27.1324,
    '1533_877': 27.2281,
    '1533_878': 27.3211,
    '1533_879': 27.4091,
    '1533_880': 27.4906,
    '1533_881': 27.5636,
    '1533_882': 27.624,
    '1533_883': 27.6665,
    '1533_884': 27.6943,
    '1533_885': 27.7109,
    '1533_886': 27.7147,
    '1533_887': 27.7078,
    '1533_888': 27.6911,
    '1533_889': 27.6649,
    '1533_890': 27.6311,
    '1533_891': 27.5927,
    '1533_892': 27.5524,
    '1533_893': 27.5125,
    '1533_894': 27.4763,
    '1533_895': 27.4452,
    '1533_896': 27.4182,
    '1533_897': 27.3959,
    '1533_898': 27.3769,
    '1533_899': 27.3587,
    '1533_900': 27.3392,
    '1533_901': 27.3145,
    '1533_902': 27.285,
    '1533_903': 27.2514,
    '1533_904': 27.2157,
    '1533_905': 27.179,
    '1533_906': 27.1426,
    '1533_907': 27.1061,
    '1533_908': 27.0694,
    '1534_860': 26.7368,
    '1534_861': 26.679,
    '1534_862': 26.6313,
    '1534_863': 26.5917,
    '1534_864': 26.5603,
    '1534_865': 26.5389,
    '1534_866': 26.5293,
    '1534_867': 26.5299,
    '1534_868': 26.541,
    '1534_869': 26.5646,
    '1534_870': 26.6022,
    '1534_871': 26.655,
    '1534_872': 26.7232,
    '1534_873': 26.8096,
    '1534_874': 26.903,
    '1534_875': 26.9982,
    '1534_876': 27.0947,
    '1534_877': 27.1908,
    '1534_878': 27.2844,
    '1534_879': 27.3734,
    '1534_880': 27.4568,
    '1534_881': 27.531,
    '1534_882': 27.5923,
    '1534_883': 27.6365,
    '1534_884': 27.6656,
    '1534_885': 27.6816,
    '1534_886': 27.6841,
    '1534_887': 27.6754,
    '1534_888': 27.657,
    '1534_889': 27.6296,
    '1534_890': 27.5949,
    '1534_891': 27.5555,
    '1534_892': 27.5149,
    '1534_893': 27.4753,
    '1534_894': 27.4394,
    '1534_895': 27.4085,
    '1534_896': 27.3818,
    '1534_897': 27.3598,
    '1534_898': 27.341,
    '1534_899': 27.322,
    '1534_900': 27.3012,
    '1534_901': 27.2764,
    '1534_902': 27.246,
    '1534_903': 27.2109,
    '1534_904': 27.1742,
    '1534_905': 27.1372,
    '1534_906': 27.1019,
    '1534_907': 27.0666,
    '1534_908': 27.0306,
    '1535_860': 26.6983,
    '1535_861': 26.6404,
    '1535_862': 26.5953,
    '1535_863': 26.5534,
    '1535_864': 26.5195,
    '1535_865': 26.4972,
    '1535_866': 26.4878,
    '1535_867': 26.4895,
    '1535_868': 26.5026,
    '1535_869': 26.5284,
    '1535_870': 26.5682,
    '1535_871': 26.6227,
    '1535_872': 26.6925,
    '1535_873': 26.7754,
    '1535_874': 26.8682,
    '1535_875': 26.9619,
    '1535_876': 27.0575,
    '1535_877': 27.1532,
    '1535_878': 27.2476,
    '1535_879': 27.3376,
    '1535_880': 27.4257,
    '1535_881': 27.5015,
    '1535_882': 27.563,
    '1535_883': 27.6073,
    '1535_884': 27.6367,
    '1535_885': 27.6524,
    '1535_886': 27.6539,
    '1535_887': 27.6436,
    '1535_888': 27.6232,
    '1535_889': 27.5951,
    '1535_890': 27.5594,
    '1535_891': 27.517,
    '1535_892': 27.475,
    '1535_893': 27.4356,
    '1535_894': 27.3996,
    '1535_895': 27.3684,
    '1535_896': 27.343,
    '1535_897': 27.3226,
    '1535_898': 27.3049,
    '1535_899': 27.2862,
    '1535_900': 27.2647,
    '1535_901': 27.2394,
    '1535_902': 27.2081,
    '1535_903': 27.1722,
    '1535_904': 27.1355,
    '1535_905': 27.0993,
    '1535_906': 27.0646,
    '1535_907': 27.0299,
    '1535_908': 26.9943,
    '1536_860': 26.6568,
    '1536_861': 26.5969,
    '1536_862': 26.5583,
    '1536_863': 26.5122,
    '1536_864': 26.4757,
    '1536_865': 26.4535,
    '1536_866': 26.4446,
    '1536_867': 26.4478,
    '1536_868': 26.4623,
    '1536_869': 26.4897,
    '1536_870': 26.5308,
    '1536_871': 26.5862,
    '1536_872': 26.6566,
    '1536_873': 26.7327,
    '1536_874': 26.8294,
    '1536_875': 26.9226,
    '1536_876': 27.018,
    '1536_877': 27.1132,
    '1536_878': 27.2054,
    '1536_879': 27.2977,
    '1536_880': 27.3856,
    '1536_881': 27.4651,
    '1536_882': 27.5281,
    '1536_883': 27.5724,
    '1536_884': 27.6023,
    '1536_885': 27.6192,
    '1536_886': 27.621,
    '1536_887': 27.6105,
    '1536_888': 27.5877,
    '1536_889': 27.5598,
    '1536_890': 27.5247,
    '1536_891': 27.4781,
    '1536_892': 27.435,
    '1536_893': 27.3958,
    '1536_894': 27.3592,
    '1536_895': 27.3267,
    '1536_896': 27.3033,
    '1536_897': 27.2851,
    '1536_898': 27.2699,
    '1536_899': 27.2524,
    '1536_900': 27.2309,
    '1536_901': 27.2065,
    '1536_902': 27.1745,
    '1536_903': 27.1362,
    '1536_904': 27.0985,
    '1536_905': 27.0631,
    '1536_906': 27.0293,
    '1536_907': 26.9956,
    '1536_908': 26.9604,
    '1537_860': 26.6125,
    '1537_861': 26.5543,
    '1537_862': 26.5089,
    '1537_863': 26.4665,
    '1537_864': 26.4322,
    '1537_865': 26.4098,
    '1537_866': 26.401,
    '1537_867': 26.4043,
    '1537_868': 26.4193,
    '1537_869': 26.4472,
    '1537_870': 26.4882,
    '1537_871': 26.5425,
    '1537_872': 26.61,
    '1537_873': 26.6888,
    '1537_874': 26.7833,
    '1537_875': 26.8786,
    '1537_876': 26.977,
    '1537_877': 27.0756,
    '1537_878': 27.1609,
    '1537_879': 27.2445,
    '1537_880': 27.3369,
    '1537_881': 27.4127,
    '1537_882': 27.4729,
    '1537_883': 27.5203,
    '1537_884': 27.5541,
    '1537_885': 27.5726,
    '1537_886': 27.5791,
    '1537_887': 27.5724,
    '1537_888': 27.5491,
    '1537_889': 27.5198,
    '1537_890': 27.4862,
    '1537_891': 27.4463,
    '1537_892': 27.4023,
    '1537_893': 27.3596,
    '1537_894': 27.3219,
    '1537_895': 27.2906,
    '1537_896': 27.2669,
    '1537_897': 27.2493,
    '1537_898': 27.2376,
    '1537_899': 27.2216,
    '1537_900': 27.1992,
    '1537_901': 27.1776,
    '1537_902': 27.1447,
    '1537_903': 27.1049,
    '1537_904': 27.064,
    '1537_905': 27.0291,
    '1537_906': 26.9963,
    '1537_907': 26.9636,
    '1537_908': 26.9287,
    '1538_860': 26.5673,
    '1538_861': 26.5092,
    '1538_862': 26.4609,
    '1538_863': 26.4201,
    '1538_864': 26.3875,
    '1538_865': 26.3656,
    '1538_866': 26.3569,
    '1538_867': 26.3602,
    '1538_868': 26.3752,
    '1538_869': 26.4029,
    '1538_870': 26.4429,
    '1538_871': 26.4951,
    '1538_872': 26.5598,
    '1538_873': 26.6367,
    '1538_874': 26.7275,
    '1538_875': 26.8286,
    '1538_876': 26.9303,
    '1538_877': 27.028,
    '1538_878': 27.1165,
    '1538_879': 27.2007,
    '1538_880': 27.2838,
    '1538_881': 27.3547,
    '1538_882': 27.4116,
    '1538_883': 27.4562,
    '1538_884': 27.4886,
    '1538_885': 27.5079,
    '1538_886': 27.5146,
    '1538_887': 27.5092,
    '1538_888': 27.4917,
    '1538_889': 27.4676,
    '1538_890': 27.4397,
    '1538_891': 27.4077,
    '1538_892': 27.363,
    '1538_893': 27.3179,
    '1538_894': 27.2804,
    '1538_895': 27.2508,
    '1538_896': 27.2297,
    '1538_897': 27.216,
    '1538_898': 27.2073,
    '1538_899': 27.1918,
    '1538_900': 27.1703,
    '1538_901': 27.1464,
    '1538_902': 27.1158,
    '1538_903': 27.0784,
    '1538_904': 27.0394,
    '1538_905': 26.9999,
    '1538_906': 26.9646,
    '1538_907': 26.9326,
    '1538_908': 26.8975,
    '1539_860': 26.5222,
    '1539_861': 26.4644,
    '1539_862': 26.4157,
    '1539_863': 26.3752,
    '1539_864': 26.3433,
    '1539_865': 26.3218,
    '1539_866': 26.3129,
    '1539_867': 26.3168,
    '1539_868': 26.3319,
    '1539_869': 26.359,
    '1539_870': 26.3981,
    '1539_871': 26.4493,
    '1539_872': 26.5143,
    '1539_873': 26.5937,
    '1539_874': 26.6859,
    '1539_875': 26.7852,
    '1539_876': 26.8853,
    '1539_877': 26.9813,
    '1539_878': 27.0709,
    '1539_879': 27.1538,
    '1539_880': 27.2305,
    '1539_881': 27.2951,
    '1539_882': 27.3463,
    '1539_883': 27.3863,
    '1539_884': 27.4158,
    '1539_885': 27.4344,
    '1539_886': 27.4405,
    '1539_887': 27.436,
    '1539_888': 27.4226,
    '1539_889': 27.4027,
    '1539_890': 27.3774,
    '1539_891': 27.3458,
    '1539_892': 27.3055,
    '1539_893': 27.2634,
    '1539_894': 27.229,
    '1539_895': 27.2022,
    '1539_896': 27.1835,
    '1539_897': 27.1737,
    '1539_898': 27.167,
    '1539_899': 27.1557,
    '1539_900': 27.1372,
    '1539_901': 27.1136,
    '1539_902': 27.0843,
    '1539_903': 27.0488,
    '1539_904': 27.0103,
    '1539_905': 26.9699,
    '1539_906': 26.9306,
    '1539_907': 26.8952,
    '1539_908': 26.8617,
    '1540_860': 26.4808,
    '1540_861': 26.4235,
    '1540_862': 26.3747,
    '1540_863': 26.3339,
    '1540_864': 26.302,
    '1540_865': 26.281,
    '1540_866': 26.272,
    '1540_867': 26.277,
    '1540_868': 26.2926,
    '1540_869': 26.3195,
    '1540_870': 26.3586,
    '1540_871': 26.4093,
    '1540_872': 26.4763,
    '1540_873': 26.5558,
    '1540_874': 26.6491,
    '1540_875': 26.7451,
    '1540_876': 26.8417,
    '1540_877': 26.9339,
    '1540_878': 27.0216,
    '1540_879': 27.1009,
    '1540_880': 27.1736,
    '1540_881': 27.2323,
    '1540_882': 27.2773,
    '1540_883': 27.3128,
    '1540_884': 27.34,
    '1540_885': 27.358,
    '1540_886': 27.3639,
    '1540_887': 27.3606,
    '1540_888': 27.3496,
    '1540_889': 27.3335,
    '1540_890': 27.3109,
    '1540_891': 27.2815,
    '1540_892': 27.245,
    '1540_893': 27.2059,
    '1540_894': 27.1773,
    '1540_895': 27.1532,
    '1540_896': 27.1364,
    '1540_897': 27.1308,
    '1540_898': 27.1267,
    '1540_899': 27.1197,
    '1540_900': 27.1034,
    '1540_901': 27.0813,
    '1540_902': 27.0535,
    '1540_903': 27.0195,
    '1540_904': 26.9822,
    '1540_905': 26.9431,
    '1540_906': 26.904,
    '1540_907': 26.8668,
    '1540_908': 26.8309,
    '1541_860': 26.4492,
    '1541_861': 26.3896,
    '1541_862': 26.3392,
    '1541_863': 26.2981,
    '1541_864': 26.2671,
    '1541_865': 26.2472,
    '1541_866': 26.2399,
    '1541_867': 26.2446,
    '1541_868': 26.2607,
    '1541_869': 26.2893,
    '1541_870': 26.3294,
    '1541_871': 26.3821,
    '1541_872': 26.4484,
    '1541_873': 26.526,
    '1541_874': 26.6129,
    '1541_875': 26.703,
    '1541_876': 26.7929,
    '1541_877': 26.8795,
    '1541_878': 26.9614,
    '1541_879': 27.036,
    '1541_880': 27.1025,
    '1541_881': 27.1576,
    '1541_882': 27.2014,
    '1541_883': 27.2356,
    '1541_884': 27.2618,
    '1541_885': 27.2795,
    '1541_886': 27.2871,
    '1541_887': 27.2867,
    '1541_888': 27.2795,
    '1541_889': 27.2664,
    '1541_890': 27.2474,
    '1541_891': 27.2229,
    '1541_892': 27.1937,
    '1541_893': 27.1634,
    '1541_894': 27.1386,
    '1541_895': 27.1187,
    '1541_896': 27.1041,
    '1541_897': 27.0965,
    '1541_898': 27.0907,
    '1541_899': 27.0823,
    '1541_900': 27.0682,
    '1541_901': 27.0487,
    '1541_902': 27.023,
    '1541_903': 26.9905,
    '1541_904': 26.9548,
    '1541_905': 26.9175,
    '1541_906': 26.8797,
    '1541_907': 26.8424,
    '1541_908': 26.8049,
    '1542_860': 26.4252,
    '1542_861': 26.3633,
    '1542_862': 26.3115,
    '1542_863': 26.2694,
    '1542_864': 26.2391,
    '1542_865': 26.2199,
    '1542_866': 26.2136,
    '1542_867': 26.2174,
    '1542_868': 26.2322,
    '1542_869': 26.2603,
    '1542_870': 26.3038,
    '1542_871': 26.3593,
    '1542_872': 26.4249,
    '1542_873': 26.4992,
    '1542_874': 26.5793,
    '1542_875': 26.662,
    '1542_876': 26.7443,
    '1542_877': 26.8239,
    '1542_878': 26.8991,
    '1542_879': 26.9679,
    '1542_880': 27.0291,
    '1542_881': 27.0812,
    '1542_882': 27.1239,
    '1542_883': 27.1579,
    '1542_884': 27.184,
    '1542_885': 27.2025,
    '1542_886': 27.2123,
    '1542_887': 27.215,
    '1542_888': 27.2118,
    '1542_889': 27.2025,
    '1542_890': 27.1881,
    '1542_891': 27.1695,
    '1542_892': 27.1478,
    '1542_893': 27.1251,
    '1542_894': 27.1051,
    '1542_895': 27.0888,
    '1542_896': 27.0756,
    '1542_897': 27.0661,
    '1542_898': 27.058,
    '1542_899': 27.0478,
    '1542_900': 27.0335,
    '1542_901': 27.0147,
    '1542_902': 26.9905,
    '1542_903': 26.9606,
    '1542_904': 26.9276,
    '1542_905': 26.8924,
    '1542_906': 26.8561,
    '1542_907': 26.8193,
    '1542_908': 26.7822,
    'empty': 0
};