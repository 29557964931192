import api from '@/providers/api'

const kumiki = {

    getCode(payload) {
        return api.post('integrations/kumiki/grant-access',payload)
    },
    addPointCloud(payload){
        return api.post('integrations/kumiki/add-point-cloud',payload)        
    },
    verifyToken(payload){
        return api.post('integrations/kumiki/verify-token',payload)        
    }

    
}

export default kumiki