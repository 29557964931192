import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cf4fa4a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col w-70 py-2 font-body h-screen bg-neutral-900 items-center text-white z-10" }
const _hoisted_2 = { class: "h-full relative w-full" }
const _hoisted_3 = { class: "relative w-full mb-1 overflow-hidden overflow-y-auto text-sm flex flex-col" }
const _hoisted_4 = { class: "flex justify-between w-full py-4 px-3" }
const _hoisted_5 = { class: "flex items-center pr-6 hover:bg-neutral-800 hover:w-full" }
const _hoisted_6 = { class: "mr-1" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "font-semibold" }
const _hoisted_9 = { class: "h-full relative w-full" }
const _hoisted_10 = { class: "relative w-full flex-shrink-0" }
const _hoisted_11 = { class: "flex items-center ml-2 w-4/5" }
const _hoisted_12 = ["value", "id", "onClick", "checked"]
const _hoisted_13 = ["onClick", "for"]
const _hoisted_14 = { class: "flex items-center ml-2 w-4/5" }
const _hoisted_15 = ["onChange", "checked"]
const _hoisted_16 = ["onClick"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("img", {
                class: "object-none stroke-current stroke-2 text-white",
                src: require('@/statics/layer-outline.svg')
              }, null, 8, _hoisted_7)
            ]),
            _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('viewer.file_manager.layer')) + "選択する", 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", {
              class: _normalizeClass(["flex-shrink h-56 mr-1", 
                                $data.isData
                                    ? 'max-h-80 transition-all duration-500 overflow-hidden overflow-y-auto'
                                    : 'overflow-hidden transition-all max-h-0 duration-500 overflow-y-auto'
                            ])
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.pointcloud.list, (tileset, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: `${index}`,
                  class: "flex items-center justify-between text-left w-full relative hover:bg-neutral-800 hover:w-full"
                }, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("input", {
                      type: "radio",
                      name: "clip_target",
                      value: index,
                      id: index,
                      onClick: $event => ($options.onClipTarget(tileset, index)),
                      "data-testid": "clip-target-radio",
                      checked: index === 0
                    }, null, 8, _hoisted_12),
                    _createElementVNode("label", {
                      onClick: $event => ($options.onSelectPointcloud(index)),
                      class: "cursor-pointer truncate",
                      for: index
                    }, _toDisplayString(tileset.filename), 9, _hoisted_13)
                  ])
                ]))
              }), 128)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.vectors.list, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: `${index}`,
                  class: "flex items-center justify-between text-left w-full relative hover:bg-neutral-800 hover:w-full"
                }, [
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("input", {
                      onChange: $event => ($options.visibility('vectors', index, item, $event)),
                      type: "checkbox",
                      class: "form-checkbox checkbox rounded mr-2",
                      checked: item.visible == 1 ? 'checked' : false,
                      "data-testid": "vector-checkbox"
                    }, null, 40, _hoisted_15),
                    _createElementVNode("label", {
                      onClick: $event => ($options.onSelectActiveProperty('vector', item)),
                      class: "text-sm cursor-pointer",
                      for: ""
                    }, _toDisplayString(item.label), 9, _hoisted_16)
                  ])
                ]))
              }), 128))
            ], 2)
          ])
        ])
      ])
    ])
  ]))
}