import { toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "relative z-50 flex justify-end items-center dark:text-white" }
const _hoisted_2 = { class: "flex flex-col flex-shrink absolute rounded-sm text-sm w-40 shadow-2xl bg-white dark:bg-neutral-600" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["font-body transition ease-in-out duration-150", {'dark' : $props.dark}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        ($data.isRetry)
          ? _withDirectives((_openBlock(), _createElementBlock("button", {
              key: 0,
              "data-testid": "btnRetry",
              class: "p-2 hover:text-error-400",
              onClick: _cache[0] || (_cache[0] = (...args) => ($options.onRetry && $options.onRetry(...args)))
            }, _toDisplayString(_ctx.$t('actions.retry')), 513)), [
              [_vShow, $props.status.numberOfFiles == 1 && $props.status.hasFailed.length == 1]
            ])
          : _createCommentVNode("", true),
        ($data.isView)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: "p-2 hover:text-error-400",
              "data-testid": "btnView",
              onClick: _cache[1] || (_cache[1] = (...args) => ($options.onView && $options.onView(...args)))
            }, _toDisplayString(_ctx.$t('actions.view')), 1))
          : _createCommentVNode("", true),
        ($data.isEdit)
          ? (_openBlock(), _createElementBlock("button", {
              key: 2,
              class: "p-2 hover:text-error-400",
              "data-testid": "action-edit",
              onClick: _cache[2] || (_cache[2] = (...args) => ($options.onEdit && $options.onEdit(...args)))
            }, _toDisplayString(_ctx.$t('actions.edit')), 1))
          : _createCommentVNode("", true),
        ($data.isEditFolder)
          ? (_openBlock(), _createElementBlock("button", {
              key: 3,
              class: "p-2 hover:text-error-400",
              "data-testid": "action-edit",
              onClick: _cache[3] || (_cache[3] = (...args) => ($options.onEditFolder && $options.onEditFolder(...args)))
            }, _toDisplayString(_ctx.$t('buttons.edit')), 1))
          : _createCommentVNode("", true),
        ($data.isMoveFolder)
          ? (_openBlock(), _createElementBlock("button", {
              key: 4,
              class: "p-2 hover:text-error-400 hover:font-openSans hover:font-semibold",
              "data-testid": "action-move",
              onClick: _cache[4] || (_cache[4] = (...args) => ($options.onMoveFolder && $options.onMoveFolder(...args)))
            }, _toDisplayString(_ctx.$t('actions.move')), 1))
          : _createCommentVNode("", true),
        ($data.isRemove)
          ? (_openBlock(), _createElementBlock("button", {
              key: 5,
              class: "p-2 hover:text-error-400 hover:font-openSans hover:font-semibold",
              "data-testid": "action-delete",
              onClick: _cache[5] || (_cache[5] = (...args) => ($options.onRemove && $options.onRemove(...args)))
            }, _toDisplayString(_ctx.$t('actions.remove')), 1))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}