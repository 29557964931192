import { createApp } from 'vue';

// Main app
import App from './App.vue';

// Main App Style
import '@/app.css';
import '@/css/pace.css';
import 'vue-color-kit/dist/vue-color-kit.css'

// Routes
import router from './router';

// Store
import store from './store';

// Localization
import i18n from './locales';

// Cookies
import VueCookies from 'vue3-cookies';

// dropzone
import DropZone from 'dropzone-vue';
import 'dropzone-vue/dist/dropzone-vue.common.css';

// toastr
import Toastr from '@incuca/vue3-toaster';
// progressbar

// swal

//Sentry
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import config from '@/config';

import { TroisJSVuePlugin } from 'troisjs';

import helper from '@/libraries/Helper'

window.CESIUM_BASE_URL = '/static/Cesium/';

const app = createApp(App);

app.config.globalProperties.$helper = helper

router.app = app;

if (config.environment != 'development' && config.environment != 'localtodevserver') {
  Sentry.init({
    environment: config.environment == 'development' ? 'local' : (config.environment == 'nonprod' ? 'development' : (config.environment == 'localtodevserver' ? 'local' : (config.environment == 'production' ? 'production' : 'unknown'))),
    logErrors: config.environment != 'production',
    app,
    dsn: "https://1a38a32bcefb438fad329148e71637c3@o384737.ingest.sentry.io/6106403",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["appv2.dev.cloud.scanx.jp", "appv2.scanx.jp", "localhost", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    release: config.release,
    tracesSampleRate: config.environment == 'production' ? 0.04 : 0.05,
  });
}

app.use(router)
  .use(store)
  .use(i18n)
  .use(VueCookies, {
    expireTimes: '30d',
    path: '/',
    domain: '',
    secure: false,
    sameSite: 'None'
  })
  .use(DropZone)
  .use(Toastr)
  .use(TroisJSVuePlugin);

// app.config.globalProperties.$worker = SimpleWebWorker;

app.mount('#app');
