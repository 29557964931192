import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-541be802"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "absolute w-full z-50 h-full left-0 top-0 flex justify-center"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 1,
  class: "absolute w-full z-50 h-full left-0 top-0 flex justify-center"
}
const _hoisted_4 = ["onClick"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($props.modalStyle=='horizontal')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["grid grid-cols-3 gap-2 content-center w-28 h-full p-2", [$props.isDarkMode ? 'bg-neutral-800' : 'bg-secondShades-100']])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.colors, (color, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: _normalizeClass(["h-2 w-2 rounded-full p-3 color-options transition-all", [color.id === $props.selectedColor ? 'option-selected' : '',color.class]]),
              onClick: $event => ($options.changeColor(color.id))
            }, null, 10, _hoisted_2))
          }), 128))
        ], 2)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
        _createElementVNode("div", {
          class: _normalizeClass(["flex flex-col justify-center p-2 h-28 rounded-md", [$props.isDarkMode ? 'bg-neutral-700' : 'bg-secondShades-100']])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.colors, (color, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: _normalizeClass(["h-2 w-2 rounded-full p-3 mb-2 color-options transition-all", [color.id === $props.selectedColor ? 'option-selected' : '',color.class]]),
              onClick: $event => ($options.changeColor(color.id))
            }, null, 10, _hoisted_4))
          }), 128))
        ], 2)
      ]))
}