/**
 * 3D Model Package
 *
 * Integrates 3D Model import features
 */
import * as Cesium from '@/sdk/cesium';
import config from '@/config';
import ProjectResources from '@/models/ProjectResources';

import { GsiGeoid } from '@/third-party/GsiGeoidMap.js';

export default {
    dataSourceName: '3D_MODELS',
    viewer: null,
    project_id: false,
    store: null,
    dataSource: null,
    pointclouds: null,
    cesium: null,
    auth: null,
    authToken: null,
    share: {
        mode: false,
        list: {}
    },
    plugins: {},

    broadcastProvider: null,

    // Initialize 3d model integraiton
    init(project_id, viewer, store, authToken, plugins, share = null) {
        this.project_id = project_id;
        this.viewer = viewer;
        this.store = store;
        this.authToken = authToken;
        this.plugins = plugins;
        this.cesium = this.plugins.$cesium;
        let dataSource = new Cesium.CustomDataSource(this.dataSourceName);
        this.dataSource = dataSource;
        viewer.dataSources.add(dataSource);
        this.share = share != null ? share : this.share;
        this.notifications();
        this.load();
    },

    notifications() {
        if (this.broadcastProvider) {
            this.broadcastProvider
                .channel(`App.Project.${this.project_id}`)
                .listen('.model.added', (e) => {
                    this.addModels(e.models);
                    this.plugins.$toast.show(
                        `<div class="font-body flex items-center justify-between text-white"><span>Data imported successfully.</span><div>`,
                        { type: 'success', position: 'top', duration: 3000 }
                    );
                });
        }
    },

    // Load data from store
    async load() {
        let models = null;
        if (this.share.mode) {
            models = this.share.list;
            this.store.commit('models/setModelsList', models);
        } else {
            models = this.store.getters['models/listModels'];
        }
        this.render(models);
    },

    // Renders models into view
    render(models) {
        const addTilesetFromModel = async (model) => {
            this.initModelURL(model)
        };

        if (models.length > 0) {
            models.forEach(addTilesetFromModel);
        } else if (models.path !== undefined) {
            addTilesetFromModel(models);
        }
        this.cesium.updateVectorLoading(true);
    },

    async initModelURL(model) {
        const { path } = model;
        const assetUrl = `${config.get().tileSetUrl}/${path}`;
        const s3Url = `${config.get().s3Url}/public/${path}`;
        const online = await this.isUrlOnline(s3Url);
        const url = online ? s3Url : assetUrl;

        try {
            this.addTileset(url, model);
        } catch (error) {
            this.addTileset(assetUrl, model);
        }
    },

    // Add array of models and render
    addModels(models) {
        this.store.dispatch('models/addModels', { models: models });
        this.render(models);
    },

    async addTileset(url, model) {
        let settings = null;
        //console.log(model)

        if (model.settings) {
            settings = JSON.parse(model.settings);
        }

        if (!model.visible) {
            //console.log('skipping')
            return
        }

        if (model.type === 'geojson') {
            this.loadGeoJSON(url, model, settings);
        } else if (model.type === 'kml') {
            this.loadKML(url, model, settings);
        } else if (model.type === 'gltf') {
            //Model with wireframe
            this.loadModelGltf(url, model, settings, true);
            //Model without wireframe
            this.loadModelGltf(url, model, settings, false);
        } else if (model.type == 'gcp') {
            this.loadGCP(model, settings);
        } else {
            //Model with wireframe
            this.load3DTilsetModel(url, model);
            //Model without wireframe
            this.load3DTilsetModel(url, model, false);
        }
    },

    /**
     * 3D Tilesets Models Using Primitives
     * it nees to be primitives in order to avoid the bug generated by importing KML or geojson
     */
    async load3DTilsetModel(url, model, wireframe = true) {
        try {
            let show = model.visible == 0 ? false : true;
            let options = {
                show: show,
                backFaceCulling: false, // to show the back of the model
                ellipsoid: Cesium.Ellipsoid.WGS84,
                shadows: Cesium.ShadowMode.DISABLED,
                lightColor: new Cesium.Cartesian3(3, 2.8, 2.4),
                colorBlendMode: Cesium.Cesium3DTileColorBlendMode.REPLACE,
                enableDebugWireframe: wireframe,
                debugWireframe: wireframe,
                enableShowOutline: false,
                showOutline: false,
                outlineColor: Cesium.Color.BLACK
            };

            //Load Tileset
            let modelTileset = await Cesium.Cesium3DTileset.fromUrl(
                url,
                options
            );

            //Add tileset to viewer
            this.viewer.scene.primitives.add(modelTileset);

            modelTileset.style = new Cesium.Cesium3DTileStyle({
                color: "color('white',0.6)"
            });

            modelTileset.isFaceModel = !wireframe;

            let matrix = this.parseJSON(model.matrix);

            let position =
                model.position !== 'pointcloud'
                    ? this.parseJSON(model.position)
                    : 'pointcloud';

            if (matrix) {
                modelTileset.root.transform = Cesium.Matrix4.IDENTITY;
                modelTileset.modelMatrix = matrix;
                modelTileset._imageBasedLighting._luminanceAtZenith = 6;
            } else if (position) {
                let centerPosition = null;

                // When the targeting poincloud selected
                if (position === 'pointcloud') {
                    let cesium = this.plugins.$cesium;

                    if (cesium.tilesetData.length > 0) {
                        let pointcloud =
                            cesium.tilesetData[cesium.selectedTileset];
                        //Get Center Position of Tileset
                        centerPosition = pointcloud.boundingSphere.center;
                    }
                } else {
                    centerPosition = new Cesium.Cartesian3(
                        Number(position.x),
                        Number(position.y),
                        Number(position.z)
                    );
                }
                if (centerPosition) {
                    this.viewer.scene.primitives.add(
                        new Cesium.DebugModelMatrixPrimitive({
                            modelMatrix: matrix,
                            length: 20.0,
                            width: 2.0,
                            show: true
                        })
                    );

                    if (modelTileset.root.children) {
                        modelTileset.root.children.forEach((tile) => {
                            tile.transform = Cesium.Matrix4.IDENTITY;
                        });
                    }
                    modelTileset._imageBasedLighting._luminanceAtZenith = 6;
                    modelTileset.root.transform = Cesium.Matrix4.IDENTITY; // solved issue DTM going over the ocean
                    modelTileset.modelMatrix =
                        Cesium.Transforms.eastNorthUpToFixedFrame(
                            centerPosition
                        ); // fixes the rotation.
                }
            } else {
                //for newly imported models
                let cesium = this.plugins.$cesium;

                modelTileset.root.transform = Cesium.Matrix4.IDENTITY;

                //default Coordinates for Models if not Pointcloud is present
                let centerPosition = Cesium.Cartesian3.fromDegrees(
                    34.843634,
                    138.066526
                );

                //if point cloud location is not specified spawn it in the first pointcloud
                if (cesium.tilesetData.length > 0) {
                    let pointcloud = cesium.tilesetData[0];
                    centerPosition = pointcloud.boundingSphere.center;
                }
                modelTileset._imageBasedLighting._luminanceAtZenith = 6;
                modelTileset.modelMatrix =
                    Cesium.Transforms.eastNorthUpToFixedFrame(centerPosition);
            }

            //Add model id to object
            modelTileset.model_id = model.model_id;
            this.store.dispatch('models/setModelEntity', {
                entity_id: model.model_id,
                model_id: model.model_id
            });
        } catch (e) {
            console.error('Error loading gltf: ' + url);
        }
    },

    async loadModelGltf(url, model, settings, wireframe = true) {
        try {
            let d = null;
            if (wireframe) {
                //show loading toaster for gltf
                d = this.plugins.$toast.show(
                    `<div class="font-body flex items-center justify-between text-white"><span>Loading model ` +
                        model.label +
                        ` please wait...</span><div>`,
                    {
                        type: 'info',
                        position: 'top',
                        duration: false,
                        dismissible: false
                    }
                );
            }

            // Working for models outputted from dtm and dsm
            let cesium = this.plugins.$cesium;
            let matrix = this.parseJSON(model.matrix);
            let position = this.parseJSON(model.position);
            let centerPosition = new Cesium.Cartesian3(0, 0, 0);

            let show = model.visible == 0 ? false : true;

            //create the Cesium Model Object and load it into the browser
            let cesiumModel = await Cesium.Model.fromGltfAsync({
                url: url,
                upAxis: Cesium.Axis.Z,
                forwardAxis: Cesium.Axis.Y,
                scale: 1,
                shadows: Cesium.ShadowMode.DISABLED,
                backFaceCulling: false,
                color: new Cesium.Color(1, 1, 1, 0.9),
                outlineColor: new Cesium.Color(0, 0, 0, 0.25),
                distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
                    0,
                    1000000
                ),
                enableDebugWireframe: wireframe,
                debugWireframe: wireframe,
                enableShowOutline: false,
                showOutline: false,
                show: show
            });

            //add to viewer
            cesiumModel.isFaceModel = !wireframe;
            this.viewer.scene.primitives.add(cesiumModel);

            cesiumModel.readyEvent.addEventListener(() => {
                if (matrix) {
                    //Priority #1 -> GLTF models which has been edited via Transformer Editor load the save matrix from database
                    centerPosition = Cesium.Matrix4.getTranslation(
                        matrix,
                        new Cesium.Cartesian3()
                    );
                    cesiumModel.modelMatrix = matrix;
                } else if (position) {
                    // Priority #2
                    centerPosition = new Cesium.Cartesian3(
                        Number(position.x),
                        Number(position.y),
                        Number(position.z)
                    );
                    cesiumModel.modelMatrix =
                        Cesium.Transforms.eastNorthUpToFixedFrame(
                            centerPosition
                        );
                } else if (cesium.tilesetData.length > 0) {
                    let pointcloud = cesium.tilesetData[0];
                    centerPosition = pointcloud.boundingSphere.center;
                    cesiumModel.modelMatrix =
                        Cesium.Transforms.eastNorthUpToFixedFrame(
                            centerPosition
                        );
                }

                // add to the viewer
                if (d) {
                    d.destroy();
                    this.plugins.$toast.success(
                        'Loading ' + model.label + ' completed!',
                        { position: 'top', duration: 1500 }
                    );
                }

                cesiumModel._imageBasedLighting._luminanceAtZenith = 6; // adjust brightness of the model
                cesiumModel.centerPosition = centerPosition.clone(); // add center position to tileset because for GLTF when we replace the bounding sphere center the zoom to function is affected
                cesiumModel._url = url; //add also url for reference
                cesiumModel.model_id = model.model_id;
                this.store.dispatch('models/setModelEntity', {
                    entity_id: model.model_id,
                    model_id: model.model_id
                });
                this.heightRefSettings(settings, cesiumModel, model);
            });
        } catch (e) {
            console.error('Error loading gltf: ' + url);
        }
    },

    async loadGeoJSON(url, model, settings) {
        
        let show = model.visible == 0 ? false : true;
        let d = null;

        if (show) {
            // creater loading toaster notification
            d = this.plugins.$toast.show(
                `<div class="font-body flex items-center justify-between text-white"><span>Loading model ` +
                    model.label +
                    ` please wait...</span><div>`,
                {
                    type: 'info',
                    position: 'top',
                    duration: false,
                    dismissible: false
                }
            );
        }
        //load geojson
        let geodataSource = Cesium.GeoJsonDataSource.load(url, {
            stroke: Cesium.Color.WHITE,
            fill: Cesium.Color.WHITE.withAlpha(0.1),
            strokeWidth: 2,
            heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
            clampToGround: false
        });

        //when geojson is loaded
        geodataSource
            .then((dataSource) => {
                if (show) {
                    //destroy loading toaster notification
                    d.destroy();
                }
                //Get the entities within the geojson
                const entities = dataSource.entities.values;

                //Change the markers from Annotation to Points
                for (let i = 0; i < entities.length; i++) {
                    let entity = entities[i];
                    entity.billboard = undefined;
                    entity.point = new Cesium.PointGraphics({
                        color: Cesium.Color.DODGERBLUE,
                        pixelSize: 20
                    });
                }
                dataSource.show = show;
                //show completed toaster notificaiton
                this.plugins.$toast.success(
                    'Loading ' + model.label + ' completed!',
                    { position: 'top', duration: 1500 }
                );

                //add to vuex store for models
                this.store.dispatch('models/setModelEntity', {
                    entity_id: dataSource.name,
                    model_id: model.model_id
                });
            })
            .catch((error) => {
                if (show) {
                    d.destroy();
                }
                //show error toaster notificaiton
                this.plugins.$toast.error(
                    'Loading ' + model.label + ' failed.',
                    { position: 'top', duration: 1500 }
                );
            });

        let entity = this.viewer.dataSources.add(geodataSource);
        this.heightRefSettings(settings, entity, model);
    },

    async loadGCP(model, settings) {
        let points = this.parseJSON(model.position);
        try {
            if (points) {
                let dataSource = new Cesium.CustomDataSource(
                    'GCP' + model.model_id
                );
                let show = model.visible == 0 ? false : true;
                points.forEach((point) => {
                    //verifiy if each axis is a valid number
                    if (
                        isNaN(point.x) === false &&
                        isNaN(point.y) === false &&
                        isNaN(point.z) === false
                    ) {
                        let cartesian = new Cesium.Cartesian3(
                            Number(point.x),
                            Number(point.y),
                            Number(point.z)
                        );

                        let entity = new Cesium.Entity({
                            point: {
                                pixelSize: 8,
                                color: this.getGcpPointColor(point.color),
                                outlineColor: Cesium.Color.WHITE,
                                outlineWidth: 2,
                                allowMovement: false
                            },
                            position: cartesian,
                            label: {
                                text: point.label,
                                font: '14px monospace',
                                show: true,
                                fillColor: Cesium.Color.WHITE,
                                horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
                                verticalOrigin: Cesium.VerticalOrigin.TOP,
                                pixelOffset: new Cesium.Cartesian2(10, -15),
                                showBackground: true,
                                backgroundColor:
                                    Cesium.Color.BLACK.withAlpha(0.6),
                                backgroundPadding: new Cesium.Cartesian2(10, 5),
                                scale: 1,
                                disableDepthTestDistance:
                                    Number.POSITIVE_INFINITY
                            }
                        });
                        entity.originalXYZ = cartesian.clone();
                        entity.snappablePoint = true;
                        entity.isGcpPoint = true;
                        entity.allowMovement = false;
                        dataSource.entities.add(entity);
                    } else {
                        console.error(
                            'ERROR: AXIS values of GCP object invalid',
                            point
                        );
                    }
                });

                let entity = this.viewer.dataSources.add(dataSource);

                entity.then((tileset) => {
                    this.heightRefSettings(settings, tileset, model);
                    tileset.show = show;
                });

                this.store.dispatch('models/setModelEntity', {
                    entity_id: dataSource.name,
                    model_id: model.model_id
                });
            }
        } catch (e) {
            console.error(
                'ERROR: There was an error loading the gcp object',
                e
            );
        }
    },

    async loadKML(url, model, settings) {
        let show = model.visible == 0 ? false : true;
        let d = null;
        if (show) {
            d = this.plugins.$toast.show(
                `<div class="font-body flex items-center justify-between text-white"><span>Loading model ` +
                    model.label +
                    ` please wait...</span><div>`,
                {
                    type: 'info',
                    position: 'top',
                    duration: false,
                    dismissible: false
                }
            );
        }
        let kmlDataSource = Cesium.KmlDataSource.load(url, {
            camera: this.viewer.scene.camera,
            canvas: this.viewer.scene.canvas
        });
        kmlDataSource.then((dataSource) => {
            let entities = dataSource.entities.values;

            //Set Color White
            entities.forEach((e) => {
                if (
                    e instanceof Cesium.Entity &&
                    e.polyline instanceof Cesium.PolylineGraphics
                ) {
                    e.polyline.material = Cesium.Color.WHITE;
                }
            });

            if (show) {
                d.destroy();
            }
            this.plugins.$toast.success(
                'Loading ' + model.label + ' completed!',
                { position: 'top', duration: 1500 }
            );
            this.store.dispatch('models/setModelEntity', {
                entity_id: dataSource.name,
                model_id: model.model_id
            });
        });
        let entity = this.viewer.dataSources.add(kmlDataSource);
        entity.then((tileset) => {
            this.heightRefSettings(settings, entity, model);
            tileset.show = show;
        });
    },
    async heightRefSettings(settings, entity, model) {
        let height_ref = false;
        let type = model.type;
        const gm = new GsiGeoid();
        if (
            settings != null &&
            Object.keys(settings).includes('display_height_ellipsoidal')
        ) {
            if (settings.display_height_ellipsoidal) {
                height_ref = true;
            }
        }
        if (type === 'kml' || type === 'geojson') {
            if (height_ref) {
                entity.then((value) => {
                    let dataSource = value;
                    // Get the array of entities
                    let testLineEntities = dataSource.entities.values;
                    for (let i = 0; i < testLineEntities.length; i++) {
                        let entity = testLineEntities[i];
                        const positions = entity.polyline.positions._value;
                        let newPositions = [];
                        // get the array of positions for the polyline
                        for (let j = 0; j < positions.length; j++) {
                            let position = positions[j];
                            const cartographic =
                                Cesium.Cartographic.fromCartesian(position);
                            const lat = Cesium.Math.toDegrees(
                                cartographic.latitude
                            );
                            const lon = Cesium.Math.toDegrees(
                                cartographic.longitude
                            );
                            const geoidValue = gm.getGeoid(lat, lon);
                            const newHpos = Cesium.Matrix4.multiplyByPoint(
                                Cesium.Transforms.eastNorthUpToFixedFrame(
                                    position
                                ),
                                new Cesium.Cartesian3(0, 0, geoidValue),
                                new Cesium.Cartesian3()
                            );
                            newPositions.push(newHpos);
                        }
                        entity.polyline.positions.setValue(newPositions);
                    }
                });
            }
        } else if (type === 'gcp') {
            if (height_ref) {
                let dataSource = entity;
                // Get the array of entities
                let testLineEntities = dataSource.entities.values;
                for (let i = 0; i < testLineEntities.length; i++) {
                    let entity = testLineEntities[i];
                    const position = entity.position._value;
                    const cartographic =
                        Cesium.Cartographic.fromCartesian(position);
                    const lat = Cesium.Math.toDegrees(cartographic.latitude);
                    const lon = Cesium.Math.toDegrees(cartographic.longitude);
                    const geoidValue = gm.getGeoid(lat, lon);
                    const newHpos = Cesium.Matrix4.multiplyByPoint(
                        Cesium.Transforms.eastNorthUpToFixedFrame(position),
                        new Cesium.Cartesian3(0, 0, geoidValue),
                        new Cesium.Cartesian3()
                    );
                    entity.position.setValue(newHpos);
                }
            }
        } else {
            /**
             * for obj, xml, gltf height update
             **/
            let center = entity.boundingSphere.center;
            if (entity.centerPosition) {
                center = entity.centerPosition;
            }
            let carto = Cesium.Cartographic.fromCartesian(center);
            const lat = Cesium.Math.toDegrees(carto.latitude);
            const lon = Cesium.Math.toDegrees(carto.longitude);
            let height = carto.height; //インスタンスを作成
            const geoidValue = gm.getGeoid(lat, lon); //緯度、経度の順 ⇒37.05534
            height += geoidValue;
            if (height_ref) {
                center = Cesium.Matrix4.multiplyByPoint(
                    entity.modelMatrix,
                    Cesium.Cartesian3.ZERO,
                    new Cesium.Cartesian3()
                );
                let cartographic = Cesium.Cartographic.fromCartesian(center);
                const offsetInModelSpace = new Cesium.Cartesian3(
                    0,
                    0,
                    cartographic.height + geoidValue
                ); // moving tileset up
                const origCenter = Cesium.Cartesian3.fromRadians(
                    cartographic.longitude,
                    cartographic.latitude,
                    0.0
                );
                const transform =
                    Cesium.Transforms.eastNorthUpToFixedFrame(origCenter);
                const movedCenter = Cesium.Matrix4.multiplyByPoint(
                    transform,
                    offsetInModelSpace,
                    new Cesium.Cartesian3()
                );
                entity.modelMatrix =
                    Cesium.Transforms.eastNorthUpToFixedFrame(movedCenter);
            }
        }
    },
    getGcpPointColor(id) {
        let color = Cesium.Color.FUCHSIA;

        switch (id) {
            case 1:
                color = Cesium.Color.FUCHSIA;
                break;
            case 2:
                color = Cesium.Color.CHARTREUSE;
                break;
            case 3:
                color = Cesium.Color.LIGHTSKYBLUE;
        }

        return color;
    },
    parseJSON(data) {
        try {
            data = JSON.parse(data);

            return data;
        } catch (e) {
            return false;
        }
    },
    getEntity(model_id, type) {
        let entity = null;

        if (this.store != null) {
            entity = this.store.getters['models/getModelEntity'](model_id);
        }

        if (entity) {
            if (!this.is3DModel(type)) {
                let ds = this.viewer.dataSources.getByName(entity.entity_id);
                return ds[0];
            } else {
                let tilesets = this.viewer.scene.primitives._primitives;
                for (let tileset of tilesets) {
                    if (tileset.model_id == entity.model_id) {
                        return tileset;
                    }
                }
            }
        }

        return false;
    },

    async setVisibility(model_id, type, visibility, data = null, i18n = { locale: 'en' }, updateDatabase = true) {
        // added try-catch to check if the model is ready or not in the viewer
        try {
            let visible = 1;
            if (typeof visibility == 'number') {
                visible = visibility;
                visibility = visibility == 1 ? true : false;
            }
            if (!this.is3DModel(type)) {
                let entity = this.getEntity(model_id, type);
                if (entity) {
                    entity.show = visibility;
                    if (!visibility) {
                        this.viewer.scene.primitives.remove(entity);
                    }
                } else {
                    data.visible = visibility
                    this.initModelURL(data)
                }
            } else {
                let tilesets = this.fetchModelTilesets(model_id);
                if (tilesets.length > 0) {
                    for (let tileset of tilesets) {
                        tileset.show = visibility;
                        if (!visibility) {
                            this.viewer.scene.primitives.remove(tileset);
                        }
                    }
                } else {
                    this.initModelURL(data)
                }
            }
            if (updateDatabase){
                ProjectResources.updateModel({
                    "visible": visible,
                    "model_id": model_id
                });
            }

        } catch (error) {
            //console.log("error: ", error)
            this.cesium.compRef.fileManagerRef.resetVectorVisibility(model_id, type);
            let message = "Loading. The display operation of this layer is not yet available."
            if (i18n.locale == 'ja') {
                message = "読み込み中。このレイヤの表示操作はまだできません。"
            }
            this.plugins.$toast.show(
                `<div class="font-body flex items-center justify-between text-white"><span> ` +
                    message +
                    ` </span><div>`,
                {
                    type: 'warning',
                    position: 'top',
                    duration: 3000
                }
            );
        }
    },

    deleteModel(viewer, model_id, type) {
        let entity = this.store.getters['models/getModelEntity'](model_id);
        if (entity) {
            if (type === 'kml' || type === 'geojson' || type === 'gcp') {
                let ds = viewer.dataSources.getByName(entity.entity_id);
                viewer.dataSources.remove(ds[0]);
            } else {
                let tilesetsToDelete = this.fetchModelTilesets(model_id);
                for (let tileset of tilesetsToDelete) {
                    this.viewer.scene.primitives.remove(tileset);
                }
            }
        }
    },

    fetchModelTilesets(model_id) {
        let tilesets = this.viewer.scene.primitives._primitives;
        let modelTilesets = [];

        tilesets.forEach((tileset) => {
            if (model_id === tileset.model_id && tileset.model_id != null) {
                modelTilesets.push(tileset);
            }
        });

        return modelTilesets;
    },

    rerender(model) {
        if (model.type == 'gcp') {
            let entity = this.store.getters['models/getModelEntity'](
                model.model_id
            );

            //remove old gcp
            let ds = this.viewer.dataSources.getByName(entity.entity_id);
            this.viewer.dataSources.remove(ds[0]);

            let points = this.parseJSON(model.position);
            let dataSource = new Cesium.CustomDataSource(
                'GCP' + model.model_id
            );

            points.forEach((point) => {
                let cartesian = new Cesium.Cartesian3(
                    Number(point.x),
                    Number(point.y),
                    Number(point.z)
                );

                let entity = new Cesium.Entity({
                    point: {
                        pixelSize: 8,
                        color: this.getGcpPointColor(point.color),
                        outlineColor: Cesium.Color.WHITE,
                        outlineWidth: 2
                    },
                    position: cartesian,
                    label: {
                        text: point.label,
                        font: '14px monospace',
                        show: true,
                        fillColor: Cesium.Color.WHITE,
                        horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
                        verticalOrigin: Cesium.VerticalOrigin.TOP,
                        pixelOffset: new Cesium.Cartesian2(10, -15),
                        showBackground: true,
                        backgroundColor: Cesium.Color.BLACK.withAlpha(0.6),
                        backgroundPadding: new Cesium.Cartesian2(10, 5),
                        scale: 1,
                        disableDepthTestDistance: Number.POSITIVE_INFINITY
                    }
                });
                entity.isGcpPoint = true;
                entity.allowMovement = false;
                dataSource.entities.add(entity);
            });

            this.viewer.dataSources.add(dataSource);

            this.store.dispatch('models/setModelEntity', {
                entity_id: dataSource.name,
                model_id: model.model_id
            });
        } else if (model.type === 'geojson') {
            let entity = this.store.getters['models/getModelEntity'](
                model.model_id
            );

            //remove old geojson
            let ds = this.viewer.dataSources.getByName(entity.entity_id);
            this.viewer.dataSources.remove(ds[0]);
            let url = config.get().tileSetUrl + `/${model.path}`;
            let geodataSource = Cesium.GeoJsonDataSource.load(url, {
                stroke: Cesium.Color.WHITE,
                fill: Cesium.Color.WHITE,
                strokeWidth: 2,
                heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
                clampToGround: false
            });
            this.viewer.dataSources.add(geodataSource);
        } else if (model.type === 'kml') {
            let entity = this.store.getters['models/getModelEntity'](
                model.model_id
            );

            //remove old geojson
            let ds = this.viewer.dataSources.getByName(entity.entity_id);
            this.viewer.dataSources.remove(ds[0]);
            let url = config.get().tileSetUrl + `/${model.path}`;
            let kmlDataSource = Cesium.KmlDataSource.load(url, {
                camera: this.viewer.scene.camera,
                canvas: this.viewer.scene.canvas,
                show: true
            });
            this.viewer.dataSources.add(kmlDataSource);
        }
    },

    updateCoordinates(payload) {
        this.store
            .dispatch('models/updateCoordinates', payload)
            .then((response) => {
                this.plugins.$toast.show(
                    `<div class="font-body flex items-center justify-between text-white">
                            <svg
                                xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 -ml-5 mr-3" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clip-rule="evenodd" />
                            </svg>
                            <span>New position saved.</span>
                            <div>`,
                    { type: 'success', position: 'top-right', duration: 3000 }
                );
            });
    },
    updateGcpPoints(payload) {
        this.rerender(payload.data.updateModelData);
        this.store
            .dispatch('models/updateCoordinates', payload.data)
            .then((response) => {
                this.plugins.$toast.show(
                    `<div class="font-body flex items-center justify-between text-white">
                            <svg
                                xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 -ml-5 mr-3" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clip-rule="evenodd" />
                            </svg>
                            <span>Ground Points Updated</span>
                            <div>`,
                    { type: 'success', position: 'top-right', duration: 3000 }
                );
            });
    },
    clearToastr() {
        if (this.plugins?.plugins?.$toast) {
            setTimeout(this.plugins.$toast.clear, 0);
        }
    },

    is3DModel(type) {
        return !(type === 'kml' || type === 'geojson' || type === 'gcp');
    },

    show3DFaceModel(model_id, visible) {
        let model = this.get3DFaceModel(model_id);
        model.show = visible;
    },

    reset3DFaceModelsVisibility() {
        let tilesets = this.viewer.scene.primitives._primitives;
        for (let tileset of tilesets) {
            if (tileset.isFaceModel) {
                tileset.show = true;
            }
        }
    },

    sync3DModelsMatrices() {
        let primitives = this.viewer.scene.primitives._primitives;
        let faceModels = primitives.filter(
            (tileset) => tileset.isFaceModel === true
        );

        for (let faceModel of faceModels) {
            for (let primitive of primitives) {
                if (primitive.model_id === faceModel.model_id) {
                    faceModel.modelMatrix = primitive.modelMatrix;
                }
            }
        }
    },

    get3DFaceModel(model_id) {
        let models = this.fetchModelTilesets(model_id);
        for (let model of models) {
            if (model.model_id == model_id && model.isFaceModel) {
                return model;
            }
        }
        return null;
    },
    async isUrlOnline(url) {
        try {
            const response = await fetch(url, { method: 'HEAD' });
            return response.status >= 200 && response.status < 300;
        } catch (error) {
            return false;
        }
    }
};
