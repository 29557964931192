import * as Cesium from '@/sdk/cesium';
import Measurement from '@/models/Measurement';
import _ from 'lodash';


export class ClippingTool {
    /**
     *
     * @param viewer
     * @param clippingTarget
     * @param primitives
     * @param project_id
     * @param ref
     */
    constructor(viewer, clippingTarget, primitives, project_id, ref) {
        this._viewer = viewer;
        this._handlerDown = '';
        this._handlerUp = '';
        this.project_id = project_id;
        this.$ref = ref;
        // It had delay when loading project measurement id from DB, using this keeps from creating a new project measurement id
        this.loadingPrjMeasurementId = false;
        this.clippingTarget = clippingTarget;
        this._clippingPlanes = {};
        this._clippingPlanesEditor = {};
        this.primitives = primitives;
        this.project_measurement_id = null;
        this.nonWireframeModel = null;

        try {
            this.initilDistance = this.clippingTarget.boundingSphere.radius / 3 || this.clippingTarget._boundingSphere.radius / 3;
        } catch (e) {
            this.initilDistance = 30;
            console.log("error", e)
        }
        this.params = {
            rotation: 0,
            visibility: true,
            clipping: true,
            distance: {
                left: this.initilDistance | 30,
                right: this.initilDistance | 30,
                front: this.initilDistance | 30,
                back: this.initilDistance | 30,
                top: this.initilDistance | 30,
                bottom: this.initilDistance / 6 | 30
            },
            region: true
        };
        try {
            this.initHandler();
            this.showOneTile(this.primitives, this.clippingTarget);
            this.nonWireframeModel = this.getIdentical3dModel(clippingTarget);
            if (this.nonWireframeModel) {
                this.nonWireframeModel.show = false;
            }
        } catch (e) {
            console.log(e)
        }
    }

    /**
     * Show only one tileset
     * @param primitives
     * @param target
     * @param cancel
     */
    showOneTile(primitives, target, cancel = false) {
        if (!cancel) {
            primitives.forEach(tile => {
                if (tile instanceof Cesium.Cesium3DTileset || tile instanceof Cesium.Model) {
                    tile.show = tile._url.match(target._url);
                }
            });
        } else {
            let fileIds = _.map(this.$ref.compRef.clipRef.clipTargetList, 'file_id');
            primitives.forEach(tile => {
                if ((tile.file_id in fileIds)) {
                    tile.show = true;
                }
            });
        }
    }


    initHandler() {
        /**
         *
         */
        // if (this._handler !== '' && this._handler instanceof new Cesium.ScreenSpaceEventHandler) {
        //     this._handler.destroy();
        // }
        this._handlerDown = new Cesium.ScreenSpaceEventHandler(this._viewer.canvas);
        this._handlerUp = new Cesium.ScreenSpaceEventHandler(this._viewer.canvas);
        let result;
        let $ref = this.$ref;
        this.loadingPrjMeasurementId = true;

        // Get the project_measurement_id from DB
        Measurement.list(this.project_id).then((response) => {
            if (response.data.status) {
                this.loadingPrjMeasurementId = false;
                if (response.data.data.length !== 0) {
                    response.data.data.forEach(e => {
                        // Filter clipping
                        if (e.measurement_id === 9) {
                            result = this._parseJSON(e.data);
                            if (result) {
                                this.params.distance = result.distance;
                                this.params.clipping = result.clipping;
                                this.params.visibility = result.visibility;
                                this.params.rotation = result.rotation;
                                this.params.region = result.region;
                                let params = this.params;
                                this.project_measurement_id = e.project_measurement_id;
                                // Clean up and activate clipping tool
                                this.deactivateClippingTool();
                                this.activateClippingTool(params);
                                // set the initial value from DB to property right panel
                                $ref.compRef.propertyRef.clip3dRotation = result.rotation;
                                $ref.compRef.propertyRef.clip3dVisibility = result.visibility;
                                $ref.compRef.propertyRef.clip3dClipping = result.clipping;
                                $ref.compRef.propertyRef.clip3dRegion = result.region;
                            }
                        }
                    });
                }
                else { // activating the clip 3d for the first time
                    this.deactivateClippingTool();
                    this.activateClippingTool(this.params);
                }
            }
            else {
                this.loadingPrjMeasurementId = false;
            }
        }).catch(error => {
            console.log(error);
            this.loadingPrjMeasurementId = false;
        });

        function changeDistance(pickedObject, distance) {
            if (
                Cesium.defined(pickedObject) &&
                Cesium.defined(pickedObject.id) &&
                Cesium.defined(pickedObject.id.plane &&
                pickedObject.id &&
                pickedObject.id.plane)
            ) {
                let selectedPlane = pickedObject.id.plane;
                if (selectedPlane._clippingPlane) {
                    if (selectedPlane._clippingPlane.id === 'front') {
                        distance.front = selectedPlane._clippingPlane.distance;
                    } else if (selectedPlane._clippingPlane.id === 'back') {
                        distance.back = selectedPlane._clippingPlane.distance;
                    } else if (selectedPlane._clippingPlane.id === 'left') {
                        distance.left = selectedPlane._clippingPlane.distance;
                    } else if (selectedPlane._clippingPlane.id === 'right') {
                        distance.right = selectedPlane._clippingPlane.distance;
                    } else if (selectedPlane._clippingPlane.id === 'top') {
                        distance.top = selectedPlane._clippingPlane.distance;
                    } else if (selectedPlane._clippingPlane.id === 'bottom') {
                        distance.bottom = selectedPlane._clippingPlane.distance;
                    }
                }
            }
        }

        let pickedObject;

        this._handlerDown.setInputAction((movement) => {
            pickedObject = this._viewer.scene.pick(movement.position);
            changeDistance(pickedObject, this.params.distance);
        }, Cesium.ScreenSpaceEventType.LEFT_DOWN);

        // const clickleft = this._handler.getInputAction(Cesium.ScreenSpaceEventType.MOUSE_MOVE);
        // console.log(clickleft)

        this._handlerUp.setInputAction(() => {
            // pickedObject = this._viewer.scene.pick(movement.position);
            if (Cesium.defined(pickedObject)) {
                changeDistance(pickedObject, this.params.distance);
                // this._viewer.scene.screenSpaceCameraController.enableInputs = true;
                pickedObject = {};
                // this.clippingTarget.clippingPlanes.enabled = true;
                this.saveSettings();
            }
        }, Cesium.ScreenSpaceEventType.LEFT_UP);
    }

    saveSettings() {
        if (!this.loadingPrjMeasurementId) {
            // Initial. This will create unique project_measurement_id per project
            // this.loadingPrjMeasurementId keeps one project_measurement_id per project
            Object.keys(this.params.distance).forEach((key, index) => {
                const num = Number(this.params.distance[key]);
                this.params.distance[key] = num.toFixed(3);
            });

            if (!this.project_measurement_id) {
                let payload = new FormData();
                payload.append('measurement', 9);//id of clip
                payload.append('label', 'clipping');
                payload.append('data', JSON.stringify({
                    'distance': this.params.distance,
                    'rotation': this.params.rotation,
                    'visibility': this.params.visibility,
                    'clipping': this.params.clipping,
                    'region': this.params.region
                }));
                Measurement.store(this.project_id, payload).then(r => {
                    this.project_measurement_id = r.data.data.project_measurement_id;
                }).catch(error => console.error(error));
            } else {
                let payload = new FormData();
                payload.append('id', this.project_measurement_id);//id of clip
                payload.append('label', 'clipping');
                payload.append('data', JSON.stringify({
                    'distance': this.params.distance,
                    'rotation': this.params.rotation,
                    'visibility': this.params.visibility,
                    'clipping': this.params.clipping,
                    'region': this.params.region
                }));
                Measurement.update(this.project_id, payload).catch(error => console.error(error));
            }
        }
    }

    changeRotation(new_rotation) {
        // TODO: To avoid error for now. Need to come up with workaround.
        if (this.clippingTarget instanceof Cesium.Model) {
            return null;
        }
        this.clippingTarget.clippingPlanes = null;
        // let tilesets = this.primitives.filter(p => {
        //     return p instanceof Cesium.Cesium3DTileFeature ||
        //         p instanceof Cesium.Cesium3DTileset ||
        //         p instanceof Cesium.Cesium3DTile ||
        //         p instanceof Cesium.Model;
        // });
        // tilesets.forEach(t => {
        //     t._clippingPlanes = null;
        // });
        if (this._clippingPlanesEditor.active === true) {
            this._clippingPlanesEditor.deactivate();
        }
        this.params.rotation = new_rotation;
        this.activateClippingTool(this.params);
        this.saveSettings();
    }

    /***
     * change the visibility of the clipping editor not clipping planes
     * @param checkbox
     */
    changeVisibility(checkbox) {
        this.params.visibility = checkbox;

        if (!checkbox) {
            this._clippingPlanesEditor.deactivate();
        } else this._clippingPlanesEditor.activate();

        this.saveSettings();
    }

    changeClipping(checkbox) {
        this.params.clipping = checkbox;
        this.clippingTarget.clippingPlanes.enabled = checkbox;
        this.saveSettings();
    }

    changeClippingRegion(checkbox) {
        this.params.region = checkbox;

        // this.clippingTarget.clippingPlanes.unionClippingRegions = checkbox;
        this.deactivateClippingTool();
        this.activateClippingTool(this.params);
        // console.log(this._clippingPlanesEditor)
        // let planes = this._clippingPlanesEditor._clippingPlanes._planes;
        // planes.forEach(plane => {
        //     console.log(plane)
        //     plane._normal = Cesium.Cartesian3.negate(plane._normal, new Cesium.Cartesian3());
        //     console.log(plane._normal)
        //     console.log(Cesium.Cartesian3.negate(plane._normal, new Cesium.Cartesian3()))
        // });
        this.saveSettings();
    }


    resetClip3d() { // reset clipping planes
        // TODO: To avoid error for now. Need to come up with workaround.
        if (this.clippingTarget instanceof Cesium.Model) {
            return null;
        }

        this.params.distance = {    // reset distance
            left: this.initilDistance | 30,
            right: this.initilDistance | 30,
            front: this.initilDistance | 30,
            back: this.initilDistance | 30,
            top: this.initilDistance | 30,
            bottom: this.initilDistance / 6 | 30
        };
        this.params.clipping = true; // reset clipping
        this.params.visibility = true; // reset visibility
        this.params.rotation = 0; // reset rotation
        this.params.region = true; // reset rotation
        this.saveSettings();
        this.deactivateClippingTool();
        this.activateClippingTool(this.params);
        // this._clippingPlanesEditor.reset();
        // console.log('Clip Reset')
    }


    activateClippingTool(params) {

        // if (this.clippingTarget instanceof Cesium.Model) {
        //     // TODO: Rotate the clipping editor by modifying the matrix
        //     // Cesium.ClippingPlane.clone(clippingPlane, result) may be useful
        //     return null;
        // }

        let angleOffset = Cesium.Math.toRadians(params.rotation ? params.rotation : 30);
        let planeRotation1 = angleOffset;
        let planeRotation2 = angleOffset + Cesium.Math.toRadians(90);
        // const boundingSphereRadius = this.clippingTarget.boundingSphere.radius;
        let statusRegion = params.region ? 1 : -1; // positive or negative

        let rightPlane = new Cesium.ClippingPlane(
            new Cesium.Cartesian3(
                Math.cos(planeRotation1) * statusRegion,
                Math.sin(planeRotation1) * statusRegion,
                0.0
            ),
            params.distance.right * statusRegion
        );
        rightPlane.id = 'right';

        let leftPlane = new Cesium.ClippingPlane(
            new Cesium.Cartesian3(
                -Math.cos(planeRotation1) * statusRegion,
                -Math.sin(planeRotation1) * statusRegion,
                0.0
            ),
            params.distance.left * statusRegion
        );
        leftPlane.id = 'left';

        let frontPlane = new Cesium.ClippingPlane(
            new Cesium.Cartesian3(
                Math.cos(planeRotation2) * statusRegion,
                Math.sin(planeRotation2) * statusRegion,
                0.0
            ),
            params.distance.front * statusRegion
        );
        frontPlane.id = 'front';

        let backPlane = new Cesium.ClippingPlane(
            new Cesium.Cartesian3(
                -Math.cos(planeRotation2) * statusRegion,
                -Math.sin(planeRotation2) * statusRegion,
                0.0
            ),
            params.distance.back * statusRegion
        );
        backPlane.id = 'back';

        let topPlane = new Cesium.ClippingPlane(
            new Cesium.Cartesian3(
                0,
                0,
                -1 * statusRegion
            ),
            params.distance.top * statusRegion
        );
        topPlane.id = 'top';

        let bottomPlane = new Cesium.ClippingPlane(
            new Cesium.Cartesian3(
                0,
                0,
                1 * statusRegion
            ),
            params.distance.bottom * statusRegion
        );
        bottomPlane.id = 'bottom';

        // Add clipping planes to 3D tileset like point cloud and model
        // console.log('clipping planes in ClippingTool.js', this.clippingTarget)
        // if (this.clippingTarget instanceof Cesium.Model) {
        //     console.log('Model', this.clippingTarget.clippingPlanes)
        //     this.clippingTarget.clippingPlanes = null;
        // }
        this.clippingTarget.clippingPlanes = new Cesium.ClippingPlaneCollection({
            planes: [
                topPlane,
                bottomPlane,
                frontPlane,
                backPlane,
                leftPlane,
                rightPlane
            ],
            enabled: params.clipping,
            unionClippingRegions: params.region, // when true, clips outside
            edgeWidth: 0.6,
            edgeColor: Cesium.Color.RED.withAlpha(0.5),
            movePlanesToOrigin: false
        });
        this.clippingTarget.clippingPlanes.enabled = params.clipping;

        //Generates plane mouse handler and activate
        this._clippingPlanesEditor = new Cesium.ClippingPlanesEditor({
            scene: this._viewer.scene,
            clippingPlanes: this.clippingTarget.clippingPlanes,
            movePlanesToOrigin: false,
            // pixelSize: new Cesium.Cartesian2(100, 100),
            maximumSizeInMeters: new Cesium.Cartesian2(1000000, 1000000)
        });

        this._clippingPlanesEditor.activate();

        // Visibility of the clipping planes
        let clippingPrimitives = this._getClippingPrimitives();
        clippingPrimitives.show = params.visibility;
    }

    getIdentical3dModel(clippingTarget) {
        let res, exist= false;
        try {
            for (let primitive of this.primitives) {
                if (primitive instanceof Cesium.Cesium3DTileset || primitive instanceof Cesium.Model) {
                    if (primitive._url === clippingTarget._url) {
                        if (exist) {
                            res = primitive;
                            break;
                        } else {
                            exist = true;
                        }
                    } else {
                        res = undefined;
                    }
                }
            }
        } catch (e) {
            console.error('there is error on getting the same 3d model', e);
        }
        return res;
    }



    deactivateClippingTool() {
        if (this.clippingTarget.clippingPlanes instanceof Cesium.ClippingPlaneCollection) {
            this.clippingTarget.clippingPlanes.enabled = false;
            this.clippingTarget.clippingPlanes = null;
        }
        // let tilesets = this.primitives.filter(p => {
        //     return p instanceof Cesium.Cesium3DTileFeature ||
        //         p instanceof Cesium.Cesium3DTileset ||
        //         p instanceof Cesium.Cesium3DTile ||
        //         p instanceof Cesium.Model;
        // });
        // tilesets.forEach(t => {
        //     t._clippingPlanes.enabled = false;
        //     t._clippingPlanes = null;
        // });

        if (this._clippingPlanesEditor.active === true) {
            this._clippingPlanesEditor.deactivate();
            this._clippingPlanesEditor = null;
        }
        // if (!this._clippingPlanesEditor.isDestroyed()) {
        //     this._clippingPlanesEditor.destroy();
        // }
    }

    destroyAll() {
        if (this._clippingPlanesEditor instanceof Cesium.ClippingPlanesEditor) {
            if (!this._clippingPlanesEditor.isDestroyed()) {
                this._clippingPlanesEditor.destroy();
            }
            if (!this.clippingTarget.clippingPlanes.isDestroyed()) {
                this.clippingTarget.clippingPlanes.removeAll();
                // this.clippingTarget.clippingPlanes.destroy();
            }
            if (this.nonWireframeModel) {
                this.nonWireframeModel.show = true;
            }
            this.showOneTile(this.primitives, this.clippingTarget, true);
        }
        // if (!this._getClippingPrimitives().isDestroyed()) {
        //     // this._getClippingPrimitives().removeAll();
        //     console.log(this._getClippingPrimitives())
        // }
        // if (!this._handler.isDestroyed() && this._handler !== undefined) {
        //     console.log(this._handler)
        //     this._handler = null;
        // }
    }

    _parseJSON(data) {
        try {
            data = JSON.parse(data);
            return data;
        } catch (e) {
            return false;
        }
    }

    /***
     * Get the current clipping plane primitives from the viewer.
     * @returns {Error|ReferenceError|*}
     * @private
     */
    _getClippingPrimitives() {
        const array = this._viewer.scene.primitives._primitives;
        // console.log(array)
        const clippingPrimitives = array.find(p => {
            if (p instanceof Cesium.PrimitiveCollection) {
                if (p._primitives[0] instanceof Cesium.ClippingPlanePrimitive) return p;
            }
        });
        if (clippingPrimitives) {
            return clippingPrimitives;
        } else return new ReferenceError('Couldn\'t find Clipping planes');
    }
}
