
import Model from '@/models/Invites'

const state = () => ({
    list: []
})
  
// getters
const getters = {
    myInvites(state) {
        return state.list
    },
    count(state) {
        return state.list.length
    },
    viewers(state) {
        var unread = state.list.filter((l) => {
            return l.role == 'Viewer'
        });
        return unread;
    },
    editors(state) {
        var unread = state.list.filter((l) => {
            return l.role == 'Editor'
        });
        return unread;
    }
}

// actions
const actions = {

    async loadInvites(context) {
        const list = await Model.list()
        //console.log(list.data)
        context.commit( 'load', list.data)
    },
}

// mutations
const mutations = {
    load(state, list) {
        state.list = list
    }
}
  
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}