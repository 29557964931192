import PermissionsModel from '@/models/Permissions'


// Todo: Agreed standard of permission is <module>.<action>
// Todo: Add custom directives for the permission (e.g v-permission="project.create") (ref: https://v3.vuejs.org/api/directives.html)

const state = () => ({
    permissions: {
        access: [],
        features: [],
        plan_access: false
    }
})
  
// getters
const getters = {
    listPermissions(state) {
        return state.permissions
    },

    getAccessPermissions(state) {
        return state.permissions.access
    },

    getFeaturePermissions(state) {
        return state.permissions.features
    },

    getPlanPermissions(state) {
        return state.permissions.plan_access
    },

    getAllPermissions(state) {
        return state.permissions
    }
}

// actions
const actions = {
    setUserPermissions(context, data) {
        context.commit( 'setPermissions', data)
    },
/* 
    reloadPermissions() {
        let permissions = await PermissionsModel.get();
        if ( permissions.data.accessPermissions && permissions.data.featurePermissions ) {
            context.commit( 'setPermissions', permissions.data)
        }
    }, */

    async reloadPermissions(context) {
        const query = await PermissionsModel.get()
        let permissions = query.data
        return new Promise((resolve) => {
            context.commit( 'setPermissions', permissions)
            resolve(permissions)
        })
    },
}

// mutations
const mutations = {
    setPermissions(state, data) {
        state.permissions.access = data.accessPermissions
        state.permissions.features = data.featurePermissions
        state.permissions.parentAccess = data.parentAccessPermissions
        state.permissions.parentFeatures = data.parentFeaturePermissions
        state.permissions.plan_access = data.invites
    }
}
  
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}