import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = $event => ($data.displayDropDown = !$data.displayDropDown))
  }, [
    _createElementVNode("img", {
      class: "object-none pl-6 pr-4 p-2",
      src: require('@/statics/three_dots_vertical.svg')
    }, null, 8, _hoisted_1),
    _createElementVNode("div", {
      class: _normalizeClass(["z-10 absolute mt-2 w-28 rounded-md bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none", $data.displayDropDown ? '' : 'hidden']),
      role: "menu",
      "aria-orientation": "vertical",
      "aria-labelledby": "menu-button",
      tabindex: "-1"
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ]))
}