import * as Cesium from '@/sdk/cesium';
import { turf } from '@/third-party/OutTurf.js';
import MeasurementSnapping from './MeasurementSnapping';
import swal from 'sweetalert';
// import postData from '@/third-party/WorkerApi';

export class Volume3D {
    constructor(viewer, method, resolutionGrid, lang, options = {}, callback) {
        this._viewer = viewer;
        this._activeShapePoints = [];
        this._method = method; //maximum, minimum, TIN;
        this._resolutionGrid = resolutionGrid; //meters, JP standard is 50cm
        this._handler = options.handler;
        this._volume3Dresult;
        this._floatingpoint;
        this._polygonShape = [];
        this._lang = lang;
        // this._vector_list = options.refs.fileManagerRef.vectors.list;
        this._activeShape;
        this._tempActiveShapePoints;
        this.saveCoordinatesAndResult = [];
        //this.callback = function(ids) {};
        this.callback = callback;
        this.ids = [];
        this._drawLayer = options.drawLayer;
        this._refs = options.refs || [];
        this._t = options.t || [];
        this.customHeight = options.custom;
        this.extent = {};
        //being used to square the grid resolution. so if it is 2, it will multiply 4 times
        this.detailLevel = 2;
        this.volumeIntersectionWidth = 0.5;
        this.selectedEpsg = options.selectedEpsg;

        //Cut Fill Volume Related Variables
        this._originalShapePoints = [];
        this.rightClickTerminated = false;
        this.enablePropertiesPanelDisplay = true;
        this.isCutFillMode = false;
        this.isCutFillResult = options.isVolumeCutFillResult || false;
        this.measure = options.measure;
        this.mode = options.mode;
        if (options.mode == 'CALC') {
            this.initHandler();
        }
    }

    /**
     * Initiate mouse handler.
     */
    initHandler() {
        this._handler.setInputAction((event) => {
            if (this._refs.propertyRef.selectedTool === 'volume') {
                if (this.enablePropertiesPanelDisplay) {
                    this._refs.propertyRef.setLoadingState({
                        state: true,
                        tool: 'volume'
                    });
                }

                this.leftClickHandler(event);

            }
        }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

        this._handler.setInputAction((event) => {

            this.mouseMoveHandler(event);

        }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

        this._handler.setInputAction((event) => {
            let success = this.rightClickHandler();
            if (success) {
                this.measure._handler.destroy();
                this.measure._handler = null;
            }
        }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);
    }

    /**
     * Start the calculation by the mouse right click over the 3rd time.
     */
    finish(recal = false) {
        return new Promise((resolve, reject) => {
            if (
                (!recal && !this.isCutFillMode) ||
                (this.isCutFillMode && !this.rightClickTerminated)
            ) {
                let areFirstTwoEqual = false;
                // Check if the first two elements are equal
                if (this._activeShapePoints[0].x === this._activeShapePoints[1].x &&
                    this._activeShapePoints[0].y === this._activeShapePoints[1].y &&
                    this._activeShapePoints[0].z === this._activeShapePoints[1].z) {
                    areFirstTwoEqual = true;
                }

                // // Check if the last two elements are equal
                // let arrLength = this._activeShapePoints.length;
                // if (this._activeShapePoints[arrLength - 1].x === this._activeShapePoints[arrLength - 2].x &&
                //     this._activeShapePoints[arrLength - 1].y === this._activeShapePoints[arrLength - 2].y &&
                //     this._activeShapePoints[arrLength - 1].z === this._activeShapePoints[arrLength - 2].z) {
                //     areFirstTwoEqual = false;
                // }
                // if (mobile) {
                //     this._activeShapePoints.shift();
                // }
                if (!areFirstTwoEqual && !this.isCutFillMode) {
                    this._activeShapePoints.pop();
                } else {
                    this._activeShapePoints.shift();
                }
            }
            this._tempActiveShapePoints = this._activeShapePoints;

            this._polygonShape.push(
                this._drawShape(
                    this._tempActiveShapePoints,
                    true, // set to false to include the per height color
                    Cesium.Color.RED.withAlpha(0.6),
                    Cesium.ClassificationType.CESIUM_3D_TILE
                )
            );

            this._viewer.entities.suspendEvents();

            this._polygonShape.push(
                this._drawShape(
                    this._tempActiveShapePoints,
                    false, // set to false to include the per height color
                    Cesium.Color.YELLOW.withAlpha(0.8),
                    // Cesium.Color.WHITE.withAlpha(0.2),
                    Cesium.ClassificationType.BOTH
                )
            );

            // Get the rectangle that encompassed the polygon
            let rectangleSettings = this.calcRectangle(this._polygonShape[0]);

            // Get the sample points from the 3D tileset
            let samplePointsPromise_3DTile = this.threeDTileSample(
                rectangleSettings.polygonRectangle,
                rectangleSettings.recWidthInMeter,
                rectangleSettings.recHeightInMeter,
                recal
            );

            // const startTime = performance.now(); // 開始時間
            samplePointsPromise_3DTile
                .then((points) => {
                    // const endTime = performance.now(); // 終了時間
                    // console.log("threeDTileSample: ",endTime - startTime); // 何ミリ秒かかったかを表示する

                    // this.sampleDebug(points);

                    // If the height is not defined, try to get the height from the 3D tileset again.
                    points.forEach((point) => {
                        if (isNaN(point.height)) {
                            point.height = this._viewer.scene.sampleHeight(
                                point,
                                this._polygonShape,
                                this.volumeIntersectionWidth * 2
                            );
                        }
                        return point;
                    });

                    // Generate detailed point list with z by creating detailed grid
                    let detailedSamplePoints =
                        this.getDetailedGridPointsWithZ(
                            points,
                            this._tempActiveShapePoints
                        );
                    // this.sampleDebug(detailedSamplePoints);

                    // Draw the detailed points as a triangle mesh.
                    // TODO: draw the triangle mesh
                    // this.drawTriangleMesh(detailedSamplePoints);

                    this._volume3Dresult = this.calc3Dvolume(detailedSamplePoints);
                    // Save the coordinates for drawing back the triangle mesh
                    //this._volume3Dresult.carto_Z = detailedSamplePoints;
                    this._volume3Dresult.custom = this.customHeight;
                    return {
                        volume: this._volume3Dresult
                    };
                })
                .then((res) => {
                    this.saveCoordinatesFromPolygonHiearchy(
                        this._polygonShape[0],
                        res.volume
                    );
                    if (!recal) {
                        this.showResultOnView(
                            this._activeShapePoints,
                            res.volume
                        );
                        this._viewer.entities.remove(this._floatingPoint);
                        this._drawLayer.entities.remove(this._floatingPoint);
                        this._viewer.entities.remove(this._activeShape);
                        this._floatingPoint = undefined;
                        this._activeShape = undefined;
                        this._activeShapePoints = [];
                        this._tempActiveShapePoints = [];
                        resolve(true);
                    } else {
                        this.showResultOnView(
                            this._tempActiveShapePoints,
                            res.volume
                        );
                    }
                    // const endTime = performance.now(); // 終了時間
                    // console.log(endTime - startTime); // 何ミリ秒かかったかを表示する
                });
            if (!recal) {
                this._activeShape.show = false;
            }
            this._viewer.entities.resumeEvents();
        });
    }

    /**
     * Calculate the 3D volume based on the coordinates(carto) list of the grid.
     * Only coordinates inside the selected area will be used.
     * Methods are TIN base and selected height base.
     * @param detailedSamplePoints - the coordinates list of the grid.
     * @returns {{NetVolume: number, CutVolume: number, FillVolume: number}}
     */
    calc3Dvolume(detailedSamplePoints) {
        let upperHeightsList = [];
        let lowerHeightsList = [];

        const sumGapVolume = (arr) =>
            arr.reduce((a, b) => Number(a + b), 0) * 0.25 ** 2;

        let cutVol = 0;
        let fillVol = 0;
        let gapArray = [];
        // Methods that use the height of the selected area as the base height
        if (this._method !== 'triangulated') {
            detailedSamplePoints.forEach((coordinate) => {
                let baseHeight = this.getHeightBaseSurface(
                    this._tempActiveShapePoints
                );
                let gapHeight =
                    Number((coordinate.height - baseHeight).toFixed(4)) || 0;
                if (gapHeight > 0) {
                    upperHeightsList.push(gapHeight || 0);
                } else {
                    lowerHeightsList.push(gapHeight || 0);
                }
                gapArray.push(gapHeight);
            });
        }
        // Methods that use the TIN surface as the base height
        else {
            let TINpoints = this.tinSurface(
                this._tempActiveShapePoints,
                detailedSamplePoints
            );
            // this.sampleDebug(TINpoints);
            // this.sampleDebug(detailedSamplePoints, undefined, 'blue');
            let cnt = Math.min(detailedSamplePoints.length, TINpoints.length);
            try {
                for (let i = 0; i <= cnt - 1; i++) {
                    const objectHeight = detailedSamplePoints[i].height
                        ? Number(detailedSamplePoints[i].height.toFixed(4))
                        : 0;
                    const referenceHeight = TINpoints[i].height
                        ? Number(TINpoints[i].height.toFixed(4))
                        : 0;
                    let gapHeight = Number(
                        (objectHeight - referenceHeight).toFixed(4) || 0
                    );

                    if (gapHeight >= 0) {
                        upperHeightsList.push(Number(gapHeight));
                    } else if (gapHeight < 0) {
                        lowerHeightsList.push(Number(gapHeight));
                    } else {
                        gapArray.push(0);
                    }
                }
            } catch (e) {
                console.log('error', e);
            }
        }
        try {
            cutVol = sumGapVolume(upperHeightsList);
            fillVol = sumGapVolume(lowerHeightsList);
        } catch (e) {
            cutVol, (fillVol = 0), 0;
            console.log('error', e);
        }

        const netVolume = fillVol + cutVol;
        return {
            // Correction for the loss of the volume due to the grid resolution
            CutVolume: cutVol,
            FillVolume: - fillVol,
            NetVolume: netVolume,
            gapArray: gapArray
        };
    }

    /**
     * Generate a TIN from rough grid points and polygon nodes, the return the detailed grid points with z.
     * @param points Cartographic list.
     * @param nodesPolygon Cartesian list.
     * @returns {[]} detailedGridPoints with Z. Cartographic list.
     */
    getDetailedGridPointsWithZ(points, nodesPolygon) {
        // Add the first node to the end of the list to make it a closed polygon
        let degLonLatList = [];
        let nodesPolygonFixed = [];
        const pointsLength = points.length;
        const nodesPolygonLength = nodesPolygon.length;

        // Get the heights of the polygon nodes
        for (let i = 0; i < nodesPolygonLength; i++) {
            const carto = Cesium.Cartographic.fromCartesian(nodesPolygon[i]);
            const h = this._viewer.scene.sampleHeight(
                carto,
                this._polygonShape,
                this.volumeIntersectionWidth * 2
            );
            nodesPolygonFixed[i] = Cesium.Cartographic.toCartesian(
                new Cesium.Cartographic(carto.longitude, carto.latitude, h)
            );
        }
        // Create interpolate function for the polygon nodes
        for (let j = 0; j < nodesPolygonLength; j++) {
            const firstPoint = nodesPolygon[j];
            const secondPoint = j === nodesPolygonLength - 1 ? nodesPolygon[0] : nodesPolygon[j + 1];
            for (let i = 1; i < 5; i++) {
                let time = i / 5;
                let cartesian = Cesium.Cartesian3.lerp(
                    firstPoint,
                    secondPoint,
                    time,
                    new Cesium.Cartesian3()
                );
                let carto = Cesium.Cartographic.fromCartesian(cartesian);
                const h = this._viewer.scene.sampleHeight(
                    carto,
                    this._polygonShape,
                    this.volumeIntersectionWidth * 2
                );
                nodesPolygonFixed.push(
                    Cesium.Cartographic.toCartesian(
                        new Cesium.Cartographic(
                            carto.longitude,
                            carto.latitude,
                            h
                        )
                    )
                );
            }
        }

        // Prepare list of the turf points
        const nodesPolygonFixedLength = nodesPolygonFixed.length;
        for (let i = 0; i < pointsLength; i++) {
            let degLon = turf.radiansToDegrees(points[i].longitude);
            let degLat = turf.radiansToDegrees(points[i].latitude);
            let degLonLat = [degLon, degLat];
            degLonLatList.push(degLonLat);
        }
        for (let i = 0; i < nodesPolygonFixedLength; i++) {
            const cartoRadian = Cesium.Cartographic.fromCartesian(nodesPolygonFixed[i]);
            const degLon = turf.radiansToDegrees(cartoRadian.longitude);
            const degLat = turf.radiansToDegrees(cartoRadian.latitude);
            const degLonLat = [degLon, degLat];
            degLonLatList.push(degLonLat);
        }
        let multiPoints = turf.points(degLonLatList);

        // Give heights for turf tins
        for (let i = 0; i < pointsLength; i++) {
            multiPoints.features[i].properties.z = points[i].height;
        }
        for (let i = 0; i < nodesPolygonFixedLength; i++) {
            const cartoRadian = Cesium.Cartographic.fromCartesian(nodesPolygonFixed[i]);
            multiPoints.features[i + pointsLength].properties.z = cartoRadian.height;
        }

        // TIN surfaces from all of the points including the nodes of the base polygon
        let tins = turf.tin(multiPoints, 'z');

        // Generate detailed grid points and filter with the polygon
        let rectangleSettings = this.calcRectangle(this._polygonShape[0]);
        const detailedGridPoints = this.createGrid(
            rectangleSettings.recWidthInMeter / 0.25, // 25cm is temporary
            rectangleSettings.recHeightInMeter / 0.25,
            rectangleSettings.polygonRectangle
        );
        let pointsInsidePolygon = this.determinePointInsidePolygon(
            this._tempActiveShapePoints,
            detailedGridPoints,
            2
        );

        // Get z list from TIN surface and apply to the detailed grid points
        let cartoListInside = [];
        let point, ptsWithin, triangle, lon, lat, h;
        let detailedSamplePoints = [];

        pointsInsidePolygon.forEach((point) => {
            let degreesLon = turf.radiansToDegrees(point.longitude);
            let degreesLat = turf.radiansToDegrees(point.latitude);
            let degreesLonLat = turf.point([degreesLon, degreesLat]);
            cartoListInside.push(degreesLonLat);
        });
        for (let i = 0; i < cartoListInside.length; i++) {
            point = cartoListInside[i];
            for (let j = 0; j < tins.features.length; j++) {
                triangle = turf.polygon(
                    [tins.features[j].geometry.coordinates[0]],
                    tins.features[j].properties
                );

                ptsWithin = turf.pointsWithinPolygon(point, triangle);
                if (ptsWithin.features.length > 0) {
                    // console.log("ptsWithin", ptsWithin, point, triangle)
                    point.properties.zValue = turf.planepoint(point, triangle);
                    lon = point.geometry.coordinates[0];
                    lat = point.geometry.coordinates[1];
                    h = Number(point.properties.zValue).toFixed(4);
                    if (h !== 'NaN') {
                        detailedSamplePoints.push(
                            Cesium.Cartographic.fromDegrees(
                                Number(lon),
                                Number(lat),
                                Number(h)
                            ) || undefined
                        );
                        break;
                    }
                }
            }
        }
        return detailedSamplePoints;
    }

    /**
     * Draw the triangle mesh. Much lighter than the boxes entities.
     * @param detailedSamplePoints
     * @returns {Cesium.Primitive}
     */
    drawTriangleMesh(detailedSamplePoints) {
        // Prepare list of the turf points
        let degLonLatList = [];
        const detailedSamplePointsLength = detailedSamplePoints.length;
        for (let i = 0; i < detailedSamplePointsLength; i = i + 1) {
            let degLon = turf.radiansToDegrees(
                detailedSamplePoints[i].longitude
            );
            let degLat = turf.radiansToDegrees(
                detailedSamplePoints[i].latitude
            );
            let degLonLat = [degLon, degLat];
            degLonLatList.push(degLonLat);
        }
        let multiPoints = turf.points(degLonLatList);
        for (let i = 0; i < degLonLatList.length; i++) {
            multiPoints.features[i].properties.z =
                detailedSamplePoints[i].height;
        }

        // Generate TIN from Delaunay triangulation
        let tins = turf.tin(multiPoints, 'z');

        // Draw the triangle mesh
        const triangleMeshes = new Cesium.CustomDataSource('triangleMeshes');

        for (let i = 0; i < tins.features.length; i++) {
            const triangle = turf.polygon(
                [tins.features[i].geometry.coordinates[0]],
                tins.features[i].properties
            );

            if (triangle) {
                const coor = triangle.geometry.coordinates[0];
                const h = triangle.properties;

                const firstnode = Cesium.Cartographic.fromDegrees(
                    Number(coor[0][0]),
                    Number(coor[0][1]),
                    Number(h.a)
                );
                const secondnode = Cesium.Cartographic.fromDegrees(
                    Number(coor[1][0]),
                    Number(coor[1][1]),
                    Number(h.b)
                );
                const thirdnode = Cesium.Cartographic.fromDegrees(
                    Number(coor[2][0]),
                    Number(coor[2][1]),
                    Number(h.c)
                );

                this._drawLayer.entities.add({
                    name: 'triangle',
                    polygon: {
                        hierarchy: [
                            Cesium.Cartographic.toCartesian(firstnode),
                            Cesium.Cartographic.toCartesian(secondnode),
                            Cesium.Cartographic.toCartesian(thirdnode)
                        ],
                        material: Cesium.Color.RED.withAlpha(0.7),
                        outline: true,
                        fill: false,
                        perPositionHeight: true
                    }
                });
            }
        }
        this.ids.push(triangleMeshes.entities.id);
    }

    /**
     *
     * @param plgn
     * @returns {{polygonRectangle,
     * recWidthInMeter: number,
     * recHeightInMeter: number
     * }}
     * Returns Cesium Rectangle object and the width and height of it
     */
    calcRectangle(plgn) {
        let polygonRectangle = Cesium.PolygonGeometry.computeRectangle({
            polygonHierarchy: plgn.polygon.hierarchy._value
        });
        let east = polygonRectangle.east;
        let west = polygonRectangle.west;
        let south = polygonRectangle.south;
        let north = polygonRectangle.north;
        this.extent = polygonRectangle;

        let EN_pos = new Cesium.Cartographic(east, north);
        let ES_pos = new Cesium.Cartographic(east, south);
        let WN_pos = new Cesium.Cartographic(west, north);

        let recWidthInMeter = Math.round(
            this.measureTwoPointsFromRadians(EN_pos, WN_pos)
        );
        let recHeightInMeter = Math.round(
            this.measureTwoPointsFromRadians(EN_pos, ES_pos)
        );

        return {
            polygonRectangle: polygonRectangle,
            recWidthInMeter: recWidthInMeter,
            recHeightInMeter: recHeightInMeter
        };
    }

    /**
     * Calculate the distance using turf.
     * @param rad1
     * @param rad2
     * @returns {number}
     */
    measureTwoPointsFromRadians(rad1, rad2) {
        let fromDegreesLon = turf.radiansToDegrees(rad1.longitude);
        let fromDegreesLat = turf.radiansToDegrees(rad1.latitude);
        let from = turf.point([fromDegreesLon, fromDegreesLat]);
        let toDegreesLon = turf.radiansToDegrees(rad2.longitude);
        let toDegreesLat = turf.radiansToDegrees(rad2.latitude);
        let to = turf.point([toDegreesLon, toDegreesLat]);
        let options = { units: 'meters' };
        return turf.distance(from, to, options);
    }

    /**
     * Filters the points that are inside the coverage.
     * Based on Ray casting algorithm
     * @param point
     * @param vX    longitude list. Degrees.
     * @param vY    latitude list. Degrees.
     * @returns {boolean}
     */
    pointInsidePolygon(point, vX, vY) {
        let x = point.longitude,
            y = point.latitude;
        let inside = false;
        for (let i = 0, j = vX.length - 1; i < vX.length; j = i++) {
            // eg. i = 0, j = 3, i < 4, j = 0
            // eg. i = 1, j = 0, i < 4, j = 1
            // eg. i = 2, j = 1, i < 4, j = 2
            let xi = vX[i],
                yi = vY[i];
            let xj = vX[j],
                yj = vY[j];

            let intersect =
                yi >= y !== yj >= y &&
                x <= ((xj - xi) * (y - yi)) / (yj - yi) + xi;
            if (intersect) {
                inside = !inside;
            }
        }
        return inside;
    }

    /**
     * Determine the points inside the polygon which is selected area.
     * @param polygonLatLon Cartesian3 array
     * @param points Cartographic array
     * @param buffer
     * @returns {*[]} Cartographic array
     */
    determinePointInsidePolygon(polygonLatLon, points, buffer = 1) {
        let cartoPoints = [];
        let lon = [];
        let lat = [];
        let pointsInside = [];
        polygonLatLon.forEach((cartesianPoint) => {
            cartoPoints.push(Cesium.Cartographic.fromCartesian(cartesianPoint));
        });
        for (let i = cartoPoints.length - 1; i >= 0; i--) {
            lon.push(cartoPoints[i].longitude);
            lat.push(cartoPoints[i].latitude);
        }
        points.forEach((point) => {
            if (this.pointInsidePolygon(point, lon, lat)) {
                pointsInside.push(point);
            }
        });
        return pointsInside;

        // // TODO Adding buffer style
        // let lonlat = [];
        // polygonLatLon.forEach(cartesianPoint => {
        //     cartoPoints.push(Cesium.Cartographic.fromCartesian(cartesianPoint));
        // });
        // for (let i = cartoPoints.length - 1; i >= 0; i--) {
        //     lonlat.push([cartoPoints[i].longitude, cartoPoints[i].latitude]);
        // }
        // lonlat.push(lonlat[0]);
        // const polygonBuffered = turf.buffer(turf.polygon([lonlat]), buffer, { units: 'meters' });
        // polygonBuffered.geometry.coordinates[0].forEach(coord => {
        //     lon.push(Cesium.Math.toDegrees(coord[0]));// radians to degrees
        //     lat.push(Cesium.Math.toDegrees(coord[1]));
        // });
        // points.forEach(point => {
        //     if (this.pointInsidePolygon(point, lon, lat)) {
        //         pointsInside.push(point);
        //     }
        // });
        // return pointsInside;
    }

    /**
     * Create grid for calculation of the each volume of the cell.
     * @param gridWidthNum
     * @param gridHeightNum
     * @param rectangleObject For use to calculate by lerp function using positions of cardinal directions
     * @returns {*[]}
     */
    createGrid(gridWidthNum, gridHeightNum, rectangleObject) {
        // console.log('grid', gridWidthNum, gridHeightNum)
        // console.log('resolution',this._resolutionGrid)
        let e = rectangleObject;
        let samplePositions = [];
        for (let y = 0; y < gridHeightNum; ++y) {
            for (let x = 0; x < gridWidthNum; ++x) {
                let longitude = Cesium.Math.lerp(
                    e.west,
                    e.east,
                    x / (gridWidthNum - 1)
                );
                let latitude = Cesium.Math.lerp(
                    e.south,
                    e.north,
                    y / (gridHeightNum - 1)
                );
                let position = new Cesium.Cartographic(longitude, latitude);
                samplePositions.push(position);
            }
        }
        return samplePositions;
    }

    surfacePlaneArea(cornerLatLonRad) {
        // input: Cesium.Carthography
        // cannot get accurate result
        function rad2deg(rad) {
            return turf.radiansToDegrees(rad);
        }

        let arrayPol = [];
        Object.keys(cornerLatLonRad).forEach((key) => {
            let elePol = [
                rad2deg(cornerLatLonRad[key].longitude),
                rad2deg(cornerLatLonRad[key].latitude)
            ];

            arrayPol.push(elePol);
        });
        arrayPol.push(arrayPol[0]);
        if (turf.booleanClockwise(turf.lineString(arrayPol))) {
            arrayPol.reverse();
        }
        let area = turf.area(turf.polygon([arrayPol]));
        // console.log("area", area, "polygon: ", arrayPol)
        // console.log("cornerLatLonRad: ", cornerLatLonRad)

        return area;
    }

    /**
     * Calculate the heights of the horizontal base when TIN base surface is not selected.
     * input: methods are minimum,maximum, mean, Tin base. coordinatesPolygon is coordinates of points of polygon in Cesium.Cartesian3
     * @param coordinatesPolygon
     * @returns {number|*}
     * output: calculated height value by the selected method
     */
    getHeightBaseSurface(coordinatesPolygon) {
        let heightList = [];
        coordinatesPolygon.forEach((point) => {
            let carto = Cesium.Cartographic.fromCartesian(point);
            heightList.push(carto.height);
        });

        if (this._method === 'highest') {
            return Math.max(...heightList);
        } else if (this._method === 'lowest') {
            return Math.min(...heightList);
        } else if (this._method === 'average') {
            const heightMean = (arr) =>
                arr.reduce((a, b) => a + b, 0) / arr.length;
            return heightMean(heightList);
        } else {
            // custom
            return this.customHeight;
        }
    }

    /**
     * Return the cartographic coordinates w/ Z list of the intersection points of the grid
     * that are above on the base surface.
     * It is using turf library.
     * @param nodesPolygon the nodes of polygon
     * @param pointsInsidePolygon the points inside polygon
     * @returns {*[]} Cartographic coordinates w/ Z list of the intersection points of the grid
     */
    tinSurface(nodesPolygon, pointsInsidePolygon) {
        // Create TIN with Z-------------------------------
        let degLonLatList = [];
        for (let i = 0; i < nodesPolygon.length; i++) {
            let cartoRadian = Cesium.Cartographic.fromCartesian(
                nodesPolygon[i]
            );
            let degLon = turf.radiansToDegrees(cartoRadian.longitude);
            let degLat = turf.radiansToDegrees(cartoRadian.latitude);
            let degLonLat = [degLon, degLat];
            degLonLatList.push(degLonLat);
        }
        let multiPoints = turf.points(degLonLatList);

        for (let i = 0; i < nodesPolygon.length; i++) {
            let cartoRadian = Cesium.Cartographic.fromCartesian(
                nodesPolygon[i]
            );
            multiPoints.features[i].properties.z = cartoRadian.height;
        }
        let tins = turf.tin(multiPoints, 'z');
        //------------------------------- END

        // Get z list from TIN surface-----------------------------------------------
        let cartoListInside = [];
        let cartoListInsidewithZ = [];
        let point, ptsWithin, triangle, lon, lat, h;

        pointsInsidePolygon.forEach((point) => {
            let degreesLon = turf.radiansToDegrees(point.longitude);
            let degreesLat = turf.radiansToDegrees(point.latitude);
            let degreesLonLat = turf.point([degreesLon, degreesLat]);
            cartoListInside.push(degreesLonLat);
        });

        for (let i = 0; i < cartoListInside.length; i++) {
            point = cartoListInside[i];
            for (let j = 0; j < tins.features.length; j++) {
                triangle = turf.polygon(
                    [tins.features[j].geometry.coordinates[0]],
                    tins.features[j].properties
                );
                ptsWithin = turf.pointsWithinPolygon(point, triangle);
                if (ptsWithin.features.length > 0) {
                    point.properties.zValue = turf.planepoint(point, triangle);
                    lon = point.geometry.coordinates[0];
                    lat = point.geometry.coordinates[1];
                    h = Number(point.properties.zValue.toFixed(4)) || 0;
                    cartoListInsidewithZ.push(
                        Cesium.Cartographic.fromDegrees(
                            Number(lon),
                            Number(lat),
                            Number(h)
                        )
                    );
                }
            }
        }
        return cartoListInsidewithZ; // carto is radius
    }

    /**
     * Get the coordinate list of the polygon which includes height values using Cesium function.
     * Virtual grid is drawn upon polygon and the list is those only within the polygon.
     * @param rectangleObject
     * @param gridWidthInMeter // The length of the grid width
     * @param gridHeightInMeter // The length of the grid height
     * @param specifyResolution
     * @returns {Promise<*>}
     */
    async threeDTileSample(
        rectangleObject,
        gridWidthInMeter,
        gridHeightInMeter,
        specifyResolution = false
    ) {
        let planeArea = gridHeightInMeter * gridWidthInMeter;
        let resolution;
        let volumeIntersectionWidth = 0.5;

        if (!specifyResolution) {
            if (planeArea > 200000) {
                resolution = 20;
                volumeIntersectionWidth = 4;
            } else if (planeArea > 100000) {
                resolution = 15;
                volumeIntersectionWidth = 3;
            } else if (planeArea > 64000) {
                resolution = 15;
                volumeIntersectionWidth = 2;
            } else if (planeArea > 32000) {
                resolution = 10;
                volumeIntersectionWidth = 1;
            } else if (planeArea > 16000) {
                resolution = 5;
                volumeIntersectionWidth = 0.7;
            } else if (planeArea > 8000) {
                resolution = 3;
                volumeIntersectionWidth = 0.5;
            } else if (planeArea > 4000) {
                resolution = 3;
                volumeIntersectionWidth = 0.3;
            } else if (planeArea > 1000) {
                resolution = 2;
                volumeIntersectionWidth = 0.2;
            } else if (planeArea > 100) {
                resolution = 1;
                volumeIntersectionWidth = 0.1;
            } else {
                resolution = 0.5;
                volumeIntersectionWidth = 0.05;
            }

            this._resolutionGrid = resolution;
            this.volumeIntersectionWidth = volumeIntersectionWidth / 2;
        }
        let c_w = gridWidthInMeter / this._resolutionGrid; //Number of grid intersection
        let c_h = gridHeightInMeter / this._resolutionGrid; //Number of grid intersection

        // Create grid
        let gridPos = this.createGrid(c_w, c_h, rectangleObject);
        gridPos = this.determinePointInsidePolygon(
            this._tempActiveShapePoints,
            gridPos,
            2
        );
        // Test sampleHeight's height

        // console.log(gridPos[0].longitude, gridPos[0].latitude, this._viewer.scene.sampleHeight(gridPos[0]))
        // const carto1 = new Cesium.Cartographic(gridPos[0].longitude, gridPos[0].latitude, this._viewer.scene.sampleHeight(gridPos[0]));
        // this._viewer.entities.add({
        //     position: Cesium.Cartographic.toCartesian(carto1),
        //     point: {
        //         pixelSize: 50,
        //         color: Cesium.Color.GREEN,
        //         outlineColor: Cesium.Color.WHITE,
        //         outlineWidth: 2
        //     }
        // });

        return await this._viewer.scene.sampleHeightMostDetailed(
            gridPos,
            this._polygonShape,
            this.volumeIntersectionWidth
        );

        // // TODO : test sampleHeight -> same result with SampleHeightMostDetailed
        // const gridPoswithZ = gridPos.map((pos) => {
        //     pos.height = this._viewer.scene.sampleHeight(pos, this._polygonShape, this.volumeIntersectionWidth);
        //     return pos;
        // });
        // console.log('gridPoswithZ', gridPoswithZ)
        //
        // return gridPoswithZ;
    }

    /**
     * Returns an array that averages the heights of the four corner of each cell in the grid.
     * @param hight_array
     * @returns {*[]}
     */
    averageCorner(hight_array = []) {
        let averageHeights = [];
        let avgHeight;
        let latlonh;
        for (let i = 0; i < hight_array.length - 4; i++) {
            avgHeight =
                (hight_array[i].height +
                    hight_array[i + 1].height +
                    hight_array[i + 2].height +
                    hight_array[i + 3].height) /
                4;
            latlonh = new Cesium.Cartographic(
                hight_array[i].longitude,
                hight_array[i].latitude,
                avgHeight
            );
            averageHeights.push(latlonh);
        }
        return averageHeights;
    }

    median(numbers) {
        const half = (numbers.length / 2) | 0;
        const arr = numbers.sort((a, b) => {
            return a - b;
        });
        if (arr.length % 2) {
            return Number(arr[half]);
        }
        return Number((arr[half - 1] + arr[half]) / 2);
    }

    /**
     * Mainly used for debug.
     * It will show the label entity to each intersection point of the grid.
     * @param samplePositions
     * @param resultObject
     * @param mode skyblue if true, pink is false
     * @param gapArray
     */
    sampleDebug(samplePositions, resultObject, mode, gapArray) {
        //By default, Cesium does not obsure geometry
        //behind terrain. Setting this flag enables that.
        this._viewer.scene.globe.depthTestAgainstTerrain = false;
        this._viewer.entities.suspendEvents();

        if (resultObject) {
            let ellipsoid = Cesium.Ellipsoid.WGS84;

            let cutVolumeResult = resultObject.CutVolume;
            let fillVolumeResult = resultObject.FillVolume;
            let netVolumeResult = resultObject.NetVolume;
            let positionOfText = samplePositions[0];
            if (positionOfText === undefined) {
                positionOfText = { height: 0 };
            }
            positionOfText.height = positionOfText.height + 2;
            this._viewer.entities.add({
                position: ellipsoid.cartographicToCartesian(positionOfText),

                point: {
                    pixelSize: 8,
                    color: Cesium.Color.RED,
                    outlineColor: Cesium.Color.WHITE,
                    outlineWidth: 2
                },
                label: {
                    text:
                        'Method: ' +
                        this._method +
                        '\n ' +
                        this._t('viewer.properties.CutVolume') +
                        ': ' +
                        cutVolumeResult +
                        ' m' +
                        '\n ' +
                        this._t('viewer.properties.FillVolume') +
                        ': ' +
                        fillVolumeResult +
                        ' m' +
                        '\n ' +
                        this._t('viewer.properties.NetVolume') +
                        ':: ' +
                        netVolumeResult +
                        ' m',
                    font: '14pt monospace',
                    show: true,
                    style: Cesium.LabelStyle.FILL_AND_OUTLINE,
                    backgroundColor: Cesium.Color.BLACK,
                    showBackground: true,
                    outlineWidth: 2,
                    horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
                    verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                    pixelOffset: new Cesium.Cartesian2(0, -9)
                }
            });
        } else {
            const randomColor = Cesium.Color.fromRandom({
                alpha: 1.0
            });
            for (const key of samplePositions.keys()) {
                let position = samplePositions[key];
                let cartesian;
                if (position instanceof Cesium.Cartographic) {
                    cartesian = Cesium.Cartographic.toCartesian(position);
                } else {
                    cartesian = position;
                }
                // this._viewer.entities.add({
                //     // name: position.height.toFixed(4) ,
                //     position: cartesian,
                //     // billboard: {
                //     //     verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                //     //     scale: 0.7,
                //     //     image: 'facility.gif'
                //     // },
                //     label: {
                //         // text: position.height.toFixed(4),
                //         text: String(key),
                //         font: '10pt monospace',
                //         horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
                //         pixelOffset: new Cesium.Cartesian2(0, -14),
                //         fillColor: Cesium.Color.BLACK,
                //         outlineColor: Cesium.Color.BLACK,
                //         showBackground: true,
                //         backgroundColor: new Cesium.Color(0.9, 0.9, 0.9, 0.7),
                //         backgroundPadding: new Cesium.Cartesian2(4, 3)
                //     }
                // });
                this._viewer.entities.add({
                    // name: position.height.toFixed(4) ,
                    position: cartesian,
                    point: {
                        color: randomColor,
                        pixelSize: 3,
                        heightReference: Cesium.HeightReference.NONE
                    },
                    label: {
                        // text: position.height.toFixed(1),
                        // text: String(key),
                        font: '10pt monospace',
                        horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
                        pixelOffset: new Cesium.Cartesian2(0, -14),
                        fillColor: Cesium.Color.BLACK,
                        outlineColor: Cesium.Color.BLACK,
                        showBackground: true,
                        backgroundColor: new Cesium.Color(0.9, 0.9, 0.9, 0.7),
                        backgroundPadding: new Cesium.Cartesian2(4, 3)
                    }
                });
            }
        }
        this._viewer.entities.resumeEvents();
    }

    drawBoxel(latlonh, gapArray) {
        let num = 0;
        const addVerticalLine = (pcCartesian, gap) => {
            // Convert the position to cartographic coordinates.
            const pcCarto = Cesium.Cartographic.fromCartesian(pcCartesian);
            // Set the height to base height by subtracting the gap.
            pcCarto.height = pcCarto.height - gap;
            // Conver the position to cartesian coordinates with the new base height.
            const baseCartesian = Cesium.Cartographic.toCartesian(pcCarto);
            // Add the entity to the scene.
            return this._viewer.entities.add({
                polyline: {
                    positions: [pcCartesian, baseCartesian],
                    material:
                        gap > 0
                            ? Cesium.Color.fromCssColorString('#FA09C4')
                            : Cesium.Color.fromCssColorString('#68EBD0') //Cesium.Color.fromRandom({ alpha: 1 }),
                }
            });
        };

        this._viewer.entities.suspendEvents();
        for (const key of latlonh.keys()) {
            const ent = addVerticalLine(
                Cesium.Cartographic.toCartesian(latlonh[key]),
                gapArray[num]
            );
            num++;
            // let gap = Number(gapArray[num]).toFixed(4);

            // latlonh[key].height = latlonh[key].height - gap / 2;
            // if (isNaN(latlonh[key].height)) console.log('height is NaN', latlonh[key].height);
            // if (isNaN(gap)) console.log('gap is NaN', gap);
            // try {
            //     if (latlonh[key] !== undefined || !isNaN(gap)) {
            //         this._viewer.entities.add({
            //             position: Cesium.Cartographic.toCartesian(latlonh[key]),
            //             box: {
            //                 dimensions: new Cesium.Cartesian3(
            //                     this._resolutionGrid,
            //                     this._resolutionGrid,
            //                     Math.abs(Number(gap))),
            //                 material: Number(gap) > 0
            //                     ? Cesium.Color.fromCssColorString('#FA09C4').withAlpha(0.5)
            //                     : Cesium.Color.fromCssColorString('#68EBD0').withAlpha(0.5)
            //                 // outline: true,
            //             }
            //             // label: {
            //             //     text: String(gap),
            //             // }
            //         });
            //     }
            // } catch (e) {
            //     console.log('cannot create cube', e)
            // }
        }
        // this.addPolygonMesh(cartog, gapArray);
        this._viewer.entities.resumeEvents();
    }

    addPolygon(cart, gap) {
        const cartesian_temp = Cesium.Cartographic.fromCartesian(cart);
        const longitude = Cesium.Math.toDegrees(cartesian_temp.longitude);
        const latitude = Cesium.Math.toDegrees(cartesian_temp.latitude);
        const height = cartesian_temp.height;
        const delta = 0.000001; //this._resolutionGrid/100000000;
        const west = longitude - delta;
        const east = longitude + delta;

        const south = latitude - delta;
        const north = latitude + delta;
        const a = Cesium.Cartesian3.fromDegrees(west, south, height);
        const b = Cesium.Cartesian3.fromDegrees(west, north, height);
        const c = Cesium.Cartesian3.fromDegrees(east, north, height);
        const d = Cesium.Cartesian3.fromDegrees(east, south, height);
        const positions = [a, b, c, d];
        return this._viewer.entities.add({
            polygon: {
                hierarchy: positions,
                material: Cesium.Color.fromCssColorString('#68EBD0'), //Cesium.Color.fromRandom({ alpha: 1 }),
                height: height,
                heightReference: Cesium.HeightReference.NONE,
                extrudedHeight: 10,
                extrudedHeightReference: Cesium.HeightReference.NONE
            }
        });
    }

    addPolygonMesh(cartog, gapArray) {
        const cartesianList = cartog.map((p) =>
            Cesium.Cartographic.toCartesian(p)
        );

        return this._viewer.entities.add({
            polygon: {
                hierarchy: cartesianList,
                material: Cesium.Color.fromCssColorString('#68EBD0'), //Cesium.Color.fromRandom({ alpha: 1 }),
                height: cartog[0].height,
                heightReference: Cesium.HeightReference.NONE,
                extrudedHeight: gapArray[0],
                extrudedHeightReference: Cesium.HeightReference.NONE,
                classificationType: Cesium.ClassificationType.CESIUM_3D_TILE,
                outline: true,
                perPositionHeight: true
            }
        });
    }

    /**
     * Create label entity that shows the result.
     * @param points
     * @param resultObject
     */
    showResultOnView(points, resultObject) {
        let cutVolumeResult = resultObject.CutVolume;
        let fillVolumeResult = resultObject.FillVolume;
        let netVolumeResult = resultObject.NetVolume;

        this._drawLayer.entities.suspendEvents();

        let fillVolume = fillVolumeResult != null ? fillVolumeResult : 0;

        // for result alignment in result text
        let cutLength = cutVolumeResult.toFixed(3).length;
        let fillLength = fillVolume.toFixed(3).length;
        let netLength = netVolumeResult.toFixed(3).length;
        let largestLength = Math.max(cutLength, fillLength, netLength);
        cutVolumeResult = " ".repeat((largestLength - cutLength) > 0 ? (largestLength - cutLength) : 0) + cutVolumeResult.toFixed(3); 
        fillVolume = " ".repeat((largestLength - fillLength) > 0 ? (largestLength - fillLength) : 0) + fillVolume.toFixed(3); 
        netVolumeResult = " ".repeat((largestLength - netLength) > 0 ? (largestLength - netLength) : 0) + netVolumeResult.toFixed(3);

        // center title text based on character length
        let label = this._lang == 'en' ? "Volume" : "体積";
        let maxLength = (this._lang == 'en' ? 22 : 16) + largestLength;
        let parentText = centerText(label, maxLength);

        // combining title & result text in one variable
        let textValue = parentText + '\n' + '\n' +
            this._t('viewer.properties.CutVolume') +
            ' :  ' +
            cutVolumeResult +
            ' m3' +
            '\n' +
            this._t('viewer.properties.FillVolume') +
            ':  ' +
            fillVolume +
            ' m3' +
            '\n' +
            this._t('viewer.properties.NetVolume') +
            ' :  ' +
            netVolumeResult +
            ' m3'
        if (this._lang == 'ja') {
            textValue = parentText + '\n' + '\n' +
                this._t('viewer.properties.CutVolume') +
                '   :  ' +
                cutVolumeResult +
                ' m3' +
                '\n' +
                this._t('viewer.properties.FillVolume') +
                '   :  ' +
                fillVolume +
                ' m3' +
                '\n' +
                this._t('viewer.properties.NetVolume') +
                ' :  ' +
                netVolumeResult +
                ' m3'
        }
        // adding the label entity for volume
        let e = this._drawLayer.entities.add({
            position: points[0],
            label: {
                text: textValue,
                // heightReference: Cesium.HeightReference.NONE,
                font: '12px monospace',
                show: true,
                showBackground: true,
                backgroundColor: Cesium.Color.BLACK.withAlpha(0.8),
                // the same setup as the point label entity
                horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
                verticalOrigin: Cesium.VerticalOrigin.TOP,
                pixelOffset: new Cesium.Cartesian2(0, -30),
                scale: 1,
                disableDepthTestDistance: Number.POSITIVE_INFINITY
            },
        });
        // adding name of entity for later tracking
        e.name = 'volume';
        this.ids.push(e.id);
        if (this.mode == "RE_CALC") {
            this._viewer.selectedEntity = e;
        }
        this._drawLayer.entities.resumeEvents();

        function centerText(text, numberOfSpaces) {
            text = text.trim();
            var l = text.length;
            var w2 = Math.ceil(numberOfSpaces / 2);
            var l2 = Math.ceil(l / 2);
            var s = new Array(w2 - l2 + 1).join(" ");
            text = s + text + s;
            if (text.length < numberOfSpaces) {
                text += new Array(numberOfSpaces - text.length + 1).join(" ");
            }
            return text;
        }
    }

    /**
     * Create red point entity.
     * @param worldPosition
     * @returns {*}
     * @private
     */
    _createPoint(worldPosition) {
        let point = this._drawLayer.entities.add({
            position: worldPosition,
            point: {
                color: Cesium.Color.CRIMSON.withAlpha(0.8),
                outlineColor: Cesium.Color.WHITE,
                outlineWidth: 2,
                pixelSize: 7,
                heightReference: Cesium.HeightReference.NONE
            }
        });
        this.ids.push(point.id);
        return point;
    }

    /**
     * Draw the polygon and line entity that has callback property.
     * @param positionsData
     * @param pph
     * @param colorAlpha
     * @param classType
     * @param positionLine
     * @returns {*}
     * @private
     */
    _drawShape(
        positionsData,
        pph = false,
        colorAlpha,
        classType,
        positionLine
    ) {
        let shape = this._drawLayer.entities.add({
            polygon: {
                hierarchy: positionsData,
                material: new Cesium.ColorMaterialProperty(colorAlpha),
                width: 5,
                outline: true,
                outlineWidth: 4.0,
                outlineColor: Cesium.Color.WHITE,
                classificationType: classType,
                perPositionHeight: pph,
                heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
                // extrudedHeight:0,
            }
        });

        if (positionLine) {
            let lineEnt = new Cesium.Entity();
            lineEnt.polyline = {
                positions: new Cesium.CallbackProperty(function () {
                    return positionLine;
                }, false),
                width: 1,
                material: Cesium.Color.YELLOW.withAlpha(0.8),
                clampToGround: false
            };
            this._drawLayer.entities.add(lineEnt);
            this.ids.push(lineEnt.id);
        }
        shape.name = 'volume_'+shape.id;
        this.ids.push(shape.id);
        return shape;
    }

    /**
     * Draw the shape and show the result that was calculated before, which is saved in DB.
     * @param cartesianXYZ
     * @param result
     * @returns {{id: []}}
     */
    draw(cartesianXYZ, result) {
        // this.drawBoxel(result.carto_Z, result.gapArray);
        // this.sampleDebug(result.carto_Z);

        this._activeShapePoints = [];
        if (cartesianXYZ.length > 0) {
            cartesianXYZ.forEach((p) => {
                this._activeShapePoints.push(
                    new Cesium.Cartesian3(p.x, p.y, p.z)
                );
                this._createPoint(p);
            });
        }
        let shape = this._drawShape(
            this._activeShapePoints,
            false, // set to false to include the per height color
            Cesium.Color.YELLOW.withAlpha(0.8),
            Cesium.ClassificationType.BOTH
        );
        let shape2 = this._drawShape(
            this._activeShapePoints,
            true,
            Cesium.Color.RED.withAlpha(0.5),
            Cesium.ClassificationType.CESIUM_3D_TILE
        );
        this.ids.push(shape.id);
        this.ids.push(shape2.id);
        this.showResultOnView(this._activeShapePoints, result);
        return { id: this.ids };
    }

    recalculate(polygonPoints) {
        if (this._handler !== null) {
            // this._handler.destroy();
            this._handler = null;
        }
        this._activeShapePoints = [];
        if (polygonPoints.length > 0) {
            polygonPoints.forEach((p) => {
                this._activeShapePoints.push(
                    new Cesium.Cartesian3(p.x, p.y, p.z)
                );
            });
        }
        if (this._method !== 'triangulated') {
            const commonHeight =
                this._method === 'custom'
                    ? this.customHeight
                    : this.getHeightBaseSurface(this._activeShapePoints);

            this._tempActiveShapePoints = this._activeShapePoints.map((c) => {
                let carto = Cesium.Cartographic.fromCartesian(c);
                carto.height = commonHeight;
                let cartesian = Cesium.Cartographic.toCartesian(carto);
                this._createPoint(cartesian);
                return cartesian;
            });
        } else {
            this._tempActiveShapePoints = this._activeShapePoints;
            //create the points
            this._tempActiveShapePoints.map(c => this._createPoint(c));
        }
        this._activeShapePoints = this._tempActiveShapePoints;
        this.finish(true);

        /** Commented the code below to reuse the finish function since both have the same code */
        // this._polygonShape[0] = this._drawShape(
        //     this._tempActiveShapePoints,
        //     false,
        //     Cesium.Color.YELLOW.withAlpha(0.8),
        //     // Cesium.Color.WHITE.withAlpha(0.2),
        //     Cesium.ClassificationType.BOTH
        // );

        // // let clampShape = this._drawShape(
        // //     this._tempActiveShapePoints,
        // //     false,
        // //     Cesium.Color.ORANGE.withAlpha(0.6),
        // //     // Cesium.Color.fromBytes(104, 235, 208, 240),
        // //     Cesium.ClassificationType.BOTH
        // // );

        // let settings = this.calcRectangle(this._polygonShape[0]);
        // let detailedSamplePointsPromise_3DTile = this.threeDTileSample(
        //     settings.polygonRectangle,
        //     settings.recWidthInMeter,
        //     settings.recHeightInMeter,
        //     true);

        // detailedSamplePointsPromise_3DTile.then(points => {
        //     this._volume3Dresult = this.calc3Dvolume(points);
        //     return this._volume3Dresult;
        // }).then((res) => {
        //     this.showResultOnView(this._tempActiveShapePoints, res);
        //     this.saveCoordinatesFromPolygonHiearchy(this._polygonShape[0], res);
        // });
    }

    saveCoordinatesFromPolygonHiearchy(plgn, result) {
        let cartesian3List = plgn.polygon.hierarchy._value.positions;
        // Round the 4th decimal place
        cartesian3List.map((c) => {
            c.x = Math.round(c.x * 1000) / 1000;
            c.y = Math.round(c.y * 1000) / 1000;
            c.z = Math.round(c.z * 1000) / 1000;
        });

        let points = {
            positions: cartesian3List,
            result: result,
            method: this._method,
            resolution: this._resolutionGrid,
            cutFillResult: this.isCutFillResult || this.isCutFillMode
        };

        this.callback({ points: points, id: this.ids });
        if (this._refs.cesiumRef != null) { 
            this._refs.cesiumRef.tempPos = points;
            this._refs.cesiumRef.entityIds = this.ids; 
        }        
              
        // console.log("this.saveCoordinatesAndResult", this.saveCoordinatesAndResult);
    }

    leftClickHandler(event) {
        // let earthPosition = this._viewer.scene.pickPosition(event.position);
        // `earthPosition` will be undefined if our mouse is not over the globe.

        // currently, it cannot point at the Terrain tile as it shows rendering error
        // when using the code below
        // let earthPosition = this.getCartesian3FromPiX(event.position);

        let earthPosition = this.measure.getMousePosition(event.position);
        //let earthPosition = this.getCartesian3FromPiX(event.position);

        if (Cesium.defined(earthPosition) && earthPosition !== false) {
            if (this._activeShapePoints.length === 0) {
                if (earthPosition !== undefined && earthPosition) {
                    // Moving point
                    this._floatingPoint = this._createPoint(earthPosition.clone());
                    // Static first point
                    this._createPoint(earthPosition.clone());
                } else return;
                // First and second coordinate for the moving line
                this._activeShapePoints.push(earthPosition.clone());
                this._activeShapePoints.push(earthPosition.clone());

                let dynamicPositions = new Cesium.CallbackProperty(() => {
                    return new Cesium.PolygonHierarchy(
                        this._activeShapePoints
                    );
                }, false);
                this._activeShape = this._drawShape(
                    dynamicPositions,
                    true,
                    Cesium.Color.RED.withAlpha(0.5),
                    Cesium.ClassificationType.BOTH,
                    this._activeShapePoints
                );
            } else {
                if (earthPosition !== undefined && earthPosition !== false) {
                    try {
                        // Added coordinate
                        this._activeShapePoints.push(earthPosition.clone());
                        // Added point
                        this._createPoint(earthPosition.clone());
                    } catch (e) {
                        console.error(e);
                        console.log("Error", earthPosition);
                    }
                }
            }
        }
    }

    mouseMoveHandler(event) {
        //Handler for Hover
        this.mouseHoverHandler(event.endPosition);
        if (Cesium.defined(this._floatingPoint)) {
            const newPosition = this.measure.getMousePosition(event.endPosition);

            // const m = this.getCartesian3FromPiX(event.position);
            // let mouseMovePosition = new Cesium.CallbackProperty(() => {
            //     return m.clone();
            // }, false);
            // console.log('mouseMovePosition', mouseMovePosition)

            if (Cesium.defined(newPosition) && newPosition !== false) {
                // the call-back moving point
                try {
                    if (typeof this._floatingPoint.position.setValue === 'function') {
                        this._floatingPoint.position.setValue(
                            newPosition
                        );
                    } else {
                        this._floatingPoint.position = new Cesium.CallbackProperty(function () {
                            return newPosition;
                        }, false);
                    }

                }
                catch (e) {
                    console.log('Floating point error', e)
                    return;
                }
                // cartesian saved on db
                if (this._activeShapePoints.length > 0) {
                    // Update the last point
                    this._activeShapePoints[this._activeShapePoints.length - 1] = newPosition;
                } else {
                    this._activeShapePoints.push(newPosition);
                }
            }
        }
    }

    async rightClickHandler() {
        let valid = this.measure.validateMeasurementNumberOfPoints(this._activeShapePoints, 3)
        if (!valid) return

        // if (this._activeShapePoints.length < 4) {
        //     this.$helper.warningMessage('Select the next point', '', '', true);
        //     return false;
        // } else {

        if (!this.isCutFillMode) { //Normal Volume Calculation
            await this.finish(false);
        } else {//Cut Fill Volume Calculation

            //Set Flag rightClickTerminated to true
            this.rightClickTerminated = true;

            //Pop the last position
            this._originalShapePoints = [...this._activeShapePoints];
            this._activeShapePoints.pop();
            this._viewer.entities.remove(this._floatingPoint);
            this._drawLayer.entities.remove(this._floatingPoint);
            this._floatingPoint = undefined;
        }

        //clear stored hovered points
        this.clearMeasurementSnapping();
        return true;
        //}

    }

    /**
     * Retrieves the mouse position.
     * @param {Object} position - The position object.
     * @returns {Object} - The mouse position object.
     */
    getMouseMovePosition(position) {
        // Get the hovered point position
        let hoveredPointPosition =
            MeasurementSnapping.getHoveredPointPosition();

        // If a hovered point position exists, return it; otherwise, convert the position to Cartesian3
        return hoveredPointPosition ? hoveredPointPosition : this.getCartesian3FromPiX(position);
    }

    /**
     * Handles the event when the mouse hovers over the Cesium viewer component.
     *
     * @param {Object} position - The position of the mouse cursor.
     * @param {Object} this._viewer - The Cesium viewer component.
     */
    mouseHoverHandler(position) {
        //detects if there is a hovered point
        MeasurementSnapping.mouseMoveSnapToPointListener(
            position,
            this._viewer
        );
        //change cursor to add if point is hovered, else set to default cursor
        this._refs.cesiumRef.snapCursor =
            MeasurementSnapping.getHoveredPointPosition() ? true : false;
    }

    /**
     * Clears all the hovered points and sets the cursor to the default cursor mode.
     */
    clearMeasurementSnapping() {
        MeasurementSnapping.clearHoveredPoint();
        this._refs.cesiumRef.snapCursor = false;
    }

    getCartesian3FromPiX(px) {
        if (this._viewer && px) {
            let picks = this._viewer.scene.drillPick(px);
            let cartesian = null;
            let isOn3dtiles = false,
                isOnTerrain = false;
            // drillPick
            for (let i in picks) {
                let pick = picks[i];
                if (
                    (pick &&
                        pick.primitive instanceof Cesium.Cesium3DTileFeature) ||
                    (pick &&
                        pick.primitive instanceof Cesium.Cesium3DTileset) ||
                    (pick && pick.primitive instanceof Cesium.Model)
                ) {
                    //模型上拾取
                    isOn3dtiles = true;
                }
                // 3dtilset
                if (isOn3dtiles) {
                    this._viewer.scene.pick(px); // pick
                    cartesian = this._viewer.scene.pickPosition(px);
                    // just doing this to make the height always positive?
                    if (cartesian) {
                        let cartographic =
                            Cesium.Cartographic.fromCartesian(cartesian);
                        if (cartographic.height < 0) cartographic.height = 0;
                        let lon = Cesium.Math.toDegrees(cartographic.longitude),
                            lat = Cesium.Math.toDegrees(cartographic.latitude),
                            height = cartographic.height;
                        cartesian = this.transformWGS84ToCartesian({
                            lng: lon,
                            lat: lat,
                            alt: height
                        });
                    }
                }
            }

            // 地形
            let boolTerrain =
                this._viewer.terrainProvider instanceof
                Cesium.EllipsoidTerrainProvider;
            // Terrain
            if (!isOn3dtiles && !boolTerrain) {
                let ray = this._viewer.scene.camera.getPickRay(px);
                if (!ray) return null;
                cartesian = this._viewer.scene.globe.pick(
                    ray,
                    this._viewer.scene
                );
                isOnTerrain = true;
            }
            // 地球
            if (!isOn3dtiles && !isOnTerrain && boolTerrain) {
                cartesian = this._viewer.scene.camera.pickEllipsoid(
                    px,
                    this._viewer.scene.globe.ellipsoid
                );
            }
            if (cartesian) {
                // let position = this.transformCartesianToWGS84(cartesian);
                // if (position.alt < 0) {
                //     cartesian = this.transformWGS84ToCartesian(position, 0.1);
                //     cartesian = Cesium.Cartesian3.ZERO;
                // }
                return cartesian;
            }
            return false;
        }
    }

    transformCartesianToWGS84(cartesian) {
        if (this._viewer && cartesian) {
            let ellipsoid = Cesium.Ellipsoid.WGS84;
            let cartographic = ellipsoid.cartesianToCartographic(cartesian);
            return {
                lng: Cesium.Math.toDegrees(cartographic.longitude),
                lat: Cesium.Math.toDegrees(cartographic.latitude),
                alt: cartographic.height
            };
        }
    }

    transformWGS84ToCartesian(position, alt) {
        if (this._viewer) {
            return position
                ? Cesium.Cartesian3.fromDegrees(
                    position.lng || position.lon,
                    position.lat,
                    (position.alt = alt || position.alt),
                    Cesium.Ellipsoid.WGS84
                )
                : Cesium.Cartesian3.ZERO;
        }
    }
    setCalculationMethod(method){
        this._method = method;
        
        if (this._method === 'triangulated') {

            this._tempActiveShapePoints = this._activeShapePoints;
            //create the points
            //this._tempActiveShapePoints.map(c=> this._createPoint(c));
            this._tempActiveShapePoints = this._activeShapePoints.forEach((c,i) => {
                this._activeShapePoints[i] = this._originalShapePoints[i];
            });
            
        } else {
            const commonHeight =
            this._method === 'custom'
                ? this.customHeight
                : this.getHeightBaseSurface(this._originalShapePoints);

            this._tempActiveShapePoints = this._activeShapePoints.forEach((c,i) => {
                let point = this._originalShapePoints[i];
                let carto = Cesium.Cartographic.fromCartesian(point);
                carto.height = commonHeight;
                let cartesian = Cesium.Cartographic.toCartesian(carto);
                this._activeShapePoints[i] = cartesian;
            });
        }       
        
        //Remove Old Points
        let indexesToRemove = [];
        if (this.ids.length > 0){
            this.ids.forEach((id, index)=>{
                let entity = this._drawLayer.entities.getById(id);
               
                if (entity && entity._point instanceof Cesium.PointGraphics){
                    this._viewer.entities.remove(entity);
                    this._drawLayer.entities.remove(entity);
                    indexesToRemove.push(index);
                }
            });
        }

        indexesToRemove.forEach(i=> this.ids.splice(i,1));
        

        //Remove floating point
        if (this._floatingPoint instanceof Cesium.Entity){
            this._viewer.entities.remove(this._floatingPoint);
            this._drawLayer.entities.remove(this._floatingPoint);      
        }
        //Create New Points
        this._activeShapePoints.forEach((c)=>{
            this._createPoint(c);
        });

    }
}
