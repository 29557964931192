import ProjectResources from '@/models/ProjectResources'

const state = () => ({
    list: null,
})

// getters
const getters = {
    listDocuments(state) {
        return state.list
    },
    getDocument: (state) => (document_id) => {
        return state.list.find(i => i.project_document_id === document_id);
    },

}

// actions
const actions = {

    async loadDocuments(context, payload) {
        let query = await ProjectResources.documents(payload);
        let data = query.data.data
        return new Promise((resolve) => {
            context.commit('setDocumentList', data);
        })
    },

    addDocument(context, payload) {
        context.commit( 'addDocument', payload.document );
   },

   clearDocumentsList(context){
        context.commit( 'clear' );
   }

}

// mutations
const mutations = {

    setDocumentList(state, data) {
        state.list = data
    },

    addDocument(state, data) {
        state.list.push( data );
    },

    clear(state){
        state.list = null;
    }

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}