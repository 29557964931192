
// Uses axios and Promises
// Everything that we need to communicate with the api will be declared here
import api from '@/providers/api'

const permissions = {

    get() {
        return api.get(`permissions/get`)
    },
    
    getProjectPermission(id){
        return api.get(`permissions/project/${id}`);
    }

}

export default permissions