import * as Cesium from '@/sdk/cesium';
import Project from '@/models/Project';
import Model3D from '@/third-party/3dModels';

export function zoomIn() {
    let viewer = this.viewer;
    let camera = this.viewer.camera;
    if (this.drawingMode !== 'manual_class_cross_section') {
        let ellipsoid = viewer.scene.globe.ellipsoid;
        let cameraHeight = ellipsoid.cartesianToCartographic(
            camera.position
        ).height;
        let moveRate = cameraHeight / 5.0;
        camera.moveForward(moveRate);
    } else { // When manual_class_cross_section is active
        camera.moveForward(1);
    }
    this.$helper.createSegmentEvent(
        this.getUser.user_id,
        "Viewer: Execute Zoom In from right bar",
        {
            'userId': this.getUser.user_id,
            'projectId': this.$route.params.project_id,
            'event': 'The user executed zoom in the viewer right bar.',
            'description': `The user executed zoom in the viewer right bar`,
            'tag': 'client',
        }
    )
}

export function zoomOut() {
    let viewer = this.viewer;
    let camera = this.viewer.camera;
    if (this.drawingMode !== 'manual_class_cross_section') {
        let ellipsoid = viewer.scene.globe.ellipsoid;
        let cameraHeight = ellipsoid.cartesianToCartographic(
            camera.position
        ).height;
        let moveRate = cameraHeight / 5.0;
        camera.moveBackward(moveRate);
    }
    else { // When manual_class_cross_section is active
        camera.moveBackward(1);
    }
    this.$helper.createSegmentEvent(
        this.getUser.user_id,
        "Viewer: Execute Zoom Out from right bar",
        {
            'userId': this.getUser.user_id,
            'projectId': this.$route.params.project_id,
            'event': 'The user executed zoom out the viewer right bar.',
            'description': `The user executed zoom out the viewer right bar`,
            'tag': 'client',
        }
    )
}

export function saveCameraParams(cesiumRef, camera = null) {
    this.$helper.createSegmentEvent(
        this.getUser.user_id,
        "Viewer: Execute Snap Camera Position from right bar",
        {
            'userId': this.getUser.user_id,
            'projectId': this.$route.params.project_id,
            'event': 'Viewer: Execute Snap Camera Position from right barThe user executed zoom in the viewer right bar.',
            'description': `The user executed snap camera position from the viewer right bar`,
            'tag': 'client',
        }
    )
    return new Promise(resolve => {
        if (camera == null) {
            camera = cesiumRef.viewer.scene.camera
        }
        let params = {
            selectedTileset: cesiumRef.selectedTileset,
            cameraPosition: {
                position: camera.position,
                direction: camera.direction,
                up: camera.up,
            }
        }
        let payload = {
            project_id: cesiumRef.$route.params.project_id,
            params: JSON.stringify(params)
        }
        Project.updateAddOnParams(payload).then((r) => {
            this.$toast.show(
                `<div class="font-body flex items-center justify-between text-white">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 -ml-5 mr-3" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                    clip-rule="evenodd" />
                                </svg>
                                <span>${this.$t('viewer.properties.saved')}</span>
                                <div>`,
                {
                    type: 'success',
                    position: 'top-right',
                    duration: 3000
                }
            );
            resolve(r)
        }).catch((error) => {
            console.log(
                'saving camera error',
                error.response
            );
            resolve(error)
        });
    });

}

export function setCameraView(cesiumRef, proj) {
    if (proj == null) {
        return
    }
    if (proj.addon_params != null) {
        const addon_params = JSON.parse(proj.addon_params)
        if (addon_params.cameraPosition == undefined) {
            return
        }
        //this.focusSelectedTile(addon_params.selectedTileset)
        const viewer_params = addon_params.cameraPosition
        setTimeout(() => {
            cesiumRef.viewer.camera.setView({
                destination: viewer_params.position,
                orientation: {
                    direction: viewer_params.direction,
                    up: viewer_params.up
                }
            });
        }, 500);
    }
}

export function focusCameraToSelectedTile(index, options, component) {
    if (index >= 0) {
        if (!component.measure.manualClassificationCrossSection.enable) {
            if (typeof options.topView !== 'undefined' || component.settings.enable2D) {
                if (options.topView || component.settings.enable2D) {
                    try {
                        /* let camera = this.viewer.scene.camera
                        const center = new Cesium.Cartesian2(this.viewer.container.clientWidth / 2, this.viewer.container.clientHeight / 2);
                        let pt = this.measure.getCartesian3FromPX(center, true)
                        if (!pt) { return }
                        let cam_position = Cesium.Cartographic.fromCartesian(pt.point);
                        if (!pt.isOn3dtiles) {
                            this.tilesetData[this.selectedTileset].readyPromise.then((tileset) => {
                                let topView = tileset.boundingSphere.center;
                                cam_position = Cesium.Cartographic.fromCartesian(topView);
                            });
                        }
                        let _pos = {
                            x: Cesium.Math.toDegrees(cam_position.longitude),
                            y: Cesium.Math.toDegrees(cam_position.latitude),
                            z: cam_position.height
                        };
                        this.viewer.camera.setView({
                            destination: Cesium.Cartesian3.fromDegrees(_pos.x, _pos.y, camera.positionCartographic.height)
                        }); */

                        // Get the bounding sphere information from the tileset data at the specified index
                        let boundingSphere = component.tilesetData[index].boundingSphere;

                        // Clone the center of the bounding sphere
                        let center = boundingSphere.center.clone();

                        // Convert the Cartesian coordinates of the center to Cartographic coordinates
                        let carthographicCenter = Cesium.Cartographic.fromCartesian(center);

                        // Calculate the diameter of the bounding sphere
                        let diameter = boundingSphere.radius * 2;

                        // Calculate a new height for the camera view, adding the diameter and an additional 20% for a buffer
                        let newHeight = carthographicCenter.height + diameter + diameter * 0.2;

                        // Create a Cartesian3 position for the top view based on the calculated longitude, latitude, and new height
                        let topView = Cesium.Cartesian3.fromRadians(carthographicCenter.longitude, carthographicCenter.latitude, newHeight);

                        // this.viewer.camera.switchToPerspectiveFrustum();

                        // Set the camera view to focus on the top view position
                        component.viewer.camera.setView({
                            destination: topView
                        });

                        // this.resetThreeCamera(false);
                    } catch (error) {
                        console.log(error)
                    }
                }
            } else {
                if (component.tilesetData[index] instanceof Cesium.Cesium3DTileset) {
                    component.viewer.zoomTo(component.tilesetData[index]).then(() => {
                        component.resetThreeCamera(false);
                    });
                } else {
                    if (component.tilesetData) {
                        component.viewer.zoomTo(component.tilesetData[0]).then(() => {
                            component.resetThreeCamera(false);
                        });
                    }
                }
                component.zoomRate = 0.0;
            }
        } else {
            component.viewer.camera.setView(component.measure.manualClassificationCrossSection.cameraView);
        }
    }
    component.$helper.createSegmentEvent(
        component.getUser.user_id,
        "Viewer: Execute focus from right bar",
        {
            'userId': component.getUser.user_id,
            'projectId': component.$route.params.project_id,
            'event': 'The user executed zoom out the viewer right bar.',
            'description': `The user executed zoom out the viewer right bar`,
            'tag': 'client',
        }
    )
}

export function setCameraToSideView(component) {
    component.tilesetData[component.selectedTileset].readyPromise.then((tileset) => {
        // console.log(tileset.boundingSphere.center);
        // console.log('properties', tileset.properties);
        // console.log(cartographic.height)
        let topView = component.tilesetData[component.selectedTileset].boundingSphere.center;
        let cartographic = Cesium.Cartographic.fromCartesian(topView);
        let _pos = {
            x: Cesium.Math.toDegrees(cartographic.longitude),
            y: Cesium.Math.toDegrees(cartographic.latitude),
            z: cartographic.height
        };
        /* this.viewer.camera.look({
            destination : Cesium.Cartesian3.fromDegrees(_pos.x, _pos.y, 0.0)
        }); */
        console.log("set sideview")
        component.viewer.camera.setView({
            destination: new Cesium.Cartesian3.fromDegrees(_pos.x, _pos.y, cartographic.height),
            orientation: {
                heading: 60,
                pitch: 0,
                roll: 0
            }
        });
    });
}

export function focusCameraToSelectedModel(payload, component) {
    const viewer = component.$refs.cesiumRef.viewer;
    let model = payload.model;
    let entity = payload.entity;
    if (!entity) {
        entity = Model3D.getEntity(
            model.model_id,
            model.type
        );
    }
    if (payload.hideOthers) {
        //Hide Pointclouds
        let pointclouds = component.$refs.cesiumRef.tilesetData;
        pointclouds.forEach((item, index) => {
            component.$refs.cesiumRef.setVisibility({
                type: 'pointcloud',
                show: false,
                index: index
            });
        });
        //Hide Models
        let models = component.$refs.cesiumRef.models;
        if (models instanceof Array) {
            models.forEach((m) => {
                let entity = Model3D.getEntity(
                    m.model_id,
                    m.type);
                if (entity.show != undefined) {
                    entity.show = model.model_id === m.model_id;
                }
            });
        }
    }
    if (entity) {
        if (model.type === 'gltf') {
            const _pos = Cesium.Cartographic.fromCartesian(entity.centerPosition);
            const radius = entity.boundingSphere.radius;
            viewer.camera.setView({
                destination: Cesium.Cartesian3.fromRadians(_pos.longitude, _pos.latitude, radius * 6)
            });
        } else {
            viewer.flyTo(entity);
        }
        // update trigger for vector layers
        this.$refs.cesiumRef.setFocusedFile(entity, 'model')
    }
}

export function focusCameraToSelectedPointcloud(payload, component) {
    let pointclouds = component.$refs.cesiumRef.tilesetData;
    let models = component.$refs.cesiumRef.models;
    let selected_id = payload.id;
    //Hide Models
    if (payload.hideModels) {
        if (models instanceof Array) {
            models.forEach((m) => {
                let entity = Model3D.getEntity(
                    m.model_id,
                    m.type);
                if (entity.show != undefined) {
                    entity.show = false;
                }
            });
        }
    }
    if (pointclouds.length > 0) {
        pointclouds.forEach((item, index) => {
            let focus = item.file_id === selected_id;
            component.$refs.cesiumRef.setVisibility({
                type: 'pointcloud',
                show: focus,
                index: index
            });
            if (focus) {
                component.$refs.cesiumRef.focusSelectedTile(index, payload);
                if (!payload.panelsOff) {
                    component.$refs.fileManagerRef.onSelectPointcloud(index);
                }

            }
        });
    }
}