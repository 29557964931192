
import Model from '@/models/Plans'

const state = () => ({
    list: [],
    selected: null,
    pricelist: []
})
  
// getters
const getters = {
    planList(state) {
        return state.list
    },
    selectedPlan (state) {
        return state.selected
    },
    priceList(state) {
        return state.pricelist
    },
}

// actions
const actions = {

    async fetchPlans(context) {
        const query = await Model.list()
        let list = query.data
        return new Promise((resolve) => {
            context.commit( 'load', list)
            resolve(list)
        })
    },

    async getPlan(context, plan_id) {
        const query = await Model.get(plan_id)
        let row = query.data
        return new Promise((resolve) => {
            context.commit( 'select', row)
            resolve(row)
        })
    },

    async getPricingList(context) {
        const query = await Model.pricingList()
        let list = query.data
        return new Promise((resolve) => {
            context.commit( 'loadPriceList', list)
            resolve(list)
        })
    },
}

// mutations
const mutations = {
    load(state, list) {
        state.list = list
    },
    select(state, row) {
        state.selected = row
    },
    loadPriceList(state, list) {
        state.pricelist = list
    }
}
  
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}