// Uses axios and Promises
// Everything that we need to communicate with the api will be declared here
import api from '@/providers/api'

const projects = {

    get(project_id) {
        return api.get(`projects/get/${project_id}`)
    },

    list(per_page = 10) {
        return api.get(`projects/list?per_page=${per_page}`)
    },
    list_all() {
        return api.get('projects/list-all')
    },
    store(payload) {
        return api.post('projects/create', payload);
    },

    update(project) {
        return api.put('projects/update', project);
    },

    reprocess(id, payload) {
        return api.post(`projects/reprocess/${id}`, payload);
    },

    delete(id) {
        return api.delete('projects/destroy/' + id);
    },

    request(url) {
        return api.request(url);
    },

    demos() {
        return api.get('projects/demo')
    },

    snapshot(id, formData) {
        return api.post(`projects/screenshot/${id}`, formData)
    },

    share(token) {
        return api.get(`projects/share/${token}`);
    },

    search(payload) {
        const params = new URLSearchParams(payload);
        const query = params.toString();
        return api.get('folders/search?' + query)
    },

    getUploadToken() {
        return api.post(`projects/upload/get/token`);
    },
    getProjectsAdminConsole(payload = null, pageNumber = 1) {
        const url = `admin/projects?page=${pageNumber}`
        return api.post(url, payload)
    },
    getProjectDetailsAdminConsole(id = null) {
        const url = `admin/projects/${id}`
        return api.get(url)
    },
    updateProjectInfoAdminConsole(payload = null) {
        return api.put('admin/projects', payload);
    },
    deleteProjectAdminConsole(id = null) {
        return api.delete('admin/projects/' + id);
    },
    transfer(project_id, user_id) {
        const url = `admin/projects/tansfer`
        let payload = {
            project_id: project_id,
            user_id: user_id
        }
        return api.post(url, payload)
    },
    updateShareSettings(project_id, status) {
        const url = `projects/update-share`
        let payload = {
            project_id: project_id,
            status: status
        }
        return api.put(url, payload)
    },
    clearStatus(project_id) {
        return api.get(`projects/status/clear/${project_id}`);
    },
    checkPassword(project_id, passwd) {
        let payload = {
            password: passwd
        }
        return api.post(`projects/checkpassword/${project_id}`, payload);
    },
    setPassword(project_id, should_enbable = true) {
        let payload = {
            enable_password: should_enbable
        }
        return api.put(`projects/setpassword/${project_id}`, payload);
    },
    saveSettings(payload) {
        return api.post('projects/save/settings', payload)
    },
    updateAddOnParams(payload) {
        return api.post('projects/update/params', payload)
    }
}

export default projects