import { createStore } from 'vuex';

/* Modules */
import projects from './modules/projects';
import account from './modules/account';
import auth from './modules/auth';
import usage from './modules/usage';
import notifications from './modules/notifications';
import invites from './modules/invites';
import folders from './modules/folders';
import permissions from './modules/permissions'
import models from './modules/models';
import extras from './modules/extras';
import pointclouds from './modules/pointclouds';
import datatypes from './modules/datatypes';
import search from './modules/search';
import plans from './modules/plans';
import billing from './modules/billing';
import subscribe from './modules/subscribe';
import documents from './modules/documents';
import epsg from './modules/epsg';
import country from './modules/country';
import customer from './modules/customer';
import downloads from './modules/downloads';
import clip from './modules/clip'

const store = createStore({
    modules: {
        'projects': projects,
        'account': account,
        'auth': auth,
        'usage': usage,
        'notifications': notifications,
        'invites': invites,
        'folders': folders,
        'permissions': permissions,
        'models': models,
        'extras': extras,
        'pointclouds': pointclouds,
        'documents': documents,
        'datatypes': datatypes,
        'search': search,
        'plans': plans,
        'billing': billing,
        'subscribe': subscribe,
        'epsg': epsg,
        'country': country,
        'customer': customer,
        'downloads': downloads,
        'clip': clip
    },
    state: () => ({
        count: 0
    }),
    mutations: {},
    actions: {}
})

export default store