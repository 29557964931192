
import api from '@/providers/api'
// Uses axios and Promises
// Everything that we need to communicate with the api will be declared here

const customer = {

    list(perPage = 20, key = '', value = '', page = 1, order = 'desc', orderBy = 'created_at', renewal = 0, loginPeriod = 90, onlyActive = 0) {
        let url = `admin/customers?per_page=${perPage}&key=${key}&value=${value}&page=${page}&order=${order}&orderBy=${orderBy}&login_period=${loginPeriod}&only_active=${onlyActive}`
        if (renewal == 1) {
            url = `admin/customers?per_page=${perPage}&key=${key}&value=${value}&page=${page}&order=${order}&orderBy=${orderBy}&renewal=1`
        }
        return api.get(url)
    },

    renewalList(perPage = 20, key = '', value = '', page = 1, order = 'desc', orderBy = 'created_at', renewal = 0) {
        let url = `admin/customers/renewal/list?per_page=${perPage}&key=${key}&value=${value}&page=${page}&order=${order}&orderBy=${orderBy}`
        if (renewal == 1) {
            url = `admin/customers/renewal/list?per_page=${perPage}&key=${key}&value=${value}&page=${page}&order=${order}&orderBy=${orderBy}&renewal=1`
        }
        return api.get(url)
    },

    listByOrg(perPage = 20, key = '', value = '', page = 1, order = 'desc', orderBy = 'created_at', renewal = 0) {
        let url = `admin/customers/list/org?per_page=${perPage}&key=${key}&value=${value}&page=${page}&order=${order}&orderBy=${orderBy}`
        if (renewal == 1) {
            url = `admin/customers/list/org?per_page=${perPage}&key=${key}&value=${value}&page=${page}&order=${order}&orderBy=${orderBy}&renewal=1`
        }
        return api.get(url)
    },

    getNewPage(url) {
        return api.get(url)
    },

    // getInvites(id, perPage = 2,page = 1){

    //     const url = `admin/customers/invites/${id}?per_page=${perPage}&page=${page}`
    //     return api.get(url)
    // },

    getProfileDetails(id) {
        const url = `admin/customers/get/${id}`
        return api.get(url)
    },
    update(id, payload) {
        const url = `admin/customers/update/${id}`
        return api.post(url, payload)
    },

    delete(id) {
        const url = `admin/customers/delete/${id}`;
        return api.delete(url)
    },
    getConnectedUsersForCustomerDetails(id, perPage = 2, page = 1) {

        const url = `admin/customers/invites/${id}?per_page=${perPage}&page=${page}`
        return api.get(url)
    }

}

export default customer
