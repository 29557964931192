import api from '@/providers/api'

const auth = {

    login(username, password, ip_address=null) {
        return api.post('auth/login', {
            email: username,
            password: password,
            ip_address: ip_address
        });
    },
    checkAutoLoginToken(token) {
        return api.post('auth/check/token', { token: token });
    },    
    loginViaToken(token) {
        return api.post('auth/login/token', { token: token });
    },
    // Checks the authorization token
    check(token) {
        api.setHeaders({ 'Authorization': `Bearer ${token}` })
        return api.get('account/me');
    },

    getLoginInfo() {
        return api.get('auth/get_login_info');
    },

    getLoginHistory() {
        return api.get('auth/login/history');
    },

    resetPassword(payload) {
        return api.post('auth/reset/password', payload);
    },

    forgotPassword(payload) {
        return api.post(`auth/forgot/password`,payload);
    },
    setNewPassword(payload){
        return api.post(`auth/set-new-password`, payload);
        //return api.get(`auth/set-new-password?token=` + payload.token + '&email=' + payload.email + '&password=' + payload.password +'&password_confirmation='+ payload.password_confirmation );
    },

    logout() {
        return api.post('auth/logout');
    }

}

export default auth