import api from '@/providers/api'

const announcement = {

    get(perPage = 20,page = 1, order= 'desc') {
        return api.get(`admin/announcements?per_page=${perPage}&page=${page}&order=${order}`)
    },

    getCategories() {
        return api.get(`admin/announcements/categories`)
    },
    store(payload) {
        return api.post('admin/announcements', payload);
    },
    update(payload) {
        return api.put('admin/announcements/'+ payload.id, payload);
    },
    show(id) {
        return api.get('admin/announcements/' + id);
    },
    delete(id) {
        return api.delete('admin/announcements/' + id);
    },
    getAnnouncement(){
        return api.get(`announcement`);
    }
    
}

export default announcement