import AuthModel from '@/models/Auth'
import ConfigProvider from '@/config'
import PermissionsModel from '@/models/Permissions'

import { mapGetters, mapActions } from 'vuex'

const auth = {
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters( 'auth', [
            'getToken'
        ])
    },
    methods: {
        ...mapActions( 'auth', [
            'setAuthToken',
            'setUser',
            'setLoginInfo'
        ]),
        ...mapActions( 'permissions', [
            'setUserPermissions'
        ]),

        isLoggedIn() {
            return this.getToken
        },

        logoutUser() {
            localStorage.removeItem('scx_token');
            this.$cookies.remove( 'scx_token' )

        },

        // Checks session cookie then authenticate-handshake user
        async checkAuthentication() {
            return new Promise((resolve, reject) => {
                if ( this.$cookies.isKey( ConfigProvider.cookie_name ) || localStorage.getItem(ConfigProvider.cookie_name ) ) {
                    let _token = this.$cookies.get( ConfigProvider.cookie_name )

                    if(!_token){
                        _token = localStorage.getItem(ConfigProvider.cookie_name );
                    }

                    // Save token to store
                    this.setAuthToken( _token );
                    AuthModel.check( this.getToken ).then(async (response) => {
                            this.setUser( response.data );

                            let permissions = await this.getPermissions();
                            //if ( permissions.data.accessPermissions && permissions.data.featurePermissions ) {
                                this.setUserPermissions(permissions.data)
                            //}

                            let login_data = await this.setLoginInfo();

                            resolve({
                                info: response.data,
                                auth: login_data,
                                token: _token
                            });

                        }).catch((error) => {
                            console.error( 'error auth check', error )
                            this.logoutUser()
                            reject(error)
                        });
                }
            })
        },

        getPermissions() {
            return PermissionsModel.get();
        }

    }
}

export default auth
