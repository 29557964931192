import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex w-full h-screen font-body bg-black" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_NavbarViewer = _resolveComponent("NavbarViewer")
  const _component_Dropdown = _resolveComponent("Dropdown")
  const _component_Notification = _resolveComponent("Notification")
  const _component_ViewerToolbar = _resolveComponent("ViewerToolbar")
  const _component_Cesium = _resolveComponent("Cesium")
  const _component_SidebarViewer = _resolveComponent("SidebarViewer")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_NavbarViewer, {
      project: $props.project,
      onOnclickDropdown: $options.handleDropdown,
      onOnclickNotification: $options.handleNotification,
      nameOnly: _ctx.nameOnly,
      nameFirstLetter: _ctx.nameFirstLetter
    }, null, 8, ["project", "onOnclickDropdown", "onOnclickNotification", "nameOnly", "nameFirstLetter"]),
    (_ctx.toggleAccount)
      ? (_openBlock(), _createBlock(_component_Dropdown, {
          key: 0,
          user: _ctx.getUser
        }, null, 8, ["user"]))
      : _createCommentVNode("", true),
    (_ctx.toggleNotification)
      ? (_openBlock(), _createBlock(_component_Notification, {
          key: 1,
          notifications: $props.notifications
        }, null, 8, ["notifications"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ViewerToolbar),
      _createVNode(_component_Cesium, {
        attribute: _ctx.attribute,
        selectedClass: _ctx.selected_class
      }, null, 8, ["attribute", "selectedClass"]),
      _createVNode(_component_SidebarViewer, {
        classifications: _ctx.classifications,
        onOnClickClass: $options.handleClass,
        onOnChangeAttrib: $options.handleChangeAttrib,
        selectedClass: _ctx.selected_class
      }, null, 8, ["classifications", "onOnClickClass", "onOnChangeAttrib", "selectedClass"])
    ])
  ]))
}