/**
 * Pointclouds Package
 *
 * Integrates Pointclouds import features
 */

import config from '@/config';
import * as Cesium from '@/sdk/cesium';

export default {

    dataSourceName: 'POINTCLOUDS',
    viewer: null,
    project_id: false,
    store: null,
    dataSource: null,

    auth: null,
    authToken: null,
    user_id: null,

    plugins: {},

    broadcastProvider: null,

    // Initialize 3d model integraiton
    init(project_id, viewer, store, auth, plugins) {
        //console.log(auth)
        this.project_id = project_id;
        this.viewer = viewer;
        this.store = store;
        this.authToken = auth.authToken;
        this.user_id = auth.user_id;
        this.plugins = plugins;

        let dataSource = new Cesium.CustomDataSource(this.dataSourceName);
        this.dataSource = dataSource;
        viewer.dataSources.add(dataSource);

        this.notifications();
        //this.load();
    },

    notifications() {
        if (this.broadcastProvider) {
            this.broadcastProvider.pchannel(`App.User.${this.user_id}`)
                .listen('.tile.complete', (e) => {
                    this.load()
                    /*                 this.plugins.$toast.show(
                                        `<div class="font-body flex items-center justify-between text-white"><span>New layer added successfully.</span><div>`,
                                        { type: "success", position: "top-right", duration: 3000 }
                                    ); */
                })
                .notification((e) => {
                    switch (e.type) {
                        case "App\\Notifications\\Projects\\ProjectProcessCompletedNotification":
                            this.load()
                            break;
                        default:
                            break;
                    }
                })
        }
    },

    // Load data from store
    async load() {
        //let pointclouds = await this.store.dispatch('pointclouds/loadPointclouds', { 'id': this.project_id })
        let projects = await this.store.dispatch('projects/setSelectedProject', {id: this.project_id, store: this.store})

        //console.log(pointclouds)
        //this.render( pointclouds )
    },

    // Renders models into view
    render(pointclouds) {
        console.log(pointclouds)
        if (pointclouds.length > 0) {
            pointclouds.forEach(item => {
                const { publish_url } = item
                let url = config.get().tileSetUrl + `/${publish_url}`
                this.addTileset(url, item);
            })
        }
    },

    // Add array of models and render
    addModels(models) {
        this.store.dispatch('pointclouds/addPointclouds', { 'models': models });
        this.render(models);
    },

    addTileset(url, pointcloud) {
        let file_id = pointcloud.file_id,
            visible = (pointcloud.visible == 1) ? true : false
        var style = {
            show: visible
        }
        //console.log(key, proj.data.files[key])
        var tileset = this.viewer.scene.primitives.add(
            new Cesium.Cesium3DTileset({
                url: url,
            })
        );
        tileset.pointCloudShading.attenuation = this.propAttenuation;
        tileset.pointCloudShading.eyeDomeLighting = this.propEyedome;

        if (!visible)
            tileset.style = new Cesium.Cesium3DTileStyle(style);

        this.store.dispatch('pointclouds/setPointcloudEntity', {
            entity_id: tileset.id,
            file_id: file_id
        })
    },

    getEntity(viewer, file_id) {
        let entity = this.store.getters['pointclouds/getPointcloudEntity'](file_id);
        if (entity) {
            let ds = viewer.dataSources.getByName(this.dataSourceName);
            let entityObj = ds[0].entities.getById(entity.entity_id);
            return entityObj;
        }
        return false;
    }

}