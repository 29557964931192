/**
 * Manual Classification
 */

import * as Cesium from '@/sdk/cesium';

export class ManualClassification {
    constructor(viewer, options, $this, args) {
        // disable base map & 3d terrain in viewer
        this.viewer = viewer;
        this.options = options;
        this.refs = this.options.refs
        this.$this = $this;
        this.$this.clear();
        const randomStr = (Math.random() + 1).toString(36).substring(7)
        const randomStr2 = (Math.random() + 1).toString(36).substring(7)
        this._polygonEntity = new Cesium.Entity({
            id: "manual_class_" + randomStr,
        });
        this._polygonEntity2 = new Cesium.Entity({
            id: "extra_entity_" + randomStr2,
        });
        this.positions = [];
        this.polygon = new Cesium.PolygonHierarchy();
        this.polyObj = null;
        this.pointObjs = [];
        this.ids = [];
        this.color = null;
        let mouseHasMove = false; //Track if the mouse has move after left clicking or at the on set of the selection
        if (options.options.classColor != undefined) {
            this.color = options.options.classColor.color
        }
        this.$this.clear();

        this._handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
        // left
        this._handler.setInputAction((movement) => {
            this.$this.clearPrompt()
            // This is to limit the to pointcloud selection only
            // let pick = $this._viewer.scene.pick(movement.position);
            // if (!pick && !pick.primitive instanceof Cesium.Cesium3DTileset) {
            //     return
            // }
            this.refs.cesiumRef.$emit('on-toggle-draggable', false);
            let cartesian = this.$this.getMousePosition(movement.position);

            //Disable Selection of points if mouse has'not move after left clicking
            console.log("mouseHasMove: ", mouseHasMove)
            if (cartesian && cartesian.x && mouseHasMove) {

                if (this.positions.length === 0) {
                    this._addInfoPoint(cartesian.clone(), true, this.positions);
                } else {
                    //remove coordinates inserted by the move event to be relace with the final position from left click
                    this.positions.pop();
                    this.polygon.positions.pop();
                    this._addInfoPoint(cartesian.clone(), false);
                }
                //Add latest coordinates to the array
                this.positions.push(cartesian.clone());
                this.polygon.positions.push(cartesian.clone());

                //use by move event
                this.positions.push(cartesian.clone());
                this.polygon.positions.push(cartesian.clone());
                mouseHasMove = false; // toggle off the flag mouseHasMove
                if (!this.polyObj) this.create();
            }
        }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
        // mouse
        this._handler.setInputAction((movement) => {
            this.$this.mouseHoverHandler(movement.endPosition);
            mouseHasMove = true;
            let cartesian = this.$this.getMousePosition(movement.endPosition);
            if (this.positions.length >= 2) {
                if (cartesian && cartesian.x) {
                    this.positions.pop();
                    this.positions.push(cartesian.clone());
                    this.polygon.positions.pop();
                    this.polygon.positions.push(cartesian.clone());
                }
            }
        }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

        // right
        this._handler.setInputAction((movement) => {
            let valid = this.$this.validateMeasurementNumberOfPoints(this.positions, 3)
            if (!valid) return

            this.$this.clear();
            this.refs.cesiumRef.$emit('on-toggle-draggable', true);
            this.positions.pop();
            this.positions.push(this.positions[0].clone());
            this.polygon.positions.pop();

            this.$this.clearMeasurementSnapping();

            if (typeof options.callback === 'function') {
                this.reCreateLine();
                options.callback(({
                    positions: this.positions,
                    id: this.ids
                }), this.polyObj);
            }
        }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);

        if (options.p) {
            if (options.p.length > 0) {
                this.$this.clear();
            }
            this.positions = options.p;
            this.polygon.positions = options.p;
            this.create();
            this._addInfoPoint(this.positions[0]);
            options.callback(({
                positions: this.positions,
                id: this.ids
            }), this.polyObj);
        }
    }

    create() {
        let t = this;
        this._polygonEntity.polyline = {
            width: 2,
            //material: new Cesium.PolylineDashMaterialProperty({ color: Cesium.Color.WHITE.withAlpha(0.8) }),
            clampToGround: false,
            classificationType: Cesium.ClassificationType.BOTH,
            positions: new Cesium.CallbackProperty(function () {
                return t.positions;
            }, false),
            material: new Cesium.PolylineDashMaterialProperty({
                color: Cesium.Color.WHITE.withAlpha(0.8),
            }),
            depthFailMaterial: new Cesium.PolylineDashMaterialProperty({
                color: Cesium.Color.WHITE.withAlpha(0.8),
            }),
        };

        // extra polyline to show outside the point cloud
        // _polygonEntity2.polyline = {
        //     width: 2,
        //     material: new Cesium.PolylineDashMaterialProperty({ color: Cesium.Color.WHITE.withAlpha(0.8) }),
        //     clampToGround: false,
        //     classificationType: Cesium.ClassificationType.TERRAIN
        // };
        // _polygonEntity2.polyline.positions = new Cesium.CallbackProperty(function () {
        //     return positions;
        // }, false);

        this._polygonEntity.polygon = {
            hierarchy: new Cesium.CallbackProperty(function () {
                return t.polygon;
            }, false),
            material: (this.color) ? Cesium.Color.fromCssColorString(this.color) : Cesium.Color.YELLOW.withAlpha(0.4),
            classificationType: Cesium.ClassificationType.CESIUM_3D_TILE,
            outline: true,
            outlineColor: Cesium.Color.WHITE,
            outlineWidth: 5,
        };
        this._polygonEntity.areaPositions = this.positions
        this._polygonEntity.extra_line_id = this._polygonEntity2.id
        //let polyObj2 = $this._drawLayer.entities.add(_polygonEntity2);
        this.polyObj = this.$this._drawLayer.entities.add(this._polygonEntity);
        this.ids.push(this.polyObj.id);
        //ids.push(polyObj2.id);
    }

    reCreateLine() {
        this._polygonEntity.polyline = {
            width: 2,
            //material: new Cesium.PolylineDashMaterialProperty({ color: Cesium.Color.WHITE.withAlpha(0.8) }),
            clampToGround: false,
            classificationType: Cesium.ClassificationType.BOTH,
            positions: this.positions,
            material: new Cesium.PolylineDashMaterialProperty({
                color: Cesium.Color.WHITE.withAlpha(0.8),
            }),
            depthFailMaterial: new Cesium.PolylineDashMaterialProperty({
                color: Cesium.Color.WHITE.withAlpha(0.8),
            }),
        };
    }
    _addInfoPoint(position, showLabel = false, positions = undefined) {
        const randomStr = (Math.random() + 1).toString(36).substring(7)
        let _labelEntity = new Cesium.Entity({
            id: "mc_point_" + randomStr,
        });
        _labelEntity.position = position;
        _labelEntity.point = {
            pixelSize: 7,
            color: Cesium.Color.CRIMSON.withAlpha(0.8),
            outlineColor: Cesium.Color.WHITE,
            outlineWidth: 1,
            heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, //DEFAULT NONE
            disableDepthTestDistance: Number.POSITIVE_INFINITY
        };
        if (showLabel) {
            _labelEntity.label = {
                text: new Cesium.CallbackProperty(function () {
                    let area = Number(
                        this.$this.getPositionsArea(
                            this.$this.transformCartesianArrayToWGS84Array(positions)) / 1.0)
                        .toLocaleString() + ' ㎡';
                    return showLabel ? area : '';
                }, false),
                show: false,
                showBackground: true,
                backgroundColor: Cesium.Color.BLACK,
                font: '14px arial',
                // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
                horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
                verticalOrigin: Cesium.VerticalOrigin.TOP,
                pixelOffset: new Cesium.Cartesian2(-20, -50), //left top
                // scale: 1,
                borderRadius: '10px',
                disableDepthTestDistance: Number.POSITIVE_INFINITY
            };
        }
        _labelEntity.mc_parent = this._polygonEntity.id
        const e = this.$this._drawLayer.entities.add(_labelEntity);
        this.pointObjs.push(e);
        this.ids.push(e.id);
    }
}