import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52c2975d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mr-4" }
const _hoisted_2 = { class: "ml-4" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_LeftChevron = _resolveComponent("LeftChevron")
  const _component_RightChevron = _resolveComponent("RightChevron")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex", [{ 'dark': $props.dark }, ($props.getMetas.total > 0) ? '' : 'invisible']])
  }, [
    _createElementVNode("button", {
      "data-testid": "btnPrevious",
      onClick: _cache[0] || (_cache[0] = $event => (_ctx.$emit('onPrev')))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_LeftChevron, {
          color: !$props.dark ? '#1E293B' : '#F1F5F9'
        }, null, 8, ["color"])
      ])
    ]),
    ($props.hasLabel)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          "data-testid": "txtPagination",
          class: _normalizeClass(["text-neutral-700 text-px-15 mt-2 font-body font-semibold", $props.dark && 'text-white'])
        }, _toDisplayString($props.getMetas.from) + " - " + _toDisplayString($props.getMetas.to) + " / " + _toDisplayString($props.getMetas.total), 3))
      : _createCommentVNode("", true),
    _createElementVNode("button", {
      "data-testid": "btnNext",
      onClick: _cache[1] || (_cache[1] = $event => (_ctx.$emit('onNext')))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_RightChevron, {
          color: !$props.dark ? '#1E293B' : '#F1F5F9'
        }, null, 8, ["color"])
      ])
    ])
  ], 2))
}