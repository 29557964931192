// Uses axios and Promises
// Everything that we need to communicate with the api will be declared here
import api from '@/providers/api'

const process = {

    list(project_id) {
        return api.get(`process/list/${project_id}`);
    },

    transform(payload) {
        return api.post('process/transform', payload)
    },
    noise(payload) {
        return api.post('process/noise', payload)
    },
    group_composition(payload) {
        return api.post('process/merge', payload)
    },
    duplicate(payload) {
        return api.post('process/duplicate', payload)
    },
    thin(payload) {
        return api.post('process/thin', payload)
    },
    ground(payload) {
        return api.post('process/ground', payload)
    },
    ground_surface(payload) {
        return api.post('process/ground-surface', payload)
    },
    classify(payload) {
        return api.post('process/classify', payload)
    },
    tin(payload) {
        return api.post('process/tin', payload)
    },
    manual_class(payload) {
        return api.post('process/manual-classify', payload)
    },
    manual_class_cross_section(payload) {
        return api.post('process/cross-section/classify', payload)
    },
    reprocess(payload) {
        return api.post('process/reprocess', payload)
    },
    dva(payload) {
        return api.post('process/dab', payload)
    },
    cross_section(payload) {
        return api.post(`process/cross-section`, payload);
    },
    cancel(process_id) {
        return api.post(`process/cancel/${process_id}`);
    },

    listAdminConsole(project_id,per_page = 2, page = 1) {
        return api.get(`admin/processes/${project_id}?per_page=${per_page}&page=${page}`);
    },
    cancelAdminConsole(process_id) {
        return api.post(`admin/processes/cancel/${process_id}`);
    },
    tree_canopy(payload) {
        console.log(payload)
        return api.post(`process/tree-canopy`, payload);
    },
    clip_2d(payload) {
        return api.post('process/clip-2d', payload)
    },
    mesh_3d(payload) {
        return api.post('process/mesh-3d', payload)
    },
    grid_data(payload) {
        return api.post('process/grid-data', payload)
    },
    profileExportDxf(payload) {
        return api.post('process/height-profile-dxf', payload)
    },
    volume(payload) {
        return api.post('process/volume', payload)
    }
}

export default process