import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col text-left rounded-md py-3 px-5 gap-y-4 relative" }
const _hoisted_2 = { class: "flex w-full" }
const _hoisted_3 = { class: "flex w-full items-center" }
const _hoisted_4 = { class: "text-lg font-bold ml-2 w-full" }
const _hoisted_5 = { class: "w-full flex justify-end" }
const _hoisted_6 = { class: "flex flex-col" }
const _hoisted_7 = { class: "text-sm text-neutral-500 font-semibold" }
const _hoisted_8 = { class: "text-md" }
const _hoisted_9 = { class: "flex flex-col" }
const _hoisted_10 = { class: "text-sm text-neutral-500 font-semibold" }
const _hoisted_11 = { class: "text-md" }
const _hoisted_12 = { class: "flex flex-col" }
const _hoisted_13 = { class: "text-sm text-neutral-500 font-semibold" }
const _hoisted_14 = { class: "text-md" }
const _hoisted_15 = { class: "flex flex-col" }
const _hoisted_16 = { class: "text-sm text-neutral-500 font-semibold" }
const _hoisted_17 = { class: "text-md" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CommonSkeleton = _resolveComponent("CommonSkeleton")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_CommonSkeleton, { class: "w-10" }),
        _createElementVNode("span", _hoisted_4, [
          _createVNode(_component_CommonSkeleton)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_CommonSkeleton, { class: "w-8" })
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("label", _hoisted_7, [
        _createVNode(_component_CommonSkeleton, { class: "w-2/6" })
      ]),
      _createElementVNode("p", _hoisted_8, [
        _createVNode(_component_CommonSkeleton, { class: "w-1/6" })
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("label", _hoisted_10, [
        _createVNode(_component_CommonSkeleton, { class: "w-2/6" })
      ]),
      _createElementVNode("p", _hoisted_11, [
        _createVNode(_component_CommonSkeleton, { class: "w-3/6" })
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("label", _hoisted_13, [
        _createVNode(_component_CommonSkeleton, { class: "w-1/6" })
      ]),
      _createElementVNode("p", _hoisted_14, [
        _createVNode(_component_CommonSkeleton, { class: "w-1/5" })
      ])
    ]),
    _createElementVNode("div", _hoisted_15, [
      _createElementVNode("label", _hoisted_16, [
        _createVNode(_component_CommonSkeleton, { class: "w-1/6" })
      ]),
      _createElementVNode("p", _hoisted_17, [
        _createVNode(_component_CommonSkeleton, { class: "w-2/5" })
      ])
    ])
  ]))
}