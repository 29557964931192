
// Uses axios and Promises
// Everything that we need to communicate with the api will be declared here
import api from '@/providers/api'

const usage = {

    get() {
        return api.get('usage/get')
    },
    getUserUsage(user){
        return api.get(`usage/user/get/${user}`)
    }
    
}

export default usage