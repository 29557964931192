import { mapGetters, mapActions } from 'vuex'
import swal from 'sweetalert'

export default {

    data() {
        return {}
    },

    computed: {
        ...mapGetters('extras', [
            'getProcessItems',
            'getCurrentRunningProcess'
        ])
    },

    methods: {
        ...mapActions('downloads', ['loadDownloads']),
        ...mapActions('extras', [
            'fetchProcessProgress',
            'updateProcessProgress',
            'resetProcessProgress',
            'createProcessItem',
            'updateProcessItem'
        ]),

        initBroadcastEvents(broadcast, project_id) {
            broadcast.channel(`App.Project.${project_id}`)
                .listen('.project.downloads', e => {
                    this.$toast.show(
                        `<div class="font-body flex items-center justify-between text-white"><span>Download file now ready.</span><div>`, { type: "success", position: "top", duration: 3000 }
                    );
                    this.loadDownloads(project_id);
                })
                .listen('.project.progress', e => {
                    if (e.data) {
                        let process = e.data.process,
                            type = e.data.type;
                        switch (type) {
                            case 'created':
                                this.createProcessItem(process);
                                break;
                            case 'update':
                            case 'cancelled':
                            case 'completed':
                                this.updateProcessItem(process);
                                break;
                            case 'failed':
                                if (e.data.process.error_code) {
                                    let reason = e.data.process.error_code;
                                    switch (reason) {
                                        case 'error.dab.datasets.not.match':
                                            swal(this.$t('viewer.analysis.warning_message'), {
                                                icon: 'warning'
                                            });
                                            break;
                                        case 'unsupported.dxf.type':
                                            swal(this.$t('viewer.process.dxf_not_supported'), {
                                                icon: 'error'
                                            });
                                            break;
                                        case 'unsupported.xml.type':
                                            swal(this.$t('viewer.process.xml_not_supported'), {
                                                icon: 'error'
                                            });
                                            break;
                                        case 'tree.canopy.no.trees':
                                            console.log("no trees");
                                            swal(this.$t('viewer.process.tree_canopy_no_trees'), {
                                                icon: 'error'
                                            });
                                            break;
                                        default:
                                            swal(this.$t('viewer.process.failed'), {
                                                icon: 'error'
                                            });
                                            break;
                                    }
                                } else {
                                    swal(this.$t('viewer.process.failed'), {
                                        icon: 'error'
                                    });
                                }

                                this.updateProcessItem(process);
                                break;
                        }


                        this.checkRunningProcess();
                    }
                });
        },


        checkRunningProcess() {
            if (this.getCurrentRunningProcess) {
                let running = this.getCurrentRunningProcess;
                let step = 0,
                    _lastStatus = 0,
                    totalSteps = 0;
                if (running) {
                    totalSteps = running.items.length;
                    running.items.forEach((i, index) => {
                        _lastStatus = i.status;
                        if ((i.status >= 0 && i.status <= 1) && step === 0) {
                            step = (index + 1);
                        }
                    });
                    if (step === 0) {
                        // If the last status is 2 or 3, get the last step, else its the first step
                        // Status 2 and 3 is completed and failed
                        step = ((_lastStatus === 2 || _lastStatus === 3) ? totalSteps : 1);
                    }
                }

                this.updateProcessProgress({
                    visible: true,
                    steps: {
                        current: step,
                        max: totalSteps
                    },
                    title: running.type.name,
                    filename: running.filename,
                    message: running.type.description,
                    progress: running.progress,
                    process_id: running.process_id
                });
            } else {
                this.updateProcessProgress({
                    visible: false,
                    steps: {
                        current: 1,
                        max: 1
                    },
                    title: '',
                    filename: '',
                    message: '',
                    progress: 0
                });
            }
        },

        initProcessHandler(project_id, broadcast) {
            // Initialize the process tracker

            // Fetch the list of progress from database
            this.fetchProcessProgress(project_id).then((processes) => {
                this.checkRunningProcess();
            })

            // Listen for events from broadcast server
            this.initBroadcastEvents(broadcast, project_id);
        },

        cleanupProcessHandler() {
            this.resetProcessProgress();
        }
    },



}