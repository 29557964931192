/*
define([
    'Cesium/Widgets/SvgPathBindingHandler',
    'KnockoutES5',
    'Core/KnockoutMarkdownBinding',
    'Core/KnockoutHammerBinding'
], function (
    SvgPathBindingHandler,
    Knockout,
    KnockoutMarkdownBinding,
    KnockoutHammerBinding) {
    'use strict';
*/

import {
    SvgPathBindingHandler,
    knockout as Knockout
} from '@/sdk/cesium'

import KnockoutMarkdownBinding from './KnockoutMarkdownBinding';
import KnockoutHammerBinding from './KnockoutHammerBinding';

var registerKnockoutBindings = function () {
    SvgPathBindingHandler.register(Knockout);
    KnockoutMarkdownBinding.register(Knockout);
    KnockoutHammerBinding.register(Knockout);

    Knockout.bindingHandlers.embeddedComponent = {
        init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
            var component = Knockout.unwrap(valueAccessor());
            component.show(element);
            return { controlsDescendantBindings: true };
        },
        update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        }
    };
};

export default registerKnockoutBindings;
//});

