/**
 * Measurements
 *
 * Listens for Backend side related Calculations
 */




export default {
    project_id: false,
    broadcastProvider: null,
    viewer: null,
    plugins: null,
    // Initialize Measurements
    init(project_id, viewer, plugins={}) {

        this.project_id = project_id;
        this.viewer = viewer;
        this.plugins = plugins;

        this.notifications();
    },

    notifications() {
        if (this.broadcastProvider) {
            this.broadcastProvider.channel(`App.Project.${this.project_id}`)
                .listen('.measurement.calculation.completed', (e) => {
                    
                    const data = e.measurement;

                    if (data.measurement_id === 10){
                        this.viewer.$refs.fileManagerRef.createMeasurement(data);

                        let parseData = JSON.parse(data.data);
                        this.viewer.focusPointcloud({id: parseData.comparedData.file_id});

                        //set the data and params to give to Property component
                        setTimeout(()=>{
                            this.viewer.handleActivePropert({
                                property: 'volume-calculation',
                                data: { data: data }
                            });
                        },10);

                        this.plugins.$toast.show(
                            `<div class="font-body flex items-center justify-between text-white"><span>Volume Calculation Completed.</span><div>`, { type: "success", position: "top", duration: 3000 }
                        );
                    }

                })
        }
    },

    clearToastr() { 
        if (this.plugins.$toast !== undefined && this.plugins.$toast !== null){    
            setTimeout(this.plugins.$toast.clear, 0);   
        }     
    },

}