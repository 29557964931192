/**
 * Converts a local time to Japan Standard Time (JST) and formats it as "YYYY-MM-DD h:mm AM/PM".
 * @param {Date} date - The local input date to be converted and formatted.
 * @returns {string} The formatted date in JST (e.g., "2023-12-24 3:00 AM").
 */
export function formatJSTDate(date) {
  // Get the local time offset in minutes using the getTimezoneOffset method
  const localOffset = date.getTimezoneOffset();
  
  // Define the offset for Japan Standard Time (JST), which is 9 hours ahead of UTC
  const jstOffset = 9 * 60; // JST is UTC+9

  // Calculate the UTC time by adding the local offset and JST offset to the original date
  const utcDate = new Date(date.getTime() + (localOffset + jstOffset) * 60000);

  // Extract year, month, and day components from the UTC date
  const year = utcDate.getFullYear();
  const month = ('0' + (utcDate.getMonth() + 1)).slice(-2);
  const day = ('0' + utcDate.getDate()).slice(-2);

  // Extract hours and minutes components from the UTC date
  const hours = utcDate.getHours();

  // Determine whether it's AM or PM based on the hours
  const ampm = hours >= 12 ? 'PM' : 'AM';

  // Format the hours to 12-hour format and add leading zero to minutes
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = ('0' + utcDate.getMinutes()).slice(-2);

  // Create the formatted date string in "YYYY-MM-DD h:mm AM/PM" format
  const formattedDate = `${year}-${month}-${day} ${formattedHours}:${formattedMinutes} ${ampm}`;
  
  // Return the formatted date string
  return formattedDate;
}
