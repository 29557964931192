
import Model from '@/models/ProjectResources'

const state = () => ({
    list: []
})
  
// getters
const getters = {
    datatypesList(state) {
        return state.list
    },
    count(state) {
        return state.list.length
    }
}

// actions
const actions = {
    async loadDatatypes(context) {
        const list = await Model.datatypes()
        context.commit( 'load', list.data.data)
    },
}

// mutations
const mutations = {
    load(state, list) {
        state.list = list
    }
}
  
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}