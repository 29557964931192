import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4ed3cbec"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "min-w-full" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      id: "cesiumContainer",
      class: _normalizeClass(["flex flex-col w-screen h-screen fixed", { moveCursor: $data.moveCursor, grabbableCursor: $data.grabbableCursor, crosshairCursor: $data.crosshairCursor, snapCursor: $data.snapCursor }])
    }, null, 2)
  ]))
}