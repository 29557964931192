//This file is automatically rebuilt by the Cesium build process.
export default "/**\n\
 * DOC_TBA\n\
 *\n\
 * @name czm_ray\n\
 * @glslStruct\n\
 */\n\
struct czm_ray\n\
{\n\
    vec3 origin;\n\
    vec3 direction;\n\
};\n\
";
