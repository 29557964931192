
import { defineComponent } from "vue";
import { Doughnut } from "vue3-chart-v2";

export default defineComponent({
  name: "MonthlyUsage",
  extends: Doughnut,
  props: ["value", "max"],
  data() {
    return {
      chartData: {
        labels: ['Used', 'Remaining'],
        datasets: [
          {
            data: [this.value, ((this.max-this.value) > 0) ? this.max-this.value : 0 ],
            backgroundColor: [
              'rgb(241, 245, 249)',
              'rgb(100, 116, 139)',
            ],
            borderColor: [
              'rgba(54, 162, 235, 0)',
              'rgba(54, 162, 235, 0)',
            ],
            borderWidth: 1
          },
        ],
      },
      chartOptions: {
        responsive: true,
        legend: {
          display: false
        },
        cutoutPercentage: 85,
      },
    };
  },
  //   props: {
  //     chartData: {
  //       type: Object,
  //       required: true,
  //     },
  //     chartOptions: {
  //       type: Object,
  //       required: false,
  //     },
  //   },
  mounted() {
    //console.log(((this.max-this.value) > 0) ? this.max-this.value : 0)
    this.renderChart(this.chartData, this.chartOptions);
  },
});
