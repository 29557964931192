import * as Cesium from '@/sdk/cesium';
import * as turf from '@/third-party/OutTurf.js';
// import { wma, ma } from 'moving-averages';


export class CrossSection {
    // constructor(viewer, resolution, tileset, params, measure) {
    constructor(viewer, worker) {
        this._viewer = viewer;
        this.distance = 0;
        this.sampleHeights = [];
        this.distanceList = [];
        this.shapes = [];
        this.worker = worker;
    }

    async sampleHeight(points, recalc, pitch, pointEntities = [], sampleHeightRes = [], options = {}, distanceRes = []) {
        
        // let startTime = performance.now();
        let viewer = this._viewer;
        this.distance = this.getTotalDistance(points);
        let partialDistance = 0;
        let updatedPoints = [];
        let $this = this;

        // added variable to update intersection width if distance is less than 10 meters
        let intersectionWidth = 0.5;
        const radius = options.tileset && options.tileset.boundingSphere ? options.tileset.boundingSphere.radius : 0;
        // added line to avoid recalculation during reload
        if (sampleHeightRes.length !== 0 && !recalc && distanceRes.length !== 0) {
            $this.sampleHeights = sampleHeightRes;
            $this.distanceList = distanceRes;
            let updatedPoints = $this.getUpdatedPoints(points, sampleHeightRes);
            $this.updatePointPosition(updatedPoints, pointEntities);
            console.log("re-draw cross-section")
            return this.sampleHeights;
        } else {
            for(let i = 0; i<points.length; i++) {
                let nextIndex = i + 1;
                if(nextIndex != points.length) {
                    let startPoint = points[i];
                    let endPoint = points[nextIndex];

                    // convert the start & end points to cartographic
                    // let startPointCarto = Cesium.Cartographic.fromCartesian(startPoint);
                    // let endPointCarto = Cesium.Cartographic.fromCartesian(endPoint);

                    if (!viewer.scene.sampleHeightSupported) return null;

                    if (startPoint instanceof Cesium.Cartesian3 && endPoint instanceof Cesium.Cartesian3) {
                        let info = {
                            type: 'second',
                            startPoint: startPoint,
                            endPoint: endPoint,
                            partialDistance: partialDistance,
                            recalc: recalc,
                            pitch: pitch,
                            radius: radius,
                            intersectionWidth: intersectionWidth,
                        }
                        let cartoList = null;
                        await this.worker.postMessage(info).then(result => {
                            partialDistance = result.intDistance;
                            this.distanceList.push(...result.distanceList); //= [...this.distanceList, ...distanceList]
                            cartoList = result.cartoList;
                        })
                        // increase the value for 0.3 to avoid or use a library for getting the average
                        const sampleHeights = await viewer.scene.sampleHeightMostDetailed(cartoList, null, intersectionWidth);
                        info = {
                            type: 'third',
                            sampleHeights: sampleHeights
                        }
                        await this.worker.postMessage(info).then(sampleHeightsInterpolated => {
                            $this.sampleHeights.push(...sampleHeightsInterpolated);
                        })
                    }
                }
                let cartoPoint = Cesium.Cartographic.fromCartesian(points[i]);
                let tempList = [...this.sampleHeights];
                let updatedHeight = tempList.find(d => 
                    (d.latitude === cartoPoint.latitude && d.longitude === cartoPoint.longitude && d.height));
                if (updatedHeight) {
                    updatedPoints.push(Cesium.Cartographic.toCartesian(updatedHeight));
                } else {
                    updatedPoints.push(points[i]);
                }
            }
            // call for updating the point entities' position
            this.updatePointPosition(updatedPoints, pointEntities);

            console.log("cross section")
            
            // let endTime = performance.now()
            // console.log(`Call to doSomething took ${endTime - startTime} milliseconds`)

            return this.sampleHeights;
        }
    }

    // can be deleted later; transferred previous implementation for distance calculation to a fx
    calculateDistanceList(points, pitch) {
        let partialDistance = 0;
        const pointMapper = (value, index, arr) => {
            if (index < arr.length - 1) {
                let distance = Math.trunc(Cesium.Cartesian3.distance(value, arr[index+1]));
                let intDistance = partialDistance + Math.trunc(distance);
                let distanceList = [];
                if (pitch === 0) {
                    if (distance <= 10) {
                        for (let t = partialDistance; t <= intDistance; t = t + 0.5) {
                            distanceList.push(t);
                        }
                    } else if (distance <= 200) {
                        for (let t = partialDistance; t <= intDistance; t++) {
                            distanceList.push(t);
                        }
                    } else {
                        for (let t = partialDistance; t <= intDistance; t = t + 2) {
                            distanceList.push(t);
                        }
                    }
                } else {
                    for (let t = partialDistance; t <= intDistance; t = t + pitch) {
                        if (Number.isInteger(t)) {
                            distanceList.push(t);
                        }
                    }
                }
                partialDistance += intDistance;
                this.distanceList.push(...distanceList);
            }
        }
        if (typeof pointMapper === 'function') {
            points.map(pointMapper);
        }
    }
    /***
     * updates the position of the point entities
     * @param positions
     * @param pointEntities
     */
    updatePointPosition(positions, pointEntities) {
       pointEntities.forEach((point, index) => {
          point.position = positions[index];
       })
    }
    /***
     * update the point position values
     */
    getUpdatedPoints(points, sampleHeightRes) {
        let updatedPoints = [];
        points.forEach((p) => {
            let cartoPoint = Cesium.Cartographic.fromCartesian(p);
            let height = sampleHeightRes.find(d => (d.latitude === cartoPoint.latitude && d.longitude === cartoPoint.longitude))
            if (height) {
                updatedPoints.push(Cesium.Cartographic.toCartesian(height));
            } else {
                updatedPoints.push(p);
            }
        });
        return updatedPoints;
    }
    /***
     * get the data and label for Line chart
     * @returns {{Distances: *[], Heights: *[]}}
     */
    getHeightsAndDistance() {
        let height_list = this.sampleHeights.map(carto => Number(Number(carto.height).toFixed(10)));
        return {
            'Heights': height_list,
            'Distances': this.distanceList
        };
    }
    /***
     * return converted positions
     * @param positions
     */
    getPositionsAndHeights(positions) {
        let positionsCartesian = [];
        if (positions[0] instanceof Cesium.Cartographic || Object.keys(positions[0]).includes('longitude')) {
            positionsCartesian = positions.map(e => {
                // const height = e.height;
                let cartesian = Cesium.Cartographic.toCartesian(e);
                // cartesian.z = height;
                return cartesian;
            });
            return positionsCartesian;
        } else console.error('No positions');
    }
    /***
     * get total distance between points
     * @param points
     * @returns {number}
     */
    getTotalDistance(points) {
        let tempDistance = [];
        for(let i=0;i<points.length;i++) {
            let nextIndex = i + 1;
            let startPoint = points[i];
            let endPoint = points[nextIndex];
            if(nextIndex != points.length && startPoint instanceof Cesium.Cartesian3 && endPoint instanceof Cesium.Cartesian3) {
                let distance = new Cesium.EllipsoidGeodesic(
                    Cesium.Cartographic.fromCartesian(startPoint),
                    Cesium.Cartographic.fromCartesian(endPoint)
                ).surfaceDistance;
                distance = Math.sqrt(
                    Math.pow(distance, 2) +
                    Math.pow(Cesium.Cartographic.fromCartesian(endPoint).height - Cesium.Cartographic.fromCartesian(startPoint).height, 2)
                );
                tempDistance.push(distance);
            }
        }
        let sumDistance = tempDistance.reduce(function(a, b) { return a + b; }, 0);
        return sumDistance;
    }

    /***
     * Create line that is same as written in the profile
     * @param positions
     */
    addLine(positions) {

        let positionsCartesian = [];
        if (positions[0] instanceof Cesium.Cartographic || this.isCartographicFormat(positions[0])) {
            positionsCartesian = positions.map(e => {
                return Cesium.Cartographic.toCartesian(e);
            });
            let entity = new Cesium.Entity();
            entity.polyline = {
                show: true,
                positions: positionsCartesian,
                clampToGround: false,
                width: 4,
                material: new Cesium.PolylineGlowMaterialProperty({
                    color: Cesium.Color.YELLOW.withAlpha(0.95),
                    glowPower: 0.7
                }),
                // material: Cesium.Color.BLUE.withAlpha(0.8),
                depthFailMaterial: Cesium.Color.YELLOW.withAlpha(0.4)
            };
            this.shapes.push(entity);
            // return this._viewer.entities.add(entity);
            return entity;
        } else console.error('No positions');
    }

    /**
     * Check if the JSON object has longitude, latitude, and height properties
     * @param {*} json 
     * @returns 
     */
    isCartographicFormat(json) {
        return (
            typeof json === 'object' &&
            'longitude' in json &&
            'latitude' in json &&
            'height' in json
        );
    }
    /***
     * Create Wall entity in the viewer for cross section visualization
     * @param twoPositions
     * @param sampleH
     */
    addWall(twoPositions, sampleH) {

        let hList = [];
        sampleH.forEach(carto => {
            hList.push(carto.height);
        });
        let hMax = Math.max(...hList);
        let hMin = Math.min(...hList);
        let instanceWall = new Cesium.GeometryInstance({
            geometry: new Cesium.WallGeometry({
                positions: twoPositions,
                minimumHeights: [hMin, hMin],
                maximumHeights: [hMax, hMax],
                vertexFormat: Cesium.VertexFormat.DEFAULT
            }),
            id: 'wall'
        });
        // console.log(instanceWall)
        const material = Cesium.Material.fromType('Color', {
            color: new Cesium.Color.CHARTREUSE.withAlpha(0.3)
        });
        let appearanceWall = new Cesium.MaterialAppearance({
            material: material,
            renderState: {
                depthTest: {
                    enabled: false
                }
            }
        });
        return new Cesium.Primitive({
            geometryInstances: [instanceWall],
            appearance: appearanceWall
        });
    }

    /***
     * uses turf
     * @param p1
     * @param p2
     * @returns {number}
     * @private
     */
    _measureTwoPoints(p1, p2) {
        let p1_rad = Cesium.Cartographic.fromCartesian(p1);
        let p2_rad = Cesium.Cartographic.fromCartesian(p2);

        let startDegreesLon = turf.turf.radiansToDegrees(p1_rad.longitude);
        let startDegreesLat = turf.turf.radiansToDegrees(p1_rad.latitude);
        let from = turf.turf.point([startDegreesLon, startDegreesLat]);

        let endDegreesLon = turf.turf.radiansToDegrees(p2_rad.longitude);
        let endDegreesLat = turf.turf.radiansToDegrees(p2_rad.latitude);
        let to = turf.turf.point([endDegreesLon, endDegreesLat]);

        let options = { units: 'meters' };
        return turf.turf.distance(from, to, options);
    }
}