
import Model from '@/models/Billing'

const state = () => ({
    details: {},
    currentPaymentMethod: {},
    currentBillingDetail: {}
})

// getters
const getters = {
    billingDetails(state) {
        return state.details
    },
    curPaymentMethod(state) {
        return state.currentPaymentMethod
    },
    currentBillingDetail(state) {
        return state.currentBillingDetail
    }
}

// actions
const actions = {

    async fetchDetails(context) {
        const query = await Model.get()
        let details = query.data.billing
        return new Promise((resolve) => {
            context.commit('setDetails', details)
            resolve(details)
        })
    },

    async currentPaymentMethod(context) {
        const query = await Model.getCurrentPaymentMethod().catch((error) => {
            console.log(error)
        });
        let pMethod = (query) ? query.data : {}
        return new Promise((resolve) => {
            context.commit('setPaymentMethod', pMethod)
            resolve(pMethod)
        })
    },

    setCurrentBillingDetail(context, data) {
        return new Promise((resolve) => {
            context.commit('setCurrentBillingDetail', data)
            resolve(data)
        })
    }

}

// mutations
const mutations = {
    setDetails(state, details) {
        state.details = details
    },
    setPaymentMethod(state, pMethod) {
        state.currentPaymentMethod = pMethod
    },
    setCurrentBillingDetail(state, data) {
        state.currentBillingDetail = data
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}