
import swal from 'sweetalert';
import { analytics } from '@/segment';
import moment from 'moment';

const helper = {
    createSegmentEvent(userId, eventName, data) {
        analytics.identify(userId, {});
        analytics.track(eventName, data, {});
    },
    identifyUserGuiding(user, status) {
        let url = window.location.href;
        let plan = status.plan
        if (url.indexOf('localhost') < 0) {
            try {
                window.userGuiding.identify(user.user_id, {
                    email: user.email,
                    'Plan': plan.name,
                    'Role': user.guest_account == 0 ? 'Admin' : (status.guest_details !== null) ? status.guest_details.role : 'Admin'
                });
            } catch (error) {
                console.log('lirabaries/helper ERROR > ', error)
            }
        }
    },
    /** Added for warning messages in 3D Volume */
    warningMessage(title = '', desc1 = '', desc2 = '', danger = false) {
        var notContent = `
            <div class="flex mt-8">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M28.7992 16.0002C28.7992 23.0694 23.0685 28.8002 15.9992 28.8002C8.92997 28.8002 3.19922 23.0694 3.19922 16.0002C3.19922 8.93095 8.92997 3.2002 15.9992 3.2002C23.0685 3.2002 28.7992 8.93095 28.7992 16.0002ZM17.5992 22.4002C17.5992 23.2838 16.8829 24.0002 15.9992 24.0002C15.1156 24.0002 14.3992 23.2838 14.3992 22.4002C14.3992 21.5165 15.1156 20.8002 15.9992 20.8002C16.8829 20.8002 17.5992 21.5165 17.5992 22.4002ZM15.9992 8.0002C15.1156 8.0002 14.3992 8.71654 14.3992 9.60019V16.0002C14.3992 16.8838 15.1156 17.6002 15.9992 17.6002C16.8829 17.6002 17.5992 16.8838 17.5992 16.0002V9.60019C17.5992 8.71654 16.8829 8.0002 15.9992 8.0002Z" fill="#FCD34D"/>
                </svg>
                <p class="text-left text-xl ml-2 mt-0.5 font-semibold font-openSans">
                ${title}
                </p>
                <p class="text-right text-xl ml-auto mt-0.5 font-semibold font-openSans" onclick="swal.close()">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 13L13 1M1 1L13 13" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </p>
            </div>
            <div>
                    <p class="w-96 text-left ml-8 mt-4 text-base">
                    ${desc1}
                    </p>
                    <p class="w-96 text-left ml-8 mt-4 text-base">
                    ${desc2}
                    </p>
            </div>
        `;
        let notContentHTML = document.createElement('div');
        notContentHTML.innerHTML = notContent;
        swal({
            content: notContentHTML,
            buttons: {
                cancel: {
                    text: 'OK',
                    value: null,
                    visible: true,
                    className: '',
                    closeModal: true
                },
                confirm: {
                    text: 'OK',
                    value: true,
                    visible: false,
                    className: '',
                    closeModal: true
                }
            },
            dangerMode: danger,
            showCloseButton: true
        });
    },
    // get datetime of download
    timestamp() {
        const today = new Date();
        const date = today.getFullYear() + '-' + (today.getMonth()+1) + '-' + today.getDate();
        const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        const timestamp = date + '-' + time;
        return timestamp;
    },
    // fx(s) for downloading measurement data to CSV file
    exportToCSV(csvData, propertyData) {
        // Convert data to CSV format
        const csvContent = csvData.map(row => row.join(',')).join('\n');
    
        // Create a Blob object from the CSV content
        const blob = new Blob([csvContent], { type: 'text/csv' });

        // Create filename
        const filename = `${propertyData.data.project_id}_${propertyData.type}_${propertyData.title}_${this.timestamp()}.csv`;
        // Create an anchor element to trigger the download
        const a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = filename;
    
        // Trigger a click event on the anchor element to start the download
        a.click();
    
        // Clean up by revoking the Blob URL
        URL.revokeObjectURL(a.href);
    },
    // convert numeric date to date format
    digitsToDate(digits, format = null) {
        if (format == null) {
            format = "YYYY/MM/DD"
        }
        return moment(new Date(digits * 1000)).format(format);
    }
}

export default helper
