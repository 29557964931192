import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Uploader = _resolveComponent("Uploader")

  return (_openBlock(), _createBlock(_component_Uploader, {
    ref: "uploader",
    onOnUpdateFileNumber: _ctx.countFiles,
    onOnFileAdd: _ctx.onFileAdd,
    owner: _ctx.owner_id,
    height: _ctx.height,
    isProjectCreate: _ctx.isProjectCreate,
    acceptedFiles: _ctx.acceptedFiles,
    onInitProcess: _ctx.handleFinished
  }, null, 8, ["onOnUpdateFileNumber", "onOnFileAdd", "owner", "height", "isProjectCreate", "acceptedFiles", "onInitProcess"]))
}