
import Model from '@/models/Notification'

const state = () => ({
    list: [],
    unreads: 0
})
  
// getters
const getters = {
    listNotifications(state) {
        return state.list
    },
    count(state) {
        return state.list.length
    },
    unread(state) {
/*         var unread = state.list.filter((l) => {
            return l.read_at == null
        });
        return unread; */

        return state.unreads;
    }
}

// actions
const actions = {
    async fetchNotification(context) {
        const unreads = await Model.unreads()
        context.commit( 'setUnread', unreads.data)

        const list = await Model.list()
        return new Promise((resolve) => {
            context.commit( 'load', list.data.data)
            resolve(list.data.data)
        })
    },
    async read(context, id) {
        const list = await Model.read(id);
    },
    async allRead(context) {
        const list = await Model.readAll();
    },
}

// mutations
const mutations = {
    load(state, data) {
        state.list = data
    },
    setUnread(state, data) {
        state.unreads = data
    }
}
  
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}