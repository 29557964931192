import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17b9dd63"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["type", "disabled"]
const _hoisted_2 = {
  key: 0,
  class: "flex"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "hidden sm:block" }
const _hoisted_5 = { class: "block sm:hidden" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass($props.baseClass + $options.buttonStyle + $options.buttonSize),
    type: $props.type,
    disabled: $props.disabled
  }, [
    ($props.hasIcon)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true),
          _createTextVNode(" " + _toDisplayString(_ctx.$t($props.text)), 1)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t($props.text)), 1),
          _createElementVNode("span", _hoisted_5, _toDisplayString($props.shortText != '' ? _ctx.$t($props.shortText) : _ctx.$t($props.text)), 1)
        ]))
  ], 10, _hoisted_1))
}