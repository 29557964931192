//Gsi Geoid Map generated by "gsigeois2Js" 
//Source Data: gsigeo2011_ver
import { geoidMap } from '@/third-party/Geoid';
import * as Cesium from '@/sdk/cesium';
import Model3D from '@/third-party/3dModels';
import ProjectResources from '@/models/ProjectResources';
import { isProxy, toRaw } from 'vue';
import ProjectFiles from '@/models/Projectfiles';

export class GsiGeoid {
    constructor($this) {
        this.geoidMap = geoidMap;
        this.$this = $this;
    }
    getGeoid(lat, lon) {
        const glamn = 20;
        const glomn = 120;
        const dgla = 0.016667;
        const dglo = 0.025;
        const nla = 1801;
        const nlo = 1201;
        if (lat < glamn || lat > glamn + dgla * (nla - 1) || lon < glomn || lon > glomn + dglo * (nlo - 1)) {
            return 0;
        }
        const j = Math.floor((lon - glomn) / dglo);
        const i = Math.floor((lat - glamn) / dgla);
        const a = this.geoidMap['' + (i) + '_' + (j) + ''];
        const b = this.geoidMap['' + (i) + '_' + (j + 1) + ''];
        const c = this.geoidMap['' + (i + 1) + '_' + (j) + ''];
        const d = this.geoidMap['' + (i + 1) + '_' + (j + 1) + ''];
        if (a == null || b == null || c == null || d == null) {
            return 0;
        }
        const wlon = glomn + j * dglo;
        const elon = glomn + (j + 1) * dglo;
        const slat = glamn + i * dgla;
        const nlat = glamn + (i + 1) * dgla;
        const t = (lat - slat) / (nlat - slat);
        const u = (lon - wlon) / (elon - wlon);
        let Z = (1 - t) * (1 - u) * a + (1 - t) * u * b + t * (1 - u) * c + t * u * d;
        Z *= 100000;
        Z = Math.floor(Z + 0.5);
        Z /= 100000;
        return Z;
    }
    // Update settings for selected target data
    updateHeightReference(selectedLayer, height_ref, layerType) {
        let layer = [];
        // check if selectedLayer is a proxy; convert to raw data if necessary
        isProxy(selectedLayer) ? layer = toRaw(selectedLayer) : layer = selectedLayer;
        let cesiumRef = this.$this;
        let $this = this;
        cesiumRef.$emit('setLoadingStatus', true);
        if (layerType === "pointcloud") {
            cesiumRef.tilesetData.forEach(function (tile, index) {
                let settings = tile.settings
                settings ? settings : settings = {};
                if (!Object.keys(settings).includes("display_height_ellipsoidal")) {
                    settings['display_height_ellipsoidal'] = false;
                }
                if (layer.includes(tile.file_id)) {
                    settings.display_height_ellipsoidal = height_ref
                    // save settings for each layer
                    ProjectFiles.saveSettings({
                        file_id: tile.file_id,
                        attrdata: JSON.stringify(settings)
                    })
                    if (height_ref) {
                        // display_height_ellipsoidal = true
                        cesiumRef.$emit('setLoadingStatus', false);
                        $this.addGeoid(tile, height_ref, layerType, {});
                    } else {
                        // display_height_ellipsoidal = false
                        cesiumRef.$emit('setLoadingStatus', false);
                        $this.addGeoid(tile, height_ref, layerType, {});
                    }
                } else {
                    if (settings.display_height_ellipsoidal == true) {
                        cesiumRef.$emit('setLoadingStatus', false);
                        $this.addGeoid(tile, height_ref, layerType, {});
                    }
                    settings.display_height_ellipsoidal = false;
                    // save settings for each layer
                    ProjectFiles.saveSettings({
                        file_id: tile.file_id,
                        attrdata: JSON.stringify(settings)
                    })
                }
            });
        } else {
            layer.forEach(model => {
                let index = null;
                let file = cesiumRef.models.find((m, i) => {
                    if (m.model_id == model) {
                        index = i;
                        return m;
                    }
                })
                let entity = null;
                if (Model3D.is3DModel(file.type)) {
                    let tilesetEntities = Model3D.fetchModelTilesets(file.model_id);
                    $this.updateModelHeightReference(file, tilesetEntities, height_ref, layer, index);
                } else {
                    entity = Model3D.getEntity(
                        file.model_id,
                        file.type
                    );
                    $this.updateModelHeightReference(file, entity, height_ref, layer, index);
                }
            });
        }
    }

    updateModelHeightReference(file, entity, height_ref, layer, index = null) {
        let settings = {};

        if (typeof file.settings === 'string') {
            try {
                settings = JSON.parse(file.settings);
            } catch (e) {
                console.log("Height Reference: Error parsing file.settings")
            }
        } else {
            settings = file.settings ? file.settings : {};
        }
        if (!Object.keys(settings).includes("display_height_ellipsoidal")) {
            settings['display_height_ellipsoidal'] = false;
        }
        if (layer.includes(file.model_id)) {
            if (settings.display_height_ellipsoidal != height_ref) {
                if (Array.isArray(entity)) {
                    entity.forEach(item => {
                        this.addGeoid(item, height_ref, file.type, file);
                    })
                } else {
                    file.settings = settings;
                    this.addGeoid(entity, height_ref, file.type, file);
                }
                // save settings for each model
                settings.display_height_ellipsoidal = height_ref;
                ProjectResources.updateModel({
                    "settings": JSON.stringify(settings),
                    "model_id": file.model_id
                })
                this.$this.models[index].settings = settings;
            }
        } else {
            if (settings.display_height_ellipsoidal == true) {
                this.$this.$emit('setLoadingStatus', false);
                this.addGeoid(entity, height_ref, file.type, file);
            }
            // save settings for each model
            settings.display_height_ellipsoidal = false;
            ProjectResources.updateModel({
                "settings": JSON.stringify(settings),
                "model_id": file.model_id
            })
        }
    }
    // Adding/Removing the geoid value to height
    addGeoid(entity, height_ref, type, file) {
        let types = ["obj", "xml", "gltf"]
        let updatedFile = file;
        isProxy(file) ? updatedFile = toRaw(file) : updatedFile = file;
        if (typeof updatedFile.settings === 'string') {
            updatedFile.settings = JSON.parse(updatedFile.settings);
        }
        /** Add for GCP later if implementation is complete */
        if (type === 'gcp') {
            if (height_ref && !updatedFile.settings.display_height_ellipsoidal) {
                let dataSource = entity;
                // Get the array of entities
                let testLineEntities = dataSource.entities.values;
                for (let i = 0; i < testLineEntities.length; i++) {
                    let entity = testLineEntities[i];
                    const position = entity.position._value;
                    const cartographic = Cesium.Cartographic.fromCartesian(position);
                    const lat = Cesium.Math.toDegrees(cartographic.latitude);
                    const lon = Cesium.Math.toDegrees(cartographic.longitude);
                    const geoidValue = this.getGeoid(lat, lon);
                    const newHpos = Cesium.Matrix4.multiplyByPoint(
                        Cesium.Transforms.eastNorthUpToFixedFrame(position),
                        new Cesium.Cartesian3(0, 0, geoidValue),
                        new Cesium.Cartesian3());
                    entity.position.setValue(newHpos);
                    // const positions = entity.polyline.positions._value;
                    // let newPositions = [];
                    // // get the array of positions for the polyline
                    // for (let j = 0; j < positions.length; j++) {
                    //     let position = positions[j];
                    //     const cartographic = Cesium.Cartographic.fromCartesian(position);
                    //     const lat = Cesium.Math.toDegrees(cartographic.latitude);
                    //     const lon = Cesium.Math.toDegrees(cartographic.longitude);
                    //     const geoidValue = this.getGeoid(lat, lon);
                    //     const newHpos = Cesium.Matrix4.multiplyByPoint(Cesium.Transforms.eastNorthUpToFixedFrame(position),
                    //         new Cesium.Cartesian3(0, 0, geoidValue), new Cesium.Cartesian3());
                    //     newPositions.push(newHpos);
                    // }
                    // entity.polyline.positions.setValue(newPositions);
                }
            } else if (!height_ref) {
                Model3D.rerender(updatedFile);
            }
        } else if (type === "kml" || type === "geojson") {
            /** 
             * needs verification if all kml/geojson data have 
             * the same structure as the test data used for this implementation 
            **/
            if (height_ref && !updatedFile.settings.display_height_ellipsoidal) {
                let primitive = entity._primitives._primitives[1];
                let center = primitive._boundingSpheres[0].center;
                let carto = Cesium.Cartographic.fromCartesian(center);
                const lat = Cesium.Math.toDegrees(carto.latitude);
                const lon = Cesium.Math.toDegrees(carto.longitude);
                let height = carto.height;//インスタンスを作成
                const geoidValue = this.getGeoid(lat, lon); //緯度、経度の順 ⇒37.05534
                height += geoidValue;
                // let surface = Cesium.Cartesian3.fromRadians(carto.longitude, carto.latitude, 0.0);
                let offset = center;
                if (height_ref) {
                    offset = Cesium.Cartesian3.fromRadians(
                        carto.longitude,
                        carto.latitude,
                        height
                    );
                }
                let translation = Cesium.Cartesian3.subtract(
                    offset,
                    center,
                    new Cesium.Cartesian3());
                if (!this.$this.executedChanges.includes(updatedFile.model_id)) {
                    primitive.modelMatrix = Cesium.Matrix4.fromTranslation(translation);
                    this.$this.executedChanges.push(updatedFile.model_id)
                }

            } else if (!height_ref) {
                console.log("rerender")
                Model3D.rerender(updatedFile);
                const index = this.$this.executedChanges.indexOf(updatedFile.model_id);
                if (index > -1) { // only splice array when item is found
                    this.$this.executedChanges.splice(index, 1); // 2nd parameter means remove one item only
                }
            }
        } else {
            /** 
             * for point cloud data, obj (DTM, DSM), xml, gltf height update 
            **/

            let center = entity.boundingSphere.center;
            if (entity.centerPosition) {
                center = entity.centerPosition;
            }
            let carto = Cesium.Cartographic.fromCartesian(center);
            const lat = Cesium.Math.toDegrees(carto.latitude);
            const lon = Cesium.Math.toDegrees(carto.longitude);
            let height = carto.height;//インスタンスを作成
            let val = Cesium.Ellipsoid.WGS84.cartesianToCartographic(center);
            const geoidValue = this.getGeoid(lat, lon); //緯度、経度の順 ⇒37.05534
            height += geoidValue;
            // let surface = Cesium.Cartesian3.fromRadians(
            //     carto.longitude,
            //     carto.latitude,
            //     0.0);
            // console.log("geoid: ", geoidValue)
            let offset = center;
            if (height_ref) {
                offset = Cesium.Cartesian3.fromRadians(
                    carto.longitude,
                    carto.latitude,
                    height
                );
            }
            let translation = Cesium.Cartesian3.subtract(
                offset,
                center,
                new Cesium.Cartesian3());
            if (types.includes(type)) {
                if (!height_ref) {
                    let matrix = JSON.parse(updatedFile.matrix);
                    let position = updatedFile.position !== 'pointcloud' ? JSON.parse(updatedFile.position) : 'pointcloud';
                    let centerPosition = new Cesium.Cartesian3();
                    if (matrix) {
                        centerPosition = Cesium.Matrix4.getTranslation(matrix, new Cesium.Cartesian3());
                        entity.modelMatrix = matrix
                    } else if (position) {
                        if (position == 'pointcloud') {
                            if (this.$this.tilesetData.length > 0) {
                                let pointcloud = this.$this.tilesetData[0];
                                centerPosition = pointcloud.boundingSphere.center;
                                // console.log("loaded pointcloud")
                            }
                        } else {
                            centerPosition = new Cesium.Cartesian3(Number(position.x), Number(position.y), Number(position.z));
                        }
                        entity.modelMatrix = Cesium.Transforms.eastNorthUpToFixedFrame(centerPosition);
                    } else {
                        if (this.$this.tilesetData.length > 0) {
                            let pointcloud = this.$this.tilesetData[0];
                            centerPosition = pointcloud.boundingSphere.center;
                            entity.modelMatrix = Cesium.Transforms.eastNorthUpToFixedFrame(centerPosition);
                            // console.log("loading as is")
                        }
                    }
                    // t.root.transform = Cesium.Matrix4.IDENTITY;
                    const index = this.$this.executedChanges.indexOf(updatedFile.model_id);
                    if (index > -1) { // only splice array when item is found
                        this.$this.executedChanges.splice(index, 1); // 2nd parameter means remove one item only
                    }
                } else {
                    center = Cesium.Matrix4.multiplyByPoint(entity.modelMatrix, Cesium.Cartesian3.ZERO, new Cesium.Cartesian3());
                    let cartographic = Cesium.Cartographic.fromCartesian(center);
                    const offsetInModelSpace = new Cesium.Cartesian3(
                        0,
                        0,
                        cartographic.height + geoidValue); // moving tileset up
                    const origCenter = Cesium.Cartesian3.fromRadians(
                        cartographic.longitude,
                        cartographic.latitude,
                        0.0);
                    const transform = Cesium.Transforms.eastNorthUpToFixedFrame(origCenter);
                    const movedCenter = Cesium.Matrix4.multiplyByPoint(
                        transform,
                        offsetInModelSpace,
                        new Cesium.Cartesian3());

                    entity.modelMatrix = Cesium.Transforms.eastNorthUpToFixedFrame(movedCenter);

                    if (!this.$this.executedChanges.includes(updatedFile.model_id)) {
                        this.$this.executedChanges.push(updatedFile.model_id);
                    }
                }
            } else {
                // console.log("pointcloud")
                entity.modelMatrix = Cesium.Matrix4.fromTranslation(translation);
            }
        }
    }
}