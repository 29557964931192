import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill", "stroke"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("svg", {
    width: $props.width,
    height: $props.height,
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      d: "M19.169 17.3928L18.7319 17.7417L19.1274 18.1371L20.8952 19.9049C21.1685 20.1783 21.1685 20.6215 20.8952 20.8949C20.6218 21.1682 20.1786 21.1682 19.9052 20.8949L3.10522 4.09488C2.83185 3.82151 2.83185 3.37829 3.10522 3.10493C3.37859 2.83156 3.8218 2.83156 4.09517 3.10493L6.23199 5.24175L6.48821 5.49797L6.81147 5.33424C8.36983 4.54491 10.1325 4.0999 12.0005 4.0999C17.0937 4.0999 21.4148 7.41133 22.9259 11.9999C22.2193 14.1454 20.8977 16.0128 19.169 17.3928ZM9.30756 7.43414L8.75244 7.7622L9.20839 8.21815L11.0251 10.0348L11.2291 10.2388L11.5078 10.1643C11.6644 10.1224 11.8294 10.0999 12.0005 10.0999C13.0498 10.0999 13.9005 10.9506 13.9005 11.9999C13.9005 12.171 13.878 12.336 13.8361 12.4926L13.7616 12.7713L13.9656 12.9753L15.7822 14.792L16.2382 15.248L16.5663 14.6928C17.0329 13.9033 17.3005 12.9821 17.3005 11.9999C17.3005 9.07279 14.9276 6.6999 12.0005 6.6999C11.0183 6.6999 10.0971 6.96753 9.30756 7.43414ZM6.72613 12.5241C6.97261 15.0329 8.96746 17.0278 11.4763 17.2743L13.9393 19.7372C13.3092 19.8442 12.6615 19.8999 12.0004 19.8999C6.90718 19.8999 2.58608 16.5885 1.07502 11.9999C1.47765 10.7773 2.07997 9.64497 2.84365 8.64161L6.72613 12.5241Z",
      fill: $props.color,
      stroke: $props.color
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}