import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "w-3/4 text-left rounded-xl border-2 border-error-500 p-6 flex flex-col mt-8" }
const _hoisted_2 = { class: "text-error-100 font-openSans font-bold text-xl" }
const _hoisted_3 = { class: "text-neutral-100 font-openSans font-normal text-lg w-10/12 mt-6" }
const _hoisted_4 = { class: "font-openSans font-normal text-lg w-10/12 mt-3 text-neutral-100" }
const _hoisted_5 = {
  key: 0,
  class: "font-openSans font-normal text-lg w-10/12 mt-3 text-neutral-100"
}
const _hoisted_6 = { class: "flex justify-start mt-10" }
const _hoisted_7 = ["disabled"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t('billing.overdue')), 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('billing.desc_overdue')), 1),
    _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('billing.contact_sales')), 1),
    ($props.overdue.daysDelayed >= 7 && $props.overdue.daysDelayed < 14)
      ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString("Your account will freeze in " + (14-$props.overdue.daysDelayed) + " days"), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = (...args) => ($options.handleContactUs && $options.handleContactUs(...args))),
        class: _normalizeClass(["border border-primary-700 text-primary-700 py-3 px-10 mr-6 text-center rounded", ($data.loading == true) ? 'cursor-wait border-secondShades-200' : 'bg-white']),
        disabled: $data.loading
      }, _toDisplayString(_ctx.$t('billing.btn_contact')), 11, _hoisted_7),
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = $event => (_ctx.$emit('click-paybycc'))),
        class: "bg-primary-700 text-white py-3 px-10 text-center rounded"
      }, _toDisplayString(_ctx.$t('billing.btn_pay')), 1)
    ])
  ]))
}