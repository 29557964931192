import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_camera = _resolveComponent("camera")
  const _component_point_light = _resolveComponent("point-light")
  const _component_lambert_material = _resolveComponent("lambert-material")
  const _component_sphere = _resolveComponent("sphere")
  const _component_scene = _resolveComponent("scene")
  const _component_renderer = _resolveComponent("renderer")

  return (_openBlock(), _createElementBlock("div", {
    id: "sample_arrow3",
    class: _normalizeClass(["flex flex-col p-1 rounded fixed right-2 bottom-14 bg-transparent opacity-80 mt-20 w-36 h-36 text-white", $props.isProperties ? 'right-72 mr-2' : '-mr-8'])
  }, [
    _createVNode(_component_renderer, {
      ref: "renderer",
      antialias: "",
      "orbit-ctrl": "",
      alpha: "",
      width: "80",
      height: "80"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_camera, { position: { z: 10 } }),
        _createVNode(_component_scene, { ref: "scene" }, {
          default: _withCtx(() => [
            _createVNode(_component_point_light, { position: { y: 50, z: 50 } }),
            _createVNode(_component_sphere, {
              ref: "sphere",
              rotation: {y: Math.PI / 2},
              radius: 0.3
            }, {
              default: _withCtx(() => [
                _createVNode(_component_lambert_material)
              ]),
              _: 1
            }, 8, ["rotation"])
          ]),
          _: 1
        }, 512)
      ]),
      _: 1
    }, 512)
  ], 2))
}