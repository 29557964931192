import { resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass({ 'modal-root': $data.modal.component })
  }, [
    _createVNode(_Transition, { name: "modal" }, {
      default: _withCtx(() => [
        ($data.modal)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent($data.modal.component), _mergeProps({
              key: 0,
              close: $data.modal.close,
              dismiss: $data.modal.dismiss
            }, $data.modal.props, {
              class: { 'd-block': $data.modal.component }
            }), null, 16, ["close", "dismiss", "class"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 2))
}