
// Uses axios and Promises
// Everything that we need to communicate with the api will be declared here
import api from '@/providers/api'

const folder = {

    get(id) {
        return api.get(`folders/get/${id}`)
    },

    list() {
        return api.get('folders/list')
    },
    listSorted(payload=null) {

        
        var query = new URLSearchParams();

        if (payload == null){
            query.append("folder_id", 0);
            query.append("order_by", 'created_at');
            query.append("direction", 'desc');
        }else{
            query.append("folder_id", payload.folder_id);
            query.append("order_by",  payload.order_by);
            query.append("direction", payload.direction);
        }
        return api.get('folders/list/sorted?' + query.toString());

    },
    store(payload) {
        return api.post('folders/create', payload);
    },

    update(payload) {
        return api.put(`folders/update/${payload.folder_id}`, payload);
    },

    delete(id) {
        return api.delete(`folders/delete/${id}`);
    },

    moveProject(payload) {
        return api.post(`folders/moveproject/${payload.project_id}`, payload);
    },

    request(url) {
        return api.request(url);
    },

}

export default folder