
// Uses axios and Promises
// Everything that we need to communicate with the api will be declared here
import api from '@/providers/api'

const plans = {

    list() {
        return api.get('plans/list')
    },

    get(id) {
        return api.get('plans/get/' + id)
    },

    pricingList(id) {
        return api.get('plans/pricings')
    },
    getPlansAdminConsole(){
        return api.get('admin/plans')
    }

}

export default plans
