import * as Cesium from '@/sdk/cesium';
import proj4 from 'proj4';
import { formatJSTDate } from '@/libraries/DateUtils';
import swal from 'sweetalert';
import Measurement from '@/models/Measurement';

export function selectMeasurement(tool, tilesetData, options, component) {
    switch (tool) {
        case 'point':
            component.measure.drawPoint({
                clampToGround: false,
                refs: component.compRef,
                selectedEpsg: component.selectedEpsg,
                lang: component.$i18n.locale,
                callback: (data) => {
                    component.handleCreateTool('point', data);
                }
            });
            break;
        case 'annotation':
            component.measure.drawAnnotation({
                clampToGround: false,
                refs: component.compRef,
                lang: component.$i18n.locale,
                callback: (data) => {
                    component.handleCreateTool('annotation', data);
                }
            });
            break;
        case 'line':
            component.measure.drawLineMeasureGraphics({
                clampToGround: false,
                refs: component.compRef,
                lang: component.$i18n.locale,
                callback: (data) => {
                    component.handleCreateTool('line', data);
                }
            });
            break;
        case 'polygon':
            component.measure.drawAreaMeasureGraphics({
                clampToGround: true,
                refs: component.compRef,
                lang: component.$i18n.locale,
                callback: (data) => {
                    component.handleCreateTool('area', data);
                }
            });
            break;
        case 'volume':
            component.measure.drawVolumeGraphics({
                method: 'triangulated',
                resolution: 0.5,
                custom: 50,
                refs: component.compRef,
                t: this.$t,
                lang: component.$i18n.locale,
                callback: (data) => {
                    component.handleCreateTool('volume', data);
                }
            }, 'CALC');

            break;
        case 'angle':
            component.measure.drawTrianglesMeasureGraphics({
                refs: component.compRef,
                lang: component.$i18n.locale,
                callback: (data) => {
                    component.handleCreateTool('angle', data);
                }
            });
            break;
        case 'profile':
            component.measure.drawProfileGraphics({
                tileset: component.tilesetData[component.selectedTileset],
                clampToGround: false,
                refs: component.compRef,
                lang: component.$i18n.locale,
                callback: (data) => {
                    component.handleCreateTool('profile', data);
                }
            }, false, 0);
            break;
        case 'height':
            component.measure.drawLineHeightGraphics({
                clampToGround: false,
                refs: component.compRef,
                lang: component.$i18n.locale,
                callback: (data) => {
                    component.handleCreateTool('height', data);
                }
            });
            break;
        case 'manual_class_cross_section': {
            component.measure.drawManualClassGraphicsCrossSection({
                tileset: tilesetData.filter((e) => e.file_id === options.fileId),
                profileWidth: options.profileWidth,
                selectedClass: options.selectedClass,
                refs: component.compRef,
                settings: component.settings,
                reselectProfile: ("reselectProfile" in options) ? options.reselectProfile : false,
                previousPosition: ("previousPosition" in options) ? options.previousPosition : null,
                lang: component.$i18n.locale,
                callback: (data) => {
                    this.manual_class_cross_section.previousPosition = data.previousPosition;
                }
            });
            break;
        }
        case 'manual_class':
            try {
                let meta = JSON.parse(options.meta.pdal_info_json);
                let japan_proj = meta.wkt;
                let pseudomercator =
                    'PROJCS["WGS 84 / Pseudo-Mercator",GEOGCS["WGS 84",DATUM["WGS_1984",SPHEROID["WGS 84",6378137,298.257223563,AUTHORITY["EPSG","7030"]],AUTHORITY["EPSG","6326"]],PRIMEM["Greenwich",0,AUTHORITY["EPSG","8901"]],UNIT["degree",0.0174532925199433,AUTHORITY["EPSG","9122"]],AUTHORITY["EPSG","4326"]],PROJECTION["Mercator_1SP"],PARAMETER["central_meridian",0],PARAMETER["scale_factor",1],PARAMETER["false_easting",0],PARAMETER["false_northing",0],UNIT["metre",1,AUTHORITY["EPSG","9001"]],AXIS["X",EAST],AXIS["Y",NORTH],EXTENSION["PROJ4","+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs"],AUTHORITY["EPSG","3857"]]';
                let webmercator = new Cesium.WebMercatorProjection();
                component.measure.drawManualClassGraphics({
                    clampToGround: true,
                    refs: component.compRef,
                    options: options,
                    lang: component.$i18n.locale,
                    callback: (data) => {
                        if (component.manual_class.points.length > 0) {
                            component.manual_class.points.push('#' + '\n');
                        }
                        data.id.forEach((id) => {
                            component.manual_class.id.push(id);
                        });
                        data.positions.forEach((pos) => {
                            let cartographic =
                                Cesium.Cartographic.fromCartesian(pos);
                            let projected =
                                webmercator.project(cartographic);
                            let projected_orig = proj4(
                                pseudomercator,
                                japan_proj,
                                [projected.x, projected.y, projected.z]
                            );

                            component.manual_class.points.push(
                                projected_orig[0] +
                                ' ' +
                                projected_orig[1] +
                                ' ' +
                                cartographic.height +
                                ' \n'
                            );
                        });
                    }
                });
            } catch (error) {
                console.log(error)
            }
            break;
        default:
            break;
    }
}

export function createMeasurementEntity(measure, component) {
    let type = {
        1: 'point',
        2: 'line',
        3: 'area',
        4: 'height',
        5: 'profile',
        6: 'annotation',
        7: 'angle',
        8: 'volume'
    };
    let data = (typeof measure.data === 'string')? JSON.parse(measure.data): measure.data;
    let _entity = '';

    switch (type[measure.measurement_id]) {
        case 'point':
            var text =
                'x: ' +
                data.x.toFixed(4) +
                '\n' +
                'y: ' +
                data.y.toFixed(4) +
                '\n' +
                'z: ' +
                data.z.toFixed(4);
            component.measure.drawPoint({
                clampToGround: false,
                position: data,
                selectedEpsg: component.selectedEpsg,
                label: measure.label,
                lang: component.$i18n.locale,
                callback: (data) => {
                    _entity = data;
                }
            });
            break;
        case 'annotation':
            component.measure.drawAnnotation({
                clampToGround: false,
                position: data,
                lang: component.$i18n.locale,
                label: measure.label + (measure.images.length > 0 ? "\n[" + this.$t('viewer.file_manager.with_image') + "]" : ''),
                callback: (data) => {
                    _entity = data;
                }
            });
            break;
        case 'line':
            component.measure.drawLineMeasureGraphics({
                clampToGround: false,
                p: data,
                lang: component.$i18n.locale,
                callback: (data) => {
                    _entity = data;
                }
            });
            break;
        case 'area':
            component.measure.drawAreaMeasureGraphics({
                clampToGround: false,
                p: data,
                lang: component.$i18n.locale,
                callback: (data) => {
                    _entity = data;
                }
            });

            break;
        case 'angle':
            component.measure.drawTrianglesMeasureGraphics({
                p: data,
                lang: component.$i18n.locale,
                callback: (data) => {
                    _entity = data;
                }
            });
            break;
        case 'height':
            component.measure.drawLineHeightGraphics({
                p: data,
                lang: component.$i18n.locale,
                callback: (data) => {
                    _entity = data;
                }
            });
            break;
        case 'profile':
            // add recalc & pitch value
            component.measure.drawProfileGraphics({
                tileset: component.tilesetData[component.selectedTileset],
                p: data,
                label: 'Profile',
                lang: component.$i18n.locale,
                callback: (data) => {
                    // add recalculate (bool) & pitch (float) as params
                    component.$emit('on-cross-section-cesium', data.samplePromise, false, false, data.pitch || 0);

                    _entity = data;
                    return _entity;
                }
            }, false, data.pitch || 0);
            // component.measure.drawMultiPointsProfileGraphics({
            //     clampToGround: false,
            //     refs: component.compRef,
            // });
            break;
        case 'volume':
            if (typeof data === "object") {
                _entity = component.measure.drawVolumeGraphics(
                    {
                        refs: component.compRef,
                        method: 'triangulated',
                        resolution: 0.5,
                        custom: 50,
                        t: this.$t,
                        callback: (data) => {
                            _entity = data;
                        },
                        positions: data.positions,
                        result: data.result,
                        lang: component.$i18n.locale
                    }, 'DRAW_BACK');
                _entity.measurement_id = measure.measurement_id;
                _entity.project_measurement_id = measure.project_measurement_id;
                _entity.project_id = measure.project_id;
            }
            // _entity = volume3D.draw(data.positions, data.result);
            break;
        default:
            break;
    }
    component.entities.push(_entity);
    return _entity;
}

export function measurementCalculations(params, measure) {
    let result = 0;
    switch (params.type) {
        case 'line':
            result = measure.getDistanceCalculation(
                params.positions
            );
            break;
        case 'area':
            result = measure.getAreaCalculation(params.positions);
            break;
        case 'volume':
            result = { e: 0, e2: 0, e3: 0 };
            break;
        case 'angle':
            result = measure.getAngleCalculation(params.positions);
            break;
        case 'profile':
            // console.log(JSON.stringify(params.positions))
            result = measure.getProfileCalculation(params.positions);
            /*    result = component.measure.getDistanceCalculation(
                params.positions
            ); */
            break;
        case 'height':
            result = measure.getHeightCalculation(params.positions);
            break;
        default:
            break;
    }
    return result;
}


export function createMeasurementTool(params, component) {
    let type = {
        point: 1,
        line: 2,
        area: 3,
        height: 4,
        profile: 5,
        annotation: 6,
        angle: 7,
        volume: 8,
        clip: 9,
        volumeCalculation: 10
    };

    let points = [];
    if (
        params.type === 'angle' ||
        params.type === 'height' ||
        params.type === 'profile'
    ) {
        points = {
            e: params.data.e,
            e2: params.data.e2,
            e3: params.data.e3
        };
    } else if (params.type === 'volume') {
        points = params.data.points;
    } else {
        points = params.data.positions;
    }

    let hasPermitToSave = component.hasPermission('measurement.save')
    if (!component.isOwn) {
        console.log('not own')
        hasPermitToSave = component.hasProjectPermission('measurement.save')
    }

    if (component.hasPermission('measurement.save') || component.hasProjectPermission('measurement.save')) {
        if (params.type === 'profile') {
            params.data.samplePromise.then((result) => {
                points.sampleHeights = result[1];
                points.distanceList = result[0].Distances;
                // added line below to fix simultaneous creation of cross-section then hiding the last 
                // created one hides all the previously created cross-sections
                component.$refs.cesiumRef.measure.entityIds = [];
                Measurement.store(component.project_id, {
                    measurement: type[params.type],
                    label: params.type,
                    data: JSON.stringify(points)
                }).then((data) => {
                    data.data.data.viewer_id = params.data.id;//ids of the entities go here
                    component.$refs.fileManagerRef.createMeasurement(data.data.data);
                    //set the data and params to give to Property component
                    component.handleActivePropert({
                        property: params.type,
                        data: data.data
                    });

                    component.projectFiles.measurements.push(data.data.data);
                    component.$refs.cesiumRef.pushEntities(params.data);

                    this.$helper.identifyUserGuiding(component.getUser, component.subStatus)
                    this.$helper.createSegmentEvent(
                        component.getUser.user_id,
                        'Viewer: Executed ' + params.type + ' measurement',
                        {
                            'userId': component.getUser.user_id,
                            'projectId': component.$route.params.project_id,
                            'description': `The user selected the tool bar in the top-hand menu.`,
                            'tag': 'client'
                        }
                    )
                }).finally(() => {
                    component.$refs.propertyRef.setLoadingState({
                        state: false,
                        tool: params.type !== 'volume' ? 'measure' : 'volume'
                    });
                }).catch((response) => {
                    this.$toast.show(this.$t('status.disconnected_message'), {
                        type: 'error',
                        position: 'top',
                        duration: false,
                        dismissible: true
                    });
                });
            })
        } else {
            const currentDate = new Date();
            const formattedDate = formatJSTDate(currentDate);
            let label = points.cutFillResult ? 'Cut + Fill Volume ' + formattedDate : params.type;
            Measurement.store(component.project_id, {
                measurement: type[params.type],
                label: label,
                data: JSON.stringify(points)
            }).then((data) => {
                data.data.data.viewer_id = params.data.id;//ids of the entities go here
                component.$refs.fileManagerRef.createMeasurement(data.data.data);

                // for volume update on mobile
                let entityData = params.data;
                entityData.measurement_id = data.data.data.measurement_id;
                entityData.project_measurement_id = data.data.data.project_measurement_id;
                entityData.project_id = data.data.data.project_id;
                component.$refs.cesiumRef.pushEntities(entityData);

                //set the data and params to give to Property component
                component.handleActivePropert({
                    property: params.type,
                    data: data.data
                });

                component.projectFiles.measurements.push(data.data.data);
                this.$helper.identifyUserGuiding(component.getUser, component.subStatus)
                this.$helper.createSegmentEvent(
                    component.getUser.user_id,
                    'Viewer: Executed ' + params.type + ' measurement',
                    {
                        'userId': component.getUser.user_id,
                        'projectId': component.$route.params.project_id,
                        'description': `The user selected the tool bar in the top-hand menu.`,
                        'tag': 'client',
                    }
                );
            }).finally(() => {
                component.$refs.propertyRef.setLoadingState({
                    state: false,
                    tool: params.type !== 'volume' ? 'measure' : 'volume'
                });
            }).catch((response) => {
                this.$toast.show(this.$t('status.disconnected_message'), {
                    type: 'error',
                    position: 'top',
                    duration: false,
                    dismissible: true
                });
            });
        }
    } else {
        let data = {
            project_measurement_id: 0,
            project_id: component.project_id,
            measurement_id: type[params.type],
            label: params.type,
            visible: 1,
            data: points,
            viewOnly: true
        };

        component.$refs.fileManagerRef.createMeasurement(data);

        let dataPanel = {
            project_measurement_id: 0,
            project_id: component.project_id,
            measurement_id: type[params.type],
            label: params.type,
            visible: 1,
            data: JSON.stringify(points),
            viewer_id: params.data.id//ids of the entities go here
        };
        component.projectFiles.measurements.push(dataPanel);
        //set the data and params to give to Property component
        component.handleActivePropert({
            property: params.type,
            data: { data: dataPanel }
        });
        component.$refs.propertyRef.setLoadingState({
            state: false,
            tool: params.type !== 'volume' ? 'measure' : 'volume'
        });

    }
}

export function deleteMeasurementHandle(params, component) {
    swal({
        title: this.$t('prompts.are_you_sure'),
        text: this.$t('prompts.delete_warning'),
        icon: 'warning',
        buttons: true,
        dangerMode: true
    }).then((willDelete) => {
        if (willDelete) {
            Measurement.delete(
                params.data.project_id,
                params.data.project_measurement_id
            ).then(() => {
                if (params.data.label === 'profile') {
                    this.$refs.cesiumRef.handleDeactivateHeightProfile(
                        false
                    );
                    if (component.showHeightProfileModal) {
                        component.showHeightProfileModal = false;
                        this.$refs.propertyRef.setPanel(false);
                    }
                    this.$refs.propertyRef.resetProperties();
                }
                this.$refs.cesiumRef.setVisibility({
                    type:
                        params.data.measurement_id !== 6
                            ? 'tool'
                            : 'annotation',
                    show: false,
                    index: 0,
                    data: params
                });

                //clear Property Panel
                let panelData = this.$refs.propertyRef.propertyData;

                if (panelData.data && panelData.data.project_measurement_id &&
                    params.data.project_measurement_id === panelData.data.project_measurement_id) {
                    component.toggleProperties(false);
                    this.$refs.propertyRef.setPanel(false);
                    this.$refs.propertyRef.resetProperties();
                }

                this.$refs.fileManagerRef.removeMeasurement(params);
            });
        }
    });
}

export function clearMeasurementHandle(component, cutFill = false) {
    component.crosshairCursor = false
    component.measure.clear()
    if (!cutFill) {
        let _drawLayer = component.viewer.dataSources.getByName('measureLayer')[0];
        let layerEntities = _drawLayer.entities.values
        let forRemoval = []
        layerEntities.forEach(layerE => {
            let tryToFind = layerE.id
            let hasFound = false
            if (component.entities.length > 0) {
                component.entities.forEach(ent => {
                    let ids = ent.id
                    if (!ids) return
                    for (let i = 0; i < ids.length; i++) {
                        let val = ids[i];
                        if (tryToFind == val) {
                            hasFound = true
                        }
                    }
                });
                if (!hasFound) {
                    forRemoval.push(tryToFind)
                }
            } else {
                forRemoval.push(tryToFind)
            }
        });
        forRemoval.forEach(element => {
            component.drawLayer.entities.removeById(element);
            // added line to fix removal of discontinued entities in distance measurement tool
            _drawLayer.entities.removeById(element);
        });
    }
}

export function renameMeasurements(entityData, component) {
    let e = component.drawLayer.entities.getById(
        entityData.data.viewer_id[0]
    );
    if (entityData.data.measurement_id === 1) {
        //Replace Label with new Label
        /*  let labelArray = e.label.text.getValue(this.viewer.clock.currentTime).split("\n");
         labelArray.shift();
         labelArray.unshift(entityData.title)
         e.label.text = labelArray.join("\n"); */

        let prevText = e.label.text.getValue(component.viewer.clock.currentTime);
        let textLength = prevText.length > entityData.title.length ? prevText.length : entityData.title.length;

        let centerText = (text, numberOfSpaces) => {
            text = text.trim();
            let l = text.length;
            let w2 = Math.ceil(numberOfSpaces / 2);
            let l2 = Math.ceil(l / 2);
            let s = new Array(w2 - l2 + 1).join(" ");
            text = s + text + s;
            if (text.length < numberOfSpaces) {
                text += new Array(numberOfSpaces - text.length + 1).join(" ");
            }
            return text;
        }
        e.label.text = centerText(entityData.title, textLength);
    } else {
        e.label.text._value = entityData.title;
    }
}