
// Uses axios and Promises
// Everything that we need to communicate with the api will be declared here
import api from '@/providers/api'

const measurement = {

    store(id, payload) {
        return api.post(`measurements/save/${id}`, payload)
    },

    list(id) {
        return api.get(`measurements/list/${id}`)
    },

    delete(id, mid) {
        return api.delete(`measurements/delete/${id}/${mid}`, {});
    },

    update(id, payload) {
        return api.post(`measurements/update/${id}`, payload);
    },

    setVisibility(id, mid, payload) {
        return api.post(`measurements/visibility/${id}/${mid}`, payload);
    },
    
    updateToolsVisibility(project_id, payload) {
        return api.post(`measurements/group/visibility/` + project_id, payload);
    },

}

export default measurement