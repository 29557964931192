
import axios from 'axios'

import config from '@/config'

const api = {

    httpHeaders: {},

    setHeaders( header = {} ) {
        axios.defaults.headers.common = header
    },

    setDefaultHeaders() {
        axios.defaults.headers.common['Accept'] = 'application/json'
    },

    get( endpoint ) {
        this.setDefaultHeaders();
        return axios.get( 
            config.get().apiUrl + '/' + endpoint
        );
    },

    post( endpoint, params ) {
        this.setDefaultHeaders();
        return axios.post( 
            config.get().apiUrl + '/' + endpoint,
            params
        );
    },

    put( endpoint, params ) {
        this.setDefaultHeaders();
        return axios.put( 
            config.get().apiUrl + '/' + endpoint,
            params
        );
    },

    delete( endpoint, params ) {
        this.setDefaultHeaders();
        return axios.delete( 
            config.get().apiUrl + '/' + endpoint,
            params
        );
    },

    request( endpoint, params ) {
        this.setDefaultHeaders();
        return axios.get( 
            endpoint,
            params
        );
    }

}

export default api