import * as Cesium from '@/sdk/cesium';
import * as turf from '@/third-party/OutTurf.js';

export class CropCrossSection {
    constructor(viewer, resolution, tileset, params, measure) {
        this.heightValues = [];
        this._viewer = viewer;
        this._resolution = resolution;
        this._tileset = tileset;

        this.linePoints = [];
        this._opposite = {};
        this._hypotenuse = {};
        this._degrees = 0;
        this.params = params.params;
        this.data = params.data;
        this.width = params.width;

        this._measure = measure;

        this._terrainClippingPlane = [];
        this._tilesetClippingPlane = [];

        this.setGeometries();
    }

    setGeometries() {

        this._opposite = this._measure.getPositionDistance(
            this._measure.transformCartesianArrayToWGS84Array(this.params.e3));
        this._hypotenuse = this._measure.getPositionDistance(
            this._measure.transformCartesianArrayToWGS84Array(this.params.e));

        let inverted = 1;
        if (this.params.e3[0].y > this.params.e3[1].y) {
            inverted = -1;
        }
        this._degrees = this._measure.getTriangleDegrees(this._opposite, this._hypotenuse) * inverted;

        this.data.forEach((d) => {
            this.linePoints.push(new Cesium.Cartesian3(d.x, d.y, d.z));
        });

    }


    cropCrossSection(params) {

        let tileset = this._tileset;

        let globe = this._viewer.scene.globe;

        let lineVec = Cesium.Cartesian3.subtract(this.linePoints[1], this.linePoints[0], new Cesium.Cartesian3());
        Cesium.Cartesian3.normalize(lineVec.clone(), lineVec);

        if(lineVec.x < 0) {
            Cesium.Cartesian3.negate(lineVec.clone(), lineVec);
        }

        const ellipsoidNormal = Cesium.Ellipsoid.WGS84.geodeticSurfaceNormal(this.linePoints[0], new Cesium.Cartesian3());

        const planeNormalDirection = Cesium.Cartesian3.cross(lineVec, ellipsoidNormal, new Cesium.Cartesian3());
        const planeNormal = Cesium.Cartesian3.normalize(planeNormalDirection, new Cesium.Cartesian3());

        const orthoPlaneNormalDirection = Cesium.Cartesian3.cross(planeNormal, Cesium.Cartesian3.UNIT_Z, new Cesium.Cartesian3());
        const orthoPlanelNormal = Cesium.Cartesian3.normalize(orthoPlaneNormalDirection, new Cesium.Cartesian3());


        const angle1 = Cesium.Cartesian3.angleBetween(ellipsoidNormal, Cesium.Cartesian3.UNIT_Z);
        let orientation = Cesium.Quaternion.fromAxisAngle(planeNormal, angle1, new Cesium.Quaternion());
        // orientation = Cesium.Quaternion.fromAxisAngle(orthoPlanelNormal, angle1, orientation);

        // if(lineVec.y > 0) {
        //
        //     const angle2 = Cesium.Cartesian3.angleBetween(ellipsoidNormal, Cesium.Cartesian3.UNIT_Z);
        //     Cesium.Quaternion.fromAxisAngle(planeNormal, -angle2, orientation);
        //
        // }



        //
        // let orthoPlanelNormal1 = Cesium.Quaternion.fromAxisAngle(planeNormal, angle1, new Cesium.Quaternion());

        //

        // const angle2 = Cesium.Cartesian3.angleBetween(ellipsoidNormal, Cesium.Cartesian3.UNIT_Z);
        // orientation = Cesium.Quaternion.fromAxisAngle(orthoPlanelNormal, angle2, new Cesium.Quaternion());

        // Cesium.Quaternion.fromAxisAngle(planeNormal, angle1, orientation1);

        let centerPosition = Cesium.Cartesian3.midpoint(this.linePoints[0], this.linePoints[1], new Cesium.Cartesian3());

        let matrix4FromQuat = Cesium.Matrix4.fromTranslationQuaternionRotationScale(centerPosition, orientation, new Cesium.Cartesian3(1, 1, 1));

        let firstPlaneNormal = planeNormal;
        let secondPlaneNormal = Cesium.Cartesian3.negate(planeNormal, new Cesium.Cartesian3());
        let thirdPlaneNormal = orthoPlanelNormal;
        let fourthPlaneNormal = Cesium.Cartesian3.negate(orthoPlanelNormal, new Cesium.Cartesian3());

        console.log('Cesium.Cartesian3.UNIT_Z', Cesium.Cartesian3.UNIT_Z)
        console.log('ellipsoidNormal', ellipsoidNormal)
        console.log('linevec', lineVec)
        console.log('angle', angle1)
        console.log('firstPlaneNormal', firstPlaneNormal)
        console.log('secondPlaneNormal', secondPlaneNormal)
        console.log('thirdPlaneNormal', thirdPlaneNormal)
        console.log('fourthPlaneNormal', fourthPlaneNormal)
        console.log('orientation', orientation)
        // debug vector
        this._viewer.entities.add({
            vector: {
                position: this.linePoints[0],
                direction: Cesium.Cartesian3.UNIT_X,
                color: Cesium.Color.RED,
                length: 100
            },
        });
        this._viewer.entities.add({
            vector: {
                position: this.linePoints[0],
                direction: Cesium.Cartesian3.UNIT_Y,
                color: Cesium.Color.GREEN,
                length: 100
            },
        });
        this._viewer.entities.add({
            vector: {
                position: this.linePoints[0],
                direction: Cesium.Cartesian3.UNIT_Z,
                color: Cesium.Color.BLUE,
                length: 100
            },
        });


        let distance = Cesium.Cartesian3.distance(this.linePoints[0], this.linePoints[1]);
        // let width = (params.width ? params.width : this.width) | 2;
        let width = 5;
        let terrainClippingPlane = new Cesium.ClippingPlaneCollection({
            planes: [
                new Cesium.ClippingPlane(
                    firstPlaneNormal,
                    width
                ),
                new Cesium.ClippingPlane(
                    secondPlaneNormal,
                    width
                ),
                new Cesium.ClippingPlane(
                    thirdPlaneNormal,
                    distance/2
                ),
                new Cesium.ClippingPlane(
                    fourthPlaneNormal,
                    distance/2
                )
            ],
            enabled: true,
            unionClippingRegions: true,
            edgeWidth: 1.0,
            edgeColor: Cesium.Color.RED,
            // modelMatrix: matrix4FromQuat//tilesetClippingMatrix//Cesium.Matrix4.IDENTITY//matrix4FromQuat//Cesium.Transforms.eastNorthUpToFixedFrame(centerPosition)//
            modelMatrix: Cesium.Matrix4.IDENTITY
        });

        /*centering of model matrix*/
        let inverseReference = Cesium.Matrix4.inverse(tileset.clippingPlanesOriginMatrix, new Cesium.Matrix4());
        let centerPositionMatrix = Cesium.Transforms.eastNorthUpToFixedFrame(centerPosition);
        let tilesetClippingMatrix = Cesium.Matrix4.multiply(inverseReference, matrix4FromQuat, new Cesium.Matrix4());
        /*end*/

        /*Clone the Terrain Clipping and set the model Matrix of the Tileset*/
        let tilesetClippingPlane = Cesium.clone(terrainClippingPlane);
        tilesetClippingPlane.modelMatrix = tilesetClippingMatrix;


        //Set the camera environment for cross section view and adds the clipping planes
        globe.backFaceCulling = false;
        globe.showSkirts = true;
        globe.clippingPlanes = terrainClippingPlane;
        tileset.clippingPlanes = tilesetClippingPlane;


        //// primitive to debug
        this._viewer.scene.primitives.add(new Cesium.DebugModelMatrixPrimitive({
            modelMatrix: tilesetClippingMatrix, //centerPositionMatrix,
            length: 20.0,
            width: 2.0,
            show: true,
            // orientation: orientation//Cesium.Quaternion.IDENTITY
        }));


        let tilesets = this.getCesium3dTileset();
        tilesets.forEach(ts => {
            // console.log(ts)
            // console.log(ts._clippingPlanes.modelMatrix)
            // console.log(ts._clippingPlanesOriginMatrix)
            // console.log(ts._modelMatrix)
            //
            // ts._clippingPlanesOriginMatrix = ts._clippingPlanes.modelMatrix;
            if (ts._clippingPlanes === undefined) {

                ts._clippingPlanes = Cesium.clone(tilesetClippingPlane);
            }
        });
    }

    // moveCameraToCrossSection(params = {}) {
    //
    //     let points = this.linePoints;
    //     // from setProfileWidth func or handleShowHeightProfile func
    //     let width = params.width ? params.width : this.width;
    //
    //     let globe = this._viewer.scene.globe;
    //     let tileset = this._tileset;
    //
    //     let pointA = points[0];
    //     let pointB = points[1];
    //
    //     //Calculate CenterPositon and Distance between the two points
    //     let centerPosition = Cesium.Cartesian3.midpoint(pointA, pointB, new Cesium.Cartesian3());
    //     // let distance = Cesium.Cartesian3.distance(pointA, pointB);
    //
    //     let boundingSphere = new Cesium.BoundingSphere(centerPosition, 0);
    //
    //     let angleOffset = Cesium.Math.toRadians(this._degrees);
    //     let planeRotation1 = angleOffset;
    //     let planeRotation2 = angleOffset + Cesium.Math.toRadians(90);
    //
    //
    //     //why hypotenuse/2?
    //     // why _opposite?
    //     let terrainClippingPlane = new Cesium.ClippingPlaneCollection({
    //         planes: [
    //             new Cesium.ClippingPlane(
    //                 new Cesium.Cartesian3(
    //                     Math.cos(planeRotation1),
    //                     Math.sin(planeRotation1),
    //                     0.0
    //                 ),
    //                 this._hypotenuse / 2
    //             ),
    //             new Cesium.ClippingPlane(
    //                 new Cesium.Cartesian3(-Math.cos(planeRotation1), -Math.sin(planeRotation1),
    //                     0.0
    //                 ),
    //                 this._hypotenuse / 2
    //             ),
    //             new Cesium.ClippingPlane(
    //                 new Cesium.Cartesian3(
    //                     Math.cos(planeRotation2),
    //                     Math.sin(planeRotation2),
    //                     0.0
    //                 ),
    //                 width
    //             ),
    //             new Cesium.ClippingPlane(
    //                 new Cesium.Cartesian3(-Math.cos(planeRotation2), -Math.sin(planeRotation2),
    //                     0.0
    //                 ),
    //                 width
    //             )
    //         ],
    //         enabled: true,
    //         unionClippingRegions: true,
    //         edgeWidth: 1.0,
    //         edgeColor: Cesium.Color.RED,
    //         modelMatrix: Cesium.Transforms.eastNorthUpToFixedFrame(centerPosition)
    //     });
    //
    //     /*centering of model matrix*/
    //     let inverseReference = Cesium.Matrix4.inverse(tileset.clippingPlanesOriginMatrix, new Cesium.Matrix4());
    //     let centerPositionMatrix = Cesium.Transforms.eastNorthUpToFixedFrame(
    //         centerPosition);
    //     let tilesetClippingMatrix = Cesium.Matrix4.multiply(
    //         inverseReference, centerPositionMatrix, new Cesium.Matrix4());
    //     /*end*/
    //
    //     /*Clone the Terrain Clipping and set the model Matrix of the Tileset*/
    //     let tilesetClippingPlane = Cesium.clone(terrainClippingPlane);
    //     tilesetClippingPlane.modelMatrix = tilesetClippingMatrix;
    //
    //     //Set the camera environment for cross section view and adds the clipping planes
    //     globe.backFaceCulling = false;
    //     globe.showSkirts = true;
    //     globe.clippingPlanes = terrainClippingPlane;
    //     tileset.clippingPlanes = tilesetClippingPlane;
    //
    //     let tilesets = this.getCesium3dTileset();
    //     tilesets.forEach(ts => {
    //         if (ts._clippingPlanes === undefined) {
    //             ts._clippingPlanes = tilesetClippingPlane;
    //         }
    //     });
    //
    //
    //     //Position the Camera
    //     // let heading = (this._degrees >= 0 ? this._degrees : 90 + this._degrees);
    //     // console.log('head', heading)
    //
    //     if (params.manual === undefined) {
    //         this._viewer.camera.viewBoundingSphere(
    //             boundingSphere,
    //             new Cesium.HeadingPitchRange(0, 0, 0)
    //         );
    //     }
    // }

    getCesium3dTileset() {
        const array = this._viewer.scene.primitives._primitives;

        if (array[0]) {
            const cesium3dTileset = array.filter(ts => {
                if (ts instanceof Cesium.Cesium3DTileFeature ||
                    ts instanceof Cesium.Cesium3DTileset ||
                    ts instanceof Cesium.Model
                ) return ts;
            });

            if (cesium3dTileset) {
                return cesium3dTileset;
            } else return new ReferenceError('Couldn\'t find Cesium3dTileset');
        }
    }


    _measureTwoPointsByRadians(point_rad1, point_rad2) {
        let startDegreesLon, startDegreesLat;
        startDegreesLon = turf.turf.radiansToDegrees(point_rad1.longitude);
        startDegreesLat = turf.turf.radiansToDegrees(point_rad1.latitude);
        let from = turf.turf.point([startDegreesLon, startDegreesLat]);
        let endDegreesLon = turf.turf.radiansToDegrees(point_rad2.longitude);
        let endDegreesLat = turf.turf.radiansToDegrees(point_rad2.latitude);
        let to = turf.turf.point([endDegreesLon, endDegreesLat]);
        let options = { units: 'meters' };
        return turf.turf.distance(from, to, options);
    }

    setProfileWidth(width) {
        this._terrainClippingPlane._planes[2].distance = width;
        this._terrainClippingPlane._planes[3].distance = width;
    }

    _computePerpendicular(positions) {

        let p1 = positions[0];
        let p2 = positions[1];

        let x1 = p1.x,
            y1 = p1.y;
        let x2 = p2.x,
            y2 = p2.y;

        let num_units = 10; //length of perpendicular line from original line
        let slope = (y2 - y1) / (x2 - x1);
        let dy = Math.sqrt(num_units ** 2 / (slope ** 2 + 1));
        let dx = -slope * dy;

        let x3 = x1 + dx;
        let y3 = y1 + dy;
        let x4 = x1 - dx;
        let y4 = y1 - dy;

        let x5 = x2 + dx;
        let y5 = y2 + dy;
        let x6 = x2 - dx;
        let y6 = y2 - dy;

        let p3 = {
            x: x3,
            y: y3,
            z: 0
        };

        let p4 = {
            x: x4,
            y: y4,
            z: 0
        };

        let p5 = {
            x: x5,
            y: y5,
            z: 0
        };

        let p6 = {
            x: x6,
            y: y6,
            z: 0
        };

        return [p1, p2, p3, p4, p5, p6];

    }
}