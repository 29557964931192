import Downloads from '@/models/Downloads'

const state = () => ({
    list: null,
    initialList: null,
    pid: null
})

// getters
const getters = {
    listDownloads(state) {
        return state.list
    },
    getDownload: (state) => (id) => {
        return state.list.find(i => i.id === id);
    },
}

// actions
const actions = {

    async loadDownloads(context, payload) {
        let query = await Downloads.list(payload);
        let data = query.data;
        return new Promise((resolve) => {
            context.commit('setDownloadList', data);
        })
    },

    clearDownloadList(context){
        context.commit( 'clear' );
    }

}

// mutations
const mutations = {

    setDownloadList(state, data) {
        //console.log("STATE",[state.initialList,state.pid,state.list])
        if (state.initialList != null && data != null){
            
            let new_pid = null;

            data.forEach( (n)=>{
                var isNew = true;
                state.initialList.forEach((o)=>{
                    if (n.id === o.id)
                        isNew = false;
                });
                if (state.pid != n.project_id || state.pid == null){
                    n.new =false
                    new_pid = n.project_id
                }
                else
                    n.new = isNew;
            });

            state.pid = new_pid == null ? state.pid : new_pid;

        }else if (data != null){
            
          
            data.forEach( (n)=>{
                n.new = false;
                state.pid = n.project_id
            });   
            state.initialList = data;         
        }

        state.list = data
    },

    clear(state){
        state.list = null;
        state.initialList = null;
    }

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}