import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, vShow as _vShow, normalizeClass as _normalizeClass, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col items-start text-left" }
const _hoisted_2 = { class: "flex justify-between mb-5 w-full" }
const _hoisted_3 = { class: "flex items-center select-none" }
const _hoisted_4 = { class: "flex items-center" }
const _hoisted_5 = ["id", "value", "checked"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["id"]
const _hoisted_8 = {
  key: 0,
  class: "absolute right-14"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ColorPicker = _resolveComponent("ColorPicker")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", null, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("input", {
            onClick: _cache[0] || (_cache[0] = $event => ($options.toggleEnableDisable($event))),
            type: "checkbox",
            class: "form-checkbox text-success-500 mr-2"
          }),
          _createElementVNode("small", null, _toDisplayString(_ctx.$t('enable_disable')), 1)
        ])
      ])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.classificationsList, (classification, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: `${index}`,
        class: "flex justify-between mb-2 w-full"
      }, [
        _createElementVNode("label", null, [
          _createElementVNode("div", _hoisted_4, [
            _withDirectives(_createElementVNode("input", {
              onClick: _cache[1] || (_cache[1] = (...args) => ($options.handleClassification && $options.handleClassification(...args))),
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.selected_class) = $event)),
              type: "checkbox",
              id: index,
              value: Number(index),
              class: "form-checkbox text-success-500 mr-2",
              checked: classification.checked
            }, null, 8, _hoisted_5), [
              [_vModelCheckbox, _ctx.selected_class]
            ]),
            _createTextVNode(" " + _toDisplayString(classification.class === 'Never Classified'
                        ? _ctx.$t('classification_type.never_classified')
                        : classification.class === 'Unclassified'
                            ? _ctx.$t('classification_type.unclassified')
                            : classification.class === 'Overlap'
                                ? _ctx.$t('classification_type.overlap')
                                : classification.class === 'Ground'
                                    ? _ctx.$t('classification_type.ground')
                                    : classification.class === 'Low Vegetation'
                                        ? _ctx.$t('classification_type.low_vegetation')
                                        : classification.class === 'Medium Vegetation'
                                            ? _ctx.$t('classification_type.medium_vegetation')
                                            : classification.class === 'High Vegetation'
                                                ? _ctx.$t('classification_type.high_vegetation')
                                                : classification.class === 'Building'
                                                    ? _ctx.$t('classification_type.building')
                                                    : classification.class === 'Low Point'
                                                        ? _ctx.$t('classification_type.low_point')
                                                        : classification.class === 'Key Point'
                                                            ? _ctx.$t('classification_type.key_point')
                                                            : classification.class === 'Water'
                                                                ? _ctx.$t('classification_type.water')
                                                                : classification.class === 'Wire'
                                                                    ? _ctx.$t('classification_type.wire')
                                                                    : classification.class === 'Car/Pedestrian'
                                                                        ? _ctx.$t('classification_type.car_pedestrian')
                                                                        : ''), 1)
          ])
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("button", {
            class: "flex items-center pounded-full",
            onClick: $event => ($options.toggleColorPicker(index, classification))
          }, [
            _createElementVNode("div", {
              class: "w-6 h-6 rounded-full",
              id: 'color_' + index,
              style: _normalizeStyle({ 'background-color': classification.color })
            }, null, 12, _hoisted_7)
          ], 8, _hoisted_6),
          ($data.selectedInput === index)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _withDirectives(_createVNode(_component_ColorPicker, {
                  class: _normalizeClass(["", $data.selectedInput > 2 ? '-mt-96' : '-mt-2']),
                  theme: "dark",
                  color: classification.color,
                  "sucker-hide": true,
                  "sucker-canvas": $data.suckerCanvas,
                  "sucker-area": $data.suckerArea,
                  onChangeColor: $options.changeColor,
                  style: {"width":"217px !important"}
                }, null, 8, ["class", "color", "sucker-canvas", "sucker-area", "onChangeColor"]), [
                  [_vShow, $data.isColorPicker]
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ]))
    }), 128))
  ]))
}