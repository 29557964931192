// Uses axios and Promises
// Everything that we need to communicate with the api will be declared here
import api from '@/providers/api'

const downloads = {

    list(id) {
        return api.get(`downloads/list/${id}`);
    },

    process(payload) {
        return api.post(`downloads/process`, payload);
    },

    download(token,password = null) {
        return api.get(`downloads/file/${token}?password=${password}`);
    },

    delete(id) {
        return api.get(`downloads/delete/${id}`);
    }

}

export default downloads