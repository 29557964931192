
import ProjectResources from '@/models/ProjectResources';

const state = () => ({
    list: [],
    entities: []
})
  
// getters
const getters = {
    listModels (state) {
        return state.list
    },
    getEntities (state, payload) {
        return state.entities;
    },
    getModelEntity: (state) => (model_id) =>  {
        return state.entities.find(i => i.model_id === model_id)
    }
}

// actions
const actions = {
    async loadModels(context, payload ) {
        let query = await ProjectResources.models(payload.id);
        let data = query.data.data
        return new Promise((resolve) => {
            context.commit( 'setModelsList', data);
            resolve(data)
        })
    },

    setModelEntity(context, payload) {
        context.commit( 'addEntity', payload )
    },

    addModels(context, payload) {
        if ( payload.models.length > 0 ) {
            payload.models.forEach(m => {
                context.commit( 'addModel', m );
            })
        } else {
            if (payload.models.model_id != undefined) {
                context.commit( 'addModel', payload.models );
            }
        }
    },

    async updateCoordinates(context, payload ) {
        let result = await ProjectResources.updateCoordinates(payload);
        return new Promise((resolve) => {
            //context
            resolve(result)
        })
    },

    async updateModel(context, payload ) {
        let result = await ProjectResources.updateModel(payload);
        return new Promise((resolve) => {
            //context
            resolve(result)
        })
    },

    clearModelsList(context){
        context.commit( 'clear' );
    }

}

// mutations
const mutations = {
    setModelsList(state, data) {
        if ( data.length > 0 ) {
            data.forEach(item => {
                item.entity = null;
            })
        }
        state.list = data
    },
    setVisibility(state, data) {
        if (data.type === 'gltf' ||
            data.type === 'obj' ||
            data.type === 'xml' ||
            data.type === 'dxf') {
                state.list.forEach(i => {
                    if ( i.model_id === data.model_id ) {
                        i.visible = data.visible
                    }
                });
        }
    },
    addEntity(state, data) {
        // Check if model has entity in store
        let check = state.entities.find(i => i.model_id === data.model_id )
        if ( !check ) {
            state.entities.push(data)
        } else {
            // Update entity_id if there is an update
            state.entities.map(i => {
                if ( i.model_id === data.model_id ) {
                    i.entity_id = data.entity_id
                }
            });
        }
        
    },

    addModel(state, data) {
        state.list.push( data );
    },

    clear(state){
        state.list = [];
        state.entities = [];
        //console.log("models cleared",state);
    }
}
  
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}