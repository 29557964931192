import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fixed inset-0 z-50 flex justify-center items-center" }
const _hoisted_2 = { class: "flex flex-col max-w-5xl rounded-lg w-full shadow-lg bg-white" }
const _hoisted_3 = { class: "modal-content" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ]))
}