import api from '@/providers/api'

const url_generator = {

    get() {
        return api.get(`admin/url_generator/signup/url`)
    },

    generate() {
        return api.post(`admin/url_generator/generate/signup/url`)
    },
}

export default url_generator