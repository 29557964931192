
// Locale
import { createI18n } from 'vue-i18n'

import en from './en.json'
import ja from './ja.json'

const i18n = createI18n({
    legacy: true, 
    locale: 'ja', 
    fallbackLocale: 'en', 
    globalInjection: true,
    messages: {
        'en': en,
        'ja': ja
    }
  })

export default i18n