import * as Cesium from '@/sdk/cesium';
import convert_xyz from '@/components/ui/panel/properties/library';
import MeasurementSnapping from './MeasurementSnapping';
import { ScanxMeasure } from '@/third-party/CesiumMeasure';

export class AdjustableMeasurements {

    constructor(viewer, $ref, selectedEpsg) {
        this.viewer = viewer;
        this.$ref = $ref;
        this.selectedEpsg = selectedEpsg;
        this.leftDownFlag = false;
        this.pointDraged = null;
        this.previousPosition = null;
        this.entityList = [];
        this.handler = null;
        this.selectedMeasurement = null;
        this.enableMeasureAdjustments = true;
        this._hoveredPoint = null;
        this.types = {
            1: 'point',
            2: 'line',
            3: 'area',
            4: 'height',
            5: 'profile',
            6: 'annotation',
            7: 'angle',
            8: 'volume'
        };
        this.measure = new ScanxMeasure(this.viewer, { selectedEpsg: this.selectedEpsg, cesiumComponent: this });

        this.initHandler();

    }

    initHandler() {

        this.handler = new Cesium.ScreenSpaceEventHandler(
            this.viewer.canvas
        );
        let pointDraged = this.pointDraged;
        let leftDownFlag = this.leftDownFlag;
        let entityList = this.entityList;
        let previousPosition = this.previousPosition;
        let $ref = this.$ref;

        this.handler.setInputAction((event) => {
            
            //if cesium widget is not initiated do not run event
            if (!this.viewer._cesiumWidget){
                return;
            }
            /**
             * Fix for Sentry Errors: 
             * https://skaenx.sentry.io/share/issue/2fb10c007ebb4e3d83ad58c886a0eb46/
             * https://skaenx.sentry.io/share/issue/b722a9fde05e4fec89c717448a540cbd/
             * Details: Added try-catch to check if no error is found in pickPosition fx
            */ 
            let newPosition = null;
            try {
                newPosition = this.viewer.scene.pickPosition(
                    event.endPosition
                );
            } catch (error) {
                console.log("Error on pickPosition!", event);
            } 
            
            let picks = [];
            try {
                if (event) {
                    picks = this.viewer.scene.drillPick(event.endPosition, 10, 1, 1);
                }
            } catch (error) {
                console.log("Invalid coordinates value!", event);
            }

            //Handler for hover of snappable points
            if (pointDraged !== null & pointDraged !== undefined) { //Only listen when there is a point dragged
                //check if entity exist
                if (pointDraged.id  !== null && pointDraged.id  !== undefined) {
                    //Make Sure Listener is only when dragging a non snappable point
                    if (pointDraged.id instanceof Object && !Object.keys(pointDraged.id).includes('snappablePoint')) {
                        MeasurementSnapping.mouseMoveSnapToPointListener(event.endPosition, this.viewer);
                    }
                }
            } else { //If no point is dragged clear Hover State
                MeasurementSnapping.clearHoveredPoint();
            }


            var isOn3dtiles = false;
            // drillPick
            for (let i in picks) {
                let pick = picks[i];

                if (pick &&
                    pick.primitive instanceof Cesium.Cesium3DTileFeature ||
                    pick && pick.primitive instanceof Cesium.Cesium3DTileset ||
                    pick && pick.primitive instanceof Cesium.Model) { //模型上拾取
                    isOn3dtiles = true;
                }
            }

            if (Cesium.defined(newPosition) && newPosition !== null) {
                var cartographic =
                    Cesium.Cartographic.fromCartesian(newPosition);
                var _pos = {
                    lon: Cesium.Math.toDegrees(
                        cartographic.longitude
                    ).toFixed(9),
                    lat: Cesium.Math.toDegrees(cartographic.latitude).toFixed(
                        9
                    ),
                    h: cartographic.height.toFixed(6)
                };

                $ref.$emit('on-mouse-move', _pos);
            }

            if (leftDownFlag === true && pointDraged != null && this.enableMeasureAdjustments) {


                if (Cesium.defined(pointDraged.id) && Cesium.defined(pointDraged.id.position) && pointDraged.id.allowMovement != false) {


                    let oldPosition = pointDraged.id.position.getValue(this.viewer.clock.currentTime);
                    //ensures oldPosition is a cartesian object
                    oldPosition = new Cesium.Cartesian3(oldPosition.x, oldPosition.y, oldPosition.z);

                    // Retrieve Cartesian3 position from pixel coordinates
                    let newPosition = this.getCartesian3FromPX(event.endPosition);

                    // Create a new Cartesian3 instance based on the newPosition
                    newPosition = new Cesium.Cartesian3(newPosition.x, newPosition.y, newPosition.z);

                    // Check if there is a hoveredPointPosition and update newPosition accordingly
                    const hoveredPointPosition = MeasurementSnapping.getHoveredPointPosition();
                    if (hoveredPointPosition) {
                        newPosition = hoveredPointPosition;
                    }

                    $ref.toggleGrabbableCursor(false);
                    $ref.toggleMoveCursor(true);

                    //Update only if globe is Enabled or If Point is On 3d Tile
                    if (this.selectedMeasurement != null) {
                        if ((this.viewer.scene.globe.show || isOn3dtiles) && !newPosition.equals(Cesium.Cartesian3.ZERO)) {

                            const parent_id = (pointDraged.id.parent_id != undefined) ? pointDraged.id.parent_id : undefined;
                            this._updateEntityPositions(oldPosition.clone(), newPosition.clone(), entityList, parent_id);

                            if (this.types[this.selectedMeasurement.measurement_id] != 'area' && this.types[this.selectedMeasurement.measurement_id] != 'volume' && pointDraged.id.nonMovable !== true) {
                                pointDraged.id.position = new Cesium.CallbackProperty(function () {
                                    return newPosition.clone();
                                }, false); //Prevent flicker, in the process of moving
                            }
                        }
                    } else {
                        if (!newPosition.equals(Cesium.Cartesian3.ZERO)) {
                            this._updateEntityPositions(oldPosition.clone(), newPosition.clone(), entityList, pointDraged.id.mc_parent);
                            pointDraged.id.position = new Cesium.CallbackProperty(function () {
                                return newPosition.clone();
                            }, false); //Prevent flicker, in the process of moving
                        }
                    }
                }
            } else {
                let pointHovered = this.viewer.scene.pick(event.endPosition);
                if (!pointDraged && !leftDownFlag && Cesium.defined(pointHovered) && Cesium.defined(pointHovered.id && Cesium.defined(pointHovered.id.id))) {
                    $ref.toggleGrabbableCursor(true);
                    $ref.toggleMoveCursor(false);
                } else {
                    $ref.toggleGrabbableCursor(false);
                    if (pointDraged != null && leftDownFlag) {
                        $ref.toggleMoveCursor(true);
                    }
                }

            }
        }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

        this.handler.setInputAction(event => {

            //if cesium widget is not initiated do not run event
            if (!this.viewer._cesiumWidget){
                return;
            }
            
            pointDraged = this.viewer.scene.pick(event.position); //Select the current entity 
            if (Cesium.defined(pointDraged) && Cesium.defined(pointDraged.id) && this.enableMeasureAdjustments && pointDraged.id._id !== "planeEntityForCrossSection") {

                leftDownFlag = true;
                if (pointDraged.primitive instanceof Cesium.PointPrimitive ||
                    pointDraged.primitive instanceof Cesium.Label) {
                    this.viewer.scene.screenSpaceCameraController.enableRotate = false; //Lock the camera
                }
                var displayedEntities = this.viewer.dataSources.getByName('measureLayer')[0]._entityCollection._entities._array;
                var measurements = $ref.projectFiles.measurements;
                var point = pointDraged.id;
                entityList = []; //reset entityList
                measurements.forEach((data) => {
                    //find where the measurement belong to
                    var entities = data.viewer_id;
                    var foundMatch = false;


                    //check first if pointDraged belongs to this measurement
                    if (entities) {
                        entities.forEach((id) => {
                            if (id == point.id) {
                                foundMatch = true;
                            }
                        });
                    }
                    //if true create the list of measurements and its data
                    if (foundMatch) {
                        $ref.toggleMoveCursor(true);
                        data.viewer_id.forEach((id) => {
                            displayedEntities.forEach((entity) => {
                                if (entity.id == id && !entityList.includes(id)) {
                                    entityList.push({
                                        id: id,
                                        data: entity,
                                        positions: data.positions
                                    });
                                }
                            });
                        });
                        this.selectedMeasurement = data;
                        if (Cesium.defined(point.position) && point.allowMovement !== false) {
                            previousPosition = point.position.getValue(this.viewer.clock.currentTime);
                            previousPosition = new Cesium.Cartesian3(previousPosition.x, previousPosition.y, previousPosition.z);
                            let tmpData = JSON.parse(this.selectedMeasurement.data);
                            //if height check if selected point is height point
                            if (this.types[this.selectedMeasurement.measurement_id] == 'height') {
                                let disabledPoint = new Cesium.Cartesian3(tmpData.e3[1].x, tmpData.e3[1].y, tmpData.e3[1].z);
                                if (previousPosition.equals(disabledPoint)) {
                                    this._resetAdjustMeasurements();
                                }
                            } else if (this.types[this.selectedMeasurement.measurement_id] == 'angle') {
                                let disabledPoint = new Cesium.Cartesian3(tmpData.e2[1].x, tmpData.e2[1].y, tmpData.e2[1].z);
                                if (previousPosition.equals(disabledPoint)) {
                                    this._resetAdjustMeasurements();
                                }
                            }
                        }
                    }
                });

                //Update Property Panel to populate the selected entiy
                if (this.selectedMeasurement) {
                    $ref.$emit("on-save-measure", {
                        id: this.selectedMeasurement.project_measurement_id,
                        data: this.selectedMeasurement.data
                    });
                }

            }


        }, Cesium.ScreenSpaceEventType.LEFT_DOWN);

        //Release plane on mouse up
        this.handler.setInputAction(event => {

            //if cesium widget is not initiated do not run event
            if (!this.viewer._cesiumWidget){
                return;
            }

            leftDownFlag = false;
            //Save and Recalculation here
            $ref.toggleMoveCursor(false);
            $ref.toggleGrabbableCursor(false);
            if (Cesium.defined(pointDraged) && this.selectedMeasurement != undefined) {
                $ref.$emit("on-save-measure", {
                    id: this.selectedMeasurement.project_measurement_id,
                    data: this.selectedMeasurement.data
                });
            }
            //Reset Measurement Flags upon release
            pointDraged = null;
            this._resetAdjustMeasurements();
            this.viewer.scene.screenSpaceCameraController.enableRotate = true;
        }, Cesium.ScreenSpaceEventType.LEFT_UP);


    }

    //Helper functions
    enabled(val) {
        this.enableMeasureAdjustments = val;
    }

    _resetAdjustMeasurements() {
        this.pointDraged = null;
        this.entityList = [];
        this.selectedMeasurement = null;
        this.leftDownFlag = false;
    }

    _updateEntityPositions(oldPosition, newPosition, entities, parent_ent_id = undefined) {
        let currentTime = this.viewer.clock.currentTime;
        let displayedEntities = this.viewer.dataSources.getByName('measureLayer')[0]._entityCollection._entities._array;
        if (this.selectedMeasurement == null) {
            if (parent_ent_id !== undefined) {
                let areaPoints = [], pointList = [], entity_mc = {};
                displayedEntities.forEach((entity) => {
                    if (entity.id == parent_ent_id) {
                        entity_mc = entity
                        areaPoints = entity.areaPositions;
                    }
                    if (entity.mc_parent == parent_ent_id) {
                        pointList.push(entity);
                    }
                });
                let newPositions = [];
                pointList.forEach((point) => {
                    //update positions on viewer
                    let position = point.position.getValue(currentTime);
                    if (oldPosition.equals(new Cesium.Cartesian3(position.x, position.y, position.z))) {
                        point.position = newPosition;
                        newPositions.push(newPosition);
                    } else {
                        newPositions.push(new Cesium.Cartesian3(position.x, position.y, position.z));
                    }

                });

                for (let i = 0; i < areaPoints.length; i++) {
                    if (Cesium.defined(newPositions[i]) && Cesium.defined(areaPoints[i])) {
                        areaPoints[i].x = newPositions[i].x;
                        areaPoints[i].y = newPositions[i].y;
                        areaPoints[i].z = newPositions[i].z;
                    }
                    entity_mc.polygon.hierarchy = areaPoints
                    entity_mc.polyline.positions = areaPoints
                }
            }
            return
        }

        let positionsList = [];
        let selectedMeasurement = this.selectedMeasurement;
        let data = JSON.parse(selectedMeasurement.data);
        let measurementType = this.types[this.selectedMeasurement.measurement_id];

        let updateNewPosition = (position) => {
            oldPosition = new Cesium.Cartesian3(oldPosition.x, oldPosition.y, oldPosition.z);
            if (oldPosition.equals(new Cesium.Cartesian3(position.x, position.y, position.z))) {
                position.x = newPosition.x;
                position.y = newPosition.y;
                position.z = newPosition.z;
            }

        }

        switch (measurementType) {

            case 'profile':
                {
                    let positions = null;

                    entities.forEach((entity) => {
                        if (Cesium.defined(entity.data.polyline)) {
                            positions = entity.data.polyline.positions.getValue(currentTime);
                        }
                    });

                    positions.forEach(updateNewPosition);

                    let horizontalPosition = this.computesHorizontalLine(positions)
                    data.e = positions;
                    //reset e2
                    data.e2 = [];
                    data.e2.push(positions[0]);
                    data.e2.push(horizontalPosition);
                    //reset e3
                    data.e3 = [];
                    data.e3.push(positions[1]);
                    data.e3.push(horizontalPosition);

                }
                break;
            case 'point': {
                data = newPosition;
                entities.forEach((entity) => {
                    entity.data.originalXYZ = newPosition;
                    if (entity.data.secondLabel !== undefined) {
                        let _pos = convert_xyz(newPosition, this.selectedEpsg, 'point');

                        entity.data.label.text = new Cesium.CallbackProperty(function () {
                            let caption = 'X: ' + _pos.x + '\n' + 'Y: ' + _pos.y + '\n' + 'Z: ' + _pos.z;
                            return caption;
                        }, false);
                        entity.data.secondLabel.position = new Cesium.CallbackProperty(function () {
                            return newPosition;
                        }, false); //Prevent flicker, in the process of moving
                    }
                });
            }
                break;
            case 'annotation':
                {
                    data = newPosition;
                }
                break;
            case 'line':
                {
                    let pointList = [], entity_line = []
                    //console.log(displayedEntities)
                    //loop through all the entities and find the parent polyline entity
                    displayedEntities.forEach((entity) => {
                        // looking for parent entity
                        if (entity.entity_id == parent_ent_id) {
                            entity_line = entity
                        }
                        // looking point entity
                        if (entity.parent_id == parent_ent_id) {
                            pointList.push(entity);
                        }
                    });

                    let newPositions = [];
                    // looping through all the point values
                    let compute2point = false;
                    pointList.forEach((point, key) => {
                        //update positions on viewer
                        const position = point.position.getValue(currentTime);
                        if (oldPosition.equals(new Cesium.Cartesian3(position.x, position.y, position.z))) {
                            point.position = newPosition;
                            newPositions.push(newPosition);
                            // redundant code; can be shortened using the code in line 448-453 
                            // if (pointList.length > 1) {
                            //     if (key == 0) {
                            //         compute2point = true
                            //     } else {
                            //         point.label.text = ((this.measure.getPositionDistance(
                            //             this.measure.transformCartesianArrayToWGS84Array(newPositions)) / 1000) * 1000)
                            //             .toFixed(3) + ' m'
                            //     }
                            // }
                        } else {
                            newPositions.push(new Cesium.Cartesian3(position.x, position.y, position.z));
                        }
                        // redundant code; can be shortened using the code in line 448-453
                        // if (compute2point && key == 1) {
                        //     point.label.text = ((this.measure.getPositionDistance(
                        //         this.measure.transformCartesianArrayToWGS84Array(newPositions)) / 1000) * 1000)
                        //         .toFixed(3) + ' m'
                        // }
                        //console.log(position)
                    })

                    // set new positions to the polyline entity
                    entity_line.polyline.positions = newPositions;

                    // realtime update for all point label when one point is adjusted
                    for (let i = newPositions.length - 1; i > 0; i--) {
                        let tempList = newPositions.slice(0, i + 1);
                        pointList[i].label.text = ((this.measure.getPositionDistance(
                            this.measure.transformCartesianArrayToWGS84Array(tempList)) / 1000) * 1000)
                            .toFixed(3) + ' m';
                    }

                    // entities.forEach((entity) => {
                    //     if (Cesium.defined(entity.data.polyline)) {
                    //         positionsList.push(entity.data.polyline.positions.getValue(currentTime));
                    //     }
                    // });

                    // console.log(positionsList)


                    // positionsList.forEach((positions) => {
                    //     //update positions on viewer
                    //     positions.forEach(updateNewPosition);
                    // });

                    // save new positions
                    data = newPositions;
                    
                    // realtime update of right panel result
                    this.$ref.compRef.propertyRef.$emit('onCalculations', {
                        type: 'line',
                        positions: newPositions
                    });
                }
                break;

            case 'height':
            case 'angle': {
                let hypotenusePoints = data.e;
                let oppositePoints = data.e2;
                let adjacentPoints = data.e3;
                let entityLines = [], entityIds = [];
                let hasMatchPoints = false;
                //find first the move point
                let oldHypotenusePoints = hypotenusePoints.map((p) => {
                    return this.toCartesian3(p);
                });

                if (oldPosition.equals(hypotenusePoints[0])) {
                    this.copyCartersianCoordinates(newPosition, hypotenusePoints[0])
                    hasMatchPoints = true;
                } else if (oldPosition.equals(hypotenusePoints[1])) {
                    this.copyCartersianCoordinates(newPosition, hypotenusePoints[1]);
                    hasMatchPoints = true;
                }

                entities.forEach((entity) => {
                    if (Cesium.defined(entity.data.polyline)) {
                        entityLines.push(entity.data.polyline.positions.getValue(currentTime));
                        entityIds.push(entity.id);
                    }
                });

                if (hasMatchPoints) {

                    //Get Point C
                    let inverseHorizontalLine = measurementType == 'height' ? true : false;
                    let trianglePointC = this.computesHorizontalLine(hypotenusePoints, inverseHorizontalLine);

                    //Lines
                    entityLines.forEach((positions) => {

                        positions[0] = this.toCartesian3(positions[0]);
                        positions[1] = this.toCartesian3(positions[1]);

                        //Adjust Hypotenuse Line
                        if (positions[0].equals(oldHypotenusePoints[0])
                            && positions[1].equals(oldHypotenusePoints[1])) {
                            this.copyCartersianCoordinates(hypotenusePoints[0], positions[0]);
                            this.copyCartersianCoordinates(hypotenusePoints[1], positions[1]);
                        }
                        //Adjust Opposite Line
                        else if (positions[0].equals(this.toCartesian3(oppositePoints[0]))
                            && positions[1].equals(this.toCartesian3(oppositePoints[1]))) {

                            this.copyCartersianCoordinates(hypotenusePoints[0], oppositePoints[0]);
                            this.copyCartersianCoordinates(trianglePointC, oppositePoints[1]);

                            this.copyCartersianCoordinates(oppositePoints[0], positions[0]);
                            this.copyCartersianCoordinates(oppositePoints[1], positions[1]);
                        }
                        //Adjust Adjacent Line
                        else if (positions[0].equals(this.toCartesian3(adjacentPoints[0]))
                            && positions[1].equals(this.toCartesian3(adjacentPoints[1]))) {


                            this.copyCartersianCoordinates(hypotenusePoints[1], adjacentPoints[0]);
                            this.copyCartersianCoordinates(trianglePointC, adjacentPoints[1]);

                            this.copyCartersianCoordinates(adjacentPoints[0], positions[0]);
                            this.copyCartersianCoordinates(adjacentPoints[1], positions[1]);
                        }

                    });
                    // realtime update of right panel result
                    measurementType == 'height' ?
                        this.$ref.compRef.propertyRef.$emit('onCalculations', {
                            type: 'height',
                            positions: { e: hypotenusePoints, e2: oppositePoints, e3: adjacentPoints, id: entityIds }
                        }) : this.$ref.compRef.propertyRef.$emit('onCalculations', {
                            type: 'angle',
                            positions: { e: hypotenusePoints, e2: oppositePoints, e3: adjacentPoints, id: entityIds }
                        });

                }

            }

                break;
            case 'area':
                {
                    let areaPoints = [], pointList = [];
                    entities.forEach((entity) => {
                        if (Cesium.defined(entity.data.polygon)) {
                            areaPoints = entity.data.areaPositions;
                        }
                        if (Cesium.defined(entity.data.point)) {
                            pointList.push(entity.data);
                        }
                    });

                    let newPositions = [], pointWithLabel;
                    pointList.forEach((point) => {
                        //update positions on viewer
                        let position = point.position.getValue(currentTime);
                        if (oldPosition.equals(new Cesium.Cartesian3(position.x, position.y, position.z))) {
                            point.position = newPosition;
                            newPositions.push(newPosition);
                        } else {
                            newPositions.push(new Cesium.Cartesian3(position.x, position.y, position.z));
                        }
                        if (point.label) {
                            pointWithLabel = point;
                        }
                    });

                    for (let i = 0; i <= areaPoints.length; i++) {
                        if (Cesium.defined(newPositions[i]) && Cesium.defined(areaPoints[i])) {
                            areaPoints[i].x = newPositions[i].x;
                            areaPoints[i].y = newPositions[i].y;
                            areaPoints[i].z = newPositions[i].z;
                        }
                    }
                    data = newPositions;
                    // update area label
                    let area = this.measure.getAreaCalculation(newPositions);
                    pointWithLabel.label.text = new Cesium.CallbackProperty(function () {
                        return area;
                    }, false);
                    // update right panel area result
                    this.$ref.compRef.propertyRef.$emit('onCalculations', {
                        type: 'area',
                        positions: newPositions
                    });

                }
                break;

        }


        //updates selected measurement data
        selectedMeasurement.data = JSON.stringify(data);

    }

    getCartesian3FromPX(px) {
        return this.measure.getCartesian3FromPX(px)
    }

    transformCartesianToWGS84(cartesian) {
        if (this.viewer && cartesian) {
            var ellipsoid = Cesium.Ellipsoid.WGS84;
            var cartographic = ellipsoid.cartesianToCartographic(cartesian);
            return {
                lng: Cesium.Math.toDegrees(cartographic.longitude),
                lat: Cesium.Math.toDegrees(cartographic.latitude),
                alt: cartographic.height
            };
        }
    }
    transformWGS84ToCartesian(position, alt) {
        if (this.viewer) {
            return position ?
                Cesium.Cartesian3.fromDegrees(
                    position.lng || position.lon,
                    position.lat,
                    position.alt = alt || position.alt,
                    Cesium.Ellipsoid.WGS84
                ) :
                Cesium.Cartesian3.ZERO;
        }
    }

    computesHorizontalLine(positions, inverse = false) {
        let cartographic = Cesium.Cartographic.fromCartesian(positions[0]);
        let cartographic2 = Cesium.Cartographic.fromCartesian(positions[1]);
        let result = null;

        if (inverse) {
            if (cartographic.height > cartographic2.height) {
                result = Cesium.Cartesian3.fromDegrees(
                    Cesium.Math.toDegrees(cartographic2.longitude),
                    Cesium.Math.toDegrees(cartographic2.latitude),
                    cartographic.height
                );
            } else {
                result = Cesium.Cartesian3.fromDegrees(
                    Cesium.Math.toDegrees(cartographic.longitude),
                    Cesium.Math.toDegrees(cartographic.latitude),
                    cartographic2.height
                );
            }
        } else {
            if (cartographic.height > cartographic2.height) {
                result = Cesium.Cartesian3.fromDegrees(
                    Cesium.Math.toDegrees(cartographic.longitude),
                    Cesium.Math.toDegrees(cartographic.latitude),
                    cartographic2.height
                );
            } else {
                result = Cesium.Cartesian3.fromDegrees(
                    Cesium.Math.toDegrees(cartographic2.longitude),
                    Cesium.Math.toDegrees(cartographic2.latitude),
                    cartographic.height
                );
            }
        }



        return result;
    }

    toCartesian3(point) {
        return new Cesium.Cartesian3(Number(point.x), Number(point.y), Number(point.z));
    }

    copyCartersianCoordinates(source, target) {
        target.x = Number(source.x);
        target.y = Number(source.y);
        target.z = Number(source.z);
    }

    setSelectedEpsg(val) {
        this.selectedEpsg = val;
    }

    centerText(text, numberOfSpaces) {
        text = text.trim();
        var l = text.length;
        var w2 = Math.ceil(numberOfSpaces / 2);
        var l2 = Math.ceil(l / 2);
        var padding = " ".repeat(Math.max(0, w2 - l2));
        text = padding + text + padding;

        if (text.length < numberOfSpaces) {
            text += " ".repeat(numberOfSpaces - text.length);
        }

        return text;
    }

}