/**
 * TransformEditor Package
 *
 * Integrates TransformEditor import features
 */

import config from '@/config';
import * as Cesium from '@/sdk/cesium';

export default {
    tileset: null,
    boundingSphere: null,
    initialPosition: null,
    initialHeadingPitchRoll: null,
    initialScale: null,
    transformEditor: null,
    activate(params) {
        console.log('editor activate', params)
        this.tileset = params.model
        let tileset = this.tileset;
        console.log("isFaceModel", tileset);
        //this.tileset.readyPromise.then((tileset) => {
            /* console.log(tileset)
            console.log(tileset.modelMatrix) */
            this.boundingSphere = tileset.boundingSphere.center
            // var rootTransform = tileset.root.transform;
            // var modelMatrix = tileset.modelMatrix;

            // Transfer root transform to the model matrix
            //Cesium.Matrix4.clone(rootTransform, modelMatrix);
            //Cesium.Matrix4.clone(Cesium.Matrix4.IDENTITY, rootTransform);
            //this.viewer.zoomTo(tileset);

            this.transformEditor = new Cesium.TransformEditor({
                container: params.viewer.container,
                scene: params.viewer.scene,
                transform: tileset.modelMatrix,
                boundingSphere: tileset.boundingSphere,
                pixelSize: 100,
                maximumSizeInMeters: Infinity,
            });

            this.transformEditor.viewModel.activate();

            this.initialPosition = Cesium.Cartesian3.clone(
                this.transformEditor.viewModel.position
            );
            this.initialHeadingPitchRoll = Cesium.HeadingPitchRoll.clone(
                this.transformEditor.viewModel.headingPitchRoll
            );
            this.initialScale = Cesium.Cartesian3.clone(
                this.transformEditor.viewModel.scale
            );
/*             console.log(this.transformEditor)
            console.log(this.tileset) */
       // });
    },
    activate2() {
        this.transformEditor.viewModel.activate()
    },
    destroy() {
        //Deactivate only 
        //this.transformEditor.viewModel.deactivate()
        //Destroy 
        this.transformEditor.destroy()
    },
    reset() {
        this.tileset.boundingSphere.center = this.boundingSphere
        this.transformEditor.viewModel.position = this.initialPosition;
        this.transformEditor.viewModel.headingPitchRoll = this.initialHeadingPitchRoll;
        this.transformEditor.viewModel.scale = this.initialScale;
        this.transformEditor.viewModel.originOffset = Cesium.Cartesian3.ZERO;
    },
    getModelProperties(){
        /* console.log(this.tileset)
        console.log(this.tileset.modelMatrix) */
        return {
            position: this.transformEditor.viewModel.position,
            rotation: this.transformEditor.viewModel.headingPitchRoll,
            scale: this.transformEditor.viewModel.scale,
            matrix: this.tileset.modelMatrix
        }
    }
}