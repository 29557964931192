import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(), _createElementBlock("svg", {
      width: $props.width,
      height: $props.height,
      viewBox: "0 0 18 19",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg"
    }, [
      _createElementVNode("path", {
        d: "M9.0001 7.8001C10.9883 7.8001 12.6001 6.18832 12.6001 4.2001C12.6001 2.21187 10.9883 0.600098 9.0001 0.600098C7.01187 0.600098 5.4001 2.21187 5.4001 4.2001C5.4001 6.18832 7.01187 7.8001 9.0001 7.8001Z",
        fill: $props.color
      }, null, 8, _hoisted_2),
      _createElementVNode("path", {
        d: "M0.600098 18.6001C0.600098 13.9609 4.3609 10.2001 9.0001 10.2001C13.6393 10.2001 17.4001 13.9609 17.4001 18.6001H0.600098Z",
        fill: $props.color
      }, null, 8, _hoisted_3)
    ], 8, _hoisted_1))
  ]))
}