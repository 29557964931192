import { vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-96490bcc"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["type", "name", "placeholder", "disabled"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'dark': $props.dark }, "w-full"])
  }, [
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.field) = $event)),
      type: $props.inputType,
      name: $setup.field,
      placeholder: $props.placeholder,
      class: _normalizeClass($props.baseClass + ' ' + $props.className + ' ' + ($props.dark && $props.darkClass) + ' ' + ($props.defaultWidth ? $props.defaultWidthClass : '') + ' ' + (($props.disabled) ? 'bg-neutral-200' : '')),
      disabled: $props.disabled,
      onFocus: _cache[1] || (_cache[1] = $event => (_ctx.$emit('focus'))),
      onBlur: _cache[2] || (_cache[2] = $event => (_ctx.$emit('blur')))
    }, null, 42, _hoisted_1), [
      [_vModelDynamic, $setup.field]
    ])
  ], 2))
}