import * as Cesium from '@/sdk/cesium';
/**
 * Event handler to allow only numbers and a decimal point.
 * @param {Event} evt - The event object.
 * @returns {boolean} Returns true if the input is allowed, otherwise false.
 */
export function numbersAndDecimalInputHandler(evt) {
    evt = evt || window.event;
    const charCode = evt.which || evt.keyCode;
    if ((charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
    } else {
        return true;
    }
}

/**
 * Validates and limits the value within the specified range.
 * @param {number} min - The minimum value of the range.
 * @param {number} max - The maximum value of the range.
 * @param {Event} event - The event object.
 * @param {Function} callback - The callback function to handle the updated value.
 */
export function validateAndLimitValueInRange(min, max, event, callback) {
    let value = event.target.value;
  
    min = parseFloat(min);
    max = parseFloat(max);

    if (isNaN(value)) {
        value = min; // Fallback value
    } else {
        if (value < min) {
            value = min;
        } else if (value > max && max !== Infinity) {
            value = max;
        }
    }

    event.target.value = Number(value);
    callback(value);
}

export function toDegrees(cartesian3Pos) {
    let pos = Cesium.Cartographic.fromCartesian(cartesian3Pos);
    return [
        (pos.longitude / Math.PI) * 180,
        (pos.latitude / Math.PI) * 180
    ];
}