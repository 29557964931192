import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("svg", {
    width: $props.width,
    height: $props.height,
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M5.15225 5.15128C5.62088 4.68265 6.38068 4.68265 6.84931 5.15128L12.0008 10.3027L17.1523 5.15128C17.6209 4.68265 18.3807 4.68265 18.8493 5.15128C19.3179 5.61991 19.3179 6.3797 18.8493 6.84833L13.6978 11.9998L18.8493 17.1513C19.3179 17.6199 19.3179 18.3797 18.8493 18.8483C18.3807 19.317 17.6209 19.317 17.1523 18.8483L12.0008 13.6969L6.84931 18.8483C6.38068 19.317 5.62088 19.317 5.15225 18.8483C4.68362 18.3797 4.68362 17.6199 5.15225 17.1513L10.3037 11.9998L5.15225 6.84833C4.68362 6.3797 4.68362 5.61991 5.15225 5.15128Z",
      fill: $props.color
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}