
import { defineComponent } from 'vue';
import { Line } from 'vue3-chart-v2';

export default defineComponent({
    name: 'LineChart',
    extends: Line,
    data() {
        return {
            lineCharts: null,
        };
    },
    props: {
        lineData: {},
        isScaleRatioOne: {},
    },
    created() {
        this.chartData = {};
        this.chartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            aspectRatio: 2,
            legend: {
                display: false
            },
            scales: {
                xAxes: [
                    {
                        display: true,
                        'padding-left': '2px',
                        gridLines: {
                            display: true,
                            color: '#2A3142'
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'Distance (m)'
                        },
                        ticks: {
                            // suggestedMin: 0,    // minimum will be 0, unless there is a lower value.
                            // suggestedMax: 100
                        }
                    }
                ],
                yAxes: [
                    {
                        display: true,
                        gridLines: {
                            display: true,
                            color: '#2A3142'
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'Height (m)'
                        },
                        ticks: {
                            // suggestedMin: 0,    // minimum will be 0, unless there is a lower value.
                            // suggestedMax: 100

                        }
                    }
                ]
            }
        };
    },
    watch: {
        lineData(heightDistancesData) {
            if (heightDistancesData.Heights.length > 0) {
                this.chartDataCal(heightDistancesData);
                if (this.lineCharts) {
                    this.lineCharts.data.datasets = this.chartData.datasets;
                    this.lineCharts.data.labels = this.chartData.labels;
                    this.toggleScaleRatioOne(this.isScaleRatioOne);
                    // this.lineCharts.update();
                } else {
                    this.renderChart(this.chartData, this.chartOptions);                    
                }
                this.lineCharts = this.state.chartObj;
            }
        },
    },
    methods: {
        chartDataCal(heightDistancesData) {
            const heights = heightDistancesData.Heights
            const distances = heightDistancesData.Distances.map(d => Number(d));
            let chartData = {
                labels: distances,
                datasets: [
                    {
                        label: 'Height value',
                        data: heights,
                        fill: false,
                        borderColor: 'rgb(255,255,0)',
                        // pointBackgroundColor: '#0F172A',
                        // backgroundColor: 'rgb(255, 255, 255)',
                        borderWidth: 2.5,
                        showLine: true,
                        radius: 1,
                        tension: 0
                    }
                ]
            };
            this.chartData = chartData;
        },
        toggleScaleRatioOne(value) {
            let lineHeightsFixed = this.lineData.Heights;
            let chartOptions = this.chartOptions;
            let lineData = this.lineData;
            console.log("toggleScale")
            if (value) {
                // Ratio patterns for distance and heights between two points
                let heightLength = Math.max(...lineHeightsFixed) - Math.min(...lineHeightsFixed);
                let totalDistance = lineData.Distances[this.lineData.Distances.length - 1];

                if (totalDistance > heightLength) {
                    let midHeight = (Math.max(...lineHeightsFixed) + Math.min(...lineHeightsFixed)) / 2;
                    // total distance in Distances[this.lineData.Distances.length - 1]
                    let tickMin = midHeight - lineData.Distances[lineData.Distances.length - 1] / 2;
                    let tickMax = midHeight + lineData.Distances[lineData.Distances.length - 1] / 2;
                    chartOptions.scales.yAxes[0].ticks.min = tickMin;
                    chartOptions.scales.yAxes[0].ticks.max = tickMax;

                    let totalDistance = lineData.Distances[lineData.Distances.length - 1];
                    chartOptions.scales.xAxes[0].ticks.min = 0;
                    chartOptions.scales.xAxes[0].ticks.max = totalDistance;
                } else {
                    let tickMin = 0;
                    let tickMax = Math.max(...lineHeightsFixed) - Math.min(...lineHeightsFixed);
                    chartOptions.scales.xAxes[0].ticks.min = tickMin;
                    chartOptions.scales.xAxes[0].ticks.max = tickMax;
                    // // check if there's an existing instance of the chart
                    // if (this.state.chartObj) {
                    //     // destroy current chart before rendering new one
                    //     this.state.chartObj.destroy();
                    // }
                }


            } else {
                chartOptions = {
                    responsive: true,
                    maintainAspectRatio: false,
                    aspectRatio: 2,
                    legend: {
                        display: false
                    },
                    scales: {
                        xAxes: [
                            {
                                display: true,
                                'padding-left': '2px',
                                gridLines: {
                                    display: true,
                                    color: '#2A3142'
                                },
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Distance (m)'
                                },
                                ticks: {
                                    // suggestedMin: 0,    // minimum will be 0, unless there is a lower value.
                                    // suggestedMax: 100
                                }
                            }
                        ],
                        yAxes: [
                            {
                                display: true,
                                gridLines: {
                                    display: true,
                                    color: '#2A3142'
                                },
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Height (m)'
                                },
                                ticks: {
                                    // suggestedMin: 0,    // minimum will be 0, unless there is a lower value.
                                    // suggestedMax: 100

                                }
                            }
                        ]
                    }
                };

            }
            this.chartOptions = chartOptions;
            this.lineCharts.options = this.chartOptions;
            this.lineCharts.update();
            if (this.lineData.Heights.length > 0) {
                this.chartDataCal(this.lineData);
            }
        },
    },
});
