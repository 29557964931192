
const mixin = {
    methods: {
        setCookie( name, value ) {
            this.$cookies.set( name,  value)
        },
        getCookie( name ) {
            return this.$cookies.get( name )
        },
        deleteCookie( name ) {
            this.$cookies.remove( name )
        },
        hasCookie( name ) {
            return this.$cookies.isKey( name )
        }
    }
}

export default mixin