
import Model from '@/models/Folder'

const state = () => ({
    list: [],
    navs: [],
    metas: [],
})
  
// getters
const getters = {
    folders(state) {
        return state.list
    },
    count(state) {
        return state.list.length
    },
    getFolderNavs(state) {
        return state.navs
    },
    getFolderMetas(state) {
        return state.metas
    },
}

// actions
const actions = {

    async loadFolders(context) {
        const list = await Model.list()
        context.commit( 'load', list.data.folders.data)
        context.commit( 'setFolderNavs', {
            first: list.data.folders.first_page_url,
            last: list.data.folders.last_page_url,
            next: list.data.folders.next_page_url,
            prev: list.data.folders.prev_page_url,
        })
        context.commit( 'setFolderMetas', {
            current_page: list.data.folders.current_page,
            from: list.data.folders.from,
            last_page: list.data.folders.last_page,
            path: list.data.folders.path,
            per_page: list.data.folders.per_page,
            to: list.data.folders.to,
            total: list.data.folders.total,
        })
    },
    async urlFolderRequest(context, url) {
        const list = await Model.request(url)
        context.commit( 'load', list.data.folders.data)
        context.commit( 'setFolderNavs', {
            first: list.data.folders.first_page_url,
            last: list.data.folders.last_page_url,
            next: list.data.folders.next_page_url,
            prev: list.data.folders.prev_page_url,
        })
        context.commit( 'setFolderMetas', {
            current_page: list.data.folders.current_page,
            from: list.data.folders.from,
            last_page: list.data.folders.last_page,
            path: list.data.folders.path,
            per_page: list.data.folders.per_page,
            to: list.data.folders.to,
            total: list.data.folders.total,
        })
    },
    removeFolder(context, id){
        context.commit( 'delete', id)
    }
}

// mutations
const mutations = {
    load(state, list) {
        state.list = list
    },
    setFolderNavs(state, navs) {
        state.navs = navs
    },
    setFolderMetas(state, metas) {
        state.metas = metas
    },
    delete(state, id) {
        var index = state.list.findIndex(list => list.folder_id == id)
        state.list.splice(index, 1)
    }
}
  
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}