
// Uses axios and Promises
// Everything that we need to communicate with the api will be declared here
import api from '@/providers/api'

const notification = {

    get(project_id) {
        return api.get(`notifications/get/${project_id}`)
    },

    list() {
        return api.get('notifications/list')
    },

    read(id) {
        return api.get(`notifications/read/${id}`);
    },

    readAll() {
        return api.get('notifications/readall');
    },
    
    store(payload) {
        return api.post('notifications/create', payload);
    },

    update() {
        return api.put('notifications/update');
    },

    delete(id) {
        return {
            status: 1,
            message: 'notification deleted successfully'
        }
    },

    unreads() {
        return api.get('notifications/unreads')
    },


}

export default notification