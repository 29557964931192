import ProcessModel from '@/models/Process';

const state = () => ({
    process: {
        progress: {
            visible: false, // Set this to true if you want to display the progress notification
            steps: {
                current: 1,
                max: 1
            },
            title: '',
            filename: '',
            message: '',
            progress: 0,
            process_id: ''
        },
        items: []
    },
    next_url: null
})

// getters
const getters = {
    currentProcess(state) {
        return state.process.progress
    },
    getCurrentRunningProcess(state) {
        let running = null;
        if (state.process) {
            running = state.process.items.filter(item => {
                return item.status === 'running'
            });
        }
        if (running) {
            return running[0];
        }
        return {};
    },
    getProcessItems(state) {
        return state.process.items;
    },
    theNextUrl(state) {
        return state.next_url;
    }

}

// actions
const actions = {
    async fetchProcessProgress(context, project_id) {
        return new Promise((resolve, reject) => {
            context.commit('setVisibility', false);
            let process_list = ProcessModel.list(project_id);
            process_list.then((result) => {
                let data = result.data.processes;
                var d = data
                context.commit('setProcessList', d.data);
                context.commit('setNextUrl', data.next_page_url)
                resolve(d.data);
            }).catch((error) => {
                reject(error);
            });
        })
    },
    createProcessItem(context, payload) {
        context.commit('createProcessItem', payload);
    },
    updateProcessItem(context, payload) {
        context.commit('updateProcessItem', payload);
    },
    updateProcessProgress(context, payload) {
        // `payload` should contain the same data template from default
        context.commit('updateProcessProgress', payload)
    },
    resetProcessProgress(context, payload) {
        context.commit('resetProcessProgress', {})
    },
    setNextUrl(context, url) {
        context.commit('setNextUrl', url)
    }
}

// mutations
const mutations = {
    createProcessItem(state, payload) {

        let proceessExist = false;
        state.process.items.forEach((item) => {
            if (item.process_id == payload.process_id) {
                proceessExist = true;
            }
        });

        if (!proceessExist) {
            state.process.progress.visible = true;
            state.process.items.unshift(payload);
        }

    },
    updateProcessItem(state, payload) {
        state.process.items.forEach((item, index) => {
            if (item.process_id == payload.process_id && item.status !== 'completed') {
                if (payload.failed_reason == undefined) {
                    payload.failed_reason = payload.fail_reason
                }
                state.process.items[index] = payload;
            }
        });
    },
    completeProcessItem(state, process_id) {
        state.process.items.forEach((process, index) => {
            if (process.process_id == process_id && process.status !== 'completed') {
                state.process.progress.visible = false;
                process.progress = 100;
                process.status = 'completed';
                process.items.forEach((item) => {
                    item.status = 2;
                });
            }
        });
    },
    resetProcessProgress(state, data) {
        state.process.progress.visible = false;
        state.process.items = [];
    },
    setVisibility(state, visible) {
        state.process.progress.visible = visible;
    },
    setProcessList(state, data) {
        //state.process.progress.visible = data.length > 0;
        state.process.items = data;
    },
    updateProcessProgress(state, data) {
        let current = state.process.progress;
        state.process.progress = { ...current, ...data }
    },
    setNextUrl(state, url) {
        state.next_url = url
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};